import { Component, OnInit, EventEmitter } from '@angular/core';

import { Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, DateAdapter } from '@angular/material';

//did use ts and ds file of parent folder 'workhours'
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';

import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { WorkhoursService } from 'app/main/pages/workHour/workhours.service';
import { ShowSdailyReportModalComponent } from 'app/main/pages/workHour/show-sdaily-report-modal/show-sdaily-report-modal.component';
import { WorkHour } from 'app/main/model/WorkHour';
import { WorkHourDiaglogOpen } from 'app/main/model/common';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { RequestWorkHour } from 'app/main/model/RequestBase';
import { ProjectRequisitionService } from 'app/main/pages/project/project.requisition.service';
import { projectRequisition } from 'app/main/model/Project';
import { WorkHourStatus, WorkHoursApproveLeaveType, PaymentOptionType } from 'app/main/model/WorkHourStatus';
import { DatePipe } from '@angular/common';
import { FormService } from 'app/core/services/form.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { AuthService } from 'app/core/services/auth.service';
import { CoWorkersService } from 'app/main/pages/coWorker/coWorkers.service';
import * as moment from 'moment';
import { addDays } from 'date-fns';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';

@Component({
  selector: 'app-non-work-hour',
  templateUrl: './non-work-hour.component.html',
  styleUrls: ['./non-work-hour.component.scss']
})

export class NonWorkHourComponent implements OnInit {

  shiftHours: any = [];
  hours: any;
  mins: any;
  workHourDiaglogOpen: WorkHourDiaglogOpen;
  show: boolean = false;
  NonworkingHour: WorkHour[] = [];
  nonWorkHour: WorkHour;
  onAdd = new EventEmitter();
  workHourValidation: FormGroup;
  total: number = 0;
  isTimeAdddedByShif: boolean = false;
  ShowManualHours: boolean = false;
  showSpinner: boolean = false;
  RequisitionList: projectRequisition[] = [];
  selectStatus = WorkHourStatus;
  today: any;
  IsRedirectToButton: boolean = true;
  projectList: any = [];
  isEditedWorkhours: boolean = false;
  HideManualHours: boolean = false;
  isAddBreakTime: boolean = true;
  editSelectedId: string = "";
  createEventObj: any = {};
  isPastDate: boolean = false;
  workHour = new WorkHour();
  startWeek: number = 1;
  paymentOption = PaymentOptionType;
  //roleName: string;
  coworkerObj: any;
  selectLeaveType = WorkHoursApproveLeaveType;
  roleClaims: any;
  hasLeaveApprovePermision: boolean = false;
  minDate:any;
  closedPaymentDate: Date = null;

  constructor(private translationLoader: FuseTranslationLoaderService,
    private workhoursService: WorkhoursService,
    private coWorkersService: CoWorkersService,
    private authService: AuthService,
    public dialogRef: MatDialogRef<NonWorkHourComponent>,
    @Inject(MAT_DIALOG_DATA) public data: WorkHourDiaglogOpen, private dialog: MatDialog, private fb: FormBuilder,
    private projectRequisitionService: ProjectRequisitionService,
    private translate: TranslateService,
    private notificationsService: NotificationsService,
    private datePipe: DatePipe, private formService: FormService,
    private adapter: DateAdapter<any>,) {

    //super("Work-hours", EventEmitterEnum.OnLoadMainContentWelcomPage);
    this.translationLoader.loadTranslations(english, danish);
    this.workHourDiaglogOpen = data;
    this.roleClaims = this.authService.getRoleClaimsList();
    //if (this.workHourDiaglogOpen.coWorkerWorkHours) {
    //  this.projectList = [];
    //  for (var i = 0; i < this.workHourDiaglogOpen.coWorkerWorkHours.project.length; i++) {
    //    if (this.workHourDiaglogOpen.coWorkerWorkHours.project[i].statusValue == 1) {
    //      this.projectList.push({
    //        projectId: this.workHourDiaglogOpen.coWorkerWorkHours.project[i].sagId,
    //        projectName: this.workHourDiaglogOpen.coWorkerWorkHours.project[i].sagNumber + " - " + this.workHourDiaglogOpen.coWorkerWorkHours.project[i].address1,
    //      })
    //    }
    //  }
    //}
    this.nonWorkHour = new WorkHour();
    if (data.status != null && data.status != undefined) {
      //this.nonWorkHour.projectId = data.projectId;
      this.nonWorkHour.status = data.status;
      this.nonWorkHour.date = new Date(moment(data.date, "YYYY-MM-DD").format('YYYY-MM-DD'));
      this.IsRedirectToButton = false;

    }
    this.adapter.setLocale('da');

    //this.nonWorkHour = new WorkHour();
  }


  ngOnInit() {
    //this.roleName = this.authService.getUserRole();
    this.authService.hasClaim(this.roleClaims.WorkHours_Leave_Approve).subscribe(hasClaim => {
      if (hasClaim) 
          this.hasLeaveApprovePermision = hasClaim
       else 
          this.hasLeaveApprovePermision = false;
    });
    this.selectStatus = this.selectStatus.filter(x => (x.id != 8 && x.id != 9));
    //super.RegisterEventEmitterForPage();
    this.hours = [{ value: '00' }, { value: '01' }, { value: '02' }, { value: '03' }, { value: '04' }, { value: '05' }, { value: '06' }, { value: '07' }, { value: '08' },
    { value: '09' }, { value: '10' }, { value: '11' }, { value: '12' }, { value: '13' }, { value: '14' }, { value: '15' }, { value: '16' }, { value: '17' },
    { value: '18' }, { value: '19' }, { value: '20' }, { value: '21' }, { value: '22' }, { value: '23' }];

    this.mins = [{ value: '00' }, { value: '15' }, { value: '30' }, { value: '45' }];
    //this.mins = [{ key: '00', value: '00' }, { key: '25', value: '15' }, { key: '50', value: '30' }, { key: '75', value: '45' }]
    this.AddValidation();
    this.today = new Date();
    if (this.nonWorkHour.status != null && this.nonWorkHour.status != undefined) {
      this.Get(new RequestWorkHour());
      this.adapter.setLocale('da')
    }
    else {
      this.nonWorkHour.date = this.today; //this.datePipe.transform(new Date(), "yyyy-MM-dd");
      this.nonWorkHour.status = 1;
      this.adapter.setLocale('da')
    }
    this.createEventObj.startDate = this.nonWorkHour.date ? this.nonWorkHour.date : this.today;
    this.createEventObj.endDate = this.nonWorkHour.date ? this.nonWorkHour.date : this.today;
    this.nonWorkHour.leaveStatus = 1;
    this.calculateEndDate();
    this.coworkerLeaveApplyAsApproved();
    this.getLastClosedPaymentDate();
  }

  AddValidation() {
    this.workHourValidation = this.fb.group({
      Date: [null, Validators.required],
      Status: [null, Validators.required],
      startDate: [null, Validators.required],
      endDate: [null, Validators.required],
      paymentOption: [null],
      leaveStatus: [null, Validators.required],
    });
  }

  Cancel(): void {
    this.dialogRef.close();
  }

  Get(model: RequestWorkHour) {
    this.showSpinner = true;
    model.StartDate = this.workHourDiaglogOpen.date;
    model.EndDate = this.workHourDiaglogOpen.date;
    //model.ProjectId = this.workHourDiaglogOpen.projectId;
    model.CoworkerId = this.workHourDiaglogOpen.workerId;
    model.Status = this.workHourDiaglogOpen.status;
    model.OrderBy = "ID";
    this.workhoursService.GetNonWorkHours<ResponseBase>(model)
      .subscribe({
        next: (response: ResponseBase) => {
          if (response) {
            this.NonworkingHour = response.result;

            // this.NonworkingHour.forEach(item => {
            //   var fh: any; var fmin: any; var th: any; var tmin: any;
            //   var tempFrom = item.from.split(':');
            //   fh = tempFrom[0];
            //   fmin = tempFrom[1];
            //   if (fmin != '00') {
            //     fmin = (fmin * 100) / 60
            //     item.from = fh + ":" + fmin;
            //   }
            //   var tempTo = item.to.split(':');
            //   th = tempTo[0];
            //   tmin = tempTo[1];
            //   if (tmin != '00') {
            //     tmin = (tmin * 100) / 60
            //     item.to = th + ":" + tmin;
            //   }
            // });

            if (this.NonworkingHour != null && this.NonworkingHour.length > 0)
              this.nonWorkHour.text = this.NonworkingHour[0].text;
            this.nonWorkHour.paymentOption = this.NonworkingHour[0].paymentOption;
            //this.workHours.forEach(item => {
            //  if (parseFloat(item.to) > 11.30) {
            //    let time = parseFloat(item.hours) - 0.5;
            //    item.hours = time.toString();
            //    if (item.hours.split('.')[1] == "5") {
            //      item.hours = item.hours.split('.')[0] + ".3";
            //    }
            //  }
            //})
            this.showSpinner = false;
          }
          else {
            this.NonworkingHour = [];
            this.showSpinner = false;
          }
        },
        error: err => {
          this.NonworkingHour = [];
          this.showSpinner = false;
        },
        complete: () => {
          this.GetTotalHours();
        }
      });
  }

  GetTotalHours() {
    this.total = 0;
    this.NonworkingHour.filter(wh => {
      //this.total += ConvertTimeToHunders(wh.hours);
      this.total += (wh.tid - wh.pauseTimer);
    })
    return this.total;
  }

  showDailyReport(Todday, ProjectName): void {
    const dialogRef = this.dialog.open(ShowSdailyReportModalComponent, {
      width: '500px',
      data: {
        workHours: this.NonworkingHour
      }
    });

    dialogRef.componentInstance.onDeleteWorkHour.subscribe((Id) => {
      //this.Delete(Id);
      this.deleteWorkHour(Id);
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  //Add() {
  //  this.formService.markFormGroupTouched(this.workHourValidation);

  //  if (this.workHourValidation.valid) {
  //    this.nonWorkHour.workerId = this.workHourDiaglogOpen.workerId;
  //    this.nonWorkHour.isWorking = false;
  //    if (!this.isTimeAdddedByShif) {
  //      this.nonWorkHour.from = this.nonWorkHour.fromStartHour + "." + this.nonWorkHour.fromStartMinute;
  //      this.nonWorkHour.to = this.nonWorkHour.fromEndHour + "." + this.nonWorkHour.fromEndMinute;
  //      this.nonWorkHour.shiftType = 4;
  //      this.AddWorkhourAPICall(this.nonWorkHour);
  //    }
  //    else {
  //      this.CreateShiftHours(0);
  //      this.AddWorkhourAPICall(this.nonWorkHour);
  //    }
  //  }
  //}

  // 2 table change
  Add() {
    var isAlreadyApplyWorkHours = false;
    var workhoursdate;
    if (this.workHourDiaglogOpen.projectWithWorkHoursList && this.workHourDiaglogOpen.projectWithWorkHoursList.length > 0) {
      if(this.nonWorkHour.nonWorkingDayList && this.nonWorkHour.nonWorkingDayList.length > 0 && (this.nonWorkHour.status == 1 || this.nonWorkHour.status == 3 || this.nonWorkHour.status == 4 || this.nonWorkHour.status == 5)){
        this.nonWorkHour.nonWorkingDayList.filter(item =>{
          this.workHourDiaglogOpen.projectWithWorkHoursList.filter(p => {
            if (p.workHoursList && p.workHoursList.length > 0) {
              var tempDateArray = p.workHoursList.filter(w => (moment(w.date).format("YYYY-MM-DD") == item));
              if (tempDateArray && tempDateArray.length > 0 && p.isActive) {
                isAlreadyApplyWorkHours = true;
                workhoursdate= item;
                return;
              }
            }
          });
          if (this.workHourDiaglogOpen.nonWorkHoursList && this.workHourDiaglogOpen.nonWorkHoursList.length > 0) {
            this.workHourDiaglogOpen.nonWorkHoursList.filter(p => {
              if (!isAlreadyApplyWorkHours && moment(p.date).format("YYYY-MM-DD") == item) {
                if (this.ShowManualHours && !(p.status == 1 || p.status == 4 || p.status == 5)) {
                  var fromTimer = item + " " + this.nonWorkHour.fromStartHour + ":" + this.nonWorkHour.fromStartMinute;
                  var startTime = moment(p.date).format("YYYY-MM-DD") + " " + p.from;
                  var endTime = moment(p.date).format("YYYY-MM-DD") + " " + p.to;
                  var today = new Date(fromTimer).getTime();
                  var from = new Date(startTime).getTime();
                  var to = new Date(endTime).getTime();
                  var withinRange = today >= from && today < to;
                  console.log("withinRange", withinRange);
                  if (withinRange) {
                    isAlreadyApplyWorkHours = true;
                    workhoursdate = item;
                    return;
                  }
                }
                else {
                  isAlreadyApplyWorkHours = true;
                  workhoursdate = item;
                  return;
                }
              }
            });
          }
        })
      }
    }
    if (this.workHourDiaglogOpen.projectWithWorkHoursList && this.workHourDiaglogOpen.projectWithWorkHoursList.length > 0) {
      if(this.nonWorkHour.nonWorkingDayList && this.nonWorkHour.nonWorkingDayList.length > 0 && (this.nonWorkHour.status == 2 || this.nonWorkHour.status == 6 || this.nonWorkHour.status == 7)){
        this.nonWorkHour.nonWorkingDayList.filter(item =>{
          this.workHourDiaglogOpen.projectWithWorkHoursList.filter(p => {
            if (p.workHoursList && p.workHoursList.length > 0) {
              var tempDateArray = p.workHoursList.filter(w => (moment(w.date).format("YYYY-MM-DD") == item));
              if (tempDateArray && tempDateArray.length > 0 && p.isActive) {
                tempDateArray.filter(whs => {
                  if (this.ShowManualHours) {
                    var fromTimer = item + " " + this.nonWorkHour.fromStartHour + ":" + this.nonWorkHour.fromStartMinute;
                    var startTime = moment(whs.date).format("YYYY-MM-DD") + " " + whs.from;
                    var endTime = moment(whs.date).format("YYYY-MM-DD") + " " + whs.to;
                    var today = new Date(fromTimer).getTime();
                    var from = new Date(startTime).getTime();
                    var to = new Date(endTime).getTime();
                    var withinRange = today >= from && today < to;
                    console.log("withinRange", withinRange);
                    if (withinRange) {
                      isAlreadyApplyWorkHours = true;
                      workhoursdate = item;
                      return;
                    }
                  }
                  else {
                    if (whs.hours >= 7) {
                      isAlreadyApplyWorkHours = true;
                      workhoursdate = item;
                      return;
                    }
                  }
                })
              }
            }
          });
        })
      }
    }
    if (isAlreadyApplyWorkHours) {
      this.translate.get("MESSAGES.CannotApplyLeave").subscribe({
        next: (res) => {
          var msg = res.replace("@date", moment(workhoursdate, "YYYY.MM.DD").format("DD.MM.YYYY"));
          this.notificationsService.error(msg);
        },
        error: err => {
        }
      });
    }
    else {
      this.nonWorkHour.workerId = this.workHourDiaglogOpen.workerId;
      this.nonWorkHour.isWorking = false;

      if (!this.isTimeAdddedByShif) {
        this.nonWorkHour.from = this.nonWorkHour.fromStartHour + ":" + this.nonWorkHour.fromStartMinute;
        this.nonWorkHour.to = this.nonWorkHour.fromEndHour + ":" + this.nonWorkHour.fromEndMinute;
        if (this.NonworkingHour.length == 0) {
          this.NonworkingHour = [];
        }
        var wh = new WorkHour();
        wh.sagOpgaveID = this.nonWorkHour.sagOpgaveID;
        wh.workerId = this.workHourDiaglogOpen.workerId;
        wh.date = this.nonWorkHour.date;
        wh.id = null;
        wh.isWorking = false;
        wh.status = this.nonWorkHour.status;
        wh.from = this.nonWorkHour.fromStartHour + ":" + this.nonWorkHour.fromStartMinute;
        wh.to = this.nonWorkHour.fromEndHour + ":" + this.nonWorkHour.fromEndMinute;
        var time = moment.utc(moment(this.nonWorkHour.to, "HH:mm").diff(moment(this.nonWorkHour.from, "HH:mm"))).format("HH:mm");
        var hour = moment.utc(moment(this.nonWorkHour.to, "HH:mm").diff(moment(this.nonWorkHour.from, "HH:mm"))).format("HH");
        var minutes = moment.utc(moment(this.nonWorkHour.to, "HH:mm").diff(moment(this.nonWorkHour.from, "HH:mm"))).format("mm");
        var tidMinute = ((Number(minutes) * 100) / 60).toString();
        wh.Tid = Number(hour + "." + tidMinute);
        wh.pauseTimer = 0;
        wh.shiftType = 4;
        wh.Hours = hour + "." + tidMinute;
        wh.text = this.nonWorkHour.text;
        wh.nonWorkingDayList = this.nonWorkHour.nonWorkingDayList;
        wh.leaveStatus = this.nonWorkHour.leaveStatus;
        this.NonworkingHour.push(wh);
        if (this.isAddBreakTime) {
          wh.pauseTimer = 0.5;
          wh.Hours = (wh.Tid - wh.pauseTimer).toString();
        }

        //this.AddWorkhourAPICall(wh);
        this.checkMoreThan7HoursOnFriday(wh);
        //var NonworkingHourSort = this.NonworkingHour.sort(function (a, b) {
        //  if (a.from < b.from) { return -1; }
        //  if (a.from > b.from) { return 1; }
        //  return 0;
        //});
        //for (var i = 0; i < NonworkingHourSort.length; i++) {
        //  if (i == 0) {
        //    var tidHoure = moment.utc(moment(NonworkingHourSort[i].to, "HH:mm").diff(moment(NonworkingHourSort[i].from, "HH:mm"))).format("HH");
        //    var tidMinute = moment.utc(moment(NonworkingHourSort[i].to, "HH:mm").diff(moment(NonworkingHourSort[i].from, "HH:mm"))).format("mm");
        //    try {
        //      tidMinute = ((Number(tidMinute) * 100) / 60).toString();
        //    } catch (e) {
        //      tidMinute = "00";
        //    }
        //    var time = tidHoure + ":" + tidMinute;
        //    NonworkingHourSort[i].Tid = Number(time.replace(':', '.'));
        //    NonworkingHourSort[i].PauseTimer = 0;
        //    NonworkingHourSort[i].Hours = time.replace(':', '.');
        //  }
        //  else {
        //    var tidHoure = moment.utc(moment(NonworkingHourSort[i].to, "HH:mm").diff(moment(NonworkingHourSort[i - 1].to, "HH:mm"))).format("HH");
        //    var tidMinute = moment.utc(moment(NonworkingHourSort[i].to, "HH:mm").diff(moment(NonworkingHourSort[i - 1].to, "HH:mm"))).format("mm");
        //    try {
        //      tidMinute = ((Number(tidMinute) * 100) / 60).toString();
        //    } catch (e) {
        //      tidMinute = "00";
        //    }
        //    var tidTime = tidHoure + ":" + tidMinute;
        //    NonworkingHourSort[i].Tid = Number(tidTime.replace(':', '.'));

        //    var pauseHoure = moment.utc(moment(NonworkingHourSort[i].from, "HH:mm").diff(moment(NonworkingHourSort[i - 1].to, "HH:mm"))).format("HH");
        //    var pauseMinute = moment.utc(moment(NonworkingHourSort[i].from, "HH:mm").diff(moment(NonworkingHourSort[i - 1].to, "HH:mm"))).format("mm");
        //    try {
        //      pauseMinute = ((Number(pauseMinute) * 100) / 60).toString();
        //    } catch (e) {
        //      pauseMinute = "00";
        //    }
        //    var puseTime = pauseHoure + ":" + pauseMinute;
        //    NonworkingHourSort[i].PauseTimer = Number(puseTime.replace(':', '.'));

        //    tidTime = moment.utc(moment(NonworkingHourSort[i].to, "HH:mm").diff(moment(NonworkingHourSort[i - 1].to, "HH:mm"))).format("HH:mm");
        //    puseTime = moment.utc(moment(NonworkingHourSort[i].from, "HH:mm").diff(moment(NonworkingHourSort[i - 1].to, "HH:mm"))).format("HH:mm");
        //    var hoursHoure = moment.utc(moment(tidTime, "HH:mm").diff(moment(puseTime, "HH:mm"))).format("HH");
        //    var hoursMinute = moment.utc(moment(tidTime, "HH:mm").diff(moment(puseTime, "HH:mm"))).format("mm");
        //    try {
        //      hoursMinute = ((Number(hoursMinute) * 100) / 60).toString();
        //    } catch (e) {
        //      hoursMinute = "00";
        //    }
        //    var hoursTime = hoursHoure + ":" + hoursMinute;
        //    NonworkingHourSort[i].Hours = hoursTime.replace(':', '.');
        //  }
        //  this.AddWorkhourAPICall(NonworkingHourSort[i]);
        //}
      }
      if (this.shiftHours.length > 0) {
        var shiftType = this.shiftHours[0].shiftType;
        this.nonWorkHour.shiftType = shiftType;
        if (shiftType == 2) {
          this.nonWorkHour.from = "07:00";
          this.nonWorkHour.to = "14:30";
          this.nonWorkHour.Tid = 7.5;
          this.nonWorkHour.pauseTimer = 0.5;
          this.nonWorkHour.Hours = "7";
          //this.AddWorkhourAPICall(this.nonWorkHour);
          this.checkMoreThan7HoursOnFriday(this.nonWorkHour);
        }
        else if (shiftType == 3) {
          this.nonWorkHour.from = "07:00";
          this.nonWorkHour.to = "15:00";
          this.nonWorkHour.Tid = 8.00;
          this.nonWorkHour.pauseTimer = 0.5;
          this.nonWorkHour.Hours = "07.5";
          //this.AddWorkhourAPICall(this.nonWorkHour);
          this.checkMoreThan7HoursOnFriday(this.nonWorkHour);
        }
      }
    }
  }

  editWorkhours(currentEditedhours: WorkHour) {
    this.shiftHours = [];
    this.editSelectedId = currentEditedhours.id;
    this.isEditedWorkhours = true;
    if (currentEditedhours["pauseTimer"] > 0) {
      this.isAddBreakTime = true;
    }
    else {
      this.isAddBreakTime = false;
    }
    this.nonWorkHour.text = currentEditedhours.text;
    this.nonWorkHour.sagOpgaveID = currentEditedhours.sagOpgaveID;

    this.nonWorkHour = currentEditedhours;

    this.nonWorkHour.fromStartHour = currentEditedhours.from.split(':')[0];
    this.nonWorkHour.fromStartMinute = currentEditedhours.from.split(':')[1];

    //Calculate Start min;
    // if (this.nonWorkHour.fromStartMinute != '00') {
    //   var Smin: any;
    //   Smin = this.nonWorkHour.fromStartMinute;
    //   var Startmin: any;
    //   Startmin = ((Smin * 60) / 100);
    //   this.nonWorkHour.fromStartMinute = Startmin.toString();
    // }
    //Cal End
    this.nonWorkHour.fromEndHour = currentEditedhours.to.split(':')[0];
    this.nonWorkHour.fromEndMinute = currentEditedhours.to.split(':')[1];
    this.nonWorkHour.paymentOption = currentEditedhours.paymentOption;
    //Calculator Start min
    // if (this.nonWorkHour.fromEndMinute != '00') {
    //   var Emin: any;
    //   Emin = this.nonWorkHour.fromEndMinute;
    //   var Endtmin: any;
    //   Endtmin = ((Emin * 60) / 100);
    //   this.nonWorkHour.fromEndMinute = Endtmin.toString();
    // }
    //Cal End
  }

  updateworkhours() {
    //for (var i = 0; i < this.NonworkingHour.length; i++) {
    //  if (this.NonworkingHour[i].id == this.nonWorkHour.id) {
    //    this.NonworkingHour[i].from = this.nonWorkHour.fromStartHour + ":" + this.nonWorkHour.fromStartMinute;
    //    this.NonworkingHour[i].to = this.nonWorkHour.fromEndHour + ":" + this.nonWorkHour.fromEndMinute;
    //  }
    //}

    //var NonworkingHourSort = this.NonworkingHour.sort(function (a, b) {
    //  if (a.from < b.from) { return -1; }
    //  if (a.from > b.from) { return 1; }
    //  return 0;
    //});

    this.nonWorkHour.from = this.nonWorkHour.fromStartHour + ":" + this.nonWorkHour.fromStartMinute;
    this.nonWorkHour.to = this.nonWorkHour.fromEndHour + ":" + this.nonWorkHour.fromEndMinute;
    this.nonWorkHour.shiftType = 4;
    if (this.isAddBreakTime)
      this.nonWorkHour.pauseTimer = 0.5;
    else
      this.nonWorkHour.pauseTimer = 0;

    var NonworkingHourSort = [];
    NonworkingHourSort.push(this.nonWorkHour);
    for (var i = 0; i < NonworkingHourSort.length; i++) {
      if (i == 0) {
        var tidHoure = moment.utc(moment(NonworkingHourSort[i].to, "HH:mm").diff(moment(NonworkingHourSort[i].from, "HH:mm"))).format("HH");
        var tidMinute = moment.utc(moment(NonworkingHourSort[i].to, "HH:mm").diff(moment(NonworkingHourSort[i].from, "HH:mm"))).format("mm");
        try {
          tidMinute = ((Number(tidMinute) * 100) / 60).toString();
        } catch (e) {
          tidMinute = "00";
        }
        var time = tidHoure + ":" + tidMinute;
        NonworkingHourSort[i].Tid = Number(time.replace(':', '.'));
        NonworkingHourSort[i].pauseTimer = 0;
        NonworkingHourSort[i].Hours = time.replace(':', '.');
      }
      else {
        var tidHoure = moment.utc(moment(NonworkingHourSort[i].to, "HH:mm").diff(moment(NonworkingHourSort[i - 1].to, "HH:mm"))).format("HH");
        var tidMinute = moment.utc(moment(NonworkingHourSort[i].to, "HH:mm").diff(moment(NonworkingHourSort[i - 1].to, "HH:mm"))).format("mm");
        try {
          tidMinute = ((Number(tidMinute) * 100) / 60).toString();
        } catch (e) {
          tidMinute = "00";
        }
        var tidTime = tidHoure + ":" + tidMinute;
        NonworkingHourSort[i].Tid = Number(tidTime.replace(':', '.'));

        var pauseHoure = moment.utc(moment(NonworkingHourSort[i].from, "HH:mm").diff(moment(NonworkingHourSort[i - 1].to, "HH:mm"))).format("HH");
        var pauseMinute = moment.utc(moment(NonworkingHourSort[i].from, "HH:mm").diff(moment(NonworkingHourSort[i - 1].to, "HH:mm"))).format("mm");
        try {
          pauseMinute = ((Number(pauseMinute) * 100) / 60).toString();
        } catch (e) {
          pauseMinute = "00";
        }
        var puseTime = pauseHoure + ":" + pauseMinute;
        NonworkingHourSort[i].pauseTimer = Number(puseTime.replace(':', '.'));

        tidTime = moment.utc(moment(NonworkingHourSort[i].to, "HH:mm").diff(moment(NonworkingHourSort[i - 1].to, "HH:mm"))).format("HH:mm");
        puseTime = moment.utc(moment(NonworkingHourSort[i].from, "HH:mm").diff(moment(NonworkingHourSort[i - 1].to, "HH:mm"))).format("HH:mm");
        var hoursHoure = moment.utc(moment(tidTime, "HH:mm").diff(moment(puseTime, "HH:mm"))).format("HH");
        var hoursMinute = moment.utc(moment(tidTime, "HH:mm").diff(moment(puseTime, "HH:mm"))).format("mm");
        try {
          hoursMinute = ((Number(hoursMinute) * 100) / 60).toString();
        } catch (e) {
          hoursMinute = "00";
        }
        var hoursTime = hoursHoure + ":" + hoursMinute;
        NonworkingHourSort[i].Hours = hoursTime.replace(':', '.');
      }
      if (this.editSelectedId == NonworkingHourSort[i].id && this.isAddBreakTime) {
        NonworkingHourSort[i].pauseTimer = 0.5;
        NonworkingHourSort[i].Hours = (NonworkingHourSort[i].tid - NonworkingHourSort[i].pauseTimer);
      }
      var tempData = [];
      tempData.push(moment(NonworkingHourSort[i].date).format("YYYY-MM-DD"));
      NonworkingHourSort[i].nonWorkingDayList = tempData;
      this.AddWorkhourAPICall(NonworkingHourSort[i]);
    }
  }

  Delete(Id: string) {
    const dialogRef = this.dialog.open(AlertMessageModelComponent, {
      width: '500px',
      data: {
        Header: 'Headers.DeleteReport',
      }
      //data: Id
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteWorkHour(Id);
        //this.workhoursService.Delete<ResponseBase>(Id)
        //  .subscribe({
        //    next: (response: ResponseBase) => {
        //      this.NonworkingHour.splice(this.NonworkingHour.findIndex(wh => wh.id == Id), 1);
        //      this.onAdd.emit();
        //    },
        //    error: err => { },
        //    complete: () => {
        //      this.GetTotalHours();
        //    }
        //  });
        //this.NonworkingHour = this.NonworkingHour.filter(o => o.id != Id);
        //var NonworkingHourSort = this.NonworkingHour.sort(function (a, b) {
        //  if (a.from < b.from) { return -1; }
        //  if (a.from > b.from) { return 1; }
        //  return 0;
        //});
        //for (var i = 0; i < NonworkingHourSort.length; i++) {
        //  if (i == 0) {
        //    var tidHoure = moment.utc(moment(NonworkingHourSort[i].to, "HH:mm").diff(moment(NonworkingHourSort[i].from, "HH:mm"))).format("HH");
        //    var tidMinute = moment.utc(moment(NonworkingHourSort[i].to, "HH:mm").diff(moment(NonworkingHourSort[i].from, "HH:mm"))).format("mm");
        //    try {
        //      tidMinute = ((Number(tidMinute) * 100) / 60).toString();
        //    } catch (e) {
        //      tidMinute = "00";
        //    }
        //    var time = tidHoure + ":" + tidMinute;
        //    NonworkingHourSort[i].Tid = Number(time.replace(':', '.'));
        //    NonworkingHourSort[i].PauseTimer = 0;
        //    NonworkingHourSort[i].Hours = time.replace(':', '.');
        //  }
        //  else {
        //    var tidHoure = moment.utc(moment(NonworkingHourSort[i].to, "HH:mm").diff(moment(NonworkingHourSort[i - 1].to, "HH:mm"))).format("HH");
        //    var tidMinute = moment.utc(moment(NonworkingHourSort[i].to, "HH:mm").diff(moment(NonworkingHourSort[i - 1].to, "HH:mm"))).format("mm");
        //    try {
        //      tidMinute = ((Number(tidMinute) * 100) / 60).toString();
        //    } catch (e) {
        //      tidMinute = "00";
        //    }
        //    var tidTime = tidHoure + ":" + tidMinute;
        //    NonworkingHourSort[i].Tid = Number(tidTime.replace(':', '.'));

        //    var pauseHoure = moment.utc(moment(NonworkingHourSort[i].from, "HH:mm").diff(moment(NonworkingHourSort[i - 1].to, "HH:mm"))).format("HH");
        //    var pauseMinute = moment.utc(moment(NonworkingHourSort[i].from, "HH:mm").diff(moment(NonworkingHourSort[i - 1].to, "HH:mm"))).format("mm");
        //    try {
        //      pauseMinute = ((Number(pauseMinute) * 100) / 60).toString();
        //    } catch (e) {
        //      pauseMinute = "00";
        //    }
        //    var puseTime = pauseHoure + ":" + pauseMinute;
        //    NonworkingHourSort[i].PauseTimer = Number(puseTime.replace(':', '.'));

        //    tidTime = moment.utc(moment(NonworkingHourSort[i].to, "HH:mm").diff(moment(NonworkingHourSort[i - 1].to, "HH:mm"))).format("HH:mm");
        //    puseTime = moment.utc(moment(NonworkingHourSort[i].from, "HH:mm").diff(moment(NonworkingHourSort[i - 1].to, "HH:mm"))).format("HH:mm");
        //    var hoursHoure = moment.utc(moment(tidTime, "HH:mm").diff(moment(puseTime, "HH:mm"))).format("HH");
        //    var hoursMinute = moment.utc(moment(tidTime, "HH:mm").diff(moment(puseTime, "HH:mm"))).format("mm");
        //    try {
        //      hoursMinute = ((Number(hoursMinute) * 100) / 60).toString();
        //    } catch (e) {
        //      hoursMinute = "00";
        //    }
        //    var hoursTime = hoursHoure + ":" + hoursMinute;
        //    NonworkingHourSort[i].Hours = hoursTime.replace(':', '.');
        //  }
        //  this.AddWorkhourAPICall(NonworkingHourSort[i]);
        //}
      }
    });
  }

  CreateShiftHours(index) {
    this.nonWorkHour.from = this.shiftHours[index].from;
    this.nonWorkHour.to = this.shiftHours[index].to;
    this.nonWorkHour.shiftType = this.shiftHours[index].shiftType;
    this.nonWorkHour.text = this.nonWorkHour.text;
  }

  AddWorkhourAPICall(workHour: WorkHour) {
    this.showSpinner = true;
    workHour.date = this.datePipe.transform(workHour.date, 'yyyy-MM-dd');
    this.workhoursService.AddWorkhoursWithDateRange<ResponseBase>(workHour)
      .subscribe({
        next: (response: ResponseBase) => {
          //if (response.result) {
          //  this.NonworkingHour.push(response.result);
          //}
          //if (this.shiftHours.length > 1) {
          //  this.CreateShiftHours(1);
          //  this.AddWorkhourAPICall(this.nonWorkHour);
          //  this.nonWorkHour = new WorkHour();
          //  this.shiftHours = new Array();
          //}
          //else {
          //  this.nonWorkHour = new WorkHour();
          //  this.shiftHours = new Array();
          //}
          //this.onAdd.emit();
        },
        error: err => {
          this.translate.get("MESSAGES.Add_Error").subscribe({
            next: (res) => {
              this.notificationsService.error(res);
            },
            error: err => {
            }
          });
        },
        complete: () => {
          //this.NonworkingHour.text = '';
          this.showSpinner = false;
          this.Cancel();
        }
      });
  }

  AddByShiftTime(startTime1, endTime1, startTime2, endTime2, shiftNo) {
    this.isAddBreakTime = false;
    this.shiftHours = new Array();
    this.ShowManualHours = false;
    this.isTimeAdddedByShif = true;
    //Cal min endTime1 endTime2
    // var tempEndtime1 = endTime1.split('.');
    // var et1h = tempEndtime1[0];
    // var et1min = tempEndtime1[1];
    // if (et1min != '00') {
    //   et1min = (15 * 100) / 60;
    //   endTime1 = et1h + "." + et1min;
    // }

    // var tempEndtime2 = endTime2.split('.');
    // var et2h = tempEndtime2[0];
    // var et2min = tempEndtime2[1];
    // if (et2min != '00') {
    //   et2min = (15 * 100) / 60;
    //   endTime2 = et2h + "." + et2min;
    // }
    //End
    if (shiftNo == 1) {
      let obj = {
        from: startTime1,
        to: endTime1,
        shiftType: shiftNo
      }
      this.shiftHours.push(obj);
    }
    else if (shiftNo == 2) {
      var temp = new Array();
      temp = [{ from: startTime1, to: endTime1, shiftType: shiftNo }, { from: startTime2, to: endTime2, shiftType: shiftNo }]
      this.shiftHours = temp;
    }
    else {
      var temp = new Array();
      temp = [{ from: startTime1, to: endTime1, shiftType: shiftNo }, { from: startTime2, to: endTime2, shiftType: shiftNo }]
      this.shiftHours = temp;
    }
  }

  onAddBreakTime(value) {
    this.isAddBreakTime = value;
  }

  CheckEndHour() {
    this.nonWorkHour.fromEndHour = (this.nonWorkHour.fromEndHour < this.nonWorkHour.fromStartHour) == true ? (this.nonWorkHour.fromEndHour = this.nonWorkHour.fromStartHour) : this.nonWorkHour.fromEndHour;
    //this.CheckEndMinute();
  }

  CheckEndMinute() {
    this.nonWorkHour.fromEndMinute = (this.nonWorkHour.fromEndMinute < this.nonWorkHour.fromStartMinute) == true ? (this.nonWorkHour.fromEndMinute = this.nonWorkHour.fromStartMinute) : ((this.nonWorkHour.fromEndHour == this.nonWorkHour.fromStartHour) == true ? (this.nonWorkHour.fromEndMinute = this.nonWorkHour.fromStartMinute) : this.nonWorkHour.fromEndMinute);
  }

  OnshowManualHours() {
    //this.isAddBreakTime = false;
    this.shiftHours = new Array();
    this.isTimeAdddedByShif = false;
    return this.ShowManualHours = !this.ShowManualHours;
  }

  OnshowPauseHours() {
    return this.HideManualHours = !this.HideManualHours;
  }

  onUpdateDate(e) {
    this.nonWorkHour.date = e;
    this.adapter.setLocale('da')
  }
  deleteWorkHour(Id) {
    this.workhoursService.Delete<ResponseBase>(Id)
      .subscribe({
        next: (response: ResponseBase) => {
          this.NonworkingHour.splice(this.NonworkingHour.findIndex(wh => wh.id == Id), 1);
          this.onAdd.emit();
        },
        error: err => { },
        complete: () => {
          this.GetTotalHours();
        }
      });
    this.NonworkingHour = this.NonworkingHour.filter(o => o.id != Id);
    var NonworkingHourSort = this.NonworkingHour.sort(function (a, b) {
      if (a.from < b.from) { return -1; }
      if (a.from > b.from) { return 1; }
      return 0;
    });
    for (var i = 0; i < NonworkingHourSort.length; i++) {
      if (i == 0) {
        var tidHoure = moment.utc(moment(NonworkingHourSort[i].to, "HH:mm").diff(moment(NonworkingHourSort[i].from, "HH:mm"))).format("HH");
        var tidMinute = moment.utc(moment(NonworkingHourSort[i].to, "HH:mm").diff(moment(NonworkingHourSort[i].from, "HH:mm"))).format("mm");
        try {
          tidMinute = ((Number(tidMinute) * 100) / 60).toString();
        } catch (e) {
          tidMinute = "00";
        }
        var time = tidHoure + ":" + tidMinute;
        NonworkingHourSort[i].Tid = Number(time.replace(':', '.'));
        //NonworkingHourSort[i].pauseTimer = 0;
        NonworkingHourSort[i].Hours = time.replace(':', '.');
      }
      else {
        var tidHoure = moment.utc(moment(NonworkingHourSort[i].to, "HH:mm").diff(moment(NonworkingHourSort[i - 1].to, "HH:mm"))).format("HH");
        var tidMinute = moment.utc(moment(NonworkingHourSort[i].to, "HH:mm").diff(moment(NonworkingHourSort[i - 1].to, "HH:mm"))).format("mm");
        try {
          tidMinute = ((Number(tidMinute) * 100) / 60).toString();
        } catch (e) {
          tidMinute = "00";
        }
        var tidTime = tidHoure + ":" + tidMinute;
        NonworkingHourSort[i].Tid = Number(tidTime.replace(':', '.'));

        var pauseHoure = moment.utc(moment(NonworkingHourSort[i].from, "HH:mm").diff(moment(NonworkingHourSort[i - 1].to, "HH:mm"))).format("HH");
        var pauseMinute = moment.utc(moment(NonworkingHourSort[i].from, "HH:mm").diff(moment(NonworkingHourSort[i - 1].to, "HH:mm"))).format("mm");
        try {
          pauseMinute = ((Number(pauseMinute) * 100) / 60).toString();
        } catch (e) {
          pauseMinute = "00";
        }
        var puseTime = pauseHoure + ":" + pauseMinute;
        NonworkingHourSort[i].pauseTimer = Number(puseTime.replace(':', '.'));

        tidTime = moment.utc(moment(NonworkingHourSort[i].to, "HH:mm").diff(moment(NonworkingHourSort[i - 1].to, "HH:mm"))).format("HH:mm");
        puseTime = moment.utc(moment(NonworkingHourSort[i].from, "HH:mm").diff(moment(NonworkingHourSort[i - 1].to, "HH:mm"))).format("HH:mm");
        var hoursHoure = moment.utc(moment(tidTime, "HH:mm").diff(moment(puseTime, "HH:mm"))).format("HH");
        var hoursMinute = moment.utc(moment(tidTime, "HH:mm").diff(moment(puseTime, "HH:mm"))).format("mm");
        try {
          hoursMinute = ((Number(hoursMinute) * 100) / 60).toString();
        } catch (e) {
          hoursMinute = "00";
        }
        var hoursTime = hoursHoure + ":" + hoursMinute;
        NonworkingHourSort[i].Hours = hoursTime.replace(':', '.');        
      }
      var tempData = [];
      NonworkingHourSort[i].Hours = (NonworkingHourSort[i].Tid - NonworkingHourSort[i].pauseTimer).toString();
      tempData.push(moment(NonworkingHourSort[i].date).format("YYYY-MM-DD"));
      NonworkingHourSort[i].nonWorkingDayList = tempData;
      this.AddWorkhourAPICall(NonworkingHourSort[i]);
    }
  }

  onFocusIn(event) {
    event.model.show();
  }

  calculateEndDate() {
    if (this.nonWorkHour.status == 2 || this.nonWorkHour.status == 3
      || this.nonWorkHour.status == 4 || this.nonWorkHour.status == 5 || this.nonWorkHour.status == 8) {
      this.createEventObj.endDate = this.createEventObj.startDate;
    }
    else {
      let tempStartDate: Date = new Date(moment(this.createEventObj.startDate).startOf('isoWeek').format("YYYY-MM-DD"));
      const endDate: Date = (this.nonWorkHour.status == 6 || this.nonWorkHour.status == 7 || this.nonWorkHour.status == 9) ? this.createEventObj.startDate : addDays(new Date(tempStartDate.getTime()), 4);
      this.createEventObj.endDate = endDate;
    }
    if (this.nonWorkHour.status == 2 || this.nonWorkHour.status == 4 || this.nonWorkHour.status == 5) {
      var days = moment(this.createEventObj.startDate).day();
      if (days == 5)
        this.createEventObj.shift = 2;
      else
        this.createEventObj.shift = 3;
    }
    this.calEndDate();
  }

  onChange(args) {
    if (args.value) {
      if (args.value.getDay() == 0 || args.value.getDay() == 6) {
        var days = args.value.getDay() == 0 ? 1 : 2;
        this.createEventObj.startDate = new Date(args.value.setDate(args.value.getDate() + days));
      }
      else
        this.createEventObj.startDate = args.value;
      this.calculateEndDate();
    }
  }

  onChangeEndDate(args) {
    this.createEventObj.endDate = args.value;
    this.calEndDate();
  }

  calEndDate() {
    this.nonWorkHour.nonWorkingDayList = [];
    var endDate = new Date(new Date(this.createEventObj.endDate).getFullYear(),
      new Date(this.createEventObj.endDate).getMonth(), new Date(this.createEventObj.endDate).getDate(), 0, 0, 0, 0);

    var startDateCal = new Date(new Date(this.createEventObj.startDate).getFullYear(),
      new Date(this.createEventObj.startDate).getMonth(), new Date(this.createEventObj.startDate).getDate(), 0, 0, 0, 0);

    for (let day = startDateCal; day <= endDate; day.setDate(day.getDate() + 1)) {
      var tempDate = moment(day).format("DD.MM.YYYY");
      var getHoliday = [];//this.holidayList.find(o => moment(o.date).format("DD.MM.YYYY") == tempDate);
      if (day.getDay() == 0 || day.getDay() == 6) {
        continue;
      }
      else if (!getHoliday || getHoliday.length == 0) {
        this.nonWorkHour.nonWorkingDayList.push(moment(day).format("YYYY-MM-DD"));
      }
    }
    this.createEventObj.endDate = endDate;
    this.automaticSelectShifttime();
  }

  onSelectStatus() {
    if (this.nonWorkHour.status == 8)
      this.nonWorkHour.paymentOption = 1;
    else
      this.nonWorkHour.paymentOption = null;

    if (this.nonWorkHour.status == 2 || this.nonWorkHour.status == 8) 
      this.nonWorkHour.leaveStatus = 2;
    this.addBreaktime()
  }

  coworkerLeaveApplyAsApproved() {
    var loginCoworkerId = this.authService.getCoWorkerId();
    this.coWorkersService.GetSingle<ResponseBase>(loginCoworkerId)
      .subscribe({
        next: (response: ResponseBase) => {
          this.coworkerObj = response.result;
          if (this.hasLeaveApprovePermision)
            this.nonWorkHour.leaveStatus = 2;
        }
      });
  }

  automaticSelectShifttime(){
    if (!this.ShowManualHours) {
      var weekDay = new Date(moment(this.createEventObj.startDate).format("YYYY-MM-DD")).getDay();
      if (this.createEventObj.startDate && this.createEventObj.endDate && weekDay == 5)
        this.AddByShiftTime('7.00', '11.30', '12.00', '14.30', 2);
      else
        this.AddByShiftTime('7.00', '11.30', '12.00', '15.00', 3);
    }
  }

  getLastClosedPaymentDate() {
    this.showSpinner = true;
    this.workhoursService.getLastClosedPaymentDate<ResponseBase>()
        .subscribe((response: ResponseBase) => {
            if (response.result){ 
                this.closedPaymentDate = response.result;
                this.minDate = moment(this.closedPaymentDate).add(1, 'days').format('YYYY-MM-DD');
            }else{
                this.closedPaymentDate = null;
                this.minDate = moment(this.closedPaymentDate).add(1, 'days').format('YYYY-MM-DD');

        }},
            error => (err) => {
                this.showSpinner = false;
            },
            () => {
                this.showSpinner = false;
            });
}

  checkMoreThan7HoursOnFriday(workHour: WorkHour) {
    if (workHour.nonWorkingDayList.length == 1 && new Date(workHour.nonWorkingDayList[0]).getDay() == 5 && (workHour.Tid - workHour.pauseTimer) > 7) {
      const dialogRef = this.dialog.open(AlertMessageModelComponent, {
        height: '200px',
        data: {
          Header: 'Headers.MoreThan7Hours',
          ShowOKButton: false
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.AddWorkhourAPICall(workHour);
        }
        else
          return;
      });
    }
    else
      this.AddWorkhourAPICall(workHour);
  }

  addBreaktime() {
    this.isAddBreakTime = false;
    if (this.nonWorkHour.fromStartHour < "11" || (this.nonWorkHour.fromStartHour == "11" && this.nonWorkHour.fromStartMinute < "30")) {
      if (this.nonWorkHour.fromEndHour > "12 " || (this.nonWorkHour.fromEndHour == "12" && this.nonWorkHour.fromEndMinute > "30")) {
        this.isAddBreakTime = true;
      }
    }
    else {
      this.isAddBreakTime = false;
    }
  }
}
