import { Component, OnInit, OnDestroy, Output, EventEmitter, Input, ChangeDetectorRef } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { FormGroup, FormBuilder, Validators, NgForm, FormArray } from '@angular/forms';
import { QuestionTypeCategory } from 'app/main/model/Project';
import { TranslateService } from '@ngx-translate/core';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { ProjectofferService } from '../../pages/project-offer/project-offer.service';
import { NotificationsService } from 'angular2-notifications';
import { ProjectQuestionDTO, QuestionParms } from 'app/main/model/Project';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { FormService } from 'app/core/services/form.service';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';
import { MatDialog } from '@angular/material';
import { AuthService } from 'app/core/services/auth.service';
import { Subscription } from 'rxjs';
import { ProjectService } from 'app/main/pages/project/project.service';
import { OfferRejectedQuestionDTO } from 'app/main/model/OfferV2';

@Component({
    selector: "vibe-add-questions",
    templateUrl: "./add-questions.component.html",
    styleUrls: ["./add-questions.component.scss"]
})

export class AddQuestionsComponent implements OnInit {
    @Output("getQuestionList") getQuestionList1: EventEmitter<any> = new EventEmitter();
    @Output("onClickCancelBtn") onClickCancelBtn: EventEmitter<any> = new EventEmitter();
    @Input() QuestionList = [];
    //@Output() AddOrUpdateQuestionSave = new EventEmitter<ProjectQuestionDTO>();
    @Output("AddOrUpdateQuestionSave") AddOrUpdateQuestionSave: EventEmitter<any> = new EventEmitter();
    @Output("getQuestionList") getQuestionList: EventEmitter<any> = new EventEmitter();

    @Input() ModuleName: string = "";
    showSpinner: boolean = false;
    currentObject = new ProjectQuestionDTO();
    currentObjectForOffer = new OfferRejectedQuestionDTO();
    questionAnswerList = [];
    isSaveData: boolean = false;
    questionId: string = "";
    favoriteSeason: boolean = true;
    isEditQuestion: boolean = false;
    ratingArrayList = [];
    IsExpand: boolean = false;

    offerQuestionform: FormGroup;
    typeList = QuestionTypeCategory;
    type: number = 1;
    questionLists = [];
    isAddNew: boolean = false;
    isCheckListDataExist: boolean = false;
    subscriptionForEdit: Subscription;
    subscriptionForDelete: Subscription;
    subscriptionForStatusData: Subscription;
    checkboxQuestionForm: FormGroup;

    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private formBuilder: FormBuilder,
        private projectofferService: ProjectofferService,
        private translate: TranslateService,
        private notificationsService: NotificationsService,
        private formService: FormService,
        private dialog: MatDialog,
        private authService: AuthService,
        private cdr: ChangeDetectorRef,
        private projectService: ProjectService) {
        this.translationLoaderService.loadTranslations(danish, english);
    }
    ngOnInit() {
        if (this.ModuleName == "Offer") {
            this.currentObject = new OfferRejectedQuestionDTO();
        }
        this.questionId = "";
        this.questionAnswerList = this.QuestionList;
        //this.getQuestionList();
        this.offerQuestionform = this.formBuilder.group({
            question: [null, [Validators.required]],
            choiceQuestionOptionsList: new FormArray([])
        });
        //this.checkboxQuestionForm = this.formBuilder.group({
        //    //question: [null]
        //    choiceQuestionOptionsList: new FormArray([])
        //})

        //this.questionLists.push({
        //    name: this.currentObject.question,
        //    type: "Textbox",
        //    choiceOptions: []
        //});
    }

    ngAfterViewChecked() {
        //your code to update the model
        this.cdr.detectChanges();
    }

    get f() { return this.checkboxQuestionForm.controls; }
    get t() { return this.f.choiceQuestionOptionsList as FormArray; }

    edit(id) {
        this.isEditQuestion = true;
        this.questionId = id;
        this.isAddNew = true;
        this.currentObject = this.questionAnswerList.find(o => o.id == id);
        this.checkboxQuestionForm = this.formBuilder.group({
            choiceQuestionOptionsList: new FormArray([])
        });
        for (let index = 0; index < this.currentObject.choiceOptions.length; index++) {
            this.t.push(this.formBuilder.group({
                id: [0],
                name: [null],
                checked: [false],
                answerType: [0],
                question: [null],
                answer: [null]
            }));
        }
        this.t.patchValue(this.currentObject.choiceOptions);
    }

    delete(item, id) {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: "200px",
            data: { Header: 'Headers.DeleteQuestion' }
        })

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.questionAnswerList.splice(this.questionAnswerList.indexOf(item), 1)
                this.questionAnswerList.filter(o => {
                    id = id + 1;
                    o.id = id;
                })
                this.AddOrUpdateQuestionSave.emit(this.questionAnswerList);
            }
        });

    }

    AddQuestionToList() {
        this.formService.markFormGroupTouched(this.offerQuestionform);
        this.checkboxQuestionForm.markAllAsTouched();
        if (this.checkboxQuestionForm.invalid) {
            return;
        }
        var isSave = false
        isSave = this.offerQuestionform.valid;
        this.currentObject.choiceOptions = this.t.value;
        this.CheckListExist();
        if (isSave && this.isCheckListDataExist && this.ModuleName != "Offer") {
            this.questionAnswerList.push(this.currentObject);
            var id = 0;
            this.questionAnswerList.filter(o => {
                id = id + 1;
                o.id = id;
            })
            this.isEditQuestion = false;
            this.isAddNew = false;
            this.checkboxQuestionForm = this.formBuilder.group({
                choiceQuestionOptionsList: new FormArray([])
            });
        }
        if (this.ModuleName == "Offer") {
            //this.AddUpdateOfferQuestion.emit(this.currentObject);
            this.AddUpdateOfferQuestion(this.currentObject);
        }
    }

    AddNewQuestion() {
        this.isAddNew = true;
        this.currentObject = new ProjectQuestionDTO();
    }

    SaveQuestion(flag) {
        this.isAddNew = !flag;
        this.AddOrUpdateQuestionSave.emit(this.QuestionList);
    }

    CheckListExist() {
        if (this.currentObject.choiceOptions && this.currentObject.choiceOptions.length == 0 && this.currentObject.questionType != 'Textbox' && this.currentObject.questionType != "Rating") {
            this.isCheckListDataExist = false;
            const dialogRef = this.dialog.open(AlertMessageModelComponent, {
                height: "200px",
                data: {
                    Header: "MESSAGES.AddTypeList",
                    ShowOKButton: true
                }
            });
            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    return;
                }
            });
        } else {
            this.isCheckListDataExist = true;
        }
        // if (this.currentObject.questionType != 'Textbox') {
        //     this.currentObject.choiceOptions = JSON.stringify(this.questionLists[0].choiceOptions);
        // }
    }

    onSelectType(questionType) {
        this.questionLists = [];
        this.offerQuestionform = this.formBuilder.group({
            choiceQuestionOptionsList: new FormArray([])
        });
        if (this.currentObject.questionType == 'MultiChoice') {
            this.questionLists.push({
                name: "",
                type: "MultiChoice",
                choiceOptions: [],
                answerType: 0,
                question: "",
                answer: ""
            });
        } else if (this.currentObject.questionType == 'SingleChoice') {
            this.questionLists.push({
                name: "",
                type: "SingleChoice",
                choiceOptions: []
            });
        } else if (this.currentObject.questionType == 'Rating') {
            this.questionLists.push({
                name: "",
                type: "Rating",
                rating: 5
            });
        } else {
            this.questionLists.push({
                name: this.currentObject.question,
                type: "Textbox",
                choiceOptions: []
            });
        }
    }

    //Back to question list
    goToQuestionList() {
        this.questionLists = [];
        this.questionLists.push({
            name: "",
            type: "Textbox",
            choiceOptions: []
        });
        this.currentObject = new ProjectQuestionDTO();
        this.isAddNew = false;
    }

    //Checkbox
    addCheckItem(form: NgForm, checkList): void {
        const checkItemVal = form.value.checkItem;
        if (!checkItemVal || checkItemVal === '') {
            return;
        }
        if (!checkList.choiceOptions) {
            checkList.choiceOptions = [];
        }
        const newCheckItem = {
            id: checkList.choiceOptions.length + 1,
            name: checkItemVal,
            checked: false,
            answerType: 0,
            question: "",
            answer: ""
        };
        checkList.choiceOptions = this.t.value;
        checkList.choiceOptions.push(newCheckItem);
        let id = 0;
        checkList.choiceOptions.filter(o => {
            id = id + 1;
            o.id = id;
        })
        form.setValue({ checkItem: '' });
        this.checkboxQuestionForm = this.formBuilder.group({
            choiceQuestionOptionsList: new FormArray([])
        });
        for (let index = 0; index < checkList.choiceOptions.length; index++) {
            this.t.push(this.formBuilder.group({
                id: [0],
                name: [null],
                checked: [false],
                answerType: [0],
                question: [null],
                answer: [null]
            }));
        }
        this.t.patchValue(checkList.choiceOptions);
    }

    removeChecklistItem(checkItem, checklist, index): void {
        this.t.removeAt(index);
        //checklist.choiceOptions.splice(checklist.choiceOptions.indexOf(checkItem), 1);
    }

    onSelectRadioBtn(checklist, i) {
        this.t.value.forEach(item => {
            item.checked = false;
        });
        this.t.value[i].checked = true;
    }

    getSelectedRating(checklist) {
        if (checklist.rating) {
            this.currentObject.rating = checklist.rating;
        }
    }

    //#region Offer related code

    AddText(checkItem, i) {
        let taskListArrays = this.checkboxQuestionForm.get('choiceQuestionOptionsList') as FormArray;
        taskListArrays.controls[i].patchValue({ "answerType": 1 });
        const validateQuestionField = taskListArrays.controls[i].get('question');
        validateQuestionField.setValidators([Validators.required]);
        validateQuestionField.updateValueAndValidity();
        // this.currentObject
        // checkItem[i].answerType = 1;
        //this.AddvalidationToQuestion();
    }

    RemoveText(checkItem, i) {
        let taskListArrays = this.checkboxQuestionForm.get('choiceQuestionOptionsList') as FormArray;
        taskListArrays.controls[i].patchValue({ "answerType": 0 });
        taskListArrays.controls[i].patchValue({ "question": "" });
        const validateQuestionField = taskListArrays.controls[i].get('question');
        validateQuestionField.setValidators(null);
        validateQuestionField.updateValueAndValidity();
        // this.questionLists[i];
        // checkItem[i].question = "";
        // checkItem[i].answerType = 0;
        //this.removeQuestionValidation(i);

    }

    AddUpdateOfferQuestion(event) {
        this.currentObjectForOffer = new OfferRejectedQuestionDTO();
        this.currentObjectForOffer = Object.assign({}, this.currentObject);
        if (this.currentObject.questionType != "Textbox") {
            this.currentObjectForOffer.choiceOptions = JSON.stringify(this.currentObject.choiceOptions);
        }
        if (this.currentObjectForOffer.id) {
            this.Update()
        } else {
            this.Add()
        }
    }


    Add() {
        this.showSpinner = true;
        this.isSaveData = true;

        //this.currentObject.questionType = this.questionLists[0].type;
        this.projectofferService.AddQuestion(this.currentObjectForOffer).subscribe({
            next: (response: ResponseBase) => {
                this.currentObject = new ProjectQuestionDTO();
                this.questionLists = [];
                if (this.questionLists.length == 0) {
                    this.questionLists.push({
                        name: this.currentObject.question,
                        type: "Textbox",
                        checkItems: []
                    });
                }
                // this.type = 'Textbox';
                this.isEditQuestion = false;
                this.isAddNew = false;
                this.getQuestionList.emit();
                this.showSpinner = false;
                this.isSaveData = false;
                this.translate.get("MESSAGES.Created").subscribe({
                    next: (res) => {
                        this.notificationsService.success(res);
                    },
                    error: err => {
                        this.showSpinner = false;
                        this.isSaveData = false;
                    }
                });
            },
            error: err => {
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                        this.isSaveData = false;
                        this.showSpinner = false;
                    },
                    error: err => {
                    }
                });
            }
        });
    }

    Update() {
        this.showSpinner = true;
        this.isSaveData = true;
        //var Id = this.currentObjectForOffer.id;
        //var json = JSON.stringify(this.currentObjectForOffer.choiceOptions);
        this.projectofferService.UpdateQuestion(this.currentObjectForOffer.id, this.currentObjectForOffer).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                // this.isAddNew = false;
                // this.isSaveData = false;
                this.currentObject = new ProjectQuestionDTO();
                this.questionLists = [];
                if (this.questionLists.length == 0) {
                    this.questionLists.push({
                        name: this.currentObject.question,
                        type: "Textbox",
                        checkItems: []
                    });
                }
                // this.type = "Textbox";
                this.getQuestionList.emit();
                this.isEditQuestion = false;
                this.isAddNew = false;
                this.currentObjectForOffer = new OfferRejectedQuestionDTO();
                this.translate.get("MESSAGES.Updated").subscribe({
                    next: (res) => {
                        this.notificationsService.success(res);
                        this.isSaveData = false;
                        this.showSpinner = false;
                    },
                    error: err => {
                    }
                });
            },
            error: err => {
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                        this.showSpinner = false;
                        this.isSaveData = false;
                    },
                    error: err => {
                    }
                });
            }
        });
    }

    editOfferData(id) {
        this.showSpinner = true;
        this.isSaveData = true;
        this.projectofferService.GetSingleOfferQuestion(id).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                this.isAddNew = true;
                this.isSaveData = false;
                this.currentObjectForOffer = new OfferRejectedQuestionDTO();
                this.currentObjectForOffer = response.result;
                if (this.questionLists.length == 0) {
                    this.questionLists.push({
                        name: this.currentObjectForOffer.question,
                        type: "Textbox",
                        checkItems: []
                    });
                }
                this.questionLists[0].type = this.currentObjectForOffer.questionType;
                if (this.currentObjectForOffer.questionType && this.currentObjectForOffer.questionType != "Textbox") {
                    this.currentObjectForOffer.questionType == "MultiChoice" ? 'MultiChoice' : 'SingleChoice'
                    //this.currentObject.choiceOptions = JSON.parse(this.currentObjectForOffer.choiceOptions);
                    if (this.currentObjectForOffer.choiceOptions) {
                        this.currentObject.choiceOptions = JSON.parse(this.currentObjectForOffer.choiceOptions);
                    }
                } else {
                    this.currentObjectForOffer.questionType = 'Textbox'
                }
                this.currentObject = this.currentObjectForOffer;
                this.currentObject.choiceOptions = JSON.parse(this.currentObjectForOffer.choiceOptions);
            },
            error: err => {
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                        this.isSaveData = false;
                        this.showSpinner = false;
                    },
                    error: err => {
                        this.showSpinner = false;
                    }
                });
            }
        });
    }

    deleteOfferData(id) {
        this.showSpinner = true;
        this.isSaveData = true;
        this.projectofferService.DeleteQuestion(id).subscribe({
            next: (response: ResponseBase) => {
                this.getQuestionList.emit();
                this.isAddNew = false;
                this.translate.get("MESSAGES.Deleted").subscribe({
                    next: (res) => {
                        this.questionLists = [];
                        if (this.questionLists.length == 0) {
                            this.questionLists.push({
                                name: this.currentObject.question,
                                type: "Textbox",
                                checkItems: []
                            });
                        }
                        this.currentObject.questionType = 'Textbox';
                        this.notificationsService.create(res);
                        this.showSpinner = false;
                        this.isSaveData = false;
                    },
                    error: err => {
                    }
                });
            },
            error: err => { this.showSpinner = false; this.isSaveData = false; },
            complete: () => { this.showSpinner = false; this.isSaveData = false; }
        })
    }

    AddvalidationToQuestion() {
        // for (let i = 0; i < this.currentObject.choiceOptions.length; i++) {
        //     if (this.currentObject.questionType == 'MultiChoice' && this.currentObject.choiceOptions[i].answerType == 1) {
        //         const checkboxQuestionControl = this.checkboxQuestionForm.controls.choiceQuestionOptionsList.get('question');
        //         // this.checkboxQuestionForm.controls.choiceQuestionOptionsList.setValidators(Validators.required);
        //         // this.checkboxQuestionForm.controls.choiceQuestionOptionsList.updateValueAndValidity();
        //          checkboxQuestionControl.setValidators([Validators.required]);
        //          checkboxQuestionControl.updateValueAndValidity();
        //         this.formService.markFormGroupTouched(this.checkboxQuestionForm);
        //     }
        // }
    }

    removeQuestionValidation(index) {
        for (let i = 0; i < this.currentObject.choiceOptions.length; i++) {
            if (this.currentObject.questionType == 'MultiChoice' && this.currentObject.choiceOptions[index].answerType == 0) {
                const checkboxQuestionControl = this.checkboxQuestionForm.get('question');
                checkboxQuestionControl.setValidators(null);
                checkboxQuestionControl.updateValueAndValidity();
                this.formService.markFormGroupTouched(this.checkboxQuestionForm);
            }
        }
    }
    //#endregion

    ngAfterViewInit() {
        this.subscriptionForEdit = this.authService.editSingleQuestionChangeObs.subscribe((data) => {
            if (data) {
                this.editOfferData(data);
                //this.cdr.detectChanges();
            }
        });

        this.subscriptionForDelete = this.authService.deleteSingleQuestionChangeObs.subscribe((data) => {
            if (data) {
                this.deleteOfferData(data);
                //this.cdr.detectChanges();
            }
        });

        this.subscriptionForStatusData = this.projectService.onStausSelectChangeObs.subscribe((data) => {
            if (data) {
                var QuestionData = data;
                this.questionAnswerList = QuestionData;
            }
        })

    }
    ngOnDestroy() {
        this.authService.editSingleQuestionChange.next("");
        this.authService.deleteSingleQuestionChange.next("");
        this.projectService.onProjectStatusChange.next("");
    }
}