export const locale = {
    lang: 'en',
    data: {
        'BUTTONS': {
            'BTNSAVE': 'Save',
            'Back': 'Cancel'
        },
        'FIELDS': {
            'SrNo': 'Sr no.',
            'Date': 'Date',
            'SupplierName': 'Supplier name',
            'MaterialCount': 'Material Count',
            'Amount': 'Amount',
            'Status': 'Status',
            'Email': 'E-mail',
            'Attachment': 'Attachment',

            'Material': 'Material',
            'RetailPrice': 'Retail Price',
            'PersonalPrice': 'Personal Price',
            'PersonalTotalPrice': 'Personal Total',
            'Quantity': 'Quantity',
            'ProductNo': 'Product',
            'OrderQuantity': 'Order Antal',
            'MinQuantity': 'Min Quantity',
            'MaxQuantity': 'Max Quantity',
            'Unit': 'Unit',
            'PurchasePrice': 'Purchase price',
            'TotalPurchasePrice': 'Total Purchase Price',
            'Total': 'Total',
            'Inventory': 'Inventory',
            'MinInventory': 'Min Inventory',
            'MaxInventory': 'Max Inventory',
            'PurchaseQuantity': 'Purchase Quantity',
            'ShowAll': 'Show All',
            'ColorCode': 'Color code',
            'ReceivedDate': 'Received Date',
            'ProjectNumber':'Project Number',
            'RequisitionNo': 'Requisition No',
        },
        'MESSAGES': {
            'Updated': 'Updated Successfully.',
            'Failed': 'Failed',
        },
        "SupplierList": {
            'value1': 'Ordered',
            'value2': 'Received',
            'value3': 'Partially Received',
            'value4': 'Delete',
        },
        "Headers": {
            'NORECORD': 'No record found'
        }
    }
}