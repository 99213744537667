import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { HttpParams, HttpClient } from '@angular/common/http';
import { ProjectsOfferRoom, OfferCategory, OfferCategoryAndWorkItem, OfferAddressModel, OffersMaterialDocument, OfferRoom } from '../../model/ProjectOffer';
import { ApiConstant } from 'app/core/constant/ApiConstant';
import { AuthService } from 'app/core/services/auth.service';
import { RequestOfferConvert, RequestProjectOfferRoom } from 'app/main/model/RequestBase';
import { CompanyInfoSetting } from 'app/main/model/CompanyInfo';
import { EmailSendingParamForOffer } from 'app/main/model/common';
import { PaintType } from 'app/constant/common.constant';


@Injectable({
    providedIn: 'root'
})
export class ProjectofferService implements Resolve<any>
{
    projects: any[];
    pfferProject: any;
    ObjProject: any;
    // MaterialList: any;
    private actionUrl: string;
    private actionUrlv2: string;
    private offertaskActionUrl: string;
    private offertaskActionUrlV2: string
    // private actionUrlForCoworkerProject: string;
    // private currentOfferId: string;
    private settingsActionUrl: string;
    constructor(private http: HttpClient, private authService: AuthService) {
        this.actionUrl = ApiConstant.API_URL + ApiConstant.PROJECTOFFER + "/";
        this.actionUrlv2 = ApiConstant.API_URL + ApiConstant.PROJECTOFFERV2;
        this.offertaskActionUrl = ApiConstant.API_URL + ApiConstant.PROJECTOFFERTASK + "/";
        this.offertaskActionUrlV2 = ApiConstant.API_URL + ApiConstant.PROJECTOFFERTASKV2;
        this.settingsActionUrl = ApiConstant.API_URL + ApiConstant.SETTINGS + "/";
    }

    public Add<T>(projectOffer: OfferAddressModel): Observable<T> {
        return this.http.post<T>(this.actionUrlv2, projectOffer);
    }

    public Update<T>(id: any, projectOffer: OfferAddressModel): Observable<T> {
        return this.http.put<T>(this.actionUrlv2 + '/' + id, projectOffer);
    }


    public Get<T>(RequestProject: any): Observable<T> {
        let search = this.GetParams(RequestProject);
        let coWorkerId = this.authService.getCoWorkerId();
        return this.http.get<T>(this.actionUrlv2, {
            params: search
        });
    }

    public GetProjectListForOffer<T>(RequestProject: any): Observable<T> {
        let search = this.GetParams(RequestProject);
        return this.http.get<T>(this.actionUrl + "Projects/", {
            params: search
        });
    }

    // public ConvertOfferToProject<T>(id: string): Observable<T> {
    //     return this.http.get<T>(this.actionUrlv2 + '/convertoffertoproject/' + id);
    // }

    public ConvertOfferToProject<T>(requestOfferConvert: RequestOfferConvert): Observable<T> {
        let search = this.GetParams(requestOfferConvert);
        return this.http.get<T>(this.actionUrlv2 + "/convertOffertoProject", {
            params: search
        });
    }

    public GetSingle<T>(id: string): Observable<T> {
        return this.http.get<T>(this.actionUrlv2 + '/' + id);
    }

    public GetOfferWithFullDetails<T>(id: string): Observable<T> {
        return this.http.get<T>(this.actionUrlv2 + '/getwithfulldetails?Id=' + id);
    }

    public DeleteProjectOffer<T>(id: string): Observable<T> {
        return this.http.delete<T>(this.offertaskActionUrlV2 + '/offer/' + id); //Delete All offer
    }

    public SendOfferToCustomer<T>(id: string, email: string, isaddroomdetail: false, isIncludePriceOnPDF: boolean) {
        return this.http.get<T>(this.offertaskActionUrlV2 + "/sendofferinvoice/" + id + "/" + email + "/" + isaddroomdetail + "/" + isIncludePriceOnPDF);
    }
    public SendOfferWithAttachmentToCustomer<T>(request: EmailSendingParamForOffer) {
        let search = this.GetParams(request);
        return this.http.get<T>(this.offertaskActionUrlV2 + "/sendemailwithinvoice", {
            params: search
        });
    }
    public SendEmailInvoiceWithAttachmentToCustomer<T>(request: EmailSendingParamForOffer) {
        return this.http.post<T>(this.offertaskActionUrlV2 + "/sendEmailInvoiceWithAttachment", request);
    }

    public GetOfferMaterialFiles<T>(request: OffersMaterialDocument) {
        let search = this.GetParams(request);
        return this.http.get<T>(this.offertaskActionUrlV2 + "/OffersMaterialDocument", {
            params: search
        });
    }

    public GetOfferAllPDF<T>(id: string, isaddroomdetail: false, isIncludePriceOnPDF: boolean, offerIdsList: string): Observable<T> {
        return this.http.get<T>(this.offertaskActionUrlV2 + "/offerinvoice/" + id + "/" + isaddroomdetail + "/" + isIncludePriceOnPDF + "/" + offerIdsList);
    }

    public GetOfferPDF<T>(id: string, offerTypeId: string, flag: boolean, isIncludePriceOnPDF: boolean) {
        return this.http.get<T>(this.offertaskActionUrlV2 + "/singleofferinvoice/" + id + "/" + offerTypeId + "/" + flag + "/" + isIncludePriceOnPDF);
    }

    public GetSingleOfferPDF<T>(id: string, offerTypeId: string, flag: boolean, isIncludePriceOnPDF: boolean, isShowRoomTotal: boolean) {
        return this.http.get<T>(this.offertaskActionUrlV2 + "/getSingleOfferInvoice/" + id + "/" + offerTypeId + "/" + flag + "/" + isIncludePriceOnPDF + "/" + isShowRoomTotal);
    }

    public GetOfferPDFbyIds<T>(OfferInvoiceDetails: any): Observable<T> {
        let par = this.GetParams(OfferInvoiceDetails);
        return this.http.get<T>(this.offertaskActionUrlV2 + "/offerinvoices", {
            params: par
        });
    }

    public getProjectOfferQuestionAnswer<T>(offerId: string): Observable<T> {
        return this.http.get<T>(this.offertaskActionUrlV2 + "/offerquestion/rejectedanswer/" + offerId);
    }

    public AddProjectOffer<T>(projectsOfferRoom: OfferRoom[]): Observable<T> {
        return this.http.post<T>(this.actionUrlv2 + "/offerRoomsList/", projectsOfferRoom);
    }

    public getSingleProjectOffer<T>(id: string): Observable<T> {
        return this.http.get<T>(this.actionUrlv2 + "/offerRooms/" + id);
    }

    public getSingleOfferCategory<T>(id: string): Observable<T> {
        return this.http.get<T>(this.offertaskActionUrl + id);
    }

    public UpdateProjectOffer<T>(id: any, projectsOfferRoom: ProjectsOfferRoom): Observable<T> {
        return this.http.put<T>(this.actionUrlv2 + "/offerRooms/" + id, projectsOfferRoom);
    }

    public deleteProjectOfferRoom<T>(offerId: string, id: string): Observable<T> {
        return this.http.delete<T>(this.actionUrlv2 + "/offerRooms/" + offerId + '/' + id);
    }

    public deleteProjectOfferItemGroup<T>(id: string): Observable<T> {
        return this.http.delete<T>(this.offertaskActionUrlV2 + "/offertask/" + id);
    }

    public deleteDiverseOfferItem<T>(id: string, offerTypeId: string, priceTotal: number): Observable<T> {
        return this.http.delete<T>(this.offertaskActionUrlV2 + "/offerDiverseItem/" + id + "?offerTypeId=" + offerTypeId + "&priceTotal=" + priceTotal);
    }
    public deleteMaterialItem<T>(id: string): Observable<T> {
        return this.http.delete<T>(this.offertaskActionUrlV2 + "/deleteOfferMaterialItem/" + id);
    }

    public getProjectOfferRoomList<T>(requestProjectOfferRoom: RequestProjectOfferRoom): Observable<T> {
        //return this.http.get<T>(this.requestProjectOfferRoom);
        let search = this.GetParams(requestProjectOfferRoom);
        return this.http.get<T>(this.actionUrlv2 + "/offerRooms", {
            params: search
        });
    }

    public GetOfferCategories(): Observable<any> {
        return this.http.get<any>(this.offertaskActionUrl);
    }

    public GetOfferCategoryWithMaterialList(): Observable<any> {
        return this.http.get<any>(this.offertaskActionUrl + "GetOfferCategoryWithMaterialList");
    }

    public SaveOfferItem(offer): Observable<any> {
        return this.http.post<any>(this.offertaskActionUrlV2, offer);
    }
    public SaveOfferItems(offer): Observable<any> {
        return this.http.post<any>(this.offertaskActionUrlV2 + "/CreateOrUpdateOfferWithMateriaList", offer);
    }
    public SaveOfferItemsList(offer): Observable<any> {
        return this.http.post<any>(this.offertaskActionUrlV2 + "/CreateOrUpdateOfferList", offer);
    }

    public SaveOfferItemV2(offer): Observable<any> {
        return this.http.post<any>(this.offertaskActionUrlV2 + "/saveOfferItem/", offer);
    }

    public UpdateOfferItem(id, offer): Observable<any> {
        return this.http.put<any>(this.offertaskActionUrl + "updateofferitem/" + id, offer);
    }

    public updateOfferItemV2(offer): Observable<any> {
        return this.http.put<any>(this.offertaskActionUrlV2 + "/updateofferitem", offer);
    }

    public GetOfferCategoryWorkItem<T>(id): Observable<T> {
        return this.http.get<T>(this.offertaskActionUrl + "workitem/" + id);
    }

    public GetOfferItem(id): Observable<any> {
        return this.http.get<any>(this.offertaskActionUrl + "offeritem/" + id);
    }

    public Getofferitemgroup<T>(currentOfferId): Observable<T> {
        return this.http.get<T>(this.offertaskActionUrl + "offeritemgroup/" + currentOfferId);
    }

    public GetofferTask<T>(currentOfferId): Observable<T> {
        return this.http.get<T>(this.offertaskActionUrlV2 + "/alloffertask/" + currentOfferId);
    }

    public getOfferTypeData(offerId): Observable<any> {
        return this.http.get<any>(this.offertaskActionUrlV2 + '/offertask/' + offerId);
    }

    public getOfferTypeDataWithRoomDetail(offerId, offerTypeId): Observable<any> {
        return this.http.get<any>(this.offertaskActionUrlV2 + '/alloffertaskwithmultiplematerial/' + offerId + "/" + offerTypeId);
    }

    public UpdateOfferFeature<T>(id, offerCategoryAndWorkItem: OfferCategoryAndWorkItem): Observable<T> {
        return this.http.put<T>(this.offertaskActionUrl + id, offerCategoryAndWorkItem);
    }

    public UpdateOfferCategory<T>(id, offerCategory: OfferCategory): Observable<T> {
        return this.http.put<T>(this.offertaskActionUrl + "offercategory/" + id, offerCategory);
    }
    public CreateOfferCategory<T>(offerCategory: OfferCategory): Observable<T> {
        return this.http.post<T>(this.offertaskActionUrl + "offercategory", offerCategory);
    }

    public GetOfferCategoryMaterial(): Observable<any> {
        return this.http.get<any>(ApiConstant.API_URL + 'Materials/offermaterial');
    }

    public AddQuestion(question): Observable<any> {
        return this.http.post<any>(this.offertaskActionUrlV2 + '/offerquestion', question);
    }

    public UpdateQuestion(id, question): Observable<any> {
        return this.http.put<any>(this.offertaskActionUrlV2 + '/offerquestion/' + id, question);
    }

    public GetSingleOfferQuestion(id): Observable<any> {
        return this.http.get<any>(this.offertaskActionUrlV2 + '/offerquestion/' + id);
    }

    public DeleteQuestion(id): Observable<any> {
        return this.http.delete<any>(this.offertaskActionUrlV2 + '/offerquestion/' + id);
    }

    public GetNoteDetail<T>(id: any): Observable<T> {
        return this.http.get<T>(this.offertaskActionUrl + "notedetail/" + id);
    }

    public UpdateDescription<T>(id: any, content: string): Observable<T> {
        return this.http.put<T>(this.offertaskActionUrl + "updatenotedetail/" + id, { id, content });
    }

    public GetOfferItemV2(id): Observable<any> {
        return this.http.get<any>(this.offertaskActionUrlV2 + "/offeritem/" + id);
    }

    public getDefaultMaterialList(): Observable<any> {
        return this.http.get<any>(this.offertaskActionUrlV2 + "/offerDefaultMaterial");
    }
    public DeleteTraetmentByOfferId(id): Observable<any> {
        return this.http.delete<any>(this.offertaskActionUrl + "deleteTraetmentByOfferId/" + id);
    }

    public UpdateOfferTypeText(roomData): Observable<any> {
        return this.http.put<any>(this.offertaskActionUrlV2 + "/offertype", roomData);
    }

    public DeleteOfferCategoryMaterial(id): Observable<any> {
        return this.http.delete<any>(this.offertaskActionUrl + "deleteOfferCategoryMaterial/" + id);
    }

    public GetOfferCategoryMaterialList<T>(ids): Observable<T> {
        return this.http.get<T>(this.offertaskActionUrl + "getOfferCategoryMaterialList?ids=" + ids);
    }

    public DeleteOfferCategory(id): Observable<any> {
        return this.http.delete<any>(this.offertaskActionUrl + "deleteOffercategory/" + id);
    }

    public MoveOfferCategory<T>(sourceCategoryId, destinationCategoryId): Observable<T> {
        return this.http.put<T>(this.offertaskActionUrl + "moveOfferCategory?sourceCategoryId=" + sourceCategoryId + "&destinationCategoryId="+ destinationCategoryId,null);
    }

    setBGImageForOfferProjectCards(offerProject) {
        offerProject.filter(offer => {
            if (offer.status) {
                switch (offer.status) {
                    case 1:
                        offer.backgroundSrc = "../assets/images/offerProject-card-background-images/created.png"
                        break;

                    case 2:
                        offer.backgroundSrc = "../assets/images/offerProject-card-background-images/sendtocustomer.png"
                        break;

                    case 3:
                        offer.backgroundSrc = "../assets/images/offerProject-card-background-images/approvedbycustomer.png"
                        break;

                    case 4:
                        offer.backgroundSrc = "../assets/images/offerProject-card-background-images/rejectedbycustomer.png"
                        break;

                    case 5:
                        offer.backgroundSrc = "../assets/images/offerProject-card-background-images/projectcreated.png"
                        break;

                    default:
                        break;
                }
            }
        })
    }

    getPaintType(paintType) {
        var paintTypeName = "";
        switch (paintType) {
            case PaintType.OneGange:
                paintTypeName = "1 gang";
                break;
            case PaintType.TwoGange:
                paintTypeName = "2 gange";
                break;
            case PaintType.ThreeGange:
                paintTypeName = "3 gange";
                break;
            case PaintType.FourGange:
                paintTypeName = "4 gange";
                break;
            case PaintType.FiveGange:
                paintTypeName = "5 gange";
                break;
            case PaintType.stk:
                paintTypeName = "Stk";
                break;
            case PaintType.meter:
                paintTypeName = "Meter";
                break;
            case PaintType.rulle:
                paintTypeName = "Rulle";
                break;
            case PaintType.liter:
                paintTypeName = "Liter";
                break;
            case PaintType.OneLag:
                paintTypeName = "1 Lag";
                break;
            case PaintType.TwoLag:
                paintTypeName = "2 Lag";
                break;
            case PaintType.ThreeLag:
                paintTypeName = "3 Lag";
                break;
        }
        return paintTypeName;
    }

    public GetDistancePerMinuteValue<T>(): Observable<T> {
        return this.http.get<T>(this.settingsActionUrl + "getSettings" + "?param1=OfferTravelingDistancePerMinute");
    }

    public GetOfferTermsForMoreValue<T>(): Observable<T> {
        return this.http.get<T>(this.settingsActionUrl + "getSettings" + "?param1=OfferTermsForMoreValue");
    }
    /**
    * Resolve
    * @param {ActivatedRouteSnapshot} route
    * @param {RouterStateSnapshot} state
    * @returns {Observable<any> | Promise<any> | any}
    */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    }

    //getProjectsList


    public GetParams(param) {
        let params: HttpParams = new HttpParams();
        for (var key in param) {
            if (param.hasOwnProperty(key)) {
                let val = param[key];
                params = params.append(key, val);
            }
        }
        return params;
    }

    public GetOfferProjectTabInfo<T>(id, name): Observable<T> {
        return this.http.get<T>(this.actionUrlv2 + "/" + id + "/" + name + "/OfferProjectFileInfo");
    }

    public UpdateOfferProjectExtraTabDescription<T>(id, name, info: CompanyInfoSetting): Observable<T> {
        return this.http.put<T>(this.actionUrlv2 + "/" + id + "/" + name + "/OfferProjectFileInfoTab", info);
    }

    //Log
    public GetProjectOfferLogDetails<T>(Id: any): Observable<T> {
        return this.http.get<T>(this.actionUrlv2 + "/getOfferLogDetails/" + Id);
    }

    public GetOfferApprovedCountApi<T>(): Observable<T> {
        return this.http.get<T>(this.actionUrlv2 + "/" + "GetApprovedOfferCount");
    }

    public GetOfferRoomLogDetail<T>(Id: any): Observable<T> {
        return this.http.get<T>(this.actionUrlv2 + "/getOfferRoomLogDetails/" + Id);
    }

    public getProjectPriceDiscount<T>(indoorOutdoorStatusID: string): Observable<T> {
        return this.http.get<T>(this.offertaskActionUrlV2 + "/getProjectPriceDiscount?indoorOutdoorStatusID=" + indoorOutdoorStatusID);
    }

    public GetOfferCategoryWithMaterialAndRoomList(id: any): Observable<any> {
        return this.http.get<any>(this.offertaskActionUrl + "GetOfferCategoryWithMaterialAndRoomList/" + id);
    }

    public GetAskOfferQuestionList(): Observable<any> {
        return this.http.get<any>(this.offertaskActionUrlV2 + '/AskOfferquestionList');
    }

    public OfferAddOrUpdateQuestionList(questions): Observable<any> {
        return this.http.post<any>(this.offertaskActionUrlV2 + '/offeraddorupdatequestionlist', questions);
    }

    public GetAllofferTasksById(offerId: string='', projectId: string=''): Observable<any> {
        return this.http.get<any>(this.offertaskActionUrlV2 + "/GetAllofferTasksById?offerId="+offerId+"&projectId="+projectId);
    }

    public CreateOrUpdateOfferLater(offer): Observable<any> {
        return this.http.post<any>(this.offertaskActionUrlV2 + "/CreateOrUpdateOfferLater", offer);
    }

    public CopyOfferCategroyWithMaterial(offerFeat): Observable<any> {
        return this.http.post<any>(this.actionUrlv2 + "/CopyOfferCategroyWithMaterial", offerFeat);
    }

    public GetOfferCreateLater(offerId): Observable<any> {
        return this.http.get<any>(this.offertaskActionUrlV2 + "/getOfferCreateLater/" + offerId);
    }

    
    public GetOfferCreateLaterForAllModule(requestOfferCreateLater : any): Observable<any> {
        let search = this.GetParams(requestOfferCreateLater);
        return this.http.get<any>(this.offertaskActionUrlV2 + "/getOfferCreateLaterForAllModule", {
            params: search
        });
    }

    public DeleteCreateLater(id): Observable<any> {
        return this.http.delete<any>(this.offertaskActionUrlV2 + "/deleteCreateLater/" + id);
    }

    public AddUpdateOfferEstimateMaterial(offer): Observable<any> {
        return this.http.post<any>(this.offertaskActionUrlV2 + "/AddUpdateOfferEstimateMaterial", offer);
    }

    public DeleteOfferEstimateMaterial(id): Observable<any> {
        return this.http.delete<any>(this.offertaskActionUrlV2 + "/DeleteOfferEstimateMaterial/" + id);
    }

    public GetOfferEstimateMaterialList<T>(MaterialObject: any): Observable<T> {
        let search = this.GetParams(MaterialObject);
        return this.http.get<T>(this.offertaskActionUrlV2 + "/getOfferEstimateMaterialList",{  params: search });
    }

    public HtmlToPDFConvert(requestBody): Observable<any> {
        return this.http.post<any>(this.offertaskActionUrlV2 + '/htmlToPDFConvert', requestBody);
    }
}
