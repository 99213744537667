import { Component, OnInit, ElementRef, Inject, NgZone, ViewChild, Optional } from '@angular/core';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { MaterialService } from './../material.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Material } from 'app/main/model/Material';
import { OfferMaterialWithPrice, OfferMaterialPrice } from 'app/main/model/OfferV2';
import { Supplier } from 'app/main/model/Supplier';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IsNumber } from 'app/core/Validators/validation';
import { environment } from 'environments/environment';
import { AuthService } from 'app/core/services/auth.service';
import { MaterialFile } from 'app/main/model/MaterialFile';
import { SupplierRequest } from 'app/main/model/RequestBase';
import { FileUpload } from 'app/main/model/FileUpload';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatRadioChange } from '@angular/material';
import { TitleService } from 'app/core/services/title.service';
import { DeleteAlertModalComponent } from 'app/main/pages/delete-alert-modal/delete-alert-modal.component';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';
import { FormService } from 'app/core/services/form.service';
import { CreateFolder, SearchFolder, ListFolder, FolderListNavigationDTO } from 'app/main/model/DropBox';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { ApiConstant } from 'app/core/constant/ApiConstant';
import { MaterialUnitList, MaterialLinetype, PurchasingUnitList } from 'app/main/model/ProjectStatus';
import { OfferComponent } from '../component/offer/offer.component';
import { BarcodeComponent } from '../component/barcode/barcode.component';
import { PriceTagComponent } from '../component/pricetag/pricetag.component';
import { RequestMaterial } from 'app/main/model/RequestBase';
import { MaterialWeatherType, MaterialType } from 'app/constant/common.constant';
import { CopyMaterialComponent } from '../component/copyMaterial/copyMaterial.component';
import { WrongMaterialBindToOfferPopupComponent } from '../component/wrong-material-bind-offer-popup/wrong-material-bind-offer-popup.component';
import { ProjectofferService } from 'app/main/pages/project-offer/project-offer.service';
import { DropboxFilesService } from 'app/main/shared-component/dropbox-files/dropbox-files.service';
import { DriveModuleEnum } from 'app/core/enum/drive-module.enum';
import { Location } from '@angular/common';

@Component({
    selector: 'app-new-material',
    templateUrl: './new-material.component.html',
    styleUrls: ['./new-material.component.scss']
})

export class NewMaterialComponent implements OnInit {
    options: string[] = []; //['0500', '1000', '1500', '2000'];
    @ViewChild("MaterialNumber", { static: false }) materialNumber: ElementRef;
    PictureShow: boolean = true;
    // savedata: any;
    IsDisplay: boolean = true;
    IsSupplierRequired: boolean = true;
    MaterialObject: Material;
    isLoad: boolean = false;
    isExist: boolean = false;
    materialForm: FormGroup;
    roleName: string;
    files: MaterialFile[] = [];
    selectedIndex: number = 0;
    IsEdit: boolean = false;
    showSpinner: boolean = false;
    // IsAdmin: boolean = false;
    IsUserMaler: boolean = false;
    IsAddAutomatically: boolean = false;
    NoStockFeatures: boolean = false;
    NonStockList: boolean = false;
    DisplayDelete: boolean = false;
    SupplierRequest: SupplierRequest;
    SupplierList: Supplier[] = [];
    //dropbox declaration start
    accessToken: string = ApiConstant.DrpBoxToken;
    res: any;
    materialQty: number;
    createFolder: CreateFolder;
    searchFolder: SearchFolder;
    listFolder: ListFolder
    renterForm: FormGroup;
    fileFolderPath: any = [this.dropboxFilesService.getSettingMasterValueDocument(), 'Material'];
    selectUnitList = MaterialUnitList;
    purchasingUnitList = PurchasingUnitList;
    materialId: any;
    submit: boolean = false;
    @ViewChild(OfferComponent, { static: false }) public OfferComponent: OfferComponent;
    childFormValidCheck: boolean = false;
    barcode: string;
    isEditBarcode: boolean = false
    isBarcodeExist: boolean = false;
    isViewFromOffer: boolean = false;
    isCalculateSalesPrice: boolean = false;
    selectLinetype = MaterialLinetype;
    requestMaterial: RequestMaterial = new RequestMaterial();
    Materials: Material[];
    currentId: any;
    previousId: any;
    nextId: any;
    tempMaterialId: any;
    isFile: any;
    tempIsFileAdded: boolean = false;
    colorCodeList = [];
    colorCodeDetail: any;
    FileLength: number = 0;
    fileFolderPathData: Array<FolderListNavigationDTO> = [];
    fileFolderForOfferPathData: Array<FolderListNavigationDTO> = [];
    materialWeatherType = MaterialWeatherType;
    IsItemNumberAlreadyExist: boolean = false;
    extraParamToSendOfferFile: any = [];
    isComeFromOrderTab: boolean = false;
    supplierId: any;
    selectMaterialtype = MaterialType;
    offerMaterialId: any = "";
    isShowOfferText: boolean = false;
    personalPriceIncreaseInPercentage: number = 0;
    retailPriceIncreaseInPercentage: number = 0;
    settingMasterValues: any = [];
    IsGettingSupplier: boolean = false;
    SearchedSupplierList: Supplier[] = [];
    IsRedirectToPopup: boolean = false;
    roleClaims: any;
    hasPurchasePriceViewPermision: boolean = false;
    backPageUrl: string = "";
    //dropbox declaration end

    constructor(private translationLoader: FuseTranslationLoaderService,
        private materialService: MaterialService,
        private _route: ActivatedRoute,
        private _router: Router,
        private formBuilder: FormBuilder,
        private authService: AuthService,
        public _ngZone: NgZone,
        public dialog: MatDialog,
        private titleService: TitleService,
        private notificationsService: NotificationsService,
        private translate: TranslateService,
        public formService: FormService,
        private projectofferService: ProjectofferService,
        @Optional() public dialogRef: MatDialogRef<NewMaterialComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
        private location: Location,
        private dropboxFilesService: DropboxFilesService,) {
        this.translationLoader.loadTranslations(english, danish);
        this.roleClaims = this.authService.getRoleClaimsList();
        this.PictureShow = true;
        this.titleService.SetTitle("TITLE_MATERIAL");
        window["materialComponent"] = { component: this, zone: this._ngZone };

        try {
            var tempData = this._router.getCurrentNavigation().extras.state;
            if (tempData && tempData.moduleDataList)
                this.Materials = tempData.moduleDataList;
        } catch (e) {

        }
    }

    ngOnInit() {
        this.authService.hasClaim(this.roleClaims.PurchasePrice_View).subscribe(hasClaim => {
            if (hasClaim) 
                this.hasPurchasePriceViewPermision = hasClaim
             else 
                this.hasPurchasePriceViewPermision = false;
        });
        //this._route.queryParams.subscribe(params => {
        //  this.savedata = params["savedata"];
        //})
        //this.roleName = this.authService.getUserRole();
        // if (this.roleName == "Admin") {
        //     this.savedata = true;
        //     this.IsAdmin = true;
        // }
        // else if (this.roleName == "Kontor") {
        //     this.savedata = true;
        // }
        // if (this.roleName == "Maler") {
        //     this.IsUserMaler = true;
        //     this.savedata = false;
        // }
        // else if (this.roleName == "Gaest") {
        //     this.savedata = false;
        // }
        // else {
        //     this.savedata = true;
        // }
        if (localStorage.getItem("settingMasterValues")) {
            this.settingMasterValues = JSON.parse(localStorage.getItem("settingMasterValues"));
            if (this.settingMasterValues && this.settingMasterValues.length > 0) {
                this.retailPriceIncreaseInPercentage = Number(this.settingMasterValues.find(s => s.key1 == "Material" && s.key2 == "RetailPriceMustBeHigherOnPurchasePriceInPercentage").value);
                this.personalPriceIncreaseInPercentage = Number(this.settingMasterValues.find(s => s.key1 == "Material" && s.key2 == "PersonalPriceMustBeHigherOnPurchasePriceInPercentage").value);
            }
        }

        this.SupplierRequest = new SupplierRequest();
        this.SupplierRequest.Index = 1;
        this.SupplierRequest.Limit = 100;
        this.isViewFromOffer = false;
        this.GetSupplierList();
        this.getColorCode();
        if (!this.data) {            
            this._route.params.subscribe(params => {
                var id = params['id'];
                this.materialId = id;
                this.tempMaterialId = id;
                this._route.queryParams.subscribe(params => {
                    if (params['supplierId']) {
                        this.supplierId = params['supplierId'];
                    }
                });
                if (id === undefined) {
                    var materialClass = new Material();
                    materialClass.id = '';
                    materialClass.itemNumber = '';
                    materialClass.text = null;
                    materialClass.holdings = null;
                    materialClass.image = null;
                    this.MaterialObject = materialClass;
                    this.MaterialObject.lineType = 'V'
                    this.MaterialObject.offerMaterial = new OfferMaterialWithPrice();
                    this.MaterialObject.offerMaterial.automaticallyCreate = "F";
                    this.MaterialObject.offerMaterial.offerMaterialPriceList = new Array<OfferMaterialPrice>();
                    this.isLoad = true;
                    this.IsDisplay = true;
                    //this.titleService.SetTitle("MATERIAL");
                }
                else {
                    this.DisplayDelete = true;
                    //this.titleService.SetTitle("MATERIAL");
                    this.IsDisplay = false;
                    this.Edit(id);
                    //this.GetMaterialFiles(id);
                    this._route.queryParams.subscribe(params => {
                        if (params && params.tab == 'order') {
                            this.isComeFromOrderTab = true;
                        }
                    });
                }

            });
        }
        else {
            this.IsRedirectToPopup = true;
            this.isViewFromOffer = true;
            this.DisplayDelete = true;
            this.IsDisplay = false;
            this.Edit(this.data);
        }
        this.AddValidation();
        //this.requestMaterial = this.materialService.getRequestParameter();
        if (this.Materials && this.Materials.length > 0) {
            this.getSetNextPreviousId();
        }
        //this.GetMaterial();
        this._route.queryParams.subscribe(params => {
            this.backPageUrl = params['previousUrl'] ? params['previousUrl'] : "";
        });
    }

    ngAfterViewInit() {
        setTimeout(() => {
            if (this.materialNumber)
                this.materialNumber.nativeElement.focus();
        }, 2000)
    }

    GetSupplierList() {
        this.IsGettingSupplier = true;
        this.materialService.GetSupplierList<ResponseBase>(this.SupplierRequest)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.SupplierList = response.result;

                    this.SupplierList.sort(function (a, b) {
                        return a.business.localeCompare(b.business);
                    });

                    // if (this.SupplierList.length > 0 && this.MaterialObject && this.MaterialObject.supplierId) {
                    //     const offerSupplier = this.SupplierList.find(o => o.id === this.MaterialObject.supplierId);
                    //     if (offerSupplier.business.toLowerCase() === "tilbud") {
                    //         this.MaterialObject.unit = null;
                    //     }
                    // }

                    if (this.supplierId) {
                        this.MaterialObject.supplierId = this.supplierId;
                    }
                },
                error: err => { },
                complete: () => {
                    this.IsGettingSupplier = false;
                }
            });
    }

    SupplierSearch(value) {
        if (value === '') {
            return this.SearchedSupplierList = []
        }
        this.SearchedSupplierList = this.SupplierList.filter((supplier) => {
            return supplier.business.toLowerCase().startsWith(value.toLowerCase())
        })
    }

    onKeyPressCheckNumber(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 44 && charCode != 46) {
            return false;
        }
        return true;
    }

    AddValidation() {
        this.IsSupplierRequired = true;
        this.materialForm = this.formBuilder.group({
            Text: [null, Validators.compose([Validators.required, Validators.maxLength(80)])],
            Picture: [null, !Validators.required],
            Holdings: [null, Validators.compose([Validators.required])],
            ItemNumber: [null, [Validators.required, Validators.min(0), Validators.max(99999999999999999999)]],
            RetailPrice: [null, Validators.compose([Validators.required, Validators.max(999999999999)])],
            PurchasePrice: [null, Validators.compose([Validators.required, Validators.max(999999999999)])],
            PersonalPrice: [null, Validators.compose([Validators.required, Validators.max(999999999999)])],
            PurchaseQuantity: [null, Validators.required],
            Unit: [null, Validators.required],
            InternalText: [],
            ShelfNo: [null, Validators.required],
            Rows: [],
            SupplierId: [null, Validators.required],
            SupplierMaterialNo: [null, Validators.required],
            MinInventory: [null, Validators.compose([Validators.required, Validators.min(0), Validators.max(999999999999)])],
            MaxInventory: [null, Validators.compose([Validators.required, Validators.min(0), Validators.max(999999999999)])],
            IsRequiredText: [],
            NoStockFeatures: [],
            NonStockList: [],
            ColorCode: [],
            Barcode: [],
            MaterialsForTheCase: [false],
            LineType: ['V', Validators.required],
            Reminder: [null],
            OnlySummer: [false],
            WeatherType: [null, Validators.required],
            PostNumberAppendix: [true],
            barcodeColorStr: ['1'],
            Materialtype: [null, Validators.required],
            IsDisplayWebshop: [false],
            DuplicateEntry: [false],
            CheckPurchasePrice: [false],
            PurchasepriceSalesUnit: [null],
            MaxOrderNumber: [null],
            PurchasingUnit: [null],
            IsSupplierQuickMaterial: [false],
        });
    }

    ChangeImage(event) {
        this.MaterialObject.image = event;
        // if (this.MaterialObject.id && this.savedata) {
        //     //this.Update();
        // }
    }

    isItemNumberExist(itemNumber) {
        this.materialService.GetMaterialByItemNumber(itemNumber).subscribe({
            next: (response: ResponseBase) => {
                if (response) {
                    var responseMaterial = response.result;
                    if (this.MaterialObject.id != responseMaterial.id) {
                        this.IsItemNumberAlreadyExist = true;
                        this.translate.get("MESSAGES.ItemNumberExist").subscribe({
                            next: (res) => {
                                this.notificationsService.error(res);
                            },
                        });
                    } else {
                        this.IsItemNumberAlreadyExist = false;
                    }
                }
            },
            error: err => {
                this.IsItemNumberAlreadyExist = false;
            },
        });

        // return this.materialService.IsNumberExist<any>(itemNumber).then(response => {
        //     this.IsItemNumberAlreadyExist = false;
        //     return null;
        // }).catch(err => {
        //     this.IsItemNumberAlreadyExist = true;
        //     return { 'exist': true };
        // });
    }

    save() {
        setTimeout(() => {
            if (this.MaterialObject.isAddToOffer) {
                this.OfferComponent.validateOffer();
            } else if (this.IsItemNumberAlreadyExist) {
                this.translate.get("MESSAGES.ItemNumberExist").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                });
            } else if (this.MaterialObject.checkPurchaseprice) {
                this.translate.get("MESSAGES.CheckPurchasePrice").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                });
            }
            else {
                this.saveMaterialData();
            }
        }, 1000);
        //this.PictureShow = false;
    }

    private isChildFormValid(formValid: any) {
        this.childFormValidCheck = formValid;
        if (this.childFormValidCheck) {
            this.saveMaterialData();
        }
    }


    saveMaterialData() {
        this.UpdateValidation();
        this.formService.markFormGroupTouched(this.materialForm);
        if (this.materialForm.valid) {
            if (this.MaterialObject.id === '') {
                this.Add();
            }
            else {
                this.Update();
            }
        }
    }

    Edit(id) {
        this.MaterialObject = null;
        this.IsEdit = true;
        this.materialService.GetSingle<ResponseBase>(id)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.MaterialObject = response.result; this.isLoad = true;
                    this.tempIsFileAdded = response.result.isAddedFiles;

                    if (this.MaterialObject.personalPrice == undefined || this.MaterialObject.personalPrice == null) {
                        this.MaterialObject.personalPrice = 0
                    }
                    if (this.MaterialObject.retailPrice == undefined || this.MaterialObject.retailPrice == null) {
                        this.MaterialObject.retailPrice = 0
                    }
                    if (this.MaterialObject.purchasePrice == undefined || this.MaterialObject.purchasePrice == null) {
                        this.MaterialObject.purchasePrice = 0
                    }

                    if (this.MaterialObject.offerMaterial && this.MaterialObject.offerMaterial.offerMaterialPriceList.length == 0) {
                        this.MaterialObject.isAddToOffer = false;
                    }
                    if (this.MaterialObject.holdings != undefined || this.MaterialObject.holdings != null) {
                        this.MaterialObject.holdingsString = this.MaterialObject.holdings.toString().replace('.', ',');
                    }
                    //if (this.MaterialObject.retailPrice != undefined || this.MaterialObject.retailPrice != null) {
                    //    this.MaterialObject.retailPriceString = this.MaterialObject.retailPrice.toString().replace('.', ',');
                    //}
                    //if (this.MaterialObject.purchasePrice != undefined || this.MaterialObject.purchasePrice != null) {
                    //    this.MaterialObject.purchasePriceString = this.MaterialObject.purchasePrice.toString().replace('.', ',');
                    //}
                    //if (this.MaterialObject.personalPrice != undefined || this.MaterialObject.personalPrice != null) {
                    //    this.MaterialObject.personalPriceString = this.MaterialObject.personalPrice.toString().replace('.', ',');
                    //}
                    //if (this.MaterialObject.purchaseQuantity != undefined || this.MaterialObject.purchaseQuantity != null) {
                    //    this.MaterialObject.purchaseQuantityString = this.MaterialObject.purchaseQuantity.toString().replace('.', ',');
                    //}
                    if (this.MaterialObject.minInventory != undefined || this.MaterialObject.minInventory != null) {
                        this.MaterialObject.minInventoryString = this.MaterialObject.minInventory.toString().replace('.', ',');
                    }
                    if (this.MaterialObject.maxInventory != undefined || this.MaterialObject.maxInventory != null) {
                        this.MaterialObject.maxInventoryString = this.MaterialObject.maxInventory.toString().replace('.', ',');
                    }
                    if (this.MaterialObject.automaticallyCreate == 'T') {
                        this.IsAddAutomatically = true;
                    }
                    else {
                        this.IsAddAutomatically = false;
                    }
                    if (this.MaterialObject.noStockFeatures == 'T') {
                        this.NoStockFeatures = true;
                    } else {
                        this.NoStockFeatures = false;
                    }
                    if (this.MaterialObject.nonStockList == 'T') {
                        this.NonStockList = true;
                    } else {
                        this.NonStockList = false;
                    }
                    if (this.MaterialObject.offerMaterial && this.MaterialObject.offerMaterial.offerMaterialPriceList.length > 0) {
                        if (this.MaterialObject.offerMaterial.automaticallyCreate == undefined)
                            this.MaterialObject.offerMaterial.automaticallyCreate = "F"
                    }
                    if (this.MaterialObject.materialType)
                        this.MaterialObject.materialTypeIds = this.MaterialObject.materialType.split(",").map(Number);
                    this.convertBarcodeColorToStr();
                    setTimeout(() => {
                        this.setMaterialTitle();
                    }, 500);
                    this.storeFileFolderPathData();
                    if (this.MaterialObject.isAddToOffer) {
                        this.storeFileFolderForOfferPathData();
                    }
                    if (this.MaterialObject && this.MaterialObject.offerMaterial)
                        this.offerMaterialId = this.MaterialObject.offerMaterial.id;
                },
                error: err => { },
                complete: () => { }
            });
    }

    Add() {
        if (this.MaterialObject.holdingsString != undefined || this.MaterialObject.holdingsString != null) {
            this.MaterialObject.holdings = Number(this.MaterialObject.holdingsString.toString().replace(',', '.'));
        }
        //if (this.MaterialObject.retailPriceString != undefined || this.MaterialObject.retailPriceString != null) {
        //    this.MaterialObject.retailPrice = Number(this.MaterialObject.retailPriceString.toString().replace(',', '.'));
        //}
        //if (this.MaterialObject.purchasePriceString != undefined || this.MaterialObject.purchasePriceString != null) {
        //    this.MaterialObject.purchasePrice = Number(this.MaterialObject.purchasePriceString.toString().replace(',', '.'));
        //}
        //if (this.MaterialObject.personalPriceString != undefined || this.MaterialObject.personalPriceString != null) {
        //    this.MaterialObject.personalPrice = Number(this.MaterialObject.personalPriceString.toString().replace(',', '.'));
        //}
        //if (this.MaterialObject.purchaseQuantityString != undefined || this.MaterialObject.purchaseQuantityString != null) {
        //    this.MaterialObject.purchaseQuantity = Number(this.MaterialObject.purchaseQuantityString.toString().replace(',', '.'));
        //}
        if (this.MaterialObject.minInventoryString != undefined || this.MaterialObject.minInventoryString != null) {
            this.MaterialObject.minInventory = Number(this.MaterialObject.minInventoryString.toString().replace(',', '.'));
        }
        if (this.MaterialObject.maxInventoryString != undefined || this.MaterialObject.maxInventoryString != null) {
            this.MaterialObject.maxInventory = Number(this.MaterialObject.maxInventoryString.toString().replace(',', '.'));
        }
        if (this.MaterialObject.automaticallyCreate == 'T') {
            this.IsAddAutomatically = true;
        }
        else {
            this.MaterialObject.automaticallyCreate = 'F';
            this.IsAddAutomatically = false;
        }
        if (this.MaterialObject.noStockFeatures == 'T') {
            this.NoStockFeatures = true;
        }
        else {
            this.MaterialObject.noStockFeatures = 'F';
            this.NoStockFeatures = false;
        }
        if (this.MaterialObject.nonStockList == 'T') {
            this.NonStockList = true;
        } else {
            this.NonStockList = false;
        }
        if (this.MaterialObject && this.MaterialObject.materialTypeIds)
            this.MaterialObject.materialType = this.MaterialObject.materialTypeIds.join(",");
        if (this.MaterialObject.offerMaterial && this.MaterialObject.offerMaterial.typeIds)
            this.MaterialObject.offerMaterial.type = this.MaterialObject.offerMaterial.typeIds.join(",");
        this.showSpinner = true;
        this.materialService.Add<ResponseBase>(this.MaterialObject)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.showSpinner = false;
                    this.MaterialObject = response.result;
                    this.tempIsFileAdded = response.result.isAddedFiles;
                    this.MaterialObject.id = response.result.id;
                    if (this.MaterialObject.holdings != undefined || this.MaterialObject.holdings != null) {
                        this.MaterialObject.holdingsString = this.MaterialObject.holdings.toString().replace('.', ',');
                    }
                    //if (this.MaterialObject.retailPrice != undefined || this.MaterialObject.retailPrice != null) {
                    //    this.MaterialObject.retailPriceString = this.MaterialObject.retailPrice.toString().replace('.', ',');
                    //}
                    //if (this.MaterialObject.purchasePrice != undefined || this.MaterialObject.purchasePrice != null) {
                    //    this.MaterialObject.purchasePriceString = this.MaterialObject.purchasePrice.toString().replace('.', ',');
                    //}
                    //if (this.MaterialObject.personalPrice != undefined || this.MaterialObject.personalPrice != null) {
                    //    this.MaterialObject.personalPriceString = this.MaterialObject.personalPrice.toString().replace('.', ',');
                    //}
                    //if (this.MaterialObject.purchaseQuantity != undefined || this.MaterialObject.purchaseQuantity != null) {
                    //    this.MaterialObject.purchaseQuantityString = this.MaterialObject.purchaseQuantity.toString().replace('.', ',');
                    //}
                    if (this.MaterialObject.minInventory != undefined || this.MaterialObject.minInventory != null) {
                        this.MaterialObject.minInventoryString = this.MaterialObject.minInventory.toString().replace('.', ',');
                    }
                    if (this.MaterialObject.maxInventory != undefined || this.MaterialObject.maxInventory != null) {
                        this.MaterialObject.maxInventoryString = this.MaterialObject.maxInventory.toString().replace('.', ',');
                    }
                    this.IsEdit = true;
                    //this.selectedIndex = 1;
                    if (this.MaterialObject.automaticallyCreate == 'T') {
                        this.IsAddAutomatically = true;
                    }
                    else {
                        this.MaterialObject.automaticallyCreate = 'F';
                        this.IsAddAutomatically = false;
                    }
                    if (this.MaterialObject.noStockFeatures == 'T') {
                        this.NoStockFeatures = true;
                    }
                    else {
                        this.MaterialObject.noStockFeatures = 'F';
                        this.NoStockFeatures = false;
                    }
                    if (this.MaterialObject.nonStockList == 'T') {
                        this.NonStockList = true;
                    } else {
                        this.MaterialObject.nonStockList = 'F';
                        this.NonStockList = false;
                    }
                    if (this.MaterialObject.materialType)
                        this.MaterialObject.materialTypeIds = this.MaterialObject.materialType.split(",").map(Number);
                    this.convertBarcodeColorToStr();
                    setTimeout(() => {
                        this.setMaterialTitle();
                    }, 500);
                    this.storeFileFolderPathData();
                    if (this.MaterialObject.isAddToOffer) {
                        this.storeFileFolderForOfferPathData();
                    }
                    this.translate.get("MESSAGES.Created").subscribe({
                        next: (res) => {
                            this.notificationsService.create(res);
                        },
                        error: err => {
                        }
                    });
                },
                error: err => {
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });
                },
                complete: () => {
                    this.showSpinner = false;
                    if (this.supplierId) {
                        this._router.navigate(['/materials/edit', this.MaterialObject.id], { queryParams: { supplierId: this.MaterialObject.supplierId } })
                    } else {
                        this._router.navigate(['/materials/edit', this.MaterialObject.id]);
                    }
                }
            });
    }

    Update() {
        if (this.MaterialObject.holdingsString != undefined || this.MaterialObject.holdingsString != null) {
            this.MaterialObject.holdings = Number(this.MaterialObject.holdingsString.toString().replace(',', '.'));
        }
        if (this.MaterialObject.minInventoryString != undefined || this.MaterialObject.minInventoryString != null) {
            this.MaterialObject.minInventory = Number(this.MaterialObject.minInventoryString.toString().replace(',', '.'));
        }
        if (this.MaterialObject.maxInventoryString != undefined || this.MaterialObject.maxInventoryString != null) {
            this.MaterialObject.maxInventory = Number(this.MaterialObject.maxInventoryString.toString().replace(',', '.'));
        }
        if (this.MaterialObject.automaticallyCreate == 'T') {
            this.IsAddAutomatically = true;
        }
        else {
            this.MaterialObject.automaticallyCreate = 'F';
            this.IsAddAutomatically = false;
        }
        if (this.MaterialObject.noStockFeatures == 'T') {
            this.NoStockFeatures = true;
        }
        else {
            this.MaterialObject.noStockFeatures = 'F';
            this.NoStockFeatures = false;
        }
        if (this.MaterialObject.nonStockList == 'T') {
            this.NonStockList = true;
        } else {
            this.MaterialObject.nonStockList = 'F';
            this.NonStockList = false;
        }
        if (this.MaterialObject && this.MaterialObject.materialTypeIds)
            this.MaterialObject.materialType = this.MaterialObject.materialTypeIds.join(",");
        if (this.MaterialObject.offerMaterial && this.MaterialObject.offerMaterial.typeIds)
            this.MaterialObject.offerMaterial.type = this.MaterialObject.offerMaterial.typeIds.join(",");
        this.showSpinner = true;
        this.materialService.Update<ResponseBase>(this.MaterialObject.id, this.MaterialObject)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.showSpinner = false;
                    this.MaterialObject = response.result;
                    //this.tempIsFileAdded = response.result.isAddedFiles;
                    if (response.result.holdings != undefined || response.result.holdings != null) {
                        this.MaterialObject.holdingsString = response.result.holdings.toString().replace('.', ',');
                    }
                    if (response.result.retailPrice != undefined || response.result.retailPrice != null) {
                        this.MaterialObject.retailPriceString = response.result.retailPrice.toString().replace('.', ',');
                    }
                    if (!response.result.minInventory || response.result.minInventory == 0) {
                        this.MaterialObject.minInventoryString = '0';
                    } else {
                        this.MaterialObject.minInventoryString = response.result.minInventory;
                    }
                    if (!response.result.maxInventory || response.result.maxInventory == 0) {
                        this.MaterialObject.maxInventoryString = '0';
                    } else {
                        this.MaterialObject.maxInventoryString = response.result.maxInventory;
                    }
                    if (this.MaterialObject.materialType)
                        this.MaterialObject.materialTypeIds = this.MaterialObject.materialType.split(",").map(Number);
                    this.storeFileFolderPathData();
                    if (this.MaterialObject.isAddToOffer) {
                        this.storeFileFolderForOfferPathData();
                    }
                    this.convertBarcodeColorToStr();
                    setTimeout(() => {
                        this.setMaterialTitle();
                    }, 500);
                    this.translate.get("MESSAGES.Updated").subscribe({
                        next: (res) => {
                            this.notificationsService.create(res);
                        },
                        error: err => {
                        }
                    });
                    if (this.MaterialObject.wrongMaterialBindToOfferList
                        && this.MaterialObject.wrongMaterialBindToOfferList.length > 0) {
                        this.wrongOfferMaterialBindToOfferPopup();
                    }
                    if (this.materialId != this.tempMaterialId)
                        this._router.navigate(['/materials/edit', this.tempMaterialId]);
                    if (this.MaterialObject && this.MaterialObject.offerMaterial)
                        this.offerMaterialId = this.MaterialObject.offerMaterial.id;
                },
                error: err => {
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });
                },
                complete: () => {
                    this.showSpinner = false;
                }
            });
    }

    wrongOfferMaterialBindToOfferPopup() {
        const dialogRef = this.dialog.open(WrongMaterialBindToOfferPopupComponent, {
            data: this.MaterialObject.wrongMaterialBindToOfferList
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                for (var i = 0; i < this.MaterialObject.wrongMaterialBindToOfferList.length; i++) {
                    this.projectofferService.DeleteOfferCategoryMaterial(this.MaterialObject.wrongMaterialBindToOfferList[i].id)
                        .subscribe((response: ResponseBase) => {
                        },
                            error => (err) => {
                            },
                            () => {
                            });
                }
            }
        });
    }

    isNumber(evt) {
        return IsNumber(evt);
    }

    ClickFileType() {
        document.getElementById('selectFile').click();
    }

    onSetActive(IsActive) {
        this.MaterialObject.isActive = IsActive;
    }
    onSetNoStockFeatures(IsActive) {
        this.NoStockFeatures = IsActive;
        if (IsActive)
            this.MaterialObject.noStockFeatures = 'T';
        else
            this.MaterialObject.noStockFeatures = 'F';
    }

    onSetAutomatically(IsActive) {
        this.IsAddAutomatically = IsActive;
        if (IsActive) {
            this.MaterialObject.automaticallyCreate = 'T';
        }
        else {
            this.MaterialObject.automaticallyCreate = 'F';
        }

    }

    onSetNonStockList(IsActive) {
        this.NonStockList = IsActive;
        if (IsActive) {
            this.MaterialObject.nonStockList = 'T';
        } else {
            this.MaterialObject.nonStockList = 'F';
        }
    }

    AddFile(event) {
        this.showSpinner = true;
        var fileUpload = new FileUpload();
        fileUpload.name = event.name;
        fileUpload.type = event.type;
        fileUpload.value = event.value;
        this.materialService.AddFileToMaterial(this.MaterialObject.id, fileUpload)
            .subscribe({
                next: (response: ResponseBase) => {
                    if (response)
                        this.files.push(response.result);
                    this.showSpinner = false;
                },
                error: err => {
                },
                complete: () => { }
            });
    }

    RemoveFile(event) {
        const dialogRef = this.dialog.open(DeleteAlertModalComponent, {
            height: '200px',
            data: event
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.showSpinner = true;
                this.materialService.RemoveFileFromMaterial<ResponseBase>(this.MaterialObject.id, event)
                    .subscribe({
                        next: (response: ResponseBase) => {
                            this.files.splice(this.files.findIndex(f => f.id == event), 1);
                            this.showSpinner = false;
                        },
                        error: err => { },
                        complete: () => { }
                    });
            }
        });
    }

    TabChange(event) {
        this.selectedIndex = event.index;
        //this.showMessage = false;
        if (event.index == 1) {
            this.storeFileFolderPathData();
            if (this.MaterialObject.isAddToOffer) {
                this.storeFileFolderForOfferPathData();
            }
        }
    }

    DeleteMaterial(Id) {
        this.showSpinner = true;
        this.materialService.Delete<ResponseBase>(Id).subscribe({
            next: (response: ResponseBase) => {
                this.translate.get("MESSAGES.Deleted").subscribe({
                    next: (res) => {
                        this.notificationsService.create(res);
                    },
                    error: err => {

                    }
                });
            },
            error: err => {
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            },
            complete: () => {
                this.showSpinner = false;
                this._router.navigate(['/materials']);
            }
        });
    }

    removeMaterial(Id) {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: '200px',
            data: {
                Header: 'Headers.DeleteMaterial'
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.DeleteMaterial(Id);
            }
        });
    }

    deleteMaterial() {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: "200px",
            data: { Header: "Headers.Delete" }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.showSpinner = true;
                this.materialService.DeleteMaterial(this.MaterialObject.id).subscribe({
                    next: (response: ResponseBase) => {
                        this.showSpinner = false;
                        this._router.navigate(['/materials']);
                        this.translate.get("MESSAGES.Deleted").subscribe({
                            next: (res) => {
                                this.notificationsService.create(res);
                            },
                            error: err => {
                            }
                        });
                    },
                    error: err => {
                        this.showSpinner = false;

                        this.translate.get("MESSAGES.Failed").subscribe({
                            next: (res) => {
                                this.notificationsService.error(res);
                            },
                            error: err => {
                            }
                        });
                    }
                });
            }
        });
    }

    // CRUD Offer Material Start 

    /**
    * Add Offer Material 
    */
    addOffer() {
        this.MaterialObject.isAddToOffer = true;
        if (!this.MaterialObject.offerMaterial) {
            this.MaterialObject.offerMaterial = new OfferMaterialWithPrice()
        }
        if (!this.MaterialObject.offerMaterial.id) {
            this.MaterialObject.offerMaterial.offerMaterialPriceList = [];
            for (var i = 1; i <= 3; i++) {
                var offerPrice = new OfferMaterialPrice();
                offerPrice.type = i;
                this.MaterialObject.offerMaterial.offerMaterialPriceList.push(offerPrice);
            }
        }
        if (this.MaterialObject.offerMaterial.id && this.MaterialObject.offerMaterial.offerMaterialPriceList.length == 0) {
            for (var i = 1; i <= 3; i++) {
                var offerPrice = new OfferMaterialPrice();
                offerPrice.type = i;
                this.MaterialObject.offerMaterial.offerMaterialPriceList.push(offerPrice);
            }
        }
    }

    /**
    * Delete Offer Material 
    */
    deleteOffer($event) {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: '200px',
            data: { Header: 'Headers.DeleteOffer' }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.deleteOfferData($event);
            }
        });
    }
    deleteOfferData(offerId) {
        if (offerId) {
            this.showSpinner = true;
            this.materialService.DeleteOfferDetail(offerId).subscribe({
                next: (response: ResponseBase) => {
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.OfferDelMessage").subscribe({
                        next: (res) => {
                            this.notificationsService.create(res);
                            this.Edit(this.materialId);
                        },
                        error: err => {
                        }
                    });
                },
                error: err => {
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.OfferDelFail").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });

                },
                complete: () => {

                }
            });
        } else {
            this.MaterialObject.isAddToOffer = false;
            this.MaterialObject.offerMaterial = null;
        }
    }

    /**
    * Delete Offer Price Material
    */
    deleteSingleOffer($event) {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: '200px',
            data: { Header: 'Headers.DeleteOfferPrice' }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.deleteOfferMaterialPrice($event);
            }
        });
    }
    deleteOfferMaterialPrice(offerPriceId) {
        if (offerPriceId) {
            this.showSpinner = true;
            this.materialService.DeleteOfferPrice(offerPriceId).subscribe({
                next: (response: ResponseBase) => {
                    this.MaterialObject = null;
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.OfferPriceDel").subscribe({
                        next: (res) => {
                            this.notificationsService.create(res);
                            this.Edit(this.materialId);
                        },
                        error: err => {
                        }
                    });
                },
                error: err => {
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.OfferPriceDelFail").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });

                },
                complete: () => {

                }
            });
        }
    }

    editbarcode(number) {
        if (number == 1) {
            this.barcode = this.MaterialObject.barcode;
        }
        if (number == 2) {
            this.barcode = this.MaterialObject.barcode2;
        }
        var MaterialDetail = {
            MaterialObject: this.MaterialObject,
            barcodeNo: number
        }
        const dialogRef = this.dialog.open(BarcodeComponent, {
            height: '20%',
            width: '30%',
            data: MaterialDetail,
            disableClose: true,
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != null) {
                this.MaterialObject = result;
                this.saveMaterialData()
            }
        });
    }
    // CRUD Offer Material End

    copyMaterial() {
        const dialogRef = this.dialog.open(CopyMaterialComponent, {
            height: '50%',
            width: '50%',
            data: {
                "isAddToOffer" : this.MaterialObject.isAddToOffer
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != null) {
                this.MaterialObject.id = "";
                this.MaterialObject.itemNumber = result.materialNumber.toString();
                this.MaterialObject.barcode = result.firstBarcode;
                this.MaterialObject.barcode2 = result.secondBarcode;
                this.saveMaterialData()
            }
        });
    }


    //Offer Image Upload
    saveOfferImgOnChange($event) {
        //this.MaterialObject.offerMaterial.Image = $event;
    }

    setMaterialTitle() {
        if (this.MaterialObject) {
            let msg = "";
            // const filteredData =  this.MaterialObject.offerMaterial && this.MaterialObject.offerMaterial.offerMaterialPriceList.length>0 ? this.MaterialObject.offerMaterial.offerMaterialPriceList.filter((obj) => obj.isDefault === true): [];
            // if (filteredData.length > 0) {
            //     const matchingOption = PaintSelectTime.find((option) => option.id === filteredData[0].type);
            //     if(matchingOption)
            //         msg = matchingOption.value;                
            // }
            //this.titleService.SetAddress((this.MaterialObject.text ? this.MaterialObject.text : "")  + " " + (this.MaterialObject.itemNumber ? this.MaterialObject.itemNumber : "") + " " + msg);
            this.titleService.SetAddress((this.MaterialObject.text ? this.MaterialObject.text : ""));
        }
    }

    //PriceTag
    OpnePriceTag() {
        const dialogRef = this.dialog.open(PriceTagComponent, {
            height: '40%',
            width: '40%',
            data: this.MaterialObject,
            disableClose: false
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {

            }
        });
    }

    CalculateSalesPrice(flag) {
        this.MaterialObject.priceSignWithBuyQuantity = !flag;
    }

    GetMaterial() {
        this.requestMaterial.Limit = 100;
        this.materialService.setRequestParameter(this.requestMaterial);
        this.materialService.Get<ResponseBase>(this.requestMaterial)
            .subscribe((response: ResponseBase) => {
                if (response != null) {
                    this.Materials = response.result;
                    this.getSetNextPreviousId();
                }
                else
                    this.Materials = [];
            },
                error => (err) => {
                    this.Materials = [];
                },
                () => {
                });
    }

    gotoMaterial(id) {
        if (this.Materials) {
            this.tempMaterialId = id;
            this.currentId = this.tempMaterialId;
            this.getSetNextPreviousId();
            this.save();
            //this._router.navigate(['/materials/edit', id]);
        }
    }

    getSetNextPreviousId() {
        this.currentId = this.tempMaterialId;
        var itemIndex = this.Materials.map(function (o) { return o.id; }).indexOf(this.currentId);
        if (itemIndex > 0)
            this.previousId = this.Materials[itemIndex - 1].id;
        else
            this.previousId = null;

        if (itemIndex != (this.Materials.length - 1))
            this.nextId = this.Materials[itemIndex + 1].id;
        else
            this.nextId = null;
    }

    getColorCode() {
        this.materialService.GetColorLits().subscribe((response: ResponseBase) => {
            if (response) {
                this.colorCodeDetail = response.result.myResponse[0];
                if (this.colorCodeDetail.value) {
                    this.colorCodeList = JSON.parse(this.colorCodeDetail.value);
                    this.options = this.colorCodeList
                }
            } else {
                this.options = [];
            }
        });
    }
    CheckSupplierType(supplier) {
        const materialUnitControl = this.materialForm.get('Unit');
        if (supplier.business.toLowerCase() === "tilbud") {
            this.MaterialObject.unit = null;
            materialUnitControl.setValidators(null);
        }
        else {
            materialUnitControl.setValidators([Validators.required]);
        }
        this.materialForm.updateValueAndValidity();
    }

    storeFileFolderPathData() {
        var rootPath = this.dropboxFilesService.getSettingMasterValueDocumentPath();
        var paths = "";
        if (rootPath.DocumentDriveType == DriveModuleEnum.Onedrive) {
            paths = rootPath.Onedrive.Path;
        } else {
            paths = rootPath.Dropbox.Path;
        }
        this.fileFolderPathData = paths.split("/").map((path, i) => ({
            name: path,
            isDisplay: false
        }));
        this.fileFolderPathData.push(
            { name: 'Material', isDisplay: false },
            { name: this.MaterialObject.itemNumber, isDisplay: true }
        );
    }

    storeFileFolderForOfferPathData() {
        var rootPath = this.dropboxFilesService.getSettingMasterValueDocumentPath();
        var paths = "";
        if (rootPath.DocumentDriveType == DriveModuleEnum.Onedrive) {
            paths = rootPath.Onedrive.Path;
        } else {
            paths = rootPath.Dropbox.Path;
        }
        this.fileFolderForOfferPathData = paths.split("/").map((path, i) => ({
            name: path,
            isDisplay: false
        }));
        this.fileFolderForOfferPathData.push(
            { name: 'Material', isDisplay: false },
            { name: this.MaterialObject.itemNumber, isDisplay: false },
            { name: "TilbudFiler", isDisplay: true }
        );
        this.extraParamToSendOfferFile = [{ name: 'OfferMaterialID', value: this.MaterialObject.offerMaterial.id }];
    }

    SetFileLenght(value) {
        this.FileLength = value;
    }
    IsFileExist(flag) {
        if (flag && !this.MaterialObject.isAddedFiles) {
            this.MaterialObject.isAddedFiles = true;
            //this.Update();
        }
        if (!flag && this.MaterialObject.isAddedFiles) {
            this.MaterialObject.isAddedFiles = false;
            //this.Update();
        }
    }

    IsOfferFileExist(flag) {
        if (flag && !this.MaterialObject.isAddedOfferFiles) {
            this.MaterialObject.isAddedOfferFiles = true;
            //this.Update();
        }
        if (!flag && this.MaterialObject.isAddedOfferFiles) {
            this.MaterialObject.isAddedOfferFiles = false;
            //this.Update();
        }
    }

    getIcon(icon) {
        return 'icon-' + icon;
    }

    goBackToOrder() {
        this._router.navigate(["/materials"], { queryParams: { tab: 'order' } });
    }

    convertBarcodeColorToStr() {
        this.MaterialObject.barcodeColorStr = this.MaterialObject.barcodeColor ? this.MaterialObject.barcodeColor.toString() : '1';
    }

    radioChange(event: MatRadioChange) {
        this.MaterialObject.barcodeColorStr = event.value;
        this.MaterialObject.barcodeColor = parseInt(this.MaterialObject.barcodeColorStr);
    }

    updatePriceTagColor(event) {
        if (event) {
            this.MaterialObject.barcodeColorStr = '2';
            this.MaterialObject.barcodeColor = 2;
        }
    }

    offerTabChange(event) {
        this.isShowOfferText = false;
        if (event.index == 2) {
            this.isShowOfferText = true;
        }
    }

    updateRetailAndPersonalPriceValidation() {
        if (this.MaterialObject && this.MaterialObject.purchasePrice) {
            var retailValue = this.MaterialObject.purchasePrice + ((this.MaterialObject.purchasePrice * this.retailPriceIncreaseInPercentage) / 100);
            var personalValue = this.MaterialObject.purchasePrice + ((this.MaterialObject.purchasePrice * this.personalPriceIncreaseInPercentage) / 100);
            this.materialForm.controls["RetailPrice"].setValidators(Validators.min(retailValue));
            this.materialForm.controls["RetailPrice"].updateValueAndValidity();
            this.materialForm.controls["PersonalPrice"].setValidators(Validators.min(personalValue));
            this.materialForm.controls["PersonalPrice"].updateValueAndValidity();
        }
    }

    UpdateValidation() {
        var statusIsOffer = this.MaterialObject.materialTypeIds ? this.MaterialObject.materialTypeIds.find(o => o == "22") : null;
        if (statusIsOffer) {
            this.IsSupplierRequired = false;
            this.MaterialObject.isRequiredText = false;
            this.materialForm.controls["Holdings"].setValidators(null);
            this.materialForm.controls["Holdings"].updateValueAndValidity();
            this.materialForm.controls["RetailPrice"].setValidators(null);
            this.materialForm.controls["RetailPrice"].updateValueAndValidity();
            this.materialForm.controls["PurchasePrice"].setValidators(null);
            this.materialForm.controls["PurchasePrice"].updateValueAndValidity();
            this.materialForm.controls["PersonalPrice"].setValidators(null);
            this.materialForm.controls["PersonalPrice"].updateValueAndValidity();
            this.materialForm.controls["PurchaseQuantity"].setValidators(null);
            this.materialForm.controls["PurchaseQuantity"].updateValueAndValidity();
            this.materialForm.controls["Unit"].setValidators(null);
            this.materialForm.controls["Unit"].updateValueAndValidity();
            this.materialForm.controls["ShelfNo"].setValidators(null);
            this.materialForm.controls["ShelfNo"].updateValueAndValidity();
            this.materialForm.controls["SupplierId"].setValidators(null);
            this.materialForm.controls["SupplierId"].updateValueAndValidity();
            this.materialForm.controls["SupplierMaterialNo"].setValidators(null);
            this.materialForm.controls["SupplierMaterialNo"].updateValueAndValidity();
            this.materialForm.controls["MinInventory"].setValidators(null);
            this.materialForm.controls["MinInventory"].updateValueAndValidity();
            this.materialForm.controls["MaxInventory"].setValidators(null);
            this.materialForm.controls["MaxInventory"].updateValueAndValidity();
            this.materialForm.controls["LineType"].setValidators(null);
            this.materialForm.controls["LineType"].updateValueAndValidity();
            this.materialForm.controls["WeatherType"].setValidators(null);
            this.materialForm.controls["WeatherType"].updateValueAndValidity();
            this.materialForm.controls["Materialtype"].setValidators(null);
        }
        else {
            this.IsSupplierRequired = true;
            this.materialForm.controls["Holdings"].setValidators([Validators.compose([Validators.required])]);
            this.materialForm.controls["Holdings"].updateValueAndValidity();
            if(this.hasPurchasePriceViewPermision){
                this.materialForm.controls["PurchasePrice"].setValidators([Validators.compose([Validators.required, Validators.max(999999999999)])]);
                this.materialForm.controls["PurchasePrice"].updateValueAndValidity();
            }
            else {
                this.materialForm.controls["PurchasePrice"].setValidators(null);
                this.materialForm.controls["PurchasePrice"].updateValueAndValidity();
            }
            this.materialForm.controls["PurchaseQuantity"].setValidators([Validators.required]);
            this.materialForm.controls["PurchaseQuantity"].updateValueAndValidity();
            this.materialForm.controls["Unit"].setValidators([Validators.required]);
            this.materialForm.controls["Unit"].updateValueAndValidity();
            this.materialForm.controls["ShelfNo"].setValidators([Validators.required]);
            this.materialForm.controls["ShelfNo"].updateValueAndValidity();
            this.materialForm.controls["SupplierId"].setValidators([Validators.required]);
            this.materialForm.controls["SupplierId"].updateValueAndValidity();
            this.materialForm.controls["SupplierMaterialNo"].setValidators([Validators.required]);
            this.materialForm.controls["SupplierMaterialNo"].updateValueAndValidity();
            this.materialForm.controls["MinInventory"].setValidators([Validators.compose([Validators.required, Validators.min(0), Validators.max(999999999999)])]);
            this.materialForm.controls["MinInventory"].updateValueAndValidity();
            this.materialForm.controls["MaxInventory"].setValidators([Validators.compose([Validators.required, Validators.min(0), Validators.max(999999999999)])]);
            this.materialForm.controls["MaxInventory"].updateValueAndValidity();
            this.materialForm.controls["LineType"].setValidators([Validators.required]);
            this.materialForm.controls["LineType"].updateValueAndValidity();
            this.materialForm.controls["WeatherType"].setValidators([Validators.required]);
            this.materialForm.controls["WeatherType"].updateValueAndValidity();
            this.materialForm.controls["Materialtype"].setValidators([Validators.required]);
            this.updateRetailAndPersonalPriceValidation();
        }
    }

    goBack() {
        this.location.back();
    }

    updatePersonalPriceTotal(){
        if (this.MaterialObject.personalPrice && this.MaterialObject.purchaseQuantity)
            this.MaterialObject.priceForBucket = this.MaterialObject.personalPrice * this.MaterialObject.purchaseQuantity;
    }
}