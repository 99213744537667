import { Component, OnInit } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { MatDialog , MatOption} from '@angular/material';
import { AuthService } from 'app/core/services/auth.service';
import { SettingsService } from '../../settings.service';
import { ResponseBase } from "app/main/model/ResponseBase";
import { Settings } from "app/main/model/Settings";

@Component({
    selector: "vibe-tools-and-ladders",
    templateUrl: "./tools-and-ladders.component.html",
    styleUrls: ["./tools-and-ladders.component.scss"]
})

export class ToolsAndLaddersSettingsComponent implements OnInit {
    showSpinner: boolean = false;
    showSpinnerForApp: boolean = false;
    toolsAndLadderForWeb: string;
    toolsAndLadderForWebObj: Settings;
    toolsAndLadderForApp: string;
    toolsAndLadderForAppObj: Settings;
    toolsAndLaddersSettings: any[] = [];
    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private authService: AuthService,
        private settingsService: SettingsService,
        private dialog: MatDialog) {
        this.translationLoaderService.loadTranslations(danish, english);
    }

    ngOnInit() {
        // this.getToolsAndLaddersForWeb();
        // this.getToolsAndLaddersForApp();
        this.getSettingForToolsAndLadders();    
    }
    getSettingForToolsAndLadders() {
        this.showSpinner = true;
        this.settingsService.GetSettingsValue<ResponseBase>("Menu", null, null).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.result && response.result.myResponse.length > 0) {
                    this.toolsAndLaddersSettings = response.result.myResponse;
                    var tempToolsAndLadderForWebObject = this.toolsAndLaddersSettings.find(s => s.key1 == "Menu" && s.key2 == "ToolsAndLaddersForWeb");
                    if (tempToolsAndLadderForWebObject) {
                        this.toolsAndLadderForWebObj = tempToolsAndLadderForWebObject;
                        this.toolsAndLadderForWeb = tempToolsAndLadderForWebObject.value;
                    }
                    var tempToolsAndLadderForAppObj = this.toolsAndLaddersSettings.find(s => s.key1 == "Menu" && s.key2 == "ToolsAndLaddersForApp");
                    if (tempToolsAndLadderForAppObj) {
                        this.toolsAndLadderForAppObj = tempToolsAndLadderForAppObj;
                        this.toolsAndLadderForApp = tempToolsAndLadderForAppObj.value;
                    }
                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    getToolsAndLaddersForWeb() {
        this.showSpinner = true;
        this.settingsService.GetSettingsValue<ResponseBase>("Menu", "ToolsAndLaddersForWeb", null).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.result && response.result.myResponse.length > 0) {
                    this.toolsAndLadderForWebObj = response.result.myResponse[0];
                    this.toolsAndLadderForWeb = response.result.myResponse[0].value;
                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    getToolsAndLaddersForApp() {
        this.showSpinner = true;
        this.settingsService.GetSettingsValue<ResponseBase>("Menu", "ToolsAndLaddersForApp", null).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.result && response.result.myResponse.length > 0) {
                    this.toolsAndLadderForAppObj = response.result.myResponse[0];
                    this.toolsAndLadderForApp = response.result.myResponse[0].value;
                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    changeSettingsToolsAndLaddersForWeb() {
        this.showSpinner = true;
        this.toolsAndLadderForWebObj.value = this.toolsAndLadderForWeb;
        this.settingsService.UpdateSettingValue<ResponseBase>(this.toolsAndLadderForWebObj).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.result && response.result.myResponse[0]) {
                    this.toolsAndLadderForWebObj = response.result.myResponse[0];
                    this.toolsAndLadderForWeb = response.result.myResponse[0].value;
                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    changeSettingsToolsAndLaddersForApp() {
        this.showSpinner = true;
        this.toolsAndLadderForAppObj.value = this.toolsAndLadderForApp;
        this.settingsService.UpdateSettingValue<ResponseBase>(this.toolsAndLadderForAppObj).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.result && response.result.myResponse[0]) {
                    this.toolsAndLadderForAppObj = response.result.myResponse[0];
                    this.toolsAndLadderForApp = response.result.myResponse[0].value;
                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }
}