import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { MatSlideToggleChange } from '@angular/material';
import { SettingsService } from '../../settings.service';
import { ResponseBase } from "app/main/model/ResponseBase";
import { Settings } from 'app/main/model/Settings';
import { environment } from 'environments/environment';
import { ReminderWeekDays } from 'app/main/model/Reminder';

@Component({
    selector: 'vibe-email-send-configuration',
    templateUrl: './email-send-configuration.component.html',
    styleUrls: ['./email-send-configuration.component.scss']
})
export class EmailSendConfigurationComponent implements OnInit {
    @Input("SendEmailSetting") SendEmailSetting: Settings;

    showSpinner: boolean = false;
    emailConfigForm: FormGroup;
    isSubmitted = false;

    isSendEmail: boolean = false;
    sendEmailConfigObj: any = {};
    SendEmailObj: Settings = new Settings();
    envEmailSendParam = "";
    weekDays = ReminderWeekDays;
    settingMasterValues: any[] = [];    

    constructor(
        private translationLoaderService: FuseTranslationLoaderService,
        private fb: FormBuilder,
        private settingsService: SettingsService) {
        this.translationLoaderService.loadTranslations(danish, english);
    }

    ngOnInit() {
        this.settingMasterValues = Object.assign([], JSON.parse(localStorage.getItem("settingMasterValues")));
        this.emailConfigForm = this.fb.group({
            IsSendEmail: [false],
            IsTimeBased: [true],
            HoursFromSend: [null],
            HoursToSend: [null],
            isExecuteMonday: [false],
            isExecuteTuesday: [false],
            isExecuteWednesday: [false],
            isExecuteThursday: [false],
            isExecuteFriday: [false],
            isExecuteSaturday: [false],
            isExecuteSunday: [false],
        });
        this.envEmailSendParam = environment.SendingNotificationValue2;
        this.setvalidators();
        this.setSettingsForSendEmail();
    }

    onSubmit() {
        this.emailConfigForm.markAllAsTouched();
        this.isSubmitted = true;
        if (this.emailConfigForm.valid) {
            this.saveEmailConfigurationSetting()
        }
    }

    saveEmailConfigurationSetting() {
        this.showSpinner = true;
        let data = {
            'IsSendEmail': this.emailConfigForm.value.IsSendEmail.toString(),
            'IsTimeBased': this.emailConfigForm.value.IsTimeBased.toString(),
            'HoursFromSend': this.emailConfigForm.value.IsTimeBased ? this.emailConfigForm.value.HoursFromSend : null,
            'HoursToSend': this.emailConfigForm.value.IsTimeBased ? this.emailConfigForm.value.HoursToSend : null,
            'isExecuteMonday': this.emailConfigForm.value.isExecuteMonday,
            'isExecuteTuesday': this.emailConfigForm.value.isExecuteTuesday,
            'isExecuteWednesday': this.emailConfigForm.value.isExecuteWednesday,
            'isExecuteThursday': this.emailConfigForm.value.isExecuteThursday,
            'isExecuteFriday': this.emailConfigForm.value.isExecuteFriday,
            'isExecuteSaturday': this.emailConfigForm.value.isExecuteSaturday,
            'isExecuteSunday': this.emailConfigForm.value.isExecuteSunday,
        }
        this.sendEmailConfigObj = JSON.stringify(data)
        this.SendEmailObj.value = this.sendEmailConfigObj;
        this.settingsService.UpdateSettingValue<ResponseBase>(this.SendEmailObj).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.result) {
                    this.sendEmailConfigObj = JSON.parse(response.result.value);
                    this.sendEmailConfigObj.IsSendEmail = JSON.parse(this.sendEmailConfigObj.IsSendEmail);
                    this.sendEmailConfigObj.IsTimeBased = JSON.parse(this.sendEmailConfigObj.IsTimeBased);
                    this.emailConfigForm.patchValue(this.sendEmailConfigObj);
                    this.setValidatorsBasedOnSavedValue();
                    this.changeInLocalStorage(this.SendEmailObj.id, this.SendEmailObj)
                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinner = false;
                this.isSubmitted = false;
            }
        });
    }

    changeIsSendEmail(event: MatSlideToggleChange) {
        this.saveEmailConfigurationSetting();
    }

    changeIsTimeBased(event: MatSlideToggleChange) {
        if (event.checked) {
            this.setvalidators()
        }
        else {
            this.clearValidators();
        }
    }

    clearValidators() {
        this.emailConfigForm.get('HoursFromSend').clearValidators();
        this.emailConfigForm.get('HoursFromSend').updateValueAndValidity();
        this.emailConfigForm.get('HoursToSend').clearValidators();
        this.emailConfigForm.get('HoursToSend').updateValueAndValidity();
    }

    setvalidators() {
        this.emailConfigForm.get('HoursFromSend').setValidators([Validators.required, Validators.min(1), Validators.max(24), this.smallerThan('HoursToSend')]);
        this.emailConfigForm.get('HoursFromSend').updateValueAndValidity();
        this.emailConfigForm.get('HoursToSend').setValidators([Validators.required, Validators.min(1), Validators.max(24), this.greaterThan('HoursFromSend')]);
        this.emailConfigForm.get('HoursToSend').updateValueAndValidity();
    }

    setValidatorsBasedOnSavedValue() {
        if (this.sendEmailConfigObj.IsTimeBased) {
            this.setvalidators();
        }
        else {
            this.clearValidators();
        }
    }

    setSettingsForSendEmail() {
        if (this.SendEmailSetting && this.SendEmailSetting != null) {
            this.SendEmailObj=this.SendEmailSetting;
            this.sendEmailConfigObj = JSON.parse(this.SendEmailObj.value);
            this.sendEmailConfigObj.IsSendEmail = JSON.parse(this.sendEmailConfigObj.IsSendEmail);
            this.sendEmailConfigObj.IsTimeBased = JSON.parse(this.sendEmailConfigObj.IsTimeBased);
            this.emailConfigForm.patchValue(this.sendEmailConfigObj);
            this.setValidatorsBasedOnSavedValue();
        }
    }

    smallerThan(otherControlName: string) {
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            if (!control.parent) {
                return null; // Control is not yet associated with a parent.
            }
            var dtnow = new Date()
            var dtCurrnetControl = (dtnow.getMonth() + 1) + "/" + dtnow.getDate() + "/" + dtnow.getFullYear() + " " + control.value;
            var dtOtherControl = (dtnow.getMonth() + 1) + "/" + dtnow.getDate() + "/" + dtnow.getFullYear() + " " + control.parent.get(otherControlName).value;
            if (dtCurrnetControl < dtOtherControl) {
                return null;
            }

            return {
                'smallerthan': true
            }
        };
    }

    greaterThan(otherControlName: string) {
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            if (!control.parent) {
                return null; // Control is not yet associated with a parent.
            }
            var dtnow = new Date()
            var dtCurrnetControl = (dtnow.getMonth() + 1) + "/" + dtnow.getDate() + "/" + dtnow.getFullYear() + " " + control.value;
            var dtOtherControl = (dtnow.getMonth() + 1) + "/" + dtnow.getDate() + "/" + dtnow.getFullYear() + " " + control.parent.get(otherControlName).value;

            if (dtCurrnetControl > dtOtherControl) {
                return null;
            }

            return {
                'greaterthan': true
            }
        };
    }

    changeInLocalStorage(ID, new_obj) {
        if (this.settingMasterValues.find(obj => obj.id == ID)) {
            this.settingMasterValues.forEach((old_obj, index) => {
                if (old_obj.id == ID) {
                    this.settingMasterValues[index] = new_obj
                }
            })
        } else {
            this.settingMasterValues.push(new_obj)
        }
        localStorage.setItem("settingMasterValues", JSON.stringify(this.settingMasterValues));
    }
}
