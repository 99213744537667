export const locale = {
  lang: 'en',
  data: {
    'FIELD': {
      'Weather': 'Weather',
      'WeekNumber': 'Week Number',
      'Today': 'Today',
      'Tomorrow': 'Tomorrow',
      'Current': 'Current',
      'Events': 'Events',
      'Notes': 'Notes'
    }
  }
};
