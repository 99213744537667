export const locale = {
    lang: 'ds',
    data: {
        'TITLE': {
            'DevicePagination': 'Antal brikker pr. side',
        },
        'FIELDS': {
            'Designation': 'Betegnelse',
            'ValideFrom': 'Gyldig Fra',
            'SRNO': 'Sr nr.'
        },
        'BUTTONS': {
            'Save': 'Gem',
            'BACK': 'Tilbage',
            'Reset': 'Nulstil',
            'AddDevice':'Tilføj Nanno brikker'
        },
        'MESSAGES': {
            'Updated': 'Opdateret succesfuldt.',
            'Failed': 'Mislykkedes',
            'Deleted': 'Slettet med succes',
            'NORECORD': 'Intet optage fundet',
            'Created': 'Oprettet med succes',
        },
        'Headers': {
            'Title':'Nanno Brikker',
            'DeleteMessage': 'Er du sikker på, at du vil slette dette',
            'Rules':'Satser',
        },
        'Lable': {
            'SRNO': 'Sr nr.',
            'Image': 'Image',
            'Brand': 'Mærke',
            'Model': 'Brik model',
            'Created': 'Oprettet',
            'SerialNumber': 'Serienummer',
            'Keywords': 'Nøgleord',
            'Description': 'Beskrivelse',
            'Group': 'Gruppe',
            'Labels': 'Etiketter',
            'VID':'vID',
            'NanolinkDeviceID':'Nanolink enheds-id',
            'Type':'Type',

        },
        'FILTERS': {
            'All': 'Alle',
            'SEARCH': 'Søg',
            'SORTING': 'Sorter efter',
        },
        'SORTBY':
        [
            {
                Text: 'Brik model ↑',
                Value: 'Model'
            },
            {
                Text: 'Brik model ↓',
                Value: 'Model DESC'
            },
            {
                Text: 'Type ↑',
                Value: 'Type'
            },
            {
                Text: 'Type ↓',
                Value: 'Type DESC'
            },
            {
                Text: 'Batteriprocent ↑',
                Value: 'BatteryPer'
            },
            {
                Text: 'Batteriprocent ↓',
                Value: 'BatteryPer DESC'
            }
        ],
    }
};