export const locale = {
  lang: 'ds',
  data: {
    'FIELDS': {
      'Qty': 'Antal',
      'Text': 'Tekst',
      'Price': 'Pris',
      'PriceTotal': 'Pris i alt',
    },
    'MESSAGES': {
      'Failed': 'Operation mislykket, prøv senere',
      'Success': 'Succes.',
      'Added': 'Tilføjet succesfuldt..',
      'Updated': 'Opdateret succesfuldt..',
    },
  }
};
