export const locale = {
  lang: "ds",
  data: {
    "BUTTONS": {
      "BTNYes": "JA",
      "BTNNo": "NEJ",
    },
    "FIELDS": {
      "Date": "Dato",
      "Time": "Tid",
      "Text": "Tekst",
      "Replay": "Besvar",
      "CompletedBy":"Afsluttet af",
      'ChooseFile': 'Vælg fil',
      'NoFileSelected': 'Ingen fil valgt',
      'FileSelected': 'Fil valgt',
    },
  }
};
