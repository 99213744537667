export const locale = {
    lang: 'en',
    data: {
        'Tabs': {
            'PROJECT': 'Projects',
            'PROJECTOFFER': 'Offer',
            'QUESTIONS': 'Questions'
        },
        'Message': {

        },
    }
};
