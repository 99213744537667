import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { Login } from '../../model/Login';
import { LoginService } from './login.service';
import { AuthService } from '../../../core/services/auth.service';
import { MessagingService } from '../../../core/services/messaging.service';
import { SettingsService } from 'app/main/pages/settings/settings.service';
import { ResponseBase } from "app/main/model/ResponseBase";
import { Router } from '@angular/router';

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loginModel: Login;
    isLoginAttemted: boolean = false;
    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private loginService: LoginService,
        private authService: AuthService,
        private settingsService: SettingsService,
        private _formBuilder: FormBuilder,
        private router: Router,
        private messagingService: MessagingService
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.messagingService.requestPermission();
        this.loginModel = new Login();
        this.loginForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
            password: ['', Validators.required]
        });
        this.doesTokenExist();
    }

    login() {
        //var login = new Login();
        //login.UserName = "admin@vibe.dk";
        //login.Password = "Test@123";
        this.isLoginAttemted = true;
        this.loginModel.FCMToken = this.messagingService.getFCMTeken();
        this.loginService.Login<any>(this.loginModel).subscribe({
            next: (response: any) => {
                this.authService.setToken(response.result);
                //this.isLoginAttemted = false;
                //location.href = "/dashboard";
                this.getSettingsWithMupltipleKeys();
                //this.router.navigateByUrl('/dashboard');
                //location.reload();

            },
            error: (err) => {
                // this.isLoginAttemted = false;
            },
            complete: () => { this.isLoginAttemted = false; }
        });

    }

    doesTokenExist() {
        var token = this.authService.getToken();
        if (token) {
            this.router.navigateByUrl('/dashboard');
        }
    }

    
    getSettingsWithMupltipleKeys() {
        this.settingsService.GetSettingsWithMupltipleKeys<ResponseBase>("").subscribe({
            next: (response: ResponseBase) => {
                if (response != null && response.result) {
                    localStorage.setItem('settingMasterValues', JSON.stringify(response.result.myResponse));
                }
            },
            error: err => {
            },
            complete: () => {
                this.router.navigateByUrl('/dashboard');
            }
        });
    }
}
