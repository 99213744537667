export const locale = {
  lang: 'ds',
  data: {
    'BUTTONS': {
      'NEWPROJECTOFFER': 'Nyt tilbud',
      'Questions':'Spørgsmål',
      'GoToOffer': 'Gå til tilbud',
      'AllImage' : 'Alle billeder'
    },
    'FIELDS': {
    },
    'FILTERS': {
      'SEARCH': 'Søg',
      'SORTING': 'Sorter efter',
      'SORTING_STATUS': 'Status',
      'Active': 'Inkluder alle inaktive',
      'BADGE': 'Inaktive',
      'Deleted': 'Vis slettet',
        'All': 'Alle',
        'IndoorOutDoor': 'Ind, ud, Sprøjt, Reklamation',
    },
    'MESSAGES': {
      'Failed': 'Operation mislykket, prøv senere',
      'NORECORD': 'Ingen Tilbud Fundet',
    },
      'selectedValueIndoorOutdoorStatus': {
          'value1': 'Indendørs ',
          'value2': 'Udendørs',
          'value3': 'Sprøjtemaling',
          'value4': 'Reklamation',
          'value5': 'Personale køb', //drawing of painter
          'value6': 'Butiks køb'     //Nordsjø logo
      },
      'selectedValueOfferStatus': {
          'value1': 'Lavet',
          'value2': 'Send til kunde',
          'value3': 'Godkendt af kunde',
          'value4': 'Afvist af kunde',
          'value5': 'Projekt oprettet',
      },
    'SORTBY':
      [
        {
          Text: 'Nummer (0-9)',
          Value: 'Number'
        },
        {
          Text: 'Nummer (9-0)',
          Value: 'Number DESC'
        }
      ],
  }
};
