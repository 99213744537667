export const locale = {
    lang: 'en',
    data: {
      'STATUS': {
        'Created': 'Created',
        'Updated': 'Updated',
        'Deleted': 'Deleted',
        'FileUploaded': 'Files Uploaded',
        'FileDeleted': 'Files Deleted',
      },
      'TITLE': {
        'CustomerPagination': 'Antal log pr. side',
      },
      'BUTTONS': {
        'BACK': 'Cancel',
        'BTNSAVE': 'Save',
      },
      'FIELDS': {
        'Detail': 'Detail',
        'ChangedDetail': 'Changed Detail',
        'ItemNo': 'Item Number',
        'Number': 'Holdings',
        'Text': 'Text',
        'Linetype': 'Line type',
        "Materialtype": 'Material type',
        'RetailPrice': 'Retail Price',
        'PurchasePrice': 'Purchase price',
        'Unit': 'Unit',
        "WeatherType": "Weather type",
        'ShelfNo': 'Shelf no.',
        'Rows': 'Rows',
        'InternalText': 'Internal text',
        'ColorCode': 'Color Code',
        'PersonalPrice': 'Personal Price',
        'PurchaseQuantity': 'Purchase Quantity',
        'Supplier': 'Supplier',
        'SupplierMaterialNo': 'Supplier Material No',
        'MinInventory': 'Min Inventory',
        'MaxInventory': 'Max Inventory',
        'Reminder': 'Reminder',
        'ModifyBy': 'Modify By',
      },
      'Headers': {
        'CustomerLogDetails':'Materials Log Details'
      },
     
      'MESSAGES': {
        'NORECORD': 'No Record Found',
      },
      'selectedValueIndoorOutdoorStatus': {
        'value1': 'Indoor ',//Man in a room
        'value2': 'Outdoor', //Sun
        'value3': 'Spray Painting',//spraypaint
        'value4': 'Complaint', //Reklamation
        'value5': 'Staff purchase', //drawing of painter
        'value6': 'Store purchase' //Nordsjø logo
      },
      'linetype': {
        'value1': 'Pay',
        'value2': 'Text',
        'value3': 'Product'
      },
    }
  };
  
  
  