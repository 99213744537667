import { Component, OnInit } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { MatDialog } from '@angular/material';
import { AuthService } from 'app/core/services/auth.service';
import { SettingsService } from '../../settings.service';
import { ResponseBase } from "app/main/model/ResponseBase";
import { RequestCommonBase } from "app/main/model/RequestBase";
import { TranslateService } from "@ngx-translate/core";
import { NotificationsService } from "angular2-notifications";
import { AlertMessageModelComponent } from "app/main/pages/alert-message-model/alert-message-model.component";
import { Router } from "@angular/router";
import { NewCoWorkerComponent } from "../../../coWorker/new-co-worker/new-co-worker.component";

@Component({
    selector: "vibe-coworker-setting",
    templateUrl: "./coworker-setting.component.html",
    styleUrls: ["./coworker-setting.component.scss"]
})

export class CorkerSettingComponent implements OnInit {
    showSpinner: boolean = false;
    reviewQuestionsList: any = [];
    settingMasterValues: any = [];
    requestBase: RequestCommonBase;
    pagination: any;
    createTimeCoworkerAskDummyData: boolean = false;
    settingForHelp: any = {};
    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private authService: AuthService,
        private settingsService: SettingsService,
        private translate: TranslateService,
        private notificationsService: NotificationsService,
        private _router: Router,
        private dialog: MatDialog) {
        this.translationLoaderService.loadTranslations(danish, english);
    }

    ngOnInit() {
        this.settingMasterValues = Object.assign([], JSON.parse(localStorage.getItem("settingMasterValues")));
        this.requestBase = new RequestCommonBase();
        this.requestBase.Index = 1;
        this.requestBase.Limit = 100;
        this.requestBase.OrderBy = 'date';
        this.getCoworkerReviewQuestions();
        this.getAndSetSetingValue();
    }

    getCoworkerReviewQuestions() {
        this.showSpinner = true;
        this.reviewQuestionsList = [];
        this.settingsService.GetCoworkerReviewQuestionList<ResponseBase>(this.requestBase).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.result) {
                    this.reviewQuestionsList = response.result;
                    this.pagination = response.pagination;
                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    getAndSetSetingValue() {
        this.settingForHelp = this.settingMasterValues.find(o => o.key1 == "AllModule" && o.key2 == "CreateTimeFillWithDummyData");
        if (this.settingForHelp) {
            var value = JSON.parse(this.settingForHelp.value);
            this.createTimeCoworkerAskDummyData = value.IsAddCoworkerHelp;
        }
    }

    UpdateSetting() {
        var value = JSON.parse(this.settingForHelp.value);
        value.IsAddCoworkerHelp = this.createTimeCoworkerAskDummyData;
        this.settingForHelp.value = JSON.stringify(value);
        this.settingsService.UpdateSettingWithTemplate<ResponseBase>(this.settingForHelp).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.result) {
                    this.settingForHelp = response.result;
                    this.changeInLocalStorage(this.settingForHelp.id, this.settingForHelp);
                    this.translate.get("Message.Updated").subscribe({
                        next: (res) => {
                            this.notificationsService.create(res);
                        },
                        error: err => { }
                    });
                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
        
    }

    changeInLocalStorage(ID, new_obj) {
        if (this.settingMasterValues.find(obj => obj.id == ID)) {
            this.settingMasterValues.forEach((old_obj, index) => {
                if (old_obj.id == ID) {
                    this.settingMasterValues[index] = new_obj
                }
            })
        } else {
            this.settingMasterValues.push(new_obj)
        }
        localStorage.setItem("settingMasterValues", JSON.stringify(this.settingMasterValues));
    }

    public EditQuestion(obj, index) {
        this._router.navigate(['/settings/reviewquestion'], { queryParams: { id: obj.id } });
    }

    public Delete(obj) {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: "200px",
            data: { Header: 'Headers.DeleteAlert' }
        })

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.showSpinner = true;
                this.settingsService.DeleteCoworkerReviewQuestion<ResponseBase>(obj['id']).subscribe({
                    next: (response: ResponseBase) => {
                        if (response.statusCode == 200) {
                            this.getCoworkerReviewQuestions();
                            this.translate.get("Message.Deleted").subscribe({
                                next: (res) => {
                                    this.notificationsService.create(res);
                                },
                                error: err => { }
                            });
                        }
                    },
                    error: err => {
                        this.translate.get("Message.Failed").subscribe({
                            next: (res) => {
                                this.notificationsService.error(res);
                            },
                            error: err => { }
                        });
                    },
                    complete: () => {
                        this.showSpinner = false;
                    }
                })
            }
        });
    }

    receivePaginatonEvent(event) {
        this.requestBase.Index = event.pageIndex;
        this.requestBase.Limit = event.pageSize;
        this.requestBase.OrderBy = 'date';
        this.getCoworkerReviewQuestions();
    }

    coworkerDefaultValueSet() {
        const dialogRef = this.dialog.open(NewCoWorkerComponent, {
            height: '80%',
            width: '90%',
            data: { moduleName: "Setting" },
        });

        dialogRef.afterClosed().subscribe(result => {
            //if (result) {
            //    this.titleService.SetTitle("TITLE_INQUIRY");
            //    this.assignedCustomerId = result;
            //    this.Update(false);
            //} else {
            //    this.titleService.SetTitle("TITLE_INQUIRY");
            //}
        });
    }
}