import { Component, OnInit, Inject } from '@angular/core';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ShortcutInput, ShortcutEventOutput } from "ng-keyboard-shortcuts";

@Component({
    selector: 'app-alert-message-model',
    templateUrl: './alert-message-model.component.html',
    styles: []
})
export class AlertMessageModelComponent implements OnInit {
    Header: string = "";
    showOKButton: Boolean = false;
    shortcuts: ShortcutInput[] = [];
    showManualButton: boolean = false;
    constructor(private translationLoader: FuseTranslationLoaderService,
        public dialogRef: MatDialogRef<AlertMessageModelComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.translationLoader.loadTranslations(english, danish);

    }

    ngOnInit() {
        this.Header = this.data.Header;
        this.showOKButton = this.data.ShowOKButton;
        this.showManualButton = this.data.ShowManualButton;
    }

    onCloseDialog(e) {
        this.dialogRef.close(e ? true : false)
    }

    addManually(value) {
        this.dialogRef.close(value);
    }

    ngAfterViewInit() {
        this.shortcuts.push(
            {
                key: ["j"],
                label: "Ja",
                description: "j",
                command: (output: ShortcutEventOutput) => {
                    this.dialogRef.close(true);
                },
                preventDefault: true
            },
            {
                key: ["n"],
                label: "Nej",
                description: "n",
                command: (output: ShortcutEventOutput) => {
                    this.dialogRef.close(false)
                },
                preventDefault: true
            }
        );
    }

}
