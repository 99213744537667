export const locale = {
    lang: 'ds',
    data: {
        'BUTTONS': {
            'Add': 'Tilføj',
            'Back': 'Tilbage',
            'Save': 'Gem'
        },
        'FIELDS': {
            'All': 'Alle',
            'Coworker': 'Medarbejder',
            'SrNo': 'Sr-nr.',
            'PlanningLeave': 'Planlægning orlov',
            'PlanningRemove': 'Planlægning Fjern',
            'Inquiry': 'Forespørgsler',
            'Not-Found-Worker': 'Medarbejder ikke fundet',
            'Offer': 'Tilbud',
            'Before7DaysProjectReminder': 'Inden 7 dages projektpåmindelse',
        },
        'MESSAGES': {
            'Created': 'Oprettet med succes',
 'Updated': 'Opdateret med succes',
 'Deleted': 'Slettet med succes',
 'Failed': 'Operation mislykket, prøv senere',
            'NORECORD': 'Ingen rekord fundet',
            'SelectCoworker':'Vælg Medarbejder'
        },
    }
}