export const locale = {
    lang: 'ds',
    data: {
      'STATUS': {
        'Created': 'lavet',
        'Updated': 'Opdateret',
        'Deleted': 'Slettet',
        'FileUploaded': 'Filer uploadet',
        'FileDeleted': 'Filer slettet',
      },
      'TITLE': {
        'CustomerPagination': 'Antal log pr. side',
      },
      'BUTTONS': {
        'BACK': 'Gå tilbage',
        'BTNSAVE': 'Gem',
      },
      'FIELDS': {
        'SRNO': 'Sr nr.',
        'Status': 'Status',
        'Number': 'Nummer',
        'Name': 'Navn',
        'Createdby': 'OprettetAf',
        'CreatedDate': 'OprettetDato',
        'ModifyBy': 'ModificereAf',
        'ModifyDate': 'ModificereDato',
        
        'Date': 'Dato',
        'Description': 'Tekst',
        'CustomerNo': 'Kunde nr.',
        'CustomerName': 'Kundenavn',
        'Email': 'E-mail',
        'PhoneNo': 'Telefon',
        'Address-line-1': 'Adresse (Linje 1)',
        'Address-line-2': 'Adresse (Linje 2)',
        'City': 'By',
        'Zipcode': 'Postnummer',
        'Position': 'Position',
        'Comments': 'Kommentarer  [ Valgfri ]',
        'OrderName': 'Ordre navn',
        'EnterLocation': 'Indtast din placering',
        'Active': 'Indstil som inaktiv',
        'From': 'Fra',
        'To': 'til',
        'colorCode': 'Farvekode',
        'PersonnelPurchas': 'Personale køb',
        'EmailInvoice': 'Email Faktura',
        'EANNummer': 'EAN Nummer',
        'Address': 'Adresse',
        'Detail': 'Detalje',
        'ChangedDetail': 'Ændret detalje',


        'CoworkerName': 'Medarbejdernavn',
        'ProjectNummer':'Projekt nr.',
        'ProjectRequisitionNummer': 'Opgave nr.'
      },
      'Headers': {
        'CustomerLogDetails': 'Opgave medarbejdere log detaljer'
      },
      'MESSAGES': {
        'NORECORD': 'Ingen registrering fundet',
      },
      'selectedValueIndoorOutdoorStatus': {
        'value1': 'Indendørs ',
        'value2': 'Udendørs',
        'value3': 'Sprøjtemaling',
        'value4': 'Reklamation',
        'value5': 'Personale køb', //drawing of painter
        'value6': 'Butiks køb'     //Nordsjø logo
      },
    }
  };
  