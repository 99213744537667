import { Component, Inject, OnInit, Optional } from '@angular/core';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { ProjectService } from '../../project.service';
import { RequestProjectLocation } from 'app/main/model/RequestBase';
import { HostListener } from '@angular/core';
@Component({
  selector: 'app-view-project-map-information',
  templateUrl: './view-project-map-information.component.html',
  styleUrls: ['./view-project-map-information.component.scss']
})
export class ViewProjectMapInformationComponent implements OnInit {
  @HostListener('document:click', ['$event'])
  showSpinner: boolean = false;
  projectInformation: any;
  requestModel: RequestProjectLocation = new RequestProjectLocation();
  isEditDelete: boolean = false;
  constructor(
    private translationLoader: FuseTranslationLoaderService,
    private translate: TranslateService,
    private notificationsService: NotificationsService,
    private projectService: ProjectService,
    public dialogRef: MatDialogRef<ViewProjectMapInformationComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.translationLoader.loadTranslations(english, danish);
  }

  ngOnInit() {
    this.requestModel.Index = 1;
    this.requestModel.Limit = 200;
    this.requestModel.OrderBy = 'Date DESC';
    this.requestModel.search = "";
    this.projectInformation = this.data && this.data.projectMapLocation ? this.data.projectMapLocation : '';
  }

  editProjectInformation(item) {
    this.showSpinner = true;
    this.projectService.UpdateProjectTaskLocation<ResponseBase>(item.id, item)
      .subscribe(resonse => {
        if (resonse) {
          this.showSpinner = false;
          this.translate.get("MESSAGES.Success").subscribe({
            next: (res) => {
              this.notificationsService.success(res);
              this.GetProjectTaskLocation(item.projectId)
            },
            error: err => {
            }
          });
        }
      },
        error => (err) => {
          this.showSpinner = false;
          this.translate.get("MESSAGES.Failed").subscribe({
            next: (res) => {
              this.notificationsService.error(res);
            },
            error: err => {
            }
          });
        });
  }

  deleteProjectInformation(item) {
    this.showSpinner = true;
    this.projectService.DeleteProjectTaskLocation(item.id)
      .subscribe({
        next: (response: ResponseBase) => {
          this.translate.get("MESSAGES.Deleted").subscribe({
            next: (res) => {
              this.notificationsService.create(res);
              this.GetProjectTaskLocation(item.projectId)
            },
            error: err => {
            }
          });
        },
        error: err => {
          this.showSpinner = false;
          this.translate.get("MESSAGES.Failed").subscribe({
            next: (res) => {
              this.notificationsService.error(res);
            },
            error: err => {
            }
          });
        },
        complete: () => {
          this.showSpinner = false;
        }
      });
  }

  GetProjectTaskLocation(item) {
    this.showSpinner = true;
    this.requestModel.ProjectID = item;
    this.projectService.GetProjectTaskLocation<ResponseBase>(this.requestModel)
      .subscribe({
        next: (response: ResponseBase) => {
          this.showSpinner = false;
          if (response && response.result && response.result.length > 0) {
            this.projectInformation = response.result;
            this.isEditDelete = true;
          }
        },
        error: err => {
          this.showSpinner = false;
        },
        complete: () => {
        }
      })
  }

  closePopup() {
    if (this.isEditDelete)
      this.dialogRef.close(true);
    else
      this.dialogRef.close(false);
  }

}
