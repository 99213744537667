import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'convertToHoursAndMinutes'})

export class ConvertToHoursAndMinutesPipe implements PipeTransform {

  transform(totalMinutes: number): string {
    if (totalMinutes > 0) {
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;

      if (hours > 0 && minutes > 0) {
        return `${hours} Timer ${ Math.ceil(minutes)} min`;
      } 
      else if (hours > 0) {
        return `${hours} Timer`;
      } 
      else {
        return `${Math.ceil(minutes)} min`;
      }
    }
  }
  
}