import { Component, Inject, OnInit, Optional } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { MaterialService } from '../../material.service';
import { ResponseBase } from 'app/main/model/ResponseBase';

//translate
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';


@Component({
    selector: 'copy-material',
    templateUrl: './copyMaterial.component.html',
    styleUrls: ['./copyMaterial.component.scss']
})

export class CopyMaterialComponent implements OnInit {
    showSpinner: boolean = false;
    firstBarcode: number;
    secondBarcode: number;
    materialNumber: any;
    isAddToOffer: boolean = false;
    constructor(private translationLoader: FuseTranslationLoaderService,
        private materialService: MaterialService,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<CopyMaterialComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
        this.translationLoader.loadTranslations(danish, english);
    }

    ngOnInit() {
        this.isAddToOffer  = (this.data && this.data.isAddToOffer) ? this.data.isAddToOffer : false;
        this.GetMaximumMaterialNumber();
    }

    GetMaximumMaterialNumber() {
        this.showSpinner = true;
        this.materialService.GetMaximumMaterialNumber().subscribe((response: ResponseBase) => {
            if (response) {
                this.showSpinner = false;
                this.materialNumber = response.result.number + 1;
            }
        });
    }

    onCloseDialog() {
        this.dialogRef.close({ "materialNumber": this.materialNumber, "firstBarcode": this.firstBarcode, "secondBarcode": this.secondBarcode })
    }
}