export const locale = {
    lang: 'en',
    data: {
        'FIELDS': {
            'startDate': 'Start Date ',
            'visit': 'Visit',
            'Coworker': 'Coworker'
        },
        'BUTTONS': {
            'BACK': 'Back',
            'SAVE': 'Save',
            'Undo': 'Undo'
        },
    }
};