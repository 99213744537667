import { Component, OnInit, Inject, ViewChild, NgZone, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs/Observable';
import { startWith, map } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl, ValidatorFn } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { FuseTranslationLoaderService } from '../../../core/services/translation-loader.service';

import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';

import { ResponseBase } from '../../model/ResponseBase';
import { RequestCoWorker, RequestReminder } from '../../model/RequestBase';
import { AuthService } from '../../../core/services/auth.service';
import { ReminderService } from './reminder.service';
import { CoWorker } from '../../model/CoWorker';
import { Reminder, ReminderFull } from '../../model/Reminder';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, DateAdapter, MAT_DATE_LOCALE, MatInput, MatOption, DEC } from '@angular/material';
import { ReminderRepeatModes, ReminderListType } from '../../model/Reminder';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { CoWorkersService } from '../coWorker/coWorkers.service';
import { DeleteAlertModalComponent } from '../delete-alert-modal/delete-alert-modal.component';
import { AlertMessageModelComponent } from '../alert-message-model/alert-message-model.component';
import { TitleService } from '../../../core/services/title.service';
import { ReminderReplayDialog } from 'app/main/shared-component/replay/replay.component';
import { VibeEditReminderComponent } from './component/edit/edit-reminder.component';
import { SignalRService } from 'app/core/services/signalR.service';


@Component({
    selector: 'app-reminder',
    templateUrl: './reminder.component.html',
    styleUrls: ['./reminder.component.scss'],
})
export class VibeReminderComponent implements OnInit, OnDestroy {

    currentselectedcoWorkerObj: any = {};
    myCoWorkerControl = new FormControl();
    //filteredCoWorkerOptions: Observable<string[]>;
    coWokers: CoWorker[] = [];
    ReminderObject: ReminderFull;
    selectedSearchCoworkerID: string = "";
    selectedSearchCoworkerName: string = "";
    filteredCoWorkerOptions: Observable<CoWorker[]>;
    reminderForm: FormGroup;
    reminderForm2: FormGroup;
    today: Date = new Date();
    Reminders: Reminder[] = [];
    MyReminders: Reminder[] = [];
    DistinctReminders: Reminder[] = [];
    DashboardReminders: Reminder[] = [];
    displayPagination: boolean;
    displayPaginationReceiveToMePagination: boolean = false;
    pagination: any;
    receiveToMePagination: any;
    text: any = '';
    reminderDate: any = '';
    selectedStartDateForFilter: any;
    selectedEndDateForFilter: any;
    orderBy: string = "DATE DESC";
    limitNumber: any;
    ShowReminderForm: boolean = false;
    ReminderListType: RequestReminder;
    displayDeleteAll: boolean = false;
    //Status: boolean = true;
    //date: Date = new Date();
    settings = {
        bigBanner: true,
        timePicker: true,
        format: 'dd-MM-yyyy',
        defaultOpen: true
    }
    panelOpenState = false;
    showSpinnerReceivedToMe: boolean = false;
    reminderRepeatModes = ReminderRepeatModes;
    reminderListTypes = ReminderListType;
    findCoworkerReminderList: Reminder[] = [];
    loggedInCoworkerID: string = "";

    //@ViewChild('input', { read: MatInput, static: true }) input: MatInput;
    @ViewChild('allSelected', { static: true }) private allSelected: MatOption;

    searchUserForm: FormGroup;
    allReminderDone: boolean = false;
    getAllReminder: boolean = false;
    getAllSendByMeReminder: boolean = false;
    notDoneReminderCount: number = 0;
    sendByMePgIndex: any;
    sendByMePgLimit: any;
    receiveByMePgIndex: any;
    receiveByMePgLimit: any;
    showPagination = {
        top: true,
        bottom: false
    }
    filterValues = {
        text: "",
        CoworkerId: "",
        StartDate: null,
        EndDate: null,
        orderBy: "DATE DESC",
        Limit: 100
    }
    roleClaims: any;
    showSpinner: boolean = false;
    constructor(private translationLoader: FuseTranslationLoaderService,
        private coWorkersService: CoWorkersService,
        private _router: Router,
        private formBuilder: FormBuilder,
        private authService: AuthService,
        private reminderService: ReminderService,
        private datePipe: DatePipe,
        public dialog: MatDialog,
        private titleService: TitleService,
        private adapter: DateAdapter<any>,
        private translate: TranslateService,
        private notificationsService: NotificationsService,
        private signalRService: SignalRService,
        private _ngZone: NgZone
    ) {
        this.titleService.SetTitle("TITLE_REMINDERS");
        this.translationLoader.loadTranslations(english, danish);
        this.roleClaims = this.authService.getRoleClaimsList();
    }
    

    ngOnInit() {
        this.selectedSearchCoworkerID = this.authService.getCoWorkerId();
        this.loggedInCoworkerID = this.authService.getCoWorkerId();
        //this.sendByMeReminder(new RequestReminder());
        this.ReminderObject = new ReminderFull();
        // var coworkerOBJ = new RequestCoWorker();
        // coworkerOBJ.Name = "";
        // this.getCoWorkers(coworkerOBJ);
        //this.reminderForm = this.formBuilder.group({
        //  Text: [null, Validators.required],
        //  //CoworkerId: [null, Validators.required],
        //  Date: [null, Validators.required]
        //});
        this.validation();
        this.adapter.setLocale('da');

        this.searchUserForm = this.formBuilder.group({
            userType: new FormControl(''),
            searchName: [null, !Validators.required],
        });
        setTimeout(() => {
            //this.receivedToMeReminder(new RequestReminder());
        }, 500);
        // var requestReminder = new RequestReminder();
        // requestReminder.ReminderDate = new Date().toString();
        //this.getDeshboardReminder(requestReminder);
        this.selectedEndDateForFilter = new Date();
        this.selectedStartDateForFilter = new Date(new Date().setMonth(this.selectedEndDateForFilter.getMonth()-1));
        setTimeout(() => {
            this.serach();            
        }, 5000);
    }

    validation() {
        this.reminderForm = this.formBuilder.group({
            Text: [null, Validators.required],
            //CoworkerId: [null, Validators.required],
            Date: [null, Validators.required],
            Time: [null, Validators.required],
        });

        this.reminderForm2 = this.formBuilder.group({
            ReminderEndDate: [null, Validators.required],
            ReminderEndTime: [null, Validators.required],
        })
    }

    // getCoWorkers(model: RequestCoWorker) {
    //     model.OrderBy = "Name";
    //     this.coWorkersService.Get<ResponseBase>(model)
    //         .subscribe((response: ResponseBase) => {
    //             if (response) {
    //                 this.coWokers = response.result.filter(x => x.doNotShowOnPlanReminderProj == false);
    //                 this.getNextRequest(response.pagination, model, response.result);
    //             }
    //             else {
    //                 this.coWokers = [];
    //             }
    //         },
    //             error => (err) => {
    //                 this.coWokers = [];
    //             },
    //             () => {

    //             });
    // }

    // getNextRequest(pagination, model, response) {
    //     if (pagination.index == 1) {
    //         this.coWokers = response.filter(x => x.doNotShowOnPlanReminderProj == false);
    //     }
    //     else {
    //         response = response.filter(x => x.doNotShowOnPlanReminderProj == false);
    //         this.coWokers.push(...response);
    //     }
    //     model.Index = pagination.index + 1;
    //     model.Limit = pagination.limit;
    //     model.TotalRows = pagination.totalCount;
    //     if (pagination.totalCount > pagination.index * pagination.limit)
    //         this.getCoWorkers(model);
    // }

    sendByMeReminder(model: RequestReminder) {
        this.displayDeleteAll = false;
        this.showSpinner = true;
        //var model = new RequestReminder();
        model.StartDate = this.datePipe.transform(this.selectedStartDateForFilter, "yyyy-MM-dd");
        model.EndDate = this.datePipe.transform(this.selectedEndDateForFilter, "yyyy-MM-dd");
        model.Text = this.text;
        model.CreatedBy = this.selectedSearchCoworkerID;
        model.IsDeleted = false;
        model.OrderBy = this.orderBy;
        model.getAll = this.getAllSendByMeReminder;
        model.Index = this.sendByMePgIndex ? this.sendByMePgIndex : model.Index;
        model.Limit = this.sendByMePgLimit ? this.sendByMePgLimit : model.Limit;
        this.reminderService.GetReminderListWithMutipleFiles<ResponseBase>(model)
            .subscribe({
                next: (response: ResponseBase) => {

                    // var todayRemider = response.result.filter(o => this.datePipe.transform(o.reminderDate, "yyyy-MM-dd") == this.datePipe.transform(new Date(), "yyyy-MM-dd"));
                    // //this.reminderService.totalReminderCount(todayRemider);
                    // if (todayRemider) {
                    //   this.authService.setReminderCount(todayRemider.length);
                    // }
                    // else {
                    //   this.authService.setReminderCount(0);
                    // }
                    if (response != null) {
                        this.displayDeleteAll = true;
                        this.Reminders = response.result;
                        this.pagination = response.pagination;
                        this.findCoworkerReminderList = response.result;
                        this.displayPagination = true;
                    }
                    else {
                        this.Reminders = [];
                        this.displayPagination = false;
                    }
                    this.showSpinner = false;
                    this.sendByMePgIndex = "";
                    this.sendByMePgLimit = "";
                },
                error: err => {
                    this.Reminders = [];
                    this.showSpinner = false;
                    this.displayPagination = false;
                },
                complete: () => {
                }
            })
    }

    receivedToMeReminder(model: RequestReminder) {
        this.showSpinnerReceivedToMe = true;
        model.Text = this.text;
        model.StartDate = this.datePipe.transform(this.selectedStartDateForFilter, "yyyy-MM-dd");
        model.EndDate = this.datePipe.transform(this.selectedEndDateForFilter, "yyyy-MM-dd");
        model.CoworkerId = this.selectedSearchCoworkerID;
        model.IsDeleted = false;
        model.OrderBy = this.orderBy;
        model.getAll = this.getAllReminder;
        model.Index = this.receiveByMePgIndex ? this.receiveByMePgIndex : model.Index;
        model.Limit = this.receiveByMePgLimit ? this.receiveByMePgLimit : model.Limit;
        // if (!this.getAllReminder) {
        //   model.getAll = false;
        // }
        this.reminderService.GetReminderListWithMutipleFiles<ResponseBase>(model)
            .subscribe({
                next: (response: ResponseBase) => {
                    if (response) {
                        this.MyReminders = [];
                        this.MyReminders = response.result;
                        this.receiveToMePagination = response.pagination;
                        this.displayPaginationReceiveToMePagination = true;

                        var todayRemider = response.result.filter(o => this.datePipe.transform(o.reminderDate, "yyyy-MM-dd") == this.datePipe.transform(new Date(), "yyyy-MM-dd"));
                        if (this.loggedInCoworkerID == this.selectedSearchCoworkerID) {
                            if (todayRemider) {
                                this.authService.setReminderCount(todayRemider.length);
                            }
                            else {
                                this.authService.setReminderCount(0);
                            }
                        }
                        var MyRemindersCount = 0;
                        MyRemindersCount = this.MyReminders.filter((x, i) => { return x.reminderStatus == false; }).length;
                        if (MyRemindersCount > 0) {
                            this.allReminderDone = false;
                        } else {
                            this.allReminderDone = true;
                        }
                    }
                    else {
                        this.MyReminders = [];
                        this.displayPaginationReceiveToMePagination = false;
                    }
                    this.receiveByMePgLimit = "";
                    this.receiveByMePgIndex = "";
                    this.showSpinnerReceivedToMe = false;
                },
                error: err => {
                    this.MyReminders = [];
                },
                complete: (
                ) => {
                    this.showSpinnerReceivedToMe = false;
                }
            })
    }

    togglePerOne() {
        this.filterValues.CoworkerId = this.selectedSearchCoworkerID;
        // this.sendByMeReminder(new RequestReminder());
        // setTimeout(() => {
        //     this.receivedToMeReminder(new RequestReminder());
        // }, 500);
    }

    deleteAllSentReminder(model: RequestReminder) {
        this.showSpinner = true;
        model.StartDate = this.datePipe.transform(this.selectedStartDateForFilter, "yyyy-MM-dd");
        model.EndDate = this.datePipe.transform(this.selectedEndDateForFilter, "yyyy-MM-dd");
        model.CreatedBy = this.authService.getCoWorkerId();
        model.IsDeleted = false;
        model.OrderBy = this.orderBy;
        this.reminderService.DeleteAllSentReminders<ResponseBase>(model)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.Reminders = [];
                    this.displayPagination = false;
                    this.showSpinner = false;
                    this.sendByMeReminder(new RequestReminder());
                },
                error: err => {
                    this.Reminders = [];
                    this.showSpinner = false;
                    this.displayPagination = false;
                },
                complete: () => {
                }
            })
    }

    serach() {
        this.filterValues.text = this.text;
        this.filterValues.CoworkerId = this.selectedSearchCoworkerID;
        this.filterValues.StartDate = this.selectedStartDateForFilter ? this.datePipe.transform(this.selectedStartDateForFilter, "yyyy-MM-dd") : null;
        this.filterValues.EndDate = this.selectedEndDateForFilter ? this.datePipe.transform(this.selectedEndDateForFilter, "yyyy-MM-dd") : null;
        this.filterValues.orderBy = this.orderBy;
        this.authService.reminderfilterSearch(this.filterValues);
    }

    ViewPastReminder(){
        // this.startDate = moment(moment().subtract(6, 'months')).format('DD/MM/YYYY');
        // this.endDate = moment().format('DD/MM/YYYY');  
        this.selectedEndDateForFilter = new Date();
        this.selectedStartDateForFilter = new Date(new Date().setMonth(this.selectedEndDateForFilter.getMonth()-6));
        this.filterValues.StartDate = moment(moment().subtract(6, 'months')).format('YYYY-MM-DD');
        this.filterValues.EndDate =  moment().format('YYYY-MM-DD');;
        this.filterValues.Limit=100000;
        this.authService.reminderfilterSearch(this.filterValues);
      }

    //All Reminder Done 
    // allReminderListDone(allReminderDone) {

    //   var message = "";
    //   var allDoneMsg = 'Headers.AllReminderDone';
    //   var removeAllDoneMsg = 'Headers.NotDoneAllReminder';
    //   var isDoneAll = this.MyReminders.filter((x, i) => { return x.reminderStatus == false; }).length //this.MyReminders.find(o => o.reminderStatus == true);

    //   if (isDoneAll > 0)
    //     message = allDoneMsg;
    //   else
    //     message = removeAllDoneMsg;

    //   const dialogRef = this.dialog.open(AlertMessageModelComponent, {
    //     height: '200px',
    //     data: { Header: message }
    //   });
    //   dialogRef.afterClosed().subscribe(result => {
    //     if (result) {
    //       //this.showSpinner = true;
    //       this.showSpinnerReceivedToMe = true;
    //       var tempMyReminders = this.MyReminders.map(function (a) {
    //         return a.id;
    //       });
    //       this.reminderService.ChangeAllReminderStatus<ResponseBase>(tempMyReminders, this.allReminderDone)
    //         .subscribe({
    //           next: (response: ResponseBase) => {
    //             this.showSpinnerReceivedToMe = false;
    //             if (this.allReminderDone) {
    //               this.getSuccessTone();
    //             }
    //             this.getAllReminder = false;
    //             var request = new RequestReminder();
    //             //if (this.ReminderListType.ReminderListType == "ReceivedToMe")
    //             this.receivedToMeReminder(request);
    //             this.GetNotDoneReminderCounts();

    //             var requestReminder = new RequestReminder();
    //             requestReminder.ReminderDate = new Date().toString();
    //             this.getDeshboardReminder(requestReminder);
    //           },
    //           error: err => { this.showSpinnerReceivedToMe = false; },
    //           complete: (
    //           ) => {
    //             this.showSpinnerReceivedToMe = false;
    //             //this._router.navigateByUrl('materials');
    //           }
    //         });
    //     }
    //     else {
    //       this.showSpinnerReceivedToMe = false;
    //       var MyRemindersCount = 0;
    //       MyRemindersCount = this.MyReminders.filter((x, i) => { return x.reminderStatus == false; }).length;
    //       if (MyRemindersCount > 0) {
    //         this.allReminderDone = false;
    //       } else {
    //         this.allReminderDone = true;
    //       }
    //     }
    //   });
    // }



    AllSendByMeReminderDone() {
        this.sendByMeReminder(new RequestReminder());
        this.GetNotDoneReminderCounts();

        var requestReminder = new RequestReminder();
        requestReminder.StartDate = new Date().toString();
        requestReminder.EndDate = new Date().toString();
        //this.getDeshboardReminder(requestReminder);
    }

    GetNotDoneReminderCounts() {
        this.reminderService.GetNotDoneReminderCountApi<ResponseBase>(this.authService.getCoWorkerId())
            .subscribe({
                next: (response: ResponseBase) => {
                    if (response != null) {
                        this.notDoneReminderCount = response.result;
                        //this.reminderService.setNotDoneReminderCount(response.result);
                        this.authService.setNotDoneReminderCount(this.notDoneReminderCount);
                    }
                },
                error: err => {
                },
                complete: (
                ) => {
                }
            });
    }



    ReminderForm() {
        this.ShowReminderForm = true;
        //this.ReminderObject.reminderDate = this.datePipe.transform(new Date(), "yyyy-MM-dd");
        //this.ReminderObject.reminderTime = this.datePipe.transform(new Date(), "HH:mm");
        this._router.navigate(["/reminder/add"]);
    }

    Cancel() {
        this.ShowReminderForm = false;
        this.sendByMeReminder(this.ReminderListType);
    }

    OptionChange(event, value) {
        if (event.source.selected)
            this.ReminderObject.coWorkerId = value;
    }

    // private _filterCoworker(value: string): CoWorker[] {
    //     const filterValue = value.toLowerCase();
    //     return this.coWokers.filter(mat => mat.name.toLowerCase().includes(filterValue));
    // }

    // DropDownSearch(value) {
    //     var request = new RequestCoWorker();
    //     if (value != undefined) {
    //         request.Name = value;
    //         this.getCoWorkers(request);
    //     }
    // }

    // getCoworkerOnClear(value) {
    //     if (!value) {
    //         this.DropDownSearch(value);
    //     }
    // }

    deleteReminder(objReminder) {
        this.showSpinner = true;
        this.reminderService.Delete<ResponseBase>(objReminder.id)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.Reminders.splice(this.Reminders.findIndex(r => r.id == objReminder.id), 1);
                    //this.Reminders = this.Reminders;
                    this.showSpinner = false;
                    this.sendByMeReminder(new RequestReminder());
                    this.receivedToMeReminder(new RequestReminder());
                    this.GetNotDoneReminderCounts();
                    var requestReminder = new RequestReminder();
                    requestReminder.StartDate = new Date().toString();
                    requestReminder.EndDate = new Date().toString();
                    //this.getDeshboardReminder(requestReminder);

                },
                error: err => {
                    this.showSpinner = false;
                },
                complete: () => {
                    this.showSpinner = false;
                }
            });
    }

    updateReminder(id, reminder) {
        this.showSpinner = true;
        //this.showSpinnerReceivedToMe = true;
        //reminder.reminderDate = this.datePipe.transform(reminder.reminderDate, "yyyy-MM-dd")
        reminder.reminderDate = moment.utc(reminder.reminderDate).format("YYYY-MM-DD HH:mm");

        if(!this.ReminderObject.reminderDate)
            this.ReminderObject.reminderDate="";

        const formData = new FormData();
        // if (selectedFiles && selectedFiles.length) {            
        //     for (var i = 0; i < selectedFiles.length; i++) {
        //         formData.append("file[]", selectedFiles[i]);
        //     }
        // }
        for ( var key in this.ReminderObject ) {
            formData.append(key, this.ReminderObject[key]);
        }

        this.reminderService.UpdateReminderWithFiles<ResponseBase>(id, formData)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.showSpinnerReceivedToMe = false;
                    this.ShowReminderForm = false;
                    this.translate.get("MESSAGES.Updated").subscribe({
                        next: (res) => {
                            this.notificationsService.success(res);
                        },
                        error: err => {
                        }
                    });
                    //this.sendByMeReminder(new RequestReminder());
                    this.sendMessage(id)
                },
                error: err => {
                    this.showSpinnerReceivedToMe = false;
                },
                complete: () => {
                    this.showSpinnerReceivedToMe = false;
                }
            });
    }



    sendByPaginatonEvent(event) {
        this.sendByMePgIndex = "";
        this.sendByMePgLimit = "";
        var request = new RequestReminder();
        request.Index = event.pageIndex;
        request.Limit = event.pageSize;
        //this.limitNumber = event.pageSize;
        request.Text = this.text.trim();
        //request.Status = this.Status;
        this.sendByMeReminder(request);
        //this.CreateReminderRequest(request);
    }

    receiveToMePaginationEvent(event) {
        var request = new RequestReminder();
        request.Index = event.pageIndex;
        request.Limit = event.pageSize;
        //this.limitNumber = event.pageSize;
        request.Text = this.text.trim();
        this.receivedToMeReminder(request);
    }

    CreateReminderRequest(request: RequestReminder) {
        //if (this.text != null)
        //  request.Name = this.text.trim();
        //if (this.number != null)

        //  request.Number = this.number;
        //if (this.orderBy != undefined)
        //  request.OrderBy = this.orderBy;
        request.Text = this.text.trim();

        //request.Limit = this.limitNumber
        if (this.ReminderListType.ReminderListType == "ReceivedToMe")
            this.receivedToMeReminder(request);
        else
            this.sendByMeReminder(request);
    }

    SearchByName(value) {
        //var request = new RequestReminder();
        // this.sendByMeReminder(new RequestReminder());
        // setTimeout(() => {
        //     this.receivedToMeReminder(new RequestReminder());
        // }, 500);
        this.filterValues.text = value;
    }

    openFileEditDialog(value) {
        var data = value.data;
        this.sendByMePgIndex = value.pagination.index;
        this.sendByMePgLimit = value.pagination.limit;
        let objData = Object.assign({}, data)
        if (data.reminderDate) {
            objData.reminderTime = moment.utc(objData.reminderDate).format("HH:mm") == "00:00" ? "" : moment.utc(objData.reminderDate).format("HH:mm");
            objData.reminderDate = moment.utc(objData.reminderDate).format("YYYY-MM-DD");
        }
        const dialogRef = this.dialog.open(VibeEditReminderComponent, {
            height: '400px',
            width: '800px',
            data: objData,
            autoFocus: false
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                var reminder = this.Reminders.find(r => r.id == result.id);
                if (reminder) {
                    var objUpdate = new Reminder();
                    objUpdate.coWorkerId = result.coWorkerId;
                    objUpdate.reminderDate = result.reminderDate;
                    objUpdate.text = result.text;
                    objUpdate.replay = result.replay;
                    objUpdate.reminderStatus = result.reminderStatus;
                    this.updateReminder(reminder.id, objUpdate);
                    reminder.text = result.text;
                }
            }
        });
    }

    openFileDeleteDialog(data) {
        this.deleteReminder(data);
    }



    openAllDeleteReminder() {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: '200px',
            data: { Header: 'Headers.DeleteAllReminder' }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.deleteAllSentReminder(new RequestReminder());
            }
        });
    }

    ChangeReminderStatus(id: any, value: boolean) {
        this.reminderService.ChangeReminderStatus<ResponseBase>(id, value)
            .subscribe({
                next: (response: ResponseBase) => {
                    var index = this.MyReminders.findIndex(c => c.id == response.result.id);
                    this.MyReminders.splice(index, 1);
                    this.MyReminders.splice(index, 0, response.result);
                    var MyRemindersCount = this.MyReminders.filter((x, i) => { return x.reminderStatus == false; }).length;
                    if (MyRemindersCount > 0) {
                        this.allReminderDone = false;
                    } else {
                        this.allReminderDone = true;
                    }
                    this.GetNotDoneReminderCounts();
                    var requestReminder = new RequestReminder();
                    requestReminder.StartDate = new Date().toString();
                    requestReminder.EndDate = new Date().toString();
                    //this.getDeshboardReminder(requestReminder);
                    //this.sendByMeReminder(new RequestReminder());
                    this.sendMessage(id)
                },
                error: err => { },
                complete: (
                ) => {
                    //this._router.navigateByUrl('materials');
                }
            });
    }

    onUpdateStartReminderDate(e) {
        this.filterValues.StartDate = this.selectedStartDateForFilter;
    }

    onUpdateEndReminderDate(e) {
        this.filterValues.EndDate = this.selectedEndDateForFilter;
    }

    ResetDate() {
        this.selectedStartDateForFilter = null;
        this.selectedEndDateForFilter = null;
        this.text = "";
        this.onUpdateStartReminderDate(null);
        this.onUpdateEndReminderDate(null);
    }
    receiveFilterEvent(event) {
        var request = new RequestReminder();
        this.orderBy = event;
        // this.receivedToMeReminder(request);
        // this.sendByMeReminder(request);
        this.filterValues.orderBy = event;
    }

    ChangeOnRepeatCheckbox(isRepeat) {
        this.ReminderObject.isRepeat = isRepeat;
        if (this.ReminderObject.isRepeat)
            this.ReminderObject.reminderEndTime = this.datePipe.transform(new Date(), "HH:mm");
        else
            this.ReminderObject.reminderEndTime = "";
    }

    onReminderListTypeChange(reminderListType) {
        if (reminderListType == "ReceivedToMe")
            this.receivedToMeReminder(new RequestReminder());
        else
            this.sendByMeReminder(new RequestReminder());
    }

    Onchangethis() {
    }

    toggleAllSelection() {
        // if (this.allSelected.selected) {
        //   this.searchUserForm.controls.userType
        //     .patchValue([...this.coWokers.map(item => item.id), 0]);
        // } else {
        //   this.searchUserForm.controls.userType.patchValue([]);
        // }
        //if (this.ReminderListType.ReminderListType == "ReceivedToMe")
        this.receivedToMeReminder(new RequestReminder());
        //else
        this.sendByMeReminder(new RequestReminder());
    }


    getSuccessTone() {
        let audio = new Audio();
        audio.src = "../../../../../../assets/soundEffect/ClappingCrowd.mp3";
        audio.load();
        audio.play();
    }

    ChangeImage(event) {
        this.ReminderObject.image = event;
    }

    sendMessage(data: any): void {
        this.signalRService.sendMessage(data);
    }
    private subscribeToEvents(): void {
      this.signalRService.messageReceived.subscribe((data: any) => {
        this._ngZone.run(() => {
          this.sendByMeReminder(new RequestReminder());
        });
      });
    }

    ngOnDestroy(): void {
        this.authService.reminderfilterSearch(null);
    }
}



// @Component({
//     selector: 'reminder-edit-dialog',
//     templateUrl: './reminder-edit-dialog.html'
// })
// export class ReminderEditDialog {
//     objReminder: ReminderFull;

//     constructor(
//         public dialogRef: MatDialogRef<ReminderEditDialog>,
//         private datePipe: DatePipe,
//         @Inject(MAT_DIALOG_DATA) public data: any) {
//     }

//     ngOnInit() {
//         this.objReminder = new ReminderFull();
//         this.objReminder.text = this.data.text;
//         this.objReminder.replay = this.data.replay;
//         this.objReminder.reminderDate = this.data.reminderDate;
//         this.objReminder.reminderTime = this.data.reminderTime;
//         //this.adapter.setLocale('da');
//     }
//     updateReminder() {
//         this.data.text = this.objReminder.text;
//         this.data.replay = this.objReminder.replay ? this.objReminder.replay : "";
//         this.data.reminderDate = this.datePipe.transform(this.objReminder.reminderDate, "yyyy-MM-dd") //this.objReminder.reminderDate;
//         if (this.objReminder.reminderTime)
//             this.data.reminderDate = moment.utc(this.data.reminderDate + " " + this.objReminder.reminderTime).format("YYYY-MM-DD HH:mm");
//         this.dialogRef.close(this.data)
//     }
//     onCloseDialog(e) {
//         this.dialogRef.close(e ? this.data : false)
//     }
// }
