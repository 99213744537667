import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiConstant } from 'app/core/constant/ApiConstant';
import { AuthService } from 'app/core/services/auth.service';
import { OfferColorCodeDTO, OfferQualityTestCategory, OfferHumidityandTemperature, OfferDefectRoomDTO } from 'app/main/model/OfferQualityTestCategory';
import { RequestForQualityTestImagesFilter, RequestOfferColorCode, RequestOfferQualityTestAnswer } from 'app/main/model/RequestBase';
import { OfferQualityTestAnswer } from 'app/main/model/OfferQualityTestAnswer';


@Injectable({
    providedIn: 'root'
})
export class QualityTestService implements Resolve<any>
{
    private offertaskActionUrl: string;
    constructor(private http: HttpClient, private authService: AuthService) {
        this.offertaskActionUrl = ApiConstant.API_URL + ApiConstant.QUALITYTEST + "/";
    }

    public AddOfferColorCode<T>(projectOffer: OfferColorCodeDTO): Observable<T> {
        return this.http.post<T>(this.offertaskActionUrl + "CreateOfferColorCode", projectOffer);
    }

    public AddOfferQualityTestCategory<T>(offerQualityTestCategory: OfferQualityTestCategory): Observable<T> {
        return this.http.post<T>(this.offertaskActionUrl, offerQualityTestCategory);
    }

    public AddOfferQualityTestAnswer<T>(offerQualityTestAnswer: OfferQualityTestAnswer): Observable<T> {
        return this.http.post<T>(this.offertaskActionUrl + "CreateOfferQualityTestAnswer", offerQualityTestAnswer);
    }

    public GetOfferColorCode<T>(Request: RequestOfferColorCode): Observable<T> {
        let caseParam = this.GetParams(Request);
        return this.http.get<any>(this.offertaskActionUrl + "GetOfferColorCodeList", {
            params: caseParam
        });
    }

    public GetOfferQualityTestAnswerList<T>(Request: RequestOfferQualityTestAnswer): Observable<T> {
        let caseParam = this.GetParams(Request);
        return this.http.get<any>(this.offertaskActionUrl + "GetOfferQualityTestAnswerList", {
            params: caseParam
        });
    }

    public GetQualityTestCategoryList(Request: any): Observable<any> {
        let caseParam = this.GetParams(Request);
        return this.http.get<any>(this.offertaskActionUrl, {
            params: caseParam
        });
    }

    public GetFilesFRomPath(Request: any): Observable<any> {
        let caseParam = this.GetParams(Request);
        return this.http.get<any>(this.offertaskActionUrl + "ReadFolderByPath", {
            params: caseParam
        });
    }

    public UpdateQualityTestCategory(id: string, qualityTest: OfferQualityTestCategory): Observable<any> {
        return this.http.put<any>(this.offertaskActionUrl + id, qualityTest);
    }

    public UpdateOfferQualityTestAnswer(id: string, qualityTest: OfferQualityTestCategory): Observable<any> {
        return this.http.put<any>(this.offertaskActionUrl + "UpdateOfferQualityTestAnswer/" + id, qualityTest);
    }

    public GetParams(param) {
        let params: HttpParams = new HttpParams();
        for (var key in param) {
            if (param.hasOwnProperty(key)) {
                let val = param[key];
                params = params.append(key, val);
            }
        }
        return params;
    }

    public UploadImages(formData: FormData) {
        return this.http.post(this.offertaskActionUrl + "UploadImages", formData, { headers: { IsContentType: "true" } });
    }

    public GetHumidityandTemperatureList(Request: any): Observable<any> {
        let caseParam = this.GetParams(Request);
        return this.http.get<any>(this.offertaskActionUrl + "getHumidityandTemperatureList", {
            params: caseParam
        });
    }

    public AddHumidityandTemperature(obj: OfferHumidityandTemperature): Observable<any> {
        return this.http.post<any>(this.offertaskActionUrl + "addHumidityandTemperature", obj);
    }

    public UpdateHumidityandTemperature(obj: OfferHumidityandTemperature): Observable<any> {
        return this.http.put<any>(this.offertaskActionUrl + "UpdateHumidityandTemperature/"+obj.id, obj);
    }

    public DeleteHumidityandTemperature(id: string) {
        return this.http.delete(this.offertaskActionUrl + "DeleteHumidityandTemperature/" + id);
    }

    public UpdateOfferColorCode(offerColorCodeDto: OfferColorCodeDTO): Observable<any> {
        return this.http.put(this.offertaskActionUrl + "UpdateOfferColorCode/" + offerColorCodeDto.id, offerColorCodeDto);
    }

    public DeleteOfferColorCode(id: string) {
        return this.http.delete(this.offertaskActionUrl + "DeleteOfferColorCode/" + id);
    }

    public DeleteOfferQualityTestCategory(id: string) {
        return this.http.delete(this.offertaskActionUrl + id);
    }

    public GetDefectRooms(Request: any): Observable<any> {
        let caseParam = this.GetParams(Request);
        return this.http.get<any>(this.offertaskActionUrl + "GetQualityTestErrorsAndDeffectsList", {
            params: caseParam
        });
    }

    public AddDefectRooms(obj: OfferDefectRoomDTO): Observable<any> {
        return this.http.post<any>(this.offertaskActionUrl + "AddQualityTestErrorsAndDeffects", obj);
    }

    public UpdateDefectRooms(obj: OfferDefectRoomDTO): Observable<any> {
        return this.http.put<any>(this.offertaskActionUrl + "UpdateOfferQualityTestErrorsAndDefects/"+obj.id, obj);
    }

    public DeleteDefectRooms(id: string) {
        return this.http.delete(this.offertaskActionUrl + "DeleteOfferQualityTestErrorsAndDefects/"+id);
    }

    public AddOfferQualityTestCustomerQuestions<T>(offerQualityTestAnswer: OfferQualityTestAnswer): Observable<T> {
        return this.http.post<T>(this.offertaskActionUrl + "CreateOfferQualityTestCustomerQuestions", offerQualityTestAnswer);
    }

    public GetOfferQualityTestCustomerQuestionsList<T>(Request: any): Observable<any> {
        let caseParam = this.GetParams(Request);
        return this.http.get<any>(this.offertaskActionUrl + "GetOfferQualityTestCustomerQuestionsList", {
            params: caseParam
        });
    }

    public DeleteOfferQualityTestCustomerQuestion(id: string) {
        return this.http.delete(this.offertaskActionUrl + "DeleteOfferQualityTestCustomerQuestion/" + id);
    }

    public DownloadOfferQualityTestCustomerQuestionsPDF<T>(Request: any): Observable<any> {
        let caseParam = this.GetParams(Request);
        return this.http.get<any>(this.offertaskActionUrl + "DownloadOfferQualityTestCustomerQuestionsPDF", {
            params: caseParam
        });
    }

    public DeleteOfferQualityTestAnswer(id: string) {
        return this.http.delete(this.offertaskActionUrl + "DeleteOfferQualityTestAnswer/"+id);
    }
    
    public GetOffersBeforeAndAfterImages<T>(Request: RequestForQualityTestImagesFilter): Observable<any> {
        let caseParam = this.GetParams(Request);
        return this.http.get<any>(this.offertaskActionUrl + "GetOffersBeforeAndAfterImages", {
            params: caseParam
        });
    }

    /**
     * Resolve
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    }
}
