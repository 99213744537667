import { Component, OnInit } from '@angular/core';

import { FuseTranslationLoaderService } from '../../../core/services/translation-loader.service';

import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';

import { EventEmitterEnum } from '../../../core/enum/event-emitter-ids.enum';
import { MapsAPILoader } from '@agm/core';
import { TitleService } from '../../../core/services/title.service';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class VibeHelpComponent implements OnInit {

  constructor(private translationLoader: FuseTranslationLoaderService, private titleService: TitleService) {
    this.titleService.SetTitle("TITLE_USER_MANUAL");
    this.translationLoader.loadTranslations(english, danish);
  }

  ngOnInit() {

  }

}

