export const locale = {
    lang: 'ds',
    data: {
        'Headers': {

        },
        'BUTTON': {
            'Go': 'Gå',
            'Cancel': 'NEJ'
        },
    }
};
