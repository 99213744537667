import { Component, OnInit, ChangeDetectorRef, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { QuestionParms, RequisitionStatusAnswer } from 'app/main/model/Project';

import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { ActivatedRoute } from '@angular/router';
import { ProjectService } from 'app/main/pages/project/project.service';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { FormService } from 'app/core/services/form.service';

@Component({
    selector: "answer-status-question",
    templateUrl: "./answer-status-question.component.html",
    styleUrls: ["./answer-status-question.component.scss"]
})

export class AnswerStatusQuestionComponent implements OnInit {
    showSpinner: boolean = false;
    requisitionStatusAnswerObj = new RequisitionStatusAnswer()
    questionList = new QuestionParms();
    currentStep: number;
    selectedQuestion: any
    totalCount: number = 0;
    statusQuestionform: FormGroup;
    //requisitionId: string = "";

    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private dialogRef: MatDialogRef<AnswerStatusQuestionComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _changeDetectorRef: ChangeDetectorRef,
        private _route: ActivatedRoute,
        private projectService: ProjectService,
        private translate: TranslateService,
        private notificationsService: NotificationsService,
        private formBuilder: FormBuilder,
        private formService: FormService) {
        translationLoaderService.loadTranslations(danish, english);
        this.currentStep = 0;
    }

    ngOnInit() {
        this.statusQuestionform = this.formBuilder.group({
            Answer: [null]
        });
        this.selectedQuestion = [];
        this.questionList = this.data.data;
        this.requisitionStatusAnswerObj.requisitionId = this.data.requisitionId;
        this.requisitionStatusAnswerObj.lastStatus = this.data.lastStaus;
        this.requisitionStatusAnswerObj.newStatus = this.data.newStatus;
        this.requisitionStatusAnswerObj.projectId = this.data.projectId;
        this.requisitionStatusAnswerObj.audienceType = this.data.data.audienceType;
        this.requisitionStatusAnswerObj.indoorOutdoorStatusId = this.data.data.indoorOutdoorStatusId;

        if (this.questionList.question) {
            this.questionList.statusQuestion = JSON.parse(this.questionList.question);
            this.totalCount = this.questionList.statusQuestion.length;
            this.selectedQuestion = this.questionList.statusQuestion[0];
            if (this.selectedQuestion.questionType == 'MultiChoice') {
                this.validateAnswer();
            }
        }
    }

    onSelectRadioBtn(selectdValue, i) {
        selectdValue.choiceOptions.forEach(item => {
            item.checked = false;
        });
        selectdValue.choiceOptions[i].checked = true;
    }

    gotoNextStep(value): void {
        this.formService.markFormGroupTouched(this.statusQuestionform);
        if (this.statusQuestionform.invalid) {
            return;
        }
        var index = value + 1
        this.currentStep = index;
        if (this.currentStep < this.totalCount) {
            this.selectedQuestion = this.questionList.statusQuestion[this.currentStep];
            if (this.selectedQuestion.questionType == 'MultiChoice') {
                this.validateAnswer();
            }
        }
    }

    gotoPreviousStep(value): void {
        var index = value - 1
        this.currentStep = index;
        if (this.currentStep == -1) {
            this.currentStep = 0;
            return
        }
        else {
            this.currentStep = index
        }
        this.selectedQuestion = this.questionList.statusQuestion[this.currentStep];
    }

    SaveAnswer() {
        this.showSpinner = true;
        this.requisitionStatusAnswerObj.json = JSON.stringify(this.questionList.statusQuestion);
        this.projectService.AddStatusAnswer(this.requisitionStatusAnswerObj).subscribe({
            next: (response: ResponseBase) => {
                if (response) {
                    this.showSpinner = false;
                    this.close();
                    this.translate.get("MESSAGES.Created").subscribe({
                        next: (res) => {
                            this.notificationsService.create(res);
                        },
                        error: err => {
                        }
                    });
                }
            },
            error: err => {
                this.showSpinner = false;
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            },
            complete: () => {

            }
        });

        //localStorage.setItem("StatusAnswer", JSONData);
    }

    close() {
        this.dialogRef.close(true);
    }

    getSelectedRating(selectedQuestion) {
        if (selectedQuestion.rating) {
            this.selectedQuestion.rating = selectedQuestion.rating;
        }
    }

    validateAnswer() {
        for (let i = 0; i < this.selectedQuestion.choiceOptions.length; i++) {
            if (this.selectedQuestion.questionType == 'MultiChoice' && this.selectedQuestion.choiceOptions[i].answerType == 1 && this.selectedQuestion.choiceOptions[i].checked == true) {
                const statusQuestionControl = this.statusQuestionform.get('Answer');
                statusQuestionControl.setValidators([Validators.required]);
                statusQuestionControl.updateValueAndValidity();
                this.formService.markFormGroupTouched(this.statusQuestionform);
            }
        }
    }

    setValidation(checked) {
        if (checked == true) {
            this.validateAnswer();
        }
    }
}