export const locale = {
    lang: 'ds',
    data: {
      'Headers': {
          'ChooseFile': 'Vælg fil',
          'NoFileSelected': 'Ingen fil valgt',
          'BeforeImages': 'Før billeder',
          'AfterImages': 'Efter billeder',
          'AllImages': 'Alle billeder'
      },
      'orderByStatus':{
        'value1':'Dato ↑',
        'value2':'Dato ↓'
      }
    }
  };
  