export const locale = {
  lang: 'en',
  data: {
    'Tabs': {
      'Offer': 'Offer',
      'BasicDetail': 'Basic Detail',
      'File': 'File',
      'Category': 'Category',
      'WorkItem':'Work Item',
      'ColorCode':'Color Code',
      'CustomerColorCode':'Customer ColorCode',
    },
    'MESSAGE': {
      'Failed': 'Operation failed , try later',
      'Success': 'Success.',
      'CommingSoon':'Comming Soon..',
      'Deleted': 'Deleted successfully',
      'Added': 'Created successfully',
      'Updated': 'Updated successfully',
      'NORECORD': 'Ingen colorcode fundet',
    },
    'FIELDS':{
      'GoTo':'Go to',
      'ColorCode':'Color Code',
      'SRNO': 'Sr No',
      'Status': 'Status',
      'Number': 'Number',
      'Name': 'Name',
      'Createdby': 'Created By',
      'CreatedDate': 'Created Date',
      'ModifyBy': 'Modify By',
      'ModifyDate': 'Modify Date',
      'CoworkerName': 'Coworker',
      'Date': 'Date',
        'Description': 'Description',
        'All': 'All',
        'RoomName': 'Room Name',
        'Text': 'Text',
        'SelectRoom':'Select Room',
    },
    'BUTTONS': {
      'Add': 'Add',
      'Update':'Update'
    },
    'Headers': {
      'DeleteCategory': 'Do you want to delete category',
    },
    'TITLE': {
      'CustomerPagination': 'Antal log pr. side',
  },
  }
};
