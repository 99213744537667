import { OnInit, Component, Input } from '@angular/core';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'app/core/services/auth.service';
import { ProjectService } from 'app/main/pages/project/project.service';

import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { RequestCustomerLog, RequestMessageLogs, RequestProjectLogs } from 'app/main/model/RequestBase';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { CustomersService } from 'app/main/pages/customer/customer.service';
import { CustomerDetailComponent } from 'app/main/pages/customer/component/cutomerDetailModal/customerDetail.component';
import { MatDialog } from '@angular/material';
import { environment } from '../../../../environments/environment';
import { AnswerStatusLogComponent } from '../answer-status-log/answer-status-log.component';

@Component({
  selector: 'vibe-message-log',
  templateUrl: 'message-log.component.html',
  styleUrls: ['./message-log.component.scss']
})
export class MessageLogComponent implements OnInit {

  showSpinner = false;
  @Input() ProjectID: string = null;
  @Input() CustomerID: string = null;
  requestModel: RequestMessageLogs = new RequestMessageLogs();
  messageLogData: any = [];
  displayPagination: boolean;
  pagination: any;
  limitNumber: any;
  constructor(private translationLoader: FuseTranslationLoaderService,
    private _router: Router,
    private translate: TranslateService,
    private authService: AuthService,
    private projectservice: ProjectService,
    private customersService: CustomersService,
    public dialog: MatDialog) {
    this.translationLoader.loadTranslations(english, danish);
  }

  ngOnInit() {
    this.requestModel.Index = 1;
    this.requestModel.Limit = 200;
    this.requestModel.OrderBy = "Date DESC";
    this.requestModel.ProjectId = this.ProjectID ? this.ProjectID : '';
    this.requestModel.CustomerId = this.CustomerID ? this.CustomerID : '';
    // this.requestModel.InquiryID = this.InquiryID;
    // this.requestModel.OfferID = this.OfferID;
    this.getCustomerLogs();
  }

  getCustomerLogs() {
    this.showSpinner = true;
    this.projectservice.GetMessageLogs<ResponseBase>(this.requestModel)
      .subscribe({
        next: (response: ResponseBase) => {
          this.showSpinner = false;
          if (response != null && response.result) {
            this.messageLogData = response.result;
            this.pagination = response.pagination;
            this.displayPagination = true;
          }
          else {
            this.messageLogData = [];
            this.displayPagination = false;
          }
        },
        error: err => {
          this.showSpinner = false;
          this.messageLogData = [];
          this.displayPagination = false;
        },
        complete: () => { }
      });
  }

  receivePaginatonEvent(event) {
    this.requestModel.Index = event.pageIndex;
    this.requestModel.Limit = event.pageSize;
    this.limitNumber = event.pageSize;
    this.getCustomerLogs();
  }
}
