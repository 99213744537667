export const locale = {
    lang: 'ds',
    data: {
        'Headers': {
            "Title": "Pris rabat",
            "DeleteWeather": "Sagsøger du, at du vil slette dette vejr?"
        },
        "FIELDS": {
            'SRNO': 'Sr nr.',
            "StartPrice": "Start pris",
            "EndPrice": "Slut pris",
            "Percentage":"Procent",
            "Edit": 'Redigere',
            "Delete": 'Slet',
            'IndoorOutDoor': 'Ind, ud, Sprøjt, Reklamation *',
            "StartDay": "Startdag DD/MM",
            "EndDay": "Slutdag DD/MM",
            "Status": "Status",
            "TotalDays": "Dage i alt",
            "TotalWorkingDays": "Samlede arbejdsdage",
            "CurrentPrice": "Nuværende pris",
        },
        "BUTTONS": {
            "Add": "Tilføj",
            'Back': 'Tilbage'
        },
        'MESSAGES': {
            'Added': 'Tilføjet med succes.',
            'Updated': 'Opdateret succesfuldt.',
            'Failed': 'Mislykkedes',
            'Deleted': 'Slettet med succes',
            'NORECORD': 'Intet optage fundet'
        },
        "VALIDATIONS": {
            'StartPrice': 'Start pris er påkrævet',
            'EndPrice': 'End pris er påkrævet',
            'Percentage': 'Indtast percentage',
            'ValidPercentage': 'Indtast gyldig percentage',
            'IndoorOutDoor': 'Vælg en hvilken som helst status',
            "StartDay": "Startdag er påkrævet",
            "EndDay": "Slutdag er påkrævet",
            "ValidStartDay": "Indtast gyldig startdag",
            "ValidEndDay": "Indtast gyldig slutdag",
        },
        'selectedValueIndoorOutdoorStatus': {
            'value1': 'Indendørs ',
            'value2': 'Udendørs',
            'value3': 'Sprøjtemaling',
            'value4': 'Reklamation',
            'value5': 'Personale køb', //drawing of painter
            'value6': 'Butiks køb'     //Nordsjø logo
        },
    }
}