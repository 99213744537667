export const locale = {
  lang: 'ds',
  data: {
    'FIELDS': {
      'Name': 'Navn',
      'EnterName': 'Indtast Navn',
      'EnterPrice': 'Indtast Pris',
      'Price': 'Pris',
      'Ranks': 'Rækker',
      'PurchasePrice': 'Indkøbspris',
      'AmountOfWages': 'beløb arbejdsløn',
      'SalePrice': 'Udsalgs Pris',
      'EnterSalePrice': 'Indtast Udsalgs Pris',
      'EnterRank': 'Enter Rank',
      'EnterPurchasePrice': 'Indtast Indkøbspris',
      'EnterAmountOfWages': 'Indtast beløb arbejdsløn',
      'OfferCategoryWorkItem': 'Tilbud kategori arbejde element',
    },
    'BUTTONS': {
      'SAVE': 'Gem',
      'BACK': 'Gå tilbage',
    },
  }
};
