export const locale = {
    lang: 'ds',
    data: {
        'TITLE': {
        },
        'FIELDS': {
            'IsSendEmail': 'Er Send e-mail ?',
            'IsSendSMS': 'Er Send SMS ?',
            //'NoteLabel':'Fjern venligst ikke @signerede ord fra sms tekst,e-mail skabelon tekst og notifikation tekst.'
        },
        'Message': {

        },
        'FILTERS': {
        },
        'moduleTypeList': {
        },
        'TOOTIPORTITLE':{
            'IsSendEmail':'Er Send E-mail',
            'IsSendNotification':'Er Send notifikation',
            'IsSendSMS':'Er Send SMS',
        }
    }
};