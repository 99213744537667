import { Injectable } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export class ErrorService {

  constructor() { }

  public GetEmailError(key) {
    return key.hasError('required') ? 'You must enter a value' :
      key.hasError('email') ? 'Not a valid email' :
        '';
  }
}
