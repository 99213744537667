import { Component, OnInit, Optional } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { MatDialog, MatDialogRef } from '@angular/material';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { ProjectService } from 'app/main/pages/project/project.service';
import { ResponseBase } from 'app/main/model/ResponseBase';
//translate
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';

@Component({
    selector: "vibe-user-role-list",
    templateUrl: "./user-role-list.component.html",
    styleUrls: ["./user-role-list.component.scss"]
})

export class UserRoleListComponent implements OnInit {
    @Optional() public dialogRef: MatDialogRef<UserRoleListComponent>;
    public showSpinner: boolean = false;
    RolesList: any = [];
    SelectedRole: any = {};
    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private dialog: MatDialog,
        private notificationsService: NotificationsService,
        private translate: TranslateService,
        private projectService: ProjectService,
        ) {
        translationLoaderService.loadTranslations(danish, english);
    }

    ngOnInit() {
        this.GetUserRoleList();
    }

    GetUserRoleList() {
        this.showSpinner = true;
        this.projectService.GetUserRoleList().subscribe({
            next: (response: ResponseBase) => {
                if (response) {
                    this.showSpinner = false;
                    this.RolesList = response.result.myResponse;
                }
            },
            error: err => {
                this.showSpinner = false;
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    Update() {
        this.showSpinner = true;
        this.projectService.updateRole<ResponseBase>(this.SelectedRole.id, this.SelectedRole).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.result) {
                    this.translate.get("MESSAGES.Updated").subscribe({
                        next: (res) => {
                            this.notificationsService.create(res);
                        },
                        error: err => { }
                    });
                    this.SelectedRole = null;
                }
            },
            error: err => {
                this.showSpinner = false;
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    edit(role) {
        this.SelectedRole = role;
    }

    gotoBack(){
        this.dialog.closeAll();
    }
}