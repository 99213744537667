import { Component, OnInit, Host, EventEmitter, Output } from '@angular/core';
import { Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { FormGroup, FormBuilder } from '@angular/forms';
import { WorkhoursService } from 'app/main/pages/workHour/workhours.service';
import { ShowSdailyReportModalComponent } from 'app/main/pages/workHour/show-sdaily-report-modal/show-sdaily-report-modal.component';
import { WorkHour } from 'app/main/model/WorkHour';
import { WorkHourDiaglogOpen } from 'app/main/model/common';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { RequestWorkHour,  RequestProjectRequisitionByCowokrer } from 'app/main/model/RequestBase';
import { ProjectRequisitionService } from 'app/main/pages/project/project.requisition.service';
import { projectRequisition } from 'app/main/model/Project';
import { WorkHourStatus } from 'app/main/model/WorkHourStatus';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { CommonService } from 'app/core/services/common.service';		
import { ProjectStatus } from 'app/main/model/ProjectStatus';
import { AuthService } from 'app/core/services/auth.service';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';
import { ProjectofferService } from 'app/main/pages/project-offer/project-offer.service';

@Component({
    selector: 'app-work-hour-modal-popup',
    templateUrl: './work-hour-modal-popup.component.html',
    styleUrls: ['./work-hour-modal-popup.component.scss']
})

export class WorkHourModalPopupComponent implements OnInit {
    @Output() jsonDataChanges = new EventEmitter<any>();
    shiftHours: any = [];
    hours: any;
    mins: any;
    workHourDiaglogOpen: WorkHourDiaglogOpen;
    show: boolean = false;
    workHours: WorkHour[] = [];
    allWorkHoursOfCoworker: WorkHour[] = [];
    workHour: WorkHour;
    onAdd = new EventEmitter();
    workHourValidation: FormGroup;
    total: number = 0;
    isTimeAdddedByShif: boolean = false;
    ShowManualHours: boolean = false;
    showSpinner: boolean = false;
    showSpinnerWorkAdd: boolean = false;
    RequisitionList: projectRequisition[] = [];
    selectStatus = WorkHourStatus;
    isEditedWorkhours: boolean = false;
    isAddBreakTime: boolean = true;
    reqStatus: number;
    StatusForReq = ProjectStatus;
    ShowAddHourButton: boolean = false;
    isFromTimeBetween: boolean = false;
    isToTimeIsBetween: boolean = false;
    isBreakTime: boolean = false;
    selectedOfferTypeIDs: string[] = []
    Date: any;
    Day: any;
    roleClaims: any;
    getOfferRoomDetailListinfo :any;
    showSpinnerForWorkHoursData:boolean = false;
    workHoursExtraInfo: string = "";
    projectWorkHourIsCompleted :  any[] = [];
    flagIsRoomSelected: boolean = false;
    constructor(private translationLoader: FuseTranslationLoaderService, private workhoursService: WorkhoursService
        , public dialogRef: MatDialogRef<WorkHourModalPopupComponent>,
        @Inject(MAT_DIALOG_DATA) public data: WorkHourDiaglogOpen, private dialog: MatDialog,
        //private atp: AmazingTimePickerService, 
        private fb: FormBuilder,
        private projectRequisitionService: ProjectRequisitionService,
        private translate: TranslateService,
        private notificationsService: NotificationsService,
        private authService: AuthService,
        private projectofferService: ProjectofferService,
        private commonService: CommonService) {

        //super("Work-hours", EventEmitterEnum.OnLoadMainContentWelcomPage);
        this.translationLoader.loadTranslations(english, danish);

        this.roleClaims = this.authService.getRoleClaimsList();
        this.workHourDiaglogOpen = data;
        this.workHour = new WorkHour();
    }


    ngOnInit() {
        this.workHourValidation = this.fb.group({
            //startHour: ['', Validators.required],
            //startMinute: ['', Validators.required],
            //endHour: ['', Validators.required],
            //endMinute: ['', Validators.required],
            //text: [null, !Validators.required],
        });

        this.hours = [{ value: '00' }, { value: '01' }, { value: '02' }, { value: '03' }, { value: '04' }, { value: '05' }, { value: '06' }, { value: '07' }, { value: '08' },
        { value: '09' }, { value: '10' }, { value: '11' }, { value: '12' }, { value: '13' }, { value: '14' }, { value: '15' }, { value: '16' }, { value: '17' },
        { value: '18' }, { value: '19' }, { value: '20' }, { value: '21' }, { value: '22' }, { value: '23' }];

        this.mins = [{ value: '00' }, { value: '15' }, { value: '30' }, { value: '45' }];
        //this.mins = [{ key: '00', value: '00' }, { key: '25', value: '15' }, { key: '50', value: '30' }, { key: '75', value: '45' }]

        //super.RegisterEventEmitterForPage();
        this.Get(new RequestWorkHour());
        this.GetAllCoworkerWorkHours(new RequestWorkHour());
        this.getRequisitionList(new RequestProjectRequisitionByCowokrer());


        //to display only last staus in status dropdown
        var newValue = this.selectStatus.find(c => c.isWorking == true);
        this.selectStatus = [];
        this.selectStatus.push(newValue);
        this.Date = moment(this.workHourDiaglogOpen.date, "YYYY-MM-DD").format("DD.MM.YYYY");
        this.Day = moment(this.workHourDiaglogOpen.date).format('dddd');
        this.translate.get("Days." + this.Day).subscribe({
            next: (name) => {
                this.Day = name;
            },
            error: err => {
            }
        });
    }

    Get(model: RequestWorkHour) {
        this.showSpinner = true;
        model.StartDate = this.workHourDiaglogOpen.date;
        model.EndDate = this.workHourDiaglogOpen.date;
        model.ProjectId = this.workHourDiaglogOpen.projectId;
        model.CoworkerId = this.workHourDiaglogOpen.workerId;
        model.OrderBy = "ID";
        this.workhoursService.GetWorkHours<ResponseBase>(model)
            .subscribe({
                next: (response: ResponseBase) => {

                    if (response) {
                        this.workHours = response.result;
                        
                        // this.workHours.forEach(item => {
                        //   var fh: any; var fmin: any; var th: any; var tmin: any;
                        //   var tempFrom = item.from.split(':');
                        //   fh = tempFrom[0];
                        //   fmin = tempFrom[1];
                        //   if (fmin != '00') {
                        //     fmin = (fmin * 100) / 60
                        //     item.from = fh + ":" + fmin;
                        //   }
                        //   var tempTo = item.to.split(':');
                        //   th = tempTo[0];
                        //   tmin = tempTo[1];
                        //   if (tmin != '00') {
                        //     tmin = (tmin * 100) / 60
                        //     item.to = th + ":" + tmin;
                        //   }
                        // });

                        this.showSpinner = false;
                    }
                    else {
                        this.workHours = [];
                        this.showSpinner = false;
                    }
                },
                error: err => {
                    this.workHours = [];
                    this.showSpinner = false;
                },
                complete: () => {
                    this.GetTotalHours();
                }
            });
    }

    GetAllCoworkerWorkHours(model: RequestWorkHour) {
        this.showSpinner = true;
        model.StartDate = this.workHourDiaglogOpen.date;
        model.EndDate = this.workHourDiaglogOpen.date;
        model.CoworkerId = this.workHourDiaglogOpen.workerId;
        model.OrderBy = "ID";
        this.workhoursService.GetWorkHours<ResponseBase>(model)
            .subscribe({
                next: (response: ResponseBase) => {
                    if (response) {
                        this.allWorkHoursOfCoworker = response.result;
                        this.showSpinner = false;
                    }
                    else {
                        this.allWorkHoursOfCoworker = [];
                        this.showSpinner = false;
                    }
                },
                error: err => {
                    this.allWorkHoursOfCoworker = [];
                    this.showSpinner = false;
                },
                complete: () => {
                }
            });
    }

    UpdateRequisitionStatus() {
        var requisition = this.RequisitionList.find(o => o.id == this.workHour.sagOpgaveID);
        if (requisition) {
            requisition.status = this.reqStatus;
            this.projectRequisitionService.Update(requisition.projectId, requisition.id, requisition).subscribe({
                next: (response: ResponseBase) => {
                    if (response) {
                        this.reqStatus = response.result.status;
                        this.translate.get("MESSAGES.Updated").subscribe({
                            next: (res) => {
                                this.notificationsService.create(res);
                            },
                            error: err => {
                            }
                        });
                    }
                },
                error: err => {
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });
                }
            });
        }
    }

    // 2 table change
    Add() {
        var isAlreadyApplyWorkHours = false;
        var workhoursdate;
        if (this.workHourDiaglogOpen.nonWorkHoursList && this.workHourDiaglogOpen.nonWorkHoursList.length > 0) {
            this.workHourDiaglogOpen.nonWorkHoursList.filter(p => {
                if (!isAlreadyApplyWorkHours && moment(p.date).format("YYYY-MM-DD") == this.workHourDiaglogOpen.date) {
                    if (this.ShowManualHours && !(p.status == 1 || p.status == 4 || p.status == 5)) {
                        var fromTimer = this.workHourDiaglogOpen.date + " " + this.workHour.fromStartHour + ":" + this.workHour.fromStartMinute;
                        var startTime = moment(p.date).format("YYYY-MM-DD") + " " + p.from;
                        var endTime = moment(p.date).format("YYYY-MM-DD") + " " + p.to;
                        var today = new Date(fromTimer).getTime();
                        var from = new Date(startTime).getTime();
                        var to = new Date(endTime).getTime();
                        var withinRange = today >= from && today < to;
                        console.log("withinRange", withinRange);
                        if (withinRange) {
                            isAlreadyApplyWorkHours = true;
                            workhoursdate = this.workHourDiaglogOpen.date;
                            return;
                        }
                    }
                    else {
                        isAlreadyApplyWorkHours = true;
                        workhoursdate = this.workHourDiaglogOpen.date;
                        return;
                    }
                }
            });
        }
        if (this.workHourDiaglogOpen.projectWithWorkHoursList && this.workHourDiaglogOpen.projectWithWorkHoursList.length > 0) {
            this.workHourDiaglogOpen.projectWithWorkHoursList.filter(p => {
                if (p.workHoursList && p.workHoursList.length > 0) {
                    var tempDateArray = p.workHoursList.filter(w => (moment(w.date).format("YYYY-MM-DD") == this.workHourDiaglogOpen.date));
                    if (tempDateArray && tempDateArray.length > 0 && p.isActive) {
                        tempDateArray.filter(whs => {
                            if (this.ShowManualHours) {
                                var fromTimer = this.workHourDiaglogOpen.date + " " + this.workHour.fromStartHour + ":" + this.workHour.fromStartMinute;
                                var startTime = moment(whs.date).format("YYYY-MM-DD") + " " + whs.from;
                                var endTime = moment(whs.date).format("YYYY-MM-DD") + " " + whs.to;
                                var today = new Date(fromTimer).getTime();
                                var from = new Date(startTime).getTime();
                                var to = new Date(endTime).getTime();
                                var withinRange = today >= from && today < to;
                                console.log("withinRange", withinRange);
                                if (withinRange) {
                                    isAlreadyApplyWorkHours = true;
                                    workhoursdate = this.workHourDiaglogOpen.date;
                                    return;
                                }
                            }
                            else {
                                if(whs.hours >= 7){
                                    isAlreadyApplyWorkHours = true;
                                    workhoursdate = this.workHourDiaglogOpen.date;
                                    return;
                                }
                            }
                        })
                    }
                }
            });
        }
        if (isAlreadyApplyWorkHours) {
            this.translate.get("MESSAGES.CannotApplyLeave").subscribe({
                next: (res) => {
                    var msg = res.replace("@date", moment(workhoursdate, "YYYY.MM.DD").format("DD.MM.YYYY"));
                    this.notificationsService.error(msg);
                },
                error: err => {
                }
            });
        }
        else {
            if (this.reqStatus == 8 || this.reqStatus == 9 || this.reqStatus == 12) {
                this.workHour.projectId = this.workHourDiaglogOpen.projectId;
                this.workHour.workerId = this.workHourDiaglogOpen.workerId;
                this.workHour.date = this.workHourDiaglogOpen.date;
                this.workHour.isWorking = true;

                if (!this.isTimeAdddedByShif) {
                    this.workHour.from = this.workHour.fromStartHour + ":" + this.workHour.fromStartMinute;
                    this.workHour.to = this.workHour.fromEndHour + ":" + this.workHour.fromEndMinute;
                    if (this.workHours.length == 0) {
                        this.workHours = [];
                    }
                    var wh = new WorkHour();
                    wh.sagOpgaveID = this.workHour.sagOpgaveID;
                    wh.projectId = this.workHourDiaglogOpen.projectId;
                    wh.workerId = this.workHourDiaglogOpen.workerId;
                    wh.date = this.workHourDiaglogOpen.date;
                    wh.isWorking = true;
                    wh.id = null;
                    wh.from = this.workHour.fromStartHour + ":" + this.workHour.fromStartMinute;
                    wh.to = this.workHour.fromEndHour + ":" + this.workHour.fromEndMinute;
                    var time = moment.utc(moment(this.workHour.to, "HH:mm").diff(moment(this.workHour.from, "HH:mm"))).format("HH:mm")
                    var tidTime = moment(time, "HH:mm").format("mm");
                    var tidHours = moment(time, "HH:mm").format("HH");
                    if (tidTime != "00")
                        time = tidHours + ":" + (Number(this.getMinute(tidTime))).toString();
                    wh.Tid = Number(time.replace(':', '.'));
                    if (this.isAddBreakTime)
                        wh.pauseTimer = 0.5;
                    else
                        wh.pauseTimer = 0;
                    wh.shiftType = 4;
                    //wh.Hours = time.replace(':', '.');
                    wh.Hours = (Number(time.replace(':', '.')) - wh.pauseTimer).toString();
                    wh.text = this.workHour.text;
                    //new code 2019-11-05
                    wh.requisitionTimerID = null
                    wh.offerID = null;
                    wh.offerTypeID = this.selectedOfferTypeIDs;
                    //this.AddWorkhourAPICall(wh);
                    this.checkMoreThan7HoursOnFriday(wh);
                    //this.workHours.push(wh);
                    //var wortkHourSort = this.workHours.sort(function (a, b) {
                    //  if (a.from < b.from) { return -1; }
                    //  if (a.from > b.from) { return 1; }
                    //  return 0;
                    //});
                    //for (var i = 0; i < wortkHourSort.length; i++) {
                    //  //if (i == 0) {
                    //  //  var tidHoure = moment.utc(moment(wortkHourSort[i].to, "HH:mm").diff(moment(wortkHourSort[i].from, "HH:mm"))).format("HH");
                    //  //  var tidMinute = moment.utc(moment(wortkHourSort[i].to, "HH:mm").diff(moment(wortkHourSort[i].from, "HH:mm"))).format("mm");
                    //  //  try {
                    //  //    tidMinute = ((Number(tidMinute) * 100) / 60).toString();
                    //  //  } catch (e) {
                    //  //    tidMinute = "00";
                    //  //  }
                    //  //  var time = tidHoure + ":" + tidMinute;
                    //  //  wortkHourSort[i].Tid = Number(time.replace(':', '.'));
                    //  //  //wortkHourSort[i].PauseTimer = 0;
                    //  //  wortkHourSort[i].Hours = time.replace(':', '.');
                    //  //}
                    //  //else {
                    //  //  var tidHoure = moment.utc(moment(wortkHourSort[i].to, "HH:mm").diff(moment(wortkHourSort[i - 1].to, "HH:mm"))).format("HH");
                    //  //  var tidMinute = moment.utc(moment(wortkHourSort[i].to, "HH:mm").diff(moment(wortkHourSort[i - 1].to, "HH:mm"))).format("mm");
                    //  //  try {
                    //  //    tidMinute = ((Number(tidMinute) * 100) / 60).toString();
                    //  //  } catch (e) {
                    //  //    tidMinute = "00";
                    //  //  }
                    //  //  var tidTime = tidHoure + ":" + tidMinute;
                    //  //  wortkHourSort[i].Tid = Number(tidTime.replace(':', '.'));

                    //  //  var pauseHoure = moment.utc(moment(wortkHourSort[i].from, "HH:mm").diff(moment(wortkHourSort[i - 1].to, "HH:mm"))).format("HH");
                    //  //  var pauseMinute = moment.utc(moment(wortkHourSort[i].from, "HH:mm").diff(moment(wortkHourSort[i - 1].to, "HH:mm"))).format("mm");
                    //  //  try {
                    //  //    pauseMinute = ((Number(pauseMinute) * 100) / 60).toString();
                    //  //  } catch (e) {
                    //  //    pauseMinute = "00";
                    //  //  }
                    //  //  var puseTime = pauseHoure + ":" + pauseMinute;
                    //  //  wortkHourSort[i].pauseTimer = Number(puseTime.replace(':', '.'));

                    //  //  tidTime = moment.utc(moment(wortkHourSort[i].to, "HH:mm").diff(moment(wortkHourSort[i - 1].to, "HH:mm"))).format("HH:mm");
                    //  //  puseTime = moment.utc(moment(wortkHourSort[i].from, "HH:mm").diff(moment(wortkHourSort[i - 1].to, "HH:mm"))).format("HH:mm");
                    //  //  var hoursHoure = moment.utc(moment(tidTime, "HH:mm").diff(moment(puseTime, "HH:mm"))).format("HH");
                    //  //  var hoursMinute = moment.utc(moment(tidTime, "HH:mm").diff(moment(puseTime, "HH:mm"))).format("mm");
                    //  //  try {
                    //  //    hoursMinute = ((Number(hoursMinute) * 100) / 60).toString();
                    //  //  } catch (e) {
                    //  //    hoursMinute = "00";
                    //  //  }
                    //  //  var hoursTime = hoursHoure + ":" + hoursMinute;
                    //  //  wortkHourSort[i].Hours = hoursTime.replace(':', '.');
                    //  //}
                    //  this.AddWorkhourAPICall(wortkHourSort[i]);
                    //}
                }
                if (this.shiftHours.length > 0) {
                    var shiftType = this.shiftHours[0].shiftType;
                    this.workHour.shiftType = shiftType;
                    if (shiftType == 2) {
                        this.workHour.from = "07:00";
                        this.workHour.to = "14:30";
                        this.workHour.Tid = 7.5;
                        this.workHour.pauseTimer = 0.5;
                        this.workHour.Hours = "7";
                        this.workHour.requisitionTimerID = null
                        this.workHour.offerID = null;
                        this.workHour.offerTypeID = this.selectedOfferTypeIDs;
                        //this.AddWorkhourAPICall(this.workHour);
                        this.checkMoreThan7HoursOnFriday(this.workHour);
                    }
                    else if (shiftType == 3) {
                        this.workHour.from = "07:00";
                        this.workHour.to = "15:00";
                        this.workHour.Tid = 8.00;
                        this.workHour.pauseTimer = 0.5;
                        this.workHour.Hours = "07.5";
                        this.workHour.requisitionTimerID = null
                        this.workHour.offerID = null;
                        this.workHour.offerTypeID = this.selectedOfferTypeIDs;
                        //this.AddWorkhourAPICall(this.workHour);
                        this.checkMoreThan7HoursOnFriday(this.workHour);
                    }
                }
            }
            else {
                this.translate.get("MESSAGES.CanNotAddHour").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                        this.commonService.playFailedTone();
                    },
                    error: err => {
                    }
                });
            }
        }
    }
    updateworkhours() {
        if (this.reqStatus == 8 || this.reqStatus == 9 || this.reqStatus == 12) {
            this.workHour.from = this.workHour.fromStartHour + ":" + this.workHour.fromStartMinute;
            this.workHour.to = this.workHour.fromEndHour + ":" + this.workHour.fromEndMinute;
            if (this.isAddBreakTime)
                this.workHour.pauseTimer = 0.5;
            else
                this.workHour.pauseTimer = 0;
            //this.AddWorkhourAPICall(this.workHour);
            //for (var i = 0; i < this.workHours.length; i++) {
            //  if (this.workHours[i].id == this.workHour.id) {
            //    this.workHours[i].from = this.workHour.fromStartHour + ":" + this.workHour.fromStartMinute;
            //    this.workHours[i].to = this.workHour.fromEndHour + ":" + this.workHour.fromEndMinute;
            //  }
            //}
            var wortkHourSort = [];
            wortkHourSort.push(this.workHour);
            for (var i = 0; i < wortkHourSort.length; i++) {
                if (i == 0) {
                    var tidHoure = moment.utc(moment(wortkHourSort[i].to, "HH:mm").diff(moment(wortkHourSort[i].from, "HH:mm"))).format("HH");
                    var tidMinute = moment.utc(moment(wortkHourSort[i].to, "HH:mm").diff(moment(wortkHourSort[i].from, "HH:mm"))).format("mm");
                    try {
                        tidMinute = ((Number(tidMinute) * 100) / 60).toString();
                    } catch (e) {
                        tidMinute = "00";
                    }
                    var time = tidHoure + ":" + tidMinute;
                    wortkHourSort[i].Tid = Number(time.replace(':', '.'));
                    //wortkHourSort[i].PauseTimer = 0;
                    //wortkHourSort[i].Hours = time.replace(':', '.');
                    wortkHourSort[i].Hours = (Number(time.replace(':', '.')) - wortkHourSort[i].pauseTimer).toString();
                }
                else {
                    var tidHoure = moment.utc(moment(wortkHourSort[i].to, "HH:mm").diff(moment(wortkHourSort[i - 1].to, "HH:mm"))).format("HH");
                    var tidMinute = moment.utc(moment(wortkHourSort[i].to, "HH:mm").diff(moment(wortkHourSort[i - 1].to, "HH:mm"))).format("mm");
                    try {
                        tidMinute = ((Number(tidMinute) * 100) / 60).toString();
                    } catch (e) {
                        tidMinute = "00";
                    }
                    var tidTime = tidHoure + ":" + tidMinute;
                    wortkHourSort[i].Tid = Number(tidTime.replace(':', '.'));

                    var pauseHoure = moment.utc(moment(wortkHourSort[i].from, "HH:mm").diff(moment(wortkHourSort[i - 1].to, "HH:mm"))).format("HH");
                    var pauseMinute = moment.utc(moment(wortkHourSort[i].from, "HH:mm").diff(moment(wortkHourSort[i - 1].to, "HH:mm"))).format("mm");
                    try {
                        pauseMinute = ((Number(pauseMinute) * 100) / 60).toString();
                    } catch (e) {
                        pauseMinute = "00";
                    }
                    var puseTime = pauseHoure + ":" + pauseMinute;
                    wortkHourSort[i].pauseTimer = Number(puseTime.replace(':', '.'));

                    tidTime = moment.utc(moment(wortkHourSort[i].to, "HH:mm").diff(moment(wortkHourSort[i - 1].to, "HH:mm"))).format("HH:mm");
                    puseTime = moment.utc(moment(wortkHourSort[i].from, "HH:mm").diff(moment(wortkHourSort[i - 1].to, "HH:mm"))).format("HH:mm");
                    var hoursHoure = moment.utc(moment(tidTime, "HH:mm").diff(moment(puseTime, "HH:mm"))).format("HH");
                    var hoursMinute = moment.utc(moment(tidTime, "HH:mm").diff(moment(puseTime, "HH:mm"))).format("mm");
                    try {
                        hoursMinute = ((Number(hoursMinute) * 100) / 60).toString();
                    } catch (e) {
                        hoursMinute = "00";
                    }
                    var hoursTime = hoursHoure + ":" + hoursMinute;
                    //wortkHourSort[i].Hours = hoursTime.replace(':', '.');
                    wortkHourSort[i].Hours = (Number(hoursTime.replace(':', '.')) - wortkHourSort[i].pauseTimer).toString();
                }
                this.AddWorkhourAPICall(wortkHourSort[i]);
            }
        }
        else {
            this.translate.get("MESSAGES.CanNotAddHour").subscribe({
                next: (res) => {
                    this.notificationsService.error(res);
                    this.commonService.playFailedTone();
                },
                error: err => {
                }
            });
        }
    }

    CreateShiftHours(index) {
        this.workHour.from = this.shiftHours[index].from;
        this.workHour.to = this.shiftHours[index].to;
        this.workHour.shiftType = this.shiftHours[index].shiftType;
        this.workHour.text = this.workHour.text;
    }

    AddWorkhourAPICall(workHour) {
        //this.checkMoreThan7HoursOnFriday(workHour);
        this.showSpinnerWorkAdd = true;
        if(this.workHoursExtraInfo){
         workHour.extraInfo = JSON.stringify(this.workHoursExtraInfo);
        }
        this.workhoursService.Add<ResponseBase>(workHour)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.showSpinnerWorkAdd = false;
                    this.translate.get("MESSAGES.Add").subscribe({
                        next: (res) => {
                            this.notificationsService.success(res);
                        },
                        error: err => {
                        }
                    });
                },
                error: err => {
                    this.showSpinnerWorkAdd = false;
                    if(err.error && err.error.statusCode==409){
                        this.translate.get("MESSAGES.Add_Error").subscribe({
                            next: (res) => {
                                this.notificationsService.error(res);
                            },
                            error: err => {
                            }
                        });
                    }
                    else {
                        if(err.error && err.error.message)
                            this.notificationsService.error(err.error.message);
                        else
                            this.notificationsService.error(err.message);
                    }
                    
                },
                complete: () => {
                    this.GetTotalHours();
                    this.workHour.text = '';
                    this.Cancel();
                }
            });
    }


    Delete(Id: string) {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            width: '500px',
            data: {
                Header: 'Headers.DeleteReport',
            }
            //Id
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.deleteWorkHour(Id)
            }
        });
    }

    Cancel(): void {
        this.dialogRef.close();
    }

    showDailyReport(): void {
        const dialogRef = this.dialog.open(ShowSdailyReportModalComponent, {
            width: '500px',
            data: {
                workHours: this.workHours
            }
        });

        dialogRef.componentInstance.onDeleteWorkHour.subscribe((Id) => {
            this.deleteWorkHour(Id);
        });

        dialogRef.afterClosed().subscribe(result => {

        });
    }

    CheckEndHour(checkMinute) {
        this.workHour.fromEndHour = (this.workHour.fromEndHour < this.workHour.fromStartHour) == true ? (this.workHour.fromEndHour = this.workHour.fromStartHour) : this.workHour.fromEndHour;
    }

    CheckEndMinute(checkHoure) {
        this.workHour.fromEndMinute = (this.workHour.fromEndMinute < this.workHour.fromStartMinute) == true ? (this.workHour.fromEndMinute = this.workHour.fromStartMinute) : ((this.workHour.fromEndHour == this.workHour.fromStartHour) == true ? (this.workHour.fromEndMinute = this.workHour.fromStartMinute) : this.workHour.fromEndMinute);
        if (checkHoure) {
            this.CheckEndHour(false);
        }
    }

    GetTotalHours() {
        this.total = 0;
        this.workHours.filter(wh => {
            this.total += (wh.tid - wh.pauseTimer); //ConvertTimeToHunders(wh.hours);
        })
        return this.total;//ConvertHundersToTime(this.total);
    }

    SaveWorkHourDetails() {
        this.shiftTimeIsAllocated();
        if (this.isFromTimeBetween == true || this.isToTimeIsBetween == true) {
            this.translate.get("MESSAGES.TimeAlreadyAllocated").subscribe({
                next: (res) => {
                    this.notificationsService.alert(res);
                    this.commonService.playWarningTone();
                },
                error: err => {
                }
            });
            return;
        }
        if(this.projectWorkHourIsCompleted.length !== 0)
        this.projectWorkHourOfferList();
        if (this.workHour.id)
            this.updateworkhours();
        else
            this.Add();
    }

    shiftTimeIsAllocated() {
        var FromTime: any; var ToTime: any;
        if (!this.isTimeAdddedByShif) {
            FromTime = this.workHour.fromStartHour + ':' + this.workHour.fromStartMinute;
            ToTime = this.workHour.fromEndHour + ':' + this.workHour.fromEndMinute;
        }

        if (this.shiftHours.length > 0) {
            var shiftType = this.shiftHours[0].shiftType;
            this.workHour.shiftType = shiftType;
            if (shiftType == 2) {
                FromTime = this.workHour.from = "07:00";
                ToTime = this.workHour.to = "14:30";
            }
            else if (shiftType == 3) {
                FromTime = this.workHour.from = "07:00";
                ToTime = this.workHour.to = "15:00";
            }
        }

        if (this.allWorkHoursOfCoworker.length > 0) {
            var existFromTime = "";
            var existToTime = "";
            for (let i = 0; i < this.allWorkHoursOfCoworker.length; i++) {
                if (this.allWorkHoursOfCoworker[i].id != this.workHour.id) {
                    var existFromTime = this.allWorkHoursOfCoworker[i].from;
                    var existToTime = this.allWorkHoursOfCoworker[i].to;

                    var selectedFromTime = moment(FromTime, "HH:mm a");
                    var selectedEndTime = moment(ToTime, "HH:mm a");

                    var startTime = moment(existFromTime, "HH:mm a");
                    var endTime = moment(existToTime, "HH:mm a");

                    if ((startTime.hour() >= 12 && endTime.hour() <= 12) || endTime.isBefore(startTime)) {
                        endTime.add(1, "days");       // handle spanning days endTime

                        if (selectedFromTime.hour() <= 12) {
                            selectedFromTime.add(1, "days");       // handle spanning days currentTime
                        }
                    }
                    this.isFromTimeBetween = selectedFromTime.isBetween(startTime, endTime);
                    this.isToTimeIsBetween = selectedEndTime.isBetween(startTime, endTime);

                    if (!this.isFromTimeBetween && !this.isToTimeIsBetween) {
                        this.isFromTimeBetween = startTime.isBetween(selectedFromTime, selectedEndTime);
                        this.isToTimeIsBetween = endTime.isBetween(selectedFromTime, selectedEndTime);
                    }
                    if (!this.isFromTimeBetween && !this.isToTimeIsBetween) {
                        this.isFromTimeBetween = selectedFromTime.isSame(startTime);
                        this.isToTimeIsBetween = selectedEndTime.isSame(endTime);
                    }
                    if (this.isFromTimeBetween || this.isToTimeIsBetween) {
                        return;
                    }
                }
            }
        }
    }

    AddByShiftTime(startTime1, endTime1, startTime2, endTime2, shiftNo) {
        this.ShowAddHourButton = true;
        this.workHour.id = null;
        this.shiftHours = new Array();
        this.isEditedWorkhours = false;
        this.ShowManualHours = false;
        this.isTimeAdddedByShif = true;

        //Cal min endTime1 endTime2
        // var tempEndtime1 = endTime1.split('.');
        // var et1h = tempEndtime1[0];
        // var et1min = tempEndtime1[1];
        // if (et1min != '00') {
        //   et1min = (15 * 100) / 60;
        //   endTime1 = et1h + "." + et1min;
        // }

        // var tempEndtime2 = endTime2.split('.');
        // var et2h = tempEndtime2[0];
        // var et2min = tempEndtime2[1];
        // if (et2min != '00') {
        //   et2min = (15 * 100) / 60;
        //   endTime2 = et2h + "." + et2min;
        // }
        //End

        if (shiftNo == 1) {
            this.isAddBreakTime = true;
            let obj = {
                from: startTime1,
                to: endTime1,
                shiftType: shiftNo
            }
            this.shiftHours.push(obj);
        }
        else if (shiftNo == 2) {
            var temp = new Array();
            temp = [{ from: startTime1, to: endTime1, shiftType: shiftNo }, { from: startTime2, to: endTime2, shiftType: shiftNo }]
            this.shiftHours = temp;
        }
        else {
            var temp = new Array();
            temp = [{ from: startTime1, to: endTime1, shiftType: shiftNo }, { from: startTime2, to: endTime2, shiftType: shiftNo }]
            this.shiftHours = temp;
        }
    }

    OnshowManualHours() {
        //this.workHour = new WorkHour();
        var tempWorkHours = this.allWorkHoursOfCoworker.filter(x =>  moment(x.date).format("YYYY-MM-DD") == this.workHourDiaglogOpen.date).sort(function (a, b) {
            return a.pauseTimer < b.pauseTimer ? 1 : -1;
        });
        this.ShowAddHourButton = true;
        this.workHour.id = null;
        if (tempWorkHours && tempWorkHours.length > 0) {
            this.workHour.fromEndHour = tempWorkHours[0].to.split(":")[0];
            this.workHour.fromEndMinute = tempWorkHours[0].to.split(":")[1];
            this.workHour.fromStartHour = tempWorkHours[0].to.split(":")[0];
            this.workHour.fromStartMinute = tempWorkHours[0].to.split(":")[1];
        }
        else {
            this.workHour.fromEndHour = "07";
            this.workHour.fromEndMinute = "00";
            this.workHour.fromStartHour = "07";
            this.workHour.fromStartMinute = "00";
        }
        this.isAddBreakTime = false;
        this.shiftHours = new Array();
        this.isTimeAdddedByShif = false;
        return this.ShowManualHours = !this.ShowManualHours;
    }

    getRequisitionList(model: RequestProjectRequisitionByCowokrer) {
        this.showSpinner = true;
        model.ProjectId = this.workHourDiaglogOpen.projectId;
        model.CoworkerId = this.workHourDiaglogOpen.workerId;
        this.projectRequisitionService.GetRequisitionByCoworker<ResponseBase>(model.ProjectId, model).subscribe({
            next: (response: ResponseBase) => {
                if (response) {
                    this.RequisitionList = response.result;
                    if (this.RequisitionList.length > 0) {
                        this.workHour.sagOpgaveID = this.RequisitionList[0].id;
                        this.reqStatus = this.RequisitionList[0].status;
                    }
                }
                else
                    this.RequisitionList = [];
            },
            error: err => {
                this.RequisitionList = [];
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    SelectStatus() {
        var selectedReq = this.RequisitionList.find(o => o.id == this.workHour.sagOpgaveID);
        this.reqStatus = selectedReq.status;
        if (this.isTimeAdddedByShif)
            this.ShowAddHourButton = true;
    }

    editWorkhours(currentEditedhours: WorkHour) {
        this.ShowAddHourButton = true;
        this.isEditedWorkhours = true;
        this.workHour = currentEditedhours;
        this.shiftHours = new Array();
        //this.workHour.text = currentEditedhours.text;
        //this.workHour.sagOpgaveID = currentEditedhours.sagOpgaveID;

        this.workHour.fromStartHour = currentEditedhours.from.split(':')[0];
        this.workHour.fromStartMinute = currentEditedhours.from.split(':')[1];
        //Calculate Start min;
        // if (this.workHour.fromStartMinute != '00') {
        //   var Smin: any;
        //   Smin = this.workHour.fromStartMinute;
        //   var Startmin: any;
        //   Startmin = ((Smin * 60) / 100);
        //   this.workHour.fromStartMinute = Startmin.toString();
        // }

        this.workHour.fromEndHour = currentEditedhours.to.split(':')[0];
        this.workHour.fromEndMinute = currentEditedhours.to.split(':')[1];
        //Calculate End min;
        // if (this.workHour.fromEndMinute != '00') {
        //   var Emin: any;
        //   Emin = this.workHour.fromEndMinute;
        //   var Endtmin: any;
        //   Endtmin = ((Emin * 60) / 100);
        //   this.workHour.fromEndMinute = Endtmin.toString();
        // }
        this.addBreaktime();
        if (currentEditedhours.pauseTimer > 0)
            this.isAddBreakTime = true;
        else
            this.isAddBreakTime = false;
        this.reqStatus = this.RequisitionList.find(o => o.id == currentEditedhours.sagOpgaveID).status;
        this.refreshWorkHoursData();
    }

    deleteWorkHour(Id) {
        this.workhoursService.Delete<ResponseBase>(Id)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.workHours.splice(this.workHours.findIndex(wh => wh.id == Id), 1);
                    //this.onAdd.emit();
                },
                error: err => { },
                complete: () => {
                    this.GetTotalHours();
                }
            });

        //this.workHours = this.workHours.filter(o => o.id != Id);
        //var wortkHourSort = this.workHours.sort(function (a, b) {
        //  if (a.from < b.from) { return -1; }
        //  if (a.from > b.from) { return 1; }
        //  return 0;
        //});
        //for (var i = 0; i < wortkHourSort.length; i++) {
        //  //if (i == 0) {
        //  //  var tidHoure = moment.utc(moment(wortkHourSort[i].to, "HH:mm").diff(moment(wortkHourSort[i].from, "HH:mm"))).format("HH");
        //  //  var tidMinute = moment.utc(moment(wortkHourSort[i].to, "HH:mm").diff(moment(wortkHourSort[i].from, "HH:mm"))).format("mm");
        //  //  try {
        //  //    tidMinute = ((Number(tidMinute) * 100) / 60).toString();
        //  //  } catch (e) {
        //  //    tidMinute = "00";
        //  //  }
        //  //  var time = tidHoure + ":" + tidMinute;
        //  //  wortkHourSort[i].Tid = Number(time.replace(':', '.'));
        //  //  //wortkHourSort[i].PauseTimer = 0;
        //  //  wortkHourSort[i].Hours = time.replace(':', '.');
        //  //}
        //  //else {
        //  //  var tidHoure = moment.utc(moment(wortkHourSort[i].to, "HH:mm").diff(moment(wortkHourSort[i - 1].to, "HH:mm"))).format("HH");
        //  //  var tidMinute = moment.utc(moment(wortkHourSort[i].to, "HH:mm").diff(moment(wortkHourSort[i - 1].to, "HH:mm"))).format("mm");
        //  //  try {
        //  //    tidMinute = ((Number(tidMinute) * 100) / 60).toString();
        //  //  } catch (e) {
        //  //    tidMinute = "00";
        //  //  }
        //  //  var tidTime = tidHoure + ":" + tidMinute;
        //  //  wortkHourSort[i].Tid = Number(tidTime.replace(':', '.'));

        //  //  var pauseHoure = moment.utc(moment(wortkHourSort[i].from, "HH:mm").diff(moment(wortkHourSort[i - 1].to, "HH:mm"))).format("HH");
        //  //  var pauseMinute = moment.utc(moment(wortkHourSort[i].from, "HH:mm").diff(moment(wortkHourSort[i - 1].to, "HH:mm"))).format("mm");
        //  //  try {
        //  //    pauseMinute = ((Number(pauseMinute) * 100) / 60).toString();
        //  //  } catch (e) {
        //  //    pauseMinute = "00";
        //  //  }
        //  //  var puseTime = pauseHoure + ":" + pauseMinute;
        //  //  wortkHourSort[i].pauseTimer = Number(puseTime.replace(':', '.'));

        //  //  tidTime = moment.utc(moment(wortkHourSort[i].to, "HH:mm").diff(moment(wortkHourSort[i - 1].to, "HH:mm"))).format("HH:mm");
        //  //  puseTime = moment.utc(moment(wortkHourSort[i].from, "HH:mm").diff(moment(wortkHourSort[i - 1].to, "HH:mm"))).format("HH:mm");
        //  //  var hoursHoure = moment.utc(moment(tidTime, "HH:mm").diff(moment(puseTime, "HH:mm"))).format("HH");
        //  //  var hoursMinute = moment.utc(moment(tidTime, "HH:mm").diff(moment(puseTime, "HH:mm"))).format("mm");
        //  //  try {
        //  //    hoursMinute = ((Number(hoursMinute) * 100) / 60).toString();
        //  //  } catch (e) {
        //  //    hoursMinute = "00";
        //  //  }
        //  //  var hoursTime = hoursHoure + ":" + hoursMinute;
        //  //  wortkHourSort[i].Hours = hoursTime.replace(':', '.');
        //  //}
        //  this.AddWorkhourAPICall(wortkHourSort[i]);
        //}
    }
    getMinute(value) {
        if (value == "00")
            return "00";
        if (value == "15")
            return "25";
        if (value == "30")
            return "50";
        if (value == "45")
            return "75";
    }

    addBreaktime() {
        this.isAddBreakTime = false;
        // if (this.workHour.fromEndHour <= '11' && this.workHour.fromEndMinute <= '30') {
        //     this.isBreakTime = false;
        // } else if (this.workHour.fromEndHour == '11' && this.workHour.fromEndMinute < '30') {
        //     this.isBreakTime = true;
        //     this.isAddBreakTime = true;
        // } else if (this.workHour.fromStartHour >= '12' && this.workHour.fromStartMinute >= '30') {
        //     this.isBreakTime = false;
        // } else if (this.workHour.fromStartHour == '12' && this.workHour.fromStartMinute < '30') {
        //     this.isBreakTime = true;
        //     this.isAddBreakTime = true;
        // } else if (this.workHour.fromStartHour >= '12') {
        //     this.isBreakTime = false;
        // } else {
        //     this.isBreakTime = true;
        //     this.isAddBreakTime = true;
        // }

        if (this.workHour.fromStartHour < "11" || (this.workHour.fromStartHour == "11" && this.workHour.fromStartMinute < "30")) {
            if (this.workHour.fromEndHour > "12 "|| (this.workHour.fromEndHour == "12" && this.workHour.fromEndMinute > "30")) {
              this.isAddBreakTime = true;
            }
          }
          else{
              this.isAddBreakTime = false;
          }
    }

    checkMoreThan7HoursOnFriday(workHour) {
        var tempTodayHoursSum = this.allWorkHoursOfCoworker.filter(x => moment(x.date).format("YYYY-MM-DD") == this.workHourDiaglogOpen.date).reduce((sum, current) => sum + parseFloat(current.hours), 0);
        tempTodayHoursSum += (workHour.Tid - workHour.pauseTimer);
        let showWarn = false;
        let headerMsg = "";
        if(new Date(workHour.date).getDay() == 5 && tempTodayHoursSum > 7){
            showWarn = true;
            headerMsg = "Headers.MoreThan7Hours"
        }
        else if(new Date(workHour.date).getDay() != 5 && tempTodayHoursSum > 7.5){
            showWarn = true;
            headerMsg = "Headers.MoreThan7-5Hours"
        }
        if (showWarn) {
            const dialogRef = this.dialog.open(AlertMessageModelComponent, {
                height: '200px',
                data: {
                    Header: headerMsg,
                    ShowOKButton: false
                }
            });
            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.AddWorkhourAPICall(workHour);
                }
                else
                    return;
            });
        }
        else
            this.AddWorkhourAPICall(workHour);
    }

    refreshWorkHoursData(){
        this.showSpinnerForWorkHoursData = true;
        setTimeout(() =>{
            this.showSpinnerForWorkHoursData = false;
        },500)
    }

    roomEditDetailList(event) {
        this.workHoursExtraInfo = event;
    }

    isRoomCompletedDetailList(event) {
        this.projectWorkHourIsCompleted = [event];
    }

    projectWorkHourOfferList() {
        // this.showSpinner = true;
        this.projectofferService.SaveOfferItemsList(this.projectWorkHourIsCompleted).subscribe({
            next: (response) => {
                // if (response) {
                //     this.translate.get("MESSAGES.Success").subscribe({
                //         next: (res) => {
                //             this.notificationsService.success(res);
                //         },
                //         error: err => {
                //         }
                //     });
                // } else {
                //     this.showSpinner = false;
                // }
            },
            error: err => {
                this.showSpinner = false;
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            },
            complete: () => {
                //this.showSpinner = false;
            }
        });

    }

    isroomSelected(event){
    this.flagIsRoomSelected = event;
    }
}