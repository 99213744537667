export const locale = {
    lang: 'en',
    data: {
        'TITLE': {
            'ReminderPagination': 'No. of reminders per page',
        },
        'FIELDS': {
            'ShowAll': 'Show all',
            'MarkAllDone': 'Mark All "Done"',
        },
        'TABS': {
            'ReceivedToMe': 'Received to me',
        },
        'MESSAGES': {
            'NORECORD': 'No Record Found',
            'NOREMINDER': 'No Reminder Found'
        },
        'TOOTIPORTITLE':{
            'Edit':'Edit',
            'Delete':'Delete',
            'Expand':'Expand',
            'ChangeReminderStatus':'Mark As Done'
        }
    }
}