import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';

import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { PayrollService } from '../../payroll.service';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog } from '@angular/material';
import { PayrollEntry } from 'app/main/model/CoWorker';
import { LoanEntryComponent } from '../LoanEntry/loanEntry.component';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';
import { AuthService } from "app/core/services/auth.service";

@Component({
    selector: "coworker-payroll-list",
    templateUrl: "./coworker-payroll-list.component.html",
    styleUrls: ["./coworker-payroll-list.component.scss"]
})

export class CoworkerPayrollListComponent implements OnInit {
    showSpinner: boolean = false;
    coWorkerId: string;
    isActive: boolean = false;
    coworkerPayrollList: PayrollEntry[] = [];
    coworkerPayrollListIsArchived: PayrollEntry[] = [];
    displayPagination: boolean = false;
    displayedColumns = ['date', 'hourlyRate', 'numberofHours', 'totalAmount', 'numberofHolidays', 'description', 'archived', 'koersel', 'gDag', 'holidays', 'salaryAdvance', 'other', 'icon', 'delete'];
    dataSource: MatTableDataSource<PayrollEntry>;
    dataSourceIsArchived: MatTableDataSource<PayrollEntry>;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
        this.dataSource.sort = sort;
    }
    roleClaims: any;
    IsArchieved: boolean = false;
    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private payrollService: PayrollService,
        private _route: ActivatedRoute,
        private dialog: MatDialog,
        private notificationsService: NotificationsService,
        private authService: AuthService,
        private translate: TranslateService,
        private routre: Router) {
        this.translationLoaderService.loadTranslations(danish, english);
        this.roleClaims = this.authService.getRoleClaimsList();
    }

    ngOnInit() {
        this._route.params.subscribe(params => {
            this.coWorkerId = params['id'];
            this.isActive = !JSON.parse(params['isActive']);
        });
        this.getCoworkerPayrolls();
    }

    getCoworkerPayrolls() {
        this.showSpinner = true;
        this.payrollService.GetPayrollByCoworkerID(this.coWorkerId, this.isActive).subscribe({
            next: (response: ResponseBase) => {
                if (response) {
                    this.coworkerPayrollList = response.result.myResponse;
                    if (this.coworkerPayrollList.length > 0) {
                        this.coworkerPayrollList.forEach(e => {
                            e.isGDag = e.gDag == "T" ? true : false;
                            e.isKoersel = e.koersel == "T" ? true : false;
                            e.isHolidays = e.holidays == "T" ? true : false;
                            e.isArchived = e.archived == "T" ? true : false;
                            e.isSalaryAdvance = e.salaryAdvance == "T" ? true : false;
                            e.isOther = e.other == "T" ? true : false;
                            e.totalAmount = (e.hourlyRate * e.numberofHours);
                        });
                        this.dataSource = new MatTableDataSource(this.coworkerPayrollList);
                        this.dataSource.paginator = this.paginator;
                        this.displayPagination = true;
                    } else {
                        this.dataSource = new MatTableDataSource(this.coworkerPayrollList);
                        this.displayPagination = false;
                    }
                    this.showSpinner = false;
                }
            },
            error: err => {
                this.coworkerPayrollList = []
                this.showSpinner = false;
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            }
        });
    }

    edit(id) {
        const dialogRef = this.dialog.open(LoanEntryComponent, {
            height: "80%",
            width: "35%",
            data: id
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result != null) {
                this.getCoworkerPayrolls();
            }
        });
    }

    deletePayroll(id) {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: "200px",
            data: { Header: 'Headers.DeleteMessage' }
        })

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.delete(id);
            }
        });
    }

    delete(id) {
        this.showSpinner = true;
        this.payrollService.Delete(id).subscribe({
            next: (response: any) => {
                if (response) {
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.Deleted").subscribe({
                        next: (res) => {
                            this.notificationsService.success(res);
                        },
                        error: err => {
                        }
                    });
                }
            },
            error: err => {
                this.showSpinner = false;
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            },
            complete: () => {
                this.showSpinner = false;
                this.getCoworkerPayrolls();
            }
        })
    }

    addLoanEntry() {
        const dialogRef = this.dialog.open(LoanEntryComponent, {
            height: "80%",
            width: "35%",
            data: ""
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.getCoworkerPayrolls();
            }
        });

    }
    showIsArchived() {
        this.IsArchieved = true;
        this.showSpinner = true;
        this.payrollService.GetPayrollByCoworkerIDIsArchived(this.coWorkerId).subscribe({
            next: (response: ResponseBase) => {
                if (response) {
                    this.coworkerPayrollListIsArchived = response.result.myResponse;
                    if (this.coworkerPayrollListIsArchived.length > 0) {
                        this.coworkerPayrollListIsArchived.forEach(e => {
                            e.isGDag = e.gDag == "T" ? true : false;
                            e.isKoersel = e.koersel == "T" ? true : false;
                            e.isHolidays = e.holidays == "T" ? true : false;
                            e.isArchived = e.archived == "T" ? true : false;
                            e.isSalaryAdvance = e.salaryAdvance == "T" ? true : false;
                            e.isOther = e.other == "T" ? true : false;
                            e.totalAmount = (e.hourlyRate * e.numberofHours);
                        });
                        this.dataSourceIsArchived = new MatTableDataSource(this.coworkerPayrollListIsArchived);
                        this.dataSourceIsArchived.paginator = this.paginator;
                        this.showSpinner = false;
                        //this.pagination = response.pagination;
                        this.displayPagination = true;
                    } else {
                        this.displayPagination = false;
                        this.showSpinner = false;
                        //this.routre.navigate(["payroll"]);
                    }
                }
            },
            error: err => {
                this.coworkerPayrollListIsArchived = []
                this.showSpinner = false;
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            }
        });
    }
}
