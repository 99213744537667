import { Component, Input, OnInit, Optional } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { MaterialService } from '../../material.service';
import { RequestMaterialOrderHistory, RequestOrderMaterial, SupplierRequest } from 'app/main/model/RequestBase';
import { ResponseBase } from 'app/main/model/ResponseBase';
import * as moment from 'moment';
import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { environment } from 'environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { MatDialog, MatDialogRef } from '@angular/material';
import { EditMaterialOrderComponent } from '../edit-material-order/edit-material-order.component';
import { Supplier } from "app/main/model/Supplier";
import { SupplierOrderList } from "app/constant/common.constant";

@Component({
    selector: "material-order-history",
    templateUrl: "./material-order-history.component.html",
    styleUrls: ["./material-order-history.component.scss"]
})

export class MaterialOrderHistoryComponent implements OnInit {
    @Input() MaterialID: string;
    showSpinner: boolean = false;
    searchStartDate: Date = new Date(new Date().getFullYear() + "-01-01");
    searchEndDate: Date = new Date(moment(new Date()).format("YYYY-MM-DD"));
    materialOrderHistoryList: any = [];
    pagination: any;
    displayPagination: boolean;
    requestBase: RequestMaterialOrderHistory;
    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private materialService: MaterialService,
        private translate: TranslateService,
        private notificationsService: NotificationsService,
        public dialog: MatDialog,
        @Optional() public dialogRef: MatDialogRef<EditMaterialOrderComponent>,
    ) {
        this.translationLoaderService.loadTranslations(danish, english);
    }

    ngOnInit() {
        this.getMaterialOrderHistory();
    }

    getMaterialOrderHistory() {
        this.showSpinner = true;
        this.requestBase = new RequestMaterialOrderHistory();
        this.requestBase.Index = 1;
        this.requestBase.Limit = 100;
        this.requestBase.MaterialID = this.MaterialID;
        this.requestBase.StartDate = moment(this.searchStartDate).format("YYYY-MM-DD");
        this.requestBase.EndDate = moment(this.searchEndDate).format("YYYY-MM-DD");
        this.materialService.GetMaterialOrderHistory(this.requestBase).subscribe({
            next: (response: ResponseBase) => {
                if (response) {
                    this.showSpinner = false;
                    this.materialOrderHistoryList = response.result;
                    this.pagination = response.pagination;
                    this.displayPagination = true;
                } else {
                    this.showSpinner = false;
                    this.materialOrderHistoryList = [];
                }
            },
            error: err => {
                this.showSpinner = false;
                this.displayPagination = false;
                this.materialOrderHistoryList = [];
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    receivePaginatonEvent(event) {
        this.requestBase.Index = event.pageIndex;
        this.requestBase.Limit = event.pageSize;
        this.requestBase = event.pageSize;
        this.getMaterialOrderHistory();
    }

}