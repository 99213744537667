export const locale = {
    lang: 'ds',
    data: {
        'Tabs': {
            'Offer': 'Tilbud',
            'BasicDetail': 'Grundlæggende detaljer',
            'File': 'Filer',
            'Category': 'Kategori',
            'WorkItem': 'Behandlinger',
            'Room': 'Rum / Bygning',
            'PDFEmail': 'PDF / Email',
            'Questions': 'Spørgsmål',
            'MtoM': 'M til M',
            'Note': 'Bemærk',
            'Offers': 'Tilbud',
            'Log': 'Log',
            'QualityTest': 'Kvalitetstest',
        },
        'MESSAGES': {
            'Failed': 'Operation mislykket, prøv senere',
            'Success': 'Succes.',
            'ComingSoon': 'Kommer snart',
            'DropBoxApiNotResponding': 'Dropbox reagerer ikke, prøv efter lidt tid',
            'FileConflict': 'Filen eksisterer allerede',
            'FolderConflict': 'Mappenavn eksisterer allerede',
            'EmailSent': 'Email sendt med succes',
            'DisabledTabMsg': 'Du skal gemme tilbuddet eller nulstille.'
        },
        'TOOTIPORTITLE':{
            'Edit':'Redigere',
            'Delete':'Slet',
            'SendSMS':'Send SMS',
            'SendEmail':'Send E-mail',
            'DeleteOffer':'Slet Tilbud',
        },
    }
};
