import { Component, OnInit } from '@angular/core';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { MatDialog } from '@angular/material';
import { NonWorkHourComponent } from './non-work-hour/non-work-hour.component';
import { SelectProjectOnWorkhoursModalComponent } from './select-project-modal/select-project-modal.component';
import { PaySlipModalComponent } from 'app/main/pages/workHour/component/pay-slip-modal/pay-slip-modal.component'
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { WorkhoursService } from './workhours.service';
import { Project } from 'app/main/model/Project';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { CoWorkersService } from 'app/main/pages/coWorker/coWorkers.service';
import { RequestWorkHourGroupBy, RequestForDeviceTripList } from 'app/main/model/RequestBase';
import { CoWorker } from 'app/main/model/CoWorker';
import { AuthService } from 'app/core/services/auth.service';
import { CoWorkerWorkHour, ProjectWorkHoursList, TimerDayWise } from 'app/main/model/CoWorkerWorkHour';
import { WeekDays } from 'app/main/model/WeekDays';
import { WeekDay, DatePipe } from '@angular/common';
import { TitleService } from 'app/core/services/title.service';
import { Router } from "@angular/router";
import { WorkHourModalPopupComponent } from './work-hour-modal-popup/work-hour-modal-popup.component';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import * as moment from 'moment';
import { ProjectService } from 'app/main/pages/project/project.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { DeleteAlertModalComponent } from 'app/main/pages/delete-alert-modal/delete-alert-modal.component';
import { NanolinkService } from 'app/main/pages/nanolink/nanolink.service';
import { GoogleMapVehicleAddressComponent } from 'app/main/pages/dashboard/component/google-map-vehicle-address/google-map-vehicle-address.component';
import { RenderDayCellEventArgs } from '@syncfusion/ej2-angular-calendars';

@Component({
    selector: 'vibe-workHour',
    templateUrl: './workHour.component.html',
    styleUrls: ['./workHour.component.scss']
})

export class VibeWorkHourComponent implements OnInit {
    public currentDate: Date;
    weekNumber: number; currentweek: number;
    disableRight: boolean = false;
    disableLeft: boolean = false;
    // useradmin: boolean = false;
    // userkontor: boolean = false;
    // usermaler: boolean = false;
    // usergaest: boolean = false;
    // userguest: boolean = false;
    //accessgranted: boolean = false;
    // isValid: boolean = false;
    // isSelect: boolean = false;
    data: any;
    worker: any;
    isacccess: boolean = false;
    // ------- new ------------
    prevWeekEndday: any;
    NextWeekEndDay: any;
    selectworker: CoWorker;
    roleName: string;
    coWorkerId: string;
    coWorkerWorkHours: CoWorkerWorkHour;
    CoWorkerNonWorkHours: CoWorkerWorkHour;
    weekDays: WeekDays[] = [];
    currentWeekStartDate?: string;
    currentWeekEndDate?: string;
    prevWeekStartDate: Date;
    nextWeekStartDate: Date;
    project: Project[];
    showSpinner: boolean = false;
    shownonWorkingHourSpinner: boolean = false;
    showWorkHourSpinner: boolean = false;
    plannedProject: any = [];
    unPlannedProject: any = [];
    year: number;
    isCheckedAll: boolean = false;
    currentselectedProjectIds: any = [];
    IsGettingCoWorkers: boolean = false;
    projectWithWorkHoursList: ProjectWorkHoursList[] = [];
    projectWithnonWorkHoursList: ProjectWorkHoursList[] = [];
    nonWorkHoursList: any = [];
    leaveNonWorkHours: ProjectWorkHoursList = new ProjectWorkHoursList();
    timerDayWise: TimerDayWise;
    deviceTripInfoList: any = [];
    requestForDeviceTripList: any = new RequestForDeviceTripList();
    roleClaims: any;
    hasOwnWorkhourPermision: boolean = false;
    closedPaymentDate: Date = null;
    comparestartDate:any;
    compareClosedate:any;
    closePayValue:any;
    closePayDate:any;
    coWokers: CoWorker[] = [];
    startDate: any;
    startWeek: number = 1;
    orderBy: string = "Number DESC";
    constructor(private translationLoader: FuseTranslationLoaderService, private workhourservice: WorkhoursService,
        private dialog: MatDialog,
        private authService: AuthService,
        private coWorkersService: CoWorkersService,
        private translate: TranslateService,
        private notificationsService: NotificationsService,
        private projectservice: ProjectService,
        private nanolinkService: NanolinkService,
        private datePipe: DatePipe,
        private titleService: TitleService,
        private _fuseSidebarService: FuseSidebarService,
        private router: Router) {
        //super("Work-hours", EventEmitterEnum.OnLoadMainContentWelcomPage);
        this.titleService.SetTitle("TITLE_WORK_HOURS");
        this.translationLoader.loadTranslations(english, danish);
        this._fuseSidebarService.getSidebar('navbar').fold();
        this.selectworker = new CoWorker();
        this.roleName = this.authService.getUserRole();
        this.coWorkerId = this.authService.getCoWorkerId();
        this.roleClaims = this.authService.getRoleClaimsList();
        

        //this.useradmin = true;
        //this.isValid = true;
        this.isacccess = true;
        //this.isSelect = true;

        this.selectworker.id = this.coWorkerId;
        // if (this.roleName != "Admin") {
        //     this.isSelect = false;
        // }

        // if (this.roleName == "Admin") {
        //     this.isValid = true;
        // }
        // else if (this.roleName == "Kontor") {
        //     this.isValid = true;
        // }
        // else if (this.roleName == "Maler") {
        //     this.isValid = true;
        // }
        // else if (this.roleName == "Gaest") {
        //     this.isValid = true;
        // }
        // else {
        //     this.isValid = true;
        // }
    }

    ngOnInit() {
        this.authService.hasClaim(this.roleClaims.Own_Workhour_View).subscribe(hasClaim => {
            if (hasClaim) 
                this.hasOwnWorkhourPermision = hasClaim
             else 
                this.hasOwnWorkhourPermision = false;
        });
        this.getLastClosedPaymentDate();
        setTimeout(() => {
            this.currentDate = new Date();
            this.year = new Date().getFullYear();
            this.getWeekNumber();
            this.startAndEndOfWeek(new Date(), true);
            this.getnetweekData(this.weekNumber);
            //this.initializationComplete = true;
            //this.getCoWorkers(new RequestCoWorker());
        }, 2000);
    }

    openDialog(date, project): void {
        var todaysNonworkingHours = this.nonWorkHoursList.filter(p => moment(p.date).format("YYYY-MM-DD") == date && p.hours >= 7);
        //var todaysNonworkingHours = this.nonWorkHoursList.filter(p => moment(p.date).format("YYYY-MM-DD") == date && (p.status == 1 || p.status == 3 || p.status == 4 || p.status == 5));
        // if (this.projectWithWorkHoursList && this.projectWithWorkHoursList.length > 0) {
        //    this.projectWithWorkHoursList.filter(p => {
        //        if(p.workHoursList && p.workHoursList.length > 0){
        //            var tempDateArray = p.workHoursList.filter(w => (moment(w.date).format("YYYY-MM-DD") == date));
        //            if (tempDateArray && tempDateArray.length > 0 && p.isActive) {
        //                this.isAlreadyApplyLeave = true;
        //                return;
        //            }
        //        }
        //    });
        // }
        if (todaysNonworkingHours.length > 0) {
            this.translate.get("MESSAGES.CannotApplyWorkHours").subscribe({
                next: (res) => {
                    var msg = res.replace("@date", moment(date, "YYYY.MM.DD").format("DD.MM.YYYY"));
                    this.notificationsService.error(msg);
                },
                error: err => {
                }
            });
        }
        else {
            if(this.closedPaymentDate){
              this.closePayDate = moment(this.closedPaymentDate).format('YYYY-MM-DD')                
            }
            else{
              this.closePayDate = "1900-01-01"; 
            }
            if(date > this.closePayDate){
                const dialogRef = this.dialog.open(WorkHourModalPopupComponent, {
                    width: '85%',
                    height: '85%',
                    data: {
                        date: date,
                        projectId: project.sagId,
                        workerId: this.selectworker.id,
                        sagNumber: project.sagNumber,
                        address: project.reqDetails,
                        sagOpgaveId: "",//this.plannedProject.find(f => f.sagId == projectId).SagOpgaveID,
                        city: "",//this.coWorkerWorkHours.project.find(f => f.sagId == projectId).city,
                        zipCode: "",//this.coWorkerWorkHours.project.find(f => f.sagId == projectId).zipCode,
                        nonWorkHoursList: this.nonWorkHoursList,
                        projectWithWorkHoursList: this.projectWithWorkHoursList,
                    },
    
                });
    
                dialogRef.componentInstance.onAdd.subscribe(() => {
                    var request = new RequestWorkHourGroupBy();
                    request.ProjectId = project.sagId;
                    this.getWorkHoursBasedOnProject(this.selectworker.id, request);
                });
    
                dialogRef.afterClosed().subscribe(result => {
                    this.getWorkHours(this.selectworker.id, new RequestWorkHourGroupBy());
                    //this.getNonWorkHours(this.selectworker.id, new RequestWorkHourGroupBy());
                });
            }
        }
    }


    setPrevWeekStartDate(date) {
        this.prevWeekStartDate = new Date(date.setDate(date.getDate() - date.getDay() - 6));
    }

    setNextWeekStartDate(date) {
        this.nextWeekStartDate = new Date(date.setDate(date.getDate() + 1));
    }

    startAndEndOfWeek(date, isIntialize: boolean = false) {
        this.weekDays = [];
        // If no date object supplied, use current date
        // Copy date so don't modify supplied date
        var now = date ? new Date(date) : new Date();

        // set time to some convenient value
        now.setHours(0, 0, 0, 0);
         var locale = "da";
        var selectedWeekStartDate = new Date(now);
        for (var i = 0; i < 7; i++) {
            var getDate = selectedWeekStartDate.getDate();
            var getDay = selectedWeekStartDate.getDay();
            var setDate = selectedWeekStartDate;
            if (getDay == 0) {
                selectedWeekStartDate.setDate(selectedWeekStartDate.getDate() - 6);
            } else {
                selectedWeekStartDate.setDate(selectedWeekStartDate.getDate() - selectedWeekStartDate.getDay() + (i + 1));
            }
            var day = new WeekDays();
            day.Date = this.datePipe.transform(selectedWeekStartDate, "yyyy-MM-dd");
            var month = selectedWeekStartDate.toLocaleString(locale, { month: "short" });
            month = month.charAt(0).toUpperCase() + month.substr(1);
            day.DateName = selectedWeekStartDate.getDate().toString();
            day.MonthName = month;
            day.Day = "WeekDays." + WeekDay[selectedWeekStartDate.getDay()];
            this.weekDays.push(day);
        }
        this.currentWeekStartDate = this.weekDays[0].Date;
        this.startDate = moment(this.currentWeekStartDate).format("DD-MM-YYYY");
        this.currentWeekEndDate = this.weekDays[this.weekDays.length - 1].Date;
        this.setPrevWeekStartDate(now);
        this.setNextWeekStartDate(selectedWeekStartDate);        
        if(!isIntialize){
            this.getWorkHours(this.selectworker.id, new RequestWorkHourGroupBy());
            this.GetDeviceTripInfoList();
        }
    }

    getPrevWeekDates(date) {
        this.startAndEndOfWeek(this.prevWeekStartDate);
    }

    getNextWeekDates(date) {
        this.startAndEndOfWeek(this.nextWeekStartDate);
    }

    getWeekNumber() {
        //For Week Number
        let d: any = new Date();
        d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
        // Set to nearest Thursday: current date + 4 - current day number
        // Make Sunday's day number 7
        d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
        // Get first day of year
        var yearStart: any = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
        // Calculate full weeks to nearest Thursday
        var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
        // Return array of year and week number]
        this.currentweek = weekNo;
        this.weekNumber = weekNo;
        return this.weekNumber;
    }

    DisableRight(weekNumber) {
        if (this.currentweek == weekNumber)
            return true;
        else
            return false;
    }

    getPrevweekData(currentWeekNumber) {
        currentWeekNumber = parseInt(currentWeekNumber);
        if (currentWeekNumber != 1) {
            this.weekNumber = currentWeekNumber - 1;
            this.disableRight = false;
            this.getPrevWeekDates(this.prevWeekEndday);
        }
        else {
            this.year = this.year - 1;
            var weeknumber = moment(this.year, "YYYY").isoWeeksInYear();
            this.weekNumber = weeknumber;
            this.getPrevWeekDates(this.prevWeekEndday);
            //this.disableLeft = true;
        }
    }

    getnetweekData(currentWeekNumber) {
        currentWeekNumber = parseInt(currentWeekNumber);

        if (currentWeekNumber != this.currentweek) {
            this.weekNumber = currentWeekNumber + 1;
            this.disableLeft = false;

            this.getNextWeekDates(this.NextWeekEndDay);
        }
        else {
            this.disableRight = true;
        }
    }

    getnextweekData(currentWeekNumber) {
        var weeknumber = moment(this.year, "YYYY").isoWeeksInYear();
        if (currentWeekNumber == weeknumber) {
            currentWeekNumber = 0;
            this.year = this.year + 1;
        }
        currentWeekNumber = parseInt(currentWeekNumber);

        this.weekNumber = currentWeekNumber + 1;
        this.disableLeft = false;

        this.getNextWeekDates(this.NextWeekEndDay);
    }

    // isValidForm() {
    //     return this.isValid;
    // }

    isvalid() {
        return this.isacccess;
    }

    // isvalidselect() {
    //     return this.isSelect;
    // }

    getWorkHoursBasedOnProject(id, model: RequestWorkHourGroupBy) {
        model.StartDate = this.currentWeekStartDate;
        model.EndDate = this.currentWeekEndDate;
        this.workhourservice.Get<ResponseBase>(id, model)
            .subscribe((response: ResponseBase) => {
                if (response) {
                    let index = this.coWorkerWorkHours.project.findIndex(p => p.sagId.toString() == model.ProjectId);
                    this.coWorkerWorkHours.project.splice(index, 1);
                    if (response.result)
                        this.coWorkerWorkHours.project.splice(index, 0, response.result.project[0]);
                    //else
                    //  this.coWorkerWorkHours = null;
                }
                else {
                    //this.coWorkerWorkHours = [];
                }
            },
                error => (err) => {
                    //this.coWorkerWorkHours = [];
                },
                () => {
                });
    }

    getWorkHours(id, model: RequestWorkHourGroupBy) {
        model.StartDate = this.currentWeekStartDate;
        model.EndDate = this.currentWeekEndDate;
        model.OrderBy = this.orderBy;
        this.plannedProject = [];
        this.unPlannedProject = [];
        this.projectWithWorkHoursList = [];
        this.projectWithnonWorkHoursList = [];
        this.nonWorkHoursList = [];
        this.showWorkHourSpinner = true;
        this.shownonWorkingHourSpinner = true;
        this.workhourservice.GetWorkhoursList<ResponseBase>(id, model)
            .subscribe((response: ResponseBase) => {
                if (response) {
                    this.showWorkHourSpinner = false;
                    this.isCheckedAll = false;
                    if (response.result.coworker)
                        this.coWorkerWorkHours = response.result.coworker;
                    if (response.result.activeWorkingHoursList) {
                        this.projectWithWorkHoursList = response.result.activeWorkingHoursList;
                        this.projectWithWorkHoursList.filter(o => {
                            o.isCheked = false
                            o.total = o.workHoursList && o.workHoursList.length > 0 ? o.workHoursList.reduce((sum, current) => sum + (current.tid - (current.pauseTimer ? current.pauseTimer : 0)), 0) : 0
                            o.timerDayWise = [];
                            this.weekDays.filter(d => {
                                this.timerDayWise = new TimerDayWise();
                                this.timerDayWise.date = d.Date;
                                this.timerDayWise.total = o.workHoursList && o.workHoursList.length > 0 ? o.workHoursList.filter(w => moment(w.date).format("YYYY-MM-DD") == d.Date).reduce((sum, current) => sum + (current.tid - (current.pauseTimer ? current.pauseTimer : 0)), 0) : 0;
                                if (o.workHoursList && o.workHoursList.length > 0) {
                                    this.timerDayWise.workHourInfo = o.workHoursList.filter(w => moment(w.date).format("YYYY-MM-DD") == d.Date)
                                        .map((x) => ((x.from as any).padEnd(5) + " - " + x.to)).join("\n");
                                }
                                var currentDate = new Date().getTime();
                                var passingDate = new Date(d.Date).getTime();
                                if (passingDate > currentDate)
                                    this.timerDayWise.isPastDate = false;
                                else
                                    this.timerDayWise.isPastDate = true;
                                o.timerDayWise.push(this.timerDayWise);
                            })
                        })
                        this.plannedProject = this.projectWithWorkHoursList.filter(o => o.isPlanning == true);
                        this.unPlannedProject = this.projectWithWorkHoursList.filter(o => o.isPlanning == false);

                    }
                    if (response.result.nonWorkingHoursList) {
                        this.nonWorkHoursList = response.result.nonWorkingHoursList;
                        var nonWorkingArray = response.result.nonWorkingHoursList;
                        var groupedByData = this.groupByKey(nonWorkingArray, 'status')
                        if (groupedByData) {
                            (Object.keys(groupedByData)).forEach(x => {
                                this.leaveNonWorkHours = new ProjectWorkHoursList();
                                this.leaveNonWorkHours.statusValue = parseInt(x);
                                this.leaveNonWorkHours.total = groupedByData[x] && groupedByData[x].length > 0 ? groupedByData[x].reduce((sum, current) => sum + (current.tid - (current.pauseTimer ? current.pauseTimer : 0)), 0) : 0;
                                this.weekDays.filter(d => {
                                    this.timerDayWise = new TimerDayWise();
                                    this.timerDayWise.date = d.Date;
                                    this.timerDayWise.total = groupedByData[x] && groupedByData[x].length > 0 ? groupedByData[x].filter(w => moment(w.date).format("YYYY-MM-DD") == d.Date).reduce((sum, current) => sum + (current.tid - (current.pauseTimer ? current.pauseTimer : 0)), 0) : 0;
                                    if (groupedByData[x] && groupedByData[x].length > 0) {
                                        this.timerDayWise.workHourInfo = groupedByData[x].filter(w => moment(w.date).format("YYYY-MM-DD") == d.Date)
                                            .map((x) => ((x.from as any).padEnd(5) + " - " + x.to)).join("\n");
                                    }
                                    this.leaveNonWorkHours.timerDayWise.push(this.timerDayWise);
                                })
                                this.projectWithnonWorkHoursList.push(this.leaveNonWorkHours);
                            })
                        }
                        setTimeout(() => {
                            this.shownonWorkingHourSpinner = false;
                        })
                    }
                }
            }, error => (err) => { }, () => { this.shownonWorkingHourSpinner = false; });
    }

    OptionChange(event, value) {
        //if (event.source.selected)
        //  this.ReminderObject.coWorkerId = value;
    }

    ChangeCoWorker(event, id) {
        //if (event.source.selected)
        this.getWorkHours(id, new RequestWorkHourGroupBy());

    }

    GetTotalHours(date) {
        var total = 0;
        if (this.projectWithWorkHoursList && this.projectWithWorkHoursList.length > 0) {
            this.projectWithWorkHoursList.filter(w => {
                if (w.timerDayWise && w.timerDayWise.length > 0)
                    total += w.timerDayWise.filter(t => moment(t.date).format("YYYY-MM-DD") == date).reduce((sum, current) => sum + current.total, 0)
            })
        }
        return total;
    }

    GetNonTotalSumHoursForWorkingAndNonWorkingHoursGrandTotal() {
        var total = 0;
        if (this.CoWorkerNonWorkHours) {
            if (this.CoWorkerNonWorkHours.project) {
                this.CoWorkerNonWorkHours.project.filter(c => {
                    var convertObj = Object.keys(c.timingDateWise).map(i => c.timingDateWise[i]);
                    for (let i = 0; i < convertObj.length; i++) {
                        total += parseFloat(parseFloat(convertObj[i]).toFixed(2));
                    }
                });
            }
        }
        return total;
    }


    GetNonTotalHours(date) {
        var total = 0;
        if (this.projectWithnonWorkHoursList && this.projectWithnonWorkHoursList.length > 0) {
            this.projectWithnonWorkHoursList.filter(w => {
                if (w.timerDayWise && w.timerDayWise.length > 0)
                    total += w.timerDayWise.filter(t => moment(t.date).format("YYYY-MM-DD") == date).reduce((sum, current) => sum + current.total, 0)
            })
        }
        return total;
    }


    GetTotalSumHours() {
        let total: any = 0;
        if (this.projectWithWorkHoursList)
            total = this.projectWithWorkHoursList.reduce((sum, current) => sum + current.total, 0);
        return total;
    }

    GetNonTotalSumHours() {
        var total = 0;
        if (this.projectWithnonWorkHoursList)
            total = this.projectWithnonWorkHoursList.reduce((sum, current) => sum + current.total, 0);
        return total;
    }

    CheckDate(date) {
        var currentDate = new Date().getTime();
        var passingDate = new Date(date).getTime();
        if (passingDate > currentDate)
            return false;
        return true;
    }


    onClickOpenNonWorkingHourModel(): void {
        const dialogRef = this.dialog.open(NonWorkHourComponent, {
            width: '70%',
            data: {
                workerId: this.selectworker.id,
                coWorkerWorkHours: this.coWorkerWorkHours,
                projectWithWorkHoursList: this.projectWithWorkHoursList,
                nonWorkHoursList: this.nonWorkHoursList,
                projectId: null
            },
            //height: '85%',
            autoFocus: false
        });

        dialogRef.componentInstance.onAdd.subscribe(() => {
            this.getWorkHours(this.selectworker.id, new RequestWorkHourGroupBy());
        });

        dialogRef.afterClosed().subscribe(result => {
            this.getWorkHours(this.selectworker.id, new RequestWorkHourGroupBy());
        });
    }

    editNonWorkingHourModel(date, projectId, status): void {
        // var isAlreadyApplyWorkHours = false
        // if (this.projectWithWorkHoursList && this.projectWithWorkHoursList.length > 0 && (status == 1 || status == 3 || status == 4 || status == 5)) {
        //    this.projectWithWorkHoursList.filter(p => {
        //        if(p.workHoursList && p.workHoursList.length > 0){
        //            var tempDateArray = p.workHoursList.filter(w => (moment(w.date).format("YYYY-MM-DD") == date));
        //            if (tempDateArray && tempDateArray.length > 0 && p.isActive) {
        //             isAlreadyApplyWorkHours = true;
        //                return;
        //            }
        //        }
        //    });
        // }
        // if (isAlreadyApplyWorkHours) {
        //     this.translate.get("MESSAGES.CannotApplyLeave").subscribe({
        //         next: (res) => {
        //             var msg = res.replace("@date", moment(date, "YYYY.MM.DD").format("DD.MM.YYYY"));
        //             this.notificationsService.error(msg);
        //         },
        //         error: err => {
        //         }
        //     });
        // }
        // else {
        if (this.closedPaymentDate) {
            this.closePayDate = moment(this.closedPaymentDate).format('YYYY-MM-DD')
        }
        else {
            this.closePayDate = "1900-01-01";
        }
        if (date > this.closePayDate) {
            const dialogRef = this.dialog.open(NonWorkHourComponent, {
                width: '70%',
                data: {
                    workerId: this.selectworker.id,
                    projectId: null,
                    date: date,
                    status: status,
                    coWorkerWorkHours: this.coWorkerWorkHours,
                    projectWithWorkHoursList: this.projectWithWorkHoursList,
                    nonWorkHoursList: this.nonWorkHoursList,
                },
                //height: '85%',
                autoFocus: false
            });

        // dialogRef.componentInstance.onAdd.subscribe(() => {
        //     this.getWorkHours(this.selectworker.id, new RequestWorkHourGroupBy());
        // });

        dialogRef.afterClosed().subscribe(result => {
            this.getWorkHours(this.selectworker.id, new RequestWorkHourGroupBy());
        });
    }
        // }

    }

    deleteCoworkerFromProject(obj) {
        this.showWorkHourSpinner = true;
        const dialogRef = this.dialog.open(DeleteAlertModalComponent, {
            height: '200px',
            data: { obj, deleteAll: false, projectId: obj.sagId }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.projectservice.removeAllProjectCoWorker<ResponseBase>(obj.sagId, this.selectworker.id)
                    .subscribe({
                        next: (response: ResponseBase) => {
                            this.translate.get("MESSAGES.CoworkerDeleted").subscribe({
                                next: (res) => {
                                    this.notificationsService.create(res);
                                },
                                error: err => {
                                }
                            });
                            this.currentDate = new Date();
                            this.year = new Date().getFullYear();
                            this.getWeekNumber();
                            this.startAndEndOfWeek(new Date());
                            this.getnetweekData(this.weekNumber);
                        },
                        error: err => {
                            //this.notificationsService.error(err.error.message);
                            this.showWorkHourSpinner = false;
                        },
                        complete: () => {
                            this.showWorkHourSpinner = false;
                        }
                    });
            }
            else {
                this.showWorkHourSpinner = false;
            }
        });
    }


    goToProjectWorkhourShourtcut(project) {
        if (project.sagId) {
            this.router.navigate(['/projects/edit', project.sagId, { isshourtcut: true }]);
        }
    }

    GenerateReport() {
        var endDate = moment(this.currentWeekStartDate).add(13, 'days')["_d"];
        const dialogRef = this.dialog.open(PaySlipModalComponent, {
            width: '50%',
            data: {
                startDate: this.currentWeekStartDate,
                endDate: endDate,
                selectworker: this.selectworker,
                coWokersList: this.coWokers,
                closedPaymentDate: this.closedPaymentDate
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if(result)
                this.getLastClosedPaymentDate();
        });
    }

    SearchProject() {
        const dialogRef = this.dialog.open(SelectProjectOnWorkhoursModalComponent, {
            height: "95%",
            width: "95%"
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.selectworker.id = result;
                this.ChangeCoWorker('', this.selectworker.id);
            }
        });
    }
    setIscheckAll() {
        var plannedProject = this.plannedProject.filter(x => x.isChecked == true);
        var unPlannedProject = this.unPlannedProject.filter(x => x.isChecked == true);
        if ((plannedProject && plannedProject.length > 0) || (unPlannedProject && unPlannedProject.length > 0))
            this.isCheckedAll = true;
        else
            this.isCheckedAll = false;
    }

    deleteMultipleProject() {
        this.showWorkHourSpinner = true;
        const dialogRef = this.dialog.open(DeleteAlertModalComponent, {
            height: '200px',
            data: { deleteAll: true }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.currentselectedProjectIds = [];
                var unPlannedProjectList = this.unPlannedProject.filter(x => x.isChecked).map(o => o.sagId);
                var plannedProjectList = this.plannedProject.filter(x => x.isChecked).map(o => o.sagId);
                this.currentselectedProjectIds = [...plannedProjectList, ...unPlannedProjectList];
                let request = {
                    ProjectIDS: this.currentselectedProjectIds,
                    CoworkerID: this.selectworker.id
                }
                this.projectservice.DeleteCoworkerOnWorkhoursByList<ResponseBase>(request)
                    .subscribe({
                        next: (response: ResponseBase) => {

                            this.translate.get("MESSAGES.CoworkerDeleted").subscribe({
                                next: (res) => {
                                    this.notificationsService.create(res);
                                },
                                error: err => {
                                }
                            });
                            this.currentDate = new Date();
                            this.year = new Date().getFullYear();
                            this.getWeekNumber();
                            this.startAndEndOfWeek(new Date());
                            this.getnetweekData(this.weekNumber);
                        },
                        error: err => {
                            //this.notificationsService.error(err.error.message);
                            this.showWorkHourSpinner = false;
                        },
                        complete: () => {
                            this.showWorkHourSpinner = false;
                        }
                    });
            }
            else {
                this.showWorkHourSpinner = false;
            }
        });
    }

    onCowrkerChange(e) {
        this.ChangeCoWorker('', e);
        this.GetDeviceTripInfoList();
    }

    groupByKey(data, key) {
        return data.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };

    GetDeviceTripInfoList() {
        this.requestForDeviceTripList.StartDate = this.currentWeekStartDate;
        this.requestForDeviceTripList.EndDate = this.currentWeekEndDate;
        this.requestForDeviceTripList.CoworkerID = this.selectworker.id;
        this.nanolinkService.GetDeviceTripInfoList<ResponseBase>(this.requestForDeviceTripList)
            .subscribe((response: ResponseBase) => {
                this.deviceTripInfoList = [];
                if (response && response.result) {
                    this.deviceTripInfoList = response.result
                }
            }, error => (err) => { },
                () => {
                });
    }

    viewRouteMap(date) {
        var tempArrayTrips = this.deviceTripInfoList.filter(o => this.datePipe.transform(o.startDateTime, 'yyyy-MM-dd') === date);
        const dialogRef = this.dialog.open(GoogleMapVehicleAddressComponent, {
            width: '90%',
            height: '90%',
            data: {
                tripInfo: tempArrayTrips
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
            }
        });

    }

    getLastClosedPaymentDate() {
        this.showSpinner = true;
        this.workhourservice.getLastClosedPaymentDate<ResponseBase>()
            .subscribe((response: ResponseBase) => {
                if (response.result) {
                    this.closedPaymentDate = response.result;
                    this.closePayValue = this.closedPaymentDate ? moment(this.closedPaymentDate).add(1, 'days').format('YYYY-MM-DD') : null;
                } else {
                    this.closedPaymentDate = null;
                    this.closePayValue = null;
                }
            },
                error => (err) => {
                    this.showSpinner = false;
                },
                () => {
                    this.showSpinner = false;
                });
    }
    onRenderCellStartDate(args: RenderDayCellEventArgs): void {
        if (args.date.getDay() == 1) 
            args.isDisabled = false;
         else 
            args.isDisabled = true;
        
    }

    onStartDateChange(args) {
        if (args) {
            let d: any = args;
            d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
            d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
            var yearStart: any = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
            var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
            this.currentweek = weekNo;
            this.weekNumber = weekNo;
            this.startAndEndOfWeek(args);
        }
    }

    onFocusIn(event) {
        event.model.show();
    }
    receiveFilterEvent(event) {
        this.orderBy = event;
        this.getWorkHours(this.selectworker.id, new RequestWorkHourGroupBy());
    }

    ngOnDestroy() {
        this._fuseSidebarService.getSidebar('navbar').unfold();
    }

    _handleKeydown(event: KeyboardEvent) {
        if (event.keyCode === 13) {
            event.stopPropagation();
        }
    }

}
