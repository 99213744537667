import { Component, OnInit } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { MaterialService } from 'app/main/pages/material/material.service';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { MaterialPostNumberDTO } from 'app/main/model/Material';

//translate
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormService } from 'app/core/services/form.service';
import { PostnumberRequest } from 'app/main/model/RequestBase';


@Component({
    selector: 'material-postNummer',
    templateUrl: './postNummer.component.html',
    styleUrls: ['./postNummer.component.scss']
})

export class PostNummerComponent implements OnInit {
    selectedIndex: number = 0;
    showSpinner = false;
    PostnumberRequest: PostnumberRequest = new PostnumberRequest();
    PostnumberList: any = [];
    displayPagination: boolean;
    pagination: any;
    MaterialColorCode: any;
    colorCodeDetail: any;
    postNumberDetail: MaterialPostNumberDTO = new MaterialPostNumberDTO();
    postNummberForm: FormGroup;
    search: any = '';
    constructor(private translationLoader: FuseTranslationLoaderService,
        private materialService: MaterialService,
        private fb: FormBuilder,
        private notificationsService: NotificationsService,
        public formService: FormService,
        private translate: TranslateService) {
        this.translationLoader.loadTranslations(danish, english);
    }

    ngOnInit() {
        this.initFormControl();
        this.PostnumberRequest = new PostnumberRequest();
        this.PostnumberRequest.Index = 1;
        this.PostnumberRequest.Limit = 100;
        this.getPostnumberList();
    }

    initFormControl() {
        this.postNummberForm = this.fb.group({
          id: [0],
          postNumber: [null, Validators.required],
          percentage: [null,Validators.required],
          city: [null,Validators.required],
          postNumberTo:[null],
          remark: [null],
        });
    }

    addOrUpdate(){
        this.formService.markFormGroupTouched(this.postNummberForm);
        if (this.postNummberForm.valid) {
            this.showSpinner = true;
            this.materialService.AddOrUpdatePostNummer<ResponseBase>(this.postNumberDetail)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.showSpinner = false;
                    if(this.postNumberDetail.id){
                        this.translate.get("MESSAGES.Updated").subscribe({
                            next: (res) => {
                                this.notificationsService.success(res);
                            },
                            error: err => {
                            }
                        });
                    }
                    else{
                        this.translate.get("MESSAGES.Created").subscribe({
                            next: (res) => {
                                this.notificationsService.success(res);
                            },
                            error: err => {
                            }
                        });
                    }
                    this.postNumberDetail = new MaterialPostNumberDTO();
                    this.postNummberForm.reset();
                    this.getPostnumberList();
                },
                error: err => {
                    this.showSpinner = false;
                        this.translate.get("MESSAGES.Failed").subscribe({
                            next: (res) => {
                                this.notificationsService.error(res);
                            },
                            error: err => {
                            }
                        });
                },
                complete: () => {
                        this.showSpinner = false;
                    }
                });
        }
    }

    getPostnumberList() {
        this.showSpinner = true;
        this.materialService.GetPostnumberList<ResponseBase>(this.PostnumberRequest)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.PostnumberList = response.result;
                    this.pagination = response.pagination;
                    this.displayPagination = true;
                },
                error: err => { },
                complete: () => {
                    this.showSpinner = false;
                }
        });
    }

    editPostnumber(item){
        this.postNumberDetail = Object.assign({}, item);
    }

    deletePostnumber(Id){
        this.showSpinner = true;
        this.materialService.removePostNummer(Id).subscribe({
            next: (response: ResponseBase) => {
                this.translate.get("MESSAGES.Deleted").subscribe({
                    next: (res) => {
                        this.notificationsService.create(res);
                    },
                    error: err => {                        
                    }
                });
                this.getPostnumberList();
            },
            error: err => {
                this.translate.get("MESSAGE.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    reset(){
        this.postNumberDetail = new MaterialPostNumberDTO();
        this.postNummberForm.reset();
    }

    Search(value) {
        if (this.search)
            this.PostnumberRequest.Search = this.search.trim();
        else
            this.PostnumberRequest.Search = "";
        this.PostnumberRequest.Index = 1;
        this.PostnumberRequest.Limit = 100;
        this.getPostnumberList();
    }

    receivePaginatonEvent(event) {
        this.PostnumberRequest.Index = event.pageIndex;
        this.PostnumberRequest.Limit = event.pageSize;
        this.getPostnumberList();
    }
}