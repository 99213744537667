import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../../../core/modules/shared.module';

import { VibeUserProfileComponent } from './user-profile.component';
import { UserProfileService } from './user-profile.service';
import { CoWorkersService } from '../coWorker/coWorkers.service';
const routes = [
  {
    path: 'user-profile',
    component: VibeUserProfileComponent
  }

];

@NgModule({
  declarations: [
    VibeUserProfileComponent,
  ],
  imports: [
    SharedModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    VibeUserProfileComponent
  ],
  providers: [
    UserProfileService, CoWorkersService]
})

export class VibeUserProfileModule {
}
