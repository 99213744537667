export const locale = {
    lang: 'en',
    data: {
        'FIELDS': {
            'SrNo': 'Sr no.',
            'Date': 'Date',
            'SupplierName': 'Supplier name',
            'OrderMaterial': 'QTY',
            'OrderAmount': 'Amt',
            'ReceivedMaterila': 'Received Materila',
            'ReceivedAmount': 'Received Amt',
            'Status': 'Status',
            'Email': 'E-mail',
            'Attachment': 'Attachment',
            'Coworker': 'Last updated by',
            'ReceivedDate': 'Received Date',
            'TextSearch': 'Search',
            'SelectSupplier': 'Select Supplier',
            'StartDate': 'Start Date',
            'EndDate': 'End Date',
            'SearchAllOptions':'All',
        },
        'MESSAGES': {
            'Failed': 'Failed',
            'NORECORD': 'No Material Found',
        },
        'TITLE': {
            'CustomerPagination': 'Number of logs per. page',
        },
        'SupplierOrderStatus':{
            'Ordered':'Ordered',
            'Received':'Received',
        },
        "SupplierList": {
            'value1': 'Ordered',
            'value2': 'Received',
            'value3': 'Partially Received',
            'value4': 'Delete',
        },
        'TOOTIPORTITLE':{
            'ViewEmailTemplate':'View Email Template',
            'ViewAttachmentFile': 'View Attachment File',
            'EditOrderedItem':'Edit Ordered Material Item',
        }
    }
}