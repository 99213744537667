export const locale = {
    lang: 'en',
    data: {
        'PAYROLL': {
            'TITLE': 'Payroll Entry',
        },
        'Headers': {
            "Title": "Payroll list",
            'DeleteMessage': 'Are you sure you want to delete'
        },
        "FIELDS": {
            'Coworker': 'Coworker',
            'Date': 'Date',
            'NumberofHours': 'Number of hours',
            'HourlyRate': 'Hourly Rate',
            'TotalAmount': 'Total Amount',
            'Description': 'Description',
            'koersel': 'Driving',
            'GDag': 'G-days',
            'Holidays': 'Holidays',
            'NumberofHolidays': 'Number of holidays',
            'Archived': 'Archived',
            'SalaryAdvance':'Lønforskud',
            'Other': 'Other',
            'Active': 'Include All Inactive',
        },
        "BUTTONS": {
             'IsArchived':'Is Archived'
        },
        "MESSAGES": {
            'Failed': 'Failed',
            'Deleted': 'Deleted successfully',
            "NORECORD": "No Record Found",
        },
         'TOOTIPORTITLE': {
            'Edit': 'Edit',
            'Delete': 'Delete',
        }
    }
}
