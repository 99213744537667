import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxMaskModule } from 'ngx-mask';
import { SharedModule } from 'app/core/modules/shared.module';
import { CalendarComponent } from './calendar.component';
import { CalenderWorkingHourPopupComponent } from './component/working-hour-popup/working-hour-popup.component';
import { CalenderEditNonWorkingHourPopupComponent } from './component/edit-non-working-hour-popup/edit-non-working-hour-popup.component';
import { ProjectListComponent } from './component/project-list/project-list.component';
import { InquiryListComponent } from './component/inquiry-list/inquiry-list.component';
import { CalendarService } from './calendar.service';
import { ScheduleModule } from '@syncfusion/ej2-angular-schedule';
import { DayService, WeekService, WorkWeekService, MonthService, AgendaService, MonthAgendaService } from '@syncfusion/ej2-angular-schedule';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { CalenderEditWorkingHourPopupComponent } from './component/edit-working-hour-popup/edit-working-hour-popup.component';
// import { RemoveNonWorkingHourPopupComponent } from './component/remove-non-working-hour-popup/remove-non-working-hour-popup';
import { ProjectMtoMComponent } from '../project/component/projectMtoM/projectMtoM.component';
import { ProjectNotesComponent } from '../project/component/notes/projectNotes.component';
import { OffersComponent } from '../project/component/offers/offers.component';
import { CalenderInquiryWorkingHourPopupComponent } from './component/inquiry-working-hour-popup/inquiry-working-hour-popup.component';
import { QuestionPopupComponent } from './component/question-popup/question-popup.component';
import { SMSEmailSelectionComponent } from './component/sms-email-selection/sms-email-selection.component';
//import { DraggableDialogDirective } from 'app/core/directives/draggable.directive';
// import { CalendarDefaultCoworkerComponent } from './component/calendar-default-coworker/calendar-default-coworker.component';
import { ClickPlanPopupComponent } from './component/click-plan-popup/click-plan-popup.component';
import { ShiftSelectionComponent } from './component/shift-selection/shift-selection.component';
import { MovePlanningWithDateRangeComponent } from './component/move-planning-with-date-range/move-planning-with-date-range.component';
import { CalenderAddNonWorkingHourPopupComponent } from './component/add-non-working-hour-popup/add-non-working-hour-popup.component';
import { MovePlanningFromOneCowrkerToAnother } from './component/move-planning-to-another-coworker/move-planning-to-another-coworker.component';
import { CoWorkerComponent } from 'app/main/pages/calendar/component/coWorker/coWorker.component';
import { BulkProjectPlanPopupComponent } from 'app/main/pages/calendar/component/bulk-project-plan-popup/bulk-project-plan-popup.component';

const routes = [
    {
        path: 'planning',
        component: CalendarComponent
    },
    {
        path: 'planning/:projectNumber/:requisitionId',
        component: CalendarComponent
    },
];

@NgModule({
    declarations: [
        CalendarComponent,
        CalenderWorkingHourPopupComponent,
        CalenderEditNonWorkingHourPopupComponent,
        ProjectListComponent,
        InquiryListComponent,
        CalenderEditWorkingHourPopupComponent,
        // RemoveNonWorkingHourPopupComponent,
        CalenderInquiryWorkingHourPopupComponent,
        QuestionPopupComponent,
        SMSEmailSelectionComponent,
        //DraggableDialogDirective,
        // CalendarDefaultCoworkerComponent,
        ClickPlanPopupComponent,
        ShiftSelectionComponent,
        MovePlanningWithDateRangeComponent,
        CalenderAddNonWorkingHourPopupComponent,
        CoWorkerComponent,
        MovePlanningFromOneCowrkerToAnother,
        BulkProjectPlanPopupComponent
    ],
    imports: [
        DatePickerModule,
        ScheduleModule,
        SharedModule,
        RouterModule.forChild(routes),
        NgxMaskModule.forRoot(),
        //AgmCoreModule.forRoot({
        //    apiKey: 'AIzaSyB2LPLAZRlRAHndaGDzsuXpBrUdzivMqYQ',
        //    libraries: ["places"]
        //})
    ],
    entryComponents: [CalenderWorkingHourPopupComponent,
        CalenderEditNonWorkingHourPopupComponent, 
        CalenderEditWorkingHourPopupComponent, 
        // RemoveNonWorkingHourPopupComponent,
        CalenderInquiryWorkingHourPopupComponent,
        ProjectMtoMComponent,
        ProjectNotesComponent,
        OffersComponent,
        QuestionPopupComponent,
        SMSEmailSelectionComponent,
        // CalendarDefaultCoworkerComponent,
        ClickPlanPopupComponent,
        ShiftSelectionComponent,
        MovePlanningWithDateRangeComponent,
        CalenderAddNonWorkingHourPopupComponent,
        CoWorkerComponent,
        MovePlanningFromOneCowrkerToAnother,
        BulkProjectPlanPopupComponent
    ],
    exports: [ProjectListComponent, InquiryListComponent

    ],
    providers: [DayService,
        WeekService,
        WorkWeekService,
        MonthService,
        AgendaService,
        MonthAgendaService,
        CalendarService]
})

export class PlanningCalendarModule {
}
