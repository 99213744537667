import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@fuse/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FusePipesModule } from 'app/core/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ProjectCycleLogComponent } from './project-cycle-log.component';
import { PaginationModule } from 'app/main/pages/pagination/pagination.module';
import { ProjectLogDetailComponent } from './component/project-log-detail/project-log-detail.component';
import { RequisitionLogDetailComponent } from './component/requisition-log-detail/requisition-log-detail.component';
import { RequisitionMaterialLogDetailComponent } from './component/requisition-material-log-detail/requisition-material-log-detail.component';
import { RequisitionCoworkerLogDetailComponent } from './component/requisition-coworker-log-detail/requisition-coworker-log-detail.component';
import { InquiryLogDetailComponent } from './component/inquiry-log-detail/inquiry-log-detail.component';
import { OfferLogDetailComponent } from './component/offer-log-detail/offer-log-detail.component';
import { OfferRoomLogDetailComponent } from './component/offer-room-log-detail/offer-room-log-detail.component';
import { MaterialLogDetailComponent } from './component/material-log-detail/material-log-detail.component';


@NgModule({
    declarations: [ProjectCycleLogComponent,
        ProjectLogDetailComponent,
        RequisitionCoworkerLogDetailComponent,
        RequisitionLogDetailComponent,
        RequisitionMaterialLogDetailComponent,
        OfferLogDetailComponent,
        InquiryLogDetailComponent,
        OfferRoomLogDetailComponent,
        MaterialLogDetailComponent,
    ],
    imports: [
        CommonModule,
        MaterialModule,
        FlexLayoutModule,
        FusePipesModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        DatePickerModule,
        NgxMatSelectSearchModule,
        PaginationModule
    ],
    exports: [ProjectCycleLogComponent,
        ProjectLogDetailComponent,
        RequisitionCoworkerLogDetailComponent,
        RequisitionLogDetailComponent,
        RequisitionMaterialLogDetailComponent,
        OfferLogDetailComponent,
        InquiryLogDetailComponent,
        OfferRoomLogDetailComponent,
        MaterialLogDetailComponent,
    ],

    entryComponents: [ProjectLogDetailComponent,
        RequisitionCoworkerLogDetailComponent,
        RequisitionLogDetailComponent,
        RequisitionMaterialLogDetailComponent,
        OfferLogDetailComponent,
        InquiryLogDetailComponent,
        OfferRoomLogDetailComponent,
        MaterialLogDetailComponent,
    ]
})

export class ProjectCycleLogModule {

}