import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../../../@fuse/material.module';

import { GoogleMapDirectionComponent } from './google-map-direction.component';
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { GoogleMapDirectionService } from './google-map-direction.service';
import { environment } from '../../../../environments/environment';

@NgModule({
  declarations: [
        GoogleMapDirectionComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    TranslateModule,
    AgmCoreModule.forRoot({
        apiKey: environment.GooogleKey,
      libraries: ["places"]
    }),
      AgmDirectionModule,
    ],
    entryComponents: [],
  exports: [
      GoogleMapDirectionComponent,
  ],
    providers: [GoogleMapDirectionService]
})

export class GoogleMapDirectionModule {
}
