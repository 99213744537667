export const locale = {
  lang: 'en',
  data: {
    'Headers': {
      'ViewAlert': 'Do you want to open this file?',
      'View': 'View',
      'No': 'No'
    }
  }
};
