export const locale = {
    lang: 'en',
    data: {
        'TABS': {
        },
        'MESSAGES': {
            'Add': 'Added successfully',
            'Add_Error': 'No record found on Vagttider or Vagtplan table, please contact to administrator.',
            'Some_Coworker_Add_Error': 'Some coworker can not add work hour.',
            'CanNotAddWorkHour': '{@Coworker} co workers Cannot add working hour.',
            'DeleteNonWorkingHoursOne': 'Do you want to delete',
            'DeleteNonWorkingHoursTwo': 'from',
            'DeleteNonWorkingHoursThree': 'to'
        },
        'Button': {
            'Plan': 'Plan',
            'Save': 'Save',
            'Cancel': 'Cancel',
            'Delete': 'Delete',
            'GoToProject': 'Go to project',
        },
        'FIELDS': {
            'Coworker': 'Coworker',
            'Project': 'Project',
            'StartDate': 'Start Date ',
            'EndDate': 'End Date ',
            'Shift': 'Period of time',
            'Not-Found-Worker': 'Co-worker not found',
            'Not-Found-Project': 'Project not found',
            'Requisition': 'Requisition',
            'EnterStarttime': 'Enter Start time',
            'EndTime': 'Enter End Time',
            'Startime': 'Startime',
            'Endtime': 'Endtime',
            'BreakTime': 'Add Break time (30 min)',
            'WorkhoursType': 'Workhours type',
            'nonStatus': 'Status',
            'nonText': 'Text',
            'WeekNumber': 'Week number',
            'LeaveStatus': 'Leave status',
            'AdminComment': 'Admin comment',
            'CustomerNo': 'Customer No.',
            'Name': 'Name',
            'Email': 'E-mail',
            'PhoneNo': 'Phone No.',
            'Address': 'Address',
            'Address-line-2': 'Address(Line 2)',
            'City': 'City',
            'Zipcode': 'Zipcode',
            'Position': 'Position',
            'Comments': 'Comments  [ Optional ]',
            'WhereInquiryCome': 'Where does the query come from',
            'received': 'Received',
            'IndoorOutDoor': 'Indoor,Outdoor,Spray Painting,Reclamation*',
            'CoworkerComment': 'Coworker Comment',
            'DepartmentPhone': 'Phone',
        },
        'selectedOption': {
            'value1': 'Add 07:00 to 14:30',
            'value2': 'Add 07:00 to 15:00',
            'value3': 'Add Hours Manually',
        },
        'Header': {
            'Add7to0230': 'Tilføj 07:00 til 14:30',
            'Add7to03': 'Tilføj 07:00 til 15:00',
            'Co-workerlist': 'Co-worker List',
        },
        'Lable': {
            'Timer': 'Timer',
            'HoursLeft': 'Hours Left',
            'Lift': 'Left',
            'Stigemand': 'Riseman',
            'RequisitionText': 'Task text',
            'No': 'No',
            'Yes': 'Yes',
            'Estimate': 'Estimate Price',
            'Price-included': 'Price incl. VAT',
            'Price-excluded': 'Price excl. VAT',
            'VAT': 'VAT/moms',
            'MaterialTotalPrice': 'Material Total',
            'MaterialTotalPriceIncludeVAT': 'Material Total incl. VAT',
            'MaterialPersonalTotalPrice': 'Material Personal Price Total',
            'hoursOnCase': 'Hours on case',
            'noOfDays': 'No of days',
            'Hoursspent': 'Hours spent',
            'Name': 'Name',
            'Hours': 'Hours',
            'TotalHours': 'Total Hours',
            'ScheduledDates': 'Scheduled dates',
        },
        'selectedWorkHourStatus': {
            'value1': 'Vacation',
            'value2': 'Sick',
            'value3': 'Holiday of the work ',
            'value4': 'Holiday with payment',
            'value5': 'Holiday without payment',
            'value6': 'School or learning',
            'value7': 'No payment for extra work but holiday for it ',
            'value8': 'Other things',
            'value9': 'Offer'
        },
        'selectedWorkHoursApproveLeaveOption': {
            'value1': 'Apply',
            'value2': 'Approved',
            'value3': 'Rejected',
        },
        'whereDoesTheInquiryComeFrom': {
            'value1': 'WWW (Internet)',
            'value2': 'Website',
            'value3': 'Facebook',
            'value4': 'Recommendation',
            'value5': 'Event',
            'value6': 'Manual',
            'value7': 'Telephone',
        },
        'selectedValueIndoorOutdoorStatus': {
            'value1': 'Indoor ',//Man in a room
            'value2': 'Outdoor', //Sun
            'value3': 'Spray Painting',//spraypaint
            'value4': 'Complaint', //Reklamation
            'value5': 'Staff purchase', //drawing of painter
            'value6': 'Store purchase' //Nordsjø logo
        },
    }
};
