import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customerhelp',
  templateUrl: './customerhelp.component.html',
  styleUrls: ['customerhelp.component.scss']
})
export class CustomerhelpComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
