import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { PaintSelectTime } from 'app/constant/common.constant';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-copy-material-offer-item-calculation',
  templateUrl: './copy-material-offer-item-calculation.component.html',
  styleUrls: ['./copy-material-offer-item-calculation.component.scss']
})
export class CopyMaterialOfferItemCalculationComponent implements OnInit {
  showSpinner = false;
  getData : any;
  SelectedCategoryTime = PaintSelectTime;
  fromSelectedId:string = '';
  toSelectedId:string = '';
  selectedFromValue: string = '';
  selectedToValue:any= [];
  material:FormGroup;
  constructor(private translationLoader: FuseTranslationLoaderService,private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<CopyMaterialOfferItemCalculationComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any)
     { this.translationLoader.loadTranslations(english, danish) 
    }

    ngOnInit() {
    this.getData = this.data;
    this.material = this.formBuilder.group({
      from: [null, Validators.compose([Validators.required])],
      to: [null, Validators.compose([Validators.required])],
    })
  }

  getPaintSElectValue(type:number){
    const selectObj = PaintSelectTime.find(item => item.id === type);
    return selectObj ? selectObj.value : '';
  }

  cancel(e){
    this.dialogRef.close(e ? true : false)
  }

  copy(e){
    this.material.markAllAsTouched();
    if(this.material.valid)
      this.dialogRef.close({"toSelectedId":this.selectedToValue, "fromSelectedId":this.fromSelectedId})
  }

  formMaterialValue(obj){
   this.fromSelectedId = obj.id;
  }

  toMaterialValue(obj){
   this.toSelectedId = obj.id;
  }

}
