import { Component, OnInit, Inject, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as english } from 'app/main/pages/coWorker/translate/en';
import { locale as danish } from 'app/main/pages/coWorker/translate/ds';
import { CoWorkersService } from 'app/main/pages/coWorker/coWorkers.service';
import { ChangeEmail } from 'app/main/model/ChangeEmail';
import { IsNumber } from 'app/core/Validators/validation';
import { SendEmailPasswordData } from 'app/main/model/RequestBase';
@Component({
  selector: 'send-email-password',
  templateUrl: './send-email-password.component.html',
  styles: []
})
export class SendEmailPasswordComponent implements OnInit {
  SendEmailPasswordForm: FormGroup;
  SendEmailPassword: ChangeEmail;
  changePassword:boolean = false;
  isEdit: boolean = false;
  constructor(private formBuilder: FormBuilder, private translationLoader: FuseTranslationLoaderService, private coWorkersService: CoWorkersService,
    public dialogRef: MatDialogRef<SendEmailPasswordComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: SendEmailPasswordData) {
    this.translationLoader.loadTranslations(english, danish);
  }

  ngOnInit() {
    //this.SendEmailPassword = new ChangeEmail();
    this.SendEmailPasswordForm = this.formBuilder.group({
      sendEmailId: ['', Validators.compose([Validators.required, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])],
      sendEmailPassword:[null, Validators.required],
      smtp:[null, Validators.required],
      smtpPort:[null, Validators.required],
      
    });
    this.SendEmailPasswordForm.patchValue(this.data);
    this.isEdit =  this.data.isEdit;
    if(this.isEdit){
      this.SendEmailPasswordForm.controls["sendEmailPassword"].setValidators(null);
      this.SendEmailPasswordForm.controls["sendEmailPassword"].updateValueAndValidity();
    }
  }

  EmailChange($event) {
    event.preventDefault();
    this.dialogRef.close(this.SendEmailPasswordForm.value);
    //this.sendEmailPassword.id = this.data;
    // this.coWorkersService.ChangeEmail<ResponseBase>(this.ChangeEmail).subscribe({
    //   next: () => {
    //     this.dialogRef.close(this.ChangeEmail.email);
    //   },
    //   error: err => { },
    //   complete: () => { }
    // });
  }

  onCancel() {
    this.dialogRef.close();
  }

  changePass(){
    this.changePassword = true;
    this.SendEmailPasswordForm.controls["sendEmailPassword"].setValidators([Validators.required]);
    this.SendEmailPasswordForm.controls["sendEmailPassword"].updateValueAndValidity();
  }

  isNumber(evt) {
    return IsNumber(evt);
  }

}
