export const locale = {
    lang: 'en',
    data: {
        'Header':{
            'Changeshifthours': 'Change shift hours'
        },
        'FIELDS': {
            'Name': 'Name',
            'Questions': 'Question',
            'SrNo': 'Sr No.',
            'Status': 'Status',
            'Body':'Body',
            'All':'All days'
        },
        'MESSAGES': {
            'Success': 'SMS is sent successfully',
            'Failed': 'Failed',
          'NORECORD': 'No Question Found',
            'SAVESUCCESSFULLY':'Save Successfully.'
        },
        'BUTTONS': {
            'SMS': 'SMS',
            'Email': 'Email',
            'Both': 'SMS & Email',
            'None': 'None',
            'Save': 'Save',
            'Back': 'Cancel'
        },
        'VALIDATIONS': {
            'Mobile': 'Mobile number is required.',
            'Text': 'Text is required.'
        },
    }
};
