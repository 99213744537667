export const locale = {
    lang: 'en',
    data: {
        'STATUS': {
            'Created': 'Created',
            'Updated': 'Updated',
            'Deleted': 'Deleted',
            'Inactive': 'Inactive',
            'Active': 'Active',
            'Statuschane': 'Status change',
            'FileUploaded': 'File Uploaded',
            'FileDeleted': 'File Deleted',
        },
        'TITLE': {
            'CustomerPagination': 'Number Log Per Page',
        },
        'BUTTONS': {
            'BACK': 'Cancel',
            'BTNSAVE': 'Save',
        },
        'FIELDS': {
            'SRNO': 'Sr No',
            'Status': 'Status',
            'Number': 'Number',
            'Name': 'Name',
            'Createdby': 'Created By',
            'CreatedDate': 'Created Date',
            'ModifyBy': 'Modify By',
            'ModifyDate': 'Modify Date',
            'CoworkerName': 'Coworker',
            'Date': 'Date',
            'Description': 'Description',
            'Action': 'Action',
            'ModuleName': 'Module',
            'ByCoworker': 'By Co-worker',
            'ByCustomer': 'By Customer',
            'RequisitionAddress':'Requisition Adresse',
            'ProjectAddress':'Project Adresse',
            'Recipient':'Recipient'
        },
        'Headers': {
        },
        'Module': {
            'Project': 'Project',
            'Requisition': 'Requisition',
            'RequisitionMaterial': 'Requisition Material',
            'RequisitionCoworker': 'Requisition Coworker',
            'Offer': 'Offers',
            'Inquiry': 'Inquirie',
            'Email': 'Email',
            'StatusChangeAnswer': 'Status Change Answer',
            'OfferRoom': 'Offer Room',
            'File': 'File',
            'BookMark':'Note',
            // 'Offer':'Offer',
            'MtoM':'M to M',
        },

        'MESSAGES': {
            'NORECORD': 'No Record Found',
            'PROJECTCREATED': '{@CoworkerName} has created new record.',
            'PROJECTUPDATED': '{@CoworkerName} has updated record.',
            'PROJECTDELETED': '{@CoworkerName} has deleted record.',
            'PROJECTINACTIVE': '{@CoworkerName} has inactive record.',
            'PROJECTACTIVE': '{@CoworkerName} has active record.',
            'STATUSCHANGED': '{@CoworkerName} has changed requisition status.',
            'STATUSCHANGEDBYCUSTOMER': 'Customer answer the status question',
            'FILEUPLOADED': '{@CoworkerName} has uploaded \'{@FileName}\' file.',
            'FILEDELETED': '{@CoworkerName} has removed \'{@FileName}\' file.',
            'CREATED': 'Record created',
            'UPDATED': 'Record updated'
        },
    }
};


