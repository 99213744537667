import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { RequestEmailSendingCoWorker, ResponseForLocalStorageData, SendEmailJson } from 'app/main/model/RequestBase';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiConstant } from 'app/core/constant/ApiConstant';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CommonService {
    private coworkerApiUrl: string;
    sendEmailJsonObj: SendEmailJson;
    settingMasterValues:any[] = [];

    constructor(private http: HttpClient,
        private notificationsService: NotificationsService,
        private translate: TranslateService) {
        this.coworkerApiUrl = ApiConstant.API_URL + ApiConstant.COWORKER + "/";
    }
    
    playFailedTone() {
        //let audio = new Audio();
        //audio.src = "../../../assets/soundEffect/failure.m4a";
        //audio.load();
        //audio.play();
    }
    
    playWarningTone() {
        let audio = new Audio();
        audio.src = "../../../assets/soundEffect/Warning.mp3";
        audio.load();
        audio.play();
    }

    playSuccessTone() {
        let audio = new Audio();
        audio.src = "../../../assets/soundEffect/success.m4a";
        audio.load();
        audio.play();
    }

    public GenerateRandomUniqueId(){
        var min=1000000000;
        var max=1111111111;
        var dt = moment(new Date()).format("YYYYMMDDHHmmss");
        var autoGenerate = Math.floor(Math.random() * (max - min) + min);
        return (dt + autoGenerate + "XXX").substring(0,20);
    }

    public GetEmailSendingCoworkerList<T>(req: RequestEmailSendingCoWorker): Observable<T> {
        var url = this.coworkerApiUrl + "EmailSendingCoworkersList";
        let param = this.GetParams(req);
        return this.http.get<T>(url, {
            params: param
        });
    }

    public GetParams(param) {
        let params: HttpParams = new HttpParams();
        for (var key in param) {
            if (param.hasOwnProperty(key)) {
                let val = param[key];
                params = params.append(key, val);
            }
        }
        return params;
    }

    public setResponseForCoworker(model) {
        var responseForLocalStorageData = new ResponseForLocalStorageData();
        var val = localStorage.getItem(ApiConstant.LocalResponseModel);
        if (val)
            responseForLocalStorageData = JSON.parse(val);
        responseForLocalStorageData.Coworkers = model;
        localStorage.setItem(ApiConstant.LocalResponseModel, JSON.stringify(responseForLocalStorageData));
    }

    public getResponseForCoworker() {
        var val = localStorage.getItem(ApiConstant.LocalResponseModel);
        var responseForLocalStorageData = new ResponseForLocalStorageData();
        var requestForLocalStorageCoworker = [];
        if (val) {
            responseForLocalStorageData = JSON.parse(val);
            if (responseForLocalStorageData.Coworkers)
                requestForLocalStorageCoworker = responseForLocalStorageData.Coworkers;
        }
        return requestForLocalStorageCoworker;
    }

    public checkEmailIsToBeSent() {
        this.settingMasterValues = Object.assign([], JSON.parse(localStorage.getItem("settingMasterValues")));
        var tempSettingObject = this.settingMasterValues.find(s => s.key1 == "Notification" && s.key2 == environment.SendingNotificationValue2 && s.key3 == "SendEmail").value;
        if (tempSettingObject){
            this.sendEmailJsonObj = JSON.parse(tempSettingObject);
            this.sendEmailJsonObj.IsSendEmail= this.sendEmailJsonObj.IsSendEmail ? this.convertStringToBoolean(this.sendEmailJsonObj.IsSendEmail.toString()): false;
            this.sendEmailJsonObj.IsTimeBased= this.sendEmailJsonObj.IsTimeBased ? this.convertStringToBoolean(this.sendEmailJsonObj.IsTimeBased.toString()): false;
        }
        if (this.sendEmailJsonObj && this.sendEmailJsonObj.IsSendEmail && this.sendEmailJsonObj.IsTimeBased) {
            const dayShortName: string = new Date().toLocaleString('en-US', { weekday: 'short' });
            if (
                (this.sendEmailJsonObj.isExecuteMonday && (dayShortName === 'Mon' || dayShortName === 'ma')) ||
                (this.sendEmailJsonObj.isExecuteTuesday && (dayShortName === 'Tue' || dayShortName === 'ti')) ||
                (this.sendEmailJsonObj.isExecuteWednesday && (dayShortName === 'Wed' || dayShortName === 'on')) ||
                (this.sendEmailJsonObj.isExecuteThursday && (dayShortName === 'Thu' || dayShortName === 'to')) ||
                (this.sendEmailJsonObj.isExecuteFriday && (dayShortName === 'Fri' || dayShortName === 'fr')) ||
                (this.sendEmailJsonObj.isExecuteSaturday && (dayShortName === 'Sat' || dayShortName === 'lø')) ||
                (this.sendEmailJsonObj.isExecuteSunday && (dayShortName === 'Sun' || dayShortName === 'sø'))
            ) {
                const fromHourParts: string[] = this.sendEmailJsonObj.HoursFromSend.split(':');
                const toHourParts: string[] = this.sendEmailJsonObj.HoursToSend.split(':');
                const todayDT: Date = new Date(new Date().toLocaleDateString() + ' 00:00:00.0');
                const fromHourCheckDT: Date = new Date(todayDT.getTime() + (parseInt(fromHourParts[0]) * 60 + parseInt(fromHourParts[1])) * 60000);
                const toHourCheckDT: Date = new Date(todayDT.getTime() + (parseInt(toHourParts[0]) * 60 + parseInt(toHourParts[1])) * 60000);
                const currentDateTime: Date = new Date();

                if (currentDateTime >= fromHourCheckDT && currentDateTime <= toHourCheckDT) {
                    // returns 1 to send email
                } else {
                    this.translate.get("Message.EmailSavedInQueueWanrning").subscribe({
                        next: (res) => {
                            this.notificationsService.alert(res);
                        },
                        error: err => {
                        }
                    });
                }
            }
        }
    }

    public convertStringToBoolean(value: string | undefined | null): boolean {
        if (!value || value.toLowerCase() === 'false') {
            return false;
        } else if (value.toLowerCase() === 'true') {
            return true;
        } else {
            return false;
        }
    }

    public isDefined<T>(value: T | undefined | null): value is T {
        return value !== undefined && value !== null;
    }
}