export const locale = {
    lang: 'en',
    data: {
        'FIELDS': {
            'Designation': 'Designation',
            'ValideFrom': 'Valide From',
            'SRNO': 'Sr no.'
        },
        'BUTTONS': {
            'Add': 'Terms Of Employment',
            'BACK': 'Back',
        },
        'MESSAGES': {
            'Updated': 'Updated Successfully.',
            'Failed': 'Failed',
            'Deleted': 'Deleted successfully',
            "NORECORD": "No Record Found",
            'Created': 'Created successfully',
        },
        'Validation': {
            'DesignationRequired': 'Designation is required',
            'ValideFromRequired': 'Valide from date is required'
        },
        'Headers': {
            'TitleList': 'Terms Of Employment List',
            'DeleteMessage': 'Are you sure you want to delete'
        },
        'TITLE': {
            'CustomerPagination': 'Number of logs per. page',
        },
        'TOOTIPORTITLE': {
           'Edit': 'Edit',
           'Delete': 'Delete',
       }
    }
};