export const locale = {
  lang: 'ds',
  data: {
    'CUSTOMER': {
      'TITLE': 'kunder',
      'DESC': 'Hej kunder!'
    },
    'BUTTONS': {
      'NEWCUSTOMER': 'Ny kunde',
      'BACK': 'Tilbage'
    },
    'MESSAGES': {
      'NORECORD': 'Ingen registrering fundet'
    },
    'FILTERS': {
      'TEXTSEARCH': 'Tekst Søg',
      'NUMBERSEARCH': 'Kunde nr. Søg',
      'CustomerNumber': 'Kunde nummer',
      'CustomerName': 'Kundenavn',
      'Email': 'E-mail',
      'PhoneNumber': 'Telefon Nummer',
      'Address': 'Adresse',
      'City': 'By',
      'ZipCode': 'Postnummer',
      'Position': 'Position',
      'Text': 'Tekst',
      'Action': 'Handling',
      'SORTING': 'Sorter efter'
    },
    'TABHEADER': {
      'Dashboard': 'Dashboard',
      'Customers': 'Kunder',
      'Materials': 'Materialer',
      'Projects': 'Projekter',
      'Documents': 'Dokumenter',
      'Workhours': 'Arbejdstimer',
      'Messages': 'Beskeder',
      'CoWorkers': 'Medarbejdere',
      'CompanyInfo': 'Firma-info',
      'Help': 'Manual'
    },
    'SORTBY':
    [
      //{
      //  Text: '     ',
      //  Value: 'ID DESC'
      //},
      {
        Text: 'Navn (A-Z)',
        Value: 'Text'
      },
      {
        Text: 'Navn (Z-A)',
        Value: 'Text DESC'
      },
      {
        Text: 'Nummer (0-9)',
        Value: 'Number'
      },
      {
        Text: 'Nummer (9-0)',
        Value: 'Number DESC'
      }
    ]
  }
}



