export const locale = {
  lang: 'en',
  data: {
    'Tabs': {
      'Offer': 'Offer',
      'BasicDetail': 'Basic Detail',
      'File': 'File',
      'Category': 'Category',
      'WorkItem':'Work Item'
    },
    'MESSAGE': {
      'Failed': 'Operation failed , try later',
      'Success': 'Success.',
      'CommingSoon':'Comming Soon..',
      'Deleted': 'Deleted successfully',
    },
    'FIELDS':{
      'GoTo':'Go to'
    },
    'BUTTONS': {
      'AddCategory': 'Add Category'
    },
    'Headers': {
      'DeleteCategory': 'Do you want to delete category',
    }
  }
};
