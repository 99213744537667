import { Component, OnInit, Inject, Optional, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatOption, } from '@angular/material';
import { InquiryService } from '../../inquiry.service';
import { NotificationsService } from 'angular2-notifications';
import { CoWorker } from "app/main/model/CoWorker";
@Component({
    selector: 'vibe-StartAndVisitDate',
    templateUrl: './startAndVisitDate.component.html',
    styleUrls: ['./startAndVisitDate.component.scss']
})

export class StartAndVisitDateComponent implements OnInit {
    @ViewChild('allSelected', { static: false }) private allSelected: MatOption;
    showSpinner: boolean = false;
    startDate: any;
    startTime: any;
    visit: any;
    visitTime: any;
    isPlanning: boolean = false;
    isScheduledVisit: boolean = false;
    coWokers: any = [];
    coWokersFullList: any = [];
    visitCoworkerIds: any[] = [];
    searchCoworker: string = "";
    selectedCoWorkerList: CoWorker[] = new Array<CoWorker>();
    constructor(private translate: TranslateService,
        private translationLoader: FuseTranslationLoaderService,
        private dialog: MatDialog,
        private inquiryService: InquiryService,
        private notificationsService: NotificationsService,
        public dialogRef: MatDialogRef<StartAndVisitDateComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
        this.translationLoader.loadTranslations(english, danish);

    }

    ngOnInit() {
        if (this.data) {
            this.coWokers = this.data.coworkerList;
            this.coWokersFullList = this.data.coworkerList;
            this.isPlanning = this.data.isPlanning;
            this.isScheduledVisit = this.data.isScheduledVisit;
            this.visitCoworkerIds = this.data.visitCoworkerIds
            if (this.data.startDate) {
                this.startDate = this.data.startDate;
            }
            if (this.data.startTime) {
                this.startTime = this.data.startTime;
            }
            if (this.data.visit) {
                this.visit = this.data.visit
            }
            if (this.data.visitTime) {
                this.visitTime = this.data.visitTime
            }
            this.setSelectedCoWorkerList();
        }
    }

    AddDate() {
        if (this.isPlanning && this.startDate && this.startTime) {
            var startDataDetail = {
                startDate: this.startDate,
                startTime: this.startTime,
                visitCoworkerIds:this.visitCoworkerIds
            }
            this.ngOnBackClick(startDataDetail)
        }
        if (this.isScheduledVisit && this.visit && this.visitTime) {
            var visitDataDetail = {
                visit: this.visit,
                visitTime: this.visitTime,
                visitCoworkerIds: this.visitCoworkerIds
            }
            this.ngOnBackClick(visitDataDetail);
        }
    }

    ngOnBackClick(data) {
        this.dialogRef.close(data);
    }

    DropDownSearchCoWorker() {
        this.coWokers = this.coWokersFullList.filter(o => o.name.toLowerCase().includes(this.searchCoworker.toLowerCase()));
    }

    toggleAllSelection() {
        if (this.allSelected.selected) {
            this.visitCoworkerIds = [];
            this.visitCoworkerIds = this.coWokersFullList.map(o => o.id);
            this.visitCoworkerIds.push("0");
        }
        else 
            this.visitCoworkerIds = [];
        this.setSelectedCoWorkerList();
    }

    DropDownCoWorkerSelect(id) {
        var alreadySelectedCoworker = this.selectedCoWorkerList.find(o => o.id == id);
        if (alreadySelectedCoworker)
            this.selectedCoWorkerList = this.selectedCoWorkerList.filter(o => o.id != id);
        else {
            var findCoworker = this.coWokersFullList.find(o => o.id == id);
            if (findCoworker)
                this.selectedCoWorkerList.push(findCoworker);
        }
    }

    setSelectedCoWorkerList() {
        this.selectedCoWorkerList = [];
        if (this.visitCoworkerIds) {
            this.visitCoworkerIds.forEach(x => {
                var findCoworker = this.coWokersFullList.find(o => o.id == x);
                if (findCoworker)
                    this.selectedCoWorkerList.push(findCoworker);
            })
        }
    }

    selectedCoWorkerDelete(id) {
        this.selectedCoWorkerList = this.selectedCoWorkerList.filter(o => o.id != id);
        this.visitCoworkerIds = this.selectedCoWorkerList.map(o => o.id);
    }
}