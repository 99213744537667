import { Component, OnInit, Optional } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { MaterialWeatherType } from 'app/constant/common.constant';
import { ProjectPriceDiscountDTO } from 'app/main/model/Material';
import { MatDialog, MatDialogRef } from '@angular/material';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { MaterialService } from 'app/main/pages/material/material.service';
import { ProjectService } from 'app/main/pages/project/project.service';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

//translate
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { FormService } from 'app/core/services/form.service';
import { ProjectIndoorOutDoorStatus } from 'app/main/model/ProjectStatus';
import { map } from "rxjs/operators";
import { of } from "rxjs";

@Component({
    selector: "vibe-project-price-discount",
    templateUrl: "./project-price-discount.component.html",
    styleUrls: ["./project-price-discount.component.scss"]
})

export class ProjectPriceDiscountComponent implements OnInit {
    @Optional() public dialogRef: MatDialogRef<ProjectPriceDiscountComponent>;
    priceDiscountForm: FormGroup;
    public showSpinner: boolean = false;
    priceDiscountDetail: any;
    priceDiscountObject = new ProjectPriceDiscountDTO();
    ProjectPriceDiscountList: any = [];
    submitted: boolean = false;
    selectIndoorOutDoorStatus = ProjectIndoorOutDoorStatus;
    hours: any;
    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private dialog: MatDialog,
        private notificationsService: NotificationsService,
        private translate: TranslateService,
        private materialService: MaterialService,
        private projectService: ProjectService,
        private formBuilder: FormBuilder,
        private formService: FormService) {
        translationLoaderService.loadTranslations(danish, english);
        this.hours = [{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }, { value: 6 }, { value: 7 }, { value: 8 },
        { value: 9 }, { value: 10 }, { value: 11 }, { value: 12 }];

    }

    ngOnInit() {
        this.GetProjectPriceDiscountList();
        this.priceDiscountForm = this.formBuilder.group({
            Id: [null],
            StartDay: [null, [Validators.required]],
            EndDay: [null,[Validators.required]],
            StartPrice: [null, [Validators.required]],
            EndPrice: [null],
            Percentage: [null, Validators.required],
            IndoorOutDoor: [null, [Validators.required]]
        });
    }

    GetProjectPriceDiscountList() {
        this.showSpinner = true;
        this.projectService.GetProjectPriceDiscountList().subscribe((response: ResponseBase) => {
            if (response) {
                this.showSpinner = false;
                this.priceDiscountDetail = response.result.myResponse[0];
                if (this.priceDiscountDetail.value) {
                    this.ProjectPriceDiscountList = JSON.parse(this.priceDiscountDetail.value);
                }
            }
        });
    }

    AddUpdate() {
        this.submitted = true;
        this.formService.markFormGroupTouched(this.priceDiscountForm);
        if (this.priceDiscountForm.invalid) {
            return;
        }
        this.showSpinner = true;
        if(!this.priceDiscountObject.id){
            this.priceDiscountObject.id = this.ProjectPriceDiscountList.length + 1;            
            this.ProjectPriceDiscountList.push(this.priceDiscountObject);
        }
        else {
            this.ProjectPriceDiscountList.filter(item => {
                if(item.id == this.priceDiscountObject.id){
                    item.startPrice = this.priceDiscountObject.startPrice;
                    item.endPrice = this.priceDiscountObject.endPrice;
                    item.percentage = this.priceDiscountObject.percentage;
                    item.startDay = this.priceDiscountForm.controls['StartDay'].value; //this.priceDiscountObject.startDay;
                    item.endDay = this.priceDiscountForm.controls['EndDay'].value; //this.priceDiscountObject.endDay;
                    item.indoorOutdoorStatusID = this.priceDiscountObject.indoorOutdoorStatusID;
                }
            });
            let tempData = []
            this.ProjectPriceDiscountList.filter(element => {
                of(element).pipe(this.removeFieldsOp(['dayPrice','totalDayPrice', 'totalDays', 'totalWorkingDays'])).subscribe((e)=> {
                    tempData.push(e)
                });
            });
            this.ProjectPriceDiscountList = Object.assign([], tempData)
        }            
        this.priceDiscountDetail.value = JSON.stringify(this.ProjectPriceDiscountList);
        this.materialService.AddUpdateProjectPriceDiscount(this.priceDiscountDetail.id, this.priceDiscountDetail)
            .subscribe({
                next: (response: ResponseBase) => {
                    if (response) {
                        this.showSpinner = false;
                        this.submitted = false;
                        this.priceDiscountObject = new ProjectPriceDiscountDTO();
                        this.priceDiscountForm.reset();
                        this.translate.get("MESSAGES.Added").subscribe({
                            next: (res) => {
                                this.notificationsService.create(res);
                            },
                            error: err => {
                            }
                        });
                        this.GetProjectPriceDiscountList();
                    }
                },
                error: err => {
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });
                },
                complete: () => {
                    this.showSpinner = false;
                }
            });
    }

    edit(value) {
        this.priceDiscountObject = new ProjectPriceDiscountDTO();
        this.priceDiscountObject = Object.assign({}, value);
    }

    delete(value,index) {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: '200px',
            data: {
                Header: 'Headers.DeletePriceDiscount'
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.showSpinner = true;
                this.ProjectPriceDiscountList.splice(index, 1);
                this.priceDiscountDetail.value = JSON.stringify(this.ProjectPriceDiscountList);
                this.materialService.AddUpdateProjectPriceDiscount(this.priceDiscountDetail.id, this.priceDiscountDetail)
                    .subscribe({
                        next: (response: ResponseBase) => {
                            if (response) {
                                this.showSpinner = false;
                                this.priceDiscountObject = new ProjectPriceDiscountDTO();
                                this.priceDiscountForm.reset();
                                this.translate.get("MESSAGES.Deleted").subscribe({
                                    next: (res) => {
                                        this.notificationsService.create(res);
                                    },
                                    error: err => {
                                    }
                                });
                            }
                        },
                        error: err => {
                            this.showSpinner = false;
                            this.translate.get("MESSAGES.Failed").subscribe({
                                next: (res) => {
                                    this.notificationsService.error(res);
                                },
                                error: err => {
                                }
                            });
                        },
                        complete: () => {
                            this.showSpinner = false;
                        }
                    });
            }
        });
    }

    gotoBack(){
        this.dialog.closeAll();
    }

    removeFieldsOp = (props)=>obs=>obs.pipe(map(({...o})=>(props.forEach(prop=>delete o[prop]), o)));
}