import { NgModule } from '@angular/core';
import { MaterialImageComponent } from './material-image.component';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
  declarations: [
    MaterialImageComponent
  ],
  imports: [
    CommonModule,
    BrowserModule
  ],
  exports: [
    MaterialImageComponent],
  providers: []
})

export class MaterialImageModule {

}
