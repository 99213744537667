export const locale = {
    lang: 'ds',
    data: {
        'HEADERS': {
            'QuickMaterial': 'Hurtigt materiale',
            'MaterialList': 'Materialeliste',
            'DeleteMaterial': 'Vil du slette "@MaterialName"?'
        },
        'MESSAGES': {
            'Created': 'Oprettet med succes',
            'Updated': 'Opdateret med succes',
            'Deleted': 'Slettet med succes',
            'Failed': 'Operation mislykket, prøv senere',
            'NoBarcodeAttachedToMaterial': 'Ingen stregkode knyttet til noget materiale.',
            'EnterTextColorCode': 'Gå ind Tekst.',
            'MaterialPriseMoreOne': 'Gå ind Udsalgs pris',
            'NoMaterialFound': 'Intet materiale fundet',
            'AddColorCode': 'Farvekode mangler at blive udfyldt',
            'MaterialAlreadyExists': 'Materiale allerede tilføjet.',
            'CanNotAddMaterial': 'Rekvisitionsstatus er ikke i Startet, Fuldført, Klar fakturering, du kan ikke tilføje materiale til denne rekvisition.',
        },
        'BUTTONS': {
            'BACK': 'Tilbage',
            'SAVE': 'Gem',
            'BtnAdd': 'Tilføj / Gem',
            'BtnAddQuickMaterial': 'Hurtigt materiale',
            'ClearAll':'Slet alt'
        },
        "Lable":{
          "Project":"Projekt",
          "Requisition":"Rekvisition"
        },
        'FIELDS': {
            'Status': 'Status',
            'MaterialTextColor': 'Tekst',
            'MaterialNo': 'Materiale nr.',
            'Material': 'Materiale',
            'MaterialText': 'Vare Tekst',
            'DateonCreate': 'Dato',
            'Quantity': 'Antal',
            'Unit': 'Enhed',
            'RetailPrice': 'Udsalgs pris pr. stk',
            'TotalPrice': 'Pris i alt',
            'PersonalPrice': 'Personale pris',
            'PersonalTotalPrice': 'Personal i alt',
            'TotalIncludedVAT': 'Tilbuds Pris incl. moms',
            'Estimate': 'Overslagspris',
            'Price-included': 'Pris incl. moms',
            'ProfitSubProfit': 'Overskud / UnderSkud',
            'ScanBarcode': 'Scan stregkode',
            'ColorCode': 'Farve kode',
            'PriceExclVAT': 'Pris excl. moms',
            'Discount': 'Rabat',
            'Total': 'I alt',
            'DonePercentage': 'Udført %',
            'DiscountLable': 'Rabat / Debitor Rabat',
            'Amount': 'Beløb',
            'Percentage': 'Procent',
            'PurchasePrice': 'Indkøbs pris',
            'RemainsTakenFromBasements': 'Rester fra kældre eller ingen lagertræk',
            'MaterialCreation': 'Materialer Udgør',
            'All': 'Alle',
            "Materialtype": 'Vare type',
            'Vat': 'Excl. Moms',
            'DuplicateEntry': 'Dublet indtastning',
            'SetReminder': 'Indstil påmindelse',
            'Price-excluded': 'Pris excl. Moms',
            'Offer-Price-included': 'Tilbuds pris incl. moms',
            'Offer-Price-excluded': 'Tilbuds pris excl. moms',
            'Price': 'Pris',
        },
        'Headers': {
            'Nomaterialadded': 'Ingen materialer',
            'DeleteMaterial': 'Vil du slette "@MaterialName"?'
        },
        'VALIDATIONS': {
            'TextRequired': 'Tekst krævet',
            'AmountRequired': 'Beløb krævet',
            'PersonalPriceRequired': 'Personlig pris påkrævet',
            'RetailPriceRequired': 'Detailpris påkrævet',
            'QuantityRequired': 'Mængde krævet',
        },
        'TOOTIPORTITLE':{
            'EditMaterial':'Rediger Materiale',
            'DeleteMaterial':'Slet Materiale',
            'RefreshMaterial':'Opdater Materialer'
        },
    }
}