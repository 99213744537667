export const locale = {
    lang: 'en',
    data: {
        'TITLE': {
            'HeaderForOfferNotification': 'Notification on offer is approved',
            'HeaderForLeaveNotification': 'Apply for freedom',
            'HeaderForInquiryNotification': 'Notification of inquiries',
            'HeaderForRemoveNotification': 'Notification on planning remove',
            'HeaderForOfferRejectedNotification': 'Notification on offer is rejected',
            'HeaderForProjectStatusBeforeSevenDaysNotification': 'Notification on project status(before 7 days)',
        },
        'FIELDS': {
            'HoursToSend': 'Hours to send',
            'DaysToSend':  'Send x days before',
            'SMSText': 'SMS text',
            'NotificationText': 'Notification text',
            'EmailText': 'Email text',
            'ExecuteTime':'Execute Time',
            'IsCreateReminder':'Is Create Reminder?',
            'NotesLabel':"Do not delete a prefix '@' character word."
        },
        'VALIDATIONS': {
            'HoursToSend': 'Hours to send is required',
            'DaysToSend': 'Days to send is required',
            'SMSText': 'SMS text is required',
            'NotificationText': 'Notification text is required',
            'EmailText': 'Email text is required',
            'ExecuteTime':'Execution time is required'
        },
        'MESSAGES': {
            'Saved': 'Saved successfully.',
        },
        'Label': {
            'InquirySMSLabel': 'Please do not remove @CustomerName ,@InquiryDate, @InquiryNumber og @Address from SMS text.',
            'PlanningLeaveSMSLabel': 'Please do not remove @CoworkerName and @LeaveDate from SMS text.',
            'OfferSMSLabel': 'Please do not remove @CustomerName, @OfferNumber, @OfferAddress and @OfferTotal from SMS text.',
            'OfferRejectedSMSLabel': 'Please do not remove @CustomerName ,@InquiryDate, @InquiryNumber og @Address from SMS text.',
            'ProjectStatusBeforeSevenDaysSMSLabel': 'Please do not remove @Count from SMS text.',
            'InquiryNotificationLabel': 'Please do not remove @CustomerName ,@InquiryDate, @InquiryNumber and @Address from Notification tekst.',
            'PlanningLeaveNotificationLabel': 'Please do not remove @CoworkerName and @LeaveDate from Notification text.',
            'OfferNotificationLabel': 'Please do not remove @CustomerName, @OfferNumber, @OfferAddress and @OfferTotal from Notification text.',
            'OfferRejectedNotificationLabel': 'Please do not remove @CustomerName, @OfferNumber, @OfferAddress and @OfferTotal from Notification text.',
            'ProjectStatusBeforeSevenDaysNotificationLabel': 'Please do not remove @Count from Notification text.',
            
        },
        'BUTTONS': {
            'Save': 'Save',
        }
    }
};