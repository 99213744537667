import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-co-worker-en',
  templateUrl: './help-co-worker-en.component.html',
  styleUrls: ['help-coworker-en.component.scss']
})
export class HelpCoWorkerEnComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
