export const locale = {
  lang: 'ds',
  data: {
    'FILTERS': {
      'TEXTSEARCH': 'Søg efter navn, e-mail, telefon, adresse',
    },
    'FIELDS': {
      'CustomerName': 'Kundenavn',
      'Not-Found-Customer': 'Ingen kunde fundet',
      'Project':'Projekter'
    },
    'BUTTONS': {
      'StaffPurchaseCash': 'Personale Køb Kontant',
      'StoredPurchase': 'Butiks køb',
      'DailySales':'Daglig salg',
    },
    'TOOTIPORTITLE': {
      'AddCustomer': 'Tilføj kunde',
      'Expand':'Udvide',
    }
  }
};
