export const locale = {
  lang: 'ds',
  data: {
    'MESSAGES': {
      'Add': 'Oprettet',
    },
    'BUTTONS': {
      'Back': 'Gå tilbage',
      'Ok': 'Gem'
    },
    'FIELDS': {
      'to': 'Email sendt til',
      'toName': 'Email sendt til navn',
      'from': 'Email sendt fra',
      'fromName': 'Email sendt fra navn',
      'subject': 'Emne',
      'coworker': 'Medarbejdere',
      'All': 'Alle',
      'AttachpdfFile': 'Vedhæft PDF-fil',
    },
    'VALIDATIONS': {
        'to': 'E-mail påkrævet',
      'toName': 'Gyldigt navn',
        'from': 'E-mail påkrævet',
      'fromName': 'Gyldigt navn',
      'subject': 'Gyldigt emne',
    },
  }
};
