export const locale = {
    lang: 'ds',
    data: {
        'FIELDS': {
            'Coworker': 'Medarbejdere',
            'CoworkerList': 'Medarbejdere liste',
            'All': 'Alle',
        },
        'MESSAGES': {
            'Created': 'Oprettet med succes',
            'Updated': 'Opdateret med succes',
            'Deleted': 'Slettet med succes',
            'Failed': 'Operation mislykket, prøv senere',
            'NORECORD': 'Intet optage fundet',
            'CoworkerExist': 'Medarbejdere eksisterer allerede',
        },
        'BUTTONS': {
            'NEWCOWORKER': 'Ny medarbejder',
        },
        'Headers': {
            'DeleteMessage': 'Er du sikker på, at du vil slette dette'
        }
    }
}