export const locale = {
  lang: 'ds',
  data: {
    'FIELDS': {
      'Name': 'Rum',
      'Width': 'Bredde',
      'Length': 'Længde',
      'CeilingHeight': 'Lofthøjde',
      'ADDNEWROOM': 'Opret 5 rum',
      'ADDNEWSINGLEROOM': 'Opret 1 rum',
      'EnterLength': 'Indtast Laengde',
      'EnterWidth': 'Indtast Bredde',
      'EnterName': 'Indtast Navn',
      'EnterCeilingHeight': 'Indtast Lofthøjde',
      'M2ForWall': 'M2 Vægge',
      'M2ForCeiling': 'M2 Loft',
      'Total': 'I alt',
      'Woodwork': 'Træværk',
      'Qty': 'Tillæg / Fradrag Kr.',
      'QtyM2': 'Tillæg / Fradrag m2',
      'Baseline': 'Grundlinje',
      'Height': 'Højde',
      'Area': 'Arealet',
      'ExtraM2QtyWall': 'Tillæg / Fradrag m2 Vægge',
      'ExtraM2QtyCeiling': 'Tillæg / Fradrag m2 Loft',
      'ExtraM2QtyFloor': 'Tillæg / Fradrag LB Træværk',
      'ExtraM2PriceWall': 'Tillæg / Fradrag Kr. Vægge',
      'ExtraM2PriceCeiling': 'Tillæg / Fradrag Kr. Loft',
      'ExtraM2PriceFloor': 'Tillæg / Fradrag Kr. Træværk',
      'ColorCode': 'Farvekode',
    },
    'MESSAGES': {
      'NoList': 'Ingen Rum Oprettet.',
      'DuplicateRoom': 'Du kan ikke indsætte samme rumnavn.'
    },
    'BUTTONS': {
      'SAVE': 'Gem',
      'BACK': 'Gå tilbage',
      'ChangeHeight': 'Lofthøjde',
      'GetArea': 'Få areal'
    },
    'SupplementDeductionTypeSelect': {
      'value0': 'Alle',
      'value1': 'M2 Vægge',
      'value2': 'M2 Loft',
      'value3': 'Træværk'
    },
    'TOOTIPORTITLE': {
      'AddSingleRoom': 'Tilføje Enkelt Værelse',
      'AddRoom': 'Tilføje Værelser',
      'Edit':'Redigere',
      'Delete': 'Slet',
      'Expand':'Udvide',
      'Collapse':'Bryder Sammen',
    },
  }
};
