export const locale = {
    lang: 'ds',
    data: {
        'TITLE': {
        },
        'FIELDS': {
            'ProjectsCoworkerRate': 'Projekt kollega pris',
            'IndoorOutDoor': 'Ind, ud, Sprøjt, Reklamation *',
            'SORTING_STATUS': 'Status',
        },
        'Message': {

        },
        'FILTERS': {
            'All': 'Alle',
        },
        'Buttons': {
            'Save':'Gem',
        },
        'selectedValueIndoorOutdoorStatus': {
            'value1': 'Indendørs ',
            'value2': 'Udendørs',
            'value3': 'Sprøjtemaling',
            'value4': 'Reklamation',
            'value5': 'Personale køb', //drawing of painter
            'value6': 'Butiks køb'     //Nordsjø logo
          },
          'TOOTIPORTITLE':{
            'ProjectWorkingHoursDetails':'Detaljer Om Projekttimer'
          }
    }
};