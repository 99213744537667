export const locale = {
    lang: 'en',
    data: {
        'TITLE': {
            'HeaderForNewCoWorkerNotification': 'Coworker Joining Notification',
            'HeaderForNewCoWorkerNotification2': 'Coworker Reminder',

            'CoWorkerJoiningSMSTextCoworker':'Please do not remove @CoworkerName, @JoiningCoworkerNames and @JoinDate from SMS text.',
            
            'CoWorkerJoiningSMSTextOtherCoworker':'Please do not remove @CoworkerName, @JoiningCoworkerNames and @JoinDate from SMS text.',
            'CoWorkerJoiningNotificationTextOtherCoworker':'Please do not remove @CoworkerName, @JoiningCoworkerNames and @JoinDate from Notification text.',
        },
        'FIELDS': {
            'IsCreateReminder': 'Is Create Reminder?',
            'DaysToSend': 'Send x days before',
            'SMSText': 'SMS text',
            'NotificationText': 'Notification text',
            'EmailText': 'Email text',
            'ExecuteTime': 'Execute Time',
            //'EmailTemplateForCoworker': 'Email Template For Coworker',
            //'EmailTemplateForOthers': 'Email Template For Other Coworkers',
            'NoteLabel':'Please do not remove @signed words from sms text,email template text and notification text.',


            'TemplatesForCoworker':'Templates For Coworker',
            'TemplatesForOthers':'Templates For Other On Same Day',
            'TemplatesForOthersBeforeXDays':'Templates For Others Before X Days',

            'EmailTemplateForCoworker':'Email Template For Coworker',
            'EmailTemplateForOthers':'Email Template For Other On Same Day',
            'EmailTemplateForOthersBeforeXDays':'Email Template For Others Before X Days',

            // 'NoteLabelForCoworker':'Please do not remove @CoworkerName,@JoiningCoworkerNames and @JoinDate from SMS,Notification and Email template text.',
            // 'NoteLabelForOthers':'Please do not remove @CoworkerName,@JoiningCoworkerNames and @JoinDate from SMS,Notification and Email template text.',
            //'NoteLabelForOthersBeforeXDays':'Please do not remove @BirthDate and @CoworkerNames from SMS,Notification and Email template text.'
        },
        'VALIDATIONS': {
            'DaysToSend': 'Days to send is required',
            'SMSText': 'SMS text is required',
            'NotificationText': 'Notification text is required',
            'EmailText': 'Email text is required',
            'ExecuteTime': 'Execution time is required'
        },
        'MESSAGES': {
            'Saved': 'Saved successfully.',
        },
        'BUTTONS': {
            'Save': 'Save',
        }
    }
};