export const locale = {
    lang: 'ds',
    data: {
      'BUTTONS': {
        'Upload': 'Upload',
        'Cancel': 'Tilbage',
        "Update":"Opdatere",
        "Reset":"Nulstil / annullere",
        "SampleFileDownload":"Prøve fil download",
        'Back': 'Tilbage',
      },
      'Header':{
        'Status': 'Status',
        'StartDate': 'Startdato',
        'EndDate': 'Slutdato',
        'VisitDate': 'besøgdato',
        'ColorCode': 'Farvekode',
        'Address': 'Adresse',
        'Postnr': 'Postnr',
        'By': 'By',
        'Telefon': 'Telefon',
        'EmailFaktura': 'EmailFaktura',
        'Email': 'Email',
      },
      'FIELDS': {
        'SelectSupplier': 'Vælg Leverandør',
        'UploadPriceList': 'Upload prisliste',
        'ChooseFile': 'Vælg fil',
        'Selectcustomer': 'Vælg kunde',
      },
      'LABELS': {
        'TITLE': 'Upload af projektliste (.xls,.xlsx)',
        'NoFileSelected': 'Ingen fil valgt',
        'FileSelected': 'Fil valgt',        
      },
      'MESSAGES': {
        'Created': 'Oprettet succesfuldt.',
        'Updated': 'Opdateret succesfuldt.',
        'Failed': 'mislykkedes',
        'Deleted': 'Slettet med succes',
        'Notes':'**Bemærk - Inaktive eller slettede materialer er fremhævet i rød farve'
      },
    }
  }; 
  