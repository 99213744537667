import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'contentToAnchorText'})
export class ContentToAnchorTextPipe implements PipeTransform
{
    transform(value: string, args: any[] = [])
    {
        var urlRegex =/(\b(https?|http|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
        return value.replace(urlRegex, function(url) {
            return '<a class="link" href="' + url + '" target="_blank">' + url + '</a>';
        });
    }
}
