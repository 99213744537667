export const locale = {
    lang: 'ds',
    data: {
        'FILTERS': {
            'TEXTSEARCH': 'Søg efter navn, e-mail, telefon, adresse',
        },
        'FIELDS': {
            'Not-Found-Material': 'Ingen materiale fundet',
            'Number': 'Sr.',
            'MaterialNo': 'Nummer',
            'Material': 'Materiale',
            'MaterialText': 'Vare Tekst',
            'DateonCreate': 'Dato on Createt',
            'Quantity': 'Antal',
            'Unit': 'Enhed',
            'RetailPrice': 'Udsalgs pris',
            'TotalPrice': 'Pris i alt',
            'PersonalPrice': 'Personal Pris',
            'PurchaseQuantity': 'Indkøbsmængde',
            'ScanBarcode': 'Scan stregkode',
            'ItemNo': 'Vare Nr',
            'QTY': 'Antal',
            'Total': 'I alt',
            'PriceExclVAT': 'Pris excl. moms',
            'PriceInclVAT': 'Pris incl. moms',
            'PersonalTotalPrice': 'Personal i alt',
            'ColorCode': 'Farve kode',
            'Discount': 'Rabat / Debitor Rabat',
            'Amount': 'Rabat excl. moms',
            'Percentage': 'Procent',
            'StaffPurchase': 'Personale køb',
        },
        'Headers': {
            'MaterialList': 'Materialeliste',
            'Nomaterialadded': 'Ingen materialer',
            'ScanMaterial': 'Scan materiale',
            'AddMaterial': 'Tilføj materiale',
            'AddedMaterialList': 'Tilføjet materialeliste',
        },
        'BUTTONS': {
            'StaffPurchase': 'Personale køb',
            'StoredPurchase': 'Butiks køb',
            'ScanMaterial': 'Scan materiale',
            'AddManualMaterial': 'Tilføj manuelt materiale',
            'AddMaterial': 'Tilføj materiale / Gem',
            'AddScanMaterial': 'Tilføj scanne materiale',
            'Add': 'Tilføj',
            'Back': 'Tilbage',
            'Finish': 'Afslut',
        }
    }
};

