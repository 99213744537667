export const locale = {
    lang: 'ds',
    data: {
        'CUSTOMER': {
            'TITLE': 'kunder',
            'DESC': 'Hej kunder!'
        },
        'TITLE': {
            'InquiriePagination': 'Antal forespørgser pr. side',
        },
        'BUTTONS': {
            'NEWCUSTOMER': 'Ny kunde',
            'NEWINQUIRY': 'Ny forespørgsel',
            'BACK': 'Tilbage'
        },
        'MESSAGES': {
            'NORECORD': 'Ingen forespørgsel fundet',
        },
        'FILTERS': {
            'TEXTSEARCH': 'Søg efter navn',
            'NUMBERSEARCH': 'Kunde nr. Søg',
            'CustomerNumber': 'Kunde nummer',
            'CustomerName': 'Kundenavn',
            'Email': 'E-mail',
            'PhoneNumber': 'Telefon nummer',
            'Address': 'Adresse',
            'City': 'By',
            'ZipCode': 'Postnummer',
            'Position': 'Position',
            'Text': 'Tekst',
            'Action': 'Handling',
            'SORTING': 'Sorter efter',
            'Active': 'Inkluder alle inaktive',
            'BADGE': 'Inaktive',
            'Deleted': 'Vis slettet',
            'Note': 'Bemærk',
        },
        'SORTBY':
            [
                //{
                //  Text: '     ',
                //  Value: 'ID DESC'
                //},
                {
                    Text: 'Navn (A-Z)',
                    Value: 'Text'
                },
                {
                    Text: 'Navn (Z-A)',
                    Value: 'Text DESC'
                },
                {
                    Text: 'Nummer (0-9)',
                    Value: 'Number'
                },
                {
                    Text: 'Nummer (9-0)',
                    Value: 'Number DESC'
                }
            ],
        'FIELDS': {
            'SORTING_STATUS': 'Status',
        },
        // 'selectedValueStatus': {
        //     'value0': 'Registered',
        //     'value1': 'Process',
        //     'value2': 'Planlagt besøg',
        //     'value3': 'Klar til opstart',
        //     'value4': 'Tilbud sendt',
        //     'value5': 'Tilbud godkendt',
        //     'value6': 'Planlagt',
        //     'value7': 'Tilbud afvist',
        //     'value8': 'Startet',
        //     'value9': 'Udført',
        //     'value10': 'Faktureret',
        //     'value11': 'Afventer',
        //     'value12': 'Klar fakturering',
        // }
    }
};
