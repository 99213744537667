export const locale = {
    lang: 'ds',
    data: {
        'TITLE': {
            'CoworkerReviewQuestion': 'Spørgsmål til medarbejder',
            'ReviewQuestionsPagination': 'Antal spørgsmål pr. side',
            'AskHelpForCoworker': 'Tilføj tid, bede om hjælp til at udfylde hjælpedata på kollega',
        },
        'FIELDS': {
            'IsSendEmail': 'Er Send e-mail ?',
            'TabList': 'Fanelisteindstilling',
            "Title":'Titel',
            "StartDate":'Start dato',
            "EndDate":'Slut dato',
            'SRNO': 'Sr nr.',
        },
        'Message': {
            'Created': 'Oprettet med succes.',
            'Updated': 'Opdateret med succes.',
            'Deleted': 'Slettet med succes.',
            'Failed': 'Noget gik galt !',
            'NoRecords': 'Ingen registreringer fundet !'
        },
        'FILTERS': {
        },
        'moduleTypeList': {
        },
        'BUTTONS': {
            'Save':'Gem',
            'Add': 'Tilføj',
            'SetValue': 'Indstil værdi',
        },
        'TOOTIPORTITLE':{
            'Edit':'Redigere',
            'Delete':'Slet',
        }
    }
};