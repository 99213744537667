import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';

import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { TranslateService } from '@ngx-translate/core';
import { TitleService } from 'app/core/services/title.service';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { InquiryService } from 'app/main/pages/inquiry/inquiry.service';

@Component({
    selector: 'vibe-Inquiry-Log-Detail',
    templateUrl: './inquiry-log-detail.component.html',
    styleUrls: ['./inquiry-log-detail.component.scss']
})
export class InquiryLogDetailComponent implements OnInit {
    showSpinner = false;
    inquiryLogsDetail: any = [];
    oldItem: any = {};
    newItem: any = {};

    currentId: any;
    previousId: any;
    nextId: any;
    tempLogId: any;
    logData: any[];
    //Initiators
    constructor(private translationLoader: FuseTranslationLoaderService,
        private _router: Router,
        private titleService: TitleService,
        private translate: TranslateService,
        private inquiryService: InquiryService,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
        this.getInquiryLogsDetails(this.data.id)
        this.tempLogId = this.data.id;
        if(this.data && this.data.logData)
            this.logData = this.data.logData;
    }
    getInquiryLogsDetails(Id) {
        this.showSpinner = true;
        this.inquiryService.GetInquiryLogDetails<ResponseBase>(Id)
            .subscribe({
                next: (response: ResponseBase) => {
                    if (response != null && response.result) {
                        this.inquiryLogsDetail = response.result;
                        this.oldItem = response.result.length > 1 ? response.result[1] : [];
                        this.newItem = response.result[0];
                        this.getSetNextPreviousId();
                    }
                    else {
                        this.inquiryLogsDetail = [];
                    }
                },
                error: err => {
                    this.showSpinner = false;
                    this.inquiryLogsDetail = [];
                },
                complete: () => { }
            });
    }
    getStatus(status) {
        var statusField = "";
        if (status == 1)
            statusField = "STATUS.Created"
        if (status == 2)
            statusField = "STATUS.Updated"
        if (status == 3)
            statusField = "STATUS.Deleted"
        if (status == 4)
            statusField = "STATUS.FileUploaded"
        if (status == 5)
            statusField = "STATUS.FileDeleted"
        return statusField;
    }
    getIndoorOutdoorStatus(status) {
        var statusText = "";
        var statusArr = status ? status.split(',') : [];
        if (statusArr.length > 0) {
            for (var i = 0; i < statusArr.length; i++) {
                var appendcomma = "";
                if (i != statusArr.length - 1)
                    appendcomma = ","
                if (statusArr[i] == "1")
                    this.translate.get('selectedValueIndoorOutdoorStatus.value1').subscribe(value => { statusText += value + appendcomma; });
                if (statusArr[i] == "2")
                    this.translate.get('selectedValueIndoorOutdoorStatus.value2').subscribe(value => { statusText += value + appendcomma; });
                if (statusArr[i] == "3")
                    this.translate.get('selectedValueIndoorOutdoorStatus.value3').subscribe(value => { statusText += value + appendcomma; });
                if (statusArr[i] == "4")
                    this.translate.get('selectedValueIndoorOutdoorStatus.value4').subscribe(value => { statusText += value + appendcomma; });
                if (statusArr[i] == "5")
                    this.translate.get('selectedValueIndoorOutdoorStatus.value5').subscribe(value => { statusText += value + appendcomma; });
                if (statusArr[i] == "6")
                    this.translate.get('selectedValueIndoorOutdoorStatus.value6').subscribe(value => { statusText += value + appendcomma; });
            }
            return statusText;
        }
    }

    getIsActive(item) {
        var statusField = '';
        if (item.isActive == true) 
            statusField = "IsActive.value1"
        else if(item.isActive == false) 
            statusField = "IsActive.value0"
        else 
            statusField = "-"
        return statusField;
    }

    gotoInquiry(id) {
        if (this.logData) {
            this.tempLogId = id;
            this.currentId = this.tempLogId;
            this.getSetNextPreviousId();
            this.inquiryLogsDetail = [];
            this.getInquiryLogsDetails(this.currentId)
        }
    }

    getSetNextPreviousId() {
        this.currentId = this.tempLogId;
        var itemIndex = this.logData.map(function (o) { return o.id; }).indexOf(this.currentId);
        if (itemIndex > 0)
            this.previousId = this.logData[itemIndex - 1].id;
        else
            this.previousId = null;

        if (itemIndex != (this.logData.length - 1))
            this.nextId = this.logData[itemIndex + 1].id;
        else
            this.nextId = null;
    }

}
