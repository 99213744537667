export const locale = {
    lang: 'ds',
    data: {
        'FIELDS': {
            'startDate': 'Start dato ',
            'visit': 'Besøg',
            'CoWorkers': 'Co-Workers',
            'All': 'Alle',
            'Not-Found-Worker': 'Medarbejder ikke fundet',
            'Co-workerlist': 'Medarbejderliste',
            'DepartmentPhone': 'Telefon',
        },
        'BUTTONS': {
            'BACK': 'Tilbage',
            'SAVE': 'Gem',
            'UNDO': 'Fortryd',
            'Delete': 'Slette',
        },
        'Lable': {
            'Name': 'Navn',
        }
    }
};

