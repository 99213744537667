export const locale = {
    lang: 'en',
    data: {
        'Headers': {
            'QuestionList': 'Ask customer the reason of rejection:'
        },
        'QuestionTypeCategory': {
            'value1': 'Textbox',
            'value2': 'Multi Choice',
            'value3': 'Single Choice',
            'value4': 'Rating'
        },
        'BUTTONS': {
            'NEWPROJECTOFFER': 'New Project Offer',
            'AddQuestion': 'Add Question',
            'SendReminder': 'Send Reminder',
            'Save': 'Save',
            'Cancel': 'Cancel',
            'Text': 'Text',
            'Add':'Add',
        },
        'FIELDS': {
            'Question': 'Question',
            'SrNo': 'Sr No.',
            'Type': 'Type',
            'Answer': 'Answer',
            'WithTextbox': 'With Textbox',
            'ReminderMsg': 'Reminder Message'
        },
        'VALIDATIONS': {
            'QuestionRequired': 'Question required',
            'CoWorkerRequired': 'CoWorker Required',
            'Not-Found-Worker': 'Co-worker not found',
            'ReminderMsgRequired': 'Reminder Message required',
        },
        'PlaceHolder': {
            'SelectCoWorker': 'Select Co-Worker',
            'Search-worker': 'Search Co-worker',
        },
        'MESSAGES': {
            'NoQuestionAnswerFound': 'No Question Found.',
            'AddTypeList': 'Add at least one value to the type list.',
            'AddTypeListItem': 'Add value to the type list.'
        },
        'ToolTip': {
            'SendReminderBySMS': 'Send reminder by Sms',
            'SendReminderByEmail': 'Send reminder by email',
            'SendReminderByNotification': 'Send reminder by notification',
        }
    }
}