import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatOption, MatDialog, MatSelect } from '@angular/material';
import { Router } from '@angular/router';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { RequestStatusProject } from 'app/main/model/RequestBase';
import { CoWorkersService } from 'app/main/pages/coWorker/coWorkers.service';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { WorkhoursService } from 'app/main/pages/workHour/workhours.service';
import { AuthService } from 'app/core/services/auth.service';
import { ShiftType } from 'app/main/model/ProjectStatus';
import { WorkHour, LeaveApplyOnPlanning, CoworkerWithScheduleDetails, WorkhoursDay, WorkingHourOtherInfo, CoworkerScheduleDayList } from 'app/main/model/WorkHour';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { addDays } from 'date-fns';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';
import { WeekendOption } from 'app/constant/common.constant';
import { CommonService } from 'app/core/services/common.service';
import { FormControl } from '@angular/forms';
import { ProjectService } from 'app/main/pages/project/project.service';


@Component({
    selector: 'calender-bulk-project-plan-popup',
    templateUrl: './bulk-project-plan-popup.component.html',
    styleUrls: ['./bulk-project-plan-popup.component.scss']
})
export class BulkProjectPlanPopupComponent implements OnInit {
    @ViewChild('select', { static: false }) select: MatSelect;
    startWeek: number = 1;
    projectInfo: any = {};
    coWorkers: any = [];
    projectCoWorkers: any = [];
    coWorkersFullData: any = [];
    showSpinner = false;
    createEventObj: LeaveApplyOnPlanning = new LeaveApplyOnPlanning;
    shiftType = ShiftType;
    // selectStatus = WorkHourStatus;
    // selectLeaveType = WorkHoursApproveLeaveType;
    projectList: any = [];
    projectFullList: any = [];
    workHour = new WorkHour();
    currentselectedCoworkerObj: any = [];
    hours: any;
    mins: any;
    isAddBreakTime: boolean = true;
    coWorkerList: any = [];
    isDisableWorkType = false;
    weekNumbers: any = [];
    isDisplayErrorMessage: boolean = false;
    isComingFromEditMode: boolean = false;
    workHourNotAddedOnCoworker: string = "";
    workHourId: string = "";
    //roleName: string;
    isFeatureDate: boolean = false;
    isPastDate: boolean = false;
    CoWorkerName: string = "";
    holidayList: any;
    tempEnddate: any;
    isDelete: boolean = false;
    //paymentOption = PaymentOptionType;
    weekendOption = WeekendOption;
    selectedCoWorkerList: CoworkerWithScheduleDetails[] = new Array<CoworkerWithScheduleDetails>();
    tempCoworkerListForLeave: any = [];
    coworkerLeaveList: any = [];
    scheduleDay: any = {};
    ScheduleDays: any = [];
    coworkerObj: any;
    projectWithWorkHoursList: any;
    projectChipListControl = new FormControl([]);
    requestProject = new RequestStatusProject();
    projects: any[];

    @ViewChild('allSelected', { static: false }) private allSelected: MatOption;
    roleClaims: any;
    hasPermision: boolean = false;
    hasLeaveApprovePermision: boolean = false;
    selectAllChecked: boolean = false;
    SearchVal?: string = "";
    filteredProjects: any[] = [];    

    //Initiators
    constructor(private translationLoader: FuseTranslationLoaderService,
        private _router: Router,
        private translate: TranslateService,
        private projectservice: ProjectService,
        private notificationsService: NotificationsService,
        private workhourservice: WorkhoursService,
        private coWorkersService: CoWorkersService,
        private authService: AuthService,
        private commonService: CommonService,
        private datePipe: DatePipe,
        private dialog: MatDialog,
        public dialogRef: MatDialogRef<BulkProjectPlanPopupComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.translationLoader.loadTranslations(english, danish);
        this.roleClaims = this.authService.getRoleClaimsList();
    }

    ngOnInit() {
        this.authService.hasClaim(this.roleClaims.Planning_Leave_Approve).subscribe(hasClaim => {
            if (hasClaim)
                this.hasLeaveApprovePermision = hasClaim
            else
                this.hasLeaveApprovePermision = false;
        });
        this.authService.hasClaim(this.roleClaims.Planning_Modify).subscribe(hasClaim => {
            if (hasClaim)
                this.hasPermision = hasClaim
            else
                this.hasPermision = false;
        });
        this.requestProject = this.projectservice.getRequestParameterForCalender();
        this.requestProject.isLift = false;
        this.requestProject.isRiseman = false;
        this.requestProject.CoworkerId = "";
        this.requestProject.IsActive = true;
        this.requestProject.IsDeleted = false;
        this.requestProject.Address = "";
        this.GetProjects(this.requestProject);
        this.isComingFromEditMode = this.data.isComingFromEditMode;
        this.projectInfo = this.data.projectInfo;
        this.projectWithWorkHoursList = this.data.projectWithWorkHoursList ? this.data.projectWithWorkHoursList : [];
        this.holidayList = JSON.parse(localStorage.getItem("HolidayList"));
        this.createEventObj.weekendId = 1;
        if (this.projectInfo.otherInfo) {
            var customDateObj = JSON.parse(this.projectInfo.otherInfo);
            this.createEventObj.startDate = customDateObj.StartDate;
            this.createEventObj.endDate = customDateObj.EndDate;
        }
        else {
            this.createEventObj.startDate = (this.projectInfo.startDate ? this.projectInfo.startDate : new Date());
        }
        if (new Date().setHours(0, 0, 0, 0) == new Date(this.createEventObj.startDate).setHours(0, 0, 0, 0)) {
            this.isPastDate = false;
        }
        else {
            this.isPastDate = moment(this.createEventObj.startDate, "YYYY-MM-DD").isBefore();
        }
        this.createEventObj.shiftType = this.projectInfo.shiftType;
        this.workHourId = this.projectInfo.workHourId;

        this.calculateEndDate();
        this.currentselectedCoworkerObj = [this.authService.getCoWorkerId()];
        this.createEventObj.coworkerId = this.authService.getCoWorkerId();
        this.coWorkers = this.data.coworkerList;
        this.coWorkersFullData = this.data.coworkerList;
        this.createEventObj.startWeekNumber = moment(this.createEventObj.startDate).week().toString();
        this.hours = [{ value: '00' }, { value: '01' }, { value: '02' }, { value: '03' }, { value: '04' }, { value: '05' }, { value: '06' }, { value: '07' }, { value: '08' },
        { value: '09' }, { value: '10' }, { value: '11' }, { value: '12' }, { value: '13' }, { value: '14' }, { value: '15' }, { value: '16' }, { value: '17' },
        { value: '18' }, { value: '19' }, { value: '20' }, { value: '21' }, { value: '22' }, { value: '23' }];

        this.mins = [{ value: '00' }, { value: '15' }, { value: '30' }, { value: '45' }]
        //this.mins = [{ key: '00', value: '00' }, { key: '25', value: '15' }, { key: '50', value: '30' }, { key: '75', value: '45' }]
        this.addWeeksNumber();
        var calandarDoubleClickData = JSON.parse(localStorage.getItem("doubleClickCalanderDetail"));
        if (calandarDoubleClickData) {
            this.currentselectedCoworkerObj = [calandarDoubleClickData.coWorkerId];
            this.createEventObj.coworkerId = this.currentselectedCoworkerObj.join().toString();
        }
        this.createEventObj.workerID = this.currentselectedCoworkerObj
    }

    DropDownSearchCoWorker(value) {
        var model = new RequestStatusProject();
        model.Index = 1;
        model.Name = value;
        this.coWorkers = this.coWorkersFullData.filter(it => {
            return it.name.toLowerCase().includes(model.Name.toLowerCase());
        });
    }
    getCoworkerOnClear(value) {
        if (!value) {
            this.DropDownSearchCoWorker(value)
        }
    }

    DropDownCoWorkerSelect(coworker) {
        var alreadySelectedCoworker = this.selectedCoWorkerList.find(o => o.id == coworker.id);
        if (alreadySelectedCoworker)
            this.selectedCoWorkerList = this.selectedCoWorkerList.filter(o => o.id != coworker.id);
        else
            var findCoworker = this.coWorkers.find(o => o.id == coworker.id);
        if (findCoworker)
            this.addCoworker(findCoworker);

        this.createEventObj.workerID = this.currentselectedCoworkerObj //.join().toString();
        this.DropDownSearchCoWorker('');
        if (this.currentselectedCoworkerObj.length == 1) {
            var coworker = this.coWorkers.find(o => o.id == this.currentselectedCoworkerObj[0]);
            this.createEventObj.leaveStatus = coworker.isLeaveApplyAsApproved == true ? 2 : 1;
        }
        else
            this.createEventObj.leaveStatus = 1;
    }

    DropDownSearchProject(value) {
        var model = new RequestStatusProject();
        model.Index = 1;
        model.Name = value;
        this.coWorkers = this.projectFullList.filter(it => {
            return it.name.toLowerCase().includes(model.Name.toLowerCase());
        });
    }

    onFocusIn(event) {
        event.model.show();
    }

    saveTimer() {
        if (this.workHour.id)
            this.updateWorkhourAPICall(this.workHour);
        else
            this.addWorkhourAPICall();
    }

    deleteTimer() {
        var message = "";
        var message1 = "";
        var message2 = "";
        var message3 = "";
        var startDate = moment(this.createEventObj.startDate).format('DD.MM.YYYY');
        var endDate = moment(this.createEventObj.endDate).format('DD.MM.YYYY');

        this.translate.get("MESSAGES.DeleteNonWorkingHoursOne").subscribe({
            next: (res) => {
                message1 = res;  //+ 'den' + '' + ' ' + startDate + ' ' + 'til den' + ' ' + endDate;
            },
            error: err => {
            }
        });
        this.translate.get("MESSAGES.DeleteNonWorkingHoursTwo").subscribe({
            next: (res) => {
                message2 = res;
            },
            error: err => {
            }
        });
        this.translate.get("MESSAGES.DeleteNonWorkingHoursThree").subscribe({
            next: (res) => {
                message3 = res;
            },
            error: err => {
            }
        });
        message = message1 + ' ' + message2 + ' ' + startDate + ' ' + message3 + ' ' + endDate;

        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            width: '500px',
            data: { Header: message }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.isDelete = result;
                this.dialogRef.close({
                    updateSchedule: false,
                    deleteSchedule: true,
                    goToProject: false,
                    projectId: this.projectInfo.sagId,
                    dataYear: moment(this.createEventObj.startDate).year().toString(),
                    workHourId: this.workHourId,
                });
            }
        });
    }

    goToProject() {
        this.dialogRef.close({
            updateSchedule: false,
            deleteSchedule: false,
            goToProject: true,
            projectId: this.projectInfo.sagId,
            workHourId: this.workHourId,
        });
    }

    addWorkhourAPICall() {
        var isAlreadyApplyWorkHours = false;
        var coworkerName = "";
        var workhoursdate;
        const inputArray = this.projectChipListControl.value.map(x => x.sagNumber)
        this.currentselectedCoworkerObj.filter(c => {
            this.ScheduleDays.forEach(e => {
                if (e.isChecked) {
                    this.projectWithWorkHoursList.filter(p => {
                        if (p.isWorking && p.workerId == c && ((moment(p.date).format("YYYY-MM-DD") == e.date) && inputArray.includes(p.sagNummer))) {
                            isAlreadyApplyWorkHours = true;
                            workhoursdate = e.date;
                            coworkerName = this.coWorkers.find(o => o.id == c).name
                            return;
                        }
                    });
                }
            });
        })
        if (isAlreadyApplyWorkHours) {
            this.translate.get("MESSAGES.CanNotAddWorkHour").subscribe({
                next: (res) => {
                    var msg = res.replace("@Coworker", coworkerName).replace("@date", moment(workhoursdate, "YYYY.MM.DD").format("DD.MM.YYYY"));
                    this.notificationsService.error(msg);
                },
                error: err => {
                }
            });
        }
        else {
            this.showSpinner = true;
            this.createEventObj.startTime = this.ScheduleDays[0].from;
            this.createEventObj.endTime = this.ScheduleDays[0].to;
            this.createEventObj.pauseTime = this.ScheduleDays[0].pauseTimer;
            this.createEventObj.totalTime = this.ScheduleDays[0].tid;
            this.createEventObj.totalWorkingTime = Number(this.ScheduleDays[0].hours);
            this.ScheduleDays.forEach(e => {
                if (e.isChecked) {
                    this.createEventObj.leaveDates.push(e.date)
                }
            });
            var workHoursListForCreate = [];
            if (this.projectChipListControl.value && this.projectChipListControl.value.length > 0) {
                this.projectChipListControl.value.filter(proj => {
                    var workingHourOtherInfo = new WorkingHourOtherInfo();
                    workingHourOtherInfo.StartDate = moment(this.createEventObj.startDate).format("YYYY-MM-DDT00:00:00");
                    workingHourOtherInfo.EndDate = moment(this.createEventObj.endDate).format("YYYY-MM-DDT00:00:00");
                    workingHourOtherInfo.IsNotifyToCustomer = false;
                    workingHourOtherInfo.SagID = proj.sagId;
                    var tempDates = this.ScheduleDays.filter(y => y.isChecked == true).map(x => x.date);
                    var PlanningDates = tempDates;
                    workingHourOtherInfo.PlanningDates = PlanningDates.join(',');
                    workingHourOtherInfo.WeekendId = this.createEventObj.weekendId;
                    for (var i = 0; i < this.currentselectedCoworkerObj.length; i++) {
                        var ScheduleDay = new WorkHour();
                        //ScheduleDay.date = this.workHour.date;
                        ScheduleDay.workerId = this.currentselectedCoworkerObj[i];
                        ScheduleDay.projectId = proj.sagId;
                        ScheduleDay.isWorking = true;
                        ScheduleDay.inquiryId = null;
                        ScheduleDay.leaveStatus = null,//this.createEventObj.leaveStatus;
                            workingHourOtherInfo.BunchId = this.commonService.GenerateRandomUniqueId();
                        ScheduleDay.otherInfo = JSON.stringify(workingHourOtherInfo);
                        ScheduleDay.status = null,//this.createEventObj.leaveType;
                            ScheduleDay.paymentOption = this.createEventObj.paymentOption;
                        ScheduleDay.text = this.createEventObj.text;
                        this.ScheduleDays.forEach(e => {
                            if (e.isChecked) {
                                var ScheduleDay = new CoworkerScheduleDayList();
                                ScheduleDay.date = e.date;
                                ScheduleDay.workerId = this.currentselectedCoworkerObj[i];
                                ScheduleDay.name = "",//this.currentselectedCoworkerObj[i].name;
                                    ScheduleDay.from = e.from;
                                ScheduleDay.to = e.to;
                                ScheduleDay.tid = e.tid;
                                ScheduleDay.pauseTimer = e.pauseTimer;
                                ScheduleDay.hours = e.hours;
                                ScheduleDay.shiftType = e.shiftType;
                                ScheduleDay.isChecked = e.isChecked;
                                ScheduleDay.projectId = proj.sagId;
                                ScheduleDay.requisitionId = "";
                                ScheduleDay.sagOpgaveId = "";
                                ScheduleDay.isWorking = true;
                                ScheduleDay.inquiryId = null;
                                ScheduleDay.leaveStatus = 0;
                                ScheduleDay.projectNumber = proj.sagNumber.toString();
                                ScheduleDay.otherInfo = JSON.stringify(workingHourOtherInfo);
                                ScheduleDay.startDate = moment(this.createEventObj.startDate).format("YYYY-MM-DD");
                                ScheduleDay.endDate = moment(this.createEventObj.endDate).format("YYYY-MM-DD");
                                ScheduleDay.customerId = proj.customerId;
                                ScheduleDay.sendType = null,//this.workHour.sendType;
                                    ScheduleDay.message = null,//this.workHour.message;
                                    ScheduleDay.newAddedCoworkerIds = this.workHour.newAddedCoworkerIds;
                                ScheduleDay.projectBasedPlanning = true
                                if (ScheduleDay.isChecked)
                                    workHoursListForCreate.push(ScheduleDay);
                                // ScheduleDay.from = e.from;
                                // ScheduleDay.to = e.to;
                                // ScheduleDay.tid = e.tid;
                                // ScheduleDay.pauseTimer = e.pauseTimer;
                                // ScheduleDay.hours = e.hours;
                                // ScheduleDay.shiftType = e.shiftType;
                                // ScheduleDay.date = e.date;
                                // var workobjWithoutRef = Object.assign({}, ScheduleDay);
                                // workHoursListForCreate.push(workobjWithoutRef);
                            }
                        });
                    }
                })

            }

            this.workhourservice.AddNonWorkHourScheduleWithDateRangeforplanning<ResponseBase>(workHoursListForCreate)
                .subscribe({
                    next: (response: ResponseBase) => {
                        this.showSpinner = false;
                        this.translate.get("MESSAGES.Add").subscribe({
                            next: (res) => {
                                this.notificationsService.success(res);
                                this.dialogRef.close({
                                    updateSchedule: true,
                                    deleteSchedule: false,
                                    goToProject: false,
                                    projectId: this.projectInfo.sagId,
                                    workHourId: this.workHourId,
                                    dataYear: moment(this.createEventObj.startDate).year().toString(),
                                    weekNumber: moment(this.createEventObj.startDate).week().toString(),
                                    response: response.result ? response.result : null,
                                });
                            },
                            error: err => {
                            }
                        });
                    },
                    error: err => {
                        this.showSpinner = false;
                        //this.workHourNotAddedOnCoworker = err.error.error.result.coworkerName;
                        this.isDisplayErrorMessage = true;
                        this.translate.get("MESSAGES.Some_Coworker_Add_Error").subscribe({
                            next: (res) => {
                                this.notificationsService.warn(res);
                            },
                            error: err => {
                            }
                        });
                    },
                    complete: () => {
                    }
                });
        }
    }

    updateWorkhourAPICall(workHour) {
        this.showSpinner = true;
        this.workhourservice.UpdateWithDateRange<ResponseBase>(workHour)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.Add").subscribe({
                        next: (res) => {
                            this.notificationsService.success(res);
                            this.dialogRef.close({
                                updateSchedule: true,
                                deleteSchedule: false,
                                goToProject: false,
                                projectId: this.projectInfo.sagId,
                                workHourId: this.workHourId,
                                dataYear: moment(this.createEventObj.startDate).year().toString(),
                                weekNumber: moment(this.createEventObj.startDate).week().toString(),
                            });
                        },
                        error: err => {
                        }
                    });
                },
                error: err => {
                    this.showSpinner = false;
                    this.workHourNotAddedOnCoworker = err.error.error.result.coworkerName;
                    this.isDisplayErrorMessage = true;
                    this.translate.get("MESSAGES.Some_Coworker_Add_Error").subscribe({
                        next: (res) => {
                            this.notificationsService.warn(res);
                        },
                        error: err => {
                        }
                    });
                },
                complete: () => {
                }
            });
    }

    calculateEndDate() {
        if (!this.isComingFromEditMode) {
            let tempStartDate: Date = new Date(moment(this.createEventObj.startDate).week(this.createEventObj.startWeekNumber || 1).startOf("week").format("YYYY-MM-DD"));
            const endDate: Date = addDays(new Date(tempStartDate.getTime()), 4);
            this.createEventObj.endDate = endDate;
            var days = moment(this.createEventObj.startDate).day();
            if (days == 5)
                this.createEventObj.shiftType = 2;
            else
                this.createEventObj.shiftType = 3;
            this.calEndDate();
            this.reCoworkerScheduleDays();
        }
    }

    onChange(args) {
        if (args.value) {
            this.createEventObj.startDate = args.value;
            if (new Date().setHours(0, 0, 0, 0) == new Date(this.createEventObj.startDate).setHours(0, 0, 0, 0))
                this.isPastDate = false;
            else
                this.isPastDate = moment(this.createEventObj.startDate, "YYYY-MM-DD").isBefore();
            this.createEventObj.startWeekNumber = moment(this.createEventObj.startDate).week().toString();
            if (!this.isComingFromEditMode) {
                let tempStartDate: Date = new Date(moment(this.createEventObj.startDate).week(this.createEventObj.startWeekNumber || 1).startOf("week").format("YYYY-MM-DD"));
                const endDate: Date = addDays(new Date(tempStartDate.getTime()), 6);
                this.createEventObj.endDate = endDate;
            }
            this.calculateEndDate();
        }
    }

    onChangeEndDate(args) {
        this.createEventObj.endDate = args.value;
        //this.tempEnddate = args.value;
        this.calEndDate();
    }

    calEndDate() {
        this.workHour.nonWorkingDayList = [];
        this.workHour.dayList = [];
        var endDate = new Date(new Date(this.createEventObj.endDate).getFullYear(),
            new Date(this.createEventObj.endDate).getMonth(), new Date(this.createEventObj.endDate).getDate(), 0, 0, 0, 0);

        var startDateCal = new Date(new Date(this.createEventObj.startDate).getFullYear(),
            new Date(this.createEventObj.startDate).getMonth(), new Date(this.createEventObj.startDate).getDate(), 0, 0, 0, 0);

        for (let day = startDateCal; day <= endDate; day.setDate(day.getDate() + 1)) {
            var tempDate = moment(day).format("DD.MM.YYYY");
            var getHoliday = this.holidayList.find(o => moment(o.date).format("DD.MM.YYYY") == tempDate);
            if (!getHoliday || getHoliday.length == 0 || this.createEventObj.leaveType == 8) {
                this.workHour.nonWorkingDayList.push(moment(day).format("YYYY-MM-DD"));
                this.pushDayList(moment(day).format("YYYY-MM-DD"), true)
            }
        }
        this.createEventObj.endDate = endDate;
    }

    getMinute(value) {
        if (value == "00")
            return "00";
        if (value == "15")
            return "25";
        if (value == "30")
            return "50";
        if (value == "45")
            return "75";
    }

    CheckEndHour(checkMinute) {
        this.workHour.fromEndHour = (this.workHour.fromEndHour < this.workHour.fromStartHour) == true ? (this.workHour.fromEndHour = this.workHour.fromStartHour) : this.workHour.fromEndHour;
    }

    addWeeksNumber() {
        this.weekNumbers = [];
        for (var i = 1; i < 54; i++) {
            this.weekNumbers.push({ value: i.toString() });
        }
    }

    DropDownWeekNumberSelect() {
        if (!this.isComingFromEditMode) {
            let tempStartDate: Date = new Date(moment(this.createEventObj.startDate).week(this.createEventObj.startWeekNumber || 1).startOf("week").format("YYYY-MM-DD"));
            const endDate: Date = addDays(new Date(tempStartDate.getTime()), 6);
            this.createEventObj.endDate = endDate;
        }
        this.calculateEndDate();
    }

    _handleKeydown(event: KeyboardEvent) {
        if (event.keyCode === 13) {
            event.stopPropagation();
        }
    }

    addCoworker(findCoworker) {
        var coworker = new CoworkerWithScheduleDetails();
        coworker.id = findCoworker.id;
        coworker.CoworkerId = findCoworker.id;
        coworker.name = findCoworker.name;
        coworker.totalWorkHourOfCoworker = 0;
        coworker.ScheduleDays = [];
        this.reCoworkerScheduleDays()
    }

    checkCoworkerOnleave(coworker) {
        if (coworker && coworker.id) {
            this.tempCoworkerListForLeave = [];
            //this.tempDayList = [];
            this.createEventObj.startDate = moment(this.createEventObj.startDate).toDate();
            var sdate = new Date(this.createEventObj.startDate.setHours(0, 0, 0, 0));
            var edate = new Date(this.createEventObj.endDate.setHours(0, 0, 0, 0));
            this.coworkerLeaveList = [];
            this.coworkerLeaveList = this.coWorkerList.filter(x => x.id == coworker.id);
            if (this.coworkerLeaveList.length > 0 && this.coworkerLeaveList[0].holidayList && this.coworkerLeaveList[0].holidayList.length > 0) {
                var data = this.coworkerLeaveList[0].holidayList.filter(o => (new Date(o.date) >= sdate && new Date(o.date) <= edate));
                if (data.length > 0) {
                    var tempCoworker = {
                        id: this.coworkerLeaveList[0].id,
                        name: this.coworkerLeaveList[0].name,
                        holidayList: this.coworkerLeaveList[0].holidayList.sort((a, b) => {
                            return <any>new Date(a.date) - <any>new Date(b.date);
                        }),
                    }
                    this.tempCoworkerListForLeave.push(tempCoworker);
                }
            }

            else
                this.addCoworkerScheduleDays(coworker)
        }
    }
    addCoworkerScheduleDays(coworker) {
        coworker.totalWorkHourOfCoworker = coworker.ScheduleDays.filter(s => s.isChecked == true).reduce((sum, current) => sum + parseFloat(current.hours), 0)
        this.selectedCoWorkerList.push(coworker);
    }

    reCoworkerScheduleDays() {
        this.ScheduleDays = [];
        this.workHour.dayList.filter((x, index) => {
            this.scheduleDay = {};
            this.scheduleDay.date = x.date;
            this.scheduleDay.isChecked = x.isChecked;
            var includeAllDays = true;
            var day = new Date(moment(x.date).format("YYYY-MM-DD")).getDay();
            if (this.createEventObj.weekendId == 1 && (day == 6 || day == 0)) {
                includeAllDays = false
            }
            if (includeAllDays) {
                if (this.createEventObj.shiftType == 2 || this.createEventObj.shiftType == 3) {
                    if (new Date(moment(x.date).format("YYYY-MM-DD")).getDay() == 5) {
                        this.scheduleDay.from = "07:00";
                        this.scheduleDay.to = "14:30";
                        this.scheduleDay.tid = 7.5;
                        this.scheduleDay.pauseTimer = 0.5;
                        this.scheduleDay.hours = "7";
                        this.scheduleDay.shiftType = 2;
                        this.scheduleDay.shiftTypeName = 'selectedShiftOption.value1'
                    }
                    else {
                        this.scheduleDay.from = "07:00";
                        this.scheduleDay.to = "15:00";
                        this.scheduleDay.tid = 8.00;
                        this.scheduleDay.pauseTimer = 0.5;
                        this.scheduleDay.hours = "07.5";
                        this.scheduleDay.shiftType = 3;
                        this.scheduleDay.shiftTypeName = 'selectedShiftOption.value2'
                    }
                }
                else if (this.createEventObj.shiftType == 4) {
                    this.scheduleDay.from = this.workHour.fromStartHour + ":" + this.workHour.fromStartMinute;
                    this.scheduleDay.to = this.workHour.fromEndHour + ":" + this.workHour.fromEndMinute;
                    var time = moment.utc(moment(this.scheduleDay.to, "HH:mm").diff(moment(this.scheduleDay.from, "HH:mm"))).format("HH:mm")
                    var tidTime = moment(time, "HH:mm").format("mm");
                    var tidHours = moment(time, "HH:mm").format("HH");
                    if (tidTime != "00")
                        time = tidHours + ":" + (Number(this.getMinute(tidTime))).toString();
                    this.scheduleDay.tid = Number(time.replace(':', '.'));

                    this.scheduleDay.pauseTimer = this.isAddBreakTime ? 0.5 : 0.0;
                    this.scheduleDay.hours = (Number(time.replace(':', '.')) - this.scheduleDay.pauseTimer).toString();
                    this.scheduleDay.shiftType = 4;
                    this.scheduleDay.shiftTypeName = this.scheduleDay.from + ' til ' + this.scheduleDay.to;
                }
                this.ScheduleDays.push(this.scheduleDay);
            }
        });
    }

    pushDayList(date, isChecked) {
        let workhoursDay = new WorkhoursDay();
        workhoursDay.date = date;
        workhoursDay.isChecked = isChecked;
        this.workHour.dayList.push(workhoursDay)
    }

    getWeekName(date) {
        return moment(date, "YYYY-MM-DD").format("ddd")
    }

    DropDownWeekPlaningChange() {
        this.calEndDate();
        this.selectedCoWorkerList.filter(coworker => {
            coworker.ScheduleDays = [];
        });
        this.reCoworkerScheduleDays();
    }

    onCheckScheduleDay(value, index, item) {
        item.isChecked = !value;
        this.ScheduleDays.filter(s => s.isChecked == true).reduce((sum, current) => sum + parseFloat(current.hours), 0)
    }

    onToppingRemoved(topping: string, toppingsControl: any) {
        const toppings = this.projectChipListControl.value as string[];
        this.removeFirst(toppings, topping);
        this.projectChipListControl.setValue([...toppings]);
    }

    private removeFirst<T>(array: T[], toRemove: T): void {
        const index = array.indexOf(toRemove);
        if (index !== -1) {
            array.splice(index, 1);
        }
    }

    GetProjects(model: RequestStatusProject) {
        this.showSpinner = true;
        this.projectservice.setRequestParameterForCalender(model);
        this.projectservice.Get<ResponseBase>(model)
            .subscribe((response: ResponseBase) => {                
                if (response != null) {
                    this.projects = [];
                    this.projects = response.result;
                    this.showSpinner = false;
                }
                else {
                    this.projects = [];
                    this.showSpinner = false;
                }
                if(this.projects.length == 0 && this.requestProject.Address) {
                    this.translate.get("MESSAGES.NORECORD").subscribe({
                        next: (res) => {
                            this.notificationsService.warn(res);
                        },
                        error: err => {
                        }
                    });
                    this.requestProject.Address = "";
                    this.SearchVal = "";
                    this.GetProjects(this.requestProject);
                }
            },
                error => (err) => {
                    this.projects = [];
                    this.showSpinner = false;
                },
                () => {
                });
    }

    toggleSelectAll() {
        //this.selectAllChecked = !this.selectAllChecked;
        if (this.selectAllChecked) {
            this.projectChipListControl.setValue([...this.projects]);
        } else {
            this.projectChipListControl.setValue([]);
        }
    }

    DropDownSearch(value) {
        this.filterProjects();
    }

    filterProjects() {
        if (this.SearchVal) {
            this.requestProject.Address = this.SearchVal.toLowerCase().trim();
            this.GetProjects(this.requestProject);
        } 
        else {
            this.requestProject.Address = "";
            this.GetProjects(this.requestProject);
        }
    }

    clearSearch(value) {
        if (!value) {
            this.requestProject.Address = "";
            this.GetProjects(this.requestProject);
        }
    }
}
