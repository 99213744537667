import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../../../core/modules/shared.module';

import { VibeMessageComponent } from './message.component';

import { ChatService } from './message.service';

import { FuseChatChatsSidenavComponent } from './sidenavs/left/chats/chats.component';
import { FuseChatUserSidenavComponent } from './sidenavs/left/user/user.component';

import { FuseChatViewComponent } from './chat-view/chat-view.component';
import { FuseChatStartComponent } from './chat-start/chat-start.component';

import { FuseChatContactSidenavComponent } from './sidenavs/right/contact/contact.component';
import { FuseChatLeftSidenavComponent } from './sidenavs/left/left.component';
import { FuseChatRightSidenavComponent } from './sidenavs/right/right.component';

const routes = [
    {
    path: 'messages',
        component: VibeMessageComponent,
        children: [],
        resolve: {
            chat: ChatService
        }
    }
];

@NgModule({
    declarations: [
        VibeMessageComponent,
        FuseChatLeftSidenavComponent,
        FuseChatUserSidenavComponent,

        FuseChatViewComponent,
        FuseChatStartComponent,

        FuseChatContactSidenavComponent,
        FuseChatChatsSidenavComponent,
        FuseChatRightSidenavComponent
    ],
    imports: [
        SharedModule,
        RouterModule.forChild(routes)
    ],
    exports: [
        VibeMessageComponent
    ],
    providers: [
        ChatService
    ]
})

export class VibeMessageModule {
}
