export const locale = {
    lang: 'en',
    data: {
      'BUTTONS': {
        'Upload': 'Upload',
        'Cancel': 'Cancel',
        "Update":"Opdatere",
        "Reset":"Reset/Cancel",
        "SampleFileDownload":"Sample file download",
        'Back': 'Back',
      },
      'Header':{
        'Kundenr': 'Number',
        'FirmaNavn': 'CompanyName',
        'KontaktPerson': 'Contact person',
        'Address': 'Address',
        'Postnr': 'Post number',
        'By': 'City',
        'Telefon': 'TelePhone',
        'EmailFaktura': 'Invoice Email',
        'Email': 'Email',
      },
      'FIELDS': {
        'SelectSupplier': 'Select Supplier',
        'UploadPriceList': 'Upload Price List',
        'ChooseFile': 'Choose File',
      },
      'LABELS': {
        'TITLE': 'Customer list upload (.xls,.xlsx)',
        'NoFileSelected': 'No File Selected',
        'FileSelected': 'File Selected',
      },
      'MESSAGES': {
        'Created': 'Created Successfully.',
        'Updated': 'Updated Successfully.',
        'Failed': 'Failed',
        'Deleted': 'Deleted successfully',
        'Notes':'**Note - Inactive or Deleted materials are highlighted in red color'
      },
    }
  };
  