import { Component, OnInit } from "@angular/core";
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { TitleService } from 'app/core/services/title.service';
import { SettingsService } from "../settings/settings.service";
import { ResponseBase } from "app/main/model/ResponseBase";

@Component({
    selector: 'vibe-tools-and-ladders',
    templateUrl: './tools-and-ladders.component.html',
    styleUrls: ['./tools-and-ladders.component.scss']
})

export class ToolsAndLaddersComponent implements OnInit {
    showSpinner = false;
    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private settingsService: SettingsService,
        private titleService: TitleService) {
        this.translationLoaderService.loadTranslations(danish, english);
        this.titleService.SetTitle("TITLE_TOOLSANDLADDERS");
    }

    ngOnInit() {
        this.getToolsAndLadders();
    }

    getToolsAndLadders() {
        this.showSpinner = true;
        this.settingsService.GetSettingsValue<ResponseBase>("Menu", "ToolsAndLaddersForWeb", null).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.result && response.result.myResponse.length > 0) {
                    if(response.result.myResponse[0] && response.result.myResponse[0].value)
                    window.open(response.result.myResponse[0].value, "_blank");
                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }
}