import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@fuse/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FusePipesModule } from 'app/core/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { BarRatingModule } from 'ngx-bar-rating';
import { RouterModule } from "@angular/router";
import { NewQuestionsComponent } from "./new-questions.component";
import { ListQuestionsComponent } from "./components/list/list-questions.component";
import { ManageQuestionsComponent } from "./components/manage/manage-questions.component";
import { VibeSelectControlModule } from "../vibe-select-control/vibe-select-control.module";

const routes = [
    {
        path: 'offers/qualitytest1',
        component: NewQuestionsComponent,
    },
];

@NgModule({
    declarations: [
        NewQuestionsComponent,
        ListQuestionsComponent,
        ManageQuestionsComponent
    ],
    imports: [
        CommonModule,
        MaterialModule,
        RouterModule.forChild(routes),
        FlexLayoutModule,
        FusePipesModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        DatePickerModule,
        NgxMatSelectSearchModule,
        BarRatingModule,
        VibeSelectControlModule
    ],
    entryComponents: [
        NewQuestionsComponent,
        ListQuestionsComponent,
        ManageQuestionsComponent
    ],
    exports: [
        NewQuestionsComponent,
        ListQuestionsComponent,
        ManageQuestionsComponent
    ]
})

export class NewQuestionsModule {

}