import { Component, OnInit, Inject } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";

@Component({
    selector: "calculate-driving-days-modal",
    templateUrl: "./calculate-driving-days.component.html",
})

export class CalculateDrivingDaysModalComponent implements OnInit {
    showSpinner: boolean = false;
    NumberOfDayesDetails: number;
    numberOfDays: number = 0;
    numberOfDrivingDays: number = 0;
    constructor(private translationLoader: FuseTranslationLoaderService,
        public dialogRef: MatDialogRef<CalculateDrivingDaysModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.translationLoader.loadTranslations(danish, english);
    }

    ngOnInit() {
        this.numberOfDays = this.data.numberOfDays;
        this.numberOfDrivingDays = this.data.numberOfDrivingDays;
    }

    Update() {
        this.dialogRef.close({
            numberOfDayesDetails: this.numberOfDays,
            numberOfDays: this.numberOfDays
        });
    }

    close() {
        this.dialogRef.close(false);
    }
}