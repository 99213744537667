export const locale = {
  lang: 'ds',
  data: {
    'Tabs': {
      'Offer': 'Tilbud',
      'BasicDetail': 'Grundlæggende detaljer',
      'File': 'Fil',
      'Category': 'Kategori',
      'WorkItem': 'Arbejdspost'
    },
    'MESSAGE': {
      'Failed': 'Operation mislykket, prøv senere',
      'Success': 'Succes.',
      'CommingSoon': 'Kommer snart..',
      'Deleted': 'Slettet med succes',
    },
    'FIELDS':{
        'GoTo': 'Gå til',
        'SRNO': 'Sr No',
        'Status': 'Status',
        'Number': 'Nummer',
        'Name': 'Navn',
        'Createdby': 'Created By',
        'CreatedDate': 'Created Dato',
        'ModifyBy': 'Modify By',
        'ModifyDate': 'Modify Dato',
        'CoworkerName': 'Medarbejder',
        'Date': 'Dato',
        'Description': 'Description',
    },
    'BUTTONS': {
        'AddCategory': 'Tilføj kategori',
        'Add':'Add',
    },
    'Headers': {
      'DeleteCategory': 'Vil du slette kategori',
    }
  }
};
