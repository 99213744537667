import { Pipe, PipeTransform } from '@angular/core';
import { ConvertTimeToHunders } from '../Validators/validation';

@Pipe({ name: 'hoursToHundredths' })
export class HoursToHundredths implements PipeTransform {
  transform(value: string): string {
    //if (value) {
    //  if (value == '-')
    //    return value;
    //  else
    //    return ConvertTimeToHunders(value).toString();
    //}
    //else
      return value;
  }
}
