export const locale = {
    lang: 'en',
    data: {
        'Header':{
            'Title':'Copy all materials from this to'
        },
       'BUTTONS':{
           'Add': 'Add',
           'Back': 'Back',
           'Copy':'Copy',
        },
        'LABELS': {
            'CopyTo': 'Copy to',
        },
        'MESSAGES': {
            'NORECORD': 'No Record Found',
            'Copy': 'Copying succeeded',
            'CopyError': 'Copy error.',
        }, 
        'FILTERS': {
            'All': 'All',
            'OfferType':'Offer Type',
        },
        'FIELDS': {
            'Not-Found-MATERIAL':'Material not found'
        }
    }
};