export const locale = {
  lang: 'en',
  data: {
    'HEADER': {
      'PleaseSelectTabsFromBelowList': 'Please select tabs from below list',
      'tabName': 'Tab Name'
    },
    'BUTTONS': {
      'Save': 'Save',
      'Back': 'Back',
      'NewTab': 'NewTab',
    },
    'MESSAGES': {
      'CompletedNextSide': 'Do you want to change the next sight seen to {Date} ?',
      'CompletedNextService': 'Do you want to change the next service to  {Date} ?',
      'ItemNumberExist': 'Number is already exist',
      'Created': 'Created successfully',
      'Updated': 'Updated successfully',
      'Deleted': 'Deleted successfully',
    }, 'TOOTIPORTITLE': {
      'Edit': 'Edit',
      'Delete': 'Delete',
      'Save':'Save'
    }
  }
};
