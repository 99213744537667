export const locale = {
  lang: 'ds',
  data: {
    'MESSAGES': {
      'Created': 'Oprettet med succes',
      'Updated': 'Opdateret med succes',
      'Deleted': 'Slettet med succes',
      'Failed': 'Operation mislykket, prøv senere',
    },
    'BUTTONS': {
      'NEWCOWORKER': 'Ny medarbejder',
    },
    'FIELDS': {

    },
  }
};

