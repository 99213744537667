export const SettingConstant= {
  Admin_Show_Document:"Kun_Admin_Atvise_Dokument",
}

export const OfferFormName = {
  FormA: "FormA",
  FormB: "FormB",
  FormC: "FormC",
  FormD: "FormD",
  FormE: "FormE",
}
