import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { ProjectsOfferRoom } from 'app/main/model/ProjectOffer';
import { RequestProjectOfferRoom } from 'app/main/model/RequestBase';
import { QualityTestService } from '../../quality-test.service';
import { ProjectofferService } from 'app/main/pages/project-offer/project-offer.service';
import { SettingsService } from 'app/main/pages/settings/settings.service';
import { environment } from 'environments/environment';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';
import { ImageGalleryPopupComponent } from 'app/main/shared-component/quality-test/components/image-gallery-list/components/image-gallery-popup/image-gallery-popup.component';
import { SharedTextBoxPopup } from 'app/main/shared-component/text-box-popup/text-box-popup.component';
import { MatDialog } from '@angular/material';

@Component({
    selector: 'app-image-gallery-list',
    templateUrl: './image-gallery-list.component.html',
    styleUrls: ['./image-gallery-list.component.scss']
})
export class ImageGalleryListComponent implements OnInit {
    @Input() ModuleID: string = "";
    @Input() ProjectID: string = "";
    @Input() ModuleName: string = "";
    @Input() ModuleRoomSelectID: string = "";
    @Input() OfferQualityTestCategoryID: string = "";
    GalleryImagesForBefore: any = [];
    GalleryImagesForAfter: any = [];
    showSpinnerOnBeforeImages: boolean = false;
    showSpinnerOnAfterImages: boolean = false;
    ProjectsOfferRooms: ProjectsOfferRoom[];
    selectedOfferRoom: string = "";
    modulename: string = "";
    moduleid: string = "";
    constructor(private _route: ActivatedRoute,
        private translationLoader: FuseTranslationLoaderService,
        private translate: TranslateService,
        private notificationsService: NotificationsService,
        private qualityTestService: QualityTestService,
        private projectofferService: ProjectofferService,
        public dialog: MatDialog,
        private settingsService: SettingsService) {
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
        this.modulename = this.ModuleRoomSelectID ? "Offer" : this.ModuleName;
        this.moduleid = this.ModuleRoomSelectID ? this.ModuleRoomSelectID : this.ModuleID;
        this.ReadFolder(true);
        this.ReadFolder(false);
        var requestProjectOfferRoom: RequestProjectOfferRoom = new RequestProjectOfferRoom();
        requestProjectOfferRoom.Limit = 100;
        //requestProjectOfferRoom.OfferId = this.OfferID;
        requestProjectOfferRoom.OfferId = this.ModuleRoomSelectID ? this.ModuleRoomSelectID : null;
        this.getProjectOfferRoomList(requestProjectOfferRoom);
    }

    ReadFolder(isBeforeLoadImages) {
        var req = {};
        var path = "";
        if (isBeforeLoadImages) {
            if (this.selectedOfferRoom)
                path = "wwwroot\\Upload\\QualityTest\\" + this.modulename + "\\" + this.moduleid + "\\BeforeImages" + "\\" + this.selectedOfferRoom;
            else
                path = "wwwroot\\Upload\\QualityTest\\" + this.modulename + "\\" + this.moduleid + "\\BeforeImages";

            this.showSpinnerOnBeforeImages = true;
            req = {
                FolderPath: path,
            }
        }
        else {
            if (this.selectedOfferRoom)
                path = "wwwroot\\Upload\\QualityTest\\" + this.modulename + "\\" + this.moduleid + "\\AfterImages" + "\\" + this.selectedOfferRoom;
            else
                path = "wwwroot\\Upload\\QualityTest\\" + this.modulename + "\\" + this.moduleid + "\\AfterImages";

            this.showSpinnerOnAfterImages = true;
            req = {
                FolderPath: path,
            }
        }

        this.qualityTestService.GetFilesFRomPath(req).subscribe({
            next: (response) => {
                if (isBeforeLoadImages) {
                    this.showSpinnerOnBeforeImages = false;
                    if (response.result) {
                        this.GalleryImagesForBefore = response.result;
                    }
                }
                else {
                    this.showSpinnerOnAfterImages = false;
                    if (response.result) {
                        this.GalleryImagesForAfter = response.result;
                    }
                }
                //this.GetFileList();
            },
            error: err => {
                //this.showSpinner = false;
                this.translate.get("MESSAGES.DropBoxApiNotResponding").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            }
        });
    }

    FileUploadOnBefore(event) {
        this.showSpinnerOnBeforeImages = true;
        this.GalleryImagesForBefore = [];
        if (event.target.files.length > 0) {
            const files = event.target.files;
            const formData = new FormData();
            for (var i = 0; i < files.length; i++) {
                formData.append("file[]", files[i]);
            }
            if (this.selectedOfferRoom)
                formData.append('RootFolderPath', "wwwroot\\Upload\\QualityTest\\" + this.modulename + "\\" + this.moduleid + "\\BeforeImages\\" + this.selectedOfferRoom);
            else
                formData.append('RootFolderPath', "wwwroot\\Upload\\QualityTest\\" + this.modulename + "\\" + this.moduleid + "\\BeforeImages");

            this.qualityTestService.UploadImages(formData).subscribe({
                next: (response) => {
                    this.ReadFolder(true);
                    this.showSpinnerOnBeforeImages = false;
                },
                error: err => {
                    //this.showSpinner = false;
                    this.translate.get("MESSAGES.DropBoxApiNotResponding").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });
                }
            });
        }
    }

    FileUploadOnAfter(event) {
        this.showSpinnerOnAfterImages = true;
        this.GalleryImagesForAfter = [];
        if (event.target.files.length > 0) {
            const files = event.target.files;
            const formData = new FormData();
            for (var i = 0; i < files.length; i++) {
                formData.append("file[]", files[i]);
            }
            if (this.selectedOfferRoom)
                formData.append('RootFolderPath', "wwwroot\\Upload\\QualityTest\\" + this.modulename + "\\" + this.moduleid + "\\AfterImages\\" + this.selectedOfferRoom);
            else
                formData.append('RootFolderPath', "wwwroot\\Upload\\QualityTest\\" + this.modulename + "\\" + this.moduleid + "\\AfterImages");

            this.qualityTestService.UploadImages(formData).subscribe({
                next: (response) => {
                    this.ReadFolder(false);
                    this.showSpinnerOnAfterImages = false;
                },
                error: err => {
                    //this.showSpinner = false;
                    this.translate.get("MESSAGES.DropBoxApiNotResponding").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });
                }
            });
        }
    }

    getProjectOfferRoomList(model) {
        //this.showSpinner = true;
        this.projectofferService.getProjectOfferRoomList<ResponseBase>(model)
            .subscribe((response: ResponseBase) => {
                //this.showSpinner = false;
                this.ProjectsOfferRooms = [];
                if (response != null) {
                    this.ProjectsOfferRooms = response.result.myResponse;
                }
            },
                error => (err) => {
                    //this.showSpinner = false;
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });
                });
    }

    onRoomSelect() {
        this.GalleryImagesForBefore = [];
        this.GalleryImagesForAfter = [];
        this.ReadFolder(true);
        this.ReadFolder(false);
    }

    getImagePath(path){
        var url = environment.url + path.replace(/\\/g, "/");
        return url;
    }
    


    gotoDelete(imagePath, moduleName){
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: '20%',
            data: { Header: 'Headers.DeleteConformation' }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                if (moduleName == "afterImages")
                    this.showSpinnerOnAfterImages = true;
                else
                    this.showSpinnerOnBeforeImages = true;
                var imagePathList=  [imagePath]; 
                this.settingsService.DeleteImagesByPath(imagePathList).subscribe({
                    next: (response) => {
                        this.translate.get("MESSAGE.Deleted").subscribe({
                            next: (res) => {
                                this.notificationsService.create(res);
                            },
                            error: err => {
                            }
                        });
                        if (moduleName == "afterImages")
                            this.ReadFolder(false);
                        else
                            this.ReadFolder(true);
                        
                    },
                    error: err => {
                        this.showSpinnerOnAfterImages = false;
                        this.showSpinnerOnBeforeImages = false;
                        this.translate.get("MESSAGES.DropBoxApiNotResponding").subscribe({
                            next: (res) => {
                                this.notificationsService.error(res);
                            },
                            error: err => {
                            }
                        });
                    }
                });
            }
        });
        
    }

    gotoEdit(imagePath, moduleName){
        var fileName = (imagePath.split('\\').pop().split('/').pop().split('.'))[0];
        const dialogRef = this.dialog.open(SharedTextBoxPopup, {
            width: '40%',
            data: {
                HeadingTitleKey: "HEADERS.RenameFile",
                TextBoxLabelKey: "FIELDS.Name",
                TextBoxValue: fileName ? fileName : "",
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result && result.IsSuccess) {
                //result.TextValue;
                if (moduleName == "afterImages")
                    this.showSpinnerOnAfterImages = true;
                else
                    this.showSpinnerOnBeforeImages = true;
                this.settingsService.RenameFile(imagePath, result.TextValue).subscribe({
                    next: (response) => {
                        this.translate.get("MESSAGE.Success").subscribe({
                            next: (res) => {
                                this.notificationsService.create(res);
                            },
                            error: err => {
                            }
                        });
                        if (moduleName == "afterImages")
                            this.ReadFolder(false);
                        else
                            this.ReadFolder(true);
                        
                    },
                    error: err => {
                        this.showSpinnerOnAfterImages = false;
                        this.showSpinnerOnBeforeImages = false;
                        this.translate.get("MESSAGES.DropBoxApiNotResponding").subscribe({
                            next: (res) => {
                                this.notificationsService.error(res);
                            },
                            error: err => {
                            }
                        });
                    }
                });
            }
        });
        
    }

    openImageGallary(imagePath,moduleName) {
        const dialogRef = this.dialog.open(ImageGalleryPopupComponent, {
            height: '80%',
            width: '60%',
            data: { 
                galleryImagesLoad : moduleName == "afterImages" ? this.GalleryImagesForAfter : this.GalleryImagesForBefore,
                imagePath:imagePath
             }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
            }
        });
        
    }

    getFileName(imagePath, moduleName){
        var fileName = "";
        if(imagePath)
         fileName = (imagePath.split('\\').pop().split('/').pop().split('.'))[0];
        return fileName;
    }
}
