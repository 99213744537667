export class OfferQualityTestCategory {
    public id: string;
    public parentID: string;
    public name: string;
    public imagePath: string;
    public image: string;
    public isLeafNode: boolean;
    public isDeleted: boolean;
    public level: number;
    public multicheck: boolean = false;
    public moduleName: string;
    public json: any;
}

export class OfferHumidityandTemperature {
    public id: string;
    public offerID: string;
    public moisture: number = 0;
    public temperature: number = 0;
    public text: string;
    public offersRoomID: string;
    public offersRoomName: string;
    public isActive: boolean = true;
    public isDeleted: boolean = false;
    public createdByName: string;
    public createdBy: string;
    public createdDate: Date = new Date();
    public modifyBy: string
    public modifyDate: Date = new Date();
    public projectID: string;
    public qualityTestCategoryID: string;
}

export class OfferColorCodeDTO {
    public id: string;
    public offerID: string;
    public projectID: string;
    public colorCode: string;
    public text: string;
    public offersRoomID: string;
    public offersRoomName: string;
    public createdByName: string;
    public isActive: boolean = true;
    public isDeleted: boolean = false;
    public qualityTestCategoryID: string;
}

export class OfferDefectRoomDTO {
    public id: string;
    public offerId: string;
    public roomId: string;
    public text: string;
    public isActive: boolean = true;
    public isDeleted: boolean = false;
    public createdByName: string;
    public createdBy: string;
    public createdDate: Date = new Date();
    public modifyBy: string
    public modifyDate: Date = new Date();
}

export class AddQuestion{
    public id?: number;
    public text: string;
}

export class AddQuestionInformation{
    public id?: number;
    public projectID: string;
    public offerID: string;
    public offerQualityTestCategoryID: string;
    public coworkerID: string;
    public json: string;
    public rootFolderPath: string; 
}