import { NgModule } from '@angular/core';

import { KeysPipe } from './keys.pipe';
import { GetByIdPipe } from './getById.pipe';
import { HtmlToPlaintextPipe } from './htmlToPlaintext.pipe';
import { FilterPipe } from './filter.pipe';
import { CamelCaseToDashPipe } from './camelCaseToDash.pipe';
import { TextSubstring } from './textSubstring.pipe';
import { DateDisplay } from './dateDisplay.pipe';
import { Fraction } from './Fraction.pipe';
import { DoToComma } from './doToComma.pipe';
import { DateFormat } from './dateFormat.pipe';
import { AngularMomentTimeAgo } from './amMomentTimeAgo.pipe';
import { HoursToHundredths } from './hoursToHundredths.pipe';
import { DenmarkDigitFormate } from './denmarkDigitFormate.pipe';
import { SortPipe } from './sort.pipe';
import { SplitCommaStringPipe } from './splitCommaStringPipe.pipe';
import { ContentToAnchorTextPipe } from './contentToAnchorText.pipe';
import { ConvertToHoursAndMinutesPipe } from './convertMintoHour.pipe';

@NgModule({
    declarations: [
        KeysPipe,
        GetByIdPipe,
        HtmlToPlaintextPipe,
        FilterPipe,
      CamelCaseToDashPipe
      ,
      TextSubstring,
      DateDisplay,
      Fraction,
      DoToComma,
      DateFormat,
      HoursToHundredths,
      DenmarkDigitFormate,
      SortPipe,
      SplitCommaStringPipe,
      AngularMomentTimeAgo,
      ContentToAnchorTextPipe,
      ConvertToHoursAndMinutesPipe
    ],
    imports     : [],
    exports     : [
        KeysPipe,
        GetByIdPipe,
        HtmlToPlaintextPipe,
        FilterPipe,
      CamelCaseToDashPipe
      ,
      TextSubstring,
      DateDisplay,
      Fraction,
      DoToComma,
      DateFormat,
      HoursToHundredths,
      DenmarkDigitFormate,
      SortPipe,
      SplitCommaStringPipe,
      AngularMomentTimeAgo,
      ContentToAnchorTextPipe,
      ConvertToHoursAndMinutesPipe
    ],
    providers:    [ SortPipe ],
})

export class FusePipesModule
{

}
