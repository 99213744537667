export const locale = {
  lang: 'en',
  data: {
    'Tabs': {
      'Offer': 'Offer',
      'BasicDetail': 'Basic Detail',
      'File': 'File',
      'Category': 'Category',
      'WorkItem':'Work Item'
    },
    'MESSAGE': {
      'Failed': 'Operation failed , try later',
      'Success': 'Success.',
      'CommingSoon':'Comming Soon..',
      'Deleted': 'Deleted successfully',
    },
    'FIELDS':{
      'GoTo':'Go to',
      'All': 'All',
      'SelectRoom':'Select Room',
    },
    'BUTTONS': {
      'AddCategory': 'Add Category'
    },
    'Headers': {
        'ChooseFile': 'Choose File',
        'NoFileSelected': 'No File Selected',
        'BeforeImages': 'Before Images',
        'AfterImages': 'After Images',
    }
  }
};
