import { Component, OnInit, Inject } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { MaterialService } from '../../material.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatTableDataSource } from '@angular/material';

import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { SupplierOrderList } from 'app/constant/common.constant';
import { ResponseBase } from 'app/main/model/ResponseBase';
import * as moment from "moment";

@Component({
    selector: "edit-material-order",
    templateUrl: "./edit-material-order.component.html",
    styleUrls: ["./edit-material-order.component.scss"]
})

export class EditMaterialOrderComponent implements OnInit {
    showSpinner: boolean = false;
    allCheckboxValue: boolean = false;
    isSaveEnabled: boolean = true;
    materialOrderObject: any;
    supplierStatusEnum = SupplierOrderList;
    SupplierOrdersDetailList: any = [];
    displayedColumns = ['isReceived', 'itemNumber', 'text', 'colorCode', 'projectNumber', 'requisitionNo','orderQty', 'purchaseQty', 'unit', 'purchasePrice', 'total'];
    dataSource: MatTableDataSource<any>;
    isChecked: boolean = false;
    materialSum: number = 0;
    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private translateService: TranslateService,
        private notificationsService: NotificationsService,
        private materialService: MaterialService,
        public dialogRef: MatDialogRef<EditMaterialOrderComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        translationLoaderService.loadTranslations(danish, english);

    }

    ngOnInit() {
        this.materialOrderObject = this.data;
        if (!this.materialOrderObject.receivedDate) {
            this.materialOrderObject.receivedDate = new Date(moment(new Date()).format("YYYY-MM-DD"));
        }
        else {
            this.materialOrderObject.receivedDate = new Date(moment(this.materialOrderObject.receivedDate).format("YYYY-MM-DD"));
        }
        if(this.materialOrderObject.orderDate)
            this.materialOrderObject.orderDate = new Date(moment(this.materialOrderObject.orderDate).format("YYYY-MM-DD"));
        this.GetSupplierOrdersDetailList();
    }

    updateStatus() {
        this.showSpinner = true;
        this.SupplierOrdersDetailList.supplierOrderDetails
            .filter(o => {
                if (o.isReceived == true) {
                    o.receivedStaus = this.materialOrderObject.status;
                }
            });
        this.materialService.UpdateMaterialStatus(this.materialOrderObject.id,
            this.materialOrderObject.status, moment(this.materialOrderObject.receivedDate).format("YYYY-MM-DD"),
            this.SupplierOrdersDetailList.supplierOrderDetails).subscribe({
                next: (response: ResponseBase) => {
                    this.materialOrderObject = response.result;
                    this.showSpinner = false;
                    this.translateService.get("MESSAGES.Updated").subscribe({
                        next: (res) => {
                            this.notificationsService.create(res);
                        },
                        error: err => {
                        }
                    });
                },
                error: err => {
                    this.showSpinner = false;
                    this.translateService.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });
                },
                complete: () => {
                    this.dialogRef.close(true);
                    this.showSpinner = false;
                }
            });
    }

    GetSupplierOrdersDetailList() {
        this.materialSum = 0;
        this.showSpinner = true;
        this.materialService.GetSupplierOrdersDetail(this.materialOrderObject.id).subscribe({
            next: (response: ResponseBase) => {
                this.SupplierOrdersDetailList = response.result;
                if (this.SupplierOrdersDetailList.supplierOrderDetails.length > 0) {
                    let anyIsReceivedItem=false;
                    this.SupplierOrdersDetailList.supplierOrderDetails.filter(e => {
                        e.receivedQty = e.receivedQty;
                        e.receivedPurchasePrice = e.receivedPurchasePrice ? e.receivedPurchasePrice : e.purchasePrice;
                        if (this.materialOrderObject.status== 1) {
                            anyIsReceivedItem = true;
                            e.isReceived = true;
                        }
                        if(e.receivedQty && e.receivedPurchasePrice)
                            this.materialSum += (e.receivedQty * e.receivedPurchasePrice);
                    });
                    if (anyIsReceivedItem) {
                        this.onSelectItem()
                    }
                    this.calculateTotal() 
                }
                this.allCheckboxValue = (this.SupplierOrdersDetailList.supplierOrderDetails.find(element => element.isReceived == false)) ? false : true;
                this.dataSource = new MatTableDataSource(this.SupplierOrdersDetailList.supplierOrderDetails);
                this.showSpinner = false;
            },
            error: err => {
                this.showSpinner = false;
                this.translateService.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    checkedActionForAll() {
        this.SupplierOrdersDetailList.supplierOrderDetails.filter(x => {
            x.isReceived = this.allCheckboxValue;
            if(this.allCheckboxValue)
                x.receivedQty = x.qty;
            else
                x.receivedQty = 0;
        });
        this.isSaveEnabled = this.allCheckboxValue;
        this.calculateTotal();
    }

    close() {
        this.dialogRef.close(false);
    }

    onSelectItem() {
        this.allCheckboxValue = (this.SupplierOrdersDetailList.supplierOrderDetails.find(element => element.isReceived == false)) ? false : true;
        this.isSaveEnabled = (this.SupplierOrdersDetailList.supplierOrderDetails.find(element => element.isReceived == true)) ? true : false;
    }

    calculateTotal() {
        let temp_total = 0;
        this.SupplierOrdersDetailList.supplierOrderDetails.filter(row => {
            temp_total += row.receivedQty * row.receivedPurchasePrice
        });
        this.materialOrderObject.totalPrice = temp_total
    }

    onSelectItemCheckUncheck(row) {
        if(row.isReceived)
            row.receivedQty = row.qty;
        else
            row.receivedQty = 0;
        this.onSelectItem();
        this.calculateTotal();
    }

    changeStatus(){
        if(this.materialOrderObject.status == 2){
            this.SupplierOrdersDetailList.supplierOrderDetails.filter(x => {
                   x.isReceived 
                   x.receivedQty = x.qty;
                  this.calculateTotal();
            });
        }   
        else if(this.materialOrderObject.status == 1){
            this.SupplierOrdersDetailList.supplierOrderDetails.filter(x => {
                x.isReceived 
                x.receivedQty = 0;
         });
        }
    }
}