export const locale = {
  lang: 'ds',
  data: {
    'TABS': {
    },
    'MESSAGES': {
      'Add': 'Oprettet',
      'Add_Error': 'Ingen registrering fundet på Vagttider eller Vagtplan bordet, kontakt venligst til administrator.',
      'Some_Coworker_Add_Error': 'Nogle kolleger kan ikke tilføje arbejdstid.',
      'CanNotAddWorkHour': '{@Coworker} medarbejdere Kan ikke tilføje arbejdstid.',
      'DeleteNonWorkingHoursOne': 'Ønsker du at slette fra',
      'DeleteNonWorkingHoursTwo': 'den',
      'DeleteNonWorkingHoursThree': 'til den'
    },
    'Button': {
      'Plan': 'Planlæg',
      'Save': 'Gem',
      'Cancel': 'Gå tilbage',
      'Delete': 'Slette',
      'GoToProject': 'gå til sagen',
    },
    'FIELDS': {
      'Coworker': 'Medarbejdere',
      'Project': 'Projekt',
      'StartDate': 'Start dato ',
      'EndDate': 'Slut dato ',
      'Shift': 'Tids rum',
      'Not-Found-Worker': 'Medarbejder ikke fundet',
      'Not-Found-Project': 'Projekt not found',
      'Requisition': 'Opgave',
      'EnterStarttime': 'Indtast starttidspunkt',
      'EndTime': 'Indtast slutstidspunkt',
      'Startime': 'Starttidspunkt',
      'Endtime': 'Slut tid',
      'BreakTime': 'Tilføj Break tid (30 min)',
      'WorkhoursType': 'Arbejdstid type',
      'nonStatus': 'Type',
      'nonText': 'Tekst',
      'WeekNumber': 'Uges nummer', 
      'LeaveStatus': 'Status',
      'AdminComment': 'Admin kommentar',
      'PaymentOption': 'Betaling option',
      'PlanningDates': 'Planlæg dato',
      'ApprovedBy': 'Godkendt af'
    },
    'selectedOption': {
      'value1': 'Tilføj 07:00 til 14:30',
      'value2': 'Tilføj 07:00 til 15:00',
      'value3': 'Tilføj timer manuelt',
    },
    'Header': {
      'Add7to0230': 'Tilføj 07:00 til 14:30',
      'Add7to03': 'Tilføj 07:00 til 15:00',
    },
    'Lable': {
      'Timer': 'Timer',
      'HoursLeft': 'Timer tilbage',
      'Lift': 'Lift',
      'Stigemand': 'Stigemand',
      'RequisitionText': 'Opgave tekst',
      'No': 'Nej',
      'Yes': 'Ja',
      'Estimate': 'Overslagspris',
      'Price-included': 'Pris incl. Moms',
      'Price-excluded': 'Pris excl. Moms',
      'VAT': 'Moms',
      'MaterialTotalPrice': 'Materiale I alt',
      'MaterialTotalPriceIncludeVAT': 'Materiale I alt incl. Moms',
      'MaterialPersonalTotalPrice': 'Materiale Personlig Pris I alt',
      'hoursOnCase': 'Timer til sagen',
      'noOfDays': 'Hvor mange dage',
      'Hoursspent': 'Timer brugt',
    },
    'selectedWorkHourStatus': {
      'value1': 'Ferie ',
      'value2': 'Syg',
      'value3': 'Feriefridag',
      'value4': 'Fridag m.løn',
      'value5': 'Fridag u.løn',
      'value6': 'Kursus/skole',
      'value7': 'Afspadsering',
      'value8': 'Andet',
      'value9': 'Tilbud'
    },
    'selectedWorkHoursApproveLeaveOption': {
      'value1': 'Ansøg',
      'value2': 'Godkendt',
      'value3': 'Afvist',
    },
    'paymentOptionType': {
      'value1': 'Uden Betaling',
      'value2': 'Med Betale',
    },
    'selectedShiftOption': {
      'value1': '07:00 til 14:30',
      'value2': '07:00 til 15:00',
      'value3': 'timer manuelt',
    },
  }
};
