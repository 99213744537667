export const locale = {
    lang: 'en',
    data: {
        'TITLE': {
            'Header-Email': 'Email Credintial',
        },
        'FIELDS': {
            'SMTP': 'SMTP',
            'SMTPPortNumber': 'SMTP Port Number',
            'SMTPEmail': 'SMTP Email',
            'SMTPPassword': 'SMTP Password',
            'SMTPUserName': 'SMTP UserName',
         },
        'VALIDATIONS': {
        },
        'MESSAGES': {
            'Saved': 'Saved successfully.',
        },
        'BUTTONS': {
            'Save': 'Save',
        }
    }
};