import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'doToComma' })
export class DoToComma implements PipeTransform {
  transform(value: string): string {
    if (value) {
      if (value == '-')
        return value;
      return parseFloat(value).toFixed(2).toString().replace(".", ",");
      //return value.replace(".", ":");
    }
    else
      return value;
  }
}
