export const locale = {
    lang: 'ds',
    data: {
        'TITLE': {
            'ReminderPagination': 'Antal påmindelser pr. side',
        },
        'FIELDS': {
            'ShowAll': 'Vis alle',
            'MarkAllDone': 'Gør alle "Færdig"',
        },
        'TABS': {
            'ReceivedToMe': 'Modtaget til mig',
        },
        'MESSAGES': {
            'NORECORD': 'Intet optage fundet',
            'NOREMINDER': 'Ingen påmindelse fundet'
        },
        'TOOTIPORTITLE':{
            'Edit':'Redigere',
            'Delete':'Slet',
            'Expand':'Udvide',
            'ChangeReminderStatus':'Marker Som Færdig'
        }
    }
}