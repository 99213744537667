import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatOption, MatDialog, MatExpansionPanel } from '@angular/material';
import { Router } from '@angular/router';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { RequestStatusProject } from 'app/main/model/RequestBase';
import { ProjectMaterial } from "app/main/model/Project"
import { ResponseBase } from 'app/main/model/ResponseBase';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { WorkhoursService } from 'app/main/pages/workHour/workhours.service';
import { AuthService } from 'app/core/services/auth.service';
import { ShiftType } from 'app/main/model/ProjectStatus';
import { WorkHourStatus, WorkHoursApproveLeaveType, PaymentOptionType } from 'app/main/model/WorkHourStatus';
import { WorkHour } from 'app/main/model/WorkHour';
import { projectRequisition } from 'app/main/model/Project';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { addDays } from 'date-fns';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';


@Component({
    selector: 'vibe-calender-edit-non-working-hour-popup',
    templateUrl: './edit-non-working-hour-popup.component.html',
    styleUrls: ['./edit-non-working-hour-popup.component.scss']
})
export class CalenderEditNonWorkingHourPopupComponent implements OnInit {
    startWeek: number = 1;
    projectInfo: any = {};
    requistionInfo: any = {};
    selectProject: string = "";
    coWorkers: any = [];
    projectCoWorkers: any = [];
    coWorkersFullData: any = [];
    showSpinner = false;
    createEventObj: any = {};
    shiftType = ShiftType;
    selectStatus = WorkHourStatus;
    selectLeaveType = WorkHoursApproveLeaveType;
    projectList: any = [];
    projectFullList: any = [];
    workHour = new WorkHour();
    RequisitionList: projectRequisition[] = [];
    currentselectedCoworkerObj: any = [];
    projectCoworkerObj: any = [];
    Requisition: projectRequisition;
    projectMaterials: ProjectMaterial[] = [];
    materialTotalPrice: number = 0;
    materialPersonalTotalPrice: number = 0;
    materialTotalPriceWithVAT: number = 0;
    hours: any;
    mins: any;
    isAddBreakTime: boolean = true;
    coWorkerList: any = [];
    isDisableWorkType = false;
    weekNumbers: any = [];
    isDisplayErrorMessage: boolean = false;
    isComingFromEditMode: boolean = false;
    workHourNotAddedOnCoworker: string = "";
    workHourId: string = "";
    //roleName: string;
    isFeatureDate: boolean = false;
    isPastDate: boolean = false;
    CoWorkerName: string = "";
    holidayList: any;
    tempEnddate: any;
    isDelete: boolean = false;
    paymentOption = PaymentOptionType;
    IsExpand: boolean = false;
    @ViewChild('allSelected', { static: false }) private allSelected: MatOption;
    planningDates: any = [];
    workHourList: any = new Array<WorkHour>();
    isCheckItemCount: number= 0;
    otherInfo:any;
    roleClaims: any;
    hasPermision: boolean = false;
    hasLeaveApprovePermision: boolean = false;
    //Initiators
    constructor(private translationLoader: FuseTranslationLoaderService,
        private _router: Router,
        private translate: TranslateService,
        private notificationsService: NotificationsService,
        private workhourservice: WorkhoursService,
        private authService: AuthService,
        private datePipe: DatePipe,
        private dialog: MatDialog,
        public dialogRef: MatDialogRef<CalenderEditNonWorkingHourPopupComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.translationLoader.loadTranslations(english, danish);
        this.roleClaims = this.authService.getRoleClaimsList();
    }

    ngOnInit() {
        //this.roleName = this.authService.getUserRole();
        this.authService.hasClaim(this.roleClaims.Planning_Leave_Approve).subscribe(hasClaim => {
            if (hasClaim) 
                this.hasLeaveApprovePermision = hasClaim
             else 
                this.hasLeaveApprovePermision = false;
        });
        this.isComingFromEditMode = this.data.isComingFromEditMode;
        this.projectInfo = this.data.projectInfo;
        this.holidayList = JSON.parse(localStorage.getItem("HolidayList"));
        this.createEventObj.coworkerId = this.projectInfo.coworkerId
        if (this.projectInfo.otherInfo) {
            var customDateObj = JSON.parse(this.projectInfo.otherInfo);
            this.otherInfo = customDateObj;
            this.createEventObj.startDate = customDateObj.StartDate;
            this.createEventObj.endDate = customDateObj.EndDate;
            if (customDateObj && customDateObj.PlanningDates)
                this.planningDates = customDateObj.PlanningDates.split(",");
            if (customDateObj.BunchId)
                this.getWorkHoursByBunchId(customDateObj.BunchId)
        }
        else {
            this.createEventObj.startDate = (this.projectInfo.startDate ? this.projectInfo.startDate : new Date());
        }
        if (new Date().setHours(0, 0, 0, 0) == new Date(this.createEventObj.startDate).setHours(0, 0, 0, 0)) {
            this.isPastDate = false;
        }
        else {
            this.isPastDate = moment(this.createEventObj.startDate, "YYYY-MM-DD").isBefore();
        }
        this.createEventObj.shift = this.projectInfo.shiftType;
        this.createEventObj.status = this.projectInfo.status;
        this.createEventObj.leaveStatus = this.projectInfo.leaveStatus;
        this.createEventObj.comment = this.projectInfo.comment;
        this.workHourId = this.projectInfo.workHourId;
        this.createEventObj.text = this.projectInfo.text;
        this.createEventObj.paymentOption = this.projectInfo.paymentOption
        if (this.isComingFromEditMode) {
            if (!this.projectInfo.otherInfo) {
                this.createEventObj.endDate = (this.projectInfo.endDate ? this.projectInfo.endDate : new Date());
            }
            this.currentselectedCoworkerObj = [this.projectInfo.coworkerId];
            this.workHour.fromStartHour = this.projectInfo.startHour;
            this.workHour.fromStartMinute = this.projectInfo.startMinute;
            this.workHour.fromEndHour = this.projectInfo.endHour;
            this.workHour.fromEndMinute = this.projectInfo.endMinute;
            this.isAddBreakTime = this.projectInfo.isPauseTime;
        }
        else {
            this.calculateEndDate();
            if (this.hasLeaveApprovePermision)
                this.createEventObj.leaveStatus = 1;
            //this.createEventObj.leaveStatus = 2;
            else {
                this.createEventObj.leaveStatus = 1;
                this.currentselectedCoworkerObj = [this.authService.getCoWorkerId()];
                this.createEventObj.coworkerId = this.authService.getCoWorkerId();
            }
        }
        this.coWorkers = this.data.coworkerList;
        this.coWorkersFullData = this.data.coworkerList;
        this.createEventObj.startWeekNumber = moment(this.createEventObj.startDate).week().toString();
        this.hours = [{ value: '00' }, { value: '01' }, { value: '02' }, { value: '03' }, { value: '04' }, { value: '05' }, { value: '06' }, { value: '07' }, { value: '08' },
        { value: '09' }, { value: '10' }, { value: '11' }, { value: '12' }, { value: '13' }, { value: '14' }, { value: '15' }, { value: '16' }, { value: '17' },
        { value: '18' }, { value: '19' }, { value: '20' }, { value: '21' }, { value: '22' }, { value: '23' }];

        this.mins = [{ value: '00' }, { value: '15' }, { value: '30' }, { value: '45' }]
        //this.mins = [{ key: '00', value: '00' }, { key: '25', value: '15' }, { key: '50', value: '30' }, { key: '75', value: '45' }]
        this.addWeeksNumber();
        var calandarDoubleClickData = JSON.parse(localStorage.getItem("doubleClickCalanderDetail"));
        if (calandarDoubleClickData) {
            this.currentselectedCoworkerObj = [calandarDoubleClickData.coWorkerId];
            this.createEventObj.coworkerId = this.currentselectedCoworkerObj.join().toString();
        }
    }

    DropDownSearchCoWorker(value) {
        var model = new RequestStatusProject();
        model.Index = 1;
        model.Name = value;
        this.coWorkers = this.coWorkersFullData.filter(it => {
            return it.name.toLowerCase().includes(model.Name.toLowerCase());
        });
    }
    getCoworkerOnClear(value) {
        if (!value) {
            this.DropDownSearchCoWorker(value)
        }
    }

    DropDownCoWorkerSelect(coworker) {
        this.createEventObj.coworkerId = this.currentselectedCoworkerObj.join().toString();
        this.DropDownSearchCoWorker('');
    }

    DropDownSearchProject(value) {
        var model = new RequestStatusProject();
        model.Index = 1;
        model.Name = value;
        this.coWorkers = this.projectFullList.filter(it => {
            return it.name.toLowerCase().includes(model.Name.toLowerCase());
        });
    }

    onFocusIn(event) {
        event.model.show();
    }

    saveTimer() {
        this.workHour.id = this.workHourId;
        this.workHour.workerId = this.currentselectedCoworkerObj.join().toString();
        this.workHour.isWorking = false;
        this.workHour.shiftType = this.createEventObj.shift;
        this.workHour.status = this.createEventObj.status;
        this.workHour.text = this.createEventObj.text;
        this.workHour.leaveStatus = this.createEventObj.leaveStatus;
        this.workHour.comment = this.createEventObj.comment;
        this.workHour.otherInfo = this.projectInfo.otherInfo;
        this.workHour.paymentOption = this.createEventObj.paymentOption;
        if (this.createEventObj.shift == 2) {
            this.workHour.from = "07:00";
            this.workHour.to = "14:30";
            this.workHour.Tid = 7.5;
            this.workHour.pauseTimer = 0.5;
            this.workHour.Hours = "7";
        }
        else if (this.createEventObj.shift == 3) {
            this.workHour.from = "07:00";
            this.workHour.to = "15:00";
            this.workHour.Tid = 8.00;
            this.workHour.pauseTimer = 0.5;
            this.workHour.Hours = "07.5";
        }
        else if (this.createEventObj.shift == 4) {
            this.workHour.from = this.workHour.fromStartHour + ":" + this.workHour.fromStartMinute;
            this.workHour.to = this.workHour.fromEndHour + ":" + this.workHour.fromEndMinute;
            var time = moment.utc(moment(this.workHour.to, "HH:mm").diff(moment(this.workHour.from, "HH:mm"))).format("HH:mm")
            var tidTime = moment(time, "HH:mm").format("mm");
            var tidHours = moment(time, "HH:mm").format("HH");
            if (tidTime != "00")
                time = tidHours + ":" + (Number(this.getMinute(tidTime))).toString();
            this.workHour.Tid = Number(time.replace(':', '.'));
            if (this.isAddBreakTime)
                this.workHour.pauseTimer = 0.5;
            else
                this.workHour.pauseTimer = 0;
            //this.workHour.Hours = time.replace(':', '.');
            this.workHour.Hours = (Number(time.replace(':', '.')) - this.workHour.pauseTimer).toString();
        }
        var startDate = moment(moment(this.createEventObj.startDate).format('YYYY/MM/DD'));
        var endDate = moment(moment(this.createEventObj.endDate).format('YYYY/MM/DD'));
        this.workHour.days = Math.abs(startDate.diff(endDate, 'days'));
        this.workHour.date = moment(this.createEventObj.startDate).format("YYYY-MM-DD")//this.datePipe.transform(this.createEventObj.startDate, "MM/dd/yyyy");
        var projectCoworkerObj = this.projectCoworkerObj;
        var newAddedCowkerList = this.currentselectedCoworkerObj.filter(function (item) {
            return !projectCoworkerObj.includes(item);
        })
        this.workHour.newAddedCoworkerIds = newAddedCowkerList ? newAddedCowkerList.join().toString() : "";
        if (this.workHour.id)
            this.updateWorkhourAPICall(this.workHour);
        else
            this.addWorkhourAPICall(this.workHour);
    }

    deleteTimer() {
        var message = "";
        var message1 = "";
        var message2 = "";
        var message3 = "";
        var deleteDates = this.workHourList.filter(s => s.isChecked == true).map(x=> moment(x.date).format("YYYY-MM-DD"));
        var startDate = moment( deleteDates[0]).format('DD.MM.YYYY'); //moment(this.createEventObj.startDate).format('DD.MM.YYYY');
        var endDate = moment(deleteDates[deleteDates.length - 1]).format('DD.MM.YYYY'); //moment(this.createEventObj.endDate).format('DD.MM.YYYY');

        this.translate.get("MESSAGES.DeleteNonWorkingHoursOne").subscribe({
            next: (res) => {
                message1 = res;  //+ 'den' + '' + ' ' + startDate + ' ' + 'til den' + ' ' + endDate;
            },
            error: err => {
            }
        });
        this.translate.get("MESSAGES.DeleteNonWorkingHoursTwo").subscribe({
            next: (res) => {
                message2 = res;
            },
            error: err => {
            }
        });
        this.translate.get("MESSAGES.DeleteNonWorkingHoursThree").subscribe({
            next: (res) => {
                message3 = res;
            },
            error: err => {
            }
        });
        message = message1 + ' ' + message2 + ' ' + startDate + ' ' + message3 + ' ' + endDate;

        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            data: { Header: message }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.isDelete = result;
                var deleteNonWorkingItems = {
                    id: this.workHourId,
                    deleteDates: deleteDates,
                    coWorkerID: this.createEventObj.coworkerId
                }
                this.dialogRef.close({
                    updateSchedule: false,
                    deleteSchedule: true,
                    goToProject: false,
                    projectId: this.projectInfo.sagId,
                    workHourId: this.workHourId,
                    dataYear: moment(this.createEventObj.startDate).year().toString(),
                    deleteNonWorkingItems: deleteNonWorkingItems
                });
            }
        });


    }

    goToProject() {
        this.dialogRef.close({
            updateSchedule: false,
            deleteSchedule: false,
            goToProject: true,
            projectId: this.projectInfo.sagId,
            workHourId: this.workHourId,
        });
    }

    addWorkhourAPICall(workHour) {
        this.showSpinner = true;
        this.workhourservice.AddWithDateRange<ResponseBase>(workHour)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.Add").subscribe({
                        next: (res) => {
                            this.notificationsService.success(res);
                            this.dialogRef.close({
                                updateSchedule: true,
                                deleteSchedule: false,
                                goToProject: false,
                                projectId: this.projectInfo.sagId,
                                workHourId: this.workHourId,
                                dataYear: moment(this.createEventObj.startDate).year().toString(),
                                weekNumber: moment(this.createEventObj.startDate).week().toString(),
                            });
                        },
                        error: err => {
                        }
                    });
                },
                error: err => {
                    this.showSpinner = false;
                    //this.workHourNotAddedOnCoworker = err.error.error.result.coworkerName;
                    this.isDisplayErrorMessage = true;
                    this.translate.get("MESSAGES.Some_Coworker_Add_Error").subscribe({
                        next: (res) => {
                            this.notificationsService.warn(res);
                        },
                        error: err => {
                        }
                    });
                },
                complete: () => {
                }
            });
    }

    updateWorkhourAPICall(workHour) {
        this.showSpinner = true;
        this.workhourservice.UpdateWithDateRange<ResponseBase>(workHour)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.Add").subscribe({
                        next: (res) => {
                            this.notificationsService.success(res);
                            this.dialogRef.close({
                                updateSchedule: true,
                                deleteSchedule: false,
                                goToProject: false,
                                projectId: this.projectInfo.sagId,
                                workHourId: this.workHourId,
                                dataYear: moment(this.createEventObj.startDate).year().toString(),
                                weekNumber: moment(this.createEventObj.startDate).week().toString(),
                            });
                        },
                        error: err => {
                        }
                    });
                },
                error: err => {
                    this.showSpinner = false;
                    this.workHourNotAddedOnCoworker = err.error.error.result.coworkerName;
                    this.isDisplayErrorMessage = true;
                    this.translate.get("MESSAGES.Some_Coworker_Add_Error").subscribe({
                        next: (res) => {
                            this.notificationsService.warn(res);
                        },
                        error: err => {
                        }
                    });
                },
                complete: () => {
                }
            });
    }

    calculateEndDate() {
        if (!this.isComingFromEditMode) {
            if (this.createEventObj.status == 2 || this.createEventObj.status == 3
                || this.createEventObj.status == 4 || this.createEventObj.status == 5 || this.createEventObj.status == 8) {
                this.createEventObj.endDate = this.createEventObj.startDate;
            }
            else {
                let tempStartDate: Date = new Date(moment().isoWeek(this.createEventObj.startWeekNumber || 1).startOf("week").format("YYYY-MM-DD"));
                const endDate: Date = (this.createEventObj.status == 6 || this.createEventObj.status == 7 || this.createEventObj.status == 9) ? this.createEventObj.startDate : addDays(new Date(tempStartDate.getTime()), 6);
                this.createEventObj.endDate = endDate;
                //this.createEventObj.endDate = moment(endDate).format("DD.MM.YYYY");
            }
            if (this.createEventObj.status == 2 || this.createEventObj.status == 4 || this.createEventObj.status == 5) {
                var days = moment(this.createEventObj.startDate).day();
                if (days == 5)
                    this.createEventObj.shift = 2;
                else
                    this.createEventObj.shift = 3;
            }
            this.calEndDate();
        }
    }

    onSelectStatus() {
        if (this.createEventObj.status == 8)
            this.createEventObj.paymentOption = 1;
        else
            this.createEventObj.paymentOption = null;
    }

    onChange(args) {
        if (args.value) {
            // if (args.value.getDay() == 0 || args.value.getDay() == 6) {
            //     var days = args.value.getDay() == 0 ? 1 : 2;
            //     this.createEventObj.startDate = new Date(args.value.setDate(args.value.getDate() + days));
            // }
            // else
            this.createEventObj.startDate = args.value;
            if (new Date().setHours(0, 0, 0, 0) == new Date(this.createEventObj.startDate).setHours(0, 0, 0, 0)) {
                this.isPastDate = false;
            }
            else {
                this.isPastDate = moment(this.createEventObj.startDate, "YYYY-MM-DD").isBefore();
            }
            this.createEventObj.startWeekNumber = moment(this.createEventObj.startDate).week().toString();
            this.calculateEndDate();
        }
    }

    onChangeEndDate(args) {
        this.createEventObj.endDate = args.value;
        //this.tempEnddate = args.value;
        this.calEndDate();
    }

    calEndDate() {
        this.workHour.nonWorkingDayList = [];
        //var endDate = this.tempEnddate;
        var endDate = new Date(new Date(this.createEventObj.endDate).getFullYear(),
            new Date(this.createEventObj.endDate).getMonth(), new Date(this.createEventObj.endDate).getDate(), 0, 0, 0, 0);

        var startDateCal = new Date(new Date(this.createEventObj.startDate).getFullYear(),
            new Date(this.createEventObj.startDate).getMonth(), new Date(this.createEventObj.startDate).getDate(), 0, 0, 0, 0);

        for (let day = startDateCal; day <= endDate; day.setDate(day.getDate() + 1)) {
            var tempDate = moment(day).format("DD.MM.YYYY");
            var getHoliday = this.holidayList.find(o => moment(o.date).format("DD.MM.YYYY") == tempDate);
            // if (day.getDay() == 0 || day.getDay() == 6) {
            //     continue;
            // }
            if (!getHoliday || getHoliday.length == 0) {
                this.workHour.nonWorkingDayList.push(moment(day).format("YYYY-MM-DD"));
            }
            //  else if (getHoliday || getHoliday.length > 0) {
            //     endDate = new Date(endDate.setDate(endDate.getDate() + 1)); //new Date(new Date(endDate).setDate(new Date(endDate).getDate() + 1));
            // }
        }
        this.createEventObj.endDate = endDate;
        //this.createEventObj.endDate = moment(new Date(endDate)).format("DD.MM.YYYY");
    }

    getMinute(value) {
        if (value == "00")
            return "00";
        if (value == "15")
            return "25";
        if (value == "30")
            return "50";
        if (value == "45")
            return "75";
    }

    CheckEndHour(checkMinute) {
        this.workHour.fromEndHour = (this.workHour.fromEndHour < this.workHour.fromStartHour) == true ? (this.workHour.fromEndHour = this.workHour.fromStartHour) : this.workHour.fromEndHour;
    }

    addWeeksNumber() {
        this.weekNumbers = [];
        for (var i = 1; i < 54; i++) {
            this.weekNumbers.push({ value: i.toString() });
        }
    }

    DropDownWeekNumberSelect() {
        this.createEventObj.startDate = moment().isoWeek(this.createEventObj.startWeekNumber || 1).startOf("week").format("DD.MM.YYYY");
        this.calculateEndDate();
    }

    _handleKeydown(event: KeyboardEvent) {
        if (event.keyCode === 13) {
            event.stopPropagation();
        }
    }

    getWorkHoursByBunchId(bunchId) {
        this.workhourservice.GetWorkHoursByBunchId<ResponseBase>(bunchId)
            .subscribe((response: ResponseBase) => {
                if (response) {
                    this.workHourList = response.result;
                    this.workHourList.filter(x => {
                        x.isChecked = false;
                        if (x.shiftType == 2)
                            x.shiftTypeName = "selectedShiftOption.value1";
                        if (x.shiftType == 3)
                            x.shiftTypeName = "selectedShiftOption.value2";
                        if (x.shiftType == 4) {
                            x.fromStartHour = moment(x.from, "HH:mm").format("HH");
                            x.fromStartMinute = moment(x.from, "HH:mm").format("mm");
                            x.fromEndHour = moment(x.to, "HH:mm").format("HH");
                            x.fromEndMinute = moment(x.to, "HH:mm").format("mm");
                            x.shiftTypeName = x.from + ' til ' + x.to;
                        }
                        if (new Date().setHours(0, 0, 0, 0) == new Date(x.date).setHours(0, 0, 0, 0)) {
                            x.isPastDate = false;
                            x.isChecked = true;
                        }
                        else{
                            x.isPastDate = moment(x.date, "YYYY-MM-DD").isBefore();
                            if(!x.isPastDate)
                                x.isChecked = true;
                        }
                    })
                    const isPastCount = this.workHourList.filter(x => x.isPastDate == true).length;
                    this.workHourList = this.workHourList.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
                    this.isCheckItemCount = this.workHourList.filter(x => x.isChecked == true).length;
                    if (isPastCount > 0) {
                        this.isPastDate = true;
                    } else {
                        this.isPastDate = false;
                    }
                }
            },
                error => (err) => { },
                () => { });
    }

    getWeekName(date) {
        return moment(date, "YYYY-MM-DD").format("ddd")
    }

    // Code to Prevent accordion from expantion
    expandPanel(matExpansionPanel: MatExpansionPanel, event: Event): void {
        event.stopPropagation(); // Preventing event bubbling
        if (!this.IsExpand) {
            if (!this._isExpansionIndicator(event.target)) {
                matExpansionPanel.close(); // Here's the magic
            }
        }
    }

    private _isExpansionIndicator(target: EventTarget): boolean {
        const expansionIndicatorClass = 'mat-expansion-indicator';
        return ((<HTMLElement>target).classList && (<HTMLElement>target).classList.contains(expansionIndicatorClass));
    }

    onCheckScheduleDay(value, index, item) {
        item.isChecked = !value;
        this.workHourList.filter(s => s.isChecked == true).reduce((sum, current) => sum + parseFloat(current.hours), 0)
    }
}
