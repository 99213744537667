
export const WorkHourStatus: any[] = [
  { id: 1, key: 'selectedWorkHourStatus.value1', isWorking: false },
  { id: 2, key: 'selectedWorkHourStatus.value2', isWorking: false },
  { id: 3, key: 'selectedWorkHourStatus.value3', isWorking: false },
  { id: 4, key: 'selectedWorkHourStatus.value4', isWorking: false },
  { id: 5, key: 'selectedWorkHourStatus.value5', isWorking: false },
  { id: 6, key: 'selectedWorkHourStatus.value6', isWorking: false },
  { id: 7, key: 'selectedWorkHourStatus.value7', isWorking: true },
  { id: 8, key: 'selectedWorkHourStatus.value8', isWorking: true },
  { id: 9, key: 'selectedWorkHourStatus.value9', isWorking: true }
]

export const WorkHoursType: any[] = [
  { id: 1, key: 'selectedWorkHoursTypeOption.value1' },
  { id: 2, key: 'selectedWorkHoursTypeOption.value2' },
  { id: 3, key: 'selectedWorkHoursTypeOption.value3' },
]

export const WorkHoursApproveLeaveType: any[] = [
  { id: 1, key: 'selectedWorkHoursApproveLeaveOption.value1' },
  { id: 2, key: 'selectedWorkHoursApproveLeaveOption.value2' },
  { id: 3, key: 'selectedWorkHoursApproveLeaveOption.value3' },
]

export const PaymentOptionType: any[] = [
  { id: 1, key: 'paymentOptionType.value1' },
  { id: 2, key: 'paymentOptionType.value2' },
]

export const RatesType: any[] = [
  { id: 1, key: 'ratesType.valueA', value: 'A', status: null },
  { id: 2, key: 'ratesType.valueB', value: 'B', status: null },
  { id: 3, key: 'ratesType.valueC', value: 'C', status: null },
  { id: 4, key: 'ratesType.valueJ', value: 'J', status: 2 },

  { id: 5, key: 'ratesType.valueK', value: 'K', status: 1 },
  { id: 6, key: 'ratesType.valueL', value: 'L', status: 3 },
  { id: 7, key: 'ratesType.valueM', value: 'M', status: 4 },
  { id: 8, key: 'ratesType.valueN', value: 'N', status: 5 },
  { id: 9, key: 'ratesType.valueO', value: 'O', status: 6 },
  { id: 12, key: 'ratesType.valueR', value: 'R', status: 7 },
  { id: 13, key: 'ratesType.valueH', value: 'H', status: 10 },
  // { id: 10, key: 'ratesType.valueP', value: 'P', status: 8 },
  // { id: 11, key: 'ratesType.valueQ', value: 'Q', status: 9 },
]

export const EmploymentType: any[] = [
  { id: 1, key: 'employmentType.valueA', value: 'A', status: null },
  { id: 2, key: 'employmentType.valueC', value: 'C', status: null },
  { id: 3, key: 'employmentType.valueD', value: 'D', status: null }
]

