import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxMaskModule } from 'ngx-mask';
import { SharedModule } from 'app/core/modules/shared.module';
import { ReportComponent } from './report.component';
import { MaterialReportComponent } from './component/material/material.component';
import { ReportService } from './report.service';
//import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { CustomerReportComponent } from './component/customer/customer.component';
import { CoworkerReviewAnswerComponent } from './component/coworker-review-answer/coworker-review-answer.component';
import { CoworkerReviewRatingChartComponent } from './component/coworker-review-rating-chart/coworker-review-rating-chart.component';
import { ViewAnswerCoworkersComponent } from './component/view-answer-coworkers/view-answer-coworkers.component';
import { CoworkerOfferReviewAnswerComponent } from './component/coworker-offer-review-answer/coworker-offer-review-answer/coworker-offer-review-answer.component';
import { CoworkerWorkhoursReportComponent } from './component/cowrker-workhours-report/cowrker-workhours-report.component';

const routes = [
  {
    path: 'report',
    component: ReportComponent
  }
];

@NgModule({
  declarations: [
    ReportComponent,
    MaterialReportComponent,
    CustomerReportComponent,
    CoworkerReviewAnswerComponent,
    CoworkerReviewRatingChartComponent,
    ViewAnswerCoworkersComponent,
    CoworkerOfferReviewAnswerComponent,
    CoworkerWorkhoursReportComponent
  ],
  imports: [
    //PDFExportModule,
    SharedModule,
    RouterModule.forChild(routes),
    NgxMaskModule.forRoot(),
    ScrollingModule,
    TableVirtualScrollModule,
    NgxChartsModule,
    DatePickerModule
    //AgmCoreModule.forRoot({
    //    apiKey: 'AIzaSyB2LPLAZRlRAHndaGDzsuXpBrUdzivMqYQ',
    //  libraries: ["places"]
    //})
  ],
  exports: [
    ReportComponent,
    MaterialReportComponent,
    CustomerReportComponent,
    CoworkerReviewAnswerComponent,
    CoworkerReviewRatingChartComponent,
    ViewAnswerCoworkersComponent,
    CoworkerWorkhoursReportComponent
  ],
  entryComponents:[ViewAnswerCoworkersComponent],
  providers: [
    ReportService]
})

export class ReportModule {
}
