import { Component, Input, Output, EventEmitter, ViewChild, OnInit } from '@angular/core';
import { PageEvent, MatPaginator } from '@angular/material';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { FuseTranslationLoaderService } from '../../../core/services/translation-loader.service';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html'
})

export class PaginationComponent implements OnInit {
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @Input() pagination: any;
  @Input() paginationTitle: any;
  @Output() paginationEvent = new EventEmitter<any>();
  @Input() itemsPerPageLabel: string;
  @Input() nextPageLabel: string;
  @Input() previousPageLabel: string;
  constructor(private translationLoader: FuseTranslationLoaderService) {
    this.translationLoader.loadTranslations(english, danish);
  }

  ngOnInit() {

  }

  length = 100;
  pageSize = 10;
  pageSizeOptions: number[] = [10,24,50,100,500];

  // MatPaginator Output
  pageEvent: PageEvent;

    Change(event) {
        this.pageEvent = event;
    event.pageIndex += 1; 
    this.paginationEvent.emit(event);
  }

  ngAfterViewInit() {
    if (this.paginationTitle) 
      this.paginator._intl.itemsPerPageLabel = this.paginationTitle;
    else
      this.paginator._intl.itemsPerPageLabel = "Antal items pr. side";
    //this.paginator._intl.nextPageLabel = this.nextPageLabel;
    //this.paginator._intl.previousPageLabel = this.previousPageLabel;
  }

  //[itemsPerPageLabel]="'FILTERS.PAGELABEL'| translate" [nextPageLabel]="'FILTERS.NEXT'| translate" [previousPageLabel]="'FILTERS.PREVIOUS'| translate"

}
