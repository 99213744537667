import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatOption } from '@angular/material';
import { CompanyInfoSetting } from 'app/main/model/CompanyInfo';
import { EmailSendingParam } from 'app/main/model/common';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormService } from 'app/core/services/form.service';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { TextEditorEmailSendService } from './text-editor-email-send.service';
import { AuthService } from 'app/core/services/auth.service';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { CkeditorConfig } from 'app/constant/common.constant';
import { RequestEmailSendingCoWorker } from 'app/main/model/RequestBase';
import { CoworkerEmailSendDTO } from 'app/main/model/CoWorker';
import { CommonService } from 'app/core/services/common.service';
import { OutdoorFileList } from 'app/main/model/ProjectOffer';

@Component({
    selector: 'app-text-editor-email-send',
    templateUrl: './text-editor-email-send.component.html',
    styleUrls: ['./text-editor-email-send.component.scss'],
})
export class TextEditorEmailSendComponent implements OnInit {
    EmailSendingCoworkerList: CoworkerEmailSendDTO[] = [];
    emailForm: FormGroup;
    public editor;
    public editorContent = "";
    emailBody: string = "";
    isLoadDefaultEmailTemplate: boolean = true;
    comapnyInfo: CompanyInfoSetting;
    emailSendingParam: EmailSendingParam;
    isEmail: boolean;
    isDisplayEmailFeild: boolean = false;
    fullEmail: string = "";
    emailBodyObj: any = {};
    coWokers = [];
    displayCoworkerList: boolean = false;
    CoWorkerName: string = "";
    selectedcoWorkerObj = [];
    staticCoworkerList = [];
    DisplayAttachement: boolean = false;
    wallMaterialId: string;
    loginUserName: string = "";
    loginUserEmailId: string = "";
    showSpinner: boolean = false;
    @ViewChild('allSelected', { static: true }) private allSelected: MatOption;
    public editorOptions = {
        placeholder: "insert content..."
    };
    config = CkeditorConfig;
    isLoadSupplierEmailTemplate: boolean = true;
    outdoorFileList: OutdoorFileList[] = [];
    imageCount: number = 0;
    previews: string[] = [];
    files: any[] = [];
    UserName:any;
    UserEmail:any;
    localResponseModel: any;
    loginUserId: string = "";
    constructor(public dialogRef: MatDialogRef<TextEditorEmailSendComponent>,
        private formBuilder: FormBuilder,
        private formService: FormService,
        private textEditorEmailSendService: TextEditorEmailSendService,
        private commonService: CommonService,
        private authService: AuthService,
        private notificationsService: NotificationsService,
        private translate: TranslateService,
        @Inject(MAT_DIALOG_DATA) public data: any, public translationLoader: FuseTranslationLoaderService) {
        this.translationLoader.loadTranslations(english, danish);
    }

    onEditorBlured(quill) {
    }

    onEditorFocused(quill) {
    }

    onEditorCreated(quill) {
        this.editor = quill;
    }

    onContentChanged({ quill, html, text }) {
    }

    ngOnInit() {
        this.loginUserId = this.authService.getCoWorkerId();
        this.localResponseModel = JSON.parse(localStorage.getItem('LocalResponseModel'));
        let coworker = this.localResponseModel ? this.localResponseModel.Coworkers.find(coworker => coworker.id === this.loginUserId):null;
        if(coworker){
            this.UserName = coworker.name;
            this.UserEmail = coworker.email;
        }
        this.config.height = 350;
        this.emailSendingParam = this.data;
        this.loginUserName = this.UserName;
        this.loginUserEmailId = this.UserEmail;
        this.staticCoworkerList = this.emailSendingParam.coworkerList;
        this.isLoadDefaultEmailTemplate = this.emailSendingParam.isLoadDefaultEmailTemplate;
        this.isLoadSupplierEmailTemplate = this.emailSendingParam.isLoadSupplierEmailTemplate;
        this.files = this.emailSendingParam.attachmentFiles;
        if (this.emailSendingParam.coworkerList) {
            this.coWokers = this.emailSendingParam.coworkerList;
        }
        else {
            var tempList = this.commonService.getResponseForCoworker();
            if (tempList) {
                this.coWokers = tempList.filter(x => x.doNotShowOnPlanReminderProj == false);
            }
        }

        if (this.data.displayCoworkerList) {
            this.coWokers = this.emailSendingParam.coworkerList;
            this.staticCoworkerList = this.emailSendingParam.coworkerList;
            this.displayCoworkerList = this.data.displayCoworkerList;
        }
        if (this.data.selectedCoworker != undefined && this.data.selectedCoworker != "") {
            this.emailSendingParam.to = [];
            this.emailSendingParam.to.push(this.data.selectedCoworker);

        }
        else {
            var email = this.emailSendingParam.to;
            this.emailSendingParam.to = [];
            this.emailSendingParam.to.push(email);
        }

        if (this.isLoadDefaultEmailTemplate) {
            this.LoadDefaultEmailTemplate();
        }
        if (this.isLoadSupplierEmailTemplate) {
            this.LoadSupplierEmailTemplate();
        }
        else {
            this.emailSendingParam.body = this.emailSendingParam.body;
        }

        this.emailForm = this.formBuilder.group({
            //toName: [null, Validators.required],
            to: [null, Validators.compose([Validators.email, Validators.maxLength(80)])],
            fromName: [null, Validators.required],
            from: [null, Validators.compose([Validators.email, Validators.maxLength(80)])],
            subject: [null, Validators.required],
            sendAttachement: [null],
        });
        this.GetEmailSendingCoworkerList();
    }

    LoadDefaultEmailTemplate() {
        // Call service and get email template
        this.showSpinner = true;
        this.textEditorEmailSendService.GetCustomerEmailTemplate().subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                var emailTemplate = response.result.body;
                this.emailSendingParam.body = emailTemplate.replace('@Text', this.emailSendingParam.text ? this.emailSendingParam.text : "")
                    .replace('@SendersName', this.loginUserName)
                    .replace('@SendersEmailId', this.loginUserEmailId);

                //this.emailBody = emailTemplate;
            },
            error: err => {
                this.showSpinner = false;
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            }
        });
    }

    GetEmailSendingCoworkerList() {
        this.EmailSendingCoworkerList = [];
        var req = new RequestEmailSendingCoWorker();
        req.Index = 1;
        req.Limit = 100;
        req.IsActive = true;
        req.IsDeleted = false;
        this.showSpinner = true;
        this.commonService.GetEmailSendingCoworkerList(req).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                this.EmailSendingCoworkerList = [];
                if (response.result != null) {
                    response.result.filter(o => {
                        var emailsendCow = new CoworkerEmailSendDTO();
                        emailsendCow.id = o.id;
                        emailsendCow.name = o.name;
                        emailsendCow.email = o.email;
                        emailsendCow.phone = o.phone;
                        emailsendCow.mobile = o.mobile;
                        emailsendCow.sendEmailId = o.sendEmailId;
                        emailsendCow.sendEmailPassword = o.sendEmailPassword;
                        this.EmailSendingCoworkerList.push(emailsendCow);
                    });
                    const fromEmail = this.authService.getSMTPEmail();
                    var emailsendCow = new CoworkerEmailSendDTO();
                    emailsendCow.id = "1";
                    emailsendCow.name = fromEmail;
                    emailsendCow.email = fromEmail;
                    emailsendCow.phone = fromEmail;
                    emailsendCow.sendEmailId = fromEmail;
                    this.EmailSendingCoworkerList.push(emailsendCow);

                    var loginCoworkerID = this.authService.getCoWorkerId();
                    var loginCoworker = this.EmailSendingCoworkerList.find(o => o.id == loginCoworkerID);
                    if (loginCoworker != null) {
                        this.emailSendingParam.from = loginCoworker.sendEmailId;
                    }
                    else {
                        this.emailSendingParam.from = fromEmail;
                    }
                }
                
            },
            error: err => {
                this.showSpinner = false;
            }
        });
    }

    toggleAllSelection() {
        if (this.allSelected.selected) {
            this.emailSendingParam.to = [];
            this.emailSendingParam.to.push(0);
            for (var i = 0; i < this.coWokers.length; i++) {
                this.emailSendingParam.to.push(this.coWokers[i].email);
            }
        } else {
            this.emailSendingParam.to = [];
        }
    }

    DropDownCoWorkerSelect(coworker) {
        this.emailSendingParam.to = coworker.email; //this.emailSendingParam.to.filter(o => o != 0);
    }
    DropDownSearchCoWorker(coworker) {
        this.coWokers = this.staticCoworkerList.filter(it => {
            return it.name.toLowerCase().includes(coworker.toLowerCase());
        });
    }

    save(event) {
    }

    sendEmail() {
        var email = this.emailSendingParam.to;
        let isArrayresult = Array.isArray(this.emailSendingParam.to);
        if(!isArrayresult){
            this.emailSendingParam.to = [];
            this.emailSendingParam.to.push(email);
        }
        this.emailSendingParam.attachmentFiles = this.files;
        var selectedCow=this.EmailSendingCoworkerList.find(o => o.sendEmailId == this.emailSendingParam.from);
        if (selectedCow) {
            this.emailSendingParam.fromName = selectedCow.name;
        }
        this.dialogRef.close(this.emailSendingParam);
    }

    colseTextEditor() {
        this.dialogRef.close();
    }

    LoadSupplierEmailTemplate() {
        this.showSpinner = true;
        this.textEditorEmailSendService.GetSupplierEmailTemplate().subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                var emailTemplate = response.result.body;
                this.emailSendingParam.body = emailTemplate.replace('@Text', this.emailSendingParam.text ? this.emailSendingParam.text : "")
                    .replace('@SendersName', this.loginUserName)
                    .replace('@SendersEmailId', this.loginUserEmailId);

                //this.emailBody = emailTemplate;
            },
            error: err => {
                this.showSpinner = false;
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            }
        });
    }

    /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files.splice(index, 1);
  }


  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    for (const item of files) {  
        const reader = new FileReader();
            reader.onload = (e: any) => {
            item.base64 = e.target.result.split(',')[1];
            item.fileName = item.name;
            item.fileType = item.type;
            this.files.push(item);
        };
        reader.readAsDataURL(item);
    }
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  changeIsDisplayPersonalPrice(value){
    this.emailSendingParam.isDisplayPersonalPrice=!value;
  }
}
