export const locale = {
    lang: 'ds',
    data: {
        'FIELDS': {
            'startDate': 'Start dato ',
            'visit': 'Besøg',
            'Coworker': 'Medarbejdere',
        },
        'BUTTONS': {
            'BACK': 'Tilbage',
            'SAVE': 'Gem',
            'Undo':'Fortryd'
        },
    }
};

