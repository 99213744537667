import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiConstant } from 'app/core/constant/ApiConstant';
import { Observable } from 'rxjs';
import { BaseRequest, RequestForDeviceTripList } from 'app/main/model/RequestBase';

@Injectable({
    providedIn: 'root'
})

export class NanolinkService {
    private actionNanolinkUrl: string;

    constructor(private http: HttpClient) {
        this.actionNanolinkUrl = ApiConstant.API_URL + ApiConstant.NANOLINK + "/";
    }

    public GetParams(param) {
        let params: HttpParams = new HttpParams();
        for (var key in param) {
            if (param.hasOwnProperty(key)) {
                let val = param[key];
                params = params.append(key, val);
            }
        }
        return params;
    }

    public GetSettingsValue<T>(param1: string, param2: string, param3: string): Observable<T> {
        var url = "";
        if (param1) {
            url += "param1=" + param1;
        }
        if (param2) {
            url += "&param2=" + param2;
        }
        if (param3) {
            url += "&param3=" + param3;
        }
        return this.http.get<T>(this.actionNanolinkUrl + "getSettings?" + url);
    }

    public GetSettingsWithMupltipleKeys<T>(param1: string): Observable<T> {
        var url = "";
        if (param1) {
            url += "?param1=" + param1;
        }
        return this.http.get<T>(this.actionNanolinkUrl + "getSettingsWithMupltipleKeys" + url);
    }

    public GetAssetsById<T>(id: any): Observable<T> {
        return this.http.get<T>(this.actionNanolinkUrl+ "GetAssetsById/" + id);
    }

    public AddAssetes<T>(assets): Observable<T> {
        return this.http.post<T>(this.actionNanolinkUrl + "CreateAssets", assets);
    }

    public UpdateAssetes<T>(id, assets): Observable<T> {
        return this.http.put<T>(this.actionNanolinkUrl + "UpdateAssets/" + id, assets);
    }

    public GetAssetsList<T>(baseRequest: any): Observable<T> {
        let search = this.GetParams(baseRequest);
        return this.http.get<T>(this.actionNanolinkUrl + "GetAssetsList", {
            params: search
        });
    }

    public DeleteAssets<T>(id): Observable<T> {
        return this.http.delete<T>(this.actionNanolinkUrl + "DeleteAssets/" + id);
    }

    public GetDeviceById<T>(id: any): Observable<T> {
        return this.http.get<T>(this.actionNanolinkUrl+ "GetDeviceById/" + id);
    }

    public AddDevice<T>(assets): Observable<T> {
        return this.http.post<T>(this.actionNanolinkUrl + "CreateDevice", assets);
    }

    public UpdateDevice<T>(id, assets): Observable<T> {
        return this.http.put<T>(this.actionNanolinkUrl + "UpdateDevice/" + id, assets);
    }

    public GetDeviceList<T>(baseRequest: any): Observable<T> {
        let search = this.GetParams(baseRequest);
        return this.http.get<T>(this.actionNanolinkUrl + "GetDeviceList", {
            params: search
        });
    }

    public GetDeviceTripInfoList<T>(requestForDeviceTripList: RequestForDeviceTripList): Observable<T> {
        let search = this.GetParams(requestForDeviceTripList);
        return this.http.get<T>(this.actionNanolinkUrl + "GetDeviceTripInfoList", {
            params: search
        });
    }

    public DeleteDevice<T>(id): Observable<T> {
        return this.http.delete<T>(this.actionNanolinkUrl + "DeleteDevice/" + id);
    }
}