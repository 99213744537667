import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as english } from '../../new-project/translate/en';
import { locale as danish } from '../../new-project/translate/ds';
import { MatDialog } from '@angular/material';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { ProjectService } from '../../project.service';
import { projectRequisition, Project, ProjectMaterialQuantity } from 'app/main/model/Project';
import { AuthService } from 'app/core/services/auth.service';
import { CommonService } from 'app/core/services/common.service';
import { QuickInvoiceMaterials, Material } from 'app/main/model/Material';
import { RequestCaseTask, RequestCustomer } from 'app/main/model/RequestBase';
import { MaterialComponent } from '../material/material.component';
import { PdfViewerModelComponent } from 'app/main/pages/pdf-viewer/pdf-viewer.component';
import { FilesObj } from 'app/main/model/DropBox';
import { ProjectInvoice, EmailSendingParam } from 'app/main/model/common';
import { TextEditorEmailSendComponent } from 'app/main/shared-component/text-editor-email-send/text-editor-email-send.component';
import { CompanyInfoService } from 'app/main/pages/companyInfo/companyInfo.service';
import { CustomersService } from 'app/main/pages/customer/customer.service';
import { Customer } from 'app/main/model/Customer';
import { BehaviorSubject } from 'rxjs';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';

@Component({
  selector: 'vibe-ProjectRequisitionMaterial',
  templateUrl: './projectRequisitionMaterial.component.html',
  styleUrls: ['./projectRequisitionMaterial.component.scss']
})

export class ProjectRequisitionMaterialComponent implements OnInit {
  showSpinner: boolean = false;
  @Input() RequisitionDetail: any;
  @Input() projectNumber: any;
  @Output() updateRequisition = new EventEmitter<any>();
  @Output() closeAddMaterialPopup = new EventEmitter<any>();
  IsAdmin: boolean = false;
  //roleName: string;
  Requisition: projectRequisition;
  projectMaterials: QuickInvoiceMaterials[] = [];
  ObjProject = new Project();
  CustomerObject: Customer;
  currentselectedMatObj: Material;
  materialTotalPrice: number = 0;
  materialPersonalTotalPrice: number = 0;
  materialTotalPriceWithVAT: number = 0;
  showMessage: boolean = false;
  editedProjectMaterialObj: any = {};
  fileObjList: Array<FilesObj>;
  isPrintInvoice: boolean = false;
  Customers: any[];
  ReqOfferPriceExclVATPercentage: number;
  showDotAddWorkhoure: boolean = false;
  donotUpdateWorkHour: boolean = false;
  isOpenMaterialPopup: boolean = false;
  roleClaims: any;
  

  private _isOpenMaterial = new BehaviorSubject<any>(null);
     @Input()
     set isOpenMaterial(value) {
         this._isOpenMaterial.next(value);
     };
     get isOpenMaterial() {
         return this._isOpenMaterial.getValue();
     }

  constructor(private translate: TranslateService,
    private translationLoader: FuseTranslationLoaderService,
    private authService: AuthService,
    private dialog: MatDialog,
    private projectService: ProjectService,
    private companyInfoService: CompanyInfoService,
    private customersService: CustomersService,
    private commonService: CommonService,
  ) {
    this.translationLoader.loadTranslations(english, danish);
    this.roleClaims = this.authService.getRoleClaimsList();
  }

  ngOnInit() {
    this.Requisition = new projectRequisition;
    // this.roleName = this.authService.getUserRole();
    // if (this.roleName == "Admin") {
    //   this.showDotAddWorkhoure = true;
    // }
    this.ObjProject.sagNumber = this.projectNumber;
    if (this.RequisitionDetail) {
      this.Requisition = this.RequisitionDetail;
      if (this.Requisition.doNotUpdateHour == "T")
        this.Requisition.donotUpdateWorkHour = true;
      else
        this.Requisition.donotUpdateWorkHour = false;
      this.ObjProject.sagId = parseInt(this.Requisition.projectId);
      //this.refreshMaterialList();
      this.getSingleProject();
    }

     this._isOpenMaterial
     .subscribe(x => {
        this.isOpenMaterialPopup = x;
        if(this.RequisitionDetail)
          this.refreshMaterialList();
        // if(this.isOpenMaterialPopup){
        //   if(this.isLoadedMaterial)
        //     this.AddNewMaterials();
        //   else
        //     this.refreshMaterialList();
        // }
     });
  }


  getProjectMaterialList(modal) {
    this.showSpinner = true;
    this.projectService.GetProjectMaterialList<ResponseBase>(modal)
      .subscribe((response: ResponseBase) => {
        this.showSpinner = false;
        if (response) {
          this.projectMaterials = response.result;
          this.materialTotalPrice = 0;
          this.materialTotalPriceWithVAT = 0;
          this.materialPersonalTotalPrice = 0;
          this.calculateProjMaterialPriceTotal();
          this.calculateOfferPriceExclVAT();
          this.projectMaterials.filter(p => {
            if (p.quantity) {
              p.quantity = parseFloat(p.quantity.toFixed(2));
            }
          });
          // setTimeout(() => {
          //   this.ShowProjectInvoice();
          // }, 500);
          this.showMessage = this.projectMaterials.length > 0 ? false : true;
          if(this.isOpenMaterialPopup)
            this.AddNewMaterials();
        }
        else {
          this.showMessage = true;
        }
      },
        error => (err) => {
          this.showSpinner = false;
        },
        () => {
          this.showSpinner = false;
        });
  }

  refreshMaterialList() {
    let objMaterialProject = new RequestCaseTask();
    objMaterialProject.fileAssignmentId = this.Requisition.id;
    objMaterialProject.IsDeleted = false;
    this.getProjectMaterialList(objMaterialProject);
  }

  onSetDonotUpdateWorkhour(IsActive) {
    this.donotUpdateWorkHour = IsActive;
    this.projectService.UpdateProjectDonotAddWorkHour<ResponseBase>(this.Requisition.projectId, IsActive)
      .subscribe((response: ResponseBase) => {
        if (response) {
        }
      },
        error => (err) => {

        });
  }

  onCheckCaseOnHourly(value) {
    this.Requisition.isHourlyWage = !value;
    this.Requisition.hourlyWage = 0;
  }

  calculateProjMaterialPriceTotal() {
    this.materialTotalPriceWithVAT = 0;
    this.projectMaterials.forEach((e: any) => {
      if (!e.totalPrice) {
        e.totalPrice = this.getTotalPrice((e.quantity ? e.quantity : 0), e.retailPrice);
      }
      if (!e.personalPrisTotal) {
        e.personalPrisTotal = this.getTotalPrice((e.quantity ? e.quantity : 0), e.personalPrice);
      }
      this.materialTotalPrice += e.totalPrice;
      if (e.personalPrice && e.personalPrice != 0) {
        this.materialPersonalTotalPrice += e.personalPrisTotal;
      }
      else {
        e.personalPrice = 0;
      }

      if (e.totalPriceVAT) {
        this.materialTotalPriceWithVAT += e.totalPriceVAT;
      } else {
        var vat = (e.totalPrice * 25) / 100;
        this.materialTotalPriceWithVAT += (e.totalPrice + vat);
      }
    });
    this.calculateOfferPriceExclVAT();
  }

  getTotalPrice(qty, price) {
    if (price == undefined) {
      price = 0;
    }
    var isNegative = false;
    if (qty < 0)
      isNegative = true;
    if (price < 0)
      isNegative = true;
    var total = (qty * price);
    if (isNegative)
      total = -Math.abs(total)
    return total;
  }


  AddNewMaterials() {
    var sendData = {};
    sendData = {
      'RequisitionId': this.Requisition.id,
      'sagId': this.ObjProject.sagId,
      //'roleName': this.roleName,
      'indoorOutdoorStatusIds': this.ObjProject.indoorOutdoorStatusIds,
      'editedProjectMaterialObj': this.editedProjectMaterialObj,
      'projectMaterials': this.projectMaterials,
      'ObjProject': this.ObjProject,
      'Requisition': this.Requisition,
      'materialTotalPrice': this.materialTotalPrice,
      'materialTotalPriceWithVAT': this.materialTotalPriceWithVAT,
      'materialPersonalTotalPrice': this.materialPersonalTotalPrice,
      'discountPercent': this.Requisition.discountPercent,
    }

    var dialogRef = this.dialog.open(MaterialComponent, {
      height: '90%',
      width: '90%',
      disableClose: true,
      data: sendData
    });

    dialogRef.afterClosed().subscribe(result => {
      this.isOpenMaterialPopup = false;
      this.closeAddMaterialPopup.emit(this.isOpenMaterialPopup);
      if (result) {
        this.refreshMaterialList();
        this.editedProjectMaterialObj = {}
      }
    });
  }

  editProjectMaterial(id) {
    this.showSpinner = true;
    this.currentselectedMatObj = new Material();
    this.projectService.editProjectMaterial<ResponseBase>(id)
      .subscribe({
        next: (response: ResponseBase) => {
          this.showSpinner = false;
          this.editedProjectMaterialObj = response.result;
          this.currentselectedMatObj = response.result.material;
          this.showSpinner = false;
          if (this.editedProjectMaterialObj) {
            this.AddNewMaterials()
          }
        },
        error: err => {
          this.showSpinner = false;
        },
        complete: () => {

        }
      });
  }

  getRetailTotalProfit() {
    var totalIncludeEstimatePrice = this.Requisition.totalIncludeEstimatePrice;
    var materialTotalPriceWithVAT = ((((this.materialTotalPrice - ((this.materialTotalPrice * this.Requisition.discountPercent) / 100)) * 25) / 100) + (this.materialTotalPrice - ((this.materialTotalPrice * this.Requisition.discountPercent) / 100)));
    var isNegative = false;
    var total = 0;
    this.Requisition.isNegativeTotalProfit = false;
    if (materialTotalPriceWithVAT < 0) {
      total = (totalIncludeEstimatePrice - Math.abs(materialTotalPriceWithVAT));
    }
    else {
      total = (totalIncludeEstimatePrice - materialTotalPriceWithVAT);
    }
    if (total < 0) {
      isNegative = true;
      this.Requisition.isNegativeTotalProfit = true;
    }
    if (isNegative)
      total = -Math.abs(total)
    return total;
  }

  getPersonalTotalProfit() {
    var totalIncludeEstimatePrice = this.Requisition.totalIncludeEstimatePrice;
    var totalVatEstimatePrice = this.Requisition.totalVatEstimatePrice;
    var materialTotalPrice = (this.materialTotalPrice - ((this.materialTotalPrice * this.Requisition.discountPercent) / 100));
    var isNegative = false;
    var total = 0;
    if (materialTotalPrice < 0) {
      total = ((totalIncludeEstimatePrice - totalVatEstimatePrice) - Math.abs(materialTotalPrice));
    }
    else {
      total = ((totalIncludeEstimatePrice - totalVatEstimatePrice) - materialTotalPrice);
    }
    if (total < 0)
      isNegative = true;
    if (isNegative)
      total = -Math.abs(total)
    return total;
  }

  calculateOfferPriceExclVAT() {
    var PriceIncluded = (this.materialTotalPrice - ((this.materialTotalPrice * this.Requisition.discountPercent) / 100));
    var tempOfferPriceexcluded = this.Requisition.totalIncludeEstimatePrice - this.Requisition.totalVatEstimatePrice;
    this.Requisition.performedPercent = ((PriceIncluded / tempOfferPriceexcluded) * 100);
  }

  SendInvoice(element, isPrint) {
    const dialogRef = this.dialog.open(AlertMessageModelComponent, {
      height: '200px',
      data: { Header: 'Headers.ViewPersonalPriceMessage' }
    });

    dialogRef.afterClosed().subscribe(result => {
      var isDisplayPersonalPrice = result ? result : false;
      this.Requisition;
      this.isPrintInvoice = false;
      this.showSpinner = true;
      this.projectService.GetProjectInvoice<ResponseBase>(this.Requisition.projectId, this.Requisition.id, isDisplayPersonalPrice)
        .subscribe({
          next: (response: ResponseBase) => {
            var objInvoice = new ProjectInvoice();
            objInvoice.name = this.ObjProject.customerName;
            objInvoice.projectId = this.Requisition.projectId;
            objInvoice.projectNumber = this.ObjProject.sagNumber.toString();
            objInvoice.fileName = response.result.filename;
            objInvoice.invoiceBase64 = response.result.base64;
            objInvoice.requisitionId = this.Requisition.id;
            this.uploadProjectInvoiceToDropbox(objInvoice);

            this.isPrintInvoice = false;


            const dialogRef = this.dialog.open(PdfViewerModelComponent, {
              height: '90%',
              width: '90%',
              data: {
                base64: response.result.base64.replace("data:application/pdf;base64,", ""),
                filename: response.result.filename + ".pdf"
              }
            });

            dialogRef.afterClosed().subscribe(result => {
              if (result) {
              }
              this.fileObjList.filter(x => {
                if (x.checked) {
                  x.checked = false;
                }
              });
            });
          },
          error: err => {
            this.showSpinner = false;
          },
          complete: () => {
            this.isPrintInvoice = false;
            this.showSpinner = false;
          }
        });

    });
  }

  uploadProjectInvoiceToDropbox(objInvoice) {
    this.projectService.UploadProjectInvoiceToDropbox<ResponseBase>(objInvoice)
      .subscribe({
        next: (response: ResponseBase) => { },
        error: err => { },
        complete: () => {
          this.showSpinner = false;
        }
      });
  }

  sendEmailPopup(element) {
    var sagNumber = this.projectNumber
    var emailSendingParam = new EmailSendingParam();
    emailSendingParam.fromName = this.authService.getUserName();
    emailSendingParam.from = this.authService.getUserEmail();
    emailSendingParam.to = this.CustomerObject.email;
    emailSendingParam.toName = this.CustomerObject.name;
    emailSendingParam.isLoadDefaultEmailTemplate = true;
    emailSendingParam.displayAttachement = true;
    emailSendingParam.subject = "Invoice# " + sagNumber + " fra Vibe Solutions";
    emailSendingParam.body = "";
    const dialogRef = this.dialog.open(TextEditorEmailSendComponent, {
      height: '90%',
      width: '90%',
      data: emailSendingParam
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null && result != '') {
        this.isPrintInvoice = false;
        var that = this;
        var emailSending = new EmailSendingParam();
        emailSending = result;
        if (emailSending.sendAttachement) {
          this.projectService.GetProjectInvoice<ResponseBase>(this.Requisition.projectId, this.Requisition.id, emailSending.isDisplayPersonalPrice)
            .subscribe({
              next: (response: ResponseBase) => {
                emailSending.attachmentBase64 = response.result.base64.replace('data:application/pdf;base64,', '');
                emailSending.attachmentFileName = 'Projekt_Faktura#' + sagNumber + '.pdf';
                that.sendEmail(emailSending);
                that.isPrintInvoice = false;
              },
              error: err => {
                this.showSpinner = false;
              },
              complete: () => {
                this.showSpinner = false;
                that.isPrintInvoice = false;
              }
            });
        }
        else {
          that.sendEmail(emailSending);
          that.isPrintInvoice = false;
        }
      }
    });
  }

  sendEmail(emailSending) {
    this.showSpinner = true;
    this.companyInfoService.SendEmail<ResponseBase>(emailSending)
      .subscribe({
        next: (response: ResponseBase) => {
          this.showSpinner = false;
          this.commonService. checkEmailIsToBeSent();
        },
        error: err => {
          this.showSpinner = false;
        },
        complete: () => {
          this.showSpinner = false;
        }
      });
  }

  getSingleProject() {
    this.projectService.GetSingle<ResponseBase>(this.Requisition.projectId).subscribe({
      next: (response: ResponseBase) => {
        this.ObjProject = response.result;
        if (this.ObjProject.customerId) {
          this.getSingleCustomer(this.ObjProject.customerId);
        }
      },
      error: err => { },
      complete: () => { }
    });
  }

  getSingleCustomer(id) {
    this.customersService.GetSingle<ResponseBase>(id)
      .subscribe({
        next: (response: ResponseBase) => {

          this.CustomerObject = response.result;
        },
        error: err => { },
        complete: () => { }
      });
  }

  saveRequisition() {
    this.updateRequisition.emit(this.Requisition);
  }
}