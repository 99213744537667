export const locale = {
    lang: 'ds',
    data: {
        'FIELDS': {
            'number': 'Nummer',
            'text': 'Tekst',
            'm2200': '0-200 m2',
            'm2201': '201-500 m2',
            'm2500': '> 500 m2',
            'appendix': 'Tillæg',
            'm2': 'M2',
            'lb15': '15 cm',
            'lb30': '30 cm',
            'lb40': '40 cm',
            'lb60': '60 cm',
            'stk10': '0-10 stk.',
            'stk20': '11-20 stk.',
            'stk21': '> 21 stk.',
            'TreatmentType':'Behandlings type',
            'Min':'Min'
        },
        'BUTTONS':{
            'Add':'Tilføje',
            'BTNSAVE': 'Gem',
            'BACK': 'Tilbage',
        },
        'MESSAGES': {
            'NORECORD': 'Ingen registrering fundet',
            'NO_REMINDER_RECORD': 'Ingen påmindelser fundet',
            'NO_PROJECT_RECORD': 'Ingen Projekter fundet',
            'Created': 'Oprettet med succes.',
            'Updated': 'Opdateret med succes.',
            'Deleted': 'Slettet med succes.',
            'Failed': 'Noget gik galt !',
          },
    }
};