export const locale = {
    lang: 'en',
    data: {
        'TITLE': {
            'Header': 'General setup in the system',
        },
        'FIELDS': {
            'DutyscheduleName': 'Vagtplan - name',
            'DutyscheduleFromTo': 'duty schedule-show from-to if no times on duty schedule',
            'HolidayDayOffAccrual14': 'holiday off accrual 14 days paid',
            'VacationDayAccrualMonth': 'holiday off accrual monthly salary',
            'DrivingCurrency': 'km. rate',
            'GDayCurrency':'G-day rate',
            'DefaultDebtorProfit':'Std. customer profit',
            'CurrentSickDaysPeriod':'current sick days period',
            'VacationLastYear':'Last allocated vacation days',
            'MultimediaTreasure': 'Multimedia Treasure',
         },
        'VALIDATIONS': {
        },
        'MESSAGES': {
            'Saved': 'Saved successfully.',
        },
        'BUTTONS': {
            'Save': 'Save',
        }
    }
};