import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpParams, HttpClient } from '@angular/common/http';
import { ApiConstant } from 'app/core/constant/ApiConstant';
import { RequestReminder } from 'app/main/model/RequestBase';
import { Reminder } from 'app/main/model/Reminder';

@Injectable({
  providedIn: 'root'
})
export class ReminderService {
  private actionUrl: string;
  private apiUrl: string;

  constructor(private http: HttpClient) {
    this.apiUrl = ApiConstant.API_URL;
    this.actionUrl = ApiConstant.API_URL + ApiConstant.REMINDER + "/";
  }

  public Get<T>(RequestReminder: any): Observable<T> {
    let search = this.GetParams(RequestReminder);
    return this.http.get<T>(this.actionUrl, {
      params: search
    });
  }

  public GetSingle<T>(id: any): Observable<T> {
    return this.http.get<T>(this.actionUrl + id);
  }

  public Add<T>(reminder: any): Observable<T> {
    return this.http.post<T>(this.actionUrl, reminder);
  }

  public Update<T>(id: any, reminder: Reminder): Observable<T> {
    return this.http.put<T>(this.actionUrl + id + '/update', reminder);
  }

  public Delete<T>(id: any): Observable<T> {
    return this.http.delete<T>(this.actionUrl + id);
  }

  public DeleteAllSentReminders<T>(RequestReminder: any): Observable<T> {
    let search = this.GetParams(RequestReminder);
    return this.http.delete<T>(this.actionUrl, {
      params: search
    });
  }

  public ChangeReminderStatus<T>(id: any, status: boolean): Observable<T> {
    return this.http.put<T>(this.actionUrl + id + "/" + ApiConstant.REMINDER_STATUS, { status: status });
  }

  public ChangeAllReminderStatus<T>(ReminderList: any, status: boolean): Observable<T> {
    return this.http.put<T>(this.actionUrl + ApiConstant.ALL_REMINDER_STATUS + "?status=" + status, ReminderList);
  }

  public GetInquiryCountapi<T>(): Observable<T> {
    return this.http.get<T>(this.apiUrl +  ApiConstant.INQUIRY + "/GetUnReadInquiryCount");
  }



  public GetNotDoneReminderCountApi<T>(id: string): Observable<T> {
    return this.http.get<T>(this.actionUrl + id + "/GetUnDoneReminderCount");
  }

  public GetParams(param) {
    let params: HttpParams = new HttpParams();
    for (var key in param) {
      if (param.hasOwnProperty(key)) {
        let val = param[key];
        params = params.append(key, val);
      }
    }
    return params;
  }

  public SendSMS<T>(smsDetail): Observable<T> {
    return this.http.post<T>(this.actionUrl + ApiConstant.SMS, smsDetail);
  }

  public GetEmailHistory<T>(id: any, moduleName: string): Observable<T> {
    return this.http.get<T>(this.actionUrl + "getEmailHistory/" + id + "/" + moduleName);
  }

  public GetReminderListWithMutipleFiles<T>(RequestReminder: any): Observable<T> {
    let search = this.GetParams(RequestReminder);
    return this.http.get<T>(this.actionUrl + "GetReminderListWithMutipleFiles", {
      params: search
    });
  }

  public CreateReminderWithFiles<T>(formData: FormData) {
    return this.http.post(this.actionUrl + "CreateReminderWithMultipleImages", formData, { headers: { IsContentType: "true" } });
  }

  public UpdateReminderWithFiles<T>(id: any, formData: FormData): Observable<T> {
    return this.http.put<T>(this.actionUrl + id + '/updateReminderWithMultiplePath', formData, { headers: { IsContentType: "true" } });
  }

  public AddCoworkerReviewAnswers(questions): Observable<any> {
    return this.http.post<any>(this.actionUrl + 'CreateCoworkerReviewAnswers', questions);
  }
}
