import { CoWorker } from "./CoWorker";

export class ReminderBasic {
  public id?: string;
  public text?: string;
  public reminderDate?: string;
  public reminderTime?: string;
  public reminderEndDate?: string;
  public reminderEndTime?: string;
  public reminderStatus?: boolean;
  public createdBy?: string;
  public name?: string = "";
  public worker?: CoWorker;
  public isRepeat: boolean = false;
  public repeatMode?: string = "day";
  public repeatModecount?: number = 1;
  public replay: string = null;
  public image: string = null;
  public imagePath: string = '';
  public replyImagePath: string = '';
  public completedByName: string = '';
  public answerBy: string = '';
  public projectId: string;
  public projectNumber: string;
  constructor() { }
}

export class Reminder extends ReminderBasic {
  public coWorkerId?: string = "";
  public customerName?: string = "";
  public isSendByMe: boolean = false;
  public sendByMeName: string = "";
  public receivedByMeName: string = "";
  public sendNotification: string = "";
  constructor() {
    super();
  }
}

export class ReminderFull extends ReminderBasic {
  public coWorkerId?: any[] = [];
  public reviewQuestionId: string = "";
  constructor() {
    super();
  }
}

//Other

export const ReminderRepeatModes: any[] = [
  { id: 1, key: 'selectedReminderRepeatModes.value1', value: 'day' },
  { id: 2, key: 'selectedReminderRepeatModes.value2', value: 'week' },
  { id: 3, key: 'selectedReminderRepeatModes.value3', value: 'month' },
  { id: 4, key: 'selectedReminderRepeatModes.value4', value: 'year' },
  { id: 5, key: 'selectedReminderRepeatModes.value5', value: 'now' }
]


export const ReminderListType: any[] = [
  { id: 1, key: 'selectedReminderListType.value1', value: 'SentByMe' },
  { id: 2, key: 'selectedReminderListType.value2', value: 'ReceivedToMe' },
]

export const ReminderWeekDays: any[] = [
  { id: 1, key: 'selectedWeekedDay.value1', value: 'isExecuteMonday', Time: 'isExecuteMondayTime', IsShowTime: false },
  { id: 2, key: 'selectedWeekedDay.value2', value: 'isExecuteTuesday', Time: 'isExecuteTuesdayTime', IsShowTime: false },
  { id: 3, key: 'selectedWeekedDay.value3', value: 'isExecuteWednesday', Time: 'isExecuteWednesdayTime', IsShowTime: false },
  { id: 4, key: 'selectedWeekedDay.value4', value: 'isExecuteThursday', Time: 'isExecuteThursdayTime', IsShowTime: false },
  { id: 5, key: 'selectedWeekedDay.value5', value: 'isExecuteFriday', Time: 'isExecuteFridayTime', IsShowTime: false },
  { id: 6, key: 'selectedWeekedDay.value6', value: 'isExecuteSaturday', Time: 'isExecuteSaturdayTime', IsShowTime: false },
  { id: 7, key: 'selectedWeekedDay.value7', value: 'isExecuteSunday', Time: 'isExecuteSundayTime', IsShowTime: false }
]

export const ReminderRepeatModeOfMonth: any[] = [
  { id: 1, key: 'selectedReminderRepeatModeOfMonth.value1' },
  { id: 2, key: 'selectedReminderRepeatModeOfMonth.value2' },
  { id: 3, key: 'selectedReminderRepeatModeOfMonth.value3' },
  { id: 4, key: 'selectedReminderRepeatModeOfMonth.value4' }
];
export const WeekOftheMonth: any[] = [
  { id: 1, key: 'selectedWeekOftheMonth.value1' },
  { id: 2, key: 'selectedWeekOftheMonth.value2' },
  { id: 3, key: 'selectedWeekOftheMonth.value3' },
  { id: 4, key: 'selectedWeekOftheMonth.value4' },
  { id: 5, key: 'selectedWeekOftheMonth.value5' }
]






