import { Component, OnInit } from "@angular/core";
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { Router, ActivatedRoute } from '@angular/router';
import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { TitleService } from 'app/core/services/title.service';
import { CarInformationsRequest } from '../../model/RequestBase';
import { ResponseBase } from '../../model/ResponseBase';
import { CarInformationsService } from './car-informations.service'
import { AuthService } from 'app/core/services/auth.service';
import { CarInformation } from "../../model/CarInformation";

@Component({
    selector: 'vibe-car-information-list',
    templateUrl: './car-informations.component.html',
    styleUrls: ['./car-informations.component.scss']
})

export class CarInformationsListComponent implements OnInit {
    selectedIndex: number = 0;
    showSpinner = false;
    CarInformationsRequest: CarInformationsRequest = new CarInformationsRequest();
    CarInformationList: CarInformation[] = [];
    displayPagination: boolean;
    pagination: any;
    // roleName: string;
    //isValid: boolean = false;
    limitNumber: any;
    orderBy: string = "Number";
    search: string = "";
    userguest: boolean = false;
    isActive: boolean = true;
    isDeleted: boolean = false;
    roleClaims: any;
    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private titleService: TitleService,
        private carInformationsService: CarInformationsService,
        private authService: AuthService,
        private _route: ActivatedRoute,
        private _router: Router) {
        this.translationLoaderService.loadTranslations(danish, english);
        this.titleService.SetTitle("TITLE_CARINFORMATIONS");
        this.roleClaims = this.authService.getRoleClaimsList();
    }

    ngOnInit() {
        // this.roleName = this.authService.getUserRole();
        // if (this.roleName == "Admin") {
        //     this.isValid = true;
        // }
        // else if (this.roleName == "Kontor") {
        //     this.isValid = true;
        // }
        // else if (this.roleName == "Maler") {
        //     this.isValid = false;
        // }
        // else if (this.roleName == "Gaest") {
        //     this.userguest = true;
        //     this.isValid = false;
        // }
        // else {
        //     this.isValid = true;
        // }
        this.CarInformationsRequest = new CarInformationsRequest();
        this.CarInformationsRequest.Index = 1;
        this.CarInformationsRequest.Limit = 100;
        this.CarInformationsRequest.OrderBy = this.orderBy;
        this.GetCarInformationsList();
    }

    GetCarInformationsList() {
        this.showSpinner = true;
        this.carInformationsService.Get<ResponseBase>(this.CarInformationsRequest)
            .subscribe({
                next: (response: ResponseBase) => {
                    if (response) {
                        this.CarInformationList = response.result;
                        this.pagination = response.pagination;
                        this.displayPagination = true;
                    } else {
                        this.showSpinner = false;
                        this.displayPagination = false;
                    }
                },
                error: err => { },
                complete: () => {
                    this.showSpinner = false;
                }
            });
    }

    gotoCarInformationEdit(id) {
        this._router.navigate(['/carinformations/edit', id],{ 
            state: { moduleDataList: this.CarInformationList },
            queryParams: { isActive: this.isActive, isDeleted : this.isDeleted }
        });
    }

    // isValidForm() {
    //     return this.isValid;
    // }

    receivePaginatonEvent(event) {
        this.CarInformationsRequest.Index = event.pageIndex;
        this.CarInformationsRequest.Limit = event.pageSize;
        this.CarInformationsRequest.OrderBy = this.orderBy;
        this.GetCarInformationsList();
    }

    SearchByValue(value) {
        if (value || value == "") {
            this.CarInformationsRequest.Search = value.trim();
            // this.CarInformationsRequest.IsActive = false;
            // this.isActive = this.CarInformationsRequest.IsActive;
            this.GetCarInformationsList();
        }
    }

    receiveFilterEvent(event) {
        this.CarInformationsRequest.OrderBy = event;
        if (this.limitNumber == undefined) {
            this.CarInformationsRequest.Limit = 100
        } else {
            this.CarInformationsRequest.Limit = this.limitNumber;
        }
        this.GetCarInformationsList();
    }

    onSetActive(active) {
        this.isActive = active;
        this.CarInformationsRequest.IsActive = this.isActive;
        this.CarInformationsRequest.Index = 1;
        if (!active) {
            this.GetCarInformationsList();
        }
    }

    getDeletedCustomers(isDeleted) {
        this.isDeleted = isDeleted;
        this.CarInformationsRequest.IsDeleted = this.isDeleted;
        this.CarInformationsRequest.Index = 1;
        this.GetCarInformationsList()
    }
}