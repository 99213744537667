export const locale = {
    lang: 'ds',
    data: {
        'HEADERS': {
            'OfferTravelingAmount': 'Tilbud Kørsel Beløb Pr. Min'
        },
        'FIELDS': {
            'TravelingAmount': 'Kørsel beløb pr. min'
        },
        'BUTTONS': {
            'Save': 'Gem',
            'Back': 'Tilbage',
        },
        'MESSAGES': {
            'Failed': 'Operation mislykket, prøv senere',
            'Success': 'Success',
        }
    }
}