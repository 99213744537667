import { Component, OnInit } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { MatDialog, MatOption, MatTableDataSource } from '@angular/material';
import { AuthService } from 'app/core/services/auth.service';
import { SettingsService } from '../../settings.service';
import { ResponseBase } from "app/main/model/ResponseBase";
import { FormBuilder, Validators,FormGroup } from "@angular/forms";
import * as moment from "moment";
import { TranslateService } from "@ngx-translate/core";
import { NotificationsService } from "angular2-notifications";
import { OfferRejectedQuestionDTO } from "app/main/model/OfferV2";
import { ProjectofferService } from "app/main/pages/project-offer/project-offer.service";
import { ActivatedRoute, Router } from "@angular/router";
import { TitleService } from "app/core/services/title.service";
import { ReminderRepeatModes, ReminderRepeatModeOfMonth, ReminderWeekDays, WeekOftheMonth } from "app/main/model/Reminder";

@Component({
  selector: "vibe-coworker-review-questions",
  templateUrl: "./coworker-review-questions.component.html",
  styleUrls: ["./coworker-review-questions.component.scss"]
})

export class CoworkerReviewQuestionsComponent implements OnInit {
  showSpinner: boolean = false;
  reviewQuestionsObj: any = [];
  currEditIndex: number = -1;
  currentObject = new OfferRejectedQuestionDTO();
  questionAnswerList = [];
  questionId: string = "";
  isEditQuestion: boolean = false;
  addOrUpdateQuestionobj:any;
  id: string = "";
  public startWeek: number = 1;
  reminderRepeatModes = ReminderRepeatModes;
  reminderRepeatModeOfMonth = ReminderRepeatModeOfMonth;
  weekDays = ReminderWeekDays;
  weekOfMonth = WeekOftheMonth;
  reviewQuestionFormDetail: any = {};
  //#region Form
  reviewQuestionForm = this.formBuilder.group({
    id: [null],
    text: [null, Validators.required],
    startDate: [null, Validators.required],
    endDate: [null, Validators.required],
    json: [null, Validators.required],
    mode: [1],
    modeOfMonth: [null],
    executeDayOnMonth: [null],
    executeWeekOnMonth: [null],
    isExecuteMonday: [false],
    isExecuteTuesday: [false],
    isExecuteWednesday: [false],
    isExecuteThursday: [false],
    isExecuteFriday: [false],
    isExecuteSaturday: [false],
    isExecuteSunday: [false],
    isRepeated: [false]
  });
  //#endregion Form

  constructor(private translationLoaderService: FuseTranslationLoaderService,
    private authService: AuthService,
    private settingsService: SettingsService,
    private projectofferService: ProjectofferService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private titleService: TitleService,
    private notificationsService: NotificationsService,
    private _router: Router,
    private _route: ActivatedRoute,
    private dialog: MatDialog) {
      this.titleService.SetTitle("TITLE_REVIEW_QUESTION");
    this.translationLoaderService.loadTranslations(danish, english);
  }

  ngOnInit() {
    this.reminderRepeatModes = this.reminderRepeatModes.filter(x => x.value != "now");
    this._route.queryParams.subscribe(params => {
      this.id = params['id'] ? params['id'] : "";
      this.getCoworkerReviewQuestionById();
    });
  }

  get f() {
    return this.reviewQuestionForm.controls;
  }
  
  public OnFocusIn(event) {
    event.model.show();
  }

  getCoworkerReviewQuestionById() {
    this.showSpinner = true;
    this.settingsService.GetCoworkerReviewQuestionById<ResponseBase>(this.id).subscribe({
      next: (response: ResponseBase) => {
        this.showSpinner = false;
        if (response != null && response.result) {
          this.reviewQuestionsObj = response.result;
          this.reviewQuestionFormDetail = response.result;
          if(this.reviewQuestionsObj.json){
            // this.reviewQuestionsObj.json = JSON.parse(this.reviewQuestionsObj.json,function(prop, value) {
            //   var lower = prop.charAt(0).toLowerCase() + prop.slice(1);
            //   if(prop === lower) return value;
            //   else this[lower] = value;
            // });
            this.questionAnswerList = JSON.parse(this.reviewQuestionsObj.json);
          }
          this.reviewQuestionForm.patchValue(this.reviewQuestionsObj); 
      }

      },
      error: err => {
      },
      complete: () => {
        this.showSpinner = false;
      }
    });
  }

  public Edit(obj, index) {
    this.currEditIndex = index;
    this.reviewQuestionForm.setValue(obj);
  }

  

  public FormReset() {
    this.reviewQuestionForm.reset();
    this.currEditIndex = -1;
  }

  public OnChangeDatePicker(args): void {
    if (args.value) {
      args.value = new Date(moment(args.value).format("YYYY-MM-DD"));
    }
  }

  getQuestionList() {
    this.showSpinner = true;
    this.isEditQuestion = false;
    this.questionId = "";
    this.projectofferService.GetAskOfferQuestionList().subscribe({
      next: (response: ResponseBase) => {
        this.showSpinner = false;
        this.questionAnswerList = response.result;
      },
    });
  }

  AddUpdateOfferQuestion(event) {
    var data = event;
        data.forEach((element,index) => {
            element['id'] = index+1
    });
    this.f.json.setValue(data);
  }

  AddOrUpdateQuestionList() {
    this.reviewQuestionForm.markAllAsTouched()
    if (this.reviewQuestionForm.invalid) {
      if (!this.reviewQuestionForm.value.json) {
        this.translate.get("VALIDATIONS.Question").subscribe({
          next: (res) => {
            this.notificationsService.error(res);
          }, error: err => { }
        });
      }
      return;
    }
    this.addOrUpdateQuestionobj = this.reviewQuestionForm.value;
    if (this.tryParseJSONObject(this.reviewQuestionForm.value.json))
      this.addOrUpdateQuestionobj.json = this.reviewQuestionForm.value.json;
    else
      this.addOrUpdateQuestionobj.json = JSON.stringify(this.reviewQuestionForm.value.json);
    this.showSpinner = true;
    this.settingsService.AddOrUpdateReviewQuestions(this.addOrUpdateQuestionobj).subscribe({
      next: (response: ResponseBase) => {
        this.showSpinner = false;
        this.FormReset();
        this.translate.get("Message.Created").subscribe({
          next: (res) => {
            this.notificationsService.success(res);
          },
          error: err => {
            this.showSpinner = false;
          }
        });
        this._router.navigate(['/settings'], { queryParams: { reqTabIndex: 9 } });
      },
      error: err => {
        this.translate.get("Message.Failed").subscribe({
          next: (res) => {
            this.notificationsService.error(res);
            this.showSpinner = false;
          },
          error: err => {
          }
        });
      }
    });
  }

  Cancel() {
    this.isEditQuestion = false;
  }

  DeleteQuestionByID(ID) {
    this.questionAnswerList = this.questionAnswerList.filter(i => i['id'] != ID)
        this.questionAnswerList.forEach((element,index) => {
            element['id'] = index+1
    });
    this.reviewQuestionsObj.json = this.questionAnswerList;
    this.f.json.setValue(this.questionAnswerList);
  }

  back(){
    this._router.navigate(['/settings'], { queryParams: { reqTabIndex: 9 } });
  }

  tryParseJSONObject(jsonString) {
    try {
      var o = JSON.parse(jsonString);
      if (o && typeof o === "object") {
        return o;
      }
    }
    catch (e) { }

    return false;
  };

  public get days() {
    const dayCount = this.getDaysInMonth(Number(moment(this.reviewQuestionFormDetail.startDate).format("YYYY")), Number(moment(this.reviewQuestionFormDetail.startDate).format("MM")));
    return Array(dayCount).fill(0).map((i, idx) => idx + 1)
   }

   public getDaysInMonth(year: number, month: number) {
    return 32 - new Date(year, month - 1, 32).getDate();
  }
}