export const locale = {
    lang: 'en',
    data: {
        'Headers': {
            'NewMaterial': 'Create New Material',
            'AddMaterial': 'Do you want to add materials?',
            //'DeleteMaterial': 'Do you want to delete this materials?',
            'DeleteDevice': 'Do you want to delete this device?',
            'Material': 'Material',
            'DeleteAlert': 'Are you sure you want to delete?',
            'AllRequisitionCoworker': 'Are you sure you want to do this on all tasks?',
            'Yes': 'Yes',
            'No': 'No',
            'OK': 'Save',
            'AskForSaveProjectData': 'Do you want to save this information ?',
            'CopyCustomerDetails': 'Copy Customer Details ?',
            'ChangeStatusToAllRequisition': 'Can you change started status to all task?',
            'UnassignCustomerFromInquiry': 'Are you sure you want to unassign customer from this inquiry ?',
            'MovetoFinishFolder': 'Are you sure you want to move this file to finish folder ?',
            'DeleteProjectFileAlert': 'Are you sure you want to delete the file?',
            'DeleteProjectWorkHours': 'Are you sure you want to delete workhours?',
            'DeleteTodaysNonWorkingHours': 'Are you sure you want to delete nonworking hours',
            'DeleteFutureNonWorkingHours': 'Do you want to delete future "non-working hours"',
            'DeleteWorkHoursOnFuture': 'Delete future hour on this task?',
            'DeleteOffer': 'Are you sure you want to delete this offer?',
            'DeleteAllOffers': 'Are you sure you want to delete offer?',
            'DeleteRoom': 'Are you sure you want to delete this room?',
            'DeleteRoomAlert': 'The offer has been created with this room, are you sure you want to delete this room, room detail will be deleted on offers.',
            'ChangeDailySalesCustomer': 'Are you sure you want change daily sales customer ?',
            'AllReminderDone': 'Do you want to make all reminders "done"?',
            'NotDoneAllReminder': 'Do you want to remove completed reminders ?',
            'DeleteSingleReminder': 'Do you want to delete this reminder ?',
            'DeleteAllReminder': 'Do you want to delete all this reminders ?',
            // 'DeleteAllRooms':'Are you sure you want to delete all rooms?'
            'DeleteDiverseMaterial': 'Do you want to delete this materials?',
            'SaveChanges': 'Do you want to save changes that you have made?',
            'CopyMaterial': 'Do you want to copy this material?',
            'CreateCustomer': 'Please create a customer first',
            'CreateProject': 'Please create a project first',
            'DeleteMaterial': 'Do you want to delete this item?',
            'DeleteProject': 'Do you want to delete this Project?',
            'DeleteCustomer': 'Do you want to delete this Customer?',
            'DeleteMessageForCreatedOffers': 'You have already created an offer with these detail you want to change these details old offer must be deleted Are you sure you want to delete the old offer?',
            'DeleteStatusMessage': 'Do you want to delete this status message?',
            'DeleteRequisitionMessage': 'Do you want to delete this task?',
            'InquiryInactiveMessage': 'Do you want to make Inquiry inactive when mail has been sent Yes / No?',
            'DeleteQuestion': 'Do you want to delete this Question?',
            'DeleteSupplier': 'Do you want to delete this supplier ?',
            'DeletePriceDiscount': 'Do you want to delete this price item?',
            'EmptyEconomyFields': 'You can not change status to "Ready billing" because economy fields are not filled.',
            'RoutePlanHeader': 'You have not selected a route. Do you want to select it ?',
            'DeleteCoworker': 'Do you want to delete this Coworker ?',
            'UndoChanges': 'Are you sure you want to undo that changes ?',
            'DeleteCarInfo': 'Do you want to delete this car ?',
            'DeleteCompanyInfoTab': 'Do you want to delete this tab ?',
            'DeleteColorCode': 'Do you want to delete this color code ?',
            'CopyMaterialForAllRooms': 'Do you want to copy this material for all rooms?',
            'CommentReq': 'Please write coworker comment',
            'CoworkerName': 'Coworker Name',
            'CoworkerComment': 'Coworker Comment',
            'ReminderDateTime': 'Reminder DateTime',
        },
        'BUTTONS': {
            'Manually': 'Manually'
        }
    }
};
