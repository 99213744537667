export const locale = {
    lang: 'en',
    data: {
        'FIELDS': {
            'Year': 'Year',
        },
        'FILTERS': {
            'SORTING_STATUS': 'Status',
            'All': 'All'
        },
        'MESSAGES': {
            'NORECORD': 'No Record Found'
        },
        'BUTTONS':{
            'ShowInactive': 'Show Inactive',
        },
        'TOOTIPORTITLE':{
            'Search':'Search'
        }
    }
};
