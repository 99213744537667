import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiConstant } from 'app/core/constant/ApiConstant';
import { Observable } from 'rxjs';
import { Settings } from 'app/main/model/Settings';

@Injectable({
    providedIn: 'root'
})

export class ToolsAndLaddersService {
    private actionSettingsUrl: string;

    constructor(private http: HttpClient) {
        this.actionSettingsUrl = ApiConstant.API_URL + ApiConstant.SETTINGS + "/";
    }

    public GetParams(param) {
        let params: HttpParams = new HttpParams();
        for (var key in param) {
            if (param.hasOwnProperty(key)) {
                let val = param[key];
                params = params.append(key, val);
            }
        }
        return params;
    }

    public GetSettingsValue<T>(param1: string, param2: string, param3: string): Observable<T> {
        var url = "";
        if (param1) {
            url += "param1=" + param1;
        }
        if (param2) {
            url += "&param2=" + param2;
        }
        if (param3) {
            url += "&param3=" + param3;
        }
        return this.http.get<T>(this.actionSettingsUrl + "getSettings?" + url);
    }

    public UpdateSettingValue<T>(setObj: Settings): Observable<T> {
        return this.http.put<T>(this.actionSettingsUrl + setObj.id, setObj);
    }

    public GetSettingsWithMupltipleKeys<T>(param1: string): Observable<T> {
        var url = "";
        if (param1) {
            url += "?param1=" + param1;
        }
        return this.http.get<T>(this.actionSettingsUrl + "getSettingsWithMupltipleKeys" + url);
    }

    public CreateHoliday<T>(holidayDto): Observable<T> {
        return this.http.post<T>(this.actionSettingsUrl + "CreateHoliday", holidayDto);
    }

    public UpdateHoliday<T>(id, holidayDto): Observable<T> {
        return this.http.put<T>(this.actionSettingsUrl + "UpdateHoliday/" + id, holidayDto);
    }

    public DeleteHoliday<T>(id: any): Observable<T> {
        return this.http.delete<T>(this.actionSettingsUrl + "DeleteHoliday/" + id);
    }

    public GetHolidayList<T>(RequestHoliday: any): Observable<T> {
        let search = this.GetParams(RequestHoliday);
        return this.http.get<T>(this.actionSettingsUrl + "GetHolidayList", {
            params: search
        });
    }
}