import { Component, OnInit } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';

import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { TermsOfEmployment } from 'app/main/model/CoWorker';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FormService } from 'app/core/services/form.service';
import { TermsOfEmploymentService } from './terms-of-employment.service';
import { NotificationsService } from 'angular2-notifications';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { DateAdapter, MatDialog } from '@angular/material';
import { AlertMessageModelComponent } from '../alert-message-model/alert-message-model.component';
import { RequestTearmsBase } from 'app/main/model/RequestBase';
import { NewTermsOfEmploymentComponent } from './new-terms-of-employment/new-terms-of-employment.component';
import { TitleService } from "app/core/services/title.service";
import { AuthService } from "app/core/services/auth.service";
import { SharedTextBoxPopup } from "app/main/shared-component/text-box-popup/text-box-popup.component";

@Component({
    selector: "terms-of-employment",
    templateUrl: "./terms-of-employment.component.html",
    styleUrls: ["./terms-of-employment.component.scss"]
})

export class TermsOfEmploymentComponent implements OnInit {
    showSpinner: boolean = false;
    employmentObject: TermsOfEmployment = new TermsOfEmployment();
    termsOfEmploymentForm: FormGroup;
    termsOfEmploymentList: [] = [];
    Id: string;
    pagination: any;
    displayPagination: boolean;
    requestBase: any;
    roleClaims: any;
    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private translate: TranslateService,
        private formService: FormService,
        private formBuilder: FormBuilder,
        private termsOfEmploymentService: TermsOfEmploymentService,
        private notificationsService: NotificationsService,
        private titleService: TitleService,
        private authService: AuthService,
        private adapter: DateAdapter<any>,
        private dialog: MatDialog) {
        this.titleService.SetTitle("TITLE_TERMOFEMPLOYMENT");
        this.translationLoaderService.loadTranslations(danish, english);
        this.roleClaims = this.authService.getRoleClaimsList();
    }

    ngOnInit() {
        this.requestBase = new RequestTearmsBase();
        this.requestBase.Index = 1;
        this.requestBase.Limit = 100;
        this.getTermsOfEmploymentList();
    }

    getTermsOfEmploymentList() {
        this.showSpinner = true;
        this.termsOfEmploymentService.Get(this.requestBase).subscribe({
            next: (response: ResponseBase) => {
                if (response) {
                    this.termsOfEmploymentList = response.result;
                    this.pagination = response.pagination;
                    this.displayPagination = true;
                }
            },
            error: err => {
                this.showSpinner = false;
                this.termsOfEmploymentList = [];
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    receivePaginatonEvent(event) {
        this.requestBase = new RequestTearmsBase();
        this.requestBase.Index = event.pageIndex;
        this.requestBase.Limit = event.pageSize;
        this.requestBase = event.pageSize;
        this.getTermsOfEmploymentList();
    }

    edit(item) {
        this.employmentObject = item;
        this.Open();
    }
    addNewTermOfEmployment(item) {
        this.employmentObject = new TermsOfEmployment();
        this.Open();
    }

    delete(item) {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: "200px",
            data: { Header: 'Headers.DeleteMessage' }
        })

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.showSpinner = true;
                this.termsOfEmploymentService.Delete(item.id).subscribe({
                    next: (response: any) => {
                        if (response) {
                            this.showSpinner = false;
                            this.getTermsOfEmploymentList();
                            this.translate.get("MESSAGES.Deleted").subscribe({
                                next: (res) => {
                                    this.notificationsService.success(res);
                                },
                                error: err => {
                                }
                            });
                        }
                    },
                    error: err => {
                        this.showSpinner = false;
                        this.translate.get("MESSAGES.Failed").subscribe({
                            next: (res) => {
                                this.notificationsService.error(res);
                            },
                            error: err => {
                            }
                        });
                    },
                    complete: () => {
                        this.showSpinner = false;
                    }
                })
            }
        });
    }

    Open() {
        const dialogRef = this.dialog.open(NewTermsOfEmploymentComponent, {
            width: "90%",
            height: "95%",
            data: this.employmentObject
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.getTermsOfEmploymentList();
            }
        });
    }

    copy(item){
        const dialogRef = this.dialog.open(SharedTextBoxPopup, {
            data: {
                HeadingTitleKey: "HEADERS.Designation",
                TextBoxLabelKey: "FIELDS.Name",
                TextBoxValue: item.designation ? item.designation : "",
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result && result.IsSuccess) {
                item.designation = result.TextValue;
                this.showSpinner = true;
                this.termsOfEmploymentService.CopyTermOfEmployement(item).subscribe({
                    next: (response: any) => {
                        if (response) {
                            this.showSpinner = false;
                            this.getTermsOfEmploymentList();
                            this.translate.get("MESSAGES.Deleted").subscribe({
                                next: (res) => {
                                    this.notificationsService.success(res);
                                },
                                error: err => {
                                }
                            });
                        }
                    },
                    error: err => {
                        this.showSpinner = false;
                        this.translate.get("MESSAGES.Failed").subscribe({
                            next: (res) => {
                                this.notificationsService.error(res);
                            },
                            error: err => {
                            }
                        });
                    },
                    complete: () => {
                        this.showSpinner = false;
                    }
                })
            }
        });

    }
}