export const locale = {
    lang: 'ds',
    data: {
        'BUTTONS': {
            'CreateNewProperty': 'Opret ny ejendom',
            'PropertyList': 'Ejendomsliste',
            'SAVE': 'Gem',
            'DELETE': 'Slet',
            'BACK': 'Gå tilbage',
        },
        'TABS': {
            'BasicDetail': 'Grundlæggende detaljer',
            'RoomBuilding': 'Værelse / Bygning',
            'Category': 'Kategori',
        },
        'FIELDS': {
            'NAME': 'Navn',
            'PHONE': 'Telefon',
            'PROPERTYTYPE': 'Ejendoms Type',
            'COLORCODE': 'Farve Kode',
        },
        'VALIDATIONS': {
            'NAME': 'Indtast gyldigt navn.',
            'ONLY50CHARACTER': 'Maksimal længde er 50 tegn.',
            'PHONENO': 'Indtast gyldigt nummer',
        },
        'TITLE': { 
            'PropertyPagination':'Antal ejendomme pr. side',
        },
        'MESSAGES': {
            'DeleteAlert': 'Er du sikker på, at du vil slette?',
        },
        'PropertyTypeCategory': {
            'Value1': 'Hus',
            'Value2': 'Lejlighed ',
        },
        'TOOTIPORTITLE':{
            'DeleteProperty':'Slet ejendom'
        }
    }
};
