import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../../../@fuse/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { ProjectMtoMComponent } from './component/projectMtoM/projectMtoM.component';
import { ProjectNotesComponent } from './component/notes/projectNotes.component';
import { OffersComponent } from './component/offers/offers.component';
import { FusePipesModule } from '../../../core/pipes/pipes.module';
import { RequisitionInfoComponent } from './component/requisitionInfo/requisitionInfo.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { RequisitionSpecialInfoComponent } from './component/requisitionSpecialInfo/requisitionSpecialInfo.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ProjectRequisitionMaterialComponent } from './component/projectRequisitionMaterial/projectRequisitionMaterial.component';
import { FuseDirectivesModule } from '@fuse/directives/directives';
@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    FusePipesModule,
    FuseDirectivesModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    DatePickerModule,
    NgxMatSelectSearchModule
  ],
  exports: [
    FuseDirectivesModule,
    ProjectMtoMComponent,
    ProjectNotesComponent,
    OffersComponent,
    RequisitionInfoComponent,
    RequisitionSpecialInfoComponent,
    ProjectRequisitionMaterialComponent],
  declarations: [
    ProjectMtoMComponent,
    ProjectNotesComponent,
    OffersComponent,
    RequisitionInfoComponent,
    RequisitionSpecialInfoComponent,
    ProjectRequisitionMaterialComponent]
})
export class ProjectSharedModule { }
