export const locale = {
  lang: 'en',
  data: {
    'btn': {
      'nonOk': 'Ok',
      'nonCancel': 'Cancel',
      'nonShowdailyreport': 'Show daily report',
    },
    'MESSAGES': {
      'Add_Error': 'No record found on Vagttider or Vagtplan table, please contact to administrator.',
      'MoreThan7Hours':'you cannot spend more than 7 hours on friday'
    },
    'Fld': {
      'nonStatus': 'Status',
      'project': 'Project',
      'nonText': 'Text',
      'nonDate': 'Date',
      'nonSR.No': 'SR.NO',
      'nonStartime': 'Start time',
      'nonEndtime': 'End time',
      'nonTotal': 'Total',
      'nonEnterStarttime': 'Enter start time',
      'nonEndTime': 'Enter end time',
      'nonDayHoursTotal': 'Day wise total',
      'pause': 'Pause',
      'breakTime': 'Add pause (30 min)',
      'WorkingHours': 'Working hours',
      'StartDate': 'Start Date ',
      'EndDate': 'End Date ',
      'PaymentOption': 'Payment option',
      'LeaveStatus': 'Leave status',
    },
    'Hdr': {
      'nonNonWorkingHours': 'Non working hours',
      'nonAddNonWorkingHours': 'Add non working hours',
      'nonAdd7to1130': 'Add 07:00 to 11:30',
      //'nonAdd7to01': 'Add 07:00 to 13:00',
      'nonAdd7to0230': 'Add 07:00 to 14:30',
      'nonAdd7to03': 'Add 07:00 to 15:00',
      'nonAddHoursManually': 'Add Hours Manually',
      'DateIsRequired': 'Date is required',
      'SelectProject': 'Select Project',
      'SelectStatus': 'Select Type',
      'Youdidnotaddanyentry': 'You did not add any entry yet.'
    },
    'selectedWorkHourStatus': {
      'value1': 'Vacation',
      'value2': 'Sick',
      'value3': 'Holiday of the work',
      'value4': 'Holiday with payment',
      'value5': 'Holiday without payment',
      'value6': 'School or learning',
      'value7': 'No payment for extra work but holiday for it',
      'value8': 'Other things',
      'value9': 'Offer'
    },
    'paymentOptionType': {
      'value1': 'Without Pay',
      'value2': 'With Pay',
    },
    'selectedWorkHoursApproveLeaveOption': {
      'value1': 'Apply',
      'value2': 'Approved',
      'value3': 'Rejected',
    },
  }
};
