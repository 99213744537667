export const locale = {
    lang: 'en',
    data: {
        'MESSAGES': {
            'Created': 'Created successfully',
            'Updated': 'Updated successfully',
            'Deleted': 'Deleted successfully',
            'Failed': 'Operation failed , try later',
        },
        'BUTTONS': {
            'BACK': 'Back',
            'SAVE': 'Save'
        },
        'FIELDS': {
            'Name': 'Name',
            'Email': 'Email',
            'Phone': 'Phone No.'
        },
        'Headers': {
            'AddPreference': 'Customer Preference',
        },
    }
};

