export const locale = {
    lang: 'en',
    data: {
        'Headers': {
            'OfferRoomLogDetails': 'Offer Room Log Details'
        },
        'FIELDS': {
            'Detail': 'Detail',
            'ChangedDetail': 'Changed Detail',
            'RoomName': 'Room name',
            'Width': 'Width',
            'Length': 'Length',
            'CeilingHeight': 'Ceiling Height',
            'Active': 'Set as inactive',
            'ExtraPrice1': 'Extra Price 1',
            'ExtraPrice2': 'Extra Price 2',
            'ExtraQty1': 'Extra Qty 1',
            'ExtraQty2': 'Extra Qty 2',
        },
        'IsActive': {
            'value1': 'Active',
            'value0': 'Inactive',
        }
    }
}