export const locale = {
    lang: 'ds',
    data: {
        'TITLE': {
            'Header': 'Tekst ved manglende planlægning',
            'SMSText':'Fjern venligst ikke @UnPlannedCoworkerList, fra SMS tekst.',
            'NotificationText':'Fjern venligst ikke @UnPlannedCoworkerList, fra Notifikation tekst.'
        },
        'FIELDS': {
            'HoursToSend': 'Send kl.',
            'DaysToSend': 'Send x dage før',
            'SMSText': 'SMS tekst',
            'NotificationText': 'Notifikations tekst',
            'EmailText': 'E-mail tekst',
            'IsCreateReminder':'Opret påmindelse?',
            'EmailTemplate':'E-mail Skabelon',
            'PlanningNoteLabel':'Fjern venligst ikke @UnPlannedCoworkerList,@count,@currentDate og @nextDate fra SMS,Notifikation og Email skabelon tekst.'
        },
        'VALIDATIONS': {
            'HoursToSend': 'Timer at sende er påkrævet',
            'DaysToSend': 'Dage at sende er påkrævet',
            'SMSText': 'SMS-tekst er påkrævet',
            'NotificationText': 'Notifikations tekst er påkrævet',
            'EmailText': 'E-mail-tekst er påkrævet',
        },
        'MESSAGES': {
            'Saved': 'Gemt succesfuldt.',
        },
        'BUTTONS': {
            'Save': 'Gem',
        }
    }
};

