import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'fraction' })
export class Fraction implements PipeTransform {
  transform(value: string, number: number): string {
    if (value) {
      if (value == '-')
        return value;
      else {
        var newValue = parseFloat(value).toFixed(number);
        var fractionPart = newValue.split('.')[1];
        var nonFractionPart = newValue.split('.')[0];
        if (nonFractionPart.length == 1) {
          return "0" + nonFractionPart + "." + fractionPart;
        }
        else {
          return nonFractionPart + "." + fractionPart;
        }
      }
    }
    else {
      return value;
    }
  }
}
