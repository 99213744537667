import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { DateAdapter, MatDialogRef, MatDialog, MAT_DIALOG_DATA, MatOption } from '@angular/material';

import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { ResponseBase } from 'app/main/model/ResponseBase';
import { BaseRequest, RequestRatesForTermsOfEmployment } from "app/main/model/RequestBase";
import { NanolinkService } from 'app/main/pages/nanolink/nanolink.service';
import { TitleService } from 'app/core/services/title.service';
import { Router } from "@angular/router";
import { AuthService } from "app/core/services/auth.service";


@Component({
    selector: "devices",
    templateUrl: "./devices.component.html",
    styleUrls: ["./devices.component.scss"]
})

export class DevicesComponent implements OnInit {
    showSpinner: boolean = false;
    deviceList: [] = [];
    pagination: any;
    displayPagination: boolean;
    requestBase: any;
    orderBy: string = "";
    roleClaims: any;

    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private translate: TranslateService,
        private nanolinkService: NanolinkService,
        private notificationsService: NotificationsService,
        private authService: AuthService,
        private titleService: TitleService,
        private _router: Router,
        private adapter: DateAdapter<any>) {
        this.translationLoaderService.loadTranslations(danish, english);
        this.titleService.SetTitle("TITLE_DEVICES");
        this.roleClaims = this.authService.getRoleClaimsList();
    }

    ngOnInit() {
        this.requestBase = new BaseRequest();
        this.requestBase.Index = 1;
        this.requestBase.Limit = 100;
        this.getDeviceList();
    } 

    getDeviceList() {
        this.showSpinner = true;
        this.nanolinkService.GetDeviceList(this.requestBase).subscribe({
            next: (response: ResponseBase) => {
                if (response) {
                    this.deviceList = response.result;
                    this.pagination = response.pagination;
                    this.displayPagination = true;
                }
            },
            error: err => {
                this.showSpinner = false;
                this.deviceList = [];
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    editDevice(id) {
        this._router.navigate(['nanolink/device/edit', id],{ }); 
    }
    addDevice() {
        this._router.navigate(['nanolink/device/add'],{ }); 
    }

    receivePaginatonEvent(event) {
        this.requestBase.Index = event.pageIndex;
        this.requestBase.Limit = event.pageSize;
        this.getDeviceList();
    }
    receiveFilterEvent(event) {
        this.requestBase.OrderBy = event;
        this.getDeviceList();
    }

    SearchBynumber() {
        this.getDeviceList();
    }
}