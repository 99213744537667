export const locale = {
  lang: 'ds',
  data: {
    'Tabs': {
      'PROJECT': 'Projekter',
      'PROJECTOFFER': 'Tilbud',
      'TabFiles': 'Filer',
      'TabBasicDetail': 'Oplysninger',
      'TabNote': 'Bemærk',
      'TabLog':'Log'
    },
    'MESSAGES': {
      'NORECORD': 'Ingen leverandører fundet',
      'Created': 'Oprettet med succes',
      'Updated': 'Opdateret med succes',
      'Deleted': 'Slettet med succes',
      'Failed': 'Operation mislykket, prøv senere',
      'EmailSent': 'Email sendt med succes'
    },
    'TITLE': {
      'CustomerPagination': 'Antal leverandører pr. side',
    },
    'SUPPLIER': {
      'TITLE': 'Leverandør',
      'TabBasicDetail': 'Oplysninger',
    },
    'FIELDS': {
      'SupplierName': 'Leverandør Navn',
      'Email': 'E-mail',
      'PhoneNo': 'Telefon',
      'Address-line-1': 'Adresse (Linje 1)',
      'Address-line-2': 'Adresse (Linje 2)',
      'City': 'By',
      'Zipcode': 'Postnummer',
      'Position': 'Position',
      'ContactPerson': 'Kontaktperson',
      'Note': 'Notat',
      'Homepage': 'Hjemmeside',
      'Number': 'Nummer',
      'AppUrlLink': 'App URL-link',
        'ChargesPercentage': 'Fortjeneste på materialer %',
        'EmailToSupplier': 'Email til Leverandør',
        'EANNumber': 'GLN Nummer',
        'ExpenseAccount': 'Udgiftskonto',
        'InvoiceAccount': 'Fakturakonto',
        'OurAccountNumber': 'Vores Kontonummer',
        'Group': 'Gruppe',
        'PaymentMethod': 'Betaling metode',
        'SWIFT': 'SWIFT',
        'PaymentId': 'Betaling id',
        'RefInPayment': 'Ref in betaling',
        'PaymentFormat': 'Betaling format',
        'Payment': 'Betaling',
    },
    'VALIDATIONS': {
      'TEXT': 'Indtast Leverandør Navn',
      'NumberRequired': 'Indtast Nummer',
      'Email': 'Indsæt Gyldig E-mail',
      'PHONENO': 'Indtast gyldigt telefonnummer',
      'PhoneNoRequired': 'Telefonnummer er påkrævet',
        'WhiteSpaceNotAllowed': 'Kun hvidt mellemrum er ikke tilladt',
      'EmailToSupplier': 'Indsæt Gyldig Leverandør E-mail',
    },
    'TOOTIPORTITLE':{
      'Edit':'Redigere',
      'Delete':'Slet',
      'SendSMS':'Send SMS',
      'SendEmail':'Send E-mail',
      'DeleteSupplier':'Slet Leverandør',
  },
  }
};
