export const locale = {
  lang: 'en',
  data: {
    'FIELDS': {
      'ImgNotFound': 'Image Not Found!',
      'Title': 'You press the picture it will be big',
    },
    'BUTTONS': {
      'Close': 'Close',
    },
  }
};
