export const locale = {
  lang: 'en',
  data: {
    'BUTTONS': {
      'Upload': 'Upload',
      'Cancel': 'Cancel',
      "Update": "Opdatere",
      "Reset": "Nulstil / annullere",
      "SampleFileDownload": "Sample file download"
    },
    'FIELDS': {
      'SelectSupplier': 'Select Supplier',
      'UploadPriceList': 'Upload Price List',
      'ChooseFile': 'Choose File',
      'Material': 'Material',
      'PersonalTotalPrice': 'Personal Total',
      'Quantity': 'Quantity',
      'ProductNo': 'Product',
      'OrderQuantity': 'Order Antal',
      'MinQuantity': 'Min Quantity',
      'MaxQuantity': 'Max Quantity',
      'TotalPurchasePrice': 'Total Purchase Price',
      'Total': 'Total',
      'Inventory': 'Inventory',
      'ShowAll': 'Show All',
      'ProjectNumber': 'Project Number',
      'RequisitionNo': 'Requisition No',
      'CaseNo': 'Case Number.',
      'ItemNo': 'Item Number',
      'Number': 'Holdings',
      'Text': 'Text',
      'OfferText': 'Offer Name',
      'FileUpload': 'Address',
      'Active': 'Set as inactive',
      'RetailPrice': 'Retail Price',
      'PurchasePrice': 'Purchase price',
      'Unit': 'Unit',
      'InternalText': 'Internal text',
      'ShelfNo': 'Shelf no.',
      'Rows': 'Rows',
      'AutomaticallyCreate': 'Automatically Create',
      'PersonalPrice': 'Personal Price',
      'SalesPrice': 'Sales Price',
      'EnterStock': 'Enter Stock',
      'PurchaseQuantity': 'Purchase Quantity',
      'Supplier': 'Supplier',
      'SupplierItemNumber': 'Supplier Material No',
      'MinInventory': 'Min Inventory',
      'MaxInventory': 'Max Inventory',
      'RequiredDetail': 'Required Detail',
      'NoStockFeatures': 'No Stock Features',
      'NotStockList': 'Not Stock List',
      'ColorCode': 'Color Code',
      'Barcode': 'Edit Barcode',
      'MaterialsForTheCase': 'Materials For The Case',
      'Wages': 'Wages',
      'CalculateSalesPrice': 'Calculate Sales Price',
      'Linetype': 'Line type',
      'Reminder': 'Reminder',
      'PriceSignWithBuyQuantity': 'Price Sign With Buy Qty',
      'OnlySummer': "Only Summer",
      "WeatherType": "Weather type",
      "All": "All",
      "IsPostNumberDiscount": "ZIP code appendix",
      "Yellow": "Yellow",
      "Blue": "Blue",
      "Materialtype": 'Material type',
      'MaterialSoldOnThisYear': 'Material Sold On This Year',
      'IsDisplayWebshop': 'Is DisplayWebshop',
      'DuplicateEntry': 'Duplicate Entry',
      'CheckPurchasePrice': 'Check PurchasePrice',
      'PurchasepriceSalesUnit': 'Purchaseprice SalesUnit',
      'MaxOrderQty': 'Max Order Qty',
    },
    'LABELS': {
      'TITLE': 'Supplier material price list upload (.xls,.xlsx)',
      'NoFileSelected': 'No File Selected',
      'FileSelected': 'File Selected',
      'ConfMsg': 'You change Price on ',
      'Supplier': 'Supplier',
      "MaterialChangesList": "Material changes list",
      "MaterialDifferentSupplierList": "Material different supplier list",
      "NewMaterialList": "New material list",
      'PriceListType':"PriceList Type"
    },
    'MESSAGES': {
      'Created': 'Created Successfully.',
      'Updated': 'Updated Successfully.',
      'Failed': 'Failed',
      'Deleted': 'Deleted successfully',
      'Notes': '**Note - Inactive or Deleted materials are highlighted in red color'
    },
    'VALIDATIONS': {
      'ITEMNUMBER': 'Item number required',
      'TEXT': 'Text required',
      'BEHOLDING': 'Holdings required',
      'ITEMNUMBEREXIST': 'Number is already exist',
      'ITEMNUMBERMIN': 'Enter Valid Number',
      'ITEMNUMBERMAX': 'Enter Valid Number',
      'BEHOLDINGMIN': 'Enter Valid Number',
      'BEHOLDINGMAX': 'Enter Valid Number',
      'Barcode': 'Same Barcode exist',
      'Unit': 'Unit required',
      'PersonalPrice': 'Personale price required',
      'RetailPrice': 'Retail price required',
      'PurchasePrice': 'Purchase price required',
      'Supplier': 'Supplier required',
      'MinInventory': 'Enter Min Inventory',
      'MaxInventory': 'Enter Max Inventory',
      'MinPrice': 'price must be {0} % higer of purchase price. ( More than {1} )',
      'LineType': 'LineType required',
      'Materialtype': 'Materialtype required',
      'WeatherType': 'WeatherType required',
      'PurchaseQuantity': 'PurchaseQuantity required',
      'SupplierMaterialNo': 'SupplierMaterialNo required',
      'PurchasingUnit': 'Purchasing Unit required',
      'ShelfNo': 'Shelf no required',
      'SupplierItemNumber': 'SupplierMaterialNo required',
    },
    'unitList': {
      'value1': 'PCS',
      'value2': 'Liter',
      'value3': 'Meter',
      'value4': 'Roll',
      'value5': 'M2',
      'value6': 'KG'
    },
    'linetype': {
      'value1': 'Pay',
      'value2': 'Text',
      'value3': 'Product'
    },
  }
};
