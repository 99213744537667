import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { InquiryService } from 'app/main/pages/inquiry/inquiry.service';
import { RequestInquiry } from 'app/main/model/RequestBase';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { IsNumber } from 'app/core/Validators/validation';

@Component({
    selector: 'vibe-inquiry-list',
    templateUrl: './inquiry-list.component.html',
    styleUrls: ['./inquiry-list.component.scss']
})
export class InquiryListComponent implements OnInit {
    @Output() selectInquiryEvent = new EventEmitter<string>();
    auth: any;
    search: any = '';
    number: any = '';
    displayPagination: boolean;
    pagination: any;
    showSpinner: boolean = false;
    requestModel: RequestInquiry = new RequestInquiry();

    //Arrays to dispaly rows in datatable
    Inquiry: any[];
    useradmin: boolean = false;
    userkontor: boolean = false;
    usermaler: boolean = false;
    usergaest: boolean = false;
    userguest: boolean = false;
    accessgranted: boolean = false;
    savedata: boolean = false;
    data: any;
    // roleName: string;
    orderBy: string = "Text";
    limitNumber: any;
    // isValid: boolean = false;
    isActive: boolean = true;
    isDeleted: boolean = false;

    idleState = 'Not started.';
    timedOut = false;
    lastPing?: Date = null;
    TodaysDateTime: Date = new Date();
    //Initiators
    constructor(private translationLoader: FuseTranslationLoaderService,
        private inquiryService: InquiryService,
        ) {
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
        //this.roleName = this.authService.getUserRole();

        // if (this.roleName == "Admin") {
        //     this.isValid = true;
        // }
        // else if (this.roleName == "Kontor") {
        //     this.isValid = true;
        // }
        // else if (this.roleName == "Maler") {
        //     this.isValid = false;
        // }
        // else if (this.roleName == "Gaest") {
        //     this.isValid = false;
        // }
        // else {
        //     this.isValid = true;
        // }
        this.requestModel = this.inquiryService.getRequestParameter();
        this.isActive = this.requestModel.IsActive;
        this.isDeleted = this.requestModel.IsDeleted;
        this.search = this.requestModel.Name;
        this.orderBy = this.requestModel.OrderBy;
        this.GetInquiry();
        this.TodaysDateTime = new Date();
        this.TodaysDateTime.setTime(this.TodaysDateTime.getTime() + (30 * 60 * 1000));
    }

    GetInquiry() {
        this.showSpinner = true;
        this.inquiryService.setRequestParameter(this.requestModel);
        this.inquiryService.Get<ResponseBase>(this.requestModel)
            .subscribe((response: ResponseBase) => {
                if (response != null) {
                    this.Inquiry = response.result;
                    this.pagination = response.pagination;
                    this.displayPagination = true;
                    this.showSpinner = false;
                }
                else {
                    this.Inquiry = [];
                    this.displayPagination = false;
                }
            },
                error => (err) => {
                    this.Inquiry = [];
                    this.displayPagination = false;
                    this.showSpinner = false;
                },
                () => {
                    this.showSpinner = false;
                });
    }

    checkAccess(inquiry) {
        this.selectInquiryEvent.emit(inquiry);
    }

    receiveFilterEvent(event) {
        this.requestModel.OrderBy = event;
        if (this.limitNumber == undefined) {
            this.requestModel.Limit = 100
        }
        else {
            this.requestModel.Limit = this.limitNumber;
        }
        this.GetInquiry();
    }

    SearchByName(value) {
        if (value || value == "") {
            this.requestModel.Name = value.trim();
            this.GetInquiry();
        }
    }

    isNumber(evt) {
        return IsNumber(evt);
    }

    onSetActive(active) {
        this.isActive = active;
        this.requestModel.IsActive = this.isActive;
        this.requestModel.Index = 1;
        this.GetInquiry();
    }
}
