import { PaintSelectTime } from 'app/constant/common.constant';
import { OfferCategoryMaterial, OfferCategory } from './ProjectOffer';

export class OfferMaterial {
    public id: string = '';
    public materialId: string = '';
    public text: string = '';
    public type: string = '1';
    public isActive: boolean = false;
    public isDeleted: boolean = false;
    public createdBy: string = '';
    public createdDate: Date = new Date();
    public modifyBy: string = '';
    public modifyDate: Date = new Date();
    public imagePath: string = '';
    public colorCode: string = '';
    public unit: string = '';
    public treatmentText: string = '';
    public automaticallyCreate: string = 'F';
    public reminder: string = '';
    public typeIds: any[] = [];
    public offerMaterialPriceText: string = '';
    public addNumberOfTimesOnPDF: boolean = false;
    public treatmentType: number = 0;
    public materialNumber: string = '';
    public addPriceOnPDF: boolean = false
    public isPostNumberDiscount: boolean = false;
    public offerDescription: string = '';
    public offerDescriptionText: string = '';
    public addUnitOnPDF: boolean = false
    public notCalculateToDriving: boolean = false
}

export class OfferMaterialWithPrice extends OfferMaterial {
    public image: string = '';
    public offerMaterialPriceList: OfferMaterialPrice[];
}

export class OfferMaterialPrice {
    public id: string = '';
    public offerMaterialId: string = '';
    public type: number = 0;
    public price: number = 0;
    public timeMeter: number = 0;
    public isAddMeterRangePrice: boolean = false;
    public paintText: string = '';
    public startMeter1: number = 0;
    public endMeter1: number = 0;
    public priceMeter1: number = 0;
    public timeMeter1: number = 0;
    public startMeter2: number = 0;
    public endMeter2: number = 0;
    public priceMeter2: number = 0;
    public timeMeter2: number = 0;
    public startMeter3: number = 0;
    public endMeter3: number = 0;
    public priceMeter3: number = 0;
    public timeMeter3: number = 0;
    public isActive: boolean = false;
    public isDeleted: boolean = false;
    public notCalculateWallPrice: boolean = false;
    public notCalculateCeilingPrice: boolean = false;
    public createdBy: string = '';
    public createdDate: Date = new Date();
    public modifyBy: string = '';
    public modifyDate: Date = new Date();
    public isDefault: boolean = false;
    public expensesType: number = 0;
    public expensesAmount: number = 0;
    public reminderstr: string = "";
    public reminder: string = "";
    public materialList: [];
}

export class OfferMeterWisePrice {
    public price: number;
    public isAddMeterRangePrice: boolean = false;
    public startMeter1: number = 0;
    public endMeter1: number = 0;
    public priceMeter1: number = 0;
    public timeMeter1: number = 0;
    public startMeter2: number = 0;
    public endMeter2: number = 0;
    public priceMeter2: number = 0;
    public timeMeter2: number = 0;
    public startMeter3: number = 0;
    public endMeter3: number = 0;
    public priceMeter3: number = 0;
    public timeMeter3: number = 0;
}

export class OfferTypeDTO {
    public id: string = '';
    public offerId: string = '';
    public offerCategoryId: string = '';
    public name: string = '';
    public offerText: string = '';
    public price: number = 0;
    public discount: number = 0;
    public priceTotal: number = 0;
    public isDeleted: boolean = false;
    public isActive: boolean = false;
    public createdBy: string = '';
    public createdDate: Date = new Date();
    public modifyBy: string = '';
    public modifyDate: Date = new Date();
    public formName: string = '';
    public vat: number = 0;
    public totalInclVAT: number = 0;
    public isIncludePriceOnPDF: boolean = false;
    public numberOfHours: number = 0;
    public numberOfDays: number = 0;
    public discountByProjectAmount: number = 0;
    public isShowRoomDetail: boolean = false;
    public distance: any;
    public time: number = 0;
    public travelingAmount: number = 0;
    public numberOfDrivingDays: number = 0;
    public TotalTime: any = 0;
    public PriceInclMoms: number = 0;
    public offerCategoryMaterial: OfferCategoryMaterial[] = [];
    public discountByPostNumber: number = 0;
    public isDiscountByPostNumber: boolean = false;
    public isDiscountByProjectAmount: boolean = false;
    public number: number = 0;
    public drivingAmountTotal: number = 0;
}

export class OfferTypeWithRoomPriceDetail extends OfferTypeDTO {
    public offerRoomDetailList: OfferRoomFullDetailDTO[];
    public offerDiverseDetailList: DiverseDTO[];
    public fileList: OutdoorFileList[];
    public offerCategoriesJson: OfferCategory[] = [];
    public customerDetailObj: any;
}

export class OfferRoomPriceDTO {
    public id: string = '';
    public offerTypeId: string = '';
    public offerRoomId: string = '';
    public name: string = '';
    public paintWall: boolean = false;
    public wallPaintText: string;
    public wallMaterialTypeId: number = 0;
    public wallMaterialId: string = '';
    public wallMaterialPriceId: string = '';
    public wallMaterialPriceValue: string = '';
    public wallLength: number = 0;
    public wallWidth: number = 0;
    public ceilingHeight: number = 0;
    public wallPrice: number = 0;
    public wallExtraQty1: number = 0;
    public wallExtraQty2: number = 0;
    public wallExtraPrice1: number = 0;
    public wallExtraPrice2: number = 0;
    public wallTotalArea: number = 0;
    public wallTotalPrice: number = 0;
    public wallColorCode: string;
    public wallMaterialReminder: string;
    public paintCeiling: boolean = false;
    public ceilingMaterialTypeId: number = 0;
    public ceilingMaterialId: string = '';
    public ceilingMaterialPriceId: string = '';
    public ceilingPaintText: string;
    public ceilingMaterialPriceValue: string = '';
    public ceilingLength: number = 0;
    public ceilingWidth: number = 0;
    public ceilingPrice: number = 0;
    public ceilingExtraQty1: number = 0;
    public ceilingExtraQty2: number = 0;
    public ceilingExtraPrice1: number = 0;
    public ceilingExtraPrice2: number = 0;
    public ceilingTotalArea: number = 0;
    public ceilingTotalPrice: number = 0;
    public ceilingMaterialReminder: string;
    public paintFloor: boolean = false;
    public floorMaterialTypeId: number = 0;
    public floorMaterialId: string = '';
    public floorMaterialPriceId: string = '';
    public floorPaintText: string;
    public floorLength: number = 0;
    public floorWidth: number = 0;
    public floorPrice: number = 0;
    public floorExtraQty1: number = 0;
    public floorExtraQty2: number = 0;
    public floorExtraPrice1: number = 0;
    public floorExtraPrice2: number = 0;
    public floorColorCode: string;
    public floorTotalArea: number = 0;
    public floorTotalPrice: number = 0;
    public floorMaterialReminder: string;
    public totalPrice: number = 0;
    public ceilingColorCode: string;
    public isDeleted: boolean = false;
    public isActive: boolean = false;
    public createdBy: string = '';
    public createdDate: Date = new Date();
    public modifyBy: string = ''
    public modifyDate: Date = new Date();

    //extra
    public wallMaterialName: string = '';
    public wallPaintType: number = 0;
    public wallPaintKey: string = '';
    public ceilingMaterialName: string = '';
    public ceilingPaintType: number = 0;
    public ceilingPaintKey: string = '';
    public floorMaterialName: string = '';
    public floorPaintType: number = 0;
    public floorPaintKey: string = '';
    public notCalculateWallPrice: boolean = false;
    public notCalculateCeilingPrice: boolean = false;
    public notCalculateFloorPrice: boolean = false;

    //Frontend fields
    public isExpand: boolean = false;
    public isAddExtraWallQty: boolean = false;
    public isAddExtraCeilingQty: boolean = false;
    public isNewDimension: boolean = false;
    public newLength: number = 0;
    public newWidth: number = 0;
    public newCeilingHeight: number = 0;
    public selectCategoryTimeForWall = PaintSelectTime;
    public selectCategoryTimeForCeiling = PaintSelectTime;
    public isAddExtraFloorQty: boolean = false;
    public selectCategoryTimeForFloor = PaintSelectTime;
    public isPaintPanels: boolean = false;
    public isPaintDoorFrame: boolean = false;
    public isPaintRadiator: boolean = false;
    public isPaintDore: boolean = false;
    public tempRoomId: string = '';
}


export class OfferRoomPriceDTO2Temp {
    public id: string = '';
    public offerTypeId: string = '';
    public offerRoomId: string = '';
    public offerMaterialId: string = '';
    public materialName: string = '';
    public offerMaterialPriceId: string = '';
    public materialPrice: number;
    public paintCeiling: boolean = false;
    public addToOffer: boolean = false;
    public name: string = '';
    public length: number = 0;
    public width: number = 0;
    public ceilingHeight: number = 0;
    public qty1: number;
    public qty2: number;
    public qty3: number;
    public qty4: number;
    public totalQty: number = 0;
    public totalPrice: number = 0;
    public price: number;
    public isDeleted: boolean = false;
    public isActive: boolean = false;
    public createdBy: string = '';
    public createdDate: Date = new Date();
    public modifyBy: string = '';
    public modifyDate: Date = new Date();
    public selectCategoryTime = PaintSelectTime;
    public selectCategoryTimeForCeiling = PaintSelectTime;
    public isNewDimension: boolean = false;
    public newLength: number = 0;
    public newWidth: number = 0;
    public newCeilingHeight: number = 0;
    public displayNewDimension: boolean = false;
    public isExpand: boolean = false;
    public paintTime: number = 0;
    public ceilingMaterialId: string = '';
    public ceilingMaterialPriceId: string = '';
    public ceilingArea: number = 0;
    public ceilingPrice: number = 0;
    public ceilingPriceTotal: number = 0;
    public wallTotalPrice: number = 0;
    public isAddExtraWallQty: boolean = false;
    public isAddExtraCeilingQty: boolean = false;
}

export class OfferRejectedQuestionDTO {
    public id: string;
    public question: string;
    public answer: string = "";
    public isDeleted: boolean = false;
    public isActive: boolean = true;
    public createdBy: string = '';
    public createdDate: Date = new Date();
    public modifyBy: string = '';
    public modifyDate: Date = new Date();
    public questionType: string = "Textbox";
    public choiceOptions: any;
    public answerList: [] = [];
}

export class DiverseDTO {
    public id: string = '';
    public offerId: string = '';
    public offerTypeID: string = '';
    public offerCategoryId: string = '';
    public materialID: string = '';
    public colorCode: string = '';
    public unit: string = '';
    public materialPriceID: string = '';
    public text: string = '';
    public price: number = 0;
    public qty: number = 0;
    public priceTotal: number = 0;
    public grandTotal: number = 0;
    public createdBy: string = '';
    public createdDate: Date = new Date();
    public modifyBy: string = '';
    public modifyDate: Date = new Date();
    public selectCategoryTimeForDiverse = PaintSelectTime;
    public paintType: number = 0;
    public materialName: string = '';
    public imagePath: string = '';
    public roomId: string = null;
    public type: number = 0;
    public materialList = [];
    public reminder: string = null;
    public isMaterialAllItem: boolean = false;
    public originalPrice: number = 0;
    public roomName: string = '';
    public length: number = 0;
    public width: number = 0;
    public isUnitPrint: boolean = false;
    public treatmentType: number = 0;
    public extraM2QtyWall: number = 0;
    public extraM2QtyCeiling: number = 0;
    public extraM2QtyFloor: number = 0;
    public extraM2PriceWall: number = 0;
    public extraM2PriceCeiling: number = 0;
    public extraM2PriceFloor: number = 0;
    public isQuantityPrint: boolean = false;
    public notCalculateToDriving: boolean = false;
    public offerCategory: OfferCategory = new OfferCategory();
}

export class OfferRoomPriceMatetialAllDTO {
    // public wallMaterialTypeId: number = 22;
    // public materialIdForWall: string = '';
    // public materialPriceIdForWall: string = '';
    // public wallColorCode: string = '';
    // public wallPrice: number = 0;

    // public ceilingMaterialTypeId: number = 21;
    // public materialIdForCeiling: string = '';
    // public materialPriceIdForCeiling: string = '';
    // public ceilingColorCode: string = '';
    // public ceilingPrice: number = 0;

    // public floorMaterialTypeId: number = 23;
    // public materialIdForFloor: string = '';
    // public materialPriceIdForFloor: string = '';
    // public floorColorCode: string = '';
    // public floorPrice: number = 0;

    public isPaintPanels: boolean = false;
    public panelsMaterialTypeId: number = 11;
    public materialPriceIdForPanels: string = '';
    public materialIdForPanels: string = '';
    public panelsPrice: number = 0;
    public PanelsColorCode: string = '';
    public panelsQty: number = 0;
    public isShowRoomListForPaneler: boolean = false;
    public panelerRoomList: any[];
    public materialQtyForPanels: number = 0;

    public isPaintDoorFrame: boolean = false;
    public doorFrameMaterialTypeId: number = 12;
    public materialPriceIdForDoorFrame: string = '';
    public materialIdForDoorFrame: string = '';
    public doorFramePrice: number = 0;
    public doorFrameColorCode: string = '';
    public doorFrameQty: number = 0;
    public isShowRoomListForDoorFrame: boolean = false;
    public doorFrameRoomList: any[];
    public materialQtyForDoorFrame: number = 0;

    public isPaintRadiator: boolean = false;
    public radiatorMaterialTypeId: number = 16;
    public materialPriceIdForRadiator: string = '';
    public materialIdForRadiator: string = '';
    public radiatorPrice: number = 0;
    public radiatorColorCode: string = '';
    public radiatorQty: number = 0;
    public isShowRoomListForRadiator: boolean = false;
    public radiatorRoomList: any[];
    public materialQtyForRadiator: number = 0;

    public isPaintDore: boolean = false;
    public doreMaterialTypeId: number = 4;
    public materialPriceIdForDore: string = '';
    public materialIdForDore: string = '';
    public dorePrice: number = 0;
    public doreColorCode: string = '';
    public doreQty: number = 0;
    public isShowRoomListForDore: boolean = false;
    public doreRoomList: any[];
    public materialQtyForDore: number = 0;
    public coverMaterialTypeId: number = 20;

    public wallMaterialAllList: MaterialList[];
    public ceilingMaterialAllList: MaterialList[];
    public floorMaterialAllList: MaterialList[];
    public offerDiverseList: OfferDiverseList[];
}


export class OfferRoomDetailDTO {
    public id: string = '';
    public offerTypeId: string = '';
    public offerRoomId: string = '';
    public name: string = '';
    public totalPrice: number = 0;
    public isPaintPanels: boolean = false;
    public isPaintDoorFrame: boolean = false;
    public isPaintRadiator: boolean = false;
    public isPaintDore: boolean = false;
    public isDeleted: boolean = false;
    public isActive: boolean = false;
    public createdBy: string = '';
    public createdDate: Date = new Date();
    public modifyBy: string = ''
    public modifyDate: Date = new Date();
    public isExpand: boolean = false;

    // Extra Field for Display
    public wallMaterialList: MaterialList[];
    public wallTotalArea: number = 0;
    public wallTotalPrice: number = 0;
    public ceilingMaterialList: MaterialList[];
    public ceilingTotalArea: number = 0;
    public ceilingTotalPrice: number = 0;
    public floorMaterialList: MaterialList[];
    public floorTotalArea: number = 0;
    public floorTotalPrice: number = 0;
    public paintWall: boolean = false;
    public paintCeiling: boolean = false;
    public paintFloor: boolean = false;
    public isAddFloor: boolean = false;
}

export class OfferRoomCalculationDTO {
    public id: string = '';
    public offerRoomDetailId: string = '';
    public length: number = 0;
    public width: number = 0;
    public ceilingHeight: number = 0;
    public extraQty1: number = 0;
    public extraQty2: number = 0;
    public extraPrice1: number = 0;
    public extraPrice2: number = 0;
    public totalArea: number = 0;
    public totalPrice: number = 0;
    public colorCode: string = '';
    public isPaint: boolean = false;
    public typeId: number = 0;
    public isDeleted: boolean = false;
    public isActive: boolean = false;
    public createdBy: string = '';
    public createdDate: Date = new Date();
    public modifyBy: string = ''
    public modifyDate: Date = new Date();
    public notCalculateWallPrice: boolean = false;
    public isAddExtraWallQty: boolean = false;
    public extraM2QtyWall: number = 0;
    public extraM2QtyCeiling: number = 0;
    public extraM2QtyFloor: number = 0;
    public extraM2PriceWall: number = 0;
    public extraM2PriceCeiling: number = 0;
    public extraM2PriceFloor: number = 0;
    public drivingAmountTotal: number = 0;    
    public paintTimerTotal: number = 0;    
}

export class MaterialList {
    public id: string = '';
    public offerRoomCalculationId: string = '';
    public offerMaterialId: string = '';
    public offerMaterialPriceId: string = '';
    public offerMaterialTypeId: string = '';
    public name: string = '';
    public paintText: string = '';
    public price: number = 0;
    public totalArea: number = 0;
    public totalPrice: number = 0;
    public materialList = [];
    public materialReminder: string;
    public selectCategoryTime = PaintSelectTime;
    public paintKey: string = '';
    public colorCode: string = '';
    public meterWisePrice: OfferMeterWisePrice = new OfferMeterWisePrice();
    public notCalculatePrice: boolean = false;
    public wallPaintText: string;
    public originalPrice: number = 0;
    public roomId: string = "";
    public roomList = [];
    public selectedRoomCount: number = 0;    
    public notCalculateToDriving: boolean = false;
}

export class OfferRoomFullDetailDTO extends OfferRoomDetailDTO {
    public offerRoomCalculation: OfferRoomCalculationWithMaterialList[];
}

export class OfferRoomCalculationWithMaterialList extends OfferRoomCalculationDTO {
    public materialList: MaterialList[];
}

export const QuestionTypeCategory: any[] = [
    { id: 'Textbox', key: 'QuestionTypeCategory.value1' },
    { id: 'MultiChoice', key: 'QuestionTypeCategory.value2' },
    { id: 'SingleChoice', key: 'QuestionTypeCategory.value3' },
];

export class OutdoorFileList {
    public categoryId: string = "";
    public categoryName: string = "";
    public files: string[] = [];
}

export class OfferDiverseList extends DiverseDTO {
    public roomList: any = [];
    public isPaint: boolean = false;
    public isShowRoomList: boolean = false;
    public treatmentType: number = 0;
    public extraM2QtyWall: number = 0;
    public extraM2QtyCeiling: number = 0;
    public extraM2QtyFloor: number = 0;
    public selectedRoomCount: number = 0;
}

export class OfferTypeCreateLater {
    public id: string = '';
    public offerId: string = "";
    public name: string = "";
    public categoryLaterList: Array<OfferCategoryLaterDTO> = [];
    public categoryLaterListJson: string = "";
    public propertyId: string = "";
    public customerId: string = "";

    public numberOfHours: number = 0;
    public distance: any;
    public time: number = 0;
    public numberOfDrivingDays: number = 0;
    public TotalTime: any = 0;
}

export class OfferCategoryLaterDTO {
    public id: string = '';
    public name: string = "";
    public qty: number = 0;
    public categoryMaterialList: Array<OfferCreateLaterCategoryMaterialDTO> = [];
}

export class OfferCreateLaterCategoryMaterialDTO {
    public id: string = '';
    public materialTypeID: number = 0;
    public materialPriceID: string = "";
    public offerMaterialText: string = "";
    public text: string = "";
    public colorCode: string = "";
    public roomId: string = "";
    public roomName: string = "";
    public length: number = 0;
    public width: number = 0;
    public height: number = 0;
    public baseline: number = 0;
    public area: number = 0;
    public materialQty: number = 0;
    public price: number = 0;
    public total: number = 0;
    public isCopyMaterial: boolean = false;
}