export const locale = {
  lang: 'en',
  data: {
    'FIELDS': {
      'Material': 'Material',
      'Total': 'Total',
      'Inventory': 'Inventory',
      'ProjectNumber': 'Project Number',
      'RequisitionNo': 'Requisition No',
      'CaseNo': 'Case Number.',
      'ItemNo': 'Item Number',
      'Number': 'Holdings',
      'Text': 'Text',
      'SRNO': 'Sr No',
      'Status': 'Status',
      'Name': 'Name',
      'Createdby': 'Created By',
      'CreatedDate': 'Created Date',
      'ModifyBy': 'Modify By',
      'ModifyDate': 'Modify Date',
      'CoworkerName': 'Coworker',
      'Date': 'Date',
      'Description': 'Description',
      'MaterialDetail': 'Material Detail',
      'RequisitionDetail': 'Requisition Detail',
      'ColorCode': 'Color Code',
      'Price': 'Price',
      'QTY': 'QTY',
      'StartDate': 'Start Date',
      'EndDate': 'End Date',
      'Coworker': 'Coworker',
      'TotalPrice': 'Total Price',
      'TotalQTY': 'Total QTY',
    },
    'LABELS': {
      'TITLE': 'Sold item',
      "MaterialChangesList": "Material changes list",
    },
    'MESSAGES': {
      'Created': 'Created Successfully.',
      'Updated': 'Updated Successfully.',
      'Failed': 'Failed',
      'Deleted': 'Deleted successfully',
      'NORECORD':'No record found.',
    },
  }
};
