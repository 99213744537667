export const locale = {
    lang: 'ds',
    data: {
        'TITLE': {
            'NotificationSendConfigHeader': 'Notification konfiguration',
        },
        'FIELDS': {
            'NotificationSCIsTimeBased':'Er tid baseret ?',
            'NotificationSCIsSendEmail': 'Er send Notification ?',
            'NotificationSCHoursFromSend': 'Fra kl.',
            'NotificationSCHoursToSend': 'Til kl.',
            'EmailTemplate':'E-mail Skabelon',
        },
        'VALIDATIONS': {
            'NotificationSCHoursFromSend': 'Timer fra afsendelse er påkrævet',
            'NotificationHoursFromSendSmaller': 'Timer fra afsendelse skal være mindre end time til afsendelse',
            'NotificationSCHoursToSend': 'Timer at sende er påkrævet',
            'NotificationHoursToSendGreater': 'Timer til afsendelse skal være længere end time fra afsendelse',
        },
        'MESSAGES': {
            'Saved': 'Gemt succesfuldt.',
        },
        'BUTTONS': {
            'NotificationSCSave': 'Gem',
        }
    }
};
