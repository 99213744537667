export const locale = {
    lang: 'en',
    data: {
        'FIELDS': {
            'Barcode': 'Edit Barcode',
        },
        'BUTTONS': {
            'BACK': 'Back',
            'Ok': 'Save',
        },
        'MESSAGES': {
            'Barcode': 'Barcode already exist.',
        },
        'VALIDATIONS': {
            'Barcode': 'Enter valid barcode / barcode already exists.',
            'BarcodeRequired': 'Please enter barcode.',
            'BarcodeRequiredWhiteSpaceNotAllowed':'Only white space not allowed'
        }
    }
};