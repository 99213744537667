import { Component, OnInit, ViewChild } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';

import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { RequestCommonBase } from 'app/main/model/RequestBase';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { AnswerStatusLogComponent } from 'app/main/shared-component/answer-status-log/answer-status-log.component';
import { MatDialog , MatOption} from '@angular/material';
import { SettingsService } from "app/main/pages/settings/settings.service";
import { environment } from 'environments/environment';
import { AlertMessageModelComponent } from "app/main/pages/alert-message-model/alert-message-model.component";
import { NotificationsService } from "angular2-notifications";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "coworker-review-answer",
    templateUrl: "./coworker-review-answer.component.html",
    styleUrls: ["./coworker-review-answer.component.scss"]
})

export class CoworkerReviewAnswerComponent implements OnInit {
    showSpinner: boolean = false;
    requestModel: RequestCommonBase = new RequestCommonBase();
    coworkerReviewAnswerList = [];
    pagination: any;
    //roleName: string;
    host: string;
    @ViewChild('allSelected', { static: false }) private allSelected: MatOption;
    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private settingsService: SettingsService,
        private notificationsService: NotificationsService,
        private translate: TranslateService,
        private dialog: MatDialog) {
        this.translationLoaderService.loadTranslations(danish, english);
    }

    ngOnInit() {
        this.host = environment.url;
        //this.roleName = this.authService.getUserRole();
        this.getCoworkerReviewAnswerList();
    }

    getCoworkerReviewAnswerList() {
        this.showSpinner = true;
        this.settingsService.GetEmployeeReviewAnswerList<ResponseBase>(this.requestModel).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.result) {
                    this.coworkerReviewAnswerList = response.result;
                    this.pagination = response.pagination;
                }
                else {
                    this.coworkerReviewAnswerList = [];
                }
            },
            error: err => {
                this.showSpinner = false;
                this.coworkerReviewAnswerList = [];
            },
            complete: () => { }
        });
    }


    receivePaginatonEvent(event) {
        this.requestModel.Index = event.pageIndex;
        this.requestModel.Limit = event.pageSize;
        this.getCoworkerReviewAnswerList();
    }

    openAnswer(question) {
        question.moduleName="coworker";
        const dilogRef = this.dialog.open(AnswerStatusLogComponent, {
            height: "70%",
            width: "70%",
            data: question
        })
        dilogRef.afterClosed().subscribe(result => {
            if (result) {

            }
        });
    }
    onImgError(event) { 
        event.target.src = 'assets/images/vibe-images/user-profile/default_profile_picture.jpg';
    }

    deleteReviewAnswer(question){
        const dilogRef = this.dialog.open(AlertMessageModelComponent, {
            height: '20%',
            data: { Header: 'Headers.DeleteMessage' }
        })
        dilogRef.afterClosed().subscribe(result => {
            if (result) {
                this.showSpinner = true;
                this.settingsService.DeleteCoworkerReviewAnswer<ResponseBase>(question.id).subscribe({
                    next: (response: ResponseBase) => {
                        if (response.statusCode == 200) {                            
                            this.translate.get("Message.Deleted").subscribe({
                                next: (res) => {
                                    this.notificationsService.create(res);
                                },
                                error: err => { }
                            });
                            this.getCoworkerReviewAnswerList();
                        }
                    },
                    error: err => {
                        this.translate.get("Message.Failed").subscribe({
                            next: (res) => {
                                this.notificationsService.error(res);
                            },
                            error: err => { }
                        });
                        this.showSpinner = false;
                    },
                    complete: () => {
                        this.showSpinner = false;
                    }
                })
            }
        });
    }
    
    deleteReviewQuestion(question) {
        const dilogRef = this.dialog.open(AlertMessageModelComponent, {
            height: '20%',
            data: { Header: 'Headers.DeleteMessage' }
        })
        dilogRef.afterClosed().subscribe(result => {
            if (result) {
                this.showSpinner = true;
                this.settingsService.DeleteAllCoworkerReviewAnswerByQuestionId<ResponseBase>(question.id).subscribe({
                    next: (response: ResponseBase) => {
                        if (response.statusCode == 200) {
                            this.translate.get("Message.Deleted").subscribe({
                                next: (res) => {
                                    this.notificationsService.create(res);
                                },
                                error: err => { }
                            });
                            this.getCoworkerReviewAnswerList();
                        }
                    },
                    error: err => {
                        this.translate.get("Message.Failed").subscribe({
                            next: (res) => {
                                this.notificationsService.error(res);
                            },
                            error: err => { }
                        });
                        this.showSpinner = false;
                    },
                    complete: () => {
                        this.showSpinner = false;
                    }
                })
            }
        });
    }
}