import { Component, OnInit, ElementRef, ViewChild, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';

import { CommonService } from 'app/core/services/common.service';
import { NotificationsService } from 'angular2-notifications';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { RequestMaterial,  RequestOfferMaterialCalculation } from 'app/main/model/RequestBase';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { MaterialService } from 'app/main/pages/material/material.service';
import { QuickInvoiceMaterials, OfferMaterialCalculation } from 'app/main/model/Material';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FormService } from 'app/core/services/form.service';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';
import { MaterialType } from 'app/constant/common.constant';
import { PaintSelectTime } from 'app/constant/common.constant';
import { AppendixCalculationComponent } from '../appendix-calculation/appendix-calculation.component';
import { NewMaterialComponent } from '../../new-material/new-material.component';
import { CopyOfferMaterialComponent } from '../copy-offer-material/copy-offer-material.component';

@Component({
    selector: 'vibe-material-material',
    templateUrl: './material.component.html',
    styleUrls: ['./material.component.scss']
})
export class OfferItemCalculationMaterialComponent implements OnInit {
    @ViewChild("barCode", { static: false }) barcodeFeild: ElementRef;
    showMessage: boolean = false;
    showSpinner = false;
    offerMaterials: QuickInvoiceMaterials[] = [];
    Materials: OfferMaterialCalculation[] = [];
    tempMaterials: OfferMaterialCalculation[] = [];
    currentselectedMatObj: OfferMaterialCalculation = new OfferMaterialCalculation();
    objAddProjectMaterial: OfferMaterialCalculation;
    editedOfferMaterialObj: any = {};
    isEdit: boolean = false;
    editIndex: number = 0;
    //roleName: any;
    IsAddManualMaterial: boolean = true;
    AddedMaterials: any[] = [];
    StoreAddedMaterial: any;
    OnSaveAddedMaterial: any;
    ScanBarcode: string = "";
    DisplayAddedMaterial: boolean = false;
    MaterialName: any = "";
    materialForm: FormGroup;
    isBarcodeValue: boolean = false;
    isSaveData: boolean = false;
    MaterialText: any = '';
    MaterialItemNumber: any = '';
    MaterialNumberOfTimes: any = '';
    //Material calculated value stored variable
    materialTotalPrice: number = 0;
    materialPersonalTotalPrice: number = 0;
    materialTotalPriceWithVAT: number = 0;
    materialTotalPriceProfit: number = 0;
    materialTotalPriceProfitWithVAT: number = 0;
    purchasePriceTotal: number = 0;
    materialType: number = 0;
    selectMaterialtype = MaterialType;
    offerMaterialCalculationRequest: RequestOfferMaterialCalculation;
    offerMaterialCalculationList: any = [];
    offerMaterialId: any = "";
    isUpdatedRecord: boolean = false;
    offersMaterialPriceId: string = "";
    materialPlainText: any;
    materialDefaultPrice: any;
    materialStartM1: any = 0;
    materialStartM2: any;
    materialStartM3: any;
    materialPrice: any;
    isAddMeterRangePrice: boolean = false;
    settingMasterValues:any=[];
    isUseScannerDevice: boolean = false;
    //Initiators
    constructor(private translationLoader: FuseTranslationLoaderService,
        public dialogRef: MatDialogRef<OfferItemCalculationMaterialComponent>,
        private dialog: MatDialog,
        private materialService: MaterialService,
        private translate: TranslateService,
        private notificationsService: NotificationsService,
        private formBuilder: FormBuilder,
        private formService: FormService,
        private commonService: CommonService,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
        this.showSpinner = true;
        this.settingMasterValues = JSON.parse(localStorage.getItem("settingMasterValues")) || [];
        const tempMaterialOtherSettingList = this.settingMasterValues.find(s => s.key1 === "Material" && s.key2 === "OtherSetting");
        if (tempMaterialOtherSettingList && tempMaterialOtherSettingList.value) {
            const sessionVal = JSON.parse(tempMaterialOtherSettingList.value);
            if (sessionVal && sessionVal.IsUseScannerDevice)
                this.isUseScannerDevice = sessionVal.IsUseScannerDevice;
        }
        var reqModel = new RequestMaterial();
        this.GetMaterial(reqModel, true);
        this.objAddProjectMaterial = new OfferMaterialCalculation();
        if (this.data) {
            //this.roleName = this.data.roleName;
            this.editedOfferMaterialObj = this.data.editedOfferMaterialObj;
            this.offerMaterials = this.data.offerMaterials;
            this.materialTotalPrice = this.data.materialTotalPrice;
            this.materialTotalPriceWithVAT = this.data.materialTotalPriceWithVAT;
            this.materialPersonalTotalPrice = this.data.materialPersonalTotalPrice;
            this.purchasePriceTotal = this.data.purchasePriceTotal;
            this.offerMaterialId = this.data.offerMaterialId;
            this.offersMaterialPriceId = this.data.offerMaterialPriceId;
            if (this.editedOfferMaterialObj && (this.editedOfferMaterialObj.materialId || this.editedOfferMaterialObj['material'])) {
                this.currentselectedMatObj.materialId = this.data.editedOfferMaterialObj.materialId ? this.editedOfferMaterialObj.materialId : this.editedOfferMaterialObj.material.id;
                this.IsAddManualMaterial = true
            }
        }
        if (this.data.MaterialObject) {
            this.MaterialText = this.data.MaterialObject.text;
            this.MaterialItemNumber = this.data.MaterialObject.itemNumber;
            const filteredData = this.data.MaterialObject.offerMaterial && this.data.MaterialObject.offerMaterial.offerMaterialPriceList.length > 0 ? this.data.MaterialObject.offerMaterial.offerMaterialPriceList.filter((obj) => obj.isDefault === true) : [];
            if (filteredData.length > 0) {
                const matchingOption = PaintSelectTime.find((option) => option.id === this.data.NumberOfTimes.type);
                if (matchingOption)
                    this.MaterialNumberOfTimes = matchingOption.value;
            }
        }
        if (this.data.NumberOfTimes) {
            this.materialPlainText = this.data.NumberOfTimes.paintText ? this.data.NumberOfTimes.paintText + " " : "";
            this.isAddMeterRangePrice = this.data.NumberOfTimes.isAddMeterRangePrice;
            if (this.isAddMeterRangePrice) {
                this.materialPlainText += this.data.NumberOfTimes.priceMeter1 > 0 ? ("(" + (this.data.NumberOfTimes.startMeter1) + " - " + (this.data.NumberOfTimes.endMeter1) + ")" + "  " + (this.data.NumberOfTimes.priceMeter1)) : "";
                this.materialPlainText += this.data.NumberOfTimes.priceMeter2 > 0 ? (this.data.NumberOfTimes.priceMeter1 > 0 ? ", " : "") + ("(" + (this.data.NumberOfTimes.startMeter2) + " - " + (this.data.NumberOfTimes.endMeter2) + ")" + "  " + (this.data.NumberOfTimes.priceMeter2)) : "";
                this.materialPlainText += this.data.NumberOfTimes.priceMeter3 > 0 ? (this.data.NumberOfTimes.priceMeter1 > 0 ? ", " : "") + ("(" + (this.data.NumberOfTimes.startMeter3) + " - " + (this.data.NumberOfTimes.endMeter3) + ")" + "  " + (this.data.NumberOfTimes.priceMeter3)) : "";
            }
            else
                this.materialDefaultPrice = ((this.data.NumberOfTimes.price ? "Pris : " : "") + "" + (this.data.NumberOfTimes.price ? this.data.NumberOfTimes.price : ""));
        }

        //if (this.roleName == "Admin") {
        this.materialForm = this.formBuilder.group({
            text: [{ value: null, disabled: true }],
            //colorCode: [null],
            quantity: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(9999999999)]],
            retailPrice: [{ value: null, disabled: true }, [Validators.required, Validators.minLength(1), Validators.maxLength(999999999)]],
            //personalPrice: [{ value: null, disabled: true }, [Validators.required, Validators.minLength(1), Validators.maxLength(9999999999)]],
            discountPercent: [{ value: 0, disabled: true }],
            discount: [{ value: 0, disabled: true }],
            imagePath: [null],
            offerMaterialId: [this.offerMaterialId],
            rows: [null],
            // timer: [null],
        });
        // } else {
        //     this.materialForm = this.formBuilder.group({
        //         text: [{ value : null, disabled: true }],
        //         colorCode: [null, ''],
        //         quantity: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(9999999999)]],
        //         retailPrice: [{ value : null, disabled: true }],
        //         personalPrice: [{ value : null, disabled: true }],
        //         discountPercent: [{ value : 0, disabled: true }],
        //         discount: [{ value : 0, disabled: true }],
        //         imagePath: [null],
        //         offerMaterialId:[this.offerMaterialId],
        //         rows: [null],
        //         timer: [null],
        //     });
        // }


        if (this.editedOfferMaterialObj['text']) {
            this.isEdit = true;
            this.currentselectedMatObj.materialId = this.editedOfferMaterialObj.materialId ? this.editedOfferMaterialObj.materialId : this.editedOfferMaterialObj.material.id,
                setTimeout(() => {
                    this.currentselectedMatObj.id = this.editedOfferMaterialObj.id;
                    this.currentselectedMatObj.itemNumber = this.editedOfferMaterialObj.itemNumber;
                    this.currentselectedMatObj.isRequiredText = this.editedOfferMaterialObj.material.isRequiredText;
                    this.currentselectedMatObj.text = this.editedOfferMaterialObj.text;
                    this.currentselectedMatObj.unit = this.editedOfferMaterialObj.material.unit;
                    this.currentselectedMatObj.colorCode = this.editedOfferMaterialObj.colorCode;
                    this.currentselectedMatObj.quantity = this.editedOfferMaterialObj.quantity;
                    this.currentselectedMatObj.personalPrice = this.editedOfferMaterialObj.personalPrice;
                    this.currentselectedMatObj.retailPrice = this.editedOfferMaterialObj.retailPrice;
                    this.currentselectedMatObj.personalPriceDiscount = this.editedOfferMaterialObj.personalPriceDiscount;
                    this.currentselectedMatObj.retailPriceDiscount = this.editedOfferMaterialObj.retailPriceDiscount;
                    this.currentselectedMatObj.discountPercent = this.editedOfferMaterialObj.discountPercent;
                    this.currentselectedMatObj.noStockTraek = this.editedOfferMaterialObj.noStockTraek;
                    this.currentselectedMatObj.offersMaterialPriceId = this.editedOfferMaterialObj.offersMaterialPriceID;
                }, 900);
        }
        if (this.offerMaterials.length > 0) {
            this.offerMaterials.forEach(o => {
                o.isRequiredText = o.material.isRequiredText;
                o.unit = o.material.unit;
                o.rows = o.material.rows;
            });
            this.AddedMaterials = this.offerMaterials;
            this.OnSaveAddedMaterial = JSON.stringify(this.AddedMaterials);
            if (this.editedOfferMaterialObj.id) {
                var findMat = this.AddedMaterials.find(o => o.id == this.editedOfferMaterialObj.id);
                this.currentselectedMatObj = findMat;
            }
            this.showMessage = this.offerMaterials.length > 0 ? false : true;
        }
        if (this.offerMaterials.length == 0 && this.AddedMaterials.length == 0) {
            this.showMessage = true;
        }
        this.calculateProjMaterialPriceTotal();
        this.showSpinner = false;
    }

    GetMaterial(model, displayLoader) {
        this.showSpinner = displayLoader;
        model.Limit = 400;
        model.OrderBy = "Text";
        this.materialService.Get<ResponseBase>(model)
            .subscribe((response: ResponseBase) => {
                if (response) {
                    this.showSpinner = false;
                    this.bindMaterialDropdownOnEdit();
                    this.getNextRequestForMaterial(response.pagination, model, response.result, displayLoader);
                    setTimeout(() => {
                        if (this.barcodeFeild && this.barcodeFeild.nativeElement)
                            this.barcodeFeild.nativeElement.focus();
                    });
                }
                else {
                    this.showSpinner = false;
                    this.Materials = [];
                    this.tempMaterials = [];
                }
            },
                error => (err) => {
                    this.showSpinner = false;
                    this.Materials = [];
                    this.tempMaterials = [];
                },
                () => {
                });
    }

    DropDownSearchMaterial(MaterialName) {
        if (this.materialType > 0) {
            this.Materials = this.tempMaterials.filter(o => o.text.toLowerCase().includes(MaterialName.toLowerCase()) || o.itemNumber.toLowerCase().includes(MaterialName.toLowerCase()) && o.materialType && o.materialType && o.materialType.split(',').find(p => p == (this.materialType.toString())));
        } else {
            this.Materials = this.tempMaterials.filter(o => o.text.toLowerCase().includes(MaterialName.toLowerCase()) || o.itemNumber.toLowerCase().includes(MaterialName.toLowerCase()));
        }
    }

    GetMaterialOnClear(value) {
        if (!value) {
            if (this.materialType > 0) {
                this.Materials = this.tempMaterials.filter(x => x.materialType && x.materialType.split(',').find(p => p == (this.materialType.toString())));
            } else {
                this.Materials = this.tempMaterials;
            }
        }
    }

    getNextRequestForMaterial(pagination, model, response, displayLoader) {
        if (pagination.index == 1) {
            this.Materials = response;
            this.tempMaterials = response;
        }
        else {
            this.Materials.push(...response);
            this.tempMaterials = this.Materials;
        }
        localStorage.setItem('Materials', JSON.stringify(this.Materials));
        model.Index = pagination.index + 1;
        model.Limit = pagination.limit;
        model.TotalRows = pagination.totalCount;
        if (pagination.totalCount > pagination.index * pagination.limit)
            this.GetMaterial(model, displayLoader);
    }

    refreshMaterialList() {
        this.offerMaterialCalculationRequest = new RequestOfferMaterialCalculation();
        this.offerMaterialCalculationRequest.Index = 1;
        this.offerMaterialCalculationRequest.Limit = 400;
        this.offerMaterialCalculationRequest.OfferMaterialID = this.offerMaterialId;
        this.GetOfferItemCalculationList();

    }

    //Edit Material
    editOfferMaterial(index) {
        this.editIndex = index;
        this.isEdit = true;
        this.IsAddManualMaterial = true;
        var editedMaterial = this.AddedMaterials[index];
        if (editedMaterial) {
            this.currentselectedMatObj = Object.assign({}, editedMaterial);
            this.currentselectedMatObj.materialId = editedMaterial.material.id;
            this.currentselectedMatObj.retailPrice = editedMaterial.material.retailPrice;
            this.currentselectedMatObj.personalPrice = editedMaterial.material.personalPrice;
            this.currentselectedMatObj.offersMaterialId = this.offerMaterialId;
            this.currentselectedMatObj.text = editedMaterial.material.text;
            this.currentselectedMatObj.itemNumber = editedMaterial.material.itemNumber;
            this.currentselectedMatObj.colorCode = editedMaterial.colorCode;
            this.currentselectedMatObj.imagePath = editedMaterial.material.imagePath;
            this.currentselectedMatObj.discountPercent = 0;
            this.currentselectedMatObj.rows = editedMaterial.material.rows;
            this.currentselectedMatObj.offersMaterialPriceId = editedMaterial.offersMaterialPriceID;
        }
    }

    onChangeMaterial(matID) {
        this.isEdit = false;
        this.showSpinner = true;
        this.currentselectedMatObj = new OfferMaterialCalculation();
        //this.materialForm.reset();
        var material = this.Materials.find(o => o.id == matID);
        if (material) {
            var matCopy = Object.assign({}, material);
            //this.currentselectedMatObj = matCopy;
            this.currentselectedMatObj.materialId = material.id;
            this.currentselectedMatObj.material = material;
            this.currentselectedMatObj.id = "";
            this.currentselectedMatObj.unit = matCopy.unit;
            this.currentselectedMatObj.retailPrice = matCopy.retailPrice;
            this.currentselectedMatObj.personalPrice = matCopy.personalPrice;
            this.currentselectedMatObj.rows = matCopy.rows;
            if (this.currentselectedMatObj.rows && parseInt(this.currentselectedMatObj.rows) > 0)
                this.currentselectedMatObj.quantity = 1 / parseInt(this.currentselectedMatObj.rows);
            else
                this.currentselectedMatObj.quantity = 1;
            this.currentselectedMatObj.itemNumber = matCopy.itemNumber;
            this.currentselectedMatObj.colorCode = matCopy.colorCode;
            this.currentselectedMatObj.discountPercent = 0;
            this.currentselectedMatObj.noStockTraek = matCopy.noStockTraek;
            this.currentselectedMatObj.imagePath = matCopy.imagePath;
            this.currentselectedMatObj.offersMaterialId = this.offerMaterialId;
            this.currentselectedMatObj.text = matCopy.text;
            this.currentselectedMatObj.offersMaterialPriceId = this.offersMaterialPriceId;
            this.materialForm.patchValue(this.currentselectedMatObj);

        }
        else {
            this.translate.get("MESSAGES.NoMaterialFound").subscribe({
                next: (res) => {
                    this.notificationsService.error(res);
                    this.getFailedTone();
                },
                error: err => {
                }
            });
        }
        this.showSpinner = false;
    }

    /**
     * Add Material using Scanner START
     */
    AddMaterialUsingScanner() {
        this.IsAddManualMaterial = false;
        setTimeout(() => {
            if (this.barcodeFeild && this.barcodeFeild.nativeElement)
                this.barcodeFeild.nativeElement.focus();
        }, 500);
    }

    BarcodeAddMaterialToList(event) {
        this.isBarcodeValue = true;
        // var that=this;
        setTimeout(() => {
            if (this.ScanBarcode) {
                var barcode = this.ScanBarcode.trim();
                var findMaterial = this.Materials.find(o => o.barcode == barcode || o.barcode2 == barcode);
                if (!findMaterial) {
                    this.translate.get("MESSAGES.NoBarcodeAttachedToMaterial").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                            this.getFailedTone();
                            return
                        },
                        error: err => {
                        }
                    });
                }
                else {
                    var addedMaterial = this.AddedMaterials.find(o =>
                        o.itemNumber == findMaterial.itemNumber
                        && o.colorCode == findMaterial.colorCode);
                    if (addedMaterial) {
                        addedMaterial.quantity = addedMaterial.quantity + findMaterial.purchaseQuantity;
                        addedMaterial.totalPrice = addedMaterial.quantity * addedMaterial.retailPrice;
                        addedMaterial.personalPriceTotal = addedMaterial.quantity * addedMaterial.personalPrice;
                        addedMaterial.totalPriceVAT = (((addedMaterial.totalPrice * 25) / 100) + addedMaterial.totalPrice);
                        this.currentselectedMatObj = addedMaterial;
                        const index: number = this.AddedMaterials.indexOf(addedMaterial);
                        this.AddedMaterials.splice(index, 1);
                        this.AddedMaterials.unshift(addedMaterial);
                        this.currentselectedMatObj = new OfferMaterialCalculation();
                        this.materialForm.reset();
                    }
                    else {
                        var copyMaterial = Object.assign({}, findMaterial);
                        this.currentselectedMatObj = new OfferMaterialCalculation();
                        this.objAddProjectMaterial.materialId = copyMaterial.id;
                        this.objAddProjectMaterial.createdDate = new Date();
                        this.objAddProjectMaterial.text = copyMaterial.text;
                        this.objAddProjectMaterial.itemNumber = copyMaterial.itemNumber;
                        this.objAddProjectMaterial.colorCode = copyMaterial.colorCode;
                        this.objAddProjectMaterial.personalPrice = copyMaterial.personalPrice;
                        this.objAddProjectMaterial.retailPrice = copyMaterial.retailPrice;
                        this.objAddProjectMaterial.quantity = copyMaterial.purchaseQuantity;
                        this.objAddProjectMaterial.discountPercent = copyMaterial.discountPercent;
                        this.objAddProjectMaterial.personalPriceDiscount = copyMaterial.personalPriceDiscount;
                        this.objAddProjectMaterial.retailPriceDiscount = copyMaterial.retailPriceDiscount;
                        this.objAddProjectMaterial.material = copyMaterial;
                        this.objAddProjectMaterial.rows = copyMaterial.rows;

                        var copyObj = Object.assign({}, this.objAddProjectMaterial);
                        this.AddedMaterials.push(copyObj);
                        this.currentselectedMatObj = new OfferMaterialCalculation();
                        this.materialForm.reset();
                    }
                    this.calculateProjMaterialPriceTotal();
                    this.getSuccessTone();
                }
                this.ScanBarcode = "";
            }
        }, 500);
    }
    //END


    /**
     * Add Material Manual START
     */
    AddMaterialManual() {
        this.IsAddManualMaterial = true;
    }

    AddToMaterialListManuallynew(isClosePopup) {
        if (this.IsAddManualMaterial) {
            this.AddToMaterialListManually(isClosePopup);
        }
        else {
            this.AddToMaterialListBarcode(isClosePopup);
        }
    }
    AddToMaterialListBarcode(isClosePopup) {
        var isSave = true;
        //for (let mat of this.AddedMaterials) {
        //    if (mat.material && mat.material.isRequiredText == true
        //        && (mat.colorCode == null || mat.colorCode == undefined || mat.colorCode == "")) {
        //        isSave = false;
        //        this.translate.get("MESSAGES.AddColorCode").subscribe({
        //            next: (res) => {
        //                this.notificationsService.error(res);
        //                this.getFailedTone();
        //            },
        //            error: err => {
        //            }
        //        });
        //        break;
        //    }
        //}
        if (isSave) {
            this.MaterialSave(isClosePopup);
        }
    }
    AddToMaterialListManually(isClosePopup) {

        var isSave = true;
        //const materialColorcodeControl = this.materialForm.get('colorCode');
        //var materialtemp = this.Materials.find(o => o.itemNumber == this.currentselectedMatObj.itemNumber);
        //if (materialtemp && materialtemp.isRequiredText) {
        //    materialColorcodeControl.setValidators([Validators.required]);
        //} else {
        //    materialColorcodeControl.setValidators(null);
        //}
        //materialColorcodeControl.updateValueAndValidity();
        this.formService.markFormGroupTouched(this.materialForm);
        isSave = this.materialForm.valid;

        //check already exists
        var matObj = this.AddedMaterials.find(o => (o.itemNumber == this.currentselectedMatObj.itemNumber)
            && (o.colorCode == this.currentselectedMatObj.colorCode));
        if (this.isEdit == true) {
            this.editIndex = this.AddedMaterials.findIndex(o => o.itemNumber == this.currentselectedMatObj.itemNumber
                && o.colorCode == this.currentselectedMatObj.colorCode);
            var materialObj = Object.assign([], this.AddedMaterials);
            materialObj.splice(this.editIndex, 1);
            matObj = materialObj.find(o => o.itemNumber == this.currentselectedMatObj.itemNumber
                && o.colorCode == this.currentselectedMatObj.colorCode);
        }
        else {
            matObj = this.AddedMaterials.find(o => (o.itemNumber == this.currentselectedMatObj.itemNumber)
                && (o.colorCode == this.currentselectedMatObj.colorCode));
        }
        if (matObj) {
            this.translate.get("MESSAGES.MaterialAlreadyExists").subscribe({
                next: (res) => {
                    this.notificationsService.error(res);
                    this.getFailedTone();
                },
                error: err => {
                }
            });
            return;
        }

        //add new material
        if (isSave && !this.currentselectedMatObj.id) {
            var material = this.Materials.find(o => o.itemNumber == this.currentselectedMatObj.itemNumber);
            var copyMaterial = Object.assign({}, material);
            this.currentselectedMatObj.material = copyMaterial;
            this.currentselectedMatObj.materialId = copyMaterial.id;
            this.currentselectedMatObj.offersMaterialId = this.offerMaterialId;
            this.currentselectedMatObj.createdDate = new Date();
            if (this.isEdit == false) {
                this.AddedMaterials.push(this.currentselectedMatObj);
                this.OnSaveAddedMaterial = JSON.stringify(this.AddedMaterials);
            }
        }
        else {
            var mat = this.AddedMaterials.find(o => o.id == this.currentselectedMatObj.id);
            if (mat) {
                mat.totalPrice = this.getTotalPrice(this.currentselectedMatObj.quantity, this.currentselectedMatObj.retailPrice.toFixed(2));
                mat.personalPriceTotal = this.getTotalPrice(this.currentselectedMatObj.quantity, this.currentselectedMatObj.personalPrice.toFixed(2));
            }
        }
        this.AddedMaterials.forEach(i => {
            i.isRequiredText = i.material.isRequiredText;
            i.unit = i.material.unit;
            if (i.id == this.currentselectedMatObj.id) {
                i.quantity = this.currentselectedMatObj.quantity;
                i.colorCode = this.currentselectedMatObj.colorCode;
                // i.timer = this.currentselectedMatObj.timer;
            }
        });
        this.OnSaveAddedMaterial = JSON.stringify(this.AddedMaterials);
        if (isSave) {
            this.MaterialSave(isClosePopup);
        }
    }

    MaterialSave(isClosePopup) {
        this.showSpinner = true;
        for (var i = 0; i < this.AddedMaterials.length; i++) {
            if (this.AddedMaterials[i].personalPrisTotal) {
                this.AddedMaterials[i].personalPrisTotal = this.AddedMaterials[i].personalPriceTotal;
            }
            if (this.AddedMaterials[i].quantity) {
                this.AddedMaterials[i].quantity = +this.AddedMaterials[i].quantity.toFixed(2);
            }
        }
        this.materialService.addOrUpdateOfferMaterialForCalculation<ResponseBase>(this.AddedMaterials)
            .subscribe((response: ResponseBase) => {
                this.isUpdatedRecord = true;
                this.materialForm.reset();
                this.currentselectedMatObj = new OfferMaterialCalculation();
                this.AddedMaterials = response.result;
                this.AddedMaterials.forEach(i => {
                    i.isRequiredText = i.material.isRequiredText;
                    i.unit = i.material.unit;
                });
                this.OnSaveAddedMaterial = JSON.stringify(this.AddedMaterials);
                this.translate.get("MESSAGES.Created").subscribe({
                    next: (res) => {
                        this.notificationsService.create(res);
                    },
                    error: err => {
                    }
                });
                this.calculateProjMaterialPriceTotal();
            },
                error => (err) => {
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                            this.getFailedTone();
                        },
                        error: err => {
                        }
                    });
                }, () => {
                    this.showSpinner = false;
                    if (isClosePopup) {
                        this.dialogRef.close(true);
                    }
                });
    }

    calculateProjMaterialPriceTotal() {
        this.materialTotalPriceWithVAT = 0;
        this.materialPersonalTotalPrice = 0;
        this.materialTotalPrice = 0;
        this.purchasePriceTotal = 0;
        this.AddedMaterials.forEach((e: any) => {
            if (!e.totalPrice) {
                e.totalPrice = this.getTotalPrice((e.quantity ? e.quantity : 0), e.material.retailPrice);
            }
            if (!e.personalPriceTotal) {
                e.personalPriceTotal = this.getTotalPrice((e.quantity ? e.quantity : 0), e.material.personalPrice);
            }
            this.materialTotalPrice += e.totalPrice;
            if (e.material.personalPrice && e.material.personalPrice != 0) {
                this.materialPersonalTotalPrice += e.personalPriceTotal;
            }
            else {
                e.personalPrice = 0;
            }

            if (e.totalPriceVAT) {
                this.materialTotalPriceWithVAT += e.totalPriceVAT;
            } else {
                var vat = (e.totalPrice * 25) / 100;
                this.materialTotalPriceWithVAT += (e.totalPrice + vat);
            }
            if (e.material.purchasePrice) {
                this.purchasePriceTotal += e.material.purchasePrice;
            }
        });
    }

    getTotalPrice(qty, price) {
        var isNegative = false;
        if (qty < 0)
            isNegative = true;
        if (price < 0)
            isNegative = true;
        var total = (qty * price);
        if (isNegative)
            total = -Math.abs(total)
        return total;
    }

    openMaterialDeleteDialog(index, materialobj) {
        var message = "";
        var materialText = materialobj.text ? materialobj.text : materialobj.material.text;
        this.translate.get("Headers.DeleteMaterial").subscribe({
            next: (res) => { message = res; }, error: err => { }
        });
        message = message.replace("@MaterialName", materialText)
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: '200px',
            data: { Header: message }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                var deletedMat = this.AddedMaterials[index];
                if (deletedMat && deletedMat.id) {
                    this.removeOfferMaterialCalculation(deletedMat.id);
                }
                else {
                    this.AddedMaterials.splice(index, 1);
                }
            }
        });
    }

    removeOfferMaterialCalculation(id) {
        this.showSpinner = true;
        this.isSaveData = true;
        this.materialService.removeOfferMaterialCalculation<ResponseBase>(id)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.isUpdatedRecord = true;
                    this.AddedMaterials = this.AddedMaterials.filter(m => m.id != id);
                    this.AddedMaterials.forEach(i => {
                        i.isRequiredText = i.material.isRequiredText;
                        i.unit = i.material.unit
                    });
                    this.OnSaveAddedMaterial = JSON.stringify(this.AddedMaterials);
                    this.showMessage = this.AddedMaterials.length > 0 ? false : true;
                    this.showSpinner = false;
                    this.materialForm.reset();
                    this.currentselectedMatObj = new OfferMaterialCalculation();
                    this.currentselectedMatObj.id = "";
                    this.currentselectedMatObj.quantity = 0.00
                    this.isEdit = false;
                    this.materialTotalPrice = 0;
                    this.materialTotalPriceWithVAT = 0;
                    this.materialPersonalTotalPrice = 0;
                    this.calculateProjMaterialPriceTotal();
                    this.isSaveData = false;
                    this.translate.get("MESSAGES.Deleted").subscribe({
                        next: (res) => {
                            this.notificationsService.create(res);
                        },
                        error: err => {
                        }
                    });
                },
                error: err => {
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                            this.showSpinner = false;
                            this.isSaveData = false;
                        },
                        error: err => {
                        }
                    });
                },
                complete: () => {
                    this.showSpinner = false;
                    this.isSaveData = false;
                }
            });
    }
    // END
    //Back button START
    ngOnBackClick() {
        this.dialogRef.close(this.isUpdatedRecord);
    }
    //END

    getFailedTone() {
        this.commonService.playFailedTone();
    }

    getSuccessTone() {
        this.commonService.playSuccessTone();
    }
    _handleKeydown(event: KeyboardEvent) {
        if (event.keyCode === 13) {
            event.stopPropagation();
        }
    }

    bindMaterialDropdownOnEdit() {
        if (this.Materials.length && this.editedOfferMaterialObj && this.editedOfferMaterialObj.material) {
            var material = this.Materials.find(x => x.id == this.editedOfferMaterialObj.material.id);
            if (material) {
                this.currentselectedMatObj.materialId = material.id;
            }
        }
    }

    getMaterialOnType(typeId) {
        if (typeId != 0) {
            this.Materials = this.tempMaterials.filter(x => x.materialType && x.materialType.split(',').find(p => p == (this.materialType.toString())));
        } else {
            this.Materials = this.tempMaterials;
        }
    }

    GetOfferItemCalculationList() {
        this.showSpinner = true;
        this.materialService.GetOfferItemCalculationList<ResponseBase>(this.offerMaterialCalculationRequest)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.offerMaterialCalculationList = response.result;
                    this.materialTotalPrice = 0;
                    this.materialTotalPriceWithVAT = 0;
                    this.materialPersonalTotalPrice = 0;
                    this.calculateProjMaterialPriceTotal();
                    this.offerMaterialCalculationList.filter(p => { p.quantity = parseFloat(p.quantity.toFixed(2)); });
                    this.showMessage = this.offerMaterialCalculationList.length > 0 ? false : true;
                },
                error: err => { },
                complete: () => { this.showSpinner = false; }
            });
    }

    showAppenidxCalculation() {
        var sendData = {};
        sendData = {
            'MaterialTreatmentType': this.data.MaterialObject.offerMaterial.treatmentType,
            'isViewInModal': true,
        }
        var dialogRef = this.dialog.open(AppendixCalculationComponent, {
            height: '70%',
            width: '90%',
            data: sendData
        });

        dialogRef.afterClosed().subscribe(result => {

        });
    }

    copyOfferMaterial() {
        var sendData = {};
        sendData = {
            'MaterialTreatmentType': this.data.MaterialObject.offerMaterial.treatmentType,
        }
        var dialogRef = this.dialog.open(CopyOfferMaterialComponent, {
            height: '60%',
            width: '60%',
            data: {
                "materialList": this.tempMaterials,
                "offersMaterialPriceId": this.offersMaterialPriceId,
                "materialHeader": (this.MaterialText ? this.MaterialText : "") + " " +
                    (this.MaterialItemNumber ? this.MaterialItemNumber : "") + " " +
                    (this.MaterialNumberOfTimes ? this.MaterialNumberOfTimes : ""),

            }
        });

        dialogRef.afterClosed().subscribe(result => {

        });
    }

    gotoMaterial(material) {
        if (material && material.materialID) {
            const dialogRef = this.dialog.open(NewMaterialComponent, {
                height: '90%',
                width: '90%',
                data: material.materialID,
            });
            dialogRef.afterClosed().subscribe(result => { });
        }
    }
}
