export const locale = {
    lang: 'ds',
    data: {
        'STATUS': {
            'Created': 'lavet',
            'Updated': 'Opdateret',
            'Deleted': 'Slettet',
            'FileUploaded': 'Fil uploadet',
            'FileDeleted': 'Fil slettet',
            'Email': 'Email',
        },
        'TITLE': {
            'CustomerPagination': 'Antal log pr. side',
        },
        'BUTTONS': {
            'BACK': 'Gå tilbage',
            'BTNSAVE': 'Gem',
        },
        'FIELDS': {
            'SRNO': 'Sr nr.',
            'Status': 'Status',
            'Number': 'Nummer',
            'Name': 'Navn',
            'Createdby': 'OprettetAf',
            'CreatedDate': 'OprettetDato',
            'ModifyBy': 'ModificereAf',
            'ModifyDate': 'ModificereDato',
            'CoworkerName': 'Medarbejder',
            'Date': 'Dato',
            'Description': 'Tekst',
            'Action': 'Handling',
        },
        'Headers': {
        },
        'MESSAGES': {
            'NORECORD': 'Ingen registrering fundet',
            'COWORKERCREATED': '{@CoworkerName} har oprettet ny rekord for {@CustomerName}.',
            'COWORKERUPDATED': '{@CoworkerName} har opdateret rekord for {@CustomerName}.',
            'COWORKERDELETED': '{@CoworkerName} har slettet rekord for {@CustomerName}.',
            'FILEUPLOADED': '{@CoworkerName} har uploadet \'{@FileName}\' fil for {@CustomerName}.',
            'FILEDELETED': '{@CoworkerName} har fjernet \'{@FileName}\' fil for {@CustomerName}.',
        },
        'TOOTIPORTITLE':{
            'CustomerDetails':'Se kundedetaljer',
            'ViewEmailTemplate':'Se e-mail-skabelon',
        }
    }
};
