import { Component, OnInit } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { MaterialService } from 'app/main/pages/material/material.service';
import { ResponseBase } from 'app/main/model/ResponseBase';

//translate
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'material-color-code',
    templateUrl: './colorCode.component.html',
    styleUrls: ['./colorCode.component.scss']
})

export class MaterialColorCodeComponent implements OnInit {
    showSpinner: boolean = false;
    colorCodeList = [];
    MaterialColorCode: any;
    colorCodeDetail: any;
    constructor(private translationLoader: FuseTranslationLoaderService,
        private materialService: MaterialService,
        private notificationsService: NotificationsService,
        private translate: TranslateService) {
        this.translationLoader.loadTranslations(danish, english);
    }

    ngOnInit() {
        this.getColorCode();
    }

    getColorCode() {
        this.showSpinner = true;
        this.materialService.GetColorLits().subscribe((response: ResponseBase) => {
            if (response) {
                this.showSpinner = false;
                this.colorCodeDetail = response.result.myResponse[0];
                if (this.colorCodeDetail.value) {
                    this.colorCodeList = JSON.parse(this.colorCodeDetail.value);
                }
            }
        });
    }

    Add() {
        if (this.MaterialColorCode) {
            this.showSpinner = true;
            this.colorCodeList.push(this.MaterialColorCode);
            var colorValues = JSON.stringify(this.colorCodeList);
            this.colorCodeDetail.value = colorValues;
            this.materialService.AddUpdateColorCodeList(this.colorCodeDetail.id, this.colorCodeDetail)
                .subscribe({
                    next: (response: ResponseBase) => {
                        this.showSpinner = false;
                        this.MaterialColorCode = "";
                        this.translate.get("MESSAGES.Updated").subscribe({
                            next: (res) => {
                                this.notificationsService.create(res);
                            },
                            error: err => {
                            }
                        });
                    },
                    error: err => {
                        this.showSpinner = false;
                        this.translate.get("MESSAGES.Failed").subscribe({
                            next: (res) => {
                                this.notificationsService.error(res);
                            },
                            error: err => {
                            }
                        });
                    },
                    complete: () => {
                        this.showSpinner = false;
                    }
                });
        }
    }

    removeColor(color) {
        this.showSpinner = true;
        this.colorCodeList = this.colorCodeList.filter(x => x != color);
        var colorValues = JSON.stringify(this.colorCodeList);
        this.colorCodeDetail.value = colorValues;
        this.materialService.AddUpdateColorCodeList(this.colorCodeDetail.id, this.colorCodeDetail)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.Deleted").subscribe({
                        next: (res) => {
                            this.notificationsService.create(res);
                        },
                        error: err => {
                        }
                    });
                },
                error: err => {
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });
                },
                complete: () => {
                    this.showSpinner = false;
                }
            });
    }
}