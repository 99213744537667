export const locale = {
    lang: 'ds',
    data: {
        'BUTTONS': {
            'Save': 'Save',
            'Back': 'Back'
        },
        'FIELDS': {
            'Notification': 'Notification',
            'SMS': 'SMS',
            'Email': 'Email'
        },
        'MESSAGES': {
            'Created': 'Created successfully',
            'Updated': 'Updated successfully',
            'Deleted': 'Deleted successfully',
            'Failed': 'Operation failed , try later',
        },
    }
}