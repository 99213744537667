export const locale = {
  lang: 'en',
  data: {
    'MESSAGES': {
      'CommingSoon': 'Comming Soon..',
      'Failed': 'Something went wrong !',
      'DataNotFound': 'Data Not Found !',
      'Success': 'Success.',
    }, 
    'LABELS': {
      'OfferRoomDetailList': 'Offer Room Detail List',
      'OfferDiverseDetailList': 'Offer Diverse Detail List',
      'WALL': 'WALL',
      'CEILLING': 'CEILLING',
      'FLOOR': 'FLOOR',
      'Length': 'Length',
      'Width': 'Width',
      'CeilingHeight': 'CeilingHeight',
      'TotalArea': 'TotalArea',
      'ColorCode': 'ColorCode',
      'Room': 'Room',
      'Quantity': 'Quantity',
      'TotalHours': 'Total Hours',
      'RemainingHours': 'Remaining Hours',
      'PaintHours': 'Paint Hours',
      'Unit': 'Unit',
      'Time': 'Number Of Times',
      'Timer-Min': 'Timer / Min'
    },
    'Button': {
      'Save': 'Save',
    },
    'TOOTIPORTITLE':{
      'IsCompleted':'Is Completed',
      'IsWorking':'Is Working',
      'ViewMaterial':'View Material'
    }
  }
};
