export const locale = {
  lang: 'ds',
  data: {
    'TITLE': {
      'Customer': 'Antal kunder pr. side',
      'Material': 'Antal materialer pr. side',
      'Project': 'Antal projekter pr. side',
      'CoWorker': 'Antal medarbejdere pr. side',
      'Reminder': 'Antal påmindelser pr. side',
      'Inquirie': 'Antal forespørgser pr. side',
      'ProjectRecuring': 'Antal projekt tilbagevendende pr. side',
    },
  }
};
