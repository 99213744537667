export const locale = {
    lang: 'en',
    data: {
        'HEADERS': {
            'ProjectsList': 'Projects List'
        },
        'FIELDS': {
            'SRNO': 'Sr.',
            'Number': 'Project Number',
            'CustomerDetail': 'Customer Detail',
            'Address': 'Project Address',
            'DateTime': "Date Time",
            'CoworkerName': 'Coworker Name',
            'Status': 'Status',
            'ProjectHours': 'Project Hours',
            'ProjectWorkingHours': 'Project Working Hours',
            'ProjectRemainingHours': 'Project Remaining Hours',
            'IndoorOutDoor': 'Indoor,Outdoor,Spray Painting,Reclamation*',
            'ShowAll': 'Show all',
            'IsHourlyWage': 'Is case on hourly pay',
            'HourlyWage': 'Hourly wage',
            'Nummber': 'No.',
            'HourlyRate': 'Hourly rate',
            'NegotiatedPrice':'Negotiated Price',
            'Turnover':'Turnover',
            'Rest':'Rest',
            'Employees':'Employees',
            'IncludeHourlyWages': 'Include hourly wages / Display with no amount',
            'IncludeTurnover': 'Turnover >0,00'
        },
        "MESSAGES": {
            'Failed': 'Failed',
            "NORECORD": "No Record Found",
            'GotoProject': 'Go to project'
        },
        'TITLE': {
            'CustomerPagination': 'Number of logs per. page',
        },
        'IsActive': {
            'value1': 'Active',
            'value0': 'Inactive',
        },
        'selectedValueIndoorOutdoorStatus': {
            'value1': 'Indoor ',//Man in a room
            'value2': 'Outdoor', //Sun
            'value3': 'Spray Painting',//spraypaint
            'value4': 'Complaint', //Reklamation
            'value5': 'Staff purchase', //drawing of painter
            'value6': 'Store purchase' //Nordsjø logo
        },
    }
}