import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../../../core/modules/shared.module';
import { FormsModule } from '@angular/forms';
import { VibeMaterialComponent } from './material.component';
import { NewMaterialComponent } from './new-material/new-material.component';
import { PaginationComponent } from '../pagination/pagination.component';
//import { FilterDropdownComponent } from '../filter-dropdown/filter-dropdown.component';
import { MaterialService } from './material.service';
import { VibeMaterialBarcodeModule } from './display-barcode/display-barcode.module';
import { SupplierListComponent } from './component/supplierList/supplierList.component';
import { NgxBarcodeModule } from 'ngx-barcode';
import { OfferComponent } from './component/offer/offer.component';
import { SupplierListArray } from './component/supplierListArray/supplierListArray.component';
import { NgxCurrencyModule } from 'ngx-currency';
import { BarcodeComponent } from './component/barcode/barcode.component';
import { CopyMaterialComponent } from './component/copyMaterial/copyMaterial.component';
import { WrongMaterialBindToOfferPopupComponent } from './component/wrong-material-bind-offer-popup/wrong-material-bind-offer-popup.component';
import { PriceTagComponent } from './component/pricetag/pricetag.component';
import { ShowProjectsMaterialHistoryComponent } from './component/projects-material-history/projects-material-history.component';
import { NgxPrintModule } from 'ngx-print';

import { CustomCurrencyMaskConfig } from "app/core/constant/CurrencyMaskConfiguartion";
import { MaterialColorCodeComponent } from './component/colorCode/colorCode.component';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { MaterialLogComponent } from './component/material-log/material-log.component';
import { MaterialOrderComponent } from './component/material-order/material-order.component';
import { MaterialOrderHistoryComponent } from './component/material-order-history/material-order-history.component';
import { NgPipesModule } from 'ngx-pipes';
import { EditMaterialOrderComponent } from './component/edit-material-order/edit-material-order.component';
import { CKEditorModule } from 'ng2-ckeditor';
import { AddOrderMaterialItemComponent } from './component/add-order-material-item/add-order-material-item.component';
import { SupplierSettingComponent } from './component/supplier-setting/supplier-setting.component';
import { AddSupplierSettingComponent } from './component/supplier-setting/component/add-supplier-setting/add-supplier-setting.component';
import { PriceTagSizeComponent } from './component/pricetagsize/pricetagsize.component';
import { MaterialListComponent } from './component/material-list/material-list.component';
import { OfferItemCalculationMaterialComponent } from './component/material/material.component';
import { MaterialsSoldStockComponent } from './component/materials-sold-stock/materials-sold-stock.component';
import { SelectProjectRequistionComponent } from './component/select-project-requistion-modal/select-project-requistion-modal.component';
import { SanitizeHtmlPipe } from 'app/core/pipes/sanitizeHtml.pipe';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SupplierPriceUploadComponent } from './component/supplier-price-upload/supplier-price-upload.component';
import { MaterialInventoryComponent } from './component/material-inventory/material-inventory.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CopyMaterialOfferItemCalculationComponent } from './component/copy-material-offer-item-calculation/copy-material-offer-item-calculation/copy-material-offer-item-calculation.component';
import { AppendixCalculationComponent } from './component/appendix-calculation/appendix-calculation.component';
import { AddApendixCalculationComponent } from './component/add-apendix-calculation/add-apendix-calculation.component';
import { CopyOfferMaterialComponent } from './component/copy-offer-material/copy-offer-material.component';
import { EditSupplierMaterialComponent } from './component/edit-supplier-material/edit-supplier-material.component';

const routes = [
    {
        path: 'materials',
        component: VibeMaterialComponent,
    },
    {
        path: 'materials/add',
        component: NewMaterialComponent
    },
    {
        path: 'materials/edit/:id',
        component: NewMaterialComponent
    },
    {
        path: 'materials/suppliersetting',
        component: SupplierSettingComponent
    },
    {
        path: 'materials/suppliersetting/add',
        component: AddSupplierSettingComponent
    },
    {
        path: 'materials/suppliersetting/edit/:id',
        component: AddSupplierSettingComponent
    },
    {
        path: 'materials/supplierpriceupload',
        component: SupplierPriceUploadComponent
    },
    {
        path: 'materials/inventory',
        component: MaterialInventoryComponent
    },
    // {
    //     path: 'materials/appendix',
    //     component: AppendixCalculationComponent
    // },
    // {
    //     path: 'materials/appendix/add',
    //     component: AddApendixCalculationComponent,
    // },
    // {
    //     path: 'materials/appendix/edit/:id',
    //     component: AddApendixCalculationComponent,
    // },
];

@NgModule({
    declarations: [
        BarcodeComponent,
        SanitizeHtmlPipe,
        VibeMaterialComponent,
        NewMaterialComponent,
        SupplierListComponent,
        OfferComponent,
        SupplierListArray,
        CopyMaterialComponent,
        WrongMaterialBindToOfferPopupComponent,
        PriceTagComponent,
        ShowProjectsMaterialHistoryComponent,
        MaterialColorCodeComponent,
        MaterialLogComponent,
        MaterialOrderComponent,
        EditMaterialOrderComponent,
        AddOrderMaterialItemComponent,
        SupplierSettingComponent,
        AddSupplierSettingComponent,
        PriceTagSizeComponent,
        MaterialListComponent,
        OfferItemCalculationMaterialComponent,
        MaterialsSoldStockComponent,
        SelectProjectRequistionComponent,
        MaterialOrderHistoryComponent,
        SupplierPriceUploadComponent,
        MaterialInventoryComponent,
        CopyMaterialOfferItemCalculationComponent,
        AppendixCalculationComponent,
        AddApendixCalculationComponent,
        CopyOfferMaterialComponent,
        EditSupplierMaterialComponent
    ],
    imports: [
        SharedModule,
        RouterModule.forChild(routes),
        FormsModule,
        VibeMaterialBarcodeModule,
        NgxBarcodeModule,
        NgxPrintModule,
        NgxCurrencyModule.forRoot(CustomCurrencyMaskConfig),
        DatePickerModule,
        NgPipesModule,
        CKEditorModule,
        ScrollingModule,
        MatTooltipModule,

    ],
    entryComponents: [
        CopyMaterialComponent,
        WrongMaterialBindToOfferPopupComponent,
        PriceTagComponent,
        ShowProjectsMaterialHistoryComponent,
        MaterialColorCodeComponent,
        MaterialLogComponent,
        EditMaterialOrderComponent,
        AddOrderMaterialItemComponent,
        PriceTagSizeComponent,
        OfferItemCalculationMaterialComponent,
        SelectProjectRequistionComponent,
        MaterialOrderHistoryComponent,
        SupplierPriceUploadComponent,
        BarcodeComponent,
        CopyMaterialOfferItemCalculationComponent,
        AddApendixCalculationComponent,
        AppendixCalculationComponent,
        CopyOfferMaterialComponent,
        EditSupplierMaterialComponent
    ],
    exports: [
        VibeMaterialComponent,
        SupplierListComponent,
        NewMaterialComponent,
        PriceTagComponent,
        ShowProjectsMaterialHistoryComponent,
        MaterialLogComponent,
        MaterialOrderComponent,
        WrongMaterialBindToOfferPopupComponent,
        MaterialListComponent,
        MaterialsSoldStockComponent,
        MaterialOrderHistoryComponent,
    ],
    providers: [MaterialService]
})

export class VibeMaterialModule {
}
