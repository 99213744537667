import { Component, OnInit, Input, Inject, Optional } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { RequestProject } from '../../model/RequestBase';
import { CustomersService } from 'app/main/pages/customer/customer.service';
import { ProjectService } from 'app/main/pages/project/project.service';
import { ResponseBase } from '../../model/ResponseBase';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import * as moment from 'moment';
import { Router } from '@angular/router';

@Component({
  selector: 'vibe-customer-project-colorcode',
  templateUrl: './customer-project-colorcode.component.html',
  styleUrls: ['./customer-project-colorcode.component.scss']
})

export class CustomerProjectColorCodeComponent implements OnInit {
  @Input() customerId: any;
  projects = [];
  showSpinner = false;
  After5DayDate: string = "";
  TodayDate: string = "";
  showBackbtn: boolean = false;
  search: any = '';
  orderBy: string = "Address ASC";
  tempProjectList = [];
  constructor(private translationLoader: FuseTranslationLoaderService,
    private customerService: CustomersService,
    private projectService: ProjectService,
    public dialog: MatDialog,
    private _router: Router,
    @Optional() public dialogRef: MatDialogRef<CustomerProjectColorCodeComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.translationLoader.loadTranslations(english, danish);
  }

  ngOnInit() {
    if (this.data && this.data.customerId) {
      this.customerId = this.data.customerId;
      this.showBackbtn = true;
    }
    if (this.customerId)
      this.GetProjectsByCustomer(this.customerId, new RequestProject());
  }

  GetProjectsByCustomer(id: any, model: RequestProject) {
    this.showSpinner = true;
    model.IsActive = false;
    model.IsDeleted = false;
    model.CustomerId = id;
    model.Limit = 10000;
    model.OrderBy = 'Created DESC';
    this.customerService.GetProjectsByCustomer<ResponseBase>(id, model)
      .subscribe((response: ResponseBase) => {
        if (response != null) {
          var proj = response.result;
          if (proj.length > 0) {
            this.projects = proj.filter(o => o.colorCodeString != null && o.colorCodeString != "").sort((a, b) => {
              return (a.isActive === b.isActive) ? 0 : a ? -1 : 1;
            });
            //this.projects.push();
            //this.projects.push(proj.filter(o => o.isActive === false));
            this.projectService.setBGImageForProjectCards(this.projects);
            for (var i = 0; i < this.projects.length; i++) {
              var formateDate = moment(this.projects[i].startDate).format('YYYY-MM-DD');
              if (this.After5DayDate >= formateDate)
                this.projects[i].displayStartDateRed = true;
              else
                this.projects[i].displayStartDateRed = false;
            }
          }
          this.tempProjectList = this.projects;
          this.receiveFilterEvent("Address ASC");
          this.showSpinner = false;
        }
        else {
          this.showSpinner = false;
          this.projects = [];
        }
      },
        error => (err) => {
          this.projects = [];
          this.showSpinner = false;
        },
        () => {
          this.showSpinner = false;
        });
  }

  getColorCodeString(value) {
    if (value)
      return value.indexOf(',') == 0 ? value.substring(1) : value;
  }

  onCloseDialog() {
    this.dialogRef.close(null)
  }

  SearchByName(value) {
    if (value || value == "") {
      this.search = value.trim();
      if (this.search) {
        var data = this.tempProjectList.filter(x => x.sagNumber == this.search ||  (!!x.address1? x.address1.toLowerCase().includes(this.search.toLowerCase()):null)
                      ||  (!!x.zipCode? x.zipCode.toLowerCase().includes(this.search.toLowerCase()):null)
                      ||  (!!x.city? x.city.toLowerCase().includes(this.search.toLowerCase()):null)
                    );
        if (data) {
          this.projects = data;
          this.receiveFilterEvent(this.orderBy);
        } else {
          this.projects = [];
        }
      } else {
        this.projects = this.tempProjectList;
      }
    }
  }

  receiveFilterEvent(event) {
    this.orderBy = event;
    if (this.orderBy == "Address ASC") {
      this.projects.sort((a, b) => !!a.address1? a.address1.localeCompare(b.address1) : null);
    }
    else if (this.orderBy == "Address DESC") {
      this.projects.sort((a, b) => !!b.address1? b.address1.localeCompare(a.address1) : null);
    }
    else if (this.orderBy == "CreatedDate ASC") {
      this.projects.sort((a, b) => new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime());
    }
    else if (this.orderBy == "CreatedDate DESC") {
      this.projects.sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime());
    }
    //var list = this.projects.filter(o => o.createdDate != null && o.createdDate != undefined)
  }

  gotoProject(project) {
    this._router.navigate(['/projects/edit', project.sagId], { queryParams: { isActive: project.isActive } });
  }
}