import { Component, OnInit, ElementRef, NgZone, ViewChild, Input, Output, EventEmitter, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatTableDataSource, MatOption } from '@angular/material';
import { Router } from '@angular/router';
import { FuseTranslationLoaderService } from '../../../../../core/services/translation-loader.service';
import { TitleService } from '../../../../../core/services/title.service';
import { EventEmitterEnum } from '../../../../../core/enum/event-emitter-ids.enum';
import { CoWorkersService } from '../../../coWorker/coWorkers.service';
import { NotificationsService } from 'angular2-notifications';
@Component({
  selector: 'vibe-requistionStatus',
  templateUrl: './requistionStatus.component.html',
  styleUrls: ['./requistionStatus.component.scss']
})
export class RequistionStatusComponent implements OnInit {
  showSpinner = false;
  selectStatus: any = {};
  requistionStatus: number = 1;
  isDisableForMaler: boolean;

  @ViewChild('allSelected', { static: false }) private allSelected: MatOption;
  //Initiators
  constructor(public dialogRef: MatDialogRef<RequistionStatusComponent>, private translationLoader: FuseTranslationLoaderService,
    private _router: Router,
    private titleService: TitleService,
    private coWorkersService: CoWorkersService,
    private notificationsService: NotificationsService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.selectStatus = this.data.projectStatus;
    this.isDisableForMaler = this.data.isDisableForMaler;
  }
  SaveAndCloseModal() {
    this.dialogRef.close(this.requistionStatus);
  }
}
