import { Component, OnInit, Optional, ViewChild } from '@angular/core';
import { Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MatRadioChange, MatTabGroup, MAT_DIALOG_DATA } from '@angular/material';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { WorkhoursService } from 'app/main/pages/workHour/workhours.service';
import { AuthService } from 'app/core/services/auth.service';
import { CoWorker } from 'app/main/model/CoWorker';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { RequestCloseCoworkersPayment, RequestPaySlip } from 'app/main/model/RequestBase';
import { PdfViewerModelComponent } from 'app/main/pages/pdf-viewer/pdf-viewer.component';
import * as moment from 'moment';
import { WorkHourStatus } from 'app/main/model/WorkHourStatus';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { RenderDayCellEventArgs } from '@syncfusion/ej2-angular-calendars';
import { CoWorkersService } from 'app/main/pages/coWorker/coWorkers.service';

@Component({
    selector: 'app-pay-slip-modal',
    templateUrl: './pay-slip-modal.component.html',
    styleUrls: ['./pay-slip-modal.component.scss']
})
export class PaySlipModalComponent implements OnInit {
    showSpinner: boolean = false;
    startWeek: number = 1;
    selectworker: CoWorker;
    startDate: any;
    endDate: any;
    paySlipSelection = "2week";
    roleName: string;
    selectedcoWorkerIds: any = null;
    coWokerList: any = [];
    searchCoWokerList: any = [];
    IsGettingCoWorkers: boolean = false;
    selectStatus = WorkHourStatus;
    isWithStatus: boolean = false;
    selectedStatus: any = null;
    isFutureWorkHours: boolean = false;
    isWorksheetFutureWorkHours: boolean = true;
    isShowWorkingCoworker: boolean = true;
    coWorkerId: string;
    RequestCloseCoworkersPayment = new RequestCloseCoworkersPayment();
    message: any = "";
    fullMessage: any = "";
    startDateWorkSheet: any;
    endDateWorkSheet: any;
    closedPaymentDate: Date = null;
    roleClaims: any;
    hasOwnWorkhourPermision: boolean = false;
    checkDateForClosePayment : any;
    compareStartDate:any;
    selectedIndex: number = 0;
    selectedCoWorkerList: CoWorker[] = new Array<CoWorker>();
    selectedcoWorkerId:number[] = [];
    @ViewChild('matTabGroup',{ static: true }) matTabGroup: MatTabGroup;
    constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<PaySlipModalComponent>,
        public dialog: MatDialog,
        private workhourservice: WorkhoursService,
        private authService: AuthService,
        private translationLoader: FuseTranslationLoaderService,
        private fb: FormBuilder,
        private notificationsService: NotificationsService,
        private translate: TranslateService,
        private coworkersService: CoWorkersService,

    ) {
        this.translationLoader.loadTranslations(english, danish);
        this.roleClaims = this.authService.getRoleClaimsList();
    }

    ngOnInit() {
        this.authService.hasClaim(this.roleClaims.Own_Workhour_View).subscribe(hasClaim => {
            if (hasClaim) 
                this.hasOwnWorkhourPermision = hasClaim
             else 
                this.hasOwnWorkhourPermision = false;
        });
        this.selectStatus = this.selectStatus.filter(x => x.id != 8);
        this.roleName = this.authService.getUserRole();
        this.coWorkerId = this.authService.getCoWorkerId();
        this.coWokerList = this.coworkersService.getResponseForCoworker();//this.data.coWokersList;
        this.selectworker = this.data.selectworker;
        if(this.data && this.data.closedPaymentDate){
            this.startDate = moment(this.data.closedPaymentDate).add(1, 'days').format('DD-MM-YYYY')
            this.endDate = moment(this.data.endDate).format("DD-MM-YYYY");
        }else{
        this.startDate = moment(this.data.startDate).format("DD-MM-YYYY");
        this.endDate = moment(this.data.endDate).format("DD-MM-YYYY");
        }
        this.startDateWorkSheet = moment(new Date()).format("DD-MM-YYYY");
        this.endDateWorkSheet = moment(new Date()).format("DD-MM-YYYY");
        //this.selectedcoWorkerIds = [this.selectworker.id];
        if(this.roleName === 'SuperAdmin')
            this.selectedcoWorkerIds = this.coWokerList.map(item => item.id);
        else
            this.selectedcoWorkerIds = [this.selectworker.id];
        this.searchCoWokerList = this.coWokerList;
        this.checkDateForClosePayment =  this.data.closedPaymentDate ? moment(this.data.closedPaymentDate).format("YYYY-MM-DD") : null;
    }

    closeModal() {
        this.dialogRef.close();
    }

    TabChange(event) {
        this.selectedIndex = event.index;
    }

    onGeneratePDF() {
        this.showSpinner = true;
        var model = new RequestPaySlip()
        model.StartDate = moment(this.startDateWorkSheet).format("YYYY-MM-DD");
        model.EndDate = moment(this.endDateWorkSheet).format("YYYY-MM-DD");
        model.CoworkerId = this.selectedcoWorkerIds.join();//this.selectworker.id;
        model.LeaveStatus = this.selectedStatus ? this.selectedStatus.join() : null;
        model.IsFutureWorkHours = this.isWorksheetFutureWorkHours;
        model.isShowWorkingCoworker = this.isShowWorkingCoworker;
        this.workhourservice.GetWorkHoursPayslipByCoworkerId<ResponseBase>(model)
            .subscribe((response: ResponseBase) => {
                if (response) {
                    var resultData = response.result;
                    var data = response.result.base64.replace("data:application/pdf;base64,", "");
                    const dialogRef = this.dialog.open(PdfViewerModelComponent, {
                        height: '90%',
                        width: '90%',
                        data: {
                            base64: data,
                            filename: response.result.fileName ? response.result.fileName + ".pdf" : "Payslip"
                        }
                    });

                    dialogRef.afterClosed().subscribe(result => {
                        if (result) {
                            this.selectedIndex = 1;
                        }
                    });
                }
            },
                error => (err) => { },
                () => {
                    this.showSpinner = false;
                });
    }

    onGeneratePDFForAll() {
        this.showSpinner = true;
        var model = new RequestPaySlip()
        model.StartDate = moment(this.startDate).format("YYYY-MM-DD");
        model.EndDate = moment(this.endDate).format("YYYY-MM-DD");
        model.LeaveStatus = this.selectedStatus ? this.selectedStatus.join() : null;
        model.IsFutureWorkHours = this.isFutureWorkHours;
        model.isShowWorkingCoworker = this.isShowWorkingCoworker;
        model.CoworkerId = this.selectedcoWorkerIds.join();//this.selectworker.id;
        this.workhourservice.GetCoworkersSalarySlip<ResponseBase>(model)
            .subscribe((response: ResponseBase) => {
                if (response) {
                    var resultData = response.result;
                    var data = response.result.base64.replace("data:application/pdf;base64,", "");
                    const dialogRef = this.dialog.open(PdfViewerModelComponent, {
                        height: '90%',
                        width: '90%',
                        data: {
                            base64: data,
                            filename: response.result.fileName ? response.result.fileName + ".pdf" : "Payslip"
                        }
                    });

                    dialogRef.afterClosed().subscribe(result => {
                        if (result) {

                        }
                    });
                }
            },
                error => (err) => { },
                () => {
                    this.showSpinner = false;
                });
    }

    onGeneratePayslipPDF() {
        this.showSpinner = true;
        var model = new RequestPaySlip()
        model.StartDate = moment(this.startDate).format("YYYY-MM-DD");
        model.EndDate = moment(this.endDate).format("YYYY-MM-DD");
        model.CoworkerId = this.selectedcoWorkerIds.join();//this.selectworker.id;
        model.LeaveStatus = this.selectedStatus ? this.selectedStatus.join() : null;
        model.IsFutureWorkHours = this.isFutureWorkHours;
        model.isShowWorkingCoworker = this.isShowWorkingCoworker;
        this.workhourservice.GeneratePaySlipByCoworkers<ResponseBase>(model)
            .subscribe((response: ResponseBase) => {
                if (response) {
                    var resultData = response.result;
                    var data = response.result.base64.replace("data:application/pdf;base64,", "");
                    const dialogRef = this.dialog.open(PdfViewerModelComponent, {
                        height: '90%',
                        width: '90%',
                        data: {
                            base64: data,
                            filename: response.result.fileName ? response.result.fileName + ".pdf" : "Payslip"
                        }
                    });

                    dialogRef.afterClosed().subscribe(result => {
                        if (result) {

                        }
                    });
                }
            },
                error => (err) => { },
                () => {
                    this.showSpinner = false;
                });
    }

    onFocusIn(event) {
        event.model.show();
    }

    radioChange(event: MatRadioChange) {
        this.paySlipSelection = event.value;
        if (this.paySlipSelection == '2week') {
            this.startDate = moment(this.data.startDate).format("DD-MM-YYYY")
            this.endDate = moment(this.data.endDate).add('2', 'weeks').format("DD-MM-YYYY");
        } else {
            this.startDate = moment(this.data.startDate).startOf('month').format('DD-MM-YYYY');
            this.endDate = moment(this.data.startDate).endOf('month').format('DD-MM-YYYY');
        }
    }

    async DropDownSearchCoWorker(searchCoworker) {
        // this.IsGettingCoWorkers = true;
        // this.searchCoWokerList = this.coWokerList.filter(o => o.name.toLowerCase().includes(searchCoworker.toLowerCase()));
        // this.IsGettingCoWorkers = false;
    }

    IsWithStatusChange() {
        if (this.isWithStatus) {
            this.selectedStatus = 1;
            this.isFutureWorkHours = false;
        } else {
            this.selectedStatus = null;
        }
    }
    IsFutureWorkHoursChange() {
        this.isWithStatus = false;
        this.selectedStatus = null;
    }

    downloadCoworkerPaymentReport() {
        this.showSpinner = true;
        var model = new RequestPaySlip()
        model.StartDate = moment(this.startDate).format("YYYY-MM-DD");
        model.EndDate = moment(this.endDate).format("YYYY-MM-DD");
        model.CoworkerId = this.selectedcoWorkerIds.join();//this.selectworker.id;
        model.LeaveStatus = this.selectedStatus ? this.selectedStatus.join() : null;
        model.IsFutureWorkHours = this.isFutureWorkHours;
        model.isShowWorkingCoworker = this.isShowWorkingCoworker;
        this.workhourservice.DownloadCoworkerPaymentReport<ResponseBase>(model)
            .subscribe((response: ResponseBase) => {
                if (response) {
                    var resultData = response.result;
                    setTimeout(function () {
                        var data = resultData.base64.replace("data:text/plain;base64,", "");
                        var binary = atob(data.replace(/\s/g, ''));
                        var len = binary.length;
                        var buffer = new ArrayBuffer(len);
                        var view = new Uint8Array(buffer);
                        for (var i = 0; i < len; i++) {
                            view[i] = binary.charCodeAt(i);
                        }
                        var download = document.createElement("a");
                        var blob = new Blob([view], { type: "text/plain" });
                        download.href = window.URL.createObjectURL(blob);
                        download.download = 'Workhours_rapport' + '.txt';
                        var event = document.createEvent("MouseEvents");
                        event.initMouseEvent(
                            "click", true, false, window, 0, 0, 0, 0, 0
                            , false, false, false, false, 0, null
                        );
                        download.dispatchEvent(event);
                    }, 100)
                }
            },
                error => (err) => { },
                () => {
                    this.showSpinner = false;
                });
    }

    public OnChangeDatePicker(args): void {
        if (args.value) {
            args.value = new Date(moment(args.value).format("YYYY-MM-DD"));
        }
    }
    
    onStartDateChange(args) {
        if (args.value) 
            args.value = new Date(moment(args.value).format("YYYY-MM-DD"));        
        this.compareStartDate = moment(args).format("YYYY-MM-DD");
        if (this.paySlipSelection == '2week') {
            const missingDays = ((7 - moment(args).isoWeekday()) + 7) % 7;
            this.endDate = moment(args).add(missingDays + 7, "days")["_d"];
        }
        else
            this.endDate = moment(args).endOf('month')["_d"];
    }


    onRenderCellStartDate(args: RenderDayCellEventArgs): void {
        if (this.paySlipSelection == '2week') {
            if (args.date.getDay() == 1) {
                args.isDisabled = false;
            } else {
                args.isDisabled = true;
            }
        }
        else if (this.paySlipSelection == 'monthly') {
            if (args.date.getDate() === 1) {
                args.isDisabled = false;
            } else {
                args.isDisabled = true;
            }
        }
    }

    onRenderCellEndDate(args: RenderDayCellEventArgs): void {
        if (this.paySlipSelection == '2week') {
            if (args.date.getDay() == 0) {
                args.isDisabled = false;
            } else {
                args.isDisabled = true;
            }
        } else if (this.paySlipSelection == 'monthly') {
            const currentDate = args.date;
            const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
            if (currentDate.getDate() === lastDayOfMonth.getDate()) {
                args.isDisabled = false;
            } else {
                args.isDisabled = true;
            }
        }
    }

    closePayment() {
        this.fullMessage = "";
        this.message = "";
        if (this.paySlipSelection == '2week') {
            this.translate.get("MESSAGES.Payment14DaysConfirmation").subscribe({
                next: (res) => { this.message = res; }, error: err => { }
            });
        }
        else if (this.paySlipSelection == 'monthly') {
            this.translate.get("MESSAGES.Payment30DaysConfirmation").subscribe({
                next: (res) => { this.message = res; }, error: err => { }
            });
        }
        this.fullMessage = this.fullMessage + this.message.replace("@startdate", moment(this.startDate).format('DD-MM-YYYY')).replace("@endDate", moment(this.endDate).format('DD-MM-YYYY')) + "<br/>";
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: '200px',
            data: { Header: this.fullMessage }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.showSpinner = true;
                var model = new RequestPaySlip()
                model.StartDate = moment(this.startDate).format("YYYY-MM-DD");
                model.EndDate = moment(this.endDate).format("YYYY-MM-DD");
                model.CoworkerId = this.selectedcoWorkerIds.join();//this.selectworker.id;
                model.LeaveStatus = this.selectedStatus ? this.selectedStatus.join() : null;
                model.IsFutureWorkHours = this.isFutureWorkHours;
                model.isShowWorkingCoworker = this.isShowWorkingCoworker;
                this.workhourservice.CloseCoworkersPayment<ResponseBase>(model)
                    .subscribe({
                        next: (response: ResponseBase) => {
                            this.showSpinner = false;
                            this.translate.get("MESSAGES.Add").subscribe({
                                next: (res) => {
                                    this.notificationsService.success(res);
                                }, error: err => { }
                            });
                            this.dialogRef.close(true);
                        },
                        error: err => { },
                        complete: () => { }
                    });
            }
        });
    }

    isWorksheetFutureWorkHoursChange(){
        this.isWorksheetFutureWorkHours;
    }

    onWorkSheetStartDateChange(args: any): void {
        if (args)
            args = new Date(moment(args).format("YYYY-MM-DD"));
        const missingDays = ((7 - moment(args).isoWeekday()) + 7) % 7;
        this.endDateWorkSheet = moment(args).add(missingDays + 7, "days")["_d"];
    }
}
