import { Component, OnInit, ElementRef, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { TitleService } from 'app/core/services/title.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { CoWorkersService } from 'app/main/pages/coWorker/coWorkers.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { QuickInvoiceMaterials, QuickInvoice } from 'app/main/model/Material';
import { FormService } from 'app/core/services/form.service';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { MaterialType } from 'app/constant/common.constant';

@Component({
    selector: 'vibe-materialDetail',
    templateUrl: './materialDetail.component.html',
    styleUrls: ['./materialDetail.component.scss']
})
export class MaterialDetailComponent implements OnInit {
    @ViewChild("colorCode", { static: false }) colorCodeFeild: ElementRef;
    @Input() Materials: QuickInvoiceMaterials[];
    @Input() Material: QuickInvoiceMaterials;
    @Input() quickInvoice: QuickInvoice;
    @Output() AddedMaterialList = new EventEmitter();
    @Output() IsAddScanMaterial = new EventEmitter();
    @Output() SelectMaterialManual = new EventEmitter();
    SearchMaterial: string = "";
    ManuallAddMaterialId: string = "";
    SearchMaterials: QuickInvoiceMaterials[];
    AddedMaterials: QuickInvoiceMaterials[];
    materialForm: FormGroup;
    selectMaterialtype = MaterialType;
    materialType: number = 0;
    //Initiators
    constructor(private translationLoader: FuseTranslationLoaderService,
        private _router: Router,
        private titleService: TitleService,
        private coWorkersService: CoWorkersService,
        private translate: TranslateService,
        private formBuilder: FormBuilder,
        private notificationsService: NotificationsService,
        public formService: FormService) {
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
        this.SearchMaterials = this.Materials;
        if (!this.Material) {
            this.Material = new QuickInvoiceMaterials();
            this.ManuallAddMaterialId = "";
        } else {
            this.ManuallAddMaterialId = this.Material.id;
        }
        
        //this.materialForm = this.formBuilder.group({
        //    //ManuallAddMaterialId: [null, Validators.compose([Validators.required])],
        //    Text: [null, Validators.compose([Validators.required, Validators.maxLength(80)])],
        //    MaterialText: [null, Validators.compose([Validators.required])],
        //    PersonalPrice: [null, [Validators.required, Validators.min(0), Validators.max(99999999999999999999)]],
        //    RetailPrice: [null, [Validators.required, Validators.min(0), Validators.max(99999999999999999999)]],
        //    PurchaseQuantity: [null, [Validators.required, Validators.min(0), Validators.max(99999999999999999999)]],
        //    Quantity: [null, [Validators.required, Validators.min(0), Validators.max(99999999999999999999)]],
        //});
        if (this.quickInvoice.indoorOutdoorStatusId == "5") {
            this.materialForm = this.formBuilder.group({
                //ManuallAddMaterialId: [null, Validators.compose([Validators.required])],
                Text: [null],
                ColorCode: [null],
                PersonalPrice: [null, [Validators.required, Validators.minLength(1), Validators.max(99999999999999999999)]],
                RetailPrice: [null, [Validators.required, Validators.minLength(1), Validators.max(99999999999999999999)]],
                PurchaseQuantity: [null, [Validators.required, Validators.minLength(1), Validators.max(99999999999999999999)]],
                Quantity: [null, [Validators.required, Validators.minLength(0.01), Validators.max(99999999999999999999)]],
            });
        }
        else {
            this.materialForm = this.formBuilder.group({
                Text: [null],
                ColorCode: [null],
                PersonalPrice: [null, []],
                RetailPrice: [null, [Validators.required, Validators.minLength(1), Validators.max(99999999999999999999)]],
                PurchaseQuantity: [null, [Validators.required, Validators.minLength(1), Validators.max(99999999999999999999)]],
                Quantity: [null, [Validators.required, Validators.minLength(0.01), Validators.max(99999999999999999999)]],
            });
        }
        if (this.Material && this.Material.isRequiredText && !this.Material.colorCode) {
            setTimeout(() => {
                this.colorCodeFeild.nativeElement.focus();
            },100)
        }
    }

    ClearSearchMaterial(value) {
        if (!value) {
            this.DropDownSearchMaterial();
        }
    }

    DropDownSearchMaterial() {
        //this.SearchMaterials = this.Materials.filter(o => o.materialType == this.materialType && (o.text.toLowerCase().includes(this.SearchMaterial.toLowerCase()) || o.itemNumber.toLowerCase().includes(this.SearchMaterial.toLowerCase())));
        if(this.materialType)
            this.SearchMaterials = this.Materials.filter(o => o.materialType && o.materialType.split(',').find(p => p == (this.materialType.toString())) && (o.text.toLowerCase().includes(this.SearchMaterial.toLowerCase()) || o.itemNumber.toLowerCase().includes(this.SearchMaterial.toLowerCase())));
        else            
            this.SearchMaterials = this.Materials.filter(o => (o.text.toLowerCase().includes(this.SearchMaterial.toLowerCase()) || o.itemNumber.toLowerCase().includes(this.SearchMaterial.toLowerCase())));
    }

    DropDownSelectMaterial() {
        this.Material = new QuickInvoiceMaterials();
        this.materialForm.reset();
        var mate = this.Materials.find(o => o.id == this.ManuallAddMaterialId);
        this.Material = Object.assign({}, mate);
        this.Material.quantity = this.Material.purchaseQuantity;
        //this.Material.text = "";

        //const materialTextControl = this.materialForm.get('ColorCode');
        //this.materialForm.get('Text').valueChanges
        //    .subscribe(o => {
        //        if (this.Material.isRequiredText == true) {
        //            materialTextControl.setValidators([Validators.required]);
        //        }
        //        else {
        //            materialTextControl.setValidators(null);
        //        }
        //        materialTextControl.updateValueAndValidity();
        //    });
    }

    AddMaterialUsingScanner() {
        this.IsAddScanMaterial.emit(true);
    }

    AddToMaterialListManually() {
        const materialTextControl = this.materialForm.get('ColorCode');
        if (this.Material.isRequiredText == true && !this.Material.colorCode) {
            materialTextControl.setValidators([Validators.required]);
        }
        else if (this.Material.isRequiredText == true && this.Material.text) {
            this.Material.text = this.Material.text
        } else {
            materialTextControl.setValidators(null);
            var mate = this.Materials.find(o => o.id == this.ManuallAddMaterialId);
            //this.Material.text = mate.text;
            this.Material.text = this.materialForm.get('Text').value == null ? mate.text : this.materialForm.get('Text').value;
        }
        materialTextControl.updateValueAndValidity();
        this.formService.markFormGroupTouched(this.materialForm);
        if (this.materialForm.valid) {
            this.Material.id = this.ManuallAddMaterialId;
            var mat = Object.assign({}, this.Material);
            this.AddedMaterialList.emit(mat);
            this.materialForm.reset();
            this.ManuallAddMaterialId = "";
        }
    }

    _handleKeydown(event: KeyboardEvent) {
        if (event.keyCode === 13) {
            event.stopPropagation();
        }
    }

    getMaterialOnType(typeId) {
        this.materialForm.reset();
        if (typeId != 0) {
            //this.SearchMaterials = this.Materials.filter(x => x.materialType == typeId);
            this.SearchMaterials = this.Materials.filter(x => x.materialType && x.materialType.split(',').find(p => p == (this.materialType.toString())));
            
        } else {
            this.SearchMaterials = this.Materials;
        }
    }

    onChangeMaterial(mat) {
        this.SelectMaterialManual.emit(mat);
    }
}

