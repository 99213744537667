import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { locale as english } from '../translate/en';
import { locale as danish } from '../translate/ds';
import { CustomersService } from "../../../customer.service"
import { CustomerRoom } from 'app/main/model/ProjectOffer';
import { RequestCustomerProperty } from 'app/main/model/RequestBase';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { NotificationsService } from 'angular2-notifications';
import { CommonService } from 'app/core/services/common.service';
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { MatDialog } from '@angular/material';
import { PropertyTypeCategory } from 'app/constant/common.constant';
import { CustomErrorStateMatcher, spaceOnlyValidator } from 'app/core/Validators/validation';

@Component({
    selector: 'vibe-customerPropertyManage',
    templateUrl: './customerPropertyManage.component.html',
})
export class CustomerPropertyManageComponent implements OnInit {
    @Input() CustomerInput: any;

    private _UpdateCustomerProperty = new BehaviorSubject<any>(null);
    @Input()
    set UpdateCustomerProperty(value) {
        this._UpdateCustomerProperty.next(value);
    };
    get UpdateCustomerProperty() {
        return this._UpdateCustomerProperty.getValue();
    }

    @Output() GoToBack: EventEmitter<boolean> = new EventEmitter();

    showSpinner = false;
    CustomerID: string = '';
    CustomerProperties: CustomerRoom[] = [];
    requestCustomerProperty: RequestCustomerProperty = new RequestCustomerProperty();
    RemoveRoomByIndex: number;
    displayPagination: boolean;
    pagination: any;

    propertyForm: FormGroup;
    submitted = false;
    objAddressEdit: any = {};
    propertyTypeCategory = PropertyTypeCategory;

    isSubmitted: boolean = false;
    esMatcher = new CustomErrorStateMatcher();

    //Initiators
    constructor(
        private translationLoader: FuseTranslationLoaderService,
        private customersService: CustomersService,
        private notificationsService: NotificationsService,
        private commonService: CommonService,
        private translate: TranslateService,
        private formBuilder: FormBuilder,
        public dialog: MatDialog
    ) {
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
        this.CustomerID = this.CustomerInput;

        this.propertyForm = this.formBuilder.group({
            ID: [null],
            Name: [null, Validators.compose([Validators.required, spaceOnlyValidator.bind(this), Validators.maxLength(50)])],
            ColorCode: [null, Validators.compose([Validators.maxLength(200)])],
            Address: [''],
            Address2: [''],
            PinCode: [''],
            City: [''],
            PropertyType: [1],
            CustomerID: [this.CustomerID],
            //Phone: [null, Validators.compose([Validators.required, Validators.maxLength(8)])],
        });

        this.objAddressEdit = {
            'latitude': "",
            'longitude': "",
            'address': "",
            'city': "",
            'pinCode': "",
            'mapLocation': "",
            'IsEdit': true
        }

        this._UpdateCustomerProperty
            .subscribe(x => {
                if (x) {
                    this.propertyForm.patchValue({ 'ID': x.id })
                    this.propertyForm.patchValue({ 'Name': x.name })
                    this.propertyForm.patchValue({ 'ColorCode': x.colorCode })
                    this.propertyForm.patchValue({ 'Address': x.address })
                    this.propertyForm.patchValue({ 'Address2': x.address2 })
                    this.propertyForm.patchValue({ 'PinCode': x.pinCode })
                    this.propertyForm.patchValue({ 'City': x.city })
                    this.propertyForm.patchValue({ 'PropertyType': x.propertyType })
                    this.propertyForm.patchValue({ 'CustomerID': this.CustomerID })
                    //this.propertyForm.patchValue({'Phone': x.phone})

                    this.objAddressEdit = {
                        'address': x.address,
                        'city': x.city,
                        'pinCode': x.pinCode,
                        'IsEdit': true
                    }
                }
            });
    }

    get f() { return this.propertyForm.controls; }

    receiveAddressObject($event) {
        this.propertyForm.patchValue({ 'Address': $event.address })
        this.propertyForm.patchValue({ 'City': $event.city })
        this.propertyForm.patchValue({ 'PinCode': $event.pinCode })
    }

    goToPropertyList() {
        this.GoToBack.emit(true);
        this.propertyForm.reset();
    }

    onSubmit() {
        this.submitted = true;
        this.esMatcher.isSubmitted = this.submitted;
        this.propertyForm.markAllAsTouched();

        if (this.propertyForm.valid && this.esMatcher.isValid) {
            this.showSpinner = true;
            let msg = (this.propertyForm.value.ID) ? 'MESSAGES.Updated' : 'MESSAGES.Created';
            this.customersService.SaveCustomerProperty<ResponseBase>(this.propertyForm.value)
                .subscribe({
                    next: (response: ResponseBase) => {
                        if (response.statusCode == 200) {
                            this.showSpinner = false;
                            this.translate.get(msg).subscribe({
                                next: (res) => {
                                    this.notificationsService.create(res);
                                },
                                error: err => {
                                }
                            });
                            this.goToPropertyList()
                        }
                    },
                    error: err => {
                        this.showSpinner = false;
                        if (err.status != 409)
                            this.translate.get("MESSAGES.Failed").subscribe({
                                next: (res) => {
                                    this.notificationsService.error(res);
                                },
                                error: err => {
                                }
                            });
                    },
                    complete: () => { }
                });
        }
    }
}
