export const locale = {
    lang: 'en',
    data: {
        'FIELDS': {
            'Billed': 'Billed date'
        },
        'BUTTONS': {
            'BACK': 'Back',
            'SAVE': 'Save',
        },
    }
};