import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { CoWorkersService } from 'app/main/pages/coWorker/coWorkers.service';
import { RequestCoWorker, RequestCoWorkerFile } from 'app/main/model/RequestBase';
import { MatTableDataSource, MatDialog, MatOption, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { CalendarService } from 'app/main/pages/calendar/calendar.service';
import { ApiConstant } from 'app/core/constant/ApiConstant';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';

import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { AuthService } from 'app/core/services/auth.service';
import { PlanningDefaultCoWorker } from 'app/main/model/WorkHour';

@Component({
    selector: "calendar-default-coworker",
    templateUrl: "./calendar-default-coworker.component.html",
    styleUrls: ["./calendar-default-coworker.component.scss"]
})

export class CalendarDefaultCoworkerComponent implements OnInit {
    @ViewChild('allSelected', { static: false }) private allSelected: MatOption;
    @ViewChild('singleSelect', { static: false }) private singleSelect: MatOption;
    widgets: any;
    widget11: any = {};
    coWokers: any = [];
    tempCoworkerList: any = [];
    defaultCoWokersList: any = [];
    currentselectedcoWorkerObj: any = {};
    showSpinner = false;
    planningDefaultCoWorkerList: any = [];
    imageBaseUrl = "";
    CoWorkerName = "";
    defaultCoworkerObj: PlanningDefaultCoWorker = new PlanningDefaultCoWorker();
    loggedInCoWorkerId: string;
    defaultCoworkerRequest: RequestCoWorkerFile = new RequestCoWorkerFile();
    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private coWorkersService: CoWorkersService,
        private calanderService: CalendarService,
        private translate: TranslateService,
        private notificationsService: NotificationsService,
        public dialog: MatDialog,
        public authSevice: AuthService,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.translationLoaderService.loadTranslations(danish, english);
        this.imageBaseUrl = ApiConstant.URL;
    }

    ngOnInit() {
        let coWorker = new RequestCoWorker();
        coWorker.IsDeleted = false;
        this.loggedInCoWorkerId = this.authSevice.getCoWorkerId()
        this.getCoWorkers(coWorker);
        this.getDefaultCoworkers();

        this.widgets = {
            'widget11': {
                'title': 'Team Members',
                'table': {
                    'columns': ['imagePath', 'coworkerNumber', 'coworkerName', 'email', 'mobile', 'action'],
                    'rows': []
                }
            },
        };
    }

    getCoWorkers(model) {
        model.Limit = 400;
        model.OrderBy = "Name";
        this.coWorkersService.Get<ResponseBase>(model)
            .subscribe((response: ResponseBase) => {
                if (response) {
                    this.getNextRequestForCoWorker(response.pagination, model, response.result);
                }
                else {
                    this.coWokers = [];
                }
            },
                error => (err) => {
                    this.coWokers = [];
                },
                () => {
                });
    }

    getNextRequestForCoWorker(pagination, model, response) {
        if (pagination.index == 1) {
            this.coWokers = response.filter(x => x.doNotShowOnPlanReminderProj == false);
        }
        else {
            response = response.filter(x => x.doNotShowOnPlanReminderProj == false);
            this.coWokers.push(...response);
        }
        this.coWokers = this.coWokers.filter(x => x.id != this.loggedInCoWorkerId);
        this.tempCoworkerList = this.coWokers;
        model.Index = pagination.index + 1;
        model.Limit = pagination.limit;
        model.TotalRows = pagination.totalCount;
        if (pagination.totalCount > pagination.index * pagination.limit)
            this.getCoWorkers(model);
    }

    DropDownSearchCoWorker(value) {
        if (value != null && value != "") {
            var request = new RequestCoWorker();
            request.Name = value;
            this.getCoWorkers(request);
        } else {
            let coWorker = new RequestCoWorker();
            coWorker.IsDeleted = false;
            this.getCoWorkers(coWorker);
        }
    }

    GetCoworkerOnClear(CoWorkerName) {
        if (!CoWorkerName) {
            this.DropDownSearchCoWorker(CoWorkerName);
        }
    }

    toggleAllSelection() {
        if (this.allSelected.selected) {
            this.currentselectedcoWorkerObj.id = [];
            this.currentselectedcoWorkerObj.id.push(0);
            for (var i = 0; i < this.coWokers.length; i++) {
                this.currentselectedcoWorkerObj.id.push(this.coWokers[i].id);
            }
        } else {
            this.currentselectedcoWorkerObj.id = [];
        }
    }

    DropDownCoWorkerSelect(coworker) {
        this.currentselectedcoWorkerObj.id = this.currentselectedcoWorkerObj.id.filter(o => o != 0);
    }

    getDefaultCoworkers() {
        this.showSpinner = true;
        this.defaultCoworkerRequest.CoWorkerId = this.loggedInCoWorkerId;
        this.calanderService.GetRequisitionPlanningDefaultCoworker(this.defaultCoworkerRequest)
            .subscribe({
                next: (response: ResponseBase) => {
                    if (response) {
                        this.defaultCoWokersList = [];
                        this.defaultCoWokersList = response.result;
                        this.coWokers = this.tempCoworkerList;
                        if (this.defaultCoWokersList.length > 0) {
                            this.widgets.widget11.table.rows = this.defaultCoWokersList;
                            for (let i = 0; i < this.defaultCoWokersList.length; i++) {
                                this.coWokers = this.coWokers.filter(x => x.id != this.defaultCoWokersList[i].selectedID);
                            }
                            this.widget11.dataSource = new MatTableDataSource(this.widgets.widget11.table.rows);
                            this.showSpinner = false;
                        } else {
                            this.defaultCoWokersList = [];
                            this.showSpinner = false;
                        }
                    } else {
                        this.defaultCoWokersList = [];
                        this.showSpinner = false;
                    }
                },
                error: err => {
                    this.showSpinner = false;
                },
                complete: () => { }
            });
    }

    AddNewCoworker() {
        if (this.currentselectedcoWorkerObj.id.length > 0) {
            this.defaultCoworkerObj.coWorkerIds = this.currentselectedcoWorkerObj.id.toString();
        } else {
            return;
        }
        this.defaultCoworkerObj.coWorkerID = this.loggedInCoWorkerId;
        this.showSpinner = true;
        this.calanderService.AddPlanningDefaultCoworker<ResponseBase>(this.defaultCoworkerObj)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.CoWorkerName = "";
                    this.currentselectedcoWorkerObj.id = "";
                    this.currentselectedcoWorkerObj.name = "";
                    this.getDefaultCoworkers();
                    this.translate.get("MESSAGES.Created").subscribe({
                        next: (res) => {
                            this.notificationsService.create(res);
                        },
                        error: err => {
                        }
                    });
                },
                error: err => {
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });
                },
                complete: () => {
                    this.showSpinner = false;
                }
            });
    }

    openDeletePopup(id, name) {
        var message = "";
        this.translate.get("Headers.DeleteMessage").subscribe({
            next: (res) => {
                message = res;
                message = message + " " + name + " " + "?";
            }, error: err => { }
        })
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: '200px',
            data: { Header: message }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.DeleteCoworker(id);
            }
        });
    }

    DeleteCoworker(Id) {
        this.showSpinner = true;
        this.calanderService.DeleteDefaultCoWorker(Id)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.getDefaultCoworkers();
                    this.translate.get("MESSAGES.Deleted").subscribe({
                        next: (res) => {
                            this.notificationsService.create(res);
                        },
                        error: err => {
                        }
                    });
                },
                error: err => {
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });
                },
                complete: () => {
                    this.showSpinner = false;
                }
            });
    }

    _handleKeydown(event: KeyboardEvent) {
        if (event.keyCode === 13) {
            // do not propagate spaces to MatSelect, as this would select the currently active option
            event.stopPropagation();
        }
    }
}