export const locale = {
    lang: 'ds',
    data: {
        'FIELDS': {
            'SrNo': 'Sr-nr.',
            'Date': 'Dato',
            'SupplierName': 'Leverandør Navn',
            'OrderMaterial': 'Antal',
            'OrderAmount': 'Beløb',
            'ReceivedMaterila': 'Modtaget Vare',
            'ReceivedAmount': 'Modtaget Beløb',
            'Status': 'Status',
            'Email': 'E-mail',
            'Attachment': 'Vedhæftet fil',
            'Coworker': 'Senest opdateret af',
            'ReceivedDate': 'Modtaget Dato',
            'TextSearch': 'Søg',
            'SelectSupplier': 'Vælg Leverandør',
            'StartDate': 'Start Dato',
            'EndDate': 'Slut Dato',
            'SearchAllOptions': 'All',

            'Material': 'Materiale',
            'RetailPrice': 'Udsalgs pris',
            'PersonalPrice': 'Personal Pris',
            'PersonalTotalPrice': 'Personal i alt',
            'Quantity': 'Antal',
            'ProductNo': 'Vare Nr',
            'OrderQuantity': 'Bestille Antal',
            'MinQuantity': 'Min Antal',
            'MaxQuantity': 'Max Antal',
            'Unit': 'Enhed',
            'PurchasePrice': 'Indkøbs Pris',
            'TotalPurchasePrice': 'I alt Indkøbs Pris',
            'Total': 'I alt',
            'Inventory': 'Beholding',
            'MinInventory': 'Min Beholding',
            'MaxInventory': 'Max Beholding',
            'PurchaseQuantity': 'Indkoebs maengde',
            'ShowAll': 'Vis alle',
            'ColorCode': 'Farve kode',
            'ProjectNumber': 'Sag Nummer',
            'RequisitionNo': 'Rekvisition Nr.',
        },
        'MESSAGES': {
            'Failed': 'Mislykkedes',
            'NORECORD': 'Ingen materiale fundet',
        },
        'TITLE': {
            'CustomerPagination': 'Antal log pr. side',
        },
        'SupplierOrderStatus': {
            'Ordered': 'Bestilt',
            'Received': 'Modtaget',
        },
        "SupplierList": {
            'value1': 'Bestilt',
            'value2': 'Modtaget',
            'value3': 'Delvist Modtaget',
            'value4': 'Delete',
        },
    }
}