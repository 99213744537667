export const locale = {
    lang: 'en',
    data: {
        'FIELDS': {
            'SRNO': 'Sr No',
            'Status': 'Status',
            'Number': 'Number',
            'Name': 'Name',
            'Createdby': 'Created By',
            'CreatedDate': 'Created Date',
            'ModifyBy': 'Modify By',
            'ModifyDate': 'Modify Date',
            'CoworkerName': 'Coworker',
            'Date': 'Date',
            'Description': 'Description',
            'ProjectDetail': 'Project Detail',
            'ColorCode': 'Color Code',
        },
        'MESSAGES': {

        },
        'BUTTONS': {
            'BACK': 'Cancel',
        },
        'FILTERS': {
            'BADGE': 'Inactive',
            'MtoM': 'M to M',
            'Note': 'Note',
            'Offers': 'Offers',
            'TEXTSEARCH': 'Search',
            'SORTING': 'Sort By',
        },
        'SORTBY':
            [
                {
                    Text: 'Address (A-Z)',
                    Value: 'Address ASC'
                },
                {
                    Text: 'Address (Z-A)',
                    Value: 'Address DESC'
                },
                {
                    Text: 'Created Date ↑',
                    Value: 'CreatedDate ASC'
                },
                {
                    Text: 'Created Date ↓',
                    Value: 'CreatedDate DESC'
                },
            ]
    }
};
