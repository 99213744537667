import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { FuseTranslationLoaderService } from '../../../../../core/services/translation-loader.service';
import { TitleService } from '../../../../../core/services/title.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { EventEmitterEnum } from '../../../../../core/enum/event-emitter-ids.enum';
import { CustomersService} from "../../customer.service"
import { TranslateService } from '@ngx-translate/core';
import { ResponseBase } from '../../../../model/ResponseBase';

@Component({
  selector: 'vibe-customerDetail',
  templateUrl: './customerDetail.component.html',
  styleUrls: ['./customerDetail.component.scss']
})
export class CustomerDetailComponent implements OnInit {
  showSpinner = false;
  customerLogsDetail: any = [];
  oldItem: any = {};
  newItem: any = {};

  currentId: any;
  previousId: any;
  nextId: any;
  tempLogId: any;
  logData: any[];
  //Initiators
  constructor(private translationLoader: FuseTranslationLoaderService,
    private _router: Router,
    private titleService: TitleService,
    private translate: TranslateService,
    private customersService: CustomersService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.translationLoader.loadTranslations(english, danish);
  }

  ngOnInit() {
    this.getCustomerLogsDetails(this.data.id)
    this.tempLogId = this.data.id;
    if(this.data && this.data.logData)
      this.logData = this.data.logData;
  }
  getCustomerLogsDetails(logId) {
    this.showSpinner = true;
    this.customersService.GetCustomerLogDetails<ResponseBase>(logId)
      .subscribe({
        next: (response: ResponseBase) => {
          if (response != null && response.result) {
            this.customerLogsDetail = response.result;
            this.oldItem = response.result.length>1 ? response.result[1]:[];
            this.newItem = response.result[0];
            this.getSetNextPreviousId();
          }
          else {
            this.customerLogsDetail = [];
          }
        },
        error: err => {
          this.showSpinner = false;
          this.customerLogsDetail = [];
        },
        complete: () => { }
      });
  }
  getStatus(status) {
    var statusField = "";
    if (status == 1)
      statusField = "STATUS.Created"
    if (status == 2)
      statusField = "STATUS.Updated"
    if (status == 3)
      statusField = "STATUS.Deleted"
    if (status == 4)
      statusField = "STATUS.FileUploaded"
    if (status == 5)
      statusField = "STATUS.FileDeleted"
    return statusField;
  }
  getIndoorOutdoorStatus(status) {
    var statusText = "";
    var statusArr = status? status.split(','):[];
    if (statusArr.length > 0) {
      for (var i = 0; i < statusArr.length; i++) {
        var appendcomma = "";
        if (i  != statusArr.length - 1)
            appendcomma = ","
        if(statusArr[i] == "1")
          this.translate.get('selectedValueIndoorOutdoorStatus.value1').subscribe(value => { statusText +=  value + appendcomma; });
        if (statusArr[i] == "2")
          this.translate.get('selectedValueIndoorOutdoorStatus.value2').subscribe(value => { statusText += value + appendcomma; });
        if (statusArr[i] == "3")
          this.translate.get('selectedValueIndoorOutdoorStatus.value3').subscribe(value => { statusText += value + appendcomma; });
        if (statusArr[i] == "4")
          this.translate.get('selectedValueIndoorOutdoorStatus.value4').subscribe(value => { statusText += value + appendcomma; });
        if (statusArr[i] == "5")
          this.translate.get('selectedValueIndoorOutdoorStatus.value5').subscribe(value => { statusText += value + appendcomma; });
        if (statusArr[i] == "6")
          this.translate.get('selectedValueIndoorOutdoorStatus.value6').subscribe(value => { statusText += value + appendcomma; });
      }
      return statusText;
    }
  }

  gotoCustomerLogDetail(id) {
    if (this.logData) {
        this.tempLogId = id;
        this.currentId = this.tempLogId;
        this.getSetNextPreviousId();
        this.customerLogsDetail=[];
        this.getCustomerLogsDetails(this.currentId)
    }
}

getSetNextPreviousId() {
    this.currentId = this.tempLogId;
    var itemIndex = this.logData.map(function (o) { return o.id; }).indexOf(this.currentId);
    if (itemIndex > 0)
        this.previousId = this.logData[itemIndex - 1].id;
    else
        this.previousId = null;

    if (itemIndex != (this.logData.length - 1))
        this.nextId = this.logData[itemIndex + 1].id;
    else
        this.nextId = null;
}
}
