export const locale = {
    lang: 'en',
    data: {
        'TITLE': {
        },
        'FIELDS': {
            'IsSendEmail': 'Is Send Email ?',
            'IsSendSMS': 'Is Send SMS ?',
            //'NoteLabel':'Please do not remove @signed words from sms text,email template text and notification text.'
        },
        'Message': {

        },
        'FILTERS': {
        },
        'moduleTypeList': {
        },
        'TOOTIPORTITLE':{
            'IsSendEmail':'Is Send Email',
            'IsSendNotification':'Is Send Notification',
            'IsSendSMS':'Is Send SMS',
        }
    }
};