import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConstant } from 'app/core/constant/ApiConstant';

@Injectable({
    providedIn: 'root'
})

export class StatisticService {
    private actionUrl: string;

    constructor(private http: HttpClient) {
        this.actionUrl = ApiConstant.API_URL + ApiConstant.STATISTIC + "/";
    }

}