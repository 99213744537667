//angular import
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MaterialModule } from '@fuse/material.module'
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { MessagingService } from './core/services/messaging.service';
import { environment } from '../environments/environment';

//fuse import
import { FuseModule } from '../@fuse/fuse.module';
import { SharedModule } from '../app/core/modules/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '../@fuse/components';
import { fuseConfig } from '../app/fuse-config';

//component import
import { AppComponent } from '../app/app.component';
import { LayoutModule } from '../app/layout/layout.module';
import { SampleModule } from '../app/main/sample/sample.module';
import { LoginComponent } from './main/pages/login/login.component';
import { TitleService } from './core/services/title.service';

//Import module
import { VibeDashboardModule } from './main/pages/dashboard/dashboard.module';
import { VibeCustomerModule } from './main/pages/customer/customer.module';
import { VibeMaterialModule } from './main/pages/material/material.module';
import { VibeProjectModule } from './main/pages/project/project.module';
import { VibeCoWorkerModule } from './main/pages/coWorker/coWorker.module';
import { VibeDocumentModule } from './main/pages/document/document.module';
import { InquiryModule } from './main/pages/inquiry/inquiry.module';
import { VibeReminderModule } from './main/pages/reminder/reminder.module';
import { VibeCompanyInfoModule } from './main/pages/companyInfo/companyInfo.module';
import { ReportModule } from './main/pages/report/report.module';
import { QuickInvoiceModule } from './main/pages/quickinvoice/quickinvoice.module';
import { CompanyInfoSettingsModule } from './main/pages/company-info-settings/company-info-settings.module';
import { ProjectOfferModule } from './main/pages/project-offer/project-offer.module';
import { VibeWorkHourModule } from './main/pages/workHour/workHour.module';
import { SuppliersModule } from './main/pages/suppliers/suppliers.module';
import { StatisticModule } from './main/pages/statistic/statistic.module';
import { PayrollModule } from './main/pages/payroll/payroll.module';
import { TermsOfEmploymentModule } from "./main/pages/terms-of-employment/terms-of-employment.module";
import { CarInformationsModule } from './main/pages/car-information/car-informations.module';
import { SettingsModule } from './main/pages/settings/settings.module';
import { QualityTestModule } from './main/pages/quality-test/quality-test.module';
import { ToolsAndLaddersModule } from './main/pages/tools-and-ladders/tools-and-ladders.module';
import { NanolinkModule } from './main/pages/nanolink/nanolink.module';

// Interceptor
import { TokenInterceptor } from 'app/core/auth/token.interceptor';
import { AuthService } from './core/services/auth.service';

import { Routegaurd } from './routegaurd';

// common
import { CommonService } from '../app/core/services/common.service';

// toastr
import { SimpleNotificationsModule } from 'angular2-notifications';

// change email
import { ChangeEmailComponent } from '../app/main/pages/change-email/change-email.component';
import { NonWorkHourComponent } from './main/pages/workHour/non-work-hour/non-work-hour.component';
import { DeleteAlertModalComponent } from './main/pages/delete-alert-modal/delete-alert-modal.component';
import { AlertMessageModelComponent } from './main/pages/alert-message-model/alert-message-model.component';
import { AlertPlanningMessageModelComponent } from './main/pages/alert-planning-message-model/alert-planning-message-model.component';
import { MovePlanningMessageModelComponent } from './main/pages/move-planning-message-model/move-planning-message-model.component';

import { TextEditorComponent } from './main/pages/text-editor/text-editor.component';
import { TextEditorTextAreaComponent } from './main/pages/text-editor-textarea/text-editor-textarea.component';
import { PlanningCalendarModule } from './main/pages/calendar/calendar.module';
import { ChangePasswordService } from './main/pages/change-password/change-password.service';
import { ChangePasswordComponent } from './main/pages/change-password/change-password.component';
import { VibeUserProfileModule } from './main/pages/userProfile/user-profile.module';
import { VibeMessageModule } from './main/pages/message/message.module';
import { VibeHelpModule } from './main/pages/help/help.module';
import { QuickPanelModule } from './layout/components/quick-panel/quick-panel.module';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { NgIdleModule } from '@ng-idle/core';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { VibeEditReminderComponent } from './main/pages/reminder/component/edit/edit-reminder.component';
import { DateTimePickerModule } from '@syncfusion/ej2-angular-calendars';

const appRoutes: Routes = [
    //{
    //    path      : '**',
    //    loadChildren: './main/pages/dashboard/dashboard.module#DashboardModule'
    //},
    {
        path: '',
        redirectTo: "/dashboard",
        pathMatch: 'full'
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'dashboard',
        loadChildren: './main/pages/dashboard/dashboard.module#VibeDashboardModule'
    },
    {
        path: 'customers',
        loadChildren: './main/pages/customer/customer.module#VibeCustomerModule',
        canActivate: [Routegaurd]
    },
    {
        path: 'coWorkers',
        loadChildren: './main/pages/coWorker/coWorker.module#VibeCoWorkerModule',
        canActivate: [Routegaurd]
    },
    {
        path: 'offers',
        loadChildren: './main/pages/project-offer/project-offer.module#ProjectOfferModule',
        canActivate: [Routegaurd]
    },
    {
        path: 'companyInfo',
        loadChildren: './main/pages/companyInfo/companyInfo.module#VibeCompanyInfoModule',
        canActivate: [Routegaurd]
    },
    {
        path: 'materials',
        loadChildren: './main/pages/material/material.module#VibeMaterialModule',
        canActivate: [Routegaurd]
    },
    {
        path: 'projects',
        loadChildren: './main/pages/project/project.module#VibeProjectModule',
        canActivate: [Routegaurd]
    },
    {
        path: 'documents',
        loadChildren: './main/pages/document/document.module#VibeDocumentModule',
        //canActivate: [Routegaurd]
    },
    {
        path: 'inquiry',
        loadChildren: './main/pages/inquiry/inquiry.module#InquiryModule',
        canActivate: [Routegaurd]
    },
    {
        path: 'report',
        loadChildren: './main/pages/report/report.module#ReportModule',
        canActivate: [Routegaurd]
    },
    {
        path: 'quickinvoice',
        loadChildren: './main/pages/quickinvoice/quickinvoice.module#QuickInvoiceModule',
        canActivate: [Routegaurd]
    },
    {
        path: 'workhours',
        loadChildren: './main/pages/workHour/workHour.module#VibeWorkHourModule',
        canActivate: [Routegaurd]
    },
    {
        path: 'planning',
        loadChildren: './main/pages/calendar/calendar.module#PlanningCalendarModule',
        canActivate: [Routegaurd]
    },
    {
        path: 'messages',
        loadChildren: './main/pages/message/message.module#VibeMessageModule',
        canActivate: [Routegaurd]
    },
    {
        path: 'statistics',
        loadChildren: './main/pages/statistic/statistic.module#StatisticModule',
        canActivate: [Routegaurd]
    },
    {
        path: 'suppliers',
        loadChildren: './main/pages/suppliers/suppliers.module#SuppliersModule',
        canActivate: [Routegaurd]
    },
    {
        path: 'carinformations',
        loadChildren: './main/pages/car-information/car-informations.module#CarInformationsModule',
        canActivate: [Routegaurd]
    },
    {
        path: 'payroll',
        loadChildren: './main/pages/payroll/payroll.module#PayrollModule',
        canActivate: [Routegaurd]
    },
    {
        path: 'termsofemployment',
        loadChildren: './main/pages/terms-of-employment/terms-of-employment.module#TermsOfEmploymentModule',
        canActivate: [Routegaurd]
    },
    {
        path: 'settings',
        loadChildren: './main/pages/settings/settings.module#SettingsModule',
        canActivate: [Routegaurd]
    },
    {
        path: 'qualitytest',
        loadChildren: './main/pages/quality-test/quality-test.module#QualityTestModule',
        canActivate: [Routegaurd]
    },
    {
        path: 'toolsandladders',
        loadChildren: './main/pages/tools-and-ladders/tools-and-ladders.module#ToolsAndLaddersModule',
        canActivate: [Routegaurd]
    },
    {
        path: 'nanolink',
        loadChildren: './main/pages/nanolink/nanolink.module#NanolinkModule',
        canActivate: [Routegaurd]
    }
];

const Options: any = {
    closeButton: true,
    debug: false,
    newestOnTop: true,
    showProgressBar: false,
    pauseOnHover: true,
    clickToClose: true,
    position: ["top", "right"],
    positionClass: "toast-top-center",
    preventDuplicates: true,
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 3000,
    extendedTimeOut: 1000,
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
    maxLength: 0,
    clickIconToClose: true,
    theClass: 'notification',

};

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        ChangePasswordComponent
    ],
    imports: [
        AngularFireDatabaseModule,
        AngularFireAuthModule,
        AngularFireMessagingModule,
        AngularFireModule.initializeApp(environment.firebase),
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,

        //Module
        VibeDashboardModule,
        VibeCustomerModule,
        VibeMaterialModule,
        VibeCoWorkerModule,
        VibeCompanyInfoModule,

        VibeProjectModule,
        VibeDocumentModule,
        InquiryModule,
        VibeReminderModule,
        ReportModule,
        QuickInvoiceModule,
        ProjectOfferModule,
        CompanyInfoSettingsModule,
        VibeWorkHourModule,
        SuppliersModule,
        CarInformationsModule,
        StatisticModule,
        PayrollModule,
        TermsOfEmploymentModule,
        SettingsModule,
        QualityTestModule,
        ToolsAndLaddersModule,
        NanolinkModule,
        // Material moment date module
        MatMomentDateModule,

        // Material
        MaterialModule,

        // Fuse modules
        FuseProgressBarModule,
        SharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        SampleModule,

        //Calendar
        PlanningCalendarModule,
        VibeUserProfileModule,
        VibeMessageModule,
        VibeHelpModule,
        QuickPanelModule,
        SimpleNotificationsModule.forRoot(),
        FuseModule.forRoot(fuseConfig),
        RouterModule.forRoot(appRoutes),
        TranslateModule.forRoot(),
        NgIdleKeepaliveModule.forRoot(),
        NgIdleModule.forRoot(),
        NgxSliderModule,
        DateTimePickerModule
    ],
    providers: [
        MessagingService,
        TitleService,
        AuthService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        ChangePasswordService,
        CommonService
    ],
    entryComponents: [
        ChangePasswordComponent,
        ChangeEmailComponent,
        NonWorkHourComponent,
        DeleteAlertModalComponent,
        AlertMessageModelComponent,
        AlertPlanningMessageModelComponent,
        TextEditorComponent,
        TextEditorTextAreaComponent,
        VibeEditReminderComponent,
        MovePlanningMessageModelComponent
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}
