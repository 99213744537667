export const locale = {
  lang: 'ds',
  data: {
    'FIELDS': {
      'CustomerReview': 'Kunde anmeldelse',
      'NotAddCustomerReview': 'Ingen kundeanmeldelse tilføjet',
      'Answer': 'Svar',
      'Question': 'Spørgsmål',
    },
  }
};
