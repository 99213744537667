import { Component, OnInit } from '@angular/core';
import { FuseTranslationLoaderService } from '../../../core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { AuthService } from '../../../core/services/auth.service';
import { TitleService } from '../../../core/services/title.service';
import { FolderListNavigationDTO } from '../../model/DropBox';
import { DropboxFilesService } from 'app/main/shared-component/dropbox-files/dropbox-files.service';
import { SettingForDriveValue } from '../../model/Settings';
import { DriveModuleEnum } from '../../../core/enum/drive-module.enum';

@Component({
    selector: 'vibe-document',
    templateUrl: './document.component.html',
    styleUrls: ['./document.component.scss']
})

export class VibeDocumentComponent implements OnInit {
    fileFolderPathData: Array<FolderListNavigationDTO> = [];
    //roleName: string = "";
    dropBoxRootFolder: SettingForDriveValue;
    constructor(private translationLoader: FuseTranslationLoaderService, private titleService: TitleService,
        private authService: AuthService,
        private dropboxFilesService: DropboxFilesService) {
        this.titleService.SetTitle("TITLE_DOCUMENTS");
        this.translationLoader.loadTranslations(english, danish);
        this.dropBoxRootFolder = this.dropboxFilesService.getSettingMasterValueDocumentPath();

    }

    ngOnInit() {
        var paths = "";
        if (this.dropBoxRootFolder.DocumentDriveType == (DriveModuleEnum.Onedrive).toString()) {
            paths = this.dropBoxRootFolder.Onedrive.Path;
        } else {
            paths = this.dropBoxRootFolder.Dropbox.Path;
        }
        var pathList = paths.split("/");
        this.fileFolderPathData = pathList.map((path, i) => ({
            name: path,
            isDisplay: i === pathList.length - 1
        }));
    }

    IsFileExist(count) {
    }
}







