import { Component, OnInit } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { ResponseBase } from "app/main/model/ResponseBase";
import { Claim } from "app/main/model/AuthModel";
import { AuthService } from "app/core/services/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { NotificationsService } from "angular2-notifications";
import { MatDialog } from "@angular/material";
import { UserRoleListComponent } from "../user-role-list/user-role-list.component";

@Component({
    selector: "vibe-role-claims",
    templateUrl: "./role-claims.component.html",
    styleUrls: ["./role-claims.component.scss"]
})

export class RoleClaimsComponent implements OnInit {
    showSpinner: boolean = false;
    claimListObj: Claim[] = [];
    roleId:string;
    roleList: any[] = [];
    isLoadingRoleClaims: boolean = false;

    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private translate: TranslateService,
        private notificationsService: NotificationsService,
        private dialog: MatDialog,
        private authService: AuthService) {
            this.translationLoaderService.loadTranslations(danish, english);
    }

    ngOnInit() {
        this.getRoles();
    }
    getRoles() {
        this.isLoadingRoleClaims = true;
        this.authService.getRoles<ResponseBase>().subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.result && response.result.length > 0) {
                    if(response.result){
                        this.roleList = response.result.sort(function (a, b) {
                            return a.name.localeCompare(b.name);
                        });;
                        this.roleId = this.roleList[0].id
                        this.getRoleClaims();
                    }
                }
            },
            error: err => {
                this.isLoadingRoleClaims = false;
            },
            complete: () => {
                this.isLoadingRoleClaims = false;
            }
        });
    }

    getRoleClaims() {
        this.showSpinner = true;
        this.authService.getRoleClaims<ResponseBase>(this.roleId).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.result && response.result.myResponse.length > 0) {
                    if(response.result.myResponse){
                        this.claimListObj = response.result.myResponse;
                    }
                }
            },
            error: err => {
                this.showSpinner = false;
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    addOrUpdateRoleClaims(){
        this.showSpinner = true;
        this.authService.addOrUpdateRoleClaims<ResponseBase>(this.roleId, this.claimListObj).subscribe({
            next: (response: ResponseBase) => {
              this.showSpinner = false;
              if (response != null && response.result) {
                this.translate.get("MESSAGES.Saved").subscribe({
                  next: (res) => {
                    this.notificationsService.create(res);
                  },
                  error: err => { }
                });
                this.getRoleClaims();
              }
            },
            error: err => {
                this.showSpinner = false;
            },
            complete: () => {
              this.showSpinner = false;
            }
          });
    }

    async ddlSearchRoleClaims(roleId) {
        this.roleId = roleId;
        this.getRoleClaims();
    }

    modifyRoleName() {
        const dialogRef = this.dialog.open(UserRoleListComponent, {
            height: '90%',
            width: '80%',
            disableClose: false,
            data: null
        });
        dialogRef.afterClosed().subscribe(result => {
            this.getRoles();
        });
    }
}