export const locale = {
    lang: 'en',
    data: {
        'TITLE': {
            'HeaderForCWAnniversary': 'Coworker Work Anniversary Reminder',
            'SMSTextCoworker':'Please do not remove @CoworkerName, @Date, @AnniversaryYear og @AnniversaryCoworkerNames from SMS text.',
            'NotificationTextCoworker':'Please do not remove @CoworkerName, @Date, @AnniversaryYear og @AnniversaryCoworkerNames from Notification text.',

            'SMSTextOtherCoworker':'Please do not remove @CoworkerNames, @AnniversaryYear and @AnniversaryCoworkerNames from SMS text.',
            'NotificationTextOtherCoworker':'Please do not remove @CoworkerNames, @AnniversaryYear and @AnniversaryCoworkerNames from Notification text.',

            'SMSTextOthersBeforeXDays':'Please do not remove @CoworkerNames, @Date, @AnniversaryYear and @AnniversaryCoworkerNames from SMS text.',
            'NotificationTextOthersBeforeXDays':'Please do not remove @CoworkerNames, @Date, @AnniversaryYear and @AnniversaryCoworkerNames from Notification text.',
        },
        'FIELDS': {
            'HoursToSend': 'Hours to send',
            'DaysToSend':  'Send x days before',
            'SendOnYear':'send after x years completed',
            'SMSText': 'SMS text',
            'NotificationText': 'Notification text',
            'EmailText': 'Email text',
            'ExecuteTime':'Execute Time',
            'IsCreateReminder':'Is Create Reminder?',
            'EmailTemplate':'Email Template',
            // 'EmailTemplateForCoworker':'Email Template For Coworker',
            // 'EmailTemplateForOthers':'Email Template For Other On Same Day',
            // 'EmailTemplateForOthersBeforeXDays':'Email Template For Others Before X Days',

            'TemplatesForCoworker':'Templates For Coworker',
            'TemplatesForOthers':'Templates For Other On Same Day',
            'TemplatesForOthersBeforeXDays':'Templates For Others Before X Days',

            'EmailTemplateForCoworker':'Email Template For Coworker',
            'EmailTemplateForOthers':'Email Template For Other On Same Day',
            'EmailTemplateForOthersBeforeXDays':'Email Template For Others Before X Days',

            // 'NoteLabelForCoworker1':'Please do not remove @CoworkerName and @AnniersaryYear from SMS,Notification and Email template text.',
            // 'NoteLabelForOthers1':'Please do not remove @CoworkerNames and @AnniversaryCoworkerNames from SMS,Notification and Email template text.',
            // 'NoteLabelForOthersBeforeXDays1':'Please do not remove @CoworkerNames,@Date and @AnniversaryCoworkerNames from SMS,Notification and Email template text.'
        },
        'VALIDATIONS': {
            'HoursToSend': 'Hours to send is required',
            'ExecuteTime':'Execution time is required'
        },
        'MESSAGES': {
            'Saved': 'Saved successfully.',
        },
        'BUTTONS': {
            'Save': 'Save',
        }
    }
};