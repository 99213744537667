export const locale = {
    lang: 'en',
    data: {
        'MESSAGES': {
            'Created': 'Created successfully',
            'Updated': 'Updated successfully',
            'Deleted': 'Deleted successfully',
            'Failed': 'Operation failed , try later',
            'NORECORD': 'No Record Found',
        },
        'BUTTONS': {
            'Save': 'Save',
            'Reset': 'Reset',
            'Cancel': 'Cancel',
            'Add': "Add",
            'Back': 'Back'
        },
        'FIELDS': {
            'SRNO': 'Sr no.',
            'Date': 'Date',
            'CoworkerName': 'Coworker',
            'Projects': 'Projekter',
            'StartDate': 'Start Date*',
            'EndDate': 'End Date*',
            'WeekDays':'WeekDays',
            'Days':'Days',
            'WeekNo':"Uge nr",
        },
        'Lable': {
            'SRNO': 'Sr No',
            'Message': 'Message',
            'Status': 'Status',
            'AudienceType': 'Audience Type'
        },
        'Headers': {
            'StatusList': 'Status list',
        },
        'Validators': {
            'StartDate': 'StartDate is required',
            'EndDate': 'EndDate is required',
            'ExecuteTime':"ExecuteTime is required",
            'SelectProject': 'Select Project',
        },
    }
};

