import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { ProjectStatus } from 'app/constant/common.constant';
import { TranslateService } from '@ngx-translate/core';

import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { MatSelect } from "@angular/material";
import { BehaviorSubject } from "rxjs";


@Component({
    selector: 'project-status',
    templateUrl: './project-status.component.html',
    styleUrls: ['./project-status.component.scss'],
})

export class ProjectStatusComponent implements OnInit {
    //@Input() RequisitionStatus: string;
    private _RequisitionStatus = new BehaviorSubject<number>(null);
    @Input()
    set RequisitionStatus(value) {
        this._RequisitionStatus.next(value);
    };
    get RequisitionStatus() {
        return this._RequisitionStatus.getValue();
    }

    @Output() Status = new EventEmitter();
    @ViewChild('myMatSelect', {static: true}) myMatSelect: MatSelect;

    requisitionStatus: any;
    selectStatus = ProjectStatus;
    tempRequisitionStatus: any = "";
    projectStatusList = ProjectStatus;

    constructor(private translationLoader: FuseTranslationLoaderService,
        private translate: TranslateService) {
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
        this._RequisitionStatus
        .subscribe(val => {
            this.requisitionStatus = val;
        })
    }

    DropDownSearchRequisitionStatus(value) {
        this.selectStatus = this.projectStatusList;
        if (value) {
            if (this.translate.store.currentLang == "en")
                this.selectStatus = this.projectStatusList.filter(x => x.en.toLowerCase().includes(value.toLowerCase()));
            else
                this.selectStatus = this.projectStatusList.filter(x => x.ds.toLowerCase().includes(value.toLowerCase()));
        }
        else
            this.selectStatus = this.projectStatusList;
    }

    onChangeStatus(SelectedStatusID) {
        this.myMatSelect.value = this.requisitionStatus
        this.Status.emit(SelectedStatusID);
    }
}