export const locale = {
    lang: 'en',
    data: {
        'MESSAGES': {
            'Add': 'Added successfully',
        },
        'BUTTONS': {
            'Back': 'Cancel',
            'Ok': 'Save',
            'ClearAll': 'Clear All',
            'Value': 'Value',
        }
    }
};