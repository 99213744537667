import { Component, OnInit, Input, Inject, Optional } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ReminderService } from '../../pages/reminder/reminder.service';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { FormService } from 'app/core/services/form.service';
import { CountryJson } from 'app/constant/flag.constant';

@Component({
    selector: 'vibe-send-sms',
    templateUrl: './send-sms.component.html',
    styleUrls: ['./send-sms.component.scss']
})

export class SendSMSComponent implements OnInit {
    SMSForm: FormGroup;
    ModuleData: any;
    SMSObject: any;
    showSpinner: boolean = false;
    countryList = CountryJson;
    tempCountryList = CountryJson;
    CountryName: string = "";
    constructor(private translationLoader: FuseTranslationLoaderService,
        private _router: Router,
        private translate: TranslateService,
        private formBuilder: FormBuilder,
        private reminderService: ReminderService,
        public formService: FormService,
        private notificationsService: NotificationsService,
        private dialogRef: MatDialogRef<SendSMSComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
        this.SMSForm = this.formBuilder.group({
            name: [null],
            mobile: [null, Validators.required],
            text: [null, Validators.required],
            countryCode: [null, Validators.required]
        });
        setTimeout(() => {
            this.ModuleData = this.data;
        }, 150);
    }

    sendSMS() {
        this.SMSObject = {
            mobile: this.ModuleData && this.ModuleData.mobile ? this.ModuleData.mobile.trim() : null,
            text: this.ModuleData.text,
            flag: this.ModuleData.flag,
            moduleID: this.ModuleData.moduleID,
            countryCode: this.ModuleData.countryCode
        }
        this.formService.markFormGroupTouched(this.SMSForm);
        if (this.SMSForm.invalid) {
            return;
        }
        this.showSpinner = true;
        this.reminderService.SendSMS<ResponseBase>(this.SMSObject).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                this.dialogRef.close(true);
                this.translate.get("MESSAGES.Success").subscribe({
                    next: (res) => {
                        this.notificationsService.create(res);
                    },
                    error: err => {
                    }
                });
            },
            error: err => {
                this.showSpinner = false;
                this.dialogRef.close(false);
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            },
            complete: () => {
                this.showSpinner = false;
            }
        })

    }

    close() {
        this.dialogRef.close();
    }

    getFlagClass(value) {
        var country = value;
        return country.toLowerCase() + ' flag';
    }

    DropDownSearchCountry(name) {
        if (name == "" || name == undefined)
            this.countryList = this.tempCountryList;
        else
            this.countryList = this.tempCountryList.filter(it => { return it.name.toLowerCase().includes(name.toLowerCase()); });
    }
}