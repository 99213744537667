export const locale = {
  lang: 'en',
  data: {
    'MESSAGES': {
      'Add': 'Added successfully',
    },
    'BUTTONS': {
      'Back': 'Cancel',
      'Ok': 'Save'
    },
    'FIELDS': {
      'to': 'Email Sent To',
      'toName': 'Email Sent To Name',
      'from': 'Email Sent From',
      'fromName': 'Email Sent From Name',
      'subject': 'Subject',
      'coworker': 'Co-Worker',
      'All': 'All',
      'AttachpdfFile':'Attach PDF file',
    },
    'VALIDATIONS': {
      'to': 'Valid Email',
      'toName': 'Valid Name',
      'from': 'Valid Email',
      'fromName': 'Valid Name',
      'subject': 'Valid Subject',
    },
  }
};
