import { Component, OnInit, Inject } from '@angular/core';

import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { FuseTranslationLoaderService } from '../../../../../../@fuse/services/translation-loader.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'app-dropbox-create-folder-modal',
    templateUrl: './create-folder-popup.component.html',
    styles: []
})
//modal to ask for create folder
export class CreteFolderModalComponent implements OnInit {
    FolderName: string;

    constructor(private translationLoader: FuseTranslationLoaderService,
        public dialogRef: MatDialogRef<CreteFolderModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {

        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
    }

    onCloseDialog(e) {
        this.dialogRef.close(e ? this.FolderName : false)
    }
}







