export const locale = {
    lang: 'ds',
    data: {
        'BUTTONS': {
            'Add': 'Tilføj',
            'Back': 'Tilbage',
            'Save': 'Gem'
        },
        'FIELDS': {
            'Key': "Nøgler",
            'Quantity': "Antal",
            'Number': 'Nummer',
            'Remark': "Bemærkning"
        },
        'VALIDATIONS': {
            'keyType': 'Vælg nøgle',
            'keysName': 'Indsæt værdi',
            'QuantityRequired': 'Indsæt antal',
            'NumberRequired': 'Indsæt Nummer'
        },
        'MESSAGES': {
            'Created': 'Oprettet med succes',
            'Updated': 'Opdateret med succes',
            'Deleted': 'Slettet med succes',
            'Failed': 'Operation mislykket, prøv senere',
            'NORECORD': 'Ingen rekord fundet',
        },
        "KeyTypes": {
            "value1": "Firma nøgle",
            "value2": "Vogn nummer",
            "value3": "Alarm pinkode",
            "value4": "Andre nøgler",
        }
    }
}