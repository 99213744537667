import { Component, OnInit, ElementRef, NgZone, ViewChild, Input, Output, EventEmitter, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatOption } from '@angular/material';
import { emailParams } from '../../model/Project'
import { CompanyInfoSetting } from '../../model/CompanyInfo';
import { EmailSendingParam } from '../../model/common';
import { FuseTranslationLoaderService } from '../../../core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl, ValidatorFn } from '@angular/forms';
import { FormService } from '../../../core/services/form.service';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { AuthService } from 'app/core/services/auth.service';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { CkeditorConfig } from 'app/constant/common.constant';

@Component({
    selector: 'app-ckeditor',
    templateUrl: './ckeditor.component.html',
    styles: []
})
export class CKEditorComponent implements OnInit {
    public editorContent = "";
    showSpinner: boolean = false;
    public editorOptions = {
        placeholder: "insert content..."
    };
    config = CkeditorConfig;
    constructor(public dialogRef: MatDialogRef<CKEditorComponent>,
        private authService: AuthService,
        private notificationsService: NotificationsService,
        private translate: TranslateService,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any, public translationLoader: FuseTranslationLoaderService) {
        this.translationLoader.loadTranslations(english, danish);
    }
    ngOnInit() {
        this.config.height = 400;
        this.editorContent = this.data;
    }
   
    colseTextEditor() {
        this.dialogRef.close();
    }

    sendBody(){
        this.dialogRef.close(this.editorContent);
    }

    save(event) {
    }
}
