import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { Device } from 'app/main/model/CoWorker';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { DateAdapter, MatDialogRef, MatDialog, MAT_DIALOG_DATA, MatOption } from '@angular/material';
import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { ResponseBase } from 'app/main/model/ResponseBase';
import { TitleService } from 'app/core/services/title.service';
import { ActivatedRoute, Router } from "@angular/router";
import { NanolinkService } from 'app/main/pages/nanolink/nanolink.service';
import { DeviceType, DeviceModel} from "app/constant/common.constant";
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';
import { spaceOnlyValidator } from "app/core/Validators/validation";
import { AuthService } from "app/core/services/auth.service";

@Component({
    selector: "new-device",
    templateUrl: "./new-device.component.html",
    styleUrls: ["./new-device.component.scss"]
})

export class NewDeviceComponent implements OnInit {
    showSpinner: boolean = false;
    deviceForm: FormGroup;
    deviceObject: Device = new Device();
    requestBase: any;
    selectDeviceType = DeviceType;
    selectDeviceModel = DeviceModel;
    deviceId: any = "";
    showMap: boolean = false;
    roleClaims: any;

    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private translate: TranslateService,
        private formBuilder: FormBuilder,
        private notificationsService: NotificationsService,
        private titleService: TitleService,
        private nanolinkService: NanolinkService,
        private authService: AuthService,
        private _router: Router,
        public dialog: MatDialog,
        private _route: ActivatedRoute,
        private adapter: DateAdapter<any>) {
        this.translationLoaderService.loadTranslations(danish, english);
        this.roleClaims = this.authService.getRoleClaimsList();
    }

    ngOnInit() {
        this.adapter.setLocale('da');
        this.deviceForm = this.formBuilder.group({
            id: [null],
            model: [null, Validators.required],
            type: [null, Validators.required],
            deviceVID:[null, Validators.compose([Validators.required, spaceOnlyValidator.bind(this)])],
            description: [null],
        });

        this._route.params.subscribe(params => {
            var id = params['id'];
            this.deviceId = id;
            if(this.deviceId)
                this.Edit();

        });
    }

    Edit() {
        this.showSpinner = true;
        this.nanolinkService.GetDeviceById<ResponseBase>(this.deviceId)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.deviceObject = response.result;
                    this.showSpinner = false;
                },
                error: err => {
                },
                complete: () => {
                    this.showSpinner = false;
                }
            });
    }



    SaveDevice() {
        this.deviceForm.markAllAsTouched();
        if (this.deviceForm.valid)
        {
            if(this.deviceObject.id)
                this.updateDevice()
            else
                this.addDevice();
        }
    }

    addDevice() {
        this.showSpinner = true;
        this.nanolinkService.AddDevice<ResponseBase>(this.deviceObject).subscribe({
            next: (response: ResponseBase) => {
                if (response) {
                    this.translate.get("MESSAGES.Created").subscribe({
                        next: (res) => {
                            this.notificationsService.success(res);
                        },
                        error: err => {
                        }
                    });
                    this.ResetDeviceForm();
                    this._router.navigate(['nanolink/devices'],{ }); 
                }
            },
            error: err => {
                this.showSpinner = false;
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }
    updateDevice() {
        this.showSpinner = true;
        this.nanolinkService.UpdateDevice<ResponseBase>(this.deviceObject.id, this.deviceObject).subscribe({
            next: (response: ResponseBase) => {
                if (response) {
                    this.translate.get("MESSAGES.Updated").subscribe({
                        next: (res) => {
                            this.notificationsService.success(res);
                        },
                        error: err => {
                        }
                    });
                    this.ResetDeviceForm();
                    this._router.navigate(['nanolink/devices'],{ }); 
                }
            },
            error: err => {
                this.showSpinner = false;
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    ChangeImage(event) {
        this.deviceObject.imageBase64 = event;
    }

    ResetDeviceForm() {
        this.deviceObject = new Device();
        this.deviceId = "";
        this.deviceForm.reset();
    }

    DeleteDevice() {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: "200px",
            data: { Header: 'Headers.DeleteAlert' }
        })

        dialogRef.afterClosed().subscribe(result => {
            if (result) {        
                this.showSpinner = true;
                this.nanolinkService.DeleteDevice<ResponseBase>(this.deviceObject.id).subscribe({
                    next: (response: ResponseBase) => {
                        if(response.statusCode==200) {
                            this.translate.get("MESSAGES.Deleted").subscribe({
                                next: (res) => {
                                    this.notificationsService.create(res);
                                },
                                error: err => { }
                            });
                            this._router.navigate(['nanolink/devices'],{ });
                        }
                    },
                    error: err => {
                        this.translate.get("MESSAGES.Failed").subscribe({
                            next: (res) => {
                                this.notificationsService.error(res);
                            },
                            error: err => { }
                        });
                    },
                    complete: () => {
                        this.showSpinner = false;
                    }
                })
            }
        });
    }    
}