import { Component, OnInit, Input, } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { RequestCoWorker, RequestForPeojectsMaterialHistory } from 'app/main/model/RequestBase';
import { MaterialService } from "app/main/pages/material/material.service";
import { CoWorkersService } from "app/main/pages/coWorker/coWorkers.service";
import { ResponseBase } from 'app/main/model/ResponseBase';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";

@Component({
    selector: 'vibe-projects-material-history',
    templateUrl: './projects-material-history.component.html',
    styleUrls: ['./projects-material-history.component.scss']
})

export class ShowProjectsMaterialHistoryComponent implements OnInit {
    @Input() MaterialNumber: string;
    totalPrice: number = 0;
    totalQTY: number = 0;
    selectedCoworkerID: string = "0";
    searchStartDate: Date = new Date(new Date().getFullYear() + "-01-01");
    searchEndDate: Date = new Date(new Date().getFullYear() + "-12-31");
    reqModel: RequestForPeojectsMaterialHistory = new RequestForPeojectsMaterialHistory();
    showSpinner = false;
    projectsMaterialList = [];
    coWokersList = [];
    constructor(private translationLoader: FuseTranslationLoaderService,
        private materialService: MaterialService,
        public coWorkersService: CoWorkersService,
        private _route: ActivatedRoute,
        private _router: Router,
    ) {
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
        this._route.params.subscribe(params => {
            this.reqModel.MaterialID = params['id'];
        });
        this.GetProjectsMaterialHistory();
        this.getCoWorkers();
    }

    GetProjectsMaterialHistory() {
        this.showSpinner = true;
        this.reqModel.IsActive = false;
        this.reqModel.IsDeleted = false;
        this.reqModel.Index = 1;
        this.reqModel.Limit = 100;
        this.reqModel.StartDate = moment(this.searchStartDate).format("YYYY-MM-DD");
        this.reqModel.EndDate = moment(this.searchEndDate).format("YYYY-MM-DD");
        this.reqModel.CoworkerID = this.selectedCoworkerID;
        if (this.reqModel.CoworkerID == "0") {
            this.reqModel.CoworkerID = "";
        }
        this.materialService.GetProjectsMaterialForHistory<ResponseBase>(this.reqModel)
            .subscribe((response: ResponseBase) => {
                if (response != null) {
                    this.totalPrice = response.result.totalPrice;
                    this.totalQTY = response.result.totalQuantity;
                    this.projectsMaterialList = response.result.projectsMaterialForHistoryList;
                }
            },
                error => (err) => {
                },
                () => {
                    this.showSpinner = false;
                });
    }

    getCoWorkers() {
        this.coWokersList = [];
        let model = new RequestCoWorker();
        model.IsActive = true;
        model.IsDeleted = false;
        model.Limit = 400;
        model.OrderBy = "Name";
        this.coWorkersService.Get<ResponseBase>(model)
            .subscribe((response: ResponseBase) => {
                if (response) {
                    this.coWokersList = response.result.filter(x => x.doNotShowOnPlanReminderProj == false);
                }
            },
                error => (err) => {
                },
                () => {
                });
    }

    onStartDateSelect() {
    }

    goToProject(id) {
        this._router.navigate(['/projects/edit', id]);
    }

    goToCustomer(id) {
        this._router.navigate(['/customers/edit', id]);
    }
}