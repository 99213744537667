import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { RequestForQualityTestTemperaturList, RequestProjectOfferRoom } from 'app/main/model/RequestBase';
import { OfferDefectRoomDTO } from 'app/main/model/OfferQualityTestCategory';
import { environment } from 'environments/environment';
import { CompanyInfoService } from 'app/main/pages/companyInfo/companyInfo.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { QualityTestService } from '../../quality-test.service';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { ProjectsOfferRoom } from 'app/main/model/ProjectOffer';
import { ProjectofferService } from 'app/main/pages/project-offer/project-offer.service';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';
import { FolderListNavigationDTO } from 'app/main/model/DropBox';
import { DropboxFilesService } from 'app/main/shared-component/dropbox-files/dropbox-files.service';
import { DriveModuleEnum } from 'app/core/enum/drive-module.enum';

@Component({
    selector: 'app-defect-room',
    templateUrl: './defect-room.component.html',
    styleUrls: ['./defect-room.component.scss'],
})
export class DefectRoomComponent implements OnInit {
    @Input() ModuleID: string = "";
    @Input() ProjectID: string = "";
    @Input() ModuleName: string = "";
    @Input() ModuleNumber: string = "";
    @Input() ModuleRoomSelectID: string = "";
    @Input() OfferQualityTestCategoryID: string = "";

    @Input() extraParamToSend: any = [];
    @Input() fileFolderPathData: Array<FolderListNavigationDTO> = [];
    moduleId: string;
    formData: any;
    uploadImg: any;
    selectedOfferRooms: any[] = [];
    ProjectsOfferRooms: ProjectsOfferRoom[];
    selectOfferRoomAllOption: boolean = false;
    showSpinner: boolean = false;
    envirnmentURL: string = "";
    listRequest: RequestForQualityTestTemperaturList = new RequestForQualityTestTemperaturList();
    defectRoomsList: OfferDefectRoomDTO[] = [];
    displayPagination: boolean;
    pagination: any;
    limitNumber: any;
    HumidityAndTemperatureDescription: string = '';
    defectFormGroup: FormGroup;
    offerID: string = "";
    projectID: string = "";
    files: any[] = [];
    module_name: string = "";
    module_id: string = "";
    rootFolderPath: string = "";
    defectFormDetail: any = {};
    @ViewChild('fileDropRef', { static: true }) fileDropRef: ElementRef;

    constructor(
        private translationLoader: FuseTranslationLoaderService,
        private translate: TranslateService,
        private notificationsService: NotificationsService,
        private projectofferService: ProjectofferService,
        private qualityTestService: QualityTestService,
        public companyInfoService: CompanyInfoService,
        private formBuilder: FormBuilder,
        public dialog: MatDialog,
        private dropboxFilesService: DropboxFilesService,
    ) {
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
        this.envirnmentURL = environment.url;
        this.listRequest.Index = 1;
        this.listRequest.Limit = 100;
        this.projectID = this.ProjectID;
        this.offerID = this.ModuleID;
        this.listRequest.projectID = this.projectID;
        this.listRequest.offerID = this.offerID;
        this.listRequest.IsActive = true;
        this.listRequest.IsDeleted = false;
        this.listRequest.qualityTestCategoryID = this.OfferQualityTestCategoryID;
        this.GetDefectRoomsList();
        var requestProjectOfferRoom: RequestProjectOfferRoom = new RequestProjectOfferRoom();
        requestProjectOfferRoom.Limit = 100;
        // requestProjectOfferRoom.OfferId = this.OfferID;
        requestProjectOfferRoom.OfferId = this.ModuleRoomSelectID ? this.ModuleRoomSelectID : null;
        this.offerID = requestProjectOfferRoom.OfferId;
        this.module_name = this.ModuleRoomSelectID ? "ProjectOffers" : this.ModuleName;
        this.module_id = this.ModuleRoomSelectID ? this.ModuleRoomSelectID : this.ModuleID;
        this.getProjectOfferRoomList(requestProjectOfferRoom);
        this.InitFormControl();
        var rootPath = this.dropboxFilesService.getSettingMasterValueDocumentPath();
        var paths = "";
        if (rootPath.DocumentDriveType == DriveModuleEnum.Onedrive) {
            paths = rootPath.Onedrive.Path;
        } else {
            paths = rootPath.Dropbox.Path;
        }
        this.rootFolderPath = "/" + paths + "/" + this.module_name + "/" + this.ModuleNumber + "/Kvalitetstest/Fejl og mangler";
    }

    InitFormControl() {
        this.defectFormGroup = this.formBuilder.group({
            id: [null],
            offerID: [this.offerID],
            roomID: [null],
            selectedOfferRooms: [null, [Validators.required]],
            text: [null, [Validators.required]],
            projectID: [this.projectID],
        });
    }

    GetDefectRoomsList() {
        if (!this.listRequest.offerID)
            this.listRequest.offerID = "";
        this.showSpinner = true;
        this.qualityTestService.GetDefectRooms(this.listRequest).subscribe({
            next: (response) => {
                if (response) {
                    this.defectRoomsList = response.result;
                    this.pagination = response.pagination;
                    this.displayPagination = true;

                }
                else {
                    this.defectRoomsList = [];
                    this.displayPagination = false;
                }
            },
            error: err => {
                this.showSpinner = false;
                this.defectRoomsList = [];
                this.displayPagination = false;
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    getProjectOfferRoomList(model) {
        this.showSpinner = true;
        this.projectofferService.getProjectOfferRoomList<ResponseBase>(model)
            .subscribe((response: ResponseBase) => {
                this.showSpinner = false;
                this.ProjectsOfferRooms = [];
                if (response != null) {
                    this.ProjectsOfferRooms = response.result.myResponse;
                }
            },
                error => (err) => {
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });
                });
    }

    RoomSelectAllOption() {
        this.selectOfferRoomAllOption = !this.selectOfferRoomAllOption;
        this.selectedOfferRooms = [];
        if (this.selectOfferRoomAllOption) {
            this.selectedOfferRooms.push("0");
            for (var i = 0; i < this.ProjectsOfferRooms.length; i++) {
                this.selectedOfferRooms.push(this.ProjectsOfferRooms[i].id);
            }
        }
    }

    SaveDefectRoom() {
        if (this.defectFormGroup.valid) {
            this.defectFormGroup.patchValue({ roomID: this.selectedOfferRooms.join() });
            this.defectFormDetail = this.defectFormGroup.value;
            this.defectFormDetail.files = this.files;
            this.defectFormDetail.rootFolderPath = this.rootFolderPath;
            this.defectFormDetail.qualityTestCategoryID = this.OfferQualityTestCategoryID;
            this.showSpinner = true;
            if (this.defectFormDetail.id == null) {
                this.qualityTestService.AddDefectRooms(this.defectFormDetail).subscribe({
                    next: (response) => {
                        if (response) {
                            this.translate.get("MESSAGE.Added").subscribe({
                                next: (res) => {
                                    this.notificationsService.success(res);
                                },
                                error: err => {
                                }
                            });
                            this.defectFormGroup.reset();
                            this.InitFormControl();
                            this.selectedOfferRooms = [];
                            this.listRequest.Index = 1;
                            this.listRequest.Limit = 100;
                            this.listRequest.offerID = this.offerID;
                            this.GetDefectRoomsList();
                        }
                    },
                    error: err => {
                    },
                    complete: () => {
                        this.showSpinner = false;
                        this.defectFormGroup.patchValue({ offerID: this.offerID });
                        this.resetFileUploader();
                    }
                });
            } else {
                this.qualityTestService.UpdateDefectRooms(this.defectFormDetail).subscribe({
                    next: (response) => {
                        if (response) {
                            this.translate.get("MESSAGE.Updated").subscribe({
                                next: (res) => {
                                    this.notificationsService.success(res);
                                },
                                error: err => {
                                }
                            });
                            this.defectFormGroup.reset();
                            this.InitFormControl();
                            this.selectedOfferRooms = [];
                            this.listRequest.Index = 1;
                            this.listRequest.Limit = 100;
                            this.listRequest.offerID = this.offerID;
                            this.GetDefectRoomsList();
                        }
                    },
                    error: err => {
                    },
                    complete: () => {
                        this.showSpinner = false;
                        this.defectFormGroup.patchValue({ offerID: this.offerID });
                        this.resetFileUploader();
                    }
                });
            }
        }
    }

    EditDefectRoom(obj) {
        this.defectFormGroup.patchValue(obj)
        this.selectedOfferRooms = obj.roomID ? obj.roomID.split(',') : [];
    }

    DeleteDefectRoom(obj) {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: '20%',
            data: { Header: 'Headers.DeleteDefectRoom' }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.DelQuaCat(obj.id);
            }
        });
    }

    DelQuaCat(Id) {
        this.showSpinner = true;
        this.qualityTestService.DeleteDefectRooms(Id).subscribe({
            next: (response: ResponseBase) => {
                this.translate.get("MESSAGE.Deleted").subscribe({
                    next: (res) => {
                        this.notificationsService.create(res);
                    },
                    error: err => {
                    }
                });
                this.listRequest.Index = 1;
                this.listRequest.Limit = 100;
                this.listRequest.offerID = this.offerID;
                this.GetDefectRoomsList();
            },
            error: err => {
                this.translate.get("MESSAGE.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    receivePaginatonEvent(event) {
        this.listRequest.Index = event.pageIndex;
        this.listRequest.Limit = event.pageSize;
        this.limitNumber = event.pageSize;
        this.GetDefectRoomsList();
    }

    /**
 * on file drop handler
 */
    onFileDropped($event) {
        this.prepareFilesList($event);
    }

    /**
     * handle file from browsing
     */
    fileBrowseHandler(files) {
        this.prepareFilesList(files);
    }

    /**
     * Delete file from files list
     * @param index (File index)
     */
    deleteFile(index: number) {
        this.files.splice(index, 1);
    }


    /**
     * Convert Files list to normal array list
     * @param files (Files List)
     */
    prepareFilesList(files: Array<any>) {
        for (const item of files) {
            const reader = new FileReader();
            reader.onload = (e: any) => {
                item.base64 = e.target.result.split(',')[1];
                item.fileName = item.name;
                item.fileType = item.type;
                console.log("item", item);
                if (!this.files)
                    this.files = [];
                this.files.push(item);
            };
            reader.readAsDataURL(item);
        }
    }

    resetFileUploader() {
        this.files = [];
        if (this.fileDropRef && this.fileDropRef.nativeElement)
            this.fileDropRef.nativeElement.value = null;
    }
}

