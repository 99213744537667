export const locale = {
    lang: 'en',
    data: {
        'TITLE': {
            'HeaderSMSReminder': 'Dismissal for failure to report hours',
            'ReminderSMSAfterAdminHeader': 'Admin',

        },
        'FIELDS': {
            "ReminderSMSAfterMin1": "(1) Send Reminder SMS After x .min",
            "ReminderSMSAfterMin2": "(2) Send Reminder SMS After x .min",
            "ReminderSMSAfterMin3": "(3) Send Reminder SMS After x .min",
            "ReminderSMSAfterMinAdmin": "Send Reminder SMS After x .min  (Admin)",
            "ReminderSMSText1": "(1) Reminder SMS Text",
            "ReminderSMSText2": "(2) Reminder SMS Text",
            "ReminderSMSText3": "(3) Reminder SMS Text",
            "ReminderSMSTextAdmin": "Reminder SMS Text (Admin)",
            'WeekDays': 'WeekDays',
            'IsCreateReminder': 'Is Create Reminder?',
            'LabelForNotApplyWorkHourCoworker': 'Please do not remove @coworkerList from SMS,Notification and Email template text.',
            'WeekDayExecute': {
                "isExecuteMonday": "Monday",
                "isExecuteMondayTime": "Monday Execute Time",
                "isExecuteTuesday": "Tuesday",
                "isExecuteTuesdayTime": "Tuesday Execute Time",
                "isExecuteWednesday": "Wednesday",
                "isExecuteWednesdayTime": "Wednesday Execute Time",
                "isExeciteThursday": "Thursday",
                "isExeciteThursdayTime": "Thursday Execute Time",
                "isExeciteFriday": "Friday",
                "isExeciteFridayTime": "Friday Execute Time",
                "isExeciteSaturday": "Saturday",
                "isExeciteSaturdayTime": "Saturday Execute Time",
                "isExecuteSunday": "Sunday",
                "isExecuteSundayTime": "Sunday Execute Time",
            }
        },
        'VALIDATIONS': {
            "ReminderSMSAfterMin1": "(1) Reminder sms after minutes required",
            "ReminderSMSAfterMin2": "(2) Reminder sms after minutes required",
            "ReminderSMSAfterMin3": "(3) Reminder sms after minutes required",
            "ReminderSMSAfterMinAdmin": "Reminder sms after minutes required (Admin)",
            "ReminderSMSText1": "(1) Reminder sms text required",
            "ReminderSMSText2": "(2) Reminder sms text required",
            "ReminderSMSText3": "(3) Reminder sms text required",
            "ReminderSMSTextAdmin": "Reminder sms text required (Admin)",
            'WeekDayExecute': {
                "isExecuteMondayTime": "Monday execute time required",
                "isExecuteTuesdayTime": "Tuesday execute time required",
                "isExecuteWednesdayTime": "Wednesday execute time required",
                "isExeciteThursdayTime": "Thursday execute time required",
                "isExeciteFridayTime": "Friday execute time required",
                "isExeciteSaturdayTime": "Saturday execute time required",
                "isExecuteSundayTime": "Sunday execute time required",
            }
        },
        'MESSAGES': {
            'Saved': 'Saved successfully.',
        },
        'BUTTONS': {
            'Save': 'Save',
        }
    }
};