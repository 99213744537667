import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { OfferQualityTestAnswer } from 'app/main/model/OfferQualityTestAnswer';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { RequestOfferQualityTestAnswer } from 'app/main/model/RequestBase';
import { QualityTestService } from '../../quality-test.service';
import { AddQuestion,AddQuestionInformation } from 'app/main/model/OfferQualityTestCategory';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';

@Component({
    selector: 'add-question-information',
    templateUrl: './add-question-information.component.html',
    styleUrls: ['./add-question-information.component.scss']
})
export class AddQuestionInformationComponent implements OnInit {
    showSpinner: boolean = false;
    question: AddQuestion[] = [];
    currentObject: any = new AddQuestion();
    questionObject: any = new AddQuestionInformation();

    constructor(private _route: ActivatedRoute,
        private translationLoader: FuseTranslationLoaderService,
        private translate: TranslateService,
        public dialog: MatDialog,
        private dialogRef: MatDialogRef<AddQuestionInformationComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private notificationsService: NotificationsService,
        private qualityTestService: QualityTestService) {
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
        this.questionObject = this.data.data;
        if(this.questionObject.json){
            this.questionObject.json = JSON.parse(this.questionObject.json);
            this.question = this.questionObject.json;
        }
    }

    // addQuestion(){
    //     if (this.currentObject.text && !this.currentObject.id) {
    //         var tempQuestion = new AddQuestion();
    //         tempQuestion.id = this.question.length + 1;
    //         tempQuestion.text = this.currentObject.text;
    //         this.question.push(tempQuestion);
    //         //this.questionObject.json = JSON.stringify(this.question);
    //         this.currentObject.text = "";
    //     }
    //     if (this.currentObject.text && this.currentObject.id) {
    //         this.question.filter(q =>{
    //             if(q.id == this.currentObject.id)
    //                 q.text=this.currentObject.text;
    //         })
    //         this.currentObject = new AddQuestion();
    //     }
    // }

    addQuestionInformation(){
        if (this.currentObject.text && !this.currentObject.id) {
            var tempQuestion = new AddQuestion();
            tempQuestion.id = this.question.length + 1;
            tempQuestion.text = this.currentObject.text;
            this.question.push(tempQuestion);
            //this.questionObject.json = JSON.stringify(this.question);
            this.currentObject.text = "";
        }
        if (this.currentObject.text && this.currentObject.id) {
            this.question.filter(q =>{
                if(q.id == this.currentObject.id)
                    q.text=this.currentObject.text;
            })
            this.currentObject = new AddQuestion();
        }
        this.showSpinner = true;
        if(this.question && this.question.length > 0)
            this.questionObject.json = JSON.stringify(this.question);
        this.qualityTestService.AddOfferQualityTestCustomerQuestions(this.questionObject).subscribe({
                next: (response: ResponseBase) => {
                    if (response) {
                        this.showSpinner = false;
                        this.translate.get("MESSAGES.Success").subscribe({
                            next: (res) => {
                                this.notificationsService.success(res);
                            },
                            error: err => {
                            }
                        });
                        this.question =[];
                        // this.dialogRef.close(null);
                        this.questionObject = response.result;
                        if(this.questionObject.json){
                            this.questionObject.json = JSON.parse(this.questionObject.json);
                            this.question = this.questionObject.json;
                        }
                    }
                },
                error: err => {
                    this.showSpinner = false;
                   
                    
                },
                complete: () => {

                }
            });
    }

    deleteQuestion(item, id) {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: "200px",
            data: { Header: 'Headers.DeleteQuestion' }
        })

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.question.splice(this.question.indexOf(item), 1)
                this.question.filter((o, index) => {
                    o.id = index + 1;
                });
            }
        });

    }

    editQuestion(item){
        this.currentObject = new AddQuestion();
        this.currentObject = Object.assign({}, item);
    }
    
    close(){
         this.question =[];
         this.dialogRef.close(null);
    }
}
