import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AuthService } from 'app/core/services/auth.service';
import { ApiConstant } from 'app/core/constant/ApiConstant';
import { Observable } from 'rxjs';
import { RequestEmailSendingCoWorker } from 'app/main/model/RequestBase';

@Injectable()
export class TextEditorEmailSendService {
    private apiUrl: string;
    private coworkerApiUrl: string;
    role: string = this.authService.getUserRole();

    constructor(private http: HttpClient,
        private authService: AuthService) {
        this.apiUrl = ApiConstant.API_URL;
        this.coworkerApiUrl = ApiConstant.API_URL + ApiConstant.COWORKER+"/";
    }

    public GetCustomerEmailTemplate<T>(): Observable<T> {
        var url = this.apiUrl + ApiConstant.REMINDER + "/";
        return this.http.get<T>(url + "getEmailTemplate");
    }

    public GetSupplierEmailTemplate<T>(): Observable<T> {
        var url = this.apiUrl + ApiConstant.REMINDER + "/" + "getSupplierEmailTemplate";
        return this.http.get<T>(url);
    }

    public GetParams(param) {
        let params: HttpParams = new HttpParams();
        for (var key in param) {
            if (param.hasOwnProperty(key)) {
                let val = param[key];
                params = params.append(key, val);
            }
        }
        return params;
    }
}