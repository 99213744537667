//#region Imports 
    // angular 
    import { Component, OnInit, Input, EventEmitter, Output, ViewChild, forwardRef, ChangeDetectorRef, Injector, AfterViewInit } from '@angular/core';
    import { ErrorStateMatcher, MatSelect } from '@angular/material';
    import { ControlValueAccessor, FormControl, FormGroupDirective, NgControl, NgForm, NG_VALUE_ACCESSOR } from '@angular/forms';

    // rxjs
    import { BehaviorSubject } from 'rxjs';

    // third party tool
    import { TranslateService } from '@ngx-translate/core';

    // app-core
    import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';

    // interfaces
    import { locale as english } from './translate/en';
    import { locale as danish } from './translate/ds';
//#endregion Imports 

class CustomFieldErrorMatcher implements ErrorStateMatcher {
    constructor(private customControl: FormControl) { }
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
      return control.dirty && this.customControl.invalid;
    }
}

@Component({
    selector: 'vibe-select-control',
    templateUrl: './vibe-select-control.component.html',
    styleUrls: ['./vibe-select-control.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => VibeSelectControlComponent),
            multi: true
        }
    ]
})
export class VibeSelectControlComponent implements OnInit, AfterViewInit, ControlValueAccessor {
    //#region Initializations

    //#region @Input
    private _DisplayList = new BehaviorSubject<any[]>([]);
    @Input()
    set DisplayList(value) {
        this._DisplayList.next(value);
    };
    get DisplayList() {
        return this._DisplayList.getValue();
    }

    _isLoaded: boolean;
    @Input()
    set IsLoading(value:boolean) {
        this._isLoaded = value;
    }
    get IsLoading() {
        return this._isLoaded;
    }

    _isDisabled: boolean;
    @Input()
    set IsDisabled(value:boolean) {
        this._isDisabled = value;
    }
    get IsDisabled() {
        return this._isDisabled;
    }

    _placeholderTxt: string;
    @Input()
    set PlaceholderTxt(value:string) {
        this._placeholderTxt = value;
    }
    get PlaceholderTxt() {
        return this._placeholderTxt;
    }

    @Input() MultipleSelect: boolean = true;
    @Input() IsSearchable: boolean = true;
    @Input() IsRequired: boolean = false;
    @Input() TransObj: string = 'Default';
    @Input() DisplayVal: string[] = ['name'];
    @Input() ReturnVal: string = 'id';
    @Input() IsTranslate: boolean = false;
    //#endregion @Input

    //#region @Output
    @Output("Search") Search: EventEmitter<string> = new EventEmitter(null);
    //#endregion @Output

    //#region @ViewChild
    @ViewChild('myMatSelect', {static: true}) myMatSelect: MatSelect;
    //#endregion @ViewChild

    //#region Variables
    control: FormControl = new FormControl();
    SearchVal?: string = "";
    currentselectedObj: any = { id: [], name: [] };
    filteredLists: any[] = [];
    selected_val: string = "";
    tempOldValue: string;
    value: any[] = [];
    isClearBtnClick: boolean = false;
    //#endregion Variables

    constructor(
        private translationLoader: FuseTranslationLoaderService,
        private translate: TranslateService,
        private cdr: ChangeDetectorRef,
        public injector: Injector
    ) {
        this.translationLoader.loadTranslations(english, danish);
    }
    //#endregion  Initializations



    //#region Private/Helper Methods
    onChange: any = () => { };
    onTouched: any = () => { };  
    private AnyItemUnselected() : boolean {
        let isAnyUnselected: boolean = false;
        this.filteredLists.forEach(item => {
            var obj = this.currentselectedObj.id.filter(o => o == item.MyReturnVal);
            if (obj.length == 0) {
                isAnyUnselected=true
                return isAnyUnselected;
            }
        });
        return isAnyUnselected;
    }
    private AllOptionReflect() {
        if(this.MultipleSelect) {
            if(this.AnyItemUnselected()) {
                this.currentselectedObj.name = this.currentselectedObj.name.filter(o => o != 0);
                this.currentselectedObj.id = this.currentselectedObj.id.filter(o => o != 0);
            } else {
                this.currentselectedObj.name.unshift(0);
                this.currentselectedObj.id.unshift(0);
                //this.selectAllOptions()
            }
        }
    }
    private selectAllOptions() {
        this.currentselectedObj.name = [];
        this.currentselectedObj.id = [];
        this.currentselectedObj.name.push(0);
        this.currentselectedObj.id.push(0);
        for (var i = 0; i < this.filteredLists.length; i++) {
            this.currentselectedObj.name.push(this.filteredLists[i].MyDisplayName);
            this.currentselectedObj.id.push(this.filteredLists[i].MyReturnVal);
        }
    }
    private bindOldSelectedIDs() {       
        if(this.value.length>0) {
            this.filteredLists.forEach(obj => {
                if(this.value.includes(obj.MyReturnVal)) {
                    if(!this.currentselectedObj.id.includes(obj.MyReturnVal)) {
                        this.currentselectedObj.id.push(obj.MyReturnVal);
                        this.currentselectedObj.name.push(obj.MyDisplayName);
                    }
                }
            })
        }
        this.reflectMatList()
    }
    private reflectMatList() {
        setTimeout(() => {            
            this.currentselectedObj.id = Object.assign([], this.currentselectedObj.id)
            this.currentselectedObj.name = Object.assign([], this.currentselectedObj.name)
            this.currentselectedObj = Object.assign({}, this.currentselectedObj)
        }, 0);
    }
    //#endregion Private/Helper Methods



    //#region Public Methods
    private getTotalOfSelected(): number {
        let totalSelected:number = this.currentselectedObj.id.length | 0
        let isAllIncluded:boolean = this.currentselectedObj.id.includes(0);
        return totalSelected -= isAllIncluded?1:0
    }
    public onPopoverChange($event) {
        if(this.isClearBtnClick) {
            this.isClearBtnClick = false;
            this.clearControlVal()
            this.onChange(null);
            this.myMatSelect.close()
        }
        if($event==false) {
            if (this.tempOldValue!=this.currentselectedObj.id.join()) {                
                if(this.MultipleSelect) {
                    this.onChange(this.currentselectedObj.id.filter(o => o != 0));
                } else {
                    this.onChange(this.currentselectedObj.id[0]);
                }
            }
        } else {
            this.tempOldValue = this.currentselectedObj.id.join();
        }
    }
    public onSelectItem(evt, coworker) {
        if (this.currentselectedObj.id && this.currentselectedObj.id.length > 0 && this.MultipleSelect) {
            this.currentselectedObj.id = this.currentselectedObj.id.filter(o => o != 0);
            this.currentselectedObj.name = this.currentselectedObj.name.filter(o => o != 0);
            var obj = this.currentselectedObj.id.filter(o => o == coworker.MyReturnVal);
            if (obj.length > 0) {
                this.value = this.value.filter(o => o != coworker.MyReturnVal);
                this.currentselectedObj.id = this.currentselectedObj.id.filter(o => o != coworker.MyReturnVal);
                this.currentselectedObj.name = this.currentselectedObj.name.filter(o => o != coworker.MyDisplayName);
            }
            else {
                this.currentselectedObj.id.push(coworker.MyReturnVal);
                this.currentselectedObj.name.push(coworker.MyDisplayName);
            }
        }
        else {
            this.currentselectedObj = { id: [], name: [] };
            this.currentselectedObj.id.push(coworker.MyReturnVal);
            this.currentselectedObj.name.push(coworker.MyDisplayName);
        }

        this.AllOptionReflect()
    }
    public DropDownSearch(value) {
        this.Search.emit(value);
    }
    public _handleKeydown(event: KeyboardEvent) {
        if (event.keyCode === 13) {
            // do not propagate spaces to MatSelect, as this would select the currently active option
            event.stopPropagation();
        }
    }
    public getDataOnClear(value) {
        if (!value) {
            this.DropDownSearch(value);
        }
    }
    public toggleAllSelection() {
        var obj = this.currentselectedObj.id.find(o => o == 0);
        if (obj == undefined || obj == null) {
            this.selectAllOptions();
        } else {
            if(this.SearchVal.trim().length!=0){
                for (var i = 0; i < this.filteredLists.length; i++) {
                    this.value = this.value.filter(o => o != this.filteredLists[i].MyReturnVal);
                    this.currentselectedObj.id = this.currentselectedObj.id.filter(o => o != 0);
                    this.currentselectedObj.name = this.currentselectedObj.name.filter(o => o != 0);
                    this.currentselectedObj.id = this.currentselectedObj.id.filter(o => o != this.filteredLists[i].MyReturnVal);
                    this.currentselectedObj.name = this.currentselectedObj.name.filter(o => o != this.filteredLists[i].MyDisplayName);
                }
            } else {
                this.clearControlVal();     
            }
        }
    }
    public clearControlVal() {
        this.currentselectedObj = [];
        this.currentselectedObj.name = [];
        this.currentselectedObj.id = [];
        this.selected_val = "";
        this.value = [];
    }
    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }   //for ControlValueAccessor
    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }   //for ControlValueAccessor
    public writeValue(value: any) {        
        if(value==null) {
            this.clearControlVal()
            this.onChange(null)
        }
        if(this.MultipleSelect) {
            this.value = Object.assign([], value);
        } else {
            this.selected_val = value;
            this.value = [];
            this.value.push(value)
        }
        this.bindOldSelectedIDs();
        this.AllOptionReflect();
    }   //for ControlValueAccessor 
    //#endregion Public Methods



    //#region Lifecycle Hooks
    ngOnInit() {
        this._DisplayList
            .subscribe(list => {
                if (list && list.length > 0) {
                    if (this.DisplayVal.length > 1) {
                        //this.filteredLists = list.map(item => ({ MyDisplayName: `${item[this.DisplayVal[0]]} - ${item[this.DisplayVal[1]] ? item[this.DisplayVal[1]] : ""}` , MyReturnVal: item[this.ReturnVal] }));
                        this.filteredLists = list.map(item => {
                            let MyDisplayName = this.DisplayVal.map((key, index) => {
                                if (index === 0)
                                    return item[key]+" - ";
                                else
                                    return item[key] ? ' ' +item[key]:'';
                            }).join(''); 

                            if (MyDisplayName.endsWith(' - ')) {
                                MyDisplayName = MyDisplayName.slice(0, -3);
                            }

                            return {
                                MyDisplayName,
                                MyReturnVal: item[this.ReturnVal]
                            };
                        });
                    } else {
                        this.filteredLists = list.map(item => ({ MyDisplayName: `${item[this.DisplayVal[0]]}`, MyReturnVal: item[this.ReturnVal] }));
                    }
                } else {
                    this.filteredLists = Object.assign([]);
                }
                this.bindOldSelectedIDs()
                this.AllOptionReflect()
            });
    }
    ngAfterContentChecked() {
        this.cdr.detectChanges();
    }
    ngAfterViewInit(): void {
        const ngControl: NgControl = this.injector.get(NgControl, null);
        if (ngControl) {
          setTimeout(() => {
            this.control = ngControl.control as FormControl;
          })
        }
    }
    //#endregion Lifecycle Hooks
}
