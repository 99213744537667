export const locale = {
    lang: 'en',
    data: {
        'HEADER': {
            'WALLS': 'Walls',
            'PAINTCELING': 'Ceiling',
            'FLOOR': 'Floor',
        },
        'BUTTONS': {
            'AddExtra': 'Add',
            'RemoveExtra': 'Remove',
            'PDF1': 'Offer',
            'PDF2': 'Offer With Room'
        },
        'FIELDS': {
            'Search': 'Search',
            'Width': 'Width',
            'Length': 'Length',
            'Total': 'm2 in total',
            'Qty': 'Supplement / Deduction Kr.',
            'QtyM2': 'Supplement / Deduction m2',
            'Price': 'Price',
            'PriceTotal': 'Price Total',
            'MaterialPrice': 'Price per m2',
            'GrandTotal': 'Price in all',
            'Rounding': 'Rounding',
            'SupplementDeduction': 'Supplement Deduction',
            'Text': 'Text',
            'Pcs': 'Pcs',
            'Door': 'Doors',
            'PaintCeling': 'Paint Celing',
            'CelingHeight': 'Celing Height',
            'MaterialType': 'Material Type',
            'RoomMaterial': 'Room Material',
            'CeilingMaterial': 'Ceiling Material',
            'Time': 'Number of times',
            'Add': 'Add',
            'RoomDetails': 'Room',
            'Not-Found-MATERIALTYPE': 'Material Type',
            'Change': 'Change',
            'DiscountedAmount': 'Discounted Amount',
            'PaintTime': 'Time',
            'CeilingArea': 'Ceiling area m2',
            'CeilingPriceGrandTotal': 'Ceiling Price',
            'WallPriceGrandTotal': 'Wall Price',
            'Name': 'Name',
            'CeilingPrice': 'Ceiling price per m2',
            'CeilingPriceTotal': 'Ceiling Price Total',
            'WallTotalPrice': 'Wall Total Price',
            'NotCalculatePrice': 'Not Calculate Price',
            'Material':'Materiale',
            'QTY': 'Qty',
            'TreatmentType':'Treatment Type',
        },
        'MESSAGES': {
            'Failed': 'Operation failed , try later',
            'Success': 'Success.',
        },
        'SelectedCategoryTime': {
            'Value1': '1 Time',
            'Value2': '2 Times',
            'Value3': '3 Times',
            'Value4': '4 Times',
            'Value5': '5 Times',
            'Value11': 'Stk',
            'Value12': 'Meter',
            'Value13': 'Rulle',
            'Value14': 'Liter',
        },
        'VALIDATIONS': {
            //Wall Fields
            'WallPrice': 'Should not be null.',
            'CeilingHeight': 'Should not be null.',
            'WallWidth': 'Should not be null.',
            'WallLength': 'Should not be null.',
            'wallExtraQty1': 'Should not be null.',
            'WallExtraQty2': 'Should not be null.',
            'WallExtraPrice1': 'Should not be null.',
            'WallExtraPrice2': 'Should not be null.',
            'WallTotalArea': 'Should not be null.',
            'WallTotalPrice': 'Should not be null.',
            'CeilingPriceTotal': 'Should not be null.',
            'TotalPrice': 'Should not be null.',
            //Ceiling Fields
            'CeilingWidth': 'Should not be null.',
            'CeilingLength': 'Should not be null.',
            'CeilingPrice': 'Should not be null.',
            'CeilingExtraQty1': 'Should not be null.',
            'CeilingExtraQty2': 'Should not be null.',
            'CeilingExtraPrice1': 'Should not be null.',
            'CeilingExtraPrice2': 'Should not be null.',
            'CeilingArea': 'Should not be null.',
            'CeilingTotalPrice': 'Should not be null.',
            'DiscountedAmount': 'Should not be null.'
        },
        'ToolTip': {
            'Start': 'Start',
            'End': 'End',
            'Price': 'Price'
        },
        'SelectedOfferCategory': {
            'Value1': 'Indoor',
            'Value2': 'Outdoor',
            'Value3': 'Window',
            'Value4': 'Doors',
            'Value5': 'Spray booth',
            'Value6': 'Talking',
            'Value7': 'Felt',
            'Value8': 'Tissue',
            'Value9': 'Rutex',
            'Value10': 'Wallpaper',
            'Value11': 'Panels',
            'Value12': 'Door frames',
            'Value13': 'Guarantee',
            'Value14': 'Lift',
            'Value15': 'Spartling',
            'Value16': 'Radiators',
            'Value17': 'Warning',
            'Value18': 'Facades',
            'Value19': 'Shed vg.',
            'Value20': 'Cover',
            'Value21': 'Ceilings',
            'Value22': 'Walls',
            'Value23': 'Floor',     
        },
    }
};
