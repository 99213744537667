import { Component, Input, OnInit } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { MatDialog, MatSlideToggleChange } from '@angular/material';
import { AuthService } from 'app/core/services/auth.service';
import { SettingsService } from '../../settings.service';
import { ResponseBase } from "app/main/model/ResponseBase";
import { Settings, SettingValue } from "app/main/model/Settings";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CoWorker } from "app/main/model/CoWorker";
import { CoWorkersService } from "app/main/pages/coWorker/coWorkers.service";
import { NotificationsService } from "angular2-notifications";
import { TranslateService } from "@ngx-translate/core";
import { SettingEmailTempalteEditorComponent } from "../setting-email-tempalte-editor/setting-email-tempalte-editor.component";
import { CkeditorConfig } from "app/constant/common.constant";

@Component({
    selector: "other-email-template-allmodule-setting",
    templateUrl: "./email-template-allmodule-setting.component.html",
    styleUrls: ["./email-template-allmodule-setting.component.scss"]
})

export class EmailTemplateForAllModuleSettingComponent implements OnInit {
    commonNotificationSetting: Settings;
    showSpinner: boolean = false;
    notificationForm: FormGroup;
    isSubmitted = false;
    notificationsObj: Settings;
    settingMasterValues: any[] = [];
    settingValue: SettingValue = new SettingValue();

    public editorOptions = {
        placeholder: "insert content..."
      };
    config = CkeditorConfig;

    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private settingsService: SettingsService,
        private translate: TranslateService,
        private notificationsService: NotificationsService,
        private dialog: MatDialog) {
        this.translationLoaderService.loadTranslations(danish, english);
    }

    ngOnInit() {
        this.config.height=400;
        this.settingMasterValues = Object.assign([], JSON.parse(localStorage.getItem("settingMasterValues")));
        this.getSendTabSettings();
    }

    getSendTabSettings() {
        this.showSpinner = true;
        this.settingsService.GetSettingsWithTemplateValue<ResponseBase>("Notification", "EmailToCustomer", null).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.result && response.result.myResponse.length > 0) {
                    this.commonNotificationSetting = response.result.myResponse[0];
                    this.setSettings();
                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    onSubmit() {
        this.isSubmitted = true;
        if (this.setSettings) {
            this.changeSettings()
        }
    }

    setSettings() {
        if (this.commonNotificationSetting && this.commonNotificationSetting != null) {
            this.notificationsObj = this.commonNotificationSetting;
            this.settingValue = JSON.parse(this.notificationsObj.value)
            this.changeInLocalStorage(this.notificationsObj.id, this.notificationsObj)
        }
    }

    IsEnableReminder(event: MatSlideToggleChange) {
        this.settingValue.IsEnable = event.checked;
        this.changeSettings();
    }

    setSettingsAfterUpdate() {
        this.settingsService.GetSettingsWithTemplateValue<ResponseBase>("Notification", this.commonNotificationSetting.key2, null).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.result && response.result.myResponse.length > 0) {
                    this.notificationsObj = response.result.myResponse[0];
                    this.settingValue = JSON.parse(this.notificationsObj.value)
                    this.notificationForm.patchValue(this.settingValue);
                    this.notificationForm.controls['CoworkersID'].setValue(this.notificationForm.controls['CoworkersID'].value.split(','))
                    this.changeInLocalStorage(this.notificationsObj.id, this.notificationsObj)
                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    changeSettings() {
        this.showSpinner = true;
        this.notificationsObj.value = JSON.stringify(this.settingValue);
        this.settingsService.UpdateSettingWithTemplate<ResponseBase>(this.notificationsObj).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.result) {
                    this.notificationsObj = response.result;
                    if (this.settingValue.IsEnable)
                        this.setSettingsAfterUpdate();
                    this.translate.get("MESSAGES.Saved").subscribe({
                        next: (res) => {
                            this.notificationsService.create(res);
                        },
                        error: err => { }
                    });
                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinner = false;
                this.isSubmitted = false;
            }
        });
    }

    changeInLocalStorage(ID, new_obj) {
        if (this.settingMasterValues.find(obj => obj.id == ID)) {
            this.settingMasterValues.forEach((old_obj, index) => {
                if (old_obj.id == ID) {
                    this.settingMasterValues[index] = new_obj
                }
            })
        } else {
            this.settingMasterValues.push(new_obj)
        }
        localStorage.setItem("settingMasterValues", JSON.stringify(this.settingMasterValues));
    }

    EmailTemplateDialog() {
        var lableName = "Note.OfferAcceptedEmailTextLabel";
        const dialogRef = this.dialog.open(SettingEmailTempalteEditorComponent, {
            height: '90%',
            width: '90%',
            data: { settingValue: this.settingValue, IsOther: false, IsBday: false, IsSameDay: false, Note: lableName}
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result != null && result != '') {
                this.settingValue = result;
                this.onSubmit();
            }
        });
    }
}