import { Component, OnInit, Inject } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';

import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PlanningDatesArray } from 'app/main/model/WorkHour';
import * as moment from 'moment';

@Component({
    selector: "move-planning-with-date-range",
    templateUrl: "./move-planning-with-date-range.component.html",
    styleUrls: ["./move-planning-with-date-range.component.scss"]
})

export class MovePlanningWithDateRangeComponent implements OnInit {
    Header: string = "";
    PlanningDatesList: PlanningDatesArray[] = [];
    isChecked: boolean = false;
    defaultSelectedDate: any;
    currentDate: any;
    nonWorkingHoursDates: any;
    moveScheduleOption : any;
    isPastDate: boolean = false;
    showOKButton: boolean = false;
    constructor(private translationLoaderService: FuseTranslationLoaderService,
        public dialogRef: MatDialogRef<MovePlanningWithDateRangeComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.translationLoaderService.loadTranslations(danish, english);
    }

    ngOnInit() {
        this.Header = this.data.Header;
        this.defaultSelectedDate = moment(this.data.DefaultSelectedDate).format("YYYY-MM-DD");
        this.nonWorkingHoursDates = this.data.NonWorkingHoursDates;
        this.currentDate = moment(new Date()).format("YYYY-MM-DD");
        this.moveScheduleOption = this.data.MoveScheduleOption;
        if (this.data.PlanningDates) {
            var dates = this.data.PlanningDates.split(',');
            dates.filter(item => {
                var obj = new PlanningDatesArray();
                obj.date = moment(item, "YYYY-MM-DD").format("DD.MM.YYYY");
                if (this.defaultSelectedDate == moment(item).format("YYYY-MM-DD"))
                    obj.isChecked = true;
                if(this.moveScheduleOption == 1){                    
                    if (new Date().setHours(0, 0, 0, 0) == new Date(moment(item).toString()).setHours(0, 0, 0, 0)) {
                        obj.isPastDate = false;
                        obj.isChecked = true;
                        this.PlanningDatesList.push(obj);
                    }
                    else {
                        obj.isPastDate= moment(item, "YYYY-MM-DD").isBefore();
                        if(!obj.isPastDate)
                            obj.isChecked = true;
                        if (!moment(Date.now(), "YYYY-MM-DD").isBefore(moment(item).format("YYYY-MM-DD"))) 
                            this.PlanningDatesList.push(obj);
                    }
                }
                else {
                    if (new Date(moment(this.defaultSelectedDate).toString()).setHours(0, 0, 0, 0) == new Date(moment(item).toString()).setHours(0, 0, 0, 0)) 
                        this.PlanningDatesList.push(obj);
                }
            });
        }
        //if (this.nonWorkingHoursDates) {
        //    var obj = new PlanningDatesArray();
        //    obj.date = moment(this.defaultSelectedDate, "YYYY-MM-DD").format("DD.MM.YYYY");
        //    obj.isChecked = true;
        //    this.PlanningDatesList.push(obj);
        //}
    }

    movePlannedDates() {
        this.PlanningDatesList.forEach(e => {
            e.date = moment(e.date, "DD.MM.YYYY").format("YYYY-MM-DD");
        });
        var Detail = {
            value: "move",
            dateList: this.PlanningDatesList
        }
        this.dialogRef.close(Detail);
    }

    onCloseDialog(e) {
        this.dialogRef.close(e ? true : false)
    }
    getDannisDate(date) {
        return moment(date, "YYYY-MM-DD").format("DD.MM.YYYY")
    }

    copyPlannedProject() {
        this.PlanningDatesList.forEach(e => {
            e.date = moment(e.date, "DD.MM.YYYY").format("YYYY-MM-DD");
        });
        var Detail = {
            value: "copy",
            dateList: this.PlanningDatesList
        }
        this.dialogRef.close(Detail);
    }
}