import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { MatDialog } from '@angular/material';
import { CompanyInfoSetting } from 'app/main/model/CompanyInfo';
import { TextEditorComponent } from 'app/main/pages/text-editor/text-editor.component';
import { ProjectService } from '../../project.service';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { ApiConstant } from 'app/core/constant/ApiConstant';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'vibe-projectMtoM',
  templateUrl: './projectMtoM.component.html',
  styleUrls: ['./projectMtoM.component.scss']
})

export class ProjectMtoMComponent implements OnInit {
  @Input() projectId: string;
  @Input() MtoMDescriptionDetail: string;
  @Output() isDisplayMtoMTabRed = new EventEmitter<boolean>();
  showSpinner: boolean = false;
  MtoMDescription: string = "";
  displayMtoMTabRed: boolean = false;


  constructor(private translate: TranslateService,
    private translationLoader: FuseTranslationLoaderService,
    public dialog: MatDialog,
    private projectService: ProjectService,
    private notificationsService: NotificationsService
  ) {
    this.translationLoader.loadTranslations(english, danish);
  }

  ngOnInit() {
    this.MtoMDescription = this.MtoMDescriptionDetail;
    this.projectId = this.projectId;
    this.getMtoMtabData();
  }

  getMtoMtabData() {
    this.showSpinner = true;
    this.projectService.GetProjectRequisition<any>(this.projectId, ApiConstant.RequisitionMtoM).subscribe({
      next: (response) => {
        this.showSpinner = false;
        this.MtoMDescription = response.result.description;
        if (this.MtoMDescription != null && this.MtoMDescription.trim() != "") {
          this.displayMtoMTabRed = true;
        }
        else {
          this.displayMtoMTabRed = false;
        }
        this.isDisplayMtoMTabRed.emit(this.displayMtoMTabRed);
      },
      error: err => {
        this.translate.get("MESSAGES.Failed").subscribe({
          next: (res) => {
            this.notificationsService.error(res);
          },
          error: err => {
          }
        });
      }
    });
  }

  openProjectRequsitionDescriptionForMtoM() {
    var selectedTabData = new CompanyInfoSetting();
    selectedTabData.Description = this.MtoMDescription;

    const dialogRef = this.dialog.open(TextEditorComponent, {
      height: '90%',
      width: '90%',
      data: { selectedTabData, isEmail: false },
      disableClose: true
    });


    dialogRef.afterClosed().subscribe(result => {
      this.showSpinner = true;
      var data = new CompanyInfoSetting();
      data.Description = "";
      if (result && result.Description) {
        data.Description = result.Description;
      }

      this.projectService.UpdateRequisitionExtraTabDescription<ResponseBase>(this.projectId, ApiConstant.RequisitionMtoM, data)
        .subscribe({
          next: (response: ResponseBase) => {
            this.MtoMDescription = response.result.description;
            this.showSpinner = false;
            if (this.MtoMDescription != null && this.MtoMDescription.trim() != "") {
              this.displayMtoMTabRed = true;
            }
            else {
              this.displayMtoMTabRed = false;
            }
            this.isDisplayMtoMTabRed.emit(this.displayMtoMTabRed);
          },
          error: err => {
            this.showSpinner = false;
            this.translate.get("MESSAGES.Failed").subscribe({
              next: (res) => {
                this.notificationsService.error(res);
              },
              error: err => {
              }
            });
          },
          complete: () => {
            this.showSpinner = false;
          }
        });
    });
  }

}