import { OnInit, Component, Optional, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';

@Component({
    selector: 'vibe-SelectProject',
    templateUrl: './selectProject.Component.html',
    styleUrls: ['./selectProject.Component.scss']
})

export class SelectProjectComponent implements OnInit {
    projectId: any;
    projectNumber: any;

    constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<SelectProjectComponent>,
        private dialog: MatDialog,
        private translate: TranslateService,
        private translationLoader: FuseTranslationLoaderService) {
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
    }

    selectProjectEventHandler(event) {
        this.dialogRef.close({ProjectID: event.sagId, ProjectNumber: event.sagNumber, ProjectAddr: `${event['address1'] +` - `+ event['zipCode'] +` - `+ event['city']}`});
    }
}