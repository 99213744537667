export const locale = {
    lang: 'en',
    data: {
        'FILTERS': {
            'TEXTSEARCH': 'Search By Name,Email,Phone,Address',
        },
        'FIELDS': {
            'Not-Found-Material': 'No material found',
            'Number': 'Sr.',
            'MaterialNo': 'Number',
            'Material': 'Material',
            'MaterialText': 'Material Text',
            'DateonCreate': 'Date on Create',
            'Quantity': 'Quantity',
            'Unit': 'Unit',
            'RetailPrice': 'Retail Price',
            'TotalPrice': 'Total Price',
            'PersonalPrice': 'Personal Price',
            'PurchaseQuantity': 'Purchase Quantity',
            'ScanBarcode': 'Scan barcode',
            'ItemNo': 'Item No',
            'QTY': 'QTY',
            'Total': 'Total',
            'AddedMaterialList': 'Added material list',
            'PriceExclVAT': 'Price excl vat',
            'PriceInclVAT': 'Price incl vat',
            'PersonalTotalPrice': 'Personal Total',
            'ColorCode':'Color code',
            'Discount':'Discount / Debtor Discount',
            'Amount':'Discount excl. VAT',
            'Percentage':'Percentage',
            'StaffPurchase': 'Staff purchase',
        },
        'Headers': {
            'MaterialList': 'Material List',
            'Nomaterialadded': 'No material added',
            'ScanMaterial': 'Scan material',
            'AddMaterial': 'Add material',
            'AddedMaterialList': 'Added material list',
        },
        'BUTTONS': {
            'StaffPurchase': 'Staff purchase',
            'StoredPurchase': 'Store purchase',
            'ScanMaterial': 'Scan material',
            'AddMaterial': 'Add material',
            'Add': 'Add',
            'AddManualMaterial': 'Add manual material',
            'AddScanMaterial': 'Add scan material',
            'Back': 'Back',
            'Finish': 'Finish',
        },
    }
};

