import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';
import { environment } from 'environments/environment';
import { QualityTestService } from 'app/main/shared-component/quality-test/quality-test.service';

@Component({
    selector: 'app-image-gallery',
    templateUrl: './image-gallery.component.html',
    styleUrls: ['./image-gallery.component.scss']
})
export class ImageGalleryComponent implements OnInit {
    displayImageGallery: boolean = false;
    @Input() GalleryImagesLoad: any[];
    galleryOptions: NgxGalleryOptions[]=[];
    galleryImages: NgxGalleryImage[]=[];
    constructor(private _route: ActivatedRoute,
        private translationLoader: FuseTranslationLoaderService,
        private translate: TranslateService,
        private notificationsService: NotificationsService,
        private qualityTestService: QualityTestService) {
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
        this.galleryOptions = [
            {
                width: '95%',
                height: 'calc(100vh - 150px)',
                thumbnailsColumns: 4,
                imageAnimation: NgxGalleryAnimation.Slide,
                previewCloseOnClick: true,
                previewCloseOnEsc: true,
                arrowPrevIcon: 'face',
                arrowNextIcon: 'material-icons face'
            },
            // max-width 800
            {
                breakpoint: 800,
                width: '95%',
                height: 'calc(100vh - 150px)',
                imagePercent: 80,
                thumbnailsPercent: 20,
                thumbnailsMargin: 20,
                thumbnailMargin: 20,
                previewCloseOnClick: true,
                previewCloseOnEsc: true,
                arrowPrevIcon: 'face',
                arrowNextIcon: 'material-icons face'
            },
            // max-width 400
            {
                breakpoint: 400,
                preview: false
            }
        ];
        for (var i = 0; i < this.GalleryImagesLoad.length; i++) {
            var url = environment.url + this.GalleryImagesLoad[i].replace(/\\/g, "/");
            var obj = {
                small: url,
                medium: url,
                big: url,
            }
            this.galleryImages.push(obj);
            this.displayImageGallery = true;
        }
    }

}
