import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../../core/modules/shared.module';
import { DatePipe } from '@angular/common';
import { NgxMaskModule } from 'ngx-mask';
import { QuillEditorModule } from 'ngx-quill-editor';
import { NgxCurrencyModule } from "ngx-currency";
import { CustomCurrencyMaskConfig } from "app/core/constant/CurrencyMaskConfiguartion";
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CKEditorModule } from 'ng2-ckeditor';
import { BarRatingModule } from "ngx-bar-rating";
import { MatBadgeModule } from '@angular/material/badge';
import { CategorySelectComponent } from './category-select/category-select.component';
import { NgxGalleryModule } from 'ngx-gallery';

const routes = [
    {
        path: 'qualitytest',
        component: CategorySelectComponent,
    },
];

@NgModule({
    declarations: [
        CategorySelectComponent,
    ],
    imports: [
        SharedModule,
        RouterModule.forChild(routes),
        NgxMaskModule.forRoot(),
        QuillEditorModule,
        NgxCurrencyModule.forRoot(CustomCurrencyMaskConfig),
        NgxSliderModule,
        CKEditorModule,
        BarRatingModule,
        MatBadgeModule,
        NgxGalleryModule,
    ],
    providers: [
        DatePipe
    ]
})
export class QualityTestModule { }
