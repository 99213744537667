export const locale = {
  lang: 'ds',
  data: {
    'STATUS': {
      'InActive': 'Inaktive',
      'Inside': 'Inde',
      'OutSide': 'Uden for',
      'None': 'Ingen',
    },
    // 'selectedValueStatus': {
    //   'value1': 'Kontaktes',
    //   'value2': 'Planlagt besøg',
    //   'value3': 'Svare ikke',
    //   'value4': 'Tilbud sendt',
    //   'value5': 'Tilbud godkendt',
    //   'value6': 'Planlagt',
    //   'value7': 'Tilbud afvist',
    //   'value8': 'Startet',
    //   'value9': 'Udført',
    //   'value10': 'Faktureret',
    //   'value11': 'Afventer',
    //   'value12': 'Klar fakturering',
    // },
    'selectedValueIndoorOutdoorStatus': {
      'value1': 'Indendørs ',
      'value2': 'Udendørs',
      'value3': 'Sprøjtemaling',
      'value4': 'Reklamation',
      'value5': 'Personale køb', //drawing of painter
      'value6': 'Butiks køb'     //Nordsjø logo
    },
    'FIELDS': {
      'TotalInclVAT': "Total incl. moms"
    },
    'TOOTIPORTITLE':{
      'GoToPDFEmail':'Gå Til PDF/E-mail',
      'GoToQuestions':'Gå Til Spørgsmål',
    }
  }
};
