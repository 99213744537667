export const locale = {
    lang: 'ds',
    data: {
        'Headers': {
            'QuestionList': 'Spørg kunden om årsagen til afvisning:'
        },
        'BUTTONS': {
            'NEWPROJECTOFFER': 'Nyt Sag tilbud',
            'AddQuestion': 'Tilføj spørgsmål',
            'Save': 'Gem',
            'Cancel': 'Gå tilbage'
        },
        'FIELDS': {
            'Question': 'Spørgsmål',
            'SrNo': 'Sr nr.',
            'Type': 'Type',
            'Answer': 'Svar',
            'DragAndDropFile':'Træk og slip filen her',
            'Or':'Eller',
            'BrowseForFile':'Søg efter fil'
        },
        'VALIDATIONS': {
            'QuestionRequired': 'Spørgsmål påkrævet',
            'AnswerRequired': 'Svar påkrævet'
        },
        'MESSAGES': {
            'NoQuestionAnswerFound': 'Ingen Spørgsmål Fundet.',
            'Created': 'Oprettet med succes',
            'Updated': 'Opdateret med succes',
            'Deleted': 'Slettet med succes',
            'Failed': 'Operation mislykket, prøv senere',
            'SelectAnyOption': 'Vælg mindst én værdi til indstillingslisten.',
        },
    }
}