import { Component, OnInit, ViewChild, EventEmitter,Input, ChangeDetectorRef, Output } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import {animate, state, style, transition, trigger} from '@angular/animations';
import {MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
@Component({
    selector: "vibe-coworker-employment",
    templateUrl: "./coworker-employment.component.html",
    styleUrls: ["./coworker-employment.component.scss"],
    animations: [
        trigger('detailExpand', [
            state('collapsed, void', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
            transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
          ])
      ]
})

export class CoworkerEmploymentComponent implements OnInit {
    showSpinner: boolean = false;
    @Input("coworkerEmploymentList") coworkerEmploymentList: any=[]; 
    displayedColumns: string[] = ['index', 'startDate', 'endDate', 'ofSalary', 'basicSalary', 'basicSalaryHours', 'personalSupplement', 'type','meetingAllowance','action'];
    dataSource: MatTableDataSource<any>;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {};
    expandedElements: any[] = [];
    innerDisplayedColumns = ['srno', 'type', 'from', 'to', 'hourlyRate', 'numberofHours', 'weekday', 'minEmployeeMonth', 'maxEmployeeMonth'];
    @Output() deleteEmployment = new EventEmitter();
    @Output() editEmployment = new EventEmitter();
    constructor(private translationLoader: FuseTranslationLoaderService,
        private cd: ChangeDetectorRef) {
        this.translationLoader.loadTranslations(danish, english);
    }

    ngOnInit() {
        this.initDataSource();
    }

    initDataSource(){
      for (let index = 0; index < this.coworkerEmploymentList.length; index++) {
        this.coworkerEmploymentList[index].coworkerRateList = new MatTableDataSource(this.coworkerEmploymentList[index].coworkerRateList);
      }
      this.dataSource = new MatTableDataSource(this.coworkerEmploymentList);
    }

    toggleRow(element: any) {
      element.coworkerRateList &&
      (element.coworkerRateList as MatTableDataSource<any>).data.length
        ? this.toggleElement(element)
        : null;
      this.cd.detectChanges();
    }
    
    isExpanded(row: any): string {
        const index = this.expandedElements.findIndex(x => x.id == row.id);
        if (index !== -1) {
          return 'expanded';
        }
        return 'collapsed';
    }
    
    toggleElement(row: any) {
        const index = this.expandedElements.findIndex(x => x.id == row.id);
        if (index === -1) {
          this.expandedElements.push(row);
        } else {
          this.expandedElements.splice(index, 1);
        }
    }

    edit(item){
      if(item){
        if(item.coworkerRateList)
          item.coworkerRateList=null;
        this.editEmployment.emit(item)
      };
    }

    delete(item) {
      if(item.id)
        this.deleteEmployment.emit(item.id);
    }

}