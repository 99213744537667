export const locale = {
    lang: 'ds',
    data: {
        'TITLE': {
        },
        'FIELDS': {
            "Role": "Rolle",
           
        },
        'MESSAGES': {
            'Saved': 'Gemt succesfuldt.',
        },
        'BUTTONS': {
            'Save': 'Gem',
            'ModifyRoles': 'Rediger roller',
        }
    }
};
