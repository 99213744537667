import { Component, OnInit, ViewChild ,ElementRef } from '@angular/core';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { Router} from '@angular/router';
import { CustomersService } from './customer.service';
import { RequestCustomer } from 'app/main/model/RequestBase';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { AuthService } from 'app/core/services/auth.service';
import { IsNumber } from 'app/core/Validators/validation';
import { TitleService } from 'app/core/services/title.service';
import { ProjectService } from 'app/main/pages/project/project.service';

@Component({
    selector: 'app-customer',
    templateUrl: './customer.component.html',
    styleUrls: ['./customer.component.scss'],
})

export class VibeCustomerComponent implements OnInit {
    @ViewChild("SearchCustomer", { static: false }) searchCustomer: ElementRef;
    auth: any;
    search: any = '';
    number: any = '';
    displayPagination: boolean;
    pagination: any;
    showSpinner: boolean = false;
    requestModel: RequestCustomer = new RequestCustomer();

    //Arrays to dispaly rows in datatable
    Customers: any[]=[];
    useradmin: boolean = false;
    userkontor: boolean = false;
    usermaler: boolean = false;
    usergaest: boolean = false;
    userguest: boolean = false;
    accessgranted: boolean = false;
    savedata: boolean = false;
    isCallOnInitMethod: boolean = true;
    data: any;
    //roleName: string;
    orderBy: string = "Text";
    limitNumber: any;
    //isValid: boolean = false;
    isActive: boolean = true;
    isDeleted: boolean = false;
    settingMasterValues:any[] = [];
    roleClaims: any; 
    //Initiators
    constructor(private translationLoader: FuseTranslationLoaderService,
        private _router: Router,
        private customersService: CustomersService, private authService: AuthService, private titleService: TitleService,
        private projectservice: ProjectService,) {
        this.translationLoader.loadTranslations(english, danish);
        this.titleService.SetTitle("TITLE_CUSTOMERS");
        this.roleClaims = this.authService.getRoleClaimsList();
    }

    ngOnInit() {
        // this.roleName = this.authService.getUserRole();

        // if (this.roleName == "Admin") {
        //     this.isValid = true;
        // }
        // else if (this.roleName == "Kontor") {
        //     this.isValid = true;
        // }
        // else if (this.roleName == "Maler") {
        //     this.isValid = false;
        // }
        // else if (this.roleName == "Gaest") {
        //     this.isValid = false;
        // }
        // else {
        //     this.isValid = true;
        // }
        this.requestModel = this.customersService.getRequestParameter();
        this.isActive = this.requestModel.IsActive;
        this.isDeleted = this.requestModel.IsDeleted;
        this.search = this.requestModel.Name;
        this.orderBy = this.requestModel.OrderBy;
        this.settingMasterValues = Object.assign([], JSON.parse(localStorage.getItem("settingMasterValues")));
        var tempSettingObject = this.settingMasterValues.find(s => s.key1 == "Menu" && s.key2 == "TabsListSettings").value;
        if(tempSettingObject && JSON.parse(tempSettingObject).find(x=>x.name=='customers').isInitialLoading)
            this.GetCustomer();
    }
    
    ngAfterViewInit() {
        setTimeout(() => {
          this.searchCustomer.nativeElement.focus();
        })
    }
    GetCustomer() {
        this.showSpinner = true;
        this.customersService.setRequestParameter(this.requestModel);
        this.customersService.Get<ResponseBase>(this.requestModel)
            .subscribe((response: ResponseBase) => {
                if (response != null) {
                    this.Customers = response.result;
                    if (this.Customers && this.Customers.length > 0) {
                        this.projectservice.setBGImageForProjectCards(this.Customers);
                    }
                    this.pagination = response.pagination;
                    this.displayPagination = true;
                    this.showSpinner = false;
                }
                else {
                    this.isCallOnInitMethod = false;
                    this.Customers = [];
                    this.displayPagination = false;
                }
            },
                error => (err) => {
                    this.isCallOnInitMethod = false;
                    this.Customers = [];
                    this.displayPagination = false;
                    this.showSpinner = false;
                },
                () => {
                    this.showSpinner = false;
                });
    }

    getDeletedCustomers(isDeleted) {
        this.isDeleted = isDeleted;
        this.requestModel.IsDeleted = this.isDeleted;
        this.requestModel.Index = 1;
        this.GetCustomer()
    }

    checkAccess(id) {
        this.EditCustomer(id);
    }

    EditCustomer(id) {
        this._router.navigate(['/customers/edit', id],{ 
            state: { moduleDataList: this.Customers }
          }); //navigationExtras
    }

    receiveFilterEvent(event) {
        this.requestModel.OrderBy = event;
        if (this.limitNumber == undefined) {
            this.requestModel.Limit = 100
        }
        else {
            this.requestModel.Limit = this.limitNumber;
        }
        this.GetCustomer();
    }

    SearchByName(value) {
        if (value || value == "") {
            this.requestModel.Name = value.trim();
            this.requestModel.Index = 1;
            //this.requestModel.IsActive = false;
            this.isActive = this.requestModel.IsActive;
            this.GetCustomer();
        }
    }

    receivePaginatonEvent(event) {
        this.requestModel.Index = event.pageIndex;
        this.requestModel.Limit = event.pageSize;
        this.limitNumber = event.pageSize;
        this.GetCustomer();
    }

    // isValidForm() {
    //     return this.isValid;
    // }
    isNumber(evt) {
        return IsNumber(evt);
    }

    onSetActive(active) {
        this.isActive = active;
        this.requestModel.IsActive = this.isActive;
        this.requestModel.Index = 1;
        if (!active) {
        this.GetCustomer();
        }
    }
}
