import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../../../@fuse/material.module';

import { GoogleMapAddressAutocompleteComponent } from './google-map-address-autocomplete.component';
import { AgmCoreModule } from '@agm/core';
import { AddressConfirmationModalComponent } from './address-confirmation-modal/address-confirmation-modal.component'
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { GoogleMapAddressAutocompleteService } from './google-map-address-autocomplete.service';
import { environment } from '../../../../environments/environment';

@NgModule({
  declarations: [
    GoogleMapAddressAutocompleteComponent,
    AddressConfirmationModalComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    TranslateModule,
    AgmCoreModule.forRoot({
        apiKey: environment.GooogleKey,
      libraries: ["places"]
    })
    ],
    entryComponents: [AddressConfirmationModalComponent],
  exports: [
    GoogleMapAddressAutocompleteComponent,
  ],
  providers: [GoogleMapAddressAutocompleteService]
})

export class GoogleMapAddressModule {
}
