import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { MatSlideToggleChange } from '@angular/material';
import { SettingsService } from '../../settings.service';
import { ResponseBase } from "app/main/model/ResponseBase";
import { Settings } from '../../../../model/Settings';
import { environment } from '../../../../../../environments/environment';
import { ReminderWeekDays } from 'app/main/model/Reminder';

@Component({
    selector: 'vibe-smssend-configuration',
    templateUrl: './smssend-configuration.component.html',
    styleUrls: ['./smssend-configuration.component.scss']
})
export class SMSSendConfigurationComponent implements OnInit {

    @Input("SendSMSSetting") SendSMSSetting: Settings;

    showSpinner: boolean = false;
    smsConfigForm: FormGroup;
    isSubmitted = false;
    sendSMSConfigObj: any = {};
    SendSMSObj: Settings;
    envSMSSendParam = "";
    IsSendSMS: boolean = false;
    weekDays = ReminderWeekDays;

    constructor(
        private translationLoaderService: FuseTranslationLoaderService,
        private fb: FormBuilder,
        private settingsService: SettingsService) {
        this.translationLoaderService.loadTranslations(danish, english);
    }

    ngOnInit() {
        this.smsConfigForm = this.fb.group({
            IsSendSMS: [false],
            IsTimeBased: [true],
            HoursFromSend: [null],
            HoursToSend: [null],
            isExecuteMonday: [false],
            isExecuteTuesday: [false],
            isExecuteWednesday: [false],
            isExecuteThursday: [false],
            isExecuteFriday: [false],
            isExecuteSaturday: [false],
            isExecuteSunday: [false],
        });
        this.envSMSSendParam = environment.SendingNotificationValue2;
        this.setvalidators();
        this.setSettingsForSendSMS();
    }

    onSubmit() {
        this.smsConfigForm.markAllAsTouched();
        this.isSubmitted = true;
        if (this.smsConfigForm.valid) {
            this.saveSMSConfigurationSetting()
        }
    }

    saveSMSConfigurationSetting() {
        this.showSpinner = true;
        let data = {
            'IsSendSMS': this.smsConfigForm.value.IsSendSMS.toString(),
            'IsTimeBased': this.smsConfigForm.value.IsTimeBased.toString(),
            'HoursFromSend': this.smsConfigForm.value.IsTimeBased ? this.smsConfigForm.value.HoursFromSend : null,
            'HoursToSend': this.smsConfigForm.value.IsTimeBased ? this.smsConfigForm.value.HoursToSend : null,
            'isExecuteMonday': this.smsConfigForm.value.isExecuteMonday,
            'isExecuteTuesday': this.smsConfigForm.value.isExecuteTuesday,
            'isExecuteWednesday': this.smsConfigForm.value.isExecuteWednesday,
            'isExecuteThursday': this.smsConfigForm.value.isExecuteThursday,
            'isExecuteFriday': this.smsConfigForm.value.isExecuteFriday,
            'isExecuteSaturday': this.smsConfigForm.value.isExecuteSaturday,
            'isExecuteSunday': this.smsConfigForm.value.isExecuteSunday,
        }
        this.sendSMSConfigObj = JSON.stringify(data)
        this.SendSMSObj.value = this.sendSMSConfigObj;
        this.settingsService.UpdateSettingValue<ResponseBase>(this.SendSMSObj).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.result) {
                    this.sendSMSConfigObj = JSON.parse(response.result.value);
                    this.sendSMSConfigObj.IsSendSMS = JSON.parse(this.sendSMSConfigObj.IsSendSMS);
                    this.sendSMSConfigObj.IsTimeBased = JSON.parse(this.sendSMSConfigObj.IsTimeBased);
                    this.smsConfigForm.patchValue(this.sendSMSConfigObj);
                    this.setValidatorsBasedOnSavedValue();

                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinner = false;
                this.isSubmitted = false;
            }
        });
    }

    changeIsSendSMS(event: MatSlideToggleChange) {
        this.saveSMSConfigurationSetting();
    }

    changeIsTimeBased(event: MatSlideToggleChange) {
        if (event.checked) {
            this.setvalidators()
        }
        else {
            this.clearValidators();
        }
    }

    clearValidators() {
        this.smsConfigForm.get('HoursFromSend').clearValidators();
        this.smsConfigForm.get('HoursFromSend').updateValueAndValidity();
        this.smsConfigForm.get('HoursToSend').clearValidators();
        this.smsConfigForm.get('HoursToSend').updateValueAndValidity();
    }

    setvalidators() {
        this.smsConfigForm.get('HoursFromSend').setValidators([Validators.required, Validators.min(1), Validators.max(24), this.smallerThan('HoursToSend')]);
        this.smsConfigForm.get('HoursFromSend').updateValueAndValidity();
        this.smsConfigForm.get('HoursToSend').setValidators([Validators.required, Validators.min(1), Validators.max(24), this.greaterThan('HoursFromSend')]);
        this.smsConfigForm.get('HoursToSend').updateValueAndValidity();
    }

    setValidatorsBasedOnSavedValue() {
        if (this.sendSMSConfigObj.IsTimeBased) {
            this.setvalidators();
        }
        else {
            this.clearValidators();
        }
    }

    setSettingsForSendSMS() {
        if (this.SendSMSSetting && this.SendSMSSetting != null) {
            this.SendSMSObj = this.SendSMSSetting;
            this.sendSMSConfigObj = JSON.parse(this.SendSMSObj.value);
            this.sendSMSConfigObj.IsSendSMS = JSON.parse(this.sendSMSConfigObj.IsSendSMS);
            this.sendSMSConfigObj.IsTimeBased = JSON.parse(this.sendSMSConfigObj.IsTimeBased);
            this.smsConfigForm.patchValue(this.sendSMSConfigObj);
            this.setValidatorsBasedOnSavedValue();
        }
    }

    smallerThan(otherControlName: string) {
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            if (!control.parent) {
                return null; // Control is not yet associated with a parent.
            }
            var dtnow = new Date()
            var dtCurrnetControl = (dtnow.getMonth() + 1) + "/" + dtnow.getDate() + "/" + dtnow.getFullYear() + " " + control.value;
            var dtOtherControl = (dtnow.getMonth() + 1) + "/" + dtnow.getDate() + "/" + dtnow.getFullYear() + " " + control.parent.get(otherControlName).value;
            if (dtCurrnetControl < dtOtherControl) {
                return null;
            }

            return {
                'smallerthan': true
            }
        };
    }

    greaterThan(otherControlName: string) {
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            if (!control.parent) {
                return null; // Control is not yet associated with a parent.
            }
            var dtnow = new Date()
            var dtCurrnetControl = (dtnow.getMonth() + 1) + "/" + dtnow.getDate() + "/" + dtnow.getFullYear() + " " + control.value;
            var dtOtherControl = (dtnow.getMonth() + 1) + "/" + dtnow.getDate() + "/" + dtnow.getFullYear() + " " + control.parent.get(otherControlName).value;

            if (dtCurrnetControl > dtOtherControl) {
                return null;
            }

            return {
                'greaterthan': true
            }
        };
    }
}
