export const locale = {
  lang: 'en',
  data: {
    'PAYROLL': {
      'TITLE': 'Payroll Entry',
      'DESC': 'Hello Co-worker!'
    },
    'BUTTONS': {
      'NEWCOWORKER': 'New Co-worker',
      'BACK': 'Back',
      'NOTIFY': 'Notify'
    },
    'FILTERS': {
      'SEARCH': 'Search By Name',
      'SORTING': 'Sort By',
      'BADGE': 'Inactive',
      'CREDENTIAL': 'Create Web User'
    },
    'TITLE': {
      'CoworkerPagination': 'Antal medarbejdere pr. side',
    },
    'FIELDS': {
      'Name': 'Name',
      'Initialer': 'Initialer',
      'CoWorkerNumber': 'CoWorker Number',
      'Address': 'Adresse',
      'ZipCode': 'ZipCode',
      'City': 'City',
      'Phone': 'Phone',
      'Email': 'E-mail',
      'InCaseOfAccident': 'In Case Of Accident',
      'CoworkerAs': 'Coworker As',
      'DateOfCoWorkerStart': 'Date Of Co-Worker Start',
      'Position1': 'Position(Nord)',
      'Position2': 'Position(Өst)',
      'Picture': 'Picture',
      'actionsColumn': 'Handling',
      'Mobile': 'Mobile',
      'CONFIRM': 'Confirm',
      'CANCEL': 'Cancel',
      'Active': 'Include All Inactive',
        'Deleted': 'Show Deleted',
        'Date': 'Date',
        'NumberofHours': 'Number of hours',
        'HourlyRate': 'Hourly Rate',
        'TotalAmount': 'Total Amount',
        'Description': 'Beskrivelse',
    },
    'MESSAGES': {
      'NORECORD': 'No Co-worker Found'
    },
    'SORTBY':
      [
        {
          Text: 'Name (A-Z)',
          Value: 'Name'
        },
        {
          Text: 'Name (Z-A)',
          Value: 'Name DESC'
        }
      ]
  }
};
