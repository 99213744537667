export const locale = {
  lang: 'en',
  data: {
    'FILTERS': {
      'TEXTSEARCH': 'Search By Name,Email,Phone,Address',
    },
    'FIELDS': {
      'CustomerName': 'Customer Name',
      'Not-Found-Customer': 'No customer found',
      'Project':'Projects'
    },
    'BUTTONS': {
      'StaffPurchaseCash': 'Staff purchase case',
      'StoredPurchase': 'Store purchase',
      'DailySales': 'Daily sales',
    },
    'Message':{
      'DailySalesMessage':'Set daily sale customer'
    },
    'TOOTIPORTITLE': {
      'DailySale': 'Daily Sales',
    }
  }
};
  