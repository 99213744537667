import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { Component, OnInit, Inject } from '@angular/core';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { TitleService } from 'app/core/services/title.service';
import { AuthService } from 'app/core/services/auth.service';
import { RequestCustomer } from 'app/main/model/RequestBase';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { CustomersService } from 'app/main/pages/customer/customer.service';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';
import { DocumentService } from 'app/main/pages/document/document.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';

@Component({
    selector: 'vibe-default-customer-add',
    templateUrl: './default-customer-add.component.html',
    styleUrls: ['./default-customer-add.component.scss']
})
export class VibeDefaultCustomerAddComponent implements OnInit {
    CustomersList: any = [];
    DefaultCustomerConfiguration: any = {};
    // roleName: string = "";
    // isValid: boolean = false;
    customerId: string = "";
    CustomerName: string = "";
    settings:any = {};
    requestModel: RequestCustomer = new RequestCustomer();
    constructor(private translationLoader: FuseTranslationLoaderService,
        private authService: AuthService, private titleService: TitleService,
        private documentService: DocumentService,
        private customersService: CustomersService, 
        private translate: TranslateService,
        private notificationsService: NotificationsService,
        private dialog: MatDialog,
        public dialogRef: MatDialogRef<VibeDefaultCustomerAddComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.translationLoader.loadTranslations(english, danish);
        this.titleService.SetTitle("TITLE_STORE_SALES");
    }

    ngOnInit() {
        // this.roleName = this.authService.getUserRole();

        // if (this.roleName == "Admin") {
        //     this.isValid = true;
        // }
        // else if (this.roleName == "Kontor") {
        //     this.isValid = true;
        // }
        // else if (this.roleName == "Maler") {
        //     this.isValid = false;
        // }
        // else if (this.roleName == "Gaest") {
        //     this.isValid = false;
        // }
        // else {
        //     this.isValid = true;
        // }
        this.CustomersList = this.data.customerList;
        this.settings = this.data.settings;
        this.customerId = this.settings.value;
    }

    DropDownSearchCustomer(name) {
        if (name != "" || name == undefined) {
            this.requestModel.Name = this.CustomerName.trim();
            this.GetCustomer();
        }
    }

    GetCustomer() {
        this.customersService.Get<ResponseBase>(this.requestModel)
            .subscribe((response: ResponseBase) => {
                if (response != null) {
                    //this.Customers = [];
                    this.CustomersList = response.result;
                }
                else {
                    this.CustomersList = [];
                }
            },
                error => (err) => {
                    this.CustomersList = [];
                },
                () => {
                });
    }

    selectDefaultCustomer() {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: '200px',
            data: { Header: 'Headers.ChangeDailySalesCustomer' }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.settings.value = this.customerId;
                this.documentService.UpdateSettingValuue<ResponseBase>(this.settings.id, this.settings)
                    .subscribe((response: ResponseBase) => {
                        if (response) {
                            this.translate.get("MESSAGES.Success").subscribe({
                                next: (res) => {
                                    this.notificationsService.success(res);
                                },
                                error: err => {
                                }
                            });
                            this.dialogRef.close(true)
                        }
                    },
                        error => (err) => {
                        },
                        () => {
                        });
            }
        });
        
    }
    onChangeCustomer(event) {
    }
}


