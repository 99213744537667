export class Project {
    public sagNumber: number;
    public customerName: string = '';
    public customerId: string = '';
    public sagId: number = 0;
    //public SagOpgaveID: number = 0;
    public customerNumber: string = '';
    public address1: string = '';
    public address2: string = '';
    public city: string = '';
    public zipCode: string = '';
    public comment: string = '';
    public requisition: string = '';
    public startDate: any;
    public endDate: any;
    public initialer: string = '';
    public size: string = '';
    public statusId: number = 0;
    public indoorOutdoorStatusIds: any[] = [];
    public indoorOutdoorStatusId: string = null;
    public varNummer: string = '';
    public Picture: string = '';
    public fileAssignmentId: string = '';
    public status: string = '';
    public phone: string = '';
    public email: string = '';
    public urgentOrNot: number;
    public offerNumber: string;
    public strictlySpeaking: string;
    //public FileData: string = '';

    // public Files: any = [];
    public includePrice?: number;
    public excludePrice: number = 0;
    public vat?: number;
    public estimatedPrice: number = 0;

    public isActive?: boolean = true;
    public IsExist?: boolean = false;
    public comingFrom: number;
    public isDeleted: boolean;
    public visit: any;
    public coworkerSeenOnPainting: string;
    public StatusLogCaseValue: number;
    public createdBy: string;
    public received: any;
    public colorcode: string;

    public startTime: any;
    public endTime: any;
    public visitTime: any;
    public receivedTime: any;
    public Lift: any;
    public Stigemand: any;
    public inquiryId: string;
    public inquiryNumber: string;
    public isDocumentMoveToFinish: boolean;
    public isPlanning: boolean;
    public offerID: string;
    public countryCode: string;
    public doNotInvoice: boolean;
    constructor() { }
}

export class ProjectFile {
    public id?: string;
    public category?: string;
    public projectId?: string;
    public fileId?: string;
    public name?: string;
    public path?: string;
    public date?: Date;
}

export class WorkingHourDetails {
    public totalProject: number = 0;
    public totalProjectRemainingHours: number = 0;
    public totalProjectMaterialPrice: number = 0;
    public totalProjectDaysLeft: number = 0;
    public lastDayWorkingHour: number = 0;
    public noOfEmployee: number = 0;
    public endDate: string;
    public materialPurchasePriseTotal: number = 0;
    public projectEndDate: string;
    public averagePrice: number = 0;
    public totalRemainigPrice: number = 0;
}

export class ProjectObjForExcell {
    public val1: string;
    public val2: string;
    public val3: string;
    public val4: string;
    public val5: string;
    public val6: string;
}


export class projectCoWorker {

    public projectCretedBy: string;
    public id: string;
    public projectId: number;
    public coworkerId: string;
    public fileAssignmentId: string;
    public coworkerList: any;
    public ProjectNummer: number;
    public ProjectRequisitionNummer: number;
    public requisition: string;
    public AddAllRequisition: boolean = false;
}

export class ProjectMaterial {   // CaseTaskDTO in web api
    public id?: string;
    public sagOpgaveId: string = null;
    public itemNumber: string = null;
    public quantity: number = 0.00;
    public materialId: string = null;
    public text: string = null;
    public retailPrice: number;
    public personalPris: number;
    public retailPriceTotal: number;
    public personalPriceTotal: number;
    public totalPriceVAT: number;
    public barcode: string;
    public price: number;
    public personalPrisTotal: number;
    public totalPrice: number;
    public colorCode: string = "";
    public ProjectRequisitionStatusLogModel: ProjectRequisitionStatusLog
}

export class ProjectMaterialQuantity {
    public qty: string;
    public quantity: number;
    public qtyDecima: number = 0.00;
    public text: string = '';
    public retailPrice: number;
    public personalPris: number;
    public colorCode: string = "";
}


export class projectRequisition {
    public projectId?: string;
    public id?: string;
    public requisition: string; // Requisitionnummer
    public comment: string;
    public opgaveText: string = '';
    public invoiceText: string = '';
    public startDate: any;
    public endDate: any;
    public responsibleCoworker?: projectCoWorker;
    public size?: number;
    public isActive: boolean;
    public isNegativeTotalProfit: boolean;
    public isReactiveProjectAndTask: boolean = false;

    // new fields
    public departmentNumber?: string;
    public departmentName?: string;
    public departmentCity?: string;
    public departmentZipCode?: string;
    public departmentAddress?: string;
    public departmentPhone?: string;

    // pricing
    public excludePrice: number = 0;
    public estimatedPrice: number;
    public vatPrice: number = 0;
    public includePrice: number = 0;
    public vatEstimatePrice: number = 0;
    public includeEstimatePrice: number = 0;
    public totalIncludeEstimatePrice: number = 0;
    public totalVatEstimatePrice: number = 0;

    // special info
    public left?: boolean;
    public leftDays?: number;
    public riseman?: boolean;
    public risemanDays?: number;

    // status
    public status?: number;
    public comingDate?: string;
    public estimatedTime?: number;
    public coworkerId?: string;
    public numberOfDays?: number;

    //just for web expansion panel
    public IsExpand: boolean = false;
    public CreatedBy: string;
    public cretedByName: string;

    public reasonForProjectDropped: number;
    public coworkerSeenOnPainting: string;
    public coworkerSeenOnPaintingName: string;

    public showStatisticEditData: boolean = false;
    public securityOfficerID: string = null;
    public employeeResponsibleID: string = null;
    public number: number = 0;
    public totalWorkingTime: number = 0;
    public StatusLogCaseValue: number;
    public pickupLift: boolean;
    public deliveryLift: boolean;
    public liftStartDate: any;
    public liftEndDate: any;
    public doNotUpdateHour: string;
    public donotUpdateWorkHour: boolean;
    public isHourlyWage: boolean = false;
    public hourlyWage: number = 0;
    public suggestedHours: number = 0;
    public hoursLeft: number = 0;
    public reqTabselectedIndex: number = 0;
    public paymentMode: number = 0;
    public discountPercent: number = 0.00;
    public discount: number = 0.00;
    public performedPercent: number = 0.00;
    public noOfWorkflows: number = 0;

    //Planning Date
    public statusChangedDate: any;
    // public hourlyRate: number = 0;
    // public isCaseOnHourly: boolean = false;
    public visitCoworkerIds: any[] = [];
    public visitCoworkerId: string = null;
    public m2Ceiling: number = 0.00;
    public m2Walls: number = 0.00;
    public billed: any;
    public plannedWorkHour: number = 0;
    public hoursBack: number = 0;
    public projectEstimatedTime: number = 0;
    public projectTotalWorkingTime: number = 0;
    public projectPlannedWorkHour: number = 0;
    public materialCreation: number = 0.00;
    public coworkerWorkingRate: number = 0.00;
}

export class emailParams {
    public ProjectId: string;
    public EmailBody: string
}

export class customerEmailParams {
    public CustomerId: string = '';
    public EmailBody: string
}

export class ProjectRequisitionStatusLog {

    //project requisition status log
    public id: string;
    public projectId: string;
    public requisitionId: string;
    public requisitionNumber: number;
    public status: number;
    public createdBy: string;
    public date: any;
    public time: any;
    public statusFrom: number;
    public StatusLogCaseValue: number;
    public debitorID: string;
    public medarbejdarID: string;
}


export enum ProjectRequisitionStatusLogEnum {
    StatusUpdated = 1,
    CoworkerDeleted = 2,
    StatickDataUpdated = 3,
    PricingDataChanged = 4,
    CoworkerAdded = 5,
    MaterialAdded = 6,
    MaterialUpdated = 7,
    MaterialDeleted = 8,
    EmailSentFromProject = 9,
    EmailSentFromCustomer = 10
};

export class ProjectBasedNotification {
    public tabDetailName: string = '';
    public fileName: string = '';
    public isAdded: boolean = false;
    public isUpdated: boolean = false;
    public isDeleted: boolean = false;
};

// export class ProjectQuestionDTO {
//     public id: string;
//     public question: string = "";
//     public answer: string = "";
//     public questionType: string = "";
//     public choiceOptions: string = "";
// }

export class QuestionParms {
    public id: string = "";
    public question: string = "";
    public status: number;
    public message: string = "";
    public statusQuestion: ProjectQuestionDTO[] = [];
    public audienceType: number;
    public indoorOutdoorStatusIds: any[] = [];
    public indoorOutdoorStatusId: string = null;
}

export class RequisitionStatusAnswer {
    public id: string = "";
    public projectId: any;
    public requisitionId: string = "";
    public json: string = "";
    public lastStatus: number;
    public newStatus: number;
    public audienceType: number;
    public indoorOutdoorStatusId: string = null;
}

export class ProjectQuestionDTO {
    public id: string;
    public question: string;
    public answer: string = "";
    public isDeleted: boolean = false;
    public isActive: boolean = true;
    public createdBy: string = '';
    public createdDate: Date = new Date();
    public modifyBy: string = '';
    public modifyDate: Date = new Date();
    public questionType: string = "Textbox";
    public choiceOptions: any;// = [];
    public answerList: [] = [];
    public rating?: number = 5;
    public isTextboxAvai?: boolean = false;
    public isTextboxAvaiVal?: string = "";
}
export class ProjectHoursDetail {
    public estimatedTime: number = 0;
    public totalWorkingTime: number = 0;
    public hoursBack: number = 0;
    public plannedWorkHour: number = 0;
}
export const QuestionTypeCategory: any[] = [
    { id: 'Textbox', key: 'QuestionTypeCategory.value1' },
    { id: 'MultiChoice', key: 'QuestionTypeCategory.value2' },
    { id: 'SingleChoice', key: 'QuestionTypeCategory.value3' },
    { id: 'Rating', key: 'QuestionTypeCategory.value4' },
];

export const projectStatistic: any[] = [
    {
        lable: "",
        data: [0],
        status: 0,
    }
]

export const offerStatistic: any[] = [
    {
        year: '',
        name: "",
        status: 0,
        value: 0.0,
        change: 0.0,
    }
]

export class ProjectHours {
    public id: string = "";
    public projectNumber: number;
    public projectAddress: string;
    public totalProjectHours: number;
    public totalProjectWorkingHours: number;
    public totalProjectRemainingHours: number;
    public indendoersUdendoersStatistID: string;
    public status: string;
    public indoorOutdoorStatus: string = "";
    public isHourlyWage: boolean = true;
}

export class WorkingHourDetailsSettingRequest extends WorkingHourDetails {
    public multipleStatus: string = "";
    public purchaseStatus: string = "";
    public autoCalculation : boolean = true;
}