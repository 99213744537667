import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatOption, MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { RequestStatusProject } from 'app/main/model/RequestBase';
import { WhereDoesTheInquiryComeFrom, ProjectIndoorOutDoorStatus } from 'app/main/model/ProjectStatus';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { CoWorker } from "app/main/model/CoWorker";
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { WorkhoursService } from 'app/main/pages/workHour/workhours.service';
import { InquiryService } from 'app/main/pages/inquiry/inquiry.service';
import { AuthService } from 'app/core/services/auth.service';
import { ShiftType } from 'app/main/model/ProjectStatus';
import { WorkHourStatus, WorkHoursApproveLeaveType } from 'app/main/model/WorkHourStatus';
import { WorkHour, CoworkerDays } from 'app/main/model/WorkHour';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';


@Component({
    selector: 'vibe-calender-inquiry-working-hour-popup',
    templateUrl: './inquiry-working-hour-popup.component.html',
    styleUrls: ['./inquiry-working-hour-popup.component.scss']
})
export class CalenderInquiryWorkingHourPopupComponent implements OnInit {
    inquiryObj: any = {};
    inquiryInfo: any = {};
    startWeek: number = 1;
    coWorkers: any = [];
    projectCoWorkers: any = [];
    coWorkersFullData: any = [];
    showSpinner = false;
    createEventObj: any = {};
    shiftType = ShiftType;
    selectStatus = WorkHourStatus;
    selectLeaveType = WorkHoursApproveLeaveType;
    projectList: any = [];
    projectFullList: any = [];
    workHour = new WorkHour();
    currentselectedCoworkerObj: any = [];
    projectCoworkerObj: any = [];
    hours: any;
    mins: any;
    isAddBreakTime: boolean = true;
    isDisableWorkType = false;
    weekNumbers: any = [];
    isDisplayErrorMessage: boolean = false;
    isComingFromEditMode: boolean = false;
    workHourNotAddedOnCoworker: string = "";
    workHourId: string = "";
    // roleName: string;
    isFeatureDate: boolean = false;
    isPastDate: boolean = false;
    CoWorkerName: string = "";
    holidayList: any;
    tempEnddate: any;
    isDelete: boolean = false;
    whereDoesTheInquiryComeFrom = WhereDoesTheInquiryComeFrom;
    selectIndoorOutDoorStatus = ProjectIndoorOutDoorStatus;
    coWorkerList: CoWorker[] = new Array<CoWorker>();
    searchCoWorkerList: CoWorker[] = new Array<CoWorker>();
    selectedCoWorkerList: CoWorker[] = new Array<CoWorker>();
    currentselectedCoworkerIds: any = [];
    totalWorkHourOfCoworker: number;

    @ViewChild('allSelected', { static: false }) private allSelected: MatOption;
    roleClaims: any;
    //hasPermision: boolean = false;
    hasLeaveApprovePermision: boolean = false;
    //Initiators
    constructor(private translationLoader: FuseTranslationLoaderService,
        private _router: Router,
        private translate: TranslateService,
        private inquiryService: InquiryService,
        private notificationsService: NotificationsService,
        private workhourservice: WorkhoursService,
        private authService: AuthService,
        private datePipe: DatePipe,
        private dialog: MatDialog,
        public dialogRef: MatDialogRef<CalenderInquiryWorkingHourPopupComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.translationLoader.loadTranslations(english, danish);
        this.roleClaims = this.authService.getRoleClaimsList();
    }

    ngOnInit() {
        //this.roleName = this.authService.getUserRole();
        this.authService.hasClaim(this.roleClaims.Planning_Leave_Approve).subscribe(hasClaim => {
            if (hasClaim) 
                this.hasLeaveApprovePermision = hasClaim
             else 
                this.hasLeaveApprovePermision = false;
        });

        this.isComingFromEditMode = this.data.isComingFromEditMode;
        this.inquiryInfo = this.data.inquiryInfo;
        this.GetInquiry(this.inquiryInfo.inquiryId);
        this.holidayList = JSON.parse(localStorage.getItem("HolidayList"));
        this.coWorkerList = this.data.coworkerList;
        this.searchCoWorkerList = this.coWorkerList;
        this.createEventObj.coworkerId = this.inquiryInfo.coworkerId
        if (this.inquiryInfo.otherInfo) {
            var customDateObj = JSON.parse(this.inquiryInfo.otherInfo);
            this.createEventObj.startDate = customDateObj.StartDate;
            this.createEventObj.endDate = customDateObj.EndDate;
        }
        else {
            this.createEventObj.startDate = (this.inquiryInfo.startDate ? this.inquiryInfo.startDate : new Date());
            this.createEventObj.endDate = (this.inquiryInfo.endDate ? this.inquiryInfo.endDate : new Date());
        }
        if (new Date().setHours(0, 0, 0, 0) == new Date(this.createEventObj.startDate).setHours(0, 0, 0, 0)) {
            this.isPastDate = false;
        }
        else {
            this.isPastDate = moment(this.createEventObj.startDate, "YYYY-MM-DD").isBefore();
        }
        this.createEventObj.shift = this.inquiryInfo.shiftType;
        this.createEventObj.status = this.inquiryInfo.status;
        //this.createEventObj.leaveStatus = this.inquiryInfo.leaveStatus;
        this.createEventObj.comment = this.inquiryInfo.comment;
        this.workHourId = this.inquiryInfo.workHourId;
        this.createEventObj.text = this.inquiryInfo.text;
        if (this.isComingFromEditMode) {
            if (!this.inquiryInfo.otherInfo) {
                this.createEventObj.endDate = (this.inquiryInfo.endDate ? this.inquiryInfo.endDate : new Date());
            }
            this.currentselectedCoworkerIds = [this.inquiryInfo.coworkerId];
        }
        else {
            
            if (this.hasLeaveApprovePermision)
                this.createEventObj.leaveStatus = 2;
            else {
                this.createEventObj.leaveStatus = 1;
                this.currentselectedCoworkerObj = [this.authService.getCoWorkerId()];
                this.createEventObj.coworkerId = this.authService.getCoWorkerId();
            }
        }
        this.workHour.fromStartHour = this.inquiryInfo.startHour;
        this.workHour.fromStartMinute = this.inquiryInfo.startMinute;
        this.workHour.fromEndHour = this.inquiryInfo.endHour;
        this.workHour.fromEndMinute = this.inquiryInfo.endMinute;
        this.isAddBreakTime = this.inquiryInfo.isPauseTime;
        this.coWorkers = this.data.coworkerList;
        this.coWorkersFullData = this.data.coworkerList;
        this.createEventObj.startWeekNumber = moment(this.createEventObj.startDate).week().toString();
        this.hours = [{ value: '00' }, { value: '01' }, { value: '02' }, { value: '03' }, { value: '04' }, { value: '05' }, { value: '06' }, { value: '07' }, { value: '08' },
        { value: '09' }, { value: '10' }, { value: '11' }, { value: '12' }, { value: '13' }, { value: '14' }, { value: '15' }, { value: '16' }, { value: '17' },
        { value: '18' }, { value: '19' }, { value: '20' }, { value: '21' }, { value: '22' }, { value: '23' }];
        this.mins = [{ value: '00' }, { value: '15' }, { value: '30' }, { value: '45' }]
    }

    GetInquiry(id) {
        this.showSpinner = true;
        this.inquiryService.GetSingle<ResponseBase>(id)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.showSpinner = false;
                    this.inquiryObj = response.result;
                    if (!this.inquiryObj.received) {
                        this.inquiryObj.received = new Date();
                    }
                    if (this.inquiryObj.received) {
                        this.inquiryObj.receivedTime = moment.utc(this.inquiryObj.received).format("HH:mm") == "00:00" ? "" : moment.utc(this.inquiryObj.received).format("HH:mm");
                        this.inquiryObj.received = moment.utc(this.inquiryObj.received).format("YYYY-MM-DD");
                    }

                    this.setIndoorOutDoorDropDown()
                },
                error: err => {
                    this.showSpinner = false;
                },
                complete: () => {

                }
            });
    }
    DropDownSearchCoWorker(value) {
        this.searchCoWorkerList = this.coWorkersFullData.filter(o => o.name.toLowerCase().includes(value.toLowerCase()));
    }
    getCoWorkerOnSearch(value) {
        if (!value) {
            this.DropDownSearchCoWorker(value)
        }
    }

    DropDownCoWorkerSelect(id) {
        //this.createEventObj.coworkerId = this.currentselectedCoworkerObj.join().toString();
        //this.DropDownSearchCoWorker('');
        var alreadySelectedCoworker = this.selectedCoWorkerList.find(o => o.id == id);
        if (alreadySelectedCoworker)
            this.selectedCoWorkerList = this.selectedCoWorkerList.filter(o => o.id != id);
        else {
            var findCoworker = this.coWorkerList.find(o => o.id == id);
            if (findCoworker)
                this.selectedCoWorkerList.push(findCoworker);
        }
        this.DropDownSearchCoWorker('');
        this.setScheduleDaysByCowrker();
    }

    DropDownSearchProject(value) {
        var model = new RequestStatusProject();
        model.Index = 1;
        model.Name = value;
        this.coWorkers = this.projectFullList.filter(it => {
            return it.name.toLowerCase().includes(model.Name.toLowerCase());
        });
    }

    onFocusIn(event) {
        event.model.show();
    }

    saveTimer() {
        this.workHour.id = this.workHourId;
        var newAddedWorkerId = this.selectedCoWorkerList.map(o => o.id);
        this.workHour.workerId = newAddedWorkerId.join().toString();
        this.workHour.isWorking = true;
        this.workHour.shiftType = this.createEventObj.shift;
        this.workHour.status = this.createEventObj.status;
        this.workHour.text = this.createEventObj.text;
        this.workHour.leaveStatus = this.createEventObj.leaveStatus;
        this.workHour.comment = this.createEventObj.comment;
        this.workHour.otherInfo = this.inquiryObj.otherInfo;
        this.workHour.inquiryId = this.inquiryInfo.inquiryId;
        if (this.createEventObj.shift == 2) {
            this.workHour.from = "07:00";
            this.workHour.to = "14:30";
            this.workHour.Tid = 7.5;
            this.workHour.pauseTimer = 0.5;
            this.workHour.Hours = "7";
        }
        else if (this.createEventObj.shift == 3) {
            this.workHour.from = "07:00";
            this.workHour.to = "15:00";
            this.workHour.Tid = 8.00;
            this.workHour.pauseTimer = 0.5;
            this.workHour.Hours = "07.5";
        }
        else if (this.createEventObj.shift == 4) {
            this.workHour.from = this.workHour.fromStartHour + ":" + this.workHour.fromStartMinute;
            this.workHour.to = this.workHour.fromEndHour + ":" + this.workHour.fromEndMinute;
            var time = moment.utc(moment(this.workHour.to, "HH:mm").diff(moment(this.workHour.from, "HH:mm"))).format("HH:mm")
            var tidTime = moment(time, "HH:mm").format("mm");
            var tidHours = moment(time, "HH:mm").format("HH");
            if (tidTime != "00")
                time = tidHours + ":" + (Number(this.getMinute(tidTime))).toString();
            this.workHour.Tid = Number(time.replace(':', '.'));
            if (this.isAddBreakTime)
                this.workHour.pauseTimer = 0.5;
            else
                this.workHour.pauseTimer = 0;
            this.workHour.Hours = time.replace(':', '.');
        }
        var startDate = moment(moment(this.createEventObj.startDate).format('YYYY/MM/DD'));
        var endDate = moment(moment(this.createEventObj.endDate).format('YYYY/MM/DD'));
        this.workHour.days = Math.abs(startDate.diff(endDate, 'days'));
        this.workHour.date = this.datePipe.transform(this.createEventObj.startDate, "MM/dd/yyyy");
        var projectCoworkerObj = this.projectCoworkerObj;
        var newAddedCowkerList = this.currentselectedCoworkerIds.filter(function (item) {
            return !projectCoworkerObj.includes(item);
        })
        this.workHour.newAddedCoworkerIds = newAddedCowkerList ? newAddedCowkerList.join().toString() : "";
        if (this.workHour.id)
            this.updateWorkhourAPICall(this.workHour);
        else
            this.addWorkhourAPICall(this.workHour);
    }

    deleteTimer() {
        var message = "";
        var message1 = "";
        var message2 = "";
        var message3 = "";
        var startDate = moment(this.createEventObj.startDate).format('DD.MM.YYYY');
        var endDate = moment(this.createEventObj.endDate).format('DD.MM.YYYY');
        var deleteDates =[moment(this.createEventObj.startDate).format("YYYY-MM-DD")];

        this.translate.get("MESSAGES.DeleteNonWorkingHoursOne").subscribe({
            next: (res) => {
                message1 = res;  //+ 'den' + '' + ' ' + startDate + ' ' + 'til den' + ' ' + endDate;
            },
            error: err => {
            }
        });
        this.translate.get("MESSAGES.DeleteNonWorkingHoursTwo").subscribe({
            next: (res) => {
                message2 = res;
            },
            error: err => {
            }
        });
        this.translate.get("MESSAGES.DeleteNonWorkingHoursThree").subscribe({
            next: (res) => {
                message3 = res;
            },
            error: err => {
            }
        });
        message = message1 + ' ' + message2 + ' ' + startDate + ' ' + message3 + ' ' + endDate;

        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            width: '500px',
            data: { Header: message }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.isDelete = result;
                var deleteNonWorkingItems = {
                    id: this.workHourId,
                    deleteDates: deleteDates,
                    coWorkerID: this.createEventObj.coworkerId
                }
                this.dialogRef.close({
                    updateSchedule: false,
                    deleteSchedule: true,
                    goToProject: false,
                    inquiryId: this.inquiryObj.sagId,
                    workHourId: this.workHourId,
                    deleteNonWorkingItems: deleteNonWorkingItems
                });
            }
        });


    }

    goToProject() {
        this.dialogRef.close({
            updateSchedule: false,
            deleteSchedule: false,
            goToProject: true,
            inquiryId: this.inquiryObj.sagId,
            workHourId: this.workHourId,
        });
    }

    addWorkhourAPICall(workHour) {
        this.showSpinner = true;
        this.workhourservice.AddWithDateRange<ResponseBase>(workHour)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.translate.get("MESSAGES.Add").subscribe({
                        next: (res) => {
                            this.notificationsService.success(res);
                            this.dialogRef.close({
                                updateSchedule: true,
                                deleteSchedule: false,
                                goToProject: false,
                                inquiryId: this.inquiryObj.sagId,
                                workHourId: this.workHourId,
                                weekNumber: moment(this.createEventObj.startDate).week().toString(),
                            });
                        },
                        error: err => {
                        }
                    });
                },
                error: err => {
                    this.workHourNotAddedOnCoworker = err.error.error.result.coworkerName;
                    this.isDisplayErrorMessage = true;
                    this.translate.get("MESSAGES.Some_Coworker_Add_Error").subscribe({
                        next: (res) => {
                            this.notificationsService.warn(res);
                        },
                        error: err => {
                        }
                    });
                },
                complete: () => {
                    this.showSpinner = false;
                }
            });
    }

    updateWorkhourAPICall(workHour) {
        this.showSpinner = true;
        this.workhourservice.InquiryUpdateWithDateRange<ResponseBase>(workHour)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.translate.get("MESSAGES.Add").subscribe({
                        next: (res) => {
                            this.notificationsService.success(res);
                            this.dialogRef.close({
                                updateSchedule: true,
                                deleteSchedule: false,
                                goToProject: false,
                                inquiryId: this.inquiryObj.sagId,
                                workHourId: this.workHourId,
                                weekNumber: moment(this.createEventObj.startDate).week().toString(),
                            });
                        },
                        error: err => {
                        }
                    });
                },
                error: err => {
                    this.workHourNotAddedOnCoworker = err.error.error.result.coworkerName;
                    this.isDisplayErrorMessage = true;
                    this.translate.get("MESSAGES.Some_Coworker_Add_Error").subscribe({
                        next: (res) => {
                            this.notificationsService.warn(res);
                        },
                        error: err => {
                        }
                    });
                },
                complete: () => {
                this.showSpinner = false;
                }
            });
    }

    onChange(args) {
        if (args.value) {
            if (args.value.getDay() == 0 || args.value.getDay() == 6) {
                var days = args.value.getDay() == 0 ? 1 : 2;
                this.createEventObj.startDate = new Date(args.value.setDate(args.value.getDate() + days));
            }
            else
                this.createEventObj.startDate = args.value;
            this.createEventObj.endDate = args.value;
            if (new Date().setHours(0, 0, 0, 0) == new Date(this.createEventObj.startDate).setHours(0, 0, 0, 0)) {
                this.isPastDate = false;
            }
            else {
                this.isPastDate = moment(this.createEventObj.startDate, "YYYY-MM-DD").isBefore();
            }
        }
    }

    onChangeEndDate(args) {
        this.createEventObj.endDate = args.value;
        this.createEventObj.startDate = args.value;
        //this.tempEnddate = args.value;
        this.calEndDate();
    }

    calEndDate() {
        this.workHour.nonWorkingDayList = [];
        //var endDate = this.tempEnddate;
        var endDate = new Date(new Date(this.createEventObj.endDate).getFullYear(),
            new Date(this.createEventObj.endDate).getMonth(), new Date(this.createEventObj.endDate).getDate(), 0, 0, 0, 0);

        var startDateCal = new Date(new Date(this.createEventObj.startDate).getFullYear(),
            new Date(this.createEventObj.startDate).getMonth(), new Date(this.createEventObj.startDate).getDate(), 0, 0, 0, 0);

        for (let day = startDateCal; day <= endDate; day.setDate(day.getDate() + 1)) {
            var tempDate = moment(day).format("DD.MM.YYYY");
            var getHoliday = this.holidayList.find(o => moment(o.date).format("DD.MM.YYYY") == tempDate);
            if (day.getDay() == 0 || day.getDay() == 6) {
                continue;
            }
            else if (!getHoliday || getHoliday.length == 0) {
                this.workHour.nonWorkingDayList.push(moment(day).format("YYYY-MM-DD"));
            }
            //  else if (getHoliday || getHoliday.length > 0) {
            //     endDate = new Date(endDate.setDate(endDate.getDate() + 1)); //new Date(new Date(endDate).setDate(new Date(endDate).getDate() + 1));
            // }
        }
        this.createEventObj.endDate = endDate;
        //this.createEventObj.endDate = moment(new Date(endDate)).format("DD.MM.YYYY");
    }

    getMinute(value) {
        if (value == "00")
            return "00";
        if (value == "15")
            return "25";
        if (value == "30")
            return "50";
        if (value == "45")
            return "75";
    }

    CheckEndHour(checkMinute) {
        this.workHour.fromEndHour = (this.workHour.fromEndHour < this.workHour.fromStartHour) == true ? (this.workHour.fromEndHour = this.workHour.fromStartHour) : this.workHour.fromEndHour;
    }

    addWeeksNumber() {
        this.weekNumbers = [];
        for (var i = 1; i < 54; i++) {
            this.weekNumbers.push({ value: i.toString() });
        }
    }

    setIndoorOutDoorDropDown() {
        if (this.inquiryObj.indoorOutdoorStatusId) {
            this.inquiryObj.indoorOutdoorStatusIds = new Array();
            var SplitIndoorOutdoorStatusId = this.inquiryObj.indoorOutdoorStatusId.split(',');
            SplitIndoorOutdoorStatusId.filter(y => {
                this.inquiryObj.indoorOutdoorStatusIds.push(parseInt(y));
            })
        }
    }

    selectedCoWorkerDelete(id) {
        this.selectedCoWorkerList = this.selectedCoWorkerList.filter(o => o.id != id);
        this.currentselectedCoworkerIds = this.selectedCoWorkerList.map(o => o.id);
    }

    setScheduleDaysByCowrker() {
        this.workHour.CoworkerDetailsId = [];
        for (var i = 0; i < this.currentselectedCoworkerIds.length; i++) {
            var CoworkerDetails = new CoworkerDays();
            CoworkerDetails.CoworkerId = this.currentselectedCoworkerIds[i];
            CoworkerDetails.ScheduleDays = this.workHour.nonWorkingDayList;
            this.workHour.CoworkerDetailsId.push(CoworkerDetails);
        }
    }

    _handleKeydown(event: KeyboardEvent) {
        if (event.keyCode === 13) {
            event.stopPropagation();
        }
    }
}
