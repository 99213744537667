export const locale = {
  lang: 'en',
  data: {
    'Headers': {
      'QuestionList': 'Ask customer the reason of offer rejection:'
    },
    
    'MESSAGES': {
      'Created': 'Created successfully',
      'Updated': 'Updated successfully',
      'Deleted': 'Deleted successfully',
      'Failed': 'Operation failed , try later',
      'NoQuestionAnswerFound': 'No Question Found.',
      'AddTypeList': 'Add at least one value to the type list.'
    },
    'VALIDATIONS': {
      'QuestionRequired': 'Question required'
    },
    'QuestionTypeCategory': {
      'value1': 'Textbox',
      'value2': 'Multi Choice',
      'value3': 'Single Choice',
    }
  }
};
