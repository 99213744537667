export const locale = {
    lang: 'en',
    data: {
        'FIELDS': {
            'startDate': 'Start Date ',
            'visit': 'Visit',
            'CoWorkers': 'Co-Workers',
            'All': 'All',
            'Not-Found-Worker': 'Co-worker not found',
            'Co-workerlist': 'Co-worker List',
            'DepartmentPhone': 'Phone',
        },
        'BUTTONS': {
            'BACK': 'Back',
            'SAVE': 'Save',
            'UNDO': 'Undo',
            'Delete': 'Delete',
        },
        'Lable': {
            'Name': 'Name',
        }
    }
};