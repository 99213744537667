export const locale = {
  lang: 'ds',
  data: {
    'Tabs': {
      'PROJECT': 'Projekter',
      'PROJECTOFFER': 'Tilbud',
    },
    'BUTTONS': {
      'NEWSUPPLIER': 'Ny leverandører',
      'CreateNewSupplier': 'Opret ny leverandører',
      'BACK': 'Tilbage',
        'UploadSupplierList': 'Upload leverandørliste',
        'UpdateFromUniconta':'Update From Uniconta',
    },
    'MESSAGES': {
        'NORECORD': 'Ingen leverandører fundet',
        'Updated': 'Updated Successfully',
    },
    'TITLE': {
      'supplierPagination': 'Antal leverandører pr. side',
    },
    'FILTERS': {
      'TEXTSEARCH': 'Søg efter navn, e-mail, telefon, adresse',
      'SORTING': 'Sorter efter',
    },
    'SORTBY':
      [
        {
          Text: 'Navn (A-Z)',
          Value: 'Text'
        },
        {
          Text: 'Navn (Z-A)',
          Value: 'Text DESC'
        }
      ]
  }
};
