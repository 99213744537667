export const locale = {
    lang: 'ds',
    data: {
        'FIELDS': {
            'Designation': 'Betegnelse',
            'ValideFrom': 'Gyldig Fra',
            'SRNO': 'Sr nr.'
        },
        'BUTTONS': {
            'Save': 'Gem',
            'BACK': 'Tilbage',
            'Reset': 'Nulstil'
        },
        'MESSAGES': {
            'Updated': 'Opdateret succesfuldt.',
            'Failed': 'Mislykkedes',
            'Deleted': 'Slettet med succes',
            'NORECORD': 'Intet optage fundet',
            'Created': 'Oprettet med succes',
        },
        'Validation': {
            'DesignationRequired': 'Betegnelse er påkrævet',
            'ValideFromRequired': 'Gyldig fra dato er påkrævet',
            'HourlyRateRequired': 'Pris er påkrævet',
            'WeekdayRequired': 'Ugedag er påkrævet',
            'TypeRequired': 'Type er påkrævet',
            'WhiteSpaceNotAllowed':'Kun hvidt mellemrum er ikke tilladt',
            'FromRequired':'Fra kl. er påkrævet',
            'ToRequired':'Til kl. er påkrævet',
            'MinEmployeeMonthRequired':'Fra antal er påkrævet',
            'MaxEmployeeMonthRequired':'Til antal er påkrævet',
            'MaximumHoursRequired':'Max timer er påkrævet',
            'MaxLeaveCountDaysRequired':'Max. antal dage er påkrævet',
            'WorkingHoursRequired':'Normal timer er påkrævet',
        },
        'Headers': {
            'Title':'Ansættelsesvilkår',
            'DeleteMessage': 'Er du sikker på, at du vil slette dette',
            'Rules':'Satser',
        },
        'Lable': {
            'SRNO': 'Sr nr.',
            'Type': 'Type',
            'From': 'Fra kl.',
            'To': 'Til kl.',
            'HourlyRate': 'Time løn',
            'Numberofhours': 'Antal Timer',
            'Weekday': 'Ugedag',
            'MinEmployeeMonth': 'Fra antal',
            'MaxEmployeeMonth': 'Til antal',
            'MD': 'dage.',
            'MaximumHours': 'Max timer',
            'MaxLeaveCountDays': 'Max. antal dage',
            'MaxLeaveCountDaysKey1': 'Max orlov',
            'MaxLeaveCountDaysKey2': 'tæller dage',
            'WorkingHours':'Normal timer',
            'WorkingShift':'Arbejdstid',
            'Number': 'Antal',
            'Hours': 'Timer',
            'Working':'Normal',
            'CalculatedAsExtra':'Begenes som afspadsering',
            'Calculated':'Beregnes',
            'As':'som ',
            'Extra':'afspadserin'

        },
        'ratesType': {
            'valueA': 'A Timeløn',
            'valueB': 'B Timeløn Overarbejde 1',
            'valueC': 'C Timeløn Overarbejde 2',
            'valueJ': 'J Sygeløn pr. time',

            'valueK': 'Ferie',
            'valueL': 'Feriefridag',
            'valueM': 'Fridag m.løn',
            'valueN': 'Fridag u.løn',
            'valueO': 'Kursus/skole',
            'valueP': 'Andet',
            'valueQ': 'Tilbud',
            'valueR': 'Afspadsering',
            'valueH': 'Hellidgage',
        },
        'FILTERS': {
            'All': 'Alle',
        },
        'selectedValueWeekDays': {
            'value1': 'Mandag',
            'value2': 'Trisdag',
            'value4': 'Onsdag',
            'value8': 'Torsdag',
            'value16': 'Fredag',
            'value32': 'lørdag',
            'value64': 'Søndag'
          },
        'TOOTIPORTITLE': {
            'Edit': 'Redigere',
            'Delete': 'Slet',
            'From':'Skal være udfyld med tidspunkt feks. 07:00 betyder at denne lønsats starter kl. 07:00',
            'To':'Skal være udfyld med tidspunkt feks. 15:00 betyder at denne lønsats starter kl. 15:00',
            'MinEmployeeMonth': 'Fra antal dage feks. sygedage 56 Medarbejder skal have været ansat i 56 dage før der udb. løn',
            'MaxEmployeeMonth': 'Skal være 99999 ved ikke hvorfor',
            'MaximumHours': 'Max. Timer feks Overarbejde 2,00 = overarbejde',
            'MaxLeaveCountDays': 'Max. antal dage feks. sygedom=28 betyder max.28 sygedag med løn(Sammenhængende)',
            'IsWorkingShift':'Denne indstilling kontrollerede for regulært skift og ikke markeret for Over Time shift.',
            'WorkingHours':'Denne indstilling har markeret for almindelige timer og ikke markeret for Over tid.',
            'IsCalculatedAsExtra':'Denne indstilling kontrolleres i timevis, går til banken og er ikke markeret til betaling.',
        }
    }
};