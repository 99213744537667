import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../../../core/modules/shared.module';

import { NgxMaskModule } from 'ngx-mask';
import { VibeCustomerComponent } from './customer.component';
import { DeleteAlertModalComponent } from '../delete-alert-modal/delete-alert-modal.component';
import { NewCustomerComponent } from './new-customer/new-customer.component';
import { CustomerLogsComponent } from './component/customerLogs/customerLogs.component';
//import { AgmCoreModule } from '@agm/core';
import { CustomerDetailComponent } from './component/cutomerDetailModal/customerDetail.component'
import { CustomersService } from './customer.service';
import { CustomCurrencyMaskConfig } from 'app/core/constant/CurrencyMaskConfiguartion';
import { NgxCurrencyModule } from 'ngx-currency';
import { CustomerRoomsComponent } from './component/customerRooms/customerRooms.component';
import { CustomerPropertiesComponent } from './component/customerProperties/customerProperties.component';
import { CustomerPropertyListComponent } from './component/customerProperties/list/customerPropertyList.component';
import { CustomerPropertyManageComponent } from './component/customerProperties/manage/customerPropertyManage.component';
import { CustomerMaterialsComponent } from './component/customerMaterials/customerMaterials.component';
import { CustomerOfferComponent } from './component/customer-offer/customer-offer.component';
import { CustomerUploadComponent } from './component/customer-upload/customer-upload.component';
import { ProjectRecuringSettingsComponent } from './component/project-recuring-setting/project-recuring-setting.component';
import { DatePickerModule, DateTimePickerModule } from '@syncfusion/ej2-angular-calendars';

const routes = [
    {
        path: 'customers',
        component: VibeCustomerComponent,
        //resolve: {
        //  formsData: CustomersService
        //}
    },
    {
        path: 'customers/add',
        component: NewCustomerComponent,
        data: { IsAdd: true }
    },
    {
        path: 'customers/inquiry/:id',
        component: NewCustomerComponent,
        data: { IsInquiryAdd: true }
    },
    {
        path: 'customers/edit/:id',
        component: NewCustomerComponent,
        data: { IsAdd: false }
    },
    {
        path:'customer/upload',
        component: CustomerUploadComponent
    }
];

@NgModule({
    declarations: [
        VibeCustomerComponent,
        NewCustomerComponent,
        CustomerLogsComponent,
        CustomerDetailComponent,
        CustomerRoomsComponent,
        CustomerPropertiesComponent,
        CustomerPropertyListComponent,
        CustomerPropertyManageComponent,
        CustomerMaterialsComponent,
        CustomerOfferComponent,
        CustomerUploadComponent,
        ProjectRecuringSettingsComponent
    ],
    imports: [
        SharedModule,
        RouterModule.forChild(routes),
        NgxMaskModule.forRoot(),
        NgxCurrencyModule.forRoot(CustomCurrencyMaskConfig),
        DatePickerModule,
        DateTimePickerModule,
        //AgmCoreModule.forRoot({
        //    apiKey: 'AIzaSyB2LPLAZRlRAHndaGDzsuXpBrUdzivMqYQ',
        //    libraries: ["places"]
        //})
    ],
    entryComponents: [CustomerDetailComponent, DeleteAlertModalComponent],
    exports: [
        VibeCustomerComponent,
        NewCustomerComponent,
        CustomerLogsComponent,
        CustomerRoomsComponent,
        CustomerPropertiesComponent,
        CustomerPropertyListComponent,
        CustomerPropertyManageComponent,
        CustomerOfferComponent,
        CustomerUploadComponent,
        ProjectRecuringSettingsComponent
    ],
    providers: [
        CustomersService
    ]
})


export class VibeCustomerModule {
}
