export const locale = {
  lang: 'ds',
  data: {
    'FIELDS': {
      'CustomerName': 'Kundenavn',
      'Not-Found-Customer': 'Ingen kunde fundet',
      'EnterCustomer': 'Indtast Kundens navn',//'Vælg kunde',
      'ScanMaterial': 'Scan materiale',
      'Invoice':'Generer faktura',
    },
    'MESSAGES': {
      'InvoiceGenerated': 'Faktura er lavet'
    }
  }
};
