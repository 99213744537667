import { Component, OnInit, Input } from "@angular/core";
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { MatDialog } from '@angular/material';

import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { RequestMaterialSupplierLogs, RequestBaseFull } from 'app/main/model/RequestBase';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { MaterialService } from '../../material.service';
import { ProjectService } from 'app/main/pages/project/project.service';
import { environment } from 'environments/environment';

@Component({
    selector: 'material-log',
    templateUrl: './material-log.component.html',
    styleUrls: ["./material-log.component.scss"]
})

export class MaterialLogComponent implements OnInit {
    showSpinner = false;
    requestModel: any;
    materialSupplierLogData: any = [];
    displayPagination: boolean;
    pagination: any;
    limitNumber: any;
    constructor(private translationLoaderService: FuseTranslationLoaderService,
        public dialog: MatDialog,
        private materialService: MaterialService,
        private projectservice: ProjectService) {
        this.translationLoaderService.loadTranslations(danish, english);
    }

    ngOnInit() {
        this.requestModel = new RequestBaseFull();
        this.requestModel.Index = 1;
        this.requestModel.Limit = 100;
        this.requestModel.OrderBy = "ModifyDate DESC";
        this.getMaterialSupplierLogs();
    }

    getMaterialSupplierLogs() {
        this.showSpinner = true;
        this.materialService.GetMaterialSupplierLogs<ResponseBase>(this.requestModel)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.showSpinner = false;
                    if (response != null && response.result) {
                        this.materialSupplierLogData = response.result;
                        this.pagination = response.pagination;
                        this.displayPagination = true;
                    }
                    else {
                        this.materialSupplierLogData = [];
                        this.displayPagination = false;
                    }
                },
                error: err => {
                    this.showSpinner = false;
                    this.materialSupplierLogData = [];
                    this.displayPagination = false;
                },
                complete: () => { }
            });
    }

    viewEmailDetail(id) {
        this.projectservice.GetProjectEmailLogDetails<ResponseBase>(id)
            .subscribe({
                next: (response: ResponseBase) => {
                    var url = environment.url + response.result.filePath
                    window.open(url, "_blank");
                },
                error: err => {
                    this.showSpinner = false;
                    this.displayPagination = false;
                },
                complete: () => { }
            });
    }

    receivePaginatonEvent(event) {
        this.requestModel.Index = event.pageIndex;
        this.requestModel.Limit = event.pageSize;
        this.getMaterialSupplierLogs();
    }
}