import { Component, OnInit, Inject } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';

import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: "requisition-status-model",
    templateUrl: "./requisition-status-model.component.html",
    styleUrls: ["./requisition-status-model.component.scss"]
})

export class RequisitionStatusModelComponent implements OnInit {
    Header: string = "";
    status: number = 0;
    requisitionList = [];
    Requisition: any;
    constructor(private translateService: FuseTranslationLoaderService,
        public dialogRef: MatDialogRef<RequisitionStatusModelComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.translateService.loadTranslations(danish, english);
    }

    ngOnInit() {
        this.Header = this.data.Header;
        this.status = this.data.status;
        this.Requisition = this.data.Requisition
        this.requisitionList = this.data.requisitionList.map(x => ({ ...x, isChecked: false }));
        for (let i = 0; i < this.requisitionList.length; i++) {
            if (this.requisitionList[i].id == this.Requisition.id) {
                this.requisitionList[i].isChecked = true;
            }
        }
    }

    onCloseDialog(flag) {
        if (flag) {
            setTimeout(() => {
                var data = this.requisitionList;
                this.dialogRef.close({ list: data });
            }, 100);
        } else {
            this.dialogRef.close(flag);
        }
    }


}