import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PdfViewerModelComponent } from './pdf-viewer.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer'; 
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '@fuse/material.module';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
        TranslateModule,
        PdfJsViewerModule
  ],
    exports: [PdfViewerModelComponent],
    declarations: [PdfViewerModelComponent]
})
export class PdfViewerModelModule { }
