import { Component, OnInit, ViewChild } from '@angular/core';
import { MatOption, MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { ProjectService } from './project.service';
import { CoWorkersService } from './../coWorker/coWorkers.service';
import { yesOrNoOptions, ProjectIndoorOutDoorStatus } from 'app/main/model/ProjectStatus';
import { ProjectStatus, ProjectStatusForMaler, PurchaseStatus } from 'app/constant/common.constant';
import { RequestCoWorker, RequestStatusProject } from 'app/main/model/RequestBase';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { WorkingHourDetails } from 'app/main/model/Project';
import { AuthService } from 'app/core/services/auth.service';
import { IsNumber } from 'app/core/Validators/validation';
import { TitleService } from 'app/core/services/title.service';
import * as moment from 'moment';
import { CoWorkerComponent } from './component/coWorker/coWorker.component';
import { StatusMessageComponent } from './component/statusMessage/statusMessage.component';
import { ProjectPriceDiscountComponent } from './component/projectPriceDiscount/project-price-discount.component';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { ProjectHoursDetails } from "app/main/pages/project/component/project-hours-details/project-hours-details";
@Component({
    selector: 'vibe-project',
    templateUrl: './project.component.html',
    styleUrls: ['./project.component.scss']
})


export class VibeProjectComponent implements OnInit {
    //Variable Diclarartion
    public startWeek: number = 1;
    workingHourDetails = new WorkingHourDetails();
    Projects: any[];
    pagination: any;
    savedata: boolean = false;
    accessgranted: boolean = false;
    data: any;
    limitNumber: any;
    displayPagination: boolean = false;
    showSpinner: boolean = false;
    //Initiators
    roleName: string;
    orderBy: string = "Number DESC";
    status: string = "";
    isActive: boolean = true;
    isDeleted: boolean = false;
    isShow60DaysOld: boolean = false;
    selectedCoworkerId: string = "";
    showCoworkerSearch: boolean = true;
    coWokers: any = [];
    @ViewChild('allSelected', { static: false }) private allSelected: MatOption;
    @ViewChild('allStatusSelected', { static: false }) private allStatusSelected: MatOption;
    @ViewChild('allIndoorOutdoorStatusSelected', { static: false }) private allIndoorOutdoorStatusSelected: MatOption;
    @ViewChild('externalPdfViewer', { static: false }) public externalPdfViewer;
    currentselectedcoWorkerObj: any = {};
    currentselectedStatusObj: number[];
    currentselectedIndoorOutdoorObj: number[];
    projectStatus = ProjectStatus;
    projectStatusForMaler = ProjectStatusForMaler;
    After5DayDate: string = "";
    TodayDate: string = "";
    requestProject = new RequestStatusProject();
    yesOrNoOptions = yesOrNoOptions;
    selectIndoorOutDoorStatus = ProjectIndoorOutDoorStatus;
    Befor60DaysDate: string = "";
    currentDate: any;
    CoWorkerName: string = "";
    requestProjectStartDate: string = null;
    purchaseStatus = PurchaseStatus;
    projectPurchaseStatus: number = 3;
    settingMasterValues:any[] = [];
    ownProjectView: boolean = false;
    requestProjectMonth: string = "";    
    roleClaims: any;

    constructor(private translationLoader: FuseTranslationLoaderService,
        private projectservice: ProjectService,
        private coWorkersService: CoWorkersService,
        private _router: Router,
        private authService: AuthService,
        private titleService: TitleService,
        private translate: TranslateService,
        private notificationsService: NotificationsService,
        public dialog: MatDialog,
        private datePipe: DatePipe) {

        //super("Project", EventEmitterEnum.OnLoadMainContentWelcomPage);
        this.titleService.SetTitle("TITLE_PROJECTS")
        this.translationLoader.loadTranslations(english, danish);
        this.roleClaims = this.authService.getRoleClaimsList();
        this.settingMasterValues = Object.assign([], JSON.parse(localStorage.getItem("settingMasterValues")));
        this.roleName = this.authService.getUserRole();
        this.requestProject = this.projectservice.getRequestParameter();
        this.requestProjectMonth = this.projectservice.getRequestParameterForMonth();
        
        const tempSettingViewModeObject = this.settingMasterValues.find(s => s.key1 === "ViewMode");
        if (tempSettingViewModeObject && !this.requestProject.viewMode) {
            const valueArray = JSON.parse(tempSettingViewModeObject.value);
            if (valueArray.length > 0) {
                const firstItem = valueArray[0];
                if (firstItem.Key === "Project") {
                    if (firstItem.Value === "IsList")
                        this._router.navigate(['/projectslist']);
                }
            }
        }

        if(this.requestProject.viewMode && this.requestProject.viewMode == "List")
            this._router.navigate(['/projectslist']);
    }

    ngOnInit() {
        //super.RegisterEventEmitterForPage();
        this.authService.hasClaim(this.roleClaims.Own_Project_View).subscribe(hasClaim => {
            if (hasClaim) 
                this.ownProjectView = hasClaim
             else 
                this.ownProjectView = false;
        });
        this.currentDate = moment(new Date()).format("YYYY-MM-DD");
        // this.roleName = this.authService.getUserRole();
        // this.requestProject = this.projectservice.getRequestParameter();
        //this.requestProjectMonth = this.projectservice.getRequestParameterForMonth();
        this.requestProject.isLift = false;
        this.requestProject.isRiseman = false;
        this.requestProject.CoworkerId = "";
        this.requestProject.startDate = null;
        //this.requestProject.startDate = null;
        this.isActive = true;
        this.isDeleted = false;
        this.requestProject.IsActive = true;
        this.requestProject.IsDeleted = false;
        //this.search = this.requestProject.Address;
        this.requestProject.Address = "";
        this.currentselectedStatusObj = [];
        if (this.requestProject.multipleStatus) {
            var statusObj = this.requestProject.multipleStatus.split(",");
            for (var i = 0; i < statusObj.length; i++) {
                this.currentselectedStatusObj.push(Number(statusObj[i]));
            }
        }

        this.currentselectedIndoorOutdoorObj = [];
        if (this.requestProject.isIndoorOutdoorTypeProjects) {
            var obj = this.requestProject.isIndoorOutdoorTypeProjects.split(",");
            for (var i = 0; i < obj.length; i++) {
                this.currentselectedIndoorOutdoorObj.push(Number(obj[i]));
            }
        }
        this.orderBy = this.requestProject.OrderBy;
        if (this.ownProjectView) {
            this.requestProject.CoworkerId = this.authService.getCoWorkerId();
            this.currentselectedcoWorkerObj.id = [];
            this.currentselectedcoWorkerObj.id.push(this.requestProject.CoworkerId);
        }
        if (this.requestProject.before60DayDate) {
            this.isShow60DaysOld = true;
        }
        var tempSettingObject = this.settingMasterValues.find(s => s.key1 == "Menu" && s.key2 == "TabsListSettings").value;
        if(tempSettingObject && JSON.parse(tempSettingObject).find(x=>x.name=='projects').isInitialLoading)
            this.GetProjects(this.requestProject);
        let coWorker = new RequestCoWorker();
        coWorker.IsDeleted = false;
        if (this.ownProjectView) {
            var tempList = this.coWorkersService.getResponseForCoworker();
            if (tempList.length == 0)
                this.getCoWorkers(coWorker);
            else {
                if(tempList.filter(x => x.id == this.requestProject.CoworkerId).length==0)
                    this.getCoWorkers(coWorker);
                else
                    this.coWokers = tempList;//.filter(x => x.doNotShowOnPlanReminderProj == false);
            }
        }
        this.TodayDate = moment(new Date()).format('YYYY-MM-DD');
        this.After5DayDate = moment(this.TodayDate, "YYYY-MM-DD").add(5, 'days').format('YYYY-MM-DD');
        this.Befor60DaysDate = moment(this.TodayDate, "YYYY-MM-DD").add(-60, 'days').format('YYYY-MM-DD');
    }

    //get Project list
    GetProjects(model) {
        this.showSpinner = true;
        //this.search = this.requestProject.Address;
        this.projectservice.setRequestParameter(model);
        this.projectservice.Get<ResponseBase>(model)
            .subscribe((response: ResponseBase) => {
                if (response != null) {
                    this.Projects = [];
                    this.Projects = response.result;
                    if (this.Projects && this.Projects.length > 0) {
                        this.projectservice.setBGImageForProjectCards(this.Projects);
                    }
                    else {
                        this.Projects = [];
                        if (this.requestProject.Address) {
                            this.translate.get("MESSAGES.NORECORDONSTATUS").subscribe({
                                next: (res) => {
                                    this.notificationsService.warn(res);
                                },
                                error: err => {
                                }
                            });
                        }
                    }
                    this.pagination = response.pagination;
                    this.displayPagination = true;
                    this.showSpinner = false;
                    for (var i = 0; i < this.Projects.length; i++) {
                        var formateDate = moment(this.Projects[i].startDate).format('YYYY-MM-DD');
                        if (this.After5DayDate >= formateDate)
                            this.Projects[i].displayStartDateRed = true;
                        else
                            this.Projects[i].displayStartDateRed = false;
                    }
                }
                else {
                    this.Projects = [];
                    this.displayPagination = false;
                    this.showSpinner = false;
                }
            },
                error => (err) => {
                    this.Projects = [];
                    this.displayPagination = false;
                    this.showSpinner = false;
                },
                () => {
                });
    }

    getDeletedProjects(isDeleted) {
        this.isDeleted = isDeleted;
        this.requestProject.IsDeleted = isDeleted;
        this.GetProjects(this.requestProject);
    }
    getBefore60DayProject() {
        if (this.isShow60DaysOld) {
            this.requestProject.before60DayDate = this.Befor60DaysDate;
        }
        else {
            this.requestProject.before60DayDate = null;
        }
        this.GetProjects(this.requestProject);
        //For IndoorOutdoor select
        this.currentselectedIndoorOutdoorObj = [];
        this.requestProject.isIndoorOutdoorTypeProjects = "";
        for (var i = 0; i < 7; i++) {
            this.currentselectedIndoorOutdoorObj.push(i);
        }
        this.requestProject.isIndoorOutdoorTypeProjects = this.currentselectedIndoorOutdoorObj.join().toString();
        //For Status select
        this.currentselectedStatusObj = [];
        this.requestProject.multipleStatus = "";
        if (this.roleName == "Maler") {
            for (var i = 0; i < 13; i++) {
                if (i != 9 && i != 12) {
                    this.currentselectedStatusObj.push(i);
                }
            }
        }
        else {
            for (var i = 0; i < 13; i++) {
                this.currentselectedStatusObj.push(i);
            }
        }
        this.requestProject.multipleStatus = this.currentselectedStatusObj.join().toString();
    }

    //Pagination related methods
    receivePaginatonEvent(event) {
        if (this.orderBy != undefined)
            this.requestProject.OrderBy = this.orderBy;
        this.requestProject.Index = event.pageIndex;
        this.requestProject.Limit = event.pageSize;
        //this.requestProject.Address = this.search;
        this.GetProjects(this.requestProject);
    }

    //for search
    DropDownSearchCoWorker(value) {
        var model = new RequestStatusProject();
        model.Index = 1;
        model.Name = value;
        this.getCoWorkers(model);
    }

    DropDownSearchCoWorkerOnClear(CoWorkerName) {
        if (!CoWorkerName) {
            this.getCoWorkers(new RequestStatusProject());
        }
    }

    DropDownStatusChange(value) {
            var offerStatusSelected = this.currentselectedStatusObj.find(o => o == 4);
            if (offerStatusSelected) {
                this.requestProject.IsActive = false;
                this.isActive = false;
            }
            if (value.id == 4 && !offerStatusSelected) {
                this.requestProject.IsActive = true;
                this.isActive = true;
            }
            if(value.id == 10){
                this.isActive = false;
                this.requestProject.IsActive = false;
            }
            if (this.currentselectedStatusObj.length <= 0) {
                this.selectedCoworkerId = "";
                this.status = "";
            }
            else {
                this.status = this.currentselectedStatusObj.join();
            }
            this.requestProject.multipleStatus = this.status;
            this.GetProjects(this.requestProject);
    }

    SearchBynumber() {
        //this.isActive = false;
        this.requestProject.IsActive = this.isActive;
        this.isShow60DaysOld = true
        this.requestProject.before60DayDate = this.Befor60DaysDate;
        this.requestProject.Address = this.requestProject.Address.trim();
        //this.GetProjectsForStatus(this.requestProject);

        //For IndoorOutdoor select
        this.currentselectedIndoorOutdoorObj = [];
        this.requestProject.isIndoorOutdoorTypeProjects = "";
        for (var i = 0; i < 7; i++) {
            this.currentselectedIndoorOutdoorObj.push(i);
        }
        this.requestProject.isIndoorOutdoorTypeProjects = this.currentselectedIndoorOutdoorObj.join().toString();
        //For Status select
        this.currentselectedStatusObj = [];
        this.requestProject.multipleStatus = "";
        if (this.roleName == "Maler") {
            for (var i = 0; i < 13; i++) {
                if (i != 9 && i != 12) {
                    this.currentselectedStatusObj.push(i);
                }
            }
        }
        else {
            for (var i = 0; i < 13; i++) {
                this.currentselectedStatusObj.push(i);
            }
        }
        this.requestProject.multipleStatus = this.currentselectedStatusObj.join().toString();
        this.requestProject.isLift = false;
        this.requestProject.isRiseman = false;
        this.GetProjects(this.requestProject);
    }
    Search(value) {
        if (value == undefined || value.toString().length % 1 == 0) {
            //this.requestProject.Address = this.search.trim();
            this.requestProject.OrderBy = this.orderBy;
            this.GetProjects(this.requestProject);
        }
    }

    GetProjectsForStatus(model: RequestStatusProject) {
        this.showSpinner = true;
        //this.search = this.requestProject.Address;
        this.projectservice.setRequestParameter(model);
        this.projectservice.Get<ResponseBase>(model)
            .subscribe((response: ResponseBase) => {
                if (response != null) {
                    //this.Projects = this.projectservice.projects;
                    this.Projects = [];
                    this.Projects = response.result;
                    this.projectservice.setBGImageForProjectCards(this.Projects);

                    this.pagination = response.pagination;
                    this.displayPagination = true;
                    this.showSpinner = false;
                    for (var i = 0; i < this.Projects.length; i++) {
                        var formateDate = moment(this.Projects[i].startDate).format('YYYY-MM-DD');
                        //if (this.TodayDate <= formateDate && this.After5DayDate >= formateDate)
                        if (this.After5DayDate >= formateDate)
                            this.Projects[i].displayStartDateRed = true;
                        else
                            this.Projects[i].displayStartDateRed = false;
                    }
                }
                else {
                    this.Projects = [];
                    this.displayPagination = false;
                    this.showSpinner = false;
                }
            },
                error => (err) => {
                    this.Projects = [];
                    this.displayPagination = false;
                    this.showSpinner = false;
                },
                () => {
                });
    }

    //coworker multiselect code here
    toggleAllSelection() {
        if (this.allSelected.selected) {
            this.currentselectedcoWorkerObj.id = [];
            this.currentselectedcoWorkerObj.id.push(0);
            for (var i = 0; i < this.coWokers.length; i++) {
                this.currentselectedcoWorkerObj.id.push(this.coWokers[i].id);
            }
        } else {
            this.currentselectedcoWorkerObj.id = [];
        }

        var withoutAll = this.currentselectedcoWorkerObj.id.filter(o => o != 0);
        this.requestProject.Address = "";
        if (this.currentselectedcoWorkerObj.id.length <= 0) {
            this.selectedCoworkerId = "";
        }
        else {
            this.selectedCoworkerId = withoutAll.join();
        }
        this.requestProject.CoworkerId = this.selectedCoworkerId;
        this.GetProjects(this.requestProject);
    }

    toggleAllStatusSelection() {
        this.currentselectedStatusObj = [];
        this.requestProject.multipleStatus = "";
        if (this.allStatusSelected.selected) {
            if (this.roleName == "Maler") {
                for (var i = 0; i < 13; i++) {
                    if (i != 9 && i != 12) {
                        this.currentselectedStatusObj.push(i);
                    }
                }
            }
            else {
                for (var i = 0; i < 13; i++) {
                    this.currentselectedStatusObj.push(i);
                }
            }
            this.requestProject.multipleStatus = this.currentselectedStatusObj.join().toString();
        }
        this.GetProjects(this.requestProject);
    }

    toggleAllStatusIndoorOutDoorSelection() {
        this.currentselectedIndoorOutdoorObj = [];
        this.requestProject.isIndoorOutdoorTypeProjects = "";
        if (this.allIndoorOutdoorStatusSelected.selected) {
            for (var i = 0; i < 7; i++) {
                this.currentselectedIndoorOutdoorObj.push(i);
            }
            this.requestProject.isIndoorOutdoorTypeProjects = this.currentselectedIndoorOutdoorObj.join().toString();
        }
        this.GetProjects(this.requestProject);
    }


    DropDownCoWorkerSelect(coworker) {
        if(this.currentselectedcoWorkerObj && this.currentselectedcoWorkerObj.id){
        this.currentselectedcoWorkerObj.id = this.currentselectedcoWorkerObj.id.filter(o => o != 0);
        this.requestProject.Address = "";

        //For IndoorOutdoor select
        this.currentselectedIndoorOutdoorObj = [];
        this.requestProject.isIndoorOutdoorTypeProjects = "";
        for (var i = 0; i < 7; i++) {
            this.currentselectedIndoorOutdoorObj.push(i);
        }
        this.requestProject.isIndoorOutdoorTypeProjects = this.currentselectedIndoorOutdoorObj.join().toString();
        //For Status select
        this.currentselectedStatusObj = [];
        this.requestProject.multipleStatus = "";
        if (this.roleName == "Maler") {
            for (var i = 0; i < 13; i++) {
                if (i != 9 && i != 12) {
                    this.currentselectedStatusObj.push(i);
                }
            }
        }
        else {
            for (var i = 0; i < 13; i++) {
                this.currentselectedStatusObj.push(i);
            }
        }
        this.requestProject.multipleStatus = this.currentselectedStatusObj.join().toString();

        if (this.currentselectedcoWorkerObj.id.length <= 0) {
            this.selectedCoworkerId = "";
        }
        else {
            this.selectedCoworkerId = this.currentselectedcoWorkerObj.id.join();
        }
        this.requestProject.CoworkerId = this.selectedCoworkerId;
        this.GetProjects(this.requestProject);
        }
    }



    getCoWorkers(model) {
        model.Limit = 400;
        model.OrderBy = "Name";
        this.coWorkersService.Get<ResponseBase>(model)
            .subscribe((response: ResponseBase) => {
                if (response) 
                    this.getNextRequestForCoWorker(response.pagination, model, response.result);
                else 
                    this.coWokers = [];
            },
                error => (err) => {
                    this.coWokers = [];
                },
                () => {
                });
    }

    getNextRequestForCoWorker(pagination, model, response) {
        if (pagination.index == 1) {
            this.coWokers = response;//response.filter(x => x.doNotShowOnPlanReminderProj == false);
        }
        else {
            //response = response.filter(x => x.doNotShowOnPlanReminderProj == false);
            this.coWokers.push(...response);
        }
        this.coWorkersService.setResponseForCoworker(this.coWokers);
        model.Index = pagination.index + 1;
        model.Limit = pagination.limit;
        model.TotalRows = pagination.totalCount;
        if (pagination.totalCount > pagination.index * pagination.limit)
            this.getCoWorkers(model);
    }

    checkAccess(event) {
        this.EditCustomer(event.sagId);
    }

    goToRequisitionTab(event) {
        var id = event.sagId;
        this._router.navigate(['/projects/edit', id], { queryParams: { isActive: this.isActive, tabIndex: 1 } });
    }
    goToRequisitionMaterialTab(event) {
        var id = event.sagId;
        this._router.navigate(['/projects/edit', id], { queryParams: { isActive: this.isActive, tabIndex: 1, reqTabIndex: 1 } });
    }

    goToRequisitionTabMode(event) {
        var id = event.project.sagId;
        if (event.tabChangeName == "Status")
            this._router.navigate(['/projects/edit', id], { queryParams: { isActive: this.isActive, tabIndex: 1, reqTabIndex: 1 } });
        if(event.tabChangeName == "Material")
            this._router.navigate(['/projects/edit', id], { queryParams: { isActive: this.isActive, tabIndex: 1, reqTabIndex: 1 } });
        if(event.tabChangeName == "Coworker")
            this._router.navigate(['/projects/edit', id], { queryParams: { isActive: this.isActive, tabIndex: 1, reqTabIndex: 2 } });
        if (event.tabChangeName == "Economy")
            this._router.navigate(['/projects/edit', id], { queryParams: { isActive: this.isActive, tabIndex: 1, reqTabIndex: 2 } });
    }

    EditCustomer(id) {
        this._router.navigate(['/projects/edit', id], { queryParams: { isActive: this.isActive } }); //navigationExtras
    }

    receiveFilterEvent(event) {
        if (this.currentselectedStatusObj == undefined) {
            this.currentselectedStatusObj = [];
        }
        this.requestProject.multipleStatus = this.currentselectedStatusObj.join().toString();
        this.orderBy = event;
        this.requestProject.OrderBy = this.orderBy;
        if (this.limitNumber == undefined) {
            this.requestProject.Limit = 100
        }
        else {
            this.requestProject.Limit = this.limitNumber;
        }
        this.GetProjects(this.requestProject);
    }

    receiveStatusFilterEvent(event) {
        this.status = event;
        this.requestProject.Status = event;
        this.GetProjects(this.requestProject);
    }

    isNumber(evt) {
        return IsNumber(evt);
    }

    onSetActive(active) {
        this.isActive = active;
        this.requestProject.Index = 1;
        this.requestProject.IsActive = active;
        this.GetProjects(this.requestProject);
    }

    onSetDeleted(deleted) {
        this.isDeleted = deleted;
        this.requestProject.Index = 1;
        this.requestProject.IsDeleted = deleted;
        this.GetProjects(this.requestProject);
    }

    onChangeSearchFilter() {
        setTimeout(() => {
            if (this.currentselectedIndoorOutdoorObj == undefined) {
                this.currentselectedIndoorOutdoorObj = [];
            }

            var IsSelectedAllStatus = this.currentselectedIndoorOutdoorObj.find(o => o == 1 || o == 2 || o == 3);
            if (IsSelectedAllStatus) {
                this.currentselectedStatusObj = [];
                ("1,3,5,6,8,11").split(",").filter(x => {
                    this.currentselectedStatusObj.push(Number(x));
                })
                this.requestProject.multipleStatus = this.currentselectedStatusObj.join().toString(); //"8,9";
            }
            else {
                this.currentselectedStatusObj = [];
                this.currentselectedStatusObj.push(0);
                this.projectStatus.filter(x => {
                    this.currentselectedStatusObj.push(Number(x.id));
                })
                this.requestProject.multipleStatus = this.currentselectedStatusObj.join().toString(); //"8,9";

            }

            this.requestProject.isIndoorOutdoorTypeProjects = this.currentselectedIndoorOutdoorObj.join().toString();
            this.GetProjects(this.requestProject);
        }, 1000)
    }

    onFocusIn(event) {
        event.model.show();
    }

    coWorkerPopup() {
        const dialogRef = this.dialog.open(CoWorkerComponent, {
            height: '70%',
            width: '80%',
            data: this.coWokers
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result != null && result != '') {
            }
        });
    }

    onStartDateSelect() {
        // this.requestProject.startDate = this.datePipe.transform(this.requestProject.startDate, 'yyyy-MM-dd');
        this.requestProject.startDate = this.datePipe.transform(this.requestProjectStartDate, 'yyyy-MM-dd');
        this.onChangeSearchFilter();
    }
    truncDecimalpoint(value) {
        return Math.trunc(value)
    }

    statusMessagePopup() {
        const dialogRef = this.dialog.open(StatusMessageComponent, {
            height: '90%',
            width: '80%',
            disableClose: true,
            data: this.coWokers
        });
        dialogRef.afterClosed().subscribe(result => { });
    }

    priceDiscountPopup() {
        const dialogRef = this.dialog.open(ProjectPriceDiscountComponent, {
            height: '90%',
            width: '80%',
            disableClose: false,
            data: this.coWokers
        });
        dialogRef.afterClosed().subscribe(result => { });
    }

    gotoMessageLogTab(event) {
        var id = event.customerId;
        this._router.navigate(['/customers/edit', id], { queryParams: { isActive: this.isActive, tabIndex: 1, reqTabIndex: 5 } });
    }

    onCowrkerChange(e) {
        this.Projects=[];
        this.DropDownCoWorkerSelect(e);
    }

    ShowProjectHoursDetailsList() {
        this.currentselectedStatusObj = [];
        this.currentselectedIndoorOutdoorObj = [];
        var tempProjectHourData = this.settingMasterValues.find(s => s.key1 == "Project" && s.key2 == "CalculateProjectPriceStatus");
        var calculateProjectPriceStatus = JSON.parse(tempProjectHourData.value);
        if(calculateProjectPriceStatus.projectIndoorOutdoorStatus){
            var obj = calculateProjectPriceStatus.projectIndoorOutdoorStatus.split(",");
            for (var i = 0; i < obj.length; i++) {
                this.currentselectedIndoorOutdoorObj.push(Number(obj[i]));
            }
        }
        if(calculateProjectPriceStatus.requistionStatus){
            var statusObj = calculateProjectPriceStatus.requistionStatus.split(",");
            for (var i = 0; i < statusObj.length; i++) {
                this.currentselectedStatusObj.push(Number(statusObj[i]));
            }
        }
        const dialogRef = this.dialog.open(ProjectHoursDetails, {
            height: "90%",
            width: "90%",
            data: {
                status: this.currentselectedStatusObj.join().toString(),
                indoorOutdoorStatus: this.currentselectedIndoorOutdoorObj.join().toString(),
                isIncludeTurnover: true
            }
        });
        dialogRef.afterClosed().subscribe(result => {
        this.requestProject.multipleStatus = this.currentselectedStatusObj.join().toString();
        this.requestProject.isIndoorOutdoorTypeProjects = this.currentselectedIndoorOutdoorObj.join().toString();
        this.GetProjects(this.requestProject);
        });
    }

    changeViewMode() {
        if (this.requestProject) {
            this.requestProject.viewMode = "List";
            this.projectservice.setRequestParameter(this.requestProject);
            this._router.navigate(['/projectslist']);
        }
    }
}