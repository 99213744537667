export const locale = {
    lang: 'en',
    data: {
        'FIELD': {
            'MyProfile': 'My Profile',
            'Messages': 'Messages',
            'Logout': 'Logout'
        },
        'TITLE_MATERIAL': 'Material',
        'TITLE_MATERIALS': 'Materials',
        'TITLE_NEW_MATERIAL': 'New Material',
        'TITLE_EDIT_MATERIAL': 'Update Material',
        'TITLE_DASHBOARD': 'Dashboard',
        'TITLE_CUSTOMERS': 'Customers',
        'TITLE_CUSTOMER': 'Customer',
        'TITLE_NEW_CUSTOMER': 'New Customer',
        'TITLE_UPDATE_CUSTOMER': 'Update Customer',
        'TITLE_PROJECTS': 'Projects',
        'TITLE_PROJECT': 'Project',
        'TITLE_PROJECTS_OFFER': 'Offer',
        'TITLE_PROJECTS_OFFERS': 'Offers',
        'TITLE_NEW_PROJECT': 'New Project',
        'TITLE_UPDATE_PROJECT': 'Update Project',
        'TITLE_DOCUMENTS': 'Documents',
        'TITLE_WORK_HOURS': 'Work-hours',
        'TITLE_MESSAGES': 'Messages',
        'TITLE_COWORKERS': 'Co-Workers',
        'TITLE_COWORKER': 'Co-Worker',
        'TITLE_NEW_COWORKER': 'New Co-Worker',
        'TITLE_UPDATE_COWORKER': 'Update Co-Worker',
        'TITLE_COMAPNYINFO': 'Company-Info',
        'TITLE_USER_PROFILE': 'My Profile',
        'TITLE_REMINDERS': 'Reminders',
        'TITLE_USER_MANUAL': 'User Manual',
        'TITLE_INQUIRYS': 'Inquiries',
        'TITLE_INQUIRY': 'Inquiry',
        'TITLE_REPORTS': 'Reports',
        'TITLE_REPORT': 'Report',
        'TITLE_PLANING': 'Planning',
        'TITLE_RESOURCECALENDAR': 'Resource Calendar',
        'TITLE_QUICKINVOICE': 'Quick Invoice',
        'TITLE_STORE_SALES': 'Store sales',
        'TITLE_STATISTIC': 'Statistic',
        'TITLE_SUPPLIERS': 'Suppliers',
        'TITLE_PAYROLL': 'Payroll Entry',
        'TITLE_SUPPLIER_SETTINGS': 'Price adjustment',
        'TITLE_CARINFORMATIONS': 'Car Informations',
        'TITLE_VEHICLE': 'Vehicles info',
        'TITLE_SETTINGS': 'Settings',
        'TITLE_QUALITY_TEST':'Quality Test',
        'TITLE_TOOLSANDLADDERS': 'Tools and ladders',
        'TITLE_TERMOFEMPLOYMENT': 'Terms Of Employment',
        'TITLE_ASSETS': 'Assets',
        // 'TITLE_DEVICES': 'Nano Devices',
        'TITLE_DEVICES': 'Nano Chips',
        'TITLE_LIVEMAP': 'Live Map',
        'TITLE_LANGUAGE': 'Language',
        'TITLE_RIGHT_MENU_CALENDER': 'Calender',
        'TITLE_REVIEW_QUESTION': 'Coworker review questions',
        'TITLE_SUPPLIERPROJECTMATERIAL': 'Project Material',
    }
};

