import { Component, OnInit, Inject } from '@angular/core';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { OfferQualityTestCategory } from 'app/main/model/OfferQualityTestCategory';
import { OfferCategory } from 'app/main/model/ProjectOffer';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { QualityTestService } from '../../quality-test.service';

@Component({
    selector: 'app-quality-category-edit',
    templateUrl: './edit-quality-category.component.html',
    styleUrls: ['./edit-quality-category.component.scss']
})
export class EditQualityCategoryComponent implements OnInit {
    qualityCategoryId: string;
    qualityCategory = new OfferQualityTestCategory();
    previousCategory = new OfferCategory();
    isNameValid: boolean = false;
    showSpinner: boolean = false;

    constructor(private qualityTestService: QualityTestService,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<EditQualityCategoryComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private translate: TranslateService,
        private translationLoader: FuseTranslationLoaderService) {
        this.translationLoader.loadTranslations(english, danish);
    }
    
    ngOnInit() {
        this.qualityCategoryId = this.data.offerCategoryId;
        this.previousCategory = this.data.previousCategory;
        if (!this.qualityCategoryId && this.previousCategory) {
            this.qualityCategory.parentID = this.previousCategory.id;
            this.qualityCategory.level = this.previousCategory.level + 1;
            this.qualityCategory.isLeafNode = true;
        } else if(this.data.offerCategory) {
            this.qualityCategory = Object.assign({}, this.data.offerCategory);
        }
    }

    ChangeImage(event) {
        this.qualityCategory.image = event;
    }

    save() {        
        if(this.qualityCategory.name==''){
            this.isNameValid = true;
            return;
        } else {
            this.isNameValid = false;
        }
        this.showSpinner = true;
        if (this.qualityCategoryId) {
            this.qualityTestService.UpdateQualityTestCategory(this.qualityCategoryId, this.qualityCategory)
                .subscribe((response: ResponseBase) => {
                    this.showSpinner = false;
                    if (response) {
                        this.qualityCategory = response.result;
                        this.dialogRef.close(this.qualityCategory)
                    }
                },
                    error => (err) => {
                        this.showSpinner = false;
                    });
        }
        else {
            this.qualityTestService.AddOfferQualityTestCategory(this.qualityCategory)
                .subscribe((response: ResponseBase) => {
                    this.showSpinner = false;
                    if (response) {
                        this.qualityCategory = response.result;
                        this.dialogRef.close(this.qualityCategory)
                    }
                },
                    error => (err) => {
                        this.showSpinner = false;
                    });
        }
    }

    onCloseDialog(e) {
        this.dialogRef.close(null)
    }

}
