export const locale = {
  lang: 'en',
  data: {
    'TABS': {
      'MATERIAL': 'Materials',
      'CUSTOMER': 'Customers',
      'PROJECT': 'Projects',
      'COWORKER': 'Co-Workers',
      'PRICE': 'Price',
      'OFFER': 'Offers',
    },
    'Message': {
      'ComingSoon': 'Coming soon',
    },
  }
};
