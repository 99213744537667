export const locale = {
  lang: 'ds',
  data: {
    'FIELDS': {
      'NewPassword': 'Nyt Kodeord',
      'ConfirmPassword': 'Bekræft Kodeord',
      'OldPassword': 'Gammelt Kodeord',
      'Updated': 'Opdateret succesfuldt.',
      'Failed': 'mislykkedes',
      'PasswordLength': 'Adgangskode skal være 6-20 længde',
      'PasswordNotMatch': 'Adgangskode stemmer ikke overens',
      'PasswordPattern': 'Adgangskoden skal være konatin a-z og 1-9',
    },
    'BUTTON': {
      'Cancel': 'Ændre ikke',
      'ChangePassword': 'Skift Kodeord'
    }
  }
};
