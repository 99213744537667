import { Component, OnInit, Optional } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { MaterialWeatherType } from 'app/constant/common.constant';
import { MaterialWeatherTypeDTO } from 'app/main/model/Material';
import { MatDialog, MatDialogRef } from '@angular/material';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { MaterialService } from 'app/main/pages/material/material.service';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

//translate
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { FormService } from 'app/core/services/form.service';

@Component({
    selector: "vibe-weather-type",
    templateUrl: "./weather-type.component.html",
    styleUrls: ["./weather-type.component.scss"]
})

export class WeatherTypeComponent implements OnInit {
    @Optional() public dialogRef: MatDialogRef<WeatherTypeComponent>;
    weatherForm: FormGroup;
    public showSpinner: boolean = false;
    weatherTypeDetail: any;
    weatherTypeList = MaterialWeatherType;
    MaterialWeatherObject = new MaterialWeatherTypeDTO();
    MaterialWeatherTypeList = [];
    submitted: boolean = false;
    hours: any;
    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private dialog: MatDialog,
        private notificationsService: NotificationsService,
        private translate: TranslateService,
        private materialService: MaterialService,
        private formBuilder: FormBuilder,
        private formService: FormService) {
        translationLoaderService.loadTranslations(danish, english);
        this.hours = [{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }, { value: 6 }, { value: 7 }, { value: 8 },
        { value: 9 }, { value: 10 }, { value: 11 }, { value: 12 }];

    }

    ngOnInit() {
        this.GetWeatherList();
        this.weatherForm = this.formBuilder.group({
            TypeId: [null, [Validators.required]],
            StartMonth: [null, [Validators.required]],
            EndMonth: [null, [Validators.required]]
        });
    }

    GetWeatherList() {
        this.showSpinner = true;
        this.materialService.GetWeatherList().subscribe((response: ResponseBase) => {
            if (response) {
                this.showSpinner = false;
                this.weatherTypeDetail = response.result.myResponse[0];
                if (this.weatherTypeDetail.value) {
                    this.MaterialWeatherTypeList = JSON.parse(this.weatherTypeDetail.value);
                }
            }
        });
    }

    AddUpdate() {
        this.submitted = true;
        this.formService.markFormGroupTouched(this.weatherForm);
        if (this.weatherForm.invalid) {
            return;
        }
        this.showSpinner = true;
        if (this.MaterialWeatherTypeList.length > 0) {
            this.MaterialWeatherTypeList = this.MaterialWeatherTypeList.filter(item => item.typeId !== this.MaterialWeatherObject.typeId);
        }
        this.MaterialWeatherTypeList.push(this.MaterialWeatherObject);
        this.weatherTypeDetail.value = JSON.stringify(this.MaterialWeatherTypeList);
        this.materialService.AddUpdateWeatherType(this.weatherTypeDetail.id, this.weatherTypeDetail)
            .subscribe({
                next: (response: ResponseBase) => {
                    if (response) {
                        this.showSpinner = false;
                        this.submitted = false;
                        this.MaterialWeatherObject = new MaterialWeatherTypeDTO();
                        this.weatherForm.reset();
                        this.translate.get("MESSAGES.Added").subscribe({
                            next: (res) => {
                                this.notificationsService.create(res);
                            },
                            error: err => {
                            }
                        });
                    }
                },
                error: err => {
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });
                },
                complete: () => {
                    this.showSpinner = false;
                }
            });
    }

    edit(value) {
        this.MaterialWeatherObject = new MaterialWeatherTypeDTO();
        this.MaterialWeatherObject = value;
    }

    delete(value) {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: '200px',
            data: {
                Header: 'Headers.DeleteWeather'
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.showSpinner = true;
                this.MaterialWeatherTypeList = this.MaterialWeatherTypeList.filter(item => item.typeId !== value.typeId);
                this.materialService.AddUpdateWeatherType(this.weatherTypeDetail.id, this.weatherTypeDetail)
                    .subscribe({
                        next: (response: ResponseBase) => {
                            if (response) {
                                this.showSpinner = false;
                                this.MaterialWeatherObject = new MaterialWeatherTypeDTO();
                                this.weatherForm.reset();
                                this.translate.get("MESSAGES.Deleted").subscribe({
                                    next: (res) => {
                                        this.notificationsService.create(res);
                                    },
                                    error: err => {
                                    }
                                });
                            }
                        },
                        error: err => {
                            this.showSpinner = false;
                            this.translate.get("MESSAGES.Failed").subscribe({
                                next: (res) => {
                                    this.notificationsService.error(res);
                                },
                                error: err => {
                                }
                            });
                        },
                        complete: () => {
                            this.showSpinner = false;
                        }
                    });
            }
        });
    }

    getWeatherTypeName(id) {
        let weatherName = "";
        weatherName = this.weatherTypeList.find(o => o.id == id).key;
        return weatherName;
    }

    getTypeIcon(id) {
        let icon = "";
        icon = this.weatherTypeList.find(o => o.id == id).icon;
        return icon;
    }

    getIcon(icon) {
        return 'icon-' + icon;
    }
}