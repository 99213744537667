export const locale = {
  lang: 'ds',
  data: {
    'FIELDS': {
      'Add': 'Tilføj',
      'SelectProjectToAdd': 'Vælg projekt',
      'SagNo': 'Sag No.',
      'Address': 'Adresse'
    }
  }
}

