export class ProjectOffer {
    public id: string;
    public projectId: string;
    public requisitionId: string;
    public customerName: string;
    public offerType: number;
    public description: string;
    public address: string;
    public postNumber: string;
    public city: string;
    public indoorsOutdoorsStatistId: any;
    public indoorsOutdoorsStatistIds: any;
    public email: string;
    public phoneNumber: string;
    public departmentName: string;
    public departmentAddress: string;
    public departmentPostNumber: string;
    public departmentCity: string;
    public inquiryId: string;
    public customerId: string;
    public condition: string;
    public notes: string;
    public isDeleted: boolean = false;
    public isActive: boolean = false;
    public createdBy: string;
    public createdDate: Date = new Date();
    public modifyBy: string
    public modifyDate: Date = new Date();
}

export class OfferModule {
    public id: string;
    public projectId: string;
    public requisitionId: string;
    public offerType: number;
    public description: string;
    public address: string;
    public postNumber: string;
    public city: string;
    public indoorsOutdoorsStatistId: any;
    public indoorsOutdoorsStatistIds: any;
    public email: string;
    public phoneNumber: string;
    public inquiryId: string;
    public customerId: string;
    public customerName: string;
    public condition: string;
    public notes: string;
    public isDeleted: boolean = false;
    public isActive: boolean = false;
    public createdBy: string;
    public createdDate: Date = new Date();
    public modifyBy: string
    public modifyDate: Date = new Date();
    public number: number = 0;
    public status: number = 1;
    public countryCode: string;
    public projectNumber: number;
    public percentage: number = 0;
    public indoorOutdoorStatusIDPercentage: number = 0;
    public propertyId: string;
    public offerCreatedDate: any;
}

export class OfferAddressModel extends OfferModule {
    public isSetInquiryAddress: boolean = false;
    public isSetCustomerAddress: boolean = false;
    public isSetProjectAddress: boolean = false;
    public isSetRequisitionAddress: boolean = false;
}

export class ProjectsOfferRoom {
    public id: string = '';
    public offerID: string = '';
    public name: string;
    public width: number = 0;
    public length: number = 0;
    public ceilingHeight: number = 2.5;
    public extraQty1: number = 0;
    public extraQty2: number = 0;
    public extraPrice1: number = 0;
    public extraPrice2: number = 0;
    public baseline: number = 0;
    public height: number = 0;
    public area: number = 0;
    public extraM2QtyWall: number = 0;
    public extraM2QtyCeiling: number = 0;
    public extraM2QtyFloor: number = 0;
    public extraM2PriceWall: number = 0;
    public extraM2PriceCeiling: number = 0;
    public extraM2PriceFloor: number = 0;
}

export class Rooms {
    public id: string = '';
    public moduleID: string = '';
    public name: string;
    public width: number = 0;
    public length: number = 0;
    public ceilingHeight: number = 2.5;
    public extraQty1: number = 0;
    public extraQty2: number = 0;
    public extraPrice1: number = 0;
    public extraPrice2: number = 0;
    public baseline: number = 0;
    public height: number = 0;
    public area: number = 0;
    public extraM2QtyWall: number = 0;
    public extraM2QtyCeiling: number = 0;
    public extraM2QtyFloor: number = 0;
    public extraM2PriceWall: number = 0;
    public extraM2PriceCeiling: number = 0;
    public extraM2PriceFloor: number = 0;
}

export class OfferRoom extends Rooms {
    public customerID: string = this.moduleID;
}

export class CustomerRoom extends Rooms {
    public CustomerPropertyID: string;
    constructor() {
        super();
        this.CustomerPropertyID = this.moduleID;
    }
}

export class ProjectRoom extends Rooms {
    public customerID: string = this.moduleID;
}

export class OfferCategory {
    public id: string;
    public parentID: string;
    public name: string;
    public ceilingHeight: number;
    public price: number;
    public measurement: string;
    public imagePath: string;
    public image: string;
    public isLeafNode: boolean;
    public isDeleted: boolean;
    public formName: string;
    public level: number;
    public formCategory: number;
    public offerItemGroupId: string = "";
    public childCategoris: OfferCategory[] = [];
    public featureJsonString: string;
    public formFeatureJsonString: string;
    public isSelected: boolean;
    public categoryMaterialList: OfferCategoryMaterial[] = [];
    public showRoomDetails: boolean = false;
    public multicheck: boolean = false;
    public qty: number = 0;
    public imageCount: number = 0;
}

//export class OfferCategoryWorkItem {
//  public id: string;
//  public offerCategoryID: string;
//  public offerRoomId: string;
//  public name: string;
//  public price: number;
//  public unitOfMeasurent: number;
//  public qty: number = 0;
//  public qty2: number = 0;
//  public qty3: number = 0;
//  public qty4: number = 0;
//  public total: number = 0;
//  public attributeJson: string;
//  public isActive: boolean;
//  public isDeleted: boolean;
//  public CreatedDate: Date;
//  public ModifyDate: Date;
//  public offerGrandTotal: number;
//  public rounding: number;
//  public supplementDeduction: number;
//  public isPainted: boolean;
//}

export class OfferCategoryWorkItem {
    public id: string;
    public offerCategoryID: string;
    public offerRoomId: string;
    public name: string;
    public price: number;
    public measurement: number;
    public denRaekker: number = 0;
    public denRaekkerMeasurement: string;
    public purchasePrice: number = 0;
    public purchasePriceMeasurement: string;
    public retailPrice: number = 0;
    public retailPriceMeasurement: string;
    public workWagesForPerforming: number = 0;
    public workWagesForPerformingMeasurement: string;
    public myFakNumber: number = 0;
    public attributeJson: string;
    public offerGrandTotal: number;
    public rounding: number;
    public supplementDeduction: number;
    public isPainted: boolean;
}

export class OfferItemGroup {
    public id: string;
    public offerCategoryID: string;
    public projectOfferID: string;
    public name: string;
    public offerPrice: number;
    public ceilingHeight: number;
    public measurement: string;
    public price: number;
    public formName: string;
    public featureJsonString: string;
}

export class OfferItem {
    public id: string;
    public offerTypeID: string;
    public offerCategoryWorkItemID: string;
    public name: string;
    public qty: number;
    public measurement: string;
    public price: number;
    public rounding: number;
    public total: number;
    public additionalDeduction: number;
    public denRaekker: number;
    public denRaekkerMeasurement: string;
    public purchasePrice: number;
    public purchasePriceMeasurement: string;
    public retailPrice: number;
    public retailPriceMeasurement: string;
    public workWagesForPerforming: number;
    public workWagesForPerformingMeasurement: string;
    public myFakNumber: string;
    public materialId: string;
}

export class OfferRoomItem {
    public id: string;
    public projectOfferRoomID: string;
    public offerItemGroupID: string;
    public offerItemID: string;
    public name: string;
    public length: number;
    public width: number;
    public ceilingHeight: number;
    public wall1: number;
    public wall2: number;
    public wall3: number;
    public wall4: number;
    public qty1: number;
    public qty2: number;
    public qty3: number;
    public qty4: number;
    public prise: number;
    public measurement: string;
    public isCeilingPaint: boolean;
    public rounding: number;
    public supplementDeduction: number;
}

export class OfferCategoryAndWorkItem {
    public OfferCategory: OfferCategory;
    public OfferCategoryWorkItemList: any[];
}

export class OfferItemAndRoomDetail extends OfferItem {
    public offerRoomItemList: OfferRoomItem[];
}

export class OfferDetail {
    public offerItemGroup: OfferItemGroup;
    public offerItem: any[];
    public offerRoomItem: any[];
    public offerItemAndRoomDetail: OfferItemAndRoomDetail[];
}

export class FormAOfferItems extends OfferRoomItem {
    public subTotal: number;
    public total: number;
    public offerGrandTotal: number;
    public tempGrandTotal: number;
    public doors: number;
    public doorsTotal: number;
}

export class FormOfferRoomItem extends OfferRoomItem {
    public total: number;
}

export class FormCOfferItems {
    public offerRoomItemList: FormOfferRoomItem[];
    public offerItem: OfferItem;
}

export class FormDOfferItems extends OfferItem {
    public offerRoomItemList: FormOfferRoomItem[];
}

export const CategorySelectedOffer: any[] = [
    { id: 20, key: "SelectedOfferCategory.Value20" },
    { id: 4, key: "SelectedOfferCategory.Value4" },
    { id: 12, key: "SelectedOfferCategory.Value12" },
    { id: 18, key: "SelectedOfferCategory.Value18" },
    { id: 7, key: "SelectedOfferCategory.Value7" },
    { id: 13, key: "SelectedOfferCategory.Value13" },
    { id: 23, key: "SelectedOfferCategory.Value23" },
    { id: 1, key: "SelectedOfferCategory.Value1" },
    { id: 14, key: "SelectedOfferCategory.Value14" },
    { id: 21, key: "SelectedOfferCategory.Value21" },
    { id: 11, key: "SelectedOfferCategory.Value11" },
    { id: 16, key: "SelectedOfferCategory.Value16" },
    { id: 9, key: "SelectedOfferCategory.Value9" },
    { id: 19, key: "SelectedOfferCategory.Value19" },
    { id: 15, key: "SelectedOfferCategory.Value15" },
    { id: 6, key: "SelectedOfferCategory.Value6" },
    { id: 5, key: "SelectedOfferCategory.Value5" },
    { id: 10, key: "SelectedOfferCategory.Value10" },
    { id: 2, key: "SelectedOfferCategory.Value2" },
    { id: 17, key: "SelectedOfferCategory.Value17" },
    { id: 3, key: "SelectedOfferCategory.Value3" },
    { id: 22, key: "SelectedOfferCategory.Value22" },
    { id: 8, key: "SelectedOfferCategory.Value8" },
]

export const CategorySelectTime: any[] = [
    { id: 1, value: '1gang', key: "SelectedCategoryTime.Value1", materialId: '' },
    { id: 2, value: '2gange', key: "SelectedCategoryTime.Value2", materialId: '' },
    { id: 3, value: '3gange', key: "SelectedCategoryTime.Value3", materialId: '' },
    { id: 4, value: '4gange', key: "SelectedCategoryTime.Value4", materialId: '' },
    { id: 5, value: '5gange', key: "SelectedCategoryTime.Value5", materialId: '' },
    { id: 11, value: 'stk', key: "SelectedCategoryTime.Value11", materialId: '' },
    { id: 12, value: 'meter', key: "SelectedCategoryTime.Value12", materialId: '' },
    { id: 13, value: 'rulle', key: "SelectedCategoryTime.Value13", materialId: '' },
    { id: 14, value: 'liter', key: "SelectedCategoryTime.Value14", materialId: '' },
    { id: 15, value: '1Lag', key: "SelectedCategoryTime.Value15", materialId: '' },
    { id: 16, value: '2Lag', key: "SelectedCategoryTime.Value16", materialId: '' },
    { id: 17, value: '3Lag', key: "SelectedCategoryTime.Value17", materialId: '' }
]

export const PaintCategorySelect: any[] = [
    { id: 1, key: "PaintCategorySelect.value1" },
    { id: 2, key: "PaintCategorySelect.value2" },
]

export const SupplementDeductionTypeSelect: any[] = [
    { id: 0, key: "SupplementDeductionTypeSelect.value0" },
    { id: 1, key: "SupplementDeductionTypeSelect.value1" },
    { id: 2, key: "SupplementDeductionTypeSelect.value2" },
    { id: 3, key: "SupplementDeductionTypeSelect.value3" },
]

//-----------------------------------Old Objects------------------------------------------
export class ProjectDetailForOffer {
    public id: string;
    public projectNumber: string;
    public customerName: string;
    public requisitionId: string;
    public email: string;
    public phoneNumber: string;
    public address: string;
    public postNumber: string;
    public city: string;
    public indoorsOutdoorsStatistId: string;
    public departmentName: string;
    public departmentAddress: string;
    public departmentPostNumber: string;
    public departmentCity: string;
}



export class ProjectsOfferRoomCalculate extends ProjectsOfferRoom {
    public subTotal: number;
    public total: number;
    public price: number;
    public rounding: number;
    public supplementDeduction: number;
    public tempGrandTotal: number;
    public doors: number;
    public doorsTotal: number;
    public isPainted: boolean;
}

//export class ProjectOffer {
//  public id: string;
//  public projectId: string;
//  public offerType: string;
//  public description: string;
//  public isDeleted: boolean = false;
//  public isActive: boolean = true;
//}



export class OfferItems {
    public id: string;
    public offerItemGroupID: string;
    public offerRoomId: string;
    public offerCategoryWorkItemID: string;
    public name: string;
    public length: number;
    public width: number;
    public ceilingHeight: number;
    public wall1: number;
    public wall2: number;
    public wall3: number;
    public wall4: number;
    public price: number;
    public qty: number;
    public qty2: number;
    public qty3: number;
    public qty4: number;
    public supplementDeduction: number;
    public rounding: number;
    public total: number;
    public unitOfMeasurent: number;
    public sttributeJson: number;
    public offerCategoryID: number;
    public offerGrandTotal: number;
    public isPainted: number;
}



//export class PostOfferCategory {
//  public offerID: string;
//  public offerCategoryID: string;
//  public offerTotal: number;
//  public offerGroupName: string;
//  public offerRoomItemList: any;
//  public offerItemList: any;
//  public offerName: string;
//  public featureJsonString: string;
//  public featureJson: FeatureJson;
//}


export class OfferCategoryWithOfferDetail extends OfferCategory {
    public offerId: string;
    public featureJson: FeatureJson;
}

export const OfferType: any[] = [
    { id: 1, key: 'InsideOrOutsideOffer.value1' },
    { id: 2, key: 'InsideOrOutsideOffer.value2' }
];

export class FeatureJson {
    public theRanks: number = 0;
    public purchasePrice: number = 0;
    public salePrice: number = 0;
    public amountOfWages: number = 0;
}

export class OfferCategoryMaterial {
    public id: string;
    public offerCategoryId: string;
    public materialTypeID: number;
    public offerMaterialID: string;
    public isDeleted: boolean = false;
    public isActive: boolean = false;
    public createdBy: string;
    public createdDate: Date = new Date();
    public modifyBy: string
    public modifyDate: Date = new Date();
    public materialList = [];
    public price: number = 0;
    public materialQty: number = 0;
    public reminder: string = "";
    public isChecked: boolean = false;
    public width: number = 0;
    public length: number = 0;
    public total: number = 0;
    public roomList = [];
    public selectCategoryTimeForDiverse = [];
    public materialPriceID:string = "";
    public isShowRoomList: boolean = false;
    public baseline: number = 0;
    public height: number = 0;
    public area: number = 0;
    public offerMaterialText: string="";
    public colorCode: string
    public treatmentType: number = 0
    public extraM2QtyWall: number = 0;
    public extraM2QtyCeiling: number = 0;
    public extraM2QtyFloor: number = 0;
    public showAndet: boolean = true;
    public isCopyMaterial: boolean = true;
    public extraM2PriceWall: number = 0;
    public extraM2PriceCeiling: number = 0;
    public extraM2PriceFloor: number = 0;
    public roomDetails: any;
    public isAddExtraQty: boolean = false;
    public notCalculateToDriving: boolean = false;
    public timer: number = 0;
}

export class OffersMaterialDocument {
    public offerID: string;
    public offerTreatmentID: string[] = [];
}

export class OffersMaterialDocumentDetail {
    public isSend: boolean = false;
    public fileName: string;
    public sharedLink: string[] = [];
}

export class OutdoorFileList {
    public categoryId: string = "";
    public categoryName: string = "";
    public files: string[] = [];
    public materialId: string = "";
}
export const OrderByStatus: any[] = [
    { id: 1, key: 'orderByStatus.value1', value:'Date ASC' },
    { id: 2, key: 'orderByStatus.value2', value:'Date DESC' },
];