export const locale = {
  lang: 'en',
  data: {
    'FIELDS': {
      'Name': 'Name',
      'UserName': 'Username',
      'Password': 'Password',
      'Email': 'E-mail',
      'Address': 'Address',
      'BloodGroup': 'Blood Group',
      'Contact': 'Contact',
      'BirthDate': 'Birth Date',
      'DateOfJoininng': 'Date of Joining',
    },
    'BUTTON': {
      'EDIT': 'Edit',
      'ChangePassword': 'Change Password'
    }
  }
};
