import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeleteAlertModalComponent } from './delete-alert-modal.component';
import { MaterialModule } from '../../../../@fuse/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';

import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    TranslateModule
  ],
  exports: [DeleteAlertModalComponent],
  declarations: [DeleteAlertModalComponent]
})
export class DeleteAlertModalModule {

}
