import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { Router } from '@angular/router';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { RequestProjectOffer } from 'app/main/model/RequestBase';
import { ProjectOffer } from 'app/main/model/ProjectOffer';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { AuthService } from 'app/core/services/auth.service';
import { TitleService } from 'app/core/services/title.service';
import { ProjectofferService } from 'app/main/pages/project-offer/project-offer.service';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { OfferStatus, ProjectIndoorOutDoorStatus } from 'app/main/model/ProjectStatus';
import { MatDialog, MatOption } from '@angular/material';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
// import { ViewQualityTestImagesComponent } from '../../component/view-qualitytest-images/view-qualitytest-images.component';

@Component({
    selector: 'app-project-offer',
    templateUrl: './project-offer.component.html',
    styleUrls: ['./project-offer.component.scss']
})
export class ProjectOfferComponent implements OnInit, OnDestroy {
    @ViewChild('allIndoorOutdoorStatusSelected', { static: false }) private allIndoorOutdoorStatusSelected: MatOption;
    orderBy: string = "Number DESC";
    showSpinner: boolean = false;
    ProjectOfferList: ProjectOffer[];
    SearchValue: string = "";
    //roleName: string;
    // isValid: boolean = false;
    isActive: boolean = false;
    isDeleted: boolean = false;
    displayNotFoundMess: boolean = false;
    currentselectedStatusObj: number[];
    currentselectedIndoorOutdoorObj: number[];
    selectIndoorOutDoorStatus = ProjectIndoorOutDoorStatus;
    offerStatus = OfferStatus;
    selectedOfferStatus: number = 0;
    requestModel: RequestProjectOffer = new RequestProjectOffer();
    pagination: any = {
        "totalCount": 35,
        "index": 1,
        "limit": 100
    };
    settingMasterValues:any[] = [];
    roleClaims: any;
    constructor(private translationLoader: FuseTranslationLoaderService,
        private authService: AuthService,
        private projectofferService: ProjectofferService,
        private notificationsService: NotificationsService,
        private _router: Router,
        private translate: TranslateService,
        private titleService: TitleService,
        private _fuseSidebarService: FuseSidebarService,
        private dialog: MatDialog) {
        this.titleService.SetTitle("TITLE_PROJECTS_OFFERS");
        this.translationLoader.loadTranslations(english, danish);
        this.roleClaims = this.authService.getRoleClaimsList();
    }
    //OfferInput: any;

    ngOnInit() {
        this._fuseSidebarService.getSidebar('navbar').setFoldedAutoTrigger();
        this.requestModel = new RequestProjectOffer();
        this.requestModel.OrderBy = this.orderBy;
        // this.roleName = this.authService.getUserRole();        
        // if (this.roleName == "Admin") {
        //     this.isValid = true;
        // }
        // else if (this.roleName == "Kontor") {
        //     this.isValid = true;
        // }
        // else if (this.roleName == "Maler") {
        //     this.isValid = false;
        // }
        // else if (this.roleName == "Gaest") {
        //     this.isValid = false;
        // }
        // else {
        //     this.isValid = true;
        // }
        this.settingMasterValues = Object.assign([], JSON.parse(localStorage.getItem("settingMasterValues")));
        var tempSettingObject = this.settingMasterValues.find(s => s.key1 == "Menu" && s.key2 == "TabsListSettings").value;
        if(tempSettingObject && JSON.parse(tempSettingObject).find(x=>x.name=='offer').isInitialLoading)
            this.GetProjectsOffers();
    }

    //get Project list
    GetProjectsOffers() {
        //model.IsDeleted = this.isDeleted;
        //model.IsActive = this.isActive;
        this.showSpinner = true;
        this.projectofferService.Get<ResponseBase>(this.requestModel)
            .subscribe((response: ResponseBase) => {
                if (response != null) {
                    this.ProjectOfferList = response.result;
                    if (this.ProjectOfferList && this.ProjectOfferList.length > 0) {
                        this.projectofferService.setBGImageForOfferProjectCards(this.ProjectOfferList);
                    }
                    this.pagination = response.pagination;
                    //count
                    var approvedOffer = response.result.filter(x => x.status == 3)
                    if (approvedOffer.length > 0) {
                        this.authService.setApprovedOfferCount(approvedOffer.length);
                    }
                    else {
                        this.authService.setApprovedOfferCount(0);
                    }
                    //var count = this.ProjectOfferList.length;
                    //if (count <= 0) {
                    //  this.displayNotFoundMess = true;
                    //}
                    this.showSpinner = false;
                }
                else {
                    this.ProjectOfferList = [];
                    this.displayNotFoundMess = true;
                    this.showSpinner = false;
                    //this.translate.get("MESSAGES.Failed").subscribe({
                    //  next: (res) => {
                    //    this.notificationsService.error(res);
                    //  },
                    //  error: err => {
                    //  }
                    //});
                }
            },
                error => (err) => {
                    this.ProjectOfferList = [];
                    this.displayNotFoundMess = true;
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });
                },
                () => {
                });
    }

    DropDownStatusChange(value) {
        if (this.currentselectedStatusObj == undefined) {
            this.currentselectedStatusObj = [];
        }
        var obj = this.currentselectedStatusObj.filter(o => o == value.id);
        var model = new RequestProjectOffer();
        if ((obj != undefined || obj != null) && obj.length == 0) {
            this.currentselectedStatusObj.push(value.id)
        }
        else {
            this.currentselectedStatusObj = this.currentselectedStatusObj.filter(o => o != value.id);
        }
        this.requestModel.IndendoersUdendoersStatistID = this.currentselectedStatusObj.join().toString();
        this.GetProjectsOffers();
    }

    StatusChange(value) {
        this.requestModel.Status = value;
        this.GetProjectsOffers();
    }

    onSetActive() {
        this.requestModel.IsActive = !this.isActive;
        this.GetProjectsOffers();
    }

    getDeletedProjects() {
        this.requestModel.IsDeleted = this.isDeleted;
        this.GetProjectsOffers();
    }

    receiveFilterEvent(event) {
        //var request = new RequestProjectOffer();
        if (this.ProjectOfferList == undefined) {
            this.ProjectOfferList = [];
        }
        this.requestModel.OrderBy = event;
        this.GetProjectsOffers();
    }

    receivePaginatonEvent(event) {
        //var request = new RequestProjectOffer();
        this.requestModel.Index = event.pageIndex;
        this.requestModel.Limit = event.pageSize;
        this.GetProjectsOffers();
    }

    Search() {
        //var request = new RequestProjectOffer();
        if (this.ProjectOfferList == undefined) {
            this.ProjectOfferList = [];
        }
        //this.isActive = true;
        this.requestModel.IsActive = !this.isActive;
        this.requestModel.Search = this.SearchValue.trim();
        this.GetProjectsOffers();
    }

    OfferClick(obj) {
        this._router.navigate(['/offers/', obj.id],{ 
            state: { moduleDataList: this.ProjectOfferList }
        }); //navigationExtras
    }

    // openOfferProjectQuestion() {
    //     this._router.navigate(['offers/questions1']);
    // }

    gotoTreatments(obj) {
        this._router.navigate(['/offers/', obj.id], { queryParams: { tabIndex: 3 } });
    }

    gotoFiles(obj) {
        this._router.navigate(['/offers/', obj.id], { queryParams: { tabIndex: 5 } });
    }

    ngOnDestroy() {
        this._fuseSidebarService.getSidebar('navbar').unsetFoldedAutoTrigger();
    }

    toggleAllStatusIndoorOutDoorSelection() {
        this.currentselectedIndoorOutdoorObj = [];
        this.requestModel.IndendoersUdendoersStatistID = "";
        if (this.allIndoorOutdoorStatusSelected.selected) {
            for (var i = 0; i < 7; i++) {
                this.currentselectedIndoorOutdoorObj.push(i);
            }
            this.requestModel.IndendoersUdendoersStatistID = this.currentselectedIndoorOutdoorObj.join().toString();
        }
        this.GetProjectsOffers();
    }

    onChangeSearchFilter() {
        setTimeout(() => {
            if (this.currentselectedIndoorOutdoorObj == undefined) {
                this.currentselectedIndoorOutdoorObj = [];
            }
            this.requestModel.IndendoersUdendoersStatistID = this.currentselectedIndoorOutdoorObj.join().toString();
            this.GetProjectsOffers();
        })
    }

    // openImage() {
    //     const dialogRef = this.dialog.open(ViewQualityTestImagesComponent, {
    //         height: '90%',
    //         width: '90%',
    //         data: {}
    //     });
    //     dialogRef.afterClosed().subscribe(result => {
    //         // if (result) {
    //         // }
    //     });
    // }

}
