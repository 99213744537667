import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TitleService } from '../../../../core/services/title.service';

@Component({
  selector: 'app-help-document-navigation',
  templateUrl: './help-document-navigation.component.html',
  styles: []
})
export class HelpDocumentNavigationComponent implements OnInit {
  currentLanguage: string;
  constructor(private translate: TranslateService, private titleService: TitleService)
  {
    this.titleService.SetTitle("TITLE_USER_MANUAL");
  }

  ngOnInit() {
    this.currentLanguage = this.translate.currentLang;
  }
  ngOnChanges() {
  }

  ngDoCheck() {
  }

  ngAfterContentInit() {
  }

  ngAfterContentChecked() {
  }

  ngAfterViewInit() {
  }

  ngAfterViewChecked() {
  }

  ngOnDestroy() {
  }

}
