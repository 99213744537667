import { Component, OnInit, Optional, Inject } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';

import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { OutdoorFileList } from "app/main/model/ProjectOffer";

@Component({
    selector: 'vibe-room-area',
    templateUrl: './room-area.component.html',
    styleUrls: ['./room-area.component.scss']
})

export class RoomAreaComponent implements OnInit {
    baseline: number = 0;
    height: number = 0;
    area: number = 0;
    offerCategory: any;
    outdoorFileList: OutdoorFileList[] = [];
    imageCount: number = 0;
    previews: string[] = [];
    constructor(public dialogRef: MatDialogRef<RoomAreaComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
        private translationLoaderService: FuseTranslationLoaderService) {
        this.translationLoaderService.loadTranslations(danish, english);
    }

    ngOnInit() {
        this.baseline = this.data.baseline;
        this.height = this.data.height;
        this.area = this.data.area;
        this.offerCategory = this.data.offerCategory ? this.data.offerCategory : [];
    }

    CalculareArea() {
        this.area = (1 / 2 * this.baseline * this.height);
        var calculatedArea = {
            baseline: this.baseline,
            height: this.height,
            area: this.area,
            outdoorFileList: this.outdoorFileList
        }
        this.ngOnBackClick(calculatedArea);
    }

    ngOnBackClick(data) {
        this.dialogRef.close(data);
    }
    calArea() {
        this.area = (1 / 2 * this.baseline * this.height);
    }

    onUploadMultipleFiles(event) {
        this.previews = [];
        var documentObj = new OutdoorFileList();
        if (event.target.files.length > 0) {
            const files = event.target.files;
            const formData = new FormData();
            for (var i = 0; i < files.length; i++) {
                documentObj.files.push(files[i]);
                const reader = new FileReader();
                reader.onload = (e: any) => {
                    this.previews.push(e.target.result);
                };
                reader.readAsDataURL(files[i]);
            }
            documentObj.categoryId = this.offerCategory.id;
            documentObj.categoryName = this.offerCategory.name;
            if (this.outdoorFileList.length == 0) 
                this.outdoorFileList[0] = documentObj
            else 
                this.outdoorFileList.push(documentObj)
            this.imageCount = this.outdoorFileList[0].files.length;
        }
    }
}