export const locale = {
  lang: 'en',
  data: {
      'TITLE': {
          'DriveSetting': 'Drive setting',
      },
      'FIELDS': {
          'Path': 'Path',
          'OneDrive': 'One drive',
          'Dropbox': 'Dropbox',
          'Token': 'Token',
          'ClientId': 'Client Id',
          'ClientSecret': 'Client Secret',
          'TenantId': 'Tenant Id',
          'UserEmail': 'User Email',
      },
      'Message': {
          'DriveInformation':'*** If change setting all coworker was loggedout.',
      },
      'FILTERS': {
      },
      'Buttons': {
          'Save': 'Save',
      },
      'Help':{
          'Note':'Please use farward slash(/) only in path setting',
      }
  }
};