import { Component, OnInit, ElementRef, NgZone, ViewChild, Input, Output, EventEmitter, Inject } from '@angular/core';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';

import { MapsAPILoader } from '@agm/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
//import { SimpleChanges } from '@angular/core/src/metadata/lifecycle_hooks';
import { GoogleMapDirectionService } from './google-map-direction.service';
declare var google: any;
@Component({
    selector: 'app-google-map-direction',
    templateUrl: './google-map-direction.component.html',
    styleUrls: ['./google-map-direction.component.scss']
})
export class GoogleMapDirectionComponent implements OnInit {
    autocomplete: any;
    setData: any = {};
    zoom: number = 15;
    latitude: number;
    longitude: number;
    callSetPlaceParam: boolean = true;
    private geoCoder;

    @ViewChild("searchOrigin", { static: true }) public searchOriginElementRef: ElementRef;
    @ViewChild("searchDestination", { static: true }) public searchDestinationElementRef: ElementRef;
    @Output() Address = new EventEmitter<any>();
    @Input() objAddressEdit: any;
    autoSearchOnOpen: boolean = false;
    public origin: any;
    public destination: any;
    public originAddress: string = "";
    public destinationAddress: string = "";
    show: boolean = true;
    travelingDetails: any;
    public optimizeWaypoints: boolean = true
    public provideRouteAlternatives: boolean = true
    isShowOkBtn: boolean = false;
    constructor(public translationLoader: FuseTranslationLoaderService,
        private googleMapDirectionService: GoogleMapDirectionService,
        private mapsAPILoader: MapsAPILoader, private ngZone: NgZone,
        public dialog: MatDialog,
        private dialogRef: MatDialogRef<GoogleMapDirectionComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
        //this.origin = { lat: 24.799448, lng: 120.979021 };
        //this.destination = { lat: 24.799524, lng: 120.975017 };
        if (this.data.Address) {
            this.destinationAddress = this.data.Address;
        }
        if (this.data.ZipCode) {
            this.destinationAddress += " " + this.data.ZipCode;
        }
        if (this.data.City) {
            this.destinationAddress += " " + this.data.City;
        }
        this.autoSearchOnOpen = this.data.autoSearchOnOpen;

        //this.destinationAddress = this.data.Address + " " + this.data.ZipCode + " " + this.data.City;
        if (this.data.SourceAdd) {
            var sourceAddress = this.data.SourceAdd;
            this.originAddress = sourceAddress.address + " " + sourceAddress.zipcode + " " + sourceAddress.city;
        } else {
            this.AddressEvent(true);
            setTimeout(() => {
                this.geoCoder = new google.maps.Geocoder;
                this.setCurrentLocation();
            }, 2000);
        }
        if (this.autoSearchOnOpen) {
            this.search();
        }
    }
    AddressEvent(isDestination) {
        var address = this.destinationAddress;
        if (!isDestination) {
            address = this.originAddress;
        }
        this.googleMapDirectionService.Get<any>(address)
            .subscribe({
                next: (response: any) => {
                    if (response.status == "OK") {
                        if (isDestination) {
                            this.destination = {
                                lat: response.results[0].geometry.location["lat"],
                                lng: response.results[0].geometry.location["lng"]
                            };
                        }
                        else {
                            this.origin = {
                                lat: response.results[0].geometry.location["lat"],
                                lng: response.results[0].geometry.location["lng"]
                            };
                        }
                    }
                },
                error: err => { },
                complete: () => { }
            });
    }

    setCurrentLocation() {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.latitude = position.coords.latitude;
                this.longitude = position.coords.longitude;
                this.origin = { lat: position.coords.latitude, lng: position.coords.longitude };
                this.zoom = 15;
                this.getAddress(this.latitude, this.longitude);
            });
        }
    }

    getAddress(latitude, longitude) {
        this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
            if (status === 'OK') {
                if (results[0]) {
                    this.zoom = 12;
                    this.originAddress = results[0].formatted_address;
                } else {
                    window.alert('No results found');
                }
            } else {
                window.alert('Geocoder failed due to: ' + status);
            }

        });
    }

    search() {
        this.AddressEvent(false);
        this.AddressEvent(true);
    }

    focusOriginAddress() {
        this.mapsAPILoader.load().then(() => {

            this.autocomplete = new google.maps.places.Autocomplete(this.searchOriginElementRef.nativeElement, {
                types: ["address"]
            });
            this.autocomplete.addListener("place_changed", () => {

                this.ngZone.run(() => {
                    let place = this.autocomplete.getPlace();
                    if (place.geometry === undefined || place.geometry === null) {
                        return;
                    }
                    //set latitude, longitude and zoom
                    this.latitude = place.geometry.location.lat();
                    this.longitude = place.geometry.location.lng();
                    this.origin = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() };
                    this.zoom = 15;

                    //this.setPlaceParam(place.address_components);
                });
            });
        });
    }

    focusDestinationAddress() {
        this.mapsAPILoader.load().then(() => {

            this.autocomplete = new google.maps.places.Autocomplete(this.searchDestinationElementRef.nativeElement, {
                types: ["address"]
            });
            this.autocomplete.addListener("place_changed", () => {

                this.ngZone.run(() => {
                    //get the place result

                    let place = this.autocomplete.getPlace();
                    //verify result
                    if (place.geometry === undefined || place.geometry === null) {
                        return;
                    }
                    //set latitude, longitude and zoom
                    this.latitude = place.geometry.location.lat();
                    this.longitude = place.geometry.location.lng();
                    this.destination = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() };
                    this.zoom = 15;

                    //this.setPlaceParam(place.address_components);
                });
            });
        });

    }

    change(event) {
        if (event) {
            this.travelingDetails = {
                distance: (event.routes[0].legs[0].distance.value)*0.001,
                duration: event.routes[0].legs[0].duration.text
            }
            this.calculateHoursAndMin(this.travelingDetails.duration);
        }
    }

    calculateHoursAndMin(timeItems) {
        var hours;
        var minutes;
        var totalMin = 0;
        var HoursAndMins = "";
        if (timeItems && timeItems.includes('hours')) {
            HoursAndMins = timeItems.split(' hours');
            hours = HoursAndMins[0].trim();
            if (HoursAndMins[1].includes('mins')) {
                minutes = HoursAndMins[1].split('mins');
                minutes = minutes[0].trim();
            } else if (HoursAndMins[1].includes('min')) {
                minutes = HoursAndMins[1].split('min');
                minutes = minutes[0].trim();
            }
            totalMin = (parseInt(hours) * 60) + parseInt(minutes);
            this.travelingDetails.duration = totalMin;
        } else if (timeItems && timeItems.includes('hour')) {
            HoursAndMins = timeItems.split(' hour');
            hours = HoursAndMins[0].trim();
            if (HoursAndMins[1].includes('mins')) {
                minutes = HoursAndMins[1].split('mins');
                minutes = minutes[0].trim();
            } else if (HoursAndMins[1].includes('min')) {
                minutes = HoursAndMins[1].split('min');
                minutes = minutes[0].trim();
            }
            totalMin = (parseInt(hours) * 60) + parseInt(minutes);
            this.travelingDetails.duration = totalMin;
        } else if (timeItems && timeItems.includes('mins')) {
            this.travelingDetails.duration = Number(timeItems.replace(/[^0-9.-]+/g, ""));
        } else if (timeItems && timeItems.includes('min')) {
            this.travelingDetails.duration = Number(timeItems.replace(/[^0-9.-]+/g, ""));
        }
    }

    Close() {
        this.dialogRef.close({
            travelingDetails: this.travelingDetails
        });
    }
}
