export const locale = {
    lang: 'en',
    data: {
        'TITLE': {
            'CustomerPagination': 'Antal log pr. side',
        },
        'FIELDS': {
            'SRNO': 'Sr no.',
            'Date': 'Date',
            'CoworkerName': 'Coworker',
            'OldStatus': 'Old status',
            'NewStatus': 'New status',
            'Description': 'Description',
            'Quiz': 'Quiz',
            'Not-Found-Quiz': 'Quiz not found',
            'ModuleType': 'Module type',
            "Title":'Title',
            "StartDate":'Start Date',
            "EndDate":'EndDate',
        },
        'Message': {
            'HideAnswer': 'Hide Answer'
        },
        'FILTERS': {
            'TEXTSEARCH': 'Search By Number',
        },
        'moduleTypeList': {
            'value0': 'All',
            'value1': 'Project',
            'value2': 'Offer'
        }
    }
};