import { Component, OnInit, ElementRef, NgZone, ViewChild } from '@angular/core';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatExpansionPanel, MatTabGroup, MatTab, MatTabHeader, MatSelect } from '@angular/material';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { ProjectService } from 'app/main/pages/project/project.service';
import { MaterialService } from 'app/main/pages/material/material.service';
import { CustomersService } from 'app/main/pages/customer/customer.service';
import { CoWorkersService } from 'app/main/pages/coWorker/coWorkers.service';
import { CommonService } from 'app/core/services/common.service';
import { ProjectRequisitionService } from 'app/main/pages/project/project.requisition.service';
import {
    Project, ProjectFile, projectCoWorker, ProjectMaterialQuantity, projectRequisition, ProjectRequisitionStatusLog,
    ProjectRequisitionStatusLogEnum, RequisitionStatusAnswer
} from 'app/main/model/Project';
import { RequestMaterial, RequestCustomer, RequestCaseTask, RequestCoWorker, RequestRequisition, SMSModules, RequestProjectMaterialCountForOrdered } from '../../../model/RequestBase';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { IsNumber, spaceOnlyValidator } from 'app/core/Validators/validation';
import { environment } from 'environments/environment';
import { AuthService } from 'app/core/services/auth.service';
import { Observable } from 'rxjs';
import { DatePipe, WeekDay } from '@angular/common';
import { RequestWorkHourGroupBy, RequestRequisitionBaseWorkHourGroupBy } from 'app/main/model/RequestBase';
import { ConvertTimeToHunders, ConvertHundersToTime } from 'app/core/Validators/validation';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatOption } from '@angular/material/core';
import { Material, QuickInvoiceMaterials } from 'app/main/model/Material';
import { ProjectIndoorOutDoorStatus, yesOrNoOptions, ReasonForProjectDropped, WhereDoesTheCustomerComeFrom, UrgentOrNot, monthList, projectMode } from '../../../model/ProjectStatus';
import { ProjectStatus } from 'app/constant/common.constant';
import { TitleService } from 'app/core/services/title.service';
import { DeleteAlertModalComponent } from 'app/main/pages/delete-alert-modal/delete-alert-modal.component';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';
import { GoogleMapDirectionComponent } from 'app/main/pages/google-map-direction/google-map-direction.component';
import { NewCustomerComponent } from 'app/main/pages/customer/new-customer/new-customer.component';
import { TextEditorComponent } from 'app/main/pages/text-editor/text-editor.component';
import { CoWorker } from 'app/main/model/CoWorker';
import { FormService } from 'app/core/services/form.service';
import { WeekDays } from 'app/main/model/WeekDays';
import { LogStatusModuleName } from 'app/core/enum/logFile.enum';
import { EmailSendingParam, ProjectInvoice } from 'app/main/model/common';
import { TextEditorEmailSendComponent } from 'app/main/shared-component/text-editor-email-send/text-editor-email-send.component';
import { postalcodeJson } from 'app/core/constant/PostalCodeConstant';
import 'rxjs/Rx';
import * as moment from 'moment';
import { CreateFolder, SearchFolder, ListFolder, FilesObj } from 'app/main/model/DropBox';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { ApiConstant } from 'app/core/constant/ApiConstant';
import { PdfViewerModelComponent } from 'app/main/pages/pdf-viewer/pdf-viewer.component';
import { CompanyInfoService } from 'app/main/pages/companyInfo/companyInfo.service';
import { WorkhoursService } from 'app/main/pages/workHour/workhours.service';
// import { projectStatusComponent } from '../component/projectStatus/projectStatus.component';
import { loadCldr } from "@syncfusion/ej2-base";
import { MaterialComponent } from '../component/material/material.component';
import { StartAndVisitDateComponent } from '../component/startAndVisitDate/startAndVisitDate.component';
import { MoveRequisitionComponent } from '../component/moveRequisition/moveRequisition.Component';
import { SMSModuleEnum } from 'app/core/enum/sms-module.enum';
import { SendSMSComponent } from 'app/main/shared-component/send-sms/send-sms.component';
import { RequisitionStatusModelComponent } from '../component/requisition-status-model/requisition-status-model.component';
import { AskRequisitionStatusAudienceTypeEnum } from 'app/core/enum/ask-requisition-status-audienceType.enum';
import { BilledStatusDateComponent } from '../component/billedStatusDate/billedStatusDate.component';
import { CoWorkerListComponent } from '../component/coWorkersList/coWorkerList.component';
import { PendingMaterialOrderListComponent } from '../component/pending-material-order/pending-material-order-list.component';
import { RequistionStatusComponent } from '../component/requistionStatus/requistionStatus.component';
import { MoveMaterialComponent } from '../component/move-material/move-material.component';
import { AnswerCategoryQuestionComponent } from 'app/main/shared-component/answer-category-question/answer-category-question.component';
import { AlertPlanningMessageModelComponent } from 'app/main/pages/alert-planning-message-model/alert-planning-message-model.component';
import { DirtyComponent } from 'app/core/directives/dirty-check';
import { ProfitHighlightComponent } from '../component/profit-highlight/profit-highlight.component';
import { DropboxFilesService } from 'app/main/shared-component/dropbox-files/dropbox-files.service';
import { TextareaEditorComponent } from 'app/main/pages/project-offer/component/offerV2-forms/component/textarea-editor/textarea-editor.component';
import { DriveModuleEnum } from 'app/core/enum/drive-module.enum';
import { Location } from '@angular/common';

declare var require: any;

loadCldr(
    require("cldr-data/main/de/numbers.json"),
    require("cldr-data/main/de/ca-gregorian.json"),
    require("cldr-data/supplemental/numberingSystems.json"),
    require("cldr-data/main/de/timeZoneNames.json"),
    require('cldr-data/supplemental/weekdata.json') // To load the culture based first day of week
);

@Component({
    selector: 'app-new-project',
    templateUrl: './new-project.component.html',
    styleUrls: ['./new-project.component.scss'],
    providers: [
        // The locale would typically be provided on the root module of your application. We do it at
        // the component level here, due to limitations of our example generation script.
        { provide: MAT_DATE_LOCALE, useValue: 'ja-JP' },

        // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
        // `MatMomentDateModule` in your applications root module. We provide it at the component level
        // here, due to limitations of our example generation script.
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    ],
})


export class NewProjectComponent implements OnInit, DirtyComponent {
    @ViewChild('projectTabs', { static: false }) private _projectTabs: MatTabGroup;
    @ViewChild('CustomerSearch', { static: false }) customerSearch: MatSelect;
    isDirty$: Observable<boolean>;
    isProjectWorkInfoChanged: boolean = false;

    startWeek: number = 1;
    ObjProject: Project;
    Files: ProjectFile[] = [];
    resultMaterial: any;
    resultCoWorker: any[] = [];
    savedata: any;
    Filename: string = "";
    Imagename: string = "";
    Materials: Material[] = [];
    projectMaterials: QuickInvoiceMaterials[] = [];
    Customers: any[];
    objMaterials: any;
    quantity: any = "0";
    quantitydecimal: any = "00";
    status: any = {};
    files: any = "";
    picture: any = "";
    fileData: any = "";
    projectForm: FormGroup;
    objaddproject: any = {};
    selectedIndex: number = 0;
    reqTabselectedIndex: number = 0;
    selectedProjectWorkHourIndex: number = 0;
    tabProjectWorkHourIndex: number = 0;
    //tabRequisitionWorkHourIndex: number = 0;
    tempLogStatusToSendEmail: any;
    //#region "customer autocomlete"
    myControl = new FormControl();
    myCoWorkerControl = new FormControl();
    arrFiles: any = {};
    filteredCustomerOptions: Observable<string[]>;
    filteredMaterialOptions: Observable<string[]>;
    filteredCoWorkerOptions: Observable<string[]>;
    IsDisplay: boolean = false;
    IsfileAdded: boolean = false;
    showSpinner: boolean = false;
    showLogTabSpinner: boolean = false;
    showRequisitionSpinner: boolean = false;
    IsDateValidate: boolean = true;
    IsDisplayWorkHour: boolean = false;
    displaySpecialInfoRed: boolean = false;
    displayChangeStatusToCoworker: boolean = false;
    //showDotAddWorkhoure: boolean = false;
    displayOffersTabRed: boolean = false;
    displayNoteTabRed: boolean = false;
    displayMtoMTabRed: boolean = false;
    //#endregion "customer autocomlete"
    projectFormTab1: FormGroup;
    projectFormTab2: FormGroup;
    projectFormTab3: FormGroup;
    projectFormTab4: FormGroup;
    projectFormTab5: FormGroup;
    RequisitionFormTab: FormGroup;
    coworkerForm: FormGroup;
    IsAddInActiveMaterial: boolean;
    InquiryId: string = "";
    matDatepicker: any;
    selectedStatus = new FormControl(2);
    isLoad: boolean = false;
    projectMaterialList: any = {};
    selectedCoworker: projectCoWorker;
    coWokers: any = []
    projectCoWorkerList: projectCoWorker[] = [];
    ShowAddNewRequisitionForm: boolean = false;
    showMessage: boolean = false;
    showMessageForCoworker: boolean = false;
    //new for project
    currentselectedMatObj: Material;
    currentselectedcoWorkerObj: any = {};
    projectMaterialQuantity: ProjectMaterialQuantity;
    isEdit: boolean = false;
    //IsEditProject: boolean = false;
    objAddProjectMaterial: QuickInvoiceMaterials;
    editedProjectMaterialObj: any = {};
    //isValid: boolean = false;
    roleName: string;
    name?: string = "";
    MaterialName?: string = "";
    CoWorkerName?: string = "";
    host: string = '';
    //displayCoworkerTab: boolean = true;
    selectStatus = ProjectStatus;
    projectStatusList = ProjectStatus;
    //statistic tab
    reasonsForProjectDropped = ReasonForProjectDropped;
    whereDoesTheCustomerComeFrom = WhereDoesTheCustomerComeFrom;
    materialTotalPrice: number = 0;
    materialPersonalTotalPrice: number = 0;
    materialTotalPriceWithVAT: number = 0;

    materialTotalPriceForEconomy: number = 0;
    materialPersonalTotalPriceForEconomy: number = 0;
    materialTotalPriceWithVATForEconomy: number = 0;

    selectIndoorOutDoorStatus = ProjectIndoorOutDoorStatus;
    yesOrNoOptions = yesOrNoOptions;
    IsAlowedToadd: boolean = true;
    // IsAdmin: boolean = false;
    // IsMaler: boolean = false;
    ResponsibleCoWorker: CoWorker;
    showrequisitionform: boolean = false;
    Expand: boolean = false;
    //#region "project Requisition"
    Requisition: projectRequisition;
    RequisitionList: any = [];
    //#endregion "project Requisition"
    panelOpenState = false;
    MaxReqNumber: number = 0;
    ProjectId: string = '';
    weekNumber: number;
    currentweek: number;
    disableRight: boolean = false;
    disableLeft: boolean = false;
    prevWeekEndday: any;
    NextWeekEndDay: any;
    weekDays: WeekDays[] = [];
    prevWeekStartDate: Date;
    nextWeekStartDate: Date;
    ProjectsCoWorkerWorkHour: any;// ProjectsCoWorkerWorkHour;
    currentWeekStartDate?: string;
    currentWeekEndDate?: string;
    ddlSelectedCustomerId: string;
    editedRequesitionId: string;
    @ViewChild('allSelected', { static: false }) private allSelected: MatOption;
    isDisableForMaler: boolean = false;
    grandTotalWorkhour: number = 0;
    materialQtySearch: number = 0;
    requisitionPlanning: any = [];

    //dropbox declaration start
    res: any;
    createFolder: CreateFolder;
    searchFolder: SearchFolder;
    listFolder: ListFolder
    renterForm: FormGroup;
    fileObjList: Array<FilesObj>;
    checkedList: any;
    fileFolderPathData: any = [];
    //dropbox declaration end

    projectRequisitionStatusLogList: ProjectRequisitionStatusLog[] = [];
    UrgentOrNot = UrgentOrNot;
    ProjectAllRequisitionCoworkers: any = [];
    SecurityOfficers: any = [];
    MaterialQuantity: number[];
    MaterialQuantity2000: number[];
    RequisitionIsActive: boolean = false;
    donotUpdateWorkHour: boolean = false;
    MtoMDescription: string = "";
    NoteDescription: string = "";
    OffersDescription: string = "";
    IfFromCustomerCustId: string;
    colorcode: string;
    DisplayMessageOnPersonal: boolean = false;
    ProjectCreatedName: string = "";
    lastSelectedCoworkerId: string = "";
    projectIsActive: boolean = true;
    requisitionStatus: number;
    paymentModeList = projectMode;
    ReqOfferPriceExclVATPercentage: number;
    RequisitionOnEditForEconomy: any;
    isRequisitionNumFromCust: boolean = false;
    @ViewChild('externalPdfViewer', { static: false }) public externalPdfViewer;

    MaterialQuantutyafterDecimal = [{ value: 0 }, { value: 25 }, { value: 50 }, { value: 75 }]

    YearList: any = [];
    StartYear: string = "1990";
    currentYear: any;
    year = "2019"
    monthList = monthList;
    dissableRightSideYear: boolean = true;
    isActiveForMaterial: boolean = false;
    displayPagination: boolean;
    logListPagination: any;
    invoice: any = {};
    requisitionInvoices: any = [];
    requisitionListForInvoices: any = [];
    isPrintInvoice: boolean = false;
    isPrintMultipleInvoice: boolean = false;
    tempObj: any = {};
    isStaffPriceOrStorePurchase: boolean = false;
    SecurityOfficer: string = "";
    EmployeeResponsible: string = "";
    previousIndex: number = 0;
    count: number = 0;
    offerId: string;
    ProjectFilePath: any;
    folderMatchLength: boolean = false;
    //requisitionPlanning: any = [];
    tempRequisitionStatus: any = "";
    projectLogId: string = "";
    customerEmailTemplate: any;
    UserName: string = "";
    totalPriceDiscount: number = 0;
    personalTotalPriceDiscount: number = 0;
    actualTotalPrice: number = 0;
    actualTotalPriceWithVAT: number = 0;
    actualPersonalTotalPrice: number = 0;
    statusMessageList: any = [];
    isFile: boolean = false;
    reqTabIndex: number = 0;
    isOpenBilledDate: boolean = false;
    requisitionStatusDisplay: boolean = true;
    purchasePriceTotal: number = 0;
    showMoreDetail: boolean = true;
    projectMaterialInOrderedCount: number = 0;
    projectMaterialListInOrdered: any = [];
    ProjectRequistionCoWorkerList: CoWorker[] = [];
    projectOffer: any = null;
    isDeleteSelectedMaterials: boolean = false;
    projectEmailCount: number = 0;
    attachmentFiles: any[] = [];
    roleClaims: any;
    hasPermision: boolean = false;
    isViewEconomy: boolean = false;
    confettiPieces: string[] = ['🤑', '💰', '💵', '💰', '🤑🎊', '💵', '💰', '💵', '🤑🥳', '🤑', '💵', '💰', '💵'];
    isWehaveProfit: boolean = false;
    getCustomer: any = "";
    requisitionNo: boolean = false;
    isRequisitionWorkhour: boolean = true;
    isInvalidMaterial: boolean = false;
    settingMasterValues: any = [];
    isShowPersonalPrice: boolean = false;
    constructor(private translationLoader: FuseTranslationLoaderService,
        private _route: ActivatedRoute,
        private _router: Router,
        private elem: ElementRef,
        private projectService: ProjectService,
        private materialService: MaterialService,
        private customersService: CustomersService,
        private coWorkersService: CoWorkersService,
        public companyInfoService: CompanyInfoService,
        public workhoursService: WorkhoursService,
        private formBuilder: FormBuilder,
        private datePipe: DatePipe,
        private authService: AuthService,
        public dialog: MatDialog,
        private adapter: DateAdapter<any>,
        private titleService: TitleService,
        private customerService: CustomersService,
        public _ngZone: NgZone,
        private notificationsService: NotificationsService,
        private translate: TranslateService,
        private projectRequisitionService: ProjectRequisitionService,
        private commonService: CommonService,
        private formService: FormService,
        private location: Location,
        private dropboxFilesService: DropboxFilesService,) {

        this.titleService.SetTitle("TITLE_PROJECT");
        this.translationLoader.loadTranslations(english, danish);
        window["projectComponent"] = { component: this, zone: this._ngZone };
        this.currentselectedMatObj = new Material();
        this.roleClaims = this.authService.getRoleClaimsList();
    }

    ngOnInit() {
        this.settingMasterValues = JSON.parse(localStorage.getItem("settingMasterValues")) || [];
        const tempMaterialOtherSettingList = this.settingMasterValues.find(s => s.key1 === "Material" && s.key2 === "OtherSetting");
        if (tempMaterialOtherSettingList && tempMaterialOtherSettingList.value) {
            const sessionVal = JSON.parse(tempMaterialOtherSettingList.value);
            if (sessionVal && sessionVal.IsShowPersonalPrice)
                this.isShowPersonalPrice = sessionVal.IsShowPersonalPrice;
        }
        this.authService.hasClaim(this.roleClaims.Project_Modify).subscribe(hasClaim => {
            if (hasClaim)
                this.hasPermision = hasClaim
            else
                this.hasPermision = false;
        });
        this.authService.hasClaim(this.roleClaims.Economy_View).subscribe(hasClaim => {
            if (hasClaim)
                this.isViewEconomy = hasClaim
            else
                this.isViewEconomy = false;
        });
        this.projectWorkInfoChange(false)
        this.GetCustomer(new RequestCustomer(), '');
        this.fileObjList = Array<FilesObj>();
        this.checkedList = new Array();
        this.Requisition = new projectRequisition();
        this.Requisition.excludePrice = 0;
        this.Requisition.status = 1;
        this.roleName = this.authService.getUserRole();
        this.currentYear = moment(new Date()).format('YYYY');
        this.Requisition.estimatedPrice = 0;
        this.Requisition.excludePrice = 0;

        this.ObjProject = new Project();
        this.ObjProject.vat = 0;
        this.ObjProject.includePrice = 0;

        this.projectMaterialQuantity = new ProjectMaterialQuantity();

        //super.RegisterEventEmitterForPage();
        this.selectedCoworker = new projectCoWorker();
        let id = this._route.snapshot.params.id;
        this._route.params.subscribe(params => {
            var id = params['id'];
            this.IfFromCustomerCustId = params['Custid'];
            this.InquiryId = params['InquiryId'];
            if (this.InquiryId)
                this.GetInquiryDetail(this.InquiryId);
            this.offerId = params['offerId'];
            if (this.IfFromCustomerCustId) {
                setTimeout(() => {
                    this.GetCustomerById(this.IfFromCustomerCustId);
                }, 1000);
            }

            if (id === undefined) {
                let ObjProject = new Project();
                ObjProject.sagId = null;
                ObjProject.sagNumber = 0;
                ObjProject.customerName = '';
                ObjProject.customerNumber = '';
                ObjProject.address1 = null;
                ObjProject.address2 = '';
                ObjProject.city = '';
                ObjProject.zipCode = '';
                ObjProject.comment = '';
                ObjProject.requisition = '';
                ObjProject.initialer = '';
                ObjProject.size = '';
                ObjProject.statusId = 0;
                ObjProject.varNummer = '';
                ObjProject.Picture = '';
                ObjProject.visit = null;
                //ObjProject.received = moment(new Date()).format("YYYY-MM-DD");
                ObjProject.received = moment(new Date()).format("DD.MM.YYYY");
                ObjProject.receivedTime = moment(new Date()).format("HH:mm");

                this.ObjProject = ObjProject;
                this.isLoad = true;
                setTimeout(() => {
                    this._projectTabs._handleClick = this.interceptTabChange.bind(this);
                }, 0);

                this.projectFormTab1 = this.formBuilder.group({
                    //address1: [null, Validators.compose([Validators.required])],
                    //city: [null, Validators.compose([Validators.required])],
                    //Zipcode: [null, Validators.compose([Validators.required])],
                    //phone: [null, Validators.compose([Validators.required])],
                    address1: [null, [Validators.required, spaceOnlyValidator.bind(this)]],
                    city: [null, [Validators.required, spaceOnlyValidator.bind(this)]],
                    Zipcode: [null, [Validators.required, spaceOnlyValidator.bind(this)]],
                    phone: [null, Validators.required],
                    IndoorOutdoorStatus: [null, Validators.required],
                    customerName: [null, Validators.required],
                    searchName: [null, !Validators.required],
                    startDate: [null, ''],
                    endDate: [null, ''],
                    Email: [null, [Validators.required, spaceOnlyValidator.bind(this), Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$")]],
                    visit: [null, ''],
                    received: [null, ''],
                    startTime: [null, ''],
                    endTime: [null, ''],
                    visitTime: [null, ''],
                    receivedTime: [null, ''],
                    colorcode: [null],
                    countryCode: [null]
                });
            }
            else {
                this._route.queryParams.subscribe(params => {
                    this.selectedIndex = params['tabIndex'] ? params['tabIndex'] : this.selectedIndex;
                    this.reqTabIndex = params['reqTabIndex'] ? parseInt(params['reqTabIndex']) : this.selectedIndex;
                });
                this.ProjectId = id;
                this.projectLogId = id;
                this.IsDisplay = false;
                this.Edit(id);
                this.GetDonotUpdateWorkHourDetail(this.ProjectId);
                this.projectFormTab1 = this.formBuilder.group({
                    //address1: [null, Validators.compose([Validators.required])],
                    //city: [null, Validators.compose([Validators.required])],
                    //Zipcode: [null, Validators.compose([Validators.required])],
                    //phone: [null, Validators.compose([Validators.required])],
                    address1: [null, [Validators.required, spaceOnlyValidator.bind(this)]],
                    city: [null, [Validators.required, spaceOnlyValidator.bind(this)]],
                    Zipcode: [null, [Validators.required, spaceOnlyValidator.bind(this)]],
                    phone: [null, Validators.required],
                    IndoorOutdoorStatus: [null, Validators.required],
                    customerName: [null, Validators.required],
                    searchName: [null, !Validators.required],
                    startDate: [null, ''],
                    endDate: [null, ''],
                    Email: [null, [Validators.required, spaceOnlyValidator.bind(this), Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$")]],
                    visit: [null, ''],
                    received: [null, ''],
                    startTime: [null, ''],
                    endTime: [null, ''],
                    visitTime: [null, ''],
                    receivedTime: [null, ''],
                    colorcode: [null],
                    countryCode: [null]
                });
            }
        });

        // this._route.queryParams.subscribe(params => {
        //     this.selectedIndex = params['tabIndex'] ? params['tabIndex'] : this.selectedIndex;
        // });
        this.objAddProjectMaterial = new QuickInvoiceMaterials();
        this.host = environment.url;
        this.adapter.setLocale('da');
        if (this.ObjProject)
            this.ObjProject.statusId = 1;
        this.dropdownList();

        this.getWeekNumber();
        this.getnetweekData(this.weekNumber);
        this.coworkerForm = this.formBuilder.group({
            userType: new FormControl('')
        });
        this.getYearsListUptoCurrentList();
        if (this._route.snapshot.params.isshourtcut) {
            this.getcurrenrtweekData();
            this.selectedIndex = 3;
        }

        this.RequisitionFormTab = this.formBuilder.group({
            opgaveText: [null, [Validators.required, spaceOnlyValidator.bind(this)]],
            requisition: [null, []],
        });
        this.getStatusMessage();
        if (this._route.snapshot.params.id)
            this.getProjectEmailCount();

    }

    GetDonotUpdateWorkHourDetail(projectId) {
        var modelRequisition = new RequestRequisition();
        modelRequisition.ProjectId = projectId;
        this.getRequisitionList(modelRequisition, false);
    }

    onSetDonotUpdateWorkhour() {
        this.Requisition.donotUpdateWorkHour = !this.Requisition.donotUpdateWorkHour;
        this.UpdateRequisition(this.Requisition);
    }

    getWeekNumber() {
        //For Week Number
        let d: any = new Date();
        d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
        // Set to nearest Thursday: current date + 4 - current day number
        // Make Sunday's day number 7
        d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
        // Get first day of year
        var yearStart: any = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
        // Calculate full weeks to nearest Thursday
        var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
        // Return array of year and week number]
        this.currentweek = weekNo;
        this.weekNumber = weekNo;
        return this.weekNumber;
    }

    CheckDate(date) {
        var currentDate = new Date().getTime();
        var passingDate = new Date(date).getTime();
        if (passingDate > currentDate)
            return false;
        return true;
    }

    DisableRight(weekNumber) {
        if (this.currentweek == weekNumber)
            return true;
        else
            return false;
    }

    getcurrenrtweekData() {
        this.startAndEndOfWeek(new Date());
    }

    getPrevweekData(currentWeekNumber) {
        currentWeekNumber = parseInt(currentWeekNumber);
        if (currentWeekNumber != 1) {
            this.weekNumber = currentWeekNumber - 1;
            this.disableRight = false;
            this.getPrevWeekDates(this.prevWeekEndday);
        }
        else {
            this.disableLeft = true;
        }
    }

    getnetweekData(currentWeekNumber) {
        currentWeekNumber = parseInt(currentWeekNumber);
        if (currentWeekNumber != this.currentweek) {
            this.weekNumber = currentWeekNumber + 1;
            this.disableLeft = false;

            this.getNextWeekDates(this.NextWeekEndDay);
        }
        else {
            this.disableRight = true;
        }
    }

    getPrevWeekDates(date) {
        this.startAndEndOfWeek(this.prevWeekStartDate);
    }

    getNextWeekDates(date) {
        this.startAndEndOfWeek(this.nextWeekStartDate);
    }

    setPrevWeekStartDate(date) {
        this.prevWeekStartDate = new Date(date.setDate(date.getDate() - date.getDay() - 6));
    }

    setNextWeekStartDate(date) {
        this.nextWeekStartDate = new Date(date.setDate(date.getDate() + 1));
    }

    startAndEndOfWeek(date) {
        this.weekDays = [];
        // If no date object supplied, use current date
        // Copy date so don't modify supplied date
        var now = date ? new Date(date) : new Date();

        // set time to some convenient value
        now.setHours(0, 0, 0, 0);
        var locale = "da";
        var selectedWeekStartDate = new Date(now);
        for (var i = 0; i < 7; i++) {
            selectedWeekStartDate.setDate(selectedWeekStartDate.getDate() - selectedWeekStartDate.getDay() + (i + 1));
            var day = new WeekDays();
            day.Date = this.datePipe.transform(selectedWeekStartDate, "yyyy-MM-dd");
            var month = selectedWeekStartDate.toLocaleString(locale, { month: "short" });
            month = month.charAt(0).toUpperCase() + month.substr(1);
            day.DateName = selectedWeekStartDate.getDate().toString();
            day.MonthName = month;
            day.Day = "WeekDays." + WeekDay[selectedWeekStartDate.getDay()];
            this.weekDays.push(day);
        }
        this.setPrevWeekStartDate(now);
        this.setNextWeekStartDate(selectedWeekStartDate);
        this.currentWeekStartDate = this.weekDays[0].Date;
        this.currentWeekEndDate = this.weekDays[this.weekDays.length - 1].Date;
        //this.setPrevWeekStartDate(now);
        //this.setNextWeekStartDate(selectedWeekStartDate);
        this.getWorkHours(new RequestWorkHourGroupBy());
        //this.getNonWorkHours(this.selectworker.id, new RequestWorkHourGroupBy());
    }

    getWorkHours(model: RequestWorkHourGroupBy) {
        model.StartDate = this.currentWeekStartDate;
        model.EndDate = this.currentWeekEndDate;
        model.ProjectId = this.ProjectId;
        this.projectService.GetProjectworkhours<ResponseBase>(model)
            .subscribe((response: ResponseBase) => {
                if (response) {
                    this.ProjectsCoWorkerWorkHour = response.result;
                    this.grandTotalWorkhour = 0;
                    for (var i = 0; i < this.ProjectsCoWorkerWorkHour.length; i++) {
                        var total = 0;
                        for (var k in this.ProjectsCoWorkerWorkHour[i].timingDateWise) {
                            if (this.ProjectsCoWorkerWorkHour[i].timingDateWise.hasOwnProperty(k)) {
                                total += ConvertTimeToHunders(this.ProjectsCoWorkerWorkHour[i].timingDateWise[k]);// parseFloat(this.ProjectsCoWorkerWorkHour[i].timingDateWise[k]);
                            }
                            this.IsDisplayWorkHour = true;
                        }
                        this.ProjectsCoWorkerWorkHour[i].totalTime = ConvertHundersToTime(total);
                        this.grandTotalWorkhour = this.grandTotalWorkhour + this.ProjectsCoWorkerWorkHour[i].totalTime;
                    }
                }
            },
                error => (err) => {
                    //this.showSpinner = false;
                },
                () => {
                    //this.showSpinner = false;
                });
    }

    getTotalWorkHoureForDateWise(date) {
        var total = 0;
        for (var i = 0; i < this.ProjectsCoWorkerWorkHour.length; i++) {
            if (this.ProjectsCoWorkerWorkHour[i].timingDateWise.hasOwnProperty(date)) {
                total += ConvertTimeToHunders(this.ProjectsCoWorkerWorkHour[i].timingDateWise[date]);
            }
        }
        if (total > 0) {
            return ConvertHundersToTime(total);
        }
        return '-';
    }

    calculareMin(min) {
        if (min) {
            var MIN = ConvertTimeToHunders(min);
            return MIN;
        }
    }

    getTotalWorkHoureForMonthWise(date) {
        var total = 0;
        for (var i = 0; i < this.ProjectsCoWorkerWorkHour.length; i++) {
            if (this.ProjectsCoWorkerWorkHour[i].timingMonthWise) {
                if (this.ProjectsCoWorkerWorkHour[i].timingMonthWise.hasOwnProperty(date.id)) {
                    total += ConvertTimeToHunders(this.ProjectsCoWorkerWorkHour[i].timingMonthWise[date.id]);
                }
            }
        }
        if (total > 0) {
            return ConvertHundersToTime(total);
        }
        return '-';
    }

    // work hour code for requisition based start
    getcurrenrtweekDataForReq() {
        this.startAndEndOfWeekForReq(new Date());
    }

    getPrevweekDataForReq(currentWeekNumber) {
        currentWeekNumber = parseInt(currentWeekNumber);
        if (currentWeekNumber != 1) {
            this.weekNumber = currentWeekNumber - 1;
            this.disableRight = false;
            this.getPrevWeekDatesForReq(this.prevWeekEndday);
        }
        else {
            this.disableLeft = true;
        }
    }

    getnetweekDataForReq(currentWeekNumber) {
        currentWeekNumber = parseInt(currentWeekNumber);
        if (currentWeekNumber != this.currentweek) {
            this.weekNumber = currentWeekNumber + 1;
            this.disableLeft = false;

            this.getNextWeekDatesForReq(this.NextWeekEndDay);
        }
        else {
            this.disableRight = true;
        }
    }

    getPrevWeekDatesForReq(date) {
        this.startAndEndOfWeekForReq(this.prevWeekStartDate);
    }

    getNextWeekDatesForReq(date) {
        this.startAndEndOfWeekForReq(this.nextWeekStartDate);
    }

    setPrevWeekStartDateForReq(date) {
        this.prevWeekStartDate = new Date(date.setDate(date.getDate() - date.getDay() - 6));
    }

    setNextWeekStartDateForReq(date) {
        this.nextWeekStartDate = new Date(date.setDate(date.getDate() + 1));
    }

    startAndEndOfWeekForReq(date) {
        this.weekDays = [];
        // If no date object supplied, use current date
        // Copy date so don't modify supplied date
        var now = date ? new Date(date) : new Date();

        // set time to some convenient value
        now.setHours(0, 0, 0, 0);
        var locale = "da";
        var selectedWeekStartDate = new Date(now);
        for (var i = 0; i < 7; i++) {
            selectedWeekStartDate.setDate(selectedWeekStartDate.getDate() - selectedWeekStartDate.getDay() + (i + 1));
            var day = new WeekDays();
            day.Date = this.datePipe.transform(selectedWeekStartDate, "yyyy-MM-dd");
            var month = selectedWeekStartDate.toLocaleString(locale, { month: "short" });
            month = month.charAt(0).toUpperCase() + month.substr(1);
            day.DateName = selectedWeekStartDate.getDate().toString();
            day.MonthName = month;
            day.Day = "WeekDays." + WeekDay[selectedWeekStartDate.getDay()];
            this.weekDays.push(day);
        }
        this.setPrevWeekStartDate(now);
        this.setNextWeekStartDate(selectedWeekStartDate);
        this.currentWeekStartDate = this.weekDays[0].Date;
        this.currentWeekEndDate = this.weekDays[this.weekDays.length - 1].Date;
        //this.setPrevWeekStartDate(now);
        //this.setNextWeekStartDate(selectedWeekStartDate);
        this.getWorkHoursForReq(new RequestRequisitionBaseWorkHourGroupBy());
        //this.getNonWorkHours(this.selectworker.id, new RequestWorkHourGroupBy());
    }

    getWorkHoursForReq(model: RequestRequisitionBaseWorkHourGroupBy) {
        model.StartDate = this.currentWeekStartDate;
        model.EndDate = this.currentWeekEndDate;
        model.ProjectId = this.ProjectId;
        model.RequisitionId = this.Requisition.id;
        this.projectService.GetRequisitionkhours<ResponseBase>(model)
            .subscribe((response: ResponseBase) => {
                if (response) {
                    this.ProjectsCoWorkerWorkHour = response.result;
                    this.grandTotalWorkhour = 0;
                    for (var i = 0; i < this.ProjectsCoWorkerWorkHour.length; i++) {
                        var total = 0;
                        for (var k in this.ProjectsCoWorkerWorkHour[i].timingDateWise) {
                            if (this.ProjectsCoWorkerWorkHour[i].timingDateWise.hasOwnProperty(k)) {
                                total += ConvertTimeToHunders(this.ProjectsCoWorkerWorkHour[i].timingDateWise[k]);// parseFloat(this.ProjectsCoWorkerWorkHour[i].timingDateWise[k]);
                            }
                            this.IsDisplayWorkHour = true;
                        }
                        this.ProjectsCoWorkerWorkHour[i].reqTotalTime = ConvertHundersToTime(total);
                        this.grandTotalWorkhour = this.grandTotalWorkhour + this.ProjectsCoWorkerWorkHour[i].reqTotalTime;
                    }
                }
            },
                error => (err) => {
                },
                () => {
                });
    }

    // work hour code for requisition based end
    dropdownList() {
        //if (this.ObjProject.sagId) {
        var reqModel = new RequestMaterial();
        if (this.hasPermision) {
            this.IsAddInActiveMaterial = true;
            reqModel.IsDeleted = false;
            reqModel.IsActive = false;
        }

        this.GetMaterial(reqModel);
        //this.GetMaterial(new RequestMaterial());

        let coWorker = new RequestCoWorker();
        coWorker.IsDeleted = false;
        var tempList = this.coWorkersService.getResponseForCoworker();
        if (tempList.length == 0)
            this.getCoWorkers(coWorker);
        else
            this.coWokers = tempList.filter(x => x.doNotShowOnPlanReminderProj == false);
        //this.getCoWorkers(coWorker);
        //}
        //this.GetCustomer(new RequestCustomer(), '');

    }
    //New Methods Implimented for CRUD
    save(moveTab) {
        this.formService.markFormGroupTouched(this.projectFormTab1);
        if (this.projectFormTab1.valid) {
            if (this.ObjProject.indoorOutdoorStatusId == "5" || this.ObjProject.indoorOutdoorStatusId == "6") {
                this.IsAddInActiveMaterial = true;
                var reqModel = new RequestMaterial();
                reqModel.IsDeleted = false;
                reqModel.IsActive = false;
                this.GetMaterial(reqModel);
            }
            if (this.ObjProject.sagId === null) {
                this.Add(moveTab);
            }
            else {
                this.Update(moveTab);
            }
        }
    }

    Add(moveTab) {
        this.showSpinner = true;
        this.ObjProject.inquiryId = this.InquiryId;
        this.ObjProject.offerID = this.offerId;
        if (this.ObjProject.startDate) {
            if (this.ObjProject.startTime)
                this.ObjProject.startDate = moment(moment(this.ObjProject.startDate).format("YYYY-MM-DD") + " " + this.ObjProject.startTime).format("YYYY-MM-DD HH:mm");
            else
                this.ObjProject.startDate = moment(this.ObjProject.startDate).format("YYYY-MM-DD");
        }
        else
            this.ObjProject.startDate = null;
        if (this.ObjProject.endDate) {
            if (this.ObjProject.endTime)
                this.ObjProject.endDate = moment(moment(this.ObjProject.endDate).format("YYYY-MM-DD") + " " + this.ObjProject.endTime).format("YYYY-MM-DD HH:mm");
            else
                this.ObjProject.endDate = moment(this.ObjProject.endDate).format("YYYY-MM-DD");
        }
        else
            this.ObjProject.endDate = null;
        if (this.ObjProject.visit) {
            if (this.ObjProject.visitTime)
                this.ObjProject.visit = moment(moment(this.ObjProject.visit).format("YYYY-MM-DD") + " " + this.ObjProject.visitTime).format("YYYY-MM-DD HH:mm");
            else
                this.ObjProject.visit = moment(this.ObjProject.visit).format("YYYY-MM-DD");
        }
        else
            this.ObjProject.visit = null;
        if (this.ObjProject.received) {
            var tempdate = moment(this.ObjProject.received, "DD.MM.YYYY").format("YYYY-MM-DD");
            if (this.ObjProject.receivedTime)
                this.ObjProject.received = moment(tempdate + " " + this.ObjProject.receivedTime).format("YYYY-MM-DD HH:mm");
            else
                this.ObjProject.received = tempdate;
        }
        else
            this.ObjProject.received = null;

        //this.ObjProject.sagId = null;
        this.ObjProject.createdBy = this.authService.getCoWorkerId();
        this.ObjProject.indoorOutdoorStatusId = "";
        this.ObjProject.indoorOutdoorStatusIds.filter(x => {
            this.ObjProject.indoorOutdoorStatusId = (this.ObjProject.indoorOutdoorStatusId ? this.ObjProject.indoorOutdoorStatusId + "," : "") + x.id;
        });
        this.projectService.Add<ResponseBase>(this.ObjProject)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.showSpinner = false;
                    this.ObjProject = response.result;
                    //this.createFolder(this.ObjProject.sagNumber);
                    this.setIndoorOutDoorDropDown();
                    this.ObjProject.sagId = response.result.sagId;
                    this.ProjectId = response.result.sagId;
                    this.projectLogId = response.result.sagId;
                    this.ObjProject.fileAssignmentId = response.result.sagOpgaveID;
                    this.ProjectCreatedName = this.authService.getUserName();
                    if (this.ObjProject.startDate) {
                        this.ObjProject.startTime = moment(this.ObjProject.startDate).format("HH:mm") == "00:00" ? "" : moment(this.ObjProject.startDate).format("HH:mm");
                        //this.ObjProject.startDate = moment.utc(this.ObjProject.startDate).format("YYYY-MM-DD");
                        this.ObjProject.startDate = this.ObjProject.startDate;
                    }
                    if (this.ObjProject.endDate) {
                        this.ObjProject.endTime = moment(this.ObjProject.endDate).format("HH:mm") == "00:00" ? "" : moment(this.ObjProject.endDate).format("HH:mm");
                        //this.ObjProject.endDate = moment.utc(this.ObjProject.endDate).format("YYYY-MM-DD");
                        this.ObjProject.endDate = this.ObjProject.endDate;
                    }
                    if (this.ObjProject.visit) {
                        this.ObjProject.visitTime = moment(this.ObjProject.visit).format("HH:mm") == "00:00" ? "" : moment(this.ObjProject.visit).format("HH:mm");
                        //this.ObjProject.visit = moment.utc(this.ObjProject.visit).format("YYYY-MM-DD");
                        this.ObjProject.visit = this.ObjProject.visit;
                    }
                    if (this.ObjProject.received) {
                        this.ObjProject.receivedTime = moment(this.ObjProject.received).format("HH:mm") == "00:00" ? "" : moment(this.ObjProject.received).format("HH:mm");
                        //this.ObjProject.received = moment.utc(this.ObjProject.received).format("YYYY-MM-DD");
                        this.ObjProject.received = this.ObjProject.received;
                    }
                    this.storeFileFolderPathData();
                    this.translate.get("MESSAGES.Created").subscribe({
                        next: (res) => {
                            this.notificationsService.create(res);
                        },
                        error: err => {
                        }
                    });
                    if (this.InquiryId) {
                        this.UpdateProjectToInquiry(this.InquiryId, this.ObjProject.sagId);
                        this.setAndUpdateRequisitionStatus();
                    }
                    this.projectOffer = { offerID: response.result.offerID, offerNumber: response.result.offerNumber };
                    //this.IsEditProject = true;
                },
                error: err => {
                    this.showSpinner = false;
                },
                complete: () => {
                    this.currentselectedcoWorkerObj.name = [];
                    this.currentselectedcoWorkerObj.id = [];
                    setTimeout(() => {
                        //this.selectedIndex = this.selectedIndex + 1;
                        if (this.ObjProject.indoorOutdoorStatusId == "5" || this.ObjProject.indoorOutdoorStatusId == "6") {
                            this.selectedIndex = 1;
                        }
                        this.GetProjectPrice(this.ObjProject.sagId);
                    }, 3000);
                    this.showSpinner = false;
                }
            });
    }

    Edit(id) {
        //this.IsEditProject = true;
        if (this.ObjProject.startDate) {
            if (this.ObjProject.startTime)
                this.ObjProject.startDate = moment(this.ObjProject.startDate + " " + this.ObjProject.startTime).format("YYYY-MM-DD HH:mm");
            else
                this.ObjProject.startDate = moment(this.ObjProject.startDate).format("YYYY-MM-DD");
        }
        else
            this.ObjProject.startDate = null;
        if (this.ObjProject.endDate) {
            if (this.ObjProject.endTime)
                this.ObjProject.endDate = moment(this.ObjProject.endDate + " " + this.ObjProject.endTime).format("YYYY-MM-DD HH:mm");
            else
                this.ObjProject.endDate = moment(this.ObjProject.endDate).format("YYYY-MM-DD");
        }
        else
            this.ObjProject.endDate = null;
        if (this.ObjProject.visit) {
            if (this.ObjProject.visitTime)
                this.ObjProject.visit = moment(this.ObjProject.visit + " " + this.ObjProject.visitTime).format("YYYY-MM-DD HH:mm");
            else
                this.ObjProject.visit = moment(this.ObjProject.visit).format("YYYY-MM-DD");
        }
        else
            this.ObjProject.visit = null;
        if (this.ObjProject.received) {
            if (this.ObjProject.receivedTime)
                this.ObjProject.received = moment(this.ObjProject.received + " " + this.ObjProject.receivedTime).format("YYYY-MM-DD HH:mm");
            else
                this.ObjProject.received = moment(this.ObjProject.received).format("YYYY-MM-DD");
        }
        else
            this.ObjProject.received = null;
        this.showSpinner = true;
        this.projectOffer = null;
        this.projectService.GetSingle<ResponseBase>(id)
            .subscribe({
                next: (response: ResponseBase) => {
                    if (response.result) {
                        this.ObjProject = response.result;
                        var staffPurchase = this.ObjProject.indoorOutdoorStatusId ? this.ObjProject.indoorOutdoorStatusId.includes("5") : "";
                        if (staffPurchase) {
                            this.isStaffPriceOrStorePurchase = true;
                        }
                        var storedPurchase = this.ObjProject.indoorOutdoorStatusId ? this.ObjProject.indoorOutdoorStatusId.includes("6") : "";
                        if (storedPurchase) {
                            this.isStaffPriceOrStorePurchase = true;
                        }
                        this.projectIsActive = this.ObjProject.isActive;
                        this.ProjectCreatedName = this.ObjProject.createdBy;
                        if (this.ObjProject.startDate) {
                            this.ObjProject.startTime = moment(this.ObjProject.startDate).format("HH:mm") == "00:00" ? "" : moment(this.ObjProject.startDate).format("HH:mm");
                            //this.ObjProject.startDate = moment.utc(this.ObjProject.startDate).format("YYYY-MM-DD");
                            this.ObjProject.startDate = this.ObjProject.startDate;
                        }
                        if (this.ObjProject.endDate) {
                            this.ObjProject.endTime = moment(this.ObjProject.endDate).format("HH:mm") == "00:00" ? "" : moment(this.ObjProject.endDate).format("HH:mm");
                            //this.ObjProject.endDate = moment.utc(this.ObjProject.endDate).format("YYYY-MM-DD");
                            this.ObjProject.endDate = this.ObjProject.endDate;
                        }
                        if (this.ObjProject.visit) {
                            this.ObjProject.visitTime = moment(this.ObjProject.visit).format("HH:mm") == "00:00" ? "" : moment(this.ObjProject.visit).format("HH:mm");
                            //this.ObjProject.visit = moment.utc(this.ObjProject.visit).format("YYYY-MM-DD");
                            this.ObjProject.visit = this.ObjProject.visit;
                        }
                        if (this.ObjProject.received) {
                            this.ObjProject.receivedTime = moment(this.ObjProject.received).format("HH:mm") == "00:00" ? "" : moment(this.ObjProject.received).format("HH:mm");
                            //this.ObjProject.received = moment.utc(this.ObjProject.received).format("YYYY-MM-DD");
                            this.ObjProject.received = this.ObjProject.received;
                        }
                        this.colorcode = response.result.colorcode;
                        this.projectOffer = { offerID: response.result.offerID, offerNumber: response.result.offerNumber };
                        this.setIndoorOutDoorDropDown();
                        this.getOfferstabData();
                        this.getNotetabData();
                        this.getMtoMtabData();
                        this.storeFileFolderPathData();
                    }
                    this.ObjProject.fileAssignmentId = response.result.sagOpgaveID;
                    this.adapter.setLocale('da');

                    this.isLoad = true;
                    setTimeout(() => {
                        this._projectTabs._handleClick = this.interceptTabChange.bind(this);
                    }, 0);
                },
                error: err => { },
                complete: () => {
                    this.showSpinner = false;
                    //this.projectService.getProjectFiles<ResponseBase>(this.ObjProject.sagId)
                    //  .subscribe({
                    //    next: (response: ResponseBase) => {
                    //      if (response) {
                    //        this.Files = response.result;
                    //      }
                    //      else {
                    //        this.Files = [];
                    //      }
                    //      this.showSpinner = false;
                    //    },
                    //    error: err => { },
                    //    complete: () => { }
                    //  });
                    //this.getProjectMaterialList(objMaterialProject);
                    //this.getprojectCoworkerList(objCoWorkerProject)

                    var modelRequisition = new RequestRequisition();
                    modelRequisition.ProjectId = id;
                    this.getRequisitionList(modelRequisition, false);
                }
            });
    }

    Update(moveTab) {
        this.showSpinner = true;
        this.ObjProject.isDocumentMoveToFinish = !this.ObjProject.isActive;
        if (this.ObjProject.startDate) {
            if (this.ObjProject.startTime)
                this.ObjProject.startDate = moment(moment(this.ObjProject.startDate).format("YYYY-MM-DD") + " " + this.ObjProject.startTime).format("YYYY-MM-DD HH:mm");
            else
                this.ObjProject.startDate = moment(this.ObjProject.startDate).format("YYYY-MM-DD");
        }
        else
            this.ObjProject.startDate = null;
        if (this.ObjProject.endDate) {
            if (this.ObjProject.endTime)
                this.ObjProject.endDate = moment(moment(this.ObjProject.endDate).format("YYYY-MM-DD") + " " + this.ObjProject.endTime).format("YYYY-MM-DD HH:mm");
            else
                this.ObjProject.endDate = moment(this.ObjProject.endDate).format("YYYY-MM-DD");
        }
        else
            this.ObjProject.endDate = null;
        if (this.ObjProject.visit) {
            if (this.ObjProject.visitTime)
                this.ObjProject.visit = moment(moment(this.ObjProject.visit).format("YYYY-MM-DD") + " " + this.ObjProject.visitTime).format("YYYY-MM-DD HH:mm");
            else
                this.ObjProject.visit = moment(this.ObjProject.visit).format("YYYY-MM-DD");
        }
        else
            this.ObjProject.visit = null;
        if (this.ObjProject.received) {
            if (this.ObjProject.receivedTime)
                this.ObjProject.received = moment(moment(this.ObjProject.received).format("YYYY-MM-DD") + " " + this.ObjProject.receivedTime).format("YYYY-MM-DD HH:mm");
            else
                this.ObjProject.received = moment(this.ObjProject.received).format("YYYY-MM-DD");
        }
        else
            this.ObjProject.received = null;
        this.ObjProject.indoorOutdoorStatusId = null;
        this.ObjProject.createdBy = this.authService.getCoWorkerId();
        this.ObjProject.indoorOutdoorStatusIds.filter(x => {
            this.ObjProject.indoorOutdoorStatusId = (this.ObjProject.indoorOutdoorStatusId ?
                this.ObjProject.indoorOutdoorStatusId + "," : null) + x.id;
        });
        this.ObjProject.indoorOutdoorStatusId = this.ObjProject.indoorOutdoorStatusId.toString();
        this.projectService.Update<ResponseBase>(this.ObjProject.sagId, this.ObjProject)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.updateBilledStatus();
                    this.ObjProject = response.result;
                    this.colorcode = response.result.colorcode;
                    this.projectIsActive = this.ObjProject.isActive;
                    this.setIndoorOutDoorDropDown();
                    this.ObjProject.sagId = response.result.sagId;
                    if (this.ObjProject.startDate) {
                        this.ObjProject.startTime = moment(this.ObjProject.startDate).format("HH:mm") == "00:00" ? "" : moment(this.ObjProject.startDate).format("HH:mm");
                        //this.ObjProject.startDate = moment.utc(this.ObjProject.startDate).format("YYYY-MM-DD");
                        this.ObjProject.startDate = this.ObjProject.startDate;
                    }
                    if (this.ObjProject.endDate) {
                        this.ObjProject.endTime = moment(this.ObjProject.endDate).format("HH:mm") == "00:00" ? "" : moment(this.ObjProject.endDate).format("HH:mm");
                        //this.ObjProject.endDate = moment.utc(this.ObjProject.endDate).format("YYYY-MM-DD");
                        //this.ObjProject.endDate = moment(this.ObjProject.endDate).format("DD.MM.YYYY");
                        this.ObjProject.endDate = this.ObjProject.endDate;
                    }
                    if (this.ObjProject.visit) {
                        this.ObjProject.visitTime = moment(this.ObjProject.visit).format("HH:mm") == "00:00" ? "" : moment(this.ObjProject.visit).format("HH:mm");
                        //this.ObjProject.visit = moment.utc(this.ObjProject.visit).format("YYYY-MM-DD");
                        this.ObjProject.visit = this.ObjProject.visit;
                    }
                    if (this.ObjProject.received) {
                        this.ObjProject.receivedTime = moment(this.ObjProject.received).format("HH:mm") == "00:00" ? "" : moment(this.ObjProject.received).format("HH:mm");
                        //this.ObjProject.received = moment.utc(this.ObjProject.received).format("YYYY-MM-DD");
                        this.ObjProject.received = this.ObjProject.received;
                    }

                    this.ObjProject.fileAssignmentId = response.result.sagOpgaveID;
                    var cust = this.Customers.find(c => c.id == this.ObjProject.customerId);
                    if (cust) {

                        cust.city = response.result.city;
                        cust.pinCode = response.result.zipCode;
                        cust.address = response.result.address1;
                        cust.phone = response.result.phone;
                        cust.email = response.result.email;
                    }
                    this.showSpinner = false;
                    if ((this.ObjProject.indoorOutdoorStatusId == "5" || this.ObjProject.indoorOutdoorStatusId == "6")
                        && moveTab) {
                        //this.selectedIndex = 1;
                        setTimeout(() => {
                            var model = new RequestRequisition();
                            model.IsDeleted = false;
                            model.ProjectId = this.ObjProject.sagId.toString();
                            this.getRequisitionList(model, true);
                            this.currentselectedcoWorkerObj.name = [];
                            this.currentselectedcoWorkerObj.id = [];
                        }, 1000);
                    }
                    this.storeFileFolderPathData();
                    setTimeout(() => {
                        this.translate.get("MESSAGES.Updated").subscribe({
                            next: (res) => {
                                this.notificationsService.create(res);
                            },
                            error: err => {
                            }
                        });
                    }, 50);
                },
                error: err => {
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });
                },
                complete: () => {
                    //if (this.selectedIndex == 4)
                    //  this._router.navigateByUrl('projects');
                    //else
                    this.selectedIndex == this.selectedIndex + 1;
                }
            });
    }

    OnlyUpdateProject() {
        this.showSpinner = true;
        this.ObjProject.isDocumentMoveToFinish = !this.ObjProject.isActive;
        if (this.ObjProject.startDate) {
            if (this.ObjProject.startTime)
                this.ObjProject.startDate = moment(moment(this.ObjProject.startDate).format("YYYY-MM-DD") + " " + this.ObjProject.startTime).format("YYYY-MM-DD HH:mm");
            else
                this.ObjProject.startDate = moment(this.ObjProject.startDate).format("YYYY-MM-DD");
        }
        else
            this.ObjProject.startDate = null;
        if (this.ObjProject.endDate) {
            if (this.ObjProject.endTime)
                this.ObjProject.endDate = moment(moment(this.ObjProject.endDate).format("YYYY-MM-DD") + " " + this.ObjProject.endTime).format("YYYY-MM-DD HH:mm");
            else
                this.ObjProject.endDate = moment(this.ObjProject.endDate).format("YYYY-MM-DD");
        }
        else
            this.ObjProject.endDate = null;
        if (this.ObjProject.visit) {
            if (this.ObjProject.visitTime)
                this.ObjProject.visit = moment(moment(this.ObjProject.visit).format("YYYY-MM-DD") + " " + this.ObjProject.visitTime).format("YYYY-MM-DD HH:mm");
            else
                this.ObjProject.visit = moment(this.ObjProject.visit).format("YYYY-MM-DD");
        }
        else
            this.ObjProject.visit = null;
        if (this.ObjProject.received) {
            if (this.ObjProject.receivedTime)
                this.ObjProject.received = moment(moment(this.ObjProject.received).format("YYYY-MM-DD") + " " + this.ObjProject.receivedTime).format("YYYY-MM-DD HH:mm");
            else
                this.ObjProject.received = moment(this.ObjProject.received).format("YYYY-MM-DD");
        }
        else
            this.ObjProject.received = null;
        this.ObjProject.indoorOutdoorStatusId = null;
        this.ObjProject.createdBy = this.authService.getCoWorkerId();
        this.ObjProject.indoorOutdoorStatusIds.filter(x => {
            this.ObjProject.indoorOutdoorStatusId = (this.ObjProject.indoorOutdoorStatusId ?
                this.ObjProject.indoorOutdoorStatusId + "," : null) + x.id;
        });
        this.ObjProject.indoorOutdoorStatusId = this.ObjProject.indoorOutdoorStatusId ? this.ObjProject.indoorOutdoorStatusId.toString() : this.ObjProject.indoorOutdoorStatusId;
        this.projectService.Update<ResponseBase>(this.ObjProject.sagId, this.ObjProject)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.ObjProject = response.result;
                    this.projectIsActive = this.ObjProject.isActive;
                    this.setIndoorOutDoorDropDown();
                    this.ObjProject.sagId = response.result.sagId;
                    if (this.ObjProject.startDate) {
                        this.ObjProject.startTime = moment(this.ObjProject.startDate).format("HH:mm") == "00:00" ? "" : moment(this.ObjProject.startDate).format("HH:mm");
                        this.ObjProject.startDate = this.ObjProject.startDate;
                    }
                    if (this.ObjProject.endDate) {
                        this.ObjProject.endTime = moment(this.ObjProject.endDate).format("HH:mm") == "00:00" ? "" : moment(this.ObjProject.endDate).format("HH:mm");
                        this.ObjProject.endDate = this.ObjProject.endDate;
                    }
                    if (this.ObjProject.visit) {
                        this.ObjProject.visitTime = moment(this.ObjProject.visit).format("HH:mm") == "00:00" ? "" : moment(this.ObjProject.visit).format("HH:mm");
                        this.ObjProject.visit = this.ObjProject.visit;
                    }
                    if (this.ObjProject.received) {
                        this.ObjProject.receivedTime = moment(this.ObjProject.received).format("HH:mm") == "00:00" ? "" : moment(this.ObjProject.received).format("HH:mm");
                        this.ObjProject.received = this.ObjProject.received;
                    }

                    this.ObjProject.fileAssignmentId = response.result.sagOpgaveID;
                    var cust = this.Customers.find(c => c.id == this.ObjProject.customerId);
                    if (cust) {

                        cust.city = response.result.city;
                        cust.pinCode = response.result.zipCode;
                        cust.address = response.result.address1;
                        cust.phone = response.result.phone;
                        cust.email = response.result.email;
                    }
                    this.showSpinner = false;
                    //if (this.ObjProject.indoorOutdoorStatusId == "5" || this.ObjProject.indoorOutdoorStatusId == "6") {
                    //  this.selectedIndex = 1;
                    //  setTimeout(() => {
                    //    var model = new RequestRequisition();
                    //    model.IsDeleted = false;
                    //    model.ProjectId = this.ObjProject.sagId.toString();
                    //    this.getRequisitionList(model, true);
                    //    this.currentselectedcoWorkerObj.name = [];
                    //    this.currentselectedcoWorkerObj.id = [];
                    //  }, 1000);
                    //}
                    this.translate.get("MESSAGES.Updated").subscribe({
                        next: (res) => {
                            this.notificationsService.create(res);
                        },
                        error: err => {
                        }
                    });
                    //if (this.ObjProject.indoorOutdoorStatusId.includes('5') || this.ObjProject.indoorOutdoorStatusId.includes('6')) {
                    //  this.RequisitionList[0].status = 8;
                    //  this.UpdateRequisition(this.RequisitionList[0]);

                    //}


                },
                error: err => {
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });
                },
                complete: () => {
                    this.selectedIndex == this.selectedIndex + 1;
                }
            });
    }

    changeIndoorOutdoorStatusId() {
        if (this.ObjProject.indoorOutdoorStatusId
            && (this.ObjProject.indoorOutdoorStatusId.includes('5') || this.ObjProject.indoorOutdoorStatusId.includes('6'))) {
            if (this.RequisitionList.length > 0) {
                this.RequisitionList[0].status = 8;
                this.UpdateRequisition(this.RequisitionList[0]);
            }
        }
    }

    UpdateProjectToInquiry(inquiryId, projectId) {
        this.projectService.UpdateProjectToInquiry<ResponseBase>(inquiryId, projectId)
            .subscribe((response: ResponseBase) => {
                if (response) {
                }
            },
                error => (err) => {
                });
    }

    setIndoorOutDoorDropDown() {
        this.ObjProject.indoorOutdoorStatusIds = new Array();
        if (this.ObjProject.indoorOutdoorStatusId) {
            if (this.ObjProject.indoorOutdoorStatusId.includes(',')) {
                var SplitIndoorOutdoorStatusId = this.ObjProject.indoorOutdoorStatusId.split(',');
                this.selectIndoorOutDoorStatus.filter(x => {
                    SplitIndoorOutdoorStatusId.filter(y => {
                        if (parseInt(y) == x.id)
                            this.ObjProject.indoorOutdoorStatusIds.push(x);
                    })
                });
            }
            else {
                this.selectIndoorOutDoorStatus.filter(x => {
                    if (parseInt(this.ObjProject.indoorOutdoorStatusId) == x.id)
                        this.ObjProject.indoorOutdoorStatusIds.push(x);
                });
            }
        }
    }

    //get customers
    GetCustomer(model, searchId) {
        model.Limit = 2000;
        model.OrderBy = "Text";
        this.customersService.Get<ResponseBase>(model)
            .subscribe((response: ResponseBase) => {
                if (response) {
                    //this.Customers = response.result;
                    this.getNextRequestForCustomer(response.pagination, model, response.result, searchId);
                }
                else {
                    this.Customers = [];
                }
            },
                error => (err) => {
                    this.Customers = [];
                });
    }

    getNextRequestForCustomer(pagination, model, response, searchId) {
        if (pagination.index == 1) {
            this.Customers = response;
        }
        else {
            this.Customers.push(...response);
        }
        model.Index = pagination.index + 1;
        model.Limit = pagination.limit;
        model.TotalRows = pagination.totalCount;
        var checkProjectId = this.Customers.find(x => x.id === this.ObjProject.customerId)
        this.requisitionNo = checkProjectId ? checkProjectId.requisitionNumber : '';
        if (pagination.totalCount > pagination.index * pagination.limit)
            this.GetCustomer(model, searchId);
        else if (searchId != '') {
            this.onChangeCustomer(searchId);
        }
        if (!this._route.snapshot.params.id) {
            setTimeout(() => {
                this.customerSearch.open();
            });
        }
    }

    GetCustomerById(selectedId) {
        if (selectedId) {
            this.customersService.GetCustomerById(selectedId).subscribe((respose: ResponseBase) => {
                if (respose) {
                    var data = respose.result;
                    this.ObjProject.customerId = data.id;
                    this.ObjProject.customerNumber = data.number;
                    this.ObjProject.customerName = data.name;
                    this.ObjProject.address1 = data.address;
                    this.ObjProject.address2 = data.address2;
                    this.ObjProject.zipCode = data.pinCode;
                    this.ObjProject.city = data.city;
                    this.ObjProject.phone = data.phone;
                    this.ObjProject.email = data.email ? data.email : data.emailInvoice;
                    this.ObjProject.countryCode = data.countryCode;
                    if (data.indoorOutdoorStatusId) {
                        if (!this.InquiryId) {
                            this.ObjProject.indoorOutdoorStatusId = data.indoorOutdoorStatusId;
                            this.setIndoorOutDoorDropDown();
                        }
                    }
                    else {
                        this.ObjProject.indoorOutdoorStatusId = "";
                        this.setIndoorOutDoorDropDown();
                    }
                    this.colorcode = data.colorcode;
                    if (data.personalePurchasing && this.ObjProject.sagId == null) {
                        this.ObjProject.indoorOutdoorStatusId = "5";
                        this.DisplayMessageOnPersonal = true;
                        this.setIndoorOutDoorDropDown();
                    }
                    else {
                        this.DisplayMessageOnPersonal = false;
                    }
                }
            });
        }
    }

    getNextRequestForMaterial(pagination, model, response) {
        if (pagination.index == 1) {
            this.Materials = response;
        }
        else {
            this.Materials.push(...response);
        }
        model.Index = pagination.index + 1;
        model.Limit = pagination.limit;
        model.TotalRows = pagination.totalCount;
        if (pagination.totalCount > pagination.index * pagination.limit)
            this.GetMaterial(model);
    }

    getNextRequestForCoWorker(pagination, model, response) {
        if (pagination.index == 1) {
            response = response.filter(x => x.doNotShowOnPlanReminderProj == false);
            this.coWokers = response;
            this.ProjectAllRequisitionCoworkers = response;
            this.SecurityOfficers = response;
        }
        else {
            response = response.filter(x => x.doNotShowOnPlanReminderProj == false);
            this.coWokers.push(...response);
            this.ProjectAllRequisitionCoworkers.push(...response);
            this.SecurityOfficers.push(...response);
        }
        model.Index = pagination.index + 1;
        model.Limit = pagination.limit;
        model.TotalRows = pagination.totalCount;
        if (pagination.totalCount > pagination.index * pagination.limit)
            this.getCoWorkers(model);
    }

    getTotalHoure(projectId) {
        var obj = this.ProjectsCoWorkerWorkHour.filter(o => o.id == projectId);
        var total = 0;
        if (!obj) {
            for (var i = 0; i < obj.timingDateWise.length; i++) {
                total += obj.timingDateWise[i]
            }

        }
        return total;
    }
    private _filter(value: string): string[] {
        //if (value != undefined) {
        const filterValue = value.toLowerCase();
        return this.Customers.filter(cust => cust.name.toLowerCase().includes(filterValue));
        //}
    }
    GetMaterial(model) {
        model.Limit = 400;
        model.OrderBy = "Text";
        this.materialService.Get<ResponseBase>(model)
            .subscribe((response: ResponseBase) => {
                if (response) {
                    this.getNextRequestForMaterial(response.pagination, model, response.result);
                    this.showMessage = this.projectMaterials.length > 0 ? false : true;
                    this.projectMaterials.filter(x => { x.isChecked = false; });
                }
                else {
                    this.Materials = [];
                }
            },
                error => (err) => {
                    this.Materials = [];
                },
                () => {
                });
    }

    getCoWorkers(model) {
        model.Limit = 400;
        model.OrderBy = "Name";
        this.coWorkersService.Get<ResponseBase>(model)
            .subscribe((response: ResponseBase) => {
                if (response) {
                    //this.coWokers = response.result;
                    this.getNextRequestForCoWorker(response.pagination, model, response.result);
                    this.showMessageForCoworker = this.projectCoWorkerList.length > 0 ? false : true;
                }
                else {
                    this.coWokers = [];
                    //this.SecurityOfficers = [];
                }
            },
                error => (err) => {
                    this.coWokers = [];
                    //this.SecurityOfficers = [];
                },
                () => {
                });
    }

    // private _filterCoworker(value: string): string[] {
    //     const filterValue = value.toLowerCase();
    //     return this.coWokers.filter(coworker => coworker.name.toLowerCase().includes(filterValue));
    // }

    //Exist Code
    ResponsibleforSecurity = [
        { value: '0', viewValue: 'Jazz' },
        { value: '1', viewValue: 'Pedru' },
        { value: '2', viewValue: 'Cristina' }
    ];

    _handleReaderLoaded(file, readerEvt) {

        var binaryString = readerEvt.target.result;
        let arrayFileName = file.name.split('.');
        let fileType = arrayFileName.slice(-1);
        let fileName = arrayFileName.slice(0, -1);


        this.arrFiles = {
            'type': fileType[0],
            'value': btoa(binaryString),
            'name': fileName[0],
        };
    }

    OnuploadPicture(event) {
        var files = event.target.files;
        var file = files[0];
        this.Imagename = file.name;
        this.IsDisplay = true;

        if (files && file) {
            var reader = new FileReader();
            reader.onload = this._picturehandleReaderLoaded.bind(this);
            reader.readAsBinaryString(file);
        }
    }

    _picturehandleReaderLoaded(readerEvt) {
        var binaryString = readerEvt.target.result;
        this.ObjProject.Picture = btoa(binaryString)
    }

    DropDownSearch(value) {
        if (value) {
            var request = new RequestCustomer();
            request.Name = value;
            try {
                if (!isNaN(Number(value.toString()))) {
                    request.Name = "";
                    request.Name = value;
                }
            } catch (e) {

            }
            this.GetCustomer(request, '');
        } else {
            this.GetCustomer(new RequestCustomer(), '');
        }
    }
    GetCustomersOnClear(value) {
        if (!value && this.selectedIndex == 0) {
            this.DropDownSearch(value)
        }
    }

    onChangeCustomer(selectedCustomer) {
        //this.lastSelectedCoworkerId &&
        if (this.IfFromCustomerCustId) {
            selectedCustomer = this.IfFromCustomerCustId;
        }
        if ((this.lastSelectedCoworkerId != selectedCustomer)
            || (this.lastSelectedCoworkerId == undefined && selectedCustomer)) {
            //this.showSpinner = true;
            if (this.Customers) {
                var data = this.Customers.find(c => c.id == selectedCustomer);
                if (data) {
                    this.ObjProject.customerId = data.id;
                    this.ObjProject.customerNumber = data.number;
                    this.ObjProject.customerName = data.name;
                    this.ObjProject.address1 = data.address;
                    this.ObjProject.address2 = data.address2;
                    this.ObjProject.zipCode = data.pinCode;
                    this.ObjProject.city = data.city;
                    this.ObjProject.phone = data.phone;
                    this.ObjProject.email = data.email;
                    this.ObjProject.colorcode = data.colorcode;
                    this.ObjProject.countryCode = data.countryCode;
                    this.Requisition.discountPercent = data.discountPercent;
                    this.Requisition.discount = data.discount;
                    this.isRequisitionNumFromCust = data.requisitionNumber;
                    if (this.isRequisitionNumFromCust) {
                        this.translate.get("MESSAGES.CustomerRequisitionNumber").subscribe({
                            next: (res) => {
                                this.notificationsService.info(res);
                            },
                            error: err => {
                            }
                        });
                    }
                    if (data.indoorOutdoorStatusId) {
                        this.ObjProject.indoorOutdoorStatusId = data.indoorOutdoorStatusId;
                        this.setIndoorOutDoorDropDown();
                    }
                    else {
                        this.ObjProject.indoorOutdoorStatusId = "";
                        this.setIndoorOutDoorDropDown();
                    }
                    this.colorcode = data.colorcode;
                    if (data.personalePurchasing && this.ObjProject.sagId == null) {
                        this.ObjProject.indoorOutdoorStatusId = "5";
                        this.DisplayMessageOnPersonal = true;
                        this.setIndoorOutDoorDropDown();
                    }
                    else {
                        this.DisplayMessageOnPersonal = false;
                    }
                }
            }
            else {
                this.ObjProject.customerId = selectedCustomer;
            }
        }
        this.lastSelectedCoworkerId = selectedCustomer;
        this.GetCustomer(new RequestCustomer(), '');
    }

    requisitionIsActiveOnChange(active) {
        var model = new RequestRequisition();
        model.IsDeleted = false;
        model.ProjectId = this.ObjProject.sagId.toString();
        model.IsActive = !active;
        this.getRequisitionList(model, true);
    }

    TabChange(event) {
        // if (this.selectedIndex == 0 && this.roleName != "Maler" && this.roleName != "Gaest") {
        //     this.save(false);
        // }
        this.selectedIndex = event.index;
        if (this.selectedIndex == 1) {
            var reqBilled = this.RequisitionList.filter(o => o.status == 10);
            var model = new RequestRequisition();
            model.IsDeleted = false;
            model.ProjectId = this.ObjProject.sagId.toString();
            model.IsActive = true;
            if (reqBilled.length > 0) {
                model.IsActive = false;
                this.RequisitionIsActive = true;
            }
            else if (!this.ObjProject.isActive) {
                model.IsActive = false;
                this.RequisitionIsActive = true;
            }
            else {
                var settingMasterValues = Object.assign([], JSON.parse(localStorage.getItem("settingMasterValues")));
                if (settingMasterValues && settingMasterValues.length > 0) {
                    var tempSettingObject = settingMasterValues.find(s => s.key1 == "Project" && s.key2 == "SearchSettings");
                    if (tempSettingObject) {
                        var result = JSON.parse(tempSettingObject.value);
                        this.RequisitionIsActive = result.isShowInactiveRquisitionDefault;
                        model.IsActive = !result.isShowInactiveRquisitionDefault;
                    }
                }
            }

            const isGotoMaterial = this.RequisitionList.length == 1 ? true : false;
            if (this.ObjProject.indoorOutdoorStatusId == "5" || this.ObjProject.indoorOutdoorStatusId == "6") {
                this.IsAddInActiveMaterial = true;
                var reqModel = new RequestMaterial();
                reqModel.IsDeleted = false;
                reqModel.IsActive = false;
                this.GetMaterial(reqModel);
                this.getRequisitionList(model, isGotoMaterial);
                //this.getRequisitionList(model, true);
            }
            else {
                this.getRequisitionList(model, isGotoMaterial);
                this.currentselectedcoWorkerObj.name = [];
                this.currentselectedcoWorkerObj.id = [];
            }
        }


        else if (this.selectedIndex == 2) {
            this.storeFileFolderPathData();
        }

        else if (this.selectedIndex == 3) {
            this.getWeekNumber();
            //this.getnetweekData(this.weekNumber);
            //this.getcurrenrtweekData();
            this.ProjectsCoWorkerWorkHour = [];
            this.tabProjectWorkHourIndex = 1;
        }

        else if (this.selectedIndex == 5) {
            var model = new RequestRequisition();
            model.IsDeleted = false;
            model.ProjectId = this.ObjProject.sagId.toString();
            this.getRequisitionList(model, false);
        }

        // else if (this.selectedIndex == 6) {
        //     this.getMtoMtabData();
        // }
        else if (this.selectedIndex == 6) {
            this.getNotetabData();
        }
        else if (this.selectedIndex == 7) {
            this.getOfferstabData();
        }
        else if (this.selectedIndex == 8) {

        }
        else if (this.selectedIndex == 9) {

        }

        else if (this.selectedIndex == 10) {

        }
        //if (this.selectedIndex == 1) {
        //  setTimeout(function () {
        //    that.getProjectReqisitionForInvoice();
        //  }, 1000)
        //}
    }


    /**
     * MtoM, Offer, Notes start
     */

    getMtoMtabData() {
        this.showSpinner = true;
        this.projectService.GetProjectRequisition<any>(this.ObjProject.sagId, ApiConstant.RequisitionMtoM).subscribe({
            next: (response) => {
                this.showSpinner = false;
                this.MtoMDescription = response.result.description;
                if (this.MtoMDescription != null && this.MtoMDescription.trim() != "") {
                    this.displayMtoMTabRed = true;
                }
                else {
                    this.displayMtoMTabRed = false;
                }
            },
            error: err => {
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            }
        });
    }

    getOfferstabData() {
        this.showSpinner = true;
        this.projectService.GetProjectRequisition<any>(this.ObjProject.sagId, ApiConstant.RequisitionOffers).subscribe({
            next: (response) => {
                this.showSpinner = false;
                this.OffersDescription = response.result.description;
                if (this.OffersDescription != null && this.OffersDescription.trim() != "") {
                    this.displayOffersTabRed = true;
                }
                else {
                    this.displayOffersTabRed = false;
                }
            },
            error: err => {
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            }
        });
    }

    getNotetabData() {
        this.showSpinner = true;
        this.projectService.GetProjectRequisition<any>(this.ObjProject.sagId, ApiConstant.RequisitionNote).subscribe({
            next: (response) => {
                this.showSpinner = false;
                this.NoteDescription = response.result.description;
                if (this.NoteDescription != null && this.NoteDescription.trim() != "") {
                    this.displayNoteTabRed = true;
                }
                else {
                    this.displayNoteTabRed = false;
                }
            },
            error: err => {
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            }
        });
    }

    isDisplayMtoMTabRed(event) {
        this.displayMtoMTabRed = event;
    }
    isDisplayNoteTabRed(event) {
        this.displayNoteTabRed = event;
    }
    isDisplayOffersTabRed(event) {
        this.displayOffersTabRed = event;
    }

    getRejectedStatusRequisitions() {
        var model = new RequestRequisition();
        model.IsDeleted = true;
        model.ProjectId = this.ObjProject.sagId.toString();
        this.getRequisitionList(model, false);
    }

    getDeletedRequsitions() {
        this.showSpinner = true;
        var model = new RequestRequisition();
        model.IsDeleted = true;
        model.ProjectId = this.ObjProject.sagId.toString();
        this.getRequisitionList(model, false);
        this.setEditModeForStatisticRequsiitions();
    }

    setEditModeForStatisticRequsiitions() {

        this.RequisitionList.filter(r => { r.showStatisticEditData = false })
        this.showSpinner = false;
    }

    ReqTabChange(event, id) {
        this.previousIndex = this.reqTabselectedIndex;
        this.Requisition.reqTabselectedIndex = event.index;
        this.reqTabselectedIndex = event.index;
        if (id == this.Requisition.id) {
            this.ReqTabValueChange();
        }
    }
    //After checking condition on Tab Change
    ReqTabValueChange() {
        // if (this.reqTabselectedIndex == 1) {
        //     setTimeout(() => {    //<<<---    using ()=> syntax
        //         if (this.projectCoWorkerList.length > 0) {
        //             var firstEmp = this.projectCoWorkerList[0];
        //             this.Requisition.securityOfficerID = firstEmp.coworkerId;
        //             this.Requisition.employeeResponsibleID = firstEmp.coworkerId;
        //         }
        //     }, 1000);
        // }
        if (this.reqTabselectedIndex == 1 || this.reqTabselectedIndex == 2) {
            if (this.reqTabselectedIndex == 2) {
                let objCoWorkerProject = new RequestCoWorker();
                objCoWorkerProject.IsDeleted = false;
                objCoWorkerProject.ProjectId = this.ObjProject.sagId;
                objCoWorkerProject.fileAssignmentId = this.editedRequesitionId;
                this.currentselectedcoWorkerObj.id = [];
                this.getprojectCoworkerList(objCoWorkerProject)
                this.calculateProjMaterialPriceTotalForEconomy();
            }
            // if (this.reqTabselectedIndex == 5) {
            //     this.calculateProjMaterialPriceTotalForEconomy();

            // }
            if (this.reqTabselectedIndex == 1) {
                this.getTotalEtstimeteOrPrice();
            }
            // this.ChangeEstimatePrice();
            // this.ChangePrice();
            this.ShowCalculatedValuesForEconomy();
        }
        else if (this.reqTabselectedIndex == 3 || this.reqTabselectedIndex == 4) {
            this.getWeekNumber();
            //this.getnetweekData(this.weekNumber);
            //this.getcurrenrtweekDataForReq();
            //this.tabRequisitionWorkHourIndex = 1;
            this.RequisitionWorkHourTab();
        }
        // if (this.previousIndex == 2) {
        //     this.economyNotificationForExcludePrice();
        // }
        // if ((this.previousIndex == 0 || this.previousIndex == 1 || this.previousIndex == 5) && this.reqTabselectedIndex != 5
        //   && this.roleName != "Maler" && this.roleName != "Gaest") {
        //   setTimeout(() => {
        //     this.saveRequisition();
        //   }, 1000);
        // }
        if (this.Requisition.status == 6 && !this.Requisition.startDate) {
            this.translate.get("MESSAGES.SpecialInfoStartDate").subscribe({
                next: (res) => {
                    this.notificationsService.alert(res);
                    this.getWarningTone();
                },
                error: err => {
                }
            });
        }
    }

    ProjectWorkHourTab(event) {
        this.tabProjectWorkHourIndex = event.index;
        if (event.index == 0) {
            var mod = new RequestWorkHourGroupBy();
            this.getWorkHours(mod);
        }
        else if (event.index == 1) {
            var model = new RequestWorkHourGroupBy();
            model.StartDate = this.currentYear + "-01-01";
            model.EndDate = this.currentYear + "-12-31";
            this.GetProjectMonthWiseWorkHour(model);
        }
    }

    RequisitionWorkHourTab() {
        if (this.reqTabselectedIndex == 3)
            this.startAndEndOfWeekForReq(new Date());
        else if (this.reqTabselectedIndex == 4) {
            this.ProjectsCoWorkerWorkHour = [];
            var model = new RequestRequisitionBaseWorkHourGroupBy();
            model.StartDate = this.currentYear + "-01-01";
            model.EndDate = this.currentYear + "-12-31";
            this.GetRequisitionMonthWiseWorkHour(model);
        }
    }

    GetProjectMonthWiseWorkHour(model: RequestWorkHourGroupBy) {
        model.ProjectId = this.ProjectId;
        this.projectService.GetProjectMonthWiseworkhours<ResponseBase>(model)
            .subscribe((response: ResponseBase) => {
                if (response) {
                    this.ProjectsCoWorkerWorkHour = response.result;
                    this.grandTotalWorkhour = 0;
                    for (var i = 0; i < this.ProjectsCoWorkerWorkHour.length; i++) {
                        var total = 0;
                        for (var k in this.ProjectsCoWorkerWorkHour[i].timingDateWise) {
                            if (this.ProjectsCoWorkerWorkHour[i].timingDateWise.hasOwnProperty(k)) {
                                total += ConvertTimeToHunders(this.ProjectsCoWorkerWorkHour[i].timingDateWise[k]);// parseFloat(this.ProjectsCoWorkerWorkHour[i].timingDateWise[k]);
                            }
                            this.IsDisplayWorkHour = true;
                        }
                        this.ProjectsCoWorkerWorkHour[i].totalTime = ConvertHundersToTime(total);
                        this.grandTotalWorkhour = this.grandTotalWorkhour + this.ProjectsCoWorkerWorkHour[i].totalTime;
                    }
                }
            },
                error => (err) => {
                    //this.showSpinner = false;
                },
                () => {
                    //this.showSpinner = false;
                });
    }

    GetRequisitionMonthWiseWorkHour(model: RequestRequisitionBaseWorkHourGroupBy) {
        model.ProjectId = this.ProjectId;
        model.RequisitionId = this.Requisition.id;
        this.projectService.GetRequisitionkMontWisehours<ResponseBase>(model)
            .subscribe((response: ResponseBase) => {
                if (response) {
                    this.ProjectsCoWorkerWorkHour = response.result;
                    this.grandTotalWorkhour = 0;
                    this.isRequisitionWorkhour = false;
                    for (var i = 0; i < this.ProjectsCoWorkerWorkHour.length; i++) {
                        var total = 0;
                        for (var k in this.ProjectsCoWorkerWorkHour[i].timingDateWise) {
                            if (this.ProjectsCoWorkerWorkHour[i].timingDateWise.hasOwnProperty(k)) {
                                total += ConvertTimeToHunders(this.ProjectsCoWorkerWorkHour[i].timingDateWise[k]);// parseFloat(this.ProjectsCoWorkerWorkHour[i].timingDateWise[k]);
                            }
                            this.IsDisplayWorkHour = true;
                        }
                        this.ProjectsCoWorkerWorkHour[i].reqTotalTime = ConvertHundersToTime(total);
                        this.grandTotalWorkhour = this.grandTotalWorkhour + this.ProjectsCoWorkerWorkHour[i].reqTotalTime;
                    }
                }
            },
                error => (err) => {
                    //this.showSpinner = false;
                },
                () => {
                    //this.showSpinner = false;
                });
    }

    calculateMonthWiseHour(month, workerId) {
        if (this.tabProjectWorkHourIndex == 1) {
            var total = 0;
            var obj = this.ProjectsCoWorkerWorkHour.find(o => o.id == workerId);
            if (obj != null) {
                var monthObj = obj.timingDateWise.find(p => new Date(p).getMonth == month);
                for (var k in monthObj) {
                    if (monthObj.hasOwnProperty(k)) {
                        total += ConvertTimeToHunders(monthObj.timingDateWise[k]);
                    }
                }
            }
            return total;
        }
    }

    isNumber(evt) {

        return IsNumber(evt);

    }

    onUpdateStartDate(e) {
        var endDate = this.ObjProject.endDate;
        if (endDate) {
            var endDateTime = new Date(endDate).getTime();
            var startDateTime = new Date(e).getTime();
            if (endDateTime < startDateTime)
                this.ObjProject.endDate = e;
        }
        //else
        //  this.ObjProject.endDate = e;
    }

    onUpdateReqStartDate(e) {
        var endDate = this.Requisition.endDate;
        if (endDate) {
            var endDateTime = new Date(endDate).getTime();
            var startDateTime = new Date(e).getTime();
            if (endDateTime < startDateTime)
                this.Requisition.endDate = e;
        }
        //else
        //  this.Requisition.endDate = e;
    }

    //onUpdateVisitDate(e) {
    //  this.ObjProject.visit = e;
    //}
    refreshMaterialList() {
        let objMaterialProject = new RequestCaseTask();
        objMaterialProject.fileAssignmentId = this.Requisition.id;
        objMaterialProject.IsDeleted = false;
        objMaterialProject.OrderBy = "ModifyDate DESC"
        this.editedProjectMaterialObj = {};
        this.getProjectMaterialList(objMaterialProject);
        this.getTotalEtstimeteOrPrice();
        // this.ChangePrice();
        // this.ChangeEstimatePrice();
        this.refreshProjectMaterialForOrdered();
    }

    getProjectMaterialList(modal) {
        this.showSpinner = true;
        this.projectService.GetProjectMaterialList<ResponseBase>(modal)
            .subscribe((response: ResponseBase) => {
                this.showSpinner = false;
                if (response) {
                    this.projectMaterials = response.result;
                    this.projectMaterials.filter(p => {
                        if (p.quantity) {
                            p.quantity = parseFloat(p.quantity.toFixed(2));
                        }
                        if (!p.noStockTraek) {
                            p.noStockTraek = false;
                        }
                        if (p.unit == undefined || p.unit == null) {
                            if (!(p.material == undefined || p.material == null)) {
                                p.unit = p.material.unit || ''
                            } else {
                                p.unit = ''
                            }
                        }
                        if (p.isRequiredText == undefined || p.isRequiredText == null) {
                            if (!(p.material == undefined || p.material == null)) {
                                p.isRequiredText = p.material.isRequiredText || false
                            } else {
                                p.isRequiredText = false
                            }
                        }
                    });
                    this.materialTotalPrice = 0;
                    this.materialTotalPriceWithVAT = 0;
                    this.materialPersonalTotalPrice = 0;
                    this.purchasePriceTotal = 0;
                    this.calculateProjMaterialPriceTotal();
                    this.calculateProjMaterialPriceTotalForEconomy();
                    setTimeout(() => {
                        this.ShowProjectInvoice();
                        this.ShowCalculatedValuesForEconomy();
                    }, 500);
                    this.showMessage = this.projectMaterials.length > 0 ? false : true;
                    this.calMaterialCreation();
                }
                else {
                    this.showMessage = true;
                }
            },
                error => (err) => {
                    this.showSpinner = false;
                },
                () => {
                    this.showSpinner = false;
                    //this.getWorkHoursTotal();
                });
    }

    onChangeMaterial(evt, mat) {
        this.showSpinner = true;
        this.currentselectedMatObj = new Material();
        this.currentselectedMatObj.id = mat;
        this.projectService.GetSingleMaterial<ResponseBase>(mat)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.showSpinner = false;
                    this.projectMaterialQuantity = new ProjectMaterialQuantity();
                    this.currentselectedMatObj.unit = response.result.unit;
                    this.projectMaterialQuantity.retailPrice = response.result.retailPrice;
                    this.projectMaterialQuantity.personalPris = response.result.personalPrice;
                    this.projectMaterialQuantity.quantity = response.result.purchaseQuantity;
                    //this.projectMaterialQuantity.text = response.result.text;
                    this.projectMaterialQuantity.colorCode = response.result.colorCode != null ? response.result.colorCode : "";
                },
                error: err => {
                    this.showSpinner = false;
                    this.currentselectedMatObj.unit = 'stk.';
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });
                },
                complete: () => {
                    this.showSpinner = false;
                }
            });
    }

    onSetActiveMaterial(active) {
        this.isActiveForMaterial = active;
        let objMaterialProject = new RequestCaseTask();
        objMaterialProject.fileAssignmentId = this.Requisition.id;
        objMaterialProject.IsDeleted = false;
        objMaterialProject.OrderBy = "ModifyDate DESC"
        this.getProjectMaterialList(objMaterialProject);
        this.refreshProjectMaterialForOrdered();
    }

    AddNewMaterials() {
        var sendData = {};
        sendData = {
            'RequisitionId': this.Requisition.id,
            'sagId': this.ObjProject.sagId,
            'roleName': this.roleName,
            'indoorOutdoorStatusIds': this.ObjProject.indoorOutdoorStatusIds,
            'editedProjectMaterialObj': this.editedProjectMaterialObj,
            'projectMaterials': this.projectMaterials,
            'ObjProject': this.ObjProject,
            'Requisition': this.Requisition,
            'materialTotalPrice': this.materialTotalPrice,
            'materialTotalPriceWithVAT': this.materialTotalPriceWithVAT,
            'materialPersonalTotalPrice': this.materialPersonalTotalPrice,
            'discountPercent': this.Requisition.discountPercent,
            'discount': this.Requisition.discount,
            'purchasePriceTotal': this.purchasePriceTotal
        }

        var dialogRef = this.dialog.open(MaterialComponent, {
            height: '90%',
            width: '90%',
            disableClose: true,
            data: sendData
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.refreshMaterialList();
                setTimeout(() => {
                    //this.calculateOfferPriceExclVAT();
                    this.Requisition.materialCreation = result.materialCreation;
                    this.UpdateRequisition(this.Requisition);
                }, 2000);
            }
            this.titleService.SetTitle("TITLE_PROJECT");
        });
    }

    calculateProjMaterialPriceTotal() {
        this.materialTotalPriceWithVAT = 0;
        this.purchasePriceTotal = 0;

        this.isInvalidMaterial = false;
        this.projectMaterials.forEach((e: any) => {
            if (!e.totalPrice) {
                e.totalPrice = this.getTotalPrice((e.quantity ? e.quantity : 0), e.retailPrice);
            }
            if (!e.personalPriceTotal) {
                e.personalPriceTotal = this.getTotalPrice((e.quantity ? e.quantity : 0), e.personalPrice);
            }
            this.materialTotalPrice += e.totalPrice;
            if (e.personalPrice && e.personalPrice != 0) {
                this.materialPersonalTotalPrice += e.personalPriceTotal;
            }
            else {
                e.personalPrice = 0;
            }

            if (e.totalPriceVAT) {
                this.materialTotalPriceWithVAT += e.totalPriceVAT;
            } else {
                var vat = (e.totalPrice * 25) / 100;
                this.materialTotalPriceWithVAT += (e.totalPrice + vat);
            }
            if (e.purchasePrice) {
                this.purchasePriceTotal += e.purchasePrice;
            }

            if (!this.commonService.isDefined(e.retailPrice) && !this.isInvalidMaterial)
                this.isInvalidMaterial = true;
        });
        this.calculateOfferPriceExclVAT();
    }

    calculateProjMaterialPriceTotalForEconomy() {
        this.materialTotalPriceForEconomy = 0;
        this.materialPersonalTotalPriceForEconomy = 0;
        this.materialTotalPriceWithVATForEconomy = 0;
        this.projectMaterials.forEach((e: any) => {
            let materialsForTheCase = true;
            if (e.material && !e.material.materialsForTheCase)
                materialsForTheCase = !e.material.materialsForTheCase
            if (materialsForTheCase) {
                if (!e.totalPrice) {
                    e.totalPrice = this.getTotalPrice((e.quantity ? e.quantity : 0), e.retailPrice);
                }
                if (!e.personalPriceTotal) {
                    e.personalPriceTotal = this.getTotalPrice((e.quantity ? e.quantity : 0), e.personalPrice);
                }
                this.materialTotalPriceForEconomy += e.totalPrice;
                if (e.personalPrice && e.personalPrice != 0) {
                    this.materialPersonalTotalPriceForEconomy += e.personalPriceTotal;
                }
                else {
                    e.personalPrice = 0;
                }

                if (e.totalPriceVAT) {
                    this.materialTotalPriceWithVATForEconomy += e.totalPriceVAT;
                } else {
                    var vat = (e.totalPrice * 25) / 100;
                    this.materialTotalPriceWithVATForEconomy += (e.totalPrice + vat);
                }
            }
        });
    }

    removeProjectMaterial(id, isUpDownQty) {
        this.showSpinner = true;
        var obj = [];
        var listOfstr = id.split(",");
        for (var i = 0; i < listOfstr.length; i++) {
            var newObj = {
                "id": listOfstr[i],
                "isUpDownStock": isUpDownQty
            }
            obj.push(newObj);
        }
        this.projectService.deleteProjectMaterialMultiple<ResponseBase>(obj)
            .subscribe({
                next: (response: ResponseBase) => {
                    //this.projectMaterials.splice(this.projectMaterials.findIndex(m => m.id == id), 1);
                    //this.projectMaterials = this.projectMaterials.filter(m => m.id != id);
                    this.projectMaterials = this.projectMaterials.filter(item => !id.split(',').includes(item.id))
                    this.showMessage = this.projectMaterials.length > 0 ? false : true;
                    this.showSpinner = false;
                    this.currentselectedMatObj = new Material();
                    this.projectMaterialQuantity.quantity = 0.00
                    this.isEdit = false;

                    this.materialTotalPrice = 0;
                    this.materialTotalPriceWithVAT = 0;
                    this.materialPersonalTotalPrice = 0;
                    this.calculateProjMaterialPriceTotal();
                    setTimeout(() => {
                        this.calculateOfferPriceExclVAT();
                        this.calMaterialCreation();
                        this.UpdateRequisition(this.Requisition);
                    }, 500);
                    this.translate.get("MESSAGES.Deleted").subscribe({
                        next: (res) => {
                            this.notificationsService.create(res);
                        },
                        error: err => {
                        }
                    });
                    this.onSetCheckedMaterial();
                },
                error: err => {
                    this.showSpinner = false;

                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });
                },
                complete: () => {

                }
            });
    }


    emailToCustomerByProject(projectId) {
        var emailSendingParam = new EmailSendingParam();
        emailSendingParam.fromName = this.authService.getUserName();
        // emailSendingParam.from = "post@vibesmalerfirma.dk";
        emailSendingParam.to = this.ObjProject.email;
        emailSendingParam.moduleName = "Project";
        emailSendingParam.moduleID = this.ProjectId;
        emailSendingParam.isLoadDefaultEmailTemplate = true;
        emailSendingParam.attachmentFiles = this.attachmentFiles;
        var addressDetail = {
            address: this.ObjProject.address1 ? this.ObjProject.address1 : "",
            zipCode: this.ObjProject.zipCode ? this.ObjProject.zipCode : "",
            city: this.ObjProject.city ? this.ObjProject.city : "",
        }
        emailSendingParam.subject = JSON.stringify(this.ObjProject.sagNumber) + " " + this.ObjProject.customerName + " " + addressDetail.address + " " + addressDetail.zipCode + " " + addressDetail.city;
        emailSendingParam.coworkerList = this.coWokers;
        const dialogRef = this.dialog.open(TextEditorEmailSendComponent, {
            height: '90%',
            width: '90%',
            data: emailSendingParam
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result != null && result != '') {
                this.showSpinner = true;
                var tempLogStatusToSendEmail = new EmailSendingParam();
                tempLogStatusToSendEmail = result;
                var ProjectRequisitionStatusLogModel = new ProjectRequisitionStatusLog();
                ProjectRequisitionStatusLogModel.projectId = this.ObjProject.sagId.toString();
                ProjectRequisitionStatusLogModel.requisitionId = this.Requisition.id;
                ProjectRequisitionStatusLogModel.status = LogStatusModuleName.Project;
                ProjectRequisitionStatusLogModel.requisitionNumber = this.Requisition.number;
                ProjectRequisitionStatusLogModel.createdBy = this.authService.getCoWorkerId();
                ProjectRequisitionStatusLogModel.StatusLogCaseValue = ProjectRequisitionStatusLogEnum.EmailSentFromProject;

                tempLogStatusToSendEmail.ProjectRequisitionStatusLogModel = ProjectRequisitionStatusLogModel;
                this.projectService.SendEmail<ResponseBase>(tempLogStatusToSendEmail)
                    .subscribe({
                        next: (response: ResponseBase) => {
                            this.showSpinner = false;
                            this.translate.get("MESSAGES.EmailSent").subscribe({
                                next: (res) => {
                                    this.notificationsService.create(res);
                                },
                                error: err => {
                                }
                            });
                            this.commonService.checkEmailIsToBeSent();

                        },
                        error: err => {
                            this.showSpinner = false;
                            this.translate.get("MESSAGES.Failed").subscribe({
                                next: (res) => {
                                    this.notificationsService.error(res);
                                },
                                error: err => {
                                }
                            });

                        },
                        complete: () => {
                            this.showSpinner = false;
                        }
                    });
            }
        });
    }

    editProjectMaterial(id) {
        this.isEdit = true;
        this.showSpinner = true;
        this.currentselectedMatObj = new Material();
        this.projectService.editProjectMaterial<ResponseBase>(id)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.showSpinner = false;
                    //var result = response.result.quantity.toFixed(2)
                    //var quantity = result.toString().split('.');

                    this.editedProjectMaterialObj = response.result;
                    this.editedProjectMaterialObj.isRequiredText = response.result.material.isRequiredText;
                    this.editedProjectMaterialObj.unit = response.result.material.unit;
                    this.currentselectedMatObj = response.result.material;
                    //this.currentselectedMatObj.name = this.editedProjectMaterialObj.material.text;
                    if (response.result.quantity) {
                        this.projectMaterialQuantity.quantity = response.result.quantity.toFixed(2);
                    }
                    // this.projectMaterialQuantity.qtyDecimal = Number(quantity[1]);
                    this.projectMaterialQuantity.text = response.result.text;
                    this.projectMaterialQuantity.colorCode = response.result.colorCode;
                    this.projectMaterialQuantity.retailPrice = response.result.retailPrice;
                    this.projectMaterialQuantity.personalPris = response.result.personalPris ? response.result.personalPris : 0.00;
                    this.showSpinner = false;
                    if (this.editedProjectMaterialObj) {
                        this.AddNewMaterials()
                    }
                },
                error: err => {
                    this.showSpinner = false;
                },
                complete: () => {

                }
            });
    }

    AddNewCoWorker() {
        // if (!this.Requisition.estimatedPrice) {
        //     if ((!this.Requisition.excludePrice && !this.Requisition.isHourlyWage)) {
        //         this.translate.get("MESSAGES.ExcludePriceNotification").subscribe({
        //             next: (res) => {
        //                 this.notificationsService.alert(res);
        //                 this.getWarningTone();
        //             },
        //             error: err => {
        //             }
        //         });
        //         return;
        //     }
        // }
        let isExist: boolean = false;
        if (this.ObjProject.sagId) {
            let objcoWorker = new RequestCoWorker();
            objcoWorker.IsDeleted = false;

            this.selectedCoworker.id = null;
            this.selectedCoworker.projectId = this.ObjProject.sagId;
            this.selectedCoworker.coworkerId = "";
            this.selectedCoworker.fileAssignmentId = this.Requisition.id;
            this.selectedCoworker.coworkerList = this.currentselectedcoWorkerObj.id;
            this.selectedCoworker.ProjectNummer = this.ObjProject.sagNumber;
            this.selectedCoworker.ProjectRequisitionNummer = this.Requisition.number;
            this.selectedCoworker.AddAllRequisition = false;

            this.projectService.addCoWorkerToProject<ResponseBase>(this.selectedCoworker)
                .subscribe((response: ResponseBase) => {
                    this.currentselectedcoWorkerObj.name = [];
                    this.currentselectedcoWorkerObj.id = [];

                    let objCoWorkerProject = new RequestCoWorker();
                    objCoWorkerProject.IsDeleted = false;
                    objCoWorkerProject.ProjectId = this.ObjProject.sagId;
                    objCoWorkerProject.fileAssignmentId = this.editedRequesitionId;
                    this.getprojectCoworkerList(objCoWorkerProject)
                    this.translate.get("MESSAGES.Created").subscribe({
                        next: (res) => {
                            this.notificationsService.create(res);
                        },
                        error: err => {
                        }
                    });
                },
                    error => (err) => {
                        this.translate.get("MESSAGES.Failed").subscribe({
                            next: (res) => {
                                this.notificationsService.error(res);
                            },
                            error: err => {
                            }
                        });

                    }, () => {

                    });
        }
    }

    showDirection() {
        //open popup
        const dialogRef = this.dialog.open(GoogleMapDirectionComponent, {
            width: '80%',
            height: '80%',
            data: {
                Address: this.Requisition.departmentAddress,
                ZipCode: this.Requisition.departmentZipCode,
                City: this.Requisition.departmentCity
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
            }
        });


    }

    AddNewCoWorkerToAllRequisition() {
        if (!this.Requisition.estimatedPrice) {
            if ((!this.Requisition.excludePrice && !this.Requisition.isHourlyWage)) {
                this.translate.get("MESSAGES.ExcludePriceNotification").subscribe({
                    next: (res) => {
                        this.notificationsService.alert(res);
                        this.getWarningTone();
                    },
                    error: err => {
                    }
                });
                return;
            }
        }
        //open popup
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: '200px',
            data: { Header: 'Headers.AllRequisitionCoworker' }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                let isExist: boolean = false;
                if (this.ObjProject.sagId) {
                    let objcoWorker = new RequestCoWorker();
                    objcoWorker.IsDeleted = false;

                    this.selectedCoworker.id = null;
                    this.selectedCoworker.projectId = this.ObjProject.sagId;
                    this.selectedCoworker.coworkerId = "";
                    this.selectedCoworker.fileAssignmentId = this.Requisition.id;
                    this.selectedCoworker.coworkerList = this.currentselectedcoWorkerObj.id;
                    this.selectedCoworker.ProjectNummer = this.ObjProject.sagNumber;
                    this.selectedCoworker.ProjectRequisitionNummer = this.Requisition.number;
                    this.selectedCoworker.AddAllRequisition = true;

                    this.projectService.addCoWorkerToProject<ResponseBase>(this.selectedCoworker)
                        .subscribe((response: ResponseBase) => {
                            this.currentselectedcoWorkerObj.name = [];
                            this.currentselectedcoWorkerObj.id = [];

                            let objCoWorkerProject = new RequestCoWorker();
                            objCoWorkerProject.IsDeleted = false;
                            objCoWorkerProject.ProjectId = this.ObjProject.sagId;
                            objCoWorkerProject.fileAssignmentId = this.editedRequesitionId;
                            this.getprojectCoworkerList(objCoWorkerProject)
                        },
                            error => (err) => {
                            }, () => {

                            });
                }
            }
        });


    }
    // ChangeRequisitionStatus() {
    //     //this.reqTabselectedIndex = 2;
    //     this.Requisition.reqTabselectedIndex = 2;
    // }

    getprojectCoworkerList(modal) {
        this.projectService.GetProjectCoWorkerList<ResponseBase>(modal)
            .subscribe((response: ResponseBase) => {
                if (response) {
                    this.projectCoWorkerList = response.result
                    this.showMessageForCoworker = this.projectCoWorkerList.length > 0 ? false : true;
                }
                else {
                    this.showMessageForCoworker = true;
                }
            },
                error => (err) => {
                },
                () => {
                });
    }

    removeProjectCoWorker(coworker) {
        this.showSpinner = true;
        this.projectService.removeProjectCoWorker<ResponseBase>(coworker)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.showSpinner = false;
                    this.projectCoWorkerList = this.projectCoWorkerList.filter(o => o.id != coworker.id);
                    this.currentselectedcoWorkerObj.id = this.currentselectedcoWorkerObj.id.filter(o => o != coworker.id);
                    this.showMessageForCoworker = this.projectCoWorkerList.length > 0 ? false : true;


                    this.translate.get("MESSAGES.Deleted").subscribe({
                        next: (res) => {
                            this.notificationsService.create(res);
                        },
                        error: err => {
                        }
                    });

                },
                error: err => {
                    this.showSpinner = false;
                    this.notificationsService.error(err.error.message);
                    //this.translate.get("MESSAGES.Failed").subscribe({
                    //  next: (res) => {
                    //    this.notificationsService.error(res);
                    //  },
                    //  error: err => {
                    //  }
                    //});

                },
                complete: () => {

                }
            });
    }

    removeAllProjectCoWorker(projectId, coworkerId) {
        this.showSpinner = true;
        this.projectService.removeAllProjectCoWorker<ResponseBase>(projectId, coworkerId)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.showSpinner = false;
                    this.projectCoWorkerList = this.projectCoWorkerList.filter(o => o.coworkerId != coworkerId);
                    this.currentselectedcoWorkerObj.id = this.currentselectedcoWorkerObj.id.filter(o => o != coworkerId);
                    this.showMessageForCoworker = this.projectCoWorkerList.length > 0 ? false : true;
                    this.translate.get("MESSAGES.Deleted").subscribe({
                        next: (res) => {
                            this.notificationsService.create(res);
                        },
                        error: err => {
                        }
                    });

                },
                error: err => {
                    this.showSpinner = false;
                    //this.notificationsService.error(err.error.message);
                    //this.translate.get("MESSAGES.Failed").subscribe({
                    //  next: (res) => {
                    //    this.notificationsService.error(res);
                    //  },
                    //  error: err => {
                    //  }
                    //});
                },
                complete: () => {

                }
            });
    }

    ChangePrice() {
        this.Requisition.includePrice = Number((this.Requisition.excludePrice + ((Number(this.Requisition.excludePrice) * 25) / 100)).toFixed(2));//.toLocaleString('en-DA', { style: 'currency', currency: 'DKK' });
        this.Requisition.vatPrice = Number((Number(this.Requisition.excludePrice) * 25) / 100);//.toLocaleString('en-DA', { style: 'currency', currency: 'DKK' });
        this.getTotalEtstimeteOrPrice();
        if (this.Requisition.excludePrice > 0) {
            this.Requisition.suggestedHours = Number((this.Requisition.excludePrice / this.Requisition.coworkerWorkingRate).toFixed(2)); //Number(((this.Requisition.excludePrice - this.materialTotalPrice) / 517.50).toFixed(2));
            this.Requisition.estimatedTime = this.Requisition.suggestedHours;
        }
        else if (this.Requisition.estimatedPrice > 0) {
            this.Requisition.suggestedHours = Number((this.Requisition.estimatedPrice / this.Requisition.coworkerWorkingRate).toFixed(2));  //Number(((this.Requisition.estimatedPrice - this.materialTotalPrice) / 517.50).toFixed(2));
            this.Requisition.estimatedTime = this.Requisition.suggestedHours;
        }
        else if (!this.Requisition.estimatedPrice || !this.Requisition.excludePrice) {
            this.Requisition.suggestedHours = 0;
            this.Requisition.estimatedTime = 0;
        }
        this.setRequisitionNoOfDays();
    }

    ChangeIncludePrice() {
        this.Requisition.excludePrice = Number(((Number(this.Requisition.includePrice)) / 1.25).toFixed(2));
        this.ChangePrice();
    }
    ChangeEstimatePrice() {
        this.Requisition.includeEstimatePrice = Number((this.Requisition.estimatedPrice + ((Number(this.Requisition.estimatedPrice) * 25) / 100)));//.toLocaleString('en-DA', { style: 'currency', currency: 'DKK' });
        this.Requisition.vatEstimatePrice = Number((Number(this.Requisition.estimatedPrice) * 25) / 100);//.toLocaleString('en-DA', { style: 'currency', currency: 'DKK' });
        this.getTotalEtstimeteOrPrice();
        if (this.Requisition.estimatedPrice > 0) {
            this.Requisition.suggestedHours = Number((this.Requisition.estimatedPrice / this.Requisition.coworkerWorkingRate).toFixed(2)); //Number(((this.Requisition.estimatedPrice - this.materialTotalPrice) / 517.50).toFixed(2));
            this.Requisition.estimatedTime = this.Requisition.suggestedHours;
        }
        else if (this.Requisition.excludePrice > 0) {
            this.Requisition.suggestedHours = Number((this.Requisition.excludePrice / this.Requisition.coworkerWorkingRate).toFixed(2)); //Number(((this.Requisition.excludePrice - this.materialTotalPrice) / 517.50).toFixed(2));
            this.Requisition.estimatedTime = this.Requisition.suggestedHours;
        }
        else if (!this.Requisition.estimatedPrice || !this.Requisition.excludePrice) {
            this.Requisition.suggestedHours = 0;
            this.Requisition.estimatedTime = 0;
        }
        this.setRequisitionNoOfDays();
    }
    // isValidForm() {
    //     return this.isValid;
    // }

    onSetActive(IsActive) {
        this.ObjProject.isActive = IsActive;
        this.save(true);
    }


    onSetRequisitionActive(IsActive, id) {
        this.Requisition.isActive = IsActive;
        if (this.requisitionPlanning.length > 0) {
            var tempDate = new Date((new Date().setHours(0, 0, 0, 0)));
            var reqPlanObj = this.requisitionPlanning.filter(o => (new Date(o.date) >= tempDate));
            if (reqPlanObj.length > 0) {
                this.translate.get("MESSAGES.InactiveRequisitionPlaniingMessage").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                        this.commonService.playFailedTone();
                    },
                    error: err => {
                    }
                });
                setTimeout(() => {
                    this.Requisition.isActive = !this.Requisition.isActive;
                });
            }
        }
        else {
            if (id) {
                this.Requisition = this.RequisitionList.find(x => x.id == id);
                if (this.Requisition.opgaveText) {
                    this.Requisition.isActive = IsActive;
                    if (this.Requisition.id)
                        this.UpdateRequisition(this.Requisition);
                }
            } else {
                this.AddRequisition(this.Requisition);
            }
        }
    }

    DropDownSearchMaterial(value) {
        var request = new RequestMaterial();
        request.Text = value;
        request.IsActive = !this.IsAddInActiveMaterial;
        this.GetMaterial(request);
    }

    onKeyPressCheckNumber(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 44 && charCode != 46) {
            return false;
        }
        return true;
    }

    // DropDownSearchCoWorker(value) {
    //     if (value != null && value != "") {
    //         var request = new RequestCoWorker();
    //         request.Name = value;
    //         this.getCoWorkers(request);
    //     } else {
    //         let coWorker = new RequestCoWorker();
    //         coWorker.IsDeleted = false;
    //         this.getCoWorkers(coWorker);
    //     }
    // }
    // GetCoworkerOnClear(CoWorkerName) {
    //     if (!CoWorkerName) {
    //         this.DropDownSearchCoWorker(CoWorkerName);
    //     }
    // }

    AddCustomer() {
        if (this.RequisitionList && this.RequisitionList.length > 0) {
            const dialogRef = this.dialog.open(AlertMessageModelComponent, {
                height: '200px',
                data: { Header: 'Headers.CopyCustomerDetails' }
            });

            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.AddCustomerModelPopUp(true);
                }
                else
                    this.AddCustomerModelPopUp(false);
            });
        }
        else {
            this.AddCustomerModelPopUp(false);
        }
    }

    AddCustomerModelPopUp(openWithDetail) {
        var sentData = {};
        if (openWithDetail) {
            sentData = {
                'address': this.RequisitionList[0].departmentAddress,
                'city': this.RequisitionList[0].departmentCity,
                'pinCode': this.RequisitionList[0].departmentZipCode,
                'phone': this.RequisitionList[0].departmentPhone,
                'name': this.RequisitionList[0].departmentName,
                IsAdd: true
            };
        }

        const dialogRef = this.dialog.open(NewCustomerComponent, {
            height: 'auto',
            width: '90%',
            disableClose: true,
            data: sentData,
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != null && result != '') {
                this.GetCustomer([], result);
            }
            this.titleService.SetTitle("TITLE_PROJECT");
        });
    }


    openMaterialDeleteDialog(data, materialobj) {
        if (materialobj.noStockTraek)
            this.removeProjectMaterial(data, false);
        else {
            var message = "";
            var materialText = materialobj.text ? materialobj.text : materialobj.material.text;
            this.translate.get("Headers.DeleteMaterial").subscribe({
                next: (res) => {
                    message = res;

                }, error: err => { }
            });
            message = message.replace("@MaterialName", materialText)
            // const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            //     height: '200px',
            //     //data: { Header: 'Headers.DeleteMaterial' }
            //     data: { Header: message }
            // });

            // dialogRef.afterClosed().subscribe(result => {
            //     if (result) {
            //         //this.removeProjectMaterial(data);
            //         this.upDownMaterialQuantityDialog("material",data);
            //     }
            // });

            const dialogRef = this.dialog.open(AlertPlanningMessageModelComponent, {
                width: '40%',
                data: {
                    Header: message,
                    FirstButtonLabel: "Headers.MaterialStockUpdown",
                    SecondButtonLabel: "Headers.NoChange",
                }
            });
            dialogRef.afterClosed().subscribe((result: any) => {
                if (result) {
                    if (result == 1) {
                        this.removeProjectMaterial(data, true);
                    }
                    else if (result == 2) {
                        this.removeProjectMaterial(data, false);
                    }
                }
            });
        }
    }

    openCoWorkerDeleteDialog(data) {
        const dialogRef = this.dialog.open(DeleteAlertModalComponent, {
            height: '200px',
            data: data
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.removeProjectCoWorker(data);
            }
        });
    }

    openAllCoWorkerDeleteDialog(data) {
        const dialogRef = this.dialog.open(DeleteAlertModalComponent, {
            height: '200px',
            data: { data, deleteAll: true, projectId: this.ProjectId }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.removeAllProjectCoWorker(this.ProjectId, result.data.coworkerId);
            }
        });
    }

    open(file) {
        window.open(this.host + file.path, "_blank");
    }

    onChangeCoWorkerIntials(evt, coworker) {
        if (evt.source.selected) {
            this.ObjProject.initialer = coworker.initials;
        }
    }

    DeleteProject(Id, isUpDownQty) {
        this.showSpinner = true;
        this.projectService.DeleteProject<ResponseBase>(Id, isUpDownQty).subscribe({
            next: (response: ResponseBase) => {
                this.translate.get("MESSAGES.Deleted").subscribe({
                    next: (res) => {
                        this.notificationsService.create(res);
                    },
                    error: err => {
                    }
                });
            },
            error: err => {
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            },
            complete: () => {
                this.showSpinner = false;
                this._router.navigate(['/projects']);
            }
        });
    }

    removeProject(Id) {
        // const dialogRef = this.dialog.open(AlertMessageModelComponent, {
        //     height: '200px',
        //     data: { Header: 'Headers.DeleteProject' }
        // });

        // dialogRef.afterClosed().subscribe(result => {
        //     if (result) {
        //         this.DeleteProject(Id);
        //     }
        // });

        var message = "";
        this.translate.get("Headers.DeleteProject").subscribe({
            next: (res) => { message = res; }, error: err => { }
        });

        const dialogRef = this.dialog.open(AlertPlanningMessageModelComponent, {
            width: '40%',
            data: {
                Header: message,
                FirstButtonLabel: "Headers.MaterialStockUpdown",
                SecondButtonLabel: "Headers.NoChange",
            }
        });
        dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
                if (result == 1)
                    this.DeleteProject(Id, true);
                else if (result == 2)
                    this.DeleteProject(Id, false);
            }
        });
    }

    getResponsibleCoWorker(initialer) {
        this.ResponsibleCoWorker = this.coWokers.find(c => c.initials == initialer);
        if (this.ResponsibleCoWorker)
            return this.ResponsibleCoWorker.name;
    }

    validateRequisitionField() {
        var planning = false; var scheduledVisit = false;
        if (this.Requisition.status == 6) {
            var planning = true
        }
        if (this.Requisition.status == 2) {
            var scheduledVisit = true
        }
        var flag = {
            isPlanning: planning,
            isScheduledVisit: scheduledVisit,
            startDate: this.ObjProject.startDate == null || this.ObjProject.startDate == undefined ? new Date() : this.ObjProject.startDate,
            startTime: this.ObjProject.startTime == null || this.ObjProject.startTime == undefined ? "07:00" : this.ObjProject.startTime,
            visit: this.ObjProject.visit == null || this.ObjProject.visit == undefined ? new Date() : this.ObjProject.visit,
            visitTime: this.ObjProject.visitTime == null || this.ObjProject.visitTime == undefined ? "07:00" : this.ObjProject.visitTime,
            coWokers: this.coWokers,
            coworkerID: this.Requisition.visitCoworkerId
        }
        const dialogRef = this.dialog.open(StartAndVisitDateComponent, {
            width: '40%',
            data: flag,
            disableClose: true,
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != null) {
                if (planning) {
                    this.ObjProject.startDate = result.startDate;
                    this.ObjProject.startTime = result.startTime;
                    this.Requisition.visitCoworkerId = result.visitCoworkerId;
                }
                if (scheduledVisit) {
                    this.ObjProject.visit = result.visit;
                    this.ObjProject.visitTime = result.visitTime;
                    this.Requisition.visitCoworkerId = result.visitCoworkerId;
                }
                this.Update(true);
                this.UpdateRequisition(this.Requisition);
                planning = false;
                scheduledVisit = false;
            }
        });
    }

    InsertUpdateBilledDate() {
        var detail = {
            BilledDate: this.Requisition.billed,
            //Status: this.ObjProject.indoorOutdoorStatusIds.find(o => o.id == 6),
        }
        const dialogRef = this.dialog.open(BilledStatusDateComponent, {
            height: "25%",
            width: "25%",
            data: detail, //this.Requisition.billed,
            disableClose: false,
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.isOpenBilledDate = false;
                this.Requisition.billed = result.billedDate;
                this.UpdateRequisition(this.Requisition);
            } else {
                this.isOpenBilledDate = false;
            }
        });
    }

    getRequisitionList(model: RequestRequisition, gotoMaterialTab) {
        this.showRequisitionSpinner = true;
        this.projectRequisitionService.Get<ResponseBase>(model.ProjectId, model).subscribe({
            next: (response: ResponseBase) => {
                if (response) {
                    this.showRequisitionSpinner = false;
                    this.RequisitionList = response.result;
                    if (this.RequisitionList.length > 0)
                        this.titleService.SetAddress((this.RequisitionList[0].departmentAddress ? this.RequisitionList[0].departmentAddress : "") + " " + (this.RequisitionList[0].departmentZipCode ? this.RequisitionList[0].departmentZipCode : "") + " " + (this.RequisitionList[0].departmentCity ? this.RequisitionList[0].departmentCity : ""));


                    this.RequisitionList = this.RequisitionList.sort((a, b) => {
                        return a.number - b.number;
                    });
                    if (this.RequisitionList.length > 0)
                        this.getMaxRequisitionNo();
                    else
                        this.MaxReqNumber = 1;
                    //(this.ObjProject.indoorOutdoorStatusId == "5" || this.ObjProject.indoorOutdoorStatusId == "6") &&
                    if (this.RequisitionList.length > 0
                        && gotoMaterialTab || this.reqTabIndex == 1) {
                        this.EditRequisition(this.RequisitionList[0].id, this.RequisitionList[0], gotoMaterialTab);
                    }
                    else if (this.reqTabIndex)
                        this.EditRequisition(this.RequisitionList[0].id, this.RequisitionList[0], false);
                }
                else
                    this.RequisitionList = [];
            },
            error: err => {
                this.showRequisitionSpinner = false;
                this.RequisitionList = [];
            },
            complete: () => {
                this.showRequisitionSpinner = false;
                this.showSpinner = false;
            }
        });
    }

    getMaxRequisitionNo() {
        this.MaxReqNumber = 0;
        this.RequisitionList.filter(req => {
            if (this.MaxReqNumber <= req.number)
                this.MaxReqNumber = req.number;
        });

    }

    saveRequisition() {
        if (this.Requisition.id)
            this.economyNotificationForExcludePrice();
        if ((this.Requisition.estimatedPrice && this.Requisition.estimatedPrice != 0) || (this.Requisition.excludePrice && this.Requisition.excludePrice != 0)) {
            if ((!this.Requisition.estimatedTime || this.Requisition.estimatedTime == 0) && !this.Requisition.isHourlyWage) {
                this.translate.get("FIELDS.messagehoursOnCase").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                        this.commonService.playFailedTone();
                    },
                    error: err => {

                    }
                });
                return false
            }
        }

        if (this.isRequisitionNumFromCust && !this.Requisition.requisition) {
            this.translate.get("MESSAGES.RequisitionNumber").subscribe({
                next: (res) => {
                    this.notificationsService.error(res);
                    //this.commonService.playWarningTone();
                },
                error: err => {
                }
            });
            return;
        }
        this.formService.markFormGroupTouched(this.RequisitionFormTab);
        if (this.requisitionNo) {
            this.RequisitionFormTab.get('requisition').setValidators([Validators.required]);
            this.RequisitionFormTab.get('requisition').updateValueAndValidity();
        }
        if (this.RequisitionFormTab.valid) {
            this.ShowAddNewRequisitionForm = false;
            this.getMaxRequisitionNo();
            this.Requisition.projectId = this.ObjProject.sagId.toString();
            // this.Requisition.comingDate = this.datePipe.transform(this.Requisition.comingDate, "MM/dd/yyyy");
            // this.Requisition.startDate = this.datePipe.transform(this.Requisition.startDate, "MM/dd/yyyy");
            // this.Requisition.endDate = this.datePipe.transform(this.Requisition.endDate, "MM/dd/yyyy");
            if (this.Requisition.id) {
                this.UpdateRequisition(this.Requisition);
            }
            else
                this.AddRequisition(this.Requisition);
        }
    }
    AddNewRequsition() {
        this.RequisitionIsActive = false;
        this.showrequisitionform = true;
        this.displaySpecialInfoRed = false;
        this.Requisition = new projectRequisition();
        this.getMaxRequisitionNo();
        this.Requisition.number = this.MaxReqNumber + 1;
        this.Requisition.isActive = true;
        this.Requisition.status = 0;
        this.Requisition.estimatedPrice = 0;
        this.Requisition.reqTabselectedIndex = 0;
        //this.Requisition.startDate = new Date();
        //this.Requisition.endDate = new Date();
        this.reqTabselectedIndex = 0;
        this.projectMaterials = [];
        this.projectCoWorkerList = [];
        this.Requisition.left = false;
        this.Requisition.riseman = false;
        //assigning some project information to requisition

        if (this.RequisitionList.length > 0) {
            this.Requisition.departmentName = this.RequisitionList[0].departmentName;
            this.Requisition.departmentAddress = this.RequisitionList[0].departmentAddress;
            this.Requisition.departmentZipCode = this.RequisitionList[0].departmentZipCode;
            this.Requisition.departmentCity = this.RequisitionList[0].departmentCity;
            this.Requisition.departmentPhone = this.RequisitionList[0].departmentPhone;
        } else {
            this.Requisition.departmentName = this.ObjProject.customerName;
            this.Requisition.departmentAddress = this.ObjProject.address1;
            this.Requisition.departmentZipCode = this.ObjProject.zipCode;
            this.Requisition.departmentCity = this.ObjProject.city;
            this.Requisition.departmentPhone = this.ObjProject.phone;
        }
        var customer = this.Customers.find(x => x.id === this.ObjProject.customerId);
        if (customer && customer.requisitionNumber) {
            this.isRequisitionNumFromCust = customer.requisitionNumber;
        }
        //this.ShowAddNewRequisitionForm = true;
    }


    AddRequisition(model: projectRequisition) {
        this.showSpinner = true;
        model.CreatedBy = this.authService.getCoWorkerId();
        model.status = 1;
        if (this.Requisition.billed) {
            this.Requisition.billed = moment(moment(this.Requisition.billed).format("DD-MM-YYYY HH:mm"));
        }
        this.projectRequisitionService.Add(model.projectId, model).subscribe({
            next: (response: ResponseBase) => {
                if (response) {
                    if (!response.result.status)
                        response.result.status = 1;

                    this.RequisitionList.unshift(response.result);
                    this.Requisition.id = response.result.id;
                    this.Requisition.startDate = response.result.startDate;
                    this.Requisition.endDate = response.result.endDate;
                    this.Requisition.discountPercent = response.result.discountPercent;
                    this.Requisition.discount = response.result.discount;
                    this.ObjProject.isActive = true;
                    this.translate.get("MESSAGES.Created").subscribe({
                        next: (res) => {
                            this.notificationsService.create(res);
                        },
                        error: err => {
                        }
                    });
                }
            },
            error: err => {

            },
            complete: () => {
                this.showSpinner = false;
                //this.reqTabselectedIndex = this.reqTabselectedIndex + 1;
                this.GetProjectPrice(model.projectId);
                this.GetProjectRequistionCoworkersByProjectID();
                setTimeout(() => {
                    this.EditRequisitionStep(this.Requisition.id, this.Requisition, 5);
                    //this.economyNotificationForExcludePrice();
                });
            }
        });
    }
    //#region Status message
    changedProjectStatus(event) {
        if (event == 12 && this.Requisition.excludePrice < 1 && this.Requisition.estimatedPrice < 1 && !this.Requisition.isHourlyWage) {
            const dialogRef = this.dialog.open(AlertMessageModelComponent, {
                height: '200px',
                data: {
                    Header: 'Headers.EmptyEconomyFields',
                    ShowOKButton: true
                }
            });

            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.requisitionStatusDisplay = false;
                    this.requisitionStatus = this.Requisition.status;
                    setTimeout(() => {
                        this.requisitionStatusDisplay = true;
                    });
                }
            });
        } else {
            //this.requisitionStatus = event;
            if (this.Requisition.status == 10 && this.Requisition.status != event) {
                const dialogRef = this.dialog.open(AlertMessageModelComponent, {
                    data: { Header: 'Headers.ReactiveProjectAndTask' }
                });
                dialogRef.afterClosed().subscribe(result => {
                    if(result)
                        this.Requisition.isReactiveProjectAndTask = true;
                    this.onChangeStatus(event);
                });
            }
            else
                this.onChangeStatus(event);
        }
    }

    onChangeStatus(SelectedStatusID) {
        //if (this.roleName != "Maler" && this.roleName != "Gaest") {
        var lastReqStatus = this.Requisition.status;
        var newReqStatus = SelectedStatusID;
        this.Requisition.status = SelectedStatusID;
        this.Requisition.StatusLogCaseValue = ProjectRequisitionStatusLogEnum.StatusUpdated;
        this.openStatusPopup(lastReqStatus, newReqStatus, SelectedStatusID);
    }

    openStatusPopup(lastReqStatus, newReqStatus, SelectedStatusID) {
        var isIndoorOutdoorStatus = false;
        for (let index = 0; index < this.ObjProject.indoorOutdoorStatusIds.length; index++) {
            var StatusData = this.statusMessageList.find(x => x.status == SelectedStatusID
                && x.audienceType == AskRequisitionStatusAudienceTypeEnum.CoworkerWithQuestion
                && x.indoorOutdoorStatusId.includes(this.ObjProject.indoorOutdoorStatusIds[index].id));
            if (StatusData) {
                isIndoorOutdoorStatus = true;
                break;
            }
        }

        if (isIndoorOutdoorStatus) {
            let requisitionStatusAnswerObj = new RequisitionStatusAnswer();
            requisitionStatusAnswerObj.requisitionId = this.Requisition.id;
            requisitionStatusAnswerObj.lastStatus = lastReqStatus;
            requisitionStatusAnswerObj.newStatus = newReqStatus;
            requisitionStatusAnswerObj.projectId = this.ProjectId;

            const dialogRef = this.dialog.open(AnswerCategoryQuestionComponent, {
                height: "90%",
                width: "70%",
                disableClose: true,
                data: {
                    Header: StatusData.message,
                    ShowOKButton: true,
                    data: JSON.parse(StatusData.question),
                }
            });

            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    requisitionStatusAnswerObj.json = result;
                    this.showSpinner = true;
                    this.projectService.AddStatusAnswer(requisitionStatusAnswerObj).subscribe({
                        next: (response: ResponseBase) => {
                            if (response) {
                                this.showSpinner = false;
                                this.requisitionStatus = SelectedStatusID;
                                this.updateSingleOrMultiStatus();
                                this.translate.get("MESSAGES.Created").subscribe({
                                    next: (res) => {
                                        this.notificationsService.create(res);
                                    },
                                    error: err => {
                                    }
                                });
                            }
                        },
                        error: err => {
                            this.showSpinner = false;
                            this.translate.get("MESSAGES.Failed").subscribe({
                                next: (res) => {
                                    this.notificationsService.error(res);
                                },
                                error: err => {
                                }
                            });
                        },
                        complete: () => {


                        }
                    });
                }
            });
        } else {
            this.requisitionStatus = SelectedStatusID;
            this.updateSingleOrMultiStatus();
        }
    }
    updateSingleOrMultiStatus() {
        if (this.RequisitionList.length > 1) {
            this.changeAllReqStatus();
        }
        else {
            this.updateRequisitionAndProjectOnStatus();
        }
    }

    changeAllReqStatus() {
        const dialogRef = this.dialog.open(RequisitionStatusModelComponent, {
            height: '31%',
            width: '40%',
            data: {
                Header: 'Headers.AllRequisitionCoworker',
                requisitionList: this.RequisitionList,
                status: this.Requisition.status,
                Requisition: this.Requisition
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                var reqList = [];
                reqList = result.list
                for (var i = 0; i < reqList.length; i++) {
                    if (reqList[i].isChecked) {
                        this.RequisitionList[i].status = this.Requisition.status;
                        if (this.Requisition.id == this.RequisitionList[i].id)
                            this.updateRequisitionAndProjectOnStatus();
                        else
                            this.UpdateRequisition(this.RequisitionList[i]);
                    }
                }
            }
            else {
                //this.UpdateRequisition(this.Requisition);
                this.ObjProject.isActive = true;
                this.OnlyUpdateProject();
                this.updateRequisitionAndProjectOnStatus();
            }
        });
    }

    updateRequisitionAndProjectOnStatus() {
        if (this.Requisition.status == 6 || this.Requisition.status == 2) {
            this.validateRequisitionField();
        } else if (this.Requisition.status == 10) {
            this.InsertUpdateBilledDate();
        } else {
            this.UpdateRequisition(this.Requisition);
        }
        // if (this.roleName == "Maler" && this.Requisition.status == 9) {
        //     setTimeout(() => {
        //         this._router.navigate(['/projects']);
        //     }, 1000);
        // }
        var materialQuantity = this.projectMaterials.filter(o => o.quantity <= 0);
        if (this.Requisition.status == 9) {
            //open popup
            if ((materialQuantity == undefined || materialQuantity.length > 0)) {
                const dialogRef = this.dialog.open(AlertMessageModelComponent, {
                    height: '200px',
                    data: { Header: 'Headers.AddMaterial' }
                });

                dialogRef.afterClosed().subscribe(result => {
                    if (result) {
                        this.reqTabselectedIndex = 1;
                        this.Requisition.reqTabselectedIndex = 1;
                    }
                    this.deleteFeaturePlanning();
                });
            }
        }
        else if (this.Requisition.status == 7) {
            this.deleteFeaturePlanning();
        }
    }

    getStatusMessage() {
        this.showSpinner = true;
        this.projectService.GetStatusMessageList<ResponseBase>()
            .subscribe({
                next: (response: ResponseBase) => {
                    this.statusMessageList = response.result;
                    this.showSpinner = false;
                },
                error: err => { this.showSpinner = false },
                complete: () => { }
            });
    }

    //#endregion Status message

    UpdateRequisition(model: projectRequisition) {
        if (this.reqTabselectedIndex == 2) {
            this.Requisition.StatusLogCaseValue = ProjectRequisitionStatusLogEnum.PricingDataChanged;
        }
        if (this.Requisition.donotUpdateWorkHour) {
            model.doNotUpdateHour = "T";
        }
        else {
            model.doNotUpdateHour = "F";
        }
        this.showSpinner = true;
        model.CreatedBy = this.authService.getCoWorkerId();
        //Invoiced date 
        if (this.Requisition.billed && typeof this.Requisition.billed !== "string") {
            this.Requisition.billed = moment(this.Requisition.billed).format("DD-MM-YYYY HH:mm");
        }
        this.projectRequisitionService.Update(model.projectId, model.id, model).subscribe({
            next: (response: ResponseBase) => {
                if (response) {
                    var index = this.RequisitionList.findIndex(f => f.id == model.id);

                    this.RequisitionList.splice(index, 1);
                    this.RequisitionList.splice(index, 0, response.result);
                    this.RequisitionOnEditForEconomy = response.result;
                    this.Requisition.startDate = response.result.startDate;
                    this.Requisition.endDate = response.result.endDate;
                    this.Requisition.comingDate = response.result.comingDate;
                    this.requisitionStatus = response.result.status;
                    this.Requisition.status = response.result.status;
                    this.Requisition.discountPercent = response.result.discountPercent;
                    this.Requisition.discount = response.result.discount;
                    this.Requisition.reqTabselectedIndex = this.reqTabselectedIndex;
                    this.Requisition.materialCreation = response.result.materialCreation;
                    this.ObjProject.isActive = response.result.projectActive;
                    if (this.Requisition.left || this.Requisition.riseman) {
                        this.displaySpecialInfoRed = true;
                    }
                    else {
                        this.displaySpecialInfoRed = false;
                    }
                    if (this.Requisition.doNotUpdateHour == "T")
                        this.Requisition.donotUpdateWorkHour = true;
                    else
                        this.Requisition.donotUpdateWorkHour = false;

                    this.translate.get("MESSAGES.Updated").subscribe({
                        next: (res) => {
                            this.notificationsService.create(res);
                        },
                        error: err => {
                        }
                    });


                    this.RequisitionList.filter(req => {
                        if (req.id == this.Requisition.id) {
                            req.coworkerSeenOnPaintingName = this.Requisition.coworkerSeenOnPaintingName,
                                req.IsExpand = true;
                        }
                        // if (req.id == this.Requisition.id && this.reqTabselectedIndex != 5) {
                        //     req.coworkerSeenOnPaintingName = this.Requisition.coworkerSeenOnPaintingName,
                        //         req.IsExpand = true;
                        // }
                        //else
                        //req.IsExpand = false;
                    });

                }
            },
            error: err => {
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
                this.showSpinner = false;
            },
            complete: () => {
                this.showSpinner = false;
                if (this.Requisition.status == 7) {
                    var model1 = new RequestRequisition();
                    model1.IsDeleted = false;
                    model1.ProjectId = this.ObjProject.sagId.toString();
                    this.getRequisitionList(model1, false);
                    this.selectedIndex = 2;
                    //this.EditRequisition(this.Requisition.id, this.Requisition);

                } else {
                    //this.reqTabselectedIndex = this.reqTabselectedIndex + 1;
                    this.GetProjectPrice(model.projectId);
                }
            }
        });
    }

    EditRequisition(Id: any, objEditingReq, gotoMaterialTab) {
        this.editedRequesitionId = Id;
        this.Expand = true;
        this.reqTabselectedIndex = 0;
        this.showrequisitionform = true;
        this.projectMaterials = [];
        this.projectCoWorkerList = [];
        this.ShowAddNewRequisitionForm = false
        this.showSpinner = true;
        this.Requisition.coworkerSeenOnPaintingName = objEditingReq.coworkerSeenOnPaintingName;
        this.requisitionStatus = objEditingReq.status;
        this.projectRequisitionService.GetSingle(this.ObjProject.sagId, Id).subscribe({
            next: (response: ResponseBase) => {
                if (response) {
                    setTimeout(() => {
                        this.Requisition = response.result;
                        this.RequisitionOnEditForEconomy = response.result;
                        //this.Requisition.hoursLeft = this.Requisition.estimatedTime - this.Requisition.totalWorkingTime;
                        this.requisitionStatus = this.Requisition.status;
                        this.Requisition.discountPercent = response.result.discountPercent;
                        this.Requisition.discount = response.result.discount;
                        this.Requisition.invoiceText = response.result.invoiceText ? response.result.invoiceText.trim() : '';
                        this.Requisition.opgaveText = response.result.opgaveText ? response.result.opgaveText.trim() : '';
                        //(this.ObjProject.indoorOutdoorStatusId == "5" || this.ObjProject.indoorOutdoorStatusId == "6") && 
                        if (gotoMaterialTab || this.reqTabIndex == 1) {
                            this.reqTabselectedIndex = 1;
                            this.Requisition.reqTabselectedIndex = 1;
                        }
                        else if (this.reqTabIndex) {
                            this.reqTabselectedIndex = this.reqTabIndex;
                            this.Requisition.reqTabselectedIndex = this.reqTabIndex;
                        }
                        if (this.Requisition.left || this.Requisition.riseman) {
                            this.displaySpecialInfoRed = true;
                        }
                        else {
                            this.displaySpecialInfoRed = false;
                        }
                        if (response.result.doNotUpdateHour == "T")
                            this.Requisition.donotUpdateWorkHour = true;
                        else
                            this.Requisition.donotUpdateWorkHour = false;

                        this.setAddressInTitle();
                        this.RequisitionList.filter(req => {
                            if (req.id == objEditingReq.id)
                                req.IsExpand = true;
                            else
                                req.IsExpand = false;
                        });
                        //this.ChangePrice();
                        this.ShowCalculatedValuesForEconomy();
                    }, 1000);
                }
            },
            error: err => {

            },
            complete: () => {
                let objMaterialProject = new RequestCaseTask();
                objMaterialProject.fileAssignmentId = Id;
                objMaterialProject.IsDeleted = false;
                objMaterialProject.OrderBy = "ModifyDate DESC"
                this.getProjectMaterialList(objMaterialProject);
                //let objCoWorkerProject = new RequestCoWorker();
                //objCoWorkerProject.IsDeleted = false;
                //objCoWorkerProject.ProjectId = this.ObjProject.sagId;
                //objCoWorkerProject.fileAssignmentId = Id;
                //this.getprojectCoworkerList(objCoWorkerProject)
                this.refreshProjectMaterialForOrdered();
                this.showSpinner = false;
            }
        });
        this.getRequisitionPlanning(this.editedRequesitionId);
    }

    ShowCalculatedValuesForEconomy() {
        var requisitionData = this.RequisitionOnEditForEconomy;
        if (requisitionData) {
            if (requisitionData.suggestedHours)
                this.Requisition.suggestedHours = requisitionData.suggestedHours;
            if (requisitionData.excludePrice) {
                this.Requisition.includePrice = Number((requisitionData.excludePrice + ((Number(requisitionData.excludePrice) * 25) / 100)).toFixed(2));
                this.Requisition.vatPrice = Number((Number(requisitionData.excludePrice) * 25) / 100);
                if (!requisitionData.suggestedHours)
                    this.Requisition.suggestedHours = Number((this.Requisition.excludePrice / this.Requisition.coworkerWorkingRate).toFixed(2));
            }
            if (requisitionData.estimatedPrice > 0) {
                this.Requisition.includeEstimatePrice = Number((this.Requisition.estimatedPrice + ((Number(this.Requisition.estimatedPrice) * 25) / 100)));//.toLocaleString('en-DA', { style: 'currency', currency: 'DKK' });
                this.Requisition.vatEstimatePrice = Number((Number(this.Requisition.estimatedPrice) * 25) / 100);
                if (!requisitionData.suggestedHours)
                    this.Requisition.suggestedHours = Number((this.Requisition.estimatedPrice / this.Requisition.coworkerWorkingRate).toFixed(2));
            }
            if (!requisitionData.suggestedHours)
                requisitionData.suggestedHours = this.Requisition.suggestedHours;
            this.Requisition.suggestedHours = requisitionData.suggestedHours;
            this.Requisition.estimatedTime = requisitionData.estimatedTime ? requisitionData.estimatedTime : requisitionData.suggestedHours;
            this.setRequisitionNoOfDays();
        }
        this.calDiscount();
    }
    getCalculatedValueForMaterialList() {

    }

    DeleteRequisition(Id: any, isUpDownQty) {
        this.showSpinner = true;
        this.projectRequisitionService.RemoveRequistion(this.ObjProject.sagId, Id, isUpDownQty).subscribe({
            next: (response: ResponseBase) => {

                this.RequisitionList.splice(this.RequisitionList.findIndex(f => f.id == Id), 1);
                this.AddNewRequsition();
                this.translate.get("MESSAGES.Deleted").subscribe({
                    next: (res) => {
                        this.notificationsService.create(res);
                    },
                    error: err => {
                    }
                });
            },
            error: err => {
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            },
            complete: () => {
                this.showSpinner = false;
                this.GetProjectPrice(this.ObjProject.sagId);
            }
        });
    }

    GetProjectPrice(Id: any) {
        this.projectService.GetProjectPrice(this.ObjProject.sagId).subscribe({
            next: (response: ResponseBase) => {
                if (response) {
                    this.ObjProject.vat = response.result.vat;
                    this.ObjProject.includePrice = response.result.includePrice;
                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    openDeleteRequisition(Id) {
        // const dialogRef = this.dialog.open(AlertMessageModelComponent, {
        //     height: '200px',
        //     //data: Id
        //     data: { Header: 'Headers.DeleteRequisitionMessage' }
        // });

        // dialogRef.afterClosed().subscribe(result => {
        //     if (result) {
        //         this.DeleteRequisition(Id);
        //         //this.upDownMaterialQuantityDialog('requisition',Id)
        //     }
        // });

        var message = "";
        this.translate.get("Headers.DeleteRequisitionMessage").subscribe({
            next: (res) => { message = res; }, error: err => { }
        });

        const dialogRef = this.dialog.open(AlertPlanningMessageModelComponent, {
            width: '40%',
            data: {
                Header: message,
                FirstButtonLabel: "Headers.MaterialStockUpdown",
                SecondButtonLabel: "Headers.NoChange",
            }
        });
        dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
                if (result == 1)
                    this.DeleteRequisition(Id, true);
                else if (result == 2)
                    this.DeleteRequisition(Id, false);
            }
        });
    }

    // methode to expand current editing acordion
    OpenAccordion(objEditingReq) {
        this.RequisitionList.filter(req => {
            if (req.id == objEditingReq.id)
                req.IsExpand = true;
            else
                req.IsExpand = false;
        });
    }

    // methode to collaps current editing acordion
    CloseAccordion(objEditingReq) {

        this.RequisitionList.filter(req => {
            if (req.id == objEditingReq.id)
                req.IsExpand = false;
        });
    }

    // methode to close all acordion together
    closeAll() {

        this.RequisitionList.filter(req => {
            req.IsExpand = false;
        });
    }

    // Code to Prevent accordion from expantion
    expandPanel(matExpansionPanel: MatExpansionPanel, event: Event): void {
        event.stopPropagation(); // Preventing event bubbling

        if (!this._isExpansionIndicator(event.target)) {
            matExpansionPanel.close(); // Here's the magic
        }
    }

    private _isExpansionIndicator(target: EventTarget): boolean {
        const expansionIndicatorClass = 'mat-expansion-indicator';
        return ((<HTMLElement>target).classList && (<HTMLElement>target).classList.contains(expansionIndicatorClass));
    }

    CopySelectedRequisition(copiedRequisition: projectRequisition) {
        this.displaySpecialInfoRed = false;
        this.ShowAddNewRequisitionForm = true;
        this.AssignCopiedReqData(copiedRequisition);
    }

    AssignCopiedReqData(copiedRequisition) {
        this.Requisition = new projectRequisition();

        this.getMaxRequisitionNo();
        //this.Requisition.requisition = this.MaxReqNumber + 1;
        this.Requisition.number = this.MaxReqNumber + 1;
        this.Requisition.comingDate = copiedRequisition.comingDate;
        this.Requisition.comment = copiedRequisition.comment;
        this.Requisition.departmentAddress = copiedRequisition.departmentAddress;
        this.Requisition.departmentCity = copiedRequisition.departmentCity;
        this.Requisition.departmentName = copiedRequisition.departmentName;
        this.Requisition.departmentNumber = copiedRequisition.departmentNumber;
        this.Requisition.departmentPhone = copiedRequisition.departmentPhone;
        this.Requisition.departmentZipCode = copiedRequisition.departmentZipCode;
        this.Requisition.endDate = copiedRequisition.endDate;
        this.Requisition.invoiceText = copiedRequisition.invoiceText;
        this.Requisition.opgaveText = copiedRequisition.opgaveText;
        this.Requisition.startDate = copiedRequisition.startDate;
        this.Requisition.projectId = copiedRequisition.projectId;
        this.Requisition.isActive = copiedRequisition.isActive;
    }

    onChangeCoWorkerSeenOnPainting(event, coworker) {

        if (event.source.selected) {
            this.RequisitionList.filter(x => {
                x.coworkerSeenOnPainting = coworker.id;
                x.coworkerSeenOnPaintingName = coworker.name;
            });
        }
    }

    onChangeReasonForDropProject(event, reason, objReq: projectRequisition) {
        if (event.source.selected) {

            this.RequisitionList.filter(x => {
                if (x.id == objReq.id) {
                    objReq.reasonForProjectDropped = reason.id;

                }
            });
        }

    }

    onChangeSecurityOfficer(event, coworker, objReq: projectRequisition) {

        if (event.source.selected) {
            this.RequisitionList.filter(x => {
                if (x.id == objReq.id) {
                    objReq.coworkerSeenOnPainting = coworker.id;
                    objReq.coworkerSeenOnPaintingName = coworker.name;
                }
            });
        }
    }

    editStatiscRequisition(req: projectRequisition, i) {
        this.RequisitionList.filter(r => {
            if (r.id == req.id)
                r.showStatisticEditData = true;
            else
                r.showStatisticEditData = false;
        })
        this.EditRequisition(req.id, req, false);
    }

    updateStatisticInfo(req: projectRequisition, i) {
        this.Requisition.StatusLogCaseValue = ProjectRequisitionStatusLogEnum.StatickDataUpdated;

        this.RequisitionList.filter(r => {
            r.showStatisticEditData = false;
        })
        this.Requisition.coworkerSeenOnPainting = req.coworkerSeenOnPainting;
        this.Requisition.coworkerSeenOnPaintingName = req.coworkerSeenOnPaintingName;
        this.Requisition.reasonForProjectDropped = req.reasonForProjectDropped;
        this.UpdateRequisition(this.Requisition);
    }

    // toggleAllSelection() {
    //     var obj = this.currentselectedcoWorkerObj.id.find(o => o == 0);
    //     if (obj == undefined || obj == null) {
    //         this.currentselectedcoWorkerObj = [];
    //         this.currentselectedcoWorkerObj.name = [];
    //         this.currentselectedcoWorkerObj.id = [];
    //         this.currentselectedcoWorkerObj.name.push(0);
    //         this.currentselectedcoWorkerObj.id.push(0);
    //         for (var i = 0; i < this.coWokers.length; i++) {
    //             this.currentselectedcoWorkerObj.name.push(this.coWokers[i].name);
    //             this.currentselectedcoWorkerObj.id.push(this.coWokers[i].id);
    //         }
    //     } else {
    //         this.currentselectedcoWorkerObj = [];
    //         this.currentselectedcoWorkerObj.name = [];
    //         this.currentselectedcoWorkerObj.id = [];
    //     }
    // }

    // onChangeCoWorker(evt, coworker) {
    //     if (this.currentselectedcoWorkerObj.id && this.currentselectedcoWorkerObj.id.length > 0) {
    //         this.currentselectedcoWorkerObj.id = this.currentselectedcoWorkerObj.id.filter(o => o != 0);
    //         var obj = this.currentselectedcoWorkerObj.id.filter(o => o == coworker.id);
    //         if (obj.length > 0) {
    //             this.currentselectedcoWorkerObj.id = this.currentselectedcoWorkerObj.id.filter(o => o != coworker.id);
    //         }
    //         else {
    //             this.currentselectedcoWorkerObj.id.push(coworker.id);
    //         }
    //     }
    //     else {
    //         this.currentselectedcoWorkerObj.id = [];
    //         this.currentselectedcoWorkerObj.id.push(coworker.id);
    //     }
    //     //if (this.currentselectedcoWorkerObj.name.length > 0 || this.currentselectedcoWorkerObj.id.length > 0) {
    //     //  this.currentselectedcoWorkerObj.name = this.currentselectedcoWorkerObj.name.filter(o => o != 0);
    //     //  this.currentselectedcoWorkerObj.id = this.currentselectedcoWorkerObj.id.filter(o => o != 0);
    //     //  var obj = this.currentselectedcoWorkerObj.id.filter(o => o == coworker.id);
    //     //  if (obj.length > 0) {
    //     //    this.currentselectedcoWorkerObj.name = this.currentselectedcoWorkerObj.name.filter(o => o != coworker.name);
    //     //    //this.currentselectedcoWorkerObj.id = this.currentselectedcoWorkerObj.id.filter(o => o != coworker.id);
    //     //  }
    //     //  else {
    //     //    this.currentselectedcoWorkerObj.name.push(coworker.name);
    //     //    this.currentselectedcoWorkerObj.id.push(coworker.id);
    //     //  }
    //     //}

    // }
    //coworker multiselect code end
    searchCityFromZipcode() {
        var zip = postalcodeJson.filter(o => o.zip == this.ObjProject.zipCode);
        if (zip.length > 0) {
            this.ObjProject.city = zip[0].city;
        }
        else {
            this.ObjProject.city = "";
        }
    }

    searchCityFromCityName() {
        var zip = postalcodeJson.filter(o => o.city == this.ObjProject.city);
        if (zip.length > 0) {
            this.ObjProject.zipCode = zip[0].zip;
        }
    }

    searchCityFromZipcodeForReq() {
        var zip = postalcodeJson.filter(o => o.zip == this.Requisition.departmentZipCode);
        if (zip.length > 0) {
            this.Requisition.departmentCity = zip[0].city;
        }
        else {
            this.Requisition.departmentCity = "";
        }
    }

    searchCityFromCityNameForReq() {
        var zip = postalcodeJson.filter(o => o.city == this.Requisition.departmentCity);
        if (zip.length > 0) {
            this.Requisition.departmentZipCode = zip[0].zip;
        }
    }

    getProjectAllRequisitionCoworkers(projectId) {

        let model = new RequestCoWorker();
        model.Limit = 400;
        model.OrderBy = "Name";
        this.coWorkersService.Get<ResponseBase>(model)
            .subscribe((response: ResponseBase) => {
                if (response) {
                    //this.coWokers = response.result;
                    this.getNextRequestForCoWorker(response.pagination, model, response.result);
                    this.showMessageForCoworker = this.projectCoWorkerList.length > 0 ? false : true;
                }
                else {
                    this.ProjectAllRequisitionCoworkers = [];
                    //this.SecurityOfficers = [];
                }
            },
                error => (err) => {
                    this.ProjectAllRequisitionCoworkers = [];
                    //this.SecurityOfficers = [];
                },
                () => {
                });
    }


    getYearsListUptoCurrentList() {
        this.YearList = new Array();

        this.currentYear = moment(new Date()).format('YYYY');

        for (var i = parseInt(this.StartYear); i <= parseInt(this.currentYear); i++) {
            this.YearList.push(i.toString());
        }
    }
    getProjectYearData(year) {
        var curYear = moment(new Date()).format('YYYY');
        this.dissableRightSideYear = false;
        if (curYear <= year) {
            this.dissableRightSideYear = true;
            this.currentYear = curYear;
        }
        else {
            this.currentYear = year;
        }
        var model = new RequestWorkHourGroupBy();
        model.StartDate = this.currentYear + "-01-01";
        model.EndDate = this.currentYear + "-12-31";
        this.GetProjectMonthWiseWorkHour(model);
    }

    getRequisitionYearData(year) {
        var curYear = moment(new Date()).format('YYYY');
        this.dissableRightSideYear = false;
        if (curYear <= year) {
            this.dissableRightSideYear = true;
            this.currentYear = curYear;
        }
        else {
            this.currentYear = year;
        }
        var model = new RequestRequisitionBaseWorkHourGroupBy();
        model.StartDate = this.currentYear + "-01-01";
        model.EndDate = this.currentYear + "-12-31";
        this.GetRequisitionMonthWiseWorkHour(model);
    }
    getNextYearData() {
    }
    onChangeYear() {

    }

    onChangeLeftValue() {
        this.Requisition.leftDays = this.Requisition.left ? 1 : 0;
    }

    onChangeStigemandValue() {
        this.Requisition.risemanDays = this.Requisition.riseman ? 1 : 0;
    }

    AddInActiveMaterials(event) {
        //this.IsAddInActiveMaterial = !this.IsAddInActiveMaterial;
        var modal = new RequestMaterial();
        modal.IsDeleted = false;
        modal.IsActive = !this.IsAddInActiveMaterial;

        this.GetMaterial(modal);
    }
    EditRequisitionStep(Id: any, objEditingReq, step) {
        this.editedRequesitionId = Id;
        this.Expand = true;
        this.reqTabselectedIndex = 0;
        this.showrequisitionform = true;
        this.projectMaterials = [];
        this.projectCoWorkerList = [];
        this.ShowAddNewRequisitionForm = false
        this.showSpinner = true;
        this.Requisition.coworkerSeenOnPaintingName = objEditingReq.coworkerSeenOnPaintingName;
        this.projectRequisitionService.GetSingle(this.ObjProject.sagId, Id).subscribe({
            next: (response: ResponseBase) => {
                if (response) {
                    setTimeout(() => {
                        this.Requisition = response.result;
                        //this.Requisition.hoursLeft = this.Requisition.estimatedTime - this.Requisition.totalWorkingTime;
                        this.RequisitionOnEditForEconomy = response.result;
                        this.requisitionStatus = response.result.status;
                        this.Requisition.discountPercent = response.result.discountPercent;
                        this.Requisition.discount = response.result.discount;
                        this.Requisition.invoiceText = response.result.invoiceText ? response.result.invoiceText.trim() : '';
                        this.Requisition.opgaveText = response.result.opgaveText ? response.result.opgaveText.trim() : '';
                        if (this.Requisition.left || this.Requisition.riseman) {
                            this.displaySpecialInfoRed = true;
                        }
                        else {
                            this.displaySpecialInfoRed = false;
                        }
                        if (response.result.doNotUpdateHour == "T")
                            this.Requisition.donotUpdateWorkHour = true;
                        else
                            this.Requisition.donotUpdateWorkHour = false;
                        this.reqTabselectedIndex = step;
                        this.Requisition.reqTabselectedIndex = step;
                        this.checkStaffPriceOrStorePuchase();
                        this.setAddressInTitle();
                        this.RequisitionList.filter(req => {
                            if (req.id == objEditingReq.id)
                                req.IsExpand = true;
                            else
                                req.IsExpand = false;
                        });

                        //this.ChangePrice();
                        this.getTotalEtstimeteOrPrice();
                        this.ShowCalculatedValuesForEconomy();
                        this.refreshProjectMaterialForOrdered();
                    }, 1000);
                }
            },
            error: err => {

            },
            complete: () => {
                if (step == 3 && this.ObjProject.indoorOutdoorStatusId == "5" || this.ObjProject.indoorOutdoorStatusId == "6") {
                    this.isActiveForMaterial = false;
                    this.IsAddInActiveMaterial = true;
                }
                let objMaterialProject = new RequestCaseTask();
                objMaterialProject.fileAssignmentId = Id;
                objMaterialProject.IsDeleted = false;
                objMaterialProject.OrderBy = "ModifyDate DESC"
                this.getProjectMaterialList(objMaterialProject);
                this.showSpinner = false;
            }
        });
        this.getRequisitionPlanning(this.editedRequesitionId);
    }

    goToStep(stepval) {
        var stepvalLocal = stepval;
        if (stepval == 7) {
            stepvalLocal = 3;
        }
        this.selectedIndex = this.selectedIndex + 1;
        this.EditRequisitionStep(this.RequisitionList[0].id, this.RequisitionList[0], stepvalLocal);
        if (stepval == 7) {
            setTimeout(() => {
                this.AddNewMaterials();
            }, 5000);

        }
    }

    getTotalEtstimeteOrPrice() {
        if (this.Requisition.includeEstimatePrice > 0) {
            this.Requisition.totalIncludeEstimatePrice = this.Requisition.includeEstimatePrice;
            this.Requisition.totalVatEstimatePrice = (this.Requisition.vatEstimatePrice);
        }
        else if (this.Requisition.includePrice > 0) {
            this.Requisition.totalIncludeEstimatePrice = (this.Requisition.includePrice);
            this.Requisition.totalVatEstimatePrice = (this.Requisition.vatPrice);
        }
        else {
            this.Requisition.totalIncludeEstimatePrice = (this.Requisition.includeEstimatePrice);
            this.Requisition.totalVatEstimatePrice = (this.Requisition.vatEstimatePrice);
        }
        this.setRequisitionNoOfDays();
        this.calculateOfferPriceExclVAT();
    }

    calculateOfferPriceExclVAT() {
        var totalPriceDiscount = 0;
        if (this.Requisition.discountPercent && this.Requisition.discountPercent > 0)
            totalPriceDiscount = (this.materialTotalPrice * this.Requisition.discountPercent) / 100;
        if (this.Requisition.discount && this.Requisition.discount > 0)
            totalPriceDiscount = this.Requisition.discount;
        //var PriceIncluded = (this.materialTotalPrice - ((this.materialTotalPrice * this.Requisition.discountPercent) / 100));
        var PriceIncluded = (this.materialTotalPrice - totalPriceDiscount);
        if (this.Requisition.totalIncludeEstimatePrice && this.Requisition.totalVatEstimatePrice) {
            var tempOfferPriceexcluded = this.Requisition.totalIncludeEstimatePrice - this.Requisition.totalVatEstimatePrice;
        }
        if (PriceIncluded && tempOfferPriceexcluded) {
            this.Requisition.performedPercent = ((PriceIncluded / tempOfferPriceexcluded) * 100);
        }
    }

    ShowProjectInvoice() {
        this.requisitionInvoices = [];
        //Open it 
        // this.ChangePrice();
        // this.ChangeEstimatePrice();
        this.getTotalEtstimeteOrPrice();
        var cust = this.Customers.find(c => c.id == this.ObjProject.customerId);
        if (cust) {
            this.invoice.customer = {};
            this.invoice.customer.city = cust.city;
            this.invoice.customer.pinCode = cust.pinCode;
            this.invoice.customer.address = cust.address;
            this.invoice.customer.phone = cust.phone;
            this.invoice.customer.email = cust.email;
            this.invoice.customer.name = cust.name;
            this.invoice.customer.colorcode = cust.colorcode;
            this.invoice.customer.id = cust.id;
            this.invoice.customer.number = cust.number;
            this.invoice.customer.position = cust.position;
        }
        this.invoice.materialList = [];
        this.invoice.materialList = this.projectMaterials;
        this.invoice.number = this.Requisition.requisition;
        this.invoice.text = this.Requisition.opgaveText;
        this.invoice.materialTotalPriceWithVAT = this.materialTotalPriceWithVAT;
        this.invoice.materialTotalPrice = this.materialTotalPrice;
        this.invoice.materialPersonalTotalPrice = this.materialPersonalTotalPrice;
        this.invoice.includeEstimatePrice = this.Requisition.includeEstimatePrice;
        this.invoice.includePrice = this.Requisition.includePrice;
        this.invoice.totalIncludeEstimatePrice = this.Requisition.totalIncludeEstimatePrice;
        this.invoice.totalVatEstimatePrice = this.Requisition.totalVatEstimatePrice;
        this.invoice.sagNumber = this.ObjProject.sagNumber;
        var indoorOutdoorStatusIds = "";
        if (this.ObjProject.indoorOutdoorStatusIds) {
            for (var i = 0; i < this.ObjProject.indoorOutdoorStatusIds.length; i++) {
                if (indoorOutdoorStatusIds)
                    indoorOutdoorStatusIds = indoorOutdoorStatusIds + ',' + danish.data[this.ObjProject.indoorOutdoorStatusIds[i].key.split(".")[0]][this.ObjProject.indoorOutdoorStatusIds[i].key.split(".")[1]]
                else
                    indoorOutdoorStatusIds = danish.data[this.ObjProject.indoorOutdoorStatusIds[i].key.split(".")[0]][this.ObjProject.indoorOutdoorStatusIds[i].key.split(".")[1]]
            }
        }
        this.invoice.indoorOutdoorStatusIds = indoorOutdoorStatusIds;
        this.requisitionInvoices.push(this.invoice);
        //this.isPrintInvoice = true;
    }


    SendInvoice(element, isPrint) {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: '200px',
            data: { Header: 'Headers.ViewPersonalPriceMessage' }
        });

        dialogRef.afterClosed().subscribe(result => {
            var isDisplayPersonalPrice = result ? result : false;
            this.Requisition;
            this.isPrintInvoice = false;
            this.showSpinner = true;
            this.projectService.GetProjectInvoice<ResponseBase>(this.Requisition.projectId, this.Requisition.id, isDisplayPersonalPrice)
                .subscribe({
                    next: (response: ResponseBase) => {
                        var objInvoice = new ProjectInvoice();
                        objInvoice.name = this.ObjProject.customerName;
                        objInvoice.projectId = this.Requisition.projectId;
                        objInvoice.projectNumber = this.ObjProject.sagNumber.toString();
                        objInvoice.fileName = response.result.filename;
                        objInvoice.invoiceBase64 = response.result.base64;
                        objInvoice.requisitionId = this.Requisition.id;
                        this.isPrintInvoice = false;
                        const dialogRef = this.dialog.open(PdfViewerModelComponent, {
                            height: '90%',
                            width: '90%',
                            data: {
                                base64: response.result.base64.replace("data:application/pdf;base64,", ""),
                                filename: response.result.filename + ".pdf"
                            }
                        });

                        dialogRef.afterClosed().subscribe(result => {
                            if (result) {
                            }
                            this.fileObjList.filter(x => {
                                if (x.checked) {
                                    x.checked = false;
                                }
                            });
                        });
                    },
                    error: err => {
                        this.showSpinner = false;
                    },
                    complete: () => {
                        this.isPrintInvoice = false;
                        this.showSpinner = false;
                    }
                });
            var that = this;

        });
    }




    sendEmailPopup(element) {
        var sagNumber = this.requisitionInvoices[0].sagNumber;
        var emailSendingParam = new EmailSendingParam();
        //var emailBody = "<html><header><title style='text-align:center;'>This is title</title></header><body style='color: red;'><b> Hello world </b> <td style='font-family: sans-serif; font-size: 14px; vertical-align: top;'>test</td></body></html>";
        emailSendingParam.fromName = this.authService.getUserName();
        emailSendingParam.from = this.authService.getUserEmail();
        emailSendingParam.to = this.requisitionInvoices[0].customer.email;
        emailSendingParam.toName = this.requisitionInvoices[0].customer.name;
        emailSendingParam.displayAttachement = true;
        emailSendingParam.subject = "Invoice# " + sagNumber + " fra Vibe Solutions";
        emailSendingParam.body = "";
        emailSendingParam.isLoadDefaultEmailTemplate = true;
        const dialogRef = this.dialog.open(TextEditorEmailSendComponent, {
            height: '90%',
            width: '90%',
            data: emailSendingParam
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result != null && result != '') {
                this.isPrintInvoice = false;
                var that = this;
                var emailSending = new EmailSendingParam();
                emailSending = result;
                if (emailSending.sendAttachement) {
                    this.projectService.GetProjectInvoice<ResponseBase>(this.Requisition.projectId, this.Requisition.id, emailSending.isDisplayPersonalPrice)
                        .subscribe({
                            next: (response: ResponseBase) => {
                                emailSending.attachmentBase64 = response.result.base64.replace('data:application/pdf;base64,', '');
                                emailSending.attachmentFileName = 'Projekt_Faktura#' + sagNumber + '.pdf';
                                that.sendEmail(emailSending);
                                that.isPrintInvoice = false;
                            },
                            error: err => {
                                this.showSpinner = false;
                            },
                            complete: () => {
                                this.showSpinner = false;
                                that.isPrintInvoice = false;
                            }
                        });
                    //setTimeout(function () {
                    //  const options = <DrawOptions>{ paperSize: "A4", landscape: false, scale: 0.7, margin: 5 };
                    //  drawDOM(element, options).then((group: Group) => {
                    //    return exportPDF(group);
                    //  }).then((dataUri) => {
                    //    emailSending.attachmentBase64 = dataUri.replace('data:application/pdf;base64,', '');
                    //    emailSending.attachmentFileName = 'Projekt_Faktura#' + sagNumber + '.pdf';
                    //    that.sendEmail(emailSending);
                    //    that.isPrintInvoice = false;
                    //  });
                    //}, 100);
                }
                else {
                    that.sendEmail(emailSending);
                    that.isPrintInvoice = false;
                }
            }
        });
    }

    sendEmail(emailSending) {
        this.showSpinner = true;
        this.companyInfoService.SendEmail<ResponseBase>(emailSending)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.showSpinner = false;
                    this.commonService.checkEmailIsToBeSent();
                },
                error: err => {
                    this.showSpinner = false;
                },
                complete: () => {
                    this.showSpinner = false;
                }
            });
    }

    getProjectReqisitionForInvoice() {
        if (this.RequisitionList && this.RequisitionList.length > 0) {
            this.requisitionListForInvoices = [];
            if (this.ObjProject && this.ObjProject.customerId) {

                this.RequisitionList.forEach(contact => {
                    let objMaterialProject = new RequestCaseTask();
                    objMaterialProject.fileAssignmentId = contact.id;
                    objMaterialProject.IsDeleted = false;
                    objMaterialProject.OrderBy = "ModifyDate DESC"
                    this.tempObj = {};
                    this.tempObj.projectMaterials = [];
                    this.invoice = {};
                    var cust = this.Customers.find(c => c.id == this.ObjProject.customerId);
                    this.projectService.GetProjectMaterialList<ResponseBase>(objMaterialProject)
                        .subscribe((response: ResponseBase) => {
                            if (response) {
                                this.tempObj.projectMaterials = response.result;
                                this.tempObj.materialTotalPrice = 0;
                                this.tempObj.materialTotalPriceWithVAT = 0;
                                this.tempObj.materialPersonalTotalPrice = 0;

                                this.tempObj.projectMaterials.forEach((e: any) => {
                                    if (e.quantity && e.quantity != 0) {
                                        this.tempObj.materialTotalPrice = this.tempObj.materialTotalPrice + this.getTotalPrice(e.quantity, e.retailPrice);
                                    }
                                    if (e.personalPris && e.personalPris != 0) {
                                        this.tempObj.materialPersonalTotalPrice = this.tempObj.materialPersonalTotalPrice + this.getTotalPrice(e.quantity, e.personalPris);
                                    }
                                    else {
                                        e.personalPris = 0;
                                    }
                                });
                                var vat = (this.tempObj.materialTotalPrice * 25) / 100;
                                this.tempObj.materialTotalPriceWithVAT = this.tempObj.materialTotalPrice + vat;
                                this.tempObj.projectMaterials.filter(p => { p.quantity = parseFloat(p.quantity.toFixed(2)); });

                                if (cust) {
                                    this.invoice.customer = {};
                                    this.invoice.customer.city = cust.city;
                                    this.invoice.customer.pinCode = cust.pinCode;
                                    this.invoice.customer.address = cust.address;
                                    this.invoice.customer.phone = cust.phone;
                                    this.invoice.customer.email = cust.email;
                                    this.invoice.customer.name = cust.name;
                                    this.invoice.customer.colorcode = cust.colorcode;
                                    this.invoice.customer.id = cust.id;
                                    this.invoice.customer.number = cust.number;
                                    this.invoice.customer.position = cust.position;
                                }
                                this.invoice.materialList = [];
                                this.invoice.materialList = this.tempObj.projectMaterials;
                                this.invoice.number = contact.requisition;
                                this.invoice.text = contact.opgaveText;
                                this.invoice.materialTotalPriceWithVAT = this.tempObj.materialTotalPriceWithVAT;
                                this.invoice.materialTotalPrice = this.tempObj.materialTotalPrice;
                                this.invoice.materialPersonalTotalPrice = this.tempObj.materialPersonalTotalPrice;
                                this.invoice.sagNumber = this.ObjProject.sagNumber;
                                var indoorOutdoorStatusIds = "";
                                if (this.ObjProject.indoorOutdoorStatusIds) {
                                    for (var i = 0; i < this.ObjProject.indoorOutdoorStatusIds.length; i++) {
                                        if (indoorOutdoorStatusIds)
                                            indoorOutdoorStatusIds = indoorOutdoorStatusIds + ',' + danish.data[this.ObjProject.indoorOutdoorStatusIds[i].key.split(".")[0]][this.ObjProject.indoorOutdoorStatusIds[i].key.split(".")[1]]
                                        else
                                            indoorOutdoorStatusIds = danish.data[this.ObjProject.indoorOutdoorStatusIds[i].key.split(".")[0]][this.ObjProject.indoorOutdoorStatusIds[i].key.split(".")[1]]
                                    }
                                }
                                this.invoice.indoorOutdoorStatusIds = indoorOutdoorStatusIds;
                                this.invoice.includePrice = Number((contact.excludePrice + ((Number(contact.excludePrice) * 25) / 100)));
                                this.invoice.vatPrice = Number((Number(contact.excludePrice) * 25) / 100);
                                this.invoice.includeEstimatePrice = Number((contact.estimatedPrice + ((Number(contact.estimatedPrice) * 25) / 100)));
                                this.invoice.vatEstimatePrice = Number((Number(contact.estimatedPrice) * 25) / 100);

                                if (this.invoice.includeEstimatePrice > 0) {
                                    this.invoice.totalIncludeEstimatePrice = this.invoice.includeEstimatePrice;
                                    this.invoice.totalVatEstimatePrice = (this.invoice.vatEstimatePrice);
                                }
                                else if (this.invoice.includePrice > 0) {
                                    this.invoice.totalIncludeEstimatePrice = (this.invoice.includePrice);
                                    this.invoice.totalVatEstimatePrice = (this.invoice.vatPrice);
                                }
                                else {
                                    this.invoice.totalIncludeEstimatePrice = (this.invoice.includeEstimatePrice);
                                    this.invoice.totalVatEstimatePrice = (this.invoice.vatEstimatePrice);
                                }
                                var copy = Object.assign({}, this.invoice)
                                this.requisitionListForInvoices.push(copy);
                            }
                            else {
                            }
                        },
                            error => (err) => {
                            },
                            () => {
                            });
                });
            }
        }
    }

    getTotalPrice(qty, price) {
        if (price == undefined) {
            price = 0;
        }
        var isNegative = false;
        if (qty < 0)
            isNegative = true;
        if (price < 0)
            isNegative = true;
        var total = (qty * price);
        if (isNegative)
            total = -Math.abs(total)
        return total;
    }

    getRetailTotalProfit() {
        var totalIncludeEstimatePrice = this.Requisition.totalIncludeEstimatePrice;
        var totalPriceDiscount = 0;
        if (this.Requisition.discountPercent && this.Requisition.discountPercent > 0)
            totalPriceDiscount = (this.materialTotalPrice * this.Requisition.discountPercent) / 100;
        if (this.Requisition.discount && this.Requisition.discount > 0)
            totalPriceDiscount = this.Requisition.discount;
        //var materialTotalPriceWithVAT = ((((this.materialTotalPrice - ((this.materialTotalPrice * this.Requisition.discountPercent) / 100)) * 25) / 100) + (this.materialTotalPrice - ((this.materialTotalPrice * this.Requisition.discountPercent) / 100)));
        var materialTotalPriceWithVAT = ((((this.materialTotalPrice - totalPriceDiscount) * 25) / 100) + (this.materialTotalPrice - totalPriceDiscount));
        var isNegative = false;
        var total = 0;
        this.Requisition.isNegativeTotalProfit = false;
        if (materialTotalPriceWithVAT < 0) {
            total = (totalIncludeEstimatePrice - Math.abs(materialTotalPriceWithVAT));
        }
        else {
            total = (totalIncludeEstimatePrice - materialTotalPriceWithVAT);
        }
        if (total < 0) {
            isNegative = true;
            this.Requisition.isNegativeTotalProfit = true;
        }
        if (isNegative)
            total = -Math.abs(total)
        return total;
    }
    getPersonalTotalProfit() {
        var totalIncludeEstimatePrice = this.Requisition.totalIncludeEstimatePrice;
        var totalVatEstimatePrice = this.Requisition.totalVatEstimatePrice;
        var totalPriceDiscount = 0;
        if (this.Requisition.discountPercent && this.Requisition.discountPercent > 0)
            totalPriceDiscount = (this.materialTotalPrice * this.Requisition.discountPercent) / 100;
        if (this.Requisition.discount && this.Requisition.discount > 0)
            totalPriceDiscount = this.Requisition.discount;
        //var materialTotalPrice = (this.materialTotalPrice - ((this.materialTotalPrice * this.Requisition.discountPercent) / 100));
        var materialTotalPrice = (this.materialTotalPrice - totalPriceDiscount);
        var isNegative = false;
        var total = 0;
        if (materialTotalPrice < 0) {
            total = ((totalIncludeEstimatePrice - totalVatEstimatePrice) - Math.abs(materialTotalPrice));
        }
        else {
            total = ((totalIncludeEstimatePrice - totalVatEstimatePrice) - materialTotalPrice);
        }
        if (total < 0)
            isNegative = true;
        if (isNegative)
            total = -Math.abs(total)
        return total;
    }

    // ChangeInvoiceStatus() {
    //     var tempObj = {
    //         projectStatus: this.selectStatus,
    //         RequisitionStatus: this.Requisition.status,
    //         isDisableForMaler: this.isDisableForMaler
    //     }
    //     const dialogRef = this.dialog.open(projectStatusComponent, {
    //         height: '30%',
    //         width: '30%',
    //         data: tempObj,
    //     });
    //     dialogRef.afterClosed().subscribe(result => {
    //         if (result != null && result != '') {
    //             this.Requisition.status = result;
    //             this.onChangeStatus(this.requisitionStatus);
    //             if (result == 12) {
    //                 setTimeout(() => {
    //                     this._router.navigate(['/projects']);
    //                 }, 1000);
    //             }
    //         }
    //     });
    // }

    uploadProjectInvoiceToDropbox(objInvoice) {
        this.projectService.UploadProjectInvoiceToDropbox<ResponseBase>(objInvoice)
            .subscribe({
                next: (response: ResponseBase) => { },
                error: err => { },
                complete: () => {
                    this.showSpinner = false;
                }
            });
    }

    checkStaffPriceOrStorePuchase() {
        if (this.Requisition && (this.Requisition.opgaveText == "Personale køb" || this.Requisition.opgaveText == "Butiks køb"))
            this.isStaffPriceOrStorePurchase = true;
        else
            this.isStaffPriceOrStorePurchase = false;
    }
    handleInput(event) {
        if (event.which === 32 && (this.projectMaterialQuantity.text == undefined || this.projectMaterialQuantity.text == ""))
            event.preventDefault();
    }

    onFocusIn(event) {
        //this.InsertUpdateBilledDate();
        event.model.show();
    }

    onFocus(flag): void {
        this.isOpenBilledDate = !flag;
        if (this.isOpenBilledDate) {
            this.InsertUpdateBilledDate();
        }
    }
    onSetDonotUpdateRequisitionWorkhour(IsActive) {
        this.Requisition.donotUpdateWorkHour = IsActive;
        this.projectService.UpdateRequisitionDonotAddWorkHour<ResponseBase>(this.Requisition.id, IsActive)
            .subscribe((response: ResponseBase) => {
                if (response) {
                    this.RequisitionList.filter(item => {
                        if (this.Requisition.id == item.id) {
                            item.doNotUpdateHour = IsActive == true ? 'T' : 'F';
                        }
                    })
                }
            },
                error => (err) => {
                    this.Customers = [];
                });
    }


    setRequisitionNoOfDays() {
        this.Requisition.hoursLeft = 0;
        if (this.Requisition.estimatedTime) {
            //this.Requisition.hoursLeft = this.Requisition.estimatedTime - this.Requisition.totalWorkingTime;
            this.Requisition.hoursLeft = parseFloat(((this.Requisition.estimatedTime - this.Requisition.totalWorkingTime)).toFixed(2));
            this.Requisition.numberOfDays = parseFloat(((this.Requisition.hoursLeft) / 7.5).toFixed(2));
        }
        else
            this.Requisition.numberOfDays = 0;

    }

    removeFeaturePlanning() {
        var model = {
            requisitionId: this.Requisition.id,
            date: moment(new Date()).format('YYYY-MM-DD')
        }
        this.workhoursService.DeleteFuturePlanning<ResponseBase>(model)
            .subscribe({
                next: (response: ResponseBase) => { },
                error: err => { },
                complete: () => { }
            });
    }

    onCheckCaseOnHourly(value) {
        this.Requisition.isHourlyWage = !value;
        this.Requisition.hourlyWage = 0;
        if (this.Requisition.isHourlyWage) {
            this.Requisition.excludePrice = 0
            this.Requisition.estimatedPrice = 0
            this.ChangePrice();
            this.ChangeEstimatePrice();
        }
        setTimeout(() => {
            this.UpdateRequisition(this.Requisition);
        }, 500);
    }
    getRequisitionPlanning(Id) {
        this.requisitionPlanning = [];
        var tempDate = moment(new Date()).format("YYYY-MM-DD");
        this.workhoursService.GetRequistionPlanning(Id, tempDate).subscribe({
            next: (response: ResponseBase) => {
                if (response) {
                    this.requisitionPlanning = response.result;
                }
            },
            error: err => {

            },
            complete: () => {
            }
        });
    }

    setAddressInTitle() {
        var address = this.Requisition.departmentAddress ? this.Requisition.departmentAddress : '' + " "
            + this.Requisition.departmentZipCode ? this.Requisition.departmentZipCode : '' + " "
                + this.Requisition.departmentCity ? this.Requisition.departmentCity : '';
        this.titleService.SetAddress(address)
    }

    moveRequisition(requisition, index) {
        var RequisitionData = {
            Requisition: requisition,
            sagNumber: this.ObjProject.sagNumber,
            requisitionNumber: index + 1
        }
        var dialogRef = this.dialog.open(MoveRequisitionComponent, {
            height: '90%',
            width: '90%',
            disableClose: false,
            data: RequisitionData
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.RequisitionList = this.RequisitionList.filter(o => o.id != requisition.id);
            }
        });
    }

    DropDownSearchRequisitionStatus(value) {
        this.selectStatus = this.projectStatusList;
        if (value) {
            if (this.translate.store.currentLang == "en")
                this.selectStatus = this.projectStatusList.filter(x => x.en.toLowerCase().includes(value.toLowerCase()));
            else
                this.selectStatus = this.projectStatusList.filter(x => x.ds.toLowerCase().includes(value.toLowerCase()));
        }
        else
            this.selectStatus = this.projectStatusList;
    }

    GetInquiryDetail(id) {
        this.customersService.GetInquiryDetail<ResponseBase>(id)
            .subscribe({
                next: (response: ResponseBase) => {
                    if (response.result && response.result.indoorOutdoorStatusId) {
                        this.ObjProject.indoorOutdoorStatusId = response.result.indoorOutdoorStatusId
                        this.setIndoorOutDoorDropDown();
                    }
                },
                error: err => {
                },
                complete: () => {

                }
            });
    }

    updateBilledStatus() {
        if (!this.ObjProject.isActive && this.ObjProject.customerNumber == "18231" && this.ObjProject.indoorOutdoorStatusIds.length == 1 && this.ObjProject.indoorOutdoorStatusIds[0].id == 6) {
            if (this.RequisitionList.length > 0) {
                this.Requisition = this.RequisitionList[0];
                this.Requisition.isActive = this.ObjProject.isActive;
                this.requisitionStatus = 10;
                this.onChangeStatus(this.requisitionStatus);
            }
        }
    }

    economyNotificationForExcludePrice() {
        var data = this.ObjProject.indoorOutdoorStatusIds.filter(x => {
            if (x.id == 1 || x.id == 2 || x.id == 3)
                return x;
        });
        if (data.length > 0) {
            if (!this.Requisition.estimatedPrice) {
                if ((!this.Requisition.excludePrice && !this.Requisition.isHourlyWage)) {
                    this.translate.get("MESSAGES.ExcludePriceNotification").subscribe({
                        next: (res) => {
                            this.notificationsService.alert(res);
                            this.getWarningTone();
                            if (this.reqTabselectedIndex == 0) {
                                this.reqTabselectedIndex = 2;
                                this.ReqTabValueChange();
                            }
                        },
                        error: err => {
                        }
                    });
                }
            }
        }
    }

    calDiscount() {
        if (this.Requisition.discountPercent && this.Requisition.discountPercent > 0) {
            this.totalPriceDiscount = (this.materialTotalPrice * this.Requisition.discountPercent) / 100;
            this.personalTotalPriceDiscount = (this.materialPersonalTotalPrice * this.Requisition.discountPercent) / 100;
        }
        if (this.Requisition.discount && this.Requisition.discount > 0) {
            this.totalPriceDiscount = this.Requisition.discount;
            this.personalTotalPriceDiscount = this.Requisition.discount;
        }
        this.actualTotalPrice = this.materialTotalPrice - this.totalPriceDiscount;
        this.actualPersonalTotalPrice = this.materialPersonalTotalPrice - this.personalTotalPriceDiscount;
        this.actualTotalPriceWithVAT = this.actualTotalPrice + ((this.actualTotalPrice * 25) / 100);
    }
    _handleKeydown(event: KeyboardEvent) {
        if (event.keyCode === 13) {
            // do not propagate spaces to MatSelect, as this would select the currently active option
            event.stopPropagation();
        }

    }

    sendSMS() {
        var moduleObj = new SMSModules()
        moduleObj.name = this.ObjProject.customerName;
        moduleObj.moduleID = this.ProjectId;
        moduleObj.mobile = this.ObjProject.phone;
        moduleObj.flag = SMSModuleEnum.Project;
        moduleObj.countryCode = this.ObjProject.countryCode;
        const dialogRef = this.dialog.open(SendSMSComponent, {
            height: '350px',
            width: '800px',
            data: moduleObj
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {

            }
        });
    }

    getWarningTone() {
        this.commonService.playWarningTone();
    }

    storeFileFolderPathData() {
        var rootPath = this.dropboxFilesService.getSettingMasterValueDocumentPath();
        var paths = "";
        if (rootPath.DocumentDriveType == DriveModuleEnum.Onedrive) {
            paths = rootPath.Onedrive.Path;
        } else {
            paths = rootPath.Dropbox.Path;
        }
        this.fileFolderPathData = paths.split("/").map((path, i) => ({
            name: path,
            isDisplay: false
        }));
        if (this.ObjProject.isDocumentMoveToFinish)
            this.fileFolderPathData.push(
                { name: 'Project', isDisplay: false },
                { name: 'Afsluttet', isDisplay: false },
                { name: this.ObjProject.sagNumber, isDisplay: true }
            );
        else
            this.fileFolderPathData.push(
                { name: 'Project', isDisplay: false },
                { name: this.ObjProject.sagNumber, isDisplay: true }
            );
    }

    IsFileExist(flag) {
        this.isFile = flag;
    }

    bindInvoiceText() {
        if (!this.Requisition.invoiceText) {
            this.Requisition.invoiceText = this.Requisition.opgaveText;
        }
    }

    calMaterialCreation() {
        var totalMaterialCreationPrice = 0
        this.projectMaterials.forEach((e: any) => {
            if (e.lineType && (e.lineType == "V" || e.material.lineType == "V")) {
                totalMaterialCreationPrice += e.totalPrice ? e.totalPrice : 0
            }
        });
        this.Requisition.materialCreation = ((totalMaterialCreationPrice / this.materialTotalPrice) * 100);
    }

    updateSuggestedHours() {
        if (this.Requisition.excludePrice > 0) {
            this.Requisition.suggestedHours = Number((this.Requisition.excludePrice / this.Requisition.coworkerWorkingRate).toFixed(2)); //Number(((this.Requisition.excludePrice - this.materialTotalPrice) / 517.50).toFixed(2));
            this.Requisition.estimatedTime = this.Requisition.suggestedHours;
        } else if (this.Requisition.estimatedPrice > 0) {
            this.Requisition.suggestedHours = Number((this.Requisition.estimatedPrice / this.Requisition.coworkerWorkingRate).toFixed(2)); //Number(((this.Requisition.estimatedPrice - this.materialTotalPrice) / 517.50).toFixed(2));
            this.Requisition.estimatedTime = this.Requisition.suggestedHours;
        }
    }

    refreshProjectMaterialForOrdered() {
        let objProjectMaterialForOrdered = new RequestProjectMaterialCountForOrdered();
        objProjectMaterialForOrdered.requisitionNumber = this.Requisition.number;
        objProjectMaterialForOrdered.projectNumber = this.ObjProject.sagNumber.toString();
        this.getProjectMaterialForOrdered(objProjectMaterialForOrdered);
    }

    getProjectMaterialForOrdered(modal) {
        this.projectService.GetProjectMaterialForOrdered<ResponseBase>(modal)
            .subscribe((response: ResponseBase) => {
                if (response)
                    this.projectMaterialListInOrdered = response.result.myResponse;
                this.projectMaterialInOrderedCount = response.result.myResponse.length;
            }, error => (err) => { }, () => { });
    }

    GetProjectRequistionCoworkersByProjectID() {
        this.projectService.GetProjectRequistionCoworkersByProjectID(this.ObjProject.sagId).subscribe({
            next: (response: ResponseBase) => {
                if (response) {
                    this.ProjectRequistionCoWorkerList = response.result;
                    if (this.ProjectRequistionCoWorkerList.length > 0 && this.RequisitionList.length > 1)
                        this.ShowRequistionCoworker();
                }
            },
            error: err => {
            },
            complete: () => {
            }
        });
    }

    ShowRequistionCoworker() {
        this.ProjectRequistionCoWorkerList = this.ProjectRequistionCoWorkerList.filter(x => x.isActive == true);
        const dialogRef = this.dialog.open(CoWorkerListComponent, {
            width: '35%',
            disableClose: true,
            data: this.ProjectRequistionCoWorkerList
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.currentselectedcoWorkerObj.id = [];
                result.filter(x => {
                    if (x.isChecked == true)
                        this.currentselectedcoWorkerObj.id.push(x.id);
                })
                if (this.currentselectedcoWorkerObj.id.length > 0)
                    this.AddNewCoWorker();
            }
        });
    }

    viewPendingMaterialOrder() {
        const dialogRef = this.dialog.open(PendingMaterialOrderListComponent, {
            height: "95%",
            width: "95%",
            data: this.projectMaterialListInOrdered
        });
        dialogRef.afterClosed().subscribe(result => { });
    }

    setAndUpdateRequisitionStatus() {
        var tempObj = {
            projectStatus: this.selectStatus,
            RequisitionStatus: this.Requisition.status,
            isDisableForMaler: this.isDisableForMaler
        }
        const dialogRef = this.dialog.open(RequistionStatusComponent, {
            height: '30%',
            width: '30%',
            data: tempObj,
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result != null && result != '') {
                // this.requisitionStatus = result;
                // this.onChangeStatus();
                this.Updatestatusallrequisition(result);
            }
        });
    }

    Updatestatusallrequisition(status) {
        this.projectRequisitionService.Updatestatusallrequisition(this.ProjectId, status).subscribe({
            next: (response: ResponseBase) => {
                if (response) {
                    this.translate.get("MESSAGES.Updated").subscribe({
                        next: (res) => {
                            this.notificationsService.create(res);
                        },
                        error: err => {
                        }
                    });
                }
            },
            error: err => {
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    moveMaterialPopup() {
        var sendData = {};
        sendData = {
            'RequisitionId': this.Requisition.id,
            'sagId': this.ObjProject.sagId,
            'roleName': this.roleName,
            'editedProjectMaterialObj': this.editedProjectMaterialObj,
            'projectMaterials': this.projectMaterials,
            'ObjProject': this.ObjProject,
            'Requisition': this.Requisition,
        }
        var dialogRef = this.dialog.open(MoveMaterialComponent, {
            height: '90%',
            width: '90%',
            data: sendData
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.refreshMaterialList();
                setTimeout(() => {
                    //this.calculateOfferPriceExclVAT();
                    // this.Requisition.materialCreation = result.materialCreation;
                    // this.UpdateRequisition(this.Requisition);
                }, 2000);
            }
        });
    }

    upDownMaterialQuantityDialog(type, data) {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: '200px',
            data: { Header: 'Headers.MaterialUpdownQuantity' },
        });

        dialogRef.afterClosed().subscribe(result => {
            if (type == "material") {
                this.removeProjectMaterial(data, result);
            }
            if (type == "requisition") {
                this.DeleteRequisition(data, result);
            }
            // if(type == "project"){
            // }
        });
    }

    openMaterialDeleteDialogForMultiple() {
        var message = "";
        this.translate.get("Headers.DeleteSelectedMaterial").subscribe({
            next: (res) => {
                message = res;
            }, error: err => { }
        });
        const dialogRef = this.dialog.open(AlertPlanningMessageModelComponent, {
            width: '40%',
            data: {
                Header: message,
                FirstButtonLabel: "Headers.MaterialStockUpdown",
                SecondButtonLabel: "Headers.NoChange",
            }
        });
        dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
                var tempIds = this.projectMaterials.filter(i => i.isChecked == true).map(x => x.id).join();
                if (result == 1)
                    this.removeProjectMaterial(tempIds, true);
                else if (result == 2)
                    this.removeProjectMaterial(tempIds, false);
            }
        });
    }

    onSetCheckedMaterial() {
        let atleastOneUncheckedMat = this.projectMaterials.filter(i => i.isChecked == true)
        if (atleastOneUncheckedMat && atleastOneUncheckedMat.length > 0)
            this.isDeleteSelectedMaterials = true;
        else
            this.isDeleteSelectedMaterials = false;
    }

    interceptTabChange(tab: MatTab, tabHeader: MatTabHeader, idx: number) {
        if (this.isProjectWorkInfoChanged && idx != 9) {
            const dialogRef = this.dialog.open(AlertMessageModelComponent, {
                height: "200px",
                data: { Header: 'Headers.UnsavedChanges' }
            })

            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.isProjectWorkInfoChanged = false;
                    this.isDirty$ = new Observable(ob => { ob.next(false); })
                    return !this.isProjectWorkInfoChanged && MatTabGroup.prototype._handleClick.apply(this._projectTabs, [tab, tabHeader, idx]);
                }
            });
        }
        return !this.isProjectWorkInfoChanged && MatTabGroup.prototype._handleClick.apply(this._projectTabs, [tab, tabHeader, idx]);
    }

    projectWorkInfoChange(e) {
        this.isProjectWorkInfoChanged = e;
        this.isDirty$ = new Observable(ob => { ob.next(e); })
    }
    onCowrkerChange(e) {
        if (e) {
            var tempCoworker = this.coWokers.filter(x => x.id == e);
            if (tempCoworker && tempCoworker.length > 0)
                this.RequisitionList.filter(x => {
                    x.coworkerSeenOnPainting = tempCoworker[0].id;
                    x.coworkerSeenOnPaintingName = tempCoworker[0].name;
                });
        }
    }

    getProjectEmailCount() {
        this.projectService.GetProjectEmailCount<ResponseBase>(this.ProjectId)
            .subscribe((response: ResponseBase) => {
                if (response) {
                    this.projectEmailCount = response.result;
                }
            },
                error => (err) => {
                    this.projectEmailCount = 0;
                });
    }

    isSendPDFFile(data) {
        if (data) {
            this.attachmentFiles = data;
            this.emailToCustomerByProject(this.ObjProject.sagId);
        }
    }

    onCancel() {
        this.titleService.SetTitle("TITLE_PROJECTS")
        this.location.back();
    }

    viewInvoice() {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: '200px',
            data: { Header: 'Headers.ViewPersonalPriceMessage' }
        });

        dialogRef.afterClosed().subscribe(result => {

        });
    }

    deleteFeaturePlanning() {
        var model = {
            requisitionId: this.Requisition.id,
            coworkerId: [''],
            startdate: moment(new Date()).add(1, 'days').format('YYYY-MM-DD'),
            enddate: '',
            projectId: this.ObjProject.sagId
        }
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: '200px',
            data: {
                Header: 'Headers.DeleteFeaturePlanning',
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.workhoursService.DeleteFuturePlanning<ResponseBase>(model)
                    .subscribe({
                        next: (response: ResponseBase) => { },
                        error: err => {
                            this.showSpinner = false;
                            this.translate.get("MESSAGES.Failed").subscribe({
                                next: (res) => {
                                    this.notificationsService.error(res);
                                },
                                error: err => {
                                }
                            });
                        },
                        complete: () => { }
                    });
            }
        });
    }

    createInvoice() {
        const checkMaterialPriseValue = this.projectMaterials.find(x => (x.retailPrice == 0) && x.itemNumber != "51")
        const checkMaterialQuantityValue = this.projectMaterials.find(x => (x.quantity == 0) && x.itemNumber != "51")
        var checkProjectId = this.Customers.find(x => x.id === this.ObjProject.customerId);
        this.requisitionNo = checkProjectId.requisitionNumber;
        var checkPlace = !this.RequisitionOnEditForEconomy.departmentAddress || !this.RequisitionOnEditForEconomy.departmentCity || !this.RequisitionOnEditForEconomy.departmentZipCode;
        var itemNotFoundOrQuantityZero = this.projectMaterials.find(x => (x.itemNumber === "51" || x.itemNumber === "52") && x.quantity > 0);
        // var checkMaterialWorkQuantity = this.projectMaterials.find(x => (x.itemNumber === "51" || x.itemNumber === "52"));
        // if(checkMaterialWorkQuantity)
        //     var getMaterialQuantity = checkMaterialWorkQuantity.quantity;
        //var tolerance = 1.2;
        //var isQuantityAndWorkhourSame = Math.abs(getMaterialQuantity - this.grandTotalWorkhour) <= tolerance;
        //var checkTotalWorkhour = this.grandTotalWorkhour == 0 ? true : false;
        const checkWorkHourValue = !itemNotFoundOrQuantityZero ? true : false; //|| checkTotalWorkhour;

        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: '200px',
            data: { Header: 'Headers.CreateAnInvoice' }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                if (this.requisitionNo && !this.Requisition.requisition) {
                    const dialogRef = this.dialog.open(AlertMessageModelComponent, {
                        height: '200px',
                        data: { Header: 'Headers.RequisitionNumber', ShowOKButton: true }
                    });

                    dialogRef.afterClosed().subscribe(result => {
                        if (result) {
                            this.previousIndex = this.reqTabselectedIndex;
                            this.Requisition.reqTabselectedIndex = 0;
                            this.reqTabselectedIndex = 0;
                        }
                    });
                }
                else if (checkMaterialPriseValue) {
                    const dialogRef = this.dialog.open(AlertMessageModelComponent, {
                        height: '200px',
                        data: { Header: 'Headers.MaterialPriceNot-0', ShowOKButton: true }
                    });

                    dialogRef.afterClosed().subscribe(result => {
                        if (result) {
                            return;
                        }
                    });
                }
                else if (checkMaterialQuantityValue) {
                    const dialogRef = this.dialog.open(AlertMessageModelComponent, {
                        height: '200px',
                        data: { Header: 'Headers.MaterialQuantityNot-0', ShowOKButton: true }
                    });

                    dialogRef.afterClosed().subscribe(result => {
                        if (result) {
                            return;
                        }
                    });
                }
                else if (checkPlace) {
                    const dialogRef = this.dialog.open(AlertMessageModelComponent, {
                        height: '200px',
                        data: { Header: 'Headers.CheckworkPlace' }
                    });

                    dialogRef.afterClosed().subscribe(result => {
                        if (result) {
                            if (checkWorkHourValue) {
                                const dialogRef = this.dialog.open(AlertMessageModelComponent, {
                                    height: '200px',
                                    data: { Header: 'Headers.CheckWorkHour' }
                                });

                                dialogRef.afterClosed().subscribe(result => {
                                    if (result) {
                                        // if(!isQuantityAndWorkhourSame && checkMaterialWorkQuantity){
                                        //     const dialogRef = this.dialog.open(AlertMessageModelComponent, {
                                        //         height: '200px',
                                        //         data: { Header: 'Headers.DifferentHour' }
                                        //     });

                                        //     dialogRef.afterClosed().subscribe(result => {
                                        //         if (result) {
                                        //             this.downloadInvoiceReport()
                                        //         }
                                        //     });
                                        // }
                                        // else{
                                        //     this.downloadInvoiceReport()
                                        // }
                                        this.downloadInvoiceReport()
                                    }
                                });
                            }
                            else {
                                this.downloadInvoiceReport();
                            }
                        }
                    });
                }
                else if (checkWorkHourValue) {
                    const dialogRef = this.dialog.open(AlertMessageModelComponent, {
                        height: '200px',
                        data: { Header: 'Headers.CheckWorkHour' }
                    });

                    dialogRef.afterClosed().subscribe(result => {
                        if (result) {
                            this.downloadInvoiceReport()
                        }
                    });
                }
                // else if(!isQuantityAndWorkhourSame && checkMaterialWorkQuantity){
                //     const dialogRef = this.dialog.open(AlertMessageModelComponent, {
                //         height: '200px',
                //         data: { Header: 'Headers.DifferentHour' }
                //     });

                //     dialogRef.afterClosed().subscribe(result => {
                //         if (result) {
                //             this.downloadInvoiceReport()
                //         }
                //     });
                // }
                else if (this.Requisition.id) {
                    this.downloadInvoiceReport()
                }
            }
        });
    }

    downloadInvoiceReport() {
        this.showSpinner = true;
        this.projectRequisitionService.DownloadInvoiceReport<ResponseBase>(this.Requisition.id, this.Requisition)
            .subscribe({
                next: (response: ResponseBase) => {
                    if (response) {
                        this.Requisition.status = response.result.status;
                        this.requisitionStatus = this.Requisition.status;
                        if (response.result && response.result.billed)
                            this.Requisition.billed = response.result.billed;
                        setTimeout(() => {
                            this.Requisition.isActive = false;
                            this.RequisitionList.filter(x => {
                                if (x.id == this.Requisition.id)
                                    x.isActive = false;
                            })
                        });
                        this.ObjProject.isActive = response.result.projectActive;
                        // var resultData = response.result.inputSringForBase64;
                        // setTimeout(function () {
                        //     var data = resultData.base64.replace("data:text/plain;base64,", "");
                        //     var binary = atob(data.replace(/\s/g, ''));
                        //     var len = binary.length;
                        //     var buffer = new ArrayBuffer(len);
                        //     var view = new Uint8Array(buffer);
                        //     for (var i = 0; i < len; i++) {
                        //         view[i] = binary.charCodeAt(i);
                        //     }
                        //     var download = document.createElement("a");
                        //     var blob = new Blob([view], { type: "text/plain" });
                        //     download.href = window.URL.createObjectURL(blob);
                        //     download.download = 'Projekt_invoice_rapport' + '.txt';
                        //     var event = document.createEvent("MouseEvents");
                        //     event.initMouseEvent(
                        //         "click", true, false, window, 0, 0, 0, 0, 0
                        //         , false, false, false, false, 0, null
                        //     );
                        //     download.dispatchEvent(event);
                        // }, 100)
                        this.showSpinner = false;
                        this.profitModal();
                    }
                },
                error: err => {
                    this.showSpinner = false;
                },
                complete: () => {
                    this.showSpinner = false;
                }
            });
    }

    profitModal() {
        this.isWehaveProfit = false;
        if (this.Requisition.totalIncludeEstimatePrice > 0) {
            setTimeout(() => {
                if (totalProfit > 0)
                    this.isWehaveProfit = true;
            }, 1000);
            const totalProfit = this.getPersonalTotalProfit();
            const dialogRef = this.dialog.open(ProfitHighlightComponent, {
                data: { Header: 'Headers.CheckWorkHour', totalProfit }
            });

            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.isWehaveProfit = false;
                } else {
                    this.isWehaveProfit = false;
                }
            });
        }
    }

    // getWorkHoursTotal(){
    //     this.ProjectsCoWorkerWorkHour = [];
    //     var model = new RequestRequisitionBaseWorkHourGroupBy();
    //     model.StartDate = this.currentYear + "-01-01";
    //     model.EndDate = this.currentYear + "-12-31";
    //     this.GetRequisitionMonthWiseWorkHour(model);
    // }

    openInvoiceTextDescription() {
        // var selectedTabData = new CompanyInfoSetting();
        // selectedTabData.Description = this.Requisition.invoiceText;    
        // const dialogRef = this.dialog.open(TextEditorComponent, {
        //   height: '90%',
        //   width: '90%',
        //   data: { selectedTabData, isEmail: false },
        //   disableClose: true
        // });   
        // dialogRef.afterClosed().subscribe(result => {
        //   this.showSpinner = false;
        //   if (result && result.Description) 
        //     this.Requisition.invoiceText = result.Description;     
        // });

        // if (!text)
        //     return;
        const dialogRef = this.dialog.open(TextareaEditorComponent, {
            height: '400px',
            width: '500px',
            data: { 'displayData': this.Requisition.invoiceText ? this.Requisition.invoiceText : "" }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result.isSave) {
                this.Requisition.invoiceText = result.value;
                // if (this.OfferTypeDetail.offerDiverseDetailList.length > 0) {
                //     this.OfferTypeDetail.offerDiverseDetailList[index].text = result.value;
                // }                
            }
        });
    }

    OpenTextareaEditerPopup(text) {
        if (!text)
            return;
        const dialogRef = this.dialog.open(TextareaEditorComponent, {
            height: '400px',
            width: '500px',
            data: { 'displayData': text ? text : "" }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result.isSave) {
                // if (this.OfferTypeDetail.offerDiverseDetailList.length > 0) {
                //     this.OfferTypeDetail.offerDiverseDetailList[index].text = result.value;
                // }                
            }
        });
    }
}