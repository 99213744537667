export const locale = {
    lang: 'ds',
    data: {
        'TITLE': {
            'MainTitle': 'Indkøbspris / Udsalgspris / Personale pris',
            'MaterialOtherSettingTitle': 'Materiale Andre indstillinger',
        },
        'FIELDS': {
            'PersonalPricePerc': 'Personale pris min.(%) større end Indkøbspris',
            'SellingPricePerc': 'Udsalgspris min.(%) større end Indkøbspris',
            'ErrorMaterialShowOfferMaterial': 'Fejl Materialeliste vis tilbudsmateriale ?',
            'OrderQty': 'Max ordreantal',
            'UseScanner': 'Brug scannerenhed',
            'ShowPersonalPrice': 'Vis personlig pris',
            'WarningPriceForMaxTotalPrice':'Advarsel for max totalpris'
        },
        'VALIDATIONS': {
            'PersonalPricePerc': 'Personlig Pris Procent Påkrævet',
            'SellingPricePerc': 'Sælger Pris Procent Påkrævet',
            'OrderQty': 'Maks. ordremængde påkrævet',
        },
        'FILTERS': {
        },
        'BUTTONS': {
            'Save':'Gem',
        },
        'MESSAGES': {
            'Created': 'Oprettet med succes',
            'Updated': 'Opdateret med succes',
        }
    }
};