import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DatePipe } from '@angular/common';
import { ReminderFull } from 'app/main/model/Reminder';
import * as moment from 'moment';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';

import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';

@Component({
    selector: "edit-reminder",
    templateUrl: "./edit-reminder.component.html",
    styleUrls: ["./edit-reminder.component.scss"]
})

export class VibeEditReminderComponent implements OnInit {
    objReminder: ReminderFull;
    today = new Date();
    constructor(
        public dialogRef: MatDialogRef<VibeEditReminderComponent>,
        private datePipe: DatePipe,
        private translateService: FuseTranslationLoaderService,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.translateService.loadTranslations(danish, english);
    }

    ngOnInit() {
        this.objReminder = new ReminderFull();
        this.objReminder.text = this.data.text;
        this.objReminder.replay = this.data.replay;
        this.objReminder.reminderDate = this.data.reminderDate;
        this.objReminder.reminderTime = this.data.reminderTime;
        this.objReminder.completedByName = this.data.completedByName ? this.data.completedByName : "";
        this.objReminder.reminderStatus = this.data.reminderStatus;
        //this.adapter.setLocale('da');
    }
    updateReminder() {
        this.data.text = this.objReminder.text;
        this.data.replay = this.objReminder.replay ? this.objReminder.replay : "";
        this.data.reminderDate = this.datePipe.transform(this.objReminder.reminderDate, "yyyy-MM-dd") //this.objReminder.reminderDate;
        if (this.objReminder.reminderTime)
            this.data.reminderDate = moment.utc(this.data.reminderDate + " " + this.objReminder.reminderTime).format("YYYY-MM-DD HH:mm");
        this.dialogRef.close(this.data)
    }
    onCloseDialog(e) {
        this.dialogRef.close(e ? this.data : false)
    }
}