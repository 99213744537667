export const locale = {
  lang: 'ds',
  data: {
    'PROJECT': {
      'TITLE': 'Projekt',
      'DESC': 'Hej Projekt!'
    },
    'BUTTONS': {
      'NEWPROJECT': 'Nyt projekt',
      "DefaultCoworker": 'Standard medarbejder',
      'StatusMessage': 'Statusmeddelelse',
      'Tool': 'Værktøj',
      'PriceDiscount':'Prisrabat',
      'ShowInactive': 'At vise Inaktive',
        'VehicleInfo':'Nanolink',
    },
    'TITLE': {
      'ProjectPagination': 'Antal projekter pr. side',
    },
    'FILTERS': {
            'StartDate': 'Start dato',
            'EndDate': 'Slut dato',
            'NoOfEmployee': 'Antal medarbejdere',
            'MaterialPurchasePriseTotal': 'Sum Lager',
            'TotalProjectDaysLeft': 'Projekter resterende Dage',
            'TotalProject': 'I alt projekter',
            'TotalProjectRemainingHours': 'Projekter resterende timer',
            'TotalPlanningRemainingHours': 'Planlægning af resterende timer',
            'TotalPlanningDaysLeft': 'Planlægning resterende dage',
            'TotalPlanningHours': 'Samlet planlægning timer',
            'Coworker': 'Medarbejdere',
            'WorkhoursType': 'Arbejdstid type',
            'nonStatus': 'Status',
            'ShowHourDetails': 'Time beskrivelser',
            'ShowTime': 'Vis Time',
            'HourlyWage': 'Timeløn',
            'TotalProjectPrice': 'I alt projekter Pris'
    },
    'MESSAGES': {
      'NORECORD': 'Intet optage fundet'
    },
    'FIELDS': {
      'IndoorOutDoor': 'Ind, ud, Sprøjt, Reklamation *',
      'Lift': 'Lift',
      'Stigemand': 'Stigemand',
      'CaseNumber': 'Sagsnummer',
      'Title': 'Titel',
      'Client': 'Klient',
      'Address': 'Adresse',
      'ProjectEndDate': 'Indtil dato',
      'EndDate': 'Slut dato',
      'CoWorkers': 'Co-Workers',
      'Files': 'Filer',
      'actionsColumn': 'Handling',
      'From': 'Fra',
      'To': 'til',
      'Not-Found-CUSTOMER': 'Kunde not found',
      'Not-Found-MATERIAL': 'Materiale not found',
      'Not-Found-Worker': 'Medarbejder ikke fundet',
      'TOTAL': 'i alt',
      'Coworker': 'Medarbejdere',
      'HoursBack': 'Timer tilbage',
      'Before60Days': '*** 60 Dag gammel.',
      'NoOfEmployee': 'Antal medarbejdere',
      'MaterialPurchasePriseTotal': 'Sum Lager',
      'RemainingTime': 'Resterende tid',
      'Planned': 'Planlagt',
      'HourlyWage': 'Timeløn',
      'Dankort': 'Dankort',
      'PaymentMode1': 'Dankort',
      'PaymentMode2': 'Mastercard',
      'PaymentMode3': 'kontant',
      'PaymentMode4': 'Mobilpay',
      'PaymentMode5': 'Mobilepay KK',
      'PaymentMode6': 'Kredit',
      'PaymentMode7': 'Andet',
      'visit': 'Besøg',
      'Text': 'Tekst*',
      'OpgaveText': 'Rekvisitions Tekst',
      'InvoiceText': 'Faktura Tekst',
      'StartDate': 'Start dato',
      'TotalProjectDaysLeft': 'Projekter resterende Dage',
      'TotalProject': 'I alt projekter',
      'TotalProjectRemainingHours': 'Projekter resterende timer',
      'TotalPlanningRemainingHours': 'Planlægning af resterende timer',
      'TotalPlanningDaysLeft': 'Planlægning resterende dage',
      'TotalPlanningHours': 'Samlet planlægning timer',
      'WorkhoursType': 'Arbejdstid type',
      'nonStatus': 'Status',
      'ShowHourDetails': 'Time beskrivelser',
      'ShowTime': 'Vis Time',
      'TotalProjectPrice': 'I alt projekter Pris'
    },
    'selectedValueIndoorOutdoorStatus': {
      'value1': 'Indendørs ',
      'value2': 'Udendørs',
      'value3': 'Sprøjtemaling',
      'value4': 'Reklamation',
      'value5': 'Personale køb', //drawing of painter
      'value6': 'Butiks køb'     //Nordsjø logo
    },
    'selectedOption': {
      'value1': 'Nej',
      'value2': 'Ja',
    },
    'SORTBY':
      [
        {
          Text: 'Nummer (0-9)',
          Value: 'Number'
        },
        {
          Text: 'Nummer (9-0)',
          Value: 'Number DESC'
        },
        {
          Text: 'Startdato ↑',
          Value: 'StartDate'
        },
        {
          Text: 'Startdato ↓',
          Value: 'StartDate DESC'
        },
        {
          Text: 'slutdato ↑',
          Value: 'EndDate'
        },
        {
          Text: 'slutdato ↓',
          Value: 'EndDate DESC'
        },
        {
          Text: 'Status ↑',
          Value: 'Status'
        },
        {
          Text: 'Status ↓',
          Value: 'Status DESC'
        }
      ],
    'SORTBY_STATUS': [
      {
        Text: 'Vis alt',
        Value: 0
      },
      {
        Text: 'Process',
        Value: 1
      },
      {
        Text: 'Planlagt besøg',
        Value: 2
      },
      {
        Text: 'Svare ikke',
        Value: 3
      },
      {
        Text: 'Tilbud sendt',
        Value: 4
      },
      {
        Text: 'Tilbud godkendt',
        Value: 5
      },
      {
        Text: 'Planlagt',
        Value: 6
      },
      {
        Text: 'Tilbud afvist',
        Value: 7
      },
      {
        Text: 'Startet',
        Value: 8
      },
      {
        Text: 'Udført',
        Value: 9
      },
      {
        Text: 'Faktureret',
        Value: 10
      },
      {
        Text: 'Reklamation / internt',
        Value: 11
      }
    ]
  }
};
