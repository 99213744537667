export const locale = {
    lang: 'ds',
    data: {
        'Header': {
            "Title": "Post nummer"
        },
        "FIELDS": {
            "Postnumber":"Postnummer",
            "City":"Bynavn",
            "Percentage":"Procent",
            "Remark":"Bemærkning",
            "PostNumberSearch":"Søg efter Postnummer, Bynavn",
            "PostnumberFrom":"Postnummer Fra",
            "PostnumberTo":"Postnummer Til",
        },
        "BUTTONS": {
            "Add": "Tilføj",
            "Reset": "Nulstil"
        },
        'MESSAGES': {
            'Updated': 'Opdateret succesfuldt.',
            'Failed': 'Mislykkedes',
            'Deleted': 'Slettet med succes',
            'NORECORD': 'Intet optage fundet',
            'Created': 'Oprettet med succes',
        },
        'VALIDATIONS': {
            'PostNummber': 'Indtast postnummer',
            'City': 'Indtast by',
            'Percentage': 'Indtast percentage',
            'ValidPercentage': 'Indtast gyldig percentage',
            'ValidPostNumber': 'Indtast gyldig postnummer',
        }
    }
}