export const locale = {
    lang: 'en',
    data: {
        'BUTTONS': {
            'CreateNewProperty': 'Create New Property',
            'PropertyList': 'Property List',
            'SAVE': 'Save',
            'DELETE': 'Delete',
            'BACK': 'Cancel',
        },
        'TABS': {
            'BasicDetail': 'Basic Detail',
            'RoomBuilding': 'Room / Building',
            'Category': 'Category',
        },
        'FIELDS': {
            'NAME': 'Name',
            'PHONE': 'Phone',
            'PROPERTYTYPE': 'Property Type',
            'COLORCODE': 'Color Code',
        },
        'VALIDATIONS': {
            'NAME': 'Enter valid name',
            'ONLY50CHARACTER': 'Maximum length is 50 characters.',
            'PHONENO': 'Enter valid number',
        },
        'TITLE': {
            'PropertyPagination':'Number of property per page',
        },
        'MESSAGES': {
            'DeleteAlert': 'Are you sure you want to delete?',
        },
        'PropertyTypeCategory': {
            'Value1': 'House',
            'Value2': 'Apartment',
        },
        'TOOTIPORTITLE':{
            'DeleteProperty':'Delete Property'
        }
    }
};
