export const locale = {
  lang: 'en',
  data: {
    'FIELDS': {
      'Add': 'Add',
      'SelectProjectToAdd': 'Select Project',
      'SagNo': 'Project No.',
      'Address': 'Address',
      'OpgaverText': 'Task Text',
      'StartDate': 'Start Date ',
      'EndDate': 'End Date ',
      'Zipcode': 'Zip',
      'City': 'City',
      'Number': 'Sr.',
      'Status': 'Status',
    }
  }
}

