export const locale = {
    lang: 'ds',
    data: {
        'HEADERS': {
            'ProjectsList': 'Liste over projekter',
        },
        'FIELDS': {
            'SRNO': 'Sr.',
            'Number': 'Sag nummer',
            'CustomerDetail': 'Debitor Detaljer',
            'Address': 'Sag Adresse',
            'DateTime': "Dato tid",
            'CoworkerName': 'Medarbejder navn',
            'Status': 'Status',
            'ProjectHours': 'Projekt timer',
            'ProjectWorkingHours': 'Projektets arbejdstid',
            'ProjectRemainingHours': 'Projektets resterende timer',
            'IndoorOutDoor': 'Ind, ud, Sprøjt, Reklamation *',
            'ShowAll': 'At vise alle',
            'IsHourlyWage': 'Er sag på timeløn',
            'HourlyWage': 'Er på timeløn',
            'Nummber': 'Nr.',
            'Workplace': 'Arbejdssted',
            'Timelon': 'Timeløn',
            'NegotiatedPrice':'Aftalt pris',
            'Turnover':'Omsætning',
            'Rest':'Rest',
            'Employees':'Timer medarb.',
            'IncludeHourlyWages': 'Inkluder er på timeløn / Display uden beløb',
            'IncludeTurnover': 'Omsætning >0,00'
        },
        'MESSAGES': {
            'Failed': 'Mislykkedes',
            'NORECORD': 'Intet optage fundet',
            'GotoProject': 'Gå til projekt'
        },
        'TITLE': {
            'CustomerPagination': 'Antal log pr. side',
        },
        'IsActive': {
            'value1': 'Aktiv',
            'value0': 'Inaktiv',
        },
        'selectedValueIndoorOutdoorStatus': {
            'value1': 'Indendørs ',
            'value2': 'Udendørs',
            'value3': 'Sprøjtemaling',
            'value4': 'Reklamation',
            'value5': 'Personale køb', //drawing of painter
            'value6': 'Butiks køb'     //Nordsjø logo
        },
    }
}