export const locale = {
    lang: 'en',
    data: {
        'TITLE': {
            'CoworkerReviewQuestion': 'Coworker review questions',
        },
        'FIELDS': {
            'IsSendEmail': 'Is Send Email ?',
            'TabList': 'Tab list setting',
            "Title":'Title',
            "StartDate":'Start Date',
            "EndDate":'EndDate',
            'SRNO': 'Sr No',
            'WeekDays':'WeekDays',
            'Days':'Days',
            'WeekNo':"Uge nr",
        },
        'Message': {
            'Created': 'Created successfully.',
            'Updated': 'Updated successfully.',
            'Deleted': 'Deleted successfully.',
            'Failed': 'Something went wrong !',
            'NoRecords': 'No records found !'
        },
        'FILTERS': {
        },
        'moduleTypeList': {
        },
        'BUTTONS': {
            'Save':'Save',
            'Add': 'Add',
            'Update': 'Update',
            'Delete': 'Delete',
            'Edit': 'Edit',
            'Cancel': 'Undo',
            'Back': 'Back',
            'RepeatMode':'Repeat'
        },
        'VALIDATIONS': {
            'Question': 'Question Required',
            'Desc': 'Question title is required',
            'StartDate': 'Start date is required',
            'endDate': 'End date is required',
        },
    }
};