export const locale = {
  lang: 'ds',
  data: {
    'FIELDS': {
      'ImgNotFound': 'Billede ikke fundet!',
      'Title': 'Du trykker på billedet, det bliver stort',
    },
    'BUTTONS': {
      'Close': 'Tæt',
    },
  }
};
