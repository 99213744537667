import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiConstant } from 'app/core/constant/ApiConstant';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { CompanyInfoSetting, CompanyInfo, CompanyInfoSettingTab } from '../../model/CompanyInfo';
import { EmailSendingParam } from 'app/main/model/common';


@Injectable({
    providedIn: 'root'
})
export class CompanyInfoService {
  private actionUrl: string;
  private userImage = new BehaviorSubject<any>('assets/images/vibe-images/user-profile/default profile picture.jpg');
  userImageIcon = this.userImage.asObservable();

  constructor(private http: HttpClient) {
    this.actionUrl = ApiConstant.API_URL + ApiConstant.COMPANYINFO;
  }


  public Get<T>(id: string): Observable<T> {
    return this.http.get<T>(this.actionUrl + "/" + id);
  }
  
  public GetCoworker<T>(RequestCoWorker: any): Observable<T> {
    var url = ApiConstant.API_URL + ApiConstant.COWORKER + "/";
    let search = this.GetParams(RequestCoWorker);
    return this.http.get<T>(url, {
      params: search
    });
  }

  //edit cmpany information
  public GetCompanyInformationData<T>(): Observable<T> {
    return this.http.get<T>(this.actionUrl + "/companyinfodata");
  }

  public EditCompanyInformationById<T>(id: string): Observable<T> {
    return this.http.get<T>(this.actionUrl + "/" + id);
  }

  public GetAll<T>(RequestCompanyInfo: any): Observable<T> {
    let search = this.GetParams(RequestCompanyInfo);
    return this.http.get<T>(this.actionUrl);
  }

  public UpdateCompanyInformation<T>(CompanyInfo: CompanyInfo): Observable<T> {
    return this.http.put<T>(this.actionUrl + '/information', CompanyInfo);
  }

  public UpdateCompanyInfoSettings<T>(CompanyInfoSetting: CompanyInfoSetting): Observable<T> {
    return this.http.put<T>(this.actionUrl, CompanyInfoSetting);
  }

  public SendEmail<T>(emailSendingParam: EmailSendingParam): Observable<T> {
    var url = ApiConstant.API_URL + ApiConstant.REMINDER + "/";
    return this.http.post<T>(url + "SendEmail", emailSendingParam);
    //return this.http.put<T>(url + id + "/" + emailBody, null);
  }

  public Update<T>(id: any, tabDescription: string): Observable<T> {
    return this.http.put<T>(this.actionUrl + id, tabDescription);
  }

  public UpdateCompanyInfoSettingList<T>(CompanyInfoSettingList: any): Observable<T> {
    return this.http.post<T>(this.actionUrl+'/updateCompanyInfoSettingList', CompanyInfoSettingList);
  }

  public addCompanyInfoSettingTab<T>(tabDescription: CompanyInfoSettingTab): Observable<T> {
    return this.http.post<T>(this.actionUrl+'/addCompanyInfoTab', tabDescription);
  }

  public deleteCompanyInfoTab<T>(id: any): Observable<T> {
    return this.http.delete<T>(this.actionUrl + "/deleteCompanyInfoTab/" + id);
  }

  public GetParams(param) {
    let params: HttpParams = new HttpParams();
    for (var key in param) {
      if (param.hasOwnProperty(key)) {
        let val = param[key];
        params = params.append(key, val);
      }
    }
    return params;
  }
}
