import { Component, OnInit, Inject, ViewChild, Optional } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';

import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { MAT_DIALOG_DATA, MatOption, MatDialog, MatDialogRef } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { CoWorkerFull, CoworkerReminderSettings, CoworkerReminderSettingsReq } from 'app/main/model/CoWorker';
import { RequestStatusProject, RequestBase, RequestCoworkerReminderBase } from 'app/main/model/RequestBase';
import { CoworkerNotificationMessageComponent } from '../notification-message/notification-message.component';
import { CoWorkersService } from '../../coWorkers.service';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { ApiConstant } from 'app/core/constant/ApiConstant';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';

@Component({
    selector: "coWorker-Notification-Modal",
    templateUrl: "./coWorker-notification.component.html",
    styleUrls: ["./coWorker-notification.component.scss"]
})

export class CoWorkerNotificationComponent implements OnInit {
    coWorkers = [];
    currentselectedcoWorkerObj: any = {};
    selectedCoworkerId: string = "";
    showSpinner = false;
    CoWorkerObject: CoWorkerFull;
    CoWorkerName = "";
    coWokersFullData: any = [];
    isNotify: boolean = false;
    isSMS: boolean = false;
    isEmail: boolean = false;
    CoworkerReminderList: CoworkerReminderSettings[];
    coworkerIdsObj = new CoworkerReminderSettingsReq;
    imageBaseUrl = "";
    tempCoworkerList = [];
    notificationValues: any;
    @ViewChild('allSelected', { static: false }) private allSelected: MatOption;
    constructor(private translationLoader: FuseTranslationLoaderService,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
        private translateService: TranslateService,
        private notificationsService: NotificationsService,
        private dialog: MatDialog,
        private dialogRef: MatDialogRef<CoworkerNotificationMessageComponent>,
        private coWorkerService: CoWorkersService,
        private translate: TranslateService) {
        this.translationLoader.loadTranslations(danish, english);
    }

    ngOnInit() {
        this.coWorkers = this.data;
        this.tempCoworkerList = this.data;
        this.coWokersFullData = this.data;
        this.imageBaseUrl = ApiConstant.URL;
        this.getCoworkerReminderSettingsList();
    }

    getCoworkerReminderSettingsList() {
        this.showSpinner = true;
        this.coWorkerService.GetCoworkerReminderSettingsList<ResponseBase>(new RequestCoworkerReminderBase)
            .subscribe((response) => {
                if (response != null) {
                    this.showSpinner = false;
                    if (response.result.length > 0) {
                        this.CoworkerReminderList = Array<CoworkerReminderSettings>();
                        this.CoworkerReminderList = response.result;
                        this.coWorkers = this.tempCoworkerList;
                        for (let i = 0; i < this.CoworkerReminderList.length; i++) {
                            this.coWorkers = this.coWorkers.filter(x => x.id != this.CoworkerReminderList[i].coworkerID);
                        }
                    } else {
                        this.CoworkerReminderList = [];
                    }
                }
            },
                error => (err) => {
                    this.CoworkerReminderList = [];
                    this.showSpinner = false;
                });
    }

    addCoworker() {
        if (this.currentselectedcoWorkerObj.id && this.currentselectedcoWorkerObj.id.length > 0) {
            var coworkerIDS = this.currentselectedcoWorkerObj.id.toString();
        } else {
            this.translate.get("MESSAGES.SelectCoworker").subscribe({
                next: (res) => {
                    this.notificationsService.error(res);
                },
            });
            return;
        }
        this.coworkerIdsObj.CoworkerIDS = coworkerIDS;
        this.showSpinner = true;
        this.coWorkerService.AddCoworkerReminderSettings<ResponseBase>(this.coworkerIdsObj).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                this.currentselectedcoWorkerObj = {};
                this.getCoworkerReminderSettingsList();
                this.translate.get("MESSAGES.Created").subscribe({
                    next: (res) => {
                        this.notificationsService.create(res);
                    },
                    error: err => {
                    }
                });
                error: err => {
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });
                }
            },
            error: err => { this.showSpinner = false },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    UpdateCoworkerReminderList() {
        this.showSpinner = true;
        this.coWorkerService.UpdateCoworkerReminderSettings<ResponseBase>(this.CoworkerReminderList).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                this.currentselectedcoWorkerObj = {};
                this.getCoworkerReminderSettingsList();
                this.translate.get("MESSAGES.Updated").subscribe({
                    next: (res) => {
                        this.notificationsService.create(res);
                    },
                    error: err => {
                    }
                });
                error: err => {
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });
                }
            },
            error: err => { this.showSpinner = false },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    // edit(value) {
    //     this.coWorkerService.GetCoworkerNotifySettingValue(value).subscribe((response: ResponseBase) => {
    //         if (response) {
    //             this.notificationValues = response.result.myResponse;
    //             this.editNotificationMsg()
    //         }
    //     });
    // }

    removeCoworker(Id) {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: '200px',
            data: { Header: 'Headers.DeleteCoworker' }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.delete(Id);
            }
        });
    }

    delete(Id) {
        this.showSpinner = true;
        this.coWorkerService.DeleteCoworkerReminderSetting<ResponseBase>(Id).subscribe({
            next: (response: ResponseBase) => {
                this.getCoworkerReminderSettingsList();
                this.translate.get("MESSAGES.Deleted").subscribe({
                    next: (res) => {
                        this.notificationsService.create(res);
                    },
                    error: err => {
                    }
                });
            },
            error: err => {
                this.showSpinner = false;
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });

            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    editNotificationMsg(value) {
        const dialogRef = this.dialog.open(CoworkerNotificationMessageComponent, {
            height: "50%",
            width: "70%",
            data: value
        })

        dialogRef.afterClosed().subscribe(result => {
            if (result) {

            }
        });
    }

    close() {
        this.dialogRef.close();
    }

    //for search
    DropDownSearchCoWorker(value) {
        var model = new RequestStatusProject();
        model.Index = 1;
        model.Name = value;
        this.coWorkers = this.coWokersFullData.filter(it => {
            return it.name.toLowerCase().includes(model.Name.toLowerCase());
        });
    }

    toggleAllSelection() {
        if (this.allSelected.selected) {
            this.currentselectedcoWorkerObj.id = [];
            this.currentselectedcoWorkerObj.id.push(0);
            for (var i = 0; i < this.coWorkers.length; i++) {
                this.currentselectedcoWorkerObj.id.push(this.coWorkers[i].id);
            }
        } else {
            this.currentselectedcoWorkerObj.id = [];
        }
    }

    DropDownCoWorkerSelect(coworker) {
        this.currentselectedcoWorkerObj.id = this.currentselectedcoWorkerObj.id.filter(o => o != 0);
    }

}