import { Component, Inject, OnInit, Optional } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';

import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { ResponseBase } from 'app/main/model/ResponseBase';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { CustomersService } from "app/main/pages/customer/customer.service";
import { Router } from "@angular/router";
import { RequestCustomerPropertyOffer } from "app/main/model/RequestBase";

@Component({
    selector: 'app-offer-selection',
    templateUrl: './offer-selection.component.html',
    styleUrls: ["./offer-selection.component.scss"]
})

export class OfferSelectionComponent implements OnInit {
    showSpinner: boolean = false;
    customerId:string = "";
    propertyId:string = "";
    customerPropertyOfferList: any;
    chosenOffer: string ="";
    requestCustomerPropertyOffer= new RequestCustomerPropertyOffer();
    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private translate: TranslateService,
        private customersService: CustomersService,
        private _router: Router,
        public dialogRef: MatDialogRef<OfferSelectionComponent>,
        public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.translationLoaderService.loadTranslations(danish, english);
    }

    ngOnInit() {
        this.requestCustomerPropertyOffer.PropertyID = this.data.propertyId;
        this.requestCustomerPropertyOffer.CustomerID = this.data.customerId;
        this.getCustomerPropertyOfferList();
    }

    close() {
        this.dialogRef.close(null);
    }

    getCustomerPropertyOfferList() {
        this.showSpinner = true;
        this.customersService.GetCustomerPropertiesOfferList(this.requestCustomerPropertyOffer).subscribe({
            next: (response: ResponseBase) => {
                this.customerPropertyOfferList = response.result;
                this.showSpinner = false;
            },
            error: err => {
                this.showSpinner = false;
            },
        });
    }

    gotoNewOffer() {
        this.dialogRef.close(null);
        this._router.navigate(['/offers/add']);
    }
    gotoOffer() {
        this.dialogRef.close(null);
        this._router.navigate(['/offers/'+ this.chosenOffer]);
    }
}