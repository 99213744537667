export const locale = {
  lang: 'en',
  data: {
    'Tabs': {
      'PROJECT': 'Projects',
      'PROJECTOFFER': 'Offer',
    },
    'BUTTONS': {
      'NEWSUPPLIER': 'New Supplier',
      'CreateNewSupplier': 'Create new supplier',
      'BACK': 'Back',
        'UploadSupplierList': 'Upload Supplier List',
        'UpdateFromUniconta': 'Update From Uniconta',
    },
    'MESSAGES': {
        'NORECORD': 'No Suppliers Found',
        'Updated':'Updated Successfully',
    },
    'TITLE': {
      'supplierPagination': 'Number Of Suppliers Per Page',
    },
    'FILTERS': {
      'TEXTSEARCH': 'Search By Name, Email, Phone, Address',
      'SORTING': 'Sort By',
    },
    'SORTBY':
      [
        {
          Text: 'Name (A-Z)',
          Value: 'Text'
        },
        {
          Text: 'Name (Z-A)',
          Value: 'Text DESC'
        }
      ]
  }
};
