export const locale = {
  lang: 'en',
  data: {
    'COMPANYINFO': {
      'TITLE': 'Company-Info',
      'DESC': 'Hello Company-Info!'
    },
    'BUTTONS': {
      'SAVE': 'Save',
      'BACK': 'Cancel'
    },
    'HEADER': {
      'ABOUTUS': 'About Us',
      'CONTACTUS': 'Contact Us',
      'WRITEDESCRIPTION': 'Write Description',
      'COMPANYINFOTERMS': 'Company Info Terms',
      'COMPANTINFOOFFERTERMS': 'Offers Terms',
      'LOGO': 'Company Logo',
      'OfferDescription': 'Offer Description',
      'HumidityAndTemperatureDescription': 'Humidity and Temperature',
      'OffersEmailTemplateDescription': 'Email Description',
      'OffersContactUsDescription': 'PDF Description',
    },
    'INFORMATION': {
      'ABOUTUS': {
        'MAINPARA': 'For many years, Vibes Painting Company has lived by faithful customers who return and recommend us.We are proud to keep our agreements and deliver a good job - it is our recipe for success.We have Denmark' + "'" + 's MOST best painters. For more visit',
        'BOLDTEXT': 'www.vibesmalerfirma.dk'
      },
      'CONTACTUS': {
        'ADDRESS': 'Address',
        'EMAIL': 'Email',
        'CoverNumber': 'Cvr.no.',
        'TELEPHONE': {
          'Title': 'Telefon',
          'MainNumber': 'Main Number',
          'StoreNumber': 'Store Number'
        }
      },

    },
    'Tabs': {
      'companyInfo': 'Company Info',
      'store': 'Store',
      'sanne': 'Sanne',
      'kim': 'kim',
      'Holiday': 'Holiday money SH money',
      'martin': 'Martin tasks'
    },
    'Fields': {
      'Conatct1': 'Store Number',
      'Conatct2': 'Main Number',
      'Address': 'Address',
      'DescriptionAboutUs': 'Description About Us',
      'name': 'Name',
      'city': 'City',
      'zip': 'Zipcode',
      'website': 'Website',
      'address': 'Address',
      'aboutUs': 'About us',
      'coverNumber': 'Cover Number',
      'RegistrationNo': 'Registration number.',
      'AccountNo': 'Account no.'
    },
    'MESSAGES': {
      'Failed': 'Operation failed , try later',
      'Success': 'Success.'
    },
    'TOOTIPORTITLE':{
      'Edit':'Edit',
      'Delete':'Delete',
      'SendSMS':'Send SMS',
      'SendEmail':'Send Email',
      'Setting':'Setting'
  }
  }
};
