export const locale = {
    lang: 'en',
    data: {
        'HEADERS': {
            'InactiveProjects': 'Inactive Projects List'
        },
        'FIELDS': {
            'SRNO': 'Sr no.',
            'Number': 'Project Number',
            'Address': 'Project Address',
            'DateTime': "Date Time",
            'CoworkerName': 'Coworker Name',
            'Status': 'Status',
            'CustomerName': 'Customer name',
            'TotalPrice': 'Total price',
            'StartDate': 'Start Date',
            'EndDate': 'End Date',
        },
        "MESSAGES": {
            'Failed': 'Failed',
            "NORECORD": "No Record Found"
        },
        'TITLE': {
            'CustomerPagination': 'Number of logs per. page',
        },
        'IsActive': {
            'value1': 'Active',
            'value0': 'Inactive',
        },
        'Botton': {
            'Search': 'Search',
        }
    }
}