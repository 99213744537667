export const locale = {
    lang: 'en',
    data: {
        'FIELDS': {
            'Name': 'Name',
            'Phone': 'Phone Number',
            'Text': 'Text',
            'CountryCode':'Country Code',
            'Not-Found-Country':'No Country found'
        },
        'MESSAGES': {
            'Success': 'SMS is sent successfully',
            'Failed': 'Failed',
        },
        'BUTTONS': {
            'Send': 'Send',
            'Back': 'Cancel'
        },
        'VALIDATIONS': {
            'Mobile': 'Mobile number is required.',
            'Text': 'Text is required.',
            'CountryCode' : 'CountryCode is required.'
        },
        'FILTERS': {
            'Country': 'Country Name'
        },
    }
};
