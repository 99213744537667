export const locale = {
  lang: 'en',
  data: {
    'Tabs': {
      'Offer': 'Offer',
      'BasicDetail': 'Basic Detail',
      'File': 'File',
      'Category': 'Category',
      'WorkItem': 'Work Item'
    },
    'TITLE': {
      'CustomerPagination': 'Antal log pr. side',
    },
    'MESSAGE': {
      'Failed': 'Operation failed , try later',
      'Success': 'Success.',
      'CommingSoon': 'Comming Soon..',
      'Deleted': 'Deleted successfully',
      'Added': 'Created successfully',
      'Updated': 'Updated successfully',
      'NORECORD': 'No humidity and temperature found',
    },
    'FIELDS': {
      'GoTo': 'Go to',
      'Moisture': 'Moisture %',
      'Temperature': 'Temperature ℃',
      'SRNO': 'Sr No',
      'Status': 'Status',
      'Number': 'Number',
      'Name': 'Name',
      'Createdby': 'Created By',
      'CreatedDate': 'Created Date',
      'ModifyBy': 'Modify By',
      'ModifyDate': 'Modify Date',
      'CoworkerName': 'Coworker',
      'Date': 'Date',
      'Description': 'Description',
      'Action': 'Action',
      'ModuleName': 'Module',
      'ByCoworker': 'by co-worker',
      'ByCustomer': 'by customer',
      'All': 'All',
      'RoomName': 'Room Name',
      'Text': 'Text',
      'SelectRoom': 'Select Room',
    },
    'BUTTONS': {
      'Add': 'Add',
      'Update': 'Update',
      'Cancel': 'Cancel'
    },
    'Headers': {
      'DeleteConformation': 'Do you want to delete',
    }
  }
};
