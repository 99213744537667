export const locale = {
  lang: 'ds',
  data: {
    'MESSAGES': {
      'Add': 'Oprettet',
      'Add_Error': 'Ingen registrering fundet på Vagttider eller Vagtplan bordet, kontakt venligst til administrator.',
      //'Update': 'Updated successfully'
      'statusMsg': 'du kan ikke spare time på rekvisition',
      'CanNotAddHour': 'Rekvisitions status er ikke i Startet, Udført, Klar fakturering, du kan ikke tilføje arbejdstid på denne rekvisition.',
      'Updated': 'Opdateret med succes',
      'Failed': 'Operation mislykket, prøv senere',
      'TimeAlreadyAllocated': 'Den valgte tid er allerede allokeret til medarbejder.'
    },
    'Button': {
      'Ok': 'Indsæt timer / Gem',
      'Cancel': 'Gå tilbage',
      'Showdailyreport': 'Vis daglig rapport'
    },
    'Fields': {
      'SetTimer': 'Tilføj tid',
      'EnterStarttime': 'Indtast starttidspunkt',
      'EndTime': 'Indtast slutstidspunkt',
      'Text': 'Tekst',
      'Week': 'uge',
      'DayHoursTotal': 'Dagtimer i alt',
      'SR.No': 'No',
      'Startime': 'Starttidspunkt',
      'Endtime': 'Slut tid',
      'Total': 'i alt',
      'Project_Number': 'Sags nr.',
      'Requisition': 'Opgave',
      'Status': 'Status',
      'Pause': 'Pause',
      'OpgaveTekst': 'Opgave',
      'breakTime': 'Tilføj pause (30 min)',
      'WorkingHours': 'Arbejdstimer',
      'Date': 'den'
    },
    'Header': {
      'DailyReport': 'Daglig rapport',
      'Youdidnotaddanyentry': 'Du har ikke tilføjet nogen post.',
      'Add7to1130': 'Tilføj 07:00 til 11:30',
      //'Add7to01': 'Tilføj 07:00 til 13:00',
      'Add7to0230': 'Tilføj 07:00 til 14:30',
      'Add7to03': 'Tilføj 07:00 til 15:00',
        'AddHoursManually': 'Tilføj timer manuelt',
        'ProjectWorkInfo': 'Projekt info',
    },
    'selectedWorkHourStatus': {
      'value1': 'Ferie ',
      'value2': 'Syg',
      'value3': 'Feriefridag',
      'value4': 'Fridag m.løn',
      'value5': 'Fridag u.løn',
      'value6': 'Kursus/skole',
      'value7': 'Afspadsering',
    },
    // 'selectedValueStatus': {
    //   'value0': 'Anbefalet',
    //   'value1': 'Kontaktes',
    //   'value2': 'Planlagt besøg',
    //   'value3': 'Svare ikke',
    //   'value4': 'Tilbud sendt',
    //   'value5': 'Tilbud godkendt',
    //   'value6': 'Planlagt',
    //   'value7': 'Tilbud afvist',
    //   'value8': 'Startet',
    //   'value9': 'Udført',
    //   'value10': 'Faktureret',
    //   'value11': 'Afventer',
    //   'value12': 'Klar fakturering',
    // },
    'Days': {
      'Monday': 'Mandag',
      'Tuesday': 'Tirsdag',
      'Wednesday': 'Onsdag',
      'Thursday': 'Torsdag',
      'Friday': 'Fredag',
      'Saturday': 'Lørdag',
      'Sunday': 'Søndag'
    }
  }
};
