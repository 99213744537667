import { Component, OnInit, ElementRef, NgZone, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { ProjectOffer } from '../../../../model/ProjectOffer';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { FuseTranslationLoaderService } from '../../../../../core/services/translation-loader.service';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss']
})
export class ProjectListComponent implements OnInit {
  label: string = "";
  @Output() OfferClickEventOutput = new EventEmitter<any>();
  @Output() GoToTreatments = new EventEmitter<any>();
  @Output() GoToFiles = new EventEmitter<any>();
  @Input() OfferInput: any;
  ProjectOffer: any;
  constructor(private translationLoader: FuseTranslationLoaderService) {
    this.translationLoader.loadTranslations(english, danish);
  }

  ngOnInit() {
    this.ProjectOffer = this.OfferInput;
  }
  getProjectDetails(objId, objNumber) {
    var obj = {
      id: objId,
      number: objNumber
    }
    this.OfferClickEventOutput.emit(obj);
  }

  gotoTreatments(objId, objNumber) {
    var obj = {
      id: objId,
      number: objNumber
    }
    this.GoToTreatments.emit(obj);
  }

  gotoFiles(objId, objNumber) {
    var obj = {
      id: objId,
      number: objNumber
    }
    this.GoToFiles.emit(obj);
  }
}
