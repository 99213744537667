export const locale = {
    lang: 'ds',
    data: {
        'TITLE': {
            'SMSSendConfigHeader': 'SMS konfiguration',
        },
        'FIELDS': {
            'SmsSCIsTimeBased':'Er tid baseret ?',
            'SmsSCIsSendEmail': 'Er send sms ?',
            'SmsSCHoursFromSend': 'Fra kl.',
            'SmsSCHoursToSend': 'Til kl.',
            'EmailTemplate':'E-mail Skabelon',
        },
        'VALIDATIONS': {
            'SmsSCHoursFromSend': 'Timer fra afsendelse er påkrævet',
            'SmsHoursFromSendSmaller': 'Timer fra afsendelse skal være mindre end time til afsendelse',
            'SmsSCHoursToSend': 'Timer at sende er påkrævet',
            'SmsHoursToSendGreater': 'Timer til afsendelse skal være længere end time fra afsendelse',
        },
        'MESSAGES': {
            'Saved': 'Gemt succesfuldt.',
        },
        'BUTTONS': {
            'SmsSCSave': 'Gem',
        }
    }
};
