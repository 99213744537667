import { Component, OnInit, OnDestroy, Output, EventEmitter, Input, ChangeDetectorRef, ViewChild, ElementRef } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { FormGroup, FormBuilder, Validators, NgForm, FormArray } from '@angular/forms';
import { QuestionTypeCategory } from 'app/main/model/Project';
import { TranslateService } from '@ngx-translate/core';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { ProjectofferService } from '../../pages/project-offer/project-offer.service';
import { NotificationsService } from 'angular2-notifications';
import { ProjectQuestionDTO, QuestionParms } from 'app/main/model/Project';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { FormService } from 'app/core/services/form.service';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';
import { MatDialog } from '@angular/material';
import { AuthService } from 'app/core/services/auth.service';
import { Subscription } from 'rxjs';
import { ProjectService } from 'app/main/pages/project/project.service';
import { OfferRejectedQuestionDTO } from 'app/main/model/OfferV2';
import { RequestCoWorker } from "app/main/model/RequestBase";
import { CoWorker } from "app/main/model/CoWorker";
import { CoWorkersService } from "app/main/pages/coWorker/coWorkers.service";

@Component({
    selector: "vibe-add-questions1",
    templateUrl: "./add-questions.component.html",
    styleUrls: ["./add-questions.component.scss"]
})

export class AddQuestions1Component implements OnInit {
    @Input() QuestionList = [];
    @Output("AddOrUpdateQuestionSave") AddOrUpdateQuestionSave: EventEmitter<any> = new EventEmitter();
    @Output("getQuestionList") getQuestionList: EventEmitter<any> = new EventEmitter();

    showSpinner: boolean = false;
    currentObject = new ProjectQuestionDTO();
    currentObjectForOffer = new OfferRejectedQuestionDTO();
    questionAnswerList = [];
    isSaveData: boolean = false;
    questionId: string = "";
    favoriteSeason: boolean = true;
    isEditQuestion: boolean = false;
    ratingArrayList = [];
    IsExpand: boolean = false;
    newOptVal: string = '';

    offerQuestionform: FormGroup;
    typeList = QuestionTypeCategory;
    type: number = 1;
    questionLists = [];
    isAddNew: boolean = false;
    isCheckListDataExist: boolean = false;
    subscriptionForEdit: Subscription;
    subscriptionForDelete: Subscription;
    subscriptionForStatusData: Subscription;
    checkboxQuestionForm: FormGroup;
    coWokers:CoWorker[] = [];
    CoWorkerName: string;
    currentselectedcoWorkerObj: any[] = [];
    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private formBuilder: FormBuilder,
        private projectofferService: ProjectofferService,
        private translate: TranslateService,
        private notificationsService: NotificationsService,
        private formService: FormService,
        private dialog: MatDialog,
        private authService: AuthService,
        private cdr: ChangeDetectorRef,
        private projectService: ProjectService,
        private coWorkersService: CoWorkersService) {
        this.translationLoaderService.loadTranslations(danish, english);
    }
    ngOnInit() {
        this.currentObject = new OfferRejectedQuestionDTO();
        this.questionId = "";
        //this.getQuestionListAPI();
        this.questionAnswerList = this.QuestionList;
        this.offerQuestionform = this.formBuilder.group({
            question: [null, [Validators.required]]
        });
        this.checkboxQuestionForm = this.formBuilder.group({
            //question: [null]
            choiceQuestionOptionsList: new FormArray([])
        })

        this.questionLists.push({
            name: this.currentObject.question,
            type: "Textbox",
            choiceOptions: []
        });

        var coworkerOBJ = new RequestCoWorker();
        coworkerOBJ.Name = "";
        this.getCoWorkers(coworkerOBJ);
    }

    getCoWorkers(model: RequestCoWorker) {
        model.OrderBy = "Name";
        this.coWorkersService.Get<ResponseBase>(model)
            .subscribe((response: ResponseBase) => {
                if (response) {
                    this.coWokers = response.result;
                }
                else {
                    this.coWokers = [];
                }
            }, error => (err) => {
                this.coWokers = [];
            }, () => {
            });
    }

    getQuestionListAPI() {
        this.showSpinner = true;
        this.isSaveData = true;
        this.isEditQuestion = false;
        this.questionId = "";
        //this.projectofferService.GetQuestionList().subscribe({
        this.projectofferService.GetAskOfferQuestionList().subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                this.isSaveData = false;
                for (let i = 0; i < response.result.length; i++) {
                    if (response.result[i].choiceOptions && response.result.length > 0) {
                        //response.result[i].choiceOptions = JSON.parse(response.result[i].choiceOptions);
                        response.result[i].choiceOptions = response.result[i].choiceOptions;
                    }
                }
                this.questionAnswerList = response.result;
            },
        });
    }

    ngAfterViewChecked() {
        //your code to update the model
        this.cdr.detectChanges();
    }

    get f() { return this.checkboxQuestionForm.controls; }
    get t() { return this.f.choiceQuestionOptionsList as FormArray; }

    edit(id) {
        this.CoWorkerName='';
        this.currentselectedcoWorkerObj = [];
        this.isEditQuestion = true;
        this.questionId = id;
        this.isAddNew = true;
        this.currentObject = this.questionAnswerList.find(o => o.id == id);
        this.checkboxQuestionForm = this.formBuilder.group({
            choiceQuestionOptionsList: new FormArray([])
        });
        if(!this.currentObject.questionType)
            this.currentObject.questionType = "Textbox";
        if(this.currentObject.choiceOptions && this.currentObject.choiceOptions.length >0){
            for (let index = 0; index < this.currentObject.choiceOptions.length; index++) {
                this.t.push(this.formBuilder.group({
                    id: [0],
                    name: [null],
                    checked: [false],
                    answerType: [0],
                    question: [null],
                    answer: [null],
                    isSendReminder: [false],
                    reminderMsg: [null],
                    remindCoWorkerIDs: [null],
                    isSendRemindBySMS: [false],
                    isSendRemindByEmail: [false],
                    isSendRemindByNotification: [false],
                }));
                
                if(this.currentObject.choiceOptions[index].isSendReminder) {
                    this.currentselectedcoWorkerObj[index] = {id : [], name : []};
                    this.currentselectedcoWorkerObj[index].id = this.currentObject.choiceOptions[index].remindCoWorkerIDs.split(',');
                    this.currentselectedcoWorkerObj[index].id.forEach(w_id => {
                        this.currentselectedcoWorkerObj[index].name.push(this.coWokers.find(i => i.id==w_id)['name'])
                    });
                }
            }
            this.t.patchValue(this.currentObject.choiceOptions);
        }
    }

    delete(item, id) {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: "200px",
            data: { Header: 'Headers.DeleteQuestion' }
        })

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.questionAnswerList.splice(this.questionAnswerList.indexOf(item), 1)
                this.questionAnswerList.filter(o => {
                    // id = id + 1;
                    // o.id = id;
                })
                this.AddOrUpdateQuestionSave.emit(this.questionAnswerList);
            }
        });

    }

    AddQuestionToList() {            
        let taskListArrays = this.checkboxQuestionForm.get('choiceQuestionOptionsList') as FormArray;
        if (this.currentObject['questionType'] != "Rating" && this.currentObject['questionType'] != "Textbox") {
            this.currentObject['choiceOptions'].forEach((item, index) => {
                if(item.isSendReminder) {
                    item['remindCoWorkerIDs'] = this.currentselectedcoWorkerObj[index]['id'].filter(i => i!=0).toString()
                    taskListArrays.controls[index].patchValue({ "remindCoWorkerIDs": item['remindCoWorkerIDs'] });
                } else {
                    item['remindCoWorkerIDs'] = ''
                    taskListArrays.controls[index].patchValue({ "remindCoWorkerIDs": '' });
                }
            });
        }
        this.formService.markFormGroupTouched(this.offerQuestionform);
        this.checkboxQuestionForm.markAllAsTouched();
        if (this.checkboxQuestionForm.invalid) {
            return;
        }
        // check if value unsaved
        if(this.newOptVal!='' && (this.currentObject.questionType == 'SingleChoice' || this.currentObject.questionType == 'MultiChoice')) {
            const dialogRef = this.dialog.open(AlertMessageModelComponent, {
                height: "200px",
                data: {
                    Header: "MESSAGES.AddTypeListItem",
                    ShowOKButton: true
                }
            });
            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    return;
                }
            });
            return;
        }

        var isSave = false
        isSave = this.offerQuestionform.valid;
        this.currentObject.choiceOptions = this.t.value;

        // CheckListExist
        if (this.currentObject.choiceOptions && this.currentObject.choiceOptions.length == 0 && this.currentObject.questionType != 'Textbox' && this.currentObject.questionType != "Rating") {
            this.isCheckListDataExist = false;
            const dialogRef = this.dialog.open(AlertMessageModelComponent, {
                height: "200px",
                data: {
                    Header: "MESSAGES.AddTypeList",
                    ShowOKButton: true
                }
            });
            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    return;
                }
            });
            return;
        } else {
            if(!this.currentObject.id){
                this.isCheckListDataExist = false;
            }
            else{
                var tempData = this.questionAnswerList.filter(x =>x.id == this.currentObject.id);
                if(tempData && tempData.length > 0)
                    this.isCheckListDataExist = true;
                else
                this.isCheckListDataExist = false;
            }
        }

        if (isSave) {
            if(!this.isCheckListDataExist)
                this.questionAnswerList.push(this.currentObject);
            var id = 0;
            this.questionAnswerList.filter(o => {
                id = id + 1;
                //o.id = id;
                o.tempId = id;
                if (o.choiceOptions && o.choiceOptions.length > 0) {
                    o.choiceOptions = JSON.stringify(o.choiceOptions);
                }
                else
                    o.choiceOptions="";
            })
            this.isEditQuestion = false;
            this.isAddNew = false;
            this.checkboxQuestionForm = this.formBuilder.group({
                choiceQuestionOptionsList: new FormArray([])
            });
        }
        this.AddOrUpdateQuestionSave.emit(this.questionAnswerList);
    }

    AddNewQuestion() {
        this.isAddNew = true;
        this.currentObject = new ProjectQuestionDTO();
    }

    // SaveQuestion(flag) {
    //     this.isAddNew = !flag;
    //     this.AddOrUpdateQuestionSave.emit(this.QuestionList);
    // }

    onSelectType(questionType) {
        this.questionLists = [];
        this.checkboxQuestionForm = this.formBuilder.group({
            choiceQuestionOptionsList: new FormArray([])
        });
        if (this.currentObject.questionType == 'MultiChoice') {
            this.questionLists.push({
                name: "",
                type: "MultiChoice",
                choiceOptions: [],
                answerType: 0,
                question: "",
                answer: "",
                isSendReminder: false,
                reminderMsg: "",
                remindCoWorkerIDs: "",
                isSendRemindBySMS: false,
                isSendRemindByEmail: false,
                isSendRemindByNotification: false,
            });
        } else if (this.currentObject.questionType == 'SingleChoice') {
            this.questionLists.push({
                name: "",
                type: "SingleChoice",
                choiceOptions: [],
                isTextboxAvai: false,
                isTextboxAvaiVal: "",
                isSendReminder: false,
                reminderMsg: "",
                remindCoWorkerIDs: "",
                isSendRemindBySMS: false,
                isSendRemindByEmail: false,
                isSendRemindByNotification: false,
            });
        } else if (this.currentObject.questionType == 'Rating') {
            this.questionLists.push({
                name: "",
                type: "Rating",
                rating: 5
            });
        } else {
            this.questionLists.push({
                name: this.currentObject.question,
                type: "Textbox",
                choiceOptions: []
            });
        }
    }

    //Back to question list
    goToQuestionList() {
        this.questionId = "";
        this.questionLists = [];
        this.questionLists.push({
            name: "",
            type: "Textbox",
            choiceOptions: []
        });
        this.currentObject = new ProjectQuestionDTO();
        this.isAddNew = false;
    }

    //Checkbox
    addCheckItem(form: NgForm, checkList): void {        
        const checkItemVal = form.value.checkItem;
        if (!checkItemVal || checkItemVal === '') {
            return;
        }
        if (!checkList.choiceOptions) {
            checkList.choiceOptions = [];
        }
        const newCheckItem = {
            id: checkList.choiceOptions.length + 1,
            name: checkItemVal,
            checked: false,
            answerType: 0,
            question: "",
            answer: "",
            isSendReminder: false,
            reminderMsg: "",
            remindCoWorkerIDs: "",
            isSendRemindBySMS: false,
            isSendRemindByEmail: false,
            isSendRemindByNotification: false,
        };
        checkList.choiceOptions = this.t.value;
        checkList.choiceOptions.push(newCheckItem);
        let id = 0;
        checkList.choiceOptions.filter(o => {
            id = id + 1;
            o.id = id;
        })
        form.setValue({ checkItem: '' });
        this.checkboxQuestionForm = this.formBuilder.group({
            choiceQuestionOptionsList: new FormArray([])
        });
        for (let index = 0; index < checkList.choiceOptions.length; index++) {
            this.t.push(this.formBuilder.group({
                id: [0],
                name: [null],
                checked: [false],
                answerType: [0],
                question: [null],
                answer: [null],
                isSendReminder: [false],
                reminderMsg: [null],
                remindCoWorkerIDs: [null],
                isSendRemindBySMS: [false],
                isSendRemindByEmail: [false],
                isSendRemindByNotification: [false],
            }));
        }
        this.t.patchValue(checkList.choiceOptions);
    }

    removeChecklistItem(checkItem, checklist, index): void {
        this.t.removeAt(index);
        this.currentselectedcoWorkerObj.splice(index, 1);
    }

    onSelectRadioBtn(checklist, i) {
        this.t.value.forEach(item => {
            item.checked = false;
        });
        this.t.value[i].checked = true;
    }

    getSelectedRating(checklist) {
        if (checklist.rating) {
            this.currentObject.rating = checklist.rating;
        }
    }

    //#region Offer related code

    AddText(checkItem, i) {
        let taskListArrays = this.checkboxQuestionForm.get('choiceQuestionOptionsList') as FormArray;
        taskListArrays.controls[i].patchValue({ "answerType": 1 });
        const validateQuestionField = taskListArrays.controls[i].get('question');
        validateQuestionField.setValidators([Validators.required]);
        validateQuestionField.updateValueAndValidity();
    }

    RemoveText(checkItem, i) {
        let taskListArrays = this.checkboxQuestionForm.get('choiceQuestionOptionsList') as FormArray;
        taskListArrays.controls[i].patchValue({ "answerType": 0 });
        taskListArrays.controls[i].patchValue({ "question": "" });
        const validateQuestionField = taskListArrays.controls[i].get('question');
        validateQuestionField.setValidators(null);
        validateQuestionField.updateValueAndValidity();
    }
    
    removeQuestionValidation(index) {
        for (let i = 0; i < this.currentObject.choiceOptions.length; i++) {
            if (this.currentObject.questionType == 'MultiChoice' && this.currentObject.choiceOptions[index].answerType == 0) {
                const checkboxQuestionControl = this.checkboxQuestionForm.get('question');
                checkboxQuestionControl.setValidators(null);
                checkboxQuestionControl.updateValueAndValidity();
                this.formService.markFormGroupTouched(this.checkboxQuestionForm);
            }
        }
    }
    //#endregion

    //#region send reminder code
    AddReminder(isChecked, optionObject, i) {
        this.currentselectedcoWorkerObj[i] = {id : [], name : []};
        this.CoWorkerName = '';
        let taskListArrays = this.checkboxQuestionForm.get('choiceQuestionOptionsList') as FormArray;
        this.currentObject['choiceOptions'][i]['isSendReminder']=isChecked;
        const validateCoworkerField = taskListArrays.controls[i].get('remindCoWorkerIDs');
        const validateReminderMsg = taskListArrays.controls[i].get('reminderMsg');
        if(isChecked) {
            validateReminderMsg.setValidators([Validators.required]);
            validateReminderMsg.updateValueAndValidity();     

            validateCoworkerField.setValidators([Validators.required]);
            validateCoworkerField.updateValueAndValidity();    
        } else {
            validateReminderMsg.patchValue(null);
            validateReminderMsg.setValidators(null);
            validateReminderMsg.updateValueAndValidity();

            validateCoworkerField.patchValue(null);
            validateCoworkerField.setValidators(null);
            validateCoworkerField.updateValueAndValidity();

            taskListArrays.controls[i].patchValue({ "isSendRemindBySMS": false });
            taskListArrays.controls[i].patchValue({ "isSendRemindByEmail": false });
            taskListArrays.controls[i].patchValue({ "isSendRemindByNotification": false });
        }
    }
    getCoworkerOnClear(value) {
        if (!value) {
            this.DropDownSearchCoWorker(value);
        }
    }
    DropDownSearchCoWorker(value) {
        if (value != null && value != "") {
            var request = new RequestCoWorker();
            request.Name = value;
            this.getCoWorkers(request);
        } else {
            let coWorker = new RequestCoWorker();
            coWorker.IsDeleted = false;
            this.getCoWorkers(coWorker);
        }
    }
    _handleKeydown(event: KeyboardEvent) {
        if (event.keyCode === 13) {
            // do not propagate spaces to MatSelect, as this would select the currently active option
            event.stopPropagation();
        }
    }
    toggleAllSelection(i) {
        var obj = this.currentselectedcoWorkerObj[i].id.find(o => o == 0);
        if (obj == undefined || obj == null) {
            this.currentselectedcoWorkerObj[i] = {id : [], name : []};
            this.currentselectedcoWorkerObj[i].name.push(0);
            this.currentselectedcoWorkerObj[i].id.push(0);
            for (var j = 0; j < this.coWokers.length; j++) {
                this.currentselectedcoWorkerObj[i].name.push(this.coWokers[j].name);
                this.currentselectedcoWorkerObj[i].id.push(this.coWokers[j].id);
            }
        } else {
            this.currentselectedcoWorkerObj[i] = {id : [], name : []};
        }
    }

    onChangeCoWorker(coworker, i) {
        if (this.currentselectedcoWorkerObj[i].id && this.currentselectedcoWorkerObj[i].id.length > 0) {
            this.currentselectedcoWorkerObj[i].id = this.currentselectedcoWorkerObj[i].id.filter(o => o != 0);
            var obj = this.currentselectedcoWorkerObj[i].id.filter(o => o == coworker.id);
            if (obj.length > 0) {
                this.currentselectedcoWorkerObj[i].id = this.currentselectedcoWorkerObj[i].id.filter(o => o != coworker.id);
            }
            else {
                this.currentselectedcoWorkerObj[i].id.push(coworker.id);
            }
        }
        else {
            this.currentselectedcoWorkerObj[i].id = [];
            this.currentselectedcoWorkerObj[i].id.push(coworker.id);
        }
    }
    //#endregion

    ngAfterViewInit() {

    }
    ngOnDestroy() {
        this.authService.editSingleQuestionChange.next("");
        this.authService.deleteSingleQuestionChange.next("");
        this.projectService.onProjectStatusChange.next("");
    }
}