export const locale = {
    lang: 'ds',
    data: {
        'TITLE': {
            'ReminderPagination': 'Antal påmindelser pr. side',
        },
        'BUTTONS': {
            'CreateReminder': 'Lav påmindelse',
            'Cancel': 'Gå tilbage',
        },
        'FIELDS': {
            'To': 'Til',
            'DateToRemind': 'Påmindelsesdato',
            'Text': 'Tekst',
            'TEXTSEARCH': 'Søg',
            'Yes': 'JA',
            'No': 'NEJ',
            'ReceivedToMe': 'Modtaget til mig',
            'SentByMe': 'Sendt af mig',
            'ReminderDate': 'Vælg Påmindelses dato',
            'SORTING': 'Sorter efter',
            'DeleteAll': 'Slet alt',
            'CompletedDate': 'Læst den',
            'Not-Found-REMINDER': 'Der er ingen påmindelser',
            'Reset': 'Opdate',
            'Done': 'Gør alle "Done"',
            'GetAll': 'Vis alle'
        },
        'TABS': {
            'SentByMe': 'Sendt af mig',
        },
        'MESSAGES': {
            'NORECORD': 'Intet optage fundet'
        },
        'TOOTIPORTITLE':{
            'Edit':'Redigere',
            'Delete':'Slet',
            'Expand':'Udvide',
            'ChangeReminderStatus':'Marker Som Færdig'
        }
    }
}