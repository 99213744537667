export const locale = {
    lang: 'ds',
    data: {
        'FIELDS': {
            'Name': 'Name',
            'Questions': 'Spørgsmål',
            'SrNo': 'Sr nr.',
            'Status': 'Status',
            'ProjectStatus':'Projekt status'
        },
        'MESSAGES': {
            'Success': 'SMS is sent successfully',
            'Failed': 'Failed',
          'NORECORD': 'Intet Spørgsmål fundet',
          'SAVESUCCESSFULLY': 'Gem med succes.'
        },
        'BUTTONS': {
            'Save': 'Gem',
            'Back': 'Cancel'
        },
        'VALIDATIONS': {
            'Mobile': 'Mobile number is required.',
            'Text': 'Text is required.'
        },
    }
};
