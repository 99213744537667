export const locale = {
  lang: 'ds',
  data: {
    'Headers': {
      'ViewAlert': 'Ønsker du at åbne denne fil?',
      'View': 'Ja',
      'No': 'NEJ'
    }
  }
};
