export const locale = {
    lang: 'en',
    data: {
        'TITLE': {
            'CreateInvoicePath':'Create invoice path'
        },
        'FIELDS': {
            'ProjectsCoworkerRate': 'Projects Coworker Rate',
            'Path':'path/filename'
        },
        'Message': {

        },
        'FILTERS': {
        },
        'Buttons': {
            'Save': 'Save',
        },
        'Help':{
            'Note':'Please use farward slash(/) only in path setting',
        }
    }
};