import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditImageComponent } from './edit-image.component';
import { MaterialModule } from './../../../../@fuse/material.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule.forChild()

  ],
  exports: [EditImageComponent],
  providers:[],
  declarations: [EditImageComponent]
})
export class EditImageModule { }
