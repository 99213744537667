import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { SettingsService } from '../../settings.service';
import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { ResponseBase } from "app/main/model/ResponseBase";
import { NotificationsService } from "angular2-notifications";
import { TranslateService } from "@ngx-translate/core";
import { Settings, SettingValue } from "app/main/model/Settings";
import { RequestCoWorker } from '../../../../model/RequestBase';
import { CoWorkersService } from '../../../coWorker/coWorkers.service';
import { CoWorker } from '../../../../model/CoWorker';

@Component({
    selector: 'app-credintial-invoice-email',
    templateUrl: './credintial-invoice-email.component.html',
    styleUrls: ['./credintial-invoice-email.component.scss']
})
export class CredintialInvoiceEmailComponent implements OnInit {
    @Input("commonSettings") commonSettings: Settings;

    showSpinner: boolean = false;
    generalForm: FormGroup;
    settingMasterValues: any[] = [];
    credintialObj: Settings;
    settingValue: SettingValue = new SettingValue();
    IsGettingCoWokers: boolean = false;
    coWokers: CoWorker[] = [];

    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private settingsService: SettingsService,
        private notificationsService: NotificationsService,
        private coWorkersService: CoWorkersService,
        private translate: TranslateService,
        private fb: FormBuilder,) {
        this.translationLoaderService.loadTranslations(danish, english);
    }

    ngOnInit() {
        this.credintialObj = new Settings();
        this.settingMasterValues = Object.assign([], JSON.parse(localStorage.getItem("settingMasterValues")));
        this.generalForm = this.fb.group({
            CoworkersID: [null, Validators.required],
            Email: [null],
            EmailPassword: [null],
            ImapServer: [null],
            ImapPort: [null],
            IsSendSMS: [false],
            IsSendNotification: [false],
            SMSText: [null],
            NotificationText: [null],
        });
        this.setSettings();
         var coworkerOBJ = new RequestCoWorker();
         coworkerOBJ.Name = "";
         this.getCoWorkers(coworkerOBJ);
    }

    getCoWorkers(model: RequestCoWorker) {
        model.OrderBy = "Name";
        this.IsGettingCoWokers = true;
        this.coWorkersService.Get<ResponseBase>(model)
            .subscribe((response: ResponseBase) => {
                if (response) {
                    this.coWokers = Object.assign([], response.result);
                    this.getNextRequest(response.pagination, model, response.result);
                }
                else {
                    this.coWokers = [];
                    this.IsGettingCoWokers = false;
                }
            },
                error => (err) => {
                    this.coWokers = [];
                    this.IsGettingCoWokers = false;
                },
                () => {

                });
    }

    getNextRequest(pagination, model, response) {
        if (pagination.index == 1) {
            this.coWokers = Object.assign([], response);
            this.IsGettingCoWokers = false;
        }
        else {
            this.coWokers.push(...response);
            this.IsGettingCoWokers = false;
        }
        model.Index = pagination.index + 1;
        model.Limit = pagination.limit;
        model.TotalRows = pagination.totalCount;
        if (pagination.totalCount > pagination.index * pagination.limit)
            this.getCoWorkers(model);
    }

    setSettings() {
        if (this.commonSettings && this.commonSettings != null) {
            this.credintialObj = this.commonSettings;
            this.settingValue = JSON.parse(this.commonSettings.value);
            this.generalForm.patchValue(this.settingValue)
            this.changeInLocalStorage(this.credintialObj.id, this.credintialObj)
        }
    }

    onSubmit() {
        this.generalForm.markAllAsTouched();
        if (this.generalForm.valid) {
            this.changeSettings()
        }
    }

    changeSettings() {
        this.showSpinner = true;
        this.credintialObj.value = JSON.stringify(this.generalForm.value);
        this.credintialObj.isDefaultMapper = true;
        this.settingsService.UpdateSettingWithTemplate<ResponseBase>(this.credintialObj).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.result) {
                    this.credintialObj = response.result;
                    this.setSettingsAfterUpdate();
                    this.translate.get("MESSAGES.Saved").subscribe({
                        next: (res) => {
                            this.notificationsService.create(res);
                        },
                        error: err => { }
                    });
                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    setSettingsAfterUpdate() {
        this.settingsService.GetSettingsWithTemplateValue<ResponseBase>("Credential", this.commonSettings.key2, null).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.result && response.result.myResponse.length > 0) {
                    this.credintialObj = response.result.myResponse[0];
                    this.settingValue = JSON.parse(this.credintialObj.value)
                    this.generalForm.patchValue(this.settingValue);
                    this.changeInLocalStorage(this.credintialObj.id, this.credintialObj)
                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    changeInLocalStorage(ID, new_obj) {
        if (this.settingMasterValues.find(obj => obj.id == ID)) {
            this.settingMasterValues.forEach((old_obj, index) => {
                if (old_obj.id == ID) {
                    this.settingMasterValues[index] = new_obj
                }
            })
        } else {
            this.settingMasterValues.push(new_obj)
        }
        localStorage.setItem("settingMasterValues", JSON.stringify(this.settingMasterValues));
    }

}
