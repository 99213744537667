import { Component, OnInit, Input, Output, EventEmitter, Inject, Optional, ViewChild, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { CompanyInfoSetting } from 'app/main/model/CompanyInfo';
import { TextEditorComponent } from 'app/main/pages/text-editor/text-editor.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, } from '@angular/material';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { ApiConstant } from 'app/core/constant/ApiConstant';
import { NotificationsService } from 'angular2-notifications';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FormService } from 'app/core/services/form.service';
import { MaterialService } from '../../material.service';
import { Material } from 'app/main/model/Material';
import { CommonService } from 'app/core/services/common.service';
import { spaceOnlyValidator } from 'app/core/Validators/validation';


@Component({
    selector: 'vibe-barcode',
    templateUrl: './barcode.component.html',
    styleUrls: ['./barcode.component.scss']
})

export class BarcodeComponent implements OnInit {
    @ViewChild("Barcode", { static: false }) barcodeNumber: ElementRef;
    materialForm: FormGroup;
    barcode: string;
    MaterialObject: Material;
    isExist: boolean = false;
    barcodeNo: number = 1;

    constructor(private translate: TranslateService,
        private translationLoader: FuseTranslationLoaderService,
        private dialog: MatDialog,
        private notificationsService: NotificationsService,
        public dialogRef: MatDialogRef<BarcodeComponent>,
        public materialService: MaterialService,
        private formBuilder: FormBuilder,
        public formService: FormService,
        private commonService: CommonService,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
        this.MaterialObject = this.data.MaterialObject;
        this.barcodeNo = this.data.barcodeNo;
        if (this.barcodeNo == 1) {
            this.barcode = this.MaterialObject.barcode;
        }
        if (this.barcodeNo == 2) {
            this.barcode = this.MaterialObject.barcode2;
        }
        this.materialForm = this.formBuilder.group({
            Barcode: [null, Validators.compose([Validators.required, spaceOnlyValidator.bind(this)])]
        });
    }

    isBarcodeExists() {
        if (this.materialForm.valid) {
            if (this.barcode) {
                this.materialService.GetMaterialByBarcode(this.barcode).subscribe({
                    next: (response: ResponseBase) => {
                        var data = response.result;
                        if (data && (data.itemNumber != this.MaterialObject.itemNumber) && data.barcode == this.barcode) {
                            this.translate.get("MESSAGES.Barcode").subscribe({
                                next: (res) => {
                                    this.isExist = true;
                                    this.notificationsService.error(res);
                                    this.commonService.playFailedTone();
                                    const materialBarcodeControl = this.materialForm.get('Barcode');
                                    materialBarcodeControl.setValidators([Validators.minLength(25)]);
                                    materialBarcodeControl.updateValueAndValidity();
                                },
                                error: err => {
                                }
                            });
                        } else {
                            this.isExist = false;
                            //this.dialogRef.close();
                            if (this.barcodeNo == 1) {
                                this.MaterialObject.barcode = this.barcode;
                            }
                            if (this.barcodeNo == 2) {
                                this.MaterialObject.barcode2 = this.barcode;
                            }
                            this.dialogRef.close(this.MaterialObject);
                        }
                    },
                    error: err => {
                        if (this.barcodeNo == 1) {
                            this.MaterialObject.barcode = this.barcode;
                        }
                        if (this.barcodeNo == 2) {
                            this.MaterialObject.barcode2 = this.barcode;
                        }
                        this.dialogRef.close(this.MaterialObject);
                    }
                });
            } else {
                if (this.barcodeNo == 1) {
                    this.MaterialObject.barcode = this.barcode;
                }
                if (this.barcodeNo == 2) {
                    this.MaterialObject.barcode2 = this.barcode;
                }
                this.dialogRef.close(this.MaterialObject);
                //const materialBarcodeControl = this.materialForm.get('Barcode');
                //materialBarcodeControl.setValidators([Validators.required]);
                //materialBarcodeControl.updateValueAndValidity();
                //this.barcodeNumber.nativeElement.focus();
            }
        }
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.barcodeNumber.nativeElement.focus();
        }, 1000)
    }

    deleteBarcode() {
        this.barcode = "";
        this.isExist = false;
        this.barcodeNumber.nativeElement.focus();
       // const materialBarcodeControl = this.materialForm.get('Barcode');
       // materialBarcodeControl.setValidators([Validators.required, spaceOnlyValidator.bind(this)]);
       // materialBarcodeControl.updateValueAndValidity();
    }

    ngOnBackClick() {
        this.dialogRef.close();
    }

}
