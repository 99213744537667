import { Component, OnInit, Optional } from '@angular/core';
import { Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FuseTranslationLoaderService } from '../../../../core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { ProjectService } from '../../project/project.service';
import { TranslateService } from '@ngx-translate/core';
import { ResponseBase } from '../../../model/ResponseBase';
@Component({
    selector: 'app-select-project-on-workhours-modal',
    templateUrl: './select-project-modal.component.html',
    styleUrls: ['./select-project-modal.component.scss']
})
export class SelectProjectOnWorkhoursModalComponent implements OnInit {
    showSpinner: boolean = false;
    projectCoWorkerList = [];
    showCoworkerList: boolean = false;
    coworkerId: string = "";
    constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<SelectProjectOnWorkhoursModalComponent>,
        private dialog: MatDialog,
        private translate: TranslateService,
        private projectservice: ProjectService,
        private translationLoader: FuseTranslationLoaderService) {
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
    }

    selectProjectEventHandler(event) {
        this.showSpinner = true;
        var projectId = event.sagId;
        var projectNum = event.sagNumber;
        this.projectservice.GetCoworkerOnProject(projectId).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                this.showCoworkerList = true;
                if (response) {

                    this.projectCoWorkerList = response.result;
                    if (this.projectCoWorkerList.length > 0) {
                        this.projectCoWorkerList = this.projectCoWorkerList.map(x => ({ ...x, isChecked: false }));
                        this.projectCoWorkerList = [...new Map(this.projectCoWorkerList.map(item =>
                            [item['id'], item])).values()];

                    } else {
                        //this.showCoworkerList = false;
                        this.projectCoWorkerList = [];
                    }
                }
            },
            error: err => {
                this.showSpinner = false;
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        //this.notificationsService.error(res);
                    },
                    error: err => {
                        this.projectCoWorkerList = [];
                    }
                });
            },
        });
    }

    getCoworker(id) {
        this.coworkerId = id;
    }

    gotToWorkHours() {
        this.dialogRef.close(this.coworkerId);
    }

    backToProject() {
        this.showCoworkerList = false;
    }
}
