import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';

import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { TranslateService } from '@ngx-translate/core';
import { TitleService } from 'app/core/services/title.service';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { MaterialService } from 'app/main/pages/material/material.service';
import { MaterialLinetype } from 'app/main/model/ProjectStatus';
import { MaterialWeatherType } from 'app/constant/common.constant';

@Component({
    selector: 'vibe-Material-Log-Detail',
    templateUrl: './material-log-detail.component.html',
    styleUrls: ['./material-log-detail.component.scss']
})
export class MaterialLogDetailComponent implements OnInit {
    materialLogsDetail: any = [];
    oldItem: any = {};
    newItem: any = {};
    selectLinetype = MaterialLinetype;
    materialWeatherType = MaterialWeatherType;
    SupplierList:any[]=[];
    logData: any[];
    showSpinner: boolean = false;

    currentId: any;
    previousId: any;
    nextId: any;
    tempLogId: any;

    //Initiators
    constructor(private translationLoader: FuseTranslationLoaderService,
        private _router: Router,
        private titleService: TitleService, 
        private translate: TranslateService,
        private materialService: MaterialService,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
        this.getMaterialLogsDetails(this.data.id)
        this.SupplierList = this.data.supplierList;
        this.tempLogId = this.data.id;
        if(this.data && this.data.logData)
            this.logData = this.data.logData;
    }

    getSupplierName(id) {
        if(id) {
            return this.SupplierList.find(i => i.id==id)['business'];
        } else {
            return '-'
        }
    }

    getLinetypeVal(id) {
        if(id) {
            return this.selectLinetype.find(i => i.id==id)['key'];
        } else {
            return '-'
        }
    }

    getIcon(icon) {
        return 'icon-' + icon;
    }

    getWeatherTypeVal(id) {
        if(id) {
            return this.materialWeatherType.find(i => i.id==id)['key'];
        } else {
            return '-'
        }
    }

    getWeatherTypeIcon(id) {
        if(id) {
            return 'icon-' + this.materialWeatherType.find(i => i.id==id)['icon'];
        } else {
            return '-'
        }
    }

    getMaterialLogsDetails(id) {
        this.showSpinner = true;
        this.materialService.GetMaterialLogDetails<ResponseBase>(id)
            .subscribe({
                next: (response: ResponseBase) => {
                    if (response != null && response.result) {
                        this.materialLogsDetail = response.result;
                        this.oldItem = response.result.length > 1 ? response.result[1] : [];
                        this.newItem = response.result[0];
                        this.tempLogId = this.newItem.id;
                        this.getSetNextPreviousId();
                    }
                    else {
                        this.materialLogsDetail = [];
                    }
                },
                error: err => {
                    this.showSpinner = false;
                    this.materialLogsDetail = [];
                },
                complete: () => {
                    this.showSpinner = false;
                }
            });
    }

    // gotoModule($event){
    //     this.materialLogsDetail={};
    //     this.getMaterialLogsDetails($event)
    // }

    gotoMaterial(id) {
        if (this.logData) {
            this.tempLogId = id;
            this.currentId = this.tempLogId;
            this.getSetNextPreviousId();
            this.materialLogsDetail={};
            this.getMaterialLogsDetails(this.currentId)
        }
    }

    getSetNextPreviousId() {
        this.currentId = this.tempLogId;
        var itemIndex = this.logData.map(function (o) { return o.id; }).indexOf(this.currentId);
        if (itemIndex > 0)
            this.previousId = this.logData[itemIndex - 1].id;
        else
            this.previousId = null;

        if (itemIndex != (this.logData.length - 1))
            this.nextId = this.logData[itemIndex + 1].id;
        else
            this.nextId = null;
    }
}
