export const locale = {
    lang: 'en',
    data: {
        'BUTTONS': {
            'Add': 'Add',
            'Back': 'Back',
            'Save': 'Save'
        },
        'FIELDS': {
            'All': 'All',
            'Coworker': 'Coworker',
            'SrNo': 'Sr no.',
            'PlanningLeave': 'Planning Leave',
            'PlanningRemove':'Planning Remove',
            'Inquiry': 'Inquiry',
            'Not-Found-Worker': 'Co-worker not found',
            'Offer': 'Offers',
            'Before7DaysProjectReminder': 'Before 7 days project reminder',
        },
        'MESSAGES': {
            'Created': 'Created successfully',
            'Updated': 'Updated successfully',
            'Deleted': 'Deleted successfully',
            'Failed': 'Operation failed , try later',
            'NORECORD': 'No Record Found',
            'SelectCoworker':'Select Coworker'
        },
    }
}