export const locale = {
    lang: 'en',
    data: {
        'TITLE': {
            'OfferSMSConfiguration': 'SMS Text When We Send Offers',
        },
        'FIELDS': {
            'SMSText': 'SMS text',
            'EmailText': 'Email text',
            'SmsNoteLabel':'Please do not remove @CustomerEmail from SMS template text.'
        },
        'VALIDATIONS': {
            'SMSText': 'SMS text is required',
        },
        'MESSAGES': {
            'Saved': 'Saved successfully.',
        },
        'BUTTONS': {
            'Save': 'Save',
        }
    }
};