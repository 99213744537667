export const locale = {
    lang: 'ds',
    data: {
        'Header': {
            "Title": "Indtast Løn"
        },
        "FIELDS": {
            // "Postnumber":"Postnummer",
            // "City":"Bynavn",
            // "Percentage":"Procent",
            // "Remark":"Bemærkning",
            // "PostNumberSearch":"Søg efter Postnummer, Bynavn",
            // "PostnumberFrom":"Postnummer Fra",
            // "PostnumberTo":"Postnummer Til",

            'Coworker': 'Medarbejdere',
            'Date': 'Dato',
            'NumberofHours': 'Antal',
            'HourlyRate': 'Pris',
            'TotalAmount': 'Totalbeløb',
            'Description': 'Beskrivelse',
            'koersel': 'Kørsel',
            'GDag': 'G-dage',
            'Holidays': 'Feriedage',
            'NumberofHolidays': 'Antal fridage',
            // 'koerselLabel': 'Koersel',
            'koerselLabel': 'kørsel',
            'GDagLabel': 'G-dage',
            'HolidaysLabel': 'Feriedage',
            'SalaryAdvanceLabel':'Lønforskud',
            'OtherLabel':'Andet'
        },
        "BUTTONS": {
            "Save": "Gem",
            "BACK": "Tilbage"
        },
        'MESSAGES': {
            'Updated': 'Opdateret succesfuldt.',
            'Failed': 'Mislykkedes',
            'Deleted': 'Slettet med succes',
            'NORECORD': 'Intet optage fundet',
            'Created': 'Oprettet med succes',
        },
        'VALIDATIONS': {
            // 'PostNummber': 'Indtast postnummer',
            // 'City': 'Indtast by',
            // 'Percentage': 'Indtast percentage',
            // 'ValidPercentage': 'Indtast gyldig percentage',
            // 'ValidPostNumber': 'Indtast gyldig postnummer',

            'CoworkerRequired': "Vælg Medarbejdere",
            'HourlyRateRequired': 'Pris er påkrævet',
            'DateRequired': "Dato påkrævet",
            'TotalAmountRequired': "Vælg Totalbeløb",
            'DesciptionRequired': "Vælg Beskrivelse",
            'NumberofHoursRequired': "Vælg Antal",
            'PayrollTypeRequired': "Vælg Indtast Løn ",
        }
    }
}