import { Component, OnInit, ViewChild } from '@angular/core';
import { FuseTranslationLoaderService } from '../../../core/services/translation-loader.service';
import { Router, NavigationExtras } from '@angular/router';

import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { MatPaginator, MatSort, MatTableDataSource, MatDialog } from '@angular/material';
import { PayrollService } from './payroll.service';

import { CoWorker, CoWorkerFull } from '../../model/CoWorker';
import { RequestPayroll } from '../../model/RequestBase';
import { ResponseBase } from '../../model/ResponseBase';
import { AuthService } from '../../../core/services/auth.service';
import { TitleService } from '../../../core/services/title.service';
import { LoanEntryComponent } from './component/LoanEntry/loanEntry.component';


@Component({
  selector: 'vibe-payroll',
  templateUrl: './payroll.component.html',
  styleUrls: ['./payroll.component.scss']
})

export class VibePayrollComponent implements OnInit {

  //Variable Diclarartion
  coWorkers: CoWorker[];
  // isValid: boolean = false;
  data: any;
  Name: string = '';
  pagination: any;
  limitNumber: any;
  displayPagination: boolean = false;
  // roleName: string;
  orderBy: string = "Name";
  showSpinner: boolean = false;
  isActive: boolean = true;
  isDeleted: boolean = false;
  roleClaims: any;
  hasOwnPayrollViewPermision: boolean = false;
  loggedInCoworkerID: string;
  constructor(private translationLoader: FuseTranslationLoaderService,
    private payrollService: PayrollService,
    private _router: Router,
    private authService: AuthService,
    private titleService: TitleService,
    private dialog: MatDialog) {
    this.titleService.SetTitle("TITLE_PAYROLL");
    this.translationLoader.loadTranslations(english, danish);
    this.roleClaims = this.authService.getRoleClaimsList();
  }

  ngOnInit() {
    this.loggedInCoworkerID = this.authService.getCoWorkerId();
    this.authService.hasClaim(this.roleClaims.Own_Payroll_View).subscribe(hasClaim => {
      if (hasClaim) 
          this.hasOwnPayrollViewPermision = hasClaim
       else 
          this.hasOwnPayrollViewPermision = false;
   });
   this.GetCoWorker(new RequestPayroll());
    // this.roleName = this.authService.getUserRole();

    // if (this.roleName == "Admin") {
    //   this.isValid = true;
    // }
    // else if (this.roleName == "Kontor") {
    //   this.isValid = true;
    // }
    // else if (this.roleName == "Maler") {
    //   this.isValid = false;
    // }
    // else if (this.roleName == "Gaest") {
    //   this.isValid = false;
    // }
    // else {
    //   this.isValid = true;
    // }

  }

  GetCoWorker(model: RequestPayroll) {
    this.showSpinner = true;
    model.OrderBy = this.orderBy;
    model.IsActive = this.isActive;
    if(this.hasOwnPayrollViewPermision)
      model.CoworkerID = this.loggedInCoworkerID;
    this.payrollService.Get<ResponseBase>(model)
      .subscribe((response: ResponseBase) => {
        if (response != null) {
          this.coWorkers = Array<CoWorker>();
          for (var i = 0; i < response.result.length; i++) {
            if (!response.result[i].userId)
              response.result[i].displayButton = false;
            else
              response.result[i].displayButton = true;
            this.coWorkers.push(response.result[i]);
          }
          this.coWorkers = response.result;
          //this.GetInCaseOfAccidentDetail(this.coWorkers);
          this.pagination = response.pagination;
          this.displayPagination = true;
          this.showSpinner = false;

        }
        else {
          this.coWorkers = [];
          this.displayPagination = false;
          this.showSpinner = false;

        }
      },
        error => (err) => {
          this.coWorkers = [];
          this.displayPagination = false;
          this.showSpinner = false;

        },
        () => {
        });
  }

  getDeletedCoworkers(isDeleted) {
    var model = new RequestPayroll();
    model.IsDeleted = this.isDeleted = isDeleted;
    this.GetCoWorker(model)
  }

  checkAccess(id) {
    this.EditCoWorker(id);
  }

  EditCoWorker(id) {
      this._router.navigate(['/payroll/list', id, this.isActive]);
  }

  Search(value) {
    var request = new RequestPayroll();
    if (value == undefined || value.toString().length % 1 == 0) {
      request.Name = this.Name.trim();
      request.OrderBy = this.orderBy;

      this.GetCoWorker(request);
    }
  }

  // isValidForm() {
  //   return this.isValid;
  // }

  receivePaginatonEvent(event) {
    var request = new RequestPayroll();
    request.Index = event.pageIndex;
    request.Limit = event.pageSize;
    this.limitNumber = event.pageSize;

    request.Name = this.Name.trim();
    this.CreateCoWorkerRequest(request);
  }

  receiveFilterEvent(event) {
    var request = new RequestPayroll();
    this.orderBy = event;
    if (this.limitNumber == undefined) {
      request.Limit = 100
    }
    else {
      request.Limit = this.limitNumber;
    }
    this.CreateCoWorkerRequest(request);
  }

  CreateCoWorkerRequest(request) {
    request.Name = this.Name.trim();
    if (this.orderBy != undefined)
      request.OrderBy = this.orderBy;
    this.GetCoWorker(request);
  }

  isNumber(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  // GetInCaseOfAccidentDetail(value: CoWorker[]) {
  //   value.map(c => {
  //     c.inCaseOfAccidentDetail = [];
  //     if (c.inCaseOfAccident != null)
  //       c.inCaseOfAccidentDetail = JSON.parse(c.inCaseOfAccident);
  //     else {
  //       c.inCaseOfAccidentDetail.push(new CoWorker());
  //     }
  //   })
  // }

  onSetActive(active) {
    this.isActive = active;
    var request = new RequestPayroll();
    request.OrderBy = this.orderBy;
    request.Name = this.Name.trim();
    this.GetCoWorker(request);
  }

  addLoanEntry() {
    const dialogRef = this.dialog.open(LoanEntryComponent, {
      height: "80%",
      width: "35%",
      data: ""
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        var request = new RequestPayroll();
        request.OrderBy = this.orderBy;
        request.Name = this.Name.trim();
        this.GetCoWorker(request);
      }
    });

  }
}



