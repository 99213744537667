import { Component, OnInit } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { NanolinkService } from 'app/main/pages/nanolink/nanolink.service';
import { NotificationsService } from 'angular2-notifications';
import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { ResponseBase } from 'app/main/model/ResponseBase';
import { RequestAssetsList } from "app/main/model/RequestBase";
import { TitleService } from 'app/core/services/title.service';
import { Router } from "@angular/router";
import { AuthService } from "app/core/services/auth.service";
import { AssetsLabels } from "app/constant/common.constant";

@Component({
    selector: "assets",
    templateUrl: "./assets.component.html",
    styleUrls: ["./assets.component.scss"]
})
export class AssetsComponent implements OnInit {
    showSpinner: boolean = false;
    assetsList: [] = [];
    pagination: any;
    displayPagination: boolean;
    requestBase: any;
    orderBy: string = "";
    roleClaims: any;
    currentselectedLables: any = "";
    selectAssetsLables = AssetsLabels;

    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private translate: TranslateService,
        private nanolinkService: NanolinkService,
        private notificationsService: NotificationsService,
        private authService: AuthService,
        private titleService: TitleService,
        private _router: Router) {
        this.translationLoaderService.loadTranslations(danish, english);
        this.titleService.SetTitle("TITLE_ASSETS");
        this.roleClaims = this.authService.getRoleClaimsList();
    }

    ngOnInit() {
        this.requestBase = new RequestAssetsList();
        this.requestBase.Index = 1;
        this.requestBase.Limit = 100;
        //this.requestBase.Labels = null;
        this.getAssetsList();
    } 

    getAssetsList() {
        this.requestBase.Labels = this.currentselectedLables ? this.currentselectedLables.toString() : "";
        this.showSpinner = true;
        this.nanolinkService.GetAssetsList(this.requestBase).subscribe({
            next: (response: ResponseBase) => {
                if (response) {
                    this.assetsList = response.result;
                    this.pagination = response.pagination;
                    this.displayPagination = true;
                }
            },
            error: err => {
                this.showSpinner = false;
                this.assetsList = [];
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    editAssets(id) {
        this._router.navigate(['nanolink/assets/edit', id],{ }); 
    }
    addAssets() {
        this._router.navigate(['nanolink/assets/add'],{ }); 
    }

    receivePaginatonEvent(event) {
        this.requestBase.Index = event.pageIndex;
        this.requestBase.Limit = event.pageSize;
        this.getAssetsList();
    }

    receiveFilterEvent(event) {
        this.requestBase.OrderBy = event;
        this.getAssetsList();
    }

    SearchBynumber() {
        this.getAssetsList();
    }

}