import { Component, OnInit, Inject } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';

import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Settings } from 'app/main/model/Settings';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { CoWorkersService } from '../../coWorkers.service';

@Component({
    selector: "Notification-message",
    templateUrl: "./notification-message.component.html",
    styleUrls: ["./notification-message.component.scss"]
})

export class CoworkerNotificationMessageComponent implements OnInit {
    showSpinner: boolean = false;
    notificationValues: any;
    SettingsObj: Settings = new Settings();
    notificationValuesParams: string = "";
    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private translateService: TranslateService,
        private notificationsService: NotificationsService,
        private dialogRef: MatDialogRef<CoworkerNotificationMessageComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private translate: TranslateService,
        private coWorkerService: CoWorkersService) {
        this.translationLoaderService.loadTranslations(danish, english);
    }

    ngOnInit() {
        this.notificationValuesParams = this.data;
        this.edit(this.notificationValuesParams)
    }

    edit(value) {
        this.showSpinner = true;
        this.coWorkerService.GetCoworkerNotifySettingValue(value)
            .subscribe((response: ResponseBase) => {
                if (response) {
                    this.showSpinner = false;
                    this.notificationValues = response.result.myResponse;
                    this.notificationValues.forEach(item => {
                        item.value = JSON.parse(item.value);
                    });
                }
            },
                error => (err) => {
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });
                });
    }

    SaveAndUpdate(notifyValue) {
        this.showSpinner = true;
        this.SettingsObj = notifyValue;
        this.SettingsObj.value = JSON.stringify(this.SettingsObj.value);
        this.coWorkerService.UpdateSettingValue<ResponseBase>(this.SettingsObj.id, this.SettingsObj)
            .subscribe(resonse => {
                if (resonse) {
                    this.showSpinner = false;
                    this.edit(this.notificationValuesParams);
                    this.translate.get("MESSAGES.Updated").subscribe({
                        next: (res) => {
                            this.notificationsService.success(res);
                        },
                        error: err => {
                        }
                    });
                }
            },
                error => (err) => {
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });
                });
    }

    back() {
        this.dialogRef.close();
    }
}