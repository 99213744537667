export const locale = {
  lang: 'en',
  data: {
    'Tabs': {
      'PROJECT': 'Projects',
      'PROJECTOFFER': 'Offer',
      'TabFiles': 'Files',
      'TabBasicDetail': 'Basic Detail',
      'TabNote': 'Note',
      'TabLog':'Log'
    },
    'MESSAGES': {
      'NORECORD': 'No Suppliers Found',
      'Created': 'Created successfully',
      'Updated': 'Updated successfully',
      'Deleted': 'Deleted successfully',
      'Failed': 'Operation failed , try later',
      'EmailSent': 'Email sent successfully',
    },
    'TITLE': {
      'CustomerPagination': 'Number Of Suppliers Per Page',
    },
    'SUPPLIER': {
      'TITLE': 'Supplier',
      'TabBasicDetail': 'Basic Detail',
    },
    'FIELDS': {
      'SupplierName': 'Supplier Name',
      'Email': 'E-mail',
      'PhoneNo': 'Phone No.',
      'Address-line-1': 'Address(Line 1)',
      'Address-line-2': 'Address(Line 2)',
      'City': 'City',
      'Zipcode': 'Zipcode',
      'Position': 'Position',
      'ContactPerson': 'Contact Person',
      'Note': 'Note',
      'Homepage': 'Homepage',
      'Number': 'Number',
      'AppUrlLink': 'App URL-link',
        'ChargesPercentage': 'Profit on materials %',
        'EmailToSupplier': 'Email to supplier',
        'EANNumber': 'EAN Number',
        'ExpenseAccount': 'Expense account',
        'InvoiceAccount': 'Invoice account',
        'OurAccountNumber': 'Our account number',
        'Group': 'Group',
        'PaymentMethod': 'Payment method',
        'SWIFT': 'SWIFT',
        'PaymentId': 'Payment id',
        'RefInPayment': 'Ref in payment',
        'PaymentFormat': 'Payment format',
        'Payment': 'Payment',
    },
    'VALIDATIONS': {
      'TEXT': 'Enter Supplier Name',
      'NumberRequired': 'Enter Nummer',
      'Email': 'Enter Valid Email',
      'PHONENO': 'Enter valid phone number',
      'PhoneNoRequired': 'Phone number required',
        'WhiteSpaceNotAllowed': 'Only white space not allowed',
        'EmailToSupplier': 'Insert Valid Supplier Email',
    },
    'TOOTIPORTITLE':{
      'Edit':'Edit',
      'Delete':'Delete',
      'SendSMS':'Send SMS',
      'SendEmail':'Send Email',
      'DeleteSupplier':'Delete Supplier',
  },
  }
};
