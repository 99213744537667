import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { TitleService } from 'app/core/services/title.service';
import { AuthService } from 'app/core/services/auth.service';
import { QuickInvoice } from 'app/main/model/Material';
import { MatVerticalStepper } from '@angular/material';
import { ViewChild } from '@angular/core';
import { MaterialService } from '../material/material.service';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { projectRequisition } from 'app/main/model/Project';
import { Customer } from 'app/main/model/Customer';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'vibe-quickinvoice',
  templateUrl: './quickinvoice.component.html',
  styleUrls: ['./quickinvoice.component.scss']
})
export class VibeQuickInvoiceComponent implements OnInit {
  // roleName: string = "";
  // isValid: boolean = false;
  isLinear = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thiredFormGroup: FormGroup;
  step: number = 2;
  customerId: string = "";
  quickInvoice: QuickInvoice;
  @ViewChild('stepper', { static: false }) stepper: MatVerticalStepper;
  showSpinner: boolean = false;
  showMaterial: boolean = false;
  customer: Customer;
  invoiceObj: any;
  isGenerateQuickinvoice: boolean = false;

  public lat = 24.799448;
  public lng = 120.979021;

  public origin: any;
  public destination: any;


  constructor(private _formBuilder: FormBuilder, private translationLoader: FuseTranslationLoaderService,
    private authService: AuthService, private titleService: TitleService, private materialService: MaterialService,
    private translate: TranslateService,
    private notificationsService: NotificationsService) {
    this.translationLoader.loadTranslations(english, danish);
    this.titleService.SetTitle("TITLE_STORE_SALES");
  }

  ngOnInit() {
    // this.roleName = this.authService.getUserRole();
    this.invoiceObj = {
      customer: {}, quickInvoice: null
    };
    // if (this.roleName == "Admin") {
    //   this.isValid = true;
    // }
    // else if (this.roleName == "Kontor") {
    //   this.isValid = true;
    // }
    // else if (this.roleName == "Maler") {
    //   this.isValid = false;
    // }
    // else if (this.roleName == "Gaest") {
    //   this.isValid = false;
    // }
    // else {
    //   this.isValid = true;
    // }
    this.firstFormGroup = this._formBuilder.group({
      customerId: ['', [Validators.required]]
    });
    this.secondFormGroup = this._formBuilder.group({
      thiredCtrl: [''],
      //MaterialList: [0, [Validators.required, Validators.min(1), Validators.max(999)]],
    });
    this.thiredFormGroup = this._formBuilder.group({
      thiredCtrl: ['']
    });
    this.getDirection();
  }

  getDirection() {
    this.origin = { lat: 24.799448, lng: 120.979021 };
    this.destination = { lat: 24.799524, lng: 120.975017 };

    // this.origin = 'Taipei Main Station';
    // this.destination = 'Taiwan Presidential Office';
  }

    getSelectedCustomerId(evnt) {
    this.showMaterial = false;
    this.customer = new Customer();
    this.customer = evnt.customer;
    this.invoiceObj = {
      customer: this.customer, quickInvoice: null
    };

    this.customerId = this.customer.id;
    this.firstFormGroup.setValue({
      customerId: this.customerId,
    });
    this.quickInvoice = new QuickInvoice();
    this.quickInvoice.id = this.customerId;
    this.quickInvoice.indoorOutdoorStatusId = evnt.indoorOutdoorStatusId.toString();
    this.quickInvoice.name = this.customer.name;
    this.stepper.selectedIndex = 1;
    this.quickInvoice.requisitionDetail = new projectRequisition();
    this.quickInvoice.requisitionDetail.status = 9;
    if (this.quickInvoice.indoorOutdoorStatusId == "5") {
      this.quickInvoice.requisitionDetail.invoiceText = "Personale køb";
      this.quickInvoice.requisitionDetail.opgaveText = "Personale køb";
    }
    else {
      this.quickInvoice.requisitionDetail.invoiceText = "Butiks køb";
      this.quickInvoice.requisitionDetail.opgaveText = "Butiks køb";
    }
    this.quickInvoice.discountPercent = this.customer.discountPercent;
    this.quickInvoice.requisitionDetail.discountPercent = this.customer.discountPercent;
    this.quickInvoice.comment = this.customer.comment;
    setTimeout(() => {
      this.showMaterial = true;
    }, 1000);

  }

  getAddedMaterialList(list) {
    this.quickInvoice.materialList = [];
    for (var i = 0; i < list.length; i++) {
      var obj = Object.assign({}, list[i]);
      obj.materialId = obj.id;
      obj.personalPris = obj.personalPrice;
      obj.id = "";
      this.quickInvoice.materialList.push(obj);
    }

    this.materialService.GenerateQuickInvoice<ResponseBase>(this.quickInvoice)
      .subscribe((response: ResponseBase) => {
        this.showSpinner = false;
        this.quickInvoice = response.result;
        this.invoiceObj = {
          customer: this.customer, quickInvoice: this.quickInvoice
        };
        this.isGenerateQuickinvoice = false; 
        this.stepper.selectedIndex = 2;
        this.translate.get("MESSAGES.InvoiceGenerated").subscribe({
          next: (res) => {
            this.notificationsService.success(res);
          },
          error: err => {
          }
        });
      },
        error => (err) => {
          this.showSpinner = false;
        },
        () => {
        });
  }

  SaveAndExits() {
    this.showSpinner = true;
    this.invoiceObj = {
      customer: {}, quickInvoice: null
    };
    this.firstFormGroup = this._formBuilder.group({
      customerId: ['', [Validators.required]]
    });
    this.secondFormGroup = this._formBuilder.group({
      thiredCtrl: [''],
    });
    this.thiredFormGroup = this._formBuilder.group({
      thiredCtrl: ['']
    });
    this.step = 1;
    this.getDirection();
    setTimeout(() => {
      this.showSpinner = false;
    }, 500);
    this.titleService.SetTitle("TITLE_STORE_SALES");
  }
}


