export const locale = {
    lang: 'ds',
    data: {
        'BUTTONS': {
        },
        'FIELDS': {
            'SrNo': 'Sr-nr.',
            'StartDate': 'Start dato',
            'EndDate': 'SlutDato',
            'Type': 'Type',
            'OfSalary': 'Aflønning',
            'BasicSalary': 'Grundløn',
            'BasicSalaryHours': 'Grundløn timer',
            'PersonalSupplement': 'Personligt tillæg(pr. time)',
            'TermsofEmploymentID': 'Vilkår for ansættelses-ID',
            'MeetingAllowance': 'Mødegodtgørelse(pr. time)',
            
        },
        'MESSAGES': {
            'Created': 'Oprettet med succes',
            'Updated': 'Opdateret med succes',
            'Deleted': 'Slettet med succes',
            'Failed': 'Operation mislykket, prøv senere',
            'NORECORD': 'Ingen rekord fundet',
            'SelectCoworker':'Vælg Medarbejder'
        },
        'Lable': {
            'SRNO': 'Sr nr.',
            'Type': 'Type',
            'From': 'Fra kl.',
            'To': 'Til kl.',
            'HourlyRate': 'Time løn',
            'Numberofhours': 'Antal Timer',
            'Weekday': 'Ugedag',
            'MinEmployeeMonth': 'Min Ansat',
            'MaxEmployeeMonth': 'Max Ansat',
            'MD': 'md.',
            '14Day':'14 dage',
            'Month':'Måned'
        },
        'ratesType': {
            'valueA': 'A Timeløn',
            'valueB': 'B Timeløn Overarbejde 1',
            'valueC': 'C Timeløn Overarbejde 2',
            'valueJ': 'J Sygeløn pr. time',

            'valueK': 'Ferie',
            'valueL': 'Feriefridag',
            'valueM': 'Fridag m.løn',
            'valueN': 'Fridag u.løn',
            'valueO': 'Kursus/skole',
            'valueP': 'Andet',
            'valueQ': 'Tilbud',
        },
        'selectedValueWeekDays': {
            'value1': 'Mandag',
            'value2': 'Trisdag',
            'value4': 'Onsdag',
            'value8': 'Torsdag',
            'value16': 'Fredag',
            'value32': 'lørdag',
            'value64': 'Søndag'
          },
    }
}