import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs'
import { NotificationsService } from 'angular2-notifications';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class MessagingService {
    currentMessage = new BehaviorSubject(null);
    FCMToken: string = "";
    constructor(private angularFireMessaging: AngularFireMessaging,
        private _router: Router,
        private notificationsService: NotificationsService) {
        this.angularFireMessaging.messages
            .subscribe((message) => { console.log(message); });

        //this.angularFireMessaging.messaging.subscribe(
        //  (_messaging) => {
        //    _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        //    _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
        //  }
        //)
    }

    requestPermission() {
        this.angularFireMessaging.requestToken.subscribe(
            (token) => {
                this.FCMToken = token;
            },
            (err) => {
                console.error('Unable to get permission to notify.', err);
            }
        );
    }

    getFCMTeken() {
        return this.FCMToken;
    }

    receiveMessage() {
        this.angularFireMessaging.messages.subscribe(
            (payload:any) => {
                this.currentMessage.next(payload);
                this.notificationsService.info(payload["notification"]["title"], payload["notification"]["body"]);
                let audio = new Audio();
                audio.src = "../../../assets/soundEffect/NotificationTone.mp3";
                audio.load();
                audio.play();
                if(payload.data && payload.data.ReviewQuestionID)
                    this._router.navigateByUrl('/dashboard?reviewQuestionID='+payload.data.ReviewQuestionID);
            })
    }
}