export const ProjectStatus: any[] = [

    //{ id: 1, value: '-', key: 'selectedValueStatus.value0' },
    //{ id: 0, value: '-', key: 'selectedValueStatus.value0' },
    { id: 1, value: 'Kontaktes', key: 'selectedValueStatus.value1', ds: 'Behandle', en: 'Process' },
    { id: 2, value: 'Planlagt besøg', key: 'selectedValueStatus.value2', ds: 'Planlagt besøg', en: 'Scheduled visit' },
    { id: 3, value: 'Svare ikke', key: 'selectedValueStatus.value3', ds: 'Klar til opstart', en: 'Ready to boot' },
    { id: 4, value: 'Tilbud sendt', key: 'selectedValueStatus.value4', ds: 'Tilbud sendt', en: 'Offers sent' },
    { id: 5, value: 'Tilbud godkendt ', key: 'selectedValueStatus.value5', ds: 'Tilbud godkendt', en: 'Offer approved' },
    { id: 6, value: 'Planlagt', key: 'selectedValueStatus.value6', ds: 'Planlagt', en: 'Planned' },
    { id: 7, value: 'Tilbud afvist', key: 'selectedValueStatus.value7', ds: 'Tilbud afvist', en: 'Offer rejected' },
    { id: 8, value: 'Startet', key: 'selectedValueStatus.value8', ds: 'Startet', en: 'Started' },
    { id: 9, value: 'Udført', key: 'selectedValueStatus.value9', ds: 'Udført', en: 'Performed' },
    { id: 10, value: 'Faktureret', key: 'selectedValueStatus.value10', ds: 'Faktureret', en: 'Billed' },
    { id: 11, value: 'Afventer', key: 'selectedValueStatus.value11', ds: 'Afventer', en: 'Pending' },
    { id: 12, value: 'Klar fakturering', key: 'selectedValueStatus.value12', ds: 'Klar fakturering', en: 'Ready billing' }
]

export const ProjectStatusForMaler: any[] = [

    //{ id: 1, value: '-', key: 'selectedValueStatus.value0' },
    { id: 1, value: 'Kontaktes', key: 'selectedValueStatus.value1' },
    { id: 2, value: 'Planlagt besøg', key: 'selectedValueStatus.value2' },
    { id: 3, value: 'Svare ikke', key: 'selectedValueStatus.value3' },
    { id: 4, value: 'Tilbud sendt', key: 'selectedValueStatus.value4' },
    { id: 5, value: 'Tilbud godkendt ', key: 'selectedValueStatus.value5' },
    { id: 6, value: 'Planlagt', key: 'selectedValueStatus.value6' },
    { id: 7, value: 'Tilbud afvist', key: 'selectedValueStatus.value7' },
    { id: 8, value: 'Startet', key: 'selectedValueStatus.value8' },
    { id: 10, value: 'Faktureret', key: 'selectedValueStatus.value10' },
    { id: 11, value: 'Reklamation / internt', key: 'selectedValueStatus.value11' }
]

export const ProjectIndoorOutDoorStatus: any[] = [
    { id: 1, key: 'selectedValueIndoorOutdoorStatus.value1' },
    { id: 2, key: 'selectedValueIndoorOutdoorStatus.value2' },
    { id: 3, key: 'selectedValueIndoorOutdoorStatus.value3' },
    { id: 4, key: 'selectedValueIndoorOutdoorStatus.value4' },
    { id: 5, key: 'selectedValueIndoorOutdoorStatus.value5' },
    { id: 6, key: 'selectedValueIndoorOutdoorStatus.value6' }
]

export const OfferStatus: any[] = [
    { id: 1, key: 'selectedValueOfferStatus.value1' },
    { id: 2, key: 'selectedValueOfferStatus.value2' },
    { id: 3, key: 'selectedValueOfferStatus.value3' },
    { id: 4, key: 'selectedValueOfferStatus.value4' },
    { id: 5, key: 'selectedValueOfferStatus.value5' }
]


export const CoworkerDeviceType: any[] = [
    { id: 1, key: 'CoworkerDeviceType.value1' },
    { id: 2, key: 'CoworkerDeviceType.value2' },
]


export const yesOrNoOptions: any[] = [
    { id: false, key: 'selectedOptionForLoftAndRiseMan.value1' },
    { id: true, key: 'selectedOptionForLoftAndRiseMan.value2' }
]

//Statistic tab relaetd dropdowns

export const ReasonForProjectDropped: any[] = [
    { id: 0, key: 'reasonForProjectDropped.value0' },
    { id: 1, key: 'reasonForProjectDropped.value1' },
    { id: 2, key: 'reasonForProjectDropped.value2' },
    { id: 3, key: 'reasonForProjectDropped.value3' },
    { id: 4, key: 'reasonForProjectDropped.value4' },
]

export const WhereDoesTheCustomerComeFrom: any[] = [
    { id: 1, key: 'whereDoesTheCustomerComeFrom.value1' },
    { id: 2, key: 'whereDoesTheCustomerComeFrom.value2' },
    { id: 3, key: 'whereDoesTheCustomerComeFrom.value3' },
    { id: 4, key: 'whereDoesTheCustomerComeFrom.value4' },
    { id: 5, key: 'whereDoesTheCustomerComeFrom.value5' },
    { id: 6, key: 'whereDoesTheCustomerComeFrom.value6' },
    { id: 7, key: 'whereDoesTheCustomerComeFrom.value7' },
]

export const WhereDoesTheInquiryComeFrom: any[] = [
    { id: 1, key: 'whereDoesTheInquiryComeFrom.value1' },
    { id: 2, key: 'whereDoesTheInquiryComeFrom.value2' },
    { id: 3, key: 'whereDoesTheInquiryComeFrom.value3' },
    { id: 4, key: 'whereDoesTheInquiryComeFrom.value4' },
    { id: 5, key: 'whereDoesTheInquiryComeFrom.value5' },
    { id: 6, key: 'whereDoesTheInquiryComeFrom.value6' },
    { id: 7, key: 'whereDoesTheInquiryComeFrom.value7' },
]

export const UrgentOrNot: any[] = [
    { id: 0, key: 'UrgentOrNot.value0' },
    { id: 5, key: 'UrgentOrNot.value1' },
    { id: 1, key: 'UrgentOrNot.value2' }
]

export const monthList: any[] = [
    { id: 1, key: 'monthList.value1' },
    { id: 2, key: 'monthList.value2' },
    { id: 3, key: 'monthList.value3' },
    { id: 4, key: 'monthList.value4' },
    { id: 5, key: 'monthList.value5' },
    { id: 6, key: 'monthList.value6' },
    { id: 7, key: 'monthList.value7' },
    { id: 8, key: 'monthList.value8' },
    { id: 9, key: 'monthList.value9' },
    { id: 10, key: 'monthList.value10' },
    { id: 11, key: 'monthList.value11' },
    { id: 12, key: 'monthList.value12' }
]


export const StatusLogCases: any[] = [
    { id: 1, key: 'StatusLogCases.value1' },
    { id: 2, key: 'StatusLogCases.value2' },
    { id: 3, key: 'StatusLogCases.value3' },
    { id: 4, key: 'StatusLogCases.value4' },
    { id: 5, key: 'StatusLogCases.value5' },
    { id: 6, key: 'StatusLogCases.value6' },
    { id: 7, key: 'StatusLogCases.value7' },
    { id: 8, key: 'StatusLogCases.value8' },
    { id: 9, key: 'StatusLogCases.value9' },
    { id: 10, key: 'StatusLogCases.value10' }

]

export const MaterialUnitList: any[] = [
    { id: 'stk.', key: 'unitList.value1' },
    { id: 'liter', key: 'unitList.value2' },
    { id: 'm.', key: 'unitList.value3' },
    { id: 'rulle', key: 'unitList.value4' },
    { id: 'm2', key: 'unitList.value5' },
    { id: 'kg', key: 'unitList.value6' }
]

export const ShiftType: any[] = [
    { id: 2, key: 'selectedOption.value1' },
    { id: 3, key: 'selectedOption.value2' },
    { id: 4, key: 'selectedOption.value3' },
]

export const projectMode: any[] = [
    { id: 1, key: 'paymentModeList.value1' },
    { id: 2, key: 'paymentModeList.value2' },
    { id: 3, key: 'paymentModeList.value3' },
    { id: 4, key: 'paymentModeList.value4' },
    { id: 5, key: 'paymentModeList.value5' },
    { id: 6, key: 'paymentModeList.value6' },
    { id: 7, key: 'paymentModeList.value7' }
]

export const MaterialLinetype: any[] = [
    { id: 'L', key: 'linetype.value1' },
    { id: 'T', key: 'linetype.value2' },
    { id: 'V', key: 'linetype.value3' }
]

export const ModuleType: any[] = [
    { id: 0, key: 'moduleTypeList.value0' },
    { id: 1, key: 'moduleTypeList.value1' },
    { id: 2, key: 'moduleTypeList.value2' }
]
export const ShiftVisitTimeType: any[] = [
    { id: 3, key: 'selectedShifttimeOption.value1' },
    { id: 4, key: 'selectedShifttimeOption.value2' },
]

// export const MaterialType: any[] = [
//     //{ id: 0, key: 'materialType.value0' },
//     { id: 1, key: 'materialType.value1' },
//     { id: 13, key: 'materialType.value13' },
//     { id: 14, key: 'materialType.value14' },
//     { id: 15, key: 'materialType.value15' },
//     { id: 2, key: 'materialType.value2' },
//     { id: 3, key: 'materialType.value3' },
//     { id: 4, key: 'materialType.value4' },
//     { id: 5, key: 'materialType.value5' },
//     { id: 23, key: 'materialType.value23' },
//     { id: 6, key: 'materialType.value6' },
//     { id: 16, key: 'materialType.value16' },
//     { id: 7, key: 'materialType.value7' },
//     { id: 8, key: 'materialType.value8' },
//     { id: 9, key: 'materialType.value9' },
//     { id: 10, key: 'materialType.value10' },
//     { id: 17, key: 'materialType.value17' },
//     { id: 22, key: 'materialType.value22' },
//     { id: 18, key: 'materialType.value18' },
//     { id: 11, key: 'materialType.value11' },
//     { id: 21, key: 'materialType.value21' },
//     { id: 20, key: 'materialType.value20' },
//     { id: 19, key: 'materialType.value19' },
//     { id: 12, key: 'materialType.value12' },
// ]

export const PurchasingUnitList: any[] = [
    { id: 'stk.', key: 'purchasingUnitList.value1' },
    { id: 'liter', key: 'purchasingUnitList.value2' },
    { id: 'm.', key: 'purchasingUnitList.value3' },
    { id: 'rulle', key: 'purchasingUnitList.value4' },
];