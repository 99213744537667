export const locale = {
    lang: 'en',
    data: {
        'FIELDS': {
            'SRNO': 'Sr No',
            'Status': 'Status',
            'Number': 'Number',
            'Name': 'Name',
            'Createdby': 'Created By',
            'CreatedDate': 'Created Date',
            'ModifyBy': 'Modify By',
            'ModifyDate': 'Modify Date',
            'CoworkerName': 'Coworker',
            'Date': 'Date',
            'Description': 'Description',
            'ProjectDetail': 'Project Detail',
            'RequisitionDetail': 'Requisition Detail',
            'ColorCode': 'Color Code',
            'Price': 'Price',
            'QTY': 'QTY',
            'StartDate': 'Start Date',
            'EndDate': 'End Date',
            'Coworker': 'Coworker',
            'TotalPrice': 'Total Price',
            'TotalQTY': 'Total QTY',
            'CoworkerDropdownAll':'All'
        },
        'MESSAGES': {
            'NORECORD':'No record found.',
        },
        'BUTTONS': {
            'BACK': 'Cancel',
        },
        'FILTERS': {
            'BADGE': 'Inactive',
            'MtoM': 'M to M',
            'Note': 'Note',
            'Offers': 'Offers',
            'TEXTSEARCH': 'Search By Project Number',
            'SORTING': 'Sort By',
        },
        'SORTBY':
            [
                {
                    Text: 'Address (A-Z)',
                    Value: 'Address ASC'
                },
                {
                    Text: 'Address (Z-A)',
                    Value: 'Address DESC'
                },
                {
                    Text: 'Created Date ↑',
                    Value: 'CreatedDate ASC'
                },
                {
                    Text: 'Created Date ↓',
                    Value: 'CreatedDate DESC'
                },
            ]
    }
};
