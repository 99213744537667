export const locale = {
  lang: 'en',
  data: {
    'Tabs': {
      'Offer': 'Offer',
      'BasicDetail': 'Basic Detail',
      'File': 'File',
      'Category': 'Category',
      'WorkItem':'Work Item'
    },
    'MESSAGE': {
      'Failed': 'Operation failed , try later',
      'Success': 'Success.',
      'CommingSoon':'Comming Soon..',
      'Deleted': 'Deleted successfully',
    },
    'FIELDS':{
        'GoTo': 'Go to',
        'SRNO': 'Sr No',
        'Status': 'Status',
        'Number': 'Number',
        'Name': 'Name',
        'Createdby': 'Created By',
        'CreatedDate': 'Created Date',
        'ModifyBy': 'Modify By',
        'ModifyDate': 'Modify Date',
        'CoworkerName': 'Coworker',
        'Date': 'Date',
        'Description': 'Description',
        'Question': 'Add information',
    },
    'BUTTONS': {
        'AddCategory': 'Add Category',
        'Add': 'Add',
        'SaveAndClosedQuestion':'Save'
    },
    'Headers': {
      'DeleteCategory': 'Do you want to delete category',
    }
  }
};
