import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../../../core/modules/shared.module';

import { VibeDashboardComponent } from './dashboard.component';
import { DashboardService } from './dashboard.service';
//import { FuseMainModule } from '../../../main/main.module';
import { projectTimeInfoComponent } from './component/projectTimeInfo.component';
import { Routegaurd } from '../../../routegaurd';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { GoogleMapVehicleAddressComponent } from './component/google-map-vehicle-address/google-map-vehicle-address.component';
import { GoogleMapVehicleAddressteService } from './component/google-map-vehicle-address/google-map-vehicle-address.service';
import { AgmCoreModule } from '@agm/core';
import { environment } from '../../../../environments/environment';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { AgmDirectionModule } from 'agm-direction';

const routes = [
  {
    path: 'dashboard',
    component: VibeDashboardComponent,
    //canActivate : [Routegaurd]
    },
    {
        path: 'dashboard/livemap',
        component: GoogleMapVehicleAddressComponent,
        //canActivate : [Routegaurd]
    }
];

@NgModule({
  declarations: [
        VibeDashboardComponent,
        GoogleMapVehicleAddressComponent,
    projectTimeInfoComponent,
  ],
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    DatePickerModule,
      AgmCoreModule.forRoot({
          apiKey: environment.GooogleKey,
          libraries: ["places"]
      }),
      AgmJsMarkerClustererModule,
      AgmDirectionModule
  ],
  exports: [
      VibeDashboardComponent,
      GoogleMapVehicleAddressComponent,
  ],
    providers: [DashboardService,
        GoogleMapVehicleAddressteService,
    ]
})

export class VibeDashboardModule {
}
