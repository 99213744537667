export const locale = {
    lang: 'en',
    data: {
        'HEADER': {
            'WALLS': 'Walls',
            'PAINTCELING': 'Ceiling',
            'FLOOR': 'Floor',
            'WallAndCeiling': {
                'wall&ceiling': 'You have not chosen to paint walls for @wallRoom - painting of ceilings for @ceilingRoom. Do you want to save changes ?',
                'wall': 'You have not chosen to paint the walls for @wallRoom ?',
                'ceiling': 'You have not chosen paint of the ceiling for @ceilingRoom ?',
                'floor': 'Do you want to paint wall and ceiling without floor paint ?',

            }
        },
        'BUTTONS': {
            'AddExtra': 'Add',
            'RemoveExtra': 'Remove',
            'PDF1': 'Offer',
            'PDF2': 'Offer With Room',
            'SelectMaterial': 'All',
            'ADDFLOOR': 'Floor',
            'GotoMaterial': 'Go to material',
            'Diverse': 'Various',
        },
        'FIELDS': {
            'Search': 'Search',
            'Width': 'Width',
            'Length': 'Length',
            'Total': 'm2 in total',
            'Qty': 'Supplement / Deduction Kr.',
            'Quantity': 'Quantity',
            'QtyM2': 'Supplement / Deduction m2',
            'Price': 'Price',
            'PriceTotal': 'Price Total',
            'MaterialPrice': 'Price per m2',
            'GrandTotal': 'Price in all',
            'Rounding': 'Rounding',
            'SupplementDeduction': 'Supplement Deduction',
            'Text': 'Text',
            'Pcs': 'Pcs',
            'Door': 'Doors',
            'PaintCeling': 'Paint Celing',
            'CelingHeight': 'Celing Height',
            'MaterialType': 'Material Type',
            'RoomMaterial': 'Room Material',
            'CeilingMaterial': 'Ceiling Material',
            'Time': 'Number of times',
            'PaintWall': 'Paint Wall',
            'RoomDetails': 'Room',
            'Not-Found-MATERIALTYPE': 'Material Type',
            'Change': 'Change',
            'DiscountedAmount': 'Discounted Amount',
            'PaintTime': 'Time',
            'CeilingArea': 'Ceiling area m2',
            'CeilingPriceGrandTotal': 'Ceiling Price',
            'WallPriceGrandTotal': 'Wall Price',
            'Name': 'Name',
            'CeilingPrice': 'Ceiling price per m2',
            'CeilingPriceTotal': 'Ceiling Price Total',
            'WallTotalPrice': 'Wall Total Price',
            'NotCalculatePrice': 'Not Calculate Price',
            'M2Wall': 'M2 Wall',
            'M2Ceiling': 'M2 Ceiling',
            'ColorCode': 'Color code',
            'PaintFloor': 'Paint Floor',
            'FloorArea': 'Floor area m2',
            'FloorPrice': 'Floor price per m2',
            'FloorPriceTotal': 'Floor Price Total',
            'M2Floor': ' M2 Floor',
            'FloorPriceGrandTotal': 'Floor Price',
            'Unit': 'Unit',
            'TotalPrice': 'Total',
            'Room': 'Room',
            'Performance': 'Performance',
            'PriceExclVAT': 'Price excl. VAT',
            'PriceInclVAT': 'Price incl. VAT',
            'TotalInclVAT':'Total incl. VAT',
            'Vat': 'VAT',
            'Material': 'Materiale',
            'Panels': 'Panels',
            'Doorframe': 'Door frame',
            'Radiator': 'Radiator',
            'Dore': 'Doors',
            'TotalM2Wall': 'Total M2 Wall',
            'TotalM2Ceiling':'Total M2 Ceiling',
            'TotalM2Floor':'Total M2 Floor',
            'FloorMaterial': 'Floor Material',
            'IsIncludePriceOnPDF':'Include Price On PDF',
            'Timer-Min' : ' Timer / Min'
        },
        'MESSAGES': {
            'Failed': 'Operation failed , try later',
            'Success': 'Success.',
            'NORECORD': 'No Room Found'
        },
        'SelectedOfferCategory': {
            'Value1': 'Indoor',
            'Value2': 'Outdoor',
            'Value3': 'Window',
            'Value4': 'Doors',
            'Value5': 'Spray booth',
            'Value6': 'Talking',
            'Value7': 'Felt',
            'Value8': 'Tissue',
            'Value9': 'Rutex',
            'Value10': 'Wallpaper',
            'Value11': 'Panels',
            'Value12': 'Door frames',
            'Value13': 'Guarantee',
            'Value14': 'Lift',
            'Value15': 'Spartling',
            'Value16': 'Radiators',
            'Value17': 'Warning',
            'Value18': 'Facades',
            'Value19': 'Shed vg.',
            'Value20': 'Cover',
            'Value21': 'Ceilings',
            'Value22': 'Walls',
            'Value23': 'Floor',
        },
        'SelectedCategoryTime': {
            'Value1': '1 Time',
            'Value2': '2 Times',
            'Value3': '3 Times',
            'Value4': '4 Times',
            'Value5': '5 Times',
            'Value11': 'Stk',
            'Value12': 'Meter',
            'Value13': 'Rulle',
            'Value14': 'Liter',
        },
        'VALIDATIONS': {
            //Wall Fields
            'WallPrice': 'Should not be null.',
            'CeilingHeight': 'Should not be null.',
            'WallWidth': 'Should not be null.',
            'WallLength': 'Should not be null.',
            'wallExtraQty1': 'Should not be null.',
            'WallExtraQty2': 'Should not be null.',
            'WallExtraPrice1': 'Should not be null.',
            'WallExtraPrice2': 'Should not be null.',
            'WallTotalArea': 'Should not be null.',
            'WallTotalPrice': 'Should not be null.',
            'CeilingPriceTotal': 'Should not be null.',
            'TotalPrice': 'Should not be null.',
            //Ceiling Fields
            'CeilingWidth': 'Should not be null.',
            'CeilingLength': 'Should not be null.',
            'CeilingPrice': 'Should not be null.',
            'CeilingExtraQty1': 'Should not be null.',
            'CeilingExtraQty2': 'Should not be null.',
            'CeilingExtraPrice1': 'Should not be null.',
            'CeilingExtraPrice2': 'Should not be null.',
            'CeilingArea': 'Should not be null.',
            'CeilingTotalPrice': 'Should not be null.',
            'DiscountedAmount': 'Should not be null.'
        },
        'ToolTip': {
            'Start': 'Start',
            'End': 'End',
            'Price': 'Price',
            'Time': 'Time'
        },
        'FILTERS': {
            'Select': 'Select',
            'All': 'All'
        },
        'unitList': {
            'value1': 'PCS',
            'value2': 'Liter',
            'value3': 'Meter',
            'value4': 'Roll',
            'value5': 'M2',
            'value6': 'KG'
        },
    }
};
