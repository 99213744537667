export const locale = {
    lang: 'ds',
    data: {
        'TITLE': {
            'Header': 'Værktøj og stiger',
        },
        'FIELDS': {
            'ToolsAndLadderForWeb': 'Værktøj og stiger til web',
            'ToolsAndLadderForApp': 'Værktøj og stiger til app',
        },
        'Message': {

        },
        'FILTERS': {
        },
        'Buttons': {
            'Save':'Gem',
        }
    }
};