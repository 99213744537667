export const locale = {
    lang: 'ds',
    data: {
        'Headers': {

        },
        'FIELDS': {
            'Yes': 'JA',
            'No': 'NEJ',
            'Copy': 'Kopi'
        }
    }
}