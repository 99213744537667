export const locale = {
    lang: 'ds',
    data: {
        'MESSAGES': {
            'Created': 'Oprettet med succes',
            'Updated': 'Opdateret med succes',
            'Deleted': 'Slettet med succes',
            'Failed': 'Operation mislykket, prøv senere',
        },
        'BUTTONS': {
            'BACK': 'Tilbage',
            'AddScanMaterial': 'Tilføj scanne materiale',
            'SAVE': 'Gem',
            'DELETE': 'Slet',
        }, 
        'TOOTIPORTITLE': {
            'Edit': 'Redigere',
        }
    }
};

