export const locale = {
  lang: 'ds',
  data: {
    'HEADER': {
      'PleaseSelectTabsFromBelowList': 'Vælg faner fra nedenstående liste',
      'tabName': 'Fanenavn'
    },
    'BUTTONS': {
      'Save': 'Gem',
      'Back': 'Tilbage',
      'NewTab': 'Ny fane',
    },
    'MESSAGES': {
      'CompletedNextSide': 'Vil du ændre næste syn til {Date} ?',
      'CompletedNextService': 'Vil du ændre næste service til {Date} ?',
      'ItemNumberExist': 'Nummeret eksisterer allerede',
      'Created': 'Oprettet med succes',
      'Updated': 'Opdateret med succes',
      'Deleted': 'Slettet med succes',
    }, 'TOOTIPORTITLE': {
      'Edit': 'Redigere',
      'Delete': 'Slet',
      'Save':'Gem'

    }
  }
};
