import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { MatDialog, MatOption, MatSlideToggleChange } from '@angular/material';
import { AuthService } from 'app/core/services/auth.service';
import { SettingsService } from '../../settings.service';
import { ResponseBase } from "app/main/model/ResponseBase";
import { Settings, SettingValue, SettingValueForAll } from "app/main/model/Settings";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { CoWorker } from "app/main/model/CoWorker";
import { RequestCoWorker } from "app/main/model/RequestBase";
import { CoWorkersService } from "app/main/pages/coWorker/coWorkers.service";
import { NotificationsService } from "angular2-notifications";
import { TranslateService } from "@ngx-translate/core";
import { SettingEmailTempalteEditorComponent } from "../setting-email-tempalte-editor/setting-email-tempalte-editor.component";
import { spaceOnlyValidator } from "app/core/Validators/validation";

@Component({
    selector: 'vibe-coworker-anniversary-reminder-settings',
    templateUrl: './coworker-anniversary-reminder-settings.component.html',
    styleUrls: ['./coworker-anniversary-reminder-settings.component.scss']
})
export class CoworkerAnniversaryReminderSettingsComponent implements OnInit {
    @Input("SendCoworkerAnniversaryReminderSetting") SendCoworkerAnniversaryReminderSetting: Settings;
    @Input("CoWokers") CoWokers: CoWorker[];
    showSpinner: boolean = false;
    coworkerAnniReminderForm: FormGroup;
    isSubmitted = false;
    coWokers: CoWorker[] = [];
    IsGettingCoWokers: boolean = false;
    CoworkerAnniversaryReminderSettingObj: Settings;
    settingMasterValues: any[] = [];
    settingValue: SettingValueForAll = new SettingValueForAll();

    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private fb: FormBuilder,
        private coWorkersService: CoWorkersService,
        private authService: AuthService,
        private settingsService: SettingsService,
        private translate: TranslateService,
        private notificationsService: NotificationsService,
        private dialog: MatDialog) {
        this.translationLoaderService.loadTranslations(danish, english);
    }

    ngOnInit() {
        this.settingMasterValues = Object.assign([], JSON.parse(localStorage.getItem("settingMasterValues")));

        // var coworkerOBJ = new RequestCoWorker();
        // coworkerOBJ.Name = "";
        // this.getCoWorkers(coworkerOBJ);

        this.coworkerAnniReminderForm = this.fb.group({
            CoworkersID: [null, Validators.required],
            DaysToSend: [null, Validators.compose([Validators.required, Validators.min(1), Validators.max(365)])],
            SendOnYear: [null, Validators.compose([Validators.required, Validators.min(1), Validators.max(365)])],
            ExecuteTime: [null, Validators.required],
            IsEnable: [true],
            IsCreateReminderForCoworker: [false],
            IsCreateReminderForOthers: [false],
            IsCreateReminderForOthersBeforeXDays: [false],

            IsSendSMSForCoworker: [false],
            IsSendSMSForOthers: [false],
            IsSendSMSForOthersBeforeXDays: [false],

            IsSendNotificationForCoworker: [false],
            IsSendNotificationForOthers: [false],
            IsSendNotificationForOthersBeforeXDays: [false],

            IsSendEmailForCoworker: [false],
            IsSendEmailForOthers: [false],
            IsSendEmailForOthersBeforeXDays: [false],

            SMSTextForCoworker: [null],
            SMSTextForOthers: [null],
            SMSTextForOthersBeforeXDays: [null],

            NotificationTextForCoworker: [null],
            NotificationTextForOthers: [null],
            NotificationTextForOthersBeforeXDays: [null],

            NotificationTitleForCoworker: [null],
            NotificationTitleForOthers: [null],
            NotificationTitleForOthersBeforeXDays: [null],
        });

        this.coworkerAnniReminderForm.controls['CoworkersID'].valueChanges
            .subscribe(value => {
                // if (value) {
                //     this.coworkerAnniReminderForm.controls['CoworkersID'].setValidators([Validators.required])
                // } else {
                //     this.coworkerAnniReminderForm.controls['CoworkersID'].setValidators(null)
                // }
                // this.coworkerAnniReminderForm.controls['CoworkersID'].updateValueAndValidity()
            });

        this.coworkerAnniReminderForm.get('IsSendSMSForCoworker').valueChanges
            .subscribe(value => {
                if (value) {
                    this.coworkerAnniReminderForm.controls['SMSTextForCoworker'].setValidators([Validators.required, spaceOnlyValidator.bind(this)])
                } else {
                    this.coworkerAnniReminderForm.controls['SMSTextForCoworker'].setValidators(null)
                }
                this.coworkerAnniReminderForm.controls['SMSTextForCoworker'].updateValueAndValidity()
            });

        this.coworkerAnniReminderForm.get('IsSendSMSForOthers').valueChanges
            .subscribe(value => {
                if (value) {
                    this.coworkerAnniReminderForm.controls['SMSTextForOthers'].setValidators([Validators.required, spaceOnlyValidator.bind(this)])
                } else {
                    this.coworkerAnniReminderForm.controls['SMSTextForOthers'].setValidators(null)
                }
                this.coworkerAnniReminderForm.controls['SMSTextForOthers'].updateValueAndValidity()
            });

        this.coworkerAnniReminderForm.get('IsSendSMSForOthersBeforeXDays').valueChanges
            .subscribe(value => {
                if (value) {
                    this.coworkerAnniReminderForm.controls['SMSTextForOthersBeforeXDays'].setValidators([Validators.required, spaceOnlyValidator.bind(this)])
                } else {
                    this.coworkerAnniReminderForm.controls['SMSTextForOthersBeforeXDays'].setValidators(null)
                }
                this.coworkerAnniReminderForm.controls['SMSTextForOthersBeforeXDays'].updateValueAndValidity()
            });

        this.coworkerAnniReminderForm.get('IsSendNotificationForCoworker').valueChanges
            .subscribe(value => {
                if (value) {
                    this.coworkerAnniReminderForm.controls['NotificationTextForCoworker'].setValidators([Validators.required, spaceOnlyValidator.bind(this)])
                    this.coworkerAnniReminderForm.controls['NotificationTitleForCoworker'].setValidators([Validators.required, spaceOnlyValidator.bind(this)])
                } else {
                    this.coworkerAnniReminderForm.controls['NotificationTextForCoworker'].setValidators(null)
                    this.coworkerAnniReminderForm.controls['NotificationTitleForCoworker'].setValidators(null)
                }
                this.coworkerAnniReminderForm.controls['NotificationTextForCoworker'].updateValueAndValidity()
                this.coworkerAnniReminderForm.controls['NotificationTitleForCoworker'].updateValueAndValidity()
            });

        this.coworkerAnniReminderForm.get('IsSendNotificationForOthers').valueChanges
            .subscribe(value => {
                if (value) {
                    this.coworkerAnniReminderForm.controls['NotificationTextForOthers'].setValidators([Validators.required, spaceOnlyValidator.bind(this)])
                    this.coworkerAnniReminderForm.controls['NotificationTitleForOthers'].setValidators([Validators.required, spaceOnlyValidator.bind(this)])
                } else {
                    this.coworkerAnniReminderForm.controls['NotificationTextForOthers'].setValidators(null)
                    this.coworkerAnniReminderForm.controls['NotificationTitleForOthers'].setValidators(null)
                }
                this.coworkerAnniReminderForm.controls['NotificationTextForOthers'].updateValueAndValidity()
                this.coworkerAnniReminderForm.controls['NotificationTitleForOthers'].updateValueAndValidity()
            });

        this.coworkerAnniReminderForm.get('IsSendNotificationForOthersBeforeXDays').valueChanges
            .subscribe(value => {
                if (value) {
                    this.coworkerAnniReminderForm.controls['NotificationTextForOthersBeforeXDays'].setValidators([Validators.required, spaceOnlyValidator.bind(this)])
                    this.coworkerAnniReminderForm.controls['NotificationTitleForOthersBeforeXDays'].setValidators([Validators.required, spaceOnlyValidator.bind(this)])
                } else {
                    this.coworkerAnniReminderForm.controls['NotificationTextForOthersBeforeXDays'].setValidators(null)
                    this.coworkerAnniReminderForm.controls['NotificationTitleForOthersBeforeXDays'].setValidators(null)
                }
                this.coworkerAnniReminderForm.controls['NotificationTextForOthersBeforeXDays'].updateValueAndValidity()
                this.coworkerAnniReminderForm.controls['NotificationTitleForOthersBeforeXDays'].updateValueAndValidity()
            });

        this.setSettingsForVehiclePlanningNotifications();
    }

    ngOnChanges(changes: SimpleChanges) {
        //if (this.CoWokers && this.CoWokers.length > 0) {
        this.coWokers = this.CoWokers;
        //}
    }

    getCoWorkers(model: RequestCoWorker) {
        model.OrderBy = "Name";
        this.IsGettingCoWokers = true;
        this.coWorkersService.Get<ResponseBase>(model)
            .subscribe((response: ResponseBase) => {
                if (response) {
                    this.coWokers = Object.assign([], response.result);
                    this.getNextRequest(response.pagination, model, response.result);
                }
                else {
                    this.coWokers = [];
                    this.IsGettingCoWokers = false;
                }
            },
                error => (err) => {
                    this.coWokers = [];
                    this.IsGettingCoWokers = false;
                },
                () => {

                });
    }

    getNextRequest(pagination, model, response) {
        if (pagination.index == 1) {
            this.coWokers = Object.assign([], response);
            this.IsGettingCoWokers = false;
        }
        else {
            this.coWokers.push(...response);
            this.IsGettingCoWokers = false;
        }
        model.Index = pagination.index + 1;
        model.Limit = pagination.limit;
        model.TotalRows = pagination.totalCount;
        if (pagination.totalCount > pagination.index * pagination.limit)
            this.getCoWorkers(model);
    }

    DropDownSearchCoWorker(value) {
        if (value != null && value != "") {
            var request = new RequestCoWorker();
            request.Name = value;
            this.getCoWorkers(request);
        } else {
            let coWorker = new RequestCoWorker();
            coWorker.IsDeleted = false;
            this.getCoWorkers(coWorker);
        }
    }

    onSubmit() {
        this.coworkerAnniReminderForm.markAllAsTouched();
        this.isSubmitted = true;
        if (this.coworkerAnniReminderForm.valid) {
            this.changeSettingsForPlanningNotifications()
        }
    }

    setSettingsForVehiclePlanningNotifications() {
        if (this.SendCoworkerAnniversaryReminderSetting && this.SendCoworkerAnniversaryReminderSetting != null) {
            this.CoworkerAnniversaryReminderSettingObj = this.SendCoworkerAnniversaryReminderSetting;
            this.settingValue = JSON.parse(this.CoworkerAnniversaryReminderSettingObj.value)
            this.coworkerAnniReminderForm.patchValue(this.settingValue)
            this.coworkerAnniReminderForm.controls['CoworkersID'].setValue(this.coworkerAnniReminderForm.controls['CoworkersID'].value.split(','))
            this.changeInLocalStorage(this.CoworkerAnniversaryReminderSettingObj.id, this.CoworkerAnniversaryReminderSettingObj)
        }
    }

    setSettingsForVehiclePlanningNotificationsAfterUpdate() {
        this.settingsService.GetSettingsWithTemplateValue<ResponseBase>("Notification", 'CoworkerAnniversaryReminder', null).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.result && response.result.myResponse.length > 0) {
                    this.CoworkerAnniversaryReminderSettingObj = response.result.myResponse[0];
                    this.settingValue = JSON.parse(this.CoworkerAnniversaryReminderSettingObj.value)
                    this.coworkerAnniReminderForm.patchValue(this.settingValue)
                    this.coworkerAnniReminderForm.controls['CoworkersID'].setValue(this.coworkerAnniReminderForm.controls['CoworkersID'].value.split(','))
                    this.changeInLocalStorage(this.CoworkerAnniversaryReminderSettingObj.id, this.CoworkerAnniversaryReminderSettingObj)
                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    IsEnableCoworkerWorkAnniversaryReminder(event: MatSlideToggleChange) {
        this.settingValue.IsEnable = event.checked;
        this.changeSettingsForPlanningNotifications();
    }

    changeSettingsForPlanningNotifications() {
        this.showSpinner = true;
        this.settingValue.CoworkersID = this.coworkerAnniReminderForm.value.CoworkersID.toString();
        this.settingValue.DaysToSend = this.coworkerAnniReminderForm.value.DaysToSend;
        this.settingValue.SendOnYear = this.coworkerAnniReminderForm.value.SendOnYear;
        this.settingValue.ExecuteTime = this.coworkerAnniReminderForm.value.ExecuteTime;
        this.settingValue.IsEnable = this.coworkerAnniReminderForm.value.IsEnable;

        this.settingValue.IsSendSMSForCoworker = this.coworkerAnniReminderForm.value.IsSendSMSForCoworker;
        this.settingValue.IsSendSMSForOthers = this.coworkerAnniReminderForm.value.IsSendSMSForOthers;
        this.settingValue.IsSendSMSForOthersBeforeXDays = this.coworkerAnniReminderForm.value.IsSendSMSForOthersBeforeXDays;

        this.settingValue.IsSendNotificationForCoworker = this.coworkerAnniReminderForm.value.IsSendNotificationForCoworker;
        this.settingValue.IsSendNotificationForOthers = this.coworkerAnniReminderForm.value.IsSendNotificationForOthers;
        this.settingValue.IsSendNotificationForOthersBeforeXDays = this.coworkerAnniReminderForm.value.IsSendNotificationForOthersBeforeXDays;

        this.settingValue.IsCreateReminderForCoworker = this.coworkerAnniReminderForm.value.IsCreateReminderForCoworker;
        this.settingValue.IsCreateReminderForOthers = this.coworkerAnniReminderForm.value.IsCreateReminderForOthers;
        this.settingValue.IsCreateReminderForOthersBeforeXDays = this.coworkerAnniReminderForm.value.IsCreateReminderForOthersBeforeXDays;

        this.settingValue.IsSendEmailForCoworker = this.coworkerAnniReminderForm.value.IsSendEmailForCoworker;
        this.settingValue.IsSendEmailForOthers = this.coworkerAnniReminderForm.value.IsSendEmailForOthers;
        this.settingValue.IsSendEmailForOthersBeforeXDays = this.coworkerAnniReminderForm.value.IsSendEmailForOthersBeforeXDays;

        this.settingValue.SMSTextForCoworker = this.coworkerAnniReminderForm.value.SMSTextForCoworker || '';
        this.settingValue.SMSTextForOthers = this.coworkerAnniReminderForm.value.SMSTextForOthers || '';
        this.settingValue.SMSTextForOthersBeforeXDays = this.coworkerAnniReminderForm.value.SMSTextForOthersBeforeXDays || '';

        this.settingValue.NotificationTextForCoworker = this.coworkerAnniReminderForm.value.NotificationTextForCoworker || '';
        this.settingValue.NotificationTextForOthers = this.coworkerAnniReminderForm.value.NotificationTextForOthers || '';
        this.settingValue.NotificationTextForOthersBeforeXDays = this.coworkerAnniReminderForm.value.NotificationTextForOthersBeforeXDays || '';

        this.settingValue.NotificationTitleForCoworker = this.coworkerAnniReminderForm.value.NotificationTitleForCoworker || '';
        this.settingValue.NotificationTitleForOthers = this.coworkerAnniReminderForm.value.NotificationTitleForOthers || '';
        this.settingValue.NotificationTitleForOthersBeforeXDays = this.coworkerAnniReminderForm.value.NotificationTitleForOthersBeforeXDays || '';
        this.CoworkerAnniversaryReminderSettingObj.value = JSON.stringify(this.settingValue);
        this.settingsService.UpdateSettingWithTemplate<ResponseBase>(this.CoworkerAnniversaryReminderSettingObj).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.result) {
                    this.CoworkerAnniversaryReminderSettingObj = response.result;
                    if (this.settingValue.IsEnable)
                        this.setSettingsForVehiclePlanningNotificationsAfterUpdate();
                    this.translate.get("MESSAGES.Saved").subscribe({
                        next: (res) => {
                            this.notificationsService.create(res);
                        },
                        error: err => { }
                    });
                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinner = false;
                this.isSubmitted = false;
            }
        });
    }

    changeInLocalStorage(ID, new_obj) {
        if (this.settingMasterValues.find(obj => obj.id == ID)) {
            this.settingMasterValues.forEach((old_obj, index) => {
                if (old_obj.id == ID) {
                    this.settingMasterValues[index] = new_obj
                }
            })
        } else {
            this.settingMasterValues.push(new_obj)
        }
        localStorage.setItem("settingMasterValues", JSON.stringify(this.settingMasterValues));
    }

    EmailTemplateDialog() {
        const dialogRef = this.dialog.open(SettingEmailTempalteEditorComponent, {
            height: '90%',
            width: '90%',
            data: { settingValue: this.settingValue, IsOther: false, IsBday: true, IsSameDay: false, Note: 'Note.AnniversaryEmailTextCoworkerNote' }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result != null && result != '') {
                this.settingValue = result;
                this.onSubmit();
            }
        });
    }

    EmailTemplateForOthersDialog() {
        const dialogRef = this.dialog.open(SettingEmailTempalteEditorComponent, {
            height: '90%',
            width: '90%',
            data: { settingValue: this.settingValue, IsOther: true, IsBday: true, IsSameDay: true, Note: 'Note.AnniversaryEmailTextOtherCoworkerNote' }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result != null && result != '') {
                this.settingValue = result;
                this.onSubmit();
            }
        });
    }

    EmailTemplateForOthersBeforeXDaysDialog() {
        const dialogRef = this.dialog.open(SettingEmailTempalteEditorComponent, {
            height: '90%',
            width: '90%',
            data: { settingValue: this.settingValue, IsOther: true, IsBday: true, IsSameDay: false, Note: 'Note.AnniversaryEmailTextOthersBeforeXDaysNote' }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result != null && result != '') {
                this.settingValue = result;
                this.onSubmit();
            }
        });
    }
}