import { Component, OnInit } from "@angular/core";
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { Router, ActivatedRoute } from '@angular/router';
import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { TitleService } from 'app/core/services/title.service';
import { SupplierRequest } from '../../model/RequestBase';
import { Supplier } from '../../model/Supplier';
import { ResponseBase } from '../../model/ResponseBase';
import { SuppliersService } from '../suppliers/suppliers.service'
import { AuthService } from 'app/core/services/auth.service';
import { TranslateService } from "@ngx-translate/core";
import { NotificationsService } from "angular2-notifications";

@Component({
    selector: 'vibe-suppliers',
    templateUrl: './suppliers.component.html',
    styleUrls: ['./suppliers.component.scss']
})

export class SuppliersComponent implements OnInit {
    selectedIndex: number = 0;
    showSpinner = false;
    SupplierRequest: SupplierRequest = new SupplierRequest();
    SupplierList: Supplier[] = [];
    displayPagination: boolean;
    pagination: any;
    // roleName: string;
    // isValid: boolean = false;
    limitNumber: any;
    orderBy:string="";
    settingMasterValues:any[] = [];
    //userguest: boolean = false;
    search: string = "";
    roleClaims: any;
    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private titleService: TitleService,
        private translate: TranslateService,
        private notificationsService: NotificationsService,
        private supplierService: SuppliersService,
        private authService: AuthService,
        private _route: ActivatedRoute,
        private _router: Router) {
        this.translationLoaderService.loadTranslations(danish, english);
        this.titleService.SetTitle("TITLE_SUPPLIERS");
        this.roleClaims = this.authService.getRoleClaimsList();
    }

    ngOnInit() {
        // this.roleName = this.authService.getUserRole();
        // if (this.roleName == "Admin") {
        //     this.isValid = true;
        // }
        // else if (this.roleName == "Kontor") {
        //     this.isValid = true;
        // }
        // else if (this.roleName == "Maler") {
        //     this.isValid = false;
        // }
        // else if (this.roleName == "Gaest") {
        //     this.userguest = true;
        //     this.isValid = false;
        // }
        // else {
        //     this.isValid = true;
        // }
        this.SupplierRequest = new SupplierRequest();
        this.SupplierRequest.Index = 1;
        this.SupplierRequest.Limit = 100;
        this.orderBy = "Text";
        this.SupplierRequest.OrderBy = this.orderBy;
        this.settingMasterValues = Object.assign([], JSON.parse(localStorage.getItem("settingMasterValues")));
        var tempSettingObject = this.settingMasterValues.find(s => s.key1 == "Menu" && s.key2 == "TabsListSettings").value;
        if(tempSettingObject && JSON.parse(tempSettingObject).find(x=>x.name=='suppliers').isInitialLoading)
            this.GetSupplierList();
    }

    GetSupplierList() {
        this.showSpinner = true;
        this.supplierService.Get<ResponseBase>(this.SupplierRequest)
            .subscribe({
                next: (response: ResponseBase) => {
                    if (response) {
                        this.SupplierList = response.result;
                        this.pagination = response.pagination;
                        this.displayPagination = true;
                    } else {
                        this.showSpinner = false;
                        this.displayPagination = false;
                    }
                },
                error: err => { },
                complete: () => {
                    this.showSpinner = false;
                }
            });
    }
    
    UpdateFromUniconta() {
        this.showSpinner = true;
        this.supplierService.UpdateFromUniconta<ResponseBase>()
            .subscribe({
                next: (response: ResponseBase) => {
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.Updated").subscribe({
                        next: (res) => {
                            this.notificationsService.create(res);
                        },
                        error: err => {
                        }
                    });
                },
                error: err => { },
                complete: () => {
                    this.showSpinner = false;
                }
            });
    }

    gotoSupplierEdit(id) {
        this._router.navigate(['/supplier/edit', id],{ 
            state: { moduleDataList: this.SupplierList }
        });
    }

    // isValidForm() {
    //     return this.isValid;
    // }

    receivePaginatonEvent(event) {
        this.SupplierRequest.Index = event.pageIndex;
        this.SupplierRequest.Limit = event.pageSize;
        this.GetSupplierList();
    }

    SearchByName(value) {
        if (value || value == "") {
            this.SupplierRequest.Search = value.trim();
            this.GetSupplierList();
        }
    }

    receiveFilterEvent(event) {
        this.SupplierRequest.OrderBy = event;
        if (this.limitNumber == undefined) {
            this.SupplierRequest.Limit = 100
        } else {
            this.SupplierRequest.Limit = this.limitNumber;
        }
        this.GetSupplierList();
    }
}