import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { CompanyInfoSetting } from 'app/main/model/CompanyInfo';
import { TextEditorComponent } from 'app/main/pages/text-editor/text-editor.component';
import { MatDialog } from '@angular/material';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { ApiConstant } from 'app/core/constant/ApiConstant';
import { ProjectService } from 'app/main/pages/project/project.service';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'vibe-projectNotes',
  templateUrl: './projectNotes.component.html',
  styleUrls: ['./projectNotes.component.scss']
})

export class ProjectNotesComponent implements OnInit {
  @Input() projectId: string;
  @Input() NoteDescriptionDetail: string;
  @Output() isDisplayNoteTabRed = new EventEmitter<boolean>();
  showSpinner: boolean = false;
  NoteDescription: string;
  displayNoteTabRed: boolean = false;


  constructor(private translate: TranslateService,
    private translationLoader: FuseTranslationLoaderService,
    private dialog: MatDialog,
    private projectService: ProjectService,
    private notificationsService: NotificationsService
  ) {
    this.translationLoader.loadTranslations(english, danish);
  }

  ngOnInit() {
    this.NoteDescription = this.NoteDescriptionDetail;
    this.getNotetabData();
  }

  getNotetabData() {
    this.showSpinner = true;
    this.projectService.GetProjectRequisition<any>(this.projectId, ApiConstant.RequisitionNote).subscribe({
      next: (response) => {
        this.showSpinner = false;
        this.NoteDescription = response.result.description;
        if (this.NoteDescription != null && this.NoteDescription.trim() != "") {
          this.displayNoteTabRed = true;
        }
        else {
          this.displayNoteTabRed = false;
        }
        this.isDisplayNoteTabRed.emit(this.displayNoteTabRed);
      },
      error: err => {
        this.translate.get("MESSAGES.Failed").subscribe({
          next: (res) => {
            this.notificationsService.error(res);
          },
          error: err => {
          }
        });
      }
    });
  }

  openProjectRequsitionDescriptionForNote() {
    var selectedTabData = new CompanyInfoSetting();
    selectedTabData.Description = this.NoteDescription;

    const dialogRef = this.dialog.open(TextEditorComponent, {
      height: '90%',
      width: '90%',
      data: { selectedTabData, isEmail: false },
      disableClose: true
    });


    dialogRef.afterClosed().subscribe(result => {
      this.showSpinner = true;
      var data = new CompanyInfoSetting();
      data.Description = "";
      if (result && result.Description) {
        data.Description = result.Description;
      }

      this.projectService.UpdateRequisitionExtraTabDescription<ResponseBase>(this.projectId, ApiConstant.RequisitionNote, data)
        .subscribe({
          next: (response: ResponseBase) => {
            this.showSpinner = false;
            this.NoteDescription = response.result.description;
            if (this.NoteDescription != null && this.NoteDescription.trim() != "") {
              this.displayNoteTabRed = true;
            }
            else {
              this.displayNoteTabRed = false;
            }
            this.isDisplayNoteTabRed.emit(this.displayNoteTabRed);
          },
          error: err => {
            this.showSpinner = false;
            this.translate.get("MESSAGES.Failed").subscribe({
              next: (res) => {
                this.notificationsService.error(res);
              },
              error: err => {
              }
            });
          },
          complete: () => {
            this.showSpinner = false;
          }
        });
    });
  }

}