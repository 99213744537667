export const locale = {
    lang: 'en',
    data: {
        'VALIDATIONS': {
            'EMAIL': 'Enter valid email.',
            'LIMIT': 'Enter valid email.',
            'EMAILEXIST': 'Email already exists.',
            'to': 'Valid Email',
            'toName': 'Valid Name',
            'from': 'Valid Email',
            'fromName': 'Valid Name',
            'subject': 'Valid Subject',
        },
        'FIELDS': {
            'SRNO':'Sr.',
            'Email': 'E-mail',
            'CONFIRM': 'Confirm',
            'CANCEL': 'Cancel',
            'IsIncludePriceOnPDF': 'Include Price On PDF',
            'to': 'Email Sent To',
            'toName': 'Email Sent To Name',
            'from': 'Email Sent From',
            'fromName': 'Email Sent From Name',
            'subject': 'Subject',
            'coworker': 'Co-Worker',
            'All': 'All',
            'AttachpdfFile': 'Attach PDF file',
            'MaterialAttachedFiles': 'Material Attached Files',
            'Files': 'Files',
            'ShowRoomTotal': 'Show room total'
        },
        'BUTTON': {
            'CANCEL': 'Cancel',
            'SendEmail': 'Send',
            'SendEmailWithRoomDetail': 'Send with room detail',
            'SeeOffer': 'See Offer',
            'SeeOfferWithRoom':'See Offer With Room',
        }
    }
};
