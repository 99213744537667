export const locale = {
    lang: 'en',
    data: {
        'Tabs': {
            'Send': 'Send',
            'HolidayList': 'Holiday',
            'Material': 'Material',
            'Project': 'Project',
            'ToolsAndLadders':'Tools and Ladders',
            'CustomerDefaultMaterials':'Customer Default Materials',
            'Planning':'Planning',
            'QuickInvoice':'Store Sales',
            'Offer':'Offers',
            'Coworker':'Co-workers',
            'Other':'Other',
            'Payment':'Payment',
            'Permission':'Permission',
            'Document' : 'Document',
            'Credintials' : 'Credintials',
            'ListView': 'List View',
            'Uniconta':'Uniconta',
        },
        'Header':{
            'ProjectEconomy': 'Revenue per hour',
            'CalculateProjectPriceStatus': 'Work in progress',
            'ProjectMonth':'Do not seen project after XXX month.',
            'CoworkerEconomy': 'Coworker rate per hour',
        },
        'Message': {
            'SeenDefaultCoworker':'Seen on planning module selected co-workers.'
        },
        'Buttons':{
            'Question':'Question',
            'Add': 'Add Coworker',
            "AddEmployeeOnNewProject": 'Add employee on new projects',
            'Questions': 'Questions',
            'OfferPricePDF':'Offer Price PDF',
            'AllImage' : 'All Images'
        },
        'TOOTIPORTITLE':{
            'WeatherType':'Weather Types',
            'PostNumber':'Post Number',
            'ColorCode':'Color Code',
            'DownloadExcel':'Download Excel',
            'PriceAdjustment':'Price adjustment',
            'OfferTravelingAmt':'driving per km',
        }
    }
};
