export const CountryJson: any[] = [
    {
        "id": 1,
        "name": "Afghanistan",
        "iso3": "AFG",
        "iso2": "AF",
        "phone_code": "93",
        "capital": "Kabul",
        "currency": "AFN",
        "native": "افغانستان",
        "region": "Asia",
        "subregion": "Southern Asia",
        "timezones": [
            {
                "zoneName": "Asia\/Kabul",
                "gmtOffset": 16200,
                "gmtOffsetName": "UTC+04:30",
                "abbreviation": "AFT",
                "tzName": "Afghanistan Time"
            }
        ],
        "emoji": "🇦🇫",
        "emojiU": "U+1F1E6 U+1F1EB"
    },
    {
        "id": 2,
        "name": "Aland Islands",
        "iso3": "ALA",
        "iso2": "AX",
        "phone_code": "358-18",
        "capital": "Mariehamn",
        "currency": "EUR",
        "native": "Åland",
        "region": "Europe",
        "subregion": "Northern Europe",
        "timezones": [
            {
                "zoneName": "Europe\/Mariehamn",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "EET",
                "tzName": "Eastern European Time"
            }
        ],
        "emoji": "🇦🇽",
        "emojiU": "U+1F1E6 U+1F1FD"
    },
    {
        "id": 3,
        "name": "Albania",
        "iso3": "ALB",
        "iso2": "AL",
        "phone_code": "355",
        "capital": "Tirana",
        "currency": "ALL",
        "native": "Shqipëria",
        "region": "Europe",
        "subregion": "Southern Europe",
        "timezones": [
            {
                "zoneName": "Europe\/Tirane",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "emoji": "🇦🇱",
        "emojiU": "U+1F1E6 U+1F1F1"
    },
    {
        "id": 4,
        "name": "Algeria",
        "iso3": "DZA",
        "iso2": "DZ",
        "phone_code": "213",
        "capital": "Algiers",
        "currency": "DZD",
        "native": "الجزائر",
        "region": "Africa",
        "subregion": "Northern Africa",
        "timezones": [
            {
                "zoneName": "Africa\/Algiers",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "emoji": "🇩🇿",
        "emojiU": "U+1F1E9 U+1F1FF"
    },
    {
        "id": 5,
        "name": "American Samoa",
        "iso3": "ASM",
        "iso2": "AS",
        "phone_code": "1-684",
        "capital": "Pago Pago",
        "currency": "USD",
        "native": "American Samoa",
        "region": "Oceania",
        "subregion": "Polynesia",
        "timezones": [
            {
                "zoneName": "Pacific\/Pago_Pago",
                "gmtOffset": -39600,
                "gmtOffsetName": "UTC-11:00",
                "abbreviation": "SST",
                "tzName": "Samoa Standard Time"
            }
        ],
        "emoji": "🇦🇸",
        "emojiU": "U+1F1E6 U+1F1F8"
    },
    {
        "id": 6,
        "name": "Andorra",
        "iso3": "AND",
        "iso2": "AD",
        "phone_code": "376",
        "capital": "Andorra la Vella",
        "currency": "EUR",
        "native": "Andorra",
        "region": "Europe",
        "subregion": "Southern Europe",
        "timezones": [
            {
                "zoneName": "Europe\/Andorra",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "emoji": "🇦🇩",
        "emojiU": "U+1F1E6 U+1F1E9"
    },
    {
        "id": 7,
        "name": "Angola",
        "iso3": "AGO",
        "iso2": "AO",
        "phone_code": "244",
        "capital": "Luanda",
        "currency": "AOA",
        "native": "Angola",
        "region": "Africa",
        "subregion": "Middle Africa",
        "timezones": [
            {
                "zoneName": "Africa\/Luanda",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "WAT",
                "tzName": "West Africa Time"
            }
        ],
        "emoji": "🇦🇴",
        "emojiU": "U+1F1E6 U+1F1F4"
    },
    {
        "id": 8,
        "name": "Anguilla",
        "iso3": "AIA",
        "iso2": "AI",
        "phone_code": "1-264",
        "capital": "The Valley",
        "currency": "XCD",
        "native": "Anguilla",
        "region": "Americas",
        "subregion": "Caribbean",
        "timezones": [
            {
                "zoneName": "America\/Anguilla",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "AST",
                "tzName": "Atlantic Standard Time"
            }
        ],
        "emoji": "🇦🇮",
        "emojiU": "U+1F1E6 U+1F1EE"
    },
    {
        "id": 10,
        "name": "Antigua And Barbuda",
        "iso3": "ATG",
        "iso2": "AG",
        "phone_code": "1-268",
        "capital": "St. John's",
        "currency": "XCD",
        "native": "Antigua and Barbuda",
        "region": "Americas",
        "subregion": "Caribbean",
        "timezones": [
            {
                "zoneName": "America\/Antigua",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "AST",
                "tzName": "Atlantic Standard Time"
            }
        ],
        "emoji": "🇦🇬",
        "emojiU": "U+1F1E6 U+1F1EC"
    },
    {
        "id": 11,
        "name": "Argentina",
        "iso3": "ARG",
        "iso2": "AR",
        "phone_code": "54",
        "capital": "Buenos Aires",
        "currency": "ARS",
        "native": "Argentina",
        "region": "Americas",
        "subregion": "South America",
        "timezones": [
            {
                "zoneName": "America\/Argentina\/Buenos_Aires",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "ART",
                "tzName": "Argentina Time"
            },
            {
                "zoneName": "America\/Argentina\/Catamarca",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "ART",
                "tzName": "Argentina Time"
            },
            {
                "zoneName": "America\/Argentina\/Cordoba",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "ART",
                "tzName": "Argentina Time"
            },
            {
                "zoneName": "America\/Argentina\/Jujuy",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "ART",
                "tzName": "Argentina Time"
            },
            {
                "zoneName": "America\/Argentina\/La_Rioja",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "ART",
                "tzName": "Argentina Time"
            },
            {
                "zoneName": "America\/Argentina\/Mendoza",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "ART",
                "tzName": "Argentina Time"
            },
            {
                "zoneName": "America\/Argentina\/Rio_Gallegos",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "ART",
                "tzName": "Argentina Time"
            },
            {
                "zoneName": "America\/Argentina\/Salta",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "ART",
                "tzName": "Argentina Time"
            },
            {
                "zoneName": "America\/Argentina\/San_Juan",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "ART",
                "tzName": "Argentina Time"
            },
            {
                "zoneName": "America\/Argentina\/San_Luis",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "ART",
                "tzName": "Argentina Time"
            },
            {
                "zoneName": "America\/Argentina\/Tucuman",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "ART",
                "tzName": "Argentina Time"
            },
            {
                "zoneName": "America\/Argentina\/Ushuaia",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "ART",
                "tzName": "Argentina Time"
            }
        ],
        "emoji": "🇦🇷",
        "emojiU": "U+1F1E6 U+1F1F7"
    },
    {
        "id": 12,
        "name": "Armenia",
        "iso3": "ARM",
        "iso2": "AM",
        "phone_code": "374",
        "capital": "Yerevan",
        "currency": "AMD",
        "native": "Հայաստան",
        "region": "Asia",
        "subregion": "Western Asia",
        "timezones": [
            {
                "zoneName": "Asia\/Yerevan",
                "gmtOffset": 14400,
                "gmtOffsetName": "UTC+04:00",
                "abbreviation": "AMT",
                "tzName": "Armenia Time"
            }
        ],
        "emoji": "🇦🇲",
        "emojiU": "U+1F1E6 U+1F1F2"
    },
    {
        "id": 13,
        "name": "Aruba",
        "iso3": "ABW",
        "iso2": "AW",
        "phone_code": "297",
        "capital": "Oranjestad",
        "currency": "AWG",
        "native": "Aruba",
        "region": "Americas",
        "subregion": "Caribbean",
        "timezones": [
            {
                "zoneName": "America\/Aruba",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "AST",
                "tzName": "Atlantic Standard Time"
            }
        ],
        "emoji": "🇦🇼",
        "emojiU": "U+1F1E6 U+1F1FC"
    },
    {
        "id": 14,
        "name": "Australia",
        "iso3": "AUS",
        "iso2": "AU",
        "phone_code": "61",
        "capital": "Canberra",
        "currency": "AUD",
        "native": "Australia",
        "region": "Oceania",
        "subregion": "Australia and New Zealand",
        "timezones": [
            {
                "zoneName": "Antarctica\/Macquarie",
                "gmtOffset": 39600,
                "gmtOffsetName": "UTC+11:00",
                "abbreviation": "MIST",
                "tzName": "Macquarie Island Station Time"
            },
            {
                "zoneName": "Australia\/Adelaide",
                "gmtOffset": 37800,
                "gmtOffsetName": "UTC+10:30",
                "abbreviation": "ACDT",
                "tzName": "Australian Central Daylight Saving Time"
            },
            {
                "zoneName": "Australia\/Brisbane",
                "gmtOffset": 36000,
                "gmtOffsetName": "UTC+10:00",
                "abbreviation": "AEST",
                "tzName": "Australian Eastern Standard Time"
            },
            {
                "zoneName": "Australia\/Broken_Hill",
                "gmtOffset": 37800,
                "gmtOffsetName": "UTC+10:30",
                "abbreviation": "ACDT",
                "tzName": "Australian Central Daylight Saving Time"
            },
            {
                "zoneName": "Australia\/Currie",
                "gmtOffset": 39600,
                "gmtOffsetName": "UTC+11:00",
                "abbreviation": "AEDT",
                "tzName": "Australian Eastern Daylight Saving Time"
            },
            {
                "zoneName": "Australia\/Darwin",
                "gmtOffset": 34200,
                "gmtOffsetName": "UTC+09:30",
                "abbreviation": "ACST",
                "tzName": "Australian Central Standard Time"
            },
            {
                "zoneName": "Australia\/Eucla",
                "gmtOffset": 31500,
                "gmtOffsetName": "UTC+08:45",
                "abbreviation": "ACWST",
                "tzName": "Australian Central Western Standard Time (Unofficial)"
            },
            {
                "zoneName": "Australia\/Hobart",
                "gmtOffset": 39600,
                "gmtOffsetName": "UTC+11:00",
                "abbreviation": "AEDT",
                "tzName": "Australian Eastern Daylight Saving Time"
            },
            {
                "zoneName": "Australia\/Lindeman",
                "gmtOffset": 36000,
                "gmtOffsetName": "UTC+10:00",
                "abbreviation": "AEST",
                "tzName": "Australian Eastern Standard Time"
            },
            {
                "zoneName": "Australia\/Lord_Howe",
                "gmtOffset": 39600,
                "gmtOffsetName": "UTC+11:00",
                "abbreviation": "LHST",
                "tzName": "Lord Howe Summer Time"
            },
            {
                "zoneName": "Australia\/Melbourne",
                "gmtOffset": 39600,
                "gmtOffsetName": "UTC+11:00",
                "abbreviation": "AEDT",
                "tzName": "Australian Eastern Daylight Saving Time"
            },
            {
                "zoneName": "Australia\/Perth",
                "gmtOffset": 28800,
                "gmtOffsetName": "UTC+08:00",
                "abbreviation": "AWST",
                "tzName": "Australian Western Standard Time"
            },
            {
                "zoneName": "Australia\/Sydney",
                "gmtOffset": 39600,
                "gmtOffsetName": "UTC+11:00",
                "abbreviation": "AEDT",
                "tzName": "Australian Eastern Daylight Saving Time"
            }
        ],
        "emoji": "🇦🇺",
        "emojiU": "U+1F1E6 U+1F1FA"
    },
    {
        "id": 15,
        "name": "Austria",
        "iso3": "AUT",
        "iso2": "AT",
        "phone_code": "43",
        "capital": "Vienna",
        "currency": "EUR",
        "native": "Österreich",
        "region": "Europe",
        "subregion": "Western Europe",
        "timezones": [
            {
                "zoneName": "Europe\/Vienna",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "emoji": "🇦🇹",
        "emojiU": "U+1F1E6 U+1F1F9"
    },
    {
        "id": 16,
        "name": "Azerbaijan",
        "iso3": "AZE",
        "iso2": "AZ",
        "phone_code": "994",
        "capital": "Baku",
        "currency": "AZN",
        "native": "Azərbaycan",
        "region": "Asia",
        "subregion": "Western Asia",
        "timezones": [
            {
                "zoneName": "Asia\/Baku",
                "gmtOffset": 14400,
                "gmtOffsetName": "UTC+04:00",
                "abbreviation": "AZT",
                "tzName": "Azerbaijan Time"
            }
        ],
        "emoji": "🇦🇿",
        "emojiU": "U+1F1E6 U+1F1FF"
    },
    {
        "id": 17,
        "name": "Bahamas The",
        "iso3": "BHS",
        "iso2": "BS",
        "phone_code": "1-242",
        "capital": "Nassau",
        "currency": "BSD",
        "native": "Bahamas",
        "region": "Americas",
        "subregion": "Caribbean",
        "timezones": [
            {
                "zoneName": "America\/Nassau",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "EST",
                "tzName": "Eastern Standard Time (North America)"
            }
        ],
        "emoji": "🇧🇸",
        "emojiU": "U+1F1E7 U+1F1F8"
    },
    {
        "id": 18,
        "name": "Bahrain",
        "iso3": "BHR",
        "iso2": "BH",
        "phone_code": "973",
        "capital": "Manama",
        "currency": "BHD",
        "native": "‏البحرين",
        "region": "Asia",
        "subregion": "Western Asia",
        "timezones": [
            {
                "zoneName": "Asia\/Bahrain",
                "gmtOffset": 10800,
                "gmtOffsetName": "UTC+03:00",
                "abbreviation": "AST",
                "tzName": "Arabia Standard Time"
            }
        ],
        "emoji": "🇧🇭",
        "emojiU": "U+1F1E7 U+1F1ED"
    },
    {
        "id": 19,
        "name": "Bangladesh",
        "iso3": "BGD",
        "iso2": "BD",
        "phone_code": "880",
        "capital": "Dhaka",
        "currency": "BDT",
        "native": "Bangladesh",
        "region": "Asia",
        "subregion": "Southern Asia",
        "timezones": [
            {
                "zoneName": "Asia\/Dhaka",
                "gmtOffset": 21600,
                "gmtOffsetName": "UTC+06:00",
                "abbreviation": "BDT",
                "tzName": "Bangladesh Standard Time"
            }
        ],
        "emoji": "🇧🇩",
        "emojiU": "U+1F1E7 U+1F1E9"
    },
    {
        "id": 20,
        "name": "Barbados",
        "iso3": "BRB",
        "iso2": "BB",
        "phone_code": "1-246",
        "capital": "Bridgetown",
        "currency": "BBD",
        "native": "Barbados",
        "region": "Americas",
        "subregion": "Caribbean",
        "timezones": [
            {
                "zoneName": "America\/Barbados",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "AST",
                "tzName": "Atlantic Standard Time"
            }
        ],
        "emoji": "🇧🇧",
        "emojiU": "U+1F1E7 U+1F1E7"
    },
    {
        "id": 21,
        "name": "Belarus",
        "iso3": "BLR",
        "iso2": "BY",
        "phone_code": "375",
        "capital": "Minsk",
        "currency": "BYN",
        "native": "Белару́сь",
        "region": "Europe",
        "subregion": "Eastern Europe",
        "timezones": [
            {
                "zoneName": "Europe\/Minsk",
                "gmtOffset": 10800,
                "gmtOffsetName": "UTC+03:00",
                "abbreviation": "MSK",
                "tzName": "Moscow Time"
            }
        ],
        "emoji": "🇧🇾",
        "emojiU": "U+1F1E7 U+1F1FE"
    },
    {
        "id": 22,
        "name": "Belgium",
        "iso3": "BEL",
        "iso2": "BE",
        "phone_code": "32",
        "capital": "Brussels",
        "currency": "EUR",
        "native": "België",
        "region": "Europe",
        "subregion": "Western Europe",
        "timezones": [
            {
                "zoneName": "Europe\/Brussels",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "emoji": "🇧🇪",
        "emojiU": "U+1F1E7 U+1F1EA"
    },
    {
        "id": 23,
        "name": "Belize",
        "iso3": "BLZ",
        "iso2": "BZ",
        "phone_code": "501",
        "capital": "Belmopan",
        "currency": "BZD",
        "native": "Belize",
        "region": "Americas",
        "subregion": "Central America",
        "timezones": [
            {
                "zoneName": "America\/Belize",
                "gmtOffset": -21600,
                "gmtOffsetName": "UTC-06:00",
                "abbreviation": "CST",
                "tzName": "Central Standard Time (North America)"
            }
        ],
        "emoji": "🇧🇿",
        "emojiU": "U+1F1E7 U+1F1FF"
    },
    {
        "id": 24,
        "name": "Benin",
        "iso3": "BEN",
        "iso2": "BJ",
        "phone_code": "229",
        "capital": "Porto-Novo",
        "currency": "XOF",
        "native": "Bénin",
        "region": "Africa",
        "subregion": "Western Africa",
        "timezones": [
            {
                "zoneName": "Africa\/Porto-Novo",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "WAT",
                "tzName": "West Africa Time"
            }
        ],
        "emoji": "🇧🇯",
        "emojiU": "U+1F1E7 U+1F1EF"
    },
    {
        "id": 25,
        "name": "Bermuda",
        "iso3": "BMU",
        "iso2": "BM",
        "phone_code": "1-441",
        "capital": "Hamilton",
        "currency": "BMD",
        "native": "Bermuda",
        "region": "Americas",
        "subregion": "Northern America",
        "timezones": [
            {
                "zoneName": "Atlantic\/Bermuda",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "AST",
                "tzName": "Atlantic Standard Time"
            }
        ],
        "emoji": "🇧🇲",
        "emojiU": "U+1F1E7 U+1F1F2"
    },
    {
        "id": 26,
        "name": "Bhutan",
        "iso3": "BTN",
        "iso2": "BT",
        "phone_code": "975",
        "capital": "Thimphu",
        "currency": "BTN",
        "native": "ʼbrug-yul",
        "region": "Asia",
        "subregion": "Southern Asia",
        "timezones": [
            {
                "zoneName": "Asia\/Thimphu",
                "gmtOffset": 21600,
                "gmtOffsetName": "UTC+06:00",
                "abbreviation": "BTT",
                "tzName": "Bhutan Time"
            }
        ],
        "emoji": "🇧🇹",
        "emojiU": "U+1F1E7 U+1F1F9"
    },
    {
        "id": 27,
        "name": "Bolivia",
        "iso3": "BOL",
        "iso2": "BO",
        "phone_code": "591",
        "capital": "Sucre",
        "currency": "BOB",
        "native": "Bolivia",
        "region": "Americas",
        "subregion": "South America",
        "timezones": [
            {
                "zoneName": "America\/La_Paz",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "BOT",
                "tzName": "Bolivia Time"
            }
        ],
        "emoji": "🇧🇴",
        "emojiU": "U+1F1E7 U+1F1F4"
    },
    {
        "id": 28,
        "name": "Bosnia and Herzegovina",
        "iso3": "BIH",
        "iso2": "BA",
        "phone_code": "387",
        "capital": "Sarajevo",
        "currency": "BAM",
        "native": "Bosna i Hercegovina",
        "region": "Europe",
        "subregion": "Southern Europe",
        "timezones": [
            {
                "zoneName": "Europe\/Sarajevo",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "emoji": "🇧🇦",
        "emojiU": "U+1F1E7 U+1F1E6"
    },
    {
        "id": 29,
        "name": "Botswana",
        "iso3": "BWA",
        "iso2": "BW",
        "phone_code": "267",
        "capital": "Gaborone",
        "currency": "BWP",
        "native": "Botswana",
        "region": "Africa",
        "subregion": "Southern Africa",
        "timezones": [
            {
                "zoneName": "Africa\/Gaborone",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "CAT",
                "tzName": "Central Africa Time"
            }
        ],
        "emoji": "🇧🇼",
        "emojiU": "U+1F1E7 U+1F1FC"
    },
    {
        "id": 30,
        "name": "Bouvet Island",
        "iso3": "BVT",
        "iso2": "BV",
        "phone_code": "0055",
        "capital": "",
        "currency": "NOK",
        "native": "Bouvetøya",
        "region": "",
        "subregion": "",
        "timezones": [
            {
                "zoneName": "Europe\/Oslo",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "emoji": "🇧🇻",
        "emojiU": "U+1F1E7 U+1F1FB"
    },
    {
        "id": 31,
        "name": "Brazil",
        "iso3": "BRA",
        "iso2": "BR",
        "phone_code": "55",
        "capital": "Brasilia",
        "currency": "BRL",
        "native": "Brasil",
        "region": "Americas",
        "subregion": "South America",
        "timezones": [
            {
                "zoneName": "America\/Araguaina",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "BRT",
                "tzName": "Brasília Time"
            },
            {
                "zoneName": "America\/Bahia",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "BRT",
                "tzName": "Brasília Time"
            },
            {
                "zoneName": "America\/Belem",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "BRT",
                "tzName": "Brasília Time"
            },
            {
                "zoneName": "America\/Boa_Vista",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "AMT",
                "tzName": "Amazon Time (Brazil)[3"
            },
            {
                "zoneName": "America\/Campo_Grande",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "AMT",
                "tzName": "Amazon Time (Brazil)[3"
            },
            {
                "zoneName": "America\/Cuiaba",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "BRT",
                "tzName": "Brasilia Time"
            },
            {
                "zoneName": "America\/Eirunepe",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "ACT",
                "tzName": "Acre Time"
            },
            {
                "zoneName": "America\/Fortaleza",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "BRT",
                "tzName": "Brasília Time"
            },
            {
                "zoneName": "America\/Maceio",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "BRT",
                "tzName": "Brasília Time"
            },
            {
                "zoneName": "America\/Manaus",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "AMT",
                "tzName": "Amazon Time (Brazil)"
            },
            {
                "zoneName": "America\/Noronha",
                "gmtOffset": -7200,
                "gmtOffsetName": "UTC-02:00",
                "abbreviation": "FNT",
                "tzName": "Fernando de Noronha Time"
            },
            {
                "zoneName": "America\/Porto_Velho",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "AMT",
                "tzName": "Amazon Time (Brazil)[3"
            },
            {
                "zoneName": "America\/Recife",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "BRT",
                "tzName": "Brasília Time"
            },
            {
                "zoneName": "America\/Rio_Branco",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "ACT",
                "tzName": "Acre Time"
            },
            {
                "zoneName": "America\/Santarem",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "BRT",
                "tzName": "Brasília Time"
            },
            {
                "zoneName": "America\/Sao_Paulo",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "BRT",
                "tzName": "Brasília Time"
            }
        ],
        "emoji": "🇧🇷",
        "emojiU": "U+1F1E7 U+1F1F7"
    },
    {
        "id": 32,
        "name": "British Indian Ocean Territory",
        "iso3": "IOT",
        "iso2": "IO",
        "phone_code": "246",
        "capital": "Diego Garcia",
        "currency": "USD",
        "native": "British Indian Ocean Territory",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "timezones": [
            {
                "zoneName": "Indian\/Chagos",
                "gmtOffset": 21600,
                "gmtOffsetName": "UTC+06:00",
                "abbreviation": "IOT",
                "tzName": "Indian Ocean Time"
            }
        ],
        "emoji": "🇮🇴",
        "emojiU": "U+1F1EE U+1F1F4"
    },
    {
        "id": 33,
        "name": "Brunei",
        "iso3": "BRN",
        "iso2": "BN",
        "phone_code": "673",
        "capital": "Bandar Seri Begawan",
        "currency": "BND",
        "native": "Negara Brunei Darussalam",
        "region": "Asia",
        "subregion": "South-Eastern Asia",
        "timezones": [
            {
                "zoneName": "Asia\/Brunei",
                "gmtOffset": 28800,
                "gmtOffsetName": "UTC+08:00",
                "abbreviation": "BNT",
                "tzName": "Brunei Darussalam Time"
            }
        ],
        "emoji": "🇧🇳",
        "emojiU": "U+1F1E7 U+1F1F3"
    },
    {
        "id": 34,
        "name": "Bulgaria",
        "iso3": "BGR",
        "iso2": "BG",
        "phone_code": "359",
        "capital": "Sofia",
        "currency": "BGN",
        "native": "България",
        "region": "Europe",
        "subregion": "Eastern Europe",
        "timezones": [
            {
                "zoneName": "Europe\/Sofia",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "EET",
                "tzName": "Eastern European Time"
            }
        ],
        "emoji": "🇧🇬",
        "emojiU": "U+1F1E7 U+1F1EC"
    },
    {
        "id": 35,
        "name": "Burkina Faso",
        "iso3": "BFA",
        "iso2": "BF",
        "phone_code": "226",
        "capital": "Ouagadougou",
        "currency": "XOF",
        "native": "Burkina Faso",
        "region": "Africa",
        "subregion": "Western Africa",
        "timezones": [
            {
                "zoneName": "Africa\/Ouagadougou",
                "gmtOffset": 0,
                "gmtOffsetName": "UTC±00",
                "abbreviation": "GMT",
                "tzName": "Greenwich Mean Time"
            }
        ],
        "emoji": "🇧🇫",
        "emojiU": "U+1F1E7 U+1F1EB"
    },
    {
        "id": 36,
        "name": "Burundi",
        "iso3": "BDI",
        "iso2": "BI",
        "phone_code": "257",
        "capital": "Bujumbura",
        "currency": "BIF",
        "native": "Burundi",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "timezones": [
            {
                "zoneName": "Africa\/Bujumbura",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "CAT",
                "tzName": "Central Africa Time"
            }
        ],
        "emoji": "🇧🇮",
        "emojiU": "U+1F1E7 U+1F1EE"
    },
    {
        "id": 37,
        "name": "Cambodia",
        "iso3": "KHM",
        "iso2": "KH",
        "phone_code": "855",
        "capital": "Phnom Penh",
        "currency": "KHR",
        "native": "Kâmpŭchéa",
        "region": "Asia",
        "subregion": "South-Eastern Asia",
        "timezones": [
            {
                "zoneName": "Asia\/Phnom_Penh",
                "gmtOffset": 25200,
                "gmtOffsetName": "UTC+07:00",
                "abbreviation": "ICT",
                "tzName": "Indochina Time"
            }
        ],
        "emoji": "🇰🇭",
        "emojiU": "U+1F1F0 U+1F1ED"
    },
    {
        "id": 38,
        "name": "Cameroon",
        "iso3": "CMR",
        "iso2": "CM",
        "phone_code": "237",
        "capital": "Yaounde",
        "currency": "XAF",
        "native": "Cameroon",
        "region": "Africa",
        "subregion": "Middle Africa",
        "timezones": [
            {
                "zoneName": "Africa\/Douala",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "WAT",
                "tzName": "West Africa Time"
            }
        ],
        "emoji": "🇨🇲",
        "emojiU": "U+1F1E8 U+1F1F2"
    },
    {
        "id": 39,
        "name": "Canada",
        "iso3": "CAN",
        "iso2": "CA",
        "phone_code": "1",
        "capital": "Ottawa",
        "currency": "CAD",
        "native": "Canada",
        "region": "Americas",
        "subregion": "Northern America",
        "timezones": [
            {
                "zoneName": "America\/Atikokan",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "EST",
                "tzName": "Eastern Standard Time (North America)"
            },
            {
                "zoneName": "America\/Blanc-Sablon",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "AST",
                "tzName": "Atlantic Standard Time"
            },
            {
                "zoneName": "America\/Cambridge_Bay",
                "gmtOffset": -25200,
                "gmtOffsetName": "UTC-07:00",
                "abbreviation": "MST",
                "tzName": "Mountain Standard Time (North America)"
            },
            {
                "zoneName": "America\/Creston",
                "gmtOffset": -25200,
                "gmtOffsetName": "UTC-07:00",
                "abbreviation": "MST",
                "tzName": "Mountain Standard Time (North America)"
            },
            {
                "zoneName": "America\/Dawson",
                "gmtOffset": -25200,
                "gmtOffsetName": "UTC-07:00",
                "abbreviation": "MST",
                "tzName": "Mountain Standard Time (North America)"
            },
            {
                "zoneName": "America\/Dawson_Creek",
                "gmtOffset": -25200,
                "gmtOffsetName": "UTC-07:00",
                "abbreviation": "MST",
                "tzName": "Mountain Standard Time (North America)"
            },
            {
                "zoneName": "America\/Edmonton",
                "gmtOffset": -25200,
                "gmtOffsetName": "UTC-07:00",
                "abbreviation": "MST",
                "tzName": "Mountain Standard Time (North America)"
            },
            {
                "zoneName": "America\/Fort_Nelson",
                "gmtOffset": -25200,
                "gmtOffsetName": "UTC-07:00",
                "abbreviation": "MST",
                "tzName": "Mountain Standard Time (North America)"
            },
            {
                "zoneName": "America\/Glace_Bay",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "AST",
                "tzName": "Atlantic Standard Time"
            },
            {
                "zoneName": "America\/Goose_Bay",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "AST",
                "tzName": "Atlantic Standard Time"
            },
            {
                "zoneName": "America\/Halifax",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "AST",
                "tzName": "Atlantic Standard Time"
            },
            {
                "zoneName": "America\/Inuvik",
                "gmtOffset": -25200,
                "gmtOffsetName": "UTC-07:00",
                "abbreviation": "MST",
                "tzName": "Mountain Standard Time (North America"
            },
            {
                "zoneName": "America\/Iqaluit",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "EST",
                "tzName": "Eastern Standard Time (North America"
            },
            {
                "zoneName": "America\/Moncton",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "AST",
                "tzName": "Atlantic Standard Time"
            },
            {
                "zoneName": "America\/Nipigon",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "EST",
                "tzName": "Eastern Standard Time (North America"
            },
            {
                "zoneName": "America\/Pangnirtung",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "EST",
                "tzName": "Eastern Standard Time (North America"
            },
            {
                "zoneName": "America\/Rainy_River",
                "gmtOffset": -21600,
                "gmtOffsetName": "UTC-06:00",
                "abbreviation": "CST",
                "tzName": "Central Standard Time (North America"
            },
            {
                "zoneName": "America\/Rankin_Inlet",
                "gmtOffset": -21600,
                "gmtOffsetName": "UTC-06:00",
                "abbreviation": "CST",
                "tzName": "Central Standard Time (North America"
            },
            {
                "zoneName": "America\/Regina",
                "gmtOffset": -21600,
                "gmtOffsetName": "UTC-06:00",
                "abbreviation": "CST",
                "tzName": "Central Standard Time (North America"
            },
            {
                "zoneName": "America\/Resolute",
                "gmtOffset": -21600,
                "gmtOffsetName": "UTC-06:00",
                "abbreviation": "CST",
                "tzName": "Central Standard Time (North America"
            },
            {
                "zoneName": "America\/St_Johns",
                "gmtOffset": -12600,
                "gmtOffsetName": "UTC-03:30",
                "abbreviation": "NST",
                "tzName": "Newfoundland Standard Time"
            },
            {
                "zoneName": "America\/Swift_Current",
                "gmtOffset": -21600,
                "gmtOffsetName": "UTC-06:00",
                "abbreviation": "CST",
                "tzName": "Central Standard Time (North America"
            },
            {
                "zoneName": "America\/Thunder_Bay",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "EST",
                "tzName": "Eastern Standard Time (North America"
            },
            {
                "zoneName": "America\/Toronto",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "EST",
                "tzName": "Eastern Standard Time (North America"
            },
            {
                "zoneName": "America\/Vancouver",
                "gmtOffset": -28800,
                "gmtOffsetName": "UTC-08:00",
                "abbreviation": "PST",
                "tzName": "Pacific Standard Time (North America"
            },
            {
                "zoneName": "America\/Whitehorse",
                "gmtOffset": -25200,
                "gmtOffsetName": "UTC-07:00",
                "abbreviation": "MST",
                "tzName": "Mountain Standard Time (North America"
            },
            {
                "zoneName": "America\/Winnipeg",
                "gmtOffset": -21600,
                "gmtOffsetName": "UTC-06:00",
                "abbreviation": "CST",
                "tzName": "Central Standard Time (North America"
            },
            {
                "zoneName": "America\/Yellowknife",
                "gmtOffset": -25200,
                "gmtOffsetName": "UTC-07:00",
                "abbreviation": "MST",
                "tzName": "Mountain Standard Time (North America"
            }
        ],
        "emoji": "🇨🇦",
        "emojiU": "U+1F1E8 U+1F1E6"
    },
    {
        "id": 40,
        "name": "Cape Verde",
        "iso3": "CPV",
        "iso2": "CV",
        "phone_code": "238",
        "capital": "Praia",
        "currency": "CVE",
        "native": "Cabo Verde",
        "region": "Africa",
        "subregion": "Western Africa",
        "timezones": [
            {
                "zoneName": "Atlantic\/Cape_Verde",
                "gmtOffset": -3600,
                "gmtOffsetName": "UTC-01:00",
                "abbreviation": "CVT",
                "tzName": "Cape Verde Time"
            }
        ],
        "emoji": "🇨🇻",
        "emojiU": "U+1F1E8 U+1F1FB"
    },
    {
        "id": 41,
        "name": "Cayman Islands",
        "iso3": "CYM",
        "iso2": "KY",
        "phone_code": "1-345",
        "capital": "George Town",
        "currency": "KYD",
        "native": "Cayman Islands",
        "region": "Americas",
        "subregion": "Caribbean",
        "timezones": [
            {
                "zoneName": "America\/Cayman",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "EST",
                "tzName": "Eastern Standard Time (North America"
            }
        ],
        "emoji": "🇰🇾",
        "emojiU": "U+1F1F0 U+1F1FE"
    },
    {
        "id": 42,
        "name": "Central African Republic",
        "iso3": "CAF",
        "iso2": "CF",
        "phone_code": "236",
        "capital": "Bangui",
        "currency": "XAF",
        "native": "Ködörösêse tî Bêafrîka",
        "region": "Africa",
        "subregion": "Middle Africa",
        "timezones": [
            {
                "zoneName": "Africa\/Bangui",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "WAT",
                "tzName": "West Africa Time"
            }
        ],
        "emoji": "🇨🇫",
        "emojiU": "U+1F1E8 U+1F1EB"
    },
    {
        "id": 43,
        "name": "Chad",
        "iso3": "TCD",
        "iso2": "TD",
        "phone_code": "235",
        "capital": "N'Djamena",
        "currency": "XAF",
        "native": "Tchad",
        "region": "Africa",
        "subregion": "Middle Africa",
        "timezones": [
            {
                "zoneName": "Africa\/Ndjamena",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "WAT",
                "tzName": "West Africa Time"
            }
        ],
        "emoji": "🇹🇩",
        "emojiU": "U+1F1F9 U+1F1E9"
    },
    {
        "id": 44,
        "name": "Chile",
        "iso3": "CHL",
        "iso2": "CL",
        "phone_code": "56",
        "capital": "Santiago",
        "currency": "CLP",
        "native": "Chile",
        "region": "Americas",
        "subregion": "South America",
        "timezones": [
            {
                "zoneName": "America\/Punta_Arenas",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "CLST",
                "tzName": "Chile Summer Time"
            },
            {
                "zoneName": "America\/Santiago",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "CLST",
                "tzName": "Chile Summer Time"
            },
            {
                "zoneName": "Pacific\/Easter",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "EASST",
                "tzName": "Easter Island Summer Time"
            }
        ],
        "emoji": "🇨🇱",
        "emojiU": "U+1F1E8 U+1F1F1"
    },
    {
        "id": 45,
        "name": "China",
        "iso3": "CHN",
        "iso2": "CN",
        "phone_code": "86",
        "capital": "Beijing",
        "currency": "CNY",
        "native": "中国",
        "region": "Asia",
        "subregion": "Eastern Asia",
        "timezones": [
            {
                "zoneName": "Asia\/Shanghai",
                "gmtOffset": 28800,
                "gmtOffsetName": "UTC+08:00",
                "abbreviation": "CST",
                "tzName": "China Standard Time"
            },
            {
                "zoneName": "Asia\/Urumqi",
                "gmtOffset": 21600,
                "gmtOffsetName": "UTC+06:00",
                "abbreviation": "XJT",
                "tzName": "China Standard Time"
            }
        ],
        "emoji": "🇨🇳",
        "emojiU": "U+1F1E8 U+1F1F3"
    },
    {
        "id": 46,
        "name": "Christmas Island",
        "iso3": "CXR",
        "iso2": "CX",
        "phone_code": "61",
        "capital": "Flying Fish Cove",
        "currency": "AUD",
        "native": "Christmas Island",
        "region": "Oceania",
        "subregion": "Australia and New Zealand",
        "timezones": [
            {
                "zoneName": "Indian\/Christmas",
                "gmtOffset": 25200,
                "gmtOffsetName": "UTC+07:00",
                "abbreviation": "CXT",
                "tzName": "Christmas Island Time"
            }
        ],
        "emoji": "🇨🇽",
        "emojiU": "U+1F1E8 U+1F1FD"
    },
    {
        "id": 47,
        "name": "Cocos (Keeling) Islands",
        "iso3": "CCK",
        "iso2": "CC",
        "phone_code": "61",
        "capital": "West Island",
        "currency": "AUD",
        "native": "Cocos (Keeling) Islands",
        "region": "Oceania",
        "subregion": "Australia and New Zealand",
        "timezones": [
            {
                "zoneName": "Indian\/Cocos",
                "gmtOffset": 23400,
                "gmtOffsetName": "UTC+06:30",
                "abbreviation": "CCT",
                "tzName": "Cocos Islands Time"
            }
        ],
        "emoji": "🇨🇨",
        "emojiU": "U+1F1E8 U+1F1E8"
    },
    {
        "id": 48,
        "name": "Colombia",
        "iso3": "COL",
        "iso2": "CO",
        "phone_code": "57",
        "capital": "Bogota",
        "currency": "COP",
        "native": "Colombia",
        "region": "Americas",
        "subregion": "South America",
        "timezones": [
            {
                "zoneName": "America\/Bogota",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "COT",
                "tzName": "Colombia Time"
            }
        ],
        "emoji": "🇨🇴",
        "emojiU": "U+1F1E8 U+1F1F4"
    },
    {
        "id": 49,
        "name": "Comoros",
        "iso3": "COM",
        "iso2": "KM",
        "phone_code": "269",
        "capital": "Moroni",
        "currency": "KMF",
        "native": "Komori",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "timezones": [
            {
                "zoneName": "Indian\/Comoro",
                "gmtOffset": 10800,
                "gmtOffsetName": "UTC+03:00",
                "abbreviation": "EAT",
                "tzName": "East Africa Time"
            }
        ],
        "emoji": "🇰🇲",
        "emojiU": "U+1F1F0 U+1F1F2"
    },
    {
        "id": 50,
        "name": "Congo",
        "iso3": "COG",
        "iso2": "CG",
        "phone_code": "242",
        "capital": "Brazzaville",
        "currency": "XAF",
        "native": "République du Congo",
        "region": "Africa",
        "subregion": "Middle Africa",
        "timezones": [
            {
                "zoneName": "Africa\/Brazzaville",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "WAT",
                "tzName": "West Africa Time"
            }
        ],
        "emoji": "🇨🇬",
        "emojiU": "U+1F1E8 U+1F1EC"
    },
    {
        "id": 51,
        "name": "Congo The Democratic Republic Of The",
        "iso3": "COD",
        "iso2": "CD",
        "phone_code": "243",
        "capital": "Kinshasa",
        "currency": "CDF",
        "native": "République démocratique du Congo",
        "region": "Africa",
        "subregion": "Middle Africa",
        "timezones": [
            {
                "zoneName": "Africa\/Kinshasa",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "WAT",
                "tzName": "West Africa Time"
            },
            {
                "zoneName": "Africa\/Lubumbashi",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "CAT",
                "tzName": "Central Africa Time"
            }
        ],
        "emoji": "🇨🇩",
        "emojiU": "U+1F1E8 U+1F1E9"
    },
    {
        "id": 52,
        "name": "Cook Islands",
        "iso3": "COK",
        "iso2": "CK",
        "phone_code": "682",
        "capital": "Avarua",
        "currency": "NZD",
        "native": "Cook Islands",
        "region": "Oceania",
        "subregion": "Polynesia",
        "timezones": [
            {
                "zoneName": "Pacific\/Rarotonga",
                "gmtOffset": -36000,
                "gmtOffsetName": "UTC-10:00",
                "abbreviation": "CKT",
                "tzName": "Cook Island Time"
            }
        ],
        "emoji": "🇨🇰",
        "emojiU": "U+1F1E8 U+1F1F0"
    },
    {
        "id": 53,
        "name": "Costa Rica",
        "iso3": "CRI",
        "iso2": "CR",
        "phone_code": "506",
        "capital": "San Jose",
        "currency": "CRC",
        "native": "Costa Rica",
        "region": "Americas",
        "subregion": "Central America",
        "timezones": [
            {
                "zoneName": "America\/Costa_Rica",
                "gmtOffset": -21600,
                "gmtOffsetName": "UTC-06:00",
                "abbreviation": "CST",
                "tzName": "Central Standard Time (North America"
            }
        ],
        "emoji": "🇨🇷",
        "emojiU": "U+1F1E8 U+1F1F7"
    },
    {
        "id": 55,
        "name": "Croatia (Hrvatska)",
        "iso3": "HRV",
        "iso2": "HR",
        "phone_code": "385",
        "capital": "Zagreb",
        "currency": "HRK",
        "native": "Hrvatska",
        "region": "Europe",
        "subregion": "Southern Europe",
        "timezones": [
            {
                "zoneName": "Europe\/Zagreb",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "emoji": "🇭🇷",
        "emojiU": "U+1F1ED U+1F1F7"
    },
    {
        "id": 56,
        "name": "Cuba",
        "iso3": "CUB",
        "iso2": "CU",
        "phone_code": "53",
        "capital": "Havana",
        "currency": "CUP",
        "native": "Cuba",
        "region": "Americas",
        "subregion": "Caribbean",
        "timezones": [
            {
                "zoneName": "America\/Havana",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "CST",
                "tzName": "Cuba Standard Time"
            }
        ],
        "emoji": "🇨🇺",
        "emojiU": "U+1F1E8 U+1F1FA"
    },
    {
        "id": 57,
        "name": "Cyprus",
        "iso3": "CYP",
        "iso2": "CY",
        "phone_code": "357",
        "capital": "Nicosia",
        "currency": "EUR",
        "native": "Κύπρος",
        "region": "Europe",
        "subregion": "Southern Europe",
        "timezones": [
            {
                "zoneName": "Asia\/Famagusta",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "EET",
                "tzName": "Eastern European Time"
            },
            {
                "zoneName": "Asia\/Nicosia",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "EET",
                "tzName": "Eastern European Time"
            }
        ],
        "emoji": "🇨🇾",
        "emojiU": "U+1F1E8 U+1F1FE"
    },
    {
        "id": 58,
        "name": "Czech Republic",
        "iso3": "CZE",
        "iso2": "CZ",
        "phone_code": "420",
        "capital": "Prague",
        "currency": "CZK",
        "native": "Česká republika",
        "region": "Europe",
        "subregion": "Eastern Europe",
        "timezones": [
            {
                "zoneName": "Europe\/Prague",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "emoji": "🇨🇿",
        "emojiU": "U+1F1E8 U+1F1FF"
    },
    {
        "id": 59,
        "name": "Denmark",
        "iso3": "DNK",
        "iso2": "DK",
        "phone_code": "45",
        "capital": "Copenhagen",
        "currency": "DKK",
        "native": "Danmark",
        "region": "Europe",
        "subregion": "Northern Europe",
        "timezones": [
            {
                "zoneName": "Europe\/Copenhagen",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "emoji": "🇩🇰",
        "emojiU": "U+1F1E9 U+1F1F0"
    },
    {
        "id": 60,
        "name": "Djibouti",
        "iso3": "DJI",
        "iso2": "DJ",
        "phone_code": "253",
        "capital": "Djibouti",
        "currency": "DJF",
        "native": "Djibouti",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "timezones": [
            {
                "zoneName": "Africa\/Djibouti",
                "gmtOffset": 10800,
                "gmtOffsetName": "UTC+03:00",
                "abbreviation": "EAT",
                "tzName": "East Africa Time"
            }
        ],
        "emoji": "🇩🇯",
        "emojiU": "U+1F1E9 U+1F1EF"
    },
    {
        "id": 61,
        "name": "Dominica",
        "iso3": "DMA",
        "iso2": "DM",
        "phone_code": "1-767",
        "capital": "Roseau",
        "currency": "XCD",
        "native": "Dominica",
        "region": "Americas",
        "subregion": "Caribbean",
        "timezones": [
            {
                "zoneName": "America\/Dominica",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "AST",
                "tzName": "Atlantic Standard Time"
            }
        ],
        "emoji": "🇩🇲",
        "emojiU": "U+1F1E9 U+1F1F2"
    },
    {
        "id": 62,
        "name": "Dominican Republic",
        "iso3": "DOM",
        "iso2": "DO",
        "phone_code": "1-809 and 1-829",
        "capital": "Santo Domingo",
        "currency": "DOP",
        "native": "República Dominicana",
        "region": "Americas",
        "subregion": "Caribbean",
        "timezones": [
            {
                "zoneName": "America\/Santo_Domingo",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "AST",
                "tzName": "Atlantic Standard Time"
            }
        ],
        "emoji": "🇩🇴",
        "emojiU": "U+1F1E9 U+1F1F4"
    },
    {
        "id": 64,
        "name": "Ecuador",
        "iso3": "ECU",
        "iso2": "EC",
        "phone_code": "593",
        "capital": "Quito",
        "currency": "USD",
        "native": "Ecuador",
        "region": "Americas",
        "subregion": "South America",
        "timezones": [
            {
                "zoneName": "America\/Guayaquil",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "ECT",
                "tzName": "Ecuador Time"
            },
            {
                "zoneName": "Pacific\/Galapagos",
                "gmtOffset": -21600,
                "gmtOffsetName": "UTC-06:00",
                "abbreviation": "GALT",
                "tzName": "Galápagos Time"
            }
        ],
        "emoji": "🇪🇨",
        "emojiU": "U+1F1EA U+1F1E8"
    },
    {
        "id": 65,
        "name": "Egypt",
        "iso3": "EGY",
        "iso2": "EG",
        "phone_code": "20",
        "capital": "Cairo",
        "currency": "EGP",
        "native": "مصر‎",
        "region": "Africa",
        "subregion": "Northern Africa",
        "timezones": [
            {
                "zoneName": "Africa\/Cairo",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "EET",
                "tzName": "Eastern European Time"
            }
        ],
        "emoji": "🇪🇬",
        "emojiU": "U+1F1EA U+1F1EC"
    },
    {
        "id": 66,
        "name": "El Salvador",
        "iso3": "SLV",
        "iso2": "SV",
        "phone_code": "503",
        "capital": "San Salvador",
        "currency": "USD",
        "native": "El Salvador",
        "region": "Americas",
        "subregion": "Central America",
        "timezones": [
            {
                "zoneName": "America\/El_Salvador",
                "gmtOffset": -21600,
                "gmtOffsetName": "UTC-06:00",
                "abbreviation": "CST",
                "tzName": "Central Standard Time (North America"
            }
        ],
        "emoji": "🇸🇻",
        "emojiU": "U+1F1F8 U+1F1FB"
    },
    {
        "id": 67,
        "name": "Equatorial Guinea",
        "iso3": "GNQ",
        "iso2": "GQ",
        "phone_code": "240",
        "capital": "Malabo",
        "currency": "XAF",
        "native": "Guinea Ecuatorial",
        "region": "Africa",
        "subregion": "Middle Africa",
        "timezones": [
            {
                "zoneName": "Africa\/Malabo",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "WAT",
                "tzName": "West Africa Time"
            }
        ],
        "emoji": "🇬🇶",
        "emojiU": "U+1F1EC U+1F1F6"
    },
    {
        "id": 68,
        "name": "Eritrea",
        "iso3": "ERI",
        "iso2": "ER",
        "phone_code": "291",
        "capital": "Asmara",
        "currency": "ERN",
        "native": "ኤርትራ",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "timezones": [
            {
                "zoneName": "Africa\/Asmara",
                "gmtOffset": 10800,
                "gmtOffsetName": "UTC+03:00",
                "abbreviation": "EAT",
                "tzName": "East Africa Time"
            }
        ],
        "emoji": "🇪🇷",
        "emojiU": "U+1F1EA U+1F1F7"
    },
    {
        "id": 69,
        "name": "Estonia",
        "iso3": "EST",
        "iso2": "EE",
        "phone_code": "372",
        "capital": "Tallinn",
        "currency": "EUR",
        "native": "Eesti",
        "region": "Europe",
        "subregion": "Northern Europe",
        "timezones": [
            {
                "zoneName": "Europe\/Tallinn",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "EET",
                "tzName": "Eastern European Time"
            }
        ],
        "emoji": "🇪🇪",
        "emojiU": "U+1F1EA U+1F1EA"
    },
    {
        "id": 70,
        "name": "Ethiopia",
        "iso3": "ETH",
        "iso2": "ET",
        "phone_code": "251",
        "capital": "Addis Ababa",
        "currency": "ETB",
        "native": "ኢትዮጵያ",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "timezones": [
            {
                "zoneName": "Africa\/Addis_Ababa",
                "gmtOffset": 10800,
                "gmtOffsetName": "UTC+03:00",
                "abbreviation": "EAT",
                "tzName": "East Africa Time"
            }
        ],
        "emoji": "🇪🇹",
        "emojiU": "U+1F1EA U+1F1F9"
    },
    {
        "id": 71,
        "name": "Falkland Islands",
        "iso3": "FLK",
        "iso2": "FK",
        "phone_code": "500",
        "capital": "Stanley",
        "currency": "FKP",
        "native": "Falkland Islands",
        "region": "Americas",
        "subregion": "South America",
        "timezones": [
            {
                "zoneName": "Atlantic\/Stanley",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "FKST",
                "tzName": "Falkland Islands Summer Time"
            }
        ],
        "emoji": "🇫🇰",
        "emojiU": "U+1F1EB U+1F1F0"
    },
    {
        "id": 72,
        "name": "Faroe Islands",
        "iso3": "FRO",
        "iso2": "FO",
        "phone_code": "298",
        "capital": "Torshavn",
        "currency": "DKK",
        "native": "Føroyar",
        "region": "Europe",
        "subregion": "Northern Europe",
        "timezones": [
            {
                "zoneName": "Atlantic\/Faroe",
                "gmtOffset": 0,
                "gmtOffsetName": "UTC±00",
                "abbreviation": "WET",
                "tzName": "Western European Time"
            }
        ],
        "emoji": "🇫🇴",
        "emojiU": "U+1F1EB U+1F1F4"
    },
    {
        "id": 73,
        "name": "Fiji Islands",
        "iso3": "FJI",
        "iso2": "FJ",
        "phone_code": "679",
        "capital": "Suva",
        "currency": "FJD",
        "native": "Fiji",
        "region": "Oceania",
        "subregion": "Melanesia",
        "timezones": [
            {
                "zoneName": "Pacific\/Fiji",
                "gmtOffset": 43200,
                "gmtOffsetName": "UTC+12:00",
                "abbreviation": "FJT",
                "tzName": "Fiji Time"
            }
        ],
        "emoji": "🇫🇯",
        "emojiU": "U+1F1EB U+1F1EF"
    },
    {
        "id": 74,
        "name": "Finland",
        "iso3": "FIN",
        "iso2": "FI",
        "phone_code": "358",
        "capital": "Helsinki",
        "currency": "EUR",
        "native": "Suomi",
        "region": "Europe",
        "subregion": "Northern Europe",
        "timezones": [
            {
                "zoneName": "Europe\/Helsinki",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "EET",
                "tzName": "Eastern European Time"
            }
        ],
        "emoji": "🇫🇮",
        "emojiU": "U+1F1EB U+1F1EE"
    },
    {
        "id": 75,
        "name": "France",
        "iso3": "FRA",
        "iso2": "FR",
        "phone_code": "33",
        "capital": "Paris",
        "currency": "EUR",
        "native": "France",
        "region": "Europe",
        "subregion": "Western Europe",
        "timezones": [
            {
                "zoneName": "Europe\/Paris",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "emoji": "🇫🇷",
        "emojiU": "U+1F1EB U+1F1F7"
    },
    {
        "id": 76,
        "name": "French Guiana",
        "iso3": "GUF",
        "iso2": "GF",
        "phone_code": "594",
        "capital": "Cayenne",
        "currency": "EUR",
        "native": "Guyane française",
        "region": "Americas",
        "subregion": "South America",
        "timezones": [
            {
                "zoneName": "America\/Cayenne",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "GFT",
                "tzName": "French Guiana Time"
            }
        ],
        "emoji": "🇬🇫",
        "emojiU": "U+1F1EC U+1F1EB"
    },
    {
        "id": 77,
        "name": "French Polynesia",
        "iso3": "PYF",
        "iso2": "PF",
        "phone_code": "689",
        "capital": "Papeete",
        "currency": "XPF",
        "native": "Polynésie française",
        "region": "Oceania",
        "subregion": "Polynesia",
        "timezones": [
            {
                "zoneName": "Pacific\/Gambier",
                "gmtOffset": -32400,
                "gmtOffsetName": "UTC-09:00",
                "abbreviation": "GAMT",
                "tzName": "Gambier Islands Time"
            },
            {
                "zoneName": "Pacific\/Marquesas",
                "gmtOffset": -34200,
                "gmtOffsetName": "UTC-09:30",
                "abbreviation": "MART",
                "tzName": "Marquesas Islands Time"
            },
            {
                "zoneName": "Pacific\/Tahiti",
                "gmtOffset": -36000,
                "gmtOffsetName": "UTC-10:00",
                "abbreviation": "TAHT",
                "tzName": "Tahiti Time"
            }
        ],
        "emoji": "🇵🇫",
        "emojiU": "U+1F1F5 U+1F1EB"
    },
    {
        "id": 78,
        "name": "French Southern Territories",
        "iso3": "ATF",
        "iso2": "TF",
        "phone_code": "",
        "capital": "Port-aux-Francais",
        "currency": "EUR",
        "native": "Territoire des Terres australes et antarctiques fr",
        "region": "Africa",
        "subregion": "Southern Africa",
        "timezones": [
            {
                "zoneName": "Indian\/Kerguelen",
                "gmtOffset": 18000,
                "gmtOffsetName": "UTC+05:00",
                "abbreviation": "TFT",
                "tzName": "French Southern and Antarctic Time"
            }
        ],
        "emoji": "🇹🇫",
        "emojiU": "U+1F1F9 U+1F1EB"
    },
    {
        "id": 79,
        "name": "Gabon",
        "iso3": "GAB",
        "iso2": "GA",
        "phone_code": "241",
        "capital": "Libreville",
        "currency": "XAF",
        "native": "Gabon",
        "region": "Africa",
        "subregion": "Middle Africa",
        "timezones": [
            {
                "zoneName": "Africa\/Libreville",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "WAT",
                "tzName": "West Africa Time"
            }
        ],
        "emoji": "🇬🇦",
        "emojiU": "U+1F1EC U+1F1E6"
    },
    {
        "id": 80,
        "name": "Gambia The",
        "iso3": "GMB",
        "iso2": "GM",
        "phone_code": "220",
        "capital": "Banjul",
        "currency": "GMD",
        "native": "Gambia",
        "region": "Africa",
        "subregion": "Western Africa",
        "timezones": [
            {
                "zoneName": "Africa\/Banjul",
                "gmtOffset": 0,
                "gmtOffsetName": "UTC±00",
                "abbreviation": "GMT",
                "tzName": "Greenwich Mean Time"
            }
        ],
        "emoji": "🇬🇲",
        "emojiU": "U+1F1EC U+1F1F2"
    },
    {
        "id": 81,
        "name": "Georgia",
        "iso3": "GEO",
        "iso2": "GE",
        "phone_code": "995",
        "capital": "Tbilisi",
        "currency": "GEL",
        "native": "საქართველო",
        "region": "Asia",
        "subregion": "Western Asia",
        "timezones": [
            {
                "zoneName": "Asia\/Tbilisi",
                "gmtOffset": 14400,
                "gmtOffsetName": "UTC+04:00",
                "abbreviation": "GET",
                "tzName": "Georgia Standard Time"
            }
        ],
        "emoji": "🇬🇪",
        "emojiU": "U+1F1EC U+1F1EA"
    },
    {
        "id": 82,
        "name": "Germany",
        "iso3": "DEU",
        "iso2": "DE",
        "phone_code": "49",
        "capital": "Berlin",
        "currency": "EUR",
        "native": "Deutschland",
        "region": "Europe",
        "subregion": "Western Europe",
        "timezones": [
            {
                "zoneName": "Europe\/Berlin",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            },
            {
                "zoneName": "Europe\/Busingen",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "emoji": "🇩🇪",
        "emojiU": "U+1F1E9 U+1F1EA"
    },
    {
        "id": 83,
        "name": "Ghana",
        "iso3": "GHA",
        "iso2": "GH",
        "phone_code": "233",
        "capital": "Accra",
        "currency": "GHS",
        "native": "Ghana",
        "region": "Africa",
        "subregion": "Western Africa",
        "timezones": [
            {
                "zoneName": "Africa\/Accra",
                "gmtOffset": 0,
                "gmtOffsetName": "UTC±00",
                "abbreviation": "GMT",
                "tzName": "Greenwich Mean Time"
            }
        ],
        "emoji": "🇬🇭",
        "emojiU": "U+1F1EC U+1F1ED"
    },
    {
        "id": 84,
        "name": "Gibraltar",
        "iso3": "GIB",
        "iso2": "GI",
        "phone_code": "350",
        "capital": "Gibraltar",
        "currency": "GIP",
        "native": "Gibraltar",
        "region": "Europe",
        "subregion": "Southern Europe",
        "timezones": [
            {
                "zoneName": "Europe\/Gibraltar",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "emoji": "🇬🇮",
        "emojiU": "U+1F1EC U+1F1EE"
    },
    {
        "id": 85,
        "name": "Greece",
        "iso3": "GRC",
        "iso2": "GR",
        "phone_code": "30",
        "capital": "Athens",
        "currency": "EUR",
        "native": "Ελλάδα",
        "region": "Europe",
        "subregion": "Southern Europe",
        "timezones": [
            {
                "zoneName": "Europe\/Athens",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "EET",
                "tzName": "Eastern European Time"
            }
        ],
        "emoji": "🇬🇷",
        "emojiU": "U+1F1EC U+1F1F7"
    },
    {
        "id": 86,
        "name": "Greenland",
        "iso3": "GRL",
        "iso2": "GL",
        "phone_code": "299",
        "capital": "Nuuk",
        "currency": "DKK",
        "native": "Kalaallit Nunaat",
        "region": "Americas",
        "subregion": "Northern America",
        "timezones": [
            {
                "zoneName": "America\/Danmarkshavn",
                "gmtOffset": 0,
                "gmtOffsetName": "UTC±00",
                "abbreviation": "GMT",
                "tzName": "Greenwich Mean Time"
            },
            {
                "zoneName": "America\/Nuuk",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "WGT",
                "tzName": "West Greenland Time"
            },
            {
                "zoneName": "America\/Scoresbysund",
                "gmtOffset": -3600,
                "gmtOffsetName": "UTC-01:00",
                "abbreviation": "EGT",
                "tzName": "Eastern Greenland Time"
            },
            {
                "zoneName": "America\/Thule",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "AST",
                "tzName": "Atlantic Standard Time"
            }
        ],
        "emoji": "🇬🇱",
        "emojiU": "U+1F1EC U+1F1F1"
    },
    {
        "id": 87,
        "name": "Grenada",
        "iso3": "GRD",
        "iso2": "GD",
        "phone_code": "1-473",
        "capital": "St. George's",
        "currency": "XCD",
        "native": "Grenada",
        "region": "Americas",
        "subregion": "Caribbean",
        "timezones": [
            {
                "zoneName": "America\/Grenada",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "AST",
                "tzName": "Atlantic Standard Time"
            }
        ],
        "emoji": "🇬🇩",
        "emojiU": "U+1F1EC U+1F1E9"
    },
    {
        "id": 88,
        "name": "Guadeloupe",
        "iso3": "GLP",
        "iso2": "GP",
        "phone_code": "590",
        "capital": "Basse-Terre",
        "currency": "EUR",
        "native": "Guadeloupe",
        "region": "Americas",
        "subregion": "Caribbean",
        "timezones": [
            {
                "zoneName": "America\/Guadeloupe",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "AST",
                "tzName": "Atlantic Standard Time"
            }
        ],
        "emoji": "🇬🇵",
        "emojiU": "U+1F1EC U+1F1F5"
    },
    {
        "id": 89,
        "name": "Guam",
        "iso3": "GUM",
        "iso2": "GU",
        "phone_code": "1-671",
        "capital": "Hagatna",
        "currency": "USD",
        "native": "Guam",
        "region": "Oceania",
        "subregion": "Micronesia",
        "timezones": [
            {
                "zoneName": "Pacific\/Guam",
                "gmtOffset": 36000,
                "gmtOffsetName": "UTC+10:00",
                "abbreviation": "CHST",
                "tzName": "Chamorro Standard Time"
            }
        ],
        "emoji": "🇬🇺",
        "emojiU": "U+1F1EC U+1F1FA"
    },
    {
        "id": 90,
        "name": "Guatemala",
        "iso3": "GTM",
        "iso2": "GT",
        "phone_code": "502",
        "capital": "Guatemala City",
        "currency": "GTQ",
        "native": "Guatemala",
        "region": "Americas",
        "subregion": "Central America",
        "timezones": [
            {
                "zoneName": "America\/Guatemala",
                "gmtOffset": -21600,
                "gmtOffsetName": "UTC-06:00",
                "abbreviation": "CST",
                "tzName": "Central Standard Time (North America"
            }
        ],
        "emoji": "🇬🇹",
        "emojiU": "U+1F1EC U+1F1F9"
    },
    {
        "id": 92,
        "name": "Guinea",
        "iso3": "GIN",
        "iso2": "GN",
        "phone_code": "224",
        "capital": "Conakry",
        "currency": "GNF",
        "native": "Guinée",
        "region": "Africa",
        "subregion": "Western Africa",
        "timezones": [
            {
                "zoneName": "Africa\/Conakry",
                "gmtOffset": 0,
                "gmtOffsetName": "UTC±00",
                "abbreviation": "GMT",
                "tzName": "Greenwich Mean Time"
            }
        ],
        "emoji": "🇬🇳",
        "emojiU": "U+1F1EC U+1F1F3"
    },
    {
        "id": 93,
        "name": "Guinea-Bissau",
        "iso3": "GNB",
        "iso2": "GW",
        "phone_code": "245",
        "capital": "Bissau",
        "currency": "XOF",
        "native": "Guiné-Bissau",
        "region": "Africa",
        "subregion": "Western Africa",
        "timezones": [
            {
                "zoneName": "Africa\/Bissau",
                "gmtOffset": 0,
                "gmtOffsetName": "UTC±00",
                "abbreviation": "GMT",
                "tzName": "Greenwich Mean Time"
            }
        ],
        "emoji": "🇬🇼",
        "emojiU": "U+1F1EC U+1F1FC"
    },
    {
        "id": 94,
        "name": "Guyana",
        "iso3": "GUY",
        "iso2": "GY",
        "phone_code": "592",
        "capital": "Georgetown",
        "currency": "GYD",
        "native": "Guyana",
        "region": "Americas",
        "subregion": "South America",
        "timezones": [
            {
                "zoneName": "America\/Guyana",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "GYT",
                "tzName": "Guyana Time"
            }
        ],
        "emoji": "🇬🇾",
        "emojiU": "U+1F1EC U+1F1FE"
    },
    {
        "id": 95,
        "name": "Haiti",
        "iso3": "HTI",
        "iso2": "HT",
        "phone_code": "509",
        "capital": "Port-au-Prince",
        "currency": "HTG",
        "native": "Haïti",
        "region": "Americas",
        "subregion": "Caribbean",
        "timezones": [
            {
                "zoneName": "America\/Port-au-Prince",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "EST",
                "tzName": "Eastern Standard Time (North America"
            }
        ],
        "emoji": "🇭🇹",
        "emojiU": "U+1F1ED U+1F1F9"
    },
    {
        "id": 96,
        "name": "Heard Island and McDonald Islands",
        "iso3": "HMD",
        "iso2": "HM",
        "phone_code": "",
        "capital": "",
        "currency": "AUD",
        "native": "Heard Island and McDonald Islands",
        "region": "",
        "subregion": "",
        "timezones": [
            {
                "zoneName": "Indian\/Kerguelen",
                "gmtOffset": 18000,
                "gmtOffsetName": "UTC+05:00",
                "abbreviation": "TFT",
                "tzName": "French Southern and Antarctic Time"
            }
        ],
        "emoji": "🇭🇲",
        "emojiU": "U+1F1ED U+1F1F2"
    },
    {
        "id": 97,
        "name": "Honduras",
        "iso3": "HND",
        "iso2": "HN",
        "phone_code": "504",
        "capital": "Tegucigalpa",
        "currency": "HNL",
        "native": "Honduras",
        "region": "Americas",
        "subregion": "Central America",
        "timezones": [
            {
                "zoneName": "America\/Tegucigalpa",
                "gmtOffset": -21600,
                "gmtOffsetName": "UTC-06:00",
                "abbreviation": "CST",
                "tzName": "Central Standard Time (North America"
            }
        ],
        "emoji": "🇭🇳",
        "emojiU": "U+1F1ED U+1F1F3"
    },
    {
        "id": 98,
        "name": "Hong Kong S.A.R.",
        "iso3": "HKG",
        "iso2": "HK",
        "phone_code": "852",
        "capital": "Hong Kong",
        "currency": "HKD",
        "native": "香港",
        "region": "Asia",
        "subregion": "Eastern Asia",
        "timezones": [
            {
                "zoneName": "Asia\/Hong_Kong",
                "gmtOffset": 28800,
                "gmtOffsetName": "UTC+08:00",
                "abbreviation": "HKT",
                "tzName": "Hong Kong Time"
            }
        ],
        "emoji": "🇭🇰",
        "emojiU": "U+1F1ED U+1F1F0"
    },
    {
        "id": 99,
        "name": "Hungary",
        "iso3": "HUN",
        "iso2": "HU",
        "phone_code": "36",
        "capital": "Budapest",
        "currency": "HUF",
        "native": "Magyarország",
        "region": "Europe",
        "subregion": "Eastern Europe",
        "timezones": [
            {
                "zoneName": "Europe\/Budapest",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "emoji": "🇭🇺",
        "emojiU": "U+1F1ED U+1F1FA"
    },
    {
        "id": 100,
        "name": "Iceland",
        "iso3": "ISL",
        "iso2": "IS",
        "phone_code": "354",
        "capital": "Reykjavik",
        "currency": "ISK",
        "native": "Ísland",
        "region": "Europe",
        "subregion": "Northern Europe",
        "timezones": [
            {
                "zoneName": "Atlantic\/Reykjavik",
                "gmtOffset": 0,
                "gmtOffsetName": "UTC±00",
                "abbreviation": "GMT",
                "tzName": "Greenwich Mean Time"
            }
        ],
        "emoji": "🇮🇸",
        "emojiU": "U+1F1EE U+1F1F8"
    },
    {
        "id": 101,
        "name": "India",
        "iso3": "IND",
        "iso2": "IN",
        "phone_code": "91",
        "capital": "New Delhi",
        "currency": "INR",
        "native": "भारत",
        "region": "Asia",
        "subregion": "Southern Asia",
        "timezones": [
            {
                "zoneName": "Asia\/Kolkata",
                "gmtOffset": 19800,
                "gmtOffsetName": "UTC+05:30",
                "abbreviation": "IST",
                "tzName": "Indian Standard Time"
            }
        ],
        "emoji": "🇮🇳",
        "emojiU": "U+1F1EE U+1F1F3"
    },
    {
        "id": 102,
        "name": "Indonesia",
        "iso3": "IDN",
        "iso2": "ID",
        "phone_code": "62",
        "capital": "Jakarta",
        "currency": "IDR",
        "native": "Indonesia",
        "region": "Asia",
        "subregion": "South-Eastern Asia",
        "timezones": [
            {
                "zoneName": "Asia\/Jakarta",
                "gmtOffset": 25200,
                "gmtOffsetName": "UTC+07:00",
                "abbreviation": "WIB",
                "tzName": "Western Indonesian Time"
            },
            {
                "zoneName": "Asia\/Jayapura",
                "gmtOffset": 32400,
                "gmtOffsetName": "UTC+09:00",
                "abbreviation": "WIT",
                "tzName": "Eastern Indonesian Time"
            },
            {
                "zoneName": "Asia\/Makassar",
                "gmtOffset": 28800,
                "gmtOffsetName": "UTC+08:00",
                "abbreviation": "WITA",
                "tzName": "Central Indonesia Time"
            },
            {
                "zoneName": "Asia\/Pontianak",
                "gmtOffset": 25200,
                "gmtOffsetName": "UTC+07:00",
                "abbreviation": "WIB",
                "tzName": "Western Indonesian Time"
            }
        ],
        "emoji": "🇮🇩",
        "emojiU": "U+1F1EE U+1F1E9"
    },
    {
        "id": 103,
        "name": "Iran",
        "iso3": "IRN",
        "iso2": "IR",
        "phone_code": "98",
        "capital": "Tehran",
        "currency": "IRR",
        "native": "ایران",
        "region": "Asia",
        "subregion": "Southern Asia",
        "timezones": [
            {
                "zoneName": "Asia\/Tehran",
                "gmtOffset": 12600,
                "gmtOffsetName": "UTC+03:30",
                "abbreviation": "IRDT",
                "tzName": "Iran Daylight Time"
            }
        ],
        "emoji": "🇮🇷",
        "emojiU": "U+1F1EE U+1F1F7"
    },
    {
        "id": 104,
        "name": "Iraq",
        "iso3": "IRQ",
        "iso2": "IQ",
        "phone_code": "964",
        "capital": "Baghdad",
        "currency": "IQD",
        "native": "العراق",
        "region": "Asia",
        "subregion": "Western Asia",
        "timezones": [
            {
                "zoneName": "Asia\/Baghdad",
                "gmtOffset": 10800,
                "gmtOffsetName": "UTC+03:00",
                "abbreviation": "AST",
                "tzName": "Arabia Standard Time"
            }
        ],
        "emoji": "🇮🇶",
        "emojiU": "U+1F1EE U+1F1F6"
    },
    {
        "id": 105,
        "name": "Ireland",
        "iso3": "IRL",
        "iso2": "IE",
        "phone_code": "353",
        "capital": "Dublin",
        "currency": "EUR",
        "native": "Éire",
        "region": "Europe",
        "subregion": "Northern Europe",
        "timezones": [
            {
                "zoneName": "Europe\/Dublin",
                "gmtOffset": 0,
                "gmtOffsetName": "UTC±00",
                "abbreviation": "GMT",
                "tzName": "Greenwich Mean Time"
            }
        ],
        "emoji": "🇮🇪",
        "emojiU": "U+1F1EE U+1F1EA"
    },
    {
        "id": 106,
        "name": "Israel",
        "iso3": "ISR",
        "iso2": "IL",
        "phone_code": "972",
        "capital": "Jerusalem",
        "currency": "ILS",
        "native": "יִשְׂרָאֵל",
        "region": "Asia",
        "subregion": "Western Asia",
        "timezones": [
            {
                "zoneName": "Asia\/Jerusalem",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "IST",
                "tzName": "Israel Standard Time"
            }
        ],
        "emoji": "🇮🇱",
        "emojiU": "U+1F1EE U+1F1F1"
    },
    {
        "id": 107,
        "name": "Italy",
        "iso3": "ITA",
        "iso2": "IT",
        "phone_code": "39",
        "capital": "Rome",
        "currency": "EUR",
        "native": "Italia",
        "region": "Europe",
        "subregion": "Southern Europe",
        "timezones": [
            {
                "zoneName": "Europe\/Rome",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "emoji": "🇮🇹",
        "emojiU": "U+1F1EE U+1F1F9"
    },
    {
        "id": 108,
        "name": "Jamaica",
        "iso3": "JAM",
        "iso2": "JM",
        "phone_code": "1-876",
        "capital": "Kingston",
        "currency": "JMD",
        "native": "Jamaica",
        "region": "Americas",
        "subregion": "Caribbean",
        "timezones": [
            {
                "zoneName": "America\/Jamaica",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "EST",
                "tzName": "Eastern Standard Time (North America"
            }
        ],
        "emoji": "🇯🇲",
        "emojiU": "U+1F1EF U+1F1F2"
    },
    {
        "id": 109,
        "name": "Japan",
        "iso3": "JPN",
        "iso2": "JP",
        "phone_code": "81",
        "capital": "Tokyo",
        "currency": "JPY",
        "native": "日本",
        "region": "Asia",
        "subregion": "Eastern Asia",
        "timezones": [
            {
                "zoneName": "Asia\/Tokyo",
                "gmtOffset": 32400,
                "gmtOffsetName": "UTC+09:00",
                "abbreviation": "JST",
                "tzName": "Japan Standard Time"
            }
        ],
        "emoji": "🇯🇵",
        "emojiU": "U+1F1EF U+1F1F5"
    },
    {
        "id": 111,
        "name": "Jordan",
        "iso3": "JOR",
        "iso2": "JO",
        "phone_code": "962",
        "capital": "Amman",
        "currency": "JOD",
        "native": "الأردن",
        "region": "Asia",
        "subregion": "Western Asia",
        "timezones": [
            {
                "zoneName": "Asia\/Amman",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "EET",
                "tzName": "Eastern European Time"
            }
        ],
        "emoji": "🇯🇴",
        "emojiU": "U+1F1EF U+1F1F4"
    },
    {
        "id": 112,
        "name": "Kazakhstan",
        "iso3": "KAZ",
        "iso2": "KZ",
        "phone_code": "7",
        "capital": "Astana",
        "currency": "KZT",
        "native": "Қазақстан",
        "region": "Asia",
        "subregion": "Central Asia",
        "timezones": [
            {
                "zoneName": "Asia\/Almaty",
                "gmtOffset": 21600,
                "gmtOffsetName": "UTC+06:00",
                "abbreviation": "ALMT",
                "tzName": "Alma-Ata Time[1"
            },
            {
                "zoneName": "Asia\/Aqtau",
                "gmtOffset": 18000,
                "gmtOffsetName": "UTC+05:00",
                "abbreviation": "AQTT",
                "tzName": "Aqtobe Time"
            },
            {
                "zoneName": "Asia\/Aqtobe",
                "gmtOffset": 18000,
                "gmtOffsetName": "UTC+05:00",
                "abbreviation": "AQTT",
                "tzName": "Aqtobe Time"
            },
            {
                "zoneName": "Asia\/Atyrau",
                "gmtOffset": 18000,
                "gmtOffsetName": "UTC+05:00",
                "abbreviation": "MSD+1",
                "tzName": "Moscow Daylight Time+1"
            },
            {
                "zoneName": "Asia\/Oral",
                "gmtOffset": 18000,
                "gmtOffsetName": "UTC+05:00",
                "abbreviation": "ORAT",
                "tzName": "Oral Time"
            },
            {
                "zoneName": "Asia\/Qostanay",
                "gmtOffset": 21600,
                "gmtOffsetName": "UTC+06:00",
                "abbreviation": "QYZST",
                "tzName": "Qyzylorda Summer Time"
            },
            {
                "zoneName": "Asia\/Qyzylorda",
                "gmtOffset": 18000,
                "gmtOffsetName": "UTC+05:00",
                "abbreviation": "QYZT",
                "tzName": "Qyzylorda Summer Time"
            }
        ],
        "emoji": "🇰🇿",
        "emojiU": "U+1F1F0 U+1F1FF"
    },
    {
        "id": 113,
        "name": "Kenya",
        "iso3": "KEN",
        "iso2": "KE",
        "phone_code": "254",
        "capital": "Nairobi",
        "currency": "KES",
        "native": "Kenya",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "timezones": [
            {
                "zoneName": "Africa\/Nairobi",
                "gmtOffset": 10800,
                "gmtOffsetName": "UTC+03:00",
                "abbreviation": "EAT",
                "tzName": "East Africa Time"
            }
        ],
        "emoji": "🇰🇪",
        "emojiU": "U+1F1F0 U+1F1EA"
    },
    {
        "id": 114,
        "name": "Kiribati",
        "iso3": "KIR",
        "iso2": "KI",
        "phone_code": "686",
        "capital": "Tarawa",
        "currency": "AUD",
        "native": "Kiribati",
        "region": "Oceania",
        "subregion": "Micronesia",
        "timezones": [
            {
                "zoneName": "Pacific\/Enderbury",
                "gmtOffset": 46800,
                "gmtOffsetName": "UTC+13:00",
                "abbreviation": "PHOT",
                "tzName": "Phoenix Island Time"
            },
            {
                "zoneName": "Pacific\/Kiritimati",
                "gmtOffset": 50400,
                "gmtOffsetName": "UTC+14:00",
                "abbreviation": "LINT",
                "tzName": "Line Islands Time"
            },
            {
                "zoneName": "Pacific\/Tarawa",
                "gmtOffset": 43200,
                "gmtOffsetName": "UTC+12:00",
                "abbreviation": "GILT",
                "tzName": "Gilbert Island Time"
            }
        ],
        "emoji": "🇰🇮",
        "emojiU": "U+1F1F0 U+1F1EE"
    },
    {
        "id": 115,
        "name": "Korea North",
        "iso3": "PRK",
        "iso2": "KP",
        "phone_code": "850",
        "capital": "Pyongyang",
        "currency": "KPW",
        "native": "북한",
        "region": "Asia",
        "subregion": "Eastern Asia",
        "timezones": [
            {
                "zoneName": "Asia\/Pyongyang",
                "gmtOffset": 32400,
                "gmtOffsetName": "UTC+09:00",
                "abbreviation": "KST",
                "tzName": "Korea Standard Time"
            }
        ],
        "emoji": "🇰🇵",
        "emojiU": "U+1F1F0 U+1F1F5"
    },
    {
        "id": 116,
        "name": "Korea South",
        "iso3": "KOR",
        "iso2": "KR",
        "phone_code": "82",
        "capital": "Seoul",
        "currency": "KRW",
        "native": "대한민국",
        "region": "Asia",
        "subregion": "Eastern Asia",
        "timezones": [
            {
                "zoneName": "Asia\/Seoul",
                "gmtOffset": 32400,
                "gmtOffsetName": "UTC+09:00",
                "abbreviation": "KST",
                "tzName": "Korea Standard Time"
            }
        ],
        "emoji": "🇰🇷",
        "emojiU": "U+1F1F0 U+1F1F7"
    },
    {
        "id": 117,
        "name": "Kuwait",
        "iso3": "KWT",
        "iso2": "KW",
        "phone_code": "965",
        "capital": "Kuwait City",
        "currency": "KWD",
        "native": "الكويت",
        "region": "Asia",
        "subregion": "Western Asia",
        "timezones": [
            {
                "zoneName": "Asia\/Kuwait",
                "gmtOffset": 10800,
                "gmtOffsetName": "UTC+03:00",
                "abbreviation": "AST",
                "tzName": "Arabia Standard Time"
            }
        ],
        "emoji": "🇰🇼",
        "emojiU": "U+1F1F0 U+1F1FC"
    },
    {
        "id": 118,
        "name": "Kyrgyzstan",
        "iso3": "KGZ",
        "iso2": "KG",
        "phone_code": "996",
        "capital": "Bishkek",
        "currency": "KGS",
        "native": "Кыргызстан",
        "region": "Asia",
        "subregion": "Central Asia",
        "timezones": [
            {
                "zoneName": "Asia\/Bishkek",
                "gmtOffset": 21600,
                "gmtOffsetName": "UTC+06:00",
                "abbreviation": "KGT",
                "tzName": "Kyrgyzstan Time"
            }
        ],
        "emoji": "🇰🇬",
        "emojiU": "U+1F1F0 U+1F1EC"
    },
    {
        "id": 119,
        "name": "Laos",
        "iso3": "LAO",
        "iso2": "LA",
        "phone_code": "856",
        "capital": "Vientiane",
        "currency": "LAK",
        "native": "ສປປລາວ",
        "region": "Asia",
        "subregion": "South-Eastern Asia",
        "timezones": [
            {
                "zoneName": "Asia\/Vientiane",
                "gmtOffset": 25200,
                "gmtOffsetName": "UTC+07:00",
                "abbreviation": "ICT",
                "tzName": "Indochina Time"
            }
        ],
        "emoji": "🇱🇦",
        "emojiU": "U+1F1F1 U+1F1E6"
    },
    {
        "id": 120,
        "name": "Latvia",
        "iso3": "LVA",
        "iso2": "LV",
        "phone_code": "371",
        "capital": "Riga",
        "currency": "EUR",
        "native": "Latvija",
        "region": "Europe",
        "subregion": "Northern Europe",
        "timezones": [
            {
                "zoneName": "Europe\/Riga",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "EET",
                "tzName": "Eastern European Time"
            }
        ],
        "emoji": "🇱🇻",
        "emojiU": "U+1F1F1 U+1F1FB"
    },
    {
        "id": 121,
        "name": "Lebanon",
        "iso3": "LBN",
        "iso2": "LB",
        "phone_code": "961",
        "capital": "Beirut",
        "currency": "LBP",
        "native": "لبنان",
        "region": "Asia",
        "subregion": "Western Asia",
        "timezones": [
            {
                "zoneName": "Asia\/Beirut",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "EET",
                "tzName": "Eastern European Time"
            }
        ],
        "emoji": "🇱🇧",
        "emojiU": "U+1F1F1 U+1F1E7"
    },
    {
        "id": 122,
        "name": "Lesotho",
        "iso3": "LSO",
        "iso2": "LS",
        "phone_code": "266",
        "capital": "Maseru",
        "currency": "LSL",
        "native": "Lesotho",
        "region": "Africa",
        "subregion": "Southern Africa",
        "timezones": [
            {
                "zoneName": "Africa\/Maseru",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "SAST",
                "tzName": "South African Standard Time"
            }
        ],
        "emoji": "🇱🇸",
        "emojiU": "U+1F1F1 U+1F1F8"
    },
    {
        "id": 123,
        "name": "Liberia",
        "iso3": "LBR",
        "iso2": "LR",
        "phone_code": "231",
        "capital": "Monrovia",
        "currency": "LRD",
        "native": "Liberia",
        "region": "Africa",
        "subregion": "Western Africa",
        "timezones": [
            {
                "zoneName": "Africa\/Monrovia",
                "gmtOffset": 0,
                "gmtOffsetName": "UTC±00",
                "abbreviation": "GMT",
                "tzName": "Greenwich Mean Time"
            }
        ],
        "emoji": "🇱🇷",
        "emojiU": "U+1F1F1 U+1F1F7"
    },
    {
        "id": 124,
        "name": "Libya",
        "iso3": "LBY",
        "iso2": "LY",
        "phone_code": "218",
        "capital": "Tripolis",
        "currency": "LYD",
        "native": "‏ليبيا",
        "region": "Africa",
        "subregion": "Northern Africa",
        "timezones": [
            {
                "zoneName": "Africa\/Tripoli",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "EET",
                "tzName": "Eastern European Time"
            }
        ],
        "emoji": "🇱🇾",
        "emojiU": "U+1F1F1 U+1F1FE"
    },
    {
        "id": 125,
        "name": "Liechtenstein",
        "iso3": "LIE",
        "iso2": "LI",
        "phone_code": "423",
        "capital": "Vaduz",
        "currency": "CHF",
        "native": "Liechtenstein",
        "region": "Europe",
        "subregion": "Western Europe",
        "timezones": [
            {
                "zoneName": "Europe\/Vaduz",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "emoji": "🇱🇮",
        "emojiU": "U+1F1F1 U+1F1EE"
    },
    {
        "id": 126,
        "name": "Lithuania",
        "iso3": "LTU",
        "iso2": "LT",
        "phone_code": "370",
        "capital": "Vilnius",
        "currency": "EUR",
        "native": "Lietuva",
        "region": "Europe",
        "subregion": "Northern Europe",
        "timezones": [
            {
                "zoneName": "Europe\/Vilnius",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "EET",
                "tzName": "Eastern European Time"
            }
        ],
        "emoji": "🇱🇹",
        "emojiU": "U+1F1F1 U+1F1F9"
    },
    {
        "id": 127,
        "name": "Luxembourg",
        "iso3": "LUX",
        "iso2": "LU",
        "phone_code": "352",
        "capital": "Luxembourg",
        "currency": "EUR",
        "native": "Luxembourg",
        "region": "Europe",
        "subregion": "Western Europe",
        "timezones": [
            {
                "zoneName": "Europe\/Luxembourg",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "emoji": "🇱🇺",
        "emojiU": "U+1F1F1 U+1F1FA"
    },
    {
        "id": 128,
        "name": "Macau S.A.R.",
        "iso3": "MAC",
        "iso2": "MO",
        "phone_code": "853",
        "capital": "Macao",
        "currency": "MOP",
        "native": "澳門",
        "region": "Asia",
        "subregion": "Eastern Asia",
        "timezones": [
            {
                "zoneName": "Asia\/Macau",
                "gmtOffset": 28800,
                "gmtOffsetName": "UTC+08:00",
                "abbreviation": "CST",
                "tzName": "China Standard Time"
            }
        ],
        "emoji": "🇲🇴",
        "emojiU": "U+1F1F2 U+1F1F4"
    },
    {
        "id": 129,
        "name": "Macedonia",
        "iso3": "MKD",
        "iso2": "MK",
        "phone_code": "389",
        "capital": "Skopje",
        "currency": "MKD",
        "native": "Северна Македонија",
        "region": "Europe",
        "subregion": "Southern Europe",
        "timezones": [
            {
                "zoneName": "Europe\/Skopje",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "emoji": "🇲🇰",
        "emojiU": "U+1F1F2 U+1F1F0"
    },
    {
        "id": 130,
        "name": "Madagascar",
        "iso3": "MDG",
        "iso2": "MG",
        "phone_code": "261",
        "capital": "Antananarivo",
        "currency": "MGA",
        "native": "Madagasikara",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "timezones": [
            {
                "zoneName": "Indian\/Antananarivo",
                "gmtOffset": 10800,
                "gmtOffsetName": "UTC+03:00",
                "abbreviation": "EAT",
                "tzName": "East Africa Time"
            }
        ],
        "emoji": "🇲🇬",
        "emojiU": "U+1F1F2 U+1F1EC"
    },
    {
        "id": 131,
        "name": "Malawi",
        "iso3": "MWI",
        "iso2": "MW",
        "phone_code": "265",
        "capital": "Lilongwe",
        "currency": "MWK",
        "native": "Malawi",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "timezones": [
            {
                "zoneName": "Africa\/Blantyre",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "CAT",
                "tzName": "Central Africa Time"
            }
        ],
        "emoji": "🇲🇼",
        "emojiU": "U+1F1F2 U+1F1FC"
    },
    {
        "id": 132,
        "name": "Malaysia",
        "iso3": "MYS",
        "iso2": "MY",
        "phone_code": "60",
        "capital": "Kuala Lumpur",
        "currency": "MYR",
        "native": "Malaysia",
        "region": "Asia",
        "subregion": "South-Eastern Asia",
        "timezones": [
            {
                "zoneName": "Asia\/Kuala_Lumpur",
                "gmtOffset": 28800,
                "gmtOffsetName": "UTC+08:00",
                "abbreviation": "MYT",
                "tzName": "Malaysia Time"
            },
            {
                "zoneName": "Asia\/Kuching",
                "gmtOffset": 28800,
                "gmtOffsetName": "UTC+08:00",
                "abbreviation": "MYT",
                "tzName": "Malaysia Time"
            }
        ],
        "emoji": "🇲🇾",
        "emojiU": "U+1F1F2 U+1F1FE"
    },
    {
        "id": 133,
        "name": "Maldives",
        "iso3": "MDV",
        "iso2": "MV",
        "phone_code": "960",
        "capital": "Male",
        "currency": "MVR",
        "native": "Maldives",
        "region": "Asia",
        "subregion": "Southern Asia",
        "timezones": [
            {
                "zoneName": "Indian\/Maldives",
                "gmtOffset": 18000,
                "gmtOffsetName": "UTC+05:00",
                "abbreviation": "MVT",
                "tzName": "Maldives Time"
            }
        ],
        "emoji": "🇲🇻",
        "emojiU": "U+1F1F2 U+1F1FB"
    },
    {
        "id": 134,
        "name": "Mali",
        "iso3": "MLI",
        "iso2": "ML",
        "phone_code": "223",
        "capital": "Bamako",
        "currency": "XOF",
        "native": "Mali",
        "region": "Africa",
        "subregion": "Western Africa",
        "timezones": [
            {
                "zoneName": "Africa\/Bamako",
                "gmtOffset": 0,
                "gmtOffsetName": "UTC±00",
                "abbreviation": "GMT",
                "tzName": "Greenwich Mean Time"
            }
        ],
        "emoji": "🇲🇱",
        "emojiU": "U+1F1F2 U+1F1F1"
    },
    {
        "id": 135,
        "name": "Malta",
        "iso3": "MLT",
        "iso2": "MT",
        "phone_code": "356",
        "capital": "Valletta",
        "currency": "EUR",
        "native": "Malta",
        "region": "Europe",
        "subregion": "Southern Europe",
        "timezones": [
            {
                "zoneName": "Europe\/Malta",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "emoji": "🇲🇹",
        "emojiU": "U+1F1F2 U+1F1F9"
    },
    {
        "id": 137,
        "name": "Marshall Islands",
        "iso3": "MHL",
        "iso2": "MH",
        "phone_code": "692",
        "capital": "Majuro",
        "currency": "USD",
        "native": "M̧ajeļ",
        "region": "Oceania",
        "subregion": "Micronesia",
        "timezones": [
            {
                "zoneName": "Pacific\/Kwajalein",
                "gmtOffset": 43200,
                "gmtOffsetName": "UTC+12:00",
                "abbreviation": "MHT",
                "tzName": "Marshall Islands Time"
            },
            {
                "zoneName": "Pacific\/Majuro",
                "gmtOffset": 43200,
                "gmtOffsetName": "UTC+12:00",
                "abbreviation": "MHT",
                "tzName": "Marshall Islands Time"
            }
        ],
        "emoji": "🇲🇭",
        "emojiU": "U+1F1F2 U+1F1ED"
    },
    {
        "id": 138,
        "name": "Martinique",
        "iso3": "MTQ",
        "iso2": "MQ",
        "phone_code": "596",
        "capital": "Fort-de-France",
        "currency": "EUR",
        "native": "Martinique",
        "region": "Americas",
        "subregion": "Caribbean",
        "timezones": [
            {
                "zoneName": "America\/Martinique",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "AST",
                "tzName": "Atlantic Standard Time"
            }
        ],
        "emoji": "🇲🇶",
        "emojiU": "U+1F1F2 U+1F1F6"
    },
    {
        "id": 139,
        "name": "Mauritania",
        "iso3": "MRT",
        "iso2": "MR",
        "phone_code": "222",
        "capital": "Nouakchott",
        "currency": "MRO",
        "native": "موريتانيا",
        "region": "Africa",
        "subregion": "Western Africa",
        "timezones": [
            {
                "zoneName": "Africa\/Nouakchott",
                "gmtOffset": 0,
                "gmtOffsetName": "UTC±00",
                "abbreviation": "GMT",
                "tzName": "Greenwich Mean Time"
            }
        ],
        "emoji": "🇲🇷",
        "emojiU": "U+1F1F2 U+1F1F7"
    },
    {
        "id": 140,
        "name": "Mauritius",
        "iso3": "MUS",
        "iso2": "MU",
        "phone_code": "230",
        "capital": "Port Louis",
        "currency": "MUR",
        "native": "Maurice",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "timezones": [
            {
                "zoneName": "Indian\/Mauritius",
                "gmtOffset": 14400,
                "gmtOffsetName": "UTC+04:00",
                "abbreviation": "MUT",
                "tzName": "Mauritius Time"
            }
        ],
        "emoji": "🇲🇺",
        "emojiU": "U+1F1F2 U+1F1FA"
    },
    {
        "id": 141,
        "name": "Mayotte",
        "iso3": "MYT",
        "iso2": "YT",
        "phone_code": "262",
        "capital": "Mamoudzou",
        "currency": "EUR",
        "native": "Mayotte",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "timezones": [
            {
                "zoneName": "Indian\/Mayotte",
                "gmtOffset": 10800,
                "gmtOffsetName": "UTC+03:00",
                "abbreviation": "EAT",
                "tzName": "East Africa Time"
            }
        ],
        "emoji": "🇾🇹",
        "emojiU": "U+1F1FE U+1F1F9"
    },
    {
        "id": 142,
        "name": "Mexico",
        "iso3": "MEX",
        "iso2": "MX",
        "phone_code": "52",
        "capital": "Mexico City",
        "currency": "MXN",
        "native": "México",
        "region": "Americas",
        "subregion": "Central America",
        "timezones": [
            {
                "zoneName": "America\/Bahia_Banderas",
                "gmtOffset": -21600,
                "gmtOffsetName": "UTC-06:00",
                "abbreviation": "CST",
                "tzName": "Central Standard Time (North America"
            },
            {
                "zoneName": "America\/Cancun",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "EST",
                "tzName": "Eastern Standard Time (North America"
            },
            {
                "zoneName": "America\/Chihuahua",
                "gmtOffset": -25200,
                "gmtOffsetName": "UTC-07:00",
                "abbreviation": "MST",
                "tzName": "Mountain Standard Time (North America"
            },
            {
                "zoneName": "America\/Hermosillo",
                "gmtOffset": -25200,
                "gmtOffsetName": "UTC-07:00",
                "abbreviation": "MST",
                "tzName": "Mountain Standard Time (North America"
            },
            {
                "zoneName": "America\/Matamoros",
                "gmtOffset": -21600,
                "gmtOffsetName": "UTC-06:00",
                "abbreviation": "CST",
                "tzName": "Central Standard Time (North America"
            },
            {
                "zoneName": "America\/Mazatlan",
                "gmtOffset": -25200,
                "gmtOffsetName": "UTC-07:00",
                "abbreviation": "MST",
                "tzName": "Mountain Standard Time (North America"
            },
            {
                "zoneName": "America\/Merida",
                "gmtOffset": -21600,
                "gmtOffsetName": "UTC-06:00",
                "abbreviation": "CST",
                "tzName": "Central Standard Time (North America"
            },
            {
                "zoneName": "America\/Mexico_City",
                "gmtOffset": -21600,
                "gmtOffsetName": "UTC-06:00",
                "abbreviation": "CST",
                "tzName": "Central Standard Time (North America"
            },
            {
                "zoneName": "America\/Monterrey",
                "gmtOffset": -21600,
                "gmtOffsetName": "UTC-06:00",
                "abbreviation": "CST",
                "tzName": "Central Standard Time (North America"
            },
            {
                "zoneName": "America\/Ojinaga",
                "gmtOffset": -25200,
                "gmtOffsetName": "UTC-07:00",
                "abbreviation": "MST",
                "tzName": "Mountain Standard Time (North America"
            },
            {
                "zoneName": "America\/Tijuana",
                "gmtOffset": -28800,
                "gmtOffsetName": "UTC-08:00",
                "abbreviation": "PST",
                "tzName": "Pacific Standard Time (North America"
            }
        ],
        "emoji": "🇲🇽",
        "emojiU": "U+1F1F2 U+1F1FD"
    },
    {
        "id": 143,
        "name": "Micronesia",
        "iso3": "FSM",
        "iso2": "FM",
        "phone_code": "691",
        "capital": "Palikir",
        "currency": "USD",
        "native": "Micronesia",
        "region": "Oceania",
        "subregion": "Micronesia",
        "timezones": [
            {
                "zoneName": "Pacific\/Chuuk",
                "gmtOffset": 36000,
                "gmtOffsetName": "UTC+10:00",
                "abbreviation": "CHUT",
                "tzName": "Chuuk Time"
            },
            {
                "zoneName": "Pacific\/Kosrae",
                "gmtOffset": 39600,
                "gmtOffsetName": "UTC+11:00",
                "abbreviation": "KOST",
                "tzName": "Kosrae Time"
            },
            {
                "zoneName": "Pacific\/Pohnpei",
                "gmtOffset": 39600,
                "gmtOffsetName": "UTC+11:00",
                "abbreviation": "PONT",
                "tzName": "Pohnpei Standard Time"
            }
        ],
        "emoji": "🇫🇲",
        "emojiU": "U+1F1EB U+1F1F2"
    },
    {
        "id": 144,
        "name": "Moldova",
        "iso3": "MDA",
        "iso2": "MD",
        "phone_code": "373",
        "capital": "Chisinau",
        "currency": "MDL",
        "native": "Moldova",
        "region": "Europe",
        "subregion": "Eastern Europe",
        "timezones": [
            {
                "zoneName": "Europe\/Chisinau",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "EET",
                "tzName": "Eastern European Time"
            }
        ],
        "emoji": "🇲🇩",
        "emojiU": "U+1F1F2 U+1F1E9"
    },
    {
        "id": 145,
        "name": "Monaco",
        "iso3": "MCO",
        "iso2": "MC",
        "phone_code": "377",
        "capital": "Monaco",
        "currency": "EUR",
        "native": "Monaco",
        "region": "Europe",
        "subregion": "Western Europe",
        "timezones": [
            {
                "zoneName": "Europe\/Monaco",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "emoji": "🇲🇨",
        "emojiU": "U+1F1F2 U+1F1E8"
    },
    {
        "id": 146,
        "name": "Mongolia",
        "iso3": "MNG",
        "iso2": "MN",
        "phone_code": "976",
        "capital": "Ulan Bator",
        "currency": "MNT",
        "native": "Монгол улс",
        "region": "Asia",
        "subregion": "Eastern Asia",
        "timezones": [
            {
                "zoneName": "Asia\/Choibalsan",
                "gmtOffset": 28800,
                "gmtOffsetName": "UTC+08:00",
                "abbreviation": "CHOT",
                "tzName": "Choibalsan Standard Time"
            },
            {
                "zoneName": "Asia\/Hovd",
                "gmtOffset": 25200,
                "gmtOffsetName": "UTC+07:00",
                "abbreviation": "HOVT",
                "tzName": "Hovd Time"
            },
            {
                "zoneName": "Asia\/Ulaanbaatar",
                "gmtOffset": 28800,
                "gmtOffsetName": "UTC+08:00",
                "abbreviation": "ULAT",
                "tzName": "Ulaanbaatar Standard Time"
            }
        ],
        "emoji": "🇲🇳",
        "emojiU": "U+1F1F2 U+1F1F3"
    },
    {
        "id": 147,
        "name": "Montenegro",
        "iso3": "MNE",
        "iso2": "ME",
        "phone_code": "382",
        "capital": "Podgorica",
        "currency": "EUR",
        "native": "Црна Гора",
        "region": "Europe",
        "subregion": "Southern Europe",
        "timezones": [
            {
                "zoneName": "Europe\/Podgorica",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "emoji": "🇲🇪",
        "emojiU": "U+1F1F2 U+1F1EA"
    },
    {
        "id": 148,
        "name": "Montserrat",
        "iso3": "MSR",
        "iso2": "MS",
        "phone_code": "1-664",
        "capital": "Plymouth",
        "currency": "XCD",
        "native": "Montserrat",
        "region": "Americas",
        "subregion": "Caribbean",
        "timezones": [
            {
                "zoneName": "America\/Montserrat",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "AST",
                "tzName": "Atlantic Standard Time"
            }
        ],
        "emoji": "🇲🇸",
        "emojiU": "U+1F1F2 U+1F1F8"
    },
    {
        "id": 149,
        "name": "Morocco",
        "iso3": "MAR",
        "iso2": "MA",
        "phone_code": "212",
        "capital": "Rabat",
        "currency": "MAD",
        "native": "المغرب",
        "region": "Africa",
        "subregion": "Northern Africa",
        "timezones": [
            {
                "zoneName": "Africa\/Casablanca",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "WEST",
                "tzName": "Western European Summer Time"
            }
        ],
        "emoji": "🇲🇦",
        "emojiU": "U+1F1F2 U+1F1E6"
    },
    {
        "id": 150,
        "name": "Mozambique",
        "iso3": "MOZ",
        "iso2": "MZ",
        "phone_code": "258",
        "capital": "Maputo",
        "currency": "MZN",
        "native": "Moçambique",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "timezones": [
            {
                "zoneName": "Africa\/Maputo",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "CAT",
                "tzName": "Central Africa Time"
            }
        ],
        "emoji": "🇲🇿",
        "emojiU": "U+1F1F2 U+1F1FF"
    },
    {
        "id": 151,
        "name": "Myanmar",
        "iso3": "MMR",
        "iso2": "MM",
        "phone_code": "95",
        "capital": "Nay Pyi Taw",
        "currency": "MMK",
        "native": "မြန်မာ",
        "region": "Asia",
        "subregion": "South-Eastern Asia",
        "timezones": [
            {
                "zoneName": "Asia\/Yangon",
                "gmtOffset": 23400,
                "gmtOffsetName": "UTC+06:30",
                "abbreviation": "MMT",
                "tzName": "Myanmar Standard Time"
            }
        ],
        "emoji": "🇲🇲",
        "emojiU": "U+1F1F2 U+1F1F2"
    },
    {
        "id": 152,
        "name": "Namibia",
        "iso3": "NAM",
        "iso2": "NA",
        "phone_code": "264",
        "capital": "Windhoek",
        "currency": "NAD",
        "native": "Namibia",
        "region": "Africa",
        "subregion": "Southern Africa",
        "timezones": [
            {
                "zoneName": "Africa\/Windhoek",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "WAST",
                "tzName": "West Africa Summer Time"
            }
        ],
        "emoji": "🇳🇦",
        "emojiU": "U+1F1F3 U+1F1E6"
    },
    {
        "id": 153,
        "name": "Nauru",
        "iso3": "NRU",
        "iso2": "NR",
        "phone_code": "674",
        "capital": "Yaren",
        "currency": "AUD",
        "native": "Nauru",
        "region": "Oceania",
        "subregion": "Micronesia",
        "timezones": [
            {
                "zoneName": "Pacific\/Nauru",
                "gmtOffset": 43200,
                "gmtOffsetName": "UTC+12:00",
                "abbreviation": "NRT",
                "tzName": "Nauru Time"
            }
        ],
        "emoji": "🇳🇷",
        "emojiU": "U+1F1F3 U+1F1F7"
    },
    {
        "id": 154,
        "name": "Nepal",
        "iso3": "NPL",
        "iso2": "NP",
        "phone_code": "977",
        "capital": "Kathmandu",
        "currency": "NPR",
        "native": "नपल",
        "region": "Asia",
        "subregion": "Southern Asia",
        "timezones": [
            {
                "zoneName": "Asia\/Kathmandu",
                "gmtOffset": 20700,
                "gmtOffsetName": "UTC+05:45",
                "abbreviation": "NPT",
                "tzName": "Nepal Time"
            }
        ],
        "emoji": "🇳🇵",
        "emojiU": "U+1F1F3 U+1F1F5"
    },
    {
        "id": 156,
        "name": "Netherlands The",
        "iso3": "NLD",
        "iso2": "NL",
        "phone_code": "31",
        "capital": "Amsterdam",
        "currency": "EUR",
        "native": "Nederland",
        "region": "Europe",
        "subregion": "Western Europe",
        "timezones": [
            {
                "zoneName": "Europe\/Amsterdam",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "emoji": "🇳🇱",
        "emojiU": "U+1F1F3 U+1F1F1"
    },
    {
        "id": 157,
        "name": "New Caledonia",
        "iso3": "NCL",
        "iso2": "NC",
        "phone_code": "687",
        "capital": "Noumea",
        "currency": "XPF",
        "native": "Nouvelle-Calédonie",
        "region": "Oceania",
        "subregion": "Melanesia",
        "timezones": [
            {
                "zoneName": "Pacific\/Noumea",
                "gmtOffset": 39600,
                "gmtOffsetName": "UTC+11:00",
                "abbreviation": "NCT",
                "tzName": "New Caledonia Time"
            }
        ],
        "emoji": "🇳🇨",
        "emojiU": "U+1F1F3 U+1F1E8"
    },
    {
        "id": 158,
        "name": "New Zealand",
        "iso3": "NZL",
        "iso2": "NZ",
        "phone_code": "64",
        "capital": "Wellington",
        "currency": "NZD",
        "native": "New Zealand",
        "region": "Oceania",
        "subregion": "Australia and New Zealand",
        "timezones": [
            {
                "zoneName": "Pacific\/Auckland",
                "gmtOffset": 46800,
                "gmtOffsetName": "UTC+13:00",
                "abbreviation": "NZDT",
                "tzName": "New Zealand Daylight Time"
            },
            {
                "zoneName": "Pacific\/Chatham",
                "gmtOffset": 49500,
                "gmtOffsetName": "UTC+13:45",
                "abbreviation": "CHAST",
                "tzName": "Chatham Standard Time"
            }
        ],
        "emoji": "🇳🇿",
        "emojiU": "U+1F1F3 U+1F1FF"
    },
    {
        "id": 159,
        "name": "Nicaragua",
        "iso3": "NIC",
        "iso2": "NI",
        "phone_code": "505",
        "capital": "Managua",
        "currency": "NIO",
        "native": "Nicaragua",
        "region": "Americas",
        "subregion": "Central America",
        "timezones": [
            {
                "zoneName": "America\/Managua",
                "gmtOffset": -21600,
                "gmtOffsetName": "UTC-06:00",
                "abbreviation": "CST",
                "tzName": "Central Standard Time (North America"
            }
        ],
        "emoji": "🇳🇮",
        "emojiU": "U+1F1F3 U+1F1EE"
    },
    {
        "id": 160,
        "name": "Niger",
        "iso3": "NER",
        "iso2": "NE",
        "phone_code": "227",
        "capital": "Niamey",
        "currency": "XOF",
        "native": "Niger",
        "region": "Africa",
        "subregion": "Western Africa",
        "timezones": [
            {
                "zoneName": "Africa\/Niamey",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "WAT",
                "tzName": "West Africa Time"
            }
        ],
        "emoji": "🇳🇪",
        "emojiU": "U+1F1F3 U+1F1EA"
    },
    {
        "id": 161,
        "name": "Nigeria",
        "iso3": "NGA",
        "iso2": "NG",
        "phone_code": "234",
        "capital": "Abuja",
        "currency": "NGN",
        "native": "Nigeria",
        "region": "Africa",
        "subregion": "Western Africa",
        "timezones": [
            {
                "zoneName": "Africa\/Lagos",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "WAT",
                "tzName": "West Africa Time"
            }
        ],
        "emoji": "🇳🇬",
        "emojiU": "U+1F1F3 U+1F1EC"
    },
    {
        "id": 162,
        "name": "Niue",
        "iso3": "NIU",
        "iso2": "NU",
        "phone_code": "683",
        "capital": "Alofi",
        "currency": "NZD",
        "native": "Niuē",
        "region": "Oceania",
        "subregion": "Polynesia",
        "timezones": [
            {
                "zoneName": "Pacific\/Niue",
                "gmtOffset": -39600,
                "gmtOffsetName": "UTC-11:00",
                "abbreviation": "NUT",
                "tzName": "Niue Time"
            }
        ],
        "emoji": "🇳🇺",
        "emojiU": "U+1F1F3 U+1F1FA"
    },
    {
        "id": 163,
        "name": "Norfolk Island",
        "iso3": "NFK",
        "iso2": "NF",
        "phone_code": "672",
        "capital": "Kingston",
        "currency": "AUD",
        "native": "Norfolk Island",
        "region": "Oceania",
        "subregion": "Australia and New Zealand",
        "timezones": [
            {
                "zoneName": "Pacific\/Norfolk",
                "gmtOffset": 43200,
                "gmtOffsetName": "UTC+12:00",
                "abbreviation": "NFT",
                "tzName": "Norfolk Time"
            }
        ],
        "emoji": "🇳🇫",
        "emojiU": "U+1F1F3 U+1F1EB"
    },
    {
        "id": 164,
        "name": "Northern Mariana Islands",
        "iso3": "MNP",
        "iso2": "MP",
        "phone_code": "1-670",
        "capital": "Saipan",
        "currency": "USD",
        "native": "Northern Mariana Islands",
        "region": "Oceania",
        "subregion": "Micronesia",
        "timezones": [
            {
                "zoneName": "Pacific\/Saipan",
                "gmtOffset": 36000,
                "gmtOffsetName": "UTC+10:00",
                "abbreviation": "ChST",
                "tzName": "Chamorro Standard Time"
            }
        ],
        "emoji": "🇲🇵",
        "emojiU": "U+1F1F2 U+1F1F5"
    },
    {
        "id": 165,
        "name": "Norway",
        "iso3": "NOR",
        "iso2": "NO",
        "phone_code": "47",
        "capital": "Oslo",
        "currency": "NOK",
        "native": "Norge",
        "region": "Europe",
        "subregion": "Northern Europe",
        "timezones": [
            {
                "zoneName": "Europe\/Oslo",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "emoji": "🇳🇴",
        "emojiU": "U+1F1F3 U+1F1F4"
    },
    {
        "id": 166,
        "name": "Oman",
        "iso3": "OMN",
        "iso2": "OM",
        "phone_code": "968",
        "capital": "Muscat",
        "currency": "OMR",
        "native": "عمان",
        "region": "Asia",
        "subregion": "Western Asia",
        "timezones": [
            {
                "zoneName": "Asia\/Muscat",
                "gmtOffset": 14400,
                "gmtOffsetName": "UTC+04:00",
                "abbreviation": "GST",
                "tzName": "Gulf Standard Time"
            }
        ],
        "emoji": "🇴🇲",
        "emojiU": "U+1F1F4 U+1F1F2"
    },
    {
        "id": 167,
        "name": "Pakistan",
        "iso3": "PAK",
        "iso2": "PK",
        "phone_code": "92",
        "capital": "Islamabad",
        "currency": "PKR",
        "native": "Pakistan",
        "region": "Asia",
        "subregion": "Southern Asia",
        "timezones": [
            {
                "zoneName": "Asia\/Karachi",
                "gmtOffset": 18000,
                "gmtOffsetName": "UTC+05:00",
                "abbreviation": "PKT",
                "tzName": "Pakistan Standard Time"
            }
        ],
        "emoji": "🇵🇰",
        "emojiU": "U+1F1F5 U+1F1F0"
    },
    {
        "id": 168,
        "name": "Palau",
        "iso3": "PLW",
        "iso2": "PW",
        "phone_code": "680",
        "capital": "Melekeok",
        "currency": "USD",
        "native": "Palau",
        "region": "Oceania",
        "subregion": "Micronesia",
        "timezones": [
            {
                "zoneName": "Pacific\/Palau",
                "gmtOffset": 32400,
                "gmtOffsetName": "UTC+09:00",
                "abbreviation": "PWT",
                "tzName": "Palau Time"
            }
        ],
        "emoji": "🇵🇼",
        "emojiU": "U+1F1F5 U+1F1FC"
    },
    {
        "id": 170,
        "name": "Panama",
        "iso3": "PAN",
        "iso2": "PA",
        "phone_code": "507",
        "capital": "Panama City",
        "currency": "PAB",
        "native": "Panamá",
        "region": "Americas",
        "subregion": "Central America",
        "timezones": [
            {
                "zoneName": "America\/Panama",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "EST",
                "tzName": "Eastern Standard Time (North America"
            }
        ],
        "emoji": "🇵🇦",
        "emojiU": "U+1F1F5 U+1F1E6"
    },
    {
        "id": 171,
        "name": "Papua new Guinea",
        "iso3": "PNG",
        "iso2": "PG",
        "phone_code": "675",
        "capital": "Port Moresby",
        "currency": "PGK",
        "native": "Papua Niugini",
        "region": "Oceania",
        "subregion": "Melanesia",
        "timezones": [
            {
                "zoneName": "Pacific\/Bougainville",
                "gmtOffset": 39600,
                "gmtOffsetName": "UTC+11:00",
                "abbreviation": "BST",
                "tzName": "Bougainville Standard Time[6"
            },
            {
                "zoneName": "Pacific\/Port_Moresby",
                "gmtOffset": 36000,
                "gmtOffsetName": "UTC+10:00",
                "abbreviation": "PGT",
                "tzName": "Papua New Guinea Time"
            }
        ],
        "emoji": "🇵🇬",
        "emojiU": "U+1F1F5 U+1F1EC"
    },
    {
        "id": 172,
        "name": "Paraguay",
        "iso3": "PRY",
        "iso2": "PY",
        "phone_code": "595",
        "capital": "Asuncion",
        "currency": "PYG",
        "native": "Paraguay",
        "region": "Americas",
        "subregion": "South America",
        "timezones": [
            {
                "zoneName": "America\/Asuncion",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "PYST",
                "tzName": "Paraguay Summer Time"
            }
        ],
        "emoji": "🇵🇾",
        "emojiU": "U+1F1F5 U+1F1FE"
    },
    {
        "id": 173,
        "name": "Peru",
        "iso3": "PER",
        "iso2": "PE",
        "phone_code": "51",
        "capital": "Lima",
        "currency": "PEN",
        "native": "Perú",
        "region": "Americas",
        "subregion": "South America",
        "timezones": [
            {
                "zoneName": "America\/Lima",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "PET",
                "tzName": "Peru Time"
            }
        ],
        "emoji": "🇵🇪",
        "emojiU": "U+1F1F5 U+1F1EA"
    },
    {
        "id": 174,
        "name": "Philippines",
        "iso3": "PHL",
        "iso2": "PH",
        "phone_code": "63",
        "capital": "Manila",
        "currency": "PHP",
        "native": "Pilipinas",
        "region": "Asia",
        "subregion": "South-Eastern Asia",
        "timezones": [
            {
                "zoneName": "Asia\/Manila",
                "gmtOffset": 28800,
                "gmtOffsetName": "UTC+08:00",
                "abbreviation": "PHT",
                "tzName": "Philippine Time"
            }
        ],
        "emoji": "🇵🇭",
        "emojiU": "U+1F1F5 U+1F1ED"
    },
    {
        "id": 176,
        "name": "Poland",
        "iso3": "POL",
        "iso2": "PL",
        "phone_code": "48",
        "capital": "Warsaw",
        "currency": "PLN",
        "native": "Polska",
        "region": "Europe",
        "subregion": "Eastern Europe",
        "timezones": [
            {
                "zoneName": "Europe\/Warsaw",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "emoji": "🇵🇱",
        "emojiU": "U+1F1F5 U+1F1F1"
    },
    {
        "id": 177,
        "name": "Portugal",
        "iso3": "PRT",
        "iso2": "PT",
        "phone_code": "351",
        "capital": "Lisbon",
        "currency": "EUR",
        "native": "Portugal",
        "region": "Europe",
        "subregion": "Southern Europe",
        "timezones": [
            {
                "zoneName": "Atlantic\/Azores",
                "gmtOffset": -3600,
                "gmtOffsetName": "UTC-01:00",
                "abbreviation": "AZOT",
                "tzName": "Azores Standard Time"
            },
            {
                "zoneName": "Atlantic\/Madeira",
                "gmtOffset": 0,
                "gmtOffsetName": "UTC±00",
                "abbreviation": "WET",
                "tzName": "Western European Time"
            },
            {
                "zoneName": "Europe\/Lisbon",
                "gmtOffset": 0,
                "gmtOffsetName": "UTC±00",
                "abbreviation": "WET",
                "tzName": "Western European Time"
            }
        ],
        "emoji": "🇵🇹",
        "emojiU": "U+1F1F5 U+1F1F9"
    },
    {
        "id": 178,
        "name": "Puerto Rico",
        "iso3": "PRI",
        "iso2": "PR",
        "phone_code": "1-787 and 1-939",
        "capital": "San Juan",
        "currency": "USD",
        "native": "Puerto Rico",
        "region": "Americas",
        "subregion": "Caribbean",
        "timezones": [
            {
                "zoneName": "America\/Puerto_Rico",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "AST",
                "tzName": "Atlantic Standard Time"
            }
        ],
        "emoji": "🇵🇷",
        "emojiU": "U+1F1F5 U+1F1F7"
    },
    {
        "id": 179,
        "name": "Qatar",
        "iso3": "QAT",
        "iso2": "QA",
        "phone_code": "974",
        "capital": "Doha",
        "currency": "QAR",
        "native": "قطر",
        "region": "Asia",
        "subregion": "Western Asia",
        "timezones": [
            {
                "zoneName": "Asia\/Qatar",
                "gmtOffset": 10800,
                "gmtOffsetName": "UTC+03:00",
                "abbreviation": "AST",
                "tzName": "Arabia Standard Time"
            }
        ],
        "emoji": "🇶🇦",
        "emojiU": "U+1F1F6 U+1F1E6"
    },
    {
        "id": 180,
        "name": "Reunion",
        "iso3": "REU",
        "iso2": "RE",
        "phone_code": "262",
        "capital": "Saint-Denis",
        "currency": "EUR",
        "native": "La Réunion",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "timezones": [
            {
                "zoneName": "Indian\/Reunion",
                "gmtOffset": 14400,
                "gmtOffsetName": "UTC+04:00",
                "abbreviation": "RET",
                "tzName": "Réunion Time"
            }
        ],
        "emoji": "🇷🇪",
        "emojiU": "U+1F1F7 U+1F1EA"
    },
    {
        "id": 181,
        "name": "Romania",
        "iso3": "ROU",
        "iso2": "RO",
        "phone_code": "40",
        "capital": "Bucharest",
        "currency": "RON",
        "native": "România",
        "region": "Europe",
        "subregion": "Eastern Europe",
        "timezones": [
            {
                "zoneName": "Europe\/Bucharest",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "EET",
                "tzName": "Eastern European Time"
            }
        ],
        "emoji": "🇷🇴",
        "emojiU": "U+1F1F7 U+1F1F4"
    },
    {
        "id": 182,
        "name": "Russia",
        "iso3": "RUS",
        "iso2": "RU",
        "phone_code": "7",
        "capital": "Moscow",
        "currency": "RUB",
        "native": "Россия",
        "region": "Europe",
        "subregion": "Eastern Europe",
        "timezones": [
            {
                "zoneName": "Asia\/Anadyr",
                "gmtOffset": 43200,
                "gmtOffsetName": "UTC+12:00",
                "abbreviation": "ANAT",
                "tzName": "Anadyr Time[4"
            },
            {
                "zoneName": "Asia\/Barnaul",
                "gmtOffset": 25200,
                "gmtOffsetName": "UTC+07:00",
                "abbreviation": "KRAT",
                "tzName": "Krasnoyarsk Time"
            },
            {
                "zoneName": "Asia\/Chita",
                "gmtOffset": 32400,
                "gmtOffsetName": "UTC+09:00",
                "abbreviation": "YAKT",
                "tzName": "Yakutsk Time"
            },
            {
                "zoneName": "Asia\/Irkutsk",
                "gmtOffset": 28800,
                "gmtOffsetName": "UTC+08:00",
                "abbreviation": "IRKT",
                "tzName": "Irkutsk Time"
            },
            {
                "zoneName": "Asia\/Kamchatka",
                "gmtOffset": 43200,
                "gmtOffsetName": "UTC+12:00",
                "abbreviation": "PETT",
                "tzName": "Kamchatka Time"
            },
            {
                "zoneName": "Asia\/Khandyga",
                "gmtOffset": 32400,
                "gmtOffsetName": "UTC+09:00",
                "abbreviation": "YAKT",
                "tzName": "Yakutsk Time"
            },
            {
                "zoneName": "Asia\/Krasnoyarsk",
                "gmtOffset": 25200,
                "gmtOffsetName": "UTC+07:00",
                "abbreviation": "KRAT",
                "tzName": "Krasnoyarsk Time"
            },
            {
                "zoneName": "Asia\/Magadan",
                "gmtOffset": 39600,
                "gmtOffsetName": "UTC+11:00",
                "abbreviation": "MAGT",
                "tzName": "Magadan Time"
            },
            {
                "zoneName": "Asia\/Novokuznetsk",
                "gmtOffset": 25200,
                "gmtOffsetName": "UTC+07:00",
                "abbreviation": "KRAT",
                "tzName": "Krasnoyarsk Time"
            },
            {
                "zoneName": "Asia\/Novosibirsk",
                "gmtOffset": 25200,
                "gmtOffsetName": "UTC+07:00",
                "abbreviation": "NOVT",
                "tzName": "Novosibirsk Time"
            },
            {
                "zoneName": "Asia\/Omsk",
                "gmtOffset": 21600,
                "gmtOffsetName": "UTC+06:00",
                "abbreviation": "OMST",
                "tzName": "Omsk Time"
            },
            {
                "zoneName": "Asia\/Sakhalin",
                "gmtOffset": 39600,
                "gmtOffsetName": "UTC+11:00",
                "abbreviation": "SAKT",
                "tzName": "Sakhalin Island Time"
            },
            {
                "zoneName": "Asia\/Srednekolymsk",
                "gmtOffset": 39600,
                "gmtOffsetName": "UTC+11:00",
                "abbreviation": "SRET",
                "tzName": "Srednekolymsk Time"
            },
            {
                "zoneName": "Asia\/Tomsk",
                "gmtOffset": 25200,
                "gmtOffsetName": "UTC+07:00",
                "abbreviation": "MSD+3",
                "tzName": "Moscow Daylight Time+3"
            },
            {
                "zoneName": "Asia\/Ust-Nera",
                "gmtOffset": 36000,
                "gmtOffsetName": "UTC+10:00",
                "abbreviation": "VLAT",
                "tzName": "Vladivostok Time"
            },
            {
                "zoneName": "Asia\/Vladivostok",
                "gmtOffset": 36000,
                "gmtOffsetName": "UTC+10:00",
                "abbreviation": "VLAT",
                "tzName": "Vladivostok Time"
            },
            {
                "zoneName": "Asia\/Yakutsk",
                "gmtOffset": 32400,
                "gmtOffsetName": "UTC+09:00",
                "abbreviation": "YAKT",
                "tzName": "Yakutsk Time"
            },
            {
                "zoneName": "Asia\/Yekaterinburg",
                "gmtOffset": 18000,
                "gmtOffsetName": "UTC+05:00",
                "abbreviation": "YEKT",
                "tzName": "Yekaterinburg Time"
            },
            {
                "zoneName": "Europe\/Astrakhan",
                "gmtOffset": 14400,
                "gmtOffsetName": "UTC+04:00",
                "abbreviation": "SAMT",
                "tzName": "Samara Time"
            },
            {
                "zoneName": "Europe\/Kaliningrad",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "EET",
                "tzName": "Eastern European Time"
            },
            {
                "zoneName": "Europe\/Kirov",
                "gmtOffset": 10800,
                "gmtOffsetName": "UTC+03:00",
                "abbreviation": "MSK",
                "tzName": "Moscow Time"
            },
            {
                "zoneName": "Europe\/Moscow",
                "gmtOffset": 10800,
                "gmtOffsetName": "UTC+03:00",
                "abbreviation": "MSK",
                "tzName": "Moscow Time"
            },
            {
                "zoneName": "Europe\/Samara",
                "gmtOffset": 14400,
                "gmtOffsetName": "UTC+04:00",
                "abbreviation": "SAMT",
                "tzName": "Samara Time"
            },
            {
                "zoneName": "Europe\/Saratov",
                "gmtOffset": 14400,
                "gmtOffsetName": "UTC+04:00",
                "abbreviation": "MSD",
                "tzName": "Moscow Daylight Time+4"
            },
            {
                "zoneName": "Europe\/Ulyanovsk",
                "gmtOffset": 14400,
                "gmtOffsetName": "UTC+04:00",
                "abbreviation": "SAMT",
                "tzName": "Samara Time"
            },
            {
                "zoneName": "Europe\/Volgograd",
                "gmtOffset": 14400,
                "gmtOffsetName": "UTC+04:00",
                "abbreviation": "MSK",
                "tzName": "Moscow Standard Time"
            }
        ],
        "emoji": "🇷🇺",
        "emojiU": "U+1F1F7 U+1F1FA"
    },
    {
        "id": 183,
        "name": "Rwanda",
        "iso3": "RWA",
        "iso2": "RW",
        "phone_code": "250",
        "capital": "Kigali",
        "currency": "RWF",
        "native": "Rwanda",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "timezones": [
            {
                "zoneName": "Africa\/Kigali",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "CAT",
                "tzName": "Central Africa Time"
            }
        ],
        "emoji": "🇷🇼",
        "emojiU": "U+1F1F7 U+1F1FC"
    },
    {
        "id": 184,
        "name": "Saint Helena",
        "iso3": "SHN",
        "iso2": "SH",
        "phone_code": "290",
        "capital": "Jamestown",
        "currency": "SHP",
        "native": "Saint Helena",
        "region": "Africa",
        "subregion": "Western Africa",
        "timezones": [
            {
                "zoneName": "Atlantic\/St_Helena",
                "gmtOffset": 0,
                "gmtOffsetName": "UTC±00",
                "abbreviation": "GMT",
                "tzName": "Greenwich Mean Time"
            }
        ],
        "emoji": "🇸🇭",
        "emojiU": "U+1F1F8 U+1F1ED"
    },
    {
        "id": 185,
        "name": "Saint Kitts And Nevis",
        "iso3": "KNA",
        "iso2": "KN",
        "phone_code": "1-869",
        "capital": "Basseterre",
        "currency": "XCD",
        "native": "Saint Kitts and Nevis",
        "region": "Americas",
        "subregion": "Caribbean",
        "timezones": [
            {
                "zoneName": "America\/St_Kitts",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "AST",
                "tzName": "Atlantic Standard Time"
            }
        ],
        "emoji": "🇰🇳",
        "emojiU": "U+1F1F0 U+1F1F3"
    },
    {
        "id": 186,
        "name": "Saint Lucia",
        "iso3": "LCA",
        "iso2": "LC",
        "phone_code": "1-758",
        "capital": "Castries",
        "currency": "XCD",
        "native": "Saint Lucia",
        "region": "Americas",
        "subregion": "Caribbean",
        "timezones": [
            {
                "zoneName": "America\/St_Lucia",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "AST",
                "tzName": "Atlantic Standard Time"
            }
        ],
        "emoji": "🇱🇨",
        "emojiU": "U+1F1F1 U+1F1E8"
    },
    {
        "id": 187,
        "name": "Saint Pierre and Miquelon",
        "iso3": "SPM",
        "iso2": "PM",
        "phone_code": "508",
        "capital": "Saint-Pierre",
        "currency": "EUR",
        "native": "Saint-Pierre-et-Miquelon",
        "region": "Americas",
        "subregion": "Northern America",
        "timezones": [
            {
                "zoneName": "America\/Miquelon",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "PMDT",
                "tzName": "Pierre & Miquelon Daylight Time"
            }
        ],
        "emoji": "🇵🇲",
        "emojiU": "U+1F1F5 U+1F1F2"
    },
    {
        "id": 188,
        "name": "Saint Vincent And The Grenadines",
        "iso3": "VCT",
        "iso2": "VC",
        "phone_code": "1-784",
        "capital": "Kingstown",
        "currency": "XCD",
        "native": "Saint Vincent and the Grenadines",
        "region": "Americas",
        "subregion": "Caribbean",
        "timezones": [
            {
                "zoneName": "America\/St_Vincent",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "AST",
                "tzName": "Atlantic Standard Time"
            }
        ],
        "emoji": "🇻🇨",
        "emojiU": "U+1F1FB U+1F1E8"
    },
    {
        "id": 191,
        "name": "Samoa",
        "iso3": "WSM",
        "iso2": "WS",
        "phone_code": "685",
        "capital": "Apia",
        "currency": "WST",
        "native": "Samoa",
        "region": "Oceania",
        "subregion": "Polynesia",
        "timezones": [
            {
                "zoneName": "Pacific\/Apia",
                "gmtOffset": 50400,
                "gmtOffsetName": "UTC+14:00",
                "abbreviation": "WST",
                "tzName": "West Samoa Time"
            }
        ],
        "emoji": "🇼🇸",
        "emojiU": "U+1F1FC U+1F1F8"
    },
    {
        "id": 192,
        "name": "San Marino",
        "iso3": "SMR",
        "iso2": "SM",
        "phone_code": "378",
        "capital": "San Marino",
        "currency": "EUR",
        "native": "San Marino",
        "region": "Europe",
        "subregion": "Southern Europe",
        "timezones": [
            {
                "zoneName": "Europe\/San_Marino",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "emoji": "🇸🇲",
        "emojiU": "U+1F1F8 U+1F1F2"
    },
    {
        "id": 193,
        "name": "Sao Tome and Principe",
        "iso3": "STP",
        "iso2": "ST",
        "phone_code": "239",
        "capital": "Sao Tome",
        "currency": "STD",
        "native": "São Tomé e Príncipe",
        "region": "Africa",
        "subregion": "Middle Africa",
        "timezones": [
            {
                "zoneName": "Africa\/Sao_Tome",
                "gmtOffset": 0,
                "gmtOffsetName": "UTC±00",
                "abbreviation": "GMT",
                "tzName": "Greenwich Mean Time"
            }
        ],
        "emoji": "🇸🇹",
        "emojiU": "U+1F1F8 U+1F1F9"
    },
    {
        "id": 194,
        "name": "Saudi Arabia",
        "iso3": "SAU",
        "iso2": "SA",
        "phone_code": "966",
        "capital": "Riyadh",
        "currency": "SAR",
        "native": "العربية السعودية",
        "region": "Asia",
        "subregion": "Western Asia",
        "timezones": [
            {
                "zoneName": "Asia\/Riyadh",
                "gmtOffset": 10800,
                "gmtOffsetName": "UTC+03:00",
                "abbreviation": "AST",
                "tzName": "Arabia Standard Time"
            }
        ],
        "emoji": "🇸🇦",
        "emojiU": "U+1F1F8 U+1F1E6"
    },
    {
        "id": 195,
        "name": "Senegal",
        "iso3": "SEN",
        "iso2": "SN",
        "phone_code": "221",
        "capital": "Dakar",
        "currency": "XOF",
        "native": "Sénégal",
        "region": "Africa",
        "subregion": "Western Africa",
        "timezones": [
            {
                "zoneName": "Africa\/Dakar",
                "gmtOffset": 0,
                "gmtOffsetName": "UTC±00",
                "abbreviation": "GMT",
                "tzName": "Greenwich Mean Time"
            }
        ],
        "emoji": "🇸🇳",
        "emojiU": "U+1F1F8 U+1F1F3"
    },
    {
        "id": 196,
        "name": "Serbia",
        "iso3": "SRB",
        "iso2": "RS",
        "phone_code": "381",
        "capital": "Belgrade",
        "currency": "RSD",
        "native": "Србија",
        "region": "Europe",
        "subregion": "Southern Europe",
        "timezones": [
            {
                "zoneName": "Europe\/Belgrade",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "emoji": "🇷🇸",
        "emojiU": "U+1F1F7 U+1F1F8"
    },
    {
        "id": 197,
        "name": "Seychelles",
        "iso3": "SYC",
        "iso2": "SC",
        "phone_code": "248",
        "capital": "Victoria",
        "currency": "SCR",
        "native": "Seychelles",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "timezones": [
            {
                "zoneName": "Indian\/Mahe",
                "gmtOffset": 14400,
                "gmtOffsetName": "UTC+04:00",
                "abbreviation": "SCT",
                "tzName": "Seychelles Time"
            }
        ],
        "emoji": "🇸🇨",
        "emojiU": "U+1F1F8 U+1F1E8"
    },
    {
        "id": 198,
        "name": "Sierra Leone",
        "iso3": "SLE",
        "iso2": "SL",
        "phone_code": "232",
        "capital": "Freetown",
        "currency": "SLL",
        "native": "Sierra Leone",
        "region": "Africa",
        "subregion": "Western Africa",
        "timezones": [
            {
                "zoneName": "Africa\/Freetown",
                "gmtOffset": 0,
                "gmtOffsetName": "UTC±00",
                "abbreviation": "GMT",
                "tzName": "Greenwich Mean Time"
            }
        ],
        "emoji": "🇸🇱",
        "emojiU": "U+1F1F8 U+1F1F1"
    },
    {
        "id": 199,
        "name": "Singapore",
        "iso3": "SGP",
        "iso2": "SG",
        "phone_code": "65",
        "capital": "Singapur",
        "currency": "SGD",
        "native": "Singapore",
        "region": "Asia",
        "subregion": "South-Eastern Asia",
        "timezones": [
            {
                "zoneName": "Asia\/Singapore",
                "gmtOffset": 28800,
                "gmtOffsetName": "UTC+08:00",
                "abbreviation": "SGT",
                "tzName": "Singapore Time"
            }
        ],
        "emoji": "🇸🇬",
        "emojiU": "U+1F1F8 U+1F1EC"
    },
    {
        "id": 200,
        "name": "Slovakia",
        "iso3": "SVK",
        "iso2": "SK",
        "phone_code": "421",
        "capital": "Bratislava",
        "currency": "EUR",
        "native": "Slovensko",
        "region": "Europe",
        "subregion": "Eastern Europe",
        "timezones": [
            {
                "zoneName": "Europe\/Bratislava",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "emoji": "🇸🇰",
        "emojiU": "U+1F1F8 U+1F1F0"
    },
    {
        "id": 201,
        "name": "Slovenia",
        "iso3": "SVN",
        "iso2": "SI",
        "phone_code": "386",
        "capital": "Ljubljana",
        "currency": "EUR",
        "native": "Slovenija",
        "region": "Europe",
        "subregion": "Southern Europe",
        "timezones": [
            {
                "zoneName": "Europe\/Ljubljana",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "emoji": "🇸🇮",
        "emojiU": "U+1F1F8 U+1F1EE"
    },
    {
        "id": 202,
        "name": "Solomon Islands",
        "iso3": "SLB",
        "iso2": "SB",
        "phone_code": "677",
        "capital": "Honiara",
        "currency": "SBD",
        "native": "Solomon Islands",
        "region": "Oceania",
        "subregion": "Melanesia",
        "timezones": [
            {
                "zoneName": "Pacific\/Guadalcanal",
                "gmtOffset": 39600,
                "gmtOffsetName": "UTC+11:00",
                "abbreviation": "SBT",
                "tzName": "Solomon Islands Time"
            }
        ],
        "emoji": "🇸🇧",
        "emojiU": "U+1F1F8 U+1F1E7"
    },
    {
        "id": 203,
        "name": "Somalia",
        "iso3": "SOM",
        "iso2": "SO",
        "phone_code": "252",
        "capital": "Mogadishu",
        "currency": "SOS",
        "native": "Soomaaliya",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "timezones": [
            {
                "zoneName": "Africa\/Mogadishu",
                "gmtOffset": 10800,
                "gmtOffsetName": "UTC+03:00",
                "abbreviation": "EAT",
                "tzName": "East Africa Time"
            }
        ],
        "emoji": "🇸🇴",
        "emojiU": "U+1F1F8 U+1F1F4"
    },
    {
        "id": 204,
        "name": "South Africa",
        "iso3": "ZAF",
        "iso2": "ZA",
        "phone_code": "27",
        "capital": "Pretoria",
        "currency": "ZAR",
        "native": "South Africa",
        "region": "Africa",
        "subregion": "Southern Africa",
        "timezones": [
            {
                "zoneName": "Africa\/Johannesburg",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "SAST",
                "tzName": "South African Standard Time"
            }
        ],
        "emoji": "🇿🇦",
        "emojiU": "U+1F1FF U+1F1E6"
    },
    {
        "id": 205,
        "name": "South Georgia",
        "iso3": "SGS",
        "iso2": "GS",
        "phone_code": "",
        "capital": "Grytviken",
        "currency": "GBP",
        "native": "South Georgia",
        "region": "Americas",
        "subregion": "South America",
        "timezones": [
            {
                "zoneName": "Atlantic\/South_Georgia",
                "gmtOffset": -7200,
                "gmtOffsetName": "UTC-02:00",
                "abbreviation": "GST",
                "tzName": "South Georgia and the South Sandwich Islands Time"
            }
        ],
        "emoji": "🇬🇸",
        "emojiU": "U+1F1EC U+1F1F8"
    },
    {
        "id": 206,
        "name": "South Sudan",
        "iso3": "SSD",
        "iso2": "SS",
        "phone_code": "211",
        "capital": "Juba",
        "currency": "SSP",
        "native": "South Sudan",
        "region": "Africa",
        "subregion": "Middle Africa",
        "timezones": [
            {
                "zoneName": "Africa\/Juba",
                "gmtOffset": 10800,
                "gmtOffsetName": "UTC+03:00",
                "abbreviation": "EAT",
                "tzName": "East Africa Time"
            }
        ],
        "emoji": "🇸🇸",
        "emojiU": "U+1F1F8 U+1F1F8"
    },
    {
        "id": 207,
        "name": "Spain",
        "iso3": "ESP",
        "iso2": "ES",
        "phone_code": "34",
        "capital": "Madrid",
        "currency": "EUR",
        "native": "España",
        "region": "Europe",
        "subregion": "Southern Europe",
        "timezones": [
            {
                "zoneName": "Africa\/Ceuta",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            },
            {
                "zoneName": "Atlantic\/Canary",
                "gmtOffset": 0,
                "gmtOffsetName": "UTC±00",
                "abbreviation": "WET",
                "tzName": "Western European Time"
            },
            {
                "zoneName": "Europe\/Madrid",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "emoji": "🇪🇸",
        "emojiU": "U+1F1EA U+1F1F8"
    },
    {
        "id": 208,
        "name": "Sri Lanka",
        "iso3": "LKA",
        "iso2": "LK",
        "phone_code": "94",
        "capital": "Colombo",
        "currency": "LKR",
        "native": "śrī laṃkāva",
        "region": "Asia",
        "subregion": "Southern Asia",
        "timezones": [
            {
                "zoneName": "Asia\/Colombo",
                "gmtOffset": 19800,
                "gmtOffsetName": "UTC+05:30",
                "abbreviation": "IST",
                "tzName": "Indian Standard Time"
            }
        ],
        "emoji": "🇱🇰",
        "emojiU": "U+1F1F1 U+1F1F0"
    },
    {
        "id": 209,
        "name": "Sudan",
        "iso3": "SDN",
        "iso2": "SD",
        "phone_code": "249",
        "capital": "Khartoum",
        "currency": "SDG",
        "native": "السودان",
        "region": "Africa",
        "subregion": "Northern Africa",
        "timezones": [
            {
                "zoneName": "Africa\/Khartoum",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "EAT",
                "tzName": "Eastern African Time"
            }
        ],
        "emoji": "🇸🇩",
        "emojiU": "U+1F1F8 U+1F1E9"
    },
    {
        "id": 210,
        "name": "Suriname",
        "iso3": "SUR",
        "iso2": "SR",
        "phone_code": "597",
        "capital": "Paramaribo",
        "currency": "SRD",
        "native": "Suriname",
        "region": "Americas",
        "subregion": "South America",
        "timezones": [
            {
                "zoneName": "America\/Paramaribo",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "SRT",
                "tzName": "Suriname Time"
            }
        ],
        "emoji": "🇸🇷",
        "emojiU": "U+1F1F8 U+1F1F7"
    },
    {
        "id": 211,
        "name": "Svalbard And Jan Mayen Islands",
        "iso3": "SJM",
        "iso2": "SJ",
        "phone_code": "47",
        "capital": "Longyearbyen",
        "currency": "NOK",
        "native": "Svalbard og Jan Mayen",
        "region": "Europe",
        "subregion": "Northern Europe",
        "timezones": [
            {
                "zoneName": "Arctic\/Longyearbyen",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "emoji": "🇸🇯",
        "emojiU": "U+1F1F8 U+1F1EF"
    },
    {
        "id": 212,
        "name": "Swaziland",
        "iso3": "SWZ",
        "iso2": "SZ",
        "phone_code": "268",
        "capital": "Mbabane",
        "currency": "SZL",
        "native": "Swaziland",
        "region": "Africa",
        "subregion": "Southern Africa",
        "timezones": [
            {
                "zoneName": "Africa\/Mbabane",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "SAST",
                "tzName": "South African Standard Time"
            }
        ],
        "emoji": "🇸🇿",
        "emojiU": "U+1F1F8 U+1F1FF"
    },
    {
        "id": 213,
        "name": "Sweden",
        "iso3": "SWE",
        "iso2": "SE",
        "phone_code": "46",
        "capital": "Stockholm",
        "currency": "SEK",
        "native": "Sverige",
        "region": "Europe",
        "subregion": "Northern Europe",
        "timezones": [
            {
                "zoneName": "Europe\/Stockholm",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "emoji": "🇸🇪",
        "emojiU": "U+1F1F8 U+1F1EA"
    },
    {
        "id": 214,
        "name": "Switzerland",
        "iso3": "CHE",
        "iso2": "CH",
        "phone_code": "41",
        "capital": "Berne",
        "currency": "CHF",
        "native": "Schweiz",
        "region": "Europe",
        "subregion": "Western Europe",
        "timezones": [
            {
                "zoneName": "Europe\/Zurich",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "emoji": "🇨🇭",
        "emojiU": "U+1F1E8 U+1F1ED"
    },
    {
        "id": 215,
        "name": "Syria",
        "iso3": "SYR",
        "iso2": "SY",
        "phone_code": "963",
        "capital": "Damascus",
        "currency": "SYP",
        "native": "سوريا",
        "region": "Asia",
        "subregion": "Western Asia",
        "timezones": [
            {
                "zoneName": "Asia\/Damascus",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "EET",
                "tzName": "Eastern European Time"
            }
        ],
        "emoji": "🇸🇾",
        "emojiU": "U+1F1F8 U+1F1FE"
    },
    {
        "id": 216,
        "name": "Taiwan",
        "iso3": "TWN",
        "iso2": "TW",
        "phone_code": "886",
        "capital": "Taipei",
        "currency": "TWD",
        "native": "臺灣",
        "region": "Asia",
        "subregion": "Eastern Asia",
        "timezones": [
            {
                "zoneName": "Asia\/Taipei",
                "gmtOffset": 28800,
                "gmtOffsetName": "UTC+08:00",
                "abbreviation": "CST",
                "tzName": "China Standard Time"
            }
        ],
        "emoji": "🇹🇼",
        "emojiU": "U+1F1F9 U+1F1FC"
    },
    {
        "id": 217,
        "name": "Tajikistan",
        "iso3": "TJK",
        "iso2": "TJ",
        "phone_code": "992",
        "capital": "Dushanbe",
        "currency": "TJS",
        "native": "Тоҷикистон",
        "region": "Asia",
        "subregion": "Central Asia",
        "timezones": [
            {
                "zoneName": "Asia\/Dushanbe",
                "gmtOffset": 18000,
                "gmtOffsetName": "UTC+05:00",
                "abbreviation": "TJT",
                "tzName": "Tajikistan Time"
            }
        ],
        "emoji": "🇹🇯",
        "emojiU": "U+1F1F9 U+1F1EF"
    },
    {
        "id": 218,
        "name": "Tanzania",
        "iso3": "TZA",
        "iso2": "TZ",
        "phone_code": "255",
        "capital": "Dodoma",
        "currency": "TZS",
        "native": "Tanzania",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "timezones": [
            {
                "zoneName": "Africa\/Dar_es_Salaam",
                "gmtOffset": 10800,
                "gmtOffsetName": "UTC+03:00",
                "abbreviation": "EAT",
                "tzName": "East Africa Time"
            }
        ],
        "emoji": "🇹🇿",
        "emojiU": "U+1F1F9 U+1F1FF"
    },
    {
        "id": 219,
        "name": "Thailand",
        "iso3": "THA",
        "iso2": "TH",
        "phone_code": "66",
        "capital": "Bangkok",
        "currency": "THB",
        "native": "ประเทศไทย",
        "region": "Asia",
        "subregion": "South-Eastern Asia",
        "timezones": [
            {
                "zoneName": "Asia\/Bangkok",
                "gmtOffset": 25200,
                "gmtOffsetName": "UTC+07:00",
                "abbreviation": "ICT",
                "tzName": "Indochina Time"
            }
        ],
        "emoji": "🇹🇭",
        "emojiU": "U+1F1F9 U+1F1ED"
    },
    {
        "id": 220,
        "name": "Togo",
        "iso3": "TGO",
        "iso2": "TG",
        "phone_code": "228",
        "capital": "Lome",
        "currency": "XOF",
        "native": "Togo",
        "region": "Africa",
        "subregion": "Western Africa",
        "timezones": [
            {
                "zoneName": "Africa\/Lome",
                "gmtOffset": 0,
                "gmtOffsetName": "UTC±00",
                "abbreviation": "GMT",
                "tzName": "Greenwich Mean Time"
            }
        ],
        "emoji": "🇹🇬",
        "emojiU": "U+1F1F9 U+1F1EC"
    },
    {
        "id": 221,
        "name": "Tokelau",
        "iso3": "TKL",
        "iso2": "TK",
        "phone_code": "690",
        "capital": "",
        "currency": "NZD",
        "native": "Tokelau",
        "region": "Oceania",
        "subregion": "Polynesia",
        "timezones": [
            {
                "zoneName": "Pacific\/Fakaofo",
                "gmtOffset": 46800,
                "gmtOffsetName": "UTC+13:00",
                "abbreviation": "TKT",
                "tzName": "Tokelau Time"
            }
        ],
        "emoji": "🇹🇰",
        "emojiU": "U+1F1F9 U+1F1F0"
    },
    {
        "id": 222,
        "name": "Tonga",
        "iso3": "TON",
        "iso2": "TO",
        "phone_code": "676",
        "capital": "Nuku'alofa",
        "currency": "TOP",
        "native": "Tonga",
        "region": "Oceania",
        "subregion": "Polynesia",
        "timezones": [
            {
                "zoneName": "Pacific\/Tongatapu",
                "gmtOffset": 46800,
                "gmtOffsetName": "UTC+13:00",
                "abbreviation": "TOT",
                "tzName": "Tonga Time"
            }
        ],
        "emoji": "🇹🇴",
        "emojiU": "U+1F1F9 U+1F1F4"
    },
    {
        "id": 223,
        "name": "Trinidad And Tobago",
        "iso3": "TTO",
        "iso2": "TT",
        "phone_code": "1-868",
        "capital": "Port of Spain",
        "currency": "TTD",
        "native": "Trinidad and Tobago",
        "region": "Americas",
        "subregion": "Caribbean",
        "timezones": [
            {
                "zoneName": "America\/Port_of_Spain",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "AST",
                "tzName": "Atlantic Standard Time"
            }
        ],
        "emoji": "🇹🇹",
        "emojiU": "U+1F1F9 U+1F1F9"
    },
    {
        "id": 224,
        "name": "Tunisia",
        "iso3": "TUN",
        "iso2": "TN",
        "phone_code": "216",
        "capital": "Tunis",
        "currency": "TND",
        "native": "تونس",
        "region": "Africa",
        "subregion": "Northern Africa",
        "timezones": [
            {
                "zoneName": "Africa\/Tunis",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "emoji": "🇹🇳",
        "emojiU": "U+1F1F9 U+1F1F3"
    },
    {
        "id": 225,
        "name": "Turkey",
        "iso3": "TUR",
        "iso2": "TR",
        "phone_code": "90",
        "capital": "Ankara",
        "currency": "TRY",
        "native": "Türkiye",
        "region": "Asia",
        "subregion": "Western Asia",
        "timezones": [
            {
                "zoneName": "Europe\/Istanbul",
                "gmtOffset": 10800,
                "gmtOffsetName": "UTC+03:00",
                "abbreviation": "EET",
                "tzName": "Eastern European Time"
            }
        ],
        "emoji": "🇹🇷",
        "emojiU": "U+1F1F9 U+1F1F7"
    },
    {
        "id": 226,
        "name": "Turkmenistan",
        "iso3": "TKM",
        "iso2": "TM",
        "phone_code": "993",
        "capital": "Ashgabat",
        "currency": "TMT",
        "native": "Türkmenistan",
        "region": "Asia",
        "subregion": "Central Asia",
        "timezones": [
            {
                "zoneName": "Asia\/Ashgabat",
                "gmtOffset": 18000,
                "gmtOffsetName": "UTC+05:00",
                "abbreviation": "TMT",
                "tzName": "Turkmenistan Time"
            }
        ],
        "emoji": "🇹🇲",
        "emojiU": "U+1F1F9 U+1F1F2"
    },
    {
        "id": 227,
        "name": "Turks And Caicos Islands",
        "iso3": "TCA",
        "iso2": "TC",
        "phone_code": "1-649",
        "capital": "Cockburn Town",
        "currency": "USD",
        "native": "Turks and Caicos Islands",
        "region": "Americas",
        "subregion": "Caribbean",
        "timezones": [
            {
                "zoneName": "America\/Grand_Turk",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "EST",
                "tzName": "Eastern Standard Time (North America"
            }
        ],
        "emoji": "🇹🇨",
        "emojiU": "U+1F1F9 U+1F1E8"
    },
    {
        "id": 228,
        "name": "Tuvalu",
        "iso3": "TUV",
        "iso2": "TV",
        "phone_code": "688",
        "capital": "Funafuti",
        "currency": "AUD",
        "native": "Tuvalu",
        "region": "Oceania",
        "subregion": "Polynesia",
        "timezones": [
            {
                "zoneName": "Pacific\/Funafuti",
                "gmtOffset": 43200,
                "gmtOffsetName": "UTC+12:00",
                "abbreviation": "TVT",
                "tzName": "Tuvalu Time"
            }
        ],
        "emoji": "🇹🇻",
        "emojiU": "U+1F1F9 U+1F1FB"
    },
    {
        "id": 229,
        "name": "Uganda",
        "iso3": "UGA",
        "iso2": "UG",
        "phone_code": "256",
        "capital": "Kampala",
        "currency": "UGX",
        "native": "Uganda",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "timezones": [
            {
                "zoneName": "Africa\/Kampala",
                "gmtOffset": 10800,
                "gmtOffsetName": "UTC+03:00",
                "abbreviation": "EAT",
                "tzName": "East Africa Time"
            }
        ],
        "emoji": "🇺🇬",
        "emojiU": "U+1F1FA U+1F1EC"
    },
    {
        "id": 230,
        "name": "Ukraine",
        "iso3": "UKR",
        "iso2": "UA",
        "phone_code": "380",
        "capital": "Kiev",
        "currency": "UAH",
        "native": "Україна",
        "region": "Europe",
        "subregion": "Eastern Europe",
        "timezones": [
            {
                "zoneName": "Europe\/Kiev",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "EET",
                "tzName": "Eastern European Time"
            },
            {
                "zoneName": "Europe\/Simferopol",
                "gmtOffset": 10800,
                "gmtOffsetName": "UTC+03:00",
                "abbreviation": "MSK",
                "tzName": "Moscow Time"
            },
            {
                "zoneName": "Europe\/Uzhgorod",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "EET",
                "tzName": "Eastern European Time"
            },
            {
                "zoneName": "Europe\/Zaporozhye",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "EET",
                "tzName": "Eastern European Time"
            }
        ],
        "emoji": "🇺🇦",
        "emojiU": "U+1F1FA U+1F1E6"
    },
    {
        "id": 231,
        "name": "United Arab Emirates",
        "iso3": "ARE",
        "iso2": "AE",
        "phone_code": "971",
        "capital": "Abu Dhabi",
        "currency": "AED",
        "native": "دولة الإمارات العربية المتحدة",
        "region": "Asia",
        "subregion": "Western Asia",
        "timezones": [
            {
                "zoneName": "Asia\/Dubai",
                "gmtOffset": 14400,
                "gmtOffsetName": "UTC+04:00",
                "abbreviation": "GST",
                "tzName": "Gulf Standard Time"
            }
        ],
        "emoji": "🇦🇪",
        "emojiU": "U+1F1E6 U+1F1EA"
    },
    {
        "id": 232,
        "name": "United Kingdom",
        "iso3": "GBR",
        "iso2": "GB",
        "phone_code": "44",
        "capital": "London",
        "currency": "GBP",
        "native": "United Kingdom",
        "region": "Europe",
        "subregion": "Northern Europe",
        "timezones": [
            {
                "zoneName": "Europe\/London",
                "gmtOffset": 0,
                "gmtOffsetName": "UTC±00",
                "abbreviation": "GMT",
                "tzName": "Greenwich Mean Time"
            }
        ],
        "emoji": "🇬🇧",
        "emojiU": "U+1F1EC U+1F1E7"
    },
    {
        "id": 233,
        "name": "United States",
        "iso3": "USA",
        "iso2": "US",
        "phone_code": "1",
        "capital": "Washington",
        "currency": "USD",
        "native": "United States",
        "region": "Americas",
        "subregion": "Northern America",
        "timezones": [
            {
                "zoneName": "America\/Adak",
                "gmtOffset": -36000,
                "gmtOffsetName": "UTC-10:00",
                "abbreviation": "HST",
                "tzName": "Hawaii–Aleutian Standard Time"
            },
            {
                "zoneName": "America\/Anchorage",
                "gmtOffset": -32400,
                "gmtOffsetName": "UTC-09:00",
                "abbreviation": "AKST",
                "tzName": "Alaska Standard Time"
            },
            {
                "zoneName": "America\/Boise",
                "gmtOffset": -25200,
                "gmtOffsetName": "UTC-07:00",
                "abbreviation": "MST",
                "tzName": "Mountain Standard Time (North America"
            },
            {
                "zoneName": "America\/Chicago",
                "gmtOffset": -21600,
                "gmtOffsetName": "UTC-06:00",
                "abbreviation": "CST",
                "tzName": "Central Standard Time (North America"
            },
            {
                "zoneName": "America\/Denver",
                "gmtOffset": -25200,
                "gmtOffsetName": "UTC-07:00",
                "abbreviation": "MST",
                "tzName": "Mountain Standard Time (North America"
            },
            {
                "zoneName": "America\/Detroit",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "EST",
                "tzName": "Eastern Standard Time (North America"
            },
            {
                "zoneName": "America\/Indiana\/Indianapolis",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "EST",
                "tzName": "Eastern Standard Time (North America"
            },
            {
                "zoneName": "America\/Indiana\/Knox",
                "gmtOffset": -21600,
                "gmtOffsetName": "UTC-06:00",
                "abbreviation": "CST",
                "tzName": "Central Standard Time (North America"
            },
            {
                "zoneName": "America\/Indiana\/Marengo",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "EST",
                "tzName": "Eastern Standard Time (North America"
            },
            {
                "zoneName": "America\/Indiana\/Petersburg",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "EST",
                "tzName": "Eastern Standard Time (North America"
            },
            {
                "zoneName": "America\/Indiana\/Tell_City",
                "gmtOffset": -21600,
                "gmtOffsetName": "UTC-06:00",
                "abbreviation": "CST",
                "tzName": "Central Standard Time (North America"
            },
            {
                "zoneName": "America\/Indiana\/Vevay",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "EST",
                "tzName": "Eastern Standard Time (North America"
            },
            {
                "zoneName": "America\/Indiana\/Vincennes",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "EST",
                "tzName": "Eastern Standard Time (North America"
            },
            {
                "zoneName": "America\/Indiana\/Winamac",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "EST",
                "tzName": "Eastern Standard Time (North America"
            },
            {
                "zoneName": "America\/Juneau",
                "gmtOffset": -32400,
                "gmtOffsetName": "UTC-09:00",
                "abbreviation": "AKST",
                "tzName": "Alaska Standard Time"
            },
            {
                "zoneName": "America\/Kentucky\/Louisville",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "EST",
                "tzName": "Eastern Standard Time (North America"
            },
            {
                "zoneName": "America\/Kentucky\/Monticello",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "EST",
                "tzName": "Eastern Standard Time (North America"
            },
            {
                "zoneName": "America\/Los_Angeles",
                "gmtOffset": -28800,
                "gmtOffsetName": "UTC-08:00",
                "abbreviation": "PST",
                "tzName": "Pacific Standard Time (North America"
            },
            {
                "zoneName": "America\/Menominee",
                "gmtOffset": -21600,
                "gmtOffsetName": "UTC-06:00",
                "abbreviation": "CST",
                "tzName": "Central Standard Time (North America"
            },
            {
                "zoneName": "America\/Metlakatla",
                "gmtOffset": -32400,
                "gmtOffsetName": "UTC-09:00",
                "abbreviation": "AKST",
                "tzName": "Alaska Standard Time"
            },
            {
                "zoneName": "America\/New_York",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "EST",
                "tzName": "Eastern Standard Time (North America"
            },
            {
                "zoneName": "America\/Nome",
                "gmtOffset": -32400,
                "gmtOffsetName": "UTC-09:00",
                "abbreviation": "AKST",
                "tzName": "Alaska Standard Time"
            },
            {
                "zoneName": "America\/North_Dakota\/Beulah",
                "gmtOffset": -21600,
                "gmtOffsetName": "UTC-06:00",
                "abbreviation": "CST",
                "tzName": "Central Standard Time (North America"
            },
            {
                "zoneName": "America\/North_Dakota\/Center",
                "gmtOffset": -21600,
                "gmtOffsetName": "UTC-06:00",
                "abbreviation": "CST",
                "tzName": "Central Standard Time (North America"
            },
            {
                "zoneName": "America\/North_Dakota\/New_Salem",
                "gmtOffset": -21600,
                "gmtOffsetName": "UTC-06:00",
                "abbreviation": "CST",
                "tzName": "Central Standard Time (North America"
            },
            {
                "zoneName": "America\/Phoenix",
                "gmtOffset": -25200,
                "gmtOffsetName": "UTC-07:00",
                "abbreviation": "MST",
                "tzName": "Mountain Standard Time (North America"
            },
            {
                "zoneName": "America\/Sitka",
                "gmtOffset": -32400,
                "gmtOffsetName": "UTC-09:00",
                "abbreviation": "AKST",
                "tzName": "Alaska Standard Time"
            },
            {
                "zoneName": "America\/Yakutat",
                "gmtOffset": -32400,
                "gmtOffsetName": "UTC-09:00",
                "abbreviation": "AKST",
                "tzName": "Alaska Standard Time"
            },
            {
                "zoneName": "Pacific\/Honolulu",
                "gmtOffset": -36000,
                "gmtOffsetName": "UTC-10:00",
                "abbreviation": "HST",
                "tzName": "Hawaii–Aleutian Standard Time"
            }
        ],
        "emoji": "🇺🇸",
        "emojiU": "U+1F1FA U+1F1F8"
    },
    {
        "id": 234,
        "name": "United States Minor Outlying Islands",
        "iso3": "UMI",
        "iso2": "UM",
        "phone_code": "1",
        "capital": "",
        "currency": "USD",
        "native": "United States Minor Outlying Islands",
        "region": "Americas",
        "subregion": "Northern America",
        "timezones": [
            {
                "zoneName": "Pacific\/Midway",
                "gmtOffset": -39600,
                "gmtOffsetName": "UTC-11:00",
                "abbreviation": "SST",
                "tzName": "Samoa Standard Time"
            },
            {
                "zoneName": "Pacific\/Wake",
                "gmtOffset": 43200,
                "gmtOffsetName": "UTC+12:00",
                "abbreviation": "WAKT",
                "tzName": "Wake Island Time"
            }
        ],
        "emoji": "🇺🇲",
        "emojiU": "U+1F1FA U+1F1F2"
    },
    {
        "id": 235,
        "name": "Uruguay",
        "iso3": "URY",
        "iso2": "UY",
        "phone_code": "598",
        "capital": "Montevideo",
        "currency": "UYU",
        "native": "Uruguay",
        "region": "Americas",
        "subregion": "South America",
        "timezones": [
            {
                "zoneName": "America\/Montevideo",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "UYT",
                "tzName": "Uruguay Standard Time"
            }
        ],
        "emoji": "🇺🇾",
        "emojiU": "U+1F1FA U+1F1FE"
    },
    {
        "id": 236,
        "name": "Uzbekistan",
        "iso3": "UZB",
        "iso2": "UZ",
        "phone_code": "998",
        "capital": "Tashkent",
        "currency": "UZS",
        "native": "O‘zbekiston",
        "region": "Asia",
        "subregion": "Central Asia",
        "timezones": [
            {
                "zoneName": "Asia\/Samarkand",
                "gmtOffset": 18000,
                "gmtOffsetName": "UTC+05:00",
                "abbreviation": "UZT",
                "tzName": "Uzbekistan Time"
            },
            {
                "zoneName": "Asia\/Tashkent",
                "gmtOffset": 18000,
                "gmtOffsetName": "UTC+05:00",
                "abbreviation": "UZT",
                "tzName": "Uzbekistan Time"
            }
        ],
        "emoji": "🇺🇿",
        "emojiU": "U+1F1FA U+1F1FF"
    },
    {
        "id": 237,
        "name": "Vanuatu",
        "iso3": "VUT",
        "iso2": "VU",
        "phone_code": "678",
        "capital": "Port Vila",
        "currency": "VUV",
        "native": "Vanuatu",
        "region": "Oceania",
        "subregion": "Melanesia",
        "timezones": [
            {
                "zoneName": "Pacific\/Efate",
                "gmtOffset": 39600,
                "gmtOffsetName": "UTC+11:00",
                "abbreviation": "VUT",
                "tzName": "Vanuatu Time"
            }
        ],
        "emoji": "🇻🇺",
        "emojiU": "U+1F1FB U+1F1FA"
    },
    {
        "id": 238,
        "name": "Vatican City State (Holy See)",
        "iso3": "VAT",
        "iso2": "VA",
        "phone_code": "379",
        "capital": "Vatican City",
        "currency": "EUR",
        "native": "Vaticano",
        "region": "Europe",
        "subregion": "Southern Europe",
        "timezones": [
            {
                "zoneName": "Europe\/Vatican",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "emoji": "🇻🇦",
        "emojiU": "U+1F1FB U+1F1E6"
    },
    {
        "id": 239,
        "name": "Venezuela",
        "iso3": "VEN",
        "iso2": "VE",
        "phone_code": "58",
        "capital": "Caracas",
        "currency": "VEF",
        "native": "Venezuela",
        "region": "Americas",
        "subregion": "South America",
        "timezones": [
            {
                "zoneName": "America\/Caracas",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "VET",
                "tzName": "Venezuelan Standard Time"
            }
        ],
        "emoji": "🇻🇪",
        "emojiU": "U+1F1FB U+1F1EA"
    },
    {
        "id": 240,
        "name": "Vietnam",
        "iso3": "VNM",
        "iso2": "VN",
        "phone_code": "84",
        "capital": "Hanoi",
        "currency": "VND",
        "native": "Việt Nam",
        "region": "Asia",
        "subregion": "South-Eastern Asia",
        "timezones": [
            {
                "zoneName": "Asia\/Ho_Chi_Minh",
                "gmtOffset": 25200,
                "gmtOffsetName": "UTC+07:00",
                "abbreviation": "ICT",
                "tzName": "Indochina Time"
            }
        ],
        "emoji": "🇻🇳",
        "emojiU": "U+1F1FB U+1F1F3"
    },
    {
        "id": 243,
        "name": "Wallis And Futuna Islands",
        "iso3": "WLF",
        "iso2": "WF",
        "phone_code": "681",
        "capital": "Mata Utu",
        "currency": "XPF",
        "native": "Wallis et Futuna",
        "region": "Oceania",
        "subregion": "Polynesia",
        "timezones": [
            {
                "zoneName": "Pacific\/Wallis",
                "gmtOffset": 43200,
                "gmtOffsetName": "UTC+12:00",
                "abbreviation": "WFT",
                "tzName": "Wallis & Futuna Time"
            }
        ],
        "emoji": "🇼🇫",
        "emojiU": "U+1F1FC U+1F1EB"
    },
    {
        "id": 244,
        "name": "Western Sahara",
        "iso3": "ESH",
        "iso2": "EH",
        "phone_code": "212",
        "capital": "El-Aaiun",
        "currency": "MAD",
        "native": "الصحراء الغربية",
        "region": "Africa",
        "subregion": "Northern Africa",
        "timezones": [
            {
                "zoneName": "Africa\/El_Aaiun",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "WEST",
                "tzName": "Western European Summer Time"
            }
        ],
        "emoji": "🇪🇭",
        "emojiU": "U+1F1EA U+1F1ED"
    },
    {
        "id": 245,
        "name": "Yemen",
        "iso3": "YEM",
        "iso2": "YE",
        "phone_code": "967",
        "capital": "Sanaa",
        "currency": "YER",
        "native": "اليَمَن",
        "region": "Asia",
        "subregion": "Western Asia",
        "timezones": [
            {
                "zoneName": "Asia\/Aden",
                "gmtOffset": 10800,
                "gmtOffsetName": "UTC+03:00",
                "abbreviation": "AST",
                "tzName": "Arabia Standard Time"
            }
        ],
        "emoji": "🇾🇪",
        "emojiU": "U+1F1FE U+1F1EA"
    },
    {
        "id": 246,
        "name": "Zambia",
        "iso3": "ZMB",
        "iso2": "ZM",
        "phone_code": "260",
        "capital": "Lusaka",
        "currency": "ZMW",
        "native": "Zambia",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "timezones": [
            {
                "zoneName": "Africa\/Lusaka",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "CAT",
                "tzName": "Central Africa Time"
            }
        ],
        "emoji": "🇿🇲",
        "emojiU": "U+1F1FF U+1F1F2"
    },
    {
        "id": 247,
        "name": "Zimbabwe",
        "iso3": "ZWE",
        "iso2": "ZW",
        "phone_code": "263",
        "capital": "Harare",
        "currency": "ZWL",
        "native": "Zimbabwe",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "timezones": [
            {
                "zoneName": "Africa\/Harare",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "CAT",
                "tzName": "Central Africa Time"
            }
        ],
        "emoji": "🇿🇼",
        "emojiU": "U+1F1FF U+1F1FC"
    }
]
