import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@fuse/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FusePipesModule } from 'app/core/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { AnswerStatusQuestionComponent } from './answer-status-question.component';
import { BarRatingModule } from 'ngx-bar-rating';

@NgModule({
    declarations: [
        AnswerStatusQuestionComponent
    ],
    imports: [
        CommonModule,
        MaterialModule,
        FlexLayoutModule,
        FusePipesModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        DatePickerModule,
        NgxMatSelectSearchModule,
        BarRatingModule
    ],
    entryComponents: [
        AnswerStatusQuestionComponent
    ],
    exports: [
        AnswerStatusQuestionComponent
    ]
})

export class AnswerStatusQuestionModule {

}