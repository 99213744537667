export const locale = {
    lang: 'en',
    data: {
        'TABS': {
        },
        'Message': {
            'MoveMessage': 'You can not move project workhours to the other employees',
            'Add': 'Added successfully',
            'Add_Error': 'No record found on Vagttider or Vagtplan table, please contact to administrator.',
            'Updated': 'Updated successfully',
            'Failed': 'Operation failed , try later',
            'PreventMoveToHolidays': 'you cannot move work hours on holidays',
            'PreventMoveToPastdays': 'you cannot move work hours on past date',
            'MoveProject': "Do you want to move project",
            'MoveLeave': "Do you want to move ",
            'From': 'From',
            'To': 'to',
            'MovedSuccessfully': 'Moved Successfully',
            'MoveError': 'Error while moving plan',
            'CopySuccessfully': 'Copy Successfully,',
            'CopyError': "Error while copying.",
            'RefreshPlanningMessage': 'You have received a new planning on  @StartDate to @EndDate need to refresh screen?',
        },
        'Buttons': {
            'Plan': 'Plan',
            'Nonworkinghours': 'Nonworking',
            'View': 'View',
            'Removenonworkinghours': 'Remove nonworking hours',
            'Project': 'Projects',
            'Inquiry': 'Inquiries',
            'DefaultCoworker': 'Default Coworker',
            'Question':'Question',
            'VehicleInfo': 'Nano link',
            'Replace':'Replace',
            'MultiPlanning':'Multi Planning',
            'AddDefaultCoworker': 'Add Default Coworker',
        },
        'Lable': {
            'Timer': 'Timer',
            'HoursLeft': 'Hours Left',
            'Lift': 'Left',
            'Stigemand': 'Riseman',
            'RequisitionText': 'Task text',
            'No': 'No',
            'Yes': 'Yes',
        },
        'FIELDS': {
            'StartDate': 'Start Date',
            'EndDate': 'End Date',
            'NoOfEmployee': 'Number of employees',
            'MaterialPurchasePriseTotal': 'Sum Stock',
            'TotalProjectDaysLeft': 'Project Remaining Days',
            'TotalProject': 'Total Projects',
            'TotalProjectRemainingHours': 'Project Remaining Hours',
            'TotalPlanningRemainingHours': 'Planning Remaining Hours',
            'TotalPlanningDaysLeft': 'Planning Remaining Days',
            'TotalPlanningHours': 'Total Planning Hours',
            'CoWorker': 'Co-Workers',
            'WorkhoursType': 'Workhours type',
            'nonStatus': 'Status',
            'ShowHourDetails': 'Hourly details',
            'ShowTime': 'Show Time',
            'TotalProjectPrice': 'Total Project Price'
        },
        'selectedWorkHourStatus': {
            'value1': 'Vacation',
            'value2': 'Sick',
            'value3': 'Holiday of the work ',
            'value4': 'Holiday with payment',
            'value5': 'Holiday without payment',
            'value6': 'School or learning',
            'value7': 'No payment for extra work but holiday for it ',
            'value8': 'Other things',
            'value9': 'Offer'
        },
        'selectedWorkHoursTypeOption': {
            'value1': 'Both',
            'value2': 'Working hours',
            'value3': 'Non working hours',
        },
        'selectedWorkHoursApproveLeaveOption': {
            'value1': 'Apply',
            'value2': 'Approved',
            'value3': 'Rejected',
        },
        'FILTERS': {
            'IndoorOutDoor': 'Indoor,Outdoor,Spray Painting,Reclamation*',
            'SEARCH': 'Search',
            'SORTING': 'Sort By',
            'SORTING_STATUS': 'Status',
            'Active': 'Include All Inactive',
            'BADGE': 'Inactive',
            'Deleted': 'Show ONLY deleted cases',
            'All': 'All',
            'Lift/Stigemand': 'Lift/Stigemand',
            'Indoor/Outdoor': 'Indoor/Outdoor',
            'Lift/Stigmand': 'Lift/Stigmand',
            'Ind/Ud': 'Ind/Ud',
            'MtoM': 'M to M',
            'Note': 'Note',
            'Offers': 'Offers',
            'Show60DayOld': 'Show 60 Days old',
            'ShowHourDetails': 'Hourly details',
            'TotalProject': 'Total Projects',
            'TotalProjectRemainingHours': 'Project Remaining Hours',
            'TotalProjectDaysLeft': 'Project Remaining Days',
            'LastDayWorkingHour': 'Last Day Working Hours',
            'ShowTime': 'Show Time',
            'HourlyWage': 'Hourly rate',
        },
    }
};
