import { NgModule } from '@angular/core';
import { MatDividerModule, MatListModule, MatSlideToggleModule } from '@angular/material';

//import { FuseSharedModule } from '@fuse/shared.module';
import { SharedModule } from '../../../core/modules/shared.module';

import { QuickPanelComponent } from 'app/layout/components/quick-panel/quick-panel.component';
import { QuickPanelService } from './quick-panel.service';

@NgModule({
    declarations: [
        QuickPanelComponent
    ],
    imports: [
        MatDividerModule,
        MatListModule,
        MatSlideToggleModule,
        SharedModule,
    ],
    exports: [
        QuickPanelComponent
    ],
    providers: [
        QuickPanelService
    ]
})
export class QuickPanelModule {
}
