export const locale = {
    lang: 'en',
    data: {
        'TITLE': {
            'Header': 'Salary calculation : Bluegarden',
            'HeaderForLable': 'Setting up the transfer of salary calculation to Bluegarden in the system',
        },
        'FIELDS': {
            'EmployerNo': 'Employer no',
            'SalaryPeriod14Days': 'Salary period -14 days',
            'PayrollMonth': 'Payroll month',
            'Group': 'Group',
            'HourlyFigures': 'Hourly figures',
            'HolidayEligibleSalary':'Holiday eligible salary',
            'OvertimeReplacement':'Overtime & replacement',
            'SalaryAdvance':'Salary advance',
            'SeniorityAllowance':'Seniority allowance',
            'Km':'km',
            'KmRate':'Km. rate',
            'GDayNumber':'G-day number',
            'GDayTotal':'G-day total',
            'HolidayDays':'Holiday days',

         },
        'VALIDATIONS': {
        },
        'MESSAGES': {
            'Saved': 'Saved successfully.',
        },
        'BUTTONS': {
            'Save': 'Save',
        }
    }
};