export const locale = {
  lang: 'en',
  data: {
    'FIELDS': {
      'StartDate': 'Start Date ',
      'EndDate': 'End Date ',
      'Coworker': 'Select Co-worker',
      'Search-worker': 'Search Co-worker',
      'Not-Found-Worker': 'Co-worker not found',
      'NO_RECORD_FOUND': 'No Workhours found',
      '2WeekSalary': '14 days salaried Employee',
      'Monthly': 'Monthly Salary Employee',
      'isWithStatus': 'Is With Status',
      'nonStatus': 'Status',
      'GenerateFutureWorkHoursReport': 'Generate report in future',
      'GenerateWorkingCowrker':'Generate working coworker?'
    },
    'BUTTONS': {
      'BtnCancel': 'Cancel',
      'BtnPDF': 'Work sheet',
      'All': 'Total',
      'BtnText': 'Txt File',
      'SalarySlip':'Salary Slip',
      'ClosePDF':'Close Payment'
    },
    'FILTERS': {
      'All': 'All',
    },
    'TAB':{
      'Salary':'Salary',
      'WorkSheet':'WorkSheet'
    },
    'MESSAGES': {
      'Add': 'Added successfully',
      'Payment14DaysConfirmation': 'Are you sure you want to Close Payment of Employee 14 Days Payment StartDate @startdate EndDate @endDate ?',
      'Payment30DaysConfirmation': 'Are you sure you want to Close Payment of Employee 30 Days Payment StartDate @startdate EndDate @endDate ?',

    },
  }
}

