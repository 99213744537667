import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@fuse/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FusePipesModule } from 'app/core/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { RoomAreaComponent } from "app/main/shared-component/offers/rooms/area/room-area.component";
import { RoomListComponent } from "app/main/shared-component/offers/rooms/list/room-list.component";
import { NgxMaskModule } from "ngx-mask";
import { NgxCurrencyModule } from "ngx-currency";
import { CustomCurrencyMaskConfig } from "app/core/constant/CurrencyMaskConfiguartion";
import { MatBadgeModule } from '@angular/material/badge';

@NgModule({
    declarations: [
        RoomAreaComponent,
        RoomListComponent
    ],
    imports: [
        CommonModule,
        MaterialModule,
        FlexLayoutModule,
        FusePipesModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        DatePickerModule,
        NgxMatSelectSearchModule,
        NgxMaskModule.forRoot(),
        NgxCurrencyModule.forRoot(CustomCurrencyMaskConfig),
        MatBadgeModule
    ],
    entryComponents: [
        RoomAreaComponent
    ],
    exports: [
        RoomAreaComponent,
        RoomListComponent
    ]
})

export class OffersModule {

}