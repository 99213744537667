import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpParams, HttpClient } from '@angular/common/http';
import { ApiConstant } from '../../../core/constant/ApiConstant';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
    private actionUrl: string;
    constructor(private http: HttpClient) {
        this.actionUrl = ApiConstant.API_URL ;
        //this.actionUrl = "https://localhost:44387/api/v1/Values/5";

    }

    public Login<T>(login: any): Observable<T> {
        return this.http.post<T>(this.actionUrl + 'Account/login', login);
        //return this.http.get<T>(this.actionUrl);

    }
}
