import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from './../../../../@fuse/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { CKEditorComponent } from './ckeditor.component';
import { CKEditorModule } from 'ng2-ckeditor';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        FlexLayoutModule,
        TranslateModule,
        CKEditorModule,
        MaterialModule,
        NgxMatSelectSearchModule
    ],
    providers:[
    ],
    entryComponents: [
        CKEditorComponent,
    ],
    declarations: [CKEditorComponent],
    exports: [
    ]

})
export class CKEditorPopupModule { }
