import { Component, OnInit, Inject } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: "textarea-editor",
    templateUrl: "./textarea-editor.component.html",
    styleUrls: ["./textarea-editor.component.scss"]
})

export class TextareaEditorComponent implements OnInit {
    public editorContent = "";
    constructor(public dialogRef: MatDialogRef<TextareaEditorComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private translationLoaderService: FuseTranslationLoaderService) {
        this.translationLoaderService.loadTranslations(danish, english);
    }

    ngOnInit() {
        this.editorContent = this.data.displayData;
    }

    saveTabData() {
        this.dialogRef.close({ value: this.editorContent, isSave: true });
    }

    colseTextEditor() {
        this.dialogRef.close({ value: this.editorContent, isSave: false });
    }
}