import { Injectable } from '@angular/core';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiConstant } from 'app/core/constant/ApiConstant';
import { Observable } from 'rxjs/Observable';
import { CompanyInfoSetting } from 'app/main/model/CompanyInfo';
import { RequestForCarPositions } from 'app/main/model/RequestBase';

@Injectable({
  providedIn: 'root'
})

export class CarInformationsService {
  private actionUrl: string;

  constructor(private http: HttpClient) {
      this.actionUrl = ApiConstant.API_URL + ApiConstant.CARINFORMATIONS + "/";
  }

  public Get<T>(RequestParam: any): Observable<T> {
      let search = this.GetParams(RequestParam);
    return this.http.get<T>(this.actionUrl, {
      params: search
    });
  }
  public GetCarInfoById<T>(Id: any): Observable<T> {
    return this.http.get<T>(this.actionUrl + Id);
  }

  public Update<T>(id: any, reqObj: any): Observable<T> {
      return this.http.put<T>(this.actionUrl + id, reqObj);
  }

    public Add<T>(reqObj: any): Observable<T> {
      return this.http.post<T>(this.actionUrl, reqObj);
  }

  public GetParams(param) {
    let params: HttpParams = new HttpParams();
    for (var key in param) {
      if (param.hasOwnProperty(key)) {
        let val = param[key];
        params = params.append(key, val);
      }
    }
    return params;
  }

  public Delete<T>(id: any): Observable<T> {
    return this.http.delete<T>(this.actionUrl + id);
  }

  public GetVehicleByItemNumber<T>(number: number): Observable<T> {
    return this.http.get<T>(this.actionUrl + "search/number/" + number);
  }

  public addUpdateNotesForCarInfo<T>(id, name, info: CompanyInfoSetting): Observable<T> {
    return this.http.put<T>(this.actionUrl + id + "/" + name + "/CarFileInfoTab", info);
  }
  public GetCarInfoNotes<T>(id, name): Observable<T> {
    return this.http.get<T>(this.actionUrl + id + "/" + name + "/CarFileInfoTab");
  }

  public GetCarPositions<T>(requestForCarPositions: RequestForCarPositions): Observable<T> {
    let search = this.GetParams(requestForCarPositions);
    return this.http.get<T>(this.actionUrl + "GetCarPositions", {
        params: search
    });
}
}