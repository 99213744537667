import { Component, Input, OnInit } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { MatDialog, MatOption, MatSlideToggleChange } from '@angular/material';
import { AuthService } from 'app/core/services/auth.service';
import { SettingsService } from '../../settings.service';
import { ResponseBase } from "app/main/model/ResponseBase";
import { Settings } from "app/main/model/Settings";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { CoWorker } from "app/main/model/CoWorker";
import { RequestCoWorker } from "app/main/model/RequestBase";
import { CoWorkersService } from "app/main/pages/coWorker/coWorkers.service";
import { NotificationsService } from "angular2-notifications";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "send-offer-sms-configuration",
    templateUrl: "./send-offer-sms-configuration.component.html",
    styleUrls: ["./send-offer-sms-configuration.component.scss"]
})

export class SendOfferSMSConfigurationComponent implements OnInit {
    @Input("SendOfferSMSToCustomerSetting") SendOfferSMSToCustomerSetting: Settings;
    showSpinner: boolean = false;
    sendOfferSMSConfigurationForm: FormGroup;
    isSubmitted = false;
    coWokers: CoWorker[] = [];
    IsGettingCoWokers: boolean = false;
    sendOfferSMSConfigurationObj: Settings;
    settingMasterValues: any[] = [];
    sendSMSConfigObj: any = {};

    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private fb: FormBuilder,
        private coWorkersService: CoWorkersService,
        private authService: AuthService,
        private settingsService: SettingsService,
        private translate: TranslateService,
        private notificationsService: NotificationsService,
        private dialog: MatDialog) {
        this.translationLoaderService.loadTranslations(danish, english);
    }

    ngOnInit() {
        this.settingMasterValues = Object.assign([], JSON.parse(localStorage.getItem("settingMasterValues")));
        this.sendOfferSMSConfigurationForm = this.fb.group({
            SMSText: [null, [Validators.required]],
            IsEnable: [true]
        });
        this.setSettings();
    }

    onSubmit() {
        this.sendOfferSMSConfigurationForm.markAllAsTouched();
        this.isSubmitted = true;
        if (this.sendOfferSMSConfigurationForm.valid) {
            this.changeSettings()
        }
    }


    IsEnableSendSMSText(event: MatSlideToggleChange) {
        this.changeSettings();
    }

    setSettings() {
        if (this.SendOfferSMSToCustomerSetting && this.SendOfferSMSToCustomerSetting != null) {
            this.sendOfferSMSConfigurationObj = this.SendOfferSMSToCustomerSetting;
            this.sendSMSConfigObj = JSON.parse(this.SendOfferSMSToCustomerSetting.value);
            this.sendOfferSMSConfigurationForm.patchValue(this.sendSMSConfigObj);
            this.changeInLocalStorage(this.sendOfferSMSConfigurationObj.id, this.sendOfferSMSConfigurationObj)
        }
    }

    setSettingsAfterUpdate() {
        this.settingsService.GetSettingsWithTemplateValue<ResponseBase>("Notification", 'SendOfferSMSToCustomer', null).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.result && response.result.myResponse.length > 0) {
                    this.sendOfferSMSConfigurationObj = response.result.myResponse[0];
                    this.sendSMSConfigObj = JSON.parse(this.SendOfferSMSToCustomerSetting.value);
                    this.sendOfferSMSConfigurationForm.patchValue(this.sendSMSConfigObj);
                    this.changeInLocalStorage(this.sendOfferSMSConfigurationObj.id, this.sendOfferSMSConfigurationObj)
                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    changeSettings() {
        this.showSpinner = true;
        let data = {
            'SMSText': this.sendOfferSMSConfigurationForm.value.SMSText.toString(),
            'IsEnable': this.sendOfferSMSConfigurationForm.value.IsEnable,
        }
        this.sendSMSConfigObj = JSON.stringify(data)
        this.sendOfferSMSConfigurationObj.value = this.sendSMSConfigObj;
        this.settingsService.UpdateSettingValue<ResponseBase>(this.sendOfferSMSConfigurationObj).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.result) {
                    //this.sendOfferSMSConfigurationObj = response.result;
                    if (this.sendOfferSMSConfigurationForm.value.IsEnable)
                        this.setSettingsAfterUpdate();
                    this.translate.get("MESSAGES.Saved").subscribe({
                        next: (res) => {
                            this.notificationsService.create(res);
                        },
                        error: err => { }
                    });
                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinner = false;
                this.isSubmitted = false;
            }
        });
    }

    changeInLocalStorage(ID, new_obj) {
        if (this.settingMasterValues.find(obj => obj.id == ID)) {
            this.settingMasterValues.forEach((old_obj, index) => {
                if (old_obj.id == ID) {
                    this.settingMasterValues[index] = new_obj
                }
            })
        } else {
            this.settingMasterValues.push(new_obj)
        }
        localStorage.setItem("settingMasterValues", JSON.stringify(this.settingMasterValues));
    }
}