export const locale = {
    lang: 'en',
    data: {
        'Headers': {

        },
        'FIELDS': {
            'Yes': 'Yes',
            'No': 'No',
            'Copy': 'Copy'
        }
    }
}