export const locale = {
    lang: 'ds',
    data: {
        'TITLE': {
        },
        'FIELDS': {
            'CoworkerRate': 'Kollega pris',
        },
        'Message': {

        },
        'FILTERS': {
        },
        'Buttons': {
            'Save':'Gem',
        }
    }
};