export const locale = {
    lang: 'en',
    data: {
        'CUSTOMER': {
            'TITLE': 'Customer',
            'DESC': 'Hello Customer!',
            'TabBasicDetail': 'Basic Detail',
            'TabContactDetail': 'Contact Detail',
            'TabOrderDetail': 'Project',
            'TabFiles': 'Files',
            'Log': 'Log',
        },
        'TABS': {
            'TabBasicDetail': 'Basic Detail',
            'Log': 'Log',
            'Files': 'Files',
            'Note': 'Note',
        },
        'BUTTONS': {
            'SHOWCUSTOMER': 'Show Cust.',
            'NEWCUSTOMER': 'New Customer',
            'BACK': 'Cancel',
            'BTNSAVE': 'Save',
            'BTNCONFIRM': 'Confirm',
            'CREATE_FOLDER': 'Create Folder',
            'DOWNLOAD': 'Download',
            'DELETE': 'Delete',
            'NEWProject': 'New Project',
            'MatchCustomer': 'Match Customer',
            'InActive': 'Set inquiry as inactive',
            'Active': 'Set inquiry as active',
            'UnassignCustomer': 'Remove Cust.',
            'CreateProject': 'Create Project',
            'GoToProject': 'Go To Project',
            'CreateOffer': 'Create Offer',
            'GoToOffer': 'Go To Offer',
        },
        'FIELDS': {

            'CustomerNo': 'Customer No.',
            'Name': 'Name',
            'Email': 'E-mail',
            'PhoneNo': 'Phone No.',
            'Address': 'Address',
            'Address-line-2': 'Address(Line 2)',
            'City': 'City',
            'Zipcode': 'Zipcode',
            'Position': 'Position',
            'Comments': 'Comments  [ Optional ]',
            'OrderName': 'Order name',
            'EnterLocation': 'Enter your location',
            'Active': 'Set as inactive',
            'From': 'From',
            'To': 'to',
            'colorCode': 'Color code',
            'PersonnelPurchas': 'Staff Purchase',
            'EmailInvoice': 'Invoice Email',
            'Status': 'Status',
            'CoworkerComment': 'Coworker Comment',
            'Number': 'Number',
            'NoCustomerMap': 'Intet Kundekort.',
            'OfferInformation': 'Offer Information',
            'CustomerInformation': 'Customer Information',
            'ProjectInformation': 'Project Information',
            'MatchCustomerInformation': '** Match customer\'s information based on name, email, phone.',
            'WhereInquiryCome': 'Where does the query come from',
            'received': 'Received Date & Time',
            'NoMatchCustomerInformation': 'No customer\'s match detail found',
            'SORTING_STATUS': 'Status',
            'All': 'All',
            'IndoorOutDoor': 'Indoor,Outdoor,Spray Painting,Reclamation*',
            'CoWorkers': 'Co-Workers',
            'CommentPlaceholder':'Comments [Optional]'
        },
        'Headers': {
            'NewCustomer': 'New Customer Form',
            'CustomerList': 'Customer List',
            'addressModalTitle': 'Please confirm your address details',
            'NoFileSelected': 'No File Selected',
            'ChooseFile': 'Choose File',
            'FOLDEREMPTY': 'This folder is empty',
            'LogTabSRNO': 'SR NO',
            'LogTabRequisitionnumber': 'Requisition number',
            'LogTabChangedon': 'Changed on',
            'LogTabChangedtime': 'Changed time',
            'LogTabChangedfrom': 'Changed from',
            'LogTabChangedto': 'Changed to',
            'Desc': 'Descripion',
            'CustomerDetail': 'Are you sure you want to keep the basic details of the selected customer ?'
        },
        'VALIDATIONS': {
            'ContactNumber': 'Enter Contact No',
            'TEXT': 'Enter Value',
            'ONLY40CHARACTER': 'Maximum length is 40 characters.',
            //'Email': 'Enter Valid Email',
            'Email': 'Enter Email',
            'ValidEmail':'Enter Valid Email Address',
            'PHONENO': 'Enter valid number',
            'BEHOLDING': 'Enter Holdings',
            'ITEMNUMBEREXIST': 'Number is already exist',
            'ITEMNUMBERMIN': 'Enter Valid Number',
            'ITEMNUMBERMAX': 'Enter Valid Number',
            'BEHOLDINGMIN': 'Enter Valid Number',
            'BEHOLDINGMAX': 'Enter Valid Number',
            'colorCode': 'Enter Valid ColorCode',
            'IndoorOutdoorStatus': 'Select Indoor,Outdoor,Spray Painting,Reclamation*'
        },
        'MESSAGES': {
            'NORECORD': 'No Record Found',
            'NoOrderAvailbale': 'No projects available',
            'DropBoxApiNotResponding': 'Dropbox not responding please try after some time',
            'FileConflict': 'File already exists',
            'FolderConflict': 'Folder name already exists',
            'Created': 'Created successfully',
            'Updated': 'Updated successfully',
            'Deleted': 'Deleted successfully',
            'Failed': 'Operation failed , try later',
            'EmailSent': 'Email sent successfully',
            'NoProjectAdded': 'No project added.',
            'NoOfferAdded': 'No offer added.',
        },
        'whereDoesTheInquiryComeFrom': {
            'value1': 'WWW (Internet)',
            'value2': 'Website',
            'value3': 'Facebook',
            'value4': 'Recommendation',
            'value5': 'Event',
            'value6': 'Manual',
            'value7': 'Telephone',
        },
        // 'selectedValueStatus': {
        //     'value0': 'Registered',
        //     'value1': 'Process',
        //     'value2': 'Scheduled visit',
        //     'value3': 'Ready to boot',
        //     'value4': 'Offers sent',
        //     'value5': 'Offer approved',
        //     'value6': 'Planned',
        //     'value7': 'Offer rejected',
        //     'value8': 'Started',
        //     'value9': 'Performed',
        //     'value10': 'Billed',
        //     'value11': 'Pending',
        //     'value12': 'Ready billing',
        // },
        'selectedValueIndoorOutdoorStatus': {
            'value1': 'Indoor ',//Man in a room
            'value2': 'Outdoor', //Sun
            'value3': 'Spray Painting',//spraypaint
            'value4': 'Complaint', //Reklamation
            'value5': 'Staff purchase', //drawing of painter
            'value6': 'Store purchase' //Nordsjø logo
        },
        'TOOTIPORTITLE':{
            'ViewCustomer':'View Customer',
            'SendSMS':'Send SMS To Customer',
            'SendEmail':'Send Email To Customer',
            'DeleteInquiry':'Delete Inquiry',
            'SelectCustomer':'Select Customer',
        }
    }
};


