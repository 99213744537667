export const locale = {
    lang: 'ds',
    data: {
        'STATUS': {
            'Created': 'lavet',
            'Updated': 'Opdateret',
            'Deleted': 'Slettet',
            'Inactive': 'Inaktiv',
            'Active': 'Aktiv',
            'Statuschane': 'lave om',
            'FileUploaded': 'Fil uploadet',
            'FileDeleted': 'Fil slettet',
        },
        'TITLE': {
            'CustomerPagination': 'Antal Log Pr. side',
        },
        'BUTTONS': {
            'BACK': 'Gå tilbage',
            'BTNSAVE': 'Gem',
        },
        'FIELDS': {
            'SRNO': 'Sr nr.',
            'Status': 'Status',
            'Number': 'Nummer',
            'Name': 'Navn',
            'Createdby': 'Oprettet Af',
            'CreatedDate': 'Oprettet Dato',
            'ModifyBy': 'Modificere Af',
            'ModifyDate': 'Modificere Dato',
            'CoworkerName': 'Medarbejder',
            'Date': 'Dato',
            'Description': 'Tekst',
            'Action': 'Handling',
            'ModuleName': 'Modul',
            'ByCoworker': 'Ved Medarbejder',
            'ByCustomer': 'Ved Kunde',
            'RequisitionAddress':'Rekvisition adresse',
            'ProjectAddress':'Sag adresse',
            'Recipient':'Modtager'
        },
        'Headers': {
        },
        'Module': {
            'Project': 'Projekter',
            'Requisition': ' Opgave',
            'RequisitionMaterial': 'Opgave Materialer',
            'RequisitionCoworker': 'Opgave Medarbejdere',
            'Offer': 'Tilbud',
            'Inquiry': 'Forespørgsler',
            'Email': 'Email',
            'StatusChangeAnswer': 'Status Lave om Svar',
            'OfferRoom': 'Tilbud rum',
            'File': 'Fil',
            'BookMark':'Bemærk',
            // 'Offer':'Tilbud',
            'MtoM':'M til M',
        },
        'MESSAGES': {
            'NORECORD': 'Ingen registrering fundet',
            'PROJECTCREATED': '{@CoworkerName} har oprettet ny rekord.',
            'PROJECTUPDATED': '{@CoworkerName} har opdateret rekord.',
            'PROJECTDELETED': '{@CoworkerName} har slettet rekord.',
            'PROJECTINACTIVE': '{@CoworkerName} har inaktiv rekord.',
            'PROJECTACTIVE': '{@CoworkerName} har aktiv rekord.',
            'STATUSCHANGED': '{@CoworkerName} har ændret opgave status.',
            'STATUSCHANGEDBYCUSTOMER': 'Kunden besvarer status spørgsmål',
            'FILEUPLOADED': '{@CoworkerName} har uploadet \'{@FileName}\' fil.',
            'FILEDELETED': '{@CoworkerName} har fjernet \'{@FileName}\' fil.',
            'CREATED': 'Rekord oprettet',
            'UPDATED': 'Rekord opdateret'
        }
    }
};
