import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { RequestForQualityTestTemperaturList, RequestProjectOfferRoom } from 'app/main/model/RequestBase';
import { OfferHumidityandTemperature } from 'app/main/model/OfferQualityTestCategory';
import { environment } from 'environments/environment';
import { CompanyInfoService } from 'app/main/pages/companyInfo/companyInfo.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material';
import { QualityTestService } from '../../quality-test.service';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { ProjectsOfferRoom } from 'app/main/model/ProjectOffer';
import { ProjectofferService } from 'app/main/pages/project-offer/project-offer.service';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';

@Component({
    selector: 'app-quality-test-humidity-temperature',
    templateUrl: './humidity-temperature.component.html',
    styleUrls: ['./humidity-temperature.component.scss'],
})
export class HumidityTemperatureComponent implements OnInit {
    @Input() ModuleID: string = "";
    @Input() ProjectID: string = "";
    @Input() ModuleName: string = "";
    @Input() ModuleRoomSelectID: string = "";
    @Input() OfferQualityTestCategoryID: string = "";
    selectedOfferRooms: any = [];
    ProjectsOfferRooms: ProjectsOfferRoom[];
    selectOfferRoomAllOption: boolean = false;
    showSpinner: boolean = false;
    envirnmentURL: string = "";
    listRequest: RequestForQualityTestTemperaturList = new RequestForQualityTestTemperaturList();
    humidityandTemperatureList: OfferHumidityandTemperature[] = [];
    humidityandTemperature: OfferHumidityandTemperature = new OfferHumidityandTemperature();
    displayPagination: boolean;
    pagination: any;
    limitNumber: any;
    HumidityAndTemperatureDescription: string = '';

    constructor(private _route: ActivatedRoute,
        private translationLoader: FuseTranslationLoaderService,
        private translate: TranslateService,
        private _router: Router,
        private notificationsService: NotificationsService,
        private projectofferService: ProjectofferService,
        private qualityTestService: QualityTestService,
        public companyInfoService: CompanyInfoService,
        public dialog: MatDialog,
        private sanitizer: DomSanitizer) {
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
        this.envirnmentURL = environment.url;
        this.listRequest.Index = 1;
        this.listRequest.Limit = 100;
        this.humidityandTemperature.projectID = this.ProjectID;
        this.listRequest.projectID = this.ProjectID;
        this.humidityandTemperature.offerID = this.ModuleID;
        this.listRequest.offerID = this.ModuleID;
        this.listRequest.IsDeleted = false;
        this.listRequest.IsActive = true;
        this.listRequest.qualityTestCategoryID = this.OfferQualityTestCategoryID;
        this.GetHumidityandTemperatureList();
        var requestProjectOfferRoom: RequestProjectOfferRoom = new RequestProjectOfferRoom();
        requestProjectOfferRoom.Limit = 100;
        //requestProjectOfferRoom.OfferId = this.OfferID;
        requestProjectOfferRoom.OfferId = this.ModuleRoomSelectID ? this.ModuleRoomSelectID : null;
        this.humidityandTemperature.offerID = this.ModuleRoomSelectID ? this.ModuleRoomSelectID : null;
        this.getProjectOfferRoomList(requestProjectOfferRoom);
        this.GetCompanyInformation()
    }

    GetCompanyInformation() {
        this.showSpinner = true;
        this.companyInfoService.GetCompanyInformationData<ResponseBase>().subscribe({
            next: (response) => {
                if (response) {
                    this.HumidityAndTemperatureDescription = response.result['humidityAndTemperatureDescription'];
                }
                else {
                    this.HumidityAndTemperatureDescription = '';
                }
            },
            error: err => {
                this.showSpinner = false;
                this.HumidityAndTemperatureDescription = '';
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    showHTDesc() {
        return this.sanitizer.bypassSecurityTrustHtml(this.HumidityAndTemperatureDescription);
    }

    GetHumidityandTemperatureList() {
        this.showSpinner = true;
        this.qualityTestService.GetHumidityandTemperatureList(this.listRequest).subscribe({
            next: (response) => {
                if (response) {
                    this.humidityandTemperatureList = response.result;
                    this.pagination = response.pagination;
                    this.displayPagination = true;

                }
                else {
                    this.humidityandTemperatureList = [];
                    this.displayPagination = false;
                }
            },
            error: err => {
                this.showSpinner = false;
                this.humidityandTemperatureList = [];
                this.displayPagination = false;
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    getProjectOfferRoomList(model) {
        this.showSpinner = true;
        this.projectofferService.getProjectOfferRoomList<ResponseBase>(model)
            .subscribe((response: ResponseBase) => {
                this.showSpinner = false;
                this.ProjectsOfferRooms = [];
                if (response != null) {
                    this.ProjectsOfferRooms = response.result.myResponse;
                }
            },
                error => (err) => {
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });
                });
    }

    RoomSelectAllOption() {
        this.selectOfferRoomAllOption = !this.selectOfferRoomAllOption;
        this.selectedOfferRooms = [];
        if (this.selectOfferRoomAllOption) {
            this.selectedOfferRooms.push("0");
            for (var i = 0; i < this.ProjectsOfferRooms.length; i++) {
                this.selectedOfferRooms.push(this.ProjectsOfferRooms[i].id);
            }
        }
    }

    AddHumidityandTemperature() {
        this.showSpinner = true;
        this.humidityandTemperature.offersRoomID = this.selectedOfferRooms.join(",");
        this.humidityandTemperature.qualityTestCategoryID = this.OfferQualityTestCategoryID;
        if (this.humidityandTemperature.id) {
            this.qualityTestService.UpdateHumidityandTemperature(this.humidityandTemperature).subscribe({
                next: (response) => {
                    if (response) {
                        this.translate.get("MESSAGE.Updated").subscribe({
                            next: (res) => {
                                this.notificationsService.success(res);
                            },
                            error: err => {
                            }
                        });
                        this.humidityandTemperature = new OfferHumidityandTemperature();
                        this.humidityandTemperature.projectID = this.ProjectID;
                        this.humidityandTemperature.offerID = this.ModuleID;
                        this.selectedOfferRooms = [];
                        this.listRequest.Index = 1;
                        this.listRequest.Limit = 100;
                        this.GetHumidityandTemperatureList();
                    }
                },
                error: err => {

                },
                complete: () => {
                    this.showSpinner = false;
                }
            });
        } else {
            this.qualityTestService.AddHumidityandTemperature(this.humidityandTemperature).subscribe({
                next: (response) => {
                    if (response) {
                        this.translate.get("MESSAGE.Added").subscribe({
                            next: (res) => {
                                this.notificationsService.success(res);
                            },
                            error: err => {
                            }
                        });
                        this.humidityandTemperature = new OfferHumidityandTemperature();
                        this.humidityandTemperature.projectID = this.ProjectID;
                        this.humidityandTemperature.offerID = this.ModuleID;
                        this.selectedOfferRooms = [];
                        this.listRequest.Index = 1;
                        this.listRequest.Limit = 100;
                        this.GetHumidityandTemperatureList();
                    }
                },
                error: err => {

                },
                complete: () => {
                    this.showSpinner = false;
                }
            });
        }
    }

    receivePaginatonEvent(event) {
        this.listRequest.Index = event.pageIndex;
        this.listRequest.Limit = event.pageSize;
        this.limitNumber = event.pageSize;
        this.listRequest.IsDeleted = false;
        this.listRequest.IsActive = true;
        this.GetHumidityandTemperatureList();
    }

    EditHumidityandTemperature(log) {
        this.humidityandTemperature = log;
        this.selectedOfferRooms = log['offersRoomID'].split(',');
    }

    DeleteHumidityandTemperature(id) {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: '20%',
            data: { Header: 'Headers.DeleteConformation' }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.DelHumTemp(id);
            }
        });
    }
    DelHumTemp(Id) {
        this.showSpinner = true;
        this.qualityTestService.DeleteHumidityandTemperature(Id).subscribe({
            next: (response: ResponseBase) => {
                this.translate.get("MESSAGE.Deleted").subscribe({
                    next: (res) => {
                        this.notificationsService.create(res);
                    },
                    error: err => {
                    }
                });
                this.listRequest.Index = 1;
                this.listRequest.Limit = 100;
                this.GetHumidityandTemperatureList();
            },
            error: err => {
                this.translate.get("MESSAGE.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    ResetForm() {
        this.humidityandTemperature = new OfferHumidityandTemperature();
        this.selectedOfferRooms = [];
    }
}
