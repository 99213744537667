export const locale = {
  lang: 'ds',
  data: {
    'FIELDS': {
      'StartDate': 'Start dato ',
      'EndDate': 'Slut dato ',
      'Coworker': 'Vælg Medarbejder',
      'Search-worker': 'Søg Medarbejder',
      'Not-Found-Worker': 'Medarbejder ikke fundet',
      'NO_RECORD_FOUND': 'Ingen arbejdstimer fundet',
      '2WeekSalary': '14 dages lønnede Medarbejder',
      'Monthly': 'Månedslønnede Medarbejder',
      'isWithStatus': 'Er Med Status',
      'nonStatus': 'Status',
      'GenerateFutureWorkHoursReport': 'Generer rapport i fremtiden',
      'GenerateWorkingCowrker':'Generer arbejdende kollega?',
      'ViewNonworkWithPayment':'Se med beløb'
    },
    'BUTTONS': {
      'BtnCancel': 'Gå tilbage',
      'BtnPDF': 'Arbejdsseddel',
      'All': 'Total',
      'BtnText': 'Txt fil',
      'SalarySlip':'Lønseddel',
      'ClosePDF':'Luk betaling',
      'BtnNonWorkhoursPDF':'PDF'

    },
    'FILTERS': {
      'All': 'Alle',
    },
    'TAB':{
      'Salary':'Løn',
      'WorkSheet':'Arbejdsark',
      'NonWorkHours':'Ikke arbejdstid'
    },
    'MESSAGES': {
      'Add': 'Oprettet',
      'Payment14DaysConfirmation': 'Er du sikker på, at du vil lukke betaling af medarbejder 14 dages betaling Startdato @startdate Slutdato @endDate?',
      'Payment30DaysConfirmation': 'Er du sikker på, at du vil lukke betaling af medarbejder 30 dages betaling Startdato @startdate Slutdato @endDate?',
    },
    'selectedWorkHourStatus': {
      'value0': 'Ingen status',
      'value1': 'Ferie ',
      'value2': 'Syg',
      'value3': 'Feriefridag',
      'value4': 'Fridag m.løn',
      'value5': 'Fridag u.løn',
      'value6': 'Kursus/skole',
      'value7': 'Afspadsering',
      'value8': 'Andet',
      'value9': 'Tilbud'
  },
  }
}

