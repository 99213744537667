export const locale = {
    lang: 'ds',
    data: {
        'FIELDS': {
            'Designation': 'Betegnelse',
            'ValideFrom': 'Gyldig Fra',
            'SRNO': 'Sr nr.'
        },
        'BUTTONS': {
            'Save': 'Gem',
            'BACK': 'Tilbage',
            'Reset': 'Nulstil',
            'ShowOnMap': 'Vis på kort',
            'BTNCOMPLETED': 'Færdiggjort',
            'Delete': 'Slet',
        },
        'MESSAGES': {
            'Updated': 'Opdateret succesfuldt.',
            'Failed': 'Mislykkedes',
            'Deleted': 'Slettet med succes',
            'NORECORD': 'Intet optage fundet',
            'Created': 'Oprettet med succes',
            'CompletedNextService': 'Vil du ændre næste service til {Date} ?',
            'DeviceAlreadyAssigned':'Dette nanolink-enheds-id er allerede tildelt nogle andre enheder.'
        },
        'Validation': {
            'BrandRequired': 'Mærke er påkrævet',
            'ModelRequired': 'Model er påkrævet',
            'GroupRequired': 'Gruppe er påkrævet',
            'LablesRequired': 'Etiketter er påkrævet',
            'WhiteSpaceNotAllowed': 'Kun hvidt mellemrum er ikke tilladt'
        },
        'Headers': {
            'Title': 'Enheder',
        },
        'Lable': {
            'SRNO': 'Sr nr.',
            'Image': 'Image',
            'Brand': 'Mærke',
            'Model': 'Model',
            'Created': 'Oprettet',
            'SerialNumber': 'Serienummer',
            'Keywords': 'Nøgleord',
            'Description': 'Beskrivelse',
            'Group': 'Gruppe',
            'Labels': 'Etiketter',
            'VID': 'vID',
            'NanolinkDeviceID': 'Nanolink enheds-id',
            'LastServiceDate': 'Sidste service dato',
            'NextServiceDate': 'Næste service dato',
            'LastSeen': 'Sidst set'
        },
        'ratesType': {
            'valueA': 'A Timeløn',
            'valueB': 'B Timeløn Overarbejde 1',
            'valueC': 'C Timeløn Overarbejde 2',
            'valueJ': 'J Sygeløn pr. time',

            'valueK': 'Ferie',
            'valueL': 'Feriefridag',
            'valueM': 'Fridag m.løn',
            'valueN': 'Fridag u.løn',
            'valueO': 'Kursus/skole',
            'valueP': 'Andet',
            'valueQ': 'Tilbud',
        },
        'FILTERS': {
            'All': 'Alle',
        },
        'selectedValueWeekDays': {
            'value1': 'Mandag',
            'value2': 'Trisdag',
            'value4': 'Onsdag',
            'value8': 'Torsdag',
            'value16': 'Fredag',
            'value32': 'lørdag',
            'value64': 'Søndag'
        },
    }
};