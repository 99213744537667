export const locale = {
    lang: 'en',
    data: {
        'BUTTONS': {
            'Add': 'Add',
            'Back': 'Back',
            'Save': 'Save'
        },
        'FIELDS': {
            'SrNo': 'Sr no.',
            'StartDate': 'StartDate',
            'EndDate': 'EndDate',
            'Type': 'Type',
            'OfSalary': 'Of Salary',
            'BasicSalary': 'Basic salary',
            'BasicSalaryHours': 'Basic salary hours',
            'PersonalSupplement': 'Personal supplement(per hour)',
            'TermsofEmploymentID': 'Terms of employment ID',
            'MeetingAllowance': 'Meeting allowance(per hour)',
        },
        'MESSAGES': {
            'Created': 'Created successfully',
            'Updated': 'Updated successfully',
            'Deleted': 'Deleted successfully',
            'Failed': 'Operation failed , try later',
            'NORECORD': 'No Record Found',
            'SelectCoworker':'Select Coworker'
        },
        'Lable': {
            'SRNO': 'Sr nr.',
            'Type': 'Type',
            'From,': 'From at',
            'To': 'To at',
            'HourlyRate': 'Hourly rate',
            'Numberofhours': 'Number of hours',
            'Weekday': 'Weekday',
            'MinEmployeeMonth': 'Min Employee Month',
            'MaxEmployeeMonth': 'Max Employee Month',
            '14Day':'14 day',
            'Month':'Month'
        },
        'ratesType': {
            'valueA': 'A Hourly rate',
            'valueB': 'B Hourly Wages Overtime 1',
            'valueC': 'C Hourly Wages Overtime 2',
            'valueJ': 'J Sick pay per. hour',

            'valueK': 'Vacation',
            'valueL': 'Holiday of the work',
            'valueM': 'Holiday with payment',
            'valueN': 'Holiday without payment',
            'valueO': 'School or learning',
            'valueP': 'Other things',
            'valueQ': 'Offer',
        },
        'selectedValueWeekDays': {
            'value1': 'Monday',
            'value2': 'Tuesday',
            'value4': 'Wednesday',
            'value8': 'Thursday',
            'value16': 'Friday',
            'value32': 'Saturday',
            'value64': 'Sunday'
          },
    }
}