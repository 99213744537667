import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SelectListItem } from 'app/main/model/SelectListItem';

@Component({
  selector: 'app-filter-dropdown',
  templateUrl: './filter-dropdown.component.html',
  styles: []
})

export class FilterDropdownComponent implements OnInit {

  @Input() Items: SelectListItem;
  @Input() Placeholder: string;
  @Input() selectedValue: string;
  @Output() FilterEvent = new EventEmitter<string>();

  //selectedValue: string;
  constructor() {
  }

  ngOnInit() {
    
  }

  ngAfterViewInit() {
    
  }

  Change() {
    this.FilterEvent.emit(this.selectedValue);
  }

}
