export const locale = {
  lang: 'en',
  data: {
    'FIELDS': {
      'Qty': 'Qty',
      'Text': 'Text',
      'Price': 'Price',
      'PriceTotal': 'Price Total',
    },
    'MESSAGES': {
      'Failed': 'Operation failed , try later',
      'Success': 'Success.',
      'Added': 'Added Successfully..',
      'Updated': 'Updated Successfully..',
    },
  }
};
