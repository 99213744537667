import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { RequestCoWorker } from "app/main/model/RequestBase";
import { CoWorkersService } from "app/main/pages/coWorker/coWorkers.service";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CoWorker } from 'app/main/model/CoWorker';
import { ResponseBase } from "app/main/model/ResponseBase";
import { Settings, SettingValue } from 'app/main/model/Settings';
import { SettingsService } from 'app/main/pages/settings/settings.service';
import { TranslateService } from "@ngx-translate/core";
import { NotificationsService } from "angular2-notifications";
import { MatSlideToggleChange } from '@angular/material';

@Component({
  selector: 'app-vonage-sms-coworker-setting',
  templateUrl: './vonage-sms-coworker-setting.component.html',
  styleUrls: ['./vonage-sms-coworker-setting.component.scss']
})
export class VonageSmsCoworkerSettingComponent implements OnInit {
  @Input("CoWokers") CoWokers: CoWorker[];
  @Input("CustomerReplyNotificationToCoworker") CustomerReplyNotificationToCoworker: Settings;
  showSpinner: boolean = false;
  notificationCoworkerForm: FormGroup;
  coWokers: CoWorker[] = [];
  isGettingCoWokers: boolean = false;
  settingValue: SettingValue = new SettingValue();
  notificationsObj: Settings;
  settingMasterValues: any[] = [];

  constructor(private translationLoaderService: FuseTranslationLoaderService,
    private fb: FormBuilder,
    private coWorkersService: CoWorkersService,
    private settingsService: SettingsService,
    private translate: TranslateService,
    private notificationsService: NotificationsService,) {
    this.translationLoaderService.loadTranslations(danish, english);
  }

  ngOnInit() {
    this.settingMasterValues = Object.assign([], JSON.parse(localStorage.getItem("settingMasterValues")));
    this.notificationCoworkerForm = this.fb.group({
      CoworkersID: [null, Validators.required],
      IsEnable: [true]
    });
    this.notificationCoworkerForm.controls['CoworkersID'].valueChanges
      .subscribe(selectedCoWorkers => {
      });
    this.setSettings();

  }

  DropDownSearchCoWorker(value) {
    if (value != null && value != "") {
      var request = new RequestCoWorker();
      request.Name = value;
      this.getCoWorkers(request);
    } else {
      let coWorker = new RequestCoWorker();
      coWorker.IsDeleted = false;
      this.getCoWorkers(coWorker);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.coWokers = this.CoWokers;
  }

  setSettings() {
    if (this.CustomerReplyNotificationToCoworker && this.CustomerReplyNotificationToCoworker != null) {
      this.notificationsObj = this.CustomerReplyNotificationToCoworker;
      this.settingValue = JSON.parse(this.notificationsObj.value)
      this.notificationCoworkerForm.patchValue(this.settingValue);
      this.notificationCoworkerForm.controls['CoworkersID'].setValue(this.notificationCoworkerForm.controls['CoworkersID'].value.split(','))
      this.changeInLocalStorage(this.notificationsObj.id, this.notificationsObj)
    }

  }
  getCoWorkers(model: RequestCoWorker) {
    model.OrderBy = "Name";
    this.isGettingCoWokers = true;
    this.coWorkersService.Get<ResponseBase>(model)
      .subscribe((response: ResponseBase) => {
        if (response) {
          this.coWokers = Object.assign([], response.result);
          this.getNextRequest(response.pagination, model, response.result);
        }
        else {
          this.coWokers = [];
          this.isGettingCoWokers = false;
        }
      },
        error => (err) => {
          this.coWokers = [];
          this.isGettingCoWokers = false;
        },
        () => {

        });
  }

  getNextRequest(pagination, model, response) {
    if (pagination.index == 1) {
      this.coWokers = Object.assign([], response);
      this.isGettingCoWokers = false;
    }
    else {
      this.coWokers.push(...response);
      this.isGettingCoWokers = false;
    }
    model.Index = pagination.index + 1;
    model.Limit = pagination.limit;
    model.TotalRows = pagination.totalCount;
    if (pagination.totalCount > pagination.index * pagination.limit)
      this.getCoWorkers(model);
  }

  onSubmit() {
    this.notificationCoworkerForm.markAllAsTouched();
    if (this.notificationCoworkerForm.valid) {
      this.changeSettings()
    }
  }

  IsEnableReminder(event: MatSlideToggleChange) {
    this.settingValue.IsEnable = event.checked;
    this.changeSettings();
  }

  changeSettings() {
    this.showSpinner = true;
    this.settingValue.CoworkersID = this.notificationCoworkerForm.value.CoworkersID.toString();
    this.settingValue.IsEnable = this.notificationCoworkerForm.value.IsEnable;
    this.notificationsObj.value = JSON.stringify(this.settingValue);

    this.settingsService.UpdateSettingWithTemplate<ResponseBase>(this.notificationsObj).subscribe({
      next: (response: ResponseBase) => {
        this.showSpinner = false;
        if (response != null && response.result) {
          this.notificationsObj = response.result;
          if (this.settingValue.IsEnable)
            this.setSettingsAfterUpdate();
          this.translate.get("MESSAGES.Saved").subscribe({
            next: (res) => {
              this.notificationsService.create(res);
            },
            error: err => { }
          });
        }
      },
      error: err => {
      },
      complete: () => {
        this.showSpinner = false;
      }
    });
  }

  setSettingsAfterUpdate() {
    this.settingsService.GetSettingsWithTemplateValue<ResponseBase>("Notification", this.CustomerReplyNotificationToCoworker.key2, null).subscribe({
      next: (response: ResponseBase) => {
        this.showSpinner = false;
        if (response != null && response.result && response.result.myResponse.length > 0) {
          this.notificationsObj = response.result.myResponse[0];
          this.settingValue = JSON.parse(this.notificationsObj.value)
          this.notificationCoworkerForm.patchValue(this.settingValue);
          this.notificationCoworkerForm.controls['CoworkersID'].setValue(this.notificationCoworkerForm.controls['CoworkersID'].value.split(','))
          this.changeInLocalStorage(this.notificationsObj.id, this.notificationsObj)
        }
      },
      error: err => {
      },
      complete: () => {
        this.showSpinner = false;
      }
    });
  }

  changeInLocalStorage(ID, new_obj) {
    if (this.settingMasterValues.find(obj => obj.id == ID)) {
      this.settingMasterValues.forEach((old_obj, index) => {
        if (old_obj.id == ID) {
          this.settingMasterValues[index] = new_obj
        }
      })
    } else {
      this.settingMasterValues.push(new_obj)
    }
    localStorage.setItem("settingMasterValues", JSON.stringify(this.settingMasterValues));
  }

}

