export const locale = {
    lang: 'ds',
    data: {
        'FIELDS': {
            'SRNO': 'Sr nr.',
            'Status': 'Status',
            'Number': 'Nummer',
            'Name': 'Navn',
            'Createdby': 'OprettetAf',
            'CreatedDate': 'OprettetDato',
            'ModifyBy': 'ModificereAf',
            'ModifyDate': 'ModificereDato',
            'CoworkerName': 'Medarbejder',
            'Date': 'Dato',
            'Description': 'Tekst',
            'ProjectDetail': 'Projektdetalje',
            'ColorCode': 'Farvekode',
        },
        'MESSAGES': {

        },
        'BUTTONS': {
            'BACK': 'Gå tilbage',
        },
        'FILTERS': {
            'BADGE': 'Inaktive',
            'MtoM': 'M til M',
            'Note': 'Bemærk',
            'Offers': 'Tilbud',
            'TEXTSEARCH': 'Søg',
            'SORTING': 'Sorter efter',
        },
        'SORTBY':
            [
                {
                    Text: 'Adresse (A-Z)',
                    Value: 'Address ASC'
                },
                {
                    Text: 'Adresse (Z-A)',
                    Value: 'Address DESC'
                },
                {
                    Text: 'Oprettet dato ↑',
                    Value: 'CreatedDate ASC'
                },
                {
                    Text: 'Oprettet dato ↓',
                    Value: 'CreatedDate DESC'
                }
            ]
    }
};