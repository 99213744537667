export const locale = {
    lang: 'en',
    data: {
        'MESSAGES': {
            'Created': 'Created successfully',
            'Updated': 'Updated successfully',
            'Deleted': 'Deleted successfully',
            'Failed': 'Operation failed , try later',
            'NORECORD': 'No Record Found',
            'StatusExits': 'this status already exits',
            'NotificationNotes':'*** Bemærk @CustomerName er kundenavn. @ProjectAmount er projektbeløb. @ProjectAmountPersonale er Projektbeløb Personligt. @ProjectNumber er projektnummer'
        },
        'BUTTONS': {
            'Save': 'Save',
            'Reset': 'Reset',
            'Cancel': 'Cancel',
            'Add': "Add",
            'Back': 'Back'
        },
        'FIELDS': {
            'Status': 'Status',
            'Not-Found-Status': 'Status not found',
            'Message': 'Message',
            'EnterMessage': 'Enter Message',
            'AudienceType': 'Employee / Debtor',
            'IndoorOutDoor': 'Indoor,Outdoor,Spray Painting,Reclamation*',
            'HoursToSend': 'Hours to send',
            'PercentageToSend':  'Send x percentage before',
            'SMSText': 'SMS text',
            'Description': 'Description',
            'EmailText': 'Email text',
            'DragAndDropFile':'Drag and drop file here',
            'Or':'Or',
            'BrowseForFile':'Browse for file',
            'PleaseSelectFromBelow': 'Please Select From Below',
            'StartDate': 'Select Start Date*',
            'EndDate': 'Select End Date*',
            'WeekDays':'WeekDays',
            'Days':'Days',
            'WeekNo':"Uge nr",
            'NotificationText': 'Notification text',
            "Subject":"Enter Subject"
        },
        'Lable': {
            'SRNO': 'Sr No',
            'Message': 'Message',
            'Status': 'Status',
            'AudienceType': 'Audience Type'
        },
        'Headers': {
            'StatusList': 'Status list',
        },
        'Validators': {
            'SelectStatus': 'Select Status',
            'SelectAudienceType': 'Select audience type',
            'SelectIndoorOrOutdoor': 'Select any one',
            'HoursToSend': 'Hours to send is required',
            'PercentageToSend': 'percentage to send is required',
            'SMSText': 'SMS text is required',
            'Description': 'Description is required',
            'EmailText': 'Email text is required',
            'StartDate': 'StartDate is required',
            'EndDate': 'EndDate is required',
            'ExecuteTime':"ExecuteTime is required",
            'NotificationText': 'Notification text is required',
        },
    }
};

