import { NgModule } from "@angular/core";
import { SuppliersComponent } from '../suppliers/suppliers.component';
import { NewSupplierComponent } from '../suppliers/component/new-supplier/new-supplier.component';
import { SupplierLogsComponent } from '../suppliers/component/supplierLogs/supplierLogs.component';
import { SupplierDetailComponent } from '../suppliers/component/supplierDetailModal/supplierDetail.component';
import { SuppliersService } from './suppliers.service';
import { SharedModule } from 'app/core/modules/shared.module';
import { RouterModule } from '@angular/router';
import { NgxMaskModule } from 'ngx-mask';
import { AgmCoreModule } from '@agm/core';
import { SupplierUploadComponent } from './component/supplier-upload/supplier-upload.component';

const routes = [
    {
        path: 'suppliers',
        component: SuppliersComponent
    },
    {
        path: 'supplier/add',
        component: NewSupplierComponent,
    },
    {
        path: 'supplier/edit/:id',
        component: NewSupplierComponent,
    },
    {
        path:'supplier/upload',
        component: SupplierUploadComponent
    }
];

@NgModule({
    declarations: [
        SuppliersComponent,
        NewSupplierComponent,
        SupplierLogsComponent,
        SupplierDetailComponent,
        SupplierUploadComponent
    ],
    imports: [
        SharedModule,
        RouterModule.forChild(routes),
        NgxMaskModule.forRoot() 
    ],
    entryComponents: [
        SuppliersComponent,
        SupplierDetailComponent
    ],
    exports: [
        NewSupplierComponent,
        SupplierLogsComponent
    ],
    providers: [
        SuppliersService
    ]
})

export class SuppliersModule {

}