import { Pipe, PipeTransform } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';

@Pipe({ name: 'sanitizeHtml', pure: false })
export class SanitizeHtmlPipe implements PipeTransform {

  constructor() { } 

  transform(data): SafeHtml {
    return new DOMParser().parseFromString(data, "text/html").documentElement.textContent;
  }
}