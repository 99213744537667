export const locale = {
  lang: 'en',
  data: {
    'CUSTOMER': {
      'TITLE': 'Customer',
      'DESC': 'Hello Customer!',
      'TabBasicDetails': 'Basic Detail',
      'TabContactDetails': 'Contact Detail',
      'TabOrderDetails': 'Order Detail'
    },
    'BUTTONS': {
      'NEWCUSTOMER': 'New Customer',
      'BACK': 'Cancel',
      'BTNSAVE': 'Save',
      'BTNCONFIRM': 'Confirm',
    },
    'FIELDS': {
      'Address-line': 'Address',
      'City': 'City',
      'Zipcode': 'Zipcode',
      'Position': 'Position',
      'EnterLocation': 'Enter your location',
      'CONFIRM_ADDRESS': 'Please confirm your address',
      'CONFIRM': 'Confirm',
      'CANCEL': 'Cancel',
      'FullAddress':'Full Address',
    },
    'VALIDATIONS': {
      'MapLocation':'Location Required',
      'Address':'Address Required',
      'ZipCode':'Zipcode Required',
      'City':'City Required',
      'Position':'Position Required',
    }
  }
};


