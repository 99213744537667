export const locale = {
    lang: 'en',
    data: {
      'BUTTONS': {
        'Upload': 'Upload',
        'Cancel': 'Cancel',
        "Update":"Opdatere",
        "Reset":"Reset/Cancel",
        "SampleFileDownload":"Sample file download",
        'Back': 'Back',
      },
      'Header':{
        'Medarbejdernavn': 'Co-worker Name',
        'Medarbejdernummer': 'Number',
        'Adresse': 'Address',
        'Postnr': 'Post number',
        'By': 'City',
        'Telefon': 'Phone',
        'Initialer': 'Initials',
        'Email': 'Email',
        'Tilbuddetalje': 'Offer detail',
        'Cpr': 'Cpr',
        'BankReg': 'Bank Reg',
        'BankKonto': 'Bank Account',
        'Startdato': 'Date of Joining',
        'Adgangskode': 'Password',
        'Medarbejdere': 'Co-workerAs',
      },
      'FIELDS': {
        'SelectSupplier': 'Select Supplier',
        'UploadPriceList': 'Upload Price List',
        'ChooseFile': 'Choose File',
        'CoworkerAs': 'Co-workerAs*',
        'TermOfEmployement': 'Terms of Employment',
      },
      'LABELS': {
        'TITLE': 'Co-Worker list upload (.xls,.xlsx)',
        'NoFileSelected': 'No File Selected',
        'FileSelected': 'File Selected',
      },
      'MESSAGES': {
        'Created': 'Created Successfully.',
        'Updated': 'Updated Successfully.',
        'Failed': 'Failed',
        'Deleted': 'Deleted successfully',
        'Notes':'**Note - Inactive or Deleted materials are highlighted in red color'
      },
      'VALIDATIONS': {
        'CoworkerAs': 'Select Co-workerAs',
        'TermOfEmployement' : 'Select TermOfEmployement'
      },
      'Roles': [
        { 'key': 'SuperAdmin', 'label': 'SuperAdmin' },
        { 'key': 'Admin', 'label': 'Admin' },
        { 'key': 'Kontor', 'label': 'Office' },
        { 'key': 'Maler', 'label': 'Painter' },
        { 'key': 'Gaest', 'label': 'Guest' },
        { 'key': 'Maler1', 'label': 'Maler1' },
        { 'key': 'Maler2', 'label': 'Maler2' },
        { 'key': 'Maler3', 'label': 'Maler3' },
        { 'key': 'Kontor1', 'label': 'Kontor1' },
        { 'key': 'Kontor2', 'label': 'Kontor2' },
        { 'key': 'Kontor3', 'label': 'Kontor3' },
    ],
    }
  };
  