export const locale = {
    lang: 'en',
    data: {
        'WORKHOUR': {
            'TITLE': 'Workhour',
            'DESC': 'Hello Workhour!'
        },
        'BUTTONS': {
            'BtnCancel': 'Cancel',
            'BtnStart': 'Start',
            'BtnStop': 'Stop',
            'BtnAdd': 'Add',
            'SeeDailyReport': 'See Daily Report',
            'AddNOnWorkingHours': 'Add non working hours',
            'Reportingtime': 'Reporting time',
            'Project': 'Projects',
            'Report': 'Report',
        },
        'FIELDS': {
            'Coworker': 'Select Co-worker',
            'SelectDay': 'Select Day',
            'Text': 'Text/Description',
            'StartTime': 'Start-Time',
            'BreakTime': 'Break-Time',
            'FinishTime': 'End-Time',
            'TotalHours': 'Total-Hours',
            'Week': 'Week',
            'Note': 'Please click on displaying hours in column (like.. 4.2 ,2.0...)  week day wise , will open pop up to add start-time and end-time to set timer for day.',
            'Noteheader': 'Note',
            'GrandTotal': 'Grand Total',
            'AddProject': 'Add Project',
            'Search-worker': 'Search Co-worker',
            'Not-Found-Worker': 'Co-worker not found',
            'NO_RECORD_FOUND': 'No Workhours found',
            'TOTAL': 'Total',
            'NonWorkingHours': 'Non working hours',
            'WorkingHours': 'Working Hours',
            'TotalIncludingBoth': 'Total Including Both',
            'Project': 'Projects',
            'Address': 'Address',
            'Timer': 'Go to Timer',
            'PlannedProject': 'Planned Project',
            'UnPlannedProject': 'Un-Planned Project',
            'SRNO': 'Sr No',
            'TimeOff': 'Time off until and including the last closed pay period.',
            'From': 'Fra',
            'To': 'til ',
            'StartDate': 'Startdate',
        },
        'WeekDays': {
            'Monday': 'Mon',
            'Tuesday': 'Tue',
            'Wednesday': 'Wed',
            'Thursday': 'Thu',
            'Friday': 'Fri',
            'Saturday': 'Sat',
            'Sunday': 'Sun'
        },
        'selectedWorkHourStatus': {
            'value0': 'No Status',
            'value1': 'Vacation ',
            'value2': 'Sick',
            'value3': 'Holiday of the work ',
            'value4': 'Holiday with payment',
            'value5': 'Holiday without payment ',
            'value6': 'School or learning',
            'value7': 'No payment for extra work but holiday for it ',
            'value8': 'Other things',
            'value9': 'Offer'
        },
        'MESSAGES': {
            'CoworkerDeleted': 'Coworker Deleted Successful.',
            'CannotApplyWorkHours':'You are on leave you can not apply work hours on @date',
            'CannotApplyLeave':'You are already applied working hours on @date, you cannot use leave.',
            'LastPaymentDate': 'Last payment date' 
        },
        'TOOTIPORTITLE':{
            'Edit':'Edit',
            'Delete':'Delete',
        },
        'FILTERS': {
            'SORTING': 'Sort By'
        },
        'SORTBY':
        [
          {
            Text: 'Number (0-9)',
            Value: 'Number'
          },
          {
            Text: 'Number (9-0)',
            Value: 'Number DESC'
                },
                {
                    Text: 'Status (0-9)',
                    Value: 'Status'
                },
                {
                    Text: 'Status (9-0)',
                    Value: 'Status DESC'
                }
        ],
    },
    
}

