import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { RouterModule, Routes, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import { NotificationsService } from 'angular2-notifications';
import { ApiConstant } from '../constant/ApiConstant';
import { CommonService } from 'app/core/services/common.service';

@Injectable({
    providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {
    constructor(public auth: AuthService, 
                private router: Router, 
                private notificationsService: NotificationsService,
                private commonService: CommonService) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.url.indexOf('login') > -1 || request.url.indexOf('openweathermap') > -1) {
            return next.handle(request.clone()).do((event: HttpEvent<any>) => { }, (err: any) => {
                if (err.error) {
                    this.notificationsService.error(err.error.message);
                    this.playFailedTone();
                }
                else {
                    this.notificationsService.error('Please check your credentials');
                    this.playFailedTone();
                }
            });
        }

        else if (request.url.indexOf('dropboxapi') > -1) {
            request = request.clone({
                headers: new HttpHeaders({
                    'Authorization': 'Bearer ' + ApiConstant.DrpBoxToken,
                    'Content-Type': 'application/json',
                })
            });

            return next.handle(request.clone()).do((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                }
            }, (err: any) => {
                this.playFailedTone();
                //this.commonService.playWarningTone();
            });
        }

        else if (request.url.indexOf('google') > -1) {
            return next.handle(request.clone()).do((event: HttpEvent<any>) => { }, (err: any) => {
                if (err.error) {
                    //this.notificationsService.error(err.error.message);
                    this.notificationsService.error("Google service have error please try again...");
                    this.playFailedTone();
                }
                else {
                    this.notificationsService.error('Please check your credentials');
                    this.playFailedTone();
                }
            });
        }


        // if (this.auth.getToken() != null && request.url.indexOf('reminders') > -1) {
        //     request = request.clone({
        //         headers: new HttpHeaders({
        //             'Authorization': `Bearer ${this.auth.getToken()}`,
        //             'Content-Type': 'application/json',
        //             'Access-Control-Allow-Origin': '*'
        //         })
        //     });

        //     return next.handle(request).do((event: HttpEvent<any>) => {
        //         if (event instanceof HttpResponse) {
        //             if (event.status == 204) {

        //             }
        //             else if (event.body.isMessageDisplay === true) {
        //             }
        //         }
        //     }, (err: any) => {
        //         if (err instanceof HttpErrorResponse) {
        //             if (err.status === 401) {
        //                 this.auth.RemoveLocalStorage();
        //                 this.router.navigate(['/login']);
        //                 this.notificationsService.error(err.error.message);
        //                 this.playFailedTone();
        //             }
        //             else if (err.status == 409) {
        //                 this.notificationsService.warn(err.error.message);
        //             }
        //             else if (err.status == 403) {
        //                 this.notificationsService.error('You do not have permission');
        //                 this.playFailedTone();
        //             }
        //             else {
        //                 this.notificationsService.error(err.error.message);
        //                 this.playFailedTone();
        //             }
        //         }
        //     });
        // }
        // else 
        if (this.auth.getToken() != null) {
            if (!request.headers.get('IsContentType')) {
                request = request.clone({
                    headers: new HttpHeaders({
                        'Authorization': `Bearer ${this.auth.getToken()}`,
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                    })
                });
            }
            else {
                request = request.clone({
                    headers: new HttpHeaders({
                        'Authorization': `Bearer ${this.auth.getToken()}`,
                        'Access-Control-Allow-Origin': '*',
                    })
                });
            }

            return next.handle(request).do((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    if (event.status === 401) {
                        this.auth.RemoveLocalStorage();
                        this.router.navigate(['/login']);
                        this.notificationsService.error(event.body.message);
                    }
                    if (event.status == 204) {

                    }
                    else if (event.body.isMessageDisplay === true) {
                        // this.notificationsService.success(event.body.message);
                    }
                }
            }, (err: any) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 401) {
                        this.auth.RemoveLocalStorage();
                        this.router.navigate(['/login']);
                        this.notificationsService.error(err.error.message);
                    }
                    else if (err.status == 409) {
                        this.notificationsService.error(err.error.message);
                    }
                    else if (err.status == 403) {
                        //this.notificationsService.error('You do not have permission');
                    }
                    
                    else {
                        //this.notificationsService.error(err.error.message);
                    }
                    if (err.status != 204) 
                            this.playFailedTone();

                }
            });
        }
        else {
            this.playFailedTone();
            this.router.navigateByUrl('/login');
        }
    }

    playFailedTone() {
        this.commonService.playFailedTone();
    }
}
