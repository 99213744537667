export const locale = {
    lang: 'en',
    data: {
        'FIELDS': {
            'Yes': 'Yes',
            'No': 'No',
            "CompletedBy":"Completed by"
        },
        'PlaceHolder': {
            'ReminderDate': 'Select Reminder Date*',
        }
    }
}