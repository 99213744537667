export class CalenderWorkHourPopup {
    public id: string;
    public projectId: string;
    public projectTitle: string;
    public coworkerId: string;
    public requisitionId: string;
    public startDate: Date = new Date();
    public endDate: Date;
    public shift: number = 2;
    public weekNumber: number = 1;
    public startHour: number = 7;
    public startMinute: number = 0;
    public endHour: number = 7;
    public endMinute: number = 0;
    public isPauseTime: boolean = false;
}
