import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { TitleService } from 'app/core/services/title.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { CoWorkersService } from 'app/main/pages/coWorker/coWorkers.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { QuickInvoice } from 'app/main/model/Material';
import { FormService } from 'app/core/services/form.service';
import { FormBuilder } from '@angular/forms';
import { projectMode } from 'app/main/model/ProjectStatus';
import { ProjectStatus } from 'app/constant/common.constant';


@Component({
    selector: 'vibe-quickinvoice-projectdetail',
    templateUrl: './projectdetail.component.html',
    styleUrls: ['./projectdetail.component.scss']
})
export class VibeQuickInvoiceProjectDetailComponent implements OnInit {
    @Input() quickInvoice: QuickInvoice;
    selectStatus = ProjectStatus;
    paymentModeList = projectMode;
    constructor(private translationLoader: FuseTranslationLoaderService,
        private _router: Router,
        private titleService: TitleService,
        private coWorkersService: CoWorkersService,
        private translate: TranslateService,
        private formBuilder: FormBuilder,
        private notificationsService: NotificationsService,
        public formService: FormService) {
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
        var t = this.quickInvoice;
        this.quickInvoice.requisitionDetail.paymentMode = 1
        //if(this.quickInvoice.comment)
        //    this.quickInvoice.requisitionDetail.comment = this.quickInvoice.comment;
    }

}

