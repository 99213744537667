import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';

import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { TranslateService } from '@ngx-translate/core';
import { TitleService } from 'app/core/services/title.service';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { ProjectService } from 'app/main/pages/project/project.service';
import { ProjectofferService } from 'app/main/pages/project-offer/project-offer.service';

@Component({
    selector: 'vibe-Offer-Log-Detail',
    templateUrl: './offer-log-detail.component.html',
    styleUrls: ['./offer-log-detail.component.scss']
})
export class OfferLogDetailComponent implements OnInit {
    showSpinner = false;
    projectOfferLogsDetail: any = [];
    oldItem: any = {};
    newItem: any = {};
    //Initiators
    constructor(private translationLoader: FuseTranslationLoaderService,
        private _router: Router,
        private titleService: TitleService,
        private translate: TranslateService,
        private projectofferService: ProjectofferService,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
        this.getOfferLogsDetails(this.data)
    }
    getOfferLogsDetails(logId) {
        this.showSpinner = true;
        this.projectofferService.GetProjectOfferLogDetails<ResponseBase>(logId)
            .subscribe({
                next: (response: ResponseBase) => {
                    if (response != null && response.result) {
                        this.projectOfferLogsDetail = response.result;
                        this.oldItem = response.result.length > 1 ? response.result[1] : [];
                        this.newItem = response.result[0];
                    }
                    else {
                        this.projectOfferLogsDetail = [];
                    }
                },
                error: err => {
                    this.showSpinner = false;
                    this.projectOfferLogsDetail = [];
                },
                complete: () => { }
            });
    }
    getStatus(status) {
        var statusField = "";
        if (status == 1)
            statusField = "STATUS.Created"
        if (status == 2)
            statusField = "STATUS.Updated"
        if (status == 3)
            statusField = "STATUS.Deleted"
        if (status == 4)
            statusField = "STATUS.FileUploaded"
        if (status == 5)
            statusField = "STATUS.FileDeleted"
        return statusField;
    }
    getIndoorOutdoorStatus(status) {
        var statusText = "";
        var statusArr = status ? status.split(',') : [];
        if (statusArr.length > 0) {
            for (var i = 0; i < statusArr.length; i++) {
                var appendcomma = "";
                if (i != statusArr.length - 1)
                    appendcomma = ","
                if (statusArr[i] == "1")
                    this.translate.get('selectedValueIndoorOutdoorStatus.value1').subscribe(value => { statusText += value + appendcomma; });
                if (statusArr[i] == "2")
                    this.translate.get('selectedValueIndoorOutdoorStatus.value2').subscribe(value => { statusText += value + appendcomma; });
                if (statusArr[i] == "3")
                    this.translate.get('selectedValueIndoorOutdoorStatus.value3').subscribe(value => { statusText += value + appendcomma; });
                if (statusArr[i] == "4")
                    this.translate.get('selectedValueIndoorOutdoorStatus.value4').subscribe(value => { statusText += value + appendcomma; });
                if (statusArr[i] == "5")
                    this.translate.get('selectedValueIndoorOutdoorStatus.value5').subscribe(value => { statusText += value + appendcomma; });
                if (statusArr[i] == "6")
                    this.translate.get('selectedValueIndoorOutdoorStatus.value6').subscribe(value => { statusText += value + appendcomma; });
            }
            return statusText;
        }
    }

    getOfferStatus(status) {
        var statusField = "";
        if (status)
            statusField = "selectedValueOfferStatus.value" + status
        return statusField;
    }

    // getIsActive(status) {
    //     var statusField = '';
    //     if (status) {
    //         statusField = "IsActive.value1"
    //     } else {
    //         statusField = "IsActive.value0"
    //     }
    //     return statusField;
    // }

}
