export const locale = {
    lang: 'en',
    data: {
        'BUTTONS': {
            'NEWCOWORKER': 'New Co-worker',
            'AddMaterial': 'Add material',
        },
        'FIELDS': {
            'Status': 'Status',
            'All': 'All',
            "Materialtype": 'Material type'
        },
        'VALIDATIONS': {
            'TextRequired': 'Text required',
            'ColorCodeRequired': 'Color code required',
            'PersonalPriceRequired': 'Personal price required',
            'RetailPriceRequired': 'Retail price required',
            'PurchaseQuantityRequired': 'Purchase quantity required',
            'QuantityRequired': 'Quantity required',
        },
    }
};

