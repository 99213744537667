export const locale = {
    lang: 'en',
    data: {
        'HEADERS': {
            'OfferTravelingAmount': 'Offer Traveling Amount Per Minute',
            'SelectCreatedOffer': 'Select offer',
        },
        'FIELDS': {
            'TravelingAmount': 'Traveling amount per. min'
        },
        'BUTTONS': {
            'Save': 'Save',
            'Back': 'Back',
        },
        'MESSAGES': {
            'Failed': 'Operation failed , try later',
            'Success': 'Success'
        }
    }
}