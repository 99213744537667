export class AppUserAuth {
  public UserName: string;
  public token: string;
  public Id: string;
  public role: string;
  public coworkerId: string;
  public email: string;
  public sub: string;
    claims: ClaimModel[] = [];
  }
  export interface ClaimModel {
    type: string;
    value: string;
  }

  export class Claim {
    public id: string;
    public clmMstrName: string;
    public clmMstrDescription: string;
    public clmMstrDisplayGroup: string;
    public clmMstrSubType: string;
    public isSelected: boolean;
    public isActive?: boolean = true;
    public isDeleted?: boolean;
    public activeTill?: Date
    public updUserID:string;
    public roleID:string;
    public roleClaimID:string;
    public claimValue:string;
  }
  