import { Component, OnInit, Inject, Optional } from '@angular/core';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'app-wrong-material-bind-offer-popup',
    templateUrl: './wrong-material-bind-offer-popup.component.html',
})
export class WrongMaterialBindToOfferPopupComponent implements OnInit {
  deleteAll: boolean = false;
    constructor(private translationLoader: FuseTranslationLoaderService,
      public dialogRef: MatDialogRef<WrongMaterialBindToOfferPopupComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.translationLoader.loadTranslations(english, danish);

  }

  ngOnInit() {
    
  }

  onCloseDialog(e) {
    this.dialogRef.close(e)
  }

}

