export const locale = {
    lang: 'en',
    data: {
        'HEADER':{
            'Title':'Calculated triangle'
        },
        'FIELDS': {
            'Baseline': 'Wide',
            'Height': 'Height',
            'Area': 'Area',
            'CalculateArea':'Calculated area'
        },
        'BUTTONS': {
            'CalculateArea': 'Calculated area',
            'BACK': 'Back',
        },
    }
}