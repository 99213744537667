import { Component, OnInit, Inject } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { MaterialService } from 'app/main/pages/material/material.service';
import { CoWorkersService } from 'app/main/pages/coWorker/coWorkers.service';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormService } from 'app/core/services/form.service';
import { RequestStatusProject } from 'app/main/model/RequestBase';
import { PayrollEntry } from 'app/main/model/CoWorker';
import { PayrollService } from '../../payroll.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatRadioChange } from '@angular/material';

//translate
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { Router } from '@angular/router';
import { SettingValue, Settings } from "app/main/model/Settings";
import { PayrollType } from "app/constant/common.constant";
import * as moment from 'moment';
import { AuthService } from "app/core/services/auth.service";

@Component({
    selector: 'payroll-loanEntry',
    templateUrl: './loanEntry.component.html',
    styleUrls: ['./loanEntry.component.scss']
})

export class LoanEntryComponent implements OnInit {
    selectedIndex: number = 0;
    showSpinner = false;
    coworkerList: any = [];
    displayPagination: boolean;
    pagination: any;
    MaterialColorCode: any;
    colorCodeDetail: any;
    loanEntryForm: FormGroup;
    search: any = '';
    payrollEntryObject: PayrollEntry = new PayrollEntry;
    CoWorkerName: string = "";
    payrollId: string = "";
    settingMasterValues:any[] = [];
    setting: Settings;
    settingValue: SettingValue = new SettingValue();
    payrollType = PayrollType;
    roleClaims: any;
    hasOwnPayrollViewPermision: boolean = false;
    loggedInCoworkerID: string;

    constructor(private translationLoader: FuseTranslationLoaderService,
        private materialService: MaterialService,
        private coWorkersService: CoWorkersService,
        private fb: FormBuilder,
        private notificationsService: NotificationsService,
        private authService: AuthService,
        public formService: FormService,
        private translate: TranslateService,
        private payrollService: PayrollService,
        private _router: Router,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<LoanEntryComponent>) {
        this.translationLoader.loadTranslations(danish, english);
        this.roleClaims = this.authService.getRoleClaimsList();
    }

    ngOnInit() {
        this.loggedInCoworkerID = this.authService.getCoWorkerId();
        this.authService.hasClaim(this.roleClaims.Own_Payroll_View).subscribe(hasClaim => {
            if (hasClaim) 
                this.hasOwnPayrollViewPermision = hasClaim
            else 
                this.hasOwnPayrollViewPermision = false;
        });
        
        if (this.data) {
            this.payrollId = this.data
            this.edit(this.payrollId);
        }
        else {
            this.payrollEntryObject.loanEntryType = "isOther";
            if(this.hasOwnPayrollViewPermision)
            this.payrollEntryObject.coworkerID = this.loggedInCoworkerID;
        }
        this.settingMasterValues = Object.assign([], JSON.parse(localStorage.getItem("settingMasterValues")));
        var settingValue = this.settingMasterValues.find(s => s.key1 == "Payment" && s.key2 == "General").value;
        if(settingValue)
            this.settingValue = JSON.parse(settingValue);
        this.getCoworkerList();
        this.initFormControl();
    }

    initFormControl() {
        this.loanEntryForm = this.fb.group({
            id: [0],
            coworkerID: [null, Validators.required],
            date: [null, Validators.required],
            numberofHours: [Validators.required],
            hourlyRate: [],
            numberofHolidays: [null],
            description: [null],
            archived: [null],
            koersel: [null],
            gDag: [null],
            holidays: [null],
            other: [null],
            totalAmount: [Validators.required],
            isKoersel: [false],
            isGDag: [false],
            isHolidays: [false],
            isSalaryAdvance:[false],
            isOther:[false],
            salaryEntryType:['isOther', Validators.required]
        });
    }


    save() {
        if (this.payrollEntryObject.id) {
            this.update();
        } else {
            this.add();
        }
    }

    add() {
        this.payrollEntryObject.koersel = this.payrollEntryObject.loanEntryType == "isKoersel" ? "T" : "F";
        this.payrollEntryObject.gDag = this.payrollEntryObject.loanEntryType == "isGDag"  ? "T" : "F";
        this.payrollEntryObject.holidays = this.payrollEntryObject.loanEntryType == "isHolidays" ? "T" : "F";
        this.payrollEntryObject.salaryAdvance = this.payrollEntryObject.loanEntryType == "isSalaryAdvance" ? "T" : "F";
        this.payrollEntryObject.other = this.payrollEntryObject.loanEntryType == "isOther" ? "T" : "F";
        this.formService.markFormGroupTouched(this.loanEntryForm);
        if (this.loanEntryForm.valid) {
            this.showSpinner = true;
            this.payrollService.AddPayroll<ResponseBase>(this.payrollEntryObject)
                .subscribe({
                    next: (response: ResponseBase) => {
                        this.showSpinner = false;
                        this.translate.get("MESSAGES.Created").subscribe({
                            next: (res) => {
                                this.notificationsService.success(res);
                            },
                            error: err => {
                            }
                        });
                        this.payrollEntryObject = new PayrollEntry();
                        this.loanEntryForm.reset();
                        this.dialogRef.close(true);
                        //this._router.navigate(["payroll"]);
                    },
                    error: err => {
                        this.showSpinner = false;
                        this.translate.get("MESSAGES.Failed").subscribe({
                            next: (res) => {
                                this.notificationsService.error(res);
                            },
                            error: err => {
                            }
                        });
                    },
                    complete: () => {
                        this.showSpinner = false;
                    }
                });
        }
    }

    update() {
        this.payrollEntryObject.koersel = this.payrollEntryObject.loanEntryType == "isKoersel" ? "T" : "F";
        this.payrollEntryObject.gDag = this.payrollEntryObject.loanEntryType == "isGDag"  ? "T" : "F";
        this.payrollEntryObject.holidays = this.payrollEntryObject.loanEntryType == "isHolidays" ? "T" : "F";
        this.payrollEntryObject.salaryAdvance = this.payrollEntryObject.loanEntryType == "isSalaryAdvance" ? "T" : "F";
        this.payrollEntryObject.other = this.payrollEntryObject.loanEntryType == "isOther" ? "T" : "F";
        this.formService.markFormGroupTouched(this.loanEntryForm);
        if (this.loanEntryForm.valid) {
            this.showSpinner = true;
            this.payrollService.UpdatePayroll<ResponseBase>(this.payrollEntryObject)
                .subscribe({
                    next: (response: ResponseBase) => {
                        this.showSpinner = false;
                        this.translate.get("MESSAGES.Updated").subscribe({
                            next: (res) => {
                                this.notificationsService.success(res);
                            },
                            error: err => {
                            }
                        });
                        this.payrollEntryObject = new PayrollEntry();
                        this.loanEntryForm.reset();
                        this.dialogRef.close(true);
                        //this._router.navigate(['/payroll/list', this.payrollId]);
                    },
                    error: err => {
                        this.showSpinner = false;
                        this.translate.get("MESSAGES.Failed").subscribe({
                            next: (res) => {
                                this.notificationsService.error(res);
                            },
                            error: err => {
                            }
                        });
                    },
                    complete: () => {
                        this.showSpinner = false;
                    }
                });
        }
    }

    edit(payrollId) {
        this.showSpinner = true;
        this.payrollService.GetPayrollByID<ResponseBase>(payrollId).subscribe({
            next: (response: ResponseBase) => {
                if (response) {
                    this.showSpinner = false;
                    this.payrollEntryObject = response.result;
                    this.payrollEntryObject.isKoersel = this.payrollEntryObject.koersel == "T" ? true : false;
                    this.payrollEntryObject.isGDag = this.payrollEntryObject.gDag == "T" ? true : false;
                    this.payrollEntryObject.isHolidays = this.payrollEntryObject.holidays == "T" ? true : false;
                    this.payrollEntryObject.isSalaryAdvance = this.payrollEntryObject.salaryAdvance == "T" ? true : false;
                    this.payrollEntryObject.isOther = this.payrollEntryObject.other == "T" ? true : false;
                    this.initLoanEnrtyType();
                    this.loanEntryForm.patchValue(this.payrollEntryObject);
                }
            },
            error: err => {
                this.showSpinner = false;
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            }
        })
    }

    getCoworkerList() {
        var model = new RequestStatusProject();
        model.Index = 1;
        model.Limit = 400;
        model.OrderBy = "Name";
        this.showSpinner = true;
        this.coWorkersService.Get<ResponseBase>(model)
            .subscribe((response: ResponseBase) => {
                if (response)
                    this.coworkerList = response.result;
                else
                    this.coworkerList = [];
            },
                error => (err) => {
                    this.coworkerList = [];
                },
                () => {
                    this.showSpinner = false
                });
        this.showSpinner = true;
        // this.materialService.GetPostnumberList<ResponseBase>(this.PostnumberRequest)
        //     .subscribe({
        //         next: (response: ResponseBase) => {
        //             this.PostnumberList = response.result;
        //             this.pagination = response.pagination;
        //             this.displayPagination = true;
        //         },
        //         error: err => { },
        //         complete: () => {
        //             this.showSpinner = false;
        //         }
        // });
    }

    calculateTotalAmount() {
        this.payrollEntryObject.totalAmount = null;
        if(this.payrollEntryObject.numberofHours && this.payrollEntryObject.hourlyRate)
            this.payrollEntryObject.totalAmount = (this.payrollEntryObject.numberofHours * this.payrollEntryObject.hourlyRate);
    }

    back() {
        this.dialogRef.close();
    }

    reset() {
        this.loanEntryForm.reset();
    }

    onSetPrice(data: MatRadioChange){
        if(this.payrollEntryObject.loanEntryType == 'isOther')
            this.loanEntryForm.controls["description"].setValidators([Validators.compose([Validators.required])]);
        else
            this.loanEntryForm.controls["description"].setValidators(null);
        this.loanEntryForm.controls["description"].updateValueAndValidity();
        if(!this.payrollId){
            if(this.payrollEntryObject.loanEntryType == 'isKoersel')
                this.payrollEntryObject.hourlyRate = this.settingValue.DrivingCurrency;
            else if(this.payrollEntryObject.loanEntryType == 'isGDag')
                this.payrollEntryObject.hourlyRate = this.settingValue.GDayCurrency;
            else 
                this.payrollEntryObject.hourlyRate = 0;
        }
    }

    initLoanEnrtyType()
    {
        if(this.payrollEntryObject.isKoersel)
            this.payrollEntryObject.loanEntryType = 'isKoersel';
        if(this.payrollEntryObject.isGDag)
            this.payrollEntryObject.loanEntryType = 'isGDag';
        if(this.payrollEntryObject.isHolidays)
            this.payrollEntryObject.loanEntryType = 'isHolidays';
        if(this.payrollEntryObject.isSalaryAdvance)
            this.payrollEntryObject.loanEntryType = 'isSalaryAdvance';
        if(this.payrollEntryObject.isOther)
            this.payrollEntryObject.loanEntryType = 'isOther';
    }

    public OnChangeDatePicker(args): void {
        if (args.value) {
            args.value = new Date(moment(args.value).format("YYYY-MM-DD"));
        }
    }

    onFocusIn(event) {
        event.model.show();
    }
}