export const locale = {
    lang: 'en',
    data: {
        'DASHBOARD': {
            'TITLE': 'Dashboard',
            'DESC': 'Hello Dashboard!',
            'ComingSoon': 'Coming Soon',
            'Bulletinboard': 'Bulletin board',
            'ReceivedToMe': 'Received to me',
            'SentByMe': 'Sent by me',
            'Projects': 'Projects',
            'Reminders': 'Reminders'
        },
        'BUTTONS': {
            'CreateReminder': 'Lav påmindelse',
            'VehicleInfo': 'Vehicle Info',
            'Search': 'Search',
        },
        'MESSAGES': {
            'NORECORD': 'No Record Found',
            'NO_REMINDER_RECORD': 'No Reminder Found',
            'NO_PROJECT_RECORD': 'No Project Found',
            'Created': 'Created successfully.',
            'Updated': 'Updated successfully.',
            'Deleted': 'Deleted successfully.',
            'Failed': 'Something went wrong !',
        },
        'FIELDS': {
            'CaseNumber': 'Case Number',
            'Title': 'Title',
            'Client': 'Client',
            'Address': 'Address',
            'StartDate': 'Start Date',
            'EndDate': 'End Date',
            'CoWorkers': 'Co-Workers',
            'Files': 'Files',
            'actionsColumn': 'Action',
            'From': 'From',
            'To': 'to',
            'Showallmyprojects': 'Show all my projects',
            'Showallprojects': 'Show all projects',
            'HoursBack': 'Hours back',
            'Offers': 'Offers',
            'Show60DayOld': 'Show 60 Days old',
            'MtoM': 'M to M',
            'Note': 'Note',
            'BADGE': 'Inactive',
            'RemainingTime': 'Remaining',
            'Planned': 'Planned',
            'HourlyWage': 'Hourly rate',
            'IndoorOutdoorStatusValue5': 'Staff purchase',
            'visit': 'Visit',
            'SelectDate': 'Select Date'
        },
        // 'selectedValueStatus': {
        //     'value0': 'Registered',
        //     'value1': 'Process',
        //     'value2': 'Scheduled visit',
        //     'value3': 'Ready to boot',
        //     'value4': 'Offers sent',
        //     'value5': 'Offer approved',
        //     'value6': 'Planned',
        //     'value7': 'Offer rejected',
        //     'value8': 'Started',
        //     'value9': 'Performed',
        //     'value10': 'Billed',
        //     'value11': 'Pending',
        //     'value12': 'Ready billing',
        // },
        'Today': 'Today',
        'Tomorrow': 'Tomorrow',
        'Yesterday': 'Yesterday'
    },
    'selectedValueIndoorOutdoorStatus': {
        'value1': 'Indoor ',//Man in a room
        'value2': 'Outdoor', //Sun
        'value3': 'Spray Painting',//spraypaint
        'value4': 'Complaint', //Reklamation
        'value5': 'Staff purchase', //drawing of painter
        'value6': 'Store purchase' //Nordsjø logo
    },
};

