import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';
import { MatDialog } from '@angular/material';
import { QualityTestService } from '../../quality-test.service';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';
import { ProjectofferService } from 'app/main/pages/project-offer/project-offer.service';
import { ProjectsOfferRoom } from 'app/main/model/ProjectOffer';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { OfferColorCodeDTO } from 'app/main/model/OfferQualityTestCategory';
import { RequestOfferColorCode, RequestProjectOfferRoom } from 'app/main/model/RequestBase';

@Component({
    selector: 'app-offer-quality-test-color-code',
    templateUrl: './color-code.component.html',
    styleUrls: ['./color-code.component.scss']
})
export class OfferQualityTestColorCodeComponent implements OnInit {
    @Input() ModuleID: string = "";
    @Input() ProjectID: string = "";
    @Input() CustomerID: string = "";
    @Input() ModuleName: string = "";
    @Input() ModuleRoomSelectID: string = "";
    @Input() OfferQualityTestCategoryID: string = "";
    colorCodeText: string = "";
    selectedOfferRooms: any = [];
    ProjectsOfferRooms: ProjectsOfferRoom[];
    selectOfferRoomAllOption: boolean = false;
    offerColorCodeDTO: OfferColorCodeDTO = new OfferColorCodeDTO();
    requestOfferColorCode: RequestOfferColorCode = new RequestOfferColorCode();
    offerColorCodeDTOList: OfferColorCodeDTO[] = [];
    showSpinner: boolean = false;
    selectedIndex: number = 0;
    displayImageGallery: boolean = false;
    //customerId: string = "20160919162547000XXX";
    galleryOptions: NgxGalleryOptions[] = [];
    galleryImages: NgxGalleryImage[] = [];
    displayPagination: boolean;
    pagination: any;
    limitNumber: any;    
    constructor(private _route: ActivatedRoute,
        private translationLoader: FuseTranslationLoaderService,
        private translate: TranslateService,
        private notificationsService: NotificationsService,
        private projectofferService: ProjectofferService,
        private qualityTestService: QualityTestService,
        private dialog: MatDialog) {
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
        this.offerColorCodeDTO.projectID = this.ProjectID;
        this.requestOfferColorCode.projectID = this.ProjectID;
        this.offerColorCodeDTO.offerID = this.ModuleID;
        this.requestOfferColorCode.offerID = this.ModuleID;        
        this.GetOfferColorCode();
        var requestProjectOfferRoom: RequestProjectOfferRoom = new RequestProjectOfferRoom();
        requestProjectOfferRoom.Limit = 100;
        requestProjectOfferRoom.OfferId = this.ModuleID;
        this.getProjectOfferRoomList(requestProjectOfferRoom);
    }

    save() {
        //this.offerColorCodeDTO.offerID = this.ModuleID;
        if (this.offerColorCodeDTO.id)
            this.update();
        else
            this.Add();
    }

    Add() {
        this.showSpinner = true;
        this.offerColorCodeDTO.projectID = this.ProjectID;
        this.offerColorCodeDTO.offerID = this.ModuleID;
        this.offerColorCodeDTO.offersRoomID = this.selectedOfferRooms.join(",");
        this.offerColorCodeDTO.qualityTestCategoryID = this.OfferQualityTestCategoryID;
        this.qualityTestService.AddOfferColorCode(this.offerColorCodeDTO).subscribe({
            next: (response) => {
                if (response) {
                    this.translate.get("MESSAGE.Added").subscribe({
                        next: (res) => {
                            this.notificationsService.success(res);
                        },
                        error: err => {
                        }
                    });
                    this.offerColorCodeDTO = new OfferColorCodeDTO();
                    this.selectedOfferRooms = [];
                    this.GetOfferColorCode();
                }
            },
            error: err => {

            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    update() {
        this.showSpinner = true;
        this.offerColorCodeDTO.offersRoomID = this.selectedOfferRooms.join(",");
        this.qualityTestService.UpdateOfferColorCode(this.offerColorCodeDTO).subscribe({
            next: (response) => {
                if (response) {
                    this.translate.get("MESSAGE.Updated").subscribe({
                        next: (res) => {
                            this.notificationsService.success(res);
                        },
                        error: err => {
                        }
                    });
                    this.offerColorCodeDTO = new OfferColorCodeDTO();
                    this.selectedOfferRooms = [];
                    this.GetOfferColorCode();
                }
            },
            error: err => {

            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    getProjectOfferRoomList(model) {
        this.showSpinner = true;
        this.projectofferService.getProjectOfferRoomList<ResponseBase>(model)
            .subscribe((response: ResponseBase) => {
                this.showSpinner = false;
                this.ProjectsOfferRooms = [];
                if (response != null) {
                    this.ProjectsOfferRooms = response.result.myResponse;
                }
            },
                error => (err) => {
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });
                });
    }

    RoomSelectAllOption() {
        this.selectOfferRoomAllOption = !this.selectOfferRoomAllOption;
        this.selectedOfferRooms = [];
        if (this.selectOfferRoomAllOption) {
            this.selectedOfferRooms.push("0");
            for (var i = 0; i < this.ProjectsOfferRooms.length; i++) {
                this.selectedOfferRooms.push(this.ProjectsOfferRooms[i].id);
            }
        }
    }

    GetOfferColorCode() {
        this.showSpinner = true;
        this.requestOfferColorCode.projectID = this.ProjectID;
        this.requestOfferColorCode.offerID = this.ModuleID;
        this.requestOfferColorCode.qualityTestCategoryID = this.OfferQualityTestCategoryID;
        this.qualityTestService.GetOfferColorCode(this.requestOfferColorCode).subscribe({
            next: (response: ResponseBase) => {
                if (response) {
                    this.offerColorCodeDTOList = response.result;
                    this.pagination = response.pagination;
                    this.displayPagination = true;
                    this.offerColorCodeDTO.colorCode = "";
                }
                else {
                    this.offerColorCodeDTOList = [];
                    this.displayPagination = false;
                }
            },
            error: err => {
                this.showSpinner = false;
                this.offerColorCodeDTOList = [];
                this.displayPagination = false;
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    TabChange(event) {
        this.selectedIndex = event.index;
    }

    receivePaginatonEvent(event) {
        this.requestOfferColorCode.Index = event.pageIndex;
        this.requestOfferColorCode.Limit = event.pageSize;
        this.GetOfferColorCode();
    }

    edit(values) {
        this.offerColorCodeDTO = new OfferColorCodeDTO();
        this.offerColorCodeDTO = values;
        this.selectedOfferRooms = this.offerColorCodeDTO.offersRoomID.split(",");
    }

    delete(id) {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: "200px",
            data: { Header: 'Headers.DeleteColorCode' }
        })

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.showSpinner = true;
                this.qualityTestService.DeleteOfferColorCode(id).subscribe({
                    next: (response: any) => {
                        if (response) {
                            this.showSpinner = false;
                            this.translate.get("MESSAGES.Deleted").subscribe({
                                next: (res) => {
                                    this.notificationsService.success(res);
                                },
                                error: err => {
                                }
                            });
                        }
                    },
                    error: err => {
                        this.showSpinner = false;
                        this.translate.get("MESSAGES.Failed").subscribe({
                            next: (res) => {
                                this.notificationsService.error(res);
                            },
                            error: err => {
                            }
                        });
                    },
                    complete: () => {
                        this.showSpinner = false;
                        this.GetOfferColorCode();
                    }
                })
            }
        });
    }
}
