export const locale = {
    lang: 'en',
    data: {
        'TITLE': {
            'MainTitle': 'Purchase price / Sale price / Staff price',
            'MaterialOtherSettingTitle': 'Material Other Settings',
        },
        'FIELDS': {
            'PersonalPricePerc': 'Personnel price min.(%) greater than purchase price',
            'SellingPricePerc': 'Sale price min. (%) greater than purchase price',
            'ErrorMaterialShowOfferMaterial': 'Error materials list show offer material ?',
            'OrderQty': 'Max Order Qty',
            'UseScanner': 'Use Scanner Device',
            'ShowPersonalPrice': 'Show Personal Price',
            'WarningPriceForMaxTotalPrice':'Warning for maximum total price'
        },
        'VALIDATIONS': {
            'PersonalPricePerc': 'Personal Price Percentage Required',
            'SellingPricePerc': 'Selling Price Percentage Required',
            'OrderQty': 'Max Order Qty Required',
        },
        'FILTERS': {
        },
        'BUTTONS': {
            'Save': 'Save',
        },
        'MESSAGES': {
            'Created': 'Created successfully',
            'Updated': 'Updated successfully',
        }
    }
};