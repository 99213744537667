export const locale = {
    lang: 'en',
    data: {
        'TITLE': {
            'DevicePagination': 'Number of chips per page',
        },
        'FIELDS': {
            'Designation': 'Designation',
            'ValideFrom': 'Valide From',
            'SRNO': 'Sr no.'
        },
        'BUTTONS': {
            'Save': 'Save',
            'BACK': 'Back',
            'Reset': 'Reset',
            'AddDevice':'Add Nano Chips'
        },
        'MESSAGES': {
            'Updated': 'Updated Successfully.',
            'Failed': 'Failed',
            'Deleted': 'Deleted successfully',
            "NORECORD": "No Record Found",
            'Created': 'Created successfully',
        },
        'Headers': {
            'Title': 'Nano Chips',
        },
        'Lable': {
            'SRNO': 'Sr nr.',
            'Image': 'Image',
            'Brand': 'Brand',
            'Model': 'Model',
            'Created': 'Created',
            'SerialNumber': 'Serial number',
            'Keywords': 'Keywords',
            'Description': 'Description',
            'Group': 'Group',
            'Labels': 'Labels',
            'VID':'vID',
            'NanolinkDeviceID':'Nanolink DeviceID',
            'Type':'Type',
        },
        'FILTERS': {
            'All': 'All',
            'SEARCH': 'Search',
            'SORTING': 'Sort By',
        },
        'SORTBY':
            [
                
                {
                    Text: 'Model ↑',
                    Value: 'Model'
                },
                {
                    Text: 'Model ↓',
                    Value: 'Model DESC'
                },
                {
                    Text: 'Type ↑',
                    Value: 'Type'
                },
                {
                    Text: 'Type ↓',
                    Value: 'Type DESC'
                },
                {
                    Text: 'Battery Percentage ↑',
                    Value: 'BatteryPer'
                },
                {
                    Text: 'Battery Percentage ↓',
                    Value: 'BatteryPer DESC'
                }
            ],
    }
};