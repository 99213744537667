export class OfferQualityTestAnswer {
    public id: string;
    public offerID: string;
    public offerQualityTestCategoryID: string;
    public json: string;
    public createdByName: string;
    public createdDate: string;
    public modifyDate: string;
    public projectID: string;
    public qualityTestCategoryID: string;
}