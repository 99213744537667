export const locale = {
    lang: 'ds',
    data: {
        'FIELDS': {
            'SrNo': 'Sr-nr.',
            'Date': 'Dato',
            'SupplierName': 'Leverandør Navn',
            'OrderMaterial': 'Antal',
            'OrderAmount': 'Beløb',
            'ReceivedMaterila': 'Modtaget Vare',
            'ReceivedAmount': 'Modtaget Beløb',
            'Status': 'Status',
            'Email': 'E-mail',
            'Attachment': 'Vedhæftet fil',
            'Coworker': 'Senest opdateret af',
            'ReceivedDate': 'Modtaget Dato',
            'TextSearch': 'Søg',
            'SelectSupplier': 'Vælg Leverandør',
            'StartDate': 'Start Dato',
            'EndDate': 'Slut Dato',
            'SearchAllOptions': 'Alle',
        },
        'MESSAGES': {
            'Failed': 'Mislykkedes',
            'NORECORD': 'Ingen materiale fundet',
        },
        'TITLE': {
            'CustomerPagination': 'Antal log pr. side',
        },
        'SupplierOrderStatus': {
            'Ordered': 'Bestilt',
            'Received': 'Modtaget',
        },
        "SupplierList": {
            'value1': 'Bestilt',
            'value2': 'Modtaget',
            'value3': 'Delvist Modtaget',
            'value4': 'Delete',
        },
        'TOOTIPORTITLE': {
            'ViewEmailTemplate':'Se e-mail-skabelon',
            'ViewAttachmentFile': 'Se vedhæftet fil',
            'EditOrderedItem':'Rediger Bestilt Materiale Vare',

        }
    }
}