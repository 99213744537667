import { Component, OnInit, ViewChild } from '@angular/core';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { TitleService } from 'app/core/services/title.service';
import { AuthService } from 'app/core/services/auth.service';
import { CompanyInfoService } from './companyInfo.service';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { RequestCompanyInfo, RequestCoWorker } from 'app/main/model/RequestBase';
import { EmailSendingParam } from 'app/main/model/common';
import { CompanyInfoSettingsComponent } from '../company-info-settings/company-info-settings.component';
import { MatAccordion, MatDialog } from '@angular/material';
import { TextEditorComponent } from '../text-editor/text-editor.component';
import { TextEditorEmailSendComponent } from 'app/main/shared-component/text-editor-email-send/text-editor-email-send.component';
import { CompanyInfoSetting, CompanyInfo } from 'app/main/model/CompanyInfo';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { CkeditorConfig } from 'app/constant/common.constant';
import { OfferDrivingAmountComponent } from './component/offer-driving-amount/offer-driving-amount.component';
//import { CompanyInfoSetting } from './../../model/CompanyInfo';
import { CommonService } from 'app/core/services/common.service';
import { GoogleMapDirectionService } from '../google-map-direction/google-map-direction.service';

@Component({
  selector: 'vibe-companyInfo',
  templateUrl: './companyInfo.component.html',
  styleUrls: ['./companyInfo.component.scss']
})
export class VibeCompanyInfoComponent implements OnInit {
  selectedIndex: number = 0;
  showForm: boolean = false;
  comapnyInfoSettingTabs: CompanyInfoSetting[];
  showSpinnerComInfoTabs: boolean = false;
  showSpinner1: boolean = false;
  showSpinner2: boolean = false;
  showSpinner3: boolean = false;
  latitude: number;
  longitude: number;
  Description: any;
  companyInfoData: CompanyInfo;
  coWokers = [];
  // IsAdmin: boolean = false;
  // roleName: string;
  config = CkeditorConfig;
  showEditor: boolean = true;
  roleClaims: any;
  constructor(private translationLoader: FuseTranslationLoaderService,
    private titleService: TitleService,
    private authService: AuthService,
    private translate: TranslateService,
    private notificationsService: NotificationsService,
    private commonService: CommonService,
    public dialog: MatDialog,
    public companyInfoService: CompanyInfoService,
    public googleMapDirectionService: GoogleMapDirectionService) {
    this.titleService.SetTitle("TITLE_COMAPNYINFO");
    this.translationLoader.loadTranslations(english, danish);
    this.roleClaims = this.authService.getRoleClaimsList();
  }

  ngOnInit() {
    this.config.height = 350;
    this.config.readOnly = true;
    this.getCompanyInfoSettingTabs(new RequestCompanyInfo());
    this.companyInfoData = new CompanyInfo();
    this.GetCompanyInformation();
    let coWorker = new RequestCoWorker();
    coWorker.IsDeleted = false;
    this.getCoWorkers(coWorker);

    // this.roleName = this.authService.getUserRole();
    // if (this.roleName == "Admin") {
    //   this.IsAdmin = true;
    // }
  }

  EditCompanyInfo() {
    this.companyInfoData;
    this.showForm = !this.showForm;
    this.config.readOnly = false;
  }

  getCompanyInfoSettingTabs(model: RequestCompanyInfo) {
    //this.comapnyInfoSettingTabs = new Array();
    this.showSpinner1 = true;
    this.companyInfoService.GetAll<ResponseBase>(model)
      .subscribe((response: ResponseBase) => {
        if (response) {
          this.comapnyInfoSettingTabs = new Array();
          response.result.filter(c => {
            this.comapnyInfoSettingTabs.push(c);
            this.showSpinner1 = false;
          })
        }
        else {
          this.showSpinner1 = false;
        }
      },
      error => (err) => {
        this.showSpinner1 = false;
      },
      () => {
      });
  }

  openCompanyInfoSettingModal() {
    const dialogRef = this.dialog.open(CompanyInfoSettingsComponent, {
       height: '90%',
      width: '700px',
      data: this.comapnyInfoSettingTabs
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.comapnyInfoSettingTabs = new Array();
        result.filter(c => {
            this.comapnyInfoSettingTabs.push(c);
        });
        this.selectedIndex = 0;
      }
    });
  }

  getCoWorkers(model) {
    model.Limit = 400;
    model.OrderBy = "Name";
    this.showSpinner3 = true;
    this.companyInfoService.GetCoworker<ResponseBase>(model)
      .subscribe((response: ResponseBase) => {
        this.showSpinner3 = false;
        if (response) {
          //this.coWokers = response.result;
          this.getNextRequestForCoWorker(response.pagination, model, response.result);
        }
        else {
          this.coWokers = [];
          //this.SecurityOfficers = [];
        }
      },
        error => (err) => {
          this.showSpinner3 = false;
          this.coWokers = [];
          //this.SecurityOfficers = [];
        },
        () => {
        });
  }

  getNextRequestForCoWorker(pagination, model, response) {
    if (pagination.index == 1) {
      this.coWokers = response;
    }
    else {
      this.coWokers.push(...response);
    }
    model.Index = pagination.index + 1;
    model.Limit = pagination.limit;
    model.TotalRows = pagination.totalCount;
    if (pagination.totalCount > pagination.index * pagination.limit)
      this.getCoWorkers(model);
  }

  sendEmail() {
    this.config.readOnly = false;
    var emailSendingParam = new EmailSendingParam();
    emailSendingParam.fromName = this.authService.getUserName();
    emailSendingParam.to = this.authService.getUserEmail();
    // emailSendingParam.from = "post@vibesmalerfirma.dk";
    emailSendingParam.coworkerList = this.coWokers;
    emailSendingParam.isLoadDefaultEmailTemplate = true;
    emailSendingParam.displayCoworkerList = true;
    const dialogRef = this.dialog.open(TextEditorEmailSendComponent, {
      height: '90%',
      width: '90%',
      data: emailSendingParam
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null && result != '') {
        this.companyInfoService.SendEmail<ResponseBase>(result)
          .subscribe({
            next: (response: ResponseBase) => {
              this.commonService. checkEmailIsToBeSent();
            },
            error: err => {
            },
            complete: () => {
            }
          });
      }
    });
  }


  openTextEditorToWriteDescription(selectedTabData: CompanyInfoSetting) {
    selectedTabData.Description = this.Description;

    const dialogRef = this.dialog.open(TextEditorComponent, {
      height: '90%',
      width: '90%',
      data: { selectedTabData, isEmail: false }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null && result != '') {
        this.companyInfoService.UpdateCompanyInfoSettings<ResponseBase>(result)
          .subscribe({
            next: (response: ResponseBase) => {
              //this.getCompanyInfoSettingTabs(new RequestCompanyInfo());

              this.Description = result.Description;
            },
            error: err => {
            },
            complete: () => {
            }
          });
      }
    });
  }

    TabChange(event) {
    this.showEditor = false;
    this.selectedIndex = event.index;
    if (event.index > 0) {
      var list = this.comapnyInfoSettingTabs.filter(o => o.isHidden == true);
      var infoObj = list[event.index - 1];
      this.Description = null
      this.showSpinnerComInfoTabs = true;
      this.companyInfoService.Get<ResponseBase>(infoObj.id)
        .subscribe((response: ResponseBase) => {
          this.showSpinnerComInfoTabs = false;

          if (response) {
            this.Description = response.result.description;
            //setInterval(() => {
            //  this.Description = response.result.description;
            //}, 1000);
          }
          //this.comapnyInfoSettingTabs.filter(x => {
          //  if (x.id == response.result.id) {
          //    x.Description = response.result.description;
          //  }
          //})
        },
          (err: any) => {
            this.showSpinnerComInfoTabs = false;
            this.translate.get("MESSAGES.Failed").subscribe({
              next: (res) => {
                this.notificationsService.error(res);
              }, error: err => { }
            });
          },
          () => {
          });
    }
    else 
      this.showEditor = true;
  }

  saveCompanyInfo() {
    this.showSpinner1 = true;
    this.showSpinner2 = true;
    this.showSpinner3 = true;
    this.companyInfoData.logo = this.companyInfoData.image;
    this.companyInfoService.UpdateCompanyInformation<ResponseBase>(this.companyInfoData)
      .subscribe((response: ResponseBase) => {
        if (response) {
          this.showSpinner1 = false;
          this.showSpinner2 = false;
          this.showSpinner3 = false;
          this.showForm = false;
          this.config.readOnly = true;
          this.addressEvent(response.result)
        }
      },
        error => (err) => {
        },
        () => {
        });
  }

  GetCompanyInformation() {
    this.showSpinner2 = true;
    this.companyInfoService.GetCompanyInformationData<ResponseBase>()
      .subscribe((response: ResponseBase) => {
        if (response) {
          this.companyInfoData = response.result;
          this.companyInfoData.imagePath = this.companyInfoData.logo;
          this.addressEvent(this.companyInfoData)
        }
        else {
        }
        this.showSpinner2 = false;
      },
        (err: any) => {
          this.showSpinner2 = false;
          this.translate.get("MESSAGES.Failed").subscribe({
            next: (res) => {
              this.notificationsService.error(res);
            }, error: err => { }
          });
        },
        () => {
        });
  }
  //TabChange(event) {
  //  //this.selectedIndex = event.index;
  //  //this.comapnyInfoSettingTabs
  //  this.getCompanyInfoSettingTabs(new RequestCompanyInfo());
  //}
  ChangeImage(event) {
    this.companyInfoData.image = event;
  }

  ngOnDestroy() {
    this.config.readOnly = false;
  }

  openOfferDrivingAmountModal() {
    const dialogRef = this.dialog.open(OfferDrivingAmountComponent, {
      height: '25%',
      width: '35%'
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  addressEvent(data) {
    const fullAddress = data && data.address && data.city && data.zipcode ? `${data.address}, ${data.city}, ${data.zipcode}` : '';
    this.googleMapDirectionService.Get<any>(fullAddress)
      .subscribe({
        next: (response: any) => {
          if (response.status == "OK") {
            const result = response.results[0];
            const location = result.geometry.location;
            this.latitude = location.lat;
            this.longitude = location.lng;
          }
        },
        error: err => { },
        complete: () => { }
      });
  }
  
}


