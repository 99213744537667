import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@fuse/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FusePipesModule } from 'app/core/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { PaginationModule } from 'app/main/pages/pagination/pagination.module';
import { VibeDropboxFileComponent } from './dropbox-files.component';
import { ViewAlertModelComponent } from './component/view-alert-model/view-alert-model.component';
import { CreteFolderModalComponent } from './component/create-folder-popup/create-folder-popup.component';
import { DropboxFilesService } from './dropbox-files.service';
import { FilterDropdownModule } from '../filter-dropdown/filter-dropdown.module';

@NgModule({
    declarations: [
        VibeDropboxFileComponent,
        ViewAlertModelComponent,
        CreteFolderModalComponent,
    ],
    imports: [
        CommonModule,
        MaterialModule,
        FlexLayoutModule,
        FusePipesModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        DatePickerModule,
        NgxMatSelectSearchModule,
        PaginationModule,
        FilterDropdownModule
    ],
    entryComponents: [
        VibeDropboxFileComponent,
        ViewAlertModelComponent,
        CreteFolderModalComponent
    ],
    exports: [
        VibeDropboxFileComponent,
        ViewAlertModelComponent,
        CreteFolderModalComponent
    ],
    providers: [DropboxFilesService],
})

export class VibeDropboxFilesModule { }