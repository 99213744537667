import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../../../core/modules/shared.module';
import { FormsModule } from '@angular/forms';
import { VibeCoWorkerComponent } from './coWorker.component';
import { NewCoWorkerComponent } from './new-co-worker/new-co-worker.component';
import { NgxMaskModule } from 'ngx-mask'
import { CoWorkersService } from './coWorkers.service';
import { ChangeEmailComponent } from '../change-email/change-email.component';
import { CoWorkerNotificationComponent } from './component/coWorker-notification/coWorker-notification.component';
import { CoworkerNotificationMessageComponent } from './component/notification-message/notification-message.component';
import { CoworkerEmploymentComponent } from './component/coworker-employment/coworker-employment.component';
import { NewCoworkerEmploymentComponent } from './component/new-coworker-employment/new-coworker-employment.component';
import { NgxCurrencyModule } from 'ngx-currency';
import { CustomCurrencyMaskConfig } from 'app/core/constant/CurrencyMaskConfiguartion';
import { DatePickerModule, DateTimePickerModule } from '@syncfusion/ej2-angular-calendars';
import { KeyComponent } from './component/key/key.components';
import { SendEmailPasswordComponent } from 'app/main/pages/coWorker/component/send-email-password/send-email-password.component';
import { CoWorkerUploadComponent } from './component/co-worker-upload/co-worker-upload.component';

const routes = [
    {
        path: 'coWorkers',
        component: VibeCoWorkerComponent,
        //resolve: {
        //  formsData: CoWorkersService
        //}
    },
    {
        path: 'coWorkers/add',
        component: NewCoWorkerComponent
    },
    {
        path: 'coWorkers/add-with-help',
        component: NewCoWorkerComponent
    },
    {
        path: 'coWorkers/edit/:id',
        component: NewCoWorkerComponent
    },
    {
        path: 'coWorker/upload',
        component: CoWorkerUploadComponent
    }
];

@NgModule({
    declarations: [
        VibeCoWorkerComponent,
        NewCoWorkerComponent,
        ChangeEmailComponent,
        CoWorkerNotificationComponent,
        CoworkerNotificationMessageComponent,
        KeyComponent,
        SendEmailPasswordComponent,
        CoworkerEmploymentComponent,
        NewCoworkerEmploymentComponent,
        CoWorkerUploadComponent
    ],
    imports: [
        SharedModule,
        RouterModule.forChild(routes),
        FormsModule, NgxMaskModule.forRoot(),
        NgxCurrencyModule.forRoot(CustomCurrencyMaskConfig),
        DatePickerModule,
        DateTimePickerModule
    ],
    entryComponents: [
        CoWorkerNotificationComponent,
        CoworkerNotificationMessageComponent,
        KeyComponent,
        SendEmailPasswordComponent,
        NewCoworkerEmploymentComponent
    ],
    exports: [
        VibeCoWorkerComponent,
        ChangeEmailComponent,
        CoworkerEmploymentComponent
    ],
    providers: [
        CoWorkersService
    ]
})

export class VibeCoWorkerModule {
}
