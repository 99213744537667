export const locale = {
    lang: 'ds',
    data: {
        'STATUS': {
            'Created': 'lavet',
            'Updated': 'Opdateret',
            'Deleted': 'Slettet',
            'Inactive': 'Inaktiv',
            'Active': 'Aktiv',
            'Statuschane': 'lave om',
            'FileUploaded': 'Fil uploadet',
            'FileDeleted': 'Fil slettet',
        },
        'TITLE': {
            'CustomerPagination': 'Antal log pr. side',
        },
        'BUTTONS': {
            'BACK': 'Gå tilbage',
            'BTNSAVE': 'Gem',
        },
        'FIELDS': {
            'SRNO': 'Sr nr.',
            'Status': 'Status',
            'Number': 'Nummer',
            'Name': 'Navn',
            'Createdby': 'OprettetAf',
            'CreatedDate': 'OprettetDato',
            'ModifyBy': 'ModificereAf',
            'ModifyDate': 'ModificereDato',
            'CoworkerName': 'Medarbejder',
            'Date': 'Dato',
            'Description': 'Tekst',
            'Action': 'Handling',
            'ModuleName': 'Modul',
            'ByCoworker': 'ved medarbejder',
            'ByCustomer': 'ved kunde'
        },
        'Headers': {
        },
        'Module': {
            'Project': 'Projekter',
            'Requisition':' Opgave',
            'RequisitionMaterial':'Opgave Materialer',
            'RequisitionCoworker':'Opgave Medarbejdere',
            'Offer':'Tilbud',
            'Inquiry': 'Forespørgsler',
            'MaterialEmail': 'Email',
            'StatusChangeAnswer': 'Status Lave om Svar'
        },
        'MESSAGES': {
            'NORECORD': 'Ingen registrering fundet',
            'SENDEMAIL':'{@CoworkerName} har sendt e-mail til {@SupplierName}.'
        },
        'TOOTIPORTITLE': {
            'ViewEmailDetails':'Se E-mail Detaljer'
        }
    }
};
