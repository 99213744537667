export const locale = {
    lang: 'ds',
    data: {
        'TITLE': {
            'Header-Email': 'Email legitimationsoplysninger',
        },
        'FIELDS': {
            'SMTP': 'SMTP',
            'SMTPPortNumber': 'SMTP Portnummer',
            'SMTPEmail': 'SMTP Email',
            'SMTPPassword': 'SMTP Adgangskode',
            'SMTPUserName': 'SMTP Brugernavn',
         },
        'VALIDATIONS': {
        },
        'MESSAGES': {
            'Saved': 'Gemt Succesfuldt.',
        },
        'BUTTONS': {
            'Save': 'Gem',
        }
    }
};

