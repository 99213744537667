export const locale = {
    lang: 'ds',
    data: {
        'BUTTONS': {
            'Save': 'Gem',
            'Back': 'Tilbage'
        },
        'FIELDS': {
            'Notification': 'Notifikation',
            'SMS': 'SMS',
            'Email': 'Email'
        },
        'MESSAGES': {
            'Created': 'Oprettet med succes',
            'Updated': 'Opdateret med succes',
            'Deleted': 'Slettet med succes',
            'Failed': 'Operation mislykket, prøv senere',
        },
    }
}