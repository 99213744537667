import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { VibeImgShowcaseComponent } from './vibe-img-showcase.component';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '@fuse/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
// import { FusePipesModule } from 'app/core/pipes/pipes.module';
import { ZoomImgComponent } from './zoom-img/zoom-img.component';

@NgModule({
  declarations:     [ VibeImgShowcaseComponent, ZoomImgComponent ],
  imports:          [ 
                      BrowserModule,
                      MaterialModule,
                      FlexLayoutModule,
                      // FusePipesModule,
                      TranslateModule,
                    ],
  exports:          [ VibeImgShowcaseComponent, ZoomImgComponent ],
  providers:        [ ],
  entryComponents:  [ ZoomImgComponent ]
})
export class VibeImgShowcaseModule { }
