export const locale = {
    lang: 'en',
    data: {
        'TITLE': {
            'NotificationSendConfigHeader': 'Notification Configuration',
        },
        'FIELDS': {
            'NotificationSCIsTimeBased':'Is Time Based ?',
            'NotificationSCIsSendEmail': 'Is Send Notification ?',
            'NotificationSCHoursFromSend': 'Hours from send',
            'NotificationSCHoursToSend': 'Hours to send',           
            'EmailTemplate':'Email Template',
        },
        'VALIDATIONS': {
            'NotificationSCHoursFromSend': 'Hours from send is required',
            'NotificationHoursFromSendSmaller': 'Hours from send should be smaller than hour to send',
            'NotificationSCHoursToSend': 'Hours to send is required',
            'NotificationHoursToSendGreater': 'Hours to send should be greater than hour from send',
        },
        'MESSAGES': {
            'Saved': 'Saved successfully.',
        },
        'BUTTONS': {
            'NotificationSCSave': 'Save',
        }
    }
};