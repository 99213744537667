import { ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseNavigationItem } from '@fuse/types';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { AuthService } from 'app/core/services/auth.service';
import { ReminderService } from 'app/main/pages/reminder/reminder.service';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { RequestReminder, RequestProjectOffer } from 'app/main/model/RequestBase';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { ProjectofferService } from 'app/main/pages/project-offer/project-offer.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

@Component({
  selector: 'fuse-nav-vertical-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss']
})
export class FuseNavVerticalItemComponent implements OnInit, OnDestroy {
  @HostBinding('class')
  classes = 'nav-item';

  @Input()
  item: FuseNavigationItem;

  reminderCount: number = 0;
  inquiryCount: number = 0;
  notDoneReminderCount: number = 0;
  subscription: Subscription;
  offerApprovedCount: number = 0;
  isSidebarFolded: boolean = false;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   */

  /**
   *
   * @param {ChangeDetectorRef} _changeDetectorRef
   * @param {FuseNavigationService} _fuseNavigationService
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _fuseNavigationService: FuseNavigationService,
    private authService: AuthService,
    private reminderService: ReminderService,
    private projectofferService: ProjectofferService,
    private datePipe: DatePipe,
    private _fuseSidebarService: FuseSidebarService,
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to navigation item
    merge(
      this._fuseNavigationService.onNavigationItemAdded,
      this._fuseNavigationService.onNavigationItemUpdated,
      this._fuseNavigationService.onNavigationItemRemoved
    ).pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {

        // Mark for check
        this._changeDetectorRef.markForCheck();
      });

    if (this.item.translate == 'NAV.DASHBOARD.TITLE') {
      this.getReminder();
    }
    if (this.item.translate == 'NAV.INQUIRY.TITLE') {
      this.GetInquiryCount();
    }
    if (this.item.translate == 'NAV.REMINDERS.TITLE') {
      this.GetNotDoneReminderCounts();
    }
    if (this.item.translate == 'NAV.OFFER.TITLE') {
      this.getProjectOfferList();
    }
  }
  ngAfterViewInit() {
    this.authService.inquiryCountChangeObs.subscribe((data) => {
      this.inquiryCount = data;
      if (!this._changeDetectorRef['destroyed']) {
        this._changeDetectorRef.detectChanges();
      }
    });

    this.authService.reminderCountChangeObs.subscribe((data) => {
      this.reminderCount = data;
      if (!this._changeDetectorRef['destroyed']) {
        this._changeDetectorRef.detectChanges();
      }
    });

    this.authService.notDoneReminderCountChangeObs.subscribe((data) => {
      this.notDoneReminderCount = data;
      if (!this._changeDetectorRef['destroyed']) {
        this._changeDetectorRef.detectChanges();
      }
    });

    this.authService.offerApprovedCountChangeObs.subscribe((data) => {
      this.offerApprovedCount = data;
      if (!this._changeDetectorRef['destroy']) {
        this._changeDetectorRef.detectChanges();
      }
    });
    this._fuseSidebarService.isSidebarFolded.subscribe((data) => {
      this.isSidebarFolded = data;
      if (!this._changeDetectorRef['destroy']) {
        this._changeDetectorRef.detectChanges();
      }
    })
  }

  // Inquiry Count
  GetInquiryCount() {
    var coworkerId = this.authService.getCoWorkerId();
    if (coworkerId) {
      this.reminderService.GetInquiryCountapi<ResponseBase>()
        .subscribe({
          next: (response: ResponseBase) => {
            if (response != null) {
              //this.inquiryCount = response.result;
              //this.reminderService.setInquiryCounts(response.result);
              this.authService.setInquiryCount(response.result);
            }
          },
          error: err => {
          },
          complete: (
          ) => {
          }
        });
    }
  }

  //Reminder Count
  getReminder() {
    var model = new RequestReminder();
    model.ReminderDate = this.datePipe.transform(new Date(), "yyyy-MM-dd");
    model.CoworkerId = this.authService.getCoWorkerId();
    model.IsDeleted = false;
    if (model.CoworkerId) {
      this.reminderService.Get<ResponseBase>(model)
        .subscribe({
          next: (response: ResponseBase) => {
            if (response != null) {
              //this.reminderCount = response.result.length;
              this.authService.setReminderCount(response.result.length);
              //this.reminderService.totalReminderCount(response.result);
            }
            else {
              this.authService.setReminderCount(0);
            }
          },
          error: err => {
          },
          complete: (
          ) => {
          }
        });
    }
  }



  //Not Done Reminder 
  GetNotDoneReminderCounts() {
    this.reminderService.GetNotDoneReminderCountApi<ResponseBase>(this.authService.getCoWorkerId())
      .subscribe({
        next: (response: ResponseBase) => {
          if (response != null) {
            //this.notDoneReminderCount = response.result;
            this.authService.setNotDoneReminderCount(response.result);
            //this.reminderService.setNotDoneReminderCount(response.result);
          }
        },
        error: err => {
        },
        complete: (
        ) => {
        }
      });
  }


  getProjectOfferList() {
    var requestModel = new RequestProjectOffer();
    this.projectofferService.Get<ResponseBase>(requestModel)
      .subscribe((response: ResponseBase) => {
        if (response != null) {
          var approvedOffer = response.result.filter(x => x.status == 3)
          this.authService.setApprovedOfferCount(approvedOffer.length);
        }
      },
        error => (err) => {
        },
        () => {
        });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
