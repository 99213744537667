import { Component, OnInit, ElementRef, ViewChild, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { MatOption } from '@angular/material';
import { Router } from '@angular/router';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { RequestStatusProject } from 'app/main/model/RequestBase';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { ProjectIndoorOutDoorStatus, yesOrNoOptions } from 'app/main/model/ProjectStatus';
import { ProjectStatus } from 'app/constant/common.constant';
import { ProjectService } from 'app/main/pages/project/project.service';
import { AuthService } from 'app/core/services/auth.service';
import * as moment from 'moment';


@Component({
    selector: 'vibe-project-list',
    templateUrl: './project-list.component.html',
    styleUrls: ['./project-list.component.scss']
})
export class ProjectListComponent implements OnInit, AfterViewInit {
    @Output() selectProjectEvent = new EventEmitter<string>();
    @Input() projectNumber: string;
    @ViewChild('allStatusSelected', { static: false }) private allStatusSelected: MatOption;
    @ViewChild('allIndoorOutdoorStatusSelected', { static: false }) private allIndoorOutdoorStatusSelected: MatOption;
    @ViewChild('projectSearch', { static: false }) projectsearch: ElementRef;
    selectIndoorOutDoorStatus = ProjectIndoorOutDoorStatus;
    projectStatus = ProjectStatus;
    currentselectedIndoorOutdoorObj: number[];
    currentselectedStatusObj: number[];
    requestProject = new RequestStatusProject();
    Projects: any[];
    After5DayDate: string = "";
    TodayDate: string = "";
    isActive: boolean = true;
    showSpinner: boolean = false;
    roleName: string;
    isComingFromProject: boolean = false;
    yesOrNoOptions = yesOrNoOptions;
    orderBy: string = "Number DESC";
    limitNumber: any;
    isCommingFrom:string = "planning";
    ownProjectView: boolean = false;
    roleClaims:any;
    //Initiators
    constructor(private translationLoader: FuseTranslationLoaderService,
        private _router: Router,
        private authService: AuthService,
        private projectservice: ProjectService) {
        this.translationLoader.loadTranslations(english, danish);
        this.roleClaims = this.authService.getRoleClaimsList();
    }

    ngOnInit() {
        this.authService.hasClaim(this.roleClaims.Own_Project_View).subscribe(hasClaim => {
            if (hasClaim) 
                this.ownProjectView = hasClaim
             else 
                this.ownProjectView = false;
        });
        this.projectStatus = this.projectStatus.filter(x => (x.id != 2 && x.id != 4 && x.id != 7 && x.id != 10 && x.id != 12));
        this.isActive = true;
        this.roleName = this.authService.getUserRole();
        this.requestProject = this.projectservice.getRequestParameterForCalender();
        this.requestProject.isLift = false;
        this.requestProject.isRiseman = false;
        this.requestProject.CoworkerId = "";
        this.requestProject.IsActive = true;
        this.requestProject.IsDeleted = false;
        var projectNumber = this.projectNumber;
        if (projectNumber) {
            this.requestProject.Address = projectNumber;
            this.isComingFromProject = true;
        }
        else {
            this.requestProject.Address = "";
        }
        this.orderBy = this.requestProject.OrderBy;
        // if (this.roleName == "Admin") {
        // }
        // else if (this.roleName == "Kontor") {
        // }
        // else if (this.roleName == "Maler") {
        //     this.requestProject.CoworkerId = this.authService.getCoWorkerId();
        // }
        // else if (this.roleName == "Gaest") {
        // }
        // else {
        // }
        if(this.ownProjectView)
            this.requestProject.CoworkerId = this.authService.getCoWorkerId();
        this.currentselectedStatusObj = [];
        if (this.requestProject.multipleStatus) {
            var statusObj = this.requestProject.multipleStatus.split(",");
            for (var i = 0; i < statusObj.length; i++) {
                this.currentselectedStatusObj.push(Number(statusObj[i]));
            }
        }
        this.currentselectedIndoorOutdoorObj = [];
        if (this.requestProject.isIndoorOutdoorTypeProjects) {
            var obj = this.requestProject.isIndoorOutdoorTypeProjects.split(",");
            for (var i = 0; i < obj.length; i++) {
                this.currentselectedIndoorOutdoorObj.push(Number(obj[i]));
            }
        }
        this.GetProjects(this.requestProject, true);
        this.TodayDate = moment(new Date()).format('YYYY-MM-DD');
        this.After5DayDate = moment(this.TodayDate, "YYYY-MM-DD").add(5, 'days').format('YYYY-MM-DD');
        //this.requestProject.Address = "821";
        //this.SearchBynumber("");
        this.requestProject = this.projectservice.getRequestParameter();
    }

    onSetActive(isActive) {
        this.isActive = isActive
        this.requestProject.Address = "";
        this.requestProject.IsActive = isActive;
        this.GetProjects(this.requestProject, true);
    }

    onChangeSearchFilter() {
        var LiftStigemandValues = [];
        if (this.requestProject.isLift == true || this.requestProject.isRiseman == true) {
            LiftStigemandValues = [5, 6, 8, 11, 12]
            this.currentselectedStatusObj = LiftStigemandValues;
            this.requestProject.multipleStatus = "5, 6, 8, 11, 12"
        }
        setTimeout(() => {
            var selectedPersonalAndBuitik = false;
            if (this.currentselectedIndoorOutdoorObj == undefined) {
                this.currentselectedIndoorOutdoorObj = [];
            }

            for (var i = 0; i < this.currentselectedIndoorOutdoorObj.length; i++) {
                if (this.currentselectedIndoorOutdoorObj[i] == 5 || this.currentselectedIndoorOutdoorObj[i] == 6) {
                    selectedPersonalAndBuitik = true;
                    break;
                }
            }
            // if (selectedPersonalAndBuitik) {
            //     this.currentselectedStatusObj = [];
            //     var statusObj = ("8,9").split(",");
            //     for (var i = 0; i < statusObj.length; i++) {
            //         this.currentselectedStatusObj.push(Number(statusObj[i]));
            //     }
            //     this.requestProject.multipleStatus = "8,9";
            // }
            this.requestProject.isIndoorOutdoorTypeProjects = this.currentselectedIndoorOutdoorObj.join().toString();
            this.GetProjects(this.requestProject);
        }, 1000)
    }

    DropDownStatusChange(value) {
        var offerStatusSelected = this.currentselectedStatusObj.find(o => o == 4);
        var status = "";
        if (offerStatusSelected) {
            this.requestProject.IsActive = false;
            this.isActive = false;
        }
        if (value.id == 4 && !offerStatusSelected) {
            this.requestProject.IsActive = true;
            this.isActive = true;
        }
        if (this.currentselectedStatusObj.length <= 0) {
            status = "";
        }
        else {
            status = this.currentselectedStatusObj.join();
        }
        this.requestProject.multipleStatus = status;
        this.GetProjects(this.requestProject);
    }
    SearchBynumber() {
        this.requestProject.Address = this.requestProject.Address.trim();
        //For IndoorOutdoor select
        this.currentselectedIndoorOutdoorObj = [];
        this.requestProject.isIndoorOutdoorTypeProjects = "";
        for (var i = 0; i < 7; i++) {
            this.currentselectedIndoorOutdoorObj.push(i);
        }
        this.requestProject.isIndoorOutdoorTypeProjects = this.currentselectedIndoorOutdoorObj.join().toString();
        //For Status select
        this.currentselectedStatusObj = [];
        this.requestProject.multipleStatus = "";
        if (this.roleName == "Maler") {
            for (var i = 0; i < 13; i++) {
                if (i != 9 && i != 12) {
                    this.currentselectedStatusObj.push(i);
                }
            }
        }
        else {
            for (var i = 0; i < 13; i++) {
                this.currentselectedStatusObj.push(i);
            }
        }
        this.requestProject.multipleStatus = this.currentselectedStatusObj.join().toString();
        this.requestProject.isLift = false;
        this.requestProject.isRiseman = false;
        this.GetProjects(this.requestProject);
    }
    //get Project list
    GetProjects(model: RequestStatusProject, isNgOnLoad: boolean = false) {
        this.showSpinner = true;
        //this.search = this.requestProject.Address;
        this.projectservice.setRequestParameterForCalender(model);
        this.projectservice.Get<ResponseBase>(model)
            .subscribe((response: ResponseBase) => {
                if (response != null) {
                    this.Projects = [];
                    this.Projects = response.result;
                    if (this.Projects && this.Projects.length > 0) {
                        this.projectservice.setBGImageForProjectCards(this.Projects);
                    }
                    else
                        this.Projects = [];
                    this.showSpinner = false;
                    for (var i = 0; i < this.Projects.length; i++) {
                        var formateDate = moment(this.Projects[i].startDate).format('YYYY-MM-DD');
                        if (this.After5DayDate >= formateDate)
                            this.Projects[i].displayStartDateRed = true;
                        else
                            this.Projects[i].displayStartDateRed = false;
                    }
                    if (this.isComingFromProject && this.Projects && this.Projects.length > 0 && isNgOnLoad) {
                        var proj = this.Projects.find(o => o.sagNumber == this.projectNumber);
                        if (proj) {
                            this.newEvent(proj);
                        }
                    }
                }
                else {
                    this.Projects = [];
                    this.showSpinner = false;
                }
            },
                error => (err) => {
                    this.Projects = [];
                    this.showSpinner = false;
                },
                () => {
                });
    }

    newEvent(project) {
        this.selectProjectEvent.emit(project);
    }

    toggleAllStatusIndoorOutDoorSelection() {
        this.currentselectedIndoorOutdoorObj = [];
        this.requestProject.isIndoorOutdoorTypeProjects = "";
        if (this.allIndoorOutdoorStatusSelected.selected) {
            for (var i = 0; i < 7; i++) {
                this.currentselectedIndoorOutdoorObj.push(i);
            }
            this.requestProject.isIndoorOutdoorTypeProjects = this.currentselectedIndoorOutdoorObj.join().toString();
        }
        this.GetProjects(this.requestProject);
    }

    toggleAllStatusSelection() {
        this.currentselectedStatusObj = [];
        this.requestProject.multipleStatus = "";
        if (this.allStatusSelected.selected) {
            if (this.roleName == "Maler") {
                for (var i = 0; i < 13; i++) {
                    if (i != 9 && i != 12) {
                        this.currentselectedStatusObj.push(i);
                    }
                }
            }
            else {
                for (var i = 0; i < 13; i++) {
                    this.currentselectedStatusObj.push(i);
                }
            }
            this.requestProject.multipleStatus = this.currentselectedStatusObj.join().toString();
        }
        this.GetProjects(this.requestProject);
    }

    receiveFilterEvent(event) {
        if (this.currentselectedStatusObj == undefined) {
            this.currentselectedStatusObj = [];
        }
        this.requestProject.multipleStatus = this.currentselectedStatusObj.join().toString();
        this.orderBy = event;
        this.requestProject.OrderBy = this.orderBy;
        if (this.limitNumber == undefined) {
            this.requestProject.Limit = 100
        }
        else {
            this.requestProject.Limit = this.limitNumber;
        }
        this.GetProjects(this.requestProject);
    }
    truncDecimalpoint(value) {
        return Math.trunc(value)
    }

    ngAfterViewInit() {
        this.authService.OpenMatDrawerFlagChangeObs.subscribe((data) => {
            if (data) {
                setTimeout(() => {
                    this.projectsearch.nativeElement.focus();
                }, 250);
            }
        });

        this.projectservice.ProjectListChange.subscribe((data) => {
            if (data) {
                this.GetProjects(this.requestProject);
            }
        });
    }
}
