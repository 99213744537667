export const locale = {
    lang: 'ds',
    data: {
        'MESSAGES': {
            'Created': 'Oprettet med succes',
            'Updated': 'Opdateret med succes',
            'Deleted': 'Slettet med succes',
            'Failed': 'Operation mislykket, prøv senere',
            'FeildUnderFinishFolder': '*** Denne projektmappe er under færdigmappe.',
            'NORECORD': 'placering ikke tilføjet',
        },
        'FIELDS': {
            'FullAddress': 'Fuld adresse',
            'Vehicle': 'Køretøj',
            'StartDate': 'Start dato',
            'EndDate': 'Indtil dato',
            'RouteDate': 'Rute dato',
            'Route': 'Rute',
            'MetersTrip': 'Meters tur',
            'Group': 'Gruppe',
            'Devices': 'Enheder',
            'Labels': 'Kategori',
            'Location': 'Beliggenhed'
        },
        'FILTERS': {
            'All': 'Alle',
          },
          'BUTTONS':{
            'Edit': 'Redigere'
        }   
    }
};

