export const locale = {
    lang: 'en',
    data: {
        'FIELDS': {
            'Designation': 'Designation',
            'ValideFrom': 'Valide From',
            'SRNO': 'Sr no.'
        },
        'BUTTONS': {
            'Save': 'Save',
            'BACK': 'Back',
            'Reset': 'Reset',
            'ShowOnMap': 'Show On Map',
            'BTNCOMPLETED': 'Completed',
            'Delete': 'Delete',
        },
        'MESSAGES': {
            'Updated': 'Updated Successfully.',
            'Failed': 'Failed',
            'Deleted': 'Deleted successfully',
            "NORECORD": "No Record Found",
            'Created': 'Created successfully',
            'CompletedNextService': 'Do you want to change the next service to  {Date} ?',
            'DeviceAlreadyAssigned':'This nanolink device ID is already assigned to some other devices.'
        },
        'Validation': {
            'BrandRequired': 'Brand is required',
            'ModelRequired': 'Model is required',
            'GroupRequired': 'Group is required',
            'LablesRequired': 'Lables is required',
            'WhiteSpaceNotAllowed': 'Only white space not allowed'
        },
        'Headers': {
            'Title': 'Assets',
            'DeleteMessage': 'Are you sure you want to delete',
            'Rules': 'Rules',
        },
        'Lable': {
            'SRNO': 'Sr nr.',
            'Image': 'Image',
            'Brand': 'Brand',
            'Model': 'Model',
            'Created': 'Created',
            'SerialNumber': 'Serial number',
            'Keywords': 'Keywords',
            'Description': 'Description',
            'Group': 'Group',
            'Labels': 'Labels',
            'VID': 'vID',
            'NanolinkDeviceID': 'Nanolink DeviceID',
            'LastServiceDate': 'Last Service Date',
            'NextServiceDate': 'Next Service Date',
            'LastSeen': 'Last Seen'
        },
        'ratesType': {
            'valueA': 'A Hourly rate',
            'valueB': 'B Hourly Wages Overtime 1',
            'valueC': 'C Hourly Wages Overtime 2',
            'valueJ': 'J Sick pay per. hour',

            'valueK': 'Vacation',
            'valueL': 'Holiday of the work',
            'valueM': 'Holiday with payment',
            'valueN': 'Holiday without payment',
            'valueO': 'School or learning',
            'valueP': 'Other things',
            'valueQ': 'Offer',
        },
        'FILTERS': {
            'All': 'All',
        },
        'selectedValueWeekDays': {
            'value1': 'Monday',
            'value2': 'Tuesday',
            'value4': 'Wednesday',
            'value8': 'Thursday',
            'value16': 'Friday',
            'value32': 'Saturday',
            'value64': 'Sunday'
        },
    }
};