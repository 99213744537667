export const locale = {
  lang: 'ds',
  data: {
    'DASHBOARD': {
      'TITLE': 'Dashboard',
      'DESC': 'Hej Dashboard!',
      'ComingSoon': 'Kommer snart',
      'Bulletinboard': 'Opslagstavle',
      'ReceivedToMe': 'Modtaget til mig',
      'SentByMe': 'Sendt af mig',
      'Projects': 'Projekter',
      'From': 'Fra',
      'To': 'til',
      'Reminders': 'Påmindelse'
    },
    'BUTTONS': {
        'CreateReminder': 'Create Reminder',
        'VehicleInfo':'Køretøjs Info',
        'Search': 'Søge',
    },
    'MESSAGES': {
      'NORECORD': 'Ingen registrering fundet',
      'NO_REMINDER_RECORD': 'Ingen påmindelser fundet',
      'NO_PROJECT_RECORD': 'Ingen Projekter fundet',
      'Created': 'Oprettet med succes.',
      'Updated': 'Opdateret med succes.',
      'Deleted': 'Slettet med succes.',
      'Failed': 'Noget gik galt !',
    },
    'FIELDS': {
      'CaseNumber': 'Sagsnummer',
      'Title': 'Titel',
      'Client': 'Klient',
      'Address': 'Adresse',
      'StartDate': 'Start dato',
      'EndDate': 'Slutdato',
      'CoWorkers': 'Co-Workers',
      'Files': 'Filer',
      'actionsColumn': 'Handling',
      'From': 'Fra',
      'To': 'til',
      'Showallmyprojects': 'Vis alle mine projekter',
      'Showallprojects': 'Vis mine projekter',
      'HoursBack': 'Timer tilbage',
      'Offers': 'Tilbud',
      'Show60DayOld': 'Vis 60 dage gammel',
      'MtoM': 'M til M',
      'Note': 'Bemærk',
      'BADGE': 'Inaktive',
      'RemainingTime': 'Resterende tid',
      'Planned': 'Planlagt',
      'HourlyWage': 'Timeløn',
      'IndoorOutdoorStatusValue5': 'Personale køb',
      'visit': 'Besøg',
      'SelectDate': 'vælg dato'
    },
    // 'selectedValueStatus': {
    //   'value0': 'Anbefalet',
    //   'value1': 'Process',
    //   'value2': 'Planlagt besøg',
    //   'value3': 'Klar til opstart',
    //   'value4': 'Tilbud sendt',
    //   'value5': 'Tilbud godkendt',
    //   'value6': 'Planlagt',
    //   'value7': 'Tilbud afvist',
    //   'value8': 'Startet',
    //   'value9': 'Udført',
    //   'value10': 'Faktureret',
    //   'value11': 'Afventer',
    //   'value12': 'Klar fakturering',
    // },
    'Today': 'I dag',
    'Tomorrow': 'I morgen',
    'Yesterday': 'I går'
  },
  'selectedValueIndoorOutdoorStatus': {
    'value1': 'Indendørs ',
    'value2': 'Udendørs',
    'value3': 'Sprøjtemaling',
    'value4': 'Reklamation',
    'value5': 'Personale køb', //drawing of painter
    'value6': 'Butiks køb'     //Nordsjø logo
  },
};

