export const locale = {
  lang: 'ds',
  data: {
    'FIELDS': {
      'Add': 'Tilføj',
      'SelectProjectToAdd': 'Vælg projekt',
      'SagNo': 'Sag No.',
      'Address': 'Adresse',
      'OpgaverText': 'Opgave Tekst',
      'StartDate': 'Start dato ',            
      'EndDate': 'Slut dato ',
      'Zipcode': 'Post.',
      'City': 'By',
      'Number': 'Sr.',
      'Status': 'Status',
    }
  }
}

