export const locale = {
    lang: 'ds',
    data: {
        'Headers': {
            'Yes': 'JA',
            'No': 'NEJ',
            'AllRequisitionCoworker': 'Er du sikker på at Vil du gøre det på alle opgaver?',
        },
        'FIELDS': {
            'ChangeStatus': "Change Staus",
            'ChangedStatus': "Changed Status",
            'To': 'to',
            'EmptyEconomyFields':'--> Økonomi felter udfyldes ikke.'
        }
    }
};
