import { Component, OnInit, Optional } from "@angular/core";
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { TitleService } from 'app/core/services/title.service';
import { MatDialog, MatDialogRef } from "@angular/material";
import { CalendarDefaultCoworkerComponent } from './component/calendar-default-coworker/calendar-default-coworker.component';
import { QuestionPopupComponent } from './component/question-popup/question-popup.component';
import { CoWorkerComponent } from './component/coWorker/coWorker.component';
import { WeatherTypeComponent } from './component/weather-type/weather-type.component';
import { PostNummerComponent } from './component/postNummer/postNummer.component';
import { DrivingAmountComponent } from './component/driving-amount/driving-amount.component';
import { MaterialColorCodeComponent } from './component/colorCode/colorCode.component';
import { OfferMaterialForExcel } from "app/main/model/Material";
import { ResponseBase } from "app/main/model/ResponseBase";
import { OfferMaterialWithPrice } from "app/main/model/OfferV2";
import { ProjectofferService } from "app/main/pages/project-offer/project-offer.service";
import { SettingsService } from 'app/main/pages/settings/settings.service';
import { AuthService } from "app/core/services/auth.service";
import { DenmarkDigitFormate } from "app/core/pipes/denmarkDigitFormate.pipe";
import { ActivatedRoute, Router } from "@angular/router";
import { ShowInactiveProjectComponent } from "app/main/pages/project/component/show-inactive-project/show-inactive-project.component";
import { Settings } from "app/main/model/Settings";
import { ViewQualityTestImagesComponent } from "app/main/pages/project-offer/component/view-qualitytest-images/view-qualitytest-images.component";

@Component({
    selector: 'vibe-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})

export class SettingsComponent implements OnInit {
    selectedIndex: number = 0;
    showSpinner = false;
    offerMaterialList: OfferMaterialWithPrice[] = [];
    public denmarkDigitFormate = new DenmarkDigitFormate();
    reqTabIndex: number = 0;
    projectSettings: any[] = [];
    SettingObj: Settings[];
    OfferAcceptedNotificationSettingObject: any;
    roleClaims: any;
    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private dialog: MatDialog,
        @Optional() public dialogRef: MatDialogRef<MaterialColorCodeComponent>,
        private projectofferService: ProjectofferService,
        private settingsService: SettingsService,
        private authService: AuthService,
        private _router: Router,
        private _route: ActivatedRoute,
        private titleService: TitleService) {
        this.translationLoaderService.loadTranslations(danish, english);
        this.titleService.SetTitle("TITLE_SETTINGS");
    }

    ngOnInit() {
        this.getSendTabSettings();
        this._route.queryParams.subscribe(params => {
            this.selectedIndex = params['reqTabIndex'] ? params['reqTabIndex'] : this.selectedIndex;
            if(this.selectedIndex == 0)
                this.getWorkingHoursDetailsSetting();
        });
        this.roleClaims = this.authService.getRoleClaimsList();
    }

    getSendTabSettings() {
        this.showSpinner = true;
        this.settingsService.GetSettingsWithTemplateValue<ResponseBase>("Notification", "OfferAcceptedToCustomer", null).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.result && response.result.myResponse.length > 0) {
                    this.SettingObj = response.result.myResponse;
                    this.SettingObj.forEach(element => {
                        if(element.key2 == "OfferAcceptedToCustomer") {
                            this.OfferAcceptedNotificationSettingObject = element;
                        }
                    });
                }
            },
            error: err => {0
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    TabChange(event) {
        this.selectedIndex = event.index;
    }

    defaultCoWorkerPopup() {
        const dialogRef = this.dialog.open(CalendarDefaultCoworkerComponent, {
            height: '80%',
            width: '80%',
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result != null && result != '') {
            }
        });
    }

    OpenQuestionSetting() {
        const dialogRef = this.dialog.open(QuestionPopupComponent, {
            height: '70%',
            width: '60%',
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {

            }
        });
    }

    coWorkerPopupModal() {
        const dialogRef = this.dialog.open(CoWorkerComponent, {
            height: '70%',
            width: '80%',
            data: null
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result != null && result != '') {
            }
        });
    }

    OpenWeatherPopup() {
        const dialogRef = this.dialog.open(WeatherTypeComponent, {
            height: "60%",
            width: "70%",
            data: ""
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result != null) {

            }
        });
    }

    openPostNumberDiscountPopup() {
        const dialogRef = this.dialog.open(PostNummerComponent, {
            height: "80%",
            width: "80%",
            data: ""
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result != null) {

            }
        });
    }

    openDrivingAmtPopup() {
        const dialogRef = this.dialog.open(DrivingAmountComponent, {
            height: '25%',
            width:'35%'
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {

            }
        });
    }

    OpenColorCode() {
        const dialogRef = this.dialog.open(MaterialColorCodeComponent, {
            height: "60%",
            width: "70%",
            data: ""
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result != null) {

            }
        });
    }

    getOfferMaterial() {
        this.projectofferService.GetOfferCategoryMaterial().subscribe({
            next: (response: ResponseBase) => {
                this.offerMaterialList = response.result.myResponse;
                this.exportExcel();
            },
            error: err => {
                this.showSpinner = false;
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    exportExcel() {
        var listObj = [];
        var obj = new OfferMaterialForExcel();
        obj.val1 = 'Vare nummer';
        obj.val2 = 'Tilbudsnavn';
        obj.val3 = 'Antal gange';
        obj.val4 = 'Maling tekst';
        obj.val5 = 'Pris';
        listObj.push(obj);
        //this.offerMaterialList =this.offerMaterialList.filter(x=>x.materialNumber=="36451258")
        for (var i = 0; i < this.offerMaterialList.length; i++) {
            var materialReport = new OfferMaterialForExcel();
            if (this.offerMaterialList[i].offerMaterialPriceList) {
                for (let j = 0; j < this.offerMaterialList[i].offerMaterialPriceList.length; j++) {
                    var materialReport = new OfferMaterialForExcel();
                    materialReport.val1 = "";
                    materialReport.val2 = "";
                    if (j == 0) {
                        materialReport.val1 = this.offerMaterialList[i].materialNumber ? this.offerMaterialList[i].materialNumber : '';
                        materialReport.val2 = this.offerMaterialList[i].text ? this.offerMaterialList[i].text : '';
                    }
                    materialReport.val3 = this.offerMaterialList[i].offerMaterialPriceList[j].type ? this.projectofferService.getPaintType(this.offerMaterialList[i].offerMaterialPriceList[j].type) : '';
                    materialReport.val4 = this.offerMaterialList[i].offerMaterialPriceList[j].paintText ? this.offerMaterialList[i].offerMaterialPriceList[j].paintText : '';
                    materialReport.val5 = this.offerMaterialList[i].offerMaterialPriceList[j].price ? this.denmarkDigitFormate.transform(this.offerMaterialList[i].offerMaterialPriceList[j].price.toFixed(2)).toString() : '0';
                    listObj.push(materialReport);
                }
            }
            else {
                materialReport.val1 = "";
                materialReport.val2 = "";
                materialReport.val3 = "";
                materialReport.val4 = "";
                materialReport.val5 = "";
                listObj.push(materialReport);
            }
        }

        var csvContent = '';
        for (var i = 0; i < listObj.length; i++) {
            csvContent = csvContent + listObj[i].val1 + ';' + listObj[i].val2 + ';' + listObj[i].val3
                + ';' + listObj[i].val4 + ';' + listObj[i].val5 + '\n';
        }
        var download = document.createElement("a");
        var blob = new Blob(["\ufeff", csvContent]);

        download.href = window.URL.createObjectURL(blob);
        download.download = 'OfferMateriallerList' + '.csv';

        var event = document.createEvent("MouseEvents");
        event.initMouseEvent(
            "click", true, false, window, 0, 0, 0, 0, 0
            , false, false, false, false, 0, null
        );
        download.dispatchEvent(event);
    }

    getWorkingHoursDetailsSetting() {
        this.showSpinner = true;
        this.settingsService.GetSettingsValue<ResponseBase>("Project", null, null).subscribe({
            next: (response: ResponseBase) => {
                if (response != null && response.result && response.result.myResponse.length>0) {
                    this.showSpinner = false;
                    if (response.result && response.result.myResponse) 
                        this.projectSettings = response.result.myResponse;
                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }
    showInactiveProjectList() {
        const dialogRef = this.dialog.open(ShowInactiveProjectComponent, {
            height: "90%",
            width: "90%",
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) 
                this.editProject(result)
        });
    }

    editProject(id) {
        this._router.navigate(['/projects/edit', id], { queryParams: { isActive: false } });
    }    

    openImage() {
        const dialogRef = this.dialog.open(ViewQualityTestImagesComponent, {
            height: '90%',
            width: '90%',
            data: {}
        });
        dialogRef.afterClosed().subscribe(result => {
            // if (result) {
            // }
        });
    }
}