import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observer } from 'rxjs/Observer';
import { CoWorkerWorkHour } from '../../model/CoWorkerWorkHour';
import { ApiConstant } from '../../../../app/core/constant/ApiConstant';
import { RequestWorkHourGroupBy, RequestWorkHour, RequestPaySlip, RequestMovePlanningWithRanage, RequestCloseCoworkersPayment } from '../../model/RequestBase';
import { WorkHour, LeaveApplyOnPlanning } from '../../model/WorkHour';
import * as moment from 'moment';

@Injectable()
export class WorkhoursService //implements Resolve<any>
{
    private actionUrl: string;
    private actionUrlWorkHour: string;
    constructor(private http: HttpClient) {
        this.actionUrl = ApiConstant.API_URL + ApiConstant.COWORKER + "/";
        this.actionUrlWorkHour = ApiConstant.API_URL + ApiConstant.WORK_HOURS + "/";
    }

    public Get<T>(CoWorkerid: string, requestWorkHourGroupBy: RequestWorkHourGroupBy): Observable<T> {
        let search = this.GetParams(requestWorkHourGroupBy);
        return this.http.get<T>(this.actionUrl + CoWorkerid + "/workhours", {
            params: search
        });
    }

    public GetWorkhoursList<T>(CoWorkerid: string, requestWorkHourGroupBy: RequestWorkHourGroupBy): Observable<T> {
        let search = this.GetParams(requestWorkHourGroupBy);
        return this.http.get<T>(this.actionUrl + CoWorkerid + "/workhourslist", {
            params: search
        });
    }

    public GetNonWorkHourGroupBy<T>(CoWorkerid: string, requestWorkHourGroupBy: RequestWorkHourGroupBy): Observable<T> {
        let search = this.GetParams(requestWorkHourGroupBy);
        return this.http.get<T>(this.actionUrl + CoWorkerid + "/nonworkhours", {
            params: search
        });
    }

    public GetWorkHours<T>(requestWorkHour: RequestWorkHour): Observable<T> {
        let search = this.GetParams(requestWorkHour);
        return this.http.get<T>(this.actionUrlWorkHour, {
            params: search
        });
    }

    public GetNonWorkHours<T>(requestWorkHour: RequestWorkHour): Observable<T> {
        let search = this.GetParams(requestWorkHour);
        return this.http.get<T>(this.actionUrlWorkHour + "nonworkhours", {
            params: search
        });
    }

    public GetSingle<T>(id: any): Observable<T> {
        return this.http.get<T>(this.actionUrlWorkHour + id);
    }
    public GetRequistionPlanning<T>(id: any, date: any): Observable<T> {
        return this.http.get<T>(this.actionUrlWorkHour + "getWorkhourByRequisitionById?requisitionId=" + id + "&date=" + date);
    }

    public GetCoworkerListAndPlanningDate<T>(sagId: any, sagOpgaveId: any): Observable<T> {
        return this.http.get<T>(this.actionUrlWorkHour + "getCoworkerListAndPlanningDate?sagId=" + sagId + "&sagOpgaveId=" + sagOpgaveId);
    }

    public Add<T>(workhour: WorkHour): Observable<T> {
        return this.http.post<T>(this.actionUrlWorkHour, workhour);
    }
    public AddWorkhoursWithDateRange<T>(workhour: WorkHour): Observable<T> {
        return this.http.post<T>(this.actionUrlWorkHour + 'addWorkHourWithDateRange', workhour);
    }
    public AddWithDateRange<T>(workhour: WorkHour): Observable<T> {
        return this.http.post<T>(this.actionUrlWorkHour + "addWorkHourWithDateRangeforplanning", workhour);
    }

    public AddScheduleDays<T>(workhours: any): Observable<T> {
        return this.http.post<T>(this.actionUrlWorkHour + "addWorkHourScheduleWithDateRangeforplanning", workhours);
    }

    public MoveWithDateRange<T>(workhour: WorkHour): Observable<T> {
        return this.http.post<T>(this.actionUrlWorkHour + "movePlanningWithDateRange", workhour);
    }

    public UpdateWithDateRange<T>(workhour: WorkHour): Observable<T> {
        return this.http.put<T>(this.actionUrlWorkHour + "updatenonworkinghourforplanning", workhour);
    }
    public InquiryUpdateWithDateRange<T>(workhour: WorkHour): Observable<T> {
        return this.http.put<T>(this.actionUrlWorkHour + "updateinquiryworkinghourforplanning", workhour);
    }

    public Move<T>(workhour: WorkHour): Observable<T> {
        return this.http.post<T>(this.actionUrlWorkHour + "moveworkhoursforplanning", workhour);
    }

    public MovePlanWithDateRange<T>(workhour): Observable<T> {
        return this.http.put<T>(this.actionUrlWorkHour + "moveworkhoursforplanningWithDateRange", workhour);
    }

    public Update<T>(id: any, workhour: WorkHour): Observable<T> {
        return this.http.put<T>(this.actionUrlWorkHour + id, workhour);
    }
    public UpldateWithList<T>(workhour: WorkHour): Observable<T> {
        return this.http.post<T>(this.actionUrlWorkHour + "/createWorkHourWithList", workhour);
    }

    public Delete<T>(id: any): Observable<T> {
        return this.http.delete<T>(this.actionUrlWorkHour + id);
    }

    public DeleteForPlanning<T>(id: any): Observable<T> {
        return this.http.delete<T>(this.actionUrlWorkHour + "deleteworkhourforplanning/" + id);
    }

    public DeleteFuturePlanning<T>(model: any): Observable<T> {
        return this.http.delete<T>(this.actionUrlWorkHour + "deletefutureplanningonrequsitionperformed", {
            params: model
        });
    }

    public GetWorkHoursByBunchId<T>(bunchId): Observable<T> {
        return this.http.get<T>(this.actionUrlWorkHour + "getWorkHoursByBunchId?bunchId=" + bunchId);
    }

    public UpdateWorkHours<T>(workhours: any): Observable<T> {
        return this.http.post<T>(this.actionUrlWorkHour + "updateWorkHours", workhours);
    }

    // public MovePlanWithDateRange<T>(workhour): Observable<T> {
    //     return this.http.put<T>(this.actionUrlWorkHour + "/moveworkhoursforplanningWithDateRange", workhour);
    // }

    public GetParams(param) {
        let params: HttpParams = new HttpParams();
        for (var key in param) {
            if (param.hasOwnProperty(key)) {
                let val = param[key];
                params = params.append(key, val);
            }
        }
        return params;
    }
    public GenerateRandomUniqueId() {
        var min = 1000000000;
        var max = 1111111111;
        var dt = moment(new Date()).format("YYYYMMDDHHmmss");
        var autoGenerate = Math.floor(Math.random() * (max - min) + min);
        return (dt + autoGenerate + "XXX").substring(0, 20);
    }

    public CopyPlanWithDateRange<T>(workhour: any): Observable<T> {
        return this.http.put<T>(this.actionUrlWorkHour + "copyworkhoursforplanning", workhour);
    }

    public AddNonWorkingHoursforplanning<T>(leaveApplyOnPlanning: LeaveApplyOnPlanning): Observable<T> {
        return this.http.post<T>(this.actionUrlWorkHour + "addNonWorkingHoursforplanning", leaveApplyOnPlanning);
    }

    public AddNonWorkHourScheduleWithDateRangeforplanning<T>(leaveApplyOnPlanning:any=[]): Observable<T> {
        return this.http.post<T>(this.actionUrlWorkHour + "addNonWorkHourScheduleWithDateRangeforplanning", leaveApplyOnPlanning);
    }

    public GetWorkHoursPayslipByCoworkerId<T>(requestPaySlip: RequestPaySlip): Observable<T> {
        return this.http.post<T>(this.actionUrlWorkHour + "getWorkHoursPayslipByCoworkerId", requestPaySlip);
    }

    public GetNonWorkHoursPayslipByCoworkerId<T>(requestPaySlip: RequestPaySlip): Observable<T> {
        return this.http.post<T>(this.actionUrlWorkHour + "generateNonworkingPaySlipByCoworkers", requestPaySlip);
    }
    
    public GeneratePaySlipByCoworkers<T>(requestPaySlip: RequestPaySlip): Observable<T> {
        return this.http.post<T>(this.actionUrlWorkHour + "generatePaySlipByCoworkers", requestPaySlip);
    }

    public GetCoworkersSalarySlip<T>(requestPaySlip: RequestPaySlip): Observable<T> {
        return this.http.post<T>(this.actionUrlWorkHour + "getCoworkersSalarySlip", requestPaySlip);
    }

    public deleteNonWorkingHourWithDateSlection<T>(workHoursItem: any): Observable<T> {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            body: workHoursItem
        }
        return this.http.delete<T>(this.actionUrlWorkHour + "deleteNonWorkingHourWithDateSlection", options);
    }

    public DownloadCoworkerPaymentReport<T>(requestPaySlip: RequestPaySlip): Observable<T> {
        return this.http.post<T>(this.actionUrlWorkHour + "downloadCoworkerPaymentReport", requestPaySlip);
    }

    public movePlanningFromOneCoworkerToAnother<T>(request: RequestMovePlanningWithRanage): Observable<T> {
        return this.http.post<T>(this.actionUrlWorkHour + "movePlanningFromOneCoworkerToAnother", request);
    }

    public CloseCoworkersPayment<T>(requestClosePayment:RequestPaySlip): Observable<T> {
        return this.http.post<T>(this.actionUrlWorkHour + "closeCoworkersPayment", requestClosePayment);
    }
    public getLastClosedPaymentDate<T>(): Observable<T> {
        return this.http.get<T>(this.actionUrlWorkHour + "getLastClosedPaymentDate");
    }
}
