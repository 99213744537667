export const locale = {
    lang: 'en',
    data: {
        'Headers': {
            "Title": "Price discount",
            "DeleteWeather": "Are you sue you want to delete this weather ?"
        },
        "FIELDS": {
            'SRNO': 'Sr nr.',
            "Role": "Role",
            'RoleName':'Role Name',
        },
        "BUTTONS": {
            "Add": "Add",
            'Back': 'Back',
            'Save':'Save',
        },
        "MESSAGES": {
            'Added': 'Added Successfully',
            'Updated': 'Updated Successfully.',
            'Failed': 'Failed',
            'Deleted': 'Deleted successfully',
            "NORECORD": "No Record Found"
        },
    }
}