import { Component, OnInit, ElementRef, NgZone, ViewChild, Input, Output, EventEmitter, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatTableDataSource ,MatOption} from '@angular/material';
import { Router } from '@angular/router';
import { FuseTranslationLoaderService } from '../../../../core/services/translation-loader.service';
import { TitleService } from '../../../../core/services/title.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { EventEmitterEnum } from '../../../../core/enum/event-emitter-ids.enum';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import * as moment from 'moment';

@Component({
  selector: 'vibe-projectTimeInfo',
  templateUrl: './projectTimeInfo.component.html',
  styleUrls: ['./projectTimeInfo.component.scss']
})
export class projectTimeInfoComponent implements OnInit {
  widgets: any;
  widget5: any;
  //Initiators
  constructor(private translationLoader: FuseTranslationLoaderService,
    private _router: Router,
    private titleService: TitleService,
    private translate: TranslateService,) {
    this.translationLoader.loadTranslations(english, danish);
  }

  ngOnInit() {
    this.widgets = {
      'widget2': {
        'title': 'Overdue',
        'data': {
          'label': 'TASKS',
          'count': 4,
          'extra': {
            'label': 'Yesterday\'s overdue',
            'count': 2
          }
        },
      },
      'widget5': {
        'currentRange': 'TW',
        'title': 'Project TimeInfo',
        'ranges': {
          'TW': 'This Week',
          'LW': 'Last Week',
          'TM': 'This Month',
          'TQ': 'This Quarter',
          'TY': 'This Year'
        }
      }
    }
    this.widget5 = {
      currentProjectRange: 'TW',
    }
  }
}
