export const locale = {
    lang: 'en',
    data: {
        'FIELDS': {
            'Designation': 'Designation',
            'ValideFrom': 'Valide From',
            'SRNO': 'Sr no.'
        },
        'BUTTONS': {
            'Save': 'Save',
            'BACK': 'Back',
            'Reset': 'Reset'
        },
        'MESSAGES': {
            'Updated': 'Updated Successfully.',
            'Failed': 'Failed',
            'Deleted': 'Deleted successfully',
            "NORECORD": "No Record Found",
            'Created': 'Created successfully',
        },
        'Validation': {
            'DesignationRequired': 'Designation is required',
            'ValideFromRequired': 'Valide from date is required',
            'HourlyRateRequired': 'Hourly rate is required',
            'WeekdayRequired': 'Weekday is required',
            'TypeRequired': 'Type is required',
            'WhiteSpaceNotAllowed':'Only white space not allowed',
            'FromRequired':'From at is required',
            'ToRequired':'To at is required',
            'MinEmployeeMonthRequired':'Min number of days is required',
            'MaxEmployeeMonthRequired':'Max number of days is required',
            'MaximumHoursRequired':'Maximum Hours is required',
            'MaxLeaveCountDaysRequired':'Max Leave Count Days is required',
            'WorkingHoursRequired':'Normal timer is required',
        },
        'Headers': {
            'Title': 'Terms of Employment',
            'DeleteMessage': 'Are you sure you want to delete',
            'Rules':'Rules',
        },
        'Lable': {
            'SRNO': 'Sr nr.',
            'Type': 'Type',
            'From,': 'From at',
            'To': 'To at',
            'HourlyRate': 'Hourly rate',
            'Numberofhours': 'Number of hours',
            'Weekday': 'Weekday',
            'MinEmployeeMonth': 'Min number of days',
            'MaxEmployeeMonth': 'Max number of days',
            'MaximumHours': 'Maximum Hours',
            'MaxLeaveCountDays': 'Max Leave Count Days',
            'MaxLeaveCountDaysKey1': 'Max Leave',
            'MaxLeaveCountDaysKey2': 'Count Days',
            'WorkingHours':'Working hours',
            'WorkingShift':'Working hours',
            'Number': 'Number of',
            'Hours': 'hours',
            'Working':'Working',
            'CalculatedAsExtra':'Calculated as time off',
            'Calculated':'Calculated',
            'As':'as',
            'Extra':'time off'


        },
        'ratesType': {
            'valueA': 'A Hourly rate',
            'valueB': 'B Hourly Wages Overtime 1',
            'valueC': 'C Hourly Wages Overtime 2',
            'valueJ': 'J Sick pay per. hour',

            'valueK': 'Vacation',
            'valueL': 'Holiday of the work',
            'valueM': 'Holiday with payment',
            'valueN': 'Holiday without payment',
            'valueO': 'School or learning',
            'valueP': 'Other things',
            'valueQ': 'Offer',
            'valueR': 'Relaxation',
            'valueH': 'Holidays',
        },
        'FILTERS': {
            'All': 'All',
        },
        'selectedValueWeekDays': {
            'value1': 'Monday',
            'value2': 'Tuesday',
            'value4': 'Wednesday',
            'value8': 'Thursday',
            'value16': 'Friday',
            'value32': 'Saturday',
            'value64': 'Sunday'
          },
        'TOOTIPORTITLE': {
           'Edit': 'Edit',
           'Delete': 'Delete',
           'From':'Must be completed with time e.g. 07:00 means that this wage rate starts at 07:00',
           'To':'Must be completed with time e.g. 15:00 means that this wage rate starts at 15:00',
           'MinEmployeeMonth': 'From number of days e.g. sick days 56 The employee must have been employed for 56 days before the ed. pay',
           'MaxEmployeeMonth': "Should be 99999 don't know why",
           'MaximumHours': 'Max. Hours eg Overtime 2.00 = overtime',
           'MaxLeaveCountDays': 'Max. number of days, e.g. sick leave=28 means max. 28 sick days with pay (Contiguous)',
           'IsWorkingShift':'This setting checked for regular shift and unchecked for Over Time shift.',
           'WorkingHours':'This setting checked for regular hours and unchecked for Over Time.',
           'IsCalculatedAsExtra':'This setting checked for hours goes to bank and unchecked for payment.',
       }
    }
};