export const locale = {
  lang: 'en',
  data: {
    'FIELDS': {
      'Name': 'Name',
      'Performance': 'Performance',
      'MaterialType': 'Material Type',
      'Search': 'Search',
      'Not-Found-MATERIALTYPE': 'Material Type',
      'ShowRoomDetails': 'Show room details',
      'MultipleOptions': 'Multiple Options',
      'IsLastNode': 'Last point',
      'IndoreOffer': 'In door Offer',
      'OutdoreOffer': 'Out door offer',
      'Price': 'Price',
      'Reminder': 'Reminder',
      'Text': 'Text',
      'Time': 'Number of times',
    },
    'Tabs': {
      'Offer': 'Offer',
      'BasicDetail': 'Basic Detail',
      'File': 'File',
      'Category': 'Category',
      'WorkItem': 'Work Item'
    },
    'MESSAGE': {
      'Failed': 'Operation failed , try later',
      'Success': 'Success.',
      'CommingSoon': 'Comming Soon..',
    },
    'BUTTONS': {
      'AddItem': 'Add Performance',
    },
    'FILTERS': {
      'Select': 'Select',
      'All': 'All'
    },
  }
};
