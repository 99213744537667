import { Component, Input, OnInit } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { MatDialog, MatOption, MatSlideToggleChange } from '@angular/material';
import { AuthService } from 'app/core/services/auth.service';
import { SettingsService } from '../../settings.service';
import { ResponseBase } from "app/main/model/ResponseBase";
import { Settings, SettingValue, SettingValueForAll } from "app/main/model/Settings";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { CoWorker } from "app/main/model/CoWorker";
import { RequestCoWorker } from "app/main/model/RequestBase";
import { CoWorkersService } from "app/main/pages/coWorker/coWorkers.service";
import { NotificationsService } from "angular2-notifications";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "environments/environment";
import { EmailSendingParam } from "app/main/model/common";
import { SettingEmailTempalteEditorComponent } from "../setting-email-tempalte-editor/setting-email-tempalte-editor.component";
import { spaceOnlyValidator } from "app/core/Validators/validation";

@Component({
  selector: 'vibe-birthday-notifications-settings',
  templateUrl: './birthday-notifications-settings.component.html',
  styleUrls: ['./birthday-notifications-settings.component.scss']
})
export class BirthdayNotificationsSettingsComponent implements OnInit {
  @Input("SendBirthdayReminderSetting") SendBirthdayReminderSetting: Settings;
  showSpinner: boolean = false;
  bithdayNotificationForm: FormGroup;
  isSubmitted = false;
  coWokers: CoWorker[] = [];
  BirthdayNotificationsObj: Settings = new Settings();
  settingMasterValues: any[] = [];
  settingValue: SettingValueForAll = new SettingValueForAll();


  constructor(private translationLoaderService: FuseTranslationLoaderService,
    private fb: FormBuilder,
    private coWorkersService: CoWorkersService,
    private authService: AuthService,
    private settingsService: SettingsService,
    private translate: TranslateService,
    private notificationsService: NotificationsService,
    private dialog: MatDialog) {
    this.translationLoaderService.loadTranslations(danish, english);
  }

  ngOnInit() {
    this.settingMasterValues = Object.assign([], JSON.parse(localStorage.getItem("settingMasterValues")));
    this.bithdayNotificationForm = this.fb.group({
      DaysToSend: [null, Validators.compose([Validators.required, Validators.min(1), Validators.max(365)])],
      ExecuteTime: [null, Validators.required],
      IsCreateReminderForCoworker: [false],
      IsCreateReminderForOthers: [false],
      IsCreateReminderForOthersBeforeXDays: [false],
      IsSendSMSForCoworker: [false],
      IsSendSMSForOthers: [false],
      IsSendSMSForOthersBeforeXDays: [false],
      IsSendNotificationForCoworker: [false],
      IsSendNotificationForOthers: [false],
      IsSendNotificationForOthersBeforeXDays: [false],
      IsSendEmailForCoworker: [false],
      IsSendEmailForOthers: [false],
      IsSendEmailForOthersBeforeXDays: [false],
      SMSTextForCoworker: [null],
      SMSTextForOthers: [null],
      SMSTextForOthersBeforeXDays: [null],
      NotificationTextForCoworker: [null],
      NotificationTextForOthers: [null],
      NotificationTextForOthersBeforeXDays: [null],
      NotificationTitleForCoworker: [null],
      NotificationTitleForOthers: [null],
      NotificationTitleForOthersBeforeXDays: [null],
      IsEnable: [true]
    });

    this.bithdayNotificationForm.get('IsSendSMSForCoworker').valueChanges
      .subscribe(value => {
        if (value) {
          this.bithdayNotificationForm.controls['SMSTextForCoworker'].setValidators([Validators.required, spaceOnlyValidator.bind(this)])
        } else {
          this.bithdayNotificationForm.controls['SMSTextForCoworker'].setValidators(null)
        }
        this.bithdayNotificationForm.controls['SMSTextForCoworker'].updateValueAndValidity()
      });

    this.bithdayNotificationForm.get('IsSendSMSForOthers').valueChanges
      .subscribe(value => {
        if (value) {
          this.bithdayNotificationForm.controls['SMSTextForOthers'].setValidators([Validators.required, spaceOnlyValidator.bind(this)])
        } else {
          this.bithdayNotificationForm.controls['SMSTextForOthers'].setValidators(null)
        }
        this.bithdayNotificationForm.controls['SMSTextForOthers'].updateValueAndValidity()
      });

    this.bithdayNotificationForm.get('IsSendSMSForOthersBeforeXDays').valueChanges
      .subscribe(value => {
        if (value) {
          this.bithdayNotificationForm.controls['SMSTextForOthersBeforeXDays'].setValidators([Validators.required, spaceOnlyValidator.bind(this)])
        } else {
          this.bithdayNotificationForm.controls['SMSTextForOthersBeforeXDays'].setValidators(null)
        }
        this.bithdayNotificationForm.controls['SMSTextForOthersBeforeXDays'].updateValueAndValidity()
      });

    this.bithdayNotificationForm.get('IsSendNotificationForCoworker').valueChanges
      .subscribe(value => {
        if (value) {
          this.bithdayNotificationForm.controls['NotificationTextForCoworker'].setValidators([Validators.required, spaceOnlyValidator.bind(this)])
          this.bithdayNotificationForm.controls['NotificationTitleForCoworker'].setValidators([Validators.required, spaceOnlyValidator.bind(this)])
        } else {
          this.bithdayNotificationForm.controls['NotificationTextForCoworker'].setValidators(null)
          this.bithdayNotificationForm.controls['NotificationTitleForCoworker'].setValidators(null)
        }
        this.bithdayNotificationForm.controls['NotificationTextForCoworker'].updateValueAndValidity()
        this.bithdayNotificationForm.controls['NotificationTitleForCoworker'].updateValueAndValidity()
      });

    this.bithdayNotificationForm.get('IsSendNotificationForOthers').valueChanges
      .subscribe(value => {
        if (value) {
          this.bithdayNotificationForm.controls['NotificationTextForOthers'].setValidators([Validators.required, spaceOnlyValidator.bind(this)])
          this.bithdayNotificationForm.controls['NotificationTitleForOthers'].setValidators([Validators.required, spaceOnlyValidator.bind(this)])
        } else {
          this.bithdayNotificationForm.controls['NotificationTextForOthers'].setValidators(null)
          this.bithdayNotificationForm.controls['NotificationTitleForOthers'].setValidators(null)
        }
        this.bithdayNotificationForm.controls['NotificationTextForOthers'].updateValueAndValidity()
        this.bithdayNotificationForm.controls['NotificationTitleForOthers'].updateValueAndValidity()
      });

    this.bithdayNotificationForm.get('IsSendNotificationForOthersBeforeXDays').valueChanges
      .subscribe(value => {
        if (value) {
          this.bithdayNotificationForm.controls['NotificationTextForOthersBeforeXDays'].setValidators([Validators.required, spaceOnlyValidator.bind(this)])
          this.bithdayNotificationForm.controls['NotificationTitleForOthersBeforeXDays'].setValidators([Validators.required, spaceOnlyValidator.bind(this)])
        } else {
          this.bithdayNotificationForm.controls['NotificationTextForOthersBeforeXDays'].setValidators(null)
          this.bithdayNotificationForm.controls['NotificationTitleForOthersBeforeXDays'].setValidators(null)
        }
        this.bithdayNotificationForm.controls['NotificationTextForOthersBeforeXDays'].updateValueAndValidity()
        this.bithdayNotificationForm.controls['NotificationTitleForOthersBeforeXDays'].updateValueAndValidity()
      });

    this.setSettingsForBirthdayNotifications();
  }

  onSubmit() {
    this.bithdayNotificationForm.markAllAsTouched();
    this.isSubmitted = true;
    if (this.bithdayNotificationForm.valid) {
      this.changeSettingsForPlanningNotifications()
    }
  }

  EmailTemplateDialog() {
    const dialogRef = this.dialog.open(SettingEmailTempalteEditorComponent, {
      height: '90%',
      width: '90%',
      data: { settingValue: this.settingValue, IsOther: false, IsBday: true, IsSameDay: false, Note: 'Note.BirthdayEmailTextCoworkerNote' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null && result != '') {
        this.settingValue = result;
        this.onSubmit();
      }
    });
  }

  EmailTemplateForOthersDialog() {
    const dialogRef = this.dialog.open(SettingEmailTempalteEditorComponent, {
      height: '90%',
      width: '90%',
      data: { settingValue: this.settingValue, IsOther: true, IsBday: true, IsSameDay: true, Note: 'Note.BirthdayEmailTextOtherCoworkerNote' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null && result != '') {
        this.settingValue = result;
        this.onSubmit();
      }
    });
  }

  EmailTemplateForOthersBeforeXDaysDialog() {
    const dialogRef = this.dialog.open(SettingEmailTempalteEditorComponent, {
      height: '90%',
      width: '90%',
      data: { settingValue: this.settingValue, IsOther: true, IsBday: true, IsSameDay: false, Note: 'Note.BirthdayEmailTextOthersBeforeXDaysNote' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null && result != '') {
        this.settingValue = result;
        this.onSubmit();
      }
    });
  }

  setSettingsForBirthdayNotifications() {
    if (this.SendBirthdayReminderSetting && this.SendBirthdayReminderSetting != null) {
      this.BirthdayNotificationsObj = this.SendBirthdayReminderSetting;
      this.settingValue = JSON.parse(this.BirthdayNotificationsObj.value)
      this.bithdayNotificationForm.patchValue(this.settingValue)
      this.changeInLocalStorage(this.BirthdayNotificationsObj.id, this.BirthdayNotificationsObj)
    }
  }

  IsEnableBirthdayReminder(event: MatSlideToggleChange) {
    this.settingValue.IsEnable = event.checked;
    this.changeSettingsForPlanningNotifications();
  }

  setSettingsForPlanningNotificationsAfterUpdate() {
    this.settingsService.GetSettingsWithTemplateValue<ResponseBase>("Notification", 'SendBirthdayReminder', null).subscribe({
      next: (response: ResponseBase) => {
        this.showSpinner = false;
        if (response != null && response.result && response.result.myResponse.length > 0) {
          this.BirthdayNotificationsObj = response.result.myResponse[0];
          this.settingValue = JSON.parse(this.BirthdayNotificationsObj.value)
          this.bithdayNotificationForm.patchValue(this.settingValue)
          this.changeInLocalStorage(this.BirthdayNotificationsObj.id, this.BirthdayNotificationsObj)
        }
      },
      error: err => {
      },
      complete: () => {
        this.showSpinner = false;
      }
    });
  }

  changeSettingsForPlanningNotifications() {
    this.showSpinner = true;

    this.settingValue.IsEnable = this.bithdayNotificationForm.value.IsEnable;
    this.settingValue.ExecuteTime = this.bithdayNotificationForm.value.ExecuteTime || '';
    this.settingValue.DaysToSend = this.bithdayNotificationForm.value.DaysToSend;

    this.settingValue.IsSendSMSForCoworker = this.bithdayNotificationForm.value.IsSendSMSForCoworker;
    this.settingValue.IsSendSMSForOthers = this.bithdayNotificationForm.value.IsSendSMSForOthers;
    this.settingValue.IsSendSMSForOthersBeforeXDays = this.bithdayNotificationForm.value.IsSendSMSForOthersBeforeXDays;


    this.settingValue.IsSendNotificationForCoworker = this.bithdayNotificationForm.value.IsSendNotificationForCoworker;
    this.settingValue.IsSendNotificationForOthers = this.bithdayNotificationForm.value.IsSendNotificationForOthers;
    this.settingValue.IsSendNotificationForOthersBeforeXDays = this.bithdayNotificationForm.value.IsSendNotificationForOthersBeforeXDays;

    this.settingValue.IsCreateReminderForCoworker = this.bithdayNotificationForm.value.IsCreateReminderForCoworker;
    this.settingValue.IsCreateReminderForOthers = this.bithdayNotificationForm.value.IsCreateReminderForOthers;
    this.settingValue.IsCreateReminderForOthersBeforeXDays = this.bithdayNotificationForm.value.IsCreateReminderForOthersBeforeXDays;

    this.settingValue.IsSendEmailForCoworker = this.bithdayNotificationForm.value.IsSendEmailForCoworker;
    this.settingValue.IsSendEmailForOthers = this.bithdayNotificationForm.value.IsSendEmailForOthers;
    this.settingValue.IsSendEmailForOthersBeforeXDays = this.bithdayNotificationForm.value.IsSendEmailForOthersBeforeXDays;

    this.settingValue.SMSTextForCoworker = this.bithdayNotificationForm.value.SMSTextForCoworker || '';
    this.settingValue.SMSTextForOthers = this.bithdayNotificationForm.value.SMSTextForOthers || '';
    this.settingValue.SMSTextForOthersBeforeXDays = this.bithdayNotificationForm.value.SMSTextForOthersBeforeXDays || '';

    this.settingValue.NotificationTextForCoworker = this.bithdayNotificationForm.value.NotificationTextForCoworker || '';
    this.settingValue.NotificationTextForOthers = this.bithdayNotificationForm.value.NotificationTextForOthers || '';
    this.settingValue.NotificationTextForOthersBeforeXDays = this.bithdayNotificationForm.value.NotificationTextForOthersBeforeXDays || '';

    this.settingValue.NotificationTitleForCoworker = this.bithdayNotificationForm.value.NotificationTitleForCoworker || '';
    this.settingValue.NotificationTitleForOthers = this.bithdayNotificationForm.value.NotificationTitleForOthers || '';
    this.settingValue.NotificationTitleForOthersBeforeXDays = this.bithdayNotificationForm.value.NotificationTitleForOthersBeforeXDays || '';

    this.BirthdayNotificationsObj.key1 = 'Notification';
    this.BirthdayNotificationsObj.key2 = 'SendBirthdayReminder';
    this.BirthdayNotificationsObj.value = JSON.stringify(this.settingValue);
    this.settingsService.UpdateSettingWithTemplate<ResponseBase>(this.BirthdayNotificationsObj).subscribe({
      next: (response: ResponseBase) => {
        this.showSpinner = false;
        if (response != null && response.result) {
          this.BirthdayNotificationsObj = response.result;
          if (this.settingValue.IsEnable)
            this.setSettingsForPlanningNotificationsAfterUpdate();
          this.translate.get("MESSAGES.Saved").subscribe({
            next: (res) => {
              this.notificationsService.create(res);
            },
            error: err => { }
          });
        }
      },
      error: err => {
      },
      complete: () => {
        this.showSpinner = false;
        this.isSubmitted = false;
      }
    });
  }

  changeInLocalStorage(ID, new_obj) {
    if (this.settingMasterValues.find(obj => obj.id == ID)) {
      this.settingMasterValues.forEach((old_obj, index) => {
        if (old_obj.id == ID) {
          this.settingMasterValues[index] = new_obj
        }
      })
    } else {
      this.settingMasterValues.push(new_obj)
    }
    localStorage.setItem("settingMasterValues", JSON.stringify(this.settingMasterValues));
  }
}

