export const locale = {
    lang: 'ds',
    data: {
        'HEADERS': {
            'ChangeDrivingDays': 'Vil du gerne ændre antal kørselsdage'
        },
        'FIELDS': {
            'ChangeFrom': 'Ændre fra',
            'ChangeTo': 'Ændre til'
        },
        'BUTTONS': {
            'Yes': 'Ja',
            'No': 'Nej',
        },
    }
};