export const locale = {
    lang: 'ds',
    data: {
        'FIELDS': {
            'Barcode': 'Rediger Stregkode',
        },
        'BUTTONS': {
            'BACK': 'Tilbage',
            'Ok': 'Gem',
        },
        'MESSAGES': {
            'Barcode': 'Stregkode findes allerede.',
        },
        'VALIDATIONS': {
            'Barcode': 'Indtast gyldig stregkode / stregkode findes allerede.',
            'BarcodeRequired': 'Indtast venligst stregkode.',
            'BarcodeRequiredWhiteSpaceNotAllowed':'Kun hvidt mellemrum er ikke tilladt'
        }
    }
};