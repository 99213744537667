import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { MatDialog } from '@angular/material';
import { TitleService } from 'app/core/services/title.service';

@Component({
    selector: 'app-category-select',
    templateUrl: './category-select.component.html',
    styleUrls: ['./category-select.component.scss']
})
export class CategorySelectComponent implements OnInit {
    OfferID: string = "";
    showSpinner: boolean = false;

    constructor(private _route: ActivatedRoute,
        private translationLoader: FuseTranslationLoaderService,
        public dialog: MatDialog,
        private titleService: TitleService) {
        //this.titleService.SetTitle("TITLE_QUALITY_TEST")
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
        this._route.params.subscribe(params => {
            if (params['id'] != undefined) {
                this.OfferID = params['id'];
            }
        });
    }

    isSendPDFFile(data) {
    }

}
