export const locale = {
    lang: 'en',
    data: {
        'Headers': {
            'QuestionList': 'Ask customer the reason of rejection:'
        },
        'QuestionTypeCategory': {
            'value1': 'Textbox',
            'value2': 'Multi Choice',
            'value3': 'Single Choice',
            'value4': 'Rating'
        },
        'BUTTONS': {
            'NEWPROJECTOFFER': 'New Project Offer',
            'AddQuestion': 'Add Question',
            'Save': 'Save',
            'Cancel': 'Cancel',
            'Text': 'Text',
            'Add':'Add',
        },
        'FIELDS': {
            'Question': 'Question',
            'SrNo': 'Sr No.',
            'Type': 'Type',
            'Answer': 'Answer'
        },
        'VALIDATIONS': {
            'QuestionRequired': 'Question required'
        },
        'MESSAGES': {
            'NoQuestionAnswerFound': 'No Question Found.',
            'AddTypeList': 'Add at least one value to the type list.'
        },
    }
}