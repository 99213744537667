import { Component, OnInit, Output } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';

import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { MatDialog, MatDialogRef } from '@angular/material';

@Component({
    selector: "vibe-click-plan-popup.component",
    templateUrl: "./click-plan-popup.component.html",
    styleUrls: ["./click-plan-popup.component.scss"]
})

export class ClickPlanPopupComponent implements OnInit {
    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private dialog: MatDialog,
        public dialogRef: MatDialogRef<ClickPlanPopupComponent>, ) {
        this.translationLoaderService.loadTranslations(danish, english);
    }

    ngOnInit() {

    }

    goToPlanning() {
        this.dialogRef.close(2);
    }

    OpenNonWorkingPopup() {
        this.dialogRef.close(1)
    }

    close() {
        this.dialogRef.close(0);
    }
}