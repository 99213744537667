export const locale = {
    lang: 'en',
    data: {
        'TITLE': {
            'Project': 'Project',
        },
        'FIELDS': {
            "Role": "Roll",
            
        },
        'VALIDATIONS': {
        },
        'MESSAGES': {
            'Saved': 'Saved successfully.',
        },
        'BUTTONS': {
            'Save': 'Save',
        }
    }
};