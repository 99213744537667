export const locale = {
  lang: 'ds',
  data: {
    'FIELDS': {
      'Name': 'Navn',
      'UserName': 'Brugernavn',
      'Password': 'Adgangskode',
      'Email': 'E-mail',
      'Address': 'Adresse',
      'BloodGroup': 'Blod type',
      'Contact': 'Telefonnummer',
      'BirthDate': 'Fødselsdato',
      'DateOfJoininng': 'Ansættelsesdato',
    },
    'BUTTON': {
      'EDIT': 'Rediger',
      'ChangePassword': 'Skift Kodeord'
    }
  }
};
