export const locale = {
    lang: 'en',
    data: {
        'STATUS': {
            'Created': 'Created',
            'Updated': 'Updated',
            'Deleted': 'Deleted',
            'Inactive': 'Inactive',
            'Active': 'Active',
            'Statuschane': 'Status change',
            'FileUploaded': 'File Uploaded',
            'FileDeleted': 'File Deleted',
        },
        'TITLE': {
            'CustomerPagination': 'Antal log pr. side',
        },
        'BUTTONS': {
            'BACK': 'Cancel',
            'BTNSAVE': 'Save',
        },
        'FIELDS': {
            'SRNO': 'Sr No',
            'Status': 'Status',
            'Number': 'Number',
            'Name': 'Name',
            'Createdby': 'Created By',
            'CreatedDate': 'Created Date',
            'ModifyBy': 'Modify By',
            'ModifyDate': 'Modify Date',
            'CoworkerName': 'Coworker',
            'Date': 'Date',
            'Description': 'Description',
            'Action': 'Action',
            'ModuleName': 'Module',
            'ByCoworker': 'by co-worker',
            'ByCustomer': 'by customer'
        },
        'Headers': {
        },
        'Module': {
            'Project': 'Project',
            'Requisition': 'Requisition',
            'RequisitionMaterial': 'Requisition Material',
            'RequisitionCoworker': 'Requisition Coworker',
            'Offer': 'Offers',
            'Inquiry': 'Inquirie',
            'MaterialEmail': 'Email',
            'StatusChangeAnswer': 'Status Change Answer'
        },

        'MESSAGES': {
            'NORECORD': 'No Record Found',
            'SENDEMAIL': '{@CoworkerName} have send email to {@SupplierName}.',
        }, 
        'TOOTIPORTITLE': {
            'ViewEmailDetails':'View Email Details'
        }
    }
};


