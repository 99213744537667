import { Component, OnInit, Inject, ChangeDetectorRef } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CalendarService } from 'app/main/pages/calendar/calendar.service';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { QuestionStatusType, ProjectStatus } from 'app/constant/common.constant';
import { Settings } from 'app/main/model/Settings';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'question-setting',
  templateUrl: './question-popup.component.html',
  styleUrls: ['./question-popup.component.scss']
})

export class QuestionPopupComponent implements OnInit {
  Settings = new Settings();
  QuestionStatusType = QuestionStatusType;
  questionForm: FormGroup
  questionList = new FormArray([]);
  showSpinner: boolean = false;
  selectStatus = ProjectStatus;
  projectStatusList = ProjectStatus;
  RequisitionStatus: any = "";
  constructor(private translate: TranslateService,
    private translationLoader: FuseTranslationLoaderService,
    private notificationsService: NotificationsService,
    private calendarService: CalendarService,
    public dialogRef: MatDialogRef<QuestionPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder) {
    this.translationLoader.loadTranslations(english, danish);
  }

  ngOnInit() {
    this.questionForm = this.fb.group({
      questions: this.fb.array([]),
    })
    this.getQuestionList();
  }

  getQuestionList() {
    this.showSpinner = true;
    this.Settings.key1 = 'DeletePlanningHourAskQuestion';
    this.calendarService.GetQuestionList<ResponseBase>(this.Settings.key1).subscribe({
      next: (response: ResponseBase) => {
        this.showSpinner = false;
        this.Settings = response.result;
        if (response.result.value) {
          var que = JSON.parse(response.result.value);
          for (var i = 0; i < que.questions.length; i++) {
            this.addQuestion(que.questions[i]);
          }
        }
      },
      error: err => {
        this.showSpinner = false;
        this.translate.get("MESSAGES.Failed").subscribe({
          next: (res) => {
            this.notificationsService.error(res);
          },
          error: err => {
          }
        });
      },
      complete: () => {

      }
    });
  }

  Save() {
    this.showSpinner = true;
    this.Settings.value = JSON.stringify(this.questionForm.value);
    this.calendarService.UpdateSettingValue<ResponseBase>(this.Settings.id, this.Settings).subscribe({
      next: (response: ResponseBase) => {
        this.showSpinner = false;
        this.translate.get("MESSAGES.SAVESUCCESSFULLY").subscribe({
          next: (res) => {
            this.notificationsService.create(res);
          },
          error: err => {
          }
        });
      },
      error: err => {
        this.showSpinner = false;
        this.translate.get("MESSAGES.Failed").subscribe({
          next: (res) => {
            this.notificationsService.error(res);
          },
          error: err => {
          }
        });
      },
      complete: () => {

      }
    });
  }

  close() {
    this.dialogRef.close();
  }

  questions(): FormArray {
    return this.questionForm.get("questions") as FormArray
  }

  newQuestion(data): FormGroup {
    var temptext = data && data.text ? data.text : null;
    var tempStatus = data && data.status ? data.status : 0;
    var tempRequisitionStatus = data && data.requisitionStatus && data.status == 1 ? data.requisitionStatus : 0;
    return this.fb.group({
      text: [temptext],
      status: [tempStatus],
      requisitionStatus: [tempRequisitionStatus]
    })
  }

  addQuestion(data) {
    this.questions().push(this.newQuestion(data));
  }

  removeQuestion(queIndex: number) {
    this.questions().removeAt(queIndex);
  }

  setProjectStatus(status, index) {
    if (status != 1) {
      return this.questions().value[index].requisitionStatus = 0;
    }
  }
}