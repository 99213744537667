// map.component.ts
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { GoogleMapDirectionService } from 'app/main/pages/google-map-direction/google-map-direction.service';

@Component({
    selector: 'project-map-route',
    templateUrl: './project-map-route.component.html',
})
export class ProjectMapRouteComponent {
    originAddress: string = '';//'Sømosevej 40, 4293, Dianalund'; // Example origin address
    destinationAddresses: string[] = [];//['Østrupvej 124, 4350 Ugerløse', 'Karsholtevej 53, 4293 Dianalund']; // Example destination addresses
    origin: any;
    destinations: any[] = [];

    latitude: number;
    longitude: number;
    callSetPlaceParam: boolean = true;
    zoom: number = 15;

    constructor(private geocodeService: GoogleMapDirectionService,
       @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        // this.geocodeService.Get(this.originAddress).subscribe((response: any) => {
        //     if (response.status == "OK") {
        //         this.origin = response.results[0].geometry.location;
        //     }
        // });        
    }

    ngOnInit() {
        if(this.data){
            this.data.filter(proj => {
                const add = proj.address1 +"," + proj.zipCode +" " +proj.city;
                this.destinationAddresses.push(add);
            })
        }
        setTimeout(() => {
            this.setCurrentLocation();
        }, 2000);
        this.destinationAddresses.forEach((address) => {
            this.geocodeService.Get(address).subscribe((response: any) => {
                if (response.status == "OK") {
                    this.destinations.push(response.results[0].geometry.location);
                }
            });
        });
    }

    setCurrentLocation() {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.latitude = position.coords.latitude;
                this.longitude = position.coords.longitude;
                this.origin = { lat: position.coords.latitude, lng: position.coords.longitude };
                this.zoom = 15;
            });
        }
    }
}
