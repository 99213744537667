import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { CommonService } from 'app/core/services/common.service';
import { OfferRoom, ProjectsOfferRoom } from 'app/main/model/ProjectOffer';
import { RequestProjectOfferRoom } from 'app/main/model/RequestBase';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';
import { ProjectofferService } from '../../project-offer.service';

@Component({
    selector: 'app-project-offer-room-list',
    templateUrl: './project-offer-room-list.component.html',
})
export class ProjectOfferRoomListComponent implements OnInit {
    @Output() OfferClickEventOutput = new EventEmitter<any>();
    @Input() RoomInput: any;
    
    offerId: string = "";
    isCommingFromOfferCategory: boolean=false;
    RemoveRoomByIndex: number;
    showSpinner: boolean = false;
    requestProjectOfferRoom: RequestProjectOfferRoom = new RequestProjectOfferRoom();
    ProjectsOfferRooms: ProjectsOfferRoom[] = [];
    OfferCategoryTreatmentList: any[] = [];
    OfferCategoryList: any[] = [];

    constructor(
        public dialog: MatDialog,
        private projectofferService: ProjectofferService,
        private translate: TranslateService,
        private notificationsService: NotificationsService,
        private commonService: CommonService,

        @Optional() public dialogRef: MatDialogRef<ProjectOfferRoomListComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit() {
        this.offerId = this.RoomInput;

        if (this.data && this.data.OfferId) {
            this.isCommingFromOfferCategory = true;
            this.offerId = this.data.OfferId;
        }
        
        this.getProjectOfferRoomList(true);
        this.getOfferTask(this.offerId);
        //this.getOfferItemGroup(this.offerId);
    }

    getProjectOfferRoomList(evt) {
        if(evt) {
            this.showSpinner = true;
            this.requestProjectOfferRoom.Limit = 100;
            this.requestProjectOfferRoom.OfferId = this.offerId;
            this.projectofferService.getProjectOfferRoomList<ResponseBase>(this.requestProjectOfferRoom)
                .subscribe((response: ResponseBase) => {
                    this.ProjectsOfferRooms = []
                    this.showSpinner = false;
                    if (response != null) {
                        setTimeout(() => {
                            this.ProjectsOfferRooms = response.result.myResponse;
                        });
                    }
                },
                error => (err) => {
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });
                });
        }
    }

    getOfferTask(id) {
        this.projectofferService.GetofferTask(id).subscribe((response: ResponseBase) => {
            this.OfferCategoryTreatmentList = response.result;
        });
    }

    getOfferItemGroup(id) {
        this.projectofferService.GetofferTask<ResponseBase>(id)
            .subscribe((response: ResponseBase) => {
                if (response != null) {
                    this.OfferCategoryList = response.result;
                }
            }, error => (err) => { });
    }

    deleteProjectOfferRoom(obj) {
        let roomId = obj.roomId;
        let rowIndex = obj.rowIndex;

        if (this.OfferCategoryTreatmentList.length) {
            const dialogRef = this.dialog.open(AlertMessageModelComponent, {
                height: '200px',
                data: { Header: 'Headers.DeleteRoomAlert' }
            });
    
            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    if (roomId) {
                        this.deleteRoom(roomId, rowIndex)
                    }
                }
            });
        } else {
            this.deleteRoom(roomId, rowIndex)
        }
    }

    deleteRoom(roomId, rowIndex) {
        this.showSpinner = true;
        this.projectofferService.deleteProjectOfferRoom<ResponseBase>(this.offerId, roomId)
            .subscribe((response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null) {
                    this.getProjectOfferRoomList(true);
                    this.RemoveRoomByIndex = rowIndex;
                }
                else {
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                            this.commonService.playFailedTone();
                        },
                        error: err => {
                        }
                    });
                }
            },
            error => (err) => {
                this.showSpinner = false;
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            });
    }

    deleteTraetmentByOfferId(list:OfferRoom[]) {
        this.projectofferService.DeleteTraetmentByOfferId(this.offerId)
            .subscribe((response: any) => {
                if (response != null)
                    this.saveProjectOfferRooms(list);
            },
            error => (err) => {
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    }, error: err => { }
                });
            });
    }

    saveProjectOfferRooms(list:OfferRoom[]) {
        var updatedData = list.filter(x => {
            var data = this.ProjectsOfferRooms.filter(t => t.id == x.id);
            if (data.length > 0) {
                //if (x.length != data[0].length || x.width != data[0].width || x.ceilingHeight != data[0].ceilingHeight || x.extraQty1 != data[0].extraQty1 || x.extraQty2 != data[0].extraQty2 || x.extraPrice1 != data[0].extraPrice1 || x.extraPrice2 != data[0].extraPrice2)
                if (x.length != data[0].length || x.width != data[0].width || x.ceilingHeight != data[0].ceilingHeight || x.extraM2QtyWall != data[0].extraM2QtyWall || x.extraM2QtyCeiling != data[0].extraM2QtyCeiling || x.extraM2QtyFloor != data[0].extraM2QtyFloor || x.extraM2PriceWall != data[0].extraM2PriceWall || x.extraM2PriceCeiling != data[0].extraM2PriceCeiling || x.extraM2PriceFloor != data[0].extraM2PriceFloor)
                    return x;
            }
        })
        if (updatedData.length > 0 && this.OfferCategoryTreatmentList.length > 0)
            this.ShowAlertMessageForChangeRoom(list);
        else
            this.AddProjectOffer(list)
    }

    AddProjectOffer(list:OfferRoom[]) {
        this.showSpinner = true;
        this.projectofferService.AddProjectOffer<ResponseBase>(list)
            .subscribe((response: ResponseBase) => {                
                this.showSpinner = false;
                if (response != null) {
                    if (response.result.length) {
                        this.getProjectOfferRoomList(true);
                    }
                    //this.OfferOutput.emit(this.projectOffer.id);
                    this.translate.get("MESSAGES.Success").subscribe({
                        next: (res) => {
                            this.notificationsService.success(res);
                        },
                        error: err => {
                        }
                    });
                }
                else {
                    
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                            this.commonService.playFailedTone();
                        },
                        error: err => {
                        }
                    });
                }
                
            },
            error => (err) => {
                this.showSpinner = false;
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            });
    }

    ShowAlertMessageForChangeRoom(list:OfferRoom[]) {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: '25%',
            width: '40%',
            data: {
                Header: 'Headers.DeleteMessageForCreatedOffers',
                ShowManualButton: true
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result)
                if (result === "donotRemoveOldOffer") {
                    this.AddProjectOffer(list);
                } else {
                    this.deleteTraetmentByOfferId(list);
                }
        });
    }

    closePopup() {
        this.dialogRef.close(this.ProjectsOfferRooms);
    }
}
