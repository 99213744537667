export const locale = {
    lang: 'ds',
    data: {
        'TITLE': {
            'Header': 'Generel opsætning i systemet',
        },
        'FIELDS': {
            'DutyscheduleName': 'Vagtplan - navn',
            'DutyscheduleFromTo': 'Vagtplan-vis fra-til hvis ingen tider på vagtplan',
            'HolidayDayOffAccrual14': 'Feriefridag optjening 14 dages lønnede',
            'VacationDayAccrualMonth': 'feriefridag optjening månedslønnede',
            'DrivingCurrency': 'Km. takst',
            'GDayCurrency':'G-dag takst',
            'DefaultDebtorProfit':'Std. debotor avance',
            'CurrentSickDaysPeriod':'lgangværende sygedageperiode',
            'VacationLastYear':'Sidst tildelt feriedage',
            'MultimediaTreasure': 'Multimedieskat',
        },
        'VALIDATIONS': {
        },
        'MESSAGES': {
            'Saved': 'Gemt succesfuldt.',
        },
        'BUTTONS': {
            'Save': 'Gem',
        }
    }
};

