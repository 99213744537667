import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatOption, MatDialog, MatExpansionPanel } from '@angular/material';
import { Router } from '@angular/router';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { RequestProjectRequisitionByCowokrer, RequestCoWorker, RequestCaseTask, RequestResourceWorkHours } from 'app/main/model/RequestBase';
import { ProjectMaterial } from "app/main/model/Project";
import { CoWorker } from "app/main/model/CoWorker";
import { CoWorkersService } from 'app/main/pages/coWorker/coWorkers.service';
import { CommonService } from 'app/core/services/common.service';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { WorkhoursService } from 'app/main/pages/workHour/workhours.service';
import { ProjectRequisitionService } from 'app/main/pages/project/project.requisition.service';
import { ProjectService } from 'app/main/pages/project/project.service';
import { CalendarService } from 'app/main/pages/calendar/calendar.service';
import { AuthService } from 'app/core/services/auth.service';
import { ShiftVisitTimeType } from 'app/main/model/ProjectStatus';
import { ProjectStatus } from 'app/constant/common.constant';
import { WorkHour, CoworkerDays, WorkhoursDay, CoworkerWithScheduleDetails, ScheduleDays, CoworkerScheduleDayList, WorkingHourOtherInfo } from 'app/main/model/WorkHour';
import { projectRequisition, ProjectHoursDetail } from 'app/main/model/Project';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import * as momentBusinessDays from 'moment-business-days';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';
import { AlertPlanningMessageModelComponent } from 'app/main/pages/alert-planning-message-model/alert-planning-message-model.component';
import { SMSEmailSelectionComponent } from 'app/main/pages/calendar/component/sms-email-selection/sms-email-selection.component'
import { UrgentOrNot, ProjectIndoorOutDoorStatus } from 'app/main/model/ProjectStatus';
import { WeekendOption } from 'app/constant/common.constant'
import { ShiftSelectionComponent } from 'app/main/pages/calendar/component/shift-selection/shift-selection.component'
import { DropboxFilesService } from 'app/main/shared-component/dropbox-files/dropbox-files.service';
import { DriveModuleEnum } from 'app/core/enum/drive-module.enum';

@Component({
    selector: 'vibe-calender-working-hour-popup',
    templateUrl: './working-hour-popup.component.html',
    styleUrls: ['./working-hour-popup.component.scss']
})
export class CalenderWorkingHourPopupComponent implements OnInit {
    startWeek: number = 1;
    projectInfo: any = {};
    requistionInfo: any = {};
    selectProject: string = "";
    coWorkerList: CoWorker[] = new Array<CoWorker>();
    searchCoWorkerList: CoWorker[] = new Array<CoWorker>();
    selectedCoWorkerList: CoworkerWithScheduleDetails[] = new Array<CoworkerWithScheduleDetails>();
    projectCoWorkers: any = [];
    showSpinner = false;
    showSpinnerForAdd = false;
    createEventObj: any = {};
    //shiftType = ShiftType;
    shiftType = ShiftVisitTimeType;
    projectList: any = [];
    projectFullList: any = [];
    workHour = new WorkHour();
    RequisitionList: projectRequisition[] = [];
    currentselectedCoworkerIds: any = [];
    projectCoworkerObj: any = [];
    Requisition: projectRequisition;
    projectMaterials: ProjectMaterial[] = [];
    materialTotalPrice: number = 0;
    materialPersonalTotalPrice: number = 0;
    materialTotalPriceWithVAT: number = 0;
    hours: any;
    mins: any;
    isAddBreakTime: boolean = true;
    defaultCoWorkerList: any = [];
    isDisableWorkType = false;
    weekNumbers: any = [];
    isDisplayErrorMessage: boolean = false;
    isComingFromEditMode: boolean = false;
    workHourNotAddedOnCoworker: string = "";
    selectIndoorOutDoorStatus = ProjectIndoorOutDoorStatus;
    UrgentOrNot = UrgentOrNot;
    workHourId: string = "";
    //roleName: string;
    isPastDate: boolean = false;
    CoWorkerName: string = "";
    colorCode: string = "";
    holidayList = [];
    IsExpand: boolean = false;
    IsExpandOtherDetails: boolean = false;
    displayMtoMTabRed: boolean = false;
    displayNoteTabRed: boolean = false;
    displayOffersTabRed: boolean = false;

    selectStatus = ProjectStatus;
    isHolidayInPlaning: boolean = false;
    isCoworkerInLeave: boolean = false;
    holidayListBetweenDates: any = [];
    coworkerLeaveList: any = [];
    tempCoworkerListForLeave: any = [];
    message: any = "";
    fullMessage: any = "";
    workingdayList = [];
    totalWorkHourOfCoworker: number;
    workHoursForLiftCoworker: any = [];
    liftReserved: any = [];
    workingDays: number = 0;
    displaySpecialInfoRed: boolean = false;
    reqTabselectedIndex: number = 0;
    previousIndex: number = 0;
    coworkerListAndPlaningDates = [];
    isViewCowrokerPlanning: boolean = false;
    selectedIndex: number = 0;
    isOpenMaterialPopup: boolean = false;
    isBreakTime: boolean = false;
    selectedcoWorkerIds: any = [];
    public workHoursList: any = [];
    // dateArray = [];
    // tempEndDate: any;
    weekendOption = WeekendOption;
    scheduleDay: any = {};
    tempDayList: any = [];
    coworkerScheduleDayList: CoworkerScheduleDayList[] = [];
    IsExpandCoworkeDetails: boolean = true;
    isCalculatedDays: boolean = true;
    totalHouronCase: number = 0;
    isBreakTimeForManualTimer: boolean = false;
    projectHoursDetail = new ProjectHoursDetail();
    fileFolderPathData: any = [];
    dropBoxRootFolder : any;
    displayFileFromDropbox: boolean = true;
    selectedOfferTypeIDs: string[] = [];

    @ViewChild('allSelected', { static: false }) private allSelected: MatOption;
    roleClaims: any;
    hasPermision: boolean = false;
    //Initiators
    constructor(private translationLoader: FuseTranslationLoaderService,
        private _router: Router,
        private translate: TranslateService,
        private notificationsService: NotificationsService,
        private workhourservice: WorkhoursService,
        private projectRequisitionService: ProjectRequisitionService,
        private projectService: ProjectService,
        private calendarService: CalendarService,
        private coWorkersService: CoWorkersService,
        private authService: AuthService,
        private commonService: CommonService,
        private datePipe: DatePipe,
        private dialog: MatDialog,
        public dialogRef: MatDialogRef<CalenderWorkingHourPopupComponent>,
        private dropboxFilesService: DropboxFilesService,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.translationLoader.loadTranslations(english, danish);
        this.roleClaims = this.authService.getRoleClaimsList();
    }

    ngOnInit() {
        //this.roleName = this.authService.getUserRole();
        this.dropBoxRootFolder = this.dropboxFilesService.getSettingMasterValueDocument();
        this.authService.hasClaim(this.roleClaims.Planning_Modify).subscribe(hasClaim => {
            if (hasClaim) 
                this.hasPermision = hasClaim
             else 
                this.hasPermision = false;
        });
        this.isComingFromEditMode = this.data.isComingFromEditMode;
        this.projectInfo = this.data.projectInfo;
        this.workHourId = this.projectInfo.workHourId;
        this.createEventObj.startDate = (this.projectInfo.startDate ? this.projectInfo.startDate : new Date());
        this.createEventObj.customerId = this.projectInfo.customerId;
        this.selectProject = this.projectInfo.projectTitle;
        this.colorCode = this.projectInfo.colorcode;
        this.holidayList = this.data.holidayList;
        if (this.data.coworkerList.length > 0) {
            this.coWorkerList = this.data.coworkerList;
            this.searchCoWorkerList = this.coWorkerList;
        }
        else
            this.getCoworkersWithHoliday();
        this.createEventObj.leaveStatus = this.projectInfo.leaveStatus;
        this.createEventObj.shift = this.projectInfo.shiftType;
        this.createEventObj.projectId = this.projectInfo.sagId;
        this.createEventObj.projectNumber = this.projectInfo.sagNumber;
        this.createEventObj.sagOpgaveID = this.projectInfo.sagOpgaveID;
        this.createEventObj.departmentPhone = "";
        this.createEventObj.size = "";
        this.createEventObj.indoorOutdoorStatusId = this.projectInfo.indoorOutdoorStatusId;
        this.createEventObj.urgentOrNot = this.projectInfo.urgentOrNot;
        this.createEventObj.visit = this.projectInfo.visit ? moment(this.projectInfo.visit).format('DD.MM.YYYY HH:mm') : "-";
        this.createEventObj.indoorOutdoorStatusIds = [];
        this.createEventObj.doNotSendNotification = this.projectInfo.doNotSendNotification;
        this.createEventObj.weekendId = 1;
        this.createEventObj.projectBasedPlanning = true;
        this.storeFileFolderPathData();
        if (this.projectInfo.indoorOutdoorStatusId) {
            if (this.projectInfo.indoorOutdoorStatusId.includes(',')) {
                var SplitIndoorOutdoorStatusId = this.projectInfo.indoorOutdoorStatusId.split(',');
                this.selectIndoorOutDoorStatus.filter(x => {
                    SplitIndoorOutdoorStatusId.filter(y => {
                        if (parseInt(y) == x.id)
                            this.createEventObj.indoorOutdoorStatusIds.push(x);
                    })
                });
            }
            else {
                this.selectIndoorOutDoorStatus.filter(x => {
                    if (parseInt(this.projectInfo.indoorOutdoorStatusId) == x.id)
                        this.createEventObj.indoorOutdoorStatusIds.push(x);
                });
            }
        }
        var request = new RequestProjectRequisitionByCowokrer;
        request.ProjectId = this.projectInfo.sagId;
        this.getRequisitionList(request);
        if (this.isComingFromEditMode) {
            //this.createEventObj.sagOpgaveID = this.projectInfo.sagOpgaveID;
            this.createEventObj.endDate = this.projectInfo.endDate;
            this.currentselectedCoworkerIds = [this.projectInfo.coworkerId];
            this.workHour.fromStartHour = this.projectInfo.startHour;
            this.workHour.fromStartMinute = this.projectInfo.startMinute;
            this.workHour.fromEndHour = this.projectInfo.endHour;
            this.workHour.fromEndMinute = this.projectInfo.endMinute;
            this.isAddBreakTime = this.projectInfo.isPauseTime;
        }
        this.hours = [{ value: '00' }, { value: '01' }, { value: '02' }, { value: '03' }, { value: '04' }, { value: '05' }, { value: '06' }, { value: '07' }, { value: '08' },
        { value: '09' }, { value: '10' }, { value: '11' }, { value: '12' }, { value: '13' }, { value: '14' }, { value: '15' }, { value: '16' }, { value: '17' },
        { value: '18' }, { value: '19' }, { value: '20' }, { value: '21' }, { value: '22' }, { value: '23' }];

        this.mins = [{ value: '00' }, { value: '15' }, { value: '30' }, { value: '45' }]
        //this.mins = [{ key: '00', value: '00' }, { key: '25', value: '15' }, { key: '50', value: '30' }, { key: '75', value: '45' }];
        this.addWeeksNumber();
        this.getWorkHoursForLiftCoworker();
    }

    getProjectMaterialList(modal) {
        this.showSpinner = true;
        this.projectService.GetProjectMaterialList<ResponseBase>(modal)
            .subscribe((response: ResponseBase) => {
                this.showSpinner = false;
                if (response) {
                    this.projectMaterials = response.result;
                    this.calculateProjMaterialPriceTotal();
                    this.projectMaterials.filter(p => { p.quantity = parseFloat(p.quantity.toFixed(2)); });
                }
            },
                error => (err) => {
                }, () => { });
    }

    getRequisitionList(model) {
        this.projectRequisitionService.Get<ResponseBase>(model.ProjectId, model).subscribe({
            next: (response: ResponseBase) => {
                if (response) {
                    this.RequisitionList = response.result;
                    this.createEventObj.status = this.RequisitionList[0].status;
                    if (this.RequisitionList.length > 0) {
                        if (this.RequisitionList.length==1) {
                            this.createEventObj.projectBasedPlanning = false;
                        }
                        if (!this.isComingFromEditMode) {
                            if (!this.createEventObj.sagOpgaveID) {
                                this.Requisition = this.RequisitionList[0];
                                this.createEventObj.sagOpgaveID = this.RequisitionList[0].id;
                            }
                            else {
                                var curReq = this.RequisitionList.find(o => o.id == this.createEventObj.sagOpgaveID);
                                this.Requisition = curReq;
                            }
                            var calandarDoubleClickData = JSON.parse(localStorage.getItem("doubleClickCalanderDetail"));
                            if (calandarDoubleClickData) {
                                //if (!this.Requisition.startDate) {
                                this.createEventObj.startDate = moment(calandarDoubleClickData.startDate)["_d"];
                                this.createEventObj.endDate = moment(calandarDoubleClickData.endDate)["_d"];
                                // } 
                                // else {
                                //     this.createEventObj.startDate = this.Requisition.startDate ? moment(this.Requisition.startDate)["_d"] : new Date();
                                //     this.createEventObj.endDate = this.Requisition.endDate ? moment(this.Requisition.endDate)["_d"] : this.createEventObj.endDate;
                                // }
                                this.DropDownCoWorkerSelect(calandarDoubleClickData.coWorkerId);
                                this.currentselectedCoworkerIds = [calandarDoubleClickData.coWorkerId];
                            } else {
                                //this.createEventObj.startDate = this.Requisition.startDate ? this.Requisition.startDate : new Date();
                                this.createEventObj.startDate = this.Requisition.startDate ? moment(this.Requisition.startDate)["_d"] : new Date();
                                this.createEventObj.endDate = this.Requisition.endDate ? moment(this.Requisition.endDate)["_d"] : this.createEventObj.endDate;
                                var ispastday = moment(this.createEventObj.startDate, "YYYY-MM-DD").isBefore();
                                if (ispastday)
                                    this.createEventObj.startDate = new Date();
                            }
                            this.calculateEndDateWithRemainingHour();
                            this.createEventObj.departmentPhone = this.RequisitionList[0].departmentPhone;
                            this.createEventObj.size = this.RequisitionList[0].size;
                            let objCoWorkerProject = new RequestCoWorker();
                            objCoWorkerProject.IsDeleted = false;
                            objCoWorkerProject.ProjectId = model.ProjectId;
                            objCoWorkerProject.fileAssignmentId = this.createEventObj.sagOpgaveID;
                            //this.getprojectCoworkerList(objCoWorkerProject);
                            this.setLiftCoworker();
                        }
                        else {
                            var curReq = this.RequisitionList.find(o => o.id == this.createEventObj.sagOpgaveID);
                            this.Requisition = curReq;
                            if (curReq) {
                                this.createEventObj.departmentPhone = curReq.departmentPhone;
                                this.createEventObj.size = curReq.size;
                            }
                            let objCoWorkerProject = new RequestCoWorker();
                            objCoWorkerProject.IsDeleted = false;
                            objCoWorkerProject.ProjectId = model.ProjectId;
                            objCoWorkerProject.fileAssignmentId = this.createEventObj.sagOpgaveID;
                            //this.getprojectCoworkerList(objCoWorkerProject);
                        }
                        this.calculatePrice();
                        this.refreshMaterialList();
                        if (new Date().setHours(0, 0, 0, 0) == new Date(this.createEventObj.startDate).setHours(0, 0, 0, 0)) {
                            this.isPastDate = false;
                        }
                        else {
                            this.isPastDate = moment(this.createEventObj.startDate, "YYYY-MM-DD").isBefore();
                        }
                        if (this.Requisition && (this.Requisition.left || this.Requisition.riseman)) {
                            this.displaySpecialInfoRed = true;
                        }
                        else {
                            this.displaySpecialInfoRed = false;
                        }
                        var reqID = this.Requisition != null ? this.Requisition.id : null;
                        this.getCowrkerListAndPlanningDate(this.projectInfo.sagId, reqID);
                        if (this.Requisition.noOfWorkflows > 0) {
                            this.createEventObj.shift = 4;
                            var ForEndTime: any;
                            if (this.Requisition.estimatedTime) {
                                ForEndTime = Math.round(this.Requisition.estimatedTime / this.Requisition.noOfWorkflows);
                            } else {
                                ForEndTime = 0;
                            }
                            this.workHour.fromStartHour = '07';
                            this.workHour.fromStartMinute = '00';
                            ForEndTime = ForEndTime + 7;
                            this.workHour.fromEndHour = ForEndTime.toString();
                            if (ForEndTime < 10) {
                                this.workHour.fromEndHour = '0' + this.workHour.fromEndHour;
                            }
                            this.workHour.fromEndMinute = '00';
                            this.AddBreaktime();
                        }
                    }
                    this.getProjectTimerFromRequisition();
                }
                else
                    this.RequisitionList = [];
            },
            error: err => {
                this.RequisitionList = [];
            },
            complete: () => {
            }
        });
    }

    DropDownRequistionSelect(requisition) {
        this.Requisition = requisition;
        this.createEventObj.departmentPhone = this.Requisition.departmentPhone ? this.Requisition.departmentPhone : "";
        this.createEventObj.size = this.Requisition.size ? this.Requisition.size : "0";
        this.createEventObj.startDate = requisition.startDate ? requisition.startDate : new Date();
        this.calculatePrice();
        this.refreshMaterialList();
        if (!this.isComingFromEditMode) {
            let objCoWorkerProject = new RequestCoWorker();
            objCoWorkerProject.IsDeleted = false;
            objCoWorkerProject.ProjectId = this.createEventObj.ProjectId;
            objCoWorkerProject.fileAssignmentId = requisition.id;
            //this.getprojectCoworkerList(objCoWorkerProject);
        }
        var reqID = this.Requisition != null ? this.Requisition.id : null;
        this.getCowrkerListAndPlanningDate(this.projectInfo.sagId, reqID)
        this.calculateEndDateWithRemainingHour();
    }

    getCoWorkerOnSearch(value) {
        if (!value) {
            this.DropDownSearchCoWorker(value);
        }
    }

    DropDownSearchCoWorker(value) {
        this.searchCoWorkerList = this.coWorkerList.filter(o => o.name.toLowerCase().includes(value.toLowerCase()));
    }

    DropDownCoWorkerSelect(id) {
        this.isViewCowrokerPlanning = false;
        var alreadySelectedCoworker = this.selectedCoWorkerList.find(o => o.id == id);
        if (alreadySelectedCoworker)
            this.selectedCoWorkerList = this.selectedCoWorkerList.filter(o => o.id != id);
        else {
            var findCoworker = this.coWorkerList.find(o => o.id == id);
            if (findCoworker)
                this.addCoworker(findCoworker);
            //this.selectedCoWorkerList.push(findCoworker);

        }
        // var liftSelectedCoworker = this.selectedCoWorkerList.filter(o => o.name.toLowerCase() == "lift");
        // if (liftSelectedCoworker.length > 0
        //     && this.selectedCoWorkerList.length == 1) {
        //     //this.singleLiftCoworkerCalculation();
        //     this.calculateEndDateWithRemainingHour();
        // }
        // else if (liftSelectedCoworker.length > 0
        //     && this.selectedCoWorkerList.length == 2) {
        //     this.calculateEndDateWithRemainingHour();
        // }
        if (this.selectedCoWorkerList.length > 0) {
            this.calculateEndDateWithRemainingHour();
        }
        //this.getTotalWorkHourOfCoworker();
        this.DropDownSearchCoWorker('');
    }

    singleLiftCoworkerCalculation() {
        this.workHour.dayList = [];
        var days = 0;
        if (this.Requisition && this.Requisition.estimatedTime && this.Requisition.totalWorkingTime) {
            days = Math.ceil((this.Requisition.estimatedTime - this.Requisition.totalWorkingTime) / 7.5);
        }
        else if (this.Requisition && this.Requisition.estimatedTime) {
            days = this.Requisition && this.Requisition.estimatedTime != null ? Math.ceil(this.Requisition.estimatedTime / 7.5) : 0;
        }
        days = days != 0 ? Math.abs(days) - 1 : 0;
        this.workingDays = days;
        this.createEventObj.endDate = moment(this.createEventObj.startDate, "YYYY-MM-DD").add(this.workingDays, 'days')["_d"];

        //var startDate = new Date(moment(this.createEventObj.startDate, "YYYY-MM-DD").toString());
        //var endDate = new Date(moment(this.createEventObj.endDate, "YYYY-MM-DD").toString());
        var startDate = new Date(new Date(this.createEventObj.startDate).getFullYear(),
            new Date(this.createEventObj.startDate).getMonth(), new Date(this.createEventObj.startDate).getDate(), 0, 0, 0, 0);
        var endDate = new Date(new Date(this.createEventObj.endDate).getFullYear(),
            new Date(this.createEventObj.endDate).getMonth(), new Date(this.createEventObj.endDate).getDate(), 0, 0, 0, 0);

        for (let day = startDate; day <= endDate; day.setDate(day.getDate() + 1)) {
            this.pushDayList(moment(day).format("YYYY-MM-DD"), true)
        }
        //this.getTotalWorkHourOfCoworker();
    }

    selectedCoWorkerDelete(id) {
        this.selectedCoWorkerList = this.selectedCoWorkerList.filter(o => o.id != id);
        this.currentselectedCoworkerIds = this.selectedCoWorkerList.map(o => o.id);
        // var liftSelectedCoworker = this.selectedCoWorkerList.filter(o => o.name.toLowerCase() == "lift");
        // if (liftSelectedCoworker.length > 0
        //     && this.selectedCoWorkerList.length == 1) {
        //     this.singleLiftCoworkerCalculation();
        // }
        this.calculateEndDateWithRemainingHour();
        //this.reCalculateScheduleDays();
        //this.getTotalWorkHourOfCoworker();
    }

    calculateEndDateWithRemainingHour() {
        var days = 0;
        this.workHour.dayList = [];
        if (this.createEventObj.projectBasedPlanning) {
            if (this.projectHoursDetail) {
                if (this.projectHoursDetail && this.projectHoursDetail.estimatedTime && this.projectHoursDetail.plannedWorkHour >= 0) {
                    days = Math.ceil((this.projectHoursDetail.estimatedTime - this.projectHoursDetail.plannedWorkHour) / 7.5);
                }
                else if (this.projectHoursDetail && this.projectHoursDetail.plannedWorkHour < 0) {
                    days = this.projectHoursDetail && this.projectHoursDetail.plannedWorkHour != null ? Math.ceil(this.projectHoursDetail.plannedWorkHour / 7.5) : 0;
                }
                if (this.createEventObj.shift == 4 && this.createEventObj.visitdays)
                    days = this.createEventObj.visitdays;
                else if (this.createEventObj.shift == 3 && this.selectedCoWorkerList.length > 1)
                    days = days / this.selectedCoWorkerList.length;
                this.createEventObj.visitdays = days != 0 ? Math.ceil(days) : 0;
                if (days < 0)
                    days = 0;
                days = days != 0 ? Math.abs(days) - 1 : 0;
                this.workingDays = days;
                if(!this.createEventObj.automaticallyCalculated){
                    this.createEventObj.endDate = moment(this.createEventObj.startDate, "YYYY-MM-DD").add(this.workingDays, 'days')["_d"];
                    this.calculateEndDate(true);
                }
                else
                    this.calculateEndDate(false);
            }
            else
                this.calculateEndDate(false);
        }
        else {
            if (this.Requisition && !this.Requisition.startDate && !this.Requisition.endDate) {
                if (this.Requisition && this.Requisition.estimatedTime && this.Requisition.plannedWorkHour > 0) {
                    days = Math.ceil((this.Requisition.estimatedTime - this.Requisition.plannedWorkHour) / 7.5);
                }
                else if (this.Requisition && this.Requisition.estimatedTime && this.Requisition.totalWorkingTime) {
                    days = Math.ceil((this.Requisition.estimatedTime - this.Requisition.totalWorkingTime) / 7.5);
                }
                else if (this.Requisition && this.Requisition.estimatedTime) {
                    days = this.Requisition && this.Requisition.estimatedTime != null ? Math.ceil(this.Requisition.estimatedTime / 7.5) : 0;
                }
                if (this.createEventObj.shift == 4 && this.createEventObj.visitdays)
                    days = this.createEventObj.visitdays;
                else if (this.createEventObj.shift == 3 && this.selectedCoWorkerList.length > 1)
                    days = days / this.selectedCoWorkerList.length;
                this.createEventObj.visitdays = days != 0 ? Math.ceil(days) : 0;
                days = days != 0 ? Math.abs(days) - 1 : 0;
                this.workingDays = days;
                //this.createEventObj.endDate = moment(this.createEventObj.startDate).add(days)["_d"];
                if(!this.createEventObj.automaticallyCalculated){
                    this.createEventObj.endDate = moment(this.createEventObj.startDate, "YYYY-MM-DD").add(this.workingDays, 'days')["_d"];
                    this.calculateEndDate(true);
                }
                else
                    this.calculateEndDate(false);
            } else {
                // if (this.Requisition && this.Requisition.startDate && this.Requisition.endDate && (this.Requisition.startDate != moment(this.createEventObj.startDate).format('YYYY-MM-DDTHH:mm:ss') || this.Requisition.endDate != moment(this.createEventObj.endDate).format('YYYY-MM-DDTHH:mm:ss'))) {
                if (this.Requisition && this.Requisition.startDate && this.Requisition.endDate) {
                    if (this.Requisition && this.Requisition.estimatedTime && this.Requisition.plannedWorkHour >= 0) {
                        days = Math.ceil((this.Requisition.estimatedTime - this.Requisition.plannedWorkHour) / 7.5);
                    }
                    else if (this.Requisition && this.Requisition.plannedWorkHour < 0) {
                        days = this.Requisition && this.Requisition.plannedWorkHour != null ? Math.ceil(this.Requisition.plannedWorkHour / 7.5) : 0;
                    }
                    if (this.createEventObj.shift == 4 && this.createEventObj.visitdays)
                        days = this.createEventObj.visitdays;
                    else if (this.createEventObj.shift == 3 && this.selectedCoWorkerList.length > 1)
                        days = days / this.selectedCoWorkerList.length;
                    this.createEventObj.visitdays = days != 0 ? Math.ceil(days) : 0;
                    if (days < 0)
                        days = 0;
                    days = days != 0 ? Math.abs(days) - 1 : 0;
                    this.workingDays = days;
                    if(!this.createEventObj.automaticallyCalculated){
                        this.createEventObj.endDate = moment(this.createEventObj.startDate, "YYYY-MM-DD").add(this.workingDays, 'days')["_d"];
                        this.calculateEndDate(true);
                    }
                    else
                        this.calculateEndDate(false);
                }
                else
                    this.calculateEndDate(false);
            }
        }
    }

    calculateEndDate(extendEndDate) {
        this.isCalculatedDays = extendEndDate;
        this.workHour.dayList = [];
        var isDisplayHolidayPopup = false;
        var startDate = new Date(new Date(this.createEventObj.startDate).getFullYear(),
            new Date(this.createEventObj.startDate).getMonth(), new Date(this.createEventObj.startDate).getDate(), 0, 0, 0, 0);
        var endDate = new Date(new Date(this.createEventObj.endDate).getFullYear(),
            new Date(this.createEventObj.endDate).getMonth(), new Date(this.createEventObj.endDate).getDate(), 0, 0, 0, 0);

        for (let day = startDate; day <= endDate; day.setDate(day.getDate() + 1)) {
            //if (this.selectedCoWorkerList.length == 1 && this.selectedCoWorkerList[0].name == "Lift") {
            //  this.workHour.dayList.push(moment(day).format("YYYY-MM-DD"));
            //}
            //else {
            var getHoliday = this.holidayList.find(o => moment(o.date).format("DD.MM.YYYY") == moment(day).format("DD.MM.YYYY"));
            if (day.getDay() == 0 || day.getDay() == 6) {
                if (extendEndDate && this.createEventObj.weekendId == 1)
                    endDate = new Date(endDate.setDate(endDate.getDate() + 1));
                else if (this.createEventObj.weekendId == 2)
                    this.pushDayList(moment(day).format("YYYY-MM-DD"), true)
            }
            else if (getHoliday) {
                isDisplayHolidayPopup = true;
                if (extendEndDate) {
                    endDate = new Date(endDate.setDate(endDate.getDate() + 1));
                }
            }
            else if (!getHoliday) {
                this.pushDayList(moment(day).format("YYYY-MM-DD"), true)
            }
            //}
        }
        this.createEventObj.endDate = endDate;
        this.createEventObj.startWeekNumber = moment(this.createEventObj.startDate).week().toString();
        if (isDisplayHolidayPopup) {
            //this.createEventObj.startDate = moment(this.createEventObj.startDate)["_d"];
            this.holidayListBetweenDates = this.holidayList.filter(o => (new Date(o.date) >= this.createEventObj.startDate && new Date(o.date) <= this.createEventObj.endDate));
            if (this.holidayListBetweenDates && this.holidayListBetweenDates.length > 0 && this.selectedCoWorkerList.length > 0)
                this.displayHolidayPopup();
        }
        // if (this.createEventObj.startDate.toDateString() == this.createEventObj.endDate.toDateString()) {
        //     if (this.createEventObj.startDate.getDay() == 5)
        //         this.createEventObj.shift = 2;
        //     else if (this.createEventObj.shift == 2)
        //         this.createEventObj.shift = 3;

        // }
        this.createEventObj.visitdays = this.workHour.dayList.length;
        //this.getTotalWorkHourOfCoworker();
        this.reCalculateScheduleDays();
    }

    displayHolidayPopup() {
        this.fullMessage = "";
        this.message = "";
        this.translate.get("MESSAGES.HolidayFoundInPlanning").subscribe({
            next: (res) => { this.message = res; }, error: err => { }
        });

        var dates = this.holidayListBetweenDates.map(function (a) {
            return moment(a.date).format("DD.MM.YYYY");
        });
        this.fullMessage = this.fullMessage + this.message.replace("@date", dates.join(' og ')) + "<br/>";

        const dialogRef = this.dialog.open(AlertPlanningMessageModelComponent, {
            width: '40%',
            data: {
                Header: this.fullMessage,
                FirstButtonLabel: "Headers.PlanWithHoliday",
                SecondButtonLabel: "Headers.PlanWithoutHoliday",

            }
        });
        dialogRef.afterClosed().subscribe((result: any) => {
            if (result == 1) {
                this.workHour.dayList = [];
                var startDate = new Date(new Date(this.createEventObj.startDate).getFullYear(),
                    new Date(this.createEventObj.startDate).getMonth(), new Date(this.createEventObj.startDate).getDate(), 0, 0, 0, 0);
                var endDate = new Date(new Date(this.createEventObj.endDate).getFullYear(),
                    new Date(this.createEventObj.endDate).getMonth(), new Date(this.createEventObj.endDate).getDate(), 0, 0, 0, 0);
                // if (calculateEndDate == true) {
                //     endDate = moment(this.createEventObj.startDate, "YYYY-MM-DD").add(this.workingDays, 'days')["_d"];
                // }

                // calculate endDate only
                for (let day = startDate; day <= endDate; day.setDate(day.getDate() + 1)) {
                    var tempDate = moment(day).format("DD.MM.YYYY");
                    var getHoliday = this.holidayList.find(o => moment(o.date).format("DD.MM.YYYY") == tempDate);
                    if (day.getDay() == 0 || day.getDay() == 6) {
                        if (this.createEventObj.weekendId == 1)
                            continue;
                        else if (this.createEventObj.weekendId == 2)
                            this.pushDayList(moment(day).format("YYYY-MM-DD"), true)
                    }
                    else {
                        this.pushDayList(moment(day).format("YYYY-MM-DD"), true)
                    }
                }
                this.createEventObj.endDate = endDate;
                this.createEventObj.startWeekNumber = moment(this.createEventObj.startDate).week().toString();
            }
            else if (result == 2) {
                this.workHour.dayList = [];
                var startDate = new Date(new Date(this.createEventObj.startDate).getFullYear(),
                    new Date(this.createEventObj.startDate).getMonth(), new Date(this.createEventObj.startDate).getDate(), 0, 0, 0, 0);
                var endDate = new Date(this.createEventObj.endDate.getFullYear(),
                    this.createEventObj.endDate.getMonth(), this.createEventObj.endDate.getDate(), 0, 0, 0, 0);
                // if (calculateEndDate == true) {
                //     endDate = moment(this.createEventObj.startDate, "YYYY-MM-DD").add(this.workingDays, 'days')["_d"];
                // }

                // calculate endDate only
                for (let day = startDate; day <= endDate; day.setDate(day.getDate() + 1)) {
                    var getHoliday = this.holidayList.find(o => moment(o.date).format("DD.MM.YYYY") == moment(day).format("DD.MM.YYYY"));
                    if (day.getDay() == 0 || day.getDay() == 6) {
                        if (getHoliday) {
                            endDate = new Date(endDate.setDate(endDate.getDate() + 1));
                            // if(getHoliday)
                            //     calculateEndDate = true;
                        }
                        if (this.createEventObj.weekendId == 1)
                            continue;
                        else if (this.createEventObj.weekendId == 2 && !getHoliday)
                            this.pushDayList(moment(day).format("YYYY-MM-DD"), true)
                    }
                    else if (getHoliday) {
                        endDate = new Date(endDate.setDate(endDate.getDate() + 1));
                    }
                    else if (!getHoliday) {
                        this.pushDayList(moment(day).format("YYYY-MM-DD"), true)
                    }
                }
                this.createEventObj.endDate = endDate;
                this.createEventObj.startWeekNumber = moment(this.createEventObj.startDate).week().toString();

                // get day list data
                //var tempStartDate = moment(this.createEventObj.startDate).format("YYYY-MM-DD");
                //var tempEndDate = moment(this.createEventObj.endDate).format("YYYY-MM-DD");
                //for (let day = new Date(tempStartDate); day <= new Date(tempEndDate); day.setDate(day.getDate() + 1)) {
                //  if (day.getDay() == 0 || day.getDay() == 6) {
                //    continue;
                //  }
                //  else {
                //    this.workHour.dayList.push(moment(day).format("YYYY-MM-DD"));
                //  }
                //}
            }
            else if (result == 3) {
            }
        });
    }

    onStartDateChange(args) {
        var liftSelectedCoworker = this.selectedCoWorkerList.filter(o => o.name.toLowerCase() == "lift");
        if (liftSelectedCoworker.length > 0
            && this.selectedCoWorkerList.length == 1) {
            this.singleLiftCoworkerCalculation();
        }
        else {
            //this.getTotalWorkHourOfCoworker();
            this.calculateEndDateWithRemainingHour();
        }
        if (new Date().setHours(0, 0, 0, 0) == new Date(this.createEventObj.startDate).setHours(0, 0, 0, 0)) {
            this.isPastDate = false;
        }
        else {
            this.isPastDate = moment(this.createEventObj.startDate, "YYYY-MM-DD").isBefore();
        }
    }

    onEndDateChange(args) {
        this.calculateEndDate(false);
        // var liftSelectedCoworker = this.selectedCoWorkerList.filter(o => o.name.toLowerCase() == "lift");
        // if (liftSelectedCoworker.length > 0
        //     && this.selectedCoWorkerList.length == 1) {
        //     this.singleLiftCoworkerCalculation();
        // }
        // else {
        //     this.calculateEndDate(false);
        //     //this.getTotalWorkHourOfCoworker();
        //     // this.holidayListBetweenDates = this.holidayList.filter(o => (new Date(o.date) >= this.createEventObj.startDate && new Date(o.date) <= this.createEventObj.endDate));
        //     // if (this.holidayListBetweenDates.length > 0)
        //     //     this.displayHolidayPopup(false);
        // }

    }

    checkCoworkersHolidayInCurrentPlaning() {
        if (!this.colorCode) {
            const dialogRef = this.dialog.open(AlertMessageModelComponent, {
                height: '200px',
                data: { Header: 'MESSAGES.ColorCodeBlank' }
            });

            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    if (this.Requisition.noOfWorkflows && this.currentselectedCoworkerIds.length > this.Requisition.noOfWorkflows) {
                        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
                            height: '200px',
                            data: { Header: 'MESSAGES.ConfirmNoOfCoworker' }
                        });

                        dialogRef.afterClosed().subscribe(result => {
                            if (result) {
                                // this.planWorkHours();
                                this.getCalenderData(new RequestResourceWorkHours());
                            }
                        });
                    } else {
                        // this.planWorkHours();
                        this.getCalenderData(new RequestResourceWorkHours());
                    }
                }
            });
        }
        else {
            if (this.Requisition.noOfWorkflows && this.currentselectedCoworkerIds.length > this.Requisition.noOfWorkflows) {
                const dialogRef = this.dialog.open(AlertMessageModelComponent, {
                    height: '200px',
                    data: { Header: 'MESSAGES.ConfirmNoOfCoworker' }
                });

                dialogRef.afterClosed().subscribe(result => {
                    if (result) {
                        // this.planWorkHours();
                        this.getCalenderData(new RequestResourceWorkHours());
                    }
                });
            } else {
                // this.planWorkHours();
                this.getCalenderData(new RequestResourceWorkHours());
            }
        }
    }


    planWorkHours() {
        if (this.currentselectedCoworkerIds.length > 0) {
            this.tempCoworkerListForLeave = [];
            this.createEventObj.startDate = moment(this.createEventObj.startDate).toDate();
            var sdate = new Date(this.createEventObj.startDate.setHours(0, 0, 0, 0));
            var edate = new Date(this.createEventObj.endDate.setHours(0, 0, 0, 0));
            var liftSelectedCoworker = this.selectedCoWorkerList.filter(o => o.name.toLowerCase() == "lift");
            if (liftSelectedCoworker.length > 0) {
                this.liftReserved = [];
                var data = this.workHoursForLiftCoworker.filter(o => (new Date(o.date) >= sdate && new Date(o.date) <= edate));
                this.liftReserved = data.sort((a, b) => {
                    return <any>new Date(a.date) - <any>new Date(b.date);
                })
                if (this.liftReserved.length > 0) {
                    this.message = "";
                    this.translate.get("MESSAGES.LeftAssigment").subscribe({
                        next: (res) => { this.message = res; }, error: err => { }
                    });
                    var dates = this.liftReserved.map(function (a) {
                        return moment(a.date).format("DD.MM.YYYY");
                    });
                    this.message = this.message.replace("@date", dates.join(' og '));
                    this.notificationsService.error(this.message);
                    this.commonService.playFailedTone();
                    this.showSpinnerForAdd = false;
                    return;
                }
                else {
                    this.setScheduleDaysByCowrker();
                    //this.saveTimer();
                    this.ShowSendSmsOrEmailSelection();
                }
            }
            else {
                this.setScheduleDaysByCowrker();
                this.ShowSendSmsOrEmailSelection();
                // this.isCoworkerInLeave = false;
                // for (var i = 0; i < this.currentselectedCoworkerIds.length; i++) {
                //     this.coworkerLeaveList = [];
                //     this.coworkerLeaveList = this.coWorkerList.filter(x => x.id == this.currentselectedCoworkerIds[i]);
                //     if (this.coworkerLeaveList.length > 0 && this.coworkerLeaveList[0].holidayList && this.coworkerLeaveList[0].holidayList.length > 0) {
                //         //var data = this.coworkerLeaveList[0].holidayList;
                //         var data = this.coworkerLeaveList[0].holidayList.filter(o => (new Date(o.date) >= sdate && new Date(o.date) <= edate));
                //         if (data.length > 0) {
                //             this.isCoworkerInLeave = true;
                //             var tempCoworker = {
                //                 id: this.coworkerLeaveList[0].id,
                //                 name: this.coworkerLeaveList[0].name,
                //                 email: this.coworkerLeaveList[0].email,
                //                 coworkerNumber: this.coworkerLeaveList[0].coworkerNumber,
                //                 mobile: this.coworkerLeaveList[0].mobile,
                //                 isActive: this.coworkerLeaveList[0].isActive,
                //                 isDeleted: this.coworkerLeaveList[0].isDeleted,
                //                 initials: this.coworkerLeaveList[0].initials,
                //                 holidayList: this.coworkerLeaveList[0].holidayList.sort((a, b) => {
                //                     return <any>new Date(a.date) - <any>new Date(b.date);
                //                 }),
                //             }
                //             this.tempCoworkerListForLeave.push(tempCoworker);
                //         }
                //     }
                // }
                // if (this.isCoworkerInLeave) {
                //     this.fullMessage = "";
                //     this.message = "";
                //     this.translate.get("MESSAGES.CoworkerOnLeave").subscribe({
                //         next: (res) => { this.message = res; }, error: err => { }
                //     });

                //     for (var i = 0; i < this.tempCoworkerListForLeave.length; i++) {
                //         var holidayListLocal = this.tempCoworkerListForLeave[i].holidayList.filter(o => (new Date(o.date) >= sdate && new Date(o.date) <= edate));
                //         var dates = holidayListLocal.map(function (a) {
                //             return moment(a.date).format("DD.MM.YYYY");
                //         });
                //         var status = holidayListLocal.map(function (a) {
                //             return "selectedWorkHourStatus.value" + a.status;
                //         });
                //         var statusName = "";
                //         if (status) {
                //             var that = this;
                //             var filterStatus = status.filter((n, i) => status.indexOf(n) === i);
                //             filterStatus.filter(function (a) {
                //                 that.translate.get(a).subscribe({
                //                     next: (res) => {
                //                         statusName += statusName + res + ',';
                //                     }, error: err => { }
                //                 });
                //             });
                //             statusName = statusName.substring(0, statusName.length - 1);
                //         }
                //         this.fullMessage = this.fullMessage + this.message.replace("@coworkerName", this.tempCoworkerListForLeave[i].name).replace("@date", dates.join(' og ')).replace("@statusName", statusName) + "<br/>";
                //     }

                //     const dialogRef = this.dialog.open(AlertPlanningMessageModelComponent, {
                //         width: '40%',
                //         data: {
                //             Header: this.fullMessage,
                //             FirstButtonLabel: "Headers.ExtendWithoutFuture",
                //             SecondButtonLabel: "Headers.ExtendOnFuture",
                //         }
                //     });
                //     dialogRef.afterClosed().subscribe((result: any) => {
                //         if (result) {
                //             if (result == 1) {
                //                 this.setScheduleDaysByCowrker();
                //                 //this.saveTimer();
                //                 this.ShowSendSmsOrEmailSelection();
                //             }
                //             if (result == 2) {
                //                 this.workHour.CoworkerDetailsId = [];
                //                 this.setScheduleDaysByCowrker();
                //                 for (var i = 0; i < this.tempCoworkerListForLeave.length; i++) {
                //                     var CoworkerId = this.tempCoworkerListForLeave[i].id;
                //                     var tempDayList = [];

                //                     var detail = this.workHour.CoworkerDetailsId.find(o => o.CoworkerId == CoworkerId);
                //                     if (detail) {
                //                         var startDateCal = new Date(detail.ScheduleDays[0]);
                //                         var endDate = new Date(detail.ScheduleDays[detail.ScheduleDays.length - 1]);
                //                         //var startDateCal = new Date(this.createEventObj.startDate.getFullYear(),
                //                         //  this.createEventObj.startDate.getMonth(), this.createEventObj.startDate.getDate(), 0, 0, 0, 0);
                //                         for (let day = startDateCal; day <= endDate; day.setDate(day.getDate() + 1)) {
                //                             var tempDate = moment(day).format("DD.MM.YYYY");
                //                             var getHoliday = this.holidayList.find(o => moment(o.date).format("DD.MM.YYYY") == tempDate);
                //                             if (day.getDay() == 0 || day.getDay() == 6 || getHoliday) {
                //                                 endDate = new Date(endDate.setDate(endDate.getDate() + 1));
                //                                 // continue;
                //                                 if (this.createEventObj.weekendId == 1)
                //                                     continue;
                //                                 else if (this.createEventObj.weekendId == 2 && !getHoliday)
                //                                     tempDayList.push(moment(day).format("YYYY-MM-DD"));
                //                             }
                //                             //else if (getHoliday) {
                //                             //  endDate = new Date(endDate.setDate(endDate.getDate() + 1));
                //                             //  if (endDate.getDay() == 0) {
                //                             //    endDate = new Date(endDate.setDate(endDate.getDate() + 1));
                //                             //  }
                //                             //  else if (endDate.getDay() == 6) {
                //                             //    endDate = new Date(endDate.setDate(endDate.getDate() + 2));
                //                             //  }
                //                             //}
                //                             else if (!getHoliday || getHoliday.length == 0) {
                //                                 var data = this.tempCoworkerListForLeave[i].holidayList.find(o => moment(o.date).format("DD.MM.YYYY") == tempDate);
                //                                 var excudeDate = this.workHour.dayList.filter(x => x.isChecked == false && moment(x.date).format("DD.MM.YYYY") == tempDate);
                //                                 if (data || excudeDate.length > 0) {
                //                                     endDate = new Date(endDate.setDate(endDate.getDate() + 1));
                //                                     //if (endDate.getDay() == 0) {
                //                                     //  endDate = new Date(endDate.setDate(endDate.getDate() + 1));
                //                                     //}
                //                                     //else if (endDate.getDay() == 6) {
                //                                     //  endDate = new Date(endDate.setDate(endDate.getDate() + 2));
                //                                     //}
                //                                 }
                //                                 else {
                //                                     if (this.workHour.dayList.length != tempDayList.length) {
                //                                         tempDayList.push(moment(day).format("YYYY-MM-DD"));
                //                                     }
                //                                     else
                //                                         break;
                //                                 }
                //                             }
                //                         }
                //                         this.workHour.CoworkerDetailsId.filter(function (item) {
                //                             if (item.CoworkerId == CoworkerId) {
                //                                 item.ScheduleDays = null;
                //                                 item.ScheduleDays = tempDayList;
                //                             }
                //                         });
                //                     }
                //                 }
                //                 //this.saveTimer();
                //                 this.ShowSendSmsOrEmailSelection();
                //             }
                //             if (result == 3) {
                //                 this.showSpinnerForAdd = false;
                //             }
                //         }
                //     });
                // }
                // else {
                //     this.setScheduleDaysByCowrker();
                //     //this.saveTimer();
                //     this.ShowSendSmsOrEmailSelection();
                // }
            }
        }
    }

    setScheduleDaysByCowrker() {
        this.workHour.CoworkerDetailsId = [];
        for (var i = 0; i < this.currentselectedCoworkerIds.length; i++) {
            //var liftCoworker = this.selectedCoWorkerList.find(o =>
            //  o.id == this.currentselectedCoworkerIds[i] && o.name.toLowerCase() == "lift")
            //if (liftCoworker && this.currentselectedCoworkerIds.length > 1) {
            //  var CoworkerDetails = new CoworkerDays();
            //  CoworkerDetails.CoworkerId = this.currentselectedCoworkerIds[i];
            //  CoworkerDetails.ScheduleDays = [];
            //  this.workHour.CoworkerDetailsId.push(CoworkerDetails);
            //}
            //else {
            var CoworkerDetails = new CoworkerDays();
            CoworkerDetails.CoworkerId = this.currentselectedCoworkerIds[i];
            CoworkerDetails.ScheduleDays = this.workHour.dayList.filter(x => x.isChecked == true).map(p => p.date); //this.workHour.dayList;
            this.workHour.CoworkerDetailsId.push(CoworkerDetails);
            //}
        }
    }

    // getTotalWorkHourOfCoworker() {
    //     if (this.createEventObj.shift == 2 || this.createEventObj.shift == 3) {
    //         this.totalWorkHourOfCoworker = (this.workHour.dayList.length * (this.createEventObj.shift == 2 ? 7 : 7.5));
    //     }
    //     else if (this.createEventObj.shift == 4) {
    //         var from = this.workHour.fromStartHour + ":" + this.workHour.fromStartMinute;
    //         var to = this.workHour.fromEndHour + ":" + this.workHour.fromEndMinute;
    //         var time = moment.utc(moment(to, "HH:mm").diff(moment(from, "HH:mm"))).format("HH:mm")
    //         var tidTime = moment(time, "HH:mm").format("mm");
    //         var tidHours = moment(time, "HH:mm").format("HH");
    //         this.AddBreaktime();
    //         if (tidTime != "00")
    //             time = tidHours + ":" + (Number(this.getMinute(tidTime))).toString();
    //         if (this.isAddBreakTime)
    //             time = (Number(time.replace(':', '.')) - 0.50).toString();
    //         else
    //             time = (Number(time.replace(':', '.'))).toString();
    //         if (Number(time) > 0)
    //             this.totalWorkHourOfCoworker = (this.workHour.dayList.length * (Number(time)));
    //         else
    //             this.totalWorkHourOfCoworker = 0;
    //     }
    // }

    // onChangeHoursAndMinute() {
    //     //this.getTotalWorkHourOfCoworker();
    // }

    grandHours() {
        // var data = this.selectedCoWorkerList.filter(x => x.name == "Lift");
        // if (data && data.length > 0)
        //     return (this.totalWorkHourOfCoworker * (this.selectedCoWorkerList.length - 1));
        // else
        //     return (this.totalWorkHourOfCoworker * this.selectedCoWorkerList.length);
        return this.selectedCoWorkerList.filter(o => o.name.toLowerCase() != "lift").reduce((sum, current) => sum + current.totalWorkHourOfCoworker, 0);
    }

    saveTimer() {
        if (this.selectedCoWorkerList.length == 1 && this.selectedCoWorkerList[0].name == "Lift") {
            this.workHour.dayList = [];
            var localstartDate = new Date(new Date(this.createEventObj.startDate).getFullYear(),
                new Date(this.createEventObj.startDate).getMonth(), new Date(this.createEventObj.startDate).getDate(), 0, 0, 0, 0);
            var localEndDate = new Date(new Date(this.createEventObj.endDate).getFullYear(),
                new Date(this.createEventObj.endDate).getMonth(), new Date(this.createEventObj.endDate).getDate(), 0, 0, 0, 0);
            for (let day = localstartDate; day <= localEndDate; day.setDate(day.getDate() + 1)) {
                this.workHour.dayList.push(moment(day).format("YYYY-MM-DD"));
            }
        }
        else
            this.workHour.dayList = this.workHour.dayList.filter(x => x.isChecked == true).map(p => p.date);
        this.workHour.id = this.workHourId;
        this.workHour.weekendId = this.createEventObj.weekendId;
        this.workHour.projectId = this.createEventObj.projectId;
        this.workHour.customerId = this.createEventObj.customerId;
        this.workHour.workerId = this.currentselectedCoworkerIds.join().toString();
        this.workHour.sagOpgaveID = this.createEventObj.sagOpgaveID;
        this.workHour.isWorking = true;
        this.workHour.shiftType = this.createEventObj.shift;
        this.workHour.projectNumber = this.createEventObj.projectNumber;

        if (this.createEventObj.shift == 2) {
            this.workHour.from = "07:00";
            this.workHour.to = "14:30";
            this.workHour.Tid = 7.5;
            this.workHour.pauseTimer = 0.5;
            this.workHour.Hours = "7";
        }
        else if (this.createEventObj.shift == 3) {
            this.workHour.from = "07:00";
            this.workHour.to = "15:00";
            this.workHour.Tid = 8.00;
            this.workHour.pauseTimer = 0.5;
            this.workHour.Hours = "07.5";
        }
        else if (this.createEventObj.shift == 4) {
            this.workHour.from = this.workHour.fromStartHour + ":" + this.workHour.fromStartMinute;
            this.workHour.to = this.workHour.fromEndHour + ":" + this.workHour.fromEndMinute;
            var time = moment.utc(moment(this.workHour.to, "HH:mm").diff(moment(this.workHour.from, "HH:mm"))).format("HH:mm")
            var tidTime = moment(time, "HH:mm").format("mm");
            var tidHours = moment(time, "HH:mm").format("HH");
            if (tidTime != "00")
                time = tidHours + ":" + (Number(this.getMinute(tidTime))).toString();
            this.workHour.Tid = Number(time.replace(':', '.'));
            if (this.isAddBreakTime)
                this.workHour.pauseTimer = 0.5;
            else
                this.workHour.pauseTimer = 0;
            //this.workHour.Hours = time.replace(':', '.');
            this.workHour.Hours = (Number(time.replace(':', '.')) - this.workHour.pauseTimer).toString();
        }
        var startDate = moment(moment(this.createEventObj.startDate).format('YYYY/MM/DD'));
        var endDate = moment(moment(this.createEventObj.endDate, "DD.MM.YYYY").format('YYYY/MM/DD'));
        this.workHour.days = Math.abs(startDate.diff(endDate, 'days'));
        this.workHour.date = this.datePipe.transform(this.createEventObj.startDate, "MM/dd/yyyy");
        this.workHour.startDate = moment(this.createEventObj.startDate).format("YYYY-MM-DD");
        this.workHour.endDate = moment(this.createEventObj.endDate).format("YYYY-MM-DD");
        var projectCoworkerObj = this.projectCoworkerObj;
        var newAddedCowkerList = this.currentselectedCoworkerIds.filter(function (item) {
            return !projectCoworkerObj.includes(item);
        })
        this.workHour.newAddedCoworkerIds = newAddedCowkerList ? newAddedCowkerList.join().toString() : "";
        if (this.workHour.isWorking)
            this.addScheduleDaysAPICall(this.workHour);
        else
            this.addWorkhourAPICall(this.workHour);
    }

    addWorkhourAPICall(workHour) {
        this.showSpinnerForAdd = true;
        this.workhourservice.AddWithDateRange<ResponseBase>(workHour)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.translate.get("MESSAGES.Add").subscribe({
                        next: (res) => {
                            this.notificationsService.success(res);
                        },
                        error: err => {
                        }
                    });
                    this.dialogRef.close({
                        updateSchedule: true,
                        deleteSchedule: false,
                        goToProject: false,
                        projectId: this.projectInfo.sagId,
                        workHourId: this.workHourId,
                        weekNumber: moment(this.createEventObj.startDate).week().toString(),
                        response: response.result ? response.result : null,
                    });
                },
                error: err => {
                    this.workHourNotAddedOnCoworker = err.error.error.result.coworkerName;
                    this.isDisplayErrorMessage = true;
                    this.translate.get("MESSAGES.Some_Coworker_Add_Error").subscribe({
                        next: (res) => {
                            this.notificationsService.warn(res);
                        },
                        error: err => {
                        }
                    });
                },
                complete: () => {
                    this.showSpinnerForAdd = false;
                }
            });
    }

    onFocusIn(event) {
        event.model.show();
    }

    goToProject() {
        this.dialogRef.close({
            updateSchedule: false,
            deleteSchedule: false,
            goToProject: true,
            projectId: this.projectInfo.sagId,
            workHourId: this.workHourId,
        });
    }

    // getTransator(value) {
    //     if (value)
    //         return 'Lable.Yes'
    //     else
    //         return 'Lable.No'
    // }

    refreshMaterialList() {
        let objMaterialProject = new RequestCaseTask();
        objMaterialProject.fileAssignmentId = this.Requisition.id;
        objMaterialProject.IsDeleted = false;
        this.getProjectMaterialList(objMaterialProject);
    }

    calculatePrice() {
        this.Requisition.includePrice = Number((this.Requisition.excludePrice + ((Number(this.Requisition.excludePrice) * 25) / 100)));
        this.Requisition.vatPrice = Number((Number(this.Requisition.excludePrice) * 25) / 100);
        this.Requisition.includeEstimatePrice = Number((this.Requisition.estimatedPrice + ((Number(this.Requisition.estimatedPrice) * 25) / 100)));
        this.Requisition.vatEstimatePrice = Number((Number(this.Requisition.estimatedPrice) * 25) / 100);
        this.getTotalEtstimeteOrPrice();
    }

    getTotalEtstimeteOrPrice() {
        if (this.Requisition.includeEstimatePrice > 0) {
            this.Requisition.totalIncludeEstimatePrice = this.Requisition.includeEstimatePrice;
            this.Requisition.totalVatEstimatePrice = (this.Requisition.vatEstimatePrice);
        }
        else if (this.Requisition.includePrice > 0) {
            this.Requisition.totalIncludeEstimatePrice = (this.Requisition.includePrice);
            this.Requisition.totalVatEstimatePrice = (this.Requisition.vatPrice);
        }
        else {
            this.Requisition.totalIncludeEstimatePrice = (this.Requisition.includeEstimatePrice);
            this.Requisition.totalVatEstimatePrice = (this.Requisition.vatEstimatePrice);
        }
    }

    UpdateRequisitionStatus() {
        var requisition = this.RequisitionList.find(o => o.id == this.createEventObj.sagOpgaveID);
        if (requisition) {
            requisition.status = this.createEventObj.status;
            this.projectRequisitionService.Update(requisition.projectId, requisition.id, requisition).subscribe({
                next: (response: ResponseBase) => {
                    if (response) {
                        this.createEventObj.status = response.result.status;
                        this.translate.get("MESSAGES.Updated").subscribe({
                            next: (res) => {
                                this.notificationsService.create(res);
                            },
                            error: err => {
                            }
                        });
                    }
                },
                error: err => {
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });
                }
            });
        }
    }

    UpdatecolorCodeOnProject() {
        var obj = { colorcode: this.colorCode };
        this.projectRequisitionService.UpdateColorCodeOnProject(this.projectInfo.sagId, obj).subscribe({
            next: (response: ResponseBase) => {
                if (response) {
                    this.translate.get("MESSAGES.Updated").subscribe({
                        next: (res) => {
                            this.notificationsService.create(res);
                        },
                        error: err => {
                        }
                    });
                }
            },
            error: err => {
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            }
        });
    }

    getTotalPrice(qty, price) {
        var isNegative = false;
        if (qty < 0)
            isNegative = true;
        if (price < 0)
            isNegative = true;
        var total = (qty * price);
        if (isNegative)
            total = -Math.abs(total)
        return total;
    }

    getMinute(value) {
        if (value == "00")
            return "00";
        if (value == "15")
            return "25";
        if (value == "30")
            return "50";
        if (value == "45")
            return "75";
    }

    CheckEndHour(checkMinute) {
        this.workHour.fromEndHour = (this.workHour.fromEndHour < this.workHour.fromStartHour) == true ? (this.workHour.fromEndHour = this.workHour.fromStartHour) : this.workHour.fromEndHour;
    }

    UpdateRequisition(event) {
        this.showSpinnerForAdd = true;
        this.Requisition = event;
        this.Requisition.CreatedBy = this.authService.getCoWorkerId();
        this.projectRequisitionService.Update(this.Requisition.projectId, this.Requisition.id, this.Requisition).subscribe({
            next: (response: ResponseBase) => {
                if (response) {
                    var index = this.RequisitionList.findIndex(f => f.id == this.Requisition.id);
                    this.RequisitionList.splice(index, 1);
                    this.RequisitionList.splice(index, 0, response.result);
                    this.Requisition.startDate = response.result.startDate;
                    this.Requisition.endDate = response.result.endDate;
                    this.Requisition.comingDate = response.result.comingDate;
                    this.Requisition.status = response.result.status;
                    if (this.Requisition.left || this.Requisition.riseman) {
                        this.displaySpecialInfoRed = true;
                    }
                    else {
                        this.displaySpecialInfoRed = false;
                    }
                    this.translate.get("MESSAGES.Updated").subscribe({
                        next: (res) => {
                            this.notificationsService.create(res);
                        },
                        error: err => {
                        }
                    });
                }
            },
            error: err => {
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            },
            complete: () => {
                this.showSpinnerForAdd = false;
            }
        });
    }

    // Tabs Code start
    isDisplayMtoMTabRed(event) {
        this.displayMtoMTabRed = event;
    }

    isDisplayNoteTabRed(event) {
        this.displayNoteTabRed = event;
    }

    isDisplayOffersTabRed(event) {
        this.displayOffersTabRed = event;
    }
    TabChange(event) {
        this.selectedIndex = event.index;
    }
    //End


    goToStep(value) {
        this.selectedIndex = value;
        this.OpenAccordionForOtherDetail(true)
        this.isOpenMaterialPopup = true;
    }

    addWeeksNumber() {
        this.weekNumbers = [];
        for (var i = 1; i < 54; i++) {
            this.weekNumbers.push({ value: i.toString() });
        }
    }

    DropDownWeekNumberSelect() {
        this.createEventObj.startDate = moment().isoWeek(this.createEventObj.startWeekNumber || 1).startOf("week").format("DD.MM.YYYY");
        this.calculateEndDateWithRemainingHour();
    }

    onCheckCaseOnHourly(value) {
        this.Requisition.isHourlyWage = !value;
        this.Requisition.hourlyWage = 0;

        if (this.Requisition.isHourlyWage) {
            this.Requisition.excludePrice = 0
            this.Requisition.estimatedPrice = 0
            this.ChangePrice();
            this.ChangeEstimatePrice();
        }
        setTimeout(() => {
            this.UpdateRequisition(this.Requisition);
        }, 500);
    }

    OpenAccordion(flag) {
        this.IsExpand = !flag;
    }

    CloseAccordion(flag) {
        this.IsExpand = !flag;
    }

    OpenAccordionForOtherDetail(flag) {
        if (this.selectedIndex == 6) {
            this.IsExpandOtherDetails = true;
        } else {
            this.IsExpandOtherDetails = !flag;
        }
    }

    CloseAccordionForOtherDetail(flag) {
        this.selectedIndex = 0;
        this.IsExpandOtherDetails = !flag;
    }

    // Code to Prevent accordion from expantion
    expandPanelForOtherDetails(matExpansionPanel: MatExpansionPanel, event: Event): void {
        event.stopPropagation(); // Preventing event bubbling
        if (!this.IsExpandOtherDetails) {
            if (!this._isExpansionIndicator(event.target)) {
                matExpansionPanel.close(); // Here's the magic
            }
        }
    }

    // Code to Prevent accordion from expantion
    expandPanel(matExpansionPanel: MatExpansionPanel, event: Event): void {
        event.stopPropagation(); // Preventing event bubbling
        if (!this.IsExpand) {
            if (!this._isExpansionIndicator(event.target)) {
                matExpansionPanel.close(); // Here's the magic
            }
        }
    }

    // Code to Prevent accordion from expantion
    expandPanel2(matExpansionPanel2: MatExpansionPanel, event: Event): void {
        event.stopPropagation(); // Preventing event bubbling
        if (!this._isExpansionIndicator(event.target)) {
            matExpansionPanel2.close(); // Here's the magic
        }
    }

    // Code to Prevent accordion from expantion
    expandPanelForFileExtraTab(matExpansionPanelForFileExtraTab: MatExpansionPanel, event: Event): void {
        event.stopPropagation(); // Preventing event bubbling
        if (!this._isExpansionIndicator(event.target)) {
            matExpansionPanelForFileExtraTab.close(); // Here's the magic
        }
    }

    private _isExpansionIndicator(target: EventTarget): boolean {
        const expansionIndicatorClass = 'mat-expansion-indicator';
        return ((<HTMLElement>target).classList && (<HTMLElement>target).classList.contains(expansionIndicatorClass));
    }

    DropDownShiftChange() {
        if (this.createEventObj.shift == 4) {
            this.AddBreaktime()
        }
        //this.onChangeHoursAndMinute();
        //this.reCalculateScheduleDays();
        this.calculateEndDateWithRemainingHour();
    }

    setLiftCoworker() {
        if (this.Requisition.left) {
            var selectCoworker = this.coWorkerList.find(o => o.name == "Lift");
            if (selectCoworker) {
                this.addCoworker(selectCoworker);
                //this.selectedCoWorkerList.push(selectCoworker);
                this.currentselectedCoworkerIds = this.selectedCoWorkerList.map(o => o.id);
            }
            this.getWorkHoursForLiftCoworker();
        }
    }

    getWorkHoursForLiftCoworker() {
        var tempDate = moment(new Date()).format("YYYY-MM-DD");
        if (this.coWorkerList.length > 0) {
            var selectCoworker = this.coWorkerList.find(o => o.name.toLowerCase() == "lift");
            if(selectCoworker){
                this.calendarService.GetWorkHoursForLiftCoworker<ResponseBase>(selectCoworker.id, tempDate)
                    .subscribe((response: ResponseBase) => {
                        if (response) {
                            this.workHoursForLiftCoworker = response.result;
                        }
                    }, error => (err) => { }, () => { });
            }
        }
    }

    calculateProjMaterialPriceTotal() {
        this.materialTotalPrice = 0;
        this.materialTotalPriceWithVAT = 0;
        this.materialPersonalTotalPrice = 0;
        this.projectMaterials.forEach((e: any) => {
            if (e.material && !e.material.materialsForTheCase) {
                if (!e.totalPrice) {
                    e.totalPrice = this.getTotalPrice((e.quantity ? e.quantity : 0), e.retailPrice);
                }
                if (!e.personalPrisTotal) {
                    e.personalPrisTotal = this.getTotalPrice((e.quantity ? e.quantity : 0), e.personalPrice);
                }
                this.materialTotalPrice += e.totalPrice;
                if (e.personalPrice && e.personalPrice != 0) {
                    this.materialPersonalTotalPrice += e.personalPrisTotal;
                }
                else {
                    e.personalPrice = 0;
                }

                if (e.totalPriceVAT) {
                    this.materialTotalPriceWithVAT += e.totalPriceVAT;
                } else {
                    var vat = (e.totalPrice * 25) / 100;
                    this.materialTotalPriceWithVAT += (e.totalPrice + vat);
                }
            }
        });
    }

    getCowrkerListAndPlanningDate(sagId, sagOpgaveId) {
        if (this.createEventObj.projectBasedPlanning)
            sagOpgaveId = "";
        else
            sagId = "";
        this.workhourservice.GetCoworkerListAndPlanningDate<ResponseBase>(sagId, sagOpgaveId)
            .subscribe((response: ResponseBase) => {
                if (response) {
                    this.coworkerListAndPlaningDates = response.result;
                }
            },
                error => (err) => {
                }, () => { });
    }
    closeAddMaterialPopup(event) {
        this.isOpenMaterialPopup = false;
    }

    _handleKeydown(event: KeyboardEvent) {
        if (event.keyCode === 13) {
            event.stopPropagation();
        }
    }

    AddBreaktime() {
        this.isBreakTime = false;
        this.isAddBreakTime = false;
        if (this.workHour.fromEndHour <= '11' && this.workHour.fromEndMinute <= '30') {
            this.isBreakTime = false;
        } else if (this.workHour.fromEndHour == '11' && this.workHour.fromEndMinute < '30') {
            this.isBreakTime = true;
            this.isAddBreakTime = true;
        } else if (this.workHour.fromStartHour >= '12' && this.workHour.fromStartMinute >= '30') {
            this.isBreakTime = false;
        } else if (this.workHour.fromStartHour == '12' && this.workHour.fromStartMinute < '30') {
            this.isBreakTime = true;
            this.isAddBreakTime = true;
        } else if (this.workHour.fromStartHour >= '12') {
            this.isBreakTime = false;
        } else {
            this.isBreakTime = true;
            this.isAddBreakTime = true;
        }
    }

    ShowSendSmsOrEmailSelection() {
        if (this.createEventObj.doNotSendNotification) {
            this.saveTimer();
        }
        else {
            var msg = "Vi forventer at komme og male hos jer " + moment(this.createEventObj.startDate).format("DD.MM.YYYY") + " kl. " + this.workHour.fromStartHour + ':' + this.workHour.fromStartMinute;
            if(this.projectInfo.notificationType != undefined){
                this.workHour.sendType = this.projectInfo.notificationType;
                this.workHour.message = msg;
                this.saveTimer();
            }
            else {
                const dialogRef = this.dialog.open(SMSEmailSelectionComponent, {
                    height: '40%',
                    width: '40%',
                    data: { message: msg },
                    disableClose: true
                });
                dialogRef.afterClosed().subscribe(result => {
                    if (result) {
                        this.workHour.sendType = result.sendType;
                        this.workHour.message = result.message;
                        this.saveTimer();
                    }
                });
            }
        }

    }

    onCheckNotifyToCustomer(flag) {
        this.workHour.isNotifyToCustomer = !flag
    }

    getCalenderData(model: RequestResourceWorkHours) {
        this.selectedcoWorkerIds = [];
        for (let i = 0; i < this.selectedCoWorkerList.length; i++) {
            this.selectedcoWorkerIds.push(this.selectedCoWorkerList[i].id);
        }
        model.CoworkerId = this.selectedcoWorkerIds.join();
        model.EndDate = moment(this.createEventObj.endDate).format("YYYY-MM-DD");
        model.StartDate = moment(this.createEventObj.startDate).format("YYYY-MM-DD");
        model.Index = 1;
        model.Limit = 1000;
        model.WorkingStatus = 1;
        this.showSpinnerForAdd = true;
        this.coWorkersService.GetCoworkersWithWorkHours<ResponseBase>(model)
            .subscribe((response: ResponseBase) => {
                if (response) {
                    this.workHoursList = response.result.workHoursList;
                    var isProjectPresent = this.workHoursList.filter(x => (moment(x.date).format("YYYY-MM-DD") >= moment(this.createEventObj.startDate).format("YYYY-MM-DD") || moment(x.date).format("YYYY-MM-DD") <= moment(this.createEventObj.endDate).format("YYYY-MM-DD")) && this.createEventObj.projectNumber == x.sagNummer && this.createEventObj.sagOpgaveID == x.sagOpgaveID).length;
                    if (isProjectPresent > 0) {
                        this.showSpinnerForAdd = false;
                        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
                            height: '200px',
                            data: {
                                Header: 'MESSAGES.AlreadyProjPlanned',
                                ShowOKButton: true
                            }
                        });
                        dialogRef.afterClosed().subscribe(result => {
                            if (result) {
                                return;
                            }
                        });
                    } else {
                        this.planWorkHours();
                    }
                }
            });
    }

    DropDownWeekPlaningChange() {
        //this.calculateEndDate(false);
        this.calculateEndDateWithRemainingHour();
    }

    pushDayList(date, isChecked) {
        //this.workHour.dayList.push(moment(day).format("YYYY-MM-DD"));
        let workhoursDay = new WorkhoursDay();
        workhoursDay.date = date;
        workhoursDay.isChecked = isChecked;
        this.workHour.dayList.push(workhoursDay)
    }

    getWeekName(date) {
        return moment(date, "YYYY-MM-DD").format("ddd")
    }

    onCheckWorkhoursDay(value, index) {
        this.workHour.dayList[index].isChecked = !value;
    }
    addCoworker(findCoworker) {
        var coworker = new CoworkerWithScheduleDetails();
        coworker.id = findCoworker.id;
        coworker.CoworkerId = findCoworker.id;
        coworker.name = findCoworker.name;
        coworker.phone = findCoworker.phone;
        coworker.totalWorkHourOfCoworker = 0;
        coworker.ScheduleDays = [];
        this.checkCoworkerOnleave(coworker);
        this.reCalculateScheduleDays();
    }

    checkCoworkerOnleave(coworker) {
        if (coworker && coworker.id) {
            this.tempCoworkerListForLeave = [];
            this.tempDayList = [];
            this.createEventObj.startDate = moment(this.createEventObj.startDate).toDate();
            var sdate = new Date(this.createEventObj.startDate.setHours(0, 0, 0, 0));
            var edate = new Date(this.createEventObj.endDate.setHours(0, 0, 0, 0));
            this.isCoworkerInLeave = false;
            this.coworkerLeaveList = [];
            this.coworkerLeaveList = this.coWorkerList.filter(x => x.id == coworker.id);
            if (this.coworkerLeaveList.length > 0 && this.coworkerLeaveList[0].holidayList && this.coworkerLeaveList[0].holidayList.length > 0) {
                var data = this.coworkerLeaveList[0].holidayList.filter(o => (new Date(o.date) >= sdate && new Date(o.date) <= edate));
                if (data.length > 0) {
                    this.isCoworkerInLeave = true;
                    var tempCoworker = {
                        id: this.coworkerLeaveList[0].id,
                        name: this.coworkerLeaveList[0].name,
                        email: this.coworkerLeaveList[0].email,
                        coworkerNumber: this.coworkerLeaveList[0].coworkerNumber,
                        mobile: this.coworkerLeaveList[0].mobile,
                        isActive: this.coworkerLeaveList[0].isActive,
                        isDeleted: this.coworkerLeaveList[0].isDeleted,
                        initials: this.coworkerLeaveList[0].initials,
                        holidayList: this.coworkerLeaveList[0].holidayList.sort((a, b) => {
                            return <any>new Date(a.date) - <any>new Date(b.date);
                        }),
                    }
                    this.tempCoworkerListForLeave.push(tempCoworker);
                }
            }
            if (this.isCoworkerInLeave) {
                this.fullMessage = "";
                this.message = "";
                this.translate.get("MESSAGES.CoworkerOnLeave").subscribe({
                    next: (res) => { this.message = res; }, error: err => { }
                });
                for (var i = 0; i < this.tempCoworkerListForLeave.length; i++) {
                    var holidayListLocal = this.tempCoworkerListForLeave[i].holidayList.filter(o => (new Date(o.date) >= sdate && new Date(o.date) <= edate));
                    var dates = holidayListLocal.map(function (a) {
                        return moment(a.date).format("DD.MM.YYYY");
                    });
                    var status = holidayListLocal.map(function (a) {
                        return "selectedWorkHourStatus.value" + a.status;
                    });
                    var statusName = "";
                    if (status) {
                        var that = this;
                        var filterStatus = status.filter((n, i) => status.indexOf(n) === i);
                        filterStatus.filter(function (a) {
                            that.translate.get(a).subscribe({
                                next: (res) => {
                                    statusName += statusName + res + ',';
                                }, error: err => { }
                            });
                        });
                        statusName = statusName.substring(0, statusName.length - 1);
                    }
                    this.fullMessage = this.fullMessage + this.message.replace("@coworkerName", this.tempCoworkerListForLeave[i].name).replace("@date", dates.join(' og ')).replace("@statusName", statusName) + "<br/>";
                }

                const dialogRef = this.dialog.open(AlertPlanningMessageModelComponent, {
                    width: '40%',
                    data: {
                        Header: this.fullMessage,
                        FirstButtonLabel: "Headers.ExtendWithoutFuture",
                        SecondButtonLabel: "Headers.ExtendOnFuture",
                    }
                });
                dialogRef.afterClosed().subscribe((result: any) => {
                    if (result) {
                        if (result == 1) {
                            this.addCoworkerScheduleDays(coworker)
                        }
                        else if (result == 2) {
                            this.workHour.CoworkerDetailsId = [];
                            this.setScheduleDaysByCowrker();
                            coworker.isLeaveExtended = true;
                            this.addCoworkerScheduleDays(coworker);
                            //coworker.totalWorkHourOfCoworker = coworker.ScheduleDays.filter(s=>s.isChecked == true).reduce((sum, current) => sum + parseFloat(current.hours), 0)
                            //this.selectedCoWorkerList.push(coworker);
                        }
                        else
                            this.selectedCoWorkerDelete(coworker.id)
                    }
                });
            }
            else
                this.addCoworkerScheduleDays(coworker)
        }
    }
    pushDayList1(date, isChecked, isLastElement, coworker) {
        let scheduleDay = new ScheduleDays();
        scheduleDay.date = date;
        scheduleDay.isChecked = isChecked;
        var remaingHours = ((this.Requisition.estimatedTime ? this.Requisition.estimatedTime : 0) - this.Requisition.plannedWorkHour);
        if (this.createEventObj.shift == 4 && this.createEventObj.visitdays)
            this.addManualTimerOnLastDayForExtended(coworker, scheduleDay);
        else if (isLastElement && remaingHours > 0)
            this.addManualTimerOnLastDayForExtended(coworker, scheduleDay);
        else {
            if (new Date(moment(date).format("YYYY-MM-DD")).getDay() == 5) {
                scheduleDay.from = "07:00";
                scheduleDay.to = "14:30";
                scheduleDay.tid = 7.5;
                scheduleDay.pauseTimer = 0.5;
                scheduleDay.hours = "7";
                scheduleDay.shiftType = 2;
                scheduleDay.shiftTypeName = 'selectedShiftOption.value1'
            }
            else {
                scheduleDay.from = "07:00";
                scheduleDay.to = "15:00";
                scheduleDay.tid = 8.00;
                scheduleDay.pauseTimer = 0.5;
                scheduleDay.hours = "07.5";
                scheduleDay.shiftType = 3;
                scheduleDay.shiftTypeName = 'selectedShiftOption.value2'
            }
        }
        this.tempDayList.push(scheduleDay)
    }

    onCheckScheduleDay(value, index, item, coworker) {
        item.isChecked = !value;
        coworker.totalWorkHourOfCoworker = coworker.ScheduleDays.filter(s => s.isChecked == true).reduce((sum, current) => sum + parseFloat(current.hours), 0)
    }
    addCoworkerScheduleDays(coworker) {
        coworker.totalWorkHourOfCoworker = coworker.ScheduleDays.filter(s => s.isChecked == true).reduce((sum, current) => sum + parseFloat(current.hours), 0)
        this.selectedCoWorkerList.push(coworker);
        this.reCalculateScheduleDays();
    }

    editShift(scheduleDay, coworker) {
        const dialogRef = this.dialog.open(ShiftSelectionComponent, {
            height: '45%',
            width: '40%',
            data: { scheduleDay: scheduleDay, coworker: coworker }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                if (result.isCheckedAll) {
                    coworker.ScheduleDays.filter(item => {
                        item.from = result.from;
                        item.to = result.to;
                        item.tid = result.tid;
                        item.pauseTimer = result.pauseTimer;
                        item.hours = result.hours;
                        item.shiftType = result.shiftType;
                        item.shiftTypeName = result.shiftTypeName;
                    })
                }
                else
                    scheduleDay = result
                coworker.totalWorkHourOfCoworker = coworker.ScheduleDays.filter(s => s.isChecked == true).reduce((sum, current) => sum + parseFloat(current.hours), 0);
            }
        });
    }

    addScheduleDaysAPICall(workHour) {
        this.coworkerScheduleDayList = [];
        this.selectedCoWorkerList.filter(item => {
            var workingHourOtherInfo = new WorkingHourOtherInfo();
            workingHourOtherInfo.StartDate = item.ScheduleDays[0].date + "T00:00:00";
            workingHourOtherInfo.EndDate = item.ScheduleDays[item.ScheduleDays.length - 1].date + "T00:00:00";
            workingHourOtherInfo.SagOpgaveID = this.createEventObj.projectBasedPlanning ? "" : workHour.sagOpgaveID;
            workingHourOtherInfo.InquiryID = workHour.inquiryId;
            workingHourOtherInfo.SagID = workHour.projectId;
            workingHourOtherInfo.BunchId = this.commonService.GenerateRandomUniqueId();
            workingHourOtherInfo.IsNotifyToCustomer = workHour.isNotifyToCustomer;
            var tempDates = item.ScheduleDays.filter(y => y.isChecked == true).map(x => x.date);
            var PlanningDates = tempDates;
            // tempDates.filter(d => {
            //     PlanningDates.push(moment(d, "YYYY-MM-DD").format("DD-MM-YYYY"));
            // })
            workingHourOtherInfo.PlanningDates = PlanningDates.join(',');
            workingHourOtherInfo.WeekendId = workHour.weekendId;
            item.ScheduleDays.filter(days => {
                var ScheduleDay = new CoworkerScheduleDayList();
                ScheduleDay.date = days.date;
                ScheduleDay.workerId = item.id;
                ScheduleDay.name = item.name;
                ScheduleDay.from = days.from;
                ScheduleDay.to = days.to;
                ScheduleDay.tid = days.tid;
                ScheduleDay.pauseTimer = days.pauseTimer;
                ScheduleDay.hours = days.hours;
                ScheduleDay.shiftType = days.shiftType;
                ScheduleDay.isChecked = days.isChecked;
                ScheduleDay.projectId = this.workHour.projectId;
                ScheduleDay.requisitionId = this.createEventObj.projectBasedPlanning ? "" : this.workHour.sagOpgaveID; //this.workHour.sagOpgaveID;
                ScheduleDay.sagOpgaveId = this.createEventObj.projectBasedPlanning ? "" : this.workHour.sagOpgaveID; //this.workHour.sagOpgaveID
                ScheduleDay.isWorking = this.workHour.isWorking;
                ScheduleDay.inquiryId = this.workHour.inquiryId;
                ScheduleDay.leaveStatus = 0;
                ScheduleDay.projectNumber = this.workHour.projectNumber.toString();
                ScheduleDay.otherInfo = JSON.stringify(workingHourOtherInfo);
                ScheduleDay.startDate = moment(this.createEventObj.startDate).format("YYYY-MM-DD");
                ScheduleDay.endDate = moment(this.createEventObj.endDate).format("YYYY-MM-DD");
                ScheduleDay.customerId = this.workHour.customerId;
                ScheduleDay.sendType = this.workHour.sendType;
                ScheduleDay.message = this.workHour.message;
                ScheduleDay.newAddedCoworkerIds = this.workHour.newAddedCoworkerIds;
                ScheduleDay.projectBasedPlanning = this.createEventObj.projectBasedPlanning
                if (ScheduleDay.isChecked)
                    this.coworkerScheduleDayList.push(ScheduleDay);
            })

        });
        //workHour.CoworkerScheduleDayList = this.coworkerScheduleDayList;
        this.showSpinnerForAdd = true;
        this.workhourservice.AddScheduleDays<ResponseBase>(this.coworkerScheduleDayList)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.translate.get("MESSAGES.Add").subscribe({
                        next: (res) => {
                            this.notificationsService.success(res);
                        },
                        error: err => {
                        }
                    });
                    this.dialogRef.close({
                        updateSchedule: true,
                        deleteSchedule: false,
                        goToProject: false,
                        projectId: this.projectInfo.sagId,
                        workHourId: this.workHourId,
                        weekNumber: moment(this.createEventObj.startDate).week().toString(),
                        dataYear: moment(this.createEventObj.startDate).year().toString(),
                        response: response.result ? response.result : null,
                    });
                },
                error: err => {
                    this.showSpinnerForAdd = false;
                    this.workHourNotAddedOnCoworker = err.error.error.result.coworkerName;
                    this.isDisplayErrorMessage = true;
                    this.translate.get("MESSAGES.Some_Coworker_Add_Error").subscribe({
                        next: (res) => {
                            this.notificationsService.warn(res);
                        },
                        error: err => {
                        }
                    });
                },
                complete: () => {
                    this.showSpinnerForAdd = false;
                }
            });
    }

    reCalculateScheduleDays() {
        if (this.selectedCoWorkerList.length > 0) {
            this.selectedCoWorkerList.filter(coworker => {
                if (coworker && coworker.id) {
                    coworker.ScheduleDays = [];
                    this.tempCoworkerListForLeave = [];
                    this.tempDayList = [];
                    this.createEventObj.startDate = moment(this.createEventObj.startDate).toDate();
                    var sdate = new Date(this.createEventObj.startDate.setHours(0, 0, 0, 0));
                    var edate = new Date(this.createEventObj.endDate.setHours(0, 0, 0, 0));
                    this.isCoworkerInLeave = false;
                    this.coworkerLeaveList = [];
                    this.coworkerLeaveList = this.coWorkerList.filter(x => x.id == coworker.id);
                    if (this.coworkerLeaveList.length > 0 && this.coworkerLeaveList[0].holidayList && this.coworkerLeaveList[0].holidayList.length > 0) {
                        var data = this.coworkerLeaveList[0].holidayList.filter(o => (new Date(o.date) >= sdate && new Date(o.date) <= edate));
                        if (data.length > 0) {
                            this.isCoworkerInLeave = true;
                            var tempCoworker = {
                                id: this.coworkerLeaveList[0].id,
                                name: this.coworkerLeaveList[0].name,
                                email: this.coworkerLeaveList[0].email,
                                coworkerNumber: this.coworkerLeaveList[0].coworkerNumber,
                                mobile: this.coworkerLeaveList[0].mobile,
                                isActive: this.coworkerLeaveList[0].isActive,
                                isDeleted: this.coworkerLeaveList[0].isDeleted,
                                initials: this.coworkerLeaveList[0].initials,
                                holidayList: this.coworkerLeaveList[0].holidayList.sort((a, b) => {
                                    return <any>new Date(a.date) - <any>new Date(b.date);
                                }),
                            }
                            this.tempCoworkerListForLeave.push(tempCoworker);
                        }
                    }
                    if (this.isCoworkerInLeave) {
                        if (!coworker.isLeaveExtended) {
                            this.reCoworkerScheduleDays(coworker)
                        }
                        else if (coworker.isLeaveExtended) {
                            this.workHour.CoworkerDetailsId = [];
                            this.setScheduleDaysByCowrker();
                            for (var i = 0; i < this.tempCoworkerListForLeave.length; i++) {
                                var CoworkerId = this.tempCoworkerListForLeave[i].id;
                                this.tempDayList = [];
                                var detail = this.workHour.CoworkerDetailsId.find(o => o.CoworkerId == CoworkerId);
                                if (detail) {
                                    var startDateCal = new Date(detail.ScheduleDays[0]);
                                    var endDate = new Date(detail.ScheduleDays[detail.ScheduleDays.length - 1]);
                                    var isLast = false;
                                    for (let day = startDateCal; day <= endDate; day.setDate(day.getDate() + 1)) {
                                        var tempDate = moment(day).format("DD.MM.YYYY");
                                        var getHoliday = this.holidayList.find(o => moment(o.date).format("DD.MM.YYYY") == tempDate);
                                        if (day.getDay() == 0 || day.getDay() == 6 || getHoliday) {
                                            endDate = new Date(endDate.setDate(endDate.getDate() + 1));
                                            if (this.createEventObj.weekendId == 1)
                                                continue;
                                            else if (this.createEventObj.weekendId == 2 && !getHoliday) {
                                                if (this.isCalculatedDays && tempDate == moment(endDate).format("DD.MM.YYYY"))
                                                    isLast = true;
                                                this.pushDayList1(moment(day).format("YYYY-MM-DD"), true, isLast, coworker)
                                            }
                                        }
                                        else if (!getHoliday || getHoliday.length == 0) {
                                            var data = this.tempCoworkerListForLeave[i].holidayList.find(o => moment(o.date).format("DD.MM.YYYY") == tempDate);
                                            var excudeDate = this.workHour.dayList.filter(x => x.isChecked == false && moment(x.date).format("DD.MM.YYYY") == tempDate);
                                            if (data || excudeDate.length > 0) {
                                                endDate = new Date(endDate.setDate(endDate.getDate() + 1));
                                            }
                                            else {
                                                if (this.workHour.dayList.length != this.tempDayList.length) {
                                                    if (this.isCalculatedDays && tempDate == moment(endDate).format("DD.MM.YYYY"))
                                                        isLast = true;
                                                    this.pushDayList1(moment(day).format("YYYY-MM-DD"), true, isLast, coworker)
                                                }
                                                else
                                                    break;
                                            }
                                        }
                                    }
                                }
                                coworker.ScheduleDays = this.tempDayList;
                                coworker.isLeaveExtended = true;
                                coworker.totalWorkHourOfCoworker = coworker.ScheduleDays.filter(s => s.isChecked == true).reduce((sum, current) => sum + parseFloat(current.hours), 0)
                            }
                        }
                    }
                    else
                        this.reCoworkerScheduleDays(coworker)
                }
            })
        }
    }

    reCoworkerScheduleDays(coworker) {
        this.workHour.dayList.filter((x, index) => {
            this.scheduleDay = {};
            this.scheduleDay.date = x.date;
            this.scheduleDay.isChecked = x.isChecked;
            //var remaingHours = ((this.Requisition.estimatedTime?this.Requisition.estimatedTime:0)-this.Requisition.plannedWorkHour);
            var remaingHours = (this.createEventObj.projectBasedPlanning ? ((this.projectHoursDetail.estimatedTime ? this.projectHoursDetail.estimatedTime : 0) - this.projectHoursDetail.plannedWorkHour) : ((this.Requisition.estimatedTime ? this.Requisition.estimatedTime : 0) - this.Requisition.plannedWorkHour));
            if (this.createEventObj.shift == 4 && this.createEventObj.visitdays)
                this.addManualTimerOnLastDay(coworker);
            else if (this.isCalculatedDays && index == this.workHour.dayList.length - 1 && remaingHours > 0)
                this.addManualTimerOnLastDay(coworker);
            else {
                if (new Date(moment(x.date).format("YYYY-MM-DD")).getDay() == 5) {
                    this.scheduleDay.from = "07:00";
                    this.scheduleDay.to = "14:30";
                    this.scheduleDay.tid = 7.5;
                    this.scheduleDay.pauseTimer = 0.5;
                    this.scheduleDay.hours = "7";
                    this.scheduleDay.shiftType = 2;
                    this.scheduleDay.shiftTypeName = 'selectedShiftOption.value1'
                }
                else {
                    this.scheduleDay.from = "07:00";
                    this.scheduleDay.to = "15:00";
                    this.scheduleDay.tid = 8.00;
                    this.scheduleDay.pauseTimer = 0.5;
                    this.scheduleDay.hours = "07.5";
                    this.scheduleDay.shiftType = 3;
                    this.scheduleDay.shiftTypeName = 'selectedShiftOption.value2'
                }
            }
            coworker.ScheduleDays.push(this.scheduleDay);
        })
        coworker.totalWorkHourOfCoworker = coworker.ScheduleDays.filter(s => s.isChecked == true).reduce((sum, current) => sum + parseFloat(current.hours), 0)
    }
    OpentoggleForCoworker(flag) {
        this.IsExpandCoworkeDetails = !flag;
    }

    expandPanelForCoworker(matExpansionPanel: MatExpansionPanel, event: Event): void {
        event.stopPropagation();
        if (!this.IsExpandOtherDetails) {
            if (!this._isExpansionIndicator(event.target)) {
                matExpansionPanel.close();
            }
        }
    }

    checkBreaktime(scheduleDay) {
        this.isBreakTimeForManualTimer = false;
        if (scheduleDay.fromEndHour <= '11' && scheduleDay.fromEndMinute <= '30')
            this.isBreakTimeForManualTimer = false;
        else if (scheduleDay.fromEndHour == '11' && scheduleDay.fromEndMinute < '30')
            this.isBreakTimeForManualTimer = true;
        else if (scheduleDay.fromStartHour >= '12' && scheduleDay.fromStartMinute >= '30')
            this.isBreakTimeForManualTimer = false;
        else if (scheduleDay.fromStartHour == '12' && scheduleDay.fromStartMinute < '30')
            this.isBreakTimeForManualTimer = true;
        else if (scheduleDay.fromStartHour >= '12')
            this.isBreakTimeForManualTimer = false;
        else
            this.isBreakTime = true;
    }

    addManualTimerOnLastDay(coworker) {
        var remaingHours;
        var tempTotalWorkHour = coworker.ScheduleDays.filter(s => s.isChecked == true).reduce((sum, current) => sum + parseFloat(current.hours), 0)
        if (this.createEventObj.shift == 4 && this.createEventObj.visitdays) {
            //remaingHours = ((this.Requisition.estimatedTime?this.Requisition.estimatedTime:0)-this.Requisition.plannedWorkHour) / this.createEventObj.visitdays / this.selectedCoWorkerList.length;
            remaingHours = (this.createEventObj.projectBasedPlanning ? ((this.projectHoursDetail.estimatedTime ? this.projectHoursDetail.estimatedTime : 0) - this.projectHoursDetail.plannedWorkHour) : ((this.Requisition.estimatedTime ? this.Requisition.estimatedTime : 0) - this.Requisition.plannedWorkHour)) / this.createEventObj.visitdays / this.selectedCoWorkerList.length;
        }
        else {
            //remaingHours = (((this.Requisition.estimatedTime?this.Requisition.estimatedTime:0)-this.Requisition.plannedWorkHour) / this.selectedCoWorkerList.length) - tempTotalWorkHour;
            remaingHours = ((this.createEventObj.projectBasedPlanning ? ((this.projectHoursDetail.estimatedTime ? this.projectHoursDetail.estimatedTime : 0) - this.projectHoursDetail.plannedWorkHour) : ((this.Requisition.estimatedTime ? this.Requisition.estimatedTime : 0) - this.Requisition.plannedWorkHour)) / this.selectedCoWorkerList.length) - tempTotalWorkHour;
        }
        var tempHH = remaingHours.toString().split(".")[0];
        var tempmm = remaingHours.toFixed(2).toString().split(".")[1];
        if (parseFloat(tempmm) > 0 && parseFloat(tempmm) <= 25)
            this.scheduleDay.to = moment(7 + parseInt(tempHH), "HH").format("HH") + ":" + "15"
        else if (parseFloat(tempmm) >= 26 && parseFloat(tempmm) <= 50)
            this.scheduleDay.to = moment(7 + parseInt(tempHH), "HH").format("HH") + ":" + "30"
        else if (parseFloat(tempmm) >= 51 && parseFloat(tempmm) <= 75)
            this.scheduleDay.to = moment(7 + parseInt(tempHH), "HH").format("HH") + ":" + "45"
        else
            this.scheduleDay.to = moment(7 + Math.ceil(remaingHours), "HH").format("HH:mm");
        this.scheduleDay.from = "07:00";
        this.scheduleDay.fromStartHour = moment(this.scheduleDay.from, "HH:mm").format("HH");
        this.scheduleDay.fromStartMinute = moment(this.scheduleDay.from, "HH:mm").format("mm");
        this.scheduleDay.fromEndHour = moment(this.scheduleDay.to, "HH:mm").format("HH");
        this.scheduleDay.fromEndMinute = moment(this.scheduleDay.to, "HH:mm").format("mm");
        var time = moment.utc(moment(this.scheduleDay.to, "HH:mm").diff(moment(this.scheduleDay.from, "HH:mm"))).format("HH:mm")
        var tidTime = moment(time, "HH:mm").format("mm");
        var tidHours = moment(time, "HH:mm").format("HH");
        if (tidTime != "00")
            time = tidHours + ":" + (Number(this.getMinute(tidTime))).toString();
        this.scheduleDay.tid = Number(time.replace(':', '.'));
        this.checkBreaktime(this.scheduleDay);
        if (this.isBreakTimeForManualTimer)
            this.scheduleDay.pauseTimer = 0.5;
        else
            this.scheduleDay.pauseTimer = 0;
        this.scheduleDay.hours = (Number(time.replace(':', '.')) - this.scheduleDay.pauseTimer).toString();
        this.scheduleDay.shiftType = 4;
        this.scheduleDay.shiftTypeName = this.scheduleDay.from + ' til ' + this.scheduleDay.to;
    }
    addManualTimerOnLastDayForExtended(coworker, scheduleDay) {
        var remaingHours;
        var tempTotalWorkHour = this.tempDayList.filter(s => s.isChecked == true).reduce((sum, current) => sum + parseFloat(current.hours), 0)
        if (this.createEventObj.shift == 4 && this.createEventObj.visitdays)
            //remaingHours = ((this.Requisition.estimatedTime?this.Requisition.estimatedTime:0)-this.Requisition.plannedWorkHour)/this.createEventObj.visitdays / this.selectedCoWorkerList.length;
            remaingHours = (this.createEventObj.projectBasedPlanning ? ((this.projectHoursDetail.estimatedTime ? this.projectHoursDetail.estimatedTime : 0) - this.projectHoursDetail.plannedWorkHour) : ((this.Requisition.estimatedTime ? this.Requisition.estimatedTime : 0) - this.Requisition.plannedWorkHour)) / this.createEventObj.visitdays / this.selectedCoWorkerList.length;
        else
            //remaingHours = (((this.Requisition.estimatedTime?this.Requisition.estimatedTime:0)-this.Requisition.plannedWorkHour) / this.selectedCoWorkerList.length) - tempTotalWorkHour;
            remaingHours = ((this.createEventObj.projectBasedPlanning ? ((this.projectHoursDetail.estimatedTime ? this.projectHoursDetail.estimatedTime : 0) - this.projectHoursDetail.plannedWorkHour) : ((this.Requisition.estimatedTime ? this.Requisition.estimatedTime : 0) - this.Requisition.plannedWorkHour)) / this.selectedCoWorkerList.length) - tempTotalWorkHour;
        var tempHH = remaingHours.toString().split(".")[0];
        var tempmm = remaingHours.toFixed(2).toString().split(".")[1];
        if (parseFloat(tempmm) > 0 && parseFloat(tempmm) <= 25)
            scheduleDay.to = moment(7 + parseInt(tempHH), "HH").format("HH") + ":" + "15"
        else if (parseFloat(tempmm) >= 26 && parseFloat(tempmm) <= 50)
            scheduleDay.to = moment(7 + parseInt(tempHH), "HH").format("HH") + ":" + "30"
        else if (parseFloat(tempmm) >= 51 && parseFloat(tempmm) <= 75)
            scheduleDay.to = moment(7 + parseInt(tempHH), "HH").format("HH") + ":" + "45"
        else
            scheduleDay.to = moment(7 + Math.ceil(remaingHours), "HH").format("HH:mm");
        scheduleDay.from = "07:00";
        scheduleDay.fromStartHour = moment(scheduleDay.from, "HH:mm").format("HH");
        scheduleDay.fromStartMinute = moment(scheduleDay.from, "HH:mm").format("mm");
        scheduleDay.fromEndHour = moment(scheduleDay.to, "HH:mm").format("HH");
        scheduleDay.fromEndMinute = moment(scheduleDay.to, "HH:mm").format("mm");
        var time = moment.utc(moment(scheduleDay.to, "HH:mm").diff(moment(scheduleDay.from, "HH:mm"))).format("HH:mm")
        var tidTime = moment(time, "HH:mm").format("mm");
        var tidHours = moment(time, "HH:mm").format("HH");
        if (tidTime != "00")
            time = tidHours + ":" + (Number(this.getMinute(tidTime))).toString();
        scheduleDay.tid = Number(time.replace(':', '.'));
        this.checkBreaktime(this.scheduleDay);
        if (this.isBreakTimeForManualTimer)
            scheduleDay.pauseTimer = 0.5;
        else
            scheduleDay.pauseTimer = 0;
        scheduleDay.hours = (Number(time.replace(':', '.')) - scheduleDay.pauseTimer).toString();
        scheduleDay.shiftType = 4;
        scheduleDay.shiftTypeName = scheduleDay.from + ' til ' + scheduleDay.to;
    }

    changePlaningType() {
        this.calculateEndDateWithRemainingHour();
        var reqID = this.Requisition != null ? this.Requisition.id : null;
        this.getCowrkerListAndPlanningDate(this.projectInfo.sagId, reqID);
    }

    getProjectTimerFromRequisition() {
        if (this.RequisitionList.length > 0) {
            this.projectHoursDetail.estimatedTime = this.RequisitionList[0].projectEstimatedTime ? this.RequisitionList[0].projectEstimatedTime : 0;
            this.projectHoursDetail.totalWorkingTime = this.RequisitionList[0].projectTotalWorkingTime ? this.RequisitionList[0].projectTotalWorkingTime : 0;
            this.projectHoursDetail.plannedWorkHour = this.RequisitionList[0].projectPlannedWorkHour ? this.RequisitionList[0].projectPlannedWorkHour : 0;
            this.calculateEndDateWithRemainingHour();
        }
    }

    storeFileFolderPathData() {
        var rootPath = this.dropboxFilesService.getSettingMasterValueDocumentPath();
        var paths = "";
        if (rootPath.DocumentDriveType == DriveModuleEnum.Onedrive) {
            paths = rootPath.Onedrive.Path;
        } else {
            paths = rootPath.Dropbox.Path;
        }
        this.fileFolderPathData = paths.split("/").map((path, i) => ({
            name: path,
            isDisplay: false
        }));
        this.fileFolderPathData.push(
            { name: this.dropBoxRootFolder, isDisplay: false },
            { name: 'Project', isDisplay: false },
            { name: this.createEventObj.projectNumber, isDisplay: true }
        );
    }

    IsFileExist(flag) {
        //this.isFile = flag;
    }

    getCoworkersWithHoliday() {
        let model = new RequestCoWorker();
        model.IsDeleted = false;
        model.Limit = 400;
        model.OrderBy = "Name";
        this.coWorkersService.GetCoworkersWithHoliday<ResponseBase>(model)
            .subscribe((response: ResponseBase) => {
                if (response) {
                    this.coWorkerList = response.result.filter(x => x.doNotShowOnPlanReminderProj == false);
                    this.searchCoWorkerList = this.coWorkerList;
                }
            },
                error => (err) => {
                },
                () => {
                });
    }

    ChangePrice() {
        this.Requisition.includePrice = Number((this.Requisition.excludePrice + ((Number(this.Requisition.excludePrice) * 25) / 100)).toFixed(2));//.toLocaleString('en-DA', { style: 'currency', currency: 'DKK' });
        this.Requisition.vatPrice = Number((Number(this.Requisition.excludePrice) * 25) / 100);//.toLocaleString('en-DA', { style: 'currency', currency: 'DKK' });
        this.getTotalEtstimeteOrPrice();
        if (this.Requisition.excludePrice > 0) {
            this.Requisition.suggestedHours = Number((this.Requisition.excludePrice / this.Requisition.coworkerWorkingRate).toFixed(2)); //Number(((this.Requisition.excludePrice - this.materialTotalPrice) / 517.50).toFixed(2));
            this.Requisition.estimatedTime = this.Requisition.suggestedHours;
        }
        else if (this.Requisition.estimatedPrice > 0) {
            this.Requisition.suggestedHours = Number((this.Requisition.estimatedPrice / this.Requisition.coworkerWorkingRate).toFixed(2));  //Number(((this.Requisition.estimatedPrice - this.materialTotalPrice) / 517.50).toFixed(2));
            this.Requisition.estimatedTime = this.Requisition.suggestedHours;
        }
        else if (!this.Requisition.estimatedPrice || !this.Requisition.excludePrice) {
            this.Requisition.suggestedHours = 0;
            this.Requisition.estimatedTime = 0;
        }
        this.setRequisitionNoOfDays();
    }

    ChangeEstimatePrice() {
        this.Requisition.includeEstimatePrice = Number((this.Requisition.estimatedPrice + ((Number(this.Requisition.estimatedPrice) * 25) / 100)));//.toLocaleString('en-DA', { style: 'currency', currency: 'DKK' });
        this.Requisition.vatEstimatePrice = Number((Number(this.Requisition.estimatedPrice) * 25) / 100);//.toLocaleString('en-DA', { style: 'currency', currency: 'DKK' });
        this.getTotalEtstimeteOrPrice();
        if (this.Requisition.estimatedPrice > 0) {
            this.Requisition.suggestedHours = Number((this.Requisition.estimatedPrice / this.Requisition.coworkerWorkingRate).toFixed(2)); //Number(((this.Requisition.estimatedPrice - this.materialTotalPrice) / 517.50).toFixed(2));
            this.Requisition.estimatedTime = this.Requisition.suggestedHours;
        }
        else if (this.Requisition.excludePrice > 0) {
            this.Requisition.suggestedHours = Number((this.Requisition.excludePrice / this.Requisition.coworkerWorkingRate).toFixed(2)); //Number(((this.Requisition.excludePrice - this.materialTotalPrice) / 517.50).toFixed(2));
            this.Requisition.estimatedTime = this.Requisition.suggestedHours;
        }
        else if (!this.Requisition.estimatedPrice || !this.Requisition.excludePrice) {
            this.Requisition.suggestedHours = 0;
            this.Requisition.estimatedTime = 0;
        }
        this.setRequisitionNoOfDays();
    }

    setRequisitionNoOfDays() {
        this.Requisition.hoursLeft = 0;
        if (this.Requisition.estimatedTime) {
            //this.Requisition.hoursLeft = this.Requisition.estimatedTime - this.Requisition.totalWorkingTime;
            this.Requisition.hoursLeft = parseFloat(((this.Requisition.estimatedTime - this.Requisition.totalWorkingTime)).toFixed(2));
            this.Requisition.numberOfDays = parseFloat(((this.Requisition.hoursLeft) / 7.5).toFixed(2));
        }
        else
            this.Requisition.numberOfDays = 0;

    }

}
