export const locale = {
  lang: 'en',
  data: {
    'FIELDS': {
      'Name': 'Room',
      'Width': 'Width',
      'Length': 'Length',
      'CeilingHeight': 'Ceiling Height',
      'ADDNEWROOM': 'Add 5 Rooms',
      'ADDNEWSINGLEROOM': 'Add 1 Room',
      'EnterLength': 'Enter Length',
      'EnterWidth': 'Enter Width',
      'EnterName': 'Enter Name',
      'EnterCeilingHeight': 'Enter Ceiling Height',
      'M2ForWall': 'M2 Wall',
      'M2ForCeiling': 'M2 Ceiling',
      'Total': 'Total',
      'Woodwork': 'Woodwork',
      'Qty': 'Supplement / Deduction Kr.',
      'QtyM2': 'Supplement / Deduction m2',
      'Baseline': 'Baseline',
      'Height': 'Height',
      'Area': 'Area',
      'ExtraM2QtyWall': 'Supplement / Deduction m2 Wall',
      'ExtraM2QtyCeiling': 'Supplement / Deduction m2 Ceiling',
      'ExtraM2QtyFloor': 'Supplement / Deduction LB Floor',
      'ExtraM2PriceWall': 'Supplement / Deduction Kr. Wall',
      'ExtraM2PriceCeiling': 'Supplement / Deduction Kr. Ceiling',
      'ExtraM2PriceFloor': 'Supplement / Deduction Kr. Woodwork',
      'ColorCode': 'ColorCode',
    },
    'MESSAGES': {
      'NoList': 'No Room Created.',
      'DuplicateRoom': 'You can not insert same room name.'
    },
    'BUTTONS': {
      'SAVE': 'Save',
      'BACK': 'Gå tilbage',
      'ChangeHeight': 'Ceiling height',
      'GetArea': 'Get area'
    },
    'SupplementDeductionTypeSelect': {
      'value0': 'All',
      'value1': 'M2 Wall',
      'value2': 'M2 Ceiling',
      'value3': 'Woodwork'
    },
    'TOOTIPORTITLE': {
      'AddSingleRoom': 'Add Single Room',
      'AddRoom': 'Add Rooms',
      'Edit': 'Edit',
      'Delete': 'Delete',
      'Expand':'Expand',
      'Collapse':'Collapse',
    },
  }
};
