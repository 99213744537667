import { Project } from "./Project";
import { CoWorker } from "./CoWorker";

export class WorkHour {
    public id?: string;
    public date?: any;
    public from?: string;
    public to?: string;
    public hours?: string;
    public text?: string;
    public workerId?: string;
    public projectId?: string;
    public project?: Project;
    public worker?: CoWorker;
    public sagOpgaveID?: string;
    public status?: number;
    public fromStartHour?: string = "07";
    public fromStartMinute?: string = "00";
    public fromEndHour?: string = "07";
    public fromEndMinute?: string = "00";
    public isWorking: boolean = true;
    public shiftType: number;
    //2 table change
    public Hours?: string;

    public Tid?: number;
    public pauseTimer?: number;
    public SagNummer?: number;
    public SagOpgaveNummer?: number;
    public SagStatus: number;
    public tid?: number;
    public days?: number;
    public newAddedCoworkerIds: string;
    public leaveStatus: number;
    public comment: string;
    public dayList: any = [];
    public nonWorkingDayList: any = [];
    public otherInfo: any;
    public CoworkerDetailsId: CoworkerDays[] = [];
    public selectedDate?: any;
    public inquiryId?: any;
    public customerId?: any;
    public startDate: string;
    public endDate: string;
    public projectNumber: number;
    public message: any;
    public sendType?: number;
    public isNotifyToCustomer: boolean = false;
    public weekendId?: number;
    public CoworkerScheduleDayList: CoworkerScheduleDayList[] = [];
    public paymentOption: any;
    public shiftTypeName: string;
    public isChecked: boolean = false;
    public isPastDate: boolean = false;
    public requisitionTimerID: string;
    public offerID: string;
    public offerTypeID: string[];
    public extraInfo : string;
}
export class CoworkerDays {
    public CoworkerId: string;
    public ScheduleDays: any = [];
}
export class WorkhoursDay {
    public date: string;
    public isChecked: boolean = false;
}
export class PlanningDefaultCoWorker {
    public id: string = '';
    public coWorkerID: string = '';
    public selectedID: string = '';
    public coWorkerIds: string = '';
}
export class CoworkerWithScheduleDetails extends CoWorker {
    public CoworkerId: string;
    public ScheduleDays: ScheduleDays[] = [];
    public totalWorkHourOfCoworker: number;
    public isLeaveExtended: boolean = false;
}
export class ScheduleDays {
    public date?: any;
    public from?: string;
    public to?: string;
    public hours?: string;
    public tid?: number;
    public pauseTimer?: number;
    public shiftType: number;
    public isChecked: boolean = false;
    public shiftTypeName: string;
}

export class CoworkerScheduleDayList extends CoWorker {
    public workerId: string;
    public date?: any;
    public from?: string;
    public to?: string;
    public hours?: string;
    public tid?: number;
    public pauseTimer?: number;
    public shiftType: number;
    public isChecked: boolean = false;
    public shiftTypeName: string;
    public otherInfo: string;
    public text: string;
    public isWorking: boolean = true;
    public requisitionId: string;
    public inquiryId: string;
    public projectId: string;
    public leaveStatus: number = 0;
    public projectNumber: string;
    public startDate: string;
    public endDate: string;
    public customerId?: any;
    public message: any;
    public sendType?: number;
    public newAddedCoworkerIds: string;
    public sagOpgaveId: string;
    public projectBasedPlanning: boolean = false;
}
export class WorkingHourOtherInfo {
    public StartDate?: any;
    public EndDate?: any;
    public SagOpgaveID: string;
    public InquiryID: string;
    public SagID: string;
    public BunchId: string;
    public IsNotifyToCustomer: boolean = false;
    public WeekendId?: number;
    public PlanningDates: string;
}

export class PlanningDatesArray {
    public date: string = "";
    public isChecked: boolean = false;
    public isPastDate: boolean = false;
}

export class LeaveApplyOnPlanning {
    public workerID: string[];
    public leaveDates: Date[] = []
    public startDate = new Date();
    public endDate = new Date();
    public startTime: string;
    public endTime: string;
    public pauseTime: number;
    public totalTime: number;
    public totalWorkingTime: number;
    public shiftType: number;
    public leaveType: number;
    public leaveStatus: number;
    public text: string = "";

    public startWeekNumber: any;
    public paymentOption: number;
    public coworkerId: string;
    public weekendId: number;
}