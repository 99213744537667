export const locale = {
    lang: 'en',
    data: {
        'Headers': {
            'AllSchedule': 'All Schedule',
            'OneDay': 'One Day',
            'Disconnect': 'Disconnect',
            "MoveScheduleOption" : "Move / copy one day or all days"
        }
    }
};
