export const locale = {
    lang: 'ds',
    data: {
        'Tabs': {
            'PROJECT': 'Projekter',
            'PROJECTOFFER': 'Tilbud',
            'QUESTIONS': 'Spørgsmål'
        },
        'Message': {

        },
    }
};
