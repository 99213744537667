import { Component, OnInit, ViewChild } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { MatDialog , MatOption} from '@angular/material';
import { AuthService } from 'app/core/services/auth.service';
import { SettingsService } from '../../settings.service';
import { ResponseBase } from "app/main/model/ResponseBase";
import { ProjectService } from 'app/main/pages/project/project.service';
import * as moment from 'moment';
import { WorkingHourDetails } from "app/main/model/Project";
import { NumberOfEmployee } from 'app/main/model/CoWorker';
import { ProjectStatus, PurchaseStatus } from "app/constant/common.constant";
import { ProjectIndoorOutDoorStatus } from "app/main/model/ProjectStatus";
import { CoWorkersService } from "app/main/pages/coWorker/coWorkers.service";
var momentBusiness = require('moment-business-days')

@Component({
    selector: "vibe-planning-working-hours-detail",
    templateUrl: "./planning-working-hours-detail.component.html",
    styleUrls: ["./planning-working-hours-detail.component.scss"]
})

export class PlanningWorkingHoursDetailComponent implements OnInit {
    showSpinner: boolean = false;
    //roleName: string;
    workingHourDetails = new WorkingHourDetails();
    purchaseStatus = PurchaseStatus;
    projectStatus = ProjectStatus;
    selectIndoorOutDoorStatus = ProjectIndoorOutDoorStatus;
    workingHourDetailsForPlanning = new WorkingHourDetails();
    currentDate: any;
    hourlyStatus: any[] = [];
    @ViewChild('allStatusSelectedForWorkHourselected', { static: false }) private allStatusSelectedForWorkHourselected: MatOption;
    @ViewChild('allStatusSelected', { static: false }) private allStatusSelected: MatOption;
    numberOfEmployee: NumberOfEmployee[] = [];
    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private authService: AuthService,
        private coWorkersService: CoWorkersService,
        private projectservice: ProjectService,
        private settingsService: SettingsService,
        private dialog: MatDialog) {
        this.translationLoaderService.loadTranslations(danish, english);
        this.numberOfEmployee = this.coWorkersService.generateEmployeeArray(25);
    }

    ngOnInit() {
        //this.roleName = this.authService.getUserRole();
        this.currentDate = moment(new Date()).format("YYYY-MM-DD");
        //if (this.roleName == "Admin") {
            this.getWorkingHourDetailsForPlanning(this.workingHourDetails.noOfEmployee);
            this.hourlyStatus = [];
            this.hourlyStatus.push(0);
            for (let i = 0; i < this.projectStatus.length; i++) {
                this.hourlyStatus.push(this.projectStatus[i].id);
            }
            this.getWorkingHourDetails();
        //}
    }
    getWorkingHourDetailsForPlanning(NoOfEmployee) {
            this.projectservice.GetProjectHoursDetailForPlanning<ResponseBase>(NoOfEmployee)
                .subscribe((response: ResponseBase) => {
                    if (response) {
                        this.workingHourDetailsForPlanning = response.result;
                        if (this.workingHourDetailsForPlanning && this.workingHourDetailsForPlanning.totalProjectDaysLeft) {
                            var days = Math.floor(this.workingHourDetailsForPlanning.totalProjectDaysLeft).toString().replace('-', '');
                            var endDate = momentBusiness(this.currentDate, 'YYYY-MM-DD').businessAdd(days)._d;
                            this.workingHourDetailsForPlanning.endDate = moment(endDate).format("DD.MM.YYYY")
                        }
                    }
                },
                    error => (err) => {
                    },
                    () => {
                    });
    }

    getWorkingHourDetails() {
        const statusStr = this.hourlyStatus.join().toString();
        this.projectservice.GetProjectsHoursDetailsWithStatusBase<ResponseBase>(this.workingHourDetails.noOfEmployee, statusStr,'0,1,2,3,4,5,6', this.workingHourDetails.averagePrice)
            .subscribe((response: ResponseBase) => {
                if (response) {
                    this.workingHourDetails = response.result;
                    if (this.workingHourDetails && this.workingHourDetails.totalProjectDaysLeft) {
                        var days = Math.floor(this.workingHourDetails.totalProjectDaysLeft).toString().replace('-', '');
                        var endDate = momentBusiness(this.currentDate, 'YYYY-MM-DD').businessAdd(days)._d;
                        this.workingHourDetails.endDate = moment(endDate).format("DD.MM.YYYY")
                    }
                }
            },
                error => (err) => {
                },
                () => {
                });
        
    }

    selectAllStatusForWorkHourselected() {
        if (this.allStatusSelectedForWorkHourselected.selected) {
            this.hourlyStatus = [];
            this.hourlyStatus.push(0);
            for (let i = 0; i < this.projectStatus.length; i++) {
                this.hourlyStatus.push(this.projectStatus[i].id);
            }
        }
        else {
            this.hourlyStatus = [];
        }
        this.getWorkingHourDetails();
    }

    selectStatusForWorkHourselected() {
        this.hourlyStatus = this.hourlyStatus.filter(o => o != 0);
        this.getWorkingHourDetails();
    }
}