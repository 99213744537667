import { Component, OnInit, Input, OnDestroy, NgZone } from "@angular/core";
import { DatePipe } from '@angular/common';
import { MatDialog, MatExpansionPanel } from '@angular/material';


import { ResponseBase } from 'app/main/model/ResponseBase';
import { RequestReminder } from 'app/main/model/RequestBase';

//Services
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { ReminderService } from 'app/main/pages/reminder/reminder.service';
import { AuthService } from 'app/core/services/auth.service';
import { environment } from 'environments/environment';

import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { Reminder } from 'app/main/model/Reminder';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { VibeEditReminderComponent } from 'app/main/pages/reminder/component/edit/edit-reminder.component';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { SignalRService } from 'app/core/services/signalR.service';
import { VibeImgShowcaseComponent } from "../../vibe-img-showcase/vibe-img-showcase.component";


@Component({
    selector: "send-by-me",
    templateUrl: "./send-by-me.html",
    styleUrls: ["./send-by-me.scss"]
})

export class SendByMeComponent implements OnInit, OnDestroy {
    @Input() showPagination: any;
    @Input() IsExpandAcc: boolean = false;
    showSpinner: boolean = false;
    selectedStartDateForFilter: any = moment(new Date()).format('YYYY-MM-DD');
    selectedEndDateForFilter: any = moment(this.selectedStartDateForFilter, "YYYY-MM-DD").add(5, 'days').format('YYYY-MM-DD');
    selectedSearchCoworkerID: string = "";
    text: any = '';
    orderBy: string = "DATE DESC";
    Reminders: Reminder[] = [];
    displayPagination: boolean;
    host: string = '';
    DefaltImage: any;
    allReminderDone: boolean = false;
    notDoneReminderCount: number = 0;
    DashboardReminders: Reminder[] = [];
    getAllSendByMeReminder: boolean = false;
    sendByMePgIndex: any;
    sendByMePgLimit: any;
    sendByMeReminderList: Reminder[] = [];
    IsExpand: boolean = false;
    pagination: any;
    displayDeleteAll: boolean = false;
    private reminderFilterObs:any;
    private UpdateReminderObs:any;

    constructor(private translateService: FuseTranslationLoaderService,
        private reminderService: ReminderService,
        private datePipe: DatePipe,
        public dialog: MatDialog,
        public _router: Router,
        private authService: AuthService,
        private translate: TranslateService,
        private notificationsService: NotificationsService,
        private signalRService: SignalRService,
        private _ngZone: NgZone) {
        this.translateService.loadTranslations(danish, english);
    }

    ngOnInit() {
        this.host = environment.url;
        this.DefaltImage = 'Upload/coworker.png'
        this.selectedSearchCoworkerID = this.authService.getCoWorkerId();
        //this.sendByMeReminder(new RequestReminder());
        if (this.IsExpandAcc) {
            this.IsExpand = true;
        }
        this.subscribeToEvents();
    }

    sendByMeReminder(model: RequestReminder) {
        this.displayDeleteAll = false;
        this.showSpinner = true;
        //var model = new RequestReminder();
        model.StartDate = model.StartDate ? model.StartDate : this.selectedStartDateForFilter;
        model.EndDate = model.EndDate ? model.EndDate : this.selectedEndDateForFilter;
        model.Text = model.Text ? model.Text : this.text;
        model.CreatedBy = model.CreatedBy ? model.CreatedBy : this.selectedSearchCoworkerID;
        model.IsDeleted = false;
        model.OrderBy = model.OrderBy ? model.OrderBy : this.orderBy;
        model.getAll = this.getAllSendByMeReminder;
        model.Index = this.sendByMePgIndex ? this.sendByMePgIndex : model.Index;
        model.Limit = this.sendByMePgLimit ? this.sendByMePgLimit : model.Limit;
        this.reminderService.GetReminderListWithMutipleFiles<ResponseBase>(model)
            .subscribe({
                next: (response: ResponseBase) => {
                    if (response != null) {
                        this.displayDeleteAll = true;
                        this.Reminders = response.result;
                        this.pagination = response.pagination;
                        this.sendByMeReminderList = response.result;
                        this.displayPagination = true;
                    }
                    else {
                        this.Reminders = [];
                        this.displayPagination = false;
                    }
                    this.showSpinner = false;
                    this.sendByMePgIndex = "";
                    this.sendByMePgLimit = "";
                },
                error: err => {
                    this.Reminders = [];
                    this.showSpinner = false;
                    this.displayPagination = false;
                },
                complete: () => {
                }
            });
    }

    PaginatonEvent(event) {
        var request = new RequestReminder();
        request.Index = event.pageIndex;
        request.Limit = event.pageSize;
        request.Text = this.text.trim();
        this.sendByMeReminder(request);
    }

    showAll(flag) {
        this.getAllSendByMeReminder = flag ? flag : false;
        var request = new RequestReminder();
        request.getAll = this.getAllSendByMeReminder;
        this.sendByMeReminder(request);
    }

    //Edit reminder
    EditReminder(value) {
        var data = value;
        this.sendByMePgIndex = this.pagination.index;
        this.sendByMePgLimit = this.pagination.limit;
        let objData = Object.assign({}, data)
        if (data.reminderDate) {
            objData.reminderTime = moment.utc(objData.reminderDate).format("HH:mm") == "00:00" ? "" : moment.utc(objData.reminderDate).format("HH:mm");
            objData.reminderDate = moment.utc(objData.reminderDate).format("YYYY-MM-DD");
        }
        const dialogRef = this.dialog.open(VibeEditReminderComponent, {
            // height: '400px',
            width: '60%',
            data: objData,
            autoFocus: false
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                var reminder = this.Reminders.find(r => r.id == result.id);
                if (reminder) {
                    var objUpdate = new Reminder();
                    objUpdate.coWorkerId = result.coWorkerId;
                    objUpdate.reminderDate = result.reminderDate;
                    objUpdate.text = result.text;
                    objUpdate.replay = result.replay;
                    objUpdate.reminderStatus = result.reminderStatus;
                    objUpdate.sendNotification="SendToMe";
                    this.updateReminder(reminder.id, objUpdate);
                    reminder.text = result.text;
                }
            }
        });
    }

    updateReminder(id, reminder) {
        this.showSpinner = true;
        reminder.reminderDate = moment.utc(reminder.reminderDate).format("YYYY-MM-DD HH:mm");

        if(!reminder.reminderDate)
            reminder.reminderDate="";

        const formData = new FormData();
        // if (selectedFiles && selectedFiles.length) {            
        //     for (var i = 0; i < selectedFiles.length; i++) {
        //         formData.append("file[]", selectedFiles[i]);
        //     }
        // }
        for ( var key in reminder ) {
            formData.append(key, reminder[key]);
        }

        this.reminderService.UpdateReminderWithFiles<ResponseBase>(id, formData)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.showSpinner = false;
                    this.authService.UpdateReceiveByMeReminderList(true);
                    this.translate.get("MESSAGES.Updated").subscribe({
                        next: (res) => {
                            this.notificationsService.success(res);
                        },
                        error: err => {
                        }
                    });
                    //this.sendByMeReminder(new RequestReminder());
                    this.sendMessage(id);
                },
                error: err => {
                    this.showSpinner = false;
                },
                complete: () => {
                    this.showSpinner = false;
                }
            });
    }
    //End

    //Delete reminder
    delete(rem) {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: '200px',
            data: { Header: 'Headers.DeleteSingleReminder' }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.deleteReminder(rem);
            }
        });
    }

    deleteReminder(objReminder) {
        this.showSpinner = true;
        this.reminderService.Delete<ResponseBase>(objReminder.id)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.Reminders.splice(this.Reminders.findIndex(r => r.id == objReminder.id), 1);
                    this.showSpinner = false;
                    //this.sendByMeReminder(new RequestReminder());
                    this.authService.UpdateReceiveByMeReminderList(true);
                    this.GetNotDoneReminderCounts();
                    var requestReminder = new RequestReminder();
                    requestReminder.StartDate = new Date().toString();
                    requestReminder.EndDate = new Date().toString();
                    this.getDeshboardReminder(requestReminder);
                    //this.sendMessage(objReminder.id);

                },
                error: err => {
                    this.showSpinner = false;
                },
                complete: () => {
                    this.showSpinner = false;
                }
            });
    }

    DeleteAll() {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: '200px',
            data: { Header: 'Headers.DeleteAllReminder' }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.deleteAllSentReminder(new RequestReminder());
            }
        });
    }

    deleteAllSentReminder(model: RequestReminder) {
        this.showSpinner = true;
        model.StartDate = this.datePipe.transform(this.selectedStartDateForFilter, "yyyy-MM-dd");
        model.EndDate = this.datePipe.transform(this.selectedEndDateForFilter, "yyyy-MM-dd");
        model.CreatedBy = this.authService.getCoWorkerId();
        model.IsDeleted = false;
        model.OrderBy = this.orderBy;
        this.reminderService.DeleteAllSentReminders<ResponseBase>(model)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.sendByMeReminderList = [];
                    this.displayPagination = false;
                    this.showSpinner = false;
                    //this.sendByMeReminder(new RequestReminder());
                    //this.sendMessage("");
                },
                error: err => {
                    this.sendByMeReminderList = [];
                    this.showSpinner = false;
                    this.displayPagination = false;
                },
                complete: () => {
                }
            })
    }
    //End

    //Status change
    ChangeReminderStatus(id, flag) {
        this.reminderService.ChangeReminderStatus<ResponseBase>(id, flag)
            .subscribe({
                next: (response: ResponseBase) => {
                    var index = this.sendByMeReminderList.findIndex(c => c.id == response.result.id);
                    this.sendByMeReminderList.splice(index, 1);
                    this.sendByMeReminderList.splice(index, 0, response.result);
                    var MyRemindersCount = this.sendByMeReminderList.filter((x, i) => { return x.reminderStatus == false; }).length;
                    if (MyRemindersCount > 0) {
                        this.allReminderDone = false;
                    } else {
                        this.allReminderDone = true;
                    }
                    this.GetNotDoneReminderCounts();
                    var requestReminder = new RequestReminder();
                    requestReminder.StartDate = new Date().toString();
                    requestReminder.EndDate = new Date().toString();
                    this.getDeshboardReminder(requestReminder);
                    this.authService.UpdateReceiveByMeReminderList(true);
                    //this.sendMessage(id);
                },
                error: err => { },
                complete: () => { }
            });
    }
    //End

    allReminderListDone(allReminderDone) {
        var message = "";
        var allDoneMsg = 'Headers.AllReminderDone';
        var removeAllDoneMsg = 'Headers.NotDoneAllReminder';
        var isDoneAll = this.sendByMeReminderList.filter((x, i) => { return x.reminderStatus == false; }).length //this.MyReminders.find(o => o.reminderStatus == true);

        if (isDoneAll > 0)
            message = allDoneMsg;
        else
            message = removeAllDoneMsg;

        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: '200px',
            data: { Header: message }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.showSpinner = true;
                var tempMyReminders = this.sendByMeReminderList.map(function (a) {
                    return a.id;
                });
                this.reminderService.ChangeAllReminderStatus<ResponseBase>(tempMyReminders, this.allReminderDone)
                    .subscribe({
                        next: (response: ResponseBase) => {
                            this.showSpinner = false;
                            if (this.allReminderDone) {
                                this.getSuccessTone();
                            }
                            this.getAllSendByMeReminder = false;
                            //this.AllReminderDone();
                            this.sendMessage(null);
                        },
                        error: err => {
                            this.showSpinner = false;
                        },
                        complete: (
                        ) => {
                            this.showSpinner = false;
                        }
                    });
            }
            else {
                this.showSpinner = false;
            }
        });
    }

    AllReminderDone() {
        this.authService.UpdateReceiveByMeReminderList(true);
        this.GetNotDoneReminderCounts();

        var requestReminder = new RequestReminder();
        requestReminder.StartDate = new Date().toString();
        requestReminder.EndDate = new Date().toString();
        this.getDeshboardReminder(requestReminder);
    }

    GetNotDoneReminderCounts() {
        this.reminderService.GetNotDoneReminderCountApi<ResponseBase>(this.authService.getCoWorkerId())
            .subscribe({
                next: (response: ResponseBase) => {
                    if (response != null) {
                        this.notDoneReminderCount = response.result;
                        this.authService.setNotDoneReminderCount(this.notDoneReminderCount);
                    }
                },
                error: err => { },
                complete: () => { }
            });
    }

    getDeshboardReminder(model: RequestReminder) {
        model.StartDate = this.datePipe.transform(model.StartDate, "yyyy-MM-dd");
        model.EndDate = this.datePipe.transform(model.EndDate, "yyyy-MM-dd");
        model.CoworkerId = this.authService.getCoWorkerId();
        model.IsDeleted = false;
        model.Status = false;
        this.reminderService.GetReminderListWithMutipleFiles<ResponseBase>(model)
            .subscribe({
                next: (response: ResponseBase) => {
                    if (response != null) {
                        this.DashboardReminders = response.result.length;
                        this.authService.setReminderCount(response.result.length);
                    }
                    else {
                        this.DashboardReminders = [];
                        this.authService.setReminderCount(0);
                    }
                },
                error: err => { this.Reminders = []; },
                complete: () => { }
            });
    }

    ReminderForm() {
        this._router.navigate(["/reminder/add"]);
    }


    ngAfterViewInit() {
        //this.reminderFilterObs = this.authService.reminderFilterSelectChangeObs.subscribe((data) => {
        //    if(data) {
        //        this.selectedStartDateForFilter = data.StartDate;
        //        this.selectedEndDateForFilter = data.EndDate;
        //        var request = new RequestReminder();
        //        request.Text = data.text;
        //        request.CreatedBy = data.CoworkerId;
        //        request.StartDate = data.StartDate;
        //        request.EndDate = data.EndDate;
        //        request.OrderBy = data.orderBy;
        //        request.Limit = data.Limit ? data.Limit:100;
        //        this.sendByMeReminder(request);
        //    }
        //});

        //this.UpdateReminderObs = this.authService.UpdateReminderListChangeObs.subscribe((data) => {
        //    if (data) {
        //        this.sendByMeReminder(new RequestReminder);
        //    }
        //});
    }

    //#region Code to Prevent accordion from expantion
    expandPanel(matExpansionPanel: MatExpansionPanel, event: Event): void {
        event.stopPropagation(); // Preventing event bubbling
        if (!this.IsExpand) {
            if (!this._isExpansionIndicator(event.target)) {
                matExpansionPanel.close(); // Here's the magic
            }
        }
    }

    private _isExpansionIndicator(target: EventTarget): boolean {
        const expansionIndicatorClass = 'mat-expansion-indicator';
        return ((<HTMLElement>target).classList && (<HTMLElement>target).classList.contains(expansionIndicatorClass));
    }

    OpenCloseAccordion(flag) {
        this.IsExpand = !flag;
        if(this.IsExpand)
            this.sendByMeReminder(new RequestReminder());
    }
    //#endregion Code to Prevent accordion from expantion

    getSuccessTone() {
        let audio = new Audio();
        audio.src = "../../../../../../assets/soundEffect/ClappingCrowd.mp3";
        audio.load();
        audio.play();
    }

    sendMessage(data: any): void {
        this.signalRService.sendMessage(data);
    }
    private subscribeToEvents(): void {
      this.signalRService.messageReceived.subscribe((data: any) => {
        this._ngZone.run(() => {
          this.sendByMeReminder(new RequestReminder());
        });
      });
    }

    showImgShowCase(imgs) {
        const dialogRef = this.dialog.open(VibeImgShowcaseComponent, {
            height: '400px',
            data: { Imgs: imgs }
        });
        dialogRef.afterClosed().subscribe(result => {
        });
    }

    ngOnDestroy() {
        if(this.reminderFilterObs) {
            this.reminderFilterObs.unsubscribe()
        }
        if(this.UpdateReminderObs) {
            this.UpdateReminderObs.unsubscribe()
        }
    }
}
