export const locale = {
    lang: 'ds',
    data: {
        'btn': {
            'Copy': 'Kopi',
            'Cancel': 'Tilbage',
          },
        'LABELS': {
            'From':'Fra',
            'To' : 'TIL'
        },
        'VALIDATIONS':{
            'from': 'vælg Lager',
            'to': 'vælg Lager'
        }
    }
};