//angular
import { Component, Inject, Optional, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
//services and interface
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import * as moment from 'moment';

//translate
import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";



@Component({
    selector: "Billed-status-date",
    templateUrl: "./billedStatusDate.component.html",
    styleUrls: ["./billedStatusDate.component.scss"]
})

export class BilledStatusDateComponent implements OnInit {
    billedDate: any;
    constructor(private translationLoaderService: FuseTranslationLoaderService,
        public dialogRef: MatDialogRef<BilledStatusDateComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
        this.translationLoaderService.loadTranslations(danish, english);
    }

    ngOnInit() {
        if (this.data) {
            this.billedDate = this.data.BilledDate;
            if (!this.billedDate) {
                this.billedDate = moment(new Date()).format("DD-MM-YYYY HH:mm");
            }
        }
    }

    AddDate() {
        if (this.billedDate && typeof this.billedDate !== "string") {
            this.billedDate = moment(this.billedDate).format("DD-MM-YYYY HH:mm");
        }
        var date = {
            billedDate: this.billedDate
        }
        this.ngOnBackClick(date)
    }

    ngOnBackClick(data) {
        this.dialogRef.close(data);
    }

}