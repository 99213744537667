import { Component, OnInit, Inject } from '@angular/core';
import { FuseTranslationLoaderService } from '../../../core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'app-alert-planning-message-model',
    templateUrl: './alert-planning-message-model.component.html',
    styles: []
})
export class AlertPlanningMessageModelComponent implements OnInit {
  Header: string = "";
  FirstButtonLabel = "";
  SecondButtonLabel = "";
    constructor(private translationLoader: FuseTranslationLoaderService,
        public dialogRef: MatDialogRef<AlertPlanningMessageModelComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.translationLoader.loadTranslations(english, danish);

    }

    ngOnInit() {
      this.Header = this.data.Header;
      this.FirstButtonLabel = this.data.FirstButtonLabel;
      this.SecondButtonLabel = this.data.SecondButtonLabel;
    }

    onCloseDialog(e) {
        this.dialogRef.close(e)
    }

}
