import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { MatDialog } from '@angular/material';
import { Component, OnInit, EventEmitter, Input, Output, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { TitleService } from 'app/core/services/title.service';
import { AuthService } from 'app/core/services/auth.service';
import { RequestMaterial } from 'app/main/model/RequestBase';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { QuickInvoiceMaterials, QuickInvoice } from 'app/main/model/Material';
import { MaterialService } from 'app/main/pages/material/material.service';
import { CommonService } from 'app/core/services/common.service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';

@Component({
  selector: 'vibe-quickinvoice-materialadd',
  templateUrl: './materialadd.component.html',
  styleUrls: ['./materialadd.component.scss']
})
export class VibeQuickInvoiceAddMaterialComponent implements OnInit {
  @Input() quickInvoice: QuickInvoice;
  @Output() AddedMaterialList = new EventEmitter();
  @ViewChild("barCode", { static: false }) barcodeFeild: ElementRef;
  // roleName: string = "";
  // isValid: boolean = false;
  customerId: string = "";
  MaterialName: string = "";
  SearchMaterial: string = "";
  requestModel: RequestMaterial = new RequestMaterial();
  showSpinner: boolean = false;
  Material: QuickInvoiceMaterials;
  Materials: QuickInvoiceMaterials[];
  SearchMaterials: QuickInvoiceMaterials[];
  AddedMaterials: QuickInvoiceMaterials[];
  ScanBarcode: string = "";
  scanMaterial: boolean = false;
  manualAddMaterial: boolean = false;
  ManuallAddMaterialId: string = "";
  IsAddManualMaterial: boolean = false;
  IsDisplayProject: boolean = false;
  DisplayAddedMaterial: boolean = false;

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  isDuplicateEntry: boolean = false;

  constructor(private _formBuilder: FormBuilder, private translationLoader: FuseTranslationLoaderService,
    private authService: AuthService, private titleService: TitleService,
    private materialService: MaterialService,
    private translate: TranslateService,
    private notificationsService: NotificationsService,
    private commonService: CommonService,
    private idle: Idle,
    private keepalive: Keepalive,
    public dialog: MatDialog) {
    this.translationLoader.loadTranslations(english, danish);
    this.titleService.SetTitle("TITLE_STORE_SALES");
    //this.titleService.SetTitle("TITLE_STORE_SALES");

    // sets an idle timeout of 5 seconds, for testing purposes.
    idle.setIdle(10);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(10);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => this.idleState = 'No longer idle.');
    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      this.IsAddManualMaterial = false;
      this.reset();
    });
    idle.onIdleStart.subscribe(() => this.idleState = 'You\'ve gone idle!');
    idle.onTimeoutWarning.subscribe((countdown) => this.idleState = 'You will time out in ' + countdown + ' seconds!');

    // sets the ping interval to 15 seconds
    keepalive.interval(15);

    keepalive.onPing.subscribe(() => this.lastPing = new Date());
    this.reset();
  }

  ngOnInit() {
    // this.roleName = this.authService.getUserRole();

    // if (this.roleName == "Admin") {
    //   this.isValid = true;
    // }
    // else if (this.roleName == "Kontor") {
    //   this.isValid = true;
    // }
    // else if (this.roleName == "Maler") {
    //   this.isValid = false;
    // }
    // else if (this.roleName == "Gaest") {
    //   this.isValid = false;
    // }
    // else {
    //   this.isValid = true;
    // }
    this.Materials = [];
    this.SearchMaterials = [];
    this.AddedMaterials = [];
    this.requestModel = new RequestMaterial();
    this.requestModel.IsActive = true;
    this.requestModel.IsDeleted = false;
    this.requestModel.Limit = 10000;
    this.requestModel.OrderBy = "Text";
    this.GetAllMaterial(this.requestModel);
    this.scanMaterial = false;
    this.manualAddMaterial = false;
  }


  ngAfterViewInit() {
  }

  GetAllMaterial(model) {
    //this.showSpinner = true;
    this.materialService.GetQuickInvoiceMaterial<ResponseBase>(model)
      .subscribe((response: ResponseBase) => {
        if (response != null) {
          this.Materials.push(...response.result);
          this.SearchMaterials.push(...response.result);
          model.Index = response.pagination.index + 1;
          model.Limit = response.pagination.limit;
          model.TotalRows = response.pagination.totalCount;
          if (response.pagination.totalCount > response.pagination.index * response.pagination.limit)
            this.GetAllMaterial(model);
          this.setBarcodeFocus()
        }
        this.showSpinner = false;
      },
        error => (err) => {
          this.showSpinner = false;
        },
        () => {
        });
  }

  ManualAddMaterial() {
    this.manualAddMaterial = true;
    this.scanMaterial = false;
    this.ScanBarcode = "";
  }

  ProjectDetail() {
    var isRequiredDetails = this.AddedMaterials.filter(item => (item.isRequiredText == true && !item.colorCode) || (item.text == undefined || item.text == ""));
    if (isRequiredDetails && isRequiredDetails.length > 0) {
      this.translate.get("MESSAGES.BlankMaterial").subscribe({
        next: (res) => {
          this.notificationsService.error(res);
          this.commonService.playFailedTone();
        },
        error: err => {
        }
      });
    }
    else {
      this.IsDisplayProject = true;
    }
  }

  ShowAddMaterialForm() {
    this.IsDisplayProject = false;
  }

  Finish() {
    var paymentMode = this.quickInvoice.requisitionDetail.paymentMode;
    var fullMsg = "";
    var message1 = "";
    var message2 = "";
    var message3 = "";
    this.translate.get("MESSAGES.PaymentModeAlert").subscribe({
      next: (res) => {
        message1 = res;
      }, error: err => { }
    });

    this.translate.get("FIELDS.Paymentmode").subscribe({
      next: (res) => {
        message3 = res;
      }, error: err => { }
    });
    this.translate.get("paymentModeList.value" + paymentMode).subscribe({
      next: (res) => {
        message2 = res;
      }, error: err => { }
    });
    fullMsg = message1 + " " + message2 + " " + message3;
    const dialogRef = this.dialog.open(AlertMessageModelComponent, {
      height: '200px',
      data: { Header: fullMsg }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.saveQuickInvoice()
      } else {
        return;
      }
    });
  }

  saveQuickInvoice() {
    this.showSpinner = true;
    var isRequiredDetails = this.AddedMaterials.filter(item => (item.isRequiredText == true && !item.colorCode) || (item.text == undefined || item.text == ""));
    if (isRequiredDetails && isRequiredDetails.length > 0) {
      this.translate.get("MESSAGES.BlankMaterial").subscribe({
        next: (res) => {
          this.notificationsService.error(res);
          this.commonService.playFailedTone();
        },
        error: err => {
        }
      });
    }
    else {
      //for (var i = 0; i < this.AddedMaterials.length; i++) {
      //  this.AddedMaterials[i].text = this.AddedMaterials[i].materialText;
      //}
      this.AddedMaterialList.emit(this.AddedMaterials);
    }
  }

  AddMaterialManually() {
    this.Material = new QuickInvoiceMaterials();
    this.IsAddManualMaterial = true;
  }

  AddScanMaterial(flag) {
    this.IsAddManualMaterial = !flag;
    this.setBarcodeFocus();
  }

    async AddToMaterialListManually(material) {
        var findMaterial = this.Materials.find(o => (o.itemNumber == material.itemNumber) && (o.colorCode == material.colorCode));
        if (!findMaterial) 
              findMaterial = new QuickInvoiceMaterials();
        if(findMaterial.maxOrderNumber && (material.quantity > findMaterial.maxOrderNumber)) {
          let msg:string='';
          this.translate.get("VALIDATIONS.MaxQuantityAddMsg1").subscribe({
              next: (res) => { msg = res.replace("@qty", material.quantity +" "+ material.unit); }, error: err => { }
          });
          const dialogRef = this.dialog.open(AlertMessageModelComponent, { 
              height: '200px',
              data: { Header: msg },
          });
          await dialogRef.afterClosed()
              .toPromise()
              .then(result => { 
                  if(result==false) {
                      return Promise.reject();
                  } else {
                      return Promise.resolve();
                  }
              });
        }
        //this.AddedMaterials = this.AddedMaterials.filter(o => o.id != material.id);
        findMaterial = material;
        material.colorCode= (material.colorCode ? material.colorCode : null);
        var addedmaterial = !this.isDuplicateEntry ? this.AddedMaterials.find(o => (o.itemNumber == material.itemNumber) && (o.colorCode == material.colorCode)) : null;
        if (addedmaterial) {
            findMaterial.quantity = material.isEditMaterial ? material.quantity : addedmaterial.quantity + findMaterial.quantity;
            findMaterial.retailPriceTotal = findMaterial.quantity * addedmaterial.retailPrice;
            findMaterial.personalPriceTotal = findMaterial.quantity * addedmaterial.personalPrice;
            const index: number = this.AddedMaterials.indexOf(addedmaterial);
            this.AddedMaterials.splice(index, 1);
            //findMaterial = addedmaterial;
        }
        else {
            findMaterial.isDuplicateEntry = this.isDuplicateEntry;
            findMaterial.quantity = findMaterial.quantity;
            findMaterial.retailPriceTotal = findMaterial.quantity * findMaterial.retailPrice;
            findMaterial.personalPriceTotal = findMaterial.quantity * findMaterial.personalPrice;
        }
        this.AddedMaterials.unshift(findMaterial);
        this.DisplayAddedMaterial = false;
        setTimeout(() => {
            this.DisplayAddedMaterial = true;
            this.isDuplicateEntry = false;
        }, 1);
    }

    BarcodeAddMaterialToList(event) {
      setTimeout(() => {
      if (this.ScanBarcode) {
        var barcode = this.ScanBarcode.trim();
        var findMaterial = this.Materials.find(o => o.barcode == barcode || o.barcode2 == barcode);
        if (!findMaterial) {
          this.translate.get("MESSAGES.NoBarcodeAttachedToMaterial").subscribe({
            next: (res) => {
              this.notificationsService.error(res);
              this.playFailedTone();
            },
            error: err => {
            }
          });
        }
        else {
          this.isDuplicateEntry = findMaterial.duplicateEntry == undefined ? false: findMaterial.duplicateEntry;
          var addedmaterial = !this.isDuplicateEntry ? this.AddedMaterials.find(o => o.barcode == barcode || o.barcode2 == barcode): null;
          if (addedmaterial) {
              findMaterial.quantity = addedmaterial.quantity + findMaterial.purchaseQuantity;
              findMaterial.retailPriceTotal = addedmaterial.quantity * addedmaterial.retailPrice;
              findMaterial.personalPriceTotal = addedmaterial.quantity * addedmaterial.personalPrice;
            const index: number = this.AddedMaterials.indexOf(addedmaterial);
            this.AddedMaterials.splice(index, 1);
            //findMaterial = addedmaterial;
          }
          else {
            findMaterial.quantity = findMaterial.purchaseQuantity;
            findMaterial.retailPriceTotal = findMaterial.quantity * findMaterial.retailPrice;
            findMaterial.personalPriceTotal = findMaterial.quantity * findMaterial.personalPrice;
            if (findMaterial && findMaterial.isRequiredText) {
              this.AddMaterialManually();
              this.Material = findMaterial; 
              this.ScanBarcode = "";
              return;
            }
          }
          this.AddedMaterials.unshift(findMaterial);
          this.playSuccessTone();
        }
        this.ScanBarcode = "";
      }
    }, 500);
    this.DisplayAddedMaterial = false;
    setTimeout(() => {
      this.DisplayAddedMaterial = true;
      this.isDuplicateEntry = false;
    }, 550);
  }

    EditMaterial(material) {
    this.IsAddManualMaterial = false;
    this.IsDisplayProject = false;
        setTimeout(() => {
      this.Material = material;
      this.IsAddManualMaterial = true;
    }, 1);
  }

  DeleteMaterial(index) {
    this.AddedMaterials.splice(index, 1);
    //this.AddedMaterials = this.AddedMaterials.filter(o => o.id != id);
  }
  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  playFailedTone() {
    this.commonService.playFailedTone();
  }

  playSuccessTone() {
    this.commonService.playSuccessTone();
  }
  setBarcodeFocus() {
    setTimeout(() => {
      if(this.barcodeFeild && this.barcodeFeild.nativeElement)
        this.barcodeFeild.nativeElement.focus();
    }, 100)
  }

  selectMaterialManual(findMaterial){
    this.isDuplicateEntry = findMaterial.duplicateEntry == undefined ? false: findMaterial.duplicateEntry;
  }
}


