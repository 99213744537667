import { Injectable } from "@angular/core";
import { CanDeactivate } from "@angular/router";
import { of } from "rxjs";
import { map, switchMap, take } from "rxjs/operators";
import { DirtyComponent } from "./dirty-check";
import { MatDialog } from "@angular/material";
import { AlertMessageModelComponent } from "app/main/pages/alert-message-model/alert-message-model.component";

@Injectable({ providedIn: "root" })
export class DirtyCheckGuard implements CanDeactivate<DirtyComponent> {
  constructor(
    private dialog: MatDialog,
  ) {}

  canDeactivate(component: DirtyComponent) {
    return component.isDirty$.pipe(
      switchMap(dirty => {
        if (dirty != true) {
          return of(true);
        }
        
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
          height: "200px",
          data: { Header: 'Headers.UnsavedChanges' }
        })

        return dialogRef.afterClosed().pipe(
          map(result => result === true)
        );
      }),
      take(1)
    );
  }
}
