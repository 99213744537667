import { Component, OnInit, Optional } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';

import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { ProjectofferService } from 'app/main/pages/project-offer/project-offer.service';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { Settings } from 'app/main/model/Settings';
import { MatDialogRef } from '@angular/material';
import { CalendarService } from 'app/main/pages/calendar/calendar.service';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'Driving-amount',
    templateUrl: './driving-amount.component.html',
    styleUrls: ["./driving-amount.component.scss"]
})

export class DrivingAmountComponent implements OnInit {
    showSpinner: boolean = false;
    SettingsObj: Settings = new Settings();
    travelingAmount: number = 0;
    DeserilizedValue: any;
    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private translate: TranslateService,
        private projectofferService: ProjectofferService,
        public dialogRef: MatDialogRef<DrivingAmountComponent>,
        private calendarService: CalendarService,
        private notificationsService: NotificationsService) {
        this.translationLoaderService.loadTranslations(danish, english);
    }

    ngOnInit() {
        this.getTravelingDistancePerDayValue();
    }

    getTravelingDistancePerDayValue() {
        this.showSpinner = true;
        this.projectofferService.GetDistancePerMinuteValue().subscribe((response: ResponseBase) => {
            if (response) {
                this.showSpinner = false;
                this.SettingsObj = response.result.myResponse[0];
                // if (this.SettingsObj.value) {
                //     this.DeserilizedValue = JSON.parse(this.SettingsObj.value);
                //     this.travelingAmount = this.DeserilizedValue.amount;
                // }
            }
        },
            error => (err) => {
                this.showSpinner = false;
            });
    }

    Update() {
        this.showSpinner = true;
        // this.DeserilizedValue.amount = this.travelingAmount ? this.travelingAmount : 0;
        // this.SettingsObj.value = JSON.stringify(this.DeserilizedValue);
        this.SettingsObj.value = this.SettingsObj.value ? this.SettingsObj.value : 0;
        this.calendarService.UpdateSettingValue<ResponseBase>(this.SettingsObj.id, this.SettingsObj)
            .subscribe(resonse => {
                if (resonse) {
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.Success").subscribe({
                        next: (res) => {
                            this.notificationsService.success(res);
                        },
                        error: err => {
                        }
                    });
                    this.dialogRef.close(true)
                }
            },
                error => (err) => {
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });
                });
    }

    close() {
        this.dialogRef.close(false);
    }
}