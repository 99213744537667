export const environment = {
  production: false,
  envName: 'devtest',
  hmr: false,
  url: 'https://webapitest.vibesite.dk/',
  fileUploadURL: 'https://test.vibesite.dk',
  api: 'api',
  version: 'v1',
  versionNo: '01.04.01',
  lastUpdatedDate: '2019-09-11',
  DropBoxFolderName: 'VibeEPTest',
  GooogleKey: 'AIzaSyB2LPLAZRlRAHndaGDzsuXpBrUdzivMqYQ',
    SendingNotificationValue2: 'SendOnDevlopment',
  // Kim's dropbox
  DrpBoxToken: 't6OzERwwNwoAAAAAAAV43cRjWXjcY7xwOVCyWGUQrvrjnMqESz0zQ0VA8GHZhgK7',
  firebase: {
    apiKey: 'AIzaSyAdnXh9tH6OkKZSjTlixgNyHlfk_LFKIDY',
    authDomain: 'vibes-maler.firebaseapp.com',
    databaseURL: 'https://vibes-maler.firebaseio.com',
    projectId: 'vibes-maler',
    storageBucket: 'vibes-maler.appspot.com',
    messagingSenderId: '961925478784',
    appId: '1:961925478784:web:29183500126efe03832965',
    measurementId: 'G-FE6NXKEXB5'
  },
  // SignalR Hub
  signalRHubName: 'VibeAppSignalRHub',
};
