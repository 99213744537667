export const locale = {
  lang: 'ds',
  data: {
    'btn': {
      'nonOk': 'Indsæt timer',
      'nonCancel': 'Gå tilbage',
      'nonShowdailyreport': 'Vis daglig rapport',

    },
    'MESSAGES': {
      'Add_Error': 'Ingen registrering fundet på Vagttider eller Vagtplan bordet, kontakt venligst til administrator.',
      'MoreThan7Hours':'du kan ikke bruge mere end 7 timer på fredag'
    },
    'Fld': {
      'nonStatus': 'Type',
      'project': 'Projekt',
      'nonText': 'Tekst',
      'nonDate': 'Dato',
      'nonSR.No': 'sr.nr',
      'nonStartime': 'Starttidspunkt',
      'nonEndtime': 'Slut tid',
      'nonTotal': 'i alt',
      'nonEnterStarttime': 'Indtast starttidspunkt',
      'nonEndTime': 'Indtast sluttidspunkt',
      'nonDayHoursTotal': 'Dagtimer i alt',
      'pause': 'Pause',
      'breakTime': 'Tilføj pause (30 min)',
      'WorkingHours': 'Arbejdstimer',
      'StartDate': 'Start dato ',
      'EndDate': 'Slut dato ',
      'PaymentOption': 'Betaling option',
      'LeaveStatus': 'Status',
    },
    'Hdr': {
      'nonNonWorkingHours': 'Ikke arbejdstid',
      'nonAddNonWorkingHours': 'Tilføj ikke arbejdstid',
      'nonAdd7to1130': 'Tilføj 07:00 til 11:30',
      //'nonAdd7to01': 'Tilføj 07:00 til 13:00',
      'nonAdd7to0230': 'Tilføj 07:00 til 14:30',
      'nonAdd7to03': 'Tilføj 07:00 til 15:00',
      'nonAddHoursManually': 'Tilføj timer manuelt',
      'DateIsRequired': 'Dato er påkrævet',
      'SelectProject': 'Vælg projekt',
      'SelectStatus': 'Vælg Type',
      'Youdidnotaddanyentry': 'Du har ikke tilføjet nogen post.'
    },
    'selectedWorkHourStatus': {
      'value1': 'Ferie ',
      'value2': 'Syg',
      'value3': 'Feriefridag',
      'value4': 'Fridag m.løn',
      'value5': 'Fridag u.løn',
      'value6': 'Kursus/skole',
      'value7': 'Afspadsering',
      'value8': 'Andet',
      'value9': 'Tilbud'
    },
    'paymentOptionType': {
      'value1': 'Uden Betaling',
      'value2': 'Med Betale',
    },
    'selectedWorkHoursApproveLeaveOption': {
      'value1': 'Ansøg',
      'value2': 'Godkendt',
      'value3': 'Afvist',
    },
  }
};
