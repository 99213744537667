export const locale = {
    lang: 'en',
    data: {
        'FILTERS': {
            'TEXTSEARCH': 'Search By Name,Email,Phone,Address',
        },
        'FIELDS': {
            'Not-Found-Material': 'No material found',
            'Number': 'Sr.',
            'MaterialNo': 'Number',
            'Material': 'Material',
            'MaterialText': 'Material Text',
            'DateonCreate': 'Date on Create',
            'Quantity': 'Quantity',
            'Unit': 'Unit',
            'RetailPrice': 'Retail Price',
            'TotalPrice': 'Total Price',
            'PersonalPrice': 'Personal Price',
            'PersonalTotalPrice': 'Personal Total',
            'PurchaseQuantity': 'Purchase Quantity',
            'ScanBarcode': 'Scan barcode',
            'ItemNo': 'Item No',
            'QTY': 'QTY',
            'Total': 'Total',
            'PriceExclVAT': 'Price excl. VAT',
            'PriceInclVAT': 'Price incl. VAT',
            'AddedMaterialList': 'Added material list',
            'ColorCode': 'Color code',
            'Paymentmode': 'as payment mode',
            'DuplicateEntry':'Duplicate Entry',
        },
        'Headers': {
            'MaterialList': 'Material List',
            'Nomaterialadded': 'No material added',
            'ScanMaterial': 'Scan material',
            'AddMaterial': 'Add material',
            'ProjectDetail': 'Project Detail',
        },
        'BUTTONS': {
            'StaffPurchase': 'Staff purchase',
            'StoredPurchase': 'Store purchase',
            'ScanMaterial': 'Scan material',
            'AddMaterial': 'Add material',
            'Add': 'Add',
            'AddManualMaterial': 'Add manual material',
            'AddScanMaterial': 'Add scan material',
            'Back': 'Back',
            'GenerateInvoice': 'Generate invoice',
            'ShowInvoice': 'Show Invoice',
            'TextToInvoice': 'Text to invoice'
        },
        'MESSAGES': {
            'NoBarcodeAttachedToMaterial': 'No barcode attached to any material.',
            'BlankMaterial': 'Color code needs to be filled outs.',
            'PaymentModeAlert': 'Is it correct that the customer wants to pay',
        },
        'paymentModeList': {
            'value1': 'Dankort',
            'value2': 'Mastercard',
            'value3': 'Cash',
            'value4': 'Mobile payment',
            'value5': 'Mobile payment KK',
            'value6': 'Credit',
            'value7': 'Other',
        },
        'VALIDATIONS': {
            'MaxQuantityAddMsg1': 'Are you sure you want to add @qty?',
            'MaxQuantityAddMsg2': ' Qty, you are trying to add ',
        }
    }
};
