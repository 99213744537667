import { Component, OnInit, Input } from '@angular/core';
import { MaterialService } from '../material.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-material-image',
  templateUrl: './material-image.component.html',
  styleUrls: ['./material-image.component.scss']
})

export class MaterialImageComponent implements OnInit {

  @Input() imagePath: string = '';
  @Input() moduleName: string;

  host: string;
  className: string;
  constructor(private materialService: MaterialService) { }

    ngOnInit() {
    this.host = environment.url;
    if (this.moduleName == "co-worker") {
      this.className = 'coworkerImage'
    }
    else if (this.moduleName == "material") {
      this.className = 'materialImage'
    }
      else {
          this.className = 'coworkerImage'
      }
  }

  ngAfterViewInit() {
    //this.materialService.GetSingle <ResponseBase>(this.materialId)
    //  .subscribe({
    //    next: (response: ResponseBase) => { this.MaterialObject = response.result; this.image = this.MaterialObject.image },
    //    error: err => { },
    //    complete: () => { }
    //  });
    //this.image = this.materialId;
  }

  setImage(event) {
    //return setErrorImage(this.host, event);
    //this.imagePath = 'Upload/DeafultProfile.png';
    if (this.moduleName == "co-worker") {
      this.imagePath = 'Upload/coworker.png'
    }
    else if (this.moduleName == "material") {
        this.imagePath = 'Upload/NoImageAvailable.png';
    }
      else {
          this.imagePath = 'Upload/coworker.png'
      }
  }
}
