import { Component, OnInit, Inject } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { UserProfileService } from './user-profile.service';
import { FuseTranslationLoaderService } from '../../../core/services/translation-loader.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { EventEmitterEnum } from '../../../core/enum/event-emitter-ids.enum';
//import { FirebaseauthService } from '../../../core/services/firebaseauth.service';
//import { LoginComponent } from '../../content/login/login.component';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { CoWorkerFull } from '../../model/CoWorker';
import { ResponseBase } from '../../model/ResponseBase';
import { CoWorkersService } from '../coWorker/coWorkers.service';
import { TitleService } from '../../../core/services/title.service';
import { DatePipe } from '@angular/common';
import { AuthService } from '../../../core/services/auth.service';
@Component({
  selector: 'app-UserProfile',
  templateUrl: './user-Profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class VibeUserProfileComponent implements OnInit {

  userDetails: CoWorkerFull;
  changepass: boolean = false;
  password: any;
  newpassword: any;
  dialogRef: any;
  ChangePasswordFormErrors: any;
  ChangePasswordForm: FormGroup;
  // roleName: string;
  constructor(private translationLoader: FuseTranslationLoaderService, 
    private userProfileService: UserProfileService, public dialog: MatDialog,
    private formBuilder: FormBuilder, private coWorkersService: CoWorkersService,
    private _router: Router, private titleService: TitleService,
    private datePipe: DatePipe, private authService: AuthService) {
    this.titleService.SetTitle("TITLE_USER_PROFILE");
    this.translationLoader.loadTranslations(english, danish);
    //this.userProfileService.getUserDetails();
    this.userDetails = new CoWorkerFull();
  }

  ngOnInit() {
    this.getUser();
    //this.roleName = this.authService.getUserRole();
  }

  changepassword() {
    this.changepass = true;
  }
  onCancel() {
    this.changepass = false;

  }

  //onChangepass() {
  //  this.authService.changePassword(this.newpassword);
  //  this.changepass = false;
  //}

  opendialog() {
    this.dialogRef = this.dialog.open(ChangePasswordComponent, {
      width: '400px',
      data: {}
    });

    this.dialogRef.afterClosed().subscribe(result => {
    });
  }

  getUser() {
    this.coWorkersService.GetCoWrokerAsUser<ResponseBase>().subscribe(
      {
        next: (response: ResponseBase) => {
          this.userDetails = response.result;
          //if (this.userDetails.dateOfJoininng != null)
          //  this.userDetails.dateOfJoininng = this.datePipe.transform(this.userDetails.dateOfJoininng, "yyyy-MM-dd");
        },
        error: err => { },
        complete: () => { }
      });
  }

  edit() {
    this._router.navigate(['/coWorkers/edit', this.userDetails.id]);
  }
}


