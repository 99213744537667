export const locale = {
    lang: 'en',
    data: {
        "FIELDS": {
            "SrNo":"Sr No",
            "Options":"Options",
            "AnswerCoworkerName":"AnswerCoworkerName",
            'Percentage':"Percentage"
        },
        "BUTTONS": {
            "Ok": "Okay",
        },
        "MESSAGES": {
            "NORECORD": "No Record Found",
        },
    }
};
