export const locale = {
  lang: 'en',
  data: {
    'FIELDS': {
      'StartDate': 'Start Date ',
      'EndDate': 'End Date ',
      'Coworker': 'Select Co-worker',
      'Search-worker': 'Search Co-worker',
      'Not-Found-Worker': 'Co-worker not found',
      'NO_RECORD_FOUND': 'No Workhours found',
      '2WeekSalary': '14 days salaried Employee',
      'Monthly': 'Monthly Salary Employee',
      'isWithStatus': 'Is With Status',
      'nonStatus': 'Status',
      'GenerateFutureWorkHoursReport': 'Generate report in future',
      'GenerateWorkingCowrker':'Generate working coworker?',
      'ViewNonworkWithPayment':'See with amount'
    },
    'BUTTONS': {
      'BtnCancel': 'Cancel',
      'BtnPDF': 'Work sheet',
      'All': 'Total',
      'BtnText': 'Txt File',
      'SalarySlip':'Salary Slip',
      'ClosePDF':'Close Payment',
      'BtnNonWorkhoursPDF':'PDF'
    },
    'FILTERS': {
      'All': 'All',
    },
    'TAB':{
      'Salary':'Salary',
      'WorkSheet':'WorkSheet',
      'NonWorkHours':'Non workhours'
    },
    'MESSAGES': {
      'Add': 'Added successfully',
      'Payment14DaysConfirmation': 'Are you sure you want to Close Payment of Employee 14 Days Payment StartDate @startdate EndDate @endDate ?',
      'Payment30DaysConfirmation': 'Are you sure you want to Close Payment of Employee 30 Days Payment StartDate @startdate EndDate @endDate ?',

    },
    'selectedWorkHourStatus': {
      'value0': 'No Status',
      'value1': 'Vacation ',
      'value2': 'Sick',
      'value3': 'Holiday of the work ',
      'value4': 'Holiday with payment',
      'value5': 'Holiday without payment ',
      'value6': 'School or learning',
      'value7': 'No payment for extra work but holiday for it ',
      'value8': 'Other things',
      'value9': 'Offer'
  },
  }
}

