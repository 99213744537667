export const locale = {
    lang: 'en',
    data: {
        'Headers': {

        },
        'BUTTON': {
            'Go': 'Gå',
            'Cancel': 'No'
        },
    }
};
