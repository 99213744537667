import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { RequestForQualityTestList } from 'app/main/model/RequestBase';
import { AddQuestionInformation, OfferQualityTestCategory } from 'app/main/model/OfferQualityTestCategory';
import { environment } from 'environments/environment';
import { MatDialog } from '@angular/material';
import { TitleService } from 'app/core/services/title.service';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { AnswerCategoryQuestionComponent } from 'app/main/shared-component/answer-category-question/answer-category-question.component';
import { EditQualityCategoryComponent } from './components/edit-quality-category/edit-quality-category.component';
import { QualityTestService } from './quality-test.service';
import { AddQuestionInformationComponent } from 'app/main/shared-component/quality-test/components/add-question-information/add-question-information.component';
import { PdfViewerModelComponent } from 'app/main/pages/pdf-viewer/pdf-viewer.component';
import { DropboxFilesService } from '../dropbox-files/dropbox-files.service';
import { DriveModuleEnum } from 'app/core/enum/drive-module.enum';

@Component({
    selector: 'app-quality-test-shared',
    templateUrl: './quality-test.component.html',
    styleUrls: ['./quality-test.component.scss']
})
export class QualityTestComponent implements OnInit {
    @Input() ModuleID: string = "";
    @Input() ProjectID: string = "";
    @Input() CustomerID: string = "";
    @Input() ModuleName: string = "";
    @Input() ModuleNumber: string = "";
    @Input() IsUpdatable: boolean = true;
    @Input() ModuleRoomSelectID: string = "";
    @Input() CurrentModuleNumber: string = "";
    @Output() IsSendPDFFile = new EventEmitter<any>();
    OfferID: string = "";
    showSpinner: boolean = false;
    envirnmentURL: string = "";
    listRequest: RequestForQualityTestList = new RequestForQualityTestList();
    qualityTestCategoryList: OfferQualityTestCategory[] = [];
    selectedQualityTestCategoryList: OfferQualityTestCategory[] = [];
    selectedQualityTestCategory: OfferQualityTestCategory = new OfferQualityTestCategory();
    QuestionList: any = [];
    QuestionListJosn: any = [];
    IsFirstList: boolean = false;
    questionObject: any = new AddQuestionInformation();
    questionInformationList: any = [];
    orderBy: string = "Name";
    sortItems: any = [];
    module_name: string = "";
    rootFolderPath: string = "";
    constructor(private _route: ActivatedRoute,
        private translationLoader: FuseTranslationLoaderService,
        private translate: TranslateService,
        private _router: Router,
        public dialog: MatDialog,
        private notificationsService: NotificationsService,
        private qualityTestService: QualityTestService,
        private dropboxFilesService: DropboxFilesService,
        private titleService: TitleService) {
        if(this._router.url == "/qualitytest")
            this.titleService.SetTitle("TITLE_QUALITY_TEST");
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
        this.envirnmentURL = environment.url;
        this._route.params.subscribe(params => {
            if (params['id'] != undefined) {
                    this.OfferID = params['id'];
            }
        });
        this.listRequest.Index = 1;
        this.listRequest.Limit = 100;
        this.listRequest.IsDeleted= false;
        this.listRequest.OrderBy = "Name";
        this.listRequest.IsActive= true;
        this.GetQualityTestCategoryList();

        this.module_name = this.ModuleName=="Offer" ? "ProjectOffers" : this.ModuleName;        
        var rootPath = this.dropboxFilesService.getSettingMasterValueDocumentPath();
        var paths = "";
        if (rootPath.DocumentDriveType == DriveModuleEnum.Onedrive) 
            paths = rootPath.Onedrive.Path;
         else 
            paths = rootPath.Dropbox.Path;        
        this.rootFolderPath = "/" + paths + "/" + this.module_name + "/" + this.CurrentModuleNumber + "/Kvalitetstest";
        
    }

    GetQualityTestCategoryList() {
        this.IsFirstList=false;
        this.showSpinner = true;
        this.qualityTestService.GetQualityTestCategoryList(this.listRequest).subscribe({
            next: (response) => {
                if (response) {
                    this.qualityTestCategoryList = response.result;
                    this.selectedQualityTestCategoryList = this.qualityTestCategoryList.filter(e => e.parentID==null);
                    this.IsFirstList=true;
                    this.getQuestionInformation();
                }
            },
            error: err => {

            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    UpdateQualityTestCategory() {
        this.showSpinner = true;
        this.qualityTestService.UpdateQualityTestCategory(this.selectedQualityTestCategory.id,
            this.selectedQualityTestCategory).subscribe({
                next: (response) => {
                    if (response) {
                        this.translate.get("MESSAGE.Updated").subscribe({
                            next: (res) => {
                              this.notificationsService.success(res);
                            },
                            error: err => {
                            }
                        });
                        this.qualityTestCategoryList = this.qualityTestCategoryList.filter(o => o.id != response.result.id)
                        this.qualityTestCategoryList.push(response.result)
                        this.selectedQualityTestCategoryList = this.qualityTestCategoryList;
                        this.selectedQualityTestCategory = response.result;
                        this.QuestionList = [];
                        if (this.selectedQualityTestCategory.json) {
                            this.QuestionListJosn = JSON.parse(this.selectedQualityTestCategory.json);
                            if(this.QuestionListJosn && this.QuestionListJosn.length > 0){
                                this.QuestionListJosn.filter(q => {
                                    if (q.choiceOptions.length>0) {
                                        try {
                                            q.choiceOptions = JSON.parse(q.choiceOptions);
                                        } catch (e) {
                                        }
                                    }
                                })
                            }
                            this.QuestionList = this.QuestionListJosn;
                        }
                    }
                },
                error: err => {

                },
                complete: () => {
                    this.showSpinner = false;
                }
            });
    }

    goToSubCategory(obj) {
        // if (obj.name.toLowerCase()=="kontrolskema") {
            this.IsFirstList = false;
            this.selectedQualityTestCategory = obj;
            if (this.selectedQualityTestCategory.moduleName) {
                this.selectedQualityTestCategory.moduleName = this.selectedQualityTestCategory.moduleName.toLowerCase();
            }
            this.selectedQualityTestCategoryList = this.qualityTestCategoryList.filter(o => o.parentID == obj.id);
            this.QuestionList = [];
            if (this.selectedQualityTestCategory.json) {
                this.QuestionListJosn = JSON.parse(this.selectedQualityTestCategory.json);
                if (this.QuestionListJosn && this.QuestionListJosn.length > 0) {
                    this.QuestionListJosn.filter(q => {
                        if (q.choiceOptions.length > 0) {
                            try {
                                q.choiceOptions = JSON.parse(q.choiceOptions);
                            } catch (e) {
                            }
                        }
                    })
                }
                this.QuestionList = this.QuestionListJosn;
            }
        // }
        //this.getQuestionInformation();
    }

    goToBackCategory() {
        this.IsFirstList=false;
        if (!this.selectedQualityTestCategory.parentID) {
            this.selectedQualityTestCategory = new OfferQualityTestCategory();
            this.selectedQualityTestCategoryList = this.qualityTestCategoryList.filter(e => e.parentID==null);
            this.IsFirstList=true;
            this.getQuestionInformation();
        }
        else {
            this.selectedQualityTestCategory = this.qualityTestCategoryList.find(o => o.id == this.selectedQualityTestCategory.parentID);
            this.selectedQualityTestCategoryList = this.qualityTestCategoryList.filter(o => o.parentID == this.selectedQualityTestCategory.id);
        }
        this.questionInformationList=[];
    }


    AddQualityCategory() {
        const dialogRef = this.dialog.open(EditQualityCategoryComponent, {
            height: '50%',
            width: '50%',
            data: {
                offerCategoryId: null,
                previousCategory: this.selectedQualityTestCategory
            } 
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.listRequest.Index = 1;
                this.listRequest.Limit = 100;
                this.listRequest.IsDeleted= false;
                this.listRequest.IsActive= true;
                if(result.id) {
                    this.qualityTestCategoryList.push(result);
                    this.selectedQualityTestCategoryList.push(result);
                }
            }
        });
    }

    EditQualityCategory(Cat) {
        const dialogRef = this.dialog.open(EditQualityCategoryComponent, {
            height: '50%',
            width: '50%',  
            data: {
                offerCategoryId: Cat.id,
                offerCategory: Cat
            } 
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.listRequest.Index = 1;
                this.listRequest.Limit = 100;
                this.listRequest.IsDeleted= false;
                this.listRequest.IsActive= true;
                if(result.id) {
                    this.qualityTestCategoryList = this.qualityTestCategoryList.map(obj => (obj.id==result.id)? result : obj);
                    this.selectedQualityTestCategoryList = this.selectedQualityTestCategoryList.map(obj => (obj.id==result.id)? result : obj);
                }
            }
        });
    }

    DeleteQualityCategory(cat_id) {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: '20%',
            data: { Header: 'Headers.DeleteCategory' }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.DelQuaCat(cat_id);
            }
        });
    }

    DelQuaCat(Id) {
        this.showSpinner = true;
        this.qualityTestService.DeleteOfferQualityTestCategory(Id).subscribe({
          next: (response: ResponseBase) => {
            this.translate.get("MESSAGE.Deleted").subscribe({
              next: (res) => {
                this.notificationsService.create(res);
              },
              error: err => {
              }
            });
            this.listRequest.Index = 1;
            this.listRequest.Limit = 100;
            this.listRequest.IsDeleted= false;
            this.listRequest.IsActive= true;
            this.qualityTestCategoryList = this.qualityTestCategoryList.filter(i => i.id != Id);
            this.selectedQualityTestCategoryList = this.selectedQualityTestCategoryList.filter(i => i.id != Id);
          },
          error: err => {
            this.translate.get("MESSAGE.Failed").subscribe({
              next: (res) => {
                this.notificationsService.error(res);
              },
              error: err => {
              }
            });
          },
          complete: () => {
            this.showSpinner = false;
          }
        });
    }

    AddOrUpdateQuestionSave(event) {
        event.forEach((element,index) => {
            element['id'] = index+1
        });
        this.selectedQualityTestCategory.json = JSON.stringify(event);
        this.UpdateQualityTestCategory()
    }

    CaregoryAnswerPopup() {
        const dialogRef = this.dialog.open(AnswerCategoryQuestionComponent, {
            height: "90%",
            width: "70%",
            disableClose: true,
            data: {
                Header: "CateGory Question Answer",
                ShowOKButton: true,
                data: this.QuestionList,
                //projectId: this.ProjectId,
                //requisitionId: this.Requisition.id,
                //lastStaus: lastReqStatus,
                //newStatus: newReqStatus,
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                //this.updateSingleOrMultiStatus();
            }
        });
    }

    AddQuestionInformation(){
        if (!this.questionObject.id) {
            this.questionObject.projectID = this.ProjectID;
            this.questionObject.offerID = this.ModuleID;
            this.questionObject.offerQualityTestCategoryID = this.selectedQualityTestCategory.id;
            this.questionObject.json = "";
        }
        const dialogRef = this.dialog.open(AddQuestionInformationComponent, {
            height: "80%",
            width: "70%",
            data: {
                // Header: "CateGory Question Answer",
                data: this.questionObject,
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            this.questionObject= new AddQuestionInformation();
            this.getQuestionInformation();
        });
    }

    getQuestionInformation() {
        this.showSpinner = true;
        var model = new AddQuestionInformation();
        model.projectID = this.ProjectID;
        model.offerID = this.ModuleID;
        // model.offerQualityTestCategoryID = this.selectedQualityTestCategory.id;
        this.qualityTestService.GetOfferQualityTestCustomerQuestionsList<ResponseBase>(model)
            .subscribe((response: ResponseBase) => {
                this.showSpinner = false;
                this.questionInformationList = [];
                if (response != null) {
                    this.questionInformationList = response.result;
                }
            },
                error => (err) => {
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });
                });
    }

    editQuestion(question){
        if (question) {
            this.questionObject = question;
            const dialogRef = this.dialog.open(AddQuestionInformationComponent, {
                height: "80%",
                width: "70%",
                data: {
                    data: this.questionObject,
                }
            });

            dialogRef.afterClosed().subscribe(result => {
                this.questionObject = new AddQuestionInformation();
                this.getQuestionInformation();
            });
        }
    }

    deleteQuestion(id){
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: "200px",
            data: { Header: 'Headers.DeleteQuestion' }
        })

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.showSpinner = true;
                this.qualityTestService.DeleteOfferQualityTestCustomerQuestion(id).subscribe({
                    next: (response: any) => {
                        if (response) {
                            this.showSpinner = false;
                            this.translate.get("MESSAGES.Deleted").subscribe({
                                next: (res) => {
                                    this.notificationsService.success(res);
                                },
                                error: err => {
                                }
                            });
                        }
                    },
                    error: err => {
                        this.showSpinner = false;
                        this.translate.get("MESSAGES.Failed").subscribe({
                            next: (res) => {
                                this.notificationsService.error(res);
                            },
                            error: err => {
                            }
                        });
                    },
                    complete: () => {
                        this.showSpinner = false;
                        this.getQuestionInformation();
                    }
                })
            }
        });

    }

    viewPDF(){
        this.showSpinner = true;
        var model = new AddQuestionInformation();
        model.projectID = this.ProjectID;
        model.offerID = this.ModuleID;
        model.rootFolderPath = this.rootFolderPath;
        this.qualityTestService.DownloadOfferQualityTestCustomerQuestionsPDF<ResponseBase>(model)
            .subscribe((response: ResponseBase) => {
                var resultData = response.result;
                var data = response.result.base64.replace("data:application/pdf;base64,", "");
                var offerNumber = JSON.parse(localStorage.getItem("OfferNumber"));
                const dialogRef = this.dialog.open(PdfViewerModelComponent, {
                    height: '90%',
                    width: '90%',
                    data: {
                        base64: data,
                        filename: response.result.filename ? response.result.filename + ".pdf" : "Kvalitetstest" + offerNumber
                    }
                });
                dialogRef.afterClosed().subscribe(result => { });

        },
            error => (err) => {},
            () => {
                this.showSpinner = false;
            });
    }

    emailPDF() {
        this.showSpinner = true;
        var model = new AddQuestionInformation();
        model.projectID = this.ProjectID;
        model.offerID = this.ModuleID;
        this.qualityTestService.DownloadOfferQualityTestCustomerQuestionsPDF<ResponseBase>(model)
            .subscribe((response: ResponseBase) => {
                var data = response.result.base64.replace("data:application/pdf;base64,", "");
                var offerNumber = JSON.parse(localStorage.getItem("OfferNumber"));
                var attachmentFiles=[];
                var tempFile = {
                    base64: data,
                    fileName: response.result.filename ? response.result.filename + ".pdf" : "Kvalitetstest_" + this.ModuleName + "_" + this.ModuleID +".pdf",
                    fileType: "application/pdf",
                    name: response.result.filename ? response.result.filename + ".pdf" : "Kvalitetstest_" + this.ModuleName + "_" + this.ModuleID,
                    size: atob(data).length,
                }
                attachmentFiles.push(tempFile)
                this.IsSendPDFFile.emit(attachmentFiles);
            },
                error => (err) => {
                    this.showSpinner = false;
                },
                () => {
                    this.showSpinner = false;
                });
    }
    
    receiveFilterEvent(event) {
        this.orderBy = event;
        this.listRequest.OrderBy = event;        
        this.GetQualityTestCategoryList();
    }
    

}
