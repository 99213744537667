//import { Injectable } from '@angular/core';
//import { BehaviorSubject } from 'rxjs/BehaviorSubject';

//@Injectable({
//    providedIn: 'root'
//})
//export class ToolbartitleService {
//  private title = new BehaviorSubject<any>(' ');
//  data = this.title.asObservable();
//  constructor() { }

//  SetTitle(values: any) {
//    this.title.next(values);
//  }
//}
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable({
    providedIn: 'root'
})
export class TitleService {
    private title = new BehaviorSubject<any>(' ');
    data = this.title.asObservable();

    private addressInTitle = new BehaviorSubject<any>('');
    address = this.addressInTitle.asObservable();

    constructor() { }

    SetTitle(values: any) {
        this.title.next(values);
    }

    SetAddress(values: any) {
        this.addressInTitle.next(values);
    }
}