export const locale = {
  lang: 'en',
  data: {
    'PROJECT': {
      'TITLE': 'Project',
      'DESC': 'Hello Project!'
    },
    'TITLE': {
      'InquiriePagination': 'Antal forespørgser pr. side',
    },
    'BUTTONS': {
      'NEWCUSTOMER': 'New Customer',
      'NEWINQUIRY': 'New Inquiry',
      'BACK': 'Back'
    },
    'FILTERS': {
      'TEXTSEARCH': 'Search By Name',
      'NUMBERSEARCH': 'Number Search',
      'CustomerNumber': 'Customer Number',
      'CustomerName': 'Customer Name',
      'PhoneNumber': 'Phone Number',
      'Email': 'Email',
      'Address': 'Address',
      'City': 'City',
      'ZipCode': 'ZipCode',
      'Position': 'Position',
      'Text': 'Text',
      'Action': 'Action',
      'SORTING': 'Sort By',
      'Active': 'Include all Inactive',
      'BADGE': 'Inactive',
      'Deleted': 'Show Deleted',
      'Note': 'Note',
    },
    'MESSAGES': {
      'NORECORD': 'No Inquiry Found'
    },
    'SORTBY':
      [
        //{
        //  Text: '     ',
        //  Value: 'ID DESC'
        //},
        {
          Text: 'Name (A-Z)',
          Value: 'Text'
        },
        {
          Text: 'Name (Z-A)',
          Value: 'Text DESC'
        },
        {
          Text: 'Number (0-9)',
          Value: 'Number'
        },
        {
          Text: 'Number (9-0)',
          Value: 'Number DESC'
        }
      ],
    'FIELDS': {
      'SORTING_STATUS': 'Status',
    },
    // 'selectedValueStatus': {
    //   'value0': 'Registered',
    //   'value1': 'Process',
    //   'value2': 'Scheduled visit',
    //   'value3': 'Ready to boot',
    //   'value4': 'Offers sent',
    //   'value5': 'Offer approved',
    //   'value6': 'Planned',
    //   'value7': 'Offer rejected',
    //   'value8': 'Started',
    //   'value9': 'Performed',
    //   'value10': 'Billed',
    //   'value11': 'Pending',
    //   'value12': 'Ready billing',
    // }
  }
};
