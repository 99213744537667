import { Component, OnInit } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { MatDialog , MatOption} from '@angular/material';
import { AuthService } from 'app/core/services/auth.service';
import { SettingsService } from '../../settings.service';
import { ResponseBase } from "app/main/model/ResponseBase";
import { Settings } from "app/main/model/Settings";

@Component({
    selector: "vibe-other-setting",
    templateUrl: "./other-setting.component.html",
    styleUrls: ["./other-setting.component.scss"]
})

export class OtherSettingComponent implements OnInit {
    showSpinner: boolean = false;
    TabSettingMasterObj : Settings;
    TabListSettingObj: Settings;
    AllOtherSettingObj: Settings;
    AllOtherSettingJson: any = {};
    TabListObj:any;
    settingMasterValues: any = [];
    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private settingsService: SettingsService) {
        this.translationLoaderService.loadTranslations(danish, english);
    }

    ngOnInit() {
        this.settingMasterValues = Object.assign([], JSON.parse(localStorage.getItem("settingMasterValues")));
        this.getSettingsForTabList();
        this.getAllOthersSettings();
    }

    getSettingsForTabList() {
        this.showSpinner = true;
        this.settingsService.GetSettingsValue<ResponseBase>("Menu", "TabsListSettings",null).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.result && response.result.myResponse.length > 0) {
                    this.TabListSettingObj = response.result.myResponse[0];
                    this.TabListObj = JSON.parse(response.result.myResponse[0].value);
                    this.changeInLocalStorage(this.TabListSettingObj.id, this.TabListSettingObj);
                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    changeTabSettings() {
        this.showSpinner = true;
        this.TabListSettingObj.value = JSON.stringify(this.TabListObj);
        this.settingsService.UpdateSettingValue<ResponseBase>(this.TabListSettingObj).subscribe({
            next: (response: ResponseBase) => {
                if (response != null && response.result) {
                    this.TabListSettingObj = response.result;
                    this.TabListObj = JSON.parse(response.result.value);
                    this.changeInLocalStorage(this.TabListSettingObj.id, this.TabListSettingObj);
                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    getAllOthersSettings() {
        this.showSpinner = true;
        this.settingsService.GetSettingsValue<ResponseBase>("AllOtherSettings", "AllOtherSettings", null).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.result && response.result.myResponse.length > 0) {
                    this.AllOtherSettingObj = response.result.myResponse[0];
                    this.AllOtherSettingJson = JSON.parse(response.result.myResponse[0].value);
                    this.changeInLocalStorage(this.AllOtherSettingObj.id, this.AllOtherSettingObj);
                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    changeAllOthersSettings() {
        this.showSpinner = true;
        this.AllOtherSettingObj.value = JSON.stringify(this.AllOtherSettingJson);
        this.settingsService.UpdateSettingValue<ResponseBase>(this.AllOtherSettingObj).subscribe({
            next: (response: ResponseBase) => {
                if (response != null && response.result) {
                    this.AllOtherSettingObj = response.result;
                    this.AllOtherSettingJson = JSON.parse(response.result.value);
                    this.changeInLocalStorage(this.AllOtherSettingObj.id, this.AllOtherSettingObj);
                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }
    
    changeInLocalStorage(ID, new_obj) {
        if(this.settingMasterValues.find(obj => obj.id==ID)) {
            this.settingMasterValues.forEach((old_obj, index) => {     
                if(old_obj.id==ID) {
                    this.settingMasterValues[index]=new_obj
                }
            })
        } else {
            this.settingMasterValues.push(new_obj)
        }
        localStorage.setItem("settingMasterValues", JSON.stringify(this.settingMasterValues));
    }
}