export const locale = {
    lang: 'ds',
    data: {
        'TITLE': {
            'CustomerPagination': 'Antal log pr. side',
        },
        'FIELDS': {
            'SRNO': 'Sr nr.',
            'Date': 'Dato',
            'CoworkerName': 'Medarbejder',
            'OldStatus': 'Gammel status',
            'NewStatus': 'Ny status',
            'Description': 'Tekst',
            'Quiz': 'Quiz',
            'Not-Found-Quiz': 'Quiz ikke fundet',
            'ModuleType': 'Modul type',
            "Title":'Titel',
            "StartDate":'Start dato',
            "EndDate":'Slut dato',
        },
        'Message': {
            'HideAnswer': 'Skjul svar'
        },
        'FILTERS': {
            'TEXTSEARCH': 'Søg efter nummer',
        },
        'moduleTypeList': {
            'value0': 'Alle',
            'value1': 'Projekter',
            'value2': 'Tilbud'
        }
    }
};