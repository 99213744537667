//#region Imports 
// angular 
import { Component, OnInit, Input, EventEmitter, Output, ViewChild, Optional, Inject } from '@angular/core';

// third party tool
import { TranslateService } from '@ngx-translate/core';

// app-core
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';

// interfaces
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { MatDialog, MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Supplier } from 'app/main/model/Supplier';
import { MaterialService } from '../../material.service';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { MaterialInventoryRequest, SupplierRequest } from 'app/main/model/RequestBase';
import { NotificationsService } from 'angular2-notifications';
import * as moment from "moment";
//#endregion Imports 

@Component({
    selector: 'material-inventory',
    templateUrl: './material-inventory.component.html',
    styleUrls: ['./material-inventory.component.scss'],
})
export class MaterialInventoryComponent implements OnInit {
    //#region Initializations
    //#region Variables
    startDate?: Date = new Date();
    endDate?: Date;
    reqModel: MaterialInventoryRequest = new MaterialInventoryRequest();
    showSpinner = false;
    materialInventoryList:any=[];
    //#endregion Variables

    constructor(
        private translationLoader: FuseTranslationLoaderService,
        private translate: TranslateService,
        private materialService: MaterialService,
        private notificationsService: NotificationsService,
        private translation: TranslateService,
    ) {
        this.translationLoader.loadTranslations(english, danish);
    }
    //#endregion  Initializations

    //#region Lifecycle Hooks
    ngOnInit() {
        //this.reqModel.startDate = moment(new Date()).format("YYYY-MM-DD");
        this.GetMaterialInventoryList()
    }
    //#endregion Lifecycle Hooks

    GetMaterialInventoryList() {
        this.showSpinner = true;
        this.reqModel.startDate = moment(this.startDate).format("YYYY-MM-DD");
        if(this.endDate)
            this.reqModel.endDate = moment(this.endDate).format("YYYY-MM-DD");
        this.materialService.GetMaterialInventoryFullList<ResponseBase>(this.reqModel)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.materialInventoryList = response.result;
                },
                error: err => { 
                    this.showSpinner = false;
                },
                complete: () => {
                    this.showSpinner = false;
                }
            });
    }
    SearchByName() {
        if (this.reqModel.number) 
            this.GetMaterialInventoryList();
    }
    //#endregion Public Methods
}