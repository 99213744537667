import { CoWorker } from "./CoWorker";
import { Project } from "./Project";


export class CoWorkerWorkHour extends CoWorker{
  public project?: ProjectWiseTiming[];
  constructor() {
    super();
  }
}

export class ProjectsCoWorkerWorkHour {
  public timingDateWise?: any;
  public id: string = '';
  public name: string = '';
  public isActive: boolean = false;
  public totalTime: number = 0;
}

export class ProjectWiseTiming extends Project {
  public timingDateWise?: any;
  public SagOpgaveID?: string;
  public statusValue?: number;
  public isCheked: boolean = false;
  constructor() {
    super();
  }
}

export class ProjectWorkHoursList extends Project {
  public timerDayWise?: TimerDayWise[]= [];
  public SagOpgaveID?: string;
  public statusValue?: number;
  public isCheked: boolean = false;
  public total: number = 0;
  public workHoursList: any;
  constructor() {
    super();
  }
}
export class TimerDayWise {
  public total: number = 0;
  public date : any;
  public isPastDate: boolean = false;
  public workHourInfo: string = "";
}
