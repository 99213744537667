export const locale = {
    lang: 'ds',
    data: {
        'Headers': {
            'AllSchedule': 'Alle dage',
            'OneDay': 'En dag',
            'Disconnect': 'Afbryd',
            "MoveScheduleOption" : "Flyt / kopier en dag eller alle dage"
        }
    }
};
