export const locale = {
    lang: 'ds',
    data: {
        'TITLE': {
        },
        'FIELDS': {
            'IsSendEmail': 'Er Send e-mail ?',
            'TabList': 'Fanelisteindstilling',
            'UseNanoLink': 'Bruge Nano Link',
            'NanolinkSetting': 'Nano link settings',
        },
        'Message': {

        },
        'FILTERS': {
        },
        'moduleTypeList': {
        },
        'BUTTONS': {
            'Save':'Gem',
        }
    }
};