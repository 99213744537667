import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-material-en',
  templateUrl: './help-material-en.component.html',
  styleUrls: ['help-material-en.component.scss']
})
export class HelpMaterialEnComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
