import { Component, OnInit, ElementRef } from '@angular/core';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { SuppliersService } from 'app/main/pages/suppliers/suppliers.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TitleService } from 'app/core/services/title.service';
import { TranslateService } from '@ngx-translate/core';
import { FormService } from 'app/core/services/form.service';
import { NotificationsService } from 'angular2-notifications';
import { AuthService } from 'app/core/services/auth.service';
import { EmailSendingParam } from 'app/main/model/common';
import { TextEditorEmailSendComponent } from 'app/main/shared-component/text-editor-email-send/text-editor-email-send.component';
import { MatDialog } from '@angular/material';
import { CustomersService } from 'app/main/pages/customer/customer.service';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';
import { SMSModules, SupplierRequest } from 'app/main/model/RequestBase';
import { SendSMSComponent } from 'app/main/shared-component/send-sms/send-sms.component';
import { CompanyInfoSetting } from 'app/main/model/CompanyInfo';
import { TextEditorComponent } from 'app/main/pages/text-editor/text-editor.component';
import { ApiConstant } from 'app/core/constant/ApiConstant';
import { FolderListNavigationDTO } from 'app/main/model/DropBox';
import { CustomErrorStateMatcher, spaceOnlyValidator } from 'app/core/Validators/validation';
import { CommonService } from 'app/core/services/common.service';
import { DropboxFilesService } from 'app/main/shared-component/dropbox-files/dropbox-files.service';
import { DriveModuleEnum } from '../../../../../core/enum/drive-module.enum';
import { Supplier } from '../../../../model/Supplier';


@Component({
    selector: 'app-new-supplier',
    templateUrl: './new-supplier.component.html',
    styleUrls: ['./new-supplier.component.scss'],
})

export class NewSupplierComponent implements OnInit {
    showSpinner = false;
    supplierFrom: FormGroup;
    supplierId: any = "";
    objAddressEdit: any = {};
    SupplierDetail: any = {};
    glAccountList: any = [];
    searchGlAccountList: any = [];
    public latitude: number;
    public longitude: number;
    selectedIndex: number = 0;
    //roleName: string = "";
    public editorOptions = {
        placeholder: "Notat"
    };
    // IsAdmin: boolean = false;
    fileFolderPathData: Array<FolderListNavigationDTO> = [];
    isFile: boolean = false;
    displayNoteTabRed: boolean = false;
    NoteDescription: string = "";
    tempSupId = "";
    suppliers: any[];
    SupplierList: Supplier[] = [];
    SearchSupplierList: Supplier[] = [];

    isSubmitted: boolean = false;
    esMatcher = new CustomErrorStateMatcher();
    roleClaims: any;

    constructor(private translationLoader: FuseTranslationLoaderService, private elem: ElementRef,
        private supplierService: SuppliersService,
        private _route: ActivatedRoute,
        private _router: Router,
        private formBuilder: FormBuilder,
        private titleService: TitleService,
        private translate: TranslateService,
        private formService: FormService,
        private notificationsService: NotificationsService,
        private authService: AuthService,
        private commonService: CommonService,
        public dialog: MatDialog,
        private customerService: CustomersService,
        private dropboxFilesService: DropboxFilesService,) {
        this.translationLoader.loadTranslations(english, danish);
        this.titleService.SetTitle("TITLE_SUPPLIERS");
        this.roleClaims = this.authService.getRoleClaimsList();
        try {
            var tempData = this._router.getCurrentNavigation().extras.state;
            if (tempData && tempData.moduleDataList)
                this.suppliers = tempData.moduleDataList;
        } catch (error) {
        }
    }

    ngOnInit() {
        //this.roleName = this.authService.getUserRole();
        this.supplierFrom = this.formBuilder.group({
            ID: [null],
            Business: [null, Validators.compose([Validators.required, spaceOnlyValidator.bind(this)])],
            Email: [null, Validators.compose([Validators.email, Validators.maxLength(80)])],
            ContactPerson: [null],
            Address1: [null],
            Address2: [null],
            PostNumber: [null],
            City: [null],
            Telephone: [null],
            Homepage: [null],
            Fax: [null],
            Note: [null],
            DeliveryConditions: [null],
            TermsOfPayment: [null],
            Number: [null, Validators.compose([Validators.required])],
            AppUrlLink: [],
            ChargesPercentage: [],
            EmailToSupplier: [null, Validators.compose([Validators.email, Validators.maxLength(75)])],
            EANNumber: [null],
            ExpenseAccount: [null],
            InvoiceAccount: [null],
            OurAccountNumber: [null],
            Group: [null],
            PaymentMethod: [null],
            SWIFT: [null],
            PaymentId: [null],
            RefInPayment: [null],
            PaymentFormat: [null],
            Payment: [null],
        });
        this._route.params.subscribe(params => {
            var id = params['id'];
            if (id != undefined) {
                this.tempSupId = id;
                this.getSupplierByID(id);
                this.getNotetabData(id);
            }
            else {
                this.objAddressEdit = {
                    'latitude': "",
                    'longitude': "",
                    'address': "",
                    'city': "",
                    'pinCode': "",
                    'mapLocation': "",
                    'IsEdit': true
                }
            }
        });
        this.getGLAccountList();
        //this.GetSupplierList(); 
        setTimeout(() => { this.GetSupplierList(); },100);
    }

    ngAfterViewInit() {
    }

    getSupplierByID(supplierId) {
        this.showSpinner = true;
        this.supplierService.GetSupplierById<ResponseBase>(supplierId)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.SupplierDetail = response.result;
                    this.supplierFrom.patchValue(this.SupplierDetail);
                    this.SupplierDetail.mapLocation = this.SupplierDetail.address1 ? this.SupplierDetail.address1 : "" + " " + this.SupplierDetail.city ? this.SupplierDetail.city : "" + " " + this.SupplierDetail.postNumber ? this.SupplierDetail.postNumber : "";
                    if (this.SupplierDetail.position) {
                        let latLongString = this.SupplierDetail.position.split(',')
                        this.latitude = parseFloat(latLongString[0].replace("latitude=", '').trim())
                        if (latLongString.length > 1)
                            this.longitude = parseFloat(latLongString[1].replace("longitude=", '').trim())
                    }
                    this.objAddressEdit = {
                        'latitude': this.latitude,
                        'longitude': this.longitude,
                        'address': this.SupplierDetail.address1,
                        'city': this.SupplierDetail.city,
                        'pinCode': this.SupplierDetail.postNumber,
                        'position': this.SupplierDetail.position,
                        'mapLocation': this.SupplierDetail.mapLocation,
                        'IsEdit': true
                    }
                },
                error: err => { },
                complete: () => {
                    this.showSpinner = false;
                }
            });
    }

    GetSupplierList() {
        this.showSpinner = true;
        var supplierRequest = new SupplierRequest();
        supplierRequest.Index = 1;
        supplierRequest.Limit = 10000;
        this.supplierService.Get<ResponseBase>(supplierRequest)
            .subscribe({
                next: (response: ResponseBase) => {
                    if (response) {
                        this.SupplierList = response.result;
                        this.SearchSupplierList = response.result;
                    }
                    this.showSpinner = false;
                },
                error: err => { },
                complete: () => {
                    this.showSpinner = false;
                }
            });
    }

    getGLAccountList() {
        this.showSpinner = true;
        this.supplierService.GetGLAccountList<ResponseBase>()
            .subscribe({
                next: (response: ResponseBase) => {
                    this.showSpinner = false;
                    this.glAccountList = response.result;
                    this.searchGlAccountList = response.result;
                },
                error: err => {
                    this.showSpinner = false;
                },
                complete: () => {
                    this.showSpinner = false;
                }
            });
    }

    glAccountSearch(value) {
        if (value === '') {
            this.searchGlAccountList = this.glAccountList;
        }
        this.searchGlAccountList = this.glAccountList.filter((o) => {
            if (o.name.toLowerCase().startsWith(value.toLowerCase()) || o.account.toLowerCase().startsWith(value.toLowerCase())) {
                return o;
            }
            //return (o.name.toLowerCase().startsWith(value.toLowerCase()) || o.account.toLowerCase().startsWith(value.toLowerCase()))
        })
    }

    supplierSearch(value) {
        if (value === '') {
            this.SearchSupplierList = this.SupplierList;
        }
        this.SearchSupplierList = this.SupplierList.filter((o) => {
            if (o.business.toLowerCase().startsWith(value.toLowerCase()) || o.number.toLowerCase().startsWith(value.toLowerCase())) {
                return o;
            }
            //return (o.name.toLowerCase().startsWith(value.toLowerCase()) || o.account.toLowerCase().startsWith(value.toLowerCase()))
        })
    }
    
    save() {
        this.isSubmitted = true;
        this.esMatcher.isSubmitted = this.isSubmitted;
        this.formService.markFormGroupTouched(this.supplierFrom);
        if (this.supplierFrom.valid && this.esMatcher.isValid) {
            this.SupplierDetail.invoiceAccount = this.SupplierDetail.invoiceAccount.toString();
            if (this.SupplierDetail.id === '' || this.SupplierDetail.id == undefined) {
                this.Add();
            }
            else {
                this.Update();
            }
        }
    }

    Add() {
        this.showSpinner = true;
        this.supplierService.Add<ResponseBase>(this.SupplierDetail)
            .subscribe({
                next: (response: ResponseBase) => {
                    if (response.result) {
                        this.SupplierDetail = response.result;
                        this.SupplierDetail.mapLocation = this.SupplierDetail.address1 ? this.SupplierDetail.address1 : "" + " " + this.SupplierDetail.city ? this.SupplierDetail.city : "" + " " + this.SupplierDetail.postNumber ? this.SupplierDetail.postNumber : "";
                        if (this.SupplierDetail.position) {
                            let latLongString = this.SupplierDetail.position.split(',')
                            this.latitude = parseFloat(latLongString[0].replace("latitude=", '').trim())
                            if (latLongString.length > 1)
                                this.longitude = parseFloat(latLongString[1].replace("longitude=", '').trim())
                        }
                        this.objAddressEdit = {
                            'latitude': this.latitude,
                            'longitude': this.longitude,
                            'address': this.SupplierDetail.address1,
                            'city': this.SupplierDetail.city,
                            'pinCode': this.SupplierDetail.postNumber,
                            'position': this.SupplierDetail.position,
                            'mapLocation': this.SupplierDetail.mapLocation,
                            'IsEdit': true
                        }
                    }
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.Created").subscribe({
                        next: (res) => {
                            this.notificationsService.create(res);
                        },
                        error: err => {
                        }
                    });
                },
                error: err => {
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });

                },
                complete: () => {
                    this.showSpinner = false;
                    if (this.selectedIndex == 0) {
                        this._router.navigate(['/supplier/edit', this.SupplierDetail.id]);
                    }
                }
            });
    }

    Update() {
        this.formService.markFormGroupTouched(this.supplierFrom);
        if (this.supplierFrom.valid) {
            this.showSpinner = true;
            this.supplierService.Update<ResponseBase>(this.SupplierDetail.id, this.SupplierDetail)
                .subscribe({
                    next: (response: ResponseBase) => {
                        if (response.result) {
                            this.SupplierDetail = response.result;
                        }
                        this.showSpinner = false;
                        this.translate.get("MESSAGES.Updated").subscribe({
                            next: (res) => {
                                this.notificationsService.create(res);
                            },
                            error: err => {
                            }
                        });
                    },
                    error: err => {
                        this.showSpinner = false;
                        this.translate.get("MESSAGES.Failed").subscribe({
                            next: (res) => {
                                this.notificationsService.error(res);
                            },
                            error: err => {
                            }
                        });

                    },
                    complete: () => {
                    }
                });
        }
    }

    receiveAddressObject($event) {
        this.SupplierDetail.address1 = $event.address;
        this.SupplierDetail.city = $event.city;
        this.SupplierDetail.postNumber = $event.pinCode;
        this.SupplierDetail.position = $event.position;
    }

    removeSupplier(Id) {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: '20%',
            data: { Header: 'Headers.DeleteSupplier' }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.DeleteSupplier(Id);
            }
        });
    }

    DeleteSupplier(Id) {
        this.showSpinner = true;
        this.supplierService.Delete<ResponseBase>(Id).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false
                this.translate.get("MESSAGES.Deleted").subscribe({
                    next: (res) => {
                        this.notificationsService.create(res);
                    },
                    error: err => { }
                });
            },
            error: err => {
                this.showSpinner = false
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => { }
                });
            },
            complete: () => {
                this.showSpinner = false;
                this._router.navigate(['/suppliers'])
            }
        })
    }

    sendSMS() {
        var moduleObj = new SMSModules()
        moduleObj.name = this.SupplierDetail.business;
        moduleObj.moduleID = this.SupplierDetail.id;
        moduleObj.mobile = this.SupplierDetail.telephone;
        const dialogRef = this.dialog.open(SendSMSComponent, {
            height: '350px',
            width: '800px',
            data: moduleObj
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {

            }
        })
    }

    emailCustomer(id) {
        var emailSendingParam = new EmailSendingParam();
        emailSendingParam.fromName = this.authService.getUserName();
        // emailSendingParam.from = "post@vibesmalerfirma.dk";
        emailSendingParam.to = this.SupplierDetail.email;
        emailSendingParam.moduleName = "Supplier";
        emailSendingParam.moduleID = this.SupplierDetail.id;
        emailSendingParam.isLoadDefaultEmailTemplate = true;
        const dialogRef = this.dialog.open(TextEditorEmailSendComponent, {
            height: '90%',
            width: '90%',
            data: emailSendingParam
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result != null && result != '') {
                var tempLogStatusToSendEmail = new EmailSendingParam();
                tempLogStatusToSendEmail = result;
                // var ProjectRequisitionStatusLogModel = new ProjectRequisitionStatusLog();
                // ProjectRequisitionStatusLogModel.createdBy = this.authService.getCoWorkerId();
                // ProjectRequisitionStatusLogModel.StatusLogCaseValue = ProjectRequisitionStatusLogEnum.EmailSentFromCustomer;
                // ProjectRequisitionStatusLogModel.status = LogStatusModuleName.Customer;
                // ProjectRequisitionStatusLogModel.debitorID = this.CustomerObject.id;
                // tempLogStatusToSendEmail.ProjectRequisitionStatusLogModel = ProjectRequisitionStatusLogModel;
                this.customerService.SendEmail<ResponseBase>(tempLogStatusToSendEmail)
                    .subscribe({
                        next: (response: ResponseBase) => {
                            this.showSpinner = false;
                            this.translate.get("MESSAGES.EmailSent").subscribe({
                                next: (res) => {
                                    this.notificationsService.create(res);
                                },
                                error: err => {
                                }
                            });
                            this.commonService.checkEmailIsToBeSent();
                        },
                        error: err => {
                            this.showSpinner = false;
                            this.translate.get("MESSAGES.Failed").subscribe({
                                next: (res) => {
                                    this.notificationsService.error(res);
                                },
                                error: err => {
                                }
                            });
                        },
                        complete: () => {
                        }
                    });
            }
        });
    }

    TabChange(event) {
        this.selectedIndex = event.index;
        if (event.index == 1) {
            this.storeFileFolderPathData();
        }
        if (event.index == 2)
            this.getNotetabData(this.tempSupId);
    }

    storeFileFolderPathData() {
        var rootPath = this.dropboxFilesService.getSettingMasterValueDocumentPath();
        var paths = "";
        if (rootPath.DocumentDriveType == DriveModuleEnum.Onedrive) {
            paths = rootPath.Onedrive.Path;
        } else {
            paths = rootPath.Dropbox.Path;
        }
        this.fileFolderPathData = paths.split("/").map((path, i) => ({
            name: path,
            isDisplay: false
        }));
        this.fileFolderPathData.push(
            { name: 'Suppliers', isDisplay: false },
            { name: this.SupplierDetail.number, isDisplay: true }
        );
    }
    IsFileExist(flag) {
        this.isFile = flag;
    }

    getNotetabData(id) {
        this.showSpinner = true;
        this.supplierService.GetSupplierNotes<any>(id, ApiConstant.RequisitionNote).subscribe({
            next: (response) => {
                this.showSpinner = false;
                this.showSpinner = false;
                this.NoteDescription = response.result.description;
                if (this.NoteDescription != null && this.NoteDescription.trim() != "") {
                    this.displayNoteTabRed = true;
                }
                else {
                    this.displayNoteTabRed = false;
                }
            },
            error: err => {
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            }
        });
    }


    openNotesForSupplier() {
        var selectedTabData = new CompanyInfoSetting();
        selectedTabData.Description = this.NoteDescription;
        const dialogRef = this.dialog.open(TextEditorComponent, {
            height: '90%',
            width: '90%',
            data: { selectedTabData, isEmail: false },
            disableClose: true
        });
        dialogRef.afterClosed().subscribe(result => {
            this.showSpinner = true;
            var data = new CompanyInfoSetting();
            data.Description = "";
            if (result && result.Description)
                data.Description = result.Description;
            this.supplierService.addUpdateNotesForSupplier<ResponseBase>(this.SupplierDetail.id, ApiConstant.RequisitionNote, data)
                .subscribe({
                    next: (response: ResponseBase) => {
                        this.showSpinner = false;
                        this.NoteDescription = response.result.description;
                        if (this.NoteDescription != null && this.NoteDescription.trim() != "") {
                            this.displayNoteTabRed = true;
                        }
                        else {
                            this.displayNoteTabRed = false;
                        }
                    },
                    error: err => {
                        this.showSpinner = false;
                        this.translate.get("MESSAGES.Failed").subscribe({
                            next: (res) => {
                                this.notificationsService.error(res);
                            },
                            error: err => {
                            }
                        });
                    },
                    complete: () => {
                        this.showSpinner = false;
                    }
                });
        });
    }

    gotoModule($event) {
        this.SupplierDetail = {};
        this._router.navigate(['/supplier/edit', $event]);
    }
}