import { Component, OnInit, ElementRef, NgZone, ViewChild, Input, Output, EventEmitter, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatTableDataSource, MatOption, MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { TitleService } from 'app/core/services/title.service';
import { CommonService } from 'app/core/services/common.service';
import { NotificationsService } from 'angular2-notifications';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { RequestMaterial, RequestCaseTask } from 'app/main/model/RequestBase';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { MaterialService } from 'app/main/pages/material/material.service';
import { Project, projectRequisition, ProjectRequisitionStatusLogEnum } from 'app/main/model/Project';
import { Material, QuickInvoiceMaterials } from 'app/main/model/Material';
import { ProjectService } from '../../project.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'app/core/services/auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FormService } from 'app/core/services/form.service';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';
import { CustomerProjectColorCodeComponent } from 'app/main/shared-component/customer-project-colorcode/customer-project-colorcode.component';
import { ProjectRequisitionService } from '../../project.requisition.service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { MaterialType } from 'app/constant/common.constant';
import { VibeAddReminderComponent } from 'app/main/pages/reminder/component/add/add-reminder.component';
import * as moment from 'moment';
import { AlertPlanningMessageModelComponent } from 'app/main/pages/alert-planning-message-model/alert-planning-message-model.component';
import { DenmarkDigitFormate } from 'app/core/pipes/denmarkDigitFormate.pipe';

@Component({
    selector: 'vibe-material',
    templateUrl: './material.component.html',
    styleUrls: ['./material.component.scss']
})
export class MaterialComponent implements OnInit {
    @ViewChild("barCode", { static: false }) barcodeFeild: ElementRef;
    @ViewChild("colorCode", { static: false }) colorCodeFeild: ElementRef;
    showMessage: boolean = false;
    ObjProject: Project;
    showSpinner = false;
    projectMaterials: QuickInvoiceMaterials[] = [];
    Materials: QuickInvoiceMaterials[] = [];
    tempMaterials: QuickInvoiceMaterials[] = [];
    //currentselectedMatObj: Material;
    //projectMaterialQuantity: QuickInvoiceMaterials;//ProjectMaterialQuantity;
    currentselectedMatObj: QuickInvoiceMaterials = new QuickInvoiceMaterials();
    objAddProjectMaterial: QuickInvoiceMaterials;
    Requisition: projectRequisition;
    RequisitionId: any;
    editedProjectMaterialObj: any = {};
    isEdit: boolean = false;
    editIndex: number = 0;
    //roleName: any;
    IsAddManualMaterial: boolean = true;
    //AddedMaterials: QuickInvoiceMaterials[] = [];
    AddedMaterials: any[] = [];
    StoreAddedMaterial: any;
    OnSaveAddedMaterial: any;
    ScanBarcode: string = "";
    DisplayAddedMaterial: boolean = false;
    PersonalPriceInvoiceTotal: number = 0;
    RetailPriceInvoiceTotal: number = 0;
    indoorOutdoorStatusIds: any;
    MaterialName: any = "";
    materialForm: FormGroup;
    isBarcodeValue: boolean = false;
    isSaveData: boolean = false;

    tempPersonalPrice: number = 0;
    tempRetailPrice: number = 0;
    idleState = 'Not started.';
    timedOut = false;
    lastPing?: Date = null;

    //Material calculated value stored variable
    materialTotalPrice: number = 0;
    materialPersonalTotalPrice: number = 0;
    materialTotalPriceWithVAT: number = 0;
    materialTotalPriceProfit: number = 0;
    materialTotalPriceProfitWithVAT: number = 0;
    discountPercent: number = 0;
    discount: number = 0;
    totalPriceDiscount: number = 0;
    personalTotalPriceDiscount: number = 0;
    actualTotalPrice: number = 0;
    actualTotalPriceWithVAT: number = 0;
    actualPersonalTotalPrice: number = 0;
    ReqOfferPriceExclVATPercentage: number = 0;
    purchasePriceTotal: number = 0;
    NoStockFeatures: boolean = false;
    materialType: number = 0;
    selectMaterialtype = MaterialType;
    isDuplicateEntry: boolean = false;
    isSetReminder: boolean = false;
    settingMasterValues:any=[];
    HowMuchMaxQuantityAdd: number=2000;
    isStaffPriceOrStorePurchase: boolean = false;
    isAddQuickMaterial: boolean = false;
    quickMatObj: QuickInvoiceMaterials = new QuickInvoiceMaterials();
    quickMaterialForm: FormGroup;
    PurchasePrice: number = 0;
    isUseScannerDevice: boolean = false;
    isShowPersonalPrice: boolean = false;
    warningPriceForMaxTotalPrice: number;
    public denmarkDigitFormate = new DenmarkDigitFormate();

    //Initiators
    constructor(private translationLoader: FuseTranslationLoaderService,
        private _router: Router,
        public dialogRef: MatDialogRef<MaterialComponent>,
        private dialog: MatDialog,
        private titleService: TitleService,
        private materialService: MaterialService,
        private projectService: ProjectService,
        private translate: TranslateService,
        private notificationsService: NotificationsService,
        private authService: AuthService,
        private formBuilder: FormBuilder,
        private formService: FormService,
        private projectRequisitionService: ProjectRequisitionService,
        private commonService: CommonService,
        private idle: Idle,
        private keepalive: Keepalive,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
        this.translationLoader.loadTranslations(english, danish);
        //this.setIdleTimeOut();
        //this.idleAfterOneMin();

    }

    ngOnInit() {
        this.showSpinner = true;
        this.settingMasterValues = JSON.parse(localStorage.getItem("settingMasterValues")) || [];
        const tempMaterialOtherSettingList = this.settingMasterValues.find(s => s.key1 === "Material" && s.key2 === "OtherSetting");
        if (tempMaterialOtherSettingList && tempMaterialOtherSettingList.value) {
            const sessionVal = JSON.parse(tempMaterialOtherSettingList.value);
            if (sessionVal && sessionVal.IsUseScannerDevice)
                this.isUseScannerDevice = sessionVal.IsUseScannerDevice;
            if (sessionVal && sessionVal.IsShowPersonalPrice)
                this.isShowPersonalPrice = sessionVal.IsShowPersonalPrice;
            if (sessionVal && sessionVal.WarningPriceForMaxTotalPrice)
                this.warningPriceForMaxTotalPrice = sessionVal.WarningPriceForMaxTotalPrice;
        }
        var reqModel = new RequestMaterial();
        this.GetMaterial(reqModel, true);
        this.objAddProjectMaterial = new QuickInvoiceMaterials();
        this.Requisition = new projectRequisition();
        //this.projectMaterialQuantity = new QuickInvoiceMaterials//ProjectMaterialQuantity();
        this.ObjProject = new Project();
        this.ObjProject.vat = 0;
        this.ObjProject.includePrice = 0;
        if (this.ObjProject)
            this.ObjProject.statusId = 1;
        if (this.data) {
            this.RequisitionId = this.data.RequisitionId;
            this.ObjProject.sagId = this.data.sagId;
            //this.roleName = this.data.roleName;
            this.indoorOutdoorStatusIds = this.data.indoorOutdoorStatusIds;
            this.editedProjectMaterialObj = this.data.editedProjectMaterialObj;
            this.projectMaterials = this.data.projectMaterials;
            this.ObjProject = this.data.ObjProject;
            this.Requisition = this.data.Requisition;
            this.materialTotalPrice = this.data.materialTotalPrice;
            this.materialTotalPriceWithVAT = this.data.materialTotalPriceWithVAT;
            this.materialPersonalTotalPrice = this.data.materialPersonalTotalPrice;
            this.purchasePriceTotal = this.data.purchasePriceTotal;
            this.discountPercent = this.data.Requisition.discountPercent;
            this.discount = this.data.Requisition.discount;
            if (this.editedProjectMaterialObj.materialId || this.editedProjectMaterialObj['material']) {
                this.currentselectedMatObj.materialId = this.data.editedProjectMaterialObj.materialId ? this.editedProjectMaterialObj.materialId : this.editedProjectMaterialObj.material.id;
                this.isDuplicateEntry = this.editedProjectMaterialObj.material && this.editedProjectMaterialObj.material.duplicateEntry ? this.editedProjectMaterialObj.material.duplicateEntry : false;
                this.IsAddManualMaterial = true
            }

            var staffPurchase = this.ObjProject.indoorOutdoorStatusId ? this.ObjProject.indoorOutdoorStatusId.includes("5") : "";
            if (staffPurchase) 
               this.isStaffPriceOrStorePurchase = true;
            var storedPurchase = this.ObjProject.indoorOutdoorStatusId ? this.ObjProject.indoorOutdoorStatusId.includes("6") : "";
            if (storedPurchase) 
               this.isStaffPriceOrStorePurchase = true;
        }

        // if(localStorage.getItem("settingMasterValues")){
        //     this.settingMasterValues=JSON.parse(localStorage.getItem("settingMasterValues"));
        //     if(this.settingMasterValues && this.settingMasterValues.length>0){
        //         var sessionVal;
        //         try {
        //             sessionVal = parseInt(this.settingMasterValues.find(s => s.key1=="Material" && s.key2=="HowMuchMaxQuantityAdd")['value']);
        //         } catch { }
        //         if(sessionVal)
        //             this.HowMuchMaxQuantityAdd = sessionVal;
        //     }
        // }

        // if (this.roleName == "Admin") {
            this.materialForm = this.formBuilder.group({
                Text: [null],
                ColorCode: [null],
                quantity: [null, 
                    Validators.compose([
                        Validators.required, 
                        Validators.min(0.01),
                        Validators.max(999999999)
                    ])
                ],
                retailPrice: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(999999999)]],
                personalPrice: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(9999999999)]],
                discountPercent: [0],
                discount: [0],
                noStockTraek: [false],
                imagePath: [null],
                PurchasePrice: [0],
            });
        // } else {
        //     this.materialForm = this.formBuilder.group({
        //         Text: [null],
        //         ColorCode: [null, ''],
        //         quantity: [null, 
        //             Validators.compose([
        //                 Validators.required, 
        //                 Validators.min(0.01),
        //                 Validators.max(999999999)
        //             ])
        //         ],
        //         retailPrice: [null],
        //         personalPrice: [null],
        //         discountPercent: [0],
        //         discount: [0],
        //         noStockTraek: [false],
        //         imagePath: [null]
        //     });
        // }

        //this.refreshMaterialList();
        this.quickMaterialForm = this.formBuilder.group({
            text: [null,Validators.required],
            retailPrice: [null],
            purchasePrice: [null],
            barcode: [null],
            quantity: [null, 
                Validators.compose([
                    Validators.required,
                    Validators.min(0.01),
                    Validators.max(999999999)
                ])
            ],});


        if (this.editedProjectMaterialObj['text']) {
            this.isEdit = true;
            this.currentselectedMatObj.materialId = this.editedProjectMaterialObj.materialId ? this.editedProjectMaterialObj.materialId : this.editedProjectMaterialObj.material.id,
                setTimeout(() => {
                    this.currentselectedMatObj.id = this.editedProjectMaterialObj.id;
                    this.currentselectedMatObj.itemNumber = this.editedProjectMaterialObj.itemNumber;
                    this.currentselectedMatObj.isRequiredText = this.editedProjectMaterialObj.material.isRequiredText;
                    this.currentselectedMatObj.text = this.editedProjectMaterialObj.text;
                    this.currentselectedMatObj.unit = this.editedProjectMaterialObj.material.unit;
                    this.currentselectedMatObj.colorCode = this.editedProjectMaterialObj.colorCode;
                    this.currentselectedMatObj.quantity = this.editedProjectMaterialObj.quantity;
                    this.currentselectedMatObj.personalPrice = this.editedProjectMaterialObj.personalPrice;
                    this.currentselectedMatObj.retailPrice = this.editedProjectMaterialObj.retailPrice;
                    this.currentselectedMatObj.personalPriceDiscount = this.editedProjectMaterialObj.personalPriceDiscount;
                    this.currentselectedMatObj.retailPriceDiscount = this.editedProjectMaterialObj.retailPriceDiscount;
                    this.currentselectedMatObj.discountPercent = this.editedProjectMaterialObj.discountPercent;
                    this.currentselectedMatObj.noStockTraek = this.editedProjectMaterialObj.noStockTraek;
                    this.currentselectedMatObj.purchasePrice = this.editedProjectMaterialObj.purchasePrice;
                }, 900);
        }
        if (this.projectMaterials.length > 0) {
            this.projectMaterials.forEach(o => {
                o.isRequiredText = (o.isRequiredText!=undefined && o.isRequiredText!=null) ? o.isRequiredText : o.material.isRequiredText;
                o.unit = (o.unit!=undefined && o.unit!=null) ? o.unit : o.material.unit;
            });
            this.AddedMaterials = this.projectMaterials;
            this.OnSaveAddedMaterial = JSON.stringify(this.AddedMaterials);
            if (this.editedProjectMaterialObj.id) {
                var findMat = this.AddedMaterials.find(o => o.id == this.editedProjectMaterialObj.id);
                this.currentselectedMatObj = findMat;
            }
            const editFirstMaterial = this.AddedMaterials.filter(x=>x.isRequiredText==true && !x.colorCode);
            if(!this.editedProjectMaterialObj.id && editFirstMaterial && editFirstMaterial.length>0) {
                this.currentselectedMatObj = editFirstMaterial[0];
                this.isEdit =true;
                this.IsAddManualMaterial = true;
                setTimeout(() => {
                    this.colorCodeFeild.nativeElement.focus();
                }, 1000);
            }
            this.showMessage = this.projectMaterials.length > 0 ? false : true;
        }
        if (this.projectMaterials.length == 0 && this.AddedMaterials.length == 0) {
            this.showMessage = true;
        }
        this.showSpinner = false;

        //this.getPersonalTotalProfit();
        //this.getRetailTotalProfit();
        this.calDiscount();
        if (!this.Requisition.materialCreation || this.Requisition.materialCreation <= 0) {
            this.calMaterialCreation();
        }
    }

    /**
     * Get Materila List START
     */
    GetMaterial(model, displayLoader) {
        this.showSpinner = displayLoader;
        model.Limit = 400;
        model.OrderBy = "Text";
        this.materialService.Get<ResponseBase>(model)
            .subscribe((response: ResponseBase) => {
                if (response) {
                    this.showSpinner = false;
                    this.bindMaterialDropdownOnEdit();
                    this.getNextRequestForMaterial(response.pagination, model, response.result);
                    setTimeout(() => {
                        if(this.barcodeFeild && this.barcodeFeild.nativeElement)
                            this.barcodeFeild.nativeElement.focus();
                    });
                }
                else {
                    this.showSpinner = false;
                    this.Materials = [];
                    this.tempMaterials = [];
                }
            },
                error => (err) => {
                    this.showSpinner = false;
                    this.Materials = [];
                    this.tempMaterials = [];
                },
                () => {
                });
    }

    DropDownSearchMaterial(MaterialName) {
        // var request = new RequestMaterial();
        // request.Text = MaterialName;
        // request.IsActive = true;
        // request.MaterialType = this.materialType;
        // this.GetMaterial(request, false);
        if (this.materialType > 0) {
            this.Materials = this.tempMaterials.filter(o => (
                o.text.toLowerCase().includes(MaterialName.toLowerCase())
                || o.itemNumber.toLowerCase().includes(MaterialName.toLowerCase()))
                && o.materialType
                && o.materialType
                && o.materialType.split(',').find(p => p == (this.materialType.toString()))
            );
        } else {
            this.Materials = this.tempMaterials.filter(o => (
                o.text.toLowerCase().includes(MaterialName.toLowerCase())
                || o.itemNumber.toLowerCase().includes(MaterialName.toLowerCase())
            ));
        }
    }

    GetMaterialOnClear(value) {
        if (!value) {
            // var request = new RequestMaterial();
            // request.IsActive = true;
            // this.GetMaterial(request, false);
            if (this.materialType > 0) {
                this.Materials = this.tempMaterials.filter(x => x.materialType && x.materialType.split(',').find(p => p == (this.materialType.toString())));
            } else {
                this.Materials = this.tempMaterials;
            }
        }
    }

    getNextRequestForMaterial(pagination, model, response) {
        if (pagination.index == 1) {
            this.Materials = response;
            this.tempMaterials = response;
        }
        else {
            this.Materials.push(...response);
            this.tempMaterials = this.Materials;
        }
        localStorage.setItem('Materials', JSON.stringify(this.Materials));
        model.Index = pagination.index + 1;
        model.Limit = pagination.limit;
        model.TotalRows = pagination.totalCount;
        if (pagination.totalCount > pagination.index * pagination.limit)
            this.GetMaterial(model, false);
    }

    refreshMaterialList() {
        let objMaterialProject = new RequestCaseTask();
        objMaterialProject.fileAssignmentId = this.RequisitionId;
        objMaterialProject.IsDeleted = false;
        this.getProjectMaterialList(objMaterialProject);
    }

    getProjectMaterialList(modal) {
        this.showSpinner = true;
        this.projectService.GetProjectMaterialList<ResponseBase>(modal)
            .subscribe((response: ResponseBase) => {
                if (response) {
                    this.showSpinner = false;
                    this.AddedMaterials = response.result;
                    this.OnSaveAddedMaterial = JSON.stringify(this.AddedMaterials);
                    this.materialTotalPrice = 0;
                    this.materialTotalPriceWithVAT = 0;
                    this.materialPersonalTotalPrice = 0;
                    this.calculateProjMaterialPriceTotal();
                    this.AddedMaterials.filter(p => { p.quantity = parseFloat(p.quantity.toFixed(2)); });

                    this.showMessage = this.AddedMaterials.length > 0 ? false : true;
                }
                else {
                    this.showMessage = true;
                }
            },
                error => (err) => {
                    this.showSpinner = false;
                },
                () => {
                    this.showSpinner = false;
                });
    }

    //Edit Material
    editProjectMaterial(index) {
        this.editIndex = index;
        this.isEdit = true;
        this.IsAddManualMaterial = true;

        var editedMaterial = this.AddedMaterials[index];
        if (editedMaterial) {
            this.currentselectedMatObj = editedMaterial;
            this.currentselectedMatObj.materialId = editedMaterial.material.id;
            this.currentselectedMatObj.retailPrice = editedMaterial.retailPrice;
            this.currentselectedMatObj.personalPrice = editedMaterial.personalPrice;
            this.currentselectedMatObj.noStockTraek = editedMaterial.noStockTraek ? editedMaterial.noStockTraek : false;
            this.currentselectedMatObj.imagePath = editedMaterial.material.imagePath;
            this.currentselectedMatObj.purchasePrice = editedMaterial.purchasePrice ? editedMaterial.purchasePrice : editedMaterial.material.purchasePrice;
            this.isDuplicateEntry = editedMaterial.material && editedMaterial.material.duplicateEntry ? editedMaterial.material.duplicateEntry : false;
        }
    }

    onChangeMaterial(matID) {
        this.isEdit = false;
        this.showSpinner = true;
        this.currentselectedMatObj = new QuickInvoiceMaterials();
        //this.materialForm.reset();
        var material = this.Materials.find(o => o.id == matID);
        if (material) {
            var matCopy = Object.assign({}, material);
            this.currentselectedMatObj = matCopy;
            this.currentselectedMatObj.materialId = material.id;
            this.currentselectedMatObj.id = "";
            this.currentselectedMatObj.unit = matCopy.unit;
            this.currentselectedMatObj.retailPrice = matCopy.retailPrice;
            this.currentselectedMatObj.personalPrice = matCopy.personalPrice;
            this.currentselectedMatObj.quantity = matCopy.purchaseQuantity;
            this.currentselectedMatObj.itemNumber = matCopy.itemNumber;
            this.currentselectedMatObj.colorCode = matCopy.colorCode;
            this.currentselectedMatObj.discountPercent = 0;
            this.currentselectedMatObj.noStockTraek = matCopy.noStockTraek;
            this.currentselectedMatObj.imagePath = matCopy.imagePath;            
            this.isDuplicateEntry = matCopy.duplicateEntry == undefined ? false: matCopy.duplicateEntry;
            if (matCopy.noStockFeatures == 'T') {
                this.currentselectedMatObj.noStockTraek = true;
            } else {
                this.currentselectedMatObj.noStockTraek = false;
            }
            var statusIsOffer = (matCopy.materialType.split(',')).find(o => o == "22");
            if(statusIsOffer)
               this.currentselectedMatObj.noStockTraek = false;
            this.materialForm.patchValue(this.currentselectedMatObj);

        }
        else {
            this.translate.get("MESSAGES.NoMaterialFound").subscribe({
                next: (res) => {
                    this.notificationsService.error(res);
                    this.getFailedTone();
                },
                error: err => {
                }
            });
        }
        this.showSpinner = false;
    }

    /**
     * Add Material using Scanner START
     */
     AddMaterialUsingScanner() {
        this.IsAddManualMaterial = false;
        this.materialType = 0;
        this.Materials = this.tempMaterials;
        this.materialForm.reset();
        this.currentselectedMatObj = new QuickInvoiceMaterials();
        this.currentselectedMatObj.id = "";
        this.currentselectedMatObj.quantity = 0.00
        setTimeout(() => {
            if(this.barcodeFeild && this.barcodeFeild.nativeElement)
                this.barcodeFeild.nativeElement.focus();
        }, 500);
    }

    BarcodeAddMaterialToList(event) {
        if(this.Requisition && this.Requisition.status == 1)
        {
            this.translate.get("MESSAGES.CanNotAddMaterial").subscribe({
                next: (res) => {
                    this.notificationsService.error(res);
                    this.getFailedTone();
                },
                error: err => {
                }
            });
            this.ScanBarcode="";
            if(this.barcodeFeild && this.barcodeFeild.nativeElement)
                this.barcodeFeild.nativeElement.value = "";
            return;
        }
        this.isBarcodeValue = true;
        // var that=this;
        setTimeout(() => {
            if (this.ScanBarcode) {
                var barcode = this.ScanBarcode.trim();
                var findMaterial = this.Materials.find(o => o.barcode == barcode || o.barcode2 == barcode);
                if (!findMaterial) {
                    this.translate.get("MESSAGES.NoBarcodeAttachedToMaterial").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                            this.getFailedTone();
                            return
                        },
                        error: err => {
                        }
                    });                    
                }
                else {
                    this.isDuplicateEntry = findMaterial.duplicateEntry == undefined ? false: findMaterial.duplicateEntry;
                    var addedMaterial = !this.isDuplicateEntry ? this.AddedMaterials.find(o =>
                        o.itemNumber == findMaterial.itemNumber
                        && o.colorCode == findMaterial.colorCode) : null;
                    if (addedMaterial) {
                        addedMaterial.quantity = addedMaterial.quantity + findMaterial.purchaseQuantity;
                        addedMaterial.totalPrice = addedMaterial.quantity * addedMaterial.retailPrice;
                        addedMaterial.personalPriceTotal = addedMaterial.quantity * addedMaterial.personalPrice;
                        addedMaterial.totalPriceVAT = (((addedMaterial.totalPrice * 25) / 100) + addedMaterial.totalPrice);
                        addedMaterial.isChanges = true;
                        this.currentselectedMatObj = addedMaterial;
                        const index: number = this.AddedMaterials.indexOf(addedMaterial);
                        this.AddedMaterials.splice(index, 1);
                        this.AddedMaterials.unshift(addedMaterial);
                        this.currentselectedMatObj = new QuickInvoiceMaterials();
                        this.materialForm.reset();
                    }
                    else {
                        if (findMaterial && findMaterial.isRequiredText){
                            this.AddMaterialManual();
                            this.ScanBarcode = "";
                            this.onChangeMaterial(findMaterial.id);
                            setTimeout(() => {
                                this.colorCodeFeild.nativeElement.focus();
                            });
                            return;
                        }
                        var copyMaterial = Object.assign({}, findMaterial);
                        this.currentselectedMatObj = new QuickInvoiceMaterials();
                        this.objAddProjectMaterial.materialId = copyMaterial.id;
                        this.objAddProjectMaterial.createdDate = new Date();
                        this.objAddProjectMaterial.text = copyMaterial.text;
                        //this.objAddProjectMaterial.colorCode = copyMaterial.colorCode;
                        this.objAddProjectMaterial.sagOpgaveId = this.RequisitionId;
                        this.objAddProjectMaterial.itemNumber = copyMaterial.itemNumber;
                        this.objAddProjectMaterial.colorCode = copyMaterial.colorCode;
                        this.objAddProjectMaterial.personalPrice = copyMaterial.personalPrice;
                        this.objAddProjectMaterial.retailPrice = copyMaterial.retailPrice;
                        this.objAddProjectMaterial.price = copyMaterial.retailPrice;
                        this.objAddProjectMaterial.quantity = copyMaterial.purchaseQuantity;
                        this.objAddProjectMaterial.discountPercent = copyMaterial.discountPercent;
                        this.objAddProjectMaterial.personalPriceDiscount = copyMaterial.personalPriceDiscount;
                        this.objAddProjectMaterial.retailPriceDiscount = copyMaterial.retailPriceDiscount;
                        this.objAddProjectMaterial.totalPrice = this.getTotalPrice(this.objAddProjectMaterial.quantity, this.objAddProjectMaterial.retailPrice);
                        this.objAddProjectMaterial.personalPriceTotal = this.getTotalPrice(this.objAddProjectMaterial.quantity, this.objAddProjectMaterial.personalPrice);
                        this.objAddProjectMaterial.totalPriceVAT = (((this.objAddProjectMaterial.totalPrice * 25) / 100) + this.objAddProjectMaterial.totalPrice);
                        this.objAddProjectMaterial.material = copyMaterial;
                        this.objAddProjectMaterial.isDuplicateEntry = this.isDuplicateEntry;

                        var copyObj = Object.assign({}, this.objAddProjectMaterial);
                        this.AddedMaterials.push(copyObj);
                        this.currentselectedMatObj = new QuickInvoiceMaterials();
                        this.materialForm.reset();
                        //this.projectMaterialQuantity = copyMaterial;
                        //this.projectMaterialQuantity.id = "";
                    }
                    this.isDuplicateEntry = false;
                    this.calculateProjMaterialPriceTotal();
                    this.getSuccessTone();
                }
                this.ScanBarcode = "";
                this.calDiscount();
            }
        }, 500);
    }
    //END


    /**
     * Add Material Manual START
     */
    AddMaterialManual() {
        this.IsAddManualMaterial = true;
    }

    AddToMaterialListManuallynew(isClosePopup) {
        if (this.IsAddManualMaterial) {
            this.AddToMaterialListManually(isClosePopup);
        }
        else {
            this.AddToMaterialListBarcode(isClosePopup);
        }
    }
    AddToMaterialListBarcode(isClosePopup) {        
        var isSave = true;
        for (let mat of this.AddedMaterials) {
            if (mat.material && mat.material.isRequiredText == true
                && (mat.colorCode == null || mat.colorCode == undefined || mat.colorCode == "")) {
                isSave = false;
                this.translate.get("MESSAGES.AddColorCode").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                        this.getFailedTone();
                    },
                    error: err => {
                    }
                });
                break;
            }
        }
        if (isSave) {
            this.MaterialSave(isClosePopup);
        }
    }
    async AddToMaterialListManually(isClosePopup) {
        if(this.Requisition && (this.Requisition.status != 8 && this.Requisition.status != 9 && this.Requisition.status != 12 ))
        {
            this.translate.get("MESSAGES.CanNotAddMaterial").subscribe({
                next: (res) => {
                    this.notificationsService.error(res);
                    this.getFailedTone();
                },
                error: err => {
                }
            });
            return;
        }
        var isSave = true;
        const materialColorcodeControl = this.materialForm.get('ColorCode');
        var materialtemp = this.Materials.find(o => o.itemNumber == this.currentselectedMatObj.itemNumber);
        if (materialtemp && materialtemp.isRequiredText) {
            materialColorcodeControl.setValidators([Validators.required]);
        } else {
            materialColorcodeControl.setValidators(null);
        }
        materialColorcodeControl.updateValueAndValidity();
        this.formService.markFormGroupTouched(this.materialForm);
        isSave = this.materialForm.valid;

        //check already exists
        var matObj = !this.isDuplicateEntry ? this.AddedMaterials.find(o => (o.itemNumber == this.currentselectedMatObj.itemNumber)
            && (o.colorCode == this.currentselectedMatObj.colorCode)) : null;
        if (this.isEdit == true && !this.isDuplicateEntry) {
            this.editIndex = this.AddedMaterials.findIndex(o => o.itemNumber == this.currentselectedMatObj.itemNumber
                && o.colorCode == this.currentselectedMatObj.colorCode);
            var materialObj = Object.assign([], this.AddedMaterials);
            materialObj.splice(this.editIndex, 1);
            matObj = materialObj.find(o => o.itemNumber == this.currentselectedMatObj.itemNumber
                && o.colorCode == this.currentselectedMatObj.colorCode);
        }
        else {
            matObj = !this.isDuplicateEntry ? this.AddedMaterials.find(o => (o.itemNumber == this.currentselectedMatObj.itemNumber)
                && (o.colorCode == this.currentselectedMatObj.colorCode)) : null;
        }
        if (matObj) {
            this.translate.get("MESSAGES.MaterialAlreadyExists").subscribe({
                next: (res) => {
                    this.notificationsService.error(res);
                    this.getFailedTone();
                },
                error: err => {
                }
            });
            return;
        }

        if(this.currentselectedMatObj.maxOrderNumber && (this.currentselectedMatObj.quantity > this.currentselectedMatObj.maxOrderNumber)) {
            let msg:string='';
            this.translate.get("VALIDATIONS.MaxQuantityAddMsg1").subscribe({
                next: (res) => { msg = res.replace("@qty",this.currentselectedMatObj.quantity+" "+ this.currentselectedMatObj.unit); }, error: err => { }
            });
            const dialogRef = this.dialog.open(AlertMessageModelComponent, { 
                height: '200px',
                data: { Header: msg },
            });

            await dialogRef.afterClosed()
                .toPromise()
                .then(result => { 
                    if(result==false) {
                        return Promise.reject();
                    } else {
                        return Promise.resolve();
                    }
                });
        }

        if(this.warningPriceForMaxTotalPrice && ((this.currentselectedMatObj.quantity * this.currentselectedMatObj.retailPrice) > this.warningPriceForMaxTotalPrice)) {
            let msg:string='';
            const maxPrice = this.denmarkDigitFormate.transform(this.warningPriceForMaxTotalPrice.toString());
            this.translate.get("VALIDATIONS.WarningMessageForMaxTotalPrice").subscribe({
                next: (res) => { msg = res.replace("@maxPrice", maxPrice)}, error: err => { }
            });
            const dialogRef = this.dialog.open(AlertMessageModelComponent, { 
                height: '200px',
                data: { Header: msg },
            });

            await dialogRef.afterClosed()
                .toPromise()
                .then(result => { 
                    if(result==false) {
                        return Promise.reject();
                    } else {
                        return Promise.resolve();
                    }
                });
        }

        //add new material
        if (isSave && !this.currentselectedMatObj.id) {
            //var matObj = this.AddedMaterials.find(o => (o.itemNumber == this.currentselectedMatObj.itemNumber)
            //  && (o.colorCode == this.currentselectedMatObj.colorCode));
            //if (this.isEdit == true) {
            //  var materialObj = Object.assign([], this.AddedMaterials);
            //  materialObj.splice(this.editIndex, 1);
            //  matObj = materialObj.find(o => o.itemNumber == this.currentselectedMatObj.itemNumber
            //    && o.colorCode == this.currentselectedMatObj.colorCode);
            //}
            //else {
            //  matObj = this.AddedMaterials.find(o => (o.itemNumber == this.currentselectedMatObj.itemNumber)
            //    && (o.colorCode == this.currentselectedMatObj.colorCode));
            //}
            //if (matObj) {
            //  this.translate.get("MESSAGES.MaterialAlreadyExists").subscribe({
            //    next: (res) => {
            //      this.notificationsService.error(res);
            //      this.getFailedTone();
            //    },
            //    error: err => {
            //    }
            //  });
            //  return;
            //}
            var material = this.Materials.find(o => o.itemNumber == this.currentselectedMatObj.itemNumber);
            var copyMaterial = Object.assign({}, material);
            this.currentselectedMatObj.material = copyMaterial;
            this.currentselectedMatObj.materialId = copyMaterial.id;
            this.currentselectedMatObj.createdDate = new Date();
            this.currentselectedMatObj.sagOpgaveId = this.RequisitionId;
            this.currentselectedMatObj.price = copyMaterial.retailPrice;
            this.currentselectedMatObj.totalPrice = this.getTotalPrice(this.currentselectedMatObj.quantity, this.currentselectedMatObj.retailPrice);
            this.currentselectedMatObj.personalPriceTotal = this.getTotalPrice(this.currentselectedMatObj.quantity, this.currentselectedMatObj.personalPrice);
            this.currentselectedMatObj.totalPriceVAT = (((this.currentselectedMatObj.totalPrice * 25) / 100) + this.currentselectedMatObj.totalPrice);
            if (this.isEdit == false) {
                //var material = this.Materials.find(o => o.itemNumber == this.currentselectedMatObj.itemNumber);
                //var copyMaterial = Object.assign({}, material);
                //this.currentselectedMatObj.material = copyMaterial;
                //this.currentselectedMatObj.materialId = copyMaterial.id;
                //this.currentselectedMatObj.createdDate = new Date();
                //this.currentselectedMatObj.sagOpgaveId = this.RequisitionId;
                //this.currentselectedMatObj.price = copyMaterial.retailPrice;
                //this.currentselectedMatObj.totalPrice = this.getTotalPrice(this.currentselectedMatObj.quantity, this.currentselectedMatObj.retailPrice);
                //this.currentselectedMatObj.personalPriceTotal = this.getTotalPrice(this.currentselectedMatObj.quantity, this.currentselectedMatObj.personalPrice);
                //this.currentselectedMatObj.totalPriceVAT = (((this.currentselectedMatObj.totalPrice * 25) / 100) + this.currentselectedMatObj.totalPrice);
                this.currentselectedMatObj.isDuplicateEntry = this.isDuplicateEntry;
                this.isDuplicateEntry = copyMaterial.duplicateEntry; 
                this.AddedMaterials.push(this.currentselectedMatObj);
                this.OnSaveAddedMaterial = JSON.stringify(this.AddedMaterials);
            }
        }
        else {
            var mat = this.AddedMaterials.find(o => o.id == this.currentselectedMatObj.id);
            if (mat) {
                //mat.price = copyMaterial.retailPrice;
                mat.totalPrice = this.getTotalPrice(this.currentselectedMatObj.quantity, this.currentselectedMatObj.retailPrice.toFixed(2));
                mat.personalPriceTotal = this.getTotalPrice(this.currentselectedMatObj.quantity, this.currentselectedMatObj.personalPrice.toFixed(2));
                mat.totalPriceVAT = (((this.currentselectedMatObj.totalPrice * 25) / 100) + this.currentselectedMatObj.totalPrice);
                mat.isChanges = true;
            }
        }
        this.AddedMaterials.forEach(i => {
            if (i.material && i.material.isRequiredText) {
                i.isRequiredText = i.material.isRequiredText;
            }
            if (i.material && i.material.unit) {
                i.unit = i.material.unit;
            }
        });
        this.OnSaveAddedMaterial = JSON.stringify(this.AddedMaterials);
        if (isSave) {
            this.MaterialSave(isClosePopup);
        }
    }

    MaterialSave(isClosePopup) {
        this.showSpinner = true;
        for (var i = 0; i < this.AddedMaterials.length; i++) {
            if (this.AddedMaterials[i].personalPrisTotal) {
                this.AddedMaterials[i].personalPrisTotal = this.AddedMaterials[i].personalPriceTotal;
            }
        }
        this.projectService.addOrUpdateMaterialToProjectWithFlag<ResponseBase>(this.AddedMaterials)
            .subscribe((response: ResponseBase) => {
                this.isDuplicateEntry = false;
                this.materialForm.reset();
                this.currentselectedMatObj = new QuickInvoiceMaterials();
                this.AddedMaterials = response.result;
                this.AddedMaterials.forEach(i => {
                    if (i.material && i.material.isRequiredText) {
                        i.isRequiredText = i.material.isRequiredText;
                    }
                    if (i.material && i.material.unit) {
                        i.unit = i.material.unit;
                    }
                });
                this.OnSaveAddedMaterial = JSON.stringify(this.AddedMaterials);
                this.calDiscount();
                this.calMaterialCreation();
                this.translate.get("MESSAGES.Created").subscribe({
                    next: (res) => {
                        this.notificationsService.create(res);
                    },
                    error: err => {
                    }
                });
            },
                error => (err) => {
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                            this.getFailedTone();
                        },
                        error: err => {
                        }
                    });
                }, () => {
                    this.showSpinner = false;
                    if (isClosePopup) {
                        this.dialogRef.close(this.Requisition);
                    }
                });
    }

    calculateProjMaterialPriceTotal() {
        this.materialTotalPriceWithVAT = 0;
        this.materialPersonalTotalPrice = 0;
        this.materialTotalPrice = 0;
        this.purchasePriceTotal = 0;
        this.AddedMaterials.forEach((e: any) => {
            if (!e.totalPrice) {
                e.totalPrice = this.getTotalPrice((e.quantity ? e.quantity : 0), e.retailPrice);
            }
            if (!e.personalPriceTotal) {
                e.personalPriceTotal = this.getTotalPrice((e.quantity ? e.quantity : 0), e.personalPrice);
            }
            this.materialTotalPrice += e.totalPrice;
            if (e.personalPrice && e.personalPrice != 0) {
                this.materialPersonalTotalPrice += e.personalPriceTotal;
            }
            else {
                e.personalPrice = 0;
            }

            if (e.totalPriceVAT) {
                this.materialTotalPriceWithVAT += e.totalPriceVAT;
            } else {
                var vat = (e.totalPrice * 25) / 100;
                this.materialTotalPriceWithVAT += (e.totalPrice + vat);
            }
            if (e.purchasePrice) {
                this.purchasePriceTotal += e.purchasePrice;
            }
        });
        this.calMaterialCreation();
        this.calDiscount();
    }

    getTotalPrice(qty, price) {
        var isNegative = false;
        if (qty < 0)
            isNegative = true;
        if (price < 0)
            isNegative = true;
        var total = (qty * price);
        if (isNegative)
            total = -Math.abs(total)
        return total;
    }

    calSingleMaterialDiscount(discountPercent) {
        var material = this.Materials.find(o => o.id == this.currentselectedMatObj.materialId);
        if (material) {
            this.currentselectedMatObj.discountPercent = discountPercent;
            //this.currentselectedMatObj.retailPrice = material.retailPrice;
            //this.currentselectedMatObj.personalPrice = material.personalPrice;
            if (discountPercent) {
                this.currentselectedMatObj.personalPriceDiscount = (this.currentselectedMatObj.personalPrice * discountPercent) / 100;;
                this.currentselectedMatObj.personalPrice = this.currentselectedMatObj.personalPrice - this.currentselectedMatObj.personalPriceDiscount;

                this.currentselectedMatObj.retailPriceDiscount = (this.currentselectedMatObj.retailPrice * discountPercent) / 100;
                this.currentselectedMatObj.retailPrice = this.currentselectedMatObj.retailPrice - this.currentselectedMatObj.retailPriceDiscount;
            } else {
                this.currentselectedMatObj.personalPriceDiscount = 0;
                this.currentselectedMatObj.retailPriceDiscount = 0;
                // this.currentselectedMatObj.retailPrice = material.retailPrice;
                // this.currentselectedMatObj.personalPrice = material.personalPrice;
            }
        }
        this.calDiscount();
    }

    calDiscount() {
        this.materialTotalPrice = 0;
        this.materialPersonalTotalPrice = 0;
        this.totalPriceDiscount = 0;
        this.personalTotalPriceDiscount = 0;
        this.purchasePriceTotal = 0;
        this.AddedMaterials.filter(o => {
            this.materialTotalPrice = this.materialTotalPrice + (o.retailPrice * o.quantity);
            this.materialPersonalTotalPrice = this.materialPersonalTotalPrice + (o.personalPrice * o.quantity);
            this.purchasePriceTotal += o.purchasePrice;
        });
        if (this.discountPercent && this.discountPercent > 0) {
            this.totalPriceDiscount = (this.materialTotalPrice * this.discountPercent) / 100;
            this.personalTotalPriceDiscount = (this.materialPersonalTotalPrice * this.discountPercent) / 100;
        }
        if (this.discount && this.discount > 0) {
            this.totalPriceDiscount = this.discount;
            this.personalTotalPriceDiscount = this.discount;
        }
        this.actualTotalPrice = this.materialTotalPrice - this.totalPriceDiscount;
        this.actualPersonalTotalPrice = this.materialPersonalTotalPrice - this.personalTotalPriceDiscount;
        this.actualTotalPriceWithVAT = this.actualTotalPrice + ((this.actualTotalPrice * 25) / 100);
        this.calMaterialTotalPriceProfit();
        this.calMaterialTotalPriceProfitWithVAT();

    }

    calMaterialTotalPriceProfitWithVAT() {
        var totalIncludeEstimatePrice = this.Requisition.totalIncludeEstimatePrice;
        var materialTotalPriceWithVAT = this.actualTotalPriceWithVAT;
        var isNegative = false;
        var total = 0;
        this.Requisition.isNegativeTotalProfit = false;
        if (materialTotalPriceWithVAT < 0) {
            total = (totalIncludeEstimatePrice - Math.abs(materialTotalPriceWithVAT));
        }
        else {
            total = (totalIncludeEstimatePrice - materialTotalPriceWithVAT);
        }
        if (total < 0) {
            isNegative = true;
            this.Requisition.isNegativeTotalProfit = true;
        }
        if (isNegative)
            total = -Math.abs(total)
        this.materialTotalPriceProfitWithVAT = total;
    }

    calMaterialTotalPriceProfit() {
        var totalIncludeEstimatePrice = this.Requisition.totalIncludeEstimatePrice;
        var totalVatEstimatePrice = this.Requisition.totalVatEstimatePrice;
        var materialTotalPrice = this.actualTotalPrice;
        var isNegative = false;
        var total = 0;
        if (materialTotalPrice < 0) {
            total = ((totalIncludeEstimatePrice - totalVatEstimatePrice) - Math.abs(materialTotalPrice));
        }
        else {
            total = ((totalIncludeEstimatePrice - totalVatEstimatePrice) - materialTotalPrice);
        }
        if (total < 0)
            isNegative = true;
        if (isNegative)
            total = -Math.abs(total)
        this.materialTotalPriceProfit = total;
        this.calculateOfferPriceExclVAT();
    }

    UpdateRequisitionStatus() {
        this.Requisition.discountPercent = this.discountPercent;
        this.Requisition.discount = this.discount;
        this.Requisition.performedPercent = this.ReqOfferPriceExclVATPercentage;
        this.projectRequisitionService.Update(this.ObjProject.sagId, this.RequisitionId, this.Requisition).subscribe({
            next: (response: ResponseBase) => {
                if (response) {
                    this.translate.get("MESSAGES.Updated").subscribe({
                        next: (res) => {
                            this.notificationsService.create(res);
                        },
                        error: err => {
                        }
                    });
                }
            },
            error: err => {
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            }
        });
    }

    calculateOfferPriceExclVAT() {
        var PriceIncluded = this.actualTotalPrice;
        var tempOfferPriceexcluded = this.Requisition.totalIncludeEstimatePrice - this.Requisition.totalVatEstimatePrice;
        this.ReqOfferPriceExclVATPercentage = ((PriceIncluded / tempOfferPriceexcluded) * 100);
    }

    openMaterialDeleteDialog(index, materialobj) {
        if(!(materialobj.itemNumber && materialobj.id)){
            this.AddedMaterials.splice(index, 1);
            return;
        }
        if(materialobj.noStockTraek) {
            var deletedMat = this.AddedMaterials[index];
            if (deletedMat && deletedMat.id) 
                this.removeProjectMaterial(deletedMat.id, false);
            else 
                this.AddedMaterials.splice(index, 1);
        }
        else {
            var message = "";
            var materialText = materialobj.text ? materialobj.text : materialobj.material.text;
            this.translate.get("Headers.DeleteMaterial").subscribe({
                next: (res) => { message = res; }, error: err => { }
            });
            message = message.replace("@MaterialName", materialText)
            // const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            //     height: '200px',
            //     //data: { Header: 'Headers.DeleteMaterial' }
            //     data: { Header: message }
            // });

            // dialogRef.afterClosed().subscribe(result => {
            //     if (result) {
            //         var deletedMat = this.AddedMaterials[index];
            //         if (deletedMat && deletedMat.id) {
            //             //this.removeProjectMaterial(deletedMat.id);
            //             this.upDownMaterialQuantityDialog("material", deletedMat.id)
            //         }
            //         else {
            //             this.AddedMaterials.splice(index, 1);
            //         }
            //     }
            // });

            const dialogRef = this.dialog.open(AlertPlanningMessageModelComponent, {
                width: '40%',
                data: {
                    Header: message,
                    FirstButtonLabel: "Headers.MaterialStockUpdown",
                    SecondButtonLabel: "Headers.NoChange",
                }
            });
            dialogRef.afterClosed().subscribe((result: any) => {
                if (result) {
                    if (result == 1) {
                        var deletedMat = this.AddedMaterials[index];
                        if (deletedMat && deletedMat.id) 
                            this.removeProjectMaterial(deletedMat.id, true);
                        else 
                            this.AddedMaterials.splice(index, 1);
                        
                    }
                    else if (result == 2) {
                        var deletedMat = this.AddedMaterials[index];
                        if (deletedMat && deletedMat.id) 
                            this.removeProjectMaterial(deletedMat.id, false);
                        else 
                            this.AddedMaterials.splice(index, 1);
                    }
                }
            });
        }
    }

    removeProjectMaterial(id, isUpDownQty) {
        this.showSpinner = true;
        this.isSaveData = true;
        this.projectService.deleteProjectMaterial<ResponseBase>(id, isUpDownQty)
            .subscribe({
                next: (response: ResponseBase) => {
                    //this.projectMaterials.splice(this.projectMaterials.findIndex(m => m.id == id), 1);
                    this.AddedMaterials = this.AddedMaterials.filter(m => m.id != id);
                    this.AddedMaterials.forEach(i => {
                        if (i.material && i.material.isRequiredText) {
                            i.isRequiredText = i.material.isRequiredText;
                        }
                        if (i.material && i.material.unit) {
                            i.unit = i.material.unit;
                        }
                    });
                    this.calMaterialCreation();
                    this.OnSaveAddedMaterial = JSON.stringify(this.AddedMaterials);
                    //this.OnSaveAddedMaterial = JSON.stringify([...this.AddedMaterials]);
                    //this.OnSaveAddedMaterial = JSON.stringify(Object.assign([], this.AddedMaterials));
                    //var tempArray = JSON.parse(JSON.stringify(mainArray));
                    this.showMessage = this.AddedMaterials.length > 0 ? false : true;
                    this.showSpinner = false;
                    this.materialForm.reset();
                    this.currentselectedMatObj = new QuickInvoiceMaterials();
                    this.currentselectedMatObj.id = "";
                    this.currentselectedMatObj.quantity = 0.00
                    this.isEdit = false;
                    this.materialTotalPrice = 0;
                    this.materialTotalPriceWithVAT = 0;
                    this.materialPersonalTotalPrice = 0;
                    this.calculateProjMaterialPriceTotal();
                    this.isSaveData = false;
                    this.translate.get("MESSAGES.Deleted").subscribe({
                        next: (res) => {
                            this.notificationsService.create(res);
                        },
                        error: err => {
                        }
                    });
                },
                error: err => {
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                            this.showSpinner = false;
                            this.isSaveData = false;
                        },
                        error: err => {
                        }
                    });
                },
                complete: () => {
                    this.showSpinner = false;
                    this.isSaveData = false;
                }
            });
    }
    // END
    //Back button START
    ngOnBackClick() {
        // if (this.OnSaveAddedMaterial != JSON.stringify(this.AddedMaterials)) {
        if (this.currentselectedMatObj && this.currentselectedMatObj.retailPrice > 0 && this.currentselectedMatObj.personalPrice > 0 && this.currentselectedMatObj.quantity > 0) {
            const dialogRef = this.dialog.open(AlertMessageModelComponent, {
                height: '200px',
                data: { Header: 'Headers.SaveChanges' }
            });

            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.AddToMaterialListManuallynew(false);
                } else {
                    this.materialForm.reset();
                    this.dialogRef.close(true);
                }
            });
        } else {
            if (this.OnSaveAddedMaterial != JSON.stringify(this.AddedMaterials))
                this.AddToMaterialListManuallynew(true);
            else {
                this.materialForm.reset();
                this.dialogRef.close(this.Requisition);
            }
        }
    }
    //END

    idleAfterOneMin() {
        // sets an idle timeout of 5 seconds, for testing purposes.
        this.idle.setIdle(5);
        // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
        this.idle.setTimeout(5);
        // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        this.idle.onIdleEnd.subscribe(() => {
            this.idleState = 'No longer idle.'
            this.reset();
        });
        this.idle.onTimeout.subscribe(() => {
            this.idleState = 'Timed out!';
            this.timedOut = true;
            this.AddMaterialUsingScanner();
            setTimeout(() => { this.reset(); }, 500);
        });
        this.idle.onIdleStart.subscribe(() => { this.idleState = 'You\'ve gone idle!' });
        this.idle.onTimeoutWarning.subscribe((countdown) => {
            this.idleState = 'You will time out in ' + countdown + ' seconds!'
        });
        // sets the ping interval to 15 seconds
        this.keepalive.interval(15);
        this.keepalive.onPing.subscribe(() => this.lastPing = new Date());
        this.reset();
    }

    setIdleTimeOut() {
        // sets an idle timeout of 5 seconds, for testing purposes.
        this.idle.setIdle(10);
        // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
        this.idle.setTimeout(10);
        // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        this.idle.onIdleEnd.subscribe(() => this.idleState = 'No longer idle.');
        this.idle.onTimeout.subscribe(() => {
            this.idleState = 'Timed out!';
            this.timedOut = true;
            this.AddMaterialUsingScanner();
            this.reset();
        });
        this.idle.onIdleStart.subscribe(() => this.idleState = 'You\'ve gone idle!');
        this.idle.onTimeoutWarning.subscribe((countdown) => this.idleState = 'You will time out in ' + countdown + ' seconds!');
        // sets the ping interval to 15 seconds
        this.keepalive.interval(15);
        this.keepalive.onPing.subscribe(() => this.lastPing = new Date());
        this.reset();
    }

    calDiscountPecentageWithClear() {
        if (this.discountPercent != null) {
            this.discount = 0;
            this.calDiscount();
            this.calMaterialCreation();
            this.UpdateRequisitionStatus();
        }
    }
    calDiscountWithClear() {
        if (this.discount != null) {
            this.discountPercent = 0;
            this.calDiscount();
            this.calMaterialCreation();
            this.UpdateRequisitionStatus();
        }
    }

    reset() {
        this.idle.watch();
        this.idleState = 'Started.';
        this.timedOut = false;
    }

    getFailedTone() {
        this.commonService.playFailedTone();
    }

    getSuccessTone() {
        this.commonService.playSuccessTone();
    }

    viewColorCode() {
        var customerId = this.ObjProject.customerId;
        var dialogRef = this.dialog.open(CustomerProjectColorCodeComponent, {
            height: '90%',
            width: '90%',
            disableClose: false,
            data: { "customerId": customerId }
        });
        dialogRef.afterClosed().subscribe(result => { });
    }

    _handleKeydown(event: KeyboardEvent) {
        if (event.keyCode === 13) {
            event.stopPropagation();
        }
    }

    bindMaterialDropdownOnEdit() {
        if (this.Materials.length && this.editedProjectMaterialObj && this.editedProjectMaterialObj.material) {
            var material = this.Materials.find(x => x.id == this.editedProjectMaterialObj.material.id);
            if (material) {
                this.currentselectedMatObj.materialId = material.id;
            }
        }
    }

    calMaterialCreation() {
        var totalMaterialCreationPrice = 0
        this.AddedMaterials.forEach((e: any) => {
            if (e.itemNumber && e.lineType && (e.lineType == "V" || e.material.lineType == "V")) {
                totalMaterialCreationPrice += e.totalPrice ? e.totalPrice : 0
            }
        });
        this.Requisition.materialCreation = ((totalMaterialCreationPrice / this.materialTotalPrice) * 100);
    }

    getMaterialOnType(typeId) {
        if (typeId != 0) {
            //this.Materials = this.tempMaterials.filter(x => x.materialType == typeId);
            this.Materials = this.tempMaterials.filter(x => x.materialType && x.materialType.split(',').find(p => p == (this.materialType.toString())));
        } else {
            this.Materials = this.tempMaterials;
        }
    }

    onChkSetReminderChange() {
        if (this.isSetReminder)
            this.ShowAddReminderComponent();
    }

    ShowAddReminderComponent() {
        const dialogRef = this.dialog.open(VibeAddReminderComponent, {
            width: '75%',
            data: { isRedirectToMaterial: true },
        });
        dialogRef.afterClosed().subscribe(result => {
        });
    } 
    onChangeStatus(event) {
        this.Requisition.status = event;
        this.Requisition.StatusLogCaseValue = ProjectRequisitionStatusLogEnum.StatusUpdated;
        this.UpdateRequisition(this.Requisition);
    }
    
    UpdateRequisition(model: projectRequisition) {
        if (this.Requisition.donotUpdateWorkHour) {
            model.doNotUpdateHour = "T";
        }
        else {
            model.doNotUpdateHour = "F";
        }
        this.showSpinner = true;
        model.CreatedBy = this.authService.getCoWorkerId();
        //Invoiced date 
        if (this.Requisition.billed && typeof this.Requisition.billed !== "string") {
            this.Requisition.billed = moment(this.Requisition.billed).format("DD-MM-YYYY HH:mm");
        }
        this.projectRequisitionService.Update(model.projectId, model.id, model).subscribe({
            next: (response: ResponseBase) => {
                if (response) {
                    this.Requisition.startDate = response.result.startDate;
                    this.Requisition.endDate = response.result.endDate;
                    this.Requisition.comingDate = response.result.comingDate;
                    this.Requisition.status = response.result.status;
                    this.Requisition.discountPercent = response.result.discountPercent;
                    this.Requisition.discount = response.result.discount;
                    this.Requisition.materialCreation = response.result.materialCreation;
                    this.ObjProject.isActive = response.result.projectActive;
                    if (this.Requisition.doNotUpdateHour == "T")
                        this.Requisition.donotUpdateWorkHour = true;
                    else
                        this.Requisition.donotUpdateWorkHour = false;
                    this.translate.get("MESSAGES.Updated").subscribe({
                        next: (res) => {
                            this.notificationsService.create(res);
                        },
                        error: err => {
                        }
                    });

                }
            },
            error: err => {
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    upDownMaterialQuantityDialog(type, data) {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: '200px',
            data: { Header: 'Headers.MaterialUpdownQuantity' },
        });

        dialogRef.afterClosed().subscribe(result => {
            if(type == "material"){
                this.removeProjectMaterial(data, result);
            }
        });
    }
    AddViewQuickMaterial(){
        this.isAddQuickMaterial = true;
        this.IsAddManualMaterial = false;
        this.materialForm.reset();
    }

    AddQuickMaterial(){
        if(this.Requisition && (this.Requisition.status != 8 && this.Requisition.status != 9 && this.Requisition.status != 12 ))
        {
            this.translate.get("MESSAGES.CanNotAddMaterial").subscribe({
                next: (res) => {
                    this.notificationsService.error(res);
                    this.getFailedTone();
                },
                error: err => {
                }
            });
            return;
        }
        this.formService.markFormGroupTouched(this.quickMaterialForm);
        if (!this.quickMaterialForm.valid)
            return;
        this.currentselectedMatObj = new QuickInvoiceMaterials();
        this.objAddProjectMaterial.createdDate = new Date();
        this.objAddProjectMaterial.text = this.quickMatObj.text;
        this.objAddProjectMaterial.sagOpgaveId = this.RequisitionId;
        this.objAddProjectMaterial.quantity = this.quickMatObj.quantity;
        this.objAddProjectMaterial.personalPrice = 0;
        this.objAddProjectMaterial.retailPrice = this.quickMatObj.retailPrice;
        this.objAddProjectMaterial.purchasePrice = this.quickMatObj.purchasePrice;
        this.objAddProjectMaterial.price = 0;
        this.objAddProjectMaterial.totalPrice = 0;
        this.objAddProjectMaterial.personalPriceTotal = 0;
        this.objAddProjectMaterial.totalPriceVAT = 0;
        this.objAddProjectMaterial.totalPrice = this.objAddProjectMaterial.retailPrice > 0 ? this.getTotalPrice(this.objAddProjectMaterial.quantity, this.objAddProjectMaterial.retailPrice) : 0;
        this.objAddProjectMaterial.totalPriceVAT = this.objAddProjectMaterial.totalPrice > 0 ? (((this.objAddProjectMaterial.totalPrice * 25) / 100) + this.objAddProjectMaterial.totalPrice) : 0;
        var copyObj = Object.assign({}, this.objAddProjectMaterial);
        this.AddedMaterials.push(copyObj);
        //this.calculateProjMaterialPriceTotal();
        this.quickMatObj = new QuickInvoiceMaterials();
        this.AddToMaterialListManuallynew(false);
    }
}
