import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiConstant } from '../../../core/constant/ApiConstant';
import { RequestInquiry, RequestForLocalStorage } from '../../model/RequestBase';

@Injectable({
  providedIn: 'root'
})
export class InquiryService {
  number: any;
  text: any;
  private actionUrl: string;
  customers: any[];
  ObjCustomer: any;
  customerOrders: any[];
  constructor(private http: HttpClient) {
    this.actionUrl = ApiConstant.API_URL + ApiConstant.INQUIRY + "/";
  }

  public Add<T>(inquiry: any): Observable<T> {
    return this.http.post<T>(this.actionUrl + "CreateAsyncByWeb", inquiry);
  }

  public Update<T>(id: any, inquiry: any): Observable<T> {
    return this.http.put<T>(this.actionUrl + id, inquiry);
  }

  public UpdateDescription<T>(id: any, content: string): Observable<T> {
    return this.http.put<T>(this.actionUrl + "updatenotedetail/" + id, { id, content });
  }

  public UpdateComments<T>(id: any, content: string): Observable<T> {
    return this.http.put<T>(this.actionUrl + "updatecomments/" + id, { id, content });
  }

  public UpdateMarkAsReadInquiry<T>(id: any): Observable<T> {
    return this.http.put<T>(this.actionUrl + "MarkAsRead/" + id, null);
  }

  public Delete<T>(id: any): Observable<T> {
    return this.http.delete<T>(this.actionUrl + id);
  }

  public Get<T>(RequestCustomer: any): Observable<T> {
    let search = this.GetParams(RequestCustomer);
    return this.http.get<T>(this.actionUrl, {
      params: search
    });
  }

  public GetSingle<T>(id: any): Observable<T> {
    return this.http.get<T>(this.actionUrl + id);
  }

  public GetNoteDetail<T>(id: any): Observable<T> {
    return this.http.get<T>(this.actionUrl + "notedetail/" + id);
  }

  public GetCommentsDetail<T>(id: any): Observable<T> {
    return this.http.get<T>(this.actionUrl + "commentdetail/" + id);
  }

  public getRequestParameter() {
    var val = localStorage.getItem(ApiConstant.LocalRequestModel);
    var requestForLocalStorage = new RequestForLocalStorage();
    var requestInquiry = new RequestInquiry();
    requestInquiry.OrderBy = "Number DESC"
    if (val) {
      requestForLocalStorage = JSON.parse(val);
      if (requestForLocalStorage.InquiryRequest) {
        requestInquiry = requestForLocalStorage.InquiryRequest;
      }
    }
    return requestInquiry;
  }

  public setRequestParameter(model) {
    var requestForLocalStorage = new RequestForLocalStorage();
    var val = localStorage.getItem(ApiConstant.LocalRequestModel);
    if (val) {
      requestForLocalStorage = JSON.parse(val);
    }
    requestForLocalStorage.InquiryRequest = model;
    localStorage.setItem(ApiConstant.LocalRequestModel, JSON.stringify(requestForLocalStorage));
  }

  public GetParams(param) {
    let params: HttpParams = new HttpParams();
    for (var key in param) {
      if (param.hasOwnProperty(key)) {
        let val = param[key];
        params = params.append(key, val);
      }
    }
    return params;
  }

  public GetInquiryLogDetails<T>(Id: any): Observable<T> {
    return this.http.get<T>(this.actionUrl + "getInquiryLogDetails/" + Id);
  }

}
