export const locale = {
    lang: 'ds',
    data: {
        'Headers': {
            'Ok': 'Gem',
            'Cancel': 'Gå tilbage',
            'ChooseFile': 'Vælg fil',
            'NoFileSelected': 'Ingen fil valgt',
            'FOLDEREMPTY': 'Denne folder er tom',
            'FolderName':'Mappe navn'
        },
    }
};

