import { Component, OnInit, Optional, Inject } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';

import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { KeyTypes, Key } from 'app/main/model/CoWorker';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { FormService } from 'app/core/services/form.service';
import { spaceOnlyValidator } from "app/core/Validators/validation";

@Component({
    selector: "key-modal",
    templateUrl: "./key.components.html",
    styleUrls: ["./key.components.scss"]
})

export class KeyComponent implements OnInit {
    keyTypeList = KeyTypes;
    keyType: string;
    coworkerKeyObj: Key[] = [];
    keysFormGroup: FormGroup;
    keyListFormGroup: FormGroup;
    keys: FormArray;
    InKeyListDetails = [];
    submitted: boolean = false;
    actionType: string = "add";
    editIndex: any;
    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private dialogRef: MatDialogRef<KeyComponent>,
        private fb: FormBuilder,
        private formService: FormService,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
        this.translationLoaderService.loadTranslations(danish, english);
    }

    ngOnInit() {
        if (this.data) {
            this.actionType = this.data.action;
            this.editIndex = this.actionType == 'edit' ? this.data.index : '';
            this.coworkerKeyObj = this.data.coworkerKeyList;
        }
        this.keysFormGroup = this.fb.group({
            keyTypeId: [null, Validators.required],
            quantity: [null, Validators.required],
            keys: [null],
            remark: [null],
        });
        this.keyListFormGroup = this.fb.group({
            keyList: new FormArray([]),
        })
        if (this.actionType != 'edit') {
            this.AddKeyDetail()
        } else {
            this.edit();
        }

    }

    get f() { return this.keyListFormGroup.controls; }
    get t() { return this.f.keyList as FormArray; }


    AddKeyDetail() {
        this.t.push(this.fb.group({
            number: [null, [Validators.required, spaceOnlyValidator.bind(this)]],
        }));
        this.InKeyListDetails = this.t.value;
    }

    delete(index) {
        this.t.removeAt(index);
    }

    save() {
        this.submitted = true;
        this.formService.markFormGroupTouched(this.keysFormGroup);
        this.t.markAllAsTouched();
        this.keysFormGroup.markAsPristine()
        this.t.markAsPristine();
        if (this.keysFormGroup.valid && this.keyListFormGroup.valid) {
            if (this.actionType != 'edit') {
                this.Add();
            } else {
                this.update();
            }
        }
    }

    Add() {
        this.keysFormGroup.get("keys").setValue(this.keyListFormGroup.value.keyList);
        this.coworkerKeyObj.push(this.keysFormGroup.value);
        var keys = JSON.stringify(this.coworkerKeyObj);
        this.dialogRef.close(keys);
    }

    update() {
        this.keysFormGroup.get("keys").setValue(this.keyListFormGroup.value.keyList);
        this.coworkerKeyObj[this.editIndex] = this.keysFormGroup.value;
        var keys = JSON.stringify(this.coworkerKeyObj);
        this.dialogRef.close(keys);
    }

    edit() {
        var data = this.coworkerKeyObj[this.editIndex];
        this.keysFormGroup.patchValue(data);
        for (let index = 0; index < this.keysFormGroup.value.keys.length; index++) {
            this.t.push(this.fb.group({
                number: this.keysFormGroup.value.keys,
            }));
        }
        this.t.patchValue(this.keysFormGroup.value.keys);
    }

    selectedTypeId(typeId) {
        var type = this.coworkerKeyObj.filter(x => x.keyTypeId == typeId);
        if (type.length > 0) {
            this.editIndex = this.coworkerKeyObj.findIndex(o => o.keyTypeId == typeId);
            this.actionType = "edit";
            this.keyListFormGroup = this.fb.group({
                keyList: new FormArray([]),
            })
            this.edit();
        } else {
            this.actionType = "";
            this.editIndex = "";
            this.keysFormGroup.reset();
            this.keysFormGroup.get("keyTypeId").setValue(typeId);
            this.keyListFormGroup = this.fb.group({
                keyList: new FormArray([]),
            });
            this.AddKeyDetail();
        }
    }

    back() {
        this.dialogRef.close(false);
    }
}