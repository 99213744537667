export const locale = {
    lang: 'en',
    data: {
        'TITLE': {
            'NotificationOfCowerker': 'Vonage SMS Coworker Setting',
        },
        'BUTTONS': {
            'Save': 'Save',
        }
    }
};