export const locale = {
    lang: 'en',
    data: {
        'Headers': {
            'Ok': 'OK',
            'Cancel': 'Cancel',
            'ChooseFile': 'Choose File',
            'NoFileSelected': 'No File Selected',
            'FOLDEREMPTY': 'This folder is empty',
            'FolderName':'Folder name'
        },
    }
};

