import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { BehaviorSubject } from 'rxjs';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { multi } from './data';

@Component({
  selector: 'vibe-customerreport',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})
export class CustomerReportComponent implements OnInit {
  private _SelectedTab = new BehaviorSubject<number>(null);
  @Input()
  set SelectedTab(value) {
      this._SelectedTab.next(value);
  };
  get SelectedTab() {
      return this._SelectedTab.getValue();
  }

  @Output() IsTabLoading = new EventEmitter<any>();

  multi: any[];
  view: any[] = [800, 400];

  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'Year';
  showYAxisLabel: boolean = true;
  yAxisLabel: string = 'Number';
  animations: boolean = true;

  colorScheme = {
    domain: ['#5AA454', '#C7B42C', '#AAAAAA']
  };

  constructor(private translationLoader: FuseTranslationLoaderService,
    private translate: TranslateService,
    ) {
    this.translationLoader.loadTranslations(english, danish);
    Object.assign(this, { multi });
  }

  ngOnInit() {
    this._SelectedTab
      .subscribe(i => {
        if(i==1) { 
        }
      })
  }

  onSelect(event) {
  }
}
