import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { CommonService } from 'app/core/services/common.service';
import { SettingsService } from 'app/main/pages/settings/settings.service';
import { ReminderRepeatModes, ReminderWeekDays, ReminderRepeatModeOfMonth, WeekOftheMonth } from 'app/main/model/Reminder';
import * as moment from 'moment';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { RequestProjectRecuringSetting } from 'app/main/model/RequestBase';
import { MatDialog } from '@angular/material';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';

@Component({
    selector: 'cust-project-recuring-setting',
    templateUrl: './project-recuring-setting.component.html',
    styleUrls: ['./project-recuring-setting.component.scss']
})
export class ProjectRecuringSettingsComponent implements OnInit {
    showSpinner: boolean = false;
    @Input() customerId: string;
    @Input() projects = [];
    projectRecuringForm: FormGroup;
    projectRecuringDetail: any = {};
    isSubmitted = false;
    IsGettingCoWokers: boolean = false;
    reminderRepeatModes = ReminderRepeatModes;
    reminderRepeatModeOfMonth = ReminderRepeatModeOfMonth
    weekDays = ReminderWeekDays;
    weekOfMonth = WeekOftheMonth;
    currentselectedcoWorkeridObj: any[] = [];
    projectRecuringList: any[] = [];
    pagination: any;
    request: RequestProjectRecuringSetting = new RequestProjectRecuringSetting();
    //Initiators
    constructor(private translationLoader: FuseTranslationLoaderService,
        private fb: FormBuilder,
        private translate: TranslateService,
        private notificationsService: NotificationsService,
        private settingsService: SettingsService,
        private datePipe: DatePipe,
        public dialog: MatDialog,
        public commonService: CommonService,) {
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
        this.projectRecuringForm = this.fb.group({
            id: [null],
            text: [null],
            mode: [1],
            startDate: [new Date(), Validators.required],
            endDate: [null, Validators.required],
            executeTime: [null, Validators.required],
            modeOfMonth: [null],
            executeDayOnMonth: [null],
            executeWeekOnMonth: [null],
            isExecuteMonday: [false],
            isExecuteTuesday: [false],
            isExecuteWednesday: [false],
            isExecuteThursday: [false],
            isExecuteFriday: [false],
            isExecuteSaturday: [false],
            isExecuteSunday: [false],
            coworkerIds: [null],
            projectIds: [null, Validators.required],
            customerId: [null]
        });
        this.projectRecuringDetail.startDate = new Date(),
            this.projectRecuringDetail.endDate = new Date(),
            //this.notificationDetail.executeTime = this.datePipe.transform(new Date(), "HH:mm");
            this.projectRecuringDetail.executeTime = "00:00";
        this.request.customerId = this.customerId;
        this.GetProjectRecuringList();


    }

    get f() { return this.projectRecuringForm.controls; }

    onSubmit() {
        this.projectRecuringForm.markAllAsTouched();
        this.isSubmitted = true;
        if (this.projectRecuringForm.valid)
            this.saveNotificationSetting();
    }

    saveNotificationSetting() {
        this.projectRecuringDetail = this.projectRecuringForm.value;
        this.projectRecuringDetail.customerId = this.customerId;
        if (this.currentselectedcoWorkeridObj && this.currentselectedcoWorkeridObj.length > 0)
            this.projectRecuringDetail.coworkerIds = this.currentselectedcoWorkeridObj.join();
        if (this.f.projectIds && this.f.projectIds.value)
            this.projectRecuringDetail.projectIds = this.f.projectIds.value.map(x => x.sagId).join();
        this.showSpinner = true;
        if (this.projectRecuringDetail.id) {
            this.settingsService.UpdateProjectRecuringSetting(this.projectRecuringDetail.id, this.projectRecuringDetail).subscribe({
                next: (response: ResponseBase) => {
                    this.showSpinner = false;
                    this.FormReset();
                    this.translate.get("MESSAGES.Created").subscribe({
                        next: (res) => {
                            this.notificationsService.success(res);
                        },
                        error: err => {
                            this.showSpinner = false;
                        }
                    });
                    this.GetProjectRecuringList();
                },
                error: err => {
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                            this.showSpinner = false;
                        },
                        error: err => {
                        }
                    });
                }
            });
        }
        else {
            this.settingsService.AddProjectRecuringSetting(this.projectRecuringDetail).subscribe({
                next: (response: ResponseBase) => {
                    this.showSpinner = false;
                    this.FormReset();
                    this.translate.get("MESSAGES.Created").subscribe({
                        next: (res) => {
                            this.notificationsService.success(res);
                        },
                        error: err => {
                            this.showSpinner = false;
                        }
                    });
                    this.GetProjectRecuringList();
                },
                error: err => {
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                            this.showSpinner = false;
                        },
                        error: err => {
                        }
                    });
                }
            });

        }
    }

    public get days() {
        const dayCount = this.getDaysInMonth(Number(moment(this.projectRecuringDetail.startDate).format("YYYY")), Number(moment(this.projectRecuringDetail.startDate).format("MM")));
        return Array(dayCount).fill(0).map((i, idx) => idx + 1)
    }

    public getDaysInMonth(year: number, month: number) {
        return 32 - new Date(year, month - 1, 32).getDate();
    }

    public FormReset() {
        this.projectRecuringForm.reset();
        this.f.mode.setValue(1);
        this.projectRecuringDetail.executeTime = "00:00";
    }

    onToppingRemoved(topping: string, toppingsControl: any) {
        const toppings = this.f.projectIds.value as string[];
        this.removeFirst(toppings, topping);
        this.f.projectIds.setValue([...toppings]);
    }

    private removeFirst<T>(array: T[], toRemove: T): void {
        const index = array.indexOf(toRemove);
        if (index !== -1) {
            array.splice(index, 1);
        }
    }

    compareFn(c1: any, c2: any): boolean {
        return c1 && c2 ? c1.uuid === c2.uuid : c1 === c2;
    }

    GetProjectRecuringList() {
        this.showSpinner = true;
        this.settingsService.GetProjectRecuringSettingList(this.request).subscribe((response: ResponseBase) => {
            if (response && response.result) {
                this.projectRecuringList = response.result;
                this.pagination = response.pagination;
            }
        }, error => (err) => { },
            () => {
                this.showSpinner = false;
            });
    }

    receivePaginatonEvent(event) {
        this.request.Index = event.pageIndex;
        this.request.Limit = event.pageSize;
        this.GetProjectRecuringList();
    }

    onFocusIn(event) {
        event.model.show();
    }

    public OnChangeDatePicker(args): void {
        if (args.value) {
            args.value = new Date(moment(args.value).format("YYYY-MM-DD"));
        }
    }

    onModeChange() {
        if (this.f.mode.value == 5) {
            this.projectRecuringDetail.startDate = new Date();
            this.projectRecuringDetail.endDate = new Date();
            this.projectRecuringDetail.executeTime = "00:00";
            this.projectRecuringForm.controls['executeTime'].setValue("00:00");
        }
        else {
            //this.notificationDetail.executeTime = this.datePipe.transform(new Date(), "HH:mm");
            this.projectRecuringDetail.executeTime = "00:00";
            this.projectRecuringForm.controls['executeTime'].setValue("00:00");
        }
    }

    edit(data) {
        this.projectRecuringDetail = data;
        this.projectRecuringForm.patchValue({
            id: this.projectRecuringDetail.id,
            text: this.projectRecuringDetail.text,
            mode: this.projectRecuringDetail.mode,
            startDate: this.projectRecuringDetail.startDate,
            endDate: this.projectRecuringDetail.endDate,
            executeTime: this.projectRecuringDetail.executeTime,
            modeOfMonth: this.projectRecuringDetail.modeOfMonth,
            executeDayOnMonth: this.projectRecuringDetail.executeDayOnMonth,
            executeWeekOnMonth: this.projectRecuringDetail.executeWeekOnMonth,
            isExecuteMonday: this.projectRecuringDetail.isExecuteMonday,
            isExecuteTuesday: this.projectRecuringDetail.isExecuteTuesday,
            isExecuteWednesday: this.projectRecuringDetail.isExecuteWednesday,
            isExecuteThursday: this.projectRecuringDetail.isExecuteThursday,
            isExecuteFriday: this.projectRecuringDetail.isExecuteFriday,
            isExecuteSaturday: this.projectRecuringDetail.isExecuteSaturday,
            isExecuteSunday: this.projectRecuringDetail.isExecuteSunday,
            customerId: this.projectRecuringDetail.customerId,
        });
        if (this.projectRecuringDetail && this.projectRecuringDetail.coworkerIds)
            this.currentselectedcoWorkeridObj = this.projectRecuringDetail.coworkerIds.split(',');                 
        if (this.projectRecuringDetail && this.projectRecuringDetail.projectIds){
            const inputArray = this.projectRecuringDetail.projectIds.split(',');
            const assignProject = this.projects.filter(item => inputArray.includes(item.sagId));
            this.projectRecuringForm.controls['projectIds'].setValue(assignProject);
        }
    }

    delete(id) {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: '200px',
            data: { Header: 'Headers.DeleteStatusMessage' }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.showSpinner = true;
                this.settingsService.DeleteProjectRecuringSetting<ResponseBase>(id)
                    .subscribe({
                        next: (response: ResponseBase) => {
                            this.showSpinner = false;
                            this.GetProjectRecuringList();
                            this.translate.get("MESSAGES.Deleted").subscribe({
                                next: (res) => {
                                    this.notificationsService.create(res);
                                },
                                error: err => {
                                    this.showSpinner = false;
                                }
                            });
                        },
                        error: err => {
                            this.showSpinner = false
                            this.translate.get("MESSAGES.Failed").subscribe({
                                next: (res) => {
                                    this.notificationsService.error(res);
                                },
                                error: err => {
                                }
                            });
                        },
                        complete: () => { }
                    });
            }
        });

    }
}
