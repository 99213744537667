import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from '@angular/router';
import { Router } from '@angular/router';
import { AuthService } from './core/services/auth.service';
@Injectable()

export class Routegaurd implements CanActivate {

    constructor(private auth: AuthService, private _router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.auth.getCoWorkerId()) {
            return true;
        }
        else {
            this._router.navigate(['/login']);
            return false;
        }
    }
}
