//#region Imports 
// angular 
import { Component, OnInit, Input, EventEmitter, Output, ViewChild, Optional, Inject } from '@angular/core';

// third party tool
import { TranslateService } from '@ngx-translate/core';

// app-core
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';

// interfaces
import { locale as english } from '../translate/en';
import { locale as danish } from '../translate/ds';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
//#endregion Imports 

@Component({
    selector: 'vibe-zoom-img',
    templateUrl: './zoom-img.component.html',
})
export class ZoomImgComponent implements OnInit {
    //#region Initializations

    //#region @Input
    //#endregion @Input

    //#region @Output
    //#endregion @Output

    //#region @ViewChild
    //#endregion @ViewChild

    //#region Variables
    showSpinner: boolean = false;
    imgPath: string = '';
    //#endregion Variables

    constructor(
        private translationLoader: FuseTranslationLoaderService,
        private translate: TranslateService,
        public dialog: MatDialog,
        @Optional() public dialogRef: MatDialogRef<ZoomImgComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.translationLoader.loadTranslations(english, danish);
    }
    //#endregion  Initializations



    //#region Private/Helper Methods
    //#endregion Private/Helper Methods



    //#region Public Methods
    onCloseDialog() {
        this.dialogRef.close(null)
    }
    //#endregion Public Methods



    //#region Lifecycle Hooks
    ngOnInit() {
        if (this.data && this.data['ImgPath']) {
            this.imgPath = this.data['ImgPath'];
        }
    }
    //#endregion Lifecycle Hooks
}

