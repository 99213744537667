export const locale = {
  lang: 'en',
  data: {
    'TITLE': {
      'Customer': 'Number of customers per page',
      'Material': 'Number of materials per page',
      'Project': 'Number of projects per page',
      'CoWorker': 'Number of employees per page',
      'Reminder': 'Number of reminders per page',
      'Inquirie': 'Number of inquiries per page',
      'ProjectRecuring': 'Number of project recuring per page',
    },
  }
};
