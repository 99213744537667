import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { FormGroup, FormBuilder } from '@angular/forms';
import { FormService } from 'app/core/services/form.service';
import { MatDialogRef } from '@angular/material';

@Component({
    selector: 'preferenceShare',
    templateUrl: './preferenceShare.component.html',
    styleUrls: ['./preferenceShare.component.scss']
})

export class PreferenceShare implements OnInit {
    preferenceForm: FormGroup;
    showSpinner: boolean = false;
    constructor(private translate: TranslateService,
        private translationLoader: FuseTranslationLoaderService,
        private formBuilder: FormBuilder,
        private formService: FormService,
        public dialogRef: MatDialogRef<PreferenceShare>) {
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
        this.preferenceForm = this.formBuilder.group({
            name: [null],
            phoneNo: [null],
            email: [null]
        });
    }
    save() {

    }

    back() {
        this.dialogRef.close();
    }

}