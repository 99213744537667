import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { Assets } from 'app/main/model/CoWorker';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { DateAdapter, MatDialogRef, MatDialog, MAT_DIALOG_DATA, MatOption } from '@angular/material';
import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { ResponseBase } from 'app/main/model/ResponseBase';
import { TitleService } from 'app/core/services/title.service';
import { ActivatedRoute, Router } from "@angular/router";
import { NanolinkService } from 'app/main/pages/nanolink/nanolink.service';
import { AssetsGroup, AssetsLabels } from "app/constant/common.constant";
import { BaseRequest } from "app/main/model/RequestBase";
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';
import * as moment from 'moment';
import { spaceOnlyValidator } from "app/core/Validators/validation";
import { AuthService } from "app/core/services/auth.service";

@Component({
    selector: "new-assets",
    templateUrl: "./new-assets.component.html",
    styleUrls: ["./new-assets.component.scss"]
})

export class NewAssetsComponent implements OnInit {
    showSpinner: boolean = false;
    asstesForm: FormGroup;
    assetsObject: Assets = new Assets();
    requestBase: any = new BaseRequest();
    selectAssetsGroup = AssetsGroup;
    selectAssetsLables = AssetsLabels;
    assetsId: any = "";
    currentselectedLablesObj: number[];
    deviceList: any[] = [];
    masterDeviceList: any[] = [];
    showMap: boolean = false;
    isGettingDevices: boolean = false;
    public today: Date = new Date();
    roleClaims: any;

    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private translate: TranslateService,
        private formBuilder: FormBuilder,
        private notificationsService: NotificationsService,
        private titleService: TitleService,
        public dialog: MatDialog,
        private nanolinkService: NanolinkService,
        private authService: AuthService,
        private _router: Router,
        private _route: ActivatedRoute,
        private adapter: DateAdapter<any>) {
        this.translationLoaderService.loadTranslations(danish, english);
        this.roleClaims = this.authService.getRoleClaimsList();
    }

    ngOnInit() {
        this.adapter.setLocale('da');
        this.asstesForm = this.formBuilder.group({
            id: [null],
            brand: [null, [Validators.required, spaceOnlyValidator.bind(this)]],
            model: [null, [Validators.required, spaceOnlyValidator.bind(this)]],
            nanolinkDeviceID: [null],
            keywords: [null],
            description: [null],
            number: [null],
            groups: [null, Validators.required],
            lables: [null, Validators.required],
            lastServiceDate: [null],
            nextServiceDate: [null],
        });

        this._route.params.subscribe(params => {
            var id = params['id'];
            this.assetsId = id;
            if (this.assetsId)
                this.Edit();

        });
        setTimeout(() => {
            this.getAssetsList();
        }, 500);
    }

    getAssetsList() {
        this.showSpinner = true;
        this.requestBase = new BaseRequest();
        this.requestBase.Index = 1;
        this.requestBase.Limit = 100000;
        this.nanolinkService.GetDeviceList(this.requestBase).subscribe({
            next: (response: ResponseBase) => {
                if (response) {
                    this.masterDeviceList = response.result;
                    this.deviceList = this.masterDeviceList;
                }
            },
            error: err => {
                this.showSpinner = false;
                this.deviceList = [];
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    Edit() {
        this.showSpinner = true;
        this.nanolinkService.GetAssetsById<ResponseBase>(this.assetsId)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.assetsObject = response.result;
                    if (response.result && response.result.labels) {
                        this.currentselectedLablesObj = response.result.labels.split(',').map(function (item) {
                            return parseInt(item);
                        });
                    }
                    if (this.assetsObject.nanolinkDeviceID == null) {
                        this.assetsObject.nanolinkDeviceID = '0';
                    }
                    if (this.assetsObject.groups == 0)
                        this.assetsObject.groups = null;
                    this.showSpinner = false;
                },
                error: err => {
                },
                complete: () => {
                    this.showSpinner = false;
                }
            });
    }



    SaveAssets() {
        this.asstesForm.markAllAsTouched();
        if (this.asstesForm.valid) {
            if (this.assetsObject.id)
                this.updateAssets()
            else
                this.addAssets();
        }
    }

    addAssets() {
        this.showSpinner = true;
        this.assetsObject.labels = this.currentselectedLablesObj.join();
        if (this.assetsObject.nanolinkDeviceID == '0') {
            this.assetsObject.nanolinkDeviceID = null;
        }
        this.nanolinkService.AddAssetes<ResponseBase>(this.assetsObject).subscribe({
            next: (response: ResponseBase) => {
                if (response) {
                    this.translate.get("MESSAGES.Created").subscribe({
                        next: (res) => {
                            this.notificationsService.success(res);
                        },
                        error: err => {
                        }
                    });
                    this.ResetAssetsForm();
                    this._router.navigate(['nanolink/assets'], {});
                }
            },
            error: err => {
                this.showSpinner = false;
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }
    updateAssets() {
        this.showSpinner = true;
        this.assetsObject.labels = this.currentselectedLablesObj.join();
        if (this.assetsObject.nanolinkDeviceID == '0') {
            this.assetsObject.nanolinkDeviceID = null;
        }
        this.nanolinkService.UpdateAssetes<ResponseBase>(this.assetsObject.id, this.assetsObject).subscribe({
            next: (response: ResponseBase) => {
                if (response) {
                    this.translate.get("MESSAGES.Updated").subscribe({
                        next: (res) => {
                            this.notificationsService.success(res);
                        },
                        error: err => {
                        }
                    });
                    this.ResetAssetsForm();
                    this._router.navigate(['nanolink/assets'], {});
                }
            },
            error: err => {
                this.showSpinner = false;
                if (err.status != 409) {
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });
                }
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    ChangeImage(event) {
        this.assetsObject.imageBase64 = event;
    }

    ResetAssetsForm() {
        this.assetsObject = new Assets();
        this.assetsId = "";
        this.currentselectedLablesObj = [];
        this.asstesForm.reset();
    }
    async dropDownSearchNanolinkDevice(searchCoworker) {
        this.isGettingDevices = true;
        this.deviceList = this.masterDeviceList.filter(o => (o.deviceVID.toLowerCase().includes(searchCoworker.toLowerCase()) || o.description.toLowerCase().includes(searchCoworker.toLowerCase())));
        this.isGettingDevices = false;
    }
    public OnChangeDatePicker(args): void {
        if (args.value) {
            args.value = new Date(moment(args.value).format("YYYY-MM-DD"));
        }
    }

    onFocusIn(event) {
        event.model.show();
    }

    visitService() {
        this.translate.get("MESSAGES.CompletedNextService").subscribe({
            next: (res) => {
                const dialogRef = this.dialog.open(AlertMessageModelComponent, {
                    height: '20%',
                    data: { Header: res.replace('{Date}', moment(this.assetsObject.nextServiceDate).format("DD.MM.YYYY")) }
                });

                dialogRef.afterClosed().subscribe(result => {
                    if (result) {
                        this.assetsObject.lastServiceDate = this.assetsObject.nextServiceDate;
                        this.assetsObject.nextServiceDate = new Date(moment(this.assetsObject.nextServiceDate).add(1, "year").format("YYYY-MM-DD"));
                        this.updateAssets();
                    }
                });
            },
            error: err => {
            }
        });
    }

    DeleteAssets() {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: "200px",
            data: { Header: 'Headers.DeleteAlert' }
        })

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.showSpinner = true;
                this.nanolinkService.DeleteAssets<ResponseBase>(this.assetsObject.id).subscribe({
                    next: (response: ResponseBase) => {
                        if (response.statusCode == 200) {
                            this.translate.get("MESSAGES.Deleted").subscribe({
                                next: (res) => {
                                    this.notificationsService.create(res);
                                },
                                error: err => { }
                            });
                            this._router.navigate(['nanolink/assets'], {});
                        }
                    },
                    error: err => {
                        this.translate.get("MESSAGES.Failed").subscribe({
                            next: (res) => {
                                this.notificationsService.error(res);
                            },
                            error: err => { }
                        });
                    },
                    complete: () => {
                        this.showSpinner = false;
                    }
                })
            }
        });
    }
}