import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { TitleService } from 'app/core/services/title.service';
import { AuthService } from 'app/core/services/auth.service';
import { RequestCustomer, RequestProject } from 'app/main/model/RequestBase';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { CustomersService } from 'app/main/pages/customer/customer.service';
import { MatDialog, MatExpansionPanel } from '@angular/material';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';
import { NewCustomerComponent } from 'app/main/pages/customer/new-customer/new-customer.component';
import { VibeDefaultCustomerAddComponent } from '../default-customer-add/default-customer-add.component';
import { ProjectService } from 'app/main/pages/project/project.service';
import * as moment from 'moment';

@Component({
    selector: 'vibe-quickinvoice-customersearch',
    templateUrl: './customersearch.component.html',
    styleUrls: ['./customersearch.component.scss']
})
export class VibeQuickInvoiceCustomerSearchComponent implements OnInit {
    @Output() SelectedCustoemerId = new EventEmitter();
    settings = {};
    storedPurchaseCustomer: any;
    displayStoredPurchaseCustomerMessage: boolean = false;
    // roleName: string = "";
    // isValid: boolean = false;
    isLinear = true;
    firstFormGroup: FormGroup;
    customerId: string = "";
    CustomerName: string = "";
    Customers: any = [];
    requestModel: RequestCustomer = new RequestCustomer();
    ShowCustomerInfo: boolean = false;
    customerDetailObj: any;
    projects = [];
    IsExpand: boolean = false;
    showSpinner: boolean = false;
    After5DayDate: string = "";

    constructor(private _formBuilder: FormBuilder, private translationLoader: FuseTranslationLoaderService,
        private authService: AuthService, private titleService: TitleService,
        private customersService: CustomersService, private dialog: MatDialog,
        private customerService: CustomersService,
        private projectService: ProjectService) {
        this.translationLoader.loadTranslations(english, danish);
        //this.titleService.SetTitle("TITLE_STORE_SALES");
    }

    ngOnInit() {
        // this.roleName = this.authService.getUserRole();
        this.requestModel = new RequestCustomer();
        this.requestModel.Limit = 100000;
        this.GetCustomer(this.requestModel, '');
        this.GetStorePurchaseCustomer();
        setTimeout(() => {
            // if (this.roleName == "Admin") {
            //     this.isValid = true;
            // }
            // else if (this.roleName == "Kontor") {
            //     this.isValid = true;
            // }
            // else if (this.roleName == "Maler") {
            //     this.isValid = false;
            // }
            // else if (this.roleName == "Gaest") {
            //     this.isValid = false;
            // }
            // else {
            //     this.isValid = true;
            // }
            this.firstFormGroup = this._formBuilder.group({
                firstCtrl: ['', Validators.required]
            });
        }, 500);
    }

    AddCustomer(value) {
        var customer = this.Customers.find(o => o.id == this.customerId);
        var obj = { customer: customer, indoorOutdoorStatusId: value };
        this.SelectedCustoemerId.emit(obj);
    }

    GetCustomer(requestModel, searchId) {
        this.customersService.Get<ResponseBase>(requestModel)
            .subscribe((response: ResponseBase) => {
                if (response != null) {
                    this.Customers = [];
                    if (this.Customers && this.Customers.length > 0) {
                        this.Customers.push(...response.result);
                    }
                    else {
                        this.Customers = response.result;
                    }
                    //this.getNextRequestForCustomer(response.pagination, requestModel, response.result, searchId);
                    //this.Customers = response.result;
                    // if (this.Customers.length > 0 && this.storedPurchaseCustomer) {
                    //     var cust = this.Customers.find(o => o.id == this.storedPurchaseCustomer.id);
                    //     if (!cust) {
                    //         this.Customers.push(this.storedPurchaseCustomer);
                    //     }
                    //     else {
                    //         this.displayStoredPurchaseCustomerMessage = true;
                    //     }
                    // }
                }
                else {
                    this.Customers = [];
                }
            },
                error => (err) => {
                    this.Customers = [];
                },
                () => {
                });
    }

    getNextRequestForCustomer(pagination, model, response, searchId) {
        if (pagination.index == 1) {
            this.Customers = response;
        }
        else {
            this.Customers.push(...response);
        }
        model.Index = pagination.index + 1;
        model.Limit = pagination.limit;
        model.TotalRows = pagination.totalCount;
        if (pagination.totalCount > pagination.index * pagination.limit)
            this.GetCustomer(model, searchId);
        else if (searchId != '') {
            this.onChangeCustomer(searchId);
        }
    }

    GetStorePurchaseCustomer() {
        this.showSpinner = true;
        this.customersService.GetCustomerStorePurchaseCustomer<ResponseBase>(this.requestModel)
            .subscribe((response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null) {
                    this.settings = response.result.settings;
                    if (response.result.customerDetail) {
                        this.storedPurchaseCustomer = response.result.customerDetail;
                        if (this.Customers) {
                            var cust = this.Customers.find(o => o.id == this.storedPurchaseCustomer.id);
                            if (!cust) {
                                if (this.Customers && this.Customers.length > 0) {
                                    this.Customers.push(...this.storedPurchaseCustomer);
                                }
                                else {
                                    this.Customers.push(this.storedPurchaseCustomer);
                                }
                            }
                        }
                    }
                }
                else {
                    this.Customers = [];
                }
            },
                error => (err) => {
                    this.showSpinner = false;
                    this.Customers = [];
                },
                () => {
                });
    }

    selectStoredPurchaseCustomer() {
        const dialogRef = this.dialog.open(VibeDefaultCustomerAddComponent, {
            height: '300px',
            width: '1000px',
            data: { settings: this.settings, customerList: this.Customers }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.customerId = "";
                this.ShowCustomerInfo = false;
                this.GetStorePurchaseCustomer();
            }
        });
    }

    onChangeCustomer(id) {
        var t = this.customerId;
        this.ShowCustomerInfo = false;
        this.storeSelectedCustomer();
        setTimeout(() => {
            this.ShowCustomerInfo = true;
        }, 100);
        this.projects = [];
        var model = new RequestProject()
        this.GetProjectsByCustomer(this.customerId, model)

    }

    DropDownSearchCustomer(name) {
        if (name != "" || name == undefined) {
            var request = new RequestCustomer();
            request.Name = name;
            request.Name = this.CustomerName.trim();
            try {
                if (!isNaN(Number(name.toString()))) {
                    request.Name = "";
                    request.Name = name;
                }
            } catch (e) {

            }
            this.GetCustomer(request, '');
        }
    }

    GetCustomersOnClear(value) {
        if (!value) {
            this.GetCustomer(new RequestCustomer(), '');
        }
    }
    selectDefaultCustomer() {
        //var customer = this.Customers.find(o => o.id == this.storedPurchaseCustomer.id);
        //if (!customer) {
        //    this.requestModel = new RequestCustomer();
        //    this.requestModel.Name = "duderdejlig@vibesmalerfirma.dk";
        //    this.customersService.Get<ResponseBase>(this.requestModel)
        //        .subscribe((response: ResponseBase) => {
        //            if (response != null) {
        //                //this.Customers = [];
        //                customer = response.result.find(o => o.email == 'duderdejlig@vibesmalerfirma.dk');
        //                this.Customers.push(customer);
        //                this.customerId = customer.id;
        //                this.ShowCustomerInfo = false;
        //                setTimeout(() => {
        //                    this.ShowCustomerInfo = true;
        //                    this.AddCustomer(6);
        //                }, 100);
        //            }
        //        },
        //            error => (err) => {
        //            },
        //            () => {
        //            });
        //}
        //else {
        //this.customerId = customer.id;
        this.customerId = this.storedPurchaseCustomer.id;
        //this.ShowCustomerInfo = false;
        //setTimeout(() => {
        //this.ShowCustomerInfo = true;
        this.AddCustomer(6);
        //}, 100);

        //}
    }

    AddNewCustomer() {
        if (this.customerId) {
            const dialogRef = this.dialog.open(AlertMessageModelComponent, {
                height: '200px',
                data: { Header: 'Headers.CopyCustomerDetails' }
            });
            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.AddCustomerModelPopUp(true);
                }
                else
                    this.AddCustomerModelPopUp(false);
            });
        } else {
            this.AddCustomerModelPopUp(false);
        }
    }

    AddCustomerModelPopUp(openWithDetail) {
        var sentData = {};
        if (openWithDetail) {
            this.storeSelectedCustomer();
            var data = this.customerDetailObj;
            sentData = {
                'city': data.city,
                'pinCode': data.pinCode,
                'phone': data.phone,
                'name': data.name,
                'email': data.email,
                'address': data.address,
                'comment': data.comment,
                'discountPercent': data.discountPercent,
                isRedirectToQuickInvoice: true,
                IsAdd: true
            }
        }
        const dialogRef = this.dialog.open(NewCustomerComponent, {
            height: 'auto',
            width: '90%',
            disableClose: true,
            data: sentData,
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != null && result != '') {
                this.customerId = result;
                this.GetCustomer([], this.customerId);
                this.onChangeCustomer(this.customerId);
            }
        });
    }

    storeSelectedCustomer() {
        this.customersService.GetSingle<ResponseBase>(this.customerId).subscribe({
            next: (response: ResponseBase) => {
                var customerObject = response.result;
                this.customerDetailObj = response.result;
                setTimeout(() => {
                    this.setAddressInTitle();
                }, 500);
                const customerArrayObject = {
                    id: customerObject.id,
                    name: customerObject.name,
                    number: customerObject.number,
                    phone: customerObject.phone,
                    email: customerObject.email,
                    comment: customerObject.comment,
                    indoorOutdoorStatusId: "",
                    isActive: customerObject.isActive,
                    emailInvoice: "",
                    discountPercent: customerObject.discountPercent

                };
                this.Customers.push(customerArrayObject);
            },
            error: err => {
                this.showSpinner = false;
            },
            complete: () => {

            }
        });
    }

    getCustomerList() {
        this.requestModel = new RequestCustomer()
        this.GetCustomer(this.requestModel, '');
    }

    _handleKeydown(event: KeyboardEvent) {
        if (event.keyCode === 13) {
            event.stopPropagation();
        }
    }

    GetProjectsByCustomer(id: any, model: RequestProject) {
        model.IsActive = true;
        model.IsDeleted = false;
        model.CustomerId = id;
        this.customerService.GetProjectsByCustomer<ResponseBase>(id, model)
            .subscribe((response: ResponseBase) => {
                if (response != null) {
                    this.projects = response.result;
                    if (this.projects.length > 0) {
                        this.projectService.setBGImageForProjectCards(this.projects);
                        for (var i = 0; i < this.projects.length; i++) {
                            var formateDate = moment(this.projects[i].startDate).format('YYYY-MM-DD');
                            if (this.After5DayDate >= formateDate)
                                this.projects[i].displayStartDateRed = true;
                            else
                                this.projects[i].displayStartDateRed = false;
                        }
                    }
                }
                else {
                    this.projects = [];
                }
            },
                error => (err) => {
                    this.projects = [];
                },
                () => {
                });
    }

    setAddressInTitle() {
        if (this.customerDetailObj && this.customerDetailObj.name) {
            var address = this.customerDetailObj.name ? this.customerDetailObj.name + " " : ''
            address += this.customerDetailObj.address ? this.customerDetailObj.address + " " : ''
            address += this.customerDetailObj.pinCode ? this.customerDetailObj.pinCode + " " : ''
            address += this.customerDetailObj.city ? this.customerDetailObj.city : '';
            this.titleService.SetAddress(address);
        }
    }

    // Accordion Start
    OpenAccordion(flag) {
        this.IsExpand = !flag;
    }

    CloseAccordion(flag) {
        this.IsExpand = !flag;
    }

    // Code to Prevent accordion from expantion
    expandPanel(matExpansionPanel: MatExpansionPanel, event: Event): void {
        event.stopPropagation(); // Preventing event bubbling
        if (!this.IsExpand) {
            if (!this._isExpansionIndicator(event.target)) {
                matExpansionPanel.close(); // Here's the magic
            }
        }
    }

    private _isExpansionIndicator(target: EventTarget): boolean {
        const expansionIndicatorClass = 'mat-expansion-indicator';
        return ((<HTMLElement>target).classList && (<HTMLElement>target).classList.contains(expansionIndicatorClass));
    }

    CustomerDropDownOnClearSearch() {
        this.requestModel = new RequestCustomer();
        this.requestModel.Limit = 100000;
        this.GetCustomer(this.requestModel, '');
    }
    // Accordion End

}


