export const locale = {
    lang: 'ds',
    data: {
        'MESSAGES': {
            'Success': 'Success'
        },
        'FIELDS': {
        'Email': 'Primær E-mail',
        'InvoiceEmail': 'Sekundær E-mail',
        },
        'BUTTONS': {
            'Print': 'Udskriv',
            'Email': 'Email',
            'PrintAndEmail': 'Udskriv og e-mail',
            'SaveAndExit': 'Gem og afslut',
        },
    }
};
