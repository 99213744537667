import { Directive, ElementRef, Input, OnInit, Renderer2 } from "@angular/core";
import { AuthService } from "app/core/services/auth.service";

@Directive({
  selector: "[appHasClaim]"
})
export class HasClaimDirective implements OnInit {
  @Input("appHasClaim") claimType: any;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.authService.hasClaim(this.claimType).subscribe(hasClaim => {
      if (hasClaim) {
        this.renderer.setStyle(this.elementRef.nativeElement, "display", "block");
      } else {
        this.renderer.setStyle(this.elementRef.nativeElement, "display", "none");
      }
    });
  }
}