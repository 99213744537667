import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxMaskModule } from 'ngx-mask';
import { SharedModule } from 'app/core/modules/shared.module';
import { VibeQuickInvoiceComponent } from './quickinvoice.component';
import { VibeCustomerModule } from '../customer/customer.module';
import { VibeQuickInvoiceCustomerSearchComponent } from './component/customersearch/customersearch.component';
import { VibeQuickInvoicePrintComponent } from './component/printinvoice/printinvoice.component';
import { VibeQuickInvoiceAddMaterialComponent } from './component/materialadd/materialadd.component';
import { MaterialDetailComponent } from "./component/materialDetail/materialDetail.component";
import { MaterialListComponent } from "./component/materiallist/materiallist.component";
import { VibeQuickInvoiceProjectDetailComponent } from "./component/projectdetail/projectdetail.component";
import { VibeDefaultCustomerAddComponent } from "./component/default-customer-add/default-customer-add.component";
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer'; 
import { NgxCurrencyModule } from 'ngx-currency';
import { CustomCurrencyMaskConfig } from "app/core/constant/CurrencyMaskConfiguartion";

const routes = [
    {
        path: 'quickinvoice',
        component: VibeQuickInvoiceComponent
    },
];

@NgModule({
  declarations: [
        MaterialDetailComponent,
        MaterialListComponent,
        VibeQuickInvoiceComponent,
        VibeQuickInvoiceCustomerSearchComponent,
        VibeQuickInvoicePrintComponent,
        VibeQuickInvoiceAddMaterialComponent,
        VibeQuickInvoiceProjectDetailComponent,
        VibeDefaultCustomerAddComponent,
    ],
    imports: [
        SharedModule,
        VibeCustomerModule,
        RouterModule.forChild(routes),
        NgxMaskModule.forRoot(),
        //AgmCoreModule.forRoot({
        //    apiKey: 'AIzaSyB2LPLAZRlRAHndaGDzsuXpBrUdzivMqYQ',
        //    libraries: ["places"]
        //}),
        PdfJsViewerModule,
        NgxCurrencyModule.forRoot(CustomCurrencyMaskConfig)
    ],
    exports: [
        VibeQuickInvoiceComponent,
        VibeQuickInvoiceCustomerSearchComponent,
        VibeQuickInvoicePrintComponent,
        VibeQuickInvoiceAddMaterialComponent,
        VibeQuickInvoiceProjectDetailComponent,
  ],
    entryComponents: [MaterialDetailComponent, MaterialListComponent, VibeDefaultCustomerAddComponent],
})

export class QuickInvoiceModule {
}
