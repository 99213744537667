export const locale = {
    lang: 'ds',
    data: {
        'TITLE': {
            'HeaderSMSReminder': 'Rykker for manglende indberetning af timer',
            'ReminderSMSAfterAdminHeader': 'Admin',
        },
        'FIELDS': {
            "ReminderSMSAfterMin1":"(1) Send Påmindelse SMS Efter x .min",
            "ReminderSMSAfterMin2":"(2) Send Påmindelse SMS Efter x .min",
            "ReminderSMSAfterMin3":"(3) Send Påmindelse SMS Efter x .min",
            "ReminderSMSAfterMinAdmin":"Send Påmindelse SMS Efter x .min (Admin)",
            "ReminderSMSText1":"(1) Påmindelse SMS Tekst",
            "ReminderSMSText2":"(2) Påmindelse SMS Tekst",
            "ReminderSMSText3":"(3) Påmindelse SMS Tekst",
            "ReminderSMSTextAdmin":"Påmindelse SMS Tekst (Admin)",   
            'WeekDays':'Hverdage',
            'IsCreateReminder': 'Opret påmindelse?',
            'LabelForNotApplyWorkHourCoworker': 'Fjern venligst ikke @coworkerList fra SMS, Notification og Email skabelontekst.',
            'WeekDayExecute': {
                "isExecuteMonday": "Monday",
                "isExecuteMondayTime": "Monday Execute Time",
                "isExecuteTuesday": "Tuesday",
                "isExecuteTuesdayTime": "Tuesday Execute Time",
                "isExecuteWednesday": "Wednesday",
                "isExecuteWednesdayTime": "Wednesday Execute Time",
                "isExeciteThursday": "Thursday",
                "isExeciteThursdayTime": "Thursday Execute Time",
                "isExeciteFriday": "Friday",
                "isExeciteFridayTime": "Friday Execute Time",
                "isExeciteSaturday": "Saturday",
                "isExeciteSaturdayTime": "Saturday Execute Time",
                "isExecuteSunday": "Sunday",
                "isExecuteSundayTime": "Sunday Execute Time",
            }
        },
        'VALIDATIONS': {
            "ReminderSMSAfterMin1":"(1) Påmindelse sms efter minutter påkrævet",
            "ReminderSMSAfterMin2":"(2) Påmindelse sms efter minutter påkrævet",
            "ReminderSMSAfterMin3":"(3) Påmindelse sms efter minutter påkrævet",
            "ReminderSMSAfterMinAdmin":"Påmindelse sms efter minutter påkrævet (Admin)",
            "ReminderSMSText1":"(1) Påmindelse sms påkrævet",
            "ReminderSMSText2":"(2) Påmindelse sms påkrævet",
            "ReminderSMSText3":"(3) Påmindelse sms påkrævet",
            "ReminderSMSTextAdmin":"Påmindelse sms påkrævet (Admin)",
            'WeekDayExecute': {
                "isExecuteMondayTime": "Monday execute time required",
                "isExecuteTuesdayTime": "Tuesday execute time required",
                "isExecuteWednesdayTime": "Wednesday execute time required",
                "isExeciteThursdayTime": "Thursday execute time required",
                "isExeciteFridayTime": "Friday execute time required",
                "isExeciteSaturdayTime": "Saturday execute time required",
                "isExecuteSundayTime": "Sunday execute time required",
            }
        },
        'MESSAGES': {
            'Saved': 'Gemt succesfuldt.',
        },
        'BUTTONS': {
            'Save': 'Gem',
        }
    }
};
