export const locale = {
  lang: 'en',
  data: {
    'PROJECT': {
      'TITLE': 'Project',
      'DESC': 'Hello Project!'
    },
    'BUTTONS': {
      'NEWPROJECT': 'New Project',
      "DefaultCoworker": 'Default Coworker',
      'StatusMessage': 'Status Message',
      'Tool': 'Tool',
      'PriceDiscount': 'Price discount',
      'ShowInactive': 'Show Inactive',
      'VehicleInfo': 'Nanolink',
      'AssignCoworker': 'Assign to employee'
    },
    'TITLE': {
      'ProjectPagination': 'Antal projekter pr. side',
    },
    'FILTERS': {
      'SEARCH': 'Search',
      'SORTING': 'Sort By',
      'SORTING_STATUS': 'Status',
      'Active': 'Include All Inactive',
      'BADGE': 'Inactive',
      'Deleted': 'Show ONLY deleted cases',
      'All': 'All',
      'Lift/Stigemand': 'Lift/Stigemand',
      'Indoor/Outdoor': 'Indoor/Outdoor',
      'Lift/Stigmand': 'Lift/Stigmand',
      'Ind/Ud': 'Ind/Ud',
      'MtoM': 'M to M',
      'Note': 'Note',
      'Offers': 'Offers',
      'Show60DayOld': 'Show only 60 days ',
      'ShowHourDetails': 'Show hourly details',
      'TotalProject': 'Total Projects',
      'TotalProjectRemainingHours': 'Project Remaining Hours',
      'TotalProjectDaysLeft': 'Project Remaining Days',
      'LastDayWorkingHour': 'Last Day Working Hours',
      'Text': 'Text*',
      'OpgaveText': 'Opgave Tekst',
      'InvoiceText': 'Invoice Text',
      'PurchaseStatus': 'Purchase Status',
      'AverageAmount': 'Average amount',
      'TotalProjectRemainingPris': 'Project remaining price',
      'SearchByZip': 'Search Zipcode',
      'Inactive':'InActive'
    },
    'MESSAGES': {
        'NORECORD': 'No Record Found',
        'NORECORDONSTATUS':'You find project may be other status or Inactive or deleted...'
    },
    'FIELDS': {
      'IndoorOutDoor': 'Indoor,Outdoor,Spray Painting,Reclamation*',
      'Lift': 'Left',
      'Stigemand': 'Riseman',
      'CaseNumber': 'Case Number',
      'Title': 'Title',
      'Client': 'Client',
      'Address': 'Address',
      'ProjectEndDate': 'End Date',
      'EndDate': 'End Date',
      'CoWorkers': 'Co-Workers',
      'Files': 'Files',
      'actionsColumn': 'Action',
      'From': 'From',
      'To': 'to',
      'Not-Found-CUSTOMER': 'Customer not found',
      'Not-Found-MATERIAL': 'Material not found',
      'Not-Found-Worker': 'Co-worker not found',
      'TOTAL': 'Total',
      'Coworker': 'Coworker',
      'HoursBack': 'Hours Back',
      'Before60Days': '*** 60 Day old.',
      'NoOfEmployee': 'Number of employees',
      'MaterialPurchasePriseTotal': 'Sum Stock',
      'RemainingTime': 'RemainingTime',
      'Planned': 'Planned',
      'HourlyWage': 'Hourly rate',
      'Dankort': 'Dankort',
      'PaymentMode1': 'Dankort',
      'PaymentMode2': 'Mastercard',
      'PaymentMode3': 'Cash',
      'PaymentMode4': 'Mobile payment',
      'PaymentMode5': 'Mobile payment KK',
      'PaymentMode6': 'Credit',
      'PaymentMode7': 'Other',
      'visit': 'Visit',
      'Text': 'Text*',
      'OpgaveText': 'Requisition Text',
      'InvoiceText': 'Invoice Text',
      'TotalProjectPrice': 'Total Project Price',
      'SagNumber':'Case Number',
      'Name':'Name',
      'ZipCode':'ZipCode',
      'city':'City',
      'CustomerName':'Customer Name',
      'CustomerDetail': 'Customer Detail',
      'ProjectAddress': 'Project Address',
      'StartDate': 'StartDate',
      'PhoneNo': 'Phone no',
      'Status': 'Status',
    },
    'SORTBY':
      [
        {
          Text: 'Number (0-9)',
          Value: 'Number'
        },
        {
          Text: 'Number (9-0)',
          Value: 'Number DESC'
        },
        {
          Text: 'Start Date ↑',
          Value: 'StartDate'
        },
        {
          Text: 'Start Date ↓',
          Value: 'StartDate DESC'
        },
        {
          Text: 'End Date ↑',
          Value: 'EndDate'
        },
        {
          Text: 'End Date ↓',
          Value: 'EndDate DESC'
        },
        {
          Text: 'Status ↑',
          Value: 'Status'
        },
        {
          Text: 'Status ↓',
          Value: 'Status DESC'
        },
        {
          Text: 'Post Nummer ↑',
          Value: 'PostNummer'
        },
        {
          Text: 'Post Nummer ↓',
          Value: 'PostNummer DESC'
        }
      ],
    // 'selectedValueStatus': {
    //   'value0': 'Registered',
    //   'value1': 'Process',
    //   'value2': 'Scheduled visit',
    //   'value3': 'Ready to boot',
    //   'value4': 'Offers sent',
    //   'value5': 'Offer approved',
    //   'value6': 'Planned',
    //   'value7': 'Offer rejected',
    //   'value8': 'Started',
    //   'value9': 'Performed',
    //   'value10': 'Billed',
    //   'value11': 'Pending',
    //   'value12': 'Ready billing',
    // },
    'selectedValueIndoorOutdoorStatus': {
      'value1': 'Indoor ',//Man in a room
      'value2': 'Outdoor', //Sun
      'value3': 'Spray Painting',//spraypaint
      'value4': 'Complaint', //Reklamation
      'value5': 'Staff purchase', //drawing of painter
      'value6': 'Store purchase' //Nordsjø logo
    },
    'selectedOption': {
      'value1': 'No',
      'value2': 'Yes',
    },
    'selectedOptionForLoftAndRiseMan': {
      'value1': 'No',
      'value2': 'Yes',
    },
    'SORTBY_STATUS': [
      {
        Text: 'Show all',
        Value: 0
      },
      {
        Text: 'Process',
        Value: 1
      },
      {
        Text: 'Scheduled visit',
        Value: 2
      },
      {
        Text: 'Do not answer',
        Value: 3
      },
      {
        Text: 'Offers sent',
        Value: 4
      },
      {
        Text: 'Offer approved',
        Value: 5
      },
      {
        Text: 'Planned',
        Value: 6
      },
      {
        Text: 'Offer rejected',
        Value: 7
      },
      {
        Text: 'Started',
        Value: 8
      },
      {
        Text: 'Performed',
        Value: 9
      },
      {
        Text: 'Billed',
        Value: 10
      },
      {
        Text: 'Complaint / internally',
        Value: 11
      }
    ],
    'TOOTIPORTITLE':{
      'ProjectWorkingHoursDetails':'Project Hours Details'
    }
  }
};
