import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { MatDialog, MatOption, MatSlideToggleChange } from '@angular/material';
import { AuthService } from 'app/core/services/auth.service';
import { SettingsService } from '../../settings.service';
import { ResponseBase } from "app/main/model/ResponseBase";
import { Settings, SettingValue, SettingValueForAll } from "app/main/model/Settings";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { CoWorker } from "app/main/model/CoWorker";
import { RequestCoWorker } from "app/main/model/RequestBase";
import { CoWorkersService } from "app/main/pages/coWorker/coWorkers.service";
import { NotificationsService } from "angular2-notifications";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "environments/environment";
import { SettingEmailTempalteEditorComponent } from "../setting-email-tempalte-editor/setting-email-tempalte-editor.component";
import { spaceOnlyValidator } from "app/core/Validators/validation";

@Component({
  selector: 'vibe-new-coworker-join-notifications-settings',
  templateUrl: './new-coworker-join-notifications-settings.component.html',
  styleUrls: ['./new-coworker-join-notifications-settings.component.scss']
})

export class NewCoworkerJoinNotificationsSettingsComponent implements OnInit {
  @Input("SendcoWorkerJoinNotificationSetting") SendcoWorkerJoinNotificationSetting: Settings;
  @Input("CoWokers") CoWokers: CoWorker[];
  showSpinner: boolean = false;
  coWorkerNotificationForm: FormGroup;
  isSubmitted = false;
  coWokers: CoWorker[] = [];
  coWorkerNotificationsObj: Settings = new Settings();
  settingMasterValues: any[] = [];
  settingValue: SettingValueForAll = new SettingValueForAll();
  IsGettingCoWokers: boolean = false;

  constructor(private translationLoaderService: FuseTranslationLoaderService,
    private fb: FormBuilder,
    private coWorkersService: CoWorkersService,
    private authService: AuthService,
    private settingsService: SettingsService,
    private translate: TranslateService,
    private notificationsService: NotificationsService,
    private dialog: MatDialog) {
    this.translationLoaderService.loadTranslations(danish, english);
  }

  ngOnInit() {
    this.settingMasterValues = Object.assign([], JSON.parse(localStorage.getItem("settingMasterValues")));

    // var coworkerOBJ = new RequestCoWorker();
    // coworkerOBJ.Name = "";
    // this.getCoWorkers(coworkerOBJ);

    this.coWorkerNotificationForm = this.fb.group({
      CoworkersID: [null, Validators.required],
      DaysToSend: [null, Validators.compose([Validators.required, Validators.min(1), Validators.max(365)])],
      ExecuteTime: [null, Validators.required],
      IsCreateReminder: [false],
      IsSendSMS: [false],
      IsSendNotification: [false],
      IsSendEmail: [false],
      SMSText: [null],
      EmailText: [null],
      IsSendSMSToNewCoWorker: [false],
      IsSendEmailToNewCoWorker: [false],

      IsCreateReminderForCoworker: [false],
      IsCreateReminderForOthers: [false],
      IsCreateReminderForOthersBeforeXDays: [false],

      IsSendSMSForCoworker: [false],
      IsSendSMSForOthers: [false],
      IsSendSMSForOthersBeforeXDays: [false],

      IsSendNotificationForCoworker: [false],
      IsSendNotificationForOthers: [false],
      IsSendNotificationForOthersBeforeXDays: [false],

      IsSendEmailForCoworker: [false],
      IsSendEmailForOthers: [false],
      IsSendEmailForOthersBeforeXDays: [false],

      SMSTextForCoworker: [null],
      SMSTextForOthers: [null],
      SMSTextForOthersBeforeXDays: [null],

      NotificationTextForCoworker: [null],
      NotificationTextForOthers: [null],
      NotificationTextForOthersBeforeXDays: [null],

      NotificationTitleForCoworker: [null],
      NotificationTitleForOthers: [null],
      NotificationTitleForOthersBeforeXDays: [null],

      IsEnable: [true]
    });

    this.coWorkerNotificationForm.controls['CoworkersID'].valueChanges
      .subscribe(selectedCoWorkers => {
      });

    this.coWorkerNotificationForm.get('IsSendSMS').valueChanges
      .subscribe(value => {
        if (value) {
          this.coWorkerNotificationForm.controls['SMSText'].setValidators(Validators.required)
        } else {
          this.coWorkerNotificationForm.controls['SMSText'].setValidators(null)
        }
        this.coWorkerNotificationForm.controls['SMSText'].updateValueAndValidity()
      });

    this.coWorkerNotificationForm.get('IsSendEmail').valueChanges
      .subscribe(value => {
        if (value) {
          this.coWorkerNotificationForm.controls['EmailText'].setValidators(Validators.required)
        } else {
          this.coWorkerNotificationForm.controls['EmailText'].setValidators(null)
        }
        this.coWorkerNotificationForm.controls['EmailText'].updateValueAndValidity()
      });


    this.coWorkerNotificationForm.get('IsSendSMSForCoworker').valueChanges
      .subscribe(value => {
        if (value) {
          this.coWorkerNotificationForm.controls['SMSTextForCoworker'].setValidators([Validators.required, spaceOnlyValidator.bind(this)])
        } else {
          this.coWorkerNotificationForm.controls['SMSTextForCoworker'].setValidators(null)
        }
        this.coWorkerNotificationForm.controls['SMSTextForCoworker'].updateValueAndValidity()
      });

    this.coWorkerNotificationForm.get('IsSendSMSForOthers').valueChanges
      .subscribe(value => {
        if (value) {
          this.coWorkerNotificationForm.controls['SMSTextForOthers'].setValidators([Validators.required, spaceOnlyValidator.bind(this)])
        } else {
          this.coWorkerNotificationForm.controls['SMSTextForOthers'].setValidators(null)
        }
        this.coWorkerNotificationForm.controls['SMSTextForOthers'].updateValueAndValidity()
      });

    this.coWorkerNotificationForm.get('IsSendSMSForOthersBeforeXDays').valueChanges
      .subscribe(value => {
        if (value) {
          this.coWorkerNotificationForm.controls['SMSTextForOthersBeforeXDays'].setValidators([Validators.required, spaceOnlyValidator.bind(this)])
        } else {
          this.coWorkerNotificationForm.controls['SMSTextForOthersBeforeXDays'].setValidators(null)
        }
        this.coWorkerNotificationForm.controls['SMSTextForOthersBeforeXDays'].updateValueAndValidity()
      });

    this.coWorkerNotificationForm.get('IsSendNotificationForCoworker').valueChanges
      .subscribe(value => {
        if (value) {
          this.coWorkerNotificationForm.controls['NotificationTextForCoworker'].setValidators([Validators.required, spaceOnlyValidator.bind(this)])
          this.coWorkerNotificationForm.controls['NotificationTitleForCoworker'].setValidators([Validators.required, spaceOnlyValidator.bind(this)])
        } else {
          this.coWorkerNotificationForm.controls['NotificationTextForCoworker'].setValidators(null)
          this.coWorkerNotificationForm.controls['NotificationTitleForCoworker'].setValidators(null)
        }
        this.coWorkerNotificationForm.controls['NotificationTextForCoworker'].updateValueAndValidity()
        this.coWorkerNotificationForm.controls['NotificationTitleForCoworker'].updateValueAndValidity()
      });

    this.coWorkerNotificationForm.get('IsSendNotificationForOthers').valueChanges
      .subscribe(value => {
        if (value) {
          this.coWorkerNotificationForm.controls['NotificationTextForOthers'].setValidators([Validators.required, spaceOnlyValidator.bind(this)])
          this.coWorkerNotificationForm.controls['NotificationTitleForOthers'].setValidators([Validators.required, spaceOnlyValidator.bind(this)])
        } else {
          this.coWorkerNotificationForm.controls['NotificationTextForOthers'].setValidators(null)
          this.coWorkerNotificationForm.controls['NotificationTitleForOthers'].setValidators(null)
        }
        this.coWorkerNotificationForm.controls['NotificationTextForOthers'].updateValueAndValidity()
        this.coWorkerNotificationForm.controls['NotificationTitleForOthers'].updateValueAndValidity()
      });

    this.coWorkerNotificationForm.get('IsSendNotificationForOthersBeforeXDays').valueChanges
      .subscribe(value => {
        if (value) {
          this.coWorkerNotificationForm.controls['NotificationTextForOthersBeforeXDays'].setValidators([Validators.required, spaceOnlyValidator.bind(this)])
          this.coWorkerNotificationForm.controls['NotificationTitleForOthersBeforeXDays'].setValidators([Validators.required, spaceOnlyValidator.bind(this)])
        } else {
          this.coWorkerNotificationForm.controls['NotificationTextForOthersBeforeXDays'].setValidators(null)
          this.coWorkerNotificationForm.controls['NotificationTitleForOthersBeforeXDays'].setValidators(null)
        }
        this.coWorkerNotificationForm.controls['NotificationTextForOthersBeforeXDays'].updateValueAndValidity()
        this.coWorkerNotificationForm.controls['NotificationTitleForOthersBeforeXDays'].updateValueAndValidity()
      });

    this.setSettingsForBirthdayNotifications();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.coWokers = this.CoWokers;
}

  getCoWorkers(model: RequestCoWorker) {
    model.OrderBy = "Name";
    this.IsGettingCoWokers = true;
    this.coWorkersService.Get<ResponseBase>(model)
      .subscribe((response: ResponseBase) => {
        if (response) {
          this.coWokers = Object.assign([], response.result);
          this.getNextRequest(response.pagination, model, response.result);
        }
        else {
          this.coWokers = [];
          this.IsGettingCoWokers = false;
        }
      },
        error => (err) => {
          this.coWokers = [];
          this.IsGettingCoWokers = false;
        },
        () => {

        });
  }

  getNextRequest(pagination, model, response) {
    if (pagination.index == 1) {
      this.coWokers = Object.assign([], response);
      this.IsGettingCoWokers = false;
    }
    else {
      this.coWokers.push(...response);
      this.IsGettingCoWokers = false;
    }
    model.Index = pagination.index + 1;
    model.Limit = pagination.limit;
    model.TotalRows = pagination.totalCount;
    if (pagination.totalCount > pagination.index * pagination.limit)
      this.getCoWorkers(model);
  }

  DropDownSearchCoWorker(value) {
    if (value != null && value != "") {
      var request = new RequestCoWorker();
      request.Name = value;
      this.getCoWorkers(request);
    } else {
      let coWorker = new RequestCoWorker();
      coWorker.IsDeleted = false;
      this.getCoWorkers(coWorker);
    }
  }


  onSubmit() {
    this.coWorkerNotificationForm.markAllAsTouched();
    this.isSubmitted = true;
    if (this.coWorkerNotificationForm.valid) {
      this.changeSettingsForPlanningNotifications()
    }
  }


  setSettingsForBirthdayNotifications() {
    if (this.SendcoWorkerJoinNotificationSetting && this.SendcoWorkerJoinNotificationSetting != null) {
      this.coWorkerNotificationsObj = this.SendcoWorkerJoinNotificationSetting;
      this.settingValue = JSON.parse(this.coWorkerNotificationsObj.value);
      this.coWorkerNotificationForm.patchValue(this.settingValue);
      this.coWorkerNotificationForm.controls['CoworkersID'].setValue(this.coWorkerNotificationForm.controls['CoworkersID'].value.split(','))
      this.changeInLocalStorage(this.coWorkerNotificationsObj.id, this.coWorkerNotificationsObj)
    }
  }

  setSettingsAfterUpdate() {
    this.settingsService.GetSettingsWithTemplateValue<ResponseBase>("Notification", 'SendCoworkerJoinReminder', null).subscribe({
      next: (response: ResponseBase) => {
        this.showSpinner = false;
        if (response != null && response.result && response.result.myResponse.length > 0) {
          this.coWorkerNotificationsObj = response.result.myResponse[0];
          this.settingValue = JSON.parse(this.coWorkerNotificationsObj.value)
          this.coWorkerNotificationForm.patchValue(this.settingValue)
          this.coWorkerNotificationForm.controls['CoworkersID'].setValue(this.coWorkerNotificationForm.controls['CoworkersID'].value.split(','))
          this.changeInLocalStorage(this.coWorkerNotificationsObj.id, this.coWorkerNotificationsObj)
        }
      },
      error: err => {
      },
      complete: () => {
        this.showSpinner = false;
      }
    });
  }

  IsEnableCoworkerJoinReminder(event: MatSlideToggleChange) {
    this.settingValue.IsEnable = event.checked;
    this.changeSettingsForPlanningNotifications();
  }

  changeSettingsForPlanningNotifications() {
    this.showSpinner = true;
    this.settingValue.CoworkersID = this.coWorkerNotificationForm.value.CoworkersID ? this.coWorkerNotificationForm.value.CoworkersID.toString(): null;
    this.settingValue.DaysToSend = this.coWorkerNotificationForm.value.DaysToSend;
    this.settingValue.ExecuteTime = this.coWorkerNotificationForm.value.ExecuteTime;
    this.settingValue.IsEnable = this.coWorkerNotificationForm.value.IsEnable;
    this.settingValue.IsSendSMSForCoworker = this.coWorkerNotificationForm.value.IsSendSMSForCoworker;
    this.settingValue.IsSendSMSForOthers = this.coWorkerNotificationForm.value.IsSendSMSForOthers;
    this.settingValue.IsSendNotificationForOthers = this.coWorkerNotificationForm.value.IsSendNotificationForOthers;
    this.settingValue.IsCreateReminderForOthers = this.coWorkerNotificationForm.value.IsCreateReminderForOthers;
    this.settingValue.IsSendEmailForCoworker = this.coWorkerNotificationForm.value.IsSendEmailForCoworker;
    this.settingValue.IsSendEmailForOthers = this.coWorkerNotificationForm.value.IsSendEmailForOthers;
    this.settingValue.SMSTextForCoworker = this.coWorkerNotificationForm.value.SMSTextForCoworker || '';
    this.settingValue.SMSTextForOthers = this.coWorkerNotificationForm.value.SMSTextForOthers || '';
    this.settingValue.NotificationTextForOthers = this.coWorkerNotificationForm.value.NotificationTextForOthers || '';
    this.settingValue.NotificationTitleForOthers = this.coWorkerNotificationForm.value.NotificationTitleForOthers || '';
    this.coWorkerNotificationsObj.key1 = 'Notification';
    this.coWorkerNotificationsObj.key2 = 'SendCoworkerJoinReminder';
    this.coWorkerNotificationsObj.value = JSON.stringify(this.settingValue);
    this.settingsService.UpdateSettingWithTemplate<ResponseBase>(this.coWorkerNotificationsObj).subscribe({
      next: (response: ResponseBase) => {
        this.showSpinner = false;
        if (response != null && response.result) {
          this.coWorkerNotificationsObj = response.result;
          if (this.settingValue.IsEnable)
            this.setSettingsAfterUpdate();
          this.translate.get("MESSAGES.Saved").subscribe({
            next: (res) => {
              this.notificationsService.create(res);
            },
            error: err => { }
          });
        }
      },
      error: err => {
      },
      complete: () => {
        this.showSpinner = false;
        this.isSubmitted = false;
      }
    });
  }

  changeInLocalStorage(ID, new_obj) {
    if (this.settingMasterValues.find(obj => obj.id == ID)) {
      this.settingMasterValues.forEach((old_obj, index) => {
        if (old_obj.id == ID) {
          this.settingMasterValues[index] = new_obj
        }
      })
    } else {
      this.settingMasterValues.push(new_obj)
    }
    localStorage.setItem("settingMasterValues", JSON.stringify(this.settingMasterValues));
  }

  EmailTemplateDialog() {
    const dialogRef = this.dialog.open(SettingEmailTempalteEditorComponent, {
      height: '90%',
      width: '90%',
      data: { settingValue: this.settingValue, IsOther: false, IsBday: false, IsSameDay: true, Note: 'Note.CoWorkerJoiningEmailTextCoworkerNote' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null && result != '') {
        this.settingValue = result;
        this.onSubmit();
      }
    });
  }

  EmailTemplateForOthersDialog() {
    const dialogRef = this.dialog.open(SettingEmailTempalteEditorComponent, {
      height: '90%',
      width: '90%',
      data: { settingValue: this.settingValue, IsOther: true, IsBday: false, IsSameDay: false, Note: 'Note.CoWorkerJoiningEmailTextOtherCoworkerNote' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null && result != '') {
        this.settingValue = result;
        this.onSubmit();
      }
    });
  }
}
