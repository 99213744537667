import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { AlertMessageModelComponent } from '../../../alert-message-model/alert-message-model.component';

@Component({
    selector: 'offerlist-selection-for-convert',
    templateUrl: './offerlist-selection-for-convert.component.html'
})
export class OfferV2ListSelecttionForConvertComponent implements OnInit {
    offerList: any = [];
    isOfferSelected: boolean = false;
    constructor(
        private translationLoader: FuseTranslationLoaderService,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<OfferV2ListSelecttionForConvertComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
        this.offerList = this.data && this.data.offerList ? this.data.offerList : [];
        this.offerList.map(x => x.showOfferInPDF = false);
    }

    onCheckboxCheck() {
        setTimeout(() => {
            this.isOfferSelected = this.offerList.some(x => x.showOfferInPDF == true);
        }, 100);
    }

    submit() {
        let filteredIds = this.offerList.filter(x => x.showOfferInPDF == true).map(i => i.id);
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: '200px',
            // data: id
            data: { Header: 'Headers.Inactive-Offer-Message' }
        });

        dialogRef.afterClosed().subscribe(result => {
            this.onCloseDialog({ "filteredIds": filteredIds.toString(), "isInactiveOffer": result });
        });

    }

    onCloseDialog(e) {
        this.dialogRef.close(e)
    }
}
