export const locale = {
    lang: 'ds',
    data: {
        'TABS': {
        },
        'MESSAGES': {
            'Add': 'Oprettet',
            'Add_Error': 'Ingen registrering fundet på Vagttider eller Vagtplan bordet, kontakt venligst til administrator.',
            'Some_Coworker_Add_Error': 'Nogle kolleger kan ikke tilføje arbejdstid.',
            'CanNotAddWorkHour': '{@Coworker} medarbejdere Kan ikke tilføje arbejdstid.',
            'DeleteNonWorkingHoursOne': 'Ønsker du at slette fra',
            'DeleteNonWorkingHoursTwo': 'den',
            'DeleteNonWorkingHoursThree': 'til den'
        },
        'Button': {
            'Plan': 'Planlæg',
            'Save': 'Gem',
            'Cancel': 'Gå tilbage',
            'Delete': 'Slette',
            'GoToProject': 'gå til sagen',
        },
        'FIELDS': {
            'Coworker': 'Medarbejdere',
            'Project': 'Projekt',
            'StartDate': 'Start dato ',
            'EndDate': 'Slut dato ',
            'Shift': 'Tids rum',
            'Not-Found-Worker': 'Medarbejder ikke fundet',
            'Not-Found-Project': 'Projekt not found',
            'Requisition': 'Opgave',
            'EnterStarttime': 'Indtast starttidspunkt',
            'EndTime': 'Indtast slutstidspunkt',
            'Startime': 'Starttidspunkt',
            'Endtime': 'Slut tid',
            'BreakTime': 'Tilføj Break tid (30 min)',
            'WorkhoursType': 'Arbejdstid type',
            'nonStatus': 'Type',
            'nonText': 'Tekst',
            'WeekNumber': 'Uges nummer',
            'LeaveStatus': 'Status',
            'AdminComment': 'Admin kommentar',
            'CustomerNo': 'Kunde nr.',
            'Name': 'Navn',
            'Email': 'E-mail',
            'PhoneNo': 'Telefon',
            'Address': 'Adresse',
            'Address-line-2': 'Adresse (Linje 2)',
            'City': 'By',
            'Zipcode': 'Postnummer',
            'Position': 'Position',
            'Comments': 'Kommentarer  [ Valgfri ]',
            'WhereInquiryCome': 'Hvor kommer forespørgelse fra',
            'received': 'Modtaget',
            'IndoorOutDoor': 'Ind, ud, Sprøjt, Reklamation *',
            'CoworkerComment': 'Medarbejder kommentar',
            'DepartmentPhone': 'Telefon',
        },
        'selectedOption': {
            'value1': 'Tilføj 07:00 til 14:30',
            'value2': 'Tilføj 07:00 til 15:00',
            'value3': 'Tilføj timer manuelt',
        },
        'Header': {
            'Add7to0230': 'Tilføj 07:00 til 14:30',
            'Add7to03': 'Tilføj 07:00 til 15:00',
            'Co-workerlist': 'Medarbejderliste',
        },
        'Lable': {
            'Timer': 'Timer',
            'HoursLeft': 'Timer tilbage',
            'Lift': 'Lift',
            'Stigemand': 'Stigemand',
            'RequisitionText': 'Opgave tekst',
            'No': 'Nej',
            'Yes': 'Ja',
            'Estimate': 'Overslagspris',
            'Price-included': 'Pris incl. Moms',
            'Price-excluded': 'Pris excl. Moms',
            'VAT': 'Moms',
            'MaterialTotalPrice': 'Materiale I alt',
            'MaterialTotalPriceIncludeVAT': 'Materiale I alt incl. Moms',
            'MaterialPersonalTotalPrice': 'Materiale Personlig Pris I alt',
            'hoursOnCase': 'Timer til sagen',
            'noOfDays': 'Hvor mange dage',
            'Hoursspent': 'Timer brugt',
            'Name': 'Navn',
            'Hours': 'Timer',
            'TotalHours': 'Samlede timer',
            'ScheduledDates': 'Planlagte datoer',
        },
        'selectedWorkHourStatus': {
            'value1': 'Ferie ',
            'value2': 'Syg',
            'value3': 'Feriefridag',
            'value4': 'Fridag m.løn',
            'value5': 'Fridag u.løn',
            'value6': 'Kursus/skole',
            'value7': 'Afspadsering',
            'value8': 'Andet',
            'value9': 'Tilbud'
        },
        'selectedWorkHoursApproveLeaveOption': {
            'value1': 'Ansøg',
            'value2': 'Godkendt',
            'value3': 'Afvist',
        },
        'whereDoesTheInquiryComeFrom': {
            'value1': 'WWW (Internet)',
            'value2': 'Hjemmeside',
            'value3': 'Facebook',
            'value4': 'Anbefaling',
            'value5': 'Begivenhed',
            'value6': 'Manuel',
            'value7': 'Telefon',
        },
        'selectedValueIndoorOutdoorStatus': {
            'value1': 'Indendørs ',
            'value2': 'Udendørs',
            'value3': 'Sprøjtemaling',
            'value4': 'Reklamation',
            'value5': 'Personale køb', //drawing of painter
            'value6': 'Butiks køb'     //Nordsjø logo
        },
    }
};
