export const locale = {
    lang: 'ds',
    data: {
        'Headers': {
            'MaterialPrice': 'Lager Pris',
            'OfferPrice': 'Tilbud Pris',
            'SuppliersItemPriceChangeList': 'Leverandoer Vare Pris Laveom liste'
        },
        "FIELDS": {
            'All': 'Alle',
            'Supplier': 'Leverandør',
            'Percentage': 'Procent',
            'RetailPrice': 'Udsalgspris',
            'SalesPrice': 'Salgspris',
            'PurchasePrice': 'Indkøbspris',
            'PersonalPrice': 'Personale pris',
            'OfferPrice': 'Tilbud Pris',

            'SRNO': 'Sr nr.',
            "Edit": 'Redigere',
            "Delete": 'Slet',
            "ChangedBy": 'ModificereAf'
        },
        "BUTTONS": {
            'CHANGEPRICE': 'Juster priser',
            'BTNSAVE': 'Gem',
            'BACK': 'Tilbage',
        },
        'MESSAGES': {
            'Added': 'Tilføjet med succes.',
            'Updated': 'Opdateret succesfuldt.',
            'Failed': 'Mislykkedes',
            'Deleted': 'Slettet med succes',
            'NORECORD': 'Intet optage fundet',
            "UndoChangesSuccess": "Fortryd ændringer lykkedes",
            "DeletePriceAdjustment": "Slette pris justering",
        },
        "VALIDATIONS": {
            'SupplierRequired': 'Vælg Leverandør',
            'PercentageRequired': 'Gå ind Procent'
        }
    }
}