export class CompanyInfoSetting {
  public ID: string;
  public id: string;
  public Name: string;
  public Description: string;
  public isHidden: boolean;
}


export class CompanyInfoSettingDTO {
  public ID: string;
  public Name: string;
  public Description: string;
  public IsHidden: boolean;
}



export class CompanyInfo {
  public ID: string;
  public name: string;
  public address: string;
  public email1: string;
  public email2: string;
  public email3: string;
  public contact1: string;
  public contact2: string;
  public city: string;
  public zipcode: string;
  public webSite: string;
  public aboutUs: string;
  public coverNumber: string;
  public invoiceTerm: string;
  public invoiceTermDescription: string;
  public image?: string;
  public logo?: string;
  public imagePath?: string;
  public offerTermDescription: string;
  public offerDescription: string;
  public humidityAndTemperaturePath: string;
  public humidityAndTemperatureDescription: string;
  public offersEmailTemplatePath: string;
  public offersEmailTemplateDescription: string;
  public offersContactUsDetailPath: string;
  public offersContactUsDetailDescription: string;
  public registrationNumber: string;
    public accountNo: string;
    public logoBase64: string;
}


//export class CompanyInfoSettingList {
//  public CompanyInfoSettingList: CompanyInfoSetting[];
//}

export class CompanyInfoSettingTab extends CompanyInfoSettingDTO {
  public DisplayOrder?: number;

}