export const locale = {
  lang: 'ds',
  data: {
    'COWORKER': {
      'TITLE': 'Medarbejdere',
      'DESC': 'Hej Medarbejdere!'
    },
    'BUTTONS': {
      'NEWCOWORKER': 'Ny medarbejder',
      'BACK': 'Gå tilbage',
      'NOTIFY': 'Underrette',
      'UploadCoWorkerList':'Upload Medarbejdere'
    },
    'TITLE': {
      'CoworkerPagination': 'Antal medarbejdere pr. side',
    },
    'FILTERS': {
      'SEARCH': 'Søg efter navn',
      'SORTING': 'Sorter efter',
      'BADGE': 'Inaktive',
      'CREDENTIAL': 'Opret webbruger'
    },
    'FIELDS': {
      'Name': 'Navn',
      'Initialer': 'Initialer',
      'CoWorkerNumber': 'Medarbejdernummer',
      'Address': 'Adresse',
      'ZipCode': 'Postnummer',
      'City': 'By',
      'Phone': 'Telefon',
      'Email': 'E-mail',
      'InCaseOfAccident': 'I tilfælde af uheld',
      'CoworkerAs': 'Coworker As',
      'DateOfCoWorkerStart': 'Dato for Co Worker Start',
      'Position1': 'Position(Nord)',
      'Position2': 'Position(Өst)',
      'Picture': 'Billede',
      'actionsColumn': 'Handling',
      'Mobile': 'Mobil',
      'CONFIRM': 'Bekræft',
      'CANCEL': 'Ændre ikke',
      'Active': 'Inkluder alle inaktive',
        'Deleted': 'Vis slettet',
    },
    'MESSAGES': {
      'NORECORD': 'Ingen medarbejder fundet'
    },
    'SORTBY':
      [
        {
          Text: 'Navn (A-Z)',
          Value: 'Name'
        },
        {
          Text: 'Navn (Z-A)',
          Value: 'Name DESC'
        }
      ]
  }
};


