export const locale = {
    lang: 'ds',
    data: {
        'FIELDS': {
            'Yes': 'JA',
            'No': 'NEJ',
            "CompletedBy":"Afsluttet af"
        },
        'PlaceHolder': {
            'ReminderDate': 'Vælg Påmindelses dato',
        }
    }
}
