export const locale = {
    lang: 'ds',
    data: {
        'TITLE': {
            'HeaderForBirthdayNotification': 'Notifikation om fødselsdag',

            'BirthdaySMSTextCoworker':'Fjern venligst ikke @CoworkerName fra SMS tekst.',
            'BirthdayNotificationTextCoworker':'Fjern venligst ikke @CoworkerName fra Notifikation tekst.',

            'BirthdaySMSTextOtherCoworker':'Fjern venligst ikke @CoworkerNames fra SMS tekst.',
            'BirthdayNotificationTextOtherCoworker':'Fjern venligst ikke @CoworkerNames fra Notifikation  tekst.',

            'BirthdaySMSTextOthersBeforeXDays':'Fjern venligst ikke @BirthDate og @CoworkerNames fra SMS tekst.',
            'BirthdayNotificationTextOthersBeforeXDays':'Fjern venligst ikke @BirthDate og @CoworkerNames fra Notifikation tekst.',
        },
        'FIELDS': {
            'IsCreateReminder':'Opret påmindelse?',
            'DaysToSend': 'Send x dage før',
            'SMSText': 'SMS tekst',
            'NotificationText': 'Notifikations tekst',
            'NotificationTitle': 'Notifikation titel',
            'EmailText': 'E-mail tekst',
            'ExecuteTime':'Udfør tid',

            'TemplatesForCoworker':'Besked For Medarbejder',
            'TemplatesForOthers':'Besked til andre på samme dag',
            'TemplatesForOthersBeforeXDays':'Besked til andre før X dage',

            'EmailTemplateForCoworker':'E-mail Skabelon For Medarbejder',
            'EmailTemplateForOthers':'E-mail skabelon til andre på samme dag',
            'EmailTemplateForOthersBeforeXDays':'E-mail-skabelon til andre før X dage',
            
            // 'NoteLabelForCoworker2':'Vær venlig gør ikke fjerne @CoworkerNames fra SMS,Notifikation og Email skabelon tekst.',
            // 'NoteLabelForOthers2':'Vær venlig gør ikke fjerne @CoworkerNames fra SMS,Notifikation og Email skabelon tekst.',
            // 'NoteLabelForOthersBeforeXDays2':'Vær venlig gør ikke fjerne @BirthDate og @CoworkerNames fra SMS,Notifikation og Email skabelon tekst.'
        },
        'VALIDATIONS': {
            'DaysToSend': 'Dage at sende er påkrævet',
            'SMSText': 'SMS-tekst er påkrævet',
            'NotificationText': 'Notifikations tekst er påkrævet',
            'NotificationTitle': 'Notifikation titel er påkrævet',
            'EmailText': 'E-mail-tekst er påkrævet',
            'ExecuteTime':'Udførelse tid er påkrævet'
        },
        'MESSAGES': {
            'Saved': 'Gemt succesfuldt.',
        },
        'BUTTONS': {
            'Save': 'Gem',
        }
    }
};
