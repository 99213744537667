import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';

import { locale as eng } from './translate/en';
import { locale as ds } from './translate/ds';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { navigation } from 'app/navigation/navigation';
import { AuthService } from '../../../core/services/auth.service';
import { Route } from '@angular/compiler/src/core';
//import { ToolbartitleService } from '../../../core/services/toolbartitle.service';
import { TitleService } from '../../../core/services/title.service';
import { CoWorkersService } from 'app/main/pages/coWorker/coWorkers.service';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { CoWorkerFull } from 'app/main/model/CoWorker';
import { environment as env } from '../../../../environments/environment';

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ToolbarComponent implements OnInit, OnDestroy {
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];
    toolbarTitle: any;
    toolbarAddress: any;
    getname: any;
    userDetails: CoWorkerFull;
    private imageUnSubscribe: any;
    userImageIcon: any = 'assets/images/vibe-images/user-profile/default profile picture.jpg';
    // Private
    private _unsubscribeAll: Subject<any>;
    toolbarHeader = "header-default";
    //isFoldedSidebar: boolean = false;
    backgroundColor: any;
    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private authService: AuthService,
        private titleService: TitleService,
        private router: Router,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        private coWorkersService: CoWorkersService,
    ) {
        this._fuseTranslationLoaderService.loadTranslations(eng, ds);
        // Set the defaults
        this.userStatusOptions = [
            {
                'title': 'Online',
                'icon': 'icon-checkbox-marked-circle',
                'color': '#4CAF50'
            },
            {
                'title': 'Away',
                'icon': 'icon-clock',
                'color': '#FFC107'
            },
            {
                'title': 'Do not Disturb',
                'icon': 'icon-minus-circle',
                'color': '#F44336'
            },
            {
                'title': 'Invisible',
                'icon': 'icon-checkbox-blank-circle-outline',
                'color': '#BDBDBD'
            },
            {
                'title': 'Offline',
                'icon': 'icon-checkbox-blank-circle-outline',
                'color': '#616161'
            }
        ];

        this.languages = [
            {
                id: 'en',
                title: 'English',
                flag: 'us'
            },
            {
                id: 'ds',
                title: 'Danish',
                flag: 'ds'
            }
        ];

        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        // change tolbar title service
        this.titleService.data.subscribe((data) => {
            this.toolbarAddress = "";
            this.toolbarTitle = data;
        });

        this.titleService.address.subscribe((data) => {
            this.toolbarAddress = data;
        });

        this.imageUnSubscribe = this.coWorkersService.userImageIcon.subscribe((result) => {
            this.userImageIcon = result;
        })
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // this._fuseSidebarService.isSidebarFolded.subscribe(val => {
        //     this.isFoldedSidebar = val
        // });

        if (env.url === "https://webapitest.vibesite.dk/" || env.url === "http://webapitest.vibesite.dk/") {
            this.toolbarHeader = "header-test";
        }
        if (env.url === "https://kronborgapi.vibesite.dk/" || env.url === "http://kronborgapi.vibesite.dk/") {
            this.toolbarHeader = "header-kronborg";
        }
        if (env.url === "https://orpapi.vibesite.dk/" || env.url === "http://orpapi.vibesite.dk/") {
            this.toolbarHeader = "header-kiltin";
        }
        this.getname = this.authService.getUserName().split("@", 1);
        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        // Set the selected language from default languages
        let lang = localStorage.getItem('selectedLanguage');
        if (!lang) {
            lang = this._translateService.currentLang;
        }
        this.selectedLanguage = _.find(this.languages, { 'id': lang });
        this.setLanguage(this.selectedLanguage)
        this.getUser();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
        this.imageUnSubscribe.unsubscribe();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void {
        // Do your search here...
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);

        // save for after reload use
        localStorage.setItem('selectedLanguage', lang.id)
    }

    getUser() {
        this.coWorkersService.GetCoWrokerAsUser<ResponseBase>().subscribe(
            {
                next: (response: ResponseBase) => {
                    this.userDetails = response.result;
                    this.coWorkersService.SetUserIcon(this.userDetails.imagePath);
                },
                error: err => {
                },
                complete: () => { }
            });
    }

    logout() {
        this.authService.LogOut().subscribe({
            next: (response => {
                this.authService.RemoveLocalStorage();
                this.router.navigate(["/login"]);
            }),
            error: err => {

            },
            complete: () => { }
        });
    }
    setImage() {
        this.userImageIcon = 'assets/images/vibe-images/user-profile/default profile picture.jpg';
    }
}
