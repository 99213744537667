import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as english } from '../../new-project/translate/en';
import { locale as danish } from '../../new-project/translate/ds';
import { MatDialog } from '@angular/material';
import { ProjectService } from '../../project.service';
import { NotificationsService } from 'angular2-notifications';
import { projectRequisition, Project } from 'app/main/model/Project';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FormService } from 'app/core/services/form.service';
import { DatePipe } from '@angular/common';
import { AuthService } from 'app/core/services/auth.service';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { WorkhoursService } from 'app/main/pages/workHour/workhours.service';
import { CommonService } from 'app/core/services/common.service';		
import * as moment from 'moment';
import { IsNumber } from 'app/core/Validators/validation';

@Component({
  selector: 'vibe-RequisitionInfo',
  templateUrl: './requisitionInfo.component.html',
  styleUrls: ['./requisitionInfo.component.scss']
})

export class RequisitionInfoComponent implements OnInit {
  showSpinner: boolean = false;
  @Input() RequisitionDetail: any;
  @Input() projectNumber: any;
  @Output() updateRequisition = new EventEmitter<any>();
  // IsAdmin: boolean = false;
  // roleName: string;
  Requisition: projectRequisition;
  RequisitionFormTab: FormGroup;
  ObjProject = new Project();
  requisitionPlanning: any = [];
  roleClaims:any;
  hasPermision: boolean = false;


  constructor(private translate: TranslateService,
    private translationLoader: FuseTranslationLoaderService,
    private dialog: MatDialog,
    private workhoursService: WorkhoursService,
    private notificationsService: NotificationsService,
    private formBuilder: FormBuilder,
    private formService: FormService,
    private datePipe: DatePipe,
    private authService: AuthService,
    private commonService: CommonService,
  ) {
    this.translationLoader.loadTranslations(english, danish);
    this.roleClaims = this.authService.getRoleClaimsList();

  }

  ngOnInit() {
    this.Requisition = new projectRequisition;
    // this.roleName = this.authService.getUserRole();
    // if (this.roleName == "Admin") {
    //   this.IsAdmin = true;
    // }
    this.authService.hasClaim(this.roleClaims.Planning_Modify).subscribe(hasClaim => {
      if (hasClaim) 
          this.hasPermision = hasClaim
       else 
          this.hasPermision = false;
  });
    this.ObjProject.sagNumber = this.projectNumber;
    if (this.RequisitionDetail) {
      this.Requisition = this.RequisitionDetail;
      this.getRequisitionPlanning(this.Requisition.id);
    }
    this.RequisitionFormTab = this.formBuilder.group({
      opgaveText: [null, Validators.required],
    });
  }

  onSetRequisitionActive(IsActive) {
    this.Requisition.isActive = IsActive;
    if (this.requisitionPlanning.length > 0) {
      var tempDate = new Date((new Date().setHours(0, 0, 0, 0)));
      var reqPlanObj = this.requisitionPlanning.filter(o => (new Date(o.date) >= tempDate));
      if (reqPlanObj.length > 0) {
        this.translate.get("MESSAGES.InactiveRequisitionPlaniingMessage").subscribe({
          next: (res) => {
            this.notificationsService.error(res);
            this.commonService.playFailedTone();
          },
          error: err => {
          }
        });
        setTimeout(() => {
          this.Requisition.isActive = !this.Requisition.isActive;
        });
      }
    }
    else {
      this.saveRequisition();
    }
  }

  getRequisitionPlanning(Id) {
    this.requisitionPlanning = [];
    var tempDate = moment(new Date()).format("YYYY-MM-DD");
    this.workhoursService.GetRequistionPlanning(Id, tempDate).subscribe({
      next: (response: ResponseBase) => {
        if (response) {
          this.requisitionPlanning = response.result;
        }
      },
      error: err => {

      },
      complete: () => {
      }
    });
  }

  saveRequisition() {
    this.formService.markFormGroupTouched(this.RequisitionFormTab);
    if (this.RequisitionFormTab.valid) {
      this.Requisition.startDate = this.datePipe.transform(this.Requisition.startDate, "MM/dd/yyyy");
      this.Requisition.endDate = this.datePipe.transform(this.Requisition.endDate, "MM/dd/yyyy");
      this.updateRequisition.emit(this.Requisition);
    }
  }

    checkStaffPriceOrStorePuchase() {
    }

    searchCityFromZipcodeForReq() {
    }

    onFocusIn(event) {
        event.model.show();
    }

    isNumber(evt) {
        return IsNumber(evt);
    }
}