import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@fuse/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FusePipesModule } from 'app/core/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ReceivedToMeComponent } from './received-to-me/received-to-me';
import { SendByMeComponent } from './send-by-me/send-by-me';
import { PaginationModule } from 'app/main/pages/pagination/pagination.module';
import { RouterModule } from "@angular/router";
import { VibeImgShowcaseComponent } from "../vibe-img-showcase/vibe-img-showcase.component";

@NgModule({
    declarations: [
        ReceivedToMeComponent,
        SendByMeComponent
    ],
    imports: [
        CommonModule,
        MaterialModule,
        FlexLayoutModule,
        FusePipesModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        DatePickerModule,
        NgxMatSelectSearchModule,
        PaginationModule,
        RouterModule
    ],
    exports: [
        ReceivedToMeComponent,
        SendByMeComponent
    ],
    entryComponents: [
        VibeImgShowcaseComponent
    ]
})

export class ReminderListModule {

}