export const locale = {
    lang: 'en',
    data: {
        'MaterialTypeCategory': {
            'Value1': 'Indoor',
            'Value2': 'Outdoor',
            'Value3': 'Window',
            'Value4': 'Doors',
            'Value5': 'Spray booth',
            'Value6': 'Talking',
            'Value7': 'Felt',
            'Value8': 'Tissue',
            'Value9': 'Rutex',
            'Value10': 'Wallpaper',
            'Value11': 'Panels',
            'Value12': 'Door frames',
            'Value13': 'Guarantee',
            'Value14': 'Lift',
            'Value15': 'Spartling',
            'Value16': 'Radiators',
            'Value17': 'Warning',
            'Value18': 'Facades',
            'Value19': 'Shed vg.',
            'Value20': 'Cover',
            'Value21': 'Ceilings',
            'Value22': 'Walls',
            'Value23': 'Floor',
            'Value24': 'Windshields',
            'Value25': 'Stern boards',
            'Value26': 'Suspension',
            'Value27': 'Other',
        },
        'SelectedCategoryTime': {
            'Value0': 'Select',
            'Value1': '1 Time',
            'Value2': '2 Times',
            'Value3': '3 Times',
            'Value4': '4 Times',
            'Value5': '5 Times',
            'Value11': 'Stk',
            'Value12': 'Meter',
            'Value13': 'Rulle',
            'Value14': 'Liter',
            'Value15': '1 Layer',
            'Value16': '2 Layer',
            'Value17': '3 Layer',
            'Value18': 'Small rooms'
        },
        'selectedValueStatus': {
            //'value0': 'Registered',
            'value0': '-',
            'value1': 'Process',
            'value2': 'Scheduled visit',
            'value3': 'Ready to boot',
            'value4': 'Offers sent',
            'value5': 'Offer approved',
            'value6': 'Planned',
            'value7': 'Offer rejected',
            'value8': 'Started',
            'value9': 'Performed',
            'value10': 'Billed',
            'value11': 'Pending',
            'value12': 'Ready billing',
        },
        'ExpensesType': {
            'value0': 'Select',
            'value1': 'Min',
            'value2': 'Amount'
        },
        'QuestionStatusType': {
            'value0': 'None',
            'value1': 'Status',
            'value2': 'OtherInfo'
        },
        'OfferTreatmentType': {
            'value0': 'None',
            'value1': 'Wood Work',
            'value2': 'Walls',
            'value3': 'Ceiling/Floor'
        },
        'MaterialWeatherType': {
            'value0': 'All',
            'value1': 'Autumn',
            'value2': 'Spring',
            'value3': 'Summer',
            'value4': 'Winter'
        },
        'AudienceType': {
            'value1': 'Co-worker With Question',
            'value2': 'Customer',
            'value3': 'Co-worker Without Question',
            'value4': 'Coworker notification',
            'value5': 'Customer notification'
        },
        'PurchaseStatus': {
            'value0': 'All',
            'value1': 'Staff purchase',
            'value2': 'Store purchases',
            'value3': 'Price excl. VAT',
            'value4': 'Price incl. VAT',
        },
        'materialType': {
            //'value0': 'All',
            'value1': 'Cover / tape',
            'value2': 'Fuger',
            'value3': 'Floor paint',
            'value4': 'Iron paint',
            'value5': 'Chemicals',
            'value6': 'Loft paint',
            'value7': 'Brushes',
            'value8': 'Roller and shafts',
            'value9': 'Spartel',
            'value10': 'Spray cans',
            'value11': 'Woodwork paint',
            'value12': 'Wall and Ceiling Paint',
            'value13': 'Miscellaneous',
            'value14': 'Facade Maling',
            'value15': 'Felt,fabric,wallpaper',
            'value16': 'Painting tool',
            'value17': 'Blocking Paint',
            'value18': 'Wood protection',
            'value19': 'Window Paint',
            'value20': 'Tool',
            'value21': 'Externally diverse',
            'value22': 'Offer',
            'value23': 'Lak',
        },
        'selectedValueWeekDays': {
            'value1': 'Monday',
            'value2': 'Tuesday',
            'value4': 'Wednesday',
            'value8': 'Thursday',
            'value16': 'Friday',
            'value32': 'Saturday',
            'value64': 'Sunday'
        },
        'Headers': {
            'NoFileSelected': 'No File Selected',
            'FOLDEREMPTY': 'This folder is empty',
            'UploadFile': 'Upload File',
            'ChooseFile': 'Choose File',
            'ChoosePicture': 'Choose Picture',
        },
        'AssetsLabels': {
            'Value1': 'Various',
            'Value2': 'Giraffe',
            'Value3': 'Cable drums, Lamps',
            'Value4': 'Machines',
            'Value5': 'Keys',
            'Value6': 'True',
            'Value7': 'Security',
            'Value8': 'Grinding cabin',
            'Value9': 'Grinding machines',
            'Value10': 'Spray booth',
            'Value11': 'Spraying machines',
            'Value12': 'Rising',
            'Value13': 'Vacuum cleaner',
            'Value14': 'Wallpaper table',
            'Value15': 'Trailer',
            'Value16': 'Conservatory',
            'Value17': 'Car park',
            
        },
        'AssetsGroup': {
            'Value1': 'Units',
            'Value2': 'Car park',
        },
        'DeviceType': {
            'Value1': 'None',
            'Value2': 'BLE',
            'Value3': 'GPS',
            'Value4': 'GPSGATE',
            'Value5': 'QR',
            'Value6': 'LANGATE',
            'Value7': 'MOBILE',
            'Value8': 'MESHGATE',
            'Value9': 'CROWD',
        },
        'DeviceModel': {
            'Value1': 'UNKNOWN',
            'Value2': 'BT40',
            'Value3': 'BT30S',
            'Value4': 'BT41',
            'Value5': 'BT80',
            'Value6': 'GT100',
            'Value7': 'GGB100',
            'Value8': 'GGT100',
            'Value9': 'LG100',
            'Value10': 'MG100',
            'Value11': 'DR100',
            'Value12': 'DY100',
            'Value13': 'TA100',
        },
        'selectedReminderRepeatModes': {
            'value1': 'Daily ',
            'value2': 'Weekly',
            'value3': 'Monthly',
            'value4': 'Yearly',
            'value5': 'Now'
          },
          'selectedWeekedDay': {
            'value1': 'Mon',
            'value2': 'Tue',
            'value3': 'Wed',
            'value4': 'Thu',
            'value5': 'Fri',
            'value6': 'Sat',
            'value7': 'Sun'
        },
        'selectedReminderRepeatModeOfMonth': {
            'value1': 'Month start day',
            'value2': 'Month End day',
            'value3': 'Monthly with specific day by week',
            'value4': 'Monthly with specific day'
        },
        'selectedWeekOftheMonth': {
            'value1': '1st',
            'value2': '2nd',
            'value3': '3rd',
            'value4': '4th',
            'value5': '5th'
        },
        'TOOTIPORTITLE': {
            'Edit': 'Edit',
            'Delete': 'Delete',
            'Copy': 'Copy'
        },
        'Message': {
            'EmailSavedInQueueWanrning': 'Setting is off for sending information we save data on server send next day.'
        },
        'supplierPriceListType': {
            'value1':"MaterialPriceList(Excel)",
            'value2':"SupplierInvoice (PDF)"
        }

    }
};