import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA, MatOption } from '@angular/material';
import { Router } from '@angular/router';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { TitleService } from 'app/core/services/title.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { ProjectService } from 'app/main/pages/project/project.service';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { ProjectStatus, AudienceType, CkeditorConfig } from 'app/constant/common.constant';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';
import { FormService } from 'app/core/services/form.service';
import { CommonService } from 'app/core/services/common.service';
import { QuestionParms } from 'app/main/model/Project';
import { AskRequisitionStatusAudienceTypeEnum } from 'app/core/enum/ask-requisition-status-audienceType.enum';
import { ProjectIndoorOutDoorStatus } from 'app/main/model/ProjectStatus';
import { RequestCoWorker } from 'app/main/model/RequestBase';
import { CoWorkersService } from 'app/main/pages/coWorker/coWorkers.service';
import { SettingsService } from 'app/main/pages/settings/settings.service';

@Component({
    selector: 'vibe-projectStatusMessage',
    templateUrl: './statusMessage.component.html',
    styleUrls: ['./statusMessage.component.scss']
})
export class StatusMessageComponent implements OnInit {
    showSpinner: boolean = false;
    requisitionStatus: number;
    tempRequisitionStatus: any = "";
    selectStatus = ProjectStatus;
    projectStatusList = ProjectStatus;
    statusMessageForm: FormGroup;
    statusMessageWithoutCoworkerQuestionForm: FormGroup;
    statusMessageDetail: any = {};
    statusList: any = [];
    questionList: any = [];
    questionListJosn: any = [];
    IsDisplayQuestions: boolean = false;
    EditStatus: number;
    QuestionParms = new QuestionParms();
    audienceTypeList = AudienceType;
    selectIndoorOutDoorStatus = ProjectIndoorOutDoorStatus;
    coWokersList: any = [];
    IsGettingCoWokers: boolean = false;
    DisplayVal: any[] = ['name']; //'coworkerNumber',
    smsText:any = "@CoworkerName. @ProjectAmount @ProjectAddress @ProjectAmountPersonale @ProjectNumber";
    notificationText:any = "@CoworkerName. @ProjectAmount @ProjectAddress @ProjectAmountPersonale @ProjectNumber";
    emailText:any = "";
    files: any[] = [];
    settingMasterValues: any[] = [];
    filesPath: any[] = [];
    @ViewChild('allSelected', { static: false }) private allSelected: MatOption;
    config = CkeditorConfig;
    //Initiators
    constructor(private translationLoader: FuseTranslationLoaderService,
        private _router: Router,
        private titleService: TitleService,
        private coWorkersService: CoWorkersService,
        private projectService: ProjectService,
        private translate: TranslateService,
        private notificationsService: NotificationsService,
        private settingsService: SettingsService,
        private formBuilder: FormBuilder,
        private formService: FormService,
        public dialog: MatDialog,
        public commonService: CommonService,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
        this.getStatusMessage();
        this.settingMasterValues = Object.assign([], JSON.parse(localStorage.getItem("settingMasterValues")));
        this.statusMessageForm = this.formBuilder.group({
            id: [null, ''],
            status: [null, Validators.compose([Validators.required])],
            //message: [null, Validators.required],
            tempRequisitionStatus: [null],
            audienceType: [null, [Validators.required]],
            indoorOutdoorStatus: [null, [Validators.required]]
        });
        this.statusMessageWithoutCoworkerQuestionForm= this.formBuilder.group({
            reminderMsg: new FormControl(''),
            remindCoWorkerIDs: new FormControl(null),
            isSendReminder: new FormControl(true),
            isSendRemindBySMS: [false],
            isSendRemindByEmail: [false],
            isSendRemindByNotification: [false],
            smsText: [this.smsText],
            notificationText: [this.notificationText],
            subject: [null],
            emailTemplateHTML: [null],
            filePath: [null],
        })
        this.statusMessageWithoutCoworkerQuestionForm.get('isSendRemindBySMS').valueChanges
            .subscribe(value => {
                if (value) {
                    this.statusMessageWithoutCoworkerQuestionForm.controls['smsText'].setValidators(Validators.required)
                } else {
                    this.statusMessageWithoutCoworkerQuestionForm.controls['smsText'].setValidators(null)
                }
                this.statusMessageWithoutCoworkerQuestionForm.controls['smsText'].updateValueAndValidity()
            });
        this.statusMessageWithoutCoworkerQuestionForm.get('isSendRemindByNotification').valueChanges
            .subscribe(value => {
                if (value) {
                    this.statusMessageWithoutCoworkerQuestionForm.controls['notificationText'].setValidators(Validators.required)
                } else {
                    this.statusMessageWithoutCoworkerQuestionForm.controls['notificationText'].setValidators(null)
                }
                this.statusMessageWithoutCoworkerQuestionForm.controls['notificationText'].updateValueAndValidity()
            });
        this.statusMessageWithoutCoworkerQuestionForm.get('isSendRemindByEmail').valueChanges
            .subscribe(value => {
                if (value) {
                    this.statusMessageWithoutCoworkerQuestionForm.controls['emailTemplateHTML'].setValidators(Validators.required)
                } else {
                    this.statusMessageWithoutCoworkerQuestionForm.controls['emailTemplateHTML'].setValidators(null)
                }
                this.statusMessageWithoutCoworkerQuestionForm.controls['emailTemplateHTML'].updateValueAndValidity()
            });
        var coworkerOBJ = new RequestCoWorker();
        coworkerOBJ.Name = "";
        this.showSpinner = true;
        this.getCoWorkers(coworkerOBJ);
        this.GetCustomerById();
    }

    get f() {
        return this.statusMessageForm.controls;
    }

    public DropDownSearchCoWorker(value) {
        if (value != null && value != "") {
            var request = new RequestCoWorker();
            request.Name = value;
            this.getCoWorkers(request);
        } else {
            let coWorker = new RequestCoWorker();
            coWorker.IsDeleted = false;
            this.getCoWorkers(coWorker);
        }
    }
    public getCoWorkers(model: RequestCoWorker) {
        return new Promise((resolve, reject) => {
            model.OrderBy = "Name";
            this.IsGettingCoWokers = true;
            this.coWorkersService.Get<ResponseBase>(model)
                .subscribe((response: ResponseBase) => {
                    if (response) {
                        this.coWokersList = response.result;
                    }
                    else {
                        this.coWokersList = [];
                    }
                }, error => (err) => {
                    this.coWokersList = [];
                }, () => {
                    this.IsGettingCoWokers = false;
                    resolve(1);
                });
        });
    }

    getStatusMessage() {
        this.showSpinner = true;
        this.projectService.GetStatusMessageList<ResponseBase>()
            .subscribe({
                next: (response: ResponseBase) => {
                    this.statusList = response.result;
                    //this.EditStatusMessage(this.statusList)
                    this.showSpinner = false;
                },
                error: err => { this.showSpinner = false },
                complete: () => { }
            });
    }

    DropDownSearchRequisitionStatus(value) {
        this.selectStatus = this.projectStatusList;
        if (value) {
            if (this.translate.store.currentLang == "en")
                this.selectStatus = this.projectStatusList.filter(x => x.en.toLowerCase().includes(value.toLowerCase()));
            else
                this.selectStatus = this.projectStatusList.filter(x => x.ds.toLowerCase().includes(value.toLowerCase()));
        }
        else
            this.selectStatus = this.projectStatusList;
    }

    public onWithoutQuestionSave() {
        this.statusMessageWithoutCoworkerQuestionForm.markAllAsTouched();

        if (this.statusMessageWithoutCoworkerQuestionForm.invalid) {
            return;
        }
        this.statusMessageWithoutCoworkerQuestionForm.controls['remindCoWorkerIDs'].patchValue(this.statusMessageWithoutCoworkerQuestionForm.controls['remindCoWorkerIDs'].value.filter(i => i != 0).toString());
        this.QuestionParms.question = JSON.stringify(this.statusMessageWithoutCoworkerQuestionForm.value);
        this.QuestionParms.indoorOutdoorStatusId = "";
        this.QuestionParms.indoorOutdoorStatusIds.filter(x => {
            this.QuestionParms.indoorOutdoorStatusId = (this.QuestionParms.indoorOutdoorStatusId ? this.QuestionParms.indoorOutdoorStatusId + "," : "") + x.id;
        });
        this.statusMessageDetail = this.QuestionParms;
        this.AddStatusMessage();
    }

    AddStatusMessage() {
        this.showSpinner = true;
        this.formService.markFormGroupTouched(this.statusMessageForm);
        if ((this.statusMessageForm.valid && this.QuestionParms.audienceType != 3)
            || (this.statusMessageWithoutCoworkerQuestionForm.valid && this.QuestionParms.audienceType == 3)) {
            if (this.QuestionParms.audienceType != 3) {
                this.QuestionParms.indoorOutdoorStatusId = "";
                this.QuestionParms.indoorOutdoorStatusIds.filter(x => {
                    this.QuestionParms.indoorOutdoorStatusId = (this.QuestionParms.indoorOutdoorStatusId ? this.QuestionParms.indoorOutdoorStatusId + "," : "") + x.id;
                });
            }
            this.projectService.AddUpdateStatusMessage<ResponseBase>(this.statusMessageDetail)
                .subscribe({
                    next: (response: ResponseBase) => {
                        this.showSpinner = false;
                        this.getStatusMessage();
                        this.QuestionParms = response.result;
                        this.QuestionParms.status = this.QuestionParms.status;
                        if (this.QuestionParms.question) {
                            this.questionListJosn = JSON.parse(this.QuestionParms.question);
                            if(this.questionListJosn && this.questionListJosn.length > 0){
                                this.questionListJosn.filter(q => {
                                    if (q.choiceOptions.length>0) {
                                        try {
                                            q.choiceOptions = JSON.parse(q.choiceOptions);
                                        } catch (e) {
                                        }
                                    }
                                })
                            }
                            this.questionList = this.questionListJosn;
                        }
                        this.setIndoorOutDoorDropDown();
                        if (this.statusMessageDetail.id) {
                            this.translate.get("MESSAGES.Updated").subscribe({
                                next: (res) => {
                                    this.notificationsService.create(res);
                                },
                                error: err => {
                                    this.showSpinner = false;
                                }
                            });
                        }
                        else {
                            this.translate.get("MESSAGES.Created").subscribe({
                                next: (res) => {
                                    this.notificationsService.create(res);
                                },
                                error: err => {
                                    this.showSpinner = false;
                                }
                            });
                        }
                        //this.Cancel();
                        if(this.QuestionParms.audienceType == 3)
                            this.BackToStatusList();
                    },
                    error: err => {
                        this.showSpinner = false;
                        this.translate.get("MESSAGES.Failed").subscribe({
                            next: (res) => {
                                this.notificationsService.error(res);
                            },
                            error: err => {
                            }
                        });
                        this.Cancel();
                    },
                    complete: () => {
                        this.showSpinner = false;
                    }
                });
        }

    }

    getDataOnStatusSelect(data) {
        if (this.QuestionParms.audienceType != AskRequisitionStatusAudienceTypeEnum.CoworkerWithOutQuestion) {
            //this.QuestionParms.audienceType = AskRequisitionStatusAudienceTypeEnum.CoworkerWithQuestion;
            this.IsDisplayQuestions = true;
            var statusObj = new QuestionParms();
            statusObj = this.statusList.find(o => o.status == data.id && o.audienceType == this.QuestionParms.audienceType);
            if (statusObj) {
                this.questionListJosn = JSON.parse(statusObj.question);
                if (this.questionListJosn && this.questionListJosn.length > 0) {
                    this.questionListJosn.filter(q => {
                        if (q.choiceOptions.length > 0) {
                            try {
                                q.choiceOptions = JSON.parse(q.choiceOptions);
                            } catch (e) {
                            }
                        }
                    })
                }
                this.questionList = this.questionListJosn;
                this.projectService.getDataOnStausSelect(this.questionList);
            }
            else {
                this.questionList = [];
                this.projectService.getDataOnStausSelect(this.questionList);
            }
        }
    }

    EditStatusMessage(data) {
        this.IsDisplayQuestions = true;
        var statusObj = Object.assign({}, this.statusList.find(o => o.id == data.id));
        if (statusObj) {
            this.QuestionParms = statusObj;
            this.QuestionParms.status = statusObj.status;
            if(statusObj.question){
                if (this.QuestionParms.audienceType != AskRequisitionStatusAudienceTypeEnum.CoworkerWithOutQuestion) {
                    this.questionListJosn = JSON.parse(statusObj.question);
                    if(this.questionListJosn && this.questionListJosn.length > 0){
                        this.questionListJosn.filter(q => {
                            if (q.choiceOptions.length>0) {
                                try {
                                    q.choiceOptions = JSON.parse(q.choiceOptions);
                                } catch (e) {
                                }
                            }
                        })
                    }
                    this.questionList = this.questionListJosn;
                }
                else if (this.QuestionParms.audienceType == AskRequisitionStatusAudienceTypeEnum.CoworkerWithOutQuestion) {
                    this.statusMessageWithoutCoworkerQuestionForm.patchValue(JSON.parse(statusObj.question));
                    this.statusMessageWithoutCoworkerQuestionForm.controls['remindCoWorkerIDs'].patchValue(this.statusMessageWithoutCoworkerQuestionForm.controls['remindCoWorkerIDs'].value.split(','));
                }
            }
            this.setIndoorOutDoorDropDown();
        }
    }

    DeleteStatusMessage(id,question) {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: '200px',
            data: { Header: 'Headers.DeleteStatusMessage' }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.showSpinner = true;
                if (question && (question.audienceType == 4 || question.audienceType == 5)) {
                    this.settingsService.DeleteReminderSchedule<ResponseBase>(id)
                        .subscribe({
                            next: (response: ResponseBase) => {
                                this.showSpinner = false;
                                this.getStatusMessage();
                                this.translate.get("MESSAGES.Deleted").subscribe({
                                    next: (res) => {
                                        this.notificationsService.create(res);
                                    },
                                    error: err => {
                                        this.showSpinner = false;
                                    }
                                });
                            },
                            error: err => {
                                this.showSpinner = false
                                this.translate.get("MESSAGES.Failed").subscribe({
                                    next: (res) => {
                                        this.notificationsService.error(res);
                                    },
                                    error: err => {
                                    }
                                });
                            },
                            complete: () => { }
                        });
                }
                else {
                    this.projectService.DeleteStatusMessage<ResponseBase>(id)
                        .subscribe({
                            next: (response: ResponseBase) => {
                                this.showSpinner = false;
                                this.getStatusMessage();
                                this.translate.get("MESSAGES.Deleted").subscribe({
                                    next: (res) => {
                                        this.notificationsService.create(res);
                                    },
                                    error: err => {
                                        this.showSpinner = false;
                                    }
                                });
                            },
                            error: err => {
                                this.showSpinner = false
                                this.translate.get("MESSAGES.Failed").subscribe({
                                    next: (res) => {
                                        this.notificationsService.error(res);
                                    },
                                    error: err => {
                                    }
                                });
                            },
                            complete: () => { }
                        });
                }
            }
        });


    }

    AddOrUpdateQuestionSave(event) {
        var data = event;
        data.forEach((element,index) => {
            element['id'] = index+1
        });
        this.QuestionParms.question = JSON.stringify(data);
        this.statusMessageDetail = this.QuestionParms;
        this.AddStatusMessage();
    }

    GetStatusName(value) {
        if (this.translate.store.currentLang == "en")
            return this.projectStatusList.filter(x => x.id == value).map(x => x.en);
        else
            return this.projectStatusList.filter(x => x.id == value).map(x => x.ds);
    }

    BackToStatusList() {
        this.questionList = [];
        //this.QuestionParms.status = 0;
        this.QuestionParms = new QuestionParms();
        this.IsDisplayQuestions = false;
        this.statusMessageWithoutCoworkerQuestionForm.reset();
    }

    AddStatus() {
        this.IsDisplayQuestions = true;
    }

    Cancel() {
        //Go to project list 
        this.dialog.closeAll();
    }

    onChangeAudienceType(type) {
        if(type.id == 4){
            this.statusMessageForm.controls["status"].setValidators(null);
            this.statusMessageForm.controls["status"].updateValueAndValidity();
            this.statusMessageForm.controls["indoorOutdoorStatus"].setValidators(null);
            this.statusMessageForm.controls["indoorOutdoorStatus"].updateValueAndValidity();
        }
        else {
            this.statusMessageForm.controls["status"].setValidators([Validators.compose([Validators.required])]);
            this.statusMessageForm.controls["status"].updateValueAndValidity();
            this.statusMessageForm.controls["indoorOutdoorStatus"].setValidators([Validators.compose([Validators.required])]);
            this.statusMessageForm.controls["indoorOutdoorStatus"].updateValueAndValidity();
        }
        this.formService.markFormGroupTouched(this.statusMessageForm);
        this.IsDisplayQuestions = true;
        var statusObj1 = this.statusList.find(o => o.status == this.QuestionParms.status && o.audienceType == type.id);
        if (statusObj1) {
            var statusObj = Object.assign({}, statusObj1);
            this.QuestionParms = statusObj;
            if (statusObj) {
                this.questionListJosn = JSON.parse(statusObj.question);
                if(this.questionListJosn && this.questionListJosn.length > 0){
                    this.questionListJosn.filter(q => {
                        if (q.choiceOptions.length>0) {
                            try {
                                q.choiceOptions = JSON.parse(q.choiceOptions);
                            } catch (e) {
                            }
                        }
                    })
                }
                this.questionList = this.questionListJosn;
                this.projectService.getDataOnStausSelect(this.questionList);
            }
        }
        else {
            this.questionList = [];
            this.QuestionParms.id = "";
            this.projectService.getDataOnStausSelect(this.questionList);
        }
    }

    GetAudiencetype(value) {
        if (this.translate.store.currentLang == "en")
            return this.audienceTypeList.filter(x => x.id == value).map(x => x.en);
        else
            return this.audienceTypeList.filter(x => x.id == value).map(x => x.ds);
    }

    setIndoorOutDoorDropDown() {
        this.QuestionParms.indoorOutdoorStatusIds = new Array();
        if (this.QuestionParms.indoorOutdoorStatusId) {
            if (this.QuestionParms.indoorOutdoorStatusId.includes(',')) {
                var SplitIndoorOutdoorStatusId = this.QuestionParms.indoorOutdoorStatusId.split(',');
                this.selectIndoorOutDoorStatus.filter(x => {
                    SplitIndoorOutdoorStatusId.filter(y => {
                        if (parseInt(y) == x.id)
                            this.QuestionParms.indoorOutdoorStatusIds.push(x);
                    })
                });
            }
            else {
                this.selectIndoorOutDoorStatus.filter(x => {
                    if (parseInt(this.QuestionParms.indoorOutdoorStatusId) == x.id)
                        this.QuestionParms.indoorOutdoorStatusIds.push(x);
                });
            }
        }
    }

    DeleteQuestionByID(ID) {
        this.questionList = this.questionList.filter(i => i['id'] != ID)
        this.questionList.forEach((element,index) => {
            element['id'] = index+1
        });
        this.QuestionParms.question = JSON.stringify(this.questionList);
        this.statusMessageDetail = this.QuestionParms;
        this.AddStatusMessage();
    }

    saveAndUpdateNotification(value){
        this.BackToStatusList();
        this.getStatusMessage();
    }
        /**
        * on file drop handler
        */
         onFileDropped($event) {
            this.prepareFilesList($event);
        }
        /**
        * handle file from browsing
        */
         fileBrowseHandler(files) {
            this.prepareFilesList(files);
        }
    
        /**
         * Delete file from files list
         * @param index (File index)
         */
        deleteFile(index: number) {
            this.files.splice(index, 1);
        }
    
        /**
         * Convert Files list to normal array list
         * @param files (Files List)
         */
        prepareFilesList(files: Array<any>) {
            for (const item of files) {
                const reader = new FileReader();
                reader.onload = (e: any) => {
                    item.base64 = e.target.result.split(',')[1];
                    item.fileName = item.name;
                    item.fileType = item.type;
                    if (!this.files)
                        this.files = [];
                    this.files.push(item);
                };
                reader.readAsDataURL(item);
            }
        }

        onCheckNotification(field){
            if (field == "isSendRemindBySMS"){
                if(this.statusMessageWithoutCoworkerQuestionForm.controls['isSendRemindBySMS'].value)
                    this.statusMessageWithoutCoworkerQuestionForm.controls['smsText'].setValue(this.smsText);
                else
                    this.statusMessageWithoutCoworkerQuestionForm.controls['smsText'].setValue(null);
            }
            if (field == "isSendRemindByEmail"){
                if (this.statusMessageWithoutCoworkerQuestionForm.controls['isSendRemindByEmail'].value)
                    this.statusMessageWithoutCoworkerQuestionForm.controls['emailTemplateHTML'].setValue(this.emailText);
                else
                    this.statusMessageWithoutCoworkerQuestionForm.controls['emailTemplateHTML'].setValue(null);
            }
            if (field == "isSendRemindByNotification"){
                if(this.statusMessageWithoutCoworkerQuestionForm.controls['isSendRemindByNotification'].value)
                    this.statusMessageWithoutCoworkerQuestionForm.controls['notificationText'].setValue(this.notificationText);
                    else
                    this.statusMessageWithoutCoworkerQuestionForm.controls['notificationText'].setValue(null);
            }
        }

        GetCustomerById() {
            var tempSettingObject = this.settingMasterValues.find(s => s.key1 == "Project" && s.key2 == "ReminderScheduleEmailTemplate").value;
            if (tempSettingObject)
                this.emailText = tempSettingObject;
        }
}
