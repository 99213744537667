export const locale = {
  lang: 'ds',
  data: {
    'FILTERS': {
      'TEXTSEARCH': 'Søg efter navn, e-mail, telefon, adresse',
    },
    'FIELDS': {
        'CustomerName': 'Daglig salg kundensnavn',
      'Not-Found-Customer': 'Ingen kunde fundet',
    },
    'BUTTONS': {
      'StaffPurchaseCash': 'Personale Køb Kontant',
      'StoredPurchase': 'Butiks køb',
      'DailySales':'Daglig salg',
    },
    'MESSAGES': {
        'Success': 'Success',
      }
  }
};
