import { Component, OnInit, Inject } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FuseTranslationLoaderService } from '../../../core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as moment from 'moment';
import { ReminderFull } from '../../model/Reminder';
import { environment } from 'environments/environment';
import { VibeImgShowcaseComponent } from '../vibe-img-showcase/vibe-img-showcase.component';

@Component({
    selector: 'reminder-replay-dialog',
    styleUrls: ['./replay.component.scss'],
    templateUrl: './replay.component.html'
})
export class ReminderReplayDialog implements OnInit {
    objReminder: ReminderFull;
    selectedFiles: any[] = [];
    today: Date = new Date();

    constructor(
        private dialog: MatDialog,
        private translationLoader: FuseTranslationLoaderService,
        public dialogRef: MatDialogRef<ReminderReplayDialog>,
        private datePipe: DatePipe,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
        this.objReminder = new ReminderFull();
        this.objReminder.text = this.data.text;
        this.objReminder.replay = this.data.replay;
        if (!this.data.reminderTime) {
            this.data.reminderTime = moment.utc(this.data.reminderDate).format('HH:mm');
        }
        this.objReminder.reminderDate = this.data.reminderDate;
        this.objReminder.reminderTime = this.data.reminderTime;
        this.objReminder.imagePath = this.data.replyImagePath;
        this.objReminder.completedByName = this.data.completedByName ? this.data.completedByName : "";
        this.objReminder.reminderStatus = this.data.reminderStatus;
        //this.adapter.setLocale('da');

        if (this.data.replyImagePath) {
            JSON.parse(this.data.replyImagePath).forEach(imgPath => {
                this.selectedFiles.push(environment.url + imgPath)
            });
        }
    }
    updateReminder() {
        this.data.replay = this.objReminder.replay;
        this.data.selectedFiles = this.selectedFiles.length ? this.selectedFiles : null;
        this.dialogRef.close(this.data)
    }
    onCloseDialog(e) {
        this.dialogRef.close(e)
    }

    // ChangeImage(event) {
    //   this.objReminder.image = event;
    // }
    OnuploadFile(event) {
        if (event.target.files.length > 0)
            this.selectedFiles = event.target.files;
    }

    showImgShowCase(imgs) {
        const dialogRef = this.dialog.open(VibeImgShowcaseComponent, {
            data: { Imgs: imgs }
        });
        dialogRef.afterClosed().subscribe(result => {
        });
    }
}
