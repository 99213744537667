import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observer } from 'rxjs/Observer';
import { ChangePassword } from 'app/main/model/ChangePassword';
import { ApiConstant } from 'app/core/constant/ApiConstant';

@Injectable()
export class ChangePasswordService {
  private actionUrl: string;
  constructor(private http: HttpClient) {
    this.actionUrl = ApiConstant.API_URL + ApiConstant.CHANGE_PASSWORD ;
  }

  public Update<T>(changePassword: ChangePassword): Observable<T> {
    return this.http.put<T>(this.actionUrl, changePassword);
  }

}
