export const locale = {
  lang: 'ds',
  data: {
    'Headers': {
      'QuestionList': 'Spørg kunden om årsagen til afvisning af tilbud:'
    },

    'MESSAGES': {
      'Created': 'Oprettet med succes',
      'Updated': 'Opdateret med succes',
      'Deleted': 'Slettet med succes',
      'Failed': 'Operation mislykket, prøv senere',
      'NoQuestionAnswerFound': 'Ingen Spørgsmål Fundet.',
      'AddTypeList': 'Tilføj mindst en værdi i typelisten.'
    },
    'VALIDATIONS': {
      'QuestionRequired': 'Spørgsmål påkrævet'
    },
    'QuestionTypeCategory': {
      'value1': 'Tekstboks',
      'value2': 'Multi valg',
      'value3': 'Enkeltvalg',
    }
  }
};
