export const locale = {
  lang: 'ds',
  data: {
    'MESSAGES': {
      'Add': 'Oprettet',
      'Updated': 'Opdateret med succes',
    },
    'BUTTONS': {
      'CreateReminder': 'Lav påmindelse',
      'Cancel': 'Gå tilbage',
    },
    'TITLE': {
      'ReminderPagination': 'Antal påmindelser pr. side',
    },
    'FIELDS': {
      'To': 'Til',
      'DateToRemind': 'Påmindelsesdato',
      'Text': 'Tekst',
      'TEXTSEARCH': 'Søg',
      'Yes': 'JA',
      'No': 'NEJ',
      'ReceivedToMe': 'Modtaget til mig',
      'SentByMe': 'Sendt af mig',
      'ReminderDate': 'Vælg Påmindelses dato',
      'StartDate': 'Vælg Startdato',
      'EndDate': 'Vælg Slutdato',
      'SORTING': 'Sorter efter',
      'DeleteAll': 'Slet alt',
      'CompletedDate': 'Læst den',
      'Not-Found-REMINDER': 'Der er ingen påmindelser',
      'Reset':'Opdate',
      'Done': 'Gør alle "Done"',
      'GetAll': 'Vis alle'
    },
    'PlaceHolder': {
      'EnterText': 'Skriv Påmindelses*',
      'ReminderDate': 'Vælg Påmindelses dato*',
      'SelectCoWorker': 'Medarbejder*',
      'Search-worker': 'Søg Medarbejder',
      'Not-Found-Worker': 'Medarbejder ikke fundet',
      'DeleteAlert': 'Er du sikker du på vil slette?',
      'ReminderList': 'Påmindelsesliste',
      'ReminderEndDate': 'Vælg Påmindelse Slutdato',
      'PleaseSelectFromBelow': 'Vælg venligst nedenstående',
      'DoYouWantRepeatThisReminder': 'Vil du Gentag denne påmindelse?'
    },
    'SORTBY':
      [
        {
          Text: 'slutdato ↑',
          Value: 'DATE'
        },
        {
          Text: 'slutdato ↓',
          Value: 'DATE DESC'
        }
      ],
    'selectedReminderRepeatModes': {
      'value1': 'Daglige ',
      'value2': 'Ugentlig',
      'value3': 'Månedlige',
      'value4': 'årlig'
    },
    'selectedReminderListType': {
      'value1': 'Sendt af mig',
      'value2': 'Modtaget til mig'
    },
    'TOOTIPORTITLE':{
      'Edit':'Redigere',
      'Delete':'Slet',
      'Search':'Søg',
      'History':'Historie'
    }
  }
};
