export const locale = {
    lang: 'ds',
    data: {
        'FIELDS': {
            'Name': 'Navn',
            'Phone': 'Telefon',
            'Text': 'Tekst',
            'CountryCode':'Landekode',
            'Not-Found-Country':'Intet land fundet'
        },
        'MESSAGES': {
            'Success': 'SMS er sendt',
            'Failed': 'Mislykkedes',
        },
        'BUTTONS': {
            'Send': 'Send',
            'Back': 'Gå tilbage '
        },
        'VALIDATIONS': {
            'Mobile': 'Telefon er påkrævet',
            'Text': 'Tekst er påkrævet',
            'CountryCode' : 'Landekode er påkrævet'
        },
        'FILTERS': {
            'Country': 'Landenavn'
        },
    }
};