export const locale = {
    lang: 'ds',
    data: {
        'Tabs': {
            'TabFiles': 'Filer',
            'TabBasicDetail': 'Oplysninger',
            'TabNote': 'Bemærk',
            'TabLog':'Log'
        },
        'BUTTONS': {
            'BTNSAVE': 'Gem',
            'BACK': 'Tilbage',
            'BTNCOMPLETED': 'Færdiggjort',
            'BTNREMINDER': 'Indstil påmindelse',
            'InActive': 'Indstil bil som inaktiv',
            'Active': 'Indstil bil som aktiv',
        },
        'FIELDS': {
            'VehicleNo': 'Køretøj nr',
            'IMEINo': 'IMEI No',
            'CarBrand': 'Bilmærke',
            'Model': 'Model',
            'RegNo': 'Reg Nr',
            'Color': 'Farve',
            'RegDate': 'Reg Dato',
            'LastSightDate': 'Sidste syn dato',
            'LastSightCoworker': 'Sidste syn Medarbejder',
            'NextSightDate': 'Næste syn dato',
            'NextSightCoworker': 'Næste syn Medarbejder',
            'LastServiceDate': 'Sidste service dato',
            'LastServiceCoworker': 'Sidste service Medarbejder',
            'NextServiceDate': 'Næste service dato',
            'NextServiceCoworker': 'Næste service Medarbejder', 
            'Leasing': 'Leasing',
            'ServiceAgreement': 'Serviceaftale',
            'RoadsideAssistance': 'Vejhjælp',
            'CompanyName': 'Firma Navn',
            'ContactPerson': 'Kontakt Person',
            'PhoneNo': 'Telefon Nr.',
            'RunningKM': 'Løber KM',
            'NextServiceAfterKM': 'Næste Service Efter KM',
            'LastServiceKM': 'Sidste Service KM',
            'NextServiceKM': 'Næste Service KM',
            'AssignCoworker': 'Tildel Medarbejder',
            'ParticulateFilter': 'Partikelfilter',
            'Adblue': 'Adblue',
            'SummerTires': 'Sommerdæk',
            'WinterTires': 'Vinterdæk',
            'AllSeasonTires': 'Helårsdæk',
            'NanolinkDevice':'Nanolink enhed'
        },
        'VALIDATIONS': {
            'VehicleNo': 'Indtast køretøjsnr',
            'Coworker': 'Gå ind Medarbejder navn',
        },
        'MESSAGES': {
            'CompletedNextSide': 'Vil du ændre næste syn til {Date} ?',
            'CompletedNextService': 'Vil du ændre næste service til {Date} ?',
            'ItemNumberExist': 'Nummeret eksisterer allerede',
            'Created': 'Oprettet med succes',
            'Updated': 'Opdateret med succes',
            'Deleted': 'Slettet med succes',
            'ReminderConfirmation': 'Vil du indstille påmindelse ?',
            'ReminderAdded': 'Påmindelse blev tilføjet.',
        },
        'TOOTIPORTITLE':{
            'Edit':'Redigere',
            'SendSMS':'Send SMS',
            'SendEmail':'Send E-mail',
            'DeleteCar':'Slet Bil',
        },
    }
};
