import { Component, OnInit, Inject, QueryList, ChangeDetectorRef, ViewChildren, ViewEncapsulation } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ProjectService } from 'app/main/pages/project/project.service';
import { ProjectofferService } from 'app/main/pages/project-offer/project-offer.service';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { fuseAnimations } from '@fuse/animations';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { SettingsService } from "app/main/pages/settings/settings.service";
import { environment } from '../../../../environments/environment';

@Component({
    selector: "answer-status-log",
    templateUrl: "./answer-status-log.component.html",
    styleUrls: ["./answer-status-log.component.scss"],
    animations: fuseAnimations
})

export class AnswerStatusLogComponent implements OnInit {
    showSpinner: boolean = false;
    questionAnswerList = [];
    animationDirection: 'left' | 'right' | 'none';
    courseStepContent: any;
    currentStep: number;
    totalCount: number = 0;
    host: string;

    @ViewChildren(FusePerfectScrollbarDirective)
    fuseScrollbarDirectives: QueryList<FusePerfectScrollbarDirective>;

    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private dilogRef: MatDialogRef<AnswerStatusLogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private projectService: ProjectService,
        private projectofferService: ProjectofferService,
        private settingsService: SettingsService,
        private translate: TranslateService,
        private notificationsService: NotificationsService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseSidebarService: FuseSidebarService) {
        this.translationLoaderService.loadTranslations(danish, english);
        this.animationDirection = 'none';
        this.currentStep = 0;
    }

    ngOnInit() {
        this.host = environment.url;
        var id = this.data.id;
        if (this.data && this.data.tilbudID)
            this.getStatusAnswerForOffer(this.data.tilbudID)
        else {
            if(this.data && this.data.moduleName=="coworker")
                this.getCoworkerReviewAnswerById(id);
            else
                this.getStatusAnswer(id);
        }
    }

    getStatusAnswer(id) {
        this.showSpinner = true;
        this.projectService.GetStatusAnswer(id).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response) {
                    var statusAnswerDetail = response.result;
                    if (statusAnswerDetail.json) {
                        this.questionAnswerList = JSON.parse(statusAnswerDetail.json);                        
                        this.totalCount = this.questionAnswerList.length;
                    }
                    if (statusAnswerDetail.audienceType == 2) {
                        for (let i = 0; i < this.questionAnswerList.length; i++) {
                            if (this.questionAnswerList[i].choiceOptions && this.questionAnswerList[i].choiceOptions.length != 0) {
                                this.questionAnswerList[i].choiceOptions = JSON.parse(this.questionAnswerList[i].choiceOptions);
                            }
                        }
                    }

                }
            },
            error: err => {
                this.showSpinner = false;
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            },
        });
    }
    getStatusAnswerForOffer(id) {
        this.showSpinner = true;
        this.projectofferService.getProjectOfferQuestionAnswer(id).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response) {
                    var statusAnswerDetail = response.result;
                    if (statusAnswerDetail.offerId) {
                        this.questionAnswerList = statusAnswerDetail.questionList;
                        this.questionAnswerList.filter(x => {
                            if(x.questionType == "Rating" && x.answer){
                                x.rating = parseInt(x.answer);
                            }
                        })
                        this.totalCount = this.questionAnswerList.length;
                    } else {
                        if (statusAnswerDetail.json) {
                            this.questionAnswerList = JSON.parse(statusAnswerDetail.json);
                            this.totalCount = this.questionAnswerList.length;
                        }
                        if (statusAnswerDetail.audienceType == 2) {
                            for (let i = 0; i < this.questionAnswerList.length; i++) {
                                if (this.questionAnswerList[i].choiceOptions && this.questionAnswerList[i].choiceOptions.length != 0) {
                                    this.questionAnswerList[i].choiceOptions = JSON.parse(this.questionAnswerList[i].choiceOptions);
                                }
                            }
                        }
                    }
                }
            },
            error: err => {
                this.showSpinner = false;
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            },
        });
    }

    getCoworkerReviewAnswerById(id) {
        this.showSpinner = true;
        this.settingsService.GetCoworkerReviewAnswerById(id).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response) {
                    var statusAnswerDetail = response.result;
                    if (statusAnswerDetail.json) {
                        this.questionAnswerList = JSON.parse(statusAnswerDetail.json);
                        this.totalCount = this.questionAnswerList.length;
                    }
                    if (statusAnswerDetail.audienceType == 2) {
                        for (let i = 0; i < this.questionAnswerList.length; i++) {
                            if (this.questionAnswerList[i].choiceOptions && this.questionAnswerList[i].choiceOptions.length != 0) {
                                this.questionAnswerList[i].choiceOptions = JSON.parse(this.questionAnswerList[i].choiceOptions);
                            }
                        }
                    }

                }
            },
            error: err => {
                this.showSpinner = false;
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            },
        });
    }


    /**
    * After view init
    */
    ngAfterViewInit(): void {
        this.courseStepContent = this.fuseScrollbarDirectives.find((fuseScrollbarDirective) => {
            return fuseScrollbarDirective.elementRef.nativeElement.id === 'course-step-content';
        });
    }

    /**
  * Go to step
  *
  * @param step
  */
    gotoStep(step): void {
        // Decide the animation direction
        this.animationDirection = this.currentStep < step ? 'left' : 'right';

        // Run change detection so the change
        // in the animation direction registered
        this._changeDetectorRef.detectChanges();

        // Set the current step
        this.currentStep = step;
    }

    /**
     * Go to next step
     */
    gotoNextStep(): void {
        // if ( this.currentStep === this.course.totalSteps - 1 )
        // {
        //     return;
        // }

        // Set the animation direction
        this.animationDirection = 'left';

        // Run change detection so the change
        // in the animation direction registered
        this._changeDetectorRef.detectChanges();

        // Increase the current step
        this.currentStep++;
    }

    /**
     * Go to previous step
     */
    gotoPreviousStep(): void {
        if (this.currentStep === 0) {
            return;
        }

        // Set the animation direction
        this.animationDirection = 'right';

        // Run change detection so the change
        // in the animation direction registered
        this._changeDetectorRef.detectChanges();

        // Decrease the current step
        this.currentStep--;
    }

}