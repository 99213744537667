import { Component, OnInit, Inject, ChangeDetectorRef } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { FormBuilder } from '@angular/forms';
import { ShiftType } from 'app/main/model/ProjectStatus';
import * as moment from 'moment';

@Component({
    selector: 'shift-selection',
    templateUrl: './shift-selection.component.html',
    styleUrls: ['./shift-selection.component.scss']
})

export class ShiftSelectionComponent implements OnInit {
    showSpinner: boolean = false;
    shiftType = ShiftType;
    createEventObj: any = {};
    hours: any;
    mins: any;
    isAddBreakTime: boolean = false;
    isBreakTime: boolean = false;
    scheduleDay: any = {};
    coworker: any = {};
    constructor(private translate: TranslateService,
        private translationLoader: FuseTranslationLoaderService,
        private notificationsService: NotificationsService,
        private dialog: MatDialog,
        public dialogRef: MatDialogRef<ShiftSelectionComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _changeDetectorRef: ChangeDetectorRef,
        private fb: FormBuilder) {
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
        this.hours = [{ value: '00' }, { value: '01' }, { value: '02' }, { value: '03' }, { value: '04' }, { value: '05' }, { value: '06' }, { value: '07' }, { value: '08' },
        { value: '09' }, { value: '10' }, { value: '11' }, { value: '12' }, { value: '13' }, { value: '14' }, { value: '15' }, { value: '16' }, { value: '17' },
        { value: '18' }, { value: '19' }, { value: '20' }, { value: '21' }, { value: '22' }, { value: '23' }];

        this.mins = [{ value: '00' }, { value: '15' }, { value: '30' }, { value: '45' }]
        this.scheduleDay = this.data.scheduleDay;
        this.coworker = this.data.coworker;
        this.createEventObj = this.scheduleDay;
        this.createEventObj.isCheckedAll = false;
        this.isAddBreakTime = this.createEventObj.pauseTimer > 0 ? true:false;
        this.isBreakTime = true;
        // if (this.createEventObj.shiftType == 4) 
        //     this.AddBreaktime();
    }
    
    onChangeHoursAndMinute() {
        if (this.createEventObj.shiftType == 4) 
            this.AddBreaktime();
        this.getTotalWorkHourOfCoworker();
    }

    getTotalWorkHourOfCoworker() {
        if (this.createEventObj.shiftType == 2) {
            this.createEventObj.from = "07:00";
            this.createEventObj.to = "14:30";
            this.createEventObj.tid = 7.5;
            this.createEventObj.pauseTimer = 0.5;
            this.createEventObj.hours = "7";
            this.createEventObj.shiftTypeName ='selectedShiftOption.value1'
        }
        else if (this.createEventObj.shiftType == 3) {
            this.createEventObj.from = "07:00";
            this.createEventObj.to = "15:00";
            this.createEventObj.tid = 8.00;
            this.createEventObj.pauseTimer = 0.5;
            this.createEventObj.hours = "07.5";
            this.createEventObj.shiftTypeName ='selectedShiftOption.value2'
        }
        else if (this.createEventObj.shiftType == 4) {
            var from = this.createEventObj.fromStartHour + ":" + this.createEventObj.fromStartMinute;
            var to = this.createEventObj.fromEndHour + ":" + this.createEventObj.fromEndMinute;
            var time = moment.utc(moment(to, "HH:mm").diff(moment(from, "HH:mm"))).format("HH:mm")
            var tidTime = moment(time, "HH:mm").format("mm");
            var tidHours = moment(time, "HH:mm").format("HH");
            if (tidTime != "00")
                time = tidHours + ":" + (Number(this.getMinute(tidTime))).toString();
            if (this.isAddBreakTime)
                this.createEventObj.pauseTimer = 0.5;
            else
                this.createEventObj.pauseTimer = 0;
            this.createEventObj.hours = (Number(time.replace(':', '.')) - this.createEventObj.pauseTimer).toString();                
            this.createEventObj.from= from;
            this.createEventObj.to= to;
            this.createEventObj.tid= (Number(time.replace(':', '.'))).toString();               
            this.createEventObj.shiftTypeName = from +' til '+ to;
        }
    }

    AddBreaktime() {
        this.isBreakTime = false;
        this.isAddBreakTime = false;
        if (this.createEventObj.fromEndHour <= '11' && this.createEventObj.fromEndMinute <= '30') {
            this.isBreakTime = false;
        } else if (this.createEventObj.fromEndHour == '11' && this.createEventObj.fromEndMinute < '30') {
            this.isBreakTime = true;
            this.isAddBreakTime = true;
        } else if (this.createEventObj.fromStartHour >= '12' && this.createEventObj.fromStartMinute >= '30') {
            this.isBreakTime = false;
        } else if (this.createEventObj.fromStartHour == '12' && this.createEventObj.fromStartMinute < '30') {
            this.isBreakTime = true;
            this.isAddBreakTime = true;
        } else if (this.createEventObj.fromStartHour >= '12') {
            this.isBreakTime = false;
        } else {
            this.isBreakTime = true;
            this.isAddBreakTime = true;
        }
    }
    getMinute(value) {
        if (value == "00")
            return "00";
        if (value == "15")
            return "25";
        if (value == "30")
            return "50";
        if (value == "45")
            return "75";
    }

    DropDownShiftChange() {
        if (this.createEventObj.shiftType == 4) {
            var ForEndTime: any =0;
            this.createEventObj.fromStartHour = '07';
            this.createEventObj.fromStartMinute = '00';
            ForEndTime = ForEndTime + 7;
            this.createEventObj.fromEndHour = ForEndTime.toString();
            if (ForEndTime < 10) {
                this.createEventObj.fromEndHour = '0' + this.createEventObj.fromEndHour;
            }
            this.createEventObj.fromEndMinute = '00';
            this.AddBreaktime()
        }
        //this.onChangeHoursAndMinute();
        this.getTotalWorkHourOfCoworker();
    }
    getWeekName(date) {
        return moment(date, "YYYY-MM-DD").format("ddd")
    }

    onCheckScheduleDay(value, item) {
        item.isCheckedAll = !value;
    }
    changeBreakTime(){
        this.getTotalWorkHourOfCoworker();
    }

    Change(){
        this.dialogRef.close(this.createEventObj);
    }

    closedPlaning(){
        this.dialogRef.close(null);
    }
}