import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observer } from 'rxjs/Observer';
import { Project, projectCoWorker, ProjectMaterial, emailParams, ProjectBasedNotification } from '../../model/Project';
import { RequestProject, RequestCaseTask, RequestCoWorker, RequestStatusProject, RequestForLocalStorage, RequestStatusLog, RequestProjectMaterialCountForOrdered, RequestProjectRoom, RequestProjectWokringHoursDetail, RequestProjectHoursDetails } from '../../model/RequestBase';
import { ApiConstant } from '../../../../app/core/constant/ApiConstant';
import { Request } from '@angular/http/src/static_request';
import { AuthService } from '../../../core/services/auth.service';
import { RequestWorkHourGroupBy, RequestRequisitionBaseWorkHourGroupBy } from '../../model/RequestBase';
import { CreateFolder, SearchFolder, ListFolder, DeleteBatchFile, MoveFolder } from '../../model/DropBox';
import { CompanyInfoSetting, CompanyInfo } from '../../model/CompanyInfo';
import { EmailSendingParam, ProjectInvoice } from '../../model/common';
import { ProjectAndRequistionInitialStatus } from 'app/constant/common.constant';
import { BehaviorSubject } from 'rxjs';
import { ProjectRoom } from 'app/main/model/ProjectOffer';


@Injectable()
export class ProjectService implements Resolve<any>
{
    projects: any;
    ObjProject: any;
    // MaterialList: any;
    private apiUrl: string;
    private actionUrl: string;
    private settingActionUrl: string;
    private projectMaterialActionUrl: string;
    private actionUrlForCoworkerProject: string;
    role: string = this.authService.getUserRole();
    settingMasterValues: any = [];

    public ProjectListChange = new BehaviorSubject<boolean>(true);
    ProjectListChangeObs = this.ProjectListChange.asObservable();

    constructor(private http: HttpClient, private authService: AuthService) {
        this.apiUrl = ApiConstant.API_URL;
        this.actionUrl = ApiConstant.API_URL + ApiConstant.PROJECT + "/";
        this.projectMaterialActionUrl = ApiConstant.API_URL + ApiConstant.PROJECTMATERIAL;
        this.actionUrlForCoworkerProject = ApiConstant.API_URL + ApiConstant.COWORKER + "/";  
        this.settingMasterValues = Object.assign([], JSON.parse(localStorage.getItem("settingMasterValues")));
        this.settingActionUrl = ApiConstant.API_URL + ApiConstant.SETTINGS + "/";
    }

    public Get<T>(RequestProject: any): Observable<T> {
        let search = this.GetParams(RequestProject);
        let coWorkerId = this.authService.getCoWorkerId();

        //this.http.get<T>(this.actionUrlForCoworkerProject + coWorkerId + "/" + ApiConstant.PROJECT, {
        //  params: search
        //}).subscribe((resp: any) => {
        //  this.projects = resp.result;
        //  this.setBGImageForProjectCards(this.projects);
        //});

        return this.http.get<T>(this.actionUrlForCoworkerProject + coWorkerId + "/" + ApiConstant.PROJECT, {
            params: search
        });
    }



    public setRequestParameter(model) {
        var requestForLocalStorage = new RequestForLocalStorage();
        var val = localStorage.getItem(ApiConstant.LocalRequestModel);
        if (val) {
            requestForLocalStorage = JSON.parse(val);
        }
        requestForLocalStorage.ProjectRequest = model;
        localStorage.setItem(ApiConstant.LocalRequestModel, JSON.stringify(requestForLocalStorage));
    }

    public getRequestParameter() {
        var val = localStorage.getItem(ApiConstant.LocalRequestModel);
        var requestForLocalStorage = new RequestForLocalStorage();
        var requestStatusProject = new RequestStatusProject();
        //requestStatusProject.OrderBy = "Status DESC";
        //this.role = this.authService.getUserRole();
        // if (this.role == "Admin") {           
        //     this.settingMasterValues = Object.assign([], JSON.parse(localStorage.getItem("settingMasterValues"))); 
        //     var tempSettingObject = (this.settingMasterValues.length > 0 && this.settingMasterValues.find(s => s.key1 == "Project" && s.key2 == "SearchSettings")) ? this.settingMasterValues.find(s => s.key1 == "Project" && s.key2 == "SearchSettings").value:null;
        //     if(tempSettingObject && JSON.parse(tempSettingObject))
        //     {
        //         requestStatusProject.multipleStatus = JSON.parse(tempSettingObject).requistionStatus;
        //         requestStatusProject.isIndoorOutdoorTypeProjects = JSON.parse(tempSettingObject).projectIndoorOutdoorStatus; 
        //     }
        //     else {
        //         requestStatusProject.multipleStatus = "1,3,5,6,8,11";//ProjectAndRequistionInitialStatus.multipleStatus; //"6,8,9,11,12";
        //         requestStatusProject.isIndoorOutdoorTypeProjects = ProjectAndRequistionInitialStatus.isIndoorOutdoorTypeProjects; //"1,2,3";
        //     }
        // }
        // else if (this.role == "Maler") {
        //     requestStatusProject.isIndoorOutdoorTypeProjects = ProjectAndRequistionInitialStatus.isIndoorOutdoorTypeProjectsForMaler; //"1,2,3,4,5";
        // }

        if (val) {
            requestForLocalStorage = JSON.parse(val);
            if (requestForLocalStorage.ProjectRequest) {
                 requestStatusProject = requestForLocalStorage.ProjectRequest;
                // var tempSettingObject = (this.settingMasterValues.length > 0 && this.settingMasterValues.find(s => s.key1 == "Project" && s.key2 == "SearchSettings")) ? this.settingMasterValues.find(s => s.key1 == "Project" && s.key2 == "SearchSettings").value:null;
                // requestStatusProject.multipleStatus =  JSON.parse(tempSettingObject).requistionStatus;
                // requestStatusProject.isIndoorOutdoorTypeProjects = JSON.parse(tempSettingObject).projectIndoorOutdoorStatus;
            }
        }
        else {
            this.settingMasterValues = Object.assign([], JSON.parse(localStorage.getItem("settingMasterValues")));
            var tempSettingObject = (this.settingMasterValues.length > 0 && this.settingMasterValues.find(s => s.key1 == "Project" && s.key2 == "SearchSettings")) ? this.settingMasterValues.find(s => s.key1 == "Project" && s.key2 == "SearchSettings").value : null;
            requestStatusProject.multipleStatus = JSON.parse(tempSettingObject).requistionStatus;
            requestStatusProject.isIndoorOutdoorTypeProjects = JSON.parse(tempSettingObject).projectIndoorOutdoorStatus;
        }
        requestStatusProject.before60DayDate = null;
        return requestStatusProject;
    }

    public setRequestParameterForCalender(model) {
        var requestForLocalStorage = new RequestForLocalStorage();
        var val = localStorage.getItem(ApiConstant.LocalRequestModel);
        if (val) {
            requestForLocalStorage = JSON.parse(val);
        }
        requestForLocalStorage.CalenderProjectRequest = model;
        localStorage.setItem(ApiConstant.LocalRequestModel, JSON.stringify(requestForLocalStorage));
    }

    public getRequestParameterForCalender() {
        var val = localStorage.getItem(ApiConstant.LocalRequestModel);
        var requestForLocalStorage = new RequestForLocalStorage();
        var requestStatusProject = new RequestStatusProject();
        requestStatusProject.OrderBy = "Status DESC";
        // this.role = this.authService.getUserRole();
        // if (this.role == "Admin") {
        //     requestStatusProject.multipleStatus = "1,3,5,6,8,11";
        //     requestStatusProject.isIndoorOutdoorTypeProjects = "1,2,3";
        // }
        // else if (this.role == "Maler") {
        //     requestStatusProject.isIndoorOutdoorTypeProjects = "1,2,3,4,5";
        // }

        if (val) {
            requestForLocalStorage = JSON.parse(val);
            if (requestForLocalStorage.CalenderProjectRequest) {
                requestStatusProject = requestForLocalStorage.CalenderProjectRequest;
            }
        }
        else {
            requestStatusProject.multipleStatus =  ProjectAndRequistionInitialStatus.multipleStatusForWorkhoursOrCalender;//"1,3,5,6,8,11";
            requestStatusProject.isIndoorOutdoorTypeProjects = ProjectAndRequistionInitialStatus.isIndoorOutdoorTypeProjectsForMaler;
        }

        return requestStatusProject;
    }

    public GetProjectworkhours<T>(requestWorkHourGroupBy: RequestWorkHourGroupBy): Observable<T> {
        let search = this.GetParams(requestWorkHourGroupBy);
        return this.http.get<T>(this.actionUrlForCoworkerProject + "projectworkhours", {
            params: search
        });
    }

    public GetProjectMonthWiseworkhours<T>(requestWorkHourGroupBy: RequestWorkHourGroupBy): Observable<T> {
        let search = this.GetParams(requestWorkHourGroupBy);
        return this.http.get<T>(this.actionUrlForCoworkerProject + "projectMonthWiseWorkhours", {
            params: search
        });
    }

    public GetRequisitionkhours<T>(requestWorkHourGroupBy: RequestRequisitionBaseWorkHourGroupBy): Observable<T> {
        let search = this.GetParams(requestWorkHourGroupBy);
        return this.http.get<T>(this.actionUrlForCoworkerProject + "requesitionworkhours", {
            params: search
        });
    }

    public GetRequisitionkMontWisehours<T>(requestWorkHourGroupBy: RequestRequisitionBaseWorkHourGroupBy): Observable<T> {
        let search = this.GetParams(requestWorkHourGroupBy);
        return this.http.get<T>(this.actionUrlForCoworkerProject + "requesitionMonthWiseWorkhours", {
            params: search
        });
    }

    public GetProjects<T>(id: any, RequestProject: any): Observable<T> {
        let search = this.GetParams(RequestProject);
        let coWorkerId = this.authService.getCoWorkerId();
        return this.http.get<T>(this.actionUrlForCoworkerProject + id + "/" + ApiConstant.PROJECT, {
            params: search
        });
    }

    public Add<T>(project: Project): Observable<T> {
        return this.http.post<T>(this.actionUrl, project);
    }

    public GetParams(param) {
        let params: HttpParams = new HttpParams();
        for (var key in param) {
            if (param.hasOwnProperty(key)) {
                let val = param[key];
                params = params.append(key, val);
            }
        }
        return params;
    }

    public GetSingle<T>(id: any): Observable<T> {
        return this.http.get<T>(this.actionUrl + id);
    }

    public GetProjectsHoursDetails<T>(NoOfEmployee: any, status: string, purchaseStatus: any, averagePrice: any, IndoorOutdoorStatus: string,autoCalculation: any): Observable<T> {
        return this.http.get<T>(this.actionUrl + "projecthoursdetail?NoOfEmployee=" + NoOfEmployee + "&status=" + status + "&purchaseStatus=" + purchaseStatus + "&averagePrice=" + averagePrice + "&indoorOutdoorStatus=" + IndoorOutdoorStatus+"&autoCalculation="+autoCalculation);
    }

    public GetProjectsHoursDetailsWithStatusBase<T>(NoOfEmployee: any, status: string, IndoorOutdoorStatus: any, averagePrice: any): Observable<T> {
        return this.http.get<T>(this.actionUrl + "projecthoursdetailWithStatusBase?NoOfEmployee=" + NoOfEmployee + "&status=" + status + "&IndoorOutdoorStatus=" + IndoorOutdoorStatus + "&averagePrice=" + averagePrice);
    }

    public GetProjectHoursDetail<T>(NoOfEmployee: any, status: string, averagePrice: any): Observable<T> {
        return this.http.get<T>(this.actionUrl + "projecthoursdetail?NoOfEmployee=" + NoOfEmployee + "&status=" + status + "&averagePrice=" + averagePrice);
    }

    public GetProjectHoursDetailForPlanning<T>(NoOfEmployee: any): Observable<T> {
        return this.http.get<T>(this.actionUrl + "projecthoursdetailforplaning?NoOfEmployee=" + NoOfEmployee);
    }

    public GetSingleMaterial<T>(id: any): Observable<T> {
        var url = ApiConstant.API_URL + ApiConstant.MATERIAL + "/";
        return this.http.get<T>(url + id);
    }

    public Update<T>(id: any, project: Project): Observable<T> {
        return this.http.put<T>(this.actionUrl + id, project);
    }

    public GetInquiry<T>(inquiryId: string): Observable<T> {
        return this.http.get<T>(this.apiUrl + ApiConstant.INQUIRY + "/" + inquiryId);
    }

    public UpdateProjectToInquiry<T>(inquiryId: string, projectId: string): Observable<T> {
        return this.http.put<T>(this.apiUrl + ApiConstant.INQUIRY + "/" + inquiryId + "/" + projectId, null);
    }

    public Delete<T>(id: any): Observable<T> {
        return this.http.delete<T>(this.actionUrl + id);
    }

    public DeleteProject<T>(id: any, isUpDownQty:boolean): Observable<T> {
        return this.http.delete<T>(this.actionUrl +"DeleteProjectWithMaterialInventory?projectNumber="+ id + "&isUpDownStock=" + isUpDownQty);
    }

    public AddFileToProject<T>(id: any, objFile: any): Observable<T> {
        return this.http.post<T>(this.actionUrl + id + "/file", objFile);
    }

    public getProjectFiles<T>(id: any): Observable<T> {
        return this.http.get<T>(this.actionUrl + id + "/file");
    }

    public removeFileFromProject<T>(id: any, fileId: any): Observable<T> {
        return this.http.delete<T>(this.actionUrl + id + "/file/" + fileId);
    }

    public addMaterialToProject<T>(objMaterial: any): Observable<T> {
        return this.http.post<T>(this.projectMaterialActionUrl, objMaterial);
    }

    public addOrUpdateMaterialToProject<T>(objMaterial: any): Observable<T> {
        return this.http.post<T>(this.projectMaterialActionUrl + "/AddUpdateProjectMaterial", objMaterial);
    }
    public addOrUpdateMaterialToProjectWithFlag<T>(objMaterial: any): Observable<T> {
        return this.http.post<T>(this.projectMaterialActionUrl + "/AddUpdateProjectMaterialWithFlag", objMaterial);
    }

    public addProjectQuickSupplierMaterial<T>(objMaterial: any): Observable<T> {
        return this.http.post<T>(this.projectMaterialActionUrl + "/CreateSupplierQuickMaterial", objMaterial);
    }

    public addCoWorkerToProject<T>(objCoWorker: projectCoWorker): Observable<T> {
        return this.http.post<T>(this.actionUrl + objCoWorker.projectId + "/coworker", objCoWorker);
    }

    public DownloadSupplierPDF<T>(): Observable<T> {
        return this.http.get<T>(this.apiUrl + ApiConstant.MATERIAL + "/getemailtosupplier");
    }

    public SendSupplierEmail<T>(): Observable<T> {
        return this.http.get<T>(this.apiUrl + ApiConstant.MATERIAL + "/sendemailtosupplier");
    }

    public GetProjectMaterialList<T>(RequestCaseTask: any): Observable<T> {
        let caseParam = this.GetParams(RequestCaseTask);
        return this.http.get<T>(this.projectMaterialActionUrl, {
            params: caseParam
            //,
            //headers: {
            //  'Content-Type': 'application/json'
            //}
        });
    }
    public GetProjectInvoice<T>(projectId: any, reqisitionId: any, isDisplayPersonalPrice: boolean = false): Observable<T> {
        return this.http.get<T>(this.actionUrl + "getProjectInvoice?projectId=" + projectId + "&reqisitionId=" + reqisitionId+"&isDisplayPersonalPrice="+ isDisplayPersonalPrice, {});
    }
    public UploadProjectInvoiceToDropbox<T>(objInvoice: ProjectInvoice): Observable<T> {
        var url = this.actionUrl + "uploadProjectInvoiceToDropbox";
        return this.http.post<T>(url, objInvoice);
    }

    public removeProjectMaterial<T>(id: any): Observable<T> {
        return this.http.delete<T>(this.projectMaterialActionUrl + "/" + id);
    }

    public deleteProjectMaterial<T>(id: any, isUpDownQty:boolean): Observable<T> {
        return this.http.delete<T>(this.projectMaterialActionUrl + "/deleteMaterialByID/" + id+"?isUpDownStock=" + isUpDownQty);
    }

    public deleteProjectMaterialMultiple<T>(obj: any): Observable<T> {
        const options = {
            headers: new HttpHeaders({
            }),
            body: obj
        }
        return this.http.delete<T>(this.projectMaterialActionUrl + "/deletemultipleentry", options);
    }

    public SendEmail<T>(emailSendingParam: EmailSendingParam): Observable<T> {
        var url = ApiConstant.API_URL + ApiConstant.REMINDER + "/";
        return this.http.post<T>(url + "SendEmail", emailSendingParam);
    }

    public GetMatchCustomersOnInquiry<T>(id: any): Observable<T> {
        return this.http.get<T>(ApiConstant.API_URL + ApiConstant.INQUIRY + "/" + "GetMatchCustomersOnInquiry/" + id);
    }

    public editProjectMaterial<T>(id: any): Observable<T> {
        return this.http.get<T>(this.projectMaterialActionUrl + "/" + id);
    }
    public GetProjectCoWorkerList<T>(requestCoWorker: RequestCoWorker): Observable<T> {

        ///api/v1/Projects/{Id}/coworkers
        let coworkerParam = this.GetParams(requestCoWorker);
        return this.http.get<T>(this.actionUrl + requestCoWorker.ProjectId + "/coworkers", {
            params: coworkerParam
            //,
            //headers: {
            //  'Content-Type': 'application/json'
            //}
        });
    }

    public GetProjectRequisition<T>(id, name): Observable<T> {
        return this.http.get<T>(this.actionUrl + id + "/" + name + "/ProjectFileInfo");
    }

    public GetProjectDonotAddWorkHour<T>(id): Observable<T> {
        return this.http.get<T>(this.actionUrl + "GetRequesitionUpdateHoure/" + id);
    }

    public GetProjectAllFileInfo<T>(id): Observable<T> {
        return this.http.get<T>(this.actionUrl + id + "/ProjectAllFileInfo");
    }

    public UpdateProjectDonotAddWorkHour<T>(id, flag): Observable<T> {
        return this.http.put<T>(this.actionUrl + "GetRequesitionUpdateHoure/" + id + "/" + flag, null);
    }
    public UpdateRequisitionDonotAddWorkHour<T>(id, flag): Observable<T> {
        return this.http.put<T>(this.actionUrl + "updateRequisitionDonotAddWorkHour/" + id + "/" + flag, null);
    }

    public UpdateRequisitionExtraTabDescription<T>(id, name, info: CompanyInfoSetting): Observable<T> {
        return this.http.put<T>(this.actionUrl + id + "/" + name + "/ProjectFileInfoTab", info);
    }

    public MoveRequisitionToSelectedProject<T>(requisitionId, projectId): Observable<T> {
        return this.http.put<T>(this.actionUrl + requisitionId + "/moveRequisitionToProject/" + projectId, null);
    }

    public MoveProjectMaterial<T>(requisitionId,requistionMaterialList: any): Observable<T> {
        return this.http.post<T>(this.actionUrl + "moveProjectMaterial/" + requisitionId, requistionMaterialList);
    }

    public SendFileNotificationToEmployee<T>(projectId, model: ProjectBasedNotification): Observable<T> {
        return this.http.put<T>(this.apiUrl + "Reminders/sendprojectbasednotification/" + projectId, model);
    }

    public removeProjectCoWorker<T>(objCoworker: projectCoWorker): Observable<T> {
        return this.http.delete<T>(this.actionUrl + objCoworker.id + "/coworker/" + objCoworker.coworkerId + "/Requisition/" + objCoworker.fileAssignmentId);
    }

    public removeAllProjectCoWorker<T>(projectId, coworkerId): Observable<T> {
        return this.http.delete<T>(this.actionUrl + projectId + "/deleteAllRequesitionCoworker/" + coworkerId);
    }

    public UpdateProjectMaterial<T>(id: any, objProjectMaterial: any): Observable<T> {
        return this.http.put<T>(this.projectMaterialActionUrl + "/" + id, objProjectMaterial);
    }

    public GetProjectPrice<T>(id: any): Observable<T> {
        return this.http.get<T>(this.actionUrl + id + "/" + "price");
    }

    public GetProjectStatusLog<T>(id: any, flag: number): Observable<T> {
        return this.http.get<T>(this.actionUrl + id + "/" + "statusLog/" + flag);
    }

    public SearchFolderDrpBox<T>(searchFolder: SearchFolder): Observable<T> {
        return this.http.post<T>(ApiConstant.DrpBoxSearch, searchFolder);
    }

    public CreateFolderDrpBox<T>(createFolder: CreateFolder): Observable<T> {
        return this.http.post<any>(ApiConstant.DrpBoxCreate, createFolder);
    }

    public MoveFolderDrpBox<T>(moveFolder: MoveFolder): Observable<T> {
        return this.http.post<any>(ApiConstant.MoveFolderDrpBox, moveFolder);
    }
    public CopyFolderDrpBox<T>(moveFolder: MoveFolder): Observable<T> {
        return this.http.post<any>(ApiConstant.CopyFolderDrpBox, moveFolder);
    }

    public ListFolderDrpBox<T>(listFolder: ListFolder): Observable<T> {
        return this.http.post<any>(ApiConstant.DrpBoxList, listFolder);
    }

    public DeleteBatchFileDrpBox<T>(deleteBatchFile: DeleteBatchFile): Observable<T> {
        return this.http.post<any>(ApiConstant.DrpBoxDelete, deleteBatchFile);
    }

    public GetDrpBoxSharedLink<T>(listFolder: ListFolder): Observable<T> {
        return this.http.post<any>(ApiConstant.DrpBoxSharedLink, listFolder);
    }

    //Log
    public GetProjectLogs<T>(RequestCustomer: any): Observable<T> {
        let search = this.GetParams(RequestCustomer);
        return this.http.get<T>(this.actionUrl + "getProjectCycleLogs", {
            params: search
        });
    }

    public GetProjectEmailLogDetails<T>(Id: any): Observable<T> {
        return this.http.get<T>(this.actionUrl + "getProjectEmailLogDetails/" + Id);
    }

    public GetProjectLogDetails<T>(Id: any): Observable<T> {
        return this.http.get<T>(this.actionUrl + "getProjectLogDetails/" + Id);
    }

    public GetRequisitionLogDetail<T>(Id: any): Observable<T> {
        return this.http.get<T>(this.actionUrl + "getRequisitionLogDetails/" + Id);
    }
    public GetRequisitionMaterialLogDetails<T>(Id: any): Observable<T> {
        return this.http.get<T>(this.apiUrl + "Casetasks/getRequisitionMaterialLogDetails/" + Id);
    }
    public GetRequisitionCoworkerLogDetails<T>(Id: any): Observable<T> {
        return this.http.get<T>(this.actionUrl + "getRequisitionCoworkerLogDetails/" + Id);
    }

    public SendEmailToCustomerNotify<T>(emailSendingParam: EmailSendingParam, planningId: any): Observable<T> {
        var url = ApiConstant.API_URL + ApiConstant.WORK_HOURS + "/";
        return this.http.post<T>(url + "notifyToCustomerForPaining?planningId=" + planningId, emailSendingParam);
    }

    public AddUpdateStatusMessage<T>(statusMessage: any): Observable<T> {
        return this.http.post<T>(this.actionUrl + "addUpdateStatusMessage", statusMessage);
    }

    public GetStatusMessageList<T>(): Observable<T> {
        return this.http.get<T>(this.actionUrl + "getStatusMessageList");
    }

    public DeleteStatusMessage<T>(id): Observable<T> {
        return this.http.delete<T>(this.actionUrl + "deleteStatusMessage?Id=" + id);
    }

    public GetProjectQuestion<T>(id): Observable<T> {
        return this.http.get<T>(this.actionUrl + "edit" + id);
    }

    public AddStatusAnswer<T>(answer: any): Observable<T> {
        return this.http.post<T>(this.actionUrl + "addStatusAnswer", answer);
    }

    public GetStatusAnswer<T>(id: any): Observable<T> {
        return this.http.get<T>(this.actionUrl + "GetStatusAnswer/" + id);
    }

    public GetStatisticQuestions<T>(RequestCustomer: any): Observable<T> {
        let search = this.GetParams(RequestCustomer);
        return this.http.get<T>(this.actionUrl + "getStatisticQuestions", {
            params: search
        });
    }

    public GetInactiveProjects<T>(RequestData: any): Observable<T> {
        let search = this.GetParams(RequestData);
        return this.http.get<T>(this.actionUrl + "getInactiveProjects", {
            params: search
        });
    }

    public GetHoursDetailsWithProjectDetailsList<T>(Status: string, indoorOutdoorStatus: string): Observable<T> {
        return this.http.get<T>(this.actionUrl + "getprojectallhoursdetailWithStatusBase?status=" + Status + "&IndoorOutdoorStatus=" + indoorOutdoorStatus);
    }
    
    public getprojectallhoursdetailWithStatusBaseAndOrderBy<T>(request: RequestProjectHoursDetails): Observable<T> {
        let search = this.GetParams(request);
        return this.http.get<T>(this.actionUrl + "getprojectallhoursdetailWithStatusBaseAndOrderBy", {
            params: search
        });
    }

    public GetCoworkerOnProject<T>(id: any): Observable<T> {
        return this.http.get<T>(this.actionUrl + id + "/allrequisitions/coworkers");
    }

    public GetMessageLogs<T>(RequestMessageLog: any): Observable<T> {
        let search = this.GetParams(RequestMessageLog);
        return this.http.get<T>(this.actionUrl + "getMessageLogs", {
            params: search
        });
    }

    public GetProjectMaterialForOrdered<T>(RequestProjectMaterialCountForOrdered: any): Observable<T> {
        let caseParam = this.GetParams(RequestProjectMaterialCountForOrdered);
        return this.http.get<T>(this.projectMaterialActionUrl+"/getProjectMaterialCountForOrdered", {
            params: caseParam
        });
    }

    public GetProjectRequistionCoworkersByProjectID<T>(id: any): Observable<T> {
        return this.http.get<T>(this.actionUrl + id + "/" + "getProjectRequisitionCoworkersByProjectId");
    }
    public GetProjectPriceDiscountList<T>(): Observable<T> {
        return this.http.get<T>(this.actionUrl + "getProjectPriceDiscountSettings" + "?param1=ProjectPriceDiscount");
    }
    
    public GetUserRoleList<T>(): Observable<T> {
        return this.http.get<T>(this.settingActionUrl + "GetUserRoleList");
    }

    public updateRole<T>(id: any, role: any): Observable<T> {
        return this.http.put<T>(this.settingActionUrl + "UpdateUserRole/" + id, role);
    }

    public AddOfferEstimateMaterialToProject<T>(requisitionId, requistionMaterialList: any): Observable<T> {
        return this.http.post<T>(this.actionUrl + "addOfferEstimateMaterialToProject/" + requisitionId, requistionMaterialList);
    }

    public DeleteCoworkerOnWorkhoursByList<T>(request: any): Observable<T> {
        const options = {
            headers: new HttpHeaders({
            }),
            body: request
        }
        return this.http.delete<T>(this.actionUrl + "deleteCoworkerOnWorkhoursByList",  options);
    }
    setBGImageForProjectCards(Projects) {
        Projects.filter(project => {
            if (project.indoorOutdoorStatusId && !project.indoorOutdoorStatusId.includes(',')) {
                switch (project.indoorOutdoorStatusId) {
                    case "1": {
                        project.backgroundSrc = "../assets/images/project-card-background-images/Manintheroom.png";
                        break;
                    }
                    case "2": {
                        project.backgroundSrc = "../assets/images/project-card-background-images/sun.png";
                        break;
                    }
                    case "3": {
                        project.backgroundSrc = "../assets/images/project-card-background-images/SprayPainting.png";
                        break;
                    }
                    case "4": {
                        project.backgroundSrc = "../assets/images/project-card-background-images/Reclamation.png";
                        break;
                    }
                    case "5": {
                        project.backgroundSrc = "../assets/images/project-card-background-images/DrawingMan.png";
                        //project.reqStatus = 8;

                        break;
                    }
                    case "6": {
                        project.backgroundSrc = "../assets/images/project-card-background-images/Noedsjo.png";
                        //project.reqStatus = 8;

                        break;
                    }
                    default: {
                        project.backgroundSrc = "";
                        break;
                    }
                }
            }
            //else if (project.indoorOutdoorStatusId && project.indoorOutdoorStatusId.includes(',')) {
            //  if (project.indoorOutdoorStatusId.includes('5') || project.indoorOutdoorStatusId.includes('6')) {
            //    //project.reqStatus = 8;
            //  }
            //}
        })
    }

    public onProjectStatusChange = new BehaviorSubject<any>("");
    onStausSelectChangeObs = this.onProjectStatusChange.asObservable();

    getDataOnStausSelect(data) {
        this.onProjectStatusChange.next(data);
    }

    public GetProjectRoomList<T>(requestProjectRoom: RequestProjectRoom): Observable<T> {
        let search = this.GetParams(requestProjectRoom);
        return this.http.get<T>(this.actionUrl + "projectRooms", {
            params: search
        });
    }

    public AddProjectRoom<T>(projectRooms: ProjectRoom[]): Observable<T> {
        return this.http.post<T>(this.actionUrl + "projectRoomsList/", projectRooms);
    }

    public DeleteProjectRoom<T>(projectId: string, id: string): Observable<T> {
        return this.http.delete<T>(this.actionUrl + "projectRooms/" + projectId + '/' + id);
    }

    public GetProjectEmailCount<T>(id): Observable<T> {
        return this.http.get<T>(this.actionUrl + "getProjectEmailsCount/" + id);
    }

    public setRequestParameterForWorkingHoursDetail(model) {
        var requestForLocalStorage = new RequestForLocalStorage();
        var val = localStorage.getItem(ApiConstant.LocalRequestModel);
        if (val) {
            requestForLocalStorage = JSON.parse(val);
        }
        requestForLocalStorage.ProjectWorkingHoursDetailRequest = model;
        localStorage.setItem(ApiConstant.LocalRequestModel, JSON.stringify(requestForLocalStorage));
    }

    public getRequestParameterForWorkingHoursDetail() {
        var val = localStorage.getItem(ApiConstant.LocalRequestModel);
        var requestForLocalStorage = new RequestForLocalStorage();
        var requestStatusProject = new RequestProjectWokringHoursDetail();
        requestStatusProject.OrderBy = "Status DESC";
        // this.role = this.authService.getUserRole();
        // if (this.role == "Admin") {
        //     requestStatusProject.multipleStatus = "1,3,5,6,8,11";//ProjectAndRequistionInitialStatus.multipleStatus; //"6,8,9,11,12";
        //     requestStatusProject.isIndoorOutdoorTypeProjects = ProjectAndRequistionInitialStatus.isIndoorOutdoorTypeProjects; //"1,2,3";
        // }
        // else if (this.role == "Maler") {
        //     requestStatusProject.isIndoorOutdoorTypeProjects = ProjectAndRequistionInitialStatus.isIndoorOutdoorTypeProjectsForMaler; //"1,2,3,4,5";
        // }

        if (val) {
            requestForLocalStorage = JSON.parse(val);
            if (requestForLocalStorage.ProjectWorkingHoursDetailRequest) {
                requestStatusProject = requestForLocalStorage.ProjectWorkingHoursDetailRequest;
            }
        }
        else {
            requestStatusProject.multipleStatus =  ProjectAndRequistionInitialStatus.multipleStatusForWorkhoursOrCalender;//"1,3,5,6,8,11";
            requestStatusProject.isIndoorOutdoorTypeProjects = ProjectAndRequistionInitialStatus.isIndoorOutdoorTypeProjectsForMaler;
        }
        requestStatusProject.before60DayDate = null;
        return requestStatusProject;
    }

    refreshProjectList(value: boolean) {
        this.ProjectListChange.next(value);
    }

    /**
     * Resolve
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    }

    public getRequestParameterForMonth() {
        var requestStatusProjectMonth;
        this.settingMasterValues = JSON.parse(localStorage.getItem("settingMasterValues"));
        var tempSettingObject = (this.settingMasterValues.length > 0 && this.settingMasterValues.find(s => s.key1 == "Project" && s.key2 == "SearchSettings")) ? this.settingMasterValues.find(s => s.key1 == "Project" && s.key2 == "SearchSettings").value : null;
        if (tempSettingObject && JSON.parse(tempSettingObject))
            requestStatusProjectMonth = JSON.parse(tempSettingObject).projectMonth;
        return requestStatusProjectMonth;
    }

    public MultipleProjectsAssignCoworkers<T>(projectAssignCoworker: any): Observable<T> {
        return this.http.post<T>(this.actionUrl + "MultipleProjectsAssignCoworkers", projectAssignCoworker);
    }

    public ProjectTaskLocation<T>(projctLocation: any): Observable<T> {
        return this.http.post<T>(this.actionUrl + "ProjectTaskLocation", projctLocation);
    }

    public GetProjectTaskLocation<T>(RequestProjectLocation: any): Observable<T> {
        let search = this.GetParams(RequestProjectLocation);
        return this.http.get<T>(this.actionUrl + "ProjectTaskLocation/List/", {
            params: search
        });
    }

    public DeleteProjectTaskLocation<T>(id: string): Observable<T> {
        return this.http.delete<T>(this.actionUrl + "ProjectTaskLocation/" + id);
    }

    public UpdateProjectTaskLocation<T>(id: any, project: any): Observable<T> {
        return this.http.put<T>(this.actionUrl + "ProjectTaskLocation/" + id, project);

    }

    public CreateProjectFromExcel<T>(formData: FormData) {
        return this.http.post(this.actionUrl + "CreateProjectFromExcel", formData, { headers: { IsContentType: "true" } });
    }
}
