export const locale = {
  lang: 'ds',
  data: {
    'MESSAGES': {
      'Add': 'Oprettet',
    },
    'BUTTONS': {
      'SelectProject': 'Vælg Projekt',
      'CreateReminder': 'Lav påmindelse',
        'Cancel': 'Gå tilbage',
        'CreateReviewQuestion': 'Opret medarbejderspørgsmål',
    },
    'TITLE': {
    },
    'FIELDS': {
      'ReminderDate': 'Vælg Påmindelses dato',
      'ChooseFile': 'Vælg fil',
      'NoFileSelected': 'Ingen fil valgt',
      'FileSelected': 'Fil valgt',
      'ReviewQuestion': 'tilføj spørgsmål',
    },
    'PlaceHolder': {
      'EnterText': 'Skriv Påmindelses*',
      'ReminderDate': 'Vælg Påmindelses dato & tid*',
      'ReminderEndDate': 'Vælg Påmindelse Slutdato & tid',
      'PleaseSelectFromBelow': 'Vælg venligst nedenstående',
      'DoYouWantRepeatThisReminder': 'Vil du Gentag denne påmindelse?',
      'ProjectNumber': 'Sag nummer',
    },
    'selectedReminderRepeatModes': {
      'value1': 'Daglige ',
      'value2': 'Ugentlig',
      'value3': 'Månedlige',
      'value4': 'årlig'
    }
  }
};
