export const locale = {
  lang: 'en',
  data: {
    'TABS': {
    },
    'MESSAGES': {
      'Add': 'Added successfully',
      'Add_Error': 'No record found on Vagttider or Vagtplan table, please contact to administrator.',
      'Some_Coworker_Add_Error': 'Some coworker can not add work hour.',
      'CanNotAddWorkHour': '{@Coworker} co workers Cannot add working hour.',
      'DeleteNonWorkingHoursOne': 'Do you want to delete',
      'DeleteNonWorkingHoursTwo': 'from',
      'DeleteNonWorkingHoursThree': 'to',
      'CannotApplyLeave':'@Coworker has been already applied working hours on @date,so you cannot apply leave.'
    },
    'Button': {
      'Plan': 'Plan',
      'Save': 'Save',
      'Cancel': 'Cancel',
      'Delete': 'Delete',
      'GoToProject': 'Go to project',
    },
    'FIELDS': {
      'Coworker': 'Coworker',
      'Project': 'Project',
      'StartDate': 'Start Date ',
      'EndDate': 'End Date ',
      'Shift': 'Period of time',
      'Not-Found-Worker': 'Co-worker not found',
      'Not-Found-Project': 'Project not found',
      'Requisition': 'Requisition',
      'EnterStarttime': 'Enter Start time',
      'EndTime': 'Enter End Time',
      'Startime': 'Startime',
      'Endtime': 'Endtime',
      'BreakTime': 'Add Break time (30 min)',
      'WorkhoursType': 'Workhours type',
      'nonStatus': 'type',
      'nonText': 'Text',
      'WeekNumber': 'Week number',
      'LeaveStatus': 'Status',
      'AdminComment': 'Admin comment',
      'PaymentOption': 'Payment option',
      'PlaninginWeekend': 'Planning in weekend',
    },
    'selectedOption': {
      'value1': 'Add 07:00 to 14:30',
      'value2': 'Add 07:00 to 15:00',
      'value3': 'Add Hours Manually',
    },
    'Lable': {
      'Timer': 'Timer',
      'HoursLeft': 'Hours Left',
      'Lift': 'Left',
      'Stigemand': 'Riseman',
      'RequisitionText': 'Task text',
      'No': 'No',
      'Yes': 'Yes',
      'Estimate': 'Estimate Price',
      'Price-included': 'Price incl. VAT',
      'Price-excluded': 'Price excl. VAT',
      'VAT': 'VAT/moms',
      'MaterialTotalPrice': 'Material Total',
      'MaterialTotalPriceIncludeVAT': 'Material Total incl. VAT',
      'MaterialPersonalTotalPrice': 'Material Personal Price Total',
      'hoursOnCase': 'Hours on case',
      'noOfDays': 'No of days',
      'Hoursspent': 'Hours spent',
      'Name': 'Name',
      'Hours': 'Hours',
      'TotalHours': 'Total Hours',
      'ScheduledDates': 'Scheduled dates',
      'VisitDays': 'Visit days',
      'Dates': 'Dates'
    },
    'selectedWorkHourStatus': {
      'value1': 'Vacation',
      'value2': 'Sick',
      'value3': 'Holiday of the work ',
      'value4': 'Holiday with payment',
      'value5': 'Holiday without payment',
      'value6': 'School or learning',
      'value7': 'No payment for extra work but holiday for it ',
      'value8': 'Other things',
      'value9': 'Offer'
    },
    'selectedWorkHoursApproveLeaveOption': {
      'value1': 'Apply',
      'value2': 'Approved',
      'value3': 'Rejected',
    },
    'paymentOptionType': {
      'value1': 'Without Pay',
      'value2': 'With Pay',
    },
    'selectedWeekendOption': {
      'value1': 'Without Weekend',
      'value2': 'With Weekend'
    },
    'selectedShiftOption': {
      'value1': '07:00 til 14:30',
      'value2': '07:00 til 15:00',
      'value3': 'Hours Manually',
  },
  }
};
