export const locale = {
    lang: 'en',
    data: {
        'TITLE': {
            'SMSHeader': 'SMS Credintial',
        },
        'FIELDS': {
            'ApiKey': 'Api Key',
            'ApiSecret': 'Api Secret',
            'FromMobileNumber': 'From Mobile Number',
         },
        'VALIDATIONS': {
        },
        'MESSAGES': {
            'Saved': 'Saved successfully.',
        },
        'BUTTONS': {
            'Save': 'Save',
        }
    }
};