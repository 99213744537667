export const locale = {
    lang: 'ds',
    data: {
        'CUSTOMER': {
            'TITLE': 'Kunde',
            'DESC': 'Hej Projekt!',
            'TabBasicDetail': 'Oplysninger',
            'TabContactDetail': 'Kontaktoplysninger',
            'TabProjectDetail': 'Projekter',
            'TabFiles': 'Filer',
            'Log': 'Log',
            'TabColorCode': 'Farvekode',
            'TabMessageLog':'Meddelelses log',
            'TabRoom': 'Rum / Bygning',
            'TabProperties': 'Ejendomme',
            'TabMaterials': 'Lager',
            'TabProjectRecuringSetting': 'Projekt Tilbagevendende Indstilling',
        },
        'BUTTONS': {
            'NEWCUSTOMER': 'Nyt kunde',
            'BACK': 'Gå tilbage',
            'BTNSAVE': 'Gem',
            'BTNCONFIRM': 'Bekræfte',
            'CREATE_FOLDER': 'Opret mappe',
            'DOWNLOAD': 'Hent',
            'DELETE': 'Slet',
            'NEWProject': 'Nyt projekt',
            'NEWPROJECTOFFER': 'Nyt tilbud',
        },
        'FIELDS': {
            'CustomerNo': 'Kunde nr.',
            'CustomerName': 'Kundenavn',
            'Email': 'E-mail',
            'PhoneNo': 'Telefon',
            'Address-line-1': 'Adresse (Linje 1)',
            'Address-line-2': 'Adresse (Linje 2)',
            'City': 'By',
            'Zipcode': 'Postnummer',
            'Position': 'Position',
            'Comments': 'Kommentarer  [ Valgfri ]',
            'OrderName': 'Ordre navn',
            'EnterLocation': 'Indtast din placering',
            'Active': 'Indstil som inaktiv',
            'From': 'Fra',
            'To': 'til',
            'colorCode': 'Farvekode',
            'PersonnelPurchas': 'Personale køb',
            'EmailInvoice': 'Email Faktura',
            'Status': 'Status',
            'EANNumber': 'EAN Nummer',
            'SattisticQuestion2': 'Hvor kommer kunden fra',
            'Discount': 'Rabat',
            'PaymentMode1': 'Dankort',
            'PaymentMode2': 'Mastercard',
            'PaymentMode3': 'kontant',
            'PaymentMode4': 'Mobilpay',
            'PaymentMode5': 'Mobilepay KK',
            'PaymentMode6': 'Kredit',
            'PaymentMode7': 'Andet',
            'visit': 'Besøg',
            'HoursBack': 'Timer tilbage',
            'RemainingTime': 'Resterende tid',
            'HourlyWage': 'Timeløn',
            'Before60Days': '*** 60 Dag gammel.',
            'Planned': 'Planlagt',
            'DoNotSendSMSANDEmail': 'Send ikke sms vi kommer & Send ikke spørgsmål',
            'RequisitionNumber': 'Rekvisition nummer',
            'CountryCode': 'Landekode',
            'Not-Found-Country': 'Intet land fundet',
            'DoNotCalculatePriceForPriceDiscount': 'Ikke beregne pris for prisrabat',
            'DoNotCalculatePriceForPostNumber': 'Ikke beregne pris for postnummer',
            'CustomerNumberAuto': 'Debitor nr. auto'
        },
        'Headers': {
            'NewCustomer': 'Ny Kundeformular',
            'addressModalTitle': 'Tjek bekræfte din adresse detaljer',
            'NoFileSelected': 'Ingen fil valgt',
            'ChooseFile': 'Vælg fil',
            'FOLDEREMPTY': 'Denne folder er tom',
            'LogTabSRNO': 'SR NO',
            'LogTabRequisitionnumber': 'Opgavenummer',
            'LogTabChangedon': 'Dato',
            'LogTabChangedtime': 'Tid',
            'LogTabChangedfrom': 'Fra',
            'LogTabChangedto': 'Til',
            'LogTabChangedby': 'Ændret af',
            'LogTabCreatedby': 'Lavet af',
            'Desc': 'Tekst',
            'NoLogAvailable': 'Ingen log tilgængelig',
        },
        'VALIDATIONS': {
            'ContactNumber': 'Indtast kontakt nr',
            'TEXT': 'Kunde navn påkrævet',
            'ONLY40CHARACTER': 'Maksimal længde er 40 tegn.',
            'Email': 'E-mail adresse påkrævet',
            'ValidEmail':'Indtast gyldig e-mail adresse',
            'PHONENO': 'Telefon nummer påkrævet',
            'ValidPhoneRequired': 'Indtast gyldig telefon nummer',
            'ITEMNUMBEREXIST': 'Nummeret eksisterer allerede',
            'ITEMNUMBERMIN': 'Indtast gyldigt nummer',
            'ITEMNUMBERMAX': 'Indtast gyldigt nummer',
            'BEHOLDINGMIN': 'Indtast gyldigt nummer',
            'BEHOLDINGMAX': 'Indtast gyldigt nummer',
            'colorCode': 'Indtast gyldigt farvekode',
            'ComingFrom': 'Komme fra er påkrævet påkrævet',
            'CUSTOMERNUMBER': 'Indtast kundenummer',
            'EMAILEXIST': 'Email findes allerede',
            'PHONENOEXIST': 'Telefonnummeret findes allerede',
        },
        'MESSAGES': {
            'NORECORD': 'Ingen registrering fundet',
            'NoOrderAvailbale': 'Ingen projekter oprettet',
            'DropBoxApiNotResponding': 'Dropbox reagerer ikke, prøv efter lidt tid',
            'FileConflict': 'Filen eksisterer allerede',
            'FolderConflict': 'Mappenavn eksisterer allerede',
            'Created': 'Oprettet med succes',
            'Updated': 'Opdateret med succes',
            'Deleted': 'Slettet med succes',
            'Failed': 'Operation mislykket, prøv senere',
            'EmailSent': 'Email sendt med succes',
            'CustomerNumberExist': 'Nummeret eksisterer allerede',
        },
        // 'selectedValueStatus': {
        //     'value0': 'Registered',
        //     'value1': 'Process',
        //     'value2': 'Planlagt besøg',
        //     'value3': 'Klar til opstart',
        //     'value4': 'Tilbud sendt',
        //     'value5': 'Tilbud godkendt',
        //     'value6': 'Planlagt',
        //     'value7': 'Tilbud afvist',
        //     'value8': 'Startet',
        //     'value9': 'Udført',
        //     'value10': 'Faktureret',
        //     'value11': 'Afventer',
        //     'value12': 'Klar fakturering',
        // },
        'selectedValueIndoorOutdoorStatus': {
            'value1': 'Indendørs ',
            'value2': 'Udendørs',
            'value3': 'Sprøjtemaling',
            'value4': 'Reklamation',
            'value5': 'Personale køb', //drawing of painter
            'value6': 'Butiks køb'     //Nordsjø logo
        },
        'StatusLogCases': {
            'value1': 'Status opdateret',
            'value2': 'Medarbejder slettet',
            'value3': 'Statick Data Opdateret',
            'value4': 'Prisdata ændret',
            'value5': 'Medarbejder Tilføjet',
            'value6': 'Vare Tilføjet',
            'value7': 'Vare Opdateret',
            'value8': 'Vare Slettet',
            'value9': 'Email Sendt Fra Projekt',
            'value10': 'Email Sendt Fra Kunde'
        },
        'whereDoesTheCustomerComeFrom': {
            'value1': 'WWW (Internet)',
            'value2': 'Hjemmeside',
            'value3': 'Facebook',
            'value4': 'Anbefaling',
            'value5': 'Fast kunde',
            'value6': 'Manuel',
            'value7': 'Telefon',
        },
        'FILTERS': {
            'BADGE': 'Inaktive',
            'MtoM': 'M til M',
            'Note': 'Bemærk',
            'Offers': 'Tilbud',
            'Country': 'Landenavn'
        },
        'TOOTIPORTITLE':{
            'SendSMS':'Send SMS',
            'SendEmail':'Send E-mail',
            'AddProject':'Tilføj projekt',
            'DeleteCustomer':'Slet kunde',
        }
    }
};
