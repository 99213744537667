export const locale = {
  lang: 'en',
  data: {
    'FIELDS': {
      'Customer': 'Customer',
      'Not-Found-Customer': 'No customer found',
      'EnterCustomer': 'Enter customer name',
      'ScanMaterial': 'Scan material',
      'Invoice': 'Generate invoice',
    },
    'MESSAGES': {
      'InvoiceGenerated':'Invoice generated'
    }
  }
};
