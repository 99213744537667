import { Component, OnInit, Inject } from '@angular/core';
import { ProjectofferService } from 'app/main/pages/project-offer/project-offer.service';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { Router, ActivatedRoute } from '@angular/router';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { OfferCategory, OfferCategoryMaterial, OfferAddressModel } from 'app/main/model/ProjectOffer';
import { environment } from 'environments/environment';
import { OfferTypeWithRoomPriceDetail } from 'app/main/model/OfferV2';

@Component({
    selector: 'app-offer-category-move',
    templateUrl: './move-offer-category.component.html',
    styleUrls: ['./move-offer-category.component.scss']
})
export class MoveOfferCategoryComponent implements OnInit {
    offerCategoriesJson: OfferCategory[] = [];
    offerCategories: OfferCategory[] = [];
    offerCategoryId: string = "";
    offerCategory = new OfferCategory();
    showSpinner: boolean = false;
    envirnmentURL: string = "";
    selectedOfferName = "";
    previousSelectObj: OfferCategory;
    selectedCategory: OfferTypeWithRoomPriceDetail = new OfferTypeWithRoomPriceDetail();
    displayComingSoon: boolean = false;
    IsOfferCategorySelected: boolean = false;
    isInnerMultipleOptions: boolean = false;
    displayForm: string = "";

    constructor(private projectofferService: ProjectofferService,
        private _route: ActivatedRoute,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<MoveOfferCategoryComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private translationLoader: FuseTranslationLoaderService) {
        this.translationLoader.loadTranslations(english, danish);
    }
    ngOnInit() {
        this.envirnmentURL = environment.url;
        this.offerCategory = this.data.category;
        // this.offerCategoriesList = this.data.offerCategoriesList;
        this.offerCategories= this.data.offerCategoriesList;
        this.offerCategoriesJson = this.data.offerCategoriesJson;
        this.offerCategoryId = this.data.offerCategoryId;
        this.displayForm= this.offerCategory.formName;
        this.previousSelectObj = this.data.previousSelectObj;
    }

    save() {
        this.showSpinner = true;
        if (this.offerCategory.id && this.selectedCategory.offerCategoryId) {
            this.projectofferService.MoveOfferCategory(this.offerCategory.id, this.selectedCategory.offerCategoryId)
                .subscribe((response: ResponseBase) => {
                    this.showSpinner = false;
                    if (response) {
                        this.offerCategory = response.result;
                        this.dialogRef.close(this.offerCategory)
                    }
                },
                    error => (err) => {
                        this.showSpinner = false;
                    });
        }
    }

    onCloseDialog(e) {
        this.dialogRef.close(this.offerCategory)
    }

    goToSubCategory(subCat) {
        this.displayComingSoon = false;
        this.selectedOfferName = subCat.name;
        this.isInnerMultipleOptions = subCat.multicheck;
        this.previousSelectObj = subCat;
        this.offerCategories = new Array();
        this.selectedCategory.offerCategoryId = subCat.id;
        this.selectedCategory.name = subCat.name;
        this.displayForm = subCat.formName;
        this.offerCategoriesJson.filter(x => {
            if (x.parentID == subCat.id) {this.offerCategories.push(x);}
        });
    }

    goToBackCategory() {
        this.displayComingSoon = false;
        this.displayForm = "";
        var parentCategoryArray = new Array();
        if (this.previousSelectObj.parentID) {
            this.offerCategoriesJson.filter(x => {
                if (x.parentID == this.previousSelectObj.parentID) {
                    parentCategoryArray.push(x);
                }
            })
        }
        else {
            this.offerCategoriesJson.filter(x => {
                if (x.parentID == null) {
                    parentCategoryArray.push(x);
                }
            })
        }
        this.previousSelectObj = this.offerCategoriesJson.filter(x => x.id == this.previousSelectObj.id)[0];
        this.offerCategories = parentCategoryArray;
        if (this.previousSelectObj) {
            this.selectedOfferName = this.previousSelectObj.name;
            this.isInnerMultipleOptions = this.previousSelectObj.multicheck;
        }
        else
            this.selectedOfferName = "";
        this.isInnerMultipleOptions = false;
        this.IsOfferCategorySelected = this.offerCategoriesJson.some(x => x.isSelected == true);
        this.goToSubCategory(this.previousSelectObj);
    }

    goToParentCategory() {
        this.displayComingSoon = false;
        this.displayForm = "";
        this.selectedCategory.offerCategoryId = "";
        this.selectedCategory.name = "";
        var parentCategoryArray = new Array();
        if (this.previousSelectObj.parentID) {
            this.offerCategoriesJson.filter(x => {
                if (x.parentID == this.previousSelectObj.parentID) {
                    parentCategoryArray.push(x);
                }
            })
        }
        else {
            this.offerCategoriesJson.filter(x => {
                if (x.parentID == null) {
                    parentCategoryArray.push(x);
                }
            })
        }
        this.previousSelectObj = this.offerCategoriesJson.filter(x => x.id == this.previousSelectObj.parentID)[0];
        this.offerCategories = parentCategoryArray;
        if (this.previousSelectObj) {
            this.selectedOfferName = this.previousSelectObj.name;
            this.isInnerMultipleOptions = this.previousSelectObj.multicheck;
        }
        else
            this.selectedOfferName = "";
        this.isInnerMultipleOptions = false;
        this.IsOfferCategorySelected = this.offerCategoriesJson.some(x => x.isSelected == true);
    }

    _handleKeydown(event: KeyboardEvent) {
        if (event.keyCode === 13) {
            event.stopPropagation();
        }
    }
}
