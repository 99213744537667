export const locale = {
    lang: 'en',
    data: {
        'MESSAGES': {
            'Created': 'Created successfully',
            'Updated': 'Updated successfully',
            'Deleted': 'Deleted successfully',
            'Failed': 'Operation failed , try later',
            'NORECORD': 'No Record Found',
            'StatusExits': 'this status already exits'
        },
        'BUTTONS': {
            'Save': 'Save',
            'Reset': 'Reset',
            'Cancel': 'Cancel',
            'Add': "Add",
            'Back': 'Back',
            'Notification':'Notification',
            'Notes':'*** Bemærk @CoworkerName er MedarbejderNavn. @ProjectAmount er Projektbeløb. @ProjectAddress er Projektadresse. @ProjectAmountPersonale er Projektbeløb Personligt. @ProjectNumber er Projektnummer, @FromStatus er fra, @ToStatus er til'
        },
        'FIELDS': {
            'Status': 'Status',
            'Not-Found-Status': 'Status not found',
            'Message': 'Message',
            'EnterMessage': 'Enter Message',
            'AudienceType': 'Employee / Debtor',
            'IndoorOutDoor': 'Indoor,Outdoor,Spray Painting,Reclamation*',
            'ReminderMsg': 'Reminder Message',
            "Subject":"Enter Subject",
            'NotificationText': 'Notification text',
            'SMSText': 'SMS text',
            'EmailText': 'Email text',
            'DragAndDropFile':'Drag and drop file here',
            'Or':'Or',
            'BrowseForFile':'Browse for file',
        },
        'Lable': {
            'SRNO': 'Sr No',
            'Message': 'Message',
            'Status': 'Status',
            'AudienceType': 'Audience Type'
        },
        'Headers': {
            'StatusList': 'Status list',
        },
        'Validators': {
            'SelectStatus': 'Select Status',
            'SelectAudienceType': 'Select audience type',
            'SelectIndoorOrOutdoor': 'Select any one',
        },
        'ToolTip': {
            'SendReminderBySMS': 'Send reminder by Sms',
            'SendReminderByEmail': 'Send reminder by email',
            'SendReminderByNotification': 'Send reminder by notification',
        }
    }
};

