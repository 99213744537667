export const locale = {
    lang: 'en',
    data: {
        'MESSAGES': {
            //'Created': 'Created successfully',
            'ItemNumberExist': 'Number is already exist',
        },
        'FIELDS': {
            'Material': 'Material',
            'RetailPrice': 'Retail Price',
            'PersonalPrice': 'Personal Price',
            'PersonalTotalPrice': 'Personal Total',
            'Quantity': 'Quantity',
            'ProductNo': 'Product',
            'OrderQuantity': 'Order Antal',
            'MinQuantity': 'Min Quantity',
            'MaxQuantity': 'Max Quantity',
            'Unit': 'Unit',
            'PurchasePrice': 'Purchase price',
            'TotalPurchasePrice': 'Total Purchase Price',
            'Total': 'Total',
            'Inventory': 'Inventory',
            'MinInventory': 'Min Inventory',
            'MaxInventory': 'Max Inventory',
            'PurchaseQuantity': 'Purchase Quantity',
            'ShowAll': 'Show All',
            'ColorCode': 'Color code',
            'ProjectNumber': 'Project Number',
            'RequisitionNo': 'Requisition No',
        },
        'BUTTONS': {
            'IncludeInactive': 'Include Inactive',
            'ShowActive': 'Show Active',
        },
        'unitList': {
            'value1': 'PCS',
            'value2': 'Liter',
            'value3': 'Meter',
            'value4': 'Roll',
            'value5': 'M2',
            'value6': 'KG'
        },
        'TOOTIPORTITLE':{
            'ActiveAll':'Active All',
            'IsActive':'Is Active',
            'WeatherType':'Weather Type',
            'GoToMaterial':'Go To Material',
        }
    }
};

