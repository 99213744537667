// angular 
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

// third party tool
import { TranslateService } from '@ngx-translate/core';

// app-core
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';

// interfaces
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { NotificationsService } from 'angular2-notifications';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import * as XLSX from 'xlsx';
import { CustomersService } from '../../customer.service';
type AOA = any[][];
@Component({
  selector: 'app-customer-upload',
  templateUrl: './customer-upload.component.html',
  styleUrls: ['./customer-upload.component.scss']
})
export class CustomerUploadComponent implements OnInit {
  selectedFiles: any[] = [];
  showSpinner: boolean = false;
  sampleFileUrl: any;
  data: any = [];
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  fileName: string = 'SheetJS.xlsx';
  customerUploadList: any = [];
  //#endregion Variables

  constructor(private _route: ActivatedRoute,
    private _router: Router,
    private translationLoader: FuseTranslationLoaderService,
    private translate: TranslateService,
    private notificationsService: NotificationsService,
    private http: HttpClient,
    private customersService: CustomersService,
  ) { this.translationLoader.loadTranslations(english, danish); }

  ngOnInit() {
    this.sampleFileUrlInit();
  }

  Upload() {
    this.showSpinner = true;
    this.customersService.CreateCustomersFromExcelRead<ResponseBase>(this.customerUploadList)
    .subscribe({
      next: (response: ResponseBase) => {
        this.showSpinner = false;
        this.translate.get("MESSAGES.Updated").subscribe({
          next: (res) => {
            this.notificationsService.success(res);
          },
          error: err => {
          }
        });
        this.customerUploadList= response.result;
      },
      error: err => {
        this.showSpinner = false;
        this.notificationsService.error(err.error.message);
      },
      complete: () => {
        this.showSpinner = false;
      }
    });
  }

  OnuploadFile(event) {
    if (event.target.files.length > 0)
      this.selectedFiles = event.target.files;
  }

  sampleFileUrlInit() {
    this.sampleFileUrl = environment.url + "Upload/CustomerUpload/Sample-CustomerUpload.xlsx";
  }

  downloadSampleFile() {
    this.http.get(this.sampleFileUrl, { responseType: "blob" }).subscribe((res: any) => {
      const url = window.URL.createObjectURL(res);
      //window.open(url);
      var download = document.createElement("a");
      download.href = url;
      download.download = 'Sample-CustomerUpload' + '.xlsx';
      var event = document.createEvent("MouseEvents");
      event.initMouseEvent(
        "click", true, false, window, 0, 0, 0, 0, 0
        , false, false, false, false, 0, null
      );
      download.dispatchEvent(event);
    });
  }
  onFileChange(evt: any) {
    const customerList: any[] = [];
    const target: DataTransfer = <DataTransfer>(evt.target);

    if (target.files.length !== 1) throw new Error('Cannot use multiple files');

    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];


      this.data = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
      const headerRow = this.data[0];
      this.data = this.data.slice(1);
      const columnMapping: { [key: string]: number } = {
        "Kundenr": headerRow.indexOf("Kundenr"),
        "FirmaNavn": headerRow.indexOf("FirmaNavn"),
        "KontaktPerson": headerRow.indexOf("KontaktPerson"),
        "Adresse": headerRow.indexOf("Adresse"),
        "Postnr": headerRow.indexOf("Postnr"),
        "By": headerRow.indexOf("By"),
        "Telefon": headerRow.indexOf("Telefon"),
        "EmailFaktura": headerRow.indexOf("EmailFaktura"),
        "Email": headerRow.indexOf("Email")
      };

           
      this.data.forEach((res) => {
        if (res[0] !== "no" && res.some((element) => element !== "")) {
          const customer = {
            "kundenr": res[columnMapping["Kundenr"]],
            "firmaNavn": res[columnMapping["FirmaNavn"]],
            "kontaktPerson": res[columnMapping["KontaktPerson"]],
            "adresse": res[columnMapping["Adresse"]],
            "postnr": res[columnMapping["Postnr"]],
            "by": res[columnMapping["By"]],
            "telefon": res[columnMapping["Telefon"]],
            "emailFaktura": res[columnMapping["EmailFaktura"]],
            "email": res[columnMapping["Email"]],
            "isInsertedStatus": null
          };

          // Check if any property has a value before pushing into customerList
          if (Object.values(customer).some((value) => value !== "")) {
            customerList.push(customer);
          }
        }
      });
      this.customerUploadList = customerList;
    };

    reader.readAsBinaryString(target.files[0]);

  }

}
