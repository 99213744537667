export const locale = {
    lang: 'ds',
    data: {
        'MESSAGES': {
            'Add': 'Oprettet',
        },
        'BUTTONS': {
            'Back': 'Gå tilbage',
            'Ok': 'Gem',
            'ClearAll': 'Slet alt',
            'Value': 'Værdi',
        }
    }
};