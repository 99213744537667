export const locale = {
    lang: 'en',
    data: {
        'FIELDS': {
            'CountryCode':'Country Code',
            'Not-Found-Country':'No Country found'
        },
        'FILTERS': {
            'Country': 'Country Name'
        },
    }
};


