export class CarInformation {
    public id: string = '';
    // public iMEINo: string = '';
    public vehicleNo: string = '';
    public carBrand: string = '';
    public model: string = '';
    public regNo: string = '';
    public color: string = '';
    public regDate: Date;
    public lastSightDate: Date;
    public lastSightCoworkerID: Date;
    public nextSightDate: Date;
    public nextSightCoworkerID: Date;
    public lastServiceDate: Date;
    public lastServiceCoworkerID: Date;
    public nextServiceDate: Date;
    public nextServiceCoworkerID: Date;
    public leasing: boolean;
    public serviceAgreement: boolean;
    public roadsideAssistance: boolean;
    public leasingDetails: string;
    public serviceAgreementDetails: string;
    public roadsideAssistanceDetails: string;
    public imageUrl: string;
    public imageBase64: string;
    public runningKM: number;
    public nextServiceAfterKM: number;
    public nextServiceKM: number;
    public lastServiceKM: number;
    public coworkerId: string;
    public coworkerName: string;
    public coworkerNumber: string;
    public isActive: boolean;
    public isDeleted: boolean;
    public isDiesel: boolean;
    public isAddFilter: boolean;
    public summerTires: boolean;
    public winterTires: boolean;
    public allSeasonTires: boolean;
    public nanolinkDeviceID:string;
    
    constructor() { }
}
export class ContactInformation {
    public companyName: string = '';
    public contactPerson: string = '';
    public phoneno: string = '';

    constructor() { }
}