export const locale = {
    lang: 'en',
    data: {
        'Headers': {
            'MaterialPrice': 'Material Price',
            'OfferPrice': 'Offer Price',
            'SuppliersItemPriceChangeList': 'Suppliers material price change list'
        },
        "FIELDS": {
            'All': 'All',
            'Supplier': 'Supplier',
            'Percentage': 'Percentage',
            'RetailPrice': 'Retail Price',
            'SalesPrice': 'Sales Price',
            'PurchasePrice': 'Purchase price',
            'PersonalPrice': 'Personal Price',
            'OfferPrice': 'Offer Price',

            'SRNO': 'Sr nr.',
            "Edit": 'Edit',
            "Delete": 'Delete',
            "ChangedBy": 'Changed By'
        },
        "BUTTONS": {
            'CHANGEPRICE': 'Adjust prices',
            'BTNSAVE': 'Save',
            'BACK': 'Back',
        },
        "MESSAGES": {
            'Added': 'Added Successfully',
            'Updated': 'Updated Successfully.',
            'Failed': 'Failed',
            'Deleted': 'Deleted successfully',
            "NORECORD": "No Record Found",
            "UndoChangesSuccess":"Undo Changes Successful",
            "DeletePriceAdjustment": "Delete price adjustment",
        },
        "VALIDATIONS": {
            'SupplierRequired': 'Select Supplier',
            'PercentageRequired': 'Enter Percentage'
        }
    }
}