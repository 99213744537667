export const locale = {
    lang: 'ds',
    data: {
        'TITLE': {
            'HeaderForCWAnniversary': 'Medarbejder Arbejde Jubilæum Påmindelse',
            'AnniversarySMSTextCoworker':'Fjern venligst ikke @CoworkerName, @Date, @AnniversaryYear og @AnniversaryCoworkerNames fra SMS tekst.',
            'AnniversaryNotificationTextCoworker':'Fjern venligst ikke @CoworkerName, @Date, @AnniversaryYear og @AnniversaryCoworkerNames fra Notifikation tekst.',

            'AnniversarySMSTextOtherCoworker':'Fjern venligst ikke @CoworkerNames, @Date, @AnniversaryYear og @AnniversaryCoworkerNames fra SMS tekst.',
            'AnniversaryNotificationTextOtherCoworker':'Fjern venligst ikke @CoworkerNames, @Date, @AnniversaryYear og @AnniversaryCoworkerNames fra Notifikation tekst.',

            'AnniversarySMSTextOthersBeforeXDays':'Fjern venligst ikke @CoworkerNames,@Date, @AnniversaryYear og @AnniversaryCoworkerNames fra SMS tekst.',
            'AnniversaryNotificationTextOthersBeforeXDays':'Fjern venligst ikke @CoworkerNames,@Date, @AnniversaryYear og @AnniversaryCoworkerNames fra Notifikation tekst.',


        },
        'FIELDS': {
            'HoursToSend': 'Send kl.',
            'DaysToSend': 'Send x dage før',
            'SendOnYear':'send efter x år',
            'SMSText': 'SMS tekst',
            'NotificationText': 'Notifikations tekst',
            'EmailText': 'E-mail tekst',
            'ExecuteTime':'Udfør tid',
            'IsCreateReminder':'Opret påmindelse?',
            'EmailTemplate':'E-mail Skabelon',
            // 'EmailTemplateForCoworker':'E-mail Skabelon For Medarbejder',
            // 'EmailTemplateForOthers':'E-mail skabelon til andre på samme dag',
            // 'EmailTemplateForOthersBeforeXDays':'E-mail-skabelon til andre før X dage',
        
            'TemplatesForCoworker':'Besked For Medarbejder',
            'TemplatesForOthers':'Besked til andre på samme dag',
            'TemplatesForOthersBeforeXDays':'Besked til andre før X dage',

            'EmailTemplateForCoworker':'E-mail Skabelon For Medarbejder',
            'EmailTemplateForOthers':'E-mail skabelon til andre på samme dag',
            'EmailTemplateForOthersBeforeXDays':'E-mail-skabelon til andre før X dage',
            
            // 'NoteLabelForCoworker1':'Vær venlig gør ikke fjerne @CoworkerName og @AnniersaryYear fra SMS,Notifikation og Email skabelon tekst.',
            // 'NoteLabelForOthers1':'Vær venlig gør ikke fjerne @CoworkerNames og @AnniversaryCoworkerNames fra SMS,Notifikation og Email skabelon tekst.',
            // 'NoteLabelForOthersBeforeXDays1':'Vær venlig gør ikke fjerne @CoworkerNames,@Date og @AnniversaryCoworkerNames fra SMS,Notifikation og Email skabelon tekst.'
        },
        'VALIDATIONS': {
            'HoursToSend': 'Timer at sende er påkrævet',
            'ExecuteTime':'Udførelse tid er påkrævet'
        },
        'MESSAGES': {
            'Saved': 'Gemt succesfuldt.',
        },
        'BUTTONS': {
            'Save': 'Gem',
        }
    }
};
