import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import { Router } from '@angular/router';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'vibe-coWorkerList',
    templateUrl: './coWorkerList.component.html',
    styleUrls: ['./coWorkerList.component.scss']
})
export class CoWorkerListComponent implements OnInit {
    coWokers: any = [];
    constructor(private translationLoader: FuseTranslationLoaderService,
        private _router: Router,
        private translate: TranslateService,
        public dialogRef: MatDialogRef<CoWorkerListComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
        this.coWokers = this.data;
        this.coWokers.filter(x=>{x.isChecked=true;});
    }

    onCheckCoworker(value, item) {
        item.isChecked = !value;
    }

    AddCoworker(){
       var selectedCoworker = this.coWokers.filter(x=>x.isChecked==true);
       this.dialogRef.close(selectedCoworker);
    }

    ngOnBackClick(data) {
        this.dialogRef.close(data);
    }

}
