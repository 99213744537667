export const locale = {
    lang: 'ds',
    data: {
        'MESSAGES': {
            'Created': 'Oprettet med succes',
            'Updated': 'Opdateret med succes',
            'Deleted': 'Slettet med succes',
            'Failed': 'Operation mislykket, prøv senere',
            'FeildUnderFinishFolder': '*** Denne projektmappe er under færdigmappe.',
        },
        'BUTTONS': {
            'CREATE_FOLDER': 'Opret mappe',
            'BACK': 'Tilbage',
            'AddScanMaterial': 'Tilføj scanne materiale',
            'SAVE': 'Gem',
            'DELETE': 'Slet',
        },
        'Headers': {
            'NoFileSelected': 'Ingen fil valgt',
            'FOLDEREMPTY': 'Denne folder er tom',
        },
        'VALIDATIONS': {
            'TextRequired': 'Tekst krævet',
            'ColorCodeRequired': 'Farve kode krævet',
            'PersonalPriceRequired': 'Personlig pris påkrævet',
            'RetailPriceRequired': 'Detailpris påkrævet',
            'PurchaseQuantityRequired': 'Indkøbsmængde krævet',
            'QuantityRequired': 'Mængde krævet',
        },
    }
};

