import { Component, OnInit, ElementRef, NgZone, ViewChild, Input, Inject, Optional,forwardRef, ChangeDetectorRef } from '@angular/core';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl, ValidatorFn,NG_VALUE_ACCESSOR, ControlValueAccessor, NgControl  } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CountryJson } from 'app/constant/flag.constant';
import { noop } from 'rxjs';

@Component({
  selector: 'app-country-dropdown',
  templateUrl: './country-dropdown.component.html',
  styleUrls: ['./country-dropdown.component.scss'],
  providers: [
    {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => CountryDropdownComponent),
        multi: true
    }
  ]
})

export class CountryDropdownComponent implements ControlValueAccessor,OnInit {
  @Input() ctrl: FormControl;
  @Input() formGroup: FormGroup;
  countryList = CountryJson;
  tempCountryList = CountryJson;
  countryName: string = "";
  countryCode: string = ""

  constructor(private translationLoader: FuseTranslationLoaderService,
    private _router: Router,
    private cdr: ChangeDetectorRef) {
      this.translationLoader.loadTranslations(english, danish);
  }
  //#region Private/Helper Methods
  private onTouched: () => void = noop;
  private onChange: (_: any) => void = noop;
  //#endregion Private/Helper Methods

  ngOnInit() {
  }
  
  getFlagClass(value){
    var country = value;
    return country.toLowerCase()+' flag';
  }

  DropDownSearchCountry(name) {
    if (name == "" || name == undefined) 
       this.countryList = this.tempCountryList; 
    else
      this.countryList = this.tempCountryList.filter(it => {return it.name.toLowerCase().includes(name.toLowerCase());}); 
  }

  ngAfterContentChecked() {
      this.cdr.detectChanges();
  }

  registerOnChange(fn: any): void {
      this.onChange = fn;
  }   //for ControlValueAccessor

  registerOnTouched(fn: any): void {
      this.onTouched = fn;
  }   //for ControlValueAccessor

  writeValue(newModel: any) {
      this.countryCode = newModel ? newModel : '+45';
  }   //for ControlValueAccessor

  changeCountryCode(data){
    if(data)
      this.onChange(data);
  }
}





