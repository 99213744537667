import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'denmarkDigitFormate' })
export class DenmarkDigitFormate implements PipeTransform {
  transform(value: string): string {
    if (value) {
      return Number(value).toLocaleString('en-DA', { style: 'currency', currency: 'DKK' }).replace('DKK ', '').replace(/,/g, '.').replace(/.([^.]*)$/, ',$1');
    }
    else if(value=="0"){
        return Number(value).toLocaleString('en-DA', { style: 'currency', currency: 'DKK' }).replace('DKK ', '').replace(/,/g, '.').replace(/.([^.]*)$/, ',$1');
    }
    else
      return value;
  }
}
