import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { OfferCategory, OfferDetail, OfferItem, OfferItemGroup } from '../../../../../model/ProjectOffer';
import { EditProjectOfferFeatureComponent } from '../../edit-project-offer-feature/edit-project-offer-feature.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ProjectofferService } from '../../../project-offer.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { FuseTranslationLoaderService } from '../../../../../../core/services/translation-loader.service';
import { OfferFormName } from '../../../../../../core/constant/SettingConstant';
import { ResponseBase } from '../../../../../model/ResponseBase';
import { OfferTypeDTO } from '../../../../../model/OfferV2';

@Component({
    selector: 'offerV2-form-c',
    templateUrl: './offerV2-form-c.component.html',
    styleUrls: ['./offerV2-form-c.component.scss']
})
export class OfferV2FormCComponent implements OnInit {
    displayEditOfferCategory: boolean = false;
    @Input() selectedCategoryInput: OfferTypeDTO;
    @Output() DisplayList = new EventEmitter<any>();
    priceTotal: number = 0;
    offerDetail: OfferDetail;
    offerId: string = "";
    constructor(private projectofferService: ProjectofferService,
        private translationLoader: FuseTranslationLoaderService,
        private _route: ActivatedRoute,
        private notificationsService: NotificationsService,
        private translate: TranslateService,
        public dialog: MatDialog) {
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
        this._route.params.subscribe(params => {
            var id = params['id'];
            if (id != undefined) {
                this.offerId = id;
            }
        });
        if (this.selectedCategoryInput.id) {
            this.GetOfferItems();
        }
        else {
            this.displayEditOfferCategory = true;
            this.offerDetail = new OfferDetail();
            this.offerDetail.offerItem = Array<OfferItem>();
            this.GetOfferCategoryWorkItem(this.selectedCategoryInput.offerCategoryId);
        }
    }

    GetOfferCategoryWorkItem(id) {
        this.projectofferService.GetOfferCategoryWorkItem<ResponseBase>(id)
            .subscribe((response: ResponseBase) => {
                if (response) {
                    this.offerDetail.offerItem = response.result.myResponse;
                    this.calculatPrise();
                }
            },
                error => (err) => {
                });
    }

    GetOfferItems() {
        this.projectofferService.GetOfferItemV2(this.selectedCategoryInput.id).subscribe({
            next: (response) => {
                if (response) {
                    this.offerDetail = response.result;
                    this.calculatPrise();
                }
            },
            error: err => {
            },
            complete: () => {
            }
        });
    }

    save() {
        this.offerDetail.offerItemGroup = new OfferItemGroup();
        this.offerDetail.offerItemGroup.formName = OfferFormName.FormC;
        this.offerDetail.offerItemGroup.name = this.selectedCategoryInput.name;
        this.offerDetail.offerItemGroup.offerCategoryID = this.selectedCategoryInput.offerCategoryId;
        this.offerDetail.offerItemGroup.price = this.priceTotal;
        this.offerDetail.offerItemGroup.projectOfferID = this.offerId;
        this.offerDetail.offerItemGroup.id = this.selectedCategoryInput.id;
        if (this.selectedCategoryInput.id) {
            this.projectofferService.updateOfferItemV2(this.offerDetail).subscribe({
                next: (response) => {
                    if (response) {
                        this.translate.get("MESSAGES.Updated").subscribe({
                            next: (res) => {
                                this.notificationsService.success(res);
                            },
                            error: err => {
                            }
                        });
                    }
                    this.DisplayList.emit(this.selectedCategoryInput.offerId);
                },
                error: err => {
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });
                },
                complete: () => {
                }
            });
        }
        else {
            this.projectofferService.SaveOfferItemV2(this.offerDetail).subscribe({
                next: (response) => {
                    if (response) {

                    }
                    this.translate.get("MESSAGES.Added").subscribe({
                        next: (res) => {
                            this.notificationsService.success(res);
                        },
                        error: err => {
                        }
                    });
                    this.DisplayList.emit(this.selectedCategoryInput.id);
                },
                error: err => {
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });
                },
                complete: () => {
                }
            });
        }
    }

    cancel() {
        this.DisplayList.emit(this.selectedCategoryInput.id);
    }
    editOfferFeature() {
        const dialogRef = this.dialog.open(EditProjectOfferFeatureComponent, {
            height: '70%',
            width: '90%',
            data: {
                slectedOffer: this.selectedCategoryInput
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result && result.isUpdate && result.response.result) {
                this.selectedCategoryInput.name = result.response.result.name;
            }
        });
    }
    calculatPrise() {
        this.priceTotal = 0;
        for (var i = 0; i < this.offerDetail.offerItem.length; i++) {
            this.offerDetail.offerItem[i].total = this.offerDetail.offerItem[i].qty * this.offerDetail.offerItem[i].price;
            if (this.offerDetail.offerItem[i].total > 0) {
                this.priceTotal = this.priceTotal + this.offerDetail.offerItem[i].total;
            }
            else {
                this.offerDetail.offerItem[i].total = 0;
            }
        }
    }

}
