export class OfferQualityTestQuestionAnswer
{
    public id?: string;
    public questionType: string ;
    public question: string;
    public questionImage: string;
    public answer?: string ;
    public rating?: number;
    public choiceOptions?: OfferQualityTestQuestionAnswerChoiceOptions[];
    public createdBy?: string;
    public createdDate?: Date = new Date();
    public modifyBy?: string;
    public modifyDate?: Date = new Date();
    public isDeleted?: boolean = false;
    public isActive?: boolean = true;
    public files: any[] = [];
    public filesPath: any[] = []; 

}

export class OfferQualityTestQuestionAnswerChoiceOptions
{
    public id?: string;
    public name?: string;
    public question?: string;
    public answerType?: string;
    public answer?: string;
    public checked?: boolean;
    public reminderMsg?: string;
    public remindCoWorkerIDs?: string;
    public isSendReminder?: boolean;
    public isSendRemindBySMS?: boolean;
    public isSendRemindByEmail?: boolean;
    public isSendRemindByNotification?: boolean;
    public isWithQueText?: boolean;
  }