import { Component, OnInit, Inject } from '@angular/core';
import { FuseTranslationLoaderService } from './../../../../core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ShortcutInput, ShortcutEventOutput } from "ng-keyboard-shortcuts";
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';

@Component({
    selector: 'app-set-reminder-model',
    templateUrl: './set-reminder-model.component.html',
    styles: []
}) 
export class SetReminderModelComponent implements OnInit {
    data_obj: any;
    Header: string = "";
    showOKButton: Boolean = false;
    shortcuts: ShortcutInput[] = [];
    showManualButton: boolean = false;
    constructor(private translationLoader: FuseTranslationLoaderService,
        public dialogRef: MatDialogRef<SetReminderModelComponent>,
        private translate: TranslateService,
        private notificationsService: NotificationsService,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.translationLoader.loadTranslations(english, danish);

    }

    ngOnInit() {
        this.data_obj = this.data;
        this.Header = this.data.Header;
        this.showOKButton = this.data.ShowOKButton;
        this.showManualButton = this.data.ShowManualButton;
    }

    onCloseDialog(e) {
        if(e){
            this.addManually();
        } else {
            this.dialogRef.close(false)
        }
    }

    addManually() {
        if(this.data_obj['ReminderText'].trim()==''){
            this.translate.get("Headers.CommentReq").subscribe({
                next: (res) => {
                    this.notificationsService.alert(res);
                },
                error: err => {
                }
            });
        } else {
            this.dialogRef.close(this.data_obj)
        }
    }

    ngAfterViewInit() {
        this.shortcuts.push(
            {
                key: ["j"],
                label: "Ja",
                description: "j",
                command: (output: ShortcutEventOutput) => {
                    this.addManually();
                },
                preventDefault: true
            },
            {
                key: ["n"],
                label: "Nej",
                description: "n",
                command: (output: ShortcutEventOutput) => {
                    this.dialogRef.close(false)
                },
                preventDefault: true
            }
        );
    }

}
