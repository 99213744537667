import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ProjectofferService } from 'app/main/pages/project-offer/project-offer.service';
import { OfferCategory, OfferCategoryMaterial, OfferCategoryWithOfferDetail, OutdoorFileList, OfferAddressModel } from 'app/main/model/ProjectOffer';
import { RequestOfferCreateLater } from 'app/main/model/RequestBase';
import { Router, ActivatedRoute } from '@angular/router';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { OfferFormName } from 'app/core/constant/SettingConstant';
import { EditOfferCategoryComponent } from 'app/main/shared-component/project-offer-category/component/edit-offer-category/edit-offer-category.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { environment } from 'environments/environment';
import { OfferCategoryLaterDTO, OfferCreateLaterCategoryMaterialDTO, OfferTypeCreateLater, OfferTypeWithRoomPriceDetail } from 'app/main/model/OfferV2';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { CustomersService } from 'app/main/pages/customer/customer.service';
import { OfferCategoryQtySelectComponent } from 'app/main/shared-component/project-offer-category/component/offer-category-qty-select/offer-category-qty-select.component';
import { MoveOfferCategoryComponent } from 'app/main/shared-component/project-offer-category/component/move-offer-category/move-offer-category.component';
import { PaintSelectTime } from 'app/constant/common.constant';
import { SharedTextBoxPopup } from 'app/main/shared-component/text-box-popup/text-box-popup.component';
import { SelectSavedOfferComponent } from 'app/main/shared-component/project-offer-category/component/select-saved-offer/select-saved-offer.component';
import { OfferSelectionComponent } from 'app/main/shared-component/project-offer-category/component/offer-selection/offer-selection.component';
import { GoogleMapDirectionComponent } from 'app/main/pages/google-map-direction/google-map-direction.component';
import { CompanyInfo } from 'app/main/model/CompanyInfo';
import { CompanyInfoService } from 'app/main/pages/companyInfo/companyInfo.service';

@Component({
    selector: 'app-project-offer-category',
    templateUrl: './project-offer-category.component.html',
    styleUrls: ['./project-offer-category.component.scss']
})
export class ProjectOfferCategoryComponent implements OnInit {
    @Input() moduleName: any;
    @Input() moduleId: any;
    @Input() roomList: any = [];
    @Output() eventDisableTabs = new EventEmitter<boolean>();
    @Output() eventGotoPDFEmail = new EventEmitter<any>();
    @Output() eventGotoDetailPage = new EventEmitter<any>();
    @Output() eventGoToBackCategory = new EventEmitter<any>();
    @Output() eventDisabledTabsFromOffer = new EventEmitter<any>();
    formName: string = "";
    selectedOfferName = "";
    previousSelectObj: OfferCategory;
    selectedCategory: OfferTypeWithRoomPriceDetail = new OfferTypeWithRoomPriceDetail();
    displayForm: string = "";
    offerId: string = "";
    displayComingSoon: boolean = false;
    offerFormName = OfferFormName;
    envirnmentURL: string = "";
    IsOfferCategorySelected: boolean = false;
    categoryIdList = [];
    isInnerMultipleOptions: boolean = false;
    customerDetailObj: any;
    offerRoomList: [];
    projectOffer: OfferAddressModel = new OfferAddressModel();
    totalInclVAT: number = 0;
    totalExclVAT: number = 0;
    vat: number = 0;
    isDisplayPDFEmailbtn: boolean = false;
    showSpinner: boolean = false;
    AddedCartCount: number = 0;
    SelectedCart: any = [];
    SelectCategoryTime = PaintSelectTime;
    MaterialObject: any = [];
    OfferTypeCreateLater: OfferTypeCreateLater = new OfferTypeCreateLater();
    OfferTypeCreateLaterList: Array<OfferTypeCreateLater> = [];
    SelectedOfferOnCart: OfferTypeCreateLater = new OfferTypeCreateLater();
    customerId: string = "";
    requestOfferCreateLater = new RequestOfferCreateLater();
    offerCategories: OfferCategory[] = [];
    offerCategoriesMasterList: OfferCategory[] = [];
    offerCategoriesJson: OfferCategory[] = [];
    outdoorFileList: OutdoorFileList[] = [];
    selectedCategoryNameForImg: string = "";
    selectedCategoryIdForImg: string = "";
    categoryIndex: number;
    companyInfoData: CompanyInfo;
    OfferTypeDetail: OfferTypeWithRoomPriceDetail = new OfferTypeWithRoomPriceDetail();
    meterAndKmText: string = "km";
    DistancePerDayValue: number;
    settingMasterValues: any[] = [];
    projectsCoworkerPrice: number = 0;
    paintTimer: number = 0;

    constructor(private projectofferService: ProjectofferService,
        private _route: ActivatedRoute,
        private translationLoader: FuseTranslationLoaderService,
        public dialog: MatDialog,
        private translate: TranslateService,
        private notificationsService: NotificationsService,
        private customersService: CustomersService,
        private companyInfoService: CompanyInfoService,) {
        this.translationLoader.loadTranslations(english, danish);
    }
    ngOnInit() {
        this.envirnmentURL = environment.url;
        this.settingMasterValues = Object.assign([], JSON.parse(localStorage.getItem("settingMasterValues")));
        var tempSettingObject = this.settingMasterValues.find(s => s.key1 == "Project" && s.key2 == "ProjectsCoworkerPrice").value;
        if (tempSettingObject)
            this.projectsCoworkerPrice = parseFloat(tempSettingObject);
        else
            this.projectsCoworkerPrice = 580;
        // this._route.params.subscribe(params => {
        //     var id = params['id'];
        //     if (id != undefined) {
        //         this.offerId = id;
        //         this.GetOffer();
        //         this.getCartOfferList(true);
        //     }
        // });
        if (this.moduleId && this.moduleName == "offer") {
            this.offerId = this.moduleId;
            this.GetOffer();
            //this.requestOfferCreateLater.OfferID = this.moduleId;
        }
        else {
            this._route.params.subscribe(params => {
                this.customerId = params['id'];
                this.requestOfferCreateLater.PropertyID = this.moduleId;
                this.requestOfferCreateLater.CustomerID = this.customerId;
                if (this.customerId)
                    this.getCustomerById(this.customerId);
            });
            this.getCartOfferList(true);
        }
        this.GetOfferCategories(false);
        this.GetCompanyInformation();

        var tempSettingObject = this.settingMasterValues.find(s => s.key1 == "OfferTravelingDistancePerMinute").value;
        if (tempSettingObject)
            this.DistancePerDayValue = parseFloat(tempSettingObject);
        else
            this.getTravelingDistancePerDayValue();
    }

    GetOfferCategories(isCallAfterSave) {
        this.showSpinner = true;
        this.projectofferService.GetOfferCategoryWithMaterialAndRoomList(this.offerId).subscribe({
            next: (response) => {
                if (response) {
                    this.offerCategoriesJson = response.result.categoryMaterialList;
                    this.offerCategoriesMasterList = this.offerCategoriesJson.map(x => Object.assign({}, x));
                    this.offerRoomList = this.roomList.length > 0 ? this.roomList : response.result.offerRoomList;
                    this.offerCategoriesJson.filter(x => {
                        x.isSelected = false;
                    });
                    if (isCallAfterSave && this.previousSelectObj)
                        this.goToSubCategory(this.previousSelectObj);
                    else
                        this.offerCategories = this.offerCategoriesJson.filter(x => x.level == 1);

                }
            },
            error: err => {

            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    //====================================== New Code Start =============

    goToSubCategory(subCat) {
        this.displayComingSoon = false;
        this.selectedOfferName = subCat.name;
        this.isInnerMultipleOptions = subCat.multicheck;
        this.previousSelectObj = subCat;
        this.offerCategories = new Array();
        if (subCat.isLeafNode) {
            this.selectedOfferName = "";
            this.isInnerMultipleOptions = false;
            this.displayForm = subCat.formName;
            this.selectedCategory.offerCategoryId = subCat.id;
            this.selectedCategory.name = subCat.name;
            this.selectedCategory.offerId = this.offerId;
            this.selectedCategory.formName = subCat.formName;
            this.selectedCategory.isShowRoomDetail = subCat.showRoomDetails;
            this.formName = subCat.formName;
            if (this.formName != this.offerFormName.FormB
                && this.formName != this.offerFormName.FormA
                && this.formName != this.offerFormName.FormC
                && this.formName != this.offerFormName.FormD
                && this.formName != this.offerFormName.FormE) {
                this.displayComingSoon = true;
            }
        }
        else {
            this.offerCategoriesJson.filter(x => {
                if (x.parentID == subCat.id) {
                    //x.isSelected = false;
                    this.offerCategories.push(x);
                }
            });
            this.formName = "";
        }
        setTimeout(() => {
            this.IsOfferCategorySelected = this.offerCategoriesJson.some(x => x.isSelected == true);
            this.disableAllOfferTabs();
        }, 100);
    }

    //====================================== End =============
    editOfferFeature(id) {
        const dialogRef = this.dialog.open(EditOfferCategoryComponent, {
            height: '70%',
            width: '80%',
            data: {
                offerCategoryId: id
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                var selectedoffer = this.offerCategories.find(o => o.id == result.id);
                var offer = this.offerCategories.filter(o => o.id != result.id);
                var offerjson = this.offerCategoriesJson.filter(o => o.id != result.id);
                if (offer) {
                    result.isSelected = selectedoffer ? selectedoffer.isSelected : false;
                    offer.push(result);
                    offerjson.push(result);
                    //this.offerCategories = [];
                    this.offerCategories = offer;
                    this.offerCategoriesJson = offerjson;
                }
            }
        });
    }

    goToParentCategory() {
        this.displayComingSoon = false;
        this.displayForm = "";
        var parentCategoryArray = new Array();
        if (this.previousSelectObj.parentID) {
            this.offerCategoriesJson.filter(x => {
                if (x.parentID == this.previousSelectObj.parentID) {
                    parentCategoryArray.push(x);
                }
            })
        }
        else {
            this.offerCategoriesJson.filter(x => {
                if (x.parentID == null) {
                    parentCategoryArray.push(x);
                }
            })
        }
        this.previousSelectObj = this.offerCategoriesJson.filter(x => x.id == this.previousSelectObj.parentID)[0];
        this.offerCategories = parentCategoryArray;
        if (this.previousSelectObj) {
            this.selectedOfferName = this.previousSelectObj.name;
            this.isInnerMultipleOptions = this.previousSelectObj.multicheck;
        }
        else
            this.selectedOfferName = "";
        this.isInnerMultipleOptions = false;
        this.IsOfferCategorySelected = this.offerCategoriesJson.some(x => x.isSelected == true);
    }

    // getOfferItemGroup(event) {
    //     this.eventDisplayOfferGroup.emit(event);
    // }

    onCheckboxCheck(id, value, Index) {
        var cat = this.offerCategoriesJson.find(o => o.id == id);
        if (cat.categoryMaterialList && cat.categoryMaterialList.length > 1 && value == true) {
            //open popup
            const dialogRef = this.dialog.open(OfferCategoryQtySelectComponent, {
                height: '85%',
                width: '90%',
                data: {
                    category: cat,
                    projectOffer: this.projectOffer,
                    customerDetailObj: this.customerDetailObj,
                    roomList: this.offerRoomList,
                    offerId: this.offerId
                },
                disableClose: true
            });
            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    cat = result.category;
                    var catQtyCount = 0;
                    result.category.categoryMaterialList.forEach(flipButton => {
                        catQtyCount = catQtyCount + flipButton.materialQty;
                    });
                    cat.qty = catQtyCount;
                    this.offerRoomList = result.roomList;
                }
                this.calculateTotal();
                this.disableAllOfferTabs();
            });
        }
        else if (cat) {
            cat.isSelected = value;
            if (value) {
                cat.qty = cat.qty ? cat.qty : 0 + 1;
            }
            else {
                cat.qty = 0;
                var index = this.outdoorFileList.findIndex(x => x.categoryId == cat.id);
                this.outdoorFileList.splice(index, 1);
                this.offerCategories[Index].imageCount = 0;
                cat.categoryMaterialList.filter(o => {
                    o.isChecked = false;
                    o.materialQty = 0;
                    o.length = 0;
                    o.width = 0;
                    o.height = 0;
                    o.total = 0;
                    o.roomList.filter(p => {
                        p.isChecked = false;
                    })
                })
            }
        }

        setTimeout(() => {
            this.IsOfferCategorySelected = this.offerCategoriesJson.some(x => x.isSelected == true);
            this.disableAllOfferTabs();
        }, 100);
    }

    onAddButtonClick(cat) {
        let temp = this.offerCategoriesJson.filter(o => o.isSelected == true).filter(p => p.categoryMaterialList.filter(j => j.isChecked == true));
        if (cat) {
            var matCount = this.offerCategoriesJson.find(o => o.id == cat.id);
            if (matCount.categoryMaterialList && matCount.categoryMaterialList.length > 0) {
                //open popup
                const dialogRef = this.dialog.open(OfferCategoryQtySelectComponent, {
                    height: '85%',
                    width: '90%',
                    data: {
                        category: matCount,
                        roomList: this.offerRoomList,
                        projectOffer: this.projectOffer,
                        customerDetailObj: this.customerDetailObj,
                        offerId: this.offerId
                    },
                    disableClose: true
                });
                dialogRef.afterClosed().subscribe(result => {
                    if (result) {
                        cat = result.category;
                        var catQtyCount = 0;
                        result.category.categoryMaterialList.forEach(flipButton => {
                            catQtyCount = catQtyCount + flipButton.materialQty;
                            if (flipButton.outdoorFileList && flipButton.outdoorFileList.length > 0) {
                                if (this.outdoorFileList.length > 0) {
                                    this.outdoorFileList.filter(mat => {
                                        if (mat.materialId == flipButton.id)
                                            mat.files = flipButton.outdoorFileList[0].files;
                                        else
                                            this.outdoorFileList.push(...flipButton.outdoorFileList);
                                    });
                                }
                                else
                                    this.outdoorFileList.push(...flipButton.outdoorFileList);
                            }
                        });
                        cat.imageCount = this.outdoorFileList.filter(x => x.categoryId == cat.id).reduce((sum, current) => sum + Number(current.files.length), 0);
                        cat.qty = catQtyCount;
                        this.offerRoomList = result.roomList;
                    }
                    this.calculateTotal();
                    this.disableAllOfferTabs();
                });
            }
            else {
                cat.qty = (cat.qty ? cat.qty : 0) + 1;
                cat.categoryMaterialList[0].materialQty = cat.qty;
            }
            cat.isSelected = true;
        }
        setTimeout(() => {
            this.IsOfferCategorySelected = this.offerCategoriesJson.some(x => x.isSelected == true);
            this.disableAllOfferTabs();
        }, 100);
    }

    removeOfferSelection(cat, id, index) {
        cat.categoryMaterialList.filter((o, i) => {
            if (o.id == id && i == index) {
                cat.qty = cat.qty - o.materialQty;
                o.isChecked = false;
                o.materialQty = 0;
                o.length = 0;
                o.width = 0;
                o.height = 0;
                o.total = 0;
                o.roomList.filter(p => {
                    p.isChecked = false;
                });
            }
        });
        if (!cat.categoryMaterialList.find(o => o.isChecked == true)) {
            cat.isSelected = false;
        }
        this.calculateTotal();
        this.disableAllOfferTabs();
    }

    goToDetailPage() {
        this.categoryIdList = this.offerCategoriesJson.filter(x => x.isSelected == true).map(y => y.id);
        var categoryItem = this.offerCategoriesJson.filter(x => x.isSelected == true);
        var categoryItemObj: OfferCategoryMaterial[] = [];
        this.offerCategoriesJson.filter(x => {
            if (x.isSelected == true) {
                categoryItemObj.push(...x.categoryMaterialList);
            }
        });
        this.displayComingSoon = false;
        this.selectedOfferName = "";
        this.offerCategories = new Array();
        //if (categoryItem[0].isLeafNode) {
        this.selectedOfferName = "";
        this.displayForm = categoryItem.length > 0 ? categoryItem[0].formName : this.previousSelectObj.formName;
        this.selectedCategory.offerCategoryId = this.categoryIdList.length > 0 ? this.categoryIdList.join() : this.previousSelectObj.id;
        this.selectedCategory.name = this.previousSelectObj.name;
        this.selectedCategory.offerId = this.offerId;
        this.selectedCategory.formName = categoryItem.length > 0 ? categoryItem[0].formName : this.previousSelectObj.formName;
        this.selectedCategory.isShowRoomDetail = categoryItem.length > 0 ? categoryItem[0].showRoomDetails : this.previousSelectObj.showRoomDetails;
        this.selectedCategory.offerCategoryMaterial = categoryItemObj ? categoryItemObj : null;
        this.selectedCategory.fileList = this.outdoorFileList;
        this.selectedCategory.offerCategoriesJson = this.offerCategoriesJson;
        this.selectedCategory.customerDetailObj = this.customerDetailObj;
        this.formName = this.displayForm;
        if (this.formName != this.offerFormName.FormB
            && this.formName != this.offerFormName.FormA
            && this.formName != this.offerFormName.FormC
            && this.formName != this.offerFormName.FormD
            && this.formName != this.offerFormName.FormE) {
            this.displayComingSoon = true;
        }
        //this.eventDisableTabs.emit(false);
        //}
        this.eventGotoDetailPage.emit(this.selectedCategory);
    }

    addNewCategory() {
        const dialogRef = this.dialog.open(EditOfferCategoryComponent, {
            height: '70%',
            width: '80%',
            data: {
                offerCategoryId: null,
                previousCategory: this.previousSelectObj
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                result.isSelected = false;
                this.offerCategories.push(result);
                this.offerCategoriesJson.push(result);
            }
        });
    }

    deleteOfferFeature(id, name) {
        var Message = "";
        this.translate.get("Headers.DeleteCategory").subscribe({
            next: (res) => {
                Message = res;
                Message = Message + " " + name + " ?";
            },
            error: err => {
            }
        });
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: '200px',
            data: { Header: Message }
        })
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.delete(id);
            }
        });
    }

    delete(id) {
        this.projectofferService.DeleteOfferCategory(id).subscribe({
            next: (response: ResponseBase) => {
                if (response) {
                    this.GetOfferCategories(true);
                    this.translate.get("MESSAGE.Deleted").subscribe({
                        next: (res) => {
                            this.notificationsService.success(res);
                        }
                    });
                }
            },
            error: (err) => {
                this.translate.get("MESSAGE.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    }
                });
            }
        });
    }

    GetOffer() {
        this.projectofferService.GetSingle<ResponseBase>(this.offerId)
            .subscribe((response: ResponseBase) => {
                if (response != null && response.result) {
                    var projectOfferDetail = response.result;
                    this.projectOffer = response.result;
                    if (projectOfferDetail.customerId) {
                        this.getCustomerById(projectOfferDetail.customerId);
                    }
                    //this.requestOfferCreateLater.OfferID = this.moduleId;
                    this.requestOfferCreateLater.PropertyID = this.projectOffer.propertyId ? this.projectOffer.propertyId : "";
                    this.requestOfferCreateLater.CustomerID = this.projectOffer.customerId ? this.projectOffer.customerId : "";
                    this.getCartOfferList(true);
                }
            },
                error => (err) => {
                });
    }

    getCustomerById(customerId) {
        this.customersService.GetSingle<ResponseBase>(customerId).subscribe({
            next: (response: ResponseBase) => {
                this.customerDetailObj = response.result;
                this.selectedCategory.customerDetailObj = this.customerDetailObj;
            },
            error: err => { },
        });
    }

    setCategoryName(id, categoryName, index) {
        this.selectedCategoryNameForImg = categoryName;
        this.selectedCategoryIdForImg = id;
        this.categoryIndex = index
    }

    OnuploadFile(event) {
        var documentObj = new OutdoorFileList();
        if (event.target.files.length > 0) {
            const files = event.target.files;
            const formData = new FormData();
            for (var i = 0; i < files.length; i++) {
                documentObj.files.push(files[i]);
            }
            documentObj.categoryId = this.selectedCategoryIdForImg;
            documentObj.categoryName = this.selectedCategoryNameForImg;
            if (this.outdoorFileList.length == 0) {
                this.outdoorFileList[0] = documentObj
            } else {
                this.outdoorFileList.push(documentObj)
            }
            this.offerCategories[this.categoryIndex].imageCount = files.length;
        }
    }

    // gotoRoom() {
    //     this.eventGotoRoom.emit("");
    // }

    disableAllOfferTabs() {
        var count = this.offerCategoriesJson.filter(x => x.isSelected == true && x.qty > 0).length;
        if (count && count > 0)
            this.eventDisabledTabsFromOffer.emit(true);
        else
            this.eventDisabledTabsFromOffer.emit(false);
    }

    resetCategorySelection() {
        this.SelectedOfferOnCart = new OfferTypeCreateLater();
        this.offerCategoriesJson.filter(x => {
            if (x.isSelected) {
                x.isSelected = false
                x.qty = 0;
                if (x.categoryMaterialList && x.categoryMaterialList.length > 0) {
                    x.categoryMaterialList.filter(m => {
                        if (m.isChecked) {
                            m.width = 0;
                            m.length = 0;
                            m.total = 0;
                            m.materialQty = 0;
                            m.isShowRoomList = false;
                            m.baseline = 0;
                            m.height = 0;
                            m.area = 0;
                            m.price = 0;
                            m.extraM2QtyWall = 0;
                            m.extraM2QtyCeiling = 0;
                            m.extraM2QtyFloor = 0;
                            m.isChecked = false
                            m.offerMaterialText = "";
                            m.colorCode = null;
                            m.isAddExtraQty = false;
                            if (m.roomList && m.roomList.length > 0) {
                                m.roomList.filter(x => x.isChecked = false);
                            }
                        }
                    })
                }
            }
        });
        this.outdoorFileList = [];
        this.categoryIdList = [];
        this.selectedCategory = new OfferTypeWithRoomPriceDetail();
        setTimeout(() => {
            this.IsOfferCategorySelected = this.offerCategoriesJson.some(x => x.isSelected == true);
            this.disableAllOfferTabs();
            this.calculateTotal();
            this.isDisplayPDFEmailbtn = false;
        }, 100);
    }

    calculateTotal() {
        var tempTotal = 0;
        this.paintTimer = 0;
        this.OfferTypeDetail.drivingAmountTotal = 0;
        this.offerCategoriesJson.filter(x => {
            if (x.isSelected == true) {
                tempTotal += x.categoryMaterialList.filter(y => y.isChecked).reduce((sum, current) => sum + current.total, 0)
                this.OfferTypeDetail.drivingAmountTotal += x.categoryMaterialList.filter(y => y.isChecked && !y.notCalculateToDriving).reduce((sum, current) => sum + current.total, 0)
                this.paintTimer += x.categoryMaterialList.filter(y => y.isChecked).reduce((sum, current) => sum + (current.timer ? (current.materialQty * current.timer) : 0), 0);
            }
        });
        this.totalExclVAT = isNaN(tempTotal) ? 0 : tempTotal;
        this.vat = ((this.totalExclVAT) * 25 / 100);
        this.totalInclVAT = (this.totalExclVAT + this.vat);
        //console.log("drivingAmountTotal", this.OfferTypeDetail.drivingAmountTotal);
        this.calculateDistanceAmt();
    }

    goToBackCategory() {
        this.displayComingSoon = false;
        this.displayForm = "";
        var parentCategoryArray = new Array();
        if (this.previousSelectObj.parentID) {
            this.offerCategoriesJson.filter(x => {
                if (x.parentID == this.previousSelectObj.parentID) {
                    parentCategoryArray.push(x);
                }
            })
        }
        else {
            this.offerCategoriesJson.filter(x => {
                if (x.parentID == null) {
                    parentCategoryArray.push(x);
                }
            })
        }
        this.previousSelectObj = this.offerCategoriesJson.filter(x => x.id == this.previousSelectObj.id)[0];
        this.offerCategories = parentCategoryArray;
        if (this.previousSelectObj) {
            this.selectedOfferName = this.previousSelectObj.name;
            this.isInnerMultipleOptions = this.previousSelectObj.multicheck;
        }
        else
            this.selectedOfferName = "";
        this.isInnerMultipleOptions = false;
        this.IsOfferCategorySelected = this.offerCategoriesJson.some(x => x.isSelected == true);
        this.goToSubCategory(this.previousSelectObj);
        this.eventGoToBackCategory.emit(this.displayForm);
    }

    // eventDisabledTabsFromOffer(event) {
    //     this.isDisplayPDFEmailbtn = true;
    //     this.eventDisableTabs.emit(false);
    // }
    goToBackPDFEmail() {
        this.eventGotoPDFEmail.emit("");
    }

    moveCategory(cat) {
        if (cat) {
            const dialogRef = this.dialog.open(MoveOfferCategoryComponent, {
                width: '75%',
                data: {
                    category: cat,
                    offerCategoriesList: this.offerCategories,
                    offerCategoriesJson: this.offerCategoriesJson,
                    previousSelectObj: this.previousSelectObj
                }
            });
            dialogRef.afterClosed().subscribe(result => {
                if (result)
                    this.GetOfferCategories(false);
            });
        }
    }

    AddToCart(isGoToDetailPage) {
        const dialogRef = this.dialog.open(SharedTextBoxPopup, {
            width:'30%',
            data: {
                HeadingTitleKey: "HEADERS.AddedToCart",
                TextBoxLabelKey: "FIELDS.Name",
                 TextBoxValue: (this.projectOffer.address ? this.projectOffer.address : "") + (this.projectOffer.postNumber ? " "+ this.projectOffer.postNumber : "") +  (this.projectOffer.city ? " "+ this.projectOffer.city :""),
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result && result.IsSuccess) {
                this.OfferTypeCreateLater = new OfferTypeCreateLater();
                this.OfferTypeCreateLater.offerId = this.offerId;
                this.OfferTypeCreateLater.name = result.TextValue;
                this.OfferTypeCreateLater.categoryLaterList = [];
                var selectedCat = this.offerCategoriesJson.filter(o => o.isSelected);
                for (var i = 0; i < selectedCat.length; i++) {
                    var offerCategoryLaterDTO = new OfferCategoryLaterDTO();
                    offerCategoryLaterDTO.id = selectedCat[i].id;
                    offerCategoryLaterDTO.name = selectedCat[i].name;
                    offerCategoryLaterDTO.qty = selectedCat[i].qty;
                    offerCategoryLaterDTO.categoryMaterialList = [];
                    var categoryMaterial = selectedCat[i].categoryMaterialList.filter(o => o.isChecked);
                    for (var j = 0; j < categoryMaterial.length; j++) {
                        var offferCreateLater = new OfferCreateLaterCategoryMaterialDTO();
                        offferCreateLater.id = categoryMaterial[j].id;
                        offferCreateLater.materialTypeID = categoryMaterial[j].materialTypeID;
                        offferCreateLater.offerMaterialText = categoryMaterial[j].offerMaterialText;
                        offferCreateLater.text = categoryMaterial[j].offerMaterialText;
                        offferCreateLater.colorCode = categoryMaterial[j].colorCode;
                        offferCreateLater.length = categoryMaterial[j].length;
                        offferCreateLater.width = categoryMaterial[j].width;
                        offferCreateLater.height = categoryMaterial[j].height;
                        offferCreateLater.baseline = categoryMaterial[j].baseline;
                        offferCreateLater.area = categoryMaterial[j].area;
                        offferCreateLater.materialQty = categoryMaterial[j].materialQty;
                        offferCreateLater.price = categoryMaterial[j].price;
                        offferCreateLater.total = categoryMaterial[j].total;
                        offferCreateLater.isCopyMaterial = categoryMaterial[j].isCopyMaterial;
                        var selectedRoom = categoryMaterial[j].roomList.find(o => o.isChecked);
                        if (selectedRoom) {
                            offferCreateLater.roomId = selectedRoom.id;
                            offferCreateLater.roomName = selectedRoom.name;
                        }
                        offerCategoryLaterDTO.categoryMaterialList.push(offferCreateLater);
                    }
                    this.OfferTypeCreateLater.categoryLaterList.push(offerCategoryLaterDTO);
                }
                if (this.SelectedOfferOnCart) {
                    this.OfferTypeCreateLater.id = this.SelectedOfferOnCart.id;
                    this.OfferTypeCreateLater.name = result.TextValue;
                }
                if (this.moduleName == "customer") {
                    this.OfferTypeCreateLater.propertyId = this.moduleId
                    this.OfferTypeCreateLater.customerId = this.customerId;
                }
                else {
                    this.OfferTypeCreateLater.propertyId = this.projectOffer.propertyId;
                    this.OfferTypeCreateLater.customerId = this.projectOffer.customerId;
                }
                this.OfferTypeCreateLater.numberOfHours = this.OfferTypeDetail.numberOfHours;
                this.OfferTypeCreateLater.numberOfDrivingDays = this.OfferTypeDetail.numberOfDrivingDays;
                this.OfferTypeCreateLater.distance = this.OfferTypeDetail.distance;
                this.OfferTypeCreateLater.time = this.OfferTypeDetail.time;
                //this.OfferTypeCreateLater.TotalTime = this.OfferTypeDetail.TotalTime;
                
                this.showSpinner = true;
                this.projectofferService.CreateOrUpdateOfferLater(this.OfferTypeCreateLater).subscribe({
                    next: (response) => {
                        if (!isGoToDetailPage)
                            this.resetCategorySelection();
                        this.translate.get("MESSAGES.Success").subscribe({
                            next: (res) => {
                                this.notificationsService.success(res);
                                this.showSpinner = false;
                                if (!this.SelectedOfferOnCart.id) {
                                    this.getCartOfferList(true);
                                }
                                this.SelectedOfferOnCart = new OfferTypeCreateLater();
                                if (isGoToDetailPage)
                                    this.goToDetailPage();
                            },
                            error: err => {
                                this.showSpinner = false;
                            }
                        });
                    },
                    error: err => {
                        this.showSpinner = false;
                        this.translate.get("MESSAGES.Failed").subscribe({
                            next: (res) => {
                                this.notificationsService.error(res);
                            },
                            error: err => {
                            }
                        });
                    },
                    complete: () => {
                        this.showSpinner = false;
                    }
                });
            }
            else {
                if (isGoToDetailPage)
                    this.goToDetailPage();
            }
        });
    }

    copyOfferFeature(id, name) {
        const dialogRef = this.dialog.open(SharedTextBoxPopup, {
            width: '30%',
            data: {
                HeadingTitleKey: "HEADERS.AddedToCart",
                TextBoxLabelKey: "FIELDS.Name",
                TextBoxValue: name,
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result && result.IsSuccess) {
                var copyFeat = {
                    "id": id,
                    "categoryName": result.TextValue
                }
                this.projectofferService.CopyOfferCategroyWithMaterial(copyFeat).subscribe({
                    next: (response) => {
                        this.translate.get("MESSAGES.Success").subscribe({
                            next: (res) => {
                                this.notificationsService.success(res);
                                this.showSpinner = false;
                            },
                            error: err => {
                                this.showSpinner = false;
                            }
                        });
                        this.GetOfferCategories(true);
                    },
                    error: err => {
                        this.showSpinner = false;
                        this.translate.get("MESSAGES.Failed").subscribe({
                            next: (res) => {
                                this.notificationsService.error(res);
                            },
                            error: err => {
                            }
                        });
                    },
                    complete: () => {
                        this.showSpinner = false;
                    }
                });
            }
        });
    }

    getCartOfferList(isRefreshCount: boolean) {
        this.showSpinner = true;
        this.projectofferService.GetOfferCreateLaterForAllModule(this.requestOfferCreateLater).subscribe({
            next: (response: ResponseBase) => {
                this.OfferTypeCreateLaterList = response.result;
                this.AddedCartCount = this.OfferTypeCreateLaterList ? this.OfferTypeCreateLaterList.length : 0;
                if (!isRefreshCount) {
                    this.selectSavedOffer();
                }
                this.showSpinner = false;
            },
            error: err => {
                this.showSpinner = false;
            },
        });
    }

    selectSavedOffer() {
        this.resetCategorySelection();
        const dialogRef = this.dialog.open(SelectSavedOfferComponent, {
            width: '25%',
            data: this.OfferTypeCreateLaterList
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                var selectedSavedOffer = this.OfferTypeCreateLaterList.find(o => o.id == result.id);
                this.SelectedOfferOnCart = selectedSavedOffer;
                this.SelectedCart = JSON.parse(selectedSavedOffer.categoryLaterListJson);
                this.OfferTypeDetail.numberOfHours = this.SelectedOfferOnCart.numberOfHours;
                this.OfferTypeDetail.numberOfDrivingDays = this.SelectedOfferOnCart.numberOfDrivingDays;
                this.OfferTypeDetail.distance = this.SelectedOfferOnCart.distance;
                this.OfferTypeDetail.time = this.SelectedOfferOnCart.time;
                this.getOfferMaterialData();
            }
        });
    }

    getOfferMaterialData() {
        this.showSpinner = true;
        this.projectofferService.GetOfferCategoryMaterial().subscribe({
            next: (response: ResponseBase) => {
                this.MaterialObject = response.result.myResponse;
                this.getCategoryMaterialOnEdit();
                this.showSpinner = false;
            },
            error: err => {
                this.showSpinner = false;
            },
            complete: () => {
            }
        });
    }

    getCategoryMaterialOnEdit() {
        for (var j = 0; j < this.SelectedCart.length; j++) {
            let selectedCat = this.offerCategoriesJson.find(o => o.id == this.SelectedCart[j].id);
            selectedCat.qty = this.SelectedCart[j].qty;
            selectedCat.isSelected = true;
            let copyMaterialList = [];
            for (let i = 0; i < this.SelectedCart[j].categoryMaterialList.length; i++) {
                var addedCartMaterialCat = selectedCat.categoryMaterialList.find(o => o.id == this.SelectedCart[j].categoryMaterialList[i].id);
                if (addedCartMaterialCat && !this.SelectedCart[j].categoryMaterialList[i].isCopyMaterial) {
                    addedCartMaterialCat.isChecked = true;
                    if (addedCartMaterialCat.materialTypeID)
                        addedCartMaterialCat.materialList =
                            this.MaterialObject.filter(o => {
                                return o.type.split(',').find(p => p == this.SelectedCart[j].categoryMaterialList[i].materialTypeID.toString())
                            });
                    else
                        addedCartMaterialCat.materialList = this.MaterialObject;
                    addedCartMaterialCat.materialQty = this.SelectedCart[j].categoryMaterialList[i].materialQty;
                    addedCartMaterialCat.length = this.SelectedCart[j].categoryMaterialList[i].length;
                    addedCartMaterialCat.width = this.SelectedCart[j].categoryMaterialList[i].width;
                    addedCartMaterialCat.height = this.SelectedCart[j].categoryMaterialList[i].height;
                    addedCartMaterialCat.total = this.SelectedCart[j].categoryMaterialList[i].total;
                    addedCartMaterialCat.price = this.SelectedCart[j].categoryMaterialList[i].price;
                    addedCartMaterialCat.isCopyMaterial = this.SelectedCart[j].categoryMaterialList[i].isCopyMaterial;
                    addedCartMaterialCat.roomList = [];
                    addedCartMaterialCat.roomList = this.offerRoomList.map(x => Object.assign({}, x));
                    if (this.SelectedCart[j].categoryMaterialList[i].roomId) {
                        var selectedRoom = addedCartMaterialCat.roomList.find(o => o.id == this.SelectedCart[j].categoryMaterialList[i].roomId);
                        if (!selectedRoom)
                            selectedRoom = addedCartMaterialCat.roomList.find(o => o.propertyRoomID == this.SelectedCart[j].categoryMaterialList[i].roomId);
                        if (selectedRoom) {
                            selectedRoom.isChecked = true;
                        }
                    }
                    this.getMaterialPrice(addedCartMaterialCat.offerMaterialID,
                        addedCartMaterialCat,
                        addedCartMaterialCat.materialPriceID ?
                            addedCartMaterialCat.materialPriceID : "");
                    if (addedCartMaterialCat.materialTypeID == 27) {
                        addedCartMaterialCat.offerMaterialText = this.SelectedCart[j].categoryMaterialList[i].offerMaterialText;
                        addedCartMaterialCat.price = this.SelectedCart[j].categoryMaterialList[i].price;
                    }
                }
                else {
                    let copyMaterial = Object.assign({}, addedCartMaterialCat);
                    copyMaterial.isChecked = true;
                    if (copyMaterial.materialTypeID)
                        copyMaterial.materialList =
                            this.MaterialObject.filter(o => {
                                return o.type.split(',').find(p => p == this.SelectedCart[j].categoryMaterialList[i].materialTypeID.toString())
                            });
                    else
                        copyMaterial.materialList = this.MaterialObject;
                    copyMaterial.materialQty = this.SelectedCart[j].categoryMaterialList[i].materialQty;
                    copyMaterial.length = this.SelectedCart[j].categoryMaterialList[i].length;
                    copyMaterial.width = this.SelectedCart[j].categoryMaterialList[i].width;
                    copyMaterial.height = this.SelectedCart[j].categoryMaterialList[i].height;
                    copyMaterial.total = this.SelectedCart[j].categoryMaterialList[i].total;
                    copyMaterial.price = this.SelectedCart[j].categoryMaterialList[i].price;
                    copyMaterial.isCopyMaterial = this.SelectedCart[j].categoryMaterialList[i].isCopyMaterial;
                    copyMaterial.roomList = []
                    copyMaterial.roomList = this.offerRoomList.map(x => Object.assign({}, x));
                    if (this.SelectedCart[j].categoryMaterialList[i].roomId) {
                        var selectedRoom = copyMaterial.roomList.find(o => o.id == this.SelectedCart[j].categoryMaterialList[i].roomId);
                        if (!selectedRoom)
                            selectedRoom = copyMaterial.roomList.find(o => o.propertyRoomID == this.SelectedCart[j].categoryMaterialList[i].roomId);
                        if (selectedRoom) {
                            selectedRoom.isChecked = true;
                        }
                    }
                    this.getMaterialPrice(copyMaterial.offerMaterialID,
                        copyMaterial,
                        copyMaterial.materialPriceID ?
                            copyMaterial.materialPriceID : "");
                    copyMaterialList.push(copyMaterial);
                    if (copyMaterial.materialTypeID == 27) {
                        copyMaterial.offerMaterialText = this.SelectedCart[j].categoryMaterialList[i].offerMaterialText;
                        copyMaterial.price = this.SelectedCart[j].categoryMaterialList[i].price;
                    }
                }
            }
            selectedCat.categoryMaterialList.push(...copyMaterialList);
        }
        this.IsOfferCategorySelected = this.offerCategoriesJson.some(x => x.isSelected == true);
        this.calculateTotal();
    }

    getMaterialPrice(materialId, offerItem, materialPriceId) {
        var materialprice = this.MaterialObject.find(o => o.id == materialId);
        if (materialprice) {
            offerItem.imagePath = materialprice.imagePath;
            offerItem.selectCategoryTimeForDiverse = [];
            for (var i = 0; i < materialprice.offerMaterialPriceList.length; i++) {
                var obj = this.SelectCategoryTime.find(o => o.id == materialprice.offerMaterialPriceList[i].type);
                if (obj) {
                    if (i == 0) {
                        offerItem.materialPriceID = materialprice.offerMaterialPriceList[i].id;
                        //offerItem.price = materialprice.offerMaterialPriceList[i].price;
                        offerItem.price = offerItem.price;
                        offerItem.colorCode = offerItem.colorCode;
                    }
                    offerItem.selectCategoryTimeForDiverse.push({
                        id: obj.id,
                        key: obj.key,
                        extraValue: materialprice.offerMaterialPriceList[i].paintText,
                        priceId: materialprice.offerMaterialPriceList[i].id,
                        reminder: materialprice.offerMaterialPriceList[i].reminder,
                    });
                    if (materialprice.offerMaterialPriceList[i].id == materialPriceId) {
                        offerItem.materialPriceID = materialprice.offerMaterialPriceList[i].id;
                        offerItem.price = (offerItem.price == 0 ? materialprice.offerMaterialPriceList[i].price : offerItem.price);
                        offerItem.text = materialprice.text + ' ' + materialprice.offerMaterialPriceList[i].paintText;
                        var offerReminderText = materialprice.offerMaterialPriceList[i].reminder ? materialprice.offerMaterialPriceList[i].reminder : "";
                    }
                    else {
                        offerItem.text = materialPriceId ? offerItem.text : materialprice.text + ' ' + materialprice.offerMaterialPriceText;
                        offerItem.colorCode = (offerItem.colorCode != undefined || offerItem.colorCode != null) ? offerItem.colorCode : materialprice.colorCode;
                        offerItem.unit = materialprice.unit;
                        var offerReminderText = materialprice.offerMaterialPriceList[i].reminder ? materialprice.offerMaterialPriceList[i].reminder : "";
                    }

                    if (materialprice.offerMaterialPriceList[i].isDefault && materialPriceId == "") {
                        offerItem.materialPriceID = materialprice.offerMaterialPriceList[i].id;
                        offerItem.price =  (offerItem.price == 0 ? materialprice.offerMaterialPriceList[i].price : offerItem.price);
                        var offerReminderText = materialprice.offerMaterialPriceList[i].reminder ? materialprice.offerMaterialPriceList[i].reminder : "";
                        offerItem.text = materialprice.text + ' ' + materialprice.offerMaterialPriceList[i].paintText + (materialprice.addNumberOfTimesOnPDF ? this.projectofferService.getPaintType(materialprice.offerMaterialPriceList[i].type) : "");
                        if (offerItem.materialList && offerItem.materialList.length > 0) {
                            offerItem.materialList.filter(m => {
                                if (m.id == materialId) {
                                    var tempPriceData = m.offerMaterialPriceList.filter(x => x.id == offerItem.materialPriceID);
                                    if (tempPriceData && tempPriceData.length > 0)
                                        m.offerMaterialPriceText = tempPriceData[0].paintText;
                                }
                            })
                        }
                    }
                    offerItem.originalPrice = offerItem.price;
                    if (offerReminderText && materialprice.reminder) {
                        offerItem.reminder = materialprice.reminder + " - " + offerReminderText;
                    } else if (offerReminderText && !materialprice.reminder) {
                        offerItem.reminder = offerReminderText;
                    } else {
                        offerItem.reminder = materialprice.reminder ? materialprice.reminder : "";
                    }
                }
            }
            if (materialprice.isPostNumberDiscount && this.projectOffer && this.projectOffer.percentage != 0 && !this.customerDetailObj.doNotCalculatePriceForPostNumber)
                offerItem.price = offerItem.price + ((offerItem.price * this.projectOffer.percentage) / 100);
        }
    }

    goToOffer() {
        const dialogRef = this.dialog.open(OfferSelectionComponent, {
            data: { customerId: this.customerId, propertyId: this.moduleId }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
            }
        });
    }

    autoSaveToCard() {
        if (this.SelectedCart && this.SelectedCart.length > 0)
            this.goToDetailPage();
        else {
            if (this.IsOfferCategorySelected) {
                const dialogRef = this.dialog.open(AlertMessageModelComponent, {
                    data: { Header: 'Headers.AutoSaveToCard' }
                });
                dialogRef.afterClosed().subscribe(result => {
                    if (result)
                        this.AddToCart(true);
                    else
                        this.goToDetailPage();
                });
            }
            else
                this.goToDetailPage();
        }
    }

    GetCompanyInformation() {
        this.companyInfoService.GetCompanyInformationData<ResponseBase>()
            .subscribe((response: ResponseBase) => {
                if (response) {
                    this.companyInfoData = response.result;
                }
            },
                (err: any) => {
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        }, error: err => { }
                    });
                },
                () => {
                });
    }

    showDirection() {
        //open popup
        const dialogRef = this.dialog.open(GoogleMapDirectionComponent, {
            width: '80%',
            height: '80%',
            disableClose: true,
            data: {
                Address: this.projectOffer.address,
                ZipCode: this.projectOffer.postNumber,
                City: this.projectOffer.city,
                SourceAdd: this.companyInfoData,
                autoSearchOnOpen: true,
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.OfferTypeDetail.distance = result.travelingDetails.distance;
                this.OfferTypeDetail.time = result.travelingDetails.duration;
                if (result.travelingDetails.distance.toString().split(" ")[1])
                    this.meterAndKmText = result.travelingDetails.distance.toString().split(" ")[1];
                //this.calculateDistanceAmt();
                this.calculateTotal();
                setTimeout(() => {
                    this.OfferTypeDetail.numberOfDrivingDays = this.OfferTypeDetail.numberOfDays;
                }, 500);
            }
        });
    }

    calculateDistanceAmt() {
        var amount = 0;
        amount = this.calculateTotalTime(this.OfferTypeDetail.numberOfDays);
        amount = amount * this.DistancePerDayValue;
        this.OfferTypeDetail.travelingAmount = amount;
        this.OfferTypeDetail.priceTotal = this.totalExclVAT;
        this.OfferTypeDetail.price = ((this.OfferTypeDetail.priceTotal + this.OfferTypeDetail.travelingAmount) - this.OfferTypeDetail.discount);
        this.OfferTypeDetail.vat = ((this.OfferTypeDetail.price) * 25 / 100);
        //this.OfferTypeDetail.totalInclVAT = (this.OfferTypeDetail.price + this.OfferTypeDetail.vat + this.OfferTypeDetail.travelingAmount);
        this.OfferTypeDetail.totalInclVAT = (this.OfferTypeDetail.price + (this.OfferTypeDetail.price * 25 / 100));
        this.OfferTypeDetail.numberOfHours = (this.OfferTypeDetail.drivingAmountTotal / this.projectsCoworkerPrice);
        this.OfferTypeDetail.numberOfDays = Math.ceil(Math.ceil((this.OfferTypeDetail.numberOfHours / 7.5) / 0.25) * 0.25);
        this.calTravelingAmtOnDrivingDaysChange();
    }

    calTravelingAmtOnDrivingDaysChange() {
        var amount = 0;
        amount = this.calculateTotalTime(this.OfferTypeDetail.numberOfDrivingDays == this.OfferTypeDetail.numberOfDays ? this.OfferTypeDetail.numberOfDrivingDays : this.OfferTypeDetail.numberOfDays);
        amount = amount * this.DistancePerDayValue;
        this.OfferTypeDetail.travelingAmount = amount;
        this.OfferTypeDetail.price = ((this.OfferTypeDetail.priceTotal + this.OfferTypeDetail.travelingAmount) - this.OfferTypeDetail.discount);
        this.OfferTypeDetail.vat = ((this.OfferTypeDetail.price) * 25 / 100);
        //this.OfferTypeDetail.totalInclVAT = (this.OfferTypeDetail.price + this.OfferTypeDetail.vat + this.OfferTypeDetail.travelingAmount);
        this.totalExclVAT = this.OfferTypeDetail.price;
        this.OfferTypeDetail.totalInclVAT = (this.OfferTypeDetail.price + (this.OfferTypeDetail.price * 25 / 100));
        this.OfferTypeDetail.numberOfHours = (this.OfferTypeDetail.drivingAmountTotal / this.projectsCoworkerPrice);
        this.OfferTypeDetail.numberOfDays = Math.ceil(Math.ceil((this.OfferTypeDetail.numberOfHours / 7.5) / 0.25) * 0.25);
    }

    calculateTotalTime(days) {
        this.OfferTypeDetail.TotalTime = 0;
        if (this.OfferTypeDetail.time) {
            var time = this.OfferTypeDetail.time;
            this.OfferTypeDetail.TotalTime = ((time * 2) * Math.ceil(days ? days : this.OfferTypeDetail.numberOfDays));
        }
        return this.OfferTypeDetail.TotalTime;
    }

    getTravelingDistancePerDayValue() {
        this.projectofferService.GetDistancePerMinuteValue().subscribe((response: ResponseBase) => {
            if (response) {
                this.DistancePerDayValue = parseFloat(response.result.myResponse[0].value);
            }
        });
    }
}
