import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class DashboardService implements Resolve<any>
{
  Reminders: any[] = [];
  TodaysReminders: any;
  TomorrowsReminder: any;
  OtherDaysReminder: any;

  constructor(private http: HttpClient) {

  }

  /**
   * Resolve
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

    return new Promise((resolve, reject) => {

      Promise.all([
        this.getReminders("Today"),
      ]).then(
        () => {
          resolve();
        },
        reject
        );
    });
  }

  getReminders(reminder): Promise<any> {

    return new Promise((resolve, reject) => {
      this.http.get('api/dashboard-reminders')
        .subscribe((response: any) => {
          this.Reminders = new Array();
          for (var i = 0; i < response.length; i++) {
            if (reminder == "I dag") {
              if (response[i].date == "23/04/2018") {
                this.Reminders.push(response[i]);
                this.TodaysReminders = this.Reminders;
                this.Reminders = this.TodaysReminders;
              }

            }
            else if (reminder == "I morgen") {
              if (response[i].date == "24/04/2018") {
                this.Reminders.push(response[i]);
                this.TomorrowsReminder = this.Reminders;
                this.Reminders = this.TomorrowsReminder;
              }
            }
            else {
              if (response[i].date != "23/04/2018" && response[i].date != "24/04/2018") {
                this.Reminders.push(response[i]);
                this.OtherDaysReminder = this.Reminders;
                this.Reminders = this.OtherDaysReminder;
              }
            }
          }

          //var tomorrow = currentTime.getDate() + 1

          resolve(response);
        }, reject);
    });
  }
}
