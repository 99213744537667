import { Component, OnInit } from '@angular/core';
import { FuseTranslationLoaderService } from '../../../../../core/services/translation-loader.service';
import { Router, NavigationExtras } from '@angular/router';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { ResponseBase } from '../../../../model/ResponseBase';
import { AuthService } from '../../../../../core/services/auth.service';
import { EventEmitterEnum } from '../../../../../core/enum/event-emitter-ids.enum';
import { TitleService } from '../../../../../core/services/title.service';
import { ProjectofferService } from '../../project-offer.service';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { OfferRejectedQuestionDTO, QuestionTypeCategory } from 'app/main/model/OfferV2';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { FormService } from 'app/core/services/form.service';
import { MatDialog } from '@angular/material';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';

@Component({
  selector: 'project-offer-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss']
})
export class ProjectOfferQuestionComponent implements OnInit {
  showSpinner: boolean = false;
  currentObject = new OfferRejectedQuestionDTO();
  questionAnswerList = [];
  isSaveData: boolean = false;
  questionId: string = "";
  favoriteSeason: boolean = true;
  isEditQuestion: boolean = false;
  ratingArrayList = [];
  IsExpand: boolean = false;
  moduleName = "Offer";
  constructor(private translationLoader: FuseTranslationLoaderService,
    private authService: AuthService,
    private projectofferService: ProjectofferService,
    private notificationsService: NotificationsService,
    private _router: Router,
    private translate: TranslateService,
    private titleService: TitleService,
    private formBuilder: FormBuilder,
    private formService: FormService,
    private dialog: MatDialog) {
    this.titleService.SetTitle("TITLE_PROJECTS_OFFERS");
    this.translationLoader.loadTranslations(english, danish);
  }

  ngOnInit() {
    this.questionId = "";
    this.getQuestionList();
  }

  getQuestionList() {
    this.showSpinner = true;
    this.isSaveData = true;
    this.isEditQuestion = false;
    this.questionId = "";
    //this.projectofferService.GetQuestionList().subscribe({
    this.projectofferService.GetAskOfferQuestionList().subscribe({
      next: (response: ResponseBase) => {
        this.showSpinner = false;
        this.isSaveData = false;
        this.questionAnswerList = response.result;
      },
    });
  }

    AddUpdateOfferQuestion(data) {
      this.AddOrUpdateQuestionList(data);
    }
    
    AddOrUpdateQuestionList(data) {
        this.showSpinner = true;
        this.isSaveData = true;
        //this.projectofferService.AddOrUpdateQuestionList(data).subscribe({
        this.projectofferService.OfferAddOrUpdateQuestionList(data).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                this.isSaveData = false;
                this.questionAnswerList = response.result;
                this.translate.get("MESSAGES.Created").subscribe({
                    next: (res) => {
                        this.notificationsService.success(res);
                    },
                    error: err => {
                        this.showSpinner = false;
                        this.isSaveData = false;
                    }
                });
            },
            error: err => {
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                        this.isSaveData = false;
                        this.showSpinner = false;
                    },
                    error: err => {
                    }
                });
            }
        });
    }

  edit(id) {
    this.questionId = id;
    this.isEditQuestion = true;
    this.authService.editSingleQuestion(this.questionId);
  }

  delete(id) {
    this.questionId = id;
    this.authService.deleteSingleQuestion(this.questionId);
  }

  Cancel() {
    this.isEditQuestion = false;
  }

  DeleteQuestionByID(id) {
    this.showSpinner = true;
    this.projectofferService.DeleteQuestion(id).subscribe({
        next: (response: ResponseBase) => {
          if(response.statusCode==200) {
            this.getQuestionList();
            this.translate.get("MESSAGES.Deleted").subscribe({
                next: (res) => {
                    this.notificationsService.create(res);
                },
                error: err => {
                  this.notificationsService.error(err);
                }
            });
          } else {
            this.showSpinner = false;
          }
        },
        error: err => { this.showSpinner = false; },
        complete: () => {  }
    })
  }
}
