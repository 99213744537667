import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@fuse/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FusePipesModule } from 'app/core/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ProjectOfferCategoryComponent } from 'app/main/shared-component/project-offer-category/project-offer-category.component';
import { OfferCategoryQtySelectComponent } from 'app/main/shared-component/project-offer-category/component/offer-category-qty-select/offer-category-qty-select.component';
import { MoveOfferCategoryComponent } from 'app/main/shared-component/project-offer-category/component/move-offer-category/move-offer-category.component';
//import { SharedTextBoxPopup } from 'app/main/shared-component/text-box-popup/text-box-popup.component';
import { SelectSavedOfferComponent } from 'app/main/shared-component/project-offer-category/component/select-saved-offer/select-saved-offer.component';
import { EditOfferCategoryComponent } from 'app/main/shared-component/project-offer-category/component/edit-offer-category/edit-offer-category.component';
import { OfferSelectionComponent } from 'app/main/shared-component/project-offer-category/component/offer-selection/offer-selection.component';
import { MatBadgeModule } from '@angular/material/badge';
import { EditImageModule } from 'app/main/pages/edit-image/edit-image.module';
import { NgxCurrencyModule } from "ngx-currency";
import { CustomCurrencyMaskConfig } from "app/core/constant/CurrencyMaskConfiguartion";
import { FuseDirectivesModule } from '@fuse/directives/directives';
import {RouterModule} from '@angular/router';

@NgModule({
    declarations: [
        ProjectOfferCategoryComponent,
        OfferCategoryQtySelectComponent,
        MoveOfferCategoryComponent,
        SelectSavedOfferComponent,
        EditOfferCategoryComponent,
        OfferSelectionComponent
    ],
    imports: [
        CommonModule,
        MaterialModule,
        FlexLayoutModule,
        FusePipesModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        DatePickerModule,
        NgxMatSelectSearchModule,
        MatBadgeModule,
        EditImageModule,
        NgxCurrencyModule.forRoot(CustomCurrencyMaskConfig),
        RouterModule,
        FuseDirectivesModule
    ],
    exports: [
        ProjectOfferCategoryComponent,
    ],
    entryComponents: [
        ProjectOfferCategoryComponent,
        OfferCategoryQtySelectComponent,
        MoveOfferCategoryComponent,
        SelectSavedOfferComponent,
        EditOfferCategoryComponent,
        OfferSelectionComponent
    ]
})

export class ProjectOfferCategoryModule { }