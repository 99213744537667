import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { setErrorImage } from '../../../core/Validators/validation';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';

@Component({
  selector: 'app-edit-image',
  templateUrl: './edit-image.component.html',
  styleUrls: ['./edit-image.component.scss']

})
export class EditImageComponent implements OnInit {
  @Input() Item: any;
  @Input() DisplayIcon: boolean = true;
  @Input() DefaltImage;
  @Output() ChangeImageEvent = new EventEmitter<string>();
  @Input() moduleName: string;

  host: string = '';

  constructor(private notificationsService: NotificationsService,
    private translate: TranslateService,
    private translationLoader: FuseTranslationLoaderService,) {
    this.translationLoader.loadTranslations(english, danish);
  }

  ngOnInit() {
    var t = this.Item;
    this.host = environment.url;

    if (this.moduleName == "'co-worker'") {
      this.DefaltImage = 'Upload/coworker.png'
    }
    else if (this.moduleName == "'material'") {
      this.DefaltImage = 'Upload/NoImageAvailable.png'
    }
    else if (this.moduleName == "'offer-category'") {
      this.DefaltImage = 'Upload/material.png'
    }
    else if (this.moduleName == "'offer-category'") {
      this.DefaltImage = 'Upload/material.png'
    }
    else {
      this.DefaltImage = 'Upload/coworker.png'
    }
  }

  uploadImage(evt) {
    var files = evt.target.files;
    var file = files[0];
    if (files && file) {
      if (this.hasExtension(file)) {
        //if ((file.size / 524288) > 1)
        //  this.notificationsService.error("You can not upload more than 512Kb file");
        //else {
        var reader = new FileReader();
        reader.onload = this._handleReaderLoaded.bind(this);
        reader.readAsBinaryString(file);
        //}
      }
      else {
        this.notificationsService.error("Select image type only");
      }
    }
  }

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.Item.image = btoa(binaryString);
    this.ChangeImageEvent.emit(this.Item.image);
  }

  setImage() {
    if (this.moduleName == "'co-worker'") {
      this.Item.imagePath = 'Upload/coworker.png'
      this.emitImage();
    }
    else if (this.moduleName == "'material'") {
      this.Item.imagePath = 'Upload/NoImageAvailable.png'
      this.emitImage();
    }
    else if (this.moduleName == "'offer-category'") {
      this.Item.imagePath = 'Upload/material.png'
      this.emitImage();
    }
    else {
      this.Item.imagePath = 'Upload/coworker.png'
      this.emitImage();
    }
    //'Upload/NoImageAvailable.png';

  }

  emitImage() {
    this.Item.image = undefined;
    this.ChangeImageEvent.emit(this.Item.image);
  }

  DeleteImage() {
    this.setImage();
  }

  ClickFileType() {
    document.getElementById('selectFile').click();
  }

  hasExtension(inputfile) {
    var fileName = inputfile.name;
    var _validFileExtensions = [".jpg", ".jpeg", ".bmp", ".gif", ".png"];
    var valid = (new RegExp('(' + _validFileExtensions.join('|').replace(/\./g, '\\.') + ')$')).test(fileName);
    return valid;
  }

}
