import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { emailParams } from 'app/main/model/Project'
import { CompanyInfoSetting } from 'app/main/model/CompanyInfo';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';

@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./../companyInfo/companyInfo.component.scss']
})
export class TextEditorComponent implements OnInit {

  public editor;
  public editorContent = "";
  emailBody: emailParams;
  comapnyInfo: CompanyInfoSetting;
  isEmail: boolean;
  savedContent: string = "";
  public editorOptions = {
    placeholder: "insert content..."
  };

  constructor(public dialogRef: MatDialogRef<TextEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public translationLoader: FuseTranslationLoaderService) {
    this.translationLoader.loadTranslations(english, danish);
  }

  onEditorBlured(quill) {
  }

  onEditorFocused(quill) {
  }

  onEditorCreated(quill) {
    this.editor = quill;
  }

  onContentChanged({ quill, html, text }) {
  }

  ngOnInit() {
    if (this.data.isEmail) {
      this.isEmail = true;
    }
    else {
      this.isEmail = false;
      this.editorContent = this.data.selectedTabData.Description
      this.comapnyInfo = this.data.selectedTabData;
      this.savedContent = this.data.selectedTabData.Description;
    }
  }


  saveTabData() {
    this.comapnyInfo.Description = this.editorContent;
    //this.comapnyInfo.description
    this.dialogRef.close(this.comapnyInfo);
  }

  clearTabData() {
    this.comapnyInfo.Description = "";
    //this.comapnyInfo.description
    this.dialogRef.close(this.comapnyInfo);
  }

  sendEmail() {
    this.emailBody = new emailParams();
    this.emailBody.ProjectId = this.data.projectId;
    this.emailBody.EmailBody = this.editorContent;
    this.dialogRef.close(this.emailBody);
  }

  colseTextEditor() {
    this.comapnyInfo.Description = this.savedContent;
    this.dialogRef.close(this.comapnyInfo);
  }
}
