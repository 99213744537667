import { Component, Input, OnInit } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { MatDialog } from '@angular/material';
import { AuthService } from 'app/core/services/auth.service';
import { SettingsService } from '../../settings.service';
import { ResponseBase } from "app/main/model/ResponseBase";
import { Settings } from "app/main/model/Settings";

@Component({
    selector: "vibe-project-invoice-path-setting",
    templateUrl: "./project-invoice-path.component.html",
    styleUrls: ["./project-invoice-path.component.scss"]
})

export class ProjectInvoicePathSettingsComponent implements OnInit {
    showSpinner: boolean = false;
    @Input() projectSettings: any;
    path: string = "";
    ProjectInvoicePathObj: Settings;
    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private settingsService: SettingsService) {
        this.translationLoaderService.loadTranslations(danish, english);
    }

    ngOnInit() {
        if (this.projectSettings && this.projectSettings.length > 0) {
            var tempSettingObject = this.projectSettings.find(s => s.key1 == "Project" && s.key2 == "ProjectInvoicePath");
            if (tempSettingObject) {
                this.ProjectInvoicePathObj = tempSettingObject;
                this.path = tempSettingObject.value;
            }
        }
        else
            this.getSettingsForProjectEconomyDetails();
    }

    getSettingsForProjectEconomyDetails() {
        this.showSpinner = true;
        this.settingsService.GetSettingsValue<ResponseBase>("Project", "ProjectInvoicePath",null).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.result && response.result.myResponse.length > 0) {
                    this.ProjectInvoicePathObj = response.result.myResponse[0];
                    this.path = response.result.myResponse[0].value;
                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    changeSettingsForProjectInvoicePath() {
        this.showSpinner = true;
        this.ProjectInvoicePathObj.value = this.path;
        this.settingsService.UpdateSettingValue<ResponseBase>(this.ProjectInvoicePathObj).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.result) {
                    this.ProjectInvoicePathObj = response.result;
                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }
}