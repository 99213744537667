import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { NotificationsService } from 'angular2-notifications';
import { QualityTestService } from 'app/main/shared-component/quality-test/quality-test.service';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { OrderByStatus } from 'app/main/model/ProjectOffer';
import { RequestForQualityTestImagesFilter } from 'app/main/model/RequestBase';

@Component({
    selector: 'app-view-qualitytest-images',
    templateUrl: './view-qualitytest-images.component.html',
    styleUrls: ['./view-qualitytest-images.component.scss']
})
export class ViewQualityTestImagesComponent implements OnInit {
    qualityTestBeforeAfterImageList: any = [];
    showSpinner: boolean = false;
    moduleid: string = "";
    host: string;
    base64Image: any;
    searchValue: string = "";
    orderBy: string = 'Date DESC';
    sortStatus = OrderByStatus;
    requestModel: RequestForQualityTestImagesFilter = new RequestForQualityTestImagesFilter();
    constructor(public dialog: MatDialog,
        private translationLoader: FuseTranslationLoaderService,
        private notificationsService: NotificationsService,
        private qualityTestService: QualityTestService,
        private translate: TranslateService,
        private http: HttpClient) { this.translationLoader.loadTranslations(english, danish) }

    ngOnInit() {
        if (environment.url.endsWith("/"))
            this.host = environment.url.slice(0, -1);
        else
            this.host = environment.url;
        this.getQualityTestBeforeAfterImages();
    }

    getQualityTestBeforeAfterImages() {
        this.qualityTestBeforeAfterImageList = [];
        this.showSpinner = true;
        this.requestModel.orderBy = this.orderBy;
        this.requestModel.search = this.searchValue ? this.searchValue : "";
        this.qualityTestService.GetOffersBeforeAndAfterImages(this.requestModel).subscribe({
            next: (response) => {
                this.showSpinner = false;
                if (response.result) {
                    this.qualityTestBeforeAfterImageList = response.result.allImageList;
                }
            },
            error: err => {
                this.showSpinner = false;
                this.translate.get("MESSAGES.DropBoxApiNotResponding").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            }
        });
    }

    downloadImage(image) {
        var imageUrl = this.host + image.filePath;
        this.http.get(imageUrl, { responseType: "blob" }).subscribe((res: any) => {
            const url = window.URL.createObjectURL(res);
            var download = document.createElement("a");
            download.href = url;
            download.download = image.fileName;
            var event = document.createEvent("MouseEvents");
            event.initMouseEvent(
                "click", true, false, window, 0, 0, 0, 0, 0
                , false, false, false, false, 0, null
            );
            download.dispatchEvent(event);
        });
    }

    search() {
        this.getQualityTestBeforeAfterImages();
    }

}
