import { Component, OnInit, Inject, Optional, ViewChild, ElementRef } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { Supplier } from 'app/main/model/Supplier';
import { MaterialService } from '../../material.service';
import { QuickInvoiceMaterials } from 'app/main/model/Material';
import { NotificationsService } from 'angular2-notifications';
import { MaterialType } from "app/constant/common.constant";
import { CommonService } from "app/core/services/common.service";

@Component({
    selector: "add-order-material-item",
    templateUrl: "./add-order-material-item.component.html",
    styleUrls: ["./add-order-material-item.component.scss"]
})

export class AddOrderMaterialItemComponent implements OnInit {
    Suppliers: Supplier[] = [];
    materialList: [] = [];
    supplierId: string = "0";
    MaterialName: string = "";
    materialId: string = "";
    Materials: QuickInvoiceMaterials[] = [];
    MaterialsList: QuickInvoiceMaterials[] = [];
    showSpinner: boolean = false;
    SearchMaterial: string = "";
    ManuallAddMaterialId: string = "";
    OrderMaterialItemList = [];
    colorCode: string = "";
    materialType: any = 0;
    selectMaterialtype = MaterialType;
    isScanner: boolean = true;
    barcode: any = '';

    @ViewChild("barCode", { static: false }) barcodeFeild: ElementRef;

    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private translateService: TranslateService,
        private dialogRef: MatDialogRef<AddOrderMaterialItemComponent>,
        private materialService: MaterialService,
        private translate: TranslateService,
        private notificationsService: NotificationsService,
        private commonService: CommonService,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
        this.translationLoaderService.loadTranslations(danish, english);

    }

    ngOnInit() {
        this.Suppliers = this.data.Suppliers;
        //this.Materials = this.data.Materials;
        this.MaterialsList = this.data.Materials;
        this.supplierId = this.data.SupplierId ? this.data.SupplierId : 0
        this.getMaterialOnSupplier(this.supplierId);
    }

    ClearSearchMaterial(value) {
        if (!value) {
            this.DropDownSearchMaterial();
        }
    }

    DropDownSearchMaterial() {
        this.Materials = this.MaterialsList.filter(o => o.text.toLowerCase().includes(this.SearchMaterial.toLowerCase()) || o.itemNumber.toLowerCase().includes(this.SearchMaterial.toLowerCase()));
    }

    getMaterialOnSupplier(supplierId) {
        this.Materials = this.MaterialsList.filter(o => o.supplierId == supplierId);
        //this.MaterialsList = this.Materials;
        setTimeout(() => {
            this.setBarcodeFocus()
        }, 0);

        this.playFailedTone();
    }

    addOrderItems() {
        if (this.materialId) {
            var material = this.MaterialsList.find(o => o.id == this.materialId);
            if(!this.OrderMaterialItemList.find(x => x.id == this.materialId)) {
                if (material) {
                    material.colorCode = this.colorCode;
                    this.OrderMaterialItemList.push(Object.assign({}, material));
                    this.playSuccessTone();
                }
            }
            //this.Materials = this.Materials.filter(x => x.id != this.materialId);
            this.materialId = "";
            this.colorCode = "";
            //this.materialType = ""
            this.barcode = ""
            this.barcodeFeild.nativeElement.value = ''
        }
    }

    storeOrderItemToList() {
        if (this.OrderMaterialItemList.length > 0) {
            this.dialogRef.close(this.OrderMaterialItemList);
        }
    }

    delete(index, id) {
        this.OrderMaterialItemList.splice(index, 1);
        this.playFailedTone();
        var materialObj = this.MaterialsList.find(x => x.id == id);
        if (materialObj) {
            this.Materials.push(materialObj);
        }
    }

    _handleKeydown(event: KeyboardEvent) {
        if (event.keyCode === 13) {
            // do not propagate spaces to MatSelect, as this would select the currently active option
            event.stopPropagation();
        }
    }

    DropDownSelectMaterial() {

    }

    back() {
        this.dialogRef.close(false);
    }

    BarcodeAddMaterialToList(event) {
        setTimeout(() => {
        if (this.barcode) {
          var barcode = this.barcode.trim();
          var findMaterial = this.Materials.find(o => o.barcode == barcode || o.barcode2 == barcode);
          if (!findMaterial) {
            this.translate.get("MESSAGES.NoBarcodeAttachedToMaterial").subscribe({
              next: (res) => {
                this.notificationsService.error(res);
                this.playFailedTone();
              },
              error: err => {
              }
            });
          }
          else {
            try {
                this.materialType = parseInt(findMaterial.materialType.slice(0,findMaterial.materialType.indexOf(',')));
            } catch (error) {
                this.materialType = parseInt(findMaterial.materialType);
            }
            this.materialId = findMaterial.id
            this.colorCode = findMaterial.colorCode
            this.addOrderItems()
          }
          this.barcode = "";
        }
        }, 500);
    }

    setBarcodeFocus() {
        setTimeout(() => {
            if(this.barcodeFeild && this.barcodeFeild.nativeElement)
                this.barcodeFeild.nativeElement.focus();
        }, 100)
    }    
    onScannerFocus(){
        if(this.materialType != 0) {
            this.materialType = 0; 
            this.Materials = this.MaterialsList.filter(o => o.supplierId == this.supplierId);
            this.materialId = "";
            this.colorCode = "";
        }
    }
    playFailedTone() {
        this.commonService.playFailedTone();
    }
    playSuccessTone() {
        this.commonService.playSuccessTone();
    }

    materialTypeChange() {
        if(this.materialType == 0) {
            this.Materials = this.MaterialsList.filter(o => o.supplierId == this.supplierId);
        } else {
            if (this.materialType) {
                this.Materials = this.MaterialsList.filter(o => {
                    if(o.hasOwnProperty('materialType')) {
                        var matTypIds = o.materialType.split(',').map(el => {
                            let n = Number(el);
                            return n === 0 ? n : n || el;
                        });
                        return (o.supplierId == this.supplierId && matTypIds.includes(this.materialType))
                    }
                });
            }
        }
    }

}