import { Component, OnInit, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { MatDialog } from '@angular/material';
import { TextEditorComponent } from 'app/main/pages/text-editor/text-editor.component';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { InquiryCustomer } from 'app/main/model/Customer';
import { CompanyInfoSetting } from 'app/main/model/CompanyInfo';
import { TitleService } from 'app/core/services/title.service';
import { AuthService } from 'app/core/services/auth.service';
import { ProjectService } from 'app/main/pages/project/project.service';
import { InquiryService } from 'app/main/pages/inquiry/inquiry.service';
import { ApiConstant } from 'app/core/constant/ApiConstant';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-inquiry-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss'],
})

export class InquiryNoteComponent implements OnInit {
  // roleName: string = "";
  NoteDescription: string = "";
  showSpinner: boolean = false;
  inquiryObj = new InquiryCustomer();
  @Input() selectedInquiry: InquiryCustomer;
  @Output() Content = new EventEmitter<any>();
  constructor(private translationLoader: FuseTranslationLoaderService, private elem: ElementRef,
    private titleService: TitleService,
    private projectService: ProjectService,
    private inquiryService: InquiryService,
    public dialog: MatDialog,
    private translate: TranslateService,
    private notificationsService: NotificationsService,
    private authService: AuthService) {
    this.titleService.SetTitle("TITLE_INQUIRY");
    this.translationLoader.loadTranslations(english, danish);
  }

  ngOnInit() {
    this.inquiryObj = this.selectedInquiry;
    // this.roleName = this.authService.getUserRole();
    // if (this.roleName == "Admin") {
    // }
    // else if (this.roleName == "Kontor") {
    // }
    // else if (this.roleName == "Maler") {
    // }
    // else if (this.roleName == "Gaest") {
    // }
    // else {
    // }
    if (this.inquiryObj.projectId)
      this.GetDetailsFromProject();
    else
      this.GetDetailsFromInquiry();
  }

  openInquiryDescriptionForNote() {
    const dialogRef = this.dialog.open(TextEditorComponent, {
      height: '90%',
      width: '90%',
      data: {
        selectedTabData: { Description: this.NoteDescription }, isEmail: false
      },
      disableClose: true
    });


    dialogRef.afterClosed().subscribe(result => {
      this.NoteDescription = "";
      if (result && result.Description) {
        this.NoteDescription = result.Description;
      }
      if (this.inquiryObj.projectId)
        this.UpdateDetailsFromProject();
      else
        this.UpdateDetailsFromInquiry();
    });
  }

  GetDetailsFromInquiry() {
    this.showSpinner = true;
    this.inquiryService.GetNoteDetail<any>(this.inquiryObj.id).subscribe({
      next: (response) => {
        this.showSpinner = false;
        this.NoteDescription = response.result.content;
      },
      error: err => {
        this.translate.get("MESSAGES.Failed").subscribe({
          next: (res) => {
            this.notificationsService.error(res);
          },
          error: err => {
          }
        });
      }
    });
  }

  UpdateDetailsFromInquiry() {
    this.showSpinner = true;
    var data = new CompanyInfoSetting();
    data.Description = this.NoteDescription;

    this.inquiryService.UpdateDescription<ResponseBase>(this.inquiryObj.id, this.NoteDescription)
      .subscribe({
        next: (response: ResponseBase) => {
          this.showSpinner = false;
          //this.NoteDescription = response.result.description;
          this.Content.emit(this.NoteDescription);
        },
        error: err => {
          this.showSpinner = false;
          this.translate.get("MESSAGES.Failed").subscribe({
            next: (res) => {
              this.notificationsService.error(res);
            },
            error: err => {
            }
          });
        },
        complete: () => {
          this.showSpinner = false;
        }
      });
  }

  GetDetailsFromProject() {
    this.showSpinner = true;
    this.projectService.GetProjectRequisition<any>(this.inquiryObj.projectId, ApiConstant.RequisitionNote).subscribe({
      next: (response) => {
        this.showSpinner = false;
        this.NoteDescription = response.result.description;
      },
      error: err => {
        this.translate.get("MESSAGES.Failed").subscribe({
          next: (res) => {
            this.notificationsService.error(res);
          },
          error: err => {
          }
        });
      }
    });
  }

  UpdateDetailsFromProject() {
    this.showSpinner = true;
    var data = new CompanyInfoSetting();
    data.Description = this.NoteDescription;

    this.projectService.UpdateRequisitionExtraTabDescription<ResponseBase>(this.inquiryObj.projectId, ApiConstant.RequisitionNote, data)
      .subscribe({
        next: (response: ResponseBase) => {
          this.showSpinner = false;
          this.NoteDescription = response.result.description;
          this.Content.emit(this.NoteDescription);
        },
        error: err => {
          this.showSpinner = false;
          this.translate.get("MESSAGES.Failed").subscribe({
            next: (res) => {
              this.notificationsService.error(res);
            },
            error: err => {
            }
          });
        },
        complete: () => {
          this.showSpinner = false;
        }
      });
  }
}





