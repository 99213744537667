export const locale = {
    lang: 'ds',
    data: {
        'MESSAGES': {
            //'Created': 'Created successfully',
            'EmailSent': 'Email sendt med succes',
            'Failed': 'Operation mislykket, prøv senere',
            'ItemNumberExist': 'Nummeret eksisterer allerede',
            'ItemNotSelected': 'Vælg mindst ét element',
            'InformForYellow': 'Gul markering betyder vare er bestilt',
        },
        'FIELDS': {
            'Material': 'Materiale',
            'RetailPrice': 'Udsalgs pris',
            'PersonalPrice': 'Personal Pris',
            'PersonalTotalPrice': 'Personal i alt',
            'Quantity': 'Antal',
            'ProductNo': 'Vare',
            'OrderQuantity': 'Bestille Antal',
            'MinQuantity': 'Min Antal',
            'MaxQuantity': 'Max Antal',
            'Unit': 'Enhed',
            'PurchasePrice': 'Indkøbs Pris',
            'TotalPurchasePrice': 'I alt Indkøbs Pris',
            'Total': 'I alt',
            'Inventory': 'Beholding',
            'MinInventory': 'Min Beholding',
            'MaxInventory': 'Max Beholding',
            'PurchaseQuantity': 'Indkoebs maengde',
            'ShowAll': 'Vis alle',
            "WeatherType": "Vejrtype",
        },
        'BUTTONS': {
            'IncludeInactive': 'Inkluder Inaktiv',
            'ShowActive': 'Vis Aktiv',
            'AddNewItem': 'Eks. Vare',
            'Project': 'Projekt',
            'AddNewMaterial': 'Ny Vare',
            'OpenInPDFViewer': 'Åbn PDF',
            'SendEmail':'Send E-mail',
        }
    }
};

