export const locale = {
    lang: 'en',
    data: {
        'STATUS': {
            'Created': 'Created',
            'Updated': 'Updated',
            'Deleted': 'Deleted',
            'FileUploaded': 'File Uploaded',
            'FileDeleted': 'File Deleted',
            'Email': 'Email',
        },
        'TITLE': {
            'CustomerPagination': 'Antal log pr. side',
        },
        'BUTTONS': {
            'BACK': 'Cancel',
            'BTNSAVE': 'Save',
        },
        'FIELDS': {
            'SRNO': 'Sr No',
            'Status': 'Status',
            'Number': 'Number',
            'Name': 'Name',
            'Createdby': 'Created By',
            'CreatedDate': 'Created Date',
            'ModifyBy': 'Modify By',
            'ModifyDate': 'Modify Date',
            'CoworkerName': 'Coworker',
            'Date': 'Date',
            'Description': 'Description',
            'Action': 'Action',
        },
        'Headers': {
        },

        'MESSAGES': {
            'NORECORD': 'No Record Found',
            'COWORKERCREATED': '{@CoworkerName} has created new record for {@CustomerName}.',
            'COWORKERUPDATED': '{@CoworkerName} has updated record for {@CustomerName}.',
            'COWORKERDELETED': '{@CoworkerName} has deleted record for {@CustomerName}.',
            'FILEUPLOADED': '{@CoworkerName} has uploaded \'{@FileName}\' file for {@CustomerName}.',
            'FILEDELETED': '{@CoworkerName} has removed \'{@FileName}\' file for {@CustomerName}.',
        },
        'TOOTIPORTITLE':{
            'CustomerDetails':'View Customer Details',
            'ViewEmailTemplate':'View Email Template',
        }

    }
};


