import { Injectable } from '@angular/core';
import { RequestFile } from './../../model/RequestBase';
import { Observable } from 'rxjs';
import { HttpParams, HttpClient } from '@angular/common/http';
import { ApiConstant } from './../../../core/constant/ApiConstant';
import { CreateFolder, SearchFolder, ListFolder, DeleteBatchFile, SearchFile, MoveFolder, MoveFolderBatch, CheckFolderExist, DeleteFolder, SharedLinkDTO, SearchFolderOrFiles } from './../../model/DropBox';
import { Settings } from '../../model/Settings';
@Injectable({
    providedIn: 'root'
})
export class DocumentService {

    actionUrl: string;
    private apiUrl: string;


    constructor(private http: HttpClient) {
        this.actionUrl = 'https://api.dropboxapi.com/';
        this.apiUrl = ApiConstant.API_URL + "Documents/";
    }

    public Get<T>(RequestFile: any): Observable<T> {
        return this.http.post<T>(this.actionUrl + '2/files/list_folder', RequestFile);
    }

    public GetShowOnlyAdminFolderName<T>(key): Observable<T> {
        return this.http.get<T>(ApiConstant.API_URL + ApiConstant.ACCOUNT + '/SettingValue/' + key);
    }

    public UpdateSettingValuue<T>(id: string, settings: Settings): Observable<T> {
        return this.http.put<T>(ApiConstant.API_URL + ApiConstant.ACCOUNT + '/SettingValue/' + id, settings);
    }

    public SearchFolderDrpBox<T>(searchFolder: SearchFolder): Observable<T> {
        return this.http.post<T>(ApiConstant.DrpBoxSearch, searchFolder);
    }

    public CreateFolderDrpBox<T>(createFolder: CreateFolder): Observable<T> {
        return this.http.post<T>(ApiConstant.DrpBoxCreate, createFolder);
    }

    public MoveFolderBatchDrpBox<T>(moveFolderBatch: MoveFolderBatch): Observable<T> {
        return this.http.post<T>(ApiConstant.MoveFolderBatchDrpBox, moveFolderBatch);
    }

    public SearchFileDrpBox<T>(searchFile: SearchFile): Observable<T> {
        var api = "https://api.dropboxapi.com/2/files/search";
        return this.http.post<T>(api, searchFile);
    }

    public ListFolderDrpBox<T>(listFolder: ListFolder): Observable<T> {
        return this.http.post<T>(ApiConstant.DrpBoxList, listFolder);
    }

    public DeleteBatchFileDrpBox<T>(deleteBatchFile: DeleteBatchFile): Observable<T> {
        return this.http.post<T>(ApiConstant.DrpBoxDelete, deleteBatchFile);
    }

    public GetDrpBoxSharedLink<T>(listFolder: ListFolder): Observable<T> {
        return this.http.post<T>(ApiConstant.DrpBoxSharedLink, listFolder);
    }

    public GetParams(param) {
        let params: HttpParams = new HttpParams();
        for (var key in param) {
            if (param.hasOwnProperty(key)) {
                let val = param[key];
                params = params.append(key, val);
            }
        }
        return params;
    }

    //New DropdownFiles API calls
    // Post 
    public CreateFolder<T>(createFolder: CreateFolder): Observable<T> {
        return this.http.post<T>(this.apiUrl + "CreateFolder", createFolder);
    }

    public UploadFiles(formData: FormData) {
        return this.http.post(this.apiUrl + "UploadFiles", formData, { headers: { IsContentType: "true" } });
    }

    // Get
    public GetListFolderDrpBox<T>(listFolder: ListFolder): Observable<T> {
        const obj = this.GetParams(listFolder);
        return this.http.get<T>(this.apiUrl + "ReadFolder", {
            params: obj
        });
    }

    public CheckFolderExist<T>(checkFolderExist: CheckFolderExist): Observable<T> {
        const obj = this.GetParams(checkFolderExist);
        return this.http.post<T>(this.apiUrl + "CheckFolderExist", {
            params: obj
        });
    }

    public GetSharedLink<T>(sharedLinkDTO: SharedLinkDTO): Observable<T> {
        const obj = this.GetParams(sharedLinkDTO);
        return this.http.post<T>(this.apiUrl + "GetSharedLink", {
            params: obj
        });
    }

    public GetSearchFileOrFolder(searchFolderOrFiles: SearchFolderOrFiles) {
        const obj = this.GetParams(searchFolderOrFiles);
        return this.http.post(this.apiUrl + "SearchFileOrFolder", {
            params: obj
        });
    }

    // Delete
    public DeleteFolderOrFile<T>(deleteFolder: DeleteFolder): Observable<T> {
        return this.http.post<T>(this.apiUrl + "DeleteFolderOrFile", deleteFolder);
    }
    //End
}
