export const locale = {
  lang: 'ds',
  data: {
    'Tabs': {
      'Offer': 'Tilbud',
      'BasicDetail': 'Grundlæggende detaljer',
      'File': 'Fil',
      'Category': 'Kategori',
      'WorkItem': 'Arbejdspost'
    },
    'TITLE': {
      'CustomerPagination': 'Antal log pr. side',
    },
    'MESSAGE': {
      'Failed': 'Operation mislykket, prøv senere',
      'Success': 'Succes.',
      'CommingSoon': 'Kommer snart..',
      'Deleted': 'Slettet med succes',
      'Added': 'Oprettet med succes',
      'Updated': 'Opdateret med succes',
      'NORECORD': 'Ingen defekte værelser fundet',
    },
    'FIELDS':{
      'GoTo':'Gå til',
      'Moisture':'Fugt %',
      'Temperature':'Temperature ℃',
      'SRNO': 'Sr Nr',
      'Status': 'Status',
      'Number': 'Number',
      'Name': 'Name',
      'Createdby': 'Created By',
      'CreatedDate': 'Created Date',
      'ModifyBy': 'Modify By',
      'ModifyDate': 'Modify Date',
      'CoworkerName': 'Coworker',
      'Date': 'Dato',
      'Description': 'Description',
      'Action': 'Action',
      'ModuleName': 'Module',
      'ByCoworker': 'by medarbejder',
      'ByCustomer': 'by customer',
      'All': 'Alle',
      'RoomName': 'Rum Navn',
      'Text': 'Tekst',
      'SelectRoom':'Vælg Rum',
      'DragAndDropFile': 'Træk og slip filen her',
      'Or': 'Eller',
      'BrowseForFile': 'Søg efter fil',
      
    },
    'BUTTONS': {
      'Add': 'Tilføj'
    },
    'Headers': {
      'DeleteDefectRoom': 'Vil du slette defekt værelse',
      'UploadFile': 'Upload Fil',
      'ChooseFile': 'Vælg fil',
      'ChoosePicture': 'Vælg billede',
    },
    'VALIDATIONS': {
      'Rooms': 'Vælg mindst ét ​​rum',
      'Text': 'Tekst er påkrævet'
    }
  }
};
