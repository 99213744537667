import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompanyInfoSettingsComponent } from './company-info-settings.component';
import { SharedModule } from 'app/core/modules/shared.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        DragDropModule
    ],
    entryComponents: [CompanyInfoSettingsComponent],
    declarations: [CompanyInfoSettingsComponent],
    exports: [CompanyInfoSettingsComponent, SharedModule]
})
export class CompanyInfoSettingsModule { }
