import { Injectable, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { MatBadgeModule } from '@angular/material/badge';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// app-core
import { CustomCurrencyMaskConfig } from "app/core/constant/CurrencyMaskConfiguartion";

// services
import { QualityTestService } from './quality-test.service';

// components
import { QualityTestComponent } from './quality-test.component';
import { CategoryQuestionComponent } from './components/category-question/category-question.component';
import { ImageGalleryListComponent } from './components/image-gallery-list/image-gallery-list.component';
import { ImageGalleryComponent } from './components/image-gallery-list/components/image-gallery/image-gallery.component';
import { OfferQualityTestColorCodeComponent } from './components/color-code/color-code.component';
import { HumidityTemperatureComponent } from './components/humidity-temperature/humidity-temperature.component';
import { EditQualityCategoryComponent } from './components/edit-quality-category/edit-quality-category.component';
import { DefectRoomComponent } from './components/defect-room/defect-room.component';
import { AddQuestionInformationComponent } from './components/add-question-information/add-question-information.component';
import { ImageGalleryPopupComponent } from './components/image-gallery-list/components/image-gallery-popup/image-gallery-popup.component';

// packages
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '@fuse/material.module';
import { NgxMaskModule } from 'ngx-mask';
import { QuillEditorModule } from 'ngx-quill-editor';
import { NgxCurrencyModule } from "ngx-currency";
import { CKEditorModule } from 'ng2-ckeditor';
import { BarRatingModule } from "ngx-bar-rating";
import { NgxGalleryModule } from 'ngx-gallery';
import 'hammerjs';

// modules
import { PaginationModule } from 'app/main/pages/pagination/pagination.module';
import { NewQuestionsModule } from '../new-questions/new-questions.module';
import { CustomerProjectColorCodeModule } from '../customer-project-colorcode/customer-project-colorcode.module';
import { EditImageModule } from 'app/main/pages/edit-image/edit-image.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FusePipesModule } from '@fuse/pipes/pipes.module';
import { FilterDropdownModule } from 'app/main/pages/filter-dropdown/filter-dropdown.module';

@Injectable()
export class CustomHammerConfig extends HammerGestureConfig {
    overrides = {
        pinch: { enable: false },
        rotate: { enable: false }
    };
}

@NgModule({
    declarations: [
        QualityTestComponent,
        CategoryQuestionComponent,
        ImageGalleryListComponent,
        ImageGalleryComponent,
        OfferQualityTestColorCodeComponent,
        HumidityTemperatureComponent,
        EditQualityCategoryComponent,
        DefectRoomComponent,
        AddQuestionInformationComponent,
        ImageGalleryPopupComponent
    ],
    imports: [
        CommonModule,
        MaterialModule,
        FormsModule,
        NgxMaskModule.forRoot(),
        QuillEditorModule,
        NgxCurrencyModule.forRoot(CustomCurrencyMaskConfig),
        NgxSliderModule,
        CKEditorModule,
        BarRatingModule,
        MatBadgeModule,
        NgxGalleryModule,
        TranslateModule,
        PaginationModule,
        NewQuestionsModule,
        CustomerProjectColorCodeModule,
        EditImageModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        FusePipesModule,
        FilterDropdownModule
    ],
    exports: [
        QualityTestComponent,
    ],
    entryComponents : [
        ImageGalleryListComponent,
        ImageGalleryComponent,
        OfferQualityTestColorCodeComponent,
        HumidityTemperatureComponent,
        EditQualityCategoryComponent,
        DefectRoomComponent,
        AddQuestionInformationComponent,
        ImageGalleryPopupComponent
    ],
    providers: [
        DatePipe,
        QualityTestService,
        { provide: HAMMER_GESTURE_CONFIG, useClass: CustomHammerConfig }
    ]
})
export class QualityTestSharedModule { }
