import { Component, OnInit, ViewChild, NgZone, Inject, Optional } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';

import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';

import { ResponseBase } from 'app/main/model/ResponseBase';
import { RequestCoWorker, RequestReminder } from 'app/main/model/RequestBase';
import { AuthService } from 'app/core/services/auth.service';
import { ReminderService } from './../../reminder.service';
import { CoWorker } from 'app/main/model/CoWorker';
import { Reminder, ReminderFull } from 'app/main/model/Reminder';
import { MatDialog, DateAdapter, MatInput, MatOption,  MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ReminderRepeatModes } from 'app/main/model/Reminder';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { CoWorkersService } from '../../../coWorker/coWorkers.service';
import { TitleService } from 'app/core/services/title.service';
import { SignalRService } from 'app/core/services/signalR.service';
import { Location } from '@angular/common';
import { SelectProjectComponent } from '../selectProject/selectProject.Component';
import { SettingsService } from 'app/main/pages/settings/settings.service';
import { RequestCommonBase } from "app/main/model/RequestBase";
import { CoworkerReviewQuestionsComponent } from 'app/main/pages/settings/component/coworker-review-questions/coworker-review-questions.component';


@Component({
    selector: 'app-add-reminder',
    templateUrl: './add-reminder.component.html',
    styleUrls: ['./add-reminder.component.scss'],
})
export class VibeAddReminderComponent implements OnInit {

    coWokers: CoWorker[] = [];
    ReminderObject: ReminderFull;
    reminderForm: FormGroup;
    reminderForm2: FormGroup;
    today: Date = new Date();
    Reminders: Reminder[] = [];
    DashboardReminders: Reminder[] = [];
    reminderDate: any = '';
    ShowReminderForm: boolean = false;
    reminderRepeatModes = ReminderRepeatModes;
    @ViewChild('input', { read: MatInput, static: true }) input: MatInput;
    @ViewChild('allSelected', { static: true }) private allSelected: MatOption;
    searchUserForm: FormGroup;
    notDoneReminderCount: number = 0;
    IsGettingCoWokers: boolean = false;
    selectedFiles: any[]=[];
    showSpinner: boolean = false;
    requestBase: RequestCommonBase;
    reviewQuestionsList:any = [];

    constructor(private translationLoader: FuseTranslationLoaderService,
        private coWorkersService: CoWorkersService,
        private _router: Router,
        private formBuilder: FormBuilder,
        private authService: AuthService,
        private reminderService: ReminderService,
        private settingsService: SettingsService,
        private datePipe: DatePipe,
        public dialog: MatDialog,
        private titleService: TitleService,
        private adapter: DateAdapter<any>,
        private translate: TranslateService,
        private notificationsService: NotificationsService,
        private location: Location,
        private signalRService: SignalRService,
        private _ngZone: NgZone,
        @Optional() public dialogRef: MatDialogRef<VibeAddReminderComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.titleService.SetTitle("TITLE_REMINDERS");
        this.translationLoader.loadTranslations(english, danish);

    }
    ngOnInit() {
        this.ReminderObject = new ReminderFull();
        // var coworkerOBJ = new RequestCoWorker();
        // coworkerOBJ.Name = "";
        // this.getCoWorkers(coworkerOBJ);
        this.validation();
        this.adapter.setLocale('da');

        this.searchUserForm = this.formBuilder.group({
            userType: new FormControl(''),
            searchName: [null, !Validators.required],
        });
        var requestReminder = new RequestReminder();
        requestReminder.ReminderDate = new Date().toString();
        this.getDeshboardReminder(requestReminder);
        this.ReminderObject.reminderDate = this.datePipe.transform(new Date(), "yyyy-MM-dd");
        this.ReminderObject.reminderTime = this.datePipe.transform(new Date(), "HH:mm");

        this.reminderForm.controls['CoworkerId'].valueChanges.subscribe(selectedList => {
            this.ReminderObject.coWorkerId = Object.assign([], selectedList);
        });
        this.getCoworkerReviewQuestions();
    }

    OnuploadFile(event) {
        if (event.target.files.length > 0) 
            this.selectedFiles = event.target.files;           
    }

    validation() {
        this.reminderForm = this.formBuilder.group({
            Text: [null, Validators.required],
            CoworkerId: [null, Validators.required],
            Date: [null, Validators.required],
            Time: [null, Validators.required],
            projectId: [null],
            projectNumber: [null],
            projectAddr: [null],
            reviewQuestion: [null],
        });

        this.reminderForm2 = this.formBuilder.group({
            ReminderEndDate: [null, Validators.required],
            ReminderEndTime: [null, Validators.required],
        })
    }

    // getCoWorkers(model: RequestCoWorker) {
    //     model.OrderBy = "Name";
    //     this.IsGettingCoWokers = true;
    //     this.coWorkersService.Get<ResponseBase>(model)
    //         .subscribe((response: ResponseBase) => {
    //             if (response) {
    //                 this.coWokers = response.result.filter(x => x.doNotShowOnPlanReminderProj == false);
    //                 this.getNextRequest(response.pagination, model, response.result);
    //             }
    //             else {
    //                 this.coWokers = [];
    //                 this.IsGettingCoWokers = false;
    //             }
    //         },
    //             error => (err) => {
    //                 this.coWokers = [];
    //                 this.IsGettingCoWokers = false;
    //             },
    //             () => {

    //             });
    // }

    // getNextRequest(pagination, model, response) {
    //     if (pagination.index == 1) {
    //         this.coWokers = response.filter(x => x.doNotShowOnPlanReminderProj == false);
    //         this.IsGettingCoWokers = false;
    //     }
    //     else {
    //         response = response.filter(x => x.doNotShowOnPlanReminderProj == false);
    //         this.coWokers.push(...response);
    //         this.IsGettingCoWokers = false;
    //     }
    //     model.Index = pagination.index + 1;
    //     model.Limit = pagination.limit;
    //     model.TotalRows = pagination.totalCount;
    //     if (pagination.totalCount > pagination.index * pagination.limit)
    //         this.getCoWorkers(model);
    // }

    Add() {
        this.ReminderObject.projectId = this.reminderForm.get("projectId").value;
        this.ReminderObject.projectNumber = this.reminderForm.get("projectNumber").value;
        this.ReminderObject.reminderDate = this.datePipe.transform(this.ReminderObject.reminderDate, "yyyy-MM-dd")
        this.ReminderObject.reminderEndDate = this.datePipe.transform(this.ReminderObject.reminderEndDate, "yyyy-MM-dd");
        if (this.ReminderObject.reminderTime)
            this.ReminderObject.reminderDate = moment.utc(this.ReminderObject.reminderDate + " " + this.ReminderObject.reminderTime).format("YYYY-MM-DD HH:mm");
        if (this.ReminderObject.reminderEndTime)
            this.ReminderObject.reminderEndDate = moment.utc(this.ReminderObject.reminderEndDate + " " + this.ReminderObject.reminderEndTime).format("YYYY-MM-DD HH:mm");

        if(!this.ReminderObject.reminderEndDate)
            this.ReminderObject.reminderEndDate = "";
        if (!this.ReminderObject.projectNumber)
            this.ReminderObject.projectNumber = "";
        if (!this.ReminderObject.projectId)
            this.ReminderObject.projectId = "";
        const formData = new FormData();
        if (this.selectedFiles && this.selectedFiles.length) {            
            for (var i = 0; i < this.selectedFiles.length; i++) {
                formData.append("file[]", this.selectedFiles[i]);
            }
        }
        for ( var key in this.ReminderObject ) {
            formData.append(key, this.ReminderObject[key]);
        }

        this.showSpinner = true;
        this.reminderService.CreateReminderWithFiles<ResponseBase>(formData)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.showSpinner = false;
                    this.ReminderObject = new ReminderFull();
                    this.validation();
                    this.Reminders.unshift(...response.result.myResponse);
                    this.ShowReminderForm = false;
                    this.translate.get("MESSAGES.Add").subscribe({
                        next: (res) => {
                            this.notificationsService.success(res);
                        },
                        error: err => {
                        }
                    });
                    this.GetNotDoneReminderCounts();
                    this.sendMessage("");
                    if (this.data && this.data.isRedirectToMaterial) {
                        this.dialogRef.close(this.ReminderObject);
                        return;
                    }
                    else
                        this.location.back();
                },
                error: err => {
                    this.showSpinner = false;
                },
                complete: () => {
                }
            });
    }

    GetNotDoneReminderCounts() {
        this.reminderService.GetNotDoneReminderCountApi<ResponseBase>(this.authService.getCoWorkerId())
            .subscribe({
                next: (response: ResponseBase) => {
                    if (response != null) {
                        this.notDoneReminderCount = response.result;
                        //this.reminderService.setNotDoneReminderCount(response.result);
                        this.authService.setNotDoneReminderCount(this.notDoneReminderCount);
                    }
                },
                error: err => {
                },
                complete: (
                ) => {
                }
            });
    }

    getDeshboardReminder(model: RequestReminder) {
        model.ReminderDate = this.datePipe.transform(model.ReminderDate, "yyyy-MM-dd");
        model.CoworkerId = this.authService.getCoWorkerId();
        model.IsDeleted = false;
        model.Status = false;
        this.reminderService.Get<ResponseBase>(model)
            .subscribe({
                next: (response: ResponseBase) => {
                    if (response != null) {
                        this.DashboardReminders = response.result.length;
                        this.authService.setReminderCount(response.result.length);
                    }
                    else {
                        this.DashboardReminders = [];
                        //this.reminderService.totalReminderCount(this.DashboardReminders);
                        this.authService.setReminderCount(0);
                    }
                },
                error: err => {
                    this.Reminders = [];
                },
                complete: (
                ) => {
                }
            })
    }

    // DropDownSearchCoWorker(value) {
    //     if (value != null && value != "") {
    //         var request = new RequestCoWorker();
    //         request.Name = value;
    //         this.getCoWorkers(request);
    //     } else {
    //         let coWorker = new RequestCoWorker();
    //         coWorker.IsDeleted = false;
    //         this.getCoWorkers(coWorker);
    //     }
    // }

    ChangeOnRepeatCheckbox(isRepeat) {
        this.ReminderObject.isRepeat = isRepeat;
        if (this.ReminderObject.isRepeat)
            this.ReminderObject.reminderEndTime = this.datePipe.transform(new Date(), "HH:mm");
        else
            this.ReminderObject.reminderEndTime = "";
    }

    Cancel() {
        this.selectedFiles = []
        if (this.data && this.data.isRedirectToMaterial) {
            this.dialogRef.close("");
        }
        else
            this.location.back();
    }

    getSuccessTone() {
        let audio = new Audio();
        audio.src = "../../../../../../assets/soundEffect/ClappingCrowd.mp3";
        audio.load();
        audio.play();
    }

    ChangeImage(event) {
        this.ReminderObject.image = event;
    }

    sendMessage(data: any): void {
        this.signalRService.sendMessage(data);
    }
    private subscribeToEvents(): void {
      this.signalRService.messageReceived.subscribe((data: any) => {
        this._ngZone.run(() => {
        });
      });
    }

    SelectProject() {
        var dialogRef = this.dialog.open(SelectProjectComponent, {
            height: '90%',
            width: '90%',
            disableClose: false,
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.reminderForm.get("projectId").patchValue(result['ProjectID']);
                this.reminderForm.get("projectNumber").patchValue(result['ProjectNumber']);
                this.reminderForm.get("projectAddr").patchValue(result['ProjectAddr']);
            }
        });
    }

    CreateCustomerReviewQuestion() {
        var dialogRef = this.dialog.open(CoworkerReviewQuestionsComponent, {
            height: '90%',
            width: '90%',
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.getCoworkerReviewQuestions();
            }
        });
    }

    getCoworkerReviewQuestions() {
        this.requestBase = new RequestCommonBase();
        this.requestBase.Index = 1;
        this.requestBase.Limit = 100;
        this.requestBase.OrderBy = 'date';
        this.showSpinner = true;
        this.settingsService.GetCoworkerReviewQuestionList<ResponseBase>(this.requestBase).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.result) {
                     //this.reviewQuestionsList = response.result;
                    // this.pagination = response.pagination;
                       const objData = response.result;
                       const today =  moment(new Date()).format("YYYY-MM-DD")
                       this.reviewQuestionsList =  objData.filter(function(item) {
                        return item.endDate >= today
                      });
                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }
}