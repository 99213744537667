export const locale = {
    lang: 'en',
    data: {
        'TITLE': {
            'HeaderForBirthdayNotification': 'Birthday Notification',

            'BirthdaySMSTextCoworker':'Please do not remove @CoworkerName from SMS text.',
            'BirthdayNotiFicationTextCoworker':'Please do not remove @CoworkerName from Notification text.',

            'BirthdaySMSTextOtherCoworker':'Please do not remove @CoworkerNames from SMS text.',
            'BirthdayNotiFicationTextOtherCoworker':'Please do not remove @CoworkerNames from Notification text.',

            'BirthdaySMSTextOthersBeforeXDays':'Please do not remove @BirthDate and @CoworkerNames from SMS text.',
            'BirthdayNotiFicationTextOthersBeforeXDays':'Please do not remove @BirthDate and @CoworkerNames from Notification text.',
        },
        'FIELDS': {
            'IsCreateReminder':'Is Create Reminder?',
            'DaysToSend':  'Send x Days Before',
            'SMSText': 'SMS Text',
            'NotificationText': 'Notification Text',
            'NotificationTitle': 'Notification Title',
            'EmailText': 'Email Text',
            'ExecuteTime':'Execute Time',

            'TemplatesForCoworker':'Templates For Coworker',
            'TemplatesForOthers':'Templates For Other On Same Day',
            'TemplatesForOthersBeforeXDays':'Templates For Others Before X Days',

            'EmailTemplateForCoworker':'Email Template For Coworker',
            'EmailTemplateForOthers':'Email Template For Other On Same Day',
            'EmailTemplateForOthersBeforeXDays':'Email Template For Others Before X Days',

            // 'NoteLabelForCoworker2':'Please do not remove @CoworkerName from SMS,Notification and Email template text.',
            // 'NoteLabelForOthers2':'Please do not remove @CoworkerNames from SMS,Notification and Email template text.',
            // 'NoteLabelForOthersBeforeXDays2':'Please do not remove @BirthDate and @CoworkerNames from SMS,Notification and Email template text.'
        },
        'VALIDATIONS': {
            'DaysToSend': 'Days to send is required',
            'SMSText': 'SMS text is required',
            'NotificationText': 'Notification text is required',
            'NotificationTitle': 'Notification title is required',
            'EmailText': 'Email text is required',
            'ExecuteTime':'Execution time is required'
        },
        'MESSAGES': {
            'Saved': 'Saved successfully.',
        },
        'BUTTONS': {
            'Save': 'Save',
        }
    }
};