import { CurrencyMaskInputMode } from "ngx-currency";
export const CustomCurrencyMaskConfig = {
    align: "left",
    allowNegative: true,
    allowZero: true,
    decimal: ",",
    precision: 2,
    prefix: "",
    suffix: "",
    thousands: ".",
    nullable: true,
    inputMode: CurrencyMaskInputMode.NATURAL
};