export const locale = {
    lang: 'en',
    data: {
        'TITLE': {
            'EmailTemplate':'Email template for all module'
        },
        'FIELDS': {
        },
        'Message': {

        },
        'FILTERS': {
        },
        'moduleTypeList': {
        },
        'BUTTONS': {
            'Save':'Save',
        }
    }
};