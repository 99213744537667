import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CustomersService } from "../../customer.service"
import { CustomerRoom } from 'app/main/model/ProjectOffer';
import { RequestProjectCustomerRoom } from 'app/main/model/RequestBase';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { NotificationsService } from 'angular2-notifications';
import { CommonService } from 'app/core/services/common.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'vibe-customer-offer',
    templateUrl: './customer-offer.component.html',
})
export class CustomerOfferComponent implements OnInit {
    @Input() PropertyInput: any;
    @Output() GoToBack: EventEmitter<boolean> = new EventEmitter();

    showSpinner = false;
    CustomerRooms: CustomerRoom[] = [];
    requestProjectCustomerRoom: RequestProjectCustomerRoom = new RequestProjectCustomerRoom();

    //Initiators
    constructor(
        private customersService: CustomersService,
        private notificationsService: NotificationsService,
        private commonService: CommonService,
        private translate: TranslateService,
    ) {
    }

    ngOnInit() {        
        if(this.PropertyInput) {
            this.GetCustomerRoomList(true)
        }
    }

    GetCustomerRoomList(evt) {
        if(evt) {
            this.showSpinner = true;
            this.requestProjectCustomerRoom.Limit = 100;
            this.requestProjectCustomerRoom.CustomerPropertyID = this.PropertyInput;
            this.customersService.GetCustomerPropertyRooms<ResponseBase>(this.requestProjectCustomerRoom)
                .subscribe((response: ResponseBase) => {
                    this.showSpinner = false;
                    this.CustomerRooms = []
                    if (response != null) {
                        this.CustomerRooms = response.result;
                    }
                },
                error => (err) => {
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });
                });
        }
    }
}
