export const locale = {
  lang: 'ds',
  data: {
    'TABS': {
      'MATERIAL': 'Materialer',
      'CUSTOMER': 'Kunder',
      'PROJECT': 'Projekter',
      'COWORKER': 'Medarbejdere',
      'PRICE': 'Prisskilte',
      'OFFER': 'Tilbud',
    },
    'Message': {
      'ComingSoon': 'Kommer snart',
    },
  }
};
