export const locale = {
  lang: 'en',
  data: {
      'MESSAGES': {
          'Created': 'Created successfully',
          'Updated': 'Updated successfully',
          'Deleted': 'Deleted successfully',
          'Failed': 'Operation failed , try later',
          'NoBarcodeAttachedToMaterial': 'No barcode attached to any material.',
          'EnterTextColorCode': 'Enter Text',
          'MaterialPriseMoreOne': 'Enter material price',
          'NoMaterialFound': 'No material found',
          'AddColorCode': 'Color code needs to be filled out.',
          'MaterialAlreadyExists': 'Material already added.',
          'Copy': 'Material Copy Successfully',
          'Cancel': 'Material Not copy'
      },
      'BUTTONS': {
          'NEWCOWORKER': 'New Co-worker',
          'BACK': 'Back',
          'AddScanMaterial': 'Add scan material',
          'SAVE': 'Save',
          'BtnAdd': 'Add',
          'ViewColorCode': 'View ColorCode',
          'CopyMaterial':'Copy Material'
      },
      'FIELDS': {
          'Status': 'Status',
          'MaterialTextColor': 'Text',
          'MaterialNo': 'Number',
          'Material': 'Material',
          'MaterialText': 'Material Text',
          'DateonCreate': 'Date',
          'Quantity': 'Quantity',
          'Unit': 'Unit',
          'RetailPrice': 'Retail Price pr. pcs',
          'TotalPrice': 'Total Price',
          'PersonalPrice': 'Personal Price',
          'PersonalTotalPrice': 'Personal Total',
          'TotalIncludedVAT': 'Offer Price incl. VAT',
          'Estimate': 'Estimate Price',
          'Price-included': 'Price incl. VAT',
          'ProfitSubProfit': 'Profit / Sub-Profit',
          'ScanBarcode': 'Scan barcode',
          'ColorCode': 'Color code',
          'PriceExclVAT': 'Price excl vat',
          'Discount': 'Discount',
          'Total': 'Total',
          'DonePercentage': 'Done %',
          'DiscountLable': 'Discount / Debtor Discount',
          'Amount': 'Amount',
          'Percentage': 'Percentage',
          'PurchasePrice': 'Purchase Price',
          'RemainsTakenFromBasements': 'Remains / taken from basements',
          'MaterialCreation': 'Material Creation',
          'All': 'Alle',
          "Materialtype": 'Material type',
          'Vat':'Excl. VAT',
          'Materials': 'Materialer',
      },
      'selectedValueIndoorOutdoorStatus': {
          'value1': 'Indoor ',//Man in a room
          'value2': 'Outdoor', //Sun
          'value3': 'Spray Painting',//spraypaint
          'value4': 'Complaint', //Reklamation
          'value5': 'Staff Purchase', //drawing of painter
          'value6': 'Store Purchase' //Nordsjø logo
      },
      'Headers': {
          'AddNewMaterial': 'Add New Material',
          'Nomaterialadded': 'No material added',
          'DeleteMaterial': 'Do you want to delete "@MaterialName"?',
      },
      'VALIDATIONS': {
          'TextRequired': 'Text required',
          'ColorCodeRequired': 'Color code required',
          'PersonalPriceRequired': 'Personal price required',
          'RetailPriceRequired': 'Retail price required',
          'PurchaseQuantityRequired': 'Purchase quantity required',
          'QuantityRequired': 'Quantity required',
      }
  }
};

