import { NgModule, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../../core/modules/shared.module';
import { ReminderService } from './reminder.service';
import { VibeReminderComponent } from './reminder.component';
import { EventEmitterEnum } from '../../../core/enum/event-emitter-ids.enum';
//import { ReminderEditDialog } from './reminder.component'
import { VibeAddReminderComponent } from './component/add/add-reminder.component';
import { VibeEditReminderComponent } from './component/edit/edit-reminder.component';
import { SelectProjectComponent } from './component/selectProject/selectProject.Component';


const routes = [
  {
    path: 'reminders',
    component: VibeReminderComponent
  },
  {
    path: 'reminder/add',
    component: VibeAddReminderComponent
  }

];

@NgModule({
  declarations: [
    VibeReminderComponent,
    //ReminderDeleteDialog,
    //ReminderEditDialog,
    VibeAddReminderComponent,
    VibeEditReminderComponent,
    SelectProjectComponent
  ],
  imports: [
    SharedModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    VibeReminderComponent
  ],
  entryComponents:[
    VibeAddReminderComponent,
    SelectProjectComponent
  ],
  providers: [ReminderService]
})

export class VibeReminderModule {
}
