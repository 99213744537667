export const locale = {
    lang: 'en',
    data: {
        'BUTTONS': {
            'NEWCOWORKER': 'New Co-worker',
            'BACK': 'Back',
            'SAVE': 'Save',
        },
        'FIELDS': {
            'CoworkerList': 'Coworker List',        
            'Name': 'Name',
        },
    }
};

