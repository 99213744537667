export const locale = {
    lang: 'ds',
    data: {
        'Header':{
            'SendSMSEmailSelection': 'Kunde Send SMS / E-mail-detaljer'
        },
        'FIELDS': {
            'Name': 'Name',
            'Questions': 'Spørgsmål',
            'SrNo': 'Sr nr.',
            'Status': 'Status',
            'Body':'Legeme'
        },
        'MESSAGES': {
            'Success': 'SMS is sent successfully',
            'Failed': 'Failed',
          'NORECORD': 'Intet Spørgsmål fundet',
          'SAVESUCCESSFULLY': 'Gem med succes.'
        },
        'BUTTONS': {
            'SMS': 'SMS',
            'Email': 'Email',
            'SMSEmail': 'SMS og e-mail',
            'None': 'Ingen',
            'Save': 'Gem',
            'Back': 'Cancel'
        },
        'VALIDATIONS': {
            'Mobile': 'Mobile number is required.',
            'Text': 'Text is required.'
        },
    }
};
