export const locale = {
    lang: 'en',
    data: {
        'TITLE': {
            'CustomerPagination': 'Antal log pr. side',
        },
        'FIELDS': {
            'SRNO': 'Sr no.',
            'Date': 'Date',
            'CoworkerName': 'Coworker',
            'OldStatus': 'Old status',
            'NewStatus': 'New status',
            'Description': 'Description',
            'Coworker': 'Coworker',
            'Not-Found-Worker': 'Co-worker not found',
            'ModuleType': 'Module type'
        },
        'Message': {

        },
        'FILTERS': {
            'TEXTSEARCH': 'Search By Number',
        },
        'moduleTypeList': {
            'value0': 'All',
            'value1': 'Project',
            'value2': 'Offer'
        },
        'TOOTIPORTITLE':{
            'SeeAnsware':'See Answare'
        }
    }
};