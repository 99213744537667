import { Component, OnInit, Inject } from '@angular/core';
import { FuseTranslationLoaderService } from '../../../../core/services/translation-loader.service';
import { locale as english } from './../translate/en';
import { locale as danish } from './../translate/ds';

import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl, ValidatorFn } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { postalcodeJson } from '../../../../core/constant/PostalCodeConstant';
@Component({
  selector: 'app-address-confirmation-modal',
  templateUrl: './address-confirmation-modal.component.html',
  //styleUrls: ['./../new-customer.component.scss'],
})
export class AddressConfirmationModalComponent implements OnInit {

  objAddress: any = {}
  constructor(private translationLoader: FuseTranslationLoaderService,
    public dialogRef: MatDialogRef<AddressConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.translationLoader.loadTranslations(english, danish);
  }

  ngOnInit() {
    var data = this.data
    this.objAddress.address = this.data.add1;
    this.objAddress.city = this.data.city;
    this.objAddress.position = this.data.position;
    this.objAddress.zip = this.data.zip;
    this.objAddress.fullAddress = this.data.fullAddress;
  }

  onConfirmAddress() {
    this.dialogRef.close(this.objAddress);
  }

  onNoClick() {
    this.dialogRef.close();
  }

  isNumber(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  searchCityFromZipcode() {
    var zip = postalcodeJson.filter(o => o.zip == this.objAddress.zip);
    if (zip.length > 0) {
      this.objAddress.city = zip[0].city;
    }
    else {
      this.objAddress.city = "";
    }
  }

}
