export const locale = {
    lang: 'ds',
    data: {
        'MESSAGES': {
            'Created': 'Oprettet med succes',
            'Updated': 'Opdateret med succes',
            'Deleted': 'Slettet med succes',
            'Failed': 'Operation mislykket, prøv senere',
        },
        'BUTTONS': {
            'BACK': 'Tilbage',
            'SAVE': 'Gem'
        },
        'FIELDS': {
            'Name': 'Navn',
            'Email': 'E-mail',
            'Phone': 'Telefon'
        },
        'Headers': {
            'AddPreference': 'Kundepræference',
        },
    }
};

