import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { MatDialog , MatOption} from '@angular/material';
import { AuthService } from 'app/core/services/auth.service';
import { SettingsService } from '../../settings.service';
import { ResponseBase } from "app/main/model/ResponseBase";
import { Settings } from "app/main/model/Settings";
import { yesOrNoOptions, ProjectIndoorOutDoorStatus } from 'app/main/model/ProjectStatus';
import { ProjectStatus, ProjectStatusForMaler, PurchaseStatus } from 'app/constant/common.constant';
import { ApiConstant } from "app/core/constant/ApiConstant";

@Component({
    selector: "project-search-settings",
    templateUrl: "./project-search-settings.component.html",
    styleUrls: ["./project-search-settings.component.scss"]
})

export class ProjectSearchSettingsComponent implements OnInit {
    showSpinner: boolean = false;
    @Input() projectSettings: any;
    CoworkerPriceOnProject: number = 0;
    calculateProjectPriceStatusObj: Settings;
    @ViewChild('allStatusSelected', { static: false }) private allStatusSelected: MatOption;
    @ViewChild('allIndoorOutdoorStatusSelected', { static: false }) private allIndoorOutdoorStatusSelected: MatOption;
    selectIndoorOutDoorStatus = ProjectIndoorOutDoorStatus;
    projectStatus = ProjectStatus;
    currentselectedStatusObj: number[];
    currentselectedIndoorOutdoorObj: number[];
    //roleName: string;
    calculateProjectPriceStatus: any ={};
    settingMasterValues: any = [];
    projectMonth: string = '';
    isShowInactiveRquisitionDefault: boolean = false;
    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private authService: AuthService,
        private settingsService: SettingsService,
        private dialog: MatDialog) {
        this.translationLoaderService.loadTranslations(danish, english);
    }

    ngOnInit() {
        this.settingMasterValues = Object.assign([], JSON.parse(localStorage.getItem("settingMasterValues")));
        if (this.projectSettings && this.projectSettings.length > 0) {
            var tempSettingObject = this.projectSettings.find(s => s.key1 == "Project" && s.key2 == "SearchSettings");
            if (tempSettingObject) {
                this.currentselectedStatusObj = [];
                this.currentselectedIndoorOutdoorObj = [];
                this.calculateProjectPriceStatusObj = tempSettingObject;
                this.calculateProjectPriceStatus = JSON.parse(tempSettingObject.value);
                this.setProjectPriceStatus();
            }
        }
        else
            this.getSettingsForCalculateProjectPriceStatus();
        //this.roleName = this.authService.getUserRole();
    }

    getSettingsForCalculateProjectPriceStatus() {
        this.showSpinner = true;
        this.settingsService.GetSettingsValue<ResponseBase>("Project", "SearchSettings",null).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.result && response.result.myResponse.length > 0) {
                    if (response.result.myResponse[0].value && response.result.myResponse.length > 0) {
                        this.currentselectedStatusObj = [];
                        this.currentselectedIndoorOutdoorObj = [];
                        this.calculateProjectPriceStatusObj = response.result.myResponse[0];
                        this.calculateProjectPriceStatus = JSON.parse(response.result.myResponse[0].value);
                        this.setProjectPriceStatus();
                    }
                    else
                        this.calculateProjectPriceStatus = {};
                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    changeSettingsForCalculateProjectPriceStatus() {
        this.showSpinner = true;
        this.calculateProjectPriceStatus.requistionStatus = this.currentselectedStatusObj.join().toString();
        this.calculateProjectPriceStatus.projectIndoorOutdoorStatus = this.currentselectedIndoorOutdoorObj.join().toString();
        this.calculateProjectPriceStatus.projectMonth = this.projectMonth;
        this.calculateProjectPriceStatus.isShowInactiveRquisitionDefault = this.isShowInactiveRquisitionDefault;
        this.calculateProjectPriceStatusObj.value = JSON.stringify(this.calculateProjectPriceStatus);
        this.settingsService.UpdateSettingValue<ResponseBase>(this.calculateProjectPriceStatusObj).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.result) {
                    this.calculateProjectPriceStatus = JSON.parse(response.result.value);
                        this.settingMasterValues.filter(item => {
                            if(item.key1 == "Project" && item.key2=="SearchSettings" && item.value){
                                var tempObj= JSON.parse(item.value); 
                                tempObj.projectIndoorOutdoorStatus = this.calculateProjectPriceStatus.projectIndoorOutdoorStatus;
                                tempObj.requistionStatus = this.calculateProjectPriceStatus.requistionStatus;
                                tempObj.projectMonth = this.calculateProjectPriceStatus.projectMonth;
                                tempObj.isShowInactiveRquisitionDefault = this.calculateProjectPriceStatus.isShowInactiveRquisitionDefault;
                                item.value= JSON.stringify(tempObj);
                                localStorage.setItem("settingMasterValues",JSON.stringify(this.settingMasterValues));
                            }
                        })
                    //     if(this.calculateProjectPriceStatus.projectIndoorOutdoorStatus){
                    //         var obj = this.calculateProjectPriceStatus.projectIndoorOutdoorStatus.split(",");
                    //         for (var i = 0; i < obj.length; i++) {
                    //             this.currentselectedIndoorOutdoorObj.push(Number(obj[i]));
                    //         }
                    //     }
                    //     if(this.calculateProjectPriceStatus.requistionStatus){
                    //         var statusObj = this.calculateProjectPriceStatus.requistionStatus.split(",");
                    //         for (var i = 0; i < statusObj.length; i++) {
                    //             this.currentselectedStatusObj.push(Number(statusObj[i]));
                    //         }
                    // }
                    this.setProjectPriceStatus();
                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    toggleAllStatusIndoorOutDoorSelection() {
        this.currentselectedIndoorOutdoorObj = [];
        if (this.allIndoorOutdoorStatusSelected.selected) {
            for (var i = 0; i < 7; i++) {
                this.currentselectedIndoorOutdoorObj.push(i);
            }
        }
    }

    toggleAllStatusSelection() {
        this.currentselectedStatusObj = [];
        if (this.allStatusSelected.selected) {
            // if (this.roleName == "Maler") {
            //     for (var i = 0; i < 13; i++) {
            //         if (i != 9 && i != 12) {
            //             this.currentselectedStatusObj.push(i);
            //         }
            //     }
            // }
            // else {
                for (var i = 0; i < 13; i++) {
                    this.currentselectedStatusObj.push(i);
                }
            // }
        }
    }
    setProjectPriceStatus(){
        if(this.calculateProjectPriceStatus.projectIndoorOutdoorStatus){
            var obj = this.calculateProjectPriceStatus.projectIndoorOutdoorStatus.split(",");
            for (var i = 0; i < obj.length; i++) {
                this.currentselectedIndoorOutdoorObj.push(Number(obj[i]));
            }
        }
        if(this.calculateProjectPriceStatus.requistionStatus){
            var statusObj = this.calculateProjectPriceStatus.requistionStatus.split(",");
            for (var i = 0; i < statusObj.length; i++) {
                this.currentselectedStatusObj.push(Number(statusObj[i]));
            }
        }
        if(this.calculateProjectPriceStatus.projectMonth){
            this.projectMonth = this.calculateProjectPriceStatus.projectMonth
        }
        this.isShowInactiveRquisitionDefault = this.calculateProjectPriceStatus.isShowInactiveRquisitionDefault
    }

    onKeyPressCheckNumber(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 44 && charCode != 46) {
            return false;
        }
        return true;
    }
}