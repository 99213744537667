import { Component, OnInit, Inject, ViewChild, ViewChildren, QueryList, ChangeDetectorRef } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { ProjectService } from 'app/main/pages/project/project.service';

import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatPaginator, MatSort, MatTable, MatTableDataSource, MatDialogRef, MAT_DIALOG_DATA, Sort } from '@angular/material';
import { RequestProjectHoursDetails } from 'app/main/model/RequestBase';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ProjectHours } from 'app/main/model/Project';

@Component({
    selector: "project-hours-details",
    templateUrl: "./project-hours-details.html",
    styleUrls: ["./project-hours-details.scss"],
    animations: [
        trigger('detailExpand', [
            state('collapsed, void', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
            transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
        ])
    ]
})

export class ProjectHoursDetails implements OnInit {
    displayedColumns: string[] = ['requistionCount', 'customerNumber', 'projectNumber', 'isHourlyWage', 'totalProjectHours', 'totalProjectWorkingHours', 'totalProjectRemainingHours', 'totalNegotiatedPrice', 'totalTurnover', 'totalRest', 'indoorOutdoorStatus', 'status'];
    dataSource: MatTableDataSource<ProjectHours>;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
        // this.dataSource.sort = sort;
    }
    showSpinner: boolean = false;
    projectList = [];
    matserProjectList = [];
    total: number;
    requestBase: any;
    pagination: any;
    displayPagination: boolean = false;
    projectHoursTotal: number = 0;
    projectWorkingHoursTotal: number = 0;
    projectRemainingHoursTotal: number = 0;
    isShowAllProject: boolean = false;
    totalNegotiatedPrice: number = 0;
    totalTurnover: number = 0;
    totalRest: number = 0;
    negativeRest: number = 0;

    innerDisplayedColumns = ['nummer', 'afdelingNavn', 'negotiatedPrice', 'turnover', 'rest', 'status'];
    expandedElements: any[] = [];
    includeHourlyWages: boolean = true;
    includeTurnover: boolean = true;
    orderByField: string = "customerNumber";
    orderByDirection: string = "asc";
    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private projectService: ProjectService,
        private dialogRef: MatDialogRef<ProjectHoursDetails>,
        private router: Router,
        private translate: TranslateService,
        private cd: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.translationLoaderService.loadTranslations(danish, english);
    }

    ngOnInit() {
        //this.dataSource = new MatTableDataSource(this.projectList);
        this.includeTurnover = this.data.isIncludeTurnover
        this.requestBase = new RequestProjectHoursDetails();
        this.requestBase.Index = 1;
        this.requestBase.Limit = 100;
        if (this.orderByDirection == "asc")
            this.requestBase.OrderBy = `${this.orderByField}`
        else
            this.requestBase.OrderBy = `${this.orderByField} ${this.orderByDirection}`
        this.getInActiveProjectList();
    }

    getInActiveProjectList() {
        this.showSpinner = true;
        this.requestBase.status = this.data.status;
        this.requestBase.indoorOutdoorStatus = this.data.indoorOutdoorStatus;
        this.projectService.getprojectallhoursdetailWithStatusBaseAndOrderBy(this.requestBase).subscribe({
            next: (response: ResponseBase) => {
                if (response && response.result) {
                    this.showSpinner = false
                    this.pagination = response.pagination;
                    this.displayPagination = true;
                    this.matserProjectList = response.result;
                    this.onShowHideHoursWages();
                    // this.projectList = this.matserProjectList;
                    // this.projectList = this.projectList.filter(x => x.isHourlyWage == false && x.totalNegotiatedPrice > 0);
                    // for (var i = 0; i < this.projectList.length; i++) {
                    //     this.projectList[i].indoorOutdoorStatus = this.getIndoorOutdoorStatus(this.projectList[i]["indendoersUdendoersStatistID"])
                    //     this.projectHoursTotal = this.projectHoursTotal + this.projectList[i]["totalProjectHours"];
                    //     this.projectWorkingHoursTotal = this.projectWorkingHoursTotal + this.projectList[i]["totalProjectWorkingHours"];
                    //     if (!this.projectList[i].isHourlyWage) {
                    //         this.projectRemainingHoursTotal = this.projectRemainingHoursTotal + this.projectList[i]["totalProjectRemainingHours"];
                    //     }
                    //     this.totalNegotiatedPrice += this.projectList[i]["totalNegotiatedPrice"] ? this.projectList[i]["totalNegotiatedPrice"] : 0;
                    //     this.totalTurnover += this.projectList[i]["totalTurnover"] ? this.projectList[i]["totalTurnover"] : 0;
                    //     this.totalRest += this.projectList[i]["totalRest"] ? this.projectList[i]["totalRest"]: 0;
                    //     this.projectList[i].requisitionCount= this.projectList[i].sagOpgaveList.length;
                    //     this.projectList[i].sagOpgaveList= new MatTableDataSource(this.projectList[i].sagOpgaveList);
                    // }
                    // this.dataSource = new MatTableDataSource(this.projectList);
                    //this.dataSource.paginator = this.pagination;
                }
            },
            error: err => {
                this.showSpinner = false
            },
            complete: () => {
                this.showSpinner = false
            }
        })
    }

    getIndoorOutdoorStatus(status) {
        var statusText = "";
        var statusArr = status ? status.split(',') : [];
        if (statusArr.length > 0) {
            for (var i = 0; i < statusArr.length; i++) {
                var appendcomma = "";
                if (i != statusArr.length - 1)
                    appendcomma = ","
                if (statusArr[i] == "1")
                    this.translate.get('selectedValueIndoorOutdoorStatus.value1').subscribe(value => { statusText += value + appendcomma; });
                if (statusArr[i] == "2")
                    this.translate.get('selectedValueIndoorOutdoorStatus.value2').subscribe(value => { statusText += value + appendcomma; });
                if (statusArr[i] == "3")
                    this.translate.get('selectedValueIndoorOutdoorStatus.value3').subscribe(value => { statusText += value + appendcomma; });
                if (statusArr[i] == "4")
                    this.translate.get('selectedValueIndoorOutdoorStatus.value4').subscribe(value => { statusText += value + appendcomma; });
                if (statusArr[i] == "5")
                    this.translate.get('selectedValueIndoorOutdoorStatus.value5').subscribe(value => { statusText += value + appendcomma; });
                if (statusArr[i] == "6")
                    this.translate.get('selectedValueIndoorOutdoorStatus.value6').subscribe(value => { statusText += value + appendcomma; });
            }
            return statusText;
        }
    }

    receivePaginatonEvent(event) {
        this.requestBase.Index = event.pageIndex;
        this.requestBase.Limit = event.pageSize;
        this.requestBase = event.pageSize;
        this.getInActiveProjectList();
    }

    goToProject(id) {
        this.dialogRef.close(id);
    }

    close() {
        this.dialogRef.close(false);
    }

    toggleRow(element: any) {
        element.sagOpgaveList &&
            (element.sagOpgaveList as MatTableDataSource<any>).data.length
            ? this.toggleElement(element)
            : null;
        this.cd.detectChanges();
    }

    isExpanded(row: any): string {
        const index = this.expandedElements.findIndex(x => x.projectNumber == row.projectNumber);
        if (index !== -1) {
            return 'expanded';
        }
        return 'collapsed';
    }

    toggleElement(row: any) {
        const index = this.expandedElements.findIndex(x => x.projectNumber == row.projectNumber);
        if (index === -1) {
            this.expandedElements.push(row);
        } else {
            this.expandedElements.splice(index, 1);
        }
    }
    onShowHideHoursWages() {
        this.totalNegotiatedPrice = 0;
        this.totalTurnover = 0;
        this.totalRest = 0;
        this.negativeRest = 0;
        this.projectHoursTotal = 0;
        this.projectWorkingHoursTotal = 0;
        this.projectRemainingHoursTotal = 0;
        this.projectList = [];
        this.projectList = this.matserProjectList.map(x => Object.assign({}, x));
        if (!this.includeHourlyWages)
            this.projectList = this.projectList.filter(x => x.isHourlyWage == false && x.totalNegotiatedPrice > 0);
        if (this.includeTurnover)
            this.projectList = this.projectList.filter(x => x.totalTurnover > 0);
        for (var i = 0; i < this.projectList.length; i++) {
            this.projectList[i].indoorOutdoorStatus = this.getIndoorOutdoorStatus(this.projectList[i]["indendoersUdendoersStatistID"])
            this.projectHoursTotal = this.projectHoursTotal + this.projectList[i]["totalProjectHours"];
            this.projectWorkingHoursTotal = this.projectWorkingHoursTotal + this.projectList[i]["totalProjectWorkingHours"];
            if (!this.projectList[i].isHourlyWage) {
                this.projectRemainingHoursTotal = this.projectRemainingHoursTotal + this.projectList[i]["totalProjectRemainingHours"];
            }
            else {
                this.projectList[i]["totalRest"] = 0;
            }
            this.totalNegotiatedPrice += this.projectList[i]["totalNegotiatedPrice"] ? this.projectList[i]["totalNegotiatedPrice"] : 0;
            this.totalTurnover += this.projectList[i]["totalTurnover"] ? this.projectList[i]["totalTurnover"] : 0;
            this.totalRest += this.projectList[i]["totalRest"] ? this.projectList[i]["totalRest"] : 0;
            this.negativeRest += (this.projectList[i]["totalRest"] < 0) ? Math.abs(this.projectList[i]["totalRest"]) : 0;
            this.projectList[i].requisitionCount = this.projectList[i].sagOpgaveList.length;
            this.projectList[i].sagOpgaveList = new MatTableDataSource(this.projectList[i].sagOpgaveList);
        }
        this.dataSource = new MatTableDataSource(this.projectList);
    }

    goToProjectWorkhourShourtcut(project) {
        if (project.id) {
            const url = this.router.serializeUrl(
                this.router.createUrlTree(['/projects/edit', project.id])
            );
            window.open(url, '_blank');
        }
    }

    sortData(e) {
        this.orderByField = e['active'];
        this.orderByDirection = e['direction'];
        if (this.orderByDirection == "asc")
            this.requestBase.OrderBy = `${this.orderByField}`
        else
            this.requestBase.OrderBy = `${this.orderByField} ${this.orderByDirection}`
        this.getInActiveProjectList();
    }
}