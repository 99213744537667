export const locale = {
    lang: 'en',
    data: {
        'BUTTONS': {
            'CoworkerAssign': 'Coworker Assign',
            'Cancel': 'Cancel',
        },
    }
};

