import { AsyncValidatorFn, ValidatorFn, AbstractControl, ValidationErrors } from "@angular/forms";
import { MaterialService } from "app/main/pages/material/material.service";
import { CoWorkersService } from "app/main/pages/coWorker/coWorkers.service";
//import { Observable } from "rxjs/Observable";
import 'rxjs/add/operator/map';

import { ErrorStateMatcher } from '@angular/material/core';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { CustomersService } from "app/main/pages/customer/customer.service";
import { Observable,from, of, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, catchError, map } from 'rxjs/operators';

//export function IsExistValidation(value: boolean): ValidatorFn {
//  return (control: AbstractControl): { [key: string]: boolean } | null => {
//    if (value == true) {
//      return { 'exist': true };
//    }
//    return null;
//  };
//}

export function IsExistValidation(materialService: MaterialService): AsyncValidatorFn {
  return (control: AbstractControl): Promise<ValidationErrors | null> => {
    return materialService.IsNumberExist<any>(control.value).then(response => {
      return null;
    }).catch(err => {
      return { 'exist': true };
    });
  };
};

export function IsExistValidationForCoWorker(coWorkersService: CoWorkersService): AsyncValidatorFn {
  return (control: AbstractControl): Promise<ValidationErrors | null> => {
    return coWorkersService.IsNumberExist<any>(control.value).then(response => {
      return null;
    }).catch(err => {
      return { 'exist': true };
    });
  };
};

export function IsInitialExistValidationForCoWorker(coWorkersService: CoWorkersService): AsyncValidatorFn {
  return (control: AbstractControl): Promise<ValidationErrors | null> => {
    return coWorkersService.IsInitialExist<any>(control.value).then(response => {
      return null;
    }).catch(err => {
      return { 'exist': true };
    });
  };
};

export function IsEmailExistValidationForCoWorker(coWorkersService: CoWorkersService): AsyncValidatorFn {
  return (control: AbstractControl): Promise<ValidationErrors | null> => {
    return coWorkersService.IsEmailExist<any>(control.value).then(response => {
      return null;
    }).catch(err => {
      return { 'exist': true };
    });
  };
};

export function IsNumber(evt): boolean {
  evt = (evt) ? evt : window.event;
  var charCode = (evt.which) ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;
};

export function setErrorImage(host, evt) {
  return evt.src = host + 'Upload/NoImageAvailable.png';
}

export function GenerateRandomNumber() {
  //return Math.floor(Math.random() * (max - min + 1)) + min;
  var date = new Date();
  var timestamp = date.getTime();
  return timestamp;
}

export function ConvertTimeToHunders(value) {
  var currentValue = parseFloat(value).toFixed(2);
  var nonFractionPart = parseFloat(currentValue.split('.')[0]);
  var FractionPart = parseFloat(currentValue.split('.')[1]);
  return Math.floor(nonFractionPart) + (((parseFloat(currentValue) - Math.floor(nonFractionPart)) * 100) / 60);
}

export function ConvertHundersToTime(value) {
  var currentValue = parseFloat(value).toFixed(2);
  var nonFractionPart = parseFloat(currentValue.split('.')[0]);
  var FractionPart = parseFloat(currentValue.split('.')[1]);
  return Math.floor(nonFractionPart) + (((parseFloat(currentValue) - Math.floor(nonFractionPart)) * 60) / 100);
}

// export class CustomValidator {  
//   static cannotContainSpace(control: AbstractControl) : ValidationErrors | null {  
//       if((control.value as string).indexOf(' ') >= 0){  
//           return {cannotContainSpace: true}  
//       }  

//       return null;  
//   }  
// }  
export function cannotContainSpace(control: AbstractControl) {
  if (control.value != null && (control.value as string).indexOf(' ') >= 0) {
    return { cannotContainSpace: true }
  }
  return null;
}

export function spaceOnlyValidator(control: AbstractControl): { [key: string]: any } | null {
  if (control.value != null) {
    const isSpace = /^\s+$/.test(control.value);
    if (control.value && isSpace) {
      return { isOnlyWhiteSpace: true };
    }
  }
  return null;
}



export class CustomErrorStateMatcher implements ErrorStateMatcher {
  public isSubmitted: boolean = false;
  public isValid: boolean = true;
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null) {
    if (form.invalid)
      this.isValid = false;
    else
      this.isValid = true;
    return !!(control && control.invalid && this.isSubmitted);
  }
}

export function IsEmailExistValidationForCustomer(customersService: CustomersService): AsyncValidatorFn {
  return (control: AbstractControl): Promise<ValidationErrors | null> => {
    return customersService.IsEmailExist<any>(control.value).then(response => {
      return null;
    }).catch(err => {
      return { 'exist': true };
    });
  };
};

export function IsPhoneNoValidationForCustomer(customersService: CustomersService): AsyncValidatorFn {
  return (control: AbstractControl): Promise<ValidationErrors | null> => {
    return customersService.IsPhoneNo<any>(control.value).then(response => {
      return null;
    }).catch(err => {
      return { 'exist': true };
    });
  };
};

export function IsEmailExistWithIdValidationForCustomer(customersService: CustomersService, currentEmail: string = '', id: string = ''): AsyncValidatorFn {
  return (control: AbstractControl): Promise<ValidationErrors | null> => {
    const email = control.value;
    // If email hasn't changed, skip validation
    if (email === currentEmail) {
      return null;
    }
    return customersService.IsEmailExistWithId<any>(control.value, id).then(response => {
      return null;
    }).catch(err => {
      return { 'exist': true };
    });
  };
};

export function IsPhoneNoWithIdValidationForCustomer(customersService: CustomersService, phone: string = '', id: string = ''): AsyncValidatorFn {
  return (control: AbstractControl): Promise<ValidationErrors | null> => {
    const email = control.value;
    // If email hasn't changed, skip validation
    if (email === phone) {
      return null;
    }
    return customersService.IsPhoneNoExistWithId<any>(control.value, id).then(response => {
      return null;
    }).catch(err => {
      return { 'exist': true };
    });
  };
};

// export function IsEmailExistValidationForCustomerNew(customersService: CustomersService): AsyncValidatorFn {
//   let previousValue: any = null;
//   let previousResult: ValidationErrors | null = null;

//   return (control: AbstractControl): Observable<ValidationErrors | null> => {
//     return new Observable<ValidationErrors | null>(observer => {
//       if (control.valueChanges) {
//         const subscription: Subscription = control.valueChanges.pipe(
//           debounceTime(1000),
//           distinctUntilChanged(),
//           switchMap(value => {
//             if (value === previousValue) {
//               return of(previousResult);
//             }
//             previousValue = value;

//             return from(customersService.IsEmailExist<any>(value)).pipe(
//               map(() => {
//                 previousResult = null; // Email does not exist
//                 return null;
//               }),
//               catchError(() => {
//                 previousResult = { 'exist': true }; // Email exists
//                 return of({ 'exist': true });
//               })
//             );
//           })
//         ).subscribe(result => {
//           observer.next(result);
//           observer.complete();
//         });

//         // Return the unsubscription logic
//         return () => subscription.unsubscribe();
//       }
//     });
//   };
// }