export const locale = {
  lang: 'en',
  data: {
    'BUTTONS': {
      'NEWPROJECTOFFER': 'New Offer',
      'Questions': 'Questions',
      'GoToOffer': 'Go to offer',
      'AllImage' : 'All Images'
    },
    'FIELDS': {
    },
    'FILTERS': {
      'SEARCH': 'Search',
      'SORTING': 'Sort By',
      'SORTING_STATUS': 'Status',
      'Active': 'Include all inactive',
      'BADGE': 'Inactive',
      'Deleted': 'Show deleted',
        'All': 'All',
        'IndoorOutDoor': 'Indoor,Outdoor,Spray Painting,Reclamation',
    },
    'MESSAGES': {
      'Failed': 'Operation failed , try later',
      'NORECORD': 'No Offer Found',
    },
      'selectedValueIndoorOutdoorStatus': {
          'value1': 'Indoor ',//Man in a room
          'value2': 'Outdoor', //Sun
          'value3': 'Spray Painting',//spraypaint
          'value4': 'Complaint', //Reklamation
          'value5': 'Staff purchase', //drawing of painter
          'value6': 'Store purchase' //Nordsjø logo
      },
      'selectedValueOfferStatus': {
          'value1': 'Created',
          'value2': 'Send to customer',
          'value3': 'Approved by customer',
          'value4': 'Rejected by customer',
          'value5': 'Project Created',
      },
    'SORTBY':
      [
        {
          Text: 'Number (0-9)',
          Value: 'Number'
        },
        {
          Text: 'Number (9-0)',
          Value: 'Number DESC'
        }
      ],
  }
};
