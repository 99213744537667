import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EventEmitterEnum } from '../../../../../core/enum/event-emitter-ids.enum';
import { FuseTranslationLoaderService } from '../../../../../core/services/translation-loader.service';
import { TitleService } from '../../../../../core/services/title.service';
import { AuthService } from '../../../../../core/services/auth.service';
import { RequestCustomer, RequestProject } from '../../../../model/RequestBase';
import { ResponseBase } from '../../../../model/ResponseBase';
import { CustomersService } from '../../../customer/customer.service';
import { MatDialog, MatExpansionPanel } from '@angular/material';
import { AlertMessageModelComponent } from '../../../alert-message-model/alert-message-model.component';
import { NewCustomerComponent } from '../../../customer/new-customer/new-customer.component';
import { VibeDefaultCustomerAddComponent } from 'app/main/pages/quickinvoice/component/default-customer-add/default-customer-add.component';
import { ProjectService } from 'app/main/pages/project/project.service';
import * as moment from 'moment';

@Component({
    selector: 'vibe-customer-daily-sales',
    templateUrl: './customer-daily-sales.component.html',
    styleUrls: ['./customer-daily-sales.component.scss']
})
export class VibeCustomerDailySalesComponent implements OnInit {
    settings = {};
    storedPurchaseCustomer: any;
    // roleName: string = "";
    // isValid: boolean = false;
    firstFormGroup: FormGroup;
    Customers: any = [];
    requestModel: RequestCustomer = new RequestCustomer();
    showSpinner: boolean = false;

    constructor(private _formBuilder: FormBuilder, private translationLoader: FuseTranslationLoaderService,
        private authService: AuthService, private titleService: TitleService,
        private customersService: CustomersService, private dialog: MatDialog,
        private customerService: CustomersService,
        private projectService: ProjectService) {
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
        //this.roleName = this.authService.getUserRole();
        this.requestModel = new RequestCustomer();
        this.requestModel.Limit = 500;
        this.GetCustomer(this.requestModel, '');
        this.GetStorePurchaseCustomer();
        setTimeout(() => {
            // if (this.roleName == "Admin") {
            //     this.isValid = true;
            // }
            // else if (this.roleName == "Kontor") {
            //     this.isValid = true;
            // }
            // else if (this.roleName == "Maler") {
            //     this.isValid = false;
            // }
            // else if (this.roleName == "Gaest") {
            //     this.isValid = false;
            // }
            // else {
            //     this.isValid = true;
            // }
            this.firstFormGroup = this._formBuilder.group({
                firstCtrl: ['', Validators.required]
            });
        }, 500);
    }

    GetCustomer(requestModel, searchId) {
        this.customersService.Get<ResponseBase>(requestModel)
            .subscribe((response: ResponseBase) => {
                if (response != null) {
                    this.getNextRequestForCustomer(response.pagination, requestModel, response.result, searchId);
                }
                else {
                    this.Customers = [];
                }
            },
                error => (err) => {
                    this.Customers = [];
                },
                () => {
                });
    }

    getNextRequestForCustomer(pagination, model, response, searchId) {
        if (pagination.index == 1) {
            this.Customers = response;
        }
        else {
            this.Customers.push(...response);
        }
        model.Index = pagination.index + 1;
        model.Limit = pagination.limit;
        model.TotalRows = pagination.totalCount;
        if (pagination.totalCount > pagination.index * pagination.limit)
            this.GetCustomer(model, searchId);
    }

    GetStorePurchaseCustomer() {
        this.customersService.GetCustomerStorePurchaseCustomer<ResponseBase>(this.requestModel)
            .subscribe((response: ResponseBase) => {
                if (response != null) {
                    this.settings = response.result.settings;
                    if (response.result.customerDetail) {
                        this.storedPurchaseCustomer = response.result.customerDetail;
                        if (this.Customers) {
                            var cust = this.Customers.find(o => o.id == this.storedPurchaseCustomer.id);
                            if (!cust) {
                                this.Customers.push(this.storedPurchaseCustomer);
                            }
                        }
                    }
                }
                else {
                    this.Customers = [];
                }
            },
                error => (err) => {
                    this.Customers = [];
                },
                () => {
                });
    }

    selectStoredPurchaseCustomer() {
        const dialogRef = this.dialog.open(VibeDefaultCustomerAddComponent, {
            height: '300px',
            width: '1000px',
            data: { settings: this.settings, customerList: this.Customers }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.GetStorePurchaseCustomer();
            }
        });
    }

    _handleKeydown(event: KeyboardEvent) {
        if (event.keyCode === 13) {
            event.stopPropagation();
        }
    }
}


