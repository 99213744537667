import { ProjectRequisitionStatusLog } from "./Project";
import { OffersMaterialDocumentDetail } from './ProjectOffer';

export class WorkHourDiaglogOpen {
  public date?: any;
  public workerId?: string;
  public projectId?: string;
  public sagNumber?: string;
  public address?: string;
  public status?: number;
  public coWorkerWorkHours: any;
  public projectWithWorkHoursList: any = [];
  public nonWorkHoursList: any = [];
  public zipCode?: string;
  public city?: string;
}

export class EmailSendingParam {
  public to: any = [];
  public toName: string = "";
  public from: string = "admin@vibe.dk";
  public fromName: string = "Admin";
  public body: string = "";
  public subject: string = "";//"Hjælp os med at kontakte dig.";
  public coworkerList: any = [];
  public attachmentBase64: string = "";
  public attachmentFileName: string = "";
  public displayAttachement: boolean = false;
  public sendAttachement: boolean = true;
  public isLoadDefaultEmailTemplate: boolean = true;
  public ProjectRequisitionStatusLogModel: ProjectRequisitionStatusLog;
  public text: string = "";
  public moduleID: string = "";
  public moduleName: string = "";
  public selectedCoworker: string = "";
  public displayCoworkerList: boolean = false;
  public isLoadSupplierEmailTemplate: boolean = false;
  public attachmentFiles:any = [];
  public isDisplayPersonalPrice: boolean = false;
}

export class EmailSendingParamForSettings{
  public body: string = "";
  public subject: string = "";
  public templateFilePath:string="";
}

export class EmailSendingParamForOffer {
    public offerId: string = "";
    public offerNumber: number;
    public projectId: string="";
    public projectNumber: number;
  public offerTaskId: string = "";
  public to: string = "";
  public toName: string = "";
  public from: string = "admin@vibe.dk";
  public fromName: string = "Admin";
  public body: string = "";
  public subject: string = "";//"Hjælp os med at kontakte dig.";
  public isSendOfferWithRoomDetail: boolean = false;
  public isIncludePriceOnPDF: boolean = false;
  public offerTaskIds: any;
  public offerMaterialFiles: OffersMaterialDocumentDetail[] = [];
    public offerMaterialFileName: string[] = [];
    public offerDropboxFilePath: string[] = [];
  public isShowRoomTotal: boolean = false;
}

export class ProjectInvoice {
  public projectId: string;
  public projectNumber: string = "";
  public fileName: string = "";
  public invoiceBase64: string = "";
  public requisitionId: string = "";
  public name: string = "";
}

export class ImageObj {
  public image: string;
  public imagePath: string;
}