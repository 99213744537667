export const locale = {
    lang: 'en',
    data: {
        'BUTTONS': {
            'NEWCOWORKER': 'New Co-worker',
        },
        'FIELDS': {
            'Status': 'Status',
            'InvoiceText': 'Invoice text',
            'TaskInfo': 'Task info',
            'Note': 'Note',
            'RequisitionNo': 'Requisition No.',
            'PaymentMode': 'Payment Mode',
            'Comments': 'Comments',
        },
        'Headers': {
            'ProjectDetail': 'Project Detail',
        },
        // 'selectedValueStatus': {
        //     'value1': 'Process',
        //     'value2': 'Scheduled visit',
        //     'value3': 'Do not answer',
        //     'value4': 'Offers sent',
        //     'value5': 'Offer approved',
        //     'value6': 'Planned',
        //     'value7': 'Offer rejected',
        //     'value8': 'Started',
        //     'value9': 'Performed',
        //     'value10': 'Billed',
        //     'value11': 'Pending',
        //     'value12': 'Ready billing',
        // },
        'paymentModeList': {
            'value1': 'Dankort',
            'value2': 'Mastercard',
            'value3': 'Cash',
            'value4': 'Mobile payment',
            'value5': 'Mobile payment KK',
            'value6': 'Credit',
            'value7': 'Other',
        }
    }
};

