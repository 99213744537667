//#region Imports 
// angular 
import { Component, OnInit, Output, EventEmitter, Input, ChangeDetectorRef, Renderer2, ViewChild, ElementRef } from "@angular/core";
import { MatDialog } from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
// model
import { CoWorker } from "app/main/model/CoWorker";
import { RequestCoWorker } from "app/main/model/RequestBase";
import { OfferQualityTestQuestionAnswer } from "../../new-question.model";
import { QuestionTypeCategory } from 'app/main/model/Project';
import { ResponseBase } from 'app/main/model/ResponseBase';
// third party packages
// interfaces
import { locale as english } from '../../translate/en';
import { locale as danish } from '../../translate/ds';
// services
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { CoWorkersService } from "app/main/pages/coWorker/coWorkers.service";
import { AuthService } from "app/core/services/auth.service";
// third party tool
// app-core
// components
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';
import { environment } from '../../../../../../environments/environment';
import { SettingEmailTempalteEditorComponent } from "app/main/pages/settings/component/setting-email-tempalte-editor/setting-email-tempalte-editor.component";
import { Settings, SettingValue } from "app/main/model/Settings";
import { SettingsService } from "app/main/pages/settings/settings.service";
//#endregion Imports 
import { TranslateService } from "@ngx-translate/core";
import { NotificationsService } from "angular2-notifications";



@Component({
    selector: "vibe-manage-questions",
    templateUrl: "./manage-questions.component.html",
    styleUrls: ["./manage-questions.component.scss"]
})

export class ManageQuestionsComponent implements OnInit {
    //#region Initializations

    //#region @Input
    private _EditableQuestion = new BehaviorSubject<any>(null);
    @Input()
    set EditableQuestion(value) {
        this._EditableQuestion.next(value);
    };
    get EditableQuestion() {
        return this._EditableQuestion.getValue();
    }
    //#endregion @Input

    //#region @Output
    @Output("OnCancelForm") OnCancelForm: EventEmitter<boolean> = new EventEmitter(false);
    @Output("OnSaveForm") OnSaveForm: EventEmitter<OfferQualityTestQuestionAnswer> = new EventEmitter(null);
    //#endregion @Output

    //#region @ViewChild
    //#endregion @ViewChild

    //#region Form
    createQuestionForm: FormGroup;
    questionImage: string = "";
    //#endregion Form

    //#region Variables
    showSpinner: boolean = false;
    showQueForm: boolean = false;
    isSubmitted: boolean = false;
    isEditMode: boolean = false;
    currentQueType: string;
    newChoiceItemName: string = '';
    typeList = QuestionTypeCategory;
    coWokers: CoWorker[] = [];
    tempOptionCondition: any[] = [];
    searchCoWorkerName: string = '';
    DisplayVal: any[] = ['name'] //'coworkerNumber',
    IsGettingCoWokers: boolean = false;
    files: any[] = [];
    filesPath: any[] = [];
    @ViewChild("fielInput", {static: false})
    InputVar: ElementRef;
    host: string;

    openPopup:boolean = false;
    settingValue: SettingValue = new SettingValue();
    settingMasterValues: any[] = [];
    commonNotificationSetting: Settings;
    manageNotificationsObj: Settings;
    //#endregion Variables

    constructor(
        private translationLoaderService: FuseTranslationLoaderService,
        private fb: FormBuilder,
        private dialog: MatDialog,
        private cdr: ChangeDetectorRef,
        private coWorkersService: CoWorkersService,
        private authService: AuthService,
        private ren: Renderer2,
        private settingsService: SettingsService,
        private translate: TranslateService,
        private notificationsService: NotificationsService,
    ) {
        this.translationLoaderService.loadTranslations(danish, english);
    }
    //#endregion  Initializations


    //#region Private/Helper Methods
    private onCreateQuestionForm() {
        this.createQuestionForm = this.fb.group({
            id: [''],
            questionType: [''],
            question: [''],
            answer: [''],
            rating: [5],
            choiceOptions: this.fb.array([]),
            createdBy: [''],
            createdDate: [''],
            modifyBy: [''],
            modifyDate: [''],
            isHideAnswer: [false],
        });
    }
    private newChoiceOption(maxId, que_name): FormGroup {
        return this.fb.group({
            id: new FormControl(maxId),
            name: new FormControl(que_name, Validators.required),
            question: new FormControl(''),
            answer: new FormControl(''),
            checked: new FormControl(false),
            reminderMsg: new FormControl(''),
            remindCoWorkerIDs: new FormControl(null),
            isSendReminder: new FormControl(false),
            isSendRemindBySMS: new FormControl(false),
            isSendRemindByEmail: new FormControl(false),
            isSendRemindByNotification: new FormControl(false),
        })
    }
    get choiceOptions(): FormArray {
        return this.createQuestionForm.get("choiceOptions") as FormArray
    }
    private addChoiceOption(que_name) {
        let maxId: number = 1;
        this.choiceOptions.value.forEach(element => {
            maxId = (element['id'] >= maxId) ? (Number(element['id']) + 1) : Number(maxId);
        });
        this.choiceOptions.push(this.newChoiceOption(maxId, que_name));
        this.tempOptionCondition[this.tempOptionCondition.length] = { isWithQueText: false };

        setTimeout(() => {
            this.newChoiceItemName = '';
        }, 0);
    }
    private removeChoiceOption(i: number) {
        this.choiceOptions.removeAt(i);
        //this.tempOptionCondition.splice(i, 1);
    }
    private clearAllValueAndValidations() {
        for (const key in this.createQuestionForm.controls) {
            this.createQuestionForm.get(key).clearValidators();
            if (key == 'choiceOptions') {
                this.choiceOptions.clear();
            } else {
                this.createQuestionForm.get(key).patchValue(null);
            }
            this.createQuestionForm.get(key).updateValueAndValidity();
        }
        this.createQuestionForm.reset();
    }
    private setTypeWiseValidation(Type) {
        this.createQuestionForm.get('question').setValidators([Validators.required]);
        this.createQuestionForm.get('question').updateValueAndValidity();

        if (Type == 'SingleChoice') {
            // this.createQuestionForm.get('id').setValidators([Validators.required]);
            // this.createQuestionForm.get('id').updateValueAndValidity();
        }
    }
    private setDefaultValue() {
        this.createQuestionForm.get('questionType').setValue(this.currentQueType);
        this.createQuestionForm.get('rating').setValue(5);
    }
    private AlertOfAddChoiceQue() {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: "200px",
            data: {
                Header: "MESSAGES.AddTypeList",
                ShowOKButton: true
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
            }
        });
    }
    private AlertOfAddTypeListItem() {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: "200px",
            data: {
                Header: "MESSAGES.AddTypeListItem",
                ShowOKButton: true
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
            }
        });
    }
    private async FillForm(que) {
        if (que.questionType == 'MultiChoice' || que.questionType == 'SingleChoice' && this.coWokers.length == 0) {
            var coworkerOBJ = new RequestCoWorker();
            coworkerOBJ.Name = "";
            this.showSpinner = true;
            await this.getCoWorkers(coworkerOBJ);
            this.showSpinner = false;
        }
        this.createQuestionForm.patchValue({ 'id': que['id'] })
        this.createQuestionForm.patchValue({ 'questionType': que['questionType'] })
        this.createQuestionForm.patchValue({ 'question': que['question'] })
        this.createQuestionForm.patchValue({ 'answer': que['answer'] })
        this.createQuestionForm.patchValue({ 'rating': que['rating'] })
        //this.createQuestionForm.patchValue({'choiceOptions' : }) : this.fb.array([]),
        this.createQuestionForm.patchValue({ 'createdBy': que['createdBy'] })
        this.createQuestionForm.patchValue({ 'createdDate': que['createdDate'] })
        this.createQuestionForm.patchValue({ 'modifyBy': que['modifyBy'] })
        this.createQuestionForm.patchValue({ 'modifyDate': que['modifyDate'] })
        this.createQuestionForm.patchValue({ 'isHideAnswer': que['isHideAnswer'] })
        this.files = que['files'];
        this.filesPath = que['filesPath'];

        let choiceOptionsList: any[] = [];
        try {
            choiceOptionsList = Object.assign([], JSON.parse(que['choiceOptions']))
        } catch (e) {
            choiceOptionsList = Object.assign([], que['choiceOptions'])
        }

        if (choiceOptionsList && choiceOptionsList.length > 0) {
            for (let j = 0; j < choiceOptionsList.length; j++) {
                const elementObj = this.fb.group({
                    id: new FormControl(j + 1),
                    name: new FormControl(choiceOptionsList[j]['name'], Validators.required),
                    question: new FormControl(choiceOptionsList[j]['question']),
                    answer: new FormControl(choiceOptionsList[j]['answer']),
                    checked: new FormControl(choiceOptionsList[j]['checked']),
                    reminderMsg: new FormControl(choiceOptionsList[j]['reminderMsg']),
                    remindCoWorkerIDs: new FormControl((choiceOptionsList[j].hasOwnProperty('remindCoWorkerIDs') && choiceOptionsList[j]['remindCoWorkerIDs'].length > 0) ? choiceOptionsList[j]['remindCoWorkerIDs'].split(',') : null),
                    isSendReminder: new FormControl(choiceOptionsList[j]['isSendReminder']),
                    isSendRemindBySMS: new FormControl(choiceOptionsList[j]['isSendRemindBySMS']),
                    isSendRemindByEmail: new FormControl(choiceOptionsList[j]['isSendRemindByEmail']),
                    isSendRemindByNotification: new FormControl(choiceOptionsList[j]['isSendRemindByNotification']),
                })
                this.choiceOptions.push(elementObj);

                let isWithQueText: boolean = (choiceOptionsList[j]['question'].length > 0) ? true : false;
                this.tempOptionCondition[j] = { isWithQueText: isWithQueText };


                if (choiceOptionsList[j]['isSendReminder'] == true) {
                    this.choiceOptions.controls[j].get('reminderMsg').setValidators(Validators.required);
                    this.choiceOptions.controls[j].get('reminderMsg').updateValueAndValidity();

                    this.choiceOptions.controls[j].get('remindCoWorkerIDs').setValidators(Validators.required);
                    this.choiceOptions.controls[j].get('remindCoWorkerIDs').updateValueAndValidity();
                } else {
                    this.choiceOptions.controls[j].get('reminderMsg').setValidators(null);
                    this.choiceOptions.controls[j].get('reminderMsg').updateValueAndValidity();

                    this.choiceOptions.controls[j].get('remindCoWorkerIDs').setValidators(null);
                    this.choiceOptions.controls[j].get('remindCoWorkerIDs').updateValueAndValidity();
                }

                if (isWithQueText) {
                    this.choiceOptions.controls[j].get('question').setValidators(Validators.required);
                    this.choiceOptions.controls[j].get('question').updateValueAndValidity();
                } else {
                    this.choiceOptions.controls[j].get('question').setValidators(null);
                    this.choiceOptions.controls[j].get('question').updateValueAndValidity();
                }

            }
        }
    }
    //#endregion Private/Helper Methods



    //#region Public Methods
    public onSelectType(currentQueType) {
        this.currentQueType = currentQueType;
        this.clearAllValueAndValidations();
        this.setTypeWiseValidation(currentQueType);
        this.setDefaultValue();
    }
    public onQueSave() {
        this.createQuestionForm.markAllAsTouched();
        this.isSubmitted = true;

        if (this.createQuestionForm.invalid) {
            return;
        }

        if (this.currentQueType == 'MultiChoice' || this.currentQueType == 'SingleChoice') {
            if (this.choiceOptions.length == 0) {
                this.AlertOfAddChoiceQue()
                return;
            }
        }

        if (this.newChoiceItemName.trim().length != 0) {
            this.AlertOfAddTypeListItem()
            return;
        }

        if (this.currentQueType == "MultiChoice" || this.currentQueType == "SingleChoice") {
            this.choiceOptions.value.forEach((item, index) => {
                if (item.isSendReminder) {
                    let tempData: any[] = Object.assign([], item['remindCoWorkerIDs'])
                    item['remindCoWorkerIDs'] = tempData.toString()
                    this.choiceOptions.controls[index].patchValue({ "remindCoWorkerIDs": item['remindCoWorkerIDs'] });
                } else {
                    item['remindCoWorkerIDs'] = ''
                    this.choiceOptions.controls[index].patchValue({ "remindCoWorkerIDs": '' });
                }
            });
        }

        let obj = new OfferQualityTestQuestionAnswer()
        obj = Object.assign(obj, this.createQuestionForm.value)

        if (this.isEditMode) {
            obj.modifyBy = this.authService.getCoWorkerId();
            obj.modifyDate = new Date();
        } else {
            obj.createdBy = obj.modifyBy = this.authService.getCoWorkerId();
            obj.createdDate = obj.modifyDate = new Date();
        }
        obj.questionImage = this.questionImage;
        obj.files = this.files;
        obj.filesPath = this.filesPath;
        this.OnSaveForm.emit(obj);
        this.onCancelBtnClick()
    }
    public addNewChoiceItem() {
        if (this.newChoiceItemName.trim().length == 0) {
            this.AlertOfAddTypeListItem()
            return;
        } else {
            this.addChoiceOption(this.newChoiceItemName)
        }
    }
    public onCancelBtnClick() {
        this.showQueForm = !this.showQueForm;
        this.isEditMode = false;
        this.currentQueType = null;
        this.OnCancelForm.emit(true);
        this.reset();
    }
    public onSelectRadioBtn(i,el) {
        var checkedIndex = this.choiceOptions.value.findIndex(p => p.checked);
        this.choiceOptions.value.forEach((item, index) => {
            this.choiceOptions.controls[index].get('checked').patchValue(false);
        });
        setTimeout(() => {
            if (checkedIndex != i) {
                this.choiceOptions.controls[i].get('checked').patchValue(true);
            }
            else {
                    el.checked = false;
                    this.ren.removeClass(el['_elementRef'].nativeElement, 'cdk-focused');
                    this.ren.removeClass(el['_elementRef'].nativeElement, 'cdk-program-focused');
            }
        }, 0);
    }
    public onDeleteChoiceOpt(i) {
        this.removeChoiceOption(i)
    }
    public sendReminderChkChange(val, i) {
        if (this.coWokers.length == 0) {
            var coworkerOBJ = new RequestCoWorker();
            coworkerOBJ.Name = "";
            this.getCoWorkers(coworkerOBJ);
        }

        //this.tempOptionCondition[i] = {isWithQueText: false};

        this.choiceOptions.controls[i].get('isSendRemindByEmail').patchValue(false)
        this.choiceOptions.controls[i].get('isSendRemindByNotification').patchValue(false)
        this.choiceOptions.controls[i].get('isSendRemindBySMS').patchValue(false)

        if (val == true) {
            this.choiceOptions.controls[i].get('reminderMsg').patchValue('')
            this.choiceOptions.controls[i].get('reminderMsg').setValidators([Validators.required]);
            this.choiceOptions.controls[i].get('reminderMsg').updateValueAndValidity();

            this.choiceOptions.controls[i].get('remindCoWorkerIDs').patchValue(null)
            this.choiceOptions.controls[i].get('remindCoWorkerIDs').setValidators([Validators.required]);
            this.choiceOptions.controls[i].get('remindCoWorkerIDs').updateValueAndValidity();

        } else {
            this.choiceOptions.controls[i].get('reminderMsg').patchValue('')
            this.choiceOptions.controls[i].get('reminderMsg').setValidators(null);
            this.choiceOptions.controls[i].get('reminderMsg').updateValueAndValidity();

            this.choiceOptions.controls[i].get('remindCoWorkerIDs').patchValue(null)
            this.choiceOptions.controls[i].get('remindCoWorkerIDs').setValidators(null);
            this.choiceOptions.controls[i].get('remindCoWorkerIDs').updateValueAndValidity();

        }
    }
    public withQueText(i) {
        this.tempOptionCondition[i]['isWithQueText'] = true;

        this.choiceOptions.controls[i].get('question').patchValue('')
        this.choiceOptions.controls[i].get('question').setValidators([Validators.required]);
        this.choiceOptions.controls[i].get('question').updateValueAndValidity();
    }
    public withOutQueText(i) {
        this.tempOptionCondition[i]['isWithQueText'] = false;

        this.choiceOptions.controls[i].get('question').patchValue('')
        this.choiceOptions.controls[i].get('question').setValidators(null);
        this.choiceOptions.controls[i].get('question').updateValueAndValidity();
    }
    ChangeImage(event) {
        this.questionImage = event;
    }
    DeleteImage() {
        this.questionImage = "";
    }
    public DropDownSearchCoWorker(value) {
        if (value != null && value != "") {
            var request = new RequestCoWorker();
            request.Name = value;
            this.getCoWorkers(request);
        } else {
            let coWorker = new RequestCoWorker();
            coWorker.IsDeleted = false;
            this.getCoWorkers(coWorker);
        }
    }
    public getCoWorkers(model: RequestCoWorker) {
        return new Promise((resolve, reject) => {
            model.OrderBy = "Name";
            this.IsGettingCoWokers = true;
            this.coWorkersService.Get<ResponseBase>(model)
                .subscribe((response: ResponseBase) => {
                    if (response) {
                        this.coWokers = response.result;
                    }
                    else {
                        this.coWokers = [];
                    }
                }, error => (err) => {
                    this.coWokers = [];
                }, () => {
                    this.IsGettingCoWokers = false;
                    resolve(1);
                });
        });
    }
    public _handleKeydown(event: KeyboardEvent) {
        if (event.keyCode === 13) {
            // do not propagate spaces to MatSelect, as this would select the currently active option
            event.stopPropagation();
        }
    }
    //#endregion Public Methods



    //#region Lifecycle Hooks
    ngOnInit() {
        this.settingMasterValues = Object.assign([], JSON.parse(localStorage.getItem("settingMasterValues")));
        this.host = environment.url;
        this.onCreateQuestionForm()
        this.reset();
        this.getSendTabSettings();
        this._EditableQuestion
            .subscribe(que => {
                if (que) {
                    this.onSelectType(que.questionType)
                    this.showQueForm = true;
                    this.isEditMode = true;
                    this.reset();
                    this.FillForm(que);
                }
            });
    }

    /**
  * on file drop handler
  */
    onFileDropped($event) {
        this.prepareFilesList($event);
    }

    /**
     * handle file from browsing
     */
    fileBrowseHandler(files) {
        this.prepareFilesList(files);
    }

    /**
     * Delete file from files list
     * @param index (File index)
     */
    deleteFile(index: number) {
        this.files.splice(index, 1);
    }


    /**
     * Convert Files list to normal array list
     * @param files (Files List)
     */
    prepareFilesList(files: Array<any>) {
        for (const item of files) {
            const reader = new FileReader();
            reader.onload = (e: any) => {
                item.base64 = e.target.result.split(',')[1];
                item.fileName = item.name;
                item.fileType = item.type;
                console.log("item", item);
                if(!this.files)
                    this.files = [];
                this.files.push(item);
            };
            reader.readAsDataURL(item);
        }
    }

    reset() {
        this.files = [];
        if (this.InputVar && this.InputVar.nativeElement)
            this.InputVar.nativeElement.value = "";
    }

    ngAfterViewChecked() {
        this.cdr.detectChanges();
    }
    ngAfterViewInit() {
    }
    ngOnDestroy() {
    }
    //#endregion Lifecycle Hooks

    checkEvent(event: boolean) {
        if(this.openPopup = event){
            this.setSettings();
        }
    }

    setSettings() {
        if (this.commonNotificationSetting && this.commonNotificationSetting != null) {
            this.manageNotificationsObj = this.commonNotificationSetting;
            console.log(this.manageNotificationsObj)
            // this.settingValue.Subject = this.createQuestionForm.value.question || '';
            this.settingValue = JSON.parse(this.manageNotificationsObj.value)
            this.createQuestionForm.patchValue(this.settingValue)
            this.changeInLocalStorage(this.manageNotificationsObj.id, this.manageNotificationsObj)
        }
    }

    getSendTabSettings() {
        this.showSpinner = true;
        this.settingsService.GetSettingsWithTemplateValue<ResponseBase>("Notification", "EmailToCustomer", null).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.result && response.result.myResponse.length > 0) {
                    this.commonNotificationSetting = response.result.myResponse[0];
                    this.setSettings();
                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    changeInLocalStorage(ID, new_obj) {
        if (this.settingMasterValues.find(obj => obj.id == ID)) {
            this.settingMasterValues.forEach((old_obj, index) => {
                if (old_obj.id == ID) {
                    this.settingMasterValues[index] = new_obj
                }
            })
        } else {
            this.settingMasterValues.push(new_obj)
        }
        localStorage.setItem("settingMasterValues", JSON.stringify(this.settingMasterValues));
    }

    EmailTemplateDialog() {
        const dialogRef = this.dialog.open(SettingEmailTempalteEditorComponent, {
          height: '90%',
          width: '90%',
          data: { settingValue: this.settingValue, IsOther: false, IsBday: false, IsSameDay: false }
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result != null && result != '') {
            this.settingValue = result;
            this.onSubmit();
          }
        });
      }

      onSubmit() {
        this.isSubmitted = true;
        if (this.checkEvent) {
            this.changeSettings();
        }
    }

    changeSettings() {
        this.showSpinner = true;
        this.settingValue.IsCreateReminder = this.createQuestionForm.value.IsCreateReminder;
        this.settingValue.IsSendSMS = this.createQuestionForm.value.IsSendSMS;
        this.settingValue.IsSendNotification = this.createQuestionForm.value.IsSendNotification;
        this.settingValue.IsSendEmail = this.createQuestionForm.value.IsSendEmail;
        this.settingValue.SMSText = this.createQuestionForm.value.SMSText || '';
        this.settingValue.NotificationText = this.createQuestionForm.value.NotificationText || '';
        this.settingValue.Subject = this.createQuestionForm.value.question || '';
        this.manageNotificationsObj.value = JSON.stringify(this.settingValue);

        this.settingsService.UpdateSettingWithTemplate<ResponseBase>(this.manageNotificationsObj).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.result) {
                    this.manageNotificationsObj = response.result;
                    this.translate.get("MESSAGES.Saved").subscribe({
                        next: (res) => {
                            this.notificationsService.create(res);
                        },
                        error: err => { }
                    });
                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinner = false;
                this.isSubmitted = false;
            }
        });
    }
}