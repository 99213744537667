export const locale = {
    lang: 'ds',
    data: {
        'FIELDS': {
            'Title': 'Denne sag har givet kr.',
            'Profit': 'i overskud',
            'Losse': 'i underskud'
        },
        'BUTTONS': {
            'BACK': 'Tilbage',
          },
    }
}