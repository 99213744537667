export const locale = {
    lang: 'en',
    data: {
        'Tabs': {
            'Offer': 'Offer',
            'BasicDetail': 'Basic Detail',
            'File': 'Files',
            'Category': 'Category',
            'WorkItem': 'Work Item',
            'Room': 'Room / Building',
            'PDFEmail': 'PDF / Email',
            'Questions': 'Questions',
            'MtoM': 'M to M',
            'Note': 'Note',
            'Offers': 'Offers',
            'Log': 'Log',
            'QualityTest': 'Quality Test',
        },
        'MESSAGES': {
            'Failed': 'Operation failed , try later',
            'Success': 'Success.',
            'ComingSoon': 'Coming Soon',
            'DropBoxApiNotResponding': 'Dropbox not responding please try after some time',
            'FileConflict': 'File already exists',
            'FolderConflict': 'Folder name already exists',
            'EmailSent': 'Email sent successfully',
            'DisabledTabMsg': 'You need to save the offer or reset.'
        },
        'TOOTIPORTITLE':{
            'Edit':'Edit',
            'Delete':'Delete',
            'SendSMS':'Send SMS',
            'SendEmail':'Send Email',
            'DeleteOffer':'Delete Offer',
        },
    }
};
