export const locale = {
    lang: 'ds',
    data: {
        'FIELD': {
            'MyProfile': 'Min profil',
            'Messages': 'Beskeder',
            'Logout': 'Log ud'
        },
        'TITLE_MATERIALS': 'Lager',
        'TITLE_MATERIAL': 'Lager',
        'TITLE_NEW_MATERIAL': 'Nyt Materiale',
        'TITLE_EDIT_MATERIAL': 'Opdatering Materiale',
        'TITLE_DASHBOARD': 'Dashboard',
        'TITLE_CUSTOMERS': 'Debitor',
        'TITLE_CUSTOMER': 'Kunde',
        'TITLE_NEW_CUSTOMER': 'Ny Kunde',
        'TITLE_UPDATE_CUSTOMER': 'Opdatere kunde',
        'TITLE_PROJECTS': 'Projekter',
        'TITLE_PROJECT': 'Projekt',
        'TITLE_PROJECTS_OFFER': 'Tilbud',
        'TITLE_PROJECTS_OFFERS': 'Tilbud',
        'TITLE_NEW_PROJECT': 'Nyt Projekt',
        'TITLE_UPDATE_PROJECT': 'Opdateringsprojekt',
        'TITLE_DOCUMENTS': 'Dokumenter',
        'TITLE_WORK_HOURS': 'Arbejdstimer',
        'TITLE_MESSAGES': 'Beskeder',
        'TITLE_COWORKERS': 'Medarbejdere',
        'TITLE_COWORKER': 'Medarbejder',
        'TITLE_NEW_COWORKER': 'Ny Medarbejdere',
        'TITLE_UPDATE_COWORKER': 'Opdatere Medarbejder',
        'TITLE_COMAPNYINFO': 'Firma',
        'TITLE_USER_PROFILE': 'Min profil',
        'TITLE_REMINDERS': 'Påmindelse',
        'TITLE_USER_MANUAL': 'Brugermanual',
        'TITLE_INQUIRYS': 'Forespørgsler',
        'TITLE_INQUIRY': 'Forespørgsel',
        'TITLE_REPORTS': 'Rapporter',
        'TITLE_REPORT': 'Rapport',
        'TITLE_RESOURCECALENDAR': 'Ressource kalender',
        'TITLE_PLANING': 'Planlægning',
        'TITLE_QUICKINVOICE': 'Butikssalg',
        'TITLE_STORE_SALES':'Butikssalg',
        'TITLE_STATISTIC':'Statestik',
        'TITLE_SUPPLIERS':'Leverandører',
        'TITLE_PAYROLL': 'Indtast Løn',
        'TITLE_SUPPLIER_SETTINGS': 'Pris justering',
        'TITLE_CARINFORMATIONS': 'Bil Informationer',
        'TITLE_VEHICLE': 'Køretøjs info',
        'TITLE_SETTINGS': 'Indstillinger',
        'TITLE_QUALITY_TEST':'Kvalitetssikring',
        'TITLE_TOOLSANDLADDERS': 'Værktøj og stiger',
        'TITLE_TERMOFEMPLOYMENT': 'Ansættelsesvilkår',
        'TITLE_ASSETS': 'Enheder',
        'TITLE_DEVICES': 'Nanno Brikker',
        'TITLE_LIVEMAP': 'Live kort',
        'TITLE_LANGUAGE': 'Sprog',
        'TITLE_RIGHT_MENU_CALENDER': 'Kalender',
        'TITLE_REVIEW_QUESTION': 'Spørgsmål til medarbejder',
        'TITLE_SUPPLIERPROJECTMATERIAL': 'Projekt materiale',

    }
};
