import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { CoWorker, CoWorkerFull, CoWorkerDevice, PayrollEntry } from '../../model/CoWorker';
import { ApiConstant } from '../../../../app/core/constant/ApiConstant';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';


@Injectable({ providedIn: 'root' })
export class PayrollService {
    private actionUrl: string;
    private userImage = new BehaviorSubject<any>('assets/images/vibe-images/user-profile/default profile picture.jpg');
    userImageIcon = this.userImage.asObservable();
    private settingsActionUrl: string;

    constructor(private http: HttpClient) {
        this.actionUrl = ApiConstant.API_URL + ApiConstant.COWORKER + "/";
        this.settingsActionUrl = ApiConstant.API_URL + ApiConstant.SETTINGS + "/";
    }

    public GetParams(param) {
        let params: HttpParams = new HttpParams();
        for (var key in param) {
            if (param.hasOwnProperty(key)) {
                let val = param[key];
                params = params.append(key, val);
            }
        }
        return params;
    }

    public Get<T>(RequestPayroll: any): Observable<T> {
        let search = this.GetParams(RequestPayroll);
        return this.http.get<T>(this.actionUrl + "SelectPayrollCoworkers", {
            params: search
        });
    }

    public GetPayrollByCoworkerID<T>(id: any, isActive: boolean): Observable<T> {
        return this.http.get<T>(this.actionUrl + "GetPayrollByCoworkerID/" + id + "?IsArchived=" + isActive);
    }

    public GetPayrollByCoworkerIDIsArchived<T>(id: any): Observable<T> {
        return this.http.get<T>(this.actionUrl + "GetPayrollByCoworkerID/" + id + "?IsArchived=true" );
    }

    public GetPayrollByID<T>(id: any): Observable<T> {
        return this.http.get<T>(this.actionUrl + "GetPayrollByID/" + id);
    }

    public AddPayroll<T>(payrollEntry: PayrollEntry): Observable<T> {
        return this.http.post<T>(this.actionUrl + "AddPayrollEntry", payrollEntry);
    }

    public UpdatePayroll<T>(payrollEntry: PayrollEntry): Observable<T> {
        return this.http.put<T>(this.actionUrl + "UpdatePayroll/" + payrollEntry.id, payrollEntry);
    }

    public Delete<T>(id: any): Observable<T> {
        return this.http.delete<T>(this.actionUrl + "DeletePayroll/" + id);
    }
}
