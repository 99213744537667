export const locale = {
  lang: 'ds',
  data: {
      'TITLE': {
          'DriveSetting':'Drive setting',
      },
      'FIELDS': {
          'Path': 'Sti',
          'OneDrive': 'One drive',
          'Dropbox': 'Dropbox',
          'Token': 'Token',
          'ClientId': 'Client Id',
          'ClientSecret': 'Client Secret',
          'TenantId': 'Tenant Id',
          'UserEmail': 'User Email',
      },
      'Message': {
          'DriveInformation': '*** Hvis indstillingen ændres, var alle kollegaer logget ud.',
      },
      'FILTERS': {
      },
      'Buttons': {
          'Save':'Gem',
      },
      'Help':{
          'Note':'Brug kun skråstreg(/) i stiindstilling',
      }
  }
};