export const locale = {
  lang: 'en',
  data: {
    'FIELDS': {
      'Name': 'Name',
      'EnterName': 'Enter Name',
      'Price': 'Pris',
      'Ranks': 'Rank',
      'PurchasePrice': 'Purchase price',
      'AmountOfWages': 'Amount of wages',
      'SalePrice': 'Sale Price',
      'EnterSalePrice': 'Enter Sale Price',
      'EnterRank': 'Enter Rank',
      'EnterPurchasePrice': 'Enter Purchase price',
      'EnterAmountOfWages': 'Enter Amount of wages',
      'OfferCategoryWorkItem': 'Offer Categorry Work Item',
    },
    'BUTTONS': {
      'SAVE': 'Gem',
      'BACK': 'Gå tilbage',
    },
  }
};
