export class WeekDays{
  public Day? :string;
  public Date?: string;
  public DateName: string;
  public MonthName: string;
}

export const WeekDaysOption: any[] = [
  { id: 1, key: 'selectedValueWeekDays.value1' },
  { id: 2, key: 'selectedValueWeekDays.value2' },
  { id: 4, key: 'selectedValueWeekDays.value4' },
  { id: 8, key: 'selectedValueWeekDays.value8' },
  { id: 16, key: 'selectedValueWeekDays.value16' },
  { id: 32, key: 'selectedValueWeekDays.value32' },
  { id: 64, key: 'selectedValueWeekDays.value64' }
]