import { Component, OnInit, Inject } from '@angular/core';
import { FuseTranslationLoaderService } from '../../../../../core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';


@Component({
  selector: 'app-view-alert-model',
  templateUrl: './view-alert-model.component.html',
  styles: []
})
export class ViewAlertModelComponent implements OnInit {
  downloadLink: string;
  constructor(private translationLoader: FuseTranslationLoaderService,
    public dialogRef: MatDialogRef<ViewAlertModelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.translationLoader.loadTranslations(english, danish);

  }

  ngOnInit() {
    this.downloadLink= this.data;
  }

  onCloseDialog(e) {
    this.dialogRef.close(e ? true : false)
  }

}
