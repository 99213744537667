import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FuseTranslationLoaderService } from '../../../core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'pdf-viewer',
    templateUrl: './pdf-viewer.component.html',
    styles: []
})
export class PdfViewerModelComponent implements OnInit {
    Url: string = "";
    downloadFileName: string = "";
    @ViewChild('externalPdfViewer', { static: false }) public externalPdfViewer;
    constructor(private translationLoader: FuseTranslationLoaderService,
        public dialogRef: MatDialogRef<PdfViewerModelComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.translationLoader.loadTranslations(english, danish);

    }

    ngOnInit() {
        //this.Url = this.data;

        var pdfdata = this.data.base64;
        this.downloadFileName = this.data.filename != '' ? this.data.filename : 'invoice.pdf';
        var data = pdfdata;
        var binary = atob(data.replace(/\s/g, ''));
        var len = binary.length;
        var buffer = new ArrayBuffer(len);
        var view = new Uint8Array(buffer);
        for (var i = 0; i < len; i++) {
            view[i] = binary.charCodeAt(i);
        }
        var blob = new Blob([view], { type: "application/pdf" });
        var url = URL.createObjectURL(blob);
        this.Url = url;
        //this.externalPdfViewer.pdfSrc = url; //'gre_research_validity_data.pdf';
        //this.externalPdfViewer.refresh();
    }

    onCloseDialog(e) {
        this.dialogRef.close(e ? true : false)
    }

}
