import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpParams, HttpClient } from '@angular/common/http';
import { projectRequisition } from 'app/main/model/Project';
import { ApiConstant } from 'app/core/constant/ApiConstant';


@Injectable()
export class ProjectRequisitionService {
  private actionUrl: string;
  constructor(private http: HttpClient) {
    this.actionUrl = ApiConstant.API_URL + ApiConstant.PROJECT + "/";
  }


  //to get requisition list of project by project Id
  public Get<T>(projectId: any, RequestRequisition: any): Observable<T> {
    let search = this.GetParams(RequestRequisition);
    return this.http.get<T>(this.actionUrl + projectId + "/" + ApiConstant.REQUISITION, {
      params: search
    });
  }

  public GetRequisitionByCoworker<T>(projectId: any, RequestProjectRequisitionByCowokrer: any): Observable<T> {
    let search = this.GetParams(RequestProjectRequisitionByCowokrer);
    return this.http.get<T>(this.actionUrl + projectId + "/" + ApiConstant.REQUISITION + "/" + ApiConstant.COWORKER, {
      params: search
    });
  }

  public GetSingle<T>(projectId: any, id: any): Observable<T> {
    return this.http.get<T>(this.actionUrl + projectId + "/" + ApiConstant.REQUISITION + "/" + id);
  }

  public Add<T>(projectId: any, requisition: projectRequisition): Observable<T> {
    return this.http.post<T>(this.actionUrl + projectId + "/" + ApiConstant.REQUISITION, requisition);
  }

  public Update<T>(projectId: any, id: any, requisition: projectRequisition): Observable<T> {
    return this.http.put<T>(this.actionUrl + projectId + "/" + ApiConstant.REQUISITION + "/" + id, requisition);
  }

    public UpdateColorCodeOnProject<T>(projectId: string, obj: any): Observable<T> {
        return this.http.put<T>(this.actionUrl + "updateColorCodeOnProject/" + projectId , obj);
    }

  public Delete<T>(projectId: any, id: any): Observable<T> {
    return this.http.delete<T>(this.actionUrl + projectId + "/" + ApiConstant.REQUISITION + "/" + id);
  }
  public RemoveRequistion<T>(projectId: any, id: any,isUpDownQty:boolean): Observable<T> {
    return this.http.delete<T>(this.actionUrl + projectId + "/RemoveRequisitionWithMaterialInventory/" + id+"?isUpDownStock=" + isUpDownQty);
  }
  public getProjectAllRequisitionCoworkers<T>(projectId: any): Observable<T> {
    return this.http.get<T>(this.actionUrl + projectId + "/" + ApiConstant.ALLREQUISITIONS + "/" + ApiConstant.COWORKER);
  }

  public Updatestatusallrequisition<T>(projectId: any, status: any): Observable<T> {
    return this.http.put<T>(this.actionUrl + projectId + "/" + "updatestatusallrequisition" + "/" + status, null);
  }

  public DownloadInvoiceReport<T>(id: string, requisition: projectRequisition): Observable<T> {
    return this.http.post<T>(this.actionUrl + "createAnInvoice/" + ApiConstant.REQUISITION + "/" + id, requisition);
  }

  //ALLREQUISITIONS: 'allrequisition'
  //"{{ID}}/allrequisitions/coworkers"


  public GetParams(param) {
    let params: HttpParams = new HttpParams();
    for (var key in param) {
      if (param.hasOwnProperty(key)) {
        let val = param[key];
        params = params.append(key, val);
      }
    }
    return params;
  }
}
