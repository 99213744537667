export const locale = {
    lang: 'ds',
    data: {
        'Headers': {
            'QuestionList': 'Spørg kunden om årsagen til afvisning:'
        },
        'QuestionTypeCategory': {
            'value1': 'Textbox',
            'value2': 'Multi Choice',
            'value3': 'Single Choice',
            'value4': 'Rating'
        },
        'BUTTONS': {
            'NEWPROJECTOFFER': 'Nyt Sag tilbud',
            'AddQuestion': 'Tilføj spørgsmål',
            'SendReminder': 'Send påmindelse',
            'Save': 'Gem',
            'Cancel': 'Gå tilbage',
            'Text': 'Tekst',
            'Add': 'Tilføj',
        },
        'FIELDS': {
            'Question': 'Spørgsmål',
            'SrNo': 'Sr nr.',
            'Type': 'Type',
            'Answer': 'Svar',
            'WithTextbox': 'Med tekstboks',
            'ReminderMsg': 'Påmindelse Besked'
        },
        'VALIDATIONS': {
            'QuestionRequired': 'Spørgsmål påkrævet',
            'CoWorkerRequired': 'Medarbejder påkrævet',
            'Not-Found-Worker': 'Medarbejder ikke fundet',
        },
        'PlaceHolder': {
            'SelectCoWorker': 'Medarbejder',
            'Search-worker': 'Søg Medarbejder',
        },
        'MESSAGES': {
            'NoQuestionAnswerFound': 'Ingen Spørgsmål Fundet.',
            'AddTypeList': 'Tilføj mindst en værdi i typelisten.',
            'AddTypeListItem': 'Tilføj værdi til typelisten.'
        },
        'ToolTip': {
            'SendReminderBySMS': 'Send påmindelse med Sms',
            'SendReminderByEmail': 'Send påmindelse med e-mail',
            'SendReminderByNotification': 'Send påmindelse med notifikationer',
        }
    }
}