export const locale = {
    lang: 'en',
    data: {
        'Headers': {
            'PlanWithHoliday': 'Plan on holidays',
            'PlanWithoutHoliday': 'Do not plan on holidays',
            'Disconnect': 'Disconnect',
            'ExtendOnFuture': 'Expand by number of days',
            'ExtendWithoutFuture': 'Schedule on these days',
            'MaterialStockUpdown':'Write up stock again',
            'NoChange':'Do not write up stock again'
        }
    }
};
