export const locale = {
    lang: 'en',
    data: {
        'TITLE': {
            'CoworkerReviewQuestion': 'Coworker review questions',
            'ReviewQuestionsPagination': 'Number of questions per page',
            'AskHelpForCoworker':'Add time ask help for fill help data on coworker',
        },
        'FIELDS': {
            'IsSendEmail': 'Is Send Email ?',
            'TabList': 'Tab list setting',
            "Title":'Title',
            "StartDate":'Start Date',
            "EndDate":'EndDate',
            'SRNO': 'Sr No',
        },
        'Message': {
            'Created': 'Created successfully.',
            'Updated': 'Updated successfully.',
            'Deleted': 'Deleted successfully.',
            'Failed': 'Something went wrong !',
            'NoRecords': 'No records found !'
        },
        'FILTERS': {
        },
        'moduleTypeList': {
        },
        'BUTTONS': {
            'Save':'Save',
            'Add': 'Add',
            'SetValue':'Set Value',
        },
        'TOOTIPORTITLE':{
            'Edit':'Edit',
            'Delete':'Delete',
        }
    }
};