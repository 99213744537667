import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../../core/modules/shared.module';
import { FormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask'
import { NgxCurrencyModule } from 'ngx-currency';
import { DateTimePickerModule, DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { TermsOfEmploymentService } from './terms-of-employment.service';
import { TermsOfEmploymentComponent } from './terms-of-employment.component';
import { CustomCurrencyMaskConfig } from 'app/core/constant/CurrencyMaskConfiguartion';
import { NewTermsOfEmploymentComponent } from './new-terms-of-employment/new-terms-of-employment.component';
import { MatBadgeModule } from '@angular/material/badge';

const routes = [
    {
        path: 'termsofemployment',
        component: TermsOfEmploymentComponent,
    },
    // {
    //     path: 'termsofemployment/:id',
    //     component: TermsOfEmploymentComponent
    // },
];

@NgModule({
    declarations: [
        TermsOfEmploymentComponent,
        NewTermsOfEmploymentComponent
    ],
    imports: [
        SharedModule,
        RouterModule.forChild(routes),
        FormsModule, NgxMaskModule.forRoot(),
        NgxCurrencyModule.forRoot(CustomCurrencyMaskConfig),
        DateTimePickerModule,
        DatePickerModule,
        MatBadgeModule
    ],
    entryComponents: [
        NewTermsOfEmploymentComponent
    ],
    exports: [
        
    ],
    providers: [
        TermsOfEmploymentService
    ]
})

export class TermsOfEmploymentModule {
}
