import { Component, Inject, OnInit, Optional } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';

import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { ProjectofferService } from 'app/main/pages/project-offer/project-offer.service';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { Settings } from 'app/main/model/Settings';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CalendarService } from 'app/main/pages/calendar/calendar.service';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { AlertMessageModelComponent } from "app/main/pages/alert-message-model/alert-message-model.component";

@Component({
    selector: 'Select-Saved-Offer',
    templateUrl: './select-saved-offer.component.html',
    styleUrls: ["./select-saved-offer.component.scss"]
})

export class SelectSavedOfferComponent implements OnInit {
    showSpinner: boolean = false;
    SettingsObj: Settings = new Settings();
    travelingAmount: number = 0;
    DeserilizedValue: any;
    SavedOfferList: any;
    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private translate: TranslateService,
        private projectofferService: ProjectofferService,
        public dialogRef: MatDialogRef<SelectSavedOfferComponent>,
        private calendarService: CalendarService,
        private notificationsService: NotificationsService,
        public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.translationLoaderService.loadTranslations(danish, english);
    }

    ngOnInit() {
        this.SavedOfferList = this.data;
    }

    deleteCreatedOffer(obj) {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: "200px",
            data: { Header: 'Headers.DeleteDiverseMaterial' }
        })

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.projectofferService.DeleteCreateLater(obj.id).subscribe({
                    next: (response) => {
                        if (response) {
                            this.SavedOfferList = this.SavedOfferList.filter(o => o.id != obj.id);
                        }
                    },
                    error: err => {

                    },
                    complete: () => {
                    }
                });
            }
        });
        
    }

    close() {
        this.dialogRef.close(null);
    }

    onSelectName(obj) {
        this.dialogRef.close(obj);
    }
}