export const locale = {
    lang: 'en',
    data: {
        'DOCUMENT': {
            'TITLE': 'Document',
            'DESC': 'Hej Document!'
        },
        'FEILDS': {
            'TEXTSEARCH': 'Text Search',
        },
        'Headers': {
            'Ok': 'OK',
            'Cancel': 'Cancel',
            'ChooseFile': 'Choose File',
            'NoFileSelected': 'No File Selected',
            'FOLDEREMPTY': 'This folder is empty',
        },
        'BUTTONS': {
            'CREATE_FOLDER': 'Create Folder',
            'DOWNLOAD': 'Download',
            'DELETE': 'Delete',
            'SEARCH': 'Search',
            'HIDEFOLDERFOREMPLOYEE': 'Hide folder for employee.',
            'MOVEFOLDERTOFINISH': 'Move to finish folder',
        },
        'MESSAGES': {
            'DropBoxApiNotResponding': 'Dropbox not responding please try after some time',
            'FileConflict': 'File already exists',
            'FolderConflict': 'Folder name already exists',
            'Updated': 'Updated',
            'SELECTANYFILE': 'Select any document',
        },
    }
};

