export const locale = {
    lang: 'en',
    data: {
        'FIELDS': {
            'Coworker': 'Coworker',
            'CoworkerList': "Coworker list",
            'All': 'All',
        },
        'MESSAGES': {
            'Created': 'Created successfully',
            'Updated': 'Updated successfully',
            'Deleted': 'Deleted successfully',
            'Failed': 'Operation failed , try later',
            'NORECORD': 'No Record Found',
            'CoworkerExist': 'Coworker is already exist.',
        },
        'BUTTONS': {
            'NEWCOWORKER': 'New Co-worker',
        },
        'Headers': {
            'DeleteMessage': 'Are you sure you want to delete'
        }
    }
}