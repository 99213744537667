export const locale = {
    lang: 'en',
    data: {
        'QuestionTypeCategory': {
            'value1': 'Textbox',
            'value2': 'Multi Choice',
            'value3': 'Single Choice',
        },
        'BUTTONS': {
            'NEWPROJECTOFFER': 'New Project Offer',
            'AddQuestion': 'Add Question',
            'Save': 'Save',
            'Cancel': 'Cancel',
            'Text': 'Text'
        },
        'FIELDS': {
            'Question': 'Question',
            'SrNo': 'Sr No.',
            'Type': 'Type',
            'Answer': 'Answer'
        },
    }
}