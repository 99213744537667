import { Component, OnInit, ElementRef, NgZone, ViewChild, Input, Output, EventEmitter, Inject } from '@angular/core';
//import { MAT_DIALOG_DATA, MatDialogRef, MatTableDataSource ,MatOption} from '@angular/material';
import { Router } from '@angular/router';
import { FuseTranslationLoaderService } from '../../../../../core/services/translation-loader.service';
import { TitleService } from '../../../../../core/services/title.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { EventEmitterEnum } from '../../../../../core/enum/event-emitter-ids.enum';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { MaterialService } from '../../material.service';
import { ResponseBase } from '../../../../model/ResponseBase';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { PdfViewerModelComponent } from 'app/main/pages/pdf-viewer/pdf-viewer.component';
import { supplierReport, QuickInvoiceMaterials } from 'app/main/model/Material';
import { MaterialWeatherType } from 'app/constant/common.constant';
import { EmailSendingParam } from 'app/main/model/common';
import { TextEditorEmailSendComponent } from 'app/main/shared-component/text-editor-email-send/text-editor-email-send.component';
import { AuthService } from 'app/core/services/auth.service';
import { queryParams } from '@syncfusion/ej2-base';
import { SupplierRequest, RequestMaterial } from 'app/main/model/RequestBase';
import { AddOrderMaterialItemComponent } from '../add-order-material-item/add-order-material-item.component';
import { Supplier } from 'app/main/model/Supplier';
import { ProjectListSharedComponent } from '../../../../shared-component/project-list/project-list.component';
import { SelectProjectRequistionComponent } from 'app/main/pages/material/component/select-project-requistion-modal/select-project-requistion-modal.component';
import { CommonService } from 'app/core/services/common.service';


@Component({
    selector: 'vibe-supplierList',
    templateUrl: './supplierList.component.html',
    styleUrls: ['./supplierList.component.scss']
})
export class SupplierListComponent implements OnInit {
    supplierList: any = [];
    showSpinner: boolean = false;
    tempList: any = [];
    showAll: boolean = false;
    weatherTypeDetail: any;
    MaterialWeatherTypeList = [];
    weatherTypeList = MaterialWeatherType;
    typeId: number = 0;
    currentselectedWeatherObj: any = { typeId: 0 };
    Materials: QuickInvoiceMaterials[] = [];
    Suppliers: Supplier[] = [];
    projectAndRequistion: any = {};
    constructor(private translationLoader: FuseTranslationLoaderService,
        private _router: Router,
        private titleService: TitleService,
        private translate: TranslateService,
        private commonService: CommonService,
        private notificationsService: NotificationsService,
        private materialService: MaterialService,
        public dialog: MatDialog,
        private authService: AuthService) {
        this.translationLoader.loadTranslations(english, danish);

    }
    ngOnInit() {
        this.GetWeatherList();
        setTimeout(() => {
            this.getSupplierList();
        }, 1000);
        this.GetAllSupplier();
        var reqModel = new RequestMaterial();
        this.GetMaterial(reqModel);
    }
    getSupplierList() {
        this.showSpinner = true;
        this.materialService.GetSupplierListWithMaterial<ResponseBase>(this.showAll)
            .subscribe((response: ResponseBase) => {
                if (response != null) {
                    this.supplierList = response.result.myResponse;
                    this.supplierList.filter(item => {
                        item.isSendBtn = true;
                        item.isChecked = true;
                        if (this.currentselectedWeatherObj.typeId) {
                            let tempMaterialList = item.materialList;
                            item.materialList = [];
                            //item.materialList = tempMaterialList.filter(x => x.weatherType == 0);
                            for (let i = 0; i < this.currentselectedWeatherObj.typeId.length; i++) {
                                this.currentselectedWeatherObj.typeId[i];
                                if (this.currentselectedWeatherObj.typeId[i] != undefined) {
                                    var list = tempMaterialList.filter(x => {
                                     x.isOrdered = x.isChecked ? false : true;
                                     return x.weatherType == this.currentselectedWeatherObj.typeId[i]
                                    });
                                    item.materialList = [...item.materialList, ...list];
                                }
                            }
                        } else {
                            item.materialList = item.materialList.filter(x => x.weatherType == 0 || x.weatherType == this.weatherTypeDetail);
                        }
                        var total = 0;
                        item.materialList.filter(p => {
                            total = total + p.purchasePrice * p.quantity;
                        });
                        item.total = total;
                        return item;
                    });
                }
                else {
                    this.supplierList = [];
                }
                this.showSpinner = false;
            },
                error => (err) => {
                    this.supplierList = [];
                    this.showSpinner = false;
                },
                () => {
                });
    }
    getTotalOfPurchasePrice(purchasePrice, maxInventory, holdings) {
        return (purchasePrice * (maxInventory - holdings))
    }
    getMaterialTotal(material) {
        var total = 0;
        material.materialList.filter(p => {
            total = total + p.purchasePrice * p.quantity;
        });
        //material.total = total;
        return total
    }
    onSetActiveAll(event, index, item) {
        item.isChecked = !item.isChecked;
        item.materialList.filter(p => {
            p.isChecked = event.checked;
        });
        item.isSendBtn = event.checked;
    }
    onSetActive(event, index, material, item) {
        material.isChecked = !material.isChecked;
        setTimeout(() => {
            this.setCheckAllInactive(event, item);
        })
        if (material.isChecked)
            item.isSendBtn = event.checked;
    }
    setCheckAllInactive(event, item) {
        var objResult = item.materialList.filter(it => {
            return it.isChecked == event.checked
        });
        if (objResult && objResult.length > 0) {
            if (objResult.length == item.materialList.length) {
                item.isChecked = event.checked;
                item.isSendBtn = event.checked;
            }
            else
                item.isChecked = false;
        }
    }

    sendEmail(item, isSendEmail) {
        let isAtleastOneSelected = item.materialList.find(i => i['isChecked']==true)
        let isExistedMaterialID = item.materialList.find(i => i['isExistedMaterialID']==true)
        if (isExistedMaterialID) {
            this.translate.get("MESSAGES.ItemNumberExist").subscribe({
                next: (res) => {
                    this.notificationsService.error(res);
                },
            });
            return;
        } else if (!isAtleastOneSelected) {
            this.translate.get("MESSAGES.ItemNotSelected").subscribe({
                next: (res) => {
                    this.notificationsService.error(res);
                },
            });
            return;
        } else {
            //if(isSendEmail){
            //    item.materialList.filter(it => {
            //        if (it.colorCode) {
            //            if (it.text && it.pdfCount == undefined) {
            //                it.MaterialText = it.text;
            //                it.pdfCount = 1
            //            }
            //            it.text = it.MaterialText;
            //        }
            //    });
            //} else {
            //    item.materialList.filter(it => {
            //        if (it.colorCode) {
            //            if (it.text && it.pdfCount == undefined) {
            //                it.MaterialText = it.text;
            //                it.pdfCount = 1
            //            }
            //            it.text = it.MaterialText + ' - ' + it.colorCode;
            //        }
            //    });
            //}
            item.materialList.filter(it => {
                if (it.colorCode) {
                    if (it.text && it.pdfCount == undefined) {
                        it.MaterialText = it.text;
                        it.pdfCount = 1
                    }
                    it.text = it.MaterialText;
                }
            });
            
            this.tempList = [];
            this.tempList.push(item);
            if (isSendEmail) {
                var emailSendingParam = new EmailSendingParam();
                emailSendingParam.fromName = this.authService.getUserName();
                // emailSendingParam.from = "post@vibesmalerfirma.dk";
                emailSendingParam.to = item.email;
                emailSendingParam.moduleName = "Supplier";
                emailSendingParam.subject = "Bestilling af vare";
                emailSendingParam.moduleID = ""; //this.customerId;
                emailSendingParam.isLoadDefaultEmailTemplate = false;
                emailSendingParam.isLoadSupplierEmailTemplate = true;
    
                const dialogRef = this.dialog.open(TextEditorEmailSendComponent, {
                    height: '90%',
                    width: '90%',
                    data: emailSendingParam
                });
                dialogRef.afterClosed().subscribe(result => {
                    if (result != null && result != '') {
                        this.tempList[0]["emailSendingParam"] = Object.assign({}, emailSendingParam);
                        this.sendEmailToSupplier(isSendEmail);
                    }
                });
            } else {
                this.sendEmailToSupplier(isSendEmail);
            }    
        }
    }

    updateQuantity(newValue, item) {
        var total = 0;
        item.materialList.filter(p => {
            total = total + p.purchasePrice * p.quantity;
        });
        item.total = "";
        item.total = total;
    }

    showAllMaterial(showAll) {
        this.showAll = showAll;
        this.getSupplierList();
    }

    GetWeatherList() {
        this.materialService.GetWeatherList().subscribe((response: ResponseBase) => {
            if (response) {
                var weatherDetail = response.result.myResponse[0];
                if (weatherDetail.value) {
                    this.MaterialWeatherTypeList = JSON.parse(weatherDetail.value);
                    var currentMonth = new Date().getMonth() + 1;
                    //var currentMonth = 12;
                    //this.weatherTypeDetail = this.MaterialWeatherTypeList.filter(x => x.startMonth >= currentMonth && x.endMonth <= currentMonth);
                    this.MaterialWeatherTypeList.filter(x => {
                        if (x.startMonth <= currentMonth && x.endMonth >= currentMonth) {
                            this.weatherTypeDetail = x.typeId;
                        }
                    });
                    this.currentselectedWeatherObj.typeId = [];
                    this.currentselectedWeatherObj.typeId.push(0);
                    this.currentselectedWeatherObj.typeId.push(this.weatherTypeDetail);
                }
            }
        });
    }

    SearchWeather(type) {
        this.currentselectedWeatherObj.typeId = this.currentselectedWeatherObj.typeId.filter(o => o != null);
        this.getSupplierList();
    }

    toggleAllSelection() {
        this.currentselectedWeatherObj.typeId = [];
        this.currentselectedWeatherObj.typeId.push(0);
    }

    sendEmailToSupplier(isSendEmail) {
        this.showSpinner = true;
        this.materialService.sendEmailToSupplierWithMaterial<ResponseBase>(this.tempList, isSendEmail)
            .subscribe((response: ResponseBase) => {
                if (response != null && isSendEmail == false) {
                    var data = response.result.replace("data:application/pdf;base64,", "");
                    this.showSpinner = true;
                    const dialogRef = this.dialog.open(PdfViewerModelComponent, {
                        height: '90%',
                        width: '90%',
                        data: {
                            base64: data,
                            filename: response.result.filename + '.pdf'
                        }
                    });
                    dialogRef.afterClosed().subscribe(result => {
                        if (result) {
                        }

                    });
                }
                this.tempList = [];
                this.showSpinner = false;
                if (isSendEmail) {
                    this.translate.get("MESSAGES.EmailSent").subscribe({
                        next: (res) => {
                            this.getSupplierList();
                            this.notificationsService.create(res);
                        },
                        error: err => {
                        }
                    });
                    this.commonService. checkEmailIsToBeSent();
                }
            },
                error => (err) => {
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });
                },
                () => {
                });

    }

    getIcon(icon) {
        return 'icon-' + icon;
    }

    selectProject() {
        const dialogRef = this.dialog.open(SelectProjectRequistionComponent, {
            height: "90%",
            width: "90%",
        });
        dialogRef.afterClosed().subscribe((result) => {
            this.projectAndRequistion = result;
            if (result) {
                // for (var i = 0; i < this.supplierList.length; i++) {
                //     for (var j = 0; j < this.supplierList[i].materialList.length; j++) {
                //         this.supplierList[i].materialList[j].projectNumber = result.sagNumber;
                //         this.supplierList[i].materialList[j].requisitionNo = result.requisitionNo;
                //     }
                // }
                
            }
        });
    }

    GetAllSupplier() {
        this.materialService.GetSupplierList<ResponseBase>(new SupplierRequest())
            .subscribe({
                next: (response: ResponseBase) => {
                    this.Suppliers = response.result;
                },
                error: err => {
                    this.Suppliers = [];
                },
                complete: () => { }
            });
    }
    GetMaterial(model) {
        model.Limit = 1000;
        model.OrderBy = "Text";
        this.materialService.Get<ResponseBase>(model)
            .subscribe((response: ResponseBase) => {
                if (response) {
                    this.getNextRequestForMaterial(response.pagination, model, response.result);
                }
                else {
                    this.Materials = [];
                }
            },
                error => (err) => {
                    this.Materials = [];
                },
                () => {
                });
    }

    getNextRequestForMaterial(pagination, model, response) {
        if (pagination.index == 1) {
            this.Materials = response;
        }
        else {
            this.Materials.push(...response);
        }
        model.Index = pagination.index + 1;
        model.Limit = pagination.limit;
        model.TotalRows = pagination.totalCount;
        if (pagination.totalCount > pagination.index * pagination.limit)
            this.GetMaterial(model);
    }

    addNewMaterial(index, supplierId) {
        if (this.supplierList[index].materialList && this.supplierList[index].materialList.length>0) {
            let newRow = Object.assign({ isNewMaterial: true, isChecked: true, quantity: 0, purchasePrice: 0 })
            this.supplierList[index].materialList = [newRow, ...this.supplierList[index].materialList];
        }
    }

    addNewItem(supplierId, itemList) {
        this.showSpinner = true;
        // for (let i = 0; i < itemList.materialList.length; i++) {
        //     this.Materials = this.Materials.filter(x => x.id != itemList.materialList[i].id);
        // }
        const dialogRef = this.dialog.open(AddOrderMaterialItemComponent, {
            height: "90%",
            width: "95%",
            disableClose: true,
            data: {
                Suppliers: this.Suppliers,
                Materials: this.Materials,
                SupplierId: supplierId
            }
        });
        dialogRef.afterClosed().subscribe((result) => {
            this.showSpinner = true;
            if (result.length > 0) {
                var supObj = this.supplierList.find(item => item.id == supplierId);
                if (supObj) {
                    var total = 0;
                    for (let i = 0; i < result.length; i++) {
                        var matObj = supObj.materialList.find(o2 => o2.id == result[i].id && result[i].colorCode == o2.colorCode);
                        if (!matObj) {
                            result[i].quantity = Math.ceil(((result[i].maxInventory - result[i].holdings) / result[i].purchaseQuantity) * result[i].purchaseQuantity);
                            result[i].quantity = result[i].quantity ? result[i].quantity : 0;
                            supObj.materialList.push(result[i]);
                        }
                    }
                    // supObj.materialList.push(...newMaterials);
                    supObj.materialList.filter(p => {
                        // p.quantity = Math.ceil(((p.maxInventory - p.holdings) / p.purchaseQuantity) * p.purchaseQuantity);
                        // p.quantity = p.quantity ? p.quantity : 0;
                        total = total + p.purchasePrice * p.quantity;
                    });
                    supObj.total = total;
                }
            }
            this.showSpinner = false;
        });
        //this._router.navigate(["/materials/add"], { queryParams: { supplierId: id } });
    }
}
