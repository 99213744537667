import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { OfferTypeWithRoomPriceDetail, OfferMaterialWithPrice, DiverseDTO, MaterialList, OfferRoomCalculationWithMaterialList, OfferRoomPriceDTO, OfferRoomPriceMatetialAllDTO, OfferMeterWisePrice, OfferDiverseList } from 'app/main/model/OfferV2';
import { MaterialTypeCategory, PaintSelectTime, OffMaterialTypeIds, TreatmentTypeIds, OfferRoomCalculationType, OfferTreatmentType } from 'app/constant/common.constant';
import { ApiConstant } from 'app/core/constant/ApiConstant';
import { NewMaterialComponent } from 'app/main/pages/material/new-material/new-material.component';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';
import { OfferAddressModel } from 'app/main/model/ProjectOffer';
import { ProjectofferService } from 'app/main/pages/project-offer/project-offer.service';

@Component({
    selector: 'offerV2-form-material-select',
    templateUrl: './material-select.component.html',
    styleUrls: ['./material-select.component.scss']
})
export class OfferV2FormMaterialSelectForAllComponent implements OnInit {
    showSpinner: boolean = false;
    materialList: OfferMaterialWithPrice[] = [];
    materialPriceListForPanels: any = [];
    materialPriceListForDoorFrame: any = [];
    materialPriceListForRadiator: any = [];
    materialPriceListForDore: any = [];
    materialListForPanels: OfferMaterialWithPrice[] = [];
    materialListForDoorFrame: OfferMaterialWithPrice[] = [];
    materialListForRadiator: OfferMaterialWithPrice[] = [];
    materialListForDore: OfferMaterialWithPrice[] = [];

    OfferTypeDetail: OfferTypeWithRoomPriceDetail;
    SelectCategoryTime = PaintSelectTime;
    selectMaterialOfferList = MaterialTypeCategory;
    MaterialTypeSearch = "";
    tempMaterialDetails: any = {};
    grandTotal: number = 0;
    selectedRoomOnEdit: OfferRoomPriceDTO = new OfferRoomPriceDTO();

    roomDetail: OfferRoomPriceMatetialAllDTO;
    tempRoomDetail: OfferRoomPriceMatetialAllDTO;
    matObj: any = [];
    offMaterialTypeIds = OffMaterialTypeIds;
    treatmentTypeIds = TreatmentTypeIds;
    offerRoomCalculationType = OfferRoomCalculationType;
    meterWisePrice: OfferMeterWisePrice = new OfferMeterWisePrice();
    projectOffer: OfferAddressModel = new OfferAddressModel();
    projectPriceDiscount: number = 0;
    customerDetailObj: any
    roomList = [];
    wallRoomList = [];
    celiRoomList = [];
    floorRoomList = [];
    diverseRoomList = [];
    MaterialTypeSearchForDiverse = "";
    offerTreatmentTypeList = OfferTreatmentType;
    constructor(private translationLoader: FuseTranslationLoaderService,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<OfferV2FormMaterialSelectForAllComponent>,
        private projectofferService: ProjectofferService,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
        this.roomDetail = new OfferRoomPriceMatetialAllDTO();
        this.materialList = this.data.materialList;
        this.OfferTypeDetail = this.data.offerTypeDetail;
        this.projectOffer = this.data.projectOffer;
        this.projectPriceDiscount = this.data.projectPriceDiscount;
        this.customerDetailObj = this.data.customerDetailObj;
        if (this.data.roomList.length > 0) {
            this.wallRoomList = this.data.roomList.map(x => ({ ...x, isChecked: true }));
            this.celiRoomList = this.data.roomList.map(x => ({ ...x, isChecked: true }));
            this.floorRoomList = this.data.roomList.map(x => ({ ...x, isChecked: true }));
            this.diverseRoomList = this.data.roomList.map(x => ({ ...x, isChecked: false }));
            this.wallRoomList.filter(room => { 
                room.totalArea = (((room.length + room.width) * 2) * room.ceilingHeight) + room.extraQty1 + room.extraQty2 + room.extraM2QtyWall;
            });
            this.celiRoomList.filter(room => {
                room.totalArea = (room.length * room.width) + room.extraQty1 + room.extraQty2 + room.extraM2QtyCeiling;
            });
            this.floorRoomList.filter(room => {
                room.totalArea = (room.length * room.width) + room.extraQty1 + room.extraQty2 + room.extraM2QtyFloor;
            });
        }
        this.tempRoomDetail = localStorage.getItem(ApiConstant.LocalRequestModelForMaterialAll) ? JSON.parse(localStorage.getItem(ApiConstant.LocalRequestModelForMaterialAll)) : null;
        if (this.tempRoomDetail) {
            this.roomDetail = this.tempRoomDetail;
        }
        else {
            for (var m = 1; m < 4; m++) {
                var materialObj = new MaterialList();
                if (m == 1) {
                    materialObj.offerMaterialTypeId = this.offMaterialTypeIds.WallType;
                    materialObj.materialList = this.materialList.filter(o => { return o.type.split(',').find(p => p == materialObj.offerMaterialTypeId) });
                    materialObj.roomList = this.wallRoomList.map(x => Object.assign({}, x));
                    materialObj.selectedRoomCount = materialObj.roomList.length;
                    this.roomDetail.wallMaterialAllList = [];
                    this.roomDetail.wallMaterialAllList.push(materialObj);
                }
                if (m == 2) {
                    materialObj.offerMaterialTypeId = this.offMaterialTypeIds.CeilingType;
                    materialObj.materialList = this.materialList.filter(o => { return o.type.split(',').find(p => p == materialObj.offerMaterialTypeId) });
                    materialObj.roomList = this.celiRoomList.map(x => Object.assign({}, x));
                    materialObj.selectedRoomCount = materialObj.roomList.length;
                    this.roomDetail.ceilingMaterialAllList = [];
                    this.roomDetail.ceilingMaterialAllList.push(materialObj);

                }
                if (m == 3) {
                    materialObj.offerMaterialTypeId = this.offMaterialTypeIds.FloorType;
                    materialObj.materialList = this.materialList.filter(o => { return o.type.split(',').find(p => p == materialObj.offerMaterialTypeId) });
                    materialObj.roomList = this.floorRoomList.map(x => Object.assign({}, x));
                    materialObj.selectedRoomCount = materialObj.roomList.length;
                    this.roomDetail.floorMaterialAllList = [];
                    this.roomDetail.floorMaterialAllList.push(materialObj);
                }
            }
            // this.roomDetail.panelerRoomList = this.diverseRoomList.map(x => Object.assign({}, x));
            // this.roomDetail.doorFrameRoomList = this.diverseRoomList.map(x => Object.assign({}, x));
            // this.roomDetail.radiatorRoomList = this.diverseRoomList.map(x => Object.assign({}, x));
            // this.roomDetail.doreRoomList = this.diverseRoomList.map(x => Object.assign({}, x));
        }
        // this.getPanelsMaterial(this.roomDetail.panelsMaterialTypeId);
        // this.getDoorFrameMaterial(this.roomDetail.doorFrameMaterialTypeId);
        // this.getRadiatorMaterial(this.roomDetail.radiatorMaterialTypeId);
        // this.getDoreMaterial(this.roomDetail.doreMaterialTypeId);
        if (this.tempRoomDetail) {
            // this.getPanelsMaterialPriceDetail('', true);
            // this.getDoorFrameMaterialPriceDetail('', true);
            // this.getRadiatorMaterialPriceDetail('', true);
            // this.getDoreMaterialPriceDetail('', true);
        }
        else{
            this.addDiverseItem(this.roomDetail.panelsMaterialTypeId);
            this.addDiverseItem(this.roomDetail.doorFrameMaterialTypeId);
            this.addDiverseItem(this.roomDetail.radiatorMaterialTypeId);
            //this.addDiverseItem(this.roomDetail.doreMaterialTypeId);
            this.addDiverseItem(this.roomDetail.coverMaterialTypeId);
            
        }
    }

    ok() {
        this.OfferTypeDetail.priceTotal = 0;
        this.OfferTypeDetail.price = 0;
        this.OfferTypeDetail.offerRoomDetailList.filter((room, i) => {
            room.totalPrice = 0;
            room.isExpand = false;
            room.wallTotalArea = 0;
            room.wallTotalPrice = 0;
            room.ceilingTotalArea = 0;
            room.ceilingTotalPrice = 0;
            room.floorTotalArea = 0;
            room.floorTotalPrice = 0;
            var wallMaterialAllList = this.roomDetail.wallMaterialAllList.filter(x => x.offerMaterialId != '' && x.offerMaterialId != undefined);
            if (wallMaterialAllList && wallMaterialAllList.length > 0) {
                room.offerRoomCalculation[0].materialList = [];
                this.roomDetail.wallMaterialAllList.filter(x => {
                    var ischeckRoomList = x.roomList.filter(tempRoom => tempRoom.id == room.offerRoomId && tempRoom.isChecked);
                    if (ischeckRoomList && ischeckRoomList.length > 0) {
                        if (x.offerMaterialId != '') {
                            var materialObj = new MaterialList();
                            materialObj = Object.assign({}, x);
                            materialObj.colorCode = x.colorCode;
                            if (x.offerMaterialPriceId && x.selectCategoryTime) {
                                var extraValue = x.selectCategoryTime.filter(t => t.priceId == x.offerMaterialPriceId)[0].extraValue;
                                materialObj.wallPaintText = extraValue;
                            }
                            room.offerRoomCalculation[0].materialList.push(materialObj);
                        }
                    }
                });
                this.calulateRoomDetails(room.offerRoomCalculation[0], this.offMaterialTypeIds.WallType, i);
            }
            // else
            //     this.clearItems(room.offerRoomCalculation[0]);

            var ceilingMaterialAllList = this.roomDetail.ceilingMaterialAllList.filter(x => x.offerMaterialId != '' && x.offerMaterialId != undefined);
            if (ceilingMaterialAllList && ceilingMaterialAllList.length > 0) {
                room.offerRoomCalculation[1].materialList = [];
                this.roomDetail.ceilingMaterialAllList.filter(x => {
                    var ischeckRoomList = x.roomList.filter(tempRoom => tempRoom.id == room.offerRoomId && tempRoom.isChecked);
                    if (ischeckRoomList && ischeckRoomList.length > 0) {
                        if (x.offerMaterialId != '') {
                            var materialObj = new MaterialList();
                            materialObj = Object.assign({}, x);
                            materialObj.colorCode = x.colorCode;
                            if (x.offerMaterialPriceId && x.selectCategoryTime) {
                                var extraValue = x.selectCategoryTime.filter(t => t.priceId == x.offerMaterialPriceId)[0].extraValue;
                                materialObj.wallPaintText = extraValue;
                            }
                            room.offerRoomCalculation[1].materialList.push(materialObj);
                        }
                    }
                });
                this.calulateRoomDetails(room.offerRoomCalculation[1], this.offMaterialTypeIds.CeilingType, i);
            }
            // else                    
            //     this.clearItems(room.offerRoomCalculation[1]);

            var floorMaterialAllList = this.roomDetail.floorMaterialAllList.filter(x => x.offerMaterialId != '' && x.offerMaterialId != undefined);
            if (floorMaterialAllList && floorMaterialAllList.length > 0) {
                if (room.offerRoomCalculation.length == 2)
                    this.addFloor(room);
                room.offerRoomCalculation[2].materialList = [];
                this.roomDetail.floorMaterialAllList.filter(x => {
                    var ischeckRoomList = x.roomList.filter(tempRoom => tempRoom.id == room.offerRoomId && tempRoom.isChecked);
                    if (ischeckRoomList && ischeckRoomList.length > 0) {
                        if (x.offerMaterialId != '') {
                            var materialObj = new MaterialList();
                            materialObj = Object.assign({}, x);
                            materialObj.colorCode = x.colorCode;
                            if (x.offerMaterialPriceId && x.selectCategoryTime) {
                                var extraValue = x.selectCategoryTime.filter(t => t.priceId == x.offerMaterialPriceId)[0].extraValue;
                                materialObj.wallPaintText = extraValue;
                            }
                            room.offerRoomCalculation[2].materialList.push(materialObj);
                        }
                    }
                });
                this.calulateRoomDetails(room.offerRoomCalculation[2], this.offMaterialTypeIds.FloorType, i);
            }
            // else {  
            //     if(room.offerRoomCalculation.length==3)                  
            //         this.clearItems(room.offerRoomCalculation[2]);
            // }         

            // if (this.roomDetail.isPaintPanels && this.roomDetail.materialIdForPanels) {
            //     var item = this.materialList.filter(x => { return x.id == this.roomDetail.materialIdForPanels && x.type.split(',').find(p => p == '11') });
            //     if (item.length > 0) {
            //         var data = this.OfferTypeDetail.offerDiverseDetailList.filter(x => x.materialID == item[0].id && x.roomId == room.offerRoomId && x.type == this.offMaterialTypeIds.Paneler && x.isMaterialAllItem == true);
            //         if (data.length > 0) {
            //             data[0].materialPriceID = this.roomDetail.materialPriceIdForPanels;
            //             data[0].price = this.roomDetail.panelsPrice;
            //             data[0].colorCode = this.roomDetail.PanelsColorCode;
            //             data[0].qty = this.roomDetail.materialQtyForPanels;
            //             if (data[0].materialID)
            //                 this.getMaterialPrice(data[0].materialID, data[0], data[0].materialPriceID)
            //         }
            //         else {
            //             var ischeckRoomList = this.roomDetail.panelerRoomList.filter(tempRoom => tempRoom.id == room.offerRoomId && tempRoom.isChecked);
            //             if (ischeckRoomList && ischeckRoomList.length > 0){
            //                 item[0].colorCode = this.roomDetail.PanelsColorCode;
            //                 this.addPanlesDoorframeRadiotorItem(item, room.offerRoomId, this.offMaterialTypeIds.Paneler, this.roomDetail.materialPriceIdForPanels, this.roomDetail.panelsPrice);
            //             }
            //             else {
            //                 var dataMatData = this.OfferTypeDetail.offerDiverseDetailList.filter(x => x.materialID == item[0].id && x.type == this.offMaterialTypeIds.Paneler && x.isMaterialAllItem == true);
            //                 if(dataMatData.length > 0){
            //                     dataMatData[0].materialPriceID = this.roomDetail.materialPriceIdForPanels;
            //                     dataMatData[0].price = this.roomDetail.panelsPrice;
            //                     dataMatData[0].colorCode = this.roomDetail.PanelsColorCode;
            //                     dataMatData[0].qty = this.roomDetail.materialQtyForPanels;
            //                     if (dataMatData[0].materialID)
            //                         this.getMaterialPrice(dataMatData[0].materialID, dataMatData[0], dataMatData[0].materialPriceID)
            //                 }
            //                 else{
            //                     var ischeckRoomList = this.roomDetail.panelerRoomList.filter(tempRoom => tempRoom.isChecked);
            //                     if(ischeckRoomList.length == 0)
            //                         this.addPanlesDoorframeRadiotorItem(item, "", this.offMaterialTypeIds.Paneler, this.roomDetail.materialPriceIdForPanels, this.roomDetail.panelsPrice);
            //                 }
            //             }                            
            //         }

            //     }
            // }

            // if (this.roomDetail.isPaintDoorFrame && this.roomDetail.materialIdForDoorFrame) {
            //     var item = this.materialList.filter(x => { return x.id == this.roomDetail.materialIdForDoorFrame && x.type.split(',').find(p => p == '12') });
            //     if (item.length > 0) {
            //         var data = this.OfferTypeDetail.offerDiverseDetailList.filter(x => x.materialID == item[0].id && x.roomId == room.offerRoomId && x.type == this.offMaterialTypeIds.Dorkarm && x.isMaterialAllItem == true);
            //         if (data.length > 0) {
            //             data[0].materialPriceID = this.roomDetail.materialPriceIdForDoorFrame;
            //             data[0].price = this.roomDetail.doorFramePrice;
            //             data[0].colorCode = this.roomDetail.doorFrameColorCode;
            //             data[0].qty = this.roomDetail.materialQtyForDoorFrame;
            //             if (data[0].materialID)
            //                 this.getMaterialPrice(data[0].materialID, data[0], data[0].materialPriceID)
            //         }
            //         else {
            //             var ischeckRoomList = this.roomDetail.doorFrameRoomList.filter(tempRoom => tempRoom.id == room.offerRoomId && tempRoom.isChecked);
            //             if (ischeckRoomList && ischeckRoomList.length > 0){
            //                 item[0].colorCode = this.roomDetail.doorFrameColorCode;
            //                 this.addPanlesDoorframeRadiotorItem(item, room.offerRoomId, this.offMaterialTypeIds.Dorkarm, this.roomDetail.materialPriceIdForDoorFrame, this.roomDetail.doorFramePrice);
            //             }
            //             else {
            //                 var dataMatData = this.OfferTypeDetail.offerDiverseDetailList.filter(x => x.materialID == item[0].id && x.type == this.offMaterialTypeIds.Dorkarm && x.isMaterialAllItem == true);
            //                 if(dataMatData.length > 0){
            //                     dataMatData[0].materialPriceID = this.roomDetail.materialPriceIdForDoorFrame;
            //                     dataMatData[0].price = this.roomDetail.doorFramePrice;
            //                     dataMatData[0].colorCode = this.roomDetail.doorFrameColorCode;
            //                     dataMatData[0].qty = this.roomDetail.materialQtyForDoorFrame;
            //                     if (dataMatData[0].materialID)
            //                         this.getMaterialPrice(dataMatData[0].materialID, dataMatData[0], dataMatData[0].materialPriceID)
            //                 }
            //                 else{
            //                     var ischeckRoomList = this.roomDetail.doorFrameRoomList.filter(tempRoom => tempRoom.isChecked);
            //                     if(ischeckRoomList.length == 0)
            //                         this.addPanlesDoorframeRadiotorItem(item, "", this.offMaterialTypeIds.Dorkarm, this.roomDetail.materialPriceIdForDoorFrame, this.roomDetail.doorFramePrice);
            //                 }
            //             }                             
            //         }
            //     }
            // }

            // if (this.roomDetail.isPaintRadiator && this.roomDetail.materialIdForRadiator) {
            //     var item = this.materialList.filter(x => { return x.id == this.roomDetail.materialIdForRadiator && x.type.split(',').find(p => p == '16') });
            //     if (item.length > 0) {
            //         var data = this.OfferTypeDetail.offerDiverseDetailList.filter(x => x.materialID == item[0].id && x.roomId == room.offerRoomId && x.type == this.offMaterialTypeIds.Radiator && x.isMaterialAllItem == true);
            //         if (data.length > 0) {
            //             data[0].materialPriceID = this.roomDetail.materialPriceIdForRadiator;
            //             data[0].price = this.roomDetail.radiatorPrice;
            //             data[0].colorCode = this.roomDetail.radiatorColorCode;
            //             data[0].qty = this.roomDetail.materialQtyForRadiator;
            //             if (data[0].materialID)
            //                 this.getMaterialPrice(data[0].materialID, data[0], data[0].materialPriceID)
            //         }
            //         else {
            //             var ischeckRoomList = this.roomDetail.radiatorRoomList.filter(tempRoom => tempRoom.id == room.offerRoomId && tempRoom.isChecked);
            //             if (ischeckRoomList && ischeckRoomList.length > 0){
            //                 item[0].colorCode = this.roomDetail.radiatorColorCode;
            //                 this.addPanlesDoorframeRadiotorItem(item, room.offerRoomId, this.offMaterialTypeIds.Radiator, this.roomDetail.materialPriceIdForRadiator, this.roomDetail.radiatorPrice);
            //             }
            //             else {
            //                 var dataMatData = this.OfferTypeDetail.offerDiverseDetailList.filter(x => x.materialID == item[0].id && x.type == this.offMaterialTypeIds.Radiator && x.isMaterialAllItem == true);
            //                 if(dataMatData.length > 0){
            //                     dataMatData[0].materialPriceID = this.roomDetail.materialPriceIdForRadiator;
            //                     dataMatData[0].price = this.roomDetail.radiatorPrice;
            //                     dataMatData[0].colorCode = this.roomDetail.radiatorColorCode;
            //                     dataMatData[0].qty = this.roomDetail.materialQtyForRadiator;
            //                     if (dataMatData[0].materialID)
            //                         this.getMaterialPrice(dataMatData[0].materialID, dataMatData[0], dataMatData[0].materialPriceID)
            //                 }
            //                 else {
            //                     var ischeckRoomList = this.roomDetail.radiatorRoomList.filter(tempRoom => tempRoom.isChecked);
            //                     if(ischeckRoomList.length == 0)
            //                         this.addPanlesDoorframeRadiotorItem(item, "", this.offMaterialTypeIds.Radiator, this.roomDetail.materialPriceIdForRadiator, this.roomDetail.radiatorPrice);
            //                 }
            //             }                              
            //         }
            //     }
            // }

            // if (this.roomDetail.isPaintDore && this.roomDetail.materialIdForDore) {
            //     var item = this.materialList.filter(x => { return x.id == this.roomDetail.materialIdForDore && x.type.split(',').find(p => p == '4') });
            //     if (item.length > 0) {
            //         var data = this.OfferTypeDetail.offerDiverseDetailList.filter(x => x.materialID == item[0].id && x.roomId == room.offerRoomId && x.type == this.offMaterialTypeIds.Dore && x.isMaterialAllItem == true);
            //         if (data.length > 0) {
            //             data[0].materialPriceID = this.roomDetail.materialPriceIdForDore;
            //             data[0].price = this.roomDetail.dorePrice;
            //             data[0].colorCode = this.roomDetail.doreColorCode;
            //             data[0].qty = this.roomDetail.materialQtyForDore;
            //             if (data[0].materialID)
            //                 this.getMaterialPrice(data[0].materialID, data[0], data[0].materialPriceID)
            //         }
            //         else {
            //             var ischeckRoomList = this.roomDetail.doreRoomList.filter(tempRoom => tempRoom.id == room.offerRoomId && tempRoom.isChecked);
            //             if (ischeckRoomList && ischeckRoomList.length > 0){
            //                 item[0].colorCode = this.roomDetail.doreColorCode;
            //                 this.addPanlesDoorframeRadiotorItem(item, room.offerRoomId, this.offMaterialTypeIds.Dore, this.roomDetail.materialPriceIdForDore, this.roomDetail.dorePrice);
            //             }
            //             else {
            //                 var dataMatData = this.OfferTypeDetail.offerDiverseDetailList.filter(x => x.materialID == item[0].id && x.type == this.offMaterialTypeIds.Dore && x.isMaterialAllItem == true);
            //                 if(dataMatData.length > 0){
            //                     dataMatData[0].materialPriceID = this.roomDetail.materialPriceIdForDore;
            //                     dataMatData[0].price = this.roomDetail.dorePrice;
            //                     dataMatData[0].colorCode = this.roomDetail.doreColorCode;
            //                     dataMatData[0].qty = this.roomDetail.materialQtyForDore;
            //                     if (dataMatData[0].materialID)
            //                         this.getMaterialPrice(dataMatData[0].materialID, dataMatData[0], dataMatData[0].materialPriceID)
            //                 }
            //                 else {
            //                     var ischeckRoomList = this.roomDetail.doreRoomList.filter(tempRoom => tempRoom.isChecked);
            //                     if(ischeckRoomList.length == 0)
            //                         this.addPanlesDoorframeRadiotorItem(item, "", this.offMaterialTypeIds.Dore, this.roomDetail.materialPriceIdForDore, this.roomDetail.dorePrice);
            //                 }
            //             }                              
            //         }
            //     }
            // }
            if (this.roomDetail.offerDiverseList) {
                this.roomDetail.offerDiverseList.filter(item => {
                    if(item.materialID){
                        var tempItem = [];
                        item.id = item.materialID;
                        tempItem.push(item);
                        var ischeckRoomList = item.roomList.filter(tempRoom => tempRoom.id == room.offerRoomId && tempRoom.isChecked);
                        if (ischeckRoomList && ischeckRoomList.length > 0) {
                            var data = this.OfferTypeDetail.offerDiverseDetailList.filter(x => x.materialID == item.materialID && x.roomId == room.offerRoomId && x.type == item.type && x.isMaterialAllItem == true);
                            if (data.length > 0) {
                                data[0].materialPriceID = item.materialPriceID;
                                data[0].price = item.price;
                                data[0].colorCode = item.colorCode;
                                data[0].treatmentType = item.treatmentType;
                                if(item.treatmentType == 0)
                                    data[0].qty = item.qty;
                                if (data[0].materialID)
                                    this.getMaterialPrice(data[0].materialID, data[0], data[0].materialPriceID)
                            }
                            else
                                this.addPanlesDoorframeRadiotorItem(tempItem, room.offerRoomId, item.type, item.materialPriceID, item.price);
                        }
                        else {
                            var dataMatData = this.OfferTypeDetail.offerDiverseDetailList.filter(x => x.materialID == item.materialID && x.type == item.type && x.isMaterialAllItem == true);
                            if(dataMatData.length > 0){
                                dataMatData[0].materialPriceID = item.materialPriceID;
                                dataMatData[0].price = item.price;
                                dataMatData[0].colorCode = item.colorCode;
                                if(item.treatmentType == 0)
                                    dataMatData[0].qty = item.qty
                                dataMatData[0].treatmentType = item.treatmentType;
                                if (dataMatData[0].materialID)
                                    this.getMaterialPrice(dataMatData[0].materialID, dataMatData[0], dataMatData[0].materialPriceID)
                            }
                            else {
                                var ischeckDiverseRoomList = item.roomList.filter(tempRoom => tempRoom.isChecked);
                                if(ischeckDiverseRoomList.length == 0)
                                    this.addPanlesDoorframeRadiotorItem(tempItem, "", item.type, item.materialPriceID, item.price);
                            }
                        }
                    }  
                });
            }
            localStorage.setItem(ApiConstant.LocalRequestModelForMaterialAll, JSON.stringify(this.roomDetail));
        })
        this.dialogRef.close(this.OfferTypeDetail)
    }

    close() {
        this.dialogRef.close(null)
    }

    getMaterialPrice(materialId, offerItem, materialPriceId) {
        var materialprice = this.materialList.find(o => o.id == materialId);
        if (materialprice) {
            offerItem.imagePath = materialprice.imagePath;
            offerItem.selectCategoryTimeForDiverse = [];
            offerItem.notCalculateToDriving = materialprice.notCalculateToDriving;
            for (var i = 0; i < materialprice.offerMaterialPriceList.length; i++) {
                var obj = this.SelectCategoryTime.find(o => o.id == materialprice.offerMaterialPriceList[i].type);
                if (obj) {
                    if (i == 0) {
                        offerItem.materialPriceID = materialprice.offerMaterialPriceList[i].id;
                        //offerItem.price = materialprice.offerMaterialPriceList[i].price;
                        offerItem.imagePath;
                        offerItem.colorCode = offerItem.colorCode;
                    }
                    offerItem.selectCategoryTimeForDiverse.push({
                        id: obj.id,
                        key: obj.key,
                        extraValue: materialprice.offerMaterialPriceList[i].paintText,
                        priceId: materialprice.offerMaterialPriceList[i].id,
                        reminder: materialprice.offerMaterialPriceList[i].reminder,
                    });
                    if (materialprice.offerMaterialPriceList[i].id == materialPriceId) {
                        offerItem.materialPriceID = materialprice.offerMaterialPriceList[i].id;
                        //offerItem.price = materialprice.offerMaterialPriceList[i].price;
                    }
                    else {
                        //offerItem.text = materialPriceId ? offerItem.text : materialprice.text;
                        offerItem.text = materialPriceId ? offerItem.text : materialprice.text + ' ' + materialprice.offerMaterialPriceText;
                        offerItem.colorCode = offerItem.colorCode ? offerItem.colorCode : materialprice.colorCode;
                        offerItem.unit = materialprice.unit;
                    }

                    if (materialprice.offerMaterialPriceList[i].isDefault && materialPriceId == "") {
                        offerItem.materialPriceID = materialprice.offerMaterialPriceList[i].id;
                        //offerItem.price = materialprice.offerMaterialPriceList[i].price;
                    }
                    offerItem.reminder = materialprice.offerMaterialPriceList[i].reminder ? materialprice.offerMaterialPriceList[i].reminder : "";
                    this.getOfferDiverseMeterPrice(offerItem);
                }
            }
        }
    }
    addPanlesDoorframeRadiotorItem(offerMaterialList, roomId, type, materialPriceID, price) {
        this.tempMaterialDetails = offerMaterialList[0];
        var roomDetail = this.OfferTypeDetail.offerRoomDetailList.filter(x => x.offerRoomId == roomId)
        if (!this.OfferTypeDetail.offerDiverseDetailList) {
            this.OfferTypeDetail.offerDiverseDetailList = [];
        }
        var offerItem = new DiverseDTO();
        offerItem.offerCategoryId = this.OfferTypeDetail.offerCategoryId;
        offerItem.offerId = this.OfferTypeDetail.offerId;
        offerItem.roomId = roomId;
        //offerItem.text = this.tempMaterialDetails.text;
        offerItem.text = this.tempMaterialDetails.text + ' ' + (this.tempMaterialDetails.offerMaterialPriceText ? this.tempMaterialDetails.offerMaterialPriceText : "");
        offerItem.type = type;
        offerItem.materialID = this.tempMaterialDetails.id;
        offerItem.materialPriceID = materialPriceID;
        offerItem.colorCode = this.tempMaterialDetails.colorCode;
        offerItem.unit = this.tempMaterialDetails.unit;
        offerItem.treatmentType = this.tempMaterialDetails.treatmentType;       
        var tempQty = this.tempMaterialDetails.qty && this.tempMaterialDetails.qty > 0 ? this.tempMaterialDetails.qty : 1;
        if (this.tempMaterialDetails && roomDetail && roomDetail.length > 0) {
            if (this.tempMaterialDetails.treatmentType == this.treatmentTypeIds.WoodWork)
                tempQty = (roomDetail[0].offerRoomCalculation[0].width + roomDetail[0].offerRoomCalculation[0].length + roomDetail[0].offerRoomCalculation[0].width + roomDetail[0].offerRoomCalculation[0].length + roomDetail[0].offerRoomCalculation[0].extraM2QtyFloor);
            if (this.tempMaterialDetails.treatmentType == this.treatmentTypeIds.Walls)
                tempQty = (((roomDetail[0].offerRoomCalculation[0].width + roomDetail[0].offerRoomCalculation[0].length) * 2) * roomDetail[0].offerRoomCalculation[0].ceilingHeight + roomDetail[0].offerRoomCalculation[0].extraM2QtyWall);
            if (this.tempMaterialDetails.treatmentType == this.treatmentTypeIds.Ceiling)
                tempQty = (roomDetail[0].offerRoomCalculation[0].width * roomDetail[0].offerRoomCalculation[0].length + roomDetail[0].offerRoomCalculation[0].extraM2QtyCeiling);
            offerItem.width = roomDetail[0].offerRoomCalculation[0].width;
            offerItem.length = roomDetail[0].offerRoomCalculation[0].length;
        }
        offerItem.qty = tempQty; //(this.tempMaterialDetails && this.tempMaterialDetails.isAddedWoodwork ? (this.selectedRoomOnEdit.wallWidth + this.selectedRoomOnEdit.wallLength + this.selectedRoomOnEdit.ceilingWidth + this.selectedRoomOnEdit.ceilingLength) : 1);
                offerItem.materialList = offerMaterialList && offerMaterialList[0].materialList.length > 0 ? offerMaterialList[0].materialList : [];
        offerItem.isMaterialAllItem = true;
        offerItem.price = price;
        if (roomDetail && roomDetail.length > 0)
            offerItem.roomName = roomDetail[0].name;
        this.OfferTypeDetail.offerDiverseDetailList.push(offerItem);
        if (offerItem.materialID)
            this.getMaterialPrice(offerItem.materialID, offerItem, offerItem.materialPriceID)
    }

    getOfferDiverseMeterPrice(offerItem) {
        var selectedMaterial = this.materialList.find(o => o.id == offerItem.materialID);
        if (selectedMaterial) {
            var selectedPrice = selectedMaterial.offerMaterialPriceList.find(o => o.id == offerItem.materialPriceID);
            if (selectedPrice) {
                var price = selectedPrice.price;
                if (selectedPrice.isAddMeterRangePrice) {
                    if (offerItem.materialPriceID) {
                        if (offerItem.qty >= selectedPrice.startMeter1 && offerItem.qty <= selectedPrice.endMeter1) {
                            price = selectedPrice.priceMeter1;
                        }
                        else if (offerItem.qty >= selectedPrice.startMeter2 && offerItem.qty <= selectedPrice.endMeter2) {
                            price = selectedPrice.priceMeter2;
                        }
                        else if (offerItem.qty >= selectedPrice.startMeter3 && offerItem.qty <= selectedPrice.endMeter3) {
                            price = selectedPrice.priceMeter3;
                        }
                        else {
                            price = 0; //selectedPrice.price;
                        }
                    }
                }
                offerItem.reminder = selectedPrice.reminder ? selectedPrice.reminder : "";
                offerItem.price = offerItem.price > 0 ? offerItem.price : price;
                this.calculateDiversePrise();
            }
        }
    }

    calculateDiversePrise() {
        this.grandTotal = 0;
        this.OfferTypeDetail.priceTotal = 0;
        var totalByRoom = 0
        if (!this.OfferTypeDetail.offerRoomDetailList) {
            this.OfferTypeDetail.offerRoomDetailList = [];
        }
        if (this.OfferTypeDetail.offerRoomDetailList.length > 0) {
            this.OfferTypeDetail.offerRoomDetailList.filter((item) => {
                totalByRoom = totalByRoom + item.totalPrice;
            });
        }
        this.OfferTypeDetail.priceTotal = totalByRoom;
        for (var i = 0; i < this.OfferTypeDetail.offerDiverseDetailList.length; i++) {
            this.OfferTypeDetail.offerDiverseDetailList[i].priceTotal = this.OfferTypeDetail.offerDiverseDetailList[i].qty * this.OfferTypeDetail.offerDiverseDetailList[i].price;
            if (this.OfferTypeDetail.offerDiverseDetailList[i].priceTotal > 0) {
                this.grandTotal = this.grandTotal + this.OfferTypeDetail.offerDiverseDetailList[i].priceTotal;
            }
            else {
                this.OfferTypeDetail.offerDiverseDetailList[i].priceTotal = 0;
            }
        }
        this.OfferTypeDetail.priceTotal = this.OfferTypeDetail.priceTotal + this.grandTotal;
        this.calculateDiscountedAmt();
    }

    calculateDiscountedAmt() {
        this.OfferTypeDetail.price = this.OfferTypeDetail.priceTotal - this.OfferTypeDetail.discount;
        this.calculateVatAmt();
    }
    calculateVatAmt() {
        this.OfferTypeDetail.vat = (this.OfferTypeDetail.priceTotal * 25 / 100);
        this.OfferTypeDetail.totalInclVAT = (this.OfferTypeDetail.priceTotal + (this.OfferTypeDetail.priceTotal * 25 / 100));
    }

    // getPanelsMaterial(type) {
    //     if (type == 0)
    //         this.materialListForPanels = this.materialList;
    //     else
    //         this.materialListForPanels = this.materialList.filter(o => { return o.type.split(',').find(p => p == type) });
    // }

    // getDoorFrameMaterial(type) {
    //     if (type == 0)
    //         this.materialListForDoorFrame = this.materialList;
    //     else
    //         this.materialListForDoorFrame = this.materialList.filter(o => { return o.type.split(',').find(p => p == type) });
    // }

    // getRadiatorMaterial(type) {
    //     if (type == 0)
    //         this.materialListForRadiator = this.materialList;
    //     else
    //         this.materialListForRadiator = this.materialList.filter(o => { return o.type.split(',').find(p => p == type) });
    // }

    // getDoreMaterial(type) {
    //     if (type == 0)
    //         this.materialListForDore = this.materialList;
    //     else
    //         this.materialListForDore = this.materialList.filter(o => { return o.type.split(',').find(p => p == type) });
    // }

    // panelsMaterialTypeOnClearSearch(value) {
    //     if (!value) {
    //         this.panelsMaterialTypeDropdownSearch();
    //     }
    // }
    // panelsMaterialTypeDropdownSearch() {
    //     if (this.roomDetail.panelsMaterialTypeId) {
    //         this.materialListForPanels = this.materialList.filter(o => { return o.type.split(',').find(p => p == this.roomDetail.panelsMaterialTypeId.toString() && o.text.toLowerCase().includes(this.MaterialTypeSearch.toLowerCase())) });
    //     }
    //     else
    //         this.materialListForPanels = this.materialList.filter(o => o.text.toLowerCase().includes(this.MaterialTypeSearch.toLowerCase()));
    // }

    // doorFrameMaterialTypeOnClearSearch(value) {
    //     if (!value) {
    //         this.doorFrameMaterialTypeDropdownSearch();
    //     }
    // }
    // doorFrameMaterialTypeDropdownSearch() {
    //     if (this.roomDetail.doorFrameMaterialTypeId) {
    //         this.materialListForDoorFrame = this.materialList.filter(o => { return o.type.split(',').find(p => p == this.roomDetail.doorFrameMaterialTypeId.toString() && o.text.toLowerCase().includes(this.MaterialTypeSearch.toLowerCase())) });
    //     }
    //     else
    //         this.materialListForDoorFrame = this.materialList.filter(o => o.text.toLowerCase().includes(this.MaterialTypeSearch.toLowerCase()));
    // }

    // radiatorMaterialTypeOnClearSearch(value) {
    //     if (!value) {
    //         this.radiatorMaterialTypeDropdownSearch();
    //     }
    // }
    // radiatorMaterialTypeDropdownSearch() {
    //     if (this.roomDetail.radiatorMaterialTypeId) {
    //         this.materialListForRadiator = this.materialList.filter(o => { return o.type.split(',').find(p => p == this.roomDetail.radiatorMaterialTypeId.toString() && o.text.toLowerCase().includes(this.MaterialTypeSearch.toLowerCase())) });
    //     }
    //     else
    //         this.materialListForRadiator = this.materialList.filter(o => o.text.toLowerCase().includes(this.MaterialTypeSearch.toLowerCase()));
    // }

    // doreMaterialTypeOnClearSearch(value) {
    //     if (!value) {
    //         this.doreMaterialTypeDropdownSearch();
    //     }
    // }
    // doreMaterialTypeDropdownSearch() {
    //     if (this.roomDetail.doreMaterialTypeId) {
    //         this.materialListForDore = this.materialList.filter(o => { return o.type.split(',').find(p => p == this.roomDetail.doreMaterialTypeId.toString() && o.text.toLowerCase().includes(this.MaterialTypeSearch.toLowerCase())) });
    //     }
    //     else
    //         this.materialListForDore = this.materialList.filter(o => o.text.toLowerCase().includes(this.MaterialTypeSearch.toLowerCase()));
    // }

    // getPanelsMaterialPriceDetail(materialPriceId, isEdit) {
    //     if (this.roomDetail.materialIdForPanels) {
    //         var materialprice = this.materialListForPanels.find(o => o.id == this.roomDetail.materialIdForPanels);
    //         this.roomDetail.PanelsColorCode = this.roomDetail.PanelsColorCode ? this.roomDetail.PanelsColorCode : materialprice.colorCode;
    //         //this.roomDetail.panelsPrice =
    //         this.materialPriceListForPanels = [];
    //         for (var i = 0; i < materialprice.offerMaterialPriceList.length; i++) {
    //             var obj = this.SelectCategoryTime.find(o => o.id == materialprice.offerMaterialPriceList[i].type);
    //             if (obj) {
    //                 if (i == 0 && !this.roomDetail.materialPriceIdForPanels)
    //                     this.roomDetail.materialPriceIdForPanels = materialprice.offerMaterialPriceList[i].id;
    //                 //this.roomDetail.panelsPrice = materialprice.offerMaterialPriceList[i].price;
    //                 this.materialPriceListForPanels.push({
    //                     id: obj.id,
    //                     key: obj.key,
    //                     extraValue: materialprice.offerMaterialPriceList[i].paintText,
    //                     priceId: materialprice.offerMaterialPriceList[i].id
    //                 });
    //                 // if (materialprice.offerMaterialPriceList[i].id == this.roomDetail.materialPriceIdForPanels && materialPriceId == "")
    //                 //     this.roomDetail.materialPriceIdForPanels = materialprice.offerMaterialPriceList[i].id;
    //                 if (materialprice.offerMaterialPriceList[i].id == materialPriceId) {
    //                     this.roomDetail.materialPriceIdForPanels = materialprice.offerMaterialPriceList[i].id;
    //                     this.roomDetail.panelsPrice = materialprice.offerMaterialPriceList[i].price;
    //                 }
    //                 if (materialprice.offerMaterialPriceList[i].isDefault && materialPriceId == "") {
    //                     this.roomDetail.materialPriceIdForPanels = materialprice.offerMaterialPriceList[i].id;
    //                     this.roomDetail.panelsPrice = materialprice.offerMaterialPriceList[i].price;
    //                 }
    //             }
    //         }
    //         this.getPriceForPanelDoorRadiator('Panels', this.roomDetail.materialIdForPanels, this.roomDetail.materialPriceIdForPanels, this.roomDetail.panelsQty, isEdit);
    //     }
    // }

    // getPriceForPanelDoorRadiator(type, materialID, materialPriceId, qty, isEdit) {
    //     if (materialID) {
    //         var selectedMaterial = this.materialList.find(o => o.id == materialID);
    //     }
    //     if (selectedMaterial) {
    //         var selectedPrice = selectedMaterial.offerMaterialPriceList.find(o => o.id == materialPriceId);
    //         if (selectedPrice) {
    //             var price = selectedPrice.price;
    //             if (selectedPrice.isAddMeterRangePrice) {
    //                 if (materialPriceId) {
    //                     if (qty >= selectedPrice.startMeter1 && qty <= selectedPrice.endMeter1) {
    //                         price = selectedPrice.priceMeter1;
    //                     }
    //                     else if (qty >= selectedPrice.startMeter2 && qty <= selectedPrice.endMeter2) {
    //                         price = selectedPrice.priceMeter2;
    //                     }
    //                     else if (qty >= selectedPrice.startMeter3 && qty <= selectedPrice.endMeter3) {
    //                         price = selectedPrice.priceMeter3;
    //                     }
    //                     else {
    //                         price = 0; //selectedPrice.price;
    //                     }
    //                 }
    //             }
    //             if (type === "Panels") {
    //                 this.roomDetail.panelsPrice = isEdit ? this.roomDetail.panelsPrice : price;
    //                 if (!isEdit && selectedMaterial.isPostNumberDiscount && this.projectOffer && this.projectOffer.percentage != 0 && !this.customerDetailObj.doNotCalculatePriceForPostNumber)
    //                     this.roomDetail.panelsPrice = this.roomDetail.panelsPrice + ((this.roomDetail.panelsPrice * this.projectOffer.percentage) / 100);
    //                 if (!isEdit && this.projectPriceDiscount && !this.customerDetailObj.doNotCalculatePriceForPriceDiscount)
    //                     this.roomDetail.panelsPrice = this.roomDetail.panelsPrice + ((this.roomDetail.panelsPrice * this.projectPriceDiscount) / 100);
    //             }
    //             if (type === "DoorFrame") {
    //                 this.roomDetail.doorFramePrice = isEdit ? this.roomDetail.doorFramePrice : price;
    //                 if (!isEdit && selectedMaterial.isPostNumberDiscount && this.projectOffer && this.projectOffer.percentage != 0 && !this.customerDetailObj.doNotCalculatePriceForPostNumber)
    //                     this.roomDetail.doorFramePrice = this.roomDetail.doorFramePrice + ((this.roomDetail.doorFramePrice * this.projectOffer.percentage) / 100);
    //                 if (!isEdit && this.projectPriceDiscount && !this.customerDetailObj.doNotCalculatePriceForPriceDiscount)
    //                     this.roomDetail.doorFramePrice = this.roomDetail.doorFramePrice + ((this.roomDetail.doorFramePrice * this.projectPriceDiscount) / 100);
    //             }
    //             if (type === "Radiator") {
    //                 this.roomDetail.radiatorPrice = isEdit ? this.roomDetail.radiatorPrice : price;
    //                 if (!isEdit && selectedMaterial.isPostNumberDiscount && this.projectOffer && this.projectOffer.percentage != 0 && !this.customerDetailObj.doNotCalculatePriceForPostNumber)
    //                     this.roomDetail.radiatorPrice = this.roomDetail.radiatorPrice + ((this.roomDetail.radiatorPrice * this.projectOffer.percentage) / 100);
    //                 if (!isEdit && this.projectPriceDiscount && !this.customerDetailObj.doNotCalculatePriceForPriceDiscount)
    //                     this.roomDetail.radiatorPrice = this.roomDetail.radiatorPrice + ((this.roomDetail.radiatorPrice * this.projectPriceDiscount) / 100);
    //             }
    //             if (type === "Dore") {
    //                 this.roomDetail.dorePrice = isEdit ? this.roomDetail.dorePrice : price;
    //                 if (!isEdit && selectedMaterial.isPostNumberDiscount && this.projectOffer && this.projectOffer.percentage != 0 && !this.customerDetailObj.doNotCalculatePriceForPostNumber)
    //                     this.roomDetail.dorePrice = this.roomDetail.dorePrice + ((this.roomDetail.dorePrice * this.projectOffer.percentage) / 100);
    //                 if (!isEdit && this.projectPriceDiscount && !this.customerDetailObj.doNotCalculatePriceForPriceDiscount)
    //                     this.roomDetail.dorePrice = this.roomDetail.dorePrice + ((this.roomDetail.dorePrice * this.projectPriceDiscount) / 100);
    //             }
    //             //this.calculateDiversePrise();
    //         }
    //     }
    // }

    // getPanelsMaterialPrice(materialPriceId) {
    //     this.getPanelsMaterialPriceDetail(materialPriceId, null);
    //     if (materialPriceId && this.roomDetail && this.materialListForPanels) {
    //         this.materialListForPanels.filter(mat => {
    //             if (mat.id == this.roomDetail.materialIdForPanels) {
    //                 var tempPriceData = mat.offerMaterialPriceList.filter(x => x.id == materialPriceId);
    //                 if (tempPriceData && tempPriceData.length > 0) {
    //                     mat.offerMaterialPriceText = tempPriceData[0].paintText;
    //                 }
    //             }
    //         })
    //     }
    // }

    // getDoorFrameMaterialPriceDetail(materialPriceId, isEdit) {
    //     if (this.roomDetail.materialIdForDoorFrame) {
    //         var materialprice = this.materialListForDoorFrame.find(o => o.id == this.roomDetail.materialIdForDoorFrame);
    //         this.roomDetail.doorFrameColorCode = this.roomDetail.doorFrameColorCode ? this.roomDetail.doorFrameColorCode : materialprice.colorCode;
    //         this.materialPriceListForDoorFrame = [];
    //         for (var i = 0; i < materialprice.offerMaterialPriceList.length; i++) {
    //             var obj = this.SelectCategoryTime.find(o => o.id == materialprice.offerMaterialPriceList[i].type);
    //             if (obj) {
    //                 if (i == 0 && !this.roomDetail.materialPriceIdForDoorFrame)
    //                     this.roomDetail.materialPriceIdForDoorFrame = materialprice.offerMaterialPriceList[i].id;
    //                 this.materialPriceListForDoorFrame.push({
    //                     id: obj.id,
    //                     key: obj.key,
    //                     extraValue: materialprice.offerMaterialPriceList[i].paintText,
    //                     priceId: materialprice.offerMaterialPriceList[i].id
    //                 });
    //                 if (materialprice.offerMaterialPriceList[i].id == materialPriceId) {
    //                     this.roomDetail.materialPriceIdForDoorFrame = materialprice.offerMaterialPriceList[i].id;
    //                     this.roomDetail.doorFramePrice = materialprice.offerMaterialPriceList[i].price;
    //                 }

    //                 if (materialprice.offerMaterialPriceList[i].isDefault && materialPriceId == "") {
    //                     this.roomDetail.materialPriceIdForDoorFrame = materialprice.offerMaterialPriceList[i].id;
    //                     this.roomDetail.doorFramePrice = materialprice.offerMaterialPriceList[i].price;
    //                 }
    //             }
    //         }
    //         this.getPriceForPanelDoorRadiator("DoorFrame", this.roomDetail.materialIdForDoorFrame, this.roomDetail.materialPriceIdForDoorFrame, this.roomDetail.doorFrameQty, isEdit)
    //     }
    // }

    // getDoorFrameMaterialPrice(materialPriceId) {
    //     this.getDoorFrameMaterialPriceDetail(materialPriceId, null);
    //     if (materialPriceId && this.roomDetail && this.materialListForDoorFrame) {
    //         this.materialListForDoorFrame.filter(mat => {
    //             if (mat.id == this.roomDetail.materialIdForDoorFrame) {
    //                 var tempPriceData = mat.offerMaterialPriceList.filter(x => x.id == materialPriceId);
    //                 if (tempPriceData && tempPriceData.length > 0) {
    //                     mat.offerMaterialPriceText = tempPriceData[0].paintText;
    //                 }
    //             }
    //         })
    //     }
    // }

    // getRadiatorMaterialPriceDetail(materialPriceId, isEdit) {
    //     if (this.roomDetail.materialIdForRadiator) {
    //         var materialprice = this.materialListForRadiator.find(o => o.id == this.roomDetail.materialIdForRadiator);
    //         this.roomDetail.radiatorColorCode = this.roomDetail.radiatorColorCode ? this.roomDetail.radiatorColorCode : materialprice.colorCode;
    //         this.materialPriceListForRadiator = [];
    //         for (var i = 0; i < materialprice.offerMaterialPriceList.length; i++) {
    //             var obj = this.SelectCategoryTime.find(o => o.id == materialprice.offerMaterialPriceList[i].type);
    //             if (obj) {
    //                 if (i == 0 && !this.roomDetail.materialPriceIdForRadiator)
    //                     this.roomDetail.materialPriceIdForRadiator = materialprice.offerMaterialPriceList[i].id;
    //                 this.materialPriceListForRadiator.push({
    //                     id: obj.id,
    //                     key: obj.key,
    //                     extraValue: materialprice.offerMaterialPriceList[i].paintText,
    //                     priceId: materialprice.offerMaterialPriceList[i].id
    //                 });
    //                 if (materialprice.offerMaterialPriceList[i].id == materialPriceId) {
    //                     this.roomDetail.materialPriceIdForRadiator = materialprice.offerMaterialPriceList[i].id;
    //                     this.roomDetail.radiatorPrice = materialprice.offerMaterialPriceList[i].price;
    //                 }

    //                 if (materialprice.offerMaterialPriceList[i].isDefault && materialPriceId == "") {
    //                     this.roomDetail.materialPriceIdForRadiator = materialprice.offerMaterialPriceList[i].id;
    //                     this.roomDetail.radiatorPrice = materialprice.offerMaterialPriceList[i].price;
    //                 }
    //             }
    //         }
    //         this.getPriceForPanelDoorRadiator("Radiator", this.roomDetail.materialIdForRadiator, this.roomDetail.materialPriceIdForRadiator, this.roomDetail.radiatorQty, isEdit);
    //     }
    // }

    // getRadiatorMaterialPrice(materialPriceId) {
    //     this.getRadiatorMaterialPriceDetail(materialPriceId, null);
    //     if (materialPriceId && this.roomDetail && this.materialListForRadiator) {
    //         this.materialListForRadiator.filter(mat => {
    //             if (mat.id == this.roomDetail.materialIdForRadiator) {
    //                 var tempPriceData = mat.offerMaterialPriceList.filter(x => x.id == materialPriceId);
    //                 if (tempPriceData && tempPriceData.length > 0) {
    //                     mat.offerMaterialPriceText = tempPriceData[0].paintText;
    //                 }
    //             }
    //         })
    //     }
    // }

    // getDoreMaterialPriceDetail(materialPriceId, isEdit) {
    //     if (this.roomDetail.materialIdForDore) {
    //         var materialprice = this.materialListForDore.find(o => o.id == this.roomDetail.materialIdForDore);
    //         this.roomDetail.doreColorCode = this.roomDetail.doreColorCode ? this.roomDetail.doreColorCode : materialprice.colorCode;
    //         this.materialPriceListForDore = [];
    //         for (var i = 0; i < materialprice.offerMaterialPriceList.length; i++) {
    //             var obj = this.SelectCategoryTime.find(o => o.id == materialprice.offerMaterialPriceList[i].type);
    //             if (obj) {
    //                 if (i == 0 && !this.roomDetail.materialPriceIdForDore)
    //                     this.roomDetail.materialPriceIdForDore = materialprice.offerMaterialPriceList[i].id;
    //                 this.materialPriceListForDore.push({
    //                     id: obj.id,
    //                     key: obj.key,
    //                     extraValue: materialprice.offerMaterialPriceList[i].paintText,
    //                     priceId: materialprice.offerMaterialPriceList[i].id
    //                 });
    //                 if (materialprice.offerMaterialPriceList[i].id == materialPriceId) {
    //                     this.roomDetail.materialPriceIdForDore = materialprice.offerMaterialPriceList[i].id;
    //                     this.roomDetail.dorePrice = materialprice.offerMaterialPriceList[i].price;
    //                 }

    //                 if (materialprice.offerMaterialPriceList[i].isDefault && materialPriceId == "") {
    //                     this.roomDetail.materialPriceIdForDore = materialprice.offerMaterialPriceList[i].id;
    //                     this.roomDetail.dorePrice = materialprice.offerMaterialPriceList[i].price;
    //                 }
    //             }
    //         }
    //         this.getPriceForPanelDoorRadiator("Dore", this.roomDetail.materialIdForDore, this.roomDetail.materialPriceIdForDore, this.roomDetail.doreQty, isEdit);
    //     }
    // }

    // getDoreMaterialPrice(materialPriceId) {
    //     this.getDoreMaterialPriceDetail(materialPriceId, null);
    //     if (materialPriceId && this.roomDetail && this.materialListForDore) {
    //         this.materialListForDore.filter(mat => {
    //             if (mat.id == this.roomDetail.materialIdForDore) {
    //                 var tempPriceData = mat.offerMaterialPriceList.filter(x => x.id == materialPriceId);
    //                 if (tempPriceData && tempPriceData.length > 0) {
    //                     mat.offerMaterialPriceText = tempPriceData[0].paintText;
    //                 }
    //             }
    //         })
    //     }
    // }

    // getMaterialListWithClear(type, materialPriceId) {
    //     if (type == 'Panels') {
    //         this.roomDetail.PanelsColorCode = null;
    //         this.roomDetail.materialPriceIdForPanels = null;
    //         this.roomDetail.isPaintPanels = true;
    //         this.roomDetail.panelsPrice = 0;
    //         this.getPanelsMaterialPriceDetail(materialPriceId, null);
    //     }
    //     if (type == 'DoorFrame') {
    //         this.roomDetail.doorFrameColorCode = null;
    //         this.roomDetail.materialPriceIdForDoorFrame = null;
    //         this.roomDetail.isPaintDoorFrame = true;
    //         this.roomDetail.doorFramePrice = 0;
    //         this.getDoorFrameMaterialPriceDetail(materialPriceId, null);
    //     }
    //     if (type == 'Radiator') {
    //         this.roomDetail.radiatorColorCode = null;
    //         this.roomDetail.materialPriceIdForRadiator = null;
    //         this.roomDetail.isPaintRadiator = true;
    //         this.roomDetail.radiatorPrice = 0;
    //         this.getRadiatorMaterialPriceDetail(materialPriceId, null);
    //     }
    //     if (type == 'Dore') {
    //         this.roomDetail.doreColorCode = null;
    //         this.roomDetail.materialPriceIdForDore = null;
    //         this.roomDetail.isPaintDore = true;
    //         this.roomDetail.dorePrice = 0;
    //         this.getDoreMaterialPriceDetail(materialPriceId, null);
    //     }
    // }

    // clearSelection(type) {

    //     if (type == 'Panels') {
    //         this.roomDetail.PanelsColorCode = null;
    //         this.roomDetail.materialPriceIdForPanels = null;
    //         this.roomDetail.isPaintPanels = false;
    //         this.roomDetail.panelsPrice = 0;
    //     }
    //     if (type == 'DoorFrame') {
    //         this.roomDetail.doorFrameColorCode = null;
    //         this.roomDetail.materialPriceIdForDoorFrame = null;
    //         this.roomDetail.isPaintDoorFrame = false;
    //         this.roomDetail.doorFramePrice = 0;
    //     }
    //     if (type == 'Radiator') {
    //         this.roomDetail.radiatorColorCode = null;
    //         this.roomDetail.materialPriceIdForRadiator = null;
    //         this.roomDetail.isPaintRadiator = false;
    //         this.roomDetail.radiatorPrice = 0;
    //     }
    //     if (type == 'Dore') {
    //         this.roomDetail.doreColorCode = null;
    //         this.roomDetail.materialPriceIdForDore = null;
    //         this.roomDetail.isPaintDore = false;
    //         this.roomDetail.dorePrice = 0;
    //     }
    // }

    _handleKeydown(event: KeyboardEvent) {
        if (event.keyCode === 13) {
            event.stopPropagation();
        }
    }

    gotoMaterial(id) {
        this.matObj = this.materialList.filter(x => x.id == id);
        if (this.matObj && this.matObj.length > 0) {
            this.matObj[0].materialID;
            const dialogRef = this.dialog.open(NewMaterialComponent, {
                height: '800px',
                width: '80%',
                data: this.matObj[0].materialID,
            });

            dialogRef.afterClosed().subscribe(result => {

            });
        }
    }

    calculateRoomPrice(roomCalculationItem) {
        if (roomCalculationItem.typeId == 1) {
            roomCalculationItem.totalArea = (((roomCalculationItem.length + roomCalculationItem.width) * 2) * roomCalculationItem.ceilingHeight)
                + roomCalculationItem.extraQty1 + roomCalculationItem.extraQty2;
        }
        else {
            roomCalculationItem.totalArea = (roomCalculationItem.length * roomCalculationItem.width)
                + roomCalculationItem.extraQty1 + roomCalculationItem.extraQty2;
        }
        roomCalculationItem.totalPrice = 0;
        roomCalculationItem.drivingAmountTotal = 0;
        if (roomCalculationItem.materialList.length > 0 && roomCalculationItem.isPaint == true) {
            roomCalculationItem.totalPrice = (roomCalculationItem.totalArea * (roomCalculationItem.materialList.reduce((sum, current) => sum + current.price, 0)))
                + roomCalculationItem.extraPrice1 + roomCalculationItem.extraPrice2;
                var tempDrivingAmountTotal =  (roomCalculationItem.materialList.filter(m => !m.notCalculateToDriving).reduce((sum, current) => sum + current.price, 0))
                if(tempDrivingAmountTotal > 0)
                    roomCalculationItem.drivingAmountTotal = (roomCalculationItem.totalArea * tempDrivingAmountTotal) + roomCalculationItem.extraPrice1 + roomCalculationItem.extraPrice2;
        }
    }

    calulateRoomDetails(roomCalculationItem, type, rci) {
        if (type == this.offMaterialTypeIds.WallType) {
            if (roomCalculationItem.materialList && roomCalculationItem.materialList.length > 0) {
                roomCalculationItem.isPaint = true;
                this.OfferTypeDetail.offerRoomDetailList[rci].paintWall = roomCalculationItem.isPaint
                this.OfferTypeDetail.offerRoomDetailList[rci].wallMaterialList = roomCalculationItem.materialList;
            }
        }
        if (type == this.offMaterialTypeIds.CeilingType) {
            if (roomCalculationItem.materialList && roomCalculationItem.materialList.length > 0) {
                roomCalculationItem.isPaint = true;
                this.OfferTypeDetail.offerRoomDetailList[rci].paintCeiling = roomCalculationItem.isPaint
                this.OfferTypeDetail.offerRoomDetailList[rci].ceilingMaterialList = roomCalculationItem.materialList;
            }
        }
        if (type == this.offMaterialTypeIds.FloorType) {
            if (roomCalculationItem.materialList && roomCalculationItem.materialList.length > 0) {
                roomCalculationItem.isPaint = true;
                this.OfferTypeDetail.offerRoomDetailList[rci].paintFloor = roomCalculationItem.isPaint
                this.OfferTypeDetail.offerRoomDetailList[rci].floorMaterialList = roomCalculationItem.materialList;
            }
        }
        this.calculateRoomPrice(roomCalculationItem);

        roomCalculationItem.materialList.filter(m => {
            var mPrice = m.price;
            this.meterWisePrice = new OfferMeterWisePrice();
            this.meterWisePrice.isAddMeterRangePrice = false;
            var selecteMaterial = this.materialList.find(o => o.id == m.offerMaterialId);
            m.name = selecteMaterial.text;
            var selectedMaterialPrice = selecteMaterial.offerMaterialPriceList.find(o => o.id == m.offerMaterialPriceId);
            var selectionTra = this.SelectCategoryTime.find(o => o.id == selectedMaterialPrice.type);
            m.paintKey = selectionTra.key;
            m.price = selectedMaterialPrice.price;
            if(mPrice && mPrice > 0)
                m.price = mPrice;
            this.meterWisePrice.price = m.price;
            roomCalculationItem.colorCode = m.colorCode ? m.colorCode : selecteMaterial.colorCode;
            if (selectedMaterialPrice.isAddMeterRangePrice) {
                //roomCalculationItem.totalArea = m.roomList.filter(t => t.isChecked==true).reduce((sum, current) => sum + current.totalArea, 0)
                if (roomCalculationItem.totalArea >= selectedMaterialPrice.startMeter1
                    && roomCalculationItem.totalArea <= selectedMaterialPrice.endMeter1) {
                    m.price = selectedMaterialPrice.priceMeter1;
                }
                else if (roomCalculationItem.totalArea >= selectedMaterialPrice.startMeter2
                    && roomCalculationItem.totalArea <= selectedMaterialPrice.endMeter2) {
                    m.price = selectedMaterialPrice.priceMeter2;
                }
                else
                    m.price = selectedMaterialPrice.priceMeter3;
                if(mPrice && mPrice > 0)
                    m.price = mPrice;
                this.meterWisePrice.price = m.price;
                // if(!m.id && selecteMaterial.isPostNumberDiscount && this.projectOffer && this.projectOffer.percentage != 0)
                //     m.price = m.price + ((m.price * this.projectOffer.percentage)/100);
                // if(!m.id && this.projectPriceDiscount)
                //     m.price = m.price + (m.price * this.projectPriceDiscount / 100);                    
                // this.calculateRoomPrice(roomCalculationItem);                
                this.meterWisePrice.startMeter1 = selectedMaterialPrice.startMeter1;
                this.meterWisePrice.endMeter1 = selectedMaterialPrice.endMeter1;
                this.meterWisePrice.priceMeter1 = selectedMaterialPrice.priceMeter1;
                this.meterWisePrice.startMeter2 = selectedMaterialPrice.startMeter2;
                this.meterWisePrice.endMeter2 = selectedMaterialPrice.endMeter2;
                this.meterWisePrice.priceMeter2 = selectedMaterialPrice.priceMeter2;
                this.meterWisePrice.startMeter3 = selectedMaterialPrice.startMeter3;
                this.meterWisePrice.endMeter3 = selectedMaterialPrice.endMeter3;
                this.meterWisePrice.priceMeter3 = selectedMaterialPrice.priceMeter3;
            }
            this.meterWisePrice.isAddMeterRangePrice = selectedMaterialPrice.isAddMeterRangePrice;
            if(mPrice && mPrice == 0){
                if (!m.id && selecteMaterial.isPostNumberDiscount && this.projectOffer && this.projectOffer.percentage != 0 && !this.customerDetailObj.doNotCalculatePriceForPostNumber)
                    m.price = m.price + ((m.price * this.projectOffer.percentage) / 100);
                if (!m.id && this.projectPriceDiscount && !this.customerDetailObj.doNotCalculatePriceForPriceDiscount)
                    m.price = m.price + (m.price * this.projectPriceDiscount / 100);
            }
            this.calculateRoomPrice(roomCalculationItem);
            //this.meterWisePrice.price = m.price;            
            var obj = this.SelectCategoryTime.find(o => o.id == selectedMaterialPrice.type);
            if (obj)
                m.paintKey = obj.key;
            m.meterWisePrice = this.meterWisePrice;
        })
    }
    clearItems(roomCalculationItem) {
        roomCalculationItem.materialList[0].offerMaterialId = null;
        roomCalculationItem.materialList[0].offerMaterialPriceId = null;
        roomCalculationItem.materialList[0].price = 0;
        roomCalculationItem.materialList[0].colorCode = null;
        roomCalculationItem.totalArea = 0;
        roomCalculationItem.totalPrice = 0;
        roomCalculationItem.colorCode = null;
        roomCalculationItem.isPaint = false;
    }

    addFloor(roomDetail) {
        var offerRoomCalculationItem = new OfferRoomCalculationWithMaterialList();
        offerRoomCalculationItem.length = roomDetail.offerRoomCalculation[0].length;
        offerRoomCalculationItem.width = roomDetail.offerRoomCalculation[0].width;
        offerRoomCalculationItem.ceilingHeight = roomDetail.offerRoomCalculation[0].ceilingHeight;
        offerRoomCalculationItem.offerRoomDetailId = roomDetail.id;
        offerRoomCalculationItem.extraQty1 = roomDetail.offerRoomCalculation[0].extraQty1 ? roomDetail.offerRoomCalculation[0].extraQty1 : 0;
        offerRoomCalculationItem.extraQty2 = roomDetail.offerRoomCalculation[0].extraQty2 ? roomDetail.offerRoomCalculation[0].extraQty2 : 0;
        offerRoomCalculationItem.extraPrice1 = roomDetail.offerRoomCalculation[0].extraPrice1 ? roomDetail.offerRoomCalculation[0].extraPrice1 : 0;
        offerRoomCalculationItem.extraPrice2 = roomDetail.offerRoomCalculation[0].extraPrice2 ? roomDetail.offerRoomCalculation[0].extraPrice2 : 0;
        offerRoomCalculationItem.extraM2QtyFloor = roomDetail.offerRoomCalculation[0].extraM2QtyFloor ? roomDetail.offerRoomCalculation[0].extraM2QtyFloor : 0;
        offerRoomCalculationItem.extraM2PriceFloor = roomDetail.offerRoomCalculation[0].extraM2PriceFloor ? roomDetail.offerRoomCalculation[0].extraM2PriceFloor : 0;
        offerRoomCalculationItem.isActive = true;
        let tempOfferRoomCalculationItem = Object.assign({}, offerRoomCalculationItem);
        tempOfferRoomCalculationItem.materialList = [];
        var materialObj = new MaterialList();
        materialObj.offerMaterialTypeId = this.offMaterialTypeIds.FloorType;
        materialObj.materialList = this.materialList.filter(o => { return o.type.split(',').find(p => p == materialObj.offerMaterialTypeId) });
        tempOfferRoomCalculationItem.typeId = this.offerRoomCalculationType.FloorType;
        tempOfferRoomCalculationItem.materialList.push(materialObj);
        tempOfferRoomCalculationItem.extraQty1 = tempOfferRoomCalculationItem.extraM2QtyFloor;
        tempOfferRoomCalculationItem.extraPrice1 = tempOfferRoomCalculationItem.extraM2PriceFloor;
        if (tempOfferRoomCalculationItem.extraM2QtyFloor > 0 || tempOfferRoomCalculationItem.extraM2PriceFloor > 0) {
            tempOfferRoomCalculationItem.isAddExtraWallQty = true
        }
        roomDetail.offerRoomCalculation.push(tempOfferRoomCalculationItem);
    }

    addMaterial(materialList, typeId) {
        var materialObj = new MaterialList();
        if (typeId == this.offerRoomCalculationType.WallType){
            materialObj.offerMaterialTypeId = this.offMaterialTypeIds.WallType;
            materialObj.roomList = this.wallRoomList.map(x => Object.assign({}, x));
        }
        if (typeId == this.offerRoomCalculationType.CeilingType){
            materialObj.offerMaterialTypeId = this.offMaterialTypeIds.CeilingType;
            materialObj.roomList = this.celiRoomList.map(x => Object.assign({}, x));
        }
        if (typeId == this.offerRoomCalculationType.FloorType){
            materialObj.offerMaterialTypeId = this.offMaterialTypeIds.FloorType;
            materialObj.roomList = this.floorRoomList.map(x => Object.assign({}, x));
        }
        materialObj.materialList = this.materialList.filter(o => { return o.type.split(',').find(p => p == materialObj.offerMaterialTypeId) });
        //materialObj.roomList = this.wallCeliFloorRoomList.map(x => Object.assign({}, x));
        materialObj.selectedRoomCount = materialObj.roomList.length;
        materialList.push(materialObj);
    }

    getMaterialList(type, isEdit, materialListItem) {
        if (type == 0)
            materialListItem.materialList = this.materialList;
        else
            materialListItem.materialList = this.materialList.filter(o => { return o.type.split(',').find(p => p == type) });
        if (!isEdit) {
            materialListItem.offerMaterialId = materialListItem.materialList[0].id;
            this.getMaterialPriceDetail(materialListItem.offerMaterialId, '', materialListItem)
        }
        else
            this.getMaterialPriceDetail(materialListItem.offerMaterialId, materialListItem.offerMaterialPriceId, materialListItem)
    }

    materialTypeOnClearSearch(value, materialListItem) {
        if (!value) {
            this.materialListDropdownSearch(materialListItem)
        }
    }

    materialListDropdownSearch(materialListItem) {
        if (materialListItem.offerMaterialTypeId)
            materialListItem.materialList = this.materialList.filter(o => { return o.type.split(',').find(p => p == (materialListItem.offerMaterialTypeId) && o.text.toLowerCase().includes(this.MaterialTypeSearch.toLowerCase())) });
        else
            materialListItem.materialList = this.materialList.filter(o => o.text.toLowerCase().includes(this.MaterialTypeSearch.toLowerCase()));
    }

    getMaterialPriceDetail(materialId, materialPriceId, materialListItem) {
        var materialprice = this.materialList.find(o => o.id == materialId);
        if (materialprice) {
            if (materialListItem) {
                materialListItem.colorCode = materialListItem.colorCode ? materialListItem.colorCode : materialprice.colorCode;
                materialListItem.selectCategoryTime = [];
                materialListItem.notCalculateToDriving = materialprice.notCalculateToDriving;
                for (var i = 0; i < materialprice.offerMaterialPriceList.length; i++) {
                    var obj = this.SelectCategoryTime.find(o => o.id == materialprice.offerMaterialPriceList[i].type);
                    if (obj) {
                        if (i == 0) {
                            materialListItem.offerMaterialPriceId = materialprice.offerMaterialPriceList[i].id;
                            materialListItem.paintKey = obj.key;
                            materialListItem.colorCode = materialListItem.colorCode;
                        }
                        materialListItem.selectCategoryTime.push({
                            id: obj.id,
                            key: obj.key,
                            extraValue: materialprice.offerMaterialPriceList[i].paintText,
                            priceId: materialprice.offerMaterialPriceList[i].id,
                            reminder: materialprice.offerMaterialPriceList[i].reminder,
                        });
                        if (materialprice.offerMaterialPriceList[i].id == materialPriceId) {
                            materialListItem.paintKey = obj.key;
                            materialListItem.offerMaterialPriceId = materialprice.offerMaterialPriceList[i].id;
                        }
                        else{
                            materialListItem.colorCode = materialListItem.colorCode ? materialListItem.colorCode : materialprice.colorCode;
                        }
                        if (materialprice.offerMaterialPriceList[i].isDefault && materialPriceId == "") {
                            materialListItem.offerMaterialPriceId = materialprice.offerMaterialPriceList[i].id;
                            if (materialListItem.materialList && materialListItem.materialList.length > 0) {
                                materialListItem.materialList.filter(m => {
                                    if (m.id == materialId) {
                                        var tempPriceData = m.offerMaterialPriceList.filter(x => x.id == materialListItem.offerMaterialPriceId);
                                        if (tempPriceData && tempPriceData.length > 0)
                                            m.offerMaterialPriceText = tempPriceData[0].paintText;
                                    }
                                })
                            }
                        }
                    }
                }

                if (!materialPriceId) {
                    var selectedMaterialLocal;
                    if (materialListItem.offerMaterialPriceId)
                        selectedMaterialLocal = materialprice.offerMaterialPriceList.filter(x => x.id == materialListItem.offerMaterialPriceId)[0];
                    else 
                        selectedMaterialLocal = materialprice.offerMaterialPriceList[0];
                    materialListItem.offerMaterialPriceId = selectedMaterialLocal.id;
                    var objSelectCategoryTime = this.SelectCategoryTime.find(o => o.id == selectedMaterialLocal.type);
                    materialListItem.paintKey = objSelectCategoryTime.key;
                    var price = selectedMaterialLocal.price;
                    materialListItem.totalArea = materialListItem.roomList.filter(t => t.isChecked==true).reduce((sum, current) => sum + current.totalArea, 0)
                    if (selectedMaterialLocal.isAddMeterRangePrice) {
                        if (materialListItem.totalArea >= selectedMaterialLocal.startMeter1
                            && materialListItem.totalArea <= selectedMaterialLocal.endMeter1) {
                            price = selectedMaterialLocal.priceMeter1;
                        }
                        else if (materialListItem.totalArea >= selectedMaterialLocal.startMeter2
                            && materialListItem.totalArea <= selectedMaterialLocal.endMeter2) {
                            price = selectedMaterialLocal.priceMeter2;
                        }
                        else {
                            price = selectedMaterialLocal.priceMeter3;
                        }
                    }
                    materialListItem.price = price;
                    materialListItem.originalPrice = price;
                    if (!materialListItem.id && materialprice.isPostNumberDiscount && this.projectOffer && this.projectOffer.percentage != 0 && !this.customerDetailObj.doNotCalculatePriceForPostNumber)
                        materialListItem.price = materialListItem.price + ((materialListItem.price * this.projectOffer.percentage) / 100);
                    if (!materialListItem.id && this.projectPriceDiscount && !this.customerDetailObj.doNotCalculatePriceForPriceDiscount)
                        materialListItem.price = materialListItem.price + (materialListItem.price * this.projectPriceDiscount / 100);
                }
            }
        }
    }

    clearPriceDetail(materialListItem) {
        materialListItem.offerMaterialPriceId = null;
        materialListItem.offerMaterialId = null;
        materialListItem.name = null;
        materialListItem.price = 0;
        materialListItem.colorCode = null;
    }

    deleteMaterialItem(index, materialListItem) {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: "200px",
            data: { Header: 'Headers.DeleteDiverseMaterial' }
        })
        dialogRef.afterClosed().subscribe(result => {
            if (result)
                materialListItem.splice(index, 1);
        });

    }

    getPriceForCategoryTimeSelect(materialId, priceId, materialListItem) {
        materialListItem.price = 0;
        //this.getMaterialPriceDetail(materialId, priceId, materialListItem)
        if (priceId && materialListItem && materialListItem.materialList) {
            materialListItem.materialList.filter(mat => {
                if (mat.id == materialId) {
                    var tempPriceData = mat.offerMaterialPriceList.filter(x => x.id == priceId);
                    if (tempPriceData && tempPriceData.length > 0) {
                        mat.offerMaterialPriceText = tempPriceData[0].paintText;
                        mat.wallPaintText = tempPriceData[0].paintText;
                        if (priceId) {
                            var selectedMaterialLocal;
                            if (materialListItem.offerMaterialPriceId)
                                selectedMaterialLocal = tempPriceData[0]
                            else 
                                selectedMaterialLocal = tempPriceData[0]
                            materialListItem.offerMaterialPriceId = selectedMaterialLocal.id;
                            var objSelectCategoryTime = this.SelectCategoryTime.find(o => o.id == selectedMaterialLocal.type);
                            materialListItem.paintKey = objSelectCategoryTime.key;
                            var price = selectedMaterialLocal.price;
                            materialListItem.totalArea = materialListItem.roomList.filter(t => t.isChecked==true).reduce((sum, current) => sum + current.totalArea, 0)
                            if (selectedMaterialLocal.isAddMeterRangePrice) {
                                if (materialListItem.totalArea >= selectedMaterialLocal.startMeter1
                                    && materialListItem.totalArea <= selectedMaterialLocal.endMeter1) {
                                    price = selectedMaterialLocal.priceMeter1;
                                }
                                else if (materialListItem.totalArea >= selectedMaterialLocal.startMeter2
                                    && materialListItem.totalArea <= selectedMaterialLocal.endMeter2) {
                                    price = selectedMaterialLocal.priceMeter2;
                                }
                                else {
                                    price = selectedMaterialLocal.priceMeter3;
                                }
                            }
                            materialListItem.price = price;
                            if (!materialListItem.id && mat.isPostNumberDiscount && this.projectOffer && this.projectOffer.percentage != 0 && !this.customerDetailObj.doNotCalculatePriceForPostNumber)
                                materialListItem.price = materialListItem.price + ((materialListItem.price * this.projectOffer.percentage) / 100);
                            if (!materialListItem.id && this.projectPriceDiscount && !this.customerDetailObj.doNotCalculatePriceForPriceDiscount)
                                materialListItem.price = materialListItem.price + (materialListItem.price * this.projectPriceDiscount / 100);
                        }
                    }
                }
            })
        }
    }

    ShowWallRoomList(materialListItem) {
        materialListItem.isShowWallRoomList = !materialListItem.isShowWallRoomList
        //this.isShowWallRoomList = !this.isShowWallRoomList;
    }

    ShowCelingRoomList(materialListItem) {
        materialListItem.isShowCelingRoomList = !materialListItem.isShowCelingRoomList;
    }

    ShowFloorRoomList(materialListItem) {
        materialListItem.isShowFloorRoomList = !materialListItem.isShowFloorRoomList;
    }

    onRoomSelect(index, roomId, isChecked, typeId) {
        if (typeId == this.offerRoomCalculationType.WallType){
            this.roomDetail.wallMaterialAllList[index].roomList.find(o => o.id == roomId).isChecked = isChecked
            this.roomDetail.wallMaterialAllList[index].selectedRoomCount = this.roomDetail.wallMaterialAllList[index].roomList.filter(r => r.isChecked==true).length;
            this.getMaterialPriceDetailWithClear(this.roomDetail.wallMaterialAllList[index].offerMaterialId,'',this.roomDetail.wallMaterialAllList[index]);
        }
        if (typeId == this.offerRoomCalculationType.CeilingType){
            this.roomDetail.ceilingMaterialAllList[index].roomList.find(o => o.id == roomId).isChecked = isChecked
            this.roomDetail.ceilingMaterialAllList[index].selectedRoomCount = this.roomDetail.ceilingMaterialAllList[index].roomList.filter(r => r.isChecked==true).length;
            this.getMaterialPriceDetailWithClear(this.roomDetail.ceilingMaterialAllList[index].offerMaterialId,'',this.roomDetail.ceilingMaterialAllList[index]);
        }
        if (typeId == this.offerRoomCalculationType.FloorType){
            this.roomDetail.floorMaterialAllList[index].roomList.find(o => o.id == roomId).isChecked = isChecked
            this.roomDetail.floorMaterialAllList[index].selectedRoomCount = this.roomDetail.floorMaterialAllList[index].roomList.filter(r => r.isChecked==true).length;
            this.getMaterialPriceDetailWithClear(this.roomDetail.floorMaterialAllList[index].offerMaterialId,'',this.roomDetail.floorMaterialAllList[index]);
        }
    }

    // ShowRoomListForPanelerDoorRadiator(type) {
    //     if (type == 'Panels')
    //         this.roomDetail.isShowRoomListForPaneler = !this.roomDetail.isShowRoomListForPaneler;
    //     if (type == 'DoorFrame')
    //         this.roomDetail.isShowRoomListForDoorFrame = !this.roomDetail.isShowRoomListForDoorFrame;
    //     if (type == 'Radiator')
    //         this.roomDetail.isShowRoomListForRadiator = !this.roomDetail.isShowRoomListForRadiator;
    //     if (type == 'Dore')
    //         this.roomDetail.isShowRoomListForDore = !this.roomDetail.isShowRoomListForDore;
    // }

    addNewItem() {
        var diverseItem = new OfferDiverseList();
        diverseItem.offerCategoryId = this.OfferTypeDetail.offerCategoryId;
        diverseItem.offerId = this.OfferTypeDetail.offerId;
        diverseItem.qty = 0;
        diverseItem.imagePath = "Upload/NoImageAvailable.png";
        diverseItem.colorCode = "";
        diverseItem.treatmentType = 0;
        diverseItem.materialList = this.materialList.map(x => Object.assign({}, x));
        diverseItem.roomList = this.diverseRoomList.map(x => Object.assign({}, x));
        if (!this.roomDetail.offerDiverseList)
            this.roomDetail.offerDiverseList = [];
        this.roomDetail.offerDiverseList.push(diverseItem);
    }

    showRoomListForDiverse(diverseItem) {
        diverseItem.isShowRoomList = !diverseItem.isShowRoomList;
    }

    getMaterialSearchClear(value, i) {
        if (!value) {
            this.materialTypeDropdownSearch(i);
        }
    }

    materialTypeDropdownSearch(i) {
        if (this.roomDetail.offerDiverseList[i].type)
            this.roomDetail.offerDiverseList[i].materialList = this.materialList.filter(o => { return o.type.split(',').find(p => p == this.roomDetail.offerDiverseList[i].type.toString() && o.text.toLowerCase().includes(this.MaterialTypeSearchForDiverse.toLowerCase())) });
        else
            this.roomDetail.offerDiverseList[i].materialList = this.materialList.filter(o => o.text.toLowerCase().includes(this.MaterialTypeSearchForDiverse.toLowerCase()));
    }

    getDiverseMaterial(type, i) {
        if (type == 0)
            this.roomDetail.offerDiverseList[i].materialList = this.materialList;
        else
            this.roomDetail.offerDiverseList[i].materialList = this.materialList.filter(o => { return o.type.split(',').find(p => p == type) });
        if (this.roomDetail.offerDiverseList[i].materialList.length > 0) {
            this.roomDetail.offerDiverseList[i].materialID = this.roomDetail.offerDiverseList[i].materialList[0].id;
            this.onChangeMaterialSelection(this.roomDetail.offerDiverseList[i].materialID, this.roomDetail.offerDiverseList[i]);
        }
        else
            this.roomDetail.offerDiverseList[i].materialID = "";
    }

    onChangeMaterialSelection(id, diverseItem) {
        diverseItem.colorCode = null;
        diverseItem.price = 0;
        diverseItem.materialPriceID = "";
        diverseItem.qty = diverseItem.qty && diverseItem.qty > 0 ? diverseItem.qty : 1;
        var materialprice = this.materialList.find(o => o.id == id);
        if(materialprice)
            diverseItem.treatmentType = materialprice.treatmentType;
        // var tempQty = diverseItem.qty;
        // if(diverseItem.treatmentType != 0) {
        //     if(diverseItem.treatmentType && diverseItem.treatmentType == this.treatmentTypeIds.WoodWork)
        //         tempQty = (diverseItem.width + diverseItem.length + diverseItem.width + diverseItem.length + diverseItem.extraM2QtyFloor);
        //     if(diverseItem.treatmentType && diverseItem.treatmentType == this.treatmentTypeIds.Walls)
        //         tempQty = (((diverseItem.width + diverseItem.length) * 2) * diverseItem.height + diverseItem.extraM2QtyWall);
        //     if(diverseItem.treatmentType && diverseItem.treatmentType == this.treatmentTypeIds.Ceiling)
        //         tempQty = (diverseItem.width * diverseItem.length + diverseItem.extraM2QtyCeiling);
        // }
        // diverseItem.qty = tempQty && tempQty > 0 ? tempQty: diverseItem.qty;
        this.calculateDiverseMaterialQty(diverseItem);
        if (id)
            diverseItem.isPaint = true;
        this.getDiverseMaterialPrice(id, diverseItem, diverseItem.materialPriceID);
    }

    getMaterialPriceOnCategoryTimeSelect(priceId, materialId, diverseItem) {
        this.getDiverseMaterialPrice(materialId, diverseItem, priceId);
        if (priceId && diverseItem && diverseItem.materialList) {
            diverseItem.materialList.filter(m => {
                if (m.id == materialId) {
                    var tempPriceData = m.offerMaterialPriceList.filter(x => x.id == priceId);
                    if (tempPriceData && tempPriceData.length > 0)
                        m.offerMaterialPriceText = tempPriceData[0].paintText;
                }
            })
        }
    }

    clearDivserseItem(diverseItem) {
        diverseItem.text = "";
        diverseItem.imagePath = "";
        diverseItem.unit = "";
        diverseItem.colorCode = "";
        diverseItem.materialPriceID = "";
        diverseItem.price = 0;
        diverseItem.priceTotal = 0;
        diverseItem.qty = 0;
        diverseItem.treatmentType = 0;
        diverseItem.extraM2QtyFloor = 0;
        diverseItem.extraM2QtyWall = 0;
        diverseItem.extraM2QtyCeiling = 0;
        diverseItem.length = 0;
        diverseItem.width = 0;
        diverseItem.height = 0;
    }

    getDiverseMaterialPrice(materialId, offerItem, materialPriceId) {
        var materialprice = this.materialList.find(o => o.id == materialId);
        if (materialprice) {
            offerItem.imagePath = materialprice.imagePath;
            offerItem.selectCategoryTimeForDiverse = [];
            for (var i = 0; i < materialprice.offerMaterialPriceList.length; i++) {
                var obj = this.SelectCategoryTime.find(o => o.id == materialprice.offerMaterialPriceList[i].type);
                if (obj) {
                    if (i == 0) {
                        offerItem.materialPriceID = materialprice.offerMaterialPriceList[i].id;
                        offerItem.colorCode = offerItem.colorCode;
                    }
                    offerItem.selectCategoryTimeForDiverse.push({
                        id: obj.id,
                        key: obj.key,
                        extraValue: materialprice.offerMaterialPriceList[i].paintText,
                        priceId: materialprice.offerMaterialPriceList[i].id,
                        reminder: materialprice.offerMaterialPriceList[i].reminder,
                    });
                    if (materialprice.offerMaterialPriceList[i].id == materialPriceId) {
                        offerItem.materialPriceID = materialprice.offerMaterialPriceList[i].id;
                    }
                    else {
                        offerItem.text = materialPriceId ? offerItem.text : materialprice.text + ' ' + materialprice.offerMaterialPriceText;
                        offerItem.colorCode = offerItem.colorCode ? offerItem.colorCode : materialprice.colorCode;
                        offerItem.unit = materialprice.unit;
                    }
                    if (materialprice.offerMaterialPriceList[i].isDefault && materialPriceId == "") {
                        offerItem.materialPriceID = materialprice.offerMaterialPriceList[i].id;
                        offerItem.price = materialprice.offerMaterialPriceList[i].price;
                        var offerReminderText = materialprice.offerMaterialPriceList[i].reminder ? materialprice.offerMaterialPriceList[i].reminder : "";
                        offerItem.text = materialprice.text + ' ' + materialprice.offerMaterialPriceList[i].paintText + (materialprice.addNumberOfTimesOnPDF ? ' ' + this.projectofferService.getPaintType(materialprice.offerMaterialPriceList[i].type) : "");
                        if (offerItem.materialList && offerItem.materialList.length > 0) {
                            offerItem.materialList.filter(m => {
                                if (m.id == materialId) {
                                    var tempPriceData = m.offerMaterialPriceList.filter(x => x.id == offerItem.materialPriceID);
                                    if (tempPriceData && tempPriceData.length > 0)
                                        m.offerMaterialPriceText = tempPriceData[0].paintText;
                                }
                            })
                        }
                    }
                    offerItem.originalPrice = offerItem.price;
                    offerItem.reminder = materialprice.offerMaterialPriceList[i].reminder ? materialprice.offerMaterialPriceList[i].reminder : "";
                    if (!offerItem.id && materialprice.isPostNumberDiscount && this.projectOffer && this.projectOffer.percentage != 0 && !this.customerDetailObj.doNotCalculatePriceForPostNumber)
                        offerItem.price = offerItem.price + ((offerItem.price * this.projectOffer.percentage) / 100);
                    if (!offerItem.id && this.projectPriceDiscount && (this.customerDetailObj && !this.customerDetailObj.doNotCalculatePriceForPriceDiscount))
                        offerItem.price = offerItem.price + (offerItem.price * this.projectPriceDiscount / 100);
                    this.getDiverseMeterPriceDetail(offerItem);
                }
            }
        }
    }
    getDiverseMeterPriceDetail(offerItem) {
        var selectedMaterial = this.materialList.find(o => o.id == offerItem.materialID);
        if (selectedMaterial) {
            var selectedPrice = selectedMaterial.offerMaterialPriceList.find(o => o.id == offerItem.materialPriceID);
            if (selectedPrice) {
                var price = selectedPrice.price;
                if (selectedPrice.isAddMeterRangePrice) {
                    if (offerItem.materialPriceID) {
                        if (offerItem.qty >= selectedPrice.startMeter1 && offerItem.qty <= selectedPrice.endMeter1) {
                            price = selectedPrice.priceMeter1;
                        }
                        else if (offerItem.qty >= selectedPrice.startMeter2 && offerItem.qty <= selectedPrice.endMeter2) {
                            price = selectedPrice.priceMeter2;
                        }
                        else if (offerItem.qty >= selectedPrice.startMeter3 && offerItem.qty <= selectedPrice.endMeter3) {
                            price = selectedPrice.priceMeter3;
                        }
                        else {
                            price = 0;
                        }
                    }
                }
                offerItem.price = price;
                offerItem.originalPrice = offerItem.price;
                if (!offerItem.id && selectedMaterial.isPostNumberDiscount && this.projectOffer && this.projectOffer.percentage != 0 && !this.customerDetailObj.doNotCalculatePriceForPostNumber)
                    offerItem.price = offerItem.price + ((offerItem.price * this.projectOffer.percentage) / 100);
                if (!offerItem.id && this.projectPriceDiscount && (this.customerDetailObj && !this.customerDetailObj.doNotCalculatePriceForPriceDiscount))
                    offerItem.price = offerItem.price + (offerItem.price * this.projectPriceDiscount / 100);;
                this.calculateDiversePrise();
            }
        }
    }

    getMaterialPriceDetailWithClear(materialId, materialPriceId, materialListItem){
        materialListItem.colorCode = null;
        materialListItem.price = 0;
        materialListItem.totalArea = 0;
        this.getMaterialPriceDetail(materialId, materialPriceId, materialListItem)
    }

    addDiverseItem(typeId) {
        var diverseItem = new OfferDiverseList();
        diverseItem.offerCategoryId = this.OfferTypeDetail.offerCategoryId;
        diverseItem.offerId = this.OfferTypeDetail.offerId;
        diverseItem.qty = 0;
        diverseItem.imagePath = "Upload/NoImageAvailable.png";
        diverseItem.colorCode = "";
        diverseItem.treatmentType = 0;
        diverseItem.extraM2QtyFloor = 0;
        diverseItem.extraM2QtyWall = 0;
        diverseItem.extraM2QtyCeiling = 0;
        if (typeId == 0)
            diverseItem.materialList = this.materialList.map(x => Object.assign({}, x));
        else {

            diverseItem.materialList = this.materialList.filter(o => { return o.type.split(',').find(p => p == typeId) });
            diverseItem.type = typeId;
        }
        //diverseItem.materialList = this.materialList.map(x => Object.assign({}, x));
        diverseItem.roomList = this.diverseRoomList.map(x => Object.assign({}, x));
        if (!this.roomDetail.offerDiverseList)
            this.roomDetail.offerDiverseList = [];
        this.roomDetail.offerDiverseList.push(diverseItem);
        if(typeId==20 && diverseItem.materialList.length > 0){
            diverseItem.materialID = diverseItem.materialList[0].id;
            this.onChangeMaterialSelection(diverseItem.materialList[0].id , diverseItem);
        }
    }

    onDiverseRoomSelect(index, diverseItem, room) {
        // if (room.isChecked) {
        //     diverseItem.length = room.length;
        //     diverseItem.width = room.width;
        //     diverseItem.height = room.ceilingHeight;        
        //     diverseItem.extraM2QtyWall = room.extraM2QtyWall;
        //     diverseItem.extraM2QtyCeiling = room.extraM2QtyCeiling;
        //     diverseItem.extraM2QtyFloor = room.extraM2QtyFloor;
        //     diverseItem.roomId = room.id;
        //     var tempQty = diverseItem.qty > 0 ? diverseItem.qty : 1;
        //     if(diverseItem.treatmentType != 0){
        //         if(diverseItem.treatmentType && diverseItem.treatmentType == this.treatmentTypeIds.WoodWork)
        //             tempQty = (diverseItem.width + diverseItem.length + diverseItem.width + diverseItem.length + diverseItem.extraM2QtyFloor);
        //         if(diverseItem.treatmentType && diverseItem.treatmentType == this.treatmentTypeIds.Walls)
        //             tempQty = (((diverseItem.width + diverseItem.length) * 2) * diverseItem.height + diverseItem.extraM2QtyWall);
        //         if(diverseItem.treatmentType && diverseItem.treatmentType == this.treatmentTypeIds.Ceiling)
        //             tempQty = (diverseItem.width * diverseItem.length + diverseItem.extraM2QtyCeiling);
        //     }
        //     diverseItem.qty = tempQty;
        //     this.calculateDiversePrise()
        // }
        this.calculateDiverseMaterialQty(diverseItem);
        diverseItem.selectedRoomCount = diverseItem.roomList.filter(r => r.isChecked==true).length;
    }

    // calculateMaterialQty(diverseItem){
    //     if(diverseItem.roomId){
    //         var room = this.diverseRoomList.filter(x=>x.id == diverseItem.roomId);
    //         if (room.length > 0) {
    //             diverseItem.length = room[0].length;
    //             diverseItem.width = room[0].width;
    //             diverseItem.height = room[0].ceilingHeight;        
    //             diverseItem.extraM2QtyWall = room[0].extraM2QtyWall;
    //             diverseItem.extraM2QtyCeiling = room[0].extraM2QtyCeiling;
    //             diverseItem.extraM2QtyFloor = room[0].extraM2QtyFloor;
    //             var tempQty=1;
    //             if(diverseItem.treatmentType && diverseItem.treatmentType == this.treatmentTypeIds.WoodWork)
    //                 tempQty = (diverseItem.width + diverseItem.length + diverseItem.width + diverseItem.length + diverseItem.extraM2QtyFloor);
    //             if(diverseItem.treatmentType && diverseItem.treatmentType == this.treatmentTypeIds.Walls)
    //                 tempQty = (((diverseItem.width + diverseItem.length) * 2) * diverseItem.height + diverseItem.extraM2QtyWall);
    //             if(diverseItem.treatmentType && diverseItem.treatmentType == this.treatmentTypeIds.Ceiling)
    //                 tempQty = (diverseItem.width * diverseItem.length + diverseItem.extraM2QtyCeiling);
    //             diverseItem.qty = tempQty;
    //             this.calculateDiversePrise()
    //         }
    //     }
    // }
    calculateDiverseMaterialQty(diverseItem){
        if(diverseItem.roomList && diverseItem.roomList.length >0){
            var tempQty = 0;
            if(diverseItem.treatmentType != 0){            
                diverseItem.roomList.filter(r =>{ 
                if(r.isChecked==true){
                    if(diverseItem.treatmentType && diverseItem.treatmentType == this.treatmentTypeIds.WoodWork)
                        tempQty += (r.width + r.length + r.width + r.length + r.extraM2QtyFloor);
                    if(diverseItem.treatmentType && diverseItem.treatmentType == this.treatmentTypeIds.Walls)
                        tempQty += (((r.width + r.length) * 2) * r.ceilingHeight + r.extraM2QtyWall);
                    if(diverseItem.treatmentType && diverseItem.treatmentType == this.treatmentTypeIds.Ceiling)
                        tempQty += (r.width * r.length + r.extraM2QtyCeiling);
                    }
                })
            }
            else
                tempQty = 1
            diverseItem.qty = tempQty > 0 ? tempQty : diverseItem.qty;
            this.calculateDiversePrise()
        }
    }

}