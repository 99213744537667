import { NgModule } from "@angular/core";
import { ProjectListSharedComponent } from './project-list.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FusePipesModule } from 'app/core/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatFormFieldModule, MatSelectModule } from '@angular/material';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@fuse/material.module';
import { ProjectCardModule } from '../project-card/project-card.module';

@NgModule({
    declarations: [ProjectListSharedComponent],
    imports: [
        CommonModule,
        MaterialModule,
        FlexLayoutModule,
        FusePipesModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        DatePickerModule,
        NgxMatSelectSearchModule,
        ProjectCardModule
    ],
    exports: [ProjectListSharedComponent,
    ],
    entryComponents: [
        ProjectListSharedComponent
    ]
})
export class ProjectListSharedModule {

}