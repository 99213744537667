export const locale = {
    lang: 'en',
    data: {
        'TITLE': {
        },
        'FIELDS': {
            'ProjectsCoworkerRate': 'Projects Coworker Rate',
        },
        'Message': {

        },
        'FILTERS': {
        },
        'Buttons': {
            'Save': 'Save',
        }
    }
};