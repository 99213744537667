export const locale = {
  lang: 'ds',
  data: {
    'BUTTONS': {
      'Search': 'Søg',
      'Ok': 'Ok'
    },
    'FIELDS': {
      'StartingPoint': 'Udgangspunktet',
      'Destination': 'Bestemmelsessted',
    },
    'MESSAGES': {
      'InsertText': 'Tryk på den grønne OK knap'
    }
  }
};
