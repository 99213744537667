export const locale = {
    lang: 'ds',
    data: {
        'HEADER':{
            'Title':'Beregnet trekant'
        },
        'FIELDS': {
            'Baseline': 'Bredde',
            'Height': 'Højde',
            'Area': 'Arealet',
            'CalculateArea':'Beregnet areal'
        },
        'BUTTONS': {
            'CalculateArea': 'Beregnet areal',
            'BACK': 'Tilbage',
        },
    }
}