export const locale = {
    lang: 'ds',
    data: {
        'TITLE': {
            'HeaderForOfferAcceptedNotification': 'Notifikation til kunde på tilbud er godkendt',
        },
        'FIELDS': {
            'DaysToSend': 'Send x dage før',
            'SMSText': 'SMS tekst',
            'NotificationText': 'Notifikations tekst',
            'EmailText': 'E-mail tekst',
            'ExecuteTime':'Udfør tid',
            'IsCreateReminder':'Opret påmindelse?',
            'EmailTemplate' :'Email skabelon',
            'NotesLabel':"Slet ikke et præfiks '@'-tegnord.",
        },
        'VALIDATIONS': {
            'DaysToSend': 'dage at sende er påkrævet',
            'SMSText': 'SMS-tekst er påkrævet',
            'NotificationText': 'Notifikations tekst er påkrævet',
            'EmailText': 'E-mail-tekst er påkrævet',
        },
        'MESSAGES': {
            'Saved': 'Gemt succesfuldt.',
        },
        'Label': {
            // 'InquirySMSLabel': 'Fjern venligst ikke @CustomerName ,@InquiryDate, @InquiryNumber og @Address fra SMS tekst.',
            // 'PlanningLeaveSMSLabel': 'Fjern venligst ikke @CoworkerName og @LeaveDate fra SMS tekst.',
            // 'OfferSMSLabel': 'Fjern venligst ikke @CustomerName, @OfferNumber og @OfferAddress fra SMS tekst.',
            // 'InquiryNotificationLabel': 'Fjern venligst ikke @CustomerName ,@InquiryDate, @InquiryNumber og @Address fra notifikations tekst.',
            // 'PlanningLeaveNotificationLabel': 'Fjern venligst ikke @CoworkerName og @LeaveDate fra notifikations tekst.',
            // 'OfferNotificationLabel': 'Fjern venligst ikke @CustomerName @OfferNumber og @OfferAddress fra notifikations tekst.',
            'OfferAcceptedNotificationLabel': 'Fjern venligst ikke @CustomerName @OfferNumber og @OfferAddress fra notifikations tekst.',
        },
        'BUTTONS': {
            'Save': 'Gem',
        }
    }
};
