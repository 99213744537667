export const locale = {
    lang: 'ds',
    data: {
        'BUTTONS': {
            'NEWCOWORKER': 'Ny medarbejder',
            'BACK': 'Tilbage',
            'SAVE': 'Gem',
        },
        'FIELDS': {
            'CoworkerList': 'Medarbejderliste',
            'Name': 'Navn',
            
        }
    }
};

