import { Component, OnInit, ElementRef, ViewChild,  Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { CommonService } from 'app/core/services/common.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { RequestRequisition } from 'app/main/model/RequestBase';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { Project, projectRequisition } from 'app/main/model/Project';
import { QuickInvoiceMaterials } from 'app/main/model/Material';
import { ProjectService } from 'app/main/pages/project/project.service';
import { ProjectRequisitionService } from 'app/main/pages/project/project.requisition.service';
@Component({
    selector: 'vibe-move-material',
    templateUrl: './move-material.component.html',
    styleUrls: ['./move-material.component.scss']
})
export class MoveMaterialComponent implements OnInit {
    @ViewChild("barCode", { static: false }) barcodeFeild: ElementRef;
    showMessage: boolean = false;
    ObjProject: Project;
    showSpinner = false;
    projectMaterials: QuickInvoiceMaterials[] = [];
    Requisition: projectRequisition;
    RequisitionId: any;
    selectedIndex: number = 0;
    // roleName: any;
    isShowMaterial: boolean = true;
    materialList: any[] = [];
    isCheckedAll: boolean = true;
    isSelectedMaterial: boolean = false;
    projectNumber: any;
    requitionNum: any;
    showRequistionList: boolean = false;
    requisitionList: any = [];
    projectAndRequistion: any = {};
    toRequistionId: string = "";
    //Initiators
    constructor(private translationLoader: FuseTranslationLoaderService,
        public dialogRef: MatDialogRef<MoveMaterialComponent>,
        private projectService: ProjectService,
        private projectRequisitionService: ProjectRequisitionService,
        private commonService: CommonService,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
        this.showSpinner = true;
        this.Requisition = new projectRequisition();
        this.ObjProject = new Project();
        this.ObjProject.vat = 0;
        this.ObjProject.includePrice = 0;
        if (this.ObjProject)
            this.ObjProject.statusId = 1;
        if (this.data) {
            this.RequisitionId = this.data.RequisitionId;
            this.ObjProject.sagId = this.data.sagId;
            //this.roleName = this.data.roleName;
            this.projectMaterials = this.data.projectMaterials;
            this.ObjProject = this.data.ObjProject;
            this.Requisition = this.data.Requisition;
            this.materialList= this.data.projectMaterials;
            this.projectNumber = this.ObjProject.sagNumber;
            this.requitionNum = this.data.requisitionNumber;
        }
        this.materialList.filter(x=>{ x.isChecked=false; x.requestQuantity = x.quantity; });
        this.onSetActive();
        this.showSpinner = false;
    }

    getTotalPrice(qty, price) {
        var isNegative = false;
        if (qty < 0)
            isNegative = true;
        if (price < 0)
            isNegative = true;
        var total = (qty * price);
        if (isNegative)
            total = -Math.abs(total)
        return total;
    }

    onSetActive() {        
        let atleastOneUncheckedMat = this.materialList.find(i => i.isChecked==true)
        if(atleastOneUncheckedMat) 
            this.isSelectedMaterial=true;
        else 
            this.isSelectedMaterial=false;
    }

    getFailedTone() {
        this.commonService.playFailedTone();
    }

    getSuccessTone() {
        this.commonService.playSuccessTone();
    }

    selectProjectEventHandler(event) {
        this.projectAndRequistion.project = event;
        var modelRequisition = new RequestRequisition();
        modelRequisition.ProjectId = event.sagId;
        this.getRequisitionList(modelRequisition);
    }

    back(){
        if(this.selectedIndex == 0)
            this.dialogRef.close(false);
        this.selectedIndex = 0;
    }

    selectProject(){
        this.selectedIndex = 1;
        this.toRequistionId=""
    }

    getRequisitionList(model: RequestRequisition) {
        this.showSpinner = true;
        this.projectRequisitionService.Get<ResponseBase>(model.ProjectId, model).subscribe({
            next: (response: ResponseBase) => {
                if (response) {
                    this.showRequistionList = true;
                    this.selectedIndex = 2;
                    this.requisitionList = response.result;
                }
                else
                    this.requisitionList = [];
            },
            error: err => {
                this.requisitionList = [];
                this.showRequistionList = false;
                this.selectedIndex = 1;
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    getRequistionNumber(requistion) {
        this.projectAndRequistion.requistion = requistion;
        this.toRequistionId = requistion.id;
    }

    moveMaterials() {
        var tempMaterialLiost = this.materialList.filter(x=>x.isChecked==true);
        this.showSpinner = true;
        this.projectService.MoveProjectMaterial(this.toRequistionId, tempMaterialLiost).subscribe(
            (response: ResponseBase) => {
                this.showSpinner = false;
                if (response) {
                    this.dialogRef.close(true);
                }
            },
            error => (err) => {
                this.showSpinner = false;
            });
    }

}
