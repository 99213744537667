import { Component, OnInit } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { TitleService } from 'app/core/services/title.service';
import { MaterialService } from '../../material.service';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { SupplierRequest, RequestBase, RequestSupplierMaterialPriceChangesList } from 'app/main/model/RequestBase';
import { Supplier } from 'app/main/model/Supplier';
import { Material, SuppliersMaterialPriceChanges } from 'app/main/model/Material';

import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { AlertMessageModelComponent } from '../../../alert-message-model/alert-message-model.component';

@Component({
    selector: "supplier-setting",
    templateUrl: "./supplier-setting.component.html",
    styleUrls: ["./supplier-setting.component.scss"]
})

export class SupplierSettingComponent implements OnInit {
    showSpinner: boolean = false;
    supplierRequest: SupplierRequest;
    supplierList: Supplier[] = [];
    SupplierPriceChangesList: [] = [];
    SupplierId: string;
    constructor(private translationLoader: FuseTranslationLoaderService,
        private titleService: TitleService,
        private materialService: MaterialService,
        private translate: TranslateService,
        private dialog: MatDialog,
        private notificationsService: NotificationsService,
        private router: Router) {
        this.translationLoader.loadTranslations(english, danish);
        this.titleService.SetTitle("TITLE_SUPPLIER_SETTINGS");
    }

    ngOnInit() {
        this.supplierRequest = new SupplierRequest();
        this.supplierRequest.Index = 1;
        this.supplierRequest.Limit = 100;
        this.GetSupplierList();
        var modal = new RequestSupplierMaterialPriceChangesList();
        modal.Index = 1;
        modal.Limit = 500;
        this.getSupplierMaterialPriceChangeList(modal);
    }

    GetSupplierList() {
        this.materialService.GetSupplierList<ResponseBase>(this.supplierRequest)
            .subscribe({
                next: (response: ResponseBase) => {
                    if(response.result){
                        this.supplierList = response.result.sort((a, b) => a.business.localeCompare(b.business));
                    }
                },
                error: err => { },
                complete: () => { }
            });
    }

    getSupplierMaterialPriceChangeList(requestModal) {
        this.showSpinner = true;
        this.materialService.GetSupplierMaterialPriceChangeList<ResponseBase>(requestModal)
            .subscribe({
                next: (response: ResponseBase) => {
                    if (response) {
                        this.showSpinner = false;
                        this.SupplierPriceChangesList = response.result;
                    } else {
                        this.SupplierPriceChangesList = [];
                    }
                },
                error: err => {
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });
                },
                complete: () => {
                    this.showSpinner = false;
                }
            });
    }

    search() {
        var requestModal = new RequestSupplierMaterialPriceChangesList();
        if (this.SupplierId) {
            requestModal.SupplierID = this.SupplierId;
        }
        this.getSupplierMaterialPriceChangeList(requestModal)
    }

    undo(item) {
        
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: '200px',
            data: { Header: "Headers.UndoChanges" }
        });
        
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.materialService.UndoSupplierPriceChanges<ResponseBase>(item)
                    .subscribe({
                        next: (response: ResponseBase) => {
                            this.translate.get("MESSAGES.UndoChangesSuccess").subscribe({
                                next: (res) => {
                                    this.notificationsService.success(res);
                                    var requestModal = new RequestSupplierMaterialPriceChangesList();
                                    this.getSupplierMaterialPriceChangeList(requestModal)
                                },
                                error: err => {
                                }
                            });
                        },
                        error: err => { },
                        complete: () => { }
                    });
            }
        });
    }

    back(){
        this.router.navigate(['/settings'], { queryParams: { reqTabIndex: 2 } });
    }
}