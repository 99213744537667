import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl, ValidatorFn } from '@angular/forms';
import { OfferCategoryWithOfferDetail, FeatureJson, OfferCategoryAndWorkItem } from '../../../../model/ProjectOffer';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { FuseTranslationLoaderService } from '../../../../../core/services/translation-loader.service';
import { ProjectofferService } from './../../project-offer.service';
import { ResponseBase } from '../../../../model/ResponseBase';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { FormService } from '../../../../../core/services/form.service';
import { CommonService } from 'app/core/services/common.service';		


@Component({
  selector: 'app-edit-project-offer-feature',
  templateUrl: './edit-project-offer-feature.component.html',
  styleUrls: ['./edit-project-offer-feature.component.scss']
})
export class EditProjectOfferFeatureComponent implements OnInit {
  showSpinner: boolean = false;
  selectedOffer: OfferCategoryWithOfferDetail;
  offerItem: OfferCategoryAndWorkItem;
  constructor(private translationLoader: FuseTranslationLoaderService,
    private projectofferService: ProjectofferService,
    private notificationsService: NotificationsService,
    private translate: TranslateService,
    private commonService: CommonService,
    public dialogRef: MatDialogRef<EditProjectOfferFeatureComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog
   ) {
    this.translationLoader.loadTranslations(english, danish);
  }

  ngOnInit() {
    this.selectedOffer = this.data.slectedOffer;
    this.GetOfferCategories();
    this.offerItem = new OfferCategoryAndWorkItem();
    this.offerItem.OfferCategoryWorkItemList = [];
  }
  GetOfferCategories() {
    this.projectofferService.getSingleOfferCategory(this.selectedOffer.id)
      .subscribe((response: ResponseBase) => {
        this.showSpinner = false;
        if (response) {
          this.offerItem = new OfferCategoryAndWorkItem();
          this.offerItem.OfferCategory = response.result.offerCategory;
          this.offerItem.OfferCategoryWorkItemList = [];
          this.offerItem.OfferCategoryWorkItemList = response.result.offerCategoryWorkItemList;
        }
      },
        error => (err) => {
          this.showSpinner = false;
        });
  }

  cancel() {
    this.dialogRef.close();
  }

  save() {
    this.Update()
  }
  Update() {
    this.showSpinner = true;
    this.projectofferService.UpdateOfferFeature<ResponseBase>(this.offerItem.OfferCategory.id, this.offerItem)
      .subscribe((response: ResponseBase) => {
        this.showSpinner = false;
        if (response != null) {
          //this.offerFeature = response.result;
          //this.OfferOutput.emit(this.projectOffer.id);
          this.translate.get("MESSAGES.Success").subscribe({
            next: (res) => {
              this.notificationsService.success(res);
            },
            error: err => {
            }
          });
          this.dialogRef.close({ isUpdate: true, id: this.selectedOffer.id, response });
        }
        else {
          this.translate.get("MESSAGES.Failed").subscribe({
            next: (res) => {
              this.notificationsService.error(res);
              this.commonService.playFailedTone();
            },
            error: err => {
            }
          });
        }
      },
        error => (err) => {
          this.showSpinner = false;
          this.translate.get("MESSAGES.Failed").subscribe({
            next: (res) => {
              this.notificationsService.error(res);
            },
            error: err => {
            }
          });
        });
  }
}
