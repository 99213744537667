import { Component, OnInit, ViewChild, ElementRef, Input, Inject, Optional } from '@angular/core';
import { FuseTranslationLoaderService } from '../../../../../core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { Router } from '@angular/router';
import { AuthService } from '../../../../../core/services/auth.service';
import { TitleService } from '../../../../../core/services/title.service';
import { TranslateService } from '@ngx-translate/core';
import { DenmarkDigitFormate } from '../../../../../core/pipes/denmarkDigitFormate.pipe';
import { Material } from '../../../../model/Material';
import { MatRadioChange, MatDialogRef ,MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'vibe-pricetagsize',
    templateUrl: './pricetagsize.component.html',
    styleUrls: ['./pricetagsize.component.scss']
})
export class PriceTagSizeComponent implements OnInit {
    @Input("MaterialObject") materialObject: Material;
    showSpinner: boolean = false;
    //Arrays to dispaly rows in datatable
    // roleName: string;
    isValid: boolean = false;
    public denmarkDigitFormate = new DenmarkDigitFormate();
    elementType = 'img';
    printSize = "10.3*7";
    materialType: any;
    pricetagHeaderColor: any;
    materialObjectList: [] = [];
    layoutStyle: string = 'layout-portrait';
    //Initiators
    constructor(private translationLoader: FuseTranslationLoaderService,
        public dialogRef: MatDialogRef<PriceTagSizeComponent>,
        private _router: Router,
        private translate: TranslateService,
        private titleService: TitleService,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
        this.translationLoader.loadTranslations(english, danish);
        this.titleService.SetTitle("TITLE_MATERIAL");
    }

    ngOnInit() {
    }

    radioChange(event: MatRadioChange) {
        this.layoutStyle = "";
        this.printSize = event.value;
        if (this.printSize == '15.4*10.3') {
            this.layoutStyle = 'layout-landscape';
        } else {
            this.layoutStyle = 'layout-portrait'
        }
    }

    onCloseDialog(){
        this.dialogRef.close(this.printSize)
    }
}
