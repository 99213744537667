import { ClaimModel } from "./AuthModel";

export class UserProfile {
  public UserName: string;
  public token: string;
  public Id: string;
  public role: string;
  public coworkerId: string;
  public email: string;
  public sub: string;
  public claims: ClaimModel[] = [];

  constructor() { }
}