export const locale = {
    lang: 'en',
    data: {
        'Buttons': {
            'Plan': 'Plan',
            'Back': 'Back',
            'Replace': 'Erstat',
        },
        'FIELDS':{
            'StartDate': 'StartDate',
            'EndDate': 'EndDate',
            'FromCoworker': 'From Coworker',
            'ToCoworker': 'To Coworker',
        },
        "MESSAGES": {
            'Updated': 'Updated Successfully.',
            'Failed': 'Failed',
            'Deleted': 'Deleted successfully',
            "NORECORD": "No Record Found",
            'Created': 'Created successfully',
        },
    }
}