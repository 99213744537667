import { Component, OnInit } from '@angular/core';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { ResponseBase } from "app/main/model/ResponseBase";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { Settings, SettingForDriveValue, SettingForDropbox, SettingForOnedrive } from "app/main/model/Settings";
import { SettingsService } from 'app/main/pages/settings/settings.service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
    selector: 'app-dropbox-path-setting',
    templateUrl: './dropbox-path-setting.component.html',
    styleUrls: ['./dropbox-path-setting.component.scss']
})
export class DropboxPathSettingComponent implements OnInit {
    showSpinner: boolean = false;
    DriveObj: Settings;
    DriveSettings: SettingForDriveValue;
    path: string = "";
    settingMasterValues: any[] = [];
    driveOptions: string = "";
    token: string = "";
    dropboxForm: FormGroup;
    onedriveForm: FormGroup;

    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private formBuilder: FormBuilder,
        private settingsService: SettingsService) { this.translationLoaderService.loadTranslations(danish, english) }

    ngOnInit() {
        this.settingMasterValues = Object.assign([], JSON.parse(localStorage.getItem("settingMasterValues")));
        this.getSettingsForDocumentDetails();
        var tempSettingObject = this.settingMasterValues.find(s => s.key1 == "Documents" && s.key2 == "DropboxPath");
        if (tempSettingObject) {
            this.path = tempSettingObject.value;
            console.log(this.path, "path")
        }
        this.dropboxForm = this.formBuilder.group({
            Token: [null],
            Path: [null],
        });
        this.onedriveForm = this.formBuilder.group({
            ClientId: [null],
            ClientSecret: [null],
            TenantId: [null],
            UserEmail: [null],
            Path: [null],
        });
    }

    getSettingsForDocumentDetails() {
        this.showSpinner = true;
        this.settingsService.GetSettingsValue<ResponseBase>("Documents", null, null).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.result && response.result.myResponse.length > 0) {
                    this.DriveObj = response.result.myResponse.find(o => o.key1 == "Documents" && o.key2 == "DriveOptions");
                    if (this.DriveObj != null) {
                        this.DriveSettings = JSON.parse(this.DriveObj.value);
                        this.driveOptions = this.DriveSettings.DocumentDriveType;
                        this.dropboxForm.patchValue(this.DriveSettings.Dropbox);
                        this.onedriveForm.patchValue(this.DriveSettings.Onedrive);
                    }
                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    changeSettingsForDropboxPath() {
        this.showSpinner = true;
        this.DriveSettings.DocumentDriveType = this.driveOptions;
        this.DriveSettings.Onedrive = this.onedriveForm.value;
        this.DriveSettings.Dropbox = this.dropboxForm.value;
        this.DriveObj.value = JSON.stringify(this.DriveSettings);
        this.settingsService.UpdateSettingWithTemplate<ResponseBase>(this.DriveObj).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.result) {
                    this.DriveObj = response.result;
                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinner = false;
                this.changeInLocalStorage(this.DriveObj.id, this.DriveObj)
            }
        });
    }

    changeInLocalStorage(ID, new_obj) {
        if (this.settingMasterValues.find(obj => obj.id == ID)) {
            this.settingMasterValues.forEach((old_obj, index) => {
                if (old_obj.id == ID) {
                    this.settingMasterValues[index] = new_obj
                }
            })
        } else {
            this.settingMasterValues.push(new_obj)
        }
        localStorage.setItem("settingMasterValues", JSON.stringify(this.settingMasterValues));
    }
}
