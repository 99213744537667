import { FuseNavigation } from '@fuse/types';
import { RoleClaims } from 'app/constant/roleClaims'

// export const SuperAdminNavigation: FuseNavigation[] = [
//     {
//         id: 'applications',
//         title: 'Applications',
//         translate: 'NAV.APPLICATIONS',
//         type: 'group',
//         children: [
//             {
//                 'id': 'dashboard',
//                 'title': 'Dashboard',
//                 'translate': 'NAV.DASHBOARD.TITLE',
//                 'type': 'item',
//                 'icon': 'dashboard',
//                 'url': '/dashboard',
//                 'color': '#631878',
//             },
//             {
//                 'id': 'customers',
//                 'title': 'Customers',
//                 'translate': 'NAV.CUSTOMERS.TITLE',
//                 'type': 'item',
//                 'icon': 'account_box',
//                 'url': '/customers',
//                 'color': '#72bb53',
//             },
//             {
//                 'id': 'matrials',
//                 'title': 'Materials',
//                 'translate': 'NAV.MATERIALS.TITLE',
//                 'type': 'item',
//                 'icon': 'invert_colors',
//                 'url': '/materials',
//                 'color': '#ff6624',
//             },
//             {
//                 'id': 'projects',
//                 'title': 'Projects',
//                 'translate': 'NAV.PROJECTS.TITLE',
//                 'type': 'item',
//                 'icon': 'receipt',
//                 'url': '/projects',
//                 'color': '#0c59cf',
//             },
//             {
//                 'id': 'offer',
//                 'title': 'Offer',
//                 'translate': 'NAV.OFFER.TITLE',
//                 'type': 'item',
//                 'icon': 'local_offer',
//                 'url': '/offers',
//                 'color': '#6B5935',
//             },
//             {
//                 'id': 'documents',
//                 'title': 'Documents',
//                 'translate': 'NAV.DOCUMENTS.TITLE',
//                 'type': 'item',
//                 'icon': 'insert_drive_file',
//                 'url': '/documents',
//                 'color': '#e61610',
//             },
//             {
//                 'id': 'workhours',
//                 'title': 'Workhours',
//                 'translate': 'NAV.WORKHOURS.TITLE',
//                 'type': 'item',
//                 'icon': 'access_time',
//                 'url': '/workhours',
//                 'color': '#aa671d',
//             },
//             {
//                 'id': 'coWorkers',
//                 'title': 'Co-workers',
//                 'translate': 'NAV.COWORKERS.TITLE',
//                 'type': 'item',
//                 'icon': 'people',
//                 'url': '/coWorkers',
//                 'color': '#8c5656',
//             },
//             {
//                 'id': 'companyInfo',
//                 'title': 'Company Info',
//                 'translate': 'NAV.COMPANYINFO.TITLE',
//                 'type': 'item',
//                 'icon': 'info',
//                 'url': '/companyInfo',
//                 'color': '#91e4fb',
//             },
//             {
//                 'id': 'planning',
//                 'title': 'planning',
//                 'translate': 'NAV.PLANNING.TITLE',
//                 'type': 'item',
//                 'icon': 'calendar_today',
//                 'url': '/planning',
//                 'color': '#00549d',
//             },
//             {
//                 'id': 'reminders',
//                 'title': 'Reminders',
//                 'translate': 'NAV.REMINDERS.TITLE',
//                 'type': 'item',
//                 'icon': 'add_alert',
//                 'url': '/reminders',
//                 'color': '#c7361c',
//             },
//             {
//                 'id': 'Suppliers',
//                 'title': 'Suppliers',
//                 'translate': 'NAV.SUPPLIERS.TITLE',
//                 'type': 'item',
//                 'icon': 'local_shipping',
//                 'url': '/suppliers',
//                 'color': '#a20c0c',
//             },
//             {
//                 'id': 'statistics',
//                 'title': 'Statistics',
//                 'translate': 'NAV.STATISTICS.TITLE',
//                 'type': 'item',
//                 'icon': 'signal_cellular_alt',
//                 'url': '/statistics',
//                 'color': '#b56e31',
//             },
//             {
//                 'id': 'settings',
//                 'title': 'Settings',
//                 'translate': 'NAV.SETTINGS.TITLE',
//                 'type': 'item',
//                 'icon': 'settings',
//                 'url': '/settings',
//                 'color': '#582015',
//             },
//             {
//                 'id': 'qualityTest',
//                 'title': 'QualityTest',
//                 'translate': 'NAV.QUALITYTEST.TITLE',
//                 'type': 'item',
//                 'icon': 'equalizer',
//                 'url': '/qualitytest',
//                 'color': '#582015',
//             },
//             {
//                 'id': 'toolsandladders',
//                 'title': 'Toolsandladders',
//                 'translate': 'NAV.TOOLSLADDERS.TITLE',
//                 'type': 'item',
//                 'icon': 'text_fields',
//                 'url': '/dashboard/livemap',
//                 'color': '#582015',
//             }
//         ]
//     }
// ];

// export const AdminNavigation: FuseNavigation[] = [
//     {
//         id: 'applications',
//         title: 'Applications',
//         translate: 'NAV.APPLICATIONS',
//         type: 'group',
//         children: [
//             {
//                 'id': 'dashboard',
//                 'title': 'Dashboard',
//                 'translate': 'NAV.DASHBOARD.TITLE',
//                 'type': 'item',
//                 'icon': 'dashboard',
//                 'url': '/dashboard',
//                 'color': '#631878',
//                 'displayInDashboard': false,
//                 'claim': 'F6CFC7CA-E200-40EC-AD5E-D6E8A90D112A'
//             },
//             {
//                 'id': 'customers',
//                 'title': 'Customers',
//                 'translate': 'NAV.CUSTOMERS.TITLE',
//                 'type': 'item',
//                 'icon': 'account_box',
//                 'url': '/customers',
//                 'color': '#72bb53',
//                 'displayInDashboard': true,
//                 'claim': null
//             },
//             {
//                 'id': 'matrials',
//                 'title': 'Materials',
//                 'translate': 'NAV.MATERIALS.TITLE',
//                 'type': 'item',
//                 'icon': 'invert_colors',
//                 'url': '/materials',
//                 'color': '#ff6624',
//                 'displayInDashboard': true,
//                 'claim': null
//             },
//             {
//                 'id': 'projects',
//                 'title': 'Projects',
//                 'translate': 'NAV.PROJECTS.TITLE',
//                 'type': 'item',
//                 'icon': 'receipt',
//                 'url': '/projects',
//                 'color': '#0c59cf',
//                 'displayInDashboard': true,
//                 'claim':'B1A33A6C-5314-4496-88CF-0110D2675FDA'
//             },
//             {
//                 'id': 'offer',
//                 'title': 'Offer',
//                 'translate': 'NAV.OFFER.TITLE',
//                 'type': 'item',
//                 'icon': 'local_offer',
//                 'url': '/offers',
//                 'color': '#6B5935',
//                 'displayInDashboard': true,
//                 'claim': null
//             },
//             {
//                 'id': 'documents',
//                 'title': 'Documents',
//                 'translate': 'NAV.DOCUMENTS.TITLE',
//                 'type': 'item',
//                 'icon': 'insert_drive_file',
//                 'url': '/documents',
//                 'color': '#e61610',
//                 'displayInDashboard': true,
//                 'claim': null
//             },
//             {
//                 'id': 'workhours',
//                 'title': 'Workhours',
//                 'translate': 'NAV.WORKHOURS.TITLE',
//                 'type': 'item',
//                 'icon': 'access_time',
//                 'url': '/workhours',
//                 'color': '#aa671d',
//                 'displayInDashboard': true,
//                 'claim': null
//             },
//             {
//                 id: 'coWorkers',
//                 title: 'Co-workers',
//                 translate: 'NAV.COWORKERS.TITLE',
//                 type: 'collapsable',
//                 icon: 'people',
//                 color: '#003748',
//                 displayInDashboard: true,
//                 children: [
//                     {
//                         id: 'coWorkers',
//                         title: 'Co-workers',
//                         translate: 'NAV.COWORKERS.TITLE',
//                         type: 'item',
//                         url: '/coWorkers',
//                         color: '#003748',
//                         icon: 'people',
//                         displayInDashboard: true,
//                         claim: null
//                     },
//                     {
//                         id: 'payroll',
//                         title: 'Payroll',
//                         translate: 'NAV.PAYROLL.TITLE',
//                         type: 'item',
//                         url: '/payroll',
//                         color: '#4AA05F',
//                         icon: 'monetization_on',
//                         displayInDashboard: true,
//                         claim: null
//                     },
//                     {
//                         id: 'termsofemployment',
//                         title: 'Terms Of Employment',
//                         translate: 'NAV.TERMSOFEMPLOYMENT.TITLE',
//                         type: 'item',
//                         url: '/termsofemployment',
//                         color: '#0BB1E5',
//                         icon: 'assignment_ind',
//                         displayInDashboard: true,
//                         claim: null
//                     }
//                 ]
//             },
//             {
//                 'id': 'inquiry',
//                 'title': 'Inquiry',
//                 'translate': 'NAV.INQUIRY.TITLE',
//                 'type': 'item',
//                 'icon': 'phone_in_talk',
//                 'url': '/inquiry',
//                 'color': '#8c5656',
//                 'displayInDashboard': true,
//                 'claim': null
//             },
//             {
//                 'id': 'companyInfo',
//                 'title': 'Company Info',
//                 'translate': 'NAV.COMPANYINFO.TITLE',
//                 'type': 'item',
//                 'icon': 'info',
//                 'url': '/companyInfo',
//                 'color': '#91e4fb',
//                 'displayInDashboard': true,
//                 'claim': null
//             },
//             {
//                 'id': 'report',
//                 'title': 'Report',
//                 'translate': 'NAV.REPORT.TITLE',
//                 'type': 'item',
//                 'icon': 'report',
//                 'url': '/report',
//                 'color': '#034152',
//                 'displayInDashboard': true,
//                 'claim': null
//             },
//             {
//                 'id': 'quickinvoice',
//                 'title': 'QuickInvoice',
//                 'translate': 'NAV.STORESALES.TITLE',
//                 'type': 'item',
//                 'icon': 'devices_other',
//                 'url': '/quickinvoice',
//                 'color': '#e210d2',
//                 'displayInDashboard': true,
//                 'claim': null
//             },
//             {
//                 'id': 'planning',
//                 'title': 'Planning',
//                 'translate': 'NAV.PLANNING.TITLE',
//                 'type': 'item',
//                 'icon': 'date_range',
//                 'url': '/planning',
//                 'color': '#00549d',
//                 'displayInDashboard': true,
//                 'claim': null
//             },
//             {
//                 'id': 'reminders',
//                 'title': 'Reminders',
//                 'translate': 'NAV.REMINDERS.TITLE',
//                 'type': 'item',
//                 'icon': 'add_alert',
//                 'url': '/reminders',
//                 'color': '#c7361c',
//                 'displayInDashboard': true,
//                 'claim': null
//             },
//             {
//                 'id': 'Suppliers',
//                 'title': 'Suppliers',
//                 'translate': 'NAV.SUPPLIERS.TITLE',
//                 'type': 'item',
//                 'icon': 'local_shipping',
//                 'url': '/suppliers',
//                 'color': '#a20c0c',
//                 'displayInDashboard': true,
//                 'claim': null
//             },
//             // {
//             //     'id': 'CarInformations',
//             //     'title': 'CarInformations',
//             //     'translate': 'NAV.CARINFORMATIONS.TITLE',
//             //     'type': 'item',
//             //     'icon': 'commute',
//             //     'url': '/carinformations',
//             //     'color': '#0B0830',
//             // },
//             {
//                 id: 'nanolink',
//                 title: 'Co-workers',
//                 translate: 'NAV.TOOLSLADDERS.TITLE',
//                 type: 'collapsable',
//                 icon: 'settings_remote',
//                 color: '#003748',
//                 displayInDashboard: false,
//                 children: [
//                     {
//                         id: 'assets',
//                         title: 'Assets',
//                         translate: 'NAV.ASSETS.TITLE',
//                         type: 'item',
//                         url: '/nanolink/assets',
//                         displayInDashboard: true,
//                         color: '#72bb53',
//                         icon: 'settings_remote',
//                         claim: null
//                     },
//                     {
//                         id: 'devices',
//                         title: 'Devices',
//                         translate: 'NAV.DEVICE.TITLE',
//                         type: 'item',
//                         url: '/nanolink/devices',
//                         displayInDashboard: true,
//                         color: '#6aa641',
//                         icon: 'router',
//                         claim: null
//                     },
//                     {
//                         id: 'carInfo',
//                         title: 'Car Info',
//                         translate: 'NAV.CARINFO.TITLE',
//                         type: 'item',
//                         url: '/carinformations',
//                         displayInDashboard: true,
//                         color: '#DD3437 ',
//                         icon: 'commute',
//                         claim: null
//                     },
//                     {
//                         id: 'livemap',
//                         title: 'Live Map',
//                         translate: 'NAV.LIVEMAP.TITLE',
//                         type: 'item',
//                         url: '/dashboard/livemap',
//                         displayInDashboard: true,
//                         color: '#49ADAB',
//                         icon: 'public',
//                         claim: null
//                     },
//                 ]
//             },
//             {
//                 'id': 'statistics',
//                 'title': 'Statistics',
//                 'translate': 'NAV.STATISTICS.TITLE',
//                 'type': 'item',
//                 'icon': 'signal_cellular_alt',
//                 'url': '/statistics',
//                 'color': '#b56e31',
//                 'displayInDashboard': true,
//                 'claim': null
//             },
//             {
//                 'id': 'settings',
//                 'title': 'Settings',
//                 'translate': 'NAV.SETTINGS.TITLE',
//                 'type': 'item',
//                 'icon': 'settings',
//                 'url': '/settings',
//                 'color': '#582015',
//                 'displayInDashboard': true,
//                 'claim': null
//             },
//             {
//                 'id': 'qualityTest',
//                 'title': 'QualityTest',
//                 'translate': 'NAV.QUALITYTEST.TITLE',
//                 'type': 'item',
//                 'icon': 'equalizer',
//                 'url': '/qualitytest',
//                 'color': '#582015',
//                 'displayInDashboard': true,
//                 'claim': null
//             },
//             //{
//             //    'id': 'toolsandladders',
//             //    'title': 'Toolsandladders',
//             //    'translate': 'NAV.TOOLSLADDERS.TITLE',
//             //    'type': 'item',
//             //    'icon': 'text_fields',
//             //    'url': '/dashboard/vehicle',
//             //    'color': '#582015',
//             //},
            
//         ]
//     }
// ];

export const navigation: FuseNavigation[] = [
    {
        id: 'applications',
        title: 'Applications',
        translate: 'NAV.APPLICATIONS',
        type: 'group',
        children: [
            {
                'id': 'dashboard',
                'title': 'Dashboard',
                'translate': 'NAV.DASHBOARD.TITLE',
                'type': 'item',
                'icon': 'dashboard',
                'url': '/dashboard',
                'color': '#631878',
                'displayInDashboard': false,
                'claim': RoleClaims.Dashboard_View
            },
            {
                'id': 'customers',
                'title': 'Customers',
                'translate': 'NAV.CUSTOMERS.TITLE',
                'type': 'item',
                'icon': 'account_box',
                'url': '/customers',
                'color': '#72bb53',
                'displayInDashboard': true,
                'claim': RoleClaims.Customer_View
            },
            {
                'id': 'matrials',
                'title': 'Materials',
                'translate': 'NAV.MATERIALS.TITLE',
                'type': 'item',
                'icon': 'invert_colors',
                'url': '/materials',
                'color': '#ff6624',
                'displayInDashboard': true,
                'claim': RoleClaims.Material_View
            },
            {
                'id': 'projects',
                'title': 'Projects',
                'translate': 'NAV.PROJECTS.TITLE',
                'type': 'item',
                'icon': 'receipt',
                'url': '/projects',
                'color': '#0c59cf',
                'displayInDashboard': true,
                'claim': [RoleClaims.Project_View, RoleClaims.Own_Project_View]
            },
            {
                'id': 'offer',
                'title': 'Offer',
                'translate': 'NAV.OFFER.TITLE',
                'type': 'item',
                'icon': 'local_offer',
                'url': '/offers',
                'color': '#6B5935',
                'displayInDashboard': true,
                'claim': RoleClaims.Offer_View
            },
            {
                'id': 'documents',
                'title': 'Documents',
                'translate': 'NAV.DOCUMENTS.TITLE',
                'type': 'item',
                'icon': 'insert_drive_file',
                'url': '/documents',
                'color': '#e61610',
                'displayInDashboard': true,
                'claim': RoleClaims.Document_View
            },
            {
                'id': 'workhours',
                'title': 'Workhours',
                'translate': 'NAV.WORKHOURS.TITLE',
                'type': 'item',
                'icon': 'access_time',
                'url': '/workhours',
                'color': '#aa671d',
                'displayInDashboard': true,
                'claim': RoleClaims.WorkHours_View
            },
            {
                id: 'coWorkers',
                title: 'Co-workers',
                translate: 'NAV.COWORKERS.TITLE',
                type: 'collapsable',
                icon: 'people',
                color: '#003748',
                displayInDashboard: true,
                claim :null,
                children: [
                    {
                        id: 'coWorkers',
                        title: 'Co-workers',
                        translate: 'NAV.COWORKERS.TITLE',
                        type: 'item',
                        url: '/coWorkers',
                        color: '#003748',
                        icon: 'people',
                        displayInDashboard: true,
                        claim: RoleClaims.Coworker_View
                    },
                    {
                        id: 'payroll',
                        title: 'Payroll',
                        translate: 'NAV.PAYROLL.TITLE',
                        type: 'item',
                        url: '/payroll',
                        color: '#4AA05F',
                        icon: 'monetization_on',
                        displayInDashboard: true,
                        claim: RoleClaims.Payroll_View
                    },
                    {
                        id: 'termsofemployment',
                        title: 'Terms Of Employment',
                        translate: 'NAV.TERMSOFEMPLOYMENT.TITLE',
                        type: 'item',
                        url: '/termsofemployment',
                        color: '#0BB1E5',
                        icon: 'assignment_ind',
                        displayInDashboard: true,
                        claim: RoleClaims.TermsofEmployment_View
                    }
                ]
            },
            {
                'id': 'inquiry',
                'title': 'Inquiry',
                'translate': 'NAV.INQUIRY.TITLE',
                'type': 'item',
                'icon': 'phone_in_talk',
                'url': '/inquiry',
                'color': '#8c5656',
                'displayInDashboard': true,
                'claim': RoleClaims.Inquiry_View
            },
            {
                'id': 'companyInfo',
                'title': 'Company Info',
                'translate': 'NAV.COMPANYINFO.TITLE',
                'type': 'item',
                'icon': 'info',
                'url': '/companyInfo',
                'color': '#91e4fb',
                'displayInDashboard': true,
                'claim': RoleClaims.CompanyInfo_View
            },
            {
                'id': 'report',
                'title': 'Report',
                'translate': 'NAV.REPORT.TITLE',
                'type': 'item',
                'icon': 'report',
                'url': '/report',
                'color': '#034152',
                'displayInDashboard': true,
                'claim': RoleClaims.Report_View
            },
            {
                'id': 'quickinvoice',
                'title': 'QuickInvoice',
                'translate': 'NAV.STORESALES.TITLE',
                'type': 'item',
                'icon': 'devices_other',
                'url': '/quickinvoice',
                'color': '#e210d2',
                'displayInDashboard': true,
                'claim': RoleClaims.QuickInvoice_View
            },
            {
                'id': 'planning',
                'title': 'Planning',
                'translate': 'NAV.PLANNING.TITLE',
                'type': 'item',
                'icon': 'date_range',
                'url': '/planning',
                'color': '#00549d',
                'displayInDashboard': true,
                'claim': [RoleClaims.Planning_View, RoleClaims.Own_Planning_View]
            },
            {
                'id': 'reminders',
                'title': 'Reminders',
                'translate': 'NAV.REMINDERS.TITLE',
                'type': 'item',
                'icon': 'add_alert',
                'url': '/reminders',
                'color': '#c7361c',
                'displayInDashboard': true,
                'classes':'custom-reminder',
                'claim': RoleClaims.Reminder_View
            },
            {
                'id': 'Suppliers',
                'title': 'Suppliers',
                'translate': 'NAV.SUPPLIERS.TITLE',
                'type': 'item',
                'icon': 'local_shipping',
                'url': '/suppliers',
                'color': '#a20c0c',
                'displayInDashboard': true,
                'claim': RoleClaims.Supplier_View
            },
            {
                id: 'nanolink',
                title: 'Co-workers',
                translate: 'NAV.TOOLSLADDERS.TITLE',
                type: 'collapsable',
                icon: 'settings_remote',
                color: '#003748',
                displayInDashboard: false,
                claim :null,
                children: [
                    {
                        id: 'assets',
                        title: 'Assets',
                        translate: 'NAV.ASSETS.TITLE',
                        type: 'item',
                        url: '/nanolink/assets',
                        displayInDashboard: true,
                        color: '#72bb53',
                        icon: 'settings_remote',
                        claim: RoleClaims.Assets_View
                    },
                    {
                        id: 'devices',
                        title: 'Devices',
                        translate: 'NAV.DEVICE.TITLE',
                        type: 'item',
                        url: '/nanolink/devices',
                        displayInDashboard: true,
                        color: '#6aa641',
                        icon: 'router',
                        claim: RoleClaims.NanoChips_View
                    },
                    {
                        id: 'carInfo',
                        title: 'Car Info',
                        translate: 'NAV.CARINFO.TITLE',
                        type: 'item',
                        url: '/carinformations',
                        displayInDashboard: true,
                        color: '#DD3437 ',
                        icon: 'commute',
                        claim: RoleClaims.CarInfo_View
                    },
                    {
                        id: 'livemap',
                        title: 'Live Map',
                        translate: 'NAV.LIVEMAP.TITLE',
                        type: 'item',
                        url: '/dashboard/livemap',
                        displayInDashboard: true,
                        color: '#49ADAB',
                        icon: 'public',
                        claim: RoleClaims.LiveMap_View
                    },
                ]
            },
            {
                'id': 'statistics',
                'title': 'Statistics',
                'translate': 'NAV.STATISTICS.TITLE',
                'type': 'item',
                'icon': 'signal_cellular_alt',
                'url': '/statistics',
                'color': '#b56e31',
                'displayInDashboard': true,
                'claim': RoleClaims.Statistics_View
            },
            {
                'id': 'settings',
                'title': 'Settings',
                'translate': 'NAV.SETTINGS.TITLE',
                'type': 'item',
                'icon': 'settings',
                'url': '/settings',
                'color': '#582015',
                'displayInDashboard': true,
                'claim': RoleClaims.Setting_View
            },
            {
                'id': 'qualityTest',
                'title': 'QualityTest',
                'translate': 'NAV.QUALITYTEST.TITLE',
                'type': 'item',
                'icon': 'equalizer',
                'url': '/qualitytest',
                'color': '#582015',
                'displayInDashboard': true,
                'claim': RoleClaims.QualityTest_View
            },
            {
                'id': 'projectMaterial',
                'title': 'ProjectMaterial',
                'translate': 'NAV.SUPPLIERQUICKMATERIAL.TITLE',
                'type': 'item',
                'icon': 'playlist_add',
                'url': '/project/supplier-quick-material',
                'color': '#9D0707',
                'displayInDashboard': true,
                'claim': RoleClaims.Supplier_Quick_Material
            },
            
        ]
    }
];

// export const KontorNavigation: FuseNavigation[] = [
//     {
//         id: 'applications',
//         title: 'Applications',
//         translate: 'NAV.APPLICATIONS',
//         type: 'group',
//         children: [
//             {
//                 'id': 'dashboard',
//                 'title': 'Dashboard',
//                 'translate': 'NAV.DASHBOARD.TITLE',
//                 'type': 'item',
//                 'icon': 'dashboard',
//                 'url': '/dashboard',
//                 'color': '#631878',

//             },
//             {
//                 'id': 'customers',
//                 'title': 'Customers',
//                 'translate': 'NAV.CUSTOMERS.TITLE',
//                 'type': 'item',
//                 'icon': 'account_box',
//                 'url': '/customers',
//                 'color': '#72bb53',

//             },
//             {
//                 'id': 'matrials',
//                 'title': 'Materials',
//                 'translate': 'NAV.MATERIALS.TITLE',
//                 'type': 'item',
//                 'icon': 'invert_colors',
//                 'url': '/materials',
//                 'color': '#ff6624',

//             },
//             {
//                 'id': 'projects',
//                 'title': 'Projects',
//                 'translate': 'NAV.PROJECTS.TITLE',
//                 'type': 'item',
//                 'icon': 'receipt',
//                 'url': '/projects',
//                 'color': '#0c59cf',

//             },
//             //{
//             //  'id': 'offer',
//             //  'title': 'Offer',
//             //  'translate': 'NAV.OFFER.TITLE',
//             //  'type': 'item',
//             //  'icon': 'local_offer',
//             //  'url': '/projectsoffers',
//             //  'color': '#6B5935',
//             //  
//             //},
//             {
//                 'id': 'documents',
//                 'title': 'Documents',
//                 'translate': 'NAV.DOCUMENTS.TITLE',
//                 'type': 'item',
//                 'icon': 'insert_drive_file',
//                 'url': '/documents',
//                 'color': '#e61610',


//             },
//             {
//                 'id': 'workhours',
//                 'title': 'Workhours',
//                 'translate': 'NAV.WORKHOURS.TITLE',
//                 'type': 'item',
//                 'icon': 'access_time',
//                 'url': '/workhours',
//                 'color': '#aa671d',


//             },
//             //{
//             //  'id': 'messages',
//             //  'title': 'Messages',
//             //  'translate': 'NAV.MESSAGES.TITLE',
//             //  'type': 'item',
//             //  'icon': 'message',
//             //  'url': '/messages',
//             //  'color': '#e93578',
//             //  

//             //},
//             {
//                 'id': 'coWorkers',
//                 'title': 'Co-workers',
//                 'translate': 'NAV.COWORKERS.TITLE',
//                 'type': 'item',
//                 'icon': 'people',
//                 'url': '/coWorkers',
//                 'color': '#003748',


//             },
//             {
//                 'id': 'companyInfo',
//                 'title': 'Company Info',
//                 'translate': 'NAV.COMPANYINFO.TITLE',
//                 'type': 'item',
//                 'icon': 'info',
//                 'url': '/companyInfo',
//                 'color': '#91e4fb',


//             },
//             {
//                 'id': 'planning',
//                 'title': 'planning',
//                 'translate': 'NAV.PLANNING.TITLE',
//                 'type': 'item',
//                 'icon': 'calendar_today',
//                 'url': '/planning',
//                 'color': '#00549d',

//             },
//             {
//                 'id': 'reminders',
//                 'title': 'Reminders',
//                 'translate': 'NAV.REMINDERS.TITLE',
//                 'type': 'item',
//                 'icon': 'add_alert',
//                 'url': '/reminders',
//                 'color': '#c7361c',
//             },
//             {
//                 'id': 'Suppliers',
//                 'title': 'Suppliers',
//                 'translate': 'NAV.SUPPLIERS.TITLE',
//                 'type': 'item',
//                 'icon': 'local_shipping',
//                 'url': '/suppliers',
//                 'color': '#a20c0c',
//             },
//             {
//                 'id': 'statistics',
//                 'title': 'Statistics',
//                 'translate': 'NAV.STATISTICS.TITLE',
//                 'type': 'item',
//                 'icon': 'signal_cellular_alt',
//                 'url': '/statistics',
//                 'color': '#b56e31',
//             },
//             {
//                 'id': 'settings',
//                 'title': 'Settings',
//                 'translate': 'NAV.SETTINGS.TITLE',
//                 'type': 'item',
//                 'icon': 'settings',
//                 'url': '/settings',
//                 'color': '#582015',
//             }
//         ]
//     }
// ];

// export const MalerNavigation: FuseNavigation[] = [
//     {
//         id: 'applications',
//         title: 'Applications',
//         translate: 'NAV.APPLICATIONS',
//         type: 'group',
//         children: [
//             {
//                 'id': 'dashboard',
//                 'title': 'Dashboard',
//                 'translate': 'NAV.DASHBOARD.TITLE',
//                 'type': 'item',
//                 'icon': 'dashboard',
//                 'url': '/dashboard',
//                 'color': '#631878',

//             },
//             {
//                 'id': 'customers',
//                 'title': 'Customers',
//                 'translate': 'NAV.CUSTOMERS.TITLE',
//                 'type': 'item',
//                 'icon': 'account_box',
//                 'url': '/customers',
//                 'color': '#72bb53',

//             },
//             {
//                 'id': 'matrials',
//                 'title': 'Materials',
//                 'translate': 'NAV.MATERIALS.TITLE',
//                 'type': 'item',
//                 'icon': 'invert_colors',
//                 'url': '/materials',
//                 'color': '#ff6624',

//             },
//             {
//                 'id': 'projects',
//                 'title': 'Projects',
//                 'translate': 'NAV.PROJECTS.TITLE',
//                 'type': 'item',
//                 'icon': 'receipt',
//                 'url': '/projects',
//                 'color': '#0c59cf',

//             },
//             //{
//             //  'id': 'offer',
//             //  'title': 'Offer',
//             //  'translate': 'NAV.OFFER.TITLE',
//             //  'type': 'item',
//             //  'icon': 'local_offer',
//             //  'url': '/projectsoffers',
//             //  'color': '#6B5935',
//             //  
//             //},
//             {
//                 'id': 'documents',
//                 'title': 'Documents',
//                 'translate': 'NAV.DOCUMENTS.TITLE',
//                 'type': 'item',
//                 'icon': 'insert_drive_file',
//                 'url': '/documents',
//                 'color': '#e61610',


//             },
//             {
//                 'id': 'workhours',
//                 'title': 'Workhours',
//                 'translate': 'NAV.WORKHOURS.TITLE',
//                 'type': 'item',
//                 'icon': 'access_time',
//                 'url': '/workhours',
//                 'color': '#aa671d',


//             },
//             //{
//             //  'id': 'messages',
//             //  'title': 'Messages',
//             //  'translate': 'NAV.MESSAGES.TITLE',
//             //  'type': 'item',
//             //  'icon': 'message',
//             //  'url': '/messages',
//             //  'color': '#e93578',
//             //  

//             //},
//             {
//                 'id': 'coWorkers',
//                 'title': 'Co-workers',
//                 'translate': 'NAV.COWORKERS.TITLE',
//                 'type': 'item',
//                 'icon': 'people',
//                 'url': '/coWorkers',
//                 'color': '#003748',


//             },
//             {
//                 'id': 'companyInfo',
//                 'title': 'Company Info',
//                 'translate': 'NAV.COMPANYINFO.TITLE',
//                 'type': 'item',
//                 'icon': 'info',
//                 'url': '/companyInfo',
//                 'color': '#91e4fb',


//             },
//             {
//                 'id': 'planning',
//                 'title': 'planning',
//                 'translate': 'NAV.PLANNING.TITLE',
//                 'type': 'item',
//                 'icon': 'calendar_today',
//                 'url': '/planning',
//                 'color': '#00549d',

//             },
//             {
//                 'id': 'reminders',
//                 'title': 'Reminders',
//                 'translate': 'NAV.REMINDERS.TITLE',
//                 'type': 'item',
//                 'icon': 'add_alert',
//                 'url': '/reminders',
//                 'color': '#c7361c',
//             },
//             {
//                 'id': 'statistics',
//                 'title': 'Statistics',
//                 'translate': 'NAV.STATISTICS.TITLE',
//                 'type': 'item',
//                 'icon': 'signal_cellular_alt',
//                 'url': '/statistics',
//                 'color': '#b56e31',
//             },
//             {
//                 'id': 'settings',
//                 'title': 'Settings',
//                 'translate': 'NAV.SETTINGS.TITLE',
//                 'type': 'item',
//                 'icon': 'settings',
//                 'url': '/settings',
//                 'color': '#582015',
//             }
//         ]
//     }
// ];

// export const GaestNavigation: FuseNavigation[] = [
//     {
//         id: 'applications',
//         title: 'Applications',
//         translate: 'NAV.APPLICATIONS',
//         type: 'group',
//         children: [
//             {
//                 'id': 'dashboard',
//                 'title': 'Dashboard',
//                 'translate': 'NAV.DASHBOARD.TITLE',
//                 'type': 'item',
//                 'icon': 'dashboard',
//                 'url': '/dashboard',
//                 'color': '#631878',

//             },
//             {
//                 'id': 'matrials',
//                 'title': 'Materials',
//                 'translate': 'NAV.MATERIALS.TITLE',
//                 'type': 'item',
//                 'icon': 'invert_colors',
//                 'url': '/materials',
//                 'color': '#ff6624',

//             },
//             {
//                 'id': 'projects',
//                 'title': 'Projects',
//                 'translate': 'NAV.PROJECTS.TITLE',
//                 'type': 'item',
//                 'icon': 'receipt',
//                 'url': '/projects',
//                 'color': '#0c59cf',

//             },
//             //{
//             //  'id': 'offer',
//             //  'title': 'Offer',
//             //  'translate': 'NAV.OFFER.TITLE',
//             //  'type': 'item',
//             //  'icon': 'local_offer',
//             //  'url': '/projectsoffers',
//             //  'color': '#6B5935',
//             //  
//             //},
//             {
//                 'id': 'documents',
//                 'title': 'Documents',
//                 'translate': 'NAV.DOCUMENTS.TITLE',
//                 'type': 'item',
//                 'icon': 'insert_drive_file',
//                 'url': '/documents',
//                 'color': '#e61610',

//             },
//             {
//                 'id': 'workhours',
//                 'title': 'Workhours',
//                 'translate': 'NAV.WORKHOURS.TITLE',
//                 'type': 'item',
//                 'icon': 'access_time',
//                 'url': '/workhours',
//                 'color': '#aa671d',


//             },
//             //{
//             //  'id': 'messages',
//             //  'title': 'Messages',
//             //  'translate': 'NAV.MESSAGES.TITLE',
//             //  'type': 'item',
//             //  'icon': 'message',
//             //  'url': '/messages',
//             //  'color': '#e93578',
//             //  

//             //},
//             {
//                 'id': 'coWorkers',
//                 'title': 'Co-workers',
//                 'translate': 'NAV.COWORKERS.TITLE',
//                 'type': 'item',
//                 'icon': 'people',
//                 'url': '/coWorkers',
//                 'color': '#003748',

//             },
//             {
//                 'id': 'companyInfo',
//                 'title': 'Company Info',
//                 'translate': 'NAV.COMPANYINFO.TITLE',
//                 'type': 'item',
//                 'icon': 'info',
//                 'url': '/companyInfo',
//                 'color': '#91e4fb',

//             },
//             {
//                 'id': 'planning',
//                 'title': 'planning',
//                 'translate': 'NAV.PLANNING.TITLE',
//                 'type': 'item',
//                 'icon': 'calendar_today',
//                 'url': '/planning',
//                 'color': '#00549d',

//             },
//             {
//                 'id': 'reminders',
//                 'title': 'Reminders',
//                 'translate': 'NAV.REMINDERS.TITLE',
//                 'type': 'item',
//                 'icon': 'add_alert',
//                 'url': '/reminders',
//                 'color': '#c7361c',
//             },
//             {
//                 'id': 'statistics',
//                 'title': 'Statistics',
//                 'translate': 'NAV.STATISTICS.TITLE',
//                 'type': 'item',
//                 'icon': 'signal_cellular_alt',
//                 'url': '/statistics',
//                 'color': '#b56e31',
//             },
//             {
//                 'id': 'settings',
//                 'title': 'Settings',
//                 'translate': 'NAV.SETTINGS.TITLE',
//                 'type': 'item',
//                 'icon': 'settings',
//                 'url': '/settings',
//                 'color': '#582015',
//             }
//         ]
//     }
// ];

// export const OtherNavigation: FuseNavigation[] = [
//     {
//         id: 'applications',
//         title: 'Applications',
//         translate: 'NAV.APPLICATIONS',
//         type: 'group',
//         children: [
//             {
//                 'id': 'dashboard',
//                 'title': 'Dashboard',
//                 'translate': 'NAV.DASHBOARD.TITLE',
//                 'type': 'item',
//                 'icon': 'dashboard',
//                 'url': '/dashboard',
//                 'color': '#631878',
//                 //
//             },
//             {
//                 'id': 'reminders',
//                 'title': 'Reminders',
//                 'translate': 'NAV.REMINDERS.TITLE',
//                 'type': 'item',
//                 'icon': 'add_alert',
//                 'url': '/reminders',
//                 'color': '#c7361c',
//             }
//         ]
//     }
// ];