export const locale = {
    lang: 'ds',
    data: {
        'FIELDS': {
            'CountryCode':'Landekode',
            'Not-Found-Country':'Intet land fundet'
        },
        'FILTERS': {
            'Country': 'Landenavn'
        },
    }
};
