export const locale = {
  lang: 'en',
  data: {
    'PROJECT': {
      'TITLE': 'Project',
      'DESC': 'Hello Project!'
    },
    'TITLE': {
      'CustomerPagination':'Antal kunder pr. side',
    },
    'BUTTONS': {
      'NEWCUSTOMER': 'New Customer',
      'CreateNewCustomer': 'Create new customer',
      'BACK': 'Back',
      'UploadCustomerList': 'Upload Customer List',
    },
    'FILTERS': {
      'TEXTSEARCH': 'Search By Name, Email, Phone, Address',
      'NUMBERSEARCH': 'Number Search',
      'CustomerNumber': 'Customer Number',
      'CustomerName': 'Customer Name',
      'PhoneNumber': 'Phone Number',
      'Email': 'Email',
      'Address': 'Address',
      'City': 'City',
      'ZipCode': 'ZipCode',
      'Position': 'Position',
      'Text': 'Text',
      'Action': 'Action',
      'SORTING': 'Sort By',
      'Active': 'Include all Inactive',
      'BADGE': 'Inactive',
      'Deleted': 'Show Deleted',
      
    },
    'MESSAGES': {
      'NORECORD': 'No Customer Found'
    },
    'SORTBY':
    [
      //{
      //  Text: '     ',
      //  Value: 'ID DESC'
      //},
      {
        Text: 'Name (A-Z)',
        Value: 'Text'
      },
      {
        Text: 'Name (Z-A)',
        Value: 'Text DESC'
      },
      {
        Text: 'Number (0-9)',
        Value: 'Number'
      },
      {
        Text: 'Number (9-0)',
        Value: 'Number DESC'
      }
    ]
  }
};
