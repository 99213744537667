export const locale = {
  lang: 'en',
  data: {
    'Headers': {
      'NewMaterial': 'Create New Material',
      'Material': 'Material',
      'DeleteAlert': 'Are you sure you want to delete this file?',
      'AllDeleteAlert': 'Are you sure you want to delete all files on this case?',
      'Yes': 'Yes',
      'No': 'No'
    }
  }
};
