import { Component, OnInit } from '@angular/core';
import { FuseTranslationLoaderService } from '../../../core/services/translation-loader.service';

import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';

import { EventEmitterEnum } from '../../../core/enum/event-emitter-ids.enum';

import { ChatService } from './message.service';
import { TitleService } from '../../../core/services/title.service';

@Component({
  selector: 'vibe-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})

export class VibeMessageComponent implements OnInit {

  selectedChat: any;

  constructor(private translationLoader: FuseTranslationLoaderService, private chatService: ChatService, private titleService: TitleService) {
    this.titleService.SetTitle("TITLE_MESSAGES");
    this.translationLoader.loadTranslations(english, danish);
  }

  ngOnInit() {
    this.chatService.onChatSelected
      .subscribe(chatData => {
        this.selectedChat = chatData;
      });
  }

}
