export const locale = {
    lang: 'en',
    data: {
      'BUTTONS': {
        'Upload': 'Upload',
        'Cancel': 'Cancel',
        "Update":"Opdatere",
        "Reset":"Reset/Cancel",
        "SampleFileDownload":"Sample file download",
        'Back': 'Back',
      },
      'Header':{
        'Number': 'Number',
        'SupplierName': 'Supplier Name',
        'ContactPerson': 'Contact Person',
        'Email': 'E-mail',
        'PhoneNo': 'Phone No.',
        'Homepage': 'Homepage',
        'AppUrlLink': 'App URL-link',
        'Address': 'Address',
        'Zipcode': 'Zipcode',
        'City': 'City',
        'Note': 'Note',
      },
      'FIELDS': {
        'SelectSupplier': 'Select Supplier',
        'UploadPriceList': 'Upload Price List',
        'ChooseFile': 'Choose File',
      },
      'LABELS': {
        'TITLE': 'Supplier list upload (.xls,.xlsx)',
        'NoFileSelected': 'No File Selected',
        'FileSelected': 'File Selected',
      },
      'MESSAGES': {
        'Created': 'Created Successfully.',
        'Updated': 'Updated Successfully.',
        'Failed': 'Failed',
        'Deleted': 'Deleted successfully',
        'Notes':'**Note - Inactive or Deleted materials are highlighted in red color'
      },
    }
  };
  