export const locale = {
  lang: 'en',
  data: {
    'Tabs': {
      'Offer': 'Offer',
      'BasicDetail': 'Basic Detail',
      'File': 'File',
      'Category': 'Category',
      'WorkItem':'Work Item'
    },
    'MESSAGE': {
      'Failed': 'Operation failed , try later',
      'Success': 'Success.',
      'CommingSoon':'Comming Soon..',
      'Deleted': 'Deleted successfully',
    },
    'FIELDS':{
      'GoTo':'See offer',
      'SRNO': 'Sr No',
      'Price': 'M2 total',
      'QTY': 'QTY',            
      'Total': 'Price in total',
      'Text': 'Text',
      "ShowMaterials" :"Offer draft",
      'TotalExclVAT': 'Total excl. VAT',
      'VAT': 'VAT',
      'TotalInclVAT': 'Total incl. VAT',
    },
    'BUTTONS': {
      'AddCategory': 'Add Category',
      'PDFOREMAIL': 'PDF / Email',
    },
    'Headers': {
      'DeleteCategory': 'Do you want to delete category',
    },
    'TOOTIPORTITLE':{
      'Edit':'Edit',
      'Delete':'Delete',
      'DeleteProject':'Delete Project',
      'GoToPDFMail':'Go To PDF/Email',
  },
  }
};
