import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MovePlanningMessageModelComponent } from './move-planning-message-model.component';
import { MaterialModule } from '../../../../@fuse/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';

import { TranslateModule } from '@ngx-translate/core';
@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,

    TranslateModule
  ],
    exports: [MovePlanningMessageModelComponent],
    declarations: [MovePlanningMessageModelComponent]
})
export class MovePlanningMessageModelModule { }
