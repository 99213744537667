import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import {MatSelectModule, MatFormFieldModule } from '@angular/material';

import { ProjectWorkInfoComponent } from './projectWorkInfo.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MaterialModule } from '@fuse/material.module';
import { FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FusePipesModule } from 'app/core/pipes/pipes.module';
import { FullscreenOverlayContainer, OverlayContainer } from '@angular/cdk/overlay';

@NgModule({
  declarations: [ ProjectWorkInfoComponent ],
  imports:      [ 
                  BrowserModule,
                  MaterialModule,
                  FlexLayoutModule,
                  FusePipesModule,
                  TranslateModule,
                  FormsModule,
                  ReactiveFormsModule,
                  MatSelectModule,
                  NgxMatSelectSearchModule,
  ],
  exports:      [ ProjectWorkInfoComponent ],
  providers:    [ 
                  {
                    provide: OverlayContainer, 
                    useClass: FullscreenOverlayContainer
                  }
                ]
})
export class ProjectWorkInfoModule { }
