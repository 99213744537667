import { Injectable } from '@angular/core';
import { UserProfile } from 'app/main/model/UserProfile';
import { Subject } from 'rxjs/Subject';
import { ApiConstant } from 'app/core/constant/ApiConstant';
import { Observable } from 'rxjs/Observable';
import { HttpParams, HttpClient } from '@angular/common/http';
import { BehaviorSubject, of } from 'rxjs';
import { AppUserAuth } from 'app/main/model/AuthModel';
import { RoleClaims } from 'app/constant/roleClaims';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  token = new Subject<string>();
  UserName = new Subject<string>();
  UserRole = new Subject<string>();
  actionUrl: string = "";
  //permissions : new Subject<string>();
  securityObject: AppUserAuth;
  roleClaims: any;

  private subject = new Subject<any>();
  private inquiryCount = new Subject<any>();

  private count = new Subject<any>();
  private reminderCount = new Subject<any>();

  private inquiryCountNumber: number = 0;
  private reminderCountNumber: number = 0;
  private notDoneReminderCountNumber: number = 0;
  private isOpenMatDrawer: boolean = false;
  private offerApprovedCountNumber: number = 0;

  public inquiryCountChange = new BehaviorSubject<any>(0);
  inquiryCountChangeObs = this.inquiryCountChange.asObservable();

  public reminderCountChange = new BehaviorSubject<any>(0);
  reminderCountChangeObs = this.reminderCountChange.asObservable();

  public notDoneReminderCountChange = new BehaviorSubject<any>(0);
  notDoneReminderCountChangeObs = this.notDoneReminderCountChange.asObservable();

  public isOpenMatDrawerChange = new BehaviorSubject<any>(false);
  OpenMatDrawerFlagChangeObs = this.isOpenMatDrawerChange.asObservable();

  public offerApprovedCountChange = new BehaviorSubject<any>(0);
  offerApprovedCountChangeObs = this.offerApprovedCountChange.asObservable();

  public reminderfilterChange = new BehaviorSubject<any>(null);
  reminderFilterSelectChangeObs = this.reminderfilterChange.asObservable();

  public UpdateReminderListChange = new BehaviorSubject<boolean>(true);
  UpdateReminderListChangeObs = this.UpdateReminderListChange.asObservable();

  public editSingleQuestionChange = new BehaviorSubject<any>(0);
  editSingleQuestionChangeObs = this.editSingleQuestionChange.asObservable();

  public deleteSingleQuestionChange = new BehaviorSubject<any>("");
  deleteSingleQuestionChangeObs = this.deleteSingleQuestionChange.asObservable();

  public GetEmailHistoryListChange = new BehaviorSubject<boolean>(true);
  GetEmailHistoryListChangeObs = this.GetEmailHistoryListChange.asObservable();

  constructor(private http: HttpClient) {
    this.actionUrl = ApiConstant.API_URL;
    if (localStorage.getItem("userClaims")) {
      this.securityObject= new AppUserAuth();
      this.securityObject.claims = JSON.parse(localStorage.getItem("userClaims"));
    } else {
      this.securityObject = new AppUserAuth();
    }
    this.roleClaims = RoleClaims;
  }
  public getToken(): string {
    return localStorage.getItem('token');
  }

  public setToken(value): void {
    this.token.next(value.token);
    localStorage.setItem('token', value.token);
    var result = this.parseJwt();
    if (result != null) {
      this.UserName.next(result.UserName);
      this.UserRole.next(result.role);
    }
    else {
      this.UserName.next("Other");
      this.UserRole.next("Other");
    }
    localStorage.setItem('userClaims', JSON.stringify(value.claims));
    this.securityObject = result;
    this.securityObject.claims = value.claims ? value.claims : null;
  }

  public LogOut<T>(): Observable<T> {
    return this.http.post<T>(this.actionUrl + 'Account/logout', null);
  }

  public getRoles<T>(): Observable<T> {
    return this.http.get<T>(this.actionUrl + 'Account/getRoles');
  }

  public RemoveToken(): void {
    localStorage.removeItem('token');
  }

  public RemoveLocalSearchValue(): void {
    localStorage.removeItem(ApiConstant.LocalRequestModel);
  }
  public RemoveLocalSettingValue(): void {
    localStorage.removeItem("settingMasterValues");
  }

  public RemoveUserClaims(): void {
    localStorage.removeItem("userClaims");
  }

  public RemoveLocalResponseValue(): void {
    localStorage.removeItem(ApiConstant.LocalResponseModel);
  }

  public RemoveLocalStorage(): void {
    this.RemoveToken();
    this.RemoveLocalSearchValue();
    this.RemoveLocalSettingValue();
    this.RemoveUserClaims();
    this.RemoveLocalResponseValue();
  }

  public getUserName(): string {
    var result = this.parseJwt();
    if (result != null) {
      //this.UserName.next(result.UserName);
      return result.sub;
    }
    else {
      //this.UserName.next("");
      return "Other";
    }
  }

  public getUserEmail(): string {
    var result = this.parseJwt();
    if (result != null) {
      //this.UserName.next(result.UserName);
      return result.email;
    }
    else {
      //this.UserName.next("");
      return "admin@vibe.dk";
    }
  }

  public getUserRole(): string {
    var result = this.parseJwt();
    if (result != null) {
      //this.UserRole.next(result.role);
      return result.role;
    }
    else {
      //this.UserRole.next("");
      return "Other";
    }
  }

  public getUserId(): string {
    var result = this.parseJwt();
    return result.Id;
  }

  public getCoWorkerId(): string {
    var result = this.parseJwt();
    if (result) {
      return result.coworkerId;
    }
    return null;
  }

  public parseJwt(): UserProfile {
    var token = this.getToken();
    if (token != undefined) {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace('-', '+').replace('_', '/');
      //var data = JSON.parse(window.atob(base64));
      var data = JSON.parse(this.b64DecodeUnicode(base64));
      var user = new UserProfile();
      user.token = token;
      user.role = data.Roles;
      user.UserName = data.email;
      user.Id = data.sid;
      user.coworkerId = data.coworkerId;
      user.email = data.email;
      user.sub = data.sub;
      return user;
    }
    else
      return null;
  }

  public b64DecodeUnicode(str) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(atob(str).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  }

  setInquiryCount(number: number) {
    this.inquiryCountNumber = number;
    this.inquiryCountChange.next(number);
  }


  setReminderCount(number: number) {
    this.reminderCountNumber = number;
    this.reminderCountChange.next(number);
  }

  setNotDoneReminderCount(number: number) {
    this.notDoneReminderCountNumber = number;
    this.notDoneReminderCountChange.next(number);
  }

  openProjectListModel(flag: boolean) {
    this.isOpenMatDrawer = flag;
    this.isOpenMatDrawerChange.next(flag);
  }

  setApprovedOfferCount(number: number) {
    this.offerApprovedCountNumber = number;
    this.offerApprovedCountChange.next(number);
  }

  reminderfilterSearch(values: any) {
    this.reminderfilterChange.next(values);
  }

  UpdateReceiveByMeReminderList(value: boolean) {
    this.UpdateReminderListChange.next(value);
  }

  UpdateSendByMeReminderList(value: boolean) {
    this.UpdateReminderListChange.next(value);
  }

  editSingleQuestion(value: any) {
    this.editSingleQuestionChange.next(value);
  }

  deleteSingleQuestion(value: any) {
    this.deleteSingleQuestionChange.next(value);
  }

  getEmailHistoryList(value: boolean) {
    this.GetEmailHistoryListChange.next(value);
  }

  public getRoleClaims<T>(roleId: any): Observable<T> {
    var url = this.actionUrl + "Account/getRoleClaims/"+roleId;
    return this.http.get<T>(url);
  }
  public addOrUpdateRoleClaims<T>(id:any, roleClaimsList: any): Observable<T> {
    return this.http.put<T>(this.actionUrl + "Account/addOrUpdateRoleClaims/"+ id, roleClaimsList);
  }

  hasClaim(type: any, value?: any): Observable<boolean> {
    let ret = false;
    if (type == null)
      ret = true;
    if (typeof type === "string") {
      ret = this.isClaimValid(type, value);
    } else {
      const claims: string[] = type;

      if (claims) {
        for (let index = 0; index < claims.length; index++) {
          ret = this.isClaimValid(claims[index]);
          if (ret) {
            break;
          }
        }
      }
    }

    return of<boolean>(ret);
  }

  private isClaimValid(claimType: string, claimValue?: string): boolean {
    let ret = false;
    let auth: AppUserAuth = null;
    auth = this.securityObject;

    if (auth) {
      if (claimType.indexOf(":") >= 0) {
        const words: string[] = claimType.split(":");
        claimType = words[0].toLocaleLowerCase();
        claimValue = words[1];
      } else {
        claimType = claimType.toLocaleLowerCase();
        claimValue = claimValue ? claimValue : "true";
      }
      const s = auth.claims.find(
        c =>
          c.type.toLocaleLowerCase() === claimType &&
          c.value === claimValue
      );
      ret =
        auth.claims.find(
          c =>
            c.type.toLocaleLowerCase() === claimType &&
            c.value === claimValue
        ) != null;
    }
    return ret;
  }

  getRoleClaimsList(){
    return this.roleClaims;
  }

  public getSMTPEmail() {
    const settingMasterValues = Object.assign([], JSON.parse(localStorage.getItem("settingMasterValues")));
    const tempSettingObject = settingMasterValues.find(s => s.key1 === "Credential" && s.key2 === "Email");
    if (tempSettingObject && tempSettingObject.value) {
      const parsedValue = JSON.parse(tempSettingObject.value);
      const SMTPEmail = parsedValue.SMTPEmail;
      return SMTPEmail;
    } else {
      return "post@vibesmalerfirma.dk";
    }
  }

}
