export const locale = {
    lang: 'ds',
    data: {
        'TITLE': {
            'HeaderForNewCoWorkerNotification': 'Notifikation om Ny medarbejder',
            'HeaderForNewCoWorkerNotification2': 'Medarbejder Påmindelser',
            
            'CoWorkerJoiningSMSTextCoworker':'Fjern venligst ikke @CoworkerName,@JoiningCoworkerNames og @JoinDate fra SMS tekst.',
            
            'CoWorkerJoiningSMSTextOtherCoworker':'Fjern venligst ikke @CoworkerName,@JoiningCoworkerNames og @JoinDate fra SMS tekst.',
            'CoWorkerJoiningNotificationTextOtherCoworker':'Fjern venligst ikke @CoworkerName,@JoiningCoworkerNames og @JoinDate fra Notifikation tekst.',
        },
        'FIELDS': {
            'IsCreateReminder': 'Opret påmindelse?',
            'DaysToSend': 'Send x dage før',
            'SMSText': 'SMS tekst',
            'NotificationText': 'Notifikations tekst',
            'EmailText': 'E-mail tekst',
            'ExecuteTime': 'Udfør tid',

            // 'EmailTemplateForCoworker': 'E-mail Skabelon For Medarbejder',
            // 'EmailTemplateForOthers': 'E-mail Skabelon For anden medarbejder',

            'NoteLabel':'Fjern venligst ikke @signerede ord fra sms tekst,e-mail skabelon tekst og notifikation tekst.',

            'TemplatesForCoworker':'Besked For Medarbejder',
            'TemplatesForOthers':'Besked til andre på samme dag',
            'TemplatesForOthersBeforeXDays':'Besked til andre før X dage',

            'EmailTemplateForCoworker':'E-mail Skabelon For Medarbejder',
            'EmailTemplateForOthers':'E-mail skabelon til andre på samme dag',
            'EmailTemplateForOthersBeforeXDays':'E-mail-skabelon til andre før X dage',
            
            // 'NoteLabelForCoworker':'Vær venlig gør ikke fjerne @CoworkerName,@JoiningCoworkerNames og @JoinDate fra SMS,Notifikation og Email skabelon tekst.',
            // 'NoteLabelForOthers':'Vær venlig gør ikke fjerne @CoworkerName,@JoiningCoworkerNames og @JoinDate fra SMS,Notifikation og Email skabelon tekst.',
            //'NoteLabelForOthersBeforeXDays':'Vær venlig gør ikke fjerne @BirthDate og @CoworkerNames fra SMS,Notifikation og Email skabelon tekst.'
        },
        'VALIDATIONS': {
            'DaysToSend': 'Dage at sende er påkrævet',
            'SMSText': 'SMS-tekst er påkrævet',
            'NotificationText': 'Notifikations tekst er påkrævet',
            'EmailText': 'E-mail-tekst er påkrævet',
            'ExecuteTime': 'Udførelse tid er påkrævet'
        },
        'MESSAGES': {
            'Saved': 'Gemt succesfuldt.',
        },
        'BUTTONS': {
            'Save': 'Gem',
        }
    }
};
