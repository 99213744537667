export const locale = {
  lang: 'ds',
    data: {
        'HEADER': {
            'SelectedRoutesDates': 'Valgte ruter Datoer',
        },
    'BUTTONS': {
          'Direction': 'Retning',
        'Search': 'Søg',
    },
    'FIELDS': {
        'FullAddress': 'Fuld adresse',
        'Vehicle': 'Køretøj',
        'StartDate': 'Start dato',
        'EndDate': 'Indtil dato',
        'RouteDate': 'Rute dato',
        'Route': 'Rute',
        'MetersTrip': 'Meters tur',
        'Group': 'Gruppe',
        'Devices': 'Enheder',
        'Labels': 'Kategori',
    },
  }
};
