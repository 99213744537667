export const locale = {
  lang: 'ds',
  data: {
    'CUSTOMER': {
      'TITLE': 'kunder',
      'DESC': 'Hej kunder!'
    },
    'BUTTONS': {
      'NEWCUSTOMER': 'Ny kunde',
      'NEWINQUIRY': 'Ny forespørgsel',
      'BACK': 'Tilbage',
      'OfferPricePDF': 'Tilbudspris PDF',
    },
    'TITLE': {
      'MaterialPagination': 'Antal materialer pr. side',
    },
    'MESSAGES': {
      'NORECORD': 'Intet materiale fundet',
      'SENDEMAILSUCCESS': 'Email sendt med succes',
    },
    'FILTERS': {
      'Filter': 'Søg efter, navn, nummer',
      'TEXTSEARCH': 'Søg efter navn',
      'NUMBERSEARCH': 'Kunde nr. Søg',
      'CustomerNumber': 'Kunde nummer',
      'CustomerName': 'Kundenavn',
      'Email': 'E-mail',
      'PhoneNumber': 'Telefon Nummer',
      'Address': 'Adresse',
      'City': 'By',
      'ZipCode': 'Postnummer',
      'Position': 'Position',
      'Text': 'Tekst',
      'Action': 'Handling',
      'SORTING': 'Sorter efter',
      'Active': 'Inkluder alle inaktive',
      'BADGE': 'Inaktive',
      'Deleted': 'Vis slettet',
      'OfferType': 'Tilbud type',
      'All': 'Alle',
      'ShowOffer': 'Vis tilbud'
    },
    'materialReportStatus': {
      'value1': 'All             ',
      'value2': 'Lager ned ↓',
      'value3': 'Lager op ↑',
      // 'value4': 'Hylde nr (A-Z)',
      // 'value5': 'Hylde nr (Z-A)',
    },
    'GridFeildsValue': {
      'MaterialNo': 'Vare Nr',
      'Name': 'Navn',
      'RetailPrice': 'Udsalgs Pris',
      'PurchasePrice': 'Indkøbs Pris',
      'PersonalPrice': 'Personal Pris',
      'Holdings': 'Beholding',
      'MinHoldings': 'Min Beholdning',
      'MaxHoldings': 'Max Beholdning',
      'Unit': 'Enhed',
      'ShelfNo': 'Hylde nr.',
      'Total': 'I Alt',
    },
    'SORTBY':
      [
        {
          Text: 'Navn (A-Z)',
          Value: 'Text'
        },
        {
          Text: 'Navn (Z-A)',
          Value: 'Text DESC'
        },
        {
          Text: 'Nummer (0-9)',
          Value: 'Number'
        },
        {
          Text: 'Nummer (9-0)',
          Value: 'Number DESC'
        },
        {
          Text: 'Hylde nr (A-Z)',
          Value: 'ShelfNo'
        },
        {
          Text: 'Hylde nr (Z-A)',
          Value: 'ShelfNo DESC'
        }
      ],
    'TOOTIPORTITLE': {
      'Material': 'Materiale'
    }
  }
};
