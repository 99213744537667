export const locale = {
  lang: 'en',
  data: {
    'PROJECT': {
      'TITLE': 'Project',
      'DESC': 'Hello Project!'
    },
    'BUTTONS': {
      'NEWPROJECT': 'New Project',
      "DefaultCoworker": 'Default Coworker',
      'StatusMessage': 'Status Message',
      'Tool': 'Tool',
      'PriceDiscount': 'Price discount',
      'ShowInactive': 'Show Inactive',
        'VehicleInfo': 'Nanolink',
    },
    'TITLE': {
      'ProjectPagination': 'Antal projekter pr. side',
    },
    'FILTERS': {
      'SEARCH': 'Search',
      'SORTING': 'Sort By',
      'SORTING_STATUS': 'Status',
      'Active': 'Include All Inactive',
      'BADGE': 'Inactive',
      'Deleted': 'Show ONLY deleted cases',
      'All': 'All',
      'Lift/Stigemand': 'Lift/Stigemand',
      'Indoor/Outdoor': 'Indoor/Outdoor',
      'Lift/Stigmand': 'Lift/Stigmand',
      'Ind/Ud': 'Ind/Ud',
      'MtoM': 'M to M',
      'Note': 'Note',
      'Offers': 'Offers',
      'Show60DayOld': 'Show 60 Days old',
      'ShowHourDetails': 'Show hourly details',
      'TotalProject': 'Total Projects',
      'TotalProjectRemainingHours': 'Project Remaining Hours',
      'TotalProjectDaysLeft': 'Project Remaining Days',
      'LastDayWorkingHour': 'Last Day Working Hours',
      'Text': 'Text*',
      'OpgaveText': 'Opgave Tekst',
      'InvoiceText': 'Invoice Text',
      'PurchaseStatus': 'Purchase Status',
      'AverageAmount': 'Average amount',
      'TotalProjectRemainingPris': 'Project remaining price'
    },
    'MESSAGES': {
      'NORECORD': 'No Record Found'
    },
    'FIELDS': {
            'StartDate': 'Start Date',
            'EndDate': 'End Date',
            'NoOfEmployee': 'Number of employees',
            'MaterialPurchasePriseTotal': 'Sum Stock',
            'TotalProjectDaysLeft': 'Project Remaining Days',
            'TotalProject': 'Total Projects',
            'TotalProjectRemainingHours': 'Project Remaining Hours',
            'TotalPlanningRemainingHours': 'Planning Remaining Hours',
            'TotalPlanningDaysLeft': 'Planning Remaining Days',
            'TotalPlanningHours': 'Total Planning Hours',
            'CoWorker': 'Co-Workers',
            'WorkhoursType': 'Workhours type',
            'nonStatus': 'Status',
            'ShowHourDetails': 'Hourly details',
            'ShowTime': 'Show Time',
            'TotalProjectPrice': 'Total Project Price'
    },
    'SORTBY':
      [
        {
          Text: 'Number (0-9)',
          Value: 'Number'
        },
        {
          Text: 'Number (9-0)',
          Value: 'Number DESC'
        },
        {
          Text: 'Start Date ↑',
          Value: 'StartDate'
        },
        {
          Text: 'Start Date ↓',
          Value: 'StartDate DESC'
        },
        {
          Text: 'End Date ↑',
          Value: 'EndDate'
        },
        {
          Text: 'End Date ↓',
          Value: 'EndDate DESC'
        },
        {
          Text: 'Status ↑',
          Value: 'Status'
        },
        {
          Text: 'Status ↓',
          Value: 'Status DESC'
        }
      ],
    'selectedValueIndoorOutdoorStatus': {
      'value1': 'Indoor ',//Man in a room
      'value2': 'Outdoor', //Sun
      'value3': 'Spray Painting',//spraypaint
      'value4': 'Complaint', //Reklamation
      'value5': 'Staff purchase', //drawing of painter
      'value6': 'Store purchase' //Nordsjø logo
    },
    'selectedOption': {
      'value1': 'No',
      'value2': 'Yes',
    },
    'SORTBY_STATUS': [
      {
        Text: 'Show all',
        Value: 0
      },
      {
        Text: 'Process',
        Value: 1
      },
      {
        Text: 'Scheduled visit',
        Value: 2
      },
      {
        Text: 'Do not answer',
        Value: 3
      },
      {
        Text: 'Offers sent',
        Value: 4
      },
      {
        Text: 'Offer approved',
        Value: 5
      },
      {
        Text: 'Planned',
        Value: 6
      },
      {
        Text: 'Offer rejected',
        Value: 7
      },
      {
        Text: 'Started',
        Value: 8
      },
      {
        Text: 'Performed',
        Value: 9
      },
      {
        Text: 'Billed',
        Value: 10
      },
      {
        Text: 'Complaint / internally',
        Value: 11
      }
    ]
  }
};
