import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiConstant } from './../../../core/constant/ApiConstant';
import { CreateFolder, ListFolder, CheckFolderExist, DeleteFolder, SharedLinkDTO, SearchFolderOrFiles } from './../../model/DropBox';
import { Settings } from '../../model/Settings';
import { environment } from 'environments/environment';
@Injectable({
    providedIn: 'root'
})
export class DropboxFilesService {

    actionUrl: string;
    private apiUrl: string;


    constructor(private http: HttpClient) {
        this.actionUrl = 'https://api.dropboxapi.com/';
        this.apiUrl = ApiConstant.API_URL + "Documents/";
    }

    //New DropdownFiles API calls
    // Post 
    public CreateFolder<T>(createFolder: CreateFolder): Observable<T> {
        return this.http.post<T>(this.apiUrl + "CreateFolder", createFolder);
    }

    public UploadFiles(formData: FormData) {
        return this.http.post(this.apiUrl + "UploadFiles", formData, { headers: { IsContentType: "true" } });
    }

    // Get
    public GetListFolderDrpBox<T>(listFolder: ListFolder): Observable<T> {
        const obj = this.GetParams(listFolder);
        return this.http.get<T>(this.apiUrl + "ReadFolder", {
            params: obj
        });
    }

    public CheckFolderExist<T>(checkFolderExist: CheckFolderExist): Observable<T> {
        const obj = this.GetParams(checkFolderExist);
        return this.http.get<T>(this.apiUrl + "CheckFolderExist", {
            params: obj
        });
    }

    public GetSharedLink<T>(sharedLinkDTO: SharedLinkDTO): Observable<T> {
        const obj = this.GetParams(sharedLinkDTO);
        return this.http.get<T>(this.apiUrl + "GetSharedLink", {
            params: obj
        });
    }

    public GetSearchFileOrFolder<T>(searchFolderOrFiles: SearchFolderOrFiles) {
        const obj = this.GetParams(searchFolderOrFiles);
        return this.http.get<T>(this.apiUrl + "SearchFileOrFolder", {
            params: obj
        });
    }

    public GetShowOnlyAdminFolderName<T>(key): Observable<T> {
        return this.http.get<T>(ApiConstant.API_URL + ApiConstant.ACCOUNT + '/SettingValue/' + key);
    }

    // Update
    public UpdateSettingValuue<T>(id: string, settings: Settings): Observable<T> {
        return this.http.put<T>(ApiConstant.API_URL + ApiConstant.ACCOUNT + '/SettingValue/' + id, settings);
    }

    // Delete
    public DeleteFolderOrFile<T>(deleteFolder: DeleteFolder): Observable<T> {
        return this.http.post<T>(this.apiUrl + "DeleteFolderOrFile", deleteFolder);
    }
    //End
    public GetParams(param) {
        let params: HttpParams = new HttpParams();
        for (var key in param) {
            if (param.hasOwnProperty(key)) {
                let val = param[key];
                params = params.append(key, val);
            }
        }
        return params;
    }

    public getSettingMasterValueDocument() {
        var settingMasterValues = Object.assign([], JSON.parse(localStorage.getItem("settingMasterValues")));
        var tempSettingObject = settingMasterValues.find(s => s.key1 == "Documents" && s.key2 == "DropboxPath");
        var path = tempSettingObject  && tempSettingObject.value ? tempSettingObject.value : environment.DropBoxFolderName;
        return path;
    }

    public getSettingMasterValueDocumentPath() {
        var settingMasterValues = Object.assign([], JSON.parse(localStorage.getItem("settingMasterValues")));
        var tempSettingObject = settingMasterValues.find(s => s.key1 == "Documents" && s.key2 == "DriveOptions");
        var path = tempSettingObject && tempSettingObject.value ? JSON.parse(tempSettingObject.value) : null;
        return path;
    }
}
