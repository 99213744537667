export const locale = {
  lang: 'ds',
  data: {
    'FIELDS': {
      'Quantity': 'Antal',
      'ProductNo': 'Vare Nr',
      'Total': 'I alt',
      'Inventory': 'Beholding',
      'ProjectNumber': 'Sag Nummer',
      'RequisitionNo': 'Opgave nr.', 
      'CaseNo': 'Sags nummer',
      'ItemNo': 'Vare nummer',
      'Number': 'Beholdning',
      'Text': 'Tekst',
      'Unit': 'Enhed',
      'SRNO': 'Sr nr.',
      'Status': 'Status',
      'Name': 'Navn',
      'CreatedBy': 'OprettetAf',
      'CreatedDate': 'OprettetDato',
      'ModifyBy': 'ModificereAf',
      'ModifyDate': 'ModificereDato',
      'CoworkerName': 'Medarbejder',
      'Date': 'Dato',
      'Description': 'Tekst',
      'MaterialDetail': 'Materiale detalje',
      'RequisitionDetail': 'Rekvisitions Detaljer',
      'ColorCode': 'Farvekode',
      'Price': 'Pris',
      'QTY': 'Antal',
      'StartDate': 'Start Dato',
      'EndDate': 'Slut Dato',
      'Coworker': 'Medarbejder',
      'TotalPrice': 'Total pris',
      'TotalQTY': 'Samlet antal',
    },
    'LABELS': {
      'TITLE': 'Solgte vare',
      'NoFileSelected': 'Ingen fil valgt',
      'FileSelected': 'Fil valgt',
      'ConfMsg': 'Du ændrer Pris pr ',
      'Supplier': 'Leverandør',
      "MaterialChangesList":"Materialeændringsliste",
      "MaterialDifferentSupplierList":"Materiale forskellig leverandørliste",
      "NewMaterialList":"Ny materialeliste"
    },
    'MESSAGES': {
      'Created': 'Oprettet succesfuldt.',
      'Updated': 'Opdateret succesfuldt.',
      'Failed': 'mislykkedes',
      'Deleted': 'Slettet med succes',
      'NORECORD': 'Ingen registrering fundet.',
    },
  }
}; 
