export const locale = {
    lang: 'ds',
    data: {
        'Headers': {
            'PlanWithHoliday': 'Planlæg på helligdage',
            'PlanWithoutHoliday': 'Planlæg ikke på helligdage',
            'Disconnect': 'Afbryd',
            'ExtendOnFuture': 'Udvid med antal dage',
            'ExtendWithoutFuture':'Planlæg på denne dage',
            'MaterialStockUpdown':'Opskriv lager igen',
            'NoChange':'Opskriver ikke lager igen'
        }
    }
};
