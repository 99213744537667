export const locale = {
    lang: 'ds',
    data: {
        'MESSAGES': {
            'Created': 'Oprettet med succes',
            'Updated': 'Opdateret med succes',
            'Deleted': 'Slettet med succes',
            'Failed': 'Operation mislykket, prøv senere',
            'NORECORD': 'Ingen registrering fundet',
            'StatusExits': 'denne status findes allerede',
            'Notes':'*** Bemærk @CoworkerName er MedarbejderNavn. @ProjectAmount er Projektbeløb. @ProjectAddress er Projektadresse. @ProjectAmountPersonale er Projektbeløb Personligt. @ProjectNumber er Projektnummer, @FromStatus er fra, @ToStatus er til'
        },
        'BUTTONS': {
            'Save': 'Gem',
            'Reset': 'Nulstil',
            'Cancel': 'Gå tilbage',
            'Add': "Tilføj",
            'Back': 'Tilbage',
            'Notification': 'Notifikation'
        },
        'FIELDS': {
            'Status': 'Status',
            'Not-Found-Status': 'Status ikke fundet',
            'Message': 'Besked',
            'EnterMessage': 'indtast besked',
            'AudienceType': 'Medarbejder / Debitor',
            'IndoorOutDoor': 'Ind, ud, Sprøjt, Reklamation *',
            'ReminderMsg': 'Påmindelse Besked',
            'SMSText': 'SMS tekst',
            'NotificationText': 'Notifikations tekst',
            "Subject":"Indtast emne",
            'EmailText': 'E-mail tekst',
            'DragAndDropFile': 'Træk og slip filen her',
            'Or': 'Eller',
            'BrowseForFile': 'Søg efter fil',


        },
        'Lable': {
            'SRNO': 'Sr nr.',
            'Message': 'Besked',
            'Status': 'Status',
            'AudienceType': 'Medarbejder / Debitor'
        },
        'Headers': {
            'StatusList': 'Status liste',
        },
        'Validators': {
            'SelectStatus': 'Vælg Status',
            'SelectAudienceType': 'Vælg Medarbejder / Debitor',
            'SelectIndoorOrOutdoor': 'Vælg en hvilken som helst',
        },
        'ToolTip': {
            'SendReminderBySMS': 'Send påmindelse med Sms',
            'SendReminderByEmail': 'Send påmindelse med e-mail',
            'SendReminderByNotification':'Send påmindelse med notifikationer',
        }
    }
};

