export const locale = {
    lang: 'ds',
    data: {
        'DOCUMENT': {
            'TITLE': 'Dokument',
            'DESC': 'Hej Dokument!'
        },
        'FEILDS': {
            'TEXTSEARCH': 'Tekst Søg',
        },
        'Headers': {
            'Ok': 'Gem',
            'Cancel': 'Gå tilbage',
            'ChooseFile': 'Vælg fil',
            'NoFileSelected': 'Ingen fil valgt',
            'FOLDEREMPTY': 'Denne folder er tom'
        },
        'BUTTONS': {
            'CREATE_FOLDER': 'Opret mappe',
            'DOWNLOAD': 'Hent',
            'DELETE': 'Slet',
            'SEARCH': 'Søg',
            'HIDEFOLDERFOREMPLOYEE': 'Skjul mappe til medarbejder',
            'MOVEFOLDERTOFINISH': 'Gå til mappen færdig',
        },
        'MESSAGES': {
            'DropBoxApiNotResponding': 'Dropbox reagerer ikke, prøv efter lidt tid',
            'FileConflict': 'Filen eksisterer allerede',
            'FolderConflict': 'Mappenavn eksisterer allerede',
            'Updated': 'Opdateret',
            'SELECTANYFILE': 'Vælg ethvert dokument',
        },
    }
};

