import { Component, OnInit, ElementRef, NgZone, ViewChild, Input, Output, EventEmitter, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatTableDataSource, MatOption, MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import * as moment from 'moment';
import { ProjectIndoorOutDoorStatus } from 'app/main/model/ProjectStatus';
import { ProjectStatus } from 'app/constant/common.constant';
import { RequestStatusProject } from 'app/main/model/RequestBase';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'app/core/services/auth.service';
import { ProjectService } from 'app/main/pages/project/project.service';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { projectRequisition } from 'app/main/model/Project';
import { MoveRequisitionComponent } from 'app/main/pages/project/component/moveRequisition/moveRequisition.Component';
import { NotificationsService } from 'angular2-notifications';


@Component({
    selector: 'vibe-project-list-shared',
    templateUrl: './project-list.component.html',
    styleUrls: ['./project-list.component.scss']
})
export class ProjectListSharedComponent implements OnInit {
    @Output() selectProjectEvent = new EventEmitter<string>();
    isOpenOnPopup: boolean = false;
    selectIndoorOutDoorStatus = ProjectIndoorOutDoorStatus;
    projectStatus = ProjectStatus;
    currentselectedIndoorOutdoorObj: number[];
    currentselectedStatusObj: number[];
    requestProject = new RequestStatusProject();
    Projects: any[];
    After5DayDate: string = "";
    TodayDate: string = "";
    isActive: boolean = true;
    showSpinner: boolean = false;
    roleName: string;
    isComingFromProject: boolean = false;
    pagination: any;
    displayPagination: boolean = false;
    requisitionNumber: string;
    requisitionAddress: string;
    Requisition: projectRequisition;
    @ViewChild('allStatusSelected', { static: false }) private allStatusSelected: MatOption;
    @ViewChild('allIndoorOutdoorStatusSelected', { static: false }) private allIndoorOutdoorStatusSelected: MatOption;
    ownProjectView: boolean = false;
    roleClaims: any;
    //Initiators
    constructor(private translationLoader: FuseTranslationLoaderService,
        private _router: Router,
        private translate: TranslateService,
        private authService: AuthService,
        private projectservice: ProjectService,
        private notificationsService: NotificationsService,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<MoveRequisitionComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
        this.translationLoader.loadTranslations(english, danish);
        this.roleClaims = this.authService.getRoleClaimsList();
    }

    ngOnInit() {
        this.authService.hasClaim(this.roleClaims.Own_Project_View).subscribe(hasClaim => {
            if (hasClaim) 
                this.ownProjectView = hasClaim
             else 
                this.ownProjectView = false;
        });
        this.isActive = true;
        this.roleName = this.authService.getUserRole();
        this.requestProject = this.projectservice.getRequestParameterForCalender();
        this.requestProject.isLift = false;
        this.requestProject.isRiseman = false;
        this.requestProject.CoworkerId = "";
        this.requestProject.IsActive = true;
        this.requestProject.IsDeleted = false;
        // if (this.roleName == "Admin") {
        // }
        // else if (this.roleName == "Kontor") {
        // }
        // else if (this.roleName == "Maler") {
        //     this.requestProject.CoworkerId = this.authService.getCoWorkerId();
        // }
        // else if (this.roleName == "Gaest") {
        // }
        // else {
        // }
        if(this.ownProjectView)
            this.requestProject.CoworkerId = this.authService.getCoWorkerId();
        this.currentselectedStatusObj = [];
        if (this.requestProject.multipleStatus) {
            var statusObj = this.requestProject.multipleStatus.split(",");
            for (var i = 0; i < statusObj.length; i++) {
                this.currentselectedStatusObj.push(Number(statusObj[i]));
            }
        }
        this.currentselectedIndoorOutdoorObj = [];
        if (this.requestProject.isIndoorOutdoorTypeProjects) {
            var obj = this.requestProject.isIndoorOutdoorTypeProjects.split(",");
            for (var i = 0; i < obj.length; i++) {
                this.currentselectedIndoorOutdoorObj.push(Number(obj[i]));
            }
        }
        this.GetProjects(this.requestProject, true);
        this.TodayDate = moment(new Date()).format('YYYY-MM-DD');
        this.After5DayDate = moment(this.TodayDate, "YYYY-MM-DD").add(5, 'days').format('YYYY-MM-DD');
        if (this.data && this.data.isOpenPopup) {
            this.isOpenOnPopup = this.data.isOpenPopup;
        }
    }

    onChangeSearchFilter() {
        setTimeout(() => {
            var selectedPersonalAndBuitik = false;
            if (this.currentselectedIndoorOutdoorObj == undefined) {
                this.currentselectedIndoorOutdoorObj = [];
            }

            for (var i = 0; i < this.currentselectedIndoorOutdoorObj.length; i++) {
                if (this.currentselectedIndoorOutdoorObj[i] == 5 || this.currentselectedIndoorOutdoorObj[i] == 6) {
                    selectedPersonalAndBuitik = true;
                    break;
                }
            }
            if (selectedPersonalAndBuitik) {
                this.currentselectedStatusObj = [];
                var statusObj = ("8,9").split(",");
                for (var i = 0; i < statusObj.length; i++) {
                    this.currentselectedStatusObj.push(Number(statusObj[i]));
                }
                this.requestProject.multipleStatus = "8,9";
            }
            this.requestProject.isIndoorOutdoorTypeProjects = this.currentselectedIndoorOutdoorObj.join().toString();
            this.GetProjects(this.requestProject);
        }, 1000)
    }

    DropDownStatusChange(value) {
        var offerStatusSelected = this.currentselectedStatusObj.find(o => o == 4);
        var status = "";
        if (offerStatusSelected) {
            this.requestProject.IsActive = false;
            this.isActive = false;
        }
        if (value.id == 4 && !offerStatusSelected) {
            this.requestProject.IsActive = true;
            this.isActive = true;
        }
        if (this.currentselectedStatusObj.length <= 0) {
            status = "";
        }
        else {
            status = this.currentselectedStatusObj.join();
        }
        this.requestProject.multipleStatus = status;
        this.GetProjects(this.requestProject);
    }
    SearchBynumber() {
        this.requestProject.Address = this.requestProject.Address.trim();
        //For IndoorOutdoor select
        //this.currentselectedIndoorOutdoorObj = [];
        //this.requestProject.isIndoorOutdoorTypeProjects = "";
        //for (var i = 0; i < 7; i++) {
        //    this.currentselectedIndoorOutdoorObj.push(i);
        //}
        this.requestProject.isIndoorOutdoorTypeProjects = this.currentselectedIndoorOutdoorObj.join().toString();
        //For Status select
        //this.currentselectedStatusObj = [];
        //this.requestProject.multipleStatus = "";
        //if (this.roleName == "Maler") {
        //    for (var i = 0; i < 13; i++) {
        //        if (i != 9 && i != 12) {
        //            this.currentselectedStatusObj.push(i);
        //        }
        //    }
        //}
        //else {
        //    for (var i = 0; i < 13; i++) {
        //        this.currentselectedStatusObj.push(i);
        //    }
        //}
        this.requestProject.multipleStatus = this.currentselectedStatusObj.join().toString();
        this.requestProject.isLift = false;
        this.requestProject.isRiseman = false;
        this.GetProjects(this.requestProject);
    }

    onSetActive(active) {
        this.isActive = active;
        this.requestProject.Index = 1;
        this.requestProject.IsActive = active;
        this.GetProjects(this.requestProject);
    }
    //get Project list
    GetProjects(model: RequestStatusProject, isNgOnLoad: boolean = false) {
        this.showSpinner = true;
        //this.search = this.requestProject.Address;
        this.projectservice.setRequestParameterForCalender(model);
        this.projectservice.Get<ResponseBase>(model)
            .subscribe((response: ResponseBase) => {
                if (response != null) {
                    this.Projects = [];
                    this.Projects = response.result;
                    this.pagination = response.pagination;
                    this.displayPagination = true;
                    if (this.Projects && this.Projects.length > 0) {
                        this.projectservice.setBGImageForProjectCards(this.Projects);
                    } else {
                        this.Projects = [];
                        this.displayPagination = false;
                        this.translate.get("MESSAGES.NORECORDONSTATUS").subscribe({
                            next: (res) => {
                                this.notificationsService.error(res);
                            },
                            error: err => {
                            }
                        });
                    }
                    this.showSpinner = false;
                    for (var i = 0; i < this.Projects.length; i++) {
                        var formateDate = moment(this.Projects[i].startDate).format('YYYY-MM-DD');
                        if (this.After5DayDate >= formateDate)
                            this.Projects[i].displayStartDateRed = true;
                        else
                            this.Projects[i].displayStartDateRed = false;
                    }
                }
                else {
                    this.Projects = [];
                    this.displayPagination = false;
                    this.showSpinner = false;
                }
            },
                error => (err) => {
                    this.Projects = [];
                    this.displayPagination = false;
                    this.showSpinner = false;
                },
                () => {
                });
    }

    newEvent(project) {
        this.selectProjectEvent.emit(project);
    }

    toggleAllStatusIndoorOutDoorSelection() {
        this.currentselectedIndoorOutdoorObj = [];
        this.requestProject.isIndoorOutdoorTypeProjects = "";
        if (this.allIndoorOutdoorStatusSelected.selected) {
            for (var i = 0; i < 7; i++) {
                this.currentselectedIndoorOutdoorObj.push(i);
            }
            this.requestProject.isIndoorOutdoorTypeProjects = this.currentselectedIndoorOutdoorObj.join().toString();
        }
        this.GetProjects(this.requestProject);
    }

    toggleAllStatusSelection() {
        this.currentselectedStatusObj = [];
        this.requestProject.multipleStatus = "";
        if (this.allStatusSelected.selected) {
            if (this.roleName == "Maler") {
                for (var i = 0; i < 13; i++) {
                    if (i != 9 && i != 12) {
                        this.currentselectedStatusObj.push(i);
                    }
                }
            }
            else {
                for (var i = 0; i < 13; i++) {
                    this.currentselectedStatusObj.push(i);
                }
            }
            this.requestProject.multipleStatus = this.currentselectedStatusObj.join().toString();
        }
        this.GetProjects(this.requestProject);
    }

    checkAccess(event) {
        this.selectProjectEvent.emit(event);
        if (this.isOpenOnPopup) {
            this.dialogRef.close(event);
        }
    }
}
