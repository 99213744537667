import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({ name: 'dateFormat' })
export class DateFormat implements PipeTransform {
  constructor(private datePipe: DatePipe) { }

  transform(value: string): string {
    if (value) {
      return this.datePipe.transform(value, "dd.MM.yyyy");
    }
    else
      return value;
  }
}
