import { Component, OnInit } from '@angular/core';
import { ProjectofferService } from '../../project-offer.service';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { OfferCategory, OfferAddressModel, OffersMaterialDocument, OffersMaterialDocumentDetail } from 'app/main/model/ProjectOffer';
import { MatDialog} from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { OfferFormName } from 'app/core/constant/SettingConstant';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';
import { SendOfferEmailComponent } from '../send-offer-email/send-offer-email.component';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { PdfViewerModelComponent } from 'app/main/pages/pdf-viewer/pdf-viewer.component';
import { EmailSendingParamForOffer } from 'app/main/model/common';
import { CompanyInfoSetting } from 'app/main/model/CompanyInfo';
import { TextEditorComponent } from 'app/main/pages/text-editor/text-editor.component';
import { ReminderService } from 'app/main/pages/reminder/reminder.service';
import { environment } from 'environments/environment';
import { AuthService } from 'app/core/services/auth.service';
import { CalculatedMaterialListComponent } from '../calculated-material-list/calculated-material-list.component';
import { RequestCalculatedOfferMaterialList, RequestOfferConvert } from 'app/main/model/RequestBase';
import { CommonService } from 'app/core/services/common.service';
import { OfferV2ListSelecttionForConvertComponent } from '../offerlist-selection-for-convert/offerlist-selection-for-convert.component';
import { CalculatedViewMaterialListComponent } from '../calculated-view-material-list/calculated-view-material-list.component';

@Component({
    selector: 'offerV2-type-list',
    templateUrl: './offerV2-list.component.html',
    styleUrls: ['./offerV2-list.component.scss']
})
export class OfferV2ListComponent implements OnInit {
    projectOffer: OfferAddressModel = new OfferAddressModel();
    offerId: string = "";
    offerCategoryList: any;
    displayForm: string = '';
    selectedCategory: any;
    displayList: boolean = true;
    showSpinner: boolean = false;
    offerFormName = OfferFormName;
    TotalExclVat: number = 0;
    VAT: number = 0;
    TotalInclVat: number = 0;
    offerCategories: OfferCategory[] = [];
    offerCategoriesJson: OfferCategory[] = [];
    OfferNumber: number;
    isIncludePriceOnPDF: boolean = false;
    showOfferInPDF: boolean = false;
    offerIdList = [];
    IsOfferSelected: boolean = false;
    EmailHistoryList = [];
    isShowRoomTotal: boolean = false;
    offerMaterialFilesAndContent: any;
    OfferTypeIDS:any;
    requestCalculatedOfferMaterialList= new RequestCalculatedOfferMaterialList();
    constructor(private projectofferService: ProjectofferService,
        private translationLoader: FuseTranslationLoaderService,
        public dialog: MatDialog,
        private translate: TranslateService,
        private commonService: CommonService,
        private notificationsService: NotificationsService,
        private _router: Router,
        private _route: ActivatedRoute,
        private reminderService: ReminderService,
        private authService: AuthService) {
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
        this._route.params.subscribe(params => {
            var id = params['id'];
            if (id != undefined) {
                this.offerId = id;
            }
        });
        this.GetOfferItemGroup(this.offerId);
        this.GetOffer();
        this.GetOfferCategories();
        //this.GetEmailHistory();
    }

    GetOfferItemGroup(id) {
        this.showSpinner = true;
        //this.projectofferService.Getofferitemgroup<ResponseBase>(id)
        this.projectofferService.GetofferTask<ResponseBase>(id)
            .subscribe((response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null) {
                    this.offerCategoryList = response.result;
                    if (this.offerCategoryList.length == 1) {
                        this.offerCategoryList = this.offerCategoryList.map(x => ({ ...x, showOfferInPDF: true }));
                        this.IsOfferSelected = true;
                    } else {
                        this.offerCategoryList = this.offerCategoryList.map(x => ({ ...x, showOfferInPDF: false }));
                    }
                    if (this.offerCategoryList.length > 0) {
                        this.calculatedValue();
                    }
                }
            },
                error => (err) => {
                    this.showSpinner = false;
                });
    }

    onCheckboxCheck() {
        setTimeout(() => {
            this.IsOfferSelected = this.offerCategoryList.some(x => x.showOfferInPDF == true);
        }, 100);
    }

    GetOffer() {
        //this.projectofferService.Getofferitemgroup<ResponseBase>(id)
        this.projectofferService.GetSingle<ResponseBase>(this.offerId)
            .subscribe((response: ResponseBase) => {
                if (response != null) {
                    this.projectOffer = response.result;
                }
            },
                error => (err) => {
                });
    }

    SendEmail(materialOfferFileList: OffersMaterialDocumentDetail[]) {
        this.offerIdList = this.offerCategoryList.filter(x => x.showOfferInPDF == true).map(y => y.id);
        var offerIdsList = this.offerIdList.join().toString(); //JSON.stringify(this.offerIdList);
        var emailParam = new EmailSendingParamForOffer();
        emailParam.offerId = this.offerId;
        emailParam.to = this.projectOffer.email;
        emailParam.toName = "";
        // emailParam.from = "post@vibesmalerfirma.dk";
        emailParam.fromName = "Admin";
        emailParam.subject = "Hermed fremsendes tilbud.";
        emailParam.body = this.offerMaterialFilesAndContent && this.offerMaterialFilesAndContent.materialContent ? this.offerMaterialFilesAndContent.materialContent : "";
        emailParam.isIncludePriceOnPDF = this.isIncludePriceOnPDF;
        emailParam.offerTaskIds = offerIdsList;
        emailParam.offerNumber = this.projectOffer.number;
        emailParam.projectId = this.projectOffer.projectId;
        emailParam.projectNumber = this.projectOffer.projectNumber;
        emailParam.offerMaterialFiles = materialOfferFileList;
        emailParam.isShowRoomTotal = this.isShowRoomTotal;
        const dialogRef = this.dialog.open(SendOfferEmailComponent, {
            height: '90%',
            width: '90%',
            // data: id
            data: emailParam
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.projectofferService.SendEmailInvoiceWithAttachmentToCustomer<ResponseBase>(result)
                    .subscribe((response: ResponseBase) => {
                        if (response) {
                            this.GetEmailHistory();
                            this.translate.get("MESSAGES.Success").subscribe({
                                next: (res) => {
                                    this.notificationsService.success(res);
                                },
                                error: err => {
                                }
                            });
                            this.commonService. checkEmailIsToBeSent();
                        }
                    },
                        error => (err) => {
                            this.showSpinner = false;
                            this.translate.get("MESSAGES.Failed").subscribe({
                                next: (res) => {
                                    this.notificationsService.error(res);
                                },
                                error: err => {
                                }
                            });
                        });
            }
        });
    }

    SendOfferOnEmailWithFiles() {
        this.showSpinner = true;
        var documentObj = new OffersMaterialDocument();
        documentObj.offerID = this.offerId;
        documentObj.offerTreatmentID = this.offerCategoryList.filter(x => x.showOfferInPDF == true).map(y => y.id);
        this.projectofferService.GetOfferMaterialFiles<ResponseBase>(documentObj)
            .subscribe((response: ResponseBase) => {
                if (response) {
                    this.offerMaterialFilesAndContent = response.result;
                    this.showSpinner = false;
                    this.SendEmail(response.result.materialFiles);
                }
            },
                error => (err) => {
                    this.showSpinner = false;
                    this.SendEmail(null);
                });
    }

    GetOfferCategories() {
        this.projectofferService.GetOfferCategories().subscribe({
            next: (response) => {
                if (response) {
                    this.offerCategoriesJson = response.result.myResponse;
                    //this.offerCategories = this.offerCategoriesJson.filter(x => x.level == 1);
                    this.offerCategories = this.offerCategoriesJson.filter(x => x.level <= 2 && x.formName != null);
                }
            },
            error: err => {

            },
            complete: () => {
            }
        });
    }

    selectOfferItem(obj) {
        //if (obj.offerCategoryId) {
        if (!obj.formName) {
            var data = this.offerCategories.filter(x => x.id == obj.offerCategoryId);
            if (data.length > 0) {
                //this.offerFormName.FormA = data[0].formName;
                this.displayForm = data[0].formName;
                this.selectedCategory = obj;
                this.displayList = false;
            }
        }
        else {
            this.displayForm = obj.formName;
            this.selectedCategory = obj;
            this.displayList = false;
        }
        //}
    }

    getOfferItemGroup(event) {
        this.GetOfferItemGroup(this.offerId);
        this.displayForm = "";
        this.displayList = true;
        this.GetEmailHistory();
    }

    goBack() {
        this.displayList = true;
        this.GetOfferItemGroup(this.offerId);
    }

    editOfferText(roomData) {
        var roomDetail = roomData
        var selectedTabData = new CompanyInfoSetting();
        selectedTabData.Description = roomDetail.offerText;
        const dialogRef = this.dialog.open(TextEditorComponent, {
            height: '90%',
            width: '90%',
            data: { selectedTabData, isEmail: false },
            disableClose: true
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result && result.Description) {
                if (roomDetail.offerText !== result.Description) {
                    roomDetail.offerText = result.Description;
                    this.updateOfferType(roomDetail);
                }
            }
        });
    }

    updateOfferType(roomDetail) {
        this.projectofferService.UpdateOfferTypeText(roomDetail).subscribe((response: ResponseBase) => {
            if (response) {
                this.translate.get("MESSAGES.Success").subscribe({
                    next: (res) => {
                        this.notificationsService.success(res);
                    },
                    error: err => {
                    }
                });
            }

        });
    }

    deleteOffer(id) {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: '200px',
            // data: id
            data: { Header: 'Headers.DeleteOffer' }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.deleteProjectOfferRoom(id);
                //this.removeProjectMaterial(result);
            }
        });
    }

    deleteProjectOfferRoom(id) {
        this.projectofferService.deleteProjectOfferItemGroup<ResponseBase>(id)
            .subscribe((response: ResponseBase) => {
                if (response != null) {
                    this.GetOfferItemGroup(this.offerId);
                }
                else {
                    //this.translate.get("MESSAGES.Failed").subscribe({
                    //  next: (res) => {
                    //    this.notificationsService.error(res);
                    //  },
                    //  error: err => {
                    //  }
                    //});
                }
            },
                error => (err) => {
                    //this.showSpinner = false;
                    //this.translate.get("MESSAGES.Failed").subscribe({
                    //  next: (res) => {
                    //    this.notificationsService.error(res);
                    //  },
                    //  error: err => {
                    //  }
                    //});
                });
    }

    SavePDF(flag) {
        this.showSpinner = true;
        this.offerIdList = this.offerCategoryList.filter(x => x.showOfferInPDF == true).map(y => y.id);
        var offerIdsList = this.offerIdList.join().toString(); //JSON.stringify(this.offerIdList);
        var OfferInvoiceDetails = {
            "OfferId": this.offerId,
            "IsAddOfferRoomDetail": flag,
            "IsIncludePriceOnPDF": this.isIncludePriceOnPDF,
            "offerTaskIds": offerIdsList,
            "isShowRoomTotal": this.isShowRoomTotal
        };

        this.projectofferService.GetOfferPDFbyIds(OfferInvoiceDetails).subscribe((response: ResponseBase) => {
            var resultData = response.result;
            var data = response.result.base64.replace("data:application/pdf;base64,", "");
            this.OfferNumber = JSON.parse(localStorage.getItem("OfferNumber"));
            const dialogRef = this.dialog.open(PdfViewerModelComponent, {
                height: '90%',
                width: '90%',
                data: {
                    base64: data,
                    filename: response.result.filename ? response.result.filename + ".pdf" : "Tilbud_" + this.OfferNumber + ".pdf"
                }
            });
            dialogRef.afterClosed().subscribe(result => {
                if (result) {

                }
            });
        },
            error => (err) => {
            },
            () => {
                this.showSpinner = false;
            });
    }

    calculatedValue() {
        this.TotalExclVat = 0;
        this.VAT = 0;
        this.TotalInclVat = 0;
        this.offerCategoryList.forEach(item => {
            this.TotalExclVat += item.price;
        });

        this.VAT = (this.TotalExclVat * 25) / 100;
        this.TotalInclVat = this.TotalExclVat + this.VAT;
    }
    onCheckIsIncludePriceOnPDF(flag) {
        this.isIncludePriceOnPDF = flag;
    }

    GetEmailHistory() {
        this.reminderService.GetEmailHistory(this.offerId, "Offer").subscribe({
            next: (response: ResponseBase) => {
                if (response) {
                    this.EmailHistoryList = response.result.myResponse;
                    this.EmailHistoryList.forEach(i => {
                        if (i.json) {
                            i.attachmentURLList = Object.assign({}, JSON.parse(i.json));
                        }
                    });
                } else {
                    this.EmailHistoryList = [];
                }
            },
            error: err => {

            },
            complete: () => {
            }
        });
    }

    viewEmailDetail(emailData) {
        var url = environment.url + emailData.filePath
        window.open(url, "_blank");
    }

    // getAttachmentIcon(value) {
    //     if (value) {
    //         return 'description';
    //     }
    // }

    viewAttachmentURL(attachmentURL) {
        if (attachmentURL) {
            var url = environment.url + attachmentURL;
            window.open(url, "_blank");
        }
    }

    openMateraialPermission() {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: '200px',
            data: { Header: 'Headers.MaterialPermission' }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                console.log(result)
                // Add Api for MAterial
            }
        });
    }

    openMaterialList() {
        this.offerIdList = this.offerCategoryList.filter(x => x.showOfferInPDF == true).map(y => y.id);
        var OfferNumberOfHours = this.offerCategoryList.filter(x => x.showOfferInPDF == true).map(y => y.numberOfHours);
        var Number =  OfferNumberOfHours.join().toString(); 
        var offerIdsList = this.offerIdList.join().toString(); 
        this.requestCalculatedOfferMaterialList.offerID = this.offerId,
        this.requestCalculatedOfferMaterialList.offerTypeIDS = offerIdsList;
        var showDriving = this.offerCategoryList.find(o => o.id);
        const dialogRef = this.dialog.open(CalculatedMaterialListComponent, {
            height: '90%',
            width:'90%',
            data:{
                "requestCalculatedOfferMaterialList" : this.requestCalculatedOfferMaterialList,
                "showDriving": showDriving,
                "address":{
                        Address: this.projectOffer.address,
                        ZipCode: this.projectOffer.postNumber,
                        City: this.projectOffer.city,
                        NumberOfHours:Number,
                        autoSearchOnOpen: true,
                }
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            // if (result) {
            // }
        });
    }

    openViewMaterialList() {
        this.offerIdList = this.offerCategoryList.filter(x => x.showOfferInPDF == true).map(y => y.id);
        var OfferNumberOfHours = this.offerCategoryList.filter(x => x.showOfferInPDF == true).map(y => y.numberOfHours);//.reduce((sum, current) => sum + current);
        var Number =  OfferNumberOfHours.join().toString(); 
        var offerIdsList = this.offerIdList.join().toString(); 
        this.requestCalculatedOfferMaterialList.offerID = this.offerId,
        this.requestCalculatedOfferMaterialList.offerTypeIDS = offerIdsList;
        var showDriving = this.offerCategoryList.find(o => o.id);
        const offerTypeList = this.offerCategoryList;
        const dialogRef = this.dialog.open(CalculatedViewMaterialListComponent, {
            height: '90%',
            width:'90%',
            data:{
                "requestCalculatedOfferMaterialList" : this.requestCalculatedOfferMaterialList,
                "showDriving": showDriving,
                "offerTypeList": offerTypeList,
                "address":{
                        Address: this.projectOffer.address,
                        ZipCode: this.projectOffer.postNumber,
                        City: this.projectOffer.city,
                        numberOfHours:Number,
                        distance : showDriving.distance,
                        time : showDriving.time,
                        autoSearchOnOpen: true,
                },
                "moduleName":"Offer"
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            // if (result) {
            // }
        });
    }

    ngAfterViewInit() {
        this.authService.GetEmailHistoryListChangeObs.subscribe((data) => {
            if (data) {
                this.GetEmailHistory();
            }
        });
    }

    convertProject() {
        const dialogRef = this.dialog.open(OfferV2ListSelecttionForConvertComponent, {
            width:'50%',
            data: { offerList:  this.offerCategoryList.map(x => ({ ...x})) }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                const requestOfferConvert = new RequestOfferConvert();
                requestOfferConvert.offerId = this.offerId;
                requestOfferConvert.offerTypeIds = result.filteredIds;
                requestOfferConvert.isInactiveOffer = result.isInactiveOffer;
                this.showSpinner = true;
                this.projectofferService.ConvertOfferToProject<ResponseBase>(requestOfferConvert)
                    .subscribe((response: ResponseBase) => {
                        this.showSpinner = false;
                        if (response != null) {
                            this._router.navigate(['/projects/edit/' + response.result.id]);
                        } 
                });
            }
        });
    }
    
    gotoProject(projectId) {
        this._router.navigate(['/projects/edit/' + projectId]);
    }
}
