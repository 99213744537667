import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ProjectofferService } from './../../project-offer.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { FuseTranslationLoaderService } from '../../../../../core/services/translation-loader.service';
import { ResponseBase } from '../../../../model/ResponseBase';
import { CommonService } from 'app/core/services/common.service';

@Component({
    selector: 'app-rejected-question',
    templateUrl: './rejected-question.component.html',
    styleUrls: ['./rejected-question.component.scss']
})
export class OfferRejectedQuestionComponent implements OnInit {
    @Input() QuestionList: any;
    questionAnswerList: any = [];
    offerId: string = "";
    showSpinner: boolean = false;
    ratingNumber: number;
    choiceSelected: boolean = true;
    isDisabled:boolean = true;
    constructor(public dialog: MatDialog,
        private translationLoader: FuseTranslationLoaderService,
        private projectofferService: ProjectofferService,
        private _router: Router,
        private _route: ActivatedRoute,
        private notificationsService: NotificationsService,
        private translate: TranslateService,
        private commonService: CommonService) {
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
        this._route.params.subscribe(params => {
            this.offerId = params['id'];
        });
        if (this.QuestionList) {
            this.getOfferQuestionAnswerList();
        }
    }

    getOfferQuestionAnswerList() {
        this.questionAnswerList = this.QuestionList;
        this.questionAnswerList.filter(item => {
            if (item.questionType == "Rating") {
                this.ratingNumber = item.answer;
            }
            if (item.questionType == "MultiChoice" || item.questionType == "SingleChoice") {
                item.choiceOptions = JSON.parse(item.choiceOptions);
            }
        });
        // this.showSpinner = true;
        // this.projectofferService.getProjectOfferQuestionAnswer<ResponseBase>(this.offerId)
        //     .subscribe((response: ResponseBase) => {
        //         this.showSpinner = false;
        //         if (response != null && response.result) {
        //             this.questionAnswerList = response.result.questionList;
        //             this.questionAnswerList.filter(item => {
        //                 if (item.questionType == "rating") {
        //                     this.ratingNumber = item.answer;
        //                 }
        //                 if (item.questionType == "MultiChoice" || item.questionType == "SingleChoice") {
        //                     item.choiceOptions = JSON.parse(item.choiceOptions);
        //                 }
        //             });
        //         }
        //     },
        //         error => (err) => {
        //             this.showSpinner = false;
        //             this.translate.get("MESSAGES.Failed").subscribe({
        //                 next: (res) => {
        //                     this.notificationsService.error(res);
        //                 },
        //                 error: err => {
        //                 }
        //             });
        //         });
    }
}
