import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { OfferAddressModel, OffersMaterialDocumentDetail, OffersMaterialDocument, OutdoorFileList, OfferCategory } from 'app/main/model/ProjectOffer';
import { MatDialog, MatExpansionPanel } from '@angular/material';
import { ProjectofferService } from 'app/main/pages/project-offer/project-offer.service';
import { TitleService } from 'app/core/services/title.service';
import { RequestProjectOfferRoom } from 'app/main/model/RequestBase';
import { ActivatedRoute } from '@angular/router';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { OfferRoomPriceDTO, OfferTypeWithRoomPriceDetail, OfferMaterialWithPrice, OfferMeterWisePrice, DiverseDTO, MaterialList, OfferRoomCalculationWithMaterialList, OfferRoomCalculationDTO, OfferRoomDetailDTO, OfferRoomFullDetailDTO } from 'app/main/model/OfferV2';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { FormService } from 'app/core/services/form.service';
import { OfferV2FormMaterialSelectForAllComponent } from '../component/material-select-for-all/material-select.component';
import { PdfViewerModelComponent } from 'app/main/pages/pdf-viewer/pdf-viewer.component';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';
import { NewMaterialComponent } from 'app/main/pages/material/new-material/new-material.component';
import { TextEditorComponent } from 'app/main/pages/text-editor/text-editor.component';
import { MaterialUnitList } from 'app/main/model/ProjectStatus';
import { CompanyInfoSetting, CompanyInfo } from 'app/main/model/CompanyInfo';
import { MaterialTypeCategory, PaintSelectTime, OffMaterialTypeIds, TreatmentTypeIds, OfferRoomCalculationType, OfferTreatmentType } from 'app/constant/common.constant';
import { CommonService } from 'app/core/services/common.service';
import { ApiConstant } from 'app/core/constant/ApiConstant';
import { Options } from '@angular-slider/ngx-slider';
import { EmailSendingParamForOffer } from 'app/main/model/common';
import { SendOfferEmailComponent } from '../../send-offer-email/send-offer-email.component';
import { TextareaEditorComponent } from '../component/textarea-editor/textarea-editor.component';
import { OfferCategoryMaterial } from 'app/main/model/ProjectOffer';
import { CustomersService } from 'app/main/pages/customer/customer.service';
import { GoogleMapDirectionComponent } from 'app/main/pages/google-map-direction/google-map-direction.component';
import { CompanyInfoService } from 'app/main/pages/companyInfo/companyInfo.service';
import { CalculateDrivingDaysModalComponent } from '../component/calculate-driving-days/calculate-driving-days.component';
import { FolderListNavigationDTO, ListFolder, SearchFolderOrFiles } from 'app/main/model/DropBox';
import { DropboxFilesService } from 'app/main/shared-component/dropbox-files/dropbox-files.service';
import { DriveModuleEnum } from 'app/core/enum/drive-module.enum';

@Component({
    selector: 'offerV2-form-b',
    templateUrl: './offerV2-form-b.component.html',
    styleUrls: ['./offerV2-form-b.component.scss']
})
export class OfferV2FormBComponent implements OnInit {
    showSpinner: boolean = false;
    @Input() selectedCategoryInput: OfferTypeWithRoomPriceDetail;
    @Input() customerDetailObject: any;
    @Output() DisplayList = new EventEmitter<any>();
    @Output() eventDisabledTabsFromOffer = new EventEmitter<any>();
    displayEditOfferCategory: boolean = false;
    requestProjectOfferRoom: RequestProjectOfferRoom = new RequestProjectOfferRoom();
    OfferTypeDetail: OfferTypeWithRoomPriceDetail = new OfferTypeWithRoomPriceDetail();
    projectOffer: OfferAddressModel = new OfferAddressModel();
    MaterialObject: OfferMaterialWithPrice[] = [];
    wallMaterialObject: OfferMaterialWithPrice[] = [];
    ceilingMaterialObject: OfferMaterialWithPrice[] = [];
    floorMaterialObject: OfferMaterialWithPrice[] = [];
    SelectCategoryTime = PaintSelectTime;
    MaterialTypeSearch = "";
    MasterMaterialTypeList = [];
    MasterOfferRoomList = [];
    MasterOfferRoomListOnEdit = [];
    TempfinalPrice: number = 0;
    OfferCategoryform: FormGroup;
    offerform: FormGroup;
    wallMeterWisePrice: OfferMeterWisePrice = new OfferMeterWisePrice();
    ceilingMeterWisePrice: OfferMeterWisePrice = new OfferMeterWisePrice();
    floorMeterWisePrice: OfferMeterWisePrice = new OfferMeterWisePrice();
    //selectCategoryTime = [{id:1, value:'1 Time'}, {id:2, value:'2 Time'}, {id:3, value:'3 Time'}]
    selectedRoomOnEdit: OfferRoomPriceDTO = new OfferRoomPriceDTO();
    isAddFloor: boolean = false;
    matObj: any = [];
    isDiverse: boolean = false;
    grandTotal: number = 0;
    DefaultMaterilaList: OfferMaterialWithPrice[] = [];
    selectUnitList = MaterialUnitList;
    MaterialTypeSearchForDiverse = "";
    MaterialObjectForDiverse: OfferMaterialWithPrice[] = [];
    roomList = [];
    roomName: string = "";
    type: number = 0;
    selectMaterialOfferList = MaterialTypeCategory;
    tempMaterialList = [];
    tempMaterialDetails: any = {};
    TotalM2ForWall = 0;
    TotalM2ForCeiling = 0;
    TotalM2ForFloor = 0;
    TotalForWall = 0;
    TotalForCeiling = 0;
    TotalForFloor = 0;
    TotalForAllRooms = 0;
    OfferNumber: number;
    //#region ngx-Slider start
    value: number = 0;
    options: Options = {
        floor: 0,
        ceil: 1500,
        showSelectionBar: true,
        getSelectionBarColor: (value: number): string => {
            if (value <= 300) {
                return 'red';
            }
            if (value <= 600) {
                return 'orange';
            }
            if (value <= 900) {
                return 'yellow';
            }
            if (value <= 1200) {
                return '#b2f3b1';
            }
            return '#2AE02A';
        }
    };
    isIncludePriceOnPDF: boolean = false;
    gulvCheckedCount: number = 0;
    offMaterialTypeIds = OffMaterialTypeIds;
    treatmentTypeIds = TreatmentTypeIds;
    offerRoomCalculationType = OfferRoomCalculationType;
    tempAssignedMaterialList = [];

    // New Offer
    projectsOfferRooms: OfferRoomFullDetailDTO[] = [];
    meterWisePrice: OfferMeterWisePrice = new OfferMeterWisePrice();
    isGulvExits: boolean = false;
    isDisplayRoomDetail: boolean = false;
    offerCategoryMaterialList: OfferCategoryMaterial[] = [];
    projectPriceDiscount: number = 0;
    customerDetailObj: any;
    companyInfoData: CompanyInfo;
    DistancePerDayValue: number;
    meterAndKmText: string = "km";
    isPerformanceTextAdded: number = 0;
    fileFolderPathData: Array<FolderListNavigationDTO> = [];
    public show: boolean = false;
    totalFileCount: number = 0;
    listFolder: ListFolder;
    outdoorFileList: OutdoorFileList[] = [];
    outdoorFileFolderPathData: any;
    searchFileAndFolder: SearchFolderOrFiles;
    isSearch: boolean = false;
    searchValue: string = "";
    isDisplayOnlySearchFolder: boolean = false;
    isEditDrivingDays: boolean = false;
    offerTreatmentTypeList = OfferTreatmentType;
    isShowRoomTotal:boolean = false
    offerMaterialFilesAndContent: any;
    settingMasterValues:any[] = [];
    projectsCoworkerPrice: number = 0;
    paintTimer: number = 0;
    //#endregion

    constructor(private projectofferService: ProjectofferService,
        private translationLoader: FuseTranslationLoaderService,
        private _route: ActivatedRoute,
        public dialog: MatDialog,
        private translate: TranslateService,
        private notificationsService: NotificationsService,
        private formBuilder: FormBuilder,
        private formService: FormService,
        private commonService: CommonService,
        private titleService: TitleService,
        private companyInfoService: CompanyInfoService,
        private customersService: CustomersService,
        private dropboxFilesService: DropboxFilesService,
    ) {
        this.translationLoader.loadTranslations(english, danish);

    }

    ngOnInit() {
        localStorage.removeItem(ApiConstant.LocalRequestModelForMaterialAll);
        this.settingMasterValues = Object.assign([], JSON.parse(localStorage.getItem("settingMasterValues")));
        var tempSettingObject = this.settingMasterValues.find(s => s.key1 == "Project" && s.key2 == "ProjectsCoworkerPrice").value;
        if(tempSettingObject)
            this.projectsCoworkerPrice = parseFloat(tempSettingObject);  
        else
            this.projectsCoworkerPrice = 580;
        this._route.params.subscribe(params => {
            var id = params['id'];
            if (id != undefined) {
                this.OfferTypeDetail.offerId = id;
                this.getOfferCategoryMaterials(this.selectedCategoryInput.offerCategoryId);

            }
        });
        if (this.OfferTypeDetail.offerId) {
            this.GetOffer();
        }
        this.getOfferMaterialData(true);
        this.OfferTypeDetail.offerCategoryId = this.selectedCategoryInput.offerCategoryId;
        this.OfferTypeDetail.name = this.selectedCategoryInput.name;
        this.OfferTypeDetail.formName = this.selectedCategoryInput.formName;
        this.offerform = this.formBuilder.group({
            discount: [0, [Validators.required, Validators.minLength(0), Validators.max(99999999999)]]
        });
        this.GetCompanyInformation();
        this.getTravelingDistancePerDayValue();
    }

    convertDigit() {
        var t = Number(this.OfferTypeDetail.price)
            .toLocaleString('en-DA', { style: 'currency', currency: 'DKK' })
            .replace('DKK ', '')
            .replace(/,/g, '.')
            .replace(/.([^.]*)$/, ',$1');
    }

    getProjectOfferRoomList(model, isEditMode) {
        this.projectofferService.getProjectOfferRoomList<ResponseBase>(model)
            .subscribe((response: ResponseBase) => {
                if (response != null) {
                    var ProjectsOfferRooms = response.result.myResponse;
                    this.roomList = response.result.myResponse;
                    if (!isEditMode) {
                        this.projectsOfferRooms = [];
                        if (ProjectsOfferRooms) {
                            for (var i = 0; i < ProjectsOfferRooms.length; i++) {
                                var offerRoomCalculationList = [];
                                var offerRoomFullDetailDTO = new OfferRoomFullDetailDTO();
                                offerRoomFullDetailDTO.offerRoomId = ProjectsOfferRooms[i].id;
                                offerRoomFullDetailDTO.name = ProjectsOfferRooms[i].name;
                                offerRoomFullDetailDTO.totalPrice = 0;
                                offerRoomFullDetailDTO.isActive = true;

                                var offerRoomCalculationItem = new OfferRoomCalculationWithMaterialList();
                                offerRoomCalculationItem.length = ProjectsOfferRooms[i].length;
                                offerRoomCalculationItem.width = ProjectsOfferRooms[i].width;
                                offerRoomCalculationItem.ceilingHeight = ProjectsOfferRooms[i].ceilingHeight;
                                offerRoomCalculationItem.offerRoomDetailId = ProjectsOfferRooms[i].id;
                                offerRoomCalculationItem.extraQty1 = ProjectsOfferRooms[i].extraQty1 ? ProjectsOfferRooms[i].extraQty1 : 0;
                                offerRoomCalculationItem.extraQty2 = ProjectsOfferRooms[i].extraQty2 ? ProjectsOfferRooms[i].extraQty2 : 0;
                                offerRoomCalculationItem.extraPrice1 = ProjectsOfferRooms[i].extraPrice1 ? ProjectsOfferRooms[i].extraPrice1 : 0;
                                offerRoomCalculationItem.extraPrice2 = ProjectsOfferRooms[i].extraPrice2 ? ProjectsOfferRooms[i].extraPrice2 : 0;
                                offerRoomCalculationItem.isActive = true;
                                if (offerRoomCalculationItem.extraQty1 > 0 || offerRoomCalculationItem.extraQty2 > 0 || offerRoomCalculationItem.extraPrice1 > 0 && offerRoomCalculationItem.extraPrice2 > 0)
                                    offerRoomCalculationItem.isAddExtraWallQty = true;

                                for (var m = 1; m < 4; m++) {
                                    let tempOfferRoomCalculationItem = Object.assign({}, offerRoomCalculationItem);
                                    tempOfferRoomCalculationItem.materialList = [];
                                    var materialObj = new MaterialList();
                                    if (m == 1) {
                                        this.tempAssignedMaterialList = [];
                                        materialObj.offerMaterialTypeId = this.offMaterialTypeIds.WallType;
                                        this.tempAssignedMaterialList = this.MaterialObject.filter(o => { return o.type.split(',').find(p => p == materialObj.offerMaterialTypeId) });
                                        materialObj.materialList = this.tempAssignedMaterialList.map(x => Object.assign({}, x)); //this.MaterialObject.filter(o => { return o.type.split(',').find(p => p == materialObj.offerMaterialTypeId) });

                                        tempOfferRoomCalculationItem.typeId = m;
                                        tempOfferRoomCalculationItem.materialList.push(materialObj);
                                        offerRoomCalculationList.push(tempOfferRoomCalculationItem);
                                    }
                                    if (m == 2) {
                                        this.tempAssignedMaterialList = [];
                                        materialObj.offerMaterialTypeId = this.offMaterialTypeIds.CeilingType;
                                        this.tempAssignedMaterialList = this.MaterialObject.filter(o => { return o.type.split(',').find(p => p == materialObj.offerMaterialTypeId) });
                                        materialObj.materialList = this.tempAssignedMaterialList.map(x => Object.assign({}, x));//this.MaterialObject.filter(o => { return o.type.split(',').find(p => p == materialObj.offerMaterialTypeId) });
                                        tempOfferRoomCalculationItem.typeId = m;
                                        tempOfferRoomCalculationItem.materialList.push(materialObj);
                                        offerRoomCalculationList.push(tempOfferRoomCalculationItem);
                                    }
                                }
                                offerRoomFullDetailDTO.offerRoomCalculation = offerRoomCalculationList;
                                this.projectsOfferRooms.push(offerRoomFullDetailDTO);

                            }
                            if (!this.selectedCategoryInput.id) {
                                this.OfferTypeDetail.offerRoomDetailList = [];
                                this.OfferTypeDetail.offerRoomDetailList = this.projectsOfferRooms;
                            }
                            else
                                this.calculateRoomDifference();

                        }
                        this.OfferTypeDetail.offerRoomDetailList = this.projectsOfferRooms;
                    }
                    else
                        this.getOfferTypeData();
                }
                else {
                    this.OfferTypeDetail.offerRoomDetailList = [];
                    this.OfferTypeDetail.offerDiverseDetailList = [];
                    this.getOfferTypeData();
                }
            },
                error => (err) => {
                });
    }

    calculateRoomDifference() {
        if (this.OfferTypeDetail.offerRoomDetailList && this.MasterOfferRoomList) {
            for (let i = 0; i < this.MasterOfferRoomList.length; i++) {
                var offerRoom = this.OfferTypeDetail.offerRoomDetailList.find(o => o.offerRoomId == this.MasterOfferRoomList[i].offerRoomId)
                if (!offerRoom) {
                    this.OfferTypeDetail.offerRoomDetailList.push(this.MasterOfferRoomList[i]);
                }
            }
        }
    }


    save(isCheckValidation) {
        this.isPerformanceTextAdded = this.OfferTypeDetail.offerDiverseDetailList.filter(x => !(x.text || x.materialID)).length;
        if (this.isPerformanceTextAdded > 0) {
            this.translate.get("MESSAGES.SelectPerformanceMaterial").subscribe({
                next: (res) => {
                    this.notificationsService.error(res);
                },
                error: err => {
                }
            });
            return;
        }
        if (!this.OfferTypeDetail.distance || !this.OfferTypeDetail.time) {
            this.OpenRoutePlanPopup();
        } else {
            //this.saveAndUpdateOffer(null);
            if (this.OfferTypeDetail.numberOfDays != this.OfferTypeDetail.numberOfDrivingDays) {
                this.CallNumberOfDrivingDaysPopup();
            } else {
                this.saveAndUpdateOffer(null);
            }
        }
    }

    //Route plan popup
    OpenRoutePlanPopup() {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: "200px",
            data: { Header: 'Headers.RoutePlanHeader' }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.showDirection();
            } else {
                if (this.OfferTypeDetail.numberOfDays != this.OfferTypeDetail.numberOfDrivingDays) {
                    this.CallNumberOfDrivingDaysPopup();
                } else {
                    this.saveAndUpdateOffer(null);
                }
            }
        })
    }

    openPopUp(text, wallRoomsName, CeilingRoomsName) {
        var fullMsg = "";
        var message = "";
        this.translate.get("HEADER.WallAndCeiling." + text).subscribe({
            next: (res) => {
                message = res.replace('@wallRoom', wallRoomsName);
                message = message.replace('@ceilingRoom', CeilingRoomsName);
                message = message.replace('.', '.' + '<br/>')
                fullMsg = message;

            }, error: err => { }
        })
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: "200px",
            data: {
                Header: fullMsg, //'HEADER.WallAndCeiling.' + text,
            }
        })

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                //if (this.OfferTypeDetail.priceTotal > 0) {
                //}
                if (this.OfferTypeDetail.numberOfDays != this.OfferTypeDetail.numberOfDrivingDays) {
                    this.CallNumberOfDrivingDaysPopup();
                } else {
                    this.saveAndUpdateOffer(null);
                }
            }
        });
    }

    saveAndUpdateOffer(value) {
        this.showSpinner = true;
        this.projectofferService.SaveOfferItems(this.OfferTypeDetail).subscribe({
            next: (response) => {
                if (response) {
                    this.eventDisabledTabsFromOffer.emit(false);
                    this.OfferTypeDetail = response.result;
                    //this.OfferTypeDetail.numberOfDrivingDays = this.OfferTypeDetail.numberOfDrivingDays ? this.OfferTypeDetail.numberOfDrivingDays : this.OfferTypeDetail.numberOfDays;
                    this.selectedCategoryInput.id = this.OfferTypeDetail.id;
                    if (this.OfferTypeDetail.offerDiverseDetailList.length > 0) {
                        this.OfferTypeDetail.offerDiverseDetailList.forEach(item => {
                            if (item.materialID)
                                this.getMaterialPriceOnEdit(item.materialID, item, item.materialPriceID);
                        })
                        this.getDiverseMaterialOnEdit();
                        this.calculateDiversePrise();
                        this.getSumOfM2ForWallceilingFloor();
                        this.calculateTravelingAmt();
                        setTimeout(() => {
                            this.calTravelingAmtOnDrivingDaysChange();
                        }, 4000);
                        setTimeout(() => {
                            //this.OfferTypeDetail.numberOfDays = this.OfferTypeDetail.numberOfDrivingDays;
                            if (this.isEditDrivingDays) {
                                this.editTravelingTime();
                            }
                        }, 4500);
                        this.outdoorFileList = JSON.parse(localStorage.getItem("OutdoorCategoryImages"));
                        if (this.selectedCategoryInput.fileList && this.selectedCategoryInput.fileList.length) {
                            this.SetFileDetails();
                        }
                    }
                }
                if (value == undefined) {
                    this.translate.get("MESSAGES.Success").subscribe({
                        next: (res) => {
                            this.notificationsService.success(res);
                        },
                        error: err => {
                        }
                    });
                }
            },
            error: err => {
                this.showSpinner = false;
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            },
            complete: () => {
                this.showSpinner = false;
                if (value != null)
                    this.SavePDF(value);
            }
        });
    }

    getOfferTypeData() {
        this.projectofferService.getOfferTypeDataWithRoomDetail(this.selectedCategoryInput.offerId, this.selectedCategoryInput.id).subscribe({
            next: (response: ResponseBase) => {
                this.OfferTypeDetail.offerRoomDetailList = [];
                if (!this.OfferTypeDetail.offerDiverseDetailList) {
                    this.OfferTypeDetail.offerDiverseDetailList = [];
                }
                this.OfferTypeDetail = response.result;
                if (this.OfferTypeDetail.offerDiverseDetailList.length > 0) {
                    if (this.OfferTypeDetail.offerRoomDetailList && this.OfferTypeDetail.offerRoomDetailList.length > 0)
                        this.editOffer(this.OfferTypeDetail.offerRoomDetailList[0].id, this.OfferTypeDetail.offerRoomDetailList[0].offerRoomId)
                    this.getDiverseMaterialOnEdit();
                    this.OfferTypeDetail.offerDiverseDetailList.forEach(item => {
                        if (item.materialID)
                            this.getMaterialPriceOnEdit(item.materialID, item, item.materialPriceID);
                    })
                    this.calculateDiversePrise();
                    setTimeout(() => {
                        this.calTravelingAmtOnDrivingDaysChange();
                    }, 4500);
                    setTimeout(() => {
                        //this.OfferTypeDetail.numberOfDays = this.OfferTypeDetail.numberOfDrivingDays;
                        this.editTravelingTime();
                    }, 4500);
                    this.SearchFile();
                }
            },
            error: err => { },
            complete: () => {

            }
        });

    }

    getOfferMaterialData(isAddedDefault) {
        this.showSpinner = true;
        this.projectofferService.GetOfferCategoryMaterial().subscribe({
            next: (response: ResponseBase) => {
                this.MaterialObject = response.result.myResponse;
                this.wallMaterialObject = response.result.myResponse;
                this.ceilingMaterialObject = response.result.myResponse;
                this.floorMaterialObject = response.result.myResponse;
                this.MasterMaterialTypeList = response.result.myResponse;
                this.MaterialObjectForDiverse = response.result.myResponse;
                if (response.result.myResponse && isAddedDefault) {
                    //this.DefaultMaterilaList = response.result.myResponse.filter(o => { return o.type.split(',').find(p => p == this.offerCategoryTypeId) });
                    setTimeout(() => {
                        if (this.selectedCategoryInput.id == "") {
                            this.OfferTypeDetail.offerDiverseDetailList = [];
                            this.addDefaultMaterial();
                            this.requestProjectOfferRoom.OfferId = this.OfferTypeDetail.offerId;
                            this.getProjectOfferRoomList(this.requestProjectOfferRoom, null);
                            this.isDisplayRoomDetail = false;
                            setTimeout(() => {
                                if (!this.OfferTypeDetail.id) {
                                    this.showDirection();
                                }
                            }, 250);
                        }
                    }, 250);
                    if (this.selectedCategoryInput.id) {
                        setTimeout(() => {
                            this.requestProjectOfferRoom.OfferId = this.OfferTypeDetail.offerId;
                            this.getProjectOfferRoomList(this.requestProjectOfferRoom, true);
                        }, 250);
                    }
                }
                else {
                    this.refreshMaterialPrice();
                }
                this.showSpinner = false;

            },
            error: err => {
                this.showSpinner = false;
            },
            complete: () => {
                this.showSpinner = false;
            }
        });

    }

    editOffer(id, roomId) {
        this.showSpinner = true;
        this.selectedRoomOnEdit = new OfferRoomPriceDTO();
        setTimeout(() => {
            this.OfferTypeDetail.offerRoomDetailList.filter(off => {
                if (roomId == off.offerRoomId) {
                    off.isExpand = true;
                    if (off.offerRoomCalculation.length > 0) {
                        this.selectedRoomOnEdit.wallWidth = off.offerRoomCalculation[0].width;
                        this.selectedRoomOnEdit.wallLength = off.offerRoomCalculation[0].length;
                        this.selectedRoomOnEdit.ceilingWidth = off.offerRoomCalculation[0].width;
                        this.selectedRoomOnEdit.ceilingLength = off.offerRoomCalculation[0].length;
                    }
                }
                else
                    off.isExpand = false;
            });
            this.checkUncheckPanlesDoorframeRadiotorItem();
            this.showSpinner = false;
        }, 1000);
    }
    AddMaterialForAll() {
        const dialogRef = this.dialog.open(OfferV2FormMaterialSelectForAllComponent, {
            height: '95%',
            width: '90%',
            data: {
                materialList: this.MaterialObject,
                offerTypeDetail: this.OfferTypeDetail,
                projectOffer: this.projectOffer,
                projectPriceDiscount: this.projectPriceDiscount,
                customerDetailObj: this.customerDetailObj,
                roomList: this.roomList
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != null) {
                this.OfferTypeDetail = result;
                this.calculateRoomTotal();
                this.calculateDiversePrise();
            }
        });
    }
    calculateDiscountedAmt() {
        if (this.OfferTypeDetail.travelingAmount > 0) {
            this.OfferTypeDetail.price = ((this.OfferTypeDetail.priceTotal + this.OfferTypeDetail.travelingAmount) - this.OfferTypeDetail.discount);
        } else {
            this.OfferTypeDetail.price = this.OfferTypeDetail.priceTotal - this.OfferTypeDetail.discount;
        }
        this.calculateVatAmt();
    }
    calculateTravelingAmt() {
        this.OfferTypeDetail.price = this.OfferTypeDetail.priceTotal + this.OfferTypeDetail.travelingAmount;
        this.calculateVatAmt();
    }
    calculateVatAmt() {
        this.OfferTypeDetail.vat = ((this.OfferTypeDetail.price) * 25 / 100);
        this.OfferTypeDetail.totalInclVAT = (this.OfferTypeDetail.price + (this.OfferTypeDetail.price * 25 / 100));
        this.OfferTypeDetail.numberOfHours = (this.OfferTypeDetail.price / this.projectsCoworkerPrice);
        this.OfferTypeDetail.numberOfDays = Math.ceil(Math.ceil((this.OfferTypeDetail.numberOfHours / 7.5) / 0.25) * 0.25);
        if (this.OfferTypeDetail.travelingAmount) {
            this.calculateDistanceAmt();
        }
    }

    SavePDF(flag) {
        //this.showSpinner = true;
        this.projectofferService.GetSingleOfferPDF(this.OfferTypeDetail.offerId, this.OfferTypeDetail.id, flag, this.isIncludePriceOnPDF, this.isShowRoomTotal).subscribe((response: ResponseBase) => {
            var resultData = response.result;
            var data = response.result.base64.replace("data:application/pdf;base64,", "");
            this.OfferNumber = JSON.parse(localStorage.getItem("OfferNumber"));
            const dialogRef = this.dialog.open(PdfViewerModelComponent, {
                height: '90%',
                width: '90%',
                data: {
                    base64: data,
                    filename: response.result.filename ? response.result.filename + ".pdf" : "Tilbud_" + this.OfferNumber
                }
            });

            dialogRef.afterClosed().subscribe(result => {
                if (result) {

                }
            });

        },
            error => (err) => {
                //this.showSpinner = false;
            },
            () => {
            });
    }
    // Common END

    /**
     *  OTHERS START
     */

    // Code to Prevent accordion from expantion
    expandPanel(matExpansionPanel: MatExpansionPanel, event: Event): void {
        event.stopPropagation(); // Preventing event bubbling

        if (!this._isExpansionIndicator(event.target)) {
            matExpansionPanel.close(); // Here's the magic
        }
    }

    private _isExpansionIndicator(target: EventTarget): boolean {
        const expansionIndicatorClass = 'mat-expansion-indicator';
        return ((<HTMLElement>target).classList && (<HTMLElement>target).classList.contains(expansionIndicatorClass));
    }

    // methode to expand current editing acordion
    OpenAccordion(objEditingOff) {
        this.OfferTypeDetail.offerRoomDetailList.filter(off => {
            if (off.offerRoomId == objEditingOff.offerRoomId)
                off.isExpand = true;
            else
                off.isExpand = false;
        });
    }

    // methode to collaps current editing acordion
    CloseAccordion(objEditingReq) {
        this.OfferTypeDetail.offerRoomDetailList.filter(off => {
            if (off.offerRoomId == objEditingReq.offerRoomId)
                off.isExpand = false;
        });
    }
    //END

    gotoMaterial(id) {
        this.matObj = this.MaterialObject.filter(x => x.id == id);
        if (this.matObj && this.matObj.length > 0) {
            this.matObj[0].materialID;
            const dialogRef = this.dialog.open(NewMaterialComponent, {
                height: '800px',
                width: '80%',
                data: this.matObj[0].materialID,
            });

            dialogRef.afterClosed().subscribe(result => {
                this.titleService.SetTitle("TITLE_PROJECTS_OFFER");

            });
        }
    }

    GetMaterialSearchClear(value, i) {
        if (!value) {
            this.materialTypeDropdownSearch(i);
        }
    }

    materialTypeDropdownSearch(i) {
        if (this.OfferTypeDetail.offerDiverseDetailList[i].type)
            this.OfferTypeDetail.offerDiverseDetailList[i].materialList = this.MasterMaterialTypeList.filter(o => { return o.type.split(',').find(p => p == this.OfferTypeDetail.offerDiverseDetailList[i].type && o.text.toLowerCase().includes(this.MaterialTypeSearchForDiverse.toLowerCase())) });
        else
            this.OfferTypeDetail.offerDiverseDetailList[i].materialList = this.MasterMaterialTypeList.filter(o => o.text.toLowerCase().includes(this.MaterialTypeSearchForDiverse.toLowerCase()));
    }

    getDiverseMaterial(type, i) {
        if (type == 0) {
            this.OfferTypeDetail.offerDiverseDetailList[i].materialList = this.MasterMaterialTypeList;
        } else {
            this.OfferTypeDetail.offerDiverseDetailList[i].materialList = this.MasterMaterialTypeList.filter(o => { return o.type.split(',').find(p => p == type) });
        }
        if (this.OfferTypeDetail.offerDiverseDetailList[i].materialList.length > 0) {
            this.OfferTypeDetail.offerDiverseDetailList[i].materialID = this.OfferTypeDetail.offerDiverseDetailList[i].materialList[0].id;
            this.onChangeMaterialSelection(this.OfferTypeDetail.offerDiverseDetailList[i].materialID, this.OfferTypeDetail.offerDiverseDetailList[i]);
        }
        else {
            this.OfferTypeDetail.offerDiverseDetailList[i].materialID = "";
        }
        this.updateDiverseQty(this.OfferTypeDetail.offerDiverseDetailList[i]);
    }

    getDiverseMaterialOnEdit() {
        for (let i = 0; i < this.OfferTypeDetail.offerDiverseDetailList.length; i++) {
            if (this.OfferTypeDetail.offerDiverseDetailList[i].type) {
                this.OfferTypeDetail.offerDiverseDetailList[i].materialList = this.MasterMaterialTypeList.filter(o => { return o.type.split(',').find(p => p == this.OfferTypeDetail.offerDiverseDetailList[i].type) });
            } else {
                this.OfferTypeDetail.offerDiverseDetailList[i].materialList = this.MasterMaterialTypeList;
            }
        }
    }

    addNewItem() {
        var offerPrice = new DiverseDTO();
        offerPrice.offerCategoryId = this.OfferTypeDetail.offerCategoryId;
        offerPrice.offerId = this.OfferTypeDetail.offerId;
        offerPrice.qty = 1;
        offerPrice.imagePath = "Upload/NoImageAvailable.png";
        offerPrice.colorCode = "";
        offerPrice.materialList = this.MasterMaterialTypeList.map(x => Object.assign({}, x));
        if (!this.OfferTypeDetail.offerDiverseDetailList) {
            this.OfferTypeDetail.offerDiverseDetailList = [];
        }
        this.OfferTypeDetail.offerDiverseDetailList.push(offerPrice);
    }

    deleteOfferItem(index, item) {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: "200px",
            data: { Header: 'Headers.DeleteDiverseMaterial' }
        })

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                if (item.id) {
                    var total = (this.OfferTypeDetail.priceTotal - item.priceTotal);
                    this.projectofferService.deleteDiverseOfferItem(item.id, item.offerTypeID, total)
                        .subscribe((response: ResponseBase) => {
                            this.OfferTypeDetail.offerDiverseDetailList.splice(index, 1);
                            this.getOfferTypeData();
                        },
                            error => (err) => {
                                //this.showSpinner = false;
                            },
                            () => {
                            });
                }
                else {
                    this.OfferTypeDetail.offerDiverseDetailList.splice(index, 1);
                    this.checkUncheckPanlesDoorframeRadiotorItem();
                }
                this.calculateDiversePrise();
            }
        });

    }

    calculateDiversePrise() {
        this.grandTotal = 0;
        this.OfferTypeDetail.priceTotal = 0;
        this.OfferTypeDetail.drivingAmountTotal = 0;
        this.paintTimer = 0;
        var totalByRoom = 0
        if (!this.OfferTypeDetail.offerRoomDetailList) {
            this.OfferTypeDetail.offerRoomDetailList = [];
        }
        for (var i = 0; i < this.OfferTypeDetail.offerRoomDetailList.length; i++) {
            totalByRoom += this.OfferTypeDetail.offerRoomDetailList[i].offerRoomCalculation.filter(x => x.isPaint == true).reduce((sum, current) => sum + current.totalPrice, 0)
            this.OfferTypeDetail.drivingAmountTotal += this.OfferTypeDetail.offerRoomDetailList[i].offerRoomCalculation.filter(x => x.isPaint == true).reduce((sum, current) => sum + current.drivingAmountTotal, 0)
            this.paintTimer += this.OfferTypeDetail.offerRoomDetailList[i].offerRoomCalculation.filter(x => x.isPaint == true).reduce((sum, current) => sum + current.paintTimerTotal, 0)
        }
        this.OfferTypeDetail.priceTotal = totalByRoom;
        for (var i = 0; i < this.OfferTypeDetail.offerDiverseDetailList.length; i++) {
            this.OfferTypeDetail.offerDiverseDetailList[i].priceTotal = this.OfferTypeDetail.offerDiverseDetailList[i].qty * this.OfferTypeDetail.offerDiverseDetailList[i].price;
            if (this.OfferTypeDetail.offerDiverseDetailList[i].priceTotal > 0) {
                this.grandTotal = this.grandTotal + this.OfferTypeDetail.offerDiverseDetailList[i].priceTotal;
            }
            else {
                this.OfferTypeDetail.offerDiverseDetailList[i].priceTotal = 0;
            }
            if(!this.OfferTypeDetail.offerDiverseDetailList[i].notCalculateToDriving)
                this.OfferTypeDetail.drivingAmountTotal += this.OfferTypeDetail.offerDiverseDetailList[i].priceTotal;
            this.getOfferItemTimer(this.OfferTypeDetail.offerDiverseDetailList[i]);
        }
        this.OfferTypeDetail.priceTotal = this.OfferTypeDetail.priceTotal + this.grandTotal;
        this.calculateDiscountedAmt();
    }

    copyOfferItem(index, item) {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: "200px",
            data: { Header: 'Headers.CopyMaterial' }
        })

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                if (item.type)
                    this.tempMaterialList = this.MasterMaterialTypeList.filter(x => x.type.split(',').find(p => p == item.type))
                else
                    this.tempMaterialList = this.MasterMaterialTypeList;
                var offerPrice = new DiverseDTO();
                offerPrice.offerCategoryId = this.OfferTypeDetail.offerCategoryId;
                offerPrice.offerId = this.OfferTypeDetail.offerId;
                offerPrice.text = item.text;
                offerPrice.roomId = item.roomId;
                offerPrice.type = item.type;
                offerPrice.notCalculateToDriving = item.notCalculateToDriving;
                offerPrice.materialID = item.materialID;
                offerPrice.materialPriceID = item.materialPriceID;
                offerPrice.price = item.price;
                offerPrice.colorCode = item.colorCode;
                offerPrice.unit = item.unit;
                offerPrice.qty = item.qty;
                offerPrice.materialList = this.tempMaterialList;
                this.OfferTypeDetail.offerDiverseDetailList.push(offerPrice);
                if (item.materialID)
                    this.getMaterialPrice(offerPrice.materialID, offerPrice, offerPrice.materialPriceID)
                this.calculateDiversePrise();
            }
        });
    }

    // getDefaultMaterialList() {
    //     this.projectofferService.getDefaultMaterialList().subscribe({
    //         next: (response: ResponseBase) => {
    //             this.DefaultMaterilaList = response.result.myResponse;
    //             setTimeout(() => {
    //                 if (this.selectedCategoryInput.id == "")
    //                     this.addDefaultMaterial();
    //             }, 500);
    //         },
    //         error: err => { },
    //         complete: () => { }
    //     });
    // }

    addDefaultMaterial() {
        if (this.offerCategoryMaterialList && this.offerCategoryMaterialList.length > 0) {
            this.offerCategoryMaterialList.forEach(item => {
                this.DefaultMaterilaList = [];
                var selectedMaterialObj: OfferMaterialWithPrice;
                //this.DefaultMaterilaList = this.MaterialObjectForDiverse.filter(x =>x.id == item.offerMaterialID);
                if (item.materialTypeID) {
                    this.DefaultMaterilaList.push(...this.MaterialObjectForDiverse.filter(x => x.type.split(',').find(p => p == item.materialTypeID.toString())));
                    selectedMaterialObj = this.DefaultMaterilaList.find(o => o.id == item.offerMaterialID);
                }
                else if (item.offerMaterialID) {
                    this.DefaultMaterilaList = this.MaterialObjectForDiverse;
                    selectedMaterialObj = this.DefaultMaterilaList.find(o => o.id == item.offerMaterialID);
                }
                var tempMaterialData = this.selectedCategoryInput.offerCategoryMaterial.filter(o => o.id == item.id && o.materialQty > 0);
                if (tempMaterialData.length > 0 && item.offerMaterialID) {
                    tempMaterialData.filter(obj => {
                        var tempCategory = this.selectedCategoryInput.offerCategoriesJson.filter(x => x.id == obj.offerCategoryId)[0];
                        // var tempParentCategory = new OfferCategory();
                        // if(tempCategory && tempCategory.length > 0 && tempCategory[0].parentID)
                        //     tempParentCategory = this.selectedCategoryInput.offerCategoriesJson.filter(x => x.id == tempCategory[0].parentID)[0];
                        var isPaintRooms = obj.roomList.filter(r => r.isChecked);
                        if (obj.roomList.length > 0 && isPaintRooms.length > 0) {
                            for (let i = 0; i < obj.roomList.length; i++) {
                                if (obj.roomList[i].isChecked) {
                                    var offerPrice = new DiverseDTO();
                                    offerPrice.offerCategoryId = this.OfferTypeDetail.offerCategoryId;
                                    offerPrice.offerId = this.OfferTypeDetail.offerId;
                                    offerPrice.materialID = selectedMaterialObj.id;
                                    offerPrice.text = selectedMaterialObj.text;
                                    offerPrice.notCalculateToDriving = selectedMaterialObj.notCalculateToDriving;
                                    offerPrice.qty = obj && obj.materialQty > 0 ? obj.materialQty : 0;
                                    offerPrice.colorCode = obj.colorCode ? obj.colorCode : null;
                                    offerPrice.unit = selectedMaterialObj.unit;
                                    offerPrice.materialList = this.DefaultMaterilaList;
                                    offerPrice.type = item.materialTypeID;
                                    offerPrice.roomId = obj.roomList[i].id;
                                    offerPrice.materialPriceID = obj.materialPriceID;
                                    offerPrice.length = obj && obj.length ? obj.length : 0;
                                    offerPrice.width = obj && obj.width ? obj.width : 0;
                                    offerPrice.treatmentType = obj.treatmentType ? obj.treatmentType : selectedMaterialObj.treatmentType;
                                    offerPrice.extraM2QtyWall = obj.extraM2QtyWall;
                                    offerPrice.extraM2QtyCeiling = obj.extraM2QtyCeiling;
                                    offerPrice.extraM2QtyFloor = obj.extraM2QtyFloor;
                                    offerPrice.extraM2PriceWall = obj.extraM2PriceWall;
                                    offerPrice.extraM2PriceCeiling = obj.extraM2PriceCeiling;
                                    offerPrice.extraM2PriceFloor = obj.extraM2PriceFloor;
                                    offerPrice.offerCategory = tempCategory;
                                    this.OfferTypeDetail.offerDiverseDetailList.push(offerPrice);
                                }
                            }
                        } else {
                            var offerPrice = new DiverseDTO();
                            offerPrice.offerCategoryId = this.OfferTypeDetail.offerCategoryId;
                            offerPrice.offerId = this.OfferTypeDetail.offerId;
                            offerPrice.materialID = selectedMaterialObj.id;
                            offerPrice.text = selectedMaterialObj.text;
                            offerPrice.notCalculateToDriving = selectedMaterialObj.notCalculateToDriving;
                            offerPrice.qty = obj && obj.materialQty > 0 ? obj.materialQty : 0;
                            offerPrice.colorCode = obj.colorCode ? obj.colorCode : null;
                            offerPrice.unit = selectedMaterialObj.unit;
                            offerPrice.materialList = this.DefaultMaterilaList;
                            offerPrice.type = item.materialTypeID;
                            offerPrice.materialPriceID = obj.materialPriceID;
                            offerPrice.length = obj && obj.length ? obj.length : 0;
                            offerPrice.width = obj && obj.width ? obj.width : 0;
                            offerPrice.treatmentType = obj.treatmentType ? obj.treatmentType : selectedMaterialObj.treatmentType;
                            offerPrice.extraM2QtyWall = obj.extraM2QtyWall;
                            offerPrice.extraM2QtyCeiling = obj.extraM2QtyCeiling;
                            offerPrice.extraM2QtyFloor = obj.extraM2QtyFloor;
                            offerPrice.extraM2PriceWall = obj.extraM2PriceWall;
                            offerPrice.extraM2PriceCeiling = obj.extraM2PriceCeiling;
                            offerPrice.extraM2PriceFloor = obj.extraM2PriceFloor;
                            offerPrice.offerCategory = tempCategory;
                            this.OfferTypeDetail.offerDiverseDetailList.push(offerPrice);
                        }
                    })
                }
                else if(!item.offerMaterialID){
                    tempMaterialData.filter(obj => {
                        var offerPrice = new DiverseDTO();
                        offerPrice.offerCategoryId = this.OfferTypeDetail.offerCategoryId;
                        offerPrice.offerId = this.OfferTypeDetail.offerId;
                        offerPrice.materialID = null;
                        offerPrice.text = obj.offerMaterialText ? obj.offerMaterialText : "";
                        offerPrice.price = obj.price ? obj.price :0;
                        offerPrice.qty = obj && obj.materialQty > 0 ? obj.materialQty : 0;
                        offerPrice.colorCode = obj.colorCode ? obj.colorCode : null;
                        offerPrice.unit = null;
                        offerPrice.materialList = null;
                        offerPrice.type = item.materialTypeID;
                        offerPrice.materialPriceID = null;
                        offerPrice.length = obj && obj.length > 0 ? obj.length : 0;
                        offerPrice.width = obj && obj.width > 0 ? obj.width : 0;
                        this.OfferTypeDetail.offerDiverseDetailList.push(offerPrice);
                    });
                }
            })
            if (this.OfferTypeDetail.offerDiverseDetailList.length > 0) {
                this.OfferTypeDetail.offerDiverseDetailList.forEach(item => {
                    var materialPriceID = item.materialPriceID ? item.materialPriceID : "";
                    if (item.materialID)
                        this.getMaterialPriceForDefaultMaterial(item.materialID, item, materialPriceID)
                        //this.getMaterialPrice(item.materialID, item, materialPriceID)
                })
            }
            this.calculateDiversePrise();
        }
        // else{
        //     var offerPrice = new DiverseDTO();
        //         offerPrice.offerCategoryId = this.OfferTypeDetail.offerCategoryId;
        //         offerPrice.offerId = this.OfferTypeDetail.offerId;
        //         offerPrice.materialID = null;
        //         offerPrice.text = "";
        //         offerPrice.qty = 1;
        //         offerPrice.colorCode = "";
        //         offerPrice.unit = "";
        //         offerPrice.type = this.offerCategoryTypeId;
        //         this.OfferTypeDetail.offerDiverseDetailList.push(offerPrice);
        // }
    }

    getMaterialPrice(materialId, offerItem, materialPriceId) {
        var materialprice = this.MaterialObjectForDiverse.find(o => o.id == materialId);
        if (materialprice) {
            offerItem.imagePath = materialprice.imagePath;
            offerItem.selectCategoryTimeForDiverse = [];
            offerItem.isUnitPrint = materialprice.addUnitOnPDF;
            offerItem.notCalculateToDriving = materialprice.notCalculateToDriving;
            if(materialprice.unit == "stk.")
                offerItem.isQuantityPrint=true;
            else
                offerItem.isQuantityPrint=false;
            for (var i = 0; i < materialprice.offerMaterialPriceList.length; i++) {
                var obj = this.SelectCategoryTime.find(o => o.id == materialprice.offerMaterialPriceList[i].type);
                if (obj) {
                    if (i == 0) {
                        offerItem.materialPriceID = materialprice.offerMaterialPriceList[i].id;
                        offerItem.price = materialprice.offerMaterialPriceList[i].price;
                        offerItem.imagePath;
                        offerItem.colorCode = offerItem.colorCode;
                    }
                    offerItem.selectCategoryTimeForDiverse.push({
                        id: obj.id,
                        key: obj.key,
                        extraValue: materialprice.offerMaterialPriceList[i].paintText,
                        priceId: materialprice.offerMaterialPriceList[i].id,
                        reminder: materialprice.offerMaterialPriceList[i].reminder,
                    });
                    if (materialprice.offerMaterialPriceList[i].id == materialPriceId) {
                        offerItem.materialPriceID = materialprice.offerMaterialPriceList[i].id;
                        offerItem.price = materialprice.offerMaterialPriceList[i].price;
                        offerItem.text = materialprice.text + ' ' + materialprice.offerMaterialPriceList[i].paintText;
                        var offerReminderText = materialprice.offerMaterialPriceList[i].reminder ? materialprice.offerMaterialPriceList[i].reminder : "";
                    }
                    else {
                        offerItem.text = materialPriceId ? offerItem.text : materialprice.text + ' ' + materialprice.offerMaterialPriceText;
                        offerItem.colorCode = offerItem.colorCode ? offerItem.colorCode : materialprice.colorCode;
                        offerItem.unit = materialprice.unit;
                        var offerReminderText = materialprice.offerMaterialPriceList[i].reminder ? materialprice.offerMaterialPriceList[i].reminder : "";
                    }

                    if (materialprice.offerMaterialPriceList[i].isDefault && materialPriceId == "") {
                        offerItem.materialPriceID = materialprice.offerMaterialPriceList[i].id;
                        offerItem.price = materialprice.offerMaterialPriceList[i].price;
                        var offerReminderText = materialprice.offerMaterialPriceList[i].reminder ? materialprice.offerMaterialPriceList[i].reminder : "";
                        //offerItem.text = materialprice.text + ' ' + materialprice.offerMaterialPriceList[i].paintText;
                        offerItem.text = materialprice.text + ' ' + materialprice.offerMaterialPriceList[i].paintText + " " + (materialprice.addNumberOfTimesOnPDF ? this.projectofferService.getPaintType(materialprice.offerMaterialPriceList[i].type) : "");
                        if (offerItem.materialList && offerItem.materialList.length > 0) {
                            offerItem.materialList.filter(m => {
                                if (m.id == materialId) {
                                    var tempPriceData = m.offerMaterialPriceList.filter(x => x.id == offerItem.materialPriceID);
                                    if (tempPriceData && tempPriceData.length > 0)
                                        m.offerMaterialPriceText = tempPriceData[0].paintText;
                                }
                            })
                        }
                    }
                    offerItem.originalPrice = offerItem.price;
                    if (offerReminderText && materialprice.reminder) {
                        offerItem.reminder = materialprice.reminder + " - " + offerReminderText;
                    } else if (offerReminderText && !materialprice.reminder) {
                        offerItem.reminder = offerReminderText;
                    } else {
                        offerItem.reminder = materialprice.reminder ? materialprice.reminder : "";
                    }
                    if (!offerItem.id && materialprice.isPostNumberDiscount && this.projectOffer && this.projectOffer.percentage != 0 && !this.customerDetailObj.doNotCalculatePriceForPostNumber)
                        offerItem.price = offerItem.price + ((offerItem.price * this.projectOffer.percentage) / 100);
                    if (!offerItem.id && this.projectPriceDiscount && (this.customerDetailObj && !this.customerDetailObj.doNotCalculatePriceForPriceDiscount))
                        offerItem.price = offerItem.price + (offerItem.price * this.projectPriceDiscount / 100);
                    this.getOfferDiverseMeterPrice(offerItem);
                }
            }
            this.checkUncheckPanlesDoorframeRadiotorItem();
            this.setOfferTextForNumberOfTimesOnPDF(materialId, offerItem);
        }
        this.calculateDiversePrise();
    }

    getMaterialPriceOnCategoryTimeSelect(priceId, materialId, offerItem) {
        this.getMaterialPrice(materialId, offerItem, priceId);
        if (priceId && offerItem && offerItem.materialList) {
            offerItem.materialList.filter(m => {
                if (m.id == materialId) {
                    var tempPriceData = m.offerMaterialPriceList.filter(x => x.id == priceId);
                    if (tempPriceData && tempPriceData.length > 0)
                        m.offerMaterialPriceText = tempPriceData[0].paintText;
                }
            })
        }
    }

    getOfferDiverseMeterPrice(offerItem) {
        var selectedMaterial = this.MaterialObject.find(o => o.id == offerItem.materialID);
        if (selectedMaterial) {
            var selectedPrice = selectedMaterial.offerMaterialPriceList.find(o => o.id == offerItem.materialPriceID);
            if (selectedPrice) {
                var price = selectedPrice.price;
                if (selectedPrice.isAddMeterRangePrice) {
                    if (offerItem.materialPriceID) {
                        if (offerItem.qty >= selectedPrice.startMeter1 && offerItem.qty <= selectedPrice.endMeter1) {
                            price = selectedPrice.priceMeter1;
                        }
                        else if (offerItem.qty >= selectedPrice.startMeter2 && offerItem.qty <= selectedPrice.endMeter2) {
                            price = selectedPrice.priceMeter2;
                        }
                        else if (offerItem.qty >= selectedPrice.startMeter3 && offerItem.qty <= selectedPrice.endMeter3) {
                            price = selectedPrice.priceMeter3;
                        }
                        else {
                            price = 0; //selectedPrice.price;
                        }
                    }
                }
                offerItem.reminder = selectedPrice.reminder ? selectedPrice.reminder : "";
                offerItem.price = price;
                offerItem.originalPrice = offerItem.price;
                if (!offerItem.id && selectedMaterial.isPostNumberDiscount && this.projectOffer && this.projectOffer.percentage != 0 && !this.customerDetailObj.doNotCalculatePriceForPostNumber)
                    offerItem.price = offerItem.price + ((offerItem.price * this.projectOffer.percentage) / 100);
                if (!offerItem.id && this.projectPriceDiscount && (this.customerDetailObj && !this.customerDetailObj.doNotCalculatePriceForPriceDiscount))
                    offerItem.price = offerItem.price + (offerItem.price * this.projectPriceDiscount / 100);
                this.calculateDiversePrise();
            }
        }
    }

    addPanlesDoorframeRadiotor(ischecked, value, roomId) {
        if (value == "panels") {
            var item = this.MasterMaterialTypeList.filter(o => { return o.type.split(',').find(p => p == this.offMaterialTypeIds.Paneler) });
            if (item.length > 0) {
                if (ischecked)
                    this.addPanlesDoorframeRadiotorItem(item, roomId, this.offMaterialTypeIds.Paneler);
            }
        }
        if (value == "doorFrame") {
            var item = this.MasterMaterialTypeList.filter(o => { return o.type.split(',').find(p => p == this.offMaterialTypeIds.Dorkarm) });
            if (item.length > 0) {
                if (ischecked)
                    this.addPanlesDoorframeRadiotorItem(item, roomId, this.offMaterialTypeIds.Dorkarm);
            }
        }
        if (value == "radiator") {
            var item = this.MasterMaterialTypeList.filter(o => { return o.type.split(',').find(p => p == this.offMaterialTypeIds.Radiator) });
            if (item.length > 0) {
                if (ischecked)
                    this.addPanlesDoorframeRadiotorItem(item, roomId, this.offMaterialTypeIds.Radiator);
            }
        }
        if (value == "dore") {
            var item = this.MasterMaterialTypeList.filter(o => { return o.type.split(',').find(p => p == this.offMaterialTypeIds.Dore) });
            if (item.length > 0) {
                if (ischecked)
                    this.addPanlesDoorframeRadiotorItem(item, roomId, this.offMaterialTypeIds.Dore);
            }
        }
    }

    addPanlesDoorframeRadiotorItem(offerMaterialList, roomId, type) {
        this.tempMaterialDetails = offerMaterialList[0];
        var roomDetail = this.roomList.filter(x => x.id == roomId)
        if (!this.OfferTypeDetail.offerDiverseDetailList) {
            this.OfferTypeDetail.offerDiverseDetailList = [];
        }
        var offerItem = new DiverseDTO();
        offerItem.offerCategoryId = this.OfferTypeDetail.offerCategoryId;
        offerItem.offerId = this.OfferTypeDetail.offerId;
        offerItem.roomId = roomId;
        offerItem.text = this.tempMaterialDetails.text;
        offerItem.type = type;
        offerItem.materialID = this.tempMaterialDetails.id;
        offerItem.materialPriceID = '';
        offerItem.colorCode = this.tempMaterialDetails.colorCode;
        offerItem.unit = this.tempMaterialDetails.unit;
        offerItem.treatmentType = this.tempMaterialDetails.treatmentType;
        var tempQty = 1;
        if (this.tempMaterialDetails) {
            if (this.tempMaterialDetails.treatmentType == this.treatmentTypeIds.WoodWork)
                tempQty = (roomDetail[0].width + roomDetail[0].length + roomDetail[0].width + roomDetail[0].length + roomDetail[0].extraM2QtyFloor);
            if (this.tempMaterialDetails.treatmentType == this.treatmentTypeIds.Walls)
                tempQty = (((roomDetail[0].width + roomDetail[0].length) * 2) * roomDetail[0].ceilingHeight + roomDetail[0].extraM2QtyWall);
            if (this.tempMaterialDetails.treatmentType == this.treatmentTypeIds.Ceiling)
                tempQty = (roomDetail[0].width * roomDetail[0].length + roomDetail[0].extraQty1 + roomDetail[0].extraM2QtyCeiling);
            offerItem.width = roomDetail[0].width;
            offerItem.length = roomDetail[0].length;
        }
        offerItem.qty = tempQty; //(type == this.offMaterialTypeIds.Paneler ? (this.selectedRoomOnEdit.wallWidth + this.selectedRoomOnEdit.wallLength + this.selectedRoomOnEdit.ceilingWidth + this.selectedRoomOnEdit.ceilingLength) : 1);
        offerItem.materialList = offerMaterialList;
        this.OfferTypeDetail.offerDiverseDetailList.push(offerItem);
        if (offerItem.materialID)
            this.getMaterialPrice(offerItem.materialID, offerItem, offerItem.materialPriceID)
        this.calculateDiversePrise();
    }

    checkUncheckPanlesDoorframeRadiotorItem() {
        if (this.OfferTypeDetail.offerRoomDetailList && this.OfferTypeDetail.offerRoomDetailList.length > 0) {
            this.OfferTypeDetail.offerRoomDetailList.filter(room => {
                var data = this.OfferTypeDetail.offerDiverseDetailList.filter(x => x.roomId == room.offerRoomId);
                if (data.length > 0) {
                    var tempPaneler = data.filter(x => x.type == this.offMaterialTypeIds.Paneler);
                    var tempDoorFrame = data.filter(x => x.type == this.offMaterialTypeIds.Dorkarm);
                    var tempRadiator = data.filter(x => x.type == this.offMaterialTypeIds.Radiator);
                    var tempDore = data.filter(x => x.type == this.offMaterialTypeIds.Dore);
                    if (tempPaneler.length > 0)
                        room.isPaintPanels = true;
                    else
                        room.isPaintPanels = false;
                    if (tempDoorFrame.length > 0)
                        room.isPaintDoorFrame = true;
                    else
                        room.isPaintDoorFrame = false;
                    if (tempRadiator.length > 0)
                        room.isPaintRadiator = true;
                    else
                        room.isPaintRadiator = false;
                    if (tempDore.length > 0)
                        room.isPaintDore = true;
                    else
                        room.isPaintDore = false;
                }
                else {
                    room.isPaintPanels = false;
                    room.isPaintDoorFrame = false;
                    room.isPaintRadiator = false;
                    room.isPaintDore = false;
                }
            });
        }
    }

    autoSavePDF(value) {
        this.saveAndUpdateOffer(value)
    }

    openOfferProjectDescription() {
        var selectedTabData = new CompanyInfoSetting();
        selectedTabData.Description = this.OfferTypeDetail.offerText;
        const dialogRef = this.dialog.open(TextEditorComponent, {
            height: '90%',
            width: '90%',
            data: { selectedTabData, isEmail: false },
            disableClose: true
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result && result.Description) {
                this.OfferTypeDetail.offerText = result.Description;
                this.save(false);
            }
            else
                this.OfferTypeDetail.offerText = null;
        });
    }

    updateDiverseQty(offerItem) {
        if (offerItem.roomId && this.OfferTypeDetail.offerRoomDetailList.length > 0) {
            var roomDetail = this.roomList.filter(x => x.id == offerItem.roomId)
            if (roomDetail.length > 0) {
                if (offerItem.treatmentType > 0) {
                    var tempQty = 1;
                    if (offerItem.treatmentType == this.treatmentTypeIds.WoodWork)
                        tempQty = (roomDetail[0].width + roomDetail[0].length + roomDetail[0].width + roomDetail[0].length + (offerItem.extraM2QtyFloor ? offerItem.extraM2QtyFloor : roomDetail[0].extraM2QtyFloor ? roomDetail[0].extraM2QtyFloor : 0));
                    if (offerItem.treatmentType == this.treatmentTypeIds.Walls)
                        tempQty = (((roomDetail[0].width + roomDetail[0].length) * 2) * roomDetail[0].ceilingHeight + (offerItem.extraM2QtyWall ? offerItem.extraM2QtyWall : roomDetail[0].extraM2QtyWall ? roomDetail[0].extraM2QtyWall : 0));
                    if (offerItem.treatmentType == this.treatmentTypeIds.Ceiling)
                        tempQty = (roomDetail[0].width * roomDetail[0].length + (offerItem.extraM2QtyCeiling ? offerItem.extraM2QtyCeiling : roomDetail[0].extraM2QtyCeiling ? roomDetail[0].extraM2QtyCeiling : 0));
                    offerItem.qty = tempQty;
                }
                else
                    offerItem.qty = 1;
                this.getMaterialPrice(offerItem.materialID, offerItem, offerItem.materialPriceID);
            }
            else
                offerItem.qty = 1;
        }
    }

    updateRoomWidthAndLength(roomDetail) {
        if (this.OfferTypeDetail.offerDiverseDetailList.length > 0) {
            var tempPanels = this.OfferTypeDetail.offerDiverseDetailList.filter(x => x.roomId == roomDetail.offerRoomId && x.type == this.offMaterialTypeIds.Paneler);
            if (tempPanels.length > 0) {
                tempPanels.filter(item => {
                    item.qty = (roomDetail.wallWidth + roomDetail.wallLength + roomDetail.ceilingWidth + roomDetail.ceilingLength)
                    this.getMaterialPrice(item.materialID, item, item.materialPriceID);
                });
            }
        }
    }
    onChangeMaterialSelection(id, offer) {
        offer.colorCode = null;
        var materialprice = this.MaterialObjectForDiverse.find(o => o.id == id);
        if(materialprice)
            offer.treatmentType = materialprice.treatmentType;
        this.getMaterialPrice(id, offer, '');
    }

    _handleKeydown(event: KeyboardEvent) {
        if (event.keyCode === 13) {
            event.stopPropagation();
        }
    }

    getSumOfM2ForWallceilingFloor() {
        this.TotalM2ForWall = 0;
        this.TotalM2ForCeiling = 0;
        this.TotalM2ForFloor = 0;
        this.TotalForWall = 0;
        this.TotalForCeiling = 0;
        this.TotalForFloor = 0;
        this.TotalForAllRooms = 0;
        for (var i = 0; i < this.OfferTypeDetail.offerRoomDetailList.length; i++) {
            this.OfferTypeDetail.offerRoomDetailList[i].wallMaterialList = [];
            this.TotalForWall += this.OfferTypeDetail.offerRoomDetailList[i].offerRoomCalculation.filter(x => x.isPaint == true && x.typeId == this.offerRoomCalculationType.WallType).reduce((sum, current) => sum + current.totalPrice, 0);
            this.TotalM2ForWall += this.OfferTypeDetail.offerRoomDetailList[i].offerRoomCalculation.filter(x => x.isPaint == true && x.typeId == this.offerRoomCalculationType.WallType).reduce((sum, current) => sum + current.totalArea, 0);
            this.OfferTypeDetail.offerRoomDetailList[i].wallTotalArea = this.OfferTypeDetail.offerRoomDetailList[i].offerRoomCalculation.filter(x => x.isPaint == true && x.typeId == this.offerRoomCalculationType.WallType).reduce((sum, current) => sum + current.totalArea, 0);
            this.OfferTypeDetail.offerRoomDetailList[i].wallTotalPrice = this.OfferTypeDetail.offerRoomDetailList[i].offerRoomCalculation.filter(x => x.isPaint == true && x.typeId == this.offerRoomCalculationType.WallType).reduce((sum, current) => sum + current.totalPrice, 0);
            this.OfferTypeDetail.offerRoomDetailList[i].offerRoomCalculation.filter(x => {
                if (x.typeId == this.offerRoomCalculationType.WallType)
                    this.OfferTypeDetail.offerRoomDetailList[i].paintWall = x.isPaint
                if (x.typeId == this.offerRoomCalculationType.WallType)
                    this.OfferTypeDetail.offerRoomDetailList[i].wallMaterialList = x.materialList;
            });

            this.OfferTypeDetail.offerRoomDetailList[i].ceilingMaterialList = []
            this.TotalForCeiling += this.OfferTypeDetail.offerRoomDetailList[i].offerRoomCalculation.filter(x => x.isPaint == true && x.typeId == this.offerRoomCalculationType.CeilingType).reduce((sum, current) => sum + current.totalPrice, 0);
            this.TotalM2ForCeiling += this.OfferTypeDetail.offerRoomDetailList[i].offerRoomCalculation.filter(x => x.isPaint == true && x.typeId == this.offerRoomCalculationType.CeilingType).reduce((sum, current) => sum + current.totalArea, 0);
            this.OfferTypeDetail.offerRoomDetailList[i].ceilingTotalArea = this.OfferTypeDetail.offerRoomDetailList[i].offerRoomCalculation.filter(x => x.isPaint == true && x.typeId == this.offerRoomCalculationType.CeilingType).reduce((sum, current) => sum + current.totalArea, 0);
            this.OfferTypeDetail.offerRoomDetailList[i].ceilingTotalPrice = this.OfferTypeDetail.offerRoomDetailList[i].offerRoomCalculation.filter(x => x.isPaint == true && x.typeId == this.offerRoomCalculationType.CeilingType).reduce((sum, current) => sum + current.totalPrice, 0);
            this.OfferTypeDetail.offerRoomDetailList[i].offerRoomCalculation.filter(x => {
                if (x.typeId == this.offerRoomCalculationType.CeilingType)
                    this.OfferTypeDetail.offerRoomDetailList[i].paintCeiling = x.isPaint
                if (x.typeId == this.offerRoomCalculationType.CeilingType)
                    this.OfferTypeDetail.offerRoomDetailList[i].ceilingMaterialList = x.materialList;
            });

            this.OfferTypeDetail.offerRoomDetailList[i].floorMaterialList = [];
            this.TotalForFloor += this.OfferTypeDetail.offerRoomDetailList[i].offerRoomCalculation.filter(x => x.isPaint == true && x.typeId == this.offerRoomCalculationType.FloorType).reduce((sum, current) => sum + current.totalPrice, 0);
            this.TotalM2ForFloor += this.OfferTypeDetail.offerRoomDetailList[i].offerRoomCalculation.filter(x => x.isPaint == true && x.typeId == this.offerRoomCalculationType.FloorType).reduce((sum, current) => sum + current.totalArea, 0);
            this.OfferTypeDetail.offerRoomDetailList[i].floorTotalArea = this.OfferTypeDetail.offerRoomDetailList[i].offerRoomCalculation.filter(x => x.isPaint == true && x.typeId == this.offerRoomCalculationType.FloorType).reduce((sum, current) => sum + current.totalArea, 0);
            this.OfferTypeDetail.offerRoomDetailList[i].floorTotalPrice = this.OfferTypeDetail.offerRoomDetailList[i].offerRoomCalculation.filter(x => x.isPaint == true && x.typeId == this.offerRoomCalculationType.FloorType).reduce((sum, current) => sum + current.totalPrice, 0);
            this.OfferTypeDetail.offerRoomDetailList[i].offerRoomCalculation.filter(x => {
                if (x.typeId == this.offerRoomCalculationType.FloorType)
                    this.OfferTypeDetail.offerRoomDetailList[i].paintFloor = x.isPaint
                if (x.typeId == this.offerRoomCalculationType.FloorType)
                    this.OfferTypeDetail.offerRoomDetailList[i].floorMaterialList = x.materialList;
            });
            this.TotalForAllRooms = this.TotalForWall + this.TotalForCeiling + this.TotalForFloor
        }
    }

    refreshMaterialPrice() {
        this.OfferTypeDetail.offerDiverseDetailList.forEach(item => {
            if (item.materialID)
                this.getMaterialPrice(item.materialID, item, item.materialPriceID);
        })
    }

    refreshOfferMaterials() {
        this.getOfferMaterialData(false);
    }

    getMaterialPriceOnEdit(materialId, offerItem, materialPriceId) {
        var materialprice = this.MaterialObjectForDiverse.find(o => o.id == materialId);
        if (materialprice) {
            offerItem.imagePath = materialprice.imagePath;
            offerItem.selectCategoryTimeForDiverse = [];
            offerItem.notCalculateToDriving = materialprice.notCalculateToDriving;
            for (var i = 0; i < materialprice.offerMaterialPriceList.length; i++) {
                var obj = this.SelectCategoryTime.find(o => o.id == materialprice.offerMaterialPriceList[i].type);
                if (obj) {
                    var offerReminderText = "";
                    if (i == 0) {
                        offerItem.materialPriceID = materialprice.offerMaterialPriceList[i].id;
                        offerItem.price = offerItem.price;
                        offerItem.colorCode = offerItem.colorCode;
                    }
                    offerItem.selectCategoryTimeForDiverse.push({
                        id: obj.id,
                        key: obj.key,
                        priceId: materialprice.offerMaterialPriceList[i].id,
                        extraValue: materialprice.offerMaterialPriceList[i].paintText,
                        reminder: materialprice.offerMaterialPriceList[i].reminder,
                    });
                    if (materialprice.offerMaterialPriceList[i].id == materialPriceId) {
                        offerItem.materialPriceID = materialprice.offerMaterialPriceList[i].id;
                        offerItem.price = offerItem.price;
                        offerReminderText = materialprice.offerMaterialPriceList[i].reminder;
                        if (offerItem.materialList && offerItem.materialList.length > 0) {
                            offerItem.materialList.filter(m => {
                                if (m.id == materialId) {
                                    var tempPriceData = m.offerMaterialPriceList.filter(x => x.id == materialPriceId);
                                    if (tempPriceData && tempPriceData.length > 0)
                                        m.offerMaterialPriceText = tempPriceData[0].paintText;
                                }
                            })
                        }
                    }
                    else {
                        offerItem.text = materialPriceId ? offerItem.text : materialprice.text + ' ' + materialprice.offerMaterialPriceText;
                        // offerItem.colorCode = offerItem.colorCode ? offerItem.colorCode : materialprice.colorCode;
                        offerItem.colorCode = offerItem.colorCode ? offerItem.colorCode : "";
                        offerItem.unit = materialprice.unit;
                        offerReminderText = materialprice.offerMaterialPriceList[i].reminder;
                    }

                    if (materialprice.offerMaterialPriceList[i].isDefault && materialPriceId == "") {
                        offerItem.materialPriceID = materialprice.offerMaterialPriceList[i].id;
                        offerItem.price = offerItem.price;
                        offerReminderText = materialprice.offerMaterialPriceList[i].reminder;
                        offerItem.text = materialprice.text + ' ' + materialprice.offerMaterialPriceList[i].paintText;
                        if (offerItem.materialList && offerItem.materialList.length > 0) {
                            offerItem.materialList.filter(m => {
                                if (m.id == materialId) {
                                    var tempPriceData = m.offerMaterialPriceList.filter(x => x.id == offerItem.materialPriceID);
                                    if (tempPriceData && tempPriceData.length > 0)
                                        m.offerMaterialPriceText = tempPriceData[0].paintText;
                                }
                            })
                        }
                    }
                    if (offerReminderText && materialprice.reminder) {
                        offerItem.reminder = materialprice.reminder + " - " + offerReminderText;
                    } else if (offerReminderText && !materialprice.reminder) {
                        offerItem.reminder = offerReminderText;
                    } else {
                        offerItem.reminder = materialprice.reminder ? materialprice.reminder : "";
                    }
                }
                if (i == materialprice.offerMaterialPriceList.length - 1)
                    offerItem.reminder = materialprice.offerMaterialPriceList.filter(x => x.id == materialPriceId).map(y => y.reminder);
            }
            this.checkUncheckPanlesDoorframeRadiotorItem();
        }
        this.calculateDiversePrise();
    }
    onCheckIsIncludePriceOnPDF(flag) {
        this.isIncludePriceOnPDF = flag;
    }

    GetOffer() {
        this.projectofferService.GetSingle<ResponseBase>(this.OfferTypeDetail.offerId)
            .subscribe((response: ResponseBase) => {
                if (response != null && response.result) {
                    this.projectOffer = response.result;
                    if (!this.OfferTypeDetail.id) {
                        if (this.projectOffer && this.projectOffer.percentage != 0) {
                            this.OfferTypeDetail.discountByPostNumber = this.projectOffer.percentage
                            this.OfferTypeDetail.isDiscountByPostNumber = true;
                        }
                        this.projectPriceDiscount = this.projectOffer.indoorOutdoorStatusIDPercentage;
                        this.OfferTypeDetail.discountByProjectAmount = this.projectPriceDiscount
                        this.OfferTypeDetail.isDiscountByProjectAmount = true;
                    }
                    if (this.customerDetailObject) {
                        this.customerDetailObj = this.customerDetailObject;
                    } else {
                        this.getCustomerById(this.projectOffer.customerId);
                    }
                }
            },
                error => (err) => {
                });
    }

    calculateRoomTotal() {
        this.OfferTypeDetail.priceTotal = 0;
        this.OfferTypeDetail.drivingAmountTotal = 0;
        this.paintTimer = 0
        for (var i = 0; i < this.OfferTypeDetail.offerRoomDetailList.length; i++) {
            this.OfferTypeDetail.offerRoomDetailList[i].totalPrice = this.OfferTypeDetail.offerRoomDetailList[i].offerRoomCalculation.filter(x => x.isPaint == true).reduce((sum, current) => sum + current.totalPrice, 0);
            this.OfferTypeDetail.priceTotal += this.OfferTypeDetail.offerRoomDetailList[i].totalPrice;
            this.OfferTypeDetail.drivingAmountTotal += this.OfferTypeDetail.offerRoomDetailList[i].offerRoomCalculation.filter(x => x.isPaint == true).reduce((sum, current) => sum + current.drivingAmountTotal, 0);
            if (this.gulvCheckedCount == 0) {
                this.gulvCheckedCount = this.OfferTypeDetail.offerRoomDetailList[i].offerRoomCalculation.filter(x => x.typeId == this.offerRoomCalculationType.FloorType).length;
                this.OfferTypeDetail.offerRoomDetailList[i].isAddFloor = this.gulvCheckedCount > 0 ? true : false;
            }
            this.paintTimer += this.OfferTypeDetail.offerRoomDetailList[i].offerRoomCalculation.filter(x => x.isPaint == true).reduce((sum, current) => sum + current.paintTimerTotal, 0)
        }
        this.OfferTypeDetail.priceTotal = this.OfferTypeDetail.priceTotal + this.grandTotal;
        this.OfferTypeDetail.price = this.OfferTypeDetail.priceTotal - this.OfferTypeDetail.discount;
        for (var i = 0; i < this.OfferTypeDetail.offerDiverseDetailList.length; i++) {
            if(!this.OfferTypeDetail.offerDiverseDetailList[i].notCalculateToDriving){
                this.OfferTypeDetail.offerDiverseDetailList[i].priceTotal = this.OfferTypeDetail.offerDiverseDetailList[i].qty * this.OfferTypeDetail.offerDiverseDetailList[i].price;
                this.OfferTypeDetail.drivingAmountTotal += this.OfferTypeDetail.offerDiverseDetailList[i].priceTotal;
            }
            this.getOfferItemTimer(this.OfferTypeDetail.offerDiverseDetailList[i]);
        }
        if (this.OfferTypeDetail.discount > 0) {
            this.calculateDiscountedAmt();
        }
        if (this.OfferTypeDetail.travelingAmount > 0) {
            this.calculateTravelingAmt();
        }
        else {
            this.OfferTypeDetail.discount = 0;
            this.calculateVatAmt();
        }
        this.getSumOfM2ForWallceilingFloor();

    }
    reCalculateRoomTotal(roomDetail, type) {
        if (roomDetail && roomDetail.offerRoomCalculation) {
            roomDetail.offerRoomCalculation.filter(x => {
                if (x.typeId == type) {
                    if (type == this.offerRoomCalculationType.WallType)
                        x.isPaint = roomDetail.paintWall;
                    if (type == this.offerRoomCalculationType.CeilingType)
                        x.isPaint = roomDetail.paintCeiling;
                    if (type == this.offerRoomCalculationType.FloorType)
                        x.isPaint = roomDetail.paintFloor;

                    if (x.materialList.length > 0 && x.isPaint == true) {
                        x.totalPrice = (x.totalArea * (x.materialList.reduce((sum, current) => sum + current.price, 0)))
                            + x.extraPrice1 + x.extraPrice2;
                    }
                }
            })
        }
        this.calculateRoomTotal();
    }
    addFloor(roomDetail) {
        var offerRoomCalculationItem = new OfferRoomCalculationWithMaterialList();
        offerRoomCalculationItem.length = roomDetail.offerRoomCalculation[0].length;
        offerRoomCalculationItem.width = roomDetail.offerRoomCalculation[0].width;
        offerRoomCalculationItem.ceilingHeight = roomDetail.offerRoomCalculation[0].ceilingHeight;
        offerRoomCalculationItem.offerRoomDetailId = roomDetail.id;
        offerRoomCalculationItem.extraQty1 = roomDetail.offerRoomCalculation[0].extraQty1 ? roomDetail.offerRoomCalculation[0].extraQty1 : 0;
        offerRoomCalculationItem.extraQty2 = roomDetail.offerRoomCalculation[0].extraQty2 ? roomDetail.offerRoomCalculation[0].extraQty2 : 0;
        offerRoomCalculationItem.extraPrice1 = roomDetail.offerRoomCalculation[0].extraPrice1 ? roomDetail.offerRoomCalculation[0].extraPrice1 : 0;
        offerRoomCalculationItem.extraPrice2 = roomDetail.offerRoomCalculation[0].extraPrice2 ? roomDetail.offerRoomCalculation[0].extraPrice2 : 0;
        offerRoomCalculationItem.isActive = true;
        let tempOfferRoomCalculationItem = Object.assign({}, offerRoomCalculationItem);
        tempOfferRoomCalculationItem.materialList = [];
        this.tempAssignedMaterialList = [];
        var materialObj = new MaterialList();
        materialObj.offerMaterialTypeId = this.offMaterialTypeIds.FloorType;
        this.tempAssignedMaterialList = this.MaterialObject.filter(o => { return o.type.split(',').find(p => p == materialObj.offerMaterialTypeId) });
        materialObj.materialList = this.tempAssignedMaterialList.map(x => Object.assign({}, x));//this.MaterialObject.filter(o => { return o.type.split(',').find(p => p == materialObj.offerMaterialTypeId)});
        tempOfferRoomCalculationItem.typeId = this.offerRoomCalculationType.FloorType;
        tempOfferRoomCalculationItem.materialList.push(materialObj);
        roomDetail.offerRoomCalculation.push(tempOfferRoomCalculationItem);
        this.calculateRoomTotal();
    }

    clearOfferItem(offerItem) {
        offerItem.text = "";
        offerItem.imagePath = "";
        offerItem.unit = "";
        offerItem.colorCode = "";
        offerItem.materialPriceID = "";
        offerItem.price = 0;
        offerItem.priceTotal = 0;
        offerItem.treatmentType = 0;
        this.calculateDiversePrise();
    }

    OpenTextareaEditerPopup(text, index, materialID) {
        if (!materialID || !text)
            return
        const dialogRef = this.dialog.open(TextareaEditorComponent, {
            height: '400px',
            width: '500px',
            data: { 'displayData': text ? text : "" }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result.isSave) {
                if (this.OfferTypeDetail.offerDiverseDetailList.length > 0) {
                    this.OfferTypeDetail.offerDiverseDetailList[index].text = result.value;
                }
            }
        });
    }

    toggleRoomDetail() {
        this.isDisplayRoomDetail = !this.isDisplayRoomDetail;
    }

    getOfferCategoryMaterials(ids) {
        this.projectofferService.GetOfferCategoryMaterialList(ids).subscribe({
            next: (response: ResponseBase) => {
                this.offerCategoryMaterialList = response.result;
            },
            error: err => { },
            complete: () => { }
        });
    }


    getCustomerById(customerId) {
        this.customersService.GetSingle<ResponseBase>(customerId).subscribe({
            next: (response: ResponseBase) => {
                this.customerDetailObj = response.result;
            },
            error: err => { },
        });
    }

    GetCompanyInformation() {
        this.companyInfoService.GetCompanyInformationData<ResponseBase>()
            .subscribe((response: ResponseBase) => {
                if (response) {
                    this.companyInfoData = response.result;
                }
            },
                (err: any) => {
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        }, error: err => { }
                    });
                },
                () => {
                });
    }

    showDirection() {
        //open popup
        const dialogRef = this.dialog.open(GoogleMapDirectionComponent, {
            width: '80%',
            height: '80%',
            disableClose: true,
            data: {
                Address: this.projectOffer.address,
                ZipCode: this.projectOffer.postNumber,
                City: this.projectOffer.city,
                SourceAdd: this.companyInfoData,
                autoSearchOnOpen: true,
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.OfferTypeDetail.distance = result.travelingDetails.distance;
                this.OfferTypeDetail.time = result.travelingDetails.duration;
                if(result.travelingDetails.distance.toString().split(" ")[1])
                    this.meterAndKmText = result.travelingDetails.distance.toString().split(" ")[1];
                this.calculateDistanceAmt();
                setTimeout(() => {
                    this.OfferTypeDetail.numberOfDrivingDays = this.OfferTypeDetail.numberOfDays;
                }, 500);
            }
        });
    }

    getTravelingDistancePerDayValue() {
        this.projectofferService.GetDistancePerMinuteValue().subscribe((response: ResponseBase) => {
            if (response) {
                this.DistancePerDayValue = response.result.myResponse[0].value;
            }
        });
    }

    // getDaysDistanceTime(value) {
    //     var daysAndTime = 0;
    //     value = Number(value);
    //     daysAndTime = value * 2
    //     return daysAndTime;
    // }

    calculateTotalTime(days) {
        this.OfferTypeDetail.TotalTime = 0;
        if (this.OfferTypeDetail.time) {
            var time = this.OfferTypeDetail.time;
            this.OfferTypeDetail.TotalTime = ((time * 2) * Math.ceil(days ? days : this.OfferTypeDetail.numberOfDays));
        }
        return this.OfferTypeDetail.TotalTime;
    }

    calculateDistanceAmt() {
        var amount = 0;
        amount = this.calculateTotalTime(this.OfferTypeDetail.numberOfDays);
        amount = amount * this.DistancePerDayValue;
        this.OfferTypeDetail.travelingAmount = amount;
        this.OfferTypeDetail.price = ((this.OfferTypeDetail.priceTotal + this.OfferTypeDetail.travelingAmount) - this.OfferTypeDetail.discount);
        this.OfferTypeDetail.vat = ((this.OfferTypeDetail.price) * 25 / 100);
        this.OfferTypeDetail.totalInclVAT = (this.OfferTypeDetail.price + (this.OfferTypeDetail.price * 25 / 100));
        this.OfferTypeDetail.numberOfHours = (this.OfferTypeDetail.drivingAmountTotal / this.projectsCoworkerPrice);
        this.OfferTypeDetail.numberOfDays = Math.ceil(Math.ceil((this.OfferTypeDetail.numberOfHours / 7.5) / 0.25) * 0.25);
        this.calTravelingAmtOnDrivingDaysChange();
    }


    calTravelingAmtOnDrivingDaysChange() {
        var amount = 0;
        amount = this.calculateTotalTime(this.OfferTypeDetail.numberOfDrivingDays == this.OfferTypeDetail.numberOfDays ? this.OfferTypeDetail.numberOfDrivingDays : this.OfferTypeDetail.numberOfDays);
        amount = amount * this.DistancePerDayValue;
        this.OfferTypeDetail.travelingAmount = amount;
        this.OfferTypeDetail.price = ((this.OfferTypeDetail.priceTotal + this.OfferTypeDetail.travelingAmount) - this.OfferTypeDetail.discount);
        this.OfferTypeDetail.vat = ((this.OfferTypeDetail.price) * 25 / 100);
        this.OfferTypeDetail.totalInclVAT = (this.OfferTypeDetail.price + (this.OfferTypeDetail.price * 25 / 100));
        this.OfferTypeDetail.numberOfHours = (this.OfferTypeDetail.drivingAmountTotal / this.projectsCoworkerPrice);
        this.OfferTypeDetail.numberOfDays = Math.ceil(Math.ceil((this.OfferTypeDetail.numberOfHours / 7.5) / 0.25) * 0.25);
    }

    setOfferTextForNumberOfTimesOnPDF(materialId, offerItem) {
        var materialprice = this.MaterialObjectForDiverse.find(o => o.id == materialId);
        if (materialprice) {
            var offerMaterialDetail = materialprice.offerMaterialPriceList.find(x => x.id == offerItem.materialPriceID)
            if (materialprice.addNumberOfTimesOnPDF && offerMaterialDetail) {
                offerItem.text = materialprice.text + ' ' + offerMaterialDetail.paintText + ' ' + this.projectofferService.getPaintType(offerMaterialDetail.type);
            }
        }
    }

    CallNumberOfDrivingDaysPopup() {
        const dialogRef = this.dialog.open(CalculateDrivingDaysModalComponent, {
            width: '45%',
            height: '30%',
            disableClose: true,
            data: {
                numberOfDays: this.OfferTypeDetail.numberOfDays,
                numberOfDrivingDays: this.OfferTypeDetail.numberOfDrivingDays,
                autoSearchOnOpen: true,
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.OfferTypeDetail.numberOfDrivingDays = result.numberOfDayesDetails;
                this.OfferTypeDetail.numberOfDays = result.numberOfDays;
                this.calTravelingAmtOnDrivingDaysChange();
                setTimeout(() => {
                    this.saveAndUpdateOffer(null);
                }, 1000);
            }
        });
    }

    GetOfferMaterialFiles() {
        this.showSpinner = true;
        var documentObj = new OffersMaterialDocument();
        documentObj.offerID = this.OfferTypeDetail.offerId;
        documentObj.offerTreatmentID = [this.OfferTypeDetail.id];
        this.projectofferService.GetOfferMaterialFiles<ResponseBase>(documentObj)
            .subscribe((response: ResponseBase) => {
                if (response) {
                    this.offerMaterialFilesAndContent = response.result;
                    this.showSpinner = false;
                    this.SendEmail(response.result.materialFiles);
                }
            },
                error => (err) => {
                    this.showSpinner = false;
                    this.SendEmail(null);
                });
    }

    SendEmail(materialOfferFileList: OffersMaterialDocumentDetail[]) {
        this.showSpinner = true;
        var offerIdsList = [this.OfferTypeDetail.id].join().toString()
        var emailParam = new EmailSendingParamForOffer();
        emailParam.offerId = this.OfferTypeDetail.offerId;
        emailParam.to = this.projectOffer.email;
        emailParam.toName = "";
        // emailParam.from = "post@vibesmalerfirma.dk";
        emailParam.fromName = "Admin";
        emailParam.subject = "Hermed fremsendes tilbud.";
        emailParam.body = this.offerMaterialFilesAndContent && this.offerMaterialFilesAndContent.materialContent ? this.offerMaterialFilesAndContent.materialContent :"";
        emailParam.isIncludePriceOnPDF = this.isIncludePriceOnPDF;
        emailParam.offerTaskIds = offerIdsList;
        emailParam.offerMaterialFiles = materialOfferFileList;
        emailParam.isShowRoomTotal = this.isShowRoomTotal;
        const dialogRef = this.dialog.open(SendOfferEmailComponent, {
            height: '90%',
            width: '90%',
            data: emailParam
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.projectofferService.SendEmailInvoiceWithAttachmentToCustomer<ResponseBase>(result)
                    .subscribe((response: ResponseBase) => {
                        if (response) {
                            this.showSpinner = false;
                            this.translate.get("MESSAGES.Success").subscribe({
                                next: (res) => {
                                    this.notificationsService.success(res);
                                },
                                error: err => {
                                }
                            });
                            this.DisplayList.emit(this.selectedCategoryInput);
                            this.commonService. checkEmailIsToBeSent();
                        }
                    },
                        error => (err) => {
                            this.showSpinner = false;
                            this.translate.get("MESSAGES.Failed").subscribe({
                                next: (res) => {
                                    this.notificationsService.error(res);
                                },
                                error: err => {
                                }
                            });
                        });
            }
            else
                this.showSpinner = false;
        });
    }

    onChangeProjectPrice(isChecked: boolean) {
        var totalpercentage = (this.OfferTypeDetail.isDiscountByProjectAmount ? this.OfferTypeDetail.discountByProjectAmount : 0)
            + (this.OfferTypeDetail.isDiscountByPostNumber ? this.OfferTypeDetail.discountByPostNumber : 0)
            + 100;
        var pricePecentage = totalpercentage;
        if (isChecked)
            pricePecentage = pricePecentage - this.OfferTypeDetail.discountByProjectAmount;
        else
            pricePecentage = pricePecentage + this.OfferTypeDetail.discountByProjectAmount;
        this.reCalculatePrice(totalpercentage, pricePecentage);
    }
    onChangePostnumnerPrice(isChecked: boolean) {
        var totalpercentage = (this.OfferTypeDetail.isDiscountByProjectAmount ? this.OfferTypeDetail.discountByProjectAmount : 0)
            + (this.OfferTypeDetail.isDiscountByPostNumber ? this.OfferTypeDetail.discountByPostNumber : 0)
            + 100;
        var pricePecentage = totalpercentage;
        if (isChecked)
            pricePecentage = pricePecentage - this.OfferTypeDetail.discountByPostNumber;
        else
            pricePecentage = pricePecentage + this.OfferTypeDetail.discountByPostNumber;
        this.reCalculatePrice(totalpercentage, pricePecentage);
    }

    reCalculatePrice(totalpercentage, pricePecentage) {
        if (this.OfferTypeDetail.offerRoomDetailList.length > 0) {
            this.OfferTypeDetail.offerRoomDetailList.filter((room, i) => {
                room.offerRoomCalculation.filter(roomCalculation => {
                    roomCalculation.materialList.filter(material => {
                        material.price = material.originalPrice ? material.originalPrice : material.price;
                        material.originalPrice = material.originalPrice ? material.originalPrice : material.price;
                        var selectedMaterial = this.MaterialObject.find(o => o.id == material.offerMaterialId);
                        if (this.OfferTypeDetail.isDiscountByPostNumber && selectedMaterial && selectedMaterial.isPostNumberDiscount && !this.customerDetailObj.doNotCalculatePriceForPostNumber)
                            material.price = material.price + ((material.price * this.OfferTypeDetail.discountByPostNumber) / 100);
                        if (this.OfferTypeDetail.isDiscountByProjectAmount && (this.customerDetailObj && !this.customerDetailObj.doNotCalculatePriceForPriceDiscount))
                            material.price = material.price + (material.price * this.OfferTypeDetail.discountByProjectAmount / 100);
                    })
                    this.reCalculateRoomTotal(room, roomCalculation.typeId);
                })
            })
        }
        if (this.OfferTypeDetail.offerDiverseDetailList.length > 0) {
            this.OfferTypeDetail.offerDiverseDetailList.filter(item => {
                item.price = item.originalPrice ? item.originalPrice : item.price;
                item.originalPrice = item.originalPrice ? item.originalPrice : item.price;
                var selectedMaterial = this.MaterialObject.find(o => o.id == item.materialID);
                if (this.OfferTypeDetail.isDiscountByPostNumber && selectedMaterial.isPostNumberDiscount && !this.customerDetailObj.doNotCalculatePriceForPostNumber)
                    item.price = item.price + ((item.price * this.OfferTypeDetail.discountByPostNumber) / 100);
                if (this.OfferTypeDetail.isDiscountByProjectAmount && (this.customerDetailObj && !this.customerDetailObj.doNotCalculatePriceForPriceDiscount))
                    item.price = item.price + (item.price * this.OfferTypeDetail.discountByProjectAmount / 100);
            })
            this.calculateDiversePrise();
        }
        setTimeout(() => {
            this.save(false)
        });
    }

    //#region File upload
    storeFileFolderPathData() {
        var rootPath = this.dropboxFilesService.getSettingMasterValueDocumentPath();
        var paths = "";
        if (rootPath.DocumentDriveType == DriveModuleEnum.Onedrive) {
            paths = rootPath.Onedrive.Path;
        } else {
            paths = rootPath.Dropbox.Path;
        }
        this.fileFolderPathData = paths.split("/").map((path, i) => ({
            name: path,
            isDisplay: false
        }));
        this.fileFolderPathData = [
            { name: 'ProjectOffers', isDisplay: false },
            { name: this.projectOffer.number.toString(), isDisplay: true },
            { name: this.OfferTypeDetail.number.toString() + "_", isDisplay: true }
        ];
    }

    GetCurrentPath() {
        var rootPath = this.dropboxFilesService.getSettingMasterValueDocumentPath();
        var paths = "";
        if (rootPath.DocumentDriveType == DriveModuleEnum.Onedrive) {
            paths = rootPath.Onedrive.Path;
        } else {
            paths = rootPath.Dropbox.Path;
        }
        var FolderPathData = [];
        FolderPathData = paths.split("/").map((path, i) => ({
            name: path,
            isDisplay: false
        }));
        FolderPathData.push(
            { name: 'ProjectOffers', isDisplay: false },
            { name: this.projectOffer.number.toString(), isDisplay: true },
        );
        var path = "";
        for (var i = 0; i < FolderPathData.length; i++) {
            path += "/" + FolderPathData[i].name;
        }
        return path;
    }

    GetCurrentFolderPath(categoryName) {
        this.storeFileFolderPathData();
        var path = "";
        for (var i = 0; i < this.fileFolderPathData.length; i++) {
            path += "/" + this.fileFolderPathData[i].name;
        }
        path = path + categoryName;
        return path;
    }

    AddFiles() {
        this.show = !this.show;
        if (this.show) {
            this.searchValue = this.OfferTypeDetail.number.toString() + "_";
            this.isSearch = true;
            this.isDisplayOnlySearchFolder = true;
            var rootPath = this.dropboxFilesService.getSettingMasterValueDocumentPath();
            var paths = "";
            if (rootPath.DocumentDriveType == DriveModuleEnum.Onedrive) {
                paths = rootPath.Onedrive.Path;
            } else {
                paths = rootPath.Dropbox.Path;
            }
            this.fileFolderPathData = paths.split("/").map((path, i) => ({
                name: path,
                isDisplay: false
            }));
            this.fileFolderPathData = [
                { name: 'ProjectOffers', isDisplay: false },
                { name: this.projectOffer.number.toString(), isDisplay: true },
            ];
        }
    }

    displayTotalFileCount(count) {
        this.totalFileCount = count;
    }

    SearchFile() {
        this.showSpinner = true;
        this.searchFileAndFolder = new SearchFolderOrFiles();
        this.searchFileAndFolder.searchValue = this.OfferTypeDetail.number.toString() + "_";
        this.searchFileAndFolder.path = this.GetCurrentPath();
        this.dropboxFilesService.GetSearchFileOrFolder<ResponseBase>(this.searchFileAndFolder).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                // response.result.myResponse.filter(o => {
                //     o.isSearch = true;
                //     o.isFolder == true;
                // });
                this.totalFileCount = response.result.myResponse.filter(x => x.isFolder == true).length;
            },
            error: err => {
                this.showSpinner = false;
            }
        });
    }

    SetFileDetails() {
        if (this.selectedCategoryInput.fileList.length > 0) {
            for (let i = 0; i < this.selectedCategoryInput.fileList.length; i++) {
                const formData = new FormData();
                const files = this.selectedCategoryInput.fileList[i].files;
                for (var j = 0; j < files.length; j++) {
                    formData.append("file[]", files[j]);
                }
                formData.append("RootFolderPath", this.GetCurrentFolderPath(this.selectedCategoryInput.fileList[i].categoryName));
                formData.append("ModuleID", this.projectOffer.id);
                this.UploadFile(formData, i);
            }
        }
    }

    UploadFile(formData, index) {
        this.dropboxFilesService.UploadFiles(formData).subscribe({
            next: (response) => {
                if (this.selectedCategoryInput.fileList.length - 1 == index) {
                    this.SearchFile();
                    this.selectedCategoryInput.fileList = [];
                }
            },
            error: err => {
                this.showSpinner = false;
                this.translate.get("MESSAGES.DropBoxApiNotResponding").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            }
        });
    }
    //#endregion File upload

    addLengthAndWidth(index) {
        if (this.OfferTypeDetail.offerDiverseDetailList[index].length > 0 && this.OfferTypeDetail.offerDiverseDetailList[index].width > 0) {
            this.OfferTypeDetail.offerDiverseDetailList[index].qty = this.OfferTypeDetail.offerDiverseDetailList[index].length * this.OfferTypeDetail.offerDiverseDetailList[index].width;
            this.calculateDiversePrise();
        }
    }

    clearLenghtWidth(index) {
        this.OfferTypeDetail.offerDiverseDetailList[index].length = 0;
        this.OfferTypeDetail.offerDiverseDetailList[index].width = 0;
    }

    editTravelingTime() {
        if (this.isEditDrivingDays) {
            this.OfferTypeDetail.numberOfDays = this.OfferTypeDetail.numberOfDrivingDays;
            var amount = 0;
            amount = this.calculateTotalTime(this.OfferTypeDetail.numberOfDrivingDays == this.OfferTypeDetail.numberOfDays ? this.OfferTypeDetail.numberOfDrivingDays : this.OfferTypeDetail.numberOfDays);
            amount = amount * this.DistancePerDayValue;
            this.OfferTypeDetail.travelingAmount = amount;
            this.OfferTypeDetail.price = ((this.OfferTypeDetail.priceTotal + this.OfferTypeDetail.travelingAmount) - this.OfferTypeDetail.discount);
            this.OfferTypeDetail.vat = ((this.OfferTypeDetail.price) * 25 / 100);
            this.OfferTypeDetail.totalInclVAT = (this.OfferTypeDetail.price + (this.OfferTypeDetail.price * 25 / 100));
            this.OfferTypeDetail.numberOfHours = (this.OfferTypeDetail.numberOfHours / this.projectsCoworkerPrice);
            // setTimeout(() => {
            //     this.saveAndUpdateOffer(null);
            // }, 1000);
        }
    }
    updateRoomLengthWidth(offerItem) {
        if (offerItem.roomId && this.OfferTypeDetail.offerRoomDetailList.length > 0) {
            var roomDetail = this.roomList.filter(x => x.id == offerItem.roomId)
            if (roomDetail.length > 0) {
                offerItem.width = roomDetail[0].width;
                offerItem.length = roomDetail[0].length;
                offerItem.height = roomDetail[0].height;
                offerItem.extraM2QtyWall = roomDetail[0].extraM2QtyWall;
                offerItem.extraM2QtyCeiling = roomDetail[0].extraM2QtyCeiling;
                offerItem.extraM2QtyFloor = roomDetail[0].extraM2QtyFloor;
                offerItem.extraM2PriceWall = roomDetail[0].extraM2PriceWall;
                offerItem.extraM2PriceCeiling = roomDetail[0].extraM2PriceCeiling;
                offerItem.extraM2PriceFloor = roomDetail[0].extraM2PriceFloor;
            }
        }
    }

    getMaterialPriceForDefaultMaterial(materialId, offerItem, materialPriceId) {
        var materialprice = this.MaterialObjectForDiverse.find(o => o.id == materialId);
        if (materialprice) {
            offerItem.imagePath = materialprice.imagePath;
            offerItem.selectCategoryTimeForDiverse = [];
            offerItem.isUnitPrint = materialprice.addUnitOnPDF;
            if(materialprice.unit == "stk.")
                offerItem.isQuantityPrint=true;
            for (var i = 0; i < materialprice.offerMaterialPriceList.length; i++) {
                var obj = this.SelectCategoryTime.find(o => o.id == materialprice.offerMaterialPriceList[i].type);
                if (obj) {
                    if (i == 0) {
                        offerItem.materialPriceID = materialprice.offerMaterialPriceList[i].id;
                        offerItem.price = materialprice.offerMaterialPriceList[i].price;
                    }
                    offerItem.selectCategoryTimeForDiverse.push({
                        id: obj.id,
                        key: obj.key,
                        extraValue: materialprice.offerMaterialPriceList[i].paintText,
                        priceId: materialprice.offerMaterialPriceList[i].id,
                        reminder: materialprice.offerMaterialPriceList[i].reminder,
                    });
                    if (materialprice.offerMaterialPriceList[i].id == materialPriceId) {
                        offerItem.materialPriceID = materialprice.offerMaterialPriceList[i].id;
                        offerItem.price = materialprice.offerMaterialPriceList[i].price;
                        offerItem.text = materialprice.text + ' ' + materialprice.offerMaterialPriceList[i].paintText;
                        var offerReminderText = materialprice.offerMaterialPriceList[i].reminder ? materialprice.offerMaterialPriceList[i].reminder : "";
                    }
                    else {
                        offerItem.text = materialPriceId ? offerItem.text : materialprice.text + ' ' + materialprice.offerMaterialPriceText;
                        offerItem.colorCode = offerItem.colorCode ? offerItem.colorCode : "";
                        offerItem.unit = materialprice.unit;
                        var offerReminderText = materialprice.offerMaterialPriceList[i].reminder ? materialprice.offerMaterialPriceList[i].reminder : "";
                    }

                    if (materialprice.offerMaterialPriceList[i].isDefault && materialPriceId == "") {
                        offerItem.materialPriceID = materialprice.offerMaterialPriceList[i].id;
                        offerItem.price = materialprice.offerMaterialPriceList[i].price;
                        var offerReminderText = materialprice.offerMaterialPriceList[i].reminder ? materialprice.offerMaterialPriceList[i].reminder : "";
                        offerItem.text = materialprice.text + ' ' + materialprice.offerMaterialPriceList[i].paintText + (materialprice.addNumberOfTimesOnPDF ? this.projectofferService.getPaintType(materialprice.offerMaterialPriceList[i].type) : "");
                        if (offerItem.materialList && offerItem.materialList.length > 0) {
                            offerItem.materialList.filter(m => {
                                if (m.id == materialId) {
                                    var tempPriceData = m.offerMaterialPriceList.filter(x => x.id == offerItem.materialPriceID);
                                    if (tempPriceData && tempPriceData.length > 0)
                                        m.offerMaterialPriceText = tempPriceData[0].paintText;
                                }
                            })
                        }
                    }
                    offerItem.originalPrice = offerItem.price;
                    if (offerReminderText && materialprice.reminder) {
                        offerItem.reminder = materialprice.reminder + " - " + offerReminderText;
                    } else if (offerReminderText && !materialprice.reminder) {
                        offerItem.reminder = offerReminderText;
                    } else {
                        offerItem.reminder = materialprice.reminder ? materialprice.reminder : "";
                    }
                    if (!offerItem.id && materialprice.isPostNumberDiscount && this.projectOffer && this.projectOffer.percentage != 0 && !this.customerDetailObj.doNotCalculatePriceForPostNumber)
                        offerItem.price = offerItem.price + ((offerItem.price * this.projectOffer.percentage) / 100);
                    if (!offerItem.id && this.projectPriceDiscount && (this.customerDetailObj && !this.customerDetailObj.doNotCalculatePriceForPriceDiscount))
                        offerItem.price = offerItem.price + (offerItem.price * this.projectPriceDiscount / 100);
                    this.getOfferDiverseMeterPrice(offerItem);
                }
            }
            this.checkUncheckPanlesDoorframeRadiotorItem();
            this.setOfferTextForNumberOfTimesOnPDF(materialId, offerItem)
        }
        this.calculateDiversePrise();
    }

    showExtraQuantity(offerItem){
        offerItem.isAddExtraQty = !offerItem.isAddExtraQty
    }

    clearDiverseItem(offerItem){
        offerItem.width = 0;
        offerItem.length = 0;
        offerItem.height = 0;
        offerItem.extraM2QtyWall = 0;
        offerItem.extraM2QtyCeiling = 0;
        offerItem.extraM2QtyFloor = 0;
        offerItem.extraM2PriceWall = 0;
        offerItem.extraM2PriceCeiling = 0;
        offerItem.extraM2PriceFloor = 0;
        offerItem.price = 0;
        offerItem.priceTotal = 0;
        offerItem.qty = 0;
        offerItem.isAddExtraQty = false;
    }

    changeDiverseUnitType(offerItem){
        if(offerItem.unit == "stk.")
            offerItem.isQuantityPrint=true;
        else
            offerItem.isQuantityPrint=false;
    }

    onRoomChange(offer,room){
        var message = "";
        this.translate.get("MESSAGES.RoomSelectionMsg").subscribe({
            next: (res) => {
                message = res.replace('@room', room ? room.name : "");
                }, error: err => { }
            })
            const dialogRef = this.dialog.open(AlertMessageModelComponent, {
                height: "200px",
                data: { Header: message }
            })
            dialogRef.afterClosed().subscribe(result => {
              if (result) {
                this.updateDiverseQty(offer);
                this.updateRoomLengthWidth(offer);
             }
        });
    }

    getOfferItemTimer(offerItem) {
        var selectedMaterial = this.MaterialObject.find(o => o.id == offerItem.materialID);
        if (selectedMaterial) {
            var selectedPrice = selectedMaterial.offerMaterialPriceList.find(o => o.id == offerItem.materialPriceID);
            if (selectedPrice) {
                let timer = 0;
                if (selectedPrice.isAddMeterRangePrice) {
                    if (offerItem.materialPriceID) {
                        if (offerItem.qty >= selectedPrice.startMeter1 && offerItem.qty <= selectedPrice.endMeter1)
                            timer = selectedPrice.timeMeter1;
                        else if (offerItem.qty >= selectedPrice.startMeter2 && offerItem.qty <= selectedPrice.endMeter2) 
                            timer = selectedPrice.timeMeter2;
                        else if (offerItem.qty >= selectedPrice.startMeter3 && offerItem.qty <= selectedPrice.endMeter3) 
                            timer = selectedPrice.timeMeter3;
                    }
                }
                else
                    timer = selectedPrice.timeMeter;
                if (offerItem.qty && offerItem.qty > 0)
                    offerItem.timer = offerItem.qty ? (offerItem.qty * (timer ? timer : 0)) : 0;
                else
                    offerItem.timer;
                this.paintTimer += offerItem.timer;
            }
        }
    }
}