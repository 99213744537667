export const locale = {
    lang: 'en',
    data: {
        'Headers': {
            'QuestionList': 'Ask customer the reason of rejection:'
        },
        'BUTTONS': {
            'NEWPROJECTOFFER': 'New Project Offer',
            'AddQuestion': 'Add Question',
            'Save': 'Save',
            'Cancel': 'Cancel'
        },
        'FIELDS': {
            'Question': 'Question',
            'SrNo': 'Sr No.',
            'Type': 'Type',
            'Answer': 'Answer'
        },
        'VALIDATIONS': {
            'QuestionRequired': 'Question required'
        },
        'MESSAGES': {
            'NoQuestionAnswerFound': 'No Question Found.',
            'Created': 'Created successfully',
            'Updated': 'Updated successfully',
            'Deleted': 'Deleted successfully',
            'Failed': 'Operation failed , try later',
            'HideAnswer': 'Hide Answer'
        },
    }
}