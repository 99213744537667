import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextEditorComponent } from './text-editor.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { QuillEditorModule } from 'ngx-quill-editor';
import { MaterialModule } from './../../../../@fuse/material.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    TranslateModule,
    QuillEditorModule,
    MaterialModule
  ],
  declarations: [TextEditorComponent],
    exports: [TextEditorComponent,
        QuillEditorModule
    ]

})
export class TextEditorModule { }
