import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { FusePipesModule } from '../pipes/pipes.module';
import { MaterialModule } from '@fuse/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxDnDModule } from '@swimlane/ngx-dnd';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FuseDirectivesModule } from '@fuse/directives/directives';
import { CookieService } from 'ngx-cookie-service';
import { TranslateModule } from '@ngx-translate/core';
import { ChartsModule } from 'ng2-charts';

// Filter dropdown
import { FilterDropdownModule } from 'app/main/pages/filter-dropdown/filter-dropdown.module'
import { PaginationModule } from 'app/main/pages/pagination/pagination.module';

//Image Module
import { MaterialImageModule } from 'app/main/pages/material/material-image/material-image-module';
import { EditImageModule } from 'app/main/pages/edit-image/edit-image.module';

// import { GoogleMapAddressModule } from 'app/main/pages/google-map-address-autocomplete/google-map-address-autocomplete.module';
import { GoogleMapDirectionModule } from 'app/main/pages/google-map-direction/google-map-direction.module';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

// File Tab
import { DeleteAlertModalModule } from 'app/main/pages/delete-alert-modal/delete-alert-modal.module';
import { AlertMessageModelModule } from 'app/main/pages/alert-message-model/alert-message-model.module';
import { AlertPlanningMessageModelModule } from 'app/main/pages/alert-planning-message-model/alert-planning-message-model.module';
// import { ViewAlertModelModule } from 'app/core/components/view-alert-model/view-alert-model.module';
import { MovePlanningMessageModelModule } from 'app/main/pages/move-planning-message-model/move-planning-message-model.module';
import { ProjectSharedModule } from 'app/main/pages/project/project-shared.module';

// Validation Form Service
import { FormService } from 'app/core/services/form.service'
import { TextEditorModule } from 'app/main/pages/text-editor/text-editor.module';
import { TextEditorTextAreaModule } from 'app/main/pages/text-editor-textarea/text-editor-textarea.module';
import { PdfViewerModelModule } from 'app/main/pages/pdf-viewer/pdf-viewer.module';
import { FuseMatchMediaService } from '@fuse/services/match-media.service';
import { ProjectListSharedModule } from 'app/main/shared-component/project-list/project-list.module';
import { ProjectCycleLogModule } from 'app/main/shared-component/project-cycle-log/project-cycle-log.module';
import { SeneSMSSharedModule } from 'app/main/shared-component/send-sms/send-sms.module';
import { ProjectCardModule } from 'app/main/shared-component/project-card/project-card.module';
import { CustomerProjectColorCodeModule } from 'app/main/shared-component/customer-project-colorcode/customer-project-colorcode.module';
import { CountryDrodownModule } from 'app/main/shared-component/country-dropdown/country-dropdown.module';
import { ReplayModule } from 'app/main/shared-component/replay/replay.module';
import { ProjectStatusModule } from 'app/main/shared-component/project-status/project-status.module';
import { ReminderListModule } from 'app/main/shared-component/reminder-list/reminder-list.module';
import { VibeDropboxFilesModule } from 'app/main/shared-component/dropbox-files/dropbox-files.module';
import { AddQuestionsModule } from 'app/main/shared-component/add-questions/add-questions.module';
import { AddQuestions1Module } from 'app/main/shared-component/add-questions1/add-questions.module';
import { AnswerStatusQuestionModule } from 'app/main/shared-component/answer-status-question/answer-status-question.module';
import { AnswerStatusLogModule } from 'app/main/shared-component/answer-status-log/answer-status-log.module';
import { TextEditorEmailSendModule } from 'app/main/shared-component/text-editor-email-send/text-editor-email-send.module';
import { MessageLogModule } from 'app/main/shared-component/message-log/message-log.module';
import { CKEditorPopupModule } from 'app/main/shared-component/ckeditor/ckeditor.module';
import { OffersModule } from 'app/main/shared-component/offers/offers.module';
import { NewQuestionsModule } from 'app/main/shared-component/new-questions/new-questions.module';
import { VibeSelectControlModule } from 'app/main/shared-component/vibe-select-control/vibe-select-control.module';
import { AnswerCategoryQuestionModule } from 'app/main/shared-component/answer-category-question/answer-category-question.module';
import { ProjectWorkInfoModule } from 'app/main/shared-component/projectWorkInfo/projectWorkInfo.module';
import { VibeImgShowcaseModule } from 'app/main/shared-component/vibe-img-showcase/vibe-img-showcase.module';
import { QualityTestSharedModule } from 'app/main/shared-component/quality-test/quality-test.module';
import { NextPriviousModule } from 'app/main/shared-component/next-previous/next-previous.module';
import { VibeCommonSelectControlModule } from 'app/main/shared-component/vibe-common-select-control/vibe-common-select-control.module';
import { TextBoxPopupSharedModule } from 'app/main/shared-component/text-box-popup/text-box-popup.module';
import { ProjectOfferCategoryModule } from 'app/main/shared-component/project-offer-category/project-offer-category.module';
import { GoogleMapAddressModule } from 'app/main/shared-component/google-map-address-autocomplete/google-map-address-autocomplete.module';

@NgModule({
    declarations: [
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,


        FusePipesModule,
        MaterialModule,
        FlexLayoutModule,
        FuseDirectivesModule,


        ColorPickerModule,
        NgxDnDModule,
        NgxDatatableModule,
        ChartsModule,
        FilterDropdownModule,
        PaginationModule,
        MaterialImageModule,
        DeleteAlertModalModule,
        AlertMessageModelModule,
        AlertPlanningMessageModelModule,
        // ViewAlertModelModule,
        GoogleMapAddressModule,
        GoogleMapDirectionModule,
        ProjectSharedModule,
        EditImageModule,
        NgxMatSelectSearchModule,
        TextEditorModule,
        TextEditorTextAreaModule,
        PdfViewerModelModule,
        ProjectListSharedModule,
        ProjectCycleLogModule,
        SeneSMSSharedModule,
        ProjectCardModule,
        CustomerProjectColorCodeModule,
        ReplayModule,
        ProjectStatusModule,
        ReminderListModule,
        VibeDropboxFilesModule,
        AddQuestionsModule,
        AddQuestions1Module,
        NewQuestionsModule,
        AnswerStatusQuestionModule,
        AnswerStatusLogModule,
        TextEditorEmailSendModule,
        MovePlanningMessageModelModule,
        CKEditorPopupModule,
        MessageLogModule,
        OffersModule,
        VibeSelectControlModule,
        AnswerCategoryQuestionModule,
        ProjectWorkInfoModule,
        VibeImgShowcaseModule,
        QualityTestSharedModule,
        NextPriviousModule,
        VibeCommonSelectControlModule,
        TextBoxPopupSharedModule,
        ProjectOfferCategoryModule,
    ],
    exports: [
        CommonModule,
        FormsModule,
        MaterialModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        FuseDirectivesModule,
        FusePipesModule,
        ColorPickerModule,
        NgxDnDModule,
        NgxDatatableModule,
        TranslateModule,
        ChartsModule,
        FilterDropdownModule,
       
        PaginationModule,
        DeleteAlertModalModule,
        AlertMessageModelModule,
        AlertPlanningMessageModelModule,
        // ViewAlertModelModule,
        GoogleMapAddressModule,
        GoogleMapDirectionModule,
        ProjectSharedModule,
        MaterialImageModule,
        EditImageModule,
        NgxMatSelectSearchModule,
        TextEditorModule,
        TextEditorTextAreaModule,
        ProjectListSharedModule,
        ProjectCycleLogModule,
        SeneSMSSharedModule,
        ProjectCardModule,
        CustomerProjectColorCodeModule,
        CountryDrodownModule,
        ReplayModule,
        ProjectStatusModule,
        ReminderListModule,
        VibeDropboxFilesModule,
        AddQuestionsModule,
        AddQuestions1Module,
        NewQuestionsModule,
        AnswerStatusQuestionModule,
        AnswerStatusLogModule,
        TextEditorEmailSendModule,
        MovePlanningMessageModelModule,
        CKEditorPopupModule,
        MessageLogModule,
        OffersModule,
        VibeSelectControlModule,
        AnswerCategoryQuestionModule,
        ProjectWorkInfoModule,
        VibeImgShowcaseModule,
        QualityTestSharedModule,
        NextPriviousModule,
        VibeCommonSelectControlModule,
        TextBoxPopupSharedModule,
        ProjectOfferCategoryModule,
    ],
    entryComponents: [
    ],
    providers: [
        CookieService,
        FuseMatchMediaService,
        FormService
    ]
})
export class SharedModule {
}
