export const locale = {
    lang: "ds",
    data: {
      "BUTTONS": {
        
      },
      "FIELDS": {
        'Status': 'Status',
        'Not-Found-Status': 'Status ikke fundet',
      },
    }
  };
  