import { Injectable } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { ApiConstant } from 'app/core/constant/ApiConstant';

@Injectable({
    providedIn: 'root'
})
export class GoogleMapVehicleAddressteService {

  private actionUrl: string;
  constructor(private http: HttpClient) {
      this.actionUrl = ApiConstant.API_URL + ApiConstant.SkyHostMap + "/";
  }

    public GetVehicleList<T>(): Observable<T> {
        return this.http.get<T>(this.actionUrl +"GetVehicleList");
    }

    public GetDirectionList<T>(RequestData): Observable<T> {
        let search = this.GetParams(RequestData);
        return this.http.get<T>(this.actionUrl + "GetVehicleRouteInfo",{
                params: search
            });
    }

    public GetParams(param) {
        let params: HttpParams = new HttpParams();
        for (var key in param) {
            if (param.hasOwnProperty(key)) {
                let val = param[key];
                params = params.append(key, val);
            }
        }
        return params;
    }
}
