import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatTableDataSource, MatOption } from '@angular/material';
import { Router } from '@angular/router';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { TitleService } from 'app/core/services/title.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { CoWorkersService } from 'app/main/pages/coWorker/coWorkers.service';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { CoWorkerFull } from 'app/main/model/CoWorker';
import { ApiConstant } from 'app/core/constant/ApiConstant';
import { ProjectIndoorOutDoorStatus } from 'app/main/model/ProjectStatus';
import * as moment from 'moment';

@Component({
    selector: 'vibe-projectCoWorker',
    templateUrl: './coWorker.component.html',
    styleUrls: ['./coWorker.component.scss']
})
export class CoWorkerComponent implements OnInit {
    orderDate: any;
    widgets: any;
    widget11: any = {};
    coWokers: any = [];
    coWokersFullData: any = [];
    currentselectedcoWorkerObj: any = {};
    selectedCoworkerId: string = "";
    showSpinner = false;
    CoWorkerObject: CoWorkerFull;
    CoWorkerList: any = [];
    imageBaseUrl = "";
    CoWorkerName = "";
    selectStatus = ProjectIndoorOutDoorStatus;
    status: any[] = [];

    @ViewChild('allSelected', {static: false}) private allSelected: MatOption;
    //Initiators
    constructor(private translationLoader: FuseTranslationLoaderService,
        private _router: Router,
        private titleService: TitleService,
        private coWorkersService: CoWorkersService,
        private translate: TranslateService,
        private notificationsService: NotificationsService,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        //super("Invoice", EventEmitterEnum.OnLoadMainContentWelcomPage);
        this.translationLoader.loadTranslations(english, danish);
        this.imageBaseUrl = ApiConstant.URL;
    }

    ngOnInit() {
        // this.coWokers = this.data;
        // this.coWokersFullData = this.data;
        this.orderDate = moment(new Date()).format("YYYY-MM-DD HH:mm");
        this.widgets = {
            'widget11': {
                'title': 'Team Members',
                'table': {
                  'columns': ['imagePath', 'coworkerNumber', 'name', 'email', 'phone', 'status','action'],
                    'rows': []
                }
            },
        };
        this.getDefaultCoworkers();
    }

    //coworker multiselect code here
    toggleAllSelection() {
        if (this.allSelected.selected) {
            this.currentselectedcoWorkerObj.id = [];
            this.currentselectedcoWorkerObj.id.push(0);
            for (var i = 0; i < this.coWokers.length; i++) {
                this.currentselectedcoWorkerObj.id.push(this.coWokers[i].id);
            }
        } else {
            this.currentselectedcoWorkerObj.id = [];
        }
        var withoutAll = this.currentselectedcoWorkerObj.id.filter(o => o != 0);
        if (this.currentselectedcoWorkerObj.id.length <= 0) {
            this.selectedCoworkerId = "";
        }
        else {
            this.selectedCoworkerId = withoutAll.join();
        }
    }

    //for search
    // DropDownSearchCoWorker(value) {
    //     var model = new RequestStatusProject();
    //     model.Index = 1;
    //     model.Name = value;
    //     this.coWokers = this.coWokersFullData.filter(it => {
    //         return it.name.toLowerCase().includes(model.Name.toLowerCase());
    //     });
    // }

    // DropDownCoWorkerSelect(coworker) {
    //     if (!this.currentselectedcoWorkerObj.id)
    //         this.selectedCoworkerId = "";
    //     else {
    //         this.selectedCoworkerId = this.currentselectedcoWorkerObj.id;
    //     }
    // }
    getDefaultCoworkers() {
        this.showSpinner = true;
        this.coWorkersService.GetDefaultCoworkers<ResponseBase>()
            .subscribe({
                next: (response: ResponseBase) => {
                    this.CoWorkerList = response.result;
                    for (var i = 0; i < this.CoWorkerList.length; i++) {
                        this.CoWorkerList[i].imagePath = this.imageBaseUrl + "" + this.CoWorkerList[i].imagePath;
                        this.CoWorkerList[i].statusDisplay = "";
                        if (this.CoWorkerList[i].status) {
                            if (this.CoWorkerList[i].status.includes("1")) {
                                this.CoWorkerList[i].statusDisplay = "Indendørs,";
                            }
                            if (this.CoWorkerList[i].status.includes("2")) {
                                this.CoWorkerList[i].statusDisplay = this.CoWorkerList[i].statusDisplay + "Udendørs,";
                            }
                            if (this.CoWorkerList[i].status.includes("3")) {
                                this.CoWorkerList[i].statusDisplay = this.CoWorkerList[i].statusDisplay + "Sprøjtemaling,";
                            }
                            if (this.CoWorkerList[i].status.includes("4")) {
                                this.CoWorkerList[i].statusDisplay = this.CoWorkerList[i].statusDisplay + "Reklamation,";
                            }
                            if (this.CoWorkerList[i].status.includes("5")) {
                                this.CoWorkerList[i].statusDisplay = this.CoWorkerList[i].statusDisplay + "Personale køb,";
                            }
                            if (this.CoWorkerList[i].status.includes("6")) {
                                this.CoWorkerList[i].statusDisplay = this.CoWorkerList[i].statusDisplay + "Butiks køb,";
                            }
                            this.CoWorkerList[i].statusDisplay = this.CoWorkerList[i].statusDisplay.substring(0, this.CoWorkerList[i].statusDisplay.length - 1);
                        }
                    }
                    this.showSpinner = false;
                    this.widgets.widget11.table.rows = this.CoWorkerList;
                    this.widget11.dataSource = new MatTableDataSource(this.widgets.widget11.table.rows);
                },
                error: err => { this.showSpinner = false },
                complete: () => { }
            });
    }
    getCoworker(value) {
        this.showSpinner = true;
        this.coWorkersService.GetSingle<ResponseBase>(this.selectedCoworkerId)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.CoWorkerObject = response.result;
                    this.CoWorkerObject.createAutomatic = value;
                    var msg = "";
                    if (value)
                        msg = "MESSAGES.Created";
                    else
                        msg = "MESSAGES.Deleted";
                    this.AddNewCoworker(msg);
                },
                error: err => { this.showSpinner = false },
                complete: () => { }
            });
    }
    AddNewCoworker(msg) {
        var selectedNewStatus = "";
        this.status.filter(x => {
            selectedNewStatus = (selectedNewStatus ? selectedNewStatus + "," : "") + x;
        });
        this.CoWorkerObject.status = selectedNewStatus;
        this.status = [];
        this.coWorkersService.Update<ResponseBase>(this.CoWorkerObject.id, this.CoWorkerObject)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.showSpinner = false;
                    this.translate.get(msg).subscribe({
                        next: (res) => {
                            this.notificationsService.create(res);
                            this.CoWorkerName = "";
                            this.currentselectedcoWorkerObj.id = "";
                            this.selectedCoworkerId = "";
                            this.getDefaultCoworkers();
                        },
                        error: err => {
                        }
                    });
                },
                error: err => {
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });
                },
                complete: () => {
                    this.showSpinner = false;
                }
            });
    }

    DeleteassignCoworker(id) {
        this.selectedCoworkerId = id;
        this.getCoworker(false);
    }
    onCowrkerChange(e) {
        if (!this.currentselectedcoWorkerObj.id)
            this.selectedCoworkerId = "";
        else 
            this.selectedCoworkerId = this.currentselectedcoWorkerObj.id;
    }
}
