export const locale = {
    lang: 'ds',
    data: {
        'TITLE': {
            'CoworkerReviewQuestion': 'Spørgsmål til medarbejder',
        },
        'FIELDS': {
            'IsSendEmail': 'Er Send e-mail ?',
            'TabList': 'Fanelisteindstilling',
            "Title":'Titel',
            "StartDate":'Start dato',
            "EndDate":'Slut dato',
            'SRNO': 'Sr nr.',
            'WeekDays': 'Hverdage',
            'Days': 'dage',
            'WeekNo': "Uge nr",
        },
        'Message': {
            'Created': 'Oprettet med succes.',
            'Updated': 'Opdateret med succes.',
            'Deleted': 'Slettet med succes.',
            'Failed': 'Noget gik galt !',
            'NoRecords': 'Ingen registreringer fundet !'
        },
        'FILTERS': {
        },
        'moduleTypeList': {
        },
        'BUTTONS': {
            'Save':'Gem',
            'Add': 'Tilføj',
            'Update': 'Opdatering',
            'Delete': 'Slet',
            'Edit': 'Redigere',
            'Cancel': 'Fortryd',
            'Back': 'Gå Tilbage',
            'RepeatMode':'Gentage'
        },
        'VALIDATIONS': {
            'Question': 'Spørgsmål påkrævet',
            'Desc': 'Question title påkrævet ',
            'StartDate': 'Startdato er påkrævet',
            'endDate': 'Slutdato er påkrævet',
        },
    }
};