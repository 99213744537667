export const locale = {
    lang: 'en',
    data: {
        'MESSAGES': {
            'Created': 'Created successfully',
            'Updated': 'Updated successfully',
            'Deleted': 'Deleted successfully',
            'Failed': 'Operation failed , try later',
        },
        'BUTTONS': {
            'Ok': 'Save',
            'Cancel': 'Return',
        },
        'FIELDS': {
            'Status': 'Status',
        },
        'selectedValueIndoorOutdoorStatus': {
            'value1': 'Indoor ',//Man in a room
            'value2': 'Outdoor', //Sun
            'value3': 'Spray Painting',//spraypaint
            'value4': 'Complaint', //Reklamation
            'value5': 'Staff Purchase', //drawing of painter
            'value6': 'Store Purchase' //Nordsjø logo
        },
    }
};

