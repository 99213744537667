export const locale = {
    lang: 'ds',
    data: {
      'BUTTONS': {
        'Upload': 'Upload',
        'Cancel': 'Tilbage',
        "Update":"Opdatere",
        "Reset":"Nulstil / annullere",
        "SampleFileDownload":"Prøve fil download",
        'Back': 'Tilbage',
      },
      'Header':{
        'Number': 'Number',
        'SupplierName': 'LeverandorNavn',
        'ContactPerson': 'Kontaktperson',
        'Email': 'Email',
        'PhoneNo': 'Telefon',
        'Homepage': 'Hjemmeside',
        'AppUrlLink': 'AppURLlink',
        'Address': 'Adresse',
        'Zipcode': 'Postnummer',
        'City': 'By',
        'Note': 'Notat',
      },
      'FIELDS': {
        'SelectSupplier': 'Vælg Leverandør',
        'UploadPriceList': 'Upload prisliste',
        'ChooseFile': 'Vælg fil',
      },
      'LABELS': {
        'TITLE': 'Leverandørliste upload (.xls,.xlsx)',
        'NoFileSelected': 'Ingen fil valgt',
        'FileSelected': 'Fil valgt',
      },
      'MESSAGES': {
        'Created': 'Oprettet succesfuldt.',
        'Updated': 'Opdateret succesfuldt.',
        'Failed': 'mislykkedes',
        'Deleted': 'Slettet med succes',
        'Notes':'**Bemærk - Inaktive eller slettede materialer er fremhævet i rød farve'
      },
    }
  }; 
  