export const locale = {
  lang: 'en',
  data: {
    'HEADER': {
      'MoveRequisition': 'Are you sure you want to move',
      'Noco-workeradded': 'No co-worker found',
    },
    'BUTTONS': {
      'Ok': 'Ok',
      'BACK': 'Back'
    },
    'FIELDS': {
      'From': 'From',
      'To': 'to',
      'HoursBack': 'Hours Back',
      'Before60Days': '*** 60 Day old.',
      'RemainingTime': 'Remaining',
      'Planned': 'Planned',
      'HourlyWage': 'Hourly rate',
      'visit': 'Visit',
      'SRNO': 'Sr No',
      'Name': 'Name',
      'MobileNo': 'Mobile No.',
    },
    'MESSAGES': {
      'NORECORD': 'No Project Found'
    },
    'FILTERS': {
      'IndoorOutDoor': 'Indoor,Outdoor,Spray Painting,Reclamation*',
      'SEARCH': 'Search',
      'SORTING': 'Sort By',
      'SORTING_STATUS': 'Status',
      'Active': 'Include All Inactive',
      'BADGE': 'Inactive',
      'Deleted': 'Show ONLY deleted cases',
      'All': 'All',
      'Lift/Stigemand': 'Lift/Stigemand',
      'Indoor/Outdoor': 'Indoor/Outdoor',
      'Lift/Stigmand': 'Lift/Stigmand',
      'Ind/Ud': 'Ind/Ud',
      'MtoM': 'M to M',
      'Note': 'Note',
      'Offers': 'Offers',
      'Show60DayOld': 'Show 60 Days old',
      'ShowHourDetails': 'Hourly details',
      'TotalProject': 'Total Projects',
      'TotalProjectRemainingHours': 'Project Remaining Hours',
      'TotalProjectDaysLeft': 'Project Remaining Days',
      'LastDayWorkingHour': 'Last Day Working Hours',
      'ShowTime': 'Show Time'
    },
    'selectedValueIndoorOutdoorStatus': {
      'value1': 'Indoor ',//Man in a room
      'value2': 'Outdoor', //Sun
      'value3': 'Spray Painting',//spraypaint
      'value4': 'Complaint', //Reklamation
      'value5': 'Staff purchase', //drawing of painter
      'value6': 'Store purchase' //Nordsjø logo
    },
    // 'selectedValueStatus': {
    //   'value1': 'Process',
    //   'value2': 'Scheduled visit',
    //   'value3': 'Do not answer',
    //   'value4': 'Offers sent',
    //   'value5': 'Offer approved',
    //   'value6': 'Planned',
    //   'value7': 'Offer rejected',
    //   'value8': 'Started',
    //   'value9': 'Performed',
    //   'value10': 'Billed',
    //   'value11': 'Pending',
    //   'value12': 'Ready billing',
    // },
  }
};
