import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { QuickPanelService } from './quick-panel.service';

import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';

@Component({
  selector: 'quick-panel',
  templateUrl: './quick-panel.component.html',
  styleUrls: ['./quick-panel.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QuickPanelComponent implements OnInit {
  date: Date;
  settings: any;
  notes = [];
  events = [];
  WeekDay: string;
  Month: string;
  DayAfterTomorrow: string;


  temp: string;
  temp_max: string;
  temp_min: string;

  temp2: string;
  temp_max2: string;
  temp_min2: string;

  temp3: string;
  temp_max3: string;
  temp_min3: string;

  objweather: any[];
  weekNumber: number;

  mydata = {
    city: 'dianalund',
    code: 'dk'
  }


  constructor(private quickpanelservice: QuickPanelService, private translationLoader: FuseTranslationLoaderService) {
    this.translationLoader.loadTranslations(english, danish);
    this.date = new Date();
    this.settings = {
      notify: true,
      cloud: false,
      retro: true
    };


  }

  ngOnInit() {

    this.getWeekNumber();

    this.quickpanelservice.getWeather(this.mydata.city, this.mydata.code).subscribe((response) => {

      this.temp = response[0].main.temp;
      this.temp_max = response[0].main.temp_max;
      this.temp_min = response[0].main.temp_min;

      this.temp2 = response[1].main.temp;
      this.temp_max2 = response[1].main.temp_max;
      this.temp_min2 = response[1].main.temp_min;

      this.temp3 = response[2].main.temp;
      this.temp_max3 = response[2].main.temp_max;
      this.temp_min3 = response[2].main.temp_min;

    })
  }

  getWeekNumber() {

    //For WeekDay
    var weekdays = new Array(7);
    weekdays[0] = "Søndag";
    weekdays[1] = "Mandag";
    weekdays[2] = "Tirsdag";
    weekdays[3] = "Onsdag";
    weekdays[4] = "Torsdag";
    weekdays[5] = "Fredag";
    weekdays[6] = "Lørdag";

    var Today = new Date();
    var WeekDay = Today.getDay();
    this.WeekDay = weekdays[WeekDay];


    var months = new Array(12);
    months[0] = "Januar";
    months[1] = "Februar";
    months[2] = "Marts";
    months[3] = "April";
    months[4] = "Maj";
    months[5] = "Juni";
    months[6] = "Juli";
    months[7] = "August";
    months[8] = "September";
    months[9] = "Oktober";
    months[10] = "November";
    months[11] = "December";

    var Month = Today.getMonth();
    this.Month = months[Month];

    var date_after_tomorrow = new Date(Today.getFullYear(), Today.getMonth(), Today.getDate() + 2);
    this.DayAfterTomorrow = date_after_tomorrow.getDate() + "/" + (date_after_tomorrow.getMonth() + 1);


    //For Week Number
    let d: any = new Date();
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    // Get first day of year
    var yearStart: any = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
    // Return array of year and week number
    this.weekNumber = weekNo;
    return this.weekNumber;
  }
}
