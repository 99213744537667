export const locale = {
    lang: 'ds',
    data: {
        'FIELDS': {
            'Designation': 'Betegnelse',
            'ValideFrom': 'Gyldig Fra',
            'SRNO': 'Sr nr.'
        },
        'BUTTONS': {
            'Add': 'Ansættelsesvilkår',
            'BACK': 'Tilbage',
        },
        'MESSAGES': {
            'Updated': 'Opdateret succesfuldt.',
            'Failed': 'Mislykkedes',
            'Deleted': 'Slettet med succes',
            'NORECORD': 'Intet optage fundet',
            'Created': 'Oprettet med succes',
        },
        'Validation': {
            'DesignationRequired': 'Betegnelse er påkrævet',
            'ValideFromRequired': 'Gyldig fra dato er påkrævet'
        },
        'Headers': {
            'TitleList': 'Ansættelsesvilkår Liste',
            'DeleteMessage': 'Er du sikker på, at du vil slette dette'
        },
        'TITLE': {
            'CustomerPagination': 'Antal log pr. side',
        },
        'TOOTIPORTITLE': {
            'Edit': 'Redigere',
            'Delete': 'Slet',
        }
    }
};