import { Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { CookieService } from 'ngx-cookie-service';
import { Subject, from, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseMatchMediaService } from '@fuse/services/match-media.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as english } from '../../../app/layout/components/toolbar/translate/en';
import { locale as danish } from '../../../app/layout/components/toolbar/translate/ds';
import { AuthService } from 'app/core/services/auth.service';

@Component({
    selector: 'fuse-shortcuts',
    templateUrl: './shortcuts.component.html',
    styleUrls: ['./shortcuts.component.scss']
})
export class FuseShortcutsComponent implements OnInit, OnDestroy {
    shortcutItems: any[];
    navigationItems: any[];
    filteredNavigationItems: any[];
    searching: boolean;
    mobileShortcutsPanelActive: boolean;
    currentLanguage: string;
    matchMediaSubscription: Subscription;
    // roleName: string;
    // useradmin: boolean = false;
    // userkontor: boolean = false;
    // usermaler: boolean = false;
    // usergaest: boolean = false;

    @Input()
    navigation: any;

    @ViewChild('searchInput', { static: true })
    searchInputField;

    @ViewChild('shortcuts', { static: true })
    shortcutsEl: ElementRef;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {Renderer2} _renderer
     * @param {CookieService} _cookieService
     * @param {FuseMatchMediaService} _fuseMatchMediaService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {MediaObserver} _observableMedia
     */
    constructor(
        private _cookieService: CookieService,
        private _fuseMatchMediaService: FuseMatchMediaService,
        private _fuseNavigationService: FuseNavigationService,
        private _observableMedia: MediaObserver,
        private _renderer: Renderer2,
        private translate: TranslateService,
        private _router: Router,
        private authService: AuthService,
        private translationLoader: FuseTranslationLoaderService,
    ) {
        // Set the defaults
        this.translationLoader.loadTranslations(english, danish);
        this.shortcutItems = [];
        this.searching = false;
        this.mobileShortcutsPanelActive = false;

        this.translate.onLangChange.subscribe((params: LangChangeEvent) => {
            this.currentLanguage = this.translate.currentLang;
            if (window.location.href.indexOf('/help-') > -1) {
                let count = 0;
                this.shortcutItems.filter(item => {
                    if (item.title == "Manual") {
                        this.shortcutItems[count].url = '/help-' + this.currentLanguage;
                    }
                    count = count + 1
                });
                this._router.navigateByUrl('/help-' + this.currentLanguage);
            }
        });

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // this.roleName = this.authService.getUserRole();
        // if (this.roleName == "Admin") {
        //     this.useradmin = true;
        //   }
        //   else if (this.roleName == "Kontor") {
        //     this.userkontor = true;
        //   }
        //   else if (this.roleName == "Maler") {
        //     this.usermaler = true;
        //   }
        //   else if (this.roleName == "Gaest") {
        //     this.usergaest = true;
        //   }
        //   else {
        //     this.useradmin = true;
        //   }
      
          this.getdata();

        // Get the navigation items and flatten them
        this.currentLanguage = this.translate.currentLang;
        this.filteredNavigationItems = this.navigationItems = this._fuseNavigationService.getFlatNavigation(this.navigation);
        if (this._cookieService.check('FUSE2.shortcuts')) {
            this.shortcutItems = JSON.parse(this._cookieService.get('FUSE2.shortcuts'));
        }
        else {
            // User's shortcut items
            this.shortcutItems = [
                // {
                //     'title': 'Mail',
                //     'type': 'item',
                //     'icon': 'email',
                //     'url': '/apps/mail'
                // },
                {
                    'title': 'Reminders',
                    'type': 'item',
                    'icon': 'check_box',
                    'url': '/reminders'
                },
                {
                    'title': 'Help',
                    'type': 'item',
                    'icon': 'live_help',
                    'url': '/help-' + this.currentLanguage
                }
                //,
                //{
                //    'title': 'To-Do',
                //    'type' : 'item',
                //    'icon' : 'check_box',
                //    'url'  : '/apps/todo'
                //}
            ];
        }

        // Subscribe to media changes
        this._fuseMatchMediaService.onMediaChange
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                if (this._observableMedia.isActive('gt-sm')) {
                    this.hideMobileShortcutsPanel();
                }
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Search
     *
     * @param event
     */
    search(event): void {
        const value = event.target.value.toLowerCase();

        if (value === '') {
            this.searching = false;
            this.filteredNavigationItems = this.navigationItems;

            return;
        }

        this.searching = true;

        this.filteredNavigationItems = this.navigationItems.filter((navigationItem) => {
            return navigationItem.title.toLowerCase().includes(value);
        });
    }

    /**
     * Toggle shortcut
     *
     * @param event
     * @param itemToToggle
     */
    toggleShortcut(event, itemToToggle): void {
        event.stopPropagation();

        for (let i = 0; i < this.shortcutItems.length; i++) {
            if (this.shortcutItems[i].url === itemToToggle.url) {
                this.shortcutItems.splice(i, 1);

                // Save to the cookies
                this._cookieService.set('FUSE2.shortcuts', JSON.stringify(this.shortcutItems));

                return;
            }
        }

        this.shortcutItems.push(itemToToggle);

        // Save to the cookies
        this._cookieService.set('FUSE2.shortcuts', JSON.stringify(this.shortcutItems));
    }

    /**
     * Is in shortcuts?
     *
     * @param navigationItem
     * @returns {any}
     */
    isInShortcuts(navigationItem): any {
        return this.shortcutItems.find(item => {
            return item.url === navigationItem.url;
        });
    }

    /**
     * On menu open
     */
    onMenuOpen(): void {
        setTimeout(() => {
            this.searchInputField.nativeElement.focus();
        });
    }

    /**
     * Show mobile shortcuts
     */
    showMobileShortcutsPanel(): void {
        this.mobileShortcutsPanelActive = true;
        this._renderer.addClass(this.shortcutsEl.nativeElement, 'show-mobile-panel');
    }

    /**
     * Hide mobile shortcuts
     */
    hideMobileShortcutsPanel(): void {
        this.mobileShortcutsPanelActive = false;
        this._renderer.removeClass(this.shortcutsEl.nativeElement, 'show-mobile-panel');
    }

    getdata() {

        const cookieExists = this._cookieService.check('FUSE2.shortcuts');

        if (cookieExists) {
            this.shortcutItems = JSON.parse(this._cookieService.get('FUSE2.shortcuts'));
        }
        else {
            this.shortcutItems = [
                {
                    'title': 'TITLE_REMINDERS',
                    'type': 'nav-item',
                    'icon': 'check_box',
                    'url': '/reminders',
                    'visibility': true
                },
                {
                    'title': 'TITLE_USER_MANUAL',
                    'type': 'nav-item',
                    'icon': 'live_help',
                    'url': '/help-' + this.translate.currentLang,
                    'visibility': true
                },
            ];

            // if (this.useradmin || this.userkontor) {
            //     // User's shortcut items
            //     this.shortcutItems = [
            //         {
            //             'title': 'TITLE_REMINDERS',
            //             'type': 'nav-item',
            //             'icon': 'check_box',
            //             'url': '/reminders',
            //             'visibility': true
            //         },
            //         {
            //             'title': 'TITLE_USER_MANUAL',
            //             'type': 'nav-item',
            //             'icon': 'live_help',
            //             'url': '/help-' + this.translate.currentLang,
            //             'visibility': true
            //         },
            //     ];
            // }
            // else if (this.usermaler) {
            //     // User's shortcut items
            //     this.shortcutItems = [
            //         {
            //             'title': 'TITLE_MESSAGES',
            //             'type': 'nav-item',
            //             'icon': 'email',
            //             'url': '/messages',
            //             'visibility': true
            //         },
            //         {
            //             'title': 'TITLE_REMINDERS',
            //             'type': 'nav-item',
            //             'icon': 'check_box',
            //             'url': '/reminders',
            //             'visibility': true
            //         },
            //         {
            //             'title': 'TITLE_USER_MANUAL',
            //             'type': 'nav-item',
            //             'icon': 'live_help',
            //             'url': '/help-' + this.translate.currentLang,
            //             'visibility': true
            //         },
            //     ];
            // }
            // else if (this.usergaest) {
            //     // User's shortcut items
            //     this.shortcutItems = [
            //         {
            //             'title': 'TITLE_MESSAGES',
            //             'type': 'nav-item',
            //             'icon': 'email',
            //             'url': '/messages',
            //             'visibility': true
            //         },
            //         {
            //             'title': 'TITLE_REMINDERS',
            //             'type': 'nav-item',
            //             'icon': 'check_box',
            //             'url': '/reminders',
            //             'visibility': false
            //         },
            //         {
            //             'title': 'TITLE_USER_MANUAL',
            //             'type': 'nav-item',
            //             'icon': 'live_help',
            //             'url': '/help-' + this.translate.currentLang,
            //             'visibility': true
            //         },
            //     ];
            // }
        }

        this.matchMediaSubscription =
            this._fuseMatchMediaService.onMediaChange.subscribe(() => {
                if (this._observableMedia.isActive('gt-sm')) {
                    this.hideMobileShortcutsPanel();
                }
            });
    }
}
