export const locale = {
    lang: 'ds',
    data: {
        'HEADERS': {
            'InactiveProjects': 'Inaktive Projekter Liste',
        },
        'FIELDS': {
            'SRNO': 'Sr nr.',
            'Number': 'Sag nummer',
            'Address': 'Sag Adresse',
            'DateTime': "Dato tid",
            'CoworkerName': 'Medarbejder navn',
            'Status': 'Status',
            'CustomerName': 'Debitor navn',
            'TotalPrice': 'Total pris',
            'StartDate': 'Start dato',
            'EndDate': 'Slut dato',
        },
        'MESSAGES': {
            'Failed': 'Mislykkedes',
            'NORECORD': 'Intet optage fundet'
        },
        'TITLE': {
            'CustomerPagination': 'Antal log pr. side',
        },
        'IsActive': {
            'value1': 'Aktiv',
            'value0': 'Inaktiv',
        },
        'Botton': {
            'Search': 'Søg',
        }
    }
}