export const locale = {
    lang: 'en',
    data: {
        'MESSAGES': {
            'Created': 'Created successfully',
            'Updated': 'Updated successfully',
            'Deleted': 'Deleted successfully',
            'Failed': 'Operation failed , try later',
            'NORECORD': 'Location not added',
        },
        'FIELDS': {
            'FullAddress': 'Full Address',
            'Vehicle': 'Vehicle',
            'StartDate': 'Start Date',
            'EndDate': 'End Date',
            'RouteDate': 'Route Date',
            'Route': 'Route',
            'MetersTrip': 'Meters trip',
            'Group': 'Group',
            'Devices': 'Devices',
            'Labels': 'Category',
            'Location': 'Location'
        },
        'FILTERS': {
            'All': 'All',
          },
        'BUTTONS':{
            'Edit': 'Edit'
        }  
    }
};