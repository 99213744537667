import { Component, OnInit, ElementRef, NgZone, ViewChild, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { CustomErrorStateMatcher, spaceOnlyValidator } from 'app/core/Validators/validation';
import { GoogleMapAddressAutocompleteService } from './google-map-address-autocomplete.service';
import { MapsAPILoader } from '@agm/core';

import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';

import { MatDialog } from '@angular/material';

import { AddressConfirmationModalComponent } from './address-confirmation-modal/address-confirmation-modal.component';
import { FormService } from 'app/core/services/form.service';


declare var google: any;
@Component({
  selector: 'app-google-map-address-autocomplete',
  templateUrl: './google-map-address-autocomplete.component.html',
  styles: []
})

export class GoogleMapAddressAutocompleteComponent implements OnInit {
  autocomplete: any;
  setData: any = {};
  zoom: number = 15;
  latitude: number;
  longitude: number;
  objAddress: any = {};
  callSetPlaceParam: boolean = true;
  formGoogleMapAddress: FormGroup;

  @ViewChild("search", { static: true }) public searchElementRef: ElementRef;
  @Output() Address = new EventEmitter<any>();
  @Input() objAddressEdit: any;
  @Input() IsValidateForm: boolean = true;
  @Input() esMatcher: CustomErrorStateMatcher;
  @Input() parentFormSubmitted: boolean = true;

  constructor(public translationLoader: FuseTranslationLoaderService,
    private googleMapAddressService: GoogleMapAddressAutocompleteService,
    private mapsAPILoader: MapsAPILoader, private ngZone: NgZone,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private formService: FormService) {
    this.translationLoader.loadTranslations(english, danish);
  }

  ngOnInit() {
    //this.objAddress = this.objAddressEdit;
    //if (this.objAddress.IsEdit) {
    //  if (this.objAddress.position) {
    //    let latLongString = this.objAddress.position.split(',')
    //    this.latitude = parseFloat(latLongString[0].replace("latitude=", '').trim())
    //    if (latLongString.length > 1)
    //      this.longitude = parseFloat(latLongString[1].replace("longitude=", '').trim())
    //    this.objAddress.mapLocation = this.objAddress.address ? this.objAddress.address : '' + this.objAddress.city ? this.objAddress.city : '' + this.objAddress.pinCode ? this.objAddress.pinCode : '';
    //  }
    //}
    //else {
    //  this.latitude = 55.528090;
    //  this.longitude = 11.495900;
    //}

    if (this.IsValidateForm) {
      this.formGoogleMapAddress = this.formBuilder.group({
        mapLocation: new FormControl(null, Validators.compose([Validators.required, spaceOnlyValidator.bind(this)])),
        //mapLocation: new FormControl(null),
        address: new FormControl(null, Validators.compose([Validators.required, spaceOnlyValidator.bind(this)])),
        pinCode: new FormControl(null, Validators.compose([Validators.required, spaceOnlyValidator.bind(this)])),
        city: new FormControl(null, Validators.compose([Validators.required, spaceOnlyValidator.bind(this)])),
        position: new FormControl(null),
        //position: new FormControl(null, Validators.compose([Validators.required, spaceOnlyValidator.bind(this)])),
      });
    }
    else {
      this.formGoogleMapAddress = this.formBuilder.group({
        mapLocation: new FormControl(null),
        address: new FormControl(null),
        pinCode: new FormControl(null),
        city: new FormControl(null),
        position: new FormControl(null),
      });
    }
    setTimeout(() => {
      this.objAddress.pinCode = this.objAddress.pinCode ? this.objAddress.pinCode.trim() : "";
      this.AddressEvent(this.objAddressEdit);
    }, 1200);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.objAddress.pinCode = this.objAddress.pinCode ? this.objAddress.pinCode.trim() : "";
    this.AddressEvent(this.objAddressEdit);
  }

  AddressEvent(address) {
    this.objAddress = address;
    if (this.objAddress.IsEdit) {
      this.objAddress.mapLocation = this.objAddress.address ? this.objAddress.address : '' + this.objAddress.city ? this.objAddress.city : '' + this.objAddress.pinCode ? this.objAddress.pinCode : '';
      if (this.objAddressEdit.position == undefined || this.objAddressEdit.position == null || this.objAddressEdit.position == "") {
        var addStr = this.objAddressEdit.address + " " + this.objAddressEdit.pinCode + " " + this.objAddressEdit.city;
        this.googleMapAddressService.Get<any>(addStr)
          .subscribe({
            next: (response: any) => {
              if (response.status == "OK") {
                this.latitude = response.results[0].geometry.location["lat"];
                this.longitude = response.results[0].geometry.location["lng"];
                this.objAddress.position = "latitude=" + this.latitude + " , " + "longitude=" + this.longitude
              }
            },
            error: err => { },
            complete: () => { }
          });
      }
      else {
        let latLongString = this.objAddress.position.split(',')
        this.latitude = parseFloat(latLongString[0].replace("latitude=", '').trim())
        if (latLongString.length > 1)
          this.longitude = parseFloat(latLongString[1].replace("longitude=", '').trim())
      }
    }
  }


  focus() {
    if (this.callSetPlaceParam)
      this.Handle();
  }

  Handle() {
    this.callSetPlaceParam = false;
    this.mapsAPILoader.load().then(() => {

      this.autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ["address"]
      });
      this.autocomplete.addListener("place_changed", () => {

        this.ngZone.run(() => {
          //get the place result

          let place = this.autocomplete.getPlace();
          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 15;

          this.setPlaceParam(place.address_components);
          this.openDialog(this.setData);
        });
      });
    });

  }

  setPlaceParam(obj) {
    var objSelectedAddress: any = {};
    objSelectedAddress.add1 = "";
    objSelectedAddress.fullAddress = "";
    var route = obj.find(c => c.types.includes('route'));
    if (route != null)
      objSelectedAddress.add1 = objSelectedAddress.add1 + route.long_name + " ";

    var street = obj.find(c => c.types.includes('street_number'));
    if (street != null)
      objSelectedAddress.add1 = objSelectedAddress.add1 + street.long_name + " ";

    var sublocality_level_3 = obj.find(c => c.types.includes('sublocality_level_3'));
    if (sublocality_level_3 != null)
      objSelectedAddress.add1 = objSelectedAddress.add1 + sublocality_level_3.long_name + " ";

    var sublocality_level_2 = obj.find(c => c.types.includes('sublocality_level_2'));
    if (sublocality_level_2 != null)
      objSelectedAddress.add1 = objSelectedAddress.add1 + sublocality_level_2.long_name + " ";

    var sublocality_level_1 = obj.find(c => c.types.includes('sublocality_level_1'));
    if (sublocality_level_1 != null)
      objSelectedAddress.add1 = objSelectedAddress.add1 + sublocality_level_1.long_name + " ";

    objSelectedAddress.fullAddress = objSelectedAddress.add1;
    var postal_code = obj.find(c => c.types.includes('postal_code'));
    if (postal_code != null) {
      objSelectedAddress.zip = postal_code.long_name;
      objSelectedAddress.zip = objSelectedAddress.zip ? objSelectedAddress.zip.trim() : "";
      objSelectedAddress.fullAddress = objSelectedAddress.fullAddress + objSelectedAddress.zip + " ";
    }

    var administrative_area_level_2 = obj.find(c => c.types.includes('locality') || c.types.includes('administrative_area_level_2'));
    if (administrative_area_level_2 != null) {
      objSelectedAddress.fullAddress = objSelectedAddress.fullAddress + administrative_area_level_2.long_name + " ";
      objSelectedAddress.city = administrative_area_level_2.long_name;
    }

    var administrative_area_level_1 = obj.find(c => c.types.includes('administrative_area_level_1'));
    if (administrative_area_level_1 != null)
      objSelectedAddress.fullAddress = objSelectedAddress.fullAddress + administrative_area_level_1.long_name;

    //var country = obj.find(c => c.types.includes('country'));
    //if (country != null)
    //  objSelectedAddress.add1 = objSelectedAddress.add1 + country.long_name;

    objSelectedAddress.position = "latitude=" + this.latitude + " , " + "longitude=" + this.longitude
    this.setData = objSelectedAddress;
  }

  openDialog(data) {
    const dialogRef = this.dialog.open(AddressConfirmationModalComponent, {
      height: '600px',
      width: '500px',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.objAddress.address = result.address
        this.objAddress.city = result.city
        this.objAddress.pinCode = result.zip ? result.zip.trim() : ""
        this.objAddress.position = result.position
        this.Address.emit(this.objAddress)
      }
      else {
        //this.CustomerObject.mapLocation = null;
      }
    });
  }
}
