import { OnInit, Component, Optional, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { projectRequisition } from 'app/main/model/Project';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';
import { ProjectService } from 'app/main/pages/project/project.service';
import { ResponseBase } from 'app/main/model/ResponseBase';

@Component({
    selector: 'vibe-MoveRequisition',
    templateUrl: './moveRequisition.Component.html',
    styleUrls: ['./moveRequisition.Component.scss']
})

export class MoveRequisitionComponent implements OnInit {
    Requisition: projectRequisition;
    projectNumber: any;
    requitionNum: any;

    constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<MoveRequisitionComponent>,
        private dialog: MatDialog,
        private translate: TranslateService,
        private projectservice: ProjectService,
        private translationLoader: FuseTranslationLoaderService) {
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
        this.Requisition = this.data.Requisition;
        this.projectNumber = this.data.sagNumber;
        this.requitionNum = this.data.requisitionNumber;
    }

    selectProjectEventHandler(event) {
        var projectId = event.sagId;
        var message = ""; var message1 = ""; var message2 = "";
         this.translate.get("HEADER.MoveRequisition").subscribe({
             next: (res) => {
                 message1 = res;
             },
             error: err => {
             }
         });
         this.translate.get("FIELDS.To").subscribe({
             next: (res) => {
                 message2 = res;
             },
             error: err => {
             }
         });
        message = message1 + ' ' + this.projectNumber
            + ' - ' + this.requitionNum + ' - '
            + this.Requisition.departmentAddress + ' '
            + this.Requisition.departmentZipCode + ' '
            + this.Requisition.departmentCity + ' '
            + message2 + ' ' + this.projectNumber
         const dialogRef = this.dialog.open(AlertMessageModelComponent, {
             height: "200px",
             data: { Header: message }
         })

         dialogRef.afterClosed().subscribe(result => {
             if (result) {
                 this.MoveRequisition(this.Requisition.id, projectId);
             }
         });
    }

    MoveRequisition(id, projectId) {
        this.projectservice.MoveRequisitionToSelectedProject(id, projectId).subscribe(
            (response: ResponseBase) => {
                if (response) {
                    this.dialogRef.close(true);
                }
            },
            error => (err) => {

            });
    }
}