export const locale = {
  lang: 'en',
  data: {
    'STATUS': {
      'InActive': 'InActive',
      'Inside': 'Inside',
      'OutSide': 'OutSide',
      'None': 'None',
    },
    // 'selectedValueStatus': {
    //   'value1': 'Must be contacted',
    //   'value2': 'Scheduled visit',
    //   'value3': 'Do not answer',
    //   'value4': 'Offers sent',
    //   'value5': 'Offer approved',
    //   'value6': 'Planned',
    //   'value7': 'Offer rejected',
    //   'value8': 'Started',
    //   'value9': 'Performed',
    //   'value10': 'Billed',
    //   'value11': 'Pending',
    //   'value12': 'Ready billing',
    // },
    'selectedValueIndoorOutdoorStatus': {
      'value1': 'Indoor ',//Man in a room
      'value2': 'Outdoor', //Sun
      'value3': 'Spray Painting',//spraypaint
      'value4': 'Complaint', //Reklamation
      'value5': 'Staff purchase', //drawing of painter
      'value6': 'Store purchase' //Nordsjø logo
    },
    'FIELDS': {
      "TotalInclVAT": "Total incl. VAT"
    },
    'TOOTIPORTITLE':{
      'GoToPDFEmail':'Go To PDF/Email',
      'GoToQuestions':'Go To Questions',

    }
  }
};
