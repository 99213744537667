export const locale = {
    lang: 'ds',
    data: {
        'Headers': {
            'NewMaterial': 'Nyt Materialer',
            'AddMaterial': 'Ønsker du at tilføje materialer?',
            'DeleteMaterial': 'Vil du slette disse materialer?',
            'DeleteDevice': 'Vil du slette disse this enheds?',
            'Material': 'Materialer',
            'DeleteAlert': 'Er du sikker på du vil slette?',
            'AllRequisitionCoworker': 'Er du sikker på at Vil du gøre det på alle opgaver?',
            'Yes': 'JA',
            'No': 'NEJ',
            'AskForSaveProjectData': 'Vil du gemme disse oplysninger?',
            'CopyCustomerDetails': 'Skal jeg hjælpe med med at hente informationer ?',
            'ChangeStatusToAllRequisition': 'Kan du ændre startet status til alle opgaver?',
            'UnassignCustomerFromInquiry': 'Er du sikker på, at du vil fjerne tildelingen af ​​kunden fra denne forespørgsel ?',
            'MovetoFinishFolder': 'Du sikker på, at du vil flytte denne fil til mappen færdig ?',
            'DeleteProjectFileAlert': 'Er du sikker på du vil slette filen?',
            'DeleteProjectWorkHours': 'Er du sikker på du vil slette Arbejdstimer?',
            'DeleteWorkHoursOnFuture': 'Er du sikker på, at du vil slette fremtidige arbejdstider på denne rekvisition ?',
        }
    }
};
