import { NgModule } from "@angular/core";
import { FlexLayoutModule } from '@angular/flex-layout';
import { FusePipesModule } from 'app/core/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@fuse/material.module';
import { CustomerProjectColorCodeComponent } from './customer-project-colorcode.component';
import { FilterDropdownModule } from '../filter-dropdown/filter-dropdown.module';

@NgModule({
    declarations: [CustomerProjectColorCodeComponent],
    imports: [
        CommonModule,
        MaterialModule,
        FlexLayoutModule,
        FusePipesModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        DatePickerModule,
        NgxMatSelectSearchModule,
        FilterDropdownModule
    ],
    exports: [CustomerProjectColorCodeComponent]
})
export class CustomerProjectColorCodeModule {

}