export const locale = {
    lang: 'en',
    data: {
        'FIELDS': {
            'CustomerReview': 'Customer Review',
            'NotAddCustomerReview': 'No Customers Review Added',
            'Answer': 'Answer',
            'Question': 'Question',
        },
    }
};
