import { Component, OnInit } from '@angular/core';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { environment } from 'environments/environment';
import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { AnswerStatusLogComponent } from 'app/main/shared-component/answer-status-log/answer-status-log.component';
import { MatDialog } from '@angular/material';
import { TranslateService } from "@ngx-translate/core";
import { ResponseBase } from 'app/main/model/ResponseBase';
import { RequestCommonBase } from 'app/main/model/RequestBase';
import { SettingsService } from "app/main/pages/settings/settings.service";
import { NotificationsService } from "angular2-notifications";


@Component({
  selector: 'app-coworker-offer-review-answer',
  templateUrl: './coworker-offer-review-answer.component.html',
  styleUrls: ['./coworker-offer-review-answer.component.scss']
})
export class CoworkerOfferReviewAnswerComponent implements OnInit {
  showSpinner: boolean = false;
  host: string;
  requestModel: RequestCommonBase = new RequestCommonBase();
  offerRejectedQuestionList = [];
  offerRejectedAnswerList = [];
  constructor(private translationLoaderService: FuseTranslationLoaderService,
    private dialog: MatDialog,private settingsService: SettingsService,
    private translate: TranslateService,
    private notificationsService: NotificationsService) {  
    this.translationLoaderService.loadTranslations(danish, english) }

    ngOnInit() {
      this.host = environment.url;
      this.getCoworkerOfferReviewAnswer();
  }

  getCoworkerOfferReviewAnswer() {
      this.showSpinner = true;
      this.settingsService.GetOfferRejectedAnswerList<ResponseBase>(this.requestModel).subscribe({
          next: (response: ResponseBase) => {
              this.showSpinner = false;
              if (response != null && response.result) {
                  this.offerRejectedQuestionList = response.result.offerRejectedQuestionList;
                  this.offerRejectedAnswerList = response.result.offerRejectedAnswerList;
              }
              else {
                  this.offerRejectedQuestionList = [];
              }
          },
          error: err => {
              this.showSpinner = false;
              this.offerRejectedQuestionList = [];
          },
          complete: () => { }
      });
  }


  receivePaginatonEvent(event) {
      this.requestModel.Index = event.pageIndex;
      this.requestModel.Limit = event.pageSize;
      this.getCoworkerOfferReviewAnswer();
  }

  openAnswer(question) {
      question.moduleName="coworker";
      const dilogRef = this.dialog.open(AnswerStatusLogComponent, {
          height: "70%",
          width: "70%",
          data: question
      })
      dilogRef.afterClosed().subscribe(result => {
          if (result) {

          }
      });
  }
  onImgError(event) { 
      event.target.src = 'assets/images/vibe-images/user-profile/default_profile_picture.jpg';
  }

}