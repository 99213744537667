export const locale = {
    lang: 'ds',
    data: {
      'STATUS': {
        'Created': 'lavet',
        'Updated': 'Opdateret',
        'Deleted': 'Slettet',
        'FileUploaded': 'Filer uploadet',
        'FileDeleted': 'Filer slettet',
      },
      'TITLE': {
        'CustomerPagination': 'Antal log pr. side',
      },
      'BUTTONS': {
        'BACK': 'Gå tilbage',
        'BTNSAVE': 'Gem',
      },
      'FIELDS': {
        'Detail': 'Detalje',
        'ChangedDetail': 'Ændret detalje',        
        'ItemNo': 'Vare nummer',
        'Number': 'Beholdning',
        'Text': 'Tekst',
        'Linetype': 'Linjetype',
        "Materialtype": 'Vare type',
        'RetailPrice': 'Udsalgspris',
        'PurchasePrice': 'Indkøbspris',
        'Unit': 'Enhed',
        "WeatherType": "Vejrtype",
        'ShelfNo': 'Hylde nr.',
        'Rows': 'Række',
        'InternalText': 'Intern tekst',
        'ColorCode': 'Farvekode',
        'PersonalPrice': 'Personale pris',
        'PurchaseQuantity': 'Indkøbsmængde',
        'Supplier': 'Leverandør',
        'SupplierMaterialNo': 'Leverandør varenummer',
        'MinInventory': 'Min Beholdning',
        'MaxInventory': 'Max Beholdning',
        'Reminder': 'Påmindelses',
        'ModifyBy': 'Modificere Af',
      },
      'Headers': {
        'CustomerLogDetails': 'Materialer log detaljer'
      },
      'MESSAGES': {
        'NORECORD': 'Ingen registrering fundet',
      },
      'selectedValueIndoorOutdoorStatus': {
        'value1': 'Indendørs ',
        'value2': 'Udendørs',
        'value3': 'Sprøjtemaling',
        'value4': 'Reklamation',
        'value5': 'Personale køb', //drawing of painter
        'value6': 'Butiks køb'     //Nordsjø logo
      },
      'linetype': {
        'value1': 'Løn',
        'value2': 'Tekst',
        'value3': 'Vare'
      },
    }
  };
  