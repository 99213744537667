export const locale = {
    lang: 'en',
    data: {
        'TITLE': {
            'Holiday': 'Holiday',
        },
        'FIELDS': {
            'Date': 'Date',
            'Desc': 'Description',
            'Action': 'Actions',
        },
        'VALIDATIONS': {
            'Date': 'Holiday Date Required',
            'Desc': 'Holiday Description Required',
            'WhiteSpaceNotAllowed':'Only white space not allowed'
        },
        'MESSAGES': {
            'Created': 'Created successfully.',
            'Updated': 'Updated successfully.',
            'Deleted': 'Deleted successfully.',
            'Failed': 'Something went wrong !',
            'NoRecords': 'No records found !'
        },
        'BUTTONS': {
            'Add': 'Add',
            'Update': 'Update',
            'Save': 'Save',
            'Delete': 'Delete',
            'Edit': 'Edit',
            'Cancel': 'Undo',
        },
        'TOOTIPORTITLE':{
            'Edit':'Edit',
            'Delete':'Delete',
        }
    }
};