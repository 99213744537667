export const locale = {
    lang: 'ds',
    data: {
        'BUTTONS': {
            'Save': 'Gem',
            'BACK': 'Tilbage',
            'Reset': 'Nulstil'
        },
        'MESSAGES': {
            'Updated': 'Opdateret succesfuldt.',
            'Failed': 'Mislykkedes',
            'Deleted': 'Slettet med succes',
            'NORECORD': 'Intet optage fundet',
            'Created': 'Oprettet med succes',
        },
        'Validation': {
            'DesignationRequired': 'Betegnelse er påkrævet',
            'ValideFromRequired': 'Gyldig fra dato er påkrævet',
            'HourlyRateRequired': 'Pris er påkrævet',
            'WeekdayRequired': 'Ugedag er påkrævet',
            'TypeRequired': 'Type er påkrævet',
            'WhiteSpaceNotAllowed':'Kun hvidt mellemrum er ikke tilladt',
            'StartDate': 'Startdato er påkrævet',
            'EndDate': 'Slutdato er påkrævet',
            'TermsofEmployment': 'Ansættelsesvilkår ID er påkrævet',
            'OfSalary': 'Aflønning er påkrævet',

        },
        'Headers': {
            'Title':'Ansættelses',
            'DeleteMessage': 'Er du sikker på, at du vil slette dette',
            'Rules':'Satser',
        },
        'FIELDS': {
            'SrNo': 'Sr-nr.',
            'StartDate': 'Start dato',
            'EndDate': 'SlutDato',
            'Type': 'Type',
            'OfSalary': 'Aflønning',
            'BasicSalary': 'Grundløn',
            'BasicSalaryHours': 'Grundløn timer',
            'PersonalSupplement': 'Personligt tillæg(pr. time)',
            'TermsofEmploymentID': 'Vilkår for ansættelses-ID',
            'MeetingAllowance': 'Mødegodtgørelse(pr. time)',
            '14Day':'14 dage',
            'Month':'Måned'
        },
        'employmentType': {
            'valueA': 'A Vagtplan altid',
            'valueC': 'C Vagtplan hverdage',
            'valueD': 'D Kun afregning efter lønindtastning',
        },
        'FILTERS': {
            'All': 'Alle',
        },
        'selectedValueWeekDays': {
            'value1': 'Mandag',
            'value2': 'Trisdag',
            'value4': 'Onsdag',
            'value8': 'Torsdag',
            'value16': 'Fredag',
            'value32': 'lørdag',
            'value64': 'Søndag'
          }
    }
};