export const locale = {
    lang: 'en',
    data: {
        'TITLE': {
            'HeaderForOfferAcceptedNotification': 'Notification for customer on offer is approved',
        },
        'FIELDS': {
            'HoursToSend': 'Hours to send',
            'DaysToSend':  'Send x days before',
            'SMSText': 'SMS text',
            'NotificationText': 'Notification text',
            'EmailText': 'Email text',
            'ExecuteTime':'Execute Time',
            'IsCreateReminder':'Is Create Reminder?',
            'EmailTemplate ':'Email Template',
            'NotesLabel':"Do not delete a prefix '@' character word.",
        },
        'VALIDATIONS': {
            'HoursToSend': 'Hours to send is required',
            'DaysToSend': 'Days to send is required',
            'SMSText': 'SMS text is required',
            'NotificationText': 'Notification text is required',
            'EmailText': 'Email text is required',
            'ExecuteTime':'Execution time is required'
        },
        'MESSAGES': {
            'Saved': 'Saved successfully.',
        },
        'Label': {
            'OfferAcceptedNotificationLabel': 'Please do not remove @CustomerName and @OfferNumber from Notification text.',
        },
        'BUTTONS': {
            'Save': 'Save',
        }
    }
};