import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { MatDialog, MatOption, MatSlideToggleChange } from '@angular/material';
import { AuthService } from 'app/core/services/auth.service';
import { SettingsService } from '../../settings.service';
import { ResponseBase } from "app/main/model/ResponseBase";
import { Settings, SettingValue } from "app/main/model/Settings";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { CoWorker } from "app/main/model/CoWorker";
import { CoWorkersService } from "app/main/pages/coWorker/coWorkers.service";
import { NotificationsService } from "angular2-notifications";
import { TranslateService } from "@ngx-translate/core";
import { SettingEmailTempalteEditorComponent } from "../setting-email-tempalte-editor/setting-email-tempalte-editor.component";

@Component({
  selector: 'setting-offer-accepted-email-template',
  templateUrl: './offer-accepted-email-template.component.html',
  styleUrls: ['./offer-accepted-email-template.component.scss']
})
export class OfferAcceptedEmailTemplateComponent implements OnInit {
    @Input("commonNotificationSetting") commonNotificationSetting: Settings;
    @Input("CoWokers") CoWokers: CoWorker[];

    showSpinner: boolean = false;
    notificationForm: FormGroup;
    isSubmitted = false;
    coWokers: CoWorker[] = [];
    isGettingCoWokers: boolean = false;
    notificationsObj: Settings;
    settingMasterValues: any[] = [];
    settingValue: SettingValue = new SettingValue();

    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private fb: FormBuilder,
        private coWorkersService: CoWorkersService,
        private authService: AuthService,
        private settingsService: SettingsService,
        private translate: TranslateService,
        private notificationsService: NotificationsService,
        private dialog: MatDialog) {
        this.translationLoaderService.loadTranslations(danish, english);
    }

    ngOnInit() {
        this.settingMasterValues = Object.assign([], JSON.parse(localStorage.getItem("settingMasterValues")));
        this.notificationForm = this.fb.group({
            CoworkersID: [null, Validators.required],
            IsCreateReminder: [false],
            IsSendSMS: [false],
            IsSendNotification: [false],
            IsSendEmail: [false],
            SMSText: [null],
            NotificationText: [null],
            IsEnable: [true]
        });
        this.setSettings();
    }
    onSubmit() {
        this.notificationForm.markAllAsTouched();
        this.isSubmitted = true;
        if (this.notificationForm.valid) {
            this.changeSettings()
        }
    }

    setSettings() {
        if (this.commonNotificationSetting && this.commonNotificationSetting != null) {
            this.notificationsObj = this.commonNotificationSetting;
            this.settingValue = JSON.parse(this.notificationsObj.value)
            this.notificationForm.patchValue(this.settingValue);
            this.notificationForm.controls['CoworkersID'].setValue(this.notificationForm.controls['CoworkersID'].value.split(','))
            this.changeInLocalStorage(this.notificationsObj.id, this.notificationsObj)
        }

    }

    IsEnableReminder(event: MatSlideToggleChange) {
        this.settingValue.IsEnable = event.checked;
        this.changeSettings();
    }

    setSettingsAfterUpdate() {
        this.settingsService.GetSettingsWithTemplateValue<ResponseBase>("Notification", this.commonNotificationSetting.key2, null).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.result && response.result.myResponse.length > 0) {
                    this.notificationsObj = response.result.myResponse[0];
                    this.settingValue = JSON.parse(this.notificationsObj.value)
                    this.notificationForm.patchValue(this.settingValue);
                    this.notificationForm.controls['CoworkersID'].setValue(this.notificationForm.controls['CoworkersID'].value.split(','))
                    this.changeInLocalStorage(this.notificationsObj.id, this.notificationsObj)
                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    changeSettings() {
        this.showSpinner = true;
        this.settingValue.CoworkersID = this.notificationForm.value.CoworkersID.toString();
        this.settingValue.IsCreateReminder = this.notificationForm.value.IsCreateReminder;
        this.settingValue.IsSendSMS = this.notificationForm.value.IsSendSMS;
        this.settingValue.IsSendNotification = this.notificationForm.value.IsSendNotification;
        this.settingValue.IsSendEmail = this.notificationForm.value.IsSendEmail;
        this.settingValue.SMSText = this.notificationForm.value.SMSText || '';
        this.settingValue.NotificationText = this.notificationForm.value.NotificationText || '';
        this.settingValue.IsEnable = this.notificationForm.value.IsEnable;
        this.notificationsObj.value = JSON.stringify(this.settingValue);

        this.settingsService.UpdateSettingWithTemplate<ResponseBase>(this.notificationsObj).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.result) {
                    this.notificationsObj = response.result;
                    if (this.settingValue.IsEnable)
                        this.setSettingsAfterUpdate();
                    this.translate.get("MESSAGES.Saved").subscribe({
                        next: (res) => {
                            this.notificationsService.create(res);
                        },
                        error: err => { }
                    });
                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinner = false;
                this.isSubmitted = false;
            }
        });
    }

    changeInLocalStorage(ID, new_obj) {
        if (this.settingMasterValues.find(obj => obj.id == ID)) {
            this.settingMasterValues.forEach((old_obj, index) => {
                if (old_obj.id == ID) {
                    this.settingMasterValues[index] = new_obj
                }
            })
        } else {
            this.settingMasterValues.push(new_obj)
        }
        localStorage.setItem("settingMasterValues", JSON.stringify(this.settingMasterValues));
    }

    EmailTemplateDialog() {
        var lableName = "Note.OfferAcceptedEmailTextLabel";
        const dialogRef = this.dialog.open(SettingEmailTempalteEditorComponent, {
            height: '90%',
            width: '90%',
            data: { settingValue: this.settingValue, IsOther: false, IsBday: false, IsSameDay: false, Note: lableName}
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result != null && result != '') {
                this.settingValue = result;
                this.onSubmit();
            }
        });
    }
}