import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../../../core/modules/shared.module';

import { VibeCompanyInfoComponent } from './companyInfo.component';
import { MapsAPILoader, AgmCoreModule } from '@agm/core';
import { CompanyInfoService } from './companyInfo.service';
import { environment } from '../../../../environments/environment';
import { CKEditorModule } from 'ng2-ckeditor';
import { OfferDrivingAmountComponent } from './component/offer-driving-amount/offer-driving-amount.component';
const routes = [
  {
    path: 'companyInfo',
    component: VibeCompanyInfoComponent
  }
];

@NgModule({
  declarations: [
    VibeCompanyInfoComponent,
    OfferDrivingAmountComponent
  ],
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    AgmCoreModule.forRoot({
        apiKey: environment.GooogleKey,
      libraries: ["places"]
    }),
    CKEditorModule
  ],
  exports: [
    VibeCompanyInfoComponent
  ],
  entryComponents:[
    OfferDrivingAmountComponent
  ],
  providers: [CompanyInfoService]
})

export class VibeCompanyInfoModule {
}
