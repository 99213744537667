import { NgModule } from "@angular/core";
import { StatisticComponent } from './statistic.component';
import { StatisticService } from './statistic.service';
import { DatePickerModule, CalendarModule } from '@syncfusion/ej2-angular-calendars';
//import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { SharedModule } from 'app/core/modules/shared.module';
import { RouterModule } from '@angular/router';
import { NgxMaskModule } from 'ngx-mask';
import { StatisticProjectComponent } from './component/statistic-project-component/statistic-project-component';
import { StatisticOfferComponent } from './component/statistic-offer-component/statistic-offer-component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartsModule } from 'ng2-charts';
import { StatisticQuestionsComponent } from './component/statistic-question/statistic-question.component';

const routes = [
    {
        path: 'statistics',
        component: StatisticComponent
    }
];

@NgModule({
    declarations: [
        StatisticComponent,
        StatisticProjectComponent,
        StatisticOfferComponent,
        StatisticQuestionsComponent
    ],
    imports: [
        DatePickerModule,
        //PDFExportModule,
        SharedModule,
        ChartsModule,
        
        NgxChartsModule,
        RouterModule.forChild(routes),
        NgxMaskModule.forRoot(),
        CalendarModule 
    ],
    entryComponents: [
        StatisticComponent,
        StatisticProjectComponent,
        StatisticOfferComponent,
        StatisticQuestionsComponent
    ],
    exports: [

    ],
    providers: [
        StatisticService
    ]
})

export class StatisticModule {

}