export const locale = {
    lang: 'ds',
    data: {
        'WORKHOUR': {
            'TITLE': 'Workhour',
            'DESC': 'Hej Workhour!'
        },
        'BUTTONS': {
            'BtnCancel': 'Gå tilbage',
            'BtnStart': 'Start',
            'BtnStop': 'Hold op',
            'BtnAdd': 'Tilføj',
            'SeeDailyReport': 'Se daglig rapport',
            'AddNOnWorkingHours': 'Tilføj ikke arbejdstid',
            'Reportingtime': 'Arbejds timer',
            'Project': 'Sag',
            'Report': 'Rapport',
        },
        'FIELDS': {
            'Coworker': 'Vælg Medarbejder',
            'SelectDay': 'Vælg dag',
            'Text': 'Tekst / Beskrivelse',
            'StartTime': 'Starttidspunkt',
            'BreakTime': 'Pause',
            'FinishTime': 'Slut tidspunkt',
            'TotalHours': 'I alt arbejdstimer',
            'Week': 'Uge',
            'Note': 'Venligst klik på visningstimer i kolonne (som.. 4.2, 2.0 ...) weekday wise, åbnes pop up for at tilføje starttid og sluttid for at indstille timer for dag.',
            'Noteheader': 'Bemærk',
            'GrandTotal': 'samlet beløb',
            'AddProject': 'Tilføj projekt',
            'Search-worker': 'Søg Medarbejder',
            'Not-Found-Worker': 'Medarbejder ikke fundet',
            'NO_RECORD_FOUND': 'Ingen arbejdstimer fundet',
            'TOTAL': 'i alt',
            'NonWorkingHours': 'Ikke arbejdstid',
            'WorkingHours': 'Arbejdstid',
            'TotalIncludingBoth': 'I alt inklusive begge',
            'Project': 'Sag',
            'Address': 'Adresse',
            'Timer': 'Se timer',
            'PlannedProject': 'Planlagt projekt',
            'UnPlannedProject': 'Ikke-planlagt projekt',
            'SRNO': 'Sr nr',
            'TimeOff': 'Afspacering til og med Sidste lukket løn periode.',
            'From': 'Fra',
            'To': 'til ',
            'StartDate': 'Startdato',
        },
        'WeekDays': {
            'Monday': 'Man.',
            'Tuesday': 'Tirs.',
            'Wednesday': 'Ons.',
            'Thursday': 'Tors.',
            'Friday': 'Fre.',
            'Saturday': 'Lør.',
            'Sunday': 'Søn.'
        },
        'selectedWorkHourStatus': {
            'value0': 'Ingen status',
            'value1': 'Ferie ',
            'value2': 'Syg',
            'value3': 'Feriefridag',
            'value4': 'Fridag m.løn',
            'value5': 'Fridag u.løn',
            'value6': 'Kursus/skole',
            'value7': 'Afspadsering',
            'value8': 'Andet',
            'value9': 'Tilbud'
        },
        'MESSAGES': {
            'CoworkerDeleted': 'Medarbejder slettet vellykket.',
            'CannotApplyWorkHours': 'Du har orlov du kan ikke anvende arbejdstid på @date',
            'CannotApplyLeave': 'Du har allerede anvendt arbejdstid på @date, du kan ikke bruge orlov.',
            'LastPaymentDate': 'Sidste betalingsdato'
        },
        'TOOTIPORTITLE': {
            'Edit': 'Redigere',
            'Delete': 'Slet',
        },
        'FILTERS': {
            'SORTING': 'Sorter efter'
        },
        'SORTBY':
      [
        {
          Text: 'Nummer (0-9)',
          Value: 'Number'
        },
        {
          Text: 'Nummer (9-0)',
          Value: 'Number DESC'
                },
                {
                    Text: 'Status (0-9)',
                    Value: 'Status'
                },
                {
                    Text: 'Status (9-0)',
                    Value: 'Status DESC'
                }
      ],
    },
}
