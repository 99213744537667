export const locale = {
  lang: 'en',
  data: {
    'Tabs': {
      'PROJECT': 'Projects',
      'PROJECTOFFER': 'Offer',
    },
    'BUTTONS': {
        'NewCarInformation': 'New Car Information',
      'BACK': 'Back'
    },
    'MESSAGES': {
        'NORECORD': 'No Car Information Found',
        'ParticulateFilter': 'Is Add Filter',
        'Adblue': 'Is Diesel',
        'SummerTires': 'Summer tires',
        'WinterTires': 'Winter tires',
        'AllSeasonTires': 'All-season tires',
    },
    'TITLE': {
        'CarInformationPagination': 'Number of car information per page',
        'VehicleNo': 'Vehicle Number',
    },
    'FILTERS': {
      'TEXTSEARCH': 'Search',
      'SORTING': 'Sort By',
      'Active': 'Include all Inactive',
      'BADGE': 'Inactive',
      'Deleted': 'Show Deleted',
    },
    'SORTBY':
      [
        {
          Text: 'Number (0-9)',
          Value: 'Number'
        },
        {
          Text: 'Number (9-0)',
          Value: 'Number DESC'
        },
      ]
  }
};
