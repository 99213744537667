import { Component, OnInit, Inject, ChangeDetectorRef } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { FuseTranslationLoaderService } from '../../../../../core/services/translation-loader.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
    selector: 'sms-email-selection',
    templateUrl: './sms-email-selection.component.html',
    styleUrls: ['./sms-email-selection.component.scss']
})

export class SMSEmailSelectionComponent implements OnInit {
    showSpinner: boolean = false;
    tempMessage:string ="";
    message: string = "";
    sendType: number = 0;
    selectionObject: any= {
        message: "",
        sendType: 0
    }
    constructor(private translate: TranslateService,
        private translationLoader: FuseTranslationLoaderService,
        private notificationsService: NotificationsService,
        private dialog: MatDialog,
        public dialogRef: MatDialogRef<SMSEmailSelectionComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _changeDetectorRef: ChangeDetectorRef,
        private fb: FormBuilder) {
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
        this.tempMessage = this.data.message;
    }

    sendSMSEmail(type){
        this.sendType = type;
        this.selectionObject.message = this.message;
        this.selectionObject.sendType = this.sendType;
        if(type == 0)
            this.dialogRef.close(this.selectionObject);
        else
            this.message = this.tempMessage;
    }

    gotoPlaning(){
        this.selectionObject.message = this.message;
        this.dialogRef.close(this.selectionObject);
    }
    
    closedPlaning(){
        this.dialogRef.close(null);
    }
}