import { Component, OnInit, Inject } from '@angular/core';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'report-view-answer-coworkers',
    templateUrl: './view-answer-coworkers.component.html',
    styleUrls: ['./view-answer-coworkers.component.scss']
})
export class ViewAnswerCoworkersComponent implements OnInit {
    Header: string = "";
    pieChartData: [] = [];
    questionText: string =""
    constructor(private translationLoader: FuseTranslationLoaderService,
        public dialogRef: MatDialogRef<ViewAnswerCoworkersComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.translationLoader.loadTranslations(english, danish);

    }

    ngOnInit() {
        this.Header = this.data.Header;
        this.pieChartData = this.data.pieChartData; 
        this.questionText = this.data.questionText; 
    }

    onCloseDialog(e) {
        this.dialogRef.close(e ? true : false)
    }

    addManually(value) {
        this.dialogRef.close(value);
    }

    ngAfterViewInit() {
    }

}
