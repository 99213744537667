//#region Imports 
    // angular 
    import { Component, OnInit, Output, EventEmitter, Input, ChangeDetectorRef } from "@angular/core";
    import { MatDialog } from '@angular/material';
    // model
    import { OfferQualityTestQuestionAnswer } from "app/main/shared-component/new-questions/new-question.model";
    // third party packages
    import { BehaviorSubject } from 'rxjs';
    // interfaces
    import { locale as english } from '../../translate/en';
    import { locale as danish } from '../../translate/ds';
    // services
    // component
    import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';
    // third party tool
    // app-core
    import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
//#endregion Imports 


@Component({
    selector: "vibe-list-questions",
    templateUrl: "./list-questions.component.html",
    styleUrls: ["./list-questions.component.scss"]
})

export class ListQuestionsComponent implements OnInit {
//#region Initializations

  //#region @Input
  private _QuestionList = new BehaviorSubject<OfferQualityTestQuestionAnswer[]>(null);
  @Input()
  set QuestionList(value) {
      this._QuestionList.next(value);
  };
  get QuestionList() {
      return this._QuestionList.getValue();
  }

  private _OnCancelEditableForm = new BehaviorSubject<boolean>(false);
  @Input()
  set OnCancelEditableForm(value) {
      this._OnCancelEditableForm.next(value);
  };
  get OnCancelEditableForm() {
      return this._OnCancelEditableForm.getValue();
  }
  //#endregion @Input
  
  //#region @Output
  @Output("EditableQueIndex") EditableQueIndex: EventEmitter<string> = new EventEmitter();
  @Output("DeletableQueIndex") DeletableQueIndex: EventEmitter<string> = new EventEmitter();
  //#endregion @Output

  //#region @ViewChild
  //#endregion @ViewChild

  //#region Form
  //#endregion Form

  //#region Variables
  selectedQueIndex = -1;
  showSpinner: boolean = false;
  questionAnswerList: OfferQualityTestQuestionAnswer[] = [];
  //#endregion Variables

    constructor(
        private translationLoaderService: FuseTranslationLoaderService,
        private dialog: MatDialog,
        private cdr: ChangeDetectorRef,
    ) {
        this.translationLoaderService.loadTranslations(danish, english);
    }
//#endregion  Initializations



//#region Private/Helper Methods
//#endregion Private/Helper Methods



//#region Public Methods
    Edit(i) {
        this.selectedQueIndex = i;
        this.EditableQueIndex.emit(i);
    }
    Delete(i) {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: "200px",
            data: { Header: 'Headers.DeleteQuestion' }
        })

        dialogRef.afterClosed().subscribe(result => {
            if (result) {                
                this.DeletableQueIndex.emit(i);
            }
        });
    }
//#endregion Public Methods



//#region Lifecycle Hooks
    ngOnInit() {
        this._QuestionList
        .subscribe(list => {
            this.questionAnswerList = Object.assign([], list);
        });

        this._OnCancelEditableForm
        .subscribe(evt => {
            if(evt) {
                this.selectedQueIndex = -1;
            }
        })
    }
    ngAfterViewChecked() {
        this.cdr.detectChanges();
    }
    ngAfterViewInit() {
    }
    ngOnDestroy() {
    }
//#endregion Lifecycle Hooks
}