import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { Router } from '@angular/router';
import { InquiryService } from './inquiry.service';
import { RequestInquiry } from 'app/main/model/RequestBase';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { AuthService } from 'app/core/services/auth.service';
import { IsNumber } from 'app/core/Validators/validation';
import { TitleService } from 'app/core/services/title.service';
import { ReminderService } from 'app/main/pages/reminder/reminder.service';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';

@Component({
    selector: 'vibe-inquiryInfo',
    templateUrl: './inquiry.component.html',
    styleUrls: ['./inquiry.component.scss']
})
export class VibeInquiryComponent implements OnInit {
    @ViewChild("SearchInquiry", { static: false }) searchInquiry: ElementRef;
    auth: any;
    search: any = '';
    number: any = '';
    displayPagination: boolean;
    pagination: any;
    showSpinner: boolean = false;
    requestModel: RequestInquiry = new RequestInquiry();

    //Arrays to dispaly rows in datatable
    Inquiry: any[];
    useradmin: boolean = false;
    userkontor: boolean = false;
    usermaler: boolean = false;
    usergaest: boolean = false;
    userguest: boolean = false;
    accessgranted: boolean = false;
    savedata: boolean = false;
    data: any;
    // roleName: string;
    orderBy: string = "Text";
    limitNumber: any;
    // isValid: boolean = false;
    isActive: boolean = true;
    isDeleted: boolean = false;

    idleState = 'Not started.';
    timedOut = false;
    lastPing?: Date = null;
    TodaysDateTime: Date = new Date();
    settingMasterValues:any[] = [];
    roleClaims: any;
    //Initiators
    constructor(private translationLoader: FuseTranslationLoaderService,
        private _router: Router,
        private inquiryService: InquiryService, private authService: AuthService,
        private reminderService: ReminderService,
        private titleService: TitleService,
        private idle: Idle,
        private keepalive: Keepalive) {
        this.translationLoader.loadTranslations(english, danish);
        this.titleService.SetTitle("TITLE_INQUIRYS");
        this.roleClaims = this.authService.getRoleClaimsList();
        //this.idleAfterOneMin();
    }

    ngOnInit() {
        // this.roleName = this.authService.getUserRole();

        // if (this.roleName == "Admin") {
        //     this.isValid = true;
        // }
        // else if (this.roleName == "Kontor") {
        //     this.isValid = true;
        // }
        // else if (this.roleName == "Maler") {
        //     this.isValid = false;
        // }
        // else if (this.roleName == "Gaest") {
        //     this.isValid = false;
        // }
        // else {
        //     this.isValid = true;
        // }
        //this.requestModel = this.inquiryService.getRequestParameter();
        //this.isActive = this.requestModel.IsActive;
        //this.isDeleted = this.requestModel.IsDeleted;
        //this.search = this.requestModel.Name;
        //this.orderBy = this.requestModel.OrderBy;
        this.settingMasterValues = Object.assign([], JSON.parse(localStorage.getItem("settingMasterValues")));
        var tempSettingObject = this.settingMasterValues.find(s => s.key1 == "Menu" && s.key2 == "TabsListSettings").value;
        if(tempSettingObject && JSON.parse(tempSettingObject).find(x=>x.name=='inquiry').isInitialLoading)
            this.GetInquiry();
        this.TodaysDateTime = new Date();
        this.TodaysDateTime.setTime(this.TodaysDateTime.getTime() + (30 * 60 * 1000));
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.searchInquiry.nativeElement.focus();
        })
    }

    GetInquiry() {
        this.showSpinner = true;
        this.inquiryService.setRequestParameter(this.requestModel);
        this.inquiryService.Get<ResponseBase>(this.requestModel)
            .subscribe((response: ResponseBase) => {
                if (response != null) {
                    this.GetInquiryCount();
                    this.Inquiry = response.result;
                    this.pagination = response.pagination;
                    this.displayPagination = true;
                    this.showSpinner = false;
                }
                else {
                    this.Inquiry = [];
                    this.displayPagination = false;
                }
            },
                error => (err) => {
                    this.Inquiry = [];
                    this.displayPagination = false;
                    this.showSpinner = false;
                },
                () => {
                    this.showSpinner = false;
                });
    }

    GetInquiryCount() {
        this.reminderService.GetInquiryCountapi<ResponseBase>()
            .subscribe({
                next: (response: ResponseBase) => {
                    if (response != null) {
                      //this.reminderService.setInquiryCounts(response.result);
                      this.authService.setInquiryCount(response.result);
                    }
                },
                error: err => {
                },
                complete: (
                ) => {
                }
            })
    }

    getDeletedCustomers(isDeleted) {
        this.isDeleted = isDeleted;
        this.requestModel.IsDeleted = this.isDeleted;
        this.requestModel.Index = 1;
        this.GetInquiry()
    }

    checkAccess(id) {
        this.EditCustomer(id);
    }

    EditCustomer(id) {
        this._router.navigate(['/inquiry/edit', id],{ 
            state: { moduleDataList: this.Inquiry }
        }); //navigationExtras
    }

    receiveFilterEvent(event) {
        this.requestModel.OrderBy = event;
        if (this.limitNumber == undefined) {
            this.requestModel.Limit = 100
        }
        else {
            this.requestModel.Limit = this.limitNumber;
        }
        this.GetInquiry();
    }

    SearchByName(value) {
        if (value || value == "") {
            this.requestModel.Name = value.trim();
            //this.isActive = false;
            this.requestModel.IsActive = this.isActive;
            this.GetInquiry();
        }
    }

    receivePaginatonEvent(event) {
        this.requestModel.Index = event.pageIndex;
        this.requestModel.Limit = event.pageSize;
        this.limitNumber = event.pageSize;
        this.GetInquiry();
    }

    // isValidForm() {
    //     return this.isValid;
    // }
    isNumber(evt) {
        return IsNumber(evt);
    }

    onSetActive(active) {
        this.isActive = active;
        this.requestModel.IsActive = this.isActive;
        this.requestModel.Index = 1;
        //if (!this.isActive) {
            this.GetInquiry();
        //}
    }

    idleAfterOneMin() {
        // sets an idle timeout of 5 seconds, for testing purposes.
        this.idle.setIdle(10);
        // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
        this.idle.setTimeout(50);
        // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        this.idle.onIdleEnd.subscribe(() => this.idleState = 'No longer idle.');
        this.idle.onTimeout.subscribe(() => {
            this.idleState = 'Timed out!';
            this.timedOut = true;
            var currentDate = new Date;
            if (currentDate < this.TodaysDateTime) {
                this.requestModel = this.inquiryService.getRequestParameter();
                this.isActive = this.requestModel.IsActive;
                this.isDeleted = this.requestModel.IsDeleted;
                this.search = this.requestModel.Name;
                this.orderBy = this.requestModel.OrderBy;
                this.GetInquiry();
                this.reset();
            }
        });
        this.idle.onIdleStart.subscribe(() => this.idleState = 'You\'ve gone idle!');
        this.idle.onTimeoutWarning.subscribe((countdown) => this.idleState = 'You will time out in ' + countdown + ' seconds!');
        this.idle.getTimeout

        // sets the ping interval to 15 seconds
        this.keepalive.interval(5);
        this.keepalive.onPing.subscribe(() => this.lastPing = new Date());
        this.reset();
    }

    reset() {
        this.idle.watch();
        this.idleState = 'Started.';
        this.timedOut = false;
    }
}


