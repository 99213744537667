import { Component, OnInit } from "@angular/core";
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { TitleService } from 'app/core/services/title.service';
import { AuthService } from "app/core/services/auth.service";

@Component({
    selector: 'vibe-statistic',
    templateUrl: './statistic.component.html',
    styleUrls: ['./statistic.component.scss']
})

export class StatisticComponent implements OnInit {
    selectedIndex: number = 0;
    showSpinner = false;
    roleClaims: any;
    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private authService: AuthService,
        private titleService: TitleService) {
        this.translationLoaderService.loadTranslations(danish, english);
        this.titleService.SetTitle("TITLE_STATISTIC");
        this.roleClaims = this.authService.getRoleClaimsList();
    }

    ngOnInit() {

    }

    TabChange(event) {
        this.selectedIndex = event.index;
    }
}