import { Component, OnInit } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { Settings } from "app/main/model/Settings";
import { SettingsService } from 'app/main/pages/settings/settings.service';
import { ResponseBase } from "app/main/model/ResponseBase";

@Component({
  selector: 'app-list-view',
  templateUrl: './list-view.component.html',
  styleUrls: ['./list-view.component.scss']
})

export class ListViewComponent implements OnInit {
  showSpinner: boolean = false;
  selectProjectView: string;
  project: string[] = ['List View', 'Card View'];
  settingMasterValues: any[] = [];
  projectLitViewObj: Settings;

  constructor(private translationLoaderService: FuseTranslationLoaderService, private settingsService: SettingsService) { this.translationLoaderService.loadTranslations(danish, english) }

  ngOnInit() {
    this.settingMasterValues = Object.assign([], JSON.parse(localStorage.getItem("settingMasterValues")));
    this.getSettingsForProjectListView();
    const tempSettingObject = this.settingMasterValues.find(s => s.key1 === "ViewMode");
    if (tempSettingObject) {
      const valueObj = JSON.parse(tempSettingObject.value);
      if (valueObj[0].Value === "IsList") {
        this.selectProjectView = this.project[0];
      } else {
        this.selectProjectView = this.project[1];
      }
    }
  }

  getSettingsForProjectListView() {
    this.showSpinner = true;
    this.settingsService.GetSettingsValue<ResponseBase>("ViewMode", null, null).subscribe({
      next: (response: ResponseBase) => {
        this.showSpinner = false;
        if (response != null && response.result && response.result.myResponse.length > 0) {
          this.projectLitViewObj = response.result.myResponse[0];
          const valueArray = JSON.parse(this.projectLitViewObj.value);
          if (valueArray.length > 0) {
            const firstItem = valueArray[0];
            if (firstItem.Key === "Project" && firstItem.Value === "IsList") {
              this.selectProjectView = this.project[0];
            } else {
              this.selectProjectView = this.project[1];
            }
          }
        }
        else{
          this.selectProjectView = this.project[1];
        }
      },
      error: err => {
      },
      complete: () => {
        this.showSpinner = false;
      }
    });
  }

  addOrUpdateProjectView() {
    this.showSpinner = true;
    let viewValue: any = [];
    if (this.selectProjectView === this.project[0]) {
      viewValue = JSON.stringify([{ "Key": "Project", "Value": "IsList" }]);
    } else {
      viewValue = JSON.stringify([{ "Key": "Project", "Value": "IsCard" }]);
    }
    this.projectLitViewObj.value = viewValue;
    this.settingsService.UpdateSettingValue<ResponseBase>(this.projectLitViewObj).subscribe({
      next: (response: ResponseBase) => {
        this.showSpinner = false;
        if (response != null && response.result) {
          this.projectLitViewObj = response.result;
        }
        this.changeInLocalStorage(this.projectLitViewObj.id, this.projectLitViewObj);
      },
      error: err => {
        this.showSpinner = false;
      },
      complete: () => {
        this.showSpinner = false;
      }
    });
  }
  

  changeInLocalStorage(ID, new_obj) {
    if (this.settingMasterValues.find(obj => obj.id == ID)) {
      this.settingMasterValues.forEach((old_obj, index) => {
        if (old_obj.id == ID) {
          this.settingMasterValues[index] = new_obj
        }
      })
    } else {
      this.settingMasterValues.push(new_obj)
    }
    localStorage.setItem("settingMasterValues", JSON.stringify(this.settingMasterValues));
  }
}
