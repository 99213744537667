export const locale = {
    lang: 'ds',
    data: {
        'TITLE': {
            'OfferSMSConfiguration': 'SMS text når vi sender tilbud',
        },
        'FIELDS': {
            'SMSText': 'SMS tekst',
            'SmsNoteLabel':'Fjern venligst ikke @CustomerEmail fra SMS skabelon tekst.'
        },
        'VALIDATIONS': {
            'SMSText': 'SMS-tekst er påkrævet',
        },
        'MESSAGES': {
            'Saved': 'Gemt succesfuldt.',
        },
        'BUTTONS': {
            'Save': 'Gem',
        }
    }
};
