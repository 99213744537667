import { Component, OnInit, Inject } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatTableDataSource } from '@angular/material';
import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";

@Component({
    selector: "pending-material-order-list",
    templateUrl: "./pending-material-order-list.component.html",
    styleUrls: ["./pending-material-order-list.component.scss"]
})

export class PendingMaterialOrderListComponent implements OnInit {
    showSpinner: boolean = false;
    pendingMaterialOrderList: any;
    displayedColumns = ['isReceived','itemNumber', 'text', 'colorCode', 'qty', 'unit', 'purchasePrice', 'total'];
    dataSource: MatTableDataSource<any>;
    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private translateService: TranslateService,
        public dialogRef: MatDialogRef<PendingMaterialOrderListComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        translationLoaderService.loadTranslations(danish, english);
    }

    ngOnInit() {
        this.pendingMaterialOrderList = this.data;
    }
    
    close() {
        this.dialogRef.close(false);
    }

    getClass(value){
        var className="";
        if(value==1)
            className="blue-grey";
        else if(value==2)
            className="green";
        else if(value==3)
            className="accent";
        else if(value==4)
            className="warn";
        else 
            className="blue-grey";
        return className;
    }
}