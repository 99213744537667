export const locale = {
    lang: 'ds',
    data: {
        'MESSAGES': {
            //'Created': 'Created successfully',
            'ItemNumberExist': 'Nummeret eksisterer allerede',
        },
        'FIELDS': {
            'Material': 'Materiale',
            'RetailPrice': 'Udsalgs pris',
            'PersonalPrice': 'Personal Pris',
            'PersonalTotalPrice': 'Personal i alt',
            'Quantity': 'Antal',
            'ProductNo': 'Vare Nr',
            'OrderQuantity': 'Bestille Antal',
            'MinQuantity': 'Min Antal',
            'MaxQuantity': 'Max Antal',
            'Unit': 'Enhed',
            'PurchasePrice': 'Indkøbs Pris',
            'TotalPurchasePrice': 'I alt Indkøbs Pris',
            'Total': 'I alt',
            'Inventory': 'Beholding',
            'MinInventory': 'Min Beholding',
            'MaxInventory': 'Max Beholding',
            'PurchaseQuantity': 'Indkoebs maengde',
            'ShowAll': 'Vis alle',
            'ColorCode': 'Farve kode',
            'ProjectNumber': 'Sag Nummer',
            'RequisitionNo': 'Opgave nr.',
        },
        'BUTTONS': {
            'IncludeInactive': 'Inkluder Inaktiv',
            'ShowActive': 'Vis Aktiv',
        },
        'unitList': {
            'value1': 'stk.',
            'value2': 'Liter',
            'value3': 'Meter',
            'value4': 'Rulle',
            'value5': 'M2',
            'value6': 'KG'
        },'TOOTIPORTITLE':{
            'ActiveAll':'Aktive Alle',
            'IsActive':'Er Aktiv',
            'WeatherType':'Vejr Type',
            'GoToMaterial':'Gå Til Materiale',

        }
    }
};

