import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';

@Component({
    selector: 'vibe-CoWorker',
    templateUrl: './coWorker.component.html',
    styleUrls: ['./coWorker.component.scss']
})
export class CoWorkerComponent implements OnInit {
    coWokersFullData: any = [];
    selectedCoworkerId: string = "";

    // roleName: string;
    selectedcoWorkerIds: any = null;
    coWokerList: any = [];
    searchCoWokerList: any = [];
    IsGettingCoWorkers: boolean = false;
    //Initiators
    constructor(private translationLoader: FuseTranslationLoaderService,
        public dialogRef: MatDialogRef<CoWorkerComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
        // this.roleName = this.authService.getUserRole();
        this.searchCoWokerList = this.coWokerList = this.data.coWokersList;
    }

    async DropDownSearchCoWorker(searchCoworker) {
        this.IsGettingCoWorkers = true;
        this.searchCoWokerList = this.coWokerList.filter(o => o.name.toLowerCase().includes(searchCoworker.toLowerCase()));
        this.IsGettingCoWorkers = false;
    }

    onCloseDialog() {
        this.dialogRef.close(null)
    }

    onOkDialog() {
        this.dialogRef.close(this.selectedcoWorkerIds);
    }

}
