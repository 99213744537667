export const locale = {
    lang: 'en',
    data: {
      'Headers': {
          'ChooseFile': 'Choose File',
          'NoFileSelected': 'No File Selected',
          'BeforeImages': 'Before Images',
          'AfterImages': 'After Images',
          'AllImages': 'All Images'
      },
      'orderByStatus':{
        'value1':'Date ↑',
        'value2':'Date ↓'
      }
    }
  };
  