export const locale = {
    lang: 'en',
    data: {
        'STATUS': {
            'Created': 'Created',
            'Updated': 'Updated',
            'Deleted': 'Deleted',
            'Inactive': 'Inactive',
            'Active': 'Active',
            'Statuschane': 'Status change',
            'FileUploaded': 'File Uploaded',
            'FileDeleted': 'File Deleted',
        },
        'TITLE': {
            'CustomerPagination': 'Antal log pr. side',
        },
        'BUTTONS': {
            'BACK': 'Cancel',
            'BTNSAVE': 'Save',
        },
        'FIELDS': {
            'SRNO': 'Sr No',
            'Status': 'Status',
            'Number': 'Number',
            'Name': 'Name',
            'Createdby': 'Created By',
            'CreatedDate': 'Created Date',
            'ModifyBy': 'Modify By',
            'ModifyDate': 'Modify Date',
            'CoworkerName': 'Coworker',
            'Date': 'Date',
            'Description': 'Description',
            'Action': 'Action',
            'ModuleName': 'Module',
            'ByCoworker': 'by co-worker',
            'ByCustomer': 'by customer',
            'RequisitionNo': 'Requisition No'
        },
        'Headers': {
        },
        'Module': {
            'Project': 'Project',
            'Requisition': 'Requisition',
            'RequisitionMaterial': 'Requisition Material',
            'RequisitionCoworker': 'Requisition Coworker',
            'Offer': 'Offers',
            'Inquiry': 'Inquirie',
            'Email': 'Email',
            'StatusChangeAnswer': 'Status Change Answer',
            'OfferRoom': 'Offer room',
            'File': 'File',
            'BookMark':'Note',
            // 'Offer':'Offer',
            'MtoM':'M to M',
            'Vare':'Materials',
            
        },

        'MESSAGES': {
            'NORECORD': 'No Record Found',
            'PROJECTCREATED': '{@CoworkerName} has created new record.',
            'PROJECTUPDATED': '{@CoworkerName} has updated record.',
            'PROJECTDELETED': '{@CoworkerName} has deleted record.',
            'PROJECTINACTIVE': '{@CoworkerName} has inactive record.',
            'PROJECTACTIVE': '{@CoworkerName} has active record.',
            'STATUSCHANGED': '{@CoworkerName} has changed requisition status.',
            'STATUSCHANGEDBYCUSTOMER': 'Customer answer the status question',
            'FILEUPLOADED': '{@CoworkerName} has uploaded \'{@FileName}\' file.',
            'FILEDELETED': '{@CoworkerName} has removed \'{@FileName}\' file.',
            'CREATED': 'Record created',
            'UPDATED': 'Record updated'
        },
        'TOOTIPORTITLE':{
            'ViewEmailDetails':'View Email Details',
            'ViewAnswareStatusLog':'View Answare Status Log',
            'ViewCustomerDetails':'View Customer Details',
        },
        'FILTERS': {
            'SORTING_STATUS':'Module Name',
            'All':'All'
        }
    }
};


