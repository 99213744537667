
export const locale = {
    lang: 'en',
    data: {
        'MESSAGE': {
            'TITLE': 'Messages',
            'DESC': 'Hello Messages!'
        },
        'BUTTONS': {
            'REFRESH': 'Refresh',
        },
        'ArryMessage': [{
            'Empname': 'Cristina',
            'date': 'Yesterday',
            'Messages': [{
                'Person': 'Cristina',
                'message': 'Hello',
                'Time': '2:50 PM'
            }, {
                'Person': 'You',
                'message': 'Hi',
                'Time': '2:51 PM'
            }]
        }]
    }
};




//export const locale = {
//    lang: 'en',
//    data: {
//        'MESSAGE': {
//            'TITLE': 'Messages',
//            'DESC': 'Hello Messages!'
//        },
//        'BUTTONS': {
//            'REFRESH': 'Refresh',
//        },
//        'MessageDetails': [{
//            'ArryMessage': [{
//                'Empname': 'Cristina',
//                'date': 'Yesterday',
//                'Messages': [{
//                    'Person': 'Cristina',
//                    'message': 'Hello',
//                    'Time': '2:50 PM'
//                }, {
//                    'Person': 'You',
//                    'message': 'Hi',
//                    'Time': '2:51 PM'
//                }]
//            }, {
//                'Empname': 'Delfin sohas',
//                'date': '05.02.2018',
//                'Messages': [{
//                    'Person': 'Delfin sohas',
//                    'message': 'Are You Coming To Office?',
//                    'Time': '8:51 AM'

//                }, {
//                    'Person': 'You',
//                    'message': 'No, I am not able to come.',
//                    'Time': '9:00 AM'
//                }]
//            }, {
//                'Empname': 'Gyorge Henry',
//                'date': 'Yesterday',
//                'Messages': [{
//                    'Person': 'Gyorge Henry',
//                    'message': 'Hey , we will have lunch together today.',
//                    'Time': '3:59 PM'
//                }, {
//                    'Person': 'You',
//                    'message': 'Okay',
//                    'Time': '4:51 PM'

//                }]
//            }, {
//                'Empname': 'Herry',
//                'date': 'Today',
//                'Messages': [{
//                    'Person': 'Herry',
//                    'message': 'Did you completed your work?',
//                    'Time': '9:51 AM'
//                }, {
//                    'Person': 'You',
//                    'message': 'No, i will done it by Today.',
//                    'Time': '9:52 PM'
//                }, {
//                    'Person': 'You',
//                    'message': 'Somthing urgent???',
//                    'Time': '9:53 PM'
//                },
//                {
//                    'Person': 'Herry',
//                    'message': 'No, not yet',
//                    'Time': '9:59 PM'
//                }]
//            }]

//        }]

//    }
//};


