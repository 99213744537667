import { NgModule } from "@angular/core";
import { ToolsAndLaddersComponent } from './tools-and-ladders.component';
import { ToolsAndLaddersService } from './tools-and-ladders.service';
//import { DatePickerModule, CalendarModule } from '@syncfusion/ej2-angular-calendars';
//import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { SharedModule } from 'app/core/modules/shared.module';
import { RouterModule } from '@angular/router';
// import { NgxMaskModule } from 'ngx-mask';
// import { CustomCurrencyMaskConfig } from "app/core/constant/CurrencyMaskConfiguartion";
// import { NgxCurrencyModule } from "ngx-currency";
// import { NgxChartsModule } from '@swimlane/ngx-charts';
// import { ChartsModule } from 'ng2-charts';

const routes = [
    {
        path: 'toolsandladders',
        component: ToolsAndLaddersComponent
    }
];

@NgModule({
    declarations: [
        ToolsAndLaddersComponent,
    ],
    imports: [
        //DatePickerModule,
        //PDFExportModule,
        SharedModule,
        //ChartsModule,
        //NgxChartsModule,
        RouterModule.forChild(routes),
        // NgxMaskModule.forRoot(),
        // NgxCurrencyModule.forRoot(CustomCurrencyMaskConfig),
        //CalendarModule 
    ],
    entryComponents: [
        ToolsAndLaddersComponent,
    ],
    exports: [

    ],
    providers: [
        ToolsAndLaddersService
    ]
})

export class ToolsAndLaddersModule {

}