export const locale = {
  lang: 'en',
  data: {
    'Default': {
      'Loading': 'Loading...',
      'SelectControlPlaceholder': 'Select',
      'SearchControlPlaceholder': 'Search',
      'NotFoundMessage': 'Data not found',
      'ControlValRequired': 'Required',
    },
    'Coworker': {
      'Loading': 'Loading...',
      'SelectControlPlaceholder': 'Select Coworker',
      'SearchControlPlaceholder': 'Search Coworker',
      'NotFoundMessage': 'Coworker not found',
      'ControlValRequired': 'Coworker Required',
    },
    'Customer': {
      'Loading': 'Loading...',
      'SelectControlPlaceholder': 'Select Customer',
      'SearchControlPlaceholder': 'Search Customer',
      'NotFoundMessage': 'Customer not found',
      'ControlValRequired': 'Customer Required',
    },
    'Supplier': {
      'Loading': 'Loading...',
      'SelectControlPlaceholder': 'Select Supplier',
      'SearchControlPlaceholder': 'Search Supplier',
      'NotFoundMessage': 'Supplier not found',
      'ControlValRequired': 'Supplier Required',
    },
    'Material': {
      'Loading': 'Loading...',
      'SelectControlPlaceholder': 'Select Material',
      'SearchControlPlaceholder': 'Search Material',
      'NotFoundMessage': 'Material not found',
      'ControlValRequired': 'Material Required',
    },
  }
};
