export const locale = {
    lang: 'ds',
    data: {
        'QuestionTypeCategory': {
            'value1': 'Textbox',
            'value2': 'Multi Choice',
            'value3': 'Single Choice',
        },
        'BUTTONS': {
            'NEWPROJECTOFFER': 'Nyt Sag tilbud',
            'AddQuestion': 'Tilføj spørgsmål',
            'Save': 'Gem',
            'Cancel': 'Gå tilbage',
            'Text': 'Tekst'
        },
        'FIELDS': {
            'Question': 'Spørgsmål',
            'SrNo': 'Sr nr.',
            'Type': 'Type',
            'Answer': 'Svar'
        },
    }
}