import { Component, OnInit, ViewChild } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { ProjectService } from 'app/main/pages/project/project.service';

import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { RequestStataticsBase } from 'app/main/model/RequestBase';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { ProjectStatus } from 'app/constant/common.constant';
import { AnswerStatusLogComponent } from 'app/main/shared-component/answer-status-log/answer-status-log.component';
import { MatDialog , MatOption} from '@angular/material';
import { AuthService } from 'app/core/services/auth.service';
import { CoWorkersService } from '../../../coWorker/coWorkers.service';
import { RequestCoWorker, RequestStatusProject } from 'app/main/model/RequestBase';
import { ModuleType } from 'app/main/model/ProjectStatus';

@Component({
    selector: "statistic-question",
    templateUrl: "./statistic-question.component.html",
    styleUrls: ["./statistic-question.component.scss"]
})

export class StatisticQuestionsComponent implements OnInit {
    showSpinner: boolean = false;
    requestModel: RequestStataticsBase = new RequestStataticsBase();
    statisticQuestionList = [];
    pagination: any;
    limitNumber: any;
    projectStatusList = ProjectStatus;
    //roleName: string;
    coWokers: any = [];
    //showCoworkerSearch: boolean = true;
    currentselectedcoWorkerObj: any = {};
    selectedCoworkerId: string = "";
    coWorkerName: string = "";
    ModuleTypeList = ModuleType;
    @ViewChild('allSelected', { static: false }) private allSelected: MatOption;
    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private projectService: ProjectService,
        private authService: AuthService,
        private coWorkersService: CoWorkersService,
        private dialog: MatDialog) {
        this.translationLoaderService.loadTranslations(danish, english);
    }

    ngOnInit() {
        //this.roleName = this.authService.getUserRole();
        // if (this.roleName == "Maler") 
        //     this.showCoworkerSearch = false;
        this.getStatisticQuestions();
        let coWorker = new RequestCoWorker();
        coWorker.IsDeleted = false;
        //if (this.showCoworkerSearch) 
            this.getCoWorkers(coWorker);
    }

    getStatisticQuestions() {
        this.showSpinner = true;
        this.projectService.GetStatisticQuestions<ResponseBase>(this.requestModel).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.result) {
                    this.statisticQuestionList = response.result;
                    this.pagination = response.pagination;
                }
                else {
                    this.statisticQuestionList = [];
                }
            },
            error: err => {
                this.showSpinner = false;
                this.statisticQuestionList = [];
            },
            complete: () => { }
        });
    }

    getStatus(status) {
        var statusKey = this.projectStatusList.find(x => x.id == status).key;
        return statusKey;
    }

    receivePaginatonEvent(event) {
        this.requestModel.Index = event.pageIndex;
        this.requestModel.Limit = event.pageSize;
        this.limitNumber = event.pageSize;
        this.getStatisticQuestions();
    }

    openAnswer(question) {
        const dilogRef = this.dialog.open(AnswerStatusLogComponent, {
            height: "70%",
            width: "70%",
            data: question
        })
        dilogRef.afterClosed().subscribe(result => {
            if (result) {

            }
        });
    }

    SearchBynumber(value) {
        this.requestModel.Search = this.requestModel.Search.trim();
        this.getStatisticQuestions();
    }

    getCoWorkers(model) {
        model.Limit = 400;
        model.OrderBy = "Name";
        this.coWorkersService.Get<ResponseBase>(model)
            .subscribe((response: ResponseBase) => {
                if (response) {
                    this.getNextRequestForCoWorker(response.pagination, model, response.result);
                }
                else {
                    this.coWokers = [];
                }
            },
                error => (err) => {
                    this.coWokers = [];
                },
                () => {
                });
    }

    getNextRequestForCoWorker(pagination, model, response) {
        if (pagination.index == 1) {
            this.coWokers = response;
        }
        else {
            this.coWokers.push(...response);
        }
        model.Index = pagination.index + 1;
        model.Limit = pagination.limit;
        model.TotalRows = pagination.totalCount;
        if (pagination.totalCount > pagination.index * pagination.limit)
            this.getCoWorkers(model);
    }

    //for search
    DropDownSearchCoWorker(value) {
        var model = new RequestStatusProject();
        model.Index = 1;
        model.Name = value;
        this.getCoWorkers(model);
    }

    DropDownSearchCoWorkerOnClear(coWorkerName) {
        if (!coWorkerName) {
            this.getCoWorkers(new RequestStatusProject());
        }
    }

     //coworker multiselect code here
     toggleAllSelection() {
        if (this.allSelected.selected) {
            this.currentselectedcoWorkerObj.id = [];
            this.currentselectedcoWorkerObj.id.push(0);
            for (var i = 0; i < this.coWokers.length; i++) {
                this.currentselectedcoWorkerObj.id.push(this.coWokers[i].id);
            }
        } 
        else 
            this.currentselectedcoWorkerObj.id = [];
        
        var withoutAll = this.currentselectedcoWorkerObj.id.filter(o => o != 0);
        if (this.currentselectedcoWorkerObj.id.length <= 0) 
            this.selectedCoworkerId = "";
        else 
            this.selectedCoworkerId = withoutAll.join();
        this.requestModel.CoworkerIds = this.selectedCoworkerId;
        this.requestModel.Search = this.requestModel.Search.trim();
        this.getStatisticQuestions();
    }

    DropDownCoWorkerSelect(coworker) {
        this.currentselectedcoWorkerObj.id = this.currentselectedcoWorkerObj.id.filter(o => o != 0);
        if (this.currentselectedcoWorkerObj.id.length <= 0) 
            this.selectedCoworkerId = "";
        else 
            this.selectedCoworkerId = this.currentselectedcoWorkerObj.id.join();
        this.requestModel.CoworkerIds = this.selectedCoworkerId;
        this.requestModel.Search = this.requestModel.Search.trim();
        this.getStatisticQuestions();
    }

}