import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-project',
  templateUrl: './help-project.component.html',
  styleUrls: ['help-project.component.scss']
})
export class HelpProjectComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
