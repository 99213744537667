export const locale = {
    lang: 'ds',
    data: {
        'VALIDATIONS': {
            'EMAIL': 'Indtast gyldig e-mail.',
            'LIMIT': 'Indtast gyldig e-mail.',
            'EMAILEXIST': 'Emailen eksisterer allerede.',
            'to': 'E-mail påkrævet',
            'toName': 'Gyldigt navn',
            'from': 'E-mail påkrævet',
            'fromName': 'Gyldigt navn',
            'subject': 'Gyldigt emne',
        },
        'FIELDS': {
            'SRNO': 'Sr.',
            'Email': 'E-mail',
            'CONFIRM': 'Bekræft',
            'CANCEL': 'Ændre ikke',
            'IsIncludePriceOnPDF': 'Inkluderet Pris På PDF',
            'to': 'Email sendt til',
            'toName': 'Email sendt til navn',
            'from': 'Email sendt fra',
            'fromName': 'Email sendt fra navn',
            'subject': 'Emne',
            'coworker': 'Medarbejdere',
            'All': 'Alle',
            'AttachpdfFile': 'Vedhæft PDF-fil',
            'MaterialAttachedFiles': 'Materielle vedhæftede filer',
            'Files': 'Files',
            'ShowRoomTotal': 'Vis rum total'
        },
        'BUTTON': {
            'CANCEL': 'Cancel',
            'SendEmail': 'Email tilbud',
            'SendEmailWithRoomDetail': 'Email tilbud med rum',
            'SeeOffer': 'Se tilbud',
            'SeeOfferWithRoom':'Se tilbud med rum',
        }
    }
};


