import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { OfferAddressModel, OfferCategory, OfferCategoryMaterial, OffersMaterialDocument, OffersMaterialDocumentDetail } from 'app/main/model/ProjectOffer';
import { MatDialog, MatExpansionPanel } from '@angular/material';
import { ProjectofferService } from 'app/main/pages/project-offer/project-offer.service';
import { TitleService } from 'app/core/services/title.service';
import { RequestProjectOfferRoom } from 'app/main/model/RequestBase';
import { ActivatedRoute } from '@angular/router';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { OfferRoomPriceDTO, OfferTypeWithRoomPriceDetail, OfferMaterialWithPrice, OfferMeterWisePrice, DiverseDTO, MaterialList, OfferRoomCalculationWithMaterialList, OfferRoomCalculationDTO, OfferRoomDetailDTO, OfferRoomFullDetailDTO, OutdoorFileList } from 'app/main/model/OfferV2';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { FormService } from 'app/core/services/form.service';
import { OfferV2FormMaterialSelectForAllComponent } from '../component/material-select-for-all/material-select.component';
import { PdfViewerModelComponent } from 'app/main/pages/pdf-viewer/pdf-viewer.component';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';
import { NewMaterialComponent } from 'app/main/pages/material/new-material/new-material.component';
import { TextEditorComponent } from 'app/main/pages/text-editor/text-editor.component';
import { MaterialUnitList } from 'app/main/model/ProjectStatus';
import { CompanyInfoSetting, CompanyInfo } from 'app/main/model/CompanyInfo';
import { MaterialTypeCategory, PaintSelectTime, OffMaterialTypeIds, TreatmentTypeIds, OfferRoomCalculationType, OfferTreatmentType } from 'app/constant/common.constant';
import { CommonService } from 'app/core/services/common.service';
import { ApiConstant } from 'app/core/constant/ApiConstant';
import { Options } from '@angular-slider/ngx-slider';
import { EmailSendingParamForOffer } from 'app/main/model/common';
import { SendOfferEmailComponent } from '../../send-offer-email/send-offer-email.component';
import { TextareaEditorComponent } from '../component/textarea-editor/textarea-editor.component';
import { MaterialService } from 'app/main/pages/material/material.service';
import { GoogleMapDirectionComponent } from 'app/main/pages/google-map-direction/google-map-direction.component';
import { CompanyInfoService } from 'app/main/pages/companyInfo/companyInfo.service';
import { CustomersService } from 'app/main/pages/customer/customer.service';
import { CalculateDrivingDaysModalComponent } from '../component/calculate-driving-days/calculate-driving-days.component';
import { FolderListNavigationDTO, ListFolder, SearchFolderOrFiles } from 'app/main/model/DropBox';
import { DropboxFilesService } from 'app/main/shared-component/dropbox-files/dropbox-files.service';
import { SortPipe } from 'app/core/pipes/sort.pipe';
import { DriveModuleEnum } from 'app/core/enum/drive-module.enum';

@Component({
    selector: 'offerV2-form-a',
    templateUrl: './offerV2-form-a.component.html',
    styleUrls: ['./offerV2-form-a.component.scss']
})
export class OfferV2FormAComponent implements OnInit {
    showSpinner: boolean = false;
    @Input() selectedCategoryInput: OfferTypeWithRoomPriceDetail;
    @Input() customerDetailObject: any;
    @Output() DisplayList = new EventEmitter<any>();
    @Output() eventDisabledTabsFromOffer = new EventEmitter<any>();
    displayEditOfferCategory: boolean = false;
    requestProjectOfferRoom: RequestProjectOfferRoom = new RequestProjectOfferRoom();
    OfferTypeDetail: OfferTypeWithRoomPriceDetail = new OfferTypeWithRoomPriceDetail();
    projectOffer: OfferAddressModel = new OfferAddressModel();
    MaterialObject: OfferMaterialWithPrice[] = [];
    wallMaterialObject: OfferMaterialWithPrice[] = [];
    ceilingMaterialObject: OfferMaterialWithPrice[] = [];
    floorMaterialObject: OfferMaterialWithPrice[] = [];
    SelectCategoryTime = PaintSelectTime;
    MaterialTypeSearch = "";
    MasterMaterialTypeList = [];
    MasterOfferRoomList = [];
    MasterOfferRoomListOnEdit = [];
    TempfinalPrice: number = 0;
    OfferCategoryform: FormGroup;
    offerform: FormGroup;
    wallMeterWisePrice: OfferMeterWisePrice = new OfferMeterWisePrice();
    ceilingMeterWisePrice: OfferMeterWisePrice = new OfferMeterWisePrice();
    floorMeterWisePrice: OfferMeterWisePrice = new OfferMeterWisePrice();
    //selectCategoryTime = [{id:1, value:'1 Time'}, {id:2, value:'2 Time'}, {id:3, value:'3 Time'}]
    selectedRoomOnEdit: OfferRoomPriceDTO = new OfferRoomPriceDTO();
    isAddFloor: boolean = false;
    matObj: any = [];
    isDiverse: boolean = false;
    grandTotal: number = 0;
    DefaultMaterilaList: OfferMaterialWithPrice[] = [];
    selectUnitList = MaterialUnitList;
    MaterialTypeSearchForDiverse = "";
    MaterialObjectForDiverse: OfferMaterialWithPrice[] = [];
    offerCategoryMaterialList: OfferCategoryMaterial[] = [];
    roomList = [];
    roomName: string = "";
    type: number = 0;
    selectMaterialOfferList = MaterialTypeCategory;
    tempMaterialList = [];
    tempMaterialDetails: any = {};
    //OffersDescription: string = "";
    TotalM2ForWall = 0;
    TotalM2ForCeiling = 0;
    TotalM2ForFloor = 0;
    TotalForWall = 0;
    TotalForCeiling = 0;
    TotalForFloor = 0;
    TotalForAllRooms = 0;
    OfferNumber: number;
    //#region ngx-Slider start
    value: number = 0;
    options: Options = {
        floor: 0,
        ceil: 1500,
        showSelectionBar: true,
        getSelectionBarColor: (value: number): string => {
            if (value <= 300) {
                return 'red';
            }
            if (value <= 600) {
                return 'orange';
            }
            if (value <= 900) {
                return 'yellow';
            }
            if (value <= 1200) {
                return '#b2f3b1';
            }
            return '#2AE02A';
        }
    };
    isIncludePriceOnPDF: boolean = false;
    gulvCheckedCount: number = 0;
    offMaterialTypeIds = OffMaterialTypeIds;
    treatmentTypeIds = TreatmentTypeIds;
    offerRoomCalculationType = OfferRoomCalculationType;
    tempAssignedMaterialList = [];
    companyInfoData: CompanyInfo;
    // New Offer
    projectsOfferRooms: OfferRoomFullDetailDTO[] = [];
    meterWisePrice: OfferMeterWisePrice = new OfferMeterWisePrice();
    isGulvExits: boolean = false;
    isDisplayRoomDetail: boolean;
    projectPriceDiscount: number = 0;
    customerDetailObj: any;
    travelAmount: number = 0;
    distance: any;
    travelingTime: any;
    DistancePerDayValue: number;
    meterAndKmText: string = "km";
    isPerformanceTextAdded: number = 0;
    fileFolderPathData: Array<FolderListNavigationDTO> = [];
    public show: boolean = false;
    totalFileCount: number = 0;
    listFolder: ListFolder;
    isEditDrivingDays: boolean = false;
    offerTreatmentTypeList = OfferTreatmentType;
    isShowRoomTotal:boolean = false
    offerMaterialFilesAndContent: any;

    searchFileAndFolder: SearchFolderOrFiles;
    isSearch: boolean = false;
    searchValue: string = "";
    isDisplayOnlySearchFolder: boolean = false;
    outdoorFileList: OutdoorFileList[] = [];
    settingMasterValues:any[] = [];
    projectsCoworkerPrice: number = 0;
    paintTimer: number = 0;
    //#endregion

    constructor(private projectofferService: ProjectofferService,
        private translationLoader: FuseTranslationLoaderService,
        private _route: ActivatedRoute,
        public dialog: MatDialog,
        private translate: TranslateService,
        private notificationsService: NotificationsService,
        private formBuilder: FormBuilder,
        private formService: FormService,
        private commonService: CommonService,
        private titleService: TitleService,
        private materialService: MaterialService,
        private companyInfoService: CompanyInfoService,
        private customersService: CustomersService,
        private dropboxFilesService: DropboxFilesService,
        private sortPipe: SortPipe
    ) {
        this.translationLoader.loadTranslations(english, danish);

    }

    ngOnInit() {
        localStorage.removeItem(ApiConstant.LocalRequestModelForMaterialAll);
        this.settingMasterValues = Object.assign([], JSON.parse(localStorage.getItem("settingMasterValues")));
        var tempSettingObject = this.settingMasterValues.find(s => s.key1 == "Project" && s.key2 == "ProjectsCoworkerPrice").value;
        if(tempSettingObject)
            this.projectsCoworkerPrice = parseFloat(tempSettingObject);  
        else
            this.projectsCoworkerPrice = 580;
        this._route.params.subscribe(params => {
            var id = params['id'];
            if (id != undefined) {
                this.OfferTypeDetail.offerId = id;
                this.getOfferCategoryMaterials(this.selectedCategoryInput.offerCategoryId);
            }
        });
        if (this.OfferTypeDetail.offerId) {
            this.GetOffer();
        }
        this.getOfferMaterialData(true);
        this.OfferTypeDetail.offerCategoryId = this.selectedCategoryInput.offerCategoryId;
        this.OfferTypeDetail.name = this.selectedCategoryInput.name;
        this.OfferTypeDetail.formName = this.selectedCategoryInput.formName;
        this.isDisplayRoomDetail = false;//this.selectedCategoryInput.isShowRoomDetail;
        this.offerform = this.formBuilder.group({
            discount: [0, [Validators.required, Validators.minLength(0), Validators.max(99999999999)]]
        });
        this.GetCompanyInformation();
        this.getTravelingDistancePerDayValue();
    }

    // convenience getters for easy access to form fields
    // get f() { return this.OfferCategoryform.controls;}
    // get t() { return this.f.wallMaterialList as FormArray; }

    convertDigit() {
        var t = Number(this.OfferTypeDetail.price)
            .toLocaleString('en-DA', { style: 'currency', currency: 'DKK' })
            .replace('DKK ', '')
            .replace(/,/g, '.')
            .replace(/.([^.]*)$/, ',$1');
    }

    getProjectOfferRoomList(model, isEditMode) {
        this.projectofferService.getProjectOfferRoomList<ResponseBase>(model)
            .subscribe((response: ResponseBase) => {
                if (response != null) {
                    var ProjectsOfferRooms = response.result.myResponse;
                    //this.projectsOfferRooms = response.result.myResponse;
                    this.roomList = response.result.myResponse;
                    if (!isEditMode) {
                        this.projectsOfferRooms = [];
                        if (ProjectsOfferRooms) {
                            for (var i = 0; i < ProjectsOfferRooms.length; i++) {
                                var offerRoomCalculationList = [];
                                var offerRoomFullDetailDTO = new OfferRoomFullDetailDTO();
                                offerRoomFullDetailDTO.offerRoomId = ProjectsOfferRooms[i].id;
                                offerRoomFullDetailDTO.name = ProjectsOfferRooms[i].name;
                                offerRoomFullDetailDTO.totalPrice = 0;
                                offerRoomFullDetailDTO.isActive = true;

                                var offerRoomCalculationItem = new OfferRoomCalculationWithMaterialList();
                                offerRoomCalculationItem.length = ProjectsOfferRooms[i].length;
                                offerRoomCalculationItem.width = ProjectsOfferRooms[i].width;
                                offerRoomCalculationItem.ceilingHeight = ProjectsOfferRooms[i].ceilingHeight;
                                offerRoomCalculationItem.offerRoomDetailId = ProjectsOfferRooms[i].id;
                                offerRoomCalculationItem.extraQty1 = ProjectsOfferRooms[i].extraQty1 ? ProjectsOfferRooms[i].extraQty1 : 0;
                                offerRoomCalculationItem.extraQty2 = ProjectsOfferRooms[i].extraQty2 ? ProjectsOfferRooms[i].extraQty2 : 0;
                                offerRoomCalculationItem.extraPrice1 = ProjectsOfferRooms[i].extraPrice1 ? ProjectsOfferRooms[i].extraPrice1 : 0;
                                offerRoomCalculationItem.extraPrice2 = ProjectsOfferRooms[i].extraPrice2 ? ProjectsOfferRooms[i].extraPrice2 : 0;
                                offerRoomCalculationItem.extraM2QtyWall = ProjectsOfferRooms[i].extraM2QtyWall ? ProjectsOfferRooms[i].extraM2QtyWall : 0;
                                offerRoomCalculationItem.extraM2QtyCeiling = ProjectsOfferRooms[i].extraM2QtyCeiling ? ProjectsOfferRooms[i].extraM2QtyCeiling : 0;
                                offerRoomCalculationItem.extraM2QtyFloor = ProjectsOfferRooms[i].extraM2QtyFloor ? ProjectsOfferRooms[i].extraM2QtyFloor : 0;
                                offerRoomCalculationItem.extraM2PriceWall = ProjectsOfferRooms[i].extraM2PriceWall ? ProjectsOfferRooms[i].extraM2PriceWall : 0;
                                offerRoomCalculationItem.extraM2PriceCeiling = ProjectsOfferRooms[i].extraM2PriceCeiling ? ProjectsOfferRooms[i].extraM2PriceCeiling : 0;
                                offerRoomCalculationItem.extraM2PriceFloor = ProjectsOfferRooms[i].extraM2PriceFloor ? ProjectsOfferRooms[i].extraM2PriceFloor : 0;
                                offerRoomCalculationItem.isActive = true;
                                if (offerRoomCalculationItem.extraQty1 > 0 || offerRoomCalculationItem.extraQty2 > 0 || offerRoomCalculationItem.extraPrice1 > 0 || offerRoomCalculationItem.extraPrice2 > 0)
                                    offerRoomCalculationItem.isAddExtraWallQty = true;
                                for (var m = 1; m < 4; m++) {
                                    let tempOfferRoomCalculationItem = Object.assign({}, offerRoomCalculationItem);
                                    tempOfferRoomCalculationItem.materialList = [];
                                    var materialObj = new MaterialList();
                                    if (m == 1) {
                                        this.tempAssignedMaterialList = [];
                                        materialObj.offerMaterialTypeId = this.offMaterialTypeIds.WallType;
                                        this.tempAssignedMaterialList = this.MaterialObject.filter(o => { return o.type.split(',').find(p => p == materialObj.offerMaterialTypeId) });
                                        materialObj.materialList = this.tempAssignedMaterialList.map(x => Object.assign({}, x)); //this.MaterialObject.filter(o => { return o.type.split(',').find(p => p == materialObj.offerMaterialTypeId) });

                                        tempOfferRoomCalculationItem.typeId = m;
                                        tempOfferRoomCalculationItem.materialList.push(materialObj);
                                        tempOfferRoomCalculationItem.extraQty1 = tempOfferRoomCalculationItem.extraM2QtyWall;
                                        tempOfferRoomCalculationItem.extraPrice1 = tempOfferRoomCalculationItem.extraM2PriceWall;
                                        if (tempOfferRoomCalculationItem.extraQty1 > 0 || tempOfferRoomCalculationItem.extraPrice1 > 0)
                                            tempOfferRoomCalculationItem.isAddExtraWallQty = true;
                                        offerRoomCalculationList.push(tempOfferRoomCalculationItem);
                                    }
                                    if (m == 2) {
                                        this.tempAssignedMaterialList = [];
                                        materialObj.offerMaterialTypeId = this.offMaterialTypeIds.CeilingType;
                                        this.tempAssignedMaterialList = this.MaterialObject.filter(o => { return o.type.split(',').find(p => p == materialObj.offerMaterialTypeId) });
                                        materialObj.materialList = this.tempAssignedMaterialList.map(x => Object.assign({}, x));//this.MaterialObject.filter(o => { return o.type.split(',').find(p => p == materialObj.offerMaterialTypeId) });
                                        tempOfferRoomCalculationItem.typeId = m;
                                        tempOfferRoomCalculationItem.materialList.push(materialObj);
                                        tempOfferRoomCalculationItem.extraQty1 = tempOfferRoomCalculationItem.extraM2QtyCeiling;
                                        tempOfferRoomCalculationItem.extraPrice1 = tempOfferRoomCalculationItem.extraM2PriceCeiling;
                                        if (tempOfferRoomCalculationItem.extraQty1 > 0 || tempOfferRoomCalculationItem.extraPrice1 > 0)
                                            tempOfferRoomCalculationItem.isAddExtraWallQty = true;
                                        offerRoomCalculationList.push(tempOfferRoomCalculationItem);
                                    }
                                    // if(m == 3){
                                    //     materialObj.offerMaterialTypeId= this.offMaterialTypeIds.FloorType;
                                    //     tempOfferRoomCalculationItem.typeId = m;
                                    //     tempOfferRoomCalculationItem.materialList.push(materialObj);
                                    //     offerRoomCalculationList.push(tempOfferRoomCalculationItem);
                                    // }
                                }
                                offerRoomFullDetailDTO.offerRoomCalculation = offerRoomCalculationList;
                                this.projectsOfferRooms.push(offerRoomFullDetailDTO);

                            }
                            if (!this.selectedCategoryInput.id) {
                                this.OfferTypeDetail.offerRoomDetailList = [];
                                //this.OfferTypeDetail.offerDiverseDetailList = [];
                                this.OfferTypeDetail.offerRoomDetailList = this.projectsOfferRooms;
                            }
                            else
                                this.calculateRoomDifference();

                        }
                        this.OfferTypeDetail.offerRoomDetailList = this.projectsOfferRooms;
                    }
                    else
                        this.getOfferTypeData();
                }
                else {
                    this.OfferTypeDetail.offerRoomDetailList = [];
                    this.OfferTypeDetail.offerDiverseDetailList = [];
                    this.getOfferTypeData();
                }
            },
                error => (err) => {
                });
    }

    calculateRoomDifference() {
        if (this.OfferTypeDetail.offerRoomDetailList && this.MasterOfferRoomList) {
            for (let i = 0; i < this.MasterOfferRoomList.length; i++) {
                var offerRoom = this.OfferTypeDetail.offerRoomDetailList.find(o => o.offerRoomId == this.MasterOfferRoomList[i].offerRoomId)
                if (!offerRoom) {
                    this.OfferTypeDetail.offerRoomDetailList.push(this.MasterOfferRoomList[i]);
                }
            }
        }
    }

    saveAndUpdate(isCheckValidation) {
        var isMaterialTextForWallEmpty = 0;
        var isMaterialTextForCeilingEmpty = 0;
        var isMaterialTextForFloorEmpty = 0;
        this.isPerformanceTextAdded = this.OfferTypeDetail.offerDiverseDetailList.filter(x => !(x.text || x.materialID)).length;
        var wallCeilingFloorMaterialLists = this.OfferTypeDetail.offerRoomDetailList;
        for (let i = 0; i < wallCeilingFloorMaterialLists.length; i++) {
            if (wallCeilingFloorMaterialLists[i].wallMaterialList)
                isMaterialTextForWallEmpty = wallCeilingFloorMaterialLists[i].wallMaterialList.filter(a => (a.offerMaterialId && a.offerMaterialId != null) && (a.name == null || a.name == "")).length + isMaterialTextForWallEmpty;
            if (wallCeilingFloorMaterialLists[i].ceilingMaterialList)
                isMaterialTextForCeilingEmpty = wallCeilingFloorMaterialLists[i].ceilingMaterialList.filter(p => (p.offerMaterialId && p.offerMaterialId != null) && (p.name == null || p.name == "")).length + isMaterialTextForCeilingEmpty;
            if (wallCeilingFloorMaterialLists[i].floorMaterialList)
                isMaterialTextForFloorEmpty = wallCeilingFloorMaterialLists[i].floorMaterialList.filter(r => (r.offerMaterialId && r.offerMaterialId != null) && (r.name == null || r.name == "")).length + isMaterialTextForFloorEmpty;
        }
        if (this.isPerformanceTextAdded > 0) {
            this.translate.get("MESSAGES.SelectPerformanceMaterial").subscribe({
                next: (res) => {
                    this.notificationsService.error(res);
                },
                error: err => {
                }
            });
            return;
        } else if (isMaterialTextForWallEmpty > 0) {
            this.translate.get("MESSAGES.WallMaterialTextEmpty").subscribe({
                next: (res) => {
                    this.notificationsService.error(res);
                },
                error: err => {
                }
            });
            return;
        } else if (isMaterialTextForCeilingEmpty > 0) {
            this.translate.get("MESSAGES.CeilingMaterialTextEmpty").subscribe({
                next: (res) => {
                    this.notificationsService.error(res);
                },
                error: err => {
                }
            });
            return;
        } else if (isMaterialTextForFloorEmpty > 0) {
            this.translate.get("MESSAGES.FloorMaterialTextEmpty").subscribe({
                next: (res) => {
                    this.notificationsService.error(res);
                },
                error: err => {
                }
            });
            return;
        }
        if (!this.OfferTypeDetail.distance || !this.OfferTypeDetail.time) {
            this.OpenRoutePlanPopup(isCheckValidation);
        } else {
            this.save(isCheckValidation);
        }
    }

    save(isCheckValidation) {
        var textWallCeiling = '';
        var callSave = false;
        var WallRoomName = "";
        var CeilingRoomName = "";
        //this.formService.markFormGroupTouched(this.OfferCategoryform);
        callSave = true;//this.OfferCategoryform.valid;
        if (isCheckValidation == false) {
            var WithoutWall = this.OfferTypeDetail.offerRoomDetailList.find(o => o.paintWall == false);
            var WithoutCeiling = this.OfferTypeDetail.offerRoomDetailList.find(o => o.paintCeiling == false);

            if (this.OfferTypeDetail.offerRoomDetailList.length > 0) {
                for (let i = 0; i < this.OfferTypeDetail.offerRoomDetailList.length; i++) {
                    if (!this.OfferTypeDetail.offerRoomDetailList[i].paintWall) {
                        var name = this.OfferTypeDetail.offerRoomDetailList[i].name;
                        if (WallRoomName) {
                            WallRoomName = WallRoomName + ', ' + name;
                        } else {
                            WallRoomName = name;
                        }
                    }
                    if (!this.OfferTypeDetail.offerRoomDetailList[i].paintCeiling) {
                        var name = this.OfferTypeDetail.offerRoomDetailList[i].name;
                        if (CeilingRoomName) {
                            CeilingRoomName = CeilingRoomName + ', ' + name;
                        } else {
                            CeilingRoomName = name;
                        }
                    }
                }
            }
            //var Withoutfloor = this.OfferTypeDetail.offerRoomPriceDetailList.find(o => o.paintFloor == false);

            if (WithoutWall && WithoutCeiling) {
                textWallCeiling = 'wall&ceiling'; //Both without wall and ceiling
            } else if (WithoutWall) {
                textWallCeiling = 'wall'; //Both without wall 
            } else if (WithoutCeiling) {
                textWallCeiling = 'ceiling';  //Both without ceiling
            }
            //else if (Withoutfloor) {
            //    textWallCeiling = 'floor';
            //}
            //else if (!WithoutWall && !WithoutCeiling && !Withoutfloor) {
            else if (!WithoutWall && !WithoutCeiling) {
                //textWallCeiling = 'wall&ceiling';
                textWallCeiling = '';
            }
            // if (textWallCeiling && !this.OfferTypeDetail.id) {
            //     this.openPopUp(textWallCeiling, WallRoomName, CeilingRoomName);
            // } else {
            if (this.OfferTypeDetail.numberOfDays != this.OfferTypeDetail.numberOfDrivingDays) {
                this.CallNumberOfDrivingDaysPopup();
            } else {
                this.saveAndUpdateOffer(null);
            }
            //}
            return
        }
        if (callSave) {
            if (!this.selectedRoomOnEdit.paintWall && !this.selectedRoomOnEdit.paintCeiling) {
                textWallCeiling = 'wall&ceiling';
            }
            else if (!this.selectedRoomOnEdit.paintWall && this.selectedRoomOnEdit.offerRoomId) {
                textWallCeiling = 'wall';
            }
            else if (!this.selectedRoomOnEdit.paintCeiling && this.selectedRoomOnEdit.offerRoomId) {
                textWallCeiling = 'ceiling';
            }
            else if (!this.selectedRoomOnEdit.paintFloor && this.selectedRoomOnEdit.offerRoomId) {
                textWallCeiling = 'floor';
            }
            // if (textWallCeiling && !this.OfferTypeDetail.id) {
            //     this.openPopUp(textWallCeiling, WallRoomName, CeilingRoomName);
            // } else {
            if (this.OfferTypeDetail.numberOfDays != this.OfferTypeDetail.numberOfDrivingDays) {
                this.CallNumberOfDrivingDaysPopup();
            } else {
                this.saveAndUpdateOffer(null);
            }
            // }
        }
    }


    //Route plan popup
    OpenRoutePlanPopup(isCheckValidation) {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: "200px",
            data: { Header: 'Headers.RoutePlanHeader' }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.showDirection();
            } else {
                this.save(isCheckValidation);
            }
        })
    }

    openPopUp(text, wallRoomsName, CeilingRoomsName) {
        var fullMsg = "";
        var message = "";
        this.translate.get("HEADER.WallAndCeiling." + text).subscribe({
            next: (res) => {
                message = res.replace('@wallRoom', wallRoomsName);
                message = message.replace('@ceilingRoom', CeilingRoomsName);
                message = message.replace('.', '.' + '<br/>')
                fullMsg = message;

            }, error: err => { }
        })
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: "200px",
            data: {
                Header: fullMsg, //'HEADER.WallAndCeiling.' + text,
            }
        })

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                //if (this.OfferTypeDetail.priceTotal > 0) {
                //}
                if (this.OfferTypeDetail.numberOfDays != this.OfferTypeDetail.numberOfDrivingDays) {
                    this.CallNumberOfDrivingDaysPopup();
                } else {
                    this.saveAndUpdateOffer(null);
                }
            }
        });
    }

    saveAndUpdateOffer(value) {
        this.showSpinner = true;
        // for (let i = 0; i < this.OfferTypeDetail.offerRoomDetailList.length; i++) {
        //     // if (this.OfferTypeDetail.offerRoomDetailList[i].isNewDimension) {
        //     //     this.OfferTypeDetail.offerRoomDetailList[i].ceilingHeight = this.OfferTypeDetail.offerRoomDetailList[i].newCeilingHeight;
        //     //     this.OfferTypeDetail.offerRoomDetailList[i].wallLength = this.OfferTypeDetail.offerRoomDetailList[i].newLength;
        //     //     this.OfferTypeDetail.offerRoomDetailList[i].wallWidth = this.OfferTypeDetail.offerRoomDetailList[i].newWidth;
        //     // }
        //     if (this.OfferTypeDetail.offerDiverseDetailList.length > 0) {
        //         var data = this.OfferTypeDetail.offerDiverseDetailList.filter(x => x.roomId == this.OfferTypeDetail.offerRoomDetailList[i].offerRoomId);
        //         if (data.length > 0) {
        //             var tempPaneler = data.filter(x => x.type == this.offMaterialTypeIds.Paneler);
        //             var tempDoorFrame = data.filter(x => x.type == this.offMaterialTypeIds.Dorkarm);
        //             var tempRadiator = data.filter(x => x.type == this.offMaterialTypeIds.Radiator);
        //             var tempDore = data.filter(x => x.type == this.offMaterialTypeIds.Dore);
        //             if (tempPaneler.length > 0)
        //                 this.OfferTypeDetail.offerRoomDetailList[i].isPaintPanels = true;
        //             else
        //                 this.OfferTypeDetail.offerRoomDetailList[i].isPaintPanels = false;
        //             if (tempDoorFrame.length > 0)
        //                 this.OfferTypeDetail.offerRoomDetailList[i].isPaintDoorFrame = true;
        //             else
        //                 this.OfferTypeDetail.offerRoomDetailList[i].isPaintDoorFrame = false;
        //             if (tempRadiator.length > 0)
        //                 this.OfferTypeDetail.offerRoomDetailList[i].isPaintRadiator = true;
        //             else
        //                 this.OfferTypeDetail.offerRoomDetailList[i].isPaintRadiator = false;
        //             if (tempDore.length > 0)
        //                 this.OfferTypeDetail.offerRoomDetailList[i].isPaintDore = true;
        //             else
        //                 this.OfferTypeDetail.offerRoomDetailList[i].isPaintDore = false;
        //         }
        //     }
        //     // if(this.selectedRoomOnEdit && this.selectedRoomOnEdit.isExpand && this.OfferTypeDetail.offerRoomPriceDetailList[i].offerRoomId==this.selectedRoomOnEdit.offerRoomId)
        //     //     this.OfferTypeDetail.offerRoomPriceDetailList[i].wallMaterialList = this.t.value;
        // }
        this.projectofferService.SaveOfferItems(this.OfferTypeDetail).subscribe({
            next: (response) => {
                if (response) {
                    this.eventDisabledTabsFromOffer.emit(false);
                    this.OfferTypeDetail = response.result;
                    this.selectedCategoryInput.id = this.OfferTypeDetail.id;
                    //this.OfferTypeDetail.numberOfDrivingDays = this.OfferTypeDetail.numberOfDrivingDays ? this.OfferTypeDetail.numberOfDrivingDays : this.OfferTypeDetail.numberOfDays;
                    // for (var i = 0; i < this.OfferTypeDetail.offerRoomPriceDetailList.length; i++) {
                    //     var material = this.MaterialObject.find(o => o.id == this.OfferTypeDetail.offerRoomPriceDetailList[i].wallMaterialId);
                    //     if (material && material.offerMaterialPriceList.length > 0) {
                    //         var materialPrice = material.offerMaterialPriceList.find(o => o.id == this.OfferTypeDetail.offerRoomPriceDetailList[i].wallMaterialPriceId);
                    //         var selectionTra = this.SelectCategoryTime
                    //             .find(o => o.id == materialPrice.type);
                    //         this.OfferTypeDetail.offerRoomPriceDetailList[i].wallPaintKey = selectionTra.key;
                    //     }
                    //     material = this.MaterialObject.find(o => o.id == this.OfferTypeDetail.offerRoomPriceDetailList[i].ceilingMaterialId);
                    //     if (material && material.offerMaterialPriceList.length > 0) {
                    //         materialPrice = material.offerMaterialPriceList.find(o => o.id == this.OfferTypeDetail.offerRoomPriceDetailList[i].ceilingMaterialPriceId);
                    //         var selectionTraForCei = this.SelectCategoryTime
                    //             .find(o => o.id == materialPrice.type);
                    //         this.OfferTypeDetail.offerRoomPriceDetailList[i].ceilingPaintKey = selectionTraForCei.key;
                    //     }
                    //     material = this.MaterialObject.find(o => o.id == this.OfferTypeDetail.offerRoomPriceDetailList[i].floorMaterialId);
                    //     if (material && material.offerMaterialPriceList.length > 0) {
                    //         materialPrice = material.offerMaterialPriceList.find(o => o.id == this.OfferTypeDetail.offerRoomPriceDetailList[i].floorMaterialPriceId);
                    //         var selectionTraForFloor = this.SelectCategoryTime
                    //             .find(o => o.id == materialPrice.type);
                    //         this.OfferTypeDetail.offerRoomPriceDetailList[i].floorPaintKey = selectionTraForFloor.key;
                    //     }
                    // }
                    if (this.OfferTypeDetail.offerDiverseDetailList.length > 0) {
                        this.OfferTypeDetail.offerDiverseDetailList.forEach(item => {
                            if (item.materialID)
                                this.getMaterialPriceOnEdit(item.materialID, item, item.materialPriceID);
                            //this.getMaterialPrice(item.materialID, item, item.materialPriceID);
                        })
                        this.getDiverseMaterialOnEdit();
                        this.calculateDiversePrise();
                        this.getSumOfM2ForWallceilingFloor();
                        this.calculateTravelingAmt();
                        setTimeout(() => {
                            this.calTravelingAmtOnDrivingDaysChange();
                        }, 4000);
                        setTimeout(() => {
                            //this.OfferTypeDetail.numberOfDays = this.OfferTypeDetail.numberOfDrivingDays;
                            this.editTravelingTime();
                        }, 4500);
                        this.outdoorFileList = JSON.parse(localStorage.getItem("OutdoorCategoryImages"));
                        if (this.selectedCategoryInput.fileList && this.selectedCategoryInput.fileList.length) {
                            this.SetFileDetails();
                        }
                    }
                }
                if (value == undefined) {
                    this.translate.get("MESSAGES.Success").subscribe({
                        next: (res) => {
                            this.notificationsService.success(res);
                        },
                        error: err => {
                        }
                    });
                }
                // this.DisplayList.emit(this.selectedCategoryInput);

            },
            error: err => {
                this.showSpinner = false;
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            },
            complete: () => {
                this.showSpinner = false;
                if (value != null)
                    this.SavePDF(value);
            }
        });
    }

    getOfferTypeData() {
        this.projectofferService.getOfferTypeDataWithRoomDetail(this.selectedCategoryInput.offerId, this.selectedCategoryInput.id).subscribe({
            next: (response: ResponseBase) => {
                this.OfferTypeDetail.offerRoomDetailList = [];
                if (!this.OfferTypeDetail.offerDiverseDetailList) {
                    this.OfferTypeDetail.offerDiverseDetailList = [];
                }
                this.OfferTypeDetail = response.result;
                // for (var i = 0; i < this.OfferTypeDetail.offerRoomPriceDetailList.length; i++) {
                //     var selectionTra = this.SelectCategoryTime
                //         .find(o => o.id == this.OfferTypeDetail.offerRoomPriceDetailList[i].wallPaintType);
                //     if (selectionTra) {
                //         this.OfferTypeDetail.offerRoomPriceDetailList[i].wallPaintKey = selectionTra.key;
                //     }
                //     var selectionTraForCei = this.SelectCategoryTime
                //         .find(o => o.id == this.OfferTypeDetail.offerRoomPriceDetailList[i].ceilingPaintType);
                //     if (selectionTraForCei) {
                //         this.OfferTypeDetail.offerRoomPriceDetailList[i].ceilingPaintKey = selectionTraForCei.key;
                //     }
                //     var selectionTraForFloor = this.SelectCategoryTime
                //         .find(o => o.id == this.OfferTypeDetail.offerRoomPriceDetailList[i].floorPaintType);
                //     if (selectionTraForFloor) {
                //         this.OfferTypeDetail.offerRoomPriceDetailList[i].floorPaintKey = selectionTraForFloor.key;
                //     }
                // }
                if (this.OfferTypeDetail.offerDiverseDetailList.length > 0) {
                    // if (this.OfferTypeDetail.offerRoomDetailList && this.OfferTypeDetail.offerRoomDetailList.length > 0) {
                    //     this.editOffer(this.OfferTypeDetail.offerRoomDetailList[0].id, this.OfferTypeDetail.offerRoomDetailList[0].offerRoomId)
                    //     //this.getDiverseMaterialOnEdit();
                    // }
                    this.getDiverseMaterialOnEdit();
                    this.OfferTypeDetail.offerDiverseDetailList.forEach(item => {
                        item.roomName = "";
                        if (item.materialID)
                            this.getMaterialPriceOnEdit(item.materialID, item, item.materialPriceID);
                        //this.getMaterialPrice(item.materialID, item, item.materialPriceID);
                    })
                    this.calculateDiversePrise();
                }
                setTimeout(() => {
                    this.calTravelingAmtOnDrivingDaysChange();
                }, 4500);
                setTimeout(() => {
                    //this.OfferTypeDetail.numberOfDays = this.OfferTypeDetail.numberOfDrivingDays;
                    this.editTravelingTime();
                }, 4500);
                this.GetFileList();
                //this.calculateTravelingAmt();
                //this.calculateRoomDifference();

                // if (this.OfferTypeDetail.discount > 0) {
                //     this.calculateDiscountedAmt();
                // } else {
                //     this.OfferTypeDetail.discount = 0;
                //     this.calculateVatAmt();
                // }
                // this.getSumOfM2ForWallceilingFloor();
            },
            error: err => { },
            complete: () => {

            }
        });

    }

    getOfferMaterialData(isAddedDefault) {
        this.showSpinner = true;
        this.projectofferService.GetOfferCategoryMaterial().subscribe({
            next: (response: ResponseBase) => {
                //var matList = response.result.myResponse.filter(x => x.id == materialId).map(x => x.reminder).toString();
                this.MaterialObject = response.result.myResponse;
                this.wallMaterialObject = response.result.myResponse;
                this.ceilingMaterialObject = response.result.myResponse;
                this.floorMaterialObject = response.result.myResponse;
                this.MasterMaterialTypeList = response.result.myResponse;
                this.MaterialObjectForDiverse = response.result.myResponse;
                // if (response.result.myResponse) {
                //     this.DefaultMaterilaList = response.result.myResponse.filter(x => x.automaticallyCreate == 'T');
                //     setTimeout(() => {
                //         if (this.selectedCategoryInput.id == "")
                //             this.addDefaultMaterial();
                //     }, 250);
                //     if (this.selectedCategoryInput.id) {
                //         setTimeout(() => {
                //             this.getOfferTypeData();
                //         }, 250);
                //     }
                // }
                if (response.result.myResponse && isAddedDefault) {
                    this.DefaultMaterilaList = response.result.myResponse.filter(x => x.automaticallyCreate == 'T');
                    setTimeout(() => {
                        if (this.selectedCategoryInput.id == "") {
                            this.OfferTypeDetail.offerDiverseDetailList = [];
                            this.addDefaultMaterial();
                            this.requestProjectOfferRoom.OfferId = this.OfferTypeDetail.offerId;
                            this.getProjectOfferRoomList(this.requestProjectOfferRoom, null);
                            setTimeout(() => {
                                if (!this.OfferTypeDetail.id) {
                                    this.showDirection();
                                }
                            }, 1000);
                        }
                    }, 250);
                    if (this.selectedCategoryInput.id) {
                        setTimeout(() => {
                            this.requestProjectOfferRoom.OfferId = this.OfferTypeDetail.offerId;
                            this.getProjectOfferRoomList(this.requestProjectOfferRoom, true);
                        }, 250);
                    }
                }
                else {
                    this.refreshMaterialPrice();
                }
                this.showSpinner = false;
            },
            error: err => {
                this.showSpinner = false;
            },
            complete: () => {
                this.showSpinner = false;
            }
        });

    }

    editOffer(id, roomId) {
        this.showSpinner = true;
        this.selectedRoomOnEdit = new OfferRoomPriceDTO();
        setTimeout(() => {
            this.OfferTypeDetail.offerRoomDetailList.filter(off => {
                if (roomId == off.offerRoomId) {
                    off.isExpand = true;
                    if (off.offerRoomCalculation.length > 0) {
                        this.selectedRoomOnEdit.wallWidth = off.offerRoomCalculation[0].width;
                        this.selectedRoomOnEdit.wallLength = off.offerRoomCalculation[0].length;
                        this.selectedRoomOnEdit.ceilingWidth = off.offerRoomCalculation[0].width;
                        this.selectedRoomOnEdit.ceilingLength = off.offerRoomCalculation[0].length;
                    }
                }
                else
                    off.isExpand = false;
            });
            // var room = this.OfferTypeDetail.offerRoomPriceDetailList.find(o => o.offerRoomId == roomId);
            // if (!room.wallMaterialTypeId) {
            //     room.wallMaterialTypeId = room.wallMaterialTypeId;
            // }
            // if (!room.ceilingMaterialTypeId) {
            //     room.ceilingMaterialTypeId = room.ceilingMaterialTypeId;
            // }
            // if (!room.floorMaterialTypeId) {
            //     room.floorMaterialTypeId = room.floorMaterialTypeId;
            // }
            // if (!room.ceilingWidth) {
            //     room.ceilingWidth = room.wallWidth;
            // }
            // if (!room.ceilingLength) {
            //     room.ceilingLength = room.wallLength;
            // }
            // if (!room.floorWidth) {
            //     room.floorWidth = room.wallWidth;
            // }
            // if (!room.floorLength) {
            //     room.floorLength = room.wallLength;
            // }
            // if (room.ceilingExtraQty1 > 0 || room.ceilingExtraQty2 > 0
            //     || room.ceilingExtraPrice1 > 0 || room.ceilingExtraPrice2 > 0) {
            //     room.isAddExtraCeilingQty = true;
            // }
            // if (room.wallExtraQty1 > 0 || room.wallExtraQty2 > 0
            //     || room.wallExtraPrice1 > 0 || room.wallExtraPrice2 > 0) {
            //     room.isAddExtraWallQty = true;
            // }
            // if (room.floorExtraPrice1 > 0 || room.floorExtraQty2 > 0
            //     || room.floorExtraPrice1 > 0 || room.floorExtraPrice2 > 0) {
            //     room.isAddExtraFloorQty = true;
            // }

            //this.selectedRoomOnEdit = this.OfferTypeDetail.offerRoomDetailList.find(o => o.offerRoomId == roomId);
            // this.OfferCategoryform.patchValue(this.selectedRoomOnEdit);
            // // if (!id) {
            // if (!this.selectedRoomOnEdit.wallMaterialTypeId || this.selectedRoomOnEdit.wallMaterialTypeId == 0)
            //     room.wallMaterialTypeId = this.offMaterialTypeIds.WallType;
            // if (!this.selectedRoomOnEdit.ceilingMaterialTypeId || this.selectedRoomOnEdit.ceilingMaterialTypeId == 0)
            //     room.ceilingMaterialTypeId = this.offMaterialTypeIds.CeilingType;
            // if (!this.selectedRoomOnEdit.floorMaterialTypeId || this.selectedRoomOnEdit.floorMaterialTypeId == 0)
            //     room.floorMaterialTypeId = this.offMaterialTypeIds.FloorType;
            // // }
            // if (room.wallMaterialTypeId) {
            //     //this.f.wallMaterialTypeId.setValue(parseInt(room.wallMaterialTypeId.toString()));
            //     this.selectedRoomOnEdit.wallMaterialTypeId = parseInt(room.wallMaterialTypeId.toString());
            //     this.getWallMaterial(this.selectedRoomOnEdit.wallMaterialTypeId, true);
            //     this.getMaterialReminder(this.selectedRoomOnEdit.wallMaterialTypeId, this.selectedRoomOnEdit.wallMaterialId)
            // }
            // if (room.ceilingMaterialTypeId) {
            //     this.f.ceilingMaterialTypeId.setValue(parseInt(room.ceilingMaterialTypeId.toString()));
            //     this.selectedRoomOnEdit.ceilingMaterialTypeId = parseInt(room.ceilingMaterialTypeId.toString());
            //     this.getCeilingMaterial(this.selectedRoomOnEdit.ceilingMaterialTypeId, true);
            //     this.getMaterialReminder(this.selectedRoomOnEdit.ceilingMaterialTypeId, this.selectedRoomOnEdit.ceilingMaterialId)
            // }
            // if (room.floorMaterialTypeId) {
            //     this.f.floorMaterialTypeId.setValue(parseInt(room.floorMaterialTypeId.toString()));
            //     this.selectedRoomOnEdit.floorMaterialTypeId = parseInt(room.floorMaterialTypeId.toString());
            //     this.getFloorMaterial(this.selectedRoomOnEdit.floorMaterialTypeId, true);
            //     this.getMaterialReminder(this.selectedRoomOnEdit.floorMaterialTypeId, this.selectedRoomOnEdit.floorMaterialId)
            // }
            //Edit
            // this.getWallTimeSelectionListOnEdit(this.selectedRoomOnEdit.wallMaterialId, roomId, this.selectedRoomOnEdit);
            // this.getCeilingTimeSelectionListOnEdit(this.selectedRoomOnEdit.ceilingMaterialId, roomId, this.selectedRoomOnEdit);
            // this.getFloorTimeSelectionListOnEdit(this.selectedRoomOnEdit.floorMaterialId, roomId, this.selectedRoomOnEdit);
            // if (this.selectedRoomOnEdit.paintFloor) {
            //     this.isAddFloor = true;
            // } else {
            //     this.isAddFloor = false;
            // }
            //this.OfferTypeDetail.offerRoomPriceDetailList;
            // this.getWallPriceDetail(this.selectedRoomOnEdit.wallMaterialId, this.selectedRoomOnEdit.wallMaterialPriceId, roomId);
            // this.getCeilingPriceDetail(this.selectedRoomOnEdit.ceilingMaterialId, this.selectedRoomOnEdit.ceilingMaterialPriceId, roomId);
            // this.getFloorPriceDetail(this.selectedRoomOnEdit.floorMaterialId, this.selectedRoomOnEdit.floorMaterialPriceId, roomId);
            this.checkUncheckPanlesDoorframeRadiotorItem();
            this.showSpinner = false;
        }, 1000);
    }

    /**
     * Paint Wall START
     */
    // wallMaterialTypeOnClearSearch(value) {
    //     if (!value) {
    //         this.wallMaterialTypeDropdownSearch()
    //     }
    // }

    // wallMaterialTypeDropdownSearch() {
    //     if (this.f.wallMaterialTypeId.value)
    //         this.wallMaterialObject = this.MasterMaterialTypeList.filter(o => { return o.type.split(',').find(p => p == this.f.wallMaterialTypeId.value && o.text.toLowerCase().includes(this.MaterialTypeSearch.toLowerCase())) });
    //     //this.wallMaterialObject = this.MasterMaterialTypeList.filter(o => (o.type==this.f.wallMaterialTypeId.value && o.text.toLowerCase().includes(this.MaterialTypeSearch.toLowerCase())));
    //     else
    //         this.wallMaterialObject = this.MasterMaterialTypeList.filter(o => o.text.toLowerCase().includes(this.MaterialTypeSearch.toLowerCase()));
    // }

    // getWallPriceDetail(materialId, materialPriceId, roomId) {
    //     var materialprice = this.MaterialObject.find(o => o.id == materialId);
    //     if (materialprice) {
    //         this.selectedRoomOnEdit.paintWall = true;
    //         this.selectedRoomOnEdit.wallColorCode = materialprice.colorCode;
    //         this.selectedRoomOnEdit.wallMaterialReminder = materialprice.reminder;
    //         this.calculatePrice(roomId);
    //         this.wallMeterWisePrice.isAddMeterRangePrice = false;
    //         var roomPriceDetail = this.OfferTypeDetail.offerRoomPriceDetailList.find(o => o.offerRoomId == roomId);
    //         if (roomPriceDetail) {
    //             roomPriceDetail.selectCategoryTimeForWall = [];
    //             for (var i = 0; i < materialprice.offerMaterialPriceList.length; i++) {
    //                 var obj = this.SelectCategoryTime.find(o => o.id == materialprice.offerMaterialPriceList[i].type);
    //                 if (obj) {
    //                     if (i == 0) {
    //                         roomPriceDetail.wallMaterialPriceId = materialprice.offerMaterialPriceList[i].id;
    //                         roomPriceDetail.wallPaintKey = obj.key;
    //                     }
    //                     roomPriceDetail.selectCategoryTimeForWall.push({
    //                         id: obj.id,
    //                         key: obj.key,
    //                         priceId: materialprice.offerMaterialPriceList[i].id
    //                     });
    //                     if (materialprice.offerMaterialPriceList[i].id == materialPriceId) {
    //                         roomPriceDetail.wallPaintKey = obj.key;
    //                     }
    //                     if (materialprice.offerMaterialPriceList[i].isDefault && materialPriceId == "") {
    //                         roomPriceDetail.wallMaterialPriceId = materialprice.offerMaterialPriceList[i].id;
    //                     }
    //                 }
    //                 // if (materialprice.offerMaterialPriceList[i].isDefault) {
    //                 //     this.selectedRoomOnEdit.wallMaterialPriceId = materialprice.offerMaterialPriceList[i].id;
    //                 // }
    //             }

    //             if (!materialPriceId) {
    //                 var selectedMaterialLocal;
    //                 if (roomPriceDetail.wallMaterialPriceId)
    //                     selectedMaterialLocal = materialprice.offerMaterialPriceList.filter(x => x.id == roomPriceDetail.wallMaterialPriceId)[0];
    //                 else
    //                     selectedMaterialLocal = materialprice.offerMaterialPriceList[0];
    //                 roomPriceDetail.wallMaterialPriceId = selectedMaterialLocal.id;
    //                 var objSelectCategoryTime = this.SelectCategoryTime.find(o => o.id == selectedMaterialLocal.type);
    //                 roomPriceDetail.wallPaintKey = objSelectCategoryTime.key;
    //                 var price = selectedMaterialLocal.price;
    //                 if (selectedMaterialLocal.isAddMeterRangePrice) {
    //                     if (this.selectedRoomOnEdit.wallTotalArea >= selectedMaterialLocal.startMeter1
    //                         && this.selectedRoomOnEdit.wallTotalArea <= selectedMaterialLocal.endMeter1) {
    //                         price = selectedMaterialLocal.priceMeter1;
    //                     }
    //                     else if (this.selectedRoomOnEdit.wallTotalArea >= selectedMaterialLocal.startMeter2
    //                         && this.selectedRoomOnEdit.wallTotalArea <= selectedMaterialLocal.endMeter2) {
    //                         price = selectedMaterialLocal.priceMeter2;
    //                     }
    //                     else {
    //                         price = selectedMaterialLocal.priceMeter3;
    //                     }
    //                 }
    //                 roomPriceDetail.wallPrice = price;
    //                 //roomPriceDetail.wallPaintTime = selectedMaterialLocal.time;
    //                 this.calculatePrice(roomId);
    //             }
    //             var tempMaterialPrice = materialprice.offerMaterialPriceList
    //                 .find(o => o.id == roomPriceDetail.wallMaterialPriceId);
    //             if (tempMaterialPrice && tempMaterialPrice.isAddMeterRangePrice) {
    //                 this.wallMeterWisePrice.startMeter1 = tempMaterialPrice.startMeter1;
    //                 this.wallMeterWisePrice.endMeter1 = tempMaterialPrice.endMeter1;
    //                 this.wallMeterWisePrice.priceMeter1 = tempMaterialPrice.priceMeter1;
    //                 this.wallMeterWisePrice.startMeter2 = tempMaterialPrice.startMeter2;
    //                 this.wallMeterWisePrice.endMeter2 = tempMaterialPrice.endMeter2;
    //                 this.wallMeterWisePrice.priceMeter2 = tempMaterialPrice.priceMeter2;
    //                 this.wallMeterWisePrice.startMeter3 = tempMaterialPrice.startMeter3;
    //                 this.wallMeterWisePrice.endMeter3 = tempMaterialPrice.endMeter3;
    //                 this.wallMeterWisePrice.priceMeter3 = tempMaterialPrice.priceMeter3;
    //                 this.wallMeterWisePrice.isAddMeterRangePrice = tempMaterialPrice.isAddMeterRangePrice;
    //             }
    //             if (selectedMaterialLocal && selectedMaterialLocal.paintText) {
    //                 roomPriceDetail.wallPaintText = selectedMaterialLocal.paintText;
    //             }
    //             this.wallMeterWisePrice.price = roomPriceDetail.wallPrice;
    //             roomPriceDetail.wallMaterialName = materialprice.text;
    //         }
    //     }
    // }

    // getPriceOnCategoryTimeSelect(materialId, priceId, roomId, roomDetail) {
    //     var selectedMaterial = this.MaterialObject.find(o => o.id == materialId);
    //     if (selectedMaterial) {
    //         this.wallMeterWisePrice.isAddMeterRangePrice = false;
    //         var selectedPrice = selectedMaterial.offerMaterialPriceList.find(o => o.id == priceId);
    //         if (selectedPrice) {
    //             var price = selectedPrice.price;
    //             if (selectedPrice.isAddMeterRangePrice) {
    //                 if (this.selectedRoomOnEdit.wallTotalArea >= selectedPrice.startMeter1
    //                     && this.selectedRoomOnEdit.wallTotalArea <= selectedPrice.endMeter1) {
    //                     price = selectedPrice.priceMeter1;
    //                 }
    //                 else if (this.selectedRoomOnEdit.wallTotalArea >= selectedPrice.startMeter2
    //                     && this.selectedRoomOnEdit.wallTotalArea <= selectedPrice.endMeter2) {
    //                     price = selectedPrice.priceMeter2;
    //                 }
    //                 else {
    //                     price = selectedPrice.priceMeter3;
    //                 }
    //                 this.wallMeterWisePrice.startMeter1 = selectedPrice.startMeter1;
    //                 this.wallMeterWisePrice.endMeter1 = selectedPrice.endMeter1;
    //                 this.wallMeterWisePrice.priceMeter1 = selectedPrice.priceMeter1;
    //                 this.wallMeterWisePrice.startMeter2 = selectedPrice.startMeter2;
    //                 this.wallMeterWisePrice.endMeter2 = selectedPrice.endMeter2;
    //                 this.wallMeterWisePrice.priceMeter2 = selectedPrice.priceMeter2;
    //                 this.wallMeterWisePrice.startMeter3 = selectedPrice.startMeter3;
    //                 this.wallMeterWisePrice.endMeter3 = selectedPrice.endMeter3;
    //                 this.wallMeterWisePrice.priceMeter3 = selectedPrice.priceMeter3;
    //             }
    //             this.wallMeterWisePrice.isAddMeterRangePrice = selectedPrice.isAddMeterRangePrice;
    //             this.wallMeterWisePrice.price = price;
    //             // var roomPriceDetail = this.OfferTypeDetail.offerRoomPriceDetailList.find(o => o.offerRoomId == roomId);
    //             // if (roomDetail && roomDetail.id)
    //             //     roomPriceDetail.wallPrice = roomDetail.wallPrice;
    //             // else
    //             //     roomPriceDetail.wallPrice = price;
    //             // if (selectedPrice.paintText) {
    //             //     roomPriceDetail.wallPaintText = selectedPrice.paintText;
    //             // }
    //             // var obj = this.SelectCategoryTime.find(o => o.id == selectedPrice.type);
    //             // if (obj) {
    //             //     roomPriceDetail.wallPaintKey = obj.key;
    //             // }
    //             // //roomPriceDetail.wallPaintTime = selectedPrice.time;
    //             // roomPriceDetail.wallMaterialName = selectedMaterial.text;
    //         }
    //         this.calculatePrice(roomId);
    //     }
    // }

    // getWallTimeSelectionListOnEdit(materialId, roomId, roomDetail) {
    //     var materialprice = this.MaterialObject.find(o => o.id == materialId);
    //     if (materialprice) {
    //         var roomPriceDetail = this.OfferTypeDetail.offerRoomPriceDetailList.find(o => o.offerRoomId == roomId);
    //         if (roomPriceDetail) {
    //             roomPriceDetail.selectCategoryTimeForWall = [];
    //             for (var i = 0; i < materialprice.offerMaterialPriceList.length; i++) {
    //                 var obj = this.SelectCategoryTime.find(o => o.id == materialprice.offerMaterialPriceList[i].type);
    //                 if (obj) {
    //                     roomPriceDetail.selectCategoryTimeForWall.push({
    //                         id: obj.id,
    //                         key: obj.key,
    //                         priceId: materialprice.offerMaterialPriceList[i].id
    //                     });
    //                     if (materialprice.offerMaterialPriceList[i].id == this.selectedRoomOnEdit.wallMaterialPriceId) {
    //                         roomPriceDetail.wallPaintKey = obj.key;
    //                     }
    //                 }
    //             }
    //             this.getPriceOnCategoryTimeSelect(materialId, this.selectedRoomOnEdit.wallMaterialPriceId, roomId, roomDetail)
    //         }
    //     }
    // }

    //Calculate Wall Price
    // calculateWallPrice(roomId) {
    //     var roomDetail = this.OfferTypeDetail.offerRoomDetailList.find(o => o.offerRoomId == roomId);
    //     var selectedPrice = this.MaterialObject.find(o => o.id == this.selectedRoomOnEdit.wallMaterialId)
    //         .offerMaterialPriceList.find(o => o.id == this.selectedRoomOnEdit.wallMaterialPriceId);
    //     if (selectedPrice) {
    //         roomDetail.wallTotalArea = (((roomDetail.wallLength + roomDetail.wallWidth) * 2) * roomDetail.ceilingHeight)
    //             + roomDetail.wallExtraQty1 + roomDetail.wallExtraQty2;
    //         if (selectedPrice.isAddMeterRangePrice && !roomDetail.notCalculateWallPrice) {
    //             if (this.selectedRoomOnEdit.wallTotalArea >= selectedPrice.startMeter1
    //                 && this.selectedRoomOnEdit.wallTotalArea <= selectedPrice.endMeter1) {
    //                 roomDetail.wallPrice = selectedPrice.priceMeter1;
    //             }
    //             else if (this.selectedRoomOnEdit.wallTotalArea >= selectedPrice.startMeter2
    //                 && this.selectedRoomOnEdit.wallTotalArea <= selectedPrice.endMeter2) {
    //                 roomDetail.wallPrice = selectedPrice.priceMeter2;
    //             }
    //             else {
    //                 roomDetail.wallPrice = selectedPrice.priceMeter3;
    //             }
    //             this.wallMeterWisePrice.startMeter1 = selectedPrice.startMeter1;
    //             this.wallMeterWisePrice.endMeter1 = selectedPrice.endMeter1;
    //             this.wallMeterWisePrice.priceMeter1 = selectedPrice.priceMeter1;
    //             this.wallMeterWisePrice.startMeter2 = selectedPrice.startMeter2;
    //             this.wallMeterWisePrice.endMeter2 = selectedPrice.endMeter2;
    //             this.wallMeterWisePrice.priceMeter2 = selectedPrice.priceMeter2;
    //             this.wallMeterWisePrice.startMeter3 = selectedPrice.startMeter3;
    //             this.wallMeterWisePrice.endMeter3 = selectedPrice.endMeter3;
    //             this.wallMeterWisePrice.priceMeter3 = selectedPrice.priceMeter3;
    //             this.wallMeterWisePrice.isAddMeterRangePrice = selectedPrice.isAddMeterRangePrice;
    //             this.wallMeterWisePrice.price = roomDetail.wallPrice;
    //         }
    //     }
    // }

    // calculateWall(roomId) {
    //     this.calculateWallPrice(this.selectedRoomOnEdit.offerRoomId);
    //     this.calculatePrice(roomId);
    // }

    // calculateWallFixedPrice(roomId) {
    //     this.selectedRoomOnEdit.notCalculateWallPrice = true;
    //     this.calculateWallPrice(this.selectedRoomOnEdit.offerRoomId);
    //     this.calculatePrice(roomId);
    // }

    // addWallQty(isExtraWallQty) {
    //     if (!isExtraWallQty) {
    //         this.selectedRoomOnEdit.isAddExtraWallQty = true;
    //     } else {
    //         this.selectedRoomOnEdit.isAddExtraWallQty = false;
    //         this.selectedRoomOnEdit.wallExtraQty1 = 0;
    //         this.selectedRoomOnEdit.wallExtraQty2 = 0;
    //         this.selectedRoomOnEdit.wallExtraPrice1 = 0;
    //         this.selectedRoomOnEdit.wallExtraPrice2 = 0;
    //         this.calculatePrice(this.selectedRoomOnEdit.offerRoomId);
    //     }
    // }

    //Clear Wall Price
    // clearWallPriceDetail(roomId, index) {
    //     var i = index;
    //     this.selectedRoomOnEdit.paintWall = false;
    //     this.selectedRoomOnEdit.wallMaterialId = null;
    //     this.selectedRoomOnEdit.wallMaterialPriceId = null;
    //     this.selectedRoomOnEdit.wallTotalArea = 0;
    //     this.selectedRoomOnEdit.wallTotalPrice = 0;
    //     this.selectedRoomOnEdit.wallPrice = 0;
    //     this.selectedRoomOnEdit.wallColorCode = null;
    //     this.selectedRoomOnEdit.wallMaterialReminder = null;
    //     var room = this.OfferTypeDetail.offerRoomDetailList.find(o => o.offerRoomId == roomId);

    //     if (!room.ceilingWidth) {
    //         room.ceilingWidth = room.wallWidth;
    //     }
    //     if (!room.ceilingLength) {
    //         room.ceilingLength = room.wallLength;
    //     }
    //     if (room.wallExtraQty1 > 0 || room.wallExtraQty2 > 0
    //         || room.wallExtraPrice1 > 0 || room.wallExtraPrice2 > 0) {
    //         room.isAddExtraWallQty = true;
    //     }
    //     // this.selectedRoomOnEdit = room;
    //     // this.OfferCategoryform.patchValue(this.selectedRoomOnEdit);
    //     // this.OfferTypeDetail.offerRoomPriceDetailList[i] = this.MasterOfferRoomList[i];
    //     this.OfferTypeDetail.offerRoomPriceDetailList[i].wallMaterialName = null;
    //     this.OfferTypeDetail.offerRoomPriceDetailList[i].wallPaintKey = null;
    //     this.calculatePrice(roomId);
    // }
    // Paint Wall END


    /**
     * Paint  Ceiling START
     */
    // ceilingMaterialTypeOnClearSearch(value) {
    //     if (!value) {
    //         this.ceilingMaterialTypeDropdownSearch();
    //     }
    // }

    // ceilingMaterialTypeDropdownSearch() {
    //     if (this.f.ceilingMaterialTypeId.value)
    //         this.ceilingMaterialObject = this.MasterMaterialTypeList.filter(o => { return o.type.split(',').find(p => p == this.f.ceilingMaterialTypeId.value && o.text.toLowerCase().includes(this.MaterialTypeSearch.toLowerCase())) });
    //     //this.ceilingMaterialObject = this.MasterMaterialTypeList.filter(o => (o.type==this.f.ceilingMaterialTypeId.value && o.text.toLowerCase().includes(this.MaterialTypeSearch.toLowerCase())));
    //     else
    //         this.ceilingMaterialObject = this.MasterMaterialTypeList.filter(o => o.text.toLowerCase().includes(this.MaterialTypeSearch.toLowerCase()));
    // }

    // getCeilingPriceDetail(materialId, materialPriceId, roomId) {
    //     var materialprice = this.MaterialObject.find(o => o.id == materialId);
    //     if (materialprice) {
    //         this.selectedRoomOnEdit.paintCeiling = true;
    //         this.selectedRoomOnEdit.ceilingColorCode = materialprice.colorCode;
    //         this.selectedRoomOnEdit.ceilingMaterialReminder = materialprice.reminder;
    //         this.calculatePrice(roomId);
    //         this.ceilingMeterWisePrice.isAddMeterRangePrice = false;
    //         var roomPriceDetail = this.OfferTypeDetail.offerRoomPriceDetailList.find(o => o.offerRoomId == roomId);
    //         if (roomPriceDetail) {
    //             roomPriceDetail.selectCategoryTimeForCeiling = [];
    //             for (var i = 0; i < materialprice.offerMaterialPriceList.length; i++) {
    //                 var obj = this.SelectCategoryTime.find(o => o.id == materialprice.offerMaterialPriceList[i].type);
    //                 if (obj) {
    //                     if (i == 0) {
    //                         roomPriceDetail.ceilingMaterialPriceId = materialprice.offerMaterialPriceList[i].id;
    //                         roomPriceDetail.ceilingPaintKey = obj.key;
    //                     }
    //                     roomPriceDetail.selectCategoryTimeForCeiling.push({
    //                         id: obj.id,
    //                         key: obj.key,
    //                         priceId: materialprice.offerMaterialPriceList[i].id
    //                     });
    //                     if (materialprice.offerMaterialPriceList[i].id == materialPriceId) {
    //                         roomPriceDetail.ceilingPaintKey = obj.key;
    //                     }
    //                     if (materialprice.offerMaterialPriceList[i].isDefault && materialPriceId == "") {
    //                         roomPriceDetail.ceilingMaterialPriceId = materialprice.offerMaterialPriceList[i].id;
    //                     }
    //                     // if (materialprice.offerMaterialPriceList[i].isDefault && materialPriceId == "") {
    //                     //     this.selectedRoomOnEdit.ceilingMaterialPriceId = materialprice.offerMaterialPriceList[i].id;
    //                     // }
    //                 }
    //             }

    //             if (!materialPriceId) {
    //                 var selectedMaterialLocal;
    //                 if (roomPriceDetail.ceilingMaterialPriceId)
    //                     selectedMaterialLocal = materialprice.offerMaterialPriceList.filter(x => x.id == roomPriceDetail.ceilingMaterialPriceId)[0];
    //                 else
    //                     selectedMaterialLocal = materialprice.offerMaterialPriceList[0];
    //                 roomPriceDetail.ceilingMaterialPriceId = selectedMaterialLocal.id;
    //                 var objSelectCategoryTime = this.SelectCategoryTime.find(o => o.id == selectedMaterialLocal.type);
    //                 roomPriceDetail.ceilingPaintKey = objSelectCategoryTime.key;
    //                 var price = selectedMaterialLocal.price;
    //                 if (selectedMaterialLocal.isAddMeterRangePrice) {
    //                     if (this.selectedRoomOnEdit.ceilingTotalArea >= selectedMaterialLocal.startMeter1
    //                         && this.selectedRoomOnEdit.ceilingTotalArea <= selectedMaterialLocal.endMeter1) {
    //                         price = selectedMaterialLocal.priceMeter1;
    //                     }
    //                     else if (this.selectedRoomOnEdit.ceilingTotalArea >= selectedMaterialLocal.startMeter2
    //                         && this.selectedRoomOnEdit.ceilingTotalArea <= selectedMaterialLocal.endMeter2) {
    //                         price = selectedMaterialLocal.priceMeter2;
    //                     }
    //                     else {
    //                         price = selectedMaterialLocal.priceMeter3;
    //                     }
    //                 }
    //                 roomPriceDetail.ceilingPrice = price;
    //                 //roomPriceDetail.ceilingPaintTime = selectedMaterialLocal.time;
    //                 this.calculatePrice(roomId);
    //             }
    //             var tempMaterialPrice = materialprice.offerMaterialPriceList
    //                 .find(o => o.id == roomPriceDetail.ceilingMaterialPriceId);
    //             if (tempMaterialPrice && tempMaterialPrice.isAddMeterRangePrice) {
    //                 this.ceilingMeterWisePrice.startMeter1 = tempMaterialPrice.startMeter1;
    //                 this.ceilingMeterWisePrice.endMeter1 = tempMaterialPrice.endMeter1;
    //                 this.ceilingMeterWisePrice.priceMeter1 = tempMaterialPrice.priceMeter1;
    //                 this.ceilingMeterWisePrice.startMeter2 = tempMaterialPrice.startMeter2;
    //                 this.ceilingMeterWisePrice.endMeter2 = tempMaterialPrice.endMeter2;
    //                 this.ceilingMeterWisePrice.priceMeter2 = tempMaterialPrice.priceMeter2;
    //                 this.ceilingMeterWisePrice.startMeter3 = tempMaterialPrice.startMeter3;
    //                 this.ceilingMeterWisePrice.endMeter3 = tempMaterialPrice.endMeter3;
    //                 this.ceilingMeterWisePrice.priceMeter3 = tempMaterialPrice.priceMeter3;
    //                 this.ceilingMeterWisePrice.isAddMeterRangePrice = tempMaterialPrice.isAddMeterRangePrice;
    //             }
    //             if (selectedMaterialLocal && selectedMaterialLocal.paintText) {
    //                 roomPriceDetail.ceilingPaintText = selectedMaterialLocal.paintText;
    //             }
    //             this.ceilingMeterWisePrice.price = roomPriceDetail.ceilingPrice;
    //             roomPriceDetail.ceilingMaterialName = materialprice.text;
    //         }
    //     }
    // }

    // getPriceOnCategoryTimeSelectForCeiling(materialId, priceId, roomId, roomDetail) {
    //     var selectedMaterial = this.MaterialObject.find(o => o.id == materialId);
    //     if (selectedMaterial) {
    //         this.ceilingMeterWisePrice.isAddMeterRangePrice = false;
    //         var selectedPrice = selectedMaterial.offerMaterialPriceList.find(o => o.id == priceId);
    //         if (selectedPrice) {
    //             var price = selectedPrice.price;
    //             if (selectedPrice.isAddMeterRangePrice) {
    //                 if (this.selectedRoomOnEdit.ceilingTotalArea >= selectedPrice.startMeter1
    //                     && this.selectedRoomOnEdit.ceilingTotalArea <= selectedPrice.endMeter1) {
    //                     price = selectedPrice.priceMeter1;
    //                 }
    //                 else if (this.selectedRoomOnEdit.ceilingTotalArea >= selectedPrice.startMeter2
    //                     && this.selectedRoomOnEdit.ceilingTotalArea <= selectedPrice.endMeter2) {
    //                     price = selectedPrice.priceMeter2;
    //                 }
    //                 else {
    //                     price = selectedPrice.priceMeter3;
    //                 }
    //                 this.ceilingMeterWisePrice.startMeter1 = selectedPrice.startMeter1;
    //                 this.ceilingMeterWisePrice.endMeter1 = selectedPrice.endMeter1;
    //                 this.ceilingMeterWisePrice.priceMeter1 = selectedPrice.priceMeter1;
    //                 this.ceilingMeterWisePrice.startMeter2 = selectedPrice.startMeter2;
    //                 this.ceilingMeterWisePrice.endMeter2 = selectedPrice.endMeter2;
    //                 this.ceilingMeterWisePrice.priceMeter2 = selectedPrice.priceMeter2;
    //                 this.ceilingMeterWisePrice.startMeter3 = selectedPrice.startMeter3;
    //                 this.ceilingMeterWisePrice.endMeter3 = selectedPrice.endMeter3;
    //                 this.ceilingMeterWisePrice.priceMeter3 = selectedPrice.priceMeter3;
    //             }
    //             this.ceilingMeterWisePrice.isAddMeterRangePrice = selectedPrice.isAddMeterRangePrice;
    //             this.ceilingMeterWisePrice.price = price;
    //             var roomPriceDetail = this.OfferTypeDetail.offerRoomPriceDetailList.find(o => o.offerRoomId == roomId);
    //             if (roomDetail && roomDetail.id)
    //                 roomPriceDetail.ceilingPrice = roomDetail.ceilingPrice;
    //             else
    //                 roomPriceDetail.ceilingPrice = price;
    //             if (selectedPrice.paintText) {
    //                 roomPriceDetail.ceilingPaintText = selectedPrice.paintText;
    //             }
    //             var obj = this.SelectCategoryTime.find(o => o.id == selectedPrice.type);
    //             if (obj) {
    //                 roomPriceDetail.ceilingPaintKey = obj.key;
    //             }
    //             //roomPriceDetail.ceilingPaintTime = selectedPrice.time;
    //             roomPriceDetail.ceilingMaterialName = selectedMaterial.text;
    //         }
    //         this.calculatePrice(roomId);
    //     }
    // }

    // getCeilingTimeSelectionListOnEdit(materialId, roomId, roomDetail) {
    //     var materialprice = this.MaterialObject.find(o => o.id == materialId);
    //     if (materialprice) {
    //         var roomPriceDetail = this.OfferTypeDetail.offerRoomPriceDetailList.find(o => o.offerRoomId == roomId);
    //         if (roomPriceDetail) {
    //             roomPriceDetail.selectCategoryTimeForCeiling = [];
    //             for (var i = 0; i < materialprice.offerMaterialPriceList.length; i++) {
    //                 var obj = this.SelectCategoryTime.find(o => o.id == materialprice.offerMaterialPriceList[i].type);
    //                 if (obj) {
    //                     roomPriceDetail.selectCategoryTimeForCeiling.push({
    //                         id: obj.id,
    //                         key: obj.key,
    //                         priceId: materialprice.offerMaterialPriceList[i].id
    //                     });
    //                     if (materialprice.offerMaterialPriceList[i].id == this.selectedRoomOnEdit.ceilingMaterialPriceId) {
    //                         roomPriceDetail.ceilingPaintKey = obj.key;
    //                     }
    //                 }
    //             }
    //         }
    //         this.getPriceOnCategoryTimeSelectForCeiling(materialId, this.selectedRoomOnEdit.ceilingMaterialPriceId, roomId, roomDetail)
    //     }
    // }

    //Calculate Ceiling Price
    // calculateCeilingPrice(roomId) {
    //     var roomDetail = this.OfferTypeDetail.offerRoomDetailList.find(o => o.offerRoomId == roomId);
    //     var selectedPrice = this.MaterialObject.find(o => o.id == this.selectedRoomOnEdit.ceilingMaterialId)
    //         .offerMaterialPriceList.find(o => o.id == this.selectedRoomOnEdit.ceilingMaterialPriceId);
    //     if (selectedPrice) {
    //         roomDetail.ceilingTotalArea = (roomDetail.ceilingLength * roomDetail.ceilingWidth)
    //             + roomDetail.ceilingExtraQty1 + roomDetail.ceilingExtraQty2;
    //         if (selectedPrice.isAddMeterRangePrice && !roomDetail.notCalculateCeilingPrice) {
    //             if (this.selectedRoomOnEdit.ceilingTotalArea >= selectedPrice.startMeter1
    //                 && this.selectedRoomOnEdit.ceilingTotalArea <= selectedPrice.endMeter1) {
    //                 roomDetail.ceilingPrice = selectedPrice.priceMeter1;
    //             }
    //             else if (this.selectedRoomOnEdit.ceilingTotalArea >= selectedPrice.startMeter2
    //                 && this.selectedRoomOnEdit.ceilingTotalArea <= selectedPrice.endMeter2) {
    //                 roomDetail.ceilingPrice = selectedPrice.priceMeter2;
    //             }
    //             else {
    //                 roomDetail.ceilingPrice = selectedPrice.priceMeter3;
    //             }
    //             this.ceilingMeterWisePrice.startMeter1 = selectedPrice.startMeter1;
    //             this.ceilingMeterWisePrice.endMeter1 = selectedPrice.endMeter1;
    //             this.ceilingMeterWisePrice.priceMeter1 = selectedPrice.priceMeter1;
    //             this.ceilingMeterWisePrice.startMeter2 = selectedPrice.startMeter2;
    //             this.ceilingMeterWisePrice.endMeter2 = selectedPrice.endMeter2;
    //             this.ceilingMeterWisePrice.priceMeter2 = selectedPrice.priceMeter2;
    //             this.ceilingMeterWisePrice.startMeter3 = selectedPrice.startMeter3;
    //             this.ceilingMeterWisePrice.endMeter3 = selectedPrice.endMeter3;
    //             this.ceilingMeterWisePrice.priceMeter3 = selectedPrice.priceMeter3;
    //             this.ceilingMeterWisePrice.isAddMeterRangePrice = selectedPrice.isAddMeterRangePrice;
    //             this.ceilingMeterWisePrice.price = roomDetail.ceilingPrice;
    //         }
    //     }
    // }

    // calculateCeling(roomId) {
    //     this.calculateCeilingPrice(this.selectedRoomOnEdit.offerRoomId);
    //     this.calculatePrice(roomId);
    // }

    // calculateCelingFixedPrice(roomId) {
    //     this.selectedRoomOnEdit.notCalculateCeilingPrice = true;
    //     this.calculateCeilingPrice(this.selectedRoomOnEdit.offerRoomId);
    //     this.calculatePrice(roomId);
    // }

    // addCeilingQty(isExtraCeilingQty) {
    //     if (!isExtraCeilingQty) {
    //         this.selectedRoomOnEdit.isAddExtraCeilingQty = true;
    //     } else {
    //         this.selectedRoomOnEdit.isAddExtraCeilingQty = false;
    //         this.selectedRoomOnEdit.ceilingExtraQty1 = 0;
    //         this.selectedRoomOnEdit.ceilingExtraQty2 = 0;
    //         this.selectedRoomOnEdit.ceilingExtraPrice1 = 0;
    //         this.selectedRoomOnEdit.ceilingExtraPrice2 = 0;
    //         this.calculatePrice(this.selectedRoomOnEdit.offerRoomId);
    //     }
    // }

    //Clear Ceiling Price
    // clearCeilingPriceDetail(roomId, index) {
    //     var i = index;
    //     this.selectedRoomOnEdit.paintCeiling = false;
    //     this.selectedRoomOnEdit.ceilingMaterialId = null
    //     this.selectedRoomOnEdit.ceilingMaterialPriceId = null;
    //     this.selectedRoomOnEdit.ceilingTotalArea = 0;
    //     this.selectedRoomOnEdit.ceilingTotalPrice = 0;
    //     this.selectedRoomOnEdit.ceilingPrice = 0;
    //     this.selectedRoomOnEdit.ceilingColorCode = null;
    //     this.selectedRoomOnEdit.ceilingMaterialReminder = null;

    //     var room = this.OfferTypeDetail.offerRoomPriceDetailList.find(o => o.offerRoomId == roomId);
    //     if (!room.ceilingWidth) {
    //         room.ceilingWidth = room.wallWidth;
    //     } if (!room.ceilingLength) {
    //         room.ceilingLength = room.wallLength;
    //     }
    //     if (room.ceilingExtraQty1 > 0 || room.ceilingExtraQty2 > 0
    //         || room.ceilingExtraPrice1 > 0 || room.ceilingExtraPrice2 > 0) {
    //         room.isAddExtraCeilingQty = true;
    //     }
    //     // this.selectedRoomOnEdit = room;
    //     // this.OfferCategoryform.patchValue(this.selectedRoomOnEdit);
    //     // this.OfferTypeDetail.offerRoomPriceDetailList[i] = this.MasterOfferRoomList[i];
    //     this.OfferTypeDetail.offerRoomPriceDetailList[i].ceilingMaterialName = null;
    //     this.OfferTypeDetail.offerRoomPriceDetailList[i].ceilingPaintKey = null;
    //     this.calculatePrice(roomId);
    // }
    // Paint Ceiling END


    /**
     * Paint Floor START
     */
    // floorMaterialTypeOnClearSearch(value) {
    //     if (!value) {
    //         this.floorMaterialTypeDropdownSearch();
    //     }
    // }
    // floorMaterialTypeDropdownSearch() {
    //     if (this.f.floorMaterialTypeId.value)
    //         this.floorMaterialObject = this.MasterMaterialTypeList.filter(o => { return o.type.split(',').find(p => p == this.f.floorMaterialTypeId.value && o.text.toLowerCase().includes(this.MaterialTypeSearch.toLowerCase())) });
    //     //this.floorMaterialObject = this.MasterMaterialTypeList.filter(o => (o.type==this.f.floorMaterialTypeId.value && o.text.toLowerCase().includes(this.MaterialTypeSearch.toLowerCase())));
    //     else
    //         this.floorMaterialObject = this.MasterMaterialTypeList.filter(o => o.text.toLowerCase().includes(this.MaterialTypeSearch.toLowerCase()));
    // }

    // getFloorPriceDetail(materialId, materialPriceId, roomId) {
    //     var materialprice = this.MaterialObject.find(o => o.id == materialId);
    //     if (materialprice) {
    //         this.isAddFloor = true;
    //         this.selectedRoomOnEdit.paintFloor = true;
    //         this.selectedRoomOnEdit.floorColorCode = materialprice.colorCode;
    //         this.selectedRoomOnEdit.floorMaterialReminder = materialprice.reminder;
    //         this.calculatePrice(roomId);
    //         this.floorMeterWisePrice.isAddMeterRangePrice = false;
    //         var roomPriceDetail = this.OfferTypeDetail.offerRoomPriceDetailList.find(o => o.offerRoomId == roomId);
    //         if (roomPriceDetail) {
    //             roomPriceDetail.selectCategoryTimeForFloor = [];
    //             for (var i = 0; i < materialprice.offerMaterialPriceList.length; i++) {
    //                 var obj = this.SelectCategoryTime.find(o => o.id == materialprice.offerMaterialPriceList[i].type);
    //                 if (obj) {
    //                     if (i == 0) {
    //                         roomPriceDetail.floorMaterialPriceId = materialprice.offerMaterialPriceList[i].id;
    //                         roomPriceDetail.floorPaintKey = obj.key;
    //                     }
    //                     roomPriceDetail.selectCategoryTimeForFloor.push({
    //                         id: obj.id,
    //                         key: obj.key,
    //                         priceId: materialprice.offerMaterialPriceList[i].id
    //                     });
    //                     if (materialprice.offerMaterialPriceList[i].id == materialPriceId) {
    //                         roomPriceDetail.floorPaintKey = obj.key;
    //                     }
    //                     if (materialprice.offerMaterialPriceList[i].isDefault && materialPriceId == "") {
    //                         roomPriceDetail.floorMaterialPriceId = materialprice.offerMaterialPriceList[i].id;
    //                     }
    //                     // if (materialprice.offerMaterialPriceList[i].isDefault && materialPriceId == "") {
    //                     //     this.selectedRoomOnEdit.floorMaterialPriceId = materialprice.offerMaterialPriceList[i].id;
    //                     // }
    //                 }
    //             }

    //             if (!materialPriceId) {
    //                 var selectedMaterialLocal;
    //                 if (roomPriceDetail.floorMaterialPriceId)
    //                     selectedMaterialLocal = materialprice.offerMaterialPriceList.filter(x => x.id == roomPriceDetail.floorMaterialPriceId)[0];
    //                 else
    //                     selectedMaterialLocal = materialprice.offerMaterialPriceList[0];
    //                 roomPriceDetail.floorMaterialPriceId = selectedMaterialLocal.id;
    //                 var objSelectCategoryTime = this.SelectCategoryTime.find(o => o.id == selectedMaterialLocal.type);
    //                 roomPriceDetail.floorPaintKey = objSelectCategoryTime.key;
    //                 var price = selectedMaterialLocal.price;
    //                 if (selectedMaterialLocal.isAddMeterRangePrice) {
    //                     if (this.selectedRoomOnEdit.floorTotalArea >= selectedMaterialLocal.startMeter1
    //                         && this.selectedRoomOnEdit.floorTotalArea <= selectedMaterialLocal.endMeter1) {
    //                         price = selectedMaterialLocal.priceMeter1;
    //                     }
    //                     else if (this.selectedRoomOnEdit.floorTotalArea >= selectedMaterialLocal.startMeter2
    //                         && this.selectedRoomOnEdit.floorTotalArea <= selectedMaterialLocal.endMeter2) {
    //                         price = selectedMaterialLocal.priceMeter2;
    //                     }
    //                     else {
    //                         price = selectedMaterialLocal.priceMeter3;
    //                     }
    //                 }
    //                 roomPriceDetail.floorPrice = price;
    //                 this.calculatePrice(roomId);
    //             }
    //             var tempMaterialPrice = materialprice.offerMaterialPriceList
    //                 .find(o => o.id == roomPriceDetail.floorMaterialPriceId);
    //             if (tempMaterialPrice && tempMaterialPrice.isAddMeterRangePrice) {
    //                 this.floorMeterWisePrice.startMeter1 = tempMaterialPrice.startMeter1;
    //                 this.floorMeterWisePrice.endMeter1 = tempMaterialPrice.endMeter1;
    //                 this.floorMeterWisePrice.priceMeter1 = tempMaterialPrice.priceMeter1;
    //                 this.floorMeterWisePrice.startMeter2 = tempMaterialPrice.startMeter2;
    //                 this.floorMeterWisePrice.endMeter2 = tempMaterialPrice.endMeter2;
    //                 this.floorMeterWisePrice.priceMeter2 = tempMaterialPrice.priceMeter2;
    //                 this.floorMeterWisePrice.startMeter3 = tempMaterialPrice.startMeter3;
    //                 this.floorMeterWisePrice.endMeter3 = tempMaterialPrice.endMeter3;
    //                 this.floorMeterWisePrice.priceMeter3 = tempMaterialPrice.priceMeter3;
    //                 this.floorMeterWisePrice.isAddMeterRangePrice = tempMaterialPrice.isAddMeterRangePrice;
    //             }
    //             if (selectedMaterialLocal && selectedMaterialLocal.paintText) {
    //                 roomPriceDetail.floorPaintText = selectedMaterialLocal.paintText;
    //             }
    //             this.floorMeterWisePrice.price = roomPriceDetail.floorPrice;
    //             roomPriceDetail.floorMaterialName = materialprice.text;

    //         }
    //     } else {
    //         this.isAddFloor = false;
    //     }
    // }

    // getPriceOnCategoryTimeSelectForFloor(materialId, priceId, roomId, roomDetail) {
    //     var selectedMaterial = this.MaterialObject.find(o => o.id == materialId);
    //     if (selectedMaterial) {
    //         this.floorMeterWisePrice.isAddMeterRangePrice = false;
    //         var selectedPrice = selectedMaterial.offerMaterialPriceList.find(o => o.id == priceId);
    //         if (selectedPrice) {
    //             var price = selectedPrice.price;
    //             if (selectedPrice.isAddMeterRangePrice) {
    //                 if (this.selectedRoomOnEdit.floorTotalArea >= selectedPrice.startMeter1
    //                     && this.selectedRoomOnEdit.floorTotalArea <= selectedPrice.endMeter1) {
    //                     price = selectedPrice.priceMeter1;
    //                 }
    //                 else if (this.selectedRoomOnEdit.floorTotalArea >= selectedPrice.startMeter2
    //                     && this.selectedRoomOnEdit.floorTotalArea <= selectedPrice.endMeter2) {
    //                     price = selectedPrice.priceMeter2;
    //                 }
    //                 else {
    //                     price = selectedPrice.priceMeter3;
    //                 }
    //                 this.floorMeterWisePrice.startMeter1 = selectedPrice.startMeter1;
    //                 this.floorMeterWisePrice.endMeter1 = selectedPrice.endMeter1;
    //                 this.floorMeterWisePrice.priceMeter1 = selectedPrice.priceMeter1;
    //                 this.floorMeterWisePrice.startMeter2 = selectedPrice.startMeter2;
    //                 this.floorMeterWisePrice.endMeter2 = selectedPrice.endMeter2;
    //                 this.floorMeterWisePrice.priceMeter2 = selectedPrice.priceMeter2;
    //                 this.floorMeterWisePrice.startMeter3 = selectedPrice.startMeter3;
    //                 this.floorMeterWisePrice.endMeter3 = selectedPrice.endMeter3;
    //                 this.floorMeterWisePrice.priceMeter3 = selectedPrice.priceMeter3;
    //             }
    //             this.floorMeterWisePrice.isAddMeterRangePrice = selectedPrice.isAddMeterRangePrice;
    //             this.floorMeterWisePrice.price = price;
    //             var roomPriceDetail = this.OfferTypeDetail.offerRoomPriceDetailList.find(o => o.offerRoomId == roomId);
    //             if (roomDetail && roomDetail.id)
    //                 roomPriceDetail.floorPrice = roomDetail.floorPrice;
    //             else
    //                 roomPriceDetail.floorPrice = price;
    //             if (selectedPrice.paintText) {
    //                 roomPriceDetail.floorPaintText = selectedPrice.paintText;
    //             }
    //             var obj = this.SelectCategoryTime.find(o => o.id == selectedPrice.type);
    //             if (obj) {
    //                 roomPriceDetail.floorPaintKey = obj.key;
    //             }
    //             roomPriceDetail.floorMaterialName = selectedMaterial.text;
    //         }
    //         this.calculatePrice(roomId);
    //     }
    // }

    // getFloorTimeSelectionListOnEdit(materialId, roomId, roomDetail) {
    //     var materialprice = this.MaterialObject.find(o => o.id == materialId);
    //     if (materialprice) {
    //         var roomPriceDetail = this.OfferTypeDetail.offerRoomPriceDetailList.find(o => o.offerRoomId == roomId);
    //         if (roomPriceDetail) {
    //             roomPriceDetail.selectCategoryTimeForFloor = [];
    //             for (var i = 0; i < materialprice.offerMaterialPriceList.length; i++) {
    //                 var obj = this.SelectCategoryTime.find(o => o.id == materialprice.offerMaterialPriceList[i].type);
    //                 if (obj) {
    //                     roomPriceDetail.selectCategoryTimeForFloor.push({
    //                         id: obj.id,
    //                         key: obj.key,
    //                         priceId: materialprice.offerMaterialPriceList[i].id
    //                     });
    //                     if (materialprice.offerMaterialPriceList[i].id == this.selectedRoomOnEdit.floorMaterialPriceId) {
    //                         roomPriceDetail.floorPaintKey = obj.key;
    //                     }
    //                 }
    //             }
    //         }
    //         this.getPriceOnCategoryTimeSelectForFloor(materialId, this.selectedRoomOnEdit.floorMaterialPriceId, roomId, roomDetail)
    //     }
    // }


    ////Calculate Floor Price
    // calculateFloorPrice(roomId) {
    //     var roomDetail = this.OfferTypeDetail.offerRoomDetailList.find(o => o.offerRoomId == roomId);
    //     var selectedPrice = this.MaterialObject.find(o => o.id == this.selectedRoomOnEdit.floorMaterialId)
    //         .offerMaterialPriceList.find(o => o.id == this.selectedRoomOnEdit.floorMaterialPriceId);
    //     if (selectedPrice) {
    //         roomDetail.floorTotalArea = (roomDetail.floorLength * roomDetail.floorWidth)
    //             + roomDetail.floorExtraQty1 + roomDetail.floorExtraQty2;
    //         if (selectedPrice.isAddMeterRangePrice && !roomDetail.notCalculateFloorPrice) {
    //             if (this.selectedRoomOnEdit.floorTotalArea >= selectedPrice.startMeter1
    //                 && this.selectedRoomOnEdit.floorTotalArea <= selectedPrice.endMeter1) {
    //                 roomDetail.floorPrice = selectedPrice.priceMeter1;
    //             }
    //             else if (this.selectedRoomOnEdit.floorTotalArea >= selectedPrice.startMeter2
    //                 && this.selectedRoomOnEdit.floorTotalArea <= selectedPrice.endMeter2) {
    //                 roomDetail.floorPrice = selectedPrice.priceMeter2;
    //             }
    //             else {
    //                 roomDetail.floorPrice = selectedPrice.priceMeter3;
    //             }
    //             this.floorMeterWisePrice.startMeter1 = selectedPrice.startMeter1;
    //             this.floorMeterWisePrice.endMeter1 = selectedPrice.endMeter1;
    //             this.floorMeterWisePrice.priceMeter1 = selectedPrice.priceMeter1;
    //             this.floorMeterWisePrice.startMeter2 = selectedPrice.startMeter2;
    //             this.floorMeterWisePrice.endMeter2 = selectedPrice.endMeter2;
    //             this.floorMeterWisePrice.priceMeter2 = selectedPrice.priceMeter2;
    //             this.floorMeterWisePrice.startMeter3 = selectedPrice.startMeter3;
    //             this.floorMeterWisePrice.endMeter3 = selectedPrice.endMeter3;
    //             this.floorMeterWisePrice.priceMeter3 = selectedPrice.priceMeter3;
    //             this.floorMeterWisePrice.isAddMeterRangePrice = selectedPrice.isAddMeterRangePrice;
    //             this.floorMeterWisePrice.price = roomDetail.floorPrice;
    //         }
    //     }
    // }

    // calculateFloor(roomId) {
    //     this.calculateFloorPrice(this.selectedRoomOnEdit.offerRoomId);
    //     this.calculatePrice(roomId);
    // }


    // calculateFloorFixedPrice(roomId) {
    //     this.selectedRoomOnEdit.notCalculateFloorPrice = true;
    //     this.calculateFloorPrice(this.selectedRoomOnEdit.offerRoomId);
    //     this.calculatePrice(roomId);
    // }

    // AddFloor() {
    //     this.isAddFloor = true;
    //     this.calculatePrice(this.selectedRoomOnEdit.offerRoomId)
    // }

    // addFloorQty(isAddExtraFloorQty) {
    //     if (!isAddExtraFloorQty) {
    //         this.selectedRoomOnEdit.isAddExtraFloorQty = true;
    //     } else {
    //         this.selectedRoomOnEdit.isAddExtraFloorQty = false;
    //         this.selectedRoomOnEdit.floorExtraQty1 = 0;
    //         this.selectedRoomOnEdit.floorExtraQty2 = 0;
    //         this.selectedRoomOnEdit.floorExtraPrice1 = 0;
    //         this.selectedRoomOnEdit.floorExtraPrice2 = 0;
    //         this.calculatePrice(this.selectedRoomOnEdit.offerRoomId);
    //     }
    // }

    //Clear Floor Price
    // clearFloorPriceDetail(roomId, index) {
    //     var i = index;
    //     this.selectedRoomOnEdit.paintFloor = false;
    //     this.selectedRoomOnEdit.floorMaterialId = null
    //     this.selectedRoomOnEdit.floorMaterialPriceId = null;
    //     this.selectedRoomOnEdit.floorTotalArea = 0;
    //     this.selectedRoomOnEdit.floorTotalPrice = 0;
    //     this.selectedRoomOnEdit.floorPrice = 0;
    //     this.selectedRoomOnEdit.floorColorCode = null;
    //     this.selectedRoomOnEdit.floorMaterialReminder = null;

    //     var room = this.OfferTypeDetail.offerRoomPriceDetailList.find(o => o.offerRoomId == roomId);
    //     if (!room.floorWidth) {
    //         room.floorWidth = room.wallWidth;
    //     } if (!room.floorLength) {
    //         room.floorLength = room.wallLength;
    //     }
    //     if (room.floorExtraQty1 > 0 || room.floorExtraQty2 > 0
    //         || room.floorExtraPrice1 > 0 || room.floorExtraPrice2 > 0) {
    //         room.isAddExtraFloorQty = true;
    //     }

    //     //this.selectedRoomOnEdit = room;
    //     //this.OfferCategoryform.patchValue(this.selectedRoomOnEdit);
    //     // this.OfferTypeDetail.offerRoomPriceDetailList[i] = this.MasterOfferRoomList[i];
    //     this.OfferTypeDetail.offerRoomPriceDetailList[i].floorMaterialName = null;
    //     this.OfferTypeDetail.offerRoomPriceDetailList[i].floorPaintKey = null;
    //     this.calculatePrice(roomId);
    // }

    // Paint Floor END

    /**
     *  Commom START
     **/
    AddMaterialForAll() {
        const dialogRef = this.dialog.open(OfferV2FormMaterialSelectForAllComponent, {
            height: '95%',
            width: '90%',
            data: {
                materialList: this.MaterialObject,
                offerTypeDetail: this.OfferTypeDetail,
                projectOffer: this.projectOffer,
                projectPriceDiscount: this.projectPriceDiscount,
                customerDetailObj: this.customerDetailObj,
                roomList: this.roomList
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != null) {
                this.OfferTypeDetail = result;
                if (this.OfferTypeDetail.offerDiverseDetailList.length > 0)
                    this.OfferTypeDetail.offerDiverseDetailList = this.sortPipe.transform(this.OfferTypeDetail.offerDiverseDetailList, "asc", "roomName");
                this.calculateRoomTotal();
                this.calculateDiversePrise();
                // if (this.OfferTypeDetail.offerRoomPriceDetailList)
                //     this.gulvCheckedCount = this.OfferTypeDetail.offerRoomPriceDetailList.filter(x => x.paintFloor).length;
                // this.getSumOfM2ForWallceilingFloor();
            }
        });
    }


    // calculatePrice(roomId) {
    //     var roomDetail = this.OfferTypeDetail.offerRoomPriceDetailList.find(o => o.offerRoomId == roomId);
    //     //Wall calculation
    //     roomDetail.wallTotalArea = (((roomDetail.wallLength + roomDetail.wallWidth) * 2) * roomDetail.ceilingHeight)
    //         + roomDetail.wallExtraQty1 + roomDetail.wallExtraQty2;
    //     roomDetail.wallTotalPrice = (roomDetail.wallTotalArea * roomDetail.wallPrice)
    //         + roomDetail.wallExtraPrice1 + roomDetail.wallExtraPrice2;

    //     //ceiling calculation
    //     roomDetail.ceilingTotalArea = (roomDetail.ceilingLength * roomDetail.ceilingWidth)
    //         + roomDetail.ceilingExtraQty1 + roomDetail.ceilingExtraQty2;
    //     roomDetail.ceilingTotalPrice = (roomDetail.ceilingTotalArea * roomDetail.ceilingPrice)
    //         + roomDetail.ceilingExtraPrice1 + roomDetail.ceilingExtraPrice2;

    //     //floor calculation
    //     roomDetail.floorExtraPrice1 = roomDetail.floorExtraPrice1 ? roomDetail.floorExtraPrice1 : 0;
    //     roomDetail.floorExtraPrice2 = roomDetail.floorExtraPrice2 ? roomDetail.floorExtraPrice2 : 0;
    //     roomDetail.floorExtraQty1 = roomDetail.floorExtraQty1 ? roomDetail.floorExtraQty1 : 0;
    //     roomDetail.floorExtraQty2 = roomDetail.floorExtraQty2 ? roomDetail.floorExtraQty2 : 0;
    //     roomDetail.floorPrice = roomDetail.floorPrice ? roomDetail.floorPrice : 0;

    //     roomDetail.floorTotalArea = (roomDetail.floorLength * roomDetail.floorWidth)
    //         + roomDetail.floorExtraQty1 + roomDetail.floorExtraQty2;

    //     roomDetail.floorTotalPrice = (roomDetail.floorTotalArea * roomDetail.floorPrice)
    //         + roomDetail.floorExtraPrice1 + roomDetail.floorExtraPrice2;

    //     roomDetail.totalPrice = 0;
    //     if (roomDetail.paintWall == true)
    //         roomDetail.totalPrice += roomDetail.wallTotalPrice;
    //     if (roomDetail.paintCeiling == true)
    //         roomDetail.totalPrice += roomDetail.ceilingTotalPrice;
    //     if (roomDetail.paintFloor == true)
    //         roomDetail.totalPrice += roomDetail.floorTotalPrice;

    //     //var addedRoomList = this.OfferTypeDetail.offerRoomPriceDetailList.filter(o => o.addToOffer == true);
    //     this.OfferTypeDetail.priceTotal = 0;
    //     for (var i = 0; i < this.OfferTypeDetail.offerRoomPriceDetailList.length; i++) {
    //         if (this.OfferTypeDetail.offerRoomPriceDetailList[i].paintWall) {
    //             this.OfferTypeDetail.priceTotal += this.OfferTypeDetail.offerRoomPriceDetailList[i].wallTotalPrice;
    //         }
    //         if (this.OfferTypeDetail.offerRoomPriceDetailList[i].paintCeiling) {
    //             this.OfferTypeDetail.priceTotal += this.OfferTypeDetail.offerRoomPriceDetailList[i].ceilingTotalPrice;
    //         }
    //         if (this.OfferTypeDetail.offerRoomPriceDetailList[i].paintFloor) {
    //             this.OfferTypeDetail.priceTotal += this.OfferTypeDetail.offerRoomPriceDetailList[i].floorTotalPrice;
    //         }
    //     }
    //     this.OfferTypeDetail.priceTotal = this.OfferTypeDetail.priceTotal + this.grandTotal;
    //     this.OfferTypeDetail.price = this.OfferTypeDetail.priceTotal;
    //     if (this.OfferTypeDetail.offerRoomPriceDetailList)
    //         this.gulvCheckedCount = this.OfferTypeDetail.offerRoomPriceDetailList.filter(x => x.paintFloor).length;
    //     if (this.OfferTypeDetail.discount > 0) {
    //         this.calculateDiscountedAmt();
    //     } else {
    //         this.OfferTypeDetail.discount = 0;
    //         this.calculateVatAmt();
    //     }
    //     this.updateRoomWidthAndLength(roomDetail);
    //     this.getSumOfM2ForWallceilingFloor();
    // }

    calculateDiscountedAmt() {
        //this.OfferTypeDetail.discountByProjectAmount = (this.OfferTypeDetail.priceTotal * this.projectPriceDiscount/100);
        if (this.OfferTypeDetail.travelingAmount > 0) {
            this.OfferTypeDetail.price = ((this.OfferTypeDetail.priceTotal + this.OfferTypeDetail.travelingAmount) - this.OfferTypeDetail.discount);
        } else {
            this.OfferTypeDetail.price = this.OfferTypeDetail.priceTotal - this.OfferTypeDetail.discount;
        }
        this.calculateVatAmt();
    }
    calculateTravelingAmt() {
        this.OfferTypeDetail.price = this.OfferTypeDetail.priceTotal + this.OfferTypeDetail.travelingAmount;
        this.calculateVatAmt();
    }
    calculateVatAmt() {
        this.OfferTypeDetail.vat = ((this.OfferTypeDetail.price) * 25 / 100);
        this.OfferTypeDetail.totalInclVAT = (this.OfferTypeDetail.price + (this.OfferTypeDetail.price * 25 / 100));
       console.log("drivingAmountTotal",this.OfferTypeDetail.drivingAmountTotal);
        this.OfferTypeDetail.numberOfHours = (this.OfferTypeDetail.drivingAmountTotal / this.projectsCoworkerPrice);
        this.OfferTypeDetail.numberOfDays = Math.ceil(Math.ceil((this.OfferTypeDetail.numberOfHours / 7.5) / 0.25) * 0.25);
        if (this.OfferTypeDetail.travelingAmount) {
            this.calculateDistanceAmt();
        }
    }

    SavePDF(flag) {
        //this.showSpinner = true;
        this.projectofferService.GetSingleOfferPDF(this.OfferTypeDetail.offerId, this.OfferTypeDetail.id, flag, this.isIncludePriceOnPDF, this.isShowRoomTotal).subscribe((response: ResponseBase) => {
            var resultData = response.result;
            var data = response.result.base64.replace("data:application/pdf;base64,", "");
            this.OfferNumber = JSON.parse(localStorage.getItem("OfferNumber"));
            const dialogRef = this.dialog.open(PdfViewerModelComponent, {
                height: '90%',
                width: '90%',
                data: {
                    base64: data,
                    filename: response.result.filename ? response.result.filename + ".pdf" : "Tilbud_" + this.OfferNumber
                }
            });

            dialogRef.afterClosed().subscribe(result => {
                if (result) {

                }
            });

            // var binary = atob(data.replace(/\s/g, ''));
            // var len = binary.length;
            // var buffer = new ArrayBuffer(len);
            // var view = new Uint8Array(buffer);
            // for (var i = 0; i < len; i++) {
            //     view[i] = binary.charCodeAt(i);
            // }
            // var blob = new Blob([view], { type: "application/pdf" });
            // var url = URL.createObjectURL(blob);

            // var link = document.createElement('a');
            // link.innerHTML = 'Download PDF file';
            // //link.download = 'Projekt_Faktura#' + sagNumber + '.pdf';
            // link.href = url;
            // link.target = "_blank";
            // //document.body.appendChild(link);
            // link.click();

        },
            error => (err) => {
                //this.showSpinner = false;
            },
            () => {
            });
    }
    // Common END

    /**
     *  OTHERS START
     */

    // Code to Prevent accordion from expantion
    expandPanel(matExpansionPanel: MatExpansionPanel, event: Event): void {
        event.stopPropagation(); // Preventing event bubbling

        if (!this._isExpansionIndicator(event.target)) {
            matExpansionPanel.close(); // Here's the magic
        }
    }

    private _isExpansionIndicator(target: EventTarget): boolean {
        const expansionIndicatorClass = 'mat-expansion-indicator';
        return ((<HTMLElement>target).classList && (<HTMLElement>target).classList.contains(expansionIndicatorClass));
    }

    // methode to expand current editing acordion
    OpenAccordion(objEditingOff) {
        this.OfferTypeDetail.offerRoomDetailList.filter(off => {
            if (off.offerRoomId == objEditingOff.offerRoomId)
                off.isExpand = true;
            else
                off.isExpand = false;
        });
    }

    // methode to collaps current editing acordion
    CloseAccordion(objEditingReq) {
        this.OfferTypeDetail.offerRoomDetailList.filter(off => {
            if (off.offerRoomId == objEditingReq.offerRoomId)
                off.isExpand = false;
        });
    }
    //END

    gotoMaterial(id) {
        this.matObj = this.MaterialObject.filter(x => x.id == id);
        if (this.matObj && this.matObj.length > 0) {
            this.matObj[0].materialID;
            const dialogRef = this.dialog.open(NewMaterialComponent, {
                height: '800px',
                width: '80%',
                data: this.matObj[0].materialID,
            });

            dialogRef.afterClosed().subscribe(result => {
                this.titleService.SetTitle("TITLE_PROJECTS_OFFER");

            });
        }
    }

    GetMaterialSearchClear(value, i) {
        if (!value) {
            this.materialTypeDropdownSearch(i);
        }
    }

    materialTypeDropdownSearch(i) {
        if (this.OfferTypeDetail.offerDiverseDetailList[i].type)
            this.OfferTypeDetail.offerDiverseDetailList[i].materialList = this.MasterMaterialTypeList.filter(o => { return o.type.split(',').find(p => p == this.OfferTypeDetail.offerDiverseDetailList[i].type && o.text.toLowerCase().includes(this.MaterialTypeSearchForDiverse.toLowerCase())) });
        //this.OfferTypeDetail.offerDiverseDetailList[i].materialList = this.MasterMaterialTypeList.filter(o => o.text.toLowerCase().includes(this.MaterialTypeSearchForDiverse.toLowerCase()));
        else
            this.OfferTypeDetail.offerDiverseDetailList[i].materialList = this.MasterMaterialTypeList.filter(o => o.text.toLowerCase().includes(this.MaterialTypeSearchForDiverse.toLowerCase()));
    }

    getDiverseMaterial(type, i) {
        if (type == 0) {
            this.OfferTypeDetail.offerDiverseDetailList[i].materialList = this.MasterMaterialTypeList;
        } else {
            this.OfferTypeDetail.offerDiverseDetailList[i].materialList = this.MasterMaterialTypeList.filter(o => { return o.type.split(',').find(p => p == type) });
        }
        if (this.OfferTypeDetail.offerDiverseDetailList[i].materialList.length > 0) {
            this.OfferTypeDetail.offerDiverseDetailList[i].materialID = this.OfferTypeDetail.offerDiverseDetailList[i].materialList[0].id;
            this.onChangeMaterialSelection(this.OfferTypeDetail.offerDiverseDetailList[i].materialID, this.OfferTypeDetail.offerDiverseDetailList[i]);
        }
        else {
            this.OfferTypeDetail.offerDiverseDetailList[i].materialID = "";
        }
        this.updateDiverseQty(this.OfferTypeDetail.offerDiverseDetailList[i]);
    }

    getDiverseMaterialOnEdit() {
        for (let i = 0; i < this.OfferTypeDetail.offerDiverseDetailList.length; i++) {
            if (this.OfferTypeDetail.offerDiverseDetailList[i].type) {
                //this.OfferTypeDetail.offerDiverseDetailList[i].materialList = this.MasterMaterialTypeList.filter(o => o.type == this.OfferTypeDetail.offerDiverseDetailList[i].type);
                this.OfferTypeDetail.offerDiverseDetailList[i].materialList = this.MasterMaterialTypeList.filter(o => { return o.type.split(',').find(p => p == this.OfferTypeDetail.offerDiverseDetailList[i].type) });
            } else {
                this.OfferTypeDetail.offerDiverseDetailList[i].materialList = this.MasterMaterialTypeList;
            }
        }
    }

    addNewItem() {
        var offerPrice = new DiverseDTO();
        offerPrice.offerCategoryId = this.OfferTypeDetail.offerCategoryId;
        offerPrice.offerId = this.OfferTypeDetail.offerId;
        offerPrice.qty = 1;
        offerPrice.imagePath = "Upload/NoImageAvailable.png";
        offerPrice.colorCode = "";
        offerPrice.materialList = this.MasterMaterialTypeList.map(x => Object.assign({}, x));
        if (!this.OfferTypeDetail.offerDiverseDetailList) {
            this.OfferTypeDetail.offerDiverseDetailList = [];
        }
        this.OfferTypeDetail.offerDiverseDetailList.push(offerPrice);
    }

    deleteOfferItem(index, item) {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: "200px",
            data: { Header: 'Headers.DeleteDiverseMaterial' }
        })

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                if (item.id) {
                    var total = (this.OfferTypeDetail.priceTotal - item.priceTotal);
                    this.projectofferService.deleteDiverseOfferItem(item.id, item.offerTypeID, total)
                        .subscribe((response: ResponseBase) => {
                            this.OfferTypeDetail.offerDiverseDetailList.splice(index, 1);
                            this.getOfferTypeData();
                        },
                            error => (err) => {
                                //this.showSpinner = false;
                            },
                            () => {
                            });
                }
                else {
                    this.OfferTypeDetail.offerDiverseDetailList.splice(index, 1);
                    this.checkUncheckPanlesDoorframeRadiotorItem();
                }
                this.calculateDiversePrise();
            }
        });

    }

    calculateDiversePrise() {
        this.grandTotal = 0;
        this.OfferTypeDetail.priceTotal = 0;
        this.OfferTypeDetail.drivingAmountTotal = 0;
        var totalByRoom = 0
        this.paintTimer = 0;
        if (!this.OfferTypeDetail.offerRoomDetailList) {
            this.OfferTypeDetail.offerRoomDetailList = [];
        }
        // if (this.OfferTypeDetail.offerRoomPriceDetailList.length > 0) {
        //     this.OfferTypeDetail.offerRoomPriceDetailList.filter((item) => {
        //         totalByRoom = totalByRoom + item.totalPrice;
        //     });
        // }
        for (var i = 0; i < this.OfferTypeDetail.offerRoomDetailList.length; i++) {
            totalByRoom += this.OfferTypeDetail.offerRoomDetailList[i].offerRoomCalculation.filter(x => x.isPaint == true).reduce((sum, current) => sum + current.totalPrice, 0)
            this.OfferTypeDetail.drivingAmountTotal += this.OfferTypeDetail.offerRoomDetailList[i].offerRoomCalculation.filter(x => x.isPaint == true).reduce((sum, current) => sum + current.drivingAmountTotal, 0)
            this.paintTimer += this.OfferTypeDetail.offerRoomDetailList[i].offerRoomCalculation.filter(x => x.isPaint == true).reduce((sum, current) => sum + current.paintTimerTotal, 0)
        }
        this.OfferTypeDetail.priceTotal = totalByRoom;
        for (var i = 0; i < this.OfferTypeDetail.offerDiverseDetailList.length; i++) {
            this.OfferTypeDetail.offerDiverseDetailList[i].priceTotal = this.OfferTypeDetail.offerDiverseDetailList[i].qty * this.OfferTypeDetail.offerDiverseDetailList[i].price;
            //this.OfferTypeDetail.offerDiverseDetailList[i].priceTotal = this.OfferTypeDetail.offerDiverseDetailList[i].price;
            if (this.OfferTypeDetail.offerDiverseDetailList[i].priceTotal > 0) {
                this.grandTotal = this.grandTotal + this.OfferTypeDetail.offerDiverseDetailList[i].priceTotal;
            }
            else {
                this.OfferTypeDetail.offerDiverseDetailList[i].priceTotal = 0;
            }
            if(!this.OfferTypeDetail.offerDiverseDetailList[i].notCalculateToDriving)
                this.OfferTypeDetail.drivingAmountTotal += this.OfferTypeDetail.offerDiverseDetailList[i].priceTotal;
            this.getOfferItemTimer(this.OfferTypeDetail.offerDiverseDetailList[i]);
        }
        this.OfferTypeDetail.priceTotal = this.OfferTypeDetail.priceTotal + this.grandTotal;
        this.calculateDiscountedAmt();
    }

    copyOfferItem(index, item) {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: "200px",
            data: { Header: 'Headers.CopyMaterial' }
        })

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                if (item.type)
                    this.tempMaterialList = this.MasterMaterialTypeList.filter(x => x.type.split(',').find(p => p == item.type))
                else
                    this.tempMaterialList = this.MasterMaterialTypeList;
                var offerPrice = new DiverseDTO();
                offerPrice.offerCategoryId = this.OfferTypeDetail.offerCategoryId;
                offerPrice.offerId = this.OfferTypeDetail.offerId;
                offerPrice.text = item.text;
                offerPrice.roomId = item.roomId;
                offerPrice.type = item.type;
                offerPrice.notCalculateToDriving = item.notCalculateToDriving;
                offerPrice.materialID = item.materialID;
                offerPrice.materialPriceID = item.materialPriceID;
                offerPrice.price = item.price;
                offerPrice.colorCode = item.colorCode;
                offerPrice.unit = item.unit;
                offerPrice.qty = item.qty;
                offerPrice.materialList = this.tempMaterialList;
                this.OfferTypeDetail.offerDiverseDetailList.push(offerPrice);
                if (item.materialID)
                    this.getMaterialPrice(offerPrice.materialID, offerPrice, offerPrice.materialPriceID)
                this.calculateDiversePrise();
            }
        });
    }

    getDefaultMaterialList() {
        this.projectofferService.getDefaultMaterialList().subscribe({
            next: (response: ResponseBase) => {
                this.DefaultMaterilaList = response.result.myResponse;
                setTimeout(() => {
                    if (this.selectedCategoryInput.id == "")
                        this.addDefaultMaterial();
                }, 500);
            },
            error: err => { },
            complete: () => { }
        });
    }

    addDefaultMaterial() {
        if (this.offerCategoryMaterialList && this.offerCategoryMaterialList.length > 0) {
            this.offerCategoryMaterialList.forEach(item => {
                this.DefaultMaterilaList = [];
                var selectedMaterialObj: OfferMaterialWithPrice;
                //this.DefaultMaterilaList = this.MaterialObjectForDiverse.filter(x =>x.id == item.offerMaterialID);
                if (item.materialTypeID) {
                    this.DefaultMaterilaList.push(...this.MaterialObjectForDiverse.filter(x => x.type.split(',').find(p => p == item.materialTypeID.toString())));
                    selectedMaterialObj = this.DefaultMaterilaList.find(o => o.id == item.offerMaterialID);
                }
                else if (item.offerMaterialID) {
                    this.DefaultMaterilaList = this.MaterialObjectForDiverse;
                    selectedMaterialObj = this.DefaultMaterilaList.find(o => o.id == item.offerMaterialID);
                }
                var tempMaterialData = this.selectedCategoryInput.offerCategoryMaterial.filter(o => o.id == item.id && o.materialQty > 0);
                if (tempMaterialData.length > 0 && item.offerMaterialID) {
                    tempMaterialData.filter(obj => {
                        var tempCategory = this.selectedCategoryInput.offerCategoriesJson.filter(x => x.id == obj.offerCategoryId)[0];
                        // var tempParentCategory = new OfferCategory();
                        // if(tempCategory && tempCategory.length > 0 && tempCategory[0].parentID)
                        //     tempParentCategory = this.selectedCategoryInput.offerCategoriesJson.filter(x => x.id == tempCategory[0].parentID)[0];
                        var isPaintRooms = obj.roomList.filter(r => r.isChecked);
                        if (obj.roomList.length > 0 && isPaintRooms.length > 0) {
                            for (let i = 0; i < obj.roomList.length; i++) {
                                if (obj.roomList[i].isChecked) {
                                    var offerPrice = new DiverseDTO();
                                    offerPrice.offerCategoryId = this.OfferTypeDetail.offerCategoryId;
                                    offerPrice.offerId = this.OfferTypeDetail.offerId;
                                    offerPrice.materialID = selectedMaterialObj.id;
                                    offerPrice.text = selectedMaterialObj.text;
                                    offerPrice.notCalculateToDriving = selectedMaterialObj.notCalculateToDriving;
                                    offerPrice.qty = obj && obj.materialQty > 0 ? obj.materialQty : 0;
                                    offerPrice.colorCode = obj.colorCode ? obj.colorCode : null;
                                    offerPrice.unit = selectedMaterialObj.unit;
                                    offerPrice.materialList = this.DefaultMaterilaList;
                                    offerPrice.type = item.materialTypeID;
                                    offerPrice.roomId = obj.roomList[i].id;
                                    offerPrice.materialPriceID = obj.materialPriceID;
                                    offerPrice.length = obj && obj.length > 0 ? obj.length : 0;
                                    offerPrice.width = obj && obj.width > 0 ? obj.width : 0;
                                    offerPrice.treatmentType = obj.treatmentType ? obj.treatmentType : selectedMaterialObj.treatmentType;
                                    offerPrice.extraM2QtyWall = obj.extraM2QtyWall;
                                    offerPrice.extraM2QtyCeiling = obj.extraM2QtyCeiling;
                                    offerPrice.extraM2QtyFloor = obj.extraM2QtyFloor;
                                    offerPrice.extraM2PriceWall = obj.extraM2PriceWall;
                                    offerPrice.extraM2PriceCeiling = obj.extraM2PriceCeiling;
                                    offerPrice.extraM2PriceFloor = obj.extraM2PriceFloor;
                                    offerPrice.offerCategory = tempCategory;
                                    this.OfferTypeDetail.offerDiverseDetailList.push(offerPrice);
                                }
                            }
                        } else {
                            var offerPrice = new DiverseDTO();
                            offerPrice.offerCategoryId = this.OfferTypeDetail.offerCategoryId;
                            offerPrice.offerId = this.OfferTypeDetail.offerId;
                            offerPrice.materialID = selectedMaterialObj.id;
                            offerPrice.text = selectedMaterialObj.text;
                            offerPrice.notCalculateToDriving = selectedMaterialObj.notCalculateToDriving;
                            offerPrice.qty = obj && obj.materialQty > 0 ? obj.materialQty : 0;
                            offerPrice.colorCode = obj.colorCode ? obj.colorCode : null;
                            offerPrice.unit = selectedMaterialObj.unit;
                            offerPrice.materialList = this.DefaultMaterilaList;
                            offerPrice.type = item.materialTypeID;
                            offerPrice.materialPriceID = obj.materialPriceID;
                            offerPrice.length = obj && obj.length > 0 ? obj.length : 0;
                            offerPrice.width = obj && obj.width > 0 ? obj.width : 0;
                            offerPrice.treatmentType = obj.treatmentType ? obj.treatmentType : selectedMaterialObj.treatmentType;
                            offerPrice.extraM2QtyWall = obj.extraM2QtyWall;
                            offerPrice.extraM2QtyCeiling = obj.extraM2QtyCeiling;
                            offerPrice.extraM2QtyFloor = obj.extraM2QtyFloor;
                            offerPrice.extraM2PriceWall = obj.extraM2PriceWall;
                            offerPrice.extraM2PriceCeiling = obj.extraM2PriceCeiling;
                            offerPrice.extraM2PriceFloor = obj.extraM2PriceFloor;
                            offerPrice.offerCategory = tempCategory;
                            this.OfferTypeDetail.offerDiverseDetailList.push(offerPrice);
                        }
                    })

                }
                else if (!item.offerMaterialID) {
                    tempMaterialData.filter(obj => {
                        var offerPrice = new DiverseDTO();
                        offerPrice.offerCategoryId = this.OfferTypeDetail.offerCategoryId;
                        offerPrice.offerId = this.OfferTypeDetail.offerId;
                        offerPrice.materialID = null;
                        offerPrice.text = obj.offerMaterialText ? obj.offerMaterialText : "";
                        //offerPrice.notCalculateToDriving = obj.notCalculateToDriving;
                        offerPrice.price = obj.price ? obj.price : 0;
                        offerPrice.qty = obj && obj.materialQty > 0 ? obj.materialQty : 0;
                        offerPrice.colorCode = obj.colorCode ? obj.colorCode : null;
                        offerPrice.unit = null;
                        offerPrice.materialList = null;
                        offerPrice.type = item.materialTypeID;
                        offerPrice.materialPriceID = null;
                        offerPrice.length = obj && obj.length > 0 ? obj.length : 0;
                        offerPrice.width = obj && obj.width > 0 ? obj.width : 0;
                        this.OfferTypeDetail.offerDiverseDetailList.push(offerPrice);
                    });
                }
            })
            if (this.OfferTypeDetail.offerDiverseDetailList.length > 0) {
                this.OfferTypeDetail.offerDiverseDetailList.forEach(item => {
                    var materialPriceID = item.materialPriceID ? item.materialPriceID : "";
                    if (item.materialID)
                        this.getMaterialPriceForDefaultMaterial(item.materialID, item, materialPriceID)
                        //this.getMaterialPrice(item.materialID, item, materialPriceID)
                })
            }
            this.calculateDiversePrise();
        }
    }

    getMaterialPrice(materialId, offerItem, materialPriceId) {
        var materialprice = this.MaterialObjectForDiverse.find(o => o.id == materialId);
        if (materialprice) {
            offerItem.imagePath = materialprice.imagePath;
            offerItem.selectCategoryTimeForDiverse = [];
            offerItem.isUnitPrint = materialprice.addUnitOnPDF;
            offerItem.notCalculateToDriving = materialprice.notCalculateToDriving;
            if(materialprice.unit == "stk.")
                offerItem.isQuantityPrint=true;
            else
                offerItem.isQuantityPrint=false;
            for (var i = 0; i < materialprice.offerMaterialPriceList.length; i++) {
                var obj = this.SelectCategoryTime.find(o => o.id == materialprice.offerMaterialPriceList[i].type);
                if (obj) {
                    if (i == 0) {
                        offerItem.materialPriceID = materialprice.offerMaterialPriceList[i].id;
                        offerItem.price = materialprice.offerMaterialPriceList[i].price;
                        offerItem.imagePath;
                        offerItem.colorCode = offerItem.colorCode;
                    }
                    offerItem.selectCategoryTimeForDiverse.push({
                        id: obj.id,
                        key: obj.key,
                        extraValue: materialprice.offerMaterialPriceList[i].paintText,
                        priceId: materialprice.offerMaterialPriceList[i].id,
                        reminder: materialprice.offerMaterialPriceList[i].reminder,
                    });
                    if (materialprice.offerMaterialPriceList[i].id == materialPriceId) {
                        offerItem.materialPriceID = materialprice.offerMaterialPriceList[i].id;
                        offerItem.price = materialprice.offerMaterialPriceList[i].price;
                        offerItem.text = materialprice.text + ' ' + (materialprice.offerMaterialPriceList[i].paintText != undefined ? materialprice.offerMaterialPriceList[i].paintText : "");
                        var offerReminderText = materialprice.offerMaterialPriceList[i].reminder ? materialprice.offerMaterialPriceList[i].reminder : "";
                    }
                    else {
                        offerItem.text = materialPriceId ? offerItem.text : materialprice.text + ' ' + materialprice.offerMaterialPriceText;
                        offerItem.colorCode = offerItem.colorCode ? offerItem.colorCode : materialprice.colorCode;
                        offerItem.unit = materialprice.unit;
                        var offerReminderText = materialprice.offerMaterialPriceList[i].reminder ? materialprice.offerMaterialPriceList[i].reminder : "";
                    }

                    if (materialprice.offerMaterialPriceList[i].isDefault && materialPriceId == "") {
                        offerItem.materialPriceID = materialprice.offerMaterialPriceList[i].id;
                        offerItem.price = materialprice.offerMaterialPriceList[i].price;
                        var offerReminderText = materialprice.offerMaterialPriceList[i].reminder ? materialprice.offerMaterialPriceList[i].reminder : "";
                        //offerItem.text = materialprice.text + ' ' + materialprice.offerMaterialPriceList[i].paintText;
                        offerItem.text = materialprice.text + ' ' + (materialprice.offerMaterialPriceList[i].paintText != undefined ? materialprice.offerMaterialPriceList[i].paintText : "") + (materialprice.addNumberOfTimesOnPDF ? this.projectofferService.getPaintType(materialprice.offerMaterialPriceList[i].type) : "");
                        if (offerItem.materialList && offerItem.materialList.length > 0) {
                            offerItem.materialList.filter(m => {
                                if (m.id == materialId) {
                                    var tempPriceData = m.offerMaterialPriceList.filter(x => x.id == offerItem.materialPriceID);
                                    if (tempPriceData && tempPriceData.length > 0)
                                        m.offerMaterialPriceText = tempPriceData[0].paintText;
                                }
                            })
                        }
                    }
                    offerItem.originalPrice = offerItem.price;
                    if (offerReminderText && materialprice.reminder) {
                        offerItem.reminder = materialprice.reminder + " - " + offerReminderText;
                    } else if (offerReminderText && !materialprice.reminder) {
                        offerItem.reminder = offerReminderText;
                    } else {
                        offerItem.reminder = materialprice.reminder ? materialprice.reminder : "";
                    }
                    if (!offerItem.id && materialprice.isPostNumberDiscount && this.projectOffer && this.projectOffer.percentage != 0 && !this.customerDetailObj.doNotCalculatePriceForPostNumber)
                        offerItem.price = offerItem.price + ((offerItem.price * this.projectOffer.percentage) / 100);
                    if (!offerItem.id && this.projectPriceDiscount && (this.customerDetailObj && !this.customerDetailObj.doNotCalculatePriceForPriceDiscount))
                        offerItem.price = offerItem.price + (offerItem.price * this.projectPriceDiscount / 100);
                    this.getOfferDiverseMeterPrice(offerItem);
                }
            }
            this.checkUncheckPanlesDoorframeRadiotorItem();
            this.setOfferTextForNumberOfTimesOnPDF(materialId, offerItem)
        }
        this.calculateDiversePrise();
    }

    getMaterialPriceOnCategoryTimeSelect(priceId, materialId, offerItem) {
        this.getMaterialPrice(materialId, offerItem, priceId);
        if (priceId && offerItem && offerItem.materialList) {
            offerItem.materialList.filter(m => {
                if (m.id == materialId) {
                    var tempPriceData = m.offerMaterialPriceList.filter(x => x.id == priceId);
                    if (tempPriceData && tempPriceData.length > 0)
                        m.offerMaterialPriceText = tempPriceData[0].paintText;
                }
            })
        }
    }

    getOfferDiverseMeterPrice(offerItem) {
        var selectedMaterial = this.MaterialObject.find(o => o.id == offerItem.materialID);
        if (selectedMaterial) {
            var selectedPrice = selectedMaterial.offerMaterialPriceList.find(o => o.id == offerItem.materialPriceID);
            if (selectedPrice) {
                var price = selectedPrice.price;
                if (selectedPrice.isAddMeterRangePrice) {
                    if (offerItem.materialPriceID) {
                        if (offerItem.qty >= selectedPrice.startMeter1 && offerItem.qty <= selectedPrice.endMeter1) {
                            price = selectedPrice.priceMeter1;
                        }
                        else if (offerItem.qty >= selectedPrice.startMeter2 && offerItem.qty <= selectedPrice.endMeter2) {
                            price = selectedPrice.priceMeter2;
                        }
                        else if (offerItem.qty >= selectedPrice.startMeter3 && offerItem.qty <= selectedPrice.endMeter3) {
                            price = selectedPrice.priceMeter3;
                        }
                        else {
                            price = 0; //selectedPrice.price;
                        }
                    }
                }
                offerItem.reminder = selectedPrice.reminder ? selectedPrice.reminder : "";
                offerItem.price = price;
                offerItem.originalPrice = offerItem.price;
                if (!offerItem.id && selectedMaterial.isPostNumberDiscount && this.projectOffer && this.projectOffer.percentage != 0 && !this.customerDetailObj.doNotCalculatePriceForPostNumber)
                    offerItem.price = offerItem.price + ((offerItem.price * this.projectOffer.percentage) / 100);
                if (!offerItem.id && this.projectPriceDiscount && (this.customerDetailObj && !this.customerDetailObj.doNotCalculatePriceForPriceDiscount))
                    offerItem.price = offerItem.price + (offerItem.price * this.projectPriceDiscount / 100);
                this.calculateDiversePrise();
            }
        }
    }

    // getWallMaterial(type, isEdit) {
    //     if (type == 0)
    //         this.wallMaterialObject = this.MasterMaterialTypeList;
    //     else
    //         this.wallMaterialObject = this.MasterMaterialTypeList.filter(o => { return o.type.split(',').find(p => p == type) });
    //     if (!isEdit) {
    //         this.selectedRoomOnEdit.wallMaterialId = this.wallMaterialObject[0].id;
    //         this.getWallPriceDetail(this.selectedRoomOnEdit.wallMaterialId, '', this.selectedRoomOnEdit.offerRoomId)
    //     }
    // }

    // getCeilingMaterial(type, isEdit) {
    //     if (type == 0)
    //         this.ceilingMaterialObject = this.MasterMaterialTypeList;
    //     else
    //         this.ceilingMaterialObject = this.MasterMaterialTypeList.filter(o => { return o.type.split(',').find(p => p == type) });
    //     if (!isEdit) {
    //         this.selectedRoomOnEdit.ceilingMaterialId = this.ceilingMaterialObject[0].id;
    //         this.getCeilingPriceDetail(this.selectedRoomOnEdit.ceilingMaterialId, '', this.selectedRoomOnEdit.offerRoomId)
    //     }
    // }

    // getFloorMaterial(type, isEdit) {
    //     if (type == 0)
    //         this.floorMaterialObject = this.MasterMaterialTypeList;
    //     else
    //         this.floorMaterialObject = this.MasterMaterialTypeList.filter(o => { return o.type.split(',').find(p => p == type) });
    //     if (!isEdit) {
    //         this.selectedRoomOnEdit.floorMaterialId = this.floorMaterialObject[0].id;
    //         this.getFloorPriceDetail(this.selectedRoomOnEdit.floorMaterialId, '', this.selectedRoomOnEdit.offerRoomId)
    //     }
    // }

    addPanlesDoorframeRadiotor(ischecked, value, roomId) {
        if (value == "panels") {
            var item = this.MasterMaterialTypeList.filter(o => { return o.type.split(',').find(p => p == this.offMaterialTypeIds.Paneler) });
            if (item.length > 0) {
                if (ischecked)
                    this.addPanlesDoorframeRadiotorItem(item, roomId, this.offMaterialTypeIds.Paneler);
            }
        }
        if (value == "doorFrame") {
            var item = this.MasterMaterialTypeList.filter(o => { return o.type.split(',').find(p => p == this.offMaterialTypeIds.Dorkarm) });
            if (item.length > 0) {
                if (ischecked)
                    this.addPanlesDoorframeRadiotorItem(item, roomId, this.offMaterialTypeIds.Dorkarm);
            }
        }
        if (value == "radiator") {
            var item = this.MasterMaterialTypeList.filter(o => { return o.type.split(',').find(p => p == this.offMaterialTypeIds.Radiator) });
            if (item.length > 0) {
                if (ischecked)
                    this.addPanlesDoorframeRadiotorItem(item, roomId, this.offMaterialTypeIds.Radiator);
            }
        }
        if (value == "dore") {
            var item = this.MasterMaterialTypeList.filter(o => { return o.type.split(',').find(p => p == this.offMaterialTypeIds.Dore) });
            if (item.length > 0) {
                if (ischecked)
                    this.addPanlesDoorframeRadiotorItem(item, roomId, this.offMaterialTypeIds.Dore);
            }
        }
    }

    addPanlesDoorframeRadiotorItem(offerMaterialList, roomId, type) {
        this.tempMaterialDetails = offerMaterialList[0];
        var roomDetail = this.roomList.filter(x => x.id == roomId)
        if (!this.OfferTypeDetail.offerDiverseDetailList) {
            this.OfferTypeDetail.offerDiverseDetailList = [];
        }
        var offerItem = new DiverseDTO();
        offerItem.offerCategoryId = this.OfferTypeDetail.offerCategoryId;
        offerItem.offerId = this.OfferTypeDetail.offerId;
        offerItem.roomId = roomId;
        offerItem.text = this.tempMaterialDetails.text;
        offerItem.type = type;
        offerItem.materialID = this.tempMaterialDetails.id;
        offerItem.materialPriceID = '';
        offerItem.colorCode = this.tempMaterialDetails.colorCode;
        offerItem.unit = this.tempMaterialDetails.unit;
        offerItem.treatmentType = this.tempMaterialDetails.treatmentType;
        var tempQty = 1;
        if (this.tempMaterialDetails) {
            if (this.tempMaterialDetails.treatmentType == this.treatmentTypeIds.WoodWork)
                tempQty = (roomDetail[0].width + roomDetail[0].length + roomDetail[0].width + roomDetail[0].length + roomDetail[0].extraM2QtyFloor);
            if (this.tempMaterialDetails.treatmentType == this.treatmentTypeIds.Walls)
                tempQty = (((roomDetail[0].width + roomDetail[0].length) * 2) * roomDetail[0].ceilingHeight + roomDetail[0].extraM2QtyWall);
            if (this.tempMaterialDetails.treatmentType == this.treatmentTypeIds.Ceiling)
                tempQty = (roomDetail[0].width * roomDetail[0].length + roomDetail[0].extraQty1 + roomDetail[0].extraM2QtyCeiling);
            offerItem.width = roomDetail[0].width;
            offerItem.length = roomDetail[0].length;
        }
        offerItem.qty = tempQty; //(type == this.offMaterialTypeIds.Paneler ? (this.selectedRoomOnEdit.wallWidth + this.selectedRoomOnEdit.wallLength + this.selectedRoomOnEdit.ceilingWidth + this.selectedRoomOnEdit.ceilingLength) : 1);
        offerItem.materialList = offerMaterialList;
        this.OfferTypeDetail.offerDiverseDetailList.push(offerItem);
        if (offerItem.materialID)
            this.getMaterialPrice(offerItem.materialID, offerItem, offerItem.materialPriceID)
        this.calculateDiversePrise();
    }

    checkUncheckPanlesDoorframeRadiotorItem() {
        if (this.OfferTypeDetail.offerRoomDetailList && this.OfferTypeDetail.offerRoomDetailList.length > 0) {
            this.OfferTypeDetail.offerRoomDetailList.filter(room => {
                var data = this.OfferTypeDetail.offerDiverseDetailList.filter(x => x.roomId == room.offerRoomId);
                if (data.length > 0) {
                    var tempPaneler = data.filter(x => x.type == this.offMaterialTypeIds.Paneler);
                    var tempDoorFrame = data.filter(x => x.type == this.offMaterialTypeIds.Dorkarm);
                    var tempRadiator = data.filter(x => x.type == this.offMaterialTypeIds.Radiator);
                    var tempDore = data.filter(x => x.type == this.offMaterialTypeIds.Dore);
                    if (tempPaneler.length > 0)
                        room.isPaintPanels = true;
                    else
                        room.isPaintPanels = false;
                    if (tempDoorFrame.length > 0)
                        room.isPaintDoorFrame = true;
                    else
                        room.isPaintDoorFrame = false;
                    if (tempRadiator.length > 0)
                        room.isPaintRadiator = true;
                    else
                        room.isPaintRadiator = false;
                    if (tempDore.length > 0)
                        room.isPaintDore = true;
                    else
                        room.isPaintDore = false;
                }
                else {
                    room.isPaintPanels = false;
                    room.isPaintDoorFrame = false;
                    room.isPaintRadiator = false;
                    room.isPaintDore = false;
                }
            });
        }
        // var data = this.OfferTypeDetail.offerDiverseDetailList.filter(x => x.roomId == this.selectedRoomOnEdit.offerRoomId);
        // if (data.length > 0) {
        //     var tempPaneler = data.filter(x => x.type == this.offMaterialTypeIds.Paneler);
        //     var tempDoorFrame = data.filter(x => x.type == this.offMaterialTypeIds.Dorkarm);
        //     var tempRadiator = data.filter(x => x.type == this.offMaterialTypeIds.Radiator);
        //     var tempDore = data.filter(x => x.type == this.offMaterialTypeIds.Dore);
        //     if (tempPaneler.length > 0)
        //         this.selectedRoomOnEdit.isPaintPanels = true;
        //     else
        //         this.selectedRoomOnEdit.isPaintPanels = false;
        //     if (tempDoorFrame.length > 0)
        //         this.selectedRoomOnEdit.isPaintDoorFrame = true;
        //     else
        //         this.selectedRoomOnEdit.isPaintDoorFrame = false;
        //     if (tempRadiator.length > 0)
        //         this.selectedRoomOnEdit.isPaintRadiator = true;
        //     else
        //         this.selectedRoomOnEdit.isPaintRadiator = false;
        //     if (tempDore.length > 0)
        //         this.selectedRoomOnEdit.isPaintDore = true;
        //     else
        //         this.selectedRoomOnEdit.isPaintDore = false;
        // }
        // else {
        //     this.selectedRoomOnEdit.isPaintPanels = false;
        //     this.selectedRoomOnEdit.isPaintDoorFrame = false;
        //     this.selectedRoomOnEdit.isPaintRadiator = false;
        //     this.selectedRoomOnEdit.isPaintDore = false;
        // }
    }

    autoSavePDF(value) {
        this.saveAndUpdateOffer(value)
    }

    openOfferProjectDescription() {
        var selectedTabData = new CompanyInfoSetting();
        selectedTabData.Description = this.OfferTypeDetail.offerText;
        const dialogRef = this.dialog.open(TextEditorComponent, {
            height: '90%',
            width: '90%',
            data: { selectedTabData, isEmail: false },
            disableClose: true
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result && result.Description) {
                this.OfferTypeDetail.offerText = result.Description;
                this.save(false);
            }
            else
                this.OfferTypeDetail.offerText = null;
        });
    }

    updateDiverseQty(offerItem) {
        if (offerItem.roomId && this.OfferTypeDetail.offerRoomDetailList.length > 0) {
            //var roomDetail = this.OfferTypeDetail.offerRoomDetailList.filter(x => x.offerRoomId == offerItem.roomId)
            var roomDetail = this.roomList.filter(x => x.id == offerItem.roomId)
            if (roomDetail.length > 0) {
                offerItem.roomName = roomDetail[0].name;
                if (offerItem.treatmentType > 0) {
                    var tempQty = 1;
                    if (offerItem.treatmentType == this.treatmentTypeIds.WoodWork)
                        tempQty = (roomDetail[0].width + roomDetail[0].length + roomDetail[0].width + roomDetail[0].length + (offerItem.extraM2QtyFloor ? offerItem.extraM2QtyFloor : roomDetail[0].extraM2QtyFloor ? roomDetail[0].extraM2QtyFloor : 0));
                    if (offerItem.treatmentType == this.treatmentTypeIds.Walls)
                        tempQty = (((roomDetail[0].width + roomDetail[0].length) * 2) * roomDetail[0].ceilingHeight + (offerItem.extraM2QtyWall ? offerItem.extraM2QtyWall :roomDetail[0].extraM2QtyWall ? roomDetail[0].extraM2QtyWall : 0));
                    if (offerItem.treatmentType == this.treatmentTypeIds.Ceiling)
                        tempQty = (roomDetail[0].width * roomDetail[0].length + (offerItem.extraM2QtyCeiling ? offerItem.extraM2QtyCeiling : roomDetail[0].extraM2QtyCeiling ? roomDetail[0].extraM2QtyCeiling : 0));
                    offerItem.qty = tempQty;
                }
                else
                    offerItem.qty = 1;
                this.getMaterialPrice(offerItem.materialID, offerItem, offerItem.materialPriceID);
            }
            else
                offerItem.qty = 1;
            this.OfferTypeDetail.offerDiverseDetailList = this.sortPipe.transform(this.OfferTypeDetail.offerDiverseDetailList, "asc", "roomName");
        }
    }

    updateRoomWidthAndLength(roomDetail) {
        if (this.OfferTypeDetail.offerDiverseDetailList.length > 0) {
            var tempPanels = this.OfferTypeDetail.offerDiverseDetailList.filter(x => x.roomId == roomDetail.offerRoomId && x.type == this.offMaterialTypeIds.Paneler);
            if (tempPanels.length > 0) {
                tempPanels.filter(item => {
                    item.qty = (roomDetail.wallWidth + roomDetail.wallLength + roomDetail.ceilingWidth + roomDetail.ceilingLength)
                    this.getMaterialPrice(item.materialID, item, item.materialPriceID);
                });
            }
        }
    }
    onChangeMaterialSelection(id, offer) {
        offer.colorCode = null;
        var materialprice = this.MaterialObjectForDiverse.find(o => o.id == id);
        if (materialprice)
            offer.treatmentType = materialprice.treatmentType;
        this.getMaterialPrice(id, offer, '');
    }

    _handleKeydown(event: KeyboardEvent) {
        if (event.keyCode === 13) {
            event.stopPropagation();
        }
    }

    getSumOfM2ForWallceilingFloor() {
        this.TotalM2ForWall = 0;
        this.TotalM2ForCeiling = 0;
        this.TotalM2ForFloor = 0;
        this.TotalForWall = 0;
        this.TotalForCeiling = 0;
        this.TotalForFloor = 0;
        this.TotalForAllRooms = 0;
        for (var i = 0; i < this.OfferTypeDetail.offerRoomDetailList.length; i++) {
            this.OfferTypeDetail.offerRoomDetailList[i].wallMaterialList = [];
            this.TotalForWall += this.OfferTypeDetail.offerRoomDetailList[i].offerRoomCalculation.filter(x => x.isPaint == true && x.typeId == this.offerRoomCalculationType.WallType).reduce((sum, current) => sum + current.totalPrice, 0);
            this.TotalM2ForWall += this.OfferTypeDetail.offerRoomDetailList[i].offerRoomCalculation.filter(x => x.isPaint == true && x.typeId == this.offerRoomCalculationType.WallType).reduce((sum, current) => sum + current.totalArea, 0);
            this.OfferTypeDetail.offerRoomDetailList[i].wallTotalArea = this.OfferTypeDetail.offerRoomDetailList[i].offerRoomCalculation.filter(x => x.isPaint == true && x.typeId == this.offerRoomCalculationType.WallType).reduce((sum, current) => sum + current.totalArea, 0);
            this.OfferTypeDetail.offerRoomDetailList[i].wallTotalPrice = this.OfferTypeDetail.offerRoomDetailList[i].offerRoomCalculation.filter(x => x.isPaint == true && x.typeId == this.offerRoomCalculationType.WallType).reduce((sum, current) => sum + current.totalPrice, 0);
            this.OfferTypeDetail.offerRoomDetailList[i].offerRoomCalculation.filter(x => {
                if (x.typeId == this.offerRoomCalculationType.WallType)
                    this.OfferTypeDetail.offerRoomDetailList[i].paintWall = x.isPaint
                if (x.typeId == this.offerRoomCalculationType.WallType)
                    this.OfferTypeDetail.offerRoomDetailList[i].wallMaterialList = x.materialList;
            });

            this.OfferTypeDetail.offerRoomDetailList[i].ceilingMaterialList = []
            this.TotalForCeiling += this.OfferTypeDetail.offerRoomDetailList[i].offerRoomCalculation.filter(x => x.isPaint == true && x.typeId == this.offerRoomCalculationType.CeilingType).reduce((sum, current) => sum + current.totalPrice, 0);
            this.TotalM2ForCeiling += this.OfferTypeDetail.offerRoomDetailList[i].offerRoomCalculation.filter(x => x.isPaint == true && x.typeId == this.offerRoomCalculationType.CeilingType).reduce((sum, current) => sum + current.totalArea, 0);
            this.OfferTypeDetail.offerRoomDetailList[i].ceilingTotalArea = this.OfferTypeDetail.offerRoomDetailList[i].offerRoomCalculation.filter(x => x.isPaint == true && x.typeId == this.offerRoomCalculationType.CeilingType).reduce((sum, current) => sum + current.totalArea, 0);
            this.OfferTypeDetail.offerRoomDetailList[i].ceilingTotalPrice = this.OfferTypeDetail.offerRoomDetailList[i].offerRoomCalculation.filter(x => x.isPaint == true && x.typeId == this.offerRoomCalculationType.CeilingType).reduce((sum, current) => sum + current.totalPrice, 0);
            this.OfferTypeDetail.offerRoomDetailList[i].offerRoomCalculation.filter(x => {
                if (x.typeId == this.offerRoomCalculationType.CeilingType)
                    this.OfferTypeDetail.offerRoomDetailList[i].paintCeiling = x.isPaint
                if (x.typeId == this.offerRoomCalculationType.CeilingType)
                    this.OfferTypeDetail.offerRoomDetailList[i].ceilingMaterialList = x.materialList;
            });

            this.OfferTypeDetail.offerRoomDetailList[i].floorMaterialList = [];
            this.TotalForFloor += this.OfferTypeDetail.offerRoomDetailList[i].offerRoomCalculation.filter(x => x.isPaint == true && x.typeId == this.offerRoomCalculationType.FloorType).reduce((sum, current) => sum + current.totalPrice, 0);
            this.TotalM2ForFloor += this.OfferTypeDetail.offerRoomDetailList[i].offerRoomCalculation.filter(x => x.isPaint == true && x.typeId == this.offerRoomCalculationType.FloorType).reduce((sum, current) => sum + current.totalArea, 0);
            this.OfferTypeDetail.offerRoomDetailList[i].floorTotalArea = this.OfferTypeDetail.offerRoomDetailList[i].offerRoomCalculation.filter(x => x.isPaint == true && x.typeId == this.offerRoomCalculationType.FloorType).reduce((sum, current) => sum + current.totalArea, 0);
            this.OfferTypeDetail.offerRoomDetailList[i].floorTotalPrice = this.OfferTypeDetail.offerRoomDetailList[i].offerRoomCalculation.filter(x => x.isPaint == true && x.typeId == this.offerRoomCalculationType.FloorType).reduce((sum, current) => sum + current.totalPrice, 0);
            this.OfferTypeDetail.offerRoomDetailList[i].offerRoomCalculation.filter(x => {
                if (x.typeId == this.offerRoomCalculationType.FloorType)
                    this.OfferTypeDetail.offerRoomDetailList[i].paintFloor = x.isPaint
                if (x.typeId == this.offerRoomCalculationType.FloorType)
                    this.OfferTypeDetail.offerRoomDetailList[i].floorMaterialList = x.materialList;
            });
            this.TotalForAllRooms = this.TotalForWall + this.TotalForCeiling + this.TotalForFloor
            //this.OfferTypeDetail.offerRoomDetailList[i].floorMaterialName =  'Test floor'
        }
    }

    refreshMaterialPrice() {
        // this.OfferTypeDetail.offerRoomPriceDetailList.forEach(room => {
        //     this.getWallTimeSelectionListOnEdit(room.wallMaterialId, room.offerRoomId, room);
        //     this.getCeilingTimeSelectionListOnEdit(room.ceilingMaterialId, room.offerRoomId, room);
        //     this.getFloorTimeSelectionListOnEdit(room.floorMaterialId, room.offerRoomId, room);
        // })

        this.OfferTypeDetail.offerDiverseDetailList.forEach(item => {
            if (item.materialID)
                this.getMaterialPrice(item.materialID, item, item.materialPriceID);
        })
    }

    refreshOfferMaterials() {
        this.getOfferMaterialData(false);
    }

    getMaterialPriceOnEdit(materialId, offerItem, materialPriceId) {
        var materialprice = this.MaterialObjectForDiverse.find(o => o.id == materialId);
        if (materialprice) {
            offerItem.imagePath = materialprice.imagePath;
            offerItem.selectCategoryTimeForDiverse = [];
            offerItem.notCalculateToDriving = materialprice.notCalculateToDriving;
            for (var i = 0; i < materialprice.offerMaterialPriceList.length; i++) {
                var obj = this.SelectCategoryTime.find(o => o.id == materialprice.offerMaterialPriceList[i].type);
                if (obj) {
                    var offerReminderText = "";
                    if (i == 0) {
                        offerItem.materialPriceID = materialprice.offerMaterialPriceList[i].id;
                        offerItem.price = offerItem.price;
                        offerItem.colorCode = offerItem.colorCode;
                    }
                    offerItem.selectCategoryTimeForDiverse.push({
                        id: obj.id,
                        key: obj.key,
                        priceId: materialprice.offerMaterialPriceList[i].id,
                        extraValue: materialprice.offerMaterialPriceList[i].paintText,
                        reminder: materialprice.offerMaterialPriceList[i].reminder,
                    });
                    if (materialprice.offerMaterialPriceList[i].id == materialPriceId) {
                        offerItem.materialPriceID = materialprice.offerMaterialPriceList[i].id;
                        offerItem.price = offerItem.price;
                        offerReminderText = materialprice.offerMaterialPriceList[i].reminder;
                        if (offerItem.materialList && offerItem.materialList.length > 0) {
                            offerItem.materialList.filter(m => {
                                if (m.id == materialId) {
                                    var tempPriceData = m.offerMaterialPriceList.filter(x => x.id == materialPriceId);
                                    if (tempPriceData && tempPriceData.length > 0)
                                        m.offerMaterialPriceText = tempPriceData[0].paintText;
                                }
                            })
                        }
                    }
                    else {
                        offerItem.text = materialPriceId ? offerItem.text : materialprice.text + ' ' + materialprice.offerMaterialPriceText;
                        // offerItem.colorCode = offerItem.colorCode ? offerItem.colorCode : materialprice.colorCode;
                        offerItem.colorCode = offerItem.colorCode ? offerItem.colorCode : "";
                        offerItem.unit = materialprice.unit;
                        offerReminderText = materialprice.offerMaterialPriceList[i].reminder;
                    }

                    if (materialprice.offerMaterialPriceList[i].isDefault && materialPriceId == "") {
                        offerItem.materialPriceID = materialprice.offerMaterialPriceList[i].id;
                        offerItem.price = offerItem.price;
                        offerReminderText = materialprice.offerMaterialPriceList[i].reminder;
                        offerItem.text = materialprice.text + ' ' + materialprice.offerMaterialPriceList[i].paintText;
                        if (offerItem.materialList && offerItem.materialList.length > 0) {
                            offerItem.materialList.filter(m => {
                                if (m.id == materialId) {
                                    var tempPriceData = m.offerMaterialPriceList.filter(x => x.id == offerItem.materialPriceID);
                                    if (tempPriceData && tempPriceData.length > 0)
                                        m.offerMaterialPriceText = tempPriceData[0].paintText;
                                }
                            })
                        }
                    }
                    if (offerReminderText && materialprice.reminder) {
                        offerItem.reminder = materialprice.reminder + " - " + offerReminderText;
                    } else if (offerReminderText && !materialprice.reminder) {
                        offerItem.reminder = offerReminderText;
                    } else {
                        offerItem.reminder = materialprice.reminder ? materialprice.reminder : "";
                    }
                }
                if (i == materialprice.offerMaterialPriceList.length - 1)
                    offerItem.reminder = materialprice.offerMaterialPriceList.filter(x => x.id == materialPriceId).map(y => y.reminder);
            }
            this.checkUncheckPanlesDoorframeRadiotorItem();
        }
        this.calculateDiversePrise();
    }
    onCheckIsIncludePriceOnPDF(flag) {
        //this.OfferTypeDetail.isIncludePriceOnPDF = flag;
        this.isIncludePriceOnPDF = flag;
    }

    // getMaterialReminder(typeId, materialId) {
    //     if (typeId == this.offMaterialTypeIds.WallType)
    //         this.selectedRoomOnEdit.wallMaterialReminder = this.wallMaterialObject.filter(x => x.id == materialId).map(x => x.reminder).toString();
    //     else if (typeId == this.offMaterialTypeIds.CeilingType)
    //         this.selectedRoomOnEdit.ceilingMaterialReminder = this.ceilingMaterialObject.filter(x => x.id == materialId).map(x => x.reminder).toString();
    //     else if (typeId == this.offMaterialTypeIds.FloorType)
    //         this.selectedRoomOnEdit.floorMaterialReminder = this.floorMaterialObject.filter(x => x.id == materialId).map(x => x.reminder).toString();
    // }


    GetOffer() {
        //this.projectofferService.Getofferitemgroup<ResponseBase>(id)
        this.projectofferService.GetSingle<ResponseBase>(this.OfferTypeDetail.offerId)
            .subscribe((response: ResponseBase) => {
                if (response != null && response.result) {
                    this.projectOffer = response.result;
                    if (!this.OfferTypeDetail.id) {
                        if (this.projectOffer && this.projectOffer.percentage != 0) {
                            this.OfferTypeDetail.discountByPostNumber = this.projectOffer.percentage
                            this.OfferTypeDetail.isDiscountByPostNumber = true;
                        }
                        this.projectPriceDiscount = this.projectOffer.indoorOutdoorStatusIDPercentage;
                        this.OfferTypeDetail.discountByProjectAmount = this.projectPriceDiscount
                        this.OfferTypeDetail.isDiscountByProjectAmount = true;
                    }
                    if (this.customerDetailObject) {
                        this.customerDetailObj = this.customerDetailObject;
                    } else {
                        this.getCustomerById(this.projectOffer.customerId);
                    }
                }
            },
                error => (err) => {
                });
    }

    calculateRoomTotal() {
        this.OfferTypeDetail.priceTotal = 0;
        this.OfferTypeDetail.drivingAmountTotal = 0;
        this.paintTimer = 0;
        for (var i = 0; i < this.OfferTypeDetail.offerRoomDetailList.length; i++) {
            this.OfferTypeDetail.offerRoomDetailList[i].totalPrice = this.OfferTypeDetail.offerRoomDetailList[i].offerRoomCalculation.filter(x => x.isPaint == true).reduce((sum, current) => sum + current.totalPrice, 0);
            this.OfferTypeDetail.priceTotal += this.OfferTypeDetail.offerRoomDetailList[i].totalPrice;
            this.OfferTypeDetail.drivingAmountTotal += this.OfferTypeDetail.offerRoomDetailList[i].offerRoomCalculation.filter(x => x.isPaint == true).reduce((sum, current) => sum + current.drivingAmountTotal, 0);
            if (this.gulvCheckedCount == 0) {
                this.gulvCheckedCount = this.OfferTypeDetail.offerRoomDetailList[i].offerRoomCalculation.filter(x => x.typeId == this.offerRoomCalculationType.FloorType).length;
                this.OfferTypeDetail.offerRoomDetailList[i].isAddFloor = this.gulvCheckedCount > 0 ? true : false;
            }
            this.paintTimer += this.OfferTypeDetail.offerRoomDetailList[i].offerRoomCalculation.filter(x => x.isPaint == true).reduce((sum, current) => sum + current.paintTimerTotal, 0)
        }
        this.OfferTypeDetail.priceTotal = this.OfferTypeDetail.priceTotal + this.grandTotal;
        //this.OfferTypeDetail.price = this.OfferTypeDetail.priceTotal;
        this.OfferTypeDetail.price = this.OfferTypeDetail.priceTotal - this.OfferTypeDetail.discount;
        for (var i = 0; i < this.OfferTypeDetail.offerDiverseDetailList.length; i++) {
            if(!this.OfferTypeDetail.offerDiverseDetailList[i].notCalculateToDriving){
                this.OfferTypeDetail.offerDiverseDetailList[i].priceTotal = this.OfferTypeDetail.offerDiverseDetailList[i].qty * this.OfferTypeDetail.offerDiverseDetailList[i].price;
                this.OfferTypeDetail.drivingAmountTotal += this.OfferTypeDetail.offerDiverseDetailList[i].priceTotal;
            }
            this.getOfferItemTimer(this.OfferTypeDetail.offerDiverseDetailList[i]);
        }
        if (this.OfferTypeDetail.discount > 0) {
            this.calculateDiscountedAmt();
        }
        if (this.OfferTypeDetail.travelingAmount > 0) {
            this.calculateTravelingAmt();
        }
        else {
            this.OfferTypeDetail.discount = 0;
            this.calculateVatAmt();
        }
        //this.updateRoomWidthAndLength(roomDetail);
        this.getSumOfM2ForWallceilingFloor();

    }
    reCalculateRoomTotal(roomDetail, type) {
        if (roomDetail && roomDetail.offerRoomCalculation) {
            roomDetail.offerRoomCalculation.filter(x => {
                if (x.typeId == type) {
                    if (type == this.offerRoomCalculationType.WallType)
                        x.isPaint = roomDetail.paintWall;
                    if (type == this.offerRoomCalculationType.CeilingType)
                        x.isPaint = roomDetail.paintCeiling;
                    if (type == this.offerRoomCalculationType.FloorType)
                        x.isPaint = roomDetail.paintFloor;

                    if (x.materialList.length > 0 && x.isPaint == true) {
                        x.totalPrice = (x.totalArea * (x.materialList.reduce((sum, current) => sum + current.price, 0)))
                            + x.extraPrice1 + x.extraPrice2;
                    }
                }
            })
        }
        this.calculateRoomTotal();
    }
    addFloor(roomDetail) {
        var offerRoomCalculationItem = new OfferRoomCalculationWithMaterialList();
        offerRoomCalculationItem.length = roomDetail.offerRoomCalculation[0].length;
        offerRoomCalculationItem.width = roomDetail.offerRoomCalculation[0].width;
        offerRoomCalculationItem.ceilingHeight = roomDetail.offerRoomCalculation[0].ceilingHeight;
        offerRoomCalculationItem.offerRoomDetailId = roomDetail.id;
        offerRoomCalculationItem.extraQty1 = roomDetail.offerRoomCalculation[0].extraQty1 ? roomDetail.offerRoomCalculation[0].extraQty1 : 0;
        offerRoomCalculationItem.extraQty2 = roomDetail.offerRoomCalculation[0].extraQty2 ? roomDetail.offerRoomCalculation[0].extraQty2 : 0;
        offerRoomCalculationItem.extraPrice1 = roomDetail.offerRoomCalculation[0].extraPrice1 ? roomDetail.offerRoomCalculation[0].extraPrice1 : 0;
        offerRoomCalculationItem.extraPrice2 = roomDetail.offerRoomCalculation[0].extraPrice2 ? roomDetail.offerRoomCalculation[0].extraPrice2 : 0;
        offerRoomCalculationItem.extraM2QtyFloor = roomDetail.offerRoomCalculation[0].extraM2QtyFloor ? roomDetail.offerRoomCalculation[0].extraM2QtyFloor : 0;
        offerRoomCalculationItem.extraM2PriceFloor = roomDetail.offerRoomCalculation[0].extraM2PriceFloor ? roomDetail.offerRoomCalculation[0].extraM2PriceFloor : 0;
        offerRoomCalculationItem.isActive = true;
        let tempOfferRoomCalculationItem = Object.assign({}, offerRoomCalculationItem);
        tempOfferRoomCalculationItem.materialList = [];
        this.tempAssignedMaterialList = [];
        var materialObj = new MaterialList();
        materialObj.offerMaterialTypeId = this.offMaterialTypeIds.FloorType;
        this.tempAssignedMaterialList = this.MaterialObject.filter(o => { return o.type.split(',').find(p => p == materialObj.offerMaterialTypeId) });
        materialObj.materialList = this.tempAssignedMaterialList.map(x => Object.assign({}, x));//this.MaterialObject.filter(o => { return o.type.split(',').find(p => p == materialObj.offerMaterialTypeId)});
        tempOfferRoomCalculationItem.typeId = this.offerRoomCalculationType.FloorType;
        tempOfferRoomCalculationItem.materialList.push(materialObj);
        tempOfferRoomCalculationItem.extraQty1 = tempOfferRoomCalculationItem.extraM2QtyFloor;
        tempOfferRoomCalculationItem.extraPrice1 = tempOfferRoomCalculationItem.extraM2PriceFloor;
        if (tempOfferRoomCalculationItem.extraQty1 > 0 || tempOfferRoomCalculationItem.extraPrice1 > 0)
            tempOfferRoomCalculationItem.isAddExtraWallQty = true;
        roomDetail.offerRoomCalculation.push(tempOfferRoomCalculationItem);
        this.calculateRoomTotal();
    }

    clearOfferItem(offerItem) {
        offerItem.text = "";
        offerItem.imagePath = "";
        offerItem.unit = "";
        offerItem.colorCode = "";
        offerItem.materialPriceID = "";
        offerItem.price = 0;
        offerItem.priceTotal = 0;
        offerItem.treatmentType = 0;
        this.calculateDiversePrise();
    }

    OpenTextareaEditerPopup(text, index, materialID) {
        if (!materialID || !text)
            return;
        const dialogRef = this.dialog.open(TextareaEditorComponent, {
            height: '400px',
            width: '500px',
            data: { 'displayData': text ? text : "" }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result.isSave) {
                if (this.OfferTypeDetail.offerDiverseDetailList.length > 0) {
                    this.OfferTypeDetail.offerDiverseDetailList[index].text = result.value;
                }
                // this.settingObj.value = result.value;
                // this.dropboxFilesService.UpdateSettingValuue<ResponseBase>(this.settingObj.id, this.settingObj)
                //     .subscribe((response: ResponseBase) => {
                //         if (response) {
                //             this.settingObj = response.result;
                //             this.translate.get("MESSAGES.Updated").subscribe({
                //                 next: (res) => {
                //                     this.notificationsService.success(res);
                //                 },
                //                 error: err => {
                //                 }
                //             });
                //         }
                //     },
                //         error => (err) => {
                //         },
                //         () => {
                //         });
            }
        });
    }

    toggleRoomDetail() {
        this.isDisplayRoomDetail = !this.isDisplayRoomDetail;
    }

    GetCompanyInformation() {
        this.companyInfoService.GetCompanyInformationData<ResponseBase>()
            .subscribe((response: ResponseBase) => {
                if (response) {
                    this.companyInfoData = response.result;
                }
            },
                (err: any) => {
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        }, error: err => { }
                    });
                },
                () => {
                });
    }

    showDirection() {
        //open popup
        const dialogRef = this.dialog.open(GoogleMapDirectionComponent, {
            width: '80%',
            height: '80%',
            disableClose: true,
            data: {
                Address: this.projectOffer.address,
                ZipCode: this.projectOffer.postNumber,
                City: this.projectOffer.city,
                SourceAdd: this.companyInfoData,
                autoSearchOnOpen: true,
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.OfferTypeDetail.distance = result.travelingDetails.distance;
                this.OfferTypeDetail.time = result.travelingDetails.duration;
                if(result.travelingDetails.distance.toString().split(" ")[1])
                    this.meterAndKmText = result.travelingDetails.distance.toString().split(" ")[1];
                this.calculateDistanceAmt();
                setTimeout(() => {
                    this.OfferTypeDetail.numberOfDrivingDays = this.OfferTypeDetail.numberOfDays;
                }, 500);
            }
        });
    }

    getCustomerById(customerId) {
        this.customersService.GetSingle<ResponseBase>(customerId).subscribe({
            next: (response: ResponseBase) => {
                this.customerDetailObj = response.result;
            },
            error: err => { },
        });
    }

    getTravelingDistancePerDayValue() {
        this.projectofferService.GetDistancePerMinuteValue().subscribe((response: ResponseBase) => {
            if (response) {
                this.DistancePerDayValue = parseFloat(response.result.myResponse[0].value);
            }
        });
    }

    // getDaysDistanceTime(value) {
    //     var daysAndTime = 0;
    //     value = Number(value);
    //     daysAndTime = value * 2
    //     return daysAndTime;
    // }

    calculateTotalTime(days) {
        this.OfferTypeDetail.TotalTime = 0;
        if (this.OfferTypeDetail.time) {
            var time = this.OfferTypeDetail.time;
            this.OfferTypeDetail.TotalTime = ((time * 2) * Math.ceil(days ? days : this.OfferTypeDetail.numberOfDays));
        }
        return this.OfferTypeDetail.TotalTime;
    }

    calculateDistanceAmt() {
        var amount = 0;
        amount = this.calculateTotalTime(this.OfferTypeDetail.numberOfDays);
        amount = amount * this.DistancePerDayValue;
        this.OfferTypeDetail.travelingAmount = amount;
        this.OfferTypeDetail.price = ((this.OfferTypeDetail.priceTotal + this.OfferTypeDetail.travelingAmount) - this.OfferTypeDetail.discount);
        this.OfferTypeDetail.vat = ((this.OfferTypeDetail.price) * 25 / 100);
        this.OfferTypeDetail.totalInclVAT = (this.OfferTypeDetail.price + (this.OfferTypeDetail.price * 25 / 100));
        this.OfferTypeDetail.numberOfHours = (this.OfferTypeDetail.drivingAmountTotal / this.projectsCoworkerPrice);
        this.OfferTypeDetail.numberOfDays = Math.ceil(Math.ceil((this.OfferTypeDetail.numberOfHours / 7.5) / 0.25) * 0.25);
        this.calTravelingAmtOnDrivingDaysChange();
    }

    calTravelingAmtOnDrivingDaysChange() {
        var amount = 0;
        amount = this.calculateTotalTime(this.OfferTypeDetail.numberOfDrivingDays == this.OfferTypeDetail.numberOfDays ? this.OfferTypeDetail.numberOfDrivingDays : this.OfferTypeDetail.numberOfDays);
        amount = amount * this.DistancePerDayValue;
        this.OfferTypeDetail.travelingAmount = amount;
        this.OfferTypeDetail.price = ((this.OfferTypeDetail.priceTotal + this.OfferTypeDetail.travelingAmount) - this.OfferTypeDetail.discount);
        this.OfferTypeDetail.vat = ((this.OfferTypeDetail.price) * 25 / 100);
        this.OfferTypeDetail.totalInclVAT = (this.OfferTypeDetail.price + (this.OfferTypeDetail.price * 25 / 100));
        this.OfferTypeDetail.numberOfHours = (this.OfferTypeDetail.drivingAmountTotal / this.projectsCoworkerPrice);
        this.OfferTypeDetail.numberOfDays = Math.ceil(Math.ceil((this.OfferTypeDetail.numberOfHours / 7.5) / 0.25) * 0.25);
    }

    setOfferTextForNumberOfTimesOnPDF(materialId, offerItem) {
        var materialprice = this.MaterialObjectForDiverse.find(o => o.id == materialId);
        if (materialprice) {
            var offerMaterialDetail = materialprice.offerMaterialPriceList.find(x => x.id == offerItem.materialPriceID)
            if (materialprice.addNumberOfTimesOnPDF && offerMaterialDetail) {
                offerItem.text = materialprice.text + ' ' + offerMaterialDetail.paintText + ' ' + this.projectofferService.getPaintType(offerMaterialDetail.type);
            }
        }
    }

    CallNumberOfDrivingDaysPopup() {
        const dialogRef = this.dialog.open(CalculateDrivingDaysModalComponent, {
            width: '45%',
            height: '30%',
            disableClose: true,
            data: {
                numberOfDays: this.OfferTypeDetail.numberOfDays,
                numberOfDrivingDays: this.OfferTypeDetail.numberOfDrivingDays,
                autoSearchOnOpen: true,
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.OfferTypeDetail.numberOfDrivingDays = result.numberOfDayesDetails;
                this.OfferTypeDetail.numberOfDays = result.numberOfDays;
                this.calTravelingAmtOnDrivingDaysChange();
                setTimeout(() => {
                    this.saveAndUpdateOffer(null);
                }, 1000);
            }
        });
    }

    GetOfferMaterialFiles() {
        this.showSpinner = true;
        var documentObj = new OffersMaterialDocument();
        documentObj.offerID = this.OfferTypeDetail.offerId;
        documentObj.offerTreatmentID = [this.OfferTypeDetail.id];
        this.projectofferService.GetOfferMaterialFiles<ResponseBase>(documentObj)
            .subscribe((response: ResponseBase) => {
                if (response) {
                    this.offerMaterialFilesAndContent = response.result;
                    this.showSpinner = false;
                    this.SendEmail(response.result.materialFiles);
                }
            },
                error => (err) => {
                    this.showSpinner = false;
                    this.SendEmail(null);
                });
    }

    SendEmail(materialOfferFileList: OffersMaterialDocumentDetail[]) {
        this.showSpinner = true;
        var offerIdsList = [this.OfferTypeDetail.id].join().toString();
        var emailParam = new EmailSendingParamForOffer();
        emailParam.offerId = this.OfferTypeDetail.offerId;
        emailParam.to = this.projectOffer.email;
        emailParam.toName = "";
        // emailParam.from = "post@vibesmalerfirma.dk";
        emailParam.fromName = "Admin";
        emailParam.subject = "Hermed fremsendes tilbud.";
        emailParam.body = this.offerMaterialFilesAndContent && this.offerMaterialFilesAndContent.materialContent ? this.offerMaterialFilesAndContent.materialContent :"";
        emailParam.isIncludePriceOnPDF = this.isIncludePriceOnPDF;
        emailParam.offerTaskIds = offerIdsList;
        emailParam.offerMaterialFiles = materialOfferFileList;
        emailParam.isShowRoomTotal = this.isShowRoomTotal;
        const dialogRef = this.dialog.open(SendOfferEmailComponent, {
            height: '90%',
            width: '90%',
            data: emailParam
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.projectofferService.SendEmailInvoiceWithAttachmentToCustomer<ResponseBase>(result)
                    .subscribe((response: ResponseBase) => {
                        if (response) {
                            this.showSpinner = false;
                            this.translate.get("MESSAGES.Success").subscribe({
                                next: (res) => {
                                    this.notificationsService.success(res);
                                },
                                error: err => {
                                }
                            });
                            this.DisplayList.emit(this.selectedCategoryInput);
                            this.commonService. checkEmailIsToBeSent();
                        }
                    },
                        error => (err) => {
                            this.showSpinner = false;
                            this.translate.get("MESSAGES.Failed").subscribe({
                                next: (res) => {
                                    this.notificationsService.error(res);
                                },
                                error: err => {
                                }
                            });
                        });
            }
            else
                this.showSpinner = false;
        });
    }

    onChangeProjectPrice(isChecked: boolean) {
        var totalpercentage = (this.OfferTypeDetail.isDiscountByProjectAmount ? this.OfferTypeDetail.discountByProjectAmount : 0)
            + (this.OfferTypeDetail.isDiscountByPostNumber ? this.OfferTypeDetail.discountByPostNumber : 0)
            + 100;
        var pricePecentage = totalpercentage;
        if (isChecked)
            pricePecentage = pricePecentage - this.OfferTypeDetail.discountByProjectAmount;
        else
            pricePecentage = pricePecentage + this.OfferTypeDetail.discountByProjectAmount;
        this.reCalculatePrice(totalpercentage, pricePecentage);
    }
    onChangePostnumnerPrice(isChecked: boolean) {
        var totalpercentage = (this.OfferTypeDetail.isDiscountByProjectAmount ? this.OfferTypeDetail.discountByProjectAmount : 0)
            + (this.OfferTypeDetail.isDiscountByPostNumber ? this.OfferTypeDetail.discountByPostNumber : 0)
            + 100;
        var pricePecentage = totalpercentage;
        if (isChecked)
            pricePecentage = pricePecentage - this.OfferTypeDetail.discountByPostNumber;
        else
            pricePecentage = pricePecentage + this.OfferTypeDetail.discountByPostNumber;
        this.reCalculatePrice(totalpercentage, pricePecentage);
    }

    reCalculatePrice(totalpercentage, pricePecentage) {
        if (this.OfferTypeDetail.offerRoomDetailList.length > 0) {
            this.OfferTypeDetail.offerRoomDetailList.filter((room, i) => {
                room.offerRoomCalculation.filter(roomCalculation => {
                    roomCalculation.materialList.filter(material => {
                        //material.price = (material.price * totalpercentage) / pricePecentage;
                        material.price = material.originalPrice ? material.originalPrice : material.price;
                        material.originalPrice = material.originalPrice ? material.originalPrice : material.price;
                        var selectedMaterial = this.MaterialObject.find(o => o.id == material.offerMaterialId);
                        if (this.OfferTypeDetail.isDiscountByPostNumber && selectedMaterial && selectedMaterial.isPostNumberDiscount && !this.customerDetailObj.doNotCalculatePriceForPostNumber)
                            material.price = material.price + ((material.price * this.OfferTypeDetail.discountByPostNumber) / 100);
                        if (this.OfferTypeDetail.isDiscountByProjectAmount && (this.customerDetailObj && !this.customerDetailObj.doNotCalculatePriceForPriceDiscount))
                            material.price = material.price + (material.price * this.OfferTypeDetail.discountByProjectAmount / 100);
                    })
                    this.reCalculateRoomTotal(room, roomCalculation.typeId);
                })
            })
        }
        if (this.OfferTypeDetail.offerDiverseDetailList.length > 0) {
            this.OfferTypeDetail.offerDiverseDetailList.filter(item => {
                //item.price = (item.price * totalpercentage) / pricePecentage;
                //item.price = item.originalPrice;
                item.price = item.originalPrice ? item.originalPrice : item.price;
                item.originalPrice = item.originalPrice ? item.originalPrice : item.price;
                var selectedMaterial = this.MaterialObject.find(o => o.id == item.materialID);
                if (this.OfferTypeDetail.isDiscountByPostNumber && selectedMaterial.isPostNumberDiscount && !this.customerDetailObj.doNotCalculatePriceForPostNumber)
                    item.price = item.price + ((item.price * this.OfferTypeDetail.discountByPostNumber) / 100);
                if (this.OfferTypeDetail.isDiscountByProjectAmount && (this.customerDetailObj && !this.customerDetailObj.doNotCalculatePriceForPriceDiscount))
                    item.price = item.price + (item.price * this.OfferTypeDetail.discountByProjectAmount / 100);
            })
            this.calculateDiversePrise();
        }
        
        setTimeout(() => {
            this.saveAndUpdate(false)
        });
    }

    storeFileFolderPathData() {
        var rootPath = this.dropboxFilesService.getSettingMasterValueDocumentPath();
        var paths = "";
        if (rootPath.DocumentDriveType == DriveModuleEnum.Onedrive) {
            paths = rootPath.Onedrive.Path;
        } else {
            paths = rootPath.Dropbox.Path;
        }
        this.fileFolderPathData = paths.split("/").map((path, i) => ({
            name: path,
            isDisplay: false
        }));
        this.fileFolderPathData.push(
            { name: 'ProjectOffers', isDisplay: false },
            { name: this.projectOffer.number.toString(), isDisplay: true },
            { name: this.OfferTypeDetail.number.toString() + "_" + "Indvendigt", isDisplay: true }
        );
    }

    AddFiles() {
        this.show = !this.show;
        if (this.show) {
            this.searchValue = this.OfferTypeDetail.number.toString() + "_";
            this.isSearch = true;
            this.isDisplayOnlySearchFolder = true;
            this.storeFileFolderPathData();
        }
    }

    displayTotalFileCount(count) {
        this.totalFileCount = count;
    }

    getOfferCategoryMaterials(ids) {
        this.projectofferService.GetOfferCategoryMaterialList(ids).subscribe({
            next: (response: ResponseBase) => {
                this.offerCategoryMaterialList = response.result;
            },
            error: err => { },
            complete: () => { }
        });
    }

    GetFileList() {
        this.storeFileFolderPathData();
        var path = "";
        for (var i = 0; i < this.fileFolderPathData.length; i++) {
            path += "/" + this.fileFolderPathData[i].name;
        }
        this.listFolder = new ListFolder();
        this.listFolder.folderPath = path;
        this.showSpinner = true;
        this.dropboxFilesService.GetListFolderDrpBox<ResponseBase>(this.listFolder).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response.result.myResponse.length > 0) {
                    this.totalFileCount = response.result.myResponse.length;
                }
                else {
                    this.totalFileCount = 0;
                }
            },
            error: err => {
                this.showSpinner = false;
            }
        });
    }

    addLengthAndWidth(index) {
        if (this.OfferTypeDetail.offerDiverseDetailList[index].length > 0 && this.OfferTypeDetail.offerDiverseDetailList[index].width > 0) {
            this.OfferTypeDetail.offerDiverseDetailList[index].qty = this.OfferTypeDetail.offerDiverseDetailList[index].length * this.OfferTypeDetail.offerDiverseDetailList[index].width
            this.calculateDiversePrise();
        }
    }
    clearLenghtWidth(index) {
        this.OfferTypeDetail.offerDiverseDetailList[index].length = 0;
        this.OfferTypeDetail.offerDiverseDetailList[index].width = 0;
    }
    editTravelingTime() {
        this.OfferTypeDetail.numberOfDays = this.OfferTypeDetail.numberOfDrivingDays;
        var amount = 0;
        amount = this.calculateTotalTime(this.OfferTypeDetail.numberOfDrivingDays == this.OfferTypeDetail.numberOfDays ? this.OfferTypeDetail.numberOfDrivingDays : this.OfferTypeDetail.numberOfDays);
        amount = amount * this.DistancePerDayValue;
        this.OfferTypeDetail.travelingAmount = amount;
        this.OfferTypeDetail.price = ((this.OfferTypeDetail.priceTotal + this.OfferTypeDetail.travelingAmount) - this.OfferTypeDetail.discount);
        this.OfferTypeDetail.vat = ((this.OfferTypeDetail.price) * 25 / 100);
        this.OfferTypeDetail.totalInclVAT = (this.OfferTypeDetail.price + (this.OfferTypeDetail.price * 25 / 100));
        this.OfferTypeDetail.numberOfHours = (this.OfferTypeDetail.drivingAmountTotal / this.projectsCoworkerPrice);
    }
    updateRoomLengthWidth(offerItem) {
        if (offerItem.roomId && this.OfferTypeDetail.offerRoomDetailList.length > 0) {
            var roomDetail = this.roomList.filter(x => x.id == offerItem.roomId)
            if (roomDetail.length > 0) {
                offerItem.width = roomDetail[0].width;
                offerItem.length = roomDetail[0].length;
                offerItem.height = roomDetail[0].height;
                offerItem.extraM2QtyWall = roomDetail[0].extraM2QtyWall;
                offerItem.extraM2QtyCeiling = roomDetail[0].extraM2QtyCeiling;
                offerItem.extraM2QtyFloor = roomDetail[0].extraM2QtyFloor;
                offerItem.extraM2PriceWall = roomDetail[0].extraM2PriceWall;
                offerItem.extraM2PriceCeiling = roomDetail[0].extraM2PriceCeiling;
                offerItem.extraM2PriceFloor = roomDetail[0].extraM2PriceFloor;
            }
        }
    }

    getMaterialPriceForDefaultMaterial(materialId, offerItem, materialPriceId) {
        var materialprice = this.MaterialObjectForDiverse.find(o => o.id == materialId);
        if (materialprice) {
            offerItem.imagePath = materialprice.imagePath;
            offerItem.selectCategoryTimeForDiverse = [];
            offerItem.isUnitPrint = materialprice.addUnitOnPDF;
            if(materialprice.unit == "stk.")
                offerItem.isQuantityPrint=true;
            else
                offerItem.isQuantityPrint=false;
            for (var i = 0; i < materialprice.offerMaterialPriceList.length; i++) {
                var obj = this.SelectCategoryTime.find(o => o.id == materialprice.offerMaterialPriceList[i].type);
                if (obj) {
                    if (i == 0) {
                        offerItem.materialPriceID = materialprice.offerMaterialPriceList[i].id;
                        offerItem.price = materialprice.offerMaterialPriceList[i].price;
                    }
                    offerItem.selectCategoryTimeForDiverse.push({
                        id: obj.id,
                        key: obj.key,
                        extraValue: materialprice.offerMaterialPriceList[i].paintText,
                        priceId: materialprice.offerMaterialPriceList[i].id,
                        reminder: materialprice.offerMaterialPriceList[i].reminder,
                    });
                    if (materialprice.offerMaterialPriceList[i].id == materialPriceId) {
                        offerItem.materialPriceID = materialprice.offerMaterialPriceList[i].id;
                        offerItem.price = materialprice.offerMaterialPriceList[i].price;
                        offerItem.text = materialprice.text + ' ' + (materialprice.offerMaterialPriceList[i].paintText != undefined ? materialprice.offerMaterialPriceList[i].paintText : "");
                        var offerReminderText = materialprice.offerMaterialPriceList[i].reminder ? materialprice.offerMaterialPriceList[i].reminder : "";
                    }
                    else {
                        offerItem.text = materialPriceId ? offerItem.text : materialprice.text + ' ' + materialprice.offerMaterialPriceText;
                        offerItem.colorCode = offerItem.colorCode ? offerItem.colorCode : "";
                        offerItem.unit = materialprice.unit;
                        var offerReminderText = materialprice.offerMaterialPriceList[i].reminder ? materialprice.offerMaterialPriceList[i].reminder : "";
                    }

                    if (materialprice.offerMaterialPriceList[i].isDefault && materialPriceId == "") {
                        offerItem.materialPriceID = materialprice.offerMaterialPriceList[i].id;
                        offerItem.price = materialprice.offerMaterialPriceList[i].price;
                        var offerReminderText = materialprice.offerMaterialPriceList[i].reminder ? materialprice.offerMaterialPriceList[i].reminder : "";
                        offerItem.text = materialprice.text + ' ' + (materialprice.offerMaterialPriceList[i].paintText != undefined ? materialprice.offerMaterialPriceList[i].paintText : "") + (materialprice.addNumberOfTimesOnPDF ? this.projectofferService.getPaintType(materialprice.offerMaterialPriceList[i].type) : "");
                        if (offerItem.materialList && offerItem.materialList.length > 0) {
                            offerItem.materialList.filter(m => {
                                if (m.id == materialId) {
                                    var tempPriceData = m.offerMaterialPriceList.filter(x => x.id == offerItem.materialPriceID);
                                    if (tempPriceData && tempPriceData.length > 0)
                                        m.offerMaterialPriceText = tempPriceData[0].paintText;
                                }
                            })
                        }
                    }
                    offerItem.originalPrice = offerItem.price;
                    if (offerReminderText && materialprice.reminder) {
                        offerItem.reminder = materialprice.reminder + " - " + offerReminderText;
                    } else if (offerReminderText && !materialprice.reminder) {
                        offerItem.reminder = offerReminderText;
                    } else {
                        offerItem.reminder = materialprice.reminder ? materialprice.reminder : "";
                    }
                    if (!offerItem.id && materialprice.isPostNumberDiscount && this.projectOffer && this.projectOffer.percentage != 0 && !this.customerDetailObj.doNotCalculatePriceForPostNumber)
                        offerItem.price = offerItem.price + ((offerItem.price * this.projectOffer.percentage) / 100);
                    if (!offerItem.id && this.projectPriceDiscount && (this.customerDetailObj && !this.customerDetailObj.doNotCalculatePriceForPriceDiscount))
                        offerItem.price = offerItem.price + (offerItem.price * this.projectPriceDiscount / 100);
                    this.getOfferDiverseMeterPrice(offerItem);
                }
            }
            this.checkUncheckPanlesDoorframeRadiotorItem();
            this.setOfferTextForNumberOfTimesOnPDF(materialId, offerItem)
        }
        this.calculateDiversePrise();
    }

    showExtraQuantity(offerItem){
        offerItem.isAddExtraQty = !offerItem.isAddExtraQty
    }

    clearDiverseItem(offerItem){
        offerItem.width = 0;
        offerItem.length = 0;
        offerItem.height = 0;
        offerItem.extraM2QtyWall = 0;
        offerItem.extraM2QtyCeiling = 0;
        offerItem.extraM2QtyFloor = 0;
        offerItem.extraM2PriceWall = 0;
        offerItem.extraM2PriceCeiling = 0;
        offerItem.extraM2PriceFloor = 0;
        offerItem.price = 0;
        offerItem.priceTotal = 0;
        offerItem.qty = 0;
        offerItem.isAddExtraQty = false;
    }

    changeDiverseUnitType(offerItem){
        if(offerItem.unit == "stk.")
            offerItem.isQuantityPrint=true;
        else
            offerItem.isQuantityPrint=false;
    }

    //#region File upload
    GetCurrentPath() {
        var rootPath = this.dropboxFilesService.getSettingMasterValueDocumentPath();
        var paths = "";
        if (rootPath.DocumentDriveType == DriveModuleEnum.Onedrive) {
            paths = rootPath.Onedrive.Path;
        } else {
            paths = rootPath.Dropbox.Path;
        }
        var FolderPathData = [];
        FolderPathData = paths.split("/").map((path, i) => ({
            name: path,
            isDisplay: false
        }));
        FolderPathData.push(
            { name: 'ProjectOffers', isDisplay: false },
            { name: this.projectOffer.number.toString(), isDisplay: true },
        );
        var path = "";
        for (var i = 0; i < FolderPathData.length; i++) {
            path += "/" + FolderPathData[i].name;
        }
        return path;
    }

    GetCurrentFolderPath(categoryName) {
        this.storeFileFolderPathData();
        var path = "";
        for (var i = 0; i < this.fileFolderPathData.length; i++) {
            path += "/" + this.fileFolderPathData[i].name;
        }
        path = path + categoryName;
        return path;
    }

    SearchFile() {
        this.showSpinner = true;
        this.searchFileAndFolder = new SearchFolderOrFiles();
        this.searchFileAndFolder.searchValue = this.OfferTypeDetail.number.toString() + "_";
        this.searchFileAndFolder.path = this.GetCurrentPath();
        this.dropboxFilesService.GetSearchFileOrFolder<ResponseBase>(this.searchFileAndFolder).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                this.totalFileCount = response.result.myResponse.filter(x => x.isFolder == true).length;
            },
            error: err => {
                this.showSpinner = false;
            }
        });
    }

    SetFileDetails() {
        if (this.selectedCategoryInput.fileList.length > 0) {
            for (let i = 0; i < this.selectedCategoryInput.fileList.length; i++) {
                const formData = new FormData();
                const files = this.selectedCategoryInput.fileList[i].files;
                for (var j = 0; j < files.length; j++) {
                    formData.append("file[]", files[j]);
                }
                formData.append("RootFolderPath", this.GetCurrentFolderPath(this.selectedCategoryInput.fileList[i].categoryName.replace("/","_")));
                formData.append("ModuleID", this.projectOffer.id);
                this.UploadFile(formData, i);
            }
        }
    }

    UploadFile(formData, index) {
        this.dropboxFilesService.UploadFiles(formData).subscribe({
            next: (response) => {
                if (this.selectedCategoryInput.fileList.length - 1 == index) {
                    this.SearchFile();
                    this.selectedCategoryInput.fileList = [];
                }
            },
            error: err => {
                this.showSpinner = false;
                this.translate.get("MESSAGES.DropBoxApiNotResponding").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            }
        });
    }
    //#endregion File upload

    onRoomChange(offer,room){
        var message = "";
        this.translate.get("MESSAGES.RoomSelectionMsg").subscribe({
            next: (res) => {
                message = res.replace('@room', room ? room.name : "");
                }, error: err => { }
            })
            const dialogRef = this.dialog.open(AlertMessageModelComponent, {
                height: "200px",
                data: { Header: message }
            })
            dialogRef.afterClosed().subscribe(result => {
              if (result) {
                this.updateDiverseQty(offer);
                this.updateRoomLengthWidth(offer);
             }
        });
    }

    getOfferItemTimer(offerItem) {
        var selectedMaterial = this.MaterialObject.find(o => o.id == offerItem.materialID);
        if (selectedMaterial) {
            var selectedPrice = selectedMaterial.offerMaterialPriceList.find(o => o.id == offerItem.materialPriceID);
            if (selectedPrice) {
                let timer = 0;
                if (selectedPrice.isAddMeterRangePrice) {
                    if (offerItem.materialPriceID) {
                        if (offerItem.qty >= selectedPrice.startMeter1 && offerItem.qty <= selectedPrice.endMeter1)
                            timer = selectedPrice.timeMeter1;
                        else if (offerItem.qty >= selectedPrice.startMeter2 && offerItem.qty <= selectedPrice.endMeter2) 
                            timer = selectedPrice.timeMeter2;
                        else if (offerItem.qty >= selectedPrice.startMeter3 && offerItem.qty <= selectedPrice.endMeter3) 
                            timer = selectedPrice.timeMeter3;
                    }
                }
                else
                    timer = selectedPrice.timeMeter;
                if(offerItem.qty && offerItem.qty >0)
                    offerItem.timer = offerItem.qty ? (offerItem.qty * (timer ? timer: 0)) : 0;
                else
                    offerItem.timer;
                this.paintTimer += offerItem.timer;
            }
        }
    }
}


