export const locale = {
  lang: 'ds',
  data: {
    'Headers': {
      'NewMaterial': 'Nyt Materialer',
      'Material': 'Materialer',
      'DeleteAlert': 'Vil du fjerne denne medarbejder?',//'Er du sikker på, at du vil slette denne fil?',
      'AllDeleteAlert': 'Vil du fjerne denne medarbejder på alle opgaver?', //'Er du sikker på du vil slette alle filer på denne sag ?',
      'Yes': 'JA',
      'No': 'NEJ'
    }
  }
};
