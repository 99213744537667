import { Component, OnInit, Inject } from '@angular/core';
import { ProjectofferService } from 'app/main/pages/project-offer/project-offer.service';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { Router, ActivatedRoute } from '@angular/router';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { OfferCategory, OfferCategoryMaterial, OfferAddressModel } from 'app/main/model/ProjectOffer';
import { OfferMaterialWithPrice } from 'app/main/model/OfferV2';
import { MaterialTypeCategory, PaintSelectTime } from 'app/constant/common.constant';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormService } from 'app/core/services/form.service';
import { spaceOnlyValidator } from 'app/core/Validators/validation';
import { NewMaterialComponent } from 'app/main/pages/material/new-material/new-material.component';

@Component({
    selector: 'app-offer-category-edit',
    templateUrl: './edit-offer-category.component.html',
    styleUrls: ['./edit-offer-category.component.scss']
})
export class EditOfferCategoryComponent implements OnInit {
    offerCategoryId: string = "";
    offerCategory = new OfferCategory();
    showSpinner: boolean = false;
    selectMaterialOfferList = MaterialTypeCategory;
    MaterialObject: OfferMaterialWithPrice[] = [];
    MasterMaterialTypeList: OfferMaterialWithPrice[] = [];
    MaterialTypeSearchForCategory = "";
    previousCategory = new OfferCategory();
    SelectCategoryTime = PaintSelectTime;
    formOfferCategory: FormGroup;
    submitted = false;
    tempDate: any;
    constructor(private projectofferService: ProjectofferService,
        private _route: ActivatedRoute,
        public dialog: MatDialog,
        private formBuilder: FormBuilder,
        private formService: FormService,
        public dialogRef: MatDialogRef<EditOfferCategoryComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private translationLoader: FuseTranslationLoaderService) {
        this.translationLoader.loadTranslations(english, danish);
    }
    ngOnInit() {
        this.tempDate = this.data;
        this.offerCategoryId = this.data.offerCategoryId;
        this.previousCategory = this.data.previousCategory;
        this.getOfferMaterialData();
        //this.GetOfferCategories();
        if (!this.offerCategoryId && this.previousCategory) {
            this.offerCategory.parentID = this.previousCategory.id;
            this.offerCategory.formName = this.previousCategory.formName;
            this.offerCategory.level = this.previousCategory.level + 1;
            this.offerCategory.isLeafNode = true;
            this.offerCategory.categoryMaterialList = [];
        }
        else {
            this.offerCategory.level = 1;
        }
        this.formOfferCategory = this.formBuilder.group({
            categoryName: [null, Validators.compose([Validators.required, spaceOnlyValidator.bind(this)])],
        });
    }

    ChangeImage(event) {
        this.offerCategory.image = event;
    }

    GetOfferCategories() {
        this.showSpinner = true;
        this.projectofferService.getSingleOfferCategory(this.offerCategoryId)
            .subscribe((response: ResponseBase) => {
                this.showSpinner = false;
                if (response) {
                    this.offerCategory = response.result.offerCategory;
                    if (this.offerCategory.categoryMaterialList && this.offerCategory.categoryMaterialList.length > 0)
                        this.getCategoryMaterialOnEdit()
                    else
                        this.offerCategory.categoryMaterialList = [];
                }
            },
                error => (err) => {
                    this.showSpinner = false;
                });
    }

    save() {
        this.submitted = true;
        this.formService.markFormGroupTouched(this.formOfferCategory);
        if (this.formOfferCategory.invalid) {
            return;
        }
        this.showSpinner = true;
        if (this.offerCategoryId) {
            this.projectofferService.UpdateOfferCategory(this.offerCategoryId, this.offerCategory)
                .subscribe((response: ResponseBase) => {
                    this.showSpinner = false;
                    if (response) {
                        this.offerCategory = response.result;
                        this.dialogRef.close(this.offerCategory)
                    }
                },
                    error => (err) => {
                        this.showSpinner = false;
                    });
        }
        else {
            this.projectofferService.CreateOfferCategory(this.offerCategory)
                .subscribe((response: ResponseBase) => {
                    this.showSpinner = false;
                    if (response) {
                        this.offerCategory = response.result;
                        this.dialogRef.close(this.offerCategory)
                    }
                },
                    error => (err) => {
                        this.showSpinner = false;
                    });
        }
    }

    onCloseDialog(e) {
        if (this.formOfferCategory.valid)
            this.dialogRef.close(this.offerCategory)
        else
            this.dialogRef.close();

    }

    getOfferMaterialData() {
        this.showSpinner = true;
        this.projectofferService.GetOfferCategoryMaterial().subscribe({
            next: (response: ResponseBase) => {
                this.MaterialObject = response.result.myResponse;
                this.MasterMaterialTypeList = response.result.myResponse;
                this.showSpinner = false;
            },
            error: err => {
                this.showSpinner = false;
            },
            complete: () => {
                if (this.offerCategoryId)
                    this.GetOfferCategories();
                //this.showSpinner = false;
            }
        });

    }

    addMaterial() {
        var offerPrice = new OfferCategoryMaterial();
        offerPrice.materialTypeID = 0;
        offerPrice.offerCategoryId = this.offerCategoryId;
        offerPrice.materialList = this.MaterialObject.map(x => Object.assign({}, x));
        if (!this.offerCategory.categoryMaterialList) {
            this.offerCategory.categoryMaterialList = [];
        }
        this.offerCategory.categoryMaterialList.push(offerPrice);
    }

    getMaterialSearchClear(value, i) {
        if (!value) {
            this.materialTypeDropdownSearch(i);
        }
    }

    materialTypeDropdownSearch(i) {
        if (this.offerCategory.categoryMaterialList[i].materialTypeID)
            this.offerCategory.categoryMaterialList[i].materialList = this.MasterMaterialTypeList.filter(o => { return o.type.split(',').find(p => p == this.offerCategory.categoryMaterialList[i].materialTypeID.toString() && o.text.toLowerCase().includes(this.MaterialTypeSearchForCategory.toLowerCase())) });
        else
            this.offerCategory.categoryMaterialList[i].materialList = this.MasterMaterialTypeList.filter(o => o.text.toLowerCase().includes(this.MaterialTypeSearchForCategory.toLowerCase()));
    }

    _handleKeydown(event: KeyboardEvent) {
        if (event.keyCode === 13) {
            event.stopPropagation();
        }
    }

    getOfferMaterial(type, i) {
        if (type == 0) {
            this.offerCategory.categoryMaterialList[i].materialList = this.MasterMaterialTypeList;
        } else {
            this.offerCategory.categoryMaterialList[i].materialList = this.MasterMaterialTypeList.filter(o => { return o.type.split(',').find(p => p == type) });
        }
        if (this.offerCategory.categoryMaterialList[i].materialList.length > 0) {
            this.offerCategory.categoryMaterialList[i].offerMaterialID = this.offerCategory.categoryMaterialList[i].materialList[0].id;
            this.onChangeMaterialSelection(this.offerCategory.categoryMaterialList[i].offerMaterialID, this.offerCategory.categoryMaterialList[i]);
            //Get Price start
            // var materilaPriceData = this.offerCategory.categoryMaterialList[i].materialList[0].offerMaterialPriceList.filter(x => x.isDefault == true);
            // if (materilaPriceData && materilaPriceData.length > 0) {
            //     if (materilaPriceData[0].isAddMeterRangePrice) {
            //         this.offerCategory.categoryMaterialList[i].price = materilaPriceData[0].priceMeter1;
            //     }
            //     else {
            //         this.offerCategory.categoryMaterialList[i].price = materilaPriceData[0].price;
            //     }
            //     this.offerCategory.categoryMaterialList[i].reminder = materilaPriceData[0].reminder;
            // } else {
            //     if (this.offerCategory.categoryMaterialList[i].materialList[0].offerMaterialPriceList[0].isAddMeterRangePrice) {
            //         this.offerCategory.categoryMaterialList[i].price = this.offerCategory.categoryMaterialList[i].materialList[0].offerMaterialPriceList[0].priceMeter1;
            //     }
            //     else {
            //         this.offerCategory.categoryMaterialList[i].price = this.offerCategory.categoryMaterialList[i].materialList[0].offerMaterialPriceList[0].price;
            //     }
            //     this.offerCategory.categoryMaterialList[i].reminder = this.offerCategory.categoryMaterialList[i].materialList[0].offerMaterialPriceList[0].reminder;
            // }
            //end
        }
        else {
            this.offerCategory.categoryMaterialList[i].offerMaterialID = "";
        }
    }

    clearOfferItem(offerItem) {
        offerItem.offerMaterialID = "";
        offerItem.materialTypeID = 0;
        offerItem.reminder = "";
    }

    getCategoryMaterialOnEdit() {
        for (let i = 0; i < this.offerCategory.categoryMaterialList.length; i++) {
            if (this.offerCategory.categoryMaterialList[i].materialTypeID) {
                this.offerCategory.categoryMaterialList[i].materialList = this.MasterMaterialTypeList.filter(o => { return o.type.split(',').find(p => p == this.offerCategory.categoryMaterialList[i].materialTypeID.toString()) });
                this.getMaterialPrice(this.offerCategory.categoryMaterialList[i].offerMaterialID, this.offerCategory.categoryMaterialList[i], this.offerCategory.categoryMaterialList[i].materialPriceID)
                // var materialFind = this.offerCategory.categoryMaterialList[i].materialList.find(o => o.id == this.offerCategory.categoryMaterialList[i].offerMaterialID);
                // var materialPriceData = materialFind.offerMaterialPriceList.find(x => x.isDefault == true);
                // if (materialPriceData) {
                //     if (materialPriceData.isAddMeterRangePrice) {
                //         this.offerCategory.categoryMaterialList[i].price = materialPriceData.priceMeter1;
                //     }
                //     else {
                //         this.offerCategory.categoryMaterialList[i].price = materialPriceData.price
                //     }
                //     this.offerCategory.categoryMaterialList[i].reminder = materialPriceData.reminder;
                // } else {
                //     if (materialFind.isAddMeterRangePrice) {
                //         this.offerCategory.categoryMaterialList[i].price = materialFind.priceMeter1;
                //     }
                //     else {
                //         this.offerCategory.categoryMaterialList[i].price = materialFind.price;
                //     }
                //     this.offerCategory.categoryMaterialList[i].reminder = materialFind.reminder;
                // }
                //end
            } else {
                this.offerCategory.categoryMaterialList[i].materialList = this.MasterMaterialTypeList;
                this.getMaterialPrice(this.offerCategory.categoryMaterialList[i].offerMaterialID, this.offerCategory.categoryMaterialList[i], "");
                // var materialList = this.MasterMaterialTypeList.find(o => o.id == this.offerCategory.categoryMaterialList[i].offerMaterialID);
                // if (materialList) {
                //     var offerPriceData = materialList.offerMaterialPriceList.find(x => x.isDefault == true);
                //     if (!offerPriceData) {
                //         offerPriceData = materialList.offerMaterialPriceList[0];
                //     }
                //     if (offerPriceData.isAddMeterRangePrice) {
                //         this.offerCategory.categoryMaterialList[i].price = offerPriceData.priceMeter1;
                //     }
                //     else {
                //         this.offerCategory.categoryMaterialList[i].price = offerPriceData.price;
                //     }
                // this.offerCategory.categoryMaterialList[i].reminder = offerPriceData.reminder;
                // }
                // this.offerCategory.categoryMaterialList[i].materialList = this.MasterMaterialTypeList;
            }
        }
    }

    setShowRoomDetail(flag) {
        this.offerCategory.showRoomDetails = !flag;
    }

    setInnerMultipleOptions(flag) {
        this.offerCategory.multicheck = !flag;
        this.offerCategory.isLeafNode = this.offerCategory.multicheck;
        if (this.offerCategory.isLeafNode) {
            this.offerCategory.formName = "FormB";
        }
    }

    formNameChange(name) {
        this.offerCategory.formName = name;
    }

    deleteOfferMaterial(index, item) {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: "200px",
            data: { Header: 'Headers.DeleteDiverseMaterial' }
        })
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                if (item.id) {
                    this.projectofferService.DeleteOfferCategoryMaterial(item.id)
                        .subscribe((response: ResponseBase) => {
                            this.offerCategory.categoryMaterialList.splice(index, 1);
                        },
                            error => (err) => {
                            },
                            () => {
                            });
                }
                else
                    this.offerCategory.categoryMaterialList.splice(index, 1);
            }
        });
    }

    getOfferCategoryMaterialPris(material, i) {
        //Get Price start
        if (this.offerCategory.categoryMaterialList[i].materialList.length > 0) {
            var materilaPriceData = material.offerMaterialPriceList.find(x => x.isDefault == true);
            if (materilaPriceData) {
                if (materilaPriceData.isAddMeterRangePrice) {
                    this.offerCategory.categoryMaterialList[i].price = materilaPriceData.priceMeter1;
                }
                else {
                    this.offerCategory.categoryMaterialList[i].price = materilaPriceData.price;
                }
                this.offerCategory.categoryMaterialList[i].reminder = materilaPriceData.reminder;
            } else {
                if (material.offerMaterialPriceList[0].isAddMeterRangePrice) {
                    this.offerCategory.categoryMaterialList[i].price = material.offerMaterialPriceList[0].priceMeter1;
                }
                else {
                    this.offerCategory.categoryMaterialList[i].price = material.offerMaterialPriceList[0].price;
                }
                this.offerCategory.categoryMaterialList[i].reminder = material.offerMaterialPriceList[0].reminder;
            }
        }
    }

    getMaterialPrice(materialId, offerItem, materialPriceId) {
        var materialprice = this.MasterMaterialTypeList.find(o => o.id == materialId);
        if (materialprice) {
            offerItem.materialID = materialprice.id;
            offerItem.materialNumber = materialprice.materialNumber;
            
            offerItem.imagePath = materialprice.imagePath;
            offerItem.selectCategoryTimeForDiverse = [];
            for (var i = 0; i < materialprice.offerMaterialPriceList.length; i++) {
                var obj = this.SelectCategoryTime.find(o => o.id == materialprice.offerMaterialPriceList[i].type);
                if (obj) {
                    if (i == 0) {
                        offerItem.materialPriceID = materialprice.offerMaterialPriceList[i].id;
                        if (materialprice.offerMaterialPriceList[i].isAddMeterRangePrice) {
                            offerItem.price = materialprice.offerMaterialPriceList[i].priceMeter1;
                        }
                        else {
                            offerItem.price = materialprice.offerMaterialPriceList[i].price;
                        }
                    }
                    offerItem.selectCategoryTimeForDiverse.push({
                        id: obj.id,
                        key: obj.key,
                        extraValue: materialprice.offerMaterialPriceList[i].paintText,
                        priceId: materialprice.offerMaterialPriceList[i].id,
                        reminder: materialprice.offerMaterialPriceList[i].reminder,
                    });
                    if (materialprice.offerMaterialPriceList[i].id == materialPriceId) {
                        offerItem.materialPriceID = materialprice.offerMaterialPriceList[i].id;
                        if (materialprice.offerMaterialPriceList[i].isAddMeterRangePrice) {
                            offerItem.price = materialprice.offerMaterialPriceList[i].priceMeter1;
                        }
                        else {
                            offerItem.price = materialprice.offerMaterialPriceList[i].price;
                        }
                        //offerItem.price = materialprice.offerMaterialPriceList[i].price;
                        offerItem.text = materialprice.text + ' ' + materialprice.offerMaterialPriceList[i].paintText;
                        var offerReminderText = materialprice.offerMaterialPriceList[i].reminder ? materialprice.offerMaterialPriceList[i].reminder : "";
                    }
                    else {
                        offerItem.text = materialPriceId ? offerItem.text : materialprice.text + ' ' + materialprice.offerMaterialPriceText;
                        offerItem.colorCode = offerItem.colorCode ? offerItem.colorCode : materialprice.colorCode;
                        offerItem.unit = materialprice.unit;
                        var offerReminderText = materialprice.offerMaterialPriceList[i].reminder ? materialprice.offerMaterialPriceList[i].reminder : "";
                    }

                    if (materialprice.offerMaterialPriceList[i].isDefault && materialPriceId == "") {
                        offerItem.materialPriceID = materialprice.offerMaterialPriceList[i].id;
                        if (materialprice.offerMaterialPriceList[i].isAddMeterRangePrice) {
                            offerItem.price = materialprice.offerMaterialPriceList[i].priceMeter1;
                        }
                        else {
                            offerItem.price = materialprice.offerMaterialPriceList[i].price;
                        }
                        //offerItem.price = materialprice.offerMaterialPriceList[i].price;
                        var offerReminderText = materialprice.offerMaterialPriceList[i].reminder ? materialprice.offerMaterialPriceList[i].reminder : "";
                        //offerItem.text = materialprice.text + ' ' + materialprice.offerMaterialPriceList[i].paintText;
                        offerItem.text = materialprice.text + ' ' + materialprice.offerMaterialPriceList[i].paintText + materialprice.addNumberOfTimesOnPDF ? this.projectofferService.getPaintType(materialprice.offerMaterialPriceList[i].type) : "";
                        if (offerItem.materialList && offerItem.materialList.length > 0) {
                            offerItem.materialList.filter(m => {
                                if (m.id == materialId) {
                                    var tempPriceData = m.offerMaterialPriceList.filter(x => x.id == offerItem.materialPriceID);
                                    if (tempPriceData && tempPriceData.length > 0)
                                        m.offerMaterialPriceText = tempPriceData[0].paintText;
                                }
                            })
                        }
                    }
                    offerItem.originalPrice = offerItem.price;
                    if (offerReminderText && materialprice.reminder) {
                        offerItem.reminder = materialprice.reminder + " - " + offerReminderText;
                    } else if (offerReminderText && !materialprice.reminder) {
                        offerItem.reminder = offerReminderText;
                    } else {
                        offerItem.reminder = materialprice.reminder ? materialprice.reminder : "";
                    }
                }
            }
            this.getOfferDiverseMeterPrice(offerItem);
        }
    }

    getOfferDiverseMeterPrice(offerItem) {
        var selectedMaterial = this.MaterialObject.find(o => o.id == offerItem.offerMaterialID);
        if (selectedMaterial) {
            var selectedPrice = selectedMaterial.offerMaterialPriceList.find(o => o.id == offerItem.materialPriceID);
            if (selectedPrice) {
                var price = selectedPrice.price;
                if (selectedPrice.isAddMeterRangePrice) {
                    if (offerItem.materialPriceID) {
                        if (offerItem.qty >= selectedPrice.startMeter1 && offerItem.qty <= selectedPrice.endMeter1) {
                            price = selectedPrice.priceMeter1;
                        }
                        else if (offerItem.qty >= selectedPrice.startMeter2 && offerItem.qty <= selectedPrice.endMeter2) {
                            price = selectedPrice.priceMeter2;
                        }
                        else if (offerItem.qty >= selectedPrice.startMeter3 && offerItem.qty <= selectedPrice.endMeter3) {
                            price = selectedPrice.priceMeter3;
                        }
                        else {
                            price = 0; //selectedPrice.price;
                        }
                    }
                }
                offerItem.reminder = selectedPrice.reminder ? selectedPrice.reminder : "";
                offerItem.price = price;
                offerItem.originalPrice = offerItem.price;
            }
        }
    }

    onChangeMaterialSelection(id, offer) {
        this.getMaterialPrice(id, offer, '');
    }

    getMaterialPriceOnCategoryTimeSelect(priceId, materialId, offerItem) {
        this.getMaterialPrice(materialId, offerItem, priceId);
        if (priceId && offerItem && offerItem.materialList) {
            offerItem.materialList.filter(m => {
                if (m.id == materialId) {
                    var tempPriceData = m.offerMaterialPriceList.filter(x => x.id == priceId);
                    if (tempPriceData && tempPriceData.length > 0)
                        m.offerMaterialPriceText = tempPriceData[0].paintText;
                }
            })
        }
    }

    gotoMaterial(id) {
        const matObj = this.MaterialObject.find(x => x.id == id);
        if (matObj) {
            const dialogRef = this.dialog.open(NewMaterialComponent, {
                height: '800px',
                width: '80%',
                data: matObj["materialID"],
            });
            dialogRef.afterClosed().subscribe(result => {});
        }
    }

    copyMaterialItem(index, item) {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: "200px",
            data: { Header: 'Headers.CopyMaterial' }
        })
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                var offerItem = new OfferCategoryMaterial();
                offerItem = Object.assign({}, item)
                offerItem.isCopyMaterial = true;
                offerItem.roomList = [];
                this.offerCategory.categoryMaterialList.push(offerItem);
            }
        });
    }
}
