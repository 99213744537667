import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { MatDialog } from '@angular/material';
import { OfferQualityTestAnswer } from 'app/main/model/OfferQualityTestAnswer';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { RequestOfferQualityTestAnswer } from 'app/main/model/RequestBase';
import { BehaviorSubject } from 'rxjs';
import { OfferQualityTestQuestionAnswer } from 'app/main/shared-component/new-questions/new-question.model';
import { AnswerCategoryQuestionComponent } from 'app/main/shared-component/answer-category-question/answer-category-question.component';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';
import { QualityTestService } from '../../quality-test.service';

@Component({
    selector: 'app-quality-test-category-question',
    templateUrl: './category-question.component.html',
    styleUrls: ['./category-question.component.scss']
})
export class CategoryQuestionComponent implements OnInit {
    showSpinner: boolean = false;
    @Output() AddOrUpdateQuestion = new EventEmitter<any>();
    //@Input() QuestionList: any = [];
    private _QuestionList = new BehaviorSubject<OfferQualityTestQuestionAnswer[]>(null);
    @Input()
    set QuestionList(value) {
        this._QuestionList.next(value);
    };
    get QuestionList() {
        return this._QuestionList.getValue();
    }
    @Input() ModuleID: string = "";
    @Input() ProjectID: string = "";
    @Input() OfferQualityTestCategoryID: string = "";
    @Input() ModuleName: string = "";
    @Input() ModuleRoomSelectID: string = "";
    offerQualityTestAnswerList: OfferQualityTestAnswer[] = [];
    requestOfferQualityTestAnswer: RequestOfferQualityTestAnswer = new RequestOfferQualityTestAnswer();
    displayPagination: boolean;
    pagination: any;
    limitNumber: any;
    questionAnswerList = [];
    isHideAnswer: boolean = false;

    constructor(private _route: ActivatedRoute,
        private translationLoader: FuseTranslationLoaderService,
        private translate: TranslateService,
        public dialog: MatDialog,
        private notificationsService: NotificationsService,
        private qualityTestService: QualityTestService) {
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
        this.requestOfferQualityTestAnswer.projectID = this.ProjectID;
        this.requestOfferQualityTestAnswer.offerID = this.ModuleID;
        this.requestOfferQualityTestAnswer.qualityTestCategoryID = this.OfferQualityTestCategoryID;
        this._QuestionList
            .subscribe(list => {
                if (list && list.length > 0) {
                    this.questionAnswerList = Object.assign([], list);
                } else {
                    this.questionAnswerList = [];
                }
                this.questionAnswerList = Object.assign([], this.questionAnswerList);
            });
        //requestProjectOfferRoom.OfferId = this.ModuleRoomSelectID ? this.ModuleRoomSelectID : null;
        this.requestOfferQualityTestAnswer.offerID = this.ModuleRoomSelectID ? this.ModuleRoomSelectID : "";
        this.GetOfferQualityTestAnswerList();
        //this.CaregoryAnswerPopup();
    }

    AddOrUpdateQuestionSave(event) {
        this.AddOrUpdateQuestion.emit(event);
    }

    EditOfferQualityTestQuestionAnswer(id) {
        var obj = this.offerQualityTestAnswerList.find(o => o.id == id);
        if (obj) {
            this.isHideAnswer = true;
            var tmpObj = JSON.parse(obj.json);
            this.CaregoryAnswerPopup(obj, tmpObj, false);
        }
    }

    GetOfferQualityTestAnswerList() {
        this.showSpinner = true;
        this.qualityTestService.GetOfferQualityTestAnswerList(
            this.requestOfferQualityTestAnswer).subscribe({
                next: (response: ResponseBase) => {
                    if (response) {
                        this.showSpinner = false;
                        this.offerQualityTestAnswerList = response.result;
                        this.pagination = response.pagination;
                        this.displayPagination = true;
                    }
                    else {
                        this.offerQualityTestAnswerList = [];
                        this.displayPagination = false;
                    }
                },
                error: err => {
                    this.showSpinner = false;
                    this.offerQualityTestAnswerList = [];
                    this.displayPagination = false;
                },
                complete: () => {

                }
            });
    }

    CaregoryAnswerPopup(obj, jsonData, isNewCreate) {
        const dialogRef = this.dialog.open(AnswerCategoryQuestionComponent, {
            height: "90%",
            width: "70%",
            disableClose: true,
            data: {
                Header: "CateGory Question Answer",
                ShowOKButton: true,
                data: jsonData,
                isHideAnswer: this.isHideAnswer
                //projectId: this.ProjectId,
                //requisitionId: this.Requisition.id,
                //lastStaus: lastReqStatus,
                //newStatus: newReqStatus,
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                if (isNewCreate) {
                    var offerQualityTestAnswer: OfferQualityTestAnswer = new OfferQualityTestAnswer();
                    offerQualityTestAnswer.projectID = this.ProjectID;
                    offerQualityTestAnswer.offerID = this.ModuleID;
                    offerQualityTestAnswer.offerQualityTestCategoryID = this.OfferQualityTestCategoryID;
                    offerQualityTestAnswer.json = result;
                    offerQualityTestAnswer.qualityTestCategoryID = this.OfferQualityTestCategoryID;
                    this.CreatedOfferCategoryAnswer(offerQualityTestAnswer);
                }
                else {
                    obj.json = result;
                    this.UpdateOfferCategoryAnswer(obj);
                }
            }
            this.isHideAnswer = false;
        });
    }
    CreatedOfferCategoryAnswer(offerQualityTestAnswer) {
        this.showSpinner = true;
        this.qualityTestService.AddOfferQualityTestAnswer(
            offerQualityTestAnswer).subscribe({
                next: (response: ResponseBase) => {
                    if (response) {
                        this.showSpinner = false;
                        this.GetOfferQualityTestAnswerList();
                    }
                },
                error: err => {
                    this.showSpinner = false;
                    this.offerQualityTestAnswerList = [];
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });
                },
                complete: () => {

                }
            });
    }

    UpdateOfferCategoryAnswer(offerQualityTestAnswer) {
        this.showSpinner = true;
        this.qualityTestService.UpdateOfferQualityTestAnswer(
            offerQualityTestAnswer.id, offerQualityTestAnswer).subscribe({
                next: (response: ResponseBase) => {
                    if (response) {
                        this.showSpinner = false;
                        this.GetOfferQualityTestAnswerList();
                    }
                },
                error: err => {
                    this.showSpinner = false;
                    this.offerQualityTestAnswerList = [];
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });
                },
                complete: () => {

                }
            });
    }

    DeleteQuestionByID(ID) {
        this.questionAnswerList = this.questionAnswerList.filter(i => i['id'] != ID)
        this.AddOrUpdateQuestion.emit(this.questionAnswerList);
    }

    DeleteQuestionAnswerConfirm(objId) {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: '20%',
            data: { Header: 'Headers.DeleteOfferQualityTestAnswer' }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.DeleteQuestionAnswer(objId);
            }
        });
    }

    DeleteQuestionAnswer(Id) {
        this.showSpinner = true;
        this.qualityTestService.DeleteOfferQualityTestAnswer(Id).subscribe({
            next: (response: ResponseBase) => {
                this.translate.get("MESSAGE.Deleted").subscribe({
                    next: (res) => {
                        this.notificationsService.create(res);
                    },
                    error: err => {
                    }
                });
                this.GetOfferQualityTestAnswerList();
            },
            error: err => {
                this.translate.get("MESSAGE.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }
}
