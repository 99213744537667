export const locale = {
    lang: 'ds',
    data: {
        'CUSTOMER': {
            'TITLE': 'Kunde',
            'DESC': 'Hej Projekt!',
            'TabBasicDetail': 'Oplysninger',
            'TabContactDetail': 'Kontaktoplysninger',
            'TabOrderDetail': 'Projekter',
            'TabFiles': 'Filer',
            'Log': 'Log',
        },
        'TABS': {
            'TabBasicDetail': 'Oplysninger',
            'Log': 'Log',
            'Files': 'Filer',
            'Note': 'Bemærk',
        },
        'BUTTONS': {
            'SHOWCUSTOMER': 'Se Kunde',
            'NEWCUSTOMER': 'Ny Kunde',
            'BACK': 'Gå tilbage',
            'BTNSAVE': 'Gem',
            'BTNCONFIRM': 'Bekræfte',
            'CREATE_FOLDER': 'Opret mappe',
            'DOWNLOAD': 'Hent',
            'DELETE': 'Slet',
            'NEWProject': 'Ny projekt',
            'MatchCustomer': 'Match kunde',
            'InActive': 'Indstil forespørgsel som inaktiv',
            'Active': 'Indstil forespørgsel som aktiv',
            'UnassignCustomer': 'Fjern kunde',
            'CreateProject': 'Opret Projekt',
            'GoToProject': 'Gå til Projekt',
            'CreateOffer': 'Opret tilbud',
            'GoToOffer': 'Gå til Tilbud',
        },
        'FIELDS': {
            'CustomerNo': 'Kunde nr.',
            'Name': 'Navn',
            'Email': 'E-mail',
            'PhoneNo': 'Telefon',
            'Address': 'Adresse',
            'Address-line-2': 'Adresse (Linje 2)',
            'City': 'By',
            'Zipcode': 'Postnummer',
            'Position': 'Position',
            'Comments': 'Kommentarer  [ Valgfri ]',
            'OrderName': 'Ordre navn',
            'EnterLocation': 'Indtast din placering',
            'Active': 'Indstil som inaktiv',
            'From': 'Fra',
            'To': 'til',
            'colorCode': 'Farvekode',
            'PersonnelPurchas': 'Personale køb',
            'EmailInvoice': 'Email Faktura',
            'Status': 'Status',
            'CoworkerComment': 'Medarbejder kommentar',
            'Number': 'Nummer',
            'NoCustomerMap': 'No Customer Map.',
            'OfferInformation': 'Tilbud Information',
            'CustomerInformation': 'Kunde Information',
            'ProjectInformation': 'Projekt Information',
            'MatchCustomerInformation': '** Match kundens oplysninger baseret på navn, e-mail, telefonopkald..',
            'WhereInquiryCome': 'Hvor kommer forespørgelse fra',
            'received': 'Modtaget dato og tid',
            'NoMatchCustomerInformation': 'Der blev ikke fundet nogen kundes matchdetaljer',
            'SORTING_STATUS': 'Status',
            'All': 'Alle',
            'IndoorOutDoor': 'Ind, ud, Sprøjt, Reklamation *',
            'CoWorkers': 'Co-Workers',
            'CommentPlaceholder':'Kommentarer [Valgfri]'
        },
        'Headers': {
            'NewCustomer': 'Ny Kundeformular',
            'CustomerList': 'Kunde Liste',
            'addressModalTitle': 'Tjek bekræfte din adresse detaljer',
            'NoFileSelected': 'Ingen fil valgt',
            'ChooseFile': 'Vælg fil',
            'FOLDEREMPTY': 'Denne folder er tom',
            'LogTabSRNO': 'SR NO',
            'LogTabRequisitionnumber': 'Opgavenummer',
            'LogTabChangedon': 'Dato',
            'LogTabChangedtime': 'Tid',
            'LogTabChangedfrom': 'Fra',
            'LogTabChangedto': 'Til',
            'LogTabChangedby': 'Ændret af',
            'LogTabCreatedby': 'Lavet af',
            'Desc': 'Tekst',
            'CustomerDetail': 'Er du sikker på, at du vil beholde den valgte kundes grundlæggende detaljer ?'
        },
        'VALIDATIONS': {
            'ContactNumber': 'Indtast kontakt nr',
            'TEXT': 'Indtast værdi',
            'ONLY40CHARACTER': 'Maksimal længde er 40 tegn.',
            //'Email': 'Indsæt Gyldig E-mail',
            'Email': 'Indsæt E-mail',
            'ValidEmail':'Indtast gyldig e-mail adresse',
            'PHONENO': 'Indtast gyldigt nummer',
            'ITEMNUMBEREXIST': 'Nummeret eksisterer allerede',
            'ITEMNUMBERMIN': 'Indtast gyldigt nummer',
            'ITEMNUMBERMAX': 'Indtast gyldigt nummer',
            'BEHOLDINGMIN': 'Indtast gyldigt nummer',
            'BEHOLDINGMAX': 'Indtast gyldigt nummer',
            'colorCode': 'Indtast gyldigt Farvekode',
            'IndoorOutdoorStatus': 'Vælg Ind, ud, Sprøjt, Reklamation *'
        },
        'MESSAGES': {
            'NORECORD': 'Ingen registrering fundet',
            'NoOrderAvailbale': 'Ingen projekt til rådighed',
            'DropBoxApiNotResponding': 'Dropbox reagerer ikke, prøv efter lidt tid',
            'FileConflict': 'Filen eksisterer allerede',
            'FolderConflict': 'Mappenavn eksisterer allerede',
            'Created': 'Oprettet med succes',
            'Updated': 'Opdateret med succes',
            'Deleted': 'Slettet med succes',
            'Failed': 'Operation mislykket, prøv senere',
            'EmailSent': 'Email sendt med succes',
            'NoProjectAdded': 'Intet projekt tilføjet',
            'NoOfferAdded': 'Intet tilbud tilføjet.',
        },
        'whereDoesTheInquiryComeFrom': {
            'value1': 'WWW (Internet)',
            'value2': 'Hjemmeside',
            'value3': 'Facebook',
            'value4': 'Anbefaling',
            'value5': 'Begivenhed',
            'value6': 'Manuel',
            'value7': 'Telefon',
        },
        // 'selectedValueStatus': {
        //     'value0': 'Registered',
        //     'value1': 'Process',
        //     'value2': 'Planlagt besøg',
        //     'value3': 'Klar til opstart',
        //     'value4': 'Tilbud sendt',
        //     'value5': 'Tilbud godkendt',
        //     'value6': 'Planlagt',
        //     'value7': 'Tilbud afvist',
        //     'value8': 'Startet',
        //     'value9': 'Udført',
        //     'value10': 'Faktureret',
        //     'value11': 'Afventer',
        //     'value12': 'Klar fakturering',
        // },
        'selectedValueIndoorOutdoorStatus': {
            'value1': 'Indendørs ',
            'value2': 'Udendørs',
            'value3': 'Sprøjtemaling',
            'value4': 'Reklamation',
            'value5': 'Personale køb', //drawing of painter
            'value6': 'Butiks køb'     //Nordsjø logo
        },
        'TOOTIPORTITLE': {
            'ViewCustomer': 'Se Kunde',
            'SendSMS':'Send SMS Til Kunden',
            'SendEmail':'Send E-mail Til Kunden',
            'DeleteInquiry':'Slet Forespørgsel',
            'SelectCustomer':'Vælg Kunde',
        },
    }
};
