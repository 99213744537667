export const locale = {
    lang: 'en',
    data: {
        'Header': {
            "Title": "Payroll list"
        },
        "FIELDS": {
            // "Postnumber":"PostNumber",
            // "City":"City",
            // "Percentage":"Percentage",
            // "Remark":"Remark",
            // "PostNumberSearch":"Search by PostNumber, City",
            // "PostnumberFrom":"PostNumber From",
            // "PostnumberTo":"PostNumber To",
            'Coworker': 'Coworker',
            'Date': 'Date',
            'NumberofHours': 'Number of hours',
            'HourlyRate': 'Hourly Rate',
            'TotalAmount': 'Total Amount',
            'Description': 'Description',
            'koersel': 'Driving',
            'GDag': 'G-days',
            'Holidays': 'Holidays',
            'NumberofHolidays': 'Number of holidays',
            // 'koerselLabel': 'Course',
            'koerselLabel': 'Driving Kilometers',
            'GDagLabel': 'G-days',
            'HolidaysLabel': 'Holidays',
            'SalaryAdvanceLabel':'Salary Advance',
            'OtherLabel':'Other'
        },
        "BUTTONS": {
            "Save": "Save",
            "BACK": "Back"
        },
        "MESSAGES": {
            'Updated': 'Updated Successfully.',
            'Failed': 'Failed',
            'Deleted': 'Deleted successfully',
            "NORECORD": "No Record Found",
            'Created': 'Created successfully',
        },
        'VALIDATIONS': {
            // 'PostNummber': 'Enter postNumber',
            // 'City': 'Enter city',
            // 'Percentage': 'Enter percentage',
            // 'ValidPercentage': 'Enter valid percentage',
            // 'ValidPostNumber': 'Indtast gyldig postnumber',

            'CoworkerRequired': "Select Coworker",
            'HourlyRateRequired': 'Hourly rate is required',
            'DateRequired': "Date Required",
            'TotalAmountRequired': "Total amount required",
            'DesciptionRequired': "Desciption required",
            'NumberofHoursRequired': "Number of hours required",
            'PayrollTypeRequired': "Payroll type required",
        }
    }
}