export const locale = {
  lang: 'ds',
  data: {
    'Tabs': {
      'Offer': 'Tilbud',
      'BasicDetail': 'Grundlæggende detaljer',
      'File': 'Fil',
      'Category': 'Kategori',
      'WorkItem': 'Arbejdspost',
      'ColorCode':'Farvekode',
      'CustomerColorCode':'Debitor Farvekode',
    },
    'MESSAGE': {
      'Failed': 'Operation mislykket, prøv senere',
      'Success': 'Succes.',
      'CommingSoon': 'Kommer snart..',
      'Deleted': 'Slettet med succes',
      'Added': 'Oprettet med succes',
      'Updated': 'Opdateret med succes',
      'NORECORD': 'Ingen farvekode fundet',
    },
    'FIELDS':{
      'GoTo':'Gå til',
      'ColorCode':'Farvekode',
      'SRNO': 'Sr Nr',
      'Status': 'Status',
      'Number': 'Nummer',
      'Name': 'Navn',
      'Createdby': 'Created By',
      'CreatedDate': 'Created Dato',
      'ModifyBy': 'Modify By',
      'ModifyDate': 'Modify Dato',
      'CoworkerName': 'Medarbejder',
      'Date': 'Dato',
      'Description': 'Description',
      'All': 'Alle',
      'RoomName': 'Rum Navn',
      'Text': 'Tekst',
      'SelectRoom':'Vælg Rum',
    },
    'BUTTONS': {
      'Add': 'Tilføj',
      'Update':'Opdatering'
    },
    'Headers': {
      'DeleteCategory': 'Vil du slette kategori',
    },
    'TITLE': {
      'CustomerPagination': 'Antal log pr. side',
    },
  }
};
