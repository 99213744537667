import { Component, OnInit, Input, Output, EventEmitter, Optional, Inject } from '@angular/core';
import { Rooms } from '../../../../model/ProjectOffer';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { FuseTranslationLoaderService } from '../../../../../core/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { CommonService } from 'app/core/services/common.service';
import { RoomAreaComponent } from '../area/room-area.component';
import { BehaviorSubject } from 'rxjs';
import { DenmarkDigitFormate } from 'app/core/pipes/denmarkDigitFormate.pipe';
import { spaceOnlyValidator } from 'app/core/Validators/validation';

@Component({
    selector: 'vibe-room-list',
    templateUrl: './room-list.component.html',
    styleUrls: ['./room-list.component.scss']
})
export class RoomListComponent implements OnInit {
    private _roomList = new BehaviorSubject<Rooms[]>([]);
    @Input()
	set RoomList(value) {
		this._roomList.next(value);
	};
	get RoomList() {
		return this._roomList.getValue();
	};

    private _removeRoomByIndex = new BehaviorSubject<Number>(null);
    @Input()
	set RemoveRoomByIndex(value) {
		this._removeRoomByIndex.next(value);
	};
	get RemoveRoomByIndex() {
		return this._removeRoomByIndex.getValue();
	};

    @Input() ModuleID: any;

    @Output() GetRooms = new EventEmitter<boolean>(false);
    @Output() DeleteRoom = new EventEmitter<any>(null);
    @Output() SaveRoom = new EventEmitter<any>(null);

    RoomList_main: Rooms[];        
    moduleID: string = "";
    showSpinner: boolean = false;
    displayNoRoomMessage: boolean = false;
    roomForm: FormGroup;
    roomTotalPriceForWall: number = 0;
    roomTotalPriceForCeiling: number = 0;
    roomTotalPriceForFloor: number = 0;
    noofRoomAdd: number = 5;
    ceilingHeight: number = 0;
    public denmarkDigitFormate = new DenmarkDigitFormate();

    constructor(
        public dialog: MatDialog,
        private translationLoader: FuseTranslationLoaderService,
        private formBuilder: FormBuilder,
        private translate: TranslateService,
        private notificationsService: NotificationsService,
    ) {
        this.translationLoader.loadTranslations(english, danish);
        this.roomForm = this.formBuilder.group({
            roomList: new FormArray([])
        });
    }

    ngOnInit() {
        this.RoomList_main = [];
        this.moduleID = this.ModuleID;

        this._roomList
			.subscribe(list => {
                list.forEach(item => {
                    item.moduleID = this.ModuleID
                });
                this.getRoomList(list);
			});
            
        this._removeRoomByIndex
			.subscribe(index => {      
                if(Number(index)>-1) {
                    this.t.removeAt(Number(index));
                }
			});
    }

    // convenience getters for easy access to form fields
    get f() { return this.roomForm.controls; }
    get t() { return this.f.roomList as FormArray; }

    getRoomList(list) {
        this.RoomList_main = [];
        if (list != null) {
            this.RoomList_main = list;
            this.displayNoRoomMessage = false;
            this.roomForm = this.formBuilder.group({
                roomList: new FormArray([])
            });
            for (let index = 0; index < this.RoomList_main.length; index++) {
                this.t.push(this.formBuilder.group({
                    id: [null],
                    moduleID: [null],
                    offerID: [null],
                    name: ['', [Validators.required, spaceOnlyValidator.bind(this)]],
                    // width: [0, [Validators.required, Validators.minLength(0), Validators.min(0.1), Validators.maxLength(9999999999)]],
                    // length: [0, [Validators.required, Validators.minLength(0), Validators.min(0.1), Validators.maxLength(9999999999)]],
                    width: [0],
                    length: [0],
                    ceilingHeight: [2.5, [Validators.required, Validators.minLength(0), Validators.min(0.1), Validators.maxLength(9999999999)]],
                    colorCode:[],
                    isAddExtraWallQty: [false],
                    extraQty1: [0],
                    extraQty2: [0],
                    extraPrice1: [0],
                    extraPrice2: [0],
                    baseline: [0],
                    height: [0],
                    area: [0],
                    extraM2QtyWall: [0],
                    extraM2QtyCeiling: [0],
                    extraM2QtyFloor: [0],
                    extraM2PriceWall: [0],
                    extraM2PriceCeiling: [0],
                    extraM2PriceFloor: [0]
                }));
            }
            this.t.patchValue(this.RoomList_main);
        }
    }

    AddCustomerRooms() {
        for (let index = 0; index < this.noofRoomAdd; index++) {
            this.t.push(this.formBuilder.group({
                id: [null],
                moduleID: [this.moduleID],
                offerID: [this.moduleID],
                name: ['', [Validators.required, spaceOnlyValidator.bind(this)]],
                // width: [0, [Validators.required, Validators.minLength(0), Validators.min(0.1), Validators.maxLength(9999999999)]],
                // length: [0, [Validators.required, Validators.minLength(0), Validators.min(0.1), Validators.maxLength(9999999999)]],
                width: [0],
                length: [0],
                ceilingHeight: [2.5, [Validators.required, Validators.minLength(0), Validators.min(0.1), Validators.maxLength(9999999999)]],
                colorCode:[],
                isAddExtraWallQty: [false],
                extraQty1: [0],
                extraQty2: [0],
                extraPrice1: [0],
                extraPrice2: [0],
                baseline: [0],
                height: [0],
                area: [0],
                extraM2QtyWall: [0],
                extraM2QtyCeiling: [0],
                extraM2QtyFloor: [0],
                extraM2PriceWall: [0],
                extraM2PriceCeiling: [0],
                extraM2PriceFloor: [0]
            }));
        }
        this.RoomList_main = this.t.value;
    }

    AddSingleNewRooms() {
        this.t.push(this.formBuilder.group({
            id: [null],
            moduleID: [this.moduleID],
            offerID: [this.moduleID],
            name: ['', [Validators.required, spaceOnlyValidator.bind(this)]],
            // width: [0, [Validators.required, Validators.minLength(0), Validators.min(1), Validators.maxLength(9999999999)]],
            // length: [0, [Validators.required, Validators.minLength(0), Validators.min(1), Validators.maxLength(9999999999)]],
            width: [0],
            length: [0],
            ceilingHeight: [2.5, [Validators.required, Validators.minLength(0), Validators.min(1), Validators.maxLength(9999999999)]],
            colorCode:[],
            isAddExtraWallQty: [false],
            extraQty1: [0],
            extraQty2: [0],
            extraPrice1: [0],
            extraPrice2: [0],
            baseline: [0],
            height: [0],
            area: [0],
            extraM2QtyWall: [0],
            extraM2QtyCeiling: [0],
            extraM2QtyFloor: [0],
            extraM2PriceWall: [0],
            extraM2PriceCeiling: [0],
            extraM2PriceFloor: [0]
        }));
        this.RoomList_main = this.t.value;
    }

    deleteRoom(id, index) {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: '200px',
            data: { Header: 'Headers.DeleteRoom' }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                if (id) {
                    this.DeleteRoom.emit({roomId: id, rowIndex: Number(index)})
                } else {
                    this.t.removeAt(index)
                    this.RoomList_main = this.t.value;
                }
            }
        });
    }

    save() {
        this.roomForm.markAllAsTouched();
        if (this.roomForm.valid) {
            this.RoomList_main = this.t.value;
            let hasDuplicates = this.RoomList_main.map(v => v.name).length > new Set(this.RoomList_main.map(v => v.name)).size ? true : false;
            if(hasDuplicates){
                    this.translate.get("MESSAGES.DuplicateRoom").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {}
                    });
            }
            else
                this.Add()
        }
    }

    Add() {
        this.SaveRoom.emit(this.RoomList_main)
    }

    getM2ForWall(room) {
        var data = room.value;
        var roomCalculation = (((data.width + data.length) * 2) * data.ceilingHeight)
            // + (data.extraQty1 ? data.extraQty1 : 0)
            // + (data.extraQty2 ? data.extraQty2 : 0)
            + (data.extraM2QtyWall ? data.extraM2QtyWall : 0);
        this.getTotalM2ForWall();
        return roomCalculation;
    }

    getTotalM2ForWall() {
        this.roomTotalPriceForWall = 0;
        this.t.value.forEach(x => {
            var roomCalculation = (((x.width + x.length) * 2) * x.ceilingHeight)
                // + (x.extraQty1 ? x.extraQty1 : 0)
                // + (x.extraQty2 ? x.extraQty2 : 0)
                + (x.extraM2QtyWall ? x.extraM2QtyWall : 0);
            this.roomTotalPriceForWall += roomCalculation;
        });
    }

    getM2ForCeiling(room) {
        var data = room.value;
        var M2Ceiling = (data.width * data.length)
            // + (data.extraQty1 ? data.extraQty1 : 0)
            // + (data.extraQty2 ? data.extraQty2 : 0)
            + (data.extraM2QtyCeiling ? data.extraM2QtyCeiling : 0);
        this.getTotalM2ForCeiling();
        return M2Ceiling;
    }

    getTotalM2ForCeiling() {
        this.roomTotalPriceForCeiling = 0;
        this.t.value.forEach(x => {
            var roomCalculation = (x.width * x.length)
                // + (x.extraQty1 ? x.extraQty1 : 0)
                // + (x.extraQty2 ? x.extraQty2 : 0)
                + (x.extraM2QtyCeiling ? x.extraM2QtyCeiling : 0);
            this.roomTotalPriceForCeiling += roomCalculation;
        });
    }

    getM2ForWorkwood(room) {
        var data = room.value;
        var M2Workwood = (data.width + data.width + data.length + data.length)
            // + (data.extraQty1 ? data.extraQty1 : 0)
            // + (data.extraQty2 ? data.extraQty2 : 0)
            + (data.extraM2QtyFloor ? data.extraM2QtyFloor : 0);
        this.getTotalM2ForWorkwood();
        return M2Workwood;
    }

    getTotalM2ForWorkwood() {
        this.roomTotalPriceForFloor = 0;
        this.t.value.forEach(x => {
            var total = (x.width + x.width + x.length + x.length)
                // + (x.extraQty1 ? x.extraQty1 : 0)
                // + (x.extraQty2 ? x.extraQty2 : 0)
                + (x.extraM2QtyFloor ? x.extraM2QtyFloor : 0);
            this.roomTotalPriceForFloor += total;
        });
    }

    changeCeilingHeight() {
        // if(this.RoomList_main.length>0){
        //     this.RoomList_main.filter(x=>{x.ceilingHeight= this.ceilingHeight; });
        //     this.t.patchValue(this.RoomList_main);
        // }
        if (this.t.value.length > 0) {
            this.t.value.filter(x => { x.ceilingHeight = this.ceilingHeight; });
            this.t.patchValue(this.t.value);
            setTimeout(() => {
                this.save();
            }, 500);
        }
    }

    GetArea(formData, index) {
        var calculationValue = {
            baseline: formData.value.baseline,
            height: formData.value.height,
            area: formData.value.area
        }
        const dialogRef = this.dialog.open(RoomAreaComponent, {
            height: '30%',
            width: '40%',
            data: calculationValue
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.t.value[index].baseline = result.baseline;
                this.t.value[index].height = result.height;
                this.t.value[index].area = result.area;
                this.t.patchValue(this.t.value);
            }
        });
    }

}
