import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { TitleService } from 'app/core/services/title.service';
import { NotificationsService } from 'angular2-notifications';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { ProjectService } from 'app/main/pages/project/project.service';
import { TranslateService } from '@ngx-translate/core';
import { ProjectRequisitionService } from 'app/main/pages/project/project.requisition.service';
@Component({
    selector: 'offer-add-material-to-project',
    templateUrl: './add-material-to-project.component.html',
    styleUrls: ['./add-material-to-project.component.scss']
})
export class AddMaterialToProjectComponent implements OnInit {
    showMessage: boolean = false;
    showSpinner = false;
    requisitionId: string = "";
    // roleName: any;
    requitionNum: any;
    requisitionList: any = [];
    materialList: any = [];
    //Initiators
    constructor(private translationLoader: FuseTranslationLoaderService,
        private _router: Router,
        public dialogRef: MatDialogRef<AddMaterialToProjectComponent>,
        private dialog: MatDialog,
        private titleService: TitleService,
        private projectService: ProjectService,
        private translate: TranslateService,
        private notificationsService: NotificationsService,
        private projectRequisitionService: ProjectRequisitionService,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
        this.requisitionList = this.data && this.data.requisitionList ? this.data.requisitionList : [];
        this.materialList = this.data && this.data.materialList ? this.data.materialList : [];
        //this.showSpinner = true;
        
    }

    

    back(){
        this.dialogRef.close(false);
    }

    getRequistionNumber(requistion) {
        //this.projectAndRequistion.requistion = requistion;
        this.requisitionId = requistion.id;
    }

    addMaterialToProject() {
        this.showSpinner = true;
        this.projectService.AddOfferEstimateMaterialToProject(this.requisitionId, this.materialList).subscribe(
            (response: ResponseBase) => {
                this.showSpinner = false;
                if (response) {
                    this.dialogRef.close(true);
                }
            },
            error => (err) => {
                this.showSpinner = false;
            });
    }

}
