import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../../../core/modules/shared.module';

import { VibeDocumentComponent } from './document.component';
import { DocumentService } from './document.service';

const routes = [
    //{
    //  path: '',
    //  children: [{
    //    path: 'documents',
    //    component: VibeDocumentComponent,
    //  }]
    //}
    {
        path: 'documents',
        component: VibeDocumentComponent
    }
];

@NgModule({
    declarations: [
        VibeDocumentComponent,
    ],
    imports: [
        SharedModule,
        RouterModule.forChild(routes)
    ],
    exports: [
        VibeDocumentComponent,
    ],
    providers: [DocumentService],
})

export class VibeDocumentModule {
}
