export var multi = [
  {
    "name": "2014",
    "series": [
      {
        "name": "Customer",
        "value": 730
      },
      {
        "name": "Lead",
        "value": 894
      }
    ]
  },
  {
    "name": "2015",
    "series": [
      {
        "name": "Customer",
        "value": 787
      },
      {
        "name": "Lead",
        "value": 827
      },
      {
        "name": "Lead2233",
        "value": 900
      }
    ]
  },
  {
    "name": "2016",
    "series": [
      {
        "name": "Customer",
        "value": 500
      },
      {
        "name": "Lead",
        "value": 580
      }
    ]
  },
  {
    "name": "2017",
    "series": [
      {
        "name": "Customer",
        "value": 730
      },
      {
        "name": "Lead",
        "value": 894
      }
    ]
  },
  {
    "name": "2018",
    "series": [
      {
        "name": "Customer",
        "value": 787
      },
      {
        "name": "Lead",
        "value": 827
      },
      {
        "name": "Lead2233",
        "value": 900
      }
    ]
  },
  {
    "name": "2019",
    "series": [
      {
        "name": "Customer",
        "value": 500
      },
      {
        "name": "Lead",
        "value": 580
      }
    ]
  },
  {
    "name": "2020",
    "series": [
      {
        "name": "Customer",
        "value": 730
      },
      {
        "name": "Lead",
        "value": 894
      }
    ]
  },
  {
    "name": "2021",
    "series": [
      {
        "name": "Customer",
        "value": 787
      },
      {
        "name": "Lead",
        "value": 827
      },
      {
        "name": "Lead2233",
        "value": 900
      }
    ]
  },
  {
    "name": "2022",
    "series": [
      {
        "name": "Customer",
        "value": 500
      },
      {
        "name": "Lead",
        "value": 580
      }
    ]
  }
];
  