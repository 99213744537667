export const locale = {
    lang: 'ds',
    data: {
        'HEADER': {
            'WALLS': 'Vægge',
            'PAINTCELING': 'Loft',
            'FLOOR': 'Gulv',
            'WallAndCeiling': {
                'wall&ceiling': 'Du har ikke valgt maling af vægge til @wallRoom - maling af lofter til @ceilingRoom. Vil du gemme ændringer ?',
                'wall': 'Du har ikke valgt maling af vægge til @wallRoom ?',
                'ceiling': 'Du har ikke valgt maling af lofterog til @ceilingRoom ?',
                'floor': 'Vil du male væg og loft uden gulvmaling ?',

            }
        },
        'BUTTONS': {
            'AddExtra': 'Tilføj',
            'RemoveExtra': 'Fjern',
            'PDF1': 'Tilbud',
            'PDF2': 'Tilbud med rum',
            'SelectMaterial': 'Alle',
            'ADDFLOOR': 'Gulv',
            'GotoMaterial': 'Gå til materialer',
            'Diverse': 'Diverse',
        },
        'FIELDS': {
            'Search': 'Søg',
            'Width': 'Bredde',
            'Length': 'Længde',
            'Total': 'M2 i alt',
            'Qty': 'Tillæg / Fradrag Kr.',
            'Quantity': 'Antal',
            'QtyM2': 'Tillæg / Fradrag m2',
            //'Qty': 'Antal M2',
            'Price': 'Pris',
            'PriceTotal': 'Pris i alt',
            'MaterialPrice': 'Pris pr.  m2',
            'GrandTotal': 'Pris i alt',
            'Rounding': 'Afrunding',
            'SupplementDeduction': 'Tillæg Kr.',
            'Text': 'Tekst',
            'Pcs': 'Stk',
            'Door': 'Dore',
            'PaintCeling': 'Maling Loft',
            'CelingHeight': 'Loftshøjde',
            'MaterialType': 'Materiale Type',
            'RoomMaterial': 'Rum Material',
            'CeilingMaterial': 'Loft Material',
            'CeilingPriceGrandTotal': 'Loft Pris',
            'WallPriceGrandTotal': 'Væg Pris',
            'Time': 'Antal gange',
            'PaintWall': 'Maling vægge',
            'RoomDetails': 'Rum',
            'Not-Found-MATERIALTYPE': 'Materiale Type',
            'Change': 'Lave om',
            'Name': 'Navn',
            'DiscountedAmount': 'Rabat beløb',
            'PaintTime': 'gang',
            'CeilingArea': 'M2 i alt',
            'CeilingPrice': 'Pris pr. m2',
            'CeilingPriceTotal': 'Loftpris i alt',
            'WallTotalPrice': 'væg total pris',
            'NotCalculatePrice': 'Ikke beregne pris',
            'M2Wall': 'M2 Vægge',
            'M2Ceiling': 'M2 Loft',
            'ColorCode': 'Farvekode',
            'PaintFloor': 'Maling Gulv',
            'FloorPriceTotal': 'Gulvpris i alt',
            'FloorArea': 'M2 i alt',
            'FloorPrice': 'Pris pr. m2',
            'M2Floor': 'Gulv M2',
            'FloorPriceGrandTotal': 'Gulv Pris',
            'Unit': 'Enhed',
            'TotalPrice': 'I alt',
            'Room': 'Rum',
            'Performance': 'Ydelse',
            'PriceExclVAT': 'Pris excl. moms',
            'PriceInclVAT': 'Pris incl. moms',
            'TotalInclVAT': 'I alt incl. moms',
            'Vat': 'Moms',
            'Material': 'Materiale',
            'Panels': 'Paneler',
            'Doorframe': 'Dørkarm',
            'Radiator': 'Radiator',
            'Dore': 'Døre',
            'TotalM2Wall': 'I alt m2 vægge',
            'TotalM2Ceiling': 'I alt m2 loft',
            'TotalM2Floor': 'I alt gulv m2',
            'FloorMaterial': 'Gulv Material',
            'IsIncludePriceOnPDF':'Inkluderet Pris På PDF',
            'Timer-Min' : ' Timer / Min'
        },
        'MESSAGES': {
            'Failed': 'Operation mislykket, prøv senere',
            'Success': 'Succes.',
            'NORECORD': 'No rum fundet'
        },
        'SelectedOfferCategory': {
            'Value1': 'Indendørs',
            'Value2': 'Udendørs',
            'Value3': 'Vindue',
            'Value4': 'Døre',
            'Value5': 'Sprøjtekabine',
            'Value6': 'Sprærende',
            'Value7': 'Filt',
            'Value8': 'Væv',
            'Value9': 'Rutex',
            'Value10': 'Tapet',
            'Value11': 'Paneler',
            'Value12': 'Dørkarme',
            'Value13': 'Garanti',
            'Value14': 'Lift',
            'Value15': 'Spartling',
            'Value16': 'Radiatore',
            'Value17': 'Varsling',
            'Value18': 'Facader',
            'Value19': 'Skur vg.',
            'Value20': 'Afdækning',
            'Value21': 'Lofter',
            'Value22': 'Vægge',
            'Value23': 'Gulv',
        },
        'SelectedCategoryTime': {
            'Value1': '1 gang',
            'Value2': '2 gange',
            'Value3': '3 gange',
            'Value4': '4 gange',
            'Value5': '5 gange',
            'Value11': 'Stk',
            'Value12': 'Meter',
            'Value13': 'Rulle',
            'Value14': 'Liter',
        },
        'VALIDATIONS': {
            'WallPrice': 'Bør ikke være null.',
            'CeilingHeight': 'Bør ikke være null.',
            'WallWidth': 'Bør ikke være null.',
            'WallLength': 'Bør ikke være null.',
            'wallExtraQty1': 'Bør ikke være null.',
            'WallExtraQty2': 'Bør ikke være null.',
            'WallExtraPrice1': 'Bør ikke være null.',
            'WallExtraPrice2': 'Bør ikke være null.',
            'WallTotalArea': 'Bør ikke være null.',
            'WallTotalPrice': 'Bør ikke være null.',
            'CeilingPriceTotal': 'Bør ikke være null.',
            'TotalPrice': 'Bør ikke være null.',
            //Ceiling Fields
            'CeilingWidth': 'Bør ikke være null.',
            'CeilingLength': 'Bør ikke være null.',
            'CeilingPrice': 'Bør ikke være null.',
            'CeilingExtraQty1': 'Bør ikke være null.',
            'CeilingExtraQty2': 'Bør ikke være null.',
            'CeilingExtraPrice1': 'Bør ikke være null.',
            'CeilingExtraPrice2': 'Bør ikke være null.',
            'CeilingArea': 'Bør ikke være null.',
            'CeilingTotalPrice': 'Bør ikke være null.',
            'DiscountedAmount': 'Bør ikke være null.',
        },
        'ToolTip': {
            'Start': 'Fra',
            'End': 'Til',
            'Price': 'Pris',
            'Time': 'Tid'
        },
        'FILTERS': {
            'Select': 'Vælg',
            'All': 'Alle'
        },
        'unitList': {
            'value1': 'stk.',
            'value2': 'Liter',
            'value3': 'Meter',
            'value4': 'Rulle',
            'value5': 'M2',
            'value6': 'KG'
        },
    }
};
