import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FuseTranslationLoaderService } from '../../../core/services/translation-loader.service';
import { locale as english } from '../coWorker/translate/en';
import { locale as danish } from '../coWorker/translate/ds';
import { CoWorkersService } from '../coWorker/coWorkers.service';
import { ChangeEmail } from '../../model/ChangeEmail';
import { ResponseBase } from '../../model/ResponseBase';
import { debounce } from 'rxjs/operators/debounce';
import { IsEmailExistValidationForCoWorker } from '../../../core/Validators/validation';
@Component({
  selector: 'app-change-email',
  templateUrl: './change-email.component.html',
  styles: []
})
export class ChangeEmailComponent implements OnInit {
  ChangeEmailForm: FormGroup;
  ChangeEmail: ChangeEmail;
  constructor(private formBuilder: FormBuilder, private translationLoader: FuseTranslationLoaderService, private coWorkersService: CoWorkersService,
    public dialogRef: MatDialogRef<ChangeEmailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string) {
    this.translationLoader.loadTranslations(english, danish);
  }

  ngOnInit() {
    this.ChangeEmail = new ChangeEmail();
    this.ChangeEmailForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]), [IsEmailExistValidationForCoWorker(this.coWorkersService)]]
    });
  }

  EmailChange($event) {
    event.preventDefault();
    this.ChangeEmail.id = this.data;
    this.coWorkersService.ChangeEmail<ResponseBase>(this.ChangeEmail).subscribe({
      next: () => {
        this.dialogRef.close(this.ChangeEmail.email);
      },
      error: err => { },
      complete: () => { }
    });
  }

  onCancel() {
    this.dialogRef.close();
  }

}
