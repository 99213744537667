import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as english } from '../../new-project/translate/en';
import { locale as danish } from '../../new-project/translate/ds';
import { NotificationsService } from 'angular2-notifications';
import { projectRequisition, Project, projectCoWorker } from 'app/main/model/Project';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FormService } from 'app/core/services/form.service';
import { DatePipe } from '@angular/common';
import { AuthService } from 'app/core/services/auth.service';
import { yesOrNoOptions } from 'app/main/model/ProjectStatus';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { CoWorkersService } from 'app/main/pages/coWorker/coWorkers.service';
import { RequestCoWorker } from 'app/main/model/RequestBase';

@Component({
  selector: 'vibe-RequisitionSpecialInfo',
  templateUrl: './requisitionSpecialInfo.component.html',
  styleUrls: ['./requisitionSpecialInfo.component.scss']
})

export class RequisitionSpecialInfoComponent implements OnInit {
  showSpinner: boolean = false;
  @Input() RequisitionDetail: any;
  @Input() projectNumber: any;
  @Input() isDisplaySpecialInfoRed: boolean;
  @Output() updateRequisition = new EventEmitter<any>();
  // IsAdmin: boolean = false;
  // roleName: string;
  Requisition: projectRequisition;
  RequisitionFormTab: FormGroup;
  ObjProject = new Project();
  yesOrNoOptions = yesOrNoOptions;
  displaySpecialInfoRed: boolean = false;
  SecurityOfficers: any = [];
  ProjectAllRequisitionCoworkers: any = [];
  coWokers: any = [];
  projectCoWorkerList: projectCoWorker[] = [];
  showMessageForCoworker: boolean = false;
  SecurityOfficer: string = "";
  EmployeeResponsible: string = "";
  roleClaims:any;
  hasPermision: boolean = false;

  constructor(private translate: TranslateService,
    private translationLoader: FuseTranslationLoaderService,
    private authService: AuthService,
    private coWorkersService: CoWorkersService
  ) {
    this.translationLoader.loadTranslations(english, danish);

  }

  ngOnInit() {
    this.authService.hasClaim(this.roleClaims.Project_Modify).subscribe(hasClaim => {
      if (hasClaim) 
          this.hasPermision = hasClaim
       else 
          this.hasPermision = false;
  });
    this.Requisition = new projectRequisition;
    //this.roleName = this.authService.getUserRole();
    let coWorker = new RequestCoWorker();
    coWorker.IsDeleted = false;
    this.getCoWorkers(coWorker);
    this.ObjProject.sagNumber = this.projectNumber;
    // if (this.roleName == "Admin") {
    //   this.IsAdmin = true;
    // }
    if (this.RequisitionDetail) {
      this.Requisition = this.RequisitionDetail;
     
      this.displaySpecialInfoRed = this.isDisplaySpecialInfoRed;
    }
  }

  onChangeLeftValue() {
    this.Requisition.leftDays = this.Requisition.left ? 1 : 0;
  }

  onChangeStigemandValue() {
    this.Requisition.risemanDays = this.Requisition.riseman ? 1 : 0;
  }

  getCoWorkers(model) {
    model.Limit = 400;
    model.OrderBy = "Name";
    this.coWorkersService.Get<ResponseBase>(model)
      .subscribe((response: ResponseBase) => {
        if (response) {
          this.getNextRequestForCoWorker(response.pagination, model, response.result);
          this.showMessageForCoworker = this.projectCoWorkerList.length > 0 ? false : true;
        }
        else {
          this.coWokers = [];
        }
      },
        error => (err) => {
          this.coWokers = [];
        },
        () => {
        });
  }

  getNextRequestForCoWorker(pagination, model, response) {
    if (pagination.index == 1) {
      this.coWokers = response;
      this.ProjectAllRequisitionCoworkers = response;
      this.SecurityOfficers = response;
    }
    else {
      this.coWokers.push(...response);
      this.ProjectAllRequisitionCoworkers.push(...response);
      this.SecurityOfficers.push(...response);
    }
    model.Index = pagination.index + 1;
    model.Limit = pagination.limit;
    model.TotalRows = pagination.totalCount;
    if (pagination.totalCount > pagination.index * pagination.limit)
      this.getCoWorkers(model);
  }

  DropDownSearchCoWorker(value) {
    if (value != null && value != "") {
      var request = new RequestCoWorker();
      request.Name = value;
      this.getCoWorkers(request);
    }
  }

  saveRequisition() {
    this.updateRequisition.emit(this.Requisition);
  }
    onFocusIn(event) {
        event.model.show();
    }
}