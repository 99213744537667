export const locale = {
  lang: 'en',
  data: {
    'PROJECT': {
      'TITLE': 'Project',
      'DESC': 'Hello Project!'
    },
    'BUTTONS': {
      'NEWCUSTOMER': 'New Customer',
      'NEWINQUIRY':'New Inquiry',
      'BACK': 'Back'
    },
    'TITLE': {
      'MaterialPagination': 'Antal materialer pr. side',
    },
    'FILTERS': {
      'Filter': 'Filter',
      'TEXTSEARCH': 'Search By Name',
      'NUMBERSEARCH': 'Number Search',
      'CustomerNumber': 'Customer Number',
      'CustomerName': 'Customer Name',
      'PhoneNumber': 'Phone Number',
      'Email': 'Email',
      'Address': 'Address',
      'City': 'City',
      'ZipCode': 'ZipCode',
      'Position': 'Position',
      'Text': 'Text',
      'Action': 'Action',
      'SORTING': 'Sort By',
      'Active': 'Include all Inactive',
      'BADGE': 'Inactive',
      'Deleted': 'Show Deleted',
    },
    'MESSAGES': {
      'NORECORD': 'No Customer Found',
      'SENDEMAILSUCCESS': 'Email sent successfully',
    },
    'GridFeildsValue': {
      'value1': 'Nummer',
      'value2': 'Name',
      'value3': 'Price',
      'value4': 'Purchase Price',
      'value5': 'Personal Price',
      'value6': 'Stock',
      'value7': 'Min Stock',
      'value8': 'Max Stock',
      'value9': 'Unit',
    },
    'SORTBY':
      [
        {
          Text: 'Name (A-Z)',
          Value: 'Text'
        },
        {
          Text: 'Name (Z-A)',
          Value: 'Text DESC'
        },
        {
          Text: 'Number (0-9)',
          Value: 'Number'
        },
        {
          Text: 'Number (9-0)',
          Value: 'Number DESC'
        }
      ]
  }
};
