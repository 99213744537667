export const locale = {
    lang: 'en',
    data: {
        'Headers': {
            "Title": "Price discount",
            "DeleteWeather": "Are you sue you want to delete this weather ?"
        },
        "FIELDS": {
            'SRNO': 'Sr nr.',
            "StartPrice": "Start price",
            "EndPrice": "End price",
            "Percentage":"Percentage",
            "Edit": 'Edit',
            "Delete": 'Delete',
            'IndoorOutDoor': 'Indoor,Outdoor,Spray Painting,Reclamation*',
            "StartDay": "StartDay DD/MM",
            "EndDay": "EndDay DD/MM",
            "Status": "Status",
            "TotalDays": "Total Days",
            "TotalWorkingDays": "Total Working Days",
            "CurrentPrice": "Current Price",
        },
        "BUTTONS": {
            "Add": "Add",
            'Back': 'Back'
        },
        "MESSAGES": {
            'Added': 'Added Successfully',
            'Updated': 'Updated Successfully.',
            'Failed': 'Failed',
            'Deleted': 'Deleted successfully',
            "NORECORD": "No Record Found"
        },
        "VALIDATIONS": {
            'StartPrice': 'Start price is required.',
            'EndPrice': 'End price is required.',
            'Percentage': 'Enter percentage',
            'ValidPercentage': 'Enter valid percentage',
            'IndoorOutDoor':'Select any Status',
            "StartDay": "Startday is required",
            "EndDay": "Endday is required",
            "ValidStartDay": "Enter valid startday",
            "ValidEndDay": "Enter valid endday",
        },
        'selectedValueIndoorOutdoorStatus': {
            'value1': 'Indoor ',//Man in a room
            'value2': 'Outdoor', //Sun
            'value3': 'Spray Painting',//spraypaint
            'value4': 'Complaint', //Reklamation
            'value5': 'Staff purchase', //drawing of painter
            'value6': 'Store purchase' //Nordsjø logo
        },
    }
}