import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextEditorEmailSendComponent } from './text-editor-email-send.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { QuillEditorModule } from 'ngx-quill-editor';
import { MaterialModule } from './../../../../@fuse/material.module';
//import { SharedModule } from '../../../core/modules/shared.module';
import { CKEditorModule } from 'ng2-ckeditor';
import { TextEditorEmailSendService } from './text-editor-email-send.service';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatBadgeModule } from '@angular/material/badge';
import { FuseDirectivesModule } from '@fuse/directives/directives';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        TranslateModule,
        QuillEditorModule,
        MaterialModule,
        //SharedModule,
        CKEditorModule,
        NgxMatSelectSearchModule,
        MatBadgeModule,
        FuseDirectivesModule
    ],
    providers:[
        TextEditorEmailSendService
    ],
    entryComponents: [
        TextEditorEmailSendComponent,
    ],
    declarations: [TextEditorEmailSendComponent],
    exports: [
        //TextEditorEmailSendComponent,
       QuillEditorModule
    ]

})
export class TextEditorEmailSendModule { }
