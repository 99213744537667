export const locale = {
    lang: 'en',
    data: {
        'MESSAGES': {
            'Created': 'Created successfully',
            'Updated': 'Updated successfully',
            'Deleted': 'Deleted successfully',
            'Failed': 'Operation failed , try later',
        },
        'BUTTONS': {
            'CREATE_FOLDER': 'Create Folder',
            'BACK': 'Back',
            'SAVE': 'Save',
            'DELETE': 'Delete',
        },
        'FIELDS': {
            
        },
        'Headers': {
            'NoFileSelected': 'No File Selected',
            'FOLDEREMPTY': 'This folder is empty',
        },
        'VALIDATIONS': {
            'TextRequired': 'Text required',
            'ColorCodeRequired': 'Color code required',
            'PersonalPriceRequired': 'Personal price required',
            'RetailPriceRequired': 'Retail price required',
            'PurchaseQuantityRequired': 'Purchase quantity required',
            'QuantityRequired': 'Quantity required',
        },
    }
};