export const locale = {
    lang: 'en',
    data: {
        'FIELDS': {
            'Name': 'Name',
            'Performance': 'Performance',
            'MaterialType': 'Material Type',
            'Search': 'Search',
            'Not-Found-MATERIALTYPE': 'Material Type',
            'ShowRoomDetails': 'Show room details',
            'MultipleOptions': 'Multiple Options',
            'IsLastNode': 'Last point',
            'IndoreOffer': 'In door Offer',
            'OutdoreOffer': 'Out door offer',
            'Price': 'Price',
            'QTY': 'QTY',
            'Reminder': 'Reminder',
            'Width': 'Width',
            'Length': 'Length',
            'Total': 'Total',
            'Time': 'Number of times',
            'Text': 'Text',
            'ColorCode': 'Color Code',
            'TreatmentType':'Treatment Type',
            'CeilingHeight': 'Ceiling Height',
            'ShowAndet': 'Show Other',
            'ExtraM2QtyWall': 'Supplement / Deduction m2 Wall',
            'ExtraM2QtyCeiling': 'Supplement / Deduction m2 Ceiling',
            'ExtraM2QtyFloor': 'Supplement / Deduction LB Floor',
            'ExtraM2PriceWall': 'Supplement / Deduction Kr. Wall',
            'ExtraM2PriceCeiling': 'Supplement / Deduction Kr. Ceiling',
            'ExtraM2PriceFloor': 'Supplement / Deduction Kr. Woodwork'
        },
        'Tabs': {
            'Offer': 'Offer',
            'BasicDetail': 'Basic Detail',
            'File': 'File',
            'Category': 'Category',
            'WorkItem': 'Work Item'
        },
        'MESSAGE': {
            'Failed': 'Operation failed , try later',
            'Success': 'Success.',
            'CommingSoon': 'Comming Soon..',
            'RoomSelectionMsg': 'Insert @room length and width on offer',
        },
        'BUTTONS': {
            'AddItem': 'Add Performance',
        },
        'FILTERS': {
            'Select': 'Select',
            'All': 'All'
        },
        'TOOTIPORTITLE':{
            'GotoMaterial':'Go to material'
          }
    }
};
