export const locale = {
    lang: 'en',
    data: {
      'STATUS': {
        'Created': 'Created',
        'Updated': 'Updated',
        'Deleted': 'Deleted',
        'FileUploaded': 'Files Uploaded',
        'FileDeleted': 'Files Deleted',
      },
      'TITLE': {
        'CustomerPagination': 'Antal log pr. side',
      },
      'BUTTONS': {
        'BACK': 'Cancel',
        'BTNSAVE': 'Save',
      },
      'FIELDS': {
        'SRNO': 'Sr No',
        'Status': 'Status',
        'Number': 'Number',
        'Name': 'Name',
        'Createdby': 'Created By',
        'CreatedDate': 'Created Date',
        'ModifyBy': 'Modify By',
        'ModifyDate': 'Modify Date',
        'CoworkerName': 'Coworker Name',
        'Date': 'Date',
        'Description': 'Description',
        'CustomerNo': 'Customer No.',
        'CustomerName': 'Customer Name',
        'Email': 'E-mail',
        'PhoneNo': 'Phone No.',
        'Address-line-1': 'Address(Line 1)',
        'Address-line-2': 'Address(Line 2)',
        'City': 'City',
        'Zipcode': 'Zipcode',
        'Position': 'Position',
        'Comments': 'Comments  [ Optional ]',
        'OrderName': 'Order name',
        'EnterLocation': 'Enter your location',
        'Active': 'Set as inactive',
        'From': 'From',
        'To': 'to',
        'colorCode': 'Color code',
        'PersonnelPurchas': 'Staff Purchase',
        'EmailInvoice': 'Invoice Email',
        'EANNumber': 'EAN Number',
        'Address': 'Address',
        'Detail': 'Detail',
        'ChangedDetail': 'Changed Detail',

        'MaterialNo': 'Number',
        'Quantity': 'Quantity',
        'MaterialText': 'Material Text',
        'TotalPrice': 'Total Price',
        'PersonalPrice': 'Personal Price',
        'PersonalTotalPrice': 'Personal Total',
        'Discount': 'Discount %',
        'RetailPriceDiscount': 'Retail Price Discount',
        'PersonalPriceDiscount':'Personal Price Discount',
        'TotalPriceVAT':'Total Price VAT'

      },
      'Headers': {
        'CustomerLogDetails':'Requisition Materials Log Details'
      },
     
      'MESSAGES': {
        'NORECORD': 'No Record Found',
      },
      'selectedValueIndoorOutdoorStatus': {
        'value1': 'Indoor ',//Man in a room
        'value2': 'Outdoor', //Sun
        'value3': 'Spray Painting',//spraypaint
        'value4': 'Complaint', //Reklamation
        'value5': 'Staff purchase', //drawing of painter
        'value6': 'Store purchase' //Nordsjø logo
      },
    }
  };
  
  
  