import { Component, Input, OnInit } from '@angular/core';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { ProjectService } from 'app/main/pages/project/project.service';
import { RequestProjectLocation } from 'app/main/model/RequestBase';
import { CompanyInfoService } from 'app/main/pages/companyInfo/companyInfo.service';
import { GoogleMapDirectionService } from 'app/main/pages/google-map-direction/google-map-direction.service';
import { AgmInfoWindow } from '@agm/core';
import { ViewProjectMapInformationComponent } from '../view-project-map-information/view-project-map-information.component';
import { MatDialog } from '@angular/material';
@Component({
  selector: 'app-project-map-location',
  templateUrl: './project-map-location.component.html',
  styleUrls: ['./project-map-location.component.scss']
})
export class ProjectMapLocationComponent implements OnInit {
  @Input() ProjectID: string;
  @Input() RequisitionList: any;

  showSpinner: boolean = false;
  previous: AgmInfoWindow;
  requestModel: RequestProjectLocation = new RequestProjectLocation();
  latitude: number;
  longitude: number;
  selectProjectLocation: any = [];
  filter: any = 0;
  show: boolean = false;
  destination: any;
  origin: any;
  zoom: number = 10;
  icon = {
    labelOrigin: { x: 16, y: 48 },
    url: "assets/images/etc/gps.png",
    scaledSize: {
      width: 52,
      height: 52
    },
  };

  constructor(
    private translationLoader: FuseTranslationLoaderService,
    private projectService: ProjectService,
    public companyInfoService: CompanyInfoService,
    public googleMapDirectionService: GoogleMapDirectionService,
    public dialog: MatDialog,

  ) {
    this.translationLoader.loadTranslations(english, danish);
  }

  ngOnInit() {
    this.requestModel.Index = 1;
    this.requestModel.Limit = 200;
    this.requestModel.ProjectID = this.ProjectID;
    this.requestModel.OrderBy = 'Date DESC';
    this.requestModel.search = "";
    this.GetProjectTaskLocation();
  }

  GetProjectTaskLocation() {
    this.showSpinner = true;
    this.projectService.GetProjectTaskLocation<ResponseBase>(this.requestModel)
      .subscribe({
        next: (response: ResponseBase) => {
          this.showSpinner = false;
          if (response && response.result && response.result.length > 0) {
            const firstLocation = response.result[0];
            this.latitude = firstLocation.latitude;
            this.longitude = firstLocation.longitude;
            this.selectProjectLocation = response.result;
          }
        },
        error: err => {
          this.showSpinner = false;
        },
        complete: () => {
        }
      })
  }

  toggleAllStatusSelection() {
    this.show = false;
    this.zoom = 10;
  }

  toggleStatusSelection(status) {
    this.show = true;
    this.destination = { lat: status.latitude, lng: status.longitude };
    this.getCurrentLocation();
  }

  getCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.origin = { lat: position.coords.latitude, lng: position.coords.longitude };
        this.zoom = 15;
      });
    }
  }

  clickedMarker(infoWindow: AgmInfoWindow) {
    if (this.previous) {
      this.previous.close();
    }
    this.previous = infoWindow;
  }

  viewProjectInfo() {
    const dialogRef = this.dialog.open(ViewProjectMapInformationComponent, {
      width: '80%',
      height: '80%',
      disableClose: true,
      data: { projectMapLocation: this.selectProjectLocation }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.GetProjectTaskLocation();
        this.zoom = 10;
        this.filter = 0;
      }
    }
    );
  }
}
