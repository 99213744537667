export const locale = {
    lang: 'en',
    data: {
        'FIELDS': {
            'Name': 'Name',
            'Questions': 'Question',
            'SrNo': 'Sr No.',
            'Status': 'Status',
            'ProjectStatus':'Project status'
        },
        'MESSAGES': {
            'Success': 'SMS is sent successfully',
            'Failed': 'Failed',
          'NORECORD': 'No Question Found',
            'SAVESUCCESSFULLY':'Save Successfully.'
        },
        'BUTTONS': {
            'Save': 'Save',
            'Back': 'Cancel'
        },
        'VALIDATIONS': {
            'Mobile': 'Mobile number is required.',
            'Text': 'Text is required.'
        },
    }
};
