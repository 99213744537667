import { Component, OnInit, ViewChild, EventEmitter, ElementRef, Input, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators, } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { TitleService } from 'app/core/services/title.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { ProjectService } from 'app/main/pages/project/project.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { FormService } from 'app/core/services/form.service';
import { CommonService } from 'app/core/services/common.service';
import { SettingsService } from 'app/main/pages/settings/settings.service';
import { CoWorker } from 'app/main/model/CoWorker';
import { Settings } from 'app/main/model/Settings';
import { CkeditorConfig } from 'app/constant/common.constant';
import { ReminderRepeatModes, ReminderWeekDays, ReminderRepeatModeOfMonth, WeekOftheMonth } from 'app/main/model/Reminder';
import * as moment from 'moment';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { environment } from 'environments/environment';

@Component({
    selector: 'vibe-notification-settings',
    templateUrl: './notification-settings.component.html',
    styleUrls: ['./notification-settings.component.scss']
})
export class NotificationSettingsComponent implements OnInit {
    showSpinner: boolean = false;
    @Input() parentForm: FormGroup;
    @Input() audienceType: any;
    @Input() status: any;
    @Input() indoorOutdoorStatus: any;
    @Input() id: any;
    @Input() currentselectedcoWorkeridStr: string;
    @Output("saveAndUpdateNotification") saveAndUpdateNotification: EventEmitter<any> = new EventEmitter();
    notificationForm: FormGroup;
    notificationDetail: any = {};
    isSubmitted = false;
    coWokers: CoWorker[] = [];
    IsGettingCoWokers: boolean = false;
    PlanningNotificationsObj: Settings;
    settingMasterValues: any[] = [];
    reminderRepeatModes = ReminderRepeatModes;
    reminderRepeatModeOfMonth = ReminderRepeatModeOfMonth
    weekDays = ReminderWeekDays;
    weekOfMonth = WeekOftheMonth;
    files: any[] = [];
    @ViewChild("fielInput", { static: false })
    InputVar: ElementRef;
    config = CkeditorConfig;
    filesPath: any[] = [];
    host: string;
    currentselectedcoWorkeridObj: any[] = [];
    smsText:any = "@CustomerName. @ProjectAmount @ProjectAmountPersonale @ProjectNumber";
    notificationText:any = "@CustomerName. @ProjectAmount @ProjectAmountPersonale @ProjectNumber";
    emailText:any = "";
    //weekDaysControl = new FormControl([]);
    //Initiators
    constructor(private translationLoader: FuseTranslationLoaderService,
        private _router: Router,
        private fb: FormBuilder,
        private titleService: TitleService,
        private projectService: ProjectService,
        private translate: TranslateService,
        private notificationsService: NotificationsService,
        private settingsService: SettingsService,
        private datePipe: DatePipe,
        private formBuilder: FormBuilder,
        private formService: FormService,
        public dialog: MatDialog,
        public commonService: CommonService,) {
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
        //this.currentselectedcoWorkeridObj = this.currentselectedcoWorkeridStr.split(',');
        this.host = environment.url;
        this.config.height = 350;
        this.settingMasterValues = Object.assign([], JSON.parse(localStorage.getItem("settingMasterValues")));
        //this.notificationDetail.mode = "week";
        this.notificationForm = this.fb.group({
            // CoworkersID: [null, Validators.required],
            id: [null],
            text: [null, Validators.required],
            isSendSMS: [false],
            isSendEmail: [false],
            isSendNotification: [false],
            smsText: [this.smsText],
            notificationText: [this.notificationText],
            emailTemplateHTML: [null],
            emailTemplatePath: [null],
            filePath: [null],
            mode: [1],
            startDate: [new Date(), Validators.required],
            endDate: [null, Validators.required],
            executeTime: [null, Validators.required],
            modeOfMonth: [null],
            executeDayOnMonth: [null],
            executeWeekOnMonth: [null],
            isExecuteMonday: [false],
            isExecuteTuesday: [false],
            isExecuteWednesday: [false],
            isExecuteThursday: [false],
            isExecuteFriday: [false],
            isExecuteSaturday: [false],
            isExecuteSunday: [false],
            sendingCoworkerID: [null],
            subject: [null],
        });

        this.notificationForm.get('isSendSMS').valueChanges
            .subscribe(value => {
                if (value) {
                    this.notificationForm.controls['smsText'].setValidators(Validators.required)
                } else {
                    this.notificationForm.controls['smsText'].setValidators(null)
                }
                this.notificationForm.controls['smsText'].updateValueAndValidity()
            });
        this.notificationForm.get('isSendNotification').valueChanges
            .subscribe(value => {
                if (value) {
                    this.notificationForm.controls['notificationText'].setValidators(Validators.required)
                } else {
                    this.notificationForm.controls['notificationText'].setValidators(null)
                }
                this.notificationForm.controls['notificationText'].updateValueAndValidity()
            });
        this.notificationForm.get('isSendEmail').valueChanges
            .subscribe(value => {
                if (value) {
                    this.notificationForm.controls['emailTemplateHTML'].setValidators(Validators.required)
                } else {
                    this.notificationForm.controls['emailTemplateHTML'].setValidators(null)
                }
                this.notificationForm.controls['emailTemplateHTML'].updateValueAndValidity()
            });
        this.notificationDetail.startDate = new Date(),//this.datePipe.transform(new Date(), "yyyy-MM-dd");
        this.notificationDetail.endDate = new Date(),//this.datePipe.transform(new Date(), "yyyy-MM-dd");
        this.notificationDetail.executeTime = this.datePipe.transform(new Date(), "HH:mm");

        this.GetCustomerById();

    }

    get f() { return this.notificationForm.controls; }

    ngOnChanges(changes: SimpleChanges) {
        if(changes && changes.audienceType && changes.audienceType.previousValue){
            if(this.f && this.f.isSendNotification)
                this.f.isSendNotification.setValue(false);
        }
    }
    

    // onCowrkerChange(e) {
    //     if(e && e.length > 0)
    //         this.notificationDetail.sendingCoworkerID = e.join(',');
    //     else
    //         this.notificationDetail.sendingCoworkerID = null;
    // }

    onSubmit() {
        this.notificationForm.markAllAsTouched();
        this.isSubmitted = true;
        if (this.notificationForm.valid && this.parentForm.valid) {
            this.saveNotificationSetting();
        }
    }

    saveNotificationSetting() {
        this.notificationDetail = this.notificationForm.value;
        this.notificationDetail.reminderType = this.audienceType;
        this.notificationDetail.status = this.status;
        this.notificationDetail.indoorOutdoorStatus = this.indoorOutdoorStatus.map(x => x.id).join();
        this.notificationDetail.files = this.files;
        if(!this.notificationForm.controls['isSendEmail'].value)
            this.notificationDetail.emailTemplateHTML=null;
        if(!this.notificationForm.controls['isSendNotification'].value)
            this.notificationDetail.notificationText=null;
        if(this.currentselectedcoWorkeridObj && this.currentselectedcoWorkeridObj.length > 0)
            this.notificationDetail.sendingCoworkerID = this.currentselectedcoWorkeridObj.join();
        this.showSpinner = true;
        if (this.notificationDetail.id) {
            this.settingsService.UpdateReminderSchedule(this.notificationDetail.id, this.notificationDetail).subscribe({
                next: (response: ResponseBase) => {
                    this.showSpinner = false;
                    this.FormReset();
                    this.translate.get("Message.Created").subscribe({
                        next: (res) => {
                            this.notificationsService.success(res);
                        },
                        error: err => {
                            this.showSpinner = false;
                        }
                    });
                    this.saveAndUpdateNotification.emit(false);
                },
                error: err => {
                    this.translate.get("Message.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                            this.showSpinner = false;
                        },
                        error: err => {
                        }
                    });
                }
            });
        }
        else {
            this.settingsService.AddReminderSchedule(this.notificationDetail).subscribe({
                next: (response: ResponseBase) => {
                    this.showSpinner = false;
                    this.FormReset();
                    this.translate.get("Message.Created").subscribe({
                        next: (res) => {
                            this.notificationsService.success(res);
                        },
                        error: err => {
                            this.showSpinner = false;
                        }
                    });
                    this.saveAndUpdateNotification.emit(false);
                },
                error: err => {
                    this.translate.get("Message.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                            this.showSpinner = false;
                        },
                        error: err => {
                        }
                    });
                }
            });

        }
    }
    /**
  * on file drop handler
  */
    onFileDropped($event) {
        this.prepareFilesList($event);
    }

    /**
     * handle file from browsing
     */
    fileBrowseHandler(files) {
        this.prepareFilesList(files);
    }

    /**
     * Delete file from files list
     * @param index (File index)
     */
    deleteFile(index: number) {
        this.files.splice(index, 1);
    }


    /**
     * Convert Files list to normal array list
     * @param files (Files List)
     */
    prepareFilesList(files: Array<any>) {
        for (const item of files) {
            const reader = new FileReader();
            reader.onload = (e: any) => {
                item.base64 = e.target.result.split(',')[1];
                item.fileName = item.name;
                item.fileType = item.type;
                console.log("item", item);
                if (!this.files)
                    this.files = [];
                this.files.push(item);
            };
            reader.readAsDataURL(item);
        }
    }

    reset() {
        this.files = [];
        if (this.InputVar && this.InputVar.nativeElement)
            this.InputVar.nativeElement.value = "";
    }

    public get days() {
        const dayCount = this.getDaysInMonth(Number(moment(this.notificationDetail.startDate).format("YYYY")), Number(moment(this.notificationDetail.startDate).format("MM")));
        return Array(dayCount).fill(0).map((i, idx) => idx + 1)
    }

    public getDaysInMonth(year: number, month: number) {
        return 32 - new Date(year, month - 1, 32).getDate();
    }

    public FormReset() {
        this.reset();
        //this.notificationDetail = null;
        this.f.mode.setValue(1);
        // this.notificationDetail.startDate = this.datePipe.transform(new Date(), "yyyy-MM-dd");
        // this.notificationDetail.endDate = this.datePipe.transform(new Date(), "yyyy-MM-dd");
        // this.notificationDetail.executeTime = this.datePipe.transform(new Date(), "HH:mm");
        this.notificationForm.reset();
        this.notificationDetail.smsText = this.smsText;
        this.notificationDetail.notificationText = this.notificationText
    }

    // onToppingRemoved(topping: string) {
    //     const toppings = this.weekDaysControl.value as string[];
    //     this.removeFirst(toppings, topping);
    //     this.weekDaysControl.setValue(toppings); // To trigger change detection
    //   }

    //   private removeFirst<T>(array: T[], toRemove: T): void {
    //     const index = array.indexOf(toRemove);
    //     if (index !== -1) {
    //       array.splice(index, 1);
    //     }
    //   }

    GetCustomerById() {
        var tempSettingObject = this.settingMasterValues.find(s => s.key1 == "Project" && s.key2 == "ReminderScheduleEmailTemplate").value;
        if (tempSettingObject)
            this.emailText = tempSettingObject;
        if (this.id) {
            this.showSpinner = true;
            this.settingsService.GetReminderScheduleById(this.id).subscribe((respose: ResponseBase) => {
                if (respose) {
                    this.notificationDetail = respose.result;
                    if(this.notificationDetail && this.notificationDetail.reminderType == 5)
                        this.notificationDetail.notificationText = null;
                    this.notificationForm.patchValue(this.notificationDetail);
                    if (this.notificationDetail.smsText)
                        this.notificationForm.controls['isSendSMS'].setValue(true);
                    if (this.notificationDetail.emailTemplateHTML)
                        this.notificationForm.controls['isSendEmail'].setValue(true);
                    if (this.notificationDetail.notificationText)
                        this.notificationForm.controls['isSendNotification'].setValue(true);
                    if (this.notificationDetail.filePath)
                        this.filesPath = this.notificationDetail.filePath.split(",");
                    if(this.notificationDetail && this.notificationDetail.sendingCoworkerID)
                        this.currentselectedcoWorkeridObj = this.notificationDetail.sendingCoworkerID.split(',');

                }
            }, error => (err) => { },
                () => {
                    this.showSpinner = false;
                });
        }
        else {
            if (tempSettingObject)
                this.notificationDetail.emailTemplateHTML = tempSettingObject;
        }
    }

    onFocusIn(event) {
        event.model.show();
    }

    public OnChangeDatePicker(args): void {
        if (args.value) {
            args.value = new Date(moment(args.value).format("YYYY-MM-DD"));
        }
    }

    onCheckNotification(field){
        if (field == "isSendSMS"){
            if(this.notificationForm.controls['isSendSMS'].value)
                this.notificationForm.controls['smsText'].setValue(this.smsText);
            else
                this.notificationForm.controls['smsText'].setValue(null);
        }
        if (field == "isSendEmail"){
            if (this.notificationForm.controls['isSendEmail'].value)
                this.notificationForm.controls['emailTemplateHTML'].setValue(this.emailText);
            else
                this.notificationForm.controls['emailTemplateHTML'].setValue(null);
        }
        if (field == "isSendNotification"){
            if(this.notificationForm.controls['isSendNotification'].value)
                this.notificationForm.controls['notificationText'].setValue(this.notificationText);
                else
                this.notificationForm.controls['notificationText'].setValue(null);
        }
    }

    onModeChange() {
        if(this.f.mode.value == 5){
            this.notificationDetail.startDate = new Date();
            this.notificationDetail.endDate = new Date();
            this.notificationDetail.executeTime = "00:00";
            this.notificationForm.controls['executeTime'].setValue("00:00");
        }
        else
            this.notificationDetail.executeTime = this.datePipe.transform(new Date(), "HH:mm");
    }
}
