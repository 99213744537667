import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { MatSlideToggleChange } from '@angular/material';
import { SettingsService } from '../../settings.service';
import { ResponseBase } from "app/main/model/ResponseBase";
import { Settings } from '../../../../model/Settings';
import { environment } from '../../../../../../environments/environment';
import { ReminderWeekDays } from 'app/main/model/Reminder';
@Component({
  selector: 'app-notificationsend-configuration',
  templateUrl: './notificationsend-configuration.component.html',
  styleUrls: ['./notificationsend-configuration.component.scss']
})
export class NotificationsendConfigurationComponent implements OnInit {

   @Input("SendNotificationSetting") SendNotificationSetting: Settings;

  showSpinner: boolean = false;
  notificationConfigForm: FormGroup;
  isSubmitted = false;
  sendNotificationConfigObj: any = {};
  SendNotificationObj: Settings;
  envNotificationSendParam = "";
  IsSendNotification: boolean = false;
  weekDays = ReminderWeekDays;

  constructor(
      private translationLoaderService: FuseTranslationLoaderService,
      private fb: FormBuilder,
      private settingsService: SettingsService) {
      this.translationLoaderService.loadTranslations(danish, english);
  }

  ngOnInit() {
      this.notificationConfigForm = this.fb.group({
          IsSendNotification: [false],
          IsTimeBased: [true],
          HoursFromSend: [null],
          HoursToSend: [null],
          isExecuteMonday: [false],
          isExecuteTuesday: [false],
          isExecuteWednesday: [false],
          isExecuteThursday: [false],
          isExecuteFriday: [false],
          isExecuteSaturday: [false],
          isExecuteSunday: [false],
      });
      this.envNotificationSendParam = environment.SendingNotificationValue2;
      this.setvalidators();
      this.setSettingsForSendNotification();
  }

  onSubmit() {
      this.notificationConfigForm.markAllAsTouched();
      this.isSubmitted = true;
      if (this.notificationConfigForm.valid) {
          this.saveNotificationConfigurationSetting()
      }
  }

  saveNotificationConfigurationSetting() {
    this.showSpinner = true;
      let data = {
          'IsSendNotification': this.notificationConfigForm.value.IsSendNotification.toString(),
          'IsTimeBased': this.notificationConfigForm.value.IsTimeBased.toString(),
          'HoursFromSend': this.notificationConfigForm.value.IsTimeBased ? this.notificationConfigForm.value.HoursFromSend : null,
          'HoursToSend': this.notificationConfigForm.value.IsTimeBased ? this.notificationConfigForm.value.HoursToSend : null,
          'isExecuteMonday': this.notificationConfigForm.value.isExecuteMonday,
          'isExecuteTuesday': this.notificationConfigForm.value.isExecuteTuesday,
          'isExecuteWednesday': this.notificationConfigForm.value.isExecuteWednesday,
          'isExecuteThursday': this.notificationConfigForm.value.isExecuteThursday,
          'isExecuteFriday': this.notificationConfigForm.value.isExecuteFriday,
          'isExecuteSaturday': this.notificationConfigForm.value.isExecuteSaturday,
          'isExecuteSunday': this.notificationConfigForm.value.isExecuteSunday,
      }
      this.sendNotificationConfigObj = JSON.stringify(data)
      this.SendNotificationObj.value = this.sendNotificationConfigObj;
      this.settingsService.UpdateSettingValue<ResponseBase>(this.SendNotificationObj).subscribe({
          next: (response: ResponseBase) => {
              this.showSpinner = false;
              if (response != null && response.result) {
                  this.sendNotificationConfigObj = JSON.parse(response.result.value);
                  this.sendNotificationConfigObj.IsSendNotification = JSON.parse(this.sendNotificationConfigObj.IsSendNotification);
                  this.sendNotificationConfigObj.IsTimeBased = JSON.parse(this.sendNotificationConfigObj.IsTimeBased);
                  this.notificationConfigForm.patchValue(this.sendNotificationConfigObj);
                  this.setValidatorsBasedOnSavedValue();

              }
          },
          error: err => {
          },
          complete: () => {
              this.showSpinner = false;
              this.isSubmitted = false;
          }
      });
  }

  changeIsSendNotification(event: MatSlideToggleChange) {
      this.saveNotificationConfigurationSetting();
  }

  changeIsTimeBased(event: MatSlideToggleChange) {
      if (event.checked) {
          this.setvalidators()
      }
      else {
          this.clearValidators();
      }
  }

  clearValidators() {
      this.notificationConfigForm.get('HoursFromSend').clearValidators();
      this.notificationConfigForm.get('HoursFromSend').updateValueAndValidity();
      this.notificationConfigForm.get('HoursToSend').clearValidators();
      this.notificationConfigForm.get('HoursToSend').updateValueAndValidity();
  }

  setvalidators() {
      this.notificationConfigForm.get('HoursFromSend').setValidators([Validators.required, Validators.min(1), Validators.max(24), this.smallerThan('HoursToSend')]);
      this.notificationConfigForm.get('HoursFromSend').updateValueAndValidity();
      this.notificationConfigForm.get('HoursToSend').setValidators([Validators.required, Validators.min(1), Validators.max(24), this.greaterThan('HoursFromSend')]);
      this.notificationConfigForm.get('HoursToSend').updateValueAndValidity();
  }

  setValidatorsBasedOnSavedValue() {
      if (this.sendNotificationConfigObj.IsTimeBased) {
          this.setvalidators();
      }
      else {
          this.clearValidators();
      }
  }

  setSettingsForSendNotification() {
      if (this.SendNotificationSetting && this.SendNotificationSetting != null) {
          this.SendNotificationObj = this.SendNotificationSetting;
          this.sendNotificationConfigObj = JSON.parse(this.SendNotificationObj.value);
          this.sendNotificationConfigObj.IsSendNotification = JSON.parse(this.sendNotificationConfigObj.IsSendNotification);
          this.sendNotificationConfigObj.IsTimeBased = JSON.parse(this.sendNotificationConfigObj.IsTimeBased);
          this.notificationConfigForm.patchValue(this.sendNotificationConfigObj);
          this.setValidatorsBasedOnSavedValue();
      }
  }

  smallerThan(otherControlName: string) {
      return (control: AbstractControl): { [key: string]: boolean } | null => {
          if (!control.parent) {
              return null; // Control is not yet associated with a parent.
          }
          var dtnow = new Date()
          var dtCurrnetControl = (dtnow.getMonth() + 1) + "/" + dtnow.getDate() + "/" + dtnow.getFullYear() + " " + control.value;
          var dtOtherControl = (dtnow.getMonth() + 1) + "/" + dtnow.getDate() + "/" + dtnow.getFullYear() + " " + control.parent.get(otherControlName).value;
          if (dtCurrnetControl < dtOtherControl) {
              return null;
          }

          return {
              'smallerthan': true
          }
      };
  }

  greaterThan(otherControlName: string) {
      return (control: AbstractControl): { [key: string]: boolean } | null => {
          if (!control.parent) {
              return null; // Control is not yet associated with a parent.
          }
          var dtnow = new Date()
          var dtCurrnetControl = (dtnow.getMonth() + 1) + "/" + dtnow.getDate() + "/" + dtnow.getFullYear() + " " + control.value;
          var dtOtherControl = (dtnow.getMonth() + 1) + "/" + dtnow.getDate() + "/" + dtnow.getFullYear() + " " + control.parent.get(otherControlName).value;

          if (dtCurrnetControl > dtOtherControl) {
              return null;
          }

          return {
              'greaterthan': true
          }
      };
  }
}

