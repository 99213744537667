export const locale = {
  lang: 'ds',
  data: {
    'Tabs': {
      'PROJECT': 'Projekter',
      'PROJECTOFFER': 'Tilbud',
    },
    'BUTTONS': {
        'NewCarInformation': 'Ny bil information',
      'BACK': 'Tilbage'
    },
    'MESSAGES': {
        'NORECORD': 'Ingen bil information fundet',
        'ParticulateFilter': 'Partikelfilter',
        'Adblue': 'Adblue',
        'SummerTires': 'Sommerdæk',
        'WinterTires': 'Vinterdæk',
        'AllSeasonTires': 'Helårsdæk',
    },
    'TITLE': {
        'CarInformationPagination': 'Antal bil information pr. side',
        'VehicleNo': 'Køretøj Nr',
    },
    'FILTERS': {
      'TEXTSEARCH': 'Søg',
      'SORTING': 'Sorter efter',
      'Active': 'Inkluder alle inaktive',
      'BADGE': 'Inaktive',
        'Deleted': 'Vis slettet'
    },
    'SORTBY':
      [
        {
          Text: 'Nummer (0-9)',
          Value: 'Number'
        },
        {
          Text: 'Nummer (9-0)',
          Value: 'Number DESC'
        },
      ]
  }
};
