export const locale = {
    lang: 'ds',
    data: {
      'BUTTONS': {
        'Upload': 'Upload',
        'Cancel': 'Tilbage',
        "Update":"Opdatere",
        "Reset":"Nulstil / annullere",
        "SampleFileDownload":"Prøve fil download",
        'Back': 'Tilbage',
      },
      'Header':{
        'Medarbejdernavn': 'Medarbejdernavn',
        'Medarbejdernummer': 'Medarbejdernummer',
        'Adresse': 'Adresse',
        'Postnr': 'Postnr',
        'By': 'By',
        'Telefon': 'Telefon',
        'Initialer': 'Initialer',
        'Email': 'Email',
        'Tilbuddetalje': 'Tilbud detalje',
        'Cpr': 'Cpr',
        'BankReg': 'Bank Reg',
        'BankKonto': 'BankKonto',
        'Startdato': 'Ansættelsesdato',
        'Adgangskode': 'Adgangskode',
        'Medarbejdere': 'Medarbejdere',
      },
      'FIELDS': {
        'SelectSupplier': 'Vælg Leverandør',
        'UploadPriceList': 'Upload prisliste',
        'ChooseFile': 'Vælg fil',
        'CoworkerAs': 'Medarbejdere*',
          'TermOfEmployement': 'Ansættelsesvilkår',
      },
      'LABELS': {
        'TITLE': 'Upload Medarbejder (.xls,.xlsx)',
        'NoFileSelected': 'Ingen fil valgt',
        'FileSelected': 'Fil valgt',
      },
      'MESSAGES': {
        'Created': 'Oprettet succesfuldt.',
        'Updated': 'Opdateret succesfuldt.',
        'Failed': 'mislykkedes',
        'Deleted': 'Slettet med succes',
        'Notes':'**Bemærk - Inaktive eller slettede materialer er fremhævet i rød farve'
      },
      'VALIDATIONS': {
        'CoworkerAs': 'Vælg kollega As',
        'TermOfEmployement' : 'Vælg Ansættelsesperiode'
      },
      'Roles': [
        { 'key': 'SuperAdmin', 'label': 'SuperAdmin' },
        { 'key': 'Admin', 'label': 'Admin' },
        { 'key': 'Kontor', 'label': 'Kontor' },
        { 'key': 'Maler', 'label': 'Maler' },
        { 'key': 'Gaest', 'label': 'Gæst' },
        { 'key': 'Maler1', 'label': 'Maler1' },
        { 'key': 'Maler2', 'label': 'Maler2' },
        { 'key': 'Maler3', 'label': 'Maler3' },
        { 'key': 'Kontor1', 'label': 'Kontor1' },
        { 'key': 'Kontor2', 'label': 'Kontor2' },
        { 'key': 'Kontor3', 'label': 'Kontor3' },
    ],
    }
  }; 
  