export const locale = {
    lang: 'ds',
    data: {
        'Tabs': {
            'Send': 'Send',
            'HolidayList': 'Helligdage',
            'Material': 'Materiale',
            'Project': 'Projekt',
            'ToolsAndLadders':'Værktøj og stiger',
            'CustomerDefaultMaterials':'Debitor standard materialer',
            'Planning':'Planlægning',
            'QuickInvoice':'Butikssalg',
            'Offer':'Tilbud',
            'Coworker':'Medarbejder',
            'Other':'Andet',
            'Payment':'Betaling',
            'Permission':'Tilladelse',
            'Document' : 'Dokumenter',
            'Credintials' : 'legitimationsoplysninger',
            'ListView': 'Listevisning',
            'Uniconta': 'Uniconta',
        },
        'Header':{
            'ProjectEconomy': 'Omsætning pr. time',
            'CalculateProjectPriceStatus': 'Iganværende arbejder',
            'ProjectMonth':'Ikke set projekt efter XXX måneder.',
            'CoworkerEconomy': 'Medarbejderpris pr. time',
        },
        'Message': {
            'SeenDefaultCoworker':'Set på planlægningsmodul udvalgte medarbejdere.'
        },
        'Buttons':{
            'Question': 'Spørgsmål',
            'Add': 'Tilføje medarbejder',
            "AddEmployeeOnNewProject": 'Tilføj medarbejder på nye projekter',
            'Questions':'Spørgsmål',
            'OfferPricePDF':'Tilbudspris PDF',
            'AllImage' : 'Alle billeder'
        },
        'TOOTIPORTITLE':{
            'WeatherType':'Vejr Typer',
            'PostNumber':'Post Nummer',
            'ColorCode':'Farve kode',
            'DownloadExcel':'Hent Excel',
            'PriceAdjustment':'Pris justering',
            'OfferTravelingAmt':'Kørsel pr. km',
        }
    }
};
