export const locale = {
    lang: 'en',
    data: {
        'MESSAGES': {
            //'Created': 'Created successfully',
            'EmailSent': 'Email sent successfully',
            'Failed': 'Operation failed , try later',
            'ItemNumberExist': 'Number is already exist',
            'ItemNotSelected': 'Select at least one item',
            'InformForYellow': 'Yellow marking means item has been ordered',
        },
        'FIELDS': {
            'Material': 'Material',
            'RetailPrice': 'Retail Price',
            'PersonalPrice': 'Personal Price',
            'PersonalTotalPrice': 'Personal Total',
            'Quantity': 'Quantity',
            'ProductNo': 'Product',
            'OrderQuantity': 'Order Antal',
            'MinQuantity': 'Min Quantity',
            'MaxQuantity': 'Max Quantity',
            'Unit': 'Unit',
            'PurchasePrice': 'Purchase price',
            'TotalPurchasePrice': 'Total Purchase Price',
            'Total': 'Total',
            'Inventory': 'Inventory',
            'MinInventory': 'Min Inventory',
            'MaxInventory': 'Max Inventory',
            'PurchaseQuantity': 'Purchase Quantity',
            'ShowAll': 'Show All',
            "WeatherType": "Weather type",
        },
        'BUTTONS': {
            'IncludeInactive': 'Include Inactive',
            'ShowActive': 'Show Active',
            'AddNewItem': 'Ex. Product',
            'Project':'Project',
            'AddNewMaterial': 'New item',
            'OpenInPDFViewer': 'Open PDF',
            'SendEmail':'Send Email',
        }
    }
};

