import { Component, OnInit, Input, Output, EventEmitter, NgZone, OnDestroy } from "@angular/core";
import { MatExpansionPanel, MatDialog } from '@angular/material';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';

import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { Reminder } from 'app/main/model/Reminder';
import { environment } from 'environments/environment';
import { id } from '@swimlane/ngx-datatable/release/utils';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { ReminderService } from '../../../pages/reminder/reminder.service';
import { RequestReminder } from 'app/main/model/RequestBase';
import { AuthService } from 'app/core/services/auth.service';
import { SignalRService } from 'app/core/services/signalR.service';
import { DatePipe } from '@angular/common';
import { ReminderReplayDialog } from '../../replay/replay.component';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { VibeImgShowcaseComponent } from "../../vibe-img-showcase/vibe-img-showcase.component";

@Component({
    selector: "received-to-me",
    templateUrl: "./received-to-me.html",
    styleUrls: ["./received-to-me.scss"]
})

export class ReceivedToMeComponent implements OnInit, OnDestroy {
    @Input() showPagination: any;
    @Input() ReminderDoneFlag: boolean = false;
    @Input() ComeFrom: any;
    //@Output() ChangedStatus = new EventEmitter<{ id: any, status: boolean }>();

    IsExpand: boolean = true;
    host: string = '';
    DefaltImage: any;
    text: any = '';
    showSpinner: boolean = false;
    pagination: any;
    MyReminders: Reminder[] = [];
    selectedStartDateForFilter: any = moment(new Date()).format('YYYY-MM-DD');
    selectedEndDateForFilter: any = moment(this.selectedStartDateForFilter, "YYYY-MM-DD").add(5, 'days').format('YYYY-MM-DD');
    selectedSearchCoworkerID: string = "";
    loggedInCoworkerID: string = "";
    orderBy: string = "DATE DESC";
    receiveByMePgIndex: any;
    receiveByMePgLimit: any;
    notDoneReminderCount: number = 0;
    DashboardReminders: Reminder[] = [];
    getAllReminder: boolean = false;
    allReminderDone: boolean = false;
    private reminderFilterObs:any;
    private UpdateReminderObs:any;

    constructor(private translateService: FuseTranslationLoaderService,
        private dialog: MatDialog,
        private authService: AuthService,
        private reminderService: ReminderService,
        private datePipe: DatePipe,
        private translate: TranslateService,
        private notificationsService: NotificationsService,
        private signalRService: SignalRService,
        private _ngZone: NgZone) {
        this.translateService.loadTranslations(danish, english);
    }

    ngOnInit() {
        this.host = environment.url;
        this.DefaltImage = 'Upload/coworker.png'
        this.selectedSearchCoworkerID = this.authService.getCoWorkerId();
        this.loggedInCoworkerID = this.authService.getCoWorkerId();
        //this.receivedToMeReminder(new RequestReminder());
        //this.getDeshboardReminder(new RequestReminder());
        this.subscribeToEvents();
    }

    //Get reminder list
    receivedToMeReminder(model: RequestReminder) {
        this.showSpinner = true;
        model.Text = model.Text ? model.Text : this.text;
        model.StartDate = model.StartDate ? model.StartDate : this.selectedStartDateForFilter;
        model.EndDate = model.EndDate ? model.EndDate : this.selectedEndDateForFilter;
        model.CoworkerId = model.CoworkerId ? model.CoworkerId : this.selectedSearchCoworkerID;
        model.IsDeleted = false;
        model.OrderBy = model.OrderBy ? model.OrderBy : this.orderBy;
        model.getAll = this.getAllReminder;
        model.Index = this.receiveByMePgIndex ? this.receiveByMePgIndex : model.Index;
        model.Limit = this.receiveByMePgLimit ? this.receiveByMePgLimit : model.Limit;
        this.reminderService.GetReminderListWithMutipleFiles<ResponseBase>(model)
            .subscribe({
                next: (response: ResponseBase) => {
                    if (response) {
                        this.MyReminders = [];
                        this.MyReminders = response.result;
                        this.pagination = response.pagination;
                        var todayRemider = response.result.filter(o => this.datePipe.transform(o.reminderDate, "yyyy-MM-dd") == this.datePipe.transform(new Date(), "yyyy-MM-dd"));
                        if (this.loggedInCoworkerID == this.selectedSearchCoworkerID) {
                            if (todayRemider) {
                                this.authService.setReminderCount(todayRemider.length);
                            }
                            else {
                                this.authService.setReminderCount(0);
                            }
                        }
                        var MyRemindersCount = 0;
                        MyRemindersCount = this.MyReminders.filter((x, i) => { return x.reminderStatus == false; }).length;
                        if (MyRemindersCount > 0) {
                            this.allReminderDone = false;
                        } else {
                            this.allReminderDone = true;
                        }
                        this.GetNotDoneReminderCounts();
                    }
                    else {
                        this.MyReminders = [];
                    }
                    this.receiveByMePgLimit = "";
                    this.receiveByMePgIndex = "";
                    this.showSpinner = false;
                },
                error: err => {
                    this.MyReminders = [];
                },
                complete: (
                ) => {
                    this.showSpinner = false;
                }
            });
    }

    PaginationEvent(event) {
        var request = new RequestReminder();
        request.Index = event.pageIndex;
        request.Limit = event.pageSize;
        request.Text = this.text.trim();
        this.pagination = request;
        this.receivedToMeReminder(request);
    }
    //End

    showAll(flag) {
        this.getAllReminder = flag ? flag : false;
        var module = this.pagination;
        this.receivedToMeReminder(module);
    }

    //Edit Reply
    editReplay(id) {
        this.receiveByMePgIndex = this.pagination.index;
        this.receiveByMePgLimit = this.pagination.limit;
        let objData = this.MyReminders.find(o => o.id == id);
        const dialogRef = this.dialog.open(ReminderReplayDialog, {
            // height: '50%',
            width: '60%',
            data: objData,
            autoFocus: false
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                var reminder = this.MyReminders.find(r => r.id == result.id);
                if (reminder) {
                    var objUpdate = new Reminder();
                    objUpdate.coWorkerId = result.coWorkerId;
                    objUpdate.reminderDate = result.reminderDate;
                    objUpdate.text = result.text;
                    objUpdate.replay = result.replay;
                    objUpdate.reminderStatus = result.reminderStatus;
                    objUpdate.replyImagePath = result.replyImagePath;
                    objUpdate.answerBy = this.loggedInCoworkerID;
                    objUpdate.sendNotification="ReceiveToMe";
                    let selectedFiles:any = result.selectedFiles;
                    this.updateReminderReply(reminder.id, objUpdate, selectedFiles);
                    reminder.text = result.text;
                }
            }
        });
    }

    updateReminderReply(id, reminder, selectedFiles:any[]) {
        this.showSpinner = true;
        //reminder.reminderDate = this.datePipe.transform(reminder.reminderDate, "yyyy-MM-dd")
        reminder.reminderDate = moment.utc(reminder.reminderDate).format("YYYY-MM-DD HH:mm");

        if(!reminder.reminderDate)
            reminder.reminderDate = "";
        if (!reminder.replyImagePath)
            reminder.replyImagePath = "";

        const formData = new FormData();
        if (selectedFiles && selectedFiles.length) {            
            for (var i = 0; i < selectedFiles.length; i++) {
                formData.append("file[]", selectedFiles[i]);
            }
        }
        for ( var key in reminder ) {
            formData.append(key, reminder[key]);
        }

        this.reminderService.UpdateReminderWithFiles<ResponseBase>(id, formData)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.showSpinner = false;
                    this.authService.UpdateSendByMeReminderList(true);
                    //this.ShowReminderForm = false;
                    this.translate.get("MESSAGES.Updated").subscribe({
                        next: (res) => {
                            this.notificationsService.success(res);
                        },
                        error: err => {
                        }
                    });
                    //this.receivedToMeReminder(new RequestReminder());
                    this.sendMessage(id);
                },
                error: err => {
                    this.showSpinner = false;
                },
                complete: () => {
                    this.showSpinner = false;
                }
            });
    }
    //End


    ChangeReminderStatus(id, flag) {
        // if (this.ComeFrom == 'dashboard') {
        //     this.ChangedStatusDeshboard(id, flag) 
        // } else {
        this.reminderService.ChangeReminderStatus<ResponseBase>(id, flag)
            .subscribe({
                next: (response: ResponseBase) => {
                    var index = this.MyReminders.findIndex(c => c.id == response.result.id);
                    this.MyReminders.splice(index, 1);
                    this.MyReminders.splice(index, 0, response.result);
                    var MyRemindersCount = this.MyReminders.filter((x, i) => { return x.reminderStatus == false; }).length;
                    if (MyRemindersCount > 0) {
                        this.allReminderDone = false;
                    } else {
                        this.allReminderDone = true;
                    }
                    this.GetNotDoneReminderCounts();
                    var requestReminder = new RequestReminder();
                    requestReminder.StartDate = new Date().toString();
                    requestReminder.EndDate = new Date(new Date().setDate(new Date().getDate() + 5)).toString();
                    this.getDeshboardReminder(requestReminder);
                    this.authService.UpdateSendByMeReminderList(true);
                    //this.sendMessage(id);
                },
                error: err => { },
                complete: () => { }
            });
        //}
    }

    GetNotDoneReminderCounts() {
        this.reminderService.GetNotDoneReminderCountApi<ResponseBase>(this.authService.getCoWorkerId())
            .subscribe({
                next: (response: ResponseBase) => {
                    if (response != null) {
                        this.notDoneReminderCount = response.result;
                        this.authService.setNotDoneReminderCount(this.notDoneReminderCount);
                    }
                },
                error: err => { },
                complete: () => { }
            });
    }

    getDeshboardReminder(model: RequestReminder) {
        model.StartDate = model.StartDate ? model.StartDate : this.selectedStartDateForFilter;
        model.EndDate = model.EndDate ? model.EndDate : this.selectedEndDateForFilter;
        model.CoworkerId = this.authService.getCoWorkerId();
        model.IsDeleted = false;
        model.Status = false;
        this.reminderService.GetReminderListWithMutipleFiles<ResponseBase>(model)
            .subscribe({
                next: (response: ResponseBase) => {
                    if (response != null) {
                        this.DashboardReminders = response.result.length;
                        this.authService.setReminderCount(response.result.length);
                    }
                    else {
                        this.DashboardReminders = [];
                        //this.reminderService.totalReminderCount(this.DashboardReminders);
                        this.authService.setReminderCount(0);
                    }
                },
                error: err => {
                    //this.Reminders = [];
                },
                complete: (
                ) => {
                }
            })
    }

    //All Reminder Done 
    allReminderListDone(allReminderDone) {
        var message = "";
        var allDoneMsg = 'Headers.AllReminderDone';
        var removeAllDoneMsg = 'Headers.NotDoneAllReminder';
        var isDoneAll = this.MyReminders.filter((x, i) => { return x.reminderStatus == false; }).length //this.MyReminders.find(o => o.reminderStatus == true);

        if (isDoneAll > 0)
            message = allDoneMsg;
        else
            message = removeAllDoneMsg;

        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: '200px',
            data: { Header: message }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.showSpinner = true;
                var tempMyReminders = this.MyReminders.map(function (a) {
                    return a.id;
                });
                this.reminderService.ChangeAllReminderStatus<ResponseBase>(tempMyReminders, this.allReminderDone)
                    .subscribe({
                        next: (response: ResponseBase) => {
                            this.showSpinner = false;
                            if (this.allReminderDone) {
                                this.getSuccessTone();
                            }
                            this.getAllReminder = false;
                            //this.AllReminderDone();
                            this.sendMessage(null);
                        },
                        error: err => {
                            this.showSpinner = false;
                        },
                        complete: (
                        ) => {
                            this.showSpinner = false;
                        }
                    });
            }
            else {
                this.showSpinner = false;
                var MyRemindersCount = 0;
                MyRemindersCount = this.MyReminders.filter((x, i) => { return x.reminderStatus == false; }).length;
                if (MyRemindersCount > 0) {
                    this.allReminderDone = false;
                } else {
                    this.allReminderDone = true;
                }
            }
        });
    }

    AllReminderDone() {
        var request = new RequestReminder();
        this.receivedToMeReminder(request);
        this.GetNotDoneReminderCounts();

        var requestReminder = new RequestReminder();
        requestReminder.StartDate = new Date().toString();
        requestReminder.EndDate = new Date(new Date().setDate(new Date().getDate() + 5)).toString();
        this.getDeshboardReminder(requestReminder);
    }

    // ChangedStatusDeshboard(id: any, value: boolean) {
    //     this.reminderService.ChangeReminderStatus<ResponseBase>(id, value)
    //         .subscribe({
    //             next: (response: ResponseBase) => {
    //                 this.MyReminders = this.MyReminders.filter(o => o.id != response.result.id);
    //                 //this.reminderService.totalReminderCount(this.Reminders);
    //                 this.authService.setReminderCount(this.MyReminders.length);
    //                 this.GetNotDoneReminderCounts();
    //                 this.getSuccessTone();
    //             },
    //             error: err => { },
    //             complete: () => { }
    //         });
    // }

    //#region Code to Prevent accordion from expantion
    expandPanel(matExpansionPanel: MatExpansionPanel, event: Event): void {
        event.stopPropagation(); // Preventing event bubbling
        if (!this.IsExpand) {
            if (!this._isExpansionIndicator(event.target)) {
                matExpansionPanel.close(); // Here's the magic
            }
        }
    }

    private _isExpansionIndicator(target: EventTarget): boolean {
        const expansionIndicatorClass = 'mat-expansion-indicator';
        return ((<HTMLElement>target).classList && (<HTMLElement>target).classList.contains(expansionIndicatorClass));
    }

    OpenCloseAccordion(flag) {
        this.IsExpand = !flag;
    }

    // CloseAccordion(flag) {
    //     this.IsExpand = !flag;
    // }

    //#endregion Code to Prevent accordion from expantion

    getSuccessTone() {
        let audio = new Audio();
        audio.src = "../../../../../../assets/soundEffect/ClappingCrowd.mp3";
        audio.load();
        audio.play();
    }

    ngAfterViewInit() {
        this.reminderFilterObs = this.authService.reminderFilterSelectChangeObs.subscribe((data) => {
           if (data) {
               this.selectedStartDateForFilter = data.StartDate;
               this.selectedEndDateForFilter = data.EndDate;
               var request = new RequestReminder();
               request.Text = data.text;
               request.CoworkerId = data.CoworkerId;
               request.StartDate = data.StartDate;
               request.EndDate = data.EndDate;
               request.OrderBy = data.orderBy;
               request.Limit = data.Limit ? data.Limit:100;
               this.receivedToMeReminder(request);
           }
        });

        //this.UpdateReminderObs = this.authService.UpdateReminderListChangeObs.subscribe((data) => {
        //    if (data) {
        //        this.receivedToMeReminder(new RequestReminder);
        //    }
        //});
    }

    sendMessage(data: any): void {
        this.signalRService.sendMessage(data);
    }
    private subscribeToEvents(): void {
      this.signalRService.messageReceived.subscribe((data: any) => {
        this._ngZone.run(() => {
          this.receivedToMeReminder(new RequestReminder());
        });
      });
    }

    showImgShowCase(imgs) {
        const dialogRef = this.dialog.open(VibeImgShowcaseComponent, {
            data: { Imgs: imgs }
        });
        dialogRef.afterClosed().subscribe(result => {
        });
    }

    ngOnDestroy() {
        if(this.reminderFilterObs) {
            this.reminderFilterObs.unsubscribe()
        }
        if(this.UpdateReminderObs) {
            this.UpdateReminderObs.unsubscribe()
        }
    }
}