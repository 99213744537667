import { Component, OnInit, Input, Inject, Optional } from '@angular/core';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { Router } from '@angular/router';
// import { RequestMaterial, RequestCoWorker } from '../../../../model/RequestBase';
// import { ResponseBase } from '../../../../model/ResponseBase';
import { AuthService } from 'app/core/services/auth.service';
import { TitleService } from 'app/core/services/title.service';
import { TranslateService } from '@ngx-translate/core';
import { DenmarkDigitFormate } from 'app/core/pipes/denmarkDigitFormate.pipe';
import { Material } from 'app/main/model/Material';
import { MatRadioChange, MAT_DIALOG_DATA } from '@angular/material';
//import { drawDOM, exportSVG, exportImage, exportPDF, DrawOptions, Group } from '@progress/kendo-drawing';
//import * as printJS from 'print-js';

@Component({
    selector: 'vibe-pricetag',
    templateUrl: './pricetag.component.html',
    styleUrls: ['./pricetag.component.scss']
})
export class PriceTagComponent implements OnInit {
    @Input("MaterialObject") materialObject: Material;
    showSpinner: boolean = false;
    //Arrays to dispaly rows in datatable
    // roleName: string;
    // isValid: boolean = false;
    public denmarkDigitFormate = new DenmarkDigitFormate();
    elementType = 'img';
    printSize = "10.3*7";
    materialType: any;
    pricetagHeaderColor: any;
    materialObjectList: [] = [];
    layoutStyle: string = 'layout-portrait';
    //Initiators
    constructor(private translationLoader: FuseTranslationLoaderService,
        private _router: Router,
        private authService: AuthService,
        private translate: TranslateService,
        private titleService: TitleService,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
        this.translationLoader.loadTranslations(english, danish);
        this.titleService.SetTitle("TITLE_MATERIAL");
    }

    ngOnInit() {
        this.materialObject = this.data;
        // this.roleName = this.authService.getUserRole();
        // if (this.roleName == "Admin") {
        //     this.isValid = true;
        // }
        // else if (this.roleName == "Kontor") {
        //     this.isValid = true;
        // }
        // else if (this.roleName == "Maler") {
        //     this.isValid = false;
        // }
        // else if (this.roleName == "Gaest") {
        //     this.isValid = false;
        // }
        // else {
        //     this.isValid = true;
        // }
        // if (this.materialObject.offerMaterial) {
        //     this.materialType = this.materialObject.offerMaterial.type;
        // }
        if (this.materialObject.barcodeColor == 2) {
            this.pricetagHeaderColor = "materialTypeOne";
        } else {
            this.pricetagHeaderColor = ""
        }

        if (this.data.materialBarcodeList && this.data.materialBarcodeList.length > 0) {
            this.materialObjectList = this.data.materialBarcodeList
        }
    }

    printPriceTag(element, isPrint) {
        //setTimeout(function () {
        //    const options = <DrawOptions>{ paperSize: "A4", landscape: false, scale: 0.7, margin: 10 };
        //    drawDOM(element, options).then((group: Group) => {
        //        return exportPDF(group);
        //    }).then((dataUri) => {
        //        if (isPrint == 'print') {
        //            printJS({
        //                printable: dataUri.replace("data:application/pdf;base64,", ""),
        //                type: 'pdf',
        //                base64: true
        //            })
        //        }
        //        else if (isPrint == 'download') {
        //            var data = dataUri.replace("data:application/pdf;base64,", "");
        //            var binary = atob(data.replace(/\s/g, ''));
        //            var len = binary.length;
        //            var buffer = new ArrayBuffer(len);
        //            var view = new Uint8Array(buffer);
        //            for (var i = 0; i < len; i++) {
        //                view[i] = binary.charCodeAt(i);
        //            }
        //            var blob = new Blob([view], { type: "application/pdf" });
        //            var url = URL.createObjectURL(blob);
        //            var link = document.createElement('a');
        //            link.innerHTML = 'Download PDF file';
        //            link.href = url;
        //            link.target = "_blank";
        //            link.click();
        //        }
        //    });
        //}, 100)
    }

    radioChange(event: MatRadioChange) {
        this.layoutStyle = "";
        this.printSize = event.value;
        if (this.printSize == '15.4*10.3') {
            this.layoutStyle = 'layout-landscape';
        } else {
            this.layoutStyle = 'layout-portrait'
        }
    }
}
