import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { Rates, TermsOfEmployment } from 'app/main/model/CoWorker';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { TermsOfEmploymentService } from '../terms-of-employment.service';
import { NotificationsService } from 'angular2-notifications';
import { DateAdapter, MatDialogRef, MatDialog, MAT_DIALOG_DATA, MatOption } from '@angular/material';

import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { ResponseBase } from 'app/main/model/ResponseBase';
import { RequestRatesForTermsOfEmployment } from "app/main/model/RequestBase";
import { RatesType } from "app/main/model/WorkHourStatus";
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';
import { WeekDaysOption } from 'app/main/model/WeekDays';
import { spaceOnlyValidator } from "app/core/Validators/validation";

@Component({
    selector: "new-terms-of-employment",
    templateUrl: "./new-terms-of-employment.component.html",
    styleUrls: ["./new-terms-of-employment.component.scss"]
})

export class NewTermsOfEmploymentComponent implements OnInit {
    showSpinner: boolean = false;
    employmentObject: TermsOfEmployment = new TermsOfEmployment();
    termsOfEmploymentForm: FormGroup;
    termsOfEmploymentList: [] = [];
    ratesTermsOfEmploymentForm: FormGroup;
    ratesObject: Rates = new Rates();

    rateListForTermsOfEmployment: [] = [];
    pagination: any;
    displayPagination: boolean;
    requestBase: any;
    ratesType = RatesType;
    selectWeekDays = WeekDaysOption;
    currentselectedWeekDaysObj: number[];
    @ViewChild('allWeekdaysSelected', { static: false }) private allWeekdaysSelected: MatOption;

    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private translate: TranslateService,
        private formBuilder: FormBuilder,
        private termsOfEmploymentService: TermsOfEmploymentService,
        private notificationsService: NotificationsService,
        private adapter: DateAdapter<any>,
        private dialogRef: MatDialogRef<NewTermsOfEmploymentComponent>,
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.translationLoaderService.loadTranslations(danish, english);
    }



    ngOnInit() {
        this.adapter.setLocale('da');
        this.termsOfEmploymentForm = this.formBuilder.group({
            designation: [null, [Validators.required, spaceOnlyValidator.bind(this)]],
            valideFrom: [null, Validators.required]
        });
        this.ratesTermsOfEmploymentForm = this.formBuilder.group({
            id: [null],
            type: [null, Validators.required],
            from: [null, Validators.required],
            to: [null, Validators.required],
            hourlyRate: [null, Validators.required],
            numberofHours: [null],
            weekday: [null, [Validators.required]],
            minEmployeeMonth: [null, Validators.required],
            maxEmployeeMonth: [null, Validators.required],
            termsofEmploymentID: [this.data.id, Validators.required],
            maximumHours:[null, Validators.required],
            maxLeaveCountDays:[null, Validators.required],
            workingHours:[null],
            isWorkingShift:[false],
            isCalculatedAsExtra:[false]
        });

        this.ratesObject.minEmployeeMonth = 0;
        this.ratesObject.maxEmployeeMonth = 0;
        this.ratesObject.maximumHours = 0;
        this.ratesObject.maxLeaveCountDays = 0;

        if (this.data && this.data.id) {
            this.employmentObject = this.data;
            this.termsOfEmploymentForm.patchValue(this.employmentObject);
        }

        this.requestBase = new RequestRatesForTermsOfEmployment();
        this.requestBase.Index = 1;
        this.requestBase.Limit = 100;
        this.requestBase.TermsofEmploymentID = this.employmentObject.id;
        this.getRatesListFOrTermsOfEmployment();
    }

    Save() {
        this.termsOfEmploymentForm.markAllAsTouched();
        if (this.termsOfEmploymentForm.valid) {
            if (this.employmentObject.id) {
                this.Update();
            } else {
                this.Add();
            }
        }
    }

    Add() {
        this.showSpinner = true;
        this.termsOfEmploymentService.AddUpdate<ResponseBase>(this.employmentObject).subscribe({
            next: (response: ResponseBase) => {
                if (response) {
                    this.employmentObject = response.result;
                    setTimeout(() => {
                        this.dialogRef.close(true);
                    }, 500);
                    this.translate.get("MESSAGES.Created").subscribe({
                        next: (res) => {
                            this.notificationsService.success(res);
                        },
                        error: err => {
                        }
                    });
                }
            },
            error: err => {
                this.showSpinner = false;
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    Update() {
        this.showSpinner = true;
        this.termsOfEmploymentService.AddUpdate<ResponseBase>(this.employmentObject).subscribe({
            next: (response: ResponseBase) => {
                if (response) {
                    this.employmentObject = response.result;
                    setTimeout(() => {
                        this.dialogRef.close(true);
                    }, 500);
                    this.translate.get("MESSAGES.Updated").subscribe({
                        next: (res) => {
                            this.notificationsService.success(res);
                        },
                        error: err => {
                        }
                    });
                }
            },
            error: err => {
                this.showSpinner = false;
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    // Edit(id) {
    //     this.showSpinner = true;
    //     this.termsOfEmploymentService.GetTermsByID(id).subscribe({
    //         next: (response: ResponseBase) => {
    //             if (response) {
    //                 this.employmentObject = response.result;
    //                 this.termsOfEmploymentForm.patchValue(this.employmentObject);
    //             }
    //         },
    //         error: err => {
    //             this.showSpinner = false;
    //             this.translate.get("MESSAGES.Failed").subscribe({
    //                 next: (res) => {
    //                     this.notificationsService.error(res);
    //                 },
    //                 error: err => {
    //                 }
    //             });
    //         },
    //         complete: () => {
    //             this.showSpinner = false;
    //         }
    //     });
    // }

    back() {
        this.dialogRef.close(false);
    }

    getRatesListFOrTermsOfEmployment() {
        this.showSpinner = true;
        this.termsOfEmploymentService.GetRatesForTermsOfEmployment(this.requestBase).subscribe({
            next: (response: ResponseBase) => {
                if (response) {
                    this.rateListForTermsOfEmployment = response.result;
                    this.rateListForTermsOfEmployment.filter((item: any) => {
                        item.weekdaysList = this.GetWeekDaysList(item.weekday);
                    });
                    this.pagination = response.pagination;
                    this.displayPagination = true;
                }
            },
            error: err => {
                this.showSpinner = false;
                this.rateListForTermsOfEmployment = [];
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    SaveRates() {
        this.ratesTermsOfEmploymentForm.markAllAsTouched();
        if (this.ratesTermsOfEmploymentForm.valid)
            this.AddOrUpdateRates();
    }

    AddOrUpdateRates() {
        this.showSpinner = true;
        this.ratesObject.termsofEmploymentID = this.employmentObject.id;
        this.ratesObject.weekday = this.currentselectedWeekDaysObj.reduce((sum, current) => sum + current, 0)
        this.termsOfEmploymentService.AddORUpdateRates<ResponseBase>(this.ratesObject).subscribe({
            next: (response: ResponseBase) => {
                if (response) {
                    this.translate.get("MESSAGES.Created").subscribe({
                        next: (res) => {
                            this.notificationsService.success(res);
                        },
                        error: err => {
                        }
                    });
                    this.ResetRateForm();
                    this.getRatesListFOrTermsOfEmployment();
                }
            },
            error: err => {
                this.showSpinner = false;
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }
    EditRates(item) {
        this.ratesObject = item;
        this.GetWeekDays(this.ratesObject.weekday)
        if (!(this.ratesObject && this.ratesObject.minEmployeeMonth)){
            this.ratesObject.minEmployeeMonth = 0;
        }
        if (!(this.ratesObject && this.ratesObject.maxEmployeeMonth)){
            this.ratesObject.maxEmployeeMonth = 0;
        }
        if (!(this.ratesObject && this.ratesObject.maximumHours)){
            this.ratesObject.maximumHours = 0;
        }
        if (!(this.ratesObject && this.ratesObject.maxLeaveCountDays)){
            this.ratesObject.maxLeaveCountDays = 0;
        }
        //this.ratesTermsOfEmploymentForm.patchValue(this.ratesObject);
    }

    DeleteRates(item) {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: "200px",
            data: { Header: 'Headers.DeleteMessage' }
        })

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.showSpinner = true;
                this.termsOfEmploymentService.DeleteRatesForTermsOfEmployment(item.id).subscribe({
                    next: (response: any) => {
                        if (response) {
                            this.showSpinner = false;
                            this.getRatesListFOrTermsOfEmployment();
                            this.translate.get("MESSAGES.Deleted").subscribe({
                                next: (res) => {
                                    this.notificationsService.success(res);
                                },
                                error: err => {
                                }
                            });
                        }
                    },
                    error: err => {
                        this.showSpinner = false;
                        this.translate.get("MESSAGES.Failed").subscribe({
                            next: (res) => {
                                this.notificationsService.error(res);
                            },
                            error: err => {
                            }
                        });
                    },
                    complete: () => {
                        this.showSpinner = false;
                    }
                })
            }
        });
    }

    ResetRateForm() {
        this.ratesObject = new Rates();
        this.ratesTermsOfEmploymentForm.reset();
        this.currentselectedWeekDaysObj = [];
        this.ratesTermsOfEmploymentForm.controls["termsofEmploymentID"].setValue(this.employmentObject.id)
    }

    toggleAllWeekDaysSelection() {
        this.currentselectedWeekDaysObj = [];
        if (this.allWeekdaysSelected.selected) {
            this.currentselectedWeekDaysObj.push(0);
            this.selectWeekDays.filter(x => {
                this.currentselectedWeekDaysObj.push(x.id);
            })
        }
    }

    GetWeekDays(sum) {
        this.currentselectedWeekDaysObj = [];
        if (sum > 127 || sum <= 0) {
        } else {
            for (var i = 64; i >= 1; i = i / 2) {
                if (sum >= i) {
                    sum -= i
                    this.currentselectedWeekDaysObj.push(i)
                }
            }
        }
    }
    GetWeekDaysList(sum) {
        var WeekDaysList = [];
        if (sum > 127 || sum <= 0) {
        } else {
            for (var i = 64; i >= 1; i = i / 2) {
                if (sum >= i) {
                    sum -= i
                    WeekDaysList.push(i)
                }
            }
            return WeekDaysList = WeekDaysList.sort((a, b) => { return a - b; });
        }
    }
    selectRatesType(rate) {
        this.ratesObject.status = rate.status;
        if (!this.ratesObject.id) {
            this.currentselectedWeekDaysObj = [];
            if(this.ratesObject.status == 10){
                this.ratesObject.from = "00:00";
                this.ratesObject.to = "23:59";
                this.ratesObject.maximumHours = 7.5;
                this.currentselectedWeekDaysObj.push(0);
                this.selectWeekDays.filter(x => {
                    this.currentselectedWeekDaysObj.push(x.id);
                })
            }
            else {
                this.ratesTermsOfEmploymentForm.controls["from"].setValue(null);
                this.ratesTermsOfEmploymentForm.controls["to"].setValue(null);
                this.ratesTermsOfEmploymentForm.controls["maximumHours"].setValue(0);
            }
        }
    }

    oncheckIsWorkingShift() {
        if (this.ratesObject.isWorkingShift)
            this.ratesTermsOfEmploymentForm.controls["workingHours"].setValidators([Validators.required]);
        else
            this.ratesTermsOfEmploymentForm.controls["workingHours"].setValidators(null);
        this.ratesTermsOfEmploymentForm.controls["workingHours"].updateValueAndValidity();
    }
}