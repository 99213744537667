import { NgModule } from '@angular/core';
import { PaginationComponent } from './pagination.component';
import { MatPaginatorModule, MatTableModule } from '@angular/material';

@NgModule({
  declarations: [
    PaginationComponent
  ],
  imports: [
    MatTableModule,
    MatPaginatorModule
  ],
  exports: [
    PaginationComponent  ],
  providers: []
})

export class PaginationModule {

}
