export const locale = {
    lang: 'en',
    data: {
        'HEADERS': {
            'ChangeDrivingDays': 'Would you like to change the number of driving days'
        },
        'FIELDS': {
            'ChangeFrom': 'Change from',
            'ChangeTo': 'Change to'
        },
        'BUTTONS': {
            'Yes': 'Yes',
            'No': 'No',
        },
    }
};