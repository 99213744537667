export class Customer {

    public id: string = '';
    public number: string = '';
    public Navn: string = '';
    public name: string = '';
    public phone: string = '';


    public Postnummer: number = 0;
    public Adresse: string = null;
    public Bynavn: string = null;
    public isActive?: boolean = true;


    //additional
    public address: string = null;
    public address2: string = null;
    public city: string = null;
    public pinCode: string = null;
    public position: string = null;
    public mapLocation: string = null;
    public email: string = "";
    public colorcode: string = null;
    public indoorOutdoorStatusIds: any[] = [];
    public indoorOutdoorStatusId: string = null;
    public emailInvoice: string = '';
    public eanNummer: string;
    public comment: string;
    public fileName: string;
    public flag: number;
    public doNotSendRecommendation: boolean = false;
    public comingFrom: number;
    public discountPercent: number = 0;
    public doNotSendNotification: boolean = false;
    public requisitionNumber?: boolean = false;
    public countryCode: string = '+45';
    public doNotCalculatePriceForPriceDiscount: boolean = false;
    public doNotCalculatePriceForPostNumber: boolean = false;
    public isCustomerNumberAuto: boolean = true;
    constructor() { }
}

export class Cust {

    public id: string = '';
    public number: string = '';
    public name: string = '';
    public phone: string = '';
    public Postnummer: number = 0;
    public Adresse: string = null;
    public Bynavn: string = null;
    public isActive?: boolean = true;
    //additional
    public address: string = null;
    public address2: string = null;
    public city: string = null;
    public pinCode: string = null;
    public position: string = null;
    public mapLocation: string = null;
    public email: string = "";
    public colorcode: string = null;
    public indoorOutdoorStatusIds: any[] = [];
    public indoorOutdoorStatusId: string = null;
    public emailInvoice: string = '';
    public isSelected: boolean;
    constructor() { }
}

export class InquiryCustomer {
    public id: string;
    public name: string;
    public phoneNo: string;
    public email: string;
    public address: string;
    public postNo: string;
    public city: string;
    public question: string;
    public customerId: string;
    public projectId: string;
    public isNote: boolean;
    public received: any;
    public receivedTime: any;
    public isFeildManually: boolean = true;
    public coworkerComment: string;
    public source: number;
    public number: string;
    public customersList: Cust[];
    public isActive: boolean = true;
    public isDeleted: boolean = false;
    public customerNumber: string;
    public customerName: string;
    public customerEmail: string;
    public customerPhone: string;
    public customerAddress: string;
    public customerCity: string;
    public customerPostNumber: string;
    public projectNumber: string;
    public projectAddress: string;
    public isFilesMoveTofinish: boolean;
    public emailInvoice: string;
    public offerID: string;
    public offerNumber: string;
    public offerAddress: string;
    public offerCity: string;
    public offerPostNumber: string;
    public status?: number;
    public indoorOutdoorStatusIds: any[] = [];
    public indoorOutdoorStatusId: string = null;
    public comments: string;
    public countryCode: string;
}

export class CustomerFile {
    public id?: string;
    public customerId?: string;
    public fileId?: string;
    public date?: Date;
    public name?: string;
    public category?: string;
    public path?: string;
    public IsActive?: string;
}

export class CustomerProperty {
    public ID?: string;
    public PropertyType: string;
    public Name: string;
    public Address: string;
    public Address2: string;
    public PinCode: string;
    public City: string;
    public Phone: string;
    public CustomerID: string;
}
