export const locale = {
  lang: "en",
  data: {
    "BUTTONS": {
      "BTNYes": "Yes",
      "BTNNo": "No"
    },
    "FIELDS": {
      "Date": "Date",
      "Time": "Time",
      "Text": "Text",
      "Replay": "Replay",
      "CompletedBy":"Completed by",
      'ChooseFile': 'Choose File',
      'NoFileSelected': 'No File Selected',
      'FileSelected': 'File Selected',
    },
  }
};
