import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiConstant } from '../../../core/constant/ApiConstant';
import { RequestInquiry, RequestForLocalStorage } from '../../model/RequestBase';
import { RequestMethod, RequestOptionsArgs } from '@angular/http';

@Injectable()
export class CalendarService {
    number: any;
    text: any;
    private actionUrl: string;
    private actionWorkHourUrl: string;
    customers: any[];
    ObjCustomer: any;
    customerOrders: any[];
    constructor(private http: HttpClient) {
        this.actionUrl = ApiConstant.API_URL + ApiConstant.INQUIRY + "/";
        this.actionWorkHourUrl = ApiConstant.API_URL + ApiConstant.WORK_HOURS + "/";
    }

    public Add<T>(inquiry: any): Observable<T> {
        return this.http.post<T>(this.actionUrl, inquiry);
    }

    public Update<T>(id: any, inquiry: any): Observable<T> {
        return this.http.put<T>(this.actionUrl + id, inquiry);
    }

    public UpdateMarkAsReadInquiry<T>(id: any): Observable<T> {
        return this.http.put<T>(this.actionUrl + "MarkAsRead/" + id, null);
    }

    public Delete<T>(id: any): Observable<T> {
        return this.http.delete<T>(this.actionUrl + id);
    }

    // public DeleteFutureDateWorkHour<T>(model: any): Observable<T> {
    //     return this.http.delete<T>(this.actionWorkHourUrl + "deletefuturedateonrequsition", {
    //         params: model
    //     });
    // }
    public DeleteWorkHourForPlanning<T>(model: any): Observable<T> {
        return this.http.delete<T>(this.actionWorkHourUrl + "deleteworkhourforplanning", {
            params: model
        });
    }

    public DeleteFutureDateWorkHour<T>(model: any): Observable<T> {
        return this.http.delete<T>(this.actionWorkHourUrl + "deletefuturedateonrequsition", {
            params: model
        });
    }

    public Get<T>(RequestCustomer: any): Observable<T> {
        let search = this.GetParams(RequestCustomer);
        return this.http.get<T>(this.actionUrl, {
            params: search
        });
    }

    public DeleteNonWorkingHourForPlanning<T>(model: any): Observable<T> {
        return this.http.delete<T>(this.actionWorkHourUrl + "deletenonworkinghourforplanning", { params: model });
    }

    public GetSingle<T>(id: any): Observable<T> {
        return this.http.get<T>(this.actionUrl + id);
    }
    public GetWorkHoursForLiftCoworker<T>(id: any, date: any): Observable<T> {
        return this.http.get<T>(this.actionWorkHourUrl + "getWorkhourForLift?id=" + id + "&date=" + date);
    }
    public getRequestParameter() {
        var val = localStorage.getItem(ApiConstant.LocalRequestModel);
        var requestForLocalStorage = new RequestForLocalStorage();
        var requestInquiry = new RequestInquiry();
        requestInquiry.OrderBy = "Number DESC"
        if (val) {
            requestForLocalStorage = JSON.parse(val);
            if (requestForLocalStorage.InquiryRequest) {
                requestInquiry = requestForLocalStorage.InquiryRequest;
            }
        }
        return requestInquiry;
    }

    public setRequestParameter(model) {
        var requestForLocalStorage = new RequestForLocalStorage();
        var val = localStorage.getItem(ApiConstant.LocalRequestModel);
        if (val) {
            requestForLocalStorage = JSON.parse(val);
        }
        requestForLocalStorage.InquiryRequest = model;
        localStorage.setItem(ApiConstant.LocalRequestModel, JSON.stringify(requestForLocalStorage));
    }

    public GetParams(param) {
        let params: HttpParams = new HttpParams();
        for (var key in param) {
            if (param.hasOwnProperty(key)) {
                let val = param[key];
                params = params.append(key, val);
            }
        }
        return params;
    }

    public GetQuestionList<T>(key: any): Observable<T> {
        return this.http.get<T>(ApiConstant.API_URL + ApiConstant.ACCOUNT + '/SettingValue/' + key);
    }

    public UpdateSettingValue<T>(id: any, settingValues): Observable<T> {
        return this.http.put<T>(ApiConstant.API_URL + ApiConstant.SETTINGS + '/' + id, settingValues);
    }

    // public GetPlanningDefaultCoworker<T>(): Observable<T> {
    //     return this.http.get<T>(ApiConstant.API_URL + ApiConstant.SETTINGS + "/getSettings?param1=PlanningDefaultCoworker");
    // }

    public UpdatePlanningDefaultCoworker<T>(id: any, settingValues): Observable<T> {
        return this.http.put<T>(ApiConstant.API_URL + ApiConstant.SETTINGS + '/' + id, settingValues)
    }

    public GetRequisitionPlanningDefaultCoworker<T>(RequestCoworker: any): Observable<T> {
        let search = this.GetParams(RequestCoworker);
        return this.http.get<T>(this.actionWorkHourUrl + "getRequisitionPlanningDefaultCoworker", {
            params: search
        });
    }

    public AddPlanningDefaultCoworker<T>(planningDefaultCoworker): Observable<T> {
        return this.http.post<T>(this.actionWorkHourUrl + 'addPlanningDefaultCoworker', planningDefaultCoworker)
    }

    public DeleteDefaultCoWorker<T>(id: any): Observable<T> {
        return this.http.delete<T>(this.actionWorkHourUrl + "deletePlanningDefaultCoWorker/" + id);
    }
    public GetWorkHoursForPlanningById<T>(id: any): Observable<T> {
        return this.http.get<T>(this.actionWorkHourUrl + "getWorkHoursForPlanningById/"+id);
    }
}
