import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';

@Injectable()
export class QuickPanelService {

  arrWeather: any[];

  apiKey = 'ef4abcacc946c64c09c1496f2b9de492'; // unnati : b9b4bdc1f19fb8e54d98817fbe7519c6

  url;

  constructor(private http: HttpClient) {
    this.url = 'https://api.openweathermap.org/data/2.5/weather?q=London,uk'
  }


  getWeather(city, code) {
    //return this.http.get(this.url + '&APPID=' + this.apiKey).map((res) => {
    return this.http.get('https://api.openweathermap.org/data/2.5/forecast?zip=4293,dk&units=metric&APPID=ef4abcacc946c64c09c1496f2b9de492').map((res) => {
      var data = res['list'];
      // var objWeather = new Array();
      // var comparedate = data.list[0].dt_txt;
      // comparedate = comparedate.split(" ");
      // objWeather.push(data.list[0]);
      // for (var i = 0; i < data.list.length; i++) {
      //   var currentdate = data.list[i].dt_txt.split(" ");
      //   if (currentdate[0] !== comparedate[0]) {
      //     comparedate[0] = currentdate[0];
      //     for (var j = 0; j < data.list.length; j++) {
      //       var current = data.list[j].dt_txt;
      //       current = current.split(" ");
      //       if (comparedate[0] == current[0] && comparedate[1] == current[1]) {
      //         objWeather.push(data.list[j]);
      //       }
      //     }
      //   }
      // }
      // return objWeather;

      var objWeather = new Array();
      var comparedate = data[0].dt_txt;
      comparedate = comparedate.split(" ");
      objWeather.push(data[0]);
      for (var i = 0; i < data.length; i++) {
        var currentdate = data[i].dt_txt.split(" ");
        if (currentdate[0] !== comparedate[0]) {
          comparedate[0] = currentdate[0];
          for (var j = 0; j < data.length; j++) {
            var current = data[j].dt_txt;
            current = current.split(" ");
            if (comparedate[0] == current[0] && comparedate[1] == current[1]) {
              objWeather.push(data[j]);
            }
          }
        }
      }
      return objWeather;

    })
  }
}



export class weather {
  dt_txt: string;
  temp: string;
  temp_max: string;
  temp_min: string;
}










//import { Injectable } from '@angular/core';
//import { Http } from '@angular/http';
//import 'rxjs/add/operator/map';

//@Injectable()
//export class QuickPanelService {

//  arrWeather: any[];

//  apiKey = 'ef4abcacc946c64c09c1496f2b9de492'; // unnati : b9b4bdc1f19fb8e54d98817fbe7519c6

//  url;

//  constructor(private http: Http) {
//    this.url = 'http://api.openweathermap.org/data/2.5/weather?q=London,uk'
//  }


//  getWeather(city, code) {

//    //return this.http.get(this.url + '&APPID=' + this.apiKey).map((res) => {
//    return this.http.get('http://api.openweathermap.org/data/2.5/forecast?zip=4293,dk&units=metric&APPID=ef4abcacc946c64c09c1496f2b9de492').map((res) => {
//      var data = JSON.parse(res['_body'])

//      var objWeather = new Array();
//      var comparedate = data.list[0].dt_txt;

//      comparedate = comparedate.split(" ");
//      objWeather.push(data.list[0]);
//      for (var i = 0; i < data.list.length; i++) {
//        var currentdate = data.list[i].dt_txt.split(" ");
//        if (currentdate[0] !== comparedate[0]) {
//          comparedate[0] = currentdate[0]
//          for (var j = 0; j < data.list.length; j++) {

//            currentdate = data.list[j].dt_txt;
//            currentdate = currentdate.split(" ")
//            if (currentdate[0] == comparedate[0]) {
//              comparedate[0] = currentdate[0]
//              if (currentdate[1] == comparedate[1]) {
//                objWeather.push(data.list[j]);
//              }
//            }
//          }
//        }
//      }
//      return objWeather;
//    })
//  }
//}
//export class weather {
//  dt_txt: string;
//  temp: string;
//  temp_max: string;
//  temp_min: string;
//}

