export const locale = {
    lang: 'ds',
    data: {
        'FIELDS': {
            'Status': 'Status',
            'MaterialTextColor': 'Tekst',
            'VareNo': 'Vare nummer',
            'MaterialNo': 'Nummer',
            'Material': 'Materiale',
            'MaterialText': 'Vare Tekst',
            'DateonCreate': 'Dato',
            'Quantity': 'Areal',
            'Unit': 'Enhed',
            'RetailPrice': 'Udsalgs pris pr. stk',
            'TotalPrice': 'Pris i alt',
            'PersonalPrice': 'Personale pris',
            'PersonalTotalPrice': 'Personal i alt',
            'TotalIncludedVAT': 'Tilbuds Pris incl. moms',
            'Estimate': 'Overslagspris',
            'Price-included': 'Pris incl. moms',
            'ProfitSubProfit': 'Overskud / UnderSkud',
            'ScanBarcode': 'Scan stregkode',
            'ColorCode': 'Farve kode',
            'PriceExclVAT': 'Pris excl. moms',
            'Discount': 'Rabat',
            'Total': 'I alt',
            'DonePercentage': 'Udført %',
            'DiscountLable': 'Rabat / Debitor Rabat',
            'Amount': 'Beløb',
            'Percentage': 'Procent',
            'PurchasePrice': 'Indkøbs pris',
            'RemainsTakenFromBasements': 'Rester fra kældre eller ingen lagertræk',
            'MaterialCreation': 'Materialer Udgør',
            'All': 'Alle',
            "Materialtype": 'Vare type',
            'Vat': 'Excl. Moms',
            'Not-Found-Material': 'Ingen materiale fundet',
            'Rows': 'Række',
            'Minutes': 'Minutter',
            'Total-purchase-price':'Samlet',
            'PurchasePrise' : 'købspris',
            'M2Quantity':'M2 Antal',
            'PCS':'STK/ 1M2',
            'PurchaseQTY':'Antal',
            'Antal/Liter':'Antal/Liter',
            'Driving':'kørsel',
            'DrivingTitle': 'KØRSEL',
            'NumberOfHours': 'Antal timer',
            'DistancePerDay': 'Km pr. dag',
            'NumberOfDrivingDays': 'Antal kørselsdage',
            'TravelingTime': 'Min. pr. vej',
            'PerDay': 'Min pr. dag',
            'TotalTime': 'Tid i alt',
            'DiscountByPostNumber': 'Post nr. tillæg',
            'EditDrivingDays': 'Redigere kørsel dage',
            'Distance': 'Km i alt',
            'OfferName': 'Tilbud Navn',
            "ShowVarious": "Vis diverse",
            "PrM2":"Pr. M2"
        },
        'Headers': {
            'AddNewMaterial': 'Tilføj nyt materiale',
            'Nomaterialadded': 'Ingen materialer',
            'DeleteMaterial': 'Vil du slette "@MaterialName"?',
            'MaterialList': 'Materialeliste',
        },
        'BUTTON': {
            'PDF':'PDF',
            'Driving': 'Kørsel',
            'Save': 'Gem',
            'BACK': 'Tilbage',
            'Reset': 'Nulstil',
            'BtnAdd': 'Tilføj / Gem',
            'AddMaterialToProject':'Tilføj til projekt'

        },
        'Validation': {
            'VareNumber': 'Vare nummer er påkrævet',
            'Number': 'Nummer er påkrævet',
            'Material': 'Materiale er påkrævet',
            'ColorCode': 'Farvekode er påkrævet',
            'Unit': 'Enhed er påkrævet',
            'M2Quantity':'M2 ANTAL er påkrævet',
            'PCS':'PCS/1M2 er påkrævet',
            'Areal':'Areal er påkrævet',
            'PurchaseQTY':'Antal er påkrævet',
            'Antal/Liter':'Antal/Liter er påkrævet',
            'RetailPrice':'Detailpris er påkrævet',
            'PurchasePrice':'Købspris er påkrævet',
            'PersonalPrice':'Personal Price er påkrævet',
        },
        'MESSAGES': {
            'Created': 'Oprettet med succes',
            'Updated': 'Opdateret med succes',
            'Deleted': 'Slettet med succes',
            'Failed': 'Operation mislykket, prøv senere',
            'NoBarcodeAttachedToMaterial': 'Ingen stregkode knyttet til noget materiale.',
            'EnterTextColorCode': 'Gå ind Tekst.',
            'MaterialPriseMoreOne': 'Gå ind Udsalgs pris',
            'NoMaterialFound': 'Intet materiale fundet',
            'AddColorCode': 'Farvekode mangler at blive udfyldt',
            'MaterialAlreadyExists': 'Materiale allerede tilføjet.',
        },
    }
}