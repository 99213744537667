export const locale = {
  lang: 'ds',
  data: {
    'MESSAGES': {
      'NORECORD': 'Ingen registrering fundet',
      'NoOrderAvailbale': 'Ingen projekt til rådighed',
      'DropBoxApiNotResponding': 'Dropbox reagerer ikke, prøv efter lidt tid',
      'FileConflict': 'Filen eksisterer allerede',
      'FolderConflict': 'Mappenavn eksisterer allerede',
      'Created': 'Oprettet med succes',
      'Updated': 'Opdateret med succes',
      'Deleted': 'Slettet med succes',
      'Failed': 'Operation mislykket, prøv senere',
      'EmailSent': 'Email sendt med succes',
      'NoProjectAdded': 'Intet projekt tilføjet',
    },
    'TOOTIPORTITLE':{
      'EditDescrption':'Redigere Forespørgsel Bemærk'
    }
  }
};
