import { Component, OnInit, Inject } from '@angular/core';
import { ProjectofferService } from 'app/main/pages/project-offer/project-offer.service';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { Router, ActivatedRoute } from '@angular/router';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { OfferCategory, OfferCategoryMaterial, OfferAddressModel } from 'app/main/model/ProjectOffer';
import { OfferMaterialWithPrice } from 'app/main/model/OfferV2';
import { MaterialTypeCategory, PaintSelectTime, TreatmentTypeIds, OfferTreatmentType } from 'app/constant/common.constant';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';
import { ProjectOfferRoomListComponent } from 'app/main/pages/project-offer/component/project-offer-room-list/project-offer-room-list.component';
import { TranslateService } from '@ngx-translate/core';
import { RoomAreaComponent } from 'app/main/shared-component/offers/rooms/area/room-area.component';
import { NewMaterialComponent } from 'app/main/pages/material/new-material/new-material.component';

@Component({
    selector: "offer-category-qty-select",
    templateUrl: "./offer-category-qty-select.component.html",
    styleUrls: ["./offer-category-qty-select.component.scss"]
})

export class OfferCategoryQtySelectComponent implements OnInit {
    offerCategoryId: string = "";
    offerCategory = new OfferCategory();
    showSpinner: boolean = false;
    selectMaterialOfferList = MaterialTypeCategory;
    MaterialObject: OfferMaterialWithPrice[] = [];
    MasterMaterialTypeList: OfferMaterialWithPrice[] = [];
    MaterialTypeSearchForCategory = "";
    previousCategory = new OfferCategory();
    roomList = [];
    SelectCategoryTime = PaintSelectTime;
    customerDetailObj: any;
    projectOffer: OfferAddressModel = new OfferAddressModel();
    projectPriceDiscount: number = 0;
    treatmentTypeIds = TreatmentTypeIds;
    offerTreatmentTypeList = OfferTreatmentType;
    offerId: string = "";
    showAndet: boolean = false;
    room: any = {};
    offerAddedToCart: any[];
    constructor(private projectofferService: ProjectofferService,
        private translate: TranslateService,
        private _route: ActivatedRoute,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<OfferCategoryQtySelectComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private translationLoader: FuseTranslationLoaderService) {
        this.translationLoader.loadTranslations(english, danish);
    }
    ngOnInit() {
        this.getOfferMaterialData();
        this.offerCategory = this.data.category;
        this.offerId = this.data.offerId;
        this.roomList = this.data.roomList;
        this.projectOffer = this.data.projectOffer;
        this.customerDetailObj = this.data.customerDetailObj;
        this.offerAddedToCart= this.data.offerAddedToCart;
        this.projectPriceDiscount = this.projectOffer.indoorOutdoorStatusIDPercentage;
        // if (this.roomList.length > 0) {
        //     this.roomList.forEach(e => {
        //         //e.isChecked = true;
        //     });
        // }
        this.roomList.filter(x=>x.isAddExtraWallQty=false);
    }

    getOfferMaterialData() {
        this.showSpinner = true;
        this.projectofferService.GetOfferCategoryMaterial().subscribe({
            next: (response: ResponseBase) => {
                this.MaterialObject = response.result.myResponse;
                this.MasterMaterialTypeList = response.result.myResponse;
                this.getCategoryMaterialOnEdit();
                this.showSpinner = false;
            },
            error: err => {
                this.showSpinner = false;
            },
            complete: () => {
            }
        });

    }

    onCloseDialog(e) {
        this.dialogRef.close({ category: this.offerCategory, roomList: this.roomList })
    }

    getCategoryMaterialOnEdit() {
        for (let i = 0; i < this.offerCategory.categoryMaterialList.length; i++) {
            if (this.offerCategory.categoryMaterialList[i].materialTypeID)
                this.offerCategory.categoryMaterialList[i].materialList = this.MasterMaterialTypeList.filter(o => { return o.type.split(',').find(p => p == this.offerCategory.categoryMaterialList[i].materialTypeID.toString()) });
            else
                this.offerCategory.categoryMaterialList[i].materialList = this.MasterMaterialTypeList;
            this.offerCategory.categoryMaterialList[i].materialQty = this.offerCategory.categoryMaterialList[i].materialQty ? this.offerCategory.categoryMaterialList[i].materialQty : 0;
            this.offerCategory.categoryMaterialList[i].length = this.offerCategory.categoryMaterialList[i].length ? this.offerCategory.categoryMaterialList[i].length : 0;
            this.offerCategory.categoryMaterialList[i].width = this.offerCategory.categoryMaterialList[i].width ? this.offerCategory.categoryMaterialList[i].width : 0;
            this.offerCategory.categoryMaterialList[i].height = this.offerCategory.categoryMaterialList[i].height ? this.offerCategory.categoryMaterialList[i].height : 0;
            this.offerCategory.categoryMaterialList[i].total = this.offerCategory.categoryMaterialList[i].total ? this.offerCategory.categoryMaterialList[i].total : 0;
            if(!this.offerCategory.categoryMaterialList[i].roomList)
                this.offerCategory.categoryMaterialList[i].roomList = this.roomList.map(x => Object.assign({}, x));
            else {
                this.offerCategory.categoryMaterialList.filter(o => {
                    if (!o.roomList)
                        o.roomList = this.roomList.map(x => Object.assign({}, x));
                    var selectedRoomID = o.roomList.filter(p => p.isChecked == true);
                    o.roomList = this.roomList.map(x => Object.assign({}, x));
                    if (selectedRoomID) {
                        o.roomList.filter(x => {
                            var selectroom = selectedRoomID.find(u => u.id == x.id);
                            if (selectroom) {
                                x.isChecked = true;
                            }
                        })
                    }
                });
            }
            this.offerCategory.categoryMaterialList[i].isShowRoomList = true;
            this.offerCategory.categoryMaterialList[i].selectCategoryTimeForDiverse = [];
            this.offerCategory.categoryMaterialList[i].baseline = 0;
            this.offerCategory.categoryMaterialList[i].area = 0;
            this.offerCategory.categoryMaterialList[i].price = 0;
            this.offerCategory.categoryMaterialList[i].extraM2QtyWall = 0;
            this.offerCategory.categoryMaterialList[i].extraM2QtyCeiling = 0;
            this.offerCategory.categoryMaterialList[i].extraM2QtyFloor = 0;
            this.offerCategory.categoryMaterialList[i].notCalculateToDriving = false;
            this.offerCategory.categoryMaterialList[i].offerMaterialText = this.offerCategory.categoryMaterialList[i].offerMaterialText ? this.offerCategory.categoryMaterialList[i].offerMaterialText : "";
            if (this.offerCategory.categoryMaterialList[i].materialTypeID && this.offerCategory.categoryMaterialList[i].materialTypeID == 27)
                this.offerCategory.categoryMaterialList[i].showAndet = this.showAndet;
            else
                this.offerCategory.categoryMaterialList[i].showAndet = true;
            var tempData = this.offerCategory.categoryMaterialList[i].materialList.filter(x=>x.id == this.offerCategory.categoryMaterialList[i].offerMaterialID);
            if(tempData && tempData.length > 0)
                this.offerCategory.categoryMaterialList[i].treatmentType = tempData[0].treatmentType ? tempData[0].treatmentType : 0;
            else                
                this.offerCategory.categoryMaterialList[i].treatmentType = 0;
            this.getMaterialPrice(this.offerCategory.categoryMaterialList[i].offerMaterialID, this.offerCategory.categoryMaterialList[i], this.offerCategory.categoryMaterialList[i].materialPriceID?this.offerCategory.categoryMaterialList[i].materialPriceID:"");
            //Get Price start
            // if (this.offerCategory.categoryMaterialList[i].materialTypeID) {
            //     this.offerCategory.categoryMaterialList[i].materialList = this.MasterMaterialTypeList.filter(o => { return o.type.split(',').find(p => p == this.offerCategory.categoryMaterialList[i].materialTypeID.toString()) });
            //     this.offerCategory.categoryMaterialList[i].materialQty = this.offerCategory.categoryMaterialList[i].materialQty ? this.offerCategory.categoryMaterialList[i].materialQty : 0;
            //     this.offerCategory.categoryMaterialList[i].length = this.offerCategory.categoryMaterialList[i].length ? this.offerCategory.categoryMaterialList[i].length : 0;
            //     this.offerCategory.categoryMaterialList[i].width = this.offerCategory.categoryMaterialList[i].width ? this.offerCategory.categoryMaterialList[i].width : 0;
            //     this.offerCategory.categoryMaterialList[i].total = this.offerCategory.categoryMaterialList[i].total ? this.offerCategory.categoryMaterialList[i].total : 0;
            //     this.offerCategory.categoryMaterialList[i].roomList = this.roomList.map(x => Object.assign({}, x));
            //     //Get Price start
            //     var materialFind = this.offerCategory.categoryMaterialList[i].materialList.find(o => o.id == this.offerCategory.categoryMaterialList[i].offerMaterialID);
            //     var materialPriceData = materialFind.offerMaterialPriceList.find(x => x.isDefault == true);
            //     if (materialPriceData) {
            //         if (materialPriceData.isAddMeterRangePrice) {
            //             this.offerCategory.categoryMaterialList[i].materialPrice = materialPriceData.priceMeter1;
            //         }
            //         else {
            //             this.offerCategory.categoryMaterialList[i].materialPrice = materialPriceData.price;
            //         }
            //         this.offerCategory.categoryMaterialList[i].reminder = materialPriceData.reminder;
            //     } else {
            //         if (materialFind.offerMaterialPriceList[0].isAddMeterRangePrice) {
            //             this.offerCategory.categoryMaterialList[i].materialPrice = materialFind.offerMaterialPriceList[0].priceMeter1;
            //         }
            //         else {
            //             this.offerCategory.categoryMaterialList[i].materialPrice = materialFind.offerMaterialPriceList[0].price;
            //         }
            //         this.offerCategory.categoryMaterialList[i].reminder = materialFind.offerMaterialPriceList[0].reminder;
            //     }
            //     //end
            // } else {
            //     var materialList = this.MasterMaterialTypeList.find(o => o.id == this.offerCategory.categoryMaterialList[i].offerMaterialID);
            //     if (materialList) {
            //         var offerPriceData = materialList.offerMaterialPriceList.find(x => x.isDefault == true);
            //         if (!offerPriceData) {
            //             offerPriceData = materialList.offerMaterialPriceList[0];
            //         }
            //     }
            //     this.offerCategory.categoryMaterialList[i].materialList = this.MasterMaterialTypeList;
            //     if (offerPriceData.isAddMeterRangePrice) {
            //         this.offerCategory.categoryMaterialList[i].materialPrice = offerPriceData.priceMeter1;
            //     }
            //     else {
            //         this.offerCategory.categoryMaterialList[i].materialPrice = offerPriceData.price;
            //     }
            //     this.offerCategory.categoryMaterialList[i].reminder = offerPriceData.reminder; //this.offerCategory.categoryMaterialList[i].materialList[0].offerMaterialPriceList[0].reminder;
            //     this.offerCategory.categoryMaterialList[i].materialQty = this.offerCategory.categoryMaterialList[i].materialQty ? this.offerCategory.categoryMaterialList[i].materialQty : 0;
            //     this.offerCategory.categoryMaterialList[i].length = this.offerCategory.categoryMaterialList[i].length ? this.offerCategory.categoryMaterialList[i].length : 0;
            //     this.offerCategory.categoryMaterialList[i].width = this.offerCategory.categoryMaterialList[i].width ? this.offerCategory.categoryMaterialList[i].width : 0;
            //     this.offerCategory.categoryMaterialList[i].total = this.offerCategory.categoryMaterialList[i].total ? this.offerCategory.categoryMaterialList[i].total : 0;
            //     this.offerCategory.categoryMaterialList[i].roomList = this.roomList.map(x => Object.assign({}, x));
            //     this.offerCategory.categoryMaterialList[i].isShowRoomList = false;
            // }
        }
    }

    onCheckboxSelect(index, flag) {
        if (this.offerCategory.categoryMaterialList[index].length > 0 && this.offerCategory.categoryMaterialList[index].width > 0 && flag) {
            this.offerCategory.categoryMaterialList[index].isChecked = true;
            this.calculateMaterialQty(index);
            // this.offerCategory.categoryMaterialList[index].materialQty = (this.offerCategory.categoryMaterialList[index].length * this.offerCategory.categoryMaterialList[index].width);
            // this.offerCategory.categoryMaterialList[index].total = (this.offerCategory.categoryMaterialList[index].price * this.offerCategory.categoryMaterialList[index].materialQty);
        } else {
            this.offerCategory.categoryMaterialList[index].isChecked = false;
            this.offerCategory.categoryMaterialList[index].materialQty = 0;
            this.offerCategory.categoryMaterialList[index].total = 0;
            this.offerCategory.categoryMaterialList[index].timer = 0;
        }
    }

    onAddQuantity(index) {
        if (this.offerCategory.categoryMaterialList[index].materialQty > 0) {
            this.offerCategory.categoryMaterialList[index].length = 0;
            this.offerCategory.categoryMaterialList[index].width = 0;
            this.offerCategory.categoryMaterialList[index].height = 0;
            this.offerCategory.categoryMaterialList[index].isChecked = true;
            this.offerCategory.categoryMaterialList[index].total = (this.offerCategory.categoryMaterialList[index].price * this.offerCategory.categoryMaterialList[index].materialQty);
            this.getOfferItemTimer(this.offerCategory.categoryMaterialList[index]);
        } else {
            this.offerCategory.categoryMaterialList[index].isChecked = false;
            this.offerCategory.categoryMaterialList[index].total = (this.offerCategory.categoryMaterialList[index].price * this.offerCategory.categoryMaterialList[index].materialQty);
            this.getOfferItemTimer(this.offerCategory.categoryMaterialList[index]);
        }
    }

    addLengthAndWidth(index) {
        if (this.offerCategory.categoryMaterialList[index].length > 0 && this.offerCategory.categoryMaterialList[index].width > 0) {
            this.offerCategory.categoryMaterialList[index].isChecked = true;
            this.calculateMaterialQty(index);
            // this.offerCategory.categoryMaterialList[index].materialQty = (this.offerCategory.categoryMaterialList[index].length * this.offerCategory.categoryMaterialList[index].width);
            // this.offerCategory.categoryMaterialList[index].total = (this.offerCategory.categoryMaterialList[index].price * this.offerCategory.categoryMaterialList[index].materialQty);
        }
    }
    getMaterialSearchClear(MaterialTypeSearchForCategory, i) {

    }

    ShowWallRoomList(material) {
        material.isShowRoomList = !material.isShowRoomList;
    }
    getOfferMaterial(type, i) {
        if (type == 0) {
            this.offerCategory.categoryMaterialList[i].materialList = this.MasterMaterialTypeList;
        } else {
            this.offerCategory.categoryMaterialList[i].materialList = this.MasterMaterialTypeList.filter(o => { return o.type.split(',').find(p => p == type) });
        }
        if (this.offerCategory.categoryMaterialList[i].materialList.length > 0) {
            this.offerCategory.categoryMaterialList[i].offerMaterialID = this.offerCategory.categoryMaterialList[i].materialList[0].id;
            this.onChangeMaterialSelection(this.offerCategory.categoryMaterialList[i].offerMaterialID, this.offerCategory.categoryMaterialList[i]);
        }
        else {
            this.offerCategory.categoryMaterialList[i].offerMaterialID = "";
        }
    }

    getMaterialPrice(materialId, offerItem, materialPriceId) {
        var materialprice = this.MasterMaterialTypeList.find(o => o.id == materialId);
        if (materialprice) {
            offerItem.materialID = materialprice.id;
            offerItem.materialNumber = materialprice.materialNumber;
            offerItem.imagePath = materialprice.imagePath;
            offerItem.notCalculateToDriving = materialprice.notCalculateToDriving;
            offerItem.selectCategoryTimeForDiverse = [];
            for (var i = 0; i < materialprice.offerMaterialPriceList.length; i++) {
                var obj = this.SelectCategoryTime.find(o => o.id == materialprice.offerMaterialPriceList[i].type);
                if (obj) {
                    if (i == 0) {
                        offerItem.materialPriceID = materialprice.offerMaterialPriceList[i].id;
                        offerItem.price = materialprice.offerMaterialPriceList[i].price;
                    }
                    offerItem.selectCategoryTimeForDiverse.push({
                        id: obj.id,
                        key: obj.key,
                        extraValue: materialprice.offerMaterialPriceList[i].paintText,
                        priceId: materialprice.offerMaterialPriceList[i].id,
                        reminder: materialprice.offerMaterialPriceList[i].reminder,
                    });
                    if (materialprice.offerMaterialPriceList[i].id == materialPriceId) {
                        offerItem.materialPriceID = materialprice.offerMaterialPriceList[i].id;
                        offerItem.price = materialprice.offerMaterialPriceList[i].price;
                        offerItem.text = materialprice.text + ' ' + (materialprice.offerMaterialPriceList[i].paintText != undefined ? materialprice.offerMaterialPriceList[i].paintText : "");
                        var offerReminderText = materialprice.offerMaterialPriceList[i].reminder ? materialprice.offerMaterialPriceList[i].reminder : "";
                    }
                    else {
                        offerItem.text = materialPriceId ? offerItem.text : materialprice.text + ' ' + materialprice.offerMaterialPriceText;
                        offerItem.colorCode = (offerItem.colorCode != undefined || offerItem.colorCode != null) ? offerItem.colorCode : materialprice.colorCode;
                        offerItem.unit = materialprice.unit;
                        var offerReminderText = materialprice.offerMaterialPriceList[i].reminder ? materialprice.offerMaterialPriceList[i].reminder : "";
                    }

                    if (materialprice.offerMaterialPriceList[i].isDefault && materialPriceId == "") {
                        offerItem.materialPriceID = materialprice.offerMaterialPriceList[i].id;
                        offerItem.price = materialprice.offerMaterialPriceList[i].price;
                        var offerReminderText = materialprice.offerMaterialPriceList[i].reminder ? materialprice.offerMaterialPriceList[i].reminder : "";
                        //offerItem.text = materialprice.text + ' ' + materialprice.offerMaterialPriceList[i].paintText;
                        offerItem.text = materialprice.text + ' ' + (materialprice.offerMaterialPriceList[i].paintText != undefined ? materialprice.offerMaterialPriceList[i].paintText : "") + (materialprice.addNumberOfTimesOnPDF ? this.projectofferService.getPaintType(materialprice.offerMaterialPriceList[i].type) : "");
                        if (offerItem.materialList && offerItem.materialList.length > 0) {
                            offerItem.materialList.filter(m => {
                                if (m.id == materialId) {
                                    var tempPriceData = m.offerMaterialPriceList.filter(x => x.id == offerItem.materialPriceID);
                                    if (tempPriceData && tempPriceData.length > 0)
                                        m.offerMaterialPriceText = tempPriceData[0].paintText;
                                }
                            })
                        }
                    }
                    offerItem.originalPrice = offerItem.price;
                    if (offerReminderText && materialprice.reminder) {
                        offerItem.reminder = materialprice.reminder + " - " + offerReminderText;
                    } else if (offerReminderText && !materialprice.reminder) {
                        offerItem.reminder = offerReminderText;
                    } else {
                        offerItem.reminder = materialprice.reminder ? materialprice.reminder : "";
                    }
                }
            }
            if (materialprice.isPostNumberDiscount && this.projectOffer && this.projectOffer.percentage != 0 && !this.customerDetailObj.doNotCalculatePriceForPostNumber)
                offerItem.price = offerItem.price + ((offerItem.price * this.projectOffer.percentage) / 100);
            if (this.projectPriceDiscount && (this.customerDetailObj && !this.customerDetailObj.doNotCalculatePriceForPriceDiscount))
                offerItem.price = offerItem.price + (offerItem.price * this.projectPriceDiscount / 100);
            this.getOfferDiverseMeterPrice(offerItem);
            this.setOfferTextForNumberOfTimesOnPDF(materialId, offerItem)
        }
    }

    getMaterialPriceOnCategoryTimeSelect(priceId, materialId, offerItem) {
        this.getMaterialPrice(materialId, offerItem, priceId);
        if (priceId && offerItem && offerItem.materialList) {
            offerItem.materialList.filter(m => {
                if (m.id == materialId) {
                    var tempPriceData = m.offerMaterialPriceList.filter(x => x.id == priceId);
                    if (tempPriceData && tempPriceData.length > 0)
                        m.offerMaterialPriceText = tempPriceData[0].paintText;
                }
            })
        }
    }

    getOfferDiverseMeterPrice(offerItem) {
        var selectedMaterial = this.MaterialObject.find(o => o.id == offerItem.offerMaterialID);
        if (selectedMaterial) {
            var selectedPrice = selectedMaterial.offerMaterialPriceList.find(o => o.id == offerItem.materialPriceID);
            if (selectedPrice) {
                var price = selectedPrice.price;
                let timer = 0;
                if (selectedPrice.isAddMeterRangePrice) {
                    if (offerItem.materialPriceID) {
                        if (offerItem.materialQty >= selectedPrice.startMeter1 && offerItem.materialQty <= selectedPrice.endMeter1) {
                            price = selectedPrice.priceMeter1;
                            timer = selectedPrice.timeMeter1;
                        }
                        else if (offerItem.materialQty >= selectedPrice.startMeter2 && offerItem.materialQty <= selectedPrice.endMeter2) {
                            price = selectedPrice.priceMeter2;
                            timer = selectedPrice.timeMeter2;
                        }
                        else if (offerItem.materialQty >= selectedPrice.startMeter3 && offerItem.materialQty <= selectedPrice.endMeter3) {
                            price = selectedPrice.priceMeter3;
                            timer = selectedPrice.timeMeter3;
                        }
                        else {
                            price = 0; //selectedPrice.price;
                        }
                    }
                }
                else
                    timer = selectedPrice.timeMeter;
                offerItem.reminder = selectedPrice.reminder ? selectedPrice.reminder : "";
                offerItem.price = price;
                offerItem.originalPrice = offerItem.price;
                offerItem.timer = timer;
                if (selectedMaterial.isPostNumberDiscount && this.projectOffer && this.projectOffer.percentage != 0 && !this.customerDetailObj.doNotCalculatePriceForPostNumber)
                    offerItem.price = offerItem.price + ((offerItem.price * this.projectOffer.percentage) / 100);
                if (this.projectPriceDiscount && (this.customerDetailObj && !this.customerDetailObj.doNotCalculatePriceForPriceDiscount))
                    offerItem.price = offerItem.price + (offerItem.price * this.projectPriceDiscount / 100);
            }
            offerItem.total = offerItem.materialQty * offerItem.price;
        }
    }

    onChangeMaterialSelection(id, offer) {
        this.getMaterialPrice(id, offer, '');
    }

    GetArea(material, offerCategory) {
        var calculationValue = {
            baseline: material.baseline,
            height: material.height,
            area: material.area,
            offerCategory: offerCategory
        }
        const dialogRef = this.dialog.open(RoomAreaComponent, {
            // height: '30%',
            width: '40%',
            data: calculationValue
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                material.baseline = result.baseline;
                material.height = result.height;
                material.area = result.area;
                material.materialQty = material.area;
                if (material.materialQty)
                    material.isChecked = true;
                if (result.outdoorFileList && result.outdoorFileList.length > 0){
                    result.outdoorFileList[0].materialId = material.id;
                    material.outdoorFileList = result.outdoorFileList;
                }
            }
        });
    }
    copyMaterialItem(index, item) {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: "200px",
            data: { Header: 'Headers.CopyMaterial' }
        })
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                var offerItem = new OfferCategoryMaterial();
                offerItem = Object.assign({}, item)
                offerItem.isCopyMaterial = true;
                offerItem.roomList = [];
                offerItem.roomList = this.roomList.map(x => Object.assign({}, x));
                this.offerCategory.categoryMaterialList.push(offerItem);
            }
        });
    }

    addEditRooms() {
        const dialogRef = this.dialog.open(ProjectOfferRoomListComponent, {
            height: '80%',
            width: '80%',
        })
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
            }
        });
    }
    onRoomSelect(index, material, room) {
        if(material.treatmentType != 0){
            if (room.isChecked) {
                var message = "";
                this.translate.get("MESSAGE.RoomSelectionMsg").subscribe({
                    next: (res) => {
                        message = res.replace('@room', room.name);
                    }, error: err => { }
                })
                const dialogRef = this.dialog.open(AlertMessageModelComponent, {
                    height: "200px",
                    data: { Header: message }
                })
                dialogRef.afterClosed().subscribe(result => {
                    if (result) {
                        material.roomList.filter(x=>{
                            if(x.id != room.id)
                                x.isChecked = false;
                        })
                        material.length = room.length;
                        material.width = room.width;
                        material.height = room.ceilingHeight;
                        material.isChecked = true;
                        material.extraM2QtyWall = room.extraM2QtyWall;
                        material.extraM2QtyCeiling = room.extraM2QtyCeiling;
                        material.extraM2QtyFloor = room.extraM2QtyFloor;
                        material.extraM2PriceWall = room.extraM2PriceWall;
                        material.extraM2PriceCeiling = room.extraM2PriceCeiling;
                        material.extraM2PriceFloor = room.extraM2PriceFloor;                        
                        this.onCheckboxSelect(index,material.isChecked);
                    }
                    else
                        room.isChecked = false;
                });
            }
        }
    }

    calculateMaterialQty(index){
        var tempQty=0;
        if(this.offerCategory.categoryMaterialList[index].treatmentType && this.offerCategory.categoryMaterialList[index].treatmentType == this.treatmentTypeIds.WoodWork)
            tempQty = (this.offerCategory.categoryMaterialList[index].width + this.offerCategory.categoryMaterialList[index].length + this.offerCategory.categoryMaterialList[index].width + this.offerCategory.categoryMaterialList[index].length + this.offerCategory.categoryMaterialList[index].extraM2QtyFloor);
        if(this.offerCategory.categoryMaterialList[index].treatmentType && this.offerCategory.categoryMaterialList[index].treatmentType == this.treatmentTypeIds.Walls)
            tempQty = (((this.offerCategory.categoryMaterialList[index].width + this.offerCategory.categoryMaterialList[index].length) * 2) * this.offerCategory.categoryMaterialList[index].height + this.offerCategory.categoryMaterialList[index].extraM2QtyWall);
        if(this.offerCategory.categoryMaterialList[index].treatmentType && this.offerCategory.categoryMaterialList[index].treatmentType == this.treatmentTypeIds.Ceiling)
            tempQty = (this.offerCategory.categoryMaterialList[index].width * this.offerCategory.categoryMaterialList[index].length + this.offerCategory.categoryMaterialList[index].extraM2QtyCeiling);
        if(this.offerCategory.categoryMaterialList[index].treatmentType == 0)                
            tempQty = (this.offerCategory.categoryMaterialList[index].width * this.offerCategory.categoryMaterialList[index].length);
        this.offerCategory.categoryMaterialList[index].materialQty = tempQty;
        this.offerCategory.categoryMaterialList[index].total = (this.offerCategory.categoryMaterialList[index].price * this.offerCategory.categoryMaterialList[index].materialQty);
        this.getOfferDiverseMeterPrice(this.offerCategory.categoryMaterialList[index]);
    }
    gotoRoomTab() {
        const dialogRef = this.dialog.open(ProjectOfferRoomListComponent, {
            height: "90%",
            width: "90%",
            data: { OfferId: this.offerId },
            disableClose: true
        })
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.roomList = result;
                this.offerCategory.categoryMaterialList.filter((o, index) => {
                    var selectedRoomID = o.roomList.filter(p => p.isChecked == true);
                    o.roomList = this.roomList.map(x => Object.assign({}, x));
                    if (selectedRoomID) {
                        o.roomList.filter(x => {
                            var selectroom = selectedRoomID.find(u => u.id == x.id);
                            if (selectroom) {
                                o.length = x.length;
                                o.width = x.width;
                                o.height = x.ceilingHeight;
                                o.extraM2QtyFloor = x.extraM2QtyFloor;
                                o.extraM2QtyWall = x.extraM2QtyWall;
                                o.extraM2QtyCeiling = x.extraM2QtyCeiling;
                                x.isChecked = true;
                                this.calculateMaterialQty(index);
                            }
                        })
                    }
                });
            }
        });
        //this.dialogRef.close("")
    }

    onShowAndetSelect(){
        this.offerCategory.categoryMaterialList.filter(x => {
            if (x.materialTypeID && x.materialTypeID == 27)
                x.showAndet = this.showAndet;
            else
                x.showAndet = true;
        });
    }

    deleteMaterialItem(index, item) {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: "200px",
            data: { Header: 'Headers.DeleteDiverseMaterial' }
        })

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.offerCategory.categoryMaterialList.splice(index, 1);
            }
        });

    }

    copyAllMaterialItem(index, item) {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: "200px",
            data: { Header: 'Headers.CopyMaterialForAllRooms' }
        })
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                if(item.roomList && item.roomList.length > 0){
                    var tempRoomSelected = item.roomList.filter(c => c.isChecked);
                    if(tempRoomSelected.length > 0 ){
                        item.roomList.filter((room, i) => {
                            if(!room.isChecked){
                                var offerItem = new OfferCategoryMaterial();
                                offerItem = Object.assign({}, item)
                                offerItem.isCopyMaterial = true;
                                offerItem.materialQty = 0;
                                offerItem.total = 0;
                                offerItem.isChecked = false;
                                offerItem.roomList = [];
                                offerItem.roomList = this.roomList.map(y => Object.assign({}, y));
                                offerItem.roomList[i].isChecked = true;
                                var tempIndex = this.offerCategory.categoryMaterialList.push(offerItem);
                                this.onRoomSelectForAllRooms(tempIndex-1 , offerItem, offerItem.roomList[i]);
                            }
                            else {
                                item.roomList[i].isChecked = true;
                                this.onRoomSelectForAllRooms(index, item, item.roomList[i]);
                            }
                        })
                    }
                    else if(tempRoomSelected.length == 0){
                        item.roomList.filter((room, i) => {
                            if(!room.isChecked){
                                var offerItem = new OfferCategoryMaterial();
                                offerItem = Object.assign({}, item)
                                offerItem.isCopyMaterial = true;
                                offerItem.materialQty = 0;
                                offerItem.isChecked = false;
                                offerItem.total = 0;
                                offerItem.roomList = [];
                                offerItem.roomList = this.roomList.map(y => Object.assign({}, y));
                                if(i != 0) {
                                    offerItem.roomList[i].isChecked = true;
                                    var tempIndex = this.offerCategory.categoryMaterialList.push(offerItem);
                                    this.onRoomSelectForAllRooms(tempIndex-1 , offerItem, offerItem.roomList[i]);
                                }
                                else {
                                    item.roomList[i].isChecked = true;
                                    this.onRoomSelectForAllRooms(index, item, item.roomList[i]);
                                }
                            }
                        })
                    }
                }
                
            }
        });
    }
    onRoomSelectForAllRooms(index, material, room) {
        if(material.treatmentType != 0){
            if (room.isChecked) {
                material.roomList.filter(x=>{
                    if(x.id != room.id)
                        x.isChecked = false;
                })
                material.length = room.length;
                material.width = room.width;
                material.height = room.ceilingHeight;
                material.isChecked = true;
                material.extraM2QtyWall = room.extraM2QtyWall;
                material.extraM2QtyCeiling = room.extraM2QtyCeiling;
                material.extraM2QtyFloor = room.extraM2QtyFloor;
                material.extraM2PriceWall = room.extraM2PriceWall;
                material.extraM2PriceCeiling = room.extraM2PriceCeiling;
                material.extraM2PriceFloor = room.extraM2PriceFloor;    
                this.onCheckboxSelect(index,material.isChecked);
            }
        }
    }
    setOfferTextForNumberOfTimesOnPDF(materialId, offerItem) {
        var materialprice = this.MaterialObject.find(o => o.id == materialId);
        if (materialprice) {
            var offerMaterialDetail = materialprice.offerMaterialPriceList.find(x => x.id == offerItem.materialPriceID)
            if (materialprice.addNumberOfTimesOnPDF && offerMaterialDetail) {
                offerItem.text = materialprice.text + ' ' + offerMaterialDetail.paintText + ' ' + this.projectofferService.getPaintType(offerMaterialDetail.type);
            }
        }
    }

    showExtraQuantity(material,room){
        room.isAddExtraQty = !room.isAddExtraQty
        material.roomDetails = room;
        material.roomList.filter(r =>{
            if(r.id == room.id)
                r.isAddExtraQty = room.isAddExtraQty
            else
                r.isAddExtraQty = false;
        })
        var tempRoomList = material.roomList.filter(r=>r.isAddExtraQty==true);
        if(tempRoomList && tempRoomList.length>0)
            material.isAddExtraQty=true;
        else
            material.isAddExtraQty=false;
        // this.room = room;
    }

    getM2ForMaterial(material,index) {
        // material.width = 0;
        // material.length = 0;
        // material.height = 0;
        //material.materialQty = this.room.extraM2QtyWall + this.room.extraM2QtyCeiling + this.room.extraM2QtyFloor;
        material.extraM2QtyWall = material.roomDetails.extraM2QtyWall;
        material.extraM2QtyCeiling = material.roomDetails.extraM2QtyCeiling;
        material.extraM2QtyFloor = material.roomDetails.extraM2QtyFloor;   
        material.extraM2PriceWall = material.roomDetails.extraM2PriceWall;
        material.extraM2PriceCeiling = material.roomDetails.extraM2PriceCeiling;
        material.extraM2PriceFloor = material.roomDetails.extraM2PriceFloor;
        this.calculateMaterialQty(index);      
    }

    copySelectedMaterialItem(index, item) {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: "200px",
            data: { Header: 'Headers.CopyMaterialForSelectedRooms' }
        })
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                if(item.roomList && item.roomList.length > 0){
                    var tempRoomSelected = item.roomList.filter(c => c.isChecked);
                    if(tempRoomSelected.length > 0 ){
                        item.roomList.filter((room, i) => {
                            if(room.isChecked){
                                var offerItem = new OfferCategoryMaterial();
                                offerItem = Object.assign({}, item)
                                offerItem.isCopyMaterial = true;
                                offerItem.materialQty = 0;
                                offerItem.roomList = [];
                                offerItem.roomList = this.roomList.map(y => Object.assign({}, y));
                                if(tempRoomSelected[0].id != room.id) {
                                    offerItem.roomList[i].isChecked = true;
                                    item.roomList[i].isChecked = false;
                                    var tempIndex = this.offerCategory.categoryMaterialList.push(offerItem);
                                    this.onRoomSelectForAllRooms(tempIndex-1 , offerItem, offerItem.roomList[i]);
                                }
                            }
                        })
                    }
                }
                
            }
        });
    }

    getOfferItemTimer(offerItem) {
        var selectedMaterial = this.MaterialObject.find(o => o.id == offerItem.offerMaterialID);
        if (selectedMaterial) {
            var selectedPrice = selectedMaterial.offerMaterialPriceList.find(o => o.id == offerItem.materialPriceID);
            if (selectedPrice) {
                let timer = 0;
                if (selectedPrice.isAddMeterRangePrice) {
                    if (offerItem.materialPriceID) {
                        if (offerItem.materialQty >= selectedPrice.startMeter1 && offerItem.materialQty <= selectedPrice.endMeter1)
                            timer = selectedPrice.timeMeter1;
                        else if (offerItem.materialQty >= selectedPrice.startMeter2 && offerItem.materialQty <= selectedPrice.endMeter2) 
                            timer = selectedPrice.timeMeter2;
                        else if (offerItem.materialQty >= selectedPrice.startMeter3 && offerItem.materialQty <= selectedPrice.endMeter3) 
                            timer = selectedPrice.timeMeter3;
                    }
                }
                else
                    timer = selectedPrice.timeMeter;
                offerItem.timer = timer;
            }
        }
    }

    gotoMaterial(id) {
        const matObj = this.MaterialObject.find(x => x.id == id);
        if (matObj) {
            const dialogRef = this.dialog.open(NewMaterialComponent, {
                height: '800px',
                width: '80%',
                data: matObj["materialID"],
            });
            dialogRef.afterClosed().subscribe(result => {});
        }
    }

    _handleKeydown(event: KeyboardEvent) {
        if (event.keyCode === 13) {
            event.stopPropagation();
        }
    }
}