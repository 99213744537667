export const locale = {
  lang: 'en',
  data: {
    'FILTERS': {
      'TEXTSEARCH': 'Search By Name,Email,Phone,Address',
    },
    'FIELDS': {
        'CustomerName': 'Daily sales customers name',
      'Not-Found-Customer': 'No customer found',
    },
    'BUTTONS': {
      'StaffPurchaseCash': 'Staff purchase case',
      'StoredPurchase': 'Store purchase',
      'DailySales': 'Daily sales',
      },
      'MESSAGES': {
          'Success':'Success',
      }
  }
};
  