export const locale = {
    lang: 'en',
    data: {
        'STATUS': {
            'Created': 'Created',
            'Updated': 'Updated',
            'Deleted': 'Deleted',
            'FileUploaded': 'File Uploaded',
            'FileDeleted': 'File Deleted',
            'Email': 'Email',
        },
        'TITLE': {
            'CustomerPagination': 'Number of materials per page',
        },
        'BUTTONS': {
            'BACK': 'Cancel',
            'BTNSAVE': 'Save',
            'Add': 'Add',
        },
        'FIELDS': {
            'SRNO': 'Sr No',
            'Status': 'Status',
            'Number': 'Number',
            'Name': 'Name',
            'Createdby': 'Created By',
            'CreatedDate': 'Created Date',
            'ModifyBy': 'Modify By',
            'ModifyDate': 'Modify Date',
            'CoworkerName': 'Coworker',
            'Date': 'Date',
            'Description': 'Description',
            'Action': 'Action',
            "Materialtype": 'Material type',
            'All': 'All',
            'Material': 'Material',
        },
        'Headers': {
        },

        'MESSAGES': {
            'NORECORD': 'No Record Found',
            'MaterialAdded': 'Customer Material Added.',
            'MaterialAddFailed': 'Operation failed , try later.',
            'DeleteAlert': 'Are you sure you want to delete?',
            'MaterialDeleted': 'Customer Material Deleted.',
            'MaterialDeleteFailed': 'Operation failed , try later.',
            'MaterialAlreadyAdded': 'Material already added',
        },
        'TOOTIPORTITLE':{
            'Edit':'Edit',
            'Delete':'Delete',
            'IsActive':'Is Active',
            'IsDeleted':'Is Deleted',
        }
    }
};


