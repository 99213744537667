import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { CompanyInfoSetting, CompanyInfoSettingTab} from 'app/main/model/CompanyInfo';
import { CompanyInfoService } from 'app/main/pages/companyInfo/companyInfo.service';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';

import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { AuthService } from 'app/core/services/auth.service';
@Component({
  selector: 'app-company-info-settings',
  templateUrl: './company-info-settings.component.html',
  styleUrls: ['./company-info-settings.component.scss']

})
export class CompanyInfoSettingsComponent implements OnInit {
  tabName:string="";
  showAddTab:boolean=false;
  roleClaims: any;
  constructor(public dialogRef: MatDialogRef<CompanyInfoSettingsComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public companyInfoService: CompanyInfoService,
    private translate: TranslateService,
    private authService: AuthService,
    private notificationsService: NotificationsService,
    private translationLoader: FuseTranslationLoaderService) {
    this.translationLoader.loadTranslations(english, danish);
    this.roleClaims = this.authService.getRoleClaimsList();
  }

  comapnyInfoSettingTabs: any[];
  name: string = '';

  ngOnInit() {

    this.comapnyInfoSettingTabs = new Array();
    this.data.filter(c => {
      c.showTextBox = false;
      c.hideEdit = false;
      this.comapnyInfoSettingTabs.push(c);
    })
  }

  onCancelClose() {
    this.dialogRef.close(this.comapnyInfoSettingTabs)
  }

  onChangeTabSettings(objCurrenttab, isHidden) {
    objCurrenttab.isHidden = isHidden.checked;
    this.companyInfoService.UpdateCompanyInfoSettings<ResponseBase>(objCurrenttab)
      .subscribe({
        next: (response: ResponseBase) => {
        },
        error: err => {
        },
        complete: () => {
        }
    });
  }

  RenameTab(renamingTab) {
    this.comapnyInfoSettingTabs.filter(x => {
      if (x.id == renamingTab.id) {
        renamingTab.showTextBox = true;
        renamingTab.hideEdit  = true;
      }
    })
  }

  saveTabName(renamingTab) {
    this.comapnyInfoSettingTabs.filter(x => {
      if (x.id == renamingTab.id) {
        renamingTab.showTextBox = false;
        renamingTab.hideEdit = false;
        this.companyInfoService.UpdateCompanyInfoSettings<ResponseBase>(renamingTab)
          .subscribe({
            next: (response: ResponseBase) => {
              //showTextBox
            },
            error: err => {
            },
            complete: () => {
            }
          });
      }
    })
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.comapnyInfoSettingTabs, event.previousIndex, event.currentIndex);
  }

  saveTabSettingList() {
    this.companyInfoService.UpdateCompanyInfoSettingList<ResponseBase>(this.comapnyInfoSettingTabs)
      .subscribe({
        next: (response: ResponseBase) => {
          if(response.result){
            this.dialogRef.close(response.result.myResponse);
          }
        },
        error: err => {
        },
        complete: () => {
        }
      });
  }
  addNewTabForm(){
    this.showAddTab  =! this.showAddTab;
  }
  cancelNewTabForm(){
    this.showAddTab=false;
    this.tabName="";
  }
  saveTabSettings() {
    var companyInfoSettingDTO = new CompanyInfoSettingTab();
    companyInfoSettingDTO.Name = this.tabName;
    companyInfoSettingDTO.DisplayOrder= this.comapnyInfoSettingTabs.length + 1;
    this.companyInfoService.addCompanyInfoSettingTab<ResponseBase>(companyInfoSettingDTO)
      .subscribe({
        next: (response: ResponseBase) => {
          if(response.result){
            this.cancelNewTabForm();
            response.result.showTextBox = false;
            response.result.hideEdit  = false;
            this.comapnyInfoSettingTabs.push(response.result);
          }
        },
        error: err => {
        },
        complete: () => {
        }
    });
  }

  deleteTabConfirm(index, item){
    const dialogRef = this.dialog.open(AlertMessageModelComponent, {
      height: '20%',
      data: { Header: 'Headers.DeleteCompanyInfoTab' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
          this.DeleteCompanyInfoTab(index,item);
      }
    });
  }

  DeleteCompanyInfoTab(index,item) {
    this.companyInfoService.deleteCompanyInfoTab<ResponseBase>(item.id).subscribe({
        next: (response: ResponseBase) => {
          if(response.result){
            this.comapnyInfoSettingTabs.splice(index, 1);
            this.translate.get("MESSAGES.Deleted").subscribe({
                next: (res) => {
                    this.notificationsService.create(res);
                },
                error: err => { }
            });
          }
        },
        error: err => {
            this.translate.get("MESSAGES.Failed").subscribe({
                next: (res) => {
                    this.notificationsService.error(res);
                },
                error: err => { }
            });
        },
        complete: () => {}
    })
}
}
