export const locale = {
    lang: 'en',
    data: {
        "Headers": {
            'Yes': 'Yes',
            'No': 'No',
            'AllRequisitionCoworker': 'Are you sure you want to do this on all tasks?',
        },
        'FIELDS': {
            'ChangeStatus': "Change Staus",
            'ChangedStatus': "Changed Status",
            'To': 'to',
            'EmptyEconomyFields': '--> Economy fields are not filled.'
        }
    }
};
