import { Component, OnInit, Inject } from '@angular/core';
import { FuseTranslationLoaderService } from '../../../core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-delete-alert-modal',
  templateUrl: './delete-alert-modal.component.html',
  styles: []
})
export class DeleteAlertModalComponent implements OnInit {
  deleteAll: boolean = false;
  constructor(private translationLoader: FuseTranslationLoaderService,
    public dialogRef: MatDialogRef<DeleteAlertModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.translationLoader.loadTranslations(english, danish);

  }

  ngOnInit() {
    var del = this.data.deleteAll;
    if (del) {
      this.deleteAll = true;
    }
  }

  onCloseDialog(e) {
    this.dialogRef.close(e ? this.data : false)
  }

}

