export const locale = {
    lang: 'ds',
    data: {
        'TITLE': {
            'EmailTemplate':'E-mail skabelon til alle moduler'
        },
        'FIELDS': {
        },
        'Message': {

        },
        'FILTERS': {
        },
        'moduleTypeList': {
        },
        'BUTTONS': {
            'Save':'Gem',
        }
    }
};