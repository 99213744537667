import { Component, OnInit } from "@angular/core";
import { SupplierRequest } from 'app/main/model/RequestBase';
import { Supplier } from 'app/main/model/Supplier';
import { SuppliersMaterialPriceChanges } from 'app/main/model/Material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { TitleService } from 'app/core/services/title.service';
import { MaterialService } from 'app/main/pages/material/material.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { FormService } from 'app/core/services/form.service';

import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { ResponseBase } from 'app/main/model/ResponseBase';
import { Router } from '@angular/router';

@Component({
    selector: "add-supplier-setting",
    templateUrl: "./add-supplier-setting.component.html",
    styleUrls: ["./add-supplier-setting.component.scss"]
})

export class AddSupplierSettingComponent implements OnInit {
    showSpinner: boolean = false;
    supplierRequest: SupplierRequest;
    supplierList: Supplier[] = [];
    suppliersMaterialPriceChangesObj: SuppliersMaterialPriceChanges = new SuppliersMaterialPriceChanges();
    SupplierMaterialPricForm: FormGroup
    SupplierPriceChangesList: [] = [];
    constructor(private translationLoader: FuseTranslationLoaderService,
        private titleService: TitleService,
        private materialService: MaterialService,
        private translate: TranslateService,
        private notificationsService: NotificationsService,
        private formBuilder: FormBuilder,
        private formService: FormService,
        private router: Router) {
        this.translationLoader.loadTranslations(english, danish);
        this.titleService.SetTitle("TITLE_SUPPLIER_SETTINGS");
    }

    ngOnInit() {
        this.supplierRequest = new SupplierRequest();
        this.supplierRequest.Index = 1;
        this.supplierRequest.Limit = 100;
        this.GetSupplierList();
        this.SupplierMaterialPricForm = this.formBuilder.group({
            SupplierId: [null, Validators.required],
            Percentage: [null, Validators.required]
        });
    }

    GetSupplierList() {
        this.materialService.GetSupplierList<ResponseBase>(this.supplierRequest)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.supplierList = response.result;
                },
                error: err => { },
                complete: () => { }
            });
    }

    save() {
        this.formService.markFormGroupTouched(this.SupplierMaterialPricForm);
        if (this.SupplierMaterialPricForm.invalid) {
            return;
        }
        this.showSpinner = true;
        this.materialService.AddSupplierMaterialPriceChanges<ResponseBase>(this.suppliersMaterialPriceChangesObj)
            .subscribe({
                next: (response: ResponseBase) => {
                    if (response) {
                        this.showSpinner = false;
                        this.router.navigate(["/materials/suppliersetting"]);
                        this.translate.get("MESSAGES.Added").subscribe({
                            next: (res) => {
                                this.notificationsService.create(res);
                            },
                            error: err => {
                            }
                        });
                    }
                },
                error: err => {
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });
                },
                complete: () => {
                    this.showSpinner = false;
                }
            });
    }
}