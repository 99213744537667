import { Component, OnDestroy, OnInit } from '@angular/core';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { DatePipe } from '@angular/common';

import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';

import { DashboardService } from './dashboard.service';
import { ProjectService } from 'app/main/pages/project/project.service'
import { AuthService } from 'app/core/services/auth.service';
import { ReminderService } from 'app/main/pages/reminder/reminder.service';
import { RequestCoWorker, RequestStatusProject } from 'app/main/model/RequestBase';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { Reminder } from 'app/main/model/Reminder';
import { TitleService } from 'app/core/services/title.service';
import * as moment from 'moment';
import { MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
//import { ReminderEditDialog } from '../reminder/reminder.component';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { SettingsService } from 'app/main/pages/settings/settings.service';
import { AnswerCategoryQuestionComponent } from 'app/main/shared-component/answer-category-question/answer-category-question.component';
import { CoWorkersService } from '../coWorker/coWorkers.service';

@Component({
  selector: 'vibe-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class VibeDashboardComponent implements OnInit, OnDestroy {

  Reminders: Reminder[] = [];
  Projects: any[];
  // isValid: boolean = false;
  // roleName: string;
  showSpinner: boolean = false;
  Count: number = 0;
  After5DayDate: string = "";
  TodayDate: string = "";
  pagination: any;
  ComeFrom = "dashboard";
  findCoworkerReminderList: Reminder[] = [];
  startDate: any;
  endDate: any;
  isActive: boolean = false;
  showPagination = {
    top: false,
    bottom: true
  }
  filterValues = {
    text: "",
    CoworkerId: "",
    StartDate: null,
    EndDate: null,
    orderBy: "DATE DESC",
    Limit: 100
  }
  navigation: any;
  coworkerReviewQuestionList : any = [];
  filterCoworkerReviewQuestionList : any = [];
  reviewQuestionID: any = "";
  coWokers: any = [];

  constructor(private translationLoader: FuseTranslationLoaderService,
    private _fuseNavigationService: FuseNavigationService,
    private _fuseSidebarService: FuseSidebarService,
    private dashboardService: DashboardService,
    private projectservice: ProjectService,
    private _route: ActivatedRoute,
    private _router: Router,
    private coWorkersService: CoWorkersService,
    private authService: AuthService,
    private datePipe: DatePipe,
    private reminderService: ReminderService,
    private titleService: TitleService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private settingsService: SettingsService,
    private notificationsService: NotificationsService) {
    this.translationLoader.loadTranslations(english, danish);
    this.titleService.SetTitle("TITLE_DASHBOARD");
    this.titleService.SetAddress("");
    // this.roleName = this.authService.getUserRole();
    // if (this.roleName == "Admin") {
    //   this.isValid = true;
    // }
    // else if (this.roleName == "Kontor") {
    //   this.isValid = true;
    // }
    // else if (this.roleName == "Maler") {
    //   this.isValid = true;
    // }
    // else if (this.roleName == "Gaest") {
    //   this.isValid = true;
    // }
    // else {
    //   this.isValid = true;
    // }

  }

  ngOnInit() {
    this._fuseSidebarService.getSidebar('navbar').fold();
    this.navigation = this.navigation || this._fuseNavigationService.getCurrentNavigation();
    //this.getProjects(new RequestStatusProject());
    //this.CreateReminderRequest(null);
    this.TodayDate = moment(new Date()).format('YYYY-MM-DD');
    this.After5DayDate = moment(this.TodayDate, "YYYY-MM-DD").add(5, 'days').format('YYYY-MM-DD');
    this.GetInquiryCount();
    //this.GetNotDoneReminderCounts();
    this.startDate = moment().format('DD/MM/YYYY');
    this.endDate = moment(moment().add(5, 'days')).format('DD/MM/YYYY');  

    this.filterValues.StartDate = this.TodayDate;
    this.filterValues.EndDate = this.After5DayDate;
    this.GetCoworkerReviewQuestionNotAnswer();
    // this._route.queryParams.subscribe(params => {
    //   this.reviewQuestionID = params["reviewQuestionID"] ? params["reviewQuestionID"]: "";
    // });

    let coWorker = new RequestCoWorker();
    coWorker.IsDeleted = false;
    var tempList = this.coWorkersService.getResponseForCoworker();
    if (tempList.length == 0)
      this.getCoWorkers(coWorker);
        
  }

  getProjects(model: RequestStatusProject) {
    this.showSpinner = true;
    model.includeAll = false;
    model.isLift = false;
    model.isRiseman = false;
    //this.roleName = this.authService.getUserRole();
    //if (this.roleName == "Admin") {
    //  model.includeAll = true;
    //}
    //else {
    //  model.includeAll = false;
    //}
    model.IsDeleted = false;
    model.CoworkerId = this.authService.getCoWorkerId();
    model.isLift = false;
    model.isRiseman = false;
    //this.showSpinner = true;
    this.projectservice.Get<ResponseBase>(model)
      .subscribe((response: ResponseBase) => {
        if (response) {
          this.Projects = response.result;
          this.projectservice.setBGImageForProjectCards(this.Projects);
          for (var i = 0; i < this.Projects.length; i++) {
            var formateDate = moment(this.Projects[i].startDate).format('YYYY-MM-DD');
            if (this.After5DayDate >= formateDate)
              this.Projects[i].displayStartDateRed = true;
            else
              this.Projects[i].displayStartDateRed = false;
          }
          //this.Projects.filter(p => {
          //  ProjectStatus.filter(ps => {
          //    if (ps.id == p.statusId) {
          //      p.status = ps.key
          //    }
          //  })
          //})
          this.showSpinner = false;
          //this.pagination = response.pagination;
          //this.displayPagination = true;
          //this.showSpinner = false;
        }
        else {
          this.Projects = [];
          this.showSpinner = false;
          //this.displayPagination = false;
          //this.showSpinner = false;
        }
      },
        error => (err) => {
          this.Projects = [];
          this.showSpinner = false;
          //this.displayPagination = false;
          //this.showSpinner = false;
        },
        () => {
        });
  }

  GetInquiryCount() {
    this.reminderService.GetInquiryCountapi<ResponseBase>()
      .subscribe({
        next: (response: ResponseBase) => {
          if (response != null) {
            //this.reminderService.setInquiryCounts(response.result);
            this.authService.setInquiryCount(response.result);
          }
        },
        error: err => {
        },
        complete: (
        ) => {
        }
      })
  }

  //Not Done Reminder 
  GetNotDoneReminderCounts() {
    this.reminderService.GetNotDoneReminderCountApi<ResponseBase>(this.authService.getCoWorkerId())
      .subscribe({
        next: (response: ResponseBase) => {
          if (response != null) {

            //this.reminderService.setNotDoneReminderCount(response.result);
            this.authService.setNotDoneReminderCount(response.result);
          }
        },
        error: err => {
        },
        complete: (
        ) => {
        }
      });
  }

  onSelectStartDate(selectedDate) {
    let FilterStartDate = selectedDate ? this.datePipe.transform(new Date(selectedDate), "yyyy-MM-dd") : null;
       
    if(this.filterValues.StartDate != FilterStartDate) {
      this.filterValues.StartDate = FilterStartDate;
    }
  }

  onSelectEndDate(selectedDate) {
    let FilterEndDate = selectedDate ? this.datePipe.transform(new Date(selectedDate), "yyyy-MM-dd") : null;
       
    if(this.filterValues.EndDate != FilterEndDate) {
      this.filterValues.EndDate = FilterEndDate;
    }
  }
  
  onFocusIn(event) {
    event.model.show();
  }

  goToRequisitionTab(event) {
    var id = event.sagId;
    this._router.navigate(['/projects/edit', id], { queryParams: { isActive: this.isActive, tabIndex: 1 } });
  }
  goToRequisitionMaterialTab(event) {
    var id = event.sagId;
    this._router.navigate(['/projects/edit', id], { queryParams: { isActive: this.isActive, tabIndex: 1, reqTabIndex: 3 } });
  }

  EditProject(event) {
    var id = event.sagId;
    this._router.navigate(['/projects/edit', id]); //navigationExtras
  }

  ReminderForm() {
    this._router.navigate(["/reminder/add"]);
  }
    VehicleInfoForm() {
        this._router.navigate(["/dashboard/livemap"]);
    }

  truncDecimalpoint(value) {
    return Math.trunc(value)
  }

  // getReminder(model: RequestReminder) {
  //   model.ReminderDate = this.datePipe.transform(model.ReminderDate, "yyyy-MM-dd");
  //   model.CoworkerId = this.authService.getCoWorkerId();
  //   model.IsDeleted = false;
  //   model.Status = false;
  //   this.reminderService.Get<ResponseBase>(model)
  //     .subscribe({
  //       next: (response: ResponseBase) => {
  //         if (response != null) {
  //           this.Reminders = response.result;
  //           //this.reminderService.totalReminderCount(this.Reminders);
  //           this.authService.setReminderCount(this.Reminders.length);
  //         }
  //         else {
  //           this.Reminders = [];
  //           //this.reminderService.totalReminderCount(this.Reminders);
  //           this.authService.setReminderCount(0);
  //         }
  //       },
  //       error: err => {
  //         this.Reminders = [];
  //       },
  //       complete: (
  //       ) => {
  //       }
  //     })
  // }

  // sendByMeReminder(model: RequestReminder) {
  //   //this.showSpinner = true;
  //   //var model = new RequestReminder();
  //   //model.CoworkerId = "";
  //   model.ReminderDate = model.ReminderDate ? this.datePipe.transform(model.ReminderDate, "yyyy-MM-dd") : this.startDate;
  //   model.CreatedBy = this.authService.getCoWorkerId();
  //   model.IsDeleted = false;
  //   model.getAll = model.getAll ? model.getAll : false;
  //   model.Index = 1;
  //   model.Limit = 500;
  //   this.reminderService.Get<ResponseBase>(model)
  //     .subscribe({
  //       next: (response: ResponseBase) => {

  //         // var todayRemider = response.result.filter(o => this.datePipe.transform(o.reminderDate, "yyyy-MM-dd") == this.datePipe.transform(new Date(), "yyyy-MM-dd"));
  //         // //this.reminderService.totalReminderCount(todayRemider);
  //         // if (todayRemider) {
  //         //   this.authService.setReminderCount(todayRemider.length);
  //         // }
  //         // else {
  //         //   this.authService.setReminderCount(0);
  //         // }
  //         if (response != null) {
  //           this.findCoworkerReminderList = response.result;
  //           this.pagination = response.pagination;
  //         }
  //         else {
  //           this.findCoworkerReminderList = [];
  //         }
  //         //this.showSpinner = false;
  //       },
  //       error: err => {
  //         this.findCoworkerReminderList = [];
  //         //this.showSpinner = false;
  //       },
  //       complete: () => {
  //       }
  //     })
  // }

  // openFileDeleteDialog(objReminder) {
  //   //this.showSpinner = true;
  //   this.reminderService.Delete<ResponseBase>(objReminder.id)
  //     .subscribe({
  //       next: (response: ResponseBase) => {
  //         this.Reminders.splice(this.Reminders.findIndex(r => r.id == objReminder.id), 1);
  //         //this.Reminders = this.Reminders;
  //         //this.showSpinner = false;
  //         var model = new RequestReminder();
  //         model.ReminderDate = this.datePipe.transform(this.Reminder, "yyyy-MM-dd");
  //         this.sendByMeReminder(model);
  //       },
  //       error: err => {
  //         //this.showSpinner = false;
  //       },
  //       complete: () => {
  //         //this.showSpinner = false;
  //       }
  //     });
  // }

  // openFileEditDialog(value) {
  //   var data = value.data;
  //   let objData = Object.assign({}, data)
  //   if (data.reminderDate) {
  //     objData.reminderTime = moment.utc(objData.reminderDate).format("HH:mm") == "00:00" ? "" : moment.utc(objData.reminderDate).format("HH:mm");
  //     objData.reminderDate = moment.utc(objData.reminderDate).format("YYYY-MM-DD");
  //   }
  //   const dialogRef = this.dialog.open(VibeEditReminderComponent, {
  //     height: '400px',
  //     width: '800px',
  //     data: objData,
  //     autoFocus: false
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result) {
  //       //var reminder = this.Reminders.find(r => r.id == result.id);
  //       var sendByFindedReminder = this.findCoworkerReminderList.find(r => r.id == result.id);
  //       if (sendByFindedReminder) {
  //         sendByFindedReminder.text = result.text;
  //       }
  //       //if (reminder) {
  //       var objUpdate = new Reminder();
  //       objUpdate.coWorkerId = result.coWorkerId;
  //       objUpdate.reminderDate = result.reminderDate;
  //       objUpdate.text = result.text;
  //       objUpdate.replay = result.replay;
  //       objUpdate.reminderStatus = result.reminderStatus;
  //       this.updateReminder(result.id, objUpdate);
  //       //reminder.text = result.text;
  //       //}
  //     }
  //   });
  // }

  // getReminderNext(reminder) {
  //   let date = new Date(reminder).setDate(new Date(reminder).getDate() + 1);
  //   this.Reminder = this.datePipe.transform(new Date(date), "yyyy-MM-dd");
  //   this.CreateReminderRequest(new Date(date).toString());
  // }


  // getReminderPrev(reminder) {
  //   let date = new Date(reminder).setDate(new Date(reminder).getDate() - 1);
  //   this.Reminder = this.datePipe.transform(new Date(date), "yyyy-MM-dd");
  //   this.CreateReminderRequest(new Date(date).toString());
  // }




  // ChangedStatus(event) {
  //   var id = event.id;
  //   var status = event.status;
  //   this.ChangeReminderStatus(id, status);
  // }

  // ChangeReminderStatus(id: any, value: boolean) {
  //   this.reminderService.ChangeReminderStatus<ResponseBase>(id, value)
  //     .subscribe({
  //       next: (response: ResponseBase) => {
  //         this.Reminders = this.Reminders.filter(o => o.id != response.result.id);
  //         //this.reminderService.totalReminderCount(this.Reminders);
  //         this.authService.setReminderCount(this.Reminders.length);
  //         this.GetNotDoneReminderCounts();
  //         this.getSuccessTone();
  //       },
  //       error: err => { },
  //       complete: (
  //       ) => {
  //         //this._router.navigateByUrl('materials');
  //       }
  //     });
  // }

  // editReplay(data) {
  //   let objData = this.Reminders.find(o => o.id == data.id);
  //   const dialogRef = this.dialog.open(ReminderReplayDialog, {
  //     height: '50%',
  //     width: '60%',
  //     data: objData,
  //     autoFocus: false
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result) {
  //       var reminder = this.Reminders.find(r => r.id == result.id);
  //       if (reminder) {
  //         var objUpdate = new Reminder();
  //         objUpdate.coWorkerId = result.coWorkerId;
  //         objUpdate.reminderDate = result.reminderDate;
  //         objUpdate.text = result.text;
  //         objUpdate.replay = result.replay;
  //         objUpdate.reminderStatus = result.reminderStatus;
  //         this.updateReminder(reminder.id, objUpdate);
  //         reminder.text = result.text;
  //         var sendByFindedReminder = this.findCoworkerReminderList.find(r => r.id == result.id);
  //         if (sendByFindedReminder) {
  //           sendByFindedReminder.replay = result.replay;
  //         }
  //       }
  //     }
  //   });
  // }

  // updateReminder(id, reminder) {
  //   //this.showSpinner = true;
  //   reminder.reminderDate = moment.utc(reminder.reminderDate).format("YYYY-MM-DD HH:mm");
  //   this.reminderService.Update<ResponseBase>(id, reminder)
  //     .subscribe({
  //       next: (response: ResponseBase) => {
  //         //this.showSpinner = false;
  //         var dateValue = new Date().toString();
  //         var requestReminder = new RequestReminder();
  //         requestReminder.ReminderDate = dateValue;
  //         this.getReminder(requestReminder);
  //         this.translate.get("MESSAGES.Updated").subscribe({
  //           next: (res) => {
  //             this.notificationsService.success(res);
  //           },
  //           error: err => {
  //           }
  //         });
  //       },
  //       error: err => {
  //         //this.showSpinner = false;
  //       },
  //       complete: () => {
  //         //this.showSpinner = false;
  //       }
  //     });
  // }

  getSuccessTone() {
    let audio = new Audio();
    audio.src = "../../../../../../assets/soundEffect/ClappingCrowd.mp3";
    audio.load();
    audio.play();
  }

  Search() {
    this.authService.reminderfilterSearch(this.filterValues);
  }
  ViewPastReminder(){
    this.startDate = moment(moment().subtract(6, 'months')).format('DD/MM/YYYY');
    this.endDate = moment().format('DD/MM/YYYY');  
    this.filterValues.StartDate = moment(moment().subtract(6, 'months')).format('YYYY-MM-DD');
    this.filterValues.EndDate =  moment().format('YYYY-MM-DD');
    this.filterValues.Limit=100000;
    this.authService.reminderfilterSearch(this.filterValues);
  }

  GetCoworkerReviewQuestionNotAnswer() {
    this.settingsService.GetCoworkerReviewQuestionNotAnswer<ResponseBase>()
      .subscribe({
        next: (response: ResponseBase) => {
          if (response != null && response.result) 
            this.coworkerReviewQuestionList = response.result;
            for (const [i, action]  of this.coworkerReviewQuestionList.entries()) {
                this.openDialog(action, i);
            }
        },
        error: err => {},
        complete: () => {}
      })
  }


  openDialog(action, index): Promise<number> {
    action.json = JSON.parse(action.json);
    //  JSON.parse(action.json,function(prop, value) {
    //   var lower = prop.charAt(0).toLowerCase() + prop.slice(1);
    //   if(prop === lower) return value;
    //   else this[lower] = value;
    // });
    const dialogRef = this.dialog.open(AnswerCategoryQuestionComponent, {
      height: "90%",
      width: "70%",
      disableClose: true,
      data: {  
        Header: "CateGory Question Answer",
        ShowOKButton: true,
        data: action.json,
        title:action.text
      }
    });
   
    return dialogRef.afterClosed()
      .toPromise() 
      .then(result => {
        if (result) {
          action.json = result;
          action.coworkerReviewQuestionID = action.id;
          if (index === 0) {
            //console.log("call api",this.coworkerReviewQuestionList);
            this.AddCoworkerReviewAnswers();
          }
        }
        return Promise.resolve(result);
      });
  }

  AddCoworkerReviewAnswers() {
    this.showSpinner = true;
    this.filterCoworkerReviewQuestionList = this.coworkerReviewQuestionList.filter(x => x.coworkerReviewQuestionID);
    this.reminderService.AddCoworkerReviewAnswers(this.filterCoworkerReviewQuestionList).subscribe({
      next: (response: ResponseBase) => {
        this.showSpinner = false;
        this.translate.get("Message.Created").subscribe({
          next: (res) => {
            this.notificationsService.success(res);
          },
          error: err => {
            this.showSpinner = false;
          }
        });
      },
      error: err => {
        this.translate.get("Message.Failed").subscribe({
          next: (res) => {
            this.notificationsService.error(res);
            this.showSpinner = false;
          },
          error: err => {
          }
        });
      }
    });
  }

  getCoWorkers(model) {
    model.Limit = 400;
    model.OrderBy = "Name";
    this.coWorkersService.Get<ResponseBase>(model)
      .subscribe((response: ResponseBase) => {
        if (response)
          this.getNextRequestForCoWorker(response.pagination, model, response.result);
        else
          this.coWokers = [];
      },
        error => (err) => {
          this.coWokers = [];
        },
        () => {
        });
  }

  getNextRequestForCoWorker(pagination, model, response) {
    if (pagination.index == 1)
      this.coWokers = response;
    else
      this.coWokers.push(...response);
    this.coWorkersService.setResponseForCoworker(this.coWokers);
    model.Index = pagination.index + 1;
    model.Limit = pagination.limit;
    model.TotalRows = pagination.totalCount;
    if (pagination.totalCount > pagination.index * pagination.limit)
      this.getCoWorkers(model);
  }
  

  ngOnDestroy(): void {
    this.authService.reminderfilterSearch(null);
  }
}
