import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertMessageModelComponent } from './alert-message-model.component';
import { MaterialModule } from '../../../../@fuse/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts'; 
import { TranslateModule } from '@ngx-translate/core';
@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    TranslateModule,
    KeyboardShortcutsModule.forRoot()
  ],
  exports: [AlertMessageModelComponent],
  declarations: [AlertMessageModelComponent]
})
export class AlertMessageModelModule { }
