export const locale = {
  lang: 'en',
  data: {
    'FIELDS': {
      'Add': 'Add',
      'SelectProjectToAdd': 'Select Project',
      'SagNo': 'Project No.',
      'Address': 'Address'
    }
  }
}

