export const locale = {
    lang: 'en',
    data: {
        'btn': {
            'Copy': 'Copy',
            'Cancel': 'Cancel',
          },
        'LABELS': {
            'From':'From',
            'To' : 'To'
        },
        'VALIDATIONS':{
            'from': 'Select Material',
            'to': 'Select Material'
        }
    }
};
