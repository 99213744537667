import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxMaskModule } from 'ngx-mask';
import { SharedModule } from '../../../core/modules/shared.module';
import { NewInquiryComponent } from './new-inquiry/new-inquiry.component';
import { InquiryNoteComponent } from './component/note/note.component';
import { VibeInquiryComponent } from './inquiry.component';
import { StartAndVisitDateComponent } from './component/startAndVisitDate/startAndVisitDate.component';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { InquiryService } from './inquiry.service';
import { CKEditorModule } from 'ng2-ckeditor';
import { QuillEditorModule } from 'ngx-quill-editor';
const routes = [
    {
        path: 'inquiry',
        component: VibeInquiryComponent
    },
    {
        path: 'inquiry/add',
        component: NewInquiryComponent,
        data: { IsAdd: true }
    },
    {
        path: 'inquiry/edit/:id',
        component: NewInquiryComponent,
        data: { IsAdd: false }
    }
];

@NgModule({
    declarations: [
        VibeInquiryComponent,
        NewInquiryComponent,
        InquiryNoteComponent,
        StartAndVisitDateComponent
    ],
    imports: [
        DatePickerModule,
        SharedModule,
        RouterModule.forChild(routes),
        NgxMaskModule.forRoot(),
        CKEditorModule,
        QuillEditorModule
        //AgmCoreModule.forRoot({
        //    apiKey: 'AIzaSyB2LPLAZRlRAHndaGDzsuXpBrUdzivMqYQ',
        //  libraries: ["places"]
        //})
    ],
    exports: [
        VibeInquiryComponent,
        NewInquiryComponent,
        InquiryNoteComponent,
        
    ],
    entryComponents: [
        StartAndVisitDateComponent
    ],
    providers: [InquiryService]
})

export class InquiryModule {
}
