import { Component, OnInit, EventEmitter } from '@angular/core';
import { Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { FuseTranslationLoaderService } from '../../../../core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';

import { WorkhoursService } from './../workhours.service';
import { WorkHour } from '../../../model/WorkHour';
import { ConvertTimeToHunders, ConvertHundersToTime } from '../../../../core/Validators/validation';
import { DeleteAlertModalComponent } from './../../delete-alert-modal/delete-alert-modal.component';
@Component({
  selector: 'app-show-sdaily-report-modal',
  templateUrl: './show-sdaily-report-modal.component.html',
  styles: []
})
export class ShowSdailyReportModalComponent implements OnInit {

  onDeleteWorkHour = new EventEmitter();
  workHours: WorkHour[] = [];
  show: boolean = false;
  total: number = 0;
  constructor(private translationLoader: FuseTranslationLoaderService, public dialogRef: MatDialogRef<ShowSdailyReportModalComponent>,
    public dialogRef1: MatDialogRef<DeleteAlertModalComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private workhoursService: WorkhoursService) {
    this.translationLoader.loadTranslations(english, danish);
    this.workHours = data.workHours;
    
    if (this.workHours.length == 0) {
      this.show = true;
    }
  }

  ngOnInit() {
  }

  Delete(Id) {
    //this.onDelete.emit(Id);
    const dialogRef1 = this.dialog.open(DeleteAlertModalComponent, {
      width: '500px',
      data: Id
    });

    dialogRef1.afterClosed().subscribe(result => {
      if (result) {
        this.dialogRef1.close();
        this.onDeleteWorkHour.emit(Id);
      }
    });
  }

  GetTotalHours() {
    this.total = 0;
    this.workHours.filter(wh => {
      this.total += ConvertTimeToHunders(wh.hours);
    })
    return ConvertHundersToTime(this.total);
  }

}
