export const locale = {
    lang: 'ds',
    data: {
        "FIELDS": {
            "SrNo":"Sr nr.",
            "Options":"Muligheder",
            "AnswerCoworkerName":"Besvaret medarbejders navn",
            'Percentage':"Procent"
        },
        "BUTTONS": {
            "Ok": "Okay",
        },
        'MESSAGES': {
            'NORECORD': 'Intet optage fundet',
        },
    }
};
