import { Component, OnInit, Inject } from '@angular/core';
import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA,MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-profit-highlight',
  templateUrl: './profit-highlight.component.html',
  styleUrls: ['./profit-highlight.component.scss']
})

export class ProfitHighlightComponent implements OnInit {
  confettiPieces: string[] = ['🎈','🎉','🎊','🎉','🥳🎊','🎉','🎊','🥳','🎉🥳','🎉','🎊','🥳','🎈'];
  isValue: any;
  constructor(private translationLoaderService: FuseTranslationLoaderService, public dialogRef: MatDialogRef<ProfitHighlightComponent>,      
     private translate: TranslateService,@Inject(MAT_DIALOG_DATA) public data: any) 

  { this.translationLoaderService.loadTranslations(danish, english) }

  ngOnInit() {
   this.getValueColor(); 
   this.isValue = this.data.totalProfit;
  }

  getValueColor() {
    if (this.isValue > 0) {
      return 'green';
    } else {
      return 'red';
    }
  }

  onCloseDialog(e) {
    this.dialogRef.close(e ? true : false)
}
  

}
