export const locale = {
    lang: 'ds',
    data: {
        'TITLE': {
            'CustomerPagination': 'Antal log pr. side',
        },
        'FIELDS': {
            'SRNO': 'Sr nr.',
            'Date': 'Dato',
            'CoworkerName': 'Medarbejder',
            'OldStatus': 'Gammel status',
            'NewStatus': 'Ny status',
            'Description': 'Tekst',
            'Coworker': 'Medarbejdere',
            'Not-Found-Worker': 'Medarbejder ikke fundet',
            'ModuleType': 'Modul type'
        },
        'Message': {

        },
        'FILTERS': {
            'TEXTSEARCH': 'Søg efter nummer',
        },
        'moduleTypeList': {
            'value0': 'Alle',
            'value1': 'Projekter',
            'value2': 'Tilbud'
        },
        'TOOTIPORTITLE':{
            'SeeAnsware':'Se Svar'
        }
    }
};