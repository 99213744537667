export const locale = {
    lang: 'en',
    data: {
        'FIELDS': {
            'number': 'Number',
            'text': 'Text',
            'm2200': '0-200 m2',
            'm2201': '201-500 m2',
            'm2500': '> 500 m2',
            'appendix': 'Appendix',
            'm2': 'M2',
            'lb15': '15 cm',
            'lb30': '30 cm',
            'lb40': '40 cm',
            'lb60': '60 cm',
            'stk10': '0-10 stk.',
            'stk20': '11-20 stk.',
            'stk21': '> 21 stk.',
            'TreatmentType':'Treatment Type',
            'Min':'Min'
        },
       'BUTTONS':{
            'Add':'Add',
            'BTNSAVE': 'Save',
            'BACK':'Back'
        },
        'MESSAGES': {
            'NORECORD': 'No Record Found',
            'NO_REMINDER_RECORD': 'No Reminder Found',
            'NO_PROJECT_RECORD': 'No Project Found',
            'Created': 'Created successfully.',
            'Updated': 'Updated successfully.',
            'Deleted': 'Deleted successfully.',
            'Failed': 'Something went wrong !',
        },
    }
};