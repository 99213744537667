import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-coworker',
  templateUrl: './help-coworker.component.html',
  styleUrls: ['help-coworker.component.scss']
})
export class HelpCoworkerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
