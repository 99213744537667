import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpParams, HttpClient } from '@angular/common/http';
import { CoWorkerFull, CoWorkerDevice, CoworkerEmployment, NumberOfEmployee } from 'app/main/model/CoWorker';
import { ChangeEmail } from 'app/main/model/ChangeEmail';
import { RequestForLocalStorage, RequestResourceWorkHours, ResponseForLocalStorageData } from 'app/main/model/RequestBase';
import { ApiConstant } from 'app/core/constant/ApiConstant';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { environment } from 'environments/environment';
import { FileUpload } from 'app/main/model/FileUpload';
import { EmailSendingParam } from 'app/main/model/common';
import { CreateFolder, SearchFolder, ListFolder, DeleteBatchFile } from 'app/main/model/DropBox';
import { CompanyInfoSetting } from 'app/main/model/CompanyInfo';


@Injectable({ providedIn: 'root' })
export class CoWorkersService {
    private actionUrl: string;
    private userImage = new BehaviorSubject<any>('assets/images/vibe-images/user-profile/default profile picture.jpg');
    userImageIcon = this.userImage.asObservable();
    private settingsActionUrl: string;

    constructor(private http: HttpClient) {
        this.actionUrl = ApiConstant.API_URL + ApiConstant.COWORKER + "/";
        this.settingsActionUrl = ApiConstant.API_URL + ApiConstant.SETTINGS + "/";
    }

    public Get<T>(RequestCoWorker: any): Observable<T> {
        let search = this.GetParams(RequestCoWorker);
        return this.http.get<T>(this.actionUrl, {
            params: search
        });
    }

    public GetSingle<T>(id: any): Observable<T> {
        return this.http.get<T>(this.actionUrl + id);
    }

    public GetCoworkerDevices<T>(coworkerId: string): Observable<T> {
        return this.http.get<T>(this.actionUrl + coworkerId + "/coworkerdevice");
    }

    public GetDefaultCoworkers<T>(): Observable<T> {
        return this.http.get<T>(this.actionUrl + "getdefaultaddcowrker");
    }

    public GetCoworkersWithHoliday<T>(RequestCoWorker: any): Observable<T> {
        let search = this.GetParams(RequestCoWorker);
        return this.http.get<T>(this.actionUrl + "getCoworkersWithHoliday", {
            params: search
        });
    }

    public Add<T>(coWorker: CoWorkerFull): Observable<T> {
        return this.http.post<T>(this.actionUrl, coWorker);
    }

    public AddCoworkerDevice<T>(coworkerId, coWorkerDevice: CoWorkerDevice): Observable<T> {
        return this.http.post<T>(this.actionUrl + coworkerId + "/coworkerdevice", coWorkerDevice);
    }

    public Update<T>(id: any, coWorker: CoWorkerFull): Observable<T> {
        return this.http.put<T>(this.actionUrl + id, coWorker);
    }

    public UpdateCoworkerDevice<T>(id: any, coWorker: CoWorkerDevice): Observable<T> {
        return this.http.put<T>(this.actionUrl + id + "/coworkerdevice", coWorker);
    }

    public Delete<T>(id: any): Observable<T> {
        return this.http.delete<T>(this.actionUrl + id);
    }

    public DeleteCoworkerDevice<T>(coworkerId: string, id: any): Observable<T> {
        return this.http.delete<T>(this.actionUrl + coworkerId + "/coworkerdevice/" + id);
    }

    public IsNumberExist<T>(number: string): Promise<T> {
        return this.http.get<T>(this.actionUrl + ApiConstant.NUMBER + "/" + number).toPromise();
    }



    public IsInitialExist<T>(name: string): Promise<T> {
        return this.http.get<T>(this.actionUrl + ApiConstant.INITIAL + "/" + name).toPromise();
    }

    public IsEmailExist<T>(email: string): Promise<T> {
        return this.http.get<T>(this.actionUrl + ApiConstant.EMAIL + "/" + email).toPromise();
    }

    public GetCoWrokerAsUser<T>(): Observable<T> {
        return this.http.get<T>(this.actionUrl + ApiConstant.COWORKERASUSER);
    }

    public ResetPassword<T>(userId: string): Promise<T> {
        return this.http.post<T>(ApiConstant.API_URL + ApiConstant.RESET_PASSWORD, { userId: userId }).toPromise();
    }

    public ChangeEmail<T>(changeEmail: ChangeEmail): Observable<T> {
        return this.http.put<T>(this.actionUrl + ApiConstant.EMAIL, changeEmail);
    }

    public AddFileToCoWorker<T>(id: any, fileUpload: FileUpload): Observable<T> {
        return this.http.post<T>(this.actionUrl + id + "/file", fileUpload);
    }

    public SendEmail<T>(emailSendingParam: EmailSendingParam): Observable<T> {
        var url = ApiConstant.API_URL + ApiConstant.REMINDER + "/";
        return this.http.post<T>(url + "SendEmail", emailSendingParam);
    }

    public GetCoWorkerFiles<T>(id: any, RequestCoWorkerFile: any): Observable<T> {
        let search = this.GetParams(RequestCoWorkerFile);
        return this.http.get<T>(this.actionUrl + id + "/files", {
            params: search
        });
    }

    public RemoveFileFromCoWorker<T>(id: any, fileId: any): Observable<T> {
        return this.http.delete<T>(this.actionUrl + id + "/file/" + fileId);
    }

    public AddUpdateCoworkerFileInfo<T>(id, name, info: CompanyInfoSetting): Observable<T> {
        return this.http.put<T>(this.actionUrl + id + "/" + name + "/coworkerFileInfoTab", info);
    }

    public GetCoworkerFileInfo<T>(id, name): Observable<T> {
        return this.http.get<T>(this.actionUrl + id + "/" + name + "/coworkerFileInfoTab");
    }

    public SearchFolderDrpBox<T>(searchFolder: SearchFolder): Observable<T> {
        return this.http.post<T>(ApiConstant.DrpBoxSearch, searchFolder);
    }

    public GetCoworker<T>(RequestCoWorker: any): Observable<T> {
        var url = ApiConstant.API_URL + ApiConstant.COWORKER + "/";
        let search = this.GetParams(RequestCoWorker);
        return this.http.get<T>(url, {
            params: search
        });
    }

    public CreateFolderDrpBox<T>(createFolder: CreateFolder): Observable<T> {
        return this.http.post<any>(ApiConstant.DrpBoxCreate, createFolder);
    }

    public ListFolderDrpBox<T>(listFolder: ListFolder): Observable<T> {
        return this.http.post<any>(ApiConstant.DrpBoxList, listFolder);
    }

    public DeleteBatchFileDrpBox<T>(deleteBatchFile: DeleteBatchFile): Observable<T> {
        return this.http.post<any>(ApiConstant.DrpBoxDelete, deleteBatchFile);
    }

    public GetDrpBoxSharedLink<T>(listFolder: ListFolder): Observable<T> {
        return this.http.post<any>(ApiConstant.DrpBoxSharedLink, listFolder);
    }

    public GetParams(param) {
        let params: HttpParams = new HttpParams();
        for (var key in param) {
            if (param.hasOwnProperty(key)) {
                let val = param[key];
                params = params.append(key, val);
            }
        }
        return params;
    }

    public SetUserIcon(path: any) {
        this.userImage.next(environment.url + path);
    }

    public setRequestParameterForCalender(model) {
        var requestForLocalStorage = new RequestForLocalStorage();
        var val = localStorage.getItem(ApiConstant.LocalRequestModel);
        if (val) {
            requestForLocalStorage = JSON.parse(val);
        }
        requestForLocalStorage.CalenderRequest = model;
        localStorage.setItem(ApiConstant.LocalRequestModel, JSON.stringify(requestForLocalStorage));
    }

    public getRequestParameterForCalender() {
        var val = localStorage.getItem(ApiConstant.LocalRequestModel);
        var requestForLocalStorage = new RequestForLocalStorage();
        var requestStatusProject = new RequestResourceWorkHours();
        requestStatusProject.OrderBy = "Status DESC";

        if (val) {
            requestForLocalStorage = JSON.parse(val);
            if (requestForLocalStorage.CalenderRequest) {
                requestStatusProject = requestForLocalStorage.CalenderRequest;
            }
        }
        return requestStatusProject;
    }

    public GetCoworkersWithWorkHours<T>(RequestCoWorker: any): Observable<T> {
        let search = this.GetParams(RequestCoWorker);
        return this.http.get<T>(this.actionUrl + "getworkhoursforplanning", {
            params: search
        });
    }

    public GetCoworkerReminderSettingsList<T>(Request: any): Observable<T> {
        let search = this.GetParams(Request);
        return this.http.get<T>(this.settingsActionUrl + "getCoworkerReminderSettingsList", {
            params: search
        });
    }

    public AddCoworkerReminderSettings<T>(coworkerIds: any): Observable<T> {
        return this.http.post<T>(this.settingsActionUrl + "AddCoworkerReminderSettings", coworkerIds);
    }

    public UpdateCoworkerReminderSettings<T>(CoworkerReminderSettingsList: any): Observable<T> {
        return this.http.put<T>(this.settingsActionUrl + "UpdateCoworkerReminderSettings", CoworkerReminderSettingsList);
    }

    public GetCoworkerNotifySettingValue<T>(value): Observable<T> {
        return this.http.get<T>(this.settingsActionUrl + "getSettings" + "?param1=" + value);
    }

    public UpdateSettingValue<T>(id: any, settingValues): Observable<T> {
        return this.http.put<T>(ApiConstant.API_URL + ApiConstant.SETTINGS + '/' + id, settingValues);
    }

    public DeleteCoworkerReminderSetting<T>(id: any): Observable<T> {
        return this.http.delete<T>(ApiConstant.API_URL + ApiConstant.SETTINGS + "/" + id);
    }

    public GetAllFieldsForSingleCoworker<T>(id: any): Observable<T> {
        return this.http.get<T>(this.actionUrl + "getCoworkerAllFields/" + id);
    }

    public UpdateAllCoworkerFields<T>(id: any, coworkerFullObj: CoWorkerFull): Observable<T> {
        return this.http.put<T>(this.actionUrl + "UpdateCoworkerAllFields/" + id, coworkerFullObj);
    }

    public GetCoworkerEmploymentList<T>(RequestCoWorker: any): Observable<T> {
        let search = this.GetParams(RequestCoWorker);
        return this.http.get<T>(this.actionUrl + "getCoworkerEmployment", {
            params: search
        });
    }

    public AddORUpdateCoworkerEmployment<T>(employment: CoworkerEmployment): Observable<T> {
        return this.http.post<T>(this.actionUrl + "AddUpdateCoworkerEmployment", employment);
    }

    public DeleteCoworkerEmployment<T>(id: any): Observable<T> {
        return this.http.delete<T>(this.actionUrl + "DeleteCoworkerEmployment/" + id);
    }

    public setResponseForCoworker(model) {
        var responseForLocalStorageData = new ResponseForLocalStorageData();
        var val = localStorage.getItem(ApiConstant.LocalResponseModel);
        if (val) 
            responseForLocalStorageData = JSON.parse(val);
        responseForLocalStorageData.Coworkers = model;
        localStorage.setItem(ApiConstant.LocalResponseModel, JSON.stringify(responseForLocalStorageData));
    }

    public getResponseForCoworker() {
        var val = localStorage.getItem(ApiConstant.LocalResponseModel);
        var responseForLocalStorageData = new ResponseForLocalStorageData();
        var requestForLocalStorageCoworker = [];
        if (val) {
            responseForLocalStorageData = JSON.parse(val);
            if (responseForLocalStorageData.Coworkers) 
                requestForLocalStorageCoworker = responseForLocalStorageData.Coworkers;
        }
        return requestForLocalStorageCoworker;
    }

    public CreateCoworkerFromExcel<T>(formData: FormData) {
        return this.http.post(this.actionUrl + "CreateCoworkerFromExcel", formData, { headers: { IsContentType: "true" } });
    }

    public generateEmployeeArray(count: number): { value: number, viewValue: string }[] {
        const employeeArray: NumberOfEmployee[]= [];
        for (let i = 1; i <= count; i++) {
          employeeArray.push({ value: i, viewValue: i.toString() });
        }
        return employeeArray;
      }

}
