import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { CompanyInfoSetting } from 'app/main/model/CompanyInfo';
import { TextEditorComponent } from 'app/main/pages/text-editor/text-editor.component';
import { MatDialog } from '@angular/material';
import { ProjectService } from 'app/main/pages/project/project.service';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { ApiConstant } from 'app/core/constant/ApiConstant';
import { NotificationsService } from 'angular2-notifications';


@Component({
    selector: 'vibe-projectOffers',
    templateUrl: './offers.component.html',
    styleUrls: ['./offers.component.scss']
})

export class OffersComponent implements OnInit {
    @Input() projectId: string;
    @Input() OffersDescriptionDetail: string;
    @Output() isDisplayOffersTabRed = new EventEmitter<boolean>();
    showSpinner: boolean = false;
    OffersDescription: string;
    displayOffersTabRed: boolean = false;

    constructor(private translate: TranslateService,
        private translationLoader: FuseTranslationLoaderService,
        private dialog: MatDialog,
        private projectService: ProjectService,
        private notificationsService: NotificationsService) {
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
        this.OffersDescription = this.OffersDescriptionDetail;
      this.projectId = this.projectId;
      this.getOfferstabData();
    }

  getOfferstabData() {
    this.showSpinner = true;
    this.projectService.GetProjectRequisition<any>(this.projectId, ApiConstant.RequisitionOffers).subscribe({
      next: (response) => {
        this.showSpinner = false;
        this.OffersDescription = response.result.description;
        if (this.OffersDescription != null && this.OffersDescription.trim() != "") {
          this.displayOffersTabRed = true;
        }
        else {
          this.displayOffersTabRed = false;
        }
        this.isDisplayOffersTabRed.emit(this.displayOffersTabRed);
      },
      error: err => {
        this.translate.get("MESSAGES.Failed").subscribe({
          next: (res) => {
            this.notificationsService.error(res);
          },
          error: err => {
          }
        });
      }
    });
  }

    openProjectRequsitionDescriptionForOffer() {
        var selectedTabData = new CompanyInfoSetting();
        selectedTabData.Description = this.OffersDescription;

        const dialogRef = this.dialog.open(TextEditorComponent, {
            height: '90%',
            width: '90%',
            data: { selectedTabData, isEmail: false },
            disableClose: true
        });


        dialogRef.afterClosed().subscribe(result => {
            this.showSpinner = true;
            var data = new CompanyInfoSetting();
            data.Description = "";
            if (result && result.Description) {
                data.Description = result.Description;
            }

            this.projectService.UpdateRequisitionExtraTabDescription<ResponseBase>(this.projectId, ApiConstant.RequisitionOffers, data)
                .subscribe({
                    next: (response: ResponseBase) => {
                        this.showSpinner = false;
                        this.OffersDescription = response.result.description;
                        if (this.OffersDescription != null && this.OffersDescription.trim() != "") {
                            this.displayOffersTabRed = true;
                        }
                        else {
                            this.displayOffersTabRed = false;
                        }
                        this.isDisplayOffersTabRed.emit(this.displayOffersTabRed);
                    },
                    error: err => {
                        this.showSpinner = false;
                        this.translate.get("MESSAGES.Failed").subscribe({
                            next: (res) => {
                                this.notificationsService.error(res);
                            },
                            error: err => {
                            }
                        });
                    },
                    complete: () => {
                        this.showSpinner = false;
                    }
                });
        });
    }

}