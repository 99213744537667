import { Component, OnInit, ElementRef, NgZone, ViewChild, Input, Output, EventEmitter, Inject, Optional, forwardRef, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatExpansionPanel } from '@angular/material';
import { TitleService } from 'app/core/services/title.service';
import { ActivatedRoute } from '@angular/router';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { OfferRoomPriceDTO, OfferTypeWithRoomPriceDetail, OfferMaterialWithPrice, OfferMeterWisePrice, DiverseDTO, MaterialList, OfferRoomCalculationWithMaterialList, OfferRoomCalculationDTO, OfferRoomDetailDTO, OfferRoomFullDetailDTO } from 'app/main/model/OfferV2';
import { TranslateService } from '@ngx-translate/core';
import { MaterialTypeCategory, PaintSelectTime, OffMaterialTypeIds, TreatmentTypeIds, OfferRoomCalculationType } from 'app/constant/common.constant';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { ProjectofferService } from 'app/main/pages/project-offer/project-offer.service';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';
import { ResponseBase } from 'app/main/model/ResponseBase'
import { TextareaEditorComponent } from '../textarea-editor/textarea-editor.component';
import { OfferAddressModel } from 'app/main/model/ProjectOffer';


@Component({
    selector: 'offer-room-type-detail',
    templateUrl: './offer-room-type-detail.component.html',
    styleUrls: ['./offer-room-type-detail.component.scss']
})
export class OfferRoomTypeDetailComponent implements OnInit {
    showSpinner: boolean = false;
    @Input() roomCalculationItem: any;
    @Input() MaterialObject: OfferMaterialWithPrice[];
    @Input() selectedCategoryInput: OfferTypeWithRoomPriceDetail;
    @Input() projectOffer: OfferAddressModel = new OfferAddressModel();
    @Input() projectPriceDiscount: number = 0;
    @Input() customerDetailObj: any;
    @Output() calculateRoomTotal = new EventEmitter<any>();
    @Output() gotoMaterial = new EventEmitter<any>();
    @Output() addFloor = new EventEmitter<any>();
    SelectCategoryTime = PaintSelectTime;
    MaterialTypeSearch = "";
    @Input() MasterMaterialTypeList = [];
    isAddFloor: boolean = false;
    selectMaterialOfferList = MaterialTypeCategory;
    offMaterialTypeIds = OffMaterialTypeIds;
    offerRoomCalculationType = OfferRoomCalculationType;
    tempAssignedMaterialList = [];

    // New Offer
    projectsOfferRooms: OfferRoomFullDetailDTO[] = [];
    meterWisePrice: OfferMeterWisePrice = new OfferMeterWisePrice();
    paintTimer: number = 0;
    //#endregion

    constructor(private projectofferService: ProjectofferService,
        private translationLoader: FuseTranslationLoaderService,
        private _route: ActivatedRoute,
        private translate: TranslateService,
        private cdr: ChangeDetectorRef,
        public dialog: MatDialog,
    ) {
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
        if (this.selectedCategoryInput.id) {
            if (this.roomCalculationItem.extraQty1 > 0 || this.roomCalculationItem.extraQty2 > 0 || this.roomCalculationItem.extraPrice1 > 0 || this.roomCalculationItem.extraPrice2 > 0)
                this.roomCalculationItem.isAddExtraWallQty = true;
            this.roomCalculationItem.materialList.filter((mat, i) => {
                this.getMaterialList(mat.offerMaterialTypeId, true, this.roomCalculationItem, i)
            })
        }
    }


    addMaterial(roomCalculationItem) {
        var materialObj = new MaterialList();
        this.tempAssignedMaterialList = [];
        if (roomCalculationItem.typeId == this.offerRoomCalculationType.WallType)
            materialObj.offerMaterialTypeId = this.offMaterialTypeIds.WallType;
        if (roomCalculationItem.typeId == this.offerRoomCalculationType.CeilingType)
            materialObj.offerMaterialTypeId = this.offMaterialTypeIds.CeilingType;
        if (roomCalculationItem.typeId == this.offerRoomCalculationType.FloorType)
            materialObj.offerMaterialTypeId = this.offMaterialTypeIds.FloorType;
        this.tempAssignedMaterialList = this.MaterialObject.filter(o => { return o.type.split(',').find(p => p == materialObj.offerMaterialTypeId) });
        materialObj.materialList = this.tempAssignedMaterialList.map(x => Object.assign({}, x));//this.MaterialObject.filter(o => { return o.type.split(',').find(p => p == materialObj.offerMaterialTypeId)});
        roomCalculationItem.materialList.push(materialObj);
    }

    addFloorItem(offerRoomDetailId) {
        this.addFloor.emit(offerRoomDetailId);
    }

    getMaterialList(type, isEdit, roomCalculationItem, materialIndex) {
        if (type == 0)
            roomCalculationItem.materialList[materialIndex].materialList = this.MasterMaterialTypeList.map(x => Object.assign({}, x));
        else
            roomCalculationItem.materialList[materialIndex].materialList = this.MasterMaterialTypeList.filter(o => { return o.type.split(',').find(p => p == type) }).map(x => Object.assign({}, x));
        if (!isEdit) {
            roomCalculationItem.materialList[materialIndex].offerMaterialId = roomCalculationItem.materialList[materialIndex].materialList[0].id;
            this.getMaterialPriceDetail(roomCalculationItem.materialList[materialIndex].offerMaterialId, '', materialIndex, roomCalculationItem)
        }
        else
            this.getMaterialPriceDetail(roomCalculationItem.materialList[materialIndex].offerMaterialId, roomCalculationItem.materialList[materialIndex].offerMaterialPriceId, materialIndex, roomCalculationItem)
    }

    materialTypeOnClearSearch(value, roomCalculationItem, materialIndex) {
        if (!value) {
            this.materialListDropdownSearch(roomCalculationItem, materialIndex)
        }
    }

    materialListDropdownSearch(roomCalculationItem, materialIndex) {
        if (roomCalculationItem.materialList[materialIndex].offerMaterialTypeId)
            roomCalculationItem.materialList[materialIndex].materialList = this.MasterMaterialTypeList.filter(o => { return o.type.split(',').find(p => p == (roomCalculationItem.materialList[materialIndex].offerMaterialTypeId) && o.text.toLowerCase().includes(this.MaterialTypeSearch.toLowerCase())) }).map(x => Object.assign({}, x));
        else
            roomCalculationItem.materialList[materialIndex].materialList = this.MasterMaterialTypeList.filter(o => o.text.toLowerCase().includes(this.MaterialTypeSearch.toLowerCase())).map(x => Object.assign({}, x));
    }

    getMaterialPriceDetail(materialId, materialPriceId, materialIndex, roomCalculationItem) {
        var materialprice = this.MaterialObject.find(o => o.id == materialId);
        if (materialprice) {
            if (!materialPriceId)
                roomCalculationItem.isPaint = true;
            roomCalculationItem.colorCode = roomCalculationItem.colorCode ? roomCalculationItem.colorCode : materialprice.colorCode;
            //roomCalculationItem.materialList[materialIndex].materialReminder = materialprice.reminder;
            roomCalculationItem.materialList[materialIndex].name;
            roomCalculationItem.materialList[materialIndex].paintKey = "";
            roomCalculationItem.materialList[materialIndex].wallPaintText = "";
            roomCalculationItem.materialList[materialIndex].notCalculateToDriving = materialprice.notCalculateToDriving;
            this.calculateRoomPrice(roomCalculationItem);
            this.meterWisePrice = new OfferMeterWisePrice();
            this.meterWisePrice.isAddMeterRangePrice = false;
            if (roomCalculationItem) {
                roomCalculationItem.materialList[materialIndex].selectCategoryTime = [];
                for (var i = 0; i < materialprice.offerMaterialPriceList.length; i++) {
                    var obj = this.SelectCategoryTime.find(o => o.id == materialprice.offerMaterialPriceList[i].type);
                    if (obj) {
                        if (i == 0) {
                            roomCalculationItem.materialList[materialIndex].offerMaterialPriceId = materialprice.offerMaterialPriceList[i].id;
                            roomCalculationItem.materialList[materialIndex].paintKey = obj.key;
                        }
                        roomCalculationItem.materialList[materialIndex].selectCategoryTime.push({
                            id: obj.id,
                            key: obj.key,
                            priceId: materialprice.offerMaterialPriceList[i].id,
                            extraValue: materialprice.offerMaterialPriceList[i].paintText,
                            reminder: materialprice.offerMaterialPriceList[i].reminder,
                        });
                        if (materialprice.offerMaterialPriceList[i].id == materialPriceId) {
                            roomCalculationItem.materialList[materialIndex].paintKey = obj.key;
                            roomCalculationItem.materialList[materialIndex].offerMaterialPriceId = materialprice.offerMaterialPriceList[i].id;
                            roomCalculationItem.materialList[materialIndex].materialReminder = materialprice.offerMaterialPriceList[i].reminder;
                            if (roomCalculationItem.materialList[materialIndex].materialList && roomCalculationItem.materialList[materialIndex].materialList.length > 0) {
                                roomCalculationItem.materialList[materialIndex].materialList.filter(m => {
                                    if (m.id == materialId) {
                                        var tempPriceData = m.offerMaterialPriceList.filter(x => x.id == materialPriceId);
                                        if (tempPriceData && tempPriceData.length > 0)
                                            m.offerMaterialPriceText = tempPriceData[0].paintText;
                                    }
                                })
                            }
                        }
                        if (materialprice.offerMaterialPriceList[i].isDefault && materialPriceId == "") {
                            roomCalculationItem.materialList[materialIndex].offerMaterialPriceId = materialprice.offerMaterialPriceList[i].id;
                            if (roomCalculationItem.materialList[materialIndex].materialList && roomCalculationItem.materialList[materialIndex].materialList.length > 0) {
                                roomCalculationItem.materialList[materialIndex].materialList.filter(m => {
                                    if (m.id == materialId) {
                                        var tempPriceData = m.offerMaterialPriceList.filter(x => x.id == roomCalculationItem.materialList[materialIndex].offerMaterialPriceId);
                                        if (tempPriceData && tempPriceData.length > 0)
                                            m.offerMaterialPriceText = tempPriceData[0].paintText;
                                    }
                                })
                            }
                        }
                    }
                }
                if (!materialPriceId) {
                    var selectedMaterialLocal;
                    if (roomCalculationItem.materialList[materialIndex].offerMaterialPriceId)
                        selectedMaterialLocal = materialprice.offerMaterialPriceList.filter(x => x.id == roomCalculationItem.materialList[materialIndex].offerMaterialPriceId)[0];
                    else
                        selectedMaterialLocal = materialprice.offerMaterialPriceList[0];
                    roomCalculationItem.materialList[materialIndex].offerMaterialPriceId = selectedMaterialLocal.id;
                    var objSelectCategoryTime = this.SelectCategoryTime.find(o => o.id == selectedMaterialLocal.type);
                    roomCalculationItem.materialList[materialIndex].paintKey = objSelectCategoryTime.key;
                    var price = selectedMaterialLocal.price;
                    let time = 0;
                    if (selectedMaterialLocal.isAddMeterRangePrice) {
                        if (roomCalculationItem.totalArea >= selectedMaterialLocal.startMeter1
                            && roomCalculationItem.totalArea <= selectedMaterialLocal.endMeter1) {
                            price = selectedMaterialLocal.priceMeter1;
                            time = selectedMaterialLocal.timeMeter1;
                        }
                        else if (roomCalculationItem.totalArea >= selectedMaterialLocal.startMeter2
                            && roomCalculationItem.totalArea <= selectedMaterialLocal.endMeter2) {
                            price = selectedMaterialLocal.priceMeter2;
                            time = selectedMaterialLocal.timeMeter2;
                        }
                        else {
                            price = selectedMaterialLocal.priceMeter3;
                            time = selectedMaterialLocal.timeMeter3;
                        }
                    }
                    else
                        time = selectedMaterialLocal.timeMeter;
                    roomCalculationItem.materialList[materialIndex].price = price;
                    roomCalculationItem.materialList[materialIndex].originalPrice = price;
                    roomCalculationItem.materialList[materialIndex].timer = time;
                    if (!roomCalculationItem.materialList[materialIndex].id && materialprice.isPostNumberDiscount && this.projectOffer && this.projectOffer.percentage != 0 && !this.customerDetailObj.doNotCalculatePriceForPostNumber)
                        roomCalculationItem.materialList[materialIndex].price = roomCalculationItem.materialList[materialIndex].price + ((roomCalculationItem.materialList[materialIndex].price * this.projectOffer.percentage) / 100);
                    if (!roomCalculationItem.materialList[materialIndex].id && this.projectPriceDiscount && !this.customerDetailObj.doNotCalculatePriceForPriceDiscount)
                        roomCalculationItem.materialList[materialIndex].price = roomCalculationItem.materialList[materialIndex].price + ((roomCalculationItem.materialList[materialIndex].price * this.projectPriceDiscount) / 100);
                    //roomPriceDetail.wallPaintTime = selectedMaterialLocal.time;
                    this.calculateRoomPrice(roomCalculationItem);
                }
                else {
                    var selectedMaterialLocal;
                    if (roomCalculationItem.materialList[materialIndex].offerMaterialPriceId)
                        selectedMaterialLocal = materialprice.offerMaterialPriceList.filter(x => x.id == roomCalculationItem.materialList[materialIndex].offerMaterialPriceId)[0];
                    let time = 0;
                    if (selectedMaterialLocal.isAddMeterRangePrice) {
                        if (roomCalculationItem.totalArea >= selectedMaterialLocal.startMeter1
                            && roomCalculationItem.totalArea <= selectedMaterialLocal.endMeter1) {
                            time = selectedMaterialLocal.timeMeter1;
                        }
                        else if (roomCalculationItem.totalArea >= selectedMaterialLocal.startMeter2
                            && roomCalculationItem.totalArea <= selectedMaterialLocal.endMeter2) {
                            time = selectedMaterialLocal.timeMeter2;
                        }
                        else {
                            time = selectedMaterialLocal.timeMeter3;
                        }
                    }
                    else
                        time = selectedMaterialLocal.timeMeter;
                    roomCalculationItem.materialList[materialIndex].timer = time;
                    this.calculatePaintTimerOnEdit(roomCalculationItem);
                }
                var tempMaterialPrice = materialprice.offerMaterialPriceList
                    .find(o => o.id == roomCalculationItem.materialList[materialIndex].offerMaterialPriceId);
                if (tempMaterialPrice && tempMaterialPrice.isAddMeterRangePrice) {
                    this.meterWisePrice.startMeter1 = tempMaterialPrice.startMeter1;
                    this.meterWisePrice.endMeter1 = tempMaterialPrice.endMeter1;
                    this.meterWisePrice.priceMeter1 = tempMaterialPrice.priceMeter1;
                    this.meterWisePrice.timeMeter1 = tempMaterialPrice.timeMeter1;
                    this.meterWisePrice.startMeter2 = tempMaterialPrice.startMeter2;
                    this.meterWisePrice.endMeter2 = tempMaterialPrice.endMeter2;
                    this.meterWisePrice.priceMeter2 = tempMaterialPrice.priceMeter2;
                    this.meterWisePrice.timeMeter2 = tempMaterialPrice.timeMeter2;
                    this.meterWisePrice.startMeter3 = tempMaterialPrice.startMeter3;
                    this.meterWisePrice.endMeter3 = tempMaterialPrice.endMeter3;
                    this.meterWisePrice.priceMeter3 = tempMaterialPrice.priceMeter3;
                    this.meterWisePrice.timeMeter3 = tempMaterialPrice.timeMeter3;
                    this.meterWisePrice.isAddMeterRangePrice = tempMaterialPrice.isAddMeterRangePrice;
                }
                if (selectedMaterialLocal && selectedMaterialLocal.paintText) {
                    roomCalculationItem.materialList[materialIndex].wallPaintText = selectedMaterialLocal.paintText;
                    roomCalculationItem.materialList[materialIndex].materialReminder = selectedMaterialLocal.reminder;
                }
                this.meterWisePrice.price = roomCalculationItem.materialList[materialIndex].price;
                roomCalculationItem.materialList[materialIndex].name;
                roomCalculationItem.materialList[materialIndex].meterWisePrice = this.meterWisePrice;
            }
        }
    }

    clearPriceDetail(materialIndex, roomCalculationItem) {
        roomCalculationItem.materialList[materialIndex].offerMaterialPriceId = null;
        roomCalculationItem.materialList[materialIndex].offerMaterialId = null;
        roomCalculationItem.materialList[materialIndex].name = null;
        roomCalculationItem.materialList[materialIndex].totalArea = 0;
        roomCalculationItem.materialList[materialIndex].totalPrice = 0;
        roomCalculationItem.materialList[materialIndex].price = 0;
        roomCalculationItem.materialList[materialIndex].colorCode = null;
        roomCalculationItem.materialList[materialIndex].materialReminder = null;
        roomCalculationItem.materialList[materialIndex].notCalculatePrice = false;
        this.calculateRoomPrice(roomCalculationItem);
    }

    getPriceForCategoryTimeSelect(materialId, priceId, materialIndex, roomCalculationItem) {
        if (priceId && roomCalculationItem && roomCalculationItem.materialList) {
            roomCalculationItem.materialList[materialIndex].materialList.filter(mat => {
                if (mat.id == materialId) {
                    var tempPriceData = mat.offerMaterialPriceList.filter(x => x.id == priceId);
                    if (tempPriceData && tempPriceData.length > 0) {
                        mat.offerMaterialPriceText = tempPriceData[0].paintText;
                        roomCalculationItem.materialList[materialIndex].wallPaintText = tempPriceData[0].paintText;
                        roomCalculationItem.materialList[materialIndex].materialReminder = tempPriceData[0].reminder;
                    }
                }
            })
        }
        var selectedMaterial = this.MaterialObject.find(o => o.id == materialId);
        if (selectedMaterial) {
            this.meterWisePrice = new OfferMeterWisePrice();
            this.meterWisePrice.isAddMeterRangePrice = false;
            var selectedPrice = selectedMaterial.offerMaterialPriceList.find(o => o.id == priceId);
            if (selectedPrice) {
                var price = selectedPrice.price;
                if (selectedPrice.isAddMeterRangePrice && !roomCalculationItem.materialList[materialIndex].notCalculatePrice) {
                    if (roomCalculationItem.totalArea >= selectedPrice.startMeter1
                        && roomCalculationItem.totalArea <= selectedPrice.endMeter1) {
                        price = selectedPrice.priceMeter1;
                    }
                    else if (roomCalculationItem.totalArea >= selectedPrice.startMeter2
                        && roomCalculationItem.totalArea <= selectedPrice.endMeter2) {
                        price = selectedPrice.priceMeter2;
                    }
                    else {
                        price = selectedPrice.priceMeter3;
                    }
                    this.meterWisePrice.startMeter1 = selectedPrice.startMeter1;
                    this.meterWisePrice.endMeter1 = selectedPrice.endMeter1;
                    this.meterWisePrice.priceMeter1 = selectedPrice.priceMeter1;
                    this.meterWisePrice.timeMeter1 = selectedPrice.timeMeter1;
                    this.meterWisePrice.startMeter2 = selectedPrice.startMeter2;
                    this.meterWisePrice.endMeter2 = selectedPrice.endMeter2;
                    this.meterWisePrice.priceMeter2 = selectedPrice.priceMeter2;
                    this.meterWisePrice.timeMeter2 = selectedPrice.timeMeter2;
                    this.meterWisePrice.startMeter3 = selectedPrice.startMeter3;
                    this.meterWisePrice.endMeter3 = selectedPrice.endMeter3;
                    this.meterWisePrice.priceMeter3 = selectedPrice.priceMeter3;
                    this.meterWisePrice.timeMeter3 = selectedPrice.timeMeter3;
                    this.meterWisePrice.isAddMeterRangePrice = selectedPrice.isAddMeterRangePrice;
                    this.meterWisePrice.price = price;
                }
                var obj = this.SelectCategoryTime.find(o => o.id == selectedPrice.type);
                if (obj) {
                    roomCalculationItem.materialList[materialIndex].paintKey = obj.key;
                }
                if (!roomCalculationItem.materialList[materialIndex].notCalculatePrice) {
                    roomCalculationItem.materialList[materialIndex].price = price;
                    if (!roomCalculationItem.materialList[materialIndex].id && selectedMaterial.isPostNumberDiscount && this.projectOffer && this.projectOffer.percentage != 0 && !this.customerDetailObj.doNotCalculatePriceForPostNumber)
                        roomCalculationItem.materialList[materialIndex].price = roomCalculationItem.materialList[materialIndex].price + ((roomCalculationItem.materialList[materialIndex].price * this.projectOffer.percentage) / 100);
                    if (!roomCalculationItem.materialList[materialIndex].id && this.projectPriceDiscount && !this.customerDetailObj.doNotCalculatePriceForPriceDiscount)
                        roomCalculationItem.materialList[materialIndex].price = roomCalculationItem.materialList[materialIndex].price + ((roomCalculationItem.materialList[materialIndex].price * this.projectPriceDiscount) / 100);
                    roomCalculationItem.materialList[materialIndex].originalPrice = roomCalculationItem.materialList[materialIndex].price;
                }
                roomCalculationItem.materialList[materialIndex].meterWisePrice = this.meterWisePrice;

            }
            this.calculateRoomPrice(roomCalculationItem);
        }
    }

    addWallQty(isExtraWallQty, roomCalculationItem) {
        if (!isExtraWallQty) {
            roomCalculationItem.isAddExtraWallQty = true;
            roomCalculationItem.extraQty1 = roomCalculationItem.extraM2QtyFloor ? roomCalculationItem.extraM2QtyFloor : 0;
            roomCalculationItem.extraPrice1 = roomCalculationItem.extraM2PriceFloor ? roomCalculationItem.extraM2PriceFloor : 0;
        } else {
            roomCalculationItem.isAddExtraWallQty = false;
            roomCalculationItem.extraQty1 = 0;
            roomCalculationItem.extraQty2 = 0;
            roomCalculationItem.extraPrice1 = 0;
            roomCalculationItem.extraPrice2 = 0;
            this.calculateRoomPrice(roomCalculationItem);
        }
    }

    calculateRoomPrice(roomCalculationItem) {
        if (roomCalculationItem.typeId == 1) {
            roomCalculationItem.totalArea = (((roomCalculationItem.length + roomCalculationItem.width) * 2) * roomCalculationItem.ceilingHeight)
                + roomCalculationItem.extraQty1 + roomCalculationItem.extraQty2;
        }
        else {
            roomCalculationItem.totalArea = (roomCalculationItem.length * roomCalculationItem.width)
                + roomCalculationItem.extraQty1 + roomCalculationItem.extraQty2;
        }
        roomCalculationItem.totalPrice = 0;
        roomCalculationItem.drivingAmountTotal = 0;
        roomCalculationItem.paintTimerTotal = 0;        
        if (roomCalculationItem.materialList.length > 0 && roomCalculationItem.isPaint == true) {
            roomCalculationItem.totalPrice = (roomCalculationItem.totalArea * (roomCalculationItem.materialList.reduce((sum, current) => sum + current.price, 0)))
                + roomCalculationItem.extraPrice1 + roomCalculationItem.extraPrice2;
            var tempDrivingAmountTotal =  (roomCalculationItem.materialList.filter(m => !m.notCalculateToDriving).reduce((sum, current) => sum + current.price, 0))
            if(tempDrivingAmountTotal > 0)
            roomCalculationItem.drivingAmountTotal = (roomCalculationItem.totalArea * tempDrivingAmountTotal) + roomCalculationItem.extraPrice1 + roomCalculationItem.extraPrice2;

            var tempPaintTimerTotal =  (roomCalculationItem.materialList.filter(m => !m.notCalculateToDriving).reduce((sum, current) => sum + (current.timer ? roomCalculationItem.totalArea * current.timer : 0), 0));
            if(tempPaintTimerTotal > 0)
                roomCalculationItem.paintTimerTotal = tempPaintTimerTotal;
        }
        this.calculateRoomTotal.emit();
    }

    gotoMaterialDetail(offerMaterialId) {
        this.gotoMaterial.emit(offerMaterialId);
    }

    deleteMaterialItem(index, materialListItem, roomCalculationItem) {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: "200px",
            data: { Header: 'Headers.DeleteDiverseMaterial' }
        })

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                if (materialListItem.id) {
                    this.projectofferService.deleteMaterialItem(materialListItem.id)
                        .subscribe((response: ResponseBase) => {
                            roomCalculationItem.materialList.splice(index, 1);
                            this.calculateRoomPrice(roomCalculationItem);
                        }, error => (err) => { }, () => { });
                }
                else {
                    roomCalculationItem.materialList.splice(index, 1);
                    this.calculateRoomPrice(roomCalculationItem);
                }
            }
        });

    }

    calculateRoomMaterialPrice(roomCalculationItem) {
        if (roomCalculationItem.materialList.length > 0) {
            roomCalculationItem.materialList.filter((mat, i) => {
                this.getPriceForCategoryTimeSelect(mat.offerMaterialId, mat.offerMaterialPriceId, i, roomCalculationItem);
            })
        }
    }
    priceChange(arg, materialListItem, roomCalculationItem) {
        materialListItem.notCalculatePrice = true;
        this.calculateRoomPrice(roomCalculationItem);
    }
    checkUnckeckedNotCalculatePrice(materialListItem) {
        materialListItem.notCalculatePrice = false;
    }
    _handleKeydown(event: KeyboardEvent) {
        if (event.keyCode === 13) {
            event.stopPropagation();
        }
    }

    getMaterialText(material, index, materialListItem) {
        var type = materialListItem.selectCategoryTime.find(x => x.key == materialListItem.paintKey);
        if (this.roomCalculationItem.materialList.length > 0) {
            if (material.addNumberOfTimesOnPDF) {
                this.roomCalculationItem.materialList[index].name = material.text + " " + material.offerMaterialPriceText + " " + this.projectofferService.getPaintType(type.id);
            } else {
                this.roomCalculationItem.materialList[index].name = material.text + " " + material.offerMaterialPriceText;
            }
        }
    }
    getMaterialTextOnTimeSelection(offerMaterialId, index, materialListItem) {
        var type = materialListItem.selectCategoryTime.find(x => x.key == materialListItem.paintKey);
        if (this.roomCalculationItem.materialList.length > 0) {
            var material = materialListItem.materialList.find(x => x.id == offerMaterialId)
            if (material.addNumberOfTimesOnPDF) {
                this.roomCalculationItem.materialList[index].name = material.text + " " + material.offerMaterialPriceText + " " + this.projectofferService.getPaintType(type.id);
            } else {
                this.roomCalculationItem.materialList[index].name = material.text + " " + material.offerMaterialPriceText;
            }
        }
    }

    OpenTextareaEditerPopup(text, index, offerMaterialId) {
        if (!offerMaterialId || !text)
            return
        const dialogRef = this.dialog.open(TextareaEditorComponent, {
            height: '400px',
            width: '500px',
            data: { 'displayData': text ? text : "" }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result && result.isSave) {
                if (this.roomCalculationItem.materialList.length > 0) {
                    this.roomCalculationItem.materialList[index].name = result.value;
                }
            }
        });
    }   

    calculatePaintTimerOnEdit(roomCalculationItem) {        
        roomCalculationItem.paintTimerTotal = 0;        
        if (roomCalculationItem.materialList.length > 0 && roomCalculationItem.isPaint == true) {
            var tempPaintTimerTotal =  (roomCalculationItem.materialList.filter(m => !m.notCalculateToDriving).reduce((sum, current) => sum + (current.timer ? roomCalculationItem.totalArea * current.timer : 0), 0));
            if(tempPaintTimerTotal > 0)
                roomCalculationItem.paintTimerTotal = tempPaintTimerTotal;
        }
        this.calculateRoomTotal.emit();
    }
}


