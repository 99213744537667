import { Component, OnInit, ElementRef, NgZone, ViewChild, Input, Output, EventEmitter, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { emailParams } from './../../model/Project'
import { CompanyInfoSetting } from '../../model/CompanyInfo';
import { FuseTranslationLoaderService } from '../../../core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';

@Component({
  selector: 'app-text-editor-textarea',
  templateUrl: './text-editor-textarea.component.html',
  styleUrls: ['./../companyInfo/companyInfo.component.scss']
})
export class TextEditorTextAreaComponent implements OnInit {
  public editorContent = "";
  constructor(public dialogRef: MatDialogRef<TextEditorTextAreaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public translationLoader: FuseTranslationLoaderService) {
    this.translationLoader.loadTranslations(english, danish);
  }

  ngOnInit() {
    this.editorContent = this.data.displayData;
  }


  saveTabData() {
    this.dialogRef.close({ value: this.editorContent, isSave: true });
  }

  clearTabData() {
    this.editorContent = "";
  }

  colseTextEditor() {
    this.dialogRef.close({ value: this.editorContent, isSave: false });
  }
}
