export const locale = {
    lang: 'en',
    data: {
        'Header': {
            "Title": "Post Number"
        },
        "FIELDS": {
            "Postnumber":"PostNumber",
            "City":"City",
            "Percentage":"Percentage",
            "Remark":"Remark",
            "PostNumberSearch":"Search by PostNumber, City",
            "PostnumberFrom":"PostNumber From",
            "PostnumberTo":"PostNumber To",
        },
        "BUTTONS": {
            "Add": "Add",
            "Reset": "Reset"
        },
        "MESSAGES": {
            'Updated': 'Updated Successfully.',
            'Failed': 'Failed',
            'Deleted': 'Deleted successfully',
            "NORECORD": "No Record Found",
            'Created': 'Created successfully',
        },
        'VALIDATIONS': {
            'PostNummber': 'Enter postNumber',
            'City': 'Enter city',
            'Percentage': 'Enter percentage',
            'ValidPercentage': 'Enter valid percentage',
            'ValidPostNumber': 'Indtast gyldig postnumber',
        }
    }
}