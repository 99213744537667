export const locale = {
    lang: 'en',
    data: {
        'TITLE': {
            'SMSSendConfigHeader': 'SMS Configuration',
        },
        'FIELDS': {
            'SmsSCIsTimeBased':'Is Time Based ?',
            'SmsSCIsSendEmail': 'Is Send SMS ?',
            'SmsSCHoursFromSend': 'Hours from send',
            'SmsSCHoursToSend': 'Hours to send',           
            'EmailTemplate':'Email Template',
        },
        'VALIDATIONS': {
            'SmsSCHoursFromSend': 'Hours from send is required',
            'SmsHoursFromSendSmaller': 'Hours from send should be smaller than hour to send',
            'SmsSCHoursToSend': 'Hours to send is required',
            'SmsHoursToSendGreater': 'Hours to send should be greater than hour from send',
        },
        'MESSAGES': {
            'Saved': 'Saved successfully.',
        },
        'BUTTONS': {
            'SmsSCSave': 'Save',
        }
    }
};