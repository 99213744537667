export const locale = {
    lang: 'en',
    data: {
        'TITLE': {
            'ReminderPagination': 'No. of reminders per page',
        },
        'BUTTONS': {
            'CreateReminder': 'Create Reminder',
            'Cancel': 'Cancel'
        },
        'FIELDS': {
            'To': 'To',
            'DateToRemind': 'Date To Remind',
            'Text': 'Text',
            'TEXTSEARCH': 'Search',
            'Yes': 'Yes',
            'No': 'No',
            'ReceivedToMe': 'Received to me',
            'SentByMe': 'Sent by me',
            'ReminderDate': 'Select Reminder Date',
            'SORTING': 'Sort by',
            'ReminderEndDate': 'Reminder End Date',
            'DeleteAll': 'Delete all',
            'CompletedDate': 'Completed Date',
            'Not-Found-REMINDER': 'Reminder not found',
            'Reset': 'Reset',
            'Done': 'Make everyone "Done"',
            'GetAll': 'Show all'
        },
        'TABS': {
            'SentByMe': 'Sent by me',
        },
        'MESSAGES': {
            'NORECORD': 'No Record Found'
        }
        ,
        'TOOTIPORTITLE': {
            'Edit': 'Edit',
            'Delete':'Delete',
            'Expand':'Expand',
            'ChangeReminderStatus':'Mark As Done'
        }
    }
}