import { Component, OnInit, ElementRef, NgZone, ViewChild, Input, Output, EventEmitter, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { FuseTranslationLoaderService } from '../../../../../core/services/translation-loader.service';
import { TitleService } from '../../../../../core/services/title.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { EventEmitterEnum } from '../../../../../core/enum/event-emitter-ids.enum';
import { CustomersService} from "app/main/pages/customer/customer.service"
import { RequestBase, RequestCustomerLog } from '../../../../model/RequestBase';
import { ResponseBase } from '../../../../model/ResponseBase';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { MatOption, MatDialog } from '@angular/material';
import { SupplierDetailComponent } from '../supplierDetailModal/supplierDetail.component'
import { ProjectService } from 'app/main/pages/project/project.service';
import { environment } from '../../../../../../environments/environment';

@Component({
    selector: 'vibe-supplierLogs',
    templateUrl: './supplierLogs.component.html',
    styleUrls: ['./supplierLogs.component.scss']
})
export class SupplierLogsComponent implements OnInit {
    showSpinner = false;
    @Input() supplierId: string;
    requestModel: RequestCustomerLog = new RequestCustomerLog();
    supplierLogData: any = [];
    displayPagination: boolean;
    pagination: any;
    limitNumber: any;
    //Initiators
    constructor(private translationLoader: FuseTranslationLoaderService,
        private customersService: CustomersService,
        private projectservice: ProjectService,
        public dialog: MatDialog, ) {
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
        this.requestModel.Index = 1;
        this.requestModel.Limit = 10;
        this.requestModel.OrderBy = "ModifyDate DESC";
        this.getCustomerLogs(this.supplierId);
    }

    getCustomerLogs(supplierId) {
        this.showSpinner = true;
        this.requestModel.DebitorId = supplierId;
        this.customersService.GetCustomerLogs<ResponseBase>(this.requestModel)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.showSpinner = false;
                    if (response != null && response.result) {
                        this.supplierLogData = response.result;
                        this.pagination = response.pagination;
                        this.displayPagination = true;
                    }
                    else {
                        this.supplierLogData = [];
                        this.displayPagination = false;
                    }
                },
                error: err => {
                    this.showSpinner = false;
                    this.supplierLogData = [];
                    this.displayPagination = false;
                },
                complete: () => { }
            });
    }

    receivePaginatonEvent(event) {
        this.requestModel.Index = event.pageIndex;
        this.requestModel.Limit = event.pageSize;
        this.limitNumber = event.pageSize;
        this.getCustomerLogs(this.supplierId);
    }
    getStatus(status) {
        var statusField = "";
        if (status == 0)
            statusField = "STATUS.Email"
        if (status == 1)
            statusField = "STATUS.Created"
        if (status == 2)
            statusField = "STATUS.Updated"
        if (status == 3)
            statusField = "STATUS.Deleted"
        if (status == 4)
            statusField = "STATUS.FileUploaded"
        if (status == 5)
            statusField = "STATUS.FileDeleted"
        return statusField;
    }

    viewCustomerDetailDifference(currentItem) {
        const dialogRef = this.dialog.open(SupplierDetailComponent, {
            height: '70%',
            width: '60%',
            data: currentItem.id
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result != null && result != '') {
            }
        });
    }

    viewEmailDetail(id) {
        this.projectservice.GetProjectEmailLogDetails<ResponseBase>(id)
            .subscribe({
                next: (response: ResponseBase) => {
                    var url = environment.url + response.result.filePath
                    window.open(url, "_blank");
                },
                error: err => {
                    this.showSpinner = false;
                    this.displayPagination = false;
                },
                complete: () => { }
            });
    }
}
