import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { SettingsService } from '../../settings.service';
import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { ResponseBase } from "app/main/model/ResponseBase";
import { NotificationsService } from "angular2-notifications";
import { TranslateService } from "@ngx-translate/core";
import { Settings, SettingValue } from "app/main/model/Settings";

@Component({
  selector: 'app-credintial-email',
  templateUrl: './credintial-email.component.html',
  styleUrls: ['./credintial-email.component.scss']
})
export class CredintialEmailComponent implements OnInit {
  @Input("commonSettings") commonSettings: Settings;

  showSpinner: boolean = false;
  generalForm: FormGroup;
  settingMasterValues: any[] = [];
  credintialObj: Settings;
  settingValue: SettingValue = new SettingValue();

  constructor(private translationLoaderService: FuseTranslationLoaderService,
    private settingsService: SettingsService,
    private notificationsService: NotificationsService,
    private translate: TranslateService,
    private fb: FormBuilder,) {
    this.translationLoaderService.loadTranslations(danish, english);
  }

  ngOnInit() {
    this.credintialObj = new Settings();
    this.settingMasterValues = Object.assign([], JSON.parse(localStorage.getItem("settingMasterValues")));
    this.generalForm = this.fb.group({
      SMTP: [null],
      SMTPPortNumber: [null],
      SMTPEmail: [null],
      SMTPPassword: [null],
      SMTPUserName: [null],
    });
    this.setSettings();
  }

  setSettings() {
    if (this.commonSettings && this.commonSettings != null) {
      this.credintialObj = this.commonSettings;
      this.settingValue = JSON.parse(this.commonSettings.value);
      this.generalForm.patchValue(this.settingValue)
      this.changeInLocalStorage(this.credintialObj.id, this.credintialObj)
    }
  }

  onSubmit() {
    this.generalForm.markAllAsTouched();
    if (this.generalForm.valid) {
      this.changeSettings()
    }
  }

  changeSettings() {
    this.showSpinner = true;
    this.credintialObj.value = JSON.stringify(this.generalForm.value);
    this.credintialObj.isDefaultMapper = true;
    this.settingsService.UpdateSettingWithTemplate<ResponseBase>(this.credintialObj).subscribe({
      next: (response: ResponseBase) => {
        this.showSpinner = false;
        if (response != null && response.result) {
          this.credintialObj = response.result;
          this.setSettingsAfterUpdate();
          this.translate.get("MESSAGES.Saved").subscribe({
            next: (res) => {
              this.notificationsService.create(res);
            },
            error: err => { }
          });
        }
      },
      error: err => {
      },
      complete: () => {
        this.showSpinner = false;
      }
    });
  }

  setSettingsAfterUpdate() {
    this.settingsService.GetSettingsWithTemplateValue<ResponseBase>("Credential", this.commonSettings.key2, null).subscribe({
      next: (response: ResponseBase) => {
        this.showSpinner = false;
        if (response != null && response.result && response.result.myResponse.length > 0) {
          this.credintialObj = response.result.myResponse[0];
          this.settingValue = JSON.parse(this.credintialObj.value)
          this.generalForm.patchValue(this.settingValue);
          this.changeInLocalStorage(this.credintialObj.id, this.credintialObj)
        }
      },
      error: err => {
      },
      complete: () => {
        this.showSpinner = false;
      }
    });
  }

  changeInLocalStorage(ID, new_obj) {
    if (this.settingMasterValues.find(obj => obj.id == ID)) {
      this.settingMasterValues.forEach((old_obj, index) => {
        if (old_obj.id == ID) {
          this.settingMasterValues[index] = new_obj
        }
      })
    } else {
      this.settingMasterValues.push(new_obj)
    }
    localStorage.setItem("settingMasterValues", JSON.stringify(this.settingMasterValues));
  }

}
