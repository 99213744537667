export const locale = {
    lang: 'ds',
    data: {
        'STATUS': {
            'Created': 'lavet',
            'Updated': 'Opdateret',
            'Deleted': 'Slettet',
            'FileUploaded': 'Fil uploadet',
            'FileDeleted': 'Fil slettet',
            'Email': 'Email',
        },
        'TITLE': {
            'CustomerPagination': 'Antal lager pr. side',
        },
        'BUTTONS': {
            'BACK': 'Gå tilbage',
            'BTNSAVE': 'Gem',
            'Add': 'Tilføj',
        },
        'FIELDS': {
            'SRNO': 'Sr nr.',
            'Status': 'Status',
            'Number': 'Nummer',
            'Name': 'Navn',
            'Createdby': 'OprettetAf',
            'CreatedDate': 'OprettetDato',
            'ModifyBy': 'ModificereAf',
            'ModifyDate': 'ModificereDato',
            'CoworkerName': 'Medarbejder',
            'Date': 'Dato',
            'Description': 'Tekst',
            'Action': 'Handling',
            "Materialtype": 'Vare type',
            'All': 'Alle',
            'Material': 'Lager',
        },
        'Headers': {
        },
        'MESSAGES': {
            'NORECORD': 'Ingen registrering fundet',
            'MaterialAdded': 'Debitor Lager Tilføjet.',
            'MaterialAddFailed': 'Handlingen mislykkedes, prøv senere.',
            'DeleteAlert': 'Er du sikker på, at du vil slette?',
            'MaterialDeleted': 'Debitor Lager Slettet.',
            'MaterialDeleteFailed': 'Handlingen mislykkedes, prøv senere.',
            'MaterialAlreadyAdded': 'Materiale allerede tilføjet',
        },
        'TOOTIPORTITLE':{
            'Edit':'Redigere',
            'Delete':'Slet',
            'IsActive':'Er Aktiv',
            'IsDeleted':'Er Slettet',
        }
    }
};
