export const locale = {
  lang: 'en',
  data: {
    'MESSAGES': {
      'NORECORD': 'No Record Found',
      'NoOrderAvailbale': 'No projects available',
      'DropBoxApiNotResponding': 'Dropbox not responding please try after some time',
      'FileConflict': 'File already exists',
      'FolderConflict': 'Folder name already exists',
      'Created': 'Created successfully',
      'Updated': 'Updated successfully',
      'Deleted': 'Deleted successfully',
      'Failed': 'Operation failed , try later',
      'EmailSent': 'Email sent successfully',
      'NoProjectAdded':'No project added.',
    },
    'TOOTIPORTITLE':{
      'EditDescrption':'Edit Inquiry Description'
    }
  }
};


