import { NgModule } from '@angular/core';
import { DisplayBarcodeComponent } from '../display-barcode/display-barcode.component';

@NgModule({
  declarations: [
    DisplayBarcodeComponent
  ],
  imports: [
   
  ],
  exports: [
    DisplayBarcodeComponent
  ],
  providers: []
})

export class VibeMaterialBarcodeModule {
}
