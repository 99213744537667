import { Component, OnInit, Inject } from '@angular/core';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-move-planning-message-model',
  templateUrl: './move-planning-message-model.component.html',
  styles: []
})
export class MovePlanningMessageModelComponent implements OnInit {
  Header: string = "";
  FirstButtonLabel = "";
  SecondButtonLabel = "";
  isWorking: boolean = false;

  constructor(private translationLoader: FuseTranslationLoaderService,
    public dialogRef: MatDialogRef<MovePlanningMessageModelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.translationLoader.loadTranslations(english, danish);

  }

  ngOnInit() {
    this.Header = this.data.Header;
    this.FirstButtonLabel = this.data.FirstButtonLabel;
    this.SecondButtonLabel = this.data.SecondButtonLabel;
    this.isWorking = this.data.isNonWorking ? this.data.isNonWorking : false;
  }

  onCloseDialog(e) {
    this.dialogRef.close(e)
  }

}
