import { Component, OnInit, Optional, Inject } from '@angular/core';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { MaterialService } from '../../material.service';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { NotificationsService } from 'angular2-notifications';
import { RequestBaseFull } from 'app/main/model/RequestBase';
import { OfferTreatmentType } from 'app/constant/common.constant';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA } from '@angular/material';
import { MaterialApendixCalculation } from 'app/main/model/Material';
import { DenmarkDigitFormate } from 'app/core/pipes/denmarkDigitFormate.pipe';
@Component({
  selector: 'app-appendix-calculation',
  templateUrl: './appendix-calculation.component.html',
  styleUrls: ['./appendix-calculation.component.scss']
})
export class AppendixCalculationComponent implements OnInit {
  showSpinner = false;
  materialAppendixCalculationList: MaterialApendixCalculation[] = [];
  materialAppendixCalculationObject: MaterialApendixCalculation;
  requestModel: any;
  offerTreatmentTypeList = OfferTreatmentType;
  materialType: number = 0;
  OfferTreatment: any = [];
  searchValue: string;
  isAdd: boolean = false;
  isViewInModal: boolean = false;
  settingMasterValues:any[] = [];
  coworkerRate: number = 0;
  public denmarkDigitFormate = new DenmarkDigitFormate();
  constructor(private translationLoader: FuseTranslationLoaderService,
    private translate: TranslateService,
    private materialService: MaterialService,
    private notificationsService: NotificationsService,
    private _router: Router,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.translationLoader.loadTranslations(english, danish);
    this.settingMasterValues = Object.assign([], JSON.parse(localStorage.getItem("settingMasterValues")));
    const tempSettingObject = this.settingMasterValues.find(s => s.key1 == "Project" && s.key2 == "CoworkerPrice").value;
    if(tempSettingObject && tempSettingObject)
      this.coworkerRate = Number(tempSettingObject);
    else
      this.coworkerRate = 170;
  }

  ngOnInit() {
    this.OfferTreatment = this.offerTreatmentTypeList;
    this.requestModel = new RequestBaseFull();
    this.requestModel.Index = 1;
    this.requestModel.Limit = 100;
    this.requestModel.TotalRows = 0;
    this.requestModel.OrderBy = "ModifyDate DESC";
    this.requestModel.IsDeleted = false;
    this.requestModel.IsActive = true;
    this.requestModel.Address = "";
    this.requestModel.TreatmentType = "";
    if (this.data) {
      this.requestModel.TreatmentType = this.data.MaterialTreatmentType;
      this.materialType = this.requestModel.TreatmentType;
      this.isViewInModal = this.data.isViewInModal;
    }
    this.GetMaterialAppendixCalculationList();
  }

  Add() {
    this.materialAppendixCalculationObject = new MaterialApendixCalculation()
    this.materialAppendixCalculationObject.treatmentType = 1;
    this.isAdd = true;
  }

  GetMaterialAppendixCalculationList() {
    this.materialAppendixCalculationList=[];
    this.showSpinner = true;
    this.materialService.GetMaterialAppendixCalculationList<ResponseBase>(this.requestModel)
      .subscribe({
        next: (response: ResponseBase) => {
          this.showSpinner = false;
          if (response != null && response.result) {
            this.materialAppendixCalculationList = response.result;
          }
        },
        error: err => {
          this.translate.get("MESSAGES.Failed").subscribe({
            next: (res) => {
              this.notificationsService.error(res);
              this.showSpinner = false;
            },
            error: err => {
            }
          });
        },
        complete: () => {
          this.showSpinner = false;
        }
      });
  }

  editAppendix(event) {
    this.isAdd = true;
    this.materialAppendixCalculationObject = Object.assign({}, event);
  }

  onSaveClicked(event) {
    this.isAdd = false;
    this.GetMaterialAppendixCalculationList();
  }

  onBackClicked(event) {
    this.isAdd = false;
    //this.GetMaterialAppendixCalculationList();
  }

  deleteAppendix(id) {
    this.showSpinner = true;
    this.materialService.DeleteMaterialAppendixCalculation(id).subscribe({
      next: (response: ResponseBase) => {
        this.translate.get("MESSAGES.Deleted").subscribe({
          next: (res) => {
            this.notificationsService.create(res);
            this.showSpinner = false;
            this.GetMaterialAppendixCalculationList();
          },
          error: err => {
          }
        });
      },
      error: err => {
        this.translate.get("MESSAGES.Failed").subscribe({
          next: (res) => {
            this.notificationsService.error(res);
            this.showSpinner = false;
          },
          error: err => {
          }
        });
      },
      complete: () => {
        this.showSpinner = false;
      }
    });
  }

  SearchMaterialCalculation() {
    this.showSpinner = true;
    this.requestModel.Address = this.searchValue;
    this.materialService.GetMaterialAppendixCalculationList<ResponseBase>(this.requestModel)
      .subscribe({
        next: (response: ResponseBase) => {
          this.showSpinner = false;
          if (response != null && response.result) {
            this.materialAppendixCalculationList = response.result;
          }
        },
        error: err => {
          this.translate.get("MESSAGES.Failed").subscribe({
            next: (res) => {
              this.notificationsService.error(res);
              this.showSpinner = false;
            },
            error: err => {
            }
          });
        },
        complete: () => {
          this.showSpinner = false;
        }
      });
  }

  selectTreatment() {
    this.showSpinner = true;
    this.requestModel.TreatmentType = this.materialType;
    if (this.materialType == 0)
      this.requestModel.TreatmentType = "";
    this.GetMaterialAppendixCalculationList();
  }

  getTreatmentValue(id: number) {
    const treatment = this.offerTreatmentTypeList.find(item => item.id === id);
    return treatment ? treatment.value : '';
  }

  calculateMaterialValue(value: any): string {
    if (value) {
      const getMaterialValue = value; //parseFloat(value.replace('.', ''));
      if (!isNaN(getMaterialValue) && getMaterialValue !== 0) {
        const calculationForMin = this.coworkerRate / 60;
        const convertInMin = getMaterialValue / calculationForMin;
        const roundedMaterialValue = this.denmarkDigitFormate.transform(Math.ceil(convertInMin).toFixed(2)).toString();        
        return `(${roundedMaterialValue} min)`;
      } else {
        return '';
      }
    }
    else {
      return '';
    }
  }
  
}
