export const locale = {
  lang: 'ds',
  data: {
    'Tabs': {
      'Offer': 'Tilbud',
      'BasicDetail': 'Grundlæggende detaljer',
      'File': 'Fil',
      'Category': 'Kategori',
      'WorkItem': 'Arbejdspost'
    },
    'MESSAGE': {
      'Failed': 'Operation mislykket, prøv senere',
      'Success': 'Succes.',
      'CommingSoon': 'Kommer snart..',
      'Deleted': 'Slettet med succes',
    },
    'FIELDS':{
      'GoTo':'Se tilbud',
      'SRNO': 'Sr nr.',
      'Price': 'M2 ialt',
      'QTY': 'Antal',            
      'Total': 'Pris i alt',
      'Text': 'Tekst',
      "ShowMaterials" :"Tilbud udkast",
      'TotalExclVAT': 'Total excl. moms',
      'VAT': 'Moms',
      'TotalInclVAT': 'Total incl. moms',
    },
    'BUTTONS': {
      'AddCategory': 'Tilføj kategori',
      'PDFOREMAIL': 'PDF / Email',
    },
    'Headers': {
      'DeleteCategory': 'Vil du slette kategori',
    },
    'TOOTIPORTITLE':{
      'Edit':'Redigere',
      'Delete':'Slet',
      'DeleteProject':'Slet projekt',
      'GoToPDFMail':'Go Til PDF/E-mail',

  },
  }
};
