export const locale = {
    lang: 'ds',
    data: {
        'TITLE': {
            'CreateInvoicePath':'Lav faktura sti'
        },
        'FIELDS': {
            'ProjectsCoworkerRate': 'Projekt kollega pris',
            'Path':'Sti/filnavn'
        },
        'Message': {

        },
        'FILTERS': {
        },
        'Buttons': {
            'Save':'Gem',
        },
        'Help':{
            'Note':'Brug kun skråstreg(/) i stiindstilling',
        }
    }
};