import { Component, OnInit, ChangeDetectorRef, Inject, ViewChildren, QueryList } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { QuestionParms, RequisitionStatusAnswer } from 'app/main/model/Project';

import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { ActivatedRoute } from '@angular/router';
import { ProjectService } from 'app/main/pages/project/project.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { FormGroup, Validators, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { FormService } from 'app/core/services/form.service';
import { OfferQualityTestQuestionAnswer } from "app/main/shared-component/new-questions/new-question.model";
import { AlertMessageModelComponent } from "app/main/pages/alert-message-model/alert-message-model.component";
import { FusePerfectScrollbarDirective } from "@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive";
import { environment } from 'environments/environment';
import { fuseAnimations } from "@fuse/animations";

@Component({
    selector: "app-answer-category-question",
    templateUrl: "./answer-category-question.component.html",
    styleUrls: ["./answer-category-question.component.scss"],
    animations: fuseAnimations
})

export class AnswerCategoryQuestionComponent implements OnInit {
    showSpinner: boolean = false;
    requisitionStatusAnswerObj = new RequisitionStatusAnswer()
    questionList = new QuestionParms();
    currentStep: number;
    selectedQuestion: any
    totalCount: number = 0;
    //statusQuestionform: FormGroup;
    animationDirection: 'left' | 'right' | 'none';
    courseStepContent: any;
    createQuestionForm: FormGroup;
    questionAnswerList: OfferQualityTestQuestionAnswer[] = [];
    //requisitionId: string = "";
    title: string = "";
    host: string;
    isHideAnswer: boolean = false;

    @ViewChildren(FusePerfectScrollbarDirective)
    fuseScrollbarDirectives: QueryList<FusePerfectScrollbarDirective>;

    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private dialogRef: MatDialogRef<AnswerCategoryQuestionComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _changeDetectorRef: ChangeDetectorRef,
        private _route: ActivatedRoute,
        private projectService: ProjectService,
        private translate: TranslateService,
        private notificationsService: NotificationsService,
        private formBuilder: FormBuilder,
        private dialog: MatDialog,
        private formService: FormService) {
        translationLoaderService.loadTranslations(danish, english);
        this.currentStep = 0;
    }

    ngOnInit() {
        this.host = environment.url;
        this.onCreateQuestionForm();

        this.questionList.statusQuestion = this.data.data;
        this.isHideAnswer = this.data.isHideAnswer ? this.data.isHideAnswer : false;
        this.totalCount = this.questionList.statusQuestion.length;
        this.title = this.data.title;

        this.setQueVal(0);
    }

    private onCreateQuestionForm() {
        this.createQuestionForm = this.formBuilder.group({
            id: [''],
            questionType: [''],
            question: [''],
            answer: [''],
            rating: [5], 
            choiceOptions: this.formBuilder.array([]),
            createdBy: [''],
            createdDate: [''],
            modifyBy: [''],
            modifyDate: [''],
        });
    }
    get choiceOptions() : FormArray {
        return this.createQuestionForm.get("choiceOptions") as FormArray
    }
    private clearAllValueAndValidations() {
        for (const key in this.createQuestionForm.controls) {
            this.createQuestionForm.get(key).clearValidators();
            if (key=='choiceOptions') {
                this.choiceOptions.clear();
            } else {
                this.createQuestionForm.get(key).patchValue(null);
            }
            this.createQuestionForm.get(key).updateValueAndValidity();
        }
        this.createQuestionForm.reset();
    }
    private setQueVal(currQueIndex) {
        this.selectedQuestion = Object.assign({}, this.questionList.statusQuestion[currQueIndex])        
        if(!this.selectedQuestion.files)
            this.selectedQuestion.files = []
        this.filForm(this.selectedQuestion);
    }
    private filForm(currQue) {
        this.clearAllValueAndValidations();

        this.createQuestionForm.patchValue({'id' : currQue['id']})
        this.createQuestionForm.patchValue({'questionType' : currQue['questionType']})
        this.createQuestionForm.patchValue({'question' : currQue['question']})
        this.createQuestionForm.patchValue({'answer' : currQue['answer']})
        this.createQuestionForm.patchValue({'rating' : currQue['rating']})
        //this.createQuestionForm.patchValue({'choiceOptions' : }) : this.fb.array([]),
        this.createQuestionForm.patchValue({'createdBy' : currQue['createdBy']})
        this.createQuestionForm.patchValue({'createdDate' : currQue['createdDate']})
        this.createQuestionForm.patchValue({'modifyBy' :currQue['modifyBy']})
        this.createQuestionForm.patchValue({'modifyDate' : currQue['modifyDate']})

        let choiceOptionsList:any[] = [];
        try {
            choiceOptionsList = Object.assign([], JSON.parse(currQue['choiceOptions']))
        } catch (e) {
            choiceOptionsList = Object.assign([], currQue['choiceOptions'])
        }
        
        if(choiceOptionsList && choiceOptionsList.length>0) {            
            for (let j = 0; j < choiceOptionsList.length; j++) {
                const elementObj = this.formBuilder.group({
                    id: new FormControl(j+1),
                    name: new FormControl(choiceOptionsList[j]['name']),
                    question: new FormControl(choiceOptionsList[j]['question']),
                    answer: new FormControl(choiceOptionsList[j]['answer']),
                    checked: new FormControl(choiceOptionsList[j]['checked']),
                    reminderMsg: new FormControl(choiceOptionsList[j]['reminderMsg']),
                    remindCoWorkerIDs: new FormControl(choiceOptionsList[j]['remindCoWorkerIDs']),
                    isSendReminder: new FormControl(choiceOptionsList[j]['isSendReminder']),
                    isSendRemindBySMS: new FormControl(choiceOptionsList[j]['isSendRemindBySMS']),
                    isSendRemindByEmail: new FormControl(choiceOptionsList[j]['isSendRemindByEmail']),
                    isSendRemindByNotification: new FormControl(choiceOptionsList[j]['isSendRemindByNotification']),
                })
                this.choiceOptions.push(elementObj);
            }
        }
        
        this.setQueValidation(currQue['questionType'])
    }
    private setQueValidation(queType) {
        if(queType == 'Textbox') {
            this.createQuestionForm.controls['answer'].setValidators([Validators.required]);
            this.createQuestionForm.controls['answer'].updateValueAndValidity();
        } else if(queType == 'SingleChoice') {
            this.choiceOptions.value.forEach((element, index) => {
                if(element['checked'] && element['question']) {
                    this.choiceOptions.controls[index].get('answer').setValidators([Validators.required]);
                    this.choiceOptions.controls[index].get('answer').updateValueAndValidity();
                }
            });
        }
    }
    public setMCValidation(val, i) { 
        this.choiceOptions.controls[i].get('checked').patchValue(val);
        
        if(val && this.choiceOptions.controls[i].get('question').value) {
            this.choiceOptions.controls[i].get('answer').patchValue("");
            this.choiceOptions.controls[i].get('answer').setValidators([Validators.required]);
            this.choiceOptions.controls[i].get('answer').updateValueAndValidity();
        } else {
            this.choiceOptions.controls[i].get('answer').patchValue("");
            this.choiceOptions.controls[i].get('answer').setValidators(null);
            this.choiceOptions.controls[i].get('answer').updateValueAndValidity();
        }
    }
    public setSCValidation(i) {
        this.selectedQuestion.choiceOptions.forEach((item, index) => {
            item.checked = false;
            this.choiceOptions.controls[index].get('checked').patchValue(false);
            
            item.answer = "";
            this.choiceOptions.controls[index].get('answer').patchValue("");
            this.choiceOptions.controls[index].get('answer').setValidators(null);
            this.choiceOptions.controls[index].get('answer').updateValueAndValidity();
        });
        this.selectedQuestion.choiceOptions[i].checked = true;
        this.choiceOptions.controls[i].get('checked').patchValue(true);

        if(this.choiceOptions.controls[i].get('question').value) {
            this.choiceOptions.controls[i].get('answer').patchValue("");
            this.choiceOptions.controls[i].get('answer').setValidators([Validators.required]);
            this.choiceOptions.controls[i].get('answer').updateValueAndValidity();
        }
    }
    private isFormValid(): boolean {
        this.createQuestionForm.markAllAsTouched();

        if(this.createQuestionForm.invalid) {
            return false;
        }

        if(this.createQuestionForm.value.questionType=='SingleChoice') {
            let isAnyChecked = false;         
            this.choiceOptions.value.forEach(element => {
                if(element['checked']) {
                    isAnyChecked = true;
                }
            });
            if(isAnyChecked==false) {
                const dialogRef = this.dialog.open(AlertMessageModelComponent, {
                    height: "200px",
                    data: {
                        Header: "MESSAGES.SelectAnyOption",
                        ShowOKButton: true
                    }
                });
                dialogRef.afterClosed().subscribe(result => {
                    if (result) {
                    }
                });
                return false;
            }
        }

        return true;
    }

    gotoNextStep(value): void {      
        if(this.isFormValid()) {
            this.questionList.statusQuestion[value] = Object.assign({}, this.selectedQuestion)
    
            var index = value + 1
            this.currentStep = index;
            if (this.currentStep < this.totalCount) {
                this.setQueVal(this.currentStep)

                // Set the animation direction
                this.animationDirection = 'left';
        
                // Run change detection so the change
                // in the animation direction registered
                this._changeDetectorRef.detectChanges();
            }    
        }
    }

    gotoPreviousStep(value): void {
        this.questionList.statusQuestion[value] = Object.assign({}, this.selectedQuestion)
        var index = value - 1
        this.currentStep = index;
        if (this.currentStep == -1) {
            this.currentStep = 0;
        }

        // Set the animation direction
        this.animationDirection = 'right';

        // Run change detection so the change
        // in the animation direction registered
        this._changeDetectorRef.detectChanges();

        // Decrease the current step
        //this.currentStep--;

        this.setQueVal(this.currentStep)
    }

    SaveAnswer(value) {
        this.questionList.statusQuestion[value] = Object.assign({}, this.selectedQuestion)
        if(this.isFormValid()) {
            this.dialogRef.close(JSON.stringify(this.questionList.statusQuestion));
        }
    }

    close() {
        this.dialogRef.close(null);
    }

    getSelectedRating(selectedQuestion) {
        if (selectedQuestion.rating) {
            this.selectedQuestion.rating = selectedQuestion.rating;
        }
    }



    /**
    * After view init
    */
    ngAfterViewInit(): void {
        this.courseStepContent = this.fuseScrollbarDirectives.find((fuseScrollbarDirective) => {
            return fuseScrollbarDirective.elementRef.nativeElement.id === 'course-step-content';
        });
    }

    /**
    * Go to step
    *
    * @param step
    */
    gotoStep(step): void {       
        // Decide the animation direction
        this.animationDirection = this.currentStep < step ? 'left' : 'right';

        // Run change detection so the change
        // in the animation direction registered
        this._changeDetectorRef.detectChanges();

        // Set the current step
        this.currentStep = step;
    }

}