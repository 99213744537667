export const locale = {
    lang: 'en',
    data: {
        'MESSAGES': {
            'Success':'Success'
        },
        'FIELDS': {
        'Email': 'Primary Email',
        'InvoiceEmail': 'Secondary Email',
        },
        'BUTTONS': {
            'Print': 'Print',
            'Email': 'Email',
            'PrintAndEmail': 'Print And Email',
            'SaveAndExit': 'Save and exit',
        },
    }
};
