import { Component, OnInit, Optional } from '@angular/core';
import { Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { ProjectService } from 'app/main/pages/project/project.service';
import { ProjectRequisitionService } from 'app/main/pages/project/project.requisition.service';
import { TranslateService } from '@ngx-translate/core';
import { RequestRequisition } from 'app/main/model/RequestBase';
import { ResponseBase } from 'app/main/model/ResponseBase';
@Component({
    selector: 'app-select-project-requistion-modal',
    templateUrl: './select-project-requistion-modal.component.html',
    styleUrls: ['./select-project-requistion-modal.component.scss']
})
export class SelectProjectRequistionComponent implements OnInit {
    showSpinner: boolean = false;
    showRequistionList: boolean = false;
    requistionId: string = "";
    requisitionList: any = [];
    projectAndRequistion: any = {};
    constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<SelectProjectRequistionComponent>,
        private dialog: MatDialog,
        private translate: TranslateService,
        private projectservice: ProjectService,
        private projectRequisitionService: ProjectRequisitionService,
        private translationLoader: FuseTranslationLoaderService) {
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
    }

    selectProjectEventHandler(event) {
        //var projectId = event.sagId;
        this.projectAndRequistion.project = event;
        var modelRequisition = new RequestRequisition();
        modelRequisition.ProjectId = event.sagId;
        this.getRequisitionList(modelRequisition);
    }

    getRequistionNumber(requistion) {
        this.projectAndRequistion.requistion = requistion;
        this.requistionId = requistion.id;
    }

    gotToSupplierList() {
        //this.dialogRef.close(this.coworkerId);
        this.dialogRef.close(this.projectAndRequistion);
    }

    backToProject() {
        this.showRequistionList = false;
    }

    getRequisitionList(model: RequestRequisition) {
        this.showSpinner = true;
        this.projectRequisitionService.Get<ResponseBase>(model.ProjectId, model).subscribe({
            next: (response: ResponseBase) => {
                if (response) {
                    this.showRequistionList = true;
                    this.requisitionList = response.result;
                }
                else
                    this.requisitionList = [];
            },
            error: err => {
                this.requisitionList = [];
                this.showRequistionList = false;
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }
}
