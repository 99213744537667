export const locale = {
  lang: 'ds',
  data: {
    'Tabs': {
      'Offer': 'Tilbud',
      'BasicDetail': 'Grundlæggende detaljer',
      'File': 'Fil',
      'Category': 'Kategori',
      'WorkItem': 'Arbejdspost'
    },
    'MESSAGE': {
      'Failed': 'Operation mislykket, prøv senere',
      'Success': 'Succes.',
      'CommingSoon': 'Kommer snart..',
      'Deleted': 'Slettet med succes',
    },
    'FIELDS':{
      'GoTo':'Gå til'
    },
    'BUTTONS': {
      'AddCategory': 'Tilføj kategori'
    },
    'Headers': {
      'DeleteCategory': 'Vil du slette kategori',
    }
  }
};
