export const locale = {
    lang: 'ds',
    data: {
        'TITLE': {
            'NotificationOfCowerker': 'Vonage SMS Medarbejdere Indstilling',
        },
        'BUTTONS': {
            'Save': 'Gem',
        }
    }
};