import { Component, OnInit, Inject } from '@angular/core';
import { PasswordValidation } from '../../../password-validation';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ChangePasswordService } from '../change-password/change-password.service';
import { ChangePassword } from 'app/main/model/ChangePassword';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { FuseTranslationLoaderService } from '../../../core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { FormService } from '../../../core/services/form.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styles: []
})


export class ChangePasswordComponent implements OnInit {

  password: ChangePassword;
  ChangePasswordFormErrors: any;
  ChangePasswordForm: FormGroup;
  error: boolean = false;
  constructor(private formBuilder: FormBuilder, private dialogRef: MatDialogRef<ChangePasswordComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog, private changePasswordService: ChangePasswordService,
    private translationLoader: FuseTranslationLoaderService,
    private notificationsService: NotificationsService,
    private translate: TranslateService,
    private formService: FormService) {
    this.error = false;
    this.translationLoader.loadTranslations(english, danish);
  }

  ngOnInit() {
    this.password = new ChangePassword();
    this.ChangePasswordForm = this.formBuilder.group({
      oldPassword: ['', Validators.required],
      //newPassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20), Validators.pattern("/^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/")]],
      newPassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]],
      comparePassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]]
    }
      , {
        validator: PasswordValidation.MatchPassword
      }
    )
  }

  onChangePassword(event) {
    event.preventDefault();
    this.formService.markFormGroupTouched(this.ChangePasswordForm);
    if (this.ChangePasswordForm.valid) {
      this.changePasswordService.Update<ResponseBase>(this.password).subscribe({
        next: () => {
          this.translate.get("FIELDS.Updated").subscribe({
            next: (res) => {
              this.notificationsService.create(res);
            },
            error: err => {
            }
          });
          this.dialogRef.close(this.password);
        },
        error: err => {
          this.translate.get("FIELDS.Failed").subscribe({
            next: (res) => {
              this.notificationsService.error(res);
            },
            error: err => {
            }
          });
        },
        complete: () => { }
      });
    }
  }

  onCancel() {
    this.dialogRef.close();
  }
}
