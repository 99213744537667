export const locale = {
    lang: 'en',
    data: {
        'TITLE': {
        },
        'FIELDS': {
            'ProjectsCoworkerRate': 'Projects Coworker Rate',
            'IndoorOutDoor': 'Indoor,Outdoor,Spray Painting,Reclamation*',
            'SORTING_STATUS': 'Status',
        },
        'Message': {

        },
        'FILTERS': {
            'All': 'All',
        },
        'Buttons': {
            'Save': 'Save',
        },
        'selectedValueIndoorOutdoorStatus': {
            'value1': 'Indoor ',//Man in a room
            'value2': 'Outdoor', //Sun
            'value3': 'Spray Painting',//spraypaint
            'value4': 'Complaint', //Reklamation
            'value5': 'Staff purchase', //drawing of painter
            'value6': 'Store purchase' //Nordsjø logo
          },
          'TOOTIPORTITLE':{
            'ProjectWorkingHoursDetails':'Project Hours Details'
          }
    }
};