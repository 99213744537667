export const locale = {
  lang: 'ds',
  data: {
    'HEADER': {
      'MoveRequisition': 'Er du sikker på, at du vil flytte',
      'Noco-workeradded': 'Ingen medarbejder fundet',
    },
    'BUTTONS': {
      'Ok': 'Ok',
      'BACK': 'Tilbage'
    },
    'FIELDS': {
      'From': 'Fra',
      'To': 'til',
      'HoursBack': 'Timer tilbage',
      'Before60Days': '*** 60 Dag gammel.',
      'RemainingTime': 'Resterende tid',
      'Planned': 'Planlagt',
      'HourlyWage': 'Timeløn',
      'visit': 'Besøg',
      'SRNO': 'Sr nr',
      'Name': 'Navn',
      'MobileNo': 'Mobil nr.',
    },
    'MESSAGES': {
      'NORECORD': 'Intet projekt fundet'
    },
    'FILTERS': {
      'IndoorOutDoor': 'Ind, ud, Sprøjt, Reklamation *',
      'SEARCH': 'Søg',
      'SORTING': 'Sorter efter',
      'SORTING_STATUS': 'Status',
      'Active': 'Inkluder alle inaktive',
      'BADGE': 'Inaktive',
      'Deleted': 'Vis KUN slettet sager',
      'All': 'Alle',
      'Lift/Stigemand': 'Lift/Stigemand',
      'Indoor/Outdoor': 'Indoor/Outdoor',
      'Lift/Stigmand': 'Lift/Stigmand',
      'Ind/Ud': 'Ind/Ud',
      'MtoM': 'M til M',
      'Note': 'Bemærk',
      'Offers': 'Tilbud',
      'Show60DayOld': 'Vis 60 dage gammel',
      'ShowHourDetails': 'Time beskrivelser',
      'TotalProject': 'I alt projekter',
      'TotalProjectRemainingHours': 'Projekter resterende timer',
      'TotalProjectDaysLeft': 'Projekter resterende Dage',
      'LastDayWorkingHour': 'Sidste dags arbejdstid',
      'ShowTime': 'Vis Time'
    },
    'selectedValueIndoorOutdoorStatus': {
      'value1': 'Indendørs ',
      'value2': 'Udendørs',
      'value3': 'Sprøjtemaling',
      'value4': 'Reklamation',
      'value5': 'Personale køb', //drawing of painter
      'value6': 'Butiks køb'     //Nordsjø logo
    },
    // 'selectedValueStatus': {
    //   'value1': 'Process',
    //   'value2': 'Planlagt besøg',
    //   'value3': 'Svare ikke',
    //   'value4': 'Tilbud sendt',
    //   'value5': 'Tilbud godkendt',
    //   'value6': 'Planlagt',
    //   'value7': 'Tilbud afvist',
    //   'value8': 'Startet',
    //   'value9': 'Udført',
    //   'value10': 'Faktureret',
    //   'value11': 'Afventer',
    //   'value12': 'Klar fakturering',
    // },
  }
};
