export const locale = {
    lang: 'ds',
    data: {
        'Tabs': {
            'Offer': 'Tilbud',
            'BasicDetail': 'Grundlæggende detaljer',
            'File': 'Fil',
            'Category': 'Kategori',
            'WorkItem': 'Behandlinger'
        },
        'FIELDS': {
            'OfferName': 'Tilbud Navn',
            'OfferPrice': 'Tilbudspris',
            'TotalExclVAT': 'Total excl. moms',
            'VAT': 'Moms',
            'TotalInclVAT': 'Total incl. moms',
            'IsIncludePriceOnPDF': 'Inkluderet Pris På PDF',
            'SRNO': 'Sr nr.',
            'Date': 'Dato',
            'ModuleName': 'Modulnavn',
            'ToEmail': 'Til email',
            'CoworkerName': 'Medarbejder',
            'EmailHistory': 'Email Historie',
            'Email': 'Email',
            'Attachment': 'Attachment',
            'ShowRoomTotal': 'Vis rum total',
            'ConvertedToProject': 'Konvertert projekt',
        },
        'MESSAGES': {
            'Failed': 'Operation mislykket, prøv senere',
            'Success': 'Succes.',
            'NoList': 'Intet tilbud fundet.'
        },
        'BUTTON': {
            'SendOfferOnEmail': 'Send tilbud på Email',
            'SeeOffer': 'Se tilbud',
            'SeeOfferWithRoom': 'Se tilbud med rum',
            'PDFOREMAIL': 'PDF / Email',            
        },
        'TOOTIPORTITLE': {
            'EditOfferText': 'Rediger tilbud tekst',
            'Edit': 'Rediger',
            'Delete': 'Slet',
            'ViewEmailTemplate':'Se E-mail Skabelon',
            'IsShowOfferInPDF':'Vis tilbud i PDF',
            'IsMaterial':'Tilføj materiale til projektet',
            'ViewMaterial':'Se materiale'


        }
    }
};
