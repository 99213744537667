export const locale = {
    lang: 'en',
    data: {
        'MESSAGES': {
            //'Created': 'Created successfully',
            'OfferPriceDel': 'Price Deleted Successfully.',
        },
        'Headers': {
            'DeleteOfferPrice': 'Are you sure you want to delete price.'
        },
        'FIELDS': {
            'OfferText': 'Offer Name',
            'MaterialType': 'Material Type',
            'Time': 'Number of times',
            'Price': 'Price',
            'PaintText': 'Paint text',
            'StartMeter1': 'Start Meter 1',
            'EndMeter1': 'End Meter 1',
            'PriceMeter1': 'Price Meter 1',
            'TimeMeter1': 'Time Meter 1',
            'StartMeter2': 'Start Meter 2',
            'EndMeter2': 'End Meter 2',
            'PriceMeter2': 'Price Meter 2',
            'TimeMeter2': 'Time Meter 2',
            'StartMeter3': 'Start Meter 3',
            'EndMeter3': 'End Meter 3',
            'PriceMeter3': 'Price Meter 3',
            'TimeMeter3': 'Time Meter 3',
            "TreatmentText": 'Treatment Text',
            'Reminder': 'Reminder',
            'ExpensesType': 'Expenses Type',
            'ExpensesAmount': 'Expenses Amount',
            'AddNumberOfTimesOnPDF':'Add no. of times on PDF',
            'IsAddedWoodwork':'Is added woodwork',
            'TreatmentType':'Treatment Type',
            'AddPriceOnPDF':'Add price on PDF',
            'AddUnitOnPDF':'Add Unit On PDF',
            'NotCalculateToDriving':'Not calculate to Driving',
            'TimeMeter': 'Time Meter',
        },
        'BUTTONS': {
            'ADDOFFER': 'Add Offer',
            'REMOVEOFFER': 'Remove Offer',
            'ADDPRICE': 'Price M2',
            'ADDNOOFTIMES': 'No. Of Times',
            'Calculation':'The price book'
        },
        'Validations': {
            'StartMeter1': 'Should not be null.',
            'EndMeter1': 'Should not be null.',
            'PriceMeter1': 'Should not be null.',
            'StartMeter2': 'Should not be null.',
            'EndMeter2': 'Should not be null.',
            'PriceMeter2': 'Should not be null.',
            'StartMeter3': 'Should not be null.',
            'EndMeter3': 'Should not be null.',
            'PriceMeter3': 'Should not be null.',
            'Text': 'Offer name should not be null.',
            'Type': 'Material type should not be null.'
        },
        'TOOTIPORTITLE':{
            'Edit':'Edit',
            'Delete':'Delete',
        },
    }
};

