import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
//import { MatSelectModule } from '@angular/material';

import { VibeSelectControlComponent } from './vibe-select-control.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MaterialModule } from '@fuse/material.module';
import { FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FusePipesModule } from 'app/core/pipes/pipes.module';
import { FullscreenOverlayContainer, OverlayContainer } from '@angular/cdk/overlay';

@NgModule({
  declarations: [ VibeSelectControlComponent ],
  imports:      [ 
                  BrowserModule,
                  MaterialModule,
                  FlexLayoutModule,
                  FusePipesModule,
                  TranslateModule,
                  FormsModule,
                  ReactiveFormsModule,
                  //MatSelectModule,
                  NgxMatSelectSearchModule,
  ],
  exports:      [ VibeSelectControlComponent ],
  providers:    [ 
                  {
                    provide: OverlayContainer, 
                    useClass: FullscreenOverlayContainer
                  }
                ]
})
export class VibeSelectControlModule { }
