import { Component, OnInit, ViewChild } from "@angular/core";
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { MatDialog, MatOption } from '@angular/material';
import { ProjectStatus } from 'app/constant/common.constant';

import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { projectStatistic } from 'app/main/model/Project';
import { ShowInactiveProjectComponent } from 'app/main/pages/project/component/show-inactive-project/show-inactive-project.component';
import { Router } from "@angular/router";

@Component({
    selector: "statistic-project",
    templateUrl: "./statistic-project-component.html",
    styleUrls: ["./statistic-project-component.scss"]
})

export class StatisticProjectComponent implements OnInit {
    showSpinner: boolean = false;
    @ViewChild('allStatusSelected', { static: false }) private allStatusSelected: MatOption;
    projectStatus = ProjectStatus;
    selectedYear: any = new Date().getFullYear();
    statisticProjectData: any;
    projectStatisticObj = projectStatistic;
    currentselectedStatusObj: number[];
    constructor(private translationLoaderService: FuseTranslationLoaderService,
        public dialog: MatDialog,
        private _router: Router) {
        this.translationLoaderService.loadTranslations(danish, english);
    }

    ngOnInit() {
        this.getDataDetail();
    }

    getDataDetail() {
        this.statisticProjectData = {
            widget5: {
                chartType: 'line',
                datasets: {
                    2018: [
                        {
                            label: 'Process',
                            data: [200, 400, 240, 520, 790, 190, 950, 180, 210, 780, 420, 990],
                            status: 1

                        },
                        {
                            label: 'Scheduled visit',
                            data: [2050, 9070, 3800, 5800, 3800, 3200, 2900, 190, 3100, 3400, 4100, 3800],
                            status: 2
                        }
                    ],
                    2019: [
                        {
                            label: 'Ready to boot',
                            data: [4110, 1380, 3210, 290, 190, 2390, 1250, 4380, 3300, 1340, 8220, 290],
                            status: 3
                        },
                        {
                            label: 'Offer Sent',
                            data: [3000, 3400, 4100, 3800, 2200, 3200, 2900, 1900, 2900, 3900, 2500, 3800],
                            status: 4

                        }
                    ],
                    2020: [
                        {
                            label: 'Offer approved',
                            data: [410, 380, 320, 2090, 1190, 2390, 1250, 380, 3500, 1340, 2220, 4290],
                            status: 5
                        },
                        {
                            label: 'Planned',
                            data: [3000, 3400, 4100, 3800, 200, 3200, 2900, 190, 2900, 3900, 200, 800],
                            status: 6

                        }
                    ],
                    2021: [
                        {
                            label: 'Offer rejected',
                            data: [410, 380, 320, 290, 190, 390, 250, 380, 300, 340, 220, 290],
                            status: 7
                        },
                        {
                            label: 'Started',
                            data: [3000, 3400, 4100, 3800, 2200, 3200, 2900, 1900, 2900, 3900, 2500, 3800],
                            status: 8

                        }
                    ]
                },
                labels: ['12am', '2am', '4am', '6am', '8am', '10am', '12pm', '2pm', '4pm', '6pm', '8pm', '10pm'],
                colors: [
                    {
                        borderColor: '#3949ab',
                        backgroundColor: '#3949ab',
                        pointBackgroundColor: '#3949ab',
                        pointHoverBackgroundColor: '#3949ab',
                        pointBorderColor: '#ffffff',
                        pointHoverBorderColor: '#ffffff'
                    },
                    {
                        borderColor: 'rgba(30, 136, 229, 0.87)',
                        backgroundColor: 'rgba(30, 136, 229, 0.87)',
                        pointBackgroundColor: 'rgba(30, 136, 229, 0.87)',
                        pointHoverBackgroundColor: 'rgba(30, 136, 229, 0.87)',
                        pointBorderColor: '#ffffff',
                        pointHoverBorderColor: '#ffffff'
                    }
                ],
                options: {
                    spanGaps: false,
                    legend: {
                        display: false
                    },
                    maintainAspectRatio: false,
                    tooltips: {
                        position: 'nearest',
                        mode: 'index',
                        intersect: false
                    },
                    layout: {
                        padding: {
                            left: 24,
                            right: 32
                        }
                    },
                    elements: {
                        point: {
                            radius: 4,
                            borderWidth: 2,
                            hoverRadius: 4,
                            hoverBorderWidth: 2
                        }
                    },
                    scales: {
                        xAxes: [
                            {
                                gridLines: {
                                    display: false
                                },
                                ticks: {
                                    fontColor: 'rgba(0,0,0,0.54)'
                                }
                            }
                        ],
                        yAxes: [
                            {
                                gridLines: {
                                    tickMarkLength: 16
                                },
                                ticks: {
                                    stepSize: 1000
                                }
                            }
                        ]
                    },
                    plugins: {
                        filler: {
                            propagate: false
                        }
                    }
                }
            }
        };
    }

    onSelectYear(event) {
        if (event) {
            this.selectedYear = this.selectedYear.getFullYear();
        }
    }

    onFocusIn(event) {
        event.model.show();
    }

    toggleAllStatusSelection() {
        this.currentselectedStatusObj = [];
        if (this.allStatusSelected.selected) {
            for (var i = 0; i < 13; i++) {
                this.currentselectedStatusObj.push(i);
            }
        }
    }

    DropDownStatusChange(value) {

    }

    SearchProjectChart() {
        if (this.selectedYear && this.currentselectedStatusObj.length > 0) {
            this.showSpinner = true;
            // let year = this.selectedYear.getFullYear();
            // var SelectedYear = year.toString();
            this.projectStatisticObj = [];
            var data = this.statisticProjectData.widget5.datasets[this.selectedYear];
            if (data) {
                this.statisticProjectData.widget5.datasets[this.selectedYear].find(o => {
                    if (o) {
                        for (let i = 0; i < this.currentselectedStatusObj.length; i++) {
                            if (o.status == this.currentselectedStatusObj[i]) {
                                this.projectStatisticObj.push(o);
                            }
                        }
                    }
                });
            }
            this.showSpinner = false;
        }
    }

    ShowInactiveProjectList() {
        const dialogRef = this.dialog.open(ShowInactiveProjectComponent, {
            height: "90%",
            width: "90%",
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                var id = result;
                this.EditCustomer(id)
            }
        });
    }
    EditCustomer(id) {
        this._router.navigate(['/projects/edit', id], { queryParams: { isActive: false } }); //navigationExtras
    }
}