export const locale = {
    lang: 'en',
    data: {
      'PROJECT': {
        'TITLE': 'Project',
        'DESC': 'Hello Project!'
      },
      'BUTTONS': {
        'BACK': 'Back',
        'SAVE': 'Save',
    },
      'TITLE': {
        'ProjectPagination': 'Antal projekter pr. side',
      },
      'FILTERS': {
        'SEARCH': 'Search',
        'SORTING': 'Sort By',
        'SORTING_STATUS': 'Status',
        'Active': 'Include All Inactive',
        'BADGE': 'Inactive',
        'Deleted': 'Show ONLY deleted cases',
        'All': 'All',
        'Lift/Stigemand': 'Lift/Stigemand',
        'Indoor/Outdoor': 'Indoor/Outdoor',
        'Lift/Stigmand': 'Lift/Stigmand',
        'Ind/Ud': 'Ind/Ud',
        'MtoM': 'M to M',
        'Note': 'Note',
        'Offers': 'Offers',
        'Show60DayOld': 'Show only 60 days ',
        'ShowHourDetails': 'Show hourly details',
        'TotalProject': 'Total Projects',
        'TotalProjectRemainingHours': 'Project Remaining Hours',
        'TotalProjectDaysLeft': 'Project Remaining Days',
        'LastDayWorkingHour': 'Last Day Working Hours',
        'Text': 'Text*',
        'OpgaveText': 'Opgave Tekst',
        'InvoiceText': 'Invoice Text',
        'PurchaseStatus': 'Purchase Status',
        'AverageAmount': 'Average amount',
        'TotalProjectRemainingPris': 'Project remaining price',
        'SearchByZip': 'Search Zipcode',
      },
      'MESSAGES': {
          'NORECORD': 'No Record Found',
          'NORECORDONSTATUS':'You find project may be other status or Inactive or deleted...',
          "SELECTCOWORKER":'Please select coworker',
          'Created': 'Created successfully',
          'Failed': 'Operation failed , try later',
      },
      'FIELDS': {
        'IndoorOutDoor': 'Indoor,Outdoor,Spray Painting,Reclamation*',
        'Lift': 'Left',
        'Stigemand': 'Riseman',
        'CaseNumber': 'Case Number',
        'Title': 'Title',
        'Client': 'Client',
        'Address': 'Address',
        'ProjectEndDate': 'End Date',
        'EndDate': 'End Date',
        'CoWorkers': 'Co-Workers',
        'Files': 'Files',
        'actionsColumn': 'Action',
        'From': 'From',
        'To': 'to',
        'Not-Found-CUSTOMER': 'Customer not found',
        'Not-Found-MATERIAL': 'Material not found',
        'Not-Found-Worker': 'Co-worker not found',
        'TOTAL': 'Total',
        'Coworker': 'Coworker',
        'HoursBack': 'Hours Back',
        'Before60Days': '*** 60 Day old.',
        'NoOfEmployee': 'Number of employees',
        'MaterialPurchasePriseTotal': 'Sum Stock',
        'RemainingTime': 'RemainingTime',
        'Planned': 'Planned',
        'HourlyWage': 'Hourly rate',
        'Dankort': 'Dankort',
        'PaymentMode1': 'Dankort',
        'PaymentMode2': 'Mastercard',
        'PaymentMode3': 'Cash',
        'PaymentMode4': 'Mobile payment',
        'PaymentMode5': 'Mobile payment KK',
        'PaymentMode6': 'Credit',
        'PaymentMode7': 'Other',
        'visit': 'Visit',
        'Text': 'Text*',
        'OpgaveText': 'Requisition Text',
        'InvoiceText': 'Invoice Text',
        'TotalProjectPrice': 'Total Project Price',
        'SagNumber':'Case Number',
        'Name':'Name',
        'ZipCode':'ZipCode',
        'city':'City',
        'CustomerName':'Customer Name',
        'CustomerDetail': 'Customer Detail',
        'ProjectAddress': 'Project Address',
      },
    }
  };
  