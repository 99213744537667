import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../../core/modules/shared.module';
import { VibeWorkHourComponent } from './workHour.component';
import { WorkhoursService } from './workhours.service';
import { SelectProjectOnWorkhoursModalComponent } from './select-project-modal/select-project-modal.component';
import { ShowSdailyReportModalComponent } from './show-sdaily-report-modal/show-sdaily-report-modal.component';
import { NonWorkHourComponent } from './non-work-hour/non-work-hour.component';
import { WorkHourModalPopupComponent } from './work-hour-modal-popup/work-hour-modal-popup.component';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { PaySlipModalComponent } from 'app/main/pages/workHour/component/pay-slip-modal/pay-slip-modal.component'

const routes = [
  {
    path: 'workhours',
    component: VibeWorkHourComponent
  },
];

@NgModule({
  declarations: [
    VibeWorkHourComponent,
    WorkHourModalPopupComponent,
        SelectProjectOnWorkhoursModalComponent,
    ShowSdailyReportModalComponent,
    NonWorkHourComponent,
    PaySlipModalComponent
  ],
  imports: [
    DatePickerModule,
    SharedModule,
    RouterModule.forChild(routes)
  ],
    entryComponents: [
        WorkHourModalPopupComponent,
        SelectProjectOnWorkhoursModalComponent,
        PaySlipModalComponent
    ],
  exports: [
    VibeWorkHourComponent,
    NonWorkHourComponent
  ],
  providers: [
    WorkhoursService
  ]
})

export class VibeWorkHourModule {
}
