import { Component, OnInit, Optional, Inject } from '@angular/core';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MaterialUnitList, MaterialLinetype } from 'app/main/model/ProjectStatus';
import { Supplier } from 'app/main/model/Supplier';
import { MaterialWeatherType, MaterialType } from 'app/constant/common.constant';

@Component({
  selector: 'app-edit-supplier-material',
  templateUrl: './edit-supplier-material.component.html',
  styleUrls: ['./edit-supplier-material.component.scss'],
})
export class EditSupplierMaterialComponent implements OnInit {
  showSpinner = false;
  materialForm: FormGroup;
  personalPriceIncreaseInPercentage: number = 0;
  retailPriceIncreaseInPercentage: number = 0;
  settingMasterValues: any = [];
  materialObject: any = {};
  searchedSupplierList: Supplier[] = [];
  supplierList: Supplier[] = [];
  selectLinetype = MaterialLinetype;
  selectMaterialtype = MaterialType;
  materialWeatherType = MaterialWeatherType;
  selectUnitList = MaterialUnitList;
  isGettingSupplier: boolean = false;
  isSupplierRequired: boolean = true;
  tempMaterialList: any = [];
  constructor(
    private translationLoader: FuseTranslationLoaderService,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    @Optional() public dialogRef: MatDialogRef<EditSupplierMaterialComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.translationLoader.loadTranslations(english, danish);
  }

  ngOnInit() {
    this.materialObject = this.data.materialObject;
    this.tempMaterialList = this.data.tempMaterialList;
    if(this.materialObject.lineType && this.materialObject.lineType.trim())
      this.materialObject.lineType = this.materialObject.lineType.trim();
    else
      this.materialObject.lineType = "V";
    this.supplierList = this.data.supplierList;
    this.initilizeForm();
    if (localStorage.getItem("settingMasterValues")) {
      this.settingMasterValues = JSON.parse(localStorage.getItem("settingMasterValues"));
      if (this.settingMasterValues && this.settingMasterValues.length > 0) {
        this.retailPriceIncreaseInPercentage = Number(this.settingMasterValues.find(s => s.key1 == "Material" && s.key2 == "RetailPriceMustBeHigherOnPurchasePriceInPercentage").value);
        this.personalPriceIncreaseInPercentage = Number(this.settingMasterValues.find(s => s.key1 == "Material" && s.key2 == "PersonalPriceMustBeHigherOnPurchasePriceInPercentage").value);
      }
    }
  }

  initilizeForm() {
    this.materialForm = this.formBuilder.group({
      text: [null, Validators.compose([Validators.required, Validators.maxLength(80)])],
      picture: [null, !Validators.required],
      holdings: [null, Validators.compose([Validators.required])],
      itemNumber: [null, [Validators.required, Validators.min(0), Validators.max(99999999999999999999)]],
      retailPrice: [null, Validators.compose([Validators.required, Validators.max(999999999999)])],
      purchasePrice: [null, Validators.compose([Validators.required, Validators.max(999999999999)])],
      personalPrice: [null, Validators.compose([Validators.required, Validators.max(999999999999)])],
      purchaseQuantity: [null, Validators.required],
      unit: [null, Validators.required],
      internalText: [],
      shelfNo: [null, Validators.required],
      rows: [],
      supplierId: [null, Validators.required],
      supplierItemNumber: [null, Validators.required],
      minInventory: [null, Validators.compose([Validators.required, Validators.min(0), Validators.max(999999999999)])],
      maxInventory: [null, Validators.compose([Validators.required, Validators.min(0), Validators.max(999999999999)])],
      isRequiredText: [],
      noStockFeatures: ['F'],
      nonStockList: [],
      colorCode: [],
      barcode: [],
      materialsForTheCase: [false],
      lineType: ['V', Validators.required],
      reminder: [null],
      onlySummer: [false],
      weatherType: [null, Validators.required],
      postNumberAppendix: [true],
      barcodeColorStr: ['1'],
      materialType: [null, Validators.required],
      isDisplayWebshop: [false],
      duplicateEntry: [false],
      checkPurchasePrice: [false],
      purchasepriceSalesUnit: [null],
      maxOrderNumber: [null],
      excelPurchaseQty: [0],
      excelPurchasePrice: [0],
      excelRetailPrice: [0],
      mappingMaterial: [null]
    });

    this.materialForm.patchValue(this.materialObject);
    this.UpdateValidation(this.materialForm);
  }

  get f() { return this.materialForm.controls; }

  updateRetailAndPersonalPriceValidation(MaterialObject) {
    if (MaterialObject && MaterialObject.controls["purchasePrice"].value) {
      var retailValue = MaterialObject.controls["purchasePrice"].value + ((MaterialObject.controls["purchasePrice"].value * this.retailPriceIncreaseInPercentage) / 100);
      var personalValue = MaterialObject.controls["purchasePrice"].value + ((MaterialObject.controls["purchasePrice"].value * this.personalPriceIncreaseInPercentage) / 100);
      MaterialObject.controls["retailPrice"].setValidators(Validators.min(retailValue));
      MaterialObject.controls["retailPrice"].updateValueAndValidity();
      MaterialObject.controls["personalPrice"].setValidators(Validators.min(personalValue));
      MaterialObject.controls["personalPrice"].updateValueAndValidity();
    }
  }

  UpdateValidation(MaterialObject) {
    var statusIsOffer = MaterialObject.controls["materialType"].value ? MaterialObject.controls["materialType"].value.find(o => o == 22):"";
    if (statusIsOffer) {
      this.isSupplierRequired = false;
      MaterialObject.controls["holdings"].setValidators(null);
      MaterialObject.controls["holdings"].updateValueAndValidity();
      MaterialObject.controls["retailPrice"].setValidators(null);
      MaterialObject.controls["retailPrice"].updateValueAndValidity();
      MaterialObject.controls["purchasePrice"].setValidators(null);
      MaterialObject.controls["purchasePrice"].updateValueAndValidity();
      MaterialObject.controls["personalPrice"].setValidators(null);
      MaterialObject.controls["personalPrice"].updateValueAndValidity();
      MaterialObject.controls["purchaseQuantity"].setValidators(null);
      MaterialObject.controls["purchaseQuantity"].updateValueAndValidity();
      MaterialObject.controls["unit"].setValidators(null);
      MaterialObject.controls["unit"].updateValueAndValidity();
      MaterialObject.controls["shelfNo"].setValidators(null);
      MaterialObject.controls["shelfNo"].updateValueAndValidity();
      MaterialObject.controls["supplierId"].setValidators(null);
      MaterialObject.controls["supplierId"].updateValueAndValidity();
      MaterialObject.controls["supplierItemNumber"].setValidators(null);
      MaterialObject.controls["supplierItemNumber"].updateValueAndValidity();
      MaterialObject.controls["minInventory"].setValidators(null);
      MaterialObject.controls["minInventory"].updateValueAndValidity();
      MaterialObject.controls["maxInventory"].setValidators(null);
      MaterialObject.controls["maxInventory"].updateValueAndValidity();
      MaterialObject.controls["lineType"].setValidators(null);
      MaterialObject.controls["lineType"].updateValueAndValidity();
      MaterialObject.controls["weatherType"].setValidators(null);
      MaterialObject.controls["weatherType"].updateValueAndValidity();
      MaterialObject.controls["materialType"].setValidators(null);
    }
    else {
      this.isSupplierRequired = true;
      MaterialObject.controls["holdings"].setValidators([Validators.compose([Validators.required])]);
      MaterialObject.controls["holdings"].updateValueAndValidity();
      MaterialObject.controls["purchasePrice"].setValidators([Validators.compose([Validators.required, Validators.max(999999999999)])]);
      MaterialObject.controls["purchasePrice"].updateValueAndValidity();
      MaterialObject.controls["purchaseQuantity"].setValidators([Validators.required]);
      MaterialObject.controls["purchaseQuantity"].updateValueAndValidity();
      MaterialObject.controls["unit"].setValidators([Validators.required]);
      MaterialObject.controls["unit"].updateValueAndValidity();
      MaterialObject.controls["shelfNo"].setValidators([Validators.required]);
      MaterialObject.controls["shelfNo"].updateValueAndValidity();
      MaterialObject.controls["supplierId"].setValidators([Validators.required]);
      MaterialObject.controls["supplierId"].updateValueAndValidity();
      MaterialObject.controls["supplierItemNumber"].setValidators([Validators.required]);
      MaterialObject.controls["supplierItemNumber"].updateValueAndValidity();
      MaterialObject.controls["minInventory"].setValidators([Validators.compose([Validators.required, Validators.min(0), Validators.max(999999999999)])]);
      MaterialObject.controls["minInventory"].updateValueAndValidity();
      MaterialObject.controls["maxInventory"].setValidators([Validators.compose([Validators.required, Validators.min(0), Validators.max(999999999999)])]);
      MaterialObject.controls["maxInventory"].updateValueAndValidity();
      MaterialObject.controls["lineType"].setValidators([Validators.required]);
      MaterialObject.controls["lineType"].updateValueAndValidity();
      MaterialObject.controls["weatherType"].setValidators([Validators.required]);
      MaterialObject.controls["weatherType"].updateValueAndValidity();
      MaterialObject.controls["materialType"].setValidators([Validators.required]);
      this.updateRetailAndPersonalPriceValidation(MaterialObject);
    }
  }
  getIcon(icon) {
    return 'icon-' + icon;
  }

  back() {
    this.dialogRef.close(null);
  }

  supplierSearch(value) {
    if (value === '') {
      return this.searchedSupplierList = []
    }
    this.searchedSupplierList = this.supplierList.filter((supplier) => {
      return supplier.business.toLowerCase().startsWith(value.toLowerCase())
    })
  }

  save() {
    this.materialForm.markAllAsTouched();
    if(this.materialForm.valid)
      this.dialogRef.close(this.materialForm.value);
  }

}
