export const locale = {
    lang: 'en',
    data: {
        'FIELDS': {
            'Status': 'Status',
            'MaterialTextColor': 'Text',
            'VareNo': 'Vare nummer',
            'MaterialNo': 'Number',
            'Material': 'Material',
            'MaterialText': 'Material Text',
            'DateonCreate': 'Date',
            'Quantity': 'Areal',
            'Unit': 'Unit',
            'RetailPrice': 'Retail Price pr. pcs',
            'TotalPrice': 'Total Price',
            'PersonalPrice': 'Personal Price',
            'PersonalTotalPrice': 'Personal Total',
            'TotalIncludedVAT': 'Offer Price incl. VAT',
            'Estimate': 'Estimate Price',
            'Price-included': 'Price incl. VAT',
            'ProfitSubProfit': 'Profit / Sub-Profit',
            'ScanBarcode': 'Scan barcode',
            'ColorCode': 'Color code',
            'PriceExclVAT': 'Price excl vat',
            'Discount': 'Discount',
            'Total': 'Total',
            'DonePercentage': 'Done %',
            'DiscountLable': 'Discount / Debtor Discount',
            'Amount': 'Amount',
            'Percentage': 'Percentage',
            'PurchasePrice': 'Purchase Price',
            'RemainsTakenFromBasements': 'Remains / taken from basements',
            'MaterialCreation': 'Material Creation',
            'All': 'All',
            "Materialtype": 'Material type',
            'Vat':'Excl. VAT',
            'Not-Found-Material': 'No material found',
            'Rows': 'Rows',
            'Minutes': 'Minutes',
            'Total-purchase-price':'Total purchase price',
            'M2Quantity':'M2 QTY',
            'PCS':'PCS/ 1M2',
            'PurchaseQTY':'QTY',
            'Antal/Liter':'Antal/Liter',
            'Driving':'Driving',
            'DrivingTitle': 'DRIVING',
            'NumberOfHours': 'Number of hours',
            'DistancePerDay': 'Distance per day',
            'NumberOfDrivingDays': 'No. of driving days',
            'TravelingTime': 'Time',
            'PerDay': 'Pr. Day',
            'TotalTime': 'Total time',
            'DiscountByPostNumber': 'Discount by PostNumber',
            'EditDrivingDays': 'Edit driving days',
            'Distance': 'Distance',
        },
        'Headers': {
            'AddNewMaterial': 'Add New Material',
            'Nomaterialadded': 'No material added',
            'DeleteMaterial': 'Do you want to delete "@MaterialName"?',
            'MaterialList': 'Material List',
        },
        'BUTTON': {
            'PDF':'PDF',
            'Driving': 'Driving',
        },
    }
}