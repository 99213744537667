export const locale = {
    lang: 'ds',
    data: {
        'Buttons': {
            'Plan': 'Planlæg',
            'Back': 'Tilbage',
            'Replace': 'Erstat',

        },
        'FIELDS':{
            'StartDate': 'Start dato ',
            'EndDate': 'Slut dato ',
            'FromCoworker': 'Fra medarbejder',
            'ToCoworker': 'Til medarbejder ',
        },
        'MESSAGES': {
            'Updated': 'Opdateret succesfuldt.',
            'Failed': 'Mislykkedes',
            'Deleted': 'Slettet med succes',
            'NORECORD': 'Intet optage fundet',
            'Created': 'Oprettet med succes',
        },
    }
}