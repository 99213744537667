export const locale = {
    lang: 'en',
    data: {
        'FIELDS': {
            'Title': 'This case has yielded DKK',
            'Profit': 'in profit',
            'Losse': 'in loss'
        },
        'BUTTONS': {
            'BACK': 'Back',
          },
    }
}