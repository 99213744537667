import { Component, OnInit, OnDestroy, Output, EventEmitter, Input, ChangeDetectorRef } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { OfferRejectedQuestionDTO, QuestionTypeCategory } from 'app/main/model/OfferV2';
import { TranslateService } from '@ngx-translate/core';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { ProjectofferService } from '../../../project-offer.service';
import { NotificationsService } from 'angular2-notifications';

import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { FormService } from 'app/core/services/form.service';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';
import { MatDialog } from '@angular/material';
import { AuthService } from 'app/core/services/auth.service';
import { Subscription } from 'rxjs';

@Component({
    selector: "add-update-questions",
    templateUrl: "./add-question.component.html",
    styleUrls: ["./add-question.component.scss"]
})

export class AddQuestionComponent implements OnInit, OnDestroy {
    @Output("getQuestionList") getQuestionList: EventEmitter<any> = new EventEmitter();
    @Output("onClickCancelBtn") onClickCancelBtn: EventEmitter<any> = new EventEmitter();
    currentObject = new OfferRejectedQuestionDTO();
    offerQuestionform: FormGroup;
    typeList = QuestionTypeCategory;
    type: string = "Textbox";
    questionLists = [];
    showSpinner: boolean = false;
    isAddNew: boolean = false;
    isSaveData: boolean = false;
    isCheckListDataExist: boolean = false;
    subscriptionForEdit: Subscription;
    subscriptionForDelete: Subscription;

    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private formBuilder: FormBuilder,
        private projectofferService: ProjectofferService,
        private translate: TranslateService,
        private notificationsService: NotificationsService,
        private formService: FormService,
        private dialog: MatDialog,
        private authService: AuthService,
        private cdr: ChangeDetectorRef) {
        this.translationLoaderService.loadTranslations(danish, english);
    }

    ngOnInit() {
        this.offerQuestionform = this.formBuilder.group({
            question: [null, [Validators.required]]
        });

        this.questionLists.push({
            name: this.currentObject.question,
            type: "Textbox",
            checkItems: []
        });
    }

    addUpdateQuestion() {
        this.formService.markFormGroupTouched(this.offerQuestionform);
        var isSave = false
        isSave = this.offerQuestionform.valid;
        this.CheckListExist();
        if (isSave && this.isCheckListDataExist) {
            if (this.currentObject.id) {
                this.Update()
            } else {
                this.Add()
            }
        }
    }

    addNewQuestion(flag) {
        this.isAddNew = !flag;
        this.currentObject = new OfferRejectedQuestionDTO();
    }

    AddUpdateOfferQuestion(event) {
        this.currentObject = event;
        if (this.currentObject.id) {
            this.Update()
        } else {
            this.Add()
        }
    }

    Add() {
        this.showSpinner = true;
        this.isSaveData = true;
        this.projectofferService.AddQuestion(this.currentObject).subscribe({
            next: (response: ResponseBase) => {
                this.currentObject = new OfferRejectedQuestionDTO();
                this.questionLists = [];
                if (this.questionLists.length == 0) {
                    this.questionLists.push({
                        name: this.currentObject.question,
                        type: "Textbox",
                        checkItems: []
                    });
                }
                this.type = 'Textbox';
                this.getQuestionList.emit();
                this.showSpinner = false;
                this.isAddNew = false;
                this.isSaveData = false;
                this.translate.get("MESSAGES.Created").subscribe({
                    next: (res) => {
                        this.notificationsService.success(res);
                    },
                    error: err => {
                        this.showSpinner = false;
                        this.isSaveData = false;
                    }
                });
            },
            error: err => {
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                        this.isSaveData = false;
                        this.showSpinner = false;
                    },
                    error: err => {
                    }
                });
            }
        });
    }

    Update() {
        this.showSpinner = true;
        this.isSaveData = true;
        var Id = this.currentObject.id;
        this.projectofferService.UpdateQuestion(Id, this.currentObject).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                this.isAddNew = false;
                this.isSaveData = false;
                this.questionLists = [];
                if (this.questionLists.length == 0) {
                    this.questionLists.push({
                        name: this.currentObject.question,
                        type: "Textbox",
                        checkItems: []
                    });
                }
                this.type = "Textbox";
                this.currentObject = new OfferRejectedQuestionDTO();
                this.getQuestionList.emit();
                this.translate.get("MESSAGES.Updated").subscribe({
                    next: (res) => {
                        this.notificationsService.success(res);
                        this.isSaveData = false;
                        this.showSpinner = false;
                    },
                    error: err => {
                    }
                });
            },
            error: err => {
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                        this.showSpinner = false;
                        this.isSaveData = false;
                    },
                    error: err => {
                    }
                });
            }
        });
    }

    edit(id) {
        this.showSpinner = true;
        this.isSaveData = true;
        this.projectofferService.GetSingleOfferQuestion(id).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                this.isAddNew = true;
                this.isSaveData = false;
                this.currentObject = new OfferRejectedQuestionDTO();
                this.currentObject = response.result;
                if (this.questionLists.length == 0) {
                    this.questionLists.push({
                        name: this.currentObject.question,
                        type: "Textbox",
                        checkItems: []
                    });
                }
                this.questionLists[0].type = this.currentObject.questionType;
                if (this.currentObject.questionType && this.currentObject.questionType != "Textbox") {
                    this.type = this.currentObject.questionType == "MultiChoice" ? 'MultiChoice' : 'SingleChoice'
                    this.questionLists[0].checkItems = JSON.parse(this.currentObject.choiceOptions);
                } else {
                    this.type = 'Textbox'
                }
            },
            error: err => {
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                        this.isSaveData = false;
                        this.showSpinner = false;
                    },
                    error: err => {
                        this.showSpinner = false;
                    }
                });
            }
        });
    }

    delete(id) {
        this.showSpinner = true;
        this.isSaveData = true;
        this.projectofferService.DeleteQuestion(id).subscribe({
            next: (response: ResponseBase) => {
                this.getQuestionList.emit();
                this.isAddNew = false;
                this.translate.get("MESSAGES.Deleted").subscribe({
                    next: (res) => {
                        this.questionLists = [];
                        this.currentObject = new OfferRejectedQuestionDTO();
                        if (this.questionLists.length == 0) {
                            this.questionLists.push({
                                name: this.currentObject.question,
                                type: "Textbox",
                                checkItems: []
                            });
                        }
                        this.type = 'Textbox';
                        this.notificationsService.create(res);
                        this.showSpinner = false;
                        this.isSaveData = false;
                    },
                    error: err => {
                    }
                });
            },
            error: err => { this.showSpinner = false; this.isSaveData = false; },
            complete: () => { this.showSpinner = false; this.isSaveData = false; }
        })
    }

    CheckListExist() {
        if (this.type != 'Textbox' && this.questionLists[0].checkItems.length == 0) {
            this.isCheckListDataExist = false;
            const dialogRef = this.dialog.open(AlertMessageModelComponent, {
                height: "200px",
                data: {
                    Header: "MESSAGES.AddTypeList",
                    ShowOKButton: true
                }
            });
            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    return;
                }
            });
        } else {
            this.isCheckListDataExist = true;
        }
        if (this.type != 'Textbox') {
            this.currentObject.choiceOptions = JSON.stringify(this.questionLists[0].checkItems);

        }
    }

    onSelectType() {
        this.questionLists = [];
        if (this.type == 'MultiChoice') {
            this.questionLists.push({
                name: "",
                type: "MultiChoice",
                checkItems: [],
                answerType: 0,
                question: "",
                answer: ""
            });
        } else if (this.type == 'SingleChoice') {
            this.questionLists.push({
                name: "",
                type: "SingleChoice",
                checkItems: []
            });
        } else {
            this.questionLists.push({
                name: this.currentObject.question,
                type: "Textbox",
                checkItems: []
            });
        }
    }

    Cancel() {
        this.questionLists = [];
        this.questionLists.push({
            name: "",
            type: "Textbox",
            checkItems: []
        });
        this.type = 'Textbox';
        this.isAddNew = false;
        this.onClickCancelBtn.emit();
    }

    //Checkbox
    addCheckItem(form: NgForm, checkList): void {
        const checkItemVal = form.value.checkItem;
        if (!checkItemVal || checkItemVal === '') {
            return;
        }
        const newCheckItem = {
            id: checkList.checkItems.length + 1,
            name: checkItemVal,
            checked: false,
            answerType: 0,
            question: "",
            answer: ""
        };
        checkList.checkItems.push(newCheckItem);
        let id = 0;
        checkList.checkItems.filter(o => {
            id = id + 1;
            o.id = id;
        })
        form.setValue({ checkItem: '' });
    }

    removeChecklistItem(checkItem, checklist): void {
        checklist.checkItems.splice(checklist.checkItems.indexOf(checkItem), 1);
    }

    onSelectRadioBtn(checklist, i) {
        checklist.checkItems.forEach(item => {
            item.checked = false;
        });
        checklist.checkItems[i].checked = true;
    }

    AddText(checklist, i) {
        checklist.checkItems[i].answerType = 1;
    }

    RemoveText(checklist, i) {
        checklist.checkItems[i].question = "";
        checklist.checkItems[i].answerType = 0;
    }

    ngAfterViewInit() {
        this.subscriptionForEdit = this.authService.editSingleQuestionChangeObs.subscribe((data) => {
            if (data) {
                this.edit(data);
                //this.cdr.detectChanges();
            }
        });

        this.subscriptionForDelete = this.authService.deleteSingleQuestionChangeObs.subscribe((data) => {
            if (data) {
                this.delete(data);
                //this.cdr.detectChanges();
            }
        });
    }
    ngOnDestroy() {
        this.authService.editSingleQuestionChange.next("");
        this.authService.deleteSingleQuestionChange.next("");
    }
}