import { OnInit, Component, ViewChild, Input } from '@angular/core';
import { MatSort, MatPaginator, MatTableDataSource } from '@angular/material';
import { supplierReport } from 'app/main/model/Material';
import { DenmarkDigitFormate } from 'app/core/pipes/denmarkDigitFormate.pipe';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { MaterialWeatherType } from 'app/constant/common.constant';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { MaterialUnitList } from 'app/main/model/ProjectStatus';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { MaterialService } from '../../material.service';

@Component({
    selector: 'SupplierListArray',
    templateUrl: './supplierListArray.component.html',
    styleUrls: ['./supplierListArray.component.scss']
})

export class SupplierListArray implements OnInit {
    private _materialsList = new BehaviorSubject<any[]>([]);
    @Input()
	set materialsList(value) {
		this._materialsList.next(value);
	};
	get materialsList() {
		return this._materialsList.getValue();
	}
    @Input() projectAndRequistion: any;    
    //materialsList: 
    total: number;
    displayedColumns = ['isChecked', 'weatherType', 'itemNumber', 'icon', 'text', 'colorCode', 'projectIcon', 'projectNumber', 'requisitionNo', 'holdings', 'minInventory', 'maxInventory', 'purchaseQuantity', 'quantity', 'unit', 'purchasePrice', 'total'];
    dataSource: MatTableDataSource<supplierReport>;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    isCheckedAll: boolean = true;
    public denmarkDigitFormate = new DenmarkDigitFormate();
    weatherTypeList = MaterialWeatherType;
    selectUnitList = MaterialUnitList;

    constructor(private translationLoader: FuseTranslationLoaderService,
        private materialService: MaterialService,
        private notificationsService: NotificationsService,
        private translate: TranslateService,
        private router: Router) {
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
        this._materialsList
			.subscribe(x => {
                this.setGrid();
			});
    }

    setGrid() {
        if (this.materialsList.length > 0) {
            var material = this.materialsList.filter(o => o.isChecked == false).length;
            if (material > 0) {
                this.isCheckedAll = false;
            }
        }
        this.dataSource = new MatTableDataSource(this.materialsList);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.calculateTotal()
    }

    onSetActiveAll(event) {
        this.materialsList.filter(p => {
            p.isChecked = event.checked;
        });

        this.dataSource = new MatTableDataSource(this.materialsList);
        this.calculateTotal();
    }

    calculateTotal() {
        var material = this.materialsList.filter(o => o.quantity != 0 && o.isChecked == true);
        this.total = 0;
        if (material) {
            material.filter(it => {
                if (!it.id) {
                    it.holdings = it.quantity;
                }
                this.total += (it.quantity * it.purchasePrice)
            });
        }
    }

    onSetActive() {        
        let atleastOneUncheckedMat = this.materialsList.find(i => i.isChecked==false)
        if(atleastOneUncheckedMat) {
            this.isCheckedAll=false
        } else {
            this.isCheckedAll=true
        }
        
        this.dataSource = new MatTableDataSource(this.materialsList);
        this.calculateTotal();
    }

    getWeatherIcon(typeId) {
        var icon = "";
        var data = this.weatherTypeList.filter(x => x.id == typeId);
        if (data.length == 0) {
            return icon = 'brightness_auto';
        }
        return icon = data[0].icon;
    }

    goToMaterial(id) {
        this.router.navigate(["/materials/edit", id], { queryParams: { tab: 'order' } });
    }
    
    selectProjectAndRequistion(item){
        if(this.projectAndRequistion && this.projectAndRequistion.project)
            item.projectNumber = this.projectAndRequistion.project.sagNumber;
        if(this.projectAndRequistion && this.projectAndRequistion.requistion)
            item.requisitionNo = this.projectAndRequistion.requistion.number;
    }

    isItemNumberExist(itemNumber, m) {        
        this.materialService.GetMaterialByItemNumber(itemNumber).subscribe({
            next: (response: ResponseBase) => {
                if (response) {
                    if (response.statusCode==200) {
                        this.materialsList[m]['isExistedMaterialID'] = true;
                        this.translate.get("MESSAGES.ItemNumberExist").subscribe({
                            next: (res) => {
                                this.notificationsService.error(res);
                            },
                        });
                    } else {
                        this.materialsList[m]['isExistedMaterialID'] = false;
                    }
                }
            },
            error: err => {
                this.materialsList[m]['isExistedMaterialID'] = false;
            },
        });
    }
}