import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { RequestCoWorker, RequestStatusProject } from 'app/main/model/RequestBase';
import { CoWorkersService } from 'app/main/pages/coWorker/coWorkers.service';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { ProjectService } from 'app/main/pages/project/project.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
interface AssignCoworkersData {
  projectIds: string[];
  coworkerIds: string[];
}
@Component({
  selector: 'app-coworker-view-popup',
  templateUrl: './coworker-view-popup.component.html',
  styleUrls: ['./coworker-view-popup.component.scss']
})
export class CoworkerViewPopupComponent implements OnInit {
  showSpinner: boolean = false;
  selectedProject: any = [];
  coWokers: any = [];
  currentselectedcoWorkerObj: any = {};
  selectedCoworker: any = [];
  constructor(public dialogRef: MatDialogRef<CoworkerViewPopupComponent>,
    private translationLoader: FuseTranslationLoaderService,
    private coWorkersService: CoWorkersService,
    private projectService: ProjectService,
    private translate: TranslateService,
    private notificationsService: NotificationsService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.translationLoader.loadTranslations(english, danish);
  }

  ngOnInit() {
    this.selectedProject = this.data;
    let coWorker = new RequestCoWorker();
    this.getCoWorkers(coWorker);
  }

  DropDownSearchCoWorker(value) {
    var model = new RequestStatusProject();
    model.Index = 1;
    model.Name = value;
    this.getCoWorkers(model);
  }

  getCoWorkers(model) {
    model.Limit = 400;
    model.OrderBy = "Name";
    this.coWorkersService.Get<ResponseBase>(model)
      .subscribe((response: ResponseBase) => {
        if (response)
          this.getNextRequestForCoWorker(response.pagination, model, response.result);
        else
          this.coWokers = [];
      });
  }

  getNextRequestForCoWorker(pagination, model, response) {
    if (pagination.index == 1) {
      this.coWokers = response;
    }
    else {
      this.coWokers.push(...response);
    }
    this.coWorkersService.setResponseForCoworker(this.coWokers);
    model.Index = pagination.index + 1;
    model.Limit = pagination.limit;
    model.TotalRows = pagination.totalCount;
    if (pagination.totalCount > pagination.index * pagination.limit)
      this.getCoWorkers(model);
  }

  onCowrkerChange(coworker) {
    this.selectedCoworker = coworker;
  }

  onCloseDialog(e) {
    this.dialogRef.close(e ? true : false)
  }

  onSaveAssignCoworker() {
    if (this.selectedProject.length !== 0 && this.selectedCoworker) {
      this.showSpinner = true;
      const projectIds = this.selectedProject.map(project => project.sagId);
      const passSelectedProjectIdCoworkerId: AssignCoworkersData = {
        projectIds: projectIds,
        coworkerIds: this.selectedCoworker
      };
      this.projectService.MultipleProjectsAssignCoworkers(passSelectedProjectIdCoworkerId).subscribe({
        next: (response: ResponseBase) => {
          if (response) {
            this.showSpinner = false;
            this.translate.get("MESSAGES.Created").subscribe({
              next: (res) => {
                this.notificationsService.create(res);
              },
              error: err => {
              }
            });
          }
        },
        error: err => {
          this.showSpinner = false;
          this.translate.get("MESSAGES.Failed").subscribe({
            next: (res) => {
              this.notificationsService.error(res);
            },
            error: err => {
            }
          });
        },
        complete: () => {

        }
      });
    }
    if(!this.selectedCoworker){
      this.translate.get("MESSAGES.SELECTCOWORKER").subscribe({
        next: (res) => {
            this.notificationsService.error(res);
        },
        error: err => {
        }
    });
    }
  }

}
