import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from 'app/core/modules/shared.module';

import { VibeProjectComponent } from './project.component';

import { NewProjectComponent } from './new-project/new-project.component';
//import { ProjectDeleteDialog } from './new-project/new-project.component';
import { ProjectService } from './project.service';
import { ProjectRequisitionService } from './project.requisition.service';
import { DatePipe } from '@angular/common';
import { NgxMaskModule } from 'ngx-mask'
//import { ProjectInvoiceComponent } from './component/project-invoice/project-invoice.component';
//import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { CoWorkerComponent } from './component/coWorker/coWorker.component';
import { StatusMessageComponent } from './component/statusMessage/statusMessage.component';
// import { projectStatusComponent } from './component/projectStatus/projectStatus.component';
//import { NewCustomerComponent } from '../customer/new-customer/new-customer.component';
import { CustomerProjectColorCodeComponent } from 'app/main/shared-component/customer-project-colorcode/customer-project-colorcode.component';
import { VibeCustomerModule } from 'app/main/pages/customer/customer.module';
import { DatePickerModule, DateTimePickerModule } from '@syncfusion/ej2-angular-calendars';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { PdfViewerModelComponent } from 'app/main/pages/pdf-viewer/pdf-viewer.component';
import { MaterialComponent } from './component/material/material.component';
import { GoogleMapDirectionComponent } from 'app/main/pages/google-map-direction/google-map-direction.component';
import { PreferenceShare } from './component/preferenceShare/preferenceShare.component';
import { StartAndVisitDateComponent } from './component/startAndVisitDate/startAndVisitDate.component';
import { MoveRequisitionComponent } from './component/moveRequisition/moveRequisition.Component';
import { NgxCurrencyModule } from "ngx-currency";
import { CustomCurrencyMaskConfig } from "app/core/constant/CurrencyMaskConfiguartion";
import { CustomeTitleCaseDirective } from 'app/core/directives/customtitlecase.directive'
import { RequisitionStatusModelComponent } from './component/requisition-status-model/requisition-status-model.component';
import { NgPipesModule } from 'ngx-pipes';
import { BilledStatusDateComponent } from './component/billedStatusDate/billedStatusDate.component';
import { ProjectPriceDiscountComponent } from './component/projectPriceDiscount/project-price-discount.component';
import { ShowInactiveProjectComponent } from './component/show-inactive-project/show-inactive-project.component';
import { ProjectHoursDetails } from './component/project-hours-details/project-hours-details';
import { CoWorkerListComponent } from './component/coWorkersList/coWorkerList.component';
import { PendingMaterialOrderListComponent } from './component/pending-material-order/pending-material-order-list.component';
import { RequistionStatusComponent } from './component/requistionStatus/requistionStatus.component';
import { MoveMaterialComponent } from './component/move-material/move-material.component';
import { NotificationSettingsComponent } from "./component/notification-settings/notification-settings.component";
import { CKEditorModule } from 'ng2-ckeditor';
import { DirtyCheckGuard } from 'app/core/directives/dirty-check-guard.service';
import { MatBadgeModule } from '@angular/material/badge';
import { ProfitHighlightComponent } from './component/profit-highlight/profit-highlight.component';
import { ProjectsListComponent } from './component/project-list/project-list.component';
import { CoworkerAssignPopupComponent } from './component/coworker-assign-popup/coworker-assign-popup.component';
import { CoworkerViewPopupComponent } from './component/coworker-view-popup/coworker-view-popup.component';
import { ProjectMapLocationComponent } from './component/project-map-location/project-map-location.component';
import { AgmCoreModule } from '@agm/core';
import { environment } from 'environments/environment';
import { AgmDirectionModule } from 'agm-direction';
import { ViewProjectMapInformationComponent } from './component/view-project-map-information/view-project-map-information.component';
import { ProjectUploadComponent } from './component/project-upload/project-upload.component';
import { ProjectMapRouteComponent } from './component/project-map-route/project-map-route.component';
import { ProjectSupplierQuickMaterial } from './component/project-supplier-quick-material/project-supplier-quick-material.component';



const routes = [
    {
        path: 'projects',
        component: VibeProjectComponent,
        resolve: {
            formsdata: ProjectService
        }
    },
    {
        path: 'projects/add',
        component: NewProjectComponent
    },
    {
        path: 'projects/add/offer/:Custid/:offerId',
        component: NewProjectComponent
    },
    {
        path: 'projects/add/from-customer/:Custid',
        component: NewProjectComponent
    },
    {
        path: 'projects/add/inquiry/:Custid/:InquiryId',
        component: NewProjectComponent
    },
    {
        path: 'projects/edit/:id',
        component: NewProjectComponent,
        canDeactivate: [DirtyCheckGuard]
    },
    {
        path: 'projectslist',
        component: ProjectsListComponent
    },
    {
        path: 'project/upload',
        component: ProjectUploadComponent
    },
    {
        path: 'project/supplier-quick-material',
        component: ProjectSupplierQuickMaterial
    },

];

@NgModule({
    declarations: [
        //ProjectInvoiceComponent,
        NewProjectComponent,
        //ProjectDeleteDialog,
        VibeProjectComponent,
        CoWorkerComponent,
        // projectStatusComponent,
        MaterialComponent,
        PreferenceShare,
        StartAndVisitDateComponent,
        MoveRequisitionComponent,
        StatusMessageComponent,
        CustomeTitleCaseDirective,
        RequisitionStatusModelComponent,
        BilledStatusDateComponent,
        ProjectPriceDiscountComponent,
        ShowInactiveProjectComponent,
        ProjectHoursDetails,
        CoWorkerListComponent,
        PendingMaterialOrderListComponent,
        RequistionStatusComponent,
        MoveMaterialComponent,
        NotificationSettingsComponent,
        ProfitHighlightComponent,
        ProjectsListComponent,
        CoworkerAssignPopupComponent,
        CoworkerViewPopupComponent,
        ProjectMapLocationComponent,
        ViewProjectMapInformationComponent,
        ProjectUploadComponent,
        ProjectMapRouteComponent,
        ProjectSupplierQuickMaterial
    ],
    imports: [
        DatePickerModule,
        //PDFExportModule,
        SharedModule,
        RouterModule.forChild(routes),
        NgxMaskModule.forRoot(),
        VibeCustomerModule,
        PdfJsViewerModule,
        NgxCurrencyModule.forRoot(CustomCurrencyMaskConfig),
        NgPipesModule,
        DateTimePickerModule,
        MatBadgeModule,
        CKEditorModule,
        AgmCoreModule.forRoot({
            apiKey: environment.GooogleKey,
            libraries: ["places"]
        }),
        AgmDirectionModule,
    ],
    entryComponents: [
        CoWorkerComponent,
        //NewCustomerComponent, 
        // projectStatusComponent, 
        PdfViewerModelComponent,
        MaterialComponent,
        GoogleMapDirectionComponent,
        PreferenceShare,
        StartAndVisitDateComponent,
        MoveRequisitionComponent,
        StatusMessageComponent,
        CustomerProjectColorCodeComponent,
        RequisitionStatusModelComponent,
        BilledStatusDateComponent,
        ProjectPriceDiscountComponent,
        ShowInactiveProjectComponent,
        ProjectHoursDetails,
        CoWorkerListComponent,
        PendingMaterialOrderListComponent,
        RequistionStatusComponent,
        MoveMaterialComponent,
        NotificationSettingsComponent,
        ProfitHighlightComponent,
        ProjectsListComponent,
        CoworkerAssignPopupComponent,
        CoworkerViewPopupComponent,
        ProjectMapLocationComponent,
        ViewProjectMapInformationComponent,
        ProjectMapRouteComponent
    ],
    exports: [
        VibeProjectComponent,
        ProjectUploadComponent,
        //ProjectInvoiceComponent
        ProjectSupplierQuickMaterial
    ],
    providers: [ProjectService, DatePipe, ProjectRequisitionService, DirtyCheckGuard]
})

export class VibeProjectModule {
}
