export const locale = {
  lang: 'ds',
  data: {
    'FIELD': {
      'Weather': 'Vejr',
      'WeekNumber': 'Uge nummer',
      'Today': 'I dag',
      'Tomorrow': 'I morgen',
      'Current': 'Nu',
      'Events': 'Begivenheder',
      'Notes': 'Noter'
    }
  }
};
