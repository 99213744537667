export const locale = {
    lang: 'ds',
    data: {
        'HEADERS': {
            'OfferAbove': 'Tilbud over'
        },
        'FIELDS': {
            'Kr': 'Kr.'
        },
        'BUTTONS': {
            'Save': 'Gem',
            'Back': 'Tilbage',
        },
        'MESSAGES': {
            'Failed': 'Operation mislykket, prøv senere',
            'Success': 'Success',
        }
    }
}