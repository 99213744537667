import { Injectable } from '@angular/core';
import { ApiConstant } from '../../../../app/core/constant/ApiConstant';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class GoogleMapAddressAutocompleteService {

  private actionUrl: string;
  constructor(private http: HttpClient) {
      this.actionUrl = "https://maps.googleapis.com/maps/api/geocode/json?address=B%C3%B8gevej%205,%20Vipper%C3%B8d%204390&key=" + environment.GooogleKey;
  }

  public Get<T>(address: string): Observable<T> {
      var apiUrl = "https://maps.googleapis.com/maps/api/geocode/json?address=" + address + "&key=" + environment.GooogleKey;
    return this.http.get<T>(apiUrl);
  }
}
