import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatOption, MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { RequestStatusProject } from 'app/main/model/RequestBase';
import { ProjectMaterial } from "app/main/model/Project"
import { CoWorkersService } from '../../../coWorker/coWorkers.service';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { WorkhoursService } from '../../../workHour/workhours.service';
import { AuthService } from 'app/core/services/auth.service';
import { ShiftType } from 'app/main/model/ProjectStatus';
import { WorkHourStatus, WorkHoursApproveLeaveType, PaymentOptionType } from 'app/main/model/WorkHourStatus';
import { WorkHour, LeaveApplyOnPlanning, CoworkerWithScheduleDetails, WorkhoursDay, WorkingHourOtherInfo } from 'app/main/model/WorkHour';
import { projectRequisition } from 'app/main/model/Project';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { addDays } from 'date-fns';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';
import { WeekendOption } from 'app/constant/common.constant';
import { CommonService } from 'app/core/services/common.service';


@Component({
    selector: 'vibe-calender-add-non-working-hour-popup',
    templateUrl: './add-non-working-hour-popup.component.html',
    styleUrls: ['./add-non-working-hour-popup.component.scss']
})
export class CalenderAddNonWorkingHourPopupComponent implements OnInit {
    startWeek: number = 1;
    projectInfo: any = {};
    requistionInfo: any = {};
    selectProject: string = "";
    coWorkers: any = [];
    projectCoWorkers: any = [];
    coWorkersFullData: any = [];
    showSpinner = false;
    createEventObj: LeaveApplyOnPlanning = new LeaveApplyOnPlanning;
    shiftType = ShiftType;
    selectStatus = WorkHourStatus;
    selectLeaveType = WorkHoursApproveLeaveType;
    projectList: any = [];
    projectFullList: any = [];
    workHour = new WorkHour();
    RequisitionList: projectRequisition[] = [];
    currentselectedCoworkerObj: any = [];
    projectCoworkerObj: any = [];
    Requisition: projectRequisition;
    projectMaterials: ProjectMaterial[] = [];
    materialTotalPrice: number = 0;
    materialPersonalTotalPrice: number = 0;
    materialTotalPriceWithVAT: number = 0;
    hours: any;
    mins: any;
    isAddBreakTime: boolean = true;
    coWorkerList: any = [];
    isDisableWorkType = false;
    weekNumbers: any = [];
    isDisplayErrorMessage: boolean = false;
    isComingFromEditMode: boolean = false;
    workHourNotAddedOnCoworker: string = "";
    workHourId: string = "";
    //roleName: string;
    isFeatureDate: boolean = false;
    isPastDate: boolean = false;
    CoWorkerName: string = "";
    holidayList: any;
    tempEnddate: any;
    isDelete: boolean = false;
    paymentOption = PaymentOptionType;
    weekendOption = WeekendOption;
    selectedCoWorkerList: CoworkerWithScheduleDetails[] = new Array<CoworkerWithScheduleDetails>();
    tempCoworkerListForLeave: any = [];
    coworkerLeaveList: any = [];
    scheduleDay: any = {};
    ScheduleDays: any = [];
    coworkerObj: any;
    projectWithWorkHoursList: any;

    @ViewChild('allSelected', { static: false }) private allSelected: MatOption;
    roleClaims:any;
    hasPermision: boolean = false;
    hasLeaveApprovePermision: boolean = false;
    //Initiators
    constructor(private translationLoader: FuseTranslationLoaderService,
        private _router: Router,
        private translate: TranslateService,
        private notificationsService: NotificationsService,
        private workhourservice: WorkhoursService,
        private coWorkersService: CoWorkersService,
        private authService: AuthService,
        private commonService: CommonService,
        private datePipe: DatePipe,
        private dialog: MatDialog,
        public dialogRef: MatDialogRef<CalenderAddNonWorkingHourPopupComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.translationLoader.loadTranslations(english, danish);
        this.roleClaims = this.authService.getRoleClaimsList();
    }

    ngOnInit() {
         //this.roleName = this.authService.getUserRole();
         this.authService.hasClaim(this.roleClaims.Planning_Leave_Approve).subscribe(hasClaim => {
            if (hasClaim) 
                this.hasLeaveApprovePermision = hasClaim
             else 
                this.hasLeaveApprovePermision = false;
        });
        this.authService.hasClaim(this.roleClaims.Planning_Modify).subscribe(hasClaim => {
            if (hasClaim) 
                this.hasPermision = hasClaim
             else 
                this.hasPermision = false;
        });
        this.selectStatus = this.selectStatus.filter(x => (x.id != 9));
        this.isComingFromEditMode = this.data.isComingFromEditMode;
        this.projectInfo = this.data.projectInfo;
        this.projectWithWorkHoursList = this.data.projectWithWorkHoursList ? this.data.projectWithWorkHoursList : [];
        this.holidayList = JSON.parse(localStorage.getItem("HolidayList"));
        this.createEventObj.weekendId = 1;
        //this.createEventObj.coworkerId = this.projectInfo.coworkerId
        if (this.projectInfo.otherInfo) {
            var customDateObj = JSON.parse(this.projectInfo.otherInfo);
            this.createEventObj.startDate = customDateObj.StartDate;
            this.createEventObj.endDate = customDateObj.EndDate;
        }
        else {
            this.createEventObj.startDate = (this.projectInfo.startDate ? this.projectInfo.startDate : new Date());
        }
        if (new Date().setHours(0, 0, 0, 0) == new Date(this.createEventObj.startDate).setHours(0, 0, 0, 0)) {
            this.isPastDate = false;
        }
        else {
            this.isPastDate = moment(this.createEventObj.startDate, "YYYY-MM-DD").isBefore();
        }
        this.createEventObj.shiftType = this.projectInfo.shiftType;
        this.createEventObj.leaveType = this.projectInfo.status;
        //this.createEventObj.leaveStatus = this.projectInfo.leaveStatus;
        //this.createEventObj.comment = this.projectInfo.comment;
        this.workHourId = this.projectInfo.workHourId;
        this.createEventObj.text = this.projectInfo.text;
        //this.createEventObj.paymentOption = this.projectInfo.paymentOption
        // if (this.isComingFromEditMode) {
        //     if (!this.projectInfo.otherInfo) {
        //         this.createEventObj.endDate = (this.projectInfo.endDate ? this.projectInfo.endDate : new Date());
        //     }
        //     this.currentselectedCoworkerObj = [this.projectInfo.coworkerId];
        //     this.workHour.fromStartHour = this.projectInfo.startHour;
        //     this.workHour.fromStartMinute = this.projectInfo.startMinute;
        //     this.workHour.fromEndHour = this.projectInfo.endHour;
        //     this.workHour.fromEndMinute = this.projectInfo.endMinute;
        //     this.isAddBreakTime = this.projectInfo.isPauseTime;
        // }
        // else {
        // }
        this.createEventObj.leaveStatus = 1;
        this.coworkerLeaveApplyAsApproved();
        this.calculateEndDate();
        this.currentselectedCoworkerObj = [this.authService.getCoWorkerId()];
        this.createEventObj.coworkerId = this.authService.getCoWorkerId();
        this.coWorkers = this.data.coworkerList;
        this.coWorkersFullData = this.data.coworkerList;
        this.createEventObj.startWeekNumber = moment(this.createEventObj.startDate).week().toString();
        this.hours = [{ value: '00' }, { value: '01' }, { value: '02' }, { value: '03' }, { value: '04' }, { value: '05' }, { value: '06' }, { value: '07' }, { value: '08' },
        { value: '09' }, { value: '10' }, { value: '11' }, { value: '12' }, { value: '13' }, { value: '14' }, { value: '15' }, { value: '16' }, { value: '17' },
        { value: '18' }, { value: '19' }, { value: '20' }, { value: '21' }, { value: '22' }, { value: '23' }];

        this.mins = [{ value: '00' }, { value: '15' }, { value: '30' }, { value: '45' }]
        //this.mins = [{ key: '00', value: '00' }, { key: '25', value: '15' }, { key: '50', value: '30' }, { key: '75', value: '45' }]
        this.addWeeksNumber();
        var calandarDoubleClickData = JSON.parse(localStorage.getItem("doubleClickCalanderDetail"));
        if (calandarDoubleClickData) {
            this.currentselectedCoworkerObj = [calandarDoubleClickData.coWorkerId];
            this.createEventObj.coworkerId = this.currentselectedCoworkerObj.join().toString();
        }
        this.createEventObj.workerID = this.currentselectedCoworkerObj
    }

    DropDownSearchCoWorker(value) {
        var model = new RequestStatusProject();
        model.Index = 1;
        model.Name = value;
        this.coWorkers = this.coWorkersFullData.filter(it => {
            return it.name.toLowerCase().includes(model.Name.toLowerCase());
        });
    }
    getCoworkerOnClear(value) {
        if (!value) {
            this.DropDownSearchCoWorker(value)
        }
    }

    DropDownCoWorkerSelect(coworker) {
        var alreadySelectedCoworker = this.selectedCoWorkerList.find(o => o.id == coworker.id);
        if (alreadySelectedCoworker)
            this.selectedCoWorkerList = this.selectedCoWorkerList.filter(o => o.id != coworker.id);
        else
            var findCoworker = this.coWorkers.find(o => o.id == coworker.id);
        if (findCoworker)
            this.addCoworker(findCoworker);

        this.createEventObj.workerID = this.currentselectedCoworkerObj //.join().toString();
        this.DropDownSearchCoWorker('');
        if (this.currentselectedCoworkerObj.length == 1) {
            var coworker = this.coWorkers.find(o => o.id == this.currentselectedCoworkerObj[0]);
            this.createEventObj.leaveStatus = coworker.isLeaveApplyAsApproved == true ? 2 : 1;
        }
        else
            this.createEventObj.leaveStatus = 1;
    }

    DropDownSearchProject(value) {
        var model = new RequestStatusProject();
        model.Index = 1;
        model.Name = value;
        this.coWorkers = this.projectFullList.filter(it => {
            return it.name.toLowerCase().includes(model.Name.toLowerCase());
        });
    }

    onFocusIn(event) {
        event.model.show();
    }

    saveTimer() {
        if (this.workHour.id)
            this.updateWorkhourAPICall(this.workHour);
        else
            this.addWorkhourAPICall();
    }

    deleteTimer() {
        var message = "";
        var message1 = "";
        var message2 = "";
        var message3 = "";
        var startDate = moment(this.createEventObj.startDate).format('DD.MM.YYYY');
        var endDate = moment(this.createEventObj.endDate).format('DD.MM.YYYY');

        this.translate.get("MESSAGES.DeleteNonWorkingHoursOne").subscribe({
            next: (res) => {
                message1 = res;  //+ 'den' + '' + ' ' + startDate + ' ' + 'til den' + ' ' + endDate;
            },
            error: err => {
            }
        });
        this.translate.get("MESSAGES.DeleteNonWorkingHoursTwo").subscribe({
            next: (res) => {
                message2 = res;
            },
            error: err => {
            }
        });
        this.translate.get("MESSAGES.DeleteNonWorkingHoursThree").subscribe({
            next: (res) => {
                message3 = res;
            },
            error: err => {
            }
        });
        message = message1 + ' ' + message2 + ' ' + startDate + ' ' + message3 + ' ' + endDate;

        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            width: '500px',
            data: { Header: message }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.isDelete = result;
                this.dialogRef.close({
                    updateSchedule: false,
                    deleteSchedule: true,
                    goToProject: false,
                    projectId: this.projectInfo.sagId,
                    dataYear: moment(this.createEventObj.startDate).year().toString(),
                    workHourId: this.workHourId,
                });
            }
        });


    }

    goToProject() {
        this.dialogRef.close({
            updateSchedule: false,
            deleteSchedule: false,
            goToProject: true,
            projectId: this.projectInfo.sagId,
            workHourId: this.workHourId,
        });
    }

    addWorkhourAPICall() {
        var isAlreadyApplyWorkHours = false;
        var coworkerName = "";
        var workhoursdate;
        if (this.projectWithWorkHoursList && this.projectWithWorkHoursList.length > 0 && this.currentselectedCoworkerObj.length > 0) {
            if (this.createEventObj.leaveType == 1 || this.createEventObj.leaveType == 3 || this.createEventObj.leaveType == 4 || this.createEventObj.leaveType == 5){
                this.currentselectedCoworkerObj.filter(c => {
                    this.ScheduleDays.forEach(e => {
                        if (e.isChecked) {
                            this.projectWithWorkHoursList.filter(p => {
                                if (p.workerId == c && (moment(p.date).format("YYYY-MM-DD") == e.date)) {
                                    isAlreadyApplyWorkHours = true;
                                    workhoursdate = e.date;
                                    coworkerName = this.coWorkers.find(o => o.id == c).name
                                    return;
                                }
                            });
                        }
                    });
                })
            }

            if (this.createEventObj.leaveType == 2 || this.createEventObj.leaveType == 6 || this.createEventObj.leaveType == 7){
                this.currentselectedCoworkerObj.filter(c => {
                    this.ScheduleDays.forEach(e => {
                        if (e.isChecked) {
                            this.projectWithWorkHoursList.filter(p => {
                                if (isAlreadyApplyWorkHours == false && p.workerId == c && (moment(p.date).format("YYYY-MM-DD") == e.date)) {
                                    if (this.createEventObj.shiftType == 4) {
                                        var fromTimer =  e.date +" "+this.workHour.fromStartHour + ":" + this.workHour.fromStartMinute;
                                        var today = new Date(fromTimer).getTime();
                                        var from = new Date(p.StartTime).getTime();
                                        var to = new Date(p.EndTime).getTime();
                                        var withinRange = today >= from && today < to;
                                        console.log("withinRange",withinRange);
                                        if (withinRange) {
                                            isAlreadyApplyWorkHours = true;
                                            workhoursdate = e.date;
                                            coworkerName = this.coWorkers.find(o => o.id == c).name;
                                            return;
                                        }
                                    }
                                    else {
                                        isAlreadyApplyWorkHours = true;
                                        workhoursdate = e.date;
                                        coworkerName = this.coWorkers.find(o => o.id == c).name;
                                        return;
                                    }
                                }
                            });
                        }
                    });
                })
            }
            
        }
        if (isAlreadyApplyWorkHours) {
            this.translate.get("MESSAGES.CannotApplyLeave").subscribe({
                next: (res) => {
                    var msg = res.replace("@Coworker",coworkerName).replace("@date", moment(workhoursdate, "YYYY.MM.DD").format("DD.MM.YYYY"));
                    this.notificationsService.error(msg);
                },
                error: err => {
                }
            });
        }
        else {
            this.showSpinner = true;
            this.createEventObj.startTime = this.ScheduleDays[0].from;
            this.createEventObj.endTime = this.ScheduleDays[0].to;
            this.createEventObj.pauseTime = this.ScheduleDays[0].pauseTimer;
            this.createEventObj.totalTime = this.ScheduleDays[0].tid;
            this.createEventObj.totalWorkingTime = Number(this.ScheduleDays[0].hours);
            this.ScheduleDays.forEach(e => {
                if (e.isChecked) {
                    this.createEventObj.leaveDates.push(e.date)
                }
            });
            var workHoursListForCreate = [];
            var workingHourOtherInfo = new WorkingHourOtherInfo();
            workingHourOtherInfo.StartDate = moment(this.createEventObj.startDate).format("YYYY-MM-DDT00:00:00");
            workingHourOtherInfo.EndDate = moment(this.createEventObj.endDate).format("YYYY-MM-DDT00:00:00");
            workingHourOtherInfo.IsNotifyToCustomer = false;
            var tempDates = this.ScheduleDays.filter(y => y.isChecked == true).map(x => x.date);
            var PlanningDates = tempDates;
            workingHourOtherInfo.PlanningDates = PlanningDates.join(',');
            workingHourOtherInfo.WeekendId = this.createEventObj.weekendId;
            for (var i = 0; i < this.currentselectedCoworkerObj.length; i++) {
                var ScheduleDay = new WorkHour();
                //ScheduleDay.date = this.workHour.date;
                ScheduleDay.workerId = this.currentselectedCoworkerObj[i];
                //ScheduleDay.from = this.scheduleDay.from;
                //ScheduleDay.to = this.scheduleDay.to;
                //ScheduleDay.tid = this.scheduleDay.tid;
                //ScheduleDay.pauseTimer = this.scheduleDay.pauseTimer;
                //ScheduleDay.hours = this.scheduleDay.hours;
                //ScheduleDay.shiftType = this.scheduleDay.shiftType;
                ScheduleDay.projectId = null;
                ScheduleDay.isWorking = false;
                ScheduleDay.inquiryId = null;
                ScheduleDay.leaveStatus = this.createEventObj.leaveStatus;
                workingHourOtherInfo.BunchId = this.commonService.GenerateRandomUniqueId();
                ScheduleDay.otherInfo = JSON.stringify(workingHourOtherInfo);
                ScheduleDay.status = this.createEventObj.leaveType;
                ScheduleDay.paymentOption = this.createEventObj.paymentOption;
                //ScheduleDay.startDate = moment(this.createEventObj.startDate).format("YYYY-MM-DD");
                //ScheduleDay.endDate = moment(this.createEventObj.endDate).format("YYYY-MM-DD");
                //ScheduleDay.customerId = this.workHour.customerId;
                //ScheduleDay.sendType = this.workHour.sendType;
                //ScheduleDay.message = this.workHour.message;
                //ScheduleDay.newAddedCoworkerIds = this.workHour.newAddedCoworkerIds;
                //ScheduleDay.projectBasedPlanning = true
                ScheduleDay.text = this.createEventObj.text;
                this.ScheduleDays.forEach(e => {
                    if (e.isChecked) {
                        ScheduleDay.from = e.from;
                        ScheduleDay.to = e.to;
                        ScheduleDay.tid = e.tid;
                        ScheduleDay.pauseTimer = e.pauseTimer;
                        ScheduleDay.hours = e.hours;
                        ScheduleDay.shiftType = e.shiftType;
                        ScheduleDay.date = e.date;
                        var workobjWithoutRef = Object.assign({}, ScheduleDay);
                        workHoursListForCreate.push(workobjWithoutRef);
                    }
                });
            }
            this.workhourservice.AddNonWorkHourScheduleWithDateRangeforplanning<ResponseBase>(workHoursListForCreate)
                .subscribe({
                    next: (response: ResponseBase) => {
                        this.showSpinner = false;
                        this.translate.get("MESSAGES.Add").subscribe({
                            next: (res) => {
                                this.notificationsService.success(res);
                                this.dialogRef.close({
                                    updateSchedule: true,
                                    deleteSchedule: false,
                                    goToProject: false,
                                    projectId: this.projectInfo.sagId,
                                    workHourId: this.workHourId,
                                    dataYear: moment(this.createEventObj.startDate).year().toString(),
                                    weekNumber: moment(this.createEventObj.startDate).week().toString(),
                                    response: response.result ? response.result : null,
                                });
                            },
                            error: err => {
                            }
                        });
                    },
                    error: err => {
                        this.showSpinner = false;
                        //this.workHourNotAddedOnCoworker = err.error.error.result.coworkerName;
                        this.isDisplayErrorMessage = true;
                        this.translate.get("MESSAGES.Some_Coworker_Add_Error").subscribe({
                            next: (res) => {
                                this.notificationsService.warn(res);
                            },
                            error: err => {
                            }
                        });
                    },
                    complete: () => {
                    }
                });
        }
    }

    updateWorkhourAPICall(workHour) {
        this.showSpinner = true;
        this.workhourservice.UpdateWithDateRange<ResponseBase>(workHour)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.Add").subscribe({
                        next: (res) => {
                            this.notificationsService.success(res);
                            this.dialogRef.close({
                                updateSchedule: true,
                                deleteSchedule: false,
                                goToProject: false,
                                projectId: this.projectInfo.sagId,
                                workHourId: this.workHourId,
                                dataYear: moment(this.createEventObj.startDate).year().toString(),
                                weekNumber: moment(this.createEventObj.startDate).week().toString(),
                            });
                        },
                        error: err => {
                        }
                    });
                },
                error: err => {
                    this.showSpinner = false;
                    this.workHourNotAddedOnCoworker = err.error.error.result.coworkerName;
                    this.isDisplayErrorMessage = true;
                    this.translate.get("MESSAGES.Some_Coworker_Add_Error").subscribe({
                        next: (res) => {
                            this.notificationsService.warn(res);
                        },
                        error: err => {
                        }
                    });
                },
                complete: () => {
                }
            });
    }

    calculateEndDate() {
        if (!this.isComingFromEditMode) {
            if (this.createEventObj.leaveType == 2 || this.createEventObj.leaveType == 3
               || this.createEventObj.leaveType == 4 || this.createEventObj.leaveType == 5 || this.createEventObj.leaveType == 8) {
               this.createEventObj.endDate = this.createEventObj.startDate;
            }
            else {
               let tempStartDate: Date = new Date(moment(this.createEventObj.startDate).week(this.createEventObj.startWeekNumber || 1).startOf("week").format("YYYY-MM-DD"));
               const endDate: Date = (this.createEventObj.leaveType == 6 || this.createEventObj.leaveType == 7 || this.createEventObj.leaveType == 9) ? this.createEventObj.startDate : addDays(new Date(tempStartDate.getTime()), 4);
               this.createEventObj.endDate = endDate;
               //this.createEventObj.endDate = moment(endDate).format("DD.MM.YYYY");
            }
            if (this.createEventObj.leaveType == 2 || this.createEventObj.leaveType == 4 || this.createEventObj.leaveType == 5) {
                var days = moment(this.createEventObj.startDate).day();
                if (days == 5)
                    this.createEventObj.shiftType = 2;
                else
                    this.createEventObj.shiftType = 3;
            }
            this.calEndDate();
            this.reCoworkerScheduleDays();
        }
    }

    onSelectStatus() {
        if (this.createEventObj.leaveType == 8)
            this.createEventObj.paymentOption = 1;
        else
            this.createEventObj.paymentOption = null;

        if (this.createEventObj.leaveType == 2 || this.createEventObj.leaveType == 8) {
            this.createEventObj.leaveStatus = 2;
        }
        else {
            if (this.hasLeaveApprovePermision)
                this.createEventObj.leaveStatus = 2;
            else
                this.createEventObj.leaveStatus = 1;
        }
    }

    onChange(args) {
        if (args.value) {
            // if (args.value.getDay() == 0 || args.value.getDay() == 6) {
            //     var days = args.value.getDay() == 0 ? 1 : 2;
            //     this.createEventObj.startDate = new Date(args.value.setDate(args.value.getDate() + days));
            // }
            // else
            this.createEventObj.startDate = args.value;
            if (new Date().setHours(0, 0, 0, 0) == new Date(this.createEventObj.startDate).setHours(0, 0, 0, 0)) {
                this.isPastDate = false;
            }
            else {
                this.isPastDate = moment(this.createEventObj.startDate, "YYYY-MM-DD").isBefore();
            }
            this.createEventObj.startWeekNumber = moment(this.createEventObj.startDate).week().toString();
            if (!this.isComingFromEditMode) {
                if (this.createEventObj.leaveType == 2 || this.createEventObj.leaveType == 3
                    || this.createEventObj.leaveType == 4 || this.createEventObj.leaveType == 5 || this.createEventObj.leaveType == 8) {
                    this.createEventObj.endDate = this.createEventObj.startDate;
                }
                else {
                    let tempStartDate: Date = new Date(moment(this.createEventObj.startDate).week(this.createEventObj.startWeekNumber || 1).startOf("week").format("YYYY-MM-DD"));
                    const endDate: Date = (this.createEventObj.leaveType == 6 || this.createEventObj.leaveType == 7 || this.createEventObj.leaveType == 9) ? this.createEventObj.startDate : addDays(new Date(tempStartDate.getTime()), 6);
                    this.createEventObj.endDate = endDate;
                    //this.createEventObj.endDate = moment(endDate).format("DD.MM.YYYY");
                }
            }
            this.calculateEndDate();
        }
    }

    onChangeEndDate(args) {
        this.createEventObj.endDate = args.value;
        //this.tempEnddate = args.value;
        this.calEndDate();
    }

    calEndDate() {
        this.workHour.nonWorkingDayList = [];
        this.workHour.dayList = [];
        var endDate = new Date(new Date(this.createEventObj.endDate).getFullYear(),
            new Date(this.createEventObj.endDate).getMonth(), new Date(this.createEventObj.endDate).getDate(), 0, 0, 0, 0);

        var startDateCal = new Date(new Date(this.createEventObj.startDate).getFullYear(),
            new Date(this.createEventObj.startDate).getMonth(), new Date(this.createEventObj.startDate).getDate(), 0, 0, 0, 0);

        for (let day = startDateCal; day <= endDate; day.setDate(day.getDate() + 1)) {
            var tempDate = moment(day).format("DD.MM.YYYY");
            var getHoliday = this.holidayList.find(o => moment(o.date).format("DD.MM.YYYY") == tempDate);
            if (!getHoliday || getHoliday.length == 0 || this.createEventObj.leaveType==8) {
                this.workHour.nonWorkingDayList.push(moment(day).format("YYYY-MM-DD"));
                this.pushDayList(moment(day).format("YYYY-MM-DD"), true)
            }
        }
        this.createEventObj.endDate = endDate;
    }

    getMinute(value) {
        if (value == "00")
            return "00";
        if (value == "15")
            return "25";
        if (value == "30")
            return "50";
        if (value == "45")
            return "75";
    }

    CheckEndHour(checkMinute) {
        this.workHour.fromEndHour = (this.workHour.fromEndHour < this.workHour.fromStartHour) == true ? (this.workHour.fromEndHour = this.workHour.fromStartHour) : this.workHour.fromEndHour;
    }

    addWeeksNumber() {
        this.weekNumbers = [];
        for (var i = 1; i < 54; i++) {
            this.weekNumbers.push({ value: i.toString() });
        }
    }

    DropDownWeekNumberSelect() {
        var startDate = moment().week(this.createEventObj.startWeekNumber || 1).startOf("week").format("DD.MM.YYYY");
        //this.createEventObj.startDate = moment(startDate).format("DD.MM.YYYY");
        if (!this.isComingFromEditMode) {
            if (this.createEventObj.leaveType == 2 || this.createEventObj.leaveType == 3
                || this.createEventObj.leaveType == 4 || this.createEventObj.leaveType == 5 || this.createEventObj.leaveType == 8) {
                this.createEventObj.endDate = this.createEventObj.startDate;
            }
            else {
                let tempStartDate: Date = new Date(moment(this.createEventObj.startDate).week(this.createEventObj.startWeekNumber || 1).startOf("week").format("YYYY-MM-DD"));
                const endDate: Date = (this.createEventObj.leaveType == 6 || this.createEventObj.leaveType == 7 || this.createEventObj.leaveType == 9) ? this.createEventObj.startDate : addDays(new Date(tempStartDate.getTime()), 6);
                this.createEventObj.endDate = endDate;
                //this.createEventObj.endDate = moment(endDate).format("DD.MM.YYYY");
            }
        }
        this.calculateEndDate();
    }

    _handleKeydown(event: KeyboardEvent) {
        if (event.keyCode === 13) {
            event.stopPropagation();
        }
    }

    addCoworker(findCoworker) {
        var coworker = new CoworkerWithScheduleDetails();
        coworker.id = findCoworker.id;
        coworker.CoworkerId = findCoworker.id;
        coworker.name = findCoworker.name;
        coworker.totalWorkHourOfCoworker = 0;
        coworker.ScheduleDays = [];
        this.reCoworkerScheduleDays()
    }

    checkCoworkerOnleave(coworker) {
        if (coworker && coworker.id) {
            this.tempCoworkerListForLeave = [];
            //this.tempDayList = [];
            this.createEventObj.startDate = moment(this.createEventObj.startDate).toDate();
            var sdate = new Date(this.createEventObj.startDate.setHours(0, 0, 0, 0));
            var edate = new Date(this.createEventObj.endDate.setHours(0, 0, 0, 0));
            this.coworkerLeaveList = [];
            this.coworkerLeaveList = this.coWorkerList.filter(x => x.id == coworker.id);
            if (this.coworkerLeaveList.length > 0 && this.coworkerLeaveList[0].holidayList && this.coworkerLeaveList[0].holidayList.length > 0) {
                var data = this.coworkerLeaveList[0].holidayList.filter(o => (new Date(o.date) >= sdate && new Date(o.date) <= edate));
                if (data.length > 0) {
                    var tempCoworker = {
                        id: this.coworkerLeaveList[0].id,
                        name: this.coworkerLeaveList[0].name,
                        holidayList: this.coworkerLeaveList[0].holidayList.sort((a, b) => {
                            return <any>new Date(a.date) - <any>new Date(b.date);
                        }),
                    }
                    this.tempCoworkerListForLeave.push(tempCoworker);
                }
            }

            else
                this.addCoworkerScheduleDays(coworker)
        }
    }
    addCoworkerScheduleDays(coworker) {
        coworker.totalWorkHourOfCoworker = coworker.ScheduleDays.filter(s => s.isChecked == true).reduce((sum, current) => sum + parseFloat(current.hours), 0)
        this.selectedCoWorkerList.push(coworker);
    }

    reCoworkerScheduleDays() {
        this.ScheduleDays = [];
        this.workHour.dayList.filter((x, index) => {
            this.scheduleDay = {};
            this.scheduleDay.date = x.date;
            this.scheduleDay.isChecked = x.isChecked;
            var includeAllDays = true;
            var day = new Date(moment(x.date).format("YYYY-MM-DD")).getDay();
            if (this.createEventObj.weekendId == 1 && (day == 6 || day == 0)) {
                includeAllDays = false
            }
            if (includeAllDays) {
                if (this.createEventObj.shiftType == 2 || this.createEventObj.shiftType == 3) {
                    if (new Date(moment(x.date).format("YYYY-MM-DD")).getDay() == 5) {
                        this.scheduleDay.from = "07:00";
                        this.scheduleDay.to = "14:30";
                        this.scheduleDay.tid = 7.5;
                        this.scheduleDay.pauseTimer = 0.5;
                        this.scheduleDay.hours = "7";
                        this.scheduleDay.shiftType = 2;
                        this.scheduleDay.shiftTypeName = 'selectedShiftOption.value1'
                    }
                    else {
                        this.scheduleDay.from = "07:00";
                        this.scheduleDay.to = "15:00";
                        this.scheduleDay.tid = 8.00;
                        this.scheduleDay.pauseTimer = 0.5;
                        this.scheduleDay.hours = "07.5";
                        this.scheduleDay.shiftType = 3;
                        this.scheduleDay.shiftTypeName = 'selectedShiftOption.value2'
                    }
                }
                // if (this.createEventObj.shiftType == 2) {
                //     this.scheduleDay.from = "07:00";
                //     this.scheduleDay.to = "14:30";
                //     this.scheduleDay.tid = 7.5;
                //     this.scheduleDay.pauseTime = 0.5;
                //     this.scheduleDay.hours = "7";
                //     this.scheduleDay.shiftType = 2;
                //     this.scheduleDay.shiftTypeName = 'selectedOption.value1'
                // }
                // else if (this.createEventObj.shiftType == 3) {
                //     this.scheduleDay.from = "07:00";
                //     this.scheduleDay.to = "15:00";
                //     this.scheduleDay.tid = 8.00;
                //     this.scheduleDay.pauseTime = 0.5;
                //     this.scheduleDay.hours = "07.5";
                //     this.scheduleDay.shiftType = 3;
                //     this.scheduleDay.shiftTypeName = 'selectedOption.value2'
                // }
                else if (this.createEventObj.shiftType == 4) {
                    this.scheduleDay.from = this.workHour.fromStartHour + ":" + this.workHour.fromStartMinute;
                    this.scheduleDay.to = this.workHour.fromEndHour + ":" + this.workHour.fromEndMinute;
                    var time = moment.utc(moment(this.scheduleDay.to, "HH:mm").diff(moment(this.scheduleDay.from, "HH:mm"))).format("HH:mm")
                    var tidTime = moment(time, "HH:mm").format("mm");
                    var tidHours = moment(time, "HH:mm").format("HH");
                    if (tidTime != "00")
                        time = tidHours + ":" + (Number(this.getMinute(tidTime))).toString();
                    this.scheduleDay.tid = Number(time.replace(':', '.'));

                    this.scheduleDay.pauseTimer = this.isAddBreakTime ? 0.5 : 0.0;
                    this.scheduleDay.hours = (Number(time.replace(':', '.')) - this.scheduleDay.pauseTimer).toString();
                    this.scheduleDay.shiftType = 4;
                    this.scheduleDay.shiftTypeName = this.scheduleDay.from + ' til ' + this.scheduleDay.to;
                }
                this.ScheduleDays.push(this.scheduleDay);
            }
        });
    }

    pushDayList(date, isChecked) {
        let workhoursDay = new WorkhoursDay();
        workhoursDay.date = date;
        workhoursDay.isChecked = isChecked;
        this.workHour.dayList.push(workhoursDay)
    }

    getWeekName(date) {
        return moment(date, "YYYY-MM-DD").format("ddd")
    }

    DropDownWeekPlaningChange() {
        this.calEndDate();
        this.selectedCoWorkerList.filter(coworker => {
            coworker.ScheduleDays = [];
        });
        this.reCoworkerScheduleDays();
    }

    onCheckScheduleDay(value, index, item) {
        item.isChecked = !value;
        this.ScheduleDays.filter(s => s.isChecked == true).reduce((sum, current) => sum + parseFloat(current.hours), 0)
    }

    coworkerLeaveApplyAsApproved() {
        var loginCoworkerId = this.authService.getCoWorkerId();
        this.coWorkersService.GetSingle<ResponseBase>(loginCoworkerId)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.coworkerObj = response.result;
                    if (this.hasLeaveApprovePermision)
                        this.createEventObj.leaveStatus = 2;
                }
            });
    }
}
