import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TabIdService {
  private tabIdKey = 'tab-id';

  constructor() {
    this.initializeTabId();
  }

  private initializeTabId() {
    if (!localStorage.getItem(this.tabIdKey)) {
      localStorage.setItem(this.tabIdKey, this.generateUniqueId());
    }
  }

  public generateUniqueId(): string {
    // Simple method to generate a unique ID. You might want to use a more robust method in production.
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  public getTabId(): string {
    return localStorage.getItem(this.tabIdKey);
  }
  
}
