export const locale = {
    lang: 'ds',
    data: {
        'TITLE': {
            'SMSHeader': 'SMS legitimationsoplysninger',
        },
        'FIELDS': {
            'ApiKey': 'Api Nøgle',
            'ApiSecret': 'Api Hemmelighed',
            'FromMobileNumber': 'Fra Mobilnummer',
         },
        'VALIDATIONS': {
        },
        'MESSAGES': {
            'Saved': 'Gemt Succesfuldt.',
        },
        'BUTTONS': {
            'Save': 'Gem',
        }
    }
};

