export const locale = {
  lang: 'ds',
  data: {
    'COMPANYINFO': {
      'TITLE': 'Firma-info',
      'DESC': 'Hej firma-info!'
    },
    'BUTTONS': {
      'SAVE': 'Gem',
      'BACK': 'Gå tilbage'
    },
    'HEADER': {
      'ABOUTUS': 'Om os',
      'CONTACTUS': 'Kontakt os',
      'WRITEDESCRIPTION': 'Skriv Beskrivelse',
      'COMPANYINFOTERMS': 'Firma info vilkår',
        'COMPANTINFOOFFERTERMS': 'Tilbuds vilkår',
      'LOGO': 'Selskab Logo',
      'OfferDescription': 'Tilbud beskrivelse',
      'HumidityAndTemperatureDescription': 'Fugtighed og temperatur',
      'OffersEmailTemplateDescription': 'Email Beskrivelse',
      'OffersContactUsDescription': 'PDF Beskrivelse',
    },
    'INFORMATION': {
      'ABOUTUS': {
        'MAINPARA': 'I mange mange år har Vibes Malerfirma levet af trofaste kunder, der vender tilbage og anbefaler os.Vi er stolte af at holde vores aftaler og levere et godt stykke arbejde - det er vores opskrift til succes. Vi har Danmarks MÅSKE bedste malere. For mere besøg ',
        'BOLDTEXT': 'www.vibesmalerfirma.dk '
      },
      'CONTACTUS': {
        'ADDRESS': 'Adresse',
        'EMAIL': 'Email',
        'CoverNumber': 'Cvr.nr.',
        'TELEPHONE': {
          'Title': 'Telefon',
          'MainNumber': 'Hoved nummer',
          'StoreNumber': 'Butiks telefon'
        }
      },

    },
    'Tabs': {
      'companyInfo': 'Oplysninger firma',
      'store': 'Butik',
      'sanne': 'Sanne',
      'kim': 'kim',
      'Holiday': 'Feriepenge SH peng',
      'martin': 'Martins opgaver'
    },
    'Fields': {
      'Conatct1': 'Hoved nummer',
      'Conatct2': 'Butiks telefon',
      'Address': 'Address',
      'DescriptionAboutUs': 'Beskrivelse Om os',
      'name': 'Navn',
      'city': 'By',
      'zip': 'PostNummer',
      'website': 'WWW',
      'address': 'Adresse',
      'aboutUs': 'Om os',
      'coverNumber': 'Cvr nr.',
      'RegistrationNo': 'Reg.nr.',
      'AccountNo': 'Konto nr.'
    },
    'MESSAGES': {
      'Failed': 'Operation mislykket, prøv senere',
      'Success': 'Succes.',
    },
    'TOOTIPORTITLE': {
      'Edit': 'Redigere',
      'Delete': 'Slet',
      'SendSMS': 'Send SMS',
      'SendEmail': 'Send E-mail',
      'Setting':'Indstilling'
    }
  }
};
