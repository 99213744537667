import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'amTimeAgo' })
export class AngularMomentTimeAgo implements PipeTransform {
  constructor() { }

  transform(value: string): string {
    if (value) {
      return moment(value).fromNow();
    }
    else
      return value;
  }
}
