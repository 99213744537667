import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { EmailSendingParamForOffer } from 'app/main/model/common';
import { CkeditorConfig } from 'app/constant/common.constant';
import { OffersMaterialDocumentDetail } from 'app/main/model/ProjectOffer';
import { ProjectofferService } from '../../project-offer.service';
import { PdfViewerModelComponent } from '../../../pdf-viewer/pdf-viewer.component';
import { CoworkerEmailSendDTO } from 'app/main/model/CoWorker';
import { RequestEmailSendingCoWorker } from 'app/main/model/RequestBase';
import { CommonService } from 'app/core/services/common.service';
import { AuthService } from 'app/core/services/auth.service';
import { FolderListDTO, FolderListNavigationDTO } from 'app/main/model/DropBox';
import { environment } from '../../../../../../environments/environment';
import { DropboxFilesService } from 'app/main/shared-component/dropbox-files/dropbox-files.service';
import { DriveModuleEnum } from '../../../../../core/enum/drive-module.enum';
@Component({
    selector: 'send-offer-email',
    templateUrl: './send-offer-email.component.html',
    styleUrls: ['./send-offer-email.component.scss']
})
export class SendOfferEmailComponent implements OnInit {
    showSpinner: boolean = false;
    config = CkeditorConfig;
    //ChangeEmailForm: FormGroup;
    //ChangeEmail: ChangeEmail;
    emailForm: FormGroup;
    emailSendingParam: EmailSendingParamForOffer = new EmailSendingParamForOffer();
    offerMaterialFileList: OffersMaterialDocumentDetail[] = [];
    isIncludePriceOnPDF: boolean = false;
    isShowRoomTotal: boolean = true;
    coWokers = [];
    fileFolderPathData: Array<FolderListNavigationDTO> = [];
    emailFilesAttachement: Array<FolderListDTO> = [];
  EmailSendingCoworkerList: CoworkerEmailSendDTO[] = [];
    constructor(private formBuilder: FormBuilder,
        private translationLoader: FuseTranslationLoaderService,
      private projectofferService: ProjectofferService,
      private commonService: CommonService,
      private authService: AuthService,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<SendOfferEmailComponent>,
        private dropboxFilesService: DropboxFilesService,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
        //this.ChangeEmail = new ChangeEmail();
        this.emailSendingParam = this.data;
        this.isIncludePriceOnPDF = this.data.isIncludePriceOnPDF;
        this.offerMaterialFileList = this.data.offerMaterialFiles;
        if (this.offerMaterialFileList.length > 0) {
            this.offerMaterialFileList.map((obj) => {
                obj.isSend = false;
                return obj;
            })
        }
        this.isShowRoomTotal = this.data.isShowRoomTotal;
        //this.ChangeEmailForm = this.formBuilder.group({
        //    email: ['', Validators.compose([Validators.required, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])]
        //});
        this.emailForm = this.formBuilder.group({
            //toName: [null, Validators.required],
            to: [null, Validators.compose([Validators.email, Validators.maxLength(80)])],
            fromName: [null],
            from: [null, Validators.compose([Validators.email, Validators.maxLength(80)])],
            subject: [null, Validators.required],
            sendAttachement: [null],
        });
        this.GetEmailSendingCoworkerList();
        this.storeFileFolderPathData();
    }

    SendEmail() {
        event.preventDefault();
        if (this.emailForm.valid) {
            this.emailSendingParam.isSendOfferWithRoomDetail = false;
            this.emailSendingParam.isIncludePriceOnPDF = this.isIncludePriceOnPDF;
            this.emailSendingParam.offerMaterialFileName = this.offerMaterialFileList.filter(o => o.isSend === true).map(y => y.fileName);
            this.emailSendingParam.offerDropboxFilePath = this.emailFilesAttachement.map(y => y.pathDisplay);
            this.dialogRef.close(this.emailSendingParam);
        }
    }

    SendEmailWithRoomDetail() {
        event.preventDefault();
        if (this.emailForm.valid) {
            this.emailSendingParam.isSendOfferWithRoomDetail = true;
            this.emailSendingParam.isIncludePriceOnPDF = this.isIncludePriceOnPDF;
            this.emailSendingParam.offerMaterialFileName = this.offerMaterialFileList.filter(o => o.isSend === true).map(y => y.fileName);
            this.emailSendingParam.offerDropboxFilePath = this.emailFilesAttachement.map(y => y.pathDisplay);
            this.dialogRef.close(this.emailSendingParam);
        }
    }

    SavePDF(flag) {
        var OfferInvoiceDetails = {
            "OfferId": this.emailSendingParam.offerId,
            "IsAddOfferRoomDetail": flag,
            "IsIncludePriceOnPDF": this.isIncludePriceOnPDF,
            "offerTaskIds": this.emailSendingParam.offerTaskIds,
            "isShowRoomTotal": this.isShowRoomTotal
        };
        this.showSpinner = true;
        this.projectofferService.GetOfferPDFbyIds(OfferInvoiceDetails)
            .subscribe(
                (response: ResponseBase) => {
            var resultData = response.result;
            var data = response.result.base64.replace("data:application/pdf;base64,", "");
            var OfferNumber = JSON.parse(localStorage.getItem("OfferNumber"));
            const dialogRef = this.dialog.open(PdfViewerModelComponent, {
                height: '90%',
                width: '90%',
                data: {
                    base64: data,
                    filename: response.result.filename ? response.result.filename + ".pdf" : "Tilbud_" + OfferNumber + ".pdf"
                }
            });
            dialogRef.afterClosed().subscribe(result => {
                if (result) {

                }
            });

        },
            error => (err) => {
                //this.showSpinner = false;
            },
                () => {
                    this.showSpinner = false;
            });
    }


    onCancel() {
        this.dialogRef.close(null);
    }

    onCheckIsIncludePriceOnPDF(flag) {
        this.isIncludePriceOnPDF = flag;
    }

    viewEmailDetail(link) {
        window.open(link, "_blank");

    }

    DropDownSearchCoWorker(coworker) {
        //this.coWokers = this.staticCoworkerList.filter(it => {
        //    return it.name.toLowerCase().includes(coworker.toLowerCase());
        //});
    }

    filesSelectionListOutEvent(obj) {
        this.emailFilesAttachement = obj;
    }

    storeFileFolderPathData() {
        var rootPath = this.dropboxFilesService.getSettingMasterValueDocumentPath();
        var paths = "";
        if (rootPath.DocumentDriveType == DriveModuleEnum.Onedrive) {
            paths = rootPath.Onedrive.Path;
        } else {
            paths = rootPath.Dropbox.Path;
        }
        this.fileFolderPathData = paths.split("/").map((path, i) => ({
            name: path,
            isDisplay: false
        }));
        if (this.emailSendingParam.projectId && this.emailSendingParam.projectNumber)
            this.fileFolderPathData.push(
                { name: 'Project', isDisplay: false },
                { name: (this.emailSendingParam.projectNumber).toString(), isDisplay: true }
            );
        else
            this.fileFolderPathData.push(
                { name: 'ProjectOffers', isDisplay: false },
                { name: (this.emailSendingParam.offerNumber).toString(), isDisplay: true }
            );
    }

  GetEmailSendingCoworkerList() {
    this.EmailSendingCoworkerList = [];
    var req = new RequestEmailSendingCoWorker();
    req.Index = 1;
    req.Limit = 100;
    req.IsActive = true;
    req.IsDeleted = false;
    this.showSpinner = true;
    this.commonService.GetEmailSendingCoworkerList(req).subscribe({
      next: (response: ResponseBase) => {
        this.showSpinner = false;
        this.EmailSendingCoworkerList = [];
        if (response.result != null) {
          response.result.filter(o => {
            var emailsendCow = new CoworkerEmailSendDTO();
            emailsendCow.id = o.id;
            emailsendCow.name = o.name;
            emailsendCow.email = o.email;
            emailsendCow.phone = o.phone;
            emailsendCow.mobile = o.mobile;
            emailsendCow.sendEmailId = o.sendEmailId;
            emailsendCow.sendEmailPassword = o.sendEmailPassword;
            this.EmailSendingCoworkerList.push(emailsendCow);
          });
          const fromEmail = this.authService.getSMTPEmail();
          var emailsendCow = new CoworkerEmailSendDTO();
          emailsendCow.id = "1";
          emailsendCow.name = fromEmail;
          emailsendCow.email = fromEmail;
          emailsendCow.phone = fromEmail;
          emailsendCow.sendEmailId = fromEmail;
          this.EmailSendingCoworkerList.push(emailsendCow);

          var loginCoworkerID = this.authService.getCoWorkerId();
          var loginCoworker = this.EmailSendingCoworkerList.find(o => o.id == loginCoworkerID);
          if (loginCoworker != null) {
            this.emailSendingParam.from = loginCoworker.sendEmailId;
          }
          else {
            this.emailSendingParam.from = fromEmail;
          }
        }

      },
      error: err => {
        this.showSpinner = false;
      }
    });
  }
}
