export const locale = {
    lang: 'ds',
    data: {
      'PROJECT': {
        'TITLE': 'Projekt',
        'DESC': 'Hej Projekt!'
      },
      'BUTTONS': {
        'BACK': 'Tilbage',
        'SAVE': 'Gem',
    },
      'TITLE': {
        'ProjectPagination': 'Antal projekter pr. side',
      },
      'FILTERS': {
        'SEARCH': 'Søg',
        'SORTING': 'Sorter efter',
        'SORTING_STATUS': 'Status',
        'Active': 'Inkluder alle inaktive',
        'BADGE': 'Inaktive',
        'Deleted': 'Vis KUN slettet sager',
        'All': 'Alle',
        'Lift/Stigemand': 'Lift/Stigemand',
        'Indoor/Outdoor': 'Indoor/Outdoor',
        'Lift/Stigmand': 'Lift/Stigmand',
        'Ind/Ud': 'Ind/Ud',
        'MtoM': 'M til M',
        'Note': 'Bemærk',
        'Offers': 'Tilbud',
        'Show60DayOld': 'Vis kun projekter de sidste 60 dage',
        'ShowHourDetails': 'Vis time beskrivelser',
        'TotalProject': 'I alt projekter',
        'TotalProjectRemainingHours': 'Projekter resterende timer',
        'TotalProjectDaysLeft': 'Projekter resterende Dage',
        'LastDayWorkingHour': 'Sidste dags arbejdstid',
        'TotalProjectPrice': 'I alt projekter Pris',
        'PurchaseStatus': 'Købs status',
        'AverageAmount': 'gennemsnitligt beløb',
        'TotalProjectRemainingPris': 'Projektets resterende pris',
        'SearchByZip': 'Søg Postnummer',
      },
      'MESSAGES': {
          'NORECORD': 'Intet optage fundet',
          'NORECORDONSTATUS': 'Du finder, at projektet kan have en anden status eller inaktivt eller slettet...',
          "SELECTCOWORKER":'Vælg venligst Medarbejdere',
          'Created': 'Oprettet med succes',
          'Failed': 'Operation mislykket, prøv senere',
      },
      'FIELDS': {
        'IndoorOutDoor': 'Ind, ud, Sprøjt, Reklamation *',
        'Lift': 'Lift',
        'Stigemand': 'Stigemand',
        'CaseNumber': 'Sagsnummer',
        'Title': 'Titel',
        'Client': 'Klient',
        'Address': 'Adresse',
        'ProjectEndDate': 'Indtil dato',
        'EndDate': 'Slut dato',
        'CoWorkers': 'Co-Workers',
        'Files': 'Filer',
        'actionsColumn': 'Handling',
        'From': 'Fra',
        'To': 'til',
        'Not-Found-CUSTOMER': 'Kunde not found',
        'Not-Found-MATERIAL': 'Materiale not found',
        'Not-Found-Worker': 'Medarbejder ikke fundet',
        'TOTAL': 'i alt',
        'Coworker': 'Medarbejdere',
        'HoursBack': 'Timer tilbage',
        'Before60Days': '*** 60 Dag gammel.',
        'NoOfEmployee': 'Antal medarbejdere',
        'MaterialPurchasePriseTotal': 'Sum Lager',
        'RemainingTime': 'Resterende tid',
        'Planned': 'Planlagt',
        'HourlyWage': 'Timeløn',
        'Dankort': 'Dankort',
        'PaymentMode1': 'Dankort',
        'PaymentMode2': 'Mastercard',
        'PaymentMode3': 'kontant',
        'PaymentMode4': 'Mobilpay',
        'PaymentMode5': 'Mobilepay KK',
        'PaymentMode6': 'Kredit',
        'PaymentMode7': 'Andet',
        'visit': 'Besøg',
        'Text': 'Tekst*',
        'OpgaveText': 'Rekvisitions Tekst',
        'InvoiceText': 'Faktura Tekst',
        'SagNumber':'SagNumber',
        'Name':'Navn',
        'ZipCode':'Postnummer',
        'city':'by',
        'CustomerName':'Kundenavn',
        'CustomerDetail': 'Debitor Detaljer',
        'ProjectAddress': 'Sag Adresse',
      },
    }
  };
  

