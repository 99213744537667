export const locale = {
  lang: 'ds',
  data: {
    'Default': {
      'Loading': 'Indlæser...',
      'SelectControlPlaceholder': 'Vælg',
      'SearchControlPlaceholder': 'Søg',
      'NotFoundMessage': 'Data ikke fundet',
      'ControlValRequired': 'påkrævet',
    },
    'Coworker': {
      'Loading': 'Indlæser...',
      'SelectControlPlaceholder': 'Vælg Medarbejder',
      'SearchControlPlaceholder': 'Søg Medarbejder',
      'NotFoundMessage': 'Medarbejder ikke fundet',
      'ControlValRequired': 'Medarbejder påkrævet',
    },
    'Customer': {
      'Loading': 'Indlæser...',
      'SelectControlPlaceholder': 'Vælg kunde',
      'SearchControlPlaceholder': 'Søg kunde',
      'NotFoundMessage': 'Kunde ikke fundet',
      'ControlValRequired': 'Kunde påkrævet',
    },
    'Supplier': {
      'Loading': 'Indlæser...',
      'SelectControlPlaceholder': 'Vælg leverandør',
      'SearchControlPlaceholder': 'Søg leverandør',
      'NotFoundMessage': 'Leverandør ikke fundet',
      'ControlValRequired': 'Leverandør påkrævet',
    },
    'Material': {
      'Loading': 'Indlæser...',
      'SelectControlPlaceholder': 'Vælge Lager',
      'SearchControlPlaceholder': 'Søg Lager',
      'NotFoundMessage': 'Lager ikke fundet',
      'ControlValRequired': 'Lager påkrævet',
    },
  }
};
