import { EventEmitter, Injectable, OnInit } from '@angular/core';
import * as signalR from '@microsoft/signalr';          // import signalR
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SignalRService {
    private apiuri = `${environment.url}`;
    private signalRHubName = `${environment.signalRHubName}`;
    messageReceived = new EventEmitter<any>();
    planningMessageReceived = new EventEmitter<any>();
    connectionEstablished = new EventEmitter<Boolean>();

    private connectionIsEstablished = false;
    private _hubConnection: HubConnection;

    constructor(private http: HttpClient) {
        this.createConnection();
        this.registerOnServerEvents();
        this.startConnection();
    }


    sendMessage(message: any) {
        this._hubConnection.invoke('SendMessage', message);
    }

    sendPlanningMessage(message: any) {
        this._hubConnection.invoke('SendPlanningMessage', message);
    }

    private createConnection() {
        this._hubConnection = new HubConnectionBuilder()
            .withUrl(this.apiuri + "" + this.signalRHubName)
            .configureLogging(LogLevel.Information)
            .withAutomaticReconnect()
            .build();
    }

    private startConnection(): void {
        this._hubConnection
            .start()
            .then(() => {
                this.connectionIsEstablished = true;
                console.log('Hub connection started');
                this.connectionEstablished.emit(true);
            })
            .catch(err => {
                console.log('Error while establishing connection, retrying...');
                setTimeout(function () { this.startConnection(); }, 5000);
            });
    }

    private registerOnServerEvents(): void {
        this._hubConnection.on('ReceiveMessage', (data: any) => {
            console.log("call ReceiveMessage");
            this.messageReceived.emit(data);
        });
        this._hubConnection.on('ReceivePlanningMessage', (data: any) => {
            console.log("call ReceivePlanningMessage");
            this.planningMessageReceived.emit(data);
        });
    }


}
export class MessageDto {
    public user: string = '';
    public msgText: string = '';
}