export const locale = {
    lang: 'en',
    data: {
        'Tabs': {
            'Send': 'Send',
            'HolidayList': 'Holiday List',
            'Material': 'Material',
            'Project': 'Project',
        },
        'Header':{
            'ProjectEconomy': 'Project Economy',
            'CalculateProjectPriceStatus': 'Calculate project price status',
        },
        'Message': {

        },
    }
};
