export const locale = {
    lang: 'ds',
    data: {
        'MESSAGES': {
            'Created': 'Oprettet med succes',
            'Updated': 'Opdateret med succes',
            'Deleted': 'Slettet med succes',
            'Failed': 'Operation mislykket, prøv senere',
        },
        'BUTTONS': {
            'AddScanMaterial': 'Tilføj scanne materiale',
            'SAVE': 'Gem',
            'BtnAdd': 'Tilføj / Gem',
            'ViewColorCode': 'Se farvekode',
            'ChoseProject': 'Vælg Projekt',
            'Back': 'Tilbage'
        },
        'FIELDS': {
            'Status': 'Status',            
            'Add': 'Tilføj',
            'SelectProjectToAdd': 'Vælg projekt',
            'SagNo': 'Sag No.',
            'Address': 'Adresse',
            'OpgaverText': 'Opgave Tekst',
            'StartDate': 'Start dato ',
            'EndDate': 'Slut dato ',
            'Zipcode': 'Post.',
            'City': 'By',
            'Number': 'Sr.',
        },
        'selectedValueIndoorOutdoorStatus': {
            'value1': 'Indendørs ',
            'value2': 'Udendørs',
            'value3': 'Sprøjtemaling',
            'value4': 'Reklamation',
            'value5': 'Personale køb', //drawing of painter
            'value6': 'Butiks køb'     //Nordsjø logo
        },
    }
};

