export const locale = {
    lang: 'ds',
    data: {
        'TABS': {
        },
        'Message': {
            'MoveMessage': 'Du kan ikke flytte projektarbejdstid til de andre medarbejdere',
            'Add': 'Oprettet',
            'Add_Error': 'Ingen registrering fundet på Vagttider eller Vagtplan bordet, kontakt venligst til administrator.',
            'Updated': 'Opdateret med succes',
            'PreventMoveToHolidays': 'du kan ikke flytte arbejdstid på helligdage',
            'PreventMoveToPastdays': 'du kan ikke flytte arbejdstid på forudgående dato',
            'MoveProject': "Vil du flytte projektet",
            'MoveLeave': "Vil du flytte",
            'From': 'Fra',
            'To': 'til',
            'MovedSuccessfully': 'Flyttet succesfuldt',
            'MoveError': 'Fejl mens bevæger sig planlægning ',
            'CopySuccessfully': 'Kopi succesfuldt',
            'CopyError': 'Fejl under kopiering ',
            'RefreshPlanningMessage': 'Du har modtaget en ny planlægning på @StartDate til @EndDate skal du opdatere skærmen?',
        },
        'Buttons': {
            'Plan': 'Planlæg',
            'Nonworkinghours': 'Ikke-arbejdstid',
            'View': 'Udsigt',
            'Removenonworkinghours': 'Fjern ikke-arbejdstid',
            'Project': 'Projekter',
            'Inquiry': 'Forespørgsler',
            "DefaultCoworker": 'Standard medarbejder',
            'Question': 'Spørgsmål',
            'VehicleInfo':'Nanolink',
            'Replace':'Erstat',
            'MultiPlanning':'Multi planlægning',
            'AddDefaultCoworker': 'Tilføje medarbejder',
        },
        'Lable': {
            'Timer': 'Timer',
            'HoursLeft': 'Timer tilbage',
            'Lift': 'Lift',
            'Stigemand': 'Stigemand',
            'RequisitionText': 'Opgave tekst',
            'No': 'Nej',
            'Yes': 'Ja',
        },
        'FIELDS': {
            'StartDate': 'Start dato',
            'EndDate': 'Slut dato',
            'NoOfEmployee': 'Antal medarbejdere',
            'MaterialPurchasePriseTotal': 'Sum Lager',
            'TotalProjectDaysLeft': 'Projekter resterende Dage',
            'TotalProject': 'I alt projekter',
            'TotalProjectRemainingHours': 'Projekter resterende timer',
            'TotalPlanningRemainingHours': 'Planlægning af resterende timer',
            'TotalPlanningDaysLeft': 'Planlægning resterende dage',
            'TotalPlanningHours': 'Samlet planlægning timer',
            'Coworker': 'Medarbejdere',
            'WorkhoursType': 'Arbejdstid type',
            'nonStatus': 'Status',
            'ShowHourDetails': 'Time beskrivelser',
            'ShowTime': 'Vis Time',
            'HourlyWage': 'Timeløn',
            'TotalProjectPrice': 'I alt projekter Pris'
        },
        'selectedWorkHourStatus': {
            'value1': 'Ferie ',
            'value2': 'Syg',
            'value3': 'Feriefridag',
            'value4': 'Fridag m.løn',
            'value5': 'Fridag u.løn',
            'value6': 'Kursus/skole',
            'value7': 'Afspadsering',
            'value8': 'Andet',
            'value9': 'Tilbud'
        },
        'selectedWorkHoursTypeOption': {
            'value1': 'Begge',
            'value2': 'Arbejdstimer',
            'value3': 'Ikke-arbejdstid',
        },
        'selectedWorkHoursApproveLeaveOption': {
            'value1': 'Ansøg',
            'value2': 'Godkendt',
            'value3': 'Afvist',
        },
        'FILTERS': {
            'IndoorOutDoor': 'Ind, ud, Sprøjt, Reklamation *',
            'SEARCH': 'Søg',
            'SORTING': 'Sorter efter',
            'SORTING_STATUS': 'Status',
            'Active': 'Inkluder alle inaktive',
            'BADGE': 'Inaktive',
            'Deleted': 'Vis KUN slettet sager',
            'All': 'Alle',
            'Lift/Stigemand': 'Lift/Stigemand',
            'Indoor/Outdoor': 'Indoor/Outdoor',
            'Lift/Stigmand': 'Lift/Stigmand',
            'Ind/Ud': 'Ind/Ud',
            'MtoM': 'M til M',
            'Note': 'Bemærk',
            'Offers': 'Tilbud',
            'Show60DayOld': 'Vis 60 dage gammel',
            'ShowHourDetails': 'Time beskrivelser',
            'TotalProject': 'I alt projekter',
            'TotalProjectRemainingHours': 'Projekter resterende timer',
            'TotalProjectDaysLeft': 'Projekter resterende Dage',
            'LastDayWorkingHour': 'Sidste dags arbejdstid',
            'ShowTime': 'Vis Time'
        },
    }
};
