export const locale = {
  lang: 'en',
  data: {
    'MESSAGES': {
      'Add': 'Added successfully',
    },
    'BUTTONS': {
      'SelectProject': 'Select Project',
      'CreateReminder': 'Create Reminder',
        'Cancel': 'Cancel',
        'CreateReviewQuestion':'Create Review Question',
    },
    'TITLE': {
    },
    'FIELDS': {
      'ReminderDate': 'Select Reminder Date',
      'ChooseFile': 'Choose File',
      'NoFileSelected': 'No File Selected',
      'FileSelected': 'File Selected',
      'ReviewQuestion': 'Review Question',
    },
    'PlaceHolder': {
      'EnterText': 'Enter Reminder Text*',
      'ReminderDate': 'Select Reminder Date & Time*',
      'ReminderEndDate': 'Select Reminder End Date & Time',
      'PleaseSelectFromBelow': 'Please Select From Below',
      'DoYouWantRepeatThisReminder': 'Do you want to repeat this reminder?',
      'ProjectNumber': 'Case Number',
    },
    'selectedReminderRepeatModes': {
      'value1': 'Daily ',
      'value2': 'Weekly',
      'value3': 'Monthly',
      'value4': 'Yearly'
    }
  }
};
