import { Component, OnInit } from '@angular/core';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { Router } from '@angular/router';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { MatDialog } from '@angular/material';
import { CoWorkersService } from './coWorkers.service';
import { CoWorker } from 'app/main/model/CoWorker';
import { RequestCoWorker } from 'app/main/model/RequestBase';
import { ResponseBase } from 'app/main/model/ResponseBase';
//import { PaginationComponent } from '../pagination/pagination.component';
import { AuthService } from 'app/core/services/auth.service';
import { TitleService } from 'app/core/services/title.service';
import { CoWorkerNotificationComponent } from './component/coWorker-notification/coWorker-notification.component';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';


@Component({
    selector: 'vibe-coWorker',
    templateUrl: './coWorker.component.html',
    styleUrls: ['./coWorker.component.scss']
})

export class VibeCoWorkerComponent implements OnInit {

    //Variable Diclarartion
    coWorkers: CoWorker[];
    // isValid: boolean = false;
    data: any;
    Name: string = '';
    pagination: any;
    limitNumber: any;
    displayPagination: boolean = false;
    // roleName: string;
    orderBy: string = "Name";
    showSpinner: boolean = false;
    isActive: boolean = true;
    isDeleted: boolean = false;
    isLoadedCoworker: boolean = false;
    settingMasterValues: any[] = [];
    roleClaims: any;
    constructor(private translationLoader: FuseTranslationLoaderService,
        private coworkersService: CoWorkersService,
        private _router: Router,
        private authService: AuthService,
        private titleService: TitleService,
        private dialog: MatDialog) {
        this.titleService.SetTitle("TITLE_COWORKERS");
        this.translationLoader.loadTranslations(english, danish);
        this.roleClaims = this.authService.getRoleClaimsList();
        this.settingMasterValues = Object.assign([], JSON.parse(localStorage.getItem("settingMasterValues")));
        var tempSettingObject = this.settingMasterValues.find(s => s.key1 == "Menu" && s.key2 == "TabsListSettings").value;
        if (tempSettingObject && JSON.parse(tempSettingObject).find(x => x.name == 'coWorkers').isInitialLoading)
            this.GetCoWorker(new RequestCoWorker());
    }

    ngOnInit() {
        // this.roleName = this.authService.getUserRole();

        // if (this.roleName == "Admin") {
        //   this.isValid = true;
        // }
        // else if (this.roleName == "Kontor") {
        //   this.isValid = true;
        // }
        // else if (this.roleName == "Maler") {
        //   this.isValid = false;
        // }
        // else if (this.roleName == "Gaest") {
        //   this.isValid = false;
        // }
        // else {
        //   this.isValid = true;
        // }

    }

    GetCoWorker(model: RequestCoWorker) {
        this.showSpinner = true;
        model.OrderBy = this.orderBy;
        model.IsActive = this.isActive;
        this.coworkersService.Get<ResponseBase>(model)
            .subscribe((response: ResponseBase) => {
                if (response != null) {
                    this.coWorkers = Array<CoWorker>();
                    for (var i = 0; i < response.result.length; i++) {
                        if (!response.result[i].userId)
                            response.result[i].displayButton = false;
                        else
                            response.result[i].displayButton = true;
                        this.coWorkers.push(response.result[i]);
                    }
                    this.coWorkers = response.result;
                    if (!this.isLoadedCoworker) {
                        var tempcoWorkers = response.result.filter(x => x.doNotShowOnPlanReminderProj == false);
                        this.coworkersService.setResponseForCoworker(tempcoWorkers);
                    }
                    this.isLoadedCoworker = true;
                    this.GetInCaseOfAccidentDetail(this.coWorkers);
                    this.pagination = response.pagination;
                    this.displayPagination = true;
                    this.showSpinner = false;

                }
                else {
                    this.coWorkers = [];
                    this.displayPagination = false;
                    this.showSpinner = false;

                }
            },
                error => (err) => {
                    this.coWorkers = [];
                    this.displayPagination = false;
                    this.showSpinner = false;

                },
                () => {
                });
    }

    getDeletedCoworkers(isDeleted) {
        var model = new RequestCoWorker();
        model.IsDeleted = this.isDeleted = isDeleted;
        this.GetCoWorker(model)
    }

    checkAccess(id) {
        this.EditCoWorker(id);
    }

    EditCoWorker(id) {
        this._router.navigate(['/coWorkers/edit', id]);
    }

    Search(value) {
        var request = new RequestCoWorker();
        if (value == undefined || value.toString().length % 1 == 0) {
            request.Name = this.Name.trim();
            request.OrderBy = this.orderBy;

            this.GetCoWorker(request);
        }
    }

    isCreateNew() {
        var settingHelpObj = this.settingMasterValues.find(o => o.key1 == "AllModule" && o.key2 == "CreateTimeFillWithDummyData");
        if (settingHelpObj) {
            var value = JSON.parse(settingHelpObj.value);
            if (value.IsAddCoworkerHelp == true) {
                const dialogRef = this.dialog.open(AlertMessageModelComponent, {
                    width: '500px',
                    data: { Header: 'Headers.CreateCoworkerWithHelpData' }
                });

                dialogRef.afterClosed().subscribe(result => {
                    if (result) {
                        this._router.navigate(['/coWorkers/add-with-help']);
                    }
                    else {
                        this._router.navigate(['/coWorkers/add']);
                    }
                });
            }
            else
                this._router.navigate(['/coWorkers/add']);
        }
        else
            this._router.navigate(['/coWorkers/add']);
    }

    receivePaginatonEvent(event) {
        var request = new RequestCoWorker();
        request.Index = event.pageIndex;
        request.Limit = event.pageSize;
        this.limitNumber = event.pageSize;

        request.Name = this.Name.trim();
        this.CreateCoWorkerRequest(request);
    }

    receiveFilterEvent(event) {
        var request = new RequestCoWorker();
        this.orderBy = event;
        if (this.limitNumber == undefined) {
            request.Limit = 100
        }
        else {
            request.Limit = this.limitNumber;
        }
        this.CreateCoWorkerRequest(request);
    }

    CreateCoWorkerRequest(request) {
        request.Name = this.Name.trim();
        if (this.orderBy != undefined)
            request.OrderBy = this.orderBy;
        this.GetCoWorker(request);
    }

    isNumber(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    GetInCaseOfAccidentDetail(value: CoWorker[]) {
        if (value) {
            value.map(c => {
                c.inCaseOfAccidentDetail = [];
                if (c.inCaseOfAccident)
                    c.inCaseOfAccidentDetail = JSON.parse(c.inCaseOfAccident.replace(/'/g, '"'));
                else {
                    c.inCaseOfAccidentDetail.push(new CoWorker());
                }
            })
        }
    }

    onSetActive(active) {
        this.isActive = active;
        var request = new RequestCoWorker();
        request.OrderBy = this.orderBy;
        request.Name = this.Name.trim();
        this.GetCoWorker(request);
    }

    openNotificationPopup() {
        const dialogRef = this.dialog.open(CoWorkerNotificationComponent, {
            height: '70%',
            width: '80%',
            data: this.coWorkers
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != null && result != '') {
            }
        });
    }
}



