import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../../../core/modules/shared.module';

import { VibeHelpComponent } from './help.component';
import { CustomerhelpComponent } from './customerhelp/customerhelp.component';
import { HelpMaterialComponent } from './help-material/help-material.component';
import { HelpDocumentNavigationComponent } from './help-document-navigation/help-document-navigation.component';
import { HelpCoworkerComponent } from './help-coworker/help-coworker.component';
import { HelpProjectComponent } from './help-project/help-project.component';
import { HelpDocumentNavigationEnComponent } from './help-document-navigation-en/help-document-navigation-en.component';
import { HelpCustomerEnComponent } from './help-customer-en/help-customer-en.component';
import { HelpMaterialEnComponent } from './help-material-en/help-material-en.component';
import { HelpCoWorkerEnComponent } from './help-co-worker-en/help-co-worker-en.component';
import { HelpProjectEnComponent } from './help-project-en/help-project-en.component';
import { HelpNavbarComponent } from './help-navbar/help-navbar.component';


const routes = [
  {
    path: 'help-en',
    component: HelpDocumentNavigationEnComponent
  },
  {
    path: 'help-ds',
    component: HelpDocumentNavigationComponent
  }
  //{
  //  path: 'help/customer-help',
  //  component: CustomerhelpComponent
  //},
  //{
  //  path: 'help/material-help',
  //  component: HelpMaterialComponent
  //}
];

@NgModule({
  declarations: [
    VibeHelpComponent,
    CustomerhelpComponent,
    HelpMaterialComponent,
    HelpCoworkerComponent,
    HelpDocumentNavigationComponent,
    HelpProjectComponent,
    HelpDocumentNavigationEnComponent,
    HelpCustomerEnComponent,
    HelpMaterialEnComponent,
    HelpCoWorkerEnComponent,
    HelpProjectEnComponent,
    HelpNavbarComponent],
  imports: [
    SharedModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    VibeHelpComponent,
    HelpMaterialComponent,
    HelpNavbarComponent
  ]
})

export class VibeHelpModule {
}
