//#region Imports 
    // angular 
    import { Component, OnInit, Output, EventEmitter, Input, ChangeDetectorRef } from "@angular/core";
    //rxjs
    import { BehaviorSubject } from "rxjs";
    // model
    import { ResponseBase } from 'app/main/model/ResponseBase';
    import { RequestCoWorker } from "app/main/model/RequestBase";
    import { CoWorker } from "app/main/model/CoWorker";
    import { OfferQualityTestQuestionAnswer } from "./new-question.model";
    // interfaces
    import { locale as english } from './translate/en';
    import { locale as danish } from './translate/ds';
    // services
    import { CoWorkersService } from "app/main/pages/coWorker/coWorkers.service";
    // app-core
    import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
    // third party packages
    // third party tool
//#endregion Imports 


@Component({
    selector: "vibe-new-questions",
    templateUrl: "./new-questions.component.html",
    styleUrls: ["./new-questions.component.scss"]
})

export class NewQuestionsComponent implements OnInit {
//#region Initializations

  //#region @Input
  private _QuestionList = new BehaviorSubject<OfferQualityTestQuestionAnswer[]>(null);
  @Input()
  set QuestionList(value) {
      this._QuestionList.next(value);
  };
  get QuestionList() {
      return this._QuestionList.getValue();
  }
  //#endregion @Input
  
  //#region @Output
  @Output("AddOrUpdateQuestionSave") AddOrUpdateQuestionSave: EventEmitter<OfferQualityTestQuestionAnswer[]> = new EventEmitter(null);
  @Output("DeleteQuestionByID") DeleteQuestionByID: EventEmitter<string> = new EventEmitter(null);
  //#endregion @Output

  //#region @ViewChild
  //#endregion @ViewChild

  //#region Form
  //#endregion Form

  //#region Variables
  editableQuestion = null;
  showSpinner: boolean = false;
  onCancelEditableForm: boolean = false;
  coWokers:CoWorker[] = [];
  questionAnswerList:any[] = [];
  editedQuestionIndex: number = -1;
  //#endregion Variables

    constructor(
        private translationLoaderService: FuseTranslationLoaderService,
        private cdr: ChangeDetectorRef,
        private coWorkersService: CoWorkersService
    ) {
        this.translationLoaderService.loadTranslations(danish, english);
    }
//#endregion  Initializations



//#region Private/Helper Methods
    private getCoWorkers(model: RequestCoWorker) {
        model.OrderBy = "Name";
        this.coWorkersService.Get<ResponseBase>(model)
            .subscribe((response: ResponseBase) => {
                if (response) {
                    this.coWokers = response.result;
                }
                else {
                    this.coWokers = [];
                }
            }, error => (err) => {
                this.coWokers = [];
            }, () => {
            });
    }
//#endregion Private/Helper Methods



//#region Public Methods
    public onEditableQueIndex(i) {
        this.onCancelEditableForm = false;
        this.editedQuestionIndex = i;
        this.editableQuestion = Object.assign({}, this.questionAnswerList[i])
    }
    public onDeletableQueIndex(i) {
        let deletableQuestion = Object.assign({}, this.questionAnswerList[i])
        this.DeleteQuestionByID.emit(deletableQuestion.id);
    }
    public onCancelForm() {
        this.onCancelEditableForm = true;
        this.editedQuestionIndex = -1;
    }
    public onSaveForm(obj) {
        if(this.editedQuestionIndex>-1){
            this.questionAnswerList[this.editedQuestionIndex] = Object.assign({}, obj);
        } else {
            this.questionAnswerList.push(Object.assign({}, obj));
        }
        this.questionAnswerList = Object.assign([], this.questionAnswerList);
        this.AddOrUpdateQuestionSave.emit(this.questionAnswerList);
    }
//#endregion Public Methods



//#region Lifecycle Hooks
    ngOnInit() {
        this._QuestionList
            .subscribe(list => {
                if(list && list.length>0){
                    this.questionAnswerList = Object.assign([], list);
                } else {
                    this.questionAnswerList = [];
                }
                this.questionAnswerList = Object.assign([], this.questionAnswerList);
            });
            
        var coworkerOBJ = new RequestCoWorker();
            coworkerOBJ.Name = "";
        this.getCoWorkers(coworkerOBJ);
    }
    ngAfterViewChecked() {
        this.cdr.detectChanges();
    }
    ngAfterViewInit() {
    }
    ngOnDestroy() {
    }
//#endregion Lifecycle Hooks
}