import { Component, OnInit } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { SettingsService } from '../../settings.service';
import { ResponseBase } from "app/main/model/ResponseBase";
import { Settings } from "app/main/model/Settings";

@Component({
    selector: "vibe-payment-settings",
    templateUrl: "./payment-settings.component.html",
    styleUrls: ["./payment-settings.component.scss"]
})

export class PaymentSettingsComponent implements OnInit {

    showSpinner: boolean = false;
    paymentSettingObj: Settings[];
    paymentGeneralSettingObject:Settings
    paymentSalaryCalculationSettingObject:Settings

    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private settingsService: SettingsService) {
    }

    ngOnInit() {
        this.getPatmentTabSettings();
    }

    getPatmentTabSettings() {
        this.showSpinner = true;
        this.settingsService.GetSettingsWithMappingValue<ResponseBase>("Payment", null, null, true).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.result && response.result.myResponse.length > 0) {
                    this.paymentSettingObj = response.result.myResponse;
                    this.paymentSettingObj.forEach(element => {
                        if (element.key2 == 'General') {
                            this.paymentGeneralSettingObject = element;
                        }
                        if(element.key2 == 'SalaryCalculation'){
                            this.paymentSalaryCalculationSettingObject = element
                        }
                    });
                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

}