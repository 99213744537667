export const locale = {
  lang: 'en',
  data: {
    'FIELDS': {
      'NewPassword': 'New Password',
      'ConfirmPassword': 'Confirm Password',
      'OldPassword': 'Old Password',
      'Updated': 'Updated successfully',
      'Failed': 'Operation failed , try later',
      'PasswordLength': 'Password Must be 6-20 length',
      'PasswordNotMatch': 'Password not match',
      'PasswordPattern':'Password must be conatin a-z and 1-9',
    },
    'BUTTON': {
      'Cancel': 'Cancel',
      'ChangePassword': 'Change Password'
    }
  }
};
