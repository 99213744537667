export const locale = {
    lang: 'ds',
    data: {
        'Tabs': {
            'Send': 'Send',
            'HolidayList': 'Ferieliste',
            'Material': 'Materiale',
            'Project': 'Projekt',
        },
        'Header':{
            'ProjectEconomy': 'Projekt økonomi',
            'CalculateProjectPriceStatus': 'Beregn status for projekt pris',
        },
        'Message': {

        },
    }
};
