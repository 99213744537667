import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { MatDialog, MatOption, MatSlideToggleChange } from '@angular/material';
import { AuthService } from 'app/core/services/auth.service';
import { SettingsService } from '../../settings.service';
import { ResponseBase } from "app/main/model/ResponseBase";
import { Settings } from "app/main/model/Settings";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { CoWorker } from "app/main/model/CoWorker";
import { RequestCoWorker } from "app/main/model/RequestBase";
import { CoWorkersService } from "app/main/pages/coWorker/coWorkers.service";
import { NotificationsService } from "angular2-notifications";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "environments/environment";
import { ReminderWeekDays } from "app/main/model/Reminder";

@Component({
  selector: 'vibe-reminder-smssettings',
  templateUrl: './reminder-smssettings.component.html',
  styleUrls: ['./reminder-smssettings.component.scss']
})
export class ReminderSMSSettingsComponent implements OnInit {
  @Input("SendReminderSMSSetting") SendReminderSMSSetting: Settings;
  @Input("CoWokers") CoWokers: CoWorker[];

  showSpinner: boolean = false;
  reminderSMSSettingForm: FormGroup;
  isSubmitted = false;
  coWokers: CoWorker[] = [];
  ReminderSMSNotificationsObj: Settings = new Settings();
  settingMasterValues: any[] = [];
  weekDays = ReminderWeekDays;
  IsGettingCoWokers: boolean = false;

  constructor(private translationLoaderService: FuseTranslationLoaderService,
    private fb: FormBuilder,
    private coWorkersService: CoWorkersService,
    private authService: AuthService,
    private settingsService: SettingsService,
    private translate: TranslateService,
    private notificationsService: NotificationsService,
    private dialog: MatDialog) {
    this.translationLoaderService.loadTranslations(danish, english);
  }

  ngOnInit() {
    this.settingMasterValues = Object.assign([], JSON.parse(localStorage.getItem("settingMasterValues")));

    // var coworkerOBJ = new RequestCoWorker();
    // coworkerOBJ.Name = "";
    // this.getCoWorkers(coworkerOBJ);

    this.reminderSMSSettingForm = this.fb.group({
      CoworkersID: [null, Validators.required],
      ReminderSMSAfterMin1: [null, Validators.compose([Validators.required, Validators.min(1), Validators.max(3600)])],
      ReminderSMSAfterMin2: [null, Validators.compose([Validators.required, Validators.min(1), Validators.max(3600)])],
      ReminderSMSAfterMin3: [null, Validators.compose([Validators.required, Validators.min(1), Validators.max(3600)])],
      ReminderSMSAfterMinAdmin: [null, Validators.compose([Validators.required, Validators.min(1), Validators.max(3600)])],
      ReminderSMSText1: [null, Validators.compose([Validators.required,])],
      ReminderSMSText2: [null, Validators.compose([Validators.required,])],
      ReminderSMSText3: [null, Validators.compose([Validators.required,])],
      ReminderSMSTextAdmin: [null, Validators.compose([Validators.required,])],
      isExecuteMonday: false,
      isExecuteMondayTime: [null],
      isExecuteTuesday: false,
      isExecuteTuesdayTime: [null],
      isExecuteWednesday: false,
      isExecuteWednesdayTime: [null],
      isExecuteThursday: false,
      isExecuteThursdayTime: [null],
      isExecuteFriday: false,
      isExecuteFridayTime: [null],
      isExecuteSaturday: false,
      isExecuteSaturdayTime: [null],
      isExecuteSunday: false,
      isExecuteSundayTime: [null],
      IsCreateReminder: [false],
      IsEnable: [true]
    });
    this.reminderSMSSettingForm.controls['CoworkersID'].valueChanges
      .subscribe(selectedCoWorkers => {
      });

    this.getSettingsForNotApplyWorkhourNotifications();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.coWokers = this.CoWokers;
  }

  getCoWorkers(model: RequestCoWorker) {
    model.OrderBy = "Name";
    this.IsGettingCoWokers = true;
    this.coWorkersService.Get<ResponseBase>(model)
      .subscribe((response: ResponseBase) => {
        if (response) {
          this.coWokers = Object.assign([], response.result);
          this.getNextRequest(response.pagination, model, response.result);
        }
        else {
          this.coWokers = [];
          this.IsGettingCoWokers = false;
        }
      },
        error => (err) => {
          this.coWokers = [];
          this.IsGettingCoWokers = false;
        },
        () => {

        });
  }

  getNextRequest(pagination, model, response) {
    if (pagination.index == 1) {
      this.coWokers = Object.assign([], response);
      this.IsGettingCoWokers = false;
    }
    else {
      this.coWokers.push(...response);
      this.IsGettingCoWokers = false;
    }
    model.Index = pagination.index + 1;
    model.Limit = pagination.limit;
    model.TotalRows = pagination.totalCount;
    if (pagination.totalCount > pagination.index * pagination.limit)
      this.getCoWorkers(model);
  }

  DropDownSearchCoWorker(value) {
    if (value != null && value != "") {
      var request = new RequestCoWorker();
      request.Name = value;
      this.getCoWorkers(request);
    } else {
      let coWorker = new RequestCoWorker();
      coWorker.IsDeleted = false;
      this.getCoWorkers(coWorker);
    }
  }

  onSubmit() {
    this.reminderSMSSettingForm.markAllAsTouched();
    this.isSubmitted = true;
    if (this.reminderSMSSettingForm.valid) {
      this.changeSettingsForPlanningNotifications()
    }
  }

  getSettingsForNotApplyWorkhourNotifications() {
    if (this.SendReminderSMSSetting && this.SendReminderSMSSetting != null) {
      this.ReminderSMSNotificationsObj = this.SendReminderSMSSetting;
      var ParseObj = JSON.parse(this.ReminderSMSNotificationsObj.value);
      this.reminderSMSSettingForm.patchValue(ParseObj);
      if (ParseObj.CoworkersID)
        this.reminderSMSSettingForm.controls['CoworkersID'].setValue(this.reminderSMSSettingForm.controls['CoworkersID'].value.split(','))

      this.weekDays.forEach(element => {
        switch (element.value) {
          case 'isExecuteMonday': {
            element.IsShowTime = this.reminderSMSSettingForm.value.isExecuteMonday;
            break;
          }
          case 'isExecuteTuesday': {
            element.IsShowTime = this.reminderSMSSettingForm.value.isExecuteTuesday;
            break;
          }
          case 'isExecuteWednesday': {
            element.IsShowTime = this.reminderSMSSettingForm.value.isExecuteWednesday;
            break;
          }
          case 'isExecuteThursday': {
            element.IsShowTime = this.reminderSMSSettingForm.value.isExecuteThursday;
            break;
          }
          case 'isExecuteFriday': {
            element.IsShowTime = this.reminderSMSSettingForm.value.isExecuteFriday;
            break;
          }
          case 'isExecuteSaturday': {
            element.IsShowTime = this.reminderSMSSettingForm.value.isExecuteSaturday;
            break;
          }
          case 'isExecuteSunday': {
            element.IsShowTime = this.reminderSMSSettingForm.value.isExecuteSunday;
            break;
          }
          default: {
            element.IsShowTime = false;
            break;
          }
        }
      });
      this.changeInLocalStorage(this.ReminderSMSNotificationsObj.id, this.ReminderSMSNotificationsObj)
    }
  }

  setSettingsAfterUpdate() {
    this.settingsService.GetSettingsWithTemplateValue<ResponseBase>("Notification", 'SendReminderSMS', null).subscribe({
      next: (response: ResponseBase) => {
        this.showSpinner = false;
        if (response != null && response.result && response.result.myResponse.length > 0) {
          this.SendReminderSMSSetting = response.result.myResponse[0];
          this.getSettingsForNotApplyWorkhourNotifications()
          this.changeInLocalStorage(this.ReminderSMSNotificationsObj.id, this.ReminderSMSNotificationsObj)
        }
      },
      error: err => {
      },
      complete: () => {
        this.showSpinner = false;
      }
    });
  }

  onWeekDayChange($event, days) {
    days.IsShowTime = $event.checked;
  }

  IsEnableReminderSMS(event: MatSlideToggleChange) {
    this.changeSettingsForPlanningNotifications();
  }

  changeSettingsForPlanningNotifications() {
    this.showSpinner = true;
    let data = {
      'CoworkersID': this.reminderSMSSettingForm.value.CoworkersID ? this.reminderSMSSettingForm.value.CoworkersID.toString() : '',

      'ReminderSMSAfterMin1': this.reminderSMSSettingForm.value.ReminderSMSAfterMin1,
      'ReminderSMSText1': this.reminderSMSSettingForm.value.ReminderSMSText1,

      'ReminderSMSAfterMin2': this.reminderSMSSettingForm.value.ReminderSMSAfterMin2,
      'ReminderSMSText2': this.reminderSMSSettingForm.value.ReminderSMSText2,

      'ReminderSMSAfterMin3': this.reminderSMSSettingForm.value.ReminderSMSAfterMin3,
      'ReminderSMSText3': this.reminderSMSSettingForm.value.ReminderSMSText3,

      'ReminderSMSAfterMinAdmin': this.reminderSMSSettingForm.value.ReminderSMSAfterMinAdmin,
      'ReminderSMSTextAdmin': this.reminderSMSSettingForm.value.ReminderSMSTextAdmin,

      "isExecuteMonday": this.reminderSMSSettingForm.value.isExecuteMonday,
      "isExecuteMondayTime": this.reminderSMSSettingForm.value.isExecuteMonday ? this.reminderSMSSettingForm.value.isExecuteMondayTime : null,

      "isExecuteTuesday": this.reminderSMSSettingForm.value.isExecuteTuesday,
      "isExecuteTuesdayTime": this.reminderSMSSettingForm.value.isExecuteTuesday ? this.reminderSMSSettingForm.value.isExecuteTuesdayTime : null,

      "isExecuteWednesday": this.reminderSMSSettingForm.value.isExecuteWednesday,
      "isExecuteWednesdayTime": this.reminderSMSSettingForm.value.isExecuteWednesday ? this.reminderSMSSettingForm.value.isExecuteWednesdayTime : null,

      "isExecuteThursday": this.reminderSMSSettingForm.value.isExecuteThursday,
      "isExecuteThursdayTime": this.reminderSMSSettingForm.value.isExecuteThursday ? this.reminderSMSSettingForm.value.isExecuteThursdayTime : null,

      "isExecuteFriday": this.reminderSMSSettingForm.value.isExecuteFriday,
      "isExecuteFridayTime": this.reminderSMSSettingForm.value.isExecuteFriday ? this.reminderSMSSettingForm.value.isExecuteFridayTime : null,

      "isExecuteSaturday": this.reminderSMSSettingForm.value.isExecuteSaturday,
      "isExecuteSaturdayTime": this.reminderSMSSettingForm.value.isExecuteSaturday ? this.reminderSMSSettingForm.value.isExecuteSaturdayTime : null,

      "isExecuteSunday": this.reminderSMSSettingForm.value.isExecuteSunday,
      "isExecuteSundayTime": this.reminderSMSSettingForm.value.isExecuteSunday ? this.reminderSMSSettingForm.value.isExecuteSundayTime : null,

      'IsCreateReminder': this.reminderSMSSettingForm.value.IsCreateReminder,
      'IsEnable': this.reminderSMSSettingForm.value.IsEnable

    }

    this.ReminderSMSNotificationsObj.value = JSON.stringify(data);
    this.settingsService.UpdateSettingValue<ResponseBase>(this.ReminderSMSNotificationsObj).subscribe({
      next: (response: ResponseBase) => {
        this.showSpinner = false;
        if (response != null && response.result) {
          this.ReminderSMSNotificationsObj = response.result;
          this.changeInLocalStorage(this.ReminderSMSNotificationsObj.id, this.ReminderSMSNotificationsObj)
          if (this.reminderSMSSettingForm.value.IsEnable)
            this.setSettingsAfterUpdate();
          this.translate.get("MESSAGES.Saved").subscribe({
            next: (res) => {
              this.notificationsService.create(res);
            },
            error: err => { }
          });
        }
      },
      error: err => {
      },
      complete: () => {
        this.showSpinner = false;
        this.isSubmitted = false;
      }
    });
  }

  changeInLocalStorage(ID, new_obj) {
    if (this.settingMasterValues.find(obj => obj.id == ID)) {
      this.settingMasterValues.forEach((old_obj, index) => {
        if (old_obj.id == ID) {
          this.settingMasterValues[index] = new_obj
        }
      })
    } else {
      this.settingMasterValues.push(new_obj)
    }
    localStorage.setItem("settingMasterValues", JSON.stringify(this.settingMasterValues));
  }
}