import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { AuthService } from 'app/core/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TitleService } from 'app/core/services/title.service';
import { MatDialog } from '@angular/material';
import { ProjectofferService } from './../../project-offer.service';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from "rxjs";
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';
import { CompanyInfoSetting } from 'app/main/model/CompanyInfo';
import { TextEditorComponent } from 'app/main/pages/text-editor/text-editor.component';
import { ApiConstant } from 'app/core/constant/ApiConstant';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { CreateFolder, SearchFolder, ListFolder, MoveFolder, FolderListNavigationDTO } from 'app/main/model/DropBox';import { ProjectService } from 'app/main/pages/project/project.service';
import { InquiryService } from 'app/main/pages/inquiry/inquiry.service';
import { CommonService } from 'app/core/services/common.service';
import { SMSModules, RequestCoWorker } from 'app/main/model/RequestBase';
import { SendSMSComponent } from 'app/main/shared-component/send-sms/send-sms.component';
import { SMSModuleEnum } from 'app/core/enum/sms-module.enum';
import { EmailSendingParam } from 'app/main/model/common';
import { TextEditorEmailSendComponent } from 'app/main/shared-component/text-editor-email-send/text-editor-email-send.component';
import { CustomersService } from 'app/main/pages/customer/customer.service';
import { OfferAddressModel } from 'app/main/model/ProjectOffer';
import { DropboxFilesService } from 'app/main/shared-component/dropbox-files/dropbox-files.service';
import { DriveModuleEnum } from '../../../../../core/enum/drive-module.enum';

@Component({
    selector: 'app-new-project-offer',
    templateUrl: './new-project-offer.component.html',
    styleUrls: ['./new-project-offer.component.scss']
})
export class NewProjectOfferComponent implements OnInit, OnDestroy {
    // roleName: string;
    // isValid: boolean = false;
    offerId: string = "";
    categoryAll: string = "All";
    selectedIndex: number = 0;
    @Output() OfferRoomOutput = new EventEmitter<any>();
    roomList: boolean = false;
    categoryPage: boolean = false;
    showSpinner: boolean = false;
    projectNumber: string = "";
    displayDelete: boolean = false;
    offerIdObservable = new Observable();
    offerNumber: number = 0;
    displayMtoMTabRed: boolean = false;
    displayNoteTabRed: boolean = false;
    displayOffersTabRed: boolean = false;
    MtoMDescription: string = "";
    NoteDescription: string = "";
    OffersDescription: string = "";

    //dropbox declaration start
    res: any;
    createFolder: CreateFolder;
    searchFolder: SearchFolder;
    listFolder: ListFolder
    checkedList: any;
    displayFileFromDropbox: boolean = true;
    OfferProjectLogId: string = "";
    smsObject = new SMSModules();
    Email: any;
    projectOffer: OfferAddressModel = new OfferAddressModel();
    customerDetailObj: any;
    isFile: boolean = false;
    fileFolderPathData: Array<FolderListNavigationDTO> = [];
    EmailHistoryList = [];
    displayQuestionTabRed: boolean = false;
    questionList: any;
    OfferInfoTabs: boolean = false;
    fileFolderPathDataForQualityTest: Array<FolderListNavigationDTO> = [];
    attachmentFiles: any[] = [];
    roleClaims: any;
    //@ViewChild('OfferInfoTabs', { static: false }) private OfferInfoTabs: MatTabGroup
    //dropbox declaration end

    constructor(public dialog: MatDialog,
        private authService: AuthService,
        private _route: ActivatedRoute,
        private projectofferService: ProjectofferService,
        private projectService: ProjectService,
        private inquiryService: InquiryService,
        private translationLoader: FuseTranslationLoaderService,
        private notificationsService: NotificationsService,
        private translate: TranslateService,
        private _router: Router,
        private _fuseSidebarService: FuseSidebarService,
        private titleService: TitleService,
        private commonService: CommonService,
        private customerService: CustomersService,
        private dropboxFilesService: DropboxFilesService,) {
        this.translationLoader.loadTranslations(english, danish);
        this.titleService.SetTitle("TITLE_PROJECTS_OFFER");
        this.roleClaims = this.authService.getRoleClaimsList();
    }

    ngOnInit() {
        this._fuseSidebarService.getSidebar('navbar').setFoldedAutoTrigger();
        // this.roleName = this.authService.getUserRole();
        // if (this.roleName == "Admin") {
        //     this.isValid = true;
        // }
        // else if (this.roleName == "Kontor") {
        //     this.isValid = true;
        // }
        // else if (this.roleName == "Maler") {
        //     this.isValid = false;
        // }
        // else if (this.roleName == "Gaest") {
        //     this.isValid = false;
        // }
        // else {
        //     this.isValid = true;
        // }

        this._route.params.subscribe(params => {
            var id = params['id'];
            if (id != undefined) {
                this.offerId = id;
                //this.getProjectOffer(this.offerId);
                this.displayDelete = true;
                this.getOfferstabData();
                this.getNotetabData();
                //this.getMtoMtabData();
                //this.getOfferQuestionAnswerList();
                this.OfferProjectLogId = id;
                var tabIndex = params["tabIndex"]
                this.selectedIndex = tabIndex ? tabIndex : 0;
                this._route.queryParams.subscribe(params => {
                    this.selectedIndex = params['tabIndex'] ? params['tabIndex'] : this.selectedIndex;
                    if (this.selectedIndex == 5) {
                        this.storeFileFolderPathData();
                    }
                });
            }
        });
        let coWorker = new RequestCoWorker();
        coWorker.IsDeleted = false;
        //this.getCoWorkers(coWorker);
    }

    // getProjectOffer(id) {
    //     this.showSpinner = true;
    //     this.projectofferService.GetSingle<ResponseBase>(id)
    //         .subscribe((response: ResponseBase) => {
    //             this.showSpinner = false;
    //             if (response != null) {
    //                 this.projectOffer = response.result;
    //                 this.offerNumber = response.result.number;
    //                 this.Email = response.result.email;
    //                 localStorage.setItem("OfferNumber", JSON.stringify(this.offerNumber));
    //                 this.getCustomerById(this.projectOffer.customerId);
    //                 this.storeFileFolderPathData();
    //             }
    //         },
    //             error => (err) => {
    //                 this.showSpinner = false;
    //             });
    // }

    TabChange(event) {
        this.selectedIndex = event.index;
        if (this.selectedIndex == 6) {
            this.storeFileFolderPathData();
            //if (this.projectOffer.isActive) {
            //this.storeFileFolderPathForSingleCategoryData();
            // }
        }
        else if (this.selectedIndex == 5) {
            this.getOfferQuestionAnswerList();
        }
        // else if (this.selectedIndex == 6) {
        //     this.getMtoMtabData();
        // }
        else if (this.selectedIndex == 7) {
            this.getNotetabData();
        }
        else if (this.selectedIndex == 4) 
            this.storeFileFolderPathDataForQualityTest();
        
    }

    GetProjectNumber(evnt) {
        this.projectNumber = evnt;
    }
    getOfferGroup() {
        this.selectedIndex = 3;
    }

    OfferClick(data) {
        this.offerId = data.offerId;
        if (data && data.mathod == "Add") {
            this.storeFileFolderPathData();
            this.getNotetabData();
            if (data.inquiryId) {
                this.getInquiryById(data.inquiryId, data);
                this.getDetailsFromInquiry(data.inquiryId);
            }
        }
        //this.OfferRoomOutput.emit(this.offerId);
    }
    deleteOffer() {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: '200px',
            data: { Header: 'Headers.DeleteAllOffers' }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.showSpinner = true;
                this.projectofferService.DeleteProjectOffer<ResponseBase>(this.offerId)
                    .subscribe((response: ResponseBase) => {
                        this.showSpinner = false;
                        this.translate.get("MESSAGES.Success").subscribe({
                            next: (res) => {
                                this.notificationsService.success(res);
                            },
                            error: err => {
                            }
                        });
                        this._router.navigate(['/offers']);
                    },
                        error => (err) => {
                            this.showSpinner = false;
                            this.translate.get("MESSAGES.Failed").subscribe({
                                next: (res) => {
                                    this.notificationsService.error(res);
                                },
                                error: err => {
                                }
                            });
                        },
                        () => {
                        });
            }
        });
    }

    //getMtoMtabData() {
    //    this.showSpinner = true;
    //    if (!this.projectOffer.projectId) {
    //        this.projectofferService.GetOfferProjectTabInfo<any>(this.offerId, ApiConstant.RequisitionMtoM).subscribe({
    //            next: (response) => {
    //                this.showSpinner = false;
    //                this.MtoMDescription = response.result.description;
    //                if (this.MtoMDescription != null && this.MtoMDescription.trim() != "") {
    //                    this.displayMtoMTabRed = true;
    //                }
    //                else {
    //                    this.displayMtoMTabRed = false;
    //                }
    //            },
    //            error: err => {
    //                this.translate.get("MESSAGES.Failed").subscribe({
    //                    next: (res) => {
    //                        this.notificationsService.error(res);
    //                    },
    //                    error: err => {
    //                    }
    //                });
    //            }
    //        });
    //    }
    //    else {
    //        this.projectService.GetProjectRequisition<any>(this.projectOffer.projectId, ApiConstant.RequisitionMtoM).subscribe({
    //            next: (response) => {
    //                this.showSpinner = false;
    //                this.MtoMDescription = response.result.description;
    //                if (this.MtoMDescription != null && this.MtoMDescription.trim() != "") {
    //                    this.displayMtoMTabRed = true;
    //                }
    //                else {
    //                    this.displayMtoMTabRed = false;
    //                }
    //            },
    //            error: err => {
    //                this.translate.get("MESSAGES.Failed").subscribe({
    //                    next: (res) => {
    //                        this.notificationsService.error(res);
    //                    },
    //                    error: err => {
    //                    }
    //                });
    //            }
    //        });
    //    }
    //}

    getNotetabData() {
        this.showSpinner = true;
        if (!this.projectOffer.projectId) {
            this.projectofferService.GetOfferProjectTabInfo<any>(this.offerId, ApiConstant.RequisitionNote).subscribe({
                next: (response) => {
                    this.showSpinner = false;
                    this.NoteDescription = response.result.description;
                    if (this.NoteDescription != null && this.NoteDescription.trim() != "") {
                        this.displayNoteTabRed = true;
                    }
                    else {
                        this.displayNoteTabRed = false;
                    }
                },
                error: err => {
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });
                }
            });
        }
        else {
            this.projectService.GetProjectRequisition<any>(this.projectOffer.projectId, ApiConstant.RequisitionNote).subscribe({
                next: (response) => {
                    this.showSpinner = false;
                    this.showSpinner = false;
                    this.NoteDescription = response.result.description;
                    if (this.NoteDescription != null && this.NoteDescription.trim() != "") {
                        this.displayNoteTabRed = true;
                    }
                    else {
                        this.displayNoteTabRed = false;
                    }
                },
                error: err => {
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });
                }
            });
        }
    }

    getOfferstabData() {
        this.showSpinner = true;
        if (!this.projectOffer.projectId) {
            this.projectofferService.GetOfferProjectTabInfo<any>(this.offerId, ApiConstant.RequisitionOffers).subscribe({
                next: (response) => {
                    this.showSpinner = false;
                    this.OffersDescription = response.result.description;
                    if (this.OffersDescription != null && this.OffersDescription.trim() != "") {
                        this.displayOffersTabRed = true;
                    }
                    else {
                        this.displayOffersTabRed = false;
                    }
                },
                error: err => {
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });
                }
            });
        }
        else {
            this.projectService.GetProjectRequisition<any>(this.projectOffer.projectId, ApiConstant.RequisitionOffers).subscribe({
                next: (response) => {
                    this.showSpinner = false;
                    this.OffersDescription = response.result.description;
                    if (this.OffersDescription != null && this.OffersDescription.trim() != "") {
                        this.displayOffersTabRed = true;
                    }
                    else {
                        this.displayOffersTabRed = false;
                    }
                },
                error: err => {
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });
                }
            });
        }
    }

    //openOfferProjectDescriptionForMtoM() {
    //    var selectedTabData = new CompanyInfoSetting();
    //    selectedTabData.Description = this.MtoMDescription;

    //    const dialogRef = this.dialog.open(TextEditorComponent, {
    //        height: '90%',
    //        width: '90%',
    //        data: { selectedTabData, isEmail: false },
    //        disableClose: true
    //    });


    //    dialogRef.afterClosed().subscribe(result => {
    //        this.showSpinner = true;
    //        var data = new CompanyInfoSetting();
    //        data.Description = "";
    //        if (result && result.Description) {
    //            data.Description = result.Description;
    //        }
    //        if (!this.projectOffer.projectId) {
    //            this.projectofferService.UpdateOfferProjectExtraTabDescription<ResponseBase>(this.offerId, ApiConstant.RequisitionMtoM, data)
    //                .subscribe({
    //                    next: (response: ResponseBase) => {
    //                        this.MtoMDescription = response.result.description;
    //                        this.showSpinner = false;
    //                        if (this.MtoMDescription != null && this.MtoMDescription.trim() != "") {
    //                            this.displayMtoMTabRed = true;
    //                        }
    //                        else {
    //                            this.displayMtoMTabRed = false;
    //                        }
    //                    },
    //                    error: err => {
    //                        this.showSpinner = false;
    //                        this.translate.get("MESSAGES.Failed").subscribe({
    //                            next: (res) => {
    //                                this.notificationsService.error(res);
    //                            },
    //                            error: err => {
    //                            }
    //                        });
    //                    },
    //                    complete: () => {
    //                        this.showSpinner = false;
    //                    }
    //                });
    //        }
    //        else {
    //            this.projectService.UpdateRequisitionExtraTabDescription<ResponseBase>(this.projectOffer.projectId, ApiConstant.RequisitionMtoM, data)
    //                .subscribe({
    //                    next: (response: ResponseBase) => {
    //                        this.MtoMDescription = response.result.description;
    //                        this.showSpinner = false;
    //                        if (this.MtoMDescription != null && this.MtoMDescription.trim() != "") {
    //                            this.displayMtoMTabRed = true;
    //                        }
    //                        else {
    //                            this.displayMtoMTabRed = false;
    //                        }
    //                    },
    //                    error: err => {
    //                        this.showSpinner = false;
    //                        this.translate.get("MESSAGES.Failed").subscribe({
    //                            next: (res) => {
    //                                this.notificationsService.error(res);
    //                            },
    //                            error: err => {
    //                            }
    //                        });
    //                    },
    //                    complete: () => {
    //                        this.showSpinner = false;
    //                    }
    //                });
    //        }
    //    });
    //}

    openOfferProjectDescriptionForNote() {
        var selectedTabData = new CompanyInfoSetting();
        selectedTabData.Description = this.NoteDescription;

        const dialogRef = this.dialog.open(TextEditorComponent, {
            height: '90%',
            width: '90%',
            data: { selectedTabData, isEmail: false },
            disableClose: true
        });


        dialogRef.afterClosed().subscribe(result => {
            this.showSpinner = true;
            var data = new CompanyInfoSetting();
            data.Description = "";
            if (result && result.Description) {
                data.Description = result.Description;
            }
            if (!this.projectOffer.projectId) {
                this.projectofferService.UpdateOfferProjectExtraTabDescription<ResponseBase>(this.offerId, ApiConstant.RequisitionNote, data)
                    .subscribe({
                        next: (response: ResponseBase) => {
                            this.showSpinner = false;
                            this.NoteDescription = response.result.description;
                            if (this.NoteDescription != null && this.NoteDescription.trim() != "") {
                                this.displayNoteTabRed = true;
                            }
                            else {
                                this.displayNoteTabRed = false;
                            }
                        },
                        error: err => {
                            this.showSpinner = false;
                            this.translate.get("MESSAGES.Failed").subscribe({
                                next: (res) => {
                                    this.notificationsService.error(res);
                                },
                                error: err => {
                                }
                            });
                        },
                        complete: () => {
                            this.showSpinner = false;
                        }
                    });
            }
            else {
                this.projectService.UpdateRequisitionExtraTabDescription<ResponseBase>(this.projectOffer.projectId, ApiConstant.RequisitionNote, data)
                    .subscribe({
                        next: (response: ResponseBase) => {
                            this.showSpinner = false;
                            this.NoteDescription = response.result.description;
                            if (this.NoteDescription != null && this.NoteDescription.trim() != "") {
                                this.displayNoteTabRed = true;
                            }
                            else {
                                this.displayNoteTabRed = false;
                            }
                        },
                        error: err => {
                            this.showSpinner = false;
                            this.translate.get("MESSAGES.Failed").subscribe({
                                next: (res) => {
                                    this.notificationsService.error(res);
                                },
                                error: err => {
                                }
                            });
                        },
                        complete: () => {
                            this.showSpinner = false;
                        }
                    });
            }
        });
    }

    openOfferProjectDescriptionForOffer() {
        var selectedTabData = new CompanyInfoSetting();
        selectedTabData.Description = this.OffersDescription;

        const dialogRef = this.dialog.open(TextEditorComponent, {
            height: '90%',
            width: '90%',
            data: { selectedTabData, isEmail: false },
            disableClose: true
        });


        dialogRef.afterClosed().subscribe(result => {
            this.showSpinner = true;
            var data = new CompanyInfoSetting();
            data.Description = "";
            if (result && result.Description) {
                data.Description = result.Description;
            }
            this.updateOfferDescription(data);
        });
    }

    //file tab bind with dropbox code start
    copyFolder(source, destination, isMove) {
        this.showSpinner = true;
        var movedFolder = new MoveFolder();
        movedFolder.from_path = source;
        movedFolder.to_path = destination;
        movedFolder.allow_shared_folder = true;
        movedFolder.autorename = false;
        movedFolder.allow_ownership_transfer = false;
        this.projectService.CopyFolderDrpBox<ResponseBase>(movedFolder)
            .subscribe((response: ResponseBase) => {
                if (response) {
                    this.showSpinner = false;
                    this.displayFileFromDropbox = false;
                    setTimeout(() => {
                        this.displayFileFromDropbox = true;
                    }, 2000);
                }
            },
                error => (err) => {
                    this.showSpinner = false;
                });
    }

    getInquiryById(inquiryId, data) {
        this.inquiryService.GetSingle<ResponseBase>(inquiryId).subscribe({
            next: (response: ResponseBase) => {
                var inquiryObj = response.result;
                if (inquiryObj) {
                    var rootPath = this.dropboxFilesService.getSettingMasterValueDocumentPath();
                    var paths = "";
                    if (rootPath.DocumentDriveType == DriveModuleEnum.Onedrive) {
                        paths = rootPath.Onedrive.Path;
                    } else {
                        paths = rootPath.Dropbox.Path;
                    }
                    var source = "/" + paths + "/Inquiries/" + inquiryObj.number;
                    var destination = "/" + paths + "/ProjectOffers/" + data.number;
                    this.copyFolder(source, destination, null);
                }
            },
            error: err => {
                this.showSpinner = false;
            },
            complete: () => {

            }
        });
    }

    getDetailsFromInquiry(inquiryId) {
        this.inquiryService.GetNoteDetail<any>(inquiryId).subscribe({
            next: (response) => {
                this.NoteDescription = response.result.content;
                if (response.result && response.result.content) {
                    var data = new CompanyInfoSetting();
                    data.Description = response.result.content;
                    this.updateNoteDescription(data);
                }
            },
            error: err => {
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            }
        });
    }

    updateNoteDescription(data) {
        if (data) {
            if (!this.projectOffer.projectId) {
                this.projectofferService.UpdateOfferProjectExtraTabDescription<ResponseBase>(this.offerId, ApiConstant.RequisitionNote, data)
                    .subscribe({
                        next: (response: ResponseBase) => {
                            this.showSpinner = false;
                            this.NoteDescription = response.result.description;
                            if (this.NoteDescription != null && this.NoteDescription.trim() != "") {
                                this.displayNoteTabRed = true;
                            }
                            else {
                                this.displayNoteTabRed = false;
                            }
                        },
                        error: err => {
                            this.showSpinner = false;
                            this.translate.get("MESSAGES.Failed").subscribe({
                                next: (res) => {
                                    this.notificationsService.error(res);
                                },
                                error: err => {
                                }
                            });
                        },
                        complete: () => {
                            this.showSpinner = false;
                        }
                    });
            }
            else {
                this.projectService.UpdateRequisitionExtraTabDescription<ResponseBase>(this.projectOffer.projectId, ApiConstant.RequisitionNote, data)
                    .subscribe({
                        next: (response: ResponseBase) => {
                            this.showSpinner = false;
                            this.NoteDescription = response.result.description;
                            if (this.NoteDescription != null && this.NoteDescription.trim() != "") {
                                this.displayNoteTabRed = true;
                            }
                            else {
                                this.displayNoteTabRed = false;
                            }
                        },
                        error: err => {
                            this.showSpinner = false;
                            this.translate.get("MESSAGES.Failed").subscribe({
                                next: (res) => {
                                    this.notificationsService.error(res);
                                },
                                error: err => {
                                }
                            });
                        },
                        complete: () => {
                            this.showSpinner = false;
                        }
                    });
            }
        }
    }

    updateOfferDescription(data) {
        if (data) {
            if (!this.projectOffer.projectId) {
                this.projectofferService.UpdateOfferProjectExtraTabDescription<ResponseBase>(this.offerId, ApiConstant.RequisitionOffers, data)
                    .subscribe({
                        next: (response: ResponseBase) => {
                            this.showSpinner = false;
                            this.OffersDescription = response.result.description;
                            if (this.OffersDescription != null && this.OffersDescription.trim() != "") {
                                this.displayOffersTabRed = true;
                            }
                            else {
                                this.displayOffersTabRed = false;
                            }
                        },
                        error: err => {
                            this.showSpinner = false;
                            this.translate.get("MESSAGES.Failed").subscribe({
                                next: (res) => {
                                    this.notificationsService.error(res);
                                },
                                error: err => {
                                }
                            });
                        },
                        complete: () => {
                            this.showSpinner = false;
                        }
                    });
            }
            else {
                this.projectService.UpdateRequisitionExtraTabDescription<ResponseBase>(this.projectOffer.projectId, ApiConstant.RequisitionOffers, data)
                    .subscribe({
                        next: (response: ResponseBase) => {
                            this.showSpinner = false;
                            this.OffersDescription = response.result.description;
                            if (this.OffersDescription != null && this.OffersDescription.trim() != "") {
                                this.displayOffersTabRed = true;
                            }
                            else {
                                this.displayOffersTabRed = false;
                            }
                        },
                        error: err => {
                            this.showSpinner = false;
                            this.translate.get("MESSAGES.Failed").subscribe({
                                next: (res) => {
                                    this.notificationsService.error(res);
                                },
                                error: err => {
                                }
                            });
                        },
                        complete: () => {
                            this.showSpinner = false;
                        }
                    });
            }
        }
    }

    getSMSModuleObj(event) {
        this.smsObject.name = event.name;
        this.smsObject.moduleID = this.offerId;
        this.smsObject.mobile = event.mobile ? event.mobile : event.phone;
        this.smsObject.flag = SMSModuleEnum.Offer;
        this.smsObject.countryCode = event.countryCode ? event.countryCode : "+45";
    }

    sendSMS() {
        var moduleObj = new SMSModules()
        moduleObj = this.smsObject;
        if (!this.smsObject || !this.smsObject.name) {
            this.getSMSModuleObj(this.customerDetailObj)
        }
        // moduleObj.name = '' //this.customerDetailObj.name;
        // moduleObj.moduleID = ''//this.projectOffer.id;
        // moduleObj.mobile = '' //this.projectOffer.phoneNumber;
        // moduleObj.flag = SMSModuleEnum.Offer;
        const dialogRef = this.dialog.open(SendSMSComponent, {
            height: '350px',
            width: '800px',
            data: moduleObj
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {

            }
        });
    }

    // getCustomerById(customerId) {
    //     this.customerService.GetSingle<ResponseBase>(customerId).subscribe({
    //         next: (response: ResponseBase) => {
    //             var customerObject = response.result;
    //             this.customerDetailObj = response.result;
    //         },
    //         error: err => {
    //             this.showSpinner = false;
    //         },
    //         complete: () => {

    //         }
    //     });
    // }

    //getCoWorkers(model) {
    //    model.Limit = 400;
    //    model.OrderBy = "Name";
    //    this.coWorkersService.Get<ResponseBase>(model)
    //        .subscribe((response: ResponseBase) => {
    //            if (response) {
    //                this.getNextRequestForCoWorker(response.pagination, model, response.result);
    //            }
    //            else {
    //                this.coWokers = [];
    //            }
    //        },
    //            error => (err) => {
    //                this.coWokers = [];
    //            },
    //            () => {
    //            });
    //}

    //getNextRequestForCoWorker(pagination, model, response) {
    //    if (pagination.index == 1) {
    //        this.coWokers = response;
    //        //this.AllCoworkers = response;
    //    }
    //    else {
    //        this.coWokers.push(...response);
    //        //this.AllCoworkers.push(...response);
    //    }
    //    model.Index = pagination.index + 1;
    //    model.Limit = pagination.limit;
    //    model.TotalRows = pagination.totalCount;
    //    if (pagination.totalCount > pagination.index * pagination.limit)
    //        this.getCoWorkers(model);
    //}

    emailCustomer() {
        var emailSendingParam = new EmailSendingParam();
        emailSendingParam.fromName = this.authService.getUserName();
        // emailSendingParam.from = "post@vibesmalerfirma.dk";
        emailSendingParam.to = this.Email;
        emailSendingParam.moduleName = "Offer";
        emailSendingParam.moduleID = this.offerId;
        emailSendingParam.isLoadDefaultEmailTemplate = true;
        emailSendingParam.moduleName = "Offer";
        emailSendingParam.moduleID = this.offerId;
        emailSendingParam.coworkerList = null;
        emailSendingParam.attachmentFiles = this.attachmentFiles;
        const dialogRef = this.dialog.open(TextEditorEmailSendComponent, {
            height: '90%',
            width: '90%',
            data: emailSendingParam
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result != null && result != '') {

                var tempLogStatusToSendEmail = new EmailSendingParam();
                tempLogStatusToSendEmail = result;
                // var ProjectRequisitionStatusLogModel = new ProjectRequisitionStatusLog();
                // ProjectRequisitionStatusLogModel.createdBy = this.authService.getCoWorkerId();
                // ProjectRequisitionStatusLogModel.StatusLogCaseValue = ProjectRequisitionStatusLogEnum.EmailSentFromCustomer;
                // ProjectRequisitionStatusLogModel.status = LogStatusModuleName.Customer;
                // ProjectRequisitionStatusLogModel.debitorID = this.CustomerObject.id;
                // tempLogStatusToSendEmail.ProjectRequisitionStatusLogModel = ProjectRequisitionStatusLogModel;

                this.customerService.SendEmail<ResponseBase>(tempLogStatusToSendEmail)
                    .subscribe({
                        next: (response: ResponseBase) => {
                            this.showSpinner = false;
                            this.authService.getEmailHistoryList(true)
                            this.translate.get("MESSAGES.EmailSent").subscribe({
                                next: (res) => {
                                    this.notificationsService.create(res);
                                },
                                error: err => {
                                }
                            });
                            this.commonService. checkEmailIsToBeSent();
                        },
                        error: err => {
                            this.showSpinner = false;
                            this.translate.get("MESSAGES.Failed").subscribe({
                                next: (res) => {
                                    this.notificationsService.error(res);
                                },
                                error: err => {
                                }
                            });
                        },
                        complete: () => {
                        }
                    });
            }
        });
    }

    IsFileExist(flag) {
        this.isFile = flag;
    }

    getOfferQuestionAnswerList() {
        this.showSpinner = true;
        this.projectofferService.getProjectOfferQuestionAnswer<ResponseBase>(this.offerId).subscribe((response: ResponseBase) => {
            if (response != null && response.result && response.result.questionList && response.result.questionList.length > 0) {
                this.displayQuestionTabRed = true;
                this.questionList = response.result.questionList;
                this.showSpinner = false;
            }
        },
            error => (err) => {
                this.showSpinner = false;
             }
        );
    }
    eventGotoRoom() {
        this.selectedIndex = 1;
    }

    eventDisableTabs(event) {
        this.OfferInfoTabs = event;
    }

    TabClickEvent() {
        if (!this.projectOffer.id || (this.OfferInfoTabs == true)) {
            this.translate.get("MESSAGES.DisabledTabMsg").subscribe({
                next: (res) => {
                    this.notificationsService.error(res);
                },
                error: err => {
                }
            });
        }
    }

    ngOnDestroy() {
        this._fuseSidebarService.getSidebar('navbar').unsetFoldedAutoTrigger();
    }
    eventGotoPDFEmail() {
        this.selectedIndex = 3;
    }

    ProjectOfferOutput(data){
        if(data){
            this.projectOffer = data;
            this.offerNumber = data.number;
            this.Email = data.email;
            localStorage.setItem("OfferNumber", JSON.stringify(this.offerNumber));
            //this.getCustomerById(this.projectOffer.customerId);
            this.storeFileFolderPathData();
        }
    }

    CustomerOutput(data){
        if(data)
            this.customerDetailObj = data;
    }

    storeFileFolderPathData() {
        var rootPath = this.dropboxFilesService.getSettingMasterValueDocumentPath();
        var paths = "";
        if (rootPath.DocumentDriveType == DriveModuleEnum.Onedrive) {
            paths = rootPath.Onedrive.Path;
        } else {
            paths = rootPath.Dropbox.Path;
        }
        this.fileFolderPathData = paths.split("/").map((path, i) => ({
            name: path,
            isDisplay: false
        }));
        if (this.projectOffer.projectId && this.projectOffer.projectNumber)
            this.fileFolderPathData.push(
                { name: 'Project', isDisplay: false },
                { name: (this.projectOffer.projectNumber).toString(), isDisplay: true }
            );
        else
            this.fileFolderPathData.push(
                { name: 'ProjectOffers', isDisplay: false },
                { name: (this.projectOffer.number).toString(), isDisplay: true }
            );
    }

    storeFileFolderPathDataForQualityTest() {
        var rootPath = this.dropboxFilesService.getSettingMasterValueDocumentPath();
        var paths = "";
        if (rootPath.DocumentDriveType == DriveModuleEnum.Onedrive) {
            paths = rootPath.Onedrive.Path;
        } else {
            paths = rootPath.Dropbox.Path;
        }
        this.fileFolderPathDataForQualityTest = paths.split("/").map((path, i) => ({
            name: path,
            isDisplay: false
        }));
        if (this.projectOffer.projectId && this.projectOffer.projectNumber)
            this.fileFolderPathDataForQualityTest.push(
                { name: 'Project', isDisplay: false },
                { name: (this.projectOffer.projectNumber).toString(), isDisplay: false },
                { name: 'Kvalitetstest', isDisplay: true },
            );
        else
            this.fileFolderPathDataForQualityTest.push(
                { name: 'ProjectOffers', isDisplay: false },
                { name: (this.projectOffer.number).toString(), isDisplay: false },
                { name: 'Kvalitetstest', isDisplay: true },
            );
    }

    isSendPDFFile(data) {
        if (data) {
            this.attachmentFiles = data;
            this.emailCustomer();
        }
    }
}
