export const locale = {
    lang: 'ds',
    data: {
        'Headers': {
            'NewMaterial': 'Nyt Materialer',
            'AddMaterial': 'Ønsker du at tilføje materialer?',
            //'DeleteMaterial': 'Vil du slette disse materialer?',
            'DeleteDevice': 'Vil du slette disse this enheds?',
            'Material': 'Materialer',
            'DeleteAlert': 'Er du sikker på du vil slette?',
            'AllRequisitionCoworker': 'Er du sikker på at Vil du gøre det på alle opgaver?',
            'Yes': 'JA',
            'No': 'NEJ',
            'OK': 'Gem',
            'AskForSaveProjectData': 'Vil du gemme disse oplysninger?',
            'CopyCustomerDetails': 'Skal jeg hjælpe med med at hente informationer ?',
            'ChangeStatusToAllRequisition': 'Kan du ændre startet status til alle opgaver?',
            'UnassignCustomerFromInquiry': 'Er du sikker på, at du vil fjerne tildelingen af ​​kunden fra denne forespørgsel ?',
            'MovetoFinishFolder': 'Du sikker på, at du vil flytte denne fil til mappen færdig ?',
            'DeleteProjectFileAlert': 'Er du sikker på du vil slette filen?',
            'DeleteProjectWorkHours': 'Er du sikker på du vil slette Arbejdstimer?',
            'DeleteTodaysNonWorkingHours': 'Er du sikker på, at du vil slette ikke-arbejdstid ?',
            'DeleteFutureNonWorkingHours': 'Ønsker du at slette fremtidige "ikke-arbejdstid" ?',
            'DeleteWorkHoursOnFuture': 'Slet fremtidige time på denne opgave?',
            'DeleteOffer': 'Er du sikker på, at du vil slette dette tilbud?',
            'DeleteAllOffers': 'Er du sikker på, at du vil slette tilbud?',
            'DeleteRoom': 'Er du sikker på, at du vil slette dette rum?',
            'DeleteRoomAlert': 'Tilbudet er oprettet med dette rum, er du sikker på, at du vil slette dette rum, rummedetaljer slettes på tilbud.',
            'ChangeDailySalesCustomer': 'Er du sikker på, at du vil ændre den daglige salgskunde ?',
            'AllReminderDone': 'Vil du gøre alle påmindelser "done" ?',
            'NotDoneAllReminder': 'Vil du fjerne afsluttede påmindelser ?',
            'DeleteSingleReminder': 'Vil du slette denne påmindelse ?',
            'DeleteAllReminder': 'Vil du slette al denne påmindelser ?',
            // 'DeleteAllRooms':'Er du sikker på, at du vil slette alle værelser?'
            'DeleteDiverseMaterial': 'Vil du slette dette materiale?',
            'SaveChanges': 'Vil du gemme de ændringer, du har foretaget?',
            'CopyMaterial': 'Vil du kopiere dette materiale?',
            'CreateCustomer': 'Opret en kunde først',
            'CreateProject': 'oprette et projekt først',
            'DeleteMaterial': 'Vil du slette denne vare?',
            'DeleteProject': 'Vil du slette denne Projekte?',
            'DeleteCustomer': 'Vil du slette denne Kunde?',
            'DeleteMessageForCreatedOffers': 'Du har allerede oprettet et tilbud med disse detaljer, du vil ændre disse detaljer gamle tilbud skal slettes Er du sikker på, at du vil slette det gamle tilbud?',
            'DeleteStatusMessage': 'Vil du slette denne statusmeddelelse?',
            'DeleteRequisitionMessage': 'Vil du slette denne opgave?',
            'InquiryInactiveMessage': 'Vil du gøre Forspørgelsen  inaktiv nå mail er sendt Ja / Nej?',
            'DeleteQuestion': 'Vil du slette dette spørgsmål?',
            'DeleteSupplier': 'Vil du slette denne leverandører ?',
            'DeletePriceDiscount': 'Vil du slette dette element?',
            'EmptyEconomyFields': 'Du kan ikke ændre status til "Klar fakturering" fordi økonomi felter udfyldes ikke.',
            'RoutePlanHeader': 'Du har ikke valgt en rute. Vil du vælge den ?',
            'DeleteCoworker': 'Vil du slette denne Medarbejder ?',
            'UndoChanges': 'Er du sikker på, at du vil fortryde ændringerne ?',
            'DeleteCarInfo': 'Vil du slette denne bil ?',
            'DeleteCompanyInfoTab': 'Vil du slette denne fane ?',
            'DeleteColorCode': 'Vil du slette dette farvekoder ?',
            'CopyMaterialForAllRooms': 'Ønsker du at kopiere dette materiale til alle rum?',
            'CommentReq': 'Skriv venligst en kollegakommentar',
            'CoworkerName': 'Medarbejder Navn',
            'CoworkerComment': 'Medarbejder Kommentar',
            'ReminderDateTime': 'Påmindelse DatoTid',
        },
        'BUTTONS': {
            'Manually': 'Manuelt'
        }
    }
};
