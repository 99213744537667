export const locale = {
    lang: 'en',
    data: {
        'HEADERS': {
            'QuickMaterial': 'Quick material',
            'MaterialList': 'Material List',
            'DeleteMaterial': 'Do you want to delete "@MaterialName"?',
        },
        'MESSAGES': {
            'Created': 'Created successfully',
            'Updated': 'Updated successfully',
            'Deleted': 'Deleted successfully',
            'Failed': 'Operation failed , try later',
            'NoBarcodeAttachedToMaterial': 'No barcode attached to any material.',
            'EnterTextColorCode': 'Enter Text',
            'MaterialPriseMoreOne': 'Enter material price',
            'NoMaterialFound': 'No material found',
            'AddColorCode': 'Color code needs to be filled out.',
            'MaterialAlreadyExists': 'Material already added.',
            'CanNotAddMaterial': 'Requisition status is not in Started,Performed,Ready billing, you can not add material on this requisition.',
        },
        'BUTTONS': {
            'BACK': 'Back',
            'SAVE': 'Save',
            'BtnAdd': 'Add',
            'BtnAddQuickMaterial': 'Quick Material',
            'ClearAll':'Clear all'
        },
        "Lable":{
          "Project":"Project",
          "Requisition":"Rekvisition"
        },
        'FIELDS': {
            'Status': 'Status',
            'MaterialTextColor': 'Text',
            'MaterialNo': 'Number',
            'Material': 'Material',
            'MaterialText': 'Material Text',
            'DateonCreate': 'Date',
            'Quantity': 'Quantity',
            'Unit': 'Unit',
            'RetailPrice': 'Retail Price pr. pcs',
            'TotalPrice': 'Total Price',
            'PersonalPrice': 'Personal Price',
            'PersonalTotalPrice': 'Personal Total',
            'TotalIncludedVAT': 'Offer Price incl. VAT',
            'Estimate': 'Estimate Price',
            'Price-included': 'Price incl. VAT',
            'ProfitSubProfit': 'Profit / Sub-Profit',
            'ScanBarcode': 'Scan barcode',
            'ColorCode': 'Color code',
            'PriceExclVAT': 'Price excl vat',
            'Discount': 'Discount',
            'Total': 'Total',
            'DonePercentage': 'Done %',
            'DiscountLable': 'Discount / Debtor Discount',
            'Amount': 'Amount',
            'Percentage': 'Percentage',
            'PurchasePrice': 'Purchase Price',
            'RemainsTakenFromBasements': 'Remains / taken from basements',
            'MaterialCreation': 'Material Creation',
            'All': 'All',
            "Materialtype": 'Material type',
            'Vat':'Excl. VAT',
            'DuplicateEntry':'Duplicate Entry',
            'SetReminder':'Set Reminder',
            'Price-excluded': 'Price excl. VAT',
            'Offer-Price-included': 'Offer price incl. VAT',
            'Offer-Price-excluded': 'Offer price excl. VAT',
            'Price': 'Price',
        },
        'Headers': {
            'Nomaterialadded': 'No material added',
            'DeleteMaterial': 'Do you want to delete "@MaterialName"?',
        },
        'VALIDATIONS': {
            'TextRequired': 'Text required',
            'AmountRequired': 'Amount required',
            'PersonalPriceRequired': 'Personal price required',
            'RetailPriceRequired': 'Retail price required',
            'QuantityRequired': 'Quantity required',
        },
        'TOOTIPORTITLE':{
            'EditMaterial':'Edit Material',
            'DeleteMaterial':'Delete Material',
            'RefreshMaterial':'Refresh Materials'
        },
    }
}