export const locale = {
  lang: 'ds',
  data: {
    'CUSTOMER': {
      'TITLE': 'kunder',
      'DESC': 'Hej kunder!'
    },
    'BUTTONS': {
      'NEWCUSTOMER': 'Ny kunde',
      'CreateNewCustomer': 'Opret ny kunde',
      'BACK': 'Tilbage',
      'UploadCustomerList':'Upload kundeliste',
    },
    'TITLE': {
      'CustomerPagination': 'Antal kunder pr. side',
    },
    'MESSAGES': {
      'NORECORD': 'Ingen kunde fundet'
    },
    'FILTERS': {
      'TEXTSEARCH': 'Søg efter navn, e-mail, telefon, adresse',
      'NUMBERSEARCH': 'Kunde nr. Søg',
      'CustomerNumber': 'Kunde nummer',
      'CustomerName': 'Kundenavn',
      'Email': 'E-mail',
      'PhoneNumber': 'Telefon Nummer',
      'Address': 'Adresse',
      'City': 'By',
      'ZipCode': 'Postnummer',
      'Position': 'Position',
      'Text': 'Tekst',
      'Action': 'Handling',
      'SORTING': 'Sorter efter',
      'Active': 'Inkluder alle inaktive',
      'BADGE': 'Inaktive',
      'Deleted': 'Vis slettet'
    },
    'SORTBY':
      [
        //{
        //  Text: '     ',
        //  Value: 'ID DESC'
        //},
        {
          Text: 'Navn (A-Z)',
          Value: 'Text'
        },
        {
          Text: 'Navn (Z-A)',
          Value: 'Text DESC'
        },
        {
          Text: 'Nummer (0-9)',
          Value: 'Number'
        },
        {
          Text: 'Nummer (9-0)',
          Value: 'Number DESC'
        }
      ]
  }
};
