import { Component, ElementRef, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { TranslateService } from '@ngx-translate/core';
import { OfferMaterialCalculation } from 'app/main/model/Material';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { RequestCalculatedOfferMaterialList } from 'app/main/model/RequestBase';
import { MaterialService } from 'app/main/pages/material/material.service';
import { CommonService } from 'app/core/services/common.service';
import { PdfViewerModelComponent } from 'app/main/pages/pdf-viewer/pdf-viewer.component';
import { GoogleMapDirectionComponent } from 'app/main/pages/google-map-direction/google-map-direction.component';
import { OfferAddressModel } from 'app/main/model/ProjectOffer';
import { CompanyInfoService } from 'app/main/pages/companyInfo/companyInfo.service';
import { NotificationsService } from 'angular2-notifications';
import { CompanyInfo } from 'app/main/model/CompanyInfo';
import { OfferTypeWithRoomPriceDetail } from 'app/main/model/OfferV2';
import { ProjectofferService } from '../../project-offer.service';
@Component({
  selector: 'offer-calculated-material-list',
  templateUrl: './calculated-material-list.component.html',
  styleUrls: ['./calculated-material-list.component.scss']
})
export class CalculatedMaterialListComponent implements OnInit {
  showSpinner: boolean = false;
  materialList: any[] = [];
  requestCalculatedOfferMaterialList = new RequestCalculatedOfferMaterialList();
  materialTotalPrice: number = 0;
  materialPersonalTotalPrice: number = 0;
  materialTotalPriceWithVAT: number = 0;
  purchasePriceTotal: number = 0;
  timerTotal: number = 0;
  minitesToHour: any
  objAddProjectMaterial: OfferMaterialCalculation;
  showPersonalField: boolean = false;
  OfferNumber: number;
  isShowDriving: boolean = false;
  projectOffer: OfferAddressModel = new OfferAddressModel();
  companyInfoData: CompanyInfo;
  isEditDrivingDays: boolean = false;
  OfferTypeDetail: OfferTypeWithRoomPriceDetail = new OfferTypeWithRoomPriceDetail();
  DistancePerDayValue: number;
  meterAndKmText: string = "km";
  projectsCoworkerPrice: number = 0;
  settingMasterValues:any[] = [];
  address:any = {};
  grandTotal: number = 0;

  constructor(private translationLoader: FuseTranslationLoaderService,
    private materialService: MaterialService,
    private translate: TranslateService,
    public dialogRef: MatDialogRef<CalculatedMaterialListComponent>,
    private commonService: CommonService,
    public dialog: MatDialog,
    private companyInfoService: CompanyInfoService,
    private notificationsService: NotificationsService,
    private projectofferService: ProjectofferService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.translationLoader.loadTranslations(english, danish);
  }

  ngOnInit() {
    this.requestCalculatedOfferMaterialList = this.data && this.data.requestCalculatedOfferMaterialList ? this.data.requestCalculatedOfferMaterialList : null;
    this.address = this.data && this.data.address ? this.data.address : null;
    this.getMaterialList();
    this.settingMasterValues = Object.assign([], JSON.parse(localStorage.getItem("settingMasterValues")));
    var tempSettingObject = this.settingMasterValues.find(s => s.key1 == "Project" && s.key2 == "ProjectsCoworkerPrice").value;
    if(tempSettingObject)
        this.projectsCoworkerPrice = parseFloat(tempSettingObject);  
    else
        this.projectsCoworkerPrice = 580;
    this.GetCompanyInformation();
    this.getTravelingDistancePerDayValue();
    this.showDirection();
  }

  getMaterialList() {
    this.showSpinner = true;
    this.materialService.GetCalculatedOfferMaterialList<ResponseBase>(this.requestCalculatedOfferMaterialList)
      .subscribe({
        next: (response: ResponseBase) => {
          this.showSpinner = false;
          this.materialList = response.result;
          this.calculateProjMaterialPriceTotal();
        },
        error: err => { },
        complete: () => {
          this.showSpinner = false;
        }
      });
  }
  getTotalPrice(qty, price) {
    var isNegative = false;
    if (qty < 0)
      isNegative = true;
    if (price < 0)
      isNegative = true;
    var total = (qty * price);
    if (isNegative)
      total = -Math.abs(total)
    return total;
  }
  calculateProjMaterialPriceTotal() {
    this.materialTotalPriceWithVAT = 0;
    this.materialPersonalTotalPrice = 0;
    this.materialTotalPrice = 0;
    this.purchasePriceTotal = 0;
    this.timerTotal = 0;
    this.materialList.forEach((e: any) => {
      if (!e.totalPrice) {
        e.totalPrice = this.getTotalPrice((e.material.purchaseQuantity ? e.material.purchaseQuantity : 0), e.material.retailPrice);
      }
      if (!e.personalPriceTotal) {
        e.personalPriceTotal = this.getTotalPrice((e.material.purchaseQuantity ? e.material.purchaseQuantity : 0), e.material.personalPrice);
      }
      if (e.timer)
        this.timerTotal += this.getTotalPrice((e.offerQty ? e.offerQty : 0), e.timer);
      this.materialTotalPrice += e.totalPrice;
      this.minitesToHour = this.convertMinToHour(this.timerTotal)
      if (e.material.personalPrice && e.material.personalPrice != 0) {
        this.materialPersonalTotalPrice += e.personalPriceTotal;
      }
      else {
        e.personalPrice = 0;
      }

      if (e.totalPriceVAT) {
        this.materialTotalPriceWithVAT += e.totalPriceVAT;
      } else {
        var vat = (e.totalPrice * 25) / 100;
        this.materialTotalPriceWithVAT += (e.totalPrice + vat);
      }
      if (e.material.purchasePrice) {
        this.purchasePriceTotal += this.getTotalPrice((e.material.purchaseQuantity ? e.material.purchaseQuantity : 0), e.material.purchasePrice);
      }
    });
  }

  showField(completed: boolean) {
    this.showPersonalField = completed;
  }

  ngOnBackClick() {
    this.dialogRef.close(null);
  }

  convertMinToHour(event) {
    const num = event;
    const hours = num / 60;
    const rhours = Math.round(hours);
    const minutesLeft = (hours - rhours) * 60;
    const rminutes = Math.round(minutesLeft);
    if (num < 60) {
      return `${num}Min`;
    } else {
      return `${rhours}H ${rminutes}Min`;
    }
  }

  getFailedTone() {
    this.commonService.playFailedTone();
  }
  getSuccessTone() {
    this.commonService.playSuccessTone();
  }

  viewPDF() {
    this.showSpinner = true;
    var calculatedOfferMaterialList = {
      "OfferID": this.requestCalculatedOfferMaterialList.offerID,
      "OfferTypeIDS": this.requestCalculatedOfferMaterialList.offerTypeIDS,
      "IsShowPersonalPrice": this.showPersonalField
    };

    this.materialService.GetCalculatedOfferMaterialListPDF(calculatedOfferMaterialList).subscribe((response: ResponseBase) => {
      var data = response.result.base64.replace("data:application/pdf;base64,", "");
      this.OfferNumber = JSON.parse(localStorage.getItem("OfferNumber"));
      const dialogRef = this.dialog.open(PdfViewerModelComponent, {
        height: '90%',
        width: '90%',
        data: {
          base64: data,
          filename: response.result.filename ? response.result.filename + ".pdf" : "Tilbud_" + this.requestCalculatedOfferMaterialList.offerID + "_" + this.requestCalculatedOfferMaterialList.offerTypeIDS + ".pdf"
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {

        }
      });
    },
      error => (err) => {
      },
      () => {
        this.showSpinner = false;
      });
  }

  GetCompanyInformation() {
    this.companyInfoService.GetCompanyInformationData<ResponseBase>()
      .subscribe((response: ResponseBase) => {
        if (response) {
          this.companyInfoData = response.result;
        }
      },
        (err: any) => {
          this.translate.get("MESSAGES.Failed").subscribe({
            next: (res) => {
              this.notificationsService.error(res);
            }, error: err => { }
          });
        },
        () => {
        });
  }

  
  calculateTotalTime(days) {
    this.OfferTypeDetail.TotalTime = 0;
    if (this.OfferTypeDetail.time) {
      var time = this.OfferTypeDetail.time;
      this.OfferTypeDetail.TotalTime = ((time * 2) * Math.ceil(days ? days : this.OfferTypeDetail.numberOfDays));
    }
    return this.OfferTypeDetail.TotalTime;
  }

  calculateDistanceAmt() {
    var amount = 0;
    amount = this.calculateTotalTime(this.OfferTypeDetail.numberOfDays);
    amount = amount * this.DistancePerDayValue;
    this.OfferTypeDetail.travelingAmount = amount;
    this.OfferTypeDetail.price = ((this.OfferTypeDetail.priceTotal + this.OfferTypeDetail.travelingAmount) - this.OfferTypeDetail.discount);
    this.OfferTypeDetail.vat = ((this.OfferTypeDetail.price) * 25 / 100);
    this.OfferTypeDetail.totalInclVAT = (this.OfferTypeDetail.price + (this.OfferTypeDetail.price * 25 / 100));
    this.OfferTypeDetail.numberOfHours = this.address.NumberOfHours;
    this.OfferTypeDetail.numberOfDays = Math.ceil(Math.ceil((this.OfferTypeDetail.numberOfHours / 7.5) / 0.25) * 0.25);
    this.calTravelingAmtOnDrivingDaysChange();
  }

  calTravelingAmtOnDrivingDaysChange() {
    var amount = 0;
    amount = this.calculateTotalTime(this.OfferTypeDetail.numberOfDrivingDays == this.OfferTypeDetail.numberOfDays ? this.OfferTypeDetail.numberOfDrivingDays : this.OfferTypeDetail.numberOfDays);
    amount = amount * this.DistancePerDayValue;
    this.OfferTypeDetail.travelingAmount = amount;
    this.OfferTypeDetail.price = ((this.OfferTypeDetail.priceTotal + this.OfferTypeDetail.travelingAmount) - this.OfferTypeDetail.discount);
    this.OfferTypeDetail.vat = ((this.OfferTypeDetail.price) * 25 / 100);
    this.OfferTypeDetail.totalInclVAT = (this.OfferTypeDetail.price + (this.OfferTypeDetail.price * 25 / 100));
    this.OfferTypeDetail.numberOfHours = this.address.NumberOfHours;
    this.OfferTypeDetail.numberOfDays = Math.ceil(Math.ceil((this.OfferTypeDetail.numberOfHours / 7.5) / 0.25) * 0.25);
  }

  getTravelingDistancePerDayValue() {
    this.projectofferService.GetDistancePerMinuteValue().subscribe((response: ResponseBase) => {
        if (response) {
            this.DistancePerDayValue = parseFloat(response.result.myResponse[0].value);
        }
    });
}
  showDirection() {
    this.isShowDriving = true;
    setTimeout(() => {
          this.OfferTypeDetail.numberOfDrivingDays = this.OfferTypeDetail.numberOfDays;
          this.OfferTypeDetail.distance = this.data.showDriving.distance;
          this.OfferTypeDetail.time = this.data.showDriving.time;
        }, 1000);
        this.calculateDistanceAmt();

    //     if (result.travelingDetails.distance.toString().split(" ")[1])
    //       this.meterAndKmText = result.travelingDetails.distance.toString().split(" ")[1];
    // const dialogRef = this.dialog.open(GoogleMapDirectionComponent, {
    //   width: '80%',
    //   height: '80%',
    //   disableClose: true,
    //   data: {
    //     Address: this.address ? this.address.Address :null,
    //     ZipCode: this.address ? this.address.ZipCode:null,
    //     City:this.address ? this.address.City:null,
    //     SourceAdd: this.companyInfoData,
    //     autoSearchOnOpen: true,
    //   }
    // });
    // dialogRef.afterClosed().subscribe(result => {
    //   if (result) {
    //     this.OfferTypeDetail.distance = result.travelingDetails.distance;
    //     this.OfferTypeDetail.time = result.travelingDetails.duration;
    //     if (result.travelingDetails.distance.toString().split(" ")[1])
    //       this.meterAndKmText = result.travelingDetails.distance.toString().split(" ")[1];
    //     this.calculateDistanceAmt();
    //     setTimeout(() => {
    //       this.OfferTypeDetail.numberOfDrivingDays = this.OfferTypeDetail.numberOfDays;
    //     }, 500);
    //   }
    // });
  }

}
