import { Component, OnInit, Optional } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { MaterialService } from '../../material.service';
import { RequestOrderMaterial, SupplierRequest } from 'app/main/model/RequestBase';
import { ResponseBase } from 'app/main/model/ResponseBase';
import * as moment from 'moment';
import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { environment } from 'environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { MatDialog, MatDialogRef } from '@angular/material';
import { EditMaterialOrderComponent } from '../edit-material-order/edit-material-order.component';
import { Supplier } from "app/main/model/Supplier";
import { SupplierOrderList } from "app/constant/common.constant";

@Component({
    selector: "material-order",
    templateUrl: "./material-order.component.html",
    styleUrls: ["./material-order.component.scss"]
})

export class MaterialOrderComponent implements OnInit {
    showSpinner: boolean = false;
    searchValue: string = "";
    searchSupplierID: string = "0";
    //searchStartDate: Date = new Date(new Date().getFullYear() + "-" + new Date().getMonth() + "-01");
    searchStartDate: Date = new Date(moment().startOf('month').format('YYYY-MM-DD'));
    searchEndDate: Date = new Date(moment(new Date()).format("YYYY-MM-DD"));
    searchSupplierStatusID: number = 0;
    supplierList: Supplier[] = [];
    materialOrderList: any = [];
    supplierStatusEnum = SupplierOrderList;
    pagination: any;
    displayPagination: boolean;
    requestBase: RequestOrderMaterial;
    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private materialService: MaterialService,
        private translate: TranslateService,
        private notificationsService: NotificationsService,
        public dialog: MatDialog,
        @Optional() public dialogRef: MatDialogRef<EditMaterialOrderComponent>,
    ) {
        this.translationLoaderService.loadTranslations(danish, english);
    }

    ngOnInit() {
        this.requestBase = new RequestOrderMaterial();
        this.requestBase.Index = 1;
        this.requestBase.Limit = 100;
        this.requestBase.IsActive = true;
        this.requestBase.IsDeleted = false;
        this.requestBase.Search = this.searchValue;
        this.getMaterialOrderList();
        this.getSupplierList();
    }

    getMaterialOrderList() {
        this.showSpinner = true;        
        if (this.searchSupplierID != '0') {
            this.requestBase.SupplierID = this.searchSupplierID;
        }
        if (this.searchSupplierStatusID != 0) {
            this.requestBase.StatusID = this.searchSupplierStatusID;
        }
        this.requestBase.StartDate = moment(this.searchStartDate).format("YYYY-MM-DD");
        this.requestBase.EndDate = moment(this.searchEndDate).format("YYYY-MM-DD");
        this.materialService.GetMaterialOrderList(this.requestBase).subscribe({
            next: (response: ResponseBase) => {
                if (response) {
                    this.showSpinner = false;
                    this.materialOrderList = response.result;
                    this.pagination = response.pagination;
                    this.displayPagination = true;
                    this.materialOrderList.forEach(i => {
                        i.orderDate = moment(i.orderDate).format("YYYY-MM-DD");
                        if (i.attachmentURL) {
                            i.attachmentURLList = Object.assign({}, JSON.parse(i.attachmentURL));
                        }
                    });
                } else {
                    this.showSpinner = false;
                    this.materialOrderList = [];
                }
            },
            error: err => {
                this.showSpinner = false;
                this.displayPagination = false;
                this.materialOrderList = [];
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    getSupplierList() {
        var supplierReq = new SupplierRequest();
        supplierReq.Index = 1;
        supplierReq.Limit = 100;
        supplierReq.OrderBy = "Text";
        this.materialService.GetSupplierList<ResponseBase>(supplierReq)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.supplierList = response.result;
                },
                error: err => { },
                complete: () => { }
            });
    }

    viewEmailTemplate(value) {
        var url = environment.url + value.filePath;
        window.open(url, "_blank");
    }

    viewAttachmentURL(attachmentURL) {
        if (attachmentURL) {
            var url = environment.url + attachmentURL;
            window.open(url, "_blank");
        }
    }

    getAttachmentIcon(value) {
        if (value) {
            return 'description';
        }
    }

    edit(orderValue) {
        const dialogRef = this.dialog.open(EditMaterialOrderComponent, {
            height: "95%",
            width: "95%",
            data: orderValue
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.getMaterialOrderList();
            }
        });
    }


    receivePaginatonEvent(event) {
        this.requestBase.Index = event.pageIndex;
        this.requestBase.Limit = event.pageSize;
        this.getMaterialOrderList();
    }

}