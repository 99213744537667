import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { MatDialog, MatOption } from '@angular/material';
import { AuthService } from 'app/core/services/auth.service';
import { SettingsService } from '../../settings.service';
import { ResponseBase } from "app/main/model/ResponseBase";
import { ProjectService } from 'app/main/pages/project/project.service';
import * as moment from 'moment';
import { WorkingHourDetailsSettingRequest } from "app/main/model/Project";
import { RequestProjectWokringHoursDetail } from "app/main/model/RequestBase";
import { NumberOfEmployee } from 'app/main/model/CoWorker';
import { ProjectStatus, ProjectStatusForMaler, PurchaseStatus } from "app/constant/common.constant";
import { ProjectHoursDetails } from "app/main/pages/project/component/project-hours-details/project-hours-details";
import { StatusMessageComponent } from "app/main/pages/project/component/statusMessage/statusMessage.component";
import { ProjectPriceDiscountComponent } from "app/main/pages/project/component/projectPriceDiscount/project-price-discount.component";
import { ShowInactiveProjectComponent } from "app/main/pages/project/component/show-inactive-project/show-inactive-project.component";
import { ProjectIndoorOutDoorStatus } from "app/main/model/ProjectStatus";
import { CoWorkersService } from "app/main/pages/coWorker/coWorkers.service";

@Component({
    selector: "vibe-project-working-hours-detail",
    templateUrl: "./project-working-hours-detail.component.html",
    styleUrls: ["./project-working-hours-detail.component.scss"]
})

export class ProjectWorkingHoursDetailComponent implements OnInit {
    showSpinner: boolean = false;
    @Input() projectSettings: any;
    roleName: string;
    workingHourDetails = new WorkingHourDetailsSettingRequest();

    purchaseStatus = PurchaseStatus;
    projectPurchaseStatus: number = 3;
    currentselectedStatusObj: number[];
    currentselectedIndoorOutdoorObj: number[];
    projectStatus = ProjectStatus;
    projectStatusForMaler = ProjectStatusForMaler;
    selectIndoorOutDoorStatus = ProjectIndoorOutDoorStatus;
    //autoCalculation:boolean = true;
    requestProject = new RequestProjectWokringHoursDetail();
    @ViewChild('allIndoorOutdoorStatusSelected', { static: false }) private allIndoorOutdoorStatusSelected: MatOption;
    @ViewChild('allStatusSelected', { static: false }) private allStatusSelected: MatOption;
    numberOfEmployee: NumberOfEmployee[] = [];
    workingHourDetialSettings: any;
    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private authService: AuthService,
        private coWorkersService: CoWorkersService,
        private projectservice: ProjectService,
        private settingsService: SettingsService,
        private dialog: MatDialog) {
        this.translationLoaderService.loadTranslations(danish, english);
        this.numberOfEmployee = this.coWorkersService.generateEmployeeArray(25);
    }

    ngOnInit() {
        this.roleName = this.authService.getUserRole();
        if (this.projectSettings && this.projectSettings.length > 0) {
            var tempSettingObject = this.projectSettings.find(s => s.key1 == "Project" && s.key2 == "WorkingHourDetialSettings");
            if (tempSettingObject) {
                this.workingHourDetialSettings = tempSettingObject;
                this.setWorkingHoursDetails();
            }
        }
        else
            this.getWorkingHoursDetailsSetting();
    }

    getWorkingHoursDetailsSetting() {
        this.showSpinner = true;
        this.settingsService.GetSettingsValue<ResponseBase>("Project", "WorkingHourDetialSettings", null).subscribe({
            next: (response: ResponseBase) => {
                if (response != null && response.result && response.result.myResponse.length > 0) {
                    if (response.result.myResponse[0].value && response.result.myResponse.length > 0) {
                        this.workingHourDetialSettings = response.result.myResponse[0];
                        this.setWorkingHoursDetails();
                    }
                    else
                        this.workingHourDetialSettings = {};
                    this.showSpinner = false;
                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    changeWorkingHoursDetailsSetting() {
        //this.showSpinner = true;
        this.workingHourDetialSettings.value = JSON.stringify(this.requestProject);
        this.settingsService.UpdateSettingValue<ResponseBase>(this.workingHourDetialSettings).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                // if (response != null && response.result) {
                // }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    getWorkingHourDetails(IsChangeWorkingHoursCall: boolean = true) {
        this.projectservice.setRequestParameterForWorkingHoursDetail(this.requestProject);
        this.projectservice.GetProjectsHoursDetails<ResponseBase>(this.requestProject.noOfEmployee,
            this.requestProject.multipleStatus, this.requestProject.projectPurchaseStatusValue,
            this.requestProject.averagePrice, this.requestProject.isIndoorOutdoorTypeProjects, this.requestProject.autoCalculation)
            .subscribe((response: ResponseBase) => {
                if (response) {
                    var worhouDet = response.result;
                    this.workingHourDetails.totalProject = worhouDet.totalProject;
                    this.workingHourDetails.totalProjectMaterialPrice = worhouDet.totalProjectMaterialPrice;
                    this.workingHourDetails.materialPurchasePriseTotal = worhouDet.materialPurchasePriseTotal;
                    this.workingHourDetails.totalProjectRemainingHours = worhouDet.totalProjectRemainingHours;
                    this.workingHourDetails.averagePrice = worhouDet.averagePrice;
                    this.workingHourDetails.totalRemainigPrice = worhouDet.totalRemainigPrice;
                    this.workingHourDetails.totalProjectDaysLeft = worhouDet.totalProjectDaysLeft;
                    this.workingHourDetails.noOfEmployee = worhouDet.noOfEmployee;
                    this.requestProject.noOfEmployee = worhouDet.noOfEmployee;
                    this.workingHourDetails.endDate = moment(worhouDet.projectEndDate).format("DD.MM.YYYY");
                    if (IsChangeWorkingHoursCall)
                        this.changeWorkingHoursDetailsSetting();
                    this.showSpinner = false;
                }
            },
                error => (err) => {
                },
                () => {
                    this.showSpinner = false;
                });

    }

    statusMessagePopup() {
        const dialogRef = this.dialog.open(StatusMessageComponent, {
            height: '90%',
            width: '90%',
            disableClose: true,
            data: null
        });
        dialogRef.afterClosed().subscribe(result => { });
    }

    onSelectPurchaseStatus() {
        this.getWorkingHourDetails();
    }

    priceDiscountPopup() {
        const dialogRef = this.dialog.open(ProjectPriceDiscountComponent, {
            height: '90%',
            width: '80%',
            disableClose: false,
            data: null
        });
        dialogRef.afterClosed().subscribe(result => { });
    }

    ShowInactiveProjectList() {
        const dialogRef = this.dialog.open(ShowInactiveProjectComponent, {
            height: "90%",
            width: "90%",
        });
        dialogRef.afterClosed().subscribe(result => {
        });
    }

    ShowProjectHoursDetailsList() {
        const dialogRef = this.dialog.open(ProjectHoursDetails, {
            height: "90%",
            width: "90%",
            data: {
                status: this.currentselectedStatusObj.join().toString(),
                indoorOutdoorStatus: this.currentselectedIndoorOutdoorObj.join().toString(),
                isIncludeTurnover:false
            }
        });
        dialogRef.afterClosed().subscribe(result => {
        });
    }

    DropDownNoOfEmployeeSelect() {
        this.showSpinner = true;
        this.getWorkingHourDetails();
    }

    onChangeSearchFilter() {
        setTimeout(() => {
            var selectedPersonalAndBuitik = false;
            if (this.currentselectedIndoorOutdoorObj == undefined) {
                this.currentselectedIndoorOutdoorObj = [];
            }

            var IsSelectedAllStatus = this.currentselectedIndoorOutdoorObj.find(o => o == 1 || o == 2 || o == 3);
            if (IsSelectedAllStatus) {
                this.currentselectedStatusObj = [];
                ("1,3,5,6,8,11").split(",").filter(x => {
                    this.currentselectedStatusObj.push(Number(x));
                })
                this.requestProject.multipleStatus = this.currentselectedStatusObj.join().toString(); //"8,9";
            }
            else {
                this.currentselectedStatusObj = [];
                this.currentselectedStatusObj.push(0);
                this.projectStatus.filter(x => {
                    this.currentselectedStatusObj.push(Number(x.id));
                })
                this.requestProject.multipleStatus = this.currentselectedStatusObj.join().toString(); //"8,9";

            }
            this.requestProject.isIndoorOutdoorTypeProjects = this.currentselectedIndoorOutdoorObj.join().toString();
            this.showSpinner = true;
            this.getWorkingHourDetails();
        }, 1000)
    }

    toggleAllStatusIndoorOutDoorSelection() {
        this.currentselectedIndoorOutdoorObj = [];
        this.requestProject.isIndoorOutdoorTypeProjects = "";
        if (this.allIndoorOutdoorStatusSelected.selected) {
            for (var i = 0; i < 7; i++) {
                this.currentselectedIndoorOutdoorObj.push(i);
            }
            this.requestProject.isIndoorOutdoorTypeProjects = this.currentselectedIndoorOutdoorObj.join().toString();
        }
        this.showSpinner = true;
        this.getWorkingHourDetails();
    }

    toggleAllStatusSelection() {
        this.currentselectedStatusObj = [];
        this.requestProject.multipleStatus = "";
        if (this.allStatusSelected.selected) {
            // if (this.roleName == "Maler") {
            //     for (var i = 0; i < 13; i++) {
            //         if (i != 9 && i != 12) {
            //             this.currentselectedStatusObj.push(i);
            //         }
            //     }
            // }
            // else {
                for (var i = 0; i < 13; i++) {
                    this.currentselectedStatusObj.push(i);
                }
            //}
            this.requestProject.multipleStatus = this.currentselectedStatusObj.join().toString();
        }
        this.showSpinner = true;
        this.getWorkingHourDetails();
    }

    DropDownStatusChange(value) {
        var offerStatusSelected = this.currentselectedStatusObj.find(o => o == 4);
        if (offerStatusSelected) {
            this.requestProject.IsActive = false;
        }
        if (value.id == 4 && !offerStatusSelected) {
            this.requestProject.IsActive = true;
        }
        if(this.currentselectedStatusObj)
            this.requestProject.multipleStatus = this.currentselectedStatusObj.join().toString(),
        this.showSpinner = true;
        this.getWorkingHourDetails();
    }

    setWorkingHoursDetails() {
        this.requestProject = JSON.parse(this.workingHourDetialSettings.value) //this.projectservice.getRequestParameterForWorkingHoursDetail();
        this.currentselectedStatusObj = [];
        if (this.requestProject.multipleStatus) {
            var statusObj = this.requestProject.multipleStatus.split(",");
            for (var i = 0; i < statusObj.length; i++) {
                this.currentselectedStatusObj.push(Number(statusObj[i]));
            }
        }

        this.currentselectedIndoorOutdoorObj = [];
        if (this.requestProject.isIndoorOutdoorTypeProjects) {
            var obj = this.requestProject.isIndoorOutdoorTypeProjects.split(",");
            for (var i = 0; i < obj.length; i++) {
                this.currentselectedIndoorOutdoorObj.push(Number(obj[i]));
            }
        }

        //if (this.roleName == "Admin")
         this.getWorkingHourDetails(false);
    }
}