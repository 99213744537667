import {
    A,
    Z,
  } from '@angular/cdk/keycodes';
  import {
    Directive,
    ElementRef,
    forwardRef,
    HostListener,
    OnInit,
    Renderer2,
    Self,
  } from '@angular/core';
  import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
  
  @Directive({
    selector: '[appTitlecase]',
    providers: [
      {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => CustomeTitleCaseDirective),
        multi: true,
      },
    ],
  })
  export class CustomeTitleCaseDirective implements ControlValueAccessor {
    /** implements ControlValueAccessorInterface */
    _onChange: (_: any) => void;
  
    /** implements ControlValueAccessorInterface */
    _touched: () => void;
  
    constructor( @Self() private _el: ElementRef, private _renderer: Renderer2) { }
  
    /** Trata as teclas */
    @HostListener('keyup', ['$event'])
    onKeyDown(evt: KeyboardEvent) {
      const keyCode = evt.keyCode;
        const key = evt.key;

    //    //   if (keyCode >= A && keyCode <= Z) {
    //    const value = this._el.nativeElement.value.replace(/\b\w/g, first => first.toLocaleUpperCase());
    //    this._renderer.setProperty(this._el.nativeElement, 'value', value);
    //    this._onChange(value);
    //    evt.preventDefault();
    ////   }

        //if (keyCode >= A && keyCode <= Z) {
        if (key !== " ") {
            const wordSplit = this._el.nativeElement.value.split(' ');
            for (let i = 0; i < wordSplit.length; i++) {
                wordSplit[i] = wordSplit[i].charAt(0).toLocaleUpperCase() + wordSplit[i].substr(1);
            }
            const wordJoin = wordSplit.join(' ');
            //const value = this._el.nativeElement.value.replace(wordJoin);
            this._renderer.setProperty(this._el.nativeElement, 'value', wordJoin);
            this._onChange(wordJoin);
        evt.preventDefault();
    }
    }
  
    @HostListener('blur', ['$event'])
    onBlur() {
      this._touched();
    }
  
    /** Implementation for ControlValueAccessor interface */
    writeValue(value: any): void {
      this._renderer.setProperty(this._el.nativeElement, 'value', value);
    }
  
    /** Implementation for ControlValueAccessor interface */
    registerOnChange(fn: (_: any) => void): void {
      this._onChange = fn;
    }
  
    /** Implementation for ControlValueAccessor interface */
    registerOnTouched(fn: () => void): void {
      this._touched = fn;
    }
  
    /** Implementation for ControlValueAccessor interface */
    setDisabledState(isDisabled: boolean): void {
      this._renderer.setProperty(this._el.nativeElement, 'disabled', isDisabled);
    }
  }
  