export const locale = {
    lang: 'ds',
    data: {
        'FIELDS': {
            'Year': 'År',
        },
        'FILTERS': {
            'SORTING_STATUS': 'Status',
            'All': 'Alle'
        },
        'MESSAGES': {
            'NORECORD': 'Intet optage fundet'
        },
        'BUTTONS':{
            'ShowInactive': 'At vise Inaktive',
        },
        'TOOTIPORTITLE':{
            'Search':'Søg'
        }
    }
};
