export const locale = {
    lang: 'en',
    data: {
        'TITLE': {
            'Header': 'Text In Case Of Lack Of Planning',
            'SMSText':'Please do not remove @UnPlannedCoworkerList from SMS text.',
            'NotificationText':'Please do not remove @UnPlannedCoworkerList from Notification text',
        },
        'FIELDS': {
            'HoursToSend': 'Hours to send',
            'DaysToSend':  'Send x days before',
            'SMSText': 'SMS text',
            'NotificationText': 'Notification text',
            'EmailText': 'Email text',
            'IsCreateReminder':'Is Create Reminder?',
            'EmailTemplate':'Email Template',
            'PlanningNoteLabel':'Please do not remove @UnPlannedCoworkerList,@count,@currentDate and @nextDate from SMS,Notification and Email template text.'

         },
        'VALIDATIONS': {
            'HoursToSend': 'Hours to send is required',
            'DaysToSend': 'Days to send is required',
            'SMSText': 'SMS text is required',
            'NotificationText': 'Notification text is required',
            'EmailText': 'Email text is required',
        },
        'MESSAGES': {
            'Saved': 'Saved successfully.',
        },
        'BUTTONS': {
            'Save': 'Save',
        }
    }
};