export const locale = {
    lang: 'en',
    data: {
        'FIELDS': {
            'SrNo': 'Sr no.',
            'Date': 'Date',
            'SupplierName': 'Supplier name',
            'OrderMaterial': 'QTY',
            'OrderAmount': 'Amt',
            'ReceivedMaterila': 'Received Materila',
            'ReceivedAmount': 'Received Amt',
            'Status': 'Status',
            'Email': 'E-mail',
            'Attachment': 'Attachment',
            'Coworker': 'Last updated by',
            'ReceivedDate': 'Received Date',
            'TextSearch': 'Search',
            'SelectSupplier': 'Select Supplier',
            'StartDate': 'Start Date',
            'EndDate': 'End Date',
            'SearchAllOptions':'All',

            'Material': 'Material',
            'RetailPrice': 'Retail Price',
            'PersonalPrice': 'Personal Price',
            'PersonalTotalPrice': 'Personal Total',
            'Quantity': 'Quantity',
            'ProductNo': 'Product',
            'OrderQuantity': 'Order Antal',
            'MinQuantity': 'Min Quantity',
            'MaxQuantity': 'Max Quantity',
            'Unit': 'Unit',
            'PurchasePrice': 'Purchase price',
            'TotalPurchasePrice': 'Total Purchase Price',
            'Total': 'Total',
            'Inventory': 'Inventory',
            'MinInventory': 'Min Inventory',
            'MaxInventory': 'Max Inventory',
            'PurchaseQuantity': 'Purchase Quantity',
            'ShowAll': 'Show All',
            'ColorCode': 'Color code',
            'ProjectNumber':'Project Number',
            'RequisitionNo': 'Requisition No',
        },
        'MESSAGES': {
            'Failed': 'Failed',
            'NORECORD': 'No Material Found',
        },
        'TITLE': {
            'CustomerPagination': 'Number of logs per. page',
        },
        'SupplierOrderStatus':{
            'Ordered':'Ordered',
            'Received':'Received',
        },
        "SupplierList": {
            'value1': 'Ordered',
            'value2': 'Received',
            'value3': 'Partially Received',
            'value4': 'Delete',
        },
    }
}