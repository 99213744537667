import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { locale as english } from '../translate/en';
import { locale as danish } from '../translate/ds';
import { CustomersService } from "../../../customer.service"
import { RequestCustomerProperty } from 'app/main/model/RequestBase';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { NotificationsService } from 'angular2-notifications';
import { CommonService } from 'app/core/services/common.service';
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from '../../../../../../core/services/translation-loader.service';
import { CustomerProperty } from 'app/main/model/Customer';
import { MatDialog } from '@angular/material';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';
import { PropertyTypeCategory } from 'app/constant/common.constant';

@Component({
    selector: 'vibe-customerPropertyList',
    templateUrl: './customerPropertyList.component.html',
    styleUrls: ['./customerPropertyList.component.scss']
})
export class CustomerPropertyListComponent implements OnInit {
    @Input() CustomerInput: any;
    @Output() UpdateCustomerProperty: EventEmitter<any> = new EventEmitter(null);

    showSpinner = false;
    CustomerID: string = '';
    CustomerProperties: CustomerProperty[] = [];
    requestCustomerProperty: RequestCustomerProperty = new RequestCustomerProperty();
    RemoveRoomByIndex: number;
    displayPagination: boolean;
    pagination: any;
    propertyTypeCategory = PropertyTypeCategory;

    //Initiators
    constructor(
        private translationLoader: FuseTranslationLoaderService,
        private customersService: CustomersService,
        private notificationsService: NotificationsService,
        private commonService: CommonService,
        private translate: TranslateService,
        public dialog: MatDialog
    ) {
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
        this.CustomerID = this.CustomerInput;
        
        if(this.CustomerID) {
            this.GetCustomerProperties()
        }
    }

    GetCustomerProperties() {
        if(this.CustomerID) {
            this.showSpinner = true;
            this.requestCustomerProperty.Index = 1;
            this.requestCustomerProperty.Limit = 100;
            this.requestCustomerProperty.CustomerID = this.CustomerID;
            this.customersService.GetCustomerProperties<ResponseBase>(this.requestCustomerProperty)
                .subscribe((response: ResponseBase) => {
                    if (response != null) {
                        this.CustomerProperties = response.result;
                        this.pagination = response.pagination;
                        this.displayPagination = true;
                        this.showSpinner = false;
                    }
                    else {
                        this.CustomerProperties = [];
                        this.displayPagination = false;
                    }
                },
                    error => (err) => {
                        this.CustomerProperties = [];
                        this.displayPagination = false;
                        this.showSpinner = false;
                    },
                    () => {
                        this.showSpinner = false;
                    });
        }
    }

    receivePaginatonEvent(event) {
        this.requestCustomerProperty.Index = event.pageIndex;
        this.requestCustomerProperty.Limit = event.pageSize;
        this.GetCustomerProperties();
    }

    updateCustomerProperty(obj){
        this.UpdateCustomerProperty.emit(obj)
    }

    deleteCustomerProperty(id) {
        var message = "";
        this.translate.get("MESSAGES.DeleteAlert").subscribe({
            next: (res) => {
                message = res;
            }, error: err => { }
        })
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: '200px',
            data: { Header: message }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.showSpinner = true;
                this.customersService.DeleteCustomerProperty<ResponseBase>(id)
                    .subscribe((response: ResponseBase) => {
                        this.showSpinner = false;
                        if (response != null) {
                            if(response.statusCode==200) {
                                this.translate.get('MESSAGES.Deleted').subscribe({
                                    next: (res) => {
                                        this.notificationsService.create(res);
                                    },
                                    error: err => {
                                    }
                                });
                                this.GetCustomerProperties()
                            }
                        }
                        else {
                            this.translate.get("MESSAGES.Failed").subscribe({
                                next: (res) => {
                                    this.notificationsService.error(res);
                                },
                                error: err => {
                                }
                            });
                        }
                    },
                    error => (err) => {
                        this.showSpinner = false;
                        this.translate.get("MESSAGES.Failed").subscribe({
                            next: (res) => {
                                this.notificationsService.error(res);
                            },
                            error: err => {
                            }
                        });
                    });
            }
        });        
    }

    getPropertyName(id){
        for (let i = 0; i < this.propertyTypeCategory.length; i++) {
            if(this.propertyTypeCategory[i].id == id) {
                return this.propertyTypeCategory[i].key
            }
        }
    }

}
