export const locale = {
  lang: 'ds',
  data: {
    'PROJECT': {
      'TITLE': 'Projekt',
      'DESC': 'Hej Projekt!'
    },
    'BUTTONS': {
      'NEWPROJECT': 'Nyt projekt',
      "DefaultCoworker": 'Standard medarbejder',
      'StatusMessage': 'Statusmeddelelse',
      'Tool': 'Værktøj',
      'PriceDiscount':'Prisrabat',
      'ShowInactive': 'At vise Inaktive',
      'VehicleInfo':'Nanolink',
      'AssignCoworker': 'Tildel til medarbejder'

    },
    'TITLE': {
      'ProjectPagination': 'Antal projekter pr. side',
    },
    'FILTERS': {
      'SEARCH': 'Søg',
      'SORTING': 'Sorter efter',
      'SORTING_STATUS': 'Status',
      'Active': 'Inkluder alle inaktive',
      'BADGE': 'Inaktive',
      'Deleted': 'Vis KUN slettet sager',
      'All': 'Alle',
      'Lift/Stigemand': 'Lift/Stigemand',
      'Indoor/Outdoor': 'Indoor/Outdoor',
      'Lift/Stigmand': 'Lift/Stigmand',
      'Ind/Ud': 'Ind/Ud',
      'MtoM': 'M til M',
      'Note': 'Bemærk',
      'Offers': 'Tilbud',
      'Show60DayOld': 'Vis kun projekter de sidste 60 dage',
      'ShowHourDetails': 'Vis time beskrivelser',
      'TotalProject': 'I alt projekter',
      'TotalProjectRemainingHours': 'Projekter resterende timer',
      'TotalProjectDaysLeft': 'Projekter resterende Dage',
      'LastDayWorkingHour': 'Sidste dags arbejdstid',
      'TotalProjectPrice': 'I alt projekter Pris',
      'PurchaseStatus': 'Købs status',
      'AverageAmount': 'gennemsnitligt beløb',
      'TotalProjectRemainingPris': 'Projektets resterende pris',
      'SearchByZip': 'Søg Postnummer',
      'Inactive':'Inaktive'
    },
    'MESSAGES': {
        'NORECORD': 'Intet optage fundet',
        'NORECORDONSTATUS': 'Du finder, at projektet kan have en anden status eller inaktivt eller slettet...'
    },
    'FIELDS': {
      'IndoorOutDoor': 'Ind, ud, Sprøjt, Reklamation *',
      'Lift': 'Lift',
      'Stigemand': 'Stigemand',
      'CaseNumber': 'Sagsnummer',
      'Title': 'Titel',
      'Client': 'Klient',
      'Address': 'Adresse',
      'ProjectEndDate': 'Indtil dato',
      'EndDate': 'Slut dato',
      'CoWorkers': 'Co-Workers',
      'Files': 'Filer',
      'actionsColumn': 'Handling',
      'From': 'Fra',
      'To': 'til',
      'Not-Found-CUSTOMER': 'Kunde not found',
      'Not-Found-MATERIAL': 'Materiale not found',
      'Not-Found-Worker': 'Medarbejder ikke fundet',
      'TOTAL': 'i alt',
      'Coworker': 'Medarbejdere',
      'HoursBack': 'Timer tilbage',
      'Before60Days': '*** 60 Dag gammel.',
      'NoOfEmployee': 'Antal medarbejdere',
      'MaterialPurchasePriseTotal': 'Sum Lager',
      'RemainingTime': 'Resterende tid',
      'Planned': 'Planlagt',
      'HourlyWage': 'Timeløn',
      'Dankort': 'Dankort',
      'PaymentMode1': 'Dankort',
      'PaymentMode2': 'Mastercard',
      'PaymentMode3': 'kontant',
      'PaymentMode4': 'Mobilpay',
      'PaymentMode5': 'Mobilepay KK',
      'PaymentMode6': 'Kredit',
      'PaymentMode7': 'Andet',
      'visit': 'Besøg',
      'Text': 'Tekst*',
      'OpgaveText': 'Rekvisitions Tekst',
      'InvoiceText': 'Faktura Tekst',
      'SagNumber':'SagNumber',
      'Name':'Navn',
      'ZipCode':'Postnummer',
      'City':'By',
      'CustomerName':'Kundenavn',
      'CustomerDetail': 'Debitor Detaljer',
      'ProjectAddress': 'Sag Adresse',
      'StartDate': 'Start dato',
      'PhoneNo': 'Telefon',
      'Status': 'Status',
    },
    'selectedValueIndoorOutdoorStatus': {
      'value1': 'Indendørs ',
      'value2': 'Udendørs',
      'value3': 'Sprøjtemaling',
      'value4': 'Reklamation',
      'value5': 'Personale køb', //drawing of painter
      'value6': 'Butiks køb'     //Nordsjø logo
    },
    'selectedOption': {
      'value1': 'Nej',
      'value2': 'Ja',
    },
    'selectedOptionForLoftAndRiseMan': {
      'value1': 'Nej',
      'value2': 'Ja',
    },
    'SORTBY':
      [
        {
          Text: 'Nummer (0-9)',
          Value: 'Number'
        },
        {
          Text: 'Nummer (9-0)',
          Value: 'Number DESC'
        },
        {
          Text: 'Startdato ↑',
          Value: 'StartDate'
        },
        {
          Text: 'Startdato ↓',
          Value: 'StartDate DESC'
        },
        {
          Text: 'slutdato ↑',
          Value: 'EndDate'
        },
        {
          Text: 'slutdato ↓',
          Value: 'EndDate DESC'
        },
        {
          Text: 'Status ↑',
          Value: 'Status'
        },
        {
          Text: 'Status ↓',
          Value: 'Status DESC'
        },
        {
          Text: 'Post Nummer ↑',
          Value: 'PostNummer'
        },
        {
          Text: 'Post Nummer ↓',
          Value: 'PostNummer DESC'
        }
      ],
    // 'selectedValueStatus': {
    //   'value0': 'Registered',
    //   'value1': 'Process',
    //   'value2': 'Planlagt besøg',
    //   'value3': 'Klar til opstart',
    //   'value4': 'Tilbud sendt',
    //   'value5': 'Tilbud godkendt',
    //   'value6': 'Planlagt',
    //   'value7': 'Tilbud afvist',
    //   'value8': 'Startet',
    //   'value9': 'Udført',
    //   'value10': 'Faktureret',
    //   'value11': 'Afventer',
    //   'value12': 'Klar fakturering',
    // },
    //'selectedValueStatus': {
    //  'value1': { 'name': 'Kontaktes', 'color': 'pink' },
    //  'value2': { 'name': 'Planlagt besøg', 'color': 'pink' },
    //  'value3': { 'name': 'Svare ikke', 'color': 'pink' },
    //  'value4': { 'name': 'Tilbud sendt', 'color': 'pink' },
    //  'value5': { 'name': 'Tilbud godkendt', 'color': 'pink' },
    //  'value6': { 'name': 'Planlagt', 'color': 'pink' },
    //  'value7': { 'name': 'Tilbud afvist', 'color': 'pink' },
    //  'value8': { 'name': 'Startet', 'color': 'pink' },
    //  'value9': { 'name': 'Udført', 'color': 'pink' },
    //  'value10': { 'name': 'Faktureret', 'color': 'pink' },
    //  'value11': { 'name': 'Afventer', 'color': 'pink' },
    //  'value12': { 'name': 'Klar fakturering', 'color': 'pink' },
    //},
    'SORTBY_STATUS': [
      {
        Text: 'Vis alt',
        Value: 0
      },
      {
        Text: 'Process',
        Value: 1
      },
      {
        Text: 'Planlagt besøg',
        Value: 2
      },
      {
        Text: 'Svare ikke',
        Value: 3
      },
      {
        Text: 'Tilbud sendt',
        Value: 4
      },
      {
        Text: 'Tilbud godkendt',
        Value: 5
      },
      {
        Text: 'Planlagt',
        Value: 6
      },
      {
        Text: 'Tilbud afvist',
        Value: 7
      },
      {
        Text: 'Startet',
        Value: 8
      },
      {
        Text: 'Udført',
        Value: 9
      },
      {
        Text: 'Faktureret',
        Value: 10
      },
      {
        Text: 'Reklamation / internt',
        Value: 11
      }
    ],
    'TOOTIPORTITLE':{
      'ProjectWorkingHoursDetails':'Detaljer Om Projekttimer'
    }
  }
};
