export const locale = {
  lang: 'en',
  data: {
    'PROJECT': {
      'TITLE': 'Project',
      'DESC': 'Hello Project!'
    },
    'BUTTONS': {
      'NEWCUSTOMER': 'New Customer',
      'BACK': 'Back'
    },
    'FILTERS': {
      'TEXTSEARCH': 'Text Search',
      'NUMBERSEARCH': 'Number Search',

      'CustomerNumber': 'Customer Number',
      'CustomerName': 'Customer Name',
      'PhoneNumber': 'Phone Number',
      'Email': 'Email',
      'Address': 'Address',
      'City': 'City',
      'ZipCode': 'ZipCode',
      'Position': 'Position',
      'Text': 'Text',
      'Action': 'Action',
      'SORTING': 'Sort By'
    },
    'MESSAGES': {
      'NORECORD': 'No Record Found'
    },
    'TABHEADER': {
      'Dashboard': 'Dashboard',
      'Customers': 'Customers',
      'Materials': 'Materials',
      'Projects': 'Projects',
      'Documents': 'Documents',
      'Workhours': 'Workhours',
      'Messages': 'Messages',
      'CoWorkers': 'CoWorkers',
      'CompanyInfo': 'Company-Info',
      'Help': 'Manual'
    },
    'SORTBY':
    [
      //{
      //  Text: '     ',
      //  Value: 'ID DESC'
      //},
      {
        Text: 'Name (A-Z)',
        Value: 'Text'
      },
      {
        Text: 'Name (Z-A)',
        Value: 'Text DESC'
      },
      {
        Text: 'Number (0-9)',
        Value: 'Number'
      },
      {
        Text: 'Number (9-0)',
        Value: 'Number DESC'
      }
    ],

  }
};
