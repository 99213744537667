import { NgModule } from "@angular/core";
import { FlexLayoutModule } from '@angular/flex-layout';
import { FusePipesModule } from 'app/core/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@fuse/material.module';
import { ProjectCardComponent } from './project-card.component';
import { PaginationModule } from 'app/main/pages/pagination/pagination.module';
import { FilterDropdownModule } from 'app/main/pages/filter-dropdown/filter-dropdown.module';
import { CustomCurrencyMaskConfig } from 'app/core/constant/CurrencyMaskConfiguartion';
import { NgxCurrencyModule } from 'ngx-currency';

@NgModule({
    declarations: [ProjectCardComponent],
    imports: [
        CommonModule,
        MaterialModule,
        FlexLayoutModule,
        FusePipesModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        DatePickerModule,
        NgxMatSelectSearchModule,
        PaginationModule,
        FilterDropdownModule,
        NgxCurrencyModule.forRoot(CustomCurrencyMaskConfig),
    ],
    exports: [ProjectCardComponent]
})
export class ProjectCardModule {

}