export const locale = {
  lang: 'en',
  data: {
    'STATUS': {
      'Created': 'Created',
      'Updated': 'Updated',
      'Deleted': 'Deleted',
      'FileUploaded': 'Files Uploaded',
      'FileDeleted': 'Files Deleted',
    },
    'TITLE': {
      'CustomerPagination': 'Antal log pr. side',
    },
    'BUTTONS': {
      'BACK': 'Cancel',
      'BTNSAVE': 'Save',
    },
    'FIELDS': {
      'SRNO': 'Sr No',
      'Status': 'Status',
      'Number': 'Number',
      'Name': 'Name',
      'Createdby': 'Created By',
      'CreatedDate': 'Created Date',
      'ModifyBy': 'Modify By',
      'ModifyDate': 'Modify Date',
      'CoworkerName': 'Coworker Name',
      'Date': 'Date',
      'Description': 'Description',
      'CustomerNo': 'Customer No.',
      'CustomerName': 'Customer Name',
      'Email': 'E-mail',
      'PhoneNo': 'Phone No.',
      'Address-line-1': 'Address(Line 1)',
      'Address-line-2': 'Address(Line 2)',
      'City': 'City',
      'Zipcode': 'Zipcode',
      'Position': 'Position',
      'Comments': 'Comments  [ Optional ]',
      'OrderName': 'Order name',
      'EnterLocation': 'Enter your location',
      'Active': 'Set as inactive',
      'From': 'From',
      'To': 'to',
      'colorCode': 'Color code',
      'PersonnelPurchas': 'Staff Purchase',
      'EmailInvoice': 'Invoice Email',
      'EANNumber': 'EAN Number',
      'Address': 'Address',
      'Detail': 'Detail',
      'ChangedDetail': 'Changed Detail',

      'RequisitionText': 'Requisition Text',
      'Text': 'Tekst',
      'StartDate': 'Start Date',
      'EndDate': 'End Date',
      'InvoiceText': 'Invoice Text',
      'RequisitionNo': 'Requisition no.',
      'Size': 'size',
      'LeftStartDate': 'Left start date',
      'LeftInfoEndDate': 'Left end date',
      'Left': 'Left',
      'LeftDays': 'Left days',
      'Riseman': 'Riseman',
      'ExcludePrice': 'Price excl. VAT',
      'EstimatedPrice': 'Estimate Price',
      'IncludePrice': 'Price incl. VAT',
      'EstimatedTime': 'Hours on case',
      'NumberOfDays': 'No of days',
      'ReasonForProjectDropped': 'Reason',
      'TotalWorkingTime': 'Timer',
      'HoursBack': 'Hours Back',
      'IsHourlyWage': 'Is case on hourly pay',
      'HourlyWage': 'Hourly rate',
      'PaymentMode': 'Payment Mode',
      'DiscountPercent': 'Discount %',
      'PerformedPercent': 'Done %',

    },
    'Headers': {
      'CustomerLogDetails': 'Requisition Log Details'
    },

    'MESSAGES': {
      'NORECORD': 'No Record Found',
    },
    'selectedValueIndoorOutdoorStatus': {
      'value1': 'Indoor ',//Man in a room
      'value2': 'Outdoor', //Sun
      'value3': 'Spray Painting',//spraypaint
      'value4': 'Complaint', //Reklamation
      'value5': 'Staff purchase', //drawing of painter
      'value6': 'Store purchase' //Nordsjø logo
    },
    'yesOrNoOptions': {
      'value1': 'No',
      'value2': 'Yes'
    },
    'paymentModeList': {
      'value1': 'Dankort',
      'value2': 'Mastercard',
      'value3': 'Cash',
      'value4': 'Mobile payment',
      'value5': 'Mobile payment KK',
      'value6': 'Credit',
      'value7': 'Other',
    },
    // 'selectedValueStatus': {
    //   'value0': 'Registered',
    //   'value1': 'Process',
    //   'value2': 'Scheduled visit',
    //   'value3': 'Ready to boot',
    //   'value4': 'Offers sent',
    //   'value5': 'Offer approved',
    //   'value6': 'Planned',
    //   'value7': 'Offer rejected',
    //   'value8': 'Started',
    //   'value9': 'Performed',
    //   'value10': 'Billed',
    //   'value11': 'Pending',
    //   'value12': 'Ready billing',
    // },
    'IsActive': {
      'value1': 'Active',
      'value0': 'Inactive',
    }

    
  }
};


