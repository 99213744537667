export const locale = {
  lang: 'ds',
  data: {
    'CUSTOMER': {
      'TITLE': 'kunder',
      'DESC': 'Hej kunder!'
    },
    'BUTTONS': {
      'NEWCUSTOMER': 'Ny kunde',
      'NEWINQUIRY': 'Ny forespørgsel',
      'BACK': 'Tilbage'
    },
    'TITLE': {
      'MaterialPagination': 'Antal materialer pr. side',
    },
    'MESSAGES': {
      'NORECORD': 'Ingen kunde fundet',
      'SENDEMAILSUCCESS': 'Email sendt med succes',
    },
    'FILTERS': {
      'Filter': 'Filter',
      'TEXTSEARCH': 'Søg efter navn',
      'NUMBERSEARCH': 'Kunde nr. Søg',
      'CustomerNumber': 'Kunde nummer',
      'CustomerName': 'Kundenavn',
      'Email': 'E-mail',
      'PhoneNumber': 'Telefon Nummer',
      'Address': 'Adresse',
      'City': 'By',
      'ZipCode': 'Postnummer',
      'Position': 'Position',
      'Text': 'Tekst',
      'Action': 'Handling',
      'SORTING': 'Sorter efter',
      'Active': 'Inkluder alle inaktive',
      'BADGE': 'Inaktive',
        'Deleted': 'Vis slettet'
    },
    'GridFeildsValue': {
      'value1': 'Nummer',
      'value2': 'Name',
      'value3': 'Pris',
      'value4': 'Indkoebs Pris',
      'value5': 'Personal Pris',
      'value6': 'Holdings',
      'value7': 'Min Beholdning',
      'value8': 'Max Beholdning',
      'value9': 'Enhed',
    },
    'SORTBY':
      [
        {
          Text: 'Navn (A-Z)',
          Value: 'Text'
        },
        {
          Text: 'Navn (Z-A)',
          Value: 'Text DESC'
        },
        {
          Text: 'Nummer (0-9)',
          Value: 'Number'
        },
        {
          Text: 'Nummer (9-0)',
          Value: 'Number DESC'
        }
      ]
  }
};
