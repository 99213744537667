export const locale = {
    lang: 'ds',
    data: {
        'Headers': {
            "Title": " Vejrtype",
            "DeleteWeather": "Sagsøger du, at du vil slette dette vejr?"
        },
        "FIELDS": {
            'SRNO': 'Sr nr.',
            "WeatherType": "Vejrtype",
            "StartMonth": "Start måned",
            "EndMonth": "Slut måned",
            "Edit": 'Redigere',
            "Delete": 'Slet'
        },
        "BUTTONS": {
            "Add": "Tilføj"
        },
        'MESSAGES': {
            'Added': 'Tilføjet med succes.',
            'Updated': 'Opdateret succesfuldt.',
            'Failed': 'Mislykkedes',
            'Deleted': 'Slettet med succes',
            'NORECORD': 'Intet optage fundet'
        },
        "VALIDATIONS": {
            'TypeId': "Weather type is required.",
            'StartMonth': 'Start month is required.',
            'EndMonth': 'End month is required.'
        }
    }
}