export const locale = {
  lang: 'en',
  data: {
    'MESSAGES': {
      'Add': 'Added successfully',
      'Updated': 'Updated successfully'
    },
    'BUTTONS': {
      'CreateReminder': 'Create Reminder',
      'Cancel': 'Cancel'
    },
    'TITLE': {
      'ReminderPagination': 'Antal påmindelser pr. side',
    },
    'FIELDS': {
      'To': 'To',
      'DateToRemind': 'Date To Remind',
      'Text': 'Text',
      'TEXTSEARCH': 'Search',
      'Yes': 'Yes',
      'No': 'No',
      'ReceivedToMe': 'Received to me',
      'SentByMe': 'Sent by me',
      'ReminderDate': 'Select Reminder Date',
      'StartDate': 'Select Start Date',
      'EndDate': 'Select End Date',
      'SORTING': 'Sort by',
      'ReminderEndDate': 'Reminder End Date',
      'DeleteAll': 'Delete all',
      'CompletedDate': 'Completed Date',
      'Not-Found-REMINDER': 'Reminder not found',
      'Reset': 'Reset',
      'Done': 'Make everyone "Done"',
      'GetAll': 'Show all'
    },
    'PlaceHolder': {
      'EnterText': 'Enter Reminder Text*',
      'ReminderDate': 'Select Reminder Date*',
      'SelectCoWorker': 'Select Co-Worker*',
      'Search-worker': 'Search Co-worker',
      'Not-Found-Worker': 'Co-worker not found',
      'DeleteAlert': 'Are you sure you want to delete?',
      'ReminderList': 'Reminder List',
      'PleaseSelectFromBelow': 'Please Select From Below',
      'DoYouWantRepeatThisReminder': 'Do you want to repeat this reminder?',
      'ReminderEndDate': 'Select Reminder End Date'
    },
    'SORTBY':
      [
        {
          Text: 'Date ↑',
          Value: 'DATE'
        },
        {
          Text: 'Date ↓',
          Value: 'DATE DESC'
        }
      ],
    'selectedReminderRepeatModes': {
      'value1': 'Daily ',
      'value2': 'Weekly',
      'value3': 'Monthly',
      'value4': 'Yearly'
    },
    'selectedReminderListType': {
      'value1': 'Sent by me',
      'value2': 'Received to me'
    },
    'TOOTIPORTITLE': {
      'Edit': 'Edit',
      'Delete': 'Delete',
      'Search':'Search',
      'History':'History'
    }
  }
};
