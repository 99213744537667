export const locale = {
    lang: 'en',
    data: {
        'TITLE': {
            'EmailSendConfigHeader': 'Email Configuration',
        },
        'FIELDS': {
            'ESCIsTimeBased':'Is Time Based ?',
            'ESCIsSendEmail': 'Is Send Email ?',
            'ESCHoursFromSend': 'Hours from send',
            'ESCHoursToSend': 'Hours to send',           
        },
        'VALIDATIONS': {
            'ESCHoursFromSend': 'Hours from send is required',
            'ESCHoursFromSendSmaller': 'Hours from send should be smaller than hour to send',
            'ESCHoursToSend': 'Hours to send is required',           
            'ESCHoursToSendGreater': 'Hours to send should be greater than hour from send',
        },
        'MESSAGES': {
            'Saved': 'Saved successfully.',
        },
        'BUTTONS': {
            'ESCSave': 'Save',
        }
    }
};