export const locale = {
    lang: 'en',
    data: {
        'BUTTONS': {
            'Add': 'Add',
            'Back': 'Back',
            'Save': 'Save'
        },
        'FIELDS': {
            'Key': "Key",
            'Quantity': "Quantity",
            'Number': 'Number',
            'Remark': "Remark"
        },
        'VALIDATIONS': {
            'keyType': 'Select Key',
            'keysName': 'Insert value',
            'QuantityRequired': 'Insert quantity',
            'NumberRequired': 'Number required'
        },
        'MESSAGES': {
            'Created': 'Created successfully',
            'Updated': 'Updated successfully',
            'Deleted': 'Deleted successfully',
            'Failed': 'Operation failed , try later',
        },
        "KeyTypes": {
            "value1": "Company key",
            "value2": "Car number",
            "value3": "PIN code alarm",
            "value4": "Other keys",
        }
    }
}