export const locale = {
    lang: 'ds',
    data: {
        'Header': {
            "Title": "Farvekode"
        },
        "FIELDS": {
            "ColorCode": "Farvekode"
        },
        "BUTTONS": {
            "Add": "Tilføj"
        },
        'MESSAGES': {
            'Updated': 'Opdateret succesfuldt.',
            'Failed': 'Mislykkedes',
            'Deleted': 'Slettet med succes',
            'NORECORD': 'Intet optage fundet'
        },
    }
}