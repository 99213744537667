export const locale = {
    lang: 'ds',
    data: {
        'Header':{
            'Title':'Kopier alle materieler fra denne til'
        },
        'BUTTONS':{
            'Add': 'Tilføj',
            'Back': 'Tilbage',
            'Copy': 'Kopi',
        },
        'LABELS': {
            'CopyTo': 'Kopier til',
        },
        'MESSAGES': {
            'NORECORD': 'Ingen registrering fundet',
            'Copy': 'Kopiering lykkedes',
            'CopyError':'Kopie error.',
          },
          'FILTERS': {
              'All': 'All',
              'OfferType': 'Tilbud type',
        },
        'FIELDS': {
            'Not-Found-MATERIAL': 'Material not found'
        }
    }
};