export const locale = {
    lang: 'ds',
    data: {
        'Headers': {
            "Title": "Pris rabat",
            "DeleteWeather": "Sagsøger du, at du vil slette dette vejr?"
        },
        "FIELDS": {
            'SRNO': 'Sr nr.',
            "Role": "Role",
            'RoleName': 'Role navn',
        },
        "BUTTONS": {
            "Add": "Tilføj",
            'Back': 'Tilbage',
            'Save': 'Gem',
        },
        'MESSAGES': {
            'Added': 'Tilføjet med succes.',
            'Updated': 'Opdateret succesfuldt.',
            'Failed': 'Mislykkedes',
            'Deleted': 'Slettet med succes',
            'NORECORD': 'Intet optage fundet'
        },
    }
}