import { NgModule } from "@angular/core";
import { AssetsComponent } from 'app/main/pages/nanolink/assets/assets.component';
import { NewAssetsComponent } from 'app/main/pages/nanolink/assets/component/new-assets/new-assets.component';
import { NewDeviceComponent } from 'app/main/pages/nanolink/devices/component/new-device/new-device.component';
import { DevicesComponent } from 'app/main/pages/nanolink/devices/devices.component';


import { NanolinkService } from 'app/main/pages/nanolink/nanolink.service';
import { SharedModule } from 'app/core/modules/shared.module';
import { RouterModule } from '@angular/router';
import { NgxMaskModule } from 'ngx-mask';
import { DatePickerModule, DateTimePickerModule } from '@syncfusion/ej2-angular-calendars';
import { CustomCurrencyMaskConfig } from "app/core/constant/CurrencyMaskConfiguartion";
import { NgxCurrencyModule } from "ngx-currency";
import { AgmCoreModule } from '@agm/core';
import { environment } from "../../../../environments/environment";

const routes = [
    {
        path: 'nanolink/assets',
        component: AssetsComponent
    },
    {
        path: 'nanolink/assets/add',
        component: NewAssetsComponent,
        data: { IsAdd: true }
    },
    {
        path: 'nanolink/assets/edit/:id',
        component: NewAssetsComponent,
        data: { IsAdd: false }
    },
    {
        path: 'nanolink/devices',
        component: DevicesComponent
    },
    {
        path: 'nanolink/device/add',
        component: NewDeviceComponent,
        data: { IsAdd: true }
    },
    {
        path: 'nanolink/device/edit/:id',
        component: NewDeviceComponent,
        data: { IsAdd: false }
    },
];

@NgModule({
    declarations: [
        AssetsComponent,
        DevicesComponent,
        NewAssetsComponent,
        NewDeviceComponent
    ],
    imports: [
        SharedModule,
        DatePickerModule,
        DateTimePickerModule,
        RouterModule.forChild(routes),
        NgxMaskModule.forRoot(),
        NgxCurrencyModule.forRoot(CustomCurrencyMaskConfig),
        AgmCoreModule.forRoot({
            apiKey: environment.GooogleKey,
            libraries: ["places"]
        })
    ],
    entryComponents: [
        
    ],
    exports: [
        AssetsComponent,
        DevicesComponent,
        NewAssetsComponent
    ],
    providers: [
        NanolinkService
    ]
})

export class NanolinkModule {

}