export const locale = {
    lang: 'ds',
    data: {
        'PAYROLL': {
            'TITLE': 'Indtast Løn',
        },
        'Headers': {
            "Title": "Indtast Løn",
            'DeleteMessage': 'Er du sikker på, at du vil slette dette'
        },
        "FIELDS": {
            'Coworker': 'Medarbejdere',
            'Date': 'Dato',
            'NumberofHours': 'Antal',
            'HourlyRate': 'Pris',
            'TotalAmount': 'Totalbeløb',
            'Description': 'Beskrivelse',
            'koersel': 'Kørsel',
            'GDag': 'G-dage',
            'Holidays': 'Feriedage',
            'NumberofHolidays': 'Antal fridage',
            'Archived': 'Arkiveret',
            'SalaryAdvance':'Lønforskud',
            'Other': 'Andet',
            'Active': 'Inkluder alle inaktive',
        },
        "BUTTONS": {
            'IsArchived':'Er Arkiveret'
        },
        'MESSAGES': {
            'Failed': 'Mislykkedes',
            'Deleted': 'Slettet med succes',
            'NORECORD': 'Intet optage fundet',
        },
        'TOOTIPORTITLE': {
            'Edit': 'Redigere',
            'Delete': 'Slet',
        }
    }
}
