export const locale = {
    lang: 'en',
    data: {
        'Tabs': {
            'Offer': 'Offer',
            'BasicDetail': 'Basic Detail',
            'File': 'File',
            'Category': 'Category',
            'WorkItem': 'Work Item',
        },
        'FIELDS': {
            'OfferName': 'Offer Name',
            'OfferPrice': 'Offer Price',
            'TotalExclVAT': 'Total excl. VAT',
            'VAT': 'VAT',
            'TotalInclVAT': 'Total incl. VAT',
            'IsIncludePriceOnPDF': 'Include Price On PDF',
            'SRNO': 'Sr no.',
            'Date': 'Date',
            'ModuleName': 'Module name',
            'ToEmail': 'To email',
            'CoworkerName': 'Coworker name',
            'EmailHistory': 'Email History',
            'Email': 'Email',
            'Attachment': 'Attachment',
            'ShowRoomTotal': 'Show room total',
            'ConvertedToProject': 'Converte Project',
        },
        'MESSAGES': {
            'Failed': 'Operation failed , try later',
            'Success': 'Success.',
            'NoList': 'No offer found.'
        },
        'BUTTON': {
            'SendOfferOnEmail': 'Send Offer On Email',
            'SeeOffer': 'See Offer',
            'SeeOfferWithRoom': 'See Offer With Room',
            'PDFOREMAIL':'PDF / Email',
        },
        'TOOTIPORTITLE':{
            'EditOfferText':'Edit Offer Text',
            'Edit':'Edit',
            'Delete':'Delete',
            'ViewEmailTemplate':'View Email Template',
            'IsShowOfferInPDF':'Is Show Offer In PDF',
            'IsMaterial':'Add Material To Project',
            'ViewMaterial':'View Material'
        }
    }
};
