export const locale = {
    lang: 'ds',
    data: {
        'MESSAGES': {
            'Created': 'Oprettet med succes',
            'Updated': 'Opdateret med succes',
            'Deleted': 'Slettet med succes',
            'Failed': 'Operation mislykket, prøv senere',
            'NORECORD': 'Ingen registrering fundet',
        },
        'BUTTONS': {
            'Save': 'Gem',
            'Reset': 'Nulstil',
            'Cancel': 'Gå tilbage',
            'Add': "Tilføj",
            'Back': 'Tilbage',            
        },
        'FIELDS': {
            'SRNO': 'Sr nr.',
            'Date': 'Dato',
            'CoworkerName': 'Medarbejder',
            'Projects': 'Projekter',
            'StartDate': 'Startdato*',
            'EndDate': 'Slutdato*',
            'WeekDays': 'Hverdage',
            'Days': 'dage',
            'WeekNo': "Uge nr",
            "Subject":"Indtast emne",
        },
        'Lable': {
            'SRNO': 'Sr nr.',
            'Message': 'Besked',
            'Status': 'Status',
            'AudienceType': 'Medarbejder / Debitor'
        },
        'Headers': {
            'StatusList': 'Status liste',
        },
        'Validators': {
            'StartDate': 'Startdato er påkrævet',
            'EndDate': 'Slutdato er påkrævet',
            'ExecuteTime': "Udførelsestid er påkrævet",
            'SelectProject': 'Vælg Projekter',
        },
    }
};

