import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { Rates, TermsOfEmployment } from '../../model/CoWorker';
import { ApiConstant } from '../../../../app/core/constant/ApiConstant';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { environment } from '../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class TermsOfEmploymentService {
    private actionUrl: string;
    private userImage = new BehaviorSubject<any>('assets/images/vibe-images/user-profile/default profile picture.jpg');
    userImageIcon = this.userImage.asObservable();
    private settingsActionUrl: string;

    constructor(private http: HttpClient) {
        this.actionUrl = ApiConstant.API_URL + ApiConstant.COWORKER + "/";
    }

    public GetParams(param) {
        let params: HttpParams = new HttpParams();
        for (var key in param) {
            if (param.hasOwnProperty(key)) {
                let val = param[key];
                params = params.append(key, val);
            }
        }
        return params;
    }

    public Get<T>(RequestCoWorker: any): Observable<T> {
        let search = this.GetParams(RequestCoWorker);
        return this.http.get<T>(this.actionUrl + "getTermsOfEmploymentAsync", {
            params: search
        });
    }

    public GetTermsByID<T>(id: any): Observable<T> {
        return this.http.get<T>(this.actionUrl + "GetTermsOfEmployment/" + id);
    }

    public AddUpdate<T>(terms: TermsOfEmployment): Observable<T> {
        return this.http.post<T>(this.actionUrl + "AddUpdateTermsOfEmployment", terms);
    }

    // public Update<T>(terms: TermsOfEmployment): Observable<T> {
    //     return this.http.put<T>(this.actionUrl + "UpdateTermsOfEmployment/" + terms.id, terms);
    // }

    public Delete<T>(id: any): Observable<T> {
        return this.http.delete<T>(this.actionUrl + "DeleteTermsOfEmployment/" + id);
    }

    public GetRatesForTermsOfEmployment<T>(RequestRatesForTermsOfEmployment: any): Observable<T> {
        let search = this.GetParams(RequestRatesForTermsOfEmployment);
        return this.http.get<T>(this.actionUrl + "GetRatesForTermsOfEmployment", {
            params: search
        });
    }
    public AddORUpdateRates<T>(terms: Rates): Observable<T> {
        return this.http.post<T>(this.actionUrl + "AddUpdateRatesForTermsOfEmployment", terms);
    }
    
    public DeleteRatesForTermsOfEmployment<T>(id: any): Observable<T> {
        return this.http.delete<T>(this.actionUrl + "DeleteRatesForTermsOfEmployment/" + id);
    }

    public CopyTermOfEmployement <T>(terms: TermsOfEmployment): Observable<T> {
        return this.http.post<T>(this.actionUrl + "CopyTermsOfEmployment", terms);
    }
}
