import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../../core/modules/shared.module';
import { FormsModule } from '@angular/forms';
import { VibePayrollComponent } from './payroll.component';
import { LoanEntryComponent } from './component/LoanEntry/loanEntry.component';
import { NgxMaskModule } from 'ngx-mask'
import { PayrollService } from './payroll.service';
import { NgxCurrencyModule } from 'ngx-currency';
import { CustomCurrencyMaskConfig } from 'app/core/constant/CurrencyMaskConfiguartion';
import { DateTimePickerModule, DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { CoworkerPayrollListComponent } from './component/coworker-payroll-list/coworker-payroll-list.component';

const routes = [
    {
        path: 'payroll',
        component: VibePayrollComponent,
        //resolve: {
        //  formsData: CoWorkersService
        //}
    },
    {
        path: 'payroll/list/:id/:isActive',
        component: CoworkerPayrollListComponent
    },
];

@NgModule({
    declarations: [
        VibePayrollComponent,
        LoanEntryComponent,
        CoworkerPayrollListComponent
    ],
    imports: [
        SharedModule,
        RouterModule.forChild(routes),
        FormsModule, NgxMaskModule.forRoot(),
        NgxCurrencyModule.forRoot(CustomCurrencyMaskConfig),
        DateTimePickerModule,
        DatePickerModule
    ],
    entryComponents: [
        LoanEntryComponent,
    ],
    exports: [
        VibePayrollComponent,
    ],
    providers: [
        PayrollService
    ]
})

export class PayrollModule {
}
