export const locale = {
    lang: 'en',
    data: {
        'Headers': {
            'QuestionList': 'Ask customer the reason of rejection:'
        },
        'BUTTONS': {
            'NEWPROJECTOFFER': 'New Project Offer',
            'AddQuestion': 'Add Question',
            'Save': 'Save',
            'Cancel': 'Cancel'
        },
        'FIELDS': {
            'Question': 'Question',
            'SrNo': 'Sr No.',
            'Type': 'Type',
            'Answer': 'Answer',
            'DragAndDropFile':'Drag and drop file here',
            'Or':'Or',
            'BrowseForFile':'Browse for file'
        },
        'VALIDATIONS': {
            'QuestionRequired': 'Question required',
            'AnswerRequired': 'Answer required'
        },
        'MESSAGES': {
            'NoQuestionAnswerFound': 'No Question Found.',
            'Created': 'Created successfully',
            'Updated': 'Updated successfully',
            'Deleted': 'Deleted successfully',
            'Failed': 'Operation failed , try later',
            'SelectAnyOption': 'Select at least one value to the option list.',
        },
    }
}