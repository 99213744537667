export const locale = {
    lang: 'ds',
    data: {
        'BUTTONS': {
            'NEWCOWORKER': 'Ny medarbejder',
            'AddMaterial': 'Tilføj materiale / Gem',
        },
        'FIELDS': {
            'Status': 'Status',
            'All': 'Alle',
            "Materialtype": 'Vare type'
        },
        'VALIDATIONS': {
            'TextRequired': 'Tekst krævet',
            'ColorCodeRequired': 'Farve kode krævet',
            'PersonalPriceRequired': 'Personlig pris påkrævet',
            'RetailPriceRequired': 'Detailpris påkrævet',
            'PurchaseQuantityRequired': 'Indkøbsmængde krævet',
            'QuantityRequired': 'Mængde krævet',
        },
    }
};

