export const locale = {
    lang: 'en',
    data: {
        'Headers': {
            'NewMaterial': 'Create New Material',
            'AddMaterial': 'Do you want to add materials?',
            'DeleteMaterial': 'Do you want to delete this materials?',
            'DeleteDevice': 'Do you want to delete this device?',
            'Material': 'Material',
            'DeleteAlert': 'Are you sure you want to delete?',
            'AllRequisitionCoworker': 'Are you sure you want to do this on all tasks?',
            'Yes': 'Yes',
            'No': 'No',
            'AskForSaveProjectData': 'Do you want to save this information ?',
            'CopyCustomerDetails': 'Copy Customer Details ?',
            'ChangeStatusToAllRequisition': 'Can you change started status to all task?',
            'UnassignCustomerFromInquiry': 'Are you sure you want to unassign customer from this inquiry ?',
            'MovetoFinishFolder': 'Are you sure you want to move this file to finish folder ?',
            'DeleteProjectFileAlert': 'Are you sure you want to delete the file?',
            'DeleteProjectWorkHours': 'Are you sure you want to delete workhours?',
            'DeleteWorkHoursOnFuture': 'Are you sure you want to delete future workhours on this requisition ?',
        }
    }
};
