export const locale = {
  lang: 'en',
    data: {
        'HEADER': {
            'SelectedRoutesDates': 'Selected Routes Dates',
        },
      'BUTTONS': {
          'Direction':'Direction',
          'Search': 'Search',
    },
    'FIELDS': {
        'FullAddress': 'Full Address',
        'Vehicle': 'Vehicle',
        'StartDate': 'Start Date',
        'EndDate': 'End Date',
        'RouteDate': 'Route Date',
        'Route': 'Route',
        'MetersTrip': 'Meters trip',
        'Group': 'Group',
        'Devices': 'Devices',
        'Labels': 'Category',
    }
  }
};


