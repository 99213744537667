import { Component, OnInit, Input} from '@angular/core';
//import * as JsBarcode from 'jsbarcode';

@Component({
  selector: 'app-display-barcode',
  templateUrl: './display-barcode.component.html',
  styles: []
})
export class DisplayBarcodeComponent implements OnInit {

  @Input() barcode: string = '';
  @Input() barcodeType: string = "CODE128";
  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    //JsBarcode("#barcode", this.barcode, {
    //  format: this.barcodeType,
    //  lineColor: "#000",
    //  displayValue: true,
    //  font: "monospace",
    //  fontOptions: "bold",
    //  textMargin:0
    //});
  }

}
