import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({ name: 'dateDisplay' })
export class DateDisplay implements PipeTransform {

  constructor(private datePipe: DatePipe) { }

  transform(value: string): string {

    //old code
    ////let newValue = new Date(value)
    ////let currentValue = new Date();
    
    ////if (newValue.getFullYear() == currentValue.getFullYear() && newValue.getMonth() == currentValue.getMonth() && newValue.getDate() == currentValue.getDate()) {
    ////  value = "Today"
    ////}
    ////else if (newValue.getFullYear() == currentValue.getFullYear() && newValue.getMonth() == currentValue.getMonth() && newValue.getDate() != currentValue.getDate()) {
    ////  if (newValue.getDate() === (currentValue.getDate() + 1)) {
    ////    value = "Tomorrow"
    ////  }
    ////  else if (newValue.getDate() === (currentValue.getDate() - 1)) {
    ////    value = "Yesterday"
    ////  }
    ////}
    ////return value;

    // end old code

    // new code
    let selectedDate = new Date(value).setHours(0, 0, 0, 0);
    let currentDate = new Date().setHours(0, 0, 0, 0);
    let NextDate = new Date().setHours(24, 0, 0, 0);
    let prevDate = new Date().setHours(-24, 0, 0, 0);
    if (selectedDate == currentDate) {
      value = "Today"
    }
    else if (selectedDate == NextDate) {
      value = "Tomorrow"
    }
    else if (selectedDate == prevDate) {
      value = "Yesterday"
    }
    else {
      value = this.datePipe.transform(value, "dd/MM/yyyy");
    }
    return value;
    // end new code
  }
}
