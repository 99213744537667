import { Component, OnInit, Inject, Optional } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, } from '@angular/material';
import { ProjectService } from '../../project.service';
import { NotificationsService } from 'angular2-notifications';

@Component({
    selector: 'vibe-StartAndVisitDate',
    templateUrl: './startAndVisitDate.component.html',
    styleUrls: ['./startAndVisitDate.component.scss']
})

export class StartAndVisitDateComponent implements OnInit {
    showSpinner: boolean = false;
    startDate: any;
    startTime: any;
    visit: any;
    visitTime: any;
    isPlanning: boolean = false;
    isScheduledVisit: boolean = false;
    coWokers: any = [];
    searchCoWokers: any = [];
    CoworkerId: string = "";
    CoWorkerName: string = "";
    constructor(private translate: TranslateService,
        private translationLoader: FuseTranslationLoaderService,
        private dialog: MatDialog,
        private projectService: ProjectService,
        private notificationsService: NotificationsService,
        public dialogRef: MatDialogRef<StartAndVisitDateComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
        this.translationLoader.loadTranslations(english, danish);

    }

    ngOnInit() {
        if (this.data) {
            this.isPlanning = this.data.isPlanning;
            this.isScheduledVisit = this.data.isScheduledVisit;
            if (this.data.startDate) {
                this.startDate = this.data.startDate;
            }
            if (this.data.startTime) {
                this.startTime = this.data.startTime;
            }
            if (this.data.visit) {
                this.visit = this.data.visit
            }
            if (this.data.visitTime) {
                this.visitTime = this.data.visitTime
            }
            if (this.data.coWokers.length > 0) {
                this.coWokers = this.data.coWokers;
                this.searchCoWokers = this.data.coWokers;
            }
            if (this.data.coworkerID) {
                this.CoworkerId = this.data.coworkerID;
            }
        }
    }

    AddDate() {
        if (this.isPlanning && this.startDate && this.startTime) {
            var startDataDetail = {
                startDate: this.startDate,
                startTime: this.startTime,
                visitCoworkerId: this.CoworkerId
            }
            this.ngOnBackClick(startDataDetail)
        }
        if (this.isScheduledVisit && this.visit && this.visitTime) {
            var visitDataDetail = {
                visit: this.visit,
                visitTime: this.visitTime,
                visitCoworkerId: this.CoworkerId
            }
            this.ngOnBackClick(visitDataDetail);
        }
    }

    ngOnBackClick(data) {
        this.dialogRef.close(data);
    }

    DropDownSearchCoWorker(cow) {
        this.searchCoWokers = this.coWokers.filter(o => (o.name).toLowerCase().includes((this.CoWorkerName).toLowerCase()));
    }
}