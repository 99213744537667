export const locale = {
  lang: 'ds',
  data: {
    'FIELDS': {
      'Name': 'Navn',
      'Performance': 'Ydelse',
      'MaterialType': 'Materiale Type',
      'Search': 'Søg',
      'Not-Found-MATERIALTYPE': 'Materiale Type',
      'ShowRoomDetails': 'Vise rum Detaljer',
      'MultipleOptions': 'Multiple Options',
      'IsLastNode': 'Sidste punkt',
      'IndoreOffer': 'I Dør Tilbud',
      'OutdoreOffer': 'Uden Dørs Tilbud',
      'Price': 'Pris',
      'Reminder': 'Påmindelses',
      'Text': 'Tekst',
      'Time': 'Antal gange',
    },
    'Tabs': {
      'Offer': 'Tilbud',
      'BasicDetail': 'Grundlæggende detaljer',
      'File': 'Fil',
      'Category': 'Kategori',
      'WorkItem': 'Arbejdspost'
    },
    'MESSAGE': {
      'Failed': 'Operation mislykket, prøv senere',
      'Success': 'Succes.',
      'CommingSoon': 'Kommer snart..',
    },
    'BUTTONS': {
      'Add': 'Tilføj',
      'Back': 'Tilbage',
      'Save': 'Gem'
    },
    'FILTERS': {
      'Select': 'Vælg',
      'All': 'Alle'
    },
  }
};
