export const locale = {
    lang: 'en',
    data: {
        'Headers': {
            "Title": "Weather type",
            "DeleteWeather": "Are you sue you want to delete this weather ?"
        },
        "FIELDS": {
            'SRNO': 'Sr nr.',
            "WeatherType": "Weather type",
            "StartMonth": "Start month",
            "EndMonth": "End month",
            "Edit": 'Edit',
            "Delete": 'Delete'
        },
        "BUTTONS": {
            "Add": "Add"
        },
        "MESSAGES": {
            'Added': 'Added Successfully',
            'Updated': 'Updated Successfully.',
            'Failed': 'Failed',
            'Deleted': 'Deleted successfully',
            "NORECORD": "No Record Found"
        },
        "VALIDATIONS": {
            'TypeId': "Weather type is required.",
            'StartDate': 'Start month is required.',
            'EndDate': 'End month is required.'
        }
    }
}