export const locale = {
  lang: 'ds',
  data: {
    'Button': {
      'Ok': 'Indsæt timer',
      'Cancel': 'Gå tilbage'
    },
    'Fields': {
      'SetTimer': 'Indsæt timer',
      'EnterStarttime': 'Indtast starttidspunkt',
      'EndTime': 'Indtast slutstidspunkt',
      'Text': 'Tekst',
      'Week': 'uge',
      'DayHoursTotal': 'Dagtimer i alt',
      'SR.No': 'SR.No',
      'Startime': 'Starttidspunkt',
      'Endtime': 'Slut tid',
      'Total': 'Total',
    },
    'Header': {
      'DailyReport': 'Daglig rapport',
      'Youdidnotaddanyentry': 'Du har ikke tilføjet nogen post.'
    }
  }
};
