import { NgModule } from "@angular/core";
import { AddQuestions1Component } from './add-questions.component';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@fuse/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FusePipesModule } from 'app/core/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { BarRatingModule } from 'ngx-bar-rating';
import { RouterModule } from "@angular/router";

const routes = [
    {
        path: 'offers/qualitytest1',
        component: AddQuestions1Component,
    },
];

@NgModule({
    declarations: [
        AddQuestions1Component
    ],
    imports: [
        CommonModule,
        MaterialModule,
        RouterModule.forChild(routes),
        FlexLayoutModule,
        FusePipesModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        DatePickerModule,
        NgxMatSelectSearchModule,
        BarRatingModule
    ],
    entryComponents: [
        AddQuestions1Component
    ],
    exports: [
        AddQuestions1Component
    ]
})

export class AddQuestions1Module {

}