export const locale = {
  lang: 'en',
  data: {
    'Button': {
      'Ok': 'Ok',
      'Cancel': 'Cancel'
    },
    'Fields': {
      'SetTimer': ' Set Timer',
      'EnterStarttime': 'Enter Starttime',
      'EndTime': 'End Time',
      'Text': 'Text',
      'Week': 'Week',
      'DayHoursTotal': 'Day wise total',
      'SR.No': 'SR.No',
      'Startime': 'Startime',
      'Endtime': 'Endtime',
      'Total': 'Total',

    },
    'Header': {
      'DailyReport': 'Daily Report',
      'Youdidnotaddanyentry':'You did not add any entry yet.'
    }
  }
};
