export const locale = {
    lang: 'en',
    data: {
        'TITLE': {
            'ProjectPagination': 'No. of projects per page',
        },
        'FIELDS': {
            'PaymentMode1': 'Dankort',
            'PaymentMode2': 'Mastercard',
            'PaymentMode3': 'Cash',
            'PaymentMode4': 'Mobile payment',
            'PaymentMode5': 'Mobile payment KK',
            'PaymentMode6': 'Credit',
            'PaymentMode7': 'Other',
            'From': 'From',
            'To': 'to ',
            'visit': 'Visit',
            'HoursBack': 'Hours Back',
            'RemainingTime': 'RemainingTime',
            'HourlyWage': 'Hourly rate',
            'Before60Days': '*** 60 Day old.',
            'Planned': 'Planned',
            'Text': 'Text*',
            'OpgaveText': 'Opgave Tekst',
            'InvoiceText': 'Invoice Text',
            'ShowActive': 'Show Inactive',
            'SORTING': 'Sort By',
            'TEXTSEARCH': 'Search',
            'Date': 'Date',
            'PriceINCVAT': 'Search Price incl. VAT'
        },
        'MESSAGES': {
            'NORECORD': 'No Record Found'
        },
        'BUTTONS': {

        },
        'FILTERS': {
            'BADGE': 'Inactive',
            'MtoM': 'M to M',
            'Note': 'Note',
            'Offers': 'Offers',
        },
        'SORTEDBY':
            [
                {
                    Text: 'Number (0-9)',
                    Value: 'Number'
                },
                {
                    Text: 'Number (9-0)',
                    Value: 'Number DESC'
                }
            ],
        'TOOTIPORTITLE': {
            'GoToRequisition': 'Go To Requisition',
            'GoToMaterial': 'Go To Material',
            'GoToMessageLog': 'Go To MessageLog',
            'GoToStatus': 'Go To Status',
            'GoToCoworker': 'Go To Co-workers',
            'GoToEconomy': 'Go To Economy',
        }
    }
};
