import { Component, OnInit } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { MatDialog, MatOption } from '@angular/material';
import { AuthService } from 'app/core/services/auth.service';
import { SettingsService } from 'app/main/pages/settings/settings.service';
import { ResponseBase } from "app/main/model/ResponseBase";
import { SettingValue, Settings } from "app/main/model/Settings";
import { environment } from "environments/environment";
import { CoWorker } from "app/main/model/CoWorker";
import { CoWorkersService } from "app/main/pages/coWorker/coWorkers.service";
import { RequestCoWorker } from "app/main/model/RequestBase";
import { CompanyInfoSetting } from "app/main/model/CompanyInfo";
import { TextEditorComponent } from "app/main/pages/text-editor/text-editor.component";
import { NotificationsService } from "angular2-notifications";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "vibe-issend-email-sms",
    templateUrl: "./issend-email-sms.component.html",
    styleUrls: ["./issend-email-sms.component.scss"]
})

export class IsSendEmailSMSComponent implements OnInit {

    showSpinner: boolean = false;
    SettingObj: Settings[];
    SendEmailSettingObject: Settings;
    SendSMSSettingObject: Settings;
    //for send notification
    SendNotificationSettingObject: Settings;

    SendUnPlannedReminderSettingObject: Settings;
    SendOfferSMSToCustomerSettingObject: Settings;
    SendBatteryPercentageReminderSettingObject: Settings;
    SendBirthdayReminderSettingObject: Settings;
    SendReminderSMSSettingObject: Settings
    SendcoWorkerJoinNotificationSetting: Settings
    SendCoworkerAnniversaryReminderSetting: Settings
    PlanningLeaveNotificationSettingObject: Settings;
    PlanningRemoveNotificationSettingObject: Settings;
    OfferNotificationSettingObject:Settings
    InquiryNotificationSettingObject:Settings
    OfferAcceptedNotificationSettingObject : Settings;
    OfferRejectedNotificationSettingObject : Settings;
    CustomerReplyNotificationToCoworkerObject : Settings;
    projectStatusBeforeSevenDaysNotificationSettingObject:Settings
    SettingNotesObject: Settings;
    envEmailSMSSendParam = "";
    coWokers: CoWorker[] = [];
    noteDescription: string = "";
    settingValue: SettingValue = new SettingValue();
    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private authService: AuthService,
        private settingsService: SettingsService,
        private coWorkersService: CoWorkersService,
        private translate: TranslateService,
        private notificationsService: NotificationsService,
        private dialog: MatDialog) {
        this.translationLoaderService.loadTranslations(danish, english);
    }

    ngOnInit() {
        this.envEmailSMSSendParam = environment.SendingNotificationValue2;
        var coworkerOBJ = new RequestCoWorker();
        coworkerOBJ.Name = "";
        this.getCoWorkers(coworkerOBJ);
        this.getSendTabSettings();
    }

    getSendTabSettings() {
        this.showSpinner = true;
        this.settingsService.GetSettingsWithTemplateValue<ResponseBase>("Notification", null, null).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.result && response.result.myResponse.length > 0) {
                    this.SettingObj = response.result.myResponse;
                    this.SettingObj.forEach(element => {
                        if (element.key2 == this.envEmailSMSSendParam && element.key3 == 'SendEmail') {
                            this.SendEmailSettingObject = element;
                        }
                        if (element.key2 == this.envEmailSMSSendParam && element.key3 == 'SendSMS') {
                            this.SendSMSSettingObject = element;
                        }
                        if (element.key2 == this.envEmailSMSSendParam && element.key3 == 'SendNotification') {
                            this.SendNotificationSettingObject = element;
                        }
                        if (element.key2 == 'SendUnPlannedReminder') {
                            this.SendUnPlannedReminderSettingObject = element;
                        }
                        if (element.key2 == 'SendOfferSMSToCustomer') {
                            this.SendOfferSMSToCustomerSettingObject = element;
                        }
                        if (element.key2 == 'SendBatteryPercentageReminder') {
                            this.SendBatteryPercentageReminderSettingObject = element;
                        }
                        if (element.key2 == 'SendBirthdayReminder') {
                            this.SendBirthdayReminderSettingObject = element;
                        }
                        if (element.key2 == 'SendReminderSMS') {
                            this.SendReminderSMSSettingObject = element;
                        }
                        if (element.key2 == 'SendCoworkerJoinReminder') {
                            this.SendcoWorkerJoinNotificationSetting = element;
                        }
                        if (element.key2 == 'CoworkerAnniversaryReminder') {
                            this.SendCoworkerAnniversaryReminderSetting = element;
                        }
                        if (element.key2 == 'PlanningLeave') {
                            this.PlanningLeaveNotificationSettingObject = element;
                        }
                        if (element.key2 == 'PlanningRemove') {
                            this.PlanningRemoveNotificationSettingObject = element;
                        }
                        if (element.key2 == 'Inquiry') {
                            this.InquiryNotificationSettingObject = element;
                        }
                        if (element.key2 == 'Offer') {
                            this.OfferNotificationSettingObject = element;
                        }
                        if(element.key2 == "OfferAcceptedToCustomer") {
                            this.OfferAcceptedNotificationSettingObject = element;
                        }
                        if(element.key2 == "OfferRejectedByCustomer") {
                            this.OfferRejectedNotificationSettingObject = element;
                        }
                        if(element.key2 == "CustomerReplyNotificationToCoworker") {
                            this.CustomerReplyNotificationToCoworkerObject = element;
                        }
                        if(element.key2 == "SettingNotes") {
                            this.SettingNotesObject = element;
                            this.settingValue = JSON.parse(this.SettingNotesObject.value)
                            this.noteDescription = this.settingValue.Body;
                        }
                        if (element.key2 == 'ProjectStatusBeforeSevenDays') {
                            this.projectStatusBeforeSevenDaysNotificationSettingObject = element;
                        }
                    });
                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    getCoWorkers(model: RequestCoWorker) {
        model.OrderBy = "Name";
        this.coWorkersService.Get<ResponseBase>(model)
            .subscribe((response: ResponseBase) => {
                if (response) {
                    this.coWokers = Object.assign([], response.result);
                }
                else {
                    this.coWokers = [];
                }
            }, error => (err) => {
                this.coWokers = [];
            }, () => {

            });
    }


    openOfferProjectDescriptionForNote(){
        var selectedTabData = new CompanyInfoSetting();
        selectedTabData.Description = this.noteDescription;
        const dialogRef = this.dialog.open(TextEditorComponent, {
            height: '90%',
            width: '90%',
            data: { selectedTabData, isEmail: false },
            disableClose: true
        });
        dialogRef.afterClosed().subscribe(result => {
            this.showSpinner = true;            
            if (result && result.Description) {
                this.settingValue.Body = result.Description;
                this.SettingNotesObject.value = JSON.stringify(this.settingValue);
                this.noteDescription = this.settingValue.Body;
                this.settingsService.UpdateSettingWithTemplate<ResponseBase>(this.SettingNotesObject).subscribe({
                        next: (response: ResponseBase) => {
                            this.showSpinner = false;
                            if (response != null && response.result) {
                                this.translate.get("MESSAGES.Saved").subscribe({
                                    next: (res) => {
                                        this.notificationsService.create(res);
                                    },
                                    error: err => { }
                                });
                            }
                        },
                        error: err => {
                        },
                        complete: () => {
                            this.showSpinner = false;
                        }
                });
            }
        });
    }
}