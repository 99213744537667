import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ProjectofferService } from '../../project-offer.service';
import { OfferCategory } from '../../../../model/ProjectOffer';
import { Router, ActivatedRoute } from '@angular/router';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { OfferFormName } from 'app/core/constant/SettingConstant';
import { MatDialog } from '@angular/material';
import { environment } from '../../../../../../environments/environment';
import { OfferTypeWithRoomPriceDetail } from '../../../../model/OfferV2';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { CustomersService } from 'app/main/pages/customer/customer.service';

@Component({
    selector: 'app-offer-category-select',
    templateUrl: './offer-category-select.component.html',
    styleUrls: ['./offer-category-select.component.scss']
})
export class OfferCategorySelectComponent implements OnInit {
    @Input() categoryInput: any;
    @Output() eventDisableTabs = new EventEmitter<boolean>();
    @Output() eventGotoPDFEmail = new EventEmitter<any>();
    previousSelectObj: OfferCategory;
    selectedCategory: OfferTypeWithRoomPriceDetail = new OfferTypeWithRoomPriceDetail();
    displayForm: string = "";
    offerId: string = "";
    offerFormName = OfferFormName;
    envirnmentURL: string = "";
    customerDetailObj: any;
    isDisplayPDFEmailbtn: boolean = false;
    showSpinner: boolean = false;
    constructor(private projectofferService: ProjectofferService,
        private _route: ActivatedRoute,
        private translationLoader: FuseTranslationLoaderService,
        public dialog: MatDialog,
        private translate: TranslateService,
        private notificationsService: NotificationsService,
        private customersService: CustomersService) {
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
        this.envirnmentURL = environment.url;
        this._route.params.subscribe(params => {
            var id = params['id'];
            if (id != undefined) {
                this.offerId = id;
            }
        });
    }

    eventDisabledTabsFromOffer(event) {
        this.isDisplayPDFEmailbtn = true;
        this.eventDisableTabs.emit(false);
    }
    goToBackPDFEmail() {
        this.eventGotoPDFEmail.emit("");
    }

    GotoDetailPage(selectedCategory){
        this.selectedCategory = selectedCategory;
        this.displayForm = this.selectedCategory.formName;
        this.customerDetailObj = selectedCategory.customerDetailObj;
    }

    GoToBackCategory(value){
        this.displayForm = value;
    }

    DisabledTabsFromOffer(value){
        this.eventDisableTabs.emit(value);
    }
}
