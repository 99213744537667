import { Component, OnInit } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';

import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { MatDialog, MatDialogRef } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import * as moment from 'moment';
import { RequestMovePlanningWithRanage } from "app/main/model/RequestBase";
import { NotificationsService } from "angular2-notifications";
import { FormService } from "app/core/services/form.service";
import { TranslateService } from "@ngx-translate/core";
import { WorkhoursService } from "app/main/pages/workHour/workhours.service";
import { ResponseBase } from "app/main/model/ResponseBase";

@Component({
    selector: "vibe-move-planning-to-another-coworker.component",
    templateUrl: "./move-planning-to-another-coworker.component.html",
    styleUrls: ["./move-planning-to-another-coworker.component.scss"]
})

export class MovePlanningFromOneCowrkerToAnother implements OnInit {
    SwapCowrkerForm: FormGroup;
    requestMovePlanningWithRanage: RequestMovePlanningWithRanage;
    showSpinner: boolean = false;
    
    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private formBuilder: FormBuilder,
        private notificationsService: NotificationsService,
        public formService: FormService,
        private workhoursService: WorkhoursService,
        private translate: TranslateService,
        public dialogRef: MatDialogRef<MovePlanningFromOneCowrkerToAnother>, ) {
        this.translationLoaderService.loadTranslations(danish, english);
    }

    ngOnInit() {
        this.initFormControl()
    }
    initFormControl(){
        this.SwapCowrkerForm = this.formBuilder.group({
            fromCoworkerID: [null, Validators.required],
            toCoworkerID: [null, Validators.required],
            startDate: [null, Validators.required],
            endDate: [null, Validators.required],
        });
    }

    close() {
        
        this.dialogRef.close(null);
    }
    
    onFocusIn(event) {
        event.model.show();
    }

    public OnChangeDatePicker(args): void {
        if (args.value) {
            args.value = new Date(moment(args.value).format("YYYY-MM-DD"));
        }
    }

    movePlanning(){
        this.formService.markFormGroupTouched(this.SwapCowrkerForm);
        if (this.SwapCowrkerForm.invalid)
            return;
        this.showSpinner = true;
        this.requestMovePlanningWithRanage = this.SwapCowrkerForm.value
        this.workhoursService.movePlanningFromOneCoworkerToAnother<ResponseBase>(this.requestMovePlanningWithRanage)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.Created").subscribe({
                        next: (res) => {
                            this.notificationsService.success(res);
                        },
                        error: err => {
                        }
                    });
                    let result = {
                        updateSchedule: true,
                        deleteSchedule: false,
                        goToProject: false,
                        weekNumber: moment(this.SwapCowrkerForm.get('startDate').value).week().toString(),
                    }
                    this.requestMovePlanningWithRanage = new RequestMovePlanningWithRanage();
                    this.SwapCowrkerForm.reset();
                    // this.dialogRef.close(true);
                    this.dialogRef.close(result);
                },
                error: err => {
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });
                },
                complete: () => {
                    this.showSpinner = false;
                }
        });
            

    }
}