export const locale = {
    lang: 'ds',
    data: {
        'FIELDS': {
            'Billed': 'Faktureret dato'
        },
        'BUTTONS': {
            'BACK': 'Tilbage',
            'SAVE': 'Gem',
        },
    }
};