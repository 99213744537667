import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from 'app/core/modules/shared.module';
import { ProjectOfferComponent } from './pages/project-offer/project-offer.component';
import { ProjectofferService } from 'app/main/pages/project-offer/project-offer.service';
import { DatePipe } from '@angular/common';
import { NgxMaskModule } from 'ngx-mask';
import { QuillEditorModule } from 'ngx-quill-editor';
import { NewProjectOfferComponent } from './pages/new-project-offer/new-project-offer.component';
import { ProjectListComponent } from './component/project-list/project-list.component';
import { ProjectOfferRoomListComponent } from './component/project-offer-room-list/project-offer-room-list.component';
import { AddProjectOfferComponent } from './component/add-project-offer/add-project-offer.component';
import { OfferCategorySelectComponent } from './component/offer-category-select/offer-category-select.component';
import { OfferV2ListComponent } from './component/offerV2-list/offerV2-list.component';
import { OfferV2FormAComponent } from './component/offerV2-forms/form-type-a/offerV2-form-a.component';
import { OfferV2FormMaterialSelectForAllComponent } from './component/offerV2-forms/component/material-select-for-all/material-select.component';
import { OfferV2FormBComponent } from './component/offerV2-forms/form-type-b/offerV2-form-b.component';
import { OfferV2FormCComponent } from './component/offerV2-forms/form-type-c/offerV2-form-c.component';
import { OfferRejectedQuestionComponent } from './component/rejected-question/rejected-question.component';
import { OfferRoomTypeDetailComponent } from './component/offerV2-forms/component/offer-room-type-detail/offer-room-type-detail.component';
import { NgxCurrencyModule } from "ngx-currency";
import { SendOfferEmailComponent } from './component/send-offer-email/send-offer-email.component';
import { ProjectOfferQuestionComponent } from './pages/question/question.component';
import { CustomCurrencyMaskConfig } from "app/core/constant/CurrencyMaskConfiguartion";
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CKEditorModule } from 'ng2-ckeditor';
import { BarRatingModule } from "ngx-bar-rating";
import { AddQuestionComponent } from './pages/question/add-question/add-question.component';
import { TextareaEditorComponent } from './component/offerV2-forms/component/textarea-editor/textarea-editor.component';
import { CalculateDrivingDaysModalComponent } from './component/offerV2-forms/component/calculate-driving-days/calculate-driving-days.component';
import { QualityTestModule } from 'app/main/pages/quality-test/quality-test.module';
import { MatBadgeModule } from '@angular/material/badge';
import { OffersModule } from 'app/main/shared-component/offers/offers.module';
import { EditProjectOfferFeatureComponent } from './component/edit-project-offer-feature/edit-project-offer-feature.component';
import { CalculatedMaterialListComponent } from './component/calculated-material-list/calculated-material-list.component';
import { ViewQualityTestImagesComponent } from './component/view-qualitytest-images/view-qualitytest-images.component';
import { OfferV2ListSelecttionForConvertComponent } from './component/offerlist-selection-for-convert/offerlist-selection-for-convert.component';
import { CalculatedViewMaterialListComponent } from './component/calculated-view-material-list/calculated-view-material-list.component';
import { AddMaterialToProjectComponent } from './component/add-material-to-project/add-material-to-project.component';

const routes = [
    {
        path: 'offers',
        component: ProjectOfferComponent,
    },
    {
        path: 'offers/questions',
        component: ProjectOfferQuestionComponent
    },
    {
        path: 'offers/questions1',
        component: ProjectOfferQuestionComponent
    },
    {
        path: 'offers/add',
        component: NewProjectOfferComponent,
    },
    {
        path: 'offers/add/inquiry/:Custid/:InquiryId',
        component: NewProjectOfferComponent
    },
    {
        path: 'offers/add/customer/:Custid',
        component: NewProjectOfferComponent
    },
    {
        path: 'offers/:id',
        component: NewProjectOfferComponent,
    }
];


@NgModule({
    declarations: [
        ProjectOfferComponent,
        NewProjectOfferComponent,
        ProjectListComponent,
        ProjectOfferRoomListComponent,
        AddProjectOfferComponent,
        OfferCategorySelectComponent,
        OfferV2FormAComponent,
        OfferV2FormMaterialSelectForAllComponent,
        OfferV2FormBComponent,
        OfferV2FormCComponent,
        OfferV2ListComponent,
        OfferRejectedQuestionComponent,
        SendOfferEmailComponent,
        ProjectOfferQuestionComponent,
        OfferRoomTypeDetailComponent,
        AddQuestionComponent,
        TextareaEditorComponent,
        CalculateDrivingDaysModalComponent,
        EditProjectOfferFeatureComponent,
        CalculatedMaterialListComponent,
        ViewQualityTestImagesComponent,
        OfferV2ListSelecttionForConvertComponent,
        CalculatedViewMaterialListComponent,
        AddMaterialToProjectComponent
    ],
    imports: [
        SharedModule,
        RouterModule.forChild(routes),
        NgxMaskModule.forRoot(),
        QuillEditorModule,
        NgxCurrencyModule.forRoot(CustomCurrencyMaskConfig),
        NgxSliderModule,
        CKEditorModule,
        BarRatingModule,
        MatBadgeModule,
        QualityTestModule,
        OffersModule
    ],
    entryComponents: [
        SendOfferEmailComponent,
        OfferV2FormMaterialSelectForAllComponent,
        TextareaEditorComponent,
        CalculateDrivingDaysModalComponent,
        ProjectOfferRoomListComponent,
        EditProjectOfferFeatureComponent,
        CalculatedMaterialListComponent,
        ViewQualityTestImagesComponent,
        OfferV2ListSelecttionForConvertComponent   ,
        CalculatedViewMaterialListComponent,
        AddMaterialToProjectComponent
    ],
    exports: [
        SendOfferEmailComponent,
    ],
    providers: [ProjectofferService,
        DatePipe,
    ]
})
export class ProjectOfferModule { }
