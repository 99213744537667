export const locale = {
    lang: 'ds',
    data: {
        'FIELDS': {
            'Name': 'Navn',
            'Performance': 'Ydelse',
            'MaterialType': 'Materiale Type',
            'Search': 'Søg',
            'Not-Found-MATERIALTYPE': 'Materiale Type',
            'ShowRoomDetails': 'Vise rum Detaljer',
            'MultipleOptions': 'Multiple Options',
            'IsLastNode': 'Sidste punkt',
            'IndoreOffer': 'In door Offer',
            'OutdoreOffer': 'Out door offer',
            'Price': 'Pris',
            'QTY': 'Antal',
            'Reminder': 'Påmindelses',
            'Width': 'Bredde',
            'Length': 'Længde',
            'Total': 'I alt',
            'Time': 'Antal gange',
            'Text': 'Tekst',
            'ColorCode': 'Farvekode',
            'TreatmentType':'Behandlings type',
            'CeilingHeight': 'Lofthøjde',
            'ShowAndet': 'Vis andet',
            'ExtraM2QtyWall': 'Tillæg / Fradrag m2 Vægge',
            'ExtraM2QtyCeiling': 'Tillæg / Fradrag m2 Loft',
            'ExtraM2QtyFloor': 'Tillæg / Fradrag LB Træværk',
            'ExtraM2PriceWall': 'Tillæg / Fradrag Kr. Vægge',
            'ExtraM2PriceCeiling': 'Tillæg / Fradrag Kr. Loft',
            'ExtraM2PriceFloor': 'Tillæg / Fradrag Kr. Træværk'
        },
        'Tabs': {
            'Offer': 'Tilbud',
            'BasicDetail': 'Grundlæggende detaljer',
            'File': 'Fil',
            'Category': 'Kategori',
            'WorkItem': 'Arbejdspost'
        },
        'MESSAGE': {
            'Failed': 'Operation mislykket, prøv senere',
            'Success': 'Succes.',
            'CommingSoon': 'Kommer snart..',
            'RoomSelectionMsg': 'Indsæt @room længde og bredde på tilbud',
        },
        'BUTTONS': {
            'AddItem': 'Tilføj Ydelse',
        },
        'FILTERS': {
            'Select': 'Vælg',
            'All': 'Alle'
        },
        'TOOTIPORTITLE':{
            'GotoMaterial':'Gå til materiale'
          }
    }
};
