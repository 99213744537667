import { Component, OnInit, ViewChild } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { ProjectService } from '../../project.service';

import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { MatDialogRef, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { RequestForGetInactiveProject } from 'app/main/model/RequestBase';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
    selector: "show-inactive-project",
    templateUrl: "./show-inactive-project.component.html",
    styleUrls: ["./show-inactive-project.component.scss"]
})

export class ShowInactiveProjectComponent implements OnInit {
    showSpinner: boolean = false;
    displayPagination: boolean = false;
    inactiveProjectList: [] = [];
    startDate: Date;
    endDate: Date;
    displayedColumns: string[] = ['customerName', 'address1', 'inactiveDate', 'totalPrice', 'coworkerName', 'isActive'];
    dataSource: any = new MatTableDataSource();
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
        this.dataSource.sort = sort;
    }
    requestBase: RequestForGetInactiveProject;
    pagination: any;
    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private projectService: ProjectService,
        private dialogRef: MatDialogRef<ShowInactiveProjectComponent>,
        private router: Router) {
        this.translationLoaderService.loadTranslations(danish, english);
    }

    ngOnInit() {
        this.requestBase = new RequestForGetInactiveProject();
        this.requestBase.Index = 1;
        this.requestBase.Limit = 100;
        this.startDate = new Date(new Date().setDate(new Date().getDate() - 7));
        this.endDate = new Date();
        this.getInActiveProjectList();
    }

    getInActiveProjectList() {
        this.showSpinner = true;
        this.requestBase.StartDate = moment(this.startDate).format('YYYY-MM-DD');
        this.requestBase.EndDate = moment(this.endDate).format('YYYY-MM-DD');
        this.projectService.GetInactiveProjects(this.requestBase).subscribe({
            next: (response: ResponseBase) => {
                if (response) {
                    this.showSpinner = false
                    this.displayPagination = true;
                    this.pagination = response.pagination;
                    this.inactiveProjectList = response.result;
                    this.dataSource = new MatTableDataSource(this.inactiveProjectList);
                }
            },
            error: err => {
                this.showSpinner = false
                this.dataSource = new MatTableDataSource();
            }
        })
    }

    receivePaginatonEvent(event) {
        this.requestBase.Index = event.pageIndex;
        this.requestBase.Limit = event.pageSize;
        this.getInActiveProjectList();
    }

    goToProject(id) {
        this.dialogRef.close(id);
    }

    close() {
        this.dialogRef.close(false);
    }

    onFocusIn(event) {
        event.model.show();
    }

    gotoProject(project) {
        this.router.navigate(['/projects/edit', project.sagId], { queryParams: { isActive: project.isActive } });
        this.dialogRef.close(false);
      }
}