import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { NotificationsService } from 'angular2-notifications';
import { CommonService } from 'app/core/services/common.service';
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { ProjectofferService } from 'app/main/pages/project-offer/project-offer.service';
import { MatCheckboxChange } from '@angular/material';
import { OfferMaterialWithPrice } from 'app/main/model/OfferV2';
import { PaintSelectTime } from 'app/constant/common.constant';
// import { CalculatedMaterialListComponent } from 'app/main/pages/project-offer/component/calculated-material-list/calculated-material-list.component';
import { MatDialog} from '@angular/material';
import { RequestCalculatedOfferMaterialList } from 'app/main/model/RequestBase';
import { CalculatedViewMaterialListComponent } from 'app/main/pages/project-offer/component/calculated-view-material-list/calculated-view-material-list.component';

@Component({
    selector: 'vibe-projectWorkInfo',
    templateUrl: './projectWorkInfo.component.html',
    styleUrls: ['./projectWorkInfo.component.scss'],
})
export class ProjectWorkInfoComponent implements OnInit {
    @Input() OfferID: string = '';
    @Input() ProjectID: string = '';
    @Input() ModuleName: string = '';
    @Input() RequisitionList: string = '';    
    @Output() selectedOfferTypeIDs = new EventEmitter<string[]>(null);
    @Output() dataChange = new EventEmitter<boolean>(false);
    @Input() WorkHoursJsonData: string = "";
    @Output() workhoursDataChanges = new EventEmitter<any>(null);
    @Output() projectWorkhoursIsCompleted = new EventEmitter<any>(null);
    @Output() dataCheck = new EventEmitter<any>(null);

    isCheckedAnyWork: boolean = false;
    showSpinner = false;
    offerTypeList: any[] = [];
    offerTypeList_Orignal: string = '';
    selectedOfferType: string[] = [];
    materialObject: OfferMaterialWithPrice[] = [];
    SelectCategoryTime = PaintSelectTime;
    isInfoChanged: boolean = false;
    getOfferRoomEditDetailListinfo: any;
    flagOfferRoom: boolean = false;
    WorkHoursJsonList: any;
    checkIsRoomDetail: boolean = false;
    checkIsCompleted: boolean = false;
    paintTimerTotal: number = 0;
  requestCalculatedOfferMaterialList = new RequestCalculatedOfferMaterialList();

    //Initiators
    constructor(
        private projectofferService: ProjectofferService,
        private notificationsService: NotificationsService,
        private commonService: CommonService,
        private translate: TranslateService,
        private translationLoader: FuseTranslationLoaderService,
        public dialog: MatDialog,
    ) {
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
        this.getOfferMaterialData();
        // this.dataChange.emit(false)
        // this.SetAndCheckIsSelected();
        if(this.offerTypeList.length==0){
            this.dataChange.emit(true)
        }
    }

    SelectIsWorkingOffer(event: MatCheckboxChange, offerType) {
        let isAnyChecked: any = false;
        const offerRoomDetailListForRoom = offerType.offerRoomDetailList;
        const offerDiverseDetailListForRoom = offerType.offerDiverseDetailList;
        var roomDetailList: any[] = [];
        if (offerRoomDetailListForRoom) {
            for (const offerType of offerRoomDetailListForRoom) {
                if (offerType.offerRoomCalculation) {
                    for (const offerRoomDetailsList of offerType.offerRoomCalculation) {
                        const materialList = offerRoomDetailsList.materialList;
                        if (materialList) {
                            var iscompledValue = materialList.filter((material) => material.isWorking === true);
                            var offerRoomDetailList = iscompledValue.map((material) => {
                                roomDetailList.push({
                                    isWorking: material.isWorking,
                                    offerRoomId: material.offerRoomCalculationId,
                                    offerId: material.id,
                                    Numberofworktime: "",
                                })
                            }
                            )
                        }
                    }
                }
            }
        }
        if (offerDiverseDetailListForRoom) {
            var DiverseDetailList: any[] = [];
            var diverseList = offerDiverseDetailListForRoom.filter((DiversList) => DiversList.isWorking === true);
            var offerDiverseList = diverseList.map((DiversList) => {
                DiverseDetailList.push({
                    isWorking: DiversList.isWorking,
                    diversRoomId: DiversList.roomId,
                    diverseID: DiversList.id,
                    Numberofworktime: "",
                })
            });
        }

        this.checkIsRoomDetail = roomDetailList.length !== 0 || DiverseDetailList.length !== 0;
        if (event.checked && isAnyChecked && !this.selectedOfferType.includes(offerType.id)) {
            this.selectedOfferType.push(offerType.id)
        } else if (!event.checked && !isAnyChecked && this.selectedOfferType.includes(offerType.id)) {
            this.selectedOfferType = this.selectedOfferType.filter(i => i != offerType.id)
        }

        this.selectedOfferTypeIDs.emit(this.selectedOfferType)
        setTimeout(() => {
        this.isInfoChanged = !(this.offerTypeList_Orignal == JSON.stringify(this.offerTypeList))
            if (this.ModuleName === "Workhours") {
                this.SetAndCheckIsSelected();
            }
        }, 0);
        this.roomDetailList(roomDetailList, DiverseDetailList)
    }

    SetAndCheckIsSelected() {
        this.isCheckedAnyWork = false;
        this.offerTypeList.forEach(offerType => {
            if (offerType['offerRoomDetailList']) {
                offerType['offerRoomDetailList'].forEach(offRm => {
                    offRm['offerRoomCalculation'].forEach(offCals => {
                        offCals['materialList'].forEach(mat => {
                            if (mat['isWorking']) {
                                this.isCheckedAnyWork = mat['isWorking'];
                            }
                        });
                    });
                });
            }

            if (offerType['offerDiverseDetailList']) {
                offerType['offerDiverseDetailList'].forEach(offDiv => {
                    if (offDiv['isWorking']) {
                        this.isCheckedAnyWork = offDiv['isWorking'];
                    }
                });
            }
        });
        this.dataChange.emit(this.isCheckedAnyWork)
    }

    IsContainAnyArea(offerRoomDetailList) {
        let containArea: boolean = false;
        if (offerRoomDetailList) {
            offerRoomDetailList.forEach((ele) => {
                if (this.IsContainArea(ele['offerRoomCalculation'])) {
                    containArea = true;
                    return containArea;
                }
            });
        }
        return containArea;
    }

    IsContainArea(offerRoomCalculation) {
        let containArea: boolean = false;
        if (offerRoomCalculation) {
            offerRoomCalculation.forEach(ele => {
                if (ele['totalArea'] > 0) {
                    containArea = true
                    return containArea;
                }
            });
        }
        return containArea;
    }

    GetProjectRoomList() {
        this.showSpinner = true;
        this.paintTimerTotal = 0;
        this.offerTypeList = [];
        if (this.WorkHoursJsonData && this.WorkHoursJsonData.length > 0)
            this.WorkHoursJsonList = JSON.parse(this.WorkHoursJsonData)
        // else 
        //     this.WorkHoursJsonList = undefined
        this.projectofferService.GetAllofferTasksById(this.OfferID, this.ProjectID)
            .subscribe((response: ResponseBase) => {
                this.showSpinner = false;
                if (response.statusCode == 200) {
                    if (response.result && response.result['offerTypeList']) {
                        this.offerTypeList = Object.assign([], response.result['offerTypeList'])
                        if(this.ModuleName == "Planning" || this.ModuleName == "Project" || this.ModuleName == "Workhours")
                            this.offerTypeList = this.offerTypeList.filter( o => o.isConvertedProject == true);
                        this.offerTypeList.forEach(offerType => {
                            if (offerType['offerRoomDetailList']) {
                                offerType['offerRoomDetailList'].forEach(offerRoom => {
                                    offerRoom['offerRoomCalculation'].forEach(offerRCalc => {
                                        offerRCalc['materialList'].filter(mat => {
                                            mat.isWorking = false;
                                            if (this.ModuleName == "Workhours") {
                                                if (!this.WorkHoursJsonList) {
                                                    mat.isWorking = false;
                                                }
                                                else {
                                                    var selectedObj = this.WorkHoursJsonList.offerRoomDetailList.find(o => o.offerRoomId === offerRCalc['id'])
                                                    if (selectedObj){
                                                        mat.isWorking = selectedObj['isWorking'];
                                                        this.isCheckedAnyWork = true;
                                                    }
                                                    else
                                                        mat.isWorking = false;
                                                }
                                            }
                                            if (mat.offerMaterialId){
                                                //this.getMaterialPriceOnEdit(mat.offerMaterialId, mat, mat.offerMaterialPriceId);
                                                this.getMaterialRoomPriceOnEdit(mat.offerMaterialId, mat, mat.offerMaterialPriceId, offerRCalc)
                                            }
                                            else {
                                                mat['isDisabled'] = mat.isCompleted;                                                
                                            }
                                        })
                                    })
                                });
                            }

                            if (offerType['offerDiverseDetailList'] && offerType['offerDiverseDetailList'].length > 0) {
                                offerType['offerDiverseDetailList'].filter(diverseMat => {
                                    if (this.ModuleName == "Workhours") {
                                        if (!this.WorkHoursJsonList) {
                                            diverseMat.isWorking = false;
                                        }
                                        else {
                                            var selectedObj = this.WorkHoursJsonList.offerDiverseDetailList.find(o => o.diverseID == diverseMat['id'])
                                            if (selectedObj) {
                                                diverseMat.isWorking = selectedObj['isWorking'];
                                                this.isCheckedAnyWork = true;
                                            }
                                            else
                                                diverseMat.isWorking = false;
                                        }
                                    }
                                    if (diverseMat.materialID){
                                        this.getMaterialPriceOnEdit(diverseMat.materialID, diverseMat, diverseMat.materialPriceID);
                                    }
                                    else {
                                        diverseMat['isDisabled'] = diverseMat.isCompleted;
                                    }
                                })
                            }
                        });

                        setTimeout(() => {
                            this.offerTypeList_Orignal = JSON.stringify(this.offerTypeList);
                            if(this.offerTypeList.length==0)
                            this.dataChange.emit(true);
                            else
                            this.dataChange.emit(this.isCheckedAnyWork);
                        }, 0);
                    }
                }
            },
                error => (err) => {
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });
                });
    }

    GetMaterialType(i) {
        let type: string = '';
        if (i == 1) {
            this.translate.get("LABELS.WALL").subscribe({
                next: (res) => {
                    type = res
                    return res
                },
                error: err => {
                }
            });
        } else if (i == 2) {
            this.translate.get("LABELS.CEILLING").subscribe({
                next: (res) => {
                    type = res
                    return res
                },
                error: err => {
                }
            });
        } else if (i == 3) {
            this.translate.get("LABELS.FLOOR").subscribe({
                next: (res) => {
                    type = res
                    return res
                },
                error: err => {
                }
            });
        }
        return type;
    }

    getOfferMaterialData() {
        this.showSpinner = true;
        this.projectofferService.GetOfferCategoryMaterial().subscribe({
            next: (response: ResponseBase) => {
                this.materialObject = response.result.myResponse;
                this.GetProjectRoomList()
            },
            error: err => {
                this.showSpinner = false;
            },
            complete: () => {
                //this.showSpinner = false;
            }
        });

    }

    getMaterialPriceOnEdit(materialId, offerItem, materialPriceId) {
        var materialprice = this.materialObject.find(o => o.id == materialId);
        if (materialprice)
            materialprice.offerMaterialPriceList.filter(x => {
                if (x.id == materialPriceId) {
                    offerItem.reminder = x.reminder;
                    offerItem.extraValue = x.paintText;
                    var obj = this.SelectCategoryTime.find(o => o.id == x.type);
                    if (obj)
                        offerItem.key = obj.key;
                    
                    let timer = 0;
                    if (x.isAddMeterRangePrice) {
                        if (offerItem.materialPriceID) {
                            if (offerItem.qty >= x.startMeter1 && offerItem.qty <= x.endMeter1)
                                timer = x.timeMeter1;
                            else if (offerItem.qty >= x.startMeter2 && offerItem.qty <= x.endMeter2)
                                timer = x.timeMeter2;
                            else if (offerItem.qty >= x.startMeter3 && offerItem.qty <= x.endMeter3)
                                timer = x.timeMeter3;
                        }
                    }
                    else
                        timer = x.timeMeter;
                    offerItem.timer = timer;
                    this.paintTimerTotal += offerItem.qty ? (offerItem.qty * (offerItem.timer ? offerItem.timer : 0)) : 0;
                }
            })

    }

    createOrUpdateOfferList() {
        this.showSpinner = true;
        this.projectofferService.SaveOfferItemsList(this.offerTypeList).subscribe({
            next: (response) => {
                if (response) {
                    this.translate.get("MESSAGES.Success").subscribe({
                        next: (res) => {
                            this.notificationsService.success(res);
                        },
                        error: err => {
                        }
                    });
                    this.checkIsCompleted = false;
                    this.GetProjectRoomList()
                } else {
                    this.showSpinner = false;
                }
            },
            error: err => {
                this.showSpinner = false;
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            },
            complete: () => {
                //this.showSpinner = false;
            }
        });

    }

    projectWorkInfoChange(event) {
        console.log(event)
    }

    roomDetailList(offerRoomDetailList: any[], offerDiverseDetailList: any) {
        var workHourRoom = {
            offerRoomDetailList, offerDiverseDetailList
        }
        this.workhoursDataChanges.emit(workHourRoom);
    }

    SelectIsCompletedOffer($event, offerType) {
        this.projectWorkhoursIsCompleted.emit(offerType);
        if (this.ModuleName == 'Project') {
            const offerRoomDetailListForRoomOffer = offerType.offerRoomDetailList;
            const offerDiverseDetailListForRoomOffer = offerType.offerDiverseDetailList;
            let iscompledValueOffer: any[] = [];
            if (offerRoomDetailListForRoomOffer) {
                for (const offerTypeIsCompleted of offerRoomDetailListForRoomOffer) {
                    if (offerTypeIsCompleted.offerRoomCalculation) {
                        for (const offerRoomDetailsListOffer of offerTypeIsCompleted.offerRoomCalculation) {
                            const materialListOfffer = offerRoomDetailsListOffer.materialList;
                            if (materialListOfffer) {
                                iscompledValueOffer.push(...materialListOfffer.filter((material) => material.isCompleted === true));
                            }
                        }
                    }
                }
            }
            if (offerDiverseDetailListForRoomOffer) {
                var diverseListOffer = offerDiverseDetailListForRoomOffer.filter((DiversList) => DiversList.isCompleted === true);
            }
            this.checkIsCompleted = iscompledValueOffer.length !== 0 || diverseListOffer.length !== 0;
        }
    }

    getMaterialRoomPriceOnEdit(materialId, offerItem, materialPriceId, roomCalculationItem) {
        var materialprice = this.materialObject.find(o => o.id == materialId);
        if (materialprice)
            materialprice.offerMaterialPriceList.filter(x => {
                if (x.id == materialPriceId) {
                    offerItem.reminder = x.reminder;
                    offerItem.extraValue = x.paintText;
                    var obj = this.SelectCategoryTime.find(o => o.id == x.type);
                    if (obj)
                        offerItem.key = obj.key;

                    let timer = 0;
                    if (x.isAddMeterRangePrice) {
                        if (offerItem.offerMaterialPriceId) {
                            if (roomCalculationItem.totalArea >= x.startMeter1 && roomCalculationItem.totalArea <= x.endMeter1)
                                timer = x.timeMeter1;
                            else if (roomCalculationItem.totalArea >= x.startMeter2 && roomCalculationItem.totalArea <= x.endMeter2)
                                timer = x.timeMeter2;
                            else if (roomCalculationItem.totalArea >= x.startMeter3 && roomCalculationItem.totalArea <= x.endMeter3)
                                timer = x.timeMeter3;
                        }
                    }
                    else
                        timer = x.timeMeter;
                    offerItem.timer = timer;
                    this.paintTimerTotal += roomCalculationItem.totalArea ? (roomCalculationItem.totalArea * (offerItem.timer ? offerItem.timer : 0)) : 0;
                }
            })

    }

    paintTimerGrandTotal(offerRoomDetailList, offerDiverseDetailList) {
        let totalDiverseDetail = 0;
        let totalRoomDetail = 0;
        if (offerRoomDetailList && offerRoomDetailList.length !== 0) {
            for (const room of offerRoomDetailList) {
                for (const calculation of room.offerRoomCalculation) {
                    let roomTotalArea = 0;
                    if (calculation.totalArea > 0 && calculation.materialList[0].timer) {
                        roomTotalArea += calculation.totalArea;
                        var getValueofRoom = roomTotalArea * calculation.materialList[0].timer;
                        if (getValueofRoom > 0) {
                            totalRoomDetail += getValueofRoom;
                        }
                    }
                }
            }
        }

        if (offerDiverseDetailList && offerDiverseDetailList.length !== 0) {
            for (const diverseDetail of offerDiverseDetailList) {
                var getDiverseDetailQty = diverseDetail.qty;
                var getDiverseDetailTimer = diverseDetail.timer;
                if (getDiverseDetailQty && getDiverseDetailTimer) {
                    var product = getDiverseDetailQty * getDiverseDetailTimer;
                    totalDiverseDetail += product;
                }
            }
        }

        var convertMintoHour = totalDiverseDetail + totalRoomDetail;
        return convertMintoHour;
    }

    getOfferRoomforMin(offerRoom) {
        let totalMinutes = 0;
        for (const calculation of offerRoom.offerRoomCalculation) {
            if (calculation.materialList.length > 0 && calculation.materialList[0].timer) {
                const timerValue = calculation.materialList[0].timer;
                const totalAreaForCalculation = calculation.totalArea;
                const calculationResult = timerValue * totalAreaForCalculation;
                totalMinutes += calculationResult;
            }
        }

        return totalMinutes;
    }
    

    // convertToHoursAndMinutes(totalMinutes) {
    //     if (totalMinutes > 0) {
    //         const hours = Math.floor(totalMinutes / 60);
    //         const minutes = totalMinutes % 60;
    //         if (hours > 0 && minutes > 0) {
    //             return `${hours} hours ${minutes} min`;
    //         } else if (hours > 0) {
    //             return `${hours} hours`;
    //         } else {
    //             return `${minutes} min`;
    //         }
    //     }
    // }


    openMaterialList(){
        //offerType
        const materialOfferId = this.offerTypeList[0].offerId;
        const materialOfferTypeIds =  this.offerTypeList.map(obj => obj.id).join(','); //offerType.id;
        // const materialOfferId = offerType.offerId;
        // const materialOfferTypeIds = offerType.id;
        this.requestCalculatedOfferMaterialList.offerID = materialOfferId;
        this.requestCalculatedOfferMaterialList.offerTypeIDS = materialOfferTypeIds;
        //var Number =  offerType.numberOfHours; 
        const numberOfHours = this.offerTypeList.reduce((sum, current) => sum + parseFloat(current.numberOfHours), 0);
        const distance = this.offerTypeList.reduce((sum, current) => sum + parseFloat(current.distance), 0);
        const time = this.offerTypeList.reduce((sum, current) => sum + parseFloat(current.time), 0);
        const offerTypeList = this.offerTypeList;
        const dialogRef = this.dialog.open(CalculatedViewMaterialListComponent, {
            height: '90%',
            width:'90%',
            data:{
                "requestCalculatedOfferMaterialList" : this.requestCalculatedOfferMaterialList,
                //"showDriving": offerType,
                "offerTypeList": offerTypeList,
                "address": {
                    // Address: this.projectOffer.address,
                    // ZipCode: this.projectOffer.postNumber,
                    // City: this.projectOffer.city,
                    numberOfHours: numberOfHours,
                    distance: distance,
                    time: time,
                    autoSearchOnOpen: true,
                },
                "moduleName":"Project",
                "requisitionList" : this.RequisitionList
        }
        });
        dialogRef.afterClosed().subscribe(result => {
            // if (result) {
            // }
        });
    }

    openMaterialListSingle(offerType){
        const materialOfferId = offerType.offerId;
        const materialOfferTypeIds = offerType.id;
        this.requestCalculatedOfferMaterialList.offerID = materialOfferId;
        this.requestCalculatedOfferMaterialList.offerTypeIDS = materialOfferTypeIds;
        const numberOfHours =  offerType.numberOfHours; 
        const dialogRef = this.dialog.open(CalculatedViewMaterialListComponent, {
            height: '90%',
            width: '90%',
            data: {
                "requestCalculatedOfferMaterialList": this.requestCalculatedOfferMaterialList,
                //"showDriving": offerType,
                "address": {
                    numberOfHours: numberOfHours,
                    distance: offerType.distance,
                    time: offerType.time,
                    autoSearchOnOpen: true,
                },
                "moduleName":"Offer",
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            // if (result) {
            // }
        });
    }
}
