//#region Imports 
// angular 
import { Component, OnInit, Optional, Inject } from '@angular/core';

// third party tool
import { TranslateService } from '@ngx-translate/core';

// app-core
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';

// interfaces
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { environment } from 'environments/environment';
import { ZoomImgComponent } from './zoom-img/zoom-img.component';
//#endregion Imports 

@Component({
    selector: 'vibe-img-showcase',
    templateUrl: './vibe-img-showcase.component.html',
    styleUrls: ['./vibe-img-showcase.component.scss'],
})
export class VibeImgShowcaseComponent implements OnInit {
    //#region Initializations

    //#region @Input
    //#endregion @Input

    //#region @Output
    //#endregion @Output

    //#region @ViewChild
    //#endregion @ViewChild

    //#region Variables
    showSpinner: boolean = false;
    imgList: any[] = [];
    displayImageGallery: boolean = false;

    //#endregion Variables

    constructor(
        private translationLoader: FuseTranslationLoaderService,
        private translate: TranslateService,
        public dialog: MatDialog,
        @Optional() public dialogRef: MatDialogRef<VibeImgShowcaseComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.translationLoader.loadTranslations(english, danish);
    }
    //#endregion  Initializations



    //#region Private/Helper Methods
    //#endregion Private/Helper Methods



    //#region Public Methods
    onCloseDialog() {
        this.dialogRef.close(null)
    }
    onZoomImg(img) {
        const dialogRef = this.dialog.open(ZoomImgComponent, {
            width: '70%',
            height: '90%',
            data: { ImgPath: img }
        });
        dialogRef.afterClosed().subscribe(result => {
        });
    }
    //#endregion Public Methods



    //#region Lifecycle Hooks
    ngOnInit() {
        if (this.data && this.data['Imgs']) {
            JSON.parse(this.data['Imgs']).forEach(imgPath => {
                this.imgList.push(environment.url + imgPath)
            });

            if (this.imgList.length) {
                this.displayImageGallery = true;
            }
        }
    }
    //#endregion Lifecycle Hooks
}
