export const locale = {
    lang: 'ds',
    data: {
        'TITLE': {
            'EmailSendConfigHeader': 'e-mail konfiguration',
        },
        'FIELDS': {
            'ESCIsTimeBased':'Er tid baseret ?',
            'ESCIsSendEmail': 'Er Send e-mail ?',
            'ESCHoursFromSend': 'Fra kl.',
            'ESCHoursToSend': 'Til kl.',            
        },
        'VALIDATIONS': {
            'ESCHoursFromSend': 'Timer fra afsendelse er påkrævet',
            'ESCHoursFromSendSmaller': 'Timer fra afsendelse skal være mindre end time til afsendelse',
            'ESCHoursToSend': 'Timer at sende er påkrævet',
            'ESCHoursToSendGreater': 'Timer til afsendelse skal være længere end time fra afsendelse',
        },
        'MESSAGES': {
            'Saved': 'Gemt succesfuldt.',
        },
        'BUTTONS': {
            'ESCSave': 'Gem',
        }
    }
};
