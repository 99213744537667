import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { MatDialog , MatOption} from '@angular/material';
import { AuthService } from 'app/core/services/auth.service';
import { SettingsService } from '../../settings.service';
import { ResponseBase } from "app/main/model/ResponseBase";
import { Settings } from "app/main/model/Settings";
import { yesOrNoOptions, ProjectIndoorOutDoorStatus } from 'app/main/model/ProjectStatus';
import { ProjectStatus, ProjectStatusForMaler, PurchaseStatus } from 'app/constant/common.constant';
import { ProjectHoursDetails } from "app/main/pages/project/component/project-hours-details/project-hours-details";

@Component({
    selector: "vibe-calculate-project-price-status-settings",
    templateUrl: "./calculate-project-price-status.component.html",
    styleUrls: ["./calculate-project-price-status.component.scss"]
})

export class CalculateProjectPriceStatusSettingsComponent implements OnInit {
    showSpinner: boolean = false;
    @Input() projectSettings: any;
    CoworkerPriceOnProject: number = 0;
    calculateProjectPriceStatusObj: Settings;
    @ViewChild('allStatusSelected', { static: false }) private allStatusSelected: MatOption;
    @ViewChild('allIndoorOutdoorStatusSelected', { static: false }) private allIndoorOutdoorStatusSelected: MatOption;
    selectIndoorOutDoorStatus = ProjectIndoorOutDoorStatus;
    projectStatus = ProjectStatus;
    currentselectedStatusObj: number[];
    currentselectedIndoorOutdoorObj: number[];
    //roleName: string;
    calculateProjectPriceStatus: any ={};
    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private authService: AuthService,
        private settingsService: SettingsService,
        private dialog: MatDialog) {
        this.translationLoaderService.loadTranslations(danish, english);
    }

    ngOnInit() {
        if (this.projectSettings && this.projectSettings.length > 0) {
            var tempSettingObject = this.projectSettings.find(s => s.key1 == "Project" && s.key2 == "CalculateProjectPriceStatus");
            if (tempSettingObject) {
                this.currentselectedStatusObj = [];
                this.currentselectedIndoorOutdoorObj = [];
                this.calculateProjectPriceStatusObj = tempSettingObject;
                this.calculateProjectPriceStatus = JSON.parse(tempSettingObject.value);
                this.setProjectPriceStatus();
            }
        }
        else
            this.getSettingsForCalculateProjectPriceStatus();
        //this.roleName = this.authService.getUserRole();
    }

    getSettingsForCalculateProjectPriceStatus() {
        this.showSpinner = true;
        this.settingsService.GetSettingsValue<ResponseBase>("Project", "CalculateProjectPriceStatus",null).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.result && response.result.myResponse.length > 0) {
                    if(response.result.myResponse[0].value) {
                        this.currentselectedStatusObj = [];
                        this.currentselectedIndoorOutdoorObj = [];
                        this.calculateProjectPriceStatusObj = response.result.myResponse[0];
                        this.calculateProjectPriceStatus = JSON.parse(response.result.myResponse[0].value);
                        this.setProjectPriceStatus();
                    }
                    else
                        this.calculateProjectPriceStatus = {};
                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    changeSettingsForCalculateProjectPriceStatus() {
        this.showSpinner = true;
        this.calculateProjectPriceStatus.requistionStatus = this.currentselectedStatusObj.join().toString();
        this.calculateProjectPriceStatus.projectIndoorOutdoorStatus = this.currentselectedIndoorOutdoorObj.join().toString();
        this.calculateProjectPriceStatusObj.value = JSON.stringify(this.calculateProjectPriceStatus);
        this.settingsService.UpdateSettingValue<ResponseBase>(this.calculateProjectPriceStatusObj).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.result) {
                    this.calculateProjectPriceStatus = JSON.parse(response.result.myResponse[0].value);
                    // if (this.calculateProjectPriceStatus.projectIndoorOutdoorStatus) {
                    //     var obj = this.calculateProjectPriceStatus.projectIndoorOutdoorStatus.split(",");
                    //     for (var i = 0; i < obj.length; i++) {
                    //         this.currentselectedIndoorOutdoorObj.push(Number(obj[i]));
                    //     }
                    // }
                    // if (this.calculateProjectPriceStatus.requistionStatus) {
                    //     var statusObj = this.calculateProjectPriceStatus.requistionStatus.split(",");
                    //     for (var i = 0; i < statusObj.length; i++) {
                    //         this.currentselectedStatusObj.push(Number(statusObj[i]));
                    //     }
                    // }
                    this.setProjectPriceStatus();
                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    toggleAllStatusIndoorOutDoorSelection() {
        this.currentselectedIndoorOutdoorObj = [];
        if (this.allIndoorOutdoorStatusSelected.selected) {
            for (var i = 0; i < 7; i++) {
                this.currentselectedIndoorOutdoorObj.push(i);
            }
        }
    }

    toggleAllStatusSelection() {
        this.currentselectedStatusObj = [];
        if (this.allStatusSelected.selected) {
            // if (this.roleName == "Maler") {
            //     for (var i = 0; i < 13; i++) {
            //         if (i != 9 && i != 12) {
            //             this.currentselectedStatusObj.push(i);
            //         }
            //     }
            // }
            // else {
                for (var i = 0; i < 13; i++) {
                    this.currentselectedStatusObj.push(i);
                }
            //}
        }
    }
    setProjectPriceStatus(){
        if(this.calculateProjectPriceStatus.projectIndoorOutdoorStatus){
            var obj = this.calculateProjectPriceStatus.projectIndoorOutdoorStatus.split(",");
            for (var i = 0; i < obj.length; i++) {
                this.currentselectedIndoorOutdoorObj.push(Number(obj[i]));
            }
        }
        if(this.calculateProjectPriceStatus.requistionStatus){
            var statusObj = this.calculateProjectPriceStatus.requistionStatus.split(",");
            for (var i = 0; i < statusObj.length; i++) {
                this.currentselectedStatusObj.push(Number(statusObj[i]));
            }
        }
    }

    ShowProjectHoursDetailsList() {
        const dialogRef = this.dialog.open(ProjectHoursDetails, {
            height: "90%",
            width: "90%",
            data: {
                status: this.currentselectedStatusObj.join().toString(),
                indoorOutdoorStatus: this.currentselectedIndoorOutdoorObj.join().toString(),
                isIncludeTurnover: true
            }
        });
        dialogRef.afterClosed().subscribe(result => {
        });
    }
}