export const locale = {
    lang: 'ds',
    data: {
        'Headers': {
            'QuestionList': 'Spørg kunden om årsagen til afvisning:'
        },
        'BUTTONS': {
            'NEWPROJECTOFFER': 'Nyt Sag tilbud',
            'AddQuestion': 'Tilføj spørgsmål',
            'Save': 'Gem',
            'Cancel': 'Gå tilbage'
        },
        'FIELDS': {
            'Question': 'Spørgsmål',
            'SrNo': 'Sr nr.',
            'Type': 'Type',
            'Answer':'Svar'
        },
        'VALIDATIONS': {
            'QuestionRequired': 'Spørgsmål påkrævet'
        },
        'MESSAGES': {
            'NoQuestionAnswerFound': 'Ingen Spørgsmål Fundet.',
            'Created': 'Oprettet med succes',
            'Updated': 'Opdateret med succes',
            'Deleted': 'Slettet med succes',
            'Failed': 'Operation mislykket, prøv senere',
            'HideAnswer': 'Skjul svar'
        },
    }
}