import { Component, Input, OnInit } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { MatDialog , MatOption} from '@angular/material';
import { AuthService } from 'app/core/services/auth.service';
import { SettingsService } from '../../settings.service';
import { ResponseBase } from "app/main/model/ResponseBase";
import { Settings } from "app/main/model/Settings";

@Component({
    selector: "vibe-project-economy-settings",
    templateUrl: "./project-economy.component.html",
    styleUrls: ["./project-economy.component.scss"]
})

export class ProjectEconomySettingsComponent implements OnInit {
    showSpinner: boolean = false;
    @Input() projectSettings: any;
    CoworkerPriceOnProject: number = 0;
    CoworkerPriceOnProjectObj: Settings;
    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private authService: AuthService,
        private settingsService: SettingsService,
        private dialog: MatDialog) {
        this.translationLoaderService.loadTranslations(danish, english);
    }

    ngOnInit() {
        if (this.projectSettings && this.projectSettings.length > 0) {
            var tempSettingObject = this.projectSettings.find(s => s.key1 == "Project" && s.key2 == "ProjectsCoworkerPrice");
            if (tempSettingObject) {
                this.CoworkerPriceOnProjectObj = tempSettingObject;
                this.CoworkerPriceOnProject = Number(tempSettingObject.value);
            }
        }
        else
            this.getSettingsForProjectEconomyDetails();
    }

    getSettingsForProjectEconomyDetails() {
        this.showSpinner = true;
        this.settingsService.GetSettingsValue<ResponseBase>("Project", "ProjectsCoworkerPrice",null).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.result && response.result.myResponse.length > 0) {
                    this.CoworkerPriceOnProjectObj = response.result.myResponse[0];
                    this.CoworkerPriceOnProject = Number(response.result.myResponse[0].value);
                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    changeSettingsForProjectEconomyDetails() {
        this.showSpinner = true;
        this.CoworkerPriceOnProjectObj.value = this.CoworkerPriceOnProject ? this.CoworkerPriceOnProject.toString() : "";
        this.settingsService.UpdateSettingValue<ResponseBase>(this.CoworkerPriceOnProjectObj).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.result) {
                    //this.CoworkerPriceOnProjectObj = response.result.myResponse[0];
                    this.CoworkerPriceOnProjectObj = response.result;
                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }
}