import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';

@Component({
  selector: 'app-coworker-assign-popup',
  templateUrl: './coworker-assign-popup.component.html',
  styleUrls: ['./coworker-assign-popup.component.scss']
})

export class CoworkerAssignPopupComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<CoworkerAssignPopupComponent>,
    private translationLoader: FuseTranslationLoaderService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.translationLoader.loadTranslations(english, danish);
   }

  ngOnInit() {
  }

  onCloseDialog(e) {
    this.dialogRef.close(e ? true : false)
 }

}
