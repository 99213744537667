import { Component, OnInit } from '@angular/core';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { SettingsService } from '../../settings.service';
import { Settings } from "app/main/model/Settings";

@Component({
  selector: 'app-credintials',
  templateUrl: './credintials.component.html',
  styleUrls: ['./credintials.component.scss']
})
export class CredintialsComponent implements OnInit {

  showSpinner: boolean = false;
  credintialSettingObj : Settings[];
    credintialEmailSettingObject: Settings;
    credintialInvoiceEmailSettingObject: Settings;
    credintialSMSSettingObject: Settings;
    credintialUnicontaSettingObject: Settings;

  constructor(private settingsService: SettingsService,) { }

  ngOnInit() {
   this.getCredintialTab();
  }

  getCredintialTab() {
    this.showSpinner = true;
    this.settingsService.GetSettingsWithMappingValue<ResponseBase>("Credential", null, null, true).subscribe({
        next: (response: ResponseBase) => {
            this.showSpinner = false;
            if (response != null && response.result && response.result.myResponse.length > 0) {
              this.credintialSettingObj = response.result.myResponse;
              this.credintialSettingObj.forEach(element => {
                if (element.key2 == 'SMS') {
                    this.credintialSMSSettingObject = element;
                }
                if(element.key2 == 'Email'){
                    this.credintialEmailSettingObject = element
                }
                  if (element.key2 == 'ReadEmailInvoice') {
                      this.credintialInvoiceEmailSettingObject = element
                  }
                  if (element.key2 == 'Uniconta') {
                      this.credintialUnicontaSettingObject = element
                  }
            });
            }
        },
        error: err => {
        },
        complete: () => {
            this.showSpinner = false;
        }
    });
  }
}
