export const locale = {
    lang: 'en',
    data: {
        'Header': {
            "Title": "Color Code"
        },
        "FIELDS": {
            "ColorCode": "Color Code"
        },
        "BUTTONS": {
            "Add": "Add"
        },
        "MESSAGES": {
            'Updated': 'Updated Successfully.',
            'Failed': 'Failed',
            'Deleted': 'Deleted successfully',
            "NORECORD": "No Record Found"
        }
    }
}