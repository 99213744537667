export const locale = {
    lang: 'ds',
    data: {
        'TOOTIPORTITLE':{
            'AddChangeProfilePicture':'Tilføj/Skift Billede',
            //'ChangeProfilePicture':'Skift Profil Billede',
            'RemoveProfilePicture':'Fjern Billede',
        }
    }
};
