export const locale = {
  lang: 'en',
  data: {
    'MESSAGES': {
      'Add': 'Added successfully',
      'Add_Error': 'No record found on Vagttider or Vagtplan table, please contact to administrator.',
      //'Update' : 'Updated successfully'
      'statusMsg': 'you can not save hour on requisition',
      'CanNotAddHour': 'Requisition status is not in Started,Performed,Ready billing, you can not add work hour on this requisition.',
      'Updated': 'Updated successfully',
      'Failed': 'Operation failed , try later',
      'TimeAlreadyAllocated': 'Selected time is already allocated to co worker.'
    },
    'Button': {
      'Ok': 'Ok / Save',
      'Cancel': 'Cancel',
      'Showdailyreport': 'Show daily report'
    },
    'Fields': {
      'SetTimer': 'Add Time',
      'EnterStarttime': 'Enter Start time',
      'EndTime': 'Enter End Time',
      'Text': 'Text',
      'Week': 'Week',
      'DayHoursTotal': 'Day wise total',
      'SR.No': 'No',
      'Startime': 'Startime',
      'Endtime': 'Endtime',
      'Total': 'Total',
      'Project_Number': 'Project no.',
      'Requisition': 'Requisition',
      'Status': 'Status',
      'Pause': 'Pause',
      'OpgaveTekst': 'Task',
      'breakTime': 'Add pause (30 min)',
      'WorkingHours': 'Working hours',
      'Date': 'date'
    },
    'Header': {
      'DailyReport': 'Daily Report',
      'Youdidnotaddanyentry': 'You did not add any entry yet.',
      'Add7to1130': 'Add 07:00 to 11:30',
      //'Add7to01': 'Add 07:00 to 13:00',
      'Add7to0230': 'Add 07:00 to 14:30',
      'Add7to03': 'Add 07:00 to 15:00',
        'AddHoursManually': 'Add Hours Manually',
        'ProjectWorkInfo': 'Project Work Info',
    },
    'selectedWorkHourStatus': {
      'value1': 'Vacation ',
      'value2': 'Sick',
      'value3': 'Holiday of the work ',
      'value4': 'Holiday with payment',
      'value5': 'Holiday without payment ',
      'value6': 'School or learning',
      'value7': 'No payment for extra work but holiday for it ',
    },
    // 'selectedValueStatus': {
    //   'value0': 'Registered',
    //   'value1': 'Must be contacted',
    //   'value2': 'Scheduled visit',
    //   'value3': 'Do not answer',
    //   'value4': 'Offers sent',
    //   'value5': 'Offer approved',
    //   'value6': 'Planned',
    //   'value7': 'Offer rejected',
    //   'value8': 'Started',
    //   'value9': 'Performed',
    //   'value10': 'Billed',
    //   'value11': 'Pending',
    //   'value12': 'Ready billing',
    // },
    'Days': {
      'Monday': 'Monday',
      'Tuesday': 'Tuesday',
      'Wednesday': 'Wednesday',
      'Thursday': 'Thursday',
      'Friday': 'Friday',
      'Saturday': 'Saturday',
      'Sunday': 'Sunday'
    }
  }
};
