export const locale = {
    lang: 'ds',
    data: {
        'Headers': {
            'OfferRoomLogDetails': 'Tilbud rum log detaljer'
        },
        'FIELDS': {
            'Detail': 'Detalje',
            'ChangedDetail': 'Ændret detalje',
            'RoomName': 'Rum navn',
            'Width': 'Bredde',
            'Length': 'Længde',
            'CeilingHeight': 'Lofthøjde',
            'Active': 'Indstil som inaktiv',
            'ExtraPrice1': 'Ekstra pris 1',
            'ExtraPrice2': 'Ekstra pris 2',
            'ExtraQty1': 'Ekstra antal 1',
            'ExtraQty2': 'Ekstra antal 2',
        },
        'IsActive': {
            'value1': 'Aktiv',
            'value0': 'Inaktiv',
        }
    }
}