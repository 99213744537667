export const locale = {
  lang: 'en',
  data: {
    'MESSAGES': {
      'Created': 'Created successfully',
      'Updated': 'Updated successfully',
      'Deleted': 'Deleted successfully',
      'Failed': 'Operation failed , try later',
    },
    'BUTTONS': {
      'NEWCOWORKER': 'New Co-worker',
    },
    'FIELDS': {

    },
  }
};

