import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { TitleService } from 'app/core/services/title.service';
import { AuthService } from 'app/core/services/auth.service';
import { QuickInvoice } from 'app/main/model/Material';
import { MatDialog } from '@angular/material';
import { ViewChild } from '@angular/core';
import { MaterialService } from 'app/main/pages/material/material.service';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { Customer } from 'app/main/model/Customer';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { PdfViewerModelComponent } from 'app/main/pages/pdf-viewer/pdf-viewer.component';

@Component({
    selector: 'vibe-quickinvoice-print',
    templateUrl: './printinvoice.component.html',
    styleUrls: ['./printinvoice.component.scss']
})
export class VibeQuickInvoicePrintComponent implements OnInit {
    @ViewChild('externalPdfViewer', {static: false}) public externalPdfViewer;
    // roleName: string = "";
    // isValid: boolean = false;
    quickInvoice: QuickInvoice;
    showSpinner: boolean = false;
    customer: Customer;
    @Input() selectedInvoice: any;
    @Output() saveAndExits = new EventEmitter();
    downloadFileName: string = "";

    constructor(private _formBuilder: FormBuilder,
        private translationLoader: FuseTranslationLoaderService,
        private translate: TranslateService,
        public dialog: MatDialog,
        private notificationsService: NotificationsService,
        private authService: AuthService, private titleService: TitleService, private materialService: MaterialService) {
        this.translationLoader.loadTranslations(english, danish);
        // this.titleService.SetTitle("TITLE_STORE_SALES");
    }

    ngOnInit() {
        // this.roleName = this.authService.getUserRole();

        // if (this.roleName == "Admin") {
        //     this.isValid = true;
        // }
        // else if (this.roleName == "Kontor") {
        //     this.isValid = true;
        // }
        // else if (this.roleName == "Maler") {
        //     this.isValid = false;
        // }
        // else if (this.roleName == "Gaest") {
        //     this.isValid = false;
        // }
        // else {
        //     this.isValid = true;
        // }
        this.customer = this.selectedInvoice.customer;
        this.quickInvoice = this.selectedInvoice.quickInvoice;
    }

    Print() {
        let isDisplayPersonalPrice =  this.quickInvoice.indoorOutdoorStatusId == "5" ? true : false;
        this.materialService.GetQuickInvoiceFile<ResponseBase>(this.quickInvoice.projectId, this.quickInvoice.requisitionId, isDisplayPersonalPrice)
            .subscribe((response: ResponseBase) => {
                var data = response.result.base64.replace("data:application/pdf;base64,", "");
                this.showSpinner = false;
                const dialogRef = this.dialog.open(PdfViewerModelComponent, {
                    height: '90%',
                    width: '90%',
                    data: {
                        base64: data,
                        filename: response.result.filename + ".pdf"
                    }
                });

                dialogRef.afterClosed().subscribe(result => {
                    if (result) {
                    }
                });
            },
                error => (err) => {
                    this.showSpinner = false;
                },
                () => {
                });
    }

    Email() {
        let isDisplayPersonalPrice =  this.quickInvoice.indoorOutdoorStatusId == "5" ? true : false;
        this.materialService.SendQuickInvoiceEmailWithFile<ResponseBase>(this.quickInvoice.projectId, this.customer.email, isDisplayPersonalPrice)
            .subscribe((response: ResponseBase) => {
                this.translate.get("MESSAGES.Success").subscribe({
                    next: (res) => {
                        this.notificationsService.success(res);
                    },
                    error: err => {
                    }
                });
                this.showSpinner = false;
            },
                error => (err) => {
                    this.showSpinner = false;
                },
                () => {
                });
    }

    PrintAndEmail() {
        this.Print();
        this.Email();
    }
    SaveAndExits() {
        this.saveAndExits.emit();
    }
}


