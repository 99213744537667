export const locale = {
  lang: 'ds',
  data: {
    'MESSAGES': {
      'CommingSoon': 'Kommer snart..',
      'Failed': 'Noget gik galt !',
      'DataNotFound': 'Data ikke fundet !',
      'Success': 'Succes.',
    },
    'LABELS': {
      'OfferRoomDetailList': 'Tilbudsværelse detaljeliste',
      'OfferDiverseDetailList': 'Tilbyd forskellig detaljeliste',
      'WALL': 'VÆG',
      'CEILLING': 'LOFT',
      'FLOOR': 'ETAGE',
      'Length': 'Længde',
      'Width': 'Bredde',
      'CeilingHeight': 'Loftshøjde',
      'TotalArea': 'Samlet areal',
      'ColorCode': 'Farvekode',
      'Room': 'Rum',
      'Quantity': 'Antal',
      'TotalHours': 'Samlede timer',
      'RemainingHours': 'Resterende timer',
      'PaintHours': 'Malings timer',
      'Unit': 'Enhed',
      'Time': 'Antal gange',
      'Timer-Min': 'Timer / Min'
    },
    'Button': {
      'Save': 'Gem',
    },
    'TOOTIPORTITLE':{
      'IsCompleted':'Er Afsluttet',
      'IsWorking':'Arbejder',
      'ViewMaterial':'Se materiale'
    }
  }
}; 
