export const locale = {
    lang: 'ds',
    data: {
        'MESSAGES': {
            'Created': 'Oprettet med succes',
            'Updated': 'Opdateret med succes',
            'Deleted': 'Slettet med succes',
            'Failed': 'Operation mislykket, prøv senere',
            'NORECORD': 'Ingen registrering fundet',
            'StatusExits': 'denne status findes allerede',
            'NotificationNotes':'*** Bemærk @CustomerName er kundenavn. @ProjectAmount er projektbeløb. @ProjectAmountPersonale er Projektbeløb Personligt. @ProjectNumber er projektnummer'
        },
        'BUTTONS': {
            'Save': 'Gem',
            'Reset': 'Nulstil',
            'Cancel': 'Gå tilbage',
            'Add': "Tilføj",
            'Back': 'Tilbage',            
        },
        'FIELDS': {
            'Status': 'Status',
            'Not-Found-Status': 'Status ikke fundet',
            'Message': 'Besked',
            'EnterMessage': 'indtast besked',
            'AudienceType': 'Medarbejder / Debitor',
            'IndoorOutDoor': 'Ind, ud, Sprøjt, Reklamation *',
            'HoursToSend': 'Send kl.',
            'PercentageToSend': 'Send x procent før',
            'SMSText': 'SMS tekst',
            'Description': 'Beskrivelse',
            'EmailText': 'E-mail tekst',
            'DragAndDropFile': 'Træk og slip filen her',
            'Or': 'Eller',
            'BrowseForFile': 'Søg efter fil',
            'PleaseSelectFromBelow': 'Vælg venligst nedenstående',
            'StartDate': 'Vælg startdato*',
            'EndDate': 'Vælg slutdato*',
            'WeekDays': 'Hverdage',
            'Days': 'dage',
            'WeekNo': "Uge nr",
            'NotificationText': 'Notifikations tekst',
            "Subject":"Indtast emne"
        },
        'Lable': {
            'SRNO': 'Sr nr.',
            'Message': 'Besked',
            'Status': 'Status',
            'AudienceType': 'Medarbejder / Debitor'
        },
        'Headers': {
            'StatusList': 'Status liste',
        },
        'Validators': {
            'SelectStatus': 'Vælg Status',
            'SelectAudienceType': 'Vælg Medarbejder / Debitor',
            'SelectIndoorOrOutdoor': 'Vælg en hvilken som helst',
            'HoursToSend': 'Timer at sende er påkrævet',
            'PercentageToSend': 'procent at sende er påkrævet',
            'SMSText': 'SMS-tekst er påkrævet',
            'Description': 'Beskrivelse er påkrævet',
            'EmailText': 'E-mail-tekst er påkrævet',
            'StartDate': 'Startdato er påkrævet',
            'EndDate': 'Slutdato er påkrævet',
            'ExecuteTime': "Udførelsestid er påkrævet",
            'NotificationText': 'Notifikations tekst er påkrævet',
        },
    }
};

