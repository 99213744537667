import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { environment } from 'environments/environment';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'app-image-gallery-popup',
    templateUrl: './image-gallery-popup.component.html',
    styleUrls: ['./image-gallery-popup.component.scss']
})
export class ImageGalleryPopupComponent implements OnInit {
    galleryImagesLoad: any[];
    imagePath: string = "";
    constructor(private _route: ActivatedRoute,
        private translationLoader: FuseTranslationLoaderService,
        private translate: TranslateService,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
        this.galleryImagesLoad = this.data && this.data.galleryImagesLoad ? this.data.galleryImagesLoad : [];
        if(this.data && this.data.imagePath)
            this.imagePath = environment.url + this.data.imagePath;
    }

}
