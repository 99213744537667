export const locale = {
    lang: 'ds',
    data: {
        'FIELDS': {
            'SRNO': 'Sr nr.',
            'Status': 'Status',
            'Number': 'Nummer',
            'Name': 'Navn',
            'CreatedBy': 'OprettetAf',
            'CreatedDate': 'OprettetDato',
            'ModifyBy': 'ModificereAf',
            'ModifyDate': 'ModificereDato',
            'CoworkerName': 'Medarbejder',
            'Date': 'Dato',
            'Description': 'Tekst',
            'MaterialDetail': 'Materiale detalje',
            'RequisitionDetail': 'Rekvisitions Detaljer',
            'ColorCode': 'Farvekode',
            'Price': 'Pris',
            'QTY': 'Antal',
            'StartDate': 'Start Dato',
            'EndDate': 'Slut Dato',
            'Coworker': 'Medarbejder',
            'TotalPrice': 'Total pris',
            'TotalQTY': 'Samlet antal',
        },
        'MESSAGES': {
            'NORECORD': 'Ingen registrering fundet.',
        },
        'BUTTONS': {
            'BACK': 'Gå tilbage',
        },
        'FILTERS': {
            'BADGE': 'Inaktive',
            'MtoM': 'M til M',
            'Note': 'Bemærk',
            'Offers': 'Tilbud',
            'TEXTSEARCH': 'Søg efter, navn, nummer',
            'SORTING': 'Sorter efter',
        },
        'SORTBY':
            [
                {
                    Text: 'Adresse (A-Z)',
                    Value: 'Address ASC'
                },
                {
                    Text: 'Adresse (Z-A)',
                    Value: 'Address DESC'
                },
                {
                    Text: 'Oprettet dato ↑',
                    Value: 'CreatedDate ASC'
                },
                {
                    Text: 'Oprettet dato ↓',
                    Value: 'CreatedDate DESC'
                }
            ]
    }
};