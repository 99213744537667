import { Component, OnInit, ElementRef, NgZone, ViewChild, Input, Inject, Optional,forwardRef, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'vibe-next-previous',
  templateUrl: './next-previous.component.html',
  styleUrls: ['./next-previous.component.scss'],
})

export class NextPreviousComponent implements OnInit {
  @Input() moduleData: any = [];
  @Input() moduleId: any = "";
  @Output() gotoModule = new EventEmitter<any>();
  currentId: any = "";
  previousId: any = "";
  nextId: any = "";
  tempId: any = "";
  constructor(private translationLoader: FuseTranslationLoaderService,
    private _router: Router,
    private cdr: ChangeDetectorRef) {
      this.translationLoader.loadTranslations(english, danish);
  }

  ngOnInit() {
    this.tempId = this.moduleId;
    this.getSetNextPreviousId();
  }

  ngAfterContentChecked() {
      this.cdr.detectChanges();
  }

  goto(id, $event) {
    if (this.moduleData) {
        this.tempId = id;
        this.currentId = this.tempId;
        this.getSetNextPreviousId();
        if (this.moduleId != this.tempId)
          this.gotoModule.emit(this.tempId);
    }
  }

  getSetNextPreviousId() {
      this.currentId = this.tempId;
      var itemIndex = this.moduleData.map(function (o) { return o.id; }).indexOf(this.currentId);
      if (itemIndex > 0)
          this.previousId = this.moduleData[itemIndex - 1].id;
      else
          this.previousId = null;

      if (itemIndex != (this.moduleData.length - 1))
          this.nextId = this.moduleData[itemIndex + 1].id;
      else
          this.nextId = null;
  }
}





