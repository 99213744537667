import { Component, Inject, OnInit } from "@angular/core";
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { ProjectofferService } from 'app/main/pages/project-offer/project-offer.service';
import { MAT_DIALOG_DATA } from '@angular/material';

//translate
import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { ResponseBase } from 'app/main/model/ResponseBase';

@Component({
    selector: "offer-room-lod-detail",
    templateUrl: "./offer-room-log-detail.component.html",
    styleUrls: ["./offer-room-log-detail.component.scss"]
})

export class OfferRoomLogDetailComponent implements OnInit {
    showSpinner: boolean = false;
    offerRoomLogsDetail: any = [];
    oldItem: any = {};
    newItem: any = {};

    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private translate: TranslateService,
        private projectofferService: ProjectofferService,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.translationLoaderService.loadTranslations(danish, english);
    }

    ngOnInit() {
        this.getOfferRoomLogDetails(this.data)
    }

    getOfferRoomLogDetails(id) {
        this.projectofferService.GetOfferRoomLogDetail<ResponseBase>(id)
            .subscribe({
                next: (response: ResponseBase) => {
                    if (response != null && response.result) {
                        this.offerRoomLogsDetail = response.result;
                        this.oldItem = response.result.length > 1 ? response.result[1] : [];
                        this.newItem = response.result[0];
                    }
                    else {
                        this.offerRoomLogsDetail = [];
                    }
                },
                error: err => {
                    this.showSpinner = false;
                    this.offerRoomLogsDetail = [];
                },
                complete: () => { }
            });
    }

    getIsActive(status) {
        var statusField = '';
        if (status) {
            statusField = "IsActive.value1"
        } else {
            statusField = "IsActive.value0"
        }
        return statusField;
    }
}