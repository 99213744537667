export const locale = {
    lang: 'ds',
    data: {
        'BUTTONS': {
            'BTNSAVE': 'Gem',
            'Back': 'Gå tilbage'
        },
        'FIELDS': {
            'SrNo': 'Sr-nr.',
            'Date': 'Dato',
            'SupplierName': 'Leverandør Navn',
            'MaterialCount': 'Materiale Tælle',
            'Amount': 'Beløb',
            'Status': 'Status',
            'Email': 'E-mail',
            'Attachment': 'Vedhæftet fil',

            'Material': 'Materiale',
            'RetailPrice': 'Udsalgs pris',
            'PersonalPrice': 'Personal Pris',
            'PersonalTotalPrice': 'Personal i alt',
            'Quantity': 'Antal',
            'ProductNo': 'Vare Nr',
            'OrderQuantity': 'Bestille Antal',
            'MinQuantity': 'Min Antal',
            'MaxQuantity': 'Max Antal',
            'Unit': 'Enhed',
            'PurchasePrice': 'Indkøbs Pris',
            'TotalPurchasePrice': 'I alt Indkøbs Pris',
            'Total': 'I alt',
            'Inventory': 'Beholding',
            'MinInventory': 'Min Beholding',
            'MaxInventory': 'Max Beholding',
            'PurchaseQuantity': 'Indkoebs maengde',
            'ShowAll': 'Vis alle',
            'ColorCode': 'Farve kode',
            'ReceivedDate': 'Modtaget Dato',
            'ProjectNumber': 'Sag Nummer',
            'RequisitionNo': 'Rekvisition Nr.',
        },
        'MESSAGES': {
            'Updated': 'Opdateret succesfuldt.',
            'Failed': 'mislykkedes',
        },
        "SupplierList": {
            'value1': 'Bestilt',
            'value2': 'Modtaget',
            'value3': 'Delvist Modtaget',
            'value4': 'Delete',
        },
        "Headers": {
            'NORECORD': 'Intet optage fundet'
        }
    }
}