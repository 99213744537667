import { Injectable } from '@angular/core';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiConstant } from 'app/core/constant/ApiConstant';
import { Observable } from 'rxjs/Observable';
import { CompanyInfoSetting } from 'app/main/model/CompanyInfo';

@Injectable({
  providedIn: 'root'
})

export class SuppliersService {
    private actionUrl: string;
    private unicontaUrl: string;

  constructor(private http: HttpClient) {
      this.actionUrl = ApiConstant.API_URL + ApiConstant.SUPPLIERS + "/";
      this.unicontaUrl = ApiConstant.API_URL + ApiConstant.UNICONTA + "/";
  }

  public Get<T>(RequestSupplier: any): Observable<T> {
    let search = this.GetParams(RequestSupplier);
    return this.http.get<T>(this.actionUrl, {
      params: search
    });
  }
  public GetSupplierById<T>(Id: any): Observable<T> {
    return this.http.get<T>(this.actionUrl + Id);
  }

    public GetGLAccountList<T>(): Observable<T> {
        return this.http.get<T>(this.unicontaUrl +"GetGLAccountList");
    }

  public Update<T>(id: any, supplier: any): Observable<T> {
    return this.http.put<T>(this.actionUrl + id, supplier);
  }

  public Add<T>(supplier: any): Observable<T> {
    return this.http.post<T>(this.actionUrl, supplier);
  }

  public GetParams(param) {
    let params: HttpParams = new HttpParams();
    for (var key in param) {
      if (param.hasOwnProperty(key)) {
        let val = param[key];
        params = params.append(key, val);
      }
    }
    return params;
  }

  public Delete<T>(id: any): Observable<T> {
    return this.http.delete<T>(this.actionUrl + id);
  }

  public addUpdateNotesForSupplier<T>(id, name, info: CompanyInfoSetting): Observable<T> {
    return this.http.put<T>(this.actionUrl + id + "/" + name + "/SupplierFileInfoTab", info);
  }

  public GetSupplierNotes<T>(id, name): Observable<T> {
    return this.http.get<T>(this.actionUrl + id + "/" + name + "/SupplierFileInfoTab");
  }

  public CreateSuppliersFromExcel<T>(formData: FormData) {
    return this.http.post(this.actionUrl + "CreateSuppliersFromExcel", formData, { headers: { IsContentType: "true" } });
  }

    public UpdateFromUniconta<T>(): Observable<T> {
        return this.http.get<T>(this.unicontaUrl + "CompareSupplierAndUpdate");
    }
}