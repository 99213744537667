export const locale = {
  lang: 'en',
  data: {
    'STATUS': {
      'Created': 'Created',
      'Updated': 'Updated',
      'Deleted': 'Deleted',
      'FileUploaded': 'Files Uploaded',
      'FileDeleted': 'Files Deleted',
    },
    'TITLE': {
      'CustomerPagination': 'Antal log pr. side',
    },
    'BUTTONS': {
      'BACK': 'Cancel',
      'BTNSAVE': 'Save',
    },
    'FIELDS': {
      'SRNO': 'Sr No',
      'Status': 'Status',
      'Number': 'Number',
      'Name': 'Name',
      'Createdby': 'Created By',
      'CreatedDate': 'Created Date',
      'ModifyBy': 'Modify By',
      'ModifyDate': 'Modify Date',
      'CoworkerName': 'Coworker Name',
      'Date': 'Date',
      'Description': 'Description',
      'CustomerNo': 'Customer No.',
      'CustomerName': 'Customer Name',
      'Email': 'E-mail',
      'PhoneNo': 'Phone No.',
      'Address-line-1': 'Address(Line 1)',
      'Address-line-2': 'Address(Line 2)',
      'City': 'City',
      'Zipcode': 'Zipcode',
      'Position': 'Position',
      'Comments': 'Comments  [ Optional ]',
      'OrderName': 'Order name',
      'EnterLocation': 'Enter your location',
      'Active': 'Set as inactive',
      'From': 'From',
      'To': 'to',
      'colorCode': 'Color code',
      'PersonnelPurchas': 'Staff Purchase',
      'EmailInvoice': 'Invoice Email',
      'EANNumber': 'EAN Number',
      'Address': 'Address',
      'Detail': 'Detail',
      'ChangedDetail': 'Changed Detail',

      'CustomerNumber': 'Customer Number',
      'StartDate': 'Start Date',
      'EndDate': 'End Date',
      'Visit': 'Visit',
      'Received': 'Received',
      'UrgentOrNot': 'Urgent/Not Urgent'
      
    },
    'Headers': {
      'CustomerLogDetails': 'Project Log Details'
    },

    'MESSAGES': {
      'NORECORD': 'No Record Found',
    },
    'selectedValueIndoorOutdoorStatus': {
      'value1': 'Indoor ',//Man in a room
      'value2': 'Outdoor', //Sun
      'value3': 'Spray Painting',//spraypaint
      'value4': 'Complaint', //Reklamation
      'value5': 'Staff purchase', //drawing of painter
      'value6': 'Store purchase' //Nordsjø logo
    },
    'UrgentOrNot': {
      'value0': 'select',
      'value1': 'Urgent',
      'value2': 'Not Urgent',
    },
    'yesOrNoOptions': {
      'value1': 'No',
      'value2': 'Yes'
    },
  }
};


