export const locale = {
    lang: 'en',
    data: {
        'BUTTONS': {
            'Search': 'Search',
            'Ok': 'Ok'
        },
        'FIELDS': {
            'StartingPoint': 'Starting Point',
            'Destination': 'Destination',
        },
        'MESSAGES': {
            'InsertText': 'Press the OK button'
        }
    }
};


