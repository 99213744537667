import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { TitleService } from 'app/core/services/title.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { CoWorkersService } from 'app/main/pages/coWorker/coWorkers.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { QuickInvoiceMaterials, QuickInvoice } from 'app/main/model/Material';
import { FormService } from 'app/core/services/form.service';
import { FormBuilder } from '@angular/forms';
import { environment } from 'environments/environment';

@Component({
    selector: 'vibe-quickinvoice-materiallist',
    templateUrl: './materiallist.component.html',
    styleUrls: ['./materiallist.component.scss']
})
export class MaterialListComponent implements OnInit {
    @Input() AddedMaterials: QuickInvoiceMaterials[];
    @Input() quickInvoice: QuickInvoice;
    @Output() EditMaterialEvent = new EventEmitter();
    @Output() DeleteMaterialEvent = new EventEmitter();
    PersonalPriceInvoiceTotal: number = 0;
    RetailPriceInvoiceTotal: number = 0;
    TotalAmount: number = 0;
    TotalDiscount: number = 0;
    InvoiceTotalWithVat: number = 0;
    MaterialTotal: number = 0;
    host: string = '';
    DefaltImage: any;
    //Initiators
    constructor(private translationLoader: FuseTranslationLoaderService,
        private _router: Router,
        private titleService: TitleService,
        private coWorkersService: CoWorkersService,
        private translate: TranslateService,
        private formBuilder: FormBuilder,
        private notificationsService: NotificationsService,
        public formService: FormService) {
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
        this.host = environment.url;
        this.DefaltImage = 'Upload/coworker.png';

        this.calculateMaterialTotal();
        this.calDiscount();
        if (this.quickInvoice.discountPercent)
            this.calDiscountPecentageWithClear();
    }

    calculateMaterialTotal() {
        for (var i = 0; i < this.AddedMaterials.length; i++) {
            if (this.quickInvoice.indoorOutdoorStatusId == "5") {
                this.PersonalPriceInvoiceTotal += this.AddedMaterials[i].personalPriceTotal;
                this.RetailPriceInvoiceTotal += this.AddedMaterials[i].retailPriceTotal;
            }
            else {
                this.RetailPriceInvoiceTotal += this.AddedMaterials[i].retailPriceTotal;
            }
            this.MaterialTotal = this.RetailPriceInvoiceTotal;
            this.AddedMaterials[i].colorCode = this.AddedMaterials[i].colorCode ? this.AddedMaterials[i].colorCode : null;
        }
    }

    EditMaterial(material) {
        material.isEditMaterial = true;
        this.EditMaterialEvent.emit(material);
    }

    DeleteMaterial(id, index) {
        this.DeleteMaterialEvent.emit(index);
        // var deletedData = this.AddedMaterials.filter(o => o.id == id);
        // if (deletedData) {
        //     this.RetailPriceInvoiceTotal -= deletedData[0].retailPriceTotal
        // }
        //this.AddedMaterials = this.AddedMaterials.filter(o => o.id != id);
        this.PersonalPriceInvoiceTotal = 0;
        this.RetailPriceInvoiceTotal = 0;
        this.MaterialTotal = 0;
        this.calculateMaterialTotal();
    }
    calDiscount() {
        if (this.quickInvoice.requisitionDetail.discountPercent && this.quickInvoice.requisitionDetail.discountPercent > 0) {
            this.TotalDiscount = (this.RetailPriceInvoiceTotal * this.quickInvoice.requisitionDetail.discountPercent / 100);
            this.TotalAmount = (this.RetailPriceInvoiceTotal - this.TotalDiscount);
        }

        if (this.quickInvoice.requisitionDetail.discount && this.quickInvoice.requisitionDetail.discount > 0) {
            this.TotalDiscount = this.quickInvoice.requisitionDetail.discount;
            this.TotalAmount = (this.RetailPriceInvoiceTotal - this.quickInvoice.requisitionDetail.discount);
        }
    }
    calDiscountPecentageWithClear() {
        if (this.quickInvoice.requisitionDetail.discountPercent && this.quickInvoice.requisitionDetail.discountPercent > 0) {
            this.quickInvoice.requisitionDetail.discount = 0;
            this.RetailPriceInvoiceTotal = this.MaterialTotal;
            this.TotalDiscount = (this.RetailPriceInvoiceTotal * this.quickInvoice.requisitionDetail.discountPercent / 100);
            this.TotalAmount = (this.RetailPriceInvoiceTotal - this.TotalDiscount);
            this.RetailPriceInvoiceTotal = this.TotalAmount;
        } else {
            this.RetailPriceInvoiceTotal = this.MaterialTotal;
        }
    }
    calDiscountWithClear() {
        if (this.quickInvoice.requisitionDetail.discount && this.quickInvoice.requisitionDetail.discount > 0) {
            this.RetailPriceInvoiceTotal = this.MaterialTotal;
            this.quickInvoice.requisitionDetail.discountPercent = 0;
            this.TotalDiscount = this.quickInvoice.requisitionDetail.discount;
            this.TotalAmount = (this.RetailPriceInvoiceTotal - this.quickInvoice.requisitionDetail.discount);
            this.RetailPriceInvoiceTotal = this.TotalAmount;
        } else {
            this.RetailPriceInvoiceTotal = this.MaterialTotal;
        }
    }
}

